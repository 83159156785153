import React, { useState } from "react";
import { Content, Header, MainContainer, Nav, NavItem, Title } from "../../styles";
import Admin from "./Admin";
import Permissions from "./Permissions";

// const TABS = ["units", "construction", "spaces", "area", "defaults"];
const TABS = ["permissions"];

function Preferences({selectedTeam, teamlist}){
    const [activeTab, setActiveTab] = useState(TABS[0]);
    return (
        <MainContainer>
            <Header>
                <Title style={{flex: "none", minWidth: "8em"}}>
                    Preferences
                </Title>
                {/* <Controls></Controls> */}
            </Header>
            <Nav>
                {
                    TABS.map(tab => {
                        return <NavItem key={tab} isActive={activeTab === tab} onClick={() => {setActiveTab(tab)}}>{tab}</NavItem>
                    })
                }
            </Nav>
            <Content>
                {activeTab === "permissions" && <Permissions key={selectedTeam.title} selectedTeam={selectedTeam} />}
                {activeTab === "defaults" && <Admin key={selectedTeam.title} selectedTeam={selectedTeam} />}
            </Content>
            {/* <Footer>Footer</Footer> */}
        </MainContainer>
    )
}

export default Preferences;