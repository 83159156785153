import BABYLON from "../../modules/babylonDS.module.js";
import {lengthOfEdge} from "../doors_windows.js";
import {isInsidePoly1, isPointOnPolygon} from "./twoSnap.js";
import {ScopeUtils} from "../scopeFunctions.js";

/**
 * Converts points in array format to <tt>BABYLON.Vector3</tt> (x, 0 ,y)
 * @param {Array} pointArray The input array of points in 2D, <tt>[x,y]</tt>
 * @returns {Array}
 */
const convertPointArrayToBabylonArray = (pointArray) =>
  pointArray.map((point) => new BABYLON.Vector3(point[0], 0, point[1]));

/**
 * Converts 2D points to 3D points, with the last element being 0.
 * @param {Array} pointArray An array of 2D points.
 * @returns {Array} An array of 3D points.
 */
const convertArray2ToArray3 = (pointArray) =>
  pointArray.map((point) => [point[0], point[1], 0]);

const changeOrderForHoles = (pointArray) =>
  pointArray.map((point) => [point[0], 0, point[1]]);
/**
 * Converts 2D path to 3D path, with the last element being 0.
 * @param {Array} pathArray An array of 2D path.
 * @returns {Array} An array of 3D path.
 */
const convertPathArray2ToPathArray3 = (pathArray) =>
  pathArray.map((singlePath) =>
    singlePath.map((point) => [point[0], point[1], 0])
  );

/**
 * Drops the y coordinate of a point3D array
 * @param {Array} pointArray Array of [x,y,z]
 * @returns {Array} Array of [x, z]
 */
const convertArray3ToArray2 = (pointArray) =>
  pointArray.map((point) => [point[0], point[2]]);

/**
 * Multiplies points with given factor
 * @param {Array} pointArray Array of 2D points.
 * @param {Number} factor
 * @param {Number} scale
 * @returns {Array} Array of 2D points multiplied by the factor and scale.
 */
const multiplyPointArrayByFactor = (pointArray, factor, scale) =>
  pointArray.map((point) => [
    point[0] * factor * scale,
    point[1] * factor * scale,
  ]);

const rotatePolygon = function (polygon, angle, centroidForRotation = [0, 0, 0], rotationQuaternion){

  function _getCentroid(coords){
    coords.pop()
    let sumX = 0;
    let sumZ = 0;

    coords.forEach((point) => {
      sumX += point[0];
      sumZ += point[1];
    });

    return [sumX / coords.length, sumZ / coords.length];
  }

  function findMinAndMax(my_array){

    return {
      xMax: Math.max(...my_array.map(e => e[0])),
      xMin: Math.min(...my_array.map(e => e[0])),
      yMax: Math.max(...my_array.map(e => e[1])),
      yMin: Math.min(...my_array.map(e => e[1]))
    };

  }

  let minAndMax = findMinAndMax(polygon);

  let _centroid1 = _getCentroid(polygon);
  // let _centroid = [ minAndMax.xMin, minAndMax.yMin ];
  centroidForRotation[2] = -centroidForRotation[2];
  let _centroid = [_centroid1[0] - centroidForRotation[0] , _centroid1[1] - centroidForRotation[2]];


  let sin = Math.sin(angle);
  let cos = Math.cos(angle);
  let _newArray = [];

  for( let i = 0; i < polygon.length; i++ ){
    let _eachPoint = polygon[i];

    // _eachPoint[0] -= _centroid[0];
    // _eachPoint[1] -= _centroid[1];

    let _xnew = ( (_eachPoint[0] - _centroid[0] ) * cos ) - ( (_eachPoint[1]-_centroid[1] ) * sin);
    let _ynew = ( (_eachPoint[0] - _centroid[0] ) * sin ) + ( (_eachPoint[1]-_centroid[1] ) * cos);

    // let _xnew = ( (_eachPoint[0] - _centroid[0]) * cos ) + ( (_eachPoint[1]-_centroid[1]) * sin);
    // let _ynew = ( - (_eachPoint[0] - _centroid[0]) * sin ) + ( (_eachPoint[1]-_centroid[1]) * cos);

    // _newArray.push([ _eachPoint[0] - _centroid[0], _eachPoint[1] - _centroid[1] ] );
    _newArray.push([ _xnew + _centroid[0], _ynew + _centroid[1] ] );

  }
  _newArray.push(_newArray[0]);
  return _newArray;
}

const rotateCADPolygon = function(polygon, angle, centroidForRotation = [0, 0, 0] ){

  function _getCentroid(coords){
    coords.pop()
    let sumX = 0;
    let sumZ = 0;

    coords.forEach((point) => {
      sumX += point[0];
      sumZ += point[1];
    });

    return [sumX / coords.length, sumZ / coords.length];
  }

  // let _centroid = _getCentroid(polygon);
  let _centroid = [centroidForRotation[0], centroidForRotation[2]];

  let sin = Math.sin(-angle);
  let cos = Math.cos(-angle);
  let _newArray = [];

  for( let i = 0; i < polygon.length; i++ ){
    let _eachPoint = polygon[i];

    // _eachPoint[0] -= _centroid[0];
    // _eachPoint[1] -= _centroid[1];

    let _xnew = ( (_eachPoint[0] - _centroid[0] ) * cos ) - ( (_eachPoint[1]-_centroid[1] ) * sin);
    let _ynew = ( (_eachPoint[0] - _centroid[0] ) * sin ) + ( (_eachPoint[1]-_centroid[1] ) * cos);


    _newArray.push([ _xnew + _centroid[0], _ynew + _centroid[1] ] );
  }

  _newArray.push(_newArray[0]);
  return _newArray;
}
/**
 * Multiplies path array by factor.
 * @param pathArray
 * @param factor
 * @param scale
 * @returns {Array}
 */
const multiplyPathArrayByFactor = (pathArray, factor, scale) => {
  const scaledPath = [];
  for (let i = 0; i < pathArray.length; i++) {
    const singlePath = pathArray[i];
    const scaledSinglePath = [];
    for (let j = 0; j < singlePath.length; j++) {
      const point = singlePath[j];
      const scaledPoint = [];
      for (let k = 0; k < point.length; k++) {
        scaledPoint.push(point[k] * factor * scale);
      }
      scaledSinglePath.push(scaledPoint);
    }
    scaledPath.push(scaledSinglePath);
  }
  return scaledPath;
};

/**
 * Negates the Y coordinate of given Point array.
 * Useful because the Y axis protruding outwards is negative, whereas all other operations are usually positive.
 * @param {Array} pointArray Array of points
 * @returns {Array} Array of points
 */
const negateYOfPointArray = (pointArray) =>
  pointArray.map((point) => [point[0], point[1] * -1, point[2]]);

/**
 * Negates the Y coordinate of given BABYLON.Vector3 path array.
 * Useful because the Y axis protruding outwards is negative, whereas all other operations are usually positive.
 * @param {Array} path array of array of BABYLON.Vector3
 * @returns {Array} array of array of BABYLON.Vector3
 */
const negateYOfPathArray = (path) =>
  path.map((singlePath) =>
    singlePath.map((point) => [point[0], point[1] * -1, point[2]])
  );

const getThreshold = (pointArray) => {
  let newPtArray = [];
  for (let i = 0; i < pointArray.length; i++) {
    newPtArray.push(pointArray[i][0]);
  }
  let min = Math.min(...newPtArray);
  let max = Math.max(...newPtArray);

  let result = Math.abs(max) - Math.abs(min);

  return Math.abs(result);
};

let removeNan = (oldArray) => {
  let newArray = oldArray.filter(function (value) {
    return !Number.isNaN(value);
  });
  return newArray;
};

let convertToPoints = (oldArr) => {
  let newArray = [];
  for (let j = 0; j < oldArr.length - 2; j = j + 3) {
    newArray.push([oldArr[j], oldArr[j + 1], oldArr[j + 2]]);
  }
  return newArray;
};

/**
 * Distance between 2D points in array format.
 * @param {Array} point1
 * @param {Array} point2
 * @returns {number}
 */
const twoGetDistance = (point1, point2) => {
  return Math.sqrt((point2[0] - point1[0]) ** 2 + (point2[1] - point1[1]) ** 2);
};

/**
 * Slope of line.
 * @param {Array} edge
 * @returns {string|number} <tt>inf</tt> if slope is infinity, a number in cases other than that.
 */
const slopeOfLine = (edge) => {
  const difference = (edge[1][0] - edge[0][0]).toFixed(5);
  if (difference == 0) {
    return "inf";
  }
  return (edge[1][1] - edge[0][1]) / (edge[1][0] - edge[0][0]);
};

const slopeOfLineVec = (edge) => {
  const difference = edge[1].x - edge[0].x;
  if (difference == 0) {
    return "inf";
  }
  return (edge[1].z - edge[0].z) / (edge[1].x - edge[0].x);
};

/**
 * Self Explanatory
 * @param {Array} point
 * @param {Number|String} slope <tt>inf</tt> if it is infinity, number in other cases.
 * @param {Number} distance
 * @returns {*[][]}
 */
const lineWithSlopeAurAtDistanceFromPoint = (point, slope, distance) => {
  if (slope == "inf") {
    let x2Plus = point[0];
    let x2Minus = point[0];
    let y2Plus = point[1] + distance;
    let y2Minus = point[1] - distance;
    let firstPoint = [x2Plus, y2Plus];
    let secondPoint = [x2Minus, y2Minus];
    return [firstPoint, secondPoint];
  }

  let c = point[1] - slope * point[0]; // y = mx + c
  let x2Plus = point[0] + Math.sqrt(distance ** 2 / (slope ** 2 + 1));
  let x2Minus = point[0] - Math.sqrt(distance ** 2 / (slope ** 2 + 1));
  let y2Plus = slope * x2Plus + c;
  let y2Minus = slope * x2Minus + c;
  let firstPoint = [x2Plus, y2Plus];
  let secondPoint = [x2Minus, y2Minus];
  return [firstPoint, secondPoint];
};

/**
 * Changes the shape of the <tt>polygon</tt> by making the given <tt>edge</tt> length equal to <tt>newDimension</tt>. Conserves all angle in the process.
 * @param {Array} polygon
 * @param {Array} edge
 * @param {Number} newDimension
 * @returns {Array}
 */
const changePolygonShape = (polygon, edge, newDimension) => {
  let newPolygon = [];
  let returnEdge = null;
  let oldPolygon = Object.assign([], polygon);
  for (let j = 0; j < oldPolygon.length; j++) {
    let prevIndex = (j - 1) % oldPolygon.length;
    if (j == 0) {
      prevIndex = oldPolygon.length - 1;
    }
    if (oldPolygon[j] == edge[1]) {
      let secondDistance = twoGetDistance(
        oldPolygon[j],
        oldPolygon[(j + 1) % oldPolygon.length]
      );
      let currentSlope = slopeOfLine([oldPolygon[prevIndex], oldPolygon[j]]);
      let secondSlope = slopeOfLine([
        oldPolygon[j],
        oldPolygon[(j + 1) % oldPolygon.length],
      ]);
      let yDirection = Math.sign(oldPolygon[j][1] - oldPolygon[prevIndex][1]);
      let xDirection = Math.sign(oldPolygon[j][0] - oldPolygon[prevIndex][0]);

      let pointsWithGivenEdgeSlope = lineWithSlopeAurAtDistanceFromPoint(
        oldPolygon[prevIndex],
        currentSlope,
        newDimension
      );
      let new1xDirection = Math.sign(
        pointsWithGivenEdgeSlope[0][0] - oldPolygon[prevIndex][0]
      );
      let new1yDirection = Math.sign(
        pointsWithGivenEdgeSlope[0][1] - oldPolygon[prevIndex][1]
      );
      let new2xDirection = Math.sign(
        pointsWithGivenEdgeSlope[1][0] - oldPolygon[prevIndex][0]
      );
      let new2yDirection = Math.sign(
        pointsWithGivenEdgeSlope[1][1] - oldPolygon[prevIndex][1]
      );
      let newPoint = null;
      let newPoint2 = null;

      if (xDirection == new1xDirection && yDirection == new1yDirection) {
        newPoint = pointsWithGivenEdgeSlope[0];
      } else {
        if (xDirection == new2xDirection && yDirection == new2yDirection) {
          newPoint = pointsWithGivenEdgeSlope[1];
        }
      }
      newPolygon.push(newPoint);
      returnEdge = [oldPolygon[prevIndex], newPoint];
      let pointsWithGivenEdgeSlope2 = lineWithSlopeAurAtDistanceFromPoint(
        newPoint,
        secondSlope,
        secondDistance
      );
      if (
        twoGetDistance(
          oldPolygon[(j + 1) % oldPolygon.length],
          pointsWithGivenEdgeSlope2[0]
        ) <
        twoGetDistance(
          oldPolygon[(j + 1) % oldPolygon.length],
          pointsWithGivenEdgeSlope2[1]
        )
      ) {
        newPoint2 = pointsWithGivenEdgeSlope2[0];
      } else {
        newPoint2 = pointsWithGivenEdgeSlope2[1];
      }
      newPolygon.push(newPoint2);
    } else if (oldPolygon[prevIndex] == edge[1]) {
      /* eslint-disable */
    } else {
      /* eslint-enable */
      newPolygon.push(oldPolygon[j]);
    }
    /* eslint-enable */
  }
  return newPolygon;
};

/**
 * Translates the <tt>polygon</tt> by <tt>xDir</tt> and <tt>yDir</tt>.
 * @param {Array} polygon
 * @param {Number} xDir
 * @param {Number} yDir
 * @returns {Array}
 */
const movePolygonInDirection = (polygon, xDir, yDir) => {
  let newPolygon = [];
  for (let i = 0; i < polygon.length; i++) {
    newPolygon.push([polygon[i][0] + xDir, polygon[i][1] + yDir]);
  }
  return newPolygon;
};

/**
 * Self Explanatory
 * @param {Array} polygon
 * @returns {Array}
 */

const removeDuplicateVertices = (polygon) => {
  const newPolygon = [];
  for (let i = 0; i < polygon.length; i++) {
    if (
      polygon[i][0] == polygon[(i + 1) % polygon.length][0] &&
      polygon[i][1] == polygon[(i + 1) % polygon.length][1]
    ) {
      newPolygon.push(polygon[i]);
      i++;
    } else {
      newPolygon.push(polygon[i]);
    }
  }
  return newPolygon;
};

const removeDuplicateVerticesModified = (polygon) => {
  const newPolygon = [];
  if (undefined === polygon || polygon.length === 0) {
    return newPolygon;
  } else {
    for (let i = 0; i < polygon.length; i++) {
      if (
        polygon[i][0] == polygon[(i + 1) % polygon.length][0] &&
        polygon[i][1] == polygon[(i + 1) % polygon.length][1]
      ) {
        newPolygon.push(polygon[i]);
        i++;
      } else {
        newPolygon.push(polygon[i]);
      }
    }
    if (
      polygon[0][0] == polygon[polygon.length - 1][0] &&
      polygon[0][1] == polygon[polygon.length - 1][1]
    ) {
      newPolygon.pop();
    }
    return newPolygon;
  }
  /* eslint-disable */
  return null;
  /* eslint-enable */
};

const equateDecimalPoints = (polygon) => {
  const newPolygon = [];
  if (undefined === polygon || polygon.length === 0) {
    return newPolygon;
  } else {
    for (let i = 0; i < polygon.length; i++) {
      let x1 = polygon[i][0];
      let y1 = polygon[i][1];

      let x2 = polygon[(i + 1) % polygon.length][0];
      let y2 = polygon[(i + 1) % polygon.length][1];

      if (x1.toFixed(2) === x2.toFixed(2)) {
        polygon[(i + 1) % polygon.length][0] = polygon[i][0];
      }
      if (y1.toFixed(2) === y2.toFixed(2)) {
        polygon[(i + 1) % polygon.length][1] = polygon[i][1];
      }
    }
    let xFirst = polygon[0][0];
    let yFirst = polygon[0][1];

    let xLast = polygon[polygon.length - 1][0];
    let yLast = polygon[polygon.length - 1][1];

    if (xFirst.toFixed(2) === xLast.toFixed(2)) {
      polygon[polygon.length - 1][0] = polygon[0][0];
    }
    if (yFirst.toFixed(2) === yLast.toFixed(2)) {
      polygon[polygon.length - 1][1] = polygon[0][1];
    }

    return polygon;
  }
  /* eslint-disable */
  return null;
  /* eslint-enable */
};

const cleanIntersectArray = (intersectArr) => {
  const newPolygon = [];
  if (undefined === intersectArr) {
    return newPolygon;
  } else {
    for (let i = 0; i < intersectArr.length; i++) {
      if (intersectArr[i].regions.length !== 0) {
        if (intersectArr[i].regions[0].length > 2) {
          newPolygon.push(intersectArr[i]);
        }
      }
    }
    return newPolygon;
  }
  /* eslint-disable */
  return null;
  /* eslint-enable */
};

const getSmallestEdge = (polygon) => {
  let smallestEdge = [polygon[0], polygon[1]];
  for (let i = 0; i < polygon.length; i++) {
    let edge = [polygon[i], polygon[(i + 1) % polygon.length]];
    if (lengthOfEdge(smallestEdge) > lengthOfEdge(edge)) {
      smallestEdge = edge;
    }
  }
  return smallestEdge;
};

const getLongestEdge = (polygon) => {
  let longest = [polygon[0], polygon[1]];
  for (let i = 0; i < polygon.length; i++) {
    let edge = [polygon[i], polygon[(i + 1) % polygon.length]];
    if (lengthOfEdge(longest) < lengthOfEdge(edge)) {
      longest = edge;
    }
  }
  return longest;
};

const roundAndRemoveDuplicates = (polygon) => {
  const newPolygon = [];
  if (undefined === polygon || polygon.length === 0) {
    return newPolygon;
  } else {
    for (let i = 0; i < polygon.length; i++) {
      if (
        Math.round(polygon[i][0]) ===
          Math.round(polygon[(i + 1) % polygon.length][0]) &&
        Math.round(polygon[i][1]) ===
          Math.round(polygon[(i + 1) % polygon.length][1])
      ) {
        newPolygon.push(polygon[i]);
        i++;
      } else {
        newPolygon.push(polygon[i]);
      }
    }
    if (
      Math.round(polygon[0][0]) ===
        Math.round(polygon[polygon.length - 1][0]) &&
      Math.round(polygon[0][1]) === Math.round(polygon[polygon.length - 1][1])
    ) {
      newPolygon.pop();
    }
    return newPolygon;
  }
  /* eslint-disable */
  return null;
  /* eslint-enable */
};

const getTopVertices = (bottomVertices, height) => {
  let newPoly = [];
  for (let i = 0; i < bottomVertices.length; i++) {
    newPoly.push([
      bottomVertices[i].x,
      bottomVertices[i].y + height,
      bottomVertices[i].z,
    ]);
  }
  newPoly = newPoly.map(
    (array) => new BABYLON.Vector3(array[0], array[1], array[2])
  );
  return newPoly;
};

const distanceBetweenPoints3DMod = (pt1, pt2) => {
  return (
    ((pt2.x - pt1.x) ** 2 + (pt2.y - pt1.y) ** 2 + (pt2.z - pt1.z) ** 2) ** 0.5
  );
};

function lengthofEdge3D(edge) {
  return distanceBetweenPoints3DMod(edge[0], edge[1]);
}

const addWallThickness = (vertices, wallThickness) => {
  let newPoly = [];
  vertices = removeDuplicateVerticesModified(vertices);
  let changeinX = vertices[1][0] - vertices[0][0];

  if (changeinX > 2 * wallThickness) {
    for (let i = 0; i < vertices.length; i++) {
      vertices[i] = [vertices[i][0], vertices[i][1] + wallThickness];
    }
  } else {
    for (let i = 0; i < vertices.length; i++) {
      vertices[i] = [vertices[i][0] + wallThickness, vertices[i][1]];
    }
  }
  return vertices;
};

const removeZeroArrays = (allPolygons) => {
  for (let i = 0; i < allPolygons.length; i++) {
    if (allPolygons[i].length < 3) {
      allPolygons.splice(i, 1);
    }
  }
  return allPolygons;
};
const getEdgesCorrespondingtoWallThickness = (vertices, wallThickness) => {
  let edgeArray = [];
  for (let i = 0; i < vertices.length; i++) {
    let edge = [vertices[i], vertices[(i + 1) % vertices.length]];
    if (Math.abs(lengthofEdge3D(edge) - wallThickness) < 0.05) {
      edgeArray.push(edge);
    }
  }
  return edgeArray;
};

const getWallAngleMod = (coords) => {
  // console.log(coords);
  var coord = coords[0];
  var normalVector = coords[2];
  // return Math.atan2(normalVector[2], normalVector[0]);
  if (normalVector.y === 0) {
    if (normalVector.x > 0) {
      return Math.PI;
    } else {
      return 0;
    }
  } else if (Math.abs(normalVector.x) > 0.001) {
    var m = normalVector.y / normalVector.x;
    return Math.atan2(normalVector.x, normalVector.y) + Math.PI / 2;
  } else {
    if (normalVector.y > 0) {
      return Math.PI / 2;
    } else {
      return -Math.PI / 2;
    }
  }
};
const getEdgesCorrespondingtoWallThicknessDoors = (vertices, wallThickness) => {
  let edgeArray = [];
  for (let i = 0; i < vertices.length; i++) {
    let edge = [vertices[i], vertices[(i + 1) % vertices.length]];
    if (Math.abs(lengthofEdge3D(edge) - wallThickness) < 1.2) {
      edgeArray.push(edge);
    }
  }
  return edgeArray;
};

const getMidVertices = (vertices, wallThickness) => {
  let newPoly = [];
  let edgeArray = getEdgesCorrespondingtoWallThickness(vertices, wallThickness);
  for (let i = 0; i < edgeArray.length; i++) {
    let v1 = edgeArray[i][0];
    let v2 = edgeArray[i][1];
    newPoly.push([(v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2]);
  }
  newPoly = newPoly.map(
    (array) => new BABYLON.Vector3(array[0], array[1], array[2])
  );
  return newPoly;
};

const getMidVerticesMod = (vertices, wallThickness) => {
  let newPoly = [];
  let edgeArray = getEdgesCorrespondingtoWallThicknessDoors(
    vertices,
    wallThickness
  );
  for (let i = 0; i < edgeArray.length; i++) {
    let v1 = edgeArray[i][0];
    let v2 = edgeArray[i][1];
    newPoly.push([(v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2]);
  }
  newPoly = newPoly.map(
    (array) => new BABYLON.Vector3(array[0], array[1], array[2])
  );
  return newPoly;
};

const isCompletelyInsideEdgeLogic = (poly1, poly2) => {
  if (poly1.length === 0 || poly2.length === 0) {
    return false;
  }
  if (isCompletelyInside(poly1, poly2)) {
    for (let i = 0; i < poly1.length; i++) {
      let edge1 = [poly1[i], poly1[(i + 1) % poly1.length]];
      for (let j = 0; j < poly2.length; j++) {
        let edge2 = [poly2[j], poly2[(j + 1) % poly2.length]];
        let status = doLineSegmentsIntersect(edge1, edge2);
        if (status) {
          return false;
        }
      }
    }
    return true;
  } else {
    return false;
  }
};

const isCompletelyInside = (poly1, poly2, checkIfOnPerimeter = false) => {
  //poly2 is inside poly1
  // let count = 0;
  // poly1.push(poly1[0]);
  // let polygon = turf.polygon([poly1]);
  //
  // for( let i = 0; i < poly2.length; i++){
  //     let point = turf.point(poly2[i]);
  //     if( turf.booleanPointInPolygon(point, polygon) === true){
  //         count = count + 1;
  //     }
  // }
  // if (count === poly2.length) {
  //     return true;
  // }
  // return false;
  if (poly1.length === 0 || poly2.length === 0) {
    return false;
  }
  let count = 0;
  for (let i = 0; i < poly2.length; i++) {
    const point = poly2[i];
    if (checkIfOnPerimeter && isPointOnPolygon(point, poly1)){
      // don't increment
      break;
    }
    else if (isInsidePoly1(point, poly1)) {
      count++;
    }
  }
  return count === poly2.length;


  // let newPtArray1 = [];
  // let newPtArray2 = [];
  //
  // for(let i = 0; i < poly1.length; i++){
  //     newPtArray1.push(poly1[i][0]);
  // }
  //
  // for(let i = 0; i < poly1.length; i++){
  //     newPtArray2.push(poly1[i][1]);
  // }
  //
  // let minXpoly1 = Math.min(...newPtArray1);
  // let maxXpoly1 = Math.max(...newPtArray1);
  //
  // let minYpoly1 = Math.min(...newPtArray2);
  // let maxYpoly1 = Math.max(...newPtArray2);
  //
  // let newPtArray3 = [];
  // let newPtArray4 = [];
  //
  // for(let i = 0; i < poly2.length; i++){
  //     newPtArray3.push(poly2[i][0]);
  // }
  //
  // for(let i = 0; i < poly2.length; i++){
  //     newPtArray4.push(poly2[i][1]);
  // }
  //
  // let minXpoly2 = Math.min(...newPtArray3);
  // let maxXpoly2 = Math.max(...newPtArray3);
  //
  // let minYpoly2 = Math.min(...newPtArray4);
  // let maxYpoly2 = Math.max(...newPtArray4);
  //
  // let isInside = false;
  // if( minXpoly1 < minXpoly2 && maxXpoly1 > maxXpoly2 && minYpoly1 < minYpoly2 && maxYpoly1 > maxYpoly2){
  //     isInside = true;
  // }
  // return isInside;
};

const isPartiallyInside = (poly1, poly2) => {
  if (poly1.length === 0 || poly2.length === 0) {
    return false;
  }
  let count = 0;
  for (let i = 0; i < poly2.length; i++) {
    if (isInsidePoly1(poly2[i], poly1) === true) {
      count = count + 1;
    }
  }
  if (count > 0) {
    return true;
  }
  return false;
};

const getUnique = (polygon) => {
  let roundedPolygon = roundPoly(polygon);
  let uniqueIndex = [];
  let uniqueArray = [];
  for (let i = 0; i < polygon.length; i++) {
    if (uniqueIndex.indexOf(roundedPolygon[i]) === -1) {
      uniqueIndex.push(i);
    }
  }
  for (let j = 0; j < uniqueIndex.length; j++) {
    uniqueArray.push(polygon[uniqueIndex[j]]);
  }

  return uniqueArray;
};

const removeInaccuracies = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    let edge1 = [polygon[i], polygon[(i + 1) % polygon.length]];
    let edge2 = [
      polygon[(i + 1) % polygon.length],
      polygon[(i + 2) % polygon.length],
    ];
    let angle = angleBetweenEdges(edge1, edge2);
    if (angle < 4) {
      polygon.splice((i + 1) % polygon.length, 1);
    }
  }
  return polygon;
};

const removesmallEdgesAfterOverlap = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    let edge1 = [polygon[i], polygon[(i + 1) % polygon.length]];
    let edge2 = [
      polygon[(i + 1) % polygon.length],
      polygon[(i + 2) % polygon.length],
    ];
    let point1 = polygon[i];
    let point2 = polygon[(i + 1) % polygon.length];
    let distance = distanceBetweenPoints3D(point1, point2);
    if (distance < 2.1) {
      polygon.splice(i, 1);
      //polygon.splice((i+1)%polygon.length, 1);
      //polygon[i-1][0] = polygon[i][0];
      //polygon.splice(i,1);
    }
  }
  return polygon;
};

const removecollinear = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    let edge1 = [polygon[i], polygon[(i + 1) % polygon.length]];
    let edge2 = [
      polygon[(i + 1) % polygon.length],
      polygon[(i + 2) % polygon.length],
    ];
    let angle = Math.round(angleBetweenEdges(edge1, edge2));
    if (angle === 0) {
      polygon.splice((i + 1) % polygon.length, 1);
    }
    if (angle === 180) {
      polygon.splice(i, 1);
    }
  }
  return polygon;
};

const getFixedDecimalValues = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    polygon[i][0] = Math.round(polygon[i][0] * 1e5) / 1e5;
    polygon[i][1] = Math.round(polygon[i][1] * 1e5) / 1e5;
  }
  return polygon;
};

const removeDuplicateDecimals = (allPoly) => {
  // let newAllPoly = [];
  // let indexArr = [];
  // for(let i = 0; i < allPoly.length; i++){
  //     let poly1 = getFixedDecimalValues(allPoly[i]);
  //     newAllPoly.push(poly1);
  // }
  // newAllPoly = newAllPoly.map(JSON.stringify).reverse().filter(function(item, index, newAllPoly) { return newAllPoly.indexOf(item, index + 1) === -1 }).reverse().map(JSON.parse);
  // //newAllPoly = Array.from(new Set(newAllPoly.map(JSON.stringify)), JSON.parse);

  for (let i = 0; i < allPoly.length; i++) {
    let a = allPoly[i];
    let th = 2;
    for (let j = i + 1; j < allPoly.length; j++) {
      let b = allPoly[j];
      if (
        a.length === b.length &&
        Math.abs(a[0][0] - b[0][0]) < th &&
        Math.abs(a[0][1] - b[0][1]) < th &&
        Math.abs(a[a.length - 1][0] - b[b.length - 1][0]) < th &&
        Math.abs(a[a.length - 1][1] - b[b.length - 1][1] < th)
      ) {
        if (
          a.length === b.length &&
          Math.abs(a[1][0] - b[1][0]) < th &&
          Math.abs(a[1][1] - b[1][1]) < th &&
          Math.abs(a[a.length - 2][0] - b[b.length - 2][0]) < th &&
          Math.abs(a[a.length - 2][1] - b[b.length - 2][1] < th)
        ) {
          allPoly.splice(j, 1);
        }
      }
    }
  }
  return allPoly;
};

const removeDuplicatesNew = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    let a = polygon[i];
    let b = polygon[(i + 1) % polygon.length];
    if (
      a[0].toFixed(2) === b[0].toFixed(2) &&
      a[1].toFixed(2) === b[1].toFixed(2)
    ) {
      polygon.splice(i, 1);
    }
  }
  return polygon;
};

const removeSmallEdges = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    let length = twoGetDistance(polygon[i], polygon[(i + 1) % polygon.length]);
    if (length < 1.2) {
      polygon.splice((i + 1) % polygon.length, 1);
    }
  }
  return polygon;
};

const dropElementsbasedonIndices = (polyArray, Indices) => {
  let newArray = [];
  for (let i = 0; i < Indices.length; i++) {
    newArray.push(polyArray[Indices[i]]);
  }
  return newArray;
};

const roundPoly = (polygon) => {
  const newPolgon = [];
  for (let i = 0; i < polygon.length; i++) {
    newPolgon.push([Math.floor(polygon[i][0]), Math.floor(polygon[i][1])]);
  }
  return newPolgon;
};

/**
 * Checks if <tt>number2</tt> is between <tt>number1</tt> and <tt>number3</tt>
 * @param {Number} number1
 * @param {Number} number2
 * @param {Number} number3
 * @returns {boolean}
 */
const isBetween = (number1, number2, number3) => {
  if (number2 >= number1) {
    if (number2 <= number3) {
      return true;
    }
  } else if (number2 >= number3) {
    if (number2 <= number1) {
      return true;
    }
  } else {
    return false;
  }
};

/**
 *
 * @param {Array} point1
 * @param {Array} point2
 * @param {Array} point3
 * @returns {boolean}
 */
const isPointBetween = (point1, point2, point3) => {
  if (
    isBetween(point1[0], point2[0], point3[0]) ||
    isBetween(point1[1], point2[1], point3[1])
  ) {
    return true;
  }
  return false;
};
const changeOrder = (pointArray) => {
  let newPoly = [];
  for (let i = 0; i < pointArray.length; i++) {
    let XY = [pointArray[i][0], pointArray[i][2], pointArray[i][1]];
    newPoly.push(XY);
  }
  return newPoly;
};
const changeOrderMod = (pointArray) => {
  let newPoly = [];
  for (let i = 0; i < pointArray.length; i++) {
    let XY = [pointArray[i].x, pointArray[i].z, pointArray[i].y];
    newPoly.push(XY);
  }
  return newPoly;
};

const modifyWallPoints = (wallPoints) => {
  wallPoints.outerCoords = changeOrder(wallPoints.outerCoords);
  wallPoints.innerCoords = changeOrder(wallPoints.innerCoords);
  wallPoints.bottomCoords = changeOrder(wallPoints.bottomCoords);
  wallPoints.topCoords = changeOrder(wallPoints.topCoords);
  wallPoints.sideCoords = changeOrder(wallPoints.sideCoords);
  return wallPoints;
};

/**
 * Checks if all the angles of the polygon <tt>pointArray</tt> are either 0 degrees or 90 degrees with respect to the axes.
 * @param {Array} pointArray
 * @returns {boolean}
 */
const isOrthogonal = (pointArray) => {
  for (let i = 0; i < pointArray.length; i++) {
    let slope = slopeOfLine([
      pointArray[i],
      pointArray[(i + 1) % pointArray.length],
    ]);
    if (!(slope == "inf" || slope == 0)) {
      return false;
    }
  }
  return true;
};

const isOrthogonalModified = (pointArray) => {
  let pointArray1 = [];

  for (let i = 0; i < pointArray.length; i++) {
    let XY = [pointArray[i][0], pointArray[i][1]];
    pointArray1.push(XY);
  }
  for (let i = 0; i < pointArray1.length; i++) {
    let slope = slopeOfLine([
      pointArray1[i],
      pointArray1[(i + 1) % pointArray1.length],
    ]);
    if (!(slope == "inf" || slope == 0)) {
      return false;
    }
  }
  return true;
};

/**
 * Self explanatory.
 * @param {Array} polygon
 * @returns {Array}
 */
const pointArrayToEdgeArray = (polygon) => {
  const edgeArray = [];
  for (let i = 0; i < polygon.length; i++) {
    const edge = [polygon[i], polygon[(i + 1) % polygon.length]];
    edgeArray.push(edge);
  }
  return edgeArray;
};

/**
 * Distance between two line segments. For non parallel line segments, the minimum distance between them.
 * @param {Array} edge1
 * @param {Array} edge2
 * @returns {number}
 */
const distanceBetweenEdges = (edge1, edge2) => {
  if (doLineSegmentsIntersect(edge1, edge2)) {
    return 0;
  }
  const distances = [];
  distances.push(distanceBetweenPointAurLine(edge1[0], edge2[0], edge2[1]));
  distances.push(distanceBetweenPointAurLine(edge1[1], edge2[0], edge2[1]));
  distances.push(distanceBetweenPointAurLine(edge2[0], edge1[0], edge1[1]));
  distances.push(distanceBetweenPointAurLine(edge2[1], edge1[0], edge1[1]));
  return distances.min();
};

/**
 * Distance between two line segments. For non parallel line segments, the minimum distance between them.
 * @param {Array} edge1
 * @param {Array} edge2
 * @returns {number}
 */
const distanceBetweenEdgesArray = (edge1, edge2) => {
  const distances = [];
  distances.push(distanceBetweenPointAurLine(edge1[0], edge2[0], edge2[1]));
  distances.push(distanceBetweenPointAurLine(edge1[1], edge2[0], edge2[1]));
  distances.push(distanceBetweenPointAurLine(edge2[0], edge1[0], edge1[1]));
  distances.push(distanceBetweenPointAurLine(edge2[1], edge1[0], edge1[1]));
  return distances.min();
};

/**
 * Self explanatory.
 * @param {Array} edge1
 * @param {Array} edge2
 * @returns {boolean}
 */
const doLineSegmentsIntersect = (edge1, edge2) => {
  const onSegment = function (p, q, r) {
    if (
      q.x <= Math.max(p.x, r.x) &&
      q.x >= Math.min(p.x, r.x) &&
      q.y <= Math.max(p.y, r.y) &&
      q.y >= Math.min(p.y, r.y)
    ) {
      return true;
    }
    return false;
  };

  const orientation = function (p, q, r) {
    let val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val == 0) return 0;
    return val > 0 ? 1 : 2;
  };

  const o1 = orientation(edge1[0], edge2[0], edge1[1]);
  const o2 = orientation(edge1[0], edge2[0], edge2[1]);
  const o3 = orientation(edge1[1], edge2[1], edge1[0]);
  const o4 = orientation(edge1[1], edge2[1], edge2[0]);

  if (o1 != o2 && o3 != o4) return true;

  if (o1 == 0 && onSegment(edge1[0], edge1[1], edge2[0])) return true;

  if (o2 == 0 && onSegment(edge1[0], edge2[1], edge2[0])) return true;

  if (o3 == 0 && onSegment(edge1[1], edge1[0], edge2[1])) return true;

  if (o4 == 0 && onSegment(edge1[1], edge2[0], edge2[1])) return true;

  return false;
};

/**
 * Self explanatory.
 * @param {Array} point
 * @param {Array} edgePt1
 * @param {Array} edgePt2
 * @returns {*[]}
 */
const projectionOfPointOnLine2D = (point, edgePt1, edgePt2) => {
  const line = [edgePt2[0] - edgePt1[0], edgePt2[1] - edgePt1[1]];
  const lineMod = (line[0] ** 2 + line[1] ** 2) ** 0.5;
  const projectionOfLine = [line[0] / lineMod, line[1] / lineMod];
  const vectorFromEdgePoint1ToPoint = [
    point[0] - edgePt1[0],
    point[1] - edgePt1[1],
  ];
  const t = productDot(vectorFromEdgePoint1ToPoint, projectionOfLine);
  const scaledT = [projectionOfLine[0] * t, projectionOfLine[1] * t];
  return [edgePt1[0] + scaledT[0], edgePt1[1] + scaledT[1]];
};

/**
 * Self explanatory.
 * @param {Array} edge1
 * @param {Array} edge2
 * @returns {boolean|number}
 */
const angleBetweenEdges = (edge1, edge2) => {
  const vector1 = [edge1[1][0] - edge1[0][0], edge1[1][1] - edge1[0][1]];
  const vector2 = [edge2[1][0] - edge2[0][0], edge2[1][1] - edge2[0][1]];
  if (vector1 == 0 || vector2 == 0) {
    return false;
  }
  const dotProd =
    productDot(vector1, vector2) / (modVector(vector1) * modVector(vector2));
  return (Math.acos(dotProd) * 180) / Math.PI;
};

/**
 * Self explanatory.
 * @param {Array} point
 * @param {Number} angle
 * @param {Number} distance
 * @returns {*[]}
 */
const lineWithAngleAndAtDistanceFromPoint = (point, angle, distance) => {
  if (angle == 90 || angle == -90 || angle == 270) {
    return lineWithSlopeAndAtDistanceFromPoint(point, "inf", distance);
  }
  const slope = Math.tan(angle);
  return lineWithSlopeAndAtDistanceFromPoint(point, slope, distance);
};

/**
 * Self explanatory.
 * @param {Array} point
 * @param {Number} slope
 * @param {Number} distance
 * @returns {*[][]}
 */
const lineWithSlopeAndAtDistanceFromPoint = (point, slope, distance) => {
  if (slope == "inf") {
    let x2Plus = point[0];
    let x2Minus = point[0];
    let y2Plus = point[1] + distance;
    let y2Minus = point[1] - distance;
    let firstPoint = [x2Plus, y2Plus];
    let secondPoint = [x2Minus, y2Minus];
    return [firstPoint, secondPoint];
  }

  let c = point[1] - slope * point[0]; // y = mx + c
  let x2Plus = point[0] + Math.sqrt(distance ** 2 / (slope ** 2 + 1));
  let x2Minus = point[0] - Math.sqrt(distance ** 2 / (slope ** 2 + 1));
  let y2Plus = slope * x2Plus + c;
  let y2Minus = slope * x2Minus + c;
  let firstPoint = [x2Plus, y2Plus];
  let secondPoint = [x2Minus, y2Minus];
  return [firstPoint, secondPoint];
};

/**
 * Self explanatory.
 * @param {Array} line
 * @param {Array} point
 * @returns {*[]}
 */
const lineParallelToLineAndPassingThroughPoint = (line, point) => {
  const slope = slopeOfLine(line);
  let anotherPoint = [0, null];
  if (slope == "inf") {
    anotherPoint[0] = point[0];
    if (point[1] == 0) {
      anotherPoint[1] = 1;
    } else {
      anotherPoint[1] = 0;
    }
    return [point, anotherPoint];
  }

  const c = point[1] - point[0] * slope;
  if (point[0] == 0) {
    anotherPoint[0] = 1;
  }
  anotherPoint[1] = anotherPoint[0] * slope + c;

  return [point, anotherPoint];
};

/**
 * Self explanatory.
 * @param {Array} pt1
 * @param {Array} pt2
 * @returns {number}
 */
const distanceBetweenPoints2D = (pt1, pt2) => {
  return ((pt2[0] - pt1[0]) ** 2 + (pt2[1] - pt1[1]) ** 2) ** 0.5;
};

/**
 * Self explanatory.
 * @param {Array} pt1
 * @param {Array} pt2
 * @returns {number}
 */
const distanceBetweenPoints3D = (pt1, pt2) => {
  return (
    ((pt2[0] - pt1[0]) ** 2 +
      (pt2[1] - pt1[1]) ** 2 +
      (pt2[2] - pt1[2]) ** 2) **
    0.5
  );
};

const distanceBetweenPoints3DVec = (pt1, pt2) => {
  return (
    ((pt2.x - pt1.x) ** 2 + (pt2.y - pt1.y) ** 2 + (pt2.z - pt1.z) ** 2) ** 0.5
  );
};

/**
 * Self explanatory.
 * @param {Array} pointToBeChecked
 * @param {Array} edgePoint1
 * @param {Array} edgePoint2
 * @returns {number}
 */
const distanceBetweenPointAurLine = (
  pointToBeChecked,
  edgePoint1,
  edgePoint2
) => {
  const vector1 = [
    pointToBeChecked[0] - edgePoint1[0],
    pointToBeChecked[1] - edgePoint1[1],
    0,
  ];
  const vector2 = [
    pointToBeChecked[0] - edgePoint2[0],
    pointToBeChecked[1] - edgePoint2[1],
    0,
  ];
  const crossVector = productCross(vector1, vector2);
  const numerator = distanceBetweenPoints3D(crossVector, [0, 0, 0]);
  const line = [
    edgePoint2[0] - edgePoint1[0],
    edgePoint2[1] - edgePoint1[1],
    0,
  ];
  const denominator = distanceBetweenPoints3D(line, [0, 0, 0]);
  return numerator / denominator;
};

/**
 * Complex explanation
 * @param {Array} edge1
 * @param {Array} edge2
 * @returns {boolean}
 */
const areEdgesClose = (edge1, edge2) => {
  if (slopeOfLine(edge1) == "inf") {
    if (isBetween(edge1[0][1], edge2[0][1], edge1[1][1])) return true;
    if (isBetween(edge1[0][1], edge2[1][1], edge1[1][1])) return true;
  } else if (slopeOfLine(edge2) == 0) {
    if (isBetween(edge1[0][0], edge2[0][0], edge1[1][0])) return true;
    if (isBetween(edge1[0][0], edge2[1][0], edge1[1][0])) return true;
  }

  if (isPointBetween(edge1[0], edge2[0], edge1[1])) return true;
  return isPointBetween(edge1[0], edge2[1], edge1[1]);
};

const areEdgesClose2 = (edge1, edge2) => {
  if (slopeOfLine(edge1) == "inf") {
    if (isBetween(edge1[0][1], edge2[0][1], edge1[1][1])) return true;
    if (isBetween(edge1[0][1], edge2[1][1], edge1[1][1])) return true;
  } else if (slopeOfLine(edge1) == 0) {
    if (isBetween(edge1[0][0], edge2[0][0], edge1[1][0])) return true;
    if (isBetween(edge1[0][0], edge2[1][0], edge1[1][0])) return true;
  }

  if (isPointBetween(edge1[0], edge2[0], edge1[1])) return true;
  return isPointBetween(edge1[0], edge2[1], edge1[1]);
};

const zeroDownZ = (pointArray, yPos) => {
  const zeroPointArray = [];
  for (let i = 0; i < pointArray.length; i++) {
    const XY = [pointArray[i][0], pointArray[i][1], yPos];
    zeroPointArray.push(XY);
  }
  return zeroPointArray;
};

const productCross = (vector1, vector2) => {
  if (vector1.length != 3 || vector2.length != 3) {
    return;
  }
  return [
    vector1[1] * vector2[2] - vector1[2] * vector2[1],
    vector1[2] * vector2[0] - vector1[0] * vector2[2],
    vector1[0] * vector2[1] - vector1[1] * vector2[0],
  ];
};

const productDot = (vector1, vector2) => {
  if (vector1.length != vector2.length)
    throw "can't find dot product: arrays have different lengths";
  let dotProd = 0;
  for (let i = 0; i < vector1.length; i++) dotProd += vector1[i] * vector2[i];
  return dotProd;
};

const modVector = (a) => {
  let mod = 0;
  for (let i = 0; i < a.length; i++) {
    mod += a[i] * a[i];
  }
  return Math.sqrt(mod);
};

const getMinEdge = (edgeArray) => {
  let minEdge = edgeArray[0];
  for (let i = 0; i < edgeArray.length; i++) {
    if (
      distanceBetweenEdges(
        edgeArray[i % edgeArray.length],
        edgeArray[(i + 1) % edgeArray.length]
      ) < minEdge
    ) {
      minEdge = edgeArray[i % edgeArray.length];
    }
  }
  let minEdgeLength = distanceBetweenPoints2D(minEdge[0], minEdge[1]);
  return minEdgeLength;
};

const removeNegs = (pointsArray) => {
  let newPtArray = [];
  let x = 0;
  let y = 0;
  let z = 0;

  for (let i = 0; i < pointsArray.length; i++) {
    if (pointsArray[i][0] < 0 && pointsArray[i][0] <= x) {
      x = pointsArray[i][0];
    }
    if (pointsArray[i][1] < 0 && pointsArray[i][1] <= y) {
      y = pointsArray[i][1];
    }
  }

  for (let i = 0; i < pointsArray.length; i++) {
    newPtArray.push([pointsArray[i][0] - x, pointsArray[i][1] - y]);
  }
  return [newPtArray, x, y];
};

const addNegs = (pointsArray, x, y) => {
  let newPtArray = [];
  for (let i = 0; i < pointsArray.length; i++) {
    newPtArray.push([pointsArray[i][0] + x, pointsArray[i][1] + y]);
  }
  return newPtArray;
};

const convertPointArrayToBabylonPolygon = (pointArray) => {
  let babyArray = convertPointArrayToBabylonArray(pointArray);
  if (babyArray[0].equals(babyArray[babyArray.length - 1])) {
    babyArray.pop();
  }
  return babyArray;
};

const multiplyBabylonArrayByFactor = (babyArray, factor) => {
  let newBabyArray = [];
  for (let i = 0; i < babyArray.length; i++) {
    let scaledBaby = babyArray[i].scale(factor);
    newBabyArray.push(scaledBaby);
  }
  return newBabyArray;
};

const negateZOfBabylonArray = (babyArray) => {
  let newBabyArray = [];
  for (let i = 0; i < babyArray.length; i++) {
    let negatedZ = new BABYLON.Vector3(
      babyArray[i].x,
      babyArray[i].y,
      babyArray[i].z * -1
    );
    newBabyArray.push(negatedZ);
  }
  return newBabyArray;
};

const exchangeYAndZ = (pointArray) => {
  const newPointArray = [];
  for (let i = 0; i < pointArray.length; i++) {
    const XY = [pointArray[i][0], pointArray[i][2], pointArray[i][1]];
    newPointArray.push(XY);
  }
  return newPointArray;
};

const exchangeYandZinSketch = (pointArray) => {
  const newPtArray = [];
  for (let i = 0; i < pointArray.length; i++) {
    const XY = [pointArray[i][0], pointArray[i][2], pointArray[i][1]];
    newPtArray.push(XY);
  }
  return newPtArray;
};

const dropY = (pointArray) => {
  const newPointArray = [];
  for (let point in pointArray) {
    newPointArray.push([point[0], point[2]]);
  }
  return newPointArray;
};

const getDistanceInDimensions = (point1, point2, units) => {
  let roundFactor = null;
  if (units === "inches") {
    roundFactor = 1;
  } else if (units === "meters") {
    roundFactor = 0.0254;
  } else if (units === "millimeter") {
    roundFactor = 25.4;
  } else if (units === "centimeter") {
    roundFactor = 2.54;
  } else {
    roundFactor = 1;
  }

  return twoGetDistance(point1 * roundFactor, point2 * roundFactor);
};

const getOverlappingDistance = (edge1, edge2) => {
  const roundFactor = ScopeUtils.getUnitFactor();

  const firstPoint = projectionOfPointOnLine2D(edge1[0], edge2[0], edge2[1]);
  const secondPoint = projectionOfPointOnLine2D(edge1[1], edge2[0], edge2[1]);

  const firstEdge = [firstPoint * roundFactor, secondPoint * roundFactor];
  const secondEdge = [edge2[0] * roundFactor, edge2[1] * roundFactor];
};

const edgeArrayToPolygonArray = (edgeArray) => {
  const polygon = [];
  for (let i = 0; i < edgeArray.length; i++) {
    edgeArray[i][0][1] = -1 * edgeArray[i][0][1];
    polygon.push(edgeArray[i][0]);
  }
  // polygon.push(polygon[0]);
  return polygon;
};

const checkIfParallel = (edge1, edge2) => {
  const vector1 = [edge1[1][0] - edge1[0][0], edge1[1][1] - edge1[0][1]];
  const vector2 = [edge2[1][0] - edge2[0][0], edge2[1][1] - edge2[0][1]];
  if (vector1 == 0 || vector2 == 0) {
    return false;
  }
  const dotProd =
    productDot(vector1, vector2) / (modVector(vector1) * modVector(vector2));
  if (Math.abs(dotProd) == 1) {
    return true;
  }
  return false;
};

const checkIfNearArray = (pt1, pt2, threshold) => {
  if (distanceBetweenPoints2D(pt1, pt2) < threshold) {
    return true;
  }
  return false;
};
export {
  convertPointArrayToBabylonArray,
  convertArray2ToArray3,
  changeOrderForHoles,
  convertPathArray2ToPathArray3,
  convertArray3ToArray2,
  multiplyPointArrayByFactor,
  rotatePolygon,
  rotateCADPolygon,
  multiplyPathArrayByFactor,
  negateYOfPointArray,
  negateYOfPathArray,
  getThreshold,
  removeNan,
  convertToPoints,
  twoGetDistance,
  slopeOfLine,
  slopeOfLineVec,
  lineWithSlopeAurAtDistanceFromPoint,
  changePolygonShape,
  movePolygonInDirection,
  removeDuplicateVertices,
  removeDuplicateVerticesModified,
  equateDecimalPoints,
  cleanIntersectArray,
  getSmallestEdge,
  getLongestEdge,
  roundAndRemoveDuplicates,
  getTopVertices,
  distanceBetweenPoints3DMod,
  lengthofEdge3D,
  addWallThickness,
  removeZeroArrays,
  getEdgesCorrespondingtoWallThickness,
  getWallAngleMod,
  getEdgesCorrespondingtoWallThicknessDoors,
  getMidVertices,
  getMidVerticesMod,
  isCompletelyInsideEdgeLogic,
  isCompletelyInside,
  isPartiallyInside,
  getUnique,
  removeInaccuracies,
  removesmallEdgesAfterOverlap,
  removecollinear,
  getFixedDecimalValues,
  removeDuplicateDecimals,
  removeDuplicatesNew,
  removeSmallEdges,
  dropElementsbasedonIndices,
  roundPoly,
  isBetween,
  isPointBetween,
  changeOrder,
  changeOrderMod,
  modifyWallPoints,
  isOrthogonal,
  isOrthogonalModified,
  pointArrayToEdgeArray,
  distanceBetweenEdges,
  distanceBetweenEdgesArray,
  doLineSegmentsIntersect,
  projectionOfPointOnLine2D,
  angleBetweenEdges,
  lineWithAngleAndAtDistanceFromPoint,
  lineWithSlopeAndAtDistanceFromPoint,
  lineParallelToLineAndPassingThroughPoint,
  distanceBetweenPoints2D,
  distanceBetweenPoints3D,
  distanceBetweenPoints3DVec,
  distanceBetweenPointAurLine,
  areEdgesClose,
  areEdgesClose2,
  zeroDownZ,
  productCross,
  productDot,
  modVector,
  getMinEdge,
  removeNegs,
  addNegs,
  convertPointArrayToBabylonPolygon,
  multiplyBabylonArrayByFactor,
  negateZOfBabylonArray,
  exchangeYAndZ,
  exchangeYandZinSketch,
  dropY,
  getDistanceInDimensions,
  getOverlappingDistance,
  edgeArrayToPolygonArray,
  checkIfParallel,
  checkIfNearArray,
};
