import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import Avatar from "../Avatar";
import CardContainer from "./Outer";
import downArrow from "../../assets/images/down_arrow.svg";
import Dropdown from "../Dropdown/index";
import * as styles from "./styles";
import { colors } from "../../themes/constant";
import { ProjectNameInput } from "../TopMenuBar/StyledComponents";
import { deleteProject, renameProject } from "../../services/projects.service";
import { blurOnEnter } from "../../snaptrude/libs/keyEvents";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import { useDispatch } from "react-redux";
import _ from "lodash";

const PROJECT_NAME_LENGTH_MAX = 28;

const InnerCard = styled.div`
  ${({ image }) =>
    image &&
    css`
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    `}
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : colors.veryLightGrey};
  height: 11rem;
  position: relative;
  border-radius: 0.9375rem;
`;

const ContentContainer = styled.div`
  display: flex;
  background-color: ${colors.fullWhite};
  width: 100%;
  max-height: 6rem;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  ${({ tooltip }) => 
    tooltip && tooltip.length > PROJECT_NAME_LENGTH_MAX &&
    css`
    &{
      position: relative;
    }
    &::before,
    &::after{
      position: absolute;
      top: -0.25rem;
      left: 50%;
      transform: translateX(-50%) translateY(-100%) scale(0);
      transition: 50ms transform;
      transform-origin: bottom center;
    }
    &::before{
      content: attr(data-tooltip);
      font-size: small;
      color: white;
      padding: 0.5rem;
      width: max-content;
      max-width: 100%;
      background: #333;
      border-radius: 0.3rem;
      word-break: break-all;
    }
    &:hover::before,
    &:hover::after {
      transform: translateX(-50%) translateY(-100%) scale(1);
    }
    `
  }
`;

const StyledHeader = styled.p`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.313rem;
  letter-spacing: -0.021rem;
  color: ${colors.primeBlack};
  opacity: 0.8;
  margin: 0.25rem;
  padding: 0;
  display: flex;
  align-items: center;
  word-break: break-all;
  overflow:hidden;
`;

const StyledDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.021rem;
  color: ${colors.primeBlack};
  opacity: 0.8;
  margin: 0.25rem;
  padding: 0;
`;

const StyledFooter = styled.p`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.021rem;
  color: rgba(45, 45, 46, 0.6);
  opacity: 0.8;
  margin: 0.25rem;
  padding: 0;
`;

function Card({
  onClick,
  avatars,
  inner,
  headerText,
  floorkey,
  description,
  footerText,
  dropdownOptions,
  image,
  cardWrapperStyle,
  accessIcon,
  isDisabled,
  teamId,
  parentFolderID
}) {
  const [projectName, setProjectName] = useState(headerText);
  const [onRenameMode, setOnRenameMode] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => setOpen(!open);
  const handleSelect = (item) => {
    // toggle(!open);
    const action = item.title || item;
    switch (action) {
      case "Open":
        handleClick();
        break;
      case "Rename":
        setOnRenameMode(true);
        break;
      case "Delete":
        deleteProject(floorkey, teamId, parentFolderID).then((resp) => {
          if(resp.success){
            // dispatch(deleteProjectRedux({floorkey}))
            // setIsDeleted(true);
          }else{
            showToast("Couldn't delete the project.", 3000, TOAST_TYPES.error);
          }
        })
        break;
      default:
        break;
    }
  }

  const handleClick = () => {
    // if(isDisabled) return;
    onClick();
  }

  const dropdownRef = useRef(null);

  return (
    <CardContainer className={`${isDisabled ? 'disabled' : ''}`} hover={true} style={isDeleted ? {display: "none"} : cardWrapperStyle}>
      {(inner || avatars) && (
        <InnerCard image={image} onClick={handleClick} style={{cursor: "pointer"}}>
          {avatars ? <Avatar urls={avatars} /> : inner}
        </InnerCard>
      )}
      <ContentContainer tooltip={projectName} options data-tooltip={`${projectName}`}>
        <div>
          <div style={styles.card.contentContainerStyles}>
            {
              onRenameMode ? 
              <ProjectNameInput
                    type="text"
                    autoFocus={true}
                    value={newProjectName}
                    onBlur={(e) => {
                      setOnRenameMode(false);
                      const text = e.target.value;
                      if(text !== "" && text !== projectName){
                        renameProject(floorkey, text, teamId, parentFolderID).then(res => {
                          if (res.success) setProjectName(text);
                        });
                      }
                    }}
                    onKeyDown={blurOnEnter}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    maxLength={50}
                  />
              :
              projectName && <StyledHeader> 
                {
                  accessIcon && <img src={accessIcon} alt="access" style={{marginRight: "0.5em"}} />
                }
                <span className="project-name">
                  {_.truncate(projectName, {length: PROJECT_NAME_LENGTH_MAX})} 
                </span>
              </StyledHeader>
            }
            {dropdownOptions && dropdownOptions.length > 0 && (
              <div style={styles.card.dropDownParentStyles}>
                <Dropdown
                  ref={dropdownRef}
                  icon={downArrow}
                  items={dropdownOptions}
                  selected={handleSelect}
                  customDropdownStyles={styles.card.customDropdownStyles}
                  customParentDivStyles={styles.card.customParentDivStyles}
                  customPaddingStyles="0.4rem"
                  align="left"
                />
              </div>
            )}
          </div>
          {description && <StyledDescription>{description}</StyledDescription>}
        </div>
        {footerText && <StyledFooter>{footerText}</StyledFooter>}
      </ContentContainer>
    </CardContainer>
  );
}

Card.propTypes = {
  avatars: PropTypes.array,
  options: PropTypes.bool,
  inner: PropTypes.any,
  children: PropTypes.element,
  image: PropTypes.string,
  customOptionsContainerStyles: PropTypes.instanceOf(Object),
};

export default Card;
