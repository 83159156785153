import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { cameraController } from "../modules/cameraControl/cameraController.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { createMassBlocks, createMassBlocksForStructure, } from "./massModeling.js";
import { createRooms } from "./wall_generation.js";
import { generateDoorData, generateWindowData, getDoorDataFplan, getWindowDataFplan, } from "./doors_windows.js";
import { updateButtonColor } from "./sketch/updateEventModes.js";
import {
  createGroupBoundingBox,
  getPlansData,
  onCamRot,
  onSolid,
  setActiveLayerAndRecord,
  updateLevelsAngularUI,
} from "../modules/extrafunc.js";
import { appElement } from "./bimDataFuncs.js";
import {
  _changeTextureDetail,
  loadCeilingMaterial,
  loadFloorMaterial,
  loadMaterials,
  loadWallMaterial,
} from "./mats.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { nonDefaultMesh, recreateMaterials, recreateMultiMaterials, updateModifications, } from "./sceneStateFuncs.js";
import { offsetStructures } from "./wall_blocks.js";
import { scrollZoom } from "./zoomFuncs.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { genInteriors2 } from "./interiors_gen.js";
import { hideAllRoofs, hideObjectUtil, showAllRoofs, updateRoofAccordion, } from "./roofVisibilityFuncs.js";
import { send_scene_data, } from "./serverFuncs.js";
import { handleEscapeKeyEvent } from "./keyEvents.js";
import { goIntoTwoD, goOutOfTwoD, } from "./twoDimension.js";
import { areaView } from "./areaView.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import { storeyView } from "./storeyView.js";
import { layerView } from "./layerView.js";
import { doorOperation } from "../modules/meshoperations/doorOperation.js";
import { windowOperation } from "../modules/meshoperations/windowOperation.js";
import { easyMoveCamera } from "./cameraFuncs.js";
import { labelView } from "./labelView.js";
import { showAllObjects } from "./toolbarEvents.js";
import { userSetBIMPropertiesHandler } from "../modules/utilityFunctions/CONSTANTS/userSetBimPropertiesHandler.js";
import sunpath from "../modules/sunpath/sunpathOperations.js";
import { explorerCam } from "./explorerCam";
import { isNeighborhoodBuilding } from "../modules/geo/terrainNeighborhood.js";
import { isMassPanelOrLinkedModel } from "../modules/speckleRevitImport/curtainWall.js";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds";

// Commented import mixpanel from 'mixpanel-browser';

function sceneInit() {
  store.newScene.gravity = new BABYLON.Vector3(0, -0.9, 0);
  // store.newScene.collisionsEnabled = true;
  showAxis(9500);
  // showAxis(-5000);
  // var groundMaterial = new BABYLON.GridMaterial("groundMaterial", store.scene);
  // groundMaterial.majorUnitFrequency = 5;
  // groundMaterial.minorUnitVisibility = 0.25;
  // groundMaterial.gridRatio = 2;
  // groundMaterial.backFaceCulling = false;
  // groundMaterial.mainColor = new BABYLON.Color3(1, 1, 1);
  // groundMaterial.lineColor = new BABYLON.Color3(1.0, 1.0, 1.0);
  // groundMaterial.opacity = 0.78;
  // groundMaterial.diffuseColor = new BABYLON.Color3(0.4, 1.0, 0.47);

  // ground.receiveShadows = true;
  // ground.material = groundMaterial;
  // ground.checkCollisions = true;
  // ground.optimize(100);
  // ground.receiveShadows = true;

  // var ground2 = BABYLON.Mesh.CreateGround("ground2", 1000, 1000, 50, store.newScene);
  // ground2.visibility = 0;
  // ground2.material = new BABYLON.StandardMaterial("gMat", store.scene);
  // ground2.material.alpha = 0.0;
  // ground2.checkCollisions = true;
  // ground2.optimize(1000);
  // ground2.receiveShadows = true;
  // ground2.position.y = 1;

  var camera3 = new BABYLON.ArcRotateCamera(
    "ArcRotateCamera3",
    0.5,
    0,
    15,
    BABYLON.Vector3.Zero(),
    store.newScene
  );
  store.newScene.activeCamera = camera3;
  // store.newScene.activeCamera.attachControl(canvas, true, false);

  cameraController.attach();

  store.newScene.activeCamera.setPosition(new BABYLON.Vector3(200, 100, -130));
  // store.newScene.activeCamera.lowerBetaLimit = 0.1;
  // store.newScene.activeCamera.upperBetaLimit = (Math.PI / 2) * 0.99;
  // store.newScene.activeCamera.upperRadiusLimit = 900;
  store.newScene.activeCamera.lowerRadiusLimit = 0.0002;
  store.newScene.activeCamera.inertia = 0.42;
  store.newScene.activeCamera.minZ = 0;
  store.newScene.activeCamera.zoomOnFactor = 0.01;
  // store.newScene.activeCamera.inertialPanningY = 0.00002;
  store.newScene.activeCamera.panningSensibility = 15;
  store.newScene.activeCamera.inertialPanningX = 0.0001;
  store.newScene.activeCamera.inertialPanningY = 0.0001;
  store.newScene.activeCamera.panningInertia = 0.2;
  store.newScene.wheelPrecision = 1.5;
  // }
  // store.newScene.clearColor = new BABYLON.Color3(0.529, 0.808, 0.98);
  store.newScene.render();
  store.engine.runRenderLoop(function () {
    store.newScene.render();
    uiIndicatorsHandler.updateLoop();
  });
  store.engine.setSize(window.innerWidth, window.innerHeight);
  
  // store.renderer = store.scene.enableDepthRenderer(store.scene.activeCamera, false);
  // store.depthMap = store.renderer.getDepthMap();
  // store.buffer = new Float32Array(4 * store.depthMap.getSize().width * store.depthMap.getSize().height)
  // document.getElementById("cw").style.visibility = "hidden";
  // document.getElementById("ccw").style.visibility = "hidden";
}

function drawGrid(a) {
  let _lines = [];
  let b = -0.01;
  let c = 10;
  for (var i1 = -a; i1 <= a; i1 += c) {
    _lines.push([
      new BABYLON.Vector3(i1, b, -a),
      new BABYLON.Vector3(i1, b, a),
    ]);
  }
  for (var i2 = -a; i2 <= a; i2 += c) {
    _lines.push([
      new BABYLON.Vector3(-a, b, i2),
      new BABYLON.Vector3(a, b, i2),
    ]);
  }
  let lineSystem = BABYLON.MeshBuilder.CreateLineSystem(
    "lineSystem",
    { lines: _lines },
    store.scene
  );
  lineSystem.color = new BABYLON.Color3(0.7, 0.7, 0.7);
  //lineSystem = BABYLON.MeshBuilder.CreateLineSystem(null, { lines: _lines, instance: lineSystem });
  lineSystem.isPickable = false;
}

function changeCanvasTheme(color) {
  var ground = store.scene.getMaterialByName("ground1");
  switch (color) {
    case "defaultTheme":
      store.newScene.clearColor = new BABYLON.Color3(
        240 / 255,
        240 / 255,
        240 / 255
      );
      ground.material.diffuseColor = new BABYLON.Color3(
        190 / 255,
        196 / 255,
        190 / 255
      );
      break;
    case "light":
      store.newScene.clearColor = new BABYLON.Color3(
        240 / 255,
        240 / 255,
        240 / 255
      );
      ground.material.diffuseColor = new BABYLON.Color3(
        190 / 255,
        196 / 255,
        190 / 255
      );
      break;
    case "dark":
      store.newScene.clearColor = new BABYLON.Color3(
        240 / 255,
        240 / 255,
        240 / 255
      );
      ground.material.diffuseColor = new BABYLON.Color3(
        190 / 255,
        196 / 255,
        190 / 255
      );
      break;
    case "default":
      store.newScene.clearColor = new BABYLON.Color3(
        240 / 255,
        240 / 255,
        240 / 255
      );
      ground.material.diffuseColor = new BABYLON.Color3(
        190 / 255,
        196 / 255,
        190 / 255
      );
      break;
  }
}

function sceneReInit() {
  store.newScene.gravity = new BABYLON.Vector3(0, -0.9, 0);
  store.newScene.collisionsEnabled = true;
  showAxis(5000);
  showAxis(-5000);

  // // ADD Ground and checkCollisions
  var ground = BABYLON.Mesh.CreateGround(
    "ground1",
    10000,
    10000,
    4,
    store.newScene
  );
  ground.material = new BABYLON.StandardMaterial("texture1", store.newScene);
  ground.material.alpha = 0.4;
  ground.material.diffuseColor = new BABYLON.Color3(0.678, 1.0, 0.184);
  ground.material.specularColor = new BABYLON.Color3(0, 0, 0);
  ground.reflectionFresnelParameters = new BABYLON.FresnelParameters();
  ground.reflectionFresnelParameters.isEnabled = false;
  ground.type = "ground";
  ground.isPickable = false;
  ground.optimize(10000);

  var camera3 = new BABYLON.ArcRotateCamera(
    "ArcRotateCamera3",
    0.5,
    0,
    15,
    BABYLON.Vector3.Zero(),
    store.newScene
  );
  store.newScene.activeCamera = camera3;
  // store.newScene.activeCamera.attachControl(canvas, true, false);
  store.newScene.activeCamera.setPosition(new BABYLON.Vector3(-30, 30, 45));
  store.newScene.activeCamera.lowerRadiusLimit = 0.0002;
  store.newScene.activeCamera.inertia = 0.42;
  store.newScene.activeCamera.panningSensibility = 40;
  store.newScene.activeCamera.inertialPanningX = 0.0001;
  store.newScene.activeCamera.inertialPanningY = 0.0001;
  store.newScene.activeCamera.panningInertia = 0.2;
}

var axes = [];

var showAxis = function (size) {
  var axisX, axisY, axisZ;
  if (size > 0) {
    axisX = BABYLON.Mesh.CreateLines(
      "axisX",
      [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0)],
      store.newScene
    );
    axisX.color = new BABYLON.Color3(1, 0, 0);
    axisY = BABYLON.Mesh.CreateLines(
      "axisY",
      [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0)],
      store.newScene
    );
    axisY.color = new BABYLON.Color3(0, 1, 0);
    axisZ = BABYLON.Mesh.CreateLines(
      "axisZ",
      [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, -size)],
      store.newScene
    );
    axisZ.color = new BABYLON.Color3(0, 0, 1);
  } else {
    axisX = BABYLON.Mesh.CreateDashedLines(
      "axisX",
      [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0)],
      store.newScene
    );
    axisX.color = new BABYLON.Color3(1, 0, 0);
    axisY = BABYLON.Mesh.CreateDashedLines(
      "axisY",
      [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0)],
      store.newScene
    );
    axisY.color = new BABYLON.Color3(0, 1, 0);
    axisZ = BABYLON.Mesh.CreateDashedLines(
      "axisZ",
      [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size)],
      store.newScene
    );
    axisZ.color = new BABYLON.Color3(0, 0, 1);
  }

  let type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;

  axisX.type = type;
  axisY.type = type;
  axisZ.type = type;

  // let position = -projectProperties.properties.plinthHeightProperty.getValue();
  let position = 0;

  axisX.position.y = position;
  axisY.position.y = position;
  axisZ.position.y = position;

  axes.push(axisX, axisY, axisZ);
};

function setupLighting() {
  // var light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, -1, 0), store.scene);
  // light.position = new BABYLON.Vector3(1000, 1500, 1000);
  // light.intensity = 0.7;
  // light.specular = new BABYLON.Color3(0,0,0);
  // var light3 = new BABYLON.HemisphericLight('light3', new BABYLON.Vector3(0, 1, 0), store.scene);
  // light3.position = new BABYLON.Vector3(1000, -1500, 1000);
  // light3.intensity = 0.7;
  // light3.specular = new BABYLON.Color3(0,0,0);
  // var light1 = new BABYLON.DirectionalLight("light1", new BABYLON.Vector3(-1, -1, 1), store.scene);
  // light1.position = new BABYLON.Vector3(50000, 20000, 10000);
  // light1.intensity = 1.0;
  // light1.specular = new BABYLON.Color3(0,0,0);

  // create a basic lighting scenario with two hemispherical lights to simulate daylight atmoshphere
  let light1 = new BABYLON.HemisphericLight(
    "light1",
    new BABYLON.Vector3(0, -1, 0),
    store.scene
  );
  light1.position = new BABYLON.Vector3(1000, 1500, 1000);
  light1.intensity = 0.7;
  light1.diffuse = new BABYLON.Color3(0.77, 0.77, 0.77);
  // light1.diffuse = BABYLON.Color3.White();
  light1.specular = BABYLON.Color3.Black();
  light1.diffuseColor = new BABYLON.Color3(0.88, 0.88, 0.88);
  light1.groundColor = BABYLON.Color3.White();

  let light2 = new BABYLON.HemisphericLight(
    "light2",
    new BABYLON.Vector3(0, 1, 0),
    store.scene
  );
  light2.position = new BABYLON.Vector3(1000, -1500, 1000);
  light2.intensity = 0;
  // light2.diffuse = BABYLON.Color3.White();
  light2.specular = BABYLON.Color3.Black();
  light2.groundColor = BABYLON.Color3.White();
  // light2.groundColor = new Color3(0, 0, 0);


  // store.scene.createDefaultLight(true);
    // store.scene.createDefaultEnvironment();

  var dirLight = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(0, -1, 0), store.scene);
  dirLight.position = new BABYLON.Vector3(150, 50, -150);
  dirLight.direction = new BABYLON.Vector3(0.5, -1, 0.5 );
  dirLight.specular = BABYLON.Color3.Black();
  dirLight.intensity = 0.7;
  dirLight.shadowEnabled = false;

  // var dirLight2 = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(0, 0, 0), store.scene);
  // // dirLight2.direction = new BABYLON.Vector3(0.5, -1, 0.5 );
  // dirLight2.diffuse = new BABYLON.Color3(0.3, 0.3, 0.3);
  // dirLight2.specular = BABYLON.Color3.Black();
  // dirLight2.intensity = 0.25;
  // dirLight2.shadowEnabled = false;

  // store.scene.registerBeforeRender(function () {
  //   dirLight2.position = store.scene.activeCamera.position;
  //   dirLight2.setDirectionToTarget(new Vector3(0, 0, 0));
  // });

  // store.scene.createDefaultEnvironment();

  // ---- studio lighting lizard example ----
  // var light = new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, 1, 0), store.scene);
	// light.diffuse = new BABYLON.Color3(1, 0.93, 0.69);
	// light.specular = new BABYLON.Color3(0, 0, 0);
	// light.groundColor = new BABYLON.Color3(0.031, 0.031, 0.125);
  // light.intensity = 2;

  // var light2 = new BABYLON.SpotLight("spotLight", new BABYLON.Vector3(-50, 50, 50), new BABYLON.Vector3(1, -1, -1), Math.PI / 2, 2, store.scene);
	// light2.diffuse = new BABYLON.Color3(1, 0.66, 0.36);
	// light2.specular = new BABYLON.Color3(0, 0, 0);
  // // light2.setDirectionToTarget(new Vector3(0, 0, 0));
  // light2.intensity = 2;

  // store.scene.registerBeforeRender(function () {
  // //   // dirLight2.position = store.scene.activeCamera.position;
  //   light2.position.x = store.scene.activeCamera.position.x + 10;
  //   light2.position.y = store.scene.activeCamera.position.y + 10;
  //   light2.position.z = store.scene.activeCamera.position.z + 10;
  //   // let target = -light2.position.clone();
  //   console.log("target", light2.position.x + 10, light2.position.y + 10);
  //   let target = new BABYLON.Vector3(-light2.position.x, -light2.position.y, -light2.position.z)
  //   light2.setDirectionToTarget(target);

  // });

  // light setup 3 start ---

  // different lighting setup based on point and directional lights
  // var light1 = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(1, 1, 0), store.scene);
  // light1.intensity = 0.3;
  // light1.diffuse = new BABYLON.Color3(0.77, 0.77, 0.77);

  // var light2 = new BABYLON.PointLight("light2", new BABYLON.Vector3(0, 1, 0), store.scene);
	// light2.diffuse = new BABYLON.Color3(1, 0, 0);
	// light2.specular = new BABYLON.Color3(0, 1, 0);

  // store.scene.registerBeforeRender(function () {
  //   light2.position.x = store.scene.activeCamera.position.x;
  //   light2.position.y = store.scene.activeCamera.position.y;
  //   light2.position.z = store.scene.activeCamera.position.z;
  // });

  // --- light setup 3 end ---

  // A directional light is put for furniture
  // Properties related to this light are changed in furniture.ds.js
  // let light3 = new BABYLON.DirectionalLight(
  //   "light3",
  //   new BABYLON.Vector3(-1, -1, 1),
  //   store.scene
  // );
  // light3.position = new BABYLON.Vector3(50000, 20000, 10000);
  // light3.intensity = 0;
  // light3.specular = new BABYLON.Color3(0, 0, 0);
  // light3.includedOnlyMeshes

  // shadowGenerator = new BABYLON.ShadowGenerator(1024, light1);
  // shadowGenerator.bias = 0.0000001;
  // shadowGenerator.useBlurVarianceShadowMap = true;
  // shadowGenerator.useExponentialShadowMap d= false;

  // shadowGenerator.useBlurExponentialShadowMap = true;
  // light1.shadowMinZ = 1;
  // light1.shadowMaxZ = 2500;
  // shadowGenerator.depthScale = 2500;
  // shadowGenerator.bias = 0.001;
}

/* eslint-disable */
function globalOffSet(args) {
  if (args.structure) {
    var bbinfo = computeStructureBounds(0);
    var structure_offset = bbinfo.diagonalLength;
    args[0].position.x += args[0].structure * structure_offset;
  }
}
/* eslint-enable */

function initModelDelta(wall_mats, wall_mat, structure, floorkey_data) {
  var promLoad = new Promise(function (resolve, reject) {
    if (store.fplan_mode != "True") {
      createMassBlocksForStructure(floorkey_data, structure);
      // updateModifications();
      // var mat_name = "wall_mat";
      // var door_data2 = generateDoorData(floorkey_data['doors']['pols']);
      // drawDoorBlocks(door_data2, store.floorkey_data['doors']['type'], mat_name, 36 * unit_scale);
      // var window_data1 = generateWindowData(floorkey_data['windows']['pols']);
      // drawWindowBlocks(window_data1, store.floorkey_data['windows']['type'], mat_name, 36 * unit_scale, 36 * unit_scale);
    } else {
      var mat_name = "wall_mat";
      var mat = store.scene.getMaterialByName(mat_name);
      createMassBlocksForStructure(floorkey_data, structure);
      store.angular.element(function () {
        createRooms();
        getDoorDataFplan();
        getWindowDataFplan();
      });
      //createWallBlocks(floorkey_data['walls'], mat_name);
      // createFloor(floorkey_data['walls'], "floor_tile");
      // createDoors(floorkey_data['doors'], mat_name, true);
      // createWindows(floorkey_data['windows'], mat_name, true);
    }
    resolve();
  });

  promLoad
    .then(function (value) {
      //console.log("Promise then execution");
      // store.canvas.addEventListener("mousewheel", scrollZoom);
      updateButtonColor("wireframe");
      updateButtonColor("edit");
      try {
        onCamRot();
      } catch (e) {
        console.log("ERROR", e);
      }
      onSolid();

      if (store.directWall) {
        $("#mass_mode").attr("checked", false);
        var mat_name = "wall_mat";
        var mat = store.scene.getMaterialByName(mat_name);
        store.angular.element(function () {
          createRooms();
          getDoorDataFplan();
          getWindowDataFplan();
        });
        store.directWall = false;
      }
    })
    .then(function (value) {
      // setTimeout(updateModifications, 1000);
    });
}

function getAngularScope() {
  return new Promise((resolve) => {
    setTimeout(() => {
      var scope = store.angular.element(appElement);
      scope = scope.scope();
      resolve(scope);
    }, 1000);
  });
}

function get_floorkey_details(floorKey, id) {
  let details = floorKey.split("_");

  if (details.length === 1) {
    return { project_id: details[0], structure_id: id + "0", level_id: 0 };
  } else {
    return {
      project_id: details[0],
      structure_id: id + details[1].toString(),
      level_id: details[2],
    };
  }
}

async function drawInitialModel(resetMass = false) {
  return;
  // eslint-disable-next-line no-unreachable
  var wall_mats = loadMaterials(store.mat_paths_common.walls, store.scene);
  var wall_mat = loadWallMaterial();
  // var wall_mats = wall_mat;
  getPlansData();
  const structureCollection = StructureCollection.getInstance();

  let defaultStructure = structureCollection.addStructure("default");
  //structure to assign to stray objects, like the sketch mode solids, whose fate is not yet decided.
  defaultStructure.addLevel(0, 100); // 0 to 1 was giving problems

  let id = Math.random().toString(36).substr(2, 6) + "_";
  //var global_proj_levels = StructureCollection.getInstance().getStructures()["p6mrjn_0"].getAllLevels();
  await loadFloorMaterial();
  await loadCeilingMaterial();

  for (let obj in store.floorkey_details) {
    if (
      store.canvasState[obj] &&
      !resetMass &&
      store.canvasState[obj] !== "Same as first"
    ) {
      store.storeysDS = JSON.parse(store.canvasState[obj])["storeys"];
      break;
    }
  }

  var index = "";
  var max = 0;
  let newObjects = [];
  let all_floorkeys = [];
  for (let obj in store.floorkey_details) {
    let fl_details = get_floorkey_details(obj, id);
    store.floorkey_details[obj].fl_details = fl_details; //will be u`sed in massModeling to assign level
    // console.log(floorkey_details);
    // console.log(canvasState[obj]);
    if (!all_floorkeys) {
      const structure = structureCollection.addStructure(
        fl_details.structure_id
      );
      setActiveLayerAndRecord(
        structure.getLayerData().addLayer("default", fl_details.structure_id)
      );
      //console.log("creating first store.scene -" + obj);
      initModelDelta(
        wall_mats,
        wall_mat,
        structure,
        store.floorkey_details[obj]
      );
    }
    // else if(all_floorkeys && !all_floorkeys.includes(obj)){
    //     const structure = structureCollection.addStructure(fl_details.structure_id);
    //     initModelDelta(wall_mats, wall_mat, structure, store.floorkey_details[obj]);
    // }
    else if (store.canvasState[obj] && !resetMass) {
      // recreateSceneInit(JSON.parse(canvasState[obj])); // find max length of db object
      if (max < store.canvasState[obj].length) {
        index = obj;
        max = store.canvasState[obj].length;
      }
    } else {
      //console.log("creating delta store.scene force -" + obj);
      newObjects.push(obj);
      const structure = structureCollection.addStructure(
        fl_details.structure_id
      );
      setActiveLayerAndRecord(
        structure.getLayerData().addLayer("default", fl_details.structure_id)
      );
      initModelDelta(
        wall_mats,
        wall_mat,
        structure,
        store.floorkey_details[obj]
      );
    }
  }
  if (max !== 0) {
    var data = JSON.parse(store.canvasState[index]);
    //console.log("loading store.scene -" + index);
    // recreateSceneInit(JSON.parse(JSON.parse(canvasState[index])));
    /*
        Todo: Fetch data from indexDB and restore the store.scene
        check if cache data exists else use the above statement
         */
    // recreateMaterials(data);
    // let structures = StructureCollection.getInstance();
    // store.angular.element(function () {
    //     structures.recreateSceneStructure(data);
    // });
    //  if(clientDb.getLastLocalCanvas("canvasState"+floorkey)){
    //       let localData = clientDb.getLastLocalCanvas("canvasState"+floorkey);
    //       localData.then((structure) => {
    //           if(structure){
    //              let str = JSON.parse(structure);
    //              reloadScene(str).then((data) =>{
    //                  // updateModifications();
    //                  addPreviousMeshesToStructures(str);
    //                  changeToIsoViewCamera();
    //              });
    //           }
    //           else{
    //              let str = JSON.parse(canvasState[index]);
    //              reloadScene(str).then((data) =>{
    //                  // updateModifications();
    //                  addPreviousMeshesToStructures(str);
    //                  changeToIsoViewCamera();
    //              });
    //           }
    //       });
    //  }
    //  else {
    //      reloadScene(data);
    //      // updateModifications();
    // }
    let status = reloadScene(data);
    status.then((status) => {
      updateModifications();
      offsetStructures();
      // store.canvas.addEventListener("mousewheel", scrollZoom, false);
      onSolid();
      updateLevelsAngularUI();
    });
  }
  // TODO : Run updateModifications() after model is loaded (Avoid setTimeOut())
  // eslint-disable-next-line no-unreachable
  const initModelPromise = updateLevelsAngularUI().then(
    updateModifications,
    null
  );
  // const promiseUM = updateModifications();

  // setTimeout(updateModifications,6000);
  offsetStructures();
  store.canvas.addEventListener("mousewheel", scrollZoom, false);
  // setTimeout(changeToIsoViewCamera,600); // let the camera and bound get values
  // ZoomAll();

  onSolid();

  //updateLevelsAngularUI();
  // const promiseULAU = promiseUM.then(updateLevelsAngularUI);
  //  return new Promise(function(resolve, reject){
  //      resolve(updateLevelsAngularUI());
  //  })
  // const promiseLast = initModelPromise.then(clickAddLevel, null);
}

// function clickAddLevel(){
//     let elem = document.getElementById('add_new_level_button');
//     elem.click();
//     layerView.generateLayerData();
//     return elem;
// }
// const initModelPromise = drawInitialModel().then(updateLevelsAngularUI, rejectPromise);

function addPreviousMeshesToStructures(str) {
  let tempStr = [];
  let strs = JSON.parse(store.canvasState[store.floorkey]);
  store.scene.meshes.forEach(function (mesh) {
    if (mesh.structure_id) {
      if (!str["structures"][mesh.structure_id]) {
        if (!tempStr.includes(mesh.structure_id)) {
          tempStr.push(mesh.structure_id);
        }
        let meshObj = mesh.getSnaptrudeDS();
        for (let st in strs["structures"]) {
          let st_no = st.split("_");
          let mesh_st_no = mesh.structure_id.split("_");
          if (st_no[1] === mesh_st_no[1]) {
            let baseStr =
              StructureCollection.getInstance().getStructureById(st);
            let levelObj = baseStr.getLevel(
              meshObj.level_low,
              meshObj.level_high
            );
            if (!levelObj) {
              levelObj = baseStr.addLevel(
                meshObj.level_low,
                meshObj.level_high
              );
            }
            levelObj.addObjectToLevel(meshObj);
            meshObj.mesh.structure_id = st;
            meshObj.structure_id = st;
            console.log(levelObj);
            break;
          }
        }
        console.log(meshObj);
      }
    }
  });
  for (let i in tempStr)
    StructureCollection.getInstance().deleteStructure(tempStr[i]);
}
async function reloadScene(data) {
  let structures = StructureCollection.getInstance();
  let resultMats = await recreateMaterialsA(data);
  let resultMultiMats = await recreateMultiMaterialsA(data);
  if (resultMats === "matsCreated" && resultMultiMats === "multiMatsCreated") {
    let resultRecreateScene = await recreateSceneStructureA(data, structures);
    return resultRecreateScene;
  }
  return true;
}

function recreateSceneStructureA(data, structures) {
  return new Promise((resolve) => {
    //store.angular.element(function () {
    structures.recreateSceneStructure(data, false, false);
    //});
    resolve("recreatedScene");
  });
}
function recreateMaterialsA(data) {
  return new Promise((resolve) => {
    recreateMaterials(data);
    resolve("matsCreated");
  });
}

function recreateMultiMaterialsA(data) {
  return new Promise((resolve) => {
    recreateMultiMaterials(data);
    resolve("multiMatsCreated");
  });
}

function executeWhenReady() {
  var meshLength = store.newScene.meshes.length;
  var textureCountMax = 15;
  // updateModifications();
  // store.canvas.addEventListener("mousewheel", scrollZoom);
  // updateButtonColor("wireframe");
  // updateButtonColor("edit");
  // onCamRot();
  // onSolid();
  // offsetStructures();
  // updateModifications();
  // ZoomAll();
  //materialScheduleView.generateMatSchedule();
  // getRoomTypes();

  if (!store.userSettingsInStructure.textureDetail) {
    store.userSettingsInStructure.textureDetail = "texture";
    _changeTextureDetail(store.userSettingsInStructure.textureDetail);
  }

  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  $scope.$apply(function () {
    ScopeUtils.loadLibraryTypesInTabs(store.gen_mats, "material");
    ScopeUtils.loadLibraryTypesInTabs(store.gen_objs, "furniture");
    // $scope.addMats(gen_mats);
    $scope.addFenestration(store.gen_objs, false, true);
    // $scope.addInteriors(gen_objs);
    $scope.generateLayerView();
  });

  $("#interiors").click(function () {
    // if (store.isProduction) {
    window.analytics.track("auto interiors clicked");
    window.analytics.track("clicked onAutoInteriors");
    //console.logInfo("auto interiors clicked");
    // }
    genInteriors2(store.room_pols, store.room_types, store.room_names);
    setTimeout(updateModifications, 5000);
  });

  $("#roof_mode").change(function () {
    if ($("#roof_mode").prop("checked")) {
      $("#roof_status").html("Roof Shown");
      showAllRoofs();
    }
    if (!$("#roof_mode").prop("checked")) {
      $("#roof_status").html("Roof Hidden");
      hideAllRoofs();
    }
  });

  $(".mass_mode").click(function () {
    // ("mass mode activated");
    var $scope = store.angular.element(appElement).scope();
    $scope = $scope.$$childHead;
    if (store.scene.getMeshByName("textPlane")) {
      store.scene.getMeshByName("textPlane").dispose();
    }

    if (this.name === "reset") {
      //window.analytics.track("reset to mass mode");
      updateButtonColor("wireframe");
      send_scene_data("on", "mass_mode");
      removeRooms();
      if ($scope.roof_shown === false) {
        $("#room_shown").click();
        setTimeout(function () {
          $scope.$apply(function () {
            $scope.roof_shown = true;
            $scope.hide_object.hide_object_activation = true;
          });
        }, 2000);
      }

      // removeObjects();
      createMassBlocks();
      setTimeout(updateLevelsAngularUI, 50);
      var mat_name = "wall_mat";
      var door_data2 = generateDoorData(store.door_data[0]);
      //drawDoorBlocks(door_data2, store.door_data[1], mat_name, 36 * unit_scale);
      var window_data1 = generateWindowData(store.window_data[0]);
      //drawWindowBlocks(window_data1, store.window_data[1], mat_name, 36 * unit_scale, 36 * unit_scale);
      onSolid();
      $scope.$apply(function () {
        $scope.InteriorButtonDisp = false;
      });
      // updateModifications();
      handleEscapeKeyEvent();
      store.roofsInScene = [];
      updateRoofAccordion();
    }

    if (this.name === "create") {
      //window.analytics.track("building mode");
      updateButtonColor("solid");
      // offsetRoomPolygons();
      // var canState = {meshData: getMeshData2(), can_mode: $('#mass_mode').prop('checked')};
      // console.log(canState);
      // wall_pols = createRoomPolygons(canState.meshData.coord);
      // removeObjects();

      //console.info("Before");
      handleEscapeKeyEvent();
      createRooms()
        .then(() => {
          //console.info("After");
          var mat_name = "wall_mat";
          var door_data2 = generateDoorData(store.door_data[0]);
          //drawDoorBlocks(door_data2, store.door_data[1], mat_name, 36 * unit_scale);
          var window_data1 = generateWindowData(store.window_data[0]);
          //drawWindowBlocks(window_data1, store.window_data[1], mat_name, 36 * unit_scale, 36 * unit_scale);

          getDoorDataFplan();
          getWindowDataFplan();
          if (store.fplan_mode == "True") {
            getDoorDataFplan();
            getWindowDataFplan();
          }
          $scope.$apply(function () {
            $scope.InteriorButtonDisp = false;
          });
          onSolid();
        })
        .finally(() => {
          if (store.$scope.isTwoDimension) {
            hideAllRoofs();
            setLayerTransperancy();
          }
          updateModifications();
        });

      // TODO : Run updateModifications() after model is loaded (Avoid setTimeOut())
      // setTimeout(updateModifications,6000);
      // if($('#roof_mode').prop('checked')){
      //     $('#roof_status').html('Roof Shown');
      //     showAllRoofs();
      // }
      // else if(!$('#roof_mode').prop('checked')){
      //     $('#roof_status').html('Roof Hidden');
      //     hideAllRoofs();
      // }
      handleEscapeKeyEvent();
      // removeRooms();
    }
    // updateLevelsAngularUI();
  });

  var createBuilding = function () {
    //window.analytics.track("building mode");
    updateButtonColor("solid");
    // offsetRoomPolygons();
    // var canState = {meshData: getMeshData2(), can_mode: $('#mass_mode').prop('checked')};
    // console.log(canState);
    // wall_pols = createRoomPolygons(canState.meshData.coord);
    // removeObjects();

    //console.info("Before");
    createRooms(true)
      .then(() => {
        //console.info("After");
        var mat_name = "wall_mat";
        var door_data2 = generateDoorData(store.door_data[0]);
        //drawDoorBlocks(door_data2, store.door_data[1], mat_name, 36 * unit_scale);
        var window_data1 = generateWindowData(store.window_data[0]);
        //drawWindowBlocks(window_data1, store.window_data[1], mat_name, 36 * unit_scale, 36 * unit_scale);

        getDoorDataFplan();
        getWindowDataFplan();
        if (store.fplan_mode == "True") {
          getDoorDataFplan();
          getWindowDataFplan();
        }
        $scope.$apply(function () {
          $scope.InteriorButtonDisp = false;
        });
        // onSolid();
      })
      .finally(() => {
        if (store.$scope.isTwoDimension) {
          // hideAllRoofs();
          // setLayerTransperancy();
        }
        if ($scope.areasUpdateNeeded) {
          areaView.generateArea();
        }

        updateModifications();

        $scope.sortTools("bim_building_position");
        handleEscapeKeyEvent();
      });

    // TODO : Run updateModifications() after model is loaded (Avoid setTimeOut())
    // setTimeout(updateModifications,6000);
    // if($('#roof_mode').prop('checked')){
    //     $('#roof_status').html('Roof Shown');
    //     showAllRoofs();
    // }
    // else if(!$('#roof_mode').prop('checked')){
    //     $('#roof_status').html('Roof Hidden');
    //     hideAllRoofs();
    // }
  };

  if (document.getElementById("plus")) {
    document.getElementById("plus").onclick = function () {
      let num = parseInt($scope.duplicateStoreysUpNum);

      if (!isNaN(num)) {
        StoreyMutation.setStoreyUnique(ScopeUtils.isStoreyUpUnique());
        StoreyMutation.duplicateStoreys(store.activeLayer.structure_id, 1, num);
      }
    };
  }

  if (document.getElementById("minus")) {
    document.getElementById("minus").onclick = function () {
      let num = parseInt($scope.duplicateStoreysDownNum);

      if (!isNaN(num)) {
        StoreyMutation.setStoreyUnique(ScopeUtils.isStoreyDownUnique());
        StoreyMutation.duplicateStoreys(store.activeLayer.structure_id, 0, num);
      }
    };
  }

  if (document.getElementById("addStorey")) {
    document.getElementById("addStorey").onclick = function () {
      let num = parseInt($scope.addStoreysNum);
      let storeyVal = null;
      for (let i = 0; i < num; i++) {
        let storey = StoreyMutation.createNewBlankStorey(
          store.activeLayer.structure_id,
          1
        );
        storeyVal = storey.value;
        let storeyUI = storeyView.getStoreyData(storey);
        // $scope.level_mode.push(false);
        // $scope.layer_vis.push(false);
        $scope.storeysUiData.unshift(storeyUI);
      }
      layerView.selectLayer("wall", store.activeLayer.structure_id, storeyVal);
    };
  }
  if (document.getElementById("minusStorey")) {
    document.getElementById("minusStorey").onclick = function () {
      let num = parseInt($scope.minusStoreysNum);
      let storeyVal = null;
      for (let i = 0; i < num; i++) {
        let storey = StoreyMutation.createNewBlankStorey(
          store.activeLayer.structure_id,
          0
        );
        storeyVal = storey.value;
        let storeyUI = storeyView.getStoreyData(storey);
        // $scope.level_mode.push(false);
        // $scope.layer_vis.push(false);
        $scope.storeysUiData.push(storeyUI);
      }
      layerView.selectLayer("wall", store.activeLayer.structure_id, storeyVal);
    };
  }

  // //// for rotating clockwise and counter clockwise
  // document.getElementById("cw").onclick = function cwrotate() {
  //     var click1 = function (mesh) {
  //         if (mesh.state == "on") {
  //             mesh.rotation.y += Math.PI / 18;
  //             // 	mesh.rotate(BABYLON.Axis.Y , Math.PI/18, BABYLON.Space.WORLD);
  //         }
  //     }
  //     // var count = 0;
  //     // while (count < store.newScene.meshes.length) {
  //     //     click1(newScene.meshes[count]);
  //     //     count++;
  //     // }
  //     updateModifications();
  // };

  // document.getElementById("ccw").onclick = function ccwrotate() {
  //     var click1 = function (mesh) {
  //         if (mesh.state == "on") {
  //             // 	mesh.computeWorldMatrix();
  //             // 	var matrix = mesh.getWorldMatrix();
  //             // 	var pos1 = mesh.getPositionExpressedInLocalSpace();
  //             // 	mesh.rotate(BABYLON.Axis.Y , -Math.PI/18, BABYLON.Space.WORLD);
  //             // 	mesh.computeWorldMatrix();
  //             // 	var matrix = mesh.getWorldMatrix();
  //             mesh.rotation.y -= Math.PI / 18;
  //
  //         }
  //     }
  //     // var count = 0;
  //     // while (count < store.newScene.meshes.length) {
  //     //     click1(newScene.meshes[count]);
  //     //     count++;
  //     // }
  //     updateModifications();
  // };
  for (var q = 0; q < store.scene.meshes.length; q++) {
    var mesh = store.scene.meshes[q];
    if (mesh._properties) {
      if (mesh._properties._components) {
        $scope.updateMaterialProperty(mesh._properties._components._layers);
      }
    }
  }
  // computeAreaMats()
}

var removeObjects = function () {
  let len = store.newScene.meshes.length;
  var all_meshes = [];
  let mesh;
  for (let p = 0; p < len; p++) {
    mesh = store.newScene.meshes[p];
    if (
      mesh.name.indexOf("boxScale") === -1 &&
      mesh.name.indexOf("axis") === -1 &&
      mesh.name.indexOf("ground") === -1
    ) {
      all_meshes.push(mesh.name);
    }
  }
  for (let p = 0; p < all_meshes.length; p++) {
    var tmesh = store.scene.getMeshByName(all_meshes[p]);
    if (tmesh) {
      let level = tmesh.getSnaptrudeLevel();
      level.removeObjectToLevel(tmesh.getSnaptrudeDS());
      tmesh.dispose();
    }
  }
};

var removeRooms = function () {
  let len = store.newScene.meshes.length;
  var all_meshes = [];
  let mesh;
  for (let p = 0; p < len; p++) {
    mesh = store.newScene.meshes[p];
    if (mesh.name !== undefined) {
      if (
        mesh.name.indexOf("boxScale") === -1 &&
        mesh.name.indexOf("axis") === -1 &&
        mesh.name.indexOf("ground") === -1
      ) {
        // if (types_of_rooms.indexOf(mesh.room_type) > -1) {
        all_meshes.push(mesh);
        // }
      }
    }
  }
  for (let p = 0; p < all_meshes.length; p++) {
    if (all_meshes[p].children) {
      all_meshes[p].children.forEach(function (child) {
        if (child) child.dispose();
      });
    }
    try {
      let level = all_meshes[p].getSnaptrudeLevel();
      level.removeObjectToLevel(all_meshes[p].getSnaptrudeDS());
    } catch (e) {
      console.log(e, "Non Snaptrude DS");
    }
    all_meshes[p].dispose();
  }
};

function selectStructure(sID, storeyVal) {
  // StructureCollection.getInstance().getStructures()[sID].hideNonActiveStoreyMeshes(storeyVal);
  // for(let i=0; i<meshes.length ; i++){
  //     if(meshes[i].storey == storeyVal+1){
  //         meshes[i].visibility = 1;
  //     }else{
  //         meshes[i].visibility = 0.5;
  //     }
  // }
  goIntoTwoD();
}
/* BASIC GRID */
// function drawGrid(a){
// 	let _lines = [];
// 	let b = -0.01;
// 	let c = 40;
// 	for (var i1 =-a; i1 <= a; i1+=c) {
// 			_lines.push([new BABYLON.Vector3(i1, b, -a), new BABYLON.Vector3(i1, b, a)]);
// 	}
// 	for (var i2 =-a; i2 <= a; i2+=c) {
// 		_lines.push([new BABYLON.Vector3(-a, b, i2), new BABYLON.Vector3(a, b, i2)]);
// 	}
// 	let lineSystem = BABYLON.MeshBuilder.CreateLineSystem("lineSystem", {lines: _lines}, store.scene);
// 	lineSystem.color = new BABYLON.Color3(0.5, 0.5, 0.5);
// 	//lineSystem = BABYLON.MeshBuilder.CreateLineSystem(null, { lines: _lines, instance: lineSystem });
// 	lineSystem.isPickable = false;
// 	lineSystem.position.x = a;
// 	lineSystem.position.z = -a;
//
// }

function createGrid(toggleState) {
  if (!store.userSettingsInStructure.gridEnabled)
    store.userSettingsInStructure.gridEnabled = false;
  toggleState = store.$scope.isTwoDimension
    ? store.userSettingsInStructure.gridEnabled
    : false;
  //let unitVal = DisplayOperation.convertToDefaultDimension(1);
  let unitVal = userSetBIMPropertiesHandler.getGridUnit();
  //if(!major)major = DisplayOperation.getOriginalDimension('100');
  let gridPlane = store.scene.getMeshByName("layerRefPlane");
  gridPlane.visibility = toggleState ? 1 : 0;
  let grid = new BABYLON.GridMaterial("grid", store.scene);
  grid.mainColor = new BABYLON.Color3(1, 1, 1);
  grid.lineColor = new BABYLON.Color3(0, 0, 0);
  //console.log("grid -- >", grid);
  grid.gridRatio = unitVal;
  grid.majorUnitFrequency = 10;
  grid.minorUnitVisibility = 0.7;
  gridPlane.material = grid;
  gridPlane.isPickable = false;
  grid.opacity = 0.9;
  grid.freeze();
}
function initSketchPlan() {
  let refPlane;
  let pos = store.scene.getMeshByID("twoPlane")
    ? store.scene.getMeshByID("twoPlane").position.y
    : store.scene.getMeshByID("ground1").position.y;
  if (!store.scene.getMeshByName("layerRefPlane")) {
    // refPlane = BABYLON.Mesh.CreatePlane("layerRefPlane", 10000, store.newScene);
    //let pos = (scene.getMeshByID("twoPlane")) ? store.scene.getMeshByID("twoPlane").position.y : store.scene.getMeshByID("ground1").position.y;
    refPlane.visibility = 0.1;
    refPlane.rotation = store.scene.getMeshByID("ground1").rotation;
    refPlane.rotation.x = Math.PI / 2;
    refPlane.position = store.scene.getMeshByID("ground1").position;
    refPlane.position.y = pos;
  } else {
    refPlane = store.scene.getMeshByName("layerRefPlane");
    refPlane.position.y = pos;
  }
  return refPlane;
  // refPlane.visibility = 0.1;
  // refPlane.rotation = store.scene.getMeshByID("ground1").rotation;
  // refPlane.rotation.x = Math.PI / 2;
  //return refPlane;
}
/***         INDICATION FOR CURRENT STOREY    ***/

// function promptActiveLayer(storeyNumber){
//     let text1 = new BABYLON.GUI.TextBlock();
//     text1.text = null;
//         if (storeyNumber) {
//             text1.color = "red";
//             text1.fontSize = 18;
//             text1.top = "500px";
//             text1.left = "-800px";
//             text1.text = "Current storey: " + storeyNumber;
//             advancedTexture.addControl(text1);
//         } else {
//             text1.color = "red";
//             text1.fontSize = 18;
//             text1.top = "500px";
//             text1.left = "-800px";
//             text1.text = "Current storey: Not selected";
//             advancedTexture.addControl(text1);
//
//         }
// }

function setLayerTransperancy(
  mesh,
  movingFromOneStoreyToAnother,
  allInstances = true
) {
  return new Promise((resolve,reject) => {
    if (!store.activeLayer) return;
    let structures =
      StructureCollection.getInstance().getStructures()[
        store.activeLayer.structure_id
        ];

    if (mesh) {
      if (mesh.isAnInstance) {
        if (allInstances)
          setLayerTransperancy(
            mesh.sourceMesh,
            movingFromOneStoreyToAnother,
            allInstances
          );
        else _setPropertiesOfMesh(mesh);
      } else {
        _setPropertiesOfMesh(mesh);
        if (mesh.instances && allInstances) {
          mesh.instances.forEach(_setPropertiesOfMesh);
        }
      }
    } else {
      let movingFrom3Dto2D = false;
      let movingFrom2Dto3D = false;

      if (!movingFromOneStoreyToAnother) {
        if (store.$scope.isTwoDimension) movingFrom3Dto2D = true;
        else movingFrom2Dto3D = true;
      }

      if (movingFrom3Dto2D) {
        store.roofsVisibleIn3D.length = 0;
      }

      for (let i = 0; i < store.scene.meshes.length; i++) {
        let mesh = store.scene.meshes[i];

        if (nonDefaultMesh(mesh)) {
          if (!["void"].includes(mesh.type.toLowerCase())) {
            if (!mesh.originalScaling) {
              _setPropertiesOfMesh(mesh);
            }
          }
        }
      }

      if (movingFrom2Dto3D) {
        store.roofsVisibleIn3D.forEach((r) => (r.isVisible = true));
      }

      updateRoofAccordion();

      _handleSketches();
    }

    function _setPropertiesOfMesh(mesh) {
      function _makeMeshPartiallyVisible(mesh) {
        mesh.isVisible = true;
        mesh.edgesColor = new BABYLON.Color4(0.4, 0.4, 0.4, 0.3);
        mesh.edgesWidth = 2;
        if (mesh.material && !isMassPanelOrLinkedModel(mesh)) {
          mesh.material.alpha = 0.4;
        }
        // if ( mesh.material && mesh.material.subMaterials ) {
        //   mesh.material.subMaterials.forEach(eachSubMaterial => {
        //     eachSubMaterial.alpha = 0.4;
        //   })
        // }
        // since material is shared, it can't have two alpha values
      }

      function _makeMeshInvisible() {
        if (["door", "window"].includes(mesh.type.toLowerCase())) {
          mesh.getChildren().forEach((child) => {
            child.isVisible = false;
          });
        }
        mesh.isVisible = false;
      }

      function _makeMeshVisible2D(mesh) {
        let structure =
          StructureCollection.getInstance().getStructures()[
            store.activeLayer.structure_id
            ];
        let storeyCollection = structure.getStoreyData();
        let storey = storeyCollection.getStoreyByValue(mesh.storey);
        if (storey && !storey.hidden) {
          mesh.isVisible = true;
        }
        mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        if (
          ["staircase", "roof", "floorplan"].includes(mesh.type.toLowerCase())
        ) {
          mesh.edgesWidth = 2.0;
          if (mesh.material && mesh.material.alpha) {
            mesh.material.alpha = 1;
          }
        } else if (["door", "window"].includes(mesh.type.toLowerCase())) {
          mesh.disableEdgesRendering();
        }
          // } else if (["furniture"].includes(mesh.type.toLowerCase())) {
          //   let height = structures.getStoreyData().getAllStoreys()[store.activeLayer.storey].height;
          //   mesh.getSnaptrudeDS().clipAtStorey(height);
          //   mesh.edgesWidth = 7;
          //   if (mesh.material) {
          //     mesh.material.alpha = 0.8;
          //   }
        // }
        else {
          mesh.edgesWidth = Furniture.isMeshFurniture(mesh) ? 2 : 5;
          if (mesh.material && !isMassPanelOrLinkedModel(mesh)) {
            mesh.material.alpha = 0.8;
            if (
              mesh.type.toLowerCase() === "wall" &&
              mesh.material.subMaterials &&
              mesh.material.subMaterials[0].name !== "GLASS"
            ) {
              mesh.material.subMaterials[0].alpha = 0.6;
            }
          }
        }
      }

      function _makeMeshVisible3D(mesh) {
        let structure =
          StructureCollection.getInstance().getStructures()[
            store.activeLayer.structure_id
            ];

        if(!structure) return; // temporary fix
        let storeyCollection = structure.getStoreyData();
        let storey = storeyCollection.getStoreyByValue(mesh.storey);

        if (storey && !storey.hidden) {
          mesh.isVisible = true;
        }
        mesh.edgesWidth = mesh?.getSnaptrudeDS()?.importType ? 10 : 3;
        mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        if (["door", "window"].includes(mesh.type.toLowerCase())) {
          mesh.enableEdgesRendering(0.85, true);
        }
        // if (["furniture"].includes(mesh.type.toLowerCase())) {
        //   mesh.getSnaptrudeDS().clipAtStorey(0, true);
        // }
        if (isNeighborhoodBuilding(mesh)) {
          mesh.edgesWidth = 70;
        }
        if (mesh.material && !isMassPanelOrLinkedModel(mesh)) {
          if(mesh.material?.metadata?.hasAlpha){
            mesh.material.alpha = mesh.material?.metadata?.alpha
          }else{
            mesh.material.alpha = 1;
          }
          
          // if ( mesh.material && mesh.material.subMaterials ) {
          //   mesh.material.subMaterials.forEach(eachSubMaterial => {
          //     // if(eachSubMaterial.name !== "GLASS"){
          //     //   eachSubMaterial.alpha = 1;
          //     // }
          //     if( eachSubMaterial.metaData && eachSubMaterial.metaData.previousAlpha ){
          //       eachSubMaterial.alpha = eachSubMaterial.metaData.previousAlpha;
          //     }
          //     else{
          //       eachSubMaterial.alpha = 1;
          //     }
          //   })
          // }
          if (
            mesh.type.toLowerCase() === "wall" &&
            mesh.material.subMaterials &&
            mesh.material.subMaterials[0].name !== "GLASS"
          ) {
            mesh.material.subMaterials[0].alpha = 1;
          }
        }
      }

      function _makeRoofVisible3D(mesh) {
        mesh.edgesWidth = 3;
        mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        if (mesh.material) mesh.material.alpha = 1;
        mesh.isVisible = false;
      }

      if (
        store.newScene.activeCamera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA &&
        store.$scope.isTwoDimension
      ) {
        const sc = structures.getStoreyData();
        let stryData = sc.getStoreyByValue(store.activeLayer.storey - 1);
        // let currentStoreyToggle = store.activeLayer.hidden;
        let currentStorey = sc.getStoreyByValue(mesh.storey);
        let currentStoreyToggle = currentStorey?.hidden;
        let belowStoreyToggle = stryData ? stryData.hidden : false;
        if (
          !mesh.modifiedBySetLayerTransparency &&
          !mesh.isVisible &&
          mesh.type.toLowerCase() !== "roof"
        )
          return;
        // these are the meshes hidden by the user explicitly. They should stay as such.
        // But roofs on lower storey get visible by default.

        if (!movingFromOneStoreyToAnother) {
          if (mesh.type.toLowerCase() === "roof" && mesh.isVisible)
            store.roofsVisibleIn3D.push(mesh);
        }

        if (mesh.storey === store.activeLayer.storey && !currentStoreyToggle) {
          //do not make this ===
          // if (mesh.name.includes("twoPlane")) {
          //   _makeMeshVisible2D(mesh);
          // }
          // if (mesh.type.toLowerCase() === "roof"){
          //     _makeMeshPartiallyVisible(mesh);
          // }
          if (mesh?.name.includes("terrain")) {
            let _terrainLayer = currentStorey?.layerData.getLayerByName(
              mesh.layerName,
              mesh.storey
            );
            if (_terrainLayer && !_terrainLayer.hidden) {
              _makeMeshVisible2D(mesh);
            } else if (_terrainLayer && _terrainLayer.hidden) {
              _makeMeshInvisible(mesh);
            }
          } else if (isNeighborhoodBuilding(mesh) && !mesh.isVisible) {
            _makeMeshInvisible(mesh);
          } else if (mesh.type.includes("pdf")) {
            let _pdfLayer = currentStorey?.layerData.getLayerBylayerId(mesh.layer_id);
            if (_pdfLayer && !_pdfLayer.hidden) {
              _makeMeshVisible2D(mesh);
            }
          } else if (mesh.type !== "FloorPlan" && mesh.type !== "cadSketch") {
            _makeMeshVisible2D(mesh);
          }
        } else if (
          mesh.storey === store.activeLayer.storey - 1 &&
          !belowStoreyToggle
        ) {
          //do not make this ===
          if (mesh.name.includes("twoPlane")) {
            _makeMeshInvisible(mesh);
          }
          if (mesh.type.toLowerCase() === "roof") {
            _makeMeshInvisible(mesh);
          } else if (mesh.type.toLowerCase() === "furniture") {
            if (mesh?.getSnaptrudeDS()?.importType) {
              _makeMeshPartiallyVisible(mesh);
            } else {
              _makeMeshInvisible(mesh);
            }
          } else if (mesh.type !== "FloorPlan" && mesh.type !== "cadSketch") {
            _makeMeshPartiallyVisible(mesh);
          }
        } else if (store.activeLayer.storey - 1 === 0 && mesh.storey === -1 &&
          !belowStoreyToggle) {
          //do not make this ===
          if (mesh.name.includes("twoPlane")) {
            _makeMeshInvisible(mesh);
          }
          if (mesh.type.toLowerCase() === "roof") {
            _makeMeshInvisible(mesh);
          } else if (mesh.type.toLowerCase() === "furniture") {
            if (mesh?.getSnaptrudeDS()?.importType) {
              _makeMeshPartiallyVisible(mesh);
            } else {
              _makeMeshInvisible(mesh);
            }
          } else if (mesh.type !== "FloorPlan" && mesh.type !== "cadSketch") {
            _makeMeshPartiallyVisible(mesh);
          }
        } else {
          _makeMeshInvisible(mesh);
        }
        mesh.modifiedBySetLayerTransparency = true;
      } else if (mesh.modifiedBySetLayerTransparency) {

        let _storeyData = structures?.getStoreyData();
        let currentStorey = _storeyData?.getStoreyByValue(mesh?.storey);
        if (
          mesh.type === "FloorPlan" ||
          mesh.name.indexOf("sketchLine") === 0 ||
          mesh.type === "cadSketch"
        ) {
          _makeMeshInvisible(mesh);
        } else if (mesh.type.toLowerCase() === "roof") {
          if (currentStorey && !currentStorey.hidden) {
            _makeRoofVisible3D(mesh);
          }
        } else if (isNeighborhoodBuilding(mesh) && !mesh.isVisible) {
          _makeMeshVisible3D(mesh);
        } else if (mesh?.name.includes("terrain") && !mesh.isVisible) {
          _makeMeshVisible3D(mesh);
        } else if (
          mesh.type !== "FloorPlan" &&
          mesh.type.toLowerCase() !== "roof"
        ) {
          _makeMeshVisible3D(mesh);
        }

        delete mesh.modifiedBySetLayerTransparency;
      } else if (
        store.newScene.activeCamera.mode === BABYLON.Camera.PERSPECTIVE_CAMERA
      ) {
        // this condition applies when a new mesh is created in 3D with a material not on any other
        // mesh currently. Example - first CB in 3D, draw in 3D on an empty project

        // added to fix the bug - CB in 3D, go to 2D, undo, go to 3D, CB again and the walls are semi transparent

        if (mesh.material && !isMassPanelOrLinkedModel(mesh)) {
          if(mesh.material?.metadata?.hasAlpha){
            mesh.material.alpha = mesh.material?.metadata?.alpha;
          }else{
            mesh.material.alpha = 1;  
          }
          if (
            mesh.type.toLowerCase() === "wall" &&
            mesh.material.subMaterials &&
            mesh.material.subMaterials[0].name !== "GLASS"
          ) {
            mesh.material.subMaterials[0].alpha = 1;
          }
        }

        // condition : hide neighborhood layer in 2D , go to 3D, go to 2D, show neighborhood layer, go to 3D (edgeWidth != 70)
        if (isNeighborhoodBuilding(mesh) && mesh.isVisible) {
          _makeMeshVisible3D(mesh);
        }
        if (mesh?.getSnaptrudeDS()?.importType) mesh.edgesWidth = 10;
      }
    }
    function _handleSketches() {
    function _assignPropertiesToSketches(sketches, options = {}) {
      let visibility = options.visibility || 0;
      let color = options.color || new BABYLON.Color3(0, 0, 0);

      let isVisible = options.isVisible;
      if (isVisible === undefined) isVisible = false;

      for (let l = 0; l < sketches.length; l++) {
        if (
          store.newScene.activeCamera.mode !==
          BABYLON.Camera.ORTHOGRAPHIC_CAMERA
        ) {
          if (!_.isArray(sketches[l].mesh)) {
            sketches[l].mesh.isVisible = false;
          } else {
            sketches[l].mesh.forEach((m) => (m.isVisible = false));
          }
        } else {
          if (!_.isArray(sketches[l].mesh)) {
            sketches[l].mesh.isVisible = isVisible;
            sketches[l].mesh.visibility = visibility;
            sketches[l].mesh.color = color;
          } else {
            sketches[l].mesh.forEach((m) => {
              m.isVisible = isVisible;
              // m.visibility = visibility;
              m.color = color;
            });
          }
        }
      }
    }

    let allStoreysObject = structures.getStoreyData().getAllStoreys();
    if (!allStoreysObject) return;

    let activeStoreySketchOptions = {
      isVisible: true,
      visibility: 1,
      color: new BABYLON.Color3(0, 0, 0),
    };

    let storeyBelowSketchOptions = {
      isVisible: true,
      visibility: 0.4,
      color: new BABYLON.Color3(0.8, 0.8, 0.8),
    };

    Object.values(allStoreysObject).forEach((storey) => {
      let layersInStorey = storey.layerData.getAllLayersInStorey(storey.value);

      let options;
      if (storey.value === store.activeLayer.storey)
        options = activeStoreySketchOptions;
      else if (storey.value === store.activeLayer.storey - 1)
        options = storeyBelowSketchOptions;

      //else default options

      for (let layer of layersInStorey) {
        if (layer.hidden) continue;
        let sketchesOnLayer = layer.sketches;
        _assignPropertiesToSketches(sketchesOnLayer, options);
      }
    });
  }
    resolve();
  })
}

function updateHeightAfterEdits(mesh) {
  let newHeight;
  newHeight =
    mesh.getBoundingInfo().boundingBox.maximumWorld.y -
    mesh.getBoundingInfo().boundingBox.minimumWorld.y;
  if (mesh.type && mesh.type.toLowerCase() == "mass") {
    if (newHeight !== mesh.height) {
      mesh.height = newHeight;
      mesh.getSnaptrudeDS().heightChanged = true;
    }
  }
  return mesh;
}

function showAllLayersInStorey(storeyData) {
  let layR = storeyData.layerData.getAllLayersInStorey(storeyData.value);
  for (let i = 0; i < layR.length; i++) {
    if (!layR[i].hidden) {
      let sketches = layR[i].sketches;
      for (let j = 0; j < sketches.length; j++) {
        sketches[j].mesh.visibility = 1;
      }
    }
  }
}

function calculateAreaOfIrregularPolygons(pointsArray) {
  let lastPoint = pointsArray[0];
  if (lastPoint !== pointsArray[pointsArray.length - 1]) {
    pointsArray.push(lastPoint);
  }
  let areaOfPolygon = null;
  let aArr = [];
  let bArr = [];
  let sumOfaArr = 0;
  let sumOfbArr = 0;
  if (pointsArray) {
    for (let i = 0; i < pointsArray.length - 1; i++) {
      let val = pointsArray[i][0] * pointsArray[i + 1][1];
      aArr.push(val);
    }
    for (let j = 0; j < pointsArray.length - 1; j++) {
      let val2 = pointsArray[j][1] * pointsArray[j + 1][0];
      bArr.push(val2);
    }
    if (aArr.length != 0) {
      for (let k = 0; k < aArr.length; k++) {
        sumOfaArr += aArr[k];
      }
    }
    if (bArr.length != 0) {
      for (let l = 0; l < bArr.length; l++) {
        sumOfbArr += bArr[l];
      }
    }
    areaOfPolygon = Math.abs(sumOfaArr - sumOfbArr) / 2;
  }
  return areaOfPolygon;
}
//
// function createScreenShotAsync(width, height){
//     return new Promise((resolve, reject) => {
//         BABYLON.Tools.CreateScreenshot(engine, store.scene.activeCamera, { width: width, height: height }, (data) => {
//             if(data === undefined){
//                 reject("Image is null");
//             }
//             else{
//                 resolve(data);
//             }
//         });
//     });
// }

function hideAllDoorWindows2D() {
  let allElements = [];
  let sd = StructureCollection.getInstance()
    .getStructures()
    [store.activeLayer.structure_id].getStoreyData();
  let allStoreys = sd.getAllStoreys();
  let least = -sd.getNegativeStoreysLength();
  let most = sd.getPositiveStoreysLength();
  for (let k = least; k < most; k++) {
    if (k === 0) continue;
    if (allStoreys[k]) {
      if (allElements.length === 0) allElements = [...allStoreys[k].elements];
      else allElements.push(...allStoreys[k].elements);
    }
  }
  for (let i = 0; i < allElements.length; i++) {
    if (allElements[i].type === "Wall") {
      let childMesh = allElements[i].mesh.getChildren();
      if (childMesh.length !== 0) {
        for (let j = 0; j < childMesh.length; j++) {
          if (!["door", "window"].includes(childMesh[j].type.toLowerCase()))
            continue;

          childMesh[j].visibility = 0;
          childMesh[j].isVisible = false;
          let children = childMesh[j].getChildren();
          children.forEach((child) => {
            if (child.type.toLowerCase() !== "floor") {
              child.isVisible = false;
            }
          });
        }
      }
    }
  }
  return allElements;
}

function showDoorWindow2D() {
  // if (store.project_type !== "sketch-to-bim") return;
  let structures = StructureCollection.getInstance();
  const storeys = StoreyMutation.getAllStoreys();
  let str = structures.getStructureById(store.activeLayer.structure_id);
  let elements = str
    .getStoreyData()
    .getStoreyByValue(store.activeLayer.storey).elements;

  if (elements.length === 0) return;
  let allElements = hideAllDoorWindows2D();
  if (store.$scope.isTwoDimension) {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].type === "Wall" && elements[i].mesh.isVisible) {
        let childMesh = elements[i].mesh.getChildren();
        if (childMesh.length !== 0) {
          for (let j = 0; j < childMesh.length; j++) {
            if (!["door", "window"].includes(childMesh[j].type.toLowerCase()))
              continue;

            childMesh[j].visibility = 0;
            childMesh[j].isVisible = false;
            if (!storeys[store.activeLayer.storey].hidden) {
              let children = childMesh[j].getChildren();
              children = children.filter(
                (child) => child.type.toLowerCase() !== "floor"
              );
              if (children.length !== 0) {
                children.forEach((child) => {
                  child.isVisible = true;
                  child.visibility = 1;
                });
              } else {
                if (childMesh[j].sourceMesh.getSnaptrudeDS().type === "Door") {
                  doorOperation.draw2d(childMesh[j]);
                } else if (
                  childMesh[j].sourceMesh.getSnaptrudeDS().type === "Window"
                ) {
                  windowOperation.draw2d(childMesh[j]);
                }
              }
            }
          }
        }
      }
    }
  } else {
    for (let i = 0; i < allElements.length; i++) {
      if (
        allElements[i].type.toLowerCase() === "wall" &&
        allElements[i].mesh.isVisible
      ) {
        let childMesh = allElements[i].mesh.getChildren();
        if (childMesh.length !== 0) {
          for (let j = 0; j < childMesh.length; j++) {
            if (!["door", "window"].includes(childMesh[j].type.toLowerCase()))
              continue;

            childMesh[j].visibility = 1;
            childMesh[j].isVisible = true;
            let children = childMesh[j].getChildren();
            children.forEach((child) => {
              if (child.type.toLowerCase() !== "floor") {
                child.isVisible = false;
                child.visibility = 0;
              }
            });
          }
        }
      }
    }
  }
}

function topViewCam(viewRadius, centerPos) {
  let camData = {
    alpha: -Math.PI / 2,
    beta: 0,
    radius: viewRadius,
    position: centerPos.clone(),
    target: centerPos.clone(),
  };
  easyMoveCamera(camData);
}

function frontViewCam(viewRadius, centerPos, isBackView) {
  let camData;

  if (
    // store.scene.activeCamera.alpha === -Math.PI / 2 &&
    // store.scene.activeCamera.beta === Math.PI / 2 &&
    // store.scene.activeCamera.radius === viewRadius &&
    isBackView
  ) {
    camData = {
      alpha: Math.PI / 2,
      beta: Math.PI / 2,
      radius: viewRadius,
      position: centerPos.clone(),
      target: centerPos.clone(),
    };
  } else {
    camData = {
      alpha: -Math.PI / 2,
      beta: Math.PI / 2,
      radius: viewRadius,
      position: centerPos.clone(),
      target: centerPos.clone(),
    };
  }
  easyMoveCamera(camData);
}

function sideViewCam(viewRadius, centerPos, isLeftView) {
  let camData;
  if (
    // store.scene.activeCamera.alpha === 0 &&
    // store.scene.activeCamera.beta === Math.PI / 2 &&
    // store.scene.activeCamera.radius === viewRadius &&
    isLeftView
  ) {
    camData = {
      alpha: Math.PI,
      beta: Math.PI / 2,
      radius: viewRadius,
      position: centerPos.clone(),
      target: centerPos.clone(),
    };
  } else {
    camData = {
      alpha: 0,
      beta: Math.PI / 2,
      radius: viewRadius,
      position: centerPos.clone(),
      target: centerPos.clone(),
    };
  }
  easyMoveCamera(camData);
}

function toggleOrthoView() {
  if (
    store.scene.activeCamera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA &&
    !store.$scope.isTwoDimension
  ) {
    store.scene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
    if (labelView) {
      labelView.resetOperation();
    }
    $("#ortho_camera_button").css("border", "none");
    onSolid(null, true);
  } else {
    store.scene.activeCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
    if (labelView) {
      if (
        store.scene.activeCamera.alpha === -Math.PI / 2 &&
        store.scene.activeCamera.beta === Math.PI / 2
      ) {
        labelView.displayAutoDimensionsElev();
      } else if (
        store.scene.activeCamera.alpha === 0 &&
        store.scene.activeCamera.beta === Math.PI / 2
      ) {
        labelView.displayAutoDimensionsElev();
      }
    }
    $("#ortho_camera_button").css("border", "1px solid #d30041");
    onSolid(null, true, { isOrtho: true });
  }
}

const toggleHiddenLine = (hideLines) => {
  if (hideLines) disableEdgesRendering();
  else enableEdgesRendering();
};

function changeAzimuthForTrueNorth(angles, terrain) {
  if (terrain.rotationQuaternion) {
    let rotAngle = terrain.rotationQuaternion.toEulerAngles().y;
    if (rotAngle > 0) {
      angles.azimuth += Math.PI + rotAngle;
    } else {
      angles.azimuth += -rotAngle;
    }
  }
  return angles;
}

function disableEdgesRendering() {
  store.scene.meshes.forEach((m) => {
    m.disableEdgesRendering();
  });
}
function enableEdgesRendering() {
  let _allMeshes = store.scene.meshes;
  let _meshesToInclude = _allMeshes.filter( m => ![ 'terrain', 'sunSphere', 'sunSphere2' ].includes(m.name) );
  _meshesToInclude.forEach((m) => {
    m.enableEdgesRendering();
  });
}


function changeCameraView(view) {
  // removeMouseEvents();
  if (store.$scope.isTwoDimension) goOutOfTwoD();
  let totalBounding = createGroupBoundingBox();
  let radius = totalBounding.radius;
  let aspectRatio = store.engine.getAspectRatio(store.scene.activeCamera);
  let halfMinFov = store.scene.activeCamera.fov / 2;
  if (aspectRatio < 1) {
    halfMinFov = Math.atan(
      aspectRatio * Math.tan(store.scene.activeCamera.fov / 2)
    );
  }
  let viewRadius = Math.abs(radius / Math.sin(halfMinFov));

  switch (view) {
    case "topView":
      topViewCam(viewRadius, totalBounding.center);
      break;
    case "frontView":
      frontViewCam(viewRadius, totalBounding.center, false);
      break;
    case "backView":
      frontViewCam(viewRadius, totalBounding.center, true);
      break;
    case "leftView":
      sideViewCam(viewRadius, totalBounding.center, true);
      break;
    case "rightView":
      sideViewCam(viewRadius, totalBounding.center, false);
      break;
  }
  // store.selectionStack = [];
}

function convexHull(points) {
  points.sort(function (a, b) {
    return a.x != b.x ? a.x - b.x : a.y - b.y;
  });
  var n = points.length;
  var hull = [];
  for (var i = 0; i < 2 * n; i++) {
    var j = i < n ? i : 2 * n - 1 - i;
    while (
      hull.length >= 2 &&
      removeMiddle(hull[hull.length - 2], hull[hull.length - 1], points[j])
    )
      hull.pop();
    hull.push(points[j]);
  }
  hull.pop();
  return hull;
}

function removeMiddle(a, b, c) {
  var cross = (a.x - b.x) * (c.y - b.y) - (a.y - b.y) * (c.x - b.x);
  var dot = (a.x - b.x) * (c.x - b.x) + (a.y - b.y) * (c.y - b.y);
  return cross < 0 || (cross == 0 && dot <= 0);
}

function exportSVG() {
  let newLine = "\n";
  let svgText =
    '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3000px" height="3000px" xml:space="preserve">';
  for (var i = 0; i < store.scene.meshes.length; i++) {
    if (!nonDefaultMesh(store.scene.meshes[i])) {
      continue;
    }
    if (!store.scene.meshes[i].getSnaptrudeDS()) {
      continue;
    }
    var points = [];
    // store.scene.meshes[i].bakeCurrentTransformIntoVertices();
    var worldMatrix = store.scene.meshes[i].getWorldMatrix();
    var transformMatrix = store.scene.getTransformMatrix();
    let globalViewport = store.scene.cameras[0].viewport.toGlobal(
      store.engine.getRenderWidth(),
      store.engine.getRenderHeight()
    );

    var positions = store.scene.meshes[i].getVerticesData(
      BABYLON.VertexBuffer.PositionKind
    );
    var numberOfVertices = positions.length / 3;
    for (let j = 0; j < numberOfVertices; j++) {
      var pointsPos = new BABYLON.Vector3(
        positions[j * 3],
        positions[j * 3 + 1],
        positions[j * 3 + 2]
      );
      var coordinates = BABYLON.Vector3.Project(
        pointsPos,
        BABYLON.Matrix.Identity(),
        transformMatrix,
        globalViewport
      );
      points.push(coordinates);
    }
    var tidiedPoints = convexHull(points);
    for (let j = 0; j < tidiedPoints.length; j++) {
      let newLineText = '<line id = "' + j + "-" + i + '"';
      if (j == 0) {
        newLineText +=
          ' x1 = "' +
          tidiedPoints[tidiedPoints.length - 1].x / 2 +
          '" ' +
          'y1 = "' +
          tidiedPoints[tidiedPoints.length - 1].y / 2 +
          '"';
      } else {
        newLineText +=
          ' x1 = "' +
          tidiedPoints[j - 1].x / 2 +
          '" ' +
          'y1 = "' +
          tidiedPoints[j - 1].y / 2 +
          '"';
      }
      newLineText +=
        ' x2 = "' +
        tidiedPoints[j].x / 2 +
        '" ' +
        'y2 = "' +
        tidiedPoints[j].y / 2 +
        '"';
      newLineText +=
        ' stroke = "black"' + ' stroke-width = "' + "0.01" + '" />';
      $("#exportsvg").append(newLine);
      svgText += newLineText;
    }
  }
  svgText += "</svg>";
  console.log(svgText);
}
var createBuilding = function () {
  //window.analytics.track("building mode");
  // updateButtonColor("solid");
  // offsetRoomPolygons();
  // var canState = {meshData: getMeshData2(), can_mode: $('#mass_mode').prop('checked')};
  // console.log(canState);
  // wall_pols = createRoomPolygons(canState.meshData.coord);
  // removeObjects();

  //console.info("Before");
  createRooms(true)
    .then(() => {
      //console.info("After");
      var mat_name = "wall_mat";
      var door_data2 = generateDoorData(store.door_data[0]);
      //drawDoorBlocks(door_data2, store.door_data[1], mat_name, 36 * unit_scale);
      var window_data1 = generateWindowData(store.window_data[0]);
      //drawWindowBlocks(window_data1, store.window_data[1], mat_name, 36 * unit_scale, 36 * unit_scale);

      getDoorDataFplan();
      getWindowDataFplan();
      if (store.fplan_mode == "True") {
        getDoorDataFplan();
        getWindowDataFplan();
      }
      /* AG-RE: ANGLUAR REFERENCE */
      // $scope.$apply(function () {
      //   $scope.InteriorButtonDisp = false;
      // });
      // onSolid();
    })
    .finally(() => {
      if (store.$scope.isTwoDimension) {
        // hideAllRoofs();
        // setLayerTransperancy();
      }
      if (store.$scope.areasUpdateNeeded) {
        areaView.generateArea();
      }

      updateModifications();

      /* AG-RE: ANGLUAR REFERENCE */
      // $scope.sortTools("bim_building_position");
      handleEscapeKeyEvent();
    });

  // TODO : Run updateModifications() after model is loaded (Avoid setTimeOut())
  // setTimeout(updateModifications,6000);
  // if($('#roof_mode').prop('checked')){
  //     $('#roof_status').html('Roof Shown');
  //     showAllRoofs();
  // }
  // else if(!$('#roof_mode').prop('checked')){
  //     $('#roof_status').html('Roof Hidden');
  //     hideAllRoofs();
  // }
};

// function updateViewsData(imageKeys) {
//   $.ajax({
//     url: "/updateviewscreeshot/",
//     data: {
//       // csrfmiddlewaretoken: csrf_token,
//       floor_key: store.floorkey,
//       imagekeys: imageKeys.toString(),
//     },
//     success: function () {
//       console.log("Updated DB");
//     },
//     dataType: "json",
//     type: "POST",
//   });
// }

const _moveCamera = function (position, target, cameraOrtho, moveIntoTwoD=false, orthoCordinates){
  if(cameraOrtho === 0){
    let speed = 70;
    let frameCount = 70;
    let _camera = store.scene.activeCamera;

    explorerCam.animateCameraToPosition(_camera, speed, frameCount, position);
    explorerCam.animateCameraTargetToPosition(_camera, speed, frameCount, target);
  }
  else {
    if(moveIntoTwoD){
      goIntoTwoD();
    }
    store.scene.activeCamera.position = new BABYLON.Vector3(position._x, position._y, position._z);
    store.scene.activeCamera.target = new BABYLON.Vector3(target._x, target._y, target._z);
    if(orthoCordinates){
      const {orthoBottom, orthoLeft, orthoRight, orthoTop} = orthoCordinates;
      store.scene.activeCamera.orthoBottom = orthoBottom;
      store.scene.activeCamera.orthoLeft = orthoLeft;
      store.scene.activeCamera.orthoRight = orthoRight;
      store.scene.activeCamera.orthoTop = orthoTop;
    }
  }
};

const takeToView = async function (val, evt = null, imageData = null) {
  let viewData = null,
    textureDetail = null;
  if (!imageData) {
    imageData = store.$scope.sceneViewData;
  }
  for (let i = 0; i < imageData.length; i++) {
    if (imageData[i].imgKey === val) {
      viewData = imageData[i];
      textureDetail = imageData[i].textureDetail;
      break;
    }
  }
  // if (store.$scope.isTwoDimension) {
  //   goOutOfTwoD();
  // }
  // refreshSelectedView([viewData.imgKey]);
  // easyMoveCamera(viewData.camData);
  const {shadowData} = viewData.camData;
  const texture = viewData.textureDetail;
  const shadowEvent = new CustomEvent("screenshot-view", {
    detail: {
      shadowData,
      texture
    }
  })
  window.dispatchEvent(shadowEvent);
  _moveCamera(viewData.camData.position, viewData.camData.target, viewData.camData.ortho, viewData.camData.isTwoDimension);
  _changeTextureDetail(textureDetail);
  //Hide objects hidden in the stored view
  showAllObjects();
  if (
    viewData.camData.hiddenMeshesScene &&
    viewData.camData.hiddenMeshesScene.length > 0
  ) {
    let stackMeshes = viewData.camData.hiddenMeshesScene.map((h) => {
      return store.scene.getMeshByUniqueID(h);
    });
    let options = {};
    options.donotAddToHiddenMeshes = false;
    hideObjectUtil(stackMeshes, false, options);
  }
  //Enable or disable shadows based on the status in the stored view
  if (viewData.camData.shadowData.shadowToggle) {
    let hemiLight = store.scene.getLightByName("light1");
    let shadowStatus = hemiLight.intensity === 0;
    if (!shadowStatus) sunpath.toggleShadows();
    sunpath.changeSunPos(
      viewData.camData.shadowData.date.month,
      viewData.camData.shadowData.date.date,
      viewData.camData.shadowData.date.time
    );
    // toggleHiddenLine(true);
  } else {
    let hemiLight = store.scene.getLightByName("light1");
    let shadowStatus = hemiLight.intensity === 0;
    if (shadowStatus) sunpath.toggleShadows();
  }

  if(viewData.camData.sunPath){
    let sunPathLines = store.scene.getMeshByName("sunpathLines");
    if(!sunPathLines){
      sunpath.toggleSunpath();
    }
  }
  else{
    sunpath.clearSunpath();
  }
  // //Toggle Ortho view if the stored view was in ortho mode
  // if (store.scene.activeCamera.mode !== viewData.camData.ortho) {
  //   toggleOrthoView();
  // }

  //ViewData stores the information at the time of saving the view.
  if( !viewData.camData.isTwoDimension ){
    if( store.$scope.isTwoDimension ){
      goOutOfTwoD();
    }
    if( viewData.camData.ortho === BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
      if( store.scene.activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
        toggleOrthoView();
        const orthoViewUpdatedEvent = new CustomEvent("orthoViewUpdated", {
          detail: {
            isActive: true,
          }
        })
        // window.dispatchEvent(orthoViewUpdatedEvent);
      }
    }
    else {
      store.scene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
    }
  }
};

const isOrthographicView = () => {
  return store.scene.activeCamera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA && !store.scene.isTwoDimension
}

const takeToComment = (sceneData) => {
  if(!sceneData) return;
  console.log(sceneData);
  let {position, target, isOrtho} = sceneData.camera;
  isOrtho = isOrtho ? 1 : 0;
  // console.log({position, target, isOrtho});
  const {orthoBottom, orthoLeft, orthoRight, orthoTop} = sceneData.camera;
  const orthoCordinates = {orthoBottom, orthoLeft, orthoRight, orthoTop};
  _moveCamera(position, target, isOrtho, true, orthoCordinates);
}

export {
  sceneInit,
  drawGrid,
  changeCanvasTheme,
  sceneReInit,
  axes,
  showAxis,
  setupLighting,
  globalOffSet,
  initModelDelta,
  getAngularScope,
  get_floorkey_details,
  drawInitialModel,
  addPreviousMeshesToStructures,
  reloadScene,
  recreateSceneStructureA,
  recreateMaterialsA,
  recreateMultiMaterialsA,
  executeWhenReady,
  removeObjects,
  removeRooms,
  selectStructure,
  createGrid,
  initSketchPlan,
  setLayerTransperancy,
  updateHeightAfterEdits,
  showAllLayersInStorey,
  calculateAreaOfIrregularPolygons,
  hideAllDoorWindows2D,
  showDoorWindow2D,
  topViewCam,
  frontViewCam,
  sideViewCam,
  toggleOrthoView,
  toggleHiddenLine,
  changeAzimuthForTrueNorth,
  // changeSunPos,
  // toggleShadows,
  changeCameraView,
  convexHull,
  removeMiddle,
  exportSVG,
  createBuilding,
  disableEdgesRendering,
  /* FROM webtrudeTodo.js */
  takeToView,
  takeToComment,
  isOrthographicView
};
