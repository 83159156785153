import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { getFace } from "./faceFuncs.js";
import { getNormalVector } from "./mathFuncs.js";
import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
import { getCSGFormOfMesh } from "../modules/extrafunc.js";
import { Wall } from "../modules/snaptrudeDS/wall.ds.js";
var facePlane = null;

function checkIfSamePlane(pickInfo) {
  var face = getFace(pickInfo);
  if (!face) return null;
  var normalVector = getNormalVector(face);
  if (!normalVector) return null;

  if (normalVector[0] == 0 && normalVector[1] == 0) {
    if (facePlane == null) {
      var val = Math.round(10000 * face[0].z) / 10000;
      var coord = "z";
      facePlane = { val: val, coord: coord };
      return true;
    }
    if (
      facePlane.coord == "z" &&
      facePlane.val == Math.round(10000 * face[0].z) / 10000
    ) {
      //console.log('same plane - z');
      return true;
    }
    return false;
  } else if (normalVector[0] == 0 && normalVector[2] == 0) {
    if (facePlane == null) {
      var val = Math.round(10000 * face[0].y) / 10000;
      var coord = "y";
      facePlane = { val: val, coord: coord };
      return true;
    }
    if (
      facePlane.coord == "y" &&
      facePlane.val == Math.round(10000 * face[0].y) / 10000
    ) {
      //console.log('same plane - y');
      return true;
    }
    return false;
  } else if (normalVector[1] == 0 && normalVector[2] == 0) {
    if (facePlane == null) {
      // var val = Math.round(10000 * face[i].x) / 10000;
      var val = Math.round(10000 * face[1].x) / 10000;
      var coord = "x";
      facePlane = { val: val, coord: coord };
      return true;
    }
    if (
      facePlane.coord == "x" &&
      facePlane.val == Math.round(10000 * face[0].x) / 10000
    ) {
      //console.log('same plane - x');
      return true;
    }
    return false;
  }
}

/**
 *
 * @param pt
 * Draws a cube on the point passed.
 */
function drawStartingPoint(pt) {
  if (store.scene.getMeshByName("startingPointOnDraw"))
    store.scene.getMeshByName("startingPointOnDraw").dispose();
  var cube = BABYLON.MeshBuilder.CreateBox(
    "startingPointOnDraw",
    0.1,
    store.newScene,
    false
  );
  cube.position = pt;
  var r = store.newScene.activeCamera.radius / 80;
  cube.scaling = new BABYLON.Vector3(0.1 * r, 0.1 * r, 0.1 * r);
  cube.material = store.scene.getMaterialByName("startingPointMaterial");
}

/*
Not generalised yet, use only in drawingEvents
 */
function isLoopClosed(drawingStack) {
  let latestPoint = drawingStack[drawingStack.length - 1];
  for (let i = 0; i < drawingStack.length - 1; i++) {
    let pt = drawingStack[i];
    if (pt.almostEquals(latestPoint)) {
      return true;
    }
  }

  let util = new ResolveEngineUtils();
  for (let i = 0; i < drawingStack.length - 2; i++) {
    let point1 = drawingStack[i];
    let point2 = drawingStack[i + 1];

    if (util.onSegment3D(point1, latestPoint, point2)) {
      let reorganisedDrawingStack = [latestPoint];

      reorganisedDrawingStack.push(...drawingStack.slice(i + 1));

      store.drawingPointStack = reorganisedDrawingStack;

      return true;
    }
  }

  return false;
}

/**
 *
 * @param diff
 * @returns {string}
 * Returns the plane on which the line exists, works only for xy, yz and xz planes.
 * And thus, returns only one of 'xy', 'yz' or 'xz'.
 */
function getPlaneForDraw(diff) {
  if (Math.abs(diff.x) < 0.0001) {
    return "yz";
  }
  if (Math.abs(diff.y) < 0.0001) {
    return "xz";
  }
  if (Math.abs(diff.z) < 0.0001) {
    return "xy";
  }
}

/**
 *
 * @param diff
 * @param plane
 * @returns {number}
 * Returns the angle between a line and a plane.
 */
function getAngleForDraw(diff, plane) {
  if (plane == "xy") {
    if (Math.abs(diff.x) >= 0.001) return Math.atan(diff.y / diff.x);
    return Math.PI / 2;
  }
  if (plane == "yz") {
    if (Math.abs(diff.z) >= 0.001) return Math.atan(diff.y / diff.z);
    return Math.PI / 2;
  }
  if (plane == "xz") {
    if (Math.abs(diff.x) >= 0.001) return Math.atan(diff.z / diff.x);
    return Math.PI / 2;
  }
}

/**
 * @type {number}
 * deleteDrawnLines() deletes the custom lines drawn and returns the number of lines deleted.
 * We delete the drawn lines not to put mess in the mesh, as lines once drawn properly are converted to polygons.
 */
function deleteDrawnLines() {
  return deleteAllMeshesContainingName("drawnLineUsingClicks");
}

/**
 *
 * @param commonName
 * @returns {number}
 * Deletes all the meshes which contain the string "commonName".
 * Returns the number of meshes deleted thus.
 * If no meshes are deleted, it returns 0.
 */
function deleteAllMeshesContainingName(commonName) {
  var countNumberOfDeletes = 0;
  for (var i = 0; i < store.newScene.meshes.length; i++) {
    if (store.newScene.meshes[i].name.indexOf(commonName) != -1) {
      var meshName = store.newScene.meshes[i].name;
      if (deleteMeshWithName(meshName, store.newScene)) countNumberOfDeletes++;
    }
  }
  for (var i = 0; i < store.newScene.meshes.length; i++) {
    if (store.newScene.meshes[i].name.indexOf(commonName) != -1) {
      var meshName = store.newScene.meshes[i].name;
      if (deleteMeshWithName(meshName, store.newScene)) countNumberOfDeletes++;
    }
  }
  return countNumberOfDeletes;
}

/**
 *
 * @param nameOfMesh
 * @param fromScene
 * @returns {boolean}
 * Deletes the mesh with name "nameOfMesh" from the store.scene "fromScene".
 * Returns true if the mesh is deleted, false otherwise.
 */
function deleteMeshWithName(nameOfMesh, fromScene) {
  if (fromScene.getMeshByName(nameOfMesh)) {
    fromScene.getMeshByName(nameOfMesh).dispose();
    return true;
  }
  return false;
}

function holeInTheWall(wallMesh, hole) {
  let aCSG = getCSGFormOfMesh(wallMesh);
  let bCSG = getCSGFormOfMesh(hole);
  // let wallInitialPos = wallMesh.position;
  // let wallInitialPos = jQuery.extend({}, wallMesh.position);
  let subCSG = aCSG.subtract(bCSG);
  let mergedMesh = subCSG.toMesh("wall", null, store.newScene);
  // let mergedMesh = subCSG.toSnaptrudeMesh("wall", null, store.newScene);
  mergedMesh.structure_id = wallMesh.structure_id;
  mergedMesh.type = "Wall";
  let wallWithHole = new Wall(mergedMesh);
  wallWithHole.assignProperties();
  wallWithHole.mesh.material = store.scene.getMaterialByName("wall_mat");

  // let wallMovedBy = mergedMesh.position.subtract(wallInitialPos);

  // return [wallWithHole, wallInitialPos, wallMovedBy];
  return wallWithHole;
}
export {
  facePlane,
  checkIfSamePlane,
  drawStartingPoint,
  isLoopClosed,
  getPlaneForDraw,
  getAngleForDraw,
  deleteDrawnLines,
  deleteAllMeshesContainingName,
  deleteMeshWithName,
  holeInTheWall,
};
