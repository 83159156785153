import React, { useEffect } from "react";
import objectPropertiesView from "../../../snaptrude/modules/objectProperties/objectPropertiesView";
import DoorPopUps from "./rightmenuPopUps/doorPopUps";
import FurniturePopUp from "./rightmenuPopUps/furniturePopUps";
import MaterialPopUps from "./rightmenuPopUps/materialPopUps";
import ObjectPropertiesEdits from "./rightmenuPopUps/objectPropertiesEdits";
import StaircasePopUps from "./rightmenuPopUps/staircasesPopUps";
import UserImportedFilesPopUp from "./rightmenuPopUps/userImportedFilesPopUp";
import WindowsPopUp from "./rightmenuPopUps/windowPopUps";

let opendRightMenu = null;
const RightMenuPopUp = ({selectedRightMenuImage, handleToolSelection, showEditStructure, resetSelectedTopSubMenu, setShowEditStructure, selectedTopMenu, activateEyeDropper}) => {
    const handleMakeUnique = () => {
      objectPropertiesView.makeUnique();
      handleToolSelection("", "right");
    }
    useEffect(() => {
      if(selectedRightMenuImage){
        resetSelectedTopSubMenu();
        opendRightMenu = selectedRightMenuImage;
      }else{
        if(opendRightMenu){
          opendRightMenu = null;
          handleToolSelection("pointer", "top");
        }
      }
    }, [selectedRightMenuImage]);
    return <>
        {selectedRightMenuImage === "objectProperties" && <ObjectPropertiesEdits
            togglePopUp = {() => {
              handleToolSelection("", "right");
              setTimeout(() => {handleToolSelection("objectProperties")}, 1000);
            }}
            closePopUp={() => {
              handleToolSelection("", "right");
              setShowEditStructure(false);
            }}
            showEditStructure={showEditStructure}
            setShowEditStructure={(showEditStructure) => {setShowEditStructure(showEditStructure)}}
            data={[
              {
                title: "Category",
                value: "Wall",
                type: "fixedValue",
              },
              { title: "Height", value: "2750", type: "input" },
              { title: "Length", value: "18000", type: "input" },
              { title: "Thickness", value: "200", type: "input" },
              { title: "Storey", value: "5", type: "fixedValue" },
              { title: "Area", value: "16,724.32", type: "fixedValue" },
              { title: "Volume", value: "16,724.32", type: "fixedValue" },
            ]}
            buttonsData={[
              { title: "Edit Structure", onClick: () => {setShowEditStructure(true)}},
              { title: "Match Properties" },
              { title: "Make Unique", onClick: handleMakeUnique },
            ]}
        />
        }
        <MaterialPopUps
            isOpen={selectedRightMenuImage === "materials"}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            closePopUp={() => handleToolSelection("", "right")}
            selectedTopMenu={selectedTopMenu}
            activateEyeDropper={activateEyeDropper}
        />
        <DoorPopUps
            isOpen={selectedRightMenuImage === "doors"}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            closePopUp={() => handleToolSelection("", "right")}
        />
        <WindowsPopUp
            isOpen={selectedRightMenuImage === "windows"}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            closePopUp={() => handleToolSelection("", "right")}
        />
        <FurniturePopUp
            isOpen={selectedRightMenuImage === "furniture"}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            closePopUp={() => handleToolSelection("", "right")}
        />
        <StaircasePopUps
            isOpen={selectedRightMenuImage === "staircases"}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            closePopUp={() => handleToolSelection("", "right")}
        />
        <UserImportedFilesPopUp
            isOpen={selectedRightMenuImage === "userimportedfiles"}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            closePopUp={() => handleToolSelection("", "right")}
        />
    </>
}

export default RightMenuPopUp;