import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from "../Button";
import { SingleUploadOverView } from "../../containers/genericModal/singleUploadOverView";
import Mapbox from "../Mapbox";
import { handleToolbar } from "../../snaptrude/libs/handleToolbar";
import Toggle from "../Toggle";
import { store } from "../../snaptrude/modules/utilityFunctions/Store.js"
import {terrainGeneration} from "../../snaptrude/modules/geo/terrainMap";
const Card = styled(Outer)`
  width: 39rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`;

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 0.1em 0em;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const Row = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HighlightedText = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  white-space: nowrap;
  background-color: ${colors.transparent};
  color: ${colors.brightRed};
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;

function TopographyModal({ isOpen, onClose, modalHeaderText }) {
  let [displayElevation, setDisplayElevation] = useState(false);
  let [satelliteStyle, setSatelliteStyle] = useState(false);
  let [displayNeighborhood, setDisplayNeighborhood] = useState(false);
  let _streetStyle = "mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g";
  let _satelliteStyle =
    "mapbox://styles/poojaghodmode/ckw1osjtm9zg514qn4f32gt3g";

  // useEffect(() => {
  //   if(isOpen){
  //     // store.terrainMap?.resize();
  //     terrainGeneration.updateCenterAndZoomOfTerrain();
  //   }
  // }, [isOpen])

  let _handleMapBoxStyleToggle = function () {
    const satValue = satelliteStyle;
    setSatelliteStyle(!satelliteStyle);
    if (!satValue) {
      store.terrainMap.setStyle(_satelliteStyle);
    } else {
      store.terrainMap.setStyle(_streetStyle);
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Load {modalHeaderText}
            </Text>
          </ModalChild>

          {/* <ModalChild right="1.0625rem">
            Elevations:
            <Toggle
              handleClick={() => setDisplayElevation(!displayElevation)}
            />
          </ModalChild> */}

          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          <Mapbox satelliteStyle={satelliteStyle} />

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ padding: "1em", display: "flex", alignItems: "center" }}>
              Elevations:
              <Toggle
                state={displayElevation}
                handleClick={() => setDisplayElevation(!displayElevation)}
              />
            </div>

            <div style={{ padding: "1em", display: "flex", alignItems: "center"  }}>
              Buildings:
              <Toggle
                state={displayNeighborhood}
                handleClick={() => setDisplayNeighborhood(!displayNeighborhood)}
              />
            </div>

            <div style={{ padding: "1em", display: "flex", alignItems: "center"  }}>
              Default style:
              <Toggle
                state={satelliteStyle}
                handleClick={() => _handleMapBoxStyleToggle()}
              />
              Satellite
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button
              onPress={onClose}
              style={{
                backgroundColor: colors.transparent,
                color: colors.dullgrey,
              }}
              title="< Back"
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              primary
              title="Load"
              onPress={() => {
                // console.log(displayElevation);
                handleToolbar("loadTopography", null, {
                  isHeightMapChecked: displayElevation,
                  isSatelliteEnabled: satelliteStyle,
                  isNeighborhoodEnabled: displayNeighborhood,
                });
                onClose();
              }}
            />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  );
}

export default TopographyModal;
