import {speckleApiToken} from "./url.constants";

const redirectToSnaptrudeManager = function (userData){
  
  const filteredData = {
    accessToken: userData.accessToken,
    refreshToken: userData.refreshToken,
    fullname: userData.fullname,
  }
  
  filteredData.speckleApiToken = speckleApiToken;
  
  const redirectLink = 'snaptrude://loginSuccess?data=' + encodeURIComponent(JSON.stringify(filteredData));
  window.open(redirectLink, "_blank");
  
  // window.close();
  // doesn't close the tab
};

export {
  redirectToSnaptrudeManager,
}