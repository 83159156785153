import React, { useContext, useEffect, useState, useCallback} from 'react';
import HistoryContext from '../../components/Context/HistoryContext';
import { routes } from '../../routes/constants';
import { getSharedWithMeProjects, searchSharedProjects } from '../../services/projects.service';
import styled from "styled-components";
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from '../../components/Card';
import { ORIGIN } from '../../services/url.constants';
import { debounce } from 'lodash';
import { SearchInput } from '../../components/searchInput';

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 5;
`;
const getImageUrl = threeDUrl => {
    return ORIGIN + '/media/' + threeDUrl;
};

function SharedWithMe() {
    const [sharedWithMeProjects, setSharedWithMeProjects] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showSearchedProjects, setShowSearchedProjects] = useState(false);
  const [searchedProjects, setSearchedProjects] = useState([]);

  const history = useContext(HistoryContext);
    
    const takeToProject = (floorkey) => {
        // window.location.replace("/model/"+floorkey)
        history.push("/model/"+floorkey);
    }

    const fetchSharedProjects = async () => {
        const res = await getSharedWithMeProjects(sharedWithMeProjects.length)
        if(res.error){
            history.push(routes.login);
            return;
        }
        if(res.projects.length>0){
            res.projects.reverse();
            let newProjects = [...sharedWithMeProjects, ...res.projects];
            setSharedWithMeProjects(newProjects);
        }
        
    }
    useEffect(() => {
      fetchSharedProjects();
    }, []);

    const debounceSearch = useCallback(
        debounce(async (text) => {
            const {success, data, message} = await searchSharedProjects(text)
            if(success){
                setShowSearchedProjects(true)
                setSearchedProjects([]);
                setSearchedProjects(data);
            }else{
                console.wran(message);
            }
        }, 500),
        []
    )
    
    const handleSearchTextChange = (evt) => {
        const {value} = evt.target;
        setSearchText(value);
        if(!value){
            setShowSearchedProjects(false);
        }else{
            debounceSearch(value);
        }
    }
    
    return (
        <MainContainer>
            <div className="content">
                <div className="header">
                    <span className="project-header"></span>
                    <div className="header-div">
                        <SearchInput 
                            value={searchText} 
                            onChange={handleSearchTextChange} 
                        />  
                    </div>
                </div>
                {
                    searchText &&
                    <div className="cards">
                    {
                        searchedProjects.map((project, index) => {
                        return (
                            <Card
                                onClick={() => {
                                    // window.analytics.track('opened an existing project from dashboard');
                                    takeToProject(project.key);
                                }}
                                headerText={project.name}
                                floorkey={project.key}
                                description={project.desc === "None" ? "" : project.desc}
                                footerText={`Shared on ${new Date(project.updated).toLocaleString()}`}
                                avatars={[]}
                                key={index}
                                image={getImageUrl(project.threeDUrl)}
                            />
                        );
                        })
                    }
                    </div>
                }
                <InfiniteScroll
                    dataLength={sharedWithMeProjects.length}
                    next={fetchSharedProjects}
                    hasMore={true}
                    style={{minHeight: "85vh"}}
                >
                    <div className="cards">
                    {!searchText && sharedWithMeProjects.map((project, index) => {
                        return (
                            <Card
                            onClick={() => {
                                // window.analytics.track('opened an existing project from dashboard');
                                takeToProject(project.key);
                            }}
                            headerText={project.name}
                            floorkey={project.key}
                            description={project.desc === "None" ? "" : project.desc}
                            footerText={`Shared on ${new Date(project.updated).toLocaleString()}`}
                            avatars={[]}
                            key={index}
                            image={getImageUrl(project.threeDUrl)}
                            />
                        );
                    })}
                    </div>
                </InfiniteScroll>
          </div>
        </MainContainer>
    );

}

export default SharedWithMe;
