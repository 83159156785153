import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledText = styled.p`
  font-style: normal;
  font-weight: ${({ weight }) => weight || "normal"};
  font-size: ${({ size }) => size || "0.875rem"};
  line-height: ${({ lineHeight }) => lineHeight || "1.125rem"};
  letter-spacing: -0.0208rem;
  color: ${({ color, theme }) => color || theme.colors.primeBlack};
  opacity: ${({ opacity }) => opacity || 0.8};
  text-align: ${({ textAlign }) => textAlign || "normal"};
  font-family: ${(props) => props.theme.font};
`;

function Text({ color, lineHeight, size, weight, ...rest }) {
  return (
    <StyledText
      color={color}
      lineHeight={lineHeight}
      size={size}
      weight={weight}
      {...rest}
    />
  );
}

Text.propTypes = {
  color: PropTypes.string,
  lineHeight: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
};

export default Text;
