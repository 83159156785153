import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { makeid } from "./arrayFuncs.js";
import { updateRoofAccordion } from "./roofVisibilityFuncs.js";
import {
  addMeshToStructure,
  updateSubMeshes,
  onSolid,
} from "../modules/extrafunc.js";
import { doorOperation } from "../modules/meshoperations/doorOperation.js";
import { windowOperation } from "../modules/meshoperations/windowOperation.js";
import { copyMaterialData } from "./mats.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import {setLayerTransperancy} from "./sceneFuncs";
function pasteObject(meshToBeCopied, options = {}) {
  
  function _cloneObject(sourceMesh, referenceMesh, unique) {
    let newInstance = null;
    
    if (!_.isBoolean(unique)) unique = uniqueObject;
    
    let isLoadedMesh = ["furniture", "door", "window"].includes(
      referenceMesh.type.toLowerCase()
    );
    if (!unique || isLoadedMesh) {
      newInstance = sourceMesh.createInstance(
        referenceMesh.name + store.copy_index
      );
    } else {
      // newInstance = sourceMesh.clone(copy_mesh.name + store.copy_index, null, true, false);
      newInstance = sourceMesh.cleanClone(
        referenceMesh.name + store.copy_index,
        null,
        true,
        false
      );
      
      newInstance.makeGeometryUnique();
      delete newInstance.source;
    }
    
    newInstance.isVisible = true;
    
    return newInstance;
  }
  
  function _assignAdditionalThings(newInstanceDS, meshDS) {
    const newInstance = newInstanceDS.mesh;
    const mesh = meshDS.mesh;
    
    if (newInstanceDS.assignProperties) newInstanceDS.assignProperties();
    let meshType = mesh.type.toLowerCase();
    if (["mass", "floor"].includes(meshType)) {
      if (mesh.room_unique_id) {
        newInstance.room_unique_id = makeid(3);
      }
      // if (!newInstance.room_id || newInstance.room_id === mesh.room_id) {
      newInstance.room_id = makeid(3);
      newInstanceDS.room_id = newInstance.room_id;
      // }
      newInstance.room_path = mesh.room_path;
      newInstance.room_type = mesh.room_type;
      
      newInstanceDS.room_path = meshDS.room_path;
      newInstanceDS.room_type = meshDS.room_type;
      
      if (meshDS.massType) {
        newInstanceDS.massType = meshDS.massType;
      }
    } else if (meshType === "roof") {
      store.roofsInScene.push(newInstance);
      updateRoofAccordion();
    } else if (meshType === "wall") {
      newInstanceDS.neighbours = null;
      newInstanceDS.neighboursDetails = null;
    } else if (meshType === "furniture") {
      if (meshDS.autoInterior) {
        newInstanceDS.autoInterior = true;
      }
    }
  }
  
  function _handleChildrenComponents(meshToBeCopied, newInstance){
    
    if (!meshToBeCopied.childrenComp) return;
    
    meshToBeCopied.childrenComp.forEach(function (child) {
      
      child.setParent(null);
      // when child is cloned, it won't have a parent, so the mesh it's cloned from should be parentless
      // parents are reassigned for the child and cloned child below
      
      let unique = uniqueObject;
      if (["furniture", "door", "window"].includes(child.type.toLowerCase())){
        unique = false;
      }
      
      let sourceMesh, sourceParent;
      if (child.sourceMesh){
        sourceMesh = child.sourceMesh;
        sourceParent = child.sourceMesh.parent;
        child.sourceMesh.setParent(null);
      }
      else sourceMesh = child;
      
      let newInstanceChild = _cloneObject(sourceMesh, child, unique);
      
      newInstanceChild.copyWorldMatrixProperties(child);
      
      let childDS = child.getSnaptrudeDS();
      newInstanceChild.storey = childDS.storey;
      addMeshToStructure(newInstanceChild, child);
      
      const newInstanceChildDS = newInstanceChild.getSnaptrudeDS();
      if (childDS.cloneProperties){
        childDS.cloneProperties(newInstanceChildDS, false);
      }
      
      _assignAdditionalThings(newInstanceChildDS, childDS);
            
      newInstanceChild.setParent(newInstance);
      newInstance.childrenComp.push(newInstanceChild);
      
      child.setParent(meshToBeCopied);
      if (sourceParent) child.sourceMesh.setParent(sourceParent);
      
      if (child.type.toLowerCase() === "door") {
        /*let door = child;
        if (door.childrenComp) {
          door.childrenComp.forEach(function (supportFloor) {
            if (supportFloor.type.toLowerCase() === "floor") {
              supportFloor.setParent(null);
              let sourceMesh = supportFloor.sourceMesh || supportFloor;
              let supportFloorInstance = _cloneObject(sourceMesh, supportFloor, uniqueObject);
              supportFloorInstance.setParent(null);
              supportFloorInstance.copyWorldMatrixProperties(supportFloor);
              
              let supportFloorDS = supportFloor.getSnaptrudeDS();
              supportFloorInstance.storey = supportFloorDS.storey;
              addMeshToStructure(supportFloorInstance, supportFloor);
              
              const supportFloorInstanceDS = supportFloorInstance.getSnaptrudeDS();
              if (supportFloorDS.cloneProperties) {
                supportFloorDS.cloneProperties(supportFloorInstanceDS, false);
              }
              _assignAdditionalThings(supportFloorInstanceDS, supportFloorDS);
              
              supportFloorInstance.setParent(newInstanceChild);
              newInstanceChild.childrenComp.push(supportFloorInstance);
              supportFloor.setParent(door);
            }
          });
        }*/
        doorOperation.setDoor2d(newInstanceChild);
      }
      
      if (child.type.toLowerCase() === "window") {
        windowOperation.setWindow2d(newInstanceChild);
      }
      
      if (handleChildren) _handleChildrenComponents(child, newInstanceChild);
    });
    
  }
  function _assignInstanceProperties(newInstance) {
    if (meshToBeCopied.parent) newInstance.setParent(meshToBeCopied.parent);
    // because otherwise copied properties below won't make sense
    
    newInstance.copyWorldMatrixProperties(meshToBeCopied);
    newInstance.visibility = meshToBeCopied.visibility;
    newInstance.showBoundingBox = meshToBeCopied.showBoundingBox;
    
    if (arrayOperation) {
      //Things for array copy
      if (!meshToBeCopied.clones) meshToBeCopied.clones = [];
      meshToBeCopied.clones.push(newInstance.uniqueId);
      delete newInstance.clones; //Cloning mesh with 'clones' property puts in the clone as well
      newInstance.wasClonedFrom = meshToBeCopied;
    }
    
    // newInstance.room_id = room_ids[room_ids.length - 1] + 1;
    newInstance._properties = meshToBeCopied._properties;
    
    // room_ids.push(newInstance.room_id);
    newInstance.state = "off";
    newInstance.storey = meshToBeCopied.storey;
    // copy_mesh.ctrlCopy = newInstance;
    // copy_mesh.movementInProgress = true;
    
    let snaptrudeDS = meshToBeCopied.getSnaptrudeDS();
    addMeshToStructure(newInstance, meshToBeCopied);
    
    const newInstanceDS = newInstance.getSnaptrudeDS();
    if (snaptrudeDS.cloneProperties) {
      snaptrudeDS.cloneProperties(newInstanceDS, uniqueObject);
    }
    
    if (uniqueObject) {
      if (newInstanceDS.brep) {
        newInstance.material = meshToBeCopied.material;
        updateSubMeshes(
          newInstance,
          newInstanceDS.brep.getFaces(),
          newInstanceDS.faceFacetMapping
        );
      } else {
        copyMaterialData(sourceMesh, newInstance);
      }
    }
    
    _assignAdditionalThings(newInstanceDS, snaptrudeDS);
    
    if (handleChildren) _handleChildrenComponents(meshToBeCopied, newInstance);
    
    // onSolid(newInstance);
    // newInstance.getChildren().forEach(child => onSolid(child));
    // setLayerTransperancy(newInstance);
    
    store.copy_index = store.copy_index + 1;
    // setLayerTransperancy();
  }
  
  function _sendMeshToInfinity(mesh) {
    //Doing these things in arrayFunctionEvents
    /*
    // scaling change messes with BRep
    // mesh.scaling = BABYLON.Vector3.Zero();
    mesh.position = BABYLON.Vector3.One().scale(1000000);

    // mesh.infiniteDistance = true; misbehaves
    mesh.type += GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh;
    mesh.isVisible = false;
    */
  }
  
  
  // start of the body of pasteObject
  
  let uniqueObject = options.uniqueObject;
  let sendOriginalToInfinity = options.sendOriginalToInfinity;
  let arrayOperation = options.arrayOperation;
  let handleChildren = options.handleChildren;
  
  if (uniqueObject === undefined) uniqueObject = true;
  if (sendOriginalToInfinity === undefined) sendOriginalToInfinity = false;
  if (arrayOperation === undefined) arrayOperation = false;
  if (handleChildren === undefined) handleChildren = true;
  
  let structureCollection = StructureCollection.getInstance();
  
  if (sendOriginalToInfinity) {
    if (meshToBeCopied.isAnInstance) sendOriginalToInfinity = false;
  }
  
  if (meshToBeCopied.type === GLOBAL_CONSTANTS.strings.identifiers.boundingBox) {
    meshToBeCopied = meshToBeCopied.parentMesh;
  }
  
  // let sourceMesh = copy_mesh.sourceMesh || copy_mesh.source || copy_mesh;
  let sourceMesh = meshToBeCopied.sourceMesh || meshToBeCopied;
  let structure = structureCollection.getStructureById(meshToBeCopied.structure_id);
  
  if (!structure || !sourceMesh) return;
  
  let createInstance = ["Furniture", "Door", "Window"].includes(meshToBeCopied.type);
  let newInstance = _cloneObject(sourceMesh, meshToBeCopied);
  
  _assignInstanceProperties(newInstance);
  
  if (!arrayOperation) newInstance.setParent(null);
  // unclear whether parent relationship should remain.
  // in both cases, WM properties remain equivalent and newInstance is identically placed in the scene
  // UPDATE -
  // keeping parent relationship for array now, need it for parallel snap and things like that
  
  // if (!newInstance.isAnInstance) copyMaterialData(copy_mesh, newInstance);
  
  let returnObject;
  if (sendOriginalToInfinity && !uniqueObject) {
    let instanceToReplaceOriginal = _cloneObject(sourceMesh, meshToBeCopied);
    _assignInstanceProperties(instanceToReplaceOriginal);
    
    instanceToReplaceOriginal.clones = _.clone(meshToBeCopied.clones);
    _.remove(
      instanceToReplaceOriginal.clones,
      (id) => id === instanceToReplaceOriginal.uniqueId
    );
    
    newInstance.wasClonedFrom = instanceToReplaceOriginal;
    _sendMeshToInfinity(meshToBeCopied);
    
    if (!options.arrayOperation && !_.isEmpty(store.selectionStack)) {
      // can't do this for array since it can be 'escaped' unlike storey up
      let index = _.findIndex(
        store.selectionStack,
        (m) => m.uniqueId === meshToBeCopied.uniqueId
      );
      store.selectionStack[index] = instanceToReplaceOriginal;
    }
    
    onSolid(instanceToReplaceOriginal, false);
    setLayerTransperancy(instanceToReplaceOriginal);
    instanceToReplaceOriginal
      .getChildMeshes()
      .forEach((progeny) => onSolid(progeny, false));
    
    returnObject = {
      instanceToReplaceOriginal,
      newInstance,
    };
  } else {
    returnObject = newInstance;
  }
  
  onSolid(newInstance, false);
  setLayerTransperancy(newInstance);
  newInstance.getChildMeshes().forEach((progeny) => onSolid(progeny, false));
  
  return returnObject;
}

function isPickedfaceObject(pickInfo) {
  let mesh = pickInfo.pickedMesh;
  if (mesh.name === "ground1") {
    return false;
  }
  if (mesh.name === "ground2") {
    return false;
  }
  if (mesh.name === "backwall") {
    return false;
  }
  if (mesh.name === "backwallExtrude") {
    return false;
  }
  if (mesh.name === "referenceGroundExtrude") {
    return false;
  }
  if (mesh.name === "axisX") {
    return false;
  }
  if (mesh.name === "axisY") {
    return false;
  }
  if (mesh.name === "axisZ") {
    return false;
  }
  return true;
}
export { pasteObject, isPickedfaceObject };
