import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { click } from "./meshEvents.js";
import { snapVertData } from "./snapFuncs.js";
import { loadComponent2 } from "./interiors_gen.js";
import { doorOperation } from "../modules/meshoperations/doorOperation.js";
import { Wall } from "../modules/snaptrudeDS/wall.ds.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
function generateDoorData(door_data1) {
  for (var i = 0; i < door_data1.length; i++) {
    var pts1 = getOffsetPoints(door_data1[i], -5);
    var pts2 = getOffsetPoints(door_data1[i], 5);
    door_data1[i][0] = pts1[0];
    door_data1[i][1] = pts1[1];
    door_data1[i][2] = pts2[1];
    door_data1[i][3] = pts2[0];
  }
  return door_data1;
}

function generateWindowData(door_data1) {
  for (var i = 0; i < door_data1.length; i++) {
    var pts1 = getOffsetPoints(door_data1[i], -5);
    var pts2 = getOffsetPoints(door_data1[i], 5);
    door_data1[i][0] = pts1[0];
    door_data1[i][1] = pts1[1];
    door_data1[i][2] = pts2[1];
    door_data1[i][3] = pts2[0];
  }
  return door_data1;
}

function getOffsetPoints(points, d) {
  var a = points[0][1] - points[1][1];
  var b = points[1][0] - points[0][0];
  var c1 = points[0][0] * points[1][1] - points[1][0] * points[0][1];
  var c2 = a * points[0][1] - b * points[0][0];
  var c3 = a * points[1][1] - b * points[1][0];
  var c4 = d * Math.sqrt(a ** 2 + b ** 2) + c1;
  var pt1X = -(b * c2 + a * c4) / (a ** 2 + b ** 2);
  var pt1Y = (a * c2 - b * c4) / (a ** 2 + b ** 2);
  var pt2X = -(b * c3 + a * c4) / (a ** 2 + b ** 2);
  var pt2Y = (a * c3 - b * c4) / (a ** 2 + b ** 2);
  var pts = [];
  pts.push([pt1X, pt1Y]);
  pts.push([pt2X, pt2Y]);
  return pts;
}

function drawDoorBlocks(door_data, door_types, mat_name, lintel) {
  var door_pols = door_data;
  // var door_types = door_data[1];
  for (var i = 0; i < door_pols.length; i++) {
    var shape_temp1 = [];
    var shape_temp2 = [];
    var path = [];
    var path1 = [];
    var path2 = [];
    var center_point = new BABYLON.Vector3(0, 0, 0);
    var screen_scale = store.room_scale_2d * store.editor_scale;
    for (var j = 0; j < door_pols[i].length; j++) {
      var point = new BABYLON.Vector3(
        door_pols[i][j][0] * store.unit_scale,
        -door_pols[i][j][1] * store.unit_scale,
        0
      );
      shape_temp1.push(point);
      point = new BABYLON.Vector3(
        door_pols[i][j][0] * store.unit_scale,
        -door_pols[i][j][1] * store.unit_scale,
        store.floor_height - lintel
      );
      shape_temp2.push(point);
      point = new BABYLON.Vector3(
        door_pols[i][j][0] * store.unit_scale,
        store.floor_height - lintel,
        -door_pols[i][j][1] * store.unit_scale
      );
      path1.push(point);
      point = new BABYLON.Vector3(
        door_pols[i][j][0] * store.unit_scale,
        0,
        -door_pols[i][j][1] * store.unit_scale
      );
      path2.push(point);
      center_point.x += door_pols[i][j][0] * store.unit_scale;
      center_point.y += door_pols[i][j][1] * store.unit_scale;
    }

    shape_temp1.push(shape_temp1[0]);
    shape_temp2.push(shape_temp2[0]);
    path1.push(path1[0]);
    path2.push(path2[0]);

    var door_name = "door1";
    var door_type = "Vertical";
    // var door_instance = loadDoorInstance(door_name, center_point, door_pols[i], lintel, door_type, i);

    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp1,
      store.scene
    ).build(true);
    ribPol1.position.y = 0;
    ribPol1.material = store.scene.getMaterialByName("wall_mat");
    var ribPol2 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp2,
      store.scene
    ).build(true);
    ribPol2.position.y = store.floor_height - lintel;
    ribPol2.material = store.scene.getMaterialByName("wall_mat");
    var ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    ribbon.material = store.scene.getMaterialByName("wall_mat");
    var mergedMesh = BABYLON.Mesh.MergeMeshes(
      [ribPol1, ribPol2, ribbon],
      true,
      true
    );
    mergedMesh.checkCollisions = true;
    mergedMesh.door_type = door_types[i];
    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    mergedMesh.convertToFlatShadedMesh();
    mergedMesh.material = store.scene.getMaterialByName(mat_name);
    // shadowGenerator.getShadowMap().renderList.push(mergedMesh);

    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mergedMesh.setPivotPoint(centroid);
    mergedMesh.rotation.y = (door_types[i] * Math.PI) / 180;
    // mergedMesh.rotation.y = 90*Math.PI/180;
    // console.log(door_types[i]);

    if (door_types[i] == 0) {
      mergedMesh.position.x += (bbinfo.maximum.y - bbinfo.minimum.y) / 2;
      // mergedMesh.position.x += (bbinfo.maximum.y-bbinfo.minimum.y)/2;
      mergedMesh.position.x += (bbinfo.maximum.x - bbinfo.minimum.x) / 2;
      mergedMesh.position.x += (bbinfo.maximum.x - bbinfo.minimum.x) / 2;

      // mergedMesh.position.z -= (bbinfo.maximum.y-bbinfo.minimum.y)/2;
      // mergedMesh.position.z -= (bbinfo.maximum.y-bbinfo.minimum.y)/2;
      mergedMesh.position.z -= (bbinfo.maximum.z - bbinfo.minimum.z) / 2;
      // mergedMesh.position.z -= (bbinfo.maximum.x-bbinfo.minimum.x)/2;
    } else {
      mergedMesh.position.x -= (bbinfo.maximum.z - bbinfo.minimum.z) / 2;
      mergedMesh.position.z -= (bbinfo.maximum.z - bbinfo.minimum.z) / 2;
      mergedMesh.position.z -= (bbinfo.maximum.z - bbinfo.minimum.z) / 2;
      // mergedMesh.position.z -= (bbinfo.maximum.z-bbinfo.minimum.z)/2;
    }
    mergedMesh.showBoundingBox = true;
    mergedMesh.offsetFlag = false;
    var intersectingMesh = getIntersectingMesh(mergedMesh);
    // substractSolid(mergedMesh, intersectingMesh);
    click(mergedMesh);
  }
}

function substractSolid(mesh1, mesh2) {
  var aCSG = BABYLON.CSG.FromMesh(mesh1);
  var bCSG = BABYLON.CSG.FromMesh(mesh2);
  var subCSG = bCSG.subtract(aCSG);
  subCSG.toMesh(mesh2.name, mesh2.material, store.scene);
  mesh1.dispose();
  mesh2.dispose();
}

function getIntersectingMesh(mesh) {
  for (var i = 0; i < store.scene.meshes.length; i++) {
    if (
      store.scene.meshes[i].name.indexOf("room") != -1 ||
      store.scene.meshes[i].name.indexOf("wall") != -1
    ) {
      if (mesh.intersectsMesh(store.scene.meshes[i])) {
        return store.scene.meshes[i];
      }
    }
  }
}

function drawWindowBlocks(window_data, window_types, mat_name, lintel, plinth) {
  var window_pols = window_data;
  // var window_types = window_data[1];
  for (var i = 0; i < window_pols.length; i++) {
    var shape_temp1 = [];
    var shape_temp2 = [];
    var path = [];
    var path1 = [];
    var path2 = [];
    var center_point = new BABYLON.Vector3(0, 0, 0);
    for (var j = 0; j < window_pols[i].length; j++) {
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] * store.unit_scale,
        -window_pols[i][j][1] * store.unit_scale,
        plinth
      );
      point = snapVertData(point);
      shape_temp1.push(point);
      point = new BABYLON.Vector3(
        window_pols[i][j][0] * store.unit_scale,
        -window_pols[i][j][1] * store.unit_scale,
        store.floor_height - lintel
      );
      point = snapVertData(point);
      shape_temp2.push(point);
      point = new BABYLON.Vector3(
        window_pols[i][j][0] * store.unit_scale,
        store.floor_height - lintel,
        -window_pols[i][j][1] * store.unit_scale
      );
      point = snapVertData(point);
      path1.push(point);
      point = new BABYLON.Vector3(
        window_pols[i][j][0] * store.unit_scale,
        plinth,
        -window_pols[i][j][1] * store.unit_scale
      );
      point = snapVertData(point);
      path2.push(point);

      center_point.x += window_pols[i][j][0] * store.unit_scale;
      center_point.y += window_pols[i][j][1] * store.unit_scale;
    }
    shape_temp1.push(shape_temp1[0]);
    shape_temp2.push(shape_temp2[0]);
    path1.push(path1[0]);
    path2.push(path2[0]);

    var window_name = "window1";
    var window_type = "Vertical";
    // var window_instance = loadWindowInstance(window_name, center_point, window_pols[i], lintel, lintel, window_type, i);

    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp1,
      store.scene
    ).build(true);
    ribPol1.material = store.scene.getMaterialByName("wall_mat");
    ribPol1.position.y = plinth;
    var ribPol2 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp2,
      store.scene
    ).build(true);
    ribPol2.position.y = store.floor_height - lintel;
    ribPol2.material = store.scene.getMaterialByName("wall_mat");
    var ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    ribbon.material = store.scene.getMaterialByName("wall_mat");
    var mergedMesh = BABYLON.Mesh.MergeMeshes(
      [ribPol1, ribPol2, ribbon],
      true,
      true
    );
    mergedMesh.checkCollisions = true;
    mergedMesh.window_type = window_types[i];
    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    mergedMesh.convertToFlatShadedMesh();
    mergedMesh.material = store.scene.getMaterialByName(mat_name);
    // shadowGenerator.getShadowMap().renderList.push(mergedMesh);

    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mergedMesh.setPivotPoint(centroid);
    mergedMesh.rotation.y = (window_types[i] * Math.PI) / 180;
    if (window_types[i] == 0) {
      // mergedMesh.position.x += (bbinfo.maximum.y-bbinfo.minimum.y)/2;
      mergedMesh.position.x += (bbinfo.maximum.x - bbinfo.minimum.x) / 2;

      // mergedMesh.position.z -= (bbinfo.maximum.y-bbinfo.minimum.y)/2;
      // mergedMesh.position.z -= (bbinfo.maximum.z-bbinfo.minimum.z)/2;
      // mergedMesh.position.z -= (bbinfo.maximum.x-bbinfo.minimum.x)/2;
      // console.log(bbinfo);
    } else {
      // mergedMesh.position.x += (bbinfo.maximum.x-bbinfo.minimum.x)/2;
      mergedMesh.position.z -= (bbinfo.maximum.x - bbinfo.minimum.x) / 2;
    }

    mergedMesh.showBoundingBox = true;
    mergedMesh.offsetFlag = false;

    click(mergedMesh);
  }
}

function getDoorDataFplan() {
  let stepSize = [3.6, 3.6];
  let doorType = "single";
  let doors = store.floorkey_details[store.floorkey].doors;
  let doorPolygons = doors.pols;
  if (doorPolygons.length == 0) {
    console.log("No doors found to render");
    return 0;
  }
  let manvithDoor = store.scene.getMeshByName("defdoor");
  if (!manvithDoor) {
    let mediaPath = window.location.origin + "\\media\\media\\objects\\";
    let doorName = "defdoor";
    loadComponent2(mediaPath, doorName, "door");
    setTimeout(function () {
      manvithDoor = store.scene.getMeshByName("defdoor");
      console.log("MANVITH_DOOR3", manvithDoor);
      for (let i = 0; i < doorPolygons.length; i++) {
        let doorPolygon = doorPolygons[i];
        let newDoor = manvithDoor.createInstance(manvithDoor.name);
        let doorCenter = getCenterPointOfDoor(doorPolygon);
        let bbInfo = newDoor.getBoundingInfo();
        let doorCenterPoint = {
          x: doorCenter[0],
          y: bbInfo.boundingBox.maximumWorld.y / 2,
          z: doorCenter[1],
        };
        let doorRotation = getRotationOfDoor(doorPolygon);

        newDoor.type = "door";
        newDoor.position.x = doorCenterPoint.x * store.unit_scale;
        newDoor.position.z = -doorCenterPoint.z * store.unit_scale;
        let bbinfo = newDoor.getBoundingInfo();
        newDoor.position.y = bbinfo.maximum.y * newDoor.scaling.y;
        newDoor.computeWorldMatrix(true);
        newDoor.refreshBoundingInfo();
        newDoor.rotation.y = doorRotation + Math.PI / 2;
        let allMeshes = store.scene.meshes.filter(
          (mesh) => mesh.type == "wall"
        );
        for (let i = 0; i < allMeshes.length; i++) {
          let intersect = newDoor.intersectsMesh(allMeshes[i]);
          if (intersect) {
            newDoor.parent = allMeshes[i];
            let newDoorSelectionBox =
              doorOperation.createDoorSelectionBox(newDoor);
            newDoorSelectionBox.position.x =
              doorCenterPoint.x * store.unit_scale;
            newDoorSelectionBox.position.z =
              -doorCenterPoint.z * store.unit_scale;
            newDoorSelectionBox.rotation.y = doorRotation + Math.PI / 2;
            newDoorSelectionBox.position.y =
              bbinfo.maximum.y * newDoor.scaling.y;

            let doorCSG = BABYLON.CSG.FromMesh(newDoorSelectionBox);
            let wallCSG = BABYLON.CSG.FromMesh(newDoor.parent);
            let newCSG = wallCSG.subtract(doorCSG);
            let newWall = newCSG.toSnaptrudeMesh("wall", null, store.scene);
            newWall.type = "wall";
            let wall = new Wall(newWall, newDoor.parent.room_id);

            wall.assignProperties();
            wall.originalWallMesh =
              newDoor.parent.getSnaptrudeDS().originalWallMesh;

            let structures = StructureCollection.getInstance();
            wall.mesh.structure_id = newDoor.parent.structure_id;
            let structure_id = newDoor.parent.structure_id;
            let str = structures.getStructureById(wall.mesh.structure_id);
            let level = str.getLevelByUniqueId(
              str.getObjectByUniqueId(newDoor.parent.uniqueId).level_id
            );
            level.addWallToLevel(wall, false);

            newWall.childrenComp = newDoor.parent.childrenComp;
            newWall.childrenComp.push(newDoor);

            for (let j = 0; j < newWall.childrenComp.length; j++) {
              newWall.childrenComp[j].parent = newWall;
            }

            newDoor.structure_id = structure_id;
            level.addMeshToLevel(newDoor, false);
            level.removeObjectToLevel(allMeshes[i].getSnaptrudeDS());
            allMeshes[i].dispose();
            newDoorSelectionBox.dispose();
          }
        }
      }
      manvithDoor.position = new BABYLON.Vector3(10000, 10000, 10000);
      manvithDoor.scaling = new BABYLON.Vector3(0, 0, 0);
      manvithDoor.rotation = new BABYLON.Vector3(0, 0, 0);
    }, 3000);
  }
  return 1;
}

function getWindowDataFplan() {
  let stepSize = [3.6, 3.6];
  let windowType = "single";
  let windowsString = "windows";
  let windows = store.floorkey_details[store.floorkey][windowsString];

  let windowPolygons = windows.pols;
  if (windowPolygons.length == 0) {
    console.log("No windows found to render");
    return 0;
  }
  let manvithWindow = store.scene.getMeshByName("defwindow");
  if (!manvithWindow) {
    let mediaPath = window.location.origin + "\\media\\media\\objects\\";
    let doorName = "defwindow";
    loadComponent2(mediaPath, doorName, "window");
    setTimeout(function () {
      manvithWindow = store.scene.getMeshByName("defwindow");
      for (let i = 0; i < windowPolygons.length; i++) {
        let windowPolygon = windowPolygons[i];
        let newWindow = manvithWindow.createInstance(manvithWindow.name);
        let doorCenter = getCenterPointOfDoor(windowPolygon);
        let bbInfo = newWindow.getBoundingInfo();
        let doorCenterPoint = {
          x: doorCenter[0],
          y: bbInfo.boundingBox.maximumWorld.y / 2,
          z: doorCenter[1],
        };
        let doorRotation = getRotationOfDoor(windowPolygon);

        newWindow.type = "window";
        newWindow.position.x = doorCenterPoint.x * store.unit_scale;
        newWindow.position.z = -doorCenterPoint.z * store.unit_scale;
        let bbinfo = newWindow.getBoundingInfo();
        newWindow.position.y = bbinfo.maximum.y * newWindow.scaling.y;
        newWindow.computeWorldMatrix(true);
        newWindow.refreshBoundingInfo();
        newWindow.rotation.y = doorRotation + Math.PI / 2;

        let allMeshes = store.scene.meshes.filter(
          (mesh) => mesh.type == "wall"
        );
        for (let i = 0; i < allMeshes.length; i++) {
          let intersect = newWindow.intersectsMesh(allMeshes[i]);
          if (intersect) {
            newWindow.parent = allMeshes[i];
            newWindow.position.y =
              (allMeshes[i].getBoundingInfo().maximum.y +
                allMeshes[i].getBoundingInfo().minimum.y) /
              2;
            let newWindowSelectionBox =
              doorOperation.createDoorSelectionBox(newWindow);
            newWindowSelectionBox.position.x =
              doorCenterPoint.x * store.unit_scale;
            newWindowSelectionBox.position.z =
              -doorCenterPoint.z * store.unit_scale;
            newWindowSelectionBox.rotation.y = doorRotation + Math.PI / 2;
            newWindowSelectionBox.position.y =
              (allMeshes[i].getBoundingInfo().maximum.y +
                allMeshes[i].getBoundingInfo().minimum.y) /
              2;

            let doorCSG = BABYLON.CSG.FromMesh(newWindowSelectionBox);
            let wallCSG = BABYLON.CSG.FromMesh(newWindow.parent);
            let newCSG = wallCSG.subtract(doorCSG);
            let newWall = newCSG.toSnaptrudeMesh("wall", null, store.scene);
            newWall.type = "wall";
            let wall = new Wall(newWall, newWindow.parent.room_id);
            wall.assignProperties();
            wall.originalWallMesh =
              newWindow.parent.getSnaptrudeDS().originalWallMesh;
            let structures = StructureCollection.getInstance();
            wall.mesh.structure_id = newWindow.parent.structure_id;
            let structure_id = newWindow.parent.structure_id;
            let str = structures.getStructureById(wall.mesh.structure_id);
            let level = str.getLevelByUniqueId(
              str.getObjectByUniqueId(newWindow.parent.uniqueId).level_id
            );
            level.addWallToLevel(wall, false);
            newWall.childrenComp = newWindow.parent.childrenComp;
            newWall.childrenComp.push(newWindow);
            for (let j = 0; j < newWall.childrenComp.length; j++) {
              newWall.childrenComp[j].parent = newWall;
            }
            newWindow.structure_id = structure_id;
            level.addMeshToLevel(newWindow, false);
            level.removeObjectToLevel(allMeshes[i].getSnaptrudeDS());
            allMeshes[i].dispose();
            newWindowSelectionBox.dispose();
          }
        }
      }
      manvithWindow.position = new BABYLON.Vector3(10000, 10000, 10000);
      manvithWindow.scaling = new BABYLON.Vector3(0, 0, 0);
      manvithWindow.rotation = new BABYLON.Vector3(0, 0, 0);
    }, 3000);
  }
  return 1;
}

function getCenterPointOfDoor(doorPolygon) {
  let doorCenterPointX = 0;
  let doorCenterPointY = 0;
  for (let j = 0; j < doorPolygon.length; j++) {
    doorCenterPointX = doorCenterPointX + doorPolygon[j][0];
    doorCenterPointY = doorCenterPointY + doorPolygon[j][1];
  }
  doorCenterPointX = doorCenterPointX / doorPolygon.length;
  doorCenterPointY = doorCenterPointY / doorPolygon.length;
  return [doorCenterPointX, doorCenterPointY];
}

function getRotationOfDoor(doorPolygon) {
  let firstEdge = [doorPolygon[0], doorPolygon[1]];
  let secondEdge = [doorPolygon[1], doorPolygon[2]];
  if (lengthOfEdge(firstEdge) < lengthOfEdge(secondEdge)) {
    return getDoorAngle(firstEdge);
  }
  return getDoorAngle(secondEdge);
}

function lengthOfEdge(edge) {
  return distanceBetweenPoints(edge[0], edge[1]);
}

function distanceBetweenPoints(pt1, pt2) {
  return ((pt2[0] - pt1[0]) ** 2 + (pt2[1] - pt1[1]) ** 2) ** 0.5;
}

function getDoorAngle(edge) {
  let slope = getSlopeForDoor(edge);
  if (slope == "inf") {
    return 1.5708;
  }
  return Math.atan(slope);
}

function getSlopeForDoor(edge) {
  let denominator = edge[1][0] - edge[0][0];
  if (denominator == 0) {
    return "inf";
  }
  let numerator = edge[1][1] - edge[0][1];
  return numerator / denominator;
}
export {
  generateDoorData,
  generateWindowData,
  getOffsetPoints,
  drawDoorBlocks,
  substractSolid,
  getIntersectingMesh,
  drawWindowBlocks,
  getDoorDataFplan,
  getWindowDataFplan,
  getCenterPointOfDoor,
  getRotationOfDoor,
  lengthOfEdge,
  distanceBetweenPoints,
  getDoorAngle,
  getSlopeForDoor,
};
