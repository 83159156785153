import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import TopNav from "../../../components/TopNav/index";
import Account from "./Account";
import Team from "./Team";
import Billing from "./Billing";
import Plans from "./Plans";
import * as constants from "./constants";
import { routes } from "../../../routes/constants";

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.colors.fullWhite};
`;

const ContentContainer = styled.div`
  padding: 1rem 2.5rem;
  width: 100%;
`;

class Profile extends React.Component {
  setSelectedOption = (option) => {
    const { history } = this.props;
    switch (option) {
      case constants.ACCOUNT:
        history.push(routes.account);
        break;
      case constants.TEAM:
        history.push(routes.teams);
        break;
      case constants.BILLING:
        history.push(routes.billing);
        break;
      case constants.PLANS:
        history.push(routes.plans);
        break;
      default:
        break;
    }
  };

  getComponentByItemType = () => {
    const { selectedOption } = this.props;
    switch (selectedOption) {
      case constants.ACCOUNT:
        return <Account />;
      case constants.TEAM:
        return <Team />;
      case constants.BILLING:
        return <Billing />;
      case constants.PLANS:
        return <Plans />;
      default:
        return <div />;
    }
  };

  render() {
    const { selectedOption } = this.props;
    return (
      <MainContainer>
        <ContentContainer>
          {selectedOption !== constants.PLANS && (
            <TopNav
              navOptions={constants.NAV_OPTIONS}
              selectedOption={selectedOption}
              onPress={this.setSelectedOption}
            />
          )}

          {this.getComponentByItemType()}
        </ContentContainer>
      </MainContainer>
    );
  }
}

Profile.propTypes = {};

export default withRouter(Profile);
