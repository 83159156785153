import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';
import { acceptTeamInvitation, getPendingTeamInvites, rejectTeamInvitation } from '../../services/team.service';
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc';
import NamedAvatar from '../Avatar/NamedAvatar';
import Divider from '../Divider';

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 20rem;
    height: 70vh;
    position: fixed;
    left: 285px;
    bottom: 4.5rem;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    filter: drop-shadow(0px 4px 10px rgba(197, 202, 220, 0.3));
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 5px;
    padding: 16px 18px;
    &:before{
        content: '';
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        position: absolute;
        left: -5px;
        background: #ffffff;
    }

    .disabled{
        opacity: 0.5;
        pointer-events: none;
    }

    .notification-wrapper{
        display: grid;
        grid-template-columns: 1fr 4fr;
        margin: 10px 0px;
    }

    .notification-icon{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .notification-content{
    }
    .notification-message{

    }
    .notification-time{
        color: #818181;
        font-size: small;
        margin: 10px 0px;
    }
    .notification-actions{
        display: flex;
        justify-content: space-between;
    }
    button{
        background: #EC1C45;
        border-radius: 5px;
        padding: 8px 16px;
        border: 0px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .btn-negative{
        border: 0.5px solid #818181;
        background: #FFFFFF;
        color: #2D2D2E;
    }
`

function TeamInviteNotification({invite, updateTeamInviteStatus, index}){
    const ref = useRef(null);

    const handleJoin = async () => {
        ref.current.classList.add('disabled');
        const resp = await acceptTeamInvitation(invite.invitation.token, invite.team.id);
        if(resp.status === "success"){
            if(resp.data.status.includes("approved")){
                updateTeamInviteStatus("accepted", index)
                showToast("Team invitation accepted");
            }else{
                showToast(resp.data.status, 3000, TOAST_TYPES.error);
            }
        }else{
            ref.current.classList.remove('disabled');
            showToast(resp.message, 3000, TOAST_TYPES.error);
        }
    }
    const handleDecline = async () => {
        ref.current.classList.add('disabled');
        const resp = await rejectTeamInvitation(invite.invitation.token, invite.team.id);
        if(resp.status === "success"){
            if(resp.data.status.includes("rejected")){
                updateTeamInviteStatus("rejected", index)
                showToast("Team invitation rejected");
            }else{
                showToast(resp.data.status, 3000, TOAST_TYPES.error);
            }
        }else{
            ref.current.classList.remove('disabled');
            showToast("Could not reject invitation", 3000, TOAST_TYPES.error);
        }
    }
    return (
        <div className='notification-wrapper' ref={ref}>
            <div className='notification-icon'>
                <NamedAvatar 
                    id={invite.team.admin.name || invite.team.admin.email} 
                    text={invite.team.admin.name || invite.team.admin.email} 
                    style={{margin: "0px"}}
                />
            </div>
            <div className='notification-content'>
                <div className='notification-message'>
                    <strong>{invite.team.admin.name || invite.team.admin.email}</strong> has invited you to their team <strong>{invite.team.name}</strong>
                </div>
                <div className='notification-time'></div>
                <div className='notification-actions'>
                    <button onClick={handleJoin}>Join</button>
                    <button onClick={handleDecline} className='btn-negative'>Decline</button>
                </div>
            </div>
        </div>
    )
}

function NotificationBar({teamInvites, setTeamInvites, handleClose}) {
    const ref = useRef(null);
    useOutsideClickHandler(ref, handleClose);
    const updateTeamInviteStatus = (status, index) => {
        const newTeamInvites = teamInvites.map((invite, idx) => {
            if(index === idx){
                invite.status = status;
            }
            return {
                ...invite
            }
        })
        setTeamInvites(newTeamInvites);
    }
    return ( 
        <Wrapper ref={ref}>
            <Divider />
            {
                teamInvites?.filter(invite => invite.status === "pending").map((invite, index) => {
                   return (
                    <>
                        <TeamInviteNotification invite={invite} updateTeamInviteStatus={updateTeamInviteStatus} index={index} />
                        <Divider />
                    </>
                   ) 
                })
            }
        </Wrapper>
     );
}

export default NotificationBar;