import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { commentOperator } from '../../snaptrude/libs/commentEvents';
import { showToast } from '../../snaptrude/modules/extrafunc';
import { socialConnect } from '../../snaptrude/modules/social/socialSocket';
import { store } from '../../snaptrude/modules/utilityFunctions/Store';
import { colors } from '../../themes/constant';
import Button from '../Button';
import Divider from '../Divider';

const Wrapper = styled.div`
    display: flex;
    z-index: 20;
    transition: 1s;
`
const CommentNumber = styled.div`
    display: ${props => props.isReply ? "none" : "flex"};
    margin: 5px 15px;
    // display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EB1E45;
    color: white;
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 1024px;
    border-bottom-right-radius: 0px;
    transform: rotate(45deg);
    span{
        transform: rotate(-45deg);
    }
`
const CommentBox = styled.div`
    background: ${colors.fullWhite};
    padding: 10px 20px;
    width: 25rem;
    border-radius: ${props => props.isReply ? "0px 0px 8px 8px" : "8px"};
    border-top: ${props => props.isReply ? `1px solid ${colors.veryLightGrey}` : "0"};
    box-shadow: ${props => props.isReply ? `8.87059px 12px 20.4706px ${colors.shadowGrey}` : `8.87059px 4.77647px 20.4706px ${colors.shadowGrey}`};
`
const TextArea = styled.textarea`
    width: 100%;
    resize: none;
    box-sizing: border-box;
    outline: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    min-height: 2.5em;
    padding: 0.5em;
    background-color: ${colors.white};
    border-radius: 5px;
    margin: 5px 0px;
    color: ${colors.darkGrey};
`
const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: ${props => props.isReply ? "0" : `1px solid ${colors.veryLightGrey}`};
    padding: 0px 20px;
    margin: 0px -20px;
`

const SCENE_DATA_FOR_REPLY = {
    isAttachedToScene: false,
    camera: null,
    component: null,
    absolutePosition: null
}

function AddComment({onClose, isReply, handleReply}) {
    const [commentText, setCommentText] = useState("");
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const comments = useSelector(state => state.comments);
    const handleAdd = async () => {
        if(isReply) {
            store.newComment.sceneData = SCENE_DATA_FOR_REPLY;
            window.analytics.track('comment modal reply');
            handleReply(commentText)
        }else{
            setIsSubmitLoading(true);
            window.analytics.track('comment modal post');
            const success = await socialConnect.postComment(commentText, null, store.newComment.sceneData);
            setIsSubmitLoading(false);
            if(!success) showToast("Could not add comment, try again.");
            else{
                commentOperator.showNewlyAddedComment();
                handleCancel();
            } 
        }
    }

    const handleCancel = () => {
        window.analytics.track('comment modal cancel');
        setCommentText("");
        onClose();
    }
    return (
        <Wrapper id="add-comment-container">
            <CommentNumber isReply={isReply}>
                <span>{comments.comments.length + 1}</span>
            </CommentNumber>
            <CommentBox isReply={isReply}>
                <TextArea autoFocus value={commentText} onChange={(evt) => {setCommentText(evt.target.value)}} placeholder={ isReply ? "Reply" : "Add a comment"}></TextArea>
                <Controls isReply={isReply}>
                    <Divider />
                   <ButtonWrapper style={{paddingLeft: "0em"}}>
                        <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} title={"Cancel"} onPress={handleCancel} />
                    </ButtonWrapper>
                    <ButtonWrapper style={{paddingRight: "0em"}}>
                        <Button isLoading={isSubmitLoading} primary={commentText !== ""} disabled={commentText === ""} title={isReply ? "Reply" : "Post"} onPress={handleAdd} />
                    </ButtonWrapper>
                </Controls>
            </CommentBox>
        </Wrapper>
    )
}

export default AddComment
