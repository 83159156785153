import styled from "styled-components";
import React from "react";
import GoogleMapReact from "google-map-react";
import locationPin from "../../assets/icons/locationPin.svg";
import { colors } from "../../themes/constant";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  width: 5.6875rem;
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.333333px;
    color: ${colors.dullgrey};
  }
`;

const MapContainer = styled.div`
  height: 6.875rem;
  width: 100%;
`;

const Pin = styled.div`
  img {
    width: 0.73375rem;
    height: 1.044375rem;
  }
`;

const AnyReactComponent = () => (
  <Pin>
    <img src={locationPin} alt="location" />
  </Pin>
);

const MapComponent = (props) => {
  const { width, height } = props;
  return (
    <Main>
      <MapContainer style={{ width, height }}>
        <GoogleMapReact
          //   bootstrapURLKeys={{
          //     key: YOUR_API_KEY,
          //     language: "en",
          //   }}
          defaultCenter={{
            lat: 59.95,
            lng: 30.33,
          }}
          defaultZoom={11}
        >
          <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
        </GoogleMapReact>
      </MapContainer>
      <TextContainer>
        <p>Or select location on map</p>
      </TextContainer>
    </Main>
  );
};

export default MapComponent;
