import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import polygon from "../../../assets/icons/polygon.svg";
import { SearchInput } from "../../../components/searchInput/index";
import { RecentBox } from "../../../components/SortByBox/index";
import Dropdown from "../../../components/Dropdown/index";
import { projects } from "../constants";
import Card from "../../../components/Card/index";
import Upgrade from "../../../components/Card/Upgrade";
import TestPic from "../test.jpg";
import GenericModal from "../../genericModal/index";
import { SHARE_MODAL } from "../../genericModal/constants";
import * as constants from "./constants";
import * as styles from "./styles";
import Axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import AuthService from "../../../services/auth.service";
import { useHistory } from "react-router-dom";
import HistoryContext from "../../../components/Context/HistoryContext";
import { routes } from "../../../routes/constants";
import { getProjects, searchProjects } from "../../../services/projects.service";
import { ORIGIN } from "../../../services/url.constants";
import mixpanel from "mixpanel-browser";
import { dashboardService } from "../../../snaptrude/modules/utilityFunctions/dashboardService";
import Button from "../../../components/Button";
import { colors } from "../../../themes/constant";
import { getIsPro } from "../../../services/payment.service";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { selectTeam } from "../../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import TeamInviteModal from "../../../components/TeamModal.js";
import createProjectGraphic from "../../../assets/images/createProjectGraphic.svg";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 5;
`;

const inviteFriendsStyles = {
  button: {
    border: `2px solid ${colors.red}`,
    background: colors.transparent,
    padding: "0px",
    width: "16em"
  },
  buttonText: {
    color: `${colors.brightRed}`,
  },
};

const WorkSPace = (props) => {
  const [open, setOpen] = useState(false);
  const loadingRef = useRef(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [permission, setPermission] = useState("Anyone With the link can view");
  const [subPermission, setSubPermission] = useState("Can View");
  const [sortBy, setSortBy] = useState("Recent");
  const [searchText, setSearchText] = useState("");
  const [isPro, setIsPro] = useState(false);
  const dropdownRef = useRef(null);
  const toggle = () => setOpen(!open);
  const [serverProjects, projectsSet] = React.useState([]);
  const history = useContext(HistoryContext);
  const { workspaceHeading, setProjectsCount, projectsCount, handleInviteModalOpen } = props;
  const [isStudent, setIsStudent] = useState(false);
  const [proDeadline, setProDeadline] = useState("");
  const [showSearchedProjects, setShowSearchedProjects] = useState(false);
  const [searchedProjects, setSearchedProjects] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectTeam(null))
  }, []);
  async function fetchProjects() {
    let user = AuthService.getCurrentUser();
    getProjects(serverProjects.length)
    .then(res => {
      // console.log(res);
      if(res.error) history.push(routes.login);
      else {
        if(res.length > 0){
          let newServerProjects = [...serverProjects, ...res.projects];
          dashboardService.createList(newServerProjects);
          newServerProjects = searchProject(newServerProjects);
          newServerProjects = getSortedProjects(newServerProjects, sortBy);
          projectsSet(newServerProjects);
          setProjectsCount(newServerProjects.length);
          localStorage.setItem("numberOfProjects", newServerProjects.length);
        }
      }
    })
    .catch(err => {
      console.log("Error in getting projects", err)
    })
  }

  React.useEffect(() => {
    window.analytics.track('Start Build Page');
    const activePlan = AuthService.getActivePlan();
    if(activePlan && activePlan.name === "student"){
      setIsStudent(true);
      setProDeadline(activePlan.remaining)
    }
    fetchProjects();
  }, []);

  useEffect(() => {
      getIsPro().then((data) => {
        setIsPro(data.isPro);
      })
  }, []);
  
  const handleSelect = (item) => {
    switch (item) {
      case "Share":
        setOpenShareModal(true);
        break;
      default:
        break;
    }
    toggle(!open);
  };

  const searchProject = (existingProjects = []) => {
    const projects = dashboardService.getList();
    if(projects){
      if(searchText === "") return projects.list;
      return projects.search(searchText);
    }
    return existingProjects;
  }


  const getImageUrl = threeDUrl => {
    return ORIGIN + '/media/' + threeDUrl;
  };

  const getSortedProjects = (unsortedProjects, newSortBy) => {
    setSortBy(newSortBy);
    const projects = unsortedProjects.slice();
    switch (newSortBy) {
      case "Recent":
        projects.sort((a, b) => {
          const A = new Date(a.updated);
          const B = new Date(b.updated);
          return B-A;
        })
        break;
      case "Created Date":
        projects.sort((a, b) => {
          const A = new Date(a.added);
          const B = new Date(b.added);
          return B-A;
        })
        break;
      case "Name":
        projects.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        break;
      default:
        break;
    }
    return projects;
  }

  const handleSortBy = (item) => {
    let sortedProjects = getSortedProjects(serverProjects, item);
    projectsSet(sortedProjects);
  }

  const debounceSearch = useCallback(
    debounce(async (text) => {
      const {success, data, message} = await searchProjects(text)
      if(success){
        setShowSearchedProjects(true)
        setSearchedProjects([]);
        setSearchedProjects(data);
      }else{
        console.warn(message);
      }
    }, 500),
    []
  )

  const handleSearchTextChange = (evt) => {
    const {value} = evt.target;
    setSearchText(value);
    if(!value){
      setShowSearchedProjects(false);
    }else{
      debounceSearch(value);
    }
  }

  const takeToProject = (floorkey) => {
    // window.location.replace("/model/"+floorkey)
    history.push("/model/"+floorkey);
  }


  return (
    <>
      {
        serverProjects.length === 0 && 
        <img src={createProjectGraphic}  style={{position: "absolute", left: "16rem", top: "7rem"}} alt={""}/>
      }
      <MainContainer>
        <div className="content">
          <div className="header">
            <span className="project-header">
              {/* <h3
                onClick={() => {
                  dropdownRef.current.firstChild.click();
                }}
                style={styles.workspaceStyles.projectHeaderH3}
              >
                {workspaceHeading}
              </h3>
              <Dropdown
                ref={dropdownRef}
                icon={polygon}
                items={constants.DROPDOWN_ITEMS}
                selected={handleSelect}
                customDropdownStyles={
                  styles.workspaceStyles.customDropdownStyles
                }
                customPaddingStyles="0.3rem"
                align={"left"}
              /> */}
            </span>
            <div className="header-div">
              <RecentBox
                seperate={true}
                header={constants.RECENT_BOX_HEADER}
                optionAppender={constants.RECENT_BOX_HEADER}
                options={constants.RECENT_BOX_OPTIONS}
                customDropDownStyle={
                  styles.workspaceStyles.recentBoxDropdownStyles
                }
                selectOption={handleSortBy}
              />
              <SearchInput 
                value={searchText} 
                onChange={handleSearchTextChange} 
              />
              {
                isStudent &&
                <div style={{position: "relative"}}>
                  <Button
                    type="button"
                    title={"Invite Friends"}
                    customButtonStyle={inviteFriendsStyles.button}
                    customButtonTextStyle={inviteFriendsStyles.buttonText}
                    onPress={handleInviteModalOpen}
                  />
                  <div style={{position: "absolute", fontSize: "13px", color: colors.red, padding: "5px"}}>{proDeadline}</div>
                </div>
              }  
            </div>
          </div>
          {
            searchText &&
            <div className="cards">
              {
                searchedProjects.map((project, index) => {
                  return (
                      <Card
                        onClick={() => {
                          window.analytics.track('opened an existing project from dashboard');
                          takeToProject(project.key);
                        }}
                        headerText={project.name}
                        floorkey={project.key}
                        description={project.desc === "None" ? "" : project.desc}
                        footerText={`Last updated on ${new Date(project.updated).toLocaleString()}`}
                        dropdownOptions={isPro ? constants.CARD_DROPDOWN_OPTIONS : []}
                        avatars={[]}
                        key={index}
                        image={getImageUrl(project.threeDUrl)}
                      />
                  );
                })
              }
            </div>
          }
          <InfiniteScroll
            dataLength={serverProjects.length}
            next={fetchProjects}
            hasMore={true}
            style={{minHeight: "85vh"}}
          >
            <div className="cards">
              {!searchText && serverProjects.map((project, index) => {
                return (
                  // <a href={"/model/" + project.key} key={project.key} style={{textDecoration:"none"}}>
                    <Card
                      onClick={() => {
                        window.analytics.track('opened an existing project from dashboard');
                        takeToProject(project.key);
                      }}
                      headerText={project.name}
                      floorkey={project.key}
                      description={project.desc === "None" ? "" : project.desc}
                      footerText={`Last updated on ${new Date(project.updated).toLocaleString()}`}
                      dropdownOptions={isPro ? constants.CARD_DROPDOWN_OPTIONS : []}
                      avatars={[]}
                      key={index}
                      image={getImageUrl(project.threeDUrl)}
                      // cardWrapperStyle={{border: "5px solid cadetblue", width: "29rem"}}
                    />
                  // </a>
                );
              })}
              {
                isPro ? <></>
                :
                projectsCount === 0 ?
                  <Upgrade style={{flexBasis: "28%", margin: "1%", position: "absolute", bottom: "15%"}} projectsCount={projectsCount} handleInviteModalOpen={handleInviteModalOpen} onPress={() => {window.analytics.track('clicked upgrade plan in infinite scroll');}} />
                  :
                  <Upgrade style={{flexBasis: "28%", margin: "1%", height: "auto", justifyContent: "space-evenly"}} projectsCount={projectsCount} handleInviteModalOpen={handleInviteModalOpen} onPress={() => {window.analytics.track('clicked upgrade plan in infinite scroll');}} />              
              }
            </div>
          </InfiniteScroll>
        </div>
      </MainContainer>
      <GenericModal
        modalType={SHARE_MODAL}
        onClose={() => setOpenShareModal(false)}
        isOpen={openShareModal}
        modalHeaderText={"Share"}
        customProps={{
          permission,
          setPermission,
          subPermission,
          setSubPermission,
        }}
      />
    </>
  );
};

export default WorkSPace;
