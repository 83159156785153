import { onSolid } from "../extrafunc";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import { createCustomMeshFromRevitExport } from "./extraFunctions";
import { checkStorey } from "./storey";

const createStructuralFramings = (structuralFramingsData, materialList) => {

  for (const structuralFramingId in structuralFramingsData) {
    try {
      const structuralFramingData = structuralFramingsData[structuralFramingId];
      const data = structuralFramingData["data"];

        if (!structuralFramingData || !data) return;
        const mesh = createCustomMeshFromRevitExport(
          data,
          structuralFramingData,
          materialList,
          { isMassType: true }
        );

        if (!mesh) continue;
        let structuralFramingDS = new Mass(mesh);
        mesh.structure_id = store.activeLayer.structure_id;

        mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
        structuralFramingDS.storey = 1;

        structuralFramingDS.room_type = "Default";
        structuralFramingDS.massType = data.category;
        structuralFramingDS.revitMetaData = { elementId: data.elementId };
        mesh.storey = 1;
        structuralFramingDS.storey = 1;
        structuralFramingDS.structure_id = store.activeLayer.structure_id;

        const structureCollection = StructureCollection.getInstance();
        const talkingAboutStructure = structureCollection.getStructureById(
          store.activeLayer.structure_id
        );
        const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
        talkingAboutLevel.addObjectToLevel(structuralFramingDS, false);
        const storey = checkStorey(data?.levelName);
        if (storey) {
          storey.addElement(structuralFramingDS);
        } else {
          StoreyMutation.assignStorey(structuralFramingDS);
        }
        StoreyMutation.assignStorey(structuralFramingDS);
        
      
    } catch (e) {
      console.warn(e, "Corrupt StructuralFramings data");
    }
  }
};

export { createStructuralFramings };
