import BABYLON from "../babylonDS.module.js";
import { store } from "./Store.js"
const colorUtil = (function () {
  let color, material;
  function init() {
    console.log("ColorUtil initialised.")
    color = {
      // Values from https://rgbcolorcode.com
  
      red: _getColor(1.0, 0.0, 0.0),
      lawngreen: _getColor(0.0, 1.0, 0.0),
      blue: _getColor(0.0, 0.0, 1.0),
      cyan: _getColor(0.0, 1.0, 1.0),
      magenta: _getColor(1.0, 0.0, 1.0),
      yellow: _getColor(1.0, 0.96, 0.0),
      purple: _getColor(0.54, 0.17, 0.89),
      grey: _getColor(0.52, 0.52, 0.51),
      darkgreen: _getColor(0.0, 0.3, 0.0), //pakistanGreen
      black: _getColor(0.0, 0.0, 0.0),
      white: _getColor(1.0, 1.0, 1.0),
    };
  
    material = {
      red: _getMaterialForColor("red", color.red),
      lawngreen: _getMaterialForColor("lawngreen", color.lawngreen),
      blue: _getMaterialForColor("blue", color.blue),
      cyan: _getMaterialForColor("cyan", color.cyan),
      magenta: _getMaterialForColor("magenta", color.magenta),
      yellow: _getMaterialForColor("yellow", color.yellow),
      purple: _getMaterialForColor("purple", color.purple),
      grey: _getMaterialForColor("grey", color.grey),
      darkgreen: _getMaterialForColor("darkgreen", color.darkgreen),
      black: _getMaterialForColor("black", color.black),
      white: _getMaterialForColor("white", color.white),
    };
  }
  function _getMaterialForColor(name, color) {
    let material = new BABYLON.StandardMaterial(name + "Material", store.newScene);
    material.diffuseColor = color;

    return material;
  }

  function _getColor(r, g, b) {
    return new BABYLON.Color3(r, g, b);
  }

  const type = {
    // css names
    preSnap: "cyan",
    postSnap: "yellow",
    orthogonal: "purple",
    parallel: "magenta",
    nonEditable: "grey",
    normal: "darkgreen",
    x: "red",
    y: "lawngreen",
    z: "blue",
    black: "black",
    white: "white",
  };

  function getMaterial(color) {
    return material[color];
  }

  function getColor(colorArg) {
    return color[colorArg];
  }

  function getColorNameForType(typeArg) {
    return type[typeArg];
  }

  return {
    init,
    color,
    material,
    type,
    getMaterial,
    getColor,
    getColorNameForType,
  };
})();
export { colorUtil };
