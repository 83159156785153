/*jshint esversion: 6 */
"use strict";
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js"
import { StraightWall } from "./wall.types.js";
import { curved } from "./room.types.js";
import { PlainFloor } from "./floor.types.js";
import { PlainRoof } from "./roof.types.js";

/**
 * { Factory pattern to create objects }
 *
 * @class      Factory (name)
 * @return     {(CurvedWall|StraightWall)}  { description_of_the_return_value }
 */
var Factory = function () {
  /**
   * Creates a wall.
   *
   * @param      {string}                     category  The category
   * @param      {<type>}                     points    The points
   * @return     {(CurvedWall|StraightWall)}  { description_of_the_return_value }
   */
  this.createWall = function (category, points) {
    var wall;

    if (category === "straight") {
      wall = StraightWall(points);
    } else if (category === "curved") {
      wall = new curved(points);
    }

    if (wall) wall.category = category;

    //DO anything else here
    return wall;
  };
  /**
   * Creates a door.
   */
  this.createDoor = function () {
    //TODO add door;
  };
  /**
   * Creates stairs.
   */
  this.createStairs = function () {
    //TOD add stairs;
  };

  /**
   * Creates a floor.
   *
   * @param      {<type>}      category  The category
   * @param      {<type>}      points    The points
   * @return     {PlainFloor}  { description_of_the_return_value }
   */
  this.createFloor = function (category, points) {
    var floor;

    if (category === "plain") floor = new PlainFloor(points);
    else if (category === "somethingElse") {
      //TODO add new types here
    }

    floor.category = category;
    return floor;
  };
  /**
   * Creates roofs.
   *
   * @param      {<type>}      category  The category
   * @param      {<type>}      points    The points
   * @return     {PlainFloor}  { description_of_the_return_value }
   */
  this.createRoof = function (category, points) {
    var roof;

    if (category === "plain") roof = new PlainRoof(points);
    else if (category === "somethingElse") {
      //TODO add new types here
    }

    roof.category = category;
    return roof;
  };
  /**
   * Creates a x axis cylinder.
   *
   * @param      {<type>}  mesh    The mesh
   * @return     {<type>}  { description_of_the_return_value }
   */
  this.createXAxisCylinder = function (mesh, bbinf = null) {
    mesh.refreshBoundingInfo();
    let bbinfo = bbinf || mesh.getBoundingInfo();
    let size = bbinfo.diagonalLength * 2.5;
    if (size < 5) {
      size = 5;
    }
    var axisX = BABYLON.Mesh.CreateBox(
      "moveMeshAxisX",
      0.4,
      store.scene,
      false,
      BABYLON.Mesh.DOUBLESIDE
    );
    var axisMat = store.scene.getMaterialByName("axisXMat");
    if (axisMat) axisX.material = axisMat;
    else {
      axisX.material = new BABYLON.StandardMaterial("axisXMat", store.scene);
      axisX.material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);
      axisX.material.directIntensity = 5.0;
      axisX.material.backFaceCulling = false;
      axisX.material.environmentIntensity = 1.0;
      axisX.material.specularIntensity = 0.3;
      axisX.material.microSurface = 0.3;
      axisX.material.specularColor = new BABYLON.Color3(1.0, 0.0, 0.0);
    }

    // axisX.material = new BABYLON.StandardMaterial("axisXMat", store.scene);
    // axisX.material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);
    // axisX.material.directIntensity = 5.0;
    // axisX.material.backFaceCulling = false;
    // axisX.material.environmentIntensity = 1.0;
    // axisX.material.specularIntensity = 0.3;
    // axisX.material.microSurface = 0.3;
    // axisX.material.specularColor = new BABYLON.Color3(1.0, 0.0, 0.0);
    axisX.scaling.x = size;
    axisX.scaling.y = 0.5;
    axisX.scaling.z = 0.5;
    let center = bbinfo.boundingBox.centerWorld;
    axisX.position = center;
    return axisX;
  };
  /**
   * Creates an y axis cylinder.
   *
   * @param      {<type>}  mesh    The mesh
   * @return     {<type>}  { description_of_the_return_value }
   */
  this.createYAxisCylinder = function (mesh, bbinf = null) {
    mesh.refreshBoundingInfo();
    let bbinfo = bbinf || mesh.getBoundingInfo();
    let size = bbinfo.diagonalLength * 2.5;
    if (size < 5) {
      size = 5;
    }
    var axisY = BABYLON.Mesh.CreateBox(
      "moveMeshAxisY",
      0.4,
      store.scene,
      false,
      BABYLON.Mesh.DOUBLESIDE
    );
    var axisMat = store.scene.getMaterialByName("axisYMat");
    if (axisMat) axisY.material = axisMat;
    else {
      axisY.material = new BABYLON.StandardMaterial("axisYMat", store.scene);
      axisY.material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 0.0);
      axisY.material.directIntensity = 5.0;
      axisY.material.backFaceCulling = false;
      axisY.material.environmentIntensity = 1.0;
      axisY.material.specularIntensity = 0.3;
      axisY.material.microSurface = 0.3;
      axisY.material.specularColor = new BABYLON.Color3(0.0, 1.0, 0.0);
    }
    axisY.scaling.y = size;
    axisY.scaling.x = 0.5;
    axisY.scaling.z = 0.5;
    let center = bbinfo.boundingBox.centerWorld;
    axisY.position = center;

    return axisY;
  };

  /**
   * Creates a z axis cylinder.
   *
   * @param      {<type>}  mesh    The mesh
   * @return     {<type>}  { description_of_the_return_value }
   */
  this.createZAxisCylinder = function (mesh, bbinf = null) {
    mesh.refreshBoundingInfo();
    let bbinfo = bbinf || mesh.getBoundingInfo();
    let size = bbinfo.diagonalLength * 2.5;
    if (size < 5) {
      size = 5;
    }
    var axisZ = BABYLON.Mesh.CreateBox(
      "moveMeshAxisZ",
      0.4,
      store.scene,
      false,
      BABYLON.Mesh.DOUBLESIDE
    );
    var axisMat = store.scene.getMaterialByName("axisZMat");
    if (axisMat) axisZ.material = axisMat;
    else {
      axisZ.material = new BABYLON.StandardMaterial("axisZMat", store.scene);
      axisZ.material.diffuseColor = new BABYLON.Color3(0.0, 0.0, 1.0);
      axisZ.material.directIntensity = 5.0;
      axisZ.material.backFaceCulling = false;
      axisZ.material.environmentIntensity = 1.0;
      axisZ.material.specularIntensity = 0.3;
      axisZ.material.microSurface = 0.3;
      axisZ.material.specularColor = new BABYLON.Color3(0.0, 0.0, 1.0);
    }
    axisZ.scaling.z = size;
    axisZ.scaling.x = 0.5;
    axisZ.scaling.y = 0.5;
    let center = bbinfo.boundingBox.centerWorld;
    axisZ.position = center;
    return axisZ;
  };

  this.drawSelectionBox = function (bbinfo) {
    var height = bbinfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
    var width = bbinfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
    var depth = bbinfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
    var center = bbinfo.boundingBox.centerWorld;

    var box = BABYLON.MeshBuilder.CreateBox(
      "customMesh" + "boxScale",
      { height: height, width: width, depth: depth },
      store.scene
    );
    box.position = center;
    box.enableEdgesRendering();
    box.edgesWidth = 8.0;
    box.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
    box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    var material = new BABYLON.StandardMaterial("bbMat", store.scene);
    material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 1);
    material.alpha = 0.2;
    material.backFaceCulling = false;
    box.material = material;
    box.isPickable = false;
  };
};
export { Factory };
