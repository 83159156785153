import { configureStore } from "@reduxjs/toolkit";
import objectPropertiesReducer from '../reducers/objectProperties/objectPropertiesSlice';
import storeysReducer  from "../reducers/objectProperties/storeysSlice";
import projectPropertiesReducer from "../reducers/objectProperties/projectPropertiesSlice";
import materialsReducer  from "../reducers/objectProperties/materialsSlice";
import screenshotUiDataReducer from "../reducers/objectProperties/screenshotUiDataSlice";
import teamsReducer from "../reducers/objectProperties/teamsSlice";
import changelogsReducer from "../reducers/objectProperties/changelogsSlice";
import projectMetadataReducer from "../reducers/objectProperties/projectMetadataSlice";
import meshSelectionReducer from "../reducers/objectProperties/meshSelection";
import commentsReducer from  "../reducers/objectProperties/commentsSlice";
import rolesReducer from "../reducers/objectProperties/rolesSlice";
import activeUsersReducer from "../reducers/objectProperties/activeUsersSlice";
import editorNotificationReducer from "../reducers/objectProperties/editorNotificationSlice";
import coveAnalysisReducer from "../reducers/objectProperties/coveAnalysisSlice";
import dndSliceReducer from "../reducers/objectProperties/dndSlice";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const reduxStore = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['objectProperties/updateSelected', 'meshSelection/selectMesh', 'meshSelection/deselectMesh', 'coveAnalysis/setEnergyCodes', 'coveAnalysis/setFetchingEnergyCodesAs'],
      },
    })
  },
  reducer: {
    objectProperties: objectPropertiesReducer,
    storeys: storeysReducer,
    projectProperties: projectPropertiesReducer,
    materials : materialsReducer,
    screenshotUiData: screenshotUiDataReducer,
    projectMetadata: projectMetadataReducer,
    meshSelection: meshSelectionReducer,
    comments: commentsReducer,
    roles: rolesReducer,
    activeUsers: activeUsersReducer,
    coveAnalysis: coveAnalysisReducer,
    teams: teamsReducer,
    changelogs: changelogsReducer,
    editorNotification: editorNotificationReducer,
    dnd: dndSliceReducer
  }
});

export default reduxStore;
