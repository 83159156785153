import { store } from "../modules/utilityFunctions/Store.js";
function toggleSavingLoopDisplay(mode) {
  if (mode) {
    document.getElementById("saving").style.visibility = "visible";
    document.getElementById("saving_loop").style.visibility = "visible";
  } else {
    document.getElementById("saving").style.visibility = "hidden";
    document.getElementById("saving_loop").style.visibility = "hidden";
  }
}

var prevButton = null;

function updateButtonColor(dom) {
  //
  // let clipPlaneButton =   document.getElementById("clipPlane");
  // let moveMeshButton =   document.getElementById("moveMesh");
  // let rotateMeshButton =   document.getElementById("rotateMesh");
  // let measureMeshButton =   document.getElementById("measureMesh");
  // let scaleMeshButton =   document.getElementById("scaleMesh");
  // let moveBlocksButton =   document.getElementById("moveBlocks");
  let select_toolButton = document.getElementById("select_tool");
  let camRotButton = document.getElementById("camRot");
  let panButton = document.getElementById("pan");
  // let editedgeButton =   document.getElementById("editedge");
  // let editvertButton =   document.getElementById("editvert");
  // let drawingModeButton =   document.getElementById("drawingMode");
  // let sketchSolidButton =   document.getElementById("sketchSolid");
  // let sketchVoidButton =   document.getElementById("sketchVoid");
  // let vrButton =   document.getElementById("vr");
  // let matModeButton =   document.getElementById("mat_mode");
  // let moveFaceButton =    document.getElementById("move_face");
  // let extrudeButton =    document.getElementById("extrude");
  // let visualizeButton =   document.getElementById("visualize");
  // let splitFaceButton =   document.getElementById("splitFace");
  // let removeElementsButton =   document.getElementById("removeElements");
  // let sketchButton = document.getElementById("sketch");
  // let eraseButton = document.getElementById("erase");
  // let setScaleButton = document.getElementById("set-scale");
  // let editPolygonButton = document.getElementById("editPolygon");
  // let arrayFunctionButton = document.getElementById("arrayFunction");

  let activeButtonColor = "orange";
  let inactiveButtonColor = "";
  if (!store.view_only) {
    let activeButton = document.getElementById(dom);
    if (activeButton) {
      activeButton.style.backgroundColor = activeButtonColor;
      if (prevButton) {
        /* eslint-disable */
        if (prevButton.id === activeButton.id) {
        } else {
          /* eslint-enable */
          prevButton.style.backgroundColor = inactiveButtonColor;
          prevButton = activeButton;
        }
      } else {
        prevButton = activeButton;
      }
    }
  } else {
    if (dom === "select_tool") {
      if (panButton) {
        panButton.style.backgroundColor = inactiveButtonColor;
      }
      if (camRotButton) {
        camRotButton.style.backgroundColor = inactiveButtonColor;
      }
      if (select_toolButton) {
        select_toolButton.style.backgroundColor = activeButtonColor;
      }
    } else if (dom === "pan") {
      if (select_toolButton) {
        select_toolButton.style.backgroundColor = inactiveButtonColor;
      }
      if (camRotButton) {
        camRotButton.style.backgroundColor = inactiveButtonColor;
      }
      if (panButton) {
        panButton.style.backgroundColor = activeButtonColor;
      }
    } else if (dom === "camRot") {
      if (panButton) {
        panButton.style.backgroundColor = inactiveButtonColor;
      }
      if (camRotButton) {
        camRotButton.style.backgroundColor = activeButtonColor;
      }
      if (select_toolButton) {
        select_toolButton.style.backgroundColor = inactiveButtonColor;
      }
    }
  }
}
export { toggleSavingLoopDisplay, prevButton, updateButtonColor };
