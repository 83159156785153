import { isMeshThrowAway } from "../extrafunc";

const CostCalculator = (() => {
    const masterCalculate = (ds, checkThrowAwayMesh = true) => {
        if(ds.type == 'Furniture'){
            const isRevitImport = ds?.revitMetaData?.type ? true : false
            return calculateFurniture(ds, checkThrowAwayMesh, isRevitImport);
        }
        return {
            cost: null,
            identifier: {
                team: null,
                elementId: null,
                type: 'Furniture', // TODO: change it to something generic in future
            }
        };
    };

    const calculateFurniture = (ds, checkThrowAwayMesh = true, isRevitImport = false) => {
        if(checkThrowAwayMesh && ds.mesh && isMeshThrowAway(ds.mesh) || ds.mesh?.replacedObject){
            return {
                cost: null,
                identifier: {
                    team: null,
                    elementId: null,
                    type: 'Furniture',
                }
            };
        }
        if (ds?.breed === "team") {
          if (isRevitImport) {
            const costs = [];
            const elementIds = [];
            const elements = [];
            const categories = [];
            const families = [];
            const teamId = ds?.teamId;
            let groupName, groupCategory, groupFamily;
            let previousCost = 0;

            if(ds.revitMetaData.teamObjectInfo){
              const element = ds.revitMetaData.teamObjectInfo;
              // element present in library(single element)
              const cost = element.cost ? element.cost : 0
              costs.push(cost);
              elementIds.push(element.elementId);
              }
            else{
              for (let id = 0; id < ds.revitMetaData.elements.length; ++id) {
                const element = ds.revitMetaData.elements[id];
                // element present in library(single element)
                if(element.type == ds.revitMetaData.type && element.elementId ){
                  const cost = element.cost ? element.cost : 0
                  costs.push(cost);
                  elementIds.push(element.elementId);
                }
                // single furniture or furniture system itself is part of furniture 
                if (element.type == ds.revitMetaData.type) continue;
  
                elements.push(element.type);
                categories.push(element.category)
                families.push(element?.family)
                
                if(element.elementId){
                  const cost = element.cost ? element.cost : 0
                  costs.push(cost);
                  elementIds.push(element.elementId);
                }else{ 
                  costs.push(null);
                  elementIds.push(-1);
                }
              }
  
              const isGroupFurniture = (ds.revitMetaData.elements.length > 1) 
              const isSingleFurnitureNotPresentInLibrary = (elementIds.length == 0  && ds.revitMetaData.elements.length == 1) || (elementIds.length == 1 && elementIds[0] == -1)
              if(isGroupFurniture || isSingleFurnitureNotPresentInLibrary ){
                groupName = ds.revitMetaData.type.replace(".", "")
                groupCategory = ds.revitMetaData.category;
                groupFamily = ds.revitMetaData?.family;
              }
            }
            if(ds?.beforeRevitMetaData){
              for (let id = 0; id < ds.beforeRevitMetaData.elements.length; ++id) {
                const element = ds.beforeRevitMetaData.elements[id];
                if(element.cost){
                  previousCost += element.cost;
                }
              }
            }
            
            
            return {
              cost: costs,
              identifier: {
                team: teamId,
                elementId: elementIds,
                type: "Furniture",
                elements: elements,
                categories: categories,
                families,
                group: {groupName, groupCategory, groupFamily},
                previousCost:previousCost,
              },
              revitImport: true,
            };
          }
          return {
            cost: ds.cost,
            identifier: {
              team: ds.teamId,
              elementId: ds.elementId,
              type: "Furniture",
            },
          };
        }
        return {
            cost: null,
            identifier: {
                team: null,
                elementId: null,
                type: 'Furniture',
            }
        };
    };

    return {
        masterCalculate,
    }
})();

export { CostCalculator };
export default CostCalculator;
