import BABYLON from "../../babylonDS.module.js";
import _ from "lodash";
import { store } from "../../utilityFunctions/Store.js";
import { GLOBAL_CONSTANTS } from "../../utilityFunctions/globalConstants.js";
import { uiIndicatorsHandler } from "../../uiIndicatorOperations/uiIndicatorsHandler.js";
import {
  getDistanceBetweenVectors,
  convertGlobalVector3ToLocal,
  convertLocalVector3ToGlobal,
} from "../../extrafunc.js";
import { angleMeasure } from "../../../libs/angleMeasure.js";
import { isTwoDimension } from "../../../libs/twoDimension.js";
import { virtualSketcher } from "../../sketchMassBIMIntegration/virtualSketcher.js";
import { commandUtils } from "../../commandManager/CommandUtils.js";
import {
  populateRoofBoundInfo,
  getOffsetValues,
} from "../../factoryTypes/roof.types.js";
import { geometryUpdater } from "../../sketchMassBIMIntegration/geometryUpdater.js";
import { moveFace } from "./moveFace.js";
import { scenePickController } from '../../utilityFunctions/scenePickController';
import { getEdgeObjectFromVertexPositions } from '../../../libs/brepOperations';
import {getAngleBetweenVectors, isFloatEqual} from "../../../libs/snapFuncs";


let faceIndicatorName = "faceBox";

function createBackWall(pickedPoint = BABYLON.Vector3.Zero()) {
  disposeBackWall();
  //uncommenting because pick is failing for some reason when previous backwall is maintained

  let wall = store.scene.getMeshByName("backwall");
  if (!wall) {
    let options = {
      size: 100000,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    };

    wall = BABYLON.MeshBuilder.CreatePlane("backwall", options, store.newScene);
    wall.type = "backwall";
    wall.isVisible = false;
    wall.isPickable = true;
    wall.visibility = 0;
    scenePickController.add(wall);
  }

  wall.setAbsolutePosition(pickedPoint);
  let rotationValue = store.scene.activeCamera.alpha - (3 * Math.PI) / 2;
  wall.rotation.y = -rotationValue;
}

function createGround() {
  // ADD Ground and checkCollisions
  const groundOptions = { width: 100000, height: 100000 };
  var ground = BABYLON.MeshBuilder.CreateGround("ground1", groundOptions, store.newScene);
  ground.material = new BABYLON.StandardMaterial("texture1", store.newScene);
  ground.material.alpha = 0.4;
  // ground.material.diffuseColor = new BABYLON.Color3(0.678, 1.000, 0.184);
  ground.material.specularColor = new BABYLON.Color3(0, 0, 0);
  ground.reflectionFresnelParameters = new BABYLON.FresnelParameters();
  ground.reflectionFresnelParameters.isEnabled = false;
  // ground.position.y += 0.1;
  ground.type = "ground";
  ground.isPickable = false;
  ground.renderingGroupId = -1.0;
  // ground.checkCollisions = true;
  // ground.optimize(10000);
  ground.isVisible = false;
  scenePickController.add(ground);

  store.newScene.clearColor = new BABYLON.Color3(
    240 / 255,
    240 / 255,
    240 / 255
  );
  ground.material.diffuseColor = new BABYLON.Color3(
    190 / 255,
    196 / 255,
    190 / 255
  );
}

function createReferenceGround(startingPoint = BABYLON.Vector3.Zero()) {
  // disposeReferenceGround();

  let referenceGround = store.scene.getMeshByName("referenceGround");
  if (!referenceGround) {
    referenceGround = BABYLON.MeshBuilder.CreateGround(
      "referenceGround",
      { width: 100000, height: 100000 },
      store.scene
    );
    referenceGround.isVisible = false;
    referenceGround.visibility = 0;
    referenceGround.type = GLOBAL_CONSTANTS.strings.identifiers.utilityElement;
    scenePickController.add(referenceGround);
  }

  referenceGround.setAbsolutePosition(startingPoint);
  referenceGround.computeWorldMatrix();
}

function disposeBackWall() {
  if (store.scene.getMeshByName("backwall"))
    store.scene.getMeshByName("backwall").dispose();
}

function disposeReferenceGround() {
  if (store.scene.getMeshByName("referenceGround"))
    store.scene.getMeshByName("referenceGround").dispose();
}

let showAxisIndicator = function (p1, p2, options) {
  return uiIndicatorsHandler.axisIndicator.show(p1, p2, options);
};

let showVertexIndicator = function (vertex, mesh, options) {
  return uiIndicatorsHandler.vertexIndicator.show(vertex, mesh, options);
};

let showEdgeIndicator = function (edge, mesh, options) {
  return uiIndicatorsHandler.edgeIndicator.show(edge, mesh, options);
};

let showEdgeIndicatorUsingEdgeId = function (edgeId, mesh, options) {
  return null;
};


function showFaceIndicator(faceCoordinates,facetId, mesh, name, material) {
  return uiIndicatorsHandler.faceIndicator.show(
    faceCoordinates,
    facetId,
    mesh,
    name,
    material
  );
}

var disposeHighlightedVertex = function (name) {
  let indicatorType;

  if (name === "vertexBox") {
    indicatorType = uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;
  } else if (name === "vertexBox2") {
    indicatorType = uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator;
  } else {
    indicatorType = uiIndicatorsHandler.vertexIndicator.TYPES.allIndicators;
  }

  uiIndicatorsHandler.vertexIndicator.remove(indicatorType);
};

var disposeHightlightedEdge = function (name) {
  let indicatorType;

  if (name === "edgeBox") {
    indicatorType = uiIndicatorsHandler.edgeIndicator.TYPES.preSnapIndicator;
  } else if (name === "edgeBox2") {
    indicatorType = uiIndicatorsHandler.edgeIndicator.TYPES.postSnapIndicator;
  } else {
    indicatorType = uiIndicatorsHandler.edgeIndicator.TYPES.allIndicators;
  }

  uiIndicatorsHandler.edgeIndicator.remove(indicatorType);
};

var disposeHighlightedFace = function (name) {
  uiIndicatorsHandler.faceIndicator.remove(name);
};

function showAxisLine(firstPoint, secondPoint, axis, disposePrevious = true, options={}) {
  let uiOptions = {
    axisType: axis,
    indicator: null,
    extension: options.extension
  };

  if (disposePrevious) {
    uiIndicatorsHandler.axisIndicator.remove();
    uiOptions.indicator = uiIndicatorsHandler.axisIndicator.TYPES.axis1;
  } else {
    uiOptions.indicator = uiIndicatorsHandler.axisIndicator.TYPES.axis2;
  }

  uiIndicatorsHandler.axisIndicator.show(firstPoint, secondPoint, uiOptions);
}

function disposeAxisLine(notAxis) {
  uiIndicatorsHandler.axisIndicator.remove(
    uiIndicatorsHandler.axisIndicator.TYPES.axis1
  );
}

function disposeAllAxisLines() {
  uiIndicatorsHandler.axisIndicator.remove();
}

function updateMeshFacetData(mesh) {
  try {
    mesh.updateFacetData();
  } catch (e) {
    console.log("Error updating mesh facetData", e);
  }
}

function indicateAllVertices(faceVertices, mesh) {
  uiIndicatorsHandler.vertexIndicator.multiIndicators.show(faceVertices, mesh);
}

function createVertexIndicatorInstance(vertex) {
  if (uiIndicatorsHandler.vertexIndicator.multiIndicators.isActive()) {
    uiIndicatorsHandler.vertexIndicator.multiIndicators.add(vertex);
  }
}

function removeVertexIndicatorInstance(vertex) {
  if (uiIndicatorsHandler.vertexIndicator.multiIndicators.isActive()) {
    uiIndicatorsHandler.vertexIndicator.multiIndicators.remove(vertex);
  }
}

function disposeAllVertexIndicators() {
  uiIndicatorsHandler.vertexIndicator.multiIndicators.remove();
}

function disposeSnappingObjects() {
  disposeAllAxisLines();
  disposeHighlightedFace();
  disposeHightlightedEdge();
  disposeHighlightedVertex();
}

function disposeSnapToObjects() {
  disposeHighlightedFace("faceBox2");
  disposeHightlightedEdge("edgeBox2");
  disposeHighlightedVertex("vertexBox2");
}

function showAngles(pt1, anglePt, pt2, angleVal, radius, options) {
  if (!radius) {
    const d1 = getDistanceBetweenVectors(pt1, anglePt);
    const d2 = getDistanceBetweenVectors(pt2, anglePt);
    radius = _.min([d1, d2]) / 4;
  }
  angleMeasure.showAngle(pt1, pt2, anglePt, angleVal, radius, options);
}

function disposeAngles() {
  angleMeasure.disposeAngles();
}

function disposeAngleSector() {
  angleMeasure.disposeSector();
}

/**
 * When multiple instances of the same sourceMesh are together, disabling the simul-edit because it
 * could be confusing for the user.
 * This method filters the instances other than the one under the pointer
 *
 * mainComponent is the one under the pointer, so gets higher priority
 *
 * @param mainComponent
 * @param components
 * @param options
 */
const filterOutStructuralAssociationComponents = function (
  mainComponent,
  components,
  options = {}
) {
  const sourceMeshes = [];
  const componentsToRemove = [];

  let mainSourceMesh;
  if (mainComponent.mesh.isAnInstance) {
    mainSourceMesh = mainComponent.mesh.sourceMesh;
  }

  components.forEach((c) => {
    if (store.$scope.isTwoDimension) {
      if (c.storey !== store.activeLayer.storey && !options.ignoreStoreyCheck) {
        componentsToRemove.push(c);
        return;
      }
    }
    if (options.doNotFilterInstances) return;

    if (virtualSketcher.util.isComponentPlanar(c)) {
      componentsToRemove.push(c);
      return;
    }
    if (c.mesh.isAnInstance) {
      const sourceMesh = c.mesh.sourceMesh;
      if (
        sourceMeshes.includes(sourceMesh) ||
        (c !== mainComponent && sourceMesh === mainSourceMesh)
      ) {
        componentsToRemove.push(c);
      } else {
        sourceMeshes.push(sourceMesh);
      }
    }
  });

  _.pull(components, ...componentsToRemove);
};

function handleVisibilityChanges(meshOfInterest, revertChanges = false) {
  if (revertChanges) {
    if (["void"].includes(meshOfInterest.type.toLowerCase())) {
      meshOfInterest.isAnInstance
        ? (meshOfInterest.sourceMesh.visibility = 0.01)
        : (meshOfInterest.visibility = 0.01);
    }
    
    if (
      ["void", "door", "window"].includes(meshOfInterest.type.toLowerCase())
    ) {
      meshOfInterest.parent.visibility = 1;
    }
  } else {
    if (["void"].includes(meshOfInterest.type.toLowerCase())) {
      meshOfInterest.isAnInstance
        ? (meshOfInterest.sourceMesh.visibility = 0.5)
        : (meshOfInterest.visibility = 0.5);
    }
    
    if (
      ["void", "door", "window"].includes(meshOfInterest.type.toLowerCase())
    ) {
      meshOfInterest.parent.visibility = 0.5;
    }
  }
}

function isMeshAVoid(mesh) {
  return ["void"].includes(mesh.type.toLowerCase());
}

function handleRoofPropertyChangeCommand(components, commandData) {
  if (_.isUndefined(commandData)) {
    
    const roofs = components
      .filter((c) => c.type.toLowerCase() === "roof")
      .map((r) => r.getDataStore());
    
    if (_.isEmpty(roofs)) return null;
    else {
      let optionsForPropertyChange = {
        componentKeys: [
          "roof_pol_offset_top",
          "roof_pol_offset_bottom",
          "roof_pol_top",
          "roof_pol_bottom",
        ],
      };

      return commandUtils.propertyChangeOperations.getCommandData(
        roofs.map((c) => c.mesh),
        optionsForPropertyChange
      );
    }
  } else {
    if (!commandData) return null;

    const roofs = components
      .filter((c) => c.type.toLowerCase() === "roof")
      .map((r) => r.getDataStore());

    roofs.forEach((roof) => {
      populateRoofBoundInfo(roof);
      // this is necessary because new vertices could have been added

      roof.setRoofPolBottom(
        getOffsetValues(roof.getRoofPolOffsetBottom(), -roof.getRoofOffset(), 
        { subtract: true }
      ));
      
      roof.setRoofPolTop(
        getOffsetValues(roof.getRoofPolOffsetTop(), -roof.getRoofOffset(),
        { subtract: true }
      ));
    });

    commandData = commandUtils.propertyChangeOperations.getCommandData(
      roofs.map((c) => c.mesh),
      { data: commandData }
    );

    return commandUtils.propertyChangeOperations.getCommand(
      "slabOutlinePropertyChange",
      commandData
    );
  }
}

function getComponentsLinkedToEdge(edge, componentOfInterest, options = {}) {
  const components = [];

  const structuralAssociation1 = virtualSketcher.structuralLookup(edge.headPt);
  const structuralAssociation2 = virtualSketcher.structuralLookup(edge.tailPt);

  if (structuralAssociation1 && structuralAssociation2) {
    const commonComponents = _.intersection(
      structuralAssociation1.components,
      structuralAssociation2.components
    );

    const commonComponentsWithAnEdgeConnectingThosePoints = commonComponents.filter(c => {
      return !!getEdgeObjectFromVertexPositions(c.mesh, c.brep, edge.headPt, edge.tailPt);
  });

  filterOutStructuralAssociationComponents(componentOfInterest, commonComponentsWithAnEdgeConnectingThosePoints, options);

  components.push(...commonComponentsWithAnEdgeConnectingThosePoints);
  } else components.push(componentOfInterest);

  if (options.excludeActual) _.pull(components, componentOfInterest);

  return components;
}

function getComponentsLinkedToEdge2D(edge, componentOfInterest, options = {}) {
  const components = getComponentsLinkedToEdge(
    edge,
    componentOfInterest,
    options
  );

  const facetId = geometryUpdater.util.getTopFacetId(componentOfInterest);
  const fakePickInfo = geometryUpdater.util.getFakePickInfo(
    edge.headPt,
    componentOfInterest,
    facetId
  );

  const parallelFaceData = {};
  const dataForParallelFaceSearch = {
    edge,
    pickInfo: fakePickInfo,
    component: componentOfInterest,
  };

  moveFace.util.getFaceObjectFromTopEdgeAndLookForParallelFaces(
    dataForParallelFaceSearch,
    parallelFaceData
  );

  if (parallelFaceData.pickData) {
    components.push(parallelFaceData.pickData.component);
  }

  filterOutStructuralAssociationComponents(
    componentOfInterest,
    components,
    options
  );
  if (options.excludeActual) _.pull(components, componentOfInterest);

  return _.uniq(components);
}

/**
 * Points are global vertices for mainInstanceComponent
 * Returns equivalent otherInstanceComponent vertices
 *
 * @param points
 * @param mainInstanceComponent
 * @param otherInstanceComponent
 * @returns {*|*[]}
 */
const getCorrespondingGlobalPointOfOtherInstance = function (
  points,
  mainInstanceComponent,
  otherInstanceComponent
) {
  let returnFirstElement = false;
  if (!_.isArray(points)) {
    points = [points];
    returnFirstElement = true;
  }

  const pointsLocal = points.map((point) =>
    convertGlobalVector3ToLocal(point, mainInstanceComponent.mesh)
  );
  const adjustedPoints = pointsLocal.map((point) =>
    convertLocalVector3ToGlobal(point, otherInstanceComponent.mesh)
  );

  return returnFirstElement ? adjustedPoints[0] : adjustedPoints;
};

function updatePosition(component, mesh, movementAmount){
  if (component && component.autoInterior) mesh.position.addInPlace(movementAmount);
  else if(mesh.type.toLowerCase().includes("cadsketch")){
    mesh.position.addInPlace(movementAmount);
  }
  else {
    const positionToBe = mesh.getAbsolutePosition().add(movementAmount);
    mesh.setAbsolutePosition(positionToBe);
  }
}

function isMeshDWF(mesh){
  return ["door", "window", "furniture"].includes(mesh.type.toLowerCase());
}

function isMeshDW(mesh){
  return ["door", "window"].includes(mesh.type.toLowerCase());
}

function isMeshDWIndicator(mesh){
  return mesh.type === GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator;
}

function isEdgeVerticalLike (edge, threshold = 5){
  const angleWithY = getAngleBetweenVectors(
    edge.headPt.subtract(edge.tailPt),
    BABYLON.Vector3.Up());
  
  return isFloatEqual(angleWithY, 0, threshold)
    || isFloatEqual(angleWithY, 180, threshold);
}

/**
 * After updating to Babylon v5.36, updation of worldMatrix is not as predictable
 * Looks like re-computation is happening over several render cycles
 * So, several features that require local to global conversion need to call this function
 *
 * @param mesh
 */
function computeWorldMatrix(mesh){
  mesh.computeWorldMatrix(true);
}

export {
  faceIndicatorName,
  createBackWall,
  createGround,
  createReferenceGround,
  disposeBackWall,
  disposeReferenceGround,
  showAxisIndicator,
  showVertexIndicator,
  showEdgeIndicator,
  showEdgeIndicatorUsingEdgeId,
  showFaceIndicator,
  disposeHighlightedVertex,
  disposeHightlightedEdge,
  disposeHighlightedFace,
  showAxisLine,
  disposeAxisLine,
  disposeAllAxisLines,
  updateMeshFacetData,
  indicateAllVertices,
  createVertexIndicatorInstance,
  removeVertexIndicatorInstance,
  disposeAllVertexIndicators,
  disposeSnappingObjects,
  disposeSnapToObjects,
  showAngles,
  disposeAngles,
  disposeAngleSector,
  filterOutStructuralAssociationComponents,
  handleRoofPropertyChangeCommand,
  handleVisibilityChanges,
  getComponentsLinkedToEdge,
  getComponentsLinkedToEdge2D,
  getCorrespondingGlobalPointOfOtherInstance,
  updatePosition,
  isMeshDWF,
  isMeshDW,
  isMeshDWIndicator,
  isMeshAVoid,
  isEdgeVerticalLike,
  computeWorldMatrix,
};
