'use strict';

class GridNode {
  
  constructor(i, j, grid) {
    this.i = i;
    this.j = j;
    this.grid = grid;
    
    if (i === 0 || j === 0 || i === grid.nRowsMinus1 || j === grid.nColumnsMinus1) this.markAsBoundary();
    else this.uncover();
  }
  
  getNeighbors(){
    return this.grid.getNeighbors(this);
  }
  
  cover(){
    this.state = GridNode.STATES.covered;
  }
  
  uncover(){
    this.state = GridNode.STATES.uncovered;
  }
  
  markAsBoundary(){
    this.state = GridNode.STATES.boundary;
  }
  
  get isCovered(){
    return this.state === GridNode.STATES.covered;
  }
  
  get isUncovered(){
    return this.state === GridNode.STATES.uncovered;
  }
  
  get isBoundary(){
    return this.state === GridNode.STATES.boundary;
  }
  
}

GridNode.STATES = {
  covered : 1,
  uncovered : 2,
  boundary : 3,
};

export default GridNode;