import React, { Suspense } from "react";
import Theme from "../app/themes/Themes";
import RoutesContainer from "./routes/index";
import { createGlobalStyle } from "styled-components";
// Commented import mixpanel from 'mixpanel-browser';
import { djangoUrl } from './services/url.constants';

// mixpanel.init("fe8c10413d42fd2e85333c12a0395320", { api_host: djangoUrl, ignore_dnt: true});
// mixpanel.init("fe8c10413d42fd2e85333c12a0395320", { debug: true, ignore_dnt: true});

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    color: ${(props) => props.theme.colors.primeBlack};
    font-family: ${(props) => props.theme.font};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }
`;

const App = () => {
  return (
    <Theme>
      <GlobalStyle />
      <Suspense fallback={<span>Loading...</span>}>
        <RoutesContainer />
      </Suspense>
    </Theme>
  );
};

export default App;
