import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { loadIntComponent, offsetInterior } from "./interiors_gen.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds.js";
function loadKitchen1(name, room_pol, flag) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/kitchen/kitchen1/" +
      name +
      ".babylon";
    var comp_path_dir =
      window.location.origin + "/media/models/kitchen/kitchen1/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadKitchen1(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function loadKitchen2(name, room_pol, flag) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/kitchen/kitchen2/" +
      name +
      ".babylon";
    var comp_path_dir =
      window.location.origin + "/media/models/kitchen/kitchen2/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadKitchen2(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function placeKitchen1Comp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "kitchen1",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeKitchen1Comp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeKitchen1Comp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;
  newInstance.storey = storey.value;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForKitchen1(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

// function getRoomOrientationForKitchen1(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var intersectWallThickness = 0;
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     if (Math.pow((Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2)), 0.5) < XX) {
//         newInstance.scaling.x *= Math.pow((Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2)), 0.5) / XX;
//     }
//
//     //console.log(newInstance.rotation.y);
//     // newInstance.scaling.x *= Math.abs(c1[0] - c2[0])*unit_scale;
//     // newInstance.scaling.y *= Math.abs(c1[1] - c2[1])*unit_scale;
// }

function getRoomOrientationForKitchen1(coords, newInstance) {
  let wallThickness = 200 / (store.unit_absolute_scale * 10000);
  offsetInterior(coords, newInstance, wallThickness);

  let angleInRadians = Math.atan2(
    coords[1][1] - coords[0][1],
    coords[1][0] - coords[0][0]
  );
  if (angleInRadians < 0) angleInRadians = Math.PI + angleInRadians;
  let angleInDegrees = (180 / Math.PI) * angleInRadians;

  let bbInfo = newInstance.getBoundingInfo();
  let floorLength = BABYLON.Vector3.Distance(
    new BABYLON.Vector3(coords[0][0], 0, coords[0][1]),
    new BABYLON.Vector3(coords[1][0], 0, coords[1][1])
  );

  if (angleInDegrees === 180 || angleInDegrees === 0) {
    if (bbInfo.boundingBox.extendSizeWorld.x * 2 > floorLength) {
      newInstance.scaling.x *=
        floorLength / (bbInfo.boundingBox.extendSizeWorld.x * 2);
    }
  } else if (angleInDegrees === 90) {
    if (bbInfo.boundingBox.extendSizeWorld.z * 2 > floorLength) {
      newInstance.scaling.z *=
        floorLength / (bbInfo.boundingBox.extendSizeWorld.z * 2);
    }
  } else if (angleInDegrees > 0 && angleInDegrees < 90) {
    let kitchenTableLength = BABYLON.Vector3.Distance(
      new BABYLON.Vector3(
        bbInfo.boundingBox.minimumWorld.x,
        bbInfo.boundingBox.centerWorld.y,
        bbInfo.boundingBox.minimumWorld.z
      ),
      new BABYLON.Vector3(
        bbInfo.boundingBox.maximumWorld.x,
        bbInfo.boundingBox.centerWorld.y,
        bbInfo.boundingBox.maximumWorld.z
      )
    );
    if (kitchenTableLength > floorLength) {
      newInstance.scaling.x *=
        (floorLength / kitchenTableLength) * Math.sin(angleInRadians);
      newInstance.scaling.z *=
        (floorLength / kitchenTableLength) * Math.cos(angleInRadians);
    }
  } else if (angleInDegrees > 90 && angleInDegrees < 180) {
    let kitchenTableLength = BABYLON.Vector3.Distance(
      new BABYLON.Vector3(
        bbInfo.boundingBox.minimumWorld.x,
        bbInfo.boundingBox.centerWorld.y,
        bbInfo.boundingBox.maximumWorld.z
      ),
      new BABYLON.Vector3(
        bbInfo.boundingBox.maximumWorld.x,
        bbInfo.boundingBox.centerWorld.y,
        bbInfo.boundingBox.minimumWorld.z
      )
    );
    if (kitchenTableLength > floorLength) {
      newInstance.scaling.x *=
        (floorLength / kitchenTableLength) * Math.cos(angleInRadians);
      newInstance.scaling.z *=
        (floorLength / kitchenTableLength) * Math.sin(angleInRadians);
    }
  }
}

function placeKitchen2Comp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "kitchen2",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeKitchen2Comp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeKitchen2Comp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;
  newInstance.storey = storey.value;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForKitchen2(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

// function getRoomOrientationForKitchen2(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var intersectWallThickness = 0;
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     if (Math.pow((Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2)), 0.5) < XX) {
//         newInstance.scaling.x *= Math.pow((Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2)), 0.5) / XX;
//     }
// }

function getRoomOrientationForKitchen2(coords, newInstance) {
  let wallThickness = 200 / (store.unit_absolute_scale * 10000);
  offsetInterior(coords, newInstance, wallThickness);
}

// //console.log(Math.abs(c1[0] - c2[0]), Math.abs(c1[1] - c2[1]));
// //console.log(XX, YY, ZZ);
// }
export {
  loadKitchen1,
  loadKitchen2,
  placeKitchen1Comp,
  arrangeKitchen1Comp,
  getRoomOrientationForKitchen1,
  placeKitchen2Comp,
  arrangeKitchen2Comp,
  getRoomOrientationForKitchen2,
};
