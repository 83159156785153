import { createSlice } from "@reduxjs/toolkit";
import { updateArea } from "../../../libs/areaView";
import { updateRoofAccordion } from "../../../libs/roofVisibilityFuncs";
import { storeyView } from "../../../libs/storeyView";
import { DisplayOperation } from "../../../modules/displayOperations/displayOperation";
import { setUserSettingAndRecord } from "../../../modules/extrafunc";
import { userSetBIMPropertiesHandler } from "../../../modules/utilityFunctions/CONSTANTS/userSetBimPropertiesHandler";
import { store } from "../../../modules/utilityFunctions/Store";
import _ from "lodash";
import {colorUtil} from "../../../modules/utilityFunctions/colorUtility";

const UNITS_MAPPING = {
    "Metres": {id: 0, value: "meters", name: "Metres"},
    "Millimeters": {id: 1, value: "millimeter", name: "Millimeters"},
    "Centimeters": {id: 2, value: "centimeter", name: "Centimeters"},
    "Inches": {id: 3, value: "inches", name: "Inches"},
    "Feet-inches": {id: 4, value: "feet-inches", name: "Feet-inches"}
}
const TOLERANCE_MAPPING = {
    "0": {id: 0, value: 0, name: "0"},
    "0.0": {id: 1, value: 1, name: "0.0"},
    "0.00": {id: 2, value: 2, name: "0.00"},
    "0.000": {id: 3, value: 3, name: "0.000"},
    "0.0000": {id: 4, value: 4, name: "0.0000"},
    "0.00000": {id: 5, value: 5, name: "0.00000"},
    "0.000000": {id: 6, value: 6, name: "0.000000"},
    "0.0000000": {id: 7, value: 7, name: "0.0000000"},
    "0.00000000": {id: 8, value: 8, name: "0.00000000"},
}
const WALL_TYPE_MAPPING = {
    "BRICK": {id: 0, value: "BRICK", name: "Brick Wall"},
    "CONCRETE": {id: 1, value: "CONCRETE", name: "Concrete Wall"},
    "WOOD" : {id: 2, value: "WOOD", name: "Wood Stud Wall"},
    "GLASS": {id: 3, value: "GLASS", name: "Glass Curtain Wall"}
}

const REVERSE_WALL_TYPE_MAPPING = {
  "Brick Wall": WALL_TYPE_MAPPING["BRICK"],
  "Concrete Wall": WALL_TYPE_MAPPING["CONCRETE"],
  "Wood Stud Wall": WALL_TYPE_MAPPING["WOOD"],
  "Glass Curtain Wall": WALL_TYPE_MAPPING["GLASS"]
}

const initialState2 = {
    "length": {
        "value": UNITS_MAPPING["Millimeters"].name
    },
    // "area": {
    //     "value": "Square meter"
    // },
    // "volume": {
    //     "value": "Liters"
    // },
    // "angle": {
    //     "value": "Degree"
    // },
    "tolerance": {
        "value": TOLERANCE_MAPPING["0.00"].name
    },
    "dimensionSnapThreshold": {
        "value": 100
    },
    "dimensionSnapEnabled": {
        "value": true
    },
    "angleSnapThreshold": {
        "value": 15
    },
    "angleSnapEnabled": {
        "value": true
    },
    "gridThreshold": {
        "value": 100
    },
    "gridEnabled": {
        "value": false
    },
    "constraintSolverThreshold": {
        "value": 10.00
    },
    "wallTypePropertyExt": {
        "value": "Brick Wall"
    },
    "wallThicknessPropertyExt": {
        "value": 200
    },
    "wallTypePropertyInt": {
        "value": "Brick Wall"
    },
    "wallThicknessPropertyInt": {
        "value": 100
    },
    "wallTypePropertyParapet": {
        "value": "Glass Curtain Wall"
    },
    "wallThicknessPropertyParapet": {
        "value": 25
    },
    "slabThicknessProperty": {
        "value": 200
    },
    "slabOverhangProperty": {
        "value": 200
    },
    "plinthHeightProperty": {
        "value": 200
    },
    "disableTooltips": {
        "value": false
    },
    "toggleInternalDimensionsProperty": {
        "value": false
    },
    "toggleNearestPointSnap": {
      "value": false
    },
    "siteArea": {
        "value": 6500
    },
    "location": {
        "value": "",
    }
  };

const initialState = {
  length: {
    partOf: "unitData",
    title: "Length",
    type: "dropdown",
    options: [
      "Millimeters",
      "Centimeters",
      "Metres",
      "Inches",
      "Feet-inches",
      // "Kilometer",
    ],
    value: UNITS_MAPPING["Millimeters"].name,
    valueReference: "length",
    hasToggle: false,
  },
  // area: {
  //   partOf: "unitData",
  //   title: "Area",
  //   type: "dropdown",
  //   options: [
  //     "Acre",
  //     "Hectare",
  //     "Square Feet",
  //     "Square Yard",
  //     "Square Centimeter",
  //     "Square Meter",
  //     "Square Kilometer",
  //   ],
  //   value: "",
  //   valueReference: "area",
  //   hasToggle: false,
  // },
  // volume: {
  //   partOf: "unitData",
  //   title: "Volume",
  //   type: "dropdown",
  //   options: [
  //     "Cubic Meter",
  //     "Litre",
  //     "Kilo Litre",
  //     "Cubic Yard",
  //     "Gallon",
  //     "Pint",
  //     "tsp",
  //     "Quart",
  //   ],
  //   value: "",
  //   valueReference: "volume",
  //   hasToggle: false,
  // },
  // angle: {
  //   partOf: "unitData",
  //   title: "Angle",
  //   type: "dropdown",
  //   options: ["Degree", "Radian"],
  //   value: "",
  //   valueReference: "angle",
  //   hasToggle: false,
  // },
  tolerance: {
    partOf: "unitData",
    title: "Tolerance",
    type: "dropdown",
    options: [
      "0",
      "0.0",
      "0.00",
      "0.000",
      "0.0000",
      "0.00000",
      "0.000000",
      "0.0000000",
      "0.00000000"
    ],
    value: TOLERANCE_MAPPING["0.00"].name,
    valueReference: "tolerance",
    hasToggle: false,
  },
  dimensionSnapThreshold: {
    partOf: "unitData",
    title: "Dimension Snap",
    type: "input",
    value: "100.00",
    valueReference: "dimensionSnapThreshold",
    hasToggle: true,
    toggleValue: true,
    toggleValueReference: "dimensionSnapEnabled",
  },
  angleSnapThreshold: {
    partOf: "unitData",
    title: "Angle Snap",
    type: "input",
    value: "15",
    valueReference: "angleSnapThreshold",
    hasToggle: true,
    toggleValue: true,
    toggleValueReference: "angleSnapEnabled",
  },
  parallelSnapEnabled: {
    partOf: "unitData",
    title: "Parallel Snap",
    type: "colorPicker",
    value: colorUtil.type.parallel,
    valueReference: "",
    hasToggle: true,
    toggleValue: true,
    toggleValueReference: "parallelSnapEnabled"
  },
  orthogonalSnapEnabled: {
    partOf: "unitData",
    title: "Orthogonal Snap",
    type: "colorPicker",
    value: colorUtil.type.orthogonal,
    valueReference: "",
    hasToggle: true,
    toggleValue: true,
    toggleValueReference: "orthogonalSnapEnabled"
  },
  normalSnapEnabled: {
    partOf: "unitData",
    title: "Normal Snap",
    type: "colorPicker",
    value: colorUtil.type.normal,
    valueReference: "",
    hasToggle: true,
    toggleValue: true,
    toggleValueReference: "normalSnapEnabled"
  },
  toggleNearestPointSnap: {
    partOf: "unitData",
    title: "Nearest Point Snap",
    type: "none",
    value: false,
    valueReference: "toggleNearestPointSnap",
    hasToggle: true,
    toggleValue: false,
    toggleValueReference: "toggleNearestPointSnap"
  },
  gridThreshold: {
    partOf: "unitData",
    title: "Grid",
    type: "input",
    value: "100.00",
    valueReference: "gridThreshold",
    hasToggle: true,
    toggleValue: false,
    toggleValueReference: "gridEnabled",
  },
  constraintSolverThreshold: {
    partOf: "unitData",
    title: "Length Constraint",
    type: "input",
    value: "10.00",
    valueReference: "constraintSolverThreshold",
    hasToggle: false,
  },
  toggleInternalDimensionsProperty: {
    partOf: "unitData",
    title: "Enable Internal Dimensions",
    type: "none",
    value: false,
    valueReference: "toggleInternalDimensionsProperty",
    hasToggle: true,
    toggleValue: false,
    toggleValueReference: "toggleInternalDimensionsProperty"
  },
  wallProfileTypeProperty: {
    partOf: "unitData",
    title: "Wall Location",
    type: "dropdown",
    options: [
      "Centre",
      "Internal",
      "External"
    ],
    value: "Centre",
    valueReference: "wallProfileTypeProperty",
    hasToggle: false,
  },
  // partOf SITE
  siteArea: {
    partOf: "siteData",
    title: "Site Area",
    type: "input",
    value: "6500.00",
    valueReference: "siteArea",
    hasToggle: false,
  },
  location: {
    partOf: "siteData",
    title: "Location",
    type: "input",
    value: "",
    valueReference: "location",
    hasToggle: false,
  },
  // partOf CONSTRUCTION
  wallTypePropertyExt: {
    partOf: "constructionData",
    superType: "ext",
    title: "Type",
    type: "dropdown",
    options: [
      "Brick Wall",
      "Concrete Wall",
      "Wood Stud Wall",
      "Glass Curtain Wall",
    ],
    value: "Brick Wall",
    valueReference: "wallTypePropertyExt",
    hasToggle: false,
  },
  wallThicknessPropertyExt: {
    partOf: "constructionData",
    superType: "ext",
    title: "Thickness",
    type: "input",
    value: "200.00",
    valueReference: "wallThicknessPropertyExt",
    hasToggle: false,
  },
  wallTypePropertyInt: {
    partOf: "constructionData",
    superType: "int",
    title: "Type",
    type: "dropdown",
    options: [
      "Brick Wall",
      "Concrete Wall",
      "Wood Stud Wall",
      "Glass Curtain Wall",
    ],
    value: "Brick Wall",
    valueReference: "wallTypePropertyInt",
    hasToggle: false,
  },
  wallThicknessPropertyInt: {
    partOf: "constructionData",
    superType: "int",
    title: "Thickness",
    type: "input",
    value: "100.00",
    valueReference: "wallThicknessPropertyInt",
    hasToggle: false,
  },
  wallTypePropertyParapet: {
    partOf: "constructionData",
    superType: "para",
    title: "Type",
    type: "dropdown",
    options: [
      "Brick Wall",
      "Concrete Wall",
      "Wood Stud Wall",
      "Glass Curtain Wall",
    ],
    value: "Glass Curtain Wall",
    valueReference: "wallTypePropertyParapet",
    hasToggle: false,
  },
  wallThicknessPropertyParapet: {
    partOf: "constructionData",
    superType: "para",
    title: "Thickness",
    type: "input",
    value: "25.00",
    valueReference: "wallThicknessPropertyParapet",
    hasToggle: false,
  },
  slabThicknessProperty: {
    partOf: "constructionData",
    superType: "slab",
    title: "Thickness",
    type: "input",
    value: "200.00",
    valueReference: "slabThicknessProperty",
    hasToggle: false,
  },
  roofOverhang: {
    partOf: "constructionData",
    superType: "slab",
    title: "Overhang",
    type: "input",
    value: "200.00",
    valueReference: "roofOverhang",
    hasToggle: false,
  },
  plinthHeightProperty: {
    partOf: "constructionData",
    superType: "slab",
    title: "Plinth",
    type: "input",
    value: "200.00",
    valueReference: "plinthHeightProperty",
    hasToggle: false,
  },
  ceilingHeightProperty: {
    partOf: "constructionData",
    superType: "slab",
    title: "Ceiling Height",
    type: "input",
    value: "2400.00",
    valueReference: "ceilingHeightProperty",
    hasToggle: false,
  }
}

export const projectPropertiesSlice = createSlice({
  name: 'projectProperties',
  initialState,
  reducers: {
    resetProjectProperties: state => initialState,
    initializeProjectProperties: (state, action) => {
        let {userSettings} = action.payload;
        const gridSnapThreshold = userSettings.gridSnapThreshold || 0.3937007874015748;

        userSettings = {
            ...userSettings,
            slabThicknessProperty: userSettings.slabThicknessProperty ? userSettings.slabThicknessProperty : store.projectProperties.properties.slabThicknessProperty.getValue(),
            wallThicknessPropertyExt: userSettings.wallThicknessPropertyExt ? userSettings.wallThicknessPropertyExt: store.projectProperties.properties.wallThicknessPropertyExt.getValue(),
            wallThicknessPropertyInt: userSettings.wallThicknessPropertyInt ? userSettings.wallThicknessPropertyInt : store.projectProperties.properties.wallThicknessPropertyInt.getValue(),
            wallThicknessPropertyParapet: userSettings.wallThicknessPropertyParapet ? userSettings.wallThicknessPropertyParapet : store.projectProperties.properties.wallThicknessPropertyParapet.getValue(),
            plinthHeightProperty: userSettings.plinthHeightProperty ? userSettings.plinthHeightProperty : store.projectProperties.properties.plinthHeightProperty.getValue(),
            ceilingHeightProperty: userSettings.ceilingHeightProperty ? userSettings.ceilingHeightProperty : store.projectProperties.properties.ceilingHeightProperty.getValue(),
            wallTypePropertyExt: userSettings.wallTypePropertyExt ? userSettings.wallTypePropertyExt : store.projectProperties.properties.wallTypePropertyExt.getValue(),
            wallTypePropertyInt: userSettings.wallTypePropertyInt ? userSettings.wallTypePropertyInt : store.projectProperties.properties.wallTypePropertyInt.getValue(),
            wallTypePropertyParapet: userSettings.wallTypePropertyParapet ? userSettings.wallTypePropertyParapet : store.projectProperties.properties.wallTypePropertyParapet.getValue(),
            angleSnapThreshold: userSettings.angleSnapThreshold ? userSettings.angleSnapThreshold : store.projectProperties.properties.angleSnapThreshold.getValue(),
            angleSnapEnabled: userSettings.angleSnapEnabled ? userSettings.angleSnapEnabled : store.projectProperties.properties.angleSnapEnabled.getValue(),
            constraintSolverThreshold: userSettings.constraintSolverThreshold ? userSettings.constraintSolverThreshold : store.projectProperties.properties.constraintSolverThreshold.getValue(),
            toggleNearestPointSnap: userSettings.toggleNearestPointSnap || store.projectProperties.properties.toggleNearestPointSnap.getValue(),
            toggleInternalDimensionsProperty: userSettings.toggleInternalDimensionsProperty ? userSettings.toggleInternalDimensionsProperty : store.projectProperties.properties.toggleInternalDimensionsProperty.getValue(),
            disableTooltips: userSettings.disableTooltips ? userSettings.disableTooltips : store.projectProperties.properties.disableTooltips.getValue(),
            // : userSettings. ? userSettings. : store.projectProperties.properties..getValue(),
        }
        // console.log(userSettings);


        if(userSettings.hasOwnProperty("unitsType")) {
            state.length.value = store.$scope.units_types[userSettings.unitsType].name;
            UpdateUnit(store.$scope.units_types[userSettings.unitsType]);
        }
        if(userSettings.hasOwnProperty("tolerance")) {
            state.tolerance.value = store.$scope.tolerance_levels[userSettings.tolerance].name;
            UpdateTolerance(store.$scope.tolerance_levels[userSettings.tolerance]);
        }
        if(userSettings.hasOwnProperty("gridSnapThreshold")) {
            state.dimensionSnapThreshold.value = DisplayOperation.convertToDefaultDimension(gridSnapThreshold);
            // UpdateDimensionSnapThreshold(state.dimensionSnapThreshold.value);
        }
        if(userSettings.hasOwnProperty("gridSnapEnabled")){
            state.dimensionSnapThreshold.toggleValue = userSettings.gridSnapEnabled;
            // UpdateDimensionSnapEnabled(userSettings.gridSnapEnabled);
        }
        if(userSettings.hasOwnProperty("angleSnapThreshold")){
            state.angleSnapThreshold.value = userSettings.angleSnapThreshold;
            // UpdateAngleSnapThreshold(userSettings.angleSnapThreshold);
        }
        if(userSettings.hasOwnProperty("angleSnapEnabled")){
            state.angleSnapThreshold.toggleValue = userSettings.angleSnapEnabled;
            // UpdateAngleSnapEnabled(userSettings.angleSnapEnabled);
        }
        if(userSettings.hasOwnProperty("parallelSnapEnabled")){
            state.parallelSnapEnabled.toggleValue = userSettings.parallelSnapEnabled;
            // UpdateAngleSnapEnabled(userSettings.angleSnapEnabled);
        }
        if(userSettings.hasOwnProperty("orthogonalSnapEnabled")){
            state.orthogonalSnapEnabled.toggleValue = userSettings.orthogonalSnapEnabled;
            // UpdateAngleSnapEnabled(userSettings.angleSnapEnabled);
        }
        if(userSettings.hasOwnProperty("normalSnapEnabled")){
            state.normalSnapEnabled.toggleValue = userSettings.normalSnapEnabled;
            // UpdateAngleSnapEnabled(userSettings.angleSnapEnabled);
        }
        if(userSettings.hasOwnProperty("gridUnitVal")){
            state.gridThreshold.value = DisplayOperation.convertToDefaultDimension(userSettings.gridUnitVal);
            // UpdateGridThreshold(state.gridThreshold.value);
        }
        if(userSettings.hasOwnProperty("gridEnabled")){
            state.gridThreshold.toggleValue = userSettings.gridEnabled;
            // UpdateGridEnabled(userSettings.gridEnabled);
        }
        if(userSettings.hasOwnProperty("constraintSolverThreshold")){
            state.constraintSolverThreshold.value = DisplayOperation.convertToDefaultDimension(userSettings.constraintSolverThreshold);
            // UpdateConstraintSolverThreshold(state.constraintSolverThreshold.value);
        }
        if(userSettings.hasOwnProperty("toggleNearestPointSnap")){
            state.toggleNearestPointSnap.toggleValue = userSettings.toggleNearestPointSnap;
        }
        if(userSettings.hasOwnProperty("toggleInternalDimensionsProperty")){
            state.toggleInternalDimensionsProperty.toggleValue = userSettings.toggleInternalDimensionsProperty;
            // const options = {
            //     doNotExecuteCmd: true
            // }
            // UpdateToggleInternalDimensionsProperty(state.toggleInternalDimensionsProperty.value, options);
        }
        if(userSettings.hasOwnProperty("wallTypePropertyExt")){
            state.wallTypePropertyExt.value = WALL_TYPE_MAPPING[userSettings.wallTypePropertyExt].name;
            // UpdateWallTypePropertyExt(WALL_TYPE_MAPPING[userSettings.wallTypePropertyExt].name);
        }
        if(userSettings.hasOwnProperty("wallThicknessPropertyExt")){
            state.wallThicknessPropertyExt.value = DisplayOperation.convertToDefaultDimension(userSettings.wallThicknessPropertyExt);
            // UpdateWallThicknessPropertyExt(state.wallThicknessPropertyExt.value);
        }

        if(userSettings.hasOwnProperty("wallTypePropertyInt")){
            state.wallTypePropertyInt.value = WALL_TYPE_MAPPING[userSettings.wallTypePropertyInt].name;
            // UpdateWallTypePropertyInt(WALL_TYPE_MAPPING[userSettings.wallTypePropertyInt].name);
        }
        if(userSettings.hasOwnProperty("wallThicknessPropertyInt")){
            state.wallThicknessPropertyInt.value = DisplayOperation.convertToDefaultDimension(userSettings.wallThicknessPropertyInt);
            // UpdateWallThicknessPropertyInt(state.wallThicknessPropertyInt.value);
        }

        if(userSettings.hasOwnProperty("wallTypePropertyParapet")){
            state.wallTypePropertyParapet.value = WALL_TYPE_MAPPING[userSettings.wallTypePropertyParapet].name;
            // UpdateWallTypePropertyParapet(WALL_TYPE_MAPPING[userSettings.wallTypePropertyParapet].name);
        }
        if(userSettings.hasOwnProperty("wallThicknessPropertyParapet")){
            state.wallThicknessPropertyParapet.value = DisplayOperation.convertToDefaultDimension(userSettings.wallThicknessPropertyParapet);
            // UpdateWallThicknessPropertyParapet(state.wallThicknessPropertyParapet.value);
        }

        if(userSettings.hasOwnProperty("plinthHeightProperty")){
            state.plinthHeightProperty.value = DisplayOperation.convertToDefaultDimension(userSettings.plinthHeightProperty);
            // UpdatePlinthHeightProperty(state.plinthHeightProperty.value);
        }
        if(userSettings.hasOwnProperty("ceilingHeightProperty")){
            state.ceilingHeightProperty.value = DisplayOperation.convertToDefaultDimension(userSettings.ceilingHeightProperty);
        }
        if(userSettings.hasOwnProperty("roofOverhang")){
            state.roofOverhang.value = DisplayOperation.convertToDefaultDimension(userSettings.roofOverhang);
            // UpdateSlabOverhangProperty(state.slabOverhangProperty.value);
        }
        if(userSettings.hasOwnProperty("slabThicknessProperty")){
            state.slabThicknessProperty.value = DisplayOperation.convertToDefaultDimension(userSettings.slabThicknessProperty);
            // UpdateSlabThicknessProperty(state.slabThicknessProperty.value);
        }
        if(userSettings.hasOwnProperty("wallProfileTypeProperty")){
            state.wallProfileTypeProperty.value = userSettings.wallProfileTypeProperty
            // UpdateSlabThicknessProperty(state.slabThicknessProperty.value);
        }
    },
    updateProjectProperties: (state, action) => {
      let {key, value} = action.payload;
    //   console.log("Update project property", key, value);
      switch (key) {
        case "length":
            UpdateUnit(UNITS_MAPPING[state[key].value]);
            break;
        case "tolerance":
            UpdateTolerance(TOLERANCE_MAPPING[state[key].value]);
            break;
        case "dimensionSnapThreshold":
            UpdateDimensionSnapThreshold(state[key].value);
            break;
        case "dimensionSnapEnabled":
            UpdateDimensionSnapEnabled(state["dimensionSnapThreshold"].toggleValue);
            break;
        case "angleSnapThreshold":
            const angle = parseInt(state[key].value) < 5 ? 5 : parseInt(state[key].value);
            UpdateAngleSnapThreshold(angle);
            break;
        case "angleSnapEnabled":
            UpdateAngleSnapEnabled(state["angleSnapThreshold"].toggleValue);
            break;
        case "parallelSnapEnabled":
            UpdateParallelSnapEnabled(state[key].toggleValue);
            break;
        case "orthogonalSnapEnabled":
            UpdateOrthogonalSnapEnabled(state[key].toggleValue);
            break;
        case "normalSnapEnabled":
            UpdateNormalSnapEnabled(state[key].toggleValue);
            break;
        case "gridThreshold":
            UpdateGridThreshold(state[key].value);
            break;
        case "gridEnabled":
            UpdateGridEnabled(state["gridThreshold"].toggleValue);
            break;
        case "constraintSolverThreshold":
            UpdateConstraintSolverThreshold(state[key].value);
            break;
        case "toggleInternalDimensionsProperty":
            UpdateToggleInternalDimensionsProperty(state[key].toggleValue);
            break;
        case "toggleNearestPointSnap":
            UpdateToggleNearestPointSnap(state[key].toggleValue);
            break;
        case "wallTypePropertyExt":
            UpdateWallTypePropertyExt(state[key].value);
            state["wallThicknessPropertyExt"].value = DisplayOperation.convertToDefaultDimension(
              store.projectProperties.properties.wallThicknessPropertyExt.getValue()
            )
            break;
        case "wallTypePropertyInt":
            UpdateWallTypePropertyInt(state[key].value);
            state["wallThicknessPropertyInt"].value = DisplayOperation.convertToDefaultDimension(
              store.projectProperties.properties.wallThicknessPropertyInt.getValue()
            )
            break;
        case "wallTypePropertyParapet":
            UpdateWallTypePropertyParapet(state[key].value);
            state["wallThicknessPropertyParapet"].value = DisplayOperation.convertToDefaultDimension(
              store.projectProperties.properties.wallThicknessPropertyParapet.getValue()
            )
            break;
        case "wallThicknessPropertyExt":
            UpdateWallThicknessPropertyExt(state[key].value);
            break;
        case "wallThicknessPropertyInt":
            UpdateWallThicknessPropertyInt(state[key].value);
            break;
        case "wallThicknessPropertyParapet":
            UpdateWallThicknessPropertyParapet(state[key].value);
            break;
        case "slabThicknessProperty":
            UpdateSlabThicknessProperty(state[key].value);
            break;
        case "roofOverhang":
            UpdateSlabOverhangProperty(state[key].value);
            break;
        case "plinthHeightProperty":
            UpdatePlinthHeightProperty(state[key].value);
            break;
        case "ceilingHeightProperty":
            UpdateCeilingHeightProperty(state[key].value);
            break;
        case "siteArea":
            updateSiteArea(state[key].value);
            break;
        case "wallProfileTypeProperty":
          updateWallProfileType(state[key].value);
          break;
        default:
            console.log("Handle " + key + " change.");
            break;
      }
      // state[key].value = value;
    },
    mapToUnits: (state, action) => {
      state.gridThreshold.value = DisplayOperation.convertToDefaultDimension(
        store.userSettingsInStructure.gridUnitVal
      );
      state.dimensionSnapThreshold.value = DisplayOperation.convertToDefaultDimension(
        store.userSettingsInStructure.gridSnapThreshold
      );
      state.constraintSolverThreshold.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.constraintSolverThreshold.getValue()
      );
      state.wallThicknessPropertyExt.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.wallThicknessPropertyExt.getValue()
      );
      state.wallThicknessPropertyInt.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.wallThicknessPropertyInt.getValue()
      );
      state.wallThicknessPropertyParapet.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.wallThicknessPropertyParapet.getValue()
      );
      state.slabThicknessProperty.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.slabThicknessProperty.getValue()
      );
      state.roofOverhang.value = DisplayOperation.convertToDefaultDimension(
        store.userSettingsInStructure.roofOverhang
      );
      state.plinthHeightProperty.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.plinthHeightProperty.getValue()
      );
      state.ceilingHeightProperty.value = DisplayOperation.convertToDefaultDimension(
        store.projectProperties.properties.ceilingHeightProperty.getValue(),
        {
          roundOff: true
        }
      );
    },
    updateProperty: (state, action) => {
      const { property, value, codeInitiated } = action.payload;
      if (property === "length") {
        state[property].value = UNITS_MAPPING[value].name
      }
      else if (property === "tolerance") {
        state[property].value = TOLERANCE_MAPPING[value].name
      }
      else if (["dimensionSnapEnabled", "gridSnapEnabled"].includes(property)) {
        state["dimensionSnapThreshold"].toggleValue = value;
      }
      else if (property === "angleSnapEnabled") {
        state["angleSnapThreshold"].toggleValue = value;
      }
      else if (property === "parallelSnapEnabled") {
        state[property].toggleValue = value;
      }
      else if (property === "orthogonalSnapEnabled") {
        state[property].toggleValue = value;
      }
      else if (property === "normalSnapEnabled") {
        state[property].toggleValue = value;
      }
      else if (property === "gridEnabled") {
        state["gridThreshold"].toggleValue = value;
      }
      else if(property === "gridUnitVal"){
        state["gridThreshold"].value = value;
      }
      else if(property === "gridSnapThreshold"){
        state["dimensionSnapThreshold"].value = value;
      }
      else if (property === "toggleNearestPointSnap") {
        state[property].toggleValue = value;
      }
      else if (property === "toggleInternalDimensionsProperty") {
        state[property].toggleValue = value;
      }
      else if (property.includes("wallType")) {
        state[property].value = value;
      }
      else {
        state[property].value = value;
      }
    }
  }
})

const UpdateToggleInternalDimensionsProperty = (newValue, options) => {
    store.$scope.userSetBIMProperties.toggleInternalDimensionsProperty = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("toggleInternalDimensionsProperty", options);
}

const UpdateToggleNearestPointSnap = (newValue, options) => {
    store.$scope.userSetBIMProperties.toggleNearestPointSnap = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("toggleNearestPointSnap", options);
}

const updateSiteArea = async function (newValue) {
    if (store.$scope.areasUpdateNeeded) {
        store.$scope.areas.site_area = newValue;
        updateArea(true);
    }
    setUserSettingAndRecord("siteArea", store.$scope.areas.site_area);
};

const updateWallProfileType = function (newValue, options) {
    store.$scope.userSetBIMProperties.wallProfileTypeProperty = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallProfileTypeProperty", options);
};

const UpdatePlinthHeightProperty = (newValue) => {
    // console.log("UpdatePlinthHeightProperty", newValue);
    store.$scope.updateViaReact = true;
    store.$scope.userSetBIMProperties.plinthHeightProperty = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("plinthHeightProperty")
    store.$scope.updateViaReact = false;
}

const UpdateCeilingHeightProperty = (newValue) => {
    // console.log("UpdatePlinthHeightProperty", newValue);
    store.$scope.updateViaReact = true;
    store.$scope.userSetBIMProperties.ceilingHeightProperty = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("ceilingHeightProperty")
    store.$scope.updateViaReact = false;
}

const UpdateSlabOverhangProperty = (newValue) => {
    // console.log("UpdateSlabOverhangProperty", newValue);
    store.$scope.userSetBIMProperties.roofOverhang = newValue;
    userSetBIMPropertiesHandler.changeRoofOverhang(store.$scope.userSetBIMProperties.roofOverhang)
}

const UpdateSlabThicknessProperty = (newValue) => {
    // console.log("UpdateSlabThicknessProperty", newValue);
    store.$scope.updateViaReact = true;
    store.$scope.userSetBIMProperties.slabThicknessProperty = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("slabThicknessProperty")
    store.$scope.updateViaReact = false;

}

const UpdateWallThicknessPropertyParapet = (newValue) => {
    // console.log("UpdateWallThicknessPropertyParapet", newValue);
    store.$scope.userSetBIMProperties.wallThicknessPropertyParapet = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallThicknessPropertyParapet")
}

const UpdateWallThicknessPropertyInt = (newValue) => {
    // console.log("UpdateWallThicknessPropertyInt", newValue);
    store.$scope.userSetBIMProperties.wallThicknessPropertyInt = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallThicknessPropertyInt")
}

const UpdateWallThicknessPropertyExt = (newValue) => {
    // console.log("UpdateWallThicknessPropertyExt", newValue);
    store.$scope.userSetBIMProperties.wallThicknessPropertyExt = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallThicknessPropertyExt")
}
const UpdateWallTypePropertyParapet = (newValue) => {
    // console.log("UpdateWallTypePropertyParapet", newValue);
    store.$scope.userSetBIMProperties.wallTypePropertyParapet = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallTypePropertyParapet")
}

const UpdateWallTypePropertyInt = (newValue) => {
    // console.log("UpdateWallTypePropertyInt", newValue);
    store.$scope.userSetBIMProperties.wallTypePropertyInt = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallTypePropertyInt")
}

const UpdateWallTypePropertyExt = (newValue) => {
    // console.log("UpdateWallTypePropertyExt", newValue);
    store.$scope.userSetBIMProperties.wallTypePropertyExt = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("wallTypePropertyExt")
}

const UpdateConstraintSolverThreshold = (newValue) => {
    store.$scope.userSetBIMProperties.constraintSolverThreshold = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("constraintSolverThreshold");
}

const UpdateGridEnabled = (newValue) => {
    store.$scope.userSetBIMProperties.gridEnabled = newValue;
    userSetBIMPropertiesHandler.changeGridEnabled()
}

const UpdateGridThreshold = (newValue) => {
    store.$scope.userSetBIMProperties.gridUnitVal = newValue;
    userSetBIMPropertiesHandler.changeGridUnit(store.$scope.userSetBIMProperties.gridUnitVal);
}
const UpdateAngleSnapEnabled = (newValue) => {
    store.$scope.userSetBIMProperties.angleSnapEnabled = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("angleSnapEnabled");
}

const UpdateParallelSnapEnabled = (newValue) => {
    store.$scope.userSetBIMProperties.parallelSnapEnabled = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("parallelSnapEnabled");
}

const UpdateOrthogonalSnapEnabled = (newValue) => {
    store.$scope.userSetBIMProperties.orthogonalSnapEnabled = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("orthogonalSnapEnabled");
}

const UpdateNormalSnapEnabled = (newValue) => {
    store.$scope.userSetBIMProperties.normalSnapEnabled = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("normalSnapEnabled");
}

const UpdateAngleSnapThreshold= (newValue) => {
    store.$scope.userSetBIMProperties.angleSnapThreshold = newValue;
    userSetBIMPropertiesHandler.changeProjectProperty("angleSnapThreshold");
}
const UpdateDimensionSnapEnabled = (newValue) => {
    store.$scope.userSetBIMProperties.gridSnapEnabled = newValue;
    userSetBIMPropertiesHandler.changeGridSnapEnabled(store.$scope.userSetBIMProperties.gridSnapEnabled)
}

const UpdateDimensionSnapThreshold = (newValue) => {
    store.$scope.userSetBIMProperties.gridSnapThreshold = newValue;
    userSetBIMPropertiesHandler.changeGridSnapThreshold(store.$scope.userSetBIMProperties.gridSnapThreshold)
}

const UpdateUnit = function (unit) {
    // console.log($scope.units_type);
    // console.log("this is update unit ",unit);
    //this.matInputMain.nativeElement.blur();
    store.$scope.units_type = unit;

    // setTimeout(function(){
    //     $window.document.getElementById('units_id').blur();
    //     canvas.focus();

    // },1000);
    // $scope.setDefaultTolerance();
    DisplayOperation.updateInputValues("units_type");
    // storeyView.changeHeightUnits();
    updateRoofAccordion();
    setUserSettingAndRecord('unitsType', unit.id);
    // if(unit.value === "feet-inches")   $scope.UpdateTolerance({id: 0, value: 0, name: "0", $$mdSelectId: 11});
    userSetBIMPropertiesHandler.updateUnitType(unit);
}

const UpdateTolerance = function (tolerance, save = false) {
    // $scope.unit_tolerance = tolerance;
    // DisplayOperation.updateInputValues("tolerance");
    store.$scope.unit_tolerance = tolerance;

    userSetBIMPropertiesHandler.updateUnitType(store.$scope.units_type);
    // storeyView.changeHeightUnits();

    DisplayOperation.updateInputValues("tolerance");
    setUserSettingAndRecord('tolerance', tolerance.id);
    // setTimeout(function(){
    //     $window.document.getElementById('units_tolerance_id').blur();
    //     canvas.focus();
    // },800);
    // if (save) setUserSettingAndRecord("tolerance", tolerance);
};
export const {initializeProjectProperties, updateProjectProperties, resetProjectProperties, mapToUnits, updateProperty} = projectPropertiesSlice.actions;

export default projectPropertiesSlice.reducer;