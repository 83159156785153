export const routes = {
  login: "/login",
  signup: "/signup",
  selectProfession: "/selectProfession",
  forgotPassword: "/login/forgotPassword",
  resetPassword: "/reset-password/:token",
  dashboard: "/dashboard",
  team: "/dashboard/team/:teamId",
  libraries: "/dashboard/libraries",
  preferences: "/dashboard/preferences",
  termsAndConditons: "/termsAndConditons",
  templates: "/dashboard/templates",
  reports: "/dashboard/reports",
  profile: "/dashboard/profile",
  allProjects: "all-projects",
  account: "/dashboard/profile/account",
  teams: "/dashboard/profile/teams",
  billing: "/dashboard/profile/billing",
  plans: "/dashboard/profile/plans",
  project1: "/dashboard/project-1",
  project2: "/dashboard/project-2",
  workspace: "/dashboard/workspace",
  sharedWithMe: "/dashboard/shared-with-me",
  recentProjects: "/dashboard/recent-projects",
  tutorials: "/dashboard/tutorials",
  guidedProjects: "/dashboard/guided-projects",
  help: "https://help.snaptrude.com/",
  bookademo: "https://calendly.com/altaf-1/30min",
  changePassword: "/dashboard/profile/changePassword",
  payment: "/payment",
  editor: "/model/:floorkey",
  render: "/model/:floorkey/render",
  sharedProject: "/sharedProject",
  modals: "/dashboard/modals",
  fileNotFound: "/file-not-found",
  activate: "/activate/:uidb64/:token",
  visualizeYourHome: "/visualize-your-home",
  pinterestTo3Dmodel: "/pinterest-to-3Dmodel",
  yourHomeIn3D: "/your-home-in-3D",
  floorplanTo3Dmodel: "/floorplan-to-3Dmodel",
};
