import { store } from "../../modules/utilityFunctions/Store.js";
import { send_complete_scene_data } from "../serverFuncs.js";
function updateModifications(savehistory) {
  if (savehistory === true) {
    let myjson = JSON.stringify(store.canvas);
    store.stateStack.push(myjson);
  }
  // (canvas);
  // setTimeout(function () {
  //   scene_block_data();
  // }, 10);
  send_complete_scene_data("save", "normal");
}

function changeCursor(cursorType) {
  store.canvas.defaultCursor = cursorType;
}

function removeAll() {
  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    obj.get("type");
    if (obj.get("type") == "image") {
      // ("Removed image");
      store.canvas.remove(obj);
    }
  });
  store.canvas.renderAll();
}

function removeAllChildren() {
  store.canvas.forEachObject(function (targ) {
    if (targ) {
      if (targ.get("id") == "rooms") {
        if (targ.children) store.canvas.remove(targ.children);
      }
    } else {
    }
  });
}

function getCanvasTransformState() {
  if (
    store.canvas.viewportTransform[0] === 1 &&
    store.canvas.viewportTransform[1] === 0 &&
    store.canvas.viewportTransform[2] === 0
  ) {
    if (
      store.canvas.viewportTransform[3] === 1 &&
      store.canvas.viewportTransform[4] === 0 &&
      store.canvas.viewportTransform[5] === 0
    ) {
      return true;
    }
  }
  return false;
}
export {
  updateModifications,
  changeCursor,
  removeAll,
  removeAllChildren,
  getCanvasTransformState,
};
