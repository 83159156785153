import styled, { css } from "styled-components";
import { colors } from "../../themes/constant";
import { cursor } from "../../themes/cursor";
import { RIGHT_SIDEBAR_OPEN_WIDTH } from "./components/constants";

export const LoadingScreen = styled.div`
  background: #FFFFFF;
  position: fixed;
  width: 100vw;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  pointer-events: none;
`;

export const CanvasWrapper = styled.div`
  // border: 10px solid;
  box-sizing: border-box;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  position: absolute;
  cursor: ${cursor.default}

`
export const EditorWrapper = styled.div`
  background-color: ${colors.backgroundGrey};
  width: 100%;
  height: inherit;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const RightMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .right_icon {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

export const RightMenu = styled.div`
  padding: 0.48em 0.4375rem;
  margin-right: 1em;
  background: ${colors.fullWhite};
  box-shadow: 0px 4px 30px ${colors.lightGreyShadow};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: max-content;
`;

export const LeftMenu = styled.div`
  padding: 0 0.5em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  // height: 100%;
  .storyIconWrapper {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  .storeyIcon {
    &:hover {
      background-color: transparent;
      filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
        brightness(93%) contrast(98%);
    }
  }
`;

export const BottomLeftContainer = styled.div`
  padding: 1em 0.5em;
  background: ${colors.transparent};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ImageGrouping = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colors.fullWhite};
  height: 2rem;
  border-radius: 5px;
  margin-right: 0.94em;
`;

export const ImageButton = styled.div`
  border: none;
  background: ${colors.fullWhite};
  margin: 0.5rem 0;
  height: 2.5em;
  width: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
  }
  .rightMenuIcon {
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
  }
  .bottomMenuIcon {
    width: 0.9375rem;
    height: 0.9375rem;
  }
  .storeyIcon {
    width: 1.25rem;
    height: 1.66rem;
    cursor: pointer;
  }
  .selected_right_icon {
    width: 1.125rem;
    height: 1.125rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  .selected_bottom_icon {
    width: 0.9375rem;
    height: 0.9375rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  
  .top-submenu-icon {
    width: 1.125rem;
    height: 1.125rem;
    margin: 0;
    cursor: pointer;
  }
  
  .selected-top-submenu-icon {
    width: 1.125rem;
    height: 1.125rem;
    margin: 0;
    cursor: pointer;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  
  ${props => props.subMenuIcon && css`
    flex-direction: column;
    height: 2em;
    width: 2em;
    margin: 0 0;
  `}
`;

export const MainLayerWrapper = styled.div`
  border-radius: 8px;
  background-color: ${colors.fullWhite};
  position: relative;
`;

export const LayerDiv = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 2.76364px 20.7273px ${colors.lightGreyShadow};
  overflow: visible;
  border-radius: 8px;
  width: 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  max-height: 10.5rem;
  overflow: scroll;
  &::-webkit-scrollbar {
  display: none;
  }
  p {
    font-family: font-family: ${(props) => props.theme.font};;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.230303px;
    margin: 0.5rem 0;
    padding: 0;
    color: ${colors.thinGray};
  }
`;

export const LayerContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 0 0.75rem;
  &:hover {
    background-color: ${colors.white};
    cursor: pointer;
  }
`;

export const RightSidebarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.isRightSidebarOpen ? RIGHT_SIDEBAR_OPEN_WIDTH : ""};
`;

export const BottomRightView = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 1em;
  right: 1em;
  margin-right: ${props => props.isRightSidebarOpen ? RIGHT_SIDEBAR_OPEN_WIDTH : ""};
`;

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // width: 100%;
`;

export const AvatarContainer = styled.div`
  top: 3rem;
  right: 0.625rem;
  position: absolute;
`;

export const StoreyModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.fullWhite};
  box-shadow: 0px 4px 30px rgba(197, 202, 220, 0.3);
  padding: 0.8125rem 0.625rem;
  border-radius: 8px;
  .slider.round {
    border-radius: 9px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 0.625rem;
    width: 0.625rem;
    left: 0.0625em;
    bottom: 0.0625em;
    background-color: ${(props) => props.theme.colors.brightRed};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: -0.1875rem;
  }
  .label {
    width: 1.25rem;
    height: 0.6875rem;
    padding-right: 0.35rem;
  }
}
`;

export const StoreyModalWrapper = styled.div`
  display: flex;
  .singularText {
    font-weight: 400;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.1875rem 0.375rem;
  p {
    font-family: DM Sans;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.333333px;
    color: #818181;
    margin: 0;
  }
`;

export const StoreyButtonContainer = styled.div`
  height: 1.5625rem;
  width: 8.75rem;
  padding-top: 0.8125rem;
`;

export const StoreyInstruction = styled.div`
  fontweight: 400;
  color: #bdbbbb;
  text-align: center;
  width: 5.6875rem;
  font-size: 10px;
  margin: 0;
  margin-bottom: 1rem;
`;

export const StoreyMainWrapper = styled.div`
  position: absolute;
  left: 3.75rem;
`;

export const SingleLayerCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .layerHover {
    display: none;
    height: 2.5rem;
    align-items: center;
  }
  &:hover {
    .layerHover {
      display: flex;
    }
  }
`;

export const LayerNavigation = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0.690909px 2.76364px rgba(189, 187, 187, 0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  cursor: pointer;
`;
