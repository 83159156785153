import * as DLL from "./doubly-linked-list";
import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { nonDefaultMesh, assignProperties } from "./sceneStateFuncs.js";
import { updateRoofAccordion } from "./roofVisibilityFuncs.js";
import { generateBrepForComponents } from "./brepOperations.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import {
  updateButtonsOnTop,
  onSolid,
  deepCopyObject,
  getCSGFormOfMesh,
} from "../modules/extrafunc.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { removeSelectionBox, click } from "./meshEvents.js";
import {
  Procedure,
  ConverMassToComponents,
} from "../modules/wallEngine/snaptrudeEngine.js";
import { createBuildingEngine } from "../modules/createBuilding/createBuildingEngine.js";
import { calculateAreaOfIrregularPolygons } from "./sceneFuncs.js";
import { projectionOfPointOnLine, snapVertData } from "./snapFuncs.js";
import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
import { searchForId, removeDuplicates, makeid } from "./arrayFuncs.js";
import {
  getMeshSlope,
  isOverlapBtwMeshes,
  findChildIntesection,
  findMeshExtremePoints,
  isPartOfBBInfo,
  makeWallPath,
  calculateAreaOfTriangle,
  getCollMeshRange,
  assignHeightForNewMesh,
  checkForParentMesh,
  getBaseVertices,
  findMeshExtremePointsBBInfo,
  convertToWallCoords,
} from "./wall_generation_helper.js";
import { appElement } from "./bimDataFuncs.js";
import { calculateEuclidianDistance } from "./polygon_dip_workers.js";
import {
  assignFloorAndRoofForNewMesh,
  assignFloorForExtendedMesh,
  findFloorForNewWall,
} from "./floorAndRoofHelper.js";
import { setUVScale } from "./mats.js";
import { getRelationMeshRange } from "./wallRelationship.js";
import { room_types_db } from "./obj_base.js";
/*jshint esversion: 6 */
// import * as WallEngine from '../modules/wallgeneration.module.js';


function createRoomBeta(mesh, h_internal, h_external) {
  //  var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  let iter = 0;

  //allows to pass variable length to the mass block
}

// function createRoom(mesh, h_internal, h_external) {
//     var $scope = store.angular.element(appElement).scope();
//     $scope = $scope.$$childHead;
//     var indices = mesh.getIndices();
//     var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
//     var builtPol1 = [];
//     var builtPol2 = [];
//     var coords1 = [];
//     var coords2 = [];
//     var shape_temp1 = [];
//     var shape_temp2 = [];
//     var bbinfo = mesh.getBoundingInfo();
//     var h = 15.500030563610416575449066827356 * store.unit_absolute_scale / store.unit_scale;

//     let wmtrix = mesh.getWorldMatrix();
//     for(let vr=0;vr<verData.length;vr+=3){
//         let point = new BABYLON.Vector3(verData[vr], verData[vr+1], verData[vr+2]);
//         let nepoint = BABYLON.Vector3.TransformCoordinates(point, wmtrix);
//         verData[vr] = nepoint.x;
//         verData[vr+1] = nepoint.y;
//         verData[vr+2] = nepoint.z;
//     }
//     // var h = 100 * store.unit_absolute_scale;
//     var room_path = JSON.parse(mesh._properties._indices);
//     if (!mesh.room_curve) {
//         var midY = (bbinfo.maximum.y - bbinfo.minimum.y) / 2;
//         for (var i = 0; i < verData.length; i += 3) {
//             if (verData[i + 1] <= midY) {
//                 builtPol1.push([verData[i] / store.unit_scale, -verData[i + 2] / store.unit_scale, verData[i + 1] / store.unit_scale]);
//                 coords1.push(new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]));
//                 shape_temp1.push(new BABYLON.Vector3(verData[i], verData[i + 2], 0));
//             }
//             else {
//                 builtPol2.push([verData[i] / store.unit_scale, -verData[i + 2] / store.unit_scale, verData[i + 1] / store.unit_scale]);
//                 coords2.push(new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]));
//                 shape_temp2.push(new BABYLON.Vector3(verData[i], verData[i + 2], 0));
//             }
//         }
//         builtPol1 = builtPol1.slice(0, room_path.length);
//         builtPol2 = builtPol2.slice(0, room_path.length);

//         builtPol1 = builtPol1.map(x=>[Math.round(x[0]*100)/100, Math.round(x[1]*100)/100, Math.round(x[2]*100)/100]);
//         builtPol2 = builtPol2.map(x=>[Math.round(x[0]*100)/100, Math.round(x[1]*100)/100, Math.round(x[2]*100)/100]);

//         builtPol1 = removeDuplicates(builtPol1);
//         builtPol2 = removeDuplicates(builtPol2);

//         console.log(builtPol1);
//         // builtPol1[builtPol1.length - 1] = builtPol1[0];
//         // builtPol2[builtPol2.length - 1] = builtPol2[0];
//         var pol1 = offsetRoomPols(builtPol1, h_external);
//         var pol2 = offsetRoomPols(builtPol1, h_internal);

//         var pol3 = offsetRoomPols(builtPol2, h_external);
//         var pol4 = offsetRoomPols(builtPol2, h_internal);

//         // pol1 = removeCollinearPoints(pol1);
//         // pol2 = removeCollinearPoints(pol2);
//         // pol3 = removeCollinearPoints(pol3);
//         // pol4 = removeCollinearPoints(pol4);

//         var floor_depth = 12;
//         var roof_depth = 4;
//         // if (!pol1.length) return;
//         var floorMesh = generateFloorForRoom([pol1], coords1, coords2, mesh, 0, floor_depth);
//         var roof_height = $scope.selectedItemLevelHeight[mesh.level + 2] / store.inch_to_mtr;
//         var roofMesh = generateRoofForRoom([pol4], coords1, coords2, mesh, 0, roof_depth, roof_height);
//         console.log($scope.selectedItemLevelHeight, mesh.level, store.unit_scale);
//         roofMesh.position.y = $scope.selectedItemLevelHeight[mesh.level + 2] / store.inch_to_mtr + 0 * roof_depth * store.unit_scale;
//         roofMesh.position.y += roofMesh.getBoundingInfo().boundingBox.extendSizeWorld.y;

//         var flag = false, resultflg = false, pol1T = JSON.parse(JSON.stringify(pol1)),
//             pol2T = JSON.parse(JSON.stringify(pol2)), pol3T = JSON.parse(JSON.stringify(pol3)),
//             pol4T = JSON.parse(JSON.stringify(pol4));
//         var point1 = JSON.parse(JSON.stringify(pol2[1])), point2 = JSON.parse(JSON.stringify(pol4[1]));
//         // var floorMesh = generateFloorForRoom([pol1], coords1, coords2, mesh, 0);
//         // var roofMesh = generateRoofForRoom([pol3], coords1, coords2, mesh, 0);
//         floorMesh.room_name = mesh.name;
//         floorMesh.type = "floor";
//         roofMesh.room_name = mesh.name;
//         roofMesh.parent = floorMesh;
//         // roofMesh.position.y = store.floor_height * (mesh._properties._level);
//         // roofMesh.position.y = $scope.selectedItemLevelHeight[mesh.level + 1]/inch_to_mtr + roof_depth*unit_scale;
//         // console.log(roofMesh.position, $scope.selectedItemLevelHeight);
//         roofMesh.type = "roof";
//         // roofMesh.position.y = store.floor_height * (mesh._properties._level);
//         var obj_height = mesh._properties._height;
//         var n1 = pol1.length;
//         var n2 = pol2.length;

//         for (var i = 0; i < pol1.length / 1; i += 1) {
//             if (i === 0) {
//                 let result = line_intersect(pol2[i][0], pol2[i][1], pol2[(i + 1) % n2][0], pol2[(i + 1) % n2][1], pol1[pol1.length - 1][0], pol1[pol1.length - 1][1], pol1[i][0], pol1[i][1]);
//                 // console.log(result);
//                 if (result) {
//                     flag = true;
//                     pol2[i][0] = result.x;
//                     pol2[i][1] = result.y;
//                     pol4[i][0] = result.x;
//                     pol4[i][1] = result.y;
//                 }
//             }
//             resultflg = false;
//             //temporary fix to solve last iteration.
//             if (i === pol1.length - 2) {
//                 pol2[1] = point1;
//                 pol4[1] = point2;
//             }
//             //if(i>3) continue;
//             var result = line_intersect(pol2[(i + 1) % n2][0], pol2[(i + 1) % n2][1], pol2[(i + 2) % n2][0], pol2[(i + 2) % n2][1], pol1[(i + 1) % n1][0], pol1[(i + 1) % n1][1], pol1[i][0], pol1[i][1]);

//             // console.log(result);
//             if (result) {
//                 pol1T[(i + 1) % n2][0] = result.x;
//                 pol1T[(i + 1) % n2][1] = result.y;
//                 resultflg = true;
//             }

//             var ribPol1 = generateWallBase(pol1[i], pol1T[(i + 1) % n1], pol2[(i + 1) % n2], pol2[i]);
//             var wall_pol = [jQuery.extend({}, pol2[i]), jQuery.extend({}, pol2[(i + 1) % n1]), jQuery.extend({}, pol1[i]), jQuery.extend({}, pol1T[(i + 1) % n2])];

//             // result = line_intersect(pol4[(i + 1) % n2][0], pol4[(i + 1) % n2][1], pol4[(i + 2) % n2][0], pol4[(i + 2) % n2][1], pol3[(i + 1) % n1][0], pol3[(i + 1) % n1][1], pol3[i][0], pol3[i][1]);
//             // // console.log(result);
//             // if (result) {
//             //     pol3T[(i + 1) % n2][0] = result.x;
//             //     pol3T[(i + 1) % n2][1] = result.y;
//             // }

//             // var ribbon1 = generateWallStructInd(pol2[i], pol2[(i + 1) % n1], pol1[i], pol1T[(i + 1) % n2], pol4[i], pol4[(i + 1) % n1], pol3[i], pol3T[(i + 1) % n2], obj_height);

//             // var ribPol2 = generateWallBase(pol3[i], pol3T[(i + 1) % n1], pol4[(i + 1) % n2], pol4[i]);
//             ribPol1.position.y += 12;

//             var mergedMesh = ribPol1;//createWallBlock(ribPol1, ribbon1, ribPol2, coords1, coords2, mesh, 0, i);
//             mergedMesh.material = store.scene.getMaterialByName('wall_mat');
//             assignProperties(mergedMesh, -1, "wall");
//             // mergedMesh._properties._thickness = collMeshes[0]._properties._thickness; //#TODO change it to include mesh with max thickness
//             // mergedMesh._properties._height= 12;
//             mergedMesh.checkCollisions = true;
//             mergedMesh.name = "wall";
//             mergedMesh.type = "wall";
//             mergedMesh.checkCollisions = true;
//             mergedMesh.original_cords = [];
//             mergedMesh.top_cords = [];

//             mergedMesh.convertToFlatShadedMesh();

//             // mergedMesh.room_name = collMeshes[0].room_name;
//             // mergedMesh.room_type = collMeshes[0].room_type;
//             // mergedMesh.room_id = collMeshes[0].room_id;
//             //mergedMesh.room_curve = mesh1.room_curve;
//             //mergedMesh.room_path = mesh1.room_path;
//             // mergedMesh.level = collMeshes[0].level;
//             store.doublyLinkedList.append(mergedMesh);
//             // mergedMesh.floor = floorRoofObj.floor;
//             // mergedMesh.roof =  floorRoofObj.roof;

//             mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
//             // mergedMesh._properties._thickness = Math.abs(h_internal) + Math.abs(h_external);
//             // mergedMesh._properties._height = obj_height;
//             // mergedMesh.floor = [floorMesh.uniqueId];
//             // mergedMesh.roof = roofMesh.uniqueId;
//             //mergedMesh.parent = floorMesh;
//             mergedMesh.type = "wall";
//             //mergedMesh.original_cords = JSON.stringify(pol2[i]) + "/"+ JSON.stringify(pol2[(i+1)%n1]) + "/" + JSON.stringify(pol1[i]) + "/" + JSON.stringify(pol1T[(i+1)%n2]);
//             mergedMesh.original_cords = [];
//             mergedMesh.original_cords.push(JSON.parse(JSON.stringify(pol2[i])));
//             mergedMesh.original_cords.push(JSON.parse(JSON.stringify(pol2[(i + 1) % n1])));
//             mergedMesh.original_cords.push(JSON.parse(JSON.stringify(pol1T[(i + 1) % n2])));
//             mergedMesh.original_cords.push(JSON.parse(JSON.stringify(pol1[i])));

//             mergedMesh.top_cords = [];
//             mergedMesh.top_cords.push(JSON.parse(JSON.stringify(pol4[i])));
//             mergedMesh.top_cords.push(JSON.parse(JSON.stringify(pol4[(i + 1) % n1])));
//             mergedMesh.top_cords.push(JSON.parse(JSON.stringify(pol3T[(i + 1) % n2])));
//             mergedMesh.top_cords.push(JSON.parse(JSON.stringify(pol3[i])));

//             //JSON.stringify(pol2[i], pol2[(i+1)%n1], pol1T[(i+1)%n2], pol1[i]);

//             //mergedMesh.original_pol2 = [pol4[i], pol4[(i+1)%n1], pol3[i], pol3T[(i+1)%n2]];
//             mergedMesh.wall_pol = wall_pol;
//             // var bbinfo = mergedMesh.getBoundingInfo();
//             // mergedMesh.setPivotPoint(bbinfo.boundingBox.centerWorld);
//             // mergedMesh.parent = null;
//             mergedMesh.room_name = mesh.name;
//             store.doublyLinkedList.append(mergedMesh);
//             store.wallLevelArray.push(mergedMesh);

//             // if(!roomWallObj.hasOwnProperty(mesh.uniqueId)){
//             //       roomWallObj[mesh.uniqueId] = {rotation:mesh.rotation, scaling:mesh.scaling, wallArr:[], centroid:mesh.getBoundingInfo().boundingBox.centerWorld};
//             // }

//             // roomWallObj[mesh.uniqueId].wallArr.push(mergedMesh);

//             if (flag) {
//                 pol2[0] = JSON.parse(JSON.stringify(pol2T[0]));
//                 pol4[0] = JSON.parse(JSON.stringify(pol4T[0]));
//                 flag = false;
//             }
//             if (resultflg) {
//                 pol2[(i + 1) % n1][0] = pol1T[(i + 1) % n2][0];
//                 pol2[(i + 1) % n1][1] = pol1T[(i + 1) % n2][1];
//                 pol4[(i + 1) % n1][0] = pol3T[(i + 1) % n2][0];
//                 pol4[(i + 1) % n1][1] = pol3T[(i + 1) % n2][1];
//             }

//             // var mergedMesh1 = checkWallOverlap(mergedMesh);
//             // if (mergedMesh1 !== mergedMesh ){
//             //     mergedMesh1.parent = floorMesh;
//             // }
//             // window.setTimeout(function () {
//             //     var mergedMesh1 = checkWallOverlap(mergedMesh);
//             //     if (mergedMesh1 !== mergedMesh) {
//             //         // mergedMesh1.position.y = obj_height - mergedMesh.getBoundingInfo().boundingBox.maximumWorld.y/2;
//             //         // mergedMesh1.parent = floorMesh;
//             //     }
//             // }, 600);
//         }
//     }
//     else{
//         // console.log(mesh.room_path);
//         // var builtPolCurve = bezierPointsFromPath(mesh.room_path);
//         // var builtPolCurve = pointsFromPath(mesh.room_path);
//         //var bezierPoints1 = generatePointsFromPath(mesh.room_path);
//         var offsetPathExternal = offsetCurvePath(mesh.room_path, h_external);
//         var offsetPathInternal = offsetCurvePath(mesh.room_path, h_internal);
//         var bezierPoints1 = generatePointsFromPath(JSON.stringify(offsetPathExternal));
//         var bezierPoints2 = generatePointsFromPath(JSON.stringify(offsetPathInternal));
//         // console.log(offsetPath);
//         // var cubicBezierCurve1 = BABYLON.Mesh.CreateLines("cbezier", bezierPoints1, store.scene);
//         // cubicBezierCurve1.color = new BABYLON.Color3(1, 0.6, 0);
//         // var cubicBezierCurve2 = BABYLON.Mesh.CreateLines("cbezier2", bezierPoints2, store.scene);
//         // cubicBezierCurve2.color = new BABYLON.Color3(1, 0.6, 0);
//         // bezierPoints1 = bezierPoints1.reverse();
//         // bezierPoints2 = bezierPoints2.reverse();
//         var ribPolCurveWall = new BABYLON.PolygonMeshBuilder("ribpolcurveWall", bezierPoints2, store.scene);
//         ribPolCurveWall.addHole(bezierPoints1);
//         var curveWall = ribPolCurveWall.build(null, 10);
//         var curveBBinfo = curveWall.getBoundingInfo();

//         if (curveBBinfo.minimum.y < 0) curveWall.position.y += Math.abs(curveBBinfo.boundingBox.minimumWorld.y);
//         // curveWall.position.y += 10;
//         curveWall.name = "wall";
//         curveWall.type = "wall";
//         curveWall.room_name = mesh.room_name;
//         curveWall.room_type = mesh.room_type;
//         curveWall.room_path = mesh.room_path;
//         curveWall.room_curve = mesh.room_curve;

//         var ribPolCurveFloor = new BABYLON.PolygonMeshBuilder("ribpolcurveFloor", bezierPoints1, store.scene);
//         var curveFloor = ribPolCurveFloor.build(null, 0.1);
//         curveFloor.position.y = 0.1;
//         curveFloor.name = "Floor";
//         curveFloor.type = "Floor";
//         curveFloor.room_name = mesh.room_name;
//         curveFloor.room_type = mesh.room_type;
//         curveFloor.room_path = mesh.room_path;
//         curveFloor.room_curve = mesh.room_curve;
//         curveFloor.material = store.scene.getMaterialByName("floor_tile");

//         var ribPolCurveCeiling = new BABYLON.PolygonMeshBuilder("ribpolcurveCeiling", bezierPoints2, store.scene);
//         var curveCeiling = ribPolCurveCeiling.build(null, 0.5);
//         curveCeiling.position.y = 10.5;
//         curveCeiling.name = "Roof";
//         curveCeiling.type = "Roof";
//         curveCeiling.room_name = mesh.room_name;
//         curveCeiling.room_type = mesh.room_type;
//         curveCeiling.room_path = mesh.room_path;
//         curveCeiling.room_curve = mesh.room_curve;
//         curveCeiling.material = store.scene.getMaterialByName("ceiling_mat");
//         // curveWall._properties = mesh._properties;
//         // curveWall._properties._thickness = Math.abs(h_internal) + Math.abs(h_external);
//         // curveWall._properties._height = 10;
//         onSolid();
//         // console.log(curveWall);

//         //ribPolCurve.material = store.scene.getMaterialByName('wall_mat');
//         // builtPol1 = builtPolCurve.points;
//         // builtPol2 = builtPol1;
//         // var builtPolPointType1 = builtPolCurve.pointType;
//         // var builtPolPointType2 = builtPolCurve.pointType;
//         //
//         // var pol1 = offsetRoomPols(builtPol1, h_external);
//         // var pol2 = offsetRoomPols(builtPol1, h_internal);
//         //
//         // var pol3 = offsetRoomPols(builtPol2, h_external);
//         // var pol4 = offsetRoomPols(builtPol2, h_internal);
//     }

//     if (mesh.children) {
//         for ( let i = 0; i < mesh.children.length; i++) {
//             if (mesh.children[i])
//                 mesh.children[i].dispose();
//         }
//     }
//     mesh.dispose();

// }

function createRoomPolygons(pols) {
  var pols1 = [];
  var pols2 = [];
  var h = 100 * store.unit_absolute_scale;
  for (var i = 0; i < pols.length; i++) {
    pols[i] = pols[i].uniq([].join);
    // pols[i].push(pols[i][0]);
    var pol1 = offsetRoomPols(pols[i], h);
    var pol2 = offsetRoomPols(pols[i], -h);

    pols1.push(pol1);
    pols2.push(pol2);
  }
  return [pols1, pols2];
}

// function createRoom(mesh) {
//   return new Promise(async (resolve, reject) => {
//     if (nonDefaultMesh(mesh)) {
//       store.assignWallThicknessPropertiesToMesh(mesh);
//     }

//     var procedure = new Procedure();
//     var proc = new ConverMassToComponents();
//     await procedure.singleConstruct(proc, mesh);

//     onSolid(mesh);

//     resolve("createRoom successful");
//   });
// }

async function createRooms(forceOnAllMasses) {
  const _componentGenerator = async function (concernedMasses) {
    const procedure = new Procedure();
    const proc = new ConverMassToComponents();
    await procedure.construct(proc, concernedMasses);
  };

  const stackOfMasses = forceOnAllMasses ? [] : store.selectionStack;

  createBuildingEngine.isCreateRoom = !forceOnAllMasses;

  await createBuildingEngine.expressCheckout(
    stackOfMasses,
    _componentGenerator
  );

  return Promise.resolve();
}

// async function checkWallOverlapParallel(num){
//     let promise1 = checkWallOverlapLoop(0, store.doublyLinkedList.size()/2);
//     let promise2 = checkWallOverlapLoop(doublyLinkedList.size()/2, store.doublyLinkedList.size());
//
//     let done = await Promise.all([promise1, promise2]);
//     setTimeout(assignWallNeighbours, 1000); //call wall relationship maker
// }
//
// function checkWallOverlapLoop(start, end){
//     return new Promise(function (resolve, reject) {
//          for(var a=start, i=0; a < end; a++){
//              var node = store.doublyLinkedList.item(a);
//              if(node && node.data)
//                 checkWallOverlap(node);
//         }
//         resolve("success");
//     });
// }

function offsetRoomPols(pol, h) {
  function _getOffset(pol, h) {
    var n = pol.length;
    var offPol = [];
    var flag = false;
    for (var i = 0; i < pol.length; i++) {
      var mi = getSlope(pol, i);
      var mii = getSlope(pol, i + 1);
      // var len_i = getDistance(pol, i);
      var si = getOffsetPointSi(pol, i, h);
      var ri = getOffsetPointRi(pol, i, h);
      var sii = getOffsetPointSi(pol, i + 1, h);
      var rii = getOffsetPointRi(pol, i + 1, h);
      // if (!si || !ri || !sii || !rii){
      // 	continue;
      // }
      if (Math.abs(mii - mi) < 0.00001) {
        continue;
      }
      if (mi != "nan" && mii != "nan") {
        var x = (mii * rii - mi * ri + si - sii) / (mii - mi);
        var y =
          (mi * mii * rii - mi * mii * ri + mii * si - mi * sii) / (mii - mi);
      } else if (mi == "nan" && mii == "nan") {
        // offPol.push(offPol[i-1]);
        continue;
      } else if (mi == "nan") {
        var x = ri;
        var y = sii - mii * rii + mii * ri;
      } else if (mii == "nan") {
        var x = rii;
        var y = si;
        var y = mi * rii - mi * ri + si;
      }
      if (!x || !y) {
        // console.log(mi, mii, si, sii, ri, rii);
      }
      offPol.push([x, y, pol[(i + 1) % pol.length][2]]);
    }

    return offPol;
  }

  let offPol = _getOffset(pol, h);

  let areaBefore = calculateAreaOfIrregularPolygons(deepCopyObject(pol));
  let areaAfter = calculateAreaOfIrregularPolygons(deepCopyObject(offPol));

  let offsetValue = h;
  if (offsetValue < 0) {
    if (areaAfter > areaBefore) {
      offPol = _getOffset(pol, -offsetValue);
    }
  } else {
    if (areaAfter < areaBefore) {
      offPol = _getOffset(pol, -offsetValue);
    }
  }

  return offPol;
}

function offsetRoomPolsRoof(pol, h) {
  function _getOffset(pol, h) {
    var n = pol.length;
    var offPol = [];
    var flag = false;
    for (var i = 0; i < pol.length; i++) {
      var mi = getSlope(pol, i, { denominatorThreshold: 0.001 });
      var mii = getSlope(pol, i + 1, { denominatorThreshold: 0.001 });
      // var len_i = getDistance(pol, i);
      var si = getOffsetPointSi(pol, i, h);
      var ri = getOffsetPointRi(pol, i, h);
      var sii = getOffsetPointSi(pol, i + 1, h);
      var rii = getOffsetPointRi(pol, i + 1, h);
      // if (!si || !ri || !sii || !rii){
      // 	continue;
      // }
      if (Math.abs(mii - mi) < 0.001) {
        offPol.push([]);
        continue;
      }
      if (mi != "nan" && mii != "nan") {
        var x = (mii * rii - mi * ri + si - sii) / (mii - mi);
        var y =
          (mi * mii * rii - mi * mii * ri + mii * si - mi * sii) / (mii - mi);
      } else if (mi == "nan" && mii == "nan") {
        // offPol.push(offPol[i-1]);
        offPol.push([]);
        continue;
      } else if (mi == "nan") {
        var x = ri;
        var y = sii - mii * rii + mii * ri;
      } else if (mii == "nan") {
        var x = rii;
        var y = si;
        var y = mi * rii - mi * ri + si;
      }
      if (!x || !y) {
        // console.log(mi, mii, si, sii, ri, rii);
      }
      offPol.push([x, y, pol[(i + 1) % pol.length][2]]);
    }

    offPol.unshift(offPol.pop());

    /*
        For collinear vertices offPol will have an empty array []
        The array is populated using projection on the line formed by newer neighbours below
         */

    let linkedList = new DLL.DoublyLinkedList();
    for (let i = 0; i < offPol.length; i++) {
      linkedList.append(offPol[i]);
    }

    for (let i = 0; i < linkedList.size(); i++) {
      let pt = linkedList.item(i)

      let currentData = pt.data;

      if (_.isEmpty(currentData)) {
        let previousItem = pt.prev,
          nextItem = pt.next;
        let previousData = previousItem.data,
          nextData = nextItem.data;
        currentData = pol[i];

        /*
                previousData and nextData won't be there when two consecutive collinear vertices are present
                in the faceVertices, because of which there'll be consecutive []s in offPol
                So next or previous point is used
                 */

        let loopCount = 0;
        let assignPolValue = false;
        while (_.isEmpty(previousData)) {
          loopCount++;
          if (loopCount >= offPol.length) {
            assignPolValue = true;
            break;
          }

          previousItem = previousItem.prev;
          previousData = previousItem.data;
        }

        loopCount = 0;
        while (_.isEmpty(nextData)) {
          loopCount++;
          if (loopCount >= offPol.length) {
            assignPolValue = true;
            break;
          }

          nextItem = nextItem.next;
          nextData = nextItem.data;
        }

        if (assignPolValue) {
          offPol[i] = pol[i];
          continue;
        }

        let previousV3 = BABYLON.Vector3.FromArray([
          previousData[0],
          previousData[2],
          previousData[1],
        ]);
        let nextV3 = BABYLON.Vector3.FromArray([
          nextData[0],
          nextData[2],
          nextData[1],
        ]);
        let currentV3 = BABYLON.Vector3.FromArray([
          currentData[0],
          currentData[2],
          currentData[1],
        ]);

        let projection = projectionOfPointOnLine(currentV3, previousV3, nextV3);

        if (projection) {
          if (
            new ResolveEngineUtils().onSegment3D(previousV3, projection, nextV3)
          ) {
            offPol[i] = [projection.x, projection.z, projection.y];
          } else {
            offPol[i] = pol[i];
          }
        } else {
          offPol[i] = pol[i];
        }
      }
    }

    return offPol;
  }

  let offPol = _getOffset(pol, h);

  /*
    Area is used to confirm if the offset direction is proper
    Problems occurred because of flip before when the direction of face vertices used to get reversed.
    That's fixed now, so mostly this extra check is not required
     */

  let areaBefore = calculateAreaOfIrregularPolygons(deepCopyObject(pol));
  let areaAfter = calculateAreaOfIrregularPolygons(deepCopyObject(offPol));

  let offsetValue = h;
  if (offsetValue < 0) {
    if (areaAfter > areaBefore) {
      offPol = _getOffset(pol, -offsetValue);
    }
  } else {
    if (areaAfter < areaBefore) {
      offPol = _getOffset(pol, -offsetValue);
    }
  }

  if (isNaN(areaAfter)) return pol;
  else return offPol;
}

function getSlope(p, i, { denominatorThreshold = 0.1 } = {}) {
  var n = p.length;
  var xs = p[(i + 1) % n][0] - p[i % n][0];
  var ys = p[(i + 1) % n][1] - p[i % n][1];
  if (Math.abs(xs) == 0) {
    return "nan";
  }
  if (Math.abs(xs) < denominatorThreshold) {
    return "nan";
  }
  if (ys === 0) return ys;

  return ys / xs;
}

function getOffsetPointSi(pol, i, h) {
  var n = pol.length;
  var len_i = getDistance(pol, i);
  var si =
    pol[(i + 1) % n][1] - (h * (pol[(i + 1) % n][0] - pol[i % n][0])) / len_i;
  if (!si) {
    // console.log(pol[(i+1)%n], pol[(i)%n], len_i);
  }
  return si;
}

function getOffsetPointRi(pol, i, h) {
  var n = pol.length;
  var len_i = getDistance(pol, i);
  var ri =
    pol[(i + 1) % n][0] + (h * (pol[(i + 1) % n][1] - pol[i % n][1])) / len_i;
  if (!ri) {
    // console.log(pol[(i+1)%n], pol[(i)%n], len_i);
  }
  return ri;
}

function getDistance(p, i) {
  var n = p.length;
  return Math.sqrt(
    (p[(i + 1) % n][0] - p[i % n][0]) ** 2 +
      (p[(i + 1) % n][1] - p[i % n][1]) ** 2
  );
}

function checkIfAreaCounts(obj_type) {
  var id = searchForId(room_types_db, obj_type);
  var obj_props = room_types_db[id];
  if (obj_props) {
    var obj_name = obj_props.name;
    var obj_height = obj_props.props.height * store.floor_height;
    var obj_far = obj_props.props.far;
    var obj_mat = obj_props.props.mat;
  } else {
    // var obj_name = store.room_types[i];
    var obj_name = store.room_types[0];
    var obj_height = store.floor_height;
    var obj_far = false;
    var obj_mat = "none";
  }
  return obj_far;
}

function getSlopeAngleAndDisWall(p1, p2) {
  var obj = {};
  var ys = p2[1] - p1[1];
  var xs = p2[0] - p2[0];
  obj.theta = Math.atan2(Math.abs(ys), Math.abs(xs));
  obj.distance = Math.sqrt(Math.pow(ys, 2) + Math.pow(xs, 2));
  return obj;
}

// Creating Rooms Starts
function createRoomsOld() {
  for (var i = 0; i < store.wall_pols[0].length; i++) {
    if (!store.room_curves[i]) {
      var id = searchForId(room_types_db, store.room_types[i]);
      var obj_props = room_types_db[id];
      if (obj_props) {
        var obj_name = obj_props.name;
        var obj_height = obj_props.props.height * store.floor_height;
        var obj_far = obj_props.props.far;
        var obj_mat = obj_props.props.mat;
      } else {
        var obj_name = store.room_types[i];
        var obj_height = store.floor_height;
        var obj_far = true;
        var obj_mat = "none";
      }

      let wall_pol1 = store.wall_pols[0];
      let wall_pol2 = store.wall_pols[1];
      var n1 = wall_pol1[i].length;
      var n2 = wall_pol2[i].length;
      var floorMesh = generateFloorForRoom(wall_pol2, i);
      var roofMesh = generateRoofForRoom(wall_pol2, i);
      roofMesh.position.y = obj_height * (store.room_levels[i] + 1);
      roofMesh.position.y = store.floor_height * (store.room_levels[i] + 1);

      for (var j = 0; j < wall_pol1[i].length / 2; j += 1) {
        var ribPol1 = generateWallBase(wall_pol1, wall_pol2, i, j, n1, n2);

        var ribbon1 = generateWallStruct(
          wall_pol1,
          wall_pol2,
          i,
          j,
          n1,
          n2,
          obj_height
        );

        var ribPol2 = generateWallBase(wall_pol1, wall_pol2, i, j, n1, n2);
        ribPol2.position.y += obj_height;

        var mergedMesh = createWallBlock(ribPol1, ribbon1, ribPol2, i, j);
        // mergedMesh.enableEdgesRendering();
        // mergedMesh.edgesWidth = 2.0;
        // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        mergedMesh.floor = floorMesh;
        mergedMesh.roof = floorMesh;
        checkWallOverlap(mergedMesh);
      }
    }
  }
}

// Creating Rooms Ends
function checkIntersection(mesh1, mesh2) {
  let bbinfo1 = mesh1.getBoundingInfo();
  let bbinfo2 = mesh2.getBoundingInfo();

  let bbinfo1_xMin = Math.round(bbinfo1.boundingBox.minimumWorld.x * 100) / 100;
  let bbinfo2_xMax = Math.round(bbinfo2.boundingBox.maximumWorld.x * 100) / 100;
  let bbinfo1_xMax = Math.round(bbinfo1.boundingBox.maximumWorld.x * 100) / 100;
  let bbinfo2_xMin = Math.round(bbinfo2.boundingBox.minimumWorld.x * 100) / 100;

  let bbinfo1_zMin = Math.round(bbinfo1.boundingBox.minimumWorld.z * 100) / 100;
  let bbinfo2_zMax = Math.round(bbinfo2.boundingBox.maximumWorld.z * 100) / 100;
  let bbinfo1_zMax = Math.round(bbinfo1.boundingBox.maximumWorld.z * 100) / 100;
  let bbinfo2_zMin = Math.round(bbinfo2.boundingBox.minimumWorld.z * 100) / 100;

  var orient1 = getMeshSlope(mesh1);
  var orient2 = getMeshSlope(mesh2);

  //for handling nan case
  if (typeof orient1 === "number" && typeof orient2 === "number") {
    orient1 = Math.round(orient1 * 10) / 10;
    orient2 = Math.round(orient2 * 10) / 10;
  }

  if (orient1 == orient2) {
    // var intersect  = BABYLON.BoundingBox.Intersects(bbinfo1.boundingBox, bbinfo2.boundingBox, true);
    var intersect = mesh1.intersectsMesh(mesh2);
    var custom_intersect = true;
    if (bbinfo1_xMin >= bbinfo2_xMax || bbinfo2_xMin >= bbinfo1_xMax)
      custom_intersect = false;
    if (bbinfo1_zMax <= bbinfo2_zMin || bbinfo2_zMax <= bbinfo1_zMin)
      custom_intersect = false;
    if (!isOverlapBtwMeshes(mesh1, mesh2)) custom_intersect = false;
    // if(bbinfo1.boundingBox.maximumWorld.y > bbinfo2.boundingBox.maximumWorld.y || bbinfo1.boundingBox.minimumWorld.y < bbinfo1.boundingBox.minimumWorld.y)
    //     custom_intersect = true;
    // if (bbinfo1.boundingBox.minimumWorld.x <= bbinfo2.boundingBox.minimumWorld.x &&
    //         bbinfo1.boundingBox.maximumWorld.x >= bbinfo2.boundingBox.maximumWorld.x &&
    //         bbinfo1.boundingBox.minimumWorld.y >= bbinfo2.boundingBox.minimumWorld.y &&
    //         bbinfo1.boundingBox.minimumWorld.z <= bbinfo2.boundingBox.minimumWorld.z &&
    //         bbinfo1.boundingBox.maximumWorld.z <= bbinfo2.boundingBox.maximumWorld.z) {
    //     return true;
    // }
    // if (bbinfo1.boundingBox.minimumWorld.x <= bbinfo2.boundingBox.minimumWorld.x &&
    //     bbinfo1.boundingBox.maximumWorld.x <= bbinfo2.boundingBox.maximumWorld.x &&
    //     bbinfo1.boundingBox.minimumWorld.y >= bbinfo2.boundingBox.minimumWorld.y &&
    //     bbinfo1.boundingBox.minimumWorld.z <= bbinfo2.boundingBox.minimumWorld.z &&
    //     bbinfo1.boundingBox.maximumWorld.z >= bbinfo2.boundingBox.maximumWorld.z) {
    //     return true;
    // }
    return intersect && custom_intersect;
  }
  return false;
}

function checkWallOverlap(node, meshCreatedFromExtention = null) {
  var mesh;
  var startNode;

  if (meshCreatedFromExtention) {
    mesh = meshCreatedFromExtention;
    startNode = store.doublyLinkedList.item(meshCreatedFromExtention).prev;
  } else {
    mesh = node.data;
    startNode = node.prev;
  }

  if (mesh.isTakenForChildIntersection) return;

  for (let wallCtr = 0; wallCtr < store.wallLevelArray.length; wallCtr++) {
    var curr_mesh = store.wallLevelArray[wallCtr];
    var collMeshes = [];

    if (curr_mesh.name === "wall" && curr_mesh.type === "wall") {
      // if (curr_mesh.position.equals(mesh.position)) {
      //   console.log(curr_mesh);
      // }
      // if (curr_mesh === mesh) continue;
      if (curr_mesh.uniqueId === mesh.uniqueId) {
        continue;
      }
      if (curr_mesh.isTakenAsChildIntersection) continue;

      if (curr_mesh.room_unique_id === mesh.room_unique_id) {
        continue;
      }
      if (
        JSON.stringify(curr_mesh.original_cords) ===
        JSON.stringify(mesh.original_cords)
      )
        continue; //console.log(mesh.top_cords, wallCtr);

      // if (curr_mesh.level !== mesh.level) continue;
      //if (JSON.stringify(curr_mesh.original_cords) === JSON.stringify(mesh.original_cords)) console.log(i);
      var intersection = checkIntersection(mesh, curr_mesh);
      // var intersection = mesh.intersectsMesh(curr_mesh, false);
      if (intersection) {
        // curr_mesh.material = store.scene.getMaterialByName("floor_tile");
        // mesh.material = store.scene.getMaterialByName("wall_mat4");
        collMeshes.push(mesh);
        collMeshes.push(curr_mesh);
        mesh.isTakenAsChildIntersection = true;
        curr_mesh.isTakenAsChildIntersection = true;
        store.wallLevelArray.removeMesh(mesh);
        store.wallLevelArray.removeMesh(curr_mesh);
        collMeshes = findChildIntesection(
          store.wallLevelArray.length - 1,
          collMeshes
        );
      }
      if (collMeshes.length > 1) {
        //checkForWallSizes(collMeshes);
        if (collMeshes.length > 1) {
          resolveIntersectionCSG(collMeshes);

          // let resolveIntersectionObj = resolveIntersectionWalls(collMeshes, store.doublyLinkedList);
          // let collMeshesForDeletion = resolveIntersectionObj.collMesh;
          // let mergedMeshes = resolveIntersectionObj.mergedMesh;

          // if(startNode)
          //     pushInWallLinkedList(0, startNode, mergedMeshes);
          // else
          //     pushInWallLinkedList(0, node, mergedMeshes);

          // for (let i = 0; i < collMeshesForDeletion.length; i++) {
          //     let mesh = collMeshesForDeletion[i];
          //     store.doublyLinkedList.remove(mesh);
          //     mesh.dispose();
          // }
        }
      }
    }
  }
}
//

function meshStartingPoint(collisionMesh) {
  let all_coords = [];
  let top_coords = [];

  for (let i = 0; i < collisionMesh.length; i++) {
    Array.prototype.push.apply(all_coords, collisionMesh[i].original_cords);
    Array.prototype.push.apply(top_coords, collisionMesh[i].top_cords);
  }

  // if (len <= 1) throw "collMesh length is less than 1";
  //var all_coords= mesh1.original_cords.concat(mesh2.original_cords);

  var x_max = -9999,
    x_min = 99999,
    y_max = -99999,
    y_min = 99999;

  all_coords.forEach(function (point) {
    if (x_min >= point[0]) {
      x_min = point[0];
    }
    if (y_min >= point[1]) {
      y_min = point[1];
    }
    if (x_max <= point[0]) {
      x_max = point[0];
    }
    if (y_max <= point[1]) {
      y_max = point[1];
    }
  });

  //sort in the overlop direction
  if (x_max - x_min >= y_max - y_min) {
    collisionMesh.sort(function (a, b) {
      return a.x_min - b.x_min;
    });
  } else {
    collisionMesh.sort(function (a, b) {
      return a.y_min - b.y_min;
    });
  }
}

function isPParent(mesh1, mesh2) {
  if (mesh2.x_max - mesh2.x_min > mesh2.y_max - mesh2.y_min) {
    if (mesh2.x_min >= mesh1.x_min && mesh2.x_max <= mesh1.x_max) return true;
    return false;
  } else {
    if (mesh2.y_min >= mesh1.y_min && mesh2.y_max <= mesh1.y_max) return true;
    return false;
  }
}

function resolveIntersectionCSG(collMeshes) {
  collMeshes.forEach(function (mesh) {
    var extremes = findMeshExtremePoints(mesh);
    mesh.x_min = Math.round(extremes.xMin * 100) / 100;
    mesh.y_min = Math.round(extremes.YMin * 100) / 100;
    mesh.x_max = Math.round(extremes.xMax * 100) / 100;
    mesh.y_max = Math.round(extremes.YMax * 100) / 100;
    mesh.z_min = Math.round(extremes.zMin * 100) / 100;
    mesh.z_max = Math.round(extremes.zMax * 100) / 100;
  });

  meshStartingPoint(collMeshes);

  for (
    let i = 0, j = 1, ctr = 0;
    ctr < collMeshes.length * 2 - 1;
    ctr++, i++, j++
  ) {
    j = j % collMeshes.length;
    i = i % collMeshes.length;

    var aCSG = getCSGFormOfMesh(collMeshes[i]);
    var bCSG = getCSGFormOfMesh(collMeshes[j]);
    var subCSG;

    if (isPParent(collMeshes[i], collMeshes[j])) {
      subCSG = aCSG.union(bCSG);
      collMeshes[j].dispose();
      collMeshes.splice(j, 1);
    } else {
      if (i === 0) {
        subCSG = aCSG.subtract(bCSG);
      } else if (i % 2 !== 0) {
        subCSG = aCSG.intersect(bCSG);
      } else {
        subCSG = bCSG.subtract(aCSG);
      }
    }

    // console.log(subCSG);
    // if ((selectionStack[0].name.indexOf("room") <= 2) && (selectionStack[0].name.indexOf("room") !== -1)) {

    // }
    var mergedMesh = subCSG.toMesh("wall", null, store.newScene);
    //console.log(mergedMesh);

    // var mergedMesh = BABYLON.Mesh.MergeMeshes(collMeshes, true, true);
    var mesh1 = collMeshes[0];
    mergedMesh.material = store.scene.getMaterialByName("wall_mat");
    mergedMesh.checkCollisions = true;
    mergedMesh.name = "wall";
    mergedMesh.checkCollisions = true;

    mergedMesh.room_name = mesh1.room_name;
    mergedMesh.room_type = mesh1.room_type;
    mergedMesh.room_id = mesh1.room_id;
    mergedMesh.room_curve = mesh1.room_curve;
    mergedMesh.room_path = mesh1.room_path;

    // mergedMesh.position = mesh1.position;
    // mergedMesh.rotation = mesh1.rotation;
    // mergedMesh.scaling = mesh1.scaling;

    // var bbinfo = mergedMesh.getBoundingInfo();
    // var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    // mergedMesh.setPivotPoint(centroid);

    assignProperties(mergedMesh, -1, "wall");
    mergedMesh._properties._thickness = collMeshes[0]._properties._thickness; //#TODO change it to include mesh with max thickness
    mergedMesh._properties._height = 12;
    mergedMesh.checkCollisions = true;
    mergedMesh.name = "wall";
    mergedMesh.type = "wall";
    mergedMesh.checkCollisions = true;
    mergedMesh.original_cords = [];
    mergedMesh.top_cords = [];

    mergedMesh.convertToFlatShadedMesh();

    mergedMesh.room_name = collMeshes[0].room_name;
    mergedMesh.room_type = collMeshes[0].room_type;
    mergedMesh.room_id = collMeshes[0].room_id;
    //mergedMesh.room_curve = mesh1.room_curve;
    //mergedMesh.room_path = mesh1.room_path;
    // mergedMesh.level = collMeshes[0].level;
    store.doublyLinkedList.append(mergedMesh);
    // mergedMesh.floor = floorRoofObj.floor;
    // mergedMesh.roof =  floorRoofObj.roof;

    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    click(mergedMesh);
  }

  collMeshes.forEach(function (mesh) {
    mesh.dispose();
  });
  // maxMesh.dispose();
  // return mergedMesh;
}

function checkForWallSizes(collMeshes) {
  for (var i = 0; i < collMeshes.length - 1; ) {
    var status = isPartOfBBInfo(collMeshes[i], collMeshes[i + 1]);
    if (status) {
      // collMeshes[i+1].dispose();
      var tempMesh = collMeshes.splice(i + (status - 1), 1);
      // console.log(tempMesh);
      store.doublyLinkedList.remove(tempMesh[0]);
      tempMesh[0].dispose();
    } else i++;
  }

  // var max = 1e-5;
  // var min = 1e5;
  // var maxMesh;
  // var minMesh;
  // for (var i = 0; i < collMeshes.length; i++) {
  //     var bbinfo = collMeshes[i].getBoundingInfo();
  //     if (bbinfo.boundingBox.maximumWorld.x > max) {
  //         max = bbinfo.boundingBox.maximumWorld;
  //         maxMesh = collMeshes[i];
  //     }
  //     if (bbinfo.boundingBox.minimumWorld.x < min) {
  //         min = bbinfo.boundingBox.minimumWorld;
  //         minMesh = collMeshes[i];
  //     }
  // }
  // for (var i = 0; i < collMeshes.length; i++) {
  //     var bbinfo = collMeshes[i].getBoundingInfo();
  //     if (bbinfo.boundingBox.maximumWorld.y < max.y) {
  //         collMeshes.splice(i, 1);
  //     }
  //     if (bbinfo.boundingBox.minimumWorld.y > min.y) {
  //         collMeshes.splice(i, 1);
  //     }
  //     if ((bbinfo.boundingBox.maximumWorld.x <= max.x) && (bbinfo.boundingBox.maximumWorld.y <= max.y) && (bbinfo.boundingBox.maximumWorld.x < max.z)) {
  //         if ((bbinfo.boundingBox.minimumWorld.x >= min.x) && (bbinfo.boundingBox.minimumWorld.y >= min.y) && (bbinfo.boundingBox.minimumWorld.x >= min.z)) {
  //             if ((collMeshes[i] !== maxMesh) && (collMeshes[i] !== minMesh))
  //                 collMeshes[i].dispose();
  //         }
  //     }
  // }
}

function createPartitionWalls(partitionObj) {
  var obj_height = 12;
  return;
  var mesh1 = store.doublyLinkedList.head().data;

  for (let j in partitionObj) {
    var wall_partition = partitionObj[j];
    var wall_path = makeWallPath([
      wall_partition[0],
      wall_partition[1],
      wall_partition[2],
      wall_partition[3],
    ]);
    try {
      var ribPol1 = generateWallBase(
        wall_path[0],
        wall_path[1],
        wall_path[2],
        wall_path[3]
      );
      var ribbon1 = generateWallStructIndInter(
        wall_path[0],
        wall_path[1],
        wall_path[2],
        wall_path[3],
        wall_path[0],
        wall_path[1],
        wall_path[2],
        wall_path[3],
        obj_height
      );
      var ribPol2 = generateWallBase(
        wall_path[0],
        wall_path[1],
        wall_path[2],
        wall_path[3]
      );
      ribPol2.position.y += obj_height;
      let mergedMesh = createWallBlock2(
        ribPol1,
        ribbon1,
        ribPol2,
        mesh1,
        mesh1,
        obj_height
      );
    } catch (e) {
      console.error("Wall Ribbon creation failed ! Skipping Wall", e);
      continue;
    }
  }
}

function resolveIntersectionWalls(collMeshes, wallLinkedList) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;

  var all_coords = [];
  var len = collMeshes.length;
  var top_coords = [];
  var indexArr = [];
  var diag_diff = 0;
  var mesh1 = collMeshes[0];
  var mesh2 = collMeshes[1];
  var mergedMeshArr = [];
  var placementThreshold = 2;

  for (let i = 0; i < len; i++) {
    // let diag_diff = findDiagonalLength(collMeshes[i].original_cords) - findDiagonalLength(collMeshes[(i+1)%len].original_cords);
    // console.log("diag_diff :" + diag_diff);
    // console.log(collMeshes[i].getBoundingInfo().diagonalLength ,collMeshes[(i+1)%len].getBoundingInfo().diagonalLength);
    // console.log(collMeshes[i].getBoundingInfo().diagonalLength - collMeshes[(i+1)%len].getBoundingInfo().diagonalLength);
    // console.log(collMeshes[i].getBoundingInfo().boundingBox ,collMeshes[(i+1)%len].getBoundingInfo().boundingBox);
    //  if(i<len-1 && (Math.abs(diag_diff) < overlap_threshold) && isOverlapBtwMeshes(collMeshes[i], collMeshes[i+1])){
    //      console.log("true for mesh1 " + collMeshes[i].getBoundingInfo().diagonalLength + " and " + collMeshes[(i+1)%len].getBoundingInfo().diagonalLength);
    //      consumeFlag = true;
    //      if(diag_diff > 0){
    //          Array.prototype.push.apply(all_coords, collMeshes[++i].original_cords); //push child and pre-increment i as 2 items has been pushed
    //          Array.prototype.push.apply(top_coords, collMeshes[i].top_cords);
    //
    //      }
    //      else{
    //          Array.prototype.push.apply(top_coords, collMeshes[i].top_cords);
    //          Array.prototype.push.apply(all_coords, collMeshes[i++].original_cords); //push child and post-increment i as 2 items has been pushed
    //      }
    //  }
    // else{
    Array.prototype.push.apply(all_coords, collMeshes[i].original_cords);
    Array.prototype.push.apply(top_coords, collMeshes[i].top_cords);
    // }
  }
  if (len <= 1) throw "collMesh length is less than 1";
  //var all_coords= mesh1.original_cords.concat(mesh2.original_cords);

  var x_max = -9999,
    x_min = 99999,
    y_max = -99999,
    y_min = 99999;

  all_coords.forEach(function (point) {
    if (x_min >= point[0]) {
      x_min = point[0];
    }
    if (y_min >= point[1]) {
      y_min = point[1];
    }
    if (x_max <= point[0]) {
      x_max = point[0];
    }
    if (y_max <= point[1]) {
      y_max = point[1];
    }
  });

  //sort in the overlop direction
  if (x_max - x_min >= y_max - y_min) {
    all_coords.sort(function (a, b) {
      return a[0] - b[0];
    });
    top_coords.sort(function (a, b) {
      return a[0] - b[0];
    });
  } else {
    all_coords.sort(function (a, b) {
      return a[1] - b[1];
    });
    top_coords.sort(function (a, b) {
      return a[1] - b[1];
    });
  }

  // if(all_coords[0])

  //roundoff the points
  for (var i = 0; i < all_coords.length - 1; i++) {
    for (var j = i + 1; j < all_coords.length; ) {
      if (
        Math.abs(all_coords[i][0] - all_coords[j][0]) <= placementThreshold &&
        Math.abs(all_coords[i][1] - all_coords[j][1]) <= placementThreshold
      ) {
        top_coords.splice(j, 1);
        all_coords.splice(j, 1);
      } else j++;
    }
  }
  //console.log("Area of triangle :" , calculateAreaOfTriangle(all_coords[0], all_coords[1], all_coords[2]));

  /* for remove below patterns
        ------                      -----|
        | /                            / |
        |/                  &&        /  |
        _____                      -------
    */
  if (
    calculateAreaOfTriangle(all_coords[0], all_coords[1], all_coords[2]) < 25
  ) {
    let point1 = all_coords[0][0] + all_coords[0][1];
    let point2 = all_coords[1][0] + all_coords[1][1];
    let point3 = all_coords[2][0] + all_coords[2][1];

    if (point1 + point2 > point2 + point3) {
      if (Math.abs(point1 - point2) < Math.abs(point1 - point3)) {
        top_coords.splice(3, 1);
        all_coords.splice(3, 1);
      } else {
        top_coords.splice(2, 1);
        all_coords.splice(2, 1);
      }
    } else {
      if (Math.abs(point1 - point3) < Math.abs(point2 - point3)) {
        top_coords.splice(2, 1);
        all_coords.splice(2, 1);
      } else {
        top_coords.splice(1, 1);
        all_coords.splice(1, 1);
      }
    }
  }
  if (
    calculateAreaOfTriangle(
      all_coords[all_coords.length - 1],
      all_coords[all_coords.length - 2],
      all_coords[all_coords.length - 3]
    ) < 15
  ) {
    let point1 =
      all_coords[all_coords.length - 1][0] +
      all_coords[all_coords.length - 1][1];
    let point2 =
      all_coords[all_coords.length - 2][0] +
      all_coords[all_coords.length - 2][1];
    let point3 =
      all_coords[all_coords.length - 3][0] +
      all_coords[all_coords.length - 3][1];

    if (point1 + point2 > point2 + point3) {
      if (Math.abs(point1 - point2) < Math.abs(point1 - point3)) {
        top_coords.splice(all_coords.length - 3, 1);
        all_coords.splice(all_coords.length - 3, 1);
      } else {
        top_coords.splice(all_coords.length - 2, 1);
        all_coords.splice(all_coords.length - 2, 1);
      }
    } else {
      if (Math.abs(point1 - point3) < Math.abs(point2 - point3)) {
        top_coords.splice(all_coords.length - 2, 1);
        all_coords.splice(all_coords.length - 2, 1);
      } else {
        top_coords.splice(all_coords.length - 1, 1);
        all_coords.splice(all_coords.length - 1, 1);
      }
    }
  }

  /*
    TODO: Check coordinate similarity between original coord and top coord before splicing simultaneously - for all meshes
    if length of top coord and original coord are different then its an incline wall!!
     */

  //for inclined wall mesh
  // if(true || calculateEuclidianDistance(all_coords[0], top_coords[0]) > 25 || calculateEuclidianDistance(all_coords[all_coords.length-1], top_coords[top_coords.length-1]) > 25){
  //     let min_threshold = 2 //(x_max - x_min) < (y_max - y_min)?((y_max - y_min)/all_coords.length* store.unit_scale):((x_max - x_min)/all_coords.length * store.unit_scale);
  //     let max_threshold = min_threshold + 5;
  //     for(let lo=0;lo<all_coords.length-2; ){
  //         if(lo>0 && (all_coords[lo][0] - all_coords[lo-1][0]) > min_threshold) { lo+=2; continue;}
  //         if((x_max - x_min) >= (y_max - y_min)){
  //             if(all_coords[lo+1][0] - all_coords[lo][0] > min_threshold && (((all_coords[lo+1][0] - all_coords[lo][0]) < max_threshold) ) ){
  //                 all_coords.splice(lo,1);
  //                 top_coords.splice(lo,1);
  //             }
  //             else if(lo>0 && ((all_coords[lo][0] - all_coords[lo-1][0]) > min_threshold && (all_coords[lo][0] - all_coords[lo-1][0]) < max_threshold)){
  //                 all_coords.splice(lo,1);
  //                 top_coords.splice(lo,1);
  //             }
  //             else
  //                 lo+=2;
  //         }
  //         else{
  //              if(all_coords[lo+1][1] - all_coords[lo][1] > min_threshold && (((all_coords[lo+1][1] - all_coords[lo][1]) < max_threshold))){
  //                 all_coords.splice(lo,1);
  //                 top_coords.splice(lo,1);
  //              }
  //             else if(lo>0 && ((all_coords[lo][0] - all_coords[lo-1][0]) > min_threshold && (all_coords[lo][0] - all_coords[lo-1][0]) < max_threshold)){
  //                 all_coords.splice(lo,1);
  //                 top_coords.splice(lo,1);
  //             }
  //              else
  //                 lo+=2;
  //         }
  //     }
  // }

  //extend the walls to the end.

  for (let j = 0; j < all_coords.length - 3; j += 2) {
    let horizontal_len =
      (store.a = calculateEuclidianDistance(
        all_coords[j + 1],
        all_coords[j + 2]
      )) <
      (store.b = calculateEuclidianDistance(
        all_coords[j + 1],
        all_coords[j + 3]
      ))
        ? store.a
        : store.b;
    let vertical_len = calculateEuclidianDistance(
      all_coords[j],
      all_coords[j + 1]
    );

    //console.log(horizontal_len, vertical_len, collMeshes[0]._properties._thickness);
    if (
      Math.abs(horizontal_len - vertical_len) < 2 ||
      horizontal_len <=
        collMeshes[0]._properties._thickness *
          collMeshes[0]._properties._thickness
    ) {
      console.log("**Inside it***");
      if (
        j === 0 ||
        horizontal_len + collMeshes[0]._properties._thickness / 100 <
          collMeshes[0]._properties._thickness *
            collMeshes[0]._properties._thickness
      ) {
        //console.log(horizontal_len, vertical_len, collMeshes[0]._properties._thickness);
        // if(Math.abs(horizontal_len - vertical_len ) < 2 || Math.sqrt(horizontal_len) <= collMeshes[0]._properties._thickness){
        if (
          Math.abs(
            collMeshes[0]._properties._thickness *
              collMeshes[0]._properties._thickness -
              vertical_len
          ) > 1
        ) {
          all_coords.splice(j, 2);
          top_coords.splice(j, 2);
        } else {
          all_coords.splice(j + 2, 2);
          top_coords.splice(j + 2, 2);
        }
      } else {
        all_coords.splice(j, 2);
        top_coords.splice(j, 2);
      }
    }
  }

  let collMeshRange = getCollMeshRange(collMeshes);
  if (all_coords.length % 2 !== 0) {
    console.error("Not of even length");
    all_coords.pop();
    top_coords.pop();
  }

  for (var i = 0, j = 0; i < (2 * all_coords.length - 4) / 4; i++, j += 2) {
    //var obj_height =  collMeshes[0]._properties._height;

    var wall_path = makeWallPath([
      all_coords[j],
      all_coords[j + 1],
      all_coords[j + 2],
      all_coords[j + 3],
    ]);
    var wall_path_top = []; //makeWallPath([top_coords[j], top_coords[j+1], top_coords[j+2], top_coords[j+3]]);
    // console.log(wall_path_top[0][2]*unit_scale);
    // var obj_height = store.angular.copy(wall_path_top[0][2])*unit_scale;
    try {
      wall_path.forEach(function (item) {
        let max_height = top_coords.find(function (point) {
          return item[0] === point[0] && item[1] === point[1];
        });
        wall_path_top.push([item[0], item[1], max_height[2]]);
      });
    } catch (e) {
      wall_path_top = makeWallPath([
        top_coords[j],
        top_coords[j + 1],
        top_coords[j + 2],
        top_coords[j + 3],
      ]);
    }

    let obj_height = assignHeightForNewMesh(wall_path, collMeshRange);
    var floorRoofObj = assignFloorAndRoofForNewMesh(wall_path, collMeshRange);

    //console.log("obj_height : " + obj_height);
    //console.log("floor array :", floorRoofObj.floor);
    //console.log("roof array :", floorRoofObj.roof);
    var mergedMesh = 0;
    try {
      /*
            TODO: Use the correct obj_height based on height priority resolution.
             */
      var ribPol1 = generateWallBase(
        wall_path[0],
        wall_path[1],
        wall_path[2],
        wall_path[3]
      );
      var ribbon1 = generateWallStructIndInter(
        wall_path[0],
        wall_path[1],
        wall_path[2],
        wall_path[3],
        wall_path_top[0],
        wall_path_top[1],
        wall_path_top[2],
        wall_path_top[3],
        obj_height
      );
      var ribPol2 = generateWallBase(
        wall_path_top[0],
        wall_path_top[1],
        wall_path_top[2],
        wall_path_top[3]
      );
      ribPol2.position.y += obj_height;
      mergedMesh = createWallBlock2(
        ribPol1,
        ribbon1,
        ribPol2,
        mesh1,
        mesh2,
        obj_height
      );
    } catch (e) {
      console.error("Wall Ribbon creation failed ! Skipping Wall", e);
      continue;
    }

    mergedMesh._properties._thickness = collMeshes[0]._properties._thickness; //#TODO change it to include mesh with max thickness
    mergedMesh._properties._height = obj_height;
    mergedMesh.checkCollisions = true;
    mergedMesh.name = "wall";
    mergedMesh.type = "wall";
    mergedMesh.checkCollisions = true;

    mergedMesh.convertToFlatShadedMesh();

    mergedMesh.room_name = mesh1.room_name;
    mergedMesh.room_type = mesh1.room_type;
    mergedMesh.room_id = mesh1.room_id;
    //mergedMesh.room_curve = mesh1.room_curve;
    //mergedMesh.room_path = mesh1.room_path;
    mergedMesh.level = mesh1.level;
    mergedMesh.floor = floorRoofObj.floor;
    mergedMesh.roof = floorRoofObj.roof;

    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    mergedMesh.original_cords = [];
    mergedMesh.original_cords.push(wall_path[0]);
    mergedMesh.original_cords.push(wall_path[1]);
    mergedMesh.original_cords.push(wall_path[2]);
    mergedMesh.original_cords.push(wall_path[3]);

    mergedMesh.top_cords = [];
    mergedMesh.top_cords.push(wall_path_top[0]);
    mergedMesh.top_cords.push(wall_path_top[1]);
    mergedMesh.top_cords.push(wall_path_top[2]);
    mergedMesh.top_cords.push(wall_path_top[3]);
    //click(mergedMesh);

    delete floorRoofObj.roof;
    delete floorRoofObj.floor;

    let checkAddToList = checkForParentMesh(
      collMeshes.length - 1,
      mergedMesh,
      collMeshes,
      indexArr
    );

    if (!checkAddToList) {
      mergedMeshArr.push(mergedMesh);
    }
  }

  if (indexArr.length > 0) {
    indexArr = removeDuplicates(indexArr);
    // console.log("******COllArr Before*******");
    // console.log(collMeshes);

    collMeshes = collMeshes.filter(function (item, index) {
      return !indexArr.includes(index);
    });

    // console.log("******COllArr After*******");
    // console.log(collMeshes);
  }
  //adjustMeshCoordsIntersection(mergedMesh, coords1, coords2);
  return { collMesh: collMeshes, mergedMesh: mergedMeshArr };
}

function resolveIntersectionWalls1(mesh1, mesh2) {
  // var orient = checkIfCollinear(mesh1, mesh2);
  var builtPol1 = getBaseVertices(mesh1);
  var builtPol2 = getBaseVertices(mesh2);
  // console.log(builtPol1, builtPol2);
  var or = store.getWallOrientation(mesh1);
  if (or == "x") {
    var coords = combineWallCoordsY(builtPol1, builtPol2);
  }
  if (or == "y") {
    var coords = combineWallCoordsX(builtPol1, builtPol2);
  }
  generateWall(coords, mesh1, mesh2);
}

function generateWall(coords, mesh1, mesh2) {
  var id = searchForId(room_types_db, mesh1.room_type);
  var obj_props = room_types_db[id];
  if (obj_props) {
    var obj_height = obj_props.props.height * store.floor_height;
  } else {
    var obj_height = store.floor_height;
  }

  var ribPol1 = generateWallBase2(coords);

  var ribbon1 = generateWallStruct2(coords, obj_height);

  var ribPol2 = generateWallBase2(coords);
  ribPol2.position.y += obj_height;

  var mergedMesh = createWallBlock2(
    ribPol1,
    ribbon1,
    ribPol2,
    mesh1,
    mesh2,
    obj_height
  );
  mergedMesh.floor = mesh1.floor;
  // mesh1.dispose();
  // mesh2.dispose();
  mergedMesh.dispose();
}

function combineWallCoordsY(pol1, pol2) {
  var pol3 = [];
  var pol4 = [];
  var pol = [];
  for (var i = 0; i < pol1.length; i++) {
    pol3.push(pol1[i][0]);
    pol4.push(pol2[i][0]);
    pol.push(pol1[i][0]);
    pol.push(pol2[i][0]);
  }
  pol.sort(function (a, b) {
    return a - b;
  });
  var pol2 = [];
  pol2.push(pol[0]);
  for (var i = 1; i < pol.length; i++) {
    if (pol[i] !== pol[i - 1]) {
      pol2.push(pol[i]);
      break;
    }
  }
  for (var i = 2; i < pol.length; i++) {
    if (pol[pol.length - i] !== pol[pol.length - i + 1]) {
      pol2.push(pol[pol.length - i]);
      break;
    }
  }
  pol2.push(pol[pol.length - 1]);
  var pol5 = [];
  for (var i = 0; i < pol1.length; i++) {
    pol5.push([pol2[i], pol1[i][1]]);
  }
  return pol5;
}

function combineWallCoordsX(pol1, pol2) {
  var pol3 = [];
  var pol4 = [];
  var pol = [];
  for (var i = 0; i < pol1.length; i++) {
    pol3.push(pol1[i][1]);
    pol4.push(pol2[i][1]);
    pol.push(pol1[i][1]);
    pol.push(pol2[i][1]);
  }
  // pol = pol.uniq([].join);
  pol.sort(function (a, b) {
    return a - b;
  });
  var pol2 = [];
  pol2.push(pol[0]);
  for (var i = 1; i < pol.length; i++) {
    if (pol[i] !== pol[i - 1]) {
      pol2.push(pol[i]);
      break;
    }
  }
  for (var i = 2; i < pol.length; i++) {
    if (pol[pol.length - i] !== pol[pol.length - i + 1]) {
      pol2.push(pol[pol.length - i]);
      break;
    }
  }
  pol2.push(pol[pol.length - 1]);
  var pol5 = [];
  for (var i = 0; i < pol1.length; i++) {
    pol5.push([pol1[i][0], pol2[i]]);
  }
  return pol5;
}

function generateFloorForRoomOld(wall_pol2, coords1, coords2, i) {
  var shape_temp1 = [];
  for (var j = 0; j < wall_pol2[i].length; j++) {
    var point = new BABYLON.Vector3(
      wall_pol2[i][j][0] * store.unit_scale,
      -wall_pol2[i][j][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
  }
  var ribPol1 = new BABYLON.PolygonMeshBuilder(
    "RibPol",
    shape_temp1,
    store.scene
  ).build(true);
  ribPol1.position.y = 0.01;
  ribPol1.name = "Floor";
  ribPol1.material = store.scene.getMaterialByName("floor_tile");
  return ribPol1;
}

function generateFloorForRoom(room_pols, coords1, coords2, mesh, i) {
  // console.log(mesh.uniqueId);
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  var shape_temp1 = [];
  var shape_temp2 = [];
  var path = [];
  var path1 = [];
  var path2 = [];
  var obj_height = 10 * store.unit_scale;
  for (var j = 0; j < room_pols[i].length; j++) {
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      -room_pols[i][j][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      -room_pols[i][j][1] * store.unit_scale,
      obj_height
    );
    point = snapVertData(point);
    shape_temp2.push(point);
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      0.0,
      -room_pols[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      -obj_height,
      -room_pols[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
  }
  var ribPol1 = new BABYLON.PolygonMeshBuilder(
    "RibPol",
    shape_temp1,
    store.scene
  ).build(true);
  // adjustCoords(ribPol1, coords1);
  var ribPol2 = new BABYLON.PolygonMeshBuilder(
    "RibPol",
    shape_temp2,
    store.scene
  ).build(true);
  ribPol2.position.y = -obj_height;
  // adjustCoords(ribPol1, coords2);
  var ribbon = BABYLON.Mesh.CreateRibbon(
    "ribbon",
    [path1, path2],
    false,
    false,
    0,
    store.scene,
    true,
    BABYLON.DOUBLESIDE
  );

  var meshVert1 = ribPol1.getTotalVertices();
  var meshInd1 = ribPol1.getIndices().length;

  var meshVert2 = ribbon.getTotalVertices();
  var meshInd2 = ribbon.getIndices().length;
  // splitFaces(ribbon1);

  var meshVert3 = ribPol2.getTotalVertices();
  var meshInd3 = ribPol2.getIndices().length;

  var mergedMesh = BABYLON.Mesh.MergeMeshes(
    [ribPol1, ribPol2, ribbon],
    true,
    true
  );
  mergedMesh.checkCollisions = true;
  mergedMesh.name = "Floor";
  mergedMesh.type = "Floor";
  mergedMesh.checkCollisions = true;
  mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  mergedMesh.level = mesh.level;
  mergedMesh.convertToFlatShadedMesh();
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  //mergedMesh.position.x = mesh.position.x;
  if (mesh.level)
    mergedMesh.position.y +=
      $scope.selectedItemLevelHeight[mesh.level + 1] / store.inch_to_mtr;
  mergedMesh.position.y += 0.1;
  //mergedMesh.position.z = mesh.position.z;
  //mergedMesh.rotation = mesh.rotation;
  //mergedMesh.scaling = mesh.scaling;
  // mergedMesh.position.y = obj_height * (room_levels[i]+1) + 0.5 * obj_height;
  // console.log(mesh.rotation);

  mergedMesh.id = makeid(5);
  mergedMesh.visibility = 1.0;
  // mergedMesh.subMeshes = [];

  // var verticesCount = mergedMesh.getTotalVertices();

  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.pop();
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.pop();
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(2, 0, verticesCount, meshInd1 + meshInd2, meshInd3, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.pop();

  // var multimat = new BABYLON.MultiMaterial("floor_multimat", store.scene);
  // multimat.subMaterials.push(scene.getMaterialByName("floor_tile"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));

  // mergedMesh.material = multimat;
  mergedMesh.material = store.scene.getMaterialByName("floor_tile");
  mergedMesh.material.backFaceCulling = false;
  setUVScale(
    mergedMesh,
    bbinfo.boundingBox.extendSizeWorld.x / 5,
    bbinfo.boundingBox.extendSizeWorld.z / 5
  );
  // adjustRoofCoords(mergedMesh, coords2, coords1, obj_height);
  assignProperties(mergedMesh, -1, "floor");
  return mergedMesh;
}

function generateRoofForRoom(
  room_pols,
  coords1,
  coords2,
  mesh,
  i,
  roof_depth,
  roof_height
) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  // console.log("inside roof");
  var shape_temp1 = [];
  var shape_temp2 = [];
  var path = [];
  var path1 = [];
  var path2 = [];
  var obj_height = roof_depth * store.unit_scale;
  for (var j = 0; j < room_pols[i].length; j++) {
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      -room_pols[i][j][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      -room_pols[i][j][1] * store.unit_scale,
      obj_height
    );
    point = snapVertData(point);
    shape_temp2.push(point);
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      0.0,
      -room_pols[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      room_pols[i][j][0] * store.unit_scale,
      obj_height,
      -room_pols[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
  }
  shape_temp1.push(shape_temp1[0]);
  shape_temp2.push(shape_temp2[0]);
  path1.push(path1[0]);
  path2.push(path2[0]);
  var ribPol1 = new BABYLON.PolygonMeshBuilder(
    "RibPol",
    shape_temp1,
    store.scene
  ).build(true);
  // ribPol1.position.y = -2*obj_height;
  // adjustCoords(ribPol1, coords1);
  var ribPol2 = new BABYLON.PolygonMeshBuilder(
    "RibPol",
    shape_temp2,
    store.scene
  ).build(true);
  ribPol2.position.y = obj_height;
  // adjustCoords(ribPol1, coords2);
  var ribbon = BABYLON.Mesh.CreateRibbon(
    "ribbon",
    [path1, path2],
    false,
    false,
    0,
    store.scene,
    true,
    BABYLON.DOUBLESIDE
  );
  var mergedMesh = BABYLON.Mesh.MergeMeshes(
    [ribPol1, ribPol2, ribbon],
    true,
    true
  );
  mergedMesh.checkCollisions = true;
  mergedMesh.name = "Roof";
  mergedMesh.type = "Roof";
  mergedMesh.checkCollisions = true;
  mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  mergedMesh.level = mesh.level;
  mergedMesh.convertToFlatShadedMesh();

  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.position.x = mesh.position.x;
  if (mesh.level)
    mergedMesh.position.y +=
      $scope.selectedItemLevelHeight[mesh.level + 1] / store.inch_to_mtr;
  // if (mesh.level === 0){
  //     mergedMesh.position.y += $scope.selectedItemLevelHeight[mesh.level + 1] / store.inch_to_mtr;
  //     console.log(mergedMesh.position.y);
  // }
  // mergedMesh.position.z = mesh.position.z;
  //mergedMesh.rotation = mesh.rotation;
  //mergedMesh.scaling = mesh.scaling;

  // mergedMesh.position.y = obj_height * (room_levels[i]+1) + 0.5 * obj_height;

  mergedMesh.visibility = 1.0;
  mergedMesh.material = store.scene.getMaterialByName("ceiling_mat");
  mergedMesh.material.backFaceCulling = false;
  // mergedMesh.position.y = mesh._properties._height * 10;
  // adjustRoofCoords(mergedMesh, coords1, coords2, obj_height);
  assignProperties(mergedMesh, -1, "roof");
  return mergedMesh;
}

function adjustRoofCoords(mesh, coords1, coords2, obj_height) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var bbinfo = mesh.getBoundingInfo();
  var midY = (bbinfo.maximum.y - bbinfo.minimum.y) / 2;
  for (var i = 0; i < verData.length; i += 3) {
    if (verData[i + 1] <= midY) {
      var temp = adjustMeshCoord(verData, i, coords2);
      if (temp) {
        verData[i + 1] = temp - obj_height;
      }
    } else {
      var temp = adjustMeshCoord(verData, i, coords2);
      if (temp) {
        verData[i + 1] = temp;
      }
    }
  }
  mesh.geometry.setVerticesData(
    BABYLON.VertexBuffer.PositionKind,
    verData,
    true
  );
}

function adjustMeshCoord(verData, i, coords) {
  for (var j = 0; j < coords.length; j++) {
    // console.log(verData[i], verData[i+2]);
    // console.log(coords[j]);
    // console.log((Math.abs(verData[i] - coords[j].x)));
    // console.log((Math.abs(verData[i+2] - coords[j].z)));
    if (
      Math.abs(verData[i] - coords[j].x) < 1.5 &&
      Math.abs(verData[i + 2] - coords[j].z) < 1.5
    ) {
      return coords[j].y;
    }
  }
  return false;
}

function adjustMeshCoordIntersection(verData, i, coords, thickness) {
  for (var j = 0; j < coords.length; j++) {
    // console.log(verData[i], verData[i+2]);
    // console.log(coords[j]);
    // console.log((Math.abs(verData[i] - coords[j].x)));
    // console.log((Math.abs(verData[i+2] - coords[j].z)));
    if (
      Math.abs(verData[i] - coords[j][0]) < 0.02 &&
      Math.abs(verData[i + 2] - coords[j][1]) < 0.02
    ) {
      return coords[j][2];
    }
  }
  return false;
}

function adjustMeshCoordsIntersection(mesh, coords1, coords2) {
  //var indices = mesh.getIndices();
  coords1 = coords1.map((point) => [
    point[0] * store.unit_scale,
    -point[1] * store.unit_scale,
    point[2] * store.unit_scale,
  ]);
  coords2 = coords2.map((point) => [
    point[0] * store.unit_scale,
    -point[1] * store.unit_scale,
    point[2] * store.unit_scale,
  ]);

  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var bbinfo = mesh.getBoundingInfo();
  var midY = (bbinfo.maximum.y - bbinfo.minimum.y) / 2;
  var thickness = 2;
  for (var i = 0; i < verData.length; i += 3) {
    if (verData[i + 1] <= midY) {
      var temp = adjustMeshCoordIntersection(verData, i, coords1, thickness);
      if (temp) {
        verData[i + 1] = temp;
      }
    } else {
      var temp = adjustMeshCoordIntersection(verData, i, coords2, thickness);
      if (temp) {
        if (temp >= verData[i + 1]) verData[i + 1] = temp;
      }
    }
  }
  mesh.geometry.setVerticesData(
    BABYLON.VertexBuffer.PositionKind,
    verData,
    true
  );
}

function adjustMeshCoords(
  mesh,
  coords1,
  coords2,
  meshVert1,
  meshVert2,
  meshVert3
) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var bbinfo = mesh.getBoundingInfo();
  var midY = (bbinfo.maximum.y + bbinfo.minimum.y) / 2;
  for (var i = 0; i < verData.length; i += 3) {
    if (verData[i + 1] <= midY) {
      var temp = adjustMeshCoord(verData, i, coords1);
      if (temp) {
        verData[i + 1] = temp;
      }
    } else {
      var temp = adjustMeshCoord(verData, i, coords2);
      if (temp) {
        verData[i + 1] = temp;
      }
    }
  }
  mesh.geometry.setVerticesData(
    BABYLON.VertexBuffer.PositionKind,
    verData,
    true
  );
  // for (var i = 0; i < verData.length; i += 3) {
  //     if (i < meshVert1*3){
  //         console.log(verData[i+1], coords1[i/3].y);
  //         verData[i+1] = coords1[i/3].y;
  //     }
  //     else if (i < meshVert1*3 + meshVert2*3){
  //         console.log(verData[i+1], coords2[i/3].y);
  //         verData[i+1] = coords2[i/3].y;
  //     }
  //     else if (i < meshVert1*3 + meshVert2*3 + meshVert3*3/2){
  //         console.log(verData[i+1], coords1[i/3].y);
  //         verData[i+1] = coords1[i/3].y;
  //     }
  //     else if (i < meshVert1*3 + meshVert2*3 + meshVert3*3){
  //         console.log(verData[i+1], coords2[i/3].y);
  //         verData[i+1] = coords2[i/3].y;
  //     }
  // }
}

function generateWallBase(
  wall_pol1_start,
  wall_pol1_end,
  wall_pol2_end,
  wall_pol2_start,
  flag = false
) {
  try {
    var shape_temp1 = [];
    var point = new BABYLON.Vector3(
      wall_pol1_start[0] * store.unit_scale,
      -wall_pol1_start[1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1_end[0] * store.unit_scale,
      -wall_pol1_end[1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2_end[0] * store.unit_scale,
      -wall_pol2_end[1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2_start[0] * store.unit_scale,
      -wall_pol2_start[1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "wall",
      shape_temp1,
      store.scene
    ).build(true, 12);
    return ribPol1;
  } catch (e) {
    console.error("Wall creation failed !");
    console.log(e);
  }
}

function generateWallStruct(wall_pol1, wall_pol2, i, j, n1, n2, obj_height) {
  try {
    var path1 = [];
    var path2 = [];
    var point = new BABYLON.Vector3(
      wall_pol1[i][j][0] * store.unit_scale,
      obj_height,
      -wall_pol1[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1[i][(j + 1) % n1][0] * store.unit_scale,
      obj_height,
      -wall_pol1[i][(j + 1) % n1][1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2[i][(j + 1) % n2][0] * store.unit_scale,
      obj_height,
      -wall_pol2[i][(j + 1) % n2][1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2[i][j][0] * store.unit_scale,
      obj_height,
      -wall_pol2[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);

    var point = new BABYLON.Vector3(
      wall_pol1[i][j][0] * store.unit_scale,
      0,
      -wall_pol1[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1[i][(j + 1) % n1][0] * store.unit_scale,
      0,
      -wall_pol1[i][(j + 1) % n1][1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2[i][(j + 1) % n2][0] * store.unit_scale,
      0,
      -wall_pol2[i][(j + 1) % n2][1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2[i][j][0] * store.unit_scale,
      0,
      -wall_pol2[i][j][1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);

    var ribbon1 = BABYLON.Mesh.CreateRibbon(
      "ribbon1",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    return ribbon1;
  } catch (e) {
    console.error("Wall ribbon creation failed !");
    console.log(e);
  }
}

function generateWallBaseInd(
  wall_pol1,
  wall_pol2,
  wall_pol3,
  wall_pol4,
  i,
  j,
  n1,
  n2
) {
  try {
    var shape_temp1 = [];
    var point = new BABYLON.Vector3(
      wall_pol1[i][j][0] * store.unit_scale,
      -wall_pol1[i][j][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1[i][(j + 1) % n1][0] * store.unit_scale,
      -wall_pol1[i][(j + 1) % n1][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2[i][(j + 1) % n2][0] * store.unit_scale,
      -wall_pol2[i][(j + 1) % n2][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2[i][j][0] * store.unit_scale,
      -wall_pol2[i][j][1] * store.unit_scale,
      0.0
    );
    point = snapVertData(point);
    shape_temp1.push(point);
    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp1,
      store.scene
    ).build(true);
    return ribPol1;
  } catch (e) {
    console.error("Wall creation failed in resolve Intersection");
    console.log(e);
  }
}

function generateWallStructInd(
  wall_pol2_start,
  wall_pol2_end,
  wall_pol1_start,
  wall_pol1_end,
  wall_pol4_start,
  wall_pol4_end,
  wall_pol3_start,
  wall_pol3_end,
  obj_height
) {
  try {
    var path1 = [];
    var path2 = [];
    var point = new BABYLON.Vector3(
      wall_pol3_start[0] * store.unit_scale,
      obj_height,
      -wall_pol3_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol3_end[0] * store.unit_scale,
      obj_height,
      -wall_pol3_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol4_end[0] * store.unit_scale,
      obj_height,
      -wall_pol4_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol4_start[0] * store.unit_scale,
      obj_height,
      -wall_pol4_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol3_start[0] * store.unit_scale,
      obj_height,
      -wall_pol3_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    // path1.push(path1[0]);

    var point = new BABYLON.Vector3(
      wall_pol1_start[0] * store.unit_scale,
      0,
      -wall_pol1_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1_end[0] * store.unit_scale,
      0,
      -wall_pol1_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2_end[0] * store.unit_scale,
      0,
      -wall_pol2_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2_start[0] * store.unit_scale,
      0,
      -wall_pol2_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1_start[0] * store.unit_scale,
      0,
      -wall_pol1_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    // path2.push(path2[0]);

    var ribbon1 = BABYLON.Mesh.CreateRibbon(
      "ribbon1",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    return ribbon1;
  } catch (e) {
    console.error("Ribbon creation failed in resolve Intersection");
    console.log(e);
  }
}

function generateWallStructIndInter(
  wall_pol2_start,
  wall_pol2_end,
  wall_pol1_start,
  wall_pol1_end,
  wall_pol4_start,
  wall_pol4_end,
  wall_pol3_start,
  wall_pol3_end,
  obj_height
) {
  try {
    var path1 = [];
    var path2 = [];
    var point = new BABYLON.Vector3(
      wall_pol3_start[0] * store.unit_scale,
      obj_height,
      -wall_pol3_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol3_end[0] * store.unit_scale,
      obj_height,
      -wall_pol3_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol4_start[0] * store.unit_scale,
      obj_height,
      -wall_pol4_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol4_end[0] * store.unit_scale,
      obj_height,
      -wall_pol4_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    var point = new BABYLON.Vector3(
      wall_pol3_start[0] * store.unit_scale,
      obj_height,
      -wall_pol3_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path1.push(point);
    // path1.push(path1[0]);

    var point = new BABYLON.Vector3(
      wall_pol1_start[0] * store.unit_scale,
      0,
      -wall_pol1_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1_end[0] * store.unit_scale,
      0,
      -wall_pol1_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2_start[0] * store.unit_scale,
      0,
      -wall_pol2_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol2_end[0] * store.unit_scale,
      0,
      -wall_pol2_end[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    var point = new BABYLON.Vector3(
      wall_pol1_start[0] * store.unit_scale,
      0,
      -wall_pol1_start[1] * store.unit_scale
    );
    point = snapVertData(point);
    path2.push(point);
    // path2.push(path2[0]);

    var ribbon1 = BABYLON.Mesh.CreateRibbon(
      "ribbon1",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    return ribbon1;
  } catch (e) {
    console.error(e);
  }
}

function generateWallBase2(pol) {
  var shape_temp1 = [];
  for (var i = 0; i < pol.length; i++) {
    var point = new BABYLON.Vector3(pol[i][0], pol[i][1], 0.0);
    point = snapVertData(point);
    shape_temp1.push(point);
  }
  var ribPol1 = new BABYLON.PolygonMeshBuilder(
    "RibPol",
    shape_temp1,
    store.scene
  ).build(true);
  return ribPol1;
}

function generateWallStruct2(pol, obj_height) {
  var path1 = [];
  var path2 = [];
  for (var i = 0; i < pol.length; i++) {
    var point = new BABYLON.Vector3(pol[i][0], obj_height, pol[i][1]);
    point = snapVertData(point);
    path1.push(point);
    var box = BABYLON.Mesh.CreateBox(
      "cCap",
      0.1,
      store.scene,
      false,
      BABYLON.Mesh.DEFAULTSIDE
    );
    box.position = point;
    var point = new BABYLON.Vector3(pol[i][0], 0.0, pol[i][1]);
    point = snapVertData(point);
    path2.push(point);
    var box = BABYLON.Mesh.CreateBox(
      "cCap",
      0.1,
      store.scene,
      false,
      BABYLON.Mesh.DEFAULTSIDE
    );
    box.position = point;
  }
  var ribbon1 = BABYLON.Mesh.CreateRibbon(
    "ribbon1",
    [path1, path2],
    false,
    false,
    0,
    store.scene,
    true,
    BABYLON.DOUBLESIDE
  );
  return ribbon1;
}

function splitFaces(mesh) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var numFaces = indices.length / 3;
  for (var j = 0; j < numFaces; j++) {
    var index0 = indices[j * 3];
    var index1 = indices[j * 3 + 1];
    var index2 = indices[j * 3 + 2];
    var index_buf = [];
    index_buf.push(index0);
    index_buf.push(index1);
    index_buf.push(index2);

    var Ax = verData[index_buf[0] * 3];
    var Ay = verData[index_buf[0] * 3 + 1];
    var Az = verData[index_buf[0] * 3 + 2];

    var Bx = verData[index_buf[1] * 3];
    var By = verData[index_buf[1] * 3 + 1];
    var Bz = verData[index_buf[1] * 3 + 2];

    var Cx = verData[index_buf[2] * 3];
    var Cy = verData[index_buf[2] * 3 + 1];
    var Cz = verData[index_buf[2] * 3 + 2];

    var a = (By - Ay) * (Cz - Az) - (Cy - Ay) * (Bz - Az);
    var b = (Bz - Az) * (Cx - Ax) - (Cz - Az) * (Bx - Ax);
    var c = (Bx - Ax) * (Cy - Ay) - (Cx - Ax) * (By - Ay);
    var d = -(a * Ax + b * Ay + c * Az);

    store.normalVector = [a, b, c];

    if (store.face_ribbon) {
      store.face_ribbon.dispose();
    }
    var mat2 = new BABYLON.StandardMaterial("mat2", store.scene);
    mat2.alpha = 1.0;
    mat2.diffuseColor = new BABYLON.Color3(0.0, 0.0, 0.0);
    mat2.backFaceCulling = false;

    store.face_coords = [];
    store.face_coords_index = [];
    for (let i = 0; i < verData.length; i = i + 3) {
      if (
        Math.abs(
          a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d
        ) <= 0.001
      ) {
        var vert = new BABYLON.Vector3(
          verData[i],
          verData[i + 1],
          verData[i + 2]
        );
        store.face_coords.push(vert);
        store.face_coords_index.push([i, i + 1, i + 2]);
      }
    }
    // console.log(face_coords_index);
  }
}

function createWallBlock(
  ribPol1,
  ribbon1,
  ribPol2,
  coords1,
  coords2,
  mesh,
  i,
  j
) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;

  // var meshVert1 = ribPol1.getTotalVertices();
  // var meshInd1 = ribPol1.getIndices().length;
  //
  // var meshVert2 = ribbon1.getTotalVertices();
  // var meshInd2 = ribbon1.getIndices().length;
  // // splitFaces(ribbon1);
  //
  // var meshVert3 = ribPol2.getTotalVertices();
  // var meshInd3 = ribPol2.getIndices().length;

  var mergedMesh = BABYLON.Mesh.MergeMeshes(
    [ribPol1, ribbon1, ribPol2],
    true,
    true
  );
  // var mergedMesh = ribPol1;
  var indices = mergedMesh.getIndices();
  var vb = mergedMesh.getVertexBuffer(BABYLON.VertexBuffer.PositionKind);
  var verData = vb.getData();
  adjustMeshCoords(mergedMesh, coords1, coords2, null, null, null);

  // mergedMesh.subMeshes = [];
  // var verticesCount = mergedMesh.getTotalVertices();
  //
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.pop();
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.pop();
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(2, 0, verticesCount, meshInd1 + meshInd2, meshInd3, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.pop();
  //
  // var multimat = new BABYLON.MultiMaterial("wall_multimat", store.scene);
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat4"));

  // mergedMesh.material = multimat;
  mergedMesh.material = store.scene.getMaterialByName("wall_mat");

  mergedMesh.checkCollisions = true;
  mergedMesh.name = "wall";
  mergedMesh.room_name = mesh.room_name;
  mergedMesh.room_type = mesh.room_type;
  mergedMesh.room_id = mesh.room_id;
  mergedMesh.room_curve = mesh.room_curve;
  mergedMesh.room_path = mesh.room_path;
  mergedMesh.level = mesh.level;
  mergedMesh.room_unique_id = mesh.room_unique_id;
  mergedMesh.structure = mesh.structure;
  mergedMesh.offsetFlag = true;
  mergedMesh.checkCollisions = true;

  mergedMesh.convertToFlatShadedMesh();
  click(mergedMesh);
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);

  // mergedMesh.position.x += mergedMesh.position.x - mesh.position.x;
  // mergedMesh.position.y += mergedMesh.position.y - mesh.position.y;
  // mergedMesh.position.z += mergedMesh.position.z - mesh.position.z;
  // mergedMesh.position = mesh.position;
  // mergedMesh.scaling = mesh.scaling;
  // mergedMesh.rotation = mesh.rotation;

  assignProperties(mergedMesh, i, "wall");
  return mergedMesh;
}

function createWallBlock2(ribPol1, ribbon1, ribPol2, mesh1, mesh2, obj_height) {
  // var meshVert1 = ribPol1.getTotalVertices();
  // var meshInd1 = ribPol1.getIndices().length;
  //
  // var meshVert2 = ribbon1.getTotalVertices();
  // var meshInd2 = ribbon1.getIndices().length;
  //
  // var meshVert3 = ribPol2.getTotalVertices();
  // var meshInd3 = ribPol2.getIndices().length;

  var mergedMesh = BABYLON.Mesh.MergeMeshes(
    [ribPol1, ribbon1, ribPol2],
    true,
    true
  );
  // var mergedMesh = ribPol1;
  var indices = mergedMesh.getIndices();
  var vb = mergedMesh.getVertexBuffer(BABYLON.VertexBuffer.PositionKind);
  var verData = vb.getData();

  // mergedMesh.subMeshes = [];
  // var verticesCount = mergedMesh.getTotalVertices();
  //
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)); // Works perfectly
  // mergedMesh.subMeshes.push(new BABYLON.SubMesh(2, 0, verticesCount, meshInd1 + meshInd2, meshInd3, mergedMesh)); // Works perfectly
  // // mergedMesh.subMeshes.push(new BABYLON.SubMesh(3, 0, verticesCount, meshInd1+meshInd2+meshInd3, meshInd4, mergedMesh)); // Works perfectly
  // // mergedMesh.subMeshes.push(new BABYLON.SubMesh(4, 0, verticesCount, meshInd1+meshInd2+meshInd3+meshInd4, meshInd5, mergedMesh)); // Works perfectly
  //
  // var multimat = new BABYLON.MultiMaterial("wall_mat", store.scene);
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("floor_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat4"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(scene.getMaterialByName("wall_mat4"));

  // mergedMesh.material = multimat;
  mergedMesh.material = store.scene.getMaterialByName("wall_mat");

  mergedMesh.checkCollisions = true;
  mergedMesh.name = "wall";
  mergedMesh.checkCollisions = true;

  mergedMesh.convertToFlatShadedMesh();
  click(mergedMesh);

  mergedMesh.room_name = mesh1.room_name;
  mergedMesh.room_type = mesh1.room_type;
  mergedMesh.room_id = mesh1.room_id;
  mergedMesh.room_curve = mesh1.room_curve;
  mergedMesh.room_path = mesh1.room_path;

  mergedMesh.room_name2 = mesh2.room_name;
  mergedMesh.room_type2 = mesh2.room_type;
  mergedMesh.room_id2 = mesh2.room_id;
  mergedMesh.room_curve2 = mesh2.room_curve;
  mergedMesh.room_path2 = mesh2.room_path;

  // mergedMesh.position = mesh1.position;
  // mergedMesh.rotation = mesh1.rotation;
  // mergedMesh.scaling = mesh1.scaling;

  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);

  assignProperties(mergedMesh, -1, "wall");
  return mergedMesh;
}

function mergeWalls(meshes, mesh, deleteSources) {
  for (var i = 0; i < meshes.length; i++) {
    var bbinfo = meshes[i].getBoundingInfo();
    var verData = meshes[i].getVerticesData(BABYLON.VertexBuffer.PositionKind);
    // findMinimumVertex(verData);
    // findMaximumVertex(verData);
  }
}

function callMakeWall(moveMesh, notIndependentMove = true) {
  let neighbours = removeDuplicates(moveMesh.neighborsWallMesh);
  let thickness = moveMesh._properties._thickness;
  let wallNeighbourArr = [];
  wallNeighbourArr.push(moveMesh);

  for (let i = 0; i < neighbours.length; i++) {
    let currMesh = store.scene.getMeshByUniqueID(neighbours[i].id);
    if (!currMesh) throw "Error check for mesh deletion";

    if (neighbours[i].type === "collinear") continue;

    if (currMesh.prevVectorData && notIndependentMove) {
      currMesh.refreshBoundingInfo();
      moveMesh.refreshBoundingInfo();

      // if(!meshLSIntersect(moveMesh, currMesh, moveMesh._properties._thickness, false, moveMesh.slopeTheta).value){
      //    delete currMesh.prevVectorData;
      //    continue;
      // }

      let parentMeshBbinfo = findMeshExtremePointsBBInfo(moveMesh);
      let newVectorData = removeDuplicates(
        currMesh.getBoundingInfo().boundingBox.vectorsWorld
      );
      let height = currMesh._properties._height;
      let thickness = currMesh._properties._thickness;
      let obj = findMeshExtremePointsBBInfo(currMesh);
      let relMeshRange = getRelationMeshRange(
        newVectorData,
        currMesh.prevVectorData
      );

      Array.prototype.push.apply(newVectorData, currMesh.prevVectorData);
      let newVectorDataObj = convertToWallCoords(
        newVectorData,
        obj.yMax - obj.yMin
      );
      let topCoords = removeDuplicates(newVectorDataObj.top);
      let bottomCoords = removeDuplicates(newVectorDataObj.bottom);
      let prevDiagonalLen = currMesh.prevDiagonalLen;
      let currFLoor = JSON.parse(JSON.stringify(currMesh.floor));
      // let moveMeshJunc = moveMesh.wallJunction;
      // for(let junc in moveMeshJunc){
      //    for(let orient in moveMeshJunc[junc]){
      //        if(moveMeshJunc[junc][orient].includes(currMesh.uniqueId))
      //    }
      // }

      if (obj.xMax - obj.xMin > obj.zMax - obj.zMin) {
        // getMeshTopBottomWorldCoords()
        topCoords.sort(function (a, b) {
          return a[0] - b[0];
        });
        bottomCoords.sort(function (a, b) {
          return a[0] - b[0];
        });
      } else {
        topCoords.sort(function (a, b) {
          return a[1] - b[1];
        });
        bottomCoords.sort(function (a, b) {
          return a[1] - b[1];
        });
      }

      let nearPoint = JSON.parse(neighbours[i].nearPoint);
      nearPoint[0] /= store.unit_scale;
      nearPoint[1] /= -store.unit_scale;

      if (
        parentMeshBbinfo.xMax - parentMeshBbinfo.xMin >
        parentMeshBbinfo.zMax - parentMeshBbinfo.zMin
      ) {
        if (
          calculateEuclidianDistance(nearPoint, topCoords[0]) <
          calculateEuclidianDistance(nearPoint, topCoords[topCoords.length - 1])
        ) {
          if (
            parseFloat(topCoords[0][1].toFixed(1)) >
            parseFloat((parentMeshBbinfo.zMax / -store.unit_scale).toFixed(1))
          ) {
            topCoords[0][1] -= thickness;
            topCoords[1][1] -= thickness;
            bottomCoords[0][1] -= thickness;
            bottomCoords[1][1] -= thickness;
          }
        } else {
          if (
            parseFloat(topCoords[topCoords.length - 1][1].toFixed(1)) <
            parseFloat((parentMeshBbinfo.zMin / -store.unit_scale).toFixed(1))
          ) {
            topCoords[topCoords.length - 1][1] += thickness;
            topCoords[topCoords.length - 2][1] += thickness;
            bottomCoords[bottomCoords.length - 1][1] += thickness;
            bottomCoords[bottomCoords.length - 2][1] += thickness;
          }
        }
      } else {
        if (
          calculateEuclidianDistance(nearPoint, topCoords[0]) <
          calculateEuclidianDistance(nearPoint, topCoords[topCoords.length - 1])
        ) {
          if (
            parseFloat(topCoords[0][0].toFixed(1)) >
            parseFloat((parentMeshBbinfo.xMin / store.unit_scale).toFixed(1))
          ) {
            topCoords[0][0] -= thickness;
            topCoords[1][0] -= thickness;
            bottomCoords[0][0] -= thickness;
            bottomCoords[1][0] -= thickness;
          }
        } else {
          if (
            parseFloat(topCoords[topCoords.length - 1][0].toFixed(1)) <
            parseFloat((parentMeshBbinfo.xMax / store.unit_scale).toFixed(1))
          ) {
            topCoords[topCoords.length - 1][0] += thickness;
            topCoords[topCoords.length - 2][0] += thickness;
            bottomCoords[bottomCoords.length - 1][0] += thickness;
            bottomCoords[bottomCoords.length - 2][0] += thickness;
          }
        }
      }

      for (let i = 0, j = 0; i < (2 * topCoords.length - 4) / 4; i++, j += 2) {
        try {
          let wall_path = makeWallPath(bottomCoords.slice(j, j + 4));
          let max_height = topCoords[j][2],
            wall_path_top = [];
          wall_path.forEach(function (item) {
            max_height = topCoords.find(function (point) {
              return item[0] === point[0] && item[1] === point[1];
            });
            wall_path_top.push(
              JSON.parse(JSON.stringify([item[0], item[1], max_height[2]]))
            );
          });

          let nwribPol1 = generateWallBase(
            wall_path[0],
            wall_path[1],
            wall_path[2],
            wall_path[3]
          );
          let nwribbon1 = generateWallStructIndInter(
            wall_path[0],
            wall_path[1],
            wall_path[2],
            wall_path[3],
            wall_path_top[0],
            wall_path_top[1],
            wall_path_top[2],
            wall_path_top[3],
            height
          );
          let nwribPol2 = generateWallBase(
            wall_path_top[0],
            wall_path_top[1],
            wall_path_top[2],
            wall_path_top[3],
            true
          );
          nwribPol2.position.y += height;
          var mergedMesh = createWallBlock2(
            nwribPol1,
            nwribbon1,
            nwribPol2,
            currMesh,
            currMesh,
            height
          );
          adjustMeshCoordsIntersection(mergedMesh, bottomCoords, topCoords);

          mergedMesh._properties._thickness = thickness;
          mergedMesh._properties._height = height;
          mergedMesh.checkCollisions = true;
          mergedMesh.name = "wall";
          mergedMesh.type = "wall";
          mergedMesh.room_name = currMesh.room_name;
          mergedMesh.room_type = currMesh.room_type;
          mergedMesh.room_id = currMesh.room_id;
          mergedMesh.room_curve = currMesh.room_curve;
          mergedMesh.roof = currMesh.roof;
          mergedMesh.room_path = currMesh.room_path;
          mergedMesh.checkCollisions = true;
          mergedMesh.convertToFlatShadedMesh();
          mergedMesh.structure = currMesh.structure;
          mergedMesh.level = currMesh.level;
          mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
          mergedMesh.children = currMesh.children;
          mergedMesh.checkForOverlap = true;
          mergedMesh.checkForExtention = true;

          //mergedMesh.floor = assignFloorForExtendedMesh(wall_path, relMeshRange);
          let dist =
            (store.a = calculateEuclidianDistance(wall_path[0], wall_path[2])) >
            (store.b = calculateEuclidianDistance(wall_path[1], wall_path[3]))
              ? store.a
              : store.b;

          try {
            if (prevDiagonalLen * prevDiagonalLen > dist * store.unit_scale) {
              //console.log("inside setTimeout");
              mergedMesh.floor = JSON.parse(JSON.stringify(moveMesh.floor));
              Array.prototype.push.apply(mergedMesh.floor, currFLoor);
            } else {
              let isPartOfFlag = assignFloorForExtendedMesh(
                wall_path,
                relMeshRange,
                currMesh._properties._thickness
              );
              if (isPartOfFlag) {
                mergedMesh.floor = JSON.parse(JSON.stringify(currMesh.floor));
              } else {
                mergedMesh.floor = JSON.parse(JSON.stringify(moveMesh.floor));
                //Array.prototype.push.apply(mergedMesh.floor, currFLoor);
                currMesh.floor.forEach(function (id) {
                  let floorM = store.scene.getMeshByUniqueID(id);
                  findFloorForNewWall(mergedMesh, floorM);
                });
              }
            } //new wall generated from the current wall otherwise tje wall is shortened.
          } catch (e) {
            console.error("Error while assigning floor", e);
          }
          //click(mergedMesh);
          store.doublyLinkedList.append(mergedMesh);
          wallNeighbourArr.push(mergedMesh);
          store.wallLevelArray.push(mergedMesh);
        } catch (e) {
          console.error("wall creation failed !", e);
          mergedMesh.dispose();
        }
      }
      deleteWallMesh(currMesh);
      newVectorData = [];
    }
  }
  return wallNeighbourArr;
}

function deleteWallMesh(mesh) {
  try {
    let neighbours = mesh.neighborsWallMesh;
    for (let j = 0; j < neighbours.length; j++) {
      let neighbourMesh = store.scene.getMeshByUniqueID(neighbours[j].id);
      let nneighbours = neighbourMesh.neighborsWallMesh;

      for (let k = 0; k < nneighbours.length; ) {
        if (nneighbours[k].id === mesh.uniqueId) nneighbours.splice(k, 1);
        else k++;
      }

      neighbourMesh.neighborsWallMesh = nneighbours;
    }

    delete mesh.prevVectorData;
    delete mesh.prevDiagonalLen;
    delete mesh.children;

    store.doublyLinkedList.remove(mesh);
  } catch (e) {
    console.error(e);
  }

  // for(let i=0;i<doublyLinkedList.size();i++){
  //     let curr_mesh = store.doublyLinkedList.item(i).data;
  //     let neighbours = curr_mesh.neighborsWallMesh;
  //     if(neighbours){
  //         for(let j=0;j<neighbours.length;){
  //             if(neighbours[j].id === mesh.uniqueId)
  //                 neighbours.splice(j,1);
  //             else
  //                 j++;
  //         }
  //     }
  // }
  mesh.dispose();
}
export {
  createRoomBeta,
  createRoomPolygons,
  // createRoom,
  createRooms,
  offsetRoomPols,
  offsetRoomPolsRoof,
  getSlope,
  getOffsetPointSi,
  getOffsetPointRi,
  getDistance,
  checkIfAreaCounts,
  getSlopeAngleAndDisWall,
  createRoomsOld,
  checkIntersection,
  checkWallOverlap,
  meshStartingPoint,
  isPParent,
  resolveIntersectionCSG,
  checkForWallSizes,
  createPartitionWalls,
  resolveIntersectionWalls,
  resolveIntersectionWalls1,
  generateWall,
  combineWallCoordsY,
  combineWallCoordsX,
  generateFloorForRoomOld,
  generateFloorForRoom,
  generateRoofForRoom,
  adjustRoofCoords,
  adjustMeshCoord,
  adjustMeshCoordIntersection,
  adjustMeshCoordsIntersection,
  adjustMeshCoords,
  generateWallBase,
  generateWallStruct,
  generateWallBaseInd,
  generateWallStructInd,
  generateWallStructIndInter,
  generateWallBase2,
  generateWallStruct2,
  splitFaces,
  createWallBlock,
  createWallBlock2,
  mergeWalls,
  callMakeWall,
  deleteWallMesh,
};
