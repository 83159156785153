import {store} from "../modules/utilityFunctions/Store";
import {goOutOfTwoD} from "./twoDimension";
import _ from "lodash";
import reduxStore from "../stateManagers/store/reduxStore";
import {updateScreenshotUiData} from "../stateManagers/reducers/objectProperties/screenshotUiDataSlice";
import BABYLON from "../modules/babylonDS.module";
import {saveHighResImage, saveLowResImage, saveViewMetaData, updateViewsData} from "../../services/views.service";
import {ORIGIN} from "../../services/url.constants";
import {_changeTextureDetail} from "./mats";
import sunpath from "../modules/sunpath/sunpathOperations";
import {toggleOrthoView} from "./sceneFuncs";

const updateViewOperation = (function (){

  async function refreshSelectedViews(selectedImages) {
    let imageArr = [];
    if(!selectedImages){
      for (let i = 0; i < store.$scope.sceneViewData.length; i++) {
        if (store.$scope.sceneViewData[i].highlighted === true) {
          imageArr.push(store.$scope.sceneViewData[i].imgKey);
        }
      }
    }
    else{
      imageArr = selectedImages;
    }
    if(store.$scope.isTwoDimension){
      goOutOfTwoD();
    }

    let promises = [];
    // let highlightedPositions = [];
    for( let i = 0; i < imageArr.length; i++ ){

      const positions = [];
      // let imageObject = _.findKey(store.$scope, )
      let imageObject = store.$scope.sceneViewData.filter((eachImage, j) => {
        if(eachImage.imgKey === imageArr[i]){
          positions.push(j);
          return eachImage;
        }
      })[0];

      let options = {};
      options.updateView = true;
      options.camData = imageObject.camData;
      options.position = positions[0];

      // highlightedPositions.push(options.position);
      promises.push(refreshSingleImage(store.floorkey, options));
    }

    // await updateViewsData(imageArr);
    await Promise.all(promises);

    if(!_.isEmpty(store.newSceneViewData)){
      store.$scope.sceneViewData.push(...store.newSceneViewData);
    }
    reduxStore.dispatch(updateScreenshotUiData({sceneViewData: store.$scope.sceneViewData}));
    return true;
  }

  async function generateHighResImages(resolution, ext, key) {
    let output = ext === "jpeg" ? "image/jpeg" : "image/png";
    let imgHeight, imgWidth;
    // let key = Math.floor(Math.random() * 90000) + 10000;

    if (!resolution) resolution = "1280 x 720";
    switch (resolution) {
      // case "3840 x 2160":
      //   imgHeight = 2160;
      //   imgWidth = 3840;
      //   break;
      case "1920 x 1080":
        imgHeight = 1080;
        imgWidth = 1920;
        break;
      case "1280 x 720":
        imgHeight = 720;
        imgWidth = 1280;
        break;
      case "960 x 540":
        imgHeight = 540;
        imgWidth = 960;
        break;
      case "800 x 240":
        imgHeight = 240;
        imgWidth = 800;
        break;
      case "560 x 240":
        imgHeight = 240;
        imgWidth = 560;
        break;
      default:
        console.log("no res selected");
        break;
    }

    let _highResCapturePromise = function () {
      return new Promise((resolve, reject) => {
        BABYLON.Tools.CreateScreenshot(
          store.engine,
          store.scene.activeCamera,
          {width: imgWidth, height: imgHeight},
          function (image) {
            resolve(image);
          }
        );
      });
    };

    return new Promise((resolve, reject) => {
      _highResCapturePromise()
        .then(async (value) => {
          await saveHighResImage(value, store.floorkey, key);
          resolve(value);
        })
        .catch((error) => {
          reject(error);
          console.error("screenshot capture failed", error);
        })
    });
  }

  function _spliceSceneViewData(key) {
    _.remove(store.$scope.sceneViewData, function(currentObject) {
      return currentObject.imgKey === key;
    });
    // store.$scope.sceneViewData.splice(position, 1);
  }

  async function refreshSingleImage(floorkey, options) {

    // let img = document.createElement("img");
    let key = Math.floor(Math.random() * 90000) + 10000;
    // let key = options.imgKey;
    let orig = ORIGIN + "/media/";
    let path = store.userid + "/" + floorkey + "/screenshot/" + "view_" + key + ".png";
    let hemiLight = store.scene.getLightByName("light1");
    let shadowToggle = hemiLight.intensity === 0;
    let sunPath = !!store.scene.getMeshByName("sunpathLines") ;
    let currentCameraData = options.camData;
    const camDataJson = JSON.stringify(currentCameraData);
    let _requiredImageInScope = store.$scope.sceneViewData[options.position];

    let _lowResCapturePromise = function () {
      return new Promise((resolve, reject) => {
        BABYLON.Tools.CreateScreenshot(
          store.engine,
          store.scene.activeCamera,
          { width: 256, height: 144 },
          (data) => {
            resolve(data);
          }
        );
      });
    };

    // let _highResCapturePromise = function () {
    //   return new Promise((resolve, reject) => {
    //     BABYLON.Tools.CreateScreenshot(
    //       store.engine,
    //       store.scene.activeCamera,
    //       { width: 3840, height: 2160 },
    //       function (image) {
    //         resolve(image);
    //       }
    //     );
    //   });
    // };

    let _saveViewMetaDataPromise = async function () {
      return saveViewMetaData(
        camDataJson,
        floorkey,
        key,
        store.userSettingsInStructure.textureDetail
      );
    };

    let _saveLowResPromise = function (lowRes) {
      return saveLowResImage(lowRes, floorkey, key);
    };

    // let _saveHighResPromise = function (highRes){
    //   return saveHighResImage(highRes, floorkey, key);
    // };

    let _refreshSceneCamera = function (){

      store.scene.activeCamera.position = currentCameraData.position;
      store.scene.activeCamera.target = new BABYLON.Vector3(currentCameraData.target._x, currentCameraData.target._y, currentCameraData.target._z);

      // _changeTextureDetail(currentCameraData.textureDetail);

      if( !currentCameraData.isTwoDimension ){
        if( store.$scope.isTwoDimension ){
          goOutOfTwoD();
        }
        if( currentCameraData.ortho === BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
          if( store.scene.activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
            toggleOrthoView();
          }
        }
        else {
          store.scene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
        }
      }

      if (currentCameraData.shadowData.shadowToggle) {
        let hemiLight = store.scene.getLightByName("light1");
        let shadowStatus = hemiLight.intensity === 0;
        if (!shadowStatus) sunpath.toggleShadows();
        sunpath.changeSunPos(
          currentCameraData.shadowData.date.month,
          currentCameraData.shadowData.date.date,
          currentCameraData.shadowData.date.time
        );
        // toggleHiddenLine(true);
      }
      else {
        let hemiLight = store.scene.getLightByName("light1");
        let shadowStatus = hemiLight.intensity === 0;
        // if (shadowStatus) sunpath.toggleShadows();
        sunpath.clearShadows();
      }

      if(currentCameraData.sunPath){
        let sunPathLines = store.scene.getMeshByName("sunpathLines");
        if(!sunPathLines){
          sunpath.toggleSunpath();
        }
      }
      else{
        sunpath.clearSunpath();
      }

      store.scene.render();
    }

    let _updateImageInFromTheScopeSceneViewData = function () {
      store.newSceneViewData.push({
        path: orig + path,
        imgKey: key,
        camData: currentCameraData,
        textureDetail: store.userSettingsInStructure.textureDetail,
        screenshotTitle: "ScreenShot Title " + key,
        highlighted: true
      })
    }

    let _deleteImageInTheBackEndPromise = function (image){
      return updateViewsData([image]);
    }

    return new Promise(async (resolve, reject) => {
      _refreshSceneCamera();
      // let highResImage = await _highResCapturePromise();
      // let lowResImage = await _lowResCapturePromise();
      let lowResImage;
      let highResImage;

      Promise.all([_lowResCapturePromise()]).then((values) => {
        lowResImage = values[0];
        // highResImage = values[1];
      })

      await _saveViewMetaDataPromise();
      await _saveLowResPromise(lowResImage);
      // await _saveHighResPromise(highResImage);
      // _spliceSceneViewData(_requiredImageInScope.imgKey);
      // _updateImageInFromTheScopeSceneViewData();
      store.$scope.sceneViewData.splice(options.position, 1, {
        path: orig + path,
        imgKey: key,
        camData: currentCameraData,
        textureDetail: store.userSettingsInStructure.textureDetail,
        screenshotTitle: "ScreenShot Title " + key,
        highlighted: true
      });

      await _deleteImageInTheBackEndPromise(_requiredImageInScope.imgKey);

      resolve("refresh view done");
    });
  }

  return {
    refreshSelectedViews,
    generateHighResImages,
  };

})();

export { updateViewOperation }


