import BABYLON from "../modules/babylonDS.module.js";
import jQuery from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { getFace } from "./faceFuncs.js";
import { getWallAngle } from "./drawStairEvents.js";
var wallst1;
var wallst2;
var door = null;
var onDoorDrawDown = function (evt) {
  door = store.scene.getMeshByName("DoorsTemp");
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  if (door) {
    if ($scope.searchTextDoor[0].toLowerCase() === "single")
      door.name = "Single_Door";
    else if ($scope.searchTextDoor[0].toLowerCase() === "double")
      door.name = "Double_Door";
    else if ($scope.searchTextDoor[0].toLowerCase() === "sliding")
      door.name = "Sliding_Door";
    else door.name = "Single_Door";
    door.type = "Door";

    let structures = StructureCollection.getInstance();
    door.structure_id = door.parent.structure_id;
    let str = structures.getStructureById(door.structure_id);
    let level = str.getLevelByUniqueId(
      str.getObjectByUniqueId(door.parent.uniqueId).level_id
    );
    level.addMeshToLevel(door, false);

    updateModifications();
  }
};

var onDoorDrawUp = function (evt) {};

var updateDoorPosition = function (distance) {
  let dist = DisplayOperation.getOriginalDimension(distance);
  if (
    BABYLON.Vector3.Distance(wallst1, door.position) <=
    BABYLON.Vector3.Distance(wallst2, door.position)
  ) {
    let pd = wallst1.subtract(door.position).normalize();
    let p = wallst1.subtract(pd.scale(dist));
    door.position = p;
    wallst1.y = door.position.y;
    DisplayOperation.drawOnMove(wallst1, door.position);
    DisplayOperation.displayOnMove(
      BABYLON.Vector3.Distance(wallst1, door.position)
    );
  } else {
    let pd = wallst2.subtract(door.position).normalize();
    let p = wallst2.subtract(pd.scale(dist));
    door.position = p;
    wallst2.y = door.position.y;
    DisplayOperation.drawOnMove(wallst2, door.position);
    DisplayOperation.displayOnMove(
      BABYLON.Vector3.Distance(wallst2, door.position)
    );
  }
  onDoorDrawDown("");
};

var onDoorDrawMove = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      // return mesh !== store.scene.getMeshByName('ground1');
      return mesh.name === "wall";
    }
  );
  // var step_size = [36 * store.unit_absolute_scale, 36 * store.unit_absolute_scale];
  var step_size = [3.6, 3.6];
  if (pickInfo.hit) {
    var $scope = store.angular.element(appElement).scope();
    $scope = $scope.$$childHead;
    if (pickInfo.pickedMesh.name === "wall") {
      var face = getFace(pickInfo);
      var angle = getWallAngle(face);
      if (store.scene.getMeshByName("DoorsTemp")) {
        door = store.scene.getMeshByName("DoorsTemp");
      } else {
        door = drawDoor(pickInfo, $scope.searchTextDoor[0], step_size);
      }
      var bbinfo = door.getBoundingInfo();

      door.position = pickInfo.pickedPoint;
      // door.position.y = pickInfo.pickedMesh.position.y - step_size[1]/2;
      door.position.y = bbinfo.maximum.y * door.scaling.y;
      // door.position.y = pickInfo.pickedMesh.position.y - Math.abs(door.getBoundingInfo().boundingBox.minimumWorld.y);
      door.position.x -=
        2 * (door.getBoundingInfo().maximum.x / 2) * Math.cos(angle);
      door.position.z -=
        2 * (door.getBoundingInfo().maximum.z / 2) * Math.sin(angle);
      door.rotation.y = angle;
      door.parent = pickInfo.pickedMesh.clone();
      let bbinfo1 = door.parent.getBoundingInfo();
      /*
            TODO: Draw a dim line between pickInfo.pickedMesh.position to door.position
            and show the distance as BABYLON.Distance()

             */

      //let st = new BABYLON.Vector3.Zero();
      let vw = bbinfo1.boundingBox.vectorsWorld;
      let vw2 = bbinfo.boundingBox.vectorsWorld;
      let end = door.position.clone();
      //st = jQuery.extend({}, door.parent.position);//(vw[5].add(vw[2])).scale(0.5);
      let st1 = null;
      let st2 = null;
      if (
        BABYLON.Vector3.Distance(vw[0], vw[4]) <
        BABYLON.Vector3.Distance(vw[0], vw[2])
      ) {
        st1 = vw[3]
          .add(vw[6])
          .scale(0.5)
          .add(vw[0].add(vw[4]).scale(0.5))
          .scale(0.5);
        st2 = vw[5]
          .add(vw[1])
          .scale(0.5)
          .add(vw[2].add(vw[7]).scale(0.5))
          .scale(0.5);
        console.log("in if");
      } else {
        st1 = vw[0]
          .add(vw[2])
          .scale(0.5)
          .add(vw[5].add(vw[3]).scale(0.5))
          .scale(0.5);
        st2 = vw[7]
          .add(vw[4])
          .scale(0.5)
          .add(vw[1].add(vw[6]).scale(0.5))
          .scale(0.5);
        console.log("in else");
      }

      //doing it to get this in global cope to update on keypress event
      wallst1 = st1;
      wallst2 = st2;

      if (
        BABYLON.Vector3.Distance(st1, end) <= BABYLON.Vector3.Distance(st2, end)
      ) {
        st1.y = end.y;
        DisplayOperation.drawOnMove(st1, end);
        DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st1, end));
      } else {
        st2.y = end.y;
        DisplayOperation.drawOnMove(st2, end);
        DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st2, end));
      }

      if (Math.abs(face[1][0]) > 0) {
        // door.position.x += Math.sign(face[1][0]) * bbinfo.maximum.x / 2;
      } else if (Math.abs(face[1][2]) > 0) {
        // door.position.z += Math.sign(face[1][2]) * bbinfo.maximum.x / 2;
      }
    }
  }
};

function drawDoor(pickInfo, doorType, step_size) {
  if (doorType.toLowerCase() == "single") {
    return createSingleDoor(pickInfo.pickedMesh, step_size);
  } else if (doorType.toLowerCase() == "double") {
    return createDoubleDoor(pickInfo.pickedMesh, step_size);
  } else if (doorType.toLowerCase() == "sliding") {
    return createSlidingDoor(pickInfo.pickedMesh, step_size);
  } else {
    return createSingleDoor(pickInfo.pickedMesh, step_size);
  }
}

function drawDoorFplan(pickInfo, doorType, step_size) {
  if (doorType.toLowerCase() == "single") {
    return createSingleDoor(null, step_size);
  } else if (doorType.toLowerCase() == "double") {
    return createDoubleDoor(pickInfo.pickedMesh, step_size);
  } else if (doorType.toLowerCase() == "sliding") {
    return createSlidingDoor(pickInfo.pickedMesh, step_size);
  } else {
    return createSingleDoor(pickInfo.pickedMesh, step_size);
  }
}

function createSingleDoor(parentMesh = null, step_size) {
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "Door",
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = step_size[0] / 2;
  mergedMesh.scaling.z = step_size[0];
  mergedMesh.scaling.y = store.floor_height - step_size[1];
  mergedMesh.name = "DoorsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      bbinfo.boundingBox.extendSizeWorld.x;
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      bbinfo.boundingBox.extendSizeWorld.x;
  mergedMesh.showBoundingBox = true;

  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}

function createDoubleDoor(parentMesh = null, step_size) {
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "Door",
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = step_size[0] * 2;
  mergedMesh.scaling.z = step_size[0] * 2;
  mergedMesh.scaling.y = store.floor_height - step_size[1];
  mergedMesh.name = "DoorsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.25;
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  mergedMesh.showBoundingBox = true;
  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}

function createSlidingDoor(parentMesh = null, step_size) {
  console.log(parentMesh);
  var i = 0;
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "doors" + i,
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = step_size[0] / 2;
  mergedMesh.scaling.z = step_size[0];
  mergedMesh.scaling.y = store.floor_height - step_size[1];
  mergedMesh.name = "DoorsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.25;
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  console.log(mergedMesh.scaling.x);
  mergedMesh.showBoundingBox = true;
  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}
export {
  wallst1,
  wallst2,
  door,
  onDoorDrawDown,
  onDoorDrawUp,
  updateDoorPosition,
  onDoorDrawMove,
  drawDoor,
  drawDoorFplan,
  createSingleDoor,
  createDoubleDoor,
  createSlidingDoor,
};
