import React, { useState } from "react";
import styled from "styled-components";
import searchIcon from "../../assets/icons/search.svg";

const StyledSearchInput = styled.form`
  display: flex;
  background: ${(props) => props.theme.colors.clearWhite};
  border: 0.1rem solid ${(props) => props.theme.colors.blackRgb};
  box-sizing: border-box;
  border-radius: 0.313rem;
  height: 2.188rem;
  &:hover,
  &:focus-within {
    border-color: ${(props) => props.theme.colors.lightGrey};
  }
  input {
    border: none;
  }
  input:focus {
    outline: none;
  }
  img {
    width: 1.4rem;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }
`;
export const SearchInput = ({value, onChange, onSubmit, ...props}) => {
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if(onSubmit) onSubmit(evt);
  }
  return (
    <StyledSearchInput onSubmit={handleSubmit} {...props}>
      <img src={searchIcon} alt="search-icon" />
      <input placeholder="Search" value={value} onChange={onChange} />
    </StyledSearchInput>
  );
};
