import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Input from "../../../components/Input/index";
import { colors } from "../../../themes/constant";
import show_hide from "../../../assets/icons/show_hide.svg";
import selectAll from "../../../assets/icons/selectAll.svg";
import dropArrow from "../../../assets/icons/dropArrow.svg";
import dropOpenArrow from "../../../assets/icons/dropOpenArrow.svg";
import editLayer from "../../../assets/tiles/editLayer.svg";
import Bulb0 from "../../../assets/menu_icons/Bulb0.svg";
import circularExpand from "../../../assets/icons/circularExpand.svg";
import lock_close from "../../../assets/icons/lock_close.svg";
import lock_open from "../../../assets/icons/lock_open.svg";
import dim_bulb from "../../../assets/icons/dim_bulb.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import copy from "../../../assets/icons/copy.svg";
import pointer from "../../../assets/icons/pointer.svg";
import heightMapToggleOff from "../../../assets/icons/heightMapToggleOff.svg"
import heightMapToggleOn from "../../../assets/icons/heightMapToggleOn.svg"
import Divider from "../../../components/Divider/index";
import * as constants from "./constants";
import { LabelWithToggle } from "../../../components/TopMenuBar/subMenuDropDowns/toggleList";
import { useDispatch } from "react-redux";
import {
  showOrHideLayer,
  deleteLayer,
  enableOrDisableHeightMap
} from "../../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice.js"
import { store } from "../../../snaptrude/modules/utilityFunctions/Store.js";
import { terrainGeneration } from "../../../snaptrude/modules/geo/terrainMap.js"

import {
  updateHeight,
  selectStorey,
} from "../../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice";
import {layerView} from "../../../snaptrude/libs/layerView";
import {deleteCadLayer} from "../../../snaptrude/modules/cadImporter/cadServices";
import {Command} from "../../../snaptrude/modules/commandManager/Command";
import {StoreyMutation} from "../../../snaptrude/modules/storeyEngine/storeyMutations";
import {CommandManager} from "../../../snaptrude/modules/commandManager/CommandManager";
import {setActiveLayerAndRecord} from "../../../snaptrude/modules/extrafunc";
import {StructureCollection} from "../../../snaptrude/modules/snaptrudeDS/structure.ds";
import {
  deleteNeighborhoodLayer,
} from "../../../snaptrude/modules/geo/terrainNeighborhood";
import {handleFloorPlanDeletionFromLayersUI} from "../../../snaptrude/libs/twoDimension";

const ImageButton = styled.div`
  border: none;
  background: ${colors.fullWhite};
  margin: 0.5rem 0;
  height: 2.5em;
  width: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  .selected_icon {
    width: 0.9375rem;
    height: 0.9375rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
`;

const MainWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  border-top-right-radius: 6.8px;
  border-bottom-right-radius: 6.8px;
  box-shadow: 8.87059px 4.77647px 20.4706px rgba(197, 202, 220, 0.5);
  padding: 0.25rem 0;
`;

const ExpandedMenu = styled.div`
  height: 2.5rem;
  width: 11.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${colors.fullWhite};
  border-top-right-radius: 6.8px;
  border-bottom-right-radius: 6.8px;
  z-index: 2;
`;

const SecondExpandedMenu = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 8.87059px 4.77647px 20.4706px rgba(197, 202, 220, 0.5);
  border-radius: 6.82353px;
  width: 12.4rem;
  height: 10.625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
  align-self: ${({ alignSelf }) => alignSelf};
  width: ${({ width }) => width};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  .toggleContainer {
    border: 1px solid ${colors.white};
    padding: 0.25rem;
    border-radius: 5px;
    .leftItem {
      margin: 0;
    }
  }
  p {
    font-family: ${(props) => props.theme.font};
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.212788px;
    color: ${colors.primeBlack};
    margin: 0;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
  }
  .newLayerImage {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3125rem;
  }
  .layerRowImage {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3125rem;
  }
  .layerIcon {
    width: 0.625rem;
    height: 0.625rem;
    cursor: pointer;
  }
`;

const EachRow = styled(Flex)`
  justify-content: space-between;
  padding: 0 0.1875rem;
  margin: ${({ margin }) => margin};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
  img {
    width: 0.9375rem;
    height: 0.9375rem;
    margin: 0.125rem 0.3125rem;
  }
  .dimText {
    font-size: 8px;
    color: ${colors.gray};
  }
  .dimColorText {
    font-size: 7px;
    color: ${colors.thinGray};
    opacity: 0.6;
    margin-right: 0.3125rem;
  }
`;

const SecondaryExpansionContainer = styled.div`
  position: absolute;
  left: -0.9375rem;
  top: 2rem;
  border-bottom-left-radius: 6.8px;
  border-bottom-right-radius: 6.8px;
  background-color: ${colors.fullWhite};
  .heading {
    font-family: ${(props) => props.theme.font};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.271896px;
    color: ${colors.primeBlack};
    margin: 0 0.625rem;
    text-align: left;
  }
  .headingPlus {
    font-size: 20px;
    color: ${colors.thinGray};
    font-weight: 400;
    margin-right: 0.5rem;
    cursor: pointer;
  }
`;

const ExpandedMenuHeader = styled(Flex)`
  justify-content: space-between;
  padding-top: 1rem;
`;

const CircularThickness = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${colors.thinGray};
  border-radius: 50%;
`;

const CircularWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.white};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  cursor: pointer;
`;

const LayerRow = (props) => {
  const [lockOpen, setLockOpen] = useState(false);
  const [dimBulb, setDimBulb] = useState(false);
  const [heightMapToggle, setHeightMapToggle] = useState(false);
  const { key, item, setShowEditExpansion, isSelected, setSelectedLayer } =
    props;

  useEffect(() => {
    let layer = layerView.getLayerFromLayerId(
      store.activeLayer.structure_id,
      1,
      item.id
    );
    if (layer && layer.terrain.length > 0)
      setHeightMapToggle(layer.terrain[0].parameters.subdivisionsX > 1);
  }, []);

  const dispatch = useDispatch();

  let handleShowOrHideLayer = (layerId, storeyValue, operation) => {
    let _showOrHideLayer = function () {
      let data = this.data;
      let layer = layerView.getLayerFromLayerId(
        store.activeLayer.structure_id,
        data.storeyValue,
        data.layerId
      );
      if (!layer.hidden) {
        setDimBulb(true);
        layer.hide();
        dispatch(
          showOrHideLayer({
            layerId: data.layerId,
            storeyValue: data.storeyValue,
            operation: "hide",
          })
        );
      } else {
        setDimBulb(false);
        layer.show();
        dispatch(
          showOrHideLayer({
            layerId: data.layerId,
            storeyValue: data.storeyValue,
            operation: "show",
          })
        );
      }
    };

    let _getCommandLogic = function () {
      return {
        execute: _showOrHideLayer,
        unexecute: _showOrHideLayer,
      };
    };

    let _executeEvent = function () {
      let _layerShowOrHideData = {
        layerId: layerId,
        storeyValue: storeyValue,
        operation: operation,
      };

      let layerHideCommand = new Command(
        "HideLayer",
        _layerShowOrHideData,
        _getCommandLogic(),
        StoreyMutation.hideOrShowLayerGetSaveData
      );

      CommandManager.execute(layerHideCommand, true);
    };

    _executeEvent();
  };

  let handleDeleteLayer = (layerId, layerName, storeyValue) => {
    let layerData = {
      id: layerId,
      structure_id: store.activeLayer.structure_id,
      name: layerName,
    };
    if(layerName.toLowerCase().includes("image")){
      dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
      handleFloorPlanDeletionFromLayersUI(layerData, storeyValue);
    }
    else if (layerName.includes("buildings")) {
      deleteNeighborhoodLayer(layerData, storeyValue);
    }
    else if(layerName.includes("terrain")){
      dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
      terrainGeneration.handleTerrainDeletionFromLayersUI(layerData, storeyValue);
    }
    else if (layerName.includes("cad")){
      dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
      deleteCadLayer(layerData, storeyValue);
    }
  };

  let handleHeightMapToggle = (layerId, storeyValue, operation) => {
    let layer = layerView.getLayerFromLayerId(
      store.activeLayer.structure_id,
      storeyValue,
      layerId
    );
    if (layer) {
      if (operation) {
        dispatch(
          enableOrDisableHeightMap({
            layerId: layerId,
            storeyValue: storeyValue,
            operation: operation,
          })
        );
      } else {
        dispatch(
          enableOrDisableHeightMap({
            layerId: layerId,
            storeyValue: storeyValue,
            operation: operation,
          })
        );
      }
    }
    terrainGeneration.enableOrDisableHeightMap(
      layerId,
      storeyValue,
      store.activeLayer.structure_id,
      operation
    );
  };

  let handleActiveLayerSelection = (layerId, storeyValue) => {
    if (
      store.activeLayer.id !== layerId &&
      store.activeLayer.storey === storeyValue
    ) {
      setSelectedLayer(layerId);
      let layer = layerView.getLayerFromLayerId(
        store.activeLayer.structure_id,
        storeyValue,
        layerId
      );
      if (layer) {
        setActiveLayerAndRecord(layer);
      } else {
        let structure =
          StructureCollection.getInstance().getStructures()[
            store.activeLayer.structure_id
          ];
        let str = structure.getStoreyData().getStoreyByValue(storeyValue);
        if (str) {
          setActiveLayerAndRecord(
            str.layerData.getLayerByName("wall", storeyValue)
          );
        }
      }
    }
  };

  return (
    <EachRow
      key={key}
      onClick={() => {
        handleActiveLayerSelection(item.id, item.storey);
      }}
    >
      <Flex>
        <img
          src={item.image}
          alt="imagePic"
          className="layerRowImage"
          style={isSelected ? { filter: "brightness(50%)" } : {}}
        />
        <p style={{ fontSize: "9px", color: `${isSelected ? "#2D2D2E" : ""}` }}>
          {item.title}
        </p>
      </Flex>
      <Flex alignItems="center">
        <div style={{ padding: "7px" }}>
          {dimBulb ? (
            <img
              className="layerIcon"
              src={dim_bulb}
              alt="show/hide"
              onClick={() => {
                handleShowOrHideLayer(item.id, item.storey, "show");
              }}
            />
          ) : (
            <img
              className="layerIcon"
              src={Bulb0}
              alt="show/hide"
              onClick={() => {
                handleShowOrHideLayer(item.id, item.storey, "hide");
              }}
            />
          )}
        </div>

        <img
          className="layerIcon"
          src={deleteIcon}
          alt="delete"
          onClick={() => {
            handleDeleteLayer(item.id, item.title, item.storey);
          }}
        />

        {/*{item.title.includes("cad") && (*/}
        {/*  <div style={{ padding: "7px" }}>*/}
        {/*    <img*/}
        {/*      className="layerIcon"*/}
        {/*      src={deleteIcon}*/}
        {/*      alt="delete"*/}
        {/*      onClick={() => {*/}
        {/*        handleDeleteLayer(item.id, item.title, item.storey);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*{item.title.includes("buildings") && (*/}
        {/*  <div style={{ padding: "7px" }}>*/}
        {/*    <img*/}
        {/*      className="layerIcon"*/}
        {/*      src={deleteIcon}*/}
        {/*      alt="delete"*/}
        {/*      onClick={() => {*/}
        {/*        handleDeleteLayer(item.id, item.title, item.storey);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {item.title.includes("terrain") && (
          <div
            onClick={() => setHeightMapToggle((prevState) => !prevState)}
            style={{ padding: "7px" }}
          >
            {heightMapToggle ? (
              <img
                className="layerIcon"
                src={heightMapToggleOn}
                onClick={() => {
                  handleHeightMapToggle(item.id, item.storey, false);
                }}
              />
            ) : (
              <img
                className="layerIcon"
                src={heightMapToggleOff}
                onClick={() => {
                  handleHeightMapToggle(item.id, item.storey, true);
                }}
              />
            )}
          </div>
        )}

        {/*<div onClick={() => setLockOpen((prevState) => !prevState)}>*/}
        {/*  {lockOpen ? (*/}
        {/*    <img className="layerIcon" src={lock_open} alt="lock_open" />*/}
        {/*  ) : (*/}
        {/*    <img className="layerIcon" src={lock_close} alt="lock_close" />*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  onClick={() =>*/}
        {/*    setShowEditExpansion((prevState) => {*/}
        {/*      if (prevState) return null;*/}
        {/*      else return item;*/}
        {/*    })*/}
        {/*  }*/}
        {/*>*/}
        {/*  <img className="layerIcon" src={dropArrow} alt=">" />*/}
        {/*</div>*/}
      </Flex>
    </EachRow>
  );
};

const ExpandedSubView = (props) => {
  const {
    setAddNewLayer,
    addNewLayer,
    setShowEditExpansion,
    showEditExpansion,
    storeyLayers
  } = props;
  const [dimBulb, setDimBulb] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(null);

  useEffect(() => {
    if(store.activeLayer && !(["wall", "rough"].includes(store.activeLayer.name))){
      setSelectedLayer(store.activeLayer.id);
    }
    return () => {}
  }, []);

  const handleLayerSelect = (layerId) => {
    // if(selectedLayer === layerId) setSelectedLayer(null);
    // else setSelectedLayer(layerId);
    setSelectedLayer(layerId)
  }
  return (
    <SecondaryExpansionContainer>
      <ExpandedMenuHeader>
        <p className="heading">Layers</p>
        {/*{!showEditExpansion && !addNewLayer && (*/}
        {/*  <p*/}
        {/*    className="headingPlus"*/}
        {/*    onClick={() => setAddNewLayer((prevState) => !prevState)}*/}
        {/*  >*/}
        {/*    +*/}
        {/*  </p>*/}
        {/*)}*/}
      </ExpandedMenuHeader>
      <Divider />
      {showEditExpansion ? (
        <SecondExpandedMenu>
          {showEditExpansion &&
            showEditExpansion.subView &&
            showEditExpansion.subView.allIcons && (
              <EachRow>
                <Flex>
                  <img
                    src={showEditExpansion.image}
                    alt="imagePic"
                    className="layerRowImage"
                  />
                  <p>{showEditExpansion.title}</p>
                </Flex>
                <Flex
                  alignItems="center"
                  alignSelf="baseline"
                  flexDirection="column"
                >
                  <Flex>
                    <div onClick={() => setDimBulb((prevState) => !prevState)}>
                      {dimBulb ? (
                        <img
                          className="layerIcon"
                          src={dim_bulb}
                          alt="show/hide"
                        />
                      ) : (
                        <img
                          className="layerIcon"
                          src={Bulb0}
                          alt="show/hide"
                        />
                      )}
                    </div>
                    <div onClick={() => setShowEditExpansion(null)}>
                      <img
                        className="layerIcon"
                        src={lock_close}
                        alt="lock_close"
                      />
                    </div>
                    <div onClick={() => setShowEditExpansion(null)}>
                      <img className="layerIcon" src={dropOpenArrow} alt=">" />
                    </div>
                  </Flex>
                  <Flex>
                    <img className="layerIcon" src={pointer} alt="pointer" />
                    <img className="layerIcon" src={copy} alt="copy" />
                    <img className="layerIcon" src={deleteIcon} alt="delete" />
                  </Flex>
                </Flex>
              </EachRow>
            )}

          {showEditExpansion &&
            showEditExpansion.subView &&
            showEditExpansion.subView.thicknessSelector && (
              <EachRow margin="0.625rem 0">
                <Flex justifyContent="space-between" width="50%">
                  <Flex style={{ width: "1.5rem" }} />
                  <p className="dimText">Thickness</p>
                </Flex>
                <Flex justifyContent="space-between">
                  <CircularWrapper>
                    <CircularThickness width="0.266rem" height="0.266rem" />
                  </CircularWrapper>

                  <CircularWrapper>
                    <CircularThickness width="0.383rem" height="0.383rem" />
                  </CircularWrapper>
                  <CircularWrapper>
                    <CircularThickness width="0.472rem" height="0.472rem" />
                  </CircularWrapper>
                </Flex>
              </EachRow>
            )}
          {showEditExpansion &&
            showEditExpansion.subView &&
            showEditExpansion.subView.colorPicker && (
              <EachRow alignItems="center" margin="0.625rem 0">
                <Flex justifyContent="space-between" width="50%">
                  <Flex style={{ width: "1.5rem" }} />
                  <p className="dimText">Colour</p>
                </Flex>
                <Flex>
                  <CircularWrapper backgroundColor={colors.red} />
                  <p className="dimColorText">#HS1242</p>
                </Flex>
              </EachRow>
            )}
          {showEditExpansion &&
            showEditExpansion.subView &&
            showEditExpansion.subView.heightToggle && (
              <EachRow alignItems="center" margin="1rem 0">
                <Flex justifyContent="space-between" width="50%">
                  <Flex style={{ width: "1.5rem" }} />
                  <p className="dimText">Height</p>
                </Flex>
                <Flex>
                  <div className="toggleContainer">
                    <LabelWithToggle
                      item={{ title: "Off", secondtitle: "On" }}
                    />
                  </div>
                </Flex>
              </EachRow>
            )}
        </SecondExpandedMenu>
      ) : (
        <SecondExpandedMenu>
          <div style={{ margin: "0 0.3125rem" }}>
            {addNewLayer && (
              <EachRow style={{ paddingTop: "0.375rem" }}>
                <Flex>
                  <img
                    src={editLayer}
                    alt="imagePic"
                    className="newLayerImage"
                  />
                  <Input
                    type={"text"}
                    fontSize="8px"
                    required
                    placeholder="Enter Layer Name"
                    onPress={(e) => {}}
                    labelStyle={{ display: "none" }}
                    customInputContainerStyle={{
                      margin: "0.15rem 0.3125rem",
                    }}
                    customInput={{
                      height: "1.375rem",
                      fontSize: "0.5rem",
                      width: "7.125rem",
                      fontWeight: 400,
                    }}
                  />
                </Flex>
              </EachRow>
            )}
            {storeyLayers.map((item, index) => {
              return (
                <LayerRow
                  key={index}
                  item={item}
                  setShowEditExpansion={setShowEditExpansion}
                  showEditExpansion={showEditExpansion}
                  isSelected={item.id === selectedLayer}
                  setSelectedLayer={handleLayerSelect}
                />
              );
            })}
          </div>
        </SecondExpandedMenu>
      )}
    </SecondaryExpansionContainer>
  );
};

const LayeredExpandPopup = (props) => {
  const dispatch = useDispatch();
  const { storeyIndex, storeyValue, storeyHeight, isHidden, layers, handleStoreyPropertyChange, onSelectStorey, setSelectedStorey } = props;
  const [showInitialExpansion, setShowInitialExpansion] = useState(false);
  const [showSecondaryExpansion, setShowSecondaryExpansion] = useState(false);
  const [showEditExpansion, setShowEditExpansion] = useState(null);
  const [addNewLayer, setAddNewLayer] = useState(false);
  return (
    <MainWrapper
      style={{
        right: showInitialExpansion ? "-12.5rem" : "-0.5rem",

        backgroundColor: showInitialExpansion
          ? colors.fullWhite
          : colors.transparent,
      }}
    >
      <div
        onClick={() => {
          const { onPress, onSelectLayer } = props;

          setShowInitialExpansion((prevState) => {
            // if (onPress && prevState) onPress(null);
            // setSelectedStorey(storeyValue);
            // onSelectLayer(storeyValue);
            return !prevState;
          });
        }}
      >
        <img src={circularExpand} alt=">" />
      </div>
      {showInitialExpansion && (
        <div style={{ position: "relative" }}>
          <ExpandedMenu>
            <Input
              type={"text"}
              required
              value={storeyHeight}
              onPress={(e) => {handleStoreyPropertyChange(storeyValue, "height", e.target.value)}}
              onBlur={() => {dispatch(updateHeight({ storeyValue, storeyHeight }))}}
              keyPress={(e) => {
                if (e.keyCode == 13) {
                  dispatch(updateHeight({ storeyValue, storeyHeight }))
                }
              }}
              labelStyle={{ display: "none" }}
              customInputContainerStyle={{ margin: "0 0.3125rem" }}
              customInput={{
                width: "2.9375rem",
                height: "1.375rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            />
            <ImageButton onClick={() => {handleStoreyPropertyChange(storeyValue, "hidden", !isHidden)}}>
              <img src={show_hide} alt="show/hide" className={isHidden ? "" : "selected_icon"} />
            </ImageButton>
            <ImageButton onClick={() => {onSelectStorey(storeyValue)}}>
              <img src={selectAll} alt="selectAll" />
            </ImageButton>
            {/* <ImageButton
              onClick={() =>
                setShowSecondaryExpansion((prevState) => !prevState)
              }
            >
              <img
                src={showInitialExpansion ? dropArrow : dropOpenArrow}
                alt="dropdown"
              />
            </ImageButton> */}
          </ExpandedMenu>
          {/* {showSecondaryExpansion && (
            <ExpandedSubView
              setAddNewLayer={setAddNewLayer}
              addNewLayer={addNewLayer}
              showEditExpansion={showEditExpansion}
              setShowEditExpansion={setShowEditExpansion}
              storeyLayers={layers}
            />
          )} */}
        </div>
      )}
    </MainWrapper>
  );
};

export default LayeredExpandPopup;
