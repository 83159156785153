/*jshint esversion: 6 */
"use strict";
import BABYLON from "../babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { assignProperties } from "../../libs/sceneStateFuncs.js";
import { meshObjectMapping } from "./mapping.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { copyBrep } from "../../libs/brepOperations.js";
import { deepCopyObject } from "../extrafunc.js";
import { SnapProperty } from "../../libs/GUI/snap_properties.js";
import { SnapElement } from "../../libs/GUI/snap_element.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import {
  prepareDataForCMD,
  addMaterialToLayers,
  getMaterialCommandLogic,
  getApplyMaterialSaveData,
} from "../../libs/applyMaterialFuncs.js";
import { Command } from "../commandManager/Command.js";
import { StructureCollection } from "./structure.ds.js";
import { massProperties } from "../../libs/objectProperties.js";
import { BasicMassComp } from "../../libs/basicCompProperties.js";
import { materialLoader } from "../../libs/mats.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import {DisplayOperation} from "../displayOperations/displayOperation";
import { getFaceIdFromFacet, getFaceVerticesFromFace } from "../../libs/brepOperations.js"
import { getNormalVector } from "../../libs/mathFuncs"
import { getPointsOnArc } from "../../libs/twoD/twoDrawCircle.js"

/**
 * { Mass datastructure }
 *
 * @class      Mass (name)
 * @param      {Object}  mesh    The mesh
 */
var Mass = function (mesh) {
  this.mesh = mesh;
  this.level = mesh.level;
  this.level_low = 0;
  this.level_hight = 0;
  this.height = 0;
  this.elevation = 0;
  this.heightFromFloor = null; // applicable for ceiling
  this.storey = mesh.storey;
  this.structure_id = mesh.structure_id;
  this.id = "m_" + Math.random().toString(36).substr(2, 9);
  this.type = "Mass";
  this.mesh.type = "Mass";
  this.profile = "";
  this.material = "";
  this.onclick = null;
  this.groupId = null;
  this.far_included = true;
  this.heightChanged = false;
  this.room_curve = false;
  this.room_type = mesh.room_type || "Default";
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  this.massType = "Room";
  this.edited = false;
  this.dependantMass = false;
  this.properties = jQuery.extend({}, massProperties);
  this.properties._type = "Mass";
  this.properties._id = this.mesh.room_id;
  this.properties._level = this.mesh.level;
  this.properties._indices = this.mesh.room_path;
  this.properties._curve = this.mesh.room_curve;
  this.properties._layers = [];
  this.properties._components = BasicMassComp;
  this.typeChangeAllowed = true;
  this.faceFacetMapping = mesh.faceFacetMapping;
  this.brep = mesh.brep;
  this._isCircularMass = mesh.isCircular || false;
  this.revitMetaData = {};
  this.isLocked = false;
  //this datastructure represents a mapping between edges and halfedges for curves
  this.geomRepMapHalfEdgesEdges = null;  
  this.geomRepRevMapEdgesHalfEdges = null;  
  delete mesh.brep;
  delete mesh.faceFacetMapping;

  this.revitMetaData = {}

  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  this.getNormal = (faceId='1') => {
    let face = this.brep.getFaces()[faceId];
    let faceVertices = getFaceVerticesFromFace(face, this.mesh, BABYLON.Space.WORLD);

    let normalVector = getNormalVector(faceVertices);

    let largest = Math.max(...normalVector.map(e => Math.abs(e)));
    let normalizedVector = new BABYLON.Vector3(normalVector[0] / largest, normalVector[1] / largest, normalVector[2] / largest);
    return normalizedVector;
  }

  this.getRadiusAndCenter = (facetId) => {
    if (!this.isCircularMass()) return;

    let faceId = getFaceIdFromFacet(facetId, this.mesh);
    let face = this.brep.getFaces()[faceId];
    let faceVertices = getFaceVerticesFromFace(face, this.mesh, BABYLON.Space.WORLD);

    let firstPoint = faceVertices[0];
    let farthestPoint = faceVertices[0]

    let farthestDistance = BABYLON.Vector3.Distance(firstPoint, farthestPoint);
    for (let point of faceVertices) {
      let distance = BABYLON.Vector3.Distance(firstPoint, point);
      if (distance > farthestDistance) {
        farthestDistance = distance;
        farthestPoint = point;
      }
    }

    return [ farthestDistance / 2, BABYLON.Vector3.Center(firstPoint, farthestPoint) ];
  }

  this.getPointsForCircle = (facetId, minPoints = 4) => {

    let [radius, center] = this.getRadiusAndCenter(facetId);
    let normal = this.getNormal();

    let points = getPointsOnArc(center, radius, normal, false, minPoints);

    points.push(center)

    return points;
  };

  this.onMove = {
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (evt) {
      //pass
    },

    onPointerMove: function (direction, diff, speed) {
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.children) {
        this.mesh.children.forEach((child) => {
          child.position[direction] += diff[direction] * speed;
        });
      }
    },
  };

  this.assignProperties = function (options) {
    // this.mesh.meshToBrep();
    if (!this.mesh.isAnInstance && !this.mesh.material) {
      this.mesh.material = store.scene.getMaterialByName("solid_mat");
    }
    if (options?.compData) {
      this.properties._components = options.compData;
      assignProperties(this.mesh, -1, "mass", options.compData);
    } else {
      assignProperties(this.mesh, -1, "mass");
    }
    this.mesh.childrenComp = [];
  };
  // this.mesh.onDispose = function(mesh){
  //     GLOBAL_CONSTANTS.deleteMeshFromStructure(mesh).then(function(){
  //           log.warn("Yaahhh, it worked");
  //     });
  // };
};
/**
 * Remove a mass to level.
 *
 * @param      {<type>}  object  The object
 */
Mass.prototype.removeMassFromLevel = function (level) {
  meshObjectMapping.deleteMapping(this.mesh);
  for (let i = 0; i < level.flyweight.masses.length; i++) {
    if (this.mesh.uniqueId === level.flyweight.masses[i].mesh.uniqueId) {
      level.flyweight.masses.splice(i, 1);
      return;
    }
  }
};
Mass.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
Mass.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
Mass.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};

Mass.prototype.isCircularMass = function () {
  return !!this._isCircularMass;
};

Mass.prototype.setIsCircularMass = function (isCircular) {
  this._isCircularMass = isCircular;
};

Mass.prototype.isTypeChangeAllowed = function () {
  return this.typeChangeAllowed;
};

Mass.prototype.allowTypeChange = function () {
  this.typeChangeAllowed = true;
};

Mass.prototype.disallowTypeChange = function () {
  this.typeChangeAllowed = false;
};

Mass.prototype.updateHeight = function (oldHeight, newHeight) {
  let _this = this;
  if(this.mesh?.name?.includes("terrain")){
    return;
  }
  if (!this.isEdited() && !this.mesh.parent) {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;
    let previousScaling = this.mesh.scaling.clone();

    if (
      boundingBox.extendSizeWorld.y * 2 >
      StoreyMutation._CONSTANTS.balconyHeight
    ) {
      let children = this.mesh.getChildren();

      children.forEach(function (child) {
        child.setParent(null);
      });
      this.mesh.scaling.y = previousScaling.y * (newHeight / oldHeight);
      let scaleDiff = this.mesh.scaling.y - previousScaling.y;

      if (previousScaling.y > 0) {
        if (this.storey > 0) {
          this.mesh.position.y += scaleDiff * boundingBox.extendSize.y;
        } else {
          this.mesh.position.y -= scaleDiff * boundingBox.extendSize.y;
        }
      } else {
        if (this.storey > 0) {
          this.mesh.position.y -= scaleDiff * boundingBox.extendSize.y;
        } else {
          this.mesh.position.y += scaleDiff * boundingBox.extendSize.y;
        }
      }

      children.forEach(function (child) {
        child.setParent(_this.mesh);
      });
    }
  }
};
Mass.prototype.updateBase = function (heightDifference) {
  function isOfBalconyHeightOrLess() {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;
    return (
      boundingBox.extendSizeWorld.y * 2 <=
      StoreyMutation._CONSTANTS.balconyHeight
    );
  }

  if (this.isEdited()) return;
  if (this.mesh.parent) return;
  if (this.storey > 0) {
    this.mesh.position.y += heightDifference;
  } else {
    this.mesh.position.y -= heightDifference;
  }
};
/**
 * Clone Mass Object
 *
 * @param      {Object}  argument  The argument
 */
Mass.prototype.clone = function () {
  let mesh = this.mesh.createInstance(this.mesh.name);
  let massClone = new Mass(mesh);
  // for (let key in massClone){
  //     if ((typeof(massClone[key]) !== "function") && (key !== "mesh")){
  //         massClone[key] = JSON.parse(JSON.stringify(this[key]));
  //     }
  // }
  //console.log(massClone);
  // massClone.mesh = mesh;
  return massClone;
};
/**
 * { function_description }
 *
 * @param      {Object}  argument  The argument
 */
Mass.prototype.notify = function (operation = null) {
  if (!operation) {
    //log.warn(this.level_low, this.level_high);
  }
};

/**
 * Gets the lowest upper coordinate.
 *
 * @return     {number}  The lowest upper coordinate.
 */
Mass.prototype.getLowestUpperCoord = function () {
  let verdata = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbinfo = this.mesh.getBoundingInfo();
  let mid =
    this.mesh.midY ||
    (bbinfo.boundingBox.maximumWorld.y + bbinfo.boundingBox.minimumWorld.y) / 2;
  let temp = [],
    wmtrix = this.mesh.getWorldMatrix();
  for (let i = 0; i < verdata.length; i += 3) {
    let point = new BABYLON.Vector3(verdata[i], verdata[i + 1], verdata[i + 2]);
    let nepoint = BABYLON.Vector3.TransformCoordinates(point, wmtrix);
    if (nepoint.y > mid) {
      temp.push(nepoint.y);
    }
  }
  return _.min(temp);
};
/**
 * Gets the highest lower coordinate.
 *
 * @return     {number}  The highest lower coordinate.
 */
Mass.prototype.getHighestLowerCoord = function () {
  let verdata = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbinfo = this.mesh.getBoundingInfo();
  let mid =
    this.mesh.midY ||
    (bbinfo.boundingBox.maximumWorld.y + bbinfo.boundingBox.minimumWorld.y) / 2;
  let temp = [],
    wmtrix = this.mesh.getWorldMatrix();
  for (let i = 0; i < verdata.length; i += 3) {
    let point = new BABYLON.Vector3(verdata[i], verdata[i + 1], verdata[i + 2]);
    let nepoint = BABYLON.Vector3.TransformCoordinates(point, wmtrix);
    if (nepoint.y < mid) {
      temp.push(nepoint.y);
    }
  }
  return _.max(temp);
};

/**
 * Updates the thickness in the layers of the Mass
 * Invoke this function before material schedule is calculated or
 * When the Mass' layers are accessed
 */

Mass.prototype.setThickness = function () {
  /*
    TODO: write the logic to compute the thickness
     */
};

Mass.prototype.cloneProperties = function (otherMass, unique = true) {
  otherMass.room_type = this.room_type;
  otherMass.edited = this.edited;
  otherMass.dependantMass = this.dependantMass;
  if (unique) {
    otherMass.brep = copyBrep(this.brep);
    otherMass.faceFacetMapping = deepCopyObject(this.faceFacetMapping);
    if (!otherMass.mesh.isAnInstance) {
      otherMass.mesh.makeGeometryUnique();
      if (this.isEdited()) otherMass.markAsEdited();
    }
  } else {
    otherMass.brep = this.brep;
    otherMass.faceFacetMapping = this.faceFacetMapping;
  }

  otherMass.massType = this.massType;
  otherMass.typeChangeAllowed = this.typeChangeAllowed;
  otherMass._isCircularMass = this._isCircularMass;
  otherMass.heightFromFloor = this.heightFromFloor;

  if (this.mesh.type === "Void") {
    otherMass.type = this.mesh.type;
    otherMass.mesh.type = this.mesh.type;
  }
};

Mass.prototype.getBoundaryCoords = function () {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbInfo = this.mesh.getBoundingInfo();
  let minY = bbInfo.boundingBox.minimumWorld.y;
  let verDataArr = [];

  for (let i = 0; i < verData.length; i += 3) {
    let vec = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
    vec = BABYLON.Vector3.TransformCoordinates(vec, this.mesh.getWorldMatrix());
    vec = new BABYLON.Vector3(
      Math.round(vec.x * 100) / 100,
      Math.round(vec.y * 100) / 100,
      Math.round(vec.z * 100) / 100
    );
    if (vec.y === Math.round(minY * 100) / 100) {
      verDataArr.push(vec);
    }
  }

  verDataArr = verDataArr.filter(
    (vec, index, self) =>
      index === self.findIndex((t) => t.x === vec.x && t.z === vec.z)
  );

  // let firstVertex = verDataArr.splice(0, 1);
  // let lastVertex = verDataArr.pop();
  // verDataArr.unshift(lastVertex);
  // verDataArr.unshift(firstVertex[0]);

  // Disabling flipCheck as flip for masses done using brep and not mesh.scaling
  // let flipCheck = false;
  // for (let axis in this.mesh.scaling) {
  //     if (this.mesh.scaling[axis] < 0) {
  //         flipCheck = true;
  //         break;
  //     }
  // }
  verDataArr.reverse();
  return verDataArr;
};
/**
 * Make object visible in store.scene
 */
Mass.prototype.show = function () {
  this.mesh.isVisible = true;
};
/**
 * Hides object in store.scene
 */
Mass.prototype.hide = function () {
  this.mesh.isVisible = false;
};
/**
 * Function to check if mass is of default roomType
 * @returns {boolean}
 */
Mass.prototype.hasNonDefaultRoomType = function () {
  if (!this.room_type) return false;
  if (this.mesh.type.toLowerCase().indexOf("throwaway") !== -1) return false;
  if (this.massType !== "Room") return false;
  return this.room_type.toLowerCase() !== "default";
};
/**
 * Notifies all.
 */
Mass.prototype.notifyAll = function (action) {
  if (action === "resetlevel") {
    return MASS_NOTIFY_FUNCTIONS.resetLevel.call(this);
  }
};

Mass.prototype.getMeshObjectProperties = function () {
  let props = [];
  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);
  let meshDimensionsSnapDim = objectPropertiesView.getMeshDimensions(this.mesh, {valueInSnapDim: true});

  let componentCategory = "Mass";
  if (this.mesh.type.toLowerCase() === "void") componentCategory = "Void";

  props.push(
    new SnapProperty(
      "Category",
      componentCategory,
      SnapElement.getInputElement(null, false)
    )
  );

  // If Mass Type is Plinth, it cannot be changed
  if (this.massType === "Plinth") {
    props.push(
      new SnapProperty(
        "Type",
        this.massType,
        SnapElement.getInputElement(null, false)
      )
    );
  } else if (this.isTypeChangeAllowed()) {
    props.push(
      new SnapProperty(
        "Type",
        this.massType,
        SnapElement.getDropDown(
          objectPropertiesView.getTypeOptionsForObject(this),
          (meshes, name) => objectPropertiesView.onTypeChange(this.mesh, name),
          true
        )
      )
    );
  } else if (componentCategory !== "Void") {
    props.push(
      new SnapProperty(
        "Type",
        this.massType,
        SnapElement.getInputElement(null, false)
      )
    );
  }

  if (this.massType.toLowerCase() === "room" || this.massType.toLowerCase() === "column" || this.massType.toLowerCase() === "beam") {
    props.push(
      new SnapProperty(
        "Label",
        this.mesh.room_type,
        SnapElement.getInputElement(
          (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
          true
        )
      )
    );
  }
  if (this.massType.toLowerCase() === "building") {
    props.push(
      new SnapProperty(
        "Label",
        this.mesh.room_type,
        SnapElement.getInputElement(
          (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
          false
        )
      )
    );
  }

  if (componentCategory === "Void") {
    if (parseFloat(meshDimensionsSnapDim.breadth) > parseFloat(meshDimensionsSnapDim.depth)){
      props.push(new SnapProperty("Length",meshDimensions.breadth,SnapElement.getInputElement((meshs,value) =>objectPropertiesView.dimensionChange("width",value,meshDimensionsSnapDim.breadth, {oldValueinSnapUnits: true}),true)));
      props.push(new SnapProperty("Thickness",meshDimensions.depth,SnapElement.getInputElement((meshs,value) =>objectPropertiesView.dimensionChange("thickness",value,meshDimensionsSnapDim.depth, {oldValueinSnapUnits: true}),true)));
    }
    else{
      props.push(new SnapProperty("Length",meshDimensions.depth,SnapElement.getInputElement((meshs,value) =>objectPropertiesView.dimensionChange("width",value,meshDimensionsSnapDim.depth, {oldValueinSnapUnits: true}),true)));
      props.push(new SnapProperty("Thickness",meshDimensions.breadth,SnapElement.getInputElement((meshs,value) =>objectPropertiesView.dimensionChange("thickness",value,meshDimensionsSnapDim.breadth, {oldValueinSnapUnits: true}),true)));
    }
  }
  else {
    if (this.isCircularMass()) {
      const callback = (_meshes, value, _options) => {
        return objectPropertiesView.radiusChange(value, meshDimensionsSnapDim.radius, { oldValueinSnapUnits: true })
      };
      let snapElement = SnapElement.getInputElement(callback, true)
      let radiusProperty = new SnapProperty("Radius", meshDimensions.radius, snapElement)

      props.push(radiusProperty);
    } else {
      let lengthCallback = (meshs, value, option) => objectPropertiesView.dimensionChange("width", value, meshDimensionsSnapDim.breadth, { oldValueinSnapUnits: true });
      let lengthSnapElement = SnapElement.getInputElement(lengthCallback, true);
      let lengthProperty = new SnapProperty("Length", meshDimensions.breadth, lengthSnapElement);

      props.push(lengthProperty);
      props.push(new SnapProperty("Width", meshDimensions.depth, SnapElement.getInputElement((meshs, value) => objectPropertiesView.dimensionChange("thickness", value, meshDimensionsSnapDim.depth, { oldValueinSnapUnits: true }), true)));
    }
  }

  if (this.massType.toLowerCase() === "ceiling"){
    props.push(new SnapProperty("Thickness",
      meshDimensions.height,
      SnapElement.getInputElement(
        (meshs,value) => objectPropertiesView.dimensionChange(
          "height",
          value,
          meshDimensionsSnapDim.height,
          {oldValueinSnapUnits: true}),
        true
      )
    ));

    props.push(new SnapProperty("Height",
      DisplayOperation.convertToDefaultDimension(this.heightFromFloor),
      SnapElement.getInputElement(
        (meshs,value) => objectPropertiesView.heightChange(
          value,
          this.heightFromFloor,
          {oldValueinSnapUnits: true}),
        true)
    ));
  }
  else {
    props.push(new SnapProperty("Height",
      meshDimensions.height,
      SnapElement.getInputElement(
        (meshs,value) => objectPropertiesView.dimensionChange(
          "height",
          value,
          meshDimensionsSnapDim.height,
          {oldValueinSnapUnits: true}),
        true)
    ));
  }
  let storey = StructureCollection.getInstance().getStructureById(this.mesh.structure_id).getStoreyData().getStoreyByValue(this.mesh.storey).name;
  let storeyValue = storey? storey : this.mesh.storey;

  props.push(
    new SnapProperty(
      "Storey",
      storeyValue,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Surface Area",
      meshDimensions.area,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Volume",
      meshDimensions.volume,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(new SnapProperty("object-buttons", this.mesh, null, true));

  return props;
};

Mass.prototype.updateDefaultMaterial = function () {
  let mesh = this.mesh.sourceMesh || this.mesh;
  let applyMaterialCommandData = {};
  let roomType = ScopeUtils.getRoomType(this.room_type);
  roomType = roomType.replace(/\s/g, "");
  let mat;

  applyMaterialCommandData._prevData = prepareDataForCMD(mesh);

  let functionType = "load" + roomType + "Material";

  if (functionType in materialLoader) {
    mat = materialLoader[functionType]();
  } else {
    mat = store.scene.getMaterialByName("solid_mat");
    mesh.material = mat;
  }

  let mat_index;
  if (mesh.material.subMaterials) {
    var multimatSubs = mesh.material.subMaterials;
    var mat_exist_flag = false;
    var mat_exist_index = -1;
    for (var p = 0; p < mesh.material.subMaterials.length; p++) {
      if (mesh.material.subMaterials[p].id === mat.id) {
        mat_exist_flag = true;
        mat_exist_index = p;
      }
    }
    if (!mat_exist_flag) {
      mesh.material.subMaterials.push(mat);

      mat_index = multimatSubs.length - 1;
    } else {
      mat_index = mat_exist_index;
    }
  } else if (mat.name === "solid_mat") {
    mat_index = 0;
  } else {
    let id = Math.random().toString(36).substr(2, 6) + "_";
    var multimat = new BABYLON.MultiMaterial(mat.name + id, store.scene);
    multimat.subMaterials.push(mesh.material);
    multimat.subMaterials.push(mat);
    mesh.material = multimat;
    mat_index = multimat.subMaterials.length - 1;
  }

  // applyMaterialCommandData._prevData = prepareDataForCMD(mesh);
  mesh.subMeshes.forEach(function (subMesh) {
    subMesh.materialIndex = mat_index;
  });
  if (this.brep) {
    let faces = this.brep.getFaces();
    faces.forEach(function (face) {
      face.materialIndex = mat_index;
    });
  }
  addMaterialToLayers(mesh, mat_index);
  applyMaterialCommandData._newData = prepareDataForCMD(mesh);
  mesh.synchronizeInstances();

  return new Command(
    "applyDefaultRoomTypeMaterial",
    applyMaterialCommandData,
    getMaterialCommandLogic(),
    getApplyMaterialSaveData
  );
};

Mass.TYPES = {
  DEPENDENT: ["Beam", "Pergola", "Sunshade", "Mass"],
  INDEPENDENT_ALL_EDITS: ["Column", "Mass", "Furniture", "Building"],
  INDEPENDENT_LIMITED_EDITS: ["Room", "Beam", "Ceiling"],
};

Mass.ALL_TYPES = _.uniq([
  ...Mass.TYPES.DEPENDENT,
  ...Mass.TYPES.INDEPENDENT_LIMITED_EDITS,
  ...Mass.TYPES.INDEPENDENT_ALL_EDITS,
]);

/**
 * Create a Plinth under the drawn Mass
 * @param {} massMesh
 */
Mass.drawPlinth = function (massMesh) {
  return;
  /*
    // let plinthMesh = massMesh.createInstance("Plinth");
    let plinthMesh = pasteObject(massMesh, {uniqueObject: false, handleChildren: false});
    plinthMesh.type = "Mass";

    // let level = StructureCollection.getInstance().getStructureById(massMesh.structure_id).getLevelByUniqueId(massMesh.getSnaptrudeDS().level_id);
    // plinthMesh.structure_id = massMesh.structure_id;
    // plinthMesh.storey = 1;
    // level.addMeshToLevel(plinthMesh, false);

    let plinthHeight = projectProperties.properties.plinthHeightProperty.getValue();
    DisplayOperation.updateDimensionScale(plinthHeight, "height", DisplayOperation.getOriginalDimension(objectPropertiesView.getMeshDimensions(plinthMesh).height), plinthMesh, {returnCommand: true});
    plinthMesh.position.y = - plinthHeight / 2;

    let plinth = plinthMesh.getSnaptrudeDS();
    plinth.massType = "Plinth";

    plinthMesh.setParent(massMesh);
    massMesh.childrenComp.push(plinthMesh);

    // let mass = massMesh.getSnaptrudeDS();
    // plinth.brep = mass.brep;

    // mass.plinth = plinth;

    let commandData = commandUtils.creationOperations.getCommandData(plinthMesh);
    let creationCommand = commandUtils.creationOperations.getCommand(commandUtils.CONSTANTS.drawMassOperation, commandData);
    return creationCommand;
    */
};

Mass.isPlinth = function (mesh) {
  return (
    mesh.type.toLowerCase() === "mass" &&
    mesh.getSnaptrudeDS().massType === "Plinth"
  );
};

/**
 * { function_description }
 *
 * @class      MASS_NOTIFY_FUNCTIONS (name)
 * @return     {Object}  { description_of_the_return_value }
 */
var MASS_NOTIFY_FUNCTIONS = (function () {
  "use strict";
  var _resetLevelForMass = function () {
    let inst = StructureCollection.getInstance();
    let structure = inst.getStructureById(this.structure_id);
    let levels = structure.getAllLevels();
    let masses = [],
      stack = [],
      indexSearch = [],
      searchMap = {},
      level = {},
      ctr_low = 0,
      ctr_high = 1;
    for (let level_id in levels) {
      let level = levels[level_id];
      masses.push(level.getMasses());
    }
    //line sweep algo
    for (let mass of _.flatten(masses)) {
      mass.mesh.refreshBoundingInfo();
      let bbinfo = mass.mesh.getBoundingInfo();
      let low = Math.round(bbinfo.boundingBox.minimumWorld.y * 10) / 10;
      let high = Math.round(bbinfo.boundingBox.maximumWorld.y * 10) / 10;

      stack.push({ low: low, high: high, obj: mass });

      indexSearch.push(low);
      indexSearch.push(high);
    }

    stack.sort(function (a, b) {
      if (a.low === b.low) {
        return a.high - b.high;
      } else {
        return a.low - b.low;
      }
    });

    indexSearch.sort(function (a, b) {
      return a - b;
    });

    indexSearch = _.sortedUniq(indexSearch);

    for (let m = 0; m < indexSearch.length - 1; ) {
      if (
        indexSearch[m] < 0 ||
        Math.abs(indexSearch[m] - indexSearch[m + 1]) <= 0.1
      )
        indexSearch.splice(m, 1);
      else {
        m++;
      }
    }

    let start = 0;
    let end = stack[stack.length - 1].high;

    for (let j of indexSearch) {
      stack.forEach(function (element) {
        if (Math.abs(element.low - j) <= 0.1) {
          if (!searchMap.hasOwnProperty(element.obj.id))
            searchMap[element.obj.id] = { low: j, item: element };
          // throw "Error ! Case not possible";
          else console.warn("Error ! Case not possible");
        }
        if (Math.abs(element.high - j) <= 0.1) {
          if (searchMap.hasOwnProperty(element.obj.id)) {
            let item = searchMap[element.obj.id];
            let key =
              _.sortedIndexOf(indexSearch, item.low).toString() +
              ":" +
              _.sortedIndexOf(indexSearch, j).toString();
            if (!level.hasOwnProperty(key)) level[key] = [];
            level[key].push(item);
            delete searchMap[element.obj.id];
          } else {
            // throw "Error ! Case not possible";
            console.warn("Error ! Case not possible");
          }
        }
      });
    }

    //log.warn(level);
    return level;
  };

  return {
    resetLevel: _resetLevelForMass,
  };
})();
export { Mass, MASS_NOTIFY_FUNCTIONS };
