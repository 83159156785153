import { colors } from "../../themes/constant";

export const shareModal = {
  boxStyle: {
    border: "none",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
    minWidth: "8rem",
    paddingRight: 0,
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: colors.gray,
    textTransform: "capitalize"
  },
  selectedMainText: {
    color: colors.primeBlack,
    fontWeight: "bold",
  },
  customDropDownStyle: {
    padding: "0",
    borderRadius: "0.25rem",
    width: "10rem",
    marginLeft: "-1.8rem",
    position: "absolute",
    left: 0,
    top: 0,
    textTransform: "capitalize"
  },
  permsissionDropdown: {
    width: "20rem",
    position: "absolute",
    top: 0,
    padding: "0.5rem 0",
    fontSize: "0.875rem",
    borderRadius: "0.313rem",
    left: 0,
  },
  customInputContainerStyle: { width: "75%", height: "100%" },
  customSendInviteButtonStyle: { fontSize: "1rem" },
  customParentDivStyle: { margin: 0 },
};

export const recentBoxStyle = {
  boxStyle: {
    border: "none",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: colors.primeBlack,
    width: "100%",
  },
  customDropdownStyle: {
    position: "absolute",
    top: "-1.25rem",
    left: 0,
    right: 0,
    borderRadius: "0.313rem",
    paddingLeft: 0,
    maxHeight: "15rem",
    overflowY: "auto",
  },
};

export const cancelStyles = {
  ctaParent: { marginTop: "2.688rem" },
  navLink: { textDecoration: "none" },
  billingCustomButtonStyle: { background: colors.transparent },
  billingCustomButtonTextStyle: {
    color: colors.brightRed,
    marginLeft: "0.875rem",
  },
  cancelCustomButtonStyle: { width: "11.25rem", padding: "0.75rem 1.25rem" },
  modalBodyStyle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "2.5rem 7.125rem 2.875rem 7.125rem",
  },
  footerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
};

export const exportStyles = {
  exportCustomButtonStyle: {
    width: "auto",
    padding: "0.75rem 1.813rem",
    marginRight: "1.063rem",
    marginBottom: "0.875rem",
    marginTop: "0.75rem",
  },
  modalBodyStyle: { margin: "0.625rem 1.125rem 5rem 1.125rem" },
  footerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  progressStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
