import { drawWithGeometryData } from "./floor";
import { Color3 } from "babylonjs";
import _ from "lodash";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { store } from "../utilityFunctions/Store";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { onSolid } from "../extrafunc";
import { Locker } from "../locker/locker";
import { createCustomMeshFromRevitExport } from "./extraFunctions";
import reduxStore from "../../stateManagers/store/reduxStore";
import { updateStorey } from "../../stateManagers/reducers/objectProperties/storeysSlice";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { addFilter } from "../../stateManagers/reducers/objectProperties/meshSelection";
import { checkStorey } from "./storey";

// Store an array of all revit link names.
// Add to structureCollection metadata for scene reload
const filterSelectionRevitLinks = [];

const createRevitLinks = (revitLinksData, materialList) => {
  const revitLinks = [];
  for (const rvtId in revitLinksData) {
    const rvtData = revitLinksData[rvtId];

    // Update UI for revit link filter selection
    if (!filterSelectionRevitLinks.includes(rvtId)) {
      filterSelectionRevitLinks.push(rvtId);
      reduxStore.dispatch(
        addFilter({
          type: rvtId,
        })
      );
    }

    try {
      for (const type in rvtData) {
        const types = rvtData[type];
        for (const meshDataId in types) {
          const meshData = types[meshDataId];
          const data = meshData["data"];

          if (!_.isEmpty(meshData) && !_.isEmpty(data)) {
            const mesh = createCustomMeshFromRevitExport(
              data,
              meshData,
              materialList,
              { isMassType: true, category: getCategory(type) }
            );

            if (!mesh) continue;
            let revitLinkDS = new Mass(mesh);
            mesh.structure_id = store.activeLayer.structure_id;

            mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
            revitLinkDS.storey = 1;

            revitLinkDS.room_type = "Default";
            revitLinkDS.massType = "LinkedModel";
            // revitLinkDS.importType = "speckleRevitImport";
            revitLinkDS.revitMetaData = {
              elementId: data.elementId,
              revitLinkName: rvtId,
            };
            mesh.storey = 1;
            revitLinkDS.storey = 1;
            revitLinkDS.structure_id = store.activeLayer.structure_id;

            const structureCollection = StructureCollection.getInstance();
            const talkingAboutStructure = structureCollection.getStructureById(
              store.activeLayer.structure_id
            );
            const talkingAboutLevel =
              talkingAboutStructure.getLevelByName("01");
            talkingAboutLevel.addObjectToLevel(revitLinkDS, false);
            const storey = checkStorey(data?.levelName);
            if (storey) {
              storey.addElement(revitLinkDS);
            } else {
              StoreyMutation.assignStorey(revitLinkDS);
            }
            onSolid(mesh);

            revitLinks.push(mesh);
          }
        }
      }
    } catch (e) {
      console.warn(e, "Corrupt Revit Links data");
    }
  }
  return { revitLinks };
};

const getCategory = (type) => {
  const DEFAULT_TYPES = {
    WALL: "wall",
    FLOOR: "floor",
    ROOF: "roof",
    COLUMN: "column",
  };

  if (!type) return;
  type = type.toLowerCase();

  if (type.includes(DEFAULT_TYPES.WALL)) {
    return DEFAULT_TYPES.WALL;
  } else if (
    type.includes(DEFAULT_TYPES.FLOOR) ||
    type.includes(DEFAULT_TYPES.ROOF)
  ) {
    return DEFAULT_TYPES.ROOF;
  } else if (type.includes(DEFAULT_TYPES.COLUMN)) {
    return DEFAULT_TYPES.COLUMN;
  }

  return;
};

const getFilterSelectionRevitLinks = () => {
  return filterSelectionRevitLinks;
};

export { createRevitLinks, getFilterSelectionRevitLinks };
