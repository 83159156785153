import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import {
  createReferenceGround,
  createBackWall,
  disposeAllAxisLines,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import { isTwoDimension } from "./twoDimension.js";
import { getFaceArea, getActiveStoreyObject } from "../modules/extrafunc.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import {isFloatEqual, projectionOfPointOnLine} from "./snapFuncs.js";
import { StateMachine } from "../modules/Classes/StateMachine";
import {isDownArrowKey, isLeftArrowKey, isRightArrowKey, isUpArrowKey} from "./keyEvents";
import {diagnostics} from "../modules/diagnoses/diagnostics";
import snapEngine from "../modules/snappingEngine/snapEngine";
import {externalUtil} from "../modules/externalUtil";

function getTertiarySnapObject() {
  return {
    snapPoint: null,
    snapPointDash: null,
    snapType: colorUtil.type.black,
  };
}

// to identify uploaded sketch and terrain map
function isSnaptrudePlaneMesh(mesh){
  return mesh.name.includes("terrain")
      || mesh.name.includes(GLOBAL_CONSTANTS.strings.identifiers.uploadedSketch);
  // || mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.cadSketch)
}

function isCADMesh(mesh){
  return mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.cadSketch);
}

function initializeSnappingEngine(initialPoint) {
  store.snappingGlobalVariables.sessionVariables.inProgress = true;
  createReferenceGround(initialPoint);
  createBackWall(initialPoint);
}

function handleOptionsForAlternateSnaps(snapData, options){
  if (snapEngine.alternateSnaps.isActive()){
    options.doNotDoSecondaryScenePicks = true;
    options.attemptCadSnaps = snapData.snappedToCadVertex;
    options.pickInfo = snapData.pickInfo;
    if (snapData.snappedToFace){
      options.vertexSnap = false;
      options.edgeSnap = false;
    }
  }
}

function lockAxisSnapConstraintDirection(event){
  if (store.snappingGlobalVariables.sessionVariables.inProgress){
    if (isUpArrowKey(event) && !store.$scope.isTwoDimension) lockAxisSnapConstraintDirectionY();
    else if (isDownArrowKey(event) && !store.$scope.isTwoDimension) lockAxisSnapConstraintDirectionParallel();
    else if (isLeftArrowKey(event)) lockAxisSnapConstraintDirectionZ();
    else if (isRightArrowKey(event)) lockAxisSnapConstraintDirectionX();
  }
}

function lockAxisSnapConstraintDirectionX(){
  store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis = GLOBAL_CONSTANTS.strings.snaps.x;
}

function lockAxisSnapConstraintDirectionY(){
  store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis = GLOBAL_CONSTANTS.strings.snaps.y;
}

function lockAxisSnapConstraintDirectionZ(){
  store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis = GLOBAL_CONSTANTS.strings.snaps.z;
}
/*
  Story of parallel snap-

  This happens only when drawing on a wall or drawn mass is being moved/copied
  Necessary because drawing paralle to the ground on an angled wall is not possible otherwise

  Implementation -
    Using the existing axis snap infra
    But the problem is it needs the snap point on the face of the wall which is available later
    So, secondarySnappedPoint is stored in sessionVariables and passed to the axisPoint function in the next call

    options.parallelFaceSnap and options.axisSnapPoint are required for the operation

 */
function lockAxisSnapConstraintDirectionParallel(){
  store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis = GLOBAL_CONSTANTS.strings.snaps.parallel;
}

function getAxisSnapConstraintDirection(){
  return store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis;
}

function forgetEdgeAndVertexInformation() {
  store.snappingGlobalVariables.lastSnappedEdgeArray.length = 0;
  store.snappingGlobalVariables.lastSnappedVertexArray.length = 0;

  store.snappingGlobalVariables.snappedToTwoAxes = false;
}

function forgetTeleportSnapInformation() {
  store.snappingGlobalVariables.sessionVariables.activeTeleportEdge = null;
  store.snappingGlobalVariables.sessionVariables.activeTeleportVertex = null;
  store.snappingGlobalVariables.sessionVariables.excludedTeleportEdges.length = 0;
  store.snappingGlobalVariables.sessionVariables.excludedTeleportVertices.length = 0;
}

function forgetConstrainedSnapInformation() {
  store.snappingGlobalVariables.sessionVariables.inProgress = false;
  store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis = null;
  store.snappingGlobalVariables.sessionVariables.previousSecondarySnappedPoint = null;
}

function resetConstrainedSnapAxisInformation() {
  const isItActive = !!store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis;
  store.snappingGlobalVariables.sessionVariables.constrainedSnapAxis = null;

  return isItActive;
}

function turnOffSnappingEngine() {
  forgetConstrainedSnapInformation();
  forgetEdgeAndVertexInformation();
  forgetTeleportSnapInformation();
  disposeAllAxisLines();
}
/*
  Using this dataset for fitting the curve
  Modify and paste the below data at https://mycurvefit.com/ to update the curve equation

  Max 20 data points

distanceFromCamera  threshold
0   0.005
10  0.01
25  0.07
40  0.18
70  0.4
100 0.58
150 0.86
200 1.13
350 2
450 2.5
700 4
850 4.85
1000    6
1400    8
1800    10.5
3500    20.5
4000    25
8000    35
10000   35
15000   35


  WOW WHAT A COLLOSAL WASTE OF TIME
  IT'S NOT A SIGMOID CURVE OR ANYTHING
  IT'S JUST A FREAKING LINE Y = MX
  areaLessThreshold = distanceFromCamera / 150;

 */

function getDynamicThreshold(pickedPoint, faceAreaFactor) {
  let distanceFromCamera = BABYLON.Vector3.Distance(
    pickedPoint,
    store.scene.activeCamera.position
  );

  if (store.$scope.isTwoDimension){
    distanceFromCamera =
      (store.newScene.activeCamera.orthoTop ** 2 +
        store.newScene.activeCamera.orthoRight ** 2) **
      0.5;
  }

  // let areaLessThreshold = 37.60971 + (0.4985581 - 37.60971)/(1 + (distanceFromCamera/2443.659)**1.922805);
  let areaLessThreshold = distanceFromCamera / 150;

  if (diagnostics.snapDiagnostics.isModified())
      areaLessThreshold = diagnostics.snapDiagnostics.getThreshold(distanceFromCamera);

  // when cam is <referenceDistance> units away, snap threshold around a point is 1 unit
  // const referenceDistance = store.$scope.isTwoDimension ? 350 : 225;
  // const referenceDistance = store.$scope.isTwoDimension ? 175 : 100;
  // const areaLessThreshold = distanceFromCamera / referenceDistance;

  let threshold;
  if (faceAreaFactor) {
    const faceAreaFactorThreshold = faceAreaFactor * (20 / 100);
    threshold = _.min([areaLessThreshold, faceAreaFactorThreshold]);
    // console.log(`When distanceFromCamera is ${distanceFromCamera}, faceAreaFactorThreshold is ${faceAreaFactorThreshold}`);
    // areaLessThreshold is almost always less than faceAreaFactorThreshold
    // it's not in the case of small items like columns, above condition applies there

    /*if (threshold < faceAreaFactorThreshold * (5 / 100)){
      // this applies when zoomed really close to large objects
      // areaLessThreshold becomes too small
      threshold = faceAreaFactorThreshold * (5 / 100);
    }*/

    const minThreshold = 0.05;
    if (threshold < minThreshold) threshold = minThreshold;
  } else {
    threshold = areaLessThreshold;
  }

  // console.log(`When distanceFromCamera is ${distanceFromCamera}, threshold is ${threshold}`);

  return threshold;
}

function getFaceAreaFactor(pickInfo) {
  let faceArea = getFaceArea(pickInfo.pickedMesh, pickInfo.faceId);
  return Math.sqrt(faceArea);
}

function getIntersectionOfSnapPoints(args, options){
  const intersection = externalUtil.getPointOfIntersection(args, options);

  if (intersection){
    // if (snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(intersection))
    return intersection;
  }
}

/*
This function operates in two modes-
1. absolute mode - checks if given 2 points are parallel to any axis
2. relative mode - checks if given 2 points are parallel to any axis, wrt to the pointer location

options.usePointerLocation determines the mode

 */
function lineParallelToWhichAxis(point1, point2, options = {}) {
  
  let x2x1 = Math.abs(point1.x - point2.x);
  let y2y1 = Math.abs(point1.y - point2.y);
  let z2z1 = Math.abs(point1.z - point2.z);

  let axis;

  const isBackwallPoint = options?.metadata?.isBackwallPoint;

  let axisSnapConstraintDirection = getAxisSnapConstraintDirection();

  if (axisSnapConstraintDirection) {
    if (
      isBackwallPoint &&
      axisSnapConstraintDirection !== GLOBAL_CONSTANTS.strings.snaps.y
    )
      return;
    // wait for the ground point

    axis = axisSnapConstraintDirection;
    if (options) {
      if (axis === GLOBAL_CONSTANTS.strings.snaps.parallel) {
        // story of this condition near the function lockAxisSnapConstraintDirectionParallel
        if (options.parallelFaceSnap && options.axisSnapPoint){
          // both these values are necessary for the correct parallel snap
        }
        else axis = null;
      }
      if (options.metadata) options.metadata.constrainedAxisSnap = axis;
    }
  }
  else if (options.considerShiftKey && StateMachine.EventValidation.isShiftKeyDown()){

    if (isBackwallPoint) {
      // back wall point
      // if (x2x1 < threshold && z2z1 < threshold && false) axis = GLOBAL_CONSTANTS.strings.snaps.y;
    }
    else {
      // ground point
      axis =
        x2x1 > z2z1
          ? GLOBAL_CONSTANTS.strings.snaps.x
          : GLOBAL_CONSTANTS.strings.snaps.z;
    }

    if (options) options.metadata.constrainedAxisSnap = axis;
  }
  else {
    
    if (options.usePointerLocation) {
      const threshold = snapEngine.snapVerifier.getScreenSpaceThreshold();
      const scalar = 100;
      
      const point1ExtendedAlongX = point1.add(new BABYLON.Vector3(1, 0, 0).scale(scalar));
      const point1ExtendedAlongY = point1.add(new BABYLON.Vector3(0, 1, 0).scale(scalar));
      const point1ExtendedAlongZ = point1.add(new BABYLON.Vector3(0, 0, 1).scale(scalar));
      
      const xProjection = projectionOfPointOnLine(point2, point1, point1ExtendedAlongX);
      const yProjection = projectionOfPointOnLine(point2, point1, point1ExtendedAlongY);
      const zProjection = projectionOfPointOnLine(point2, point1, point1ExtendedAlongZ);
      
      const dx = snapEngine.snapVerifier.getScreenSpaceDistanceFromPointer(xProjection);
      const dy = snapEngine.snapVerifier.getScreenSpaceDistanceFromPointer(yProjection);
      const dz = snapEngine.snapVerifier.getScreenSpaceDistanceFromPointer(zProjection);
      
      if (dx < threshold){
        axis = GLOBAL_CONSTANTS.strings.snaps.x;
      }
      else if (dy < threshold){
        axis = GLOBAL_CONSTANTS.strings.snaps.y;
      }
      else if (dz < threshold){
        axis = GLOBAL_CONSTANTS.strings.snaps.z;
      }
    }
    else {
      const threshold = getDynamicThreshold(point1);
      
      if (x2x1 > threshold / 10 && y2y1 < threshold && z2z1 < threshold) {
        axis = GLOBAL_CONSTANTS.strings.snaps.x;
      } else if (y2y1 > threshold / 10 && x2x1 < threshold && z2z1 < threshold) {
        axis = GLOBAL_CONSTANTS.strings.snaps.y;
      } else if (z2z1 > threshold / 10 && x2x1 < threshold && y2y1 < threshold) {
        axis = GLOBAL_CONSTANTS.strings.snaps.z;
      }
    }
    
  }

  return axis;
}

function getUnitNormalToEdge(edgePoint1, edgePoint2) {
  let orthogonal = BABYLON.Vector3.Cross(edgePoint1, edgePoint2);
  let orthogonalOfOrthogonalDown = BABYLON.Vector3.Cross(
    orthogonal,
    edgePoint1.subtract(edgePoint2)
  );
  let unitOrthogonalOfOrthogonalDown =
    orthogonalOfOrthogonalDown.normalizeToNew();

  unitOrthogonalOfOrthogonalDown.y = 0;

  if (unitOrthogonalOfOrthogonalDown.equals(BABYLON.Vector3.Zero()))
    return null;
  else return unitOrthogonalOfOrthogonalDown;
}

function storeEdgeInformation(edge) {
  if (!edge.headPt) return;

  if (isFloatEqual(edge.headPt.y, edge.tailPt.y, 0.01)) {
    if (
      !store.snappingGlobalVariables.lastSnappedEdgeArray.inArray(
        getEdgeComparator(edge)
      )
    ) {
      store.snappingGlobalVariables.lastSnappedEdgeArray.unshift(edge);
      // the most recently added edge gets highest snap priority

      if (
        store.snappingGlobalVariables.lastSnappedEdgeArray.length >
        store.snappingGlobalVariables.edgeMemorySize
      ) {
        store.snappingGlobalVariables.lastSnappedEdgeArray.pop();
        //remove last edge of low priority
      }
    }
  }
}

/**
 * Sets the angle between line p1p2 and p2p3
 *
 * @param p1
 * @param p2
 * @param p3
 * @param rotationAxis
 * @param angleToSnap
 * @returns {*}
 */
function setAngleBetweenPoints(p1, p2, p3, rotationAxis, angleToSnap) {
  const rotatedVector = rotateVector(
    p1.subtract(p2),
    rotationAxis,
    angleToSnap
  );
  const direction = rotatedVector.normalize();

  return p2.add(direction.scale(p3.subtract(p2).length()));
}

function rotateVector(vector, rotationAxis, angleInRadians) {
  const rotationMatrix = BABYLON.Matrix.RotationAxis(
    rotationAxis,
    angleInRadians
  );
  return BABYLON.Vector3.TransformCoordinates(vector, rotationMatrix);
}

function sphtocat(sph, r, offset) {
  let theta = sph.azimuth;
  const phi = sph.altitude;
  if (sph.azimuth < 0) {
    theta = Math.PI + sph.azimuth;
  } else {
    theta = sph.azimuth - Math.PI;
  }

  const x = r * Math.sin(theta) * Math.cos(phi);
  const y = r * Math.sin(phi);
  const z = r * Math.cos(theta) * Math.cos(phi);
  return new BABYLON.Vector3(x, y, z);
}

function degreeToRadian(degree) {
  return (degree * Math.PI) / 180;
}

function radianToDegree(radian) {
  return (radian * 180) / Math.PI;
}

function changeCheckPointFor2D(checkPoint, referencePoint) {
  if (store.$scope.isTwoDimension) {
    let refY;
    if (!referencePoint) {
      const activeStoreyBase = getActiveStoreyObject().base;
      if (isNaN(activeStoreyBase)) {
        return;
      } else {
        refY = activeStoreyBase;
      }
    } else refY = referencePoint.y;

    checkPoint.y = refY;
  }
}

function getVertexComparator(vertex) {
  return (vertexInArray) => vertexInArray.equals(vertex);
}

function getEdgeComparator(edge) {
  return (edgeInArray) => areEdgesSame(edgeInArray, edge);
}

function areEdgesSame(edge1, edge2) {
  return (
    (edge1.headPt.equals(edge2.headPt) && edge1.tailPt.equals(edge2.tailPt)) ||
    (edge1.headPt.equals(edge2.tailPt) && edge1.tailPt.equals(edge2.headPt))
  );
}

function areEdgesSimilar(edge1, edge2) {
  return (
    (edge1.headPt.almostEquals(edge2.headPt) &&
      edge1.tailPt.almostEquals(edge2.tailPt)) ||
    (edge1.headPt.almostEquals(edge2.tailPt) &&
      edge1.tailPt.almostEquals(edge2.headPt))
  );
}

function areLinesSimilar(line1, line2) {
  return (
    (line1[0].almostEquals(line2[0]) && line1[1].almostEquals(line2[1])) ||
    (line1[0].almostEquals(line2[1]) && line1[1].almostEquals(line2[0]))
  );
}
export {
  getTertiarySnapObject,
  isSnaptrudePlaneMesh,
  isCADMesh,
  initializeSnappingEngine,
  lockAxisSnapConstraintDirection,
  getAxisSnapConstraintDirection,
  forgetEdgeAndVertexInformation,
  forgetTeleportSnapInformation,
  resetConstrainedSnapAxisInformation,
  turnOffSnappingEngine,
  getDynamicThreshold,
  getFaceAreaFactor,
  lineParallelToWhichAxis,
  getUnitNormalToEdge,
  storeEdgeInformation,
  setAngleBetweenPoints,
  rotateVector,
  sphtocat,
  degreeToRadian,
  radianToDegree,
  changeCheckPointFor2D,
  getVertexComparator,
  getEdgeComparator,
  areEdgesSame,
  areEdgesSimilar,
  areLinesSimilar,
  handleOptionsForAlternateSnaps,
  getIntersectionOfSnapPoints,
};
