import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import MaterialImage from "../../assets/images/Mask Group.png";
import Divider from "../Divider/index";
import { colors } from "../../themes/constant";
import { handleToolbar } from "../../snaptrude/libs/handleToolbar";
import { handleGetResourceOperations } from "../../services/resources.service";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import {intSelect, onStaircaseTool, removeMouseEvents} from "../../snaptrude/libs/toolbarEvents";
import { STAIRCASE } from "./constants";
import LibraryTooltip from "../Tooltip/LibraryTooltip";
import { generateNameFromImgPath } from "../../containers/editor/components/rightmenuPopUps/helper";
import { libraryService } from "../../snaptrude/modules/utilityFunctions/libraryService";
import Input from "../Input";
import searchIcon from "../../assets/icons/search.svg";
import authService from "../../services/auth.service";
import { getElementByIdAsync } from "./helper";
import { debounce } from "lodash";

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex: 1;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ bg }) => bg};
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  align-items: center;

  .add {
    width: 0.9375em;
    height: 0.9375em;
    padding-left: 0.9375em;
    margin-top: 0.8125em;
    cursor: pointer;
  }

  .add-material {
    display: none;
  }
`;

const ScrollContainer = styled.ul`
  overflow-y: auto;
  list-style: none;
  padding: 0.5em;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  
  div{
    margin: 0.15em;
  }

  // min-height: ${props => props.height};
  /* ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.25em;
      padding-top: 0.3125em;
      padding-bottom: 0;
      margin-bottom: 0.5em;
      margin-top: 0;
  `} */

  .selected {
    border-bottom: 2px solid ${colors.borderRed};
  }

  .m-selected {
    border-width: 0.0625em;
    border-style: solid;
    border-color: ${colors.borderRed};
  }

  .m-outer {
    border-radius: 0.3125em;
    &:hover {
      background: #ddd;
      cursor: pointer;
    }
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ grid }) =>
      !grid &&
      css`
        &:hover {
          background: ${colors.white};
          border-radius: 0.3125em;
          cursor: pointer;
        }
      `};
  }

  li > p {
    margin-left: 1em;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
  .tooltip {
    height: 1.125rem;
    width: 5.3125rem;
    padding: 0.0625rem 0.625rem;
    &::after {
      display: none;
    }
  }
`;

const LibraryItemsContainer = styled.div`
  flex: 1;
  max-height: 16em;
  overflow-y: auto;
  width: 90%;
  .items{
    display: flex;
    flex-wrap: wrap;
    padding: 0.4em;
  }
  .items div{
    margin: 0.1em;
  }
  .m-selected {
    border-width: 0.0625em;
    border-style: solid;
    border-color: ${colors.borderRed};
    margin: -0.0625em;
    &:hover {
      cursor: pointer;
    }
  }

  .m-outer {
    border-radius: 0.3125em;
    &:hover {
      background: #ddd;
      cursor: pointer;
    }
  }
`


const MaterialItem = styled.div`
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  width: 3.5em;
  height: 3.5em;
  margin: 0.125em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0.9375rem;
  flex-wrap: wrap;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 4.125rem;
`;

const LabelText = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
  margin: 0.5em 0;
`;

const ValueText = styled.p`
  font-family: ${(props) => props.theme.font};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  margin: 0.5em 0;
`;

const SearchWrapper = styled.form`
  width: 80%;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  position: relative;
  img{
    height: 1.25em;
    position: absolute;
    left: 0.5em;
  }
  input{
    width: 100%;
    height: 2em;
    padding-left: 2.5em;
  }  
`
let previousSelected = {}

function TileWithBottomStats(props) {
  const {
    statsArray,
    filterSubType,
    filterType,
    thumbnailImageItems,
    setThumbnailImageItems,
    resetSelectedTopSubMenu,
    isOpen,
    importedObjects
  } = props;
  
  const [selectedThumbnail, setSelectedThumbnail] = useState("");
  const scrollContainer = useRef(null);
  const [searchString, setSearchString] = useState("");
  const [originalThumbnailImageItems, setOriginalThumbnailImageItems] = useState([]);

  useEffect(() => {
    window.addEventListener("keydown", handleEscapeEvent);
    return () => {
      window.removeEventListener("keydown", handleEscapeEvent);
    }
  }, []);

  useEffect(() => {
    // if(scrollContainer && scrollContainer.current) scrollContainer.current.scrollTop = 0;
    if(filterType === "staircase"){
      setThumbnailImageItems(STAIRCASE.filter(staircase => staircase.subType === filterSubType));
    }
    else {
      const options = {
        resourceName: "objects",
        floorkey: store.floorkey,
        filterType: filterType,
        filterSubType: filterSubType
      }
      const user = authService.getCurrentUser();
      if(!user.email) return;
      handleGetResourceOperations(options)
      .then(result => {
        setThumbnailImageItems(result);
        setOriginalThumbnailImageItems(result);
    })
    }
  }, [filterSubType])

  useEffect(() => {
    if(isOpen){
      async function waitForDOMtoLoadAndSelect(){
        if(filterType && filterSubType) {
          let index = 0;
          if(previousSelected[filterType] && previousSelected[filterType][filterSubType]){
            index = previousSelected[filterType][filterSubType].index || 0;
          }
          const libraryItem = await getElementByIdAsync(`${filterType}-${filterSubType}-list-item-${index}`);
          if(!libraryItem) return;
          if(index === 0){
            document.querySelector(`#${filterType}-${filterSubType}-container`).scrollTop = 0;
          }else{
            libraryItem.scrollIntoView({behavior: "smooth"})
          }
          const imageurl = libraryItem.getAttribute("data-imageurl");
          if(selectedThumbnail.imgURL !== imageurl)libraryItem.click();
        }
      }
      if(filterType === "staircase"){
        setThumbnailImageItems(STAIRCASE.filter(staircase => staircase.subType === filterSubType));
      }
      waitForDOMtoLoadAndSelect();
      return () => {
        // removeMouseEvents();
      }
      
    }  else{
      setSelectedThumbnail({
        imgURL: null,
        type: null,
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;

    }
  }, [isOpen, filterSubType]);

  
  useEffect(() => {
    if(importedObjects){
      setThumbnailImageItems([...thumbnailImageItems, ...importedObjects])
    }
  }, [importedObjects]);

  const handleEscapeEvent = (evt) => {
    if(evt.key === "Escape"){
      // deselect the selected thumbnail
      setSelectedThumbnail({
        imgURL : null,
        type : null
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;
    
      // removeMouseEvents();

      // reset the selected tool
      // resetSelectedTopSubMenu();
    }
  }
  
  const handleThumbnailItemClick = (item, index) => {
    const { babylon, type, img, subType } = item;
    previousSelected[filterType] = {
        ...previousSelected[filterType],
        [filterSubType]: {
        item,
        index
      }
    }
    let prop, tabs;
    
    if (selectedThumbnail === img){
      // clicking on the already selected thumbnail
      setSelectedThumbnail("");
      removeMouseEvents();
    }
    else {
      
      // setSelectedSubMenu("");
      setSelectedThumbnail(img);
      
      switch (filterType) {
        case "door":
          prop = {
            propurl: babylon,
            subType: subType
          }
          tabs = {
            title: "Door"
          }
          intSelect(prop, tabs);
          break;
        case "window":
          prop = {
            propurl: babylon,
            subType: subType
          }
          tabs = {
            title: "Window"
          }
          intSelect(prop, tabs);
          break;
        case "staircase":
          onStaircaseTool(subType);
          break;
        default:
          break;
      }
    }
  }
  
  const getNameForItem = (item) => {
    if(item.fullName) return item.fullName;
    return generateNameFromImgPath(item.img);
  }

  const handleSearch = (evt, value="") => {
    if(evt) evt.preventDefault();
    let queryString = searchString;
    if(value) queryString = value;
    if(queryString === ""){
      setThumbnailImageItems(originalThumbnailImageItems);
      return;
    }
    const list = libraryService.getList(filterType);
    if(list){
      const matchedList = list.search(queryString);
      setThumbnailImageItems(matchedList);
    }
  }

  const debounceSearch = useCallback(
    debounce((value) => {
      handleSearch(null, value);
    }, 500),
    []
  )

  const handleSearchTextChange = (evt) => {
    const {value} = evt.target;
    setSearchString(value);
    debounceSearch(value);
  }

  let scrollContainerHeight;
  if (filterType === "door") scrollContainerHeight = "11.75em";
  else if (filterType === "window") scrollContainerHeight = "10em";
  else if (filterType === "staircase") scrollContainerHeight = "10em";
  
  return (
    <>
      <Container>
        <Inner>
          {
            filterType !== "staircase" ?
            <SearchWrapper onSubmit={handleSearch}>
              <img src={searchIcon} alt="Q" />
              <Input type="text" placeholder="Search" value={searchString} onPress={handleSearchTextChange} />
            </SearchWrapper>
            :
            <div style={{marginTop: "1.3em"}}></div>
          }
          {/* <ScrollContainer grid height={scrollContainerHeight} ref={scrollContainer}> */}
          <LibraryItemsContainer id={`${filterType}-${filterSubType}-container`}>
            <div className="items" id={`${filterType}-${filterSubType}-list`}>

            {thumbnailImageItems && thumbnailImageItems.map((item, index) => {
              item.fullName = getNameForItem(item);
              return <div
                  key={index + "div"}
                  data-tip
                  data-for={`${index}`}
                  className={
                    selectedThumbnail
                      ? selectedThumbnail === item.img
                        ? "m-selected"
                        : "m-outer"
                      : "m-outer"
                  }
                  style={{
                    padding: "0.0625rem",
                    // width: "fit-content",
                  }}
                >
                  <MaterialItem
                    data-tip
                    data-for={`${index}`}
                    data-imageurl={item.img}
                    key={index + "img"}
                    imageUrl={(item && item.img) || MaterialImage}
                    onClick={() => {handleThumbnailItemClick(item, index)}}
                    id={`${filterType}-${filterSubType}-list-item-${index}`}
                  />
                  <LibraryTooltip id={`${index}`}>{item.fullName}</LibraryTooltip>
                </div>
          })}
          </div>
          </LibraryItemsContainer>
          {/* </ScrollContainer> */}
          {/*{statsArray && (
            <>
              <Divider customStyle={{ margin: "0 0.9375rem" }} />
              <StatsContainer>
                {statsArray.map((item, index) => {
                  return (
                    <RowContainer key={index}>
                      <LabelText>{item.title}</LabelText>
                      <ValueText>{item.value}</ValueText>
                    </RowContainer>
                  );
                })}
              </StatsContainer>
            </>
          )}*/}
        </Inner>
      </Container>
    </>
  );
}

export default TileWithBottomStats;
