import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { nonDefaultMesh } from "./sceneStateFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { Mass } from "../modules/snaptrudeDS/mass.ds.js";
import reduxStore from "../stateManagers/store/reduxStore.js";
import { deselectMesh, selectMesh } from "../stateManagers/reducers/objectProperties/meshSelection.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants";

function click(mesh) {
  // mesh.actionManager = new BABYLON.ActionManager(newScene);
  // mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, function (ev) {
  //     if (nonDefaultMesh(mesh) && (mesh.name.indexOf("twoPlane") === -1)) {
  //         var $scope = store.angular.element(appElement).scope();
  //         $scope = $scope.$$childHead;
  //         bbox = mesh.getBoundingInfo();
  //         boxes = [];
  //       //  console.log(mesh.uniqueId);
  //         //console.log("storey ->", mesh.storey);
  //         //console.log(mesh.getSnaptrudeDS());
  //         if (mesh.name.toLowerCase() == "wall"){
  //
  //
  //             let wallPos = mesh.position;
  //             wallPos = new BABYLON.Vector3(wallPos.x, 0, wallPos.z);
  //
  //             let sphere = store.scene.getMeshByName("isolatedViewSphere");
  //             if (!sphere){
  //                 sphere = BABYLON.MeshBuilder.CreateSphere("isolatedViewSphere", {diameter:3}, store.scene);
  //                 sphere.checkCollisions = true;
  //                 sphere.showBoundingBox = false;
  //             }
  //
  //             sphere.setAbsolutePosition(wallPos);
  //             sphere.refreshBoundingInfo();
  //             sphere.isVisible = false;
  //             sphere.visibility = 0.0;
  //
  //             // findWallNeighboursSync([mesh]);
  //         }
  //
  //         // if(mesh.floor){
  //         //     try{
  //         //         if(mesh.floor.length){
  //         //              mesh.floor.forEach(function(id){
  //         //                 let mesh1 = store.scene.getMeshByUniqueID(id);
  //         //                 mesh1.material = store.scene.getMaterialByName("wall_mat");
  //         //                 setTimeout(function(mesh1){
  //         //                     mesh1.material = store.scene.getMaterialByName("floor_tile");
  //         //                 }, 3000, mesh1);
  //         //             });
  //         //         }
  //         //         else{
  //         //              let mesh1 = store.scene.getMeshByUniqueID(mesh.floor);
  //         //             mesh1.material = store.scene.getMaterialByName("wall_mat");
  //         //              setTimeout(function(mesh1){
  //         //                 mesh1.material = store.scene.getMaterialByName("floor_tile");
  //         //             }, 3000, mesh1);
  //         //         }
  //         //     }
  //         //     catch(e){
  //         //         console.log(e);
  //         //     }
  //         // }
  //         //checkWallLink(500);
  //         if  ($scope.assignBaseObject){
  //             let object = mesh.getSnaptrudeDS();
  //             if (object){
  //                 object.properties._components = $scope.assignBaseObject.properties._components;
  //             }
  //         }
  //         //moveMeshInGroup(mesh);
  //         if (mesh.state === "on") {
  //             // removeMeshSelectionChildren(mesh);
  //             if ((ev.sourceEvent.ctrlKey || ev.sourceEvent.shiftKey || ev.sourceEvent.metaKey)) {
  //                 for (var i = 0; i < selectionStack.length; i++) {
  //                     if (selectionStack[i] === mesh) {
  //                         selectionStack.splice(i, 1);
  //                         mesh.state = "off";
  //                         removeMeshSelectionChildren(mesh);
  //                         break;
  //                     }
  //                 }
  //             }
  //             else {
  //                 for (var i = 0; i < selectionStack.length; i++) {
  //                     removeMeshSelectionChildren(selectionStack[i]);
  //                     selectionStack[i].state = "off";
  //                 }
  //                 selectionStack = [];
  //                 // document.getElementById("cw").style.visibility = "hidden";
  //                 // document.getElementById("ccw").style.visibility = "hidden";
  //             }
  //
  //         }
  //         else {
  //             //console.log(mesh.state);
  //             mesh.children = [];
  //             if (ev.sourceEvent.ctrlKey || ev.sourceEvent.shiftKey || ev.sourceEvent.metaKey) {
  //                 selectionStack.push(mesh);
  //                 let group = MeshGroups.getGroupByUniqueId("my_group");
  //                 group.addTemporaryMember(mesh);
  //                 mesh.state = "on";
  //             }
  //             else {
  //                 //console.log(mesh.state);
  //                 for (var i = 0; i < selectionStack.length; i++) {
  //                     removeMeshSelectionChildren(selectionStack[i]);
  //                     selectionStack[i].state = "off";
  //                 }
  //                 selectionStack = [];
  //                 let group = MeshGroups.createNewGroup("my_group");
  //                 group.addTemporaryMember(mesh);
  //                 selectionStack.push(mesh);
  //                 mesh.state = "on";
  //             }
  //
  //             drawSelectionBox(mesh);
  //             // document.getElementById("cw").style.visibility = "visible";
  //             // document.getElementById("ccw").style.visibility = "visible";
  //         }
  //     }
  //     var $scope = store.angular.element(appElement).scope();
  //     $scope = $scope.$$childHead;
  //     $scope.$apply(function () {
  //         $scope.levelButtonDisp = false;
  //         $scope.activeObjectName = mesh.name;
  //         $scope.activeObject = mesh;
  //         $scope.instruction = "";
  //     });
  //     DisplayOperation.displayMeshDimensions(mesh);
  //     updatePropBlock();
  //     enableMassMode(mesh);
  //     hideButtonStatus(mesh, true);
  // }));
}

function drawSelectionBoxRotate(mesh) {
  if (mesh.name === "boxScale" || !nonDefaultMesh(mesh)) return;
  // console.log(mesh.name);
  var boxScale = store.scene.getMeshByName("boxScale");
  if (boxScale) {
    if (boxScale.parentMesh === mesh) {
      return;
    } else {
      boxScale.dispose();
    }
  }
  var bbinfo = mesh.getBoundingInfo();
  mesh.freezeWorldMatrix();
  bbinfo.update(mesh._worldMatrix);
  var height = bbinfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
  var width = bbinfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
  var depth = bbinfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
  // mesh.unfreezeWorldMatrix();
  // console.log(height, width, depth);
  // let wmtrix = mesh.getWorldMatrix();
  // let nepoint = BABYLON.Vector3.TransformCoordinates(bbinfo.boundingBox.extendSize, wmtrix);
  // var height = nepoint.y*2 + 0.1;
  // var width = nepoint.x*2 + 0.1;
  // var depth = nepoint.z*2 + 0.1;

  var center = bbinfo.boundingBox.centerWorld;
  mesh.unfreezeWorldMatrix();

  var box = BABYLON.MeshBuilder.CreateBox(
    "boxScale",
    { height: height, width: width, depth: depth },
    store.scene
  );
  box.position = center;
  box.enableEdgesRendering();
  box.edgesWidth = 8.0;
  box.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  // box.rotation = mesh.rotation;
  // box.scaling = mesh.scaling;

  var material = new BABYLON.StandardMaterial("bbMat", store.scene);
  material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 1);
  material.alpha = 0.2;
  material.backFaceCulling = false;
  box.material = material;
  // box.isPickable = false;
  mesh.children = [];
  mesh.children.push(box);
  box.parentMesh = mesh;

  return box;
}

function drawGroupSelectionBoxRotate(bbInfo, mesh) {
  let boxScale = store.scene.getMeshByName("boxScale");
  if (boxScale) {
    if (boxScale.parentMesh === mesh) {
      return;
    } else {
      boxScale.dispose();
    }
  }

  let height = bbInfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
  let width = bbInfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
  let depth = bbInfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
  let center = bbInfo.boundingBox.centerWorld;

  let box = BABYLON.MeshBuilder.CreateBox(
    "boxScale",
    { height: height, width: width, depth: depth },
    store.scene
  );
  box.position = center;
  box.enableEdgesRendering();
  box.edgesWidth = 8.0;
  box.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

  let mat = store.scene.getMaterialByName("bbMat");
  let material;
  if (mat) material = mat;
  else {
    material = new BABYLON.StandardMaterial("bbMat", store.scene);
    material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 1);
    material.alpha = 0.2;
    material.backFaceCulling = false;
  }
  box.material = material;
  box.isPickable = false;
  mesh.children = [];
  mesh.children.push(box);
  box.parentMesh = mesh;
}

function drawSelectionBox(mesh) {
  // drawPivotAxis(mesh);

  /*
    Old code for red color boxes as selection
     */
  // for (var i = 0; i < bbox.boundingBox.vectorsWorld.length; i++) {
  //     var boxSize = 0.05 * bbox.diagonalLength;
  //     if (boxSize > 0.75) {
  //         boxSize = 0.75;
  //     }
  //     var box = BABYLON.Mesh.CreateBox(mesh.name + "boxScale" + i, boxSize, store.newScene);
  //     box.position = bbox.boundingBox.vectorsWorld[i];
  //     box.material = store.scene.getMaterialByName("boxm1");
  //     boxes.push(box);
  //     mesh.children.push(box);
  // }
  mesh.computeWorldMatrix(true);
  var bbinfo = mesh.getBoundingInfo();
  // try {
  //   mesh.freezeWorldMatrix();
  // } catch (e) {
  //   console.log(e);
  // }
  // bbinfo.update(mesh._worldMatrix);
  var height = bbinfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
  var width = bbinfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
  var depth = bbinfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
  var center = bbinfo.boundingBox.centerWorld;
  // try {
  //   mesh.unfreezeWorldMatrix();
  // } catch (e) {
  //   console.log(e);
  // }

  var box = BABYLON.MeshBuilder.CreateBox(
    "boxScale",
    { height: height, width: width, depth: depth },
    store.scene
  );
  box.position = center;
  box.enableEdgesRendering();
  box.edgesWidth = 8.0;
  box.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

  let mat = store.scene.getMaterialByName("bbMat");
  let material;
  if (mat) material = mat;
  else {
    material = new BABYLON.StandardMaterial("bbMat", store.scene);
    material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 1);
    material.alpha = 0.2;
    material.backFaceCulling = false;
  }
  box.material = material;
  box.isPickable = false;
  if (mesh.children) {
    mesh.children.push(box);
  } else {
    mesh.children = [];
    mesh.children.push(box);
  }
  box.parentMesh = mesh;
  /*if (["door", "window"].includes(mesh.type.toLowerCase())) {
    const childMeshes = mesh.getChildren().filter(c => c.type ===
      GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator);
    if (childMeshes[0] && childMeshes[0].isVisible) {
      store.highlighter.addMesh(childMeshes[0], BABYLON.Color3.Blue());
    }
  }*/
  reduxStore.dispatch(selectMesh({uniqueId: mesh.uniqueId}));
  return box;
  // box.rotation = box.parentMesh.rotation;
}

function removeSelectionBox(mesh) {
  /* AG-RE: ANGULAR REFERENCE */
  // console.warn("AG-RE: called removeSelectionBox(mesh)");

  // Removing elements from an array within the callback of forEach causes elements to be skipped.
  // Disposing a mesh removes it from the scene.meshes array
  // Work around is looping over a copy of the array like below since the copy will remain unmodified when disposing the mesh.
  store.newScene.meshes.slice().forEach(function (mesh) {
    if (mesh.name) {
      if (
        mesh.name.indexOf("boxScale") == -1 &&
        mesh.name.indexOf("axis") == -1 &&
        mesh.name.indexOf("ground") == -1
      ) {
        mesh.state = "off";
        if (mesh.children) {
          if (mesh.children.length) {
            for (let i = 0; i < mesh.children.length; i++) {
              if (mesh.children[i]) mesh.children[i].dispose();
            }
          }
          mesh.children = [];
        }
      } else if (mesh.name.indexOf("boxScale") !== -1) {
        mesh.dispose();
      }
    }
  });
}

function removeMeshSelectionBox(mesh) {
  if (mesh && mesh.hasOwnProperty("children")) {
    for (let i = 0; i < mesh.children.length; i++) {
      if (mesh.children[i].name.includes("boxScale")) {
        mesh.children[i].dispose();
        mesh.children.splice(i, 1);
        i--;
      }
    }
  }
}

function removeMeshSelectionChildren(mesh) {
  reduxStore.dispatch(deselectMesh({uniqueId: mesh.uniqueId}));
  DisplayOperation.removeDimensions();
  if (mesh.children) {
    for (let i = 0; i < mesh.children.length; i++) {
      if (mesh.children[i]) mesh.children[i].dispose();
    }
    mesh.children = [];
  }
  /*if (["door", "window"].includes(mesh.type.toLowerCase())) {
    const childMeshes = mesh.getChildren().filter(c => c.type ===
      GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator);
    if (childMeshes[0] && childMeshes[0].isVisible) {
      store.highlighter.removeMesh(childMeshes[0]);
    }
  }*/
  // mesh.state = "off";
  //         for (var i = selectionStack.length - 1; i >= 0; i--) {
  //             if (selectionStack[i] === mesh) {
  //                 selectionStack.splice(i, 1);
  //             }
  //         }
  //         var selectFlag = 0;
  //         for ( let i = 0; i < store.newScene.meshes.length; i++) {
  //             if (newScene.meshes[i].children) {
  //                 if (newScene.meshes[i].children.length > 0) {
  //                     selectFlag = 1;
  //                 }
  //             }
  //         }
  //         var $scope = store.angular.element(appElement).scope();
  //         $scope = $scope.$$childHead;
  //
  //         if (selectFlag === 0) {
  //             $scope.$apply(function () {
  //                 $scope.levelButtonDisp = true;
  //             });
  //         }
  //         //to check if any mesh is selected, if selected, the controllers won't be hid
  //         var countMU = 0;
  //         var test = 0;
  //         while (countMU < store.newScene.meshes.length && test === 0) {
  //             if (newScene.meshes[countMU].visibility === 0.5) {
  //                 test = 1;
  //             }
  //             countMU++;
  //         }
  //         document.getElementById("cw").style.visibility = "hidden"
  //         document.getElementById("ccw").style.visibility = "hidden"
  //         disableMassMode(mesh);
  //         hideButtonStatus(mesh, false);
}

function hover(mesh) {
  if (!mesh.actionManager)
    mesh.actionManager = new BABYLON.ActionManager(store.newScene);
  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          mesh.visibility = 0.8;
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          mesh.visibility = 0.8;
        }
      )
    );

  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        function () {
          mesh.visibility = 1.0;
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        function () {
          mesh.visibility = 1.0;
        }
      )
    );
}

function hoverRotate(mesh) {
  if (!mesh.actionManager)
    mesh.actionManager = new BABYLON.ActionManager(store.newScene);
  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          createRotationComps(mesh);
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          createRotationComps(mesh);
        }
      )
    );

  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        function () {
          removeRotationComps(mesh);
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        function () {
          removeRotationComps(mesh);
        }
      )
    );
}

function removeRotationLines() {
  var rotLine1 = store.scene.getMeshByName("rotLine1");
  if (rotLine1) rotLine1.dispose();
  var rotLine2 = store.scene.getMeshByName("rotLine2");
  if (rotLine2) rotLine2.dispose();
}

function createRotationComps(mesh) {
  removeRotationLines();
  if (store.scene.getMeshByName("rotateBBox")) {
    store.scene.getMeshByName("rotateBBox").dispose();
  }
  // var pickInfo2 = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (pickMesh) {
  //     return pickMesh === mesh;
  // });
  // console.log(mesh);
  drawSelectionBoxRotate(mesh);
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (pickMesh) {
      return pickMesh.name === "boxScale";
    }
  );
  // console.log(pickInfo.pickedMesh);
  var bbinfo = mesh.getBoundingInfo();

  // var bbox = BABYLON.Mesh.CreateBox("rotateBBox", 1.0, store.newScene);
  // bbox.position = mesh.position;
  // bbox.scaling.x = 2*bbinfo.boundingBox.extendSizeWorld.x;
  // bbox.scaling.y = 2*bbinfo.boundingBox.extendSizeWorld.y;
  // bbox.scaling.z = 2*bbinfo.boundingBox.extendSizeWorld.z;
  // var pickInfo2 = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (pickMesh) {
  //     return pickMesh.name === bbox.name;
  // });
  // console.log(pickInfo2);
  // var face = getFace(pickInfo);
  var normal = pickInfo.getNormal(true, false);
  if (!normal) return;
  // var centroid = new BABYLON.Vector3(face[0][0].x, face[0][0].y, face[0][0].z);
  // for (var i=1; i<face[0].length; i++){
  //     centroid.addInPlace(new BABYLON.Vector3(face[0][i].x, face[0][i].y, face[0][i].z));
  // }
  var centroid = bbinfo.boundingBox.centerWorld;
  // centroid.x /= face[0].length;
  // centroid.y /= face[0].length;
  // centroid.z /= face[0].length;
  // centroid = BABYLON.Vector3.TransformCoordinates(centroid, mesh.getWorldMatrix());

  var myPoints1 = [];
  var myPoints2 = [];

  var deltaTheta = 0.1;
  var radius1 = bbinfo.diagonalLength / 8;
  var radius2 = bbinfo.diagonalLength / 12;
  var theta = 0;
  var Y = 0;
  var colors1 = [];
  var colors2 = [];
  for (var i = 0; theta <= 6.3; i++) {
    myPoints1.push(
      new BABYLON.Vector3(
        radius1 * Math.cos(theta),
        Y,
        radius1 * Math.sin(theta)
      )
    );
    if (theta <= 3.2)
      myPoints2.push(
        new BABYLON.Vector3(
          radius2 * Math.cos(theta),
          Y,
          radius2 * Math.sin(theta)
        )
      );
    colors1.push(new BABYLON.Color4(1, 0, 0, 1));
    theta += deltaTheta;
  }
  // var anglexy = Math.atan2(face[1][0], face[1][2]);
  // var anglez = Math.atan2(face[1][1], 1/Math.pow(3, 0.5));
  // var anglexy = Math.atan2(normal.y, normal.x);
  // var anglez = Math.atan2(normal.z, normal.x);
  //Create lines
  var lines1 = BABYLON.MeshBuilder.CreateLines(
    "rotLine1",
    { points: myPoints1, colors: colors1 },
    store.scene
  );
  var lines2 = BABYLON.MeshBuilder.CreateDashedLines(
    "rotLine2",
    { points: myPoints2, dashNb: 40 },
    store.scene
  );
  lines2.color = BABYLON.Color3.Blue();
  lines1.isPickable = false;
  lines2.isPickable = false;
  lines1.position = centroid.clone();
  lines2.position = centroid.clone();
  // console.log(normal);
  if (
    Math.abs(normal.x) >= 0.01 &&
    Math.abs(normal.x) > Math.abs(normal.y) &&
    Math.abs(normal.x) > Math.abs(normal.z)
  ) {
    if (normal.x > 0) {
      lines1.position.x = bbinfo.boundingBox.maximumWorld.x;
      lines2.position.x = bbinfo.boundingBox.maximumWorld.x;
    } else {
      lines1.position.x = bbinfo.boundingBox.minimumWorld.x;
      lines2.position.x = bbinfo.boundingBox.minimumWorld.x;
    }
    lines1.rotation.z = (Math.PI / 2) * Math.sign(normal.x);
    lines2.rotation.z = (Math.PI / 2) * Math.sign(normal.x);
  } else if (
    Math.abs(normal.y) >= 0.01 &&
    Math.abs(normal.y) > Math.abs(normal.x) &&
    Math.abs(normal.y) > Math.abs(normal.z)
  ) {
    if (normal.y > 0) {
      lines1.position.y = bbinfo.boundingBox.maximumWorld.y;
      lines2.position.y = bbinfo.boundingBox.maximumWorld.y;
    } else {
      lines1.position.y = bbinfo.boundingBox.minimumWorld.y;
      lines2.position.y = bbinfo.boundingBox.minimumWorld.y;
    }
  } else if (
    Math.abs(normal.z) >= 0.01 &&
    Math.abs(normal.z) > Math.abs(normal.x) &&
    Math.abs(normal.z) > Math.abs(normal.y)
  ) {
    if (normal.z > 0) {
      lines1.position.z = bbinfo.boundingBox.maximumWorld.z;
      lines2.position.z = bbinfo.boundingBox.maximumWorld.z;
    } else {
      lines1.position.z = bbinfo.boundingBox.minimumWorld.z;
      lines2.position.z = bbinfo.boundingBox.minimumWorld.z;
    }
    lines1.rotation.x = (Math.PI / 2) * Math.sign(normal.z);
    lines2.rotation.x = (Math.PI / 2) * Math.sign(normal.z);
  }

  // if (anglez && Math.abs(anglez) <= Math.PI/2){
  //     lines1.rotation.z = 0;
  //     lines2.rotation.z = 0;
  // }
  // else{
  //     lines1.rotation.z = Math.PI/2;
  //     lines2.rotation.z = Math.PI/2;
  // }
  // if (anglexy && Math.abs(anglexy) <= Math.PI/2){
  //     lines1.rotation.x = Math.PI/2;
  //     lines2.rotation.x = Math.PI/2;
  // }
  // else{
  //     lines1.rotation.x = 0;
  //     lines2.rotation.x = 0;
  // }
  // if (anglexy && Math.abs(anglexy) <= Math.PI/2){
  //     lines1.rotation.y = 0;
  //     lines2.rotation.y = 0;
  // }
  // else{
  //     lines1.rotation.y = Math.PI/2;
  //     lines2.rotation.y = Math.PI/2;
  // }
}

function removeRotationComps(mesh) {}

function unclick(mesh) {
  mesh.actionManager = null;
}

function drawPivotAxis(mesh) {
  //console.log(mesh);
  var size = 2;
  var axisX = BABYLON.Mesh.CreateLines(
    "axisX",
    [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0)],
    store.newScene
  );
  axisX.color = new BABYLON.Color3(1, 0, 0);
  var axisY = BABYLON.Mesh.CreateLines(
    "axisY",
    [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0)],
    store.newScene
  );
  axisY.color = new BABYLON.Color3(0, 1, 0);
  var axisZ = BABYLON.Mesh.CreateLines(
    "axisZ",
    [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size)],
    store.newScene
  );
  //console.log(mesh.getPivotPoint());
  axisZ.color = new BABYLON.Color3(0, 0, 1);
  //console.log(mesh.position.x);
  axisX.position = mesh.position;
  axisY.position = mesh.position;
  axisZ.position = mesh.position;
}

function enableMassMode(mesh) {
  // console.warn("AG-RE: called enableMassMode()");
  /* AG-RE: ANGULAR REFERENCE */
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  // if (mesh.name.indexOf("room") !== -1) {
  //   $scope.$apply(function () {
  //     $scope.mass_mode_object.mass_mode_activation = false;
  //     $scope.mass_mode_object.mass_mode_status = true;
  //   });
  // }
}

function hideButtonStatus(mesh, status) {
  // console.warn("AG-RE: called hideButtonStatus()");
  /* AG-RE: ANGULAR REFERENCE */
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  // let phase = $scope.$root.$$phase;
  // if (phase == "$apply" || phase == "$digest") {
  //   $scope.hide_object.hide_object_activation = !status;
  //   $scope.hide_object.hide_object_status = false;
  // } else {
  //   $scope.$apply(function () {
  //     $scope.hide_object.hide_object_activation = !status;
  //     $scope.hide_object.hide_object_status = false;
  //   });
  // }
}

function disableMassMode(mesh) {
  /* ANGULAR REFERENCE */
  // console.warn("AG-RE: called disableMassMode(mesh)");
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  // if (mesh.name.indexOf("room") !== -1) {
  //   $scope.$apply(function () {
  //     $scope.mass_mode_object.mass_mode_activation = true;
  //     $scope.mass_mode_object.mass_mode_status = false;
  //   });
  // }
}

function removeChildren(mesh) {
  let children = mesh.children;
  if(children){
    for (let i = 0; i < children.length; i++) {
      if (children[i] && !Mass.isPlinth(children[i])) children[i].dispose();
    }
  }
  mesh.children = [];

  // let children = mesh.getChildren(predicate);
  // children.forEach(function (child) {
  //     child.isVisible = false;
  //     child.isPickable = false;
  // })
}

// /**
//  * TODO: To be shifted to BabylonDS.module.js after pull from rest3d
//  * @param id
//  */
// BABYLON.Mesh.prototype.getSubMeshByID = function (id) {
//   var subMesh = null;
//   this.subMeshes.forEach(function (sMesh) {
//     if (sMesh._id === id) {
//       //console.log(sMesh);
//       subMesh = sMesh;
//       return;
//     }
//   });
//   return subMesh;
// };
export {
  click,
  drawSelectionBoxRotate,
  drawGroupSelectionBoxRotate,
  drawSelectionBox,
  removeSelectionBox,
  removeMeshSelectionBox,
  removeMeshSelectionChildren,
  hover,
  hoverRotate,
  removeRotationLines,
  createRotationComps,
  removeRotationComps,
  unclick,
  drawPivotAxis,
  enableMassMode,
  hideButtonStatus,
  disableMassMode,
  removeChildren,
};
