// "use strict";

// function _defineProperty(obj, key, value) {
//   if (key in obj) {
//     Object.defineProperty(obj, key, {
//       value: value,
//       enumerable: true,
//       configurable: true,
//       writable: true,
//     });
//   } else {
//     obj[key] = value;
//   }
//   return obj;
// }

// class SnapProperty {
//   constructor(name = "default", value = "", element, hidden = false) {
//     _defineProperty(this, "getValue", function () {
//       return this.value;
//     });

//     _defineProperty(this, "getName", function () {
//       return this.name;
//     });

//     _defineProperty(this, "getElement", function () {
//       return this.element;
//     });

//     this.name = name;
//     this.element = element;
//     this.value = value;
//     this.hidden = hidden;
//   }
// }
// export { _defineProperty,SnapProperty };

class SnapProperty {
  constructor(name = "default", value = "", element, hidden=false){
    this.name = name;
    this.element = element;
    this.value = value;
    this.hidden = hidden;
  }
  getValue = () => this.value;
  getName = () => this.name;
  getElement = () => this.element;
  setHidden = (hidden) => {
    this.hidden = hidden;
  }
}

export {SnapProperty}