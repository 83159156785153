import React, { useCallback, useEffect, useRef, useState } from 'react';
import LayeredExpandPopup from "../components/layerExpandPopup";
import styled from 'styled-components';
import { colors } from '../../../themes/constant';
import * as icons from "../Icons";
import add from "../../../assets/icons/add.svg";
import Input from '../../../components/Input';
import ScrollableDropdown from '../../../components/ScrollableDropdown';
import Button from '../../../components/Button';
import { LabelWithToggle } from '../../../components/TopMenuBar/subMenuDropDowns/toggleList';
import { useDispatch, useSelector } from 'react-redux';
import { addBlankStoreys, updateStorey, duplicateStoreys } from '../../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice';
import { store } from '../../../snaptrude/modules/utilityFunctions/Store';
import { StoreyMutation } from '../../../snaptrude/modules/storeyEngine/storeyMutations';
import { layerView } from '../../../snaptrude/libs/layerView.js';
import { abs } from 'mathjs';
import Tooltip from '../../../components/Tooltip';
import {cameraController} from "../../../snaptrude/modules/cameraControl/cameraController";

const Wrapper = styled.div`
  padding: 0 0.5em;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  // height: 100%;
  .storyIconWrapper {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  .increasedHeight{
    height: 3em;
  }
  .storeyIcon {
    &:hover {
      background-color: transparent;
      filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
        brightness(93%) contrast(98%);
    }
  }
  .storeyIcon.active{
    background-color: transparent;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
        brightness(93%) contrast(98%);
  }
`;

const StoreyModalWrapper = styled.div`
  display: flex;
  .singularText {
    font-weight: 400;
  }
`;

const StoreyControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.fullWhite};
  border-radius: 8px;
`

const ImageButton = styled.div`
  border: none;
  background: ${colors.fullWhite};
  // margin: 0.5rem 0;
  height: 2.5em;
  width: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
  }
  .rightMenuIcon {
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
  }
  .bottomMenuIcon {
    width: 0.9375rem;
    height: 0.9375rem;
  }
  .storeyIcon {
    width: 1.25rem;
    height: 1.66rem;
    cursor: pointer;
  }
  .selected_right_icon {
    width: 1.125rem;
    height: 1.125rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  .selected_bottom_icon {
    width: 0.9375rem;
    height: 0.9375rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
`;

const StoreyMainWrapper = styled.div`
  // position: absolute;
  // left: 3.75rem;
`;
const MainLayerWrapper = styled.div`
  border-radius: 8px;
  background-color: ${colors.fullWhite};
  position: relative;
`;
const LayerDiv = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 2.76364px 20.7273px ${colors.lightGreyShadow};
  overflow: visible;
  width: fit-content;
  max-width: 8em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  max-height: 10.5rem;
  overflow: scroll;
  &::-webkit-scrollbar {
  display: none;
  }
  p {
    font-family: ${(props) => props.theme.font};;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.230303px;
    margin: 0.5rem 0;
    padding: 0;
    color: ${colors.thinGray};
  }
`;

const LayerContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 0 0.75rem;
  &:hover {
    background-color: ${colors.white};
    cursor: pointer;
  }
`;

const LayerNavigation = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0.690909px 2.76364px rgba(189, 187, 187, 0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  cursor: pointer;
`;
const SingleLayerCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .layerHover {
    display: none;
    height: 2.5rem;
    align-items: center;
  }
  &:hover {
    .layerHover {
      display: flex;
    }
  }
`;
const StoreyModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.fullWhite};
  box-shadow: 0px 4px 30px rgba(197, 202, 220, 0.3);
  padding: 0.8125rem 0.625rem;
  width: 70%;
  border-radius: 8px;
  .slider.round {
    border-radius: 9px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 0.625rem;
    width: 0.625rem;
    left: 0.0625em;
    bottom: 0.0625em;
    background-color: ${(props) => props.theme.colors.brightRed};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: -0.1875rem;
  }
  .label {
    width: 1.25rem;
    height: 0.6875rem;
    padding-right: 0.35rem;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.1875rem 0.375rem;
  p {
    font-family: DM Sans;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.333333px;
    color: #818181;
    margin: 0;
  }
`;
const StoreyInstruction = styled.div`
  fontweight: 400;
  color: #bdbbbb;
  text-align: center;
  width: 5.6875rem;
  font-size: 10px;
  margin: 0;
  margin-bottom: 1rem;
`;
const StoreyButtonContainer = styled.div`
  height: 1.5625rem;
  width: 8.75rem;
  padding-top: 0.8125rem;
`;
const Container = styled.div`
  // border: 2px solid crimson;
  font-family: DM Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.thinGray};
`
const Row = styled.div`
  // border: 2px solid cadetblue;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Col = styled.span`
  // border: 2px solid lightblue;
  text-align: ${props => props.textAlign ? props.textAlign : "left"};
  padding: 0em 0.5em;
  color: ${props => props.isActive ? props.theme.colors.brightRed : props.theme.colors.thinGray};
`
const StoreyInput = styled.input`
  border-radius: 0.3125rem;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primeBlack};
  outline: 0;
  border: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  letter-spacing: -0.015rem;
  flex: 1;
  height: 2rem;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  ::placeholder {
    font-family: ${(props) => props.theme.font};
    font-weight: 500;
    opacity: 0.5;
  }
  .edit-btn {
    font-weight: bold;
  }
`
const Switch = styled.div`
  box-sizing: border-box;
  width: 25px;
  height: 15px;
  border: 2px solid #BDBBBB;
  border-radius: 10px;
  display: flex;
  align-items: center;
  curson: pointer;
`
const Slider = styled.div`
  border-radius: 13px;
  width: 13px;
  height: 13px;
  background-color: #EB1E45;
  transition: all 0.5s;
`
const CheckboxSwitch = ({isActive, setIsActive}) => {
  return <Switch onClick={() => setIsActive(!isActive)}>
    <Slider style={{transform: `translateX(${isActive ? "0px" : "7.5px"})`}} />
  </Switch>
}

const NewStoreyModalOnUnselected = ({ isClone, setIsClone, numOfCopies, setNumOfCopies, onDuplicateSelection, hideConfirmationButton = false, appendAbove}) => {
  return <>
    <Row>
      <Col textAlign="right">Copies</Col>
      <Col style={{width: "20%"}}>
        <StoreyInput 
          style={{width: "100%"}} 
          type="number" 
          min={1} 
          value={numOfCopies}
          onChange={(e) => {setNumOfCopies(e.target.value)}} 
        />
      </Col>
    </Row>
    <Row style={{margin: "1rem 0rem"}}>
      <Col textAlign="right" isActive={isClone} >Clone</Col>
      <Col textAlign="center">
        <CheckboxSwitch 
            isActive={isClone} 
            setIsActive={setIsClone}
        />  
      </Col>
      <Col isActive={!isClone}>Unique</Col>
    </Row>
    {
      hideConfirmationButton ?
        <></>
      :
      <Row>
        <Col textAlign="center">
          <Button 
            outline
            title={`Copy selection ${appendAbove ? "above" : "below"}`} 
            customButtonStyle={{
              padding: "0.25rem 0.625rem",
              minHeight: "auto",
            }}
              onPress={() => {onDuplicateSelection()}}
          />
        </Col>
      </Row>
    }
  </>
}

const NewStoreyModalOnSelected = ({isCopy, setIsCopy, isClone, setIsClone, numberOnBlankMode, setNumberOnBlankMode, storeyOnCopyMode, setStoreyOnCopyMode,  numOfCopies, setNumOfCopies, onConfirmation, onDuplicateSelection, appendAbove}) => {
  return <>
    <Row>
      <Col textAlign="right" isActive={isCopy} >Copy</Col>
      <Col textAlign="center">
        <CheckboxSwitch 
            isActive={isCopy} 
            setIsActive={setIsCopy}
        />  
      </Col>
      <Col isActive={!isCopy}>Blank</Col>
    </Row>
    {
      isCopy ?
        <>
          <Row style={{margin: "1rem 0rem"}}>
            <Col textAlign="right">Storey</Col>
            <Col style={{width: "20%"}}>
              <StoreyInput 
                style={{width: "100%"}} 
                type="number"
                value={storeyOnCopyMode}
                onChange={(e) => {setStoreyOnCopyMode(e.target.value)}} 
              />
            </Col>
          </Row>
          <Row style={{margin: "1rem 0rem"}}>
            <Col style={{color: colors.secondaryGrey, fontWeight: "300"}}>Or select object on canvas</Col>
          </Row>
          <NewStoreyModalOnUnselected 
            isClone={isClone} 
            setIsClone={setIsClone}
            numOfCopies={numOfCopies}
            setNumOfCopies={setNumOfCopies}
            onConfirmation={onConfirmation}
            hideConfirmationButton={true}
          />
        </>
      :
      <Row style={{margin: "1rem 0rem"}}>
        <Col textAlign="right">Number</Col>
        <Col style={{width: "20%"}}>
          <StoreyInput 
            style={{width: "100%"}} 
            type="number" 
            min={1} 
            value={numberOnBlankMode}
            onChange={(e) => {setNumberOnBlankMode(e.target.value)}} 
          />
        </Col>
      </Row>
    }
    <Row>
      <Col textAlign="center">
        <Button 
          outline
          title={
            isCopy ? 
              `Copy Storey ${storeyOnCopyMode} ${appendAbove ? "above" : "below"}`
              :
              `Create new storey ${appendAbove ? "above" : "below"}`
          }
          customButtonStyle={{
            padding: "0.25rem 0.625rem",
            minHeight: "auto",
          }}
          onPress={() => {
            isCopy ?
              onDuplicateSelection(storeyOnCopyMode) : onConfirmation()
          }}
        />
      </Col>
    </Row>
  </>
}

const GetStoreyModal = ({appendLayers, appendAbove, closePopUp, isCopy, setIsCopy, isClone, setIsClone, numberOnBlankMode, setNumberOnBlankMode, storeyOnCopyMode, setStoreyOnCopyMode,  numOfCopies, setNumOfCopies}) => {
  const ref = useRef(null);
  const closeOnOutsideClick = (evt) => {
    if(!ref.current.contains(evt.target)) closePopUp();
  }

  useEffect(() => {
    window.addEventListener("click", closeOnOutsideClick);
    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
    }
  }, [closeOnOutsideClick]);
  
  
  const onConfirmation = () => {
    appendLayers(!appendAbove);
    closePopUp();
  }

  const onDuplicateSelection = (storeyValue) => {
    if (storeyValue) {
      store.selectionStack.forEach(m => {
        if (m.children) {
          m.children.forEach(child => {
            child.dispose();
          })
        }
      })
      store.selectionStack.length = 0;
      const storey = StoreyMutation.getAllStoreys()[storeyValue]
      store.selectionStack.push(...storey.elements.map(e => e.mesh))
    }
    StoreyMutation.setStoreyUnique(!isClone);
    if (appendAbove) {
      StoreyMutation.duplicateStoreys(
        store.activeLayer.structure_id,
        1,
        numOfCopies,
      );
    }
    else {
      StoreyMutation.duplicateStoreys(
        store.activeLayer.structure_id,
        0,
        numOfCopies
      );
    }
    closePopUp();
  }

  return (
    <StoreyModal ref={ref}>
      <Container>
      {
        store.selectionStack.length !== 0 ?
          <NewStoreyModalOnUnselected 
            isClone={isClone} 
            setIsClone={setIsClone}
            numOfCopies={numOfCopies}
            setNumOfCopies={setNumOfCopies}
            onDuplicateSelection={onDuplicateSelection}
            appendAbove={appendAbove}
          />
        :
        <NewStoreyModalOnSelected 
          isClone={isClone} 
          setIsClone={setIsClone}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
          numOfCopies={numOfCopies}
          setNumOfCopies={setNumOfCopies}
          storeyOnCopyMode={storeyOnCopyMode}
          setStoreyOnCopyMode={setStoreyOnCopyMode}
          numberOnBlankMode={numberOnBlankMode}
          setNumberOnBlankMode={setNumberOnBlankMode}
          onConfirmation={onConfirmation}
          onDuplicateSelection={onDuplicateSelection}
          appendAbove={appendAbove}
        />
      }
      </Container>
    </StoreyModal>
  );
};

const blankStorey = {
  "id": null,
  "name": "",
  "value": 1,
  "height": 3000,
  "structure_id": null,
  "hidden": false,
  "layers": []
}

function LeftMenu(props) {
  const {selectedStorey, setSelectedStorey} = props;
  const [storeyArray, setStoreyArray] = useState([]);
  const [copyStoreyArray, setCopyStoreyArray] = useState([]);
  const storeyArrayRedux = useSelector(state => state.storeys.items);
  const [isNewStoreyModalVisible, setIsNewStoreyModalVisible] = useState(false);
  const [appendAbove, setAppendAbove] = useState(true);
  const [storeyVisible, setStoreyVisible] = useState({low: 0, high: 5});
  
  // STOREY MODAL STATES
  const [isClone, setIsClone] = useState(true);
  const [isCopy, setIsCopy] = useState(false);
  const [storeyOnCopyMode, setStoreyOnCopyMode] = useState(1);
  const [numberOnBlankMode, setNumberOnBlankMode] = useState(1);
  const [numOfCopies, setNumOfCopies] = useState(1);

  useEffect(() => {
    // console.log("Use effect inside LeftMenu called.")
    // console.log(storeyArrayRedux);
    const newStoreyArray = storeyArrayRedux.slice();
    setStoreyArray(newStoreyArray);
    // setCopyStoreyArray(newStoreyArray)
    if(newStoreyArray.length <= 5) setCopyStoreyArray(newStoreyArray);
    
  }, [storeyArrayRedux])
  const dispatch = useDispatch();

  const handleStoreyPropertyChange = (storeyValue, property, value) => {
    // const newArray = storeyArray.slice();
    // newArray[storeyIndex][property] = value;
    // setStoreyArray(newArray);
    // setCopyStoreyArray(newArray);
    dispatch(updateStorey({ storeyValue, property, value }));
  }

  const onSelectStorey = (storeyValue) => {
    StoreyMutation.selectStorey(
      store.activeLayer.structure_id,
      storeyValue
    )
  }

  const handleStoreySelect = useCallback((evt) => {
    const {detail} = evt;
    const {storeyValue, viaCommentSelect} = detail;
    onSelectLayer(storeyValue, viaCommentSelect);
  }, [])

  useEffect(() => {
    window.addEventListener("select-storey", handleStoreySelect);
    return () => {
      window.removeEventListener("select-storey", handleStoreySelect);
    }
  }, [handleStoreySelect]);

  const onSelectLayer = (storeyValue, viaCommentSelect) => {
    setSelectedStorey(storeyValue, viaCommentSelect); 
    const storeySelectEvent = new CustomEvent("storey-select", {
      detail: {
        storeyNumber: storeyValue,
      }
    })
    window.dispatchEvent(storeySelectEvent);
    // Save and set 2D-2D views
    cameraController.recordPriorParams();
    layerView.selectLayer("Wall", store.activeLayer.structure_id, storeyValue);
    cameraController.setParams();
    store.idle = true;
  }

  const appendLayers = (dec) => {
    dispatch(addBlankStoreys(numberOnBlankMode, appendAbove));
    
    // FOR UI UPDATE
    if(storeyArrayRedux.length + numberOnBlankMode > 5){
      if(appendAbove) {
        setStoreyVisible({
          low: 0,
          high: 5
        })
      }else{
        const high = storeyArrayRedux.length + parseInt(numberOnBlankMode);
        const low = high - 5;
        setStoreyVisible({
          low: low,
          high: high
        })
      }
    }
  };

  const layerNavigationPress = (position) => {
    if(position === "top"){
      setStoreyVisible({
        low: storeyVisible.low - 1,
        high: storeyVisible.high - 1
      })
    }else{
      setStoreyVisible({
        low: storeyVisible.low + 1,
        high: storeyVisible.high + 1
      })
    }
  };
  return (
    <Wrapper style={{zIndex: 2}}>
      <StoreyControlsWrapper>
      <ImageButton
        style={{ marginTop: "0" }}
        className={`storyIconWrapper ${storeyArrayRedux.length > 5 ? "increasedHeight" : ""} `}
        onClick={() => {setIsNewStoreyModalVisible(true); setAppendAbove(true); setSelectedStorey(null)}}
      >
        <img
          data-tip
          data-for={"add-storey"}
          className={`storeyIcon ${(isNewStoreyModalVisible && appendAbove) ? "active" : ""}`}
          src={icons.STOREY_UP[1]}
          alt={icons.STOREY_DOWN[0]}
          style={{width: "15px", height: "15px"}}
        />
        <Tooltip id={"add-storey"}>Add new storey</Tooltip>
      </ImageButton>
      <MainLayerWrapper>
        {storeyArrayRedux.length > 5 && storeyVisible.low > 0 && (
          <LayerNavigation
            style={{ top: "-0.5rem" }}
            onClick={() => layerNavigationPress("top")}
          >
            <img src={icons.TOP_NAV_ARROW} alt="up" />
          </LayerNavigation>
        )}
        <LayerDiv style={{ overflow: "visible" }}>
          {storeyArrayRedux.map((storey, index) => (
            (index < storeyVisible.low || index >= storeyVisible.high) ? <></> :
            <SingleLayerCell
              key={index}
              // onClick={() => {
              //   setSelectedStorey(storey.value);
              // }}
            >
              <LayerContainer
                data-tip
                data-for={"storey-"+storey.value}
                onClick={() => {onSelectLayer(storey.value)}}
              >
                <p
                  style={
                    selectedStorey === storey.value
                      ? { color: colors.brightRed }
                      : {}
                  }
                >
                  {
                    storey.name === "" ? storey.value :
                      storey.name.length > 10 ? storey.name.slice(0,10) + "..." : storey.name
                  }
                  {/* {storey.value} */}
                </p>
                <Tooltip id={"storey-"+storey.value}>{ storey.name === "" ?  "Storey " + storey.value :
                storey.name.length > 10 ? storey.name.slice(0,10) + "..." : storey.name }</Tooltip>
              </LayerContainer>
              <div
                className="layerHover"
                style={
                  selectedStorey === storey.value ? { display: "flex" } : {}
                }
              >
                <LayeredExpandPopup onPress={setSelectedStorey}
                  storeyIndex={index}
                  storeyValue={storey.value}
                  storeyHeight={storey.height}
                  isHidden={storey.hidden}
                  layers={storey.layers}
                  handleStoreyPropertyChange={handleStoreyPropertyChange}
                  onSelectStorey={onSelectStorey}
                  onSelectLayer={onSelectLayer}
                  setSelectedStorey={setSelectedStorey}
                />
              </div>
            </SingleLayerCell>
          ))}
        </LayerDiv>
        {storeyArrayRedux.length > 5 && storeyVisible.high < storeyArrayRedux.length &&
           (
            <LayerNavigation
              style={{ bottom: "-0.5rem" }}
              onClick={() => layerNavigationPress("bottom")}
            >
              <img src={icons.BOTTOM_NAV_ARROW} alt="down" />
            </LayerNavigation>
          )}
      </MainLayerWrapper>
      <ImageButton
        className={`storyIconWrapper ${storeyArrayRedux.length > 5 ? "increasedHeight" : ""} `}
        // onClick={() => appendLayers(true)}
        onClick={() => { setIsNewStoreyModalVisible(true); setAppendAbove(false); setSelectedStorey(null)}}
      >
        <img
          data-tip
          data-for={"add-basement"}
          className={`storeyIcon ${(isNewStoreyModalVisible && !appendAbove) ? "active" : ""}`}
          src={icons.STOREY_DOWN[1]}
          alt={icons.STOREY_DOWN[0]}
          style={{width: "15px", height: "15px"}}
        />
        <Tooltip id={"add-basement"}>Add new basement</Tooltip>
      </ImageButton>
      </StoreyControlsWrapper>
      <StoreyModalWrapper>
        {isNewStoreyModalVisible && (
          <StoreyMainWrapper>
            <GetStoreyModal 
              appendLayers={appendLayers} 
              appendAbove={appendAbove} 
              closePopUp={() => {setIsNewStoreyModalVisible(false)}}
              isClone={isClone}
              isCopy={isCopy}
              storeyOnCopyMode={storeyOnCopyMode}
              numberOnBlankMode={numberOnBlankMode}
              numOfCopies={numOfCopies}
              setIsClone={setIsClone}
              setIsCopy={setIsCopy}
              setStoreyOnCopyMode={setStoreyOnCopyMode}
              setNumberOnBlankMode={setNumberOnBlankMode}
              setNumOfCopies={setNumOfCopies}
            />
          </StoreyMainWrapper>
        )}
      </StoreyModalWrapper>
    </Wrapper>          
  )
}

export default LeftMenu
