"use strict";
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import {
  getSerializedFormOfMesh,
  getCSGFormOfMesh,
  onSolid,
  removeMeshFromStructure,
} from "../extrafunc.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";
import { copyMaterialData } from "../../libs/mats.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { CommandManager } from "../commandManager/CommandManager.js";
// import { drawWindow } from "../../libs/drawWindowEvents.js";
import { drawWindow } from "../../libs/drawWindows2D";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { ComponentManager } from "../componentManager/componentManager.js";
import { StateMachine } from "../Classes/StateMachine.js";
import { nearestPointOnLine } from "../../libs/snapFuncs.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { Window } from "../snaptrudeDS/window.ds.js";
import {GLOBAL_CONSTANTS} from "../utilityFunctions/globalConstants";
import {scenePickController} from "../utilityFunctions/scenePickController";
import { cursor } from "../../../themes/cursor.js";
import { updateCursor } from "../../../containers/editor/cursorHandler.js";
import stackedWallHelper from "../stackedWalls/stackedWallHelper";
import {doorOperation} from "./doorOperation";

var windowOperation = (function () {
  "use strict";
  let _windowMeshName = null;
  let _window = null;
  let _selectionBox = null;
  let windowt1 = null;
  let windowt2 = null;
  let updateWindowOffset = null;

  let _newWindowInScene = false;

  var _drawWindow = function (pickInfo, _windowInstance, _window, distance) {
    try {
      if (_selectionBox) {
        if (pickInfo.pickedMesh.childrenComp.length === 0)
          pickInfo.pickedMesh.getSnaptrudeDS().originalWallMesh =
            getSerializedFormOfMesh(pickInfo.pickedMesh);

        let options = {
          preserveChildren: true,
        };

        let wallDeletionCommandData =
          commandUtils.deletionOperations.getCommandData(
            pickInfo.pickedMesh,
            null,
            options
          );

        let windowCSG = getCSGFormOfMesh(_selectionBox);
        let wallCSG = getCSGFormOfMesh(pickInfo.pickedMesh);

        let newCSG = wallCSG.subtract(windowCSG);

        let newWallMesh = newCSG.toMesh("wall", null, store.scene);
        newWallMesh.type = "wall";

        newWallMesh.position = pickInfo.pickedMesh.position;
        newWallMesh.storey = pickInfo.pickedMesh.storey;
        let wall = new Wall(newWallMesh, pickInfo.pickedMesh.room_id);
        wall.assignProperties();

        let oldWallDS = pickInfo.pickedMesh.getSnaptrudeDS();

        wall.wThickness = oldWallDS.wThickness;
        wall.localLineSegment = oldWallDS.localLineSegment;
        wall.originalWallMesh = oldWallDS.originalWallMesh;

        // Storing brep in originalWallMesh. Needed after deletion of fenestration
        if (oldWallDS.brep) {
          wall.originalWallMesh.brep = store.resurrect.stringify(
            oldWallDS.brep
          );
        }

        wall.neighbours = oldWallDS.neighbours;
        wall.neighboursDetails = oldWallDS.neighboursDetails;
        wall.setTopCoords(oldWallDS.topCoords);
        wall.setBottomCoords(oldWallDS.bottomCoords);
        wall.setMidYHeight(oldWallDS.midY);
        wall.direction = oldWallDS.direction;
        wall.bottomLineSegment = oldWallDS.bottomLineSegment;
        wall.room_id = oldWallDS.room_id;
        wall.profile = oldWallDS.profile;
        wall.topLineSegment = oldWallDS.topLineSegment;
        wall.properties = oldWallDS.properties;
        wall.revitMetaData = oldWallDS.revitMetaData;
        // wall.createdByCB = oldWallDS.createdByCB;

        if (!wall.brep) {
          copyMaterialData(pickInfo.pickedMesh, newWallMesh);
        }

        let structures = StructureCollection.getInstance();
        wall.mesh.structure_id = pickInfo.pickedMesh.structure_id;
        let structure_id = pickInfo.pickedMesh.structure_id;
        let str = structures.getStructureById(wall.mesh.structure_id);
        let level = str.getLevelByUniqueId(
          str.getObjectByUniqueId(pickInfo.pickedMesh.uniqueId).level_id
        );
        level.addWallToLevel(wall, false);

        newWallMesh.childrenComp = pickInfo.pickedMesh.childrenComp;
        newWallMesh.childrenComp.push(_windowInstance);
        _windowInstance.structure_id = structure_id;
        _windowInstance.storey = newWallMesh.storey;
        let _newWindow = new Window(_windowInstance);
        level.addWindowToLevel(_newWindow, false);

        const stackedWallCommand = stackedWallHelper.update(pickInfo.pickedMesh, newWallMesh);

        newWallMesh.childrenComp.forEach(function (child) {
          child.setParent(newWallMesh);
        });

        let options1 = {
          postExecuteCallback: windowInstanceCreationPostExecution,
          preUnExecuteCallback: windowInstanceCreationPreUnExecution,
        };

        let linkedListData = StoreyMutation.replaceElementInDLL(
          oldWallDS,
          wall
        );

        let creationCommandData =
          commandUtils.creationOperations.getCommandData(_windowInstance);
        let windowInstanceCreationCmd =
          commandUtils.creationOperations.getCommand(
            "windowInstanceCreation",
            creationCommandData,
            options1
          );
        let wallCreationCommand =
          commandUtils.creationOperations.getCommandData(newWallMesh);
        let wallCreationCmd = commandUtils.creationOperations.getCommand(
          "wallWithHoleCreation",
          wallCreationCommand
        );
        let deletionCmd = commandUtils.deletionOperations.getCommand(
          "wallDeletion",
          wallDeletionCommandData,
          options
        );

        let dllCmd = null;
        if (linkedListData) {
          dllCmd = commandUtils.linkedListOperations.getCommand(
            "updateLinkedListCluster",
            linkedListData
          );
        }

        _window.scaling = new BABYLON.Vector3(0, 0, 0);
        _window.position = new BABYLON.Vector3(10000, 0, 10000);
        // _window.rotation = new BABYLON.Vector3(0, 0, 0);

        isCommandDataEmpty([wallDeletionCommandData, wallCreationCommand]);

        if (_newWindowInScene) {
          let creationCommandData =
            commandUtils.creationOperations.getCommandData(_window);
          let windowCreationCmd = commandUtils.creationOperations.getCommand(
            "windowCreation",
            creationCommandData
          );

          let allCommands = [
            wallCreationCmd,
            windowCreationCmd,
            windowInstanceCreationCmd,
            deletionCmd,
            dllCmd,
          ];

          const yets = [
            false,
            false,
            false,
            true,
            false,
          ];

          allCommands = _.compact(allCommands);
          doorOperation.util.addStackedWallCommand(allCommands, yets, stackedWallCommand);
          CommandManager.execute(allCommands, yets);

          _newWindowInScene = false;
        } else {
          let allCommands = [
            wallCreationCmd,
            windowInstanceCreationCmd,
            deletionCmd,
            dllCmd,
          ];

          const yets = [
            false,
            false,
            true,
            false
          ];

          allCommands = _.compact(allCommands);
          doorOperation.util.addStackedWallCommand(allCommands, yets, stackedWallCommand);
          CommandManager.execute(allCommands, yets);
        }

        onSolid(newWallMesh);
        onSolid(_windowInstance);
      }
    } catch (err) {
      console.log("WINDOW OPERATION FAILED", err);
      windowOperation._reset();
    }
  };

  var _getNearestPoint = function (vw, point) {
    let pt1 = null;
    let pt2 = null;
    let pts = [];
    switch (point) {
      case "start":
        if (
          BABYLON.Vector3.Distance(vw[0], vw[4]) <
          BABYLON.Vector3.Distance(vw[0], vw[2])
        ) {
          pt1 = vw[3]
            .add(vw[6])
            .scale(0.5)
            .add(vw[0].add(vw[4]).scale(0.5))
            .scale(0.5);
          pt2 = vw[5]
            .add(vw[1])
            .scale(0.5)
            .add(vw[2].add(vw[7]).scale(0.5))
            .scale(0.5);
        } else {
          pt1 = vw[0]
            .add(vw[2])
            .scale(0.5)
            .add(vw[5].add(vw[3]).scale(0.5))
            .scale(0.5);
          pt2 = vw[7]
            .add(vw[4])
            .scale(0.5)
            .add(vw[1].add(vw[6]).scale(0.5))
            .scale(0.5);
        }
        pts = [pt1, pt2];
        break;
      case "end":
        if (
          BABYLON.Vector3.Distance(vw[0], vw[2]) <
          BABYLON.Vector3.Distance(vw[0], vw[4])
        ) {
          pt1 = vw[5]
            .add(vw[2])
            .scale(0.5)
            .add(vw[3].add(vw[0]).scale(0.5))
            .scale(0.5);
          pt2 = vw[1]
            .add(vw[7])
            .scale(0.5)
            .add(vw[6].add(vw[4]).scale(0.5))
            .scale(0.5);
        } else {
          pt1 = vw[3]
            .add(vw[0])
            .scale(0.5)
            .add(vw[6].add(vw[4]).scale(0.5))
            .scale(0.5);
          pt2 = vw[5]
            .add(vw[2])
            .scale(0.5)
            .add(vw[1].add(vw[7]).scale(0.5))
            .scale(0.5);
        }
        pts = [pt1, pt2];
        break;
    }

    return pts;
  };

  let setWindow2d = function (windowInstance) {
    if (windowInstance.getChildren() != 0)
      windowInstance.getChildren()[0].dispose();
    let subType;
    if (windowInstance.isAnInstance) {
      subType = windowInstance.sourceMesh.getSnaptrudeDS().subType;
    } else {
      subType = windowInstance.getSnaptrudeDS().subType;
    }
    let WindowWidth = windowInstance._boundingInfo.boundingBox.extendSize.x * windowInstance.absoluteScaling.x * 2;
    let WindowThickness = windowInstance._boundingInfo.boundingBox.extendSize.z * windowInstance.absoluteScaling.z * 2;
    if (subType) {
      let {symbolData} = windowInstance.getSnaptrudeDS()
      if (symbolData) subType = "revitImport"
      let window2D = drawWindow.createDrawing(subType, WindowWidth, symbolData); // fetch door type from door mesh DS based on category
      if (window2D) {
        if (subType == "revitImport") {
          window2D.setParent(windowInstance);
          window2D.position = BABYLON.Vector3.Zero();
        } else {
          window2D.setParent(windowInstance);
          window2D.position = BABYLON.Vector3.Zero();
          window2D.position.x =
            window2D.parent._boundingInfo.boundingBox.minimum.x;
          window2D.position.y =
            window2D.parent._boundingInfo.boundingBox.minimum.y;
          window2D.position.z = -0.05;
          window2D.rotation = BABYLON.Vector3.Zero();
        }

        window2D.isVisible = store.$scope.isTwoDimension;
        //window2D.visibility = (store.$scope.isTwoDimension) ? 1 : 0;
        window2D.type =
          GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator;
        scenePickController.add(window2D);
      }
    }
  };

  let windowInstanceCreationPostExecution = function () {
    this.data.forEach((dataPoint) => {
      let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
      if (mesh) {
        setWindow2d(mesh);
      }
    });
  };

  let windowInstanceCreationPreUnExecution = function () {
    this.data.forEach((dataPoint) => {
      let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
      if (mesh) {
        mesh.getChildren().forEach((c) => c.dispose());
      }
    });
  };

  function loadWindowMesh(subType, tasks) {
    try {
      if (tasks.length > 0) {
        let mesh = tasks[0].loadedMeshes[0];
        mesh.name = tasks[0].name;
        mesh.type = "window";
        mesh.originalScaling = mesh.scaling.clone();
        ComponentManager.prepareLoadedMesh(mesh);
        mesh.getSnaptrudeDS().subType = subType;

        dispatchEvents.call(this, mesh);
        this.setMetadata();
      }
      ComponentManager.reset();
    } catch (err) {
      console.log(err);
      document.body.style.cursor = "default";
      ComponentManager.reset();
    }
  }

  function dispatchEvents(mesh) {
    document.body.style.cursor = "default";
    this.setWindowMeshName(mesh.name);
    store.canvas.addEventListener("pointerdown", this.onPointerDown, false);
    store.canvas.addEventListener("pointermove", this.onPointerMove, false);
    store.canvas.addEventListener("pointerup", this.onPointerUp, false);
    store.ACTIVE_EVENT = { event: "windowOperation" };
  }

  function setWindowMeshName(name) {
    _windowMeshName = name;
  }

  function clearEvents() {
    store.canvas.removeEventListener("pointerdown", this.onPointerDown, false);
    store.canvas.removeEventListener("pointerup", this.onPointerUp, false);
    store.canvas.removeEventListener("pointermove", this.onPointerMove, false);
    return this._reset();
  }

  function isCommandDataEmpty(commandData) {
    commandData.forEach((data) => {
      if (_.isEmpty(data)) {
        throw "Command Data Empty";
      }
    });
  }
  return {
    onPointerDown: function (evt) {
      if (StateMachine.EventValidation.isPointerDownWithMiddleButton(evt))
        return;
      if (store.isiPad) {
        // store.newScene.activeCamera.detachControl(canvas);
      }
      let pickInfo = store.newScene.pick(
        store.newScene.pointerX,
        store.newScene.pointerY,
        function (mesh) {
          return mesh.type.toLowerCase() === "wall" && mesh.isVisible;
        }
      );

      if (pickInfo.hit) {
        if (_window) {
          let windowName = _window.name + "Ins" + _window.instances.length;
          let _windowInstance = _window.createInstance(windowName);
          _windowInstance.type = "window";
          _drawWindow(pickInfo, _windowInstance, _window, null);
          setWindow2d(_windowInstance);
        }
      }
    },

    onPointerMove: function (evt) {
      try {
        if (
          StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(evt)
        )
          return;
        let pickInfo = store.newScene.pick(
          store.newScene.pointerX,
          store.newScene.pointerY,
          function (mesh) {
            return mesh.type.toLowerCase() === "wall" && mesh.isVisible;
          }
        );

        if (pickInfo.hit) {
          updateCursor(cursor.placeObject);
          pickInfo.pickedMesh.computeWorldMatrix(true);
          let bbinfo1 = pickInfo.pickedMesh.getBoundingInfo();
          let pickedPoint = pickInfo.pickedPoint;
          let pickNormal = pickInfo.getNormal(true, false);
          let wm = pickInfo.pickedMesh.getWorldMatrix();
          let wmR = wm.getRotationMatrix();
          let normal = BABYLON.Vector3.TransformCoordinates(pickNormal, wmR).normalize();

          let wallThickness = pickInfo.pickedMesh
            .getSnaptrudeDS()
            .calculateWidth();
          let pointSet = pickInfo.pickedMesh.getSnaptrudeDS().edgeMidPts();

          _window = store.newScene.getMeshByName(_windowMeshName);
          _window.visibility = 1;

          let bbinfo = _window.getBoundingInfo();
          let _offset = wallThickness / 2;

          _window.scaling = new BABYLON.Vector3(
            _window.originalScaling.x,
            _window.originalScaling.y,
            _window.originalScaling.z
          );

          let _windowChildren = _window.getChildren();
          if (_windowChildren.length === 0) {
            setWindow2d(_window);
          }

          let wallAngle;
          if (Math.round(normal.y) === 1) {
            // let worldMatrix = pickInfo.pickedMesh.getSnaptrudeDS().getSupervisedWorldMatrix();
            // let wallTopEndPoints = pickInfo.pickedMesh.getSnaptrudeDS().getLocalLineSegment();
            // wallTopEndPoints = convertLocalCoordsToGlobal(wallTopEndPoints, worldMatrix)
            //     .map(point => BABYLON.Vector3.FromArray(point));
            //
            // wallTopEndPoints = wallTopEndPoints.map(vec =>
            //     vec.snaptrudeFunctions().roundOff(3)
            // );

            let pickedPointPrecision = pickedPoint.clone();
            pickedPointPrecision.snaptrudeFunctions().roundOff(3);
            let snapPoint = nearestPointOnLine(
              pickedPointPrecision,
              pointSet[0],
              pointSet[1]
            );
            wallAngle = Wall.calculateAngleByMidLine(pointSet[0], pointSet[1]);

            _window.position.x = snapPoint.x;
            _window.position.z = snapPoint.z;
          } else {
            wallAngle = Wall.calculateAngleByFaceNormal(pickedPoint, normal);

            _window.position.x = pickedPoint.x;
            _window.position.z = pickedPoint.z;
            _window.position.x -= _offset * Math.sin(wallAngle);
            _window.position.z -= _offset * Math.cos(wallAngle);
          }

          _window.position.y =
            0.9 / (store.unit_absolute_scale * 10) +
            bbinfo1.boundingBox.minimumWorld.y +
            bbinfo.boundingBox.extendSizeWorld.y;

          // _window.rotation = BABYLON.Vector3.Zero();
          if (_selectionBox) _selectionBox.dispose();
          _window.rotation.y = 0;
          _window.computeWorldMatrix(true);
          _selectionBox = windowOperation.createWindowSelectionBox(
            _window,
            wallThickness
          );

          _window.rotation.y = wallAngle;
          _window.isPickable = false;

          _selectionBox.scaling = _selectionBox.originalScaling.clone();
          _selectionBox.position = _window.position.clone();
          _selectionBox.rotation.y = wallAngle;

          let vw = bbinfo1.boundingBox.vectorsWorld;
          let vw2 = bbinfo.boundingBox.vectorsWorld;
          let end = _window.position.clone();
          let st1 = pointSet[0];
          let st2 = pointSet[1];
          // let end = Object.assign({}, _window.position);
          // let st1 = _getNearestPoint(vw, "start")[0];
          // let st2 = _getNearestPoint(vw, "start")[1];
          // let end1 = _getNearestPoint(vw2, "end")[0];
          // let end2 = _getNearestPoint(vw2, "end")[1];

          //doing it to get this in global cope to update on keypress event
          windowt1 = st1;
          windowt2 = st2;

          if (
            BABYLON.Vector3.Distance(st1, end) <=
            BABYLON.Vector3.Distance(st2, end)
          ) {
            //    (BABYLON.Vector3.Distance(st1, end1) <= BABYLON.Vector3.Distance(st1, end2)) ? end = end1 : end = end2;
            st1.y = end.y;
            updateWindowOffset = BABYLON.Vector3.Distance(
              _window.position,
              end
            );
            DisplayOperation.drawOnMove(st1, end);
            DisplayOperation.displayOnMove(
              BABYLON.Vector3.Distance(st1, end),
              "distBox",
              true
            );
          } else {
            //   (BABYLON.Vector3.Distance(st2, end1) <= BABYLON.Vector3.Distance(st2, end2)) ? end = end1 : end = end2;
            st2.y = end.y;
            updateWindowOffset = BABYLON.Vector3.Distance(
              _window.position,
              end
            );
            DisplayOperation.drawOnMove(st2, end);
            DisplayOperation.displayOnMove(
              BABYLON.Vector3.Distance(st2, end),
              "distBox",
              true
            );
          }
        } else {
          updateCursor(cursor.cantPlace);

          if (_window) {
            _window.scaling = new BABYLON.Vector3(0, 0, 0);
            _window.position = new BABYLON.Vector3(10000, 0, 10000);
            // _window.rotation = new BABYLON.Vector3(0, 0, 0);
          }

          if (_selectionBox) {
            _selectionBox.dispose();
          }
        }
      } catch (err) {
        console.log("WINDOW SNAP FAILED", err);
        if (_window) {
          _window.scaling = new BABYLON.Vector3(0, 0, 0);
          _window.position = new BABYLON.Vector3(10000, 0, 10000);
          // _window.rotation = new BABYLON.Vector3(0, 0, 0);
        }

        if (_selectionBox) {
          _selectionBox.dispose();
        }
      }
    },

    onPointerUp: function (evt) {},

    draw2d: function (instanceObj) {
      setWindow2d(instanceObj);
    },

    createWindowSelectionBox: function (windowComp, thickness) {
      let bbInfo = windowComp.getBoundingInfo();
      windowComp.freezeWorldMatrix();
      bbInfo.update(windowComp._worldMatrix);
      let height = bbInfo.boundingBox.extendSizeWorld.y * 2;
      let width = bbInfo.boundingBox.extendSizeWorld.x * 2;
      let depth = thickness + 0.2;
      windowComp.unfreezeWorldMatrix();

      let box = BABYLON.MeshBuilder.CreateBox(
        "windowBoxScale",
        {
          height: height,
          width: width,
          depth: depth,
          updatable: true,
        },
        store.scene
      );

      box.enableEdgesRendering();
      box.edgesWidth = 8.0;
      box.edgesColor = new BABYLON.Color4(0, 1, 0, 1);
      box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

      let mat = store.scene.getMaterialByName("windowBBMat");
      if (!mat) {
        mat = new BABYLON.StandardMaterial("windowBBMat", store.scene);
        mat.diffuseColor = new BABYLON.Color3(0.3, 1, 0.5);
        mat.alpha = 0.2;
        mat.backFaceCulling = false;
      }
      box.material = mat;
      box.isPickable = false;

      box.originalScaling = box.scaling.clone();
      return box;
    },

    updateWindowPosition: function (distance) {
      if (_selectionBox && _window) {
        let dist = DisplayOperation.getOriginalDimension(distance);
        if (
          BABYLON.Vector3.Distance(windowt1, _window.position) <=
          BABYLON.Vector3.Distance(windowt2, _window.position)
        ) {
          let pd = windowt1.subtract(_window.position).normalize();
          let p = windowt1.subtract(pd.scale(dist + updateWindowOffset));
          let endpt = windowt1.subtract(pd.scale(dist));
          _window.position = p;
          _selectionBox.position = p;
          _window.position.y = windowt1.y;
          DisplayOperation.drawOnMove(windowt1, endpt);
          DisplayOperation.displayOnMove(
            BABYLON.Vector3.Distance(windowt1, endpt)
          );
        } else {
          let pd = windowt2.subtract(_window.position).normalize();
          let p = windowt2.subtract(pd.scale(dist + updateWindowOffset));
          let endpt = windowt2.subtract(pd.scale(dist));
          _window.position = p;
          _selectionBox.position = p;
          _window.position.y = windowt2.y;
          DisplayOperation.drawOnMove(windowt2, endpt);
          DisplayOperation.displayOnMove(
            BABYLON.Vector3.Distance(windowt2, endpt)
          );
        }
        // let _windowInstance = _window.createInstance(_window.name);
        // _windowInstance.type = 'window';
        let pickInfo = store.newScene.pick(
          store.newScene.pointerX,
          store.newScene.pointerY,
          function (mesh) {
            return mesh.type.toLowerCase() === "wall" && mesh.isVisible;
          }
        );
        let windowName = _window.name + "Ins" + _window.instances.length;
        let _windowInstance = _window.createInstance(windowName);
        _windowInstance.type = "window";
        _drawWindow(pickInfo, _windowInstance, _window, null);
        setWindow2d(_windowInstance);
      }
    },

    _reset: function () {
      // if (isiPad) store.newScene.activeCamera.attachControl(canvas, true, false);
      let isWorking = false;
      if (_newWindowInScene) {
        let redundantWindow = store.scene.getMeshByName(_windowMeshName);
        if (redundantWindow) {
          removeMeshFromStructure(redundantWindow);
          redundantWindow.dispose();
        }
        _window = null;
        if (_selectionBox) _selectionBox.dispose();
        _selectionBox = null;
        // isWorking = true;
      }
      if (_window) {
        _window.scaling = new BABYLON.Vector3(0, 0, 0);
        _window.position = new BABYLON.Vector3(10000, 0, 10000);
        // _window.rotation = new BABYLON.Vector3(0, 0, 0);
        if (_selectionBox) _selectionBox.dispose();
        _selectionBox = null;
        _window = null;
        // isWorking = true;
      }
      _windowMeshName = null;
      windowOperation.windowMeshName = null;
      _newWindowInScene = false;
      return isWorking;
    },

    loadWindowMesh,
    dispatchEvents,
    windowMeshName: _windowMeshName,
    window: _window,
    setWindowMeshName,
    setMetadata: function () {
      _newWindowInScene = true;
    },
    setWindow2d,
    clearEvents,
  };
})();
export { windowOperation };
