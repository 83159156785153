import BABYLON from "../modules/babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { setUserSettingAndRecord, updateSubMeshes, } from "../modules/extrafunc.js";
import { click } from "./meshEvents.js";
import { computeAreaTriangle3D } from "./areaFuncs.js";
import { addMaterialToLayersFace, saveMaterialInBackend, } from "./applyMaterialFuncs.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { djangoUrl } from "../../services/url.constants.js";
import MATERIAL_STRUCTURE from "../modules/materialStructure/materialStructure";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds";
import {Door} from "../modules/snaptrudeDS/doors.ds";

function loadMaterials(paths, scene) {
  var mats = [];
  for (var i = 0; i < paths.length; i++) {
    var filename = paths[i].replace(/^.*[\\\/]/, "");
    var mat = new BABYLON.StandardMaterial("mat_" + filename, scene);
    // var mat = new BABYLON.StandardMaterial("mat_"+filename, scene);
    // mat.diffuseColor = new BABYLON.Color3(0.9, 0.9, 0.9);
    mat.diffuseColor = BABYLON.Color3.White();
    // var mat = new BABYLON.StandardMaterial("mat_"+filename, scene);
    // // mat.directIntensity = 1.5;
    // // mat.environmentIntensity = 0.5;
    // // mat.specularIntensity = 1.3;
    mat.cameraExposure = 0.9;
    mat.cameraContrast = 1.6;
    // mat.useGlossinessFromSpecularMapAlpha = true;
    // mat.diffuseColor = BABYLON.Color3.White();
    // mat.specularColor = new BABYLON.Color3(1.0, 1.0, 1.0);

    // mat.diffuseTexture = new BABYLON.Texture(window.location.origin + "/" + paths[i], scene);

    // mat.backFaceCulling = false;
    mats.push(mat);
  }
  return mats;
}

function loadWallMaterial() {
  var mat = new BABYLON.StandardMaterial("wall_mat", store.scene);
  mat.diffuseColor = BABYLON.Color3.White();
  // // mat.directIntensity = 23.0;
  mat.backFaceCulling = true;
  mat.isDefault = true;
  // // mat.environmentIntensity = 1.0;
  // // mat.specularIntensity = 0.25;
  // mat.microSurface = 0.05;
  // mat.specularColor = BABYLON.Color3.White();
  mat.materialType = "Paint";
  // let texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // texture.uScale = 5.0;
  // texture.vScale = 5.0;
  // mat.specularTexture = texture;
  return mat;
}

function loadFloorMaterial() {
  var mat = new BABYLON.StandardMaterial("floor_tile", store.scene);
  // mat.diffuseTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/tile2.jpg", store.scene);
  // mat.diffuseTexture.uScale = 1.0 / unit_scale / 5;
  // mat.diffuseTexture.vScale = 1.0 / unit_scale / 5;
  // // mat.directIntensity = 1.2;
  // // mat.directIntensity = 0.5;
  // // mat.environmentIntensity = 0.5;
  // // mat.specularIntensity = 0.0;
  mat.cameraExposure = 0.9;
  mat.cameraContrast = 1.6;
  mat.isDefault = true;

  // var texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // texture.uScale = 5.0;
  // texture.vScale = 5.0;
  // mat.specularTexture = texture;
  // mat.useGlossinessFromSpecularMapAlpha = true;
  mat.diffuseColor = BABYLON.Color3.Gray();
  mat.materialType = "Concrete";
}

function loadCeilingMaterial() {
  var mat = new BABYLON.StandardMaterial("ceiling_mat", store.scene);
  // mat.diffuseTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/tile2.jpg", store.scene);
  // mat.diffuseTexture.uScale = 1.0 / unit_scale / 5;
  // mat.diffuseTexture.vScale = 1.0 / unit_scale / 5;
  // // mat.directIntensity = 0.2;
  // // mat.environmentIntensity = 0.5;
  // // mat.specularIntensity = 0.0;
  mat.cameraExposure = 0.9;
  mat.cameraContrast = 1.6;
  mat.isDefault = true;

  // var texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // texture.uScale = 5.0;
  // texture.vScale = 5.0;
  // mat.specularTexture = texture;
  // mat.useGlossinessFromSpecularMapAlpha = true;
  mat.diffuseColor = new BABYLON.Color3(0.4, 0.4, 0.4);
  mat.materialType = "Concrete";
}

// function loadAllMaterials(paths, store.scene) {
//     var wall_mats = loadMaterials(paths.walls, store.scene);
//     return wall_mats;
// }

function changeTextureDetail(level){
  if (!level) return;
  if(store.userSettingsInStructure.textureDetail === level){
      if (level === "texture") _changeTextureDetail("color");
      else if (level === "color") _changeTextureDetail("texture");
  }
  else{
      _changeTextureDetail(level);        
  }

  // if (!level) return;
  // if (level === "texture")
  // if(!level)  return;
  // if(userSettingsInStructure.textureDetail === level)
  //     return;
  // if(_changeTextureDetail(level)){
  //     setUserSettingAndRecord("textureDetail", level);
  // }
}

function _changeTextureDetail(level){
  let status = false;
  if(level === "color"){
      let level = "color";
      // store.scene.texturesEnabled = false;
      // $('#colorEnabled').css('border','1px solid #d30041');
      // $('#textureEnabled').css('border','none');
      changeDiffuseColor(level);
      setUserSettingAndRecord("textureDetail", level);
      status = true;
  }
  else if(level === "texture"){
      let level = "texture";
      // store.scene.texturesEnabled = true;
      // $('#textureEnabled').css('border','1px solid #d30041');
      // $('#colorEnabled').css('border','none');
      changeDiffuseColor("texture");
      setUserSettingAndRecord("textureDetail", level);
      status = true;
  }
  return status;
}

function changeDiffuseColor(level){
  store.scene.materials.forEach(mat => {
    if(mat.id?.includes("terrainMaterial")) return // skip in case of terrain's material
    // let texture = mat.diffuseTexture;
    if(!mat.metadata)    mat.metadata = {};

    if(level === "color"){
      if( store.userSettingsInStructure.textureDetail !== 'color' ) {
        if(mat.diffuseTexture) { // remove texture temporarily
          mat.diffuseTextureCopy = mat.diffuseTexture
          mat.diffuseTexture = null
        }

        mat.metadata.diffuseColor = mat.diffuseColor;
        mat.diffuseColor = BABYLON.Color3.White();
      }
    }
    else if(level === "texture"){
      if(mat.metadata.diffuseColor){
        if(mat.diffuseTextureCopy) { // add texture back
          mat.diffuseTexture = mat.diffuseTextureCopy
          mat.diffuseTextureCopy = undefined
        }

        mat.diffuseColor = mat.metadata.diffuseColor;
      }
    }
    // if(texture){
    //
    // }
    // else{
    //     if(level === "color"){
    //       if( store.userSettingsInStructure.textureDetail !== 'color' ) {
    //         mat.metadata.diffuseColor = mat.diffuseColor;
    //         mat.diffuseColor = BABYLON.Color3.White();
    //       }
    //     }
    //     else if(level === "texture"){
    //         if(mat.metadata.diffuseColor){
    //             mat.diffuseColor = mat.metadata.diffuseColor;
    //         }
    //     }
    // }
  });
}

function textureOnLoadCallback(){
  if(!this.metadata)    this.metadata = {};
  // this.diffuseTexture.metadata.averageColor = findAverageColor(this.diffuseTexture.readPixels());
  if(store.userSettingsInStructure.textureDetail === "color"){
      this.metadata.diffuseColor = this.diffuseColor;
      this.diffuseColor = BABYLON.Color3.White();
  }
}

function findAverageColor(pixelArray) {
  if (!pixelArray) {
    return BABYLON.Color3.White();
  }
  let nPixels = pixelArray.length / 4, r = 0, g = 0, b = 0, a = 0;
  for (let i = 0; i < nPixels; i++) {
    r += pixelArray[4 * i];
    g += pixelArray[4 * i + 1];
    b += pixelArray[4 * i + 2];
  }
  r /= nPixels * 255;
  g /= nPixels * 255;
  b /= nPixels * 255;
  return new BABYLON.Color3(r, g, b);
}

function createMaterialFromImage(mat_url, mat_type, name) {
  // var mat = new BABYLON.StandardMaterial(mat.substring(mat.indexOf('materials'), mat.length-1), store.scene);
  var mat_name = name || mat_url.replace(/^.*[\\\/]/, "").split(".")[0];
  //console.log(mat_name);

  var existing_material = store.scene.getMaterialByName(mat_name);
  if (existing_material) {
    return existing_material;
  }
  var mat = new BABYLON.StandardMaterial(mat_name, store.scene);
  // mat.name = mat_name;
  mat.id = mat_name;
  mat.materialType = mat_type.charAt(0).toUpperCase() + mat_type.slice(1);
  console.log(mat.id);
  mat.diffuseColor = BABYLON.Color3.White();
  mat.diffuseTexture = new BABYLON.Texture(
    mat_url,
    store.scene,
    undefined,
    undefined,
    undefined,
    textureOnLoadCallback.bind(mat)
  );
  mat.diffuseTexture.hasAlpha = true;
  // mat.diffuseTexture.uScale = 1.0 / unit_scale / 1;
  // mat.diffuseTexture.vScale = 1.0 / unit_scale / 1;
  // // mat.directIntensity = 1.5;
  // mat.environmentIntensity = 1.0;
  // mat.specularIntensity = 0.3;
  mat.cameraExposure = 0.9;
  mat.cameraContrast = 1.6;

  // // mat.directIntensity = 8.0;
  mat.backFaceCulling = true;
  // // mat.environmentIntensity = 1.0;
  // // mat.specularIntensity = 0.3;
  // mat.microSurface = 0.3;
  // mat.specularColor = new BABYLON.Color3(1.0, 1.0, 1.0);

  // var texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // texture.uScale = 5.0;
  // texture.vScale = 5.0;
  // mat.specularTexture = texture;
  // mat.useGlossinessFromSpecularMapAlpha = true;

  // mat.zOffset = 2;
  return mat;
}

function applyMaterialToFace(
  temp_mesh,
  face_coords,
  face_coords_index,
  face_coords_index2,
  mat_name
) {
  let subMeshIndices;
  if (temp_mesh.material.subMaterials) {
    // var multimat = new BABYLON.MultiMaterial(mat_name.name, store.scene);
    var multimatSubs = temp_mesh.material.subMaterials;
    var mat_exist_flag = false;
    var mat_exist_index = -1;
    var mat_index;
    for (var p = 0; p < temp_mesh.material.subMaterials.length; p++) {
      if (temp_mesh.material.subMaterials[p].id === mat_name.id) {
        mat_exist_flag = true;
        mat_exist_index = p;
      }
    }
    if (!mat_exist_flag) {
      temp_mesh.material.subMaterials.push(mat_name);

      mat_index = multimatSubs.length - 1;
    } else {
      mat_index = mat_exist_index;
    }
  } else {
    let id = Math.random().toString(36).substr(2, 6) + "_";
    var multimat = new BABYLON.MultiMaterial(mat_name.name + id, store.scene);
    multimat.subMaterials.push(temp_mesh.material);
    multimat.subMaterials.push(mat_name);
    temp_mesh.material = multimat;
    var mat_index = multimat.subMaterials.length - 1;
  }

  /*
    Snippet to apply material to entire mesh
     */
  // temp_mesh.material = multimat;
  // var bbinfo = temp_mesh.getBoundingInfo();
  // if (bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z)
  //     var hscale = bbinfo.boundingBox.extendSizeWorld.x;
  // else
  //     var hscale = bbinfo.boundingBox.extendSizeWorld.z;
  //
  // var vscale = bbinfo.boundingBox.extendSizeWorld.y;
  // setUVScale(temp_mesh, hscale/4, vscale/4 );
  // return;

  var verticesCount = temp_mesh.getTotalVertices();
  var indices = temp_mesh.getIndices();
  var meshInd1, meshInd2;
  // var meshIndices = [];
  var meshIndices = face_coords_index2;
  // for (var i = 0; i < face_coords_index.length; i++) {
  //     meshIndices.push(face_coords_index[i][0] / 3);
  // }
  var n = meshIndices.length;
  var temp_mesh_indices = [];
  subMeshIndices = [];
  temp_mesh_indices.push(meshIndices[0]);
  for (var i = 1; i < meshIndices.length; i++) {
    if (meshIndices[i] !== meshIndices[i - 1] + 1) {
      subMeshIndices.push(temp_mesh_indices);
      temp_mesh_indices = [];
      temp_mesh_indices.push(meshIndices[i]);
    } else {
      temp_mesh_indices.push(meshIndices[i]);
    }
  }
  if (temp_mesh_indices.length) subMeshIndices.push(temp_mesh_indices);
  console.log(meshIndices);
  console.log(subMeshIndices);
  console.log(temp_mesh.material);
  if (temp_mesh.subMeshes.length > 1) {
    for (let q = 0; q < subMeshIndices.length; q++) {
      var meshInd1 = subMeshIndices[q][0];
      var meshInd2 = subMeshIndices[q][subMeshIndices[q].length - 1];
      if (meshInd2 % 2) meshInd2 += 1;
      for (var p = 0; p < temp_mesh.subMeshes.length; p++) {
        var indStart = temp_mesh.subMeshes[p].indexStart;
        var indEnd = temp_mesh.subMeshes[p].indexCount + indStart;
        var mat_idx = temp_mesh.subMeshes[p].materialIndex;
        if (meshInd1 === meshInd2) break;
        console.log(meshInd1, meshInd2);
        console.log(indStart, indEnd);
        console.log(jQuery.extend({}, temp_mesh.subMeshes));
        if (meshInd1 === indStart && meshInd2 === indEnd) {
          console.log("here1");
          temp_mesh.subMeshes[p].materialIndex = mat_index;
          console.log(temp_mesh.material);
          break;
        } else if (meshInd1 === indStart && meshInd2 > indEnd) {
          temp_mesh.subMeshes[p].materialIndex = mat_index;
          console.log("here2");
          // break;
        } else if (meshInd1 === indStart && meshInd2 < indEnd) {
          //Split the current submesh into meshind1 to meshind2 - mat_index and meshind2 to indEnd - mat_idx
          // temp_mesh.subMeshes[p].materialIndex = mat_index;
          temp_mesh.subMeshes.splice(p, 1);
          if (meshInd2 % 2) meshInd2 += 1;
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_index,
              meshInd1,
              meshInd2 - meshInd1,
              meshInd1,
              meshInd2 - meshInd1,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_idx,
              meshInd2,
              indEnd - meshInd2,
              meshInd2,
              indEnd - meshInd2,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          console.log("here3");
          console.log(temp_mesh.subMeshes);
          break;
        } else if (meshInd1 < indStart && meshInd2 === indEnd) {
          temp_mesh.subMeshes[p].materialIndex = mat_index;
          console.log("here4");
          break;
        } else if (meshInd1 > indStart && meshInd2 === indEnd) {
          //Split the current submesh into meshind1 to meshind2 - mat_idx and meshind2 to indEnd mat_index
          temp_mesh.subMeshes.splice(p, 1);
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_idx,
              0,
              verticesCount,
              indStart,
              meshInd1 - indStart,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_index,
              0,
              verticesCount,
              meshInd1,
              meshInd2 - meshInd1,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          // temp_mesh.subMeshes.push(new BABYLON.SubMesh(mat_index, 0, verticesCount, meshInd2, indEnd - meshInd2, temp_mesh));
          // temp_mesh.subMeshes.pop();
          console.log("here5");
          break;
        } else if (meshInd1 > indStart && meshInd2 > indEnd) {
          if (meshInd1 < indStart) {
            // split mesh into indStart to meshInd1 - mat_idx
            // meshInd1 to indEnd - mat_index
            temp_mesh.subMeshes.splice(p, 1);
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_idx,
                indStart,
                meshInd1 - indStart,
                indStart,
                meshInd1 - indStart,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_index,
                meshInd1,
                indEnd - meshInd1,
                meshInd1,
                indEnd - meshInd1,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
            console.log("here6");
            break;
          }
        } else if (meshInd1 < indStart && meshInd2 < indEnd) {
          if (meshInd2 > indStart) {
            // split mesh into meshInd1 to indexStart - mat_index
            // indexStart to meshInd2 - mat_index
            // meshInd2 to indEnd - mat_idx
            // temp_mesh.subMeshes.splice(p, 1);
            // temp_mesh.subMeshes.push(new BABYLON.SubMesh(mat_index, 0, verticesCount, meshInd1, indStart - meshInd1, temp_mesh));
            // temp_mesh.subMeshes.pop();
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_index,
                indStart,
                meshInd2 - indStart,
                indStart,
                meshInd2 - indStart,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_idx,
                meshInd2,
                indEnd - meshInd2,
                meshInd2,
                indEnd - meshInd2,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
            console.log("here7");
            break;
          }
        } else if (meshInd1 > indStart && meshInd2 < indEnd) {
          // split mesh into indStart to meshInd1 - mat_idx
          // meshInd1 to meshInd2 - mat_index
          // meshInd2 to indEnd - mat_idx
          var sMesh = jQuery.extend({}, temp_mesh.subMeshes[p + 1]);
          temp_mesh.subMeshes.splice(p, 1);
          // temp_mesh.subMeshes.splice(p+1, 1);
          // temp_mesh.subMeshes.splice(p+2, 1);
          if (meshInd2 % 2) meshInd2 += 1;
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_idx,
              0,
              verticesCount,
              indStart,
              meshInd1 - indStart,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_index,
              0,
              verticesCount,
              meshInd1,
              meshInd2 - meshInd1,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_idx,
              0,
              verticesCount,
              meshInd2,
              indEnd - meshInd2,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          // temp_mesh.subMeshes.push(new BABYLON.SubMesh(mat_idx, meshInd2, indEnd-meshInd2, meshInd2, indEnd - meshInd2, temp_mesh));
          // temp_mesh.subMeshes.pop();
          // temp_mesh.subMeshes.push(new BABYLON.SubMesh(sMesh.materialIndex, sMesh.indexStart, sMesh.indexCount, sMesh.indexStart, sMesh.indexCount, temp_mesh));
          // temp_mesh.subMeshes.pop();
          console.log("here8");
          console.log(temp_mesh.subMeshes);
          break;
        } else if (meshInd1 < indStart && meshInd2 > indEnd) {
          // split mesh into meshInd1 to indStart - mat_idx
          // indStart to indEnd - mat_index
          // indEnd to meshInd2 - mat_idx
          // temp_mesh.subMeshes.splice(p, 1);
          // temp_mesh.subMeshes.push(new BABYLON.SubMesh(mat_idx, 0, verticesCount, meshInd1, indStart - meshInd1, temp_mesh));
          // temp_mesh.subMeshes.pop();
          // temp_mesh.subMeshes.push(new BABYLON.SubMesh(mat_index, 0, verticesCount, indStart, indEnd - indStart, temp_mesh));
          // temp_mesh.subMeshes.pop();
          // temp_mesh.subMeshes.push(new BABYLON.SubMesh(mat_idx, 0, verticesCount, meshInd2, indEnd - meshInd2, temp_mesh));
          // temp_mesh.subMeshes.pop();
          console.log("here9");
        }
      }
    }
  } else {
    var indStart = temp_mesh.subMeshes[0].indexStart;
    var indEnd = indStart + temp_mesh.subMeshes[0].indexCount;
    var meshInd1 = subMeshIndices[0][0];
    var meshInd2 = subMeshIndices[0][subMeshIndices[0].length - 1] + 1;
    var mat_idx = temp_mesh.subMeshes[0].materialIndex;
    console.log(indStart, indEnd, meshInd1, meshInd2);
    temp_mesh.subMeshes = [];
    temp_mesh.subMeshes.push(
      new BABYLON.SubMesh(
        mat_idx,
        0,
        verticesCount,
        indStart,
        meshInd1 - indStart,
        temp_mesh
      )
    );
    temp_mesh.subMeshes.pop();
    temp_mesh.subMeshes.push(
      new BABYLON.SubMesh(
        mat_index,
        0,
        verticesCount,
        meshInd1,
        meshInd2 - meshInd1,
        temp_mesh
      )
    );
    temp_mesh.subMeshes.pop();
    temp_mesh.subMeshes.push(
      new BABYLON.SubMesh(
        mat_idx,
        0,
        verticesCount,
        meshInd2,
        indEnd - meshInd2,
        temp_mesh
      )
    );
    temp_mesh.subMeshes.pop();
    console.log(temp_mesh.subMeshes);
  }
  console.log(verticesCount, indices.length);
  console.log(temp_mesh.material);
  console.log(temp_mesh.subMeshes);
  // var bbinfo = temp_mesh.getBoundingInfo();
  // if (bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z)
  //     var hscale = bbinfo.boundingBox.extendSizeWorld.x;
  // else
  //     var hscale = bbinfo.boundingBox.extendSizeWorld.z;
  //
  // var vscale = bbinfo.boundingBox.extendSizeWorld.y;
  // setUVScale(temp_mesh, hscale/4, vscale/4 );
  return;
}

function applyMaterialToFace2(
  temp_mesh,
  face_coords,
  face_coords_index,
  mat_name
) {
  if (temp_mesh.material.subMaterials) {
    var multimat = new BABYLON.MultiMaterial(mat_name.name, store.scene);
    var multimatSubs = temp_mesh.material.subMaterials;
    var mat_exist_flag = false;
    var mat_exist_index = -1;
    var mat_index;
    for (var p = 0; p < temp_mesh.material.subMaterials.length; p++) {
      if (temp_mesh.material.subMaterials[p].name === mat_name.name) {
        mat_exist_flag = true;
        mat_exist_index = p;
      }
    }
    if (!mat_exist_flag) {
      temp_mesh.material.subMaterials.push(mat_name);
      mat_index = multimatSubs.length - 1;
    } else {
      mat_index = mat_exist_index;
    }
  } else {
    var multimat = new BABYLON.MultiMaterial(mat_name.name, store.scene);
    multimat.subMaterials.push(temp_mesh.material);
    multimat.subMaterials.push(mat_name);
    var mat_index = multimat.subMaterials.length - 1;
  }

  var verticesCount = temp_mesh.getTotalVertices();
  var indices = temp_mesh.getIndices();

  var meshInd1, meshInd2;
  console.log(face_coords_index);
  if (face_coords_index[3][0] < face_coords_index[0][0]) {
    var diff = Math.abs(face_coords_index[3][0] - face_coords_index[0][0]);
    console.log(diff);
    if (diff === 9) {
      meshInd1 = face_coords_index[3][0] / 3;
      meshInd2 = face_coords_index[2][0] / 3 + 1;
    } else if (diff === 6) {
      meshInd1 = face_coords_index[3][0] / 3 - 1;
      meshInd2 = face_coords_index[2][0] / 3 + 1;
    } else if (diff === 3) {
      meshInd1 = face_coords_index[3][0] / 3 - 2;
      meshInd2 = face_coords_index[2][0] / 3 + 1;
    }
  } else {
    var diff = Math.abs(face_coords_index[3][0] - face_coords_index[0][0]);
    console.log(diff);
    if (diff === 9) {
      meshInd1 = face_coords_index[0][0] / 3 - 3;
      meshInd2 = face_coords_index[3][0] / 3 + 3;
    } else if (diff === 6) {
      meshInd1 = face_coords_index[0][0] / 3 - 2;
      meshInd2 = face_coords_index[3][0] / 3 + 2;
    } else if (diff === 3) {
      meshInd1 = face_coords_index[0][0] / 3 - 1;
      meshInd2 = face_coords_index[3][0] / 3 + 1;
    }
  }
  if (temp_mesh.subMeshes.length > 1) {
    for (var p = 0; p < temp_mesh.subMeshes.length; p++) {
      var indStart = temp_mesh.subMeshes[p].indexStart;
      var indEnd = temp_mesh.subMeshes[p].indexCount + indStart;
      var mat_idx = temp_mesh.subMeshes[p].materialIndex;
      console.log(meshInd1, meshInd2);
      console.log(indStart, indEnd);
      if (meshInd1 === indStart && meshInd2 === indEnd) {
        temp_mesh.subMeshes[p].materialIndex = mat_index;
        break;
      } else if (meshInd1 === indStart && meshInd2 > indEnd) {
        temp_mesh.subMeshes[p].materialIndex = mat_index;
        console.log("here1");
        // break;
      } else if (meshInd1 < indStart && meshInd2 === indEnd) {
        temp_mesh.subMeshes[p].materialIndex = mat_index;
        console.log("here2");
        // break;
      } else if (meshInd1 >= indStart) {
        if (meshInd2 <= indEnd) {
          console.log("here3");
          temp_mesh.subMeshes.splice(p, 1);
          if (meshInd1 !== indStart) {
            // var smesh1 = new BABYLON.SubMesh(mat_idx, 0, verticesCount, indStart, meshInd1 - indStart, temp_mesh);
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_idx,
                0,
                verticesCount,
                indStart,
                meshInd1 - indStart,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
          }
          // var smesh2 = new BABYLON.SubMesh(mat_index, 0, verticesCount, meshInd1, meshInd2 - meshInd1, temp_mesh);
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_index,
              0,
              verticesCount,
              meshInd1,
              meshInd2 - meshInd1,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          if (indEnd !== meshInd2) {
            // var smesh3 = new BABYLON.SubMesh(mat_idx, 0, verticesCount, meshInd2, indEnd - meshInd2, temp_mesh);
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_idx,
                0,
                verticesCount,
                meshInd2,
                indEnd - meshInd2,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
          }
          console.log(temp_mesh);
          break;
        } else {
          console.log("here4");
          temp_mesh.subMeshes.splice(p, 1);
          if (meshInd1 !== indStart) {
            temp_mesh.subMeshes.push(
              new BABYLON.SubMesh(
                mat_idx,
                0,
                verticesCount,
                indStart,
                meshInd1 - indStart,
                temp_mesh
              )
            );
            temp_mesh.subMeshes.pop();
          }
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_index,
              0,
              verticesCount,
              meshInd1,
              indEnd - meshInd1,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          for (var q = 0; q < temp_mesh.subMeshes.length - 2; q++) {
            var indStart = temp_mesh.subMeshes[q].indexStart;
            var indEnd = temp_mesh.subMeshes[q].indexCount + indStart;
            var mat_idx = temp_mesh.subMeshes[q].materialIndex;
            console.log(indStart, indEnd, q);
            if (meshInd1 === indStart && meshInd2 === indEnd) {
              temp_mesh.subMeshes[q].materialIndex = mat_index;
              break;
            } else if (meshInd1 === indStart && meshInd2 > indEnd) {
              temp_mesh.subMeshes[q].materialIndex = mat_index;
              console.log("here1");
              // break;
            } else if (meshInd1 < indStart && meshInd2 === indEnd) {
              temp_mesh.subMeshes[q].materialIndex = mat_index;
              console.log("here2");
              // break;
            } else if (meshInd1 >= indStart) {
              if (meshInd2 <= indEnd) {
                console.log("here3");
                temp_mesh.subMeshes.splice(q, 1);
                if (meshInd1 !== indStart) {
                  temp_mesh.subMeshes.push(
                    new BABYLON.SubMesh(
                      mat_idx,
                      0,
                      verticesCount,
                      indStart,
                      meshInd1 - indStart,
                      temp_mesh
                    )
                  );
                  temp_mesh.subMeshes.pop();
                }
                temp_mesh.subMeshes.push(
                  new BABYLON.SubMesh(
                    mat_index,
                    0,
                    verticesCount,
                    meshInd1,
                    meshInd2 - meshInd1,
                    temp_mesh
                  )
                );
                temp_mesh.subMeshes.pop();
                if (indEnd !== meshInd2) {
                  temp_mesh.subMeshes.push(
                    new BABYLON.SubMesh(
                      mat_idx,
                      0,
                      verticesCount,
                      meshInd2,
                      indEnd - meshInd2,
                      temp_mesh
                    )
                  );
                  temp_mesh.subMeshes.pop();
                }
                break;
              }
            } else if (meshInd1 < indStart) {
              if (meshInd2 < indEnd) {
                console.log("here5");
                temp_mesh.subMeshes.splice(q, 1);
                temp_mesh.subMeshes.push(
                  new BABYLON.SubMesh(
                    mat_index,
                    0,
                    verticesCount,
                    indStart,
                    meshInd2 - indStart,
                    temp_mesh
                  )
                );
                temp_mesh.subMeshes.pop();
                temp_mesh.subMeshes.push(
                  new BABYLON.SubMesh(
                    mat_idx,
                    0,
                    verticesCount,
                    meshInd2,
                    indEnd - meshInd2,
                    temp_mesh
                  )
                );
                temp_mesh.subMeshes.pop();
                break;
              }
            }
          }
          break;
        }
      } else if (meshInd1 < indStart) {
        if (meshInd2 < indEnd) {
          console.log("here5");
          temp_mesh.subMeshes.splice(p, 1);
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_idx,
              0,
              verticesCount,
              indStart,
              meshInd2 - indStart,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          temp_mesh.subMeshes.push(
            new BABYLON.SubMesh(
              mat_idx,
              0,
              verticesCount,
              meshInd2,
              indEnd - meshInd2,
              temp_mesh
            )
          );
          temp_mesh.subMeshes.pop();
          break;
        }
      }
    }
  } else {
    temp_mesh.subMeshes = [];
    temp_mesh.subMeshes.push(
      new BABYLON.SubMesh(0, 0, verticesCount, 0, meshInd1, temp_mesh)
    ); // Works perfectly
    temp_mesh.subMeshes.push(
      new BABYLON.SubMesh(
        mat_index,
        0,
        verticesCount,
        meshInd1,
        meshInd2 - meshInd1,
        temp_mesh
      )
    ); // Works perfectly
    temp_mesh.subMeshes.push(
      new BABYLON.SubMesh(
        0,
        0,
        verticesCount,
        meshInd2,
        indices.length - meshInd2,
        temp_mesh
      )
    ); // Works perfectly
  }

  var temp_submeshes = [];
  for (var p = 0; p < temp_mesh.subMeshes.length; p++) {
    if (p > 0) {
      if (
        temp_mesh.subMeshes[p].indexStart ===
        temp_mesh.subMeshes[p - 1].indexStart
      ) {
        continue;
      }
    }
    if (temp_mesh.subMeshes[p].indexCount !== 0) {
      temp_submeshes.push(temp_mesh.subMeshes[p]);
    }
  }
  temp_mesh.subMeshes = temp_submeshes;

  if (!temp_mesh.material.subMaterials) {
    temp_mesh.material = multimat;
  } else {
  }
  click(temp_mesh);
  console.log(temp_mesh);
}

function checkIfVertExists(vert, face_coords) {
  for (var i = 0; i < face_coords.length; i++) {
    // console.log(vert.equals(face_coords[i]));
    // if (vert.equals(face_coords[i])){
    // 	return true;
    // }
    var face_coord = face_coords[i];
    var temp1X = Math.floor(face_coord.x);
    var temp1Y = Math.floor(face_coord.y);
    var temp1Z = Math.floor(face_coord.z);

    var temp2X = Math.floor(vert.x);
    var temp2Y = Math.floor(vert.y);
    var temp2Z = Math.floor(vert.z);
    // console.log(temp1X, temp1Y, temp1Z);
    // console.log(temp2X, temp2Y, temp2Z);
    if (temp1X == temp2X && temp1Y == temp2Y && temp1Z == temp2Z) {
      return true;
    } else if (temp1X != temp2X && temp1Y != temp2Y && temp1Z != temp2Z) {
      return true;
    }
    // else if(temp1Y!=temp2Y && temp1Z!=temp2Z){
    // 	return true;
    // }
    // else if(temp1X!=temp2X && temp1Z!=temp2Z){
    // 	return true;
    // }
  }
  // console.log(vert, face_coords);
  return false;
}

function getConstantElem(face_coords) {
  var const_elem = "n";
  if (
    face_coords[0].x === face_coords[1].x &&
    face_coords[0].x === face_coords[2].x
  ) {
    const_elem = "x";
  }
  if (
    face_coords[0].y === face_coords[1].y &&
    face_coords[0].y === face_coords[2].y
  ) {
    const_elem = "y";
  }
  if (
    face_coords[0].z === face_coords[1].z &&
    face_coords[0].z === face_coords[2].z
  ) {
    const_elem = "z";
  }
  return const_elem;
}

function getLastVertex(vert, face_coords) {
  var const_elem = "n";
  if (
    face_coords[0].x === face_coords[1].x &&
    face_coords[0].x === face_coords[2].x
  ) {
    const_elem = "x";
  }
  if (
    face_coords[0].y === face_coords[1].y &&
    face_coords[0].y === face_coords[2].y
  ) {
    const_elem = "y";
  }
  if (
    face_coords[0].z === face_coords[1].z &&
    face_coords[0].z === face_coords[2].z
  ) {
    const_elem = "z";
  }

  var temp_coor = new BABYLON.Vector3(0, 0, 0);

  if (const_elem === "x") {
    temp_coor.x = face_coords[0].x;
    if (
      face_coords[0].y !== face_coords[1].y &&
      face_coords[0].y !== face_coords[2].y
    ) {
      temp_coor.y = face_coords[0].y;
      if (face_coords[0].z === face_coords[1].z) {
        temp_coor.z = face_coords[2].z;
      } else {
        temp_coor.z = face_coords[1].z;
      }
    } else if (
      face_coords[1].y !== face_coords[0].y &&
      face_coords[1].y !== face_coords[2].y
    ) {
      temp_coor.y = face_coords[1].y;
      if (face_coords[1].z === face_coords[0].z) {
        temp_coor.z = face_coords[2].z;
      } else {
        temp_coor.z = face_coords[0].z;
      }
    } else if (
      face_coords[2].y !== face_coords[0].y &&
      face_coords[2].y !== face_coords[1].y
    ) {
      temp_coor.y = face_coords[2].y;
      if (face_coords[2].z === face_coords[0].z) {
        temp_coor.z = face_coords[1].z;
      } else {
        temp_coor.z = face_coords[0].z;
      }
    }
  } else if (const_elem === "z") {
    temp_coor.z = face_coords[0].z;
    if (
      face_coords[0].y !== face_coords[1].y &&
      face_coords[0].y !== face_coords[2].y
    ) {
      temp_coor.y = face_coords[0].y;
      if (face_coords[0].x === face_coords[1].x) {
        temp_coor.x = face_coords[2].x;
      } else {
        temp_coor.x = face_coords[1].x;
      }
    } else if (
      face_coords[1].y !== face_coords[0].y &&
      face_coords[1].y !== face_coords[2].y
    ) {
      temp_coor.y = face_coords[1].y;
      if (face_coords[1].x === face_coords[0].x) {
        temp_coor.x = face_coords[2].x;
      } else {
        temp_coor.x = face_coords[0].x;
      }
    } else if (
      face_coords[2].y !== face_coords[0].y &&
      face_coords[2].y !== face_coords[1].y
    ) {
      temp_coor.y = face_coords[2].y;
      if (face_coords[2].x === face_coords[0].x) {
        temp_coor.x = face_coords[1].x;
      } else {
        temp_coor.x = face_coords[0].x;
      }
    }
  }

  return temp_coor;
}

function computeAreaofSubMesh(mesh, idx) {
  let smesh = mesh.subMeshes[idx];
  let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let indices = mesh.getIndices();
  let indexStart = smesh.indexStart;
  let indexEnd = smesh.indexStart + smesh.indexCount;
  let area = 0;
  mesh._updateBoundingInfo();
  let m = mesh.getWorldMatrix();
  for (let i = indexStart; i < indexEnd; i = i + 3) {
    let vert1 = new BABYLON.Vector3(
      Math.round(verData[indices[i] * 3] * 1000) / 1000,
      Math.round(verData[indices[i] * 3 + 1] * 1000) / 1000,
      Math.round(verData[indices[i] * 3 + 2] * 1000) / 1000
    );
    let vert2 = new BABYLON.Vector3(
      Math.round(verData[indices[i + 1] * 3] * 1000) / 1000,
      Math.round(verData[indices[i + 1] * 3 + 1] * 1000) / 1000,
      Math.round(verData[indices[i + 1] * 3 + 2] * 1000) / 1000
    );
    let vert3 = new BABYLON.Vector3(
      Math.round(verData[indices[i + 2] * 3] * 1000) / 1000,
      Math.round(verData[indices[i + 2] * 3 + 1] * 1000) / 1000,
      Math.round(verData[indices[i + 2] * 3 + 2] * 1000) / 1000
    );
    let p1 = BABYLON.Vector3.TransformCoordinates(vert1, m);
    let p2 = BABYLON.Vector3.TransformCoordinates(vert2, m);
    let p3 = BABYLON.Vector3.TransformCoordinates(vert3, m);
    area += computeAreaTriangle3D(p1, p2, p3);
  }
  return area;
}

function computerAreaofSubMesh(mesh, idx) {
  var smesh = mesh.subMeshes[idx];
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var indices = mesh.getIndices();
  var indexStart = smesh.indexStart;
  var indexEnd = smesh.indexStart + smesh.indexCount;
  var area = 0;
  for (var i = indexStart; i < indexEnd; i = i + 3) {
    var vert1 = new BABYLON.Vector3(
      Math.round(verData[indices[i] * 3] * 1000) / 1000,
      Math.round(verData[indices[i] * 3 + 1] * 1000) / 1000,
      Math.round(verData[indices[i] * 3 + 2] * 1000) / 1000
    );
    var vert2 = new BABYLON.Vector3(
      Math.round(verData[indices[i + 1] * 3] * 1000) / 1000,
      Math.round(verData[indices[i + 1] * 3 + 1] * 1000) / 1000,
      Math.round(verData[indices[i + 1] * 3 + 2] * 1000) / 1000
    );
    var vert3 = new BABYLON.Vector3(
      Math.round(verData[indices[i + 2] * 3] * 1000) / 1000,
      Math.round(verData[indices[i + 2] * 3 + 1] * 1000) / 1000,
      Math.round(verData[indices[i + 2] * 3 + 2] * 1000) / 1000
    );

    var a = BABYLON.Vector3.Distance(vert1, vert2);
    var b = BABYLON.Vector3.Distance(vert2, vert3);
    var c = BABYLON.Vector3.Distance(vert3, vert1);
    var s = (a + b + c) / 2;
    area += Math.sqrt(s * (s - a) * (s - b) * (s - c));
  }
  return area * (store.unit_absolute_scale * 10) ** 2;
}

function loadGardenMaterial() {
  var mat = new BABYLON.StandardMaterial("garden_mat", store.scene);
  mat.diffuseColor = BABYLON.Color3.White();
  mat.diffuseTexture = new BABYLON.Texture(
    window.location.origin +
      "/media/Textures/Grass/wildtextures-deep-grass.jpg",
    store.scene,
    undefined,
    undefined,
    undefined,
    textureOnLoadCallback.bind(mat)
  );
  mat.diffuseTexture.uScale = 5.0;
  mat.diffuseTexture.vScale = 5.0;
  // mat.directIntensity = 1.5;
  // mat.environmentIntensity = 0.5;
  // mat.specularIntensity = 0.3;
  mat.cameraExposure = 0.9;
  mat.cameraContrast = 1.6;

  // var texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // texture.uScale = 5.0;
  // texture.vScale = 5.0;
  // mat.specularTexture = texture;
  // mat.useGlossinessFromSpecularMapAlpha = true;

  return mat;
}

// function loadWaterMaterial() {
//     var water = new BABYLON.WaterMaterial("water_mat", store.scene, new BABYLON.Vector2(512, 512));
//     water.backFaceCulling = true;
//     water.bumpTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/water/waterbump.png", store.scene);
//     water.windForce = -5;
//     water.waveHeight = 0.2;
//     water.bumpHeight = 0.05;
//     water.waterColor = new BABYLON.Color3(0.047, 0.23, 0.015);
//     water.colorBlendFactor = 0.5;
//     water.addToRenderList(scene.getMeshByName("ground1"));
// }

function loadMassMaterial() {
  var mat = new BABYLON.StandardMaterial("mass_mat", store.scene);
  mat.diffuseColor = BABYLON.Color3.Gray();
  // mat.directIntensity = 4.0;
  mat.backFaceCulling = true;
  // mat.environmentIntensity = 1.0;
  // mat.specularIntensity = 0.3;
  // mat.microSurface = 0.3;
  // mat.specularColor = new BABYLON.Color3(1.0, 1.0, 1.0);
  return mat;
}

function loadSolidMaterial() {
  var mat = new BABYLON.StandardMaterial("solid_mat", store.scene);
  mat.diffuseColor = new BABYLON.Color3(0.55, 0.55, 0.55);
  // mat.directIntensity = 0.5;
  mat.backFaceCulling = true;
  mat.isDefault = true;
  // mat.environmentIntensity = 1.0;
  // mat.specularIntensity = 0.3;
  // mat.microSurface = 0.3;
  // mat.specularColor = new BABYLON.Color3(0.0, 0.0, 0.0);
  return mat;
}

function loadStaircaseMaterial() {
  var mat = new BABYLON.StandardMaterial("staircase_mat", store.scene);
  mat.diffuseColor = BABYLON.Color3.White();
  // mat.directIntensity = 23.0;
  mat.backFaceCulling = true;
  mat.isDefault = true;
  // mat.environmentIntensity = 1.0;
  // mat.specularIntensity = 0.25;
  // mat.microSurface = 0.05;
  // mat.specularColor = new BABYLON.Color3(1.0, 1.0, 1.0);
  mat.materialType = "Paint";
  // let texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // texture.uScale = 5.0;
  // texture.vScale = 5.0;
  // mat.specularTexture = texture;
  return mat;
}

function loadBuildingMaterial(){
  let buildingMaterial = new BABYLON.StandardMaterial(
    "buildingMaterial",
    store.scene
  );
  buildingMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
}

function addMaterialToMesh(mesh, mat_type) {
  // console.log(mat_type, mesh.type);
  mesh.disableEdgesRendering();
  if (mat_type === "none") {
    if (store.scene.getMaterialByName("mass_mat")) {
      mesh.material = store.scene.getMaterialByName("mass_mat");
    } else {
      loadMassMaterial();
      mesh.material = store.scene.getMaterialByName("mass_mat");
    }
    mesh.showBoundingBox = true;
  } else if (mesh.type === "wall") {
    mesh.showBoundingBox = true;
  } else if (mesh.name === "wall") {
    mesh.showBoundingBox = true;
  } else if (mat_type === "garden") {
    if (store.scene.getMaterialByName("garden_mat")) {
      mesh.material = store.scene.getMaterialByName("garden_mat");
    } else {
      loadGardenMaterial();
      mesh.material = store.scene.getMaterialByName("garden_mat");
    }
    mesh.showBoundingBox = false;
  } else if (mat_type === "park") {
    if (store.scene.getMaterialByName("garden_mat")) {
      mesh.material = store.scene.getMaterialByName("garden_mat");
    } else {
      loadGardenMaterial();
      mesh.material = store.scene.getMaterialByName("garden_mat");
    }
    mesh.showBoundingBox = false;
  }
  // else if (mat_type == "water"){
  // 	loadWaterMaterial();
  // 	mesh.material = store.scene.getMaterialByName("water_mat");
  // }
}

function addMaterialToMeshSolid(mesh, mat_type, options = {}) {

  function getEdgeWidth(mesh) {
      const type = mesh.type.toLowerCase() === "mass" ?
        mesh.getSnaptrudeDS().massType.toLowerCase() : mesh.type.toLowerCase();

      let edgeWidth;

      switch (type) {
        case "room":
          edgeWidth = 20;
          break;
        case "column":
        case "wall":
        case "roof":
        case "beam":
          edgeWidth = 10;
          break;
        case "mullion":
          edgeWidth = 4;
          break;
        case "panel":
          edgeWidth = 0;
          break;
        default:
          edgeWidth = 5;
          break;
      }

      return edgeWidth;
    }

  const _enableEdgesRendering = function (
    mesh,
    epsilon,
    checkVerticesInsteadOfIndices
  ) {
    mesh.enableEdgesRendering(epsilon, checkVerticesInsteadOfIndices, {
      useAlternateEdgeFinder: false,
    });
  }
  
  if (mesh.type) {
    /*
        Not sure if this will make it less efficient or more
        if (mesh.edgesRenderer){
            mesh.edgesRenderer.dispose();
        }

        Was wondering if enableEdgesRendering has to be done even if edgesRenderer is present. But,
        mesh.edgesRenderer._rebuild(); and
        mesh.edgesRenderer.render(); did not work


        mesh.edgesShareWithInstances = true;
        also did not work. It somehow messes up GUI. Very weird.
        Maybe because of throwaway, because for regular masses, plinth's edge rendering
        is proper even though it has no edgesRenderer

        Thus, all instances will have a separate edgesRenderer that have to be updated individually

        */

    if (mesh.name == "Building") {
      let buildingMaterial = store.scene.getMaterialByName("buildingMaterial")
      if(!buildingMaterial){
        buildingMaterial = loadBuildingMaterial()
      }
      _enableEdgesRendering(mesh);
      mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
      mesh.edgesWidth = !store.$scope.isTwoDimension && !options.isOrtho ? 70 : 7;
      
      mesh.material = buildingMaterial;
      return;
    }

    if (mesh.type.toLowerCase() === "wall") {
      const forceWillHaveRedundantEdges =
        options.forceWillHaveRedundantEdges || false;

      let children = mesh.getChildren();
      let willHaveRedundantEdges = false;

      if (_.isEmpty(children)) {
      } else {
        children.some((c) => {
          if (
            ["door", "window", "void", "furniture"].includes(
              c.type.toLowerCase()
            )
          ) {
            willHaveRedundantEdges = true;
            return true;
          }
        });
      }

      if(mesh?.containsCurtainWall){
        willHaveRedundantEdges = true;
      }

      if (willHaveRedundantEdges || forceWillHaveRedundantEdges) {
        _enableEdgesRendering(mesh, 0.5, true);
      } else {
        _enableEdgesRendering(mesh);
      }
      // _enableEdgesRendering(mesh, 0.85, true);
      mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
      mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "roof") {
      _enableEdgesRendering(mesh, 0.5, true);
      // _enableEdgesRendering(mesh, 0.85, true);
      mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
      mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "mass") {
      if (mesh.material) {
        if (mesh.material.subMaterials) {
          //subMaterials will be populated if user has applied any other material to it,
          // in which case those should be maintained
          //default epsilon is 0.95; means no edge if angle between faces < 18 degrees
          _enableEdgesRendering(mesh);
          mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
          mesh.edgesColor =
            options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
          return;
        } else {
          // mesh.material = store.scene.getMaterialByName("solid_mat");
        }
      } else {
        if (store.scene.getMaterialByName("solid_mat")) {
          mesh.material = store.scene.getMaterialByName("solid_mat");
        } else {
          loadSolidMaterial();
          mesh.material = store.scene.getMaterialByName("solid_mat");
        }
      }

      mesh.showBoundingBox = false;
      _enableEdgesRendering(mesh);
      mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
      mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type === "door_lintel") {
      _enableEdgesRendering(mesh, 0.5, true);
      mesh.edgesWidth = 5.0;
      mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type === "window_lintel") {
      _enableEdgesRendering(mesh, 0.5, true);
      mesh.edgesWidth = 5.0;
      mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "door") {
      // _enableEdgesRendering(mesh, 0.85, false);
      // mesh.edgesWidth = 3.0;
      // mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "window") {
      // _enableEdgesRendering(mesh, 0.85, false);
      // mesh.edgesWidth = 3.0;
      // mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "curtainwall") {
      // mesh.showBoundingBox = true;
    } else if (mesh.type.toLowerCase() === "furniture") {
      // const furnitureDS = mesh.getSnaptrudeDS();
      // if (mesh.getSnaptrudeDS().importType && options.enableFurnitureEdgesOnce) {
      //   mesh.enableEdgesRendering(0.5);
      //   mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
      //   mesh.edgesWidth = 3.0;
      // }
      // _enableEdgesRendering(mesh, 0.85, true);
      // mesh.edgesWidth = 3.0;
      // mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "staircase") {
      _enableEdgesRendering(mesh);
      mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 2.0 : getEdgeWidth(mesh));
      mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
    } else if (mesh.type.toLowerCase() === "floor") {
    _enableEdgesRendering(mesh, 0.5, true);
    // _enableEdgesRendering(mesh, 0.85, true);
    mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
    mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
    }else if (mesh.type.toLowerCase() === "column") {
      _enableEdgesRendering(mesh, 0.5, true);
      // _enableEdgesRendering(mesh, 0.85, true);
      mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
      mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
    }
  } else if (mesh.type.toLowerCase() === "floor") {
    _enableEdgesRendering(mesh, 0.5, true);
    // _enableEdgesRendering(mesh, 0.85, true);
    mesh.edgesWidth = options.edgesWidth || (options.isOrtho ? 3.0 : getEdgeWidth(mesh));
    mesh.edgesColor = options.edgesColor || new BABYLON.Color4(0, 0, 0, 1);
  }  else if (mat_type == "garden") {
    if (store.scene.getMaterialByName("garden_mat")) {
      mesh.material = store.scene.getMaterialByName("garden_mat");
    } else {
      loadGardenMaterial();
      mesh.material = store.scene.getMaterialByName("garden_mat");
    }
    mesh.showBoundingBox = false;
  } else if (mat_type == "park") {
    if (store.scene.getMaterialByName("garden_mat")) {
      mesh.material = store.scene.getMaterialByName("garden_mat");
    } else {
      loadGardenMaterial();
      mesh.material = store.scene.getMaterialByName("garden_mat");
    }
    mesh.showBoundingBox = false;
  }
  // else if (mat_type == "water"){
  // 	loadWaterMaterial();
  // 	mesh.material = store.scene.getMaterialByName("water_mat");
  // }
}

function loadWallModeMaterial() {
  var multimat = new BABYLON.MultiMaterial("wall_mat", store.scene);
  multimat.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(store.floor_mat);
  multimat.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
  multimat.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
  multimat.subMaterials.push(store.scene.getMaterialByName("wall_mat4"));

  var multimat = new BABYLON.MultiMaterial("wall_mode_mat", store.scene);
  multimat.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
  // multimat.subMaterials.push(floor_mat);
  multimat.subMaterials.push(store.scene.getMaterialByName("wall_mat4"));
}

function setUVScale(mesh, uScale, vScale) {
  var i,
    UVs = mesh.getVerticesData(BABYLON.VertexBuffer.UVKind),
    len = UVs.length;

  if (uScale !== 1) {
    for (let i = 0; i < len; i += 2) {
      UVs[i] *= uScale;
    }
  }
  if (vScale !== 1) {
    for (let i = 1; i < len; i += 2) {
      UVs[i] *= vScale;
    }
  }

  mesh.setVerticesData(BABYLON.VertexBuffer.UVKind, UVs);
}

/**
 * Copy Material Data from one mesh to another
 * @param oldMesh
 * @param newMesh
 */
function copyMaterialData(oldMesh, newMesh) {
  oldMesh = oldMesh.sourceMesh || oldMesh;
  newMesh = newMesh.sourceMesh || newMesh;

  try {
    if (oldMesh.subMeshes.length > 1) copyMaterialDataByFace(oldMesh, newMesh);
    else copyMaterialDataByEntity(oldMesh, newMesh);
  } catch (e) {
    console.warn("Error in material copy");
    console.warn(e);
  }
}

/**
 * Copy material data from one mesh to another by entity
 * @param oldMesh
 * @param newMesh
 */
function copyMaterialDataByEntity(oldMesh, newMesh) {
  newMesh.material = oldMesh.material;
  for (let i = 0; i < newMesh.subMeshes.length; i++) {
    newMesh.subMeshes[i].materialIndex = oldMesh.subMeshes[0].materialIndex;
  }
}

/**
 * Copy material data from one mesh to another by faces
 * @param oldMesh
 * @param newMesh
 */
function copyMaterialDataByFace(oldMesh, newMesh) {
  // UTILITY FUNCTIONS
  // Function to round-off values in normals
  var roundOffVector3 = function (vec, n) {
    // let newValues = Object.values(vec).map(val => Number(Number(val).toFixed(n)));
    // return new BABYLON.Vector3(...newValues);
    let vecArr = vec.asArray();
    vecArr = vecArr.map((value) => Number(value.toFixed(n)));
    return BABYLON.Vector3.FromArray(vecArr);
  };

  // Function to compare two normals
  var compareNormal = function (normal1, normal2) {
    return (
      normal1.x === normal2.x &&
      normal1.y === normal2.y &&
      normal1.z === normal2.z
    );
  };

  // Function to check if elements are consecutive
  var areConsecutive = function (arr, n) {
    if (n < 1) return false;

    let min = Math.min(...arr);
    let max = Math.max(...arr);

    if (max - min + 1 === n) {
      for (let i = 0; i < n; i++) {
        let j = arr[i] < 0 ? -arr[i] - min : arr[i] - min;

        if (arr[j] >= 0) arr[j] = -arr[j];
        else return false;
      }
      return true;
    }

    return false;
  };

  // Function to extract split index of non-consecutive faceIdList
  var getSplitIndex = function (arr) {
    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i + 1] - arr[i] !== 1) return i + 1;
    }
  };

  // Function to get material index for sub-mesh
  var getMaterialIndex = function (id) {
    for (let i = 0; i < filteredSubMeshMetaData.length; i++) {
      if (filteredSubMeshMetaData[i].faceIdList[0] === id) {
        return filteredSubMeshMetaData[i].materialIndex;
      }
    }
  };

  // Split faceIdList if they are not consecutive and create new sub-mesh metadata to maintain consistency
  var createConsistentSubMeshMetaData = function (filteredSubMeshMetaData) {
    let tempData = [];
    filteredSubMeshMetaData.forEach(function (data) {
      data.faceIdList.sort(function (a, b) {
        return a - b;
      });
      if (
        !areConsecutive(
          Object.assign([], data.faceIdList),
          data.faceIdList.length
        )
      ) {
        let splitIndex = getSplitIndex(data.faceIdList);
        let cutArray = data.faceIdList.splice(
          splitIndex,
          data.faceIdList.length - splitIndex
        );
        data.faceIdList.length = splitIndex;

        let subMeshData = {};
        subMeshData.faceIdList = cutArray;
        subMeshData.materialIndex = data.materialIndex;

        tempData.push(subMeshData);
      }
    });
    if (tempData.length === 0) return;
    else {
      filteredSubMeshMetaData.push(...tempData);
      createConsistentSubMeshMetaData(filteredSubMeshMetaData);
    }
  };

  // Copy material to new mesh
  newMesh.material = oldMesh.material;

  // Variable to store old mesh material data
  let oldMeshMaterialData = [];

  // Update facet data and get local normals of each face of old mesh
  oldMesh.updateFacetData();
  let facetLocalNormalsOldMesh = oldMesh.getFacetLocalNormals();

  // Get old mesh's subMeshes material data
  oldMesh.subMeshes.forEach(function (subMesh) {
    if (subMesh.indexCount >= 3) {
      let materialData = {};
      materialData.faceId = subMesh.indexStart / 3;
      materialData.normal = roundOffVector3(
        facetLocalNormalsOldMesh[materialData.faceId],
        3
      );
      materialData.materialIndex = subMesh.materialIndex;
      oldMeshMaterialData.push(materialData);
    }
  });

  // Filter OldMeshMaterialData to get rid of of duplicates(same normal data)
  let tempOldMeshMaterialData = [];
  tempOldMeshMaterialData.push(oldMeshMaterialData[0]);
  oldMeshMaterialData.forEach(function (data) {
    if (tempOldMeshMaterialData.length > 0) {
      let compareFlag;
      for (let i = 0; i < tempOldMeshMaterialData.length; i++) {
        compareFlag = compareNormal(
          data.normal,
          tempOldMeshMaterialData[i].normal
        );
        if (compareFlag) break;
      }
      if (!compareFlag) {
        tempOldMeshMaterialData.push(data);
      }
    }
  });

  // Update facet data of new mesh and get local normals
  newMesh.updateFacetData();
  let facetLocalNormalsNewMesh = newMesh
    .getFacetLocalNormals()
    .map((vec) => roundOffVector3(vec, 3));

  let subMeshesMetaData = [];

  // Populate sub-mesh metadata for sub-mesh creation based on normals collected from tempOldMeshMaterialData
  for (let i = 0; i < tempOldMeshMaterialData.length; i++) {
    let subMeshData = {};
    let faceIds = [];
    for (let j = 0; j < facetLocalNormalsNewMesh.length; j++) {
      if (
        compareNormal(
          facetLocalNormalsNewMesh[j],
          tempOldMeshMaterialData[i].normal
        )
      )
        faceIds.push(j);
    }
    subMeshData.faceIdList = faceIds;
    subMeshData.materialIndex = tempOldMeshMaterialData[i].materialIndex;

    subMeshesMetaData.push(subMeshData);
  }

  // Filter sub-mesh meta data to eliminate default material data
  let filteredSubMeshMetaData = subMeshesMetaData.filter(
    (subMeshData) => subMeshData.materialIndex > 0
  );

  if (filteredSubMeshMetaData.length > 0) {
    // Create consistent sub-mesh metadata
    createConsistentSubMeshMetaData(filteredSubMeshMetaData);

    // Get extrema of each faceIdList
    let extrema = [];
    filteredSubMeshMetaData.forEach(function (data) {
      extrema.push(
        data.faceIdList[0],
        data.faceIdList[data.faceIdList.length - 1]
      );
    });
    extrema.sort(function (a, b) {
      return a - b;
    });

    // Get indexCount and verticesCount
    let iEnd = newMesh.facetNb * 3;
    let verticesCount = newMesh.getTotalVertices();

    // Initialize sub-meshes
    newMesh.subMeshes = [];

    // Create sub-meshes using extrema ids
    for (let i = 0; i < extrema.length; i += 2) {
      if (extrema[i] === 0) {
        let materialIndex = getMaterialIndex(extrema[i]);
        if (materialIndex) {
          BABYLON.SubMesh.AddToMesh(
            materialIndex,
            0,
            verticesCount,
            extrema[i] * 3,
            (extrema[i + 1] + 1) * 3,
            newMesh
          );
        }
        if (i + 2 < extrema.length) {
          if ((extrema[i + 2] - extrema[i + 1] - 1) * 3 >= 3) {
            BABYLON.SubMesh.AddToMesh(
              0,
              0,
              verticesCount,
              (extrema[i + 1] + 1) * 3,
              (extrema[i + 2] - extrema[i + 1] - 1) * 3,
              newMesh
            );
          }
        } else {
          if (extrema[extrema.length - 1] * 3 !== iEnd) {
            let endIndex = extrema[extrema.length - 1];
            if (iEnd - (endIndex + 1) * 3 !== 0)
              BABYLON.SubMesh.AddToMesh(
                0,
                0,
                verticesCount,
                (endIndex + 1) * 3,
                iEnd - (endIndex + 1) * 3,
                newMesh
              );
          }
        }
      } else {
        if (i === 0) {
          BABYLON.SubMesh.AddToMesh(
            0,
            0,
            verticesCount,
            0,
            extrema[i] * 3,
            newMesh
          );
          let materialIndex = getMaterialIndex(extrema[i]);
          if (materialIndex)
            BABYLON.SubMesh.AddToMesh(
              materialIndex,
              0,
              verticesCount,
              extrema[i] * 3,
              (extrema[i + 1] - extrema[i] + 1) * 3,
              newMesh
            );
        } else {
          let materialIndex = getMaterialIndex(extrema[i]);
          if (materialIndex)
            BABYLON.SubMesh.AddToMesh(
              materialIndex,
              0,
              verticesCount,
              extrema[i] * 3,
              (extrema[i + 1] - extrema[i] + 1) * 3,
              newMesh
            );
        }
        if (i + 2 < extrema.length) {
          if ((extrema[i + 2] - extrema[i + 1] - 1) * 3 >= 3) {
            BABYLON.SubMesh.AddToMesh(
              0,
              0,
              verticesCount,
              (extrema[i + 1] + 1) * 3,
              (extrema[i + 2] - extrema[i + 1] - 1) * 3,
              newMesh
            );
          }
        } else {
          if (extrema[extrema.length - 1] * 3 !== iEnd) {
            let endIndex = extrema[extrema.length - 1];
            if (iEnd - (endIndex + 1) * 3 !== 0)
              BABYLON.SubMesh.AddToMesh(
                0,
                0,
                verticesCount,
                (endIndex + 1) * 3,
                iEnd - (endIndex + 1) * 3,
                newMesh
              );
          }
        }
      }
    }
  }
}

function applyMaterialByFace(pickInfo, materialURL, materialType) {
  // UTILITY FUNCTIONS
  // Function to round-off values in normals
  var roundOffVector3 = function (vec, n) {
    // let newValues = Object.values(vec).map(val => Number(Number(val).toFixed(n)));
    // return new BABYLON.Vector3(...newValues);
    let vecArr = vec.asArray();
    vecArr = vecArr.map((value) => Number(value.toFixed(n)));
    return BABYLON.Vector3.FromArray(vecArr);
  };

  // Function to compare two normals
  var compareNormal = function (normal1, normal2) {
    return (
      normal1.x === normal2.x &&
      normal1.y === normal2.y &&
      normal1.z === normal2.z
    );
  };

  // Function to check if elements are consecutive
  var areConsecutive = function (arr, n) {
    if (n < 1) return false;

    let min = Math.min(...arr);
    let max = Math.max(...arr);

    if (max - min + 1 === n) {
      for (let i = 0; i < n; i++) {
        let j = arr[i] < 0 ? -arr[i] - min : arr[i] - min;

        if (arr[j] >= 0) arr[j] = -arr[j];
        else return false;
      }
      return true;
    }

    return false;
  };

  // Function to extract split index of non-consecutive faceIdList
  var getSplitIndex = function (arr) {
    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i + 1] - arr[i] !== 1) return i + 1;
    }
  };

  // Create material and get its index value from sub-materials
  var getMaterialIndex = function () {
    if (materialURL) {
      let materialIndex;
      const applyMaterialProperties = store.$scope.applyMaterialProperties;
      const materialName = applyMaterialProperties.name;
      let matName = materialName || materialURL.replace(/^.*[\\\/]/, "").split(".")[0];
      let tempMat = store.scene.getMaterialByName(matName);
      if (!tempMat) {
        tempMat = createMaterialFromImage(materialURL, materialType, matName);
        saveMaterialInBackend(tempMat);
      }
      if (mesh.material.subMaterials) {
        let multiMatSubMaterials = pickInfo.pickedMesh.material.subMaterials;
        let materialExistFlag = false;
        let materialExistIndex = -1;
        for (let p = 0; p < multiMatSubMaterials.length; p++) {
          if (multiMatSubMaterials[p].id === tempMat.id) {
            materialExistFlag = true;
            materialExistIndex = p;
          }
        }
        if (!materialExistFlag) {
          multiMatSubMaterials.push(tempMat);
          materialIndex = multiMatSubMaterials.length - 1;
        } else {
          materialIndex = materialExistIndex;
        }
      } else {
        let id = Math.random().toString(36).substr(2, 6) + "_";
        let multiMat = new BABYLON.MultiMaterial(
          tempMat.name + id,
          store.scene
        );
        multiMat.subMaterials.push(mesh.material);
        multiMat.subMaterials.push(tempMat);
        mesh.material = multiMat;
        materialIndex = multiMat.subMaterials.length - 1;
      }

      return materialIndex;
    } else return null;
  };

  // Function to get material index for sub-mesh
  var getMaterialIndexOfSubMesh = function (id) {
    for (let i = 0; i < filteredSubMeshesMetaData.length; i++) {
      if (filteredSubMeshesMetaData[i].faceIdList[0] === id) {
        return filteredSubMeshesMetaData[i].materialIndex;
      }
    }
  };

  var getMaterialIndexCurrent = function (mesh) {
    for (let i = 0; i < mesh.subMeshes.length; i++) {
      let matIndex = mesh.subMeshes[i].materialIndex;
      if (mesh.material.subMaterials[matIndex].diffuseTexture) {
        if (
          mesh.material.subMaterials[matIndex].diffuseTexture.url ===
          materialURL
        ) {
          return matIndex;
        }
      }
    }
  };

  // Split faceIdList if they are not consecutive and create new sub-mesh metadata to maintain consistency
  var createConsistentSubMeshMetaData = function (subMeshesMetaData) {
    let tempData = [];
    subMeshesMetaData.forEach(function (data) {
      data.faceIdList.sort(function (a, b) {
        return a - b;
      });
      if (
        !areConsecutive(
          Object.assign([], data.faceIdList),
          data.faceIdList.length
        )
      ) {
        let splitIndex = getSplitIndex(data.faceIdList);
        let cutArray = data.faceIdList.splice(
          splitIndex,
          data.faceIdList.length - splitIndex
        );
        data.faceIdList.length = splitIndex;

        let subMeshData = {};
        subMeshData.faceIdList = cutArray;
        subMeshData.materialIndex = data.materialIndex;

        tempData.push(subMeshData);
      }
    });
    if (tempData.length === 0) return;
    else {
      subMeshesMetaData.push(...tempData);
      createConsistentSubMeshMetaData(subMeshesMetaData);
    }
  };

  let mesh = pickInfo.pickedMesh;
  if (mesh.isAnInstance) mesh = mesh.sourceMesh;
  let faceId = pickInfo.faceId;
  let prevSubMeshes = jQuery.extend({}, mesh.subMeshes);

  if (Furniture.isMeshFurniture(mesh) || Door.isMeshDoorOrWindow(mesh)) {
    const subMeshId = pickInfo.subMeshId;
    const materialIndex = getMaterialIndex();
    const previousMaterialIndex = mesh.subMeshes[subMeshId].materialIndex;
    if (materialIndex) {
      mesh.subMeshes[subMeshId].materialIndex = materialIndex;
    }

    if(mesh.getSnaptrudeDS()?.revitMetaData?.type) {
      mesh.subMeshes.map((submesh, index) => {
        if(mesh.subMeshes[index].materialIndex === previousMaterialIndex) {
          mesh.subMeshes[index].materialIndex = materialIndex
        }
      });
    }
    return;
  }

  mesh.updateFacetData();
  let facetLocalNormals = mesh
    .getFacetLocalNormals()
    .map((vec) => roundOffVector3(vec, 3));

  let normal = facetLocalNormals[faceId];

  let meshMaterialData = [];
  mesh.subMeshes.forEach(function (subMesh) {
    if (subMesh.indexCount >= 3) {
      let materialData = {};
      materialData.faceId = subMesh.indexStart / 3;
      materialData.normal = roundOffVector3(
        facetLocalNormals[materialData.faceId],
        3
      );
      materialData.materialIndex = subMesh.materialIndex;
      meshMaterialData.push(materialData);
    }
  });

  let tempMeshMaterialData = [];
  tempMeshMaterialData.push(meshMaterialData[0]);
  meshMaterialData.forEach(function (data) {
    if (tempMeshMaterialData.length > 0) {
      let compareFlag;
      for (let i = 0; i < tempMeshMaterialData.length; i++) {
        compareFlag = compareNormal(
          data.normal,
          tempMeshMaterialData[i].normal
        );
        if (compareFlag) break;
      }
      if (!compareFlag) {
        tempMeshMaterialData.push(data);
      }
    }
  });

  let subMeshesMetaData = [];

  for (let i = 0; i < tempMeshMaterialData.length; i++) {
    let subMeshData = {};
    let faceIds = [];
    for (let j = 0; j < facetLocalNormals.length; j++) {
      if (compareNormal(facetLocalNormals[j], tempMeshMaterialData[i].normal))
        faceIds.push(j);
    }
    subMeshData.faceIdList = faceIds;
    subMeshData.materialIndex = tempMeshMaterialData[i].materialIndex;

    subMeshesMetaData.push(subMeshData);
  }

  let filteredSubMeshesMetaData = subMeshesMetaData.filter(
    (subMeshData) => subMeshData.materialIndex > 0
  );

  let subMeshExistFlag = false;

  for (let i = 0; i < filteredSubMeshesMetaData.length; i++) {
    if (filteredSubMeshesMetaData[i].faceIdList.includes(faceId)) {
      filteredSubMeshesMetaData[i].materialIndex = getMaterialIndex();
      subMeshExistFlag = true;
      break;
    }
  }

  if (!subMeshExistFlag) {
    let subMeshData = {};
    let faceIds = [];
    for (let i = 0; i < facetLocalNormals.length; i++) {
      if (compareNormal(facetLocalNormals[i], normal)) faceIds.push(i);
    }
    subMeshData.faceIdList = faceIds;
    subMeshData.materialIndex = getMaterialIndex();
    filteredSubMeshesMetaData.push(subMeshData);
  }

  if (filteredSubMeshesMetaData.length > 0) {
    createConsistentSubMeshMetaData(filteredSubMeshesMetaData);

    let extrema = [];
    filteredSubMeshesMetaData.forEach(function (data) {
      extrema.push(
        data.faceIdList[0],
        data.faceIdList[data.faceIdList.length - 1]
      );
    });
    extrema.sort(function (a, b) {
      return a - b;
    });

    let iEnd = mesh.facetNb * 3;
    let verticesCount = mesh.getTotalVertices();

    const defaultMaterialIndex = mesh.subMeshes.length === 1 ?
      mesh.subMeshes[0].materialIndex : 0
    mesh.subMeshes = [];

    for (let i = 0; i < extrema.length; i += 2) {
      if (extrema[i] === 0) {
        let materialIndex = getMaterialIndexOfSubMesh(extrema[i]);
        if (materialIndex) {
          BABYLON.SubMesh.AddToMesh(
            materialIndex,
            0,
            verticesCount,
            extrema[i] * 3,
            (extrema[i + 1] + 1) * 3,
            mesh
          );
        }
        if (i + 2 < extrema.length) {
          if ((extrema[i + 2] - extrema[i + 1] - 1) * 3 >= 3) {
            BABYLON.SubMesh.AddToMesh(
              defaultMaterialIndex,
              0,
              verticesCount,
              (extrema[i + 1] + 1) * 3,
              (extrema[i + 2] - extrema[i + 1] - 1) * 3,
              mesh
            );
          }
        } else {
          if (extrema[extrema.length - 1] * 3 !== iEnd) {
            let endIndex = extrema[extrema.length - 1];
            if (iEnd - (endIndex + 1) * 3 !== 0)
              BABYLON.SubMesh.AddToMesh(
                defaultMaterialIndex,
                0,
                verticesCount,
                (endIndex + 1) * 3,
                iEnd - (endIndex + 1) * 3,
                mesh
              );
          }
        }
      } else {
        if (i === 0) {
          BABYLON.SubMesh.AddToMesh(
            defaultMaterialIndex,
            0,
            verticesCount,
            0,
            extrema[i] * 3,
            mesh
          );
          let materialIndex = getMaterialIndexOfSubMesh(extrema[i]);
          if (materialIndex)
            BABYLON.SubMesh.AddToMesh(
              materialIndex,
              0,
              verticesCount,
              extrema[i] * 3,
              (extrema[i + 1] - extrema[i] + 1) * 3,
              mesh
            );
        } else {
          let materialIndex = getMaterialIndexOfSubMesh(extrema[i]);
          if (materialIndex)
            BABYLON.SubMesh.AddToMesh(
              materialIndex,
              0,
              verticesCount,
              extrema[i] * 3,
              (extrema[i + 1] - extrema[i] + 1) * 3,
              mesh
            );
        }
        if (i + 2 < extrema.length) {
          if ((extrema[i + 2] - extrema[i + 1] - 1) * 3 >= 3) {
            BABYLON.SubMesh.AddToMesh(
              defaultMaterialIndex,
              0,
              verticesCount,
              (extrema[i + 1] + 1) * 3,
              (extrema[i + 2] - extrema[i + 1] - 1) * 3,
              mesh
            );
          }
        } else {
          if (extrema[extrema.length - 1] * 3 !== iEnd) {
            let endIndex = extrema[extrema.length - 1];
            if (iEnd - (endIndex + 1) * 3 !== 0)
              BABYLON.SubMesh.AddToMesh(
                defaultMaterialIndex,
                0,
                verticesCount,
                (endIndex + 1) * 3,
                iEnd - (endIndex + 1) * 3,
                mesh
              );
          }
        }
      }
    }

    let currentMaterialIndex = getMaterialIndexCurrent(mesh);

    addMaterialToLayersFace(
      mesh,
      prevSubMeshes,
      materialType,
      currentMaterialIndex,
      normal
    );
    if (mesh.instances.length > 0) {
      mesh.synchronizeInstances();
    }
    updateModifications();
  }
}

// function applyMaterialByBREP(pickInfo, materialURL, materialType) {
//     // UTILITY FUNCTIONS
//     // Function to round-off values in normals
//     var roundOffVector3 = function (vec, n) {
//         let newValues = Object.values(vec).map(val => Number(Number(val).toFixed(n)));
//         return new BABYLON.Vector3(...newValues);
//     };
//
//     // Function to compare two normals
//     var compareNormal = function (normal1, normal2) {
//         return normal1.x === normal2.x && normal1.y === normal2.y && normal1.z === normal2.z;
//     };
//
//     // Function to check if elements are consecutive
//     var areConsecutive = function (arr, n) {
//         if (n < 1) return false;
//
//         let min = Math.min(...arr);
//         let max = Math.max(...arr);
//
//         if (max - min + 1 === n) {
//             for (let i = 0; i < n; i++) {
//                 let j = (arr[i] < 0) ? -arr[i] - min : arr[i] - min;
//
//                 if (arr[j] >= 0) arr[j] = -arr[j];
//                 else return false;
//             }
//             return true;
//         }
//
//         return false;
//     };
//
//     // Function to extract split index of non-consecutive faceIdList
//     var getSplitIndex = function (arr) {
//         for (let i = 0; i < arr.length - 1; i++) {
//             if (arr[i + 1] - arr[i] !== 1) return i + 1;
//         }
//     };
//
//     // Create material and get its index value from sub-materials
//     var getMaterialIndex = function () {
//         if (materialURL) {
//             let materialIndex;
//             let matName = materialURL.replace(/^.*[\\\/]/, '').split('.')[0];
//             let tempMat = store.scene.getMaterialByName(matName);
//             if (!tempMat) {
//                 tempMat = createMaterialFromImage(materialURL, materialType);
//             }
//             if (mesh.material.subMaterials) {
//                 let multiMatSubMaterials = pickInfo.pickedMesh.material.subMaterials;
//                 let materialExistFlag = false;
//                 let materialExistIndex = -1;
//                 for (let p = 0; p < multiMatSubMaterials.length; p++) {
//                     if (multiMatSubMaterials[p].id === tempMat.id) {
//                         materialExistFlag = true;
//                         materialExistIndex = p;
//                     }
//                 }
//                 if (!materialExistFlag) {
//                     multiMatSubMaterials.push(tempMat);
//                     materialIndex = multiMatSubMaterials.length - 1;
//                 } else {
//                     materialIndex = materialExistIndex;
//                 }
//             } else {
//                 let id = Math.random().toString(36).substr(2, 6) + '_';
//                 let multiMat = new BABYLON.MultiMaterial(tempMat.name + id, store.scene);
//                 multiMat.subMaterials.push(mesh.material);
//                 multiMat.subMaterials.push(tempMat);
//                 mesh.material = multiMat;
//                 materialIndex = multiMat.subMaterials.length - 1;
//             }
//
//             return materialIndex;
//         } else return null;
//     };
//
//     // Function to get material index for sub-mesh
//     var getMaterialIndexOfSubMesh = function (id) {
//         for (let i = 0; i < filteredSubMeshesMetaData.length; i++) {
//             if (filteredSubMeshesMetaData[i].faceIdList[0] === id) {
//                 return filteredSubMeshesMetaData[i].materialIndex;
//             }
//         }
//     };
//
//     var getMaterialIndexCurrent = function (mesh) {
//         for (let i = 0; i < mesh.subMeshes.length; i++) {
//             let matIndex = mesh.subMeshes[i].materialIndex;
//             if (mesh.material.subMaterials[matIndex].diffuseTexture) {
//                 if (mesh.material.subMaterials[matIndex].diffuseTexture.url === materialURL) {
//                     return matIndex;
//                 }
//             }
//         }
//     };
//
//     // Split faceIdList if they are not consecutive and create new sub-mesh metadata to maintain consistency
//     var createConsistentSubMeshMetaData = function (subMeshesMetaData) {
//         let tempData = [];
//         subMeshesMetaData.forEach(function (data) {
//             data.faceIdList.sort(function (a, b) {
//                 return a - b
//             });
//             if (!areConsecutive(Object.assign([], data.faceIdList), data.faceIdList.length)) {
//                 let splitIndex = getSplitIndex(data.faceIdList);
//                 let cutArray = data.faceIdList.splice(splitIndex, data.faceIdList.length - splitIndex);
//                 data.faceIdList.length = splitIndex;
//
//                 let subMeshData = {};
//                 subMeshData.faceIdList = cutArray;
//                 subMeshData.materialIndex = data.materialIndex;
//
//                 tempData.push(subMeshData);
//             }
//         });
//         if (tempData.length === 0) return;
//         else {
//             subMeshesMetaData.push(...tempData);
//             createConsistentSubMeshMetaData(subMeshesMetaData);
//         }
//     };
//
//     let mesh = pickInfo.pickedMesh;
//     if (mesh.isAnInstance) mesh = mesh.sourceMesh;
//     let object = mesh.getSnaptrudeDS();
//     let facetId = pickInfo.faceId;
//     // let brep = object.brep;
//
//     let meshMaterialData = [];
//     mesh.subMeshes.forEach(function (subMesh) {
//         if (subMesh.indexCount >= 3) {
//             let materialData = {};
//             materialData.facetId = subMesh.indexStart / 3;
//             materialData.materialIndex = subMesh.materialIndex;
//             meshMaterialData.push(materialData);
//         }
//     });
//
//     let subMeshesMetaData = [];
//
//     for (let i = 0; i < meshMaterialData.length; i++) {
//         let subMeshData = {};
//         let faceID = getFaceIdFromFacet(meshMaterialData[i].facetId, mesh);
//         subMeshData.faceIdList = object.faceFacetMapping[faceID];
//         subMeshData.materialIndex = meshMaterialData[i].materialIndex;
//
//         subMeshesMetaData.push(subMeshData);
//     }
//
//     let filteredSubMeshesMetaData = subMeshesMetaData.filter(subMeshData => subMeshData.materialIndex > 0);
//
//     let subMeshExistFlag = false;
//
//     for (let i = 0; i < filteredSubMeshesMetaData.length; i++) {
//         if (filteredSubMeshesMetaData[i].faceIdList.includes(facetId)) {
//             filteredSubMeshesMetaData[i].materialIndex = getMaterialIndex();
//             subMeshExistFlag = true;
//             break;
//         }
//     }
//
//     if (!subMeshExistFlag) {
//         let subMeshData = {};
//         let faceID = getFaceIdFromFacet(facetId, mesh);
//         subMeshData.faceIdList = object.faceFacetMapping[faceID];
//         subMeshData.materialIndex = getMaterialIndex();
//         filteredSubMeshesMetaData.push(subMeshData);
//     }
//
//     if (filteredSubMeshesMetaData) {
//         createConsistentSubMeshMetaData(filteredSubMeshesMetaData);
//
//         let extrema = [];
//         filteredSubMeshesMetaData.forEach(function (data) {
//             extrema.push(data.faceIdList[0], data.faceIdList[data.faceIdList.length - 1])
//         });
//         extrema.sort(function (a, b) {
//             return a - b
//         });
//
//         let iEnd = mesh.facetNb * 3;
//         let verticesCount = mesh.getTotalVertices();
//
//         mesh.subMeshes = [];
//
//         for (let i = 0; i < extrema.length; i += 2) {
//             if (extrema[i] === 0) {
//                 let materialIndex = getMaterialIndexOfSubMesh(extrema[i]);
//                 if (materialIndex) {
//                     BABYLON.SubMesh.AddToMesh(materialIndex, 0, verticesCount, extrema[i] * 3, (extrema[i + 1] + 1) * 3, mesh);
//                 }
//                 if ((i + 2) < extrema.length) {
//                     if ((extrema[i + 2] - extrema[i + 1] - 1) * 3 >= 3) {
//                         BABYLON.SubMesh.AddToMesh(0, 0, verticesCount, (extrema[i + 1] + 1) * 3, (extrema[i + 2] - extrema[i + 1] - 1) * 3, mesh);
//                     }
//                 } else {
//                     if (extrema[extrema.length - 1] * 3 !== iEnd) {
//                         let endIndex = extrema[extrema.length - 1];
//                         if ((iEnd - ((endIndex + 1) * 3)) !== 0)
//                             BABYLON.SubMesh.AddToMesh(0, 0, verticesCount, (endIndex + 1) * 3, iEnd - ((endIndex + 1) * 3), mesh);
//                     }
//                 }
//             } else {
//                 if (i === 0) {
//                     BABYLON.SubMesh.AddToMesh(0, 0, verticesCount, 0, extrema[i] * 3, mesh);
//                     let materialIndex = getMaterialIndexOfSubMesh(extrema[i]);
//                     if (materialIndex)
//                         BABYLON.SubMesh.AddToMesh(materialIndex, 0, verticesCount, extrema[i] * 3, (extrema[i + 1] - extrema[i] + 1) * 3, mesh)
//                 } else {
//                     let materialIndex = getMaterialIndexOfSubMesh(extrema[i]);
//                     if (materialIndex)
//                         BABYLON.SubMesh.AddToMesh(materialIndex, 0, verticesCount, extrema[i] * 3, (extrema[i + 1] - extrema[i] + 1) * 3, mesh);
//                 }
//                 if ((i + 2) < extrema.length) {
//                     if ((extrema[i + 2] - extrema[i + 1] - 1) * 3 >= 3) {
//                         BABYLON.SubMesh.AddToMesh(0, 0, verticesCount, (extrema[i + 1] + 1) * 3, (extrema[i + 2] - extrema[i + 1] - 1) * 3, mesh);
//                     }
//                 } else {
//                     if (extrema[extrema.length - 1] * 3 !== iEnd) {
//                         let endIndex = extrema[extrema.length - 1];
//                         if ((iEnd - ((endIndex + 1) * 3)) !== 0)
//                             BABYLON.SubMesh.AddToMesh(0, 0, verticesCount, (endIndex + 1) * 3, iEnd - ((endIndex + 1) * 3), mesh);
//                     }
//                 }
//             }
//         }
//         let currentMaterialIndex = getMaterialIndexCurrent(mesh);
//         let faceID = getFaceIdFromFacet(facetId, mesh);
//         let faces = mesh.getSnaptrudeDS().brep.getFaces();
//
//         for (let i = 0; i < faces.length; i++) {
//             if (faces[i].index === faceID) {
//                 faces[i].materialIndex = currentMaterialIndex;
//                 break;
//             }
//         }
//
//         // addMaterialToLayersFace(mesh, prevSubMeshes, materialType, currentMaterialIndex, normal);
//         if (mesh.instances.length > 0) {
//             mesh.synchronizeInstances();
//         }
//         updateModifications();
//     }
//
// }

function applyMaterialByBREP(pickedMesh, faceID, materialURL, materialType,  options) {
  // Create material and get its index value from sub-materials
  function _getMaterialIndex() {
    if (materialURL) {
      let materialIndex;
      const applyMaterialProperties = store.$scope.applyMaterialProperties;
      const materialName = applyMaterialProperties.name;
      let matName = materialName || materialURL.replace(/^.*[\\\/]/, "").split(".")[0];
      let tempMat = store.scene.getMaterialByName(matName);
      if (!tempMat) {
        tempMat = createMaterialFromImage(materialURL, materialType, matName);
        saveMaterialInBackend(tempMat);
      }
      if (mesh.material.subMaterials) {
        let multiMatSubMaterials = pickedMesh.material.subMaterials;
        let materialExistFlag = false;
        let materialExistIndex = -1;
        for (let p = 0; p < multiMatSubMaterials.length; p++) {
          if (multiMatSubMaterials[p].id === tempMat.id) {
            materialExistFlag = true;
            materialExistIndex = p;
          }
        }
        if (!materialExistFlag) {
          multiMatSubMaterials.push(tempMat);
          materialIndex = multiMatSubMaterials.length - 1;
        } else {
          materialIndex = materialExistIndex;
        }
      } else {
        let id = Math.random().toString(36).substr(2, 6) + "_";
        let multiMat = new BABYLON.MultiMaterial(
          tempMat.name + id,
          store.scene
        );
        multiMat.subMaterials.push(mesh.material);
        multiMat.subMaterials.push(tempMat);
        mesh.material = multiMat;
        materialIndex = multiMat.subMaterials.length - 1;
      }

      return materialIndex;
    } else return null;
  }
  // Function to round-off values in normals
  var roundOffVector3 = function (vec, n) {
    let newValues = Object.values(vec).map((val) =>
      Number(Number(val).toFixed(n))
    );
    return new BABYLON.Vector3(...newValues);
  };
  let mesh = pickedMesh;
  if (mesh.isAnInstance) mesh = mesh.sourceMesh;
  let prevSubMeshes = Object.assign({}, mesh.subMeshes);
  //To get the normal of the face on which the material is applied
  mesh.updateFacetData();
  let facetLocalNormals = mesh
    .getFacetLocalNormals()
    .map((vec) =>
      BABYLON.Vector3.FromArray(
        vec.asArray().map((value) => Number(value.toFixed(3)))
      )
    );

  let object = mesh.getSnaptrudeDS();

  if (object.brep && object.faceFacetMapping) {
    let faces = object.brep.getFaces();

    let materialIndex = options?.mat_index ? options.mat_index : _getMaterialIndex();
    // let faceID = getFaceIdFromFacet(facetID, mesh);

    // Set materialIndex of b-rep face
    faces[faceID].materialIndex = materialIndex;
    let normalVec = facetLocalNormals[object.faceFacetMapping[faceID][0]];

    //update subMeshes
    if (mesh.subMeshes.length === 1) {
      updateSubMeshes(mesh, faces, object.faceFacetMapping);
    }

    // Apply material to subMesh
    mesh.subMeshes[faceID].materialIndex = materialIndex;
    if (!options?.revitImport)
    addMaterialToLayersFace(
      mesh,
      prevSubMeshes,
      materialType,
      materialIndex,
      normalVec
    );
  }
}

const materialLoader = (function () {
  function createStandardMaterial(url, name, options = {}) {
    let existingMaterial = store.scene.getMaterialByName(name);
    if (existingMaterial) {
      return existingMaterial;
    }
    let mat = new BABYLON.StandardMaterial(name, store.scene);
    mat.id = name;
    // mat.materialType = "RoomType";
    mat.materialType = options.materialType || "RoomType";
    mat.inStatic = true;
    mat.diffuseColor = BABYLON.Color3.White();
    mat.diffuseTexture = new BABYLON.Texture(
      url,
      store.scene, undefined, undefined, undefined,
      textureOnLoadCallback.bind(mat)
    )
    mat.cameraExposure = 0.9;
    mat.cameraContrast = 1.6;
    mat.backFaceCulling = true;
    // mat.zOffset = 2;

    switch (name) {
      case "poolMat":
        mat.alpha = 0.6;
        mat.diffuseTexture.uScale = 1 / (store.unit_absolute_scale * 10 *
          DisplayOperation.getOriginalDimension("3000", "millimeter"));
        mat.diffuseTexture.vScale = 1 / (store.unit_absolute_scale * 10 *
          DisplayOperation.getOriginalDimension("3000", "millimeter"));
        break;
      case "waterbodyMat":
        mat.alpha = 0.6;
        break;
      case "footpathMat":
      case "deckMat":
        mat.diffuseTexture.uScale = 1 / (store.unit_absolute_scale * 10 *
          DisplayOperation.getOriginalDimension("2000", "millimeter"));
        mat.diffuseTexture.vScale = 1 / (store.unit_absolute_scale * 10 *
          DisplayOperation.getOriginalDimension("2000", "millimeter"));
        break;
      case "GLASS":
        mat.alpha = MATERIAL_STRUCTURE["Wall"]["GlassWall"]._layers[0].transparency;
        break;
    }
    saveMaterialInBackend(mat);
    return mat;
  }

  function loadSiteMaterial() {
    let url = djangoUrl + "/media/materials/grass.jpg";
    return createStandardMaterial(url, "siteMat");
  }

  function loadPoolMaterial() {
    let url = djangoUrl + "/media/materials/pool.jpg";
    return createStandardMaterial(url, "poolMat");
  }

  function loadGroundMaterial() {
    let url = djangoUrl + "/media/materials/ground.jpg";
    return createStandardMaterial(url, "groundMat");
  }

  function loadWaterBodyMaterial() {
    let url = djangoUrl + "/media/materials/waterbody.jpg";
    return createStandardMaterial(url, "waterbodyMat");
  }

  function loadRoadMaterial() {
    let url = djangoUrl + "/media/materials/road.jpg";
    return createStandardMaterial(url, "roadMat");
  }

  function loadFootpathMaterial() {
    let url = djangoUrl + "/media/materials/footpath.jpg";
    return createStandardMaterial(url, "footpathMat");
  }

  function loadDeckMaterial() {
    let url = djangoUrl + "/media/materials/deck.jpg";
    return createStandardMaterial(url, "deckMat");
  }


  let wallTypeMultiMaterialMapping = {};
  function addWallTypeMultiMaterialMapping(wallType, multiMat) {
    wallTypeMultiMaterialMapping[wallType] = multiMat;
  }

  function getWallTypeMultiMaterialMapping(wallType) {
    return wallTypeMultiMaterialMapping[wallType];
  }
  
  function clearWallTypeMultiMaterialMapping(){
    wallTypeMultiMaterialMapping = {};
  }

  function loadWallMaterial(wallType) {
    let multimat = getWallTypeMultiMaterialMapping(wallType);
    if (!multimat) {
      multimat = store.scene.getMultiMaterialByID("multi" + wallType);
      addWallTypeMultiMaterialMapping(wallType, multimat);
    }
    if (multimat) {
      // multimat.subMaterials[0].alpha = 1;
      return multimat;
    }

    let mat;
    if (wallType === "BRICK") mat = store.scene.getMaterialByName("wall_mat");
    else {
      let wallTypeMaterialMapping = {
        // BRICK: "wall_mat",
        CONCRETE: "Concrete_Grooved",
        WOOD: "Wood_Planking",
        GLASS: "Glass_Frosted_Blue"
      };
      let wallTypeMaterialTypeMapping = {
        BRICK: "Brick",
        CONCRETE: "Concrete",
        WOOD: "Wood",
        GLASS: "Glass"
      };
      let url = djangoUrl + "/media/materials/" + wallTypeMaterialMapping[wallType] + ".jpg";
      mat = createStandardMaterial(url, wallType, { materialType: wallTypeMaterialTypeMapping[wallType] });
      if (mat.name === "wall_mat") mat.alpha = 1;
      // mat.materialType = "WallType";
    }
    multimat = new BABYLON.MultiMaterial("multi" + wallType, store.scene);
    addWallTypeMultiMaterialMapping(wallType, multimat);
    multimat.subMaterials.push(mat);
    saveMaterialInBackend(multimat);
    return multimat;
  }

  return {
    loadSiteMaterial,
    loadPoolMaterial,
    loadGroundMaterial,
    loadRoadMaterial,
    loadFootpathMaterial,
    loadDeckMaterial,
    loadWaterBodyMaterial,
    loadWallMaterial,
    addWallTypeMultiMaterialMapping,
    getWallTypeMultiMaterialMapping,
    clearWallTypeMultiMaterialMapping
  };
})();
export {
  loadMaterials,
  loadWallMaterial,
  loadFloorMaterial,
  loadCeilingMaterial,
  changeTextureDetail,
  _changeTextureDetail,
  changeDiffuseColor,
  textureOnLoadCallback,
  findAverageColor,
  createMaterialFromImage,
  applyMaterialToFace,
  applyMaterialToFace2,
  checkIfVertExists,
  getConstantElem,
  getLastVertex,
  computeAreaofSubMesh,
  computerAreaofSubMesh,
  loadGardenMaterial,
  loadMassMaterial,
  loadSolidMaterial,
  loadStaircaseMaterial,
  addMaterialToMesh,
  addMaterialToMeshSolid,
  loadWallModeMaterial,
  setUVScale,
  copyMaterialData,
  copyMaterialDataByEntity,
  copyMaterialDataByFace,
  applyMaterialByFace,
  applyMaterialByBREP,
  materialLoader
};
