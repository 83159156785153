import Fuse from "fuse.js";
const dashboardService = (function() {
    let projectList = null;
    const createList = (items) => {
        projectList = new Fuse(items, {
            shouldSort: true,
            keys: ["name", "desc"]
        })
    }
    const getList = () => {
        return projectList;
    }

    return {
        createList,
        getList
    }
})();

export {dashboardService};