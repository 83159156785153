import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { approveSignOff, getSignOff, requestSignOff } from '../../services/projects.service';
import gearIcon from "../../assets/icons/gear.svg";
import ActionDropdown from '../ActionDropdown';
import { useSelector } from 'react-redux';
import authService from '../../services/auth.service';
import _ from 'lodash';
const SignOffWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  /* identical to box height */
  letter-spacing: -0.333333px;

  color: #4F4F4F;

  &.disabled{
    opacity: 0.5;
    pointer-events: none;
  }
  .dropdown-wrapper{
    flex: 1;
    display: flex;
  }
  .message{
    flex: 1;
  }
  button{
    box-sizing: border-box;
    padding: 5px 15px;
    gap: 10px;

    width: 86px;
    height: 23px;
    right: 0px;
    top: 0px;
    border: 1px solid #EB1E45;
    border-radius: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;

    text-align: center;
    letter-spacing: -0.333333px;

    color: #EB1E45;
    background: transparent;
    cursor: pointer;
  }
  .actions-wrapper{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.333333px;
    color: #818181;
  }
  .sign-off-status{
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;

    /* identical to box height */
    text-align: center;
    letter-spacing: -0.333333px;

    color: #4F4F4F;
  }
  .approved{
    color: #27AE60;
  }
`
function SignOff({ currentProjectTeam }) {
    const [signOff, setSignOff] = useState({
        selectedUser: null,
        isRequested: false,
        isRequestedFromMe: false,
        requestedToId: null,
        isApproved: false,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [allUsersForProject, setAllUsersForProject] = useState([]);
    const [requestedTo, setRequestedTo] = useState(null);
    const { roles } = useSelector(state => state.roles);

    const handleUpdateSignOff = (updatedSignOff) => {
        const isRequested = true;
        const isApproved = updatedSignOff.approved;
        const isRequestedFromMe = authService.getCurrentUser().id === updatedSignOff.requested_to;
        const requestedToId = updatedSignOff.requested_to;
        setSignOff({ isRequested, isRequestedFromMe, isApproved, requestedToId })
    }

    useEffect(() => {
        getSignOff()
            .then(resp => {
                if (resp.status === 'success' && resp.requestedSignOff) {
                    handleUpdateSignOff(resp.requestedSignOff);
                }
                setIsLoading(false);
            })
    }, []);

    useEffect(() => {
        const currentUser = authService.getCurrentUser();
        if (!currentUser.email) return;
        let allUsersForProjectNew = [];
        if (currentProjectTeam) {
            for (let member of currentProjectTeam.members) {
                if (member.status === "approved" && currentProjectTeam.me.team_member_id !== member.team_member_id) {
                    allUsersForProjectNew.push({ title: member.user.name || member.user.email, email: member.user.email, id: member.user.id, handleClick: () => { setSignOff({ selectedUser: member }) } });
                }
            }
        }
        for (let role of roles) {
            if (role.user.email !== currentUser.email) {
                const newRole = _.cloneDeep(role)
                newRole.user.name = newRole.user.firstName;
                allUsersForProjectNew.push({ title: newRole.user.name || newRole.user.email, email: newRole.user.email, id: parseInt(newRole.user.id), handleClick: () => { setSignOff({ selectedUser: newRole }) } });

            }
        }
        allUsersForProjectNew = _.uniqBy(allUsersForProjectNew, 'email');
        setAllUsersForProject(allUsersForProjectNew);
    }, [roles, currentProjectTeam]);

    useEffect(() => {
        if (signOff.requestedToId != null) {
            for (let user of allUsersForProject) {
                if (user.id === signOff.requestedToId) {
                    setRequestedTo(user)
                }
            }

        }
    }, [signOff, allUsersForProject]);

    const handleRequestSignOff = async () => {
        if (!signOff.selectedUser) return;
        setIsWaitingForResponse(true);
        const requestedbyteammemberID = currentProjectTeam?.me.team_member_id;
        const requestedtoteammemberID = signOff.selectedUser.team_member_id;
        const requestedtouserID = signOff.selectedUser.user.id;
        const resp = await requestSignOff(requestedbyteammemberID, requestedtoteammemberID, requestedtouserID)
        setIsWaitingForResponse(false);
        if (resp.status === 'success' && resp.requestedSignOff) {
            handleUpdateSignOff(resp.requestedSignOff);
        }
    }

    const handleApprove = async () => {
        setIsWaitingForResponse(true);
        const resp = await approveSignOff();
        setIsWaitingForResponse(false);
        if (resp.status === 'success' && resp.requestedSignOff) {
            handleUpdateSignOff(resp.requestedSignOff);
        }
    }

    if (isLoading) return null;
    return (
        <SignOffWrapper className={`${isWaitingForResponse ? 'disabled' : ''}`}>
            {/* <img src={gearIcon} alt="setting" /> */}
            {/* SIGN OFF NOT REQUESTED */}
            {
                !signOff.isRequested &&
                <>
                    <div className="dropdown-wrapper">
                        <span style={{ width: "14em" }}>{signOff.selectedUser?.user.name || signOff.selectedUser?.user.email || "Select user to sign-off "}</span>
                        <ActionDropdown
                            actions={allUsersForProject}
                            dropdownAlign="right bottom"
                            dropdownStyle={{ width: "15.5em" }}
                            showSingle={true}
                        />
                    </div>
                    <button onClick={handleRequestSignOff}>Get Sign-off</button>
                </>
            }
            {/* SIGN OF REQUESTED */}
            {
                signOff.isRequested &&
                <>
                    {/* NOT APPROVED and REQUESTED FROM ME */}
                    {
                        !signOff.isApproved && signOff.isRequestedFromMe &&
                        <>
                            <div className='message'>Sign Off Requested</div>
                            <button onClick={handleApprove}>Approve</button>
                        </>
                    }
                    {/* NOT APPROVED and NOT REQUESTED FROM ME */}
                    {
                        !signOff.isApproved && !signOff.isRequestedFromMe &&
                        <>
                            <div className='message'>Sign Off Requested from {requestedTo?.title || requestedTo?.email}</div>
                            <span className="sign-off-status">Pending</span>
                        </>
                    }

                    {/* APPROVED and REQUESTED FROM ME */}
                    {
                        signOff.isApproved && signOff.isRequestedFromMe &&
                        <>
                            <div className='message'>You have signed off on changes</div>
                            <span className="sign-off-status approved">Approved</span>
                        </>
                    }
                    {/* APPROVED and NOT REQUESTED FROM ME */}
                    {
                        signOff.isApproved && !signOff.isRequestedFromMe &&
                        <>
                            <div className='message'>{requestedTo?.title || requestedTo?.email} signed off on changes</div>
                            <span className="sign-off-status approved">Approved</span>
                        </>
                    }
                </>
            }
        </SignOffWrapper>
    );
}

export default SignOff;