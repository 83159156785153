import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import droparrowIcon from "../../assets/icons/dropArrow.svg";
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0.25em;
    position: relative;
    width: fit-content;
    font-size: small;
    img{
        cursor: pointer;
        z-index: 1;
    }
    .actions-wrapper{
        position: absolute;
        top: 0;
        padding: 0.125em 0em;
        text-align: left;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(197, 202, 220, 0.3);
        border-radius: 12px;
        line-height: normal;
        z-index: 2;
        max-height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .action-icon-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .action-icon{
        width: 0.75em;
    }
    .actions-wrapper.left{
        left: 0;
    }
    .actions-wrapper.right{
        right: 0;
    }
    .actions-wrapper.top{
        top: 0;
    }
    .actions-wrapper.bottom{
        top: auto;
        bottom: 0;
    }
    .action{
        cursor: pointer;
        padding: 0.25em 0.5em;
        min-width: 8em;
    }
    .action:hover{
        background-color: #F2F2F2;
    }
`
function ActionDropdown({actions, dropdownAlign="left", unEditable=false, wrapperStyle, dropdownStyle={}, showSingle = false, icon, iconStyles={}, ...props}) {
    const [showActions, setShowActions] = useState(false);
    icon = icon || droparrowIcon;
    const ref = useRef(null);
    useOutsideClickHandler(ref, () => {setShowActions(false)})
    const handleActionClick = (action) => {
        setShowActions(false);
        action.handleClick();
    }
    if(unEditable) return null;
    return ( 
        <Wrapper {...props} ref={ref} style={wrapperStyle}>
            <div className='action-icon-container' onClick={(evt) => {evt.preventDefault();setShowActions(true)}}>
            {
                showSingle ?
                actions.length > 0 &&
                    <img className='action-icon' style={iconStyles} src={icon} alt="dropdown" />
                :
                actions.length > 1 &&
                    <img className='action-icon' style={iconStyles} src={icon} alt="dropdown" />
            }
            </div>
            {
                showActions && 
                <div className={`actions-wrapper ${dropdownAlign}`} style={dropdownStyle}>
                    {
                        actions.map((action, index) => {
                            return (
                                <div key={`${action.title}-${index}`} className='action' onClick={(evt) => {evt.preventDefault(); handleActionClick(action)}}>
                                    {action.title}
                                </div>
                            ) 
                        })
                    }
                </div>
            }
        </Wrapper>
    );
}

export function ActionDropdownCustomizable({
    actions, dropdownAlign="left", unEditable=false, dropdownStyle={}, showSingle = false, icon, iconStyles={},
    triggerComponent,
    useComponent,
    ...props
}) {
    const [showActions, setShowActions] = useState(false);
    icon = icon || droparrowIcon;
    const ref = useRef(null);
    useOutsideClickHandler(ref, () => {setShowActions(false)})
    const handleActionClick = (action) => {
        setShowActions(false);
        if(useComponent) return
        action.handleClick();
    }
    if(unEditable) return null;
    return ( 
        <Wrapper {...props} ref={ref}>
            {
                useComponent ? React.cloneElement(triggerComponent, { onClick: (evt) => {evt.preventDefault(); setShowActions(true)}}) :
                (
                showSingle ?
                actions.length > 0 &&
                    <img className='action-icon' style={iconStyles} src={icon} alt="dropdown" onClick={(evt) => {evt.preventDefault(); setShowActions(true)}} />
                :
                actions.length > 1 &&
                    <img className='action-icon' style={iconStyles} src={icon} alt="dropdown" onClick={(evt) => {evt.preventDefault(); setShowActions(true)}} />
                    )
            }
            {
                showActions && 
                <div className={`actions-wrapper ${dropdownAlign}`} style={dropdownStyle}>
                    {
                        actions.map((action, index) => {
                            return (
                                useComponent ?
                                <div className='action'> {action.component} </div> :
                                <div key={`${action.title}-${index}`} className='action' onClick={(evt) => {evt.preventDefault(); handleActionClick(action)}}> { action.title } </div>
                            ) 
                        })
                    }
                </div>
            }
        </Wrapper>
    );
}


export default ActionDropdown;