import _ from "lodash";
import {AutoSave} from "../socket/autoSave";
import {autoSaveConfig} from "../socket/autoSaveConfig";

const delayedExecutionEngine = (function () {
  const _executables = [];

  const _execute = function (executable) {
    try {
      return executable.callback.call();
    } catch (e) {
      console.error(e);
      AutoSave.handleFailure(
        autoSaveConfig.CONSTANTS.autoSaveFailureNewMessage
      );
    }
  };
  
  function addExecutable(callback, name = callback.name) {
    if (!_executables.inArray(e => e.callback === callback)) {
      _executables.push({
        name,
        callback,
      });
    }
  }

  function removeLastExecutable() {
    if (!_.isEmpty(_executables)) _executables.pop();
  }

  function executeOne() {
    if (!_.isEmpty(_executables)) {
      let firstInQueue = _.first(_executables);
      _execute(firstInQueue);
      _executables.splice(0, 1);
    }
  }

  function executeAll() {
    const toReturn = [];
    _executables.forEach(e => toReturn.push(_execute(e)));
    _executables.length = 0;

    return _.compact(toReturn);
  }

  function kitneBacheHai() {
    return _executables.length;
  }

  function flush(log = true) {
    _executables.forEach(e => {
      if (log){
        console.warn("Flushing the executable", e.name);
        console.trace();
      }
    });
    _executables.length = 0;
  }

  return {
    addExecutable,
    removeLastExecutable,
    executeOne,
    executeAll,
    kitneBacheHai,
    flush,
  };
})();

export { delayedExecutionEngine };
