"use strict";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { projectionOfPointOnLine } from "../../libs/snapFuncs.js";
import { virtualSketcher } from "./virtualSketcher.js";
import { getTopFaceVertices,
  getBottomFaceVertices,
  removeVertexFromComponent,
  splitFaceByFaceObject, 
  getFaceVerticesFromFace,
  findNearestEdgeFromBRep,
  getVertexFaces} from "../../libs/brepOperations.js";
import { convertGlobalVector3ToLocal,convertLocalVector3ToGlobal } from "../extrafunc.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { setLayerTransperancy } from "../../libs/sceneFuncs.js";
import { splitFaceOperator } from "../meshoperations/splitFaceOperations.js";
import { dimensionsTuner } from "./dimensionsTuner.js";

const snapmda = window.snapmda;
const geometryUpdater = (function () {
  const CONSTANTS = {
    operatingSpace: null,
  };

  const init = () => {
    CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
  }

  let componentVerticesToAddMap;
  let componentVerticesToRemoveMap;

  const affectedComponents = [];
  const excludedComponents = [];
  let commandData;

  const getFakePickInfo = function (vertex, component, facetId) {
    return {
      hit: true,
      pickedMesh: component.mesh,
      pickedPoint: vertex,
      faceId: facetId,
    };
  };

  const _cleanUp = function () {
    componentVerticesToAddMap = new Map();
    componentVerticesToRemoveMap = new Map();
    affectedComponents.length = 0;
    excludedComponents.length = 0;
    commandData = [];
  };

  const _addForAddition = function (edgeLabel, vector) {
    if (edgeLabel.components.length !== 1) {
      console.error(
        "Something is wrong, edgeLabel.components.length = " +
          edgeLabel.components.length
      );
      console.log(vector);
      console.log(edgeLabel.getNodeVectors());
    } else {
      const component = edgeLabel.components[0];
      const vectorExactlyOnTheEdge = projectionOfPointOnLine(
        vector,
        ...edgeLabel.getNodeVectors(component)
      );

      const verticesToAdd = componentVerticesToAddMap.get(
        edgeLabel.components[0]
      );
      if (verticesToAdd) {
        verticesToAdd.pushIfNotExist(vectorExactlyOnTheEdge, (v) =>
          v.almostEquals(vectorExactlyOnTheEdge)
        );
      } else {
        componentVerticesToAddMap.set(edgeLabel.components[0], [
          vectorExactlyOnTheEdge,
        ]);
      }
    }
  };

  const add = function (mass) {
    const graph = virtualSketcher.getBottomAdjacencyGraph();
    const edgeLabels = Object.values(graph._edgeLabels);
    const nodeLabels = Object.values(graph._nodes);

    const vertices = virtualSketcher.util.getAppropriateVerticesToAdd(mass);

    const edgeLabelsOfMassBeingAdded = [];

    /*
        Vertices added to other masses because of the current mass
         */
    vertices.forEach((v) => {
      edgeLabels.some((label) => {
        if (label.components.includes(mass)) {
          edgeLabelsOfMassBeingAdded.pushIfNotExist(label, (e) => e === label);
          // Will be dealt with in the loop below
          return;
        }

        if (label.contains(v)) {
          _addForAddition(label, v);
          return true;
        }
      });
    });

    /*
        Vertices added to the current mass itself
         */
    edgeLabelsOfMassBeingAdded.forEach((edgeLabel) => {
      nodeLabels.forEach((nodeLabel) => {
        // not array.some, multiple nodes can add themselves as vertices to a single edge

        // to accommodate masses of different heights, we need to check if an edge contains a vector
        // in '2D' perspective
        // have added a edgeLabel.contains2D for this purpose, will be used later

        if (edgeLabel.contains(nodeLabel.vector)) {
          const componentsAssociatedWithNode = nodeLabel.components;

          // const associatedAndNotExcludedComponents = _.difference(componentsAssociatedWithNode, excludedComponents);
          const associatedAndNotExcludedComponents = _.difference(
            componentsAssociatedWithNode,
            []
          );

          if (!_.isEmpty(associatedAndNotExcludedComponents))
            _addForAddition(edgeLabel, nodeLabel.vector);
        }
      });
    });
  };

  const remove = function (node, options = {}) {
    let vertexToRemove = options.vertexToRemove;
    let component = options.component;

    if (!component || !vertexToRemove) {
      const graph = virtualSketcher.getBottomAdjacencyGraph();
      const nodeLabel = graph.node(node);

      if (nodeLabel.components.length !== 1) return;

      vertexToRemove = nodeLabel.vector;
      component = nodeLabel.components[0];
    }

    const verticesToRemove = componentVerticesToRemoveMap.get(component);
    if (verticesToRemove) {
      // verticesToRemove.pushIfNotExist(vertexToRemove, v => v.almostEquals(vertexToRemove));

      // not pushIfNotExist because sometimes two duplicate vertices will need to be removed
      verticesToRemove.push(vertexToRemove);
    } else if (
      !excludedComponents.includes(component) ||
      options.removalForGeometrySanitization
    ) {
      const mapValue = [vertexToRemove];
      mapValue.doNotAddBackToGraph = options.removalForGeometrySanitization;
      componentVerticesToRemoveMap.set(component, mapValue);
    }
  };

  const getTopFacetId = function (component) {
    const metadata = {};
    getTopFaceVertices(component, CONSTANTS.operatingSpace, metadata);

    if (isNaN(metadata.faceId)) {
      console.error("Face ID not found");
    } else {
      return component.faceFacetMapping[metadata.faceId][0];
    }
  };

  const getBottomFacetId = function (component) {
    const metadata = {};
    getBottomFaceVertices(component, CONSTANTS.operatingSpace, metadata);

    if (isNaN(metadata.faceId)) {
      console.error("Face ID not found");
    } else {
      return component.faceFacetMapping[metadata.faceId][0];
    }
  };

  const isFaceTopOrBottom = function (component, faceId) {
    const metadata = {
      allFaces: true,
    };

    getTopFaceVertices(component, null, metadata);

    if (metadata.faceIds.includes(faceId)) return true;

    getBottomFaceVertices(component, null, metadata);

    if (metadata.faceIds.includes(faceId)) return true;
  };

  const getTopFaceId = function (component,options = {}) {
    getTopFaceVertices(component, CONSTANTS.operatingSpace, options);

    if (isNaN(options.faceId)) {
      console.error("Face ID not found");
    } else {
      return options.faceId;
    }
  };

  const getBottomFaceId = function (component,options = {}) {
    getBottomFaceVertices(component, CONSTANTS.operatingSpace, options);

    if (isNaN(options.faceId)) {
      console.error("Face ID not found");
    } else {
      return options.faceId;
    }
  };

  const _filterInstances = function (map) {
    const _modifiedIntersectionWith = (comparator, arrayOfArrays) => {
      const firstArray = arrayOfArrays.shift();
      let commonElements = firstArray;
      arrayOfArrays.forEach((array2) => {
        commonElements = commonElements.filter((x) =>
          array2.some((y) => comparator(x, y))
        );
      });

      return commonElements;
    };

    let components = Array.from(map.keys());
    if (_.isEmpty(components)) return;

    const affectedMeshes = components.map((c) => c.mesh);

    const instances = affectedMeshes.filter((m) => m.isAnInstance);
    if (_.isEmpty(instances)) return;

    const sourceMeshesInvolved = _.uniq(instances.map((i) => i.sourceMesh));
    sourceMeshesInvolved.forEach((m) => {
      const instanceComponents = m.instances.map((i) => i.getSnaptrudeDS());

      let startIndex;
      if (_.difference(m.instances, affectedMeshes).length === 0) {
        // all instances of a sourceMesh are affected
        // so keep 1 and remove others
        startIndex = 1;

        // also keep only the vertices common to all instances
        const verticesToModify = [];
        instanceComponents.forEach((ic) => {
          verticesToModify.push(map.get(ic));
        });

        const verticesToModifyLocal = verticesToModify.map((vertices, i) => {
          const respectiveMesh = instanceComponents[i].mesh;
          return vertices.map((v) => {
            const vDash = convertGlobalVector3ToLocal(v, respectiveMesh);
            vDash.forcedRemoval = v.forcedRemoval;
            return vDash;
          });
        });

        // const commonVertices = _.intersectionWith(...verticesToModifyLocal, (v1, v2) => v1.almostEquals(v2));
        // _.intersectionWith([a, b, b], [a, b, b]) gives [a, b] rather than [a, b, b]
        // so need to modify the usage

        const commonVertices = _modifiedIntersectionWith(
          (v1, v2) => v1.almostEquals(v2),
          verticesToModifyLocal
        );

        if (_.isEmpty(commonVertices)) {
          console.warn(
            "Common vertices between instances not found, will not be modifying geometry"
          );
          startIndex = 0;
          // not all instances affected equivalently
          // remove all the instances
        } else {
          const firstComponent = instanceComponents[0];
          const mapValues = instanceComponents.map((c) => map.get(c));

          const doNotAddBackToGraph = mapValues.reduce((acc, currentValue) => {
            return acc || currentValue.doNotAddBackToGraph;
          }, false);

          const newMapValue = commonVertices.map((v) => {
            const vDash = convertLocalVector3ToGlobal(v, firstComponent.mesh);
            vDash.forcedRemoval = v.forcedRemoval;
            return vDash;
          });

          newMapValue.doNotAddBackToGraph = doNotAddBackToGraph;

          map.set(firstComponent, newMapValue);
        }
      } else {
        // not all instances present, remove all of them
        console.warn(
          "Not all instances being edited, will not be modifying geometry"
        );
        startIndex = 0;
      }

      const instanceComponentsToRemove = instanceComponents.slice(startIndex);
      instanceComponentsToRemove.forEach((ic) => map.delete(ic));
    });
  };

  /**
     * Looks for instances and vertices that are not marked for removal but could be removed
     *
     * https://imgur.com/a/b0r1Zcf
     * When j moves to the left, v1 is marked for removal, this function adds v2 to the map
     *
     * @param componentVerticesToRemoveMap
     * @private
     */
   const _addRemovalEligibleInstances = function (componentVerticesToRemoveMap){
    let components = Array.from(componentVerticesToRemoveMap.keys());
    if (_.isEmpty(components)) return;

    const affectedMeshes = components.map(c => c.mesh);

    const instances = affectedMeshes.filter(m => m.isAnInstance);
    if (_.isEmpty(instances)) return;

    const sourceMeshesInvolved = _.uniq(instances.map(i => i.sourceMesh));
    sourceMeshesInvolved.forEach(m => {

        const instanceComponents = m.instances.map(i => i.getSnaptrudeDS());
        const instanceMeshesNotGettingModified = _.difference(m.instances, affectedMeshes);

        if (instanceMeshesNotGettingModified.length !== 0) {
            // all instances of a sourceMesh are affected
            // so keep 1 and remove others

            // also keep only the vertices common to all instances
            const arrayOfVerticesToModify = [];
            instanceComponents.forEach(ic => {
                const vertices = componentVerticesToRemoveMap.get(ic);
                arrayOfVerticesToModify.push(vertices);
            });

            const arrayOfVerticesToModifyLocal = arrayOfVerticesToModify.map((vertices, i) => {
                if (!vertices) return;

                const respectiveMesh = instanceComponents[i].mesh;
                return vertices.map(v => {
                    const vDash = convertGlobalVector3ToLocal(v, respectiveMesh);
                    vDash.forcedRemoval = v.forcedRemoval;
                    return vDash;
                });
            });

            const verticesToModifyLocal = _.uniqWith(_.compact(_.flatten(arrayOfVerticesToModifyLocal)),
                (v1, v2) => v1.almostEquals(v2));

            instanceMeshesNotGettingModified.forEach(mesh => {
                const component = mesh.getSnaptrudeDS();

                verticesToModifyLocal.forEach(vertexLocal => {
                    const vertexGlobal = convertLocalVector3ToGlobal(vertexLocal, mesh);
                    const vertexLabel = virtualSketcher.lookup(vertexGlobal);
                    if (!vertexLabel) return;

                    if (vertexLabel.components.length === 1){
                        // this can be removed
                        let vertices = componentVerticesToRemoveMap.get(component);
                        if (!vertices){
                            vertices = [];
                            componentVerticesToRemoveMap.set(component, vertices);
                        }
                        vertices.push(vertexGlobal);
                    }
                });
            });
        }
    });
  };


  const performRemoval = function () {
    _addRemovalEligibleInstances(componentVerticesToRemoveMap);
    _filterInstances(componentVerticesToRemoveMap);

    let componentsForRemoveEdge = Array.from(
      componentVerticesToRemoveMap.keys()
    );
    if (_.isEmpty(componentsForRemoveEdge)) return;

    const affectedMeshes = componentsForRemoveEdge.map((c) => c.mesh);

    commandData =
      commandUtils.geometryChangeOperations.getCommandData(affectedMeshes);

    const componentsToNotAddBackImmediatelyToGraph = [];
    // they'll not be added with updateWithoutGeometryEdit now, will be done later

    componentVerticesToRemoveMap.forEach((verticesToRemove, component) => {
      if (verticesToRemove.doNotAddBackToGraph)
        componentsToNotAddBackImmediatelyToGraph.push(component);
      verticesToRemove.forEach((lowerVertex) => {
        let appropriateFaceId;
        if (virtualSketcher.util.doesComponentGrowDownwards(component))
          appropriateFaceId = getTopFaceId(component);
        else appropriateFaceId = getBottomFaceId(component);

        // faceId determined within the loop because it could change after a removal

        // used to calculate the top vertex before by using storey height but this is a better approach
        // removing the vertex while looking from bottom of the mass

        const options = {
          operatingSpace: CONSTANTS.operatingSpace,
          faceId: appropriateFaceId,
          onlyIfSystemGenerated: true,
          forcedRemoval: lowerVertex.forcedRemoval,
        };

        const removed = removeVertexFromComponent(
          lowerVertex,
          component,
          options
        );
        if (!removed) {
          const reason =
            options.nonRemovalReason || "Auto removal of vertices failed";
          console.error(reason);
        }
      });

      setLayerTransperancy(component.mesh);
    });

    affectedComponents.push(...componentsForRemoveEdge);

    _.remove(componentsForRemoveEdge, (c) =>
      componentsToNotAddBackImmediatelyToGraph.includes(c)
    );

    // componentsForRemoveEdge.forEach(c => virtualSketcher.updateWithoutGeometryEdit(c, c.mesh.isAnInstance));
    virtualSketcher.updateWithoutGeometryEdit(componentsForRemoveEdge, true);

    // if component being updated is an instance, other instances would've been removed from componentsForRemoveEdge,
    // so 'allInstances' should be set to true for graph to be updated for other instances

    componentVerticesToRemoveMap = new Map();
  };

  function getFaceToSplit(topFaceId, botFaceId, component, lowerVertex)
  {
    const pickInfo = getFakePickInfo(
      lowerVertex,
      component,
      botFaceId
    );
    const faceInfo = { face: null, lowerPoint: null };

    const mesh = component;
    const space = CONSTANTS.operatingSpace;
    const edge = findNearestEdgeFromBRep(pickInfo, space);

    if (edge) {
      const v1 = edge.headPt;
      const v2 = edge.tailPt;
      //get side face of the vertex v1
      //get side face of the vertex v2
      var facesv1 = getVertexFaces(component, v1);
      var facesv2 = getVertexFaces(component, v2);
      let tempSide = _.intersectionWith(
        facesv1,
        facesv2);
      //intersection set must have only two faces
      //get the face which is not bottom
      //find common side face for v1 and v2 
      let sideFaceId = (tempSide[0].index == botFaceId)? tempSide[1].index: tempSide[0].index;
      //find common vertices for side face and top Face
      // let botVertices = getBottomFaceVertices(botFaceId, mesh, BABYLON.Space.WORLD);
      const metadata = {};
      let topVertices = getTopFaceVertices(component, CONSTANTS.operatingSpace, metadata);
      let brep =   component.brep? component.brep : component.getSnaptrudeDS().brep;
      let sideFace = brep.getFaces()[sideFaceId];
      // options.faceId = sideFaceId;
      // let sideVertices = getFaceVerticesFromFace(sideFace, mesh, space, options);
      // let difference = sideVertices.filter(x => !botVertices.includes(x));
      // ///difference is top vertices to split
      // const options = { facetId: pickInfo.faceId, faceId: NaN };
      const v4 = v1.clone();
      const v3 = v2.clone();
      v4.y = topVertices[0].y;
      v3.y = topVertices[0].y;
      if (v3 && v4) {
        if (sideFace) {
          faceInfo.face = sideFace;

          const upperEdgeLength = BABYLON.Vector3.Distance(v1, v2);
          const lowerEdgeLength = BABYLON.Vector3.Distance(v4, v3);

          const upperEdgeDivisionRatio =
            BABYLON.Vector3.Distance(v1, lowerVertex) / upperEdgeLength;
          const lowerEdgeDivisionLength =
            lowerEdgeLength * upperEdgeDivisionRatio;

          let splitPt = v4.add(
            v3.subtract(v4).normalize().scale(lowerEdgeDivisionLength)
          );
          splitPt = projectionOfPointOnLine(splitPt, v3, v4);
          faceInfo.lowerPoint = splitPt;
          // faceInfo.lowerPoint = projectionOfPointOnLine(upperPoint, v3, v4);
        }
      }
    }
    return faceInfo;

  }
  const performAddition = function () {
    _filterInstances(componentVerticesToAddMap);

    let componentsForAddEdge = Array.from(componentVerticesToAddMap.keys());
    if (_.isEmpty(componentsForAddEdge)) return;

    _.remove(componentsForAddEdge, (e) => affectedComponents.includes(e));

    // removing the components for whom commandData has been taken already
    const affectedMeshes = componentsForAddEdge.map((c) => c.mesh);

    const commandDataForAddEdge =
      commandUtils.geometryChangeOperations.getCommandData(affectedMeshes);
    commandData.push(...commandDataForAddEdge);

    const editedComponents = [];

    componentVerticesToAddMap.forEach((verticesToAdd, component) => {
      verticesToAdd.forEach((lowerVertex) => {
        editedComponents.push(component);
        var topFaceId = getTopFaceId(component);
        var botFaceId = getBottomFaceId(component);
        let appropriateFacetId;
        if (virtualSketcher.util.doesComponentGrowDownwards(component))
          appropriateFacetId = getTopFacetId(component);
        else appropriateFacetId = getBottomFacetId(component);

        // faceId determined within the loop because it could change after an addition

        const fakePickInfo = getFakePickInfo(
          lowerVertex,
          component,
          appropriateFacetId
        );
        const faceInfo = getFaceToSplit(topFaceId, botFaceId, component, lowerVertex);
        // const faceInfo = splitFaceOperator.determineFaceToSplitAndPoints(
        //   fakePickInfo,
        //   lowerVertex
        // );

        // faceInfo.lowerPoint = lowerVertex;
        if (!faceInfo.face || !faceInfo.lowerPoint) {
          console.error("Auto addition of vertices failed");
          return;
        }

        const split = splitFaceByFaceObject(
          fakePickInfo.pickedMesh,
          faceInfo.face,
          lowerVertex,
          faceInfo.lowerPoint,
          CONSTANTS.operatingSpace,
          {
            isSystemGenerated: true,
          }
        );

        if (!split) console.error("Auto addition of vertices failed");
      });

      setLayerTransperancy(component.mesh);
    });

    // editedComponents.forEach(c => virtualSketcher.updateWithoutGeometryEdit(c, c.mesh.isAnInstance));
    virtualSketcher.updateWithoutGeometryEdit(editedComponents, true);

    // if component being updated is an instance, other instances would've been removed from editedComponents,
    // so 'allInstances' should be set to true for graph to be updated for other instances

    affectedComponents.push(...componentsForAddEdge);

    componentVerticesToAddMap = new Map();
  };

  const excludeComponents = function (components) {
    excludedComponents.push(...components);
  };

  const yieldResult = function () {
    performRemoval();
    performAddition();

    let command = null;
    if (!_.isEmpty(affectedComponents)) {
      const affectedMeshes = affectedComponents.map((c) => c.mesh);
      commandData = commandUtils.geometryChangeOperations.getCommandData(
        affectedMeshes,
        commandData
      );

      command = commandUtils.geometryChangeOperations.getCommand(
        "massBIMIntegrationGeometryEdit",
        commandData
      );
      _cleanUp();
    }


    const dimensionChangeCommand = dimensionsTuner.yieldResult();

    const allCommands = _.compact([command, dimensionChangeCommand]);

    return commandUtils.geometryChangeOperations.flattenCommands(allCommands);
  };

  _cleanUp();

  return {
    init,
    add,
    remove,
    excludeComponents,
    performRemoval,
    performAddition,
    yieldResult,
    util: {
      getTopFacetId,
      getTopFaceId,
      getBottomFaceId,
      getBottomFacetId,
      getFakePickInfo,
      isFaceTopOrBottom,
    },
  };
})();
export { geometryUpdater };
