import React from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { colors } from "../../themes/constant";

const StyledSlider = styled.div``;

function CustomSlider({ value, onChange, onAfterChange, min, max, step }) {
  return (
    <StyledSlider>
      <Slider
        min={min || 0}
        max={max || 1}
        step={step || 0.1}
        value={value}
        onChange={onChange}
        onAfterChange={onAfterChange}
        railStyle={{
          height: "0.375rem",
          border: "2px",
        }}
        handleStyle={{
          height: "0.8125rem",
          width: "0.8125rem",
          marginLeft: -5,
          marginTop: -5,
          backgroundColor: colors.fullWhite,
          border: `2px solid ${colors.brightRed}`,
        }}
        trackStyle={{
          background: colors.brightRed,
          border: "2px",
        }}
      />
    </StyledSlider>
  );
}

export default CustomSlider;
