let StoneMass = {
  "_name": "StoneMass",
  "_layers": [
      {check:false, value:"Stone",
      subtype: "",
      thickness:300,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true
      }],
}

let StoneMassFace = {
  "_name": "StoneMassFace",
  "_layers": [{check:false, value:"Stone",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Stone",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
};

let BrickMass = {
  "_name": "BrickMass",
  "_layers": [
      {check:false, value:"Brick",
      subtype: "",
      thickness:300,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true
      }],
}

let BrickMassFace = {
  "_name": "BrickMassFace",
  "_layers": [{check:false, value:"Brick Cladding",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Brick Cladding",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let TilesMass = {
  "_name": "TileMassFace",
  "_layers": [{check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let TilesMassFace = {
  "_name": "TileMassFace",
  "_layers": [{check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let WoodMass = {
  "_name": "WoodMass",
  "_layers": [{check:false, value:"Wood",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

let WoodMassFace = {
  "_name": "WoodMassFace",
  "_layers": [{check:false, value:"Wood",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "sq mts",
    core: false
  }, {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }],
}

let ConcreteMass = {
  "_name": "ConcreteMass",
  "_layers": [{check:false, value:"Concrete",
    subtype: "",
    thickness:250,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
};

let ConcreteMassFace = {
  "_name": "ConcreteMassFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:250,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Concrete",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}



let ColorsMass = {
  "_name": "ColorsMass",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let ColorsMassFace = {
  "_name": "ColorsMassFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let PaintMass = {
  "_name": "ColorsMass",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let PaintMassFace = {
  "_name": "ColorsMassFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let MetalMass = {
  "_name": "MetalMass",
  "_layers": [{check:false, value:"Steel",
    subtype: "",
    thickness:25,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
};

let MetalMassFace = {
  "_name": "MetalMassFace",
  "_layers": [{check:false, value:"Metal Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "square metres",
    core: false
  }, {check:false, value:"Metal Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "square metres",
    core: false
  }]
}

let GlassMass = {
  "_name": "GlassMass",
  "_layers": [{check:false, value:"Glass",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "square metres",
    transparency: 0.2,
    core: true
  }]
};

let GlassMassFace = {
  "_name": "GlassMassFace",
  "_layers": [{check:false, value:"Glass",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Concrete",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Glass",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var GroundcoverMass = {
  "_name": "GroundcoverMass",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

var GroundcoverMassFace = {
  "_name": "GroundcoverMassFace",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  },{check:false, value:"Soil",
      subtype: "",
      thickness:25,
      quantity: "Area",
      unit: "Square metres",
    },
  ]
}

export {
  StoneMass,
  StoneMassFace,
  TilesMass,
  TilesMassFace,
  WoodMass,
  WoodMassFace,
  ConcreteMass,
  ConcreteMassFace,
  MetalMass,
  MetalMassFace,
  ColorsMass,
  ColorsMassFace,
  PaintMass,
  PaintMassFace,
  GlassMass,
  GlassMassFace,
  GroundcoverMass,
  GroundcoverMassFace
}
