import axios from "axios";
import {djangoUrl} from "./url.constants";


const authenticateUserForRender = async (floorKey) => {
  try {
    const formData = new FormData();
    formData.append('projectId', floorKey);

    const url = djangoUrl + '/createRenderUser/';
    const response = await axios.post(url, formData);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

const createNewProject = async (projectName, floorKey) => {
  try {
    const formData = new FormData();
    formData.append('projectName', projectName);
    formData.append('projectId', floorKey);

    const url = djangoUrl + '/createRenderProject/';
    const response = await axios.post(url, formData);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

const getRenderDetails = async (floorKey) => {
  try {
    const url = djangoUrl + '/getRenderDetails?projectId=' + floorKey
    const response = await axios.get(url);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

const getRenderStatus = async (floorKey) => {
  try {
    const url = djangoUrl + '/getRenderStatus?projectId=' + floorKey
    const response = await axios.get(url);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

const getRenders = async (floorKey) => {
  try {
    const url = djangoUrl + '/getRenders?projectId=' + floorKey
    const response = await axios.get(url);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

const getRenderById = async (id, floorKey) => {
  try {
    const url = djangoUrl + '/getRenderById?projectId=' + floorKey + '&renderId=' + id;
    const response = await axios.get(url);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

const startRender = async (...args) => {
  try {
    const [file, imageBase64, renderName, renderQuality, floorKey] = args;
    const formData = new FormData();
    formData.append('glb', file);
    formData.append('image', imageBase64);
    formData.append('renderName', renderName);
    formData.append('renderQuality', renderQuality);
    formData.append('projectId', floorKey);

    const url = djangoUrl + '/startRender/';
    const response = await axios.post(url, formData);

    return response.data;
  } catch (e) {
    console.warn(e);
    throw e;
  }
}

const updateRenderView = async (renderId, floorKey) => {
  try {
    const formData = new FormData();
    formData.append('renderId', renderId);
    formData.append('projectId', floorKey)

    const url = djangoUrl + '/updateRenderView/';
    const response = await axios.post(url, formData);

    return response.data;
  }
  catch (e) {
    console.warn(e);
    throw e;
  }
}

export {
  authenticateUserForRender,
  createNewProject,
  getRenderDetails,
  getRenderStatus,
  getRenders,
  getRenderById,
  startRender,
  updateRenderView
}



