import { onSolid } from "../extrafunc";
import { Furniture } from "../snaptrudeDS/furniture.ds";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  createCustomMeshFromRevitExport,
  createCustomMeshFromSpeckleData,
} from "./extraFunctions";
import { checkStorey } from "./storey";

const createRailings = (railingsData, materialList) => {
  const railingMeshes = [];

  for (const railingId in railingsData) {
    try {
      const railingData = railingsData[railingId];
      const data = railingData["data"];
        if (!railingData || !data) return;
        const mesh = createCustomMeshFromRevitExport(
          data,
          railingData,
          materialList,
          { isMassType: true }
        );

        if (!mesh) continue;
        let railingDS = new Mass(mesh);
        mesh.structure_id = store.activeLayer.structure_id;

        mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
        railingDS.storey = 1;

        railingDS.room_type = "Default";
        railingDS.massType = data.category;
        // railingDS.importType = "speckleRevitImport";
        railingDS.revitMetaData = { elementId: data.elementId };
        mesh.storey = 1;
        railingDS.storey = 1;
        railingDS.structure_id = store.activeLayer.structure_id;

        const structureCollection = StructureCollection.getInstance();
        const talkingAboutStructure = structureCollection.getStructureById(
          store.activeLayer.structure_id
        );
        const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
        talkingAboutLevel.addObjectToLevel(railingDS, false);
        const storey = checkStorey(data?.levelName);
        if (storey) {
          storey.addElement(railingDS);
        } else {
          StoreyMutation.assignStorey(railingDS);
        }
        onSolid(mesh);

        railingMeshes.push(mesh);
      
    } catch (e) {
      console.warn(e, "Corrupt Railings data");
    }
  }
  return {railingMeshes};
};

export { createRailings };
