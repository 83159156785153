import BABYLON from "../babylonDS.module.js";
import $ from "jquery";
import { store } from "../utilityFunctions/Store.js";
import { forgeConfig } from "../forgeConnection/forgeConfig";
import { removeDuplicateVertices } from "../../libs/twoD/twoServices.js";
import { drawRoomMass } from "../../libs/twoD/twoDrawRooms.js";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { AutoSave } from "../socket/autoSave.js";
import { Command } from "../commandManager/Command.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { cadImport } from "../../libs/cadImport.js";
import { scenePickController } from "../utilityFunctions/scenePickController.js";
import { DisplayOperation } from "../displayOperations/displayOperation";
import reduxStore from "../../stateManagers/store/reduxStore";
import {appendStorey, deleteLayer} from "../../stateManagers/reducers/objectProperties/storeysSlice";
import {setActiveLayerAndRecord, showToast, TOAST_TYPES} from "../extrafunc";
import {removeSelectionBox} from "../../libs/meshEvents";
import {meshUniqueIdMapper} from "../utilityFunctions/meshUniqueIdMapper";
import _ from "lodash";
import snapEngine from "../snappingEngine/snapEngine";
import { Vector3 } from "babylonjs";
/**
 * Takes away the Z coordinate.
 * @param polygonArray
 * @returns {Array}
 */
const convertPolygon3DArrayTo2D = (polygonArray) => {
  const polygon2DArray = [];
  for (let i = 0; i < polygonArray.length; i++) {
    const newPolygon = [];
    const polygon = polygonArray[i];
    for (let j = 0; j < polygon.length; j++) {
      newPolygon.push([polygon[j][0], polygon[j][1]]);
    }
    polygon2DArray.push(newPolygon);
  }
  return polygon2DArray;
};

/**
 * Adds the Z coordinate.
 * @param polygonArray
 * @param finalCoordinate
 * @returns {Array}
 */
const convertPolygon2DArrayTo3D = (polygonArray, finalCoordinate) => {
  const polygon3DArray = [];
  for (let i = 0; i < polygonArray.length; i++) {
    const newPolygon = [];
    const polygon = polygonArray[i];
    for (let j = 0; j < polygon.length; j++) {
      newPolygon.push([polygon[j][0], polygon[j][1], finalCoordinate]);
    }
    polygon3DArray.push(newPolygon);
  }
  return polygon3DArray;
};

const fetchGraphCycles = (edgeArray) => {
  const options = { edgearray: edgeArray };
  return new Promise((resolve, reject) => {
    fetch(forgeConfig.graphURL, {
      method: "post",
      body: JSON.stringify(options),
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(new Error(error));
      });
  });
};

const fetchSplitWallsAjax = (contours) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: forgeConfig.wallSplitterURL,
      crossDomain: true,
      data: {
        contours: JSON.stringify(contours),
      },
      success: function (data) {
        resolve(data);
      },
      error: function (error) {
        reject(error);
      },
      dataType: "JSON",
      method: "post",
    });
  });
};

const cancelWorkItems = workItems => {
  return new Promise((resolve, reject) => {
      $.ajax({
          url: forgeConfig.cancelWorkItemURL,
          crossDomain: true,
          data: JSON.stringify({ 'workItems': workItems }),
          success: function (data) {
              resolve(data);
          },
          error: function (error) {
              reject(error);
          },
          dataType: 'JSON',
          contentType: 'application/json',
          method: 'post'
      });
  });
};

const drawCADContours = (walls, nonBim) => {
  for (let i = 0; i < walls.length; i++) {
    let oneWall = removeDuplicateVertices(walls[i]);
    //let polygon = multiplyPointArrayByFactor(oneWall, 1/imageScaleFactor, scale);
    let polygon = oneWall;
    polygon = removeDuplicateVertices(polygon);
    polygon.push(polygon[0]);
    try {
      drawRoomMass(
        polygon,
        "room1",
        "Default",
        "1",
        "01",
        store.activeLayer.structure_id,
        false,
        1,
        null
      );
    } catch (err) {
      console.log("Error drawing wall");
    }
  }
  for (let i = 0; i < nonBim.length; i++) {
    let oneWall = removeDuplicateVertices(nonBim[i]);
    //let polygon = multiplyPointArrayByFactor(oneWall, 1/imageScaleFactor, scale);
    let polygon = oneWall;
    polygon = removeDuplicateVertices(polygon);
    polygon.push(polygon[0]);
    try {
      drawRoomMass(
        polygon,
        "room1",
        "Default",
        "1",
        "01",
        store.activeLayer.structure_id,
        false,
        1,
        null
      );
    } catch (err) {
      console.log("Error drawing wall");
    }
  }
  return true;
};

/**
 *
 * @param pointA
 * @param pointB
 * @param percentageFactor => For 50% its 0.5
 * @returns {*}
 */
function getPointInBetweenByPercentageFactor(pointA, pointB, percentage) {
  let dir = pointB.clone().subtract(pointA);
  let length = dir.length();
  dir = dir.normalize().scale(length * percentage);
  return pointA.clone().add(dir);
}

let getXAxis = function (edge) {
  if (edge[1][1] - edge[0][1] > 0) {
    return [
      [1, 0, 0],
      [0, 0, 0],
    ];
  } else {
    return [
      [0, 0, 0],
      [1, 0, 0],
    ];
  }
};

let shiftCentreOfEdgeArray = (edgeArray) => {
  let centre = BABYLON.Vector3.Zero();
  let sumX = 0;
  let sumZ = 0;

  for (let i = 0; i < edgeArray.length; i++) {
    let point = edgeArray[i][0];
    sumX += point[0];
    sumZ += point[1];
  }
  centre.x = sumX / edgeArray.length;
  centre.z = sumZ / edgeArray.length;

  for (let i = 0; i < edgeArray.length; i++) {
    for (let j = 0; j < edgeArray[i].length; j++) {
      edgeArray[i][j][0] -= centre.x;
      edgeArray[i][j][1] -= centre.z;
    }
  }

  return {
    edgeArray: edgeArray,
    centre: centre,
  };
};

const pointArrayToV3 = (pointArray) => {
  return pointArray.map(pointToV3);
}

const pointToV3 = (point) => {
  // if point[2] is undefined, v3.y = 0
  return new BABYLON.Vector3(point[0], point[2], -1 * point[1]);
}

const edgeArrayToBabylonVector = (edgeArray) => {
  const babyArray = [];
  for (let i = 0; i < edgeArray.length; i++) {
    const edge = edgeArray[i];
    const p1 = pointToV3(edge[0]);
    const p2 = pointToV3(edge[1]);
    babyArray.push([p1, p2]);
  }
  return babyArray;
};

const pathArrayToPointArray = function (path){
  let pointArray = [];
  path.forEach(p => {
    pointArray.push(p[0]);
  })
  return pointArray;
}

const _findCentre = function (anArray){

  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;
  let centre = BABYLON.Vector3.Zero();

  pathArrayToPointArray(anArray).forEach((point) => {
    sumX += point.x;
    sumY += point.y;
    sumZ += point.z;
  });

  centre.x = sumX / anArray.length;
  centre.y = sumY / anArray.length;
  centre.z = sumZ / anArray.length;

  return centre;
};

const drawCADLineSystem = (edgeArrayV3, pointArrayV3) => {

  let _centre = _findCentre(edgeArrayV3);

  const lineSystem = BABYLON.MeshBuilder.CreateLineSystem(
    "CAD",
    { lines: edgeArrayV3 },
    store.scene
  );
  lineSystem.isPickable = true;
  lineSystem.type = GLOBAL_CONSTANTS.strings.identifiers.cadSketch;
  lineSystem.color = new BABYLON.Color3.Black();
  lineSystem.intersectionThreshold = 1;
  lineSystem.setPivotPoint(_centre);

  scenePickController.add(lineSystem, true);

  if (!pointArrayV3) pointArrayV3 = _.flatten(edgeArrayV3);
  // pointArray is a newly added property in CAD import. This handles its backward compatibility

  snapEngine.cadSnaps.add(lineSystem, pointArrayV3);

  /*
  Ideal way, but a lot of unnecessary wm update is happening all over the code base

  lineSystem.onAfterWorldMatrixUpdateObservable.add(function (){
    snapEngine.cadSnaps.update(lineSystem);
  });
  */


  return lineSystem;
};

let createCadSketch = function (data, {layerId = null, layerName = null, autoSave = true}) {
  let structure = StructureCollection.getInstance().getStructureById(data.structureId);
  let storeyCollection = structure.getStoreyData();
  let storey = storeyCollection.getStoreyByValue(data.storey);
  let layerData = storey.layerData;

  let fileName = cadImport.createCADLayer(storeyCollection, data.storey,
      {layerId: layerId, layerName: layerName, autoSave: autoSave, structure_id: data.structureId});
  data.layerName = fileName;

  // let levelLow = (parseInt(data.storey) - 1).toString();
  // let levelHigh = (parseInt(levelLow) + 1).toString();

  let level;
  level = structure.getLevel("0", "1");
  if (!level) structure.addLevel("0", "1");
  let levelNum = "01";

  let layer = layerData.getLayerByName(fileName, data.storey);
  data.layerId = layer.id;
  data.layerNum = levelNum;
  data.storeyId = storey.id;
  data.levelId = level.flyweight.uniqueId;
  data.levelNum = levelNum;

  const edgeArrayV3 = edgeArrayToBabylonVector(data.edgeArray);
  const pointArrayV3 = data.pointArray ? pointArrayToV3(data.pointArray) : null;

  const shiftBy = shiftEdgeArrayXY(data.edgeArray);
  const cadSystem = drawCADLineSystem(edgeArrayV3, pointArrayV3);
  cadSystem.layerName = data.layerName;
  cadSystem.storey = store.activeLayer.storey;
  if(data.position){
    cadSystem.position = BABYLON.Vector3.FromArray(data.position)
  }
  else{
    cadSystem.position = new BABYLON.Vector3(-shiftBy[0], 0, shiftBy[1]);
  }
  if(data.rotationQuaternion){
    cadSystem.rotationQuaternion = new BABYLON.Quaternion(
      data.rotationQuaternion[0],
      data.rotationQuaternion[1],
      data.rotationQuaternion[2],
      data.rotationQuaternion[3]);
  }
  if(data.uniqueId){
    meshUniqueIdMapper.update(cadSystem, data.uniqueId);
  }

  let options = {};
  options.autoSave = false;
  cadImport.assignLayer(data.edgeArray, fileName, data.storey, cadSystem, shiftBy, cadSystem.position.asArray(), options);
  data.sketchesPosition = layer.sketches[0].position;

  snapEngine.cadSnaps.update(cadSystem);

  return layer;
};

const deleteCadSketch = function (structureId, storeyValue, layerName) {
  let structureCollection = StructureCollection.getInstance();

  let structure = structureCollection.getStructures()[structureId];
  let storey = structure.getStoreyData().getStoreyByValue(storeyValue);
  let layer = storey.layerData.getLayerByName(layerName, storeyValue);
  let sketches = layer.sketches;

  if(layer.mesh)
  {
    removeSelectionBox(layer.mesh);
    layer.mesh.dispose();
    snapEngine.cadSnaps.remove(layer.mesh);
  }

  sketches.length = 0;
  storey.layerData.deleteLayer(layer.id);
  reduxStore.dispatch(deleteLayer({ layerId: layer.id, storeyValue: storeyValue }));
  setActiveLayerAndRecord(storey.layerData.getLayerByName('wall', storey.value));
  // storeyView.deleteStoreyLayerUIData(storey, layer.id);
};

const getSketchesFromLayer = (layer, storeyValue) => {
  let structure = StructureCollection.getInstance().getStructures()[layer.structure_id];
  let storey = structure.getStoreyData().getStoreyByValue(storeyValue);
  let _layer = storey.layerData.getLayerByName(layer.name, storeyValue);
  return _layer.sketches;
};

const deleteCadLayer = (layer, storeyValue) => {

  let _executeEvent = function () {
      let sketch = getSketchesFromLayer(layer, storeyValue)[0];
      let _cadMesh = sketch.mesh;

      let data = {
          structureId: layer.structure_id,
          storey: storeyValue,
          edgeArray: sketch.edgeArray,
          position: sketch.position,
          layerId: layer.id,
          layerName: layer.name,
          uniqueId: _cadMesh?.uniqueId,
          rotationQuaternion: _cadMesh.rotationQuaternion?.asArray()
      };

      let commandLogic = { execute: _deleteCadSketch, unexecute: _reCreateCadSketch };

      let cmd = new Command("delete cad layer", data, commandLogic, _getSaveData);
      CommandManager.execute(cmd, true);
  };

  let _getSaveData = function () {
      let saveData = AutoSave.getSaveDataPrototype();

      saveData.commandId = this.id;
      saveData.data.saveType = "cadSketchDeletion";

      saveData.data.identifier = {
          structure_id: this.data.structureId,
          storey: this.data.storey,
          layerName: this.data.layerName,
          layerId: this.data.layerId,
          floorkey: store.floorkey
      };

      saveData.data.beforeOperationData = {
          edgeArray: this.data.edgeArray,
          position: this.data.position,
          structureId: this.data.structureId,
          storey: this.data.storey,
          layerName: this.data.layerName,
          rotationQuarternion: this.data.rotationQuarternion
      };
      saveData.data.afterOperationData = {};

      return saveData;
  };

  let _reCreateCadSketch = function() {
      createCadSketch(this.data, { layerId: this.data.layerId, layerName: this.data.layerName });

      let structureCollection = StructureCollection.getInstance();
      let structure = structureCollection.getStructures()[this.data.structureId];
      let storey = structure.getStoreyData().getStoreyByValue(this.data.storey);
      let layer = storey.layerData.getLayerByName(this.data.layerName, this.data.storey);
      let sketches = layer.sketches;

      layer.mesh.position = new BABYLON.Vector3(...this.data.position);
      sketches[0].position = layer.mesh.position.asArray();

      // let layers = storey.layerData.getAllLayers();
      // Object.keys(layers).forEach(function (key) {
      //     if (store.$scope.layerUIToggles[layers[key].id]) {
      //         layers[key].hidden = true;
      //         layers[key].hide();
      //     } else {
      //         layers[key].hidden = false;
      //         layers[key].show();
      //     }
      // });
  };

  let _deleteCadSketch = function() {
      deleteCadSketch(this.data.structureId, this.data.storey, this.data.layerName);
  };

  _executeEvent();
};

const drawEdgeArrayAsSketch = (edgeArray, pointArray) => {
  let layer = null;
  let cadPosition = [0, 0, 0];

  let structures = StructureCollection.getInstance();
  let str = structures.getStructureById(store.activeLayer.structure_id);
  let storeyCollection = str.getStoreyData();
  let storeyNum = store.storeyNumForImport;
  let payload = {
    items: [],
  };

  if (storeyNum > 0) {
    let posLength = storeyCollection.getPositiveStoreysLength();
    if (storeyNum > posLength - 1) {
      for (let i = posLength; i <= storeyNum; i++) {
        const storey = storeyCollection.addStorey(
          store.activeLayer.structure_id,
          i
        );
        if (!storey.exists) {
          payload.items.push({
            id: storey.id,
            value: storey.value,
            name: storey.name,
            height: DisplayOperation.convertToDefaultDimension(storey.height),
            hidden: storey.hidden,
            layers: [],
          });
          delete storey.exists;
        }
      }
    }
  } else {
    let negLength = storeyCollection.getNegativeStoreysLength();
    if (Math.abs(storeyNum) > negLength) {
      for (let i = negLength + 1; i <= Math.abs(storeyNum); i++) {
        const storey = storeyCollection.addStorey(
          store.activeLayer.structure_id,
          -i
        );
        if (!storey.exists) {
          payload.items.push({
            id: storey.id,
            value: storey.value,
            name: storey.name,
            height: DisplayOperation.convertToDefaultDimension(storey.height),
            hidden: storey.hidden,
            layers: [],
          });
          delete storey.exists;
        }
      }
    }
  }
  reduxStore.dispatch(appendStorey(payload));

  let cadSketchCommandName = "Draw Cad Sketch";

  let getCommandLogic = function () {
    return {
      execute: _createCadSketch,
      unexecute: _deleteCadSketch,
    };
  };

  let getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = this.id;
    saveData.data.saveType = "addCadSketch";

    saveData.data.identifier = {
      structure_id: this.data.structureId,
      storey: this.data.storey,
      storeyId: this.data.storeyId,
      layerName: this.data.layerName,
      layerId: this.data.layerId,
      levelNum: this.data.levelNum,
      levelId: this.data.levelId,
      floorkey: store.floorkey,
    };

    let dataAfter = {
      edgeArray: this.data.edgeArray,
      pointArray: this.data.pointArray,
      position: this.data.sketchesPosition,
      structureId: this.data.structureId,
      storey: this.data.storey,
      layerName: this.data.layerName,
    };

    saveData.data.afterOperationData = dataAfter;
    return saveData;
  };

  let _executeEvent = function () {
    let data = {
      edgeArray: edgeArray,
      pointArray,
      position: null,
      structureId: store.activeLayer.structure_id,
      storey: storeyNum,
    };

    let cmd = new Command(
      cadSketchCommandName,
      data,
      getCommandLogic(),
      getSaveData
    );
    CommandManager.execute(cmd, true);
  };

  let _createCadSketch = function () {
    layer = createCadSketch(this.data, { layerId: this.data.layerId });
  };

  let _deleteCadSketch = function () {
    let data = this.data;
    deleteCadSketch(data.structureId, data.storey, data.layerName);
  };

  _executeEvent();

  return layer;
}

let _handleCollaborationForCAD = function (){

  let _executeFromSaveData = saveData => {
      const identifier = saveData.identifier;
      const afterOperationData = saveData.afterOperationData;
      const beforeOperationData = saveData.beforeOperationData;
      const chosenData = afterOperationData;

      const data = {};
      data.structureId = chosenData.structureId;
      data.storey = chosenData.storey;
      data.layerName = chosenData.layerName;
      data.edgeArray = chosenData.edgeArray;
      data.position = chosenData.position;

      createCadSketch(data, {autoSave: false, layerName: data.layerName});
  };

  let _unexecuteFromSaveData = saveData => {
      const identifier = saveData.identifier;
      const afterOperationData = saveData.afterOperationData;
      const beforeOperationData = saveData.beforeOperationData;
      const chosenData = beforeOperationData;

      const data = {};
      data.structureId = chosenData.structureId;
      data.storey = chosenData.storey;
      data.layerName = chosenData.layerName;

      deleteCadSketch(data.structureId, data.storey, data.layerName);
  };

  return {
      executeFromSaveData: _executeFromSaveData,
      unExecuteFromSaveData: _unexecuteFromSaveData,
  };
};

let _handleCollaborationForDeleteCAD = function (){

    let _executeFromSaveData = saveData => {
      const beforeOperationData = saveData.beforeOperationData;

      const data = {};
      data.structureId = beforeOperationData.structureId;
      data.storey = beforeOperationData.storey;
      data.layerName = beforeOperationData.layerName;

      deleteCadSketch(data.structureId, data.storey, data.layerName);
    };

    let _unexecuteFromSaveData = saveData => {
      const beforeOperationData = saveData.beforeOperationData;

      const data = {};
      data.structureId = beforeOperationData.structureId;
      data.storey = beforeOperationData.storey;
      data.layerName = beforeOperationData.layerName;
      data.edgeArray = beforeOperationData.edgeArray;
      data.position = beforeOperationData.position;

      createCadSketch(data, {autoSave: false, layerName: data.layerName});
    };
    return {
      executeFromSaveData: _executeFromSaveData,
      unExecuteFromSaveData: _unexecuteFromSaveData,
    };
};

function _moveAllCADSketches(data) {
  if(!data){
    data = this.data;
  }
  let structureCollection = StructureCollection.getInstance();
  let structure = structureCollection.getStructures()[data.structureId];
  let storey = structure.getStoreyData().getStoreyByValue(data.storey);
  let layer = storey.layerData.getLayerByName(data.layerName, data.storey);
  let sketches = layer.sketches;

  layer.mesh.position = new BABYLON.Vector3(...data.curPos);
  sketches[0].position = layer.mesh.position.asArray();

  snapEngine.cadSnaps.update(layer.mesh);
}

function _unMoveAllCADSketches(data) {
  if(!data){
    data = this.data;
  }
  let structureCollection = StructureCollection.getInstance();
  let structure = structureCollection.getStructures()[data.structureId];
  let storey = structure.getStoreyData().getStoreyByValue(data.storey);
  let layer = storey.layerData.getLayerByName(data.layerName, data.storey);
  let sketches = layer.sketches;

  layer.mesh.position = new BABYLON.Vector3(...data.iniPos);
  sketches[0].position = layer.mesh.position.asArray();

  snapEngine.cadSnaps.update(layer.mesh);
}

function _handleCollaborationForMoveCAD(){

    let _executeFromSaveData = function (saveData){
        const identifier = saveData.identifier;
        const afterOperationData = saveData.afterOperationData;
        const beforeOperationData = saveData.beforeOperationData;
        const chosenData = afterOperationData;

        let data = {
            structureId: identifier.structure_id,
            storey: identifier.storey,
            layerName: identifier.layerName,
            curPos: chosenData.cadPosition
        };

        _moveAllCADSketches(data);
    };

    let _unExecuteFromSaveData = function (saveData){
        const identifier = saveData.identifier;
        const afterOperationData = saveData.afterOperationData;
        const beforeOperationData = saveData.beforeOperationData;
        const chosenData = afterOperationData;

        let data = {
            structureId: identifier.structure_id,
            storey: identifier.storey,
            layerName: identifier.layerName,
            iniPos: beforeOperationData.cadPosition
        };

        _unMoveAllCADSketches(data);
    };

    return {
        executeFromSaveData: _executeFromSaveData,
        unExecuteFromSaveData: _unExecuteFromSaveData
    };
}

function undoRedoForMoveCAD(data) {
  let cadSketchCommandName = "Move CAD";

  let getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = this.id;
    saveData.data.saveType = "moveCAD";

    saveData.data.identifier = {
      structure_id: this.data.structureId,
      storey: this.data.storey,
      layerName: this.data.layerName,
      layerId: this.data.layerId,
      floorkey: store.floorkey,
    };

    let dataBefore = {
      movementAmount: this.data.movementAmount.negate(),
      layerName: this.data.layerName,
      cadPosition: this.data.initialPosition.asArray(),
    };

    let dataAfter = {
      movementAmount: this.data.movementAmount,
      layerName: this.data.layerName,
      cadPosition: this.data.currentPosition.asArray(),
    };

    saveData.data.beforeOperationData = dataBefore;
    saveData.data.afterOperationData = dataAfter;
    return saveData;
  };

  let getCommandLogic = function () {
    return {
      execute: _moveAllCADSketches,
      unexecute: _unMoveAllCADSketches,
    };
  };

  let movementAmount = data.currentPosition.subtract(data.initialPosition);
  data.movementAmount = movementAmount;
  data.curPos = data.currentPosition.asArray();
  data.iniPos = data.initialPosition.asArray();
  data.structureId = store.activeLayer.structure_id;

  let _executeEvent = function () {
    let cmd = new Command(
      cadSketchCommandName,
      data,
      getCommandLogic(),
      getSaveData
    );
    CommandManager.execute(cmd, false);
  };
  _executeEvent();
}

let getAllSketchMeshesFromCADLayer = (layerName, structureId, storey) => {
  let structureCollection = StructureCollection.getInstance();
  let structure = structureCollection.getStructures()[structureId];
  let storeyData = structure.getStoreyData().getStoreyByValue(storey);
  let layer = storeyData.layerData.getLayerByName(layerName, storey);
  let sketches = layer.sketches;

  let meshes = [layer.mesh];
  // for (let i = 0; i < sketches.length; i++) {
  //     if (_.isArray(sketches[i].mesh)) {
  //         meshes.push(...sketches[i].mesh);
  //     } else {
  //         meshes.push(sketches[i].mesh);
  //     }
  // }
  return meshes;
};

const fetchSplitWalls = (contours) => {
  const options = { contours: contours };
  return new Promise((resolve, reject) => {
    fetch(forgeConfig.wallSplitterURL, {
      method: "post",
      body: JSON.stringify(options),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(new Error(error));
      });
  });
};

const setAllowedLayersCAD = (cadParser, layerArray) => {
  return new Promise((resolve, reject) => {
    if (cadParser.setAllowedLayersSubstring(layerArray)) resolve(true);
    reject(new Error("Could not set allowed layers for the given cadJSON"));
  });
};

/**
 * Returns the min X and max Y of the edgeArray.
 * @param edgeArray
 * @returns {[number, number]}
 */
const shiftEdgeArrayXY = (edgeArray) => {
  let minX = Infinity;
  let minY = -Infinity;
  for (let i = 0; i < edgeArray.length; i++) {
    const vertex1 = edgeArray[i][0];
    const vertex2 = edgeArray[i][1];

    if (vertex1[0] < minX) {
      minX = vertex1[0];
    }
    if (vertex1[1] > minY) {
      minY = vertex1[1];
    }
    if (vertex2[0] < minX) {
      minX = vertex2[0];
    }
    if (vertex2[1] > minY) {
      minY = vertex2[1];
    }
  }
  if (minX !== Infinity && minY !== -Infinity) {
    return [minX, minY];
  }
};

function handleShowOrHideForCAD(storeyOfInterest){

  if(!storeyOfInterest){
    return;
  }

  let _allCADLayersInStorey = storeyOfInterest.layerData.getAllLayersByType("cad", storeyOfInterest.value);
  if(!_.isEmpty(_allCADLayersInStorey)){
    for( let i = 0; i < _allCADLayersInStorey.length; i++ ){
      let _eachLayer = _allCADLayersInStorey[i];
      if(_eachLayer && _eachLayer.mesh){
        _eachLayer.mesh.isVisible = !(_eachLayer.hidden || !store.$scope.isTwoDimension);
      }
    }
  }

}

function handleCollaborationForShowOrHideLayer (){

  function executeFromSaveData( saveData ){
    let data = saveData.identifier;
    let structure = StructureCollection.getInstance().getStructureById(data.structure_id);
    let storeyCollection = structure.getStoreyData();
    let storey = storeyCollection.getStoreyByValue(data.storey);
    let layerData = storey.layerData;
    let _layer = null;

    if(data.layerId){

      _layer = layerData.getLayerBylayerId(data.layerId);
      if( _layer && _layer.mesh ){
        _layer.mesh.isVisible = !_layer.mesh.isVisible;
      }
    }

  }
  // function unExecuteFromSaveData( saveData ){
  //
  // }

  return {
    executeFromSaveData,
    unExecuteFromSaveData: executeFromSaveData
  }
}

export {
  convertPolygon3DArrayTo2D,
  convertPolygon2DArrayTo3D,
  fetchGraphCycles,
  fetchSplitWallsAjax,
  drawCADContours,
  getPointInBetweenByPercentageFactor,
  getXAxis,
  shiftCentreOfEdgeArray,
  edgeArrayToBabylonVector,
  pointArrayToV3,
  drawCADLineSystem,
  drawEdgeArrayAsSketch,
  createCadSketch,
  deleteCadSketch,
  deleteCadLayer,
  _moveAllCADSketches,
  _unMoveAllCADSketches,
  undoRedoForMoveCAD,
  getAllSketchMeshesFromCADLayer,
  fetchSplitWalls,
  setAllowedLayersCAD,
  shiftEdgeArrayXY,
  _handleCollaborationForCAD,
  _handleCollaborationForMoveCAD,
  _handleCollaborationForDeleteCAD,
  handleShowOrHideForCAD,
  handleCollaborationForShowOrHideLayer
};
