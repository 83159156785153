import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ActionDropdown from '../../../../components/ActionDropdown';
import LibraryContextMenu from '../../../../components/ContextMenu/LibraryContextMenu';
import LibraryInput from '../../../../components/LibraryInput';
import { deleteTeamObject, duplicateTeamObject, getTeamObjects, updateTeamObjectProperty } from '../../../../services/team.service';
import { showToast, TOAST_TYPES } from '../../../../snaptrude/modules/extrafunc';
import { sort } from './classificationOperations';
import { CONTEXT_MENU_STATE_DEFAULT, DIRECTION, FURNITURE_PROPERTIES, FURNITURE_TYPE_OPTIONS, MATERIAL_TYPE_OPTIONS } from './constants';
import { Wrapper as GenericWrapper } from './Styled';
import addIcon from '../../../../assets/icons/addButton.svg'
import { formatMultipleSubType } from '../helper';

const Wrapper = styled(GenericWrapper)`
.furniture-name{
    min-width: 212.5px;
    text-align: left;
}
.family-name{
    min-width: 212.5px;
    text-align: left;
    text-transform: capitalize;
}
.cost{
    width: 100px;
}
.room{
    font-size: 14px;
}
`

function FurnitureRow({ furniture, hasEditPermission, currentElementId, index, handlePropertyUpdate, handleContextMenu }) {
    return (
        <tr key={furniture.id} onContextMenu={(evt) => { handleContextMenu(evt, furniture) }}>
            <td>
                <img style={{ width: "inherit", borderRadius: "5px", height: "50px" }} src={furniture.url} alt="furniture" />
            </td>
            <td className='furniture-name'>
                <LibraryInput data-customcontextmenu="allow" unEditable={hasEditPermission ? furniture.id !== currentElementId : true} textAlign='left' placeholder={"Furniture Name"} type={"text"} value={furniture.displayName} handleSubmit={(newValue) => { handlePropertyUpdate("custom_name", newValue, furniture) }} />
            </td>
            <td className='furniture-family'>
                <LibraryInput unEditable={!hasEditPermission} textAlign='left' placeholder={"Furniture Family"} type={"text"} value={furniture.familyName} handleSubmit={(newValue) => { handlePropertyUpdate("family_name", newValue, furniture) }} />
            </td>
            <td className='room'>
                <div className='pills-wrapper'>
                    <div class='all-pills'>
                        {
                            furniture.subType.split(";").map(furnitureType => {
                                return <span key={furnitureType} className='pill'>{_.startCase(furnitureType)} <span style={{ cursor: "pointer" }} onClick={() => { handlePropertyUpdate("obj_subtype", "-" + furnitureType.toLowerCase(), furniture) }}>&#10006;</span></span>
                            })
                        }
                    </div>
                    <ActionDropdown
                        actions={
                            FURNITURE_TYPE_OPTIONS.map(option => {
                                return { title: option, handleClick: () => { handlePropertyUpdate("obj_subtype", option.toLowerCase(), furniture) } }
                            })
                        }
                        dropdownAlign="right"
                        unEditable={!hasEditPermission}
                        icon={addIcon}
                        iconStyles={{ width: '1.15em' }}
                    />

                </div>
            </td>
            <td className='cost'>
                <LibraryInput unEditable={!hasEditPermission} placeholder={"Cost"} type={"number"} value={furniture.cost} handleSubmit={(newValue) => { handlePropertyUpdate("cost", newValue, furniture) }} />
            </td>
            <td className='vendor'>
                <LibraryInput unEditable={!hasEditPermission} placeholder={"Vendor"} type={"text"} value={furniture.vendor} handleSubmit={(newValue) => { handlePropertyUpdate("vendor", newValue, furniture) }} />
            </td>
            <td className='description'>
                <LibraryInput unEditable={!hasEditPermission} placeholder={"Description"} type={"text"} value={furniture.description} handleSubmit={(newValue) => { handlePropertyUpdate("description", newValue, furniture) }} />
            </td>
        </tr>
    )
}

function Furnitures({ selectedTeam, teamlist, searchResult, isSearched }) {
    const scrollContainerRef = useRef(null);
    const [lastItemIndex, setLastItemIndex] = useState(20);
    const [currentElementId, setCurrentElementId] = useState(null);
    const [contextMenu, setContextMenu] = useState(CONTEXT_MENU_STATE_DEFAULT);
    const [orderBy, setOrderBy] = useState({ field: '', direction: DIRECTION.NONE });

    const handleDelete = async (teamId, elementId) => {
        const furnitureTableEle = document.getElementById("furniture-table");
        if (furnitureTableEle) furnitureTableEle.classList.add("disabled");
        setContextMenu(CONTEXT_MENU_STATE_DEFAULT);
        const resp = await deleteTeamObject(teamId, elementId, "furnitures");
        if (resp.status !== "success") {
            showToast(resp.message || "Could not delete furniture, try again", 3000, TOAST_TYPES.error);
        }
        if (furnitureTableEle) furnitureTableEle.classList.remove("disabled")
    }

    const handleDuplicate = async (teamId, elementId) => {
        const furnitureTableEle = document.getElementById("furniture-table");
        if (furnitureTableEle) furnitureTableEle.classList.add("disabled");
        setContextMenu(CONTEXT_MENU_STATE_DEFAULT);
        const resp = await duplicateTeamObject(teamId, elementId, "furnitures");
        if (resp.status !== "success") {
            showToast(resp.message || "Could not delete furniture, try again", 3000, TOAST_TYPES.error);
        }
        if (furnitureTableEle) furnitureTableEle.classList.remove("disabled")
    }
    const handleRename = (elementId, targetElement) => {
        setCurrentElementId(elementId);
        setTimeout(() => {
            if (targetElement) targetElement.focus();
        }, 1)
        setContextMenu(CONTEXT_MENU_STATE_DEFAULT);
    }
    const contextMenuOptions = [
        { title: "Rename", handleClick: () => { handleRename(contextMenu.elementId, contextMenu.targetElement) } },
        { title: "Duplicate", handleClick: () => { handleDuplicate(selectedTeam.id, contextMenu.elementId) } },
        { title: "Delete", handleClick: () => { handleDelete(selectedTeam.id, contextMenu.elementId) } },
    ]

    const handlePropertyUpdate = async (property, newValue, furniture) => {
        setCurrentElementId(null);
        const furnitureTableEle = document.getElementById("furniture-table");
        if (furnitureTableEle) furnitureTableEle.classList.add("disabled");
        if (property === "obj_subtype") newValue = formatMultipleSubType(newValue, furniture.subType);
        const resp = await updateTeamObjectProperty(selectedTeam.id, property, newValue, furniture, "furnitures");
        if (resp.status !== "success") {
            showToast(resp.message || "Could not update property, try again", 3000, TOAST_TYPES.error);
        }
        if (furnitureTableEle) furnitureTableEle.classList.remove("disabled")
    }

    const handleOrderBy = (field) => {
        if (field === 'image') return;
        const newOrderBy = {
            field: field,
            direction: DIRECTION.ASC
        }

        let prev = orderBy;
        if (prev.field === newOrderBy.field) {
            if (prev.direction === DIRECTION.ASC) {
                newOrderBy.direction = DIRECTION.DESC
            } else if (prev.direction === DIRECTION.DESC) {
                newOrderBy.field = '';
                newOrderBy.direction = DIRECTION.NONE
            }
        }

        scrollContainerRef.current.scrollTop = 0;
        setOrderBy(newOrderBy);
        setLastItemIndex(20)
    }

    const currentTeam = teamlist.find(team => team.id === selectedTeam.id);
    const permissions = currentTeam?.roleBasedPermissions[currentTeam.role];
    const hasEditPermission = permissions?.edit_library_properties;
    const furnitures = useMemo(() => {
        if (!currentTeam?.objects?.furnitures) return [];
        const sorted = sort(currentTeam.objects.furnitures, orderBy);
        return sorted;
    }, [currentTeam.objects?.furnitures, orderBy])

    useEffect(() => {
        const handleScrollBottom = () => {
            const { offsetHeight, scrollTop, scrollHeight } = scrollContainerRef.current;
            const length = currentTeam?.objects?.furnitures?.length || Number.MAX_VALUE;
            if (offsetHeight + scrollTop >= scrollHeight - 10) {
                setLastItemIndex(prev => Math.min(length, prev + 20))
            }
        }
        scrollContainerRef.current.addEventListener("scroll", handleScrollBottom);
        return () => {
            scrollContainerRef.current.removeEventListener("scroll", handleScrollBottom);
        }

    }, [scrollContainerRef, currentTeam?.objects?.furnitures?.length]);

    const handleContextMenu = (evt, material) => {
        evt.preventDefault();
        if (evt.target.dataset.customcontextmenu !== "allow") return;
        const { clientX, clientY } = evt;
        setContextMenu({ show: true, x: clientX, y: clientY, elementId: material.id, targetElement: evt.target })
    }
    return (
        <Wrapper ref={scrollContainerRef}>
            <table id="furniture-table">
                <thead>
                    <tr>
                        {
                            FURNITURE_PROPERTIES.map(property => {
                                return (
                                    <th
                                        key={property.key}
                                        onClick={() => { handleOrderBy(property.key) }}
                                        className={`${_.kebabCase(property.title)} ${orderBy.field === property.key ? orderBy.direction : ''}`}
                                    >
                                        {property.title}
                                    </th>

                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        isSearched && searchResult.objects.map((furniture, index) => {
                            return <FurnitureRow furniture={furniture} index={index} hasEditPermission={hasEditPermission} handlePropertyUpdate={handlePropertyUpdate} currentElementId={currentElementId} handleContextMenu={handleContextMenu} />
                        })
                    }
                    {
                        furnitures.slice(0, lastItemIndex).map((furniture, index) => {
                            return <FurnitureRow furniture={furniture} index={index} hasEditPermission={hasEditPermission} handlePropertyUpdate={handlePropertyUpdate} currentElementId={currentElementId} handleContextMenu={handleContextMenu} />

                        })
                    }
                </tbody>
            </table>
            {
                contextMenu.show &&
                <LibraryContextMenu x={contextMenu.x} y={contextMenu.y} handleClose={() => { setContextMenu({ show: false }) }}>
                    {
                        contextMenuOptions.map(menuItem => {
                            return <div key={menuItem.title} className="menu-item" onClick={menuItem.handleClick}>
                                {menuItem.title}
                            </div>
                        })
                    }
                </LibraryContextMenu>
            }
        </Wrapper>
    );
}

export default Furnitures;