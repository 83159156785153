import React, { useMemo } from "react";
import { Container, Block, Name, NameLink, Separator, EllipsedButtonWrapper, DropdownItemLink } from './styles'
import { useFileSystemUpdate } from "../../contexts/FileSystemContext";
import ActionDropdown, { ActionDropdownCustomizable } from "../ActionDropdown";
import { useCallback } from "react";
import Tooltip from "../Tooltip";

const getFolderRouteAddress = (folderId, workspaceRoute) => `${workspaceRoute}/folder/${folderId}`

const DropdownItem = ({ name, options, showSingle }) => {
    return (
        <Block
            style={{
                padding: "0 0.5em",
            }}
            showHoverEffect
        >
            <Name data-tip data-for={'tooltip_' + name} active>{name}</Name>
            <Tooltip id={'tooltip_' + name}>{name}</Tooltip>
            { options && <ActionDropdown actions={options} showSingle={showSingle} /> }
        </Block>
    )
}

const Item = ({ name, active, link, options, showSingle, minWidth }) => {
    if(active) return <DropdownItem name={name} options={options} showSingle={showSingle} />

    return (
        <Block style={{ minWidth }} >
            <NameLink to={link} data-tip data-for={'tooltip_' + name}>{name}</NameLink>
            <Separator>{">"}</Separator>
            <Tooltip id={'tooltip_' + name}>{name}</Tooltip>
        </Block>
    )
}

/**
 * FolderBreadcrumbs
 * @param {{
 *  path: String[],
 *  activePath: String
 * }} props
 * @returns 
 */
const FolderBreadcrumbs = ({
  path,
  teamId,
  rootRoute,
  workspaceRoute
}) => {
    const fileSystemUpdate = useFileSystemUpdate();

    const getActions = useCallback((folderId, teamId = null) => {
        if(!folderId) return

        return [
            {
                title: "Create folder inside",
                handleClick: () => {
                    fileSystemUpdate(teamId, folderId, "create")
                }
            },
            {
                title: "Rename",
                handleClick: () => {
                    fileSystemUpdate(teamId, folderId, "rename")
                }
            },
            {
                title: "Delete",
                handleClick: () => {
                    fileSystemUpdate(teamId, folderId, "delete")
                }
            }
        ]
    }, [fileSystemUpdate])

    const { root, ellipsed, rest } = useMemo(() => {
        let _root = null,
            _ellipsed = [],
            _rest = []

        if(path.length) {
            _root = path[0]
        }

        if(path.length > 4) {
            _ellipsed = path.slice(1, -2);
            _rest = path.slice(-2);
        } else if(path.length > 1){
            _rest = path.slice(1);
        }

        return { root: _root, ellipsed: _ellipsed, rest: _rest }
    }, [path, path.length])

    const ellipsedActions = useMemo(() => {
        return ellipsed.map(path => ({
            component: <>
                <DropdownItemLink
                    to={getFolderRouteAddress(path?.folderID, workspaceRoute)}
                    data-for={'tooltip_' + path?.name}
                    data-tip
                > {path?.name} </DropdownItemLink>
                { String(path?.name).length > 15 && <Tooltip id={'tooltip_' + path?.name}>{path?.name}</Tooltip> }
            </>
        }))
    }, [ellipsed, ellipsed.length])

    return (
        <Container>
            {
                root && <Item 
                    name={root?.name}
                    active={path.length == 1}
                    link={rootRoute}
                    minWidth="fit-content"
                    options={[
                        {
                            title: "Create new folder",
                            handleClick: () => {
                                fileSystemUpdate(teamId, null, "create")
                            }
                        },
                    ]}
                    showSingle
                />
            }
            {
                ellipsed.length ?
                <EllipsedButtonWrapper>
                    <ActionDropdownCustomizable
                        actions={ellipsedActions}
                        useComponent
                        triggerComponent={
                            <Block 
                                style={{
                                    padding: "0 1em",
                                    cursor: "pointer",
                                    height: '100%'
                                }}
                                showHoverEffect
                            >
                            ...
                            </Block>
                        }
                        style={{
                            boxSizing: 'content-box',
                            height: '100%',
                            padding: 0
                        }}
                    />
                    <Separator>{">"}</Separator>
                </EllipsedButtonWrapper>
                : <></>
            }
            {
                rest.length ? rest.map((item, idx) => (
                    <Item
                        key={`${idx}-${item.name}`}
                        name={item?.name}
                        active={idx == rest.length -1}
                        link={getFolderRouteAddress(item?.folderID, workspaceRoute)}
                        options={idx == rest.length -1 && getActions(item.folderID, teamId)}
                    />
                )) : <></>
            }
        </Container>
    );
};

export default FolderBreadcrumbs;
