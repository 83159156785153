import React, {useState} from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
background: ${props => props.disabled ? 'transparent' : '#F2F2F2'};
border-radius: 5px;
padding: 5px;
font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
letter-spacing: -0.333333px;
color: #2D2D2E;
border: 0px;
text-align: center;
width: 90%;
&.text-align-left{
    text-align: left;
}
&.text-align-right{
    text-align: right;
}

`

function LibraryInput({handleSubmit, value, placeholder, type="text", unEditable=false, textAlign = "center", ...props}){
    if(!value) value = "";
    const [_value, _setValue] = useState(value);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        if(handleSubmit) handleSubmit(_value);
    }
    const handleChange = (e) => {
        let newValue = e.target.value;
        // if(type === "number"){
        //     newValue = e.target.valueAsNumber;
        // }
        _setValue(newValue);
    }
    return (
        <form onSubmit={_handleSubmit} onBlur={_handleSubmit}>
            <StyledInput step="0.01" className={`text-align-${textAlign}`} disabled={unEditable} type={type} value={_value} onChange={handleChange} placeholder={placeholder} {...props} />
        </form>
    )
}

export default LibraryInput;