import { showToast } from "../extrafunc.js";
import { TOAST_TYPES } from "../extrafunc.js";
import { updateExportProgressBar } from "../../../containers/genericModal/export.js";

export const OUTPUT_FORMATS = Object.freeze({
    'dwg': 'dwg',
    'ifc': 'ifc',
    'rvt': 'rvt',
    'pdf': 'pdf'
});

export const rvtExportDownloadResult = (url) => {
    let dlAnchorElem = document.getElementById("downloadAnchorElem");
    dlAnchorElem.setAttribute("href", url);
    dlAnchorElem.click();
};

export const rvtExportOperationStatus = (data) => {
    updateExportProgressBar(data.value, data.message);

    if (data.value == 0) {
        let messageDuration = 3000;
        showToast('Failed to export', messageDuration, TOAST_TYPES.error);
        updateExportProgressBar(data.value, data.message, false);
    }
    if (data.value == 100) {
        let messageDuration = 3000;
        showToast('Successfully exported project', messageDuration, TOAST_TYPES.success);

        updateExportProgressBar(100, 'Downloading');
        setTimeout(() => { }, 5000);
        updateExportProgressBar(0, 'Exporting', false);
    }
};
