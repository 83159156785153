export const getFormData = (data) => {
    const formData = new FormData();
    for(let item in data) {
        formData.append(item, data[item]);
    }
    return formData;
}

export const getQueryParamsString = (data) => {
    const queryParams = new URLSearchParams();
    for(let item in data){
        queryParams.append(item, data[item]);
    }
    return queryParams.toString();
}