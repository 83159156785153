import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/constants";
import backgroundImage from "../../assets/images/right-image.png";
import { PARAGRAPH_TEXT } from "./constants";
import Logo from "../../components/Logo";
import Paragraph from "../../components/Paragraph";
import SignUp from "./Signup";
import Login from "./Login";
import SelectProfession from "./selectProfession";
import ForgotPassword from "./ForgotPassword";
import { colors } from "../../themes/constant";
// Commented import mixpanel from 'mixpanel-browser';

const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

const MainWrapper = styled.div`
  height: 100%;
  font-family: ${(props) => props.theme.font};
  background-color: ${(props) => props.theme.colors.fullWhite};
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: 40% 100%;
  background-position: right;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  width: 30%;
`;

const CardContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fullWhite};
  box-shadow: 0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey};
  border-radius: 0.313rem;
  // margin: 1rem;
  padding: 0.5rem 1.44rem 1rem 1.44rem;
  // flex: 1;
  // margin: 0.75rem 4.5rem 0.75rem 4.5rem;
  // max-width: 29rem;
  // position: relative;
  // left: 7%;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

class LoginSignupPage extends React.Component {
  

  getCardBodyWrtPath = () => {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case routes.signup:
        return <SignUp />;
      case routes.forgotPassword:
        return <ForgotPassword />;
      case routes.selectProfession:
        return <SelectProfession />;
      default:
        return <Login />;
    }
  };

  render() {
    return (
      <MainWrapper>
        <ContentContainer>
          <LeftContainer>
            <Logo />
            <Paragraph
              heading={PARAGRAPH_TEXT.heading}
              subHeading={PARAGRAPH_TEXT.subheading}
              body={PARAGRAPH_TEXT.body}
            />
            <div />
          </LeftContainer>
          <CardContainer>{this.getCardBodyWrtPath()}</CardContainer>
        </ContentContainer>
      </MainWrapper>
    );
  }
}

export default withRouter(LoginSignupPage);
