import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { StructureCollection } from "../../../modules/snaptrudeDS/structure.ds";
import { StoreyMutation } from "../../../modules/storeyEngine/storeyMutations";

const initialState = {
    comments: [],
    groupedComments: {
        unAssigned: {
          title: "",
          comments: []
        },
        resolved: {
          title: "Resolved",
          comments: []
        },
    }
};

const groupCommentsAccrodingToStorey = (comments) => {
    // GET HEIGHT OF ANY STOREY, TO ASSIGN STOREY BASED ON COMMENT POSITION.
    const storeys = StoreyMutation.getAllStoreys();
    console.log(storeys)
    let height = storeys["1"].height;

    const allComponents = StructureCollection.getInstance().getAllComponents();
    const componentToStorey = new Map();
    allComponents.forEach(component => {
      componentToStorey.set(component.id, component.mesh.storey);
    });
    const groupedCommentsNew = _.cloneDeep(initialState.groupedComments);
    let prevCommentId = null;
    comments.forEach((rawComment, index) => {
      if(rawComment.isDeleted) return;
      if(rawComment.id === prevCommentId) return;
      prevCommentId = rawComment.id;
      const comment = {
        ...rawComment,
        localIndex: index
      };
      let prevChildCommentId = null;
      comment.children = comment.children.filter(childComment => {
        if(comment.isDeleted) return false;
        if(childComment.id === prevChildCommentId) return false;
        prevChildCommentId = childComment.id;
        return true;
      });
      if(comment.isResolved){
        groupedCommentsNew.resolved.comments.push(comment);
      }else if(comment.scene && comment.scene.isAttachedToScene){
        const dsPropsId = comment.scene.component.dsPropsId;
        if(!dsPropsId){
          groupedCommentsNew.unAssigned.comments.push(comment);
          return;
        }else if(dsPropsId.startsWith("cadSketch") || dsPropsId.startsWith("FloorPlan") || dsPropsId.startsWith("pdf")){
          const storeyForLayer = parseInt(dsPropsId.split("::")[1])
          componentToStorey.set(dsPropsId, storeyForLayer);
        }
        let storey = componentToStorey.get(dsPropsId);
        if(!storey){
          if(height){
            const y = comment.scene.absolutePosition._y;
            const res = (y/height).toFixed(5);
            if(y>0) storey = Math.ceil(res);
            // else if(y==0) storey = 1;
            else storey = Math.floor(res); 
          }else{
            groupedCommentsNew.unAssigned.comments.push(comment)
            return;
          }
        }
        if(!groupedCommentsNew[storey]){
          let title = "Storey " + storey;
          if(storeys[storey] && storeys[storey].name){
            title = storeys[storey].name;
          }
          groupedCommentsNew[storey] = {title: title, comments: [], storey: storey};
        } 
        groupedCommentsNew[storey].comments.push(comment)
      }else{
        groupedCommentsNew.unAssigned.comments.push(comment)
      }
    })
    return groupedCommentsNew;
}

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        resetComments: state => initialState,
        initComments: (state, action) => {
            const { comments } = action.payload;
            state.comments = comments;
            const groupedComments = groupCommentsAccrodingToStorey(comments);
            state.groupedComments = groupedComments;
        },
        addComment: (state, action) => {
            const { comment } = action.payload;
            if(comment.parentComment) {
                state.comments = state.comments.map(existingComment => {
                    if(existingComment.id === comment.parentComment){
                        existingComment.children.push(comment);
                    }
                    return existingComment;
                })
            }else{
                state.comments.push(comment);
              }
              const groupedComments = groupCommentsAccrodingToStorey(state.comments);
              state.groupedComments = groupedComments;
        },
        removeComment: (state, action) => {
            const {commentID} = action.payload;
            let updateFlag = false;
            state.comments = state.comments.map(cmt =>{
                if(updateFlag) return cmt;
                if(cmt.id === commentID){
                    cmt.isDeleted = true;
                    updateFlag = true;
                }else{
                    cmt.children = cmt.children.map(childComment => {
                        if(childComment.id === commentID){
                            childComment.isDeleted = true;
                            updateFlag = true;
                        }
                        return childComment;
                    })
                }
                return cmt;
            });
            const groupedComments = groupCommentsAccrodingToStorey(state.comments);
            state.groupedComments = groupedComments;
        },

        editCommentText: (state, action) => {
            const {commentID, newtext} = action.payload;
            state.comments = state.comments.map((cmt) => {
                if(cmt.id === commentID){
                    cmt.comment = newtext;
                }else{
                    cmt.children = cmt.children.map(childComment => {
                        if(childComment.id === commentID){
                            childComment.comment = newtext;
                        }
                        return childComment;
                    })
                }
                return {...cmt}
            })
            const groupedComments = groupCommentsAccrodingToStorey(state.comments);
            state.groupedComments = groupedComments;
        },
        resolveComment: (state, action) => {
            const { commentID } = action.payload;
            const comment = state.comments.filter(cmt => cmt.id === commentID);
            if(comment && comment[0]){
                comment[0].isResolved = true;
            }
            const groupedComments = groupCommentsAccrodingToStorey(state.comments);
            state.groupedComments = groupedComments;
        },
        unresolveComment: (state, action) => {
            const { commentID } = action.payload;
            const comment = state.comments.filter(cmt => cmt.id === commentID);
            if(comment && comment[0]){
                comment[0].isResolved = false;
            }
            const groupedComments = groupCommentsAccrodingToStorey(state.comments);
            state.groupedComments = groupedComments;
        },
        incomingComment: (state, action) => {
          const { commentObject } = action.payload;
          if(commentObject.parentComment){
            const parentComment = state.comments.filter(cmt => cmt.id === commentObject.parentComment);
            if(parentComment && parentComment[0]){
              parentComment[0].children.push(commentObject);
            }
          } else {
            state.comments.push(commentObject);
          }  
          const groupedComments = groupCommentsAccrodingToStorey(state.comments);
          state.groupedComments = groupedComments;
        },
        likeComment: (state, action) => {

        },
        unlikeComment: (state, action) => {

        },
    }
});

export const { resetComments, initComments, addComment, removeComment, editCommentText, resolveComment, unresolveComment, incomingComment, likeComment, unlikeComment } = commentsSlice.actions;

export default commentsSlice.reducer;
