export const SHARE_MODAL = "SHARE MODAL";

// Cancel
export const CANCEL_HEADER_TEXT =
  "Cancelling your subscription will result in your entire team losing access to their files.";
export const CANCEL_CTA_TEXT = "Are you sure you want to cancel ?";
export const BILLING_BUTTON_TEXT = "Back to Billing";
export const CANCEL_SUBSCRIPTION_TEXT = "Cancel Subscription";

// Share
export const SHARE_EMAIL = "Enter email address to invite someone";
export const SEND_INVITE = "Send Invite";
export const RECENT_BOX_DROPDOWN_OPTIONS = [
  "Restricted: Only those added",
  "Anyone with the link",
  // "Restricted: Only your team",
];
export const RECENT_BOX_PERMISSION_OPTIONS = [
  "Can View",
  "Can Comment",
  "Can Edit",
];
export const EXPORT_MODAL = "EXPORT MODAL";
export const VIEWS_MODAL = "VIEWS MODAL";
export const SAVE_AS_MODAL = "SAVE AS MODAL";
export const SEDN_INVITE = "SEND INVITE";
export const IMPORT_WITH_DROPDOWN = "IMPORT WITH DROPDOWN";
export const IMPORT_FILE_OVERVIEW = "IMPORT FILE OVERVIEW";
export const IMPORT_SINGLE_FILE_OVERVIEW = "IMPORT SINGLE FILE OVERVIEW";
