import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { getFace } from "./faceFuncs.js";
import { isTwoDimension } from "./twoDimension.js";
import { getDynamicThreshold } from "./snapUtilities.js";
import { areThreeVectorsCollinear } from "../modules/extrafunc.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
import snapEngine from "../modules/snappingEngine/snapEngine";
// import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
var selectVertexScale = 100;
var wentNearThisEdge = [];
let axisSnapFactor = 120; //lower the value, higher the threshold, stronger the snap

/**
 *
 * @param pickInfo
 * @param threshold
 * @returns {*}
 * Returns a BABYLON.Vector3 of the nearest vertex found, else returns null.
 * In case you already have face vertices, you can pass them as the second parameter.
 * Otherwise, use the second parameter as null.
 */
function findNearestVertex(pickInfo, threshold = 60) {
  var face = getFace(pickInfo);
  if (!face) return null;

  let wmatrix = pickInfo.pickedMesh.getWorldMatrix();
  for (let i = 0; i < face[0].length; i++) {
    face[0][i] = BABYLON.Vector3.TransformCoordinates(face[0][i], wmatrix);
  }

  //Rounding not done - it is good because increases accuracy.

  //TO OR NOT TO - Remove duplicate elements from face[]

  //console.log('findNearestVertex face', face[0]);
  for (var i = 0; i < face[0].length; i++) {
    //TO DO - Edit thresholds
    var tempDist = BABYLON.Vector3.Distance(
      pickInfo.pickedPoint,
      store.scene.activeCamera.position
    );
    if (threshold) tempDist = tempDist / threshold;
    else tempDist = tempDist / selectVertexScale;

    if (store.$scope.isTwoDimension && tempDist > 1) tempDist = 1;
    // if (tempDist > 30)
    //     tempDist = tempDist/selectVertexScale;
    // else
    //     tempDist = 0.5;
    // console.log(tempDist);
    if (checkIfNear(pickInfo.pickedPoint, face[0][i], tempDist, false)) {
      return [face[0][i], face[2][i]];
    }
  }
  return null;
}

function snapOnGivenLine(pointToSnap, linePoint1, linePoint2) {
  let pointOnTheLine = null;
  let projectionOnLine = projectionOfPointOnLine(
    pointToSnap,
    linePoint1,
    linePoint2
  );

  // let threshold = getDynamicThreshold(pointToSnap);

  if (snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(projectionOnLine)) {
    pointOnTheLine = projectionOnLine;
  }

  return pointOnTheLine;
}

function getRefGroundPoint() {
  let pickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
    return mesh.name === "referenceGround";
  });

  return pickInfo.pickedPoint;
}

function getBackWallPoint() {
  let pickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
    return mesh.name === "backwall";
  });

  return pickInfo.pickedPoint;
}

function distanceBetweenPointAndLine(pointToBeChecked, edgePoint1, edgePoint2) {
  var vector1 = pointToBeChecked.subtract(edgePoint1);
  var vector2 = pointToBeChecked.subtract(edgePoint2);
  var crossVector = BABYLON.Vector3.Cross(vector1, vector2);
  var numerator = BABYLON.Vector3.Distance(crossVector, BABYLON.Vector3.Zero());
  var line = edgePoint2.subtract(edgePoint1);
  var denominator = BABYLON.Vector3.Distance(line, BABYLON.Vector3.Zero());
  return numerator / denominator;
}

function projectionOfPointOnLine(
  pointToBeChecked,
  edgePoint1,
  edgePoint2,
  twoDPoints = false
) {
  //console.log("pt", pointToBeChecked, "edgept1", edgePoint1, "edgept2", edgePoint2);
  var line = edgePoint2.subtract(edgePoint1);
  var vectorFromEdgePoint1ToPoint = pointToBeChecked.subtract(edgePoint1);
  var projectionOfLine, t;
  if (twoDPoints) {
    projectionOfLine = line.scale(
      1 / BABYLON.Vector2.Distance(line, BABYLON.Vector2.Zero())
    ); //check the distance
    t = BABYLON.Vector2.Dot(vectorFromEdgePoint1ToPoint, projectionOfLine);
  } else {
    projectionOfLine = line.scale(
      1 / BABYLON.Vector3.Distance(line, BABYLON.Vector3.Zero())
    ); //check the distance
    t = BABYLON.Vector3.Dot(vectorFromEdgePoint1ToPoint, projectionOfLine);
  }
  return edgePoint1.add(projectionOfLine.scale(t));
}

function projectionOfPointOnFace(
  point,
  faceCoordinates,
  plane,
  threshold = 60
) {
  if (!plane) {
    let threePoints;
    let length = faceCoordinates.length;

    while (length--) {
      threePoints = _.sampleSize(faceCoordinates, 3);
      // const resolveEngineUtils = new ResolveEngineUtils();
      if (!areThreeVectorsCollinear(...threePoints, 1e-2)){
        break;
      }
    }

    plane = BABYLON.Plane.FromPoints(...threePoints);
  }

  let distanceBetweenPointAndFace = plane.signedDistanceTo(point);
  if (Math.abs(distanceBetweenPointAndFace) > threshold) return null;

  let normal = plane.normal.normalize().negate(); //unit normal vector
  let correction = normal.scale(distanceBetweenPointAndFace);

  return point.add(correction);
}

function perpendicularLineToEdge(passingThroughPoint, edgePoint1, edgePoint2) {
  let slopeNum = edgePoint2.y - edgePoint1.y;
  let slopeDen = edgePoint2.x - edgePoint1.x;
  let slope = null;
  let perpSlope = null;
  let returnPoint = new BABYLON.Vector2.Zero();
  if (slopeDen == 0) {
    slope = "inf";
  } else {
    slope = slopeNum / slopeDen;
  }
  if (slope == "inf") {
    perpSlope = 0;
  } else if (slope == 0) {
    perpSlope = "inf";
  } else {
    perpSlope = -1 / slope;
  }

  if (perpSlope == 0) {
    returnPoint.x = passingThroughPoint.x + 1;
    returnPoint.y = passingThroughPoint.y;
    return [passingThroughPoint, returnPoint];
  } else if (perpSlope == "inf") {
    returnPoint.x = passingThroughPoint.x;
    returnPoint.y = passingThroughPoint.y + 1;
    return [passingThroughPoint, returnPoint];
  } else {
    returnPoint.x = 0;
    returnPoint.y =
      perpSlope * returnPoint.x -
      perpSlope * passingThroughPoint.x +
      passingThroughPoint.y;
    return [passingThroughPoint, returnPoint];
  }
  //return null;
}

/**
 *
 * @param pt1
 * @param pt2
 * @param pt3
 * @returns {number}
 * 0 -> pt1, pt2, pt3 are collinear
 * 1 -> pt1, pt2, pt3 are oriented clockwise
 * 2 -> pt1, pt2, pt3 are oriented counter clockwise
 *
 * This function is written specifically for getEnclosingVerticesInOrder().
 */
function getOrientation(pt1, pt2, pt3) {
  var val =
    (pt2.y - pt1.y) * (pt3.x - pt2.x) - (pt2.x - pt1.x) * (pt3.y - pt2.y);
  if (val == 0) return 0;
  return val > 0 ? 1 : 2;
}

/**
 *
 * @param pt1
 * @param pt2
 * @param threshold
 * @param twoOrdinates
 * @returns {boolean}
 *
 * Returns if the distance between the two given points is less than the threshold.
 * The last parameter differentiates whether the provided points are BABYLON.Vector3 form or BABYLON.Vector2 form
 */
function checkIfNear(pt1, pt2, threshold, twoOrdinates) {
  if (twoOrdinates) {
    return BABYLON.Vector2.Distance(pt1, pt2) < threshold ? true : false;
  }
  return BABYLON.Vector3.Distance(pt1, pt2) < threshold ? true : false;
}

/**
 * Returns unsigned angle
 *
 * @param vector1
 * @param vector2
 * @returns {number}
 */
function getAngleBetweenVectors(vector1, vector2, precise) {
  return (getAngleInRadians(vector1, vector2, precise) * 180) / Math.PI;
}

/**
 * Returns unsigned angle
 *
 * @param vector1
 * @param vector2
 * @param precise
 * @returns {number}
 */
function getAngleInRadians(vector1, vector2, precise = false) {
  let dotProduct = BABYLON.Vector3.Dot(vector1, vector2);
  let lengthOfVector1 = BABYLON.Vector3.Distance(
    vector1,
    BABYLON.Vector3.Zero()
  );
  let lengthOfVector2 = BABYLON.Vector3.Distance(
    vector2,
    BABYLON.Vector3.Zero()
  );
  let cos = dotProduct / (lengthOfVector1 * lengthOfVector2);

  // if (!precise) cos = _.round(cos, 3);

  // cos = _.clamp(cos, -1, 1);
  return Math.acos(cos);
}

function isFloatEqual(float1, float2, threshold = 1e-5) {
  return Math.abs(float1 - float2) < threshold;
}

var snapVertData = function (diff) {
  // var factor = Math.round(global_scale_factor * unit_scale);
  // var factor = 10;
  // diff.x = Math.round(diff.x*factor)/factor;
  // diff.y = Math.round(diff.y*factor)/factor;
  // diff.z = Math.round(diff.z*factor)/factor;

  return diff;
};

/**
 * Get the nearest point on line given a point.
 * Limited to 2D use cases
 *
 * @param refPoint
 * @param linePoint1
 * @param linePoint2
 *
 * @return {BABYLON.Vector3}
 */
function nearestPointOnLine(refPoint, linePoint1, linePoint2) {
  let nearestPoint;
  let lineLength = BABYLON.Vector3.Distance(linePoint1, linePoint2);

  if (lineLength === 0) {
    throw "The points on input line must not be identical";
  }

  let coefficient =
    ((refPoint.x - linePoint1.x) * (linePoint2.x - linePoint1.x) +
      (refPoint.z - linePoint1.z) * (linePoint2.z - linePoint1.z)) /
    Math.pow(lineLength, 2);

  // restrict to line boundary
  if (coefficient > 1) {
    coefficient = 1;
  } else if (coefficient < 0) {
    coefficient = 0;
  }

  nearestPoint = new BABYLON.Vector3(
    linePoint1.x + coefficient * (linePoint2.x - linePoint1.x),
    linePoint1.y,
    linePoint1.z + coefficient * (linePoint2.z - linePoint1.z)
  );

  return nearestPoint;
}
export {
  selectVertexScale,
  wentNearThisEdge,
  axisSnapFactor,
  findNearestVertex,
  snapOnGivenLine,
  getRefGroundPoint,
  getBackWallPoint,
  distanceBetweenPointAndLine,
  projectionOfPointOnLine,
  projectionOfPointOnFace,
  perpendicularLineToEdge,
  getOrientation,
  checkIfNear,
  getAngleBetweenVectors,
  getAngleInRadians,
  isFloatEqual,
  snapVertData,
  nearestPointOnLine,
};
