import jQuery from "jquery";
import { store } from "../../modules/utilityFunctions/Store.js";
import { orientation } from "../orientation.js";
import { draw_interior_drag } from "./drawInteriorsFplan.js";
import { projectionOfPointOnLine2D } from "../twoD/twoServices.js";
function snapObjectEdgeExt(pol, pt, threshX, threshY, orientation) {
  var o = store.canvas.getObjects();
  let nearThreshX = 50;
  let nearThreshY = 50;
  //let prevArea = getAreaOfPolygon(pol.points);
  o.forEach(function (obj) {
    let pt1, pt2;
    if (obj.get("id") == "rooms" && obj != pol) {
      //if ((obj.get('id') == 'rooms') && (obj.get('type') == 'polygon') && (obj != pol)){
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pt.forEach(function (pt11) {
        pt1 = jQuery.extend({}, pt11);

        if (pt1.x > meanXX) {
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;

        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = jQuery.extend({}, pt22);

          if (pt2.x > meanX) {
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          // if ((pt1.x != pt2.x) && (pt1.y != pt2.y)){
          if (
            Math.abs(pt1.x - pt2.x) < snap_threshX &&
            Math.abs(pt1.y - pt2.y) < nearThreshY
          ) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (
            Math.abs(pt1.y - pt2.y) < snap_threshY &&
            Math.abs(pt1.x - pt2.x) < nearThreshX
          ) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
          // }
        });
      });
      if (orientation == "x") {
        if (X1 != undefined) {
          pt[0].x -= X1.x - X2.x;
          pt[1].x -= X1.x - X2.x;
          pol.setCoords();
        }
      }
      if (orientation == "y") {
        if (Y1 != undefined) {
          pt[0].y -= Y1.y - Y2.y;
          pt[1].y -= Y1.y - Y2.y;
          pol.setCoords();
        }
      }
    }
  });
  return pt;
}

function snapNewObject(pol, threshX, threshY) {
  var n = pol.points.length;
  for (var i = 0; i < pol.points.length; i++) {
    var centSlopeX = pol.points[(i + 1) % n].x - pol.points[i].x;
    var centSlopeY = pol.points[(i + 1) % n].y - pol.points[i].y;

    if (centSlopeX != 0) {
      store.angle = (Math.atan(centSlopeY / centSlopeX) * 180) / Math.PI;
    } else {
      store.angle = 90;
    }
    if (Math.abs(store.angle) > 85) {
      orientation = "x";
    } else if (Math.abs(store.angle) < 5) {
      orientation = "y";
    } else if (
      Math.abs(store.angle) > 5 &&
      Math.abs(store.angle) < 85 &&
      store.angle < 0
    ) {
      orientation = "a";
    } else if (
      Math.abs(store.angle) > 5 &&
      Math.abs(store.angle) < 85 &&
      store.angle > 0
    ) {
      orientation = "a";
    }
    snapObjectEdgeExt(
      pol,
      [pol.points[(i + 1) % n], pol.points[i % n]],
      threshX,
      threshY,
      orientation
    );
  }
  var n = pol.fill.slice(5, pol.fill.length - 2).split(",");
  var n2 = 1.37;
  for (let p = 0; n2 % 1 != 0; p++) {
    n2 = (n[0] - 5 + 255 * p) / 53;
  }
  //let selfSnapPol = snapPartitionsInSamePolygon(pol.points); //THIS FUNCTION HAS NOT BEEN CALLED EVEN THOUGH IT CREATES PARTITION IS BECAUSE IT IS DISTORTING SOME SHAPES IN SOME COMPLEX SHAPES
  let selfSnapPol = pol.points;
  var pol2 = draw_interior_drag(selfSnapPol, pol, n2); //THIS FUNCTION HAS NO SIGNIFICANCE TAKING THE POL OBJECT
  store.canvas.remove(pol);
  return pol2;
}

function snapObjectEdge(pol, pt, threshX, threshY, orientation) {
  var o = store.canvas.getObjects();
  let pt1, pt2;
  o.forEach(function (obj) {
    if (obj.get("id") == "rooms" && obj.get("type") == "polygon") {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pt.forEach(function (pt11) {
        pt1 = jQuery.extend({}, pt11);

        if (pt1.x > meanXX) {
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;

        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = jQuery.extend({}, pt22);

          if (pt2.x > meanX) {
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (orientation == "x") {
        if (X1 != undefined) {
          pt[0].x -= X1.x - X2.x;
          pt[1].x -= X1.x - X2.x;
          pol.setCoords();
        }
      }
      if (orientation == "y") {
        if (Y1 != undefined) {
          pt[0].y -= Y1.y - Y2.y;
          pt[1].y -= Y1.y - Y2.y;
          pol.setCoords();
        }
      }
    }
  });
  return pt;
}

function snapObjectVert(pol, pt, threshX, threshY) {
  var o = store.canvas.getObjects();
  let pt1, pt2;
  o.forEach(function (obj) {
    if (obj.get("id") == "rooms" && obj.get("type") == "polygon") {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pt.forEach(function (pt11) {
        pt1 = jQuery.extend({}, pt11);

        if (pt1.x > meanXX) {
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;

        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = jQuery.extend({}, pt22);

          if (pt2.x > meanX) {
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (pt1.x != pt2.x && pt1.y != pt2.y) {
            if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
              X1 = pt1;
              X2 = pt2;
              snap_threshX = Math.abs(pt1.x - pt2.x);
            }
            if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
              Y1 = pt1;
              Y2 = pt2;
              snap_threshY = Math.abs(pt1.y - pt2.y);
            }
          }
        });
      });
      if (X1 != undefined) {
        pt[0].x -= X1.x - X2.x;
        pol.setCoords();
      }
      if (Y1 != undefined) {
        pt[0].y -= Y1.y - Y2.y;
        pol.setCoords();
      }
    }
  });
  return pt;
}

function getSlopeOfEdge(edge) {
  if (edge[1].x - edge[0].x == 0) {
    return "inf";
  }
  return (edge[1].y - edge[0].y) / (edge[1].x - edge[0].x);
}

function isParallel(edge1, edge2) {
  let slope1 = getSlopeOfEdge(edge1);
  let slope2 = getSlopeOfEdge(edge2);
  if (slope1 == slope2) return true;
  return false;
}

function snapPartitionsInSamePolygon(pol) {
  let edgeArray = [];
  let lengthThreshold = 10;
  let newPol = [];

  for (let i = 0; i < pol.length; i++) {
    let edge = [pol[i], pol[(i + 1) % pol.length]];
    edgeArray.push(edge);
  }

  for (let i = 0; i < edgeArray.length; i++) {
    let edge = edgeArray[i];
    let lengthOfEdge = getLengthOfEdge(edge[0], edge[1]);
    if (lengthOfEdge < lengthThreshold) {
      let edgeBefore = edgeArray[(i - 1) % edgeArray.length];
      let edgeAfter = edgeArray[(i + 1) % edgeArray.length];
      let edgeAfterAfter = edgeArray[(i + 2) % edgeArray.length];
      if (isParallel(edgeBefore, edgeAfter)) {
        edgeAfter[0] = edgeBefore[1];
        let newSecondPoint = projectionOfPointOnLine2D(
          edgeAfter[1],
          edgeBefore[0],
          edgeBefore[1]
        );
        edgeAfter[1] = newSecondPoint;
        edgeAfterAfter[0] = edgeAfter[1];
      }
    }
  }

  for (let i = 0; i < edgeArray.length; i++) {
    newPol.push(edgeArray[i][0]);
  }

  return newPol;
}

function snapObject(pol, threshX, threshY) {
  if (pol.get("type") != "polygon") {
    return;
  }
  let pt1, pt2;
  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (
      obj.get("id") == "rooms" &&
      obj.get("type") == "polygon" &&
      obj != pol &&
      pol.get("type") == "polygon"
    ) {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pol.points.forEach(function (pt11) {
        pt1 = jQuery.extend({}, pt11);

        if (pt1.x > meanXX) {
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;
        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = jQuery.extend({}, pt22);

          if (pt2.x > meanX) {
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (X1 != undefined) {
        pol.left -= X1.x - X2.x;
        pol.setCoords();
      }
      if (Y1 != undefined) {
        pol.top -= Y1.y - Y2.y;
        pol.setCoords();
      }
    }
  });
}

/**
 *
 * @param pointArray - array of BABYLON.Vector2
 * @param prevArea
 * @returns area if satisfied else false
 */
function checkIfAreaLessens(pointArray, prevArea) {
  let newArea = getAreaOfPolygon(pointArray);
  console.log(
    "Inside checkIfArea, newArea = ",
    newArea,
    ", prevArea = ",
    prevArea
  );
  if (newArea < prevArea) {
    //return true;
    return newArea;
  }
  return false;
}

/**
 *
 * @param pointArray - array of BABYLON.Vector2
 * @returns {number}
 */
function getAreaOfPolygon(pointArray) {
  let area = 0;
  let prevPtIndex = pointArray.length - 1;
  let arrayLength = pointArray.length;
  for (let i = 0; i < arrayLength; i++) {
    area +=
      (pointArray[prevPtIndex].x + pointArray[i].x) *
      (pointArray[prevPtIndex].y - pointArray[i].y);
    prevPtIndex = i;
  }
  return Math.abs(area / 2.0);
}

function getLengthOfEdge(edgePt1, edgePt2) {
  return ((edgePt2.x - edgePt1.x) ** 2 + (edgePt2.y - edgePt1.y) ** 2) ** 0.5;
}

function projectionOfPointOnLine2DFplan(
  pointToBeChecked,
  edgePoint1,
  edgePoint2
) {
  let line = { x: 0, y: 0 };
  line.x = edgePoint2.x - edgePoint1.x;
  line.y = edgePoint2.y - edgePoint1.y;
  let zero = { x: 0, y: 0 };
  let lengthOfLine = getLengthOfEdge(line, zero);
  line.x = line.x / lengthOfLine;
  line.y = line.y / lengthOfLine;
  let vectorFromEdgePoint1ToPoint = { x: 0, y: 0 };
  vectorFromEdgePoint1ToPoint.x = pointToBeChecked.x - edgePoint1.x;
  vectorFromEdgePoint1ToPoint.y = pointToBeChecked.y - edgePoint1.y;
  let t = dotProduct(vectorFromEdgePoint1ToPoint, line);
  line.x = edgePoint1.x + line.x * t;
  line.y = edgePoint2.y + line.y * t;
  return line;
}

function dotProduct(vector1, vector2) {
  return vector1.x * vector2.x + vector1.y * vector2.y;
}
export {
  snapObjectEdgeExt,
  snapNewObject,
  snapObjectEdge,
  snapObjectVert,
  getSlopeOfEdge,
  isParallel,
  snapPartitionsInSamePolygon,
  snapObject,
  checkIfAreaLessens,
  getAreaOfPolygon,
  getLengthOfEdge,
  projectionOfPointOnLine2DFplan,
  dotProduct,
};
