'use strict';

import {getV3ProjectedOntoScreenSpace} from "../extrafunc";
import {store} from "../utilityFunctions/Store";

const snapVerifier = (function (){
  
  const PROXIMITY_THRESHOLD = 10; // in css px
  // ref- https://stackoverflow.com/a/21767407
  
  const _getCurrentScreenPosition = function (){
    return {
      x: store.scene.pointerX,
      y: store.scene.pointerY,
    }
  };
  
  const _getDistanceBetweenScreenPoints = function (p1, p2){
    return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
  };
  
  const checkIfSnapIsValidInScreenSpace = function (snapPoint){
    return getScreenSpaceDistanceFromPointer(snapPoint) < PROXIMITY_THRESHOLD;
  };
  
  const getScreenSpaceDistanceFromPointer = function (v3){
    const v2 = getV3ProjectedOntoScreenSpace(v3);
    const mousePoint = _getCurrentScreenPosition();
    
    return _getDistanceBetweenScreenPoints(v2, mousePoint);
  };
  
  const getScreenSpaceDistanceBetweenVectors = function (v1, v2){
    const v1Screen = getV3ProjectedOntoScreenSpace(v1);
    const v2Screen = getV3ProjectedOntoScreenSpace(v2);
    
    return _getDistanceBetweenScreenPoints(v1Screen, v2Screen);
  };
  
  const checkIfSnapIsValidIn3DSpace = function (snapPoint){
  
  };
  
  const getScreenSpaceThreshold = function (){
    return PROXIMITY_THRESHOLD;
  };
  
  return {
    checkIfSnapIsValidInScreenSpace,
    checkIfSnapIsValidIn3DSpace,
    getScreenSpaceThreshold,
    getScreenSpaceDistanceFromPointer,
    getScreenSpaceDistanceBetweenVectors,
  };
  
})();

export default snapVerifier;