const workercode = () => {

    onmessage = function(e) {
        const {data: blobUrl} = e;

        var xhr = new XMLHttpRequest;
        xhr.responseType = 'blob';

        xhr.onload = function() {
            var recoveredBlob = xhr.response;

            var reader = new FileReader;
            reader.onload = function() {
                var blobAsDataUrl = reader.result;
                postMessage(blobAsDataUrl);
            };

            reader.readAsDataURL(recoveredBlob);
        };

        xhr.open('GET', blobUrl);
        xhr.send();
    }
};

let code = workercode.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;