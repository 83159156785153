import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { makeid } from "../../libs/arrayFuncs.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { layerView } from "../../libs/layerView.js";
import {
  removeMeshFromStructure,
  setActiveLayerAndRecord,
  showToast,
  TOAST_TYPES,
} from "../extrafunc.js";
import { removeMeshSelectionChildren } from "../../libs/meshEvents.js";
import { Command } from "../commandManager/Command.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { AutoSave } from "../socket/autoSave.js";
import reduxStore from "../../stateManagers/store/reduxStore";
import {
  appendLayer,
  deleteLayer,
} from "../../stateManagers/reducers/objectProperties/storeysSlice";
import topoLayer from "../../../assets/tiles/topoLayer.svg";
import { scenePickController } from "../utilityFunctions/scenePickController";
import _ from "lodash";
import { meshUniqueIdMapper } from "../utilityFunctions/meshUniqueIdMapper";
import { getRotationQuaternion } from "../extrafunc";
import {
  addNeighborhoodToLayer, createNeighborhood,
  deleteNeighborhood,
} from "./terrainNeighborhood.js";
import { Action as CoveAnalysisAction } from '../../stateManagers/reducers/objectProperties/coveAnalysisSlice'
import coveToolHelpers from "../covetool/index.js";

let terrainGeneration = (function () {
  let map = store.terrainMap;
  let _lastKnownCenter = [-77.04, 38.907]; //lng, lat
  let _lastKnownZoom = 15;
  let _defaultMapBoxStyle = "https://api.mapbox.com/styles/v1/mapbox/cjaudgl840gn32rnrepcb9b9g/tiles/";
  let _satelliteMapBoxStyle = "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/";

  const init = (mapComponent) => {
    map = mapComponent;
    // console.log("initialised mapbox")
    // mapboxgl.accessToken =
    //   "pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw";
    // const hmToGScale = "/himgtogscale/";
    // map = new mapboxgl.Map({
    //   container: "map",
    //   style: "mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g", // the outdoors-v10 style but without Hillshade layers
    //   center: [-77.04, 38.907], //  was [-119.5591, 37.715]
    //   zoom: 15,
    //   minZoom: 15,
    // });

    // map.on("load", function () {
    //   map.addSource("dem", {
    //     type: "raster-dem",
    //     url: "mapbox://mapbox.terrain-rgb",
    //   });
    //   map.addLayer(
    //     {
    //       id: "hillshading",
    //       source: "dem",
    //       type: "hillshade",
    //       // insert below waterway-river-canal-shadow;
    //       // where hillshading sits in the Mapbox Outdoors style
    //     },
    //     "waterway-river-canal-shadow"
    //   );

    //   map.addControl(
    //     new MapboxGeocoder({
    //       accessToken: mapboxgl.accessToken,
    //       mapboxgl: mapboxgl,
    //     })
    //   );
    //   let unit = "metric";

    //   map.dragRotate.disable();
    //   map.touchZoomRotate.disableRotation();

    //   map.addControl(
    //     new mapboxgl.ScaleControl({
    //       maxWidth: 100,
    //       unit: unit,
    //     })
    //   );
    // });

    // $("#mapBoxModal").on("shown.bs.modal", function () {
    //   map.resize();
    // });
  };

  let mapTilesArray = [];
  let createTerrain = function (data) {
    if (!data) {
      data = this.data;
    }
    let terrain = BABYLON.MeshBuilder.CreateGround(
      "terrain",
      {
        width: data.width,
        height: data.height,
        subdivisionsX: data.subdivisionsX,
        subdivisionsY: data.subdivisionsY,
        updatable: true,
      },
      store.scene
    );

    if (data.uniqueId) {
      // terrain.uniqueId = data.uniqueId;
      meshUniqueIdMapper.update(terrain, data.uniqueId);
    } else {
      data.uniqueId = terrain.uniqueId;
    }
    store.lastImportedTerrainMap = terrain.uniqueId;

    let mapData = terrain.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    if (data.subdivisionsX !== 0) {
      for (let y = 0; y < data.imgHeight; y++) {
        for (let x = 0; x < data.imgWidth; x++) {
          let i = (y * data.imgWidth + x) * 4;
          let h =
            -10000 +
            (data.imgPixels.data[i] * 256 * 256 +
              data.imgPixels.data[i + 1] * 256 +
              data.imgPixels.data[i + 2]) *
              0.1;
          h = DisplayOperation.getOriginalDimension(h, "meters") * 1;
          mapData[(y * data.imgWidth + x) * 3 + 1] = h;
        }
      }
    }
    terrain.computeWorldMatrix(true);
    let terrainMaterial = new BABYLON.StandardMaterial(
      "terrainMaterial",
      store.scene
    );
    terrainMaterial.backFaceCulling = false;
    terrainMaterial.diffuseTexture = new BABYLON.Texture.CreateFromBase64String(
      data.textURL,
      "terrain" + makeid(3),
      store.scene
    );

    terrain.updateVerticesData(
      BABYLON.VertexBuffer.PositionKind,
      mapData,
      true
    );
    if (data.terrainPosition && !data.fromHeightMapToggle) {
      terrain.position.y = data.terrainPosition[1];
      terrain.position.x = data.terrainPosition[0];
      terrain.position.z = data.terrainPosition[2];
    } else {
      terrain.position.y -=
        terrain.getBoundingInfo().boundingBox.minimumWorld.y;
      terrain.position.x += (data.width + 1) / 2;
      terrain.position.z -= (data.height + 1) / 2;
    }

    if (data.rotationQuaternion) {
      terrain.rotationQuaternion = new BABYLON.Quaternion(
        data.rotationQuaternion[0],
        data.rotationQuaternion[1],
        data.rotationQuaternion[2],
        data.rotationQuaternion[3]
      );
    } else {
      if (terrain.rotationQuaternion)
        data.rotationQuaternion = terrain.rotationQuaternion.asArray();
      else
        data.rotationQuaternion = getRotationQuaternion(
          terrain.rotation
        ).asArray();
        terrain.rotationQuaternion = new BABYLON.Quaternion(
          data.rotationQuaternion[0],
          data.rotationQuaternion[1],
          data.rotationQuaternion[2],
          data.rotationQuaternion[3]
        );
    }

    if (data.subdivisionsX === 0) {
      terrain.position.y += 0.005;
    }
    //this is to keep the previous Y for set Datum as we delete and create terrain again.
    if (
      data.fromHeightMapToggle &&
      data.currentYPosition &&
      data.subdivisionsX !== 0
    ) {
      terrain.position.y = data.currentYPosition;
    }

    if (data.fromHeightMapToggle) {
      terrain.position.x = data.terrainPosition[0];
      terrain.position.z = data.terrainPosition[2];
    }

    data.terrainPosition = terrain.position.asArray();
    terrain.material = terrainMaterial;
    if (!map) {
      map = store.terrainMap;
    }
    if (map) {
      terrain.mapCenter = map.getCenter();
      terrain.mapZoom = Math.floor(map.getZoom());
      terrain.mapBounds = map.getBounds();

      store.terrainMapData = {
        center: terrain.mapCenter,
        zoom: terrain.mapZoom,
        bounds: terrain.mapBounds,
      };
    }

    if (data.mapCenter) {
      terrain.mapCenter = data.mapCenter;
    } else {
      // data.mapCenter = [terrain.mapCenter.lng, terrain.mapCenter.lat];
      data.mapCenter = terrain.mapCenter;
    }

    if (data.mapZoom) {
      terrain.mapZoom = data.mapZoom;
    } else {
      data.mapZoom = terrain.mapZoom;
    }

    if (data.mapBounds) {
      terrain.mapBounds = data.mapBounds;
    } else {
      data.mapBounds = terrain.mapBounds;
    }

    let structure =
      StructureCollection.getInstance().getStructures()[data.structure];
    let structureID = data.structure;
    // let levelLow = (parseInt(data.storey) - 1).toString();
    // let levelHigh = (parseInt(levelLow) + 1).toString();

    terrain.structure_id = structureID;
    terrain.type = "Mass";
    terrain.storey = data.storey;

    let level;
    level = structure.getLevel("0", "1");
    if (!level) structure.addLevel("0", "1");
    let levelNum = "01";

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure =
      structureCollection.getStructureById(structureID);
    const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelNum);
    talkingAboutLevel.addObjectToLevel(new Mass(terrain), false);
    data.levelId = talkingAboutLevel.flyweight.uniqueId;
    data.levelNum = levelNum;

    let storeyCollection = talkingAboutStructure.getStoreyData();
    let storey = storeyCollection.getStoreyByValue(data.storey);
    let layerData = storey.layerData;

    data.storeyId = storey.id;

    let layerName;
    if (data.layerName) {
      layerName = data.layerName;
    } else {
      let count = layerData.getCountOfLayerByType("terrain", data.storey);
      count = count + 1;
      layerName = "terrain-" + count;
    }
    terrain.layerName = layerName;
    if (!data.fromHeightMapToggle) {
      let options = {};
      options.layerId = data.layerId ? data.layerId : null;
      layerView.addAndSelectLayer(layerName, data.structure, 1, options);

      let layer = store.activeLayer;
      
      if(data.subdivisionsX !== 0) layer.heightMapToggle = true

      reduxStore.dispatch(
        appendLayer({
          id: layer.id,
          title: layer.name,
          storey: 1,
          hidden: layer.hidden,
          heightMapToggle: layer.heightMapToggle,
          image: topoLayer,
          // subView: {
          //   allIcons: true,
          //   heightToggle: layer.heightMapToggle,
          //   thicknessSelector: false,
          //   colorPicker: false,
          // },
        })
      );
      // ScopeUtils.addLayer(store.activeLayer);

      store.activeLayer.addTerrain(terrain, data);
      data.layerName = store.activeLayer.name;
      data.layerId = store.activeLayer.id;
    } else {
      let layer = layerData.getLayerByName(data.layerName, data.storey);
      data.fromHeightMapToggle = false;
      layer.addTerrain(terrain, data);
    }
    terrain.isVisible = true;
    terrain.getSnaptrudeDS().massType = "Mass";

    coveToolHelpers.calculateAndSaveLocation()

    if (data.isNeighborhoodEnabled) {
      let elevation = terrain._subdivisionsX === 1 ? false : true;
      let neighborhoodLayer = storey.layerData.getLayerBylayerId(
        data.neighborhoodLayer
      );
      if (
        neighborhoodLayer &&
        neighborhoodLayer.neighborhood.parentID === terrain.uniqueId
      ) {
        store.neighborhoodBuildings[data.layerName] = neighborhoodLayer.neighborhood.mesh;
      }
      let buildings = store.neighborhoodBuildings[data.layerName] ? store.neighborhoodBuildings[data.layerName] : [];
      buildings.forEach((building) => {
        let mesh = store.scene.getMeshByUniqueID(building);
        if (mesh) {
          mesh.setParent(terrain);
          if (elevation) {
            mesh.position.y = mesh.getSnaptrudeDS().elevation + mesh.height / 2;
          } else {
            mesh.position.y = mesh.height / 2;
          }
        }
      });
    }
  };

  let _allTerrainMapsOnTheScene = function () {
    let allMeshesOnScene = scenePickController.getVisibleList();
    return allMeshesOnScene.filter((mesh) => mesh.name.includes("terrain"));
  };

  let _updateCenterAndZoomOfTerrain = function () {
    let lastImportedTerrainMapUniqueId = store.lastImportedTerrainMap;

    let _resetToDefault = function () {
      _lastKnownCenter = map.getCenter();
      _lastKnownZoom = Math.floor(map.getZoom());
    };

    if (lastImportedTerrainMapUniqueId) {
      let _terrain = store.scene.getMeshByUniqueID(
        lastImportedTerrainMapUniqueId
      );
      if (_terrain) {
        _lastKnownCenter = _terrain.mapCenter;
        _lastKnownZoom = _terrain.mapZoom;
      } else {
        _resetToDefault();
      }
    } else {
      let allTerrains = _allTerrainMapsOnTheScene();
      if (!_.isEmpty(allTerrains)) {
        let _requiredTerrain = allTerrains[allTerrains.length - 1];
        _lastKnownCenter = _requiredTerrain.mapCenter;
        _lastKnownZoom = _requiredTerrain.mapZoom;
      } else {
        _lastKnownCenter = [-77.04, 38.907]; //lng, lat
        _lastKnownZoom = 15;
      }
    }

    if (map) {
      map.flyTo({
        center: _lastKnownCenter,
        zoom: _lastKnownZoom,
      });
    }
  };

  let _moveMapToLastKnownLocation = function() {

    let _lastKnownLocationData = store.lastSearchedLocationData;
    let _lastKnownCenter = [ _lastKnownLocationData?.lastKnownCoordinates?.lng, _lastKnownLocationData?.lastKnownCoordinates?.lat ];
    if(_.isEmpty(_lastKnownCenter)){
      _lastKnownCenter = [-77.04, 38.907]; //lng, lat
    }
    let _lastKnownZoom = _lastKnownLocationData.lastKnownZoom ? _lastKnownLocationData.lastKnownZoom : 15;

    if (map) {
      map.flyTo({
        center: _lastKnownCenter,
        zoom: _lastKnownZoom,
      });
    }
  };

  let _saveLocationInBackend = function() {
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = makeid(5);
    saveData.data.identifier = {
      floorkey: store.floorkey,
      structure_id: store.activeLayer
        ? store.activeLayer.structure_id || "default"
        : "default",
    };
    saveData.data.saveType = "setUserSetting";

    saveData.data.afterOperationData = [
      {
        key: "lastSearchedLocationData",
        value: store.lastSearchedLocationData,
      },
    ];
    //
    // saveData.data.beforeOperationData = [
    //   {
    //     key: "lastSearchedLocationData",
    //     value: store.lastSearchedLocationData,
    //   },
    // ];

    AutoSave.directPublish(saveData);
  }

  let _storeLastSearchedLocation = function (){
    if(store.terrainMap){
      let _center = store.terrainMap.getCenter();
      let _zoom = store.terrainMap.getZoom();
      let _bounds = store.terrainMap.getBounds()

      if(store.lastSearchedLocationData){
        store.lastSearchedLocationData.lastKnownCoordinates.lat = _center.lat;
        store.lastSearchedLocationData.lastKnownCoordinates.lng = _center.lng;

        store.lastSearchedLocationData.lastKnownZoom = Math.round(_zoom);

        store.lastSearchedLocationData.bounds = _bounds
      }
      _saveLocationInBackend();
    }
  };

  let _boundsOfTheTerrainAsAnArray = function (){
    if(store.terrainMap){
      let bounds = store.terrainMap.getBounds();

      let _northEast = bounds?._ne.toArray();
      let _southWest = bounds?._sw.toArray();
      let _northWest = bounds.getNorthWest().toArray();
      let _southEast = bounds?.getSouthEast().toArray();

      // should be in the order of ne, se, sw, nw, ne
      return [
        _northEast, _southEast, _southWest, _northWest, _northEast
      ]
    }
  };

  let deleteTerrain = function (data) {
    if (!data) {
      data = this.data;
    }
    if (data.structure) {
      let mesh = store.scene.getMeshByUniqueID(data.uniqueId);
      let meshSnaptrudeDs = mesh.getSnaptrudeDS();
      let structure =
        StructureCollection.getInstance().getStructures()[
          meshSnaptrudeDs.structure_id
        ];
      let storey = structure
        .getStoreyData()
        .getStoreyByValue(meshSnaptrudeDs.storey);
      let layer = storey.layerData.getLayerByName(
        data.layerName,
        meshSnaptrudeDs.storey
      );
      let layerID = layer.id;

      if (data.isNeighborhoodEnabled) {
        let buildings = store.neighborhoodBuildings[data.layerName] ? store.neighborhoodBuildings[data.layerName] : [];
        buildings.forEach((building) => {
          let mesh = store.scene.getMeshByUniqueID(building);
          if (mesh) {
            mesh.setParent("");
          }
        });
      }

      layer.terrain.pop();
      removeMeshFromStructure(mesh);
      removeMeshSelectionChildren(mesh);
      mesh.dispose();

      if (!data.fromHeightMapToggle) {
        storey.layerData.deleteLayer(layerID);
        reduxStore.dispatch(deleteLayer({ layerId: layerID, storeyValue: 1 }));
        setActiveLayerAndRecord(storey.layerData.getLayerByName("wall", 1));
        // storeyView.deleteStoreyLayerUIData(storey, layerID);
      }

      // remove location from redux and store
      reduxStore.dispatch(CoveAnalysisAction.updateSettings({
        location: {
          lat: null,
          lng: null
        }
      }))

      coveToolHelpers.toggleUseOflastSearchedLocation()
    }
  };

  let _handleHeightMapToggle = (terrain, isEnabled) => {
    let terrainData = terrain[0].parameters;
    terrainData.fromHeightMapToggle = true;
    let _terrainMesh = store.scene.getMeshByUniqueID(terrainData.uniqueId);
    if (!_terrainMesh.rotationQuaternion) {
      _terrainMesh.rotationQuaternion = getRotationQuaternion(
        _terrainMesh.rotation
      );
    }
    terrainData.rotationQuaternion = _terrainMesh.rotationQuaternion.asArray();

    deleteTerrain(terrainData);
    if (isEnabled) {
      terrainData.subdivisionsX = terrainData.imgWidth - 1;
      terrainData.subdivisionsY = terrainData.imgHeight - 1;
    } else {
      terrainData.subdivisionsX = 0;
      terrainData.subdivisionsY = 0;
    }
    createTerrain(terrainData);

    let structure =
      StructureCollection.getInstance().getStructures()[terrainData.structure];
    let storey = structure.getStoreyData().getStoreyByValue(terrainData.storey);
    let layer = storey.layerData.getLayerByName(
      terrainData.layerName,
      terrainData.storey
    );
    layer.heightMapToggle = !layer.heightMapToggle;
    if (layer.hidden) {
      store.scene.getMeshByUniqueID(terrainData.uniqueId).isVisible = false;
    }
    return true;
  };
  
  let _handleTerrainDeletionFromLayersUI = function(data, storey) {

    let structure = StructureCollection.getInstance().getStructures()[data.structure_id];
    let layerData = structure.getStoreyData().getStoreyByValue(storey).layerData;
    let layer = layerData.getLayerBylayerId(data.id);
    let terrain = layer.terrain[0];

    let _terrainDeletionCommands = [];

    let _terrainMesh = store.scene.getMeshByUniqueID(terrain.parameters.uniqueId);
    // let meshSnaptrudeDs = _terrainMesh.getSnaptrudeDS();
    // let terrain = structure.getStoreyData().getStoreyByValue(storey).layerData.getTerrainObject(_terrainMesh.uniqueId);

    let neighborhoodLayer;

    if (terrain.parameters.isNeighborhoodEnabled) {

      const storeyOfInterest = structure.getStoreyData().getStoreyByValue(storey);
      neighborhoodLayer = storeyOfInterest.layerData.getLayerBylayerId(
        terrain.parameters.neighborhoodLayer,
        storey
      );

      if (neighborhoodLayer) {
        let data = {
          storey: 1,
          structure_id: neighborhoodLayer.structure_id,
          layer: neighborhoodLayer,
          layerId: neighborhoodLayer.id,
          storeyData: 1,
        };

        let _createNeighborhood = function () {
          let neighborhood = this.data.layer.neighborhood;
          createNeighborhood(
            neighborhood.bounds,
            { center: neighborhood.center, zoom: neighborhood.zoom },
            neighborhood.parentID,
            neighborhood.parameters,
            { layerId: this.data.layerId }
          );
        };

        let _deleteNeighborhood = function () {
          deleteNeighborhood(this.data.layer);
        };

        let getCommandLogic = function () {
          return {
            execute: _deleteNeighborhood,
            unexecute: _createNeighborhood,
          };
        };

        let getSaveData = function () {
          let saveData = AutoSave.getSaveDataPrototype();
          saveData.commandId = "deleteNeighborhoodLayer" + makeid(3);
          saveData.data.saveType = "deleteNeighborhoodLayer";
          saveData.data.identifier = {
            structure_id: this.data.structure_id,
            floorkey: store.floorkey,
            storey: this.data.storey.toString(),
            id: this.data.layer.id,
          };

          let dataAfter = { neighborhood: this.data.layer.neighborhood };
          saveData.data.afterOperationData = dataAfter;
          return saveData;
        };

        let cmd = new Command(
          "deleteNeighborhoodLayer",
          data,
          getCommandLogic(),
          getSaveData
        );
        _terrainDeletionCommands.push(cmd);
      }
    }

    function getCommandLogic() {
      return {
        execute: terrainGeneration.deleteTerrain,
        unexecute: terrainGeneration.createTerrain,
      };
    }

    let getSaveData = function () {
      let saveData = AutoSave.getSaveDataPrototype();
      saveData.commandId = "terrainMapDeletion" + makeid(3)

      saveData.data.saveType = "terrainMapDeletion";

      saveData.data.identifier = {
        structure_id: this.data.structure,
        storey: this.data.storey.toString(),
        layerName: this.data.layerName,
        floorkey: store.floorkey,
        id: this.data.layerId,
      };

      let dataAfter = {
        parameters: {
          uniqueId: this.data.uniqueId,
          width: this.data.width,
          height: this.data.height,
          subdivisionsX: this.data.subdivisionsX,
          subdivisionsY: this.data.subdivisionsY,
          imgPixels: this.data.imgPixels,
          textURL: this.data.textURL,
          imgHeight: this.data.imgHeight,
          imgWidth: this.data.imgWidth,
          terrainPosition: this.data.terrainPosition,
          rotationQuaternion: this.data.rotationQuaternion,
          layerName: this.data.layerName,
          structure: this.data.structure,
          storey: this.data.storey,
          mapCenter: this.data.mapCenter,
          mapZoom: this.data.mapZoom,
          tilesArray: this.data.tilesArray,
          mapBounds: this.data.mapBounds,
          isNeighborhoodEnabled: this.data.isNeighborhoodEnabled,
          neighborhoodLayer: this.data.neighborhoodLayer,
        },
      };

      saveData.data.afterOperationData = dataAfter;
      return saveData;
    };

    let _terrainData = null;
    _terrainMesh.computeWorldMatrix(true);
    if (!terrain.parameters.rotationQuaternion) {
      _terrainData = {
        rotationQuaternion: _terrainMesh.rotationQuaternion?.asArray(),
        ...terrain.parameters,
      };
    } else {
      terrain.parameters.rotationQuaternion =
        _terrainMesh.rotationQuaternion?.asArray();
      _terrainData = terrain.parameters;
    }

    let cmd = new Command(
      "TerrainMapDeletion",
      _terrainData,
      getCommandLogic(),
      getSaveData
    );
    _terrainDeletionCommands.push(cmd);

    CommandManager.execute(_terrainDeletionCommands, [true, true]);
  }

  let _enableOrDisableHeightMap = function (
    layerId,
    storeyVal,
    structure_id,
    isEnabled
  ) {
    let structure =
      StructureCollection.getInstance().getStructures()[structure_id];
    let layer = structure
      .getStoreyData()
      .getStoreyByValue(storeyVal)
      .layerData.getLayerBylayerId(layerId);

    if (isEnabled) {
      _handleHeightMapToggle(layer.terrain, true);
    } else {
      _handleHeightMapToggle(layer.terrain, false);
    }

    let heightMapUpdateData = {
      structure_id: store.activeLayer.structure_id,
      storey: storeyVal,
      layerName: layer.name,
      terrain: layer.terrain,
      heightMapToggle: layer.heightMapToggle,
      layerId: layer.id,
    };

    function _enableHeightMap() {
      let data = this.data;
      layer.enableOrDisableHeightMapToggle();
      _handleHeightMapToggle(data.terrain, true);
    }

    function _disableHeightMap() {
      let data = this.data;
      layer.enableOrDisableHeightMapToggle();
      _handleHeightMapToggle(data.terrain, false);
    }

    function _getCommandLogic() {
      return {
        unexecute: _disableHeightMap,
        execute: _enableHeightMap,
      };
    }

    let _saveData = function () {
      let data = this.data;
      let saveData = AutoSave.getSaveDataPrototype();

      saveData.commandId = this.id;
      saveData.data.identifier = {
        structure_id: data.structure_id,
        storey: data.storey,
        layerName: data.layerName,
        floorkey: store.floorkey,
        id: data.layerId,
      };

      if (this.data.heightMapToggle) {
        saveData.data.saveType = "enableDisableHeightMap";
      } else {
        saveData.data.saveType = "enableDisableHeightMap";
      }

      saveData.data.afterOperationData = {
        terrain: this.data.terrain,
      };

      return saveData;
    };

    let enableHeightMapCommand = new Command(
      "enableHeightMap",
      heightMapUpdateData,
      _getCommandLogic(),
      _saveData
    );
    CommandManager.execute(enableHeightMapCommand, false);
  };

  let getBoundsOfTheTerrainMap = function (_tilesArray, _zoom) {
    let _boundsTopLeft = tile2BoundingBox(
      _tilesArray[0][0],
      _tilesArray[1][1],
      _zoom
    );
    let _boundsTopRight = tile2BoundingBox(
      _tilesArray[1][0],
      _tilesArray[1][1],
      _zoom
    );
    let _boundsBottomLeft = tile2BoundingBox(
      _tilesArray[_tilesArray.length - 2][0],
      _tilesArray[_tilesArray.length - 2][1],
      _zoom
    );
    let _boundsBottomRight = tile2BoundingBox(
      _tilesArray[_tilesArray.length - 1][0],
      _tilesArray[_tilesArray.length - 1][1],
      _zoom
    );

    return {
      _ne: {
        lat: _boundsTopRight.north,
        lng: _boundsTopRight.east,
      },
      _sw: {
        lat: _boundsBottomLeft.south,
        lng: _boundsBottomLeft.west,
      },
      _se: {
        lat: _boundsBottomRight.south,
        lng: _boundsBottomRight.east,
      },
      _nw: {
        lat: _boundsTopLeft.north,
        lng: _boundsTopLeft.west,
      },
    };
  };

  const toDegrees = (radians) => (radians * 180) / Math.PI;

  const tile2Lon = (x, z) => {
    return (x / Math.pow(2.0, z)) * 360.0 - 180;
  };

  const tile2Lat = (y, z) => {
    let n = Math.PI - (2.0 * Math.PI * y) / Math.pow(2.0, z);
    return toDegrees(Math.atan(Math.sinh(n)));
  };

  const tile2BoundingBox = (x, y, zoom) => {
    let bb = {};
    bb.north = tile2Lat(y, zoom);
    bb.south = tile2Lat(y + 1, zoom);
    bb.west = tile2Lon(x, zoom);
    bb.east = tile2Lon(x + 1, zoom);
    return bb;
  };

  const _getHeightMapGround = (url, quality, options) => {
    async function grey(quality) {
      let _executeEvent = function () {
        let data = {
          width: groundSizeX,
          height: groundSizeZ,
          subdivisionsX: subX,
          subdivisionsY: subZ,
          imgPixels: imgPixels,
          textURL: textURL,
          imgHeight: initialWidth,
          imgWidth: initialWidth,
          fromHeightMapToggle: false,
          structure: store.activeLayer.structure_id,
          storey: 1,
          tilesArray: mapTilesArray,
          mapBounds: _bounds,
          isNeighborhoodEnabled: isNeighborhoodEnabled,
          neighborhoodLayer: "",

          // layerId: store.activeLayer.id,
        };

        let cmd = new Command(
          "TerrainMapGeneration",
          data,
          getCommandLogic(),
          getSaveData
        );
        CommandManager.execute(cmd, true);
      };

      let _createTerrain = function () {
        createTerrain(this.data);
        let terrain = store.activeLayer.terrain[0].parameters.uniqueId;
        if (this.data.isNeighborhoodEnabled) {
          showToast("Generating Neighborhood...", 3000, TOAST_TYPES.success);
          addNeighborhoodToLayer(
            this.data.mapBounds,
            { zoom, center },
            terrain
          );
          CommandManager.popLastItemFromExecuted()
          if (!store.neighborhoodBuildings[this.data.layerName].length) {
            this.data.isNeighborhoodEnabled = false;
          } else {
            this.data.neighborhoodLayer = store.activeLayer.id;
          }
        }
      };

      let _deleteTerrain = function () {
        if (this.data.isNeighborhoodEnabled) {
          let structure = StructureCollection.getInstance().getStructures()[this.data.structure];
          let layerData = structure.getStoreyData().getStoreyByValue(1).layerData;
          let neighborhoodLayer =  layerData.getLayerBylayerId(this.data.neighborhoodLayer);
          deleteNeighborhood(neighborhoodLayer);
        }
        deleteTerrain(this.data);
      };

      let getCommandLogic = function () {
        return {
          execute: _createTerrain,
          unexecute: _deleteTerrain,
        };
      };

      let getSaveData = function () {
        let saveData = AutoSave.getSaveDataPrototype();
        saveData.commandId = this.id;
        saveData.data.saveType = "terrainMapGeneration";
        saveData.data.identifier = {
          structure_id: this.data.structure,
          floorkey: store.floorkey,
          storey: this.data.storey.toString(),
          id: this.data.layerId,
        };

        let dataAfter = {
          parameters: {
            uniqueId: this.data.uniqueId,
            width: this.data.width,
            height: this.data.height,
            subdivisionsX: this.data.subdivisionsX,
            subdivisionsY: this.data.subdivisionsY,
            imgPixels: this.data.imgPixels,
            textURL: this.data.textURL,
            imgHeight: this.data.imgHeight,
            imgWidth: this.data.imgWidth,
            terrainPosition: this.data.terrainPosition,
            rotationQuaternion: this.data.rotationQuaternion,
            currentYPosition: this.data.currentYPosition,
            layerName: this.data.layerName,
            structure: this.data.structure,
            storey: this.data.storey,
            mapCenter: this.data.mapCenter,
            mapZoom: this.data.mapZoom,
            mapBounds: this.data.mapBounds,
            tilesArray: this.data.tilesArray,
            isNeighborhoodEnabled: this.data.isNeighborhoodEnabled,
            neighborhoodLayer: this.data.neighborhoodLayer,
          },
        };
        saveData.data.afterOperationData = dataAfter;
        return saveData;
      };
      /*
            const width = input.width;
            const height = input.height;
            const container = document.createElement('div');
            document.body.appendChild(container);
            container.appendChild(cnv);
            cnv.width = width;
            cnv.height = cnv.width * height / width;

            let factor = 1;
            if(quality === "1"){
                factor = 1/4;
            }
            else if(quality === "2"){
                factor = 1/2;
            }

            let cur = {
                width: Math.floor(width * factor),
                height: Math.floor(height * factor)
            };

            oc.width = cur.width;
            oc.height = cur.height;

            octx.drawImage(myimage, 0, 0, cur.width, cur.height);

            while (cur.width * factor > width) {
                cur = {
                    width: Math.floor(cur.width * factor),
                    height: Math.floor(cur.height * factor)
                };
                octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
            }

            // cnx.imageSmoothingQuality = "medium";
            cnx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, cnv.width, cnv.height);
            // cnx.drawImage(myimage, 0, 0);
            const imgPixels = cnx.getImageData(0, 0, cur.width, cur.height);
            */
      let initialWidth = 1024;

      if (quality === "1") {
        initialWidth *= 0.25;
      } else if (quality === "2") {
        initialWidth *= 0.5;
      }

      let subX;
      let subZ;

      if (options && !options.isHeightMapChecked) {
        subX = 0;
        subZ = 0;
      } else {
        subX = initialWidth - 1;
        subZ = initialWidth - 1;
      }
      let isNeighborhoodEnabled = options.isNeighborhoodEnabled;

      let _allTilesAndHeightMapsWithInRange = allTilesWithInRange(
        initialWidth,
        options
      );

      let allTileUrls = _allTilesAndHeightMapsWithInRange.tileUrls;
      let heightMapUrls = _allTilesAndHeightMapsWithInRange.heightMapUrls;

      // let resizedHeightMapURLs = [];
      // if( initialWidth <= 256 ){
      //     for( let i = 0; i < heightMapUrls.length; i++ ){
      //         resizedHeightMapURLs.push( await resizeImagesManual(heightMapUrls[i], 0.5) );
      //     }
      //     heightMapUrls = resizedHeightMapURLs;
      // }

      let imageObjects = prepareDataForStitching(allTileUrls, 1024); // Keep 1024 for now, otherwise texture is all blurry, doesn't effect speed.
      let heightMapObjects = prepareDataForStitching(
        heightMapUrls,
        initialWidth
      );

      const imgPixels = await stitchHeightMapsAndGetImagePixels(
        heightMapObjects,
        initialWidth,
        options.isHeightMapChecked
      );
      let textURL = await stitchImages(imageObjects);

      const center = map.getCenter();
      const zoom = map.getZoom();
      const _bounds = getBoundsOfTheTerrainMap(mapTilesArray, zoom);
      let sizeX = _boundsInMeters(
        _bounds._ne.lat,
        _bounds._sw.lat,
        _bounds._ne.lng,
        _bounds._ne.lng
      );
      let sizeY = _boundsInMeters(
        _bounds._sw.lat,
        _bounds._sw.lat,
        _bounds._ne.lng,
        _bounds._sw.lng
      );
      sizeX = DisplayOperation.getOriginalDimension(sizeX, "meters");
      sizeY = DisplayOperation.getOriginalDimension(sizeY, "meters");
      const groundSizeX = sizeX - 1;
      const groundSizeZ = sizeY - 1;

      _executeEvent();
      // container.remove();
    }
    grey(quality);
    // let myimage = new Image();
    // let paths = [];
    // myimage.onload = () => {
    //     grey(myimage, quality);
    // };
    // myimage.crossOrigin = 'Anonymous';
    // myimage.src = url;
    // return paths;
  };

  //BADLY WRITTEN, MERGE executeFromSaveData and unuexcuteFromSaveData functions
  let _executeFromSaveData = (saveData) => {
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    const caller = {
      data: [],
    };
    // let data = caller.data;

    let data = {
        width: chosenData.parameters.width,
        height: chosenData.parameters.height,
        subdivisionsX: chosenData.parameters.subdivisionsX,
        subdivisionsY: chosenData.parameters.subdivisionsY,
        imgPixels: chosenData.parameters.imgPixels,
        textURL: chosenData.parameters.textURL,
        imgHeight: chosenData.parameters.imgHeight,
        imgWidth: chosenData.parameters.imgWidth,
        fromHeightMapToggle: false, //CHECK
        structure: chosenData.parameters.structure,
        storey: chosenData.parameters.storey,
        layerId: identifier.id, //CHECK,
        layerName: chosenData.parameters.layerName,
        uniqueId: chosenData.parameters.uniqueId,
        mapCenter: chosenData.parameters.mapCenter,
        mapBounds: chosenData.parameters.mapBounds,
        mapZoom: chosenData.parameters.mapZoom,
        rotationQuaternion: chosenData.parameters.rotationQuaternion,
        tilesArray: chosenData.parameters.tilesArray,

    };

    createTerrain(data); //CHECK
  };

  const _unexecuteFromSaveData = (saveData) => {
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    const caller = {
      data: [],
    };
    // let data = caller.data;

    let data = {
        width: chosenData.parameters.width,
        height: chosenData.parameters.height,
        subdivisionsX: chosenData.parameters.subdivisionsX,
        subdivisionsY: chosenData.parameters.subdivisionsY,
        imgPixels: chosenData.parameters.imgPixels,
        textURL: chosenData.parameters.textURL,
        imgHeight: chosenData.parameters.imgHeight,
        imgWidth: chosenData.parameters.imgWidth,
        fromHeightMapToggle: false, //CHECK
        structure: chosenData.parameters.structure,
        storey: chosenData.parameters.storey,
        layerId: identifier.id, //CHECK
        layerName: chosenData.parameters.layerName,
        uniqueId: chosenData.parameters.uniqueId
    };

    deleteTerrain(data);
  };

  // Deletion happening from delete key event
  let _handleCollaborationOfTerrainDeletion = function () {
    let _executeFromSaveData = function (saveData) {
      const identifier = saveData.identifier;
      const afterOperationData = saveData.afterOperationData;
      const beforeOperationData = saveData.beforeOperationData;
      const chosenData = afterOperationData;

      let data = {
        width: chosenData.parameters.width,
        height: chosenData.parameters.height,
        subdivisionsX: chosenData.parameters.subdivisionsX,
        subdivisionsY: chosenData.parameters.subdivisionsY,
        imgPixels: chosenData.parameters.imgPixels,
        textURL: chosenData.parameters.textURL,
        imgHeight: chosenData.parameters.imgHeight,
        imgWidth: chosenData.parameters.imgWidth,
        fromHeightMapToggle: false, //CHECK
        structure: chosenData.parameters.structure,
        storey: chosenData.parameters.storey,
        layerId: identifier.id, //CHECK,
        layerName: chosenData.parameters.layerName,
        uniqueId: chosenData.parameters.uniqueId,
      };
      deleteTerrain(data);
    };

    let _unexecuteFromSaveData = function (saveData) {
      const identifier = saveData.identifier;
      const afterOperationData = saveData.afterOperationData;
      const beforeOperationData = saveData.beforeOperationData;
      const chosenData = afterOperationData;

      let data = {
        width: chosenData.parameters.width,
        height: chosenData.parameters.height,
        subdivisionsX: chosenData.parameters.subdivisionsX,
        subdivisionsY: chosenData.parameters.subdivisionsY,
        imgPixels: chosenData.parameters.imgPixels,
        textURL: chosenData.parameters.textURL,
        imgHeight: chosenData.parameters.imgHeight,
        imgWidth: chosenData.parameters.imgWidth,
        fromHeightMapToggle: false, //CHECK
        structure: chosenData.parameters.structure,
        storey: chosenData.parameters.storey,
        layerId: identifier.id, //CHECK,
        layerName: chosenData.parameters.layerName,
        uniqueId: chosenData.parameters.uniqueId,
        terrainPosition: chosenData.parameters.terrainPosition,
        rotationQuaternion: chosenData.parameters.rotationQuaternion
      };
      createTerrain(data);
    };

    return {
      executeFromSaveData: _executeFromSaveData,
      unExecuteFromSaveData: _unexecuteFromSaveData,
    };
  };

  let prepareDataForStitching = (allUrls, tileWidth) => {
    let objects = [];
    tileWidth = tileWidth / 2;
    objects.push({ uri: allUrls[0], x: 0, y: 0, sw: tileWidth, sh: tileWidth });
    objects.push({
      uri: allUrls[1],
      x: tileWidth,
      y: 0,
      sw: tileWidth,
      sh: tileWidth,
    });
    objects.push({
      uri: allUrls[2],
      x: 0,
      y: tileWidth,
      sw: tileWidth,
      sh: tileWidth,
    });
    objects.push({
      uri: allUrls[3],
      x: tileWidth,
      y: tileWidth,
      sw: tileWidth,
      sh: tileWidth,
    });
    return objects;
  };

  const _getHeightMapTile = (z, x, y, imgQuality) => {
    map.setZoom(Math.floor(map.getZoom()));
    if (!x || !y || !z) {
      [x, y] = latLonToTiles(
        map.transform.center.lat,
        map.transform.center.lng,
        map.getZoom()
      );
      z = Math.floor(map.getZoom());
    }
    let q = "";
    if (imgQuality === 1024) {
      q = "@2x";
    }
    _lastKnownCenter = map.getCenter();

    return (
      "https://api.mapbox.com/v4/mapbox.terrain-rgb/" +
      z +
      "/" +
      x +
      "/" +
      y +
      q +
      ".pngraw?access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw"
    );
  };

  const _getTerrainSateliteImage = (z, x, y, imgQuality, options) => {
    map.setZoom(Math.floor(map.getZoom()));
    if (!x || !y || !z) {
      [x, y] = latLonToTiles(
        map.transform.center.lat,
        map.transform.center.lng,
        map.getZoom()
      );
      z = map.getZoom();
    }
    let q = "";
    if (imgQuality === 1024) {
      q = "@2x";
    }
    // "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/" for satellite image tiles.
    let _tileStyleUrl = _defaultMapBoxStyle;
    if (options && options.isSatelliteEnabled) {
      _tileStyleUrl = _satelliteMapBoxStyle;
    }

    return (
      _tileStyleUrl +
      z +
      "/" +
      x +
      "/" +
      y +
      q +
      "?access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw"
    );
  };

  const _boundsInMeters = (lat1, lat2, lon1, lon2) => {
    lon1 = _degrees_to_radians(lon1);
    lon2 = _degrees_to_radians(lon2);
    lat1 = _degrees_to_radians(lat1);
    lat2 = _degrees_to_radians(lat2);

    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.sin(dlat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;

    let c = 2 * Math.asin(Math.sqrt(a));

    let r = 6371 * 1000; //For meters

    return c * r;
  };

  const _degrees_to_radians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const _latlongToTiles = (lat_deg, lon_deg, zoom) => {
    let lat_rad = _degrees_to_radians(lat_deg);
    let n = 2.0 ** zoom;
    let xtile = parseInt(((lon_deg + 180.0) / 360.0) * n);
    let ytile = parseInt(
      ((1.0 - Math.asinh(Math.tan(lat_rad)) / Math.PI) / 2.0) * n
    );
    return [xtile, ytile];
  };

  function lon2tile(lon, zoom) {
    return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
  }

  function lat2tile(lat, zoom) {
    return Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
        ) /
          Math.PI) /
        2) *
        Math.pow(2, zoom)
    );
  }

  function latLonToTiles(lat, lon, zoom) {
    let x = lon2tile(lon, zoom);
    let y = lat2tile(lat, zoom);
    return [x, y];
  }

  function tile2long(x, z) {
    return (x / Math.pow(2, z)) * 360 - 180;
  }

  function tile2lat(y, z) {
    let n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
    return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
  }

  function numberOfTilesWithinRange(bounds, zoom) {
    // let top_tile = lat2tile(e, zoom); // eg.lat2tile(34.422, 9);
    // let left_tile = lon2tile(n, zoom);
    // let bottom_tile = lat2tile(w, zoom);
    // let right_tile = lon2tile(s, zoom);
    let n = bounds._ne.lng;
    let e = bounds._ne.lat;
    let s = bounds._sw.lng;
    let w = bounds._sw.lat;

    let topAndBottom = latLonToTiles(bounds._ne.lat, bounds._ne.lng, zoom);
    let leftAndRight = latLonToTiles(bounds._sw.lat, bounds._sw.lng, zoom);

    let width = Math.abs(leftAndRight[0] - topAndBottom[0]) + 1;
    let height = Math.abs(leftAndRight[1] - topAndBottom[1]) + 1;

    return width * height;
  }

  function _getAllTileURLs(sw, ne, n, tileWidth, options) {
    let allCoords = [];
    let tileUrls = [];
    if (n >= 4) {
      allCoords.push([ne[0] - 1, ne[1]]);
      allCoords.push(ne);
      allCoords.push([ne[0] - 1, ne[1] + 1]);
      allCoords.push([ne[0], ne[1] + 1]);
    } else {
      allCoords.push([ne[0] - 1, ne[1]]);
      allCoords.push(ne);
    }

    mapTilesArray = allCoords;
    let heightMapUrls = [];
    let zoom = map.getZoom();
    for (let i = 0; i < allCoords.length; i++) {
      tileUrls.push(
        _getTerrainSateliteImage(
          zoom,
          allCoords[i][0],
          allCoords[i][1],
          tileWidth,
          options
        )
      );
      heightMapUrls.push(
        _getHeightMapTile(zoom, allCoords[i][0], allCoords[i][1], tileWidth)
      );
    }
    // console.log(tileUrls);
    return {
      tileUrls: tileUrls,
      heightMapUrls: heightMapUrls,
    };
  }

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      // console.log(url);
      img.onerror = () => reject(new Error(`load ${url} fail`));
      img.src = url;
    });
  };

  async function resizeImage(image, width) {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");

    var oc = document.createElement("canvas");
    let octx = oc.getContext("2d");

    const draw = () => {
      return loadImage(image).then((img) => {
        canvas.height = width;
        canvas.width = width;

        oc.width = img.width * 0.5;
        oc.height = img.height * 0.5;
        octx.drawImage(img, 0, 0, oc.width, oc.height);

        octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

        ctx.drawImage(
          oc,
          0,
          0,
          oc.width * 0.5,
          oc.height * 0.5,
          0,
          0,
          canvas.width,
          canvas.height
        );
        // ctx.drawImage(img, 0, 0, width, width);
      });
    };

    let p = draw();
    await p;

    let dataURL = canvas.toDataURL();
    let imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // return dataURL;
    canvas.remove();
    oc.remove();

    return {
      dataURL: dataURL,
      imgPixels: imgPixels,
    };
  }

  async function resizeImagesManual(image, factor) {
    //<script src="https://rawgit.com/danschumann/limby-resize/master/lib/canvas_resize.js"></script> ( put this in webtrude before. )

    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");

    let newCanvas = document.createElement("canvas");
    let newCtx = canvas.getContext("2d");

    async function drawAndResize(width) {
      newCanvas.width = newCanvas.height = Math.floor(width * factor);

      return new Promise((resolve, reject) => {
        store.canvasResize(canvas, newCanvas, function () {
          resolve("done");
        });
      });
    }

    const draw = () => {
      return loadImage(image).then((img) => {
        canvas.width = canvas.height = img.width;
        ctx.drawImage(img, 0, 0, img.width, img.height);
      });
    };

    await draw();
    await drawAndResize(canvas.width);

    let dataURL = newCanvas.toDataURL();
    let imgPixels = newCtx.getImageData(
      0,
      0,
      newCanvas.width,
      newCanvas.height
    );

    canvas.remove();
    newCanvas.remove();

    return dataURL;
    // return {
    //     dataURL: dataURL,
    //     imgPixels: imgPixels
    // };
  }

  async function stitchImages(imageObjects, tileWidth, options) {
    let canvas = document.createElement("canvas");
    const getContext = () => canvas.getContext("2d");

    // let newCanvas = document.createElement('canvas');
    // let newContext = newCanvas.getContext('2d');

    const container = document.createElement("div");
    document.body.appendChild(container);
    container.appendChild(canvas);

    canvas.height = tileWidth ? tileWidth : 1024;
    canvas.width = tileWidth ? tileWidth : 1024;

    // canvas.height = 1024;
    // canvas.width = 1024;

    const draw = (options) => {
      const ctx = getContext();
      const myOptions = Object.assign({}, options);
      return loadImage(myOptions.uri).then((img) => {
        ctx.drawImage(
          img,
          myOptions.x,
          myOptions.y,
          myOptions.sw,
          myOptions.sh
        );
      });
    };

    /*const imgs = [
            {
                uri: "https://api.mapbox.com/styles/v1/mapbox/cjaudgl840gn32rnrepcb9b9g/tiles/18/43342/101764@2x?access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw",
                x: 0,
                y: 0,
                sw: 128,
                sh: 128
            },
            {
                uri: "https://api.mapbox.com/styles/v1/mapbox/cjaudgl840gn32rnrepcb9b9g/tiles/18/43343/101764@2x?access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw",
                x: 128,
                y: 0,
                sw: 128,
                sh: 128
            },
            {
                uri: "https://api.mapbox.com/styles/v1/mapbox/cjaudgl840gn32rnrepcb9b9g/tiles/18/43342/101765@2x?access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw",
                x: 0,
                y: 128,
                sw: 128,
                sh: 128
            },
            {
                uri: "https://api.mapbox.com/styles/v1/mapbox/cjaudgl840gn32rnrepcb9b9g/tiles/18/43343/101765@2x?access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw",
                x: 128,
                y: 128,
                sw: 128,
                sh: 128
            }
        ];*/

    const allPromises = imageObjects.map(draw);
    await Promise.all(allPromises);
    let dataURL = canvas.toDataURL();

    let imgPixels = getContext().getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    canvas.remove();
    // newCanvas.remove();
    // console.log(dataURL);
    container.remove();
    if (options && options.heightMap) {
      return {
        dataURL: dataURL,
        imgPixels: imgPixels,
      };
    }
    return dataURL;
  }

  async function stitchHeightMapsAndGetImagePixels(
    heightMapObjects,
    tileWidth,
    isHeightMapChecked
  ) {
    let options = {};
    options.heightMap = true;

    // if( !isHeightMapChecked ){
    //     let imgPixels = ctx.getImageData(0, 0, tileWidth, tileWidth);
    //     cnv.remove();
    //     return imgPixels;
    // }

    let data = await stitchImages(heightMapObjects, tileWidth, options);
    // let canvasData = data.dataURL;
    return data.imgPixels;
  }

  function signedAngle(ax, ay, bx, by) {
    let dot = ax*bx + ay*by;
    let cross = ax*by - ay*bx;
    return Math.atan2(cross, dot);
  }

  function _angleBetweenEdgesInRadians (edge1, edge2) {
    let vector1 = [edge1[1].x - edge1[0].x, edge1[1].z - edge1[0].z];
    let vector2 = [edge2[1].x - edge2[0].x, edge2[1].z - edge2[0].z];

    return signedAngle(vector1[0], vector1[1], vector2[0], vector2[1]);
    // let dotProduct = math.dot(vector1, vector2);
    // let normalizedProduct = null;
    // if (dotProduct !== 0) {
    //   normalizedProduct = dotProduct / (modVector(vector1) * modVector(vector2));
    // } else {
    //   normalizedProduct = dotProduct;
    // }
    // return Math.acos(normalizedProduct);
  }

  function allTilesWithInRange(tileWidth, options = {}) {
    let bounds = map.getBounds();
    let zoom = map.getZoom();
    let sw = latLonToTiles(bounds._sw.lat, bounds._sw.lng, zoom);
    let ne = latLonToTiles(bounds._ne.lat, bounds._ne.lng, zoom);
    // let swBuiltIn = _latlongToTiles(bounds._sw.lat, bounds._sw.lng, zoom);
    // let neBuiltIn = _latlongToTiles(bounds._ne.lat, bounds._ne.lng, zoom);
    // console.log("sw, ne: ", sw, ne );
    // console.log("swB, neB", swBuiltIn, neBuiltIn);
    let numberOfTiles = numberOfTilesWithinRange(bounds, zoom);
    return _getAllTileURLs(sw, ne, numberOfTiles, tileWidth, options);
  }

  /* Given a query in the form "lng, lat" or "lat, lng"
  * returns the matching geographic coordinate(s)
  * as search results in carmen geojson format,
  * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */

  const _coordinatesGeocoder = function (query) {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (!matches) {
      return null;
    }

    function coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        place_name: 'Lat: ' + lat + ' Lng: ' + lng,
        place_type: ['coordinate'],
        properties: {},
        type: 'Feature'
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  };

  function _isWithinBounds (point, bounds) {
    point = [point.lng, point.lat];
    let lng = (point[0] - bounds._ne.lng) * (point[0] - bounds._sw.lng) < 0;
    let lat = (point[1] - bounds._ne.lat) * (point[1] - bounds._sw.lat) < 0;
    return lng && lat;
  }

  function _alignTerrainMapWithInGivenLatLon( latLon1, latLon2, pointClicked1, pointClicked2 ){

    let _executeEvent = function (){

      let _requiredData = {
        structure: store.activeLayer.structure_id,
        storey: 1,
        terrainMeshUniqueId: _terrainMesh.uniqueId,
        layerId: layer?.id,
        rotationQuaternionBefore: _rotationQuaternionBefore,
        rotationQuaternionAfter: _rotationQuaternionAfter,
        positionBefore: _positionBefore,
        positionAfter: _positionAfter,
        movementAmount: _movementAmount,
        angle: _angleBetweenTheCurrentEdgeAndTheEdgeItsSupposedToBe,
        pivot: pointClicked1.asArray()
      }

      let _alignAtLatLon = function (){
        let data = this.data;
        let _meshOfInterest = store.scene.getMeshByUniqueID(data.terrainMeshUniqueId);
        if(_meshOfInterest){
          _meshOfInterest.rotationQuaternion = new BABYLON.Quaternion(
            data.rotationQuaternionAfter[0],
            data.rotationQuaternionAfter[1],
            data.rotationQuaternionAfter[2],
            data.rotationQuaternionAfter[3]);
          _meshOfInterest.position = new BABYLON.Vector3.FromArray(data.positionAfter);
        }
      }
      let _undoAlignAtLatLon = function(){
        let data = this.data;
        let _meshOfInterest = store.scene.getMeshByUniqueID(data.terrainMeshUniqueId);
        if(_meshOfInterest){
          _meshOfInterest.rotationQuaternion = new BABYLON.Quaternion(
            data.rotationQuaternionBefore[0],
            data.rotationQuaternionBefore[1],
            data.rotationQuaternionBefore[2],
            data.rotationQuaternionBefore[3]);
          _meshOfInterest.position = new BABYLON.Vector3.FromArray(data.positionBefore);
        }
      }

      let _getCommandLogic = function(){
        return {
          execute: _alignAtLatLon,
          unexecute: _undoAlignAtLatLon,
        };
      }
      let _getSaveData = function() {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = "setAlignmentCoordinates" + this.data.layerId
        saveData.data.saveType = "setAlignmentCoordinates";

        saveData.data.identifier = {
          structure_id: this.data.structure,
          storey: this.data.storey,
          floorkey: store.floorkey,
          id: this.data.layerId,
        };

        let dataBefore = {
          position: this.data.positionBefore,
          rotationQuaternion: this.data.rotationQuaternionBefore,
          movementAmount: [0, 0, 0],
        };
        let dataAfter = {
          position: this.data.positionAfter,
          rotationQuaternion: this.data.rotationQuaternionAfter,
          movementAmount: this.data.movementAmount, // only required for asset marker placement.
          angle: this.data.angle, // only required for asset marker placement.
          pivot: this.data.pivot // only required for asset marker placement.
        };

        saveData.data.beforeOperationData = dataBefore;
        saveData.data.afterOperationData = dataAfter;
        return saveData;
      }

      let cmd = new Command("setAlignmentCoordinates", _requiredData, _getCommandLogic(), _getSaveData);
      CommandManager.execute(cmd, false);
    }

    let _terrainMesh = _allTerrainMapsOnTheScene()[0];
    let _rotationQuaternionBefore = (_terrainMesh.rotationQuaternion) ? _terrainMesh.rotationQuaternion.asArray() : BABYLON.Quaternion.Identity().asArray();
    let _positionBefore = _terrainMesh.position.asArray();

    let structure = StructureCollection.getInstance().getStructures()[store.activeLayer.structure_id];
    let storey = structure.getStoreyData().getStoreyByValue(1);

    let layer = storey.layerData.getLayerByName(_terrainMesh.layerName, 1);

    let _tilesArray = layer.terrain[0]?.parameters?.tilesArray;
    let _boundsOftheActualMap = getBoundsOfTheTerrainMap(_tilesArray, _terrainMesh.mapZoom);

    if( !_isWithinBounds(latLon1, _boundsOftheActualMap) || !_isWithinBounds(latLon2, _boundsOftheActualMap) ){
      showToast("Inputs out of bounds", 3000, TOAST_TYPES.error);
      return;
    }

    let x1 = terrainGeneration.terrainBoundsInMeters(
      _boundsOftheActualMap._nw.lat,
      _boundsOftheActualMap._nw.lat,
      _boundsOftheActualMap._nw.lng,
      latLon1.lng,
    );
    let x1Original = DisplayOperation.getOriginalDimension(x1, "meters");

    let y1 = terrainGeneration.terrainBoundsInMeters(
      _boundsOftheActualMap._nw.lat,
      latLon1.lat,
      _boundsOftheActualMap._nw.lng,
      _boundsOftheActualMap._nw.lng
    );
    let y1Original = DisplayOperation.getOriginalDimension(y1, "meters");

    let x2 = terrainGeneration.terrainBoundsInMeters(
      _boundsOftheActualMap._nw.lat,
      _boundsOftheActualMap._nw.lat,
      _boundsOftheActualMap._nw.lng,
      latLon2.lng,
    );
    let x2Original = DisplayOperation.getOriginalDimension(x2, "meters");

    let y2 = terrainGeneration.terrainBoundsInMeters(
      _boundsOftheActualMap._nw.lat,
      latLon2.lat,
      _boundsOftheActualMap._nw.lng,
      _boundsOftheActualMap._nw.lng
    );
    let y2Original = DisplayOperation.getOriginalDimension(y2, "meters");

    //Actual Points from Lat Lon
    let _originalPoint1 = new BABYLON.Vector3(x1Original, 11.811023712158203, -y1Original);
    let _originalPoint2 = new BABYLON.Vector3(x2Original, 11.811023712158203, -y2Original);

    // const box1 = BABYLON.MeshBuilder.CreateBox("box", {}, store.scene);
    // box1.position = pointClicked1;
    // const box2 = BABYLON.MeshBuilder.CreateBox("box", {}, store.scene);
    // box2.position = pointClicked2;

    let edge1 = [pointClicked1, pointClicked2];
    let edge2 = [_originalPoint1, _originalPoint2];

    let _angleBetweenTheCurrentEdgeAndTheEdgeItsSupposedToBe = _angleBetweenEdgesInRadians(edge1, edge2);
    // console.log("angle1", _angleBetweenTheCurrentEdgeAndTheEdgeItsSupposedToBe * 180/Math.PI);

    let _transformNode = new BABYLON.TransformNode("transformNode", store.scene);

    _transformNode.position = _originalPoint1.clone();
    _transformNode.rotationQuaternion = BABYLON.Quaternion.Identity();
    _terrainMesh.setParent(_transformNode);
    _transformNode.rotate(BABYLON.Axis.Y, _angleBetweenTheCurrentEdgeAndTheEdgeItsSupposedToBe, BABYLON.Space.WORLD);
    _transformNode.computeWorldMatrix(true);
    _terrainMesh.setParent(null);
    _terrainMesh.computeWorldMatrix(true);

    let _rotationQuaternionAfter = _terrainMesh.absoluteRotationQuaternion?.asArray();

    //Translate To the position.
    _terrainMesh.position.x -= _originalPoint1.x - pointClicked1._x;
    _terrainMesh.position.z -= _originalPoint1.z - pointClicked1._z;
    _terrainMesh.computeWorldMatrix(true);
    let _positionAfter = _terrainMesh.position.asArray();
    let _movementAmount = BABYLON.Vector3.FromArray(_positionAfter).subtract(BABYLON.Vector3.FromArray(_positionBefore)).asArray();

    _executeEvent();

  }

  function _handleInaccuraciesInTerrainDataDuringReload(){
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = 'removeTerrainInaccuracies - ' + makeid(5);
    saveData.data.identifier = {
      floorkey: store.floorkey,
      structure_id: store.activeLayer.structure_id,
      storey: 1
    };
    saveData.data.saveType = "removeTerrainInaccuracies";
    saveData.data.beforeOperationData = store.residualTerrainLayers;
    saveData.data.afterOperationData = {}
    store.residualTerrainLayerExists = false;

    AutoSave.directPublish(saveData);
  }

  return {
    init,
    map: map,
    updateCenterAndZoomOfTerrain: _updateCenterAndZoomOfTerrain,
    getHeightMapTile: _getHeightMapTile,
    getHeightMapGround: _getHeightMapGround,
    terrainBoundsInMeters: _boundsInMeters,
    createTerrain: createTerrain,
    deleteTerrain: deleteTerrain,
    handleHeightMapToggle: _handleHeightMapToggle,
    enableOrDisableHeightMap: _enableOrDisableHeightMap,
    handleTerrainDeletionFromLayersUI: _handleTerrainDeletionFromLayersUI,
    _handleCollaborationOfTerrainDeletion,
    executeFromSaveData: _executeFromSaveData,
    unExecuteFromSaveData: _unexecuteFromSaveData,
    alignTerrainMapWithInGivenLatLon: _alignTerrainMapWithInGivenLatLon,
    coordinatesGeocoder: _coordinatesGeocoder,
    storeLastSearchedLocation: _storeLastSearchedLocation,
    moveMapToLastKnownLocation: _moveMapToLastKnownLocation,
    boundsOfTheTerrainAsAnArray: _boundsOfTheTerrainAsAnArray,
    handleInaccuraciesInTerrainDataDuringReload: _handleInaccuraciesInTerrainDataDuringReload,
    allTerrainMapsOnTheScene: _allTerrainMapsOnTheScene,
    getBoundsOfTheTerrainMap
  };
})();

//Set DATUM
let moveTerrain = function (data) {
  if (!data) {
    data = this.data;
  }
  let mesh = store.scene.getMeshByUniqueID(data.uniqueId);

  if (data.isCollabCall) {
    mesh.position = data.point;
  } else {
    mesh.position.y -= data.point.y;
  }

  let layerData = StructureCollection.getInstance()
    .getStructureById(data.structure)
    .getStoreyData()
    .getStoreyByValue(data.storey).layerData;
  let layer = layerData.getLayerByName(data.layerName, data.storey);

  layer.terrain[0].parameters.terrainPosition = mesh.position.asArray();
  if (layer.heightMapToggle) {
    layer.terrain[0].parameters.currentYPosition = mesh.position.y;
  }
};

let undoMoveTerrain = function (data) {
  if (!data) {
    data = this.data;
  }
  let mesh = store.scene.getMeshByUniqueID(data.uniqueId);
  if (data.isCollabCall) {
    mesh.position = data.point;
  } else {
    mesh.position.y += data.point.y;
  }

  let layerData = StructureCollection.getInstance()
    .getStructureById(data.structure)
    .getStoreyData()
    .getStoreyByValue(data.storey).layerData;
  let layer = layerData.getLayerByName(data.layerName, data.storey);
  layer.terrain[0].parameters.terrainPosition = mesh.position.asArray();
  if (layer.heightMapToggle) {
    layer.terrain[0].parameters.currentYPosition = mesh.position.y;
  }
};

let _handleCollaborationForSetStoreyHeight = function () {
  let _executeFromSaveData = function (saveData) {
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    let data = {
      structure: identifier.structure_id,
      storey: identifier.storey,
      layerName: identifier.layerName,
      uniqueId: identifier.uniqueId,
      point: BABYLON.Vector3.FromArray(chosenData.point),
      isCollabCall: true,
    };
    moveTerrain(data);
  };

  let _unExecuteFromSaveData = function (saveData) {
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    let data = {
      structure: identifier.structure_id,
      storey: identifier.storey,
      layerName: identifier.layerName,
      uniqueId: identifier.uniqueId,
      point: BABYLON.Vector3.FromArray(beforeOperationData.point),
      isCollabCall: true,
    };
    undoMoveTerrain(data);
  };

  return {
    executeFromSaveData: _executeFromSaveData,
    unExecuteFromSaveData: _unExecuteFromSaveData,
  };
};

function onSetStoreyDown(e) {
  if (e && (e.which === 2 || e.button === 4)) {
    //middle mouse click.
    return true;
  }

  let pickInfo = store.scene.pick(
    store.scene.pointerX,
    store.scene.pointerY,
    function (mesh) {
      return mesh.name.includes("terrain");
    }
  );

  if (pickInfo.hit) {
    let mesh = pickInfo.pickedMesh;
    let pickedPoint = pickInfo.pickedPoint;
    let meshDS = mesh.getSnaptrudeDS();

    let _moveTerrain = function () {
      moveTerrain(this.data);
    };

    let _undoMoveTerrain = function () {
      undoMoveTerrain(this.data);
    };

    let _getCommandLogic = function () {
      return {
        execute: _moveTerrain,
        unexecute: _undoMoveTerrain,
      };
    };

    let _saveData = function () {
      let saveData = AutoSave.getSaveDataPrototype();
      let layerData = StructureCollection.getInstance()
        .getStructureById(this.data.structure)
        .getStoreyData()
        .getStoreyByValue(this.data.storey).layerData;
      let layerId = layerData.getLayerByName(
        this.data.layerName,
        this.data.storey
      ).id;

      saveData.commandId = this.id;
      saveData.data.saveType = "setTerrainHeight";
      saveData.data.identifier = {
        structure_id: this.data.structure,
        storey: this.data.storey,
        layerName: this.data.layerName,
        uniqueId: this.data.uniqueId,
        floorkey: store.floorkey,
        id: layerId,
      };

      let dataAfter = {
        point: this.data.meshPosition.asArray(),
      };

      saveData.data.afterOperationData = dataAfter;
      return saveData;
    };

    let _executeEvent = function () {
      let data = {
        uniqueId: mesh.uniqueId,
        point: pickedPoint,
        meshPosition: mesh.position,
        structure: meshDS.structure_id,
        storey: meshDS.storey,
        layerName: mesh.layerName,
      };
      let cmd = new Command(
        "setTerrainHeight",
        data,
        _getCommandLogic(),
        _saveData
      );
      CommandManager.execute(cmd, true);
    };

    _executeEvent();
  }
}

export {
  terrainGeneration,
  moveTerrain,
  undoMoveTerrain,
  _handleCollaborationForSetStoreyHeight,
  onSetStoreyDown,
};
