import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";
import Button from "../Button";
import CloseButton from "../../assets/icons/close.svg";
import { colors } from "../../themes/constant";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc'
import ScrollableDropdown from '../ScrollableDropdown'

// Cove tool related imports
import CoveHelper from "../../snaptrude/modules/covetool"
import API from '../../services/covetool.service'
import { Action, getCoveAnalysisState } from "../../snaptrude/stateManagers/reducers/objectProperties/coveAnalysisSlice";
import { fetchAndSaveDefaultSettings, fetchAndSaveEnergyCodes, fetchLatestAnalysis } from "../../snaptrude/modules/covetool/utils";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";

// static data
const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

/**
 * Daylight Analysis Modal
 * @param {{
 *   title: string,
 *   isOpen: boolean,
 *   onClose: function,
 *   openTopographyModal: function
 * }} props
 * @returns {JSX.Element}
 */
const DaylightAnalysisSettingModal = ({ isOpen, onClose, openTopographyModal }) => {
  const { BuildingType } = CoveHelper
  const dispatch = useDispatch()

  // Route related
  const history = useHistory()

  // global states
  const coveAnalysisState = useSelector(getCoveAnalysisState);
  const { isCoveToolProject } = useSelector(state => state.projectMetadata)

  // loaders
  const { fetchingSettings, fetchingEnergyCodes, fetchingAnalysis } = coveAnalysisState

  // data
  const { projectSettings, allEnergyCodes, newRecordsTable, analysisTable, infoModal } = coveAnalysisState
  
  const { location } = projectSettings

  // holds sustainability data
  const analysis = analysisTable[store.floorkey]

  // holds data of completion modal
  const newRecords = newRecordsTable[store.floorkey]

  // loader for saving settings
  const [loading, setLoading] = useState(false)
  const [selectedEnergyCode, changeSelectedEnergyCode] = useState(1)
  const [selectedEnergyCodeName, changeSelectedEnergyCodeName] = useState('')
  const [selectedBuildingTypes, changeSelectedBuildingTypes] = useState([])

  const [nonResidentialBuildingTypeSelected, setNonResidentialBuildingTypeSelected] = useState([])

  // load settings in local state
  useEffect(() => {
    if(!isOpen) return

    if(projectSettings) {
      // daylight analysis settings
      let { energyCode, energyCodeName, buildingTypes } = projectSettings
      buildingTypes= buildingTypes?.slice(0, 1)

      changeSelectedEnergyCode(energyCode)
      changeSelectedEnergyCodeName(energyCodeName)
      changeSelectedBuildingTypes(buildingTypes)
      setNonResidentialBuildingTypeSelected(buildingTypes)
    }
  }, [isOpen, projectSettings])
  
  // start fetching analysis
  useEffect(() => {
    if(analysis || isCoveToolProject || isCoveToolProject == null) return

    fetchLatestAnalysis()
  }, [])

  // renders daylight analysis based on given search param
  useEffect(() => {
    if(fetchingAnalysis || !isCoveToolProject) return

    const params = new URLSearchParams(history.location.search)
    const type = params.get('daylight')
    if(type == "SDA") CoveHelper.renderDaylightAnalysis(store.daylightAnalysis?.data)
    else CoveHelper.renderDaylightAnalysis(store.daylightAnalysis?.data, false)

  }, [history.location.search, fetchingAnalysis])

  // fetch all energy codes
  useEffect(() => {
    if(isCoveToolProject == false) {
      if(location?.lat && location?.lng) fetchAndSaveEnergyCodes(location)
      else fetchAndSaveEnergyCodes()
    }
  }, [isCoveToolProject, location?.lat])

  // fetch default settings
  useEffect(() => {
    if(isCoveToolProject == false) {
      fetchAndSaveDefaultSettings()
    }
  }, [isCoveToolProject])

  useEffect(() => {
    if(selectedEnergyCode == "") {
      if(Array.isArray(allEnergyCodes) && allEnergyCodes.length) {
        const item = allEnergyCodes.find((item) => item.id == 1)
        changeSelectedEnergyCode(1)
        changeSelectedEnergyCodeName(item.name)
      }
    }
  }, [allEnergyCodes])

  // handle energy code select
  const handleEnergyCodeChange = (energyCodeName) => {
    const enerygCode = allEnergyCodes?.find(item => item.name == energyCodeName)

    changeSelectedEnergyCode(enerygCode.id)
    changeSelectedEnergyCodeName(energyCodeName)

    changeSelectedBuildingTypes((prev) => {
      let updatedbuildingTypes = prev

      if(allowSingleFamilyHome(energyCodeName)) {
        updatedbuildingTypes = ["Single Family Home"]
      } else {
        updatedbuildingTypes = nonResidentialBuildingTypeSelected
      }
      return updatedbuildingTypes
    })
  }

  // handle building type select
  const handleBuildingTypeSelect = (type, energyCodeName) => {
    if(!allowSingleFamilyHome(energyCodeName)) {
      setNonResidentialBuildingTypeSelected([type])
    }
    changeSelectedBuildingTypes([type])
  }

  const save = async (close = true, toast = true) => {
    try {
      if(!store.floorkey) {
        console.log("Save default settings - no floor key", store.floorkey)
        return
      }

      if(selectedBuildingTypes.length == 0) {
        showToast("Please select at least one building type!", 3000, TOAST_TYPES.error)
        return
      }

      setLoading(true)

      const _locationString = (() => {
        if(location && location?.lat && location?.lng) {
          return location?.lat + ',' + location?.lng
        }
      })()

      const success = await API.saveSettings(store.floorkey, selectedEnergyCode, selectedEnergyCodeName, selectedBuildingTypes, _locationString)

      dispatch(Action.updateSettings({
        energyCode: selectedEnergyCode,
        energyCodeName: selectedEnergyCodeName,
        buildingTypes: selectedBuildingTypes,
        location
      }))

      if(toast && success) showToast("Daylight analysis settings has been saved successfully!", 3000, TOAST_TYPES.success)
      setLoading(false)

      // turn off calculation
      store.daylightAnalysis.projectSettings.calculationOn = false

      if(close) onClose()
    } catch(err) {
      console.log(err)
      setLoading(false)
    }
  }

  const saveButtonDisabled = loading || fetchingSettings || fetchingEnergyCodes || selectedBuildingTypes.length == 0

  const energyCodeOptions = useMemo(() => {
    if(Array.isArray(allEnergyCodes)) {
      return allEnergyCodes.map(item => item.name)
    }

    return []
  }, [allEnergyCodes])

  return (
    <>
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">Daylight Analysis Settings</Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          {
            fetchingSettings ?
            <h4 style={{ padding: "10px 20px" }}>Getting information...
            </h4>:
            <BodyContainer>
            {
              fetchingEnergyCodes ? <h3>Loading...</h3> :
              <>
                <h3>Energy Code:</h3>
                {
                  fetchingEnergyCodes ? <p>Getting energy codes...</p> : Array.isArray(allEnergyCodes) ?
                  <div style={{ width: '50%'}}>
                    <ScrollableDropdown
                      options={energyCodeOptions}
                      height="10em"
                      width="100%"
                      onChange={!loading ? handleEnergyCodeChange : () => {}}
                      selectedOption={selectedEnergyCodeName || ''}
                      cutomTextStyle={{ paddingLeft: 0 }}
                      autoClose
                    />
                  </div>
                  // (<select value={selectedEnergyCode || ""} onChange={!loading ? handleEnergyCodeChange : () => {}} disabled={false}>
                  //   { allEnergyCodes.map(code => <option key={code.id} value={code.id} data-name={code.name}>{code.name}</option>) }
                  // </select>)
                  : <div onClick={fetchAndSaveEnergyCodes}>Get energy codes</div>
                }
                <h3>Building Type:</h3>
                <BuildingTypesGird>
                  {
                    BuildingType.getAll().map(type => (
                      <div key={type}>
                        <input
                          id={type}
                          type="checkbox"
                          name={type}
                          value={type}
                          checked={selectedBuildingTypes?.includes(type)}
                          disabled={BuildingType.disableBuildingTypeOption(type, selectedEnergyCodeName, selectedBuildingTypes)}
                          onChange={() => {
                            if(loading) return
                            if(BuildingType.isSelectableBuildingType(type, selectedEnergyCodeName, selectedBuildingTypes)) {
                              handleBuildingTypeSelect(type, selectedEnergyCodeName)
                            }
                          }}
                        />
                        <label htmlFor={type}>{type}</label>
                      </div>
                    ))
                  }
                </BuildingTypesGird>
                <h3>Location:</h3>
                {
                  location && location.lat && location.lng ? (
                    <>
                      <div>Latitude: {location.lat}</div>
                      <div>Longitude: {location.lng}</div>
                    </>
                  ) : (
                    <div style={{ color: "blue" }} onClick={openTopographyModal}>Load topography</div>
                  )
                }
              </>
            }
            </BodyContainer>
          }
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button
              onPress={onClose}
              style={{
                backgroundColor: colors.transparent,
                color: colors.dullgrey,
              }}
              title="Close"
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              primary
              title={loading ? "Saving" : "Save"}
              onPress={save}
              disabled={saveButtonDisabled}
              style={{ backgroundColor: saveButtonDisabled ? colors.lightGrey : colors.primary }}
              isLoading={loading}
            />
          </ButtonWrapper>
        </ModalFooter>
        </>
      </Card>
    </ReactModal>
    {
      (Array.isArray(newRecords) && newRecords.length > 0) && 
      newRecords.map((record, idx) => <CompletionModal key={idx} index={idx} record={record} />)
    }
    <InfoModal isOpen={infoModal.open} title={infoModal.title} body={infoModal.body} />
    </>
  )
}

export default DaylightAnalysisSettingModal

const allowSingleFamilyHome = (energyCodeName) => {
  if(String(energyCodeName).includes("Home") || String(energyCodeName).includes("Residential")) return true

  return false
}

/**
 * Modal for showing completed analysis
 * @param {*}  
 * @returns 
 */
const CompletionModal = ({ record }) => {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(Action.removeNewRecord({ floor_key: store.floorkey }))
  }

  const open = (sda = true) => () => {
    window.open(`/model/${record.floor_key}?daylight=${sda ? 'SDA' : 'ASE'}`, '_blank', 'noopener,noreferrer')
    close()
  }

  return (
    <ReactModal
      ariaHideApp={false}
      onRequestClose={close}
      style={modalStylesDefault}
      isOpen
    >
      <Card>
        <>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Daylight Analysis has been completed!
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={close} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
            <ul>
              <li><strong>Run on:</strong> {new Date(record?.added).toLocaleString()}</li>
              <li><strong>Energy Code Name:</strong> {record?.details?.energy_code_name}</li>
              <li><strong>Building Types</strong>: {record?.details?.building_types?.replaceAll(',', ', ')}</li>
              <li><strong>Location:</strong> {record?.details?.location?.replaceAll(',', ', ')}</li>
            </ul>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button
              primary
              title="View SDA"
              onPress={open(true)}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              primary
              title="View ASE"
              onPress={open(false)}
            />
          </ButtonWrapper>
        </ModalFooter>
        </>
      </Card>
    </ReactModal>
  )
}


/**
 * Modal for showing info
 * @param {*}  
 * @returns 
 */
const InfoModal = ({ isOpen, title, body }) => {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(Action.closeInfoModal())
  }

  return (
    <ReactModal
      ariaHideApp={false}
      onRequestClose={close}
      style={modalStylesDefault}
      isOpen={isOpen}
    >
      <Card>
        <>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold"> {title} </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={close} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          <BodyContainer> { body } </BodyContainer>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button
              primary
              title="Close"
              onPress={close}
            />
          </ButtonWrapper>
        </ModalFooter>
        </>
      </Card>
    </ReactModal>
  )
}



/* styled components */
const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`;

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 0.1em 0em;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
  min-height: 200px;

  li {
    padding: 13px 0;
  }
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const BodyContainer = styled.div`
  padding: 0 1em;
  padding-bottom: 1em;
`;

const BuildingTypesGird = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1em;

  &> div {
    display: flex;
    align-items: center;
  }
`;
