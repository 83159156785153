'use strict';

import { drawingOperator } from "../../libs/drawingEvents";
import { isPointInsideTheMesh } from "../extrafunc";
import Grid from "./Grid";

const roomLayout = (function (){
  
  let grid;
  
  let originXInit = 2000;
  let originZInit = -2000;
  let unitSizeX = 500;
  let unitSizeZ = -500;
  
  let originX = originXInit;
  let originZ = originZInit;
  
  let debugMode = false;
  
  const generatedMasses = [];
  
  const _getCorrespondingPoint = function (node){
    return [
      originX + node.j * unitSizeX,
      originZ + node.i * unitSizeZ,
    ];
  };
  
  const _isNodeWithinAMass = function (node){
    const point = _getCorrespondingPoint(node);
    const v3 = drawingOperator.convertProjectUnitPointsToV3([point])[0];
    
    let nodeIsWithin = false;
    generatedMasses.some(mass => {
      nodeIsWithin = isPointInsideTheMesh(v3, mass.mesh);
      return nodeIsWithin;
    });
    
    return nodeIsWithin;
  };
  
  const lay = function (origin, unitSize = 500, nRows = 10, nColumns = 10){
    
    unitSizeX = unitSize;
    unitSizeZ = -unitSize;
    
    if (origin){
      originX = origin[0];
      originZ = origin[1];
    }
    
    generatedMasses.length = 0;
    grid = new Grid(nRows, nColumns);
    
    let swept = true;
    while (swept){
      if (isInDebugMode()) grid.draw();
      const boundaryNodes = grid.sweep();
      if (boundaryNodes){
        swept = true;
        
        const arrayOfPoints = boundaryNodes.map(_getCorrespondingPoint);
        const mesh = drawingOperator.createFromPoints(arrayOfPoints);
        if (mesh) generatedMasses.push(mesh.getSnaptrudeDS());
      }
      else swept = false;
    }
    
    originX += (nColumns + 5) * unitSizeX;
    if (originX > 20 * originXInit) {
      originX = originXInit;
      originZ += (nRows + 5) * unitSizeZ;
    }
  };
  
  const getGrid = function (){
    return grid;
  };
  
  const debugOn = function (){
    debugMode = true;
  };
  
  const isInDebugMode = function (){
    return debugMode;
  };
  
  const debugOff = function (){
    debugMode = false;
  };
  
  return {
    lay,
    getGrid,
    debugOn,
    debugOff,
    isInDebugMode
  };
  
})();

export default roomLayout;
