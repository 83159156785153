import BABYLON from "../../babylonDS.module.js";
import _ from "lodash";
import { store } from "../../utilityFunctions/Store.js";
import {
  convertGlobalVector3ToLocal,
  convertLocalVector3ToGlobal,
  areComponentsSiblings,
  getIndicesOfPointInVerData,
  areThreeVectorsCollinear,
  getBabylonGUIElementByName,
  disposeMesh,
  onSolid,
} from "../../extrafunc.js";
import {
  getComponentsLinkedToEdge,
  handleRoofPropertyChangeCommand,
  updateMeshFacetData,
  getCorrespondingGlobalPointOfOtherInstance,
  showAxisLine,
  disposeSnapToObjects,
  disposeAllAxisLines, isEdgeVerticalLike,
} from "./moveUtil.js";
import { virtualSketcher } from "../../sketchMassBIMIntegration/virtualSketcher.js";
import { isTwoDimension } from "../../../libs/twoDimension.js";
import {
  getLowerVertexOfTheEdge,
  getVertexNeighbours,
  getFaceVerticesFromFacetID,
  updateBrepPositions,
} from "../../../libs/brepOperations.js";
import { pseudoEdgeRenderer } from "./pseudoEdgeRenderer.js";
import { constraintSolver } from "./constraintSolver.js";
import { commandUtils } from "../../commandManager/CommandUtils.js";
import { delayedExecutionEngine } from "../../utilityFunctions/delayedExecution.js";
import { getMeshMedianY } from "../../../libs/mathFuncs.js";
import { StoreyMutation } from "../../storeyEngine/storeyMutations.js";
import { CommandManager } from "../../commandManager/CommandManager.js";
import {
  getRefGroundPoint,
  snapOnGivenLine,
  isFloatEqual, getAngleBetweenVectors,
} from "../../../libs/snapFuncs.js";
import { ResolveEngineUtils } from "../../wallEngine/resolveEngine.js";
import { snapHorizontallyAndVertically } from "../../../libs/snapFuncsSecondary.js";
import { GLOBAL_CONSTANTS } from "../../utilityFunctions/globalConstants.js";
import { lineParallelToWhichAxis } from "../../../libs/snapUtilities.js";
import { externalUtil } from "../../externalUtil.js";
import { findPrioritizedSnapPoint } from "../../../libs/snapFuncsPrimary.js";
import { uiIndicatorsHandler } from "../../uiIndicatorOperations/uiIndicatorsHandler.js";
import { DisplayOperation } from "../../displayOperations/displayOperation.js";
import { moveOperator } from "./moveOperation.js";
import { setLayerTransperancy } from "../../../libs/sceneFuncs.js";
const moveEdge = (function () {
  let _centreOfSelectedEdge = null;
  let _selectedEdgeWorld = null;
  let _meshOfInterest = null;
  let _componentOfInterest = null;

  const _componentsOfInterest = [];
  let _level0AndLevel1InstanceComponents = [];
  const _meshesOfInterest = [];

  let _componentVertexIndicesMapping = {};

  let _edgeSelected = false;

  let _edgeStartingPositionOnMove = null;
  let _startingPointOnEdge = null;
  let _edgeIndicatorInitialPosition = null;
  let _topFaceVertices = null;
  let _tertiarySnapEdgeArray = [];

  let _vertexNeighbours = []; //2D vertex edit scenario

  let _moveEdgeCommandData = null;
  let _propertyChangeCommandData = null;
  let _meshEditCheckValue = null;

  let _vertexWMReferenceComponentMap = new Map();
  let _componentVerticesBeingMovedMap = new Map();

  let _allVerticesBeingMoved = [];

  const _CONSTANTS = {
    propertyChangeCommandName: "moveEdgePropertyChange",
  };

  /*
    https://imgur.com/a/iKDumKH

    Looks for and handles components only till level 2
    In the image, junction between m and l will move and that between m and n will not move

     */
  const _handleSimultaneousMultiEdgeEdit = function () {
    const _handleSetOfInstances = function (
      instanceComponents,
      handleNextLevelInstanceComponents
    ) {
      instanceComponents = _.uniqBy(
        instanceComponents,
        (ic) => ic.mesh.sourceMesh
      );

      _level0AndLevel1InstanceComponents.push(...instanceComponents);
      const nextLevelInstanceComponents = [];

      for (let instanceComponent of instanceComponents) {
        const instanceLocalVerticesBeingMoved = _componentVerticesBeingMovedMap
          .get(_getCorrespondingFirstStageInstanceComponent(instanceComponent))
          .map((v) => convertGlobalVector3ToLocal(v, instanceComponent.mesh));

        const allInstances = instanceComponent.mesh.sourceMesh.instances;
        allInstances.forEach((currentInstanceMesh) => {
          if (currentInstanceMesh === instanceComponent.mesh) return;
          const currentInstanceComponent = currentInstanceMesh.getSnaptrudeDS();

          const currentInstanceGlobalVertices =
            instanceLocalVerticesBeingMoved.map((v) =>
              convertLocalVector3ToGlobal(v, currentInstanceMesh)
            );

          const edge = {
            headPt: currentInstanceGlobalVertices[0],
            tailPt: currentInstanceGlobalVertices[1],
          };

          const options = {
            ignoreStoreyCheck: true,
            excludeActual: true,
          };

          let componentsLinkedToEdge = getComponentsLinkedToEdge(
            edge,
            currentInstanceComponent,
            options
          );
          const componentsAlreadyConsidered = Array.from(
            _componentVerticesBeingMovedMap.keys()
          );

          componentsLinkedToEdge = _.differenceWith(
            componentsLinkedToEdge,
            componentsAlreadyConsidered,
            areComponentsSiblings
          );

          const instanceLinkedComponents = componentsLinkedToEdge.filter(
            (c) => c.mesh.isAnInstance
          );
          nextLevelInstanceComponents.push(...instanceLinkedComponents);

          componentsLinkedToEdge.forEach((c) => {
            _addEdgePointToMove(edge.headPt, c, currentInstanceComponent);
            _addEdgePointToMove(edge.tailPt, c, currentInstanceComponent);
          });

          const optionsForGraphLookup = {
            includeEdgePoints: true,
          };

          const nodesAlongEdge = virtualSketcher.structuralFindNodesAlongEdge(
            ...currentInstanceGlobalVertices,
            optionsForGraphLookup
          );
  
          if (isEdgeVerticalLike(edge)){
            if (!_.isEmpty(nodesAlongEdge)) {
              // balconies, decks, water bodies
      
              nodesAlongEdge.forEach((node) => {
                node.components.forEach((c) => {
                  if (c.storey !== currentInstanceComponent.storey) return;
                  if (c === currentInstanceComponent) return;
          
                  if (c.mesh.isAnInstance) {
                    const correspondingComponent =
                      _getCorrespondingFirstStageInstanceComponent(c);
                    if (correspondingComponent) {
                      // do nothing, component already added for edit
                    } else {
                      nextLevelInstanceComponents.push(c);
                      _addEdgePointToMove(
                        node.vector,
                        c,
                        currentInstanceComponent
                      );
                    }
                  } else {
                    _addEdgePointToMove(node.vector, c, currentInstanceComponent);
                  }
                });
              });
            }
          }
        });
      }

      if (handleNextLevelInstanceComponents) {
        _handleSetOfInstances(nextLevelInstanceComponents, false);
      }
    };

    const componentsOfInterestAsOfNow = Array.from(
      _componentVerticesBeingMovedMap.keys()
    );

    const level0InstanceComponents = componentsOfInterestAsOfNow.filter(
      (c) => c.mesh.isAnInstance
    );
    const level0NonInstanceComponents = componentsOfInterestAsOfNow.filter(
      (c) => !c.mesh.isAnInstance
    );

    _handleSetOfInstances(level0InstanceComponents, true);
  };

  const _addEdgePointToMove = function (vertex, component, componentToReferTo) {
    // to verify if 'that' vertex of 'that' component has already been accounted
    // to prevent double movement, once through each instance
    if (component.mesh.isAnInstance) {
      const componentsAlreadyConsideredForMove = Array.from(
        _componentVerticesBeingMovedMap.keys()
      );
      const allSiblingInstances = component.mesh.sourceMesh.instances;

      // if a sibling mesh of the instance component is already present, do not add current points
      const commonElements = _.intersection(
        componentsAlreadyConsideredForMove.map((c) => c.mesh),
        allSiblingInstances
      );
      if (!_.isEmpty(commonElements)) {
        const existingSiblingInstance = commonElements[0];
        const existingSiblingComponent =
          existingSiblingInstance.getSnaptrudeDS();
        // there should only be one

        const verticesBeingMoved = _componentVerticesBeingMovedMap.get(
          existingSiblingComponent
        );
        const localVerticesBeingMoved = verticesBeingMoved.map((v) =>
          convertGlobalVector3ToLocal(v, existingSiblingInstance)
        );

        const currentLocalVertex = convertGlobalVector3ToLocal(
          vertex,
          component.mesh
        );

        if (
          localVerticesBeingMoved.inArray((v) =>
            v.almostEquals(currentLocalVertex)
          )
        ) {
          return;
        }
      }
    }

    _allVerticesBeingMoved.pushIfNotExist(vertex.clone(), (v) =>
      v.almostEquals(vertex)
    );
    _vertexWMReferenceComponentMap.set(vertex, componentToReferTo);

    let verticesMoved = _componentVerticesBeingMovedMap.get(component);
    if (!verticesMoved) {
      verticesMoved = [];
      _componentVerticesBeingMovedMap.set(component, verticesMoved);
    }

    verticesMoved.pushIfNotExist(vertex, (v) => v.almostEquals(vertex));
    // function should never be called for adding duplicate entry
    // just for safety
  };

  const _populateComponentsOfInterest = function () {
    _componentsOfInterest.push(
      ...Array.from(_componentVerticesBeingMovedMap.keys())
    );
  };

  function setInitialData(data) {
    const storeEdgeIndices = function () {
      _componentsOfInterest.forEach((c) => {
        const edgeCoordIndices = [];

        // if _edgePointsBeingMoved has points not belonging to component,
        // indices will be empty, so doesn't matter

        const verticesBeingMoved = _componentVerticesBeingMovedMap.get(c);
        verticesBeingMoved.forEach((vertex) => {
          const indicesArray = getIndicesOfPointInVerData(
            vertex,
            c,
            BABYLON.Space.WORLD
          );
          const wmComponentReference =
            _vertexWMReferenceComponentMap.get(vertex);
          indicesArray.forEach(
            (indices) => (indices.wmComponentReference = wmComponentReference)
          );
          edgeCoordIndices.push(...indicesArray);
        });

        _componentVertexIndicesMapping[c.id] = edgeCoordIndices;
      });
    };
    
    resetData();

    _meshOfInterest = data.mesh;
    _componentOfInterest = _meshOfInterest.getSnaptrudeDS();

    if (store.$scope.isTwoDimension) {
      let upperVertex = data.vertex;

      let options = { facetId: data.facetId, faceId: NaN };
      let lowerVertex = getLowerVertexOfTheEdge(
        upperVertex,
        _meshOfInterest,
        options,
        BABYLON.Space.WORLD
      );
      if (!lowerVertex) {
        console.warn("Lower vertex not determined");
        return;
      }

      data.edge = {};
      data.edge.headPt = upperVertex;
      data.edge.tailPt = lowerVertex;
    }

    _selectedEdgeWorld = data.edge;

    if (virtualSketcher.util.isComponentPlanar(_componentOfInterest)) {
      _addEdgePointToMove(_selectedEdgeWorld.headPt, _componentOfInterest);
      if (!store.$scope.isTwoDimension)
        _addEdgePointToMove(_selectedEdgeWorld.tailPt, _componentOfInterest);
    } else {
      const componentsLinkedToEdge = getComponentsLinkedToEdge(
        data.edge,
        _componentOfInterest
      );

      componentsLinkedToEdge.forEach((c) => {
        _addEdgePointToMove(_selectedEdgeWorld.headPt, c);
        _addEdgePointToMove(_selectedEdgeWorld.tailPt, c);
      });
  
      if (isEdgeVerticalLike(_selectedEdgeWorld)){
        const optionsForGraphLookup = {
          includeEdgePoints: true,
        };
    
        const nodesAlongEdge = virtualSketcher.structuralFindNodesAlongEdge(
          data.edge.headPt,
          data.edge.tailPt,
          optionsForGraphLookup
        );
    
        if (!_.isEmpty(nodesAlongEdge)) {
          // balconies, decks, water bodies
      
          nodesAlongEdge.forEach((node) => {
            node.components.forEach((c) => {
              if (c.storey !== _componentOfInterest.storey) return;
              if (c === _componentOfInterest) return;
              if (areComponentsSiblings(c, _componentOfInterest)) return;
          
              _addEdgePointToMove(node.vector, c);
            });
          });
        }
      }
    }

    _handleSimultaneousMultiEdgeEdit();
    _populateComponentsOfInterest();

    pseudoEdgeRenderer.initialize(_componentsOfInterest);
    constraintSolver.initialize(_componentsOfInterest);

    _meshesOfInterest.push(..._componentsOfInterest.map((c) => c.mesh));

    _centreOfSelectedEdge = BABYLON.Vector3.Center(
      _selectedEdgeWorld.headPt,
      _selectedEdgeWorld.tailPt
    );

    _startingPointOnEdge = data.projection.clone();
    //flat masses
    if (_selectedEdgeWorld.headPt.equals(_selectedEdgeWorld.tailPt))
      _startingPointOnEdge = _selectedEdgeWorld.headPt;

    _edgeIndicatorInitialPosition = BABYLON.Vector3.Center(
      _selectedEdgeWorld.headPt,
      _selectedEdgeWorld.tailPt
    );
    _edgeSelected = true;

    _edgeStartingPositionOnMove = _startingPointOnEdge;

    if (store.$scope.isTwoDimension) {
      //headPt is the point above
      _vertexNeighbours = getVertexNeighbours(
        _selectedEdgeWorld.headPt,
        _meshOfInterest,
        BABYLON.Space.WORLD
      );
      _topFaceVertices = getFaceVerticesFromFacetID(
        data.facetId,
        _meshOfInterest,
        BABYLON.Space.WORLD
      );

      for (let i = 0; i < _topFaceVertices.length - 1; i++) {
        let v1 = _topFaceVertices[i];
        let v2 = _topFaceVertices[i + 1];

        _tertiarySnapEdgeArray.push({
          headPt: v1,
          tailPt: v2,
        });
      }

      _tertiarySnapEdgeArray.push({
        headPt: _.last(_topFaceVertices),
        tailPt: _.first(_topFaceVertices),
      });
    }

    storeEdgeIndices();
    _moveEdgeCommandData =
      commandUtils.geometryChangeOperations.getCommandData(_meshesOfInterest);
    _meshEditCheckValue =
      _meshOfInterest.getBoundingInfo().boundingBox.extendSizeWorld.y;
    // newScene.activeCamera.detachControl(canvas);

    _propertyChangeCommandData = handleRoofPropertyChangeCommand(
      _componentsOfInterest
    );

    delayedExecutionEngine.addExecutable(_recordOperation);
  }

  function _recordOperation() {
    
    _postCompletionProcessing();
    
    let verData = _meshOfInterest.getVerticesData(
      BABYLON.VertexBuffer.PositionKind
    );
    _meshOfInterest.midY = getMeshMedianY(
      verData,
      _meshOfInterest.getWorldMatrix()
    );

    _meshesOfInterest.forEach((m) => {
      m.BrepToMesh();
      m.computeWorldMatrix(true);
      updateMeshFacetData(m);
    });

    StoreyMutation.assignStorey(_componentsOfInterest);

    let newMeshEditCheckValue =
      _meshOfInterest.getBoundingInfo().boundingBox.extendSizeWorld.y;
    if (_.round(_meshEditCheckValue, 4) !== _.round(newMeshEditCheckValue, 4)) {
      _componentsOfInterest.forEach(c => c.markAsEdited());
    }

    _moveEdgeCommandData = commandUtils.geometryChangeOperations.getCommandData(
      _meshesOfInterest,
      _moveEdgeCommandData
    );

    let moveEdgeCommand = commandUtils.geometryChangeOperations.getCommand(
      commandUtils.CONSTANTS.moveEdgeOperation,
      _moveEdgeCommandData
    );

    const integrationGeometryChangeCommand =
      virtualSketcher.updateWithGeometryEdit(_componentsOfInterest, true);
  
    const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
      [moveEdgeCommand, integrationGeometryChangeCommand]
    );

    const propertyChangeCommand = handleRoofPropertyChangeCommand(
      _componentsOfInterest,
      _propertyChangeCommandData
    );

    const commands = _.compact([commonGeometryChangeCommand, propertyChangeCommand]);
    const yets = commands.map((_) => false);

    CommandManager.execute(commands, yets);
    
    resetData();
  }

  const _getCorrespondingFirstStageInstanceComponent = function (
    instanceComponent
  ) {
    if (!instanceComponent.mesh.isAnInstance) return;

    let correspondingComponent;
    _level0AndLevel1InstanceComponents.some((c) => {
      if (
        c.mesh.isAnInstance &&
        c.mesh.sourceMesh === instanceComponent.mesh.sourceMesh
      ) {
        correspondingComponent = c;
        return true;
      }
    });

    return correspondingComponent;
  };

  function _moveEdge(toPosition, fromPosition, options = {}) {
    if (!_.isBoolean(options.checkConstraint)) options.checkConstraint = true;

    const movementAmount = toPosition.subtract(fromPosition);

    if (options.checkConstraint) {
      const componentMovementDataMap = new Map();

      _componentsOfInterest.forEach((component) => {
        
        const movementData = new Map();
        componentMovementDataMap.set(component, movementData);
        const edgeCoordIndices = _componentVertexIndicesMapping[component.id];

        for (let indices of edgeCoordIndices) {
          
          let fromPositionCopy = fromPosition.clone();
          let toPositionCopy = toPosition.clone();
          
          const wmComponentReference = indices.wmComponentReference;

          if (wmComponentReference) {
            const [fromPositionRefMeshGlobal, toPositionRefMeshGlobal] =
              getCorrespondingGlobalPointOfOtherInstance(
                [fromPositionCopy, toPositionCopy],
                _getCorrespondingFirstStageInstanceComponent(
                  wmComponentReference
                ),
                wmComponentReference
              );
            
            fromPositionCopy = fromPositionRefMeshGlobal;
            toPositionCopy = toPositionRefMeshGlobal;
          }
          
          movementData.set(fromPositionCopy, toPositionCopy);
          
        }
      });

      const isConstrained = constraintSolver.shouldConstrain(
        componentMovementDataMap,
        options
      );
      if (isConstrained) {
        if (options.correctedAlternatePoint) {
          toPosition.copyFrom(options.correctedAlternatePoint);
          options.movementCorrected = true;
        } else return false;
      }
    }

    _allVerticesBeingMoved.forEach((v) => {
      const vTo = v.add(movementAmount);
      v.copyFrom(vTo);
    });

    _componentsOfInterest.forEach((component) => {
      const brep = component.brep;
      const mesh = component.mesh;

      let oldPos = [];
      let newPos = [];

      var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

      const edgeCoordIndices = _componentVertexIndicesMapping[component.id];

      for (let indices of edgeCoordIndices) {
        let xIndex = indices[0];
        let yIndex = indices[1];
        let zIndex = indices[2];

        let fromPositionCopy = fromPosition.clone();
        let toPositionCopy = toPosition.clone();

        const wmComponentReference = indices.wmComponentReference;

        if (wmComponentReference) {
          const [fromPositionRefMeshGlobal, toPositionRefMeshGlobal] =
            getCorrespondingGlobalPointOfOtherInstance(
              [fromPositionCopy, toPositionCopy],
              _getCorrespondingFirstStageInstanceComponent(
                wmComponentReference
              ),
              wmComponentReference
            );

          fromPositionCopy = fromPositionRefMeshGlobal;
          toPositionCopy = toPositionRefMeshGlobal;
        }

        let movementVector = convertGlobalVector3ToLocal(
          toPositionCopy,
          mesh
        ).subtract(convertGlobalVector3ToLocal(fromPositionCopy, mesh));

        if (store.$scope.isTwoDimension) movementVector.y = 0;

        oldPos.push([verData[xIndex], verData[yIndex], verData[zIndex]]);
        verData[xIndex] += movementVector.x;
        verData[yIndex] += movementVector.y;
        verData[zIndex] += movementVector.z;
        newPos.push([verData[xIndex], verData[yIndex], verData[zIndex]]);
      }
      let subMeshes = [...mesh.subMeshes];

      let geometry = mesh.geometry || mesh.sourceMesh.geometry;
      geometry.setVerticesData(
        BABYLON.VertexBuffer.PositionKind,
        verData,
        true
      );

      mesh.subMeshes = subMeshes;

      updateBrepPositions(brep, oldPos, newPos);

      if (mesh.type.toLowerCase() === "roof")
        component.updateRoofOutline(oldPos, newPos);
    });

    return true;
  }

  function _getScenePosition() {
    function _determineAxisPointFor2DVertexEdit(vertex2D) {
      let snappedToAnAxisPoint = null;
      let snappedToBothAxesPoint = null;

      let groundPoint = getRefGroundPoint();
      let firstSnapNeighbour = null;
      let secondSnapNeighbour = null;

      let snappedToTwoAxes = false;
      
      const parallelSnapEnabled = store.projectProperties.properties.parallelSnapEnabled.getValue();
      const orthogonalSnapEnabled = store.projectProperties.properties.orthogonalSnapEnabled.getValue();
      
      let util = new ResolveEngineUtils();

      for (let i = 0; i < _topFaceVertices.length; i++) {
        let neighbour = _topFaceVertices[i];
        if (neighbour.equals(vertex2D)) continue;

        let snapPoint = null;
        let snapType = null;

        let orthogonalSnapPoint = snapHorizontallyAndVertically(
          groundPoint,
          neighbour
        );
        if (orthogonalSnapEnabled && orthogonalSnapPoint) {
          snapPoint = orthogonalSnapPoint.pt;
          snapType = GLOBAL_CONSTANTS.strings.snaps.orthogonal;
        } else if (parallelSnapEnabled) {
          
          let immediateNeighbour = false;
          _vertexNeighbours.some((vertex) => {
            if (vertex.equals(neighbour)) {
              immediateNeighbour = true;
              return true;
            }
          });
          if (!immediateNeighbour) continue;

          let pointOnTheEdgeTrajectory = snapOnGivenLine(
            groundPoint,
            vertex2D,
            neighbour
          );
          if (pointOnTheEdgeTrajectory) {
            snapPoint = pointOnTheEdgeTrajectory;
            snapType = GLOBAL_CONSTANTS.strings.snaps.parallel;
          } else {
            continue;
          }
        }
        else {
          continue;
        }

        if (areThreeVectorsCollinear(neighbour, vertex2D, snapPoint)) {
          let axis = lineParallelToWhichAxis(neighbour, snapPoint, {usePointerLocation: true});
          if (axis) snapType = axis;
        }

        if (snappedToAnAxisPoint) {
          if (
            util.onSegment3D(
              neighbour,
              firstSnapNeighbour,
              snappedToAnAxisPoint
            ) ||
            util.onSegment3D(
              firstSnapNeighbour,
              neighbour,
              snappedToAnAxisPoint
            ) ||
            util.onSegment3D(
              neighbour,
              snappedToAnAxisPoint,
              firstSnapNeighbour
            )
          ) {
            //this means the points were collinear and thus, if not avoided
            //it'll misbehave
            continue;
          }

          snappedToBothAxesPoint = externalUtil.getPointOfIntersection(
            [neighbour, snapPoint, firstSnapNeighbour, snappedToAnAxisPoint],
            { type: "vector-vector" }
          );

          if (snappedToBothAxesPoint) {
            secondSnapNeighbour = neighbour;

            showAxisLine(neighbour, snappedToBothAxesPoint, snapType, false);
            snappedToTwoAxes = true;
            break;
          }
        } else {
          firstSnapNeighbour = neighbour;
          snappedToAnAxisPoint = snapPoint;

          showAxisLine(neighbour, snappedToAnAxisPoint, snapType);
        }
      }

      return {
        snappedPoint: snappedToBothAxesPoint || snappedToAnAxisPoint,
        metadata: {
          neighbour: secondSnapNeighbour || firstSnapNeighbour,
          snappedToTwoAxes,
        },
      };
    }

    disposeSnapToObjects();

    let edgeMovement = {};
    edgeMovement.edge = _selectedEdgeWorld;
    let options = {
      edgeMovement,
    };

    let edgePoint1 = _selectedEdgeWorld.headPt;
    let edgePoint2 = _selectedEdgeWorld.tailPt;

    let threshold = 0.5;
    if (
      isFloatEqual(edgePoint1.x, edgePoint2.x, threshold) &&
      isFloatEqual(edgePoint1.z, edgePoint2.z, threshold)
    ) {
      //this is for 2D vertex edit and vertical 3D edge edit
      options = {};
    }

    options.excludedMeshes = _meshesOfInterest;

    if (store.$scope.isTwoDimension) {
      disposeAllAxisLines();

      //edgePoint1 is _selectedEdgeWorld.headPt
      //headPt is the vertex above in 2D edit
      let snapObject = _determineAxisPointFor2DVertexEdit(edgePoint1);

      let axisPoint = snapObject.snappedPoint;
      options.metadata = {};
      options.metadata.tertiarySnappedPointDash = snapObject.metadata.neighbour;
      options.snappedToTwoAxes = snapObject.metadata.snappedToTwoAxes;
      options.tertiarySnapEdgeArray = _.concat(
        _tertiarySnapEdgeArray,
        store.snappingGlobalVariables.lastSnappedEdgeArray
      );
      
      options.attemptCadSnaps = true;

      return findPrioritizedSnapPoint(
        _startingPointOnEdge,
        axisPoint,
        _meshOfInterest,
        options
      );
    } else {
      options.doNotDoSecondaryScenePicks = true;

      return findPrioritizedSnapPoint(
        _startingPointOnEdge,
        null,
        _meshOfInterest,
        options
      );
    }
  }

  function _moveIndicators(incrementalMovementVector) {
    uiIndicatorsHandler.vertexIndicator.moveBy(incrementalMovementVector);
    uiIndicatorsHandler.edgeIndicator.moveBy(incrementalMovementVector);
  }

  function move() {
    if (_edgeSelected) {
      if (!_startingPointOnEdge) return;
      let edgeCurrentPosition = _getScenePosition();
      if (!edgeCurrentPosition) {
        return;
      }

      /*if (store.BIMProject) {
        if (_meshOfInterest.type.toLowerCase() === "mass") {
          if (_componentOfInterest.massType.toLowerCase() === "room") {
            edgeCurrentPosition.y = _edgeStartingPositionOnMove.y;
          }
        }
        // eslint-disable-next-line no-empty
        else if (_meshOfInterest.type.toLowerCase() === "roof") {
        } else {
          edgeCurrentPosition.y = _edgeStartingPositionOnMove.y;
        }
      }*/

      const options = {};
      if (store.$scope.isTwoDimension) {
        options.attemptCorrection = false;
        options.pivot = _selectedEdgeWorld.headPt;
        // options.mainInstanceComponent = _mainInstanceComponentOfInterest;
      }

      // attemptCorrection has become hella complex for simultaneous edit
      // In the worst case, edit gets stuck, so disabling it
      // let's see if users notice the absence

      const moved = _moveEdge(
        edgeCurrentPosition,
        _edgeStartingPositionOnMove,
        options
      );

      if (moved) {
        let incrementalMovementVector = edgeCurrentPosition.subtract(
          _edgeStartingPositionOnMove
        );
        _moveIndicators(incrementalMovementVector);

        let toPoint = _centreOfSelectedEdge.addInPlace(
          incrementalMovementVector
        );

        //this is because dimLine covered the snap axis in some cases of 2D vertex edit
        if (store.$scope.isTwoDimension && store.scene.getMeshByName("snappedAxis")) {
          const inputBox = getBabylonGUIElementByName("distBox");
          if (inputBox)
            inputBox.moveToVector3(
              BABYLON.Vector3.Center(_edgeIndicatorInitialPosition, toPoint),
              store.scene
            );

          disposeMesh("dimline");
        }

        DisplayOperation.drawOnMove(_edgeIndicatorInitialPosition, toPoint);

        let movementAmount = toPoint
          .subtract(_edgeIndicatorInitialPosition)
          .length();
        DisplayOperation.displayOnMove(movementAmount, null, true, {
          onChangeCallback: moveOperator.handleUserInput,
        });

        _edgeStartingPositionOnMove = edgeCurrentPosition;

        pseudoEdgeRenderer.update();
        /*
                _meshesOfInterest.forEach(m => {
                    onSolid(m);
                    setLayerTransperancy(m);
                });
                */

        if (options.movementCorrected) {
          disposeSnapToObjects();
          disposeAllAxisLines();
        }
      } else {
        disposeSnapToObjects();
        disposeAllAxisLines();
      }
    }
  }

  const resetData = function () {
    _edgeStartingPositionOnMove = null;
    _centreOfSelectedEdge = null;
    _startingPointOnEdge = null;
    _edgeIndicatorInitialPosition = null;
    _edgeSelected = false;
    _vertexNeighbours = null;
    _topFaceVertices = null;
    _tertiarySnapEdgeArray.length = 0;
    _componentsOfInterest.length = 0;
    _level0AndLevel1InstanceComponents.length = 0;
    _meshesOfInterest.length = 0;
    _componentVertexIndicesMapping = {};
    _vertexWMReferenceComponentMap = new Map();
    _componentVerticesBeingMovedMap = new Map();
    _allVerticesBeingMoved.length = 0;

    disposeAllAxisLines();
    pseudoEdgeRenderer.flush();
    constraintSolver.flush();
  };
  
  const _postCompletionProcessing = function (){
    _componentsOfInterest.forEach((c) => {
      delete c.brep.indexes;

      onSolid(c.mesh);
      setLayerTransperancy(c.mesh);

      if (c.mesh.isAnInstance) {
        c.mesh.sourceMesh.instances.forEach((i) => i.refreshBoundingInfo());
      } else {
        c.mesh.refreshBoundingInfo();
      }
    });
  };

  function finishOperation(intermediateConclusion) {
    
    _postCompletionProcessing();
    // eslint-disable-next-line no-empty
    if (intermediateConclusion) {
    } else {
      delayedExecutionEngine.executeAll();
      resetData();
    }
  }

  function handleUserInput(input, checkConstraint) {
    if (_edgeSelected) {
      let currentPosition = _edgeStartingPositionOnMove;
      let direction = currentPosition
        .subtract(_startingPointOnEdge)
        .normalize()
        .scale(input);
      let destination = _startingPointOnEdge.add(direction);

      return _moveEdge(destination, currentPosition, { checkConstraint });
    }
  }

  function cancelOperation() {
    handleUserInput(0, false);
    finishOperation(true);
    resetData();
  }

  return {
    setInitialData,
    move,
    finishOperation,
    handleUserInput,
    cancelOperation,
    resetData
  };
})();
export { moveEdge };
