import _ from 'lodash';
import React, { useRef, useState } from 'react'
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import { updateFolderProperties, validateFolderName } from '../../services/folder.service';
import { findFolderRecursive } from '../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice';
import { colors } from '../../themes/constant';
import Button from '../Button';
import Input from '../Input';
import Text from '../Text';
import { CloseButtonIcon, ModalChild, ModalHeader, ICON, modalStylesDefault, ModalBody, StyledRow, StyledCol, ModalFooter, ButtonWrapper, Card } from './style';

function RenameFolderModal({teamID, folderID, handleClose, ...props}) {
    const [folderName, setFolderName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const ref = useRef(null);
    const {currentFolder} = useSelector(state => {
        let team, currentFolder;
        if(teamID){
            team = state.teams.teamlist.find(team => team.id === teamID);
            currentFolder = findFolderRecursive(team.fileSystem?.folders, folderID);
        }else{
            team=null;
            currentFolder = findFolderRecursive(state.teams.personalWorkspace.fileSystem.folders, folderID);
        }
        return {
            team,
            currentFolder
        }
    })
    const handleSubmit = async (evt) => {
        if(evt) evt.preventDefault();
        if(folderName === "") return;
        if(folderName === currentFolder.name) return;
        setIsLoading(true);
        const {isValid, reason} = validateFolderName(folderName);
        if(isValid){
            const resp = await updateFolderProperties(teamID, currentFolder, {name: folderName});
            if(resp.status === "success"){
                setTimeout(()=>{
                    handleClose();
                }, 100);
            }
        }else{
            setErrorMessage(reason);
        }
        setIsLoading(false);
    }
    if(!currentFolder) return null;
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={handleClose}
            style={modalStylesDefault}
        >
            <Card>
            <ModalHeader>
                <ModalChild left="1.0625rem" style={{ maxWidth: "31rem", whiteSpace: "nowrap", overflow: "hidden" }}>
                    <Text opacity={1} weight="bold">
                        Rename Folder 
                        <strong style={{ fontSize: "16px" }}> {_.truncate(currentFolder.name, {length: 48})}</strong>
                    </Text>
                </ModalChild>
                <ModalChild right="1.0625rem">
                    <CloseButtonIcon onClick={handleClose} src={ICON.CloseButton} alt="icon" />
                </ModalChild>
            </ModalHeader>
            <ModalBody>
                <StyledRow>
                    <StyledCol>Folder name</StyledCol>
                    <StyledCol>
                        <form onSubmit={handleSubmit}>
                        <Input 
                            placeholder={"Enter new folder name"}
                            value={folderName}
                            onPress={(e)=>{setFolderName(e.target.value)}}
                            autoFocus={true}
                            errorMessage={errorMessage}
                            error={Boolean(errorMessage)}
                        />
                        </form>
                    </StyledCol>
                </StyledRow>
            </ModalBody>
            <ModalFooter>
                <ButtonWrapper>
                    <Button onPress={handleClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Cancel" />
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button isLoading={isLoading} primary title="Rename" onPress={handleSubmit} />
                </ButtonWrapper>
            </ModalFooter>
            </Card>
        </ReactModal>
     );
}

export default RenameFolderModal;