import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import { routes } from '../../routes/constants'
import { colors } from '../../themes/constant'
import logo from "../../assets/snaptrude.svg";
import { useParams } from 'react-router'
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc'
import authService from '../../services/auth.service'
const Wrapper = styled.div`
    font-family: DM Sans;
    background-color: ${colors.fullWhite};
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
const Logo = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    img{
        width: 10em;
    }
`

const Content = styled.div`
    width: 25em;
    a{
        text-decoration: none;
    }
`
const Heading = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.333333px;
    margin-bottom: 40px;
`
const Text = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.333333px;
    margin-bottom: 40px;

`

export default function Activate() {
    const [message, setMessage] = useState("Please wait while we verify your account.")
    const {uidb64, token} = useParams();
    let newMessage = "";
    useEffect(() => {
        if(!uidb64 || !token){
            newMessage = "The activation link appears to be broken."
            showToast(newMessage)
            setMessage(newMessage);
            return;
        }
        authService.activateEmail(uidb64, token)
        .then(resp => {
            if(resp.success){
                newMessage = "Email activation successful"
                showToast(newMessage, 3000, TOAST_TYPES.success);
                setMessage(newMessage)
            }else{
                newMessage = resp.message;
                showToast(newMessage, 3000, TOAST_TYPES.error);
                setMessage(newMessage)
            }
        })
        return () => {

        }
    }, [])
    return (
        <Wrapper>
            <Logo>
                <Link to={routes.dashboard}>
                    <img src={logo} alt="Snaptrude" />
                </Link>
            </Logo>
            <Content>
                <Heading>Activate your account</Heading>
                <Text>{message}</Text>
                {/* <Link to={routes.dashboard}><Button primary title="Login" /></Link> */}
            </Content>
        </Wrapper>
    )
}

