import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import edit from "../../../assets/icons/edit.svg";
import copy from "../../../assets/icons/copy.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import uploadImg from "../../../assets/icons/uploadImg.svg";
import { store } from "../../../snaptrude/modules/utilityFunctions/Store";
import { takeToView } from "../../../snaptrude/libs/sceneFuncs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {djangoUrl} from "../../../services/url.constants";
import Text from "../../../components/Text";
import * as styles from "../../../components/Card/Manage/styles";
import Divider from "../../../components/Divider";

const MainContainer = styled.div`
  padding: 0rem 1.06em 0 0.98em;
  max-height: 85%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar {
    width: 0.1875em;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: ${colors.thinGray};
    -webkit-box-shadow: inset 0 0 6px ${colors.black05};
  }
`;

const ScreenShotText = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  line-height: 16px;
  display: flex;
  margin-left: 2px;
  max-width: 140px;
  align-items: center;
  letter-spacing: -0.333333px;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const ScreenShotContainer = styled.div`
  background: ${colors.white};
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  border: 2px solid ${colors.white};
  box-sizing: border-box;
  border-radius: 8px;
  width: 9.375rem;
  height: 4.921875rem;
  position: relative;
  img {
    width: 9.38em;
    height: 4.92em;
    display: block;
  }
  .selected {
    border: 2px solid ${colors.brightRed};
    transition: 0.5s ease;
  }
`;

const ScreenShotOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 8px;
  border: 2px solid ${colors.transparent};
  &:hover {
    opacity: 1;
  }
`;

const OverlayIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    height: 0.75rem;
    width: 0.75rem;
  }
`;

const ImageContainer = styled.div`
  background: ${colors.fullWhite};
  border-radius: 3px;
  padding: 0.09375rem;
  margin: 0 0.125em;
  cursor: pointer;
`;

const DUMMY_LIST = [1, 2, 3, 4, 5, 6]

const ViewSidebar = (props) => {
  const [selectedScreenshot, setSelectedScreenshot] = useState(-1);
  // eslint-disable-next-line no-unused-vars
  const [optionSelected, setOptionSelected] = useState("");
  const sceneViewData = useSelector(state => state.screenshotUiData.sceneViewData);
  const renders = useSelector(state => state.screenshotUiData.renders.list);

  const handleScreenshotSelect = (key, index) => {
    setSelectedScreenshot(index);
    takeToView(key);
  }
  const setSelectedOption = (index, optionSelected) => {
    setOptionSelected(optionSelected);
  };

  return (
    <div>
      {sceneViewData.length > 0 &&
        <Text
          size={"14px"}
          weight={"bold"}
          style={{marginLeft: "1rem", paddingTop: "3rem"}}>
          Views
        </Text>
      }
      <MainContainer>
        {sceneViewData.map((item, index) => {
          return (
            <div key={index}>
              <ScreenShotContainer imageUrl={item.path} onClick={() => handleScreenshotSelect(item.imgKey, index)}>
                {/* <img src={item.screenshot} alt="screenshot" /> */}
                <ScreenShotOverlay
                  className={selectedScreenshot === index ? "selected" : ""}
                >
                  <OverlayIcons>
                    {/* <ImageContainer
                      onClick={() => setSelectedOption(index, "import")}
                    >
                      <img src={download} alt="import" />
                    </ImageContainer> */}
                    {/* <ImageContainer
                      onClick={() => setSelectedOption(index, "edit")}
                    >
                      <img src={edit} alt="edit" />
                    </ImageContainer>
                    <ImageContainer
                      onClick={() => setSelectedOption(index, "copy")}
                    >
                      <img src={copy} alt="copy" />
                    </ImageContainer>
                    <ImageContainer
                      onClick={() => setSelectedOption(index, "erase")}
                    >
                      <img src={uploadImg} alt="uploadImg" />
                    </ImageContainer>
                    <ImageContainer
                      onClick={() => setSelectedOption(index, "deleteIcon")}
                    >
                      <img src={deleteIcon} alt="deleteIcon" />
                    </ImageContainer> */}
                  </OverlayIcons>
                </ScreenShotOverlay>
              </ScreenShotContainer>
              <ScreenShotText
                color={
                  selectedScreenshot !== index ? colors.thinGray : colors.darkGrey
                }
                fontWeight={selectedScreenshot !== index ? 500 : 700}
              >
                 {item.screenshotTitle}
              </ScreenShotText>
            </div>
          );
        })}
        </MainContainer>
        {renders.length > 0 &&
          <div>
            <Divider width={"325px"} style={{marginLeft: "15px"}}/>
            <Text
              size={"14px"}
              weight={"bold"}
              style={{marginLeft: "1rem", paddingTop: sceneViewData.length > 0 ? "0rem": "3rem"}}>
              3D Renders
            </Text>
          </div>
        }
        <MainContainer>
        {renders.map((item, index) => {
          return (
            <div key={index}>
              <Link to={`/model/${store.floorkey}/render?id=${item.fields.render_id}`} target="_blank">
                <ScreenShotContainer imageUrl={`${djangoUrl}/media/${item.fields.screenshot}`}>
                  <ScreenShotOverlay>
                    <OverlayIcons>
                    </OverlayIcons>
                  </ScreenShotOverlay>
                </ScreenShotContainer>
              </Link>
              <ScreenShotText
                color={colors.thinGray}
                fontWeight={500}
              >
                {item.fields.render_name}
              </ScreenShotText>
            </div>
          );
        })}
      </MainContainer>
    </div>
  );
};

export default ViewSidebar;
