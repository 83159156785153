import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { loadIntComponent, offsetInterior } from "./interiors_gen.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds.js";
function loadBathroom(name, room_pol, flag) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/bathroom/bathroom/" +
      name +
      ".babylon";
    var comp_path_dir =
      window.location.origin + "/media/models/bathroom/bathroom/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
    //console.log(bed_mesh, name, comp_path, comp_path_dir);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadBathroom(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
      //console.log(newMesh);
    }
  }, 1000);
}

function placeBathroomComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "bathroom",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeBathroomComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeBathroomComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;
  newInstance.storey = storey.value;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForBathroom(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

// function getRoomOrientationForBathroom(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     var intersectWallThickness = 0;
//     // var ZZ = bbinfo.maximum.z - bbinfo.minimum.z;
//     // var YY = bbinfo.maximum.y - bbinfo.minimum.y;
//     // var XX = bbinfo.maximum.x - bbinfo.minimum.x;
//
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * unit_scale) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * unit_scale) * Math.cos(angle);
//     }
// }

function getRoomOrientationForBathroom(coords, newInstance) {
  let wallThickness = 200 / (store.unit_absolute_scale * 10000);
  offsetInterior(coords, newInstance, wallThickness);
}
export {
  loadBathroom,
  placeBathroomComp,
  arrangeBathroomComp,
  getRoomOrientationForBathroom,
};
