import { store } from "../utilityFunctions/Store.js"
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { AutoSave } from "../socket/autoSave.js";
import _ from "lodash";

const saveDiagnostics = (function () {
  const EVENT_NAME = "getSavedState";

  const _compareStates = function (savedState) {
    const sceneState = {};

    const structuresInScene = StructureCollection.getInstance().getStructures();

    _.forEach(structuresInScene, (structure, structureId) => {
      if (structureId === "default") return;

      _.forEach(structure.getAllLevels(), (level) => {
        _.forEach(level.flyweight, (primitives, type) => {
          if (_.isArray(primitives)) {
            if (!sceneState[type]) sceneState[type] = [];
            const IDs = _.map(primitives, (object) => object.id);
            sceneState[type].push(...IDs);
          }
        });
      });
    });

    const dataMismatch = {
      objectsInDBButNotInScene: {},
      objectsInSceneButNotInDB: {},
    };

    _.forEach(savedState, (componentIdsInDB, componentType) => {
      const componentIdsInScene = sceneState[componentType];

      const onlyInDB = _.difference(componentIdsInDB, componentIdsInScene);
      if (!_.isEmpty(onlyInDB)) {
        if (!dataMismatch.objectsInDBButNotInScene[componentType])
          dataMismatch.objectsInDBButNotInScene[componentType] = [];

        dataMismatch.objectsInDBButNotInScene[componentType].push(...onlyInDB);
      }

      const onlyInScene = _.difference(componentIdsInScene, componentIdsInDB);
      if (!_.isEmpty(onlyInScene)) {
        if (!dataMismatch.objectsInSceneButNotInDB[componentType])
          dataMismatch.objectsInSceneButNotInDB[componentType] = [];

        dataMismatch.objectsInSceneButNotInDB[componentType].push(
          ...onlyInScene
        );
      }
    });

    // console.log(sceneState);
    // console.log(savedState);

    const doesDataMatch =
      _.isEmpty(dataMismatch.objectsInDBButNotInScene) &&
      _.isEmpty(dataMismatch.objectsInSceneButNotInDB);

    if (doesDataMatch) {
      console.log("It's a match! ✅");
    } else {
      console.error("There's a mismatch ❌");
      console.log(dataMismatch);
    }
  };

  /**
   * This returns an object with the details of components in Db but not in scene and vice versa.
   *
   * The residents of objectsInDBButNotInScene just after reload will be instance objects whose sourceMesh has been deleted
   */
  const diagnose = function () {
    const data = {
      floorkey : store.floorkey,
    };

    AutoSave.emitEvent(EVENT_NAME, data, _compareStates);
  };

  return {
    diagnose,
  };
})();
export { saveDiagnostics };
