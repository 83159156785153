import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import OuterCard from "../Outer";
import Text from "../../Text";
import Divider from "../../Divider";
import Button from "../../Button";

import * as styles from "./styles";
import * as constants from "./constants";

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  flex: ${({ flex }) => flex};
`;

const SpanContainer = styled.span`
  margin: 0;
  display: flex;
  align-items: center;
`;

const CustomText = styled(Text)`
  display: inline;
  opacity: ${({ opacity }) => opacity || 1};
  margin-right: ${({ marginRight }) => marginRight};
  margin: ${({ margin }) => margin};
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.theme.fontWeight[props.weight] || "500"};
  font-family: ${(props) => props.theme.font};
`;

const Card = styled(OuterCard)`
  height: 12.9375rem;
  width: 100%;
  padding: 1rem 1rem 0 1rem;
`;

function Manage({ isSubscribed, total, interval, professional, users, renewal, onPress, currencySymbol }) {
  return (
    <Card hover={true}>
      <CustomText opacity="0.8" color="secondaryBlack" weight="bold">
        {constants.CURRENT_PLAN}
      </CustomText>
      <Divider customStyle={styles.DividerStyles} />

      { isSubscribed &&
      <FlexContainer flexDirection="column" alignItems="stretch">
        <FlexContainer>
          <FlexContainer flex="0 0 66%">
            <CustomText opacity="0.8" color="darkGrey">
              {constants.PROFESSIONAL_PLAN}
            </CustomText>
          </FlexContainer>
          <FlexContainer justifyContent="normal" alignItems="baseline" flex="1">
            <CustomText
              lineHeight="1.9375rem"
              color="primeBlack"
              size="1.5rem"
              weight="bold"
              margin="0 0.5rem 0 0"
            >
              {currencySymbol}{professional}
            </CustomText>
            <SpanContainer>
              <CustomText size="0.75rem" color="primeBlack">
                {constants.PER_USER} / {interval}
              </CustomText>
            </SpanContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center">
          <FlexContainer flex="0 0 66%">
            <CustomText opacity="0.8" color="darkGrey">
              {constants.TOTAL} {users} {constants.USERS}
            </CustomText>
          </FlexContainer>
          <FlexContainer flex="1">
            <CustomText
              lineHeight="1.9375rem"
              color="primeBlack"
              size="1.5rem"
              weight="bold"
              margin={0}
            >
              {currencySymbol}{total}
            </CustomText>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      }
      {!isSubscribed &&
        <FlexContainer flexDirection="column" alignItems="stretch">
          <FlexContainer>
            <FlexContainer flex="0 0 66%">
              <CustomText opacity="0.8" color="darkGrey">
                Free Plan
              </CustomText>
            <Button
              type="button"
              title={constants.MANAGE_SUBSCRIPTION}
              customButtonStyle={styles.ButtonStyles}
              customButtonTextStyle={styles.ButtonTextStyles}
              onPress={onPress}
            />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      }

      <Divider customStyle={styles.DividerStyles} />
      {isSubscribed &&
        <FlexContainer>
          <CustomText color="darkGrey" size="0.8125rem" lineHeight="1.0625rem">
            {constants.NEXT_RENEWAL_ON} {renewal}
          </CustomText>
          <Button
            type="button"
            title={constants.MANAGE_SUBSCRIPTION}
            customButtonStyle={styles.ButtonStyles}
            customButtonTextStyle={styles.ButtonTextStyles}
            onPress={onPress}
          />
        </FlexContainer>
      }
    </Card>
  );
}

Manage.propTypes = {
  users: PropTypes.number,
  professional: PropTypes.number,
  total: PropTypes.number,
  renewal: PropTypes.string,
};

export default Manage;
