import axios from "axios";
import { ORIGIN } from "./url.constants";
import { showToast, TOAST_TYPES } from "../snaptrude/modules/extrafunc";
import { store } from "../snaptrude/modules/utilityFunctions/Store";
import { getJWT } from "./jwt.service";

const getEnergyCodes = async (floorkey = null, lat = null, lng =null) => {
  try {
    let endpoint = "/getEnergyCodes"

    if(floorkey && lat && lng) endpoint += `?floor_key=${floorkey}&lat=${lat}&lng=${lng}`

    const response = await axios.get(ORIGIN + endpoint);
    const data = response.data

    if(!!data?.error) {
      console.log("Error getting energy codes", data?.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)  
    showToast(error?.msg || "Failed to get energy codes!", 3000, TOAST_TYPES.error);
  }
};

const getDaylightAnalysisInfoAll = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/getDaylightAnalysisInfoAll?floor_key=" + floor_key);
    const data = response.data

    if(!!data?.error) {
      console.log("Error getting all analysis info", data?.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const getDaylightAnalysisInfo = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/getDaylightAnalysisInfo?floor_key=" + floor_key);
    const data = response.data

    if(!!data?.error) {
      console.log("Error getting analysis info", data?.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const getDaylightAnalysisInfoLatest = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/getDaylightAnalysisInfoLatest?floor_key=" + floor_key);
    const data = response.data

    if(!!data?.error) {
      console.log("Error getting latest analysis info", data?.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const getDaylightAnalysisCalculation = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/getDaylightAnalysisCalculations?floor_key=" + floor_key);
    const data = response.data

    if(!!data?.error) {
      console.log("Error getting daylight calculation", data?.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const checkAnalysisStatus = async (floor_key, returnWholeData = false) => {
  try {
    if(!floor_key) {
      console.log("checkAnalysisStatus: No floor_key")
      return
    }
    const response = await axios.get(ORIGIN + "/getAnalysisStatus?floor_key=" + floor_key);
    const data = response.data

    if(returnWholeData) return data

    if(!!data?.error) {
      console.log("Error checking for analysis status", data?.error)
      // showToast(data.msg, 3000, TOAST_TYPES.error);
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

/**
 * Saves daylight analysis settings
 * @param {string} floorkey 
 * @param {number | string} energyCodeId 
 * @param {string} energyCodeName 
 * @param {string[]} buildingTypes 
 */
const saveSettings = async (floorkey, energyCodeId, energyCodeName, buildingTypes, location) => {
  try {
    const formData = new FormData()
    formData.append("user", store.infoUser.id)
    formData.append("jwt", getJWT())
    formData.append("floor_key", floorkey)
    formData.append("energy_code_id", energyCodeId)
    formData.append("energy_code_name", energyCodeName)
    formData.append("building_types", buildingTypes)

    if(location) formData.append("location", location)

    const { data } = await axios.post(ORIGIN + "/saveDaylightAnalysisSettings/", formData);
    if(!!data?.error) {
      console.log("Error Saving Daylight Settings", data?.error)
      showToast(data.msg, 3000, TOAST_TYPES.error);
      return
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};


const saveAsCoveProject = async (floorkey) => {
  try {
    const formData = new FormData()
    formData.append("floorkey", floorkey)

    const response = await axios.post(ORIGIN + "/saveAsCoveProject/", formData);
    return response;
  } catch (error) {
    return error?.response || error?.message
  }
};

const triggerDaylightAnalysis = async (formData) => {
  try {
    const response = await axios.post(ORIGIN + "/triggerDaylightAnalysis/", formData);
    const data = response.data
    
    if(!!data?.error) {
      console.log("Error Starting analysis", data?.error)
      showToast(data.msg, 3000, TOAST_TYPES.error);
      return
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const analysisFailed = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/triggerDaylightAnalysisFailure?floor_key=" + floor_key);
    const data = response.data
    
    if(!!data?.error) {
      console.log("failed to set analysis as failed", data.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const getEUIBreakdown = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/getEUIBreakdown?floor_key=" + floor_key);
    const data = response.data
    
    if(!!data?.error) {
      console.log("Error Getting EUI", data?.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

const getDaylightAnalysis = async (floor_key) => {
  try {
    const response = await axios.get(ORIGIN + "/getDaylightAnalysis?floor_key=" + floor_key);
    const data = response.data
    
    if(!!data?.error) {
      showToast(data.msg, 3000, TOAST_TYPES.error);
      console.log(data.error)
      return
    }

    return data.data;
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

/**
 * Copy all daylight analysis of one project to other project
 * @param {string} floorkey of source project 
 * @param {string} floorkey of destination project
 */
const copyDaylightAnalysis = async (source, destination) => {
  try {
    const formData = new FormData()
    formData.append("source_floorkey", source)
    formData.append("dest_floorkey", destination)

    const data = await axios.post(ORIGIN + "/copyDaylightAnalysis/", formData);
        
    if(!!data?.error) {
      console.log("Failed to copy daylight analysis", data?.error)
      showToast(data.msg, 3000, TOAST_TYPES.error);
      return
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error)
    return { error, data: null };
  }
};

export default {
  getEnergyCodes,
  getDaylightAnalysisInfo,
  getDaylightAnalysisInfoLatest,
  getDaylightAnalysisInfoAll,
  checkAnalysisStatus,
  triggerDaylightAnalysis,
  analysisFailed,
  getEUIBreakdown,
  getDaylightAnalysis,
  saveAsCoveProject,
  saveSettings,
  copyDaylightAnalysis
}
