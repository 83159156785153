/*jshint esversion: 6 */
"use strict";
import * as log from "loglevel";
import _ from "lodash";
import { RoomEngine,CreatePlainFloor } from "./snaptrudeEngine.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";

function FloorBuilder() {
  this.bob = new FloorMaker();

  this.step1 = function (mass) {
    this.bob.createFloors(mass);
  };

  this.step2 = function (mass) {
    //this.bob.save();
  };

  this.step3 = function (mass) {
    //this.bob.save();
  };

  //add more

  this.get = function () {
    return this.bob;
  };
}

function FloorMaker() {
  this.floors = [];

  /**
   * Creates walls for all the rooms in scene.
   */
  this.createFloors = function (mass) {
    //floor generation
    var roomEngine = new RoomEngine();
    var structureCollection = StructureCollection.getInstance();
    var structure = structureCollection.getStructureById(mass.structure_id);
    var level = structure.getLevelByUniqueId(mass.level_id);

    try {
      roomEngine.execute(new CreatePlainFloor(mass.mesh));
      //add more
    } catch (e) {
      log.error(e);
      // var errorMessage = new ErrorMessage(mass.mesh, "error");
      // errorMessage.show("Ooops, can't generate wall for this structure");
      // mass.mesh.material = errorMessage.material;
    }

    mass.floors = [];
    log.log(roomEngine.getCurrentValue());
    let floors = roomEngine.getCurrentValue();
    _.flatten(floors).forEach(function (floor) {
      floor.groupId = mass.groupId;
      floor.far_included = mass.far_included;
      floor.room_type = mass.room_type;
      floor.room_unique_id = mass.room_unique_id;
      floor.mesh.room_type = mass.room_type;
      floor.mesh.room_unique_id = mass.room_unique_id;
      level.addFloorToLevel(floor, false);
      mass.floors.push(floor);
      createBuildingEngine.addComponent(floor);
    });

    floors.length = 0;
  };

  //add more
}
export { FloorBuilder,FloorMaker };
