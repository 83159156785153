'use strict';

import {getTopFaceVertices} from "../../libs/brepOperations";
import {getDistanceBetweenVectors, mmToSnaptrudeUnits} from "../extrafunc";
import {offsetRoomPolsRoof} from "../../libs/wall_generation";
import {areaOfPolygonV3} from "../../libs/areaFuncs";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray} from "../../libs/arrayFuncs";
import {projectionOfPointOnLine} from "../../libs/snapFuncs";
import {uiIndicatorsHandler} from "../uiIndicatorOperations/uiIndicatorsHandler";
import BABYLON from "../babylonDS.module";
import _ from "lodash";
import {externalUtil} from "../externalUtil";

const gardener = (function (){
  
  const _getGridPoints = function (vertices) {
    
    const _getOffset = function (vertices){
      const offsetArray = offsetRoomPolsRoof(vertices.map(v3 => [v3.x, v3.z, v3.y]), -OFFSET_AMOUNT);
      return offsetArray.map(arr => new BABYLON.Vector3(arr[0], arr[2], arr[1]));
    };
    
    const gridPoints = [];
    
    const OFFSET_AMOUNT = mmToSnaptrudeUnits(1000);
    const THRESHOLD_AREA = mmToSnaptrudeUnits(2000) * mmToSnaptrudeUnits(2000); //1 sq m
    
    const edgesArray = [];
    
    let offsetArea;
    let offsetPoints = vertices;
    
    let keepOffsetting = true;
    
    do {
      offsetPoints = _getOffset(offsetPoints);
      offsetArea = areaOfPolygonV3(offsetPoints);
      
      const edges = offsetPoints.map((v1, i) => {
        const v2 = getCircularlyNextElementInArray(offsetPoints, v1);
        
        return {
          headPt: v1,
          tailPt: v2,
        };
        
      });
      
      const edgeLengths = edges.map(e => {
        return getDistanceBetweenVectors(e.headPt, e.tailPt);
      });
      
      const minEdgeLength = _.min(edgeLengths);
      
      // keepOffsetting = offsetArea > THRESHOLD_AREA && minEdgeLength > OFFSET_AMOUNT;
      keepOffsetting = offsetArea > THRESHOLD_AREA;
      
      edgesArray.push(edges);
      
      if (edgesArray.length > 1){
        edgesArray.forEach(edges => {
          offsetPoints.forEach((point, i) => {
            const e1 = edges[i];
            const e2 = getCircularlyPreviousElementInArray(edges, e1);
            
            const previousPoint = getCircularlyPreviousElementInArray(offsetPoints, point);
            const nextPoint = getCircularlyNextElementInArray(offsetPoints, point);
            
            const projection1 = externalUtil.getPointOfIntersection([point, previousPoint, e1.headPt, e1.tailPt]);
            const projection2 = externalUtil.getPointOfIntersection([point, nextPoint, e2.headPt, e2.tailPt]);
            
            // const projection1 = projectionOfPointOnLine(point, e1.headPt, e1.tailPt);
            // const projection2 = projectionOfPointOnLine(point, e2.headPt, e2.tailPt);
            
            if (projection1) gridPoints.push(projection1);
            if (projection2) gridPoints.push(projection2);
            
            // uiIndicatorsHandler.vertexIndicator.multiIndicators.show([projection1, projection2]);
          });
        });
      }
      else {
        gridPoints.push(...offsetPoints);
      }
      
      if (!keepOffsetting) {
        gridPoints.push(...offsetPoints);
      }
    }
    while (keepOffsetting);
    
    return gridPoints;
    
  };
  
  const add = function (site){
    const topVertices = getTopFaceVertices(site);
    
    const gridPoints = _getGridPoints(topVertices);
    
    uiIndicatorsHandler.vertexIndicator.multiIndicators.show(gridPoints);
  };
  
  const update = function (){
  
  };
  
  return {
    add,
    update,
  };
  
})();

export default gardener;
