import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from "../Button";
import { SingleUploadOverView } from "../../containers/genericModal/singleUploadOverView";
import Mapbox from "../Mapbox";
import { handleToolbar } from "../../snaptrude/libs/handleToolbar";
import Toggle from "../Toggle";
import { store } from "../../snaptrude/modules/utilityFunctions/Store.js";
import { terrainGeneration } from "../../snaptrude/modules/geo/terrainMap";
import Input from "../Input";
const Card = styled(Outer)`
  width: 39rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`;

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 0.1em 0em;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const Row = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HighlightedText = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  white-space: nowrap;
  background-color: ${colors.transparent};
  color: ${colors.brightRed};
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;

const InputWithLabel = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  padding-bottom: 1rem;
  label {
    padding: 0.75rem;
    width: 7rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function SpeckleRevitImport({ isOpen, onClose, modalHeaderText }) {
  let [speckleStreamId, setSpeckleStreamId] = useState("");
  let [speckleBaseObjectId, setSpeckleBaseObjectId] = useState("");

  // useEffect(() => {
  //   if(isOpen){
  //     // store.terrainMap?.resize();
  //     terrainGeneration.updateCenterAndZoomOfTerrain();
  //   }
  // }, [isOpen])

  //   let _handleMapBoxStyleToggle = function () {
  //     const satValue = satelliteStyle;
  //     setSatelliteStyle(!satelliteStyle);
  //     if (!satValue) {
  //       store.terrainMap.setStyle(_satelliteStyle);
  //     } else {
  //       store.terrainMap.setStyle(_streetStyle);
  //     }
  //   };

  let handleSubmitButton = () =>{
    if(speckleStreamId && speckleBaseObjectId){
    handleToolbar("importRevitFromSpeckle", null, {
      speckleStreamId,
      speckleBaseObjectId,
    });
    onClose();
  }
  }
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              {modalHeaderText}
            </Text>
          </ModalChild>

          {/* <ModalChild right="1.0625rem">
            Elevations:
            <Toggle
              handleClick={() => setDisplayElevation(!displayElevation)}
            />
          </ModalChild> */}

          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          <InputContainer>
            <InputWithLabel>
              <label>Speckle Stream ID</label>
              <Input
                type="text"
                required
                value={speckleStreamId}
                customInputContainerStyle={{ width: "60%" }}
                placeholder={"Please enter Speckle Stream ID"}
                onPress={(evt) => {
                  setSpeckleStreamId(evt.target.value);
                }}
              />
            </InputWithLabel>
            <InputWithLabel>
              <label>Base Object ID</label>
              <Input
                type="text"
                required
                value={speckleBaseObjectId}
                customInputContainerStyle={{ width: "60%" }}
                placeholder={"Please enter Base Object ID"}
                onPress={(evt) => {
                  setSpeckleBaseObjectId(evt.target.value);
                }}
              />
            </InputWithLabel>
          </InputContainer>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button
              onPress={onClose}
              style={{
                backgroundColor: colors.transparent,
                color: colors.dullgrey,
              }}
              title="< Cancel"
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              primary
              title="Import"
              onPress={() => {
                handleSubmitButton()
              }}
              // onPress={() => {
              //   handleToolbar("importRevitFromSpeckle", null, {
              //     speckleStreamId,
              //     speckleBaseObjectId,
              //   });
              //   onClose();
              // }}
            />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  );
}

export default SpeckleRevitImport;
