import styled from "styled-components";
import { Link } from 'react-router-dom'
import { colors } from "../../themes/constant";

export const Container = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  min-width: 0;
  padding-right: 10px;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: background-color 0.2s ease;
  min-width: 0px;

  img {
    margin-left: 0.5em;
  }

  .action {
    padding: 0.5em 1em;
    width: 10em;
  }
  
  .actions-wrapper {
    top: 200%;
  }

  .actions-wrapper.left {
    left: -80%;
  }

  ${({ showHoverEffect }) => showHoverEffect && `
    :hover {
      background-color: ${colors.white};
    }
  `}
`

export const EllipsedButtonWrapper = styled.div`
  display: inline-flex;
  min-width: 60px;

  .action {
    padding: 0;
  }

  .action a {
    padding: 0.5em 1em;
    width: 10em;
  }
  
  .actions-wrapper {
    top: 100%;
    max-height: 200px;
  }

  .actions-wrapper.left {
    left: -80%;
  }
`

export const Name = styled.span`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  white-space: nowrap;

  ${({ active }) => active && `
    font-weight: 600;
  `}
`

export const NameLink = styled(Link)`
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  color: rgba(45,45,46, 0.8);
  border-radius: 6px;
  transition: background-color 0.2s ease;
  padding: 0 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    background-color: ${colors.white};
  }

  ${({ active }) => active && `
    font-weight: 600;
  `}
`

export const DropdownItemLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: rgba(45,45,46, 0.8);
  padding: 0 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  white-space: nowrap;
`

export const Separator = styled.span`
  margin: 0 0.5em;

  ${({ active }) => active && `
    font-weight: 600;
  `}
`;