import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { routes } from "../../../routes/constants";
import SharedWithMe from "../../SharedWithMe";
import AllFiles from "../AllFiles";
function PersonalDashboard({ }) {
    const { path } = useRouteMatch();
    const { personalWorkspace } = useSelector(state => {
        return {
            personalWorkspace: state.teams.personalWorkspace
        }
    })
    return (
        <React.Fragment>
            <Switch>
                <Route path={`${routes.sharedWithMe}`}>
                    <SharedWithMe />
                </Route>
                <Route path={`${path}/:folderID`}>
                    <AllFiles personalWorkspace={personalWorkspace} />
                </Route>
                <Route exact path={path}>
                    <AllFiles personalWorkspace={personalWorkspace} />
                </Route>
            </Switch>
        </React.Fragment>
    );
}

export default PersonalDashboard;