import _ from "lodash";
import { makeid } from "../../libs/arrayFuncs.js";
class Command {
  constructor(name, data, logic, getSaveData) {
    this.id = "cmd_" + makeid(5);

    this.name = name;
    this.data = data;
    this.execute = logic.execute;
    this.unexecute = logic.unexecute;

    this.getSaveData = function () {
      let returnValue = [];
      if (getSaveData) {
        returnValue = getSaveData.call(this);
        if (!_.isArray(returnValue)) {
          returnValue = [returnValue];
        }
      }

      const commandName = this.name;
      returnValue.forEach((r) => (r.name = commandName));

      return returnValue;
    };

    /*this.serialize = function () {

            function _removeCircularity(dataPoint, i){
                _.forEach(dataPoint, (value, key) => {
                    let newValue = value;
                    if (key.includes("brep")){
                        newValue = resurrect.stringify(value);
                    }
                    else if (key.includes("material")){
                        newValue = null;
                    }
                    else if (key.includes("struct")){
                        newValue = null;
                    }

                    if (isNaN(i)) nonCircularData[key] = newValue;
                    else nonCircularData[i][key] = newValue;
                });
            }

            let data = this.data;
            let nonCircularData;

            if (_.isArray(data)){
                nonCircularData = jQuery.extend(true, [], data);

                nonCircularData.forEach((dataPoint, i)=> {
                    _removeCircularity(dataPoint, i);
                })
            }
            else {
                nonCircularData = jQuery.extend(true, {}, data);
                _removeCircularity(nonCircularData);
            }

            return JSON.stringify({
                commandName : this.name,
                data : nonCircularData
            });
        };

        this.deserialize = function (jsonData) {
            let data = JSON.parse(jsonData);
            let cmd = new Command(data.name, data.data, logic);

            return cmd;
        };*/
  }
}
export { Command };
