import BABYLON from "../babylonDS.module.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import {
  _getMiddleSectionOfAWall,
  getBottomFaceVertices,
  getFaceVerticesFromFace,
  getTopFaceVertices
} from "../../libs/brepOperations.js";
import { convertGlobalVector3ToLocal, convertLocalVector3ToGlobal, deSerializeMesh } from "../extrafunc.js";
import { store } from "../../../snaptrude/modules/utilityFunctions/Store"
import { object } from "prop-types";
import _ from "lodash";
import { TmpVectors } from "babylonjs";

const revitDataPreparation = (() => {

  let deletedElements = [];

  const shouldAddOn = (obj) => {
    if (obj?.revitMetaData?.isModified) return true;

    if (!obj?.revitMetaData?.elementId) return true;

    if (obj?.mesh?.type?.toLowerCase()?.includes("throwaway") == true) return true;

    return false;
  }

  const addOnData = () => {
    const structures = StructureCollection.getInstance().getStructures();
    const returnObject = {};
    returnObject["structures"] = {};
    for (const structure in structures) {
      returnObject["structures"][structure] = {};
      const levels = structures[structure].getAllLevels();
      for (const level in levels) {
        returnObject["structures"][structure][level] = {};
        const floors = levels[level].getFloors();
        returnObject["structures"][structure][level]["floors"] = {};

        const roofs = levels[level].getRoofs();
        returnObject["structures"][structure][level]["roofs"] = {};

        const walls = levels[level].getWalls();
        returnObject["structures"][structure][level]["walls"] = {};

        const doors = levels[level].getDoors();
        returnObject["structures"][structure][level]["doors"] = {};
        const doorsLookUp = {};

        const windows = levels[level].getWindows();
        returnObject["structures"][structure][level]["windows"] = {};
        const windowsLookUp = {};

        const masses = levels[level].getMasses();
        returnObject["structures"][structure][level]["masses"] = {};

        let _floorLookup = {};
        for (let i = 0; i < floors.length; i++) {
          //TODO: remove throwaways
          if (!shouldAddOn(floors[i])) { continue; }

          try {
            floors[i].setThickness();

            const serializedObject = StructureCollection.getInstance().getSerializedComponent(floors[i]);
            returnObject["structures"][structure][level]["floors"][i] = serializedObject;

            const floorObject = returnObject["structures"][structure][level]["floors"][i];
            const uniqueID = serializedObject["dsProps"]["uniqueID"];
            _floorLookup[uniqueID] = serializedObject;

            const bottomVertices = getBottomFaceVertices(floors[i], BABYLON.Space.LOCAL)
              .map((vertex) => vertex.asArray());
            const topVertices = getTopFaceVertices(floors[i], BABYLON.Space.LOCAL)
              .map((vertex) => vertex.asArray());

            floorObject["bottomVertices"] = bottomVertices;
            floorObject["topVertices"] = topVertices;
            floorObject["thickness"] = (topVertices[0][1] - bottomVertices[0][1]) * floors[i].mesh.scaling.y;

            floorObject["geometries"] = {};
            if (floors[i].mesh.isAnInstance) {
              const uniqueIdOfSource = serializedObject.meshes[0].uniqueIdSource;
              floorObject["materialName"] = _floorLookup[uniqueIdOfSource].meshes[0].materialId;
              floorObject["subMeshes"] = _deepCopy(_floorLookup[uniqueIdOfSource].meshes[0].subMeshes);
            }

          }
          catch (e) {
            console.log("can't set floor thickness: ", e.message)
          }
        }

        let _roofLookUp = {};
        for (let i = 0; i < roofs.length; i++) {
          if (!shouldAddOn(roofs[i])) { continue; }
          //TODO: remove throwaways
          try {
            roofs[i].setThickness();

            const serializedObject =
                StructureCollection.getInstance().getSerializedComponent(roofs[i]);

            returnObject["structures"][structure][level]["roofs"][i]
              = serializedObject;

            const roofObject = returnObject["structures"][structure][level]["roofs"][i]
            const uniqueID = serializedObject["dsProps"]["uniqueID"];
            _roofLookUp[uniqueID] = serializedObject;

            try {
              const bottomVertices = getBottomFaceVertices(roofs[i], BABYLON.Space.LOCAL)
                .map((vertex) => vertex.asArray());
              roofObject["bottomVertices"] = bottomVertices;

              const topVertices = getTopFaceVertices(roofs[i], BABYLON.Space.LOCAL)
                .map((vertex) => vertex.asArray());
              roofObject["topVertices"] = topVertices;

              roofObject["thickness"] = (topVertices[0][1] - bottomVertices[0][1]) * roofs[i].mesh.scaling.y;

              let _faceOfInterest = roofs[i].brep.getFaces()[0];
              roofs[i].mesh.computeWorldMatrix(true)
              roofs[i].mesh.brep = roofs[i].brep;

              if (roofs[i].mesh.isAnInstance) {
                const uniqueIdOfSource = serializedObject.meshes[0].uniqueIdSource;
                roofObject["materialName"] = _roofLookUp[uniqueIdOfSource].meshes[0].materialId;
                roofObject["subMeshes"] = _deepCopy(_roofLookUp[uniqueIdOfSource].meshes[0].subMeshes);
              }

            } catch (e) {
              console.log("can't set thickness of floor");
            }
          }
          catch (e) {
            console.log("can't set roof thickness: ", e.message)
          }
        }

        for (let i = 0; i < doors.length; i++) {
          if (!shouldAddOn(doors[i])) { continue; }

          const serializedDoor = StructureCollection.getInstance().getSerializedComponent(doors[i]);
          returnObject["structures"][structure][level]["doors"][i] = serializedDoor;

          const doorObject = returnObject["structures"][structure][level]["doors"][i];

          const uniqueID = serializedDoor["dsProps"]["uniqueID"];

          doorsLookUp[uniqueID] = serializedDoor;

          if (doors[i].mesh.isAnInstance) {
            const uniqueIdOfSource = serializedDoor.meshes[0].uniqueIdSource;
            doorObject["meshes"][0]["originalScaling"] = doorsLookUp[uniqueIdOfSource]["meshes"][0]["originalScaling"];

            let mesh = doors[i].mesh;
            mesh.computeWorldMatrix(true);
            mesh.refreshBoundingInfo();

            let scaling = mesh.absoluteScaling.clone();

            let width = mesh.getBoundingInfo().boundingBox.extendSize.x * Math.abs(scaling.x) * 2;
            doorObject['meshes'][0]['width'] = width;

            let almostEquals = (a, b, precision = 0.01) => {
              return Math.abs(a - b) < precision
            }
            if (almostEquals(Math.abs(mesh.rotation.x), 1.57)) {
              let height = mesh.getBoundingInfo().boundingBox.extendSize.z * Math.abs(scaling.z) * 2;
              doorObject['meshes'][0]['height'] = height;
            } else {
              let height = mesh.getBoundingInfo().boundingBox.extendSize.y * Math.abs(scaling.y) * 2;
              doorObject['meshes'][0]['height'] = height;
            }
          }

          doorObject["geometries"] = {
            key: 'value' // hacky dummy data so that reconciliation doesn't break
          };
        }

        for (let i = 0; i < windows.length; i++) {
          if (!shouldAddOn(windows[i])) { continue; }

          const serializedWindow = StructureCollection.getInstance().getSerializedComponent(windows[i]);
          returnObject["structures"][structure][level]["windows"][i] = serializedWindow;

          const windowObject = returnObject["structures"][structure][level]["windows"][i];

          const uniqueID = serializedWindow["dsProps"]["uniqueID"];
          windowsLookUp[uniqueID] = serializedWindow;

          if (windows[i].mesh.isAnInstance) {
            const uniqueIdOfSource = serializedWindow.meshes[0].uniqueIdSource;
            windowObject["meshes"][0]["originalScaling"] = windowsLookUp[uniqueIdOfSource]["meshes"][0]["originalScaling"];

            let mesh = windows[i].mesh;
            mesh.computeWorldMatrix(true);
            mesh.refreshBoundingInfo();

            let scaling = mesh.absoluteScaling.clone();
            let width = mesh.getBoundingInfo().boundingBox.extendSize.x * Math.abs(scaling.x) * 2;
            let height = mesh.getBoundingInfo().boundingBox.extendSize.y * Math.abs(scaling.y) * 2;

            windowObject['meshes'][0]['width'] = width;
            windowObject['meshes'][0]['height'] = height;
          }

          windowObject["geometries"] = {
            key: 'value' // hacky dummy data so that reconciliation doesn't break
          };
        }

        const furniture = levels[level].getFurnitures();
        returnObject['structures'][structure][level]['furnitures'] = {};
        for (let i = 0; i < furniture.length; i++) {
          if (!shouldAddOn(furniture[i])) { continue; }

          const serializedFurniture = StructureCollection.getInstance().getSerializedComponent(furniture[i]);
          returnObject['structures'][structure][level]['furnitures'][i] = serializedFurniture;

          //`geometries` property not used in export, removing it significantly reduces size of addOnData.
          returnObject['structures'][structure][level]['furnitures'][i]["geometries"] = {};

          let mesh = furniture[i].mesh;

          let originalEpsilon = mesh._edgesRenderer ? mesh._edgesRenderer : null;

          mesh.enableEdgesRendering(0.9, true);

          const furnitureObject = returnObject['structures'][structure][level]['furnitures'][i];
          furnitureObject.linesPositions = mesh._edgesRenderer.linesPositions;
          furnitureObject.linesIndices = mesh._edgesRenderer.linesIndices;

          furnitureObject['meshes'][0]['localBoundingBox'] = {
            'min': babylonVectorToArray(mesh.getBoundingInfo().boundingBox.minimum),
            'max': babylonVectorToArray(mesh.getBoundingInfo().boundingBox.maximum),
            'center': babylonVectorToArray(mesh.getBoundingInfo().boundingBox.center),
          }

          furnitureObject['meshes'][0]['worldBoundingBox'] = {
            'min': babylonVectorToArray(mesh.getBoundingInfo().boundingBox.minimumWorld),
            'max': babylonVectorToArray(mesh.getBoundingInfo().boundingBox.maximumWorld),
            'center': babylonVectorToArray(mesh.getBoundingInfo().boundingBox.centerWorld),
          }

          if (!Array.isArray(furnitureObject.dsProps.revitMetaData.offset)) {
            if (furnitureObject.dsProps.revitMetaData.offset)
              furnitureObject.dsProps.revitMetaData.offset = babylonVectorToArray(furnitureObject.dsProps.revitMetaData.offset);
            else
              furnitureObject.dsProps.revitMetaData.offset = [0, 0, 0];
          }

          furnitureObject.dsProps.revitMetaData.family = mesh.getSnaptrudeDS().getMeshObjectPropertiesMap().family;
          furnitureObject.dsProps.revitMetaData.type = mesh.getSnaptrudeDS().getMeshObjectPropertiesMap().type;

          try {
            if (mesh.sourceMesh) {
              furnitureObject.dsProps.revitMetaData.type = mesh.sourceMesh.getSnaptrudeDS().revitMetaData.type;
            }
          } catch (e) {
            console.log(e);
          }

          // if (mesh.rotationQuaternion) {
            furnitureObject['meshes'][0]['rotationQuaternion'] = [
              mesh.absoluteRotationQuaternion._x,
              mesh.absoluteRotationQuaternion._y,
              mesh.absoluteRotationQuaternion._z,
              mesh.absoluteRotationQuaternion._w
            ];
          // }

          if (originalEpsilon) {
            mesh.enableEdgesRendering(originalEpsilon, true);
          } else {
            mesh.disableEdgesRendering();
          }
        }

        addOnWithGeometries(walls, returnObject, structure, level, "walls");
        addOnWithGeometries(masses, returnObject, structure, level, "masses");
      }
    }

    returnObject["deletedElements"] = store.exposed.revitDataPreparation.deletedElements;

    return returnObject;
  };

  const addFaceNormalInformationOnSubMeshes = function (_normals, _indices, subMeshes, mesh) {
    if(!_.isEmpty(subMeshes)){
      for( let i = 0; i < subMeshes.length; i++ ){
        let _indexStart = _indices[subMeshes[i].indexStart];
        let _x = _normals[_indexStart * 3];
        let _y = _normals[(_indexStart * 3) + 1];
        let _z = _normals[(_indexStart * 3) + 2];

        let result = new BABYLON.Vector3(_x, _y, _z);

        let wm = mesh.getWorldMatrix();

        if (mesh.nonUniformScaling) {
          TmpVectors.Matrix[0].copyFrom(wm);
          wm = TmpVectors.Matrix[0];
          wm.setTranslationFromFloats(0, 0, 0);
          wm.invert();
          wm.transposeToRef(TmpVectors.Matrix[1]);

          wm = TmpVectors.Matrix[1];
        }

        result = BABYLON.Vector3.TransformNormal(result, wm);

        subMeshes[i].normal = result.asArray();
      }
    }
    return subMeshes;
  }

  // TODO: remove throwaways
  const addOnWithGeometries = (objects, returnObject, structure, level, typeName) => {
    let lookUp = {};
    for (let i = 0; i < objects.length; i++) {
      if (!shouldAddOn(objects[i])) { continue; }

      if (typeName == "walls") {
        try {
          objects[i].setThickness();
        } catch {
          console.log('Unable to setThickness uniqueId:', objects[i].mesh.uniqueId);
        }
      }
      const serializedObject =
        StructureCollection.getInstance().getSerializedComponent(objects[i]);
      returnObject["structures"][structure][level][typeName][i] =
        serializedObject;
      let _normalsOfTheObject = objects[i].mesh?.getVerticesData(BABYLON.VertexBuffer.NormalKind);
      let _indicesOfTheObject = objects[i].mesh?.getIndices();

      const currentObject = returnObject["structures"][structure][level][typeName][i];
      currentObject["meshes"][0]["originalScaling"] = [...currentObject["meshes"][0]["scaling"]];

      const uniqueID = serializedObject["dsProps"]["uniqueID"];
      lookUp[uniqueID] = serializedObject;
      lookUp[uniqueID].meshes[0].subMeshes = addFaceNormalInformationOnSubMeshes( _normalsOfTheObject, _indicesOfTheObject, lookUp[uniqueID].meshes[0].subMeshes, objects[i].mesh);

      if (objects[i].mesh.isAnInstance) {
        const uniqueIdOfSource = serializedObject.meshes[0].uniqueIdSource;
        currentObject["geometries"] = _deepCopy(lookUp[uniqueIdOfSource]["geometries"]);
        currentObject["materialName"] = lookUp[uniqueIdOfSource].meshes[0].materialId;
        currentObject["subMeshes"] = _deepCopy(lookUp[uniqueIdOfSource].meshes[0].subMeshes);
      }

      if (typeName == "walls") {
        currentObject['layers'] = _deepCopy(objects[i]?.properties?._components?._layers);
        let boundingBox = objects[i].mesh.getBoundingInfo().boundingBox;
        currentObject['baseHeight'] = boundingBox.minimumWorld.y;
        if (objects[i].brep) {
          let profile = _getMiddleSectionOfAWall(objects[i])
          let _callback = (v) => {
            return babylonVectorToArray(v);
          }
          let profileArrays = profile.map(v => _callback(v));
          currentObject['profile'] = profileArrays;
        }
        else {
          let orginalGlobalProfile = [];
          if (!_.isEmpty(objects[i]?.revitMetaData?.wallProfile)) {
            orginalGlobalProfile = objects[i].revitMetaData.wallProfile;
          }
          else{
            let _originalMesh = deSerializeMesh(objects[i].originalWallMesh);
            let _oldbrep = store.resurrect.resurrect(objects[i].originalWallMesh.brep);
            let _thickness = objects[i].calculateWidth();
            let options = {
              componentDoesNotExist: true,
              wallThickness: _thickness,
              brep: _oldbrep,
              mesh: _originalMesh
            }
            orginalGlobalProfile = _getMiddleSectionOfAWall(undefined, options );
            _originalMesh.dispose();
          }

          let originalMeshPosition = BABYLON.Vector3.FromArray(objects[i].originalWallMesh.meshes[0].position);
          let localProfile  = orginalGlobalProfile.map ( w => (new BABYLON.Vector3(w._x, w._y, w._z)).subtract(originalMeshPosition))
          let globalProfile = localProfile.map( l => convertLocalVector3ToGlobal(l, objects[i].mesh));


          currentObject['profile'] = globalProfile.map((v) => v.asArray());
        }
      }

      // `centerPosition` is the coordinates of the centroid of the mesh.
      // Differes from the `position` attribute, which is the global coordinates of the mesh's local origin.
      currentObject['meshes'][0]['centerPosition'] = [
        objects[i].mesh.getBoundingInfo().boundingBox.centerWorld._x,
        objects[i].mesh.getBoundingInfo().boundingBox.centerWorld._y,
        objects[i].mesh.getBoundingInfo().boundingBox.centerWorld._z
      ];


      if (objects[i].massType === "Beam") {
        let face = objects[i].brep.getFaces()[1];
        let faceVertices = getFaceVerticesFromFace(face, objects[i].mesh, BABYLON.Space.WORLD);

        returnObject['structures'][structure][level][typeName][i]['faceVertices'] = faceVertices;
      }

      if (objects[i].massType == "Ceiling") {
        if (objects[i].brep) {
          const bottomVertices = getBottomFaceVertices(objects[i], BABYLON.Space.WORLD)
            .map((vertex) => vertex.asArray());
          currentObject["bottomVertices"] = bottomVertices;

          const topVertices = getTopFaceVertices(objects[i], BABYLON.Space.WORLD)
            .map((vertex) => vertex.asArray());
          currentObject["topVertices"] = topVertices;

          currentObject["thickness"] = (topVertices[0][1] - bottomVertices[0][1]) * objects[i].mesh.scaling.y;
        }
        else {

          let positionOffset = objects[i].mesh.position.subtract(objects[i].revitMetaData.originalPosition);

          let profile = objects[i].revitMetaData.originalProfile.map(v => BABYLON.Vector3.FromArray([v[0], v[2], v[1]]).add(positionOffset)); 
          currentObject["topVertices"] = profile.map(v => v.asArray());

          let voids = []

          for (let vObj of objects[i].revitMetaData.originalVoids) {
            let key = vObj.id;
            let profile = vObj.path;

            voids.push({
              curveId: key,
              profile: profile.map(v => BABYLON.Vector3.FromArray([v[0], v[2], v[1]]).add(positionOffset).asArray())
            });
          }

          currentObject["voids"] = voids;
          currentObject["geometries"] = {}
        }
      }
      if (typeName == "walls") { // TODO: remove `geometries` for beams and columns after refactoring reconciliation in the revit addin
        currentObject["geometries"] = {}
      }
    }

    return returnObject;
  };

  const _getTransformedVertices = (mesh, meshForTransformationMatrix = undefined) => {
    let transformationMatrix = mesh.computeWorldMatrix(true);
    if (meshForTransformationMatrix) {
      transformationMatrix = meshForTransformationMatrix.computeWorldMatrix(true);
    }
    const inverseMatrix = _buildInverseMatrix(transformationMatrix);
    const vertexData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

    const transformedVertices = [];
    for (let i = 0; i < vertexData.length; i += 3) {
      const vertex = new BABYLON.Vector3(vertexData[i], vertexData[i + 1], vertexData[i + 2]);
      const transformedVertex = _applyTransformationMatrix(vertex, transformationMatrix, inverseMatrix);
      transformedVertices.push(...transformedVertex.asArray());
    }
    return transformedVertices;
  };

  const _applyTransformationMatrix = (vertex, transformationMatrix, inverseMatrix) => {
    const global = BABYLON.Vector3.TransformCoordinates(vertex, transformationMatrix);
    const backLocal = BABYLON.Vector3.TransformCoordinates(global, inverseMatrix);
    return backLocal;
  };

  const _buildInverseMatrix = (transformationMatrix) => {
    const inverseMatrix = new BABYLON.Matrix();
    inverseMatrix._m[0] = 1;
    inverseMatrix._m[1] = 0;
    inverseMatrix._m[2] = 0;
    inverseMatrix._m[3] = 0;
    inverseMatrix._m[4] = 0;
    inverseMatrix._m[5] = 1;
    inverseMatrix._m[6] = 0;
    inverseMatrix._m[7] = 0;
    inverseMatrix._m[8] = 0;
    inverseMatrix._m[9] = 0;
    inverseMatrix._m[10] = 1;
    inverseMatrix._m[11] = 0;
    inverseMatrix._m[12] = -transformationMatrix._m[12];
    inverseMatrix._m[13] = -transformationMatrix._m[13];
    inverseMatrix._m[14] = -transformationMatrix._m[14];
    inverseMatrix._m[15] = 1;

    return inverseMatrix;
  };

  const digestAddOnData = (structureCollection, addOnData) => {
    structureCollection = JSON.parse(structureCollection);
    for (const structure in addOnData.structures) {
      if (structure in structureCollection.structures) {
        for (const level in addOnData.structures[structure]) {
          if (level in structureCollection.structures[structure]) {
            for (const primitive in addOnData.structures[structure][level]) {
              structureCollection.structures[structure][level][primitive] =
                addOnData.structures[structure][level][primitive];
            }
          }
        }
      }
    }
    if (!structureCollection['metadata']) {
      structureCollection['metadata'] = {}
    }
    structureCollection['metadata']['revitDeleteIds'] = addOnData['deletedElements'];
    return JSON.stringify(structureCollection);
  };

  const getStructureCollectionForExport = () => {
    return digestAddOnData(
      StructureCollection.getInstance().getSnaptrudeJSON(true),
      addOnData()
    );
  };

  // Note: This method doesn't handle complex data types.
  // See https://stackoverflow.com/questions/11491938/issues-with-date-when-using-json-stringify-and-json-parse/11491993#11491993
  const _deepCopy = (object) => {
    if (!object) return;

    return JSON.parse(JSON.stringify(object));
  };

  const babylonVectorToArray = (vector) => [vector._x, vector._y, vector._z];

  const resetRevitDeletedElements = function () {
    deletedElements = [];
  }

  return {
    deletedElements,
    addOnData,
    digestAddOnData,
    getStructureCollectionForExport,
    _getTransformedVertices,
    resetRevitDeletedElements
  };
})();

export { revitDataPreparation };
