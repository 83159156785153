import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import Button from "../Button";
import Input from "../Input";
import teamBackround from "../../assets/backgrounds/teamBackground.svg";

import greenCursor from "../../assets/icons/green_tag_cursor.png";
import pinkCursor from "../../assets/icons/pink_tag_cursor.png";
import yellowCursor from "../../assets/icons/yellow_tag_cursor.png";
import { createTeam, searchExactTeam } from "../../services/team.service";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import HistoryContext from "../Context/HistoryContext";
import { useDispatch } from "react-redux";
import { selectTeam } from "../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import { teamNameCheck } from "../../common/utils/stringChecks";
import _ from "lodash";

const Wrapper = styled.div`
    position: relative;
    width: 35em;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: ${colors.primeBlack};
    padding: 1em;
    & > div{
        // border: 2px solid;
        margin: 0em 0.5em;
        padding: 0.25em;
        text-align: center;
    }
`
function PageOne({ updatePage, handleClose, setTeamId, headingText, inputLabelText, ...props }) {
    const [teamName, setTeamName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isFormSubmittng = useRef(false);
    const history = useContext(HistoryContext);
    const dispatch = useDispatch();

    const handleSubmit = async (evt) => {
        if (evt) evt.preventDefault();
        const check = teamNameCheck(teamName);
        if(check.invalid){
            showToast(check.reason, 3000, TOAST_TYPES.error);
            return;
        }
        if (isFormSubmittng.current) return;
        isFormSubmittng.current = true;
        setIsLoading(true);
        const sanitizedTeamName = _.trim(teamName);
        const resp = await createTeam(sanitizedTeamName);
        if (resp.status === "success") {
            showToast("Team created!", 3000, TOAST_TYPES.success);
            setTeamId(resp.team.id);
            dispatch(selectTeam(resp.team.id));
            history.push(resp.team.route)
            setTimeout(() => {
                updatePage(2);
            }, 100)
        } else if (resp.status === "fail") {
            showToast(resp.message, 3000, TOAST_TYPES.error);
            isFormSubmittng.current = false;
        } else if (resp.status === "error") {
            showToast(resp.message, 3000, TOAST_TYPES.error);
            isFormSubmittng.current = false;
        }
        setIsLoading(false);
    }
    return (
        <Wrapper>
            <img src={teamBackround} alt="team-background" style={{ position: "absolute" }} />
            <img src={greenCursor} alt="cursor-green" style={{ position: "absolute", top: "-10%", left: "60%" }} />
            <img src={pinkCursor} alt="cursor-pink" style={{ position: "absolute", top: "60%" }} />
            <img src={yellowCursor} alt="cursor-yellow" style={{ position: "absolute", top: "80%", left: "90%" }} />
            <div className="heading" style={{ marginTop: "1em" }}>{headingText || "Create your team"}</div>
            <div className="subheading">The go to collaborative design tool for<br />architecture teams</div>
            <div style={{ marginTop: "5em" }}>{inputLabelText || "Name your team"}</div>
            <div>
                <form onSubmit={handleSubmit}>
                    <Input autoFocus={true} type="text" placeholder="Team Name" value={teamName} onPress={(evt) => { setTeamName(evt.target.value) }} customInput={{ textAlign: "center" }} />
                </form>
            </div>
            <div className="actions" style={{ marginTop: "3em" }}>
                <div className="button-wrapper">
                    <Button customButtonStyle={{ backgroundColor: colors.fullWhite, color: colors.secondaryGrey }} title={"Do this later"} onPress={handleClose} />
                </div>
                <div className="button-wrapper">
                    <Button isLoading={isLoading} primary={true} title={"Name the team"} onPress={handleSubmit} />
                </div>
            </div>
            <div className="page-indicator" style={{ marginTop: "2em" }}>
                <span className="indicator active"></span>
                <span className="indicator"></span>
            </div>
        </Wrapper>
    );
}

export default PageOne;