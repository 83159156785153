import { store } from "../../modules/utilityFunctions/Store.js";
import { appElement } from "../bimDataFuncs.js";
import { orientation } from "../orientation.js";
import { draw_interior_drag } from "../fplan/drawInteriorsFplan.js";
import { getFillColor } from "./canvasServices.js";
// ##########################################
function snapObjectDrawScale(pol, threshX, threshY) {
  if (pol.get("type") != "polygon") {
    return;
  }
  let pt1, pt2, near_objX, near_objY;
  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (
      obj.get("id") == "rooms" &&
      obj.get("type") == "polygon" &&
      obj != pol &&
      pol.get("type") == "polygon"
    ) {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pol.points.forEach(function (pt11) {
        pt1 = Object.assign({}, pt11);
        console.log("POL", pol);
        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;
        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            near_objX = obj;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            near_objY = obj;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (X1 != undefined) {
        pol.scaleX = (pol.width + Math.abs(X1.x - X2.x)) / pol.width;
        pol.setCoords();
        if (
          near_objX.width / pol.width < 1.2 &&
          near_objX.width / pol.width > 0.8
        ) {
          pol.scaleX = near_objX.width / pol.width;
          pol.setCoords();
        }
      }
      if (Y1 != undefined) {
        pol.scaleY = (pol.height + Math.abs(Y1.y - Y2.y)) / pol.height;
        pol.setCoords();
        if (
          near_objY.height / pol.height < 1.2 &&
          near_objY.height / pol.height > 0.8
        ) {
          pol.scaleY = near_objY.height / pol.height;
          pol.setCoords();
        }
      }
    }
  });
}

// ##########################################

// ##########################################
function snapObjectDraw(pol, threshX, threshY) {
  if (pol.get("type") != "polygon") {
    return;
  }
  let pt1, pt2, near_objX, near_objY;
  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (
      obj.get("id") == "rooms" &&
      obj.get("type") == "polygon" &&
      obj != pol &&
      pol.get("type") == "polygon"
    ) {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pol.points.forEach(function (pt11) {
        pt1 = Object.assign({}, pt11);

        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;
        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            near_objX = obj;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            near_objY = obj;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (X1 != undefined) {
        pol.left -= X1.x - X2.x;
        pol.setCoords();
      }
      if (Y1 != undefined) {
        pol.top -= Y1.y - Y2.y;
        pol.setCoords();
      }
    }
  });
}

// ##########################################

// ##########################################
function snapObject(pol, threshX, threshY) {
  if (pol.get("type") != "polygon") {
    return;
  }
  let pt1, pt2, near_objX, near_objY;

  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (
      obj.get("id") == "rooms" &&
      obj.get("type") == "polygon" &&
      obj != pol &&
      pol.get("type") == "polygon"
    ) {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pol.points.forEach(function (pt11) {
        pt1 = Object.assign({}, pt11);

        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;
        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (X1 != undefined) {
        pol.left -= X1.x - X2.x;
        pol.setCoords();
      }
      if (Y1 != undefined) {
        pol.top -= Y1.y - Y2.y;
        pol.setCoords();
      }
    }
  });
}

// ##########################################

// ##########################################
function snapObjectScale(pol, threshX, threshY) {
  if (pol.get("type") != "polygon") {
    return;
  }
  let pt1, pt2, near_objX, near_objY;

  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (
      obj.get("id") == "rooms" &&
      obj.get("type") == "polygon" &&
      obj != pol &&
      pol.get("type") == "polygon"
    ) {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pol.points.forEach(function (pt11) {
        pt1 = Object.assign({}, pt11);

        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;
        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          } else if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (X1 != undefined) {
        var left_temp = pol.left;
        pol.scaleX -= (X1.x - X2.x) / pol.width;
        pol.setCoords();
      }
      if (Y1 != undefined) {
        var top_temp = pol.top;
        pol.scaleY -= (Y1.y - Y2.y) / pol.height;
        pol.setCoords();
      }
    }
  });
}

// ##########################################

// ##########################################
function snapObjectEdge(pol, pt, threshX, threshY, orientation) {
  var o = store.canvas.getObjects();
  let pt1, pt2, near_objX, near_objY;

  o.forEach(function (obj) {
    if (obj.get("id") == "rooms" && obj.get("type") == "polygon") {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pt.forEach(function (pt11) {
        pt1 = Object.assign({}, pt11);

        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;

        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        });
      });
      if (orientation == "x") {
        if (X1 != undefined) {
          pt[0].x -= X1.x - X2.x;
          pt[1].x -= X1.x - X2.x;
          pol.setCoords();
        }
      }
      if (orientation == "y") {
        if (Y1 != undefined) {
          pt[0].y -= Y1.y - Y2.y;
          pt[1].y -= Y1.y - Y2.y;
          pol.setCoords();
        }
      }
    }
  });
  return pt;
}

function snapCurveVert(pol, pt, threshX, threshY) {
  
  var X1, X2, Y1, Y2;
  var meanXX = 0;
  var meanYY = 0;
  // for (var i = 0; i < pol.points.length; i++) {
  //     meanXX = meanXX + pol.points[i].x;
  //     meanYY = meanYY + pol.points[i].y;
  // }
  //console.log(pol);
  //console.log(pt);
  var objPath = pol.path;
  var pointsLength = 0;
  for (var i = 0; i < objPath.length; i++) {
    var type = objPath[i][0];
    if (type === "M" || type === "L") {
      meanXX = meanXX + objPath[i][1];
      meanYY = meanYY + objPath[i][2];
      pointsLength += 1;
    }
    if (type === "C") {
      for (var j = 1; j < objPath[i].length; j += 2) {
        meanXX = meanXX + objPath[i][j];
        meanYY = meanYY + objPath[i][j + 1];
        pointsLength += 1;
      }
    }
  }

  meanXX = meanXX / pointsLength;
  meanYY = meanYY / pointsLength;
  var snap_threshX = threshX;
  var snap_threshY = threshY;
  pt.forEach(function (pt11) {
    var pt1 = Object.assign({}, pt11);

    if (pt1.x > meanXX) {
      //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
      pt1.x =
        pol.left +
        (pt1.x + (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX - pol.minX);
    } else {
      pt1.x = pol.left + (pt1.x - pol.minX);
    }
    if (pt1.y > meanYY) {
      //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
      pt1.y =
        pol.top +
        (pt1.y + (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY - pol.minY);
    } else {
      pt1.y = pol.top + (pt1.y - pol.minY);
    }

    // var meanX = 0;
    // var meanY = 0;
    // for (var i = 0; i < obj.points.length; i++) {
    //     meanX = meanX + obj.points[i].x;
    //     meanY = meanY + obj.points[i].y;
    // }
    // meanX = meanX / obj.points.length;
    //
    // meanY = meanY / obj.points.length;

    // pol.points.forEach(function (pt22) {
    //     var pt2 = Object.assign({}, pt22);
    //
    //     if (pt2.x > meanX) {
    //         pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
    //     }
    //     else {
    //         pt2.x = obj.left + (pt2.x - obj.minX);
    //     }
    //     if (pt2.y > meanY) {
    //         pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
    //     }
    //     else {
    //         pt2.y = obj.top + (pt2.y - obj.minY);
    //     }
    //     if ((pt1.x !== pt2.x) && (pt1.y !== pt2.y)) {
    //         if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
    //             X1 = pt1;
    //             X2 = pt2;
    //             snap_threshX = Math.abs(pt1.x - pt2.x);
    //         }
    //         if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
    //             Y1 = pt1;
    //             Y2 = pt2;
    //             snap_threshY = Math.abs(pt1.y - pt2.y);
    //         }
    //     }
    // });
    for (var i = 0; i < objPath.length; i++) {
      var type = objPath[i][0];
      if (type === "M" || type === "L") {
        if (objPath[i][1] > meanXX) {
          //objPath[i][1] = pol.left + (objPath[i][1] + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          objPath[i][1] =
            pol.left +
            (objPath[i][1] +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          objPath[i][1] = pol.left + (objPath[i][1] - pol.minX);
        }
        if (objPath[i][2] > meanYY) {
          //objPath[i][2] = pol.top + (objPath[i][2] + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          objPath[i][2] =
            pol.top +
            (objPath[i][2] +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          objPath[i][2] = pol.top + (objPath[i][2] - pol.minY);
        }
        if (pt1.x !== objPath[i][1] && pt1.y !== objPath[i][2]) {
          var pt2 = { x: objPath[i][1], y: objPath[i][2] };
          if (Math.abs(pt1.x - objPath[i][1]) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - objPath[i][1]);
          }
          if (Math.abs(pt1.y - objPath[i][2]) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - objPath[i][2]);
          }
        }
      }
      if (type === "C") {
        for (var j = 1; j < objPath[i].length; j += 2) {
          if (objPath[i][j] > meanXX) {
            // objPath[i][j] = pol.left + (objPath[i][j] + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
            objPath[i][j] =
              pol.left +
              (objPath[i][j] +
                (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
                pol.minX);
          } else {
            objPath[i][j] = pol.left + (objPath[i][j] - pol.minX);
          }
          if (objPath[i][j + 1] > meanYY) {
            //objPath[i][j + 1] = pol.top + (objPath[i][j + 1] + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
            objPath[i][j + 1] =
              pol.top +
              (objPath[i][j + 1] +
                (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
                pol.minY);
          } else {
            objPath[i][j + 1] = pol.top + (objPath[i][j + 1] - pol.minY);
          }
          if (pt1.x !== objPath[i][j] && pt1.y !== objPath[i][j + 1]) {
            var pt2 = { x: objPath[i][j], y: objPath[i][j + 1] };
            if (Math.abs(pt1.x - objPath[i][j]) < snap_threshX) {
              X1 = pt1;
              X2 = pt2;
              snap_threshX = Math.abs(pt1.x - objPath[i][j]);
            }
            if (Math.abs(pt1.y - objPath[i][j + 1]) < snap_threshY) {
              Y1 = pt1;
              Y2 = pt2;
              snap_threshY = Math.abs(pt1.y - objPath[i][j + 1]);
            }
          }
        }
      }
    }
  });
  if (X1 !== undefined) {
    pt[0].x -= X1.x - X2.x;
    pol.setCoords();
  }
  if (Y1 !== undefined) {
    pt[0].y -= Y1.y - Y2.y;
    pol.setCoords();
  }
  //console.log(X1, Y1, X2, Y2, pt);
  return pt;
}

function snapObjectVert(pol, pt, threshX, threshY) {
  
  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (obj.get("id") === "rooms" && obj.get("type") === "polygon") {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (var i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pt.forEach(function (pt11) {
        var pt1 = Object.assign({}, pt11);

        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (var i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;

        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          var pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          if (pt1.x !== pt2.x && pt1.y !== pt2.y) {
            if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
              X1 = pt1;
              X2 = pt2;
              snap_threshX = Math.abs(pt1.x - pt2.x);
            }
            if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
              Y1 = pt1;
              Y2 = pt2;
              snap_threshY = Math.abs(pt1.y - pt2.y);
            }
          }
        });
      });
      if (X1 !== undefined) {
        pt[0].x -= X1.x - X2.x;
        pol.setCoords();
      }
      if (Y1 !== undefined) {
        pt[0].y -= Y1.y - Y2.y;
        pol.setCoords();
      }
    }
  });
  return pt;
}

function snapObjectVertCruve(pol, pt, threshx, threshY) {}

function snapTapeVert(pt, threshX, threshY) {
  let pt1, pt2, near_objX, near_objY;

  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (obj.get("id") == "rooms" && obj.get("type") == "polygon") {
      var X1, X2, Y1, Y2;
      var pt1 = pt;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      var meanX = 0;
      var meanY = 0;
      for (let i = 0; i < obj.points.length; i++) {
        meanX = meanX + obj.points[i].x;
        meanY = meanY + obj.points[i].y;
      }
      meanX = meanX / obj.points.length;

      meanY = meanY / obj.points.length;

      obj.points.forEach(function (pt22) {
        pt2 = Object.assign({}, pt22);

        if (pt2.x > meanX) {
          //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
          pt2.x =
            obj.left +
            (pt2.x +
              (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
              obj.minX);
        } else {
          pt2.x = obj.left + (pt2.x - obj.minX);
        }
        if (pt2.y > meanY) {
          //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
          pt2.y =
            obj.top +
            (pt2.y +
              (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
              obj.minY);
        } else {
          pt2.y = obj.top + (pt2.y - obj.minY);
        }
        if (pt1.x != pt2.x && pt1.y != pt2.y) {
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
        }
      });
    }
    if (X1 != undefined) {
      pt.x -= X1.x - X2.x;
    }
    if (Y1 != undefined) {
      pt.y -= Y1.y - Y2.y;
    }
  });
  return pt;
}

function snapObjectEdgeExt(pol, pt, threshX, threshY, orientation) {
  let pt1, pt2, near_objX, near_objY;

  var o = store.canvas.getObjects();
  o.forEach(function (obj) {
    if (
      obj.get("id") == "rooms" &&
      obj.get("type") == "polygon" &&
      obj != pol
    ) {
      var X1, X2, Y1, Y2;
      var meanXX = 0;
      var meanYY = 0;
      for (let i = 0; i < pol.points.length; i++) {
        meanXX = meanXX + pol.points[i].x;
        meanYY = meanYY + pol.points[i].y;
      }
      meanXX = meanXX / pol.points.length;
      meanYY = meanYY / pol.points.length;
      var snap_threshX = threshX;
      var snap_threshY = threshY;
      pt.forEach(function (pt11) {
        pt1 = Object.assign({}, pt11);

        if (pt1.x > meanXX) {
          //pt1.x = pol.left + (pt1.x + pol.getScaledWidth() * (pol.scaleX - 1) / pol.scaleX - pol.minX);
          pt1.x =
            pol.left +
            (pt1.x +
              (pol.getWidth() * (pol.scaleX - 1)) / pol.scaleX -
              pol.minX);
        } else {
          pt1.x = pol.left + (pt1.x - pol.minX);
        }
        if (pt1.y > meanYY) {
          //pt1.y = pol.top + (pt1.y + pol.getScaledHeight() * (pol.scaleY - 1) / pol.scaleY - pol.minY);
          pt1.y =
            pol.top +
            (pt1.y +
              (pol.getHeight() * (pol.scaleY - 1)) / pol.scaleY -
              pol.minY);
        } else {
          pt1.y = pol.top + (pt1.y - pol.minY);
        }

        var meanX = 0;
        var meanY = 0;
        for (let i = 0; i < obj.points.length; i++) {
          meanX = meanX + obj.points[i].x;
          meanY = meanY + obj.points[i].y;
        }
        meanX = meanX / obj.points.length;

        meanY = meanY / obj.points.length;

        obj.points.forEach(function (pt22) {
          pt2 = Object.assign({}, pt22);

          if (pt2.x > meanX) {
            //pt2.x = obj.left + (pt2.x + obj.getScaledWidth() * (obj.scaleX - 1) / obj.scaleX - obj.minX);
            pt2.x =
              obj.left +
              (pt2.x +
                (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX -
                obj.minX);
          } else {
            pt2.x = obj.left + (pt2.x - obj.minX);
          }
          if (pt2.y > meanY) {
            //pt2.y = obj.top + (pt2.y + obj.getScaledHeight() * (obj.scaleY - 1) / obj.scaleY - obj.minY);
            pt2.y =
              obj.top +
              (pt2.y +
                (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
                obj.minY);
          } else {
            pt2.y = obj.top + (pt2.y - obj.minY);
          }
          // if ((pt1.x != pt2.x) && (pt1.y != pt2.y)){
          if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
            X1 = pt1;
            X2 = pt2;
            snap_threshX = Math.abs(pt1.x - pt2.x);
          }
          if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
            Y1 = pt1;
            Y2 = pt2;
            snap_threshY = Math.abs(pt1.y - pt2.y);
          }
          // }
        });
      });
      if (orientation == "x") {
        if (X1 != undefined) {
          pt[0].x -= X1.x - X2.x;
          pt[1].x -= X1.x - X2.x;
          pol.setCoords();
        }
      }
      if (orientation == "y") {
        if (Y1 != undefined) {
          pt[0].y -= Y1.y - Y2.y;
          pt[1].y -= Y1.y - Y2.y;
          pol.setCoords();
        }
      }
    }
  });
  return pt;
}

function snapObjectEdgeExtDimension(pol, pt, spt, prec, orientation) {
  let pt1, pt2, near_objX, near_objY;

  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  var X1, X2, Y1, Y2;
  var meanXX = 0;
  var meanYY = 0;
  for (let i = 0; i < pol.points.length; i++) {
    meanXX = meanXX + pol.points[i].x;
    meanYY = meanYY + pol.points[i].y;
  }
  meanXX = meanXX / pol.points.length;
  meanYY = meanYY / pol.points.length;
  var pts = [];
  pt.forEach(function (pt11) {
    pt1 = Object.assign({}, pt11);
    // if (pt1.x > meanXX){
    // 	pt1.x = pol.left + (pt1.x+pol.getScaledWidth()*(pol.scaleX-1)/pol.scaleX - pol.minX);
    // }
    // else{
    // 	pt1.x = pol.left + (pt1.x - pol.minX);
    // }
    // if (pt1.y > meanYY){
    // 	pt1.y = pol.top + (pt1.y + pol.getScaledHeight()*(pol.scaleY-1)/pol.scaleY  - pol.minY);
    // }
    // else{
    // 	pt1.y = pol.top + (pt1.y - pol.minY);
    // }
    pts.push(pt1);
  });
  if ($scope.unit.value === "meters") {
    var dist = (getDistance(pts[0], pts[1]) * 2.54) / 100;
    var distDelta = (dist - Math.round(dist / prec) * prec) / 2;
  } else {
    var dist = getDistance(pts[0], pts[1]);
    var distDelta = (dist - Math.round(dist / prec) * prec) / 2;
  }
  pt[0].x = pt[0].x + (distDelta * (pts[1].x - pts[0].x)) / dist;
  pt[0].y = pt[0].y + (distDelta * (pts[1].y - pts[0].y)) / dist;
  spt[0].x = spt[0].x + (distDelta * (pts[1].x - pts[0].x)) / dist;
  spt[0].y = spt[0].y + (distDelta * (pts[1].y - pts[0].y)) / dist;
  pt[1].x = pt[1].x - (distDelta * (pts[1].x - pts[0].x)) / dist;
  pt[1].y = pt[1].y - (distDelta * (pts[1].y - pts[0].y)) / dist;
  spt[1].x = spt[1].x - (distDelta * (pts[1].x - pts[0].x)) / dist;
  spt[1].y = spt[1].y - (distDelta * (pts[1].y - pts[0].y)) / dist;
  return pt;
}

function getDistance(p1, p2) {
  return Math.sqrt(Math.pow(p2.y - p1.y, 2) + Math.pow(p2.x - p1.x, 2));
}

function snapNewObjectDimensions(pol, prec) {
  
  var n = pol.points.length;
  for (var i = 0; i < pol.points.length; i++) {
    var centSlopeX = pol.points[(i + 1) % n].x - pol.points[i].x;
    var centSlopeY = pol.points[(i + 1) % n].y - pol.points[i].y;

    if (centSlopeX != 0) {
      store.angle = (Math.atan(centSlopeY / centSlopeX) * 180) / Math.PI;
    } else {
      store.angle = 90;
    }
    if (Math.abs(store.angle) > 85) {
      orientation = "x";
    } else if (Math.abs(store.angle) < 5) {
      orientation = "y";
    } else if (
      Math.abs(store.angle) > 5 &&
      Math.abs(store.angle) < 85 &&
      store.angle < 0
    ) {
      orientation = "a";
    } else if (
      Math.abs(store.angle) > 5 &&
      Math.abs(store.angle) < 85 &&
      store.angle > 0
    ) {
      orientation = "a";
    }
    if (i > 0)
      snapObjectEdgeExtDimension(
        pol,
        [pol.points[(i + 1) % n], pol.points[i % n]],
        [pol.points[(i + 2) % n], pol.points[(i - 1) % n]],
        prec,
        orientation
      );
    else
      snapObjectEdgeExtDimension(
        pol,
        [pol.points[(i + 1) % n], pol.points[i % n]],
        [pol.points[(i + 2) % n], pol.points[(n - 1) % n]],
        prec,
        orientation
      );
    // snapObjectVert(pol, [pol.points[(i)%n]], threshX, threshY);
  }
  var pol2 = draw_interior_drag(pol.points, pol, getFillColor(pol));
  store.canvas.remove(pol);
  return pol2;
}

function snapNewObject(pol, threshX, threshY) {
  
  var n = pol.points.length;
  for (var i = 0; i < pol.points.length; i++) {
    var centSlopeX = pol.points[(i + 1) % n].x - pol.points[i].x;
    var centSlopeY = pol.points[(i + 1) % n].y - pol.points[i].y;

    if (centSlopeX != 0) {
      store.angle = (Math.atan(centSlopeY / centSlopeX) * 180) / Math.PI;
    } else {
      store.angle = 90;
    }
    if (Math.abs(store.angle) > 85) {
      orientation = "x";
    } else if (Math.abs(store.angle) < 5) {
      orientation = "y";
    } else if (
      Math.abs(store.angle) > 5 &&
      Math.abs(store.angle) < 85 &&
      store.angle < 0
    ) {
      orientation = "a";
    } else if (
      Math.abs(store.angle) > 5 &&
      Math.abs(store.angle) < 85 &&
      store.angle > 0
    ) {
      orientation = "a";
    }
    // snapObjectVert(pol, [pol.points[(i)%n]], threshX, threshY);
    // snapObjectVert(pol, [pol.points[(i)%n]], threshX, threshY);
    snapObjectEdgeExt(
      pol,
      [pol.points[(i + 1) % n], pol.points[i % n]],
      threshX,
      threshY,
      orientation
    );
    // snapObjectVert(pol, [pol.points[(i)%n]], threshX, threshY);
  }
  var n = pol.fill.slice(5, pol.fill.length - 2).split(",");
  let fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  var n2 = 1.37;
  for (let p = 0; n2 % 1 != 0; p++) {
    n2 = (n[0] - 5 + 255 * p) / 53;
  }
  var pol2 = draw_interior_drag(pol.points, pol, getFillColor(pol));
  store.canvas.remove(pol);
  return pol2;
}
export {
  snapObjectDrawScale,
  snapObjectDraw,
  snapObject,
  snapObjectScale,
  snapObjectEdge,
  snapCurveVert,
  snapObjectVert,
  snapObjectVertCruve,
  snapTapeVert,
  snapObjectEdgeExt,
  snapObjectEdgeExtDimension,
  getDistance,
  snapNewObjectDimensions,
  snapNewObject,
};
