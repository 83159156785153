import BABYLON, { AdvancedDynamicTexture } from "../modules/babylonDS.module";
import { store, resetStore } from "../modules/utilityFunctions/Store";
import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine";
import PolyBool from "polybooljs";
import mapboxgl from 'mapbox-gl';
import Resurrect from "resurrect-js";
import { ProjectProperties } from "../modules/projectProperties";
import { FLOORKEY_DETAILS } from "../modules/utilityFunctions/CONSTANTS/floorkey_details";
import {} from "../modules/utilityFunctions/expose";
import {revitDataPreparation} from "../modules/revitExport/revitExport";
// Commented import mixpanel from 'mixpanel-browser';

mapboxgl.accessToken = "pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw";

export const babylonInit = (floorkey) => {
  if(store.scene) store.scene.dispose();
  if(store.newScene) store.newScene.dispose();
  if(store.engine) store.engine.dispose();
  resetStore();
  revitDataPreparation.resetRevitDeletedElements();
  window.store = store;
  console.log("BABYLON INIT");
  store.projectProperties = new ProjectProperties();
  store.floorkey = floorkey;
  store.jwt = localStorage.user;
  store.floorkey_details[store.floorkey] = FLOORKEY_DETAILS;
  store.bool = PolyBool;
  store.canvas = document.getElementById("canvas");
  // store.canvas.addEventListener("keydown", keydown, false);
  store.engine = new BABYLON.Engine(store.canvas, true, {preserveDrawingBuffer: true, stencil: true});
  store.engine.getCaps().parallelShaderCompile = null;
  store.scene = new BABYLON.Scene(store.engine);
  store.newScene = store.scene;
  store.userid =  localStorage.id;
  store.infoUser = {id: localStorage.id, email: localStorage.email, name: localStorage.name};

  // Commented mixpanel.identify(store.infoUser.name);
  // Commented mixpanel.people.set_once({
  //   "$name": store.infoUser.name,
  //   "$email": store.infoUser.email,
  //   "$created_at": new Date().toString(),
  // });

  window.analytics.identify(store.infoUser.id, {
    'name': store.infoUser.name,
    'email': store.infoUser.email,
  });

  window.analytics.track('3D Started');

  store.newScene.executeWhenReady(() => {
    store.resurrect = new Resurrect({resolver: new Resurrect.NamespaceResolver(window.snapmda), cleanup: true, })
    store.resolveEngineUtils = new ResolveEngineUtils();
    import("../libs/init")
    .then(({Snaptrude}) => {
      Snaptrude.init();
    })
    .catch((err) => {
      console.log("Error in setuping babylon init.", err);
    })
  })
  store.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
  store.advancedTexture.useInvalidateRectOptimization = false;

  //(Need to remove this soon) TODO: ADITYA
  const mapContainer = document.getElementById("mapContainer");
  // store.map = new mapboxgl.Map({
  //   container: "mapContainer",
  //   style: 'mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g',
  //   center: [-77.04, 38.907],
  //   zoom: 15,
  //   minZoom: 15
  // });
  
  // window.showComments = () => {
  //   document.getElementById("comment-for-demo-1").style.display = "flex";
  //   document.getElementById("comment-for-demo-2").style.display = "flex";
  // }
  // window.hideComments = () => {
  //   console.log("Helo")
  //   document.getElementById("comment-for-demo-1").style.display = "none";
  //   document.getElementById("comment-for-demo-2").style.display = "none";
  // }
}