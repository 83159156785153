import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Route, Redirect, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { routes } from "./constants";
import {
  ACCOUNT,
  TEAM,
  PLANS,
  BILLING,
} from "../containers/dashboard/profile/constants";
import LoginSignupPage from "../containers/loginSignup/index";
import Dashboard from "../containers/dashboard/index";
import ChangePassword from "../containers/changePassword/index";
import Payment from "../containers/payment/index";
import NotFound from "../containers/notFound/index";
import Editor from "../containers/editor/index";
import Render from "../containers/render/index";
import SharedProject from "../containers/sharedProject/index";

import AuthService from "../services/auth.service";
import { Provider } from "react-redux";
import reduxStore from "../snaptrude/stateManagers/store/reduxStore";

import { createContext } from "react";
import HistoryContext from "../components/Context/HistoryContext";

import TestComponent from "../components/Test";
import { store } from "../snaptrude/modules/utilityFunctions/Store";
import ResetPassword from "../containers/loginSignup/ResetPassword";
import FileNotFound from "../containers/FileNotFound";
import Activate from "../containers/Activate";
import TypeForm from "../components/TypeForm";
import AppLoadingScreen from "../components/Loading/AppLoadingScreen";
import Plans from "../containers/dashboard/profile/Plans";
const history = createBrowserHistory();

Sentry.init({
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const RoutesContainer = () => {
  let [loggedIn, setLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      document.title = "Snaptrude";
    })

    loggedIn = AuthService.isAuthenticated();
    setLogin(loggedIn);

    return ()=> {
      unlisten();
    }
  }, []);

  return (
    <Provider store={reduxStore}>
    <Router history={history} onUpdate>
      <div id="toastWrapper"/>
      <HistoryContext.Provider value={history}>
        {
          isLoading ? 
          <Switch>
            <Route path="*">
              <AppLoadingScreen handleClose={() => {setIsLoading(false)}} />
            </Route>
          </Switch>
          :
        <Switch>
          <Route exact path="/test-component">
            <TestComponent />
          </Route>
          <Route exact path="/">
            <Redirect to={routes.dashboard} />
          </Route>
          <Route path={routes.login}>
            <LoginSignupPage />
          </Route>
          <Route path={"/directSignin"} exact>
            <Redirect to={routes.login} />
          </Route>
          <Route path={routes.signup} exact>
            <LoginSignupPage />
          </Route>
          <Route path="/directSignup" exact>
            <Redirect to={routes.signup} />
          </Route>
          <Route path={routes.forgotPassword} exact>
            <LoginSignupPage />
          </Route>
          <Route path={routes.resetPassword} exact>
            <ResetPassword />
          </Route>

          <Route path={routes.sharedProject} exact>
            <SharedProject />
          </Route>
          <Route exact path={routes.fileNotFound}>
            <FileNotFound />
          </Route>
          <Route exact path={routes.visualizeYourHome}>
            <TypeForm dataTfWdiget={"vQ2m31fq"} />
          </Route>
          <Route exact path={routes.pinterestTo3Dmodel}>
            <TypeForm dataTfWdiget={"S71DC9yP"} />
          </Route>
          <Route exact path={routes.yourHomeIn3D}>
            <TypeForm dataTfWdiget={"HnqvgTNv"} />
          </Route>
          <Route exact path={routes.floorplanTo3Dmodel}>
            <TypeForm dataTfWdiget={"aVmRdI0b"} />
          </Route>
          <Route exact path={routes.activate}>
            <Activate />
          </Route>
          <PrivateRoute isAuthenticated={loggedIn}>
            <Route path={routes.selectProfession} exact>
              <LoginSignupPage />
            </Route>
            <Route path={routes.project1} exact>
              <Dashboard workspaceHeading={"Project 1"} />
            </Route>
            <Route path={routes.project2} exact>
              <Dashboard workspaceHeading={"Project 1"} />
            </Route>
            <Route path={routes.dashboard} exact>
              <Dashboard workspaceHeading={"Project 1"} />
            </Route>
            <Route path={`${routes.dashboard}/folder`}>
              <Dashboard />
            </Route>
            <Route path={routes.recentProjects} exact>
              <Dashboard workspaceHeading={"Recent Projects"} />
            </Route>
            <Route path={routes.modals} exact>
              <Dashboard workspaceHeading={"Modals"} />
            </Route>
            <Route path={routes.sharedWithMe} exact>
              <Dashboard workspaceHeading={"Shared With Me"} />
            </Route>
            <Route path={routes.libraries} exact>
              <Dashboard workspaceHeading={"Libraries"} />
            </Route>
            <Route path={routes.preferences} exact>
              <Dashboard workspaceHeading={"Preferences"} />
            </Route>
            <Route path={routes.team} >
              <Dashboard workspaceHeading={"Team"} />
            </Route>
            <Route path={routes.tutorials} exact>
              <Dashboard />
            </Route>
            <Route path={routes.workspace} exact>
              <Dashboard workspaceHeading={"Project n"} />
            </Route>
            <Route path={routes.guidedProjects} exact>
              <Dashboard />
            </Route>
            <Route path={routes.help} exact>
              <NotFound />
            </Route>
            <Route path={routes.profile} exact>
              <Dashboard selectedOption={ACCOUNT} />
            </Route>
            <Route path={routes.account} exact>
              <Dashboard selectedOption={ACCOUNT} />
            </Route>
            <Route path={routes.teams} exact>
              <Dashboard selectedOption={TEAM} />
            </Route>
            <Route path={routes.billing} exact>
              <Dashboard selectedOption={BILLING} />
            </Route>
            <Route path={routes.plans} exact>
              <Plans showBackButton={true} />
            </Route>
            <Route path={routes.changePassword} exact>
              <ChangePassword />
            </Route>
            <Route path={routes.payment} exact>
              <Payment />
            </Route>
            <Route path={routes.editor} exact>
              <Provider store={reduxStore}>
                <Editor />
              </Provider>
            </Route>
            <Route path={routes.render} exact>
              <Provider store={reduxStore}>
                <Render />
              </Provider>
            </Route>
            <Route>
              <NotFound />
            </Route>
          </PrivateRoute>
        </Switch>
      }
      </HistoryContext.Provider>
    </Router>
    </Provider>
  );
};

const PrivateRoute = (props) => {
  return (
    <Route
      render={({ location }) =>
        AuthService.isAuthenticated() ? (
          <Switch>{props.children}</Switch>
        ) : (
          <Redirect
            to={{ pathname: routes.login, state: { from: location } }}
          />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
  isAuthenticated: PropTypes.bool,
  children: PropTypes.any,
};

export default RoutesContainer;
