import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  getWallMeshLineSegmentSlower,
  getWallMeshLineSegment,
  checkForId,
} from "./wall_generation_helper.js";
import { removeDuplicates } from "./arrayFuncs.js";
import {
  calculateEuclidianDistance,
  getOrientation,
} from "./polygon_dip_workers.js";
function moveWallMesh(mesh, face, thickness = 0) {
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

  let mesh_direction = mesh.getBoundingInfo().boundingBox.directions[0];

  if (face.axis === "z")
    mesh_direction = mesh.getBoundingInfo().boundingBox.directions[2];

  let tree = getWallMeshLineSegmentSlower(mesh),
    adjust = 1;
  if (Math.abs(tree[4] - tree[1]) > 2 && Math.abs(tree[3] - tree[0]) > 2) {
    mesh_direction.z = tree[4] - tree[1];
    mesh_direction.x = tree[3] - tree[0];
    adjust = -1;
  }

  let thickness_x =
    thickness *
    Math.cos(Math.atan(mesh_direction.z / mesh_direction.x)) *
    adjust;
  let thickness_z =
    thickness * Math.sin(Math.atan(mesh_direction.z / mesh_direction.x));

  console.log("Changes thickness :" + thickness_x, thickness_z);

  if (face.axis === "x") {
    if (face.type === "max") {
      for (let i = 0; i < verData.length; i += 3) {
        if (
          Math.round(verData[i] * 100) / 100 ===
          Math.round(face.value * 100) / 100
        ) {
          verData[i] = verData[i] - thickness_x;
          verData[i + 2] = verData[i + 2] + thickness_z;
        }
      }
    } else {
      for (let i = 0; i < verData.length; i += 3) {
        if (
          Math.round(verData[i] * 100) / 100 ===
          Math.round(face.value * 100) / 100
        ) {
          verData[i] = verData[i] + thickness_x;
          verData[i + 2] = verData[i + 2] - thickness_z;
        }
      }
    }
  } else {
    if (face.type === "max") {
      for (let i = 2; i < verData.length; i += 3) {
        if (
          Math.round(verData[i] * 100) / 100 ===
          Math.round(face.value * 100) / 100
        ) {
          verData[i] = verData[i] - thickness_z;
          verData[i - 2] = verData[i - 2] + thickness_x;
        }
      }
    } else {
      for (let i = 2; i < verData.length; i += 3) {
        if (
          Math.round(verData[i] * 100) / 100 ===
          Math.round(face.value * 100) / 100
        ) {
          verData[i] = verData[i] + thickness_z;
          verData[i - 2] = verData[i - 2] - thickness_x;
        }
      }
    }
  }
  mesh.geometry.setVerticesData(
    BABYLON.VertexBuffer.PositionKind,
    verData,
    true
  );
  let bbinfo = mesh.getBoundingInfo();
  let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mesh.setPivotPoint(centroid);
}

function moveMeshInGroup(mesh) {
  if (!mesh) return;

  let meshLS;
  let counterClkMeshes = [];
  let clkMeshes = [];
  let neighbourMeshes = mesh.neighborsWallMesh;

  try {
    if (!mesh.neighborsWallMesh) {
      mesh.neighborsWallMesh = [];
      neighbourMeshes = [];
      throw "No neighbors found";
    }
    mesh.neighborsWallMesh = removeDuplicates(mesh.neighborsWallMesh);

    // if(!mesh.lineSegmentForm){
    //     meshLS = getWallMeshLineSegment(mesh);
    //     mesh.lineSegmentForm = meshLS;
    // }
    // else meshLS = mesh.lineSegmentForm;
    meshLS = getWallMeshLineSegment(mesh);
  } catch (e) {
    console.log(e);
  }

  if (mesh.moveMeshObj) delete mesh.moveMeshObj;
  let obj = {};

  for (let i = 0; i < neighbourMeshes.length; i++) {
    let orient,
      curr_near_faces = [];
    let currMesh = store.scene.getMeshByUniqueID(neighbourMeshes[i].id);
    if (!currMesh) continue;

    currMesh.prevVectorData = null;
    delete currMesh.prevVectorData; //very important as it updates the neighbours connection
    delete currMesh.prevDiagonalLen;

    let Ljoint = false;
    let Tjoint = false;
    currMesh.refreshBoundingInfo();

    let pointsArr = getWallMeshLineSegment(currMesh);
    let curMeshIndices = [];
    // pointsArr= currMesh.lineSegmentForm;
    // if(!pointsArr){
    //     pointsArr= getWallMeshLineSegment(currMesh);
    //     currMesh.lineSegmentForm = pointsArr;
    // }

    let d1 = calculateEuclidianDistance(meshLS, pointsArr);
    let d2 = calculateEuclidianDistance(meshLS, pointsArr.slice(3));

    let d3 = calculateEuclidianDistance(meshLS.slice(3), pointsArr);
    let d4 = calculateEuclidianDistance(meshLS.slice(3), pointsArr.slice(3));
    let thickness = Math.pow(
      currMesh._properties._thickness * store.unit_scale,
      2
    );

    if (d1 + d2 < d3 + d4) {
      neighbourMeshes[i].nearPoint = JSON.stringify(meshLS.slice(0, 3));
    } else neighbourMeshes[i].nearPoint = JSON.stringify(meshLS.slice(3));

    if (neighbourMeshes[i].type === "collinear") continue;

    let curr_verdata = currMesh.getVerticesData(
      BABYLON.VertexBuffer.PositionKind
    );
    let worldMat = currMesh.getWorldMatrix();

    if (d1 + d3 < d2 + d4) {
      orient = getOrientation(
        { x: meshLS[0], y: meshLS[1] },
        { x: meshLS[3], y: meshLS[4] },
        { x: pointsArr[3], y: pointsArr[4] }
      );
      for (let i = 0; i < curr_verdata.length; i += 3) {
        let coord = new BABYLON.Vector3(
          curr_verdata[i],
          curr_verdata[i + 1],
          curr_verdata[i + 2]
        );
        let coord2 = BABYLON.Vector3.TransformCoordinates(coord, worldMat);
        if (
          calculateEuclidianDistance(
            [coord2.x, coord2.z],
            pointsArr.slice(0, 3)
          ) <= thickness
        ) {
          curMeshIndices.push(i);
        }
      }
    } else {
      orient = getOrientation(
        { x: meshLS[0], y: meshLS[1] },
        { x: meshLS[3], y: meshLS[4] },
        { x: pointsArr[0], y: pointsArr[1] }
      );
      for (let i = 0; i < curr_verdata.length; i += 3) {
        let coord = new BABYLON.Vector3(
          curr_verdata[i],
          curr_verdata[i + 1],
          curr_verdata[i + 2]
        );
        let coord2 = BABYLON.Vector3.TransformCoordinates(coord, worldMat);
        if (
          calculateEuclidianDistance(
            [coord2.x, coord2.z],
            pointsArr.slice(3)
          ) <= thickness
        ) {
          curMeshIndices.push(i);
        }
      }
    }

    if (orient === 1) {
      if (checkForId(counterClkMeshes, currMesh.uniqueId))
        counterClkMeshes.push({
          mesh: currMesh,
          nearPointIndexes: curMeshIndices,
          tTypeJoint: Tjoint,
          lTypeJoint: Ljoint,
          near_vector: curr_near_faces,
        });
      // currMesh.material = store.scene.getMaterialByName("wall_mat4");
      // setTimeout(function(currMesh){
      //     currMesh.material = store.scene.getMaterialByName("wall_mat");
      // }, 3000, currMesh);
    } else {
      if (checkForId(clkMeshes, currMesh.uniqueId))
        clkMeshes.push({
          mesh: currMesh,
          nearPointIndexes: curMeshIndices,
          tTypeJoint: Tjoint,
          lTypeJoint: Ljoint,
          near_vector: curr_near_faces,
        });
      // currMesh.material = store.scene.getMaterialByName("floor_tile");
      //  setTimeout(function(currMesh){
      //     currMesh.material = store.scene.getMaterialByName("wall_mat");
      // }, 3000, currMesh);
    }
  }

  neighbourMeshes.forEach(function (item) {
    if (!obj.hasOwnProperty(item.nearPoint)) obj[item.nearPoint] = {};
    if (!obj[item.nearPoint].hasOwnProperty(item.type))
      obj[item.nearPoint][item.type] = [];
    obj[item.nearPoint][item.type].push(item.id);
  });

  for (let key in obj) {
    let juncObj = obj[key];
    if (
      juncObj.hasOwnProperty("collinear") &&
      juncObj.hasOwnProperty("nonCollinear") &&
      juncObj["collinear"].length === 1 &&
      juncObj["nonCollinear"].length >= 1
    ) {
      let Tjoint = true;
      let currMesh = store.scene.getMeshByUniqueID(juncObj.nonCollinear[0]);
      let face_data = currMesh.getBoundingInfo().boundingBox.vectorsWorld;
      currMesh.prevVectorData = face_data;
      currMesh.prevDiagonalLen = currMesh.getBoundingInfo().diagonalLength;

      // if(juncObj["nonCollinear"].length > 1){
      //     let currMesh2= store.scene.getMeshByUniqueID(juncObj.nonCollinear[1]);
      //     Array.prototype.push.apply(currMesh.prevVectorData, currMesh2.getBoundingInfo().boundingBox.vectorsWorld);
      //     deleteWallMesh(currMesh2);
      // }
    }
  }

  mesh.wallJunctions = obj;
  mesh.moveMeshObj = {
    clkWiseMesh: clkMeshes,
    counterClkWiseMesh: counterClkMeshes,
  };
  return { clkWiseMesh: clkMeshes, counterClkWiseMesh: counterClkMeshes };
  // console.log(clkMeshes);
  // console.log(counterClkMeshes);
}
export { moveWallMesh, moveMeshInGroup };
