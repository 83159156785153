import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    iframe {
        border-radius:0 !important;
    }
`

function TypeForm({dataTfWdiget}) {

    return (
        <Wrapper id="wrapper" data-tf-widget={dataTfWdiget} data-tf-inline-on-mobile ></Wrapper>
    )
}

export default TypeForm
