import * as log from "loglevel";
import BABYLON from "../babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import {store} from "../utilityFunctions/Store.js";
import {ResolveEngine, ResolveEngineUtils,} from "../wallEngine/resolveEngine.js";
import {StructureCollection} from "./structure.ds.js";
import {RelationshipEngine} from "../wallEngine/wallRelationship.js";
import {storeyObj} from "./levels.ds.js";
import {deepCopyObject, getDistanceBetweenVectors, onSolid} from "../extrafunc.js";
import {click} from "../../libs/meshEvents.js";
import {assignProperties} from "../../libs/sceneStateFuncs.js";
import {meshObjectMapping} from "./mapping.js";
import {StoreyMutation} from "../storeyEngine/storeyMutations.js";
import {Factory} from "../factoryTypes/meshFactory.module.js";
import {createBuildingEngine} from "../createBuilding/createBuildingEngine.js";
import {
  copyBrep,
  getBottomFaceVertices,
  getFaceVerticesFromFace,
  getTopFaceVertices,
} from "../../libs/brepOperations.js";
import {getNormalVector} from "../../libs/mathFuncs.js";
import {labelView} from "../../libs/labelView.js";
import {distanceBetweenEdgesArray, slopeOfLine,} from "../../libs/twoD/twoServices.js";
import {DisplayOperation} from "../displayOperations/displayOperation.js";
import {convexHullNew} from "../../libs/hull.js";
import {SnapProperty} from "../../libs/GUI/snap_properties.js";
import {SnapElement} from "../../libs/GUI/snap_element.js";
import {commandUtils} from "../commandManager/CommandUtils.js";
import {CommandManager} from "../commandManager/CommandManager.js";
import {BasicWallComp} from "../../libs/basicCompProperties.js";
import {wallProperties} from "../../libs/objectProperties.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import {userSetBIMPropertiesHandler} from "../utilityFunctions/CONSTANTS/userSetBimPropertiesHandler.js";
import {materialLoader} from "../../libs/mats.js";
import {
  addMaterialToLayers,
  getApplyMaterialSaveData,
  getMaterialCommandLogic,
  prepareDataForCMD
} from "../../libs/applyMaterialFuncs.js";
import {Command} from "../commandManager/Command.js";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService.js";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray} from "../../libs/arrayFuncs";

/**
 * { Wall datastructure }
 *
 * @class      Wall (name)
 * @param      {Object}  mesh     The mesh
 * @param      {Object}  room_id  The room identifier
 */
var Wall = function (mesh, room_id, structure_id = null, group_id) {
  this.mesh = mesh;
  this.properties = {};
  this.neighbours = [];
  this.neighboursDetails = {};
  this.slope = 1; //TODO;
  this.profile = 1; //TODO;
  this.id = "w_" + Math.random().toString(36).substr(2, 9);
  this.room_id = room_id;
  this.groupId = group_id;
  this.type = "Wall";
  this.mesh.type = "Wall";
  this.storey = mesh.storey;
  this.level = 0;
  this.structure = 0;
  this.topCoords = [];
  this.bottomCoords = [];
  this.thickness = [];
  this.wThickness = 0.7874;
  this.topLineSegment = [];
  this.bottomLineSegment = [];
  this.localLineSegment = [];
  this.lineSegment = [];
  this.structure_id = structure_id || mesh.structure_id;
  this.midY = null;
  this.dirty = false;
  this.direction = null;
  this.utils = new ResolveEngineUtils();
  this.originalWallMesh = null;
  this.meta = [];
  this.moveDetails = {};
  this.isLocked = false;
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  this.edited = false;
  this.mesh.onExtruded = new BABYLON.Observable();
  this.mesh.onExtruded.add(function (data) {
    if (data.extrudeCheck) {
      data.mesh.getSnaptrudeDS().originalWallMesh =
        BABYLON.SceneSerializer.SerializeMesh(data.mesh);
    }
  });

  this.properties.wallMaterialType = "BRICK";
  this.properties.wallType = "External";
  this.brep = mesh.brep;
  this.faceFacetMapping = mesh.faceFacetMapping;
  this.typeChangeAllowed = false;
  this.revitMetaData = {}
 
  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }
 
  if(!this.mesh.room_type){
    this.mesh.room_type = snaptrudeDSCount.getLabel("Wall");
  }
  delete mesh.brep;
  delete mesh.faceFacetMapping;

  if (!mesh.structure_id) {
    this.mesh.structure_id = structure_id;
  }

  this.onMove = {
    onStart: function (id = null) {
      if (id) {
        this.neighbours.forEach((neighbour) => {
          if (neighbour.wall_id === id) {
            this.meta.push(neighbour.wall_id);
          }
        });
      } else {
        this.neighbours.forEach((neighbour) => {
          if (neighbour.type === "non_collinear") {
            this.meta.push(neighbour.wall_id);
          }
        });
      }
      let ids = JSON.parse(JSON.stringify(this.meta));
      this.meta = [];
      this.moveDetails.start = true;
      this.moveDetails.limit = [];

      for (let end in this.neighboursDetails) {
        let endPoint = this.neighboursDetails[end];
        let checkFlag = false,
          nid = null;
        let verticalNeighbour = 0;

        for (let x in endPoint) {
          //console.log(endPoint[x]);
          let neighbour = _.find(this.neighbours, (o) => {
            return o.wall_id === x;
          });
          if (neighbour.type === "collinear") {
            checkFlag = true;
            nid = x;
          }
          if (neighbour.type === "non_collinear") {
            verticalNeighbour++;
          }
          if (verticalNeighbour >= 2) {
            checkFlag = false;
          }
        }

        if (checkFlag) {
          //create a new wall code
          // continue;
        }

        for (let id of ids) {
          if (endPoint.hasOwnProperty(id)) {
            let structures = StructureCollection.getInstance().getStructures();
            for (let structure in structures) {
              let wall = structures[structure].getWallByAssignedId(id);
              if (!wall) continue;
              endPoint[id].indices = wall.getIndicesFromNearestPoint(
                JSON.parse(endPoint[id].end)
              );
              wall.moveVertexIndices = endPoint[id].indices;
              wall.moveCoodsIndices = endPoint[id].endIndex;
              let self = this;
              RelationshipEngine.assignOrientation(this, wall).then(function (
                success
              ) {
                if (!endPoint[id]) endPoint[id] = {};
                endPoint[id].orientation = success;
                let index = _.difference([0, 1, 2, 3], wall.moveCoodsIndices);
                let pointmx = wall.getLineSegment()[Math.floor(index[0] / 2)];
                if (!self.moveDetails.limit) self.moveDetails.limit = [];
                self.moveDetails.limit.push({
                  id: wall.id,
                  x: pointmx[0],
                  z: pointmx[1],
                  y: pointmx[2],
                  orient: success,
                  thickness: wall.thickness,
                  junction: checkFlag,
                });
                self.meta.push({
                  wall: wall,
                  end: end,
                  id: id,
                  orient: success,
                  junction: checkFlag,
                  isDelete: false,
                  topCoords: JSON.parse(JSON.stringify(wall.topCoords)),
                  bottomCoords: JSON.parse(JSON.stringify(wall.bottomCoords)),
                });
              });

              // console.log("################");
              // console.log(wall.moveCoodsIndices, wall.topCoords, endPoint[id].end,endPoint);
            }
          }
        }
      }
    },
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (direction) {
      //pass
      /*if(direction && this.moveDetails.limit.length > 0){
                this.moveDetails.minLimit = {};
                this.moveDetails.maxLimit = {};
                this.moveDetails.deleteWall = [];
                let min = 1000, max = -9999;
                this.moveDetails.limit.forEach((obj) => {
                    if(obj.orient === 2){ //left side
                        if(direction === "x" && max < obj[direction]){
                            this.moveDetails.minLimit[direction] = obj[direction] - obj.thickness/2;
                            this.moveDetails.minLimit.id = obj.id;
                            max = obj[direction] - obj.thickness/2;
                        }
                        if(direction === "z" && min > obj[direction]){
                            this.moveDetails.minLimit[direction] = obj[direction] + obj.thickness/2;
                            this.moveDetails.minLimit.id = obj.id;
                            min = obj[direction] + obj.thickness/2;
                        }
                    }

                    if(obj.orient === 1){ //right side
                        if(direction === "x" && min > obj[direction]){
                            this.moveDetails.maxLimit[direction] = obj[direction] - obj.thickness/2;
                            this.moveDetails.maxLimit.id = obj.id;
                            min = obj[direction] - obj.thickness /2;
                        }
                        if(direction === "z" && max < obj[direction]){
                            this.moveDetails.maxLimit[direction] = obj[direction] + obj.thickness/2;
                            this.moveDetails.maxLimit.id = obj.id;
                            max = obj[direction] + obj.thickness /2;
                        }
                    }
                });

                if(!this.moveDetails.maxLimit[direction]) this.moveDetails.maxLimit[direction] = (direction === 'z')? -100:100;
                if(!this.moveDetails.minLimit[direction]) this.moveDetails.minLimit[direction] = (direction === 'z')? -1:0;
            }*/
    },

    onPointerMove: function (direction, diff, speed, evt) {
      /*if(!direction || !this.moveDetails) return;
            if(!this.moveDetails.start || !this.moveDetails.minLimit || !this.moveDetails.maxLimit) return;

            if(direction === "z"){
                this.moveDetails.isLimit = this.mesh.position[direction] >= this.moveDetails.minLimit[direction];
                this.moveDetails.increSpeed = (this.direction !== "z") && (diff[direction]*speed) > 0;

            }
            else if(direction === "x"){
                this.moveDetails.isLimit = this.mesh.position[direction] <= this.moveDetails.minLimit[direction];
                this.moveDetails.increSpeed = (this.direction !== "x") && (diff[direction]*speed) < 0;
            }
            if(this.moveDetails.isLimit && this.moveDetails.increSpeed && false){ //TUrning Off
                // this.mesh.position[direction] = this.moveDetails.minLimit[direction];
                evt.preventDefault();
                evt.stopPropagation();
                let downEvent  = document.createEvent("MouseEvents");
                downEvent.initMouseEvent("pointerdown", true, true, window);
                let rwall_id = this.moveDetails.minLimit.id;
                if(rwall_id){
                    let metaIndex = _.findIndex(this.meta, function(o) { return o.id === rwall_id; });
                    this.meta[metaIndex].wall.remove();
                    this.meta.splice(metaIndex, 1);
                }
                canvas.dispatchEvent(downEvent);
                return false;
            }
          
            if(direction === "z"){
                this.moveDetails.isLimit = this.mesh.position[direction] <= this.moveDetails.maxLimit[direction];
                this.moveDetails.increSpeed = (this.direction !== "z") && (diff[direction]*speed) < 0;
            }
            else if(direction === "x"){
                this.moveDetails.isLimit = this.mesh.position[direction] >= this.moveDetails.maxLimit[direction];
                this.moveDetails.increSpeed = (this.direction !== "x") && (diff[direction]*speed) > 0;
            }
            if(this.moveDetails.isLimit && this.moveDetails.increSpeed && false){ //Turning off
                // this.mesh.position[direction] = this.moveDetails.maxLimit[direction];
                evt.preventDefault();
                evt.stopPropagation();
                let downEvent  = document.createEvent("MouseEvents");
                downEvent.initMouseEvent("pointerdown", true, true, window);
                let rwall_id = this.moveDetails.maxLimit.id;
                if(rwall_id){
                    let metaIndex = _.findIndex(this.meta, function(o) { return o.id === rwall_id; });
                    this.meta[metaIndex].wall.remove();
                    this.meta.splice(metaIndex, 1);
                }
                canvas.dispatchEvent(downEvent);
                return false;
            }
            
            this.moveDetails.direction = direction;
            this.mesh.position[direction] += (diff[direction] * speed);
            if (this.mesh.name.indexOf("boxScale") != -1){
                this.mesh.parentMesh.position = this.mesh.position;
            }
            this.notify("extendMesh", diff[direction] * speed, direction);
            let self = this;
            this.meta.forEach((obj) => {
                if(obj.orient === 2 && obj.junction && direction === "z")  obj.isDelete = true;
                if(obj.orient === 1 && obj.junction && direction === "x")  obj.isDelete = true;

                obj.wall.notify("extendNeighbourMeshes", diff[direction] * speed, direction);
            });*/
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.name.indexOf("boxScale") != -1) {
        this.mesh.parentMesh.position = this.mesh.position;
      }
    },
    onFinish: function (nestedLevel = 2) {
      if (!this.moveDetails.start) return false;
      let walls = [this],
        dir = null;
      for (let at in this.moveDetails) {
        let direction = this.moveDetails[at];
        dir = direction;
        delete this.moveDetails[at];
      }
      this.moveDetails = {};

      let sc = StructureCollection.getInstance();
      sc = sc.getStructureById(this.structure_id);
      this.meta.forEach((obj) => {
        delete obj.wall.moveVertexIndices;
        delete obj.wall.moveCoodsIndices;
        walls.push(obj.wall);

        if (obj.isDelete) {
          let oldWall = obj.wall.createWall(obj.bottomCoords, obj.topCoords);
          let sc = StructureCollection.getInstance();
          sc = sc.getStructureById(obj.wall.structure_id);
          let level = sc.getLevelByUniqueId(obj.wall.level_id);

          let storeyOj = new storeyObj(obj.wall.storey, [level]);

          let intersectedWalls = new Map();
          intersectedWalls.set(storeyOj, [[oldWall, obj.wall]]);

          let resolveEngine = new ResolveEngine();
          resolveEngine.startEngine(intersectedWalls).then((message) => {
            //console.log("finished, ", message);
            // resolveEngine.constructSmallDeletedWalls().then((message) => {
            //     console.log(message);
            //     RelationshipEngine.init();
            // });
          });
        }

        //create a new wall
        if (obj.junction && !obj.isDelete) {
          // Array.prototype.push.apply(obj.bottomCoords, obj.wall.bottomCoords);
          let tempTop = _.intersectionWith(
            obj.topCoords,
            obj.wall.topCoords,
            function (a, b) {
              return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
            }
          );

          let tempBottom = _.intersectionWith(
            obj.bottomCoords,
            obj.wall.bottomCoords,
            function (a, b) {
              return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
            }
          );

          //union
          Array.prototype.push.apply(obj.topCoords, obj.wall.topCoords);
          Array.prototype.push.apply(obj.bottomCoords, obj.wall.bottomCoords);

          let finalTempTop = _.differenceWith(
            obj.topCoords,
            tempTop,
            function (a, b) {
              return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
            }
          );

          let finalTempBottom = _.differenceWith(
            obj.bottomCoords,
            tempBottom,
            function (a, b) {
              return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
            }
          );

          let xwall = obj.wall.createWall(finalTempBottom, finalTempTop);
          walls.push(xwall);
        }

        obj.wall.neighbours = obj.wall.neighbours.filter((wall) => {
          let neighbours_neighbour = sc.getWallByAssignedId(wall.wall_id);
          if (!neighbours_neighbour) return false;

          return true;
        });

        //delete neighbours neighbour
        // if(nestedLevel !== 1){
        //     obj.wall.neighbours.forEach((wall) => {
        //         let neighbours_neighbour = sc.getWallByAssignedId(wall.wall_id);
        //         neighbours_neighbour.removeNeighbour(obj.wall.id);
        //         walls.push(neighbours_neighbour);
        //     });
        //      //delete neighbour
        //    // obj.wall.assignProperties(false); Throws error
        //     obj.wall.neighbours = [];
        //     obj.wall.neighboursDetails = {};
        // }
        // else{
        //     obj.wall.removeNeighbour(this.id);
        //     this.removeNeighbour(obj.wall.id);
        // }
        obj.wall.neighbours = [];
        obj.wall.neighboursDetails = {};
      });
      //makeing true for temporary
      /* eslint-disable */
      if (nestedLevel === 2 || true) {
        /* eslint-enable */
        this.neighbours = [];
        this.neighboursDetails = {};
      }

      this.meta.length = 0;
      this.moveDetails.start = false;

      // this.updateVertices(); doesn't work

      this.setBottomCoords(this.bottomCoords).then((succes) => {
        this.setTopCoords(this.topCoords).then((success) => {
          // RelationshipEngine.reEstablishRelation(walls);
          RelationshipEngine.init();
          onSolid(this.mesh);
        });
      });
    },
  };

  this.mesh.onDispose = function () {
    //console.log("deleting wall with id ", this.id);
    // let self = this;
    // setTimeout(() => {
    //     self.remove();
    // }, 10000);
  }.bind(this);

  this.assignProperties = function (isFlatShaded = true, comp) {
    this.mesh.type = "wall";
    if (!this.mesh.isAnInstance && !this.mesh.material)
      this.mesh.material = store.scene.getMaterialByName("wall_mat");
    this.mesh.checkCollisions = true;
    this.mesh.level = 1;
    this.mesh.room_unique_id = mesh.room_unique_id;
    if (!this.properties) this.properties = {};
    this.properties = jQuery.extend(this.properties, wallProperties);
    this.properties._type = "Wall";
    // this.properties._rooms.push(this.mesh.room_name);
    // console.log(this.mesh.room_name);
    this.properties._id = this.mesh.room_id;
    this.properties._level = this.mesh.level;
    this.properties._indices = this.mesh.room_path;
    this.properties._curve = this.mesh.room_curve;
    this.properties._layers = [];
    this.properties._components = comp ? comp : BasicWallComp;
    click(this.mesh);
    this.mesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    this.mesh.checkCollisions = true;
    this.mesh.name = "wall";
    this.mesh.childrenComp = [];
    // if(isFlatShaded) this.mesh.convertToFlatShadedMesh();
    // Set Pivot Point
    // this.mesh.setAbsolutePosition(new BABYLON.Vector3.Zero());

    // let bbinfo = this.mesh.getBoundingInfo();
    // let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    // this.mesh.setPivotPoint(centroid);
    //this.mesh.bakeCurrentTransformIntoVertices();
    assignProperties(this.mesh, -1, "wall", comp); //TODO remove this in next itr
  };
};
/**
 * Remove a wall from level.
 *
 * @param      {<type>}  object  The object
 */
Wall.prototype.removeWallFromLevel = function (level) {
  meshObjectMapping.deleteMapping(this.mesh);
  for (let i = 0; i < level.flyweight.walls.length; i++) {
    if (this.mesh.uniqueId === level.flyweight.walls[i].mesh.uniqueId) {
      level.flyweight.walls.splice(i, 1);
      return;
    }
  }
};
/**
 * Make object visible in store.scene
 */
Wall.prototype.show = function () {
  this.mesh.isVisible = true;

  if (this.mesh.getChildren().length > 0) {
    this.mesh.getChildren().forEach((element) => {
      element.isVisible = true;
      if (element.getChildren().length > 0 && store.$scope.isTwoDimension) {
        element.getChildMeshes().forEach(function (grandchild) {
          grandchild.isVisibilty = true;
        });
      }
    });
  }
};
/**
 * Hides object in store.scene
 */
Wall.prototype.hide = function () {
  this.mesh.isVisible = false;
  if (this.mesh.getChildren().length > 0) {
    this.mesh.getChildren().forEach((element) => {
      element.isVisible = false;
      if (element.getChildren().length > 0 && store.$scope.isTwoDimension) {
        element.getChildMeshes().forEach(function (grandchild) {
          grandchild.isVisibilty = false;
        });
      }
    });
  }
};
Wall.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
Wall.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
Wall.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};

Wall.prototype.isTypeChangeAllowed = function () {
  return this.typeChangeAllowed;
};

Wall.prototype.allowTypeChange = function () {
  this.typeChangeAllowed = true;
};

Wall.prototype.disallowTypeChange = function () {
  this.typeChangeAllowed = false;
};

Wall.prototype.updateVertices = async function () {
  this.mesh.computeWorldMatrix(true);
  // this.mesh.absolutePosition = new BABYLON.Vector3.Zero();

  let wmtrix = this.mesh.getWorldMatrix();
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let newVerdata = [];
  for (let vr = 0; vr < verData.length; vr += 3) {
    let point1 = new BABYLON.Vector3(
      verData[vr],
      verData[vr + 1],
      verData[vr + 2]
    );
    let nepoint1 = BABYLON.Vector3.TransformCoordinates(point1, wmtrix);
    newVerdata.push(nepoint1.x);
    newVerdata.push(nepoint1.y);
    newVerdata.push(nepoint1.z);
  }
  this.mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, newVerdata);
  this.mesh.refreshBoundingInfo();
  // let bbinfo = this.mesh.getBoundingInfo();
  // let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  // this.mesh.setPivotPoint(centroid);
};
Wall.prototype.updateHeight = function (oldHeight, newHeight) {
  if (!this.isEdited()) {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;

    // Accommodate slab height for scaling of wall
    if (
      boundingBox.extendSizeWorld.y * 2 >
      StoreyMutation._CONSTANTS.balconyHeight
    ) {
      let newWallHeight = newHeight - store.projectProperties.properties.slabThicknessProperty.getValue();
      let oldWallHeight = oldHeight - store.projectProperties.properties.slabThicknessProperty.getValue();
      this.mesh.scaling.y *= newWallHeight / oldWallHeight;

      let diff = newHeight - oldHeight;
      this.mesh.position.y += this.storey > 0 ? diff / 2 : -diff / 2;
    }
  }
};
Wall.prototype.updateBase = function (heightDifference) {
  function isOfBalconyHeightOrLess() {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;
    return (
      boundingBox.extendSizeWorld.y * 2 <=
      StoreyMutation._CONSTANTS.balconyHeight
    );
  }

  // if (!(!isOfBalconyHeightOrLess.call(this) && this.isEdited())) {
  //     this.mesh.position.y += heightDifference;
  // }
  if (this.storey > 0) {
    this.mesh.position.y += heightDifference;
  } else {
    this.mesh.position.y -= heightDifference;
  }
};
/**
 * Gets the line segment.
 */
Wall.prototype.getLineSegment = function (forceUpdate = false, prec = 2) {
  if (this.profile !== 2) return null;

  return this.getBottomLineSegment(forceUpdate, prec); //TODO update in future to handle both top and bottom;
};

Wall.prototype.getTopLineSegment = function (forceUpdate = false, prec = 2) {
  if (this.topCoords.length < 4) throw "error";
  if (forceUpdate) this.topLineSegment.length = 0;
  if (this.topLineSegment.length !== 0) return this.topLineSegment;

  const p1 = [
    (this.topCoords[0][0] + this.topCoords[1][0]) / 2,
    (this.topCoords[0][1] + this.topCoords[1][1]) / 2,
    (this.topCoords[0][2] + this.topCoords[1][2]) / 2,
  ];
  const p2 = [
    (this.topCoords[2][0] + this.topCoords[3][0]) / 2,
    (this.topCoords[2][1] + this.topCoords[3][1]) / 2,
    (this.topCoords[2][2] + this.topCoords[3][2]) / 2,
  ];

  this.topLineSegment.push(p1.map((x) => _.round(x, prec)));
  this.topLineSegment.push(p2.map((x) => _.round(x, prec)));
  // console.log(this.topLineSegment);

  return this.topLineSegment;
};

Wall.prototype.getIndicesFromNearestPoint = function (point) {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let wmtrix = this.mesh.getWorldMatrix();
  const indices = [];
  let threshold = this.thickness + 1;
  for (let pointIndex = 0; pointIndex < verData.length; pointIndex += 3) {
    let point1 = new BABYLON.Vector3(
      verData[pointIndex],
      verData[pointIndex + 1],
      verData[pointIndex + 2]
    );
    let nepoint1 = BABYLON.Vector3.TransformCoordinates(point1, wmtrix);
    if (
      (Math.abs(nepoint1.x - point[0]) < 0.5 &&
        Math.abs(nepoint1.z - point[1]) < threshold) ||
      (Math.abs(nepoint1.x - point[0]) < threshold &&
        Math.abs(nepoint1.z - point[1]) < 0.5)
    ) {
      indices.push(pointIndex);
    }
  }
  return indices;
};

Wall.prototype.removeNeighbour = function (id) {
  let index = _.findIndex(this.neighbours, (o) => o.wall_id === id);
  if (index !== -1) {
    this.neighbours.splice(index, 1);
  }
  for (let end in this.neighboursDetails) {
    let neighbours = this.neighboursDetails[end];
    if (neighbours.hasOwnProperty(id)) {
      delete neighbours[id];
    }
  }
};

Wall.prototype.createWall = function (bottomCoords, topCoords) {
  let sc = StructureCollection.getInstance();
  sc = sc.getStructureById(this.structure_id);
  let factory = new Factory();
  let level = sc.getLevelByUniqueId(this.level_id);

  var wall_path_bottom = bottomCoords;
  var wall_path_top = topCoords;
  const heightObj = { low: bottomCoords[0][2], high: topCoords[0][2] };

  var points = {
    pol1: wall_path_bottom,
    pol2: wall_path_top,
    height: heightObj,
  };
  var wallMesh = factory.createWall("straight", points);
  wallMesh.structure_id = this.structure_id;

  var wall = new Wall(wallMesh, this.room_id, this.structure_id);
  let midY = (heightObj.high + heightObj.low) / 2;

  wall.adjustHeight(bottomCoords, topCoords, midY);
  // adjustMeshCoords(wallMesh, coords1, coords2, null, null);
  wall.setBottomCoords(bottomCoords);
  wall.setTopCoords(topCoords);
  wall.setMidYHeight(midY);
  level.addWallToLevel(wall, false);
  wall.assignProperties(false);

  wall.originalWallMesh = BABYLON.SceneSerializer.SerializeMesh(wallMesh);
  return wall;
};

Wall.prototype.getBaseLine = function (){
  const brep = this.brep;
  if (!brep) return;
  
  const bottomFaceVertices = getBottomFaceVertices(this);
  if (bottomFaceVertices.length !== 4) return;
  
  const firstVertex= bottomFaceVertices[0];
  const nextVertex = getCircularlyNextElementInArray(bottomFaceVertices, firstVertex);
  const previousVertex = getCircularlyPreviousElementInArray(bottomFaceVertices, firstVertex);
  
  const nextLength = getDistanceBetweenVectors(firstVertex, nextVertex);
  const previousLength = getDistanceBetweenVectors(firstVertex, previousVertex);
  
  let end1, end2;
  if (nextLength > previousLength){
    end1 = BABYLON.Vector3.Center(firstVertex, previousVertex);
    
    const nextNextVertex = getCircularlyNextElementInArray(bottomFaceVertices, nextVertex);
    end2 = BABYLON.Vector3.Center(nextVertex, nextNextVertex);
  }
  else {
    end1 = BABYLON.Vector3.Center(firstVertex, nextVertex);
    
    const previousPreviousVertex = getCircularlyPreviousElementInArray(bottomFaceVertices, previousVertex);
    end2 = BABYLON.Vector3.Center(previousVertex, previousPreviousVertex);
  }
  
  return [end1, end2];
};

Wall.prototype.getBottomLineSegment = function (forceUpdate = false, prec = 2) {
  if (this.bottomCoords.length < 4) throw "error";
  if (forceUpdate) this.bottomLineSegment.length = 0;
  if (this.bottomLineSegment.length !== 0) return this.bottomLineSegment;

  const p1 = [
    (this.bottomCoords[0][0] + this.bottomCoords[1][0]) / 2,
    (this.bottomCoords[0][1] + this.bottomCoords[1][1]) / 2,
    (this.bottomCoords[0][2] + this.bottomCoords[3][2]) / 2,
  ];
  const p2 = [
    (this.bottomCoords[2][0] + this.bottomCoords[3][0]) / 2,
    (this.bottomCoords[2][1] + this.bottomCoords[3][1]) / 2,
    (this.bottomCoords[2][2] + this.bottomCoords[3][2]) / 2,
  ];

  this.bottomLineSegment.push(p1.map((x) => _.round(x, prec)));
  this.bottomLineSegment.push(p2.map((x) => _.round(x, prec)));
  //console.log(this.bottomLineSegment);

  return this.bottomLineSegment;
};
/**
 * Gets the lower base.
 *
 * @param      {Object}  argument  The argument
 */
Wall.prototype.getLowerBase = function (argument) {
  // body...
};
/**
 * Gets the upper base.
 *
 * @param      {Object}  argument  The argument
 */
Wall.prototype.getUpperBase = function (argument) {
  // body...
};
/**
 * Gets the face.
 *
 * @param      {Object}  argument  The argument
 */
Wall.prototype.getFace = function (argument) {
  // body...
};
/**
 * { check overlap }
 *
 * @param      {Object}  wall    The wall
 */
Wall.prototype.intersect = function (wall) {
  // body...
};

Wall.prototype.remove = function () {
  createBuildingEngine.removeComponent(this);

  /*//pass;
    let sc = StructureCollection.getInstance();
    if(!this.structure_id) return;
    let structure = sc.getStructureById(this.structure_id);
    let level = structure.getLevelByUniqueId(this.level_id);

    let neighbours = this.neighbours;
    neighbours.forEach((neighbour) => {
        let wall = structure.getWallByAssignedId(neighbour.wall_id);
        if(!wall) return;
        wall.removeNeighbour(this.id);
    });

    if(this.mesh.children){
        this.mesh.children.forEach((child) => {
            child.dispose();
        });    
    }
   
    this.mesh.dispose();
    // this.removeWallFromLevel(level); doesn't get removed from storey when this is done
    level.removeObjectToLevel(this);*/
};

Wall.prototype.cloneProperties = function (otherWall, unique = true) {
  otherWall.bottomCoords = this.bottomCoords;
  otherWall.bottomLineSegment = this.bottomLineSegment;
  otherWall.direction = this.direction;
  otherWall.midY = this.midY;
  otherWall.neighbours = this.neighbours;
  otherWall.neighboursDetails = this.neighboursDetails;
  otherWall.originalWallMesh = this.originalWallMesh;
  otherWall.profile = this.profile;
  otherWall.room_id = this.room_id;
  otherWall.topCoords = this.topCoords;
  otherWall.topLineSegment = this.topLineSegment;
  otherWall.typeChangeAllowed = this.typeChangeAllowed;
  otherWall.wThickness = this.wThickness;
  otherWall.localLineSegment = this.localLineSegment;
  // otherWall.wallMaterialType = this.wallMaterialType;
  otherWall.properties = _.cloneDeep(this.properties);

  if (unique) {
    otherWall.brep = copyBrep(this.brep);
    otherWall.faceFacetMapping = deepCopyObject(this.faceFacetMapping);
    if (!otherWall.mesh.isAnInstance) {
      otherWall.mesh.makeGeometryUnique();
      if (this.isEdited()) otherWall.markAsEdited();
    }
  } else {
    otherWall.brep = this.brep;
    otherWall.faceFacetMapping = this.faceFacetMapping;
  }

  otherWall.revitMetaData = this.revitMetaData;

  if (otherWall.revitMetaData.elementId) {
    otherWall.revitMetaData.sourceElementId = this.revitMetaData.elementId;
    delete otherWall.revitMetaData.elementId;
  }
};
/**
 * { Adjust the height -'y' of the wall }
 *
 * @param      {number}  pol_bottom  The pol bottom
 * @param      {number}  pol_top     The pol top
 * @param      {number}  midY        The middle y
 */
Wall.prototype.adjustHeight = function (pol_bottom, pol_top, midY = 0) {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbinfo = this.mesh.getBoundingInfo();
  if (!midY) {
    midY = (bbinfo.maximum.y + bbinfo.minimum.y) / 2;
  }
  for (let i = 0; i < verData.length; i += 3) {
    if (verData[i + 1] < midY) {
      for (let j = 0; j < pol_bottom.length; j++) {
        if (
          Math.abs(verData[i] - pol_bottom[j][0]) < 0.1 &&
          Math.abs(verData[i + 2] - pol_bottom[j][1]) < 0.1
        ) {
          verData[i + 1] = pol_bottom[j][2];
          break;
        }
      }
    } else {
      for (let j = 0; j < pol_top.length; j++) {
        if (
          Math.abs(verData[i] - pol_top[j][0]) < 0.1 &&
          Math.abs(verData[i + 2] - pol_top[j][1]) < 0.1
        ) {
          verData[i + 1] = pol_top[j][2];
          break;
        }
      }
    }
  }
  this.mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, verData);
};

Wall.prototype.setBottomCoords = async function (coords) {
  if (_.isEmpty(coords)) return;

  let self = this;
  return new Promise((resolve, reject) => {
    self.bottomCoords = JSON.parse(JSON.stringify(coords));
    let x_min = _.minBy(self.bottomCoords, function (o) {
      return o[0];
    })[0];
    let x_max = _.maxBy(self.bottomCoords, function (o) {
      return o[0];
    })[0];

    let z_min = _.minBy(self.bottomCoords, function (o) {
      return o[1];
    })[1];
    let z_max = _.maxBy(self.bottomCoords, function (o) {
      return o[1];
    })[1];

    if (x_max - x_min > z_max - z_min) {
      self.bottomCoords.sort(function (a, b) {
        return a[0] - b[0];
      });
      self.direction = "x";
    } else {
      self.bottomCoords.sort(function (a, b) {
        return a[1] - b[1];
      });
      self.direction = "z";
    }

    self._updateSkeleton.call(self);
    resolve();
  });
};

Wall.prototype.setTopCoords = function (coords) {
  if (_.isEmpty(coords)) return;

  let self = this;
  return new Promise((resolve, reject) => {
    self.topCoords = JSON.parse(JSON.stringify(coords));
    let x_min = _.minBy(self.topCoords, function (o) {
      return o[0];
    })[0];
    let x_max = _.maxBy(self.topCoords, function (o) {
      return o[0];
    })[0];

    let z_min = _.minBy(self.topCoords, function (o) {
      return o[1];
    })[1];
    let z_max = _.maxBy(self.topCoords, function (o) {
      return o[1];
    })[1];

    if (x_max - x_min > z_max - z_min) {
      self.topCoords.sort(function (a, b) {
        return a[0] - b[0];
      });
      self.thickness = Math.abs(z_max - z_min);
      self.direction = "x";
    } else {
      self.topCoords.sort(function (a, b) {
        return a[1] - b[1];
      });
      self.thickness = Math.abs(x_max - x_min);
      self.direction = "z";
    }
    self._updateSkeleton.call(self);
    resolve();
  });
};

Wall.prototype._updateSkeleton = function () {
  if (this.topCoords.length === 0 || this.bottomCoords.length === 0) return;
  let topLS = this.getTopLineSegment(true);
  let botLS = this.getBottomLineSegment(true);
  let profile = this.utils.getProfile(topLS[0], topLS[1], botLS[0], botLS[1]);

  if (profile === 2) {
    this.profile = 2;
    //console.log("wall is normal");
  } else if (profile === 1) {
    this.profile = 1;
    //console.log("wall is vertex mutated");
  } else if (profile === 3) {
    this.profile = 3;
    //console.log("wall is edge mutated");
  }
};

Wall.prototype.getOrientation = function (wall) {
  if (this.topCoords.length === 0 || this.bottomCoords.length === 0) return;
};

Wall.prototype.setMidYHeight = function (coords) {
  this.midY = coords;
};

Wall.prototype.getBottomCoords = function () {
  this.dirty = true;
  return this.bottomCoords;
};

Wall.prototype.getTopCoords = function () {
  this.dirty = true;
  return this.topCoords;
};

Wall.prototype.getMidYHeight = function () {
  this.dirty = true;
  return this.midY;
};

Wall.prototype.extendEndVertex = function (ls, wall) {
  //only works for normal walls
  let destPoint, endPoint, indices;
  let id = wall.id;
  for (let end in this.neighboursDetails) {
    let endpoint = this.neighboursDetails[end];
    if (endpoint.hasOwnProperty(id)) {
      destPoint = JSON.parse(endpoint[id].end);
      endPoint = JSON.parse(end);
      indices = endpoint.indices;
      break;
    }
  }

  if (this.direction === "x") {
    //check for z position
    let diff = endPoint[1] - destPoint[1];
    diff *= 2;
    wall.notify("extendNeighbourMeshes", diff, "z");
  } else {
    let diff = endPoint[0] - destPoint[0];
    diff *= 2;
    wall.notify("extendNeighbourMeshes", diff, "x");
  }
};

/**
 * Method to compute if the geometry of the wall is changed
 */
Wall.prototype.getGeometryChangeBoolean = function () {
  function _getNormal(faceVertices) {
    return BABYLON.Vector3.FromArray(getNormalVector(faceVertices)).normalize();
  }

  if (this.brep) {
    let faces = this.brep.getFaces();
    let arrayOfNormals = [];
    for (let i = 0; i < faces.length; i++) {
      let face = faces[i];
      let faceVertices = getFaceVerticesFromFace(
        face,
        this.mesh,
        BABYLON.Space.WORLD
      );
      let normal = _getNormal(faceVertices);
      arrayOfNormals.push(normal);
    }
    let status = [];
    let nonParallelFaces = 0;
    let nonParallelIndex = -1;
    for (let i = 0; i < arrayOfNormals.length; i++) {
      for (let j = 0; j < arrayOfNormals.length; j++) {
        if (i == j) continue;
        let angle =
          BABYLON.Vector3.Dot(arrayOfNormals[i], arrayOfNormals[j]) /
          (arrayOfNormals[i].length() * arrayOfNormals[j].length());
        if (Math.abs(angle + 1) < 1e-4) {
          status.push(false);
          break;
        }
      }
      if (status[i] === undefined) {
        status.push(true);
        if (nonParallelFaces === 2) {
          return true;
        } else if (nonParallelFaces === 0 || nonParallelFaces === 1) {
          nonParallelIndex = i;
          nonParallelFaces++;
        }
      }
    }
    return false;
  } else {
    return false;
  }
};

/**
 * Updates the thickness in the layers of the wall
 * Invoke this function before material schedule is calculated or
 * When the wall's layers are accessed
 */
Wall.prototype.setThickness = function () {
  /*
    TODO: write the logic to compute the thickness
     */
  if (this.getGeometryChangeBoolean()) {
    this.properties._components._layers.forEach(function (layer) {
      if (layer.core) {
        layer.thickness = "Variable";
      }
    });
    return;
  }

  let checkLength = (edgePoints, index) => {
    let i = parseInt(index[0]);
    let j = parseInt(index[1]);
    return BABYLON.Vector3.Distance(edgePoints[i], edgePoints[j]);
  };

  if (labelView) {
    let edgePoints;
    try {
      edgePoints = labelView._getEdgePoints(this.mesh);
    } catch (err) {
      this.properties._components._layers.forEach(function (layer) {
        if (layer.core) {
          layer.thickness = "Variable";
        }
      });
      return;
    }
    if(!edgePoints) return;
    let thickness = 1000000;
    let edgeArray = [];
    edgePoints.forEach(function (edgePoint) {
      edgeArray.push([edgePoint.x, edgePoint.z]);
    });

    let slopes = [];
    let minLen = 0;
    let lineIndex;
    let lenArray = [];
    for (let i = 0; i < edgePoints.length; i++) {
      for (let j = i + 1; j < edgePoints.length; j++) {
        let slope = slopeOfLine([edgeArray[i], edgeArray[j]]);
        for (let k = 0; k < slopes.length; k++) {
          if (isNaN(slope)) {
            if (slope === slopes[k]["slope"]) {
              let len = checkLength(edgePoints, slopes[k].index);
              lenArray.push({ len: len, lineIndex: slopes[k]["index"] });
              if (len > minLen) {
                lineIndex = slopes[k]["index"];
                minLen = len;
              }
            }
          } else if (
            Math.abs(Math.abs(slope) - Math.abs(slopes[k]["slope"])) < 1e-3
          ) {
            let len = checkLength(edgePoints, slopes[k].index);
            lenArray.push({ len: len, lineIndex: slopes[k]["index"] });
            if (len > minLen) {
              lineIndex = slopes[k]["index"];
              minLen = len;
            }
          }
        }
        slopes.push({ index: i.toString() + j.toString(), slope: slope });
      }
    }
    let lens = [];
    if (lenArray.length > 2) {
      for (let i = 0; i < lenArray.length; i++) {
        lens.push(lenArray[i]["len"]);
      }
      let indices = new Array(lenArray.length);
      for (let i = 0; i < lenArray.length; ++i) indices[i] = i;
      indices.sort(function (a, b) {
        return lens[a] < lens[b] ? -1 : lens[a] > lens[b] ? 1 : 0;
      });
      lineIndex = lenArray[indices[indices.length - 2]]["lineIndex"];
    }
    let a = parseInt(lineIndex[0]);
    let b = parseInt(lineIndex[1]);
    let c = [];
    for (let i = 0; i < 4; i++) {
      if (a == i) {
        continue;
      }
      if (b == i) {
        continue;
      } else {
        c.push(i);
      }
    }
    edgeArray.map((e) => {
      return [e[0].toFixed(5), e[1].toFixed(5)];
    });
    thickness = distanceBetweenEdgesArray(
      [edgeArray[a], edgeArray[b]],
      [edgeArray[c[0]], edgeArray[c[1]]]
    );

    /* commenting because this loop is being broken in the first iteration
        for (let i = 0; i < edgePoints.length; i++) {
            break;
            let pt1 = new BABYLON.Vector2(edgePoints[i].x, edgePoints[i].z);
            let pt2 = new BABYLON.Vector2(edgePoints[(i + 1) % edgePoints.length].x, edgePoints[(i + 1) % edgePoints.length].z);
            let perpendicularPoints1 = perpendicularLineToEdge(pt1, pt1, pt2);
            let perpendicularPoints2 = perpendicularLineToEdge(pt2, pt1, pt2);

            let slop1 = slopeOfLine([perpendicularPoints1[0].asArray(), perpendicularPoints1[1].asArray()]);
            let slop2 = slopeOfLine([perpendicularPoints2[0].asArray(), perpendicularPoints2[1].asArray()]);
            let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
            let slope2 = -1 / slop2 === "inf" ? 0 : slop2;

            let points1 = lineWithSlopeAurAtDistanceFromPoint(pt1.asArray(), slope1.toString().includes("Inf") ? "inf" : slope1, 1);
            let points2 = lineWithSlopeAurAtDistanceFromPoint(pt2.asArray(), slope2.toString().includes("Inf") ? "inf" : slope2, 1);

            let storeyObject = getActiveStoreyObject(activeLayer.storey);
            let st = null;
            let end = null;
            if (slop1 === 0) {
                st = new BABYLON.Vector3(points1[1][0], storeyObject.height, points1[1][1]);
                end = new BABYLON.Vector3(points2[1][0], storeyObject.height, points2[1][1]);
            } else if (slop1 === "inf") {
                st = new BABYLON.Vector3(points1[0][0], storeyObject.height, points1[0][1]);
                end = new BABYLON.Vector3(points2[0][0], storeyObject.height, points2[0][1]);
            } else {
                st = new BABYLON.Vector3(points1[1][0], storeyObject.height, points1[1][1]);
                end = new BABYLON.Vector3(points2[1][0], storeyObject.height, points2[1][1]);
            }
            let thick = BABYLON.Vector3.Distance(st, end);
            if (thick<thickness){
                thickness = thick;
            }
        }
        */
    if (this.properties) {
      if (this.properties._components) {
        if (thickness != 1000000) {
          this.properties._components._layers.forEach(function (layer) {
            if (layer.core) {
              layer.thickness =
                DisplayOperation.convertDimensionTo(thickness).mMts;
            }
          });
          this.properties._components._thickness =
            DisplayOperation.convertDimensionTo(thickness).mMts;
          return this.properties._components._thickness;
        }
      }
    }
  }
};

/**
 * Get bottom boundary coordinates of Wall
 * @returns {Array}
 */
Wall.prototype.getBoundaryCoords = function () {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let verDataArr = [];
  let bBox = this.mesh.getBoundingInfo().boundingBox;
  let maxY = Math.round(bBox.maximumWorld.y * 10000) / 10000;

  for (let i = 0; i < verData.length; i += 3) {
    let vec = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
    vec = BABYLON.Vector3.TransformCoordinates(vec, this.mesh.getWorldMatrix());
    vec = new BABYLON.Vector3(
      Math.round(vec.x * 10000) / 10000,
      Math.round(vec.y * 10000) / 10000,
      Math.round(vec.z * 10000) / 10000
    );
    if (vec.y === maxY) {
      verDataArr.push(vec);
    }
  }
  verDataArr = verDataArr.filter(
    (vec, index, self) =>
      index === self.findIndex((t) => t.x === vec.x && t.z === vec.z)
  );

  // [verDataArr[2], verDataArr[3]] = [verDataArr[3], verDataArr[2]];
  return verDataArr;
};

Wall.prototype.getLocalLineSegment = function () {
  if (this.localLineSegment.length > 0) {
    return this.localLineSegment;
  } else {
    // let localTopCoords = [];
    // let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    // verData = verData.map(coord => Number(coord.toFixed(3)));
    //
    // let maxY = Number(this.mesh.getBoundingInfo().maximum.y.toFixed(3));
    //
    // for (let i = 0; i < verData.length; i += 3) {
    //     if (verData[i + 1] === maxY) {
    //         localTopCoords.push([
    //             verData[i],
    //             verData[i + 1],
    //             verData[i + 2]
    //         ]);
    //     }
    // }
    //
    // localTopCoords = localTopCoords.filter((vec, index, self) =>
    //     index === self.findIndex((t) => (
    //         t[0] === vec[0] && t[2] === vec[2]
    //     ))
    // );
    let operatingSpace = BABYLON.Space.LOCAL;
    let vectorFormOfCoords = getTopFaceVertices(this, operatingSpace);
    let v1 = vectorFormOfCoords[1].subtract(vectorFormOfCoords[0]);
    let v2 = vectorFormOfCoords[3].subtract(vectorFormOfCoords[0]);

    let l1 = v1.length();
    let l2 = v2.length();

    this.localLineSegment = [];
    if (l1 > l2) {
      this.localLineSegment.push(
        BABYLON.Vector3.Center(
          vectorFormOfCoords[0],
          vectorFormOfCoords[3]
        ).asArray()
      );
      this.localLineSegment.push(
        BABYLON.Vector3.Center(
          vectorFormOfCoords[1],
          vectorFormOfCoords[2]
        ).asArray()
      );
    } else {
      this.localLineSegment.push(
        BABYLON.Vector3.Center(
          vectorFormOfCoords[0],
          vectorFormOfCoords[1]
        ).asArray()
      );
      this.localLineSegment.push(
        BABYLON.Vector3.Center(
          vectorFormOfCoords[3],
          vectorFormOfCoords[2]
        ).asArray()
      );
    }

    return this.localLineSegment;
  }
};

/**
 * Gets the worldMatrix of a wall-mesh edited by freeMove
 * @return { BABYLON.Matrix }
 */
Wall.prototype.getSupervisedWorldMatrix = function () {
  this.mesh.computeWorldMatrix(true);

  let supervisedWorldMatrix = this.mesh.getWorldMatrix().clone();
  let bBox = this.mesh.getBoundingInfo().boundingBox;
  let geometryCenter = bBox.centerWorld;

  return supervisedWorldMatrix.setTranslation(geometryCenter);
};

Wall.prototype.calculateWidth = function () {
  if (this.mesh.getChildren().length > 0) {
    return this.wThickness;
  } else {
    let thickness;
    try{
      thickness = this.getThickness();
      this.wThickness = thickness;
    }catch(e){
      thickness = this.wThickness;
    }
    return thickness;
  }
};

Wall.prototype.getThickness = function () {
  if (!this.brep) {
    return this.wThickness;
  }

  let operatingSpace = BABYLON.Space.WORLD;
  let coords = getTopFaceVertices(this, operatingSpace);

  let v1 = coords[1].subtract(coords[0]);
  let v2 = coords[3].subtract(coords[0]);

  let l1 = v1.length();
  let l2 = v2.length();

  return l1 > l2 ? l2 : l1;
};

/**
 * Function to generate and assign room_ids for the wall based on floor information
 * Accepts the array of floors in the current storey
 * The logic is based on scaling and rotation - has to be updated when freemove thickness
 * change is possible for walls
 */
Wall.prototype.assignRoomIds = function (floors) {
  if (!DisplayOperation || !objectPropertiesView) return;
  this.room_id = "";
  const a = JSON.stringify(this.mesh.scaling.x);
  const b = JSON.stringify(this.mesh.scaling.y);
  const c = JSON.stringify(this.mesh.scaling.z);
  const d = JSON.stringify(this.mesh.rotation.y);
  let scale = this.mesh.scaling;
  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh, {valueInSnapDim: true});
  let depth = meshDimensions.depth;
  let breadth = meshDimensions.breadth;
  let dist = 1;
  if (breadth > depth) {
    this.mesh.scaling.z = 1/(scale.z * dist) / meshDimensions.breadth;
  } else {
    this.mesh.scaling.x = 1/(scale.x * dist) / meshDimensions.depth;
  }
  this.mesh.rotation.y += (Math.PI / 180) * 10;
  this.mesh.computeWorldMatrix(true);
  for (let p = 0; p < floors.length; p++) {
    if (floors[p].mesh) {
      if (floors[p].mesh.parent) {
        if (floors[p].mesh.parent.parent) {
          if (floors[p].mesh.parent.parent.type) {
            if (floors[p].mesh.parent.parent.type.toLowerCase() === "wall") {
              continue;
            }
          }
        }
      }
    }
    if (floors[p].storey === this.storey) {
      const intersect = floors[p].mesh.intersectsMesh(this.mesh);
      if (intersect && floors[p].room_id) {
        this.room_id += floors[p].room_id;
      }
    }
  }
  this.mesh.scaling.x = parseFloat(a);
  this.mesh.scaling.y = parseFloat(b);
  this.mesh.scaling.z = parseFloat(c);
  this.mesh.rotation.y = parseFloat(d);
  this.mesh.computeWorldMatrix(true);
};

Wall.prototype.edgeMidPts = function () {
  let twoPts = [];
  let drawPts = [];
  let midPoints = [];
  let coords = this.getBoundaryCoords();
  for (let i = 0; i < coords.length; i++) {
    let point = new BABYLON.Vector2(coords[i].x, coords[i].z);
    twoPts.push(point);
  }
  let pts = convexHullNew(twoPts);
  for (let j = 0; j < pts.length; j++) {
    drawPts.push(new BABYLON.Vector3(pts[j].x, this.midY, pts[j].y));
  }
  drawPts.push(drawPts[0]); // loop closed
  for (let k = 0; k < drawPts.length - 1; k++) {
    let set = {
      pointSet: [drawPts[k], drawPts[k + 1]],
      dis: BABYLON.Vector3.Distance(drawPts[k], drawPts[k + 1]),
    };
    midPoints.push(set);
  }
  let sorted = midPoints.sort((a, b) => a.dis - b.dis);
  let pt1 = new BABYLON.Vector3.Center(
    sorted[0].pointSet[0],
    sorted[0].pointSet[1]
  );
  let pt2 = new BABYLON.Vector3.Center(
    sorted[1].pointSet[0],
    sorted[1].pointSet[1]
  );
  return [pt1, pt2];
};

/**
 * Notifies all.
 */
Wall.prototype.notifyAll = function (action, data = null) {
  if (action === "resetlevel") {
    return WALL_NOTIFY_FUNCTIONS.resetLevel.call(this);
  }
};

Wall.prototype.notify = function (
  action,
  speed = null,
  direction = null,
  param = null
) {
  if (action === "extendMesh") {
    let topCoords = this.topCoords;
    let bottomCoords = this.bottomCoords;
    topCoords.forEach((point) => {
      switch (direction) {
        case "x":
          point[0] += speed;
          break;
        case "z":
          point[1] += speed;
          break;
        case "y":
          point[2] += speed;
          break;
      }
    });

    bottomCoords.forEach((point) => {
      switch (direction) {
        case "x":
          point[0] += speed;
          break;
        case "z":
          point[1] += speed;
          break;
        case "y":
          point[2] += speed;
          break;
      }
    });

    return;
  }

  if (action === "extendNeighbourMeshes") {
    let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    let topCoords = this.topCoords;
    let bottomCoords = this.bottomCoords;
    let point;
    for (let i = 0; i < this.moveVertexIndices.length; i++) {
      switch (direction) {
        case "x":
          verData[this.moveVertexIndices[i]] += speed;
          if (i === 0) {
            this.moveCoodsIndices.forEach((index) => {
              point = topCoords[index];
              point[0] += speed;
              point = bottomCoords[index];
              point[0] += speed;
            });
          }
          break;

        case "z":
          verData[this.moveVertexIndices[i] + 2] += speed;
          if (i === 0) {
            this.moveCoodsIndices.forEach((index) => {
              point = topCoords[index];
              point[1] += speed;
              point = bottomCoords[index];
              point[1] += speed;
            });
          }
          break;
        case "y":
          verData[this.moveVertexIndices[i] + 1] += speed;
          if (i === 0) {
            this.moveCoodsIndices.forEach((index) => {
              point = topCoords[index];
              point[2] += speed;
              point = bottomCoords[index];
              point[2] += speed;
            });
          }
          break;
        default:
          break;
      }
    }

    this.mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, verData);

    return;
  }
};

Wall.prototype.getMeshObjectProperties = function () {
  let props = [];
  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);
  props.push(
    new SnapProperty(
      "Category",
      "Wall",
      SnapElement.getInputElement(null, false)
    )
  );

  if (this.massType) {
    //wall drawn using draw tool
    props.push(
      new SnapProperty(
        "Type",
        this.massType,
        SnapElement.getInputElement(null, false)
      )
    );
  } else if (this.isTypeChangeAllowed()) {
    //wall drawn using draw tool
    props.push(
      new SnapProperty(
        "Type",
        this.type,
        SnapElement.getDropDown(
          objectPropertiesView.getTypeOptionsForObject(this),
          (meshes, name) => objectPropertiesView.onTypeChange(this.mesh, name),
          true
        )
      )
    );
  }

  props.push(
    new SnapProperty(
      "Label",
      this.mesh.room_type,
      SnapElement.getInputElement(
        (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
        true
      )
    )
  );

  //props.push(new SnapProperty("Thickness",meshDimensions.depth,SnapElement.getInputElement((meshs,value) =>objectPropertiesView.dimensionChange("thickness",value),true)));
  props.push(
    new SnapProperty(
      "Location",
      this.properties.wallType,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Wall Type",
      this.revitMetaData.family ?
      this.revitMetaData.family :
      store.projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES[
        this.properties.wallMaterialType
      ],
      this.revitMetaData.family?
      SnapElement.getInputElement(null, false) :
      SnapElement.getDropDown(
        userSetBIMPropertiesHandler.getWallTypes(),
        (meshes, name) => this.changeWallMaterialType(name),
        true
      )
    )
  );

  if(this.isOrthogonal()){
    if (DisplayOperation.getOriginalDimension(meshDimensions.breadth) > DisplayOperation.getOriginalDimension(meshDimensions.depth)){
      props.push(new SnapProperty("Thickness",meshDimensions.depth,SnapElement.getInputElement((meshs,value) => this.changeWallThickness(value),true)));
      props.push(new SnapProperty("Length",meshDimensions.breadth,SnapElement.getInputElement((meshs,value) => objectPropertiesView.dimensionChange("width",value,meshDimensions.breadth),true)));
    }
    else{
      props.push(new SnapProperty("Thickness",meshDimensions.breadth,SnapElement.getInputElement((meshs,value) => this.changeWallThickness(value),true)));
      props.push(new SnapProperty("Length",meshDimensions.depth,SnapElement.getInputElement((meshs,value) => objectPropertiesView.dimensionChange("width",value,meshDimensions.depth),true)));
    }
  }
  props.push(
    new SnapProperty(
      "Height",
      meshDimensions.height,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange(
            "height",
            value,
            meshDimensions.height
          ),
        true
      )
    )
  );

  let storey = StructureCollection.getInstance().getStructureById(this.mesh.structure_id).getStoreyData().getStoreyByValue(this.mesh.storey).name
  let storeyValue = storey? storey : this.mesh.storey

  props.push(
    new SnapProperty(
      "Storey",
      storeyValue,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Surface Area",
      meshDimensions.area,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Volume",
      meshDimensions.volume,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(new SnapProperty("object-buttons", this.mesh, null, true));

  return props;
};

Wall.prototype.isOrthogonal = function () {
  let midPoints = this.edgeMidPts();
  let orthogonalAngles = [0, 0.5, 1, 1.5, 2];
  for (let i = 0; i < orthogonalAngles.length; i++)    orthogonalAngles[i] = parseFloat((Math.PI * orthogonalAngles[i]).toFixed(2));
  let angle = parseFloat(Wall.calculateAngleByMidLine(midPoints[0], midPoints[1]).toFixed(2));
  let orthogonal = orthogonalAngles.includes(angle);
  return orthogonal;
};

// Wall.CONSTANTS = function(){
//     return {
//         wallLocations: {Ext: "External", Int: "Internal", Parapet: "Parapet"}
//     };
// };

Wall.prototype.getStack = function (getInstances) {
  let stack = [], sourceMeshUniqueId = {}, mainStack;
  mainStack = _.filter(store.selectionStack, mesh => mesh.type.toLowerCase() === "wall");
  if (mainStack.length < 2) mainStack = [this.mesh];
  // else    mainStack = selectionStack;
  mainStack.forEach(mesh => {
    let sourceMesh = mesh.sourceMesh;
    if (sourceMesh) {
      if (!sourceMeshUniqueId[sourceMesh.uniqueId]) {
        stack.push(sourceMesh);
        sourceMeshUniqueId[sourceMesh.uniqueId] = true;
        if (getInstances) {
          sourceMesh.instances.forEach(instance => {
            stack.push(instance);
          });
        }
      }
    }
    else {
      stack.push(mesh);
    }
  });

  return stack;

};

Wall.prototype.changeWallMaterialType = function (wallMaterialTypeFull, options = {}) {
  let _filterStackForWallThicknessChange = function (stack, oldWallMaterialType) {
    let newStack = _.filter(stack, wallMesh => {
      // let defaultThickness = 0;
      // if(oldWallMaterialType == "GLASS")    defaultThickness = DisplayOperation.getOriginalDimension(25, "millimeter");
      // else if(oldWallMaterialType == "WOOD")    defaultThickness = DisplayOperation.getOriginalDimension(125, "millimeter");
      // else if(["BRICK", "CONCRETE"].includes(oldWallMaterialType)){
      //     if(wallMesh.getSnaptrudeDS().properties.wallType === "External")  defaultThickness = DisplayOperation.getOriginalDimension(200, "millimeter");
      //     else if(["Internal", "Parapet"].includes(wallMesh.getSnaptrudeDS().properties.wallType))    defaultThickness = DisplayOperation.getOriginalDimension(100, "millimeter");
      // }    
      // let meshDimensions = objectPropertiesView.getMeshDimensions(wallMesh, {valueInSnapDim: true});
      // let breadth = meshDimensions.breadth;
      // let depth = meshDimensions.depth;
      // let thickness = breadth > depth ? depth : breadth;
      // if(Math.abs(thickness - defaultThickness) < 1e-3)  return true;
      // else    return false;

      if (!wallMesh.getSnaptrudeDS().properties.wallThicknessChanged) return true;
      else {
        let meshDimensions = objectPropertiesView.getMeshDimensions(wallMesh, { valueInSnapDim: true });
        let breadth = meshDimensions.breadth;
        let depth = meshDimensions.depth;
        let thickness = breadth > depth ? depth : breadth;
        if (Math.abs(wallMesh.getSnaptrudeDS().properties.originalWallThickness - thickness) < 1e-3) return true;
        else return false;
      }
    });
    return newStack;
  };

  let reverseTypeMapping = {};
  for (const key in store.projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES) {
    reverseTypeMapping[store.projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES[key]] = key;
  }
  let newWallMaterialType = reverseTypeMapping[wallMaterialTypeFull];
  if (!newWallMaterialType) return;
  if (this.properties.wallMaterialType === newWallMaterialType) return;

  let optionsForPropertyChange = {
    componentKeys: ["properties"]
  };

  let stack = options.stack || this.getStack(true);

  let cmds = [], yets = [];
  let propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(stack, optionsForPropertyChange);
  let oldValue = this.properties.wallMaterialType;

  stack.forEach(mesh => {
    let meshDS = mesh.getSnaptrudeDS();
    meshDS.properties.wallMaterialType = newWallMaterialType;
  });
  // this.wallMaterialType = newWallMaterialType;

  let cmdData = this.updateDefaultMaterial({ stack: stack, returnCommand: true });
  cmds.push(...cmdData.cmds);
  yets.push(...cmdData.yets);


  if (!options.codeInitiated) {
    let newThickness = 0;
    if (this.properties.wallMaterialType === "GLASS")
      newThickness = DisplayOperation.getOriginalDimension(25, "millimeter");
    else if (this.properties.wallMaterialType === "WOOD")
      newThickness = DisplayOperation.getOriginalDimension(125, "millimeter");
    else if (["BRICK", "CONCRETE"].includes(this.properties.wallMaterialType) && ["WOOD", "GLASS"].includes(oldValue)) {
      if (this.properties.wallType === "External")
        newThickness = DisplayOperation.getOriginalDimension(200, "millimeter");
      else if (["Internal", "Parapet"].includes(this.properties.wallType))
        newThickness = DisplayOperation.getOriginalDimension(100, "millimeter");
    }
    if (newThickness) {
      let newStack = _filterStackForWallThicknessChange(stack, oldValue);
      // let newStack = stack;
      let command = this.changeWallThickness(newThickness, { stack: newStack, returnCommand: true, codeInitiated: true, valueInSnapDim: true });
      if (command) {
        cmds.push(...command.cmds);
        yets.push(...command.yets);
      }
    }
  }

  optionsForPropertyChange.data = propertyChangeCommandData;
  propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(stack, optionsForPropertyChange);
  cmds.push(commandUtils.propertyChangeOperations.getCommand("wallMaterialTypeChange", propertyChangeCommandData));
  yets.push(true);
  if (!options.returnCommand) {
    CommandManager.execute(cmds, yets);
  }
  else {
    return { cmds: cmds, yets: yets };
  }
};

Wall.prototype.updateDefaultMaterial = function(options = {}) {
  let stack = options.stack || this.getStack(false);
  // let mesh = this.mesh.sourceMesh || this.mesh;
  let mat = materialLoader.loadWallMaterial(this.properties.wallMaterialType);
  let cmds = [], yets = [];
  stack.forEach(mesh => {
      if(mesh.sourceMesh) return;
      let applyMaterialCommandData = {};
      if(options.returnCommand){
          applyMaterialCommandData._prevData = prepareDataForCMD(mesh);
      } 

      mesh.material = mat;
      mesh.subMeshes.forEach(function (subMesh) {
          subMesh.materialIndex = 0;
      });
      let meshDS;
      try{
          meshDS = mesh.getSnaptrudeDS();
      }
      catch{
          // This happens when the wall is created during CB
          meshDS = this;
      }
      if (meshDS.brep) {
          let faces = meshDS.brep.getFaces();
          faces.forEach(function (face) {
              face.materialIndex = 0;
          });
      }
      addMaterialToLayers(mesh, 0);

      if(options.returnCommand){
          applyMaterialCommandData._newData = prepareDataForCMD(mesh);
          cmds.push(new Command("applyDefaultRoomTypeMaterial", applyMaterialCommandData, getMaterialCommandLogic(), getApplyMaterialSaveData));
          yets.push(false);
      } 
  });
  
  if(options.returnCommand){
      return {cmds: cmds, yets: yets};
  } 
};

Wall.prototype._changeWallThickness = function(value, stack, options = {}){
  let _handleChildren = function(mesh, data){
      if(!data.children){
          data.children = [];
          mesh.getChildren().forEach(child => {
              if (child.type.toLowerCase() === "void") {
                let childMeshDimensions = objectPropertiesView.getMeshDimensions(child);
                let childBreadth = parseFloat(childMeshDimensions.breadth);
                let childDepth = parseFloat(childMeshDimensions.depth);

                let childOldValue = childBreadth < childDepth ? childBreadth : childDepth;
                let childDist = childOldValue * (value / options.oldValue);
                let cmdData = DisplayOperation.updateDimensionScale(childDist, "thickness", childOldValue, child, {
                  returnCommand: true,
                  doNotClearSelection: true,
                  ignoreSelectionStack: true,
                  overrideMeshParentSelectionForCommandData: true
                });
                additionalCommands.push(...cmdData.cmd);
                additionalYets.push(...cmdData.yetToExecutes);
              }
              data.children.push(child);
              child.setParent(null);
          });
      }
      else{
          data.children.forEach(child => child.setParent(mesh));
          delete data.children;
      }

  };
  let cmdOptions = {
      params: [commandUtils.worldMatrixChangeOperations.PARAMS.scale,
          commandUtils.worldMatrixChangeOperations.PARAMS.position],
      ignoreSelectionStack: true
  };

  let propChangeOptions = {
      componentKeys: ["wThickness"]
  };
  // cmdOptions.ignoreSelectionStack = true;
  let excludeChildren = [];
  stack.forEach(mesh => mesh.getChildren().forEach(child => excludeChildren.push(child.uniqueId)));
  if(excludeChildren.length > 0){
      cmdOptions.dataOptions = {};
      cmdOptions.dataOptions.excludeChildren = excludeChildren;
  }
  
  let beforeCmdData = commandUtils.worldMatrixChangeOperations.getCommandData(stack, cmdOptions);
  // let oldValue = options.oldValue;

  let thicknessPropertyChangeCmdData = commandUtils.propertyChangeOperations.getCommandData(stack, propChangeOptions);
  propChangeOptions.data = thicknessPropertyChangeCmdData;

  let additionalCommands = [];
  let additionalYets = [];

  stack.forEach(mesh => {
      let meshDS = mesh.getSnaptrudeDS();
      if(!meshDS.isOrthogonal())   return;
      let meshDimensions = objectPropertiesView.getMeshDimensions(mesh, {valueInSnapDim: true});
      let breadth = meshDimensions.breadth, depth = meshDimensions.depth;
      let data = {};
      _handleChildren(mesh, data);
      if (breadth > depth) {
          mesh.scaling.x = (mesh.scaling.x * value) / depth;
      } 
      else {
          mesh.scaling.z = (mesh.scaling.z * value) / breadth;
      }
      meshDS.wThickness = value;
      _handleChildren(mesh, data);
  });

  // For updating /wThickness/ property of wall DS
  thicknessPropertyChangeCmdData = commandUtils.propertyChangeOperations.getCommandData(stack, propChangeOptions);
  let propertyChangeCommand = commandUtils.propertyChangeOperations.getCommand("wallThicknessChange", thicknessPropertyChangeCmdData);

  cmdOptions.data = beforeCmdData;
  let afterCmdData = commandUtils.worldMatrixChangeOperations.getCommandData(store.currentMesh, cmdOptions);
  let scalingCommand = commandUtils.worldMatrixChangeOperations.getCommand("Wall Thickness Change", afterCmdData, cmdOptions);
  let allCmds = [];
  let allYets = [];
  allCmds.push(scalingCommand, propertyChangeCommand);
  allYets.push(false, false);

  if (!_.isEmpty(additionalCommands)) {
    allCmds.push(...additionalCommands);
    allYets.push(...additionalYets);
  }
  return {cmds: allCmds, yets: allYets};
};

Wall.prototype.changeWallThickness = function (wallThickness, options = {}) {
  // objectPropertiesView.dimensionChange("thickness", wallThickness, options.oldValue);
  let value;
  if (options.valueInSnapDim) value = wallThickness;
  else value = DisplayOperation.getOriginalDimension(wallThickness);
  let oldValue = options.oldValue;
  if (!oldValue) {
    let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh, { valueInSnapDim: true });
    oldValue = meshDimensions.breadth > meshDimensions.depth ? meshDimensions.depth : meshDimensions.breadth;
  }
  // oldValue = DisplayOperation.getOriginalDimension(oldValue);
  if (value === oldValue) return;
  // let cmdOptions = {returnCommand: true, ignoreSelectionStack: true};
  // if(options.codeInitiated)   cmdOptions.codeInitiated = true;
  let stack = options.stack || this.getStack(true);

  let cmds = [], yets = [];
  if (!options.codeInitiated) {
    let newWallType;
    let thresholdInBabylonUnits = parseFloat(DisplayOperation.getOriginalDimension(50, "millimeter").toFixed(4));
    let prunedValue = parseFloat(value.toFixed(4)), prunedOldValue = parseFloat(oldValue.toFixed(4));
    if (prunedValue > thresholdInBabylonUnits && prunedOldValue <= thresholdInBabylonUnits)
      newWallType = store.projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES.BRICK;
    else if (prunedValue <= thresholdInBabylonUnits && prunedOldValue > thresholdInBabylonUnits)
      newWallType = store.projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES.GLASS;
    if (newWallType && false) {
      // not changing wall type when thickness is changed
      let command = this.changeWallMaterialType(newWallType, { stack: stack, returnCommand: true, codeInitiated: true });
      if (command) {
        cmds.push(...command.cmds);
        yets.push(...command.yets);
      }
    }
    else {
      stack.forEach(wallMesh => {
        let wallDS = wallMesh.getSnaptrudeDS();
        wallDS.properties.wallThicknessChanged = true;
        wallDS.properties.originalWallThickness = oldValue;
      });
    }
  }

  let cmdData = this._changeWallThickness(value, stack, { oldValue: oldValue });
  if (cmdData) {
    cmds.push(...cmdData.cmds);
    yets.push(...cmdData.yets);
  }

  if (!options.returnCommand) {
    CommandManager.execute(cmds, yets);
  }
  else {
    return { cmds: cmds, yets: yets };
  }
};

Wall.prototype.getAssociatedFloor = function () {
  let _this = this;
  let roomId = _this.room_id;
  let storey = StructureCollection.getInstance()
    .getStructures()
    [_this.structure_id].getStoreyData()
    .getAllStoreys()[_this.storey];
  let floors = storey.filterElements(["floor"]);
  floors = floors.filter((floor) => !floor.parent && floor.room_id === roomId);
  return floors[0];
};
/**
 * Function to calculate the wall angle w.r.t X-Axis
 * given a wallFace point and it's corresponding normal
 *
 * @param facePoint
 * @param faceNormal
 * @returns {number}
 * @static
 */
Wall.calculateAngleByFaceNormal = function (facePoint, faceNormal) {
  let arbitraryPoint = facePoint.add(faceNormal.scale(10));
  let angle =
    -Math.atan2(
      arbitraryPoint.z - facePoint.z,
      arbitraryPoint.x - facePoint.x
    ) +
    Math.PI / 2;
  return angle > 0 ? angle : 2 * Math.PI + angle;
};

/**
 * Function to calculate the wall angle w.r.t X-axis
 * given end points of a line profiling a wall
 * @param pointA
 * @param pointB
 */
Wall.calculateAngleByMidLine = function (pointA, pointB) {
  let angle = -Math.atan2(pointA.z - pointB.z, pointA.x - pointB.x);
  return angle > 0 ? angle : 2 * Math.PI + angle;
};

/**
 * Check data integrity of components on reload
 * @param data
 */
Wall.isCorrupt = function (data) {
  const pos = data.meshes[0].position;
  if (pos[0] === null) return true;

  const topCoords = data.dsProps.topCoords;
  const bottomCoords = data.dsProps.bottomCoords;

  if (!_.isEmpty(topCoords)) {
    if (topCoords[0][0] === null) return true;
  }

  if (!_.isEmpty(bottomCoords)) {
    if (bottomCoords[0][0] === null) return true;
  }

  return false;
};


/**
 * { function_description }
 *
 * @class      MASS_NOTIFY_FUNCTIONS (name)
 * @return     {Object}  { description_of_the_return_value }
 */
var WALL_NOTIFY_FUNCTIONS = (function () {
  "use strict";

  var _updateCoords = function (data) {
    //pass
  };

  var _resetLevel = function () {
    let inst = StructureCollection.getInstance();
    let structure = inst.getStructureById(this.structure_id);
    let levels = structure.getAllLevels();
    let walls = [],
      stack = [],
      indexSearch = [],
      searchMap = {},
      level = {},
      ctr_low = 0,
      ctr_high = 1;
    for (let level_id in levels) {
      let level = levels[level_id];
      walls.push(level.getWalls());
    }
    //line sweep algo
    for (let mass of _.flatten(walls)) {
      mass.mesh.refreshBoundingInfo();
      let bbinfo = mass.mesh.getBoundingInfo();
      let low = Math.round(bbinfo.boundingBox.minimumWorld.y * 10) / 10;
      let high = Math.round(bbinfo.boundingBox.maximumWorld.y * 10) / 10;

      stack.push({ low: low, high: high, obj: mass });
      indexSearch.push(low);
      indexSearch.push(high);
    }

    stack.sort(function (a, b) {
      if (a.low === b.low) {
        return a.high - b.high;
      } else {
        return a.low - b.low;
      }
    });

    indexSearch.sort(function (a, b) {
      return a - b;
    });

    indexSearch = _.sortedUniq(indexSearch);

    for (let m = 0; m < indexSearch.length - 1; ) {
      if (
        indexSearch[m] < 0 ||
        Math.abs(indexSearch[m] - indexSearch[m + 1]) <= 0.1
      )
        indexSearch.splice(m, 1);
      else {
        m++;
      }
    }

    for (let j of indexSearch) {
      stack.forEach(function (element) {
        if (Math.abs(element.low - j) <= 0.1) {
          if (!searchMap.hasOwnProperty(element.obj.id))
            searchMap[element.obj.id] = { low: j, item: element };
          // throw "Error ! Case not possible";
          else console.warn("Error ! Case not possible");
        }
        if (Math.abs(element.high - j) <= 0.1) {
          if (searchMap.hasOwnProperty(element.obj.id)) {
            let item = searchMap[element.obj.id];
            let key =
              _.sortedIndexOf(indexSearch, item.low).toString() +
              ":" +
              _.sortedIndexOf(indexSearch, j).toString();
            if (!level.hasOwnProperty(key)) level[key] = [];
            level[key].push(item);
            delete searchMap[element.obj.id];
          } else {
            // throw "Error ! Case not possible";
            console.warn("Error ! Case not possible");
          }
        }
      });
    }

    log.warn(level);
    return level;
  };

  var _extendMesh = function (data) {
    //pass
  };

  return {
    resetLevel: _resetLevel,
    extendMesh: _extendMesh,
    updateCoords: _updateCoords,
  };
})();
export { Wall, WALL_NOTIFY_FUNCTIONS };
