import {getDistanceBetweenVectors, mmToSnaptrudeUnits} from "../extrafunc";
import _ from "lodash";
import {computeAreaMass} from "../../libs/areaFuncs";
import {getBottomFaceVertices} from "../../libs/brepOperations";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService";

function shouldMassBeAColumn(mass){

	const mesh = mass.mesh;

	const thresholdArea = 15.5; //this is in babylon units, corresponds to 1 sq. metre
	const thresholdLength = mmToSnaptrudeUnits(1000);
	const areaOfPolygon = _.round(Math.abs(computeAreaMass(mesh, 0)), 1);

	if (areaOfPolygon > thresholdArea) {
		return false;
	}
	else {

		let bottomPoints = getBottomFaceVertices(mass);

		// if area < 1 sq m but any side > 1m, it should be a room

		let edgeLengthViolated = false;
		const length = bottomPoints.length;
		bottomPoints.some((point, i) => {
			const nextIndex = i === length - 1 ? 0 : i + 1;
			const nextPoint = bottomPoints[nextIndex];

			const edgeLength = getDistanceBetweenVectors(point, nextPoint);
			if (edgeLength > thresholdLength) edgeLengthViolated = true;

			return edgeLengthViolated;
		});

		return !edgeLengthViolated;

	}
}

function assignColumnProperties(mass){
	mass.massType = "Column";
	mass.mesh.room_type = snaptrudeDSCount.getLabel("Column");
}

export {
	shouldMassBeAColumn,
	assignColumnProperties,
}