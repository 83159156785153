import {
  getDimensionInSnaptrudeUnits,
  getFurnitureFromFurnitureMap,
  speckleRevitImport,
} from ".";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import BABYLON from "../babylonDS.module";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { Furniture } from "../snaptrudeDS/furniture.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  createCustomMeshFromRevitExport,
  getRevitName,
} from "./extraFunctions";
import { FOOT_TO_MM } from "./speckleConstants";
import { checkStorey } from "./storey";

const checkIfFurnitureSingle = (elementId, singleFurnitureList) => {
  for (let i = 0; i < singleFurnitureList.length; ++i) {
    if (singleFurnitureList[i] === elementId) return true;
  }
  return false;
};

const getFurnitureFromElementId = (elementId, furnitureList) => {
  for (let i = 0; i < furnitureList.length; ++i) {
    if (furnitureList[i].elementId === elementId) return furnitureList[i];
  }
  return false;
};

const getElementFromFurnitureSet = (requiredFurnitureList, furnitureList) => {
  let requiredFurniture = [];
  for (let i = 0; i < requiredFurnitureList.length; ++i) {
    let furniture = getFurnitureFromElementId(
      requiredFurnitureList[i],
      furnitureList
    );
    if (furniture) {
      requiredFurniture.push(furniture);
    }
  }
  return requiredFurniture;
};

const checkIfModelGroupIsFlipped = (furnitureName, subComponent) => {
  let name = furnitureName.split("-");
  let flippedVersion = name.pop();
  name = name.join("-");
  if(subComponent.isFaceFlipped == true){
    return name + "-1"
  }else{
    return name + "-0"
  }
}
const createFurniture = (
  furnitures,
  allFurnituresData,
  furnitureMaterials,
  materialList,
  opt = {
    outermostFamily: true,
  }
) => {
  const structures = StructureCollection.getInstance();
  const str = structures.getStructureById(store.activeLayer.structure_id);
  const level = str.getLevelByName("01");
  let furnituresData = [];
  let furnitureData;
  let sourceFurnitureData = [];
  let options = {};
  const teamFurnitureMap = speckleRevitImport.getFurnitureMap();
  const teamId = speckleRevitImport.getTeamId();

  for (let idx in furnitures) {
    // if(idx == "1364568" || idx == "31115722" ||idx == "31115815"){
    try {
      const furnitureComponents = {};
      let furnitureData = allFurnituresData[idx];
      let mainFurnitureMesh = allFurnituresData[idx];
      let furnitureName = getRevitName(furnitureData);
      furnitureComponents[idx] = furnitureName;
      let furnitureSubComponentsData = furnitures[idx].subComponents;
      if (Object.keys(furnitureSubComponentsData).length > 0) {
        for (
          let subIdx = 0;
          subIdx < furnitureSubComponentsData.length;
          ++subIdx
        ) {
          const furnitureId = furnitureSubComponentsData[subIdx];
          const furnitureData = allFurnituresData[furnitureId];
          if (!furnitureData) {
            continue; // Door can contain other elements as security devices eg. 30672208 in cybercity project
          }
          const furnitureName = getRevitName(furnitureData);
          furnitureComponents[furnitureId] = furnitureName;
        }
      }
     
      let furnitureMaterialsData = [];
      let furnitureComponentsData = [];
      for (let id in furnitureComponents) {
        let furnitureMaterial = furnitureMaterials[furnitureComponents[id]];
        if (!furnitureMaterial || Object.keys(furnitureMaterial).length <= 1) {
          continue;
        } else {
          options.createUsingSpeckle = false;
          furnitureMaterialsData.push(furnitureMaterial);
          furnitureComponentsData.push(allFurnituresData[id]);
        }
      }
      if(mainFurnitureMesh.category == "Model Groups"){
        furnitureName = checkIfModelGroupIsFlipped(furnitureName, furnitureComponentsData[0])
      }
      let _furniture = store.scene.getMeshByName(furnitureName);
      options.name = furnitureName
      if (!_furniture) {
        let isSourceMesh = false;
        furnitureData = createFurnituresWithMeshData(
          furnitureComponentsData,
          furnitureMaterialsData,
          mainFurnitureMesh,
          materialList,
          options
        );
        isSourceMesh = true;
        if (furnitureData) {
          sourceFurnitureData.push(furnitureData.mesh);
        }
      }

      _furniture = store.scene.getMeshByName(furnitureName);
      let furniture = allFurnituresData[idx];

      if (_furniture) {
        const furnitureInstanceDS = createFurnitureInstance(
          furniture,
          _furniture,
          idx,
          furnitureComponentsData,
          teamFurnitureMap,
          teamId
        );
        if (furnitureInstanceDS) {
          furnituresData.push(furnitureInstanceDS.mesh);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  // }
  // onSolid();

  return { furnituresData, sourceFurnitureData };
};

const createFurnitureInstance = (
  furniture,
  _furniture,
  furnitureElementId,
  furnitureComponentsData,
  teamFurnitureMap = [],
  teamId
) => {
  let furnitureName = _furniture.name + "Ins" + _furniture.instances.length;
  let _furnitureInstance = _furniture.createInstance(furnitureName);
  _furnitureInstance.setAbsolutePosition(
    _furnitureInstance.getAbsolutePosition()
  );
  _furnitureInstance.type = "furniture";
  _furnitureInstance.structure_id = store.activeLayer.structure_id;

  let _furnitureDS = _furniture.getSnaptrudeDS();
  let angle;

  if (
    furniture.category == "Model Groups" ||
    furniture.category == "Assemblies"
  ) {
    angle =
      2 * Math.PI -
      (furnitureComponentsData[0].rotation -
        _furnitureDS.revitMetaData.offsetRotation);
  } else {
    angle = 2 * Math.PI - (furniture.rotation - _furnitureDS.revitRotation);
  }

  if (furniture.length && _furnitureDS.revitMetaData.length) {
    _furnitureInstance.scaling.x =
      furniture.length / _furnitureDS.revitMetaData.length;
  }
  
  const condition =  _furnitureDS.revitMetaData.isHandFlipped !== furniture.isHandFlipped && _furnitureDS.revitMetaData.mirrored !== furniture.mirrored
  if(condition){
    angle += Math.PI
  }

  _furnitureInstance.rotation.y = angle;
  let basePoint;
  if (furniture.bbCenter) {
    basePoint = furniture.bbCenter;
  } else {
    basePoint = [0, 0, 0];
  }

  let center = basePoint.map((point) =>
    DisplayOperation.getOriginalDimension(point, "millimeter")
  );
  _furnitureInstance.position = new BABYLON.Vector3(
    center[0],
    center[2],
    center[1]
  );

  let _newFurniture = new Furniture(_furnitureInstance);
  _newFurniture.autoInterior = true;
  _newFurniture.snapTo = "none";
  // _newFurniture.importType = _furniture.getSnaptrudeDS().importType;
  _newFurniture.revitMetaData.elementId = furnitureElementId;
  _newFurniture.revitMetaData.family = furniture.family;
  _newFurniture.revitMetaData.facingFlipped = furniture?.isFaceFlipped;
  _newFurniture.revitMetaData.revitOffset = furniture?.offset;
  _newFurniture.revitMetaData.category = furniture.category;
  _newFurniture.revitMetaData.familyRotation = _furnitureDS.revitRotation;
  _newFurniture.revitMetaData.type = furniture?.type;
  _newFurniture.revitMetaData.rotation = furniture?.rotation;
  _newFurniture.revitMetaData.boundingBoxCenter =
    _furnitureDS.revitMetaData.bbCenter;
  _newFurniture.revitMetaData.location = _furnitureDS.revitMetaData.basePoint;
  _newFurniture.revitMetaData.offset = _furnitureDS.revitMetaData.offset;
  _newFurniture.revitMetaData.elements = [];
  _newFurniture.teamId = teamId;
  if (teamId) {
    _newFurniture.breed = "team";
  }
  const nestedElements = _furnitureDS.revitMetaData.elementsData;

  let type = furniture?.type;
  let family = furniture?.family;
  let category = furniture.category;
  // let isCheckFamily = false;
  let mappedElement = getFurnitureFromFurnitureMap(
    teamFurnitureMap,
    type,
    family
  );
  if (mappedElement) {
    _newFurniture.revitMetaData.teamObjectInfo = mappedElement;
    if (category)
      _newFurniture.revitMetaData.teamObjectInfo["category"] = category;
  }
  for (let i = 0; i < nestedElements.length; ++i) {
    let type = nestedElements[i][0];
    const family = nestedElements[i][1];
    const category = nestedElements[i][2];
    let nestedElement = {};
    type = type.replace(".", "");
    let mappedElement = getFurnitureFromFurnitureMap(
      teamFurnitureMap,
      type,
      family
    );
    if (mappedElement) {
      nestedElement = { ...mappedElement };
    }
    nestedElement["type"] = type;
    if (family) nestedElement["family"] = family;
    if (category) nestedElement["category"] = category;

    _newFurniture.revitMetaData.elements.push(nestedElement);
  }

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  talkingAboutLevel.addObjectToLevel(_newFurniture, false);
  const storey = checkStorey(furniture?.levelName);
  if(storey){
    storey.addElement(_newFurniture);
  }else{
    StoreyMutation.assignStorey(_newFurniture);
  }
  
  // onSolid(_newFurniture.mesh, false, { enableFurnitureEdgesOnce: true });
  return _newFurniture;
};

const createFurnituresWithMeshData = (
  data,
  materials,
  mainFurnitureMesh,
  materialList,
  options
) => {
  if (!data || !materials) return;
  let material;
  let furnitureMesh,
    furnituresMesh = [];

  let mainMeshPosition = mainFurnitureMesh["bbCenter"].map((point) =>
    DisplayOperation.getOriginalDimension(point, "millimeter")
  );
  let angle = mainFurnitureMesh.rotation;
  for (let id = 0; id < data.length; ++id) {
    furnitureMesh = createCustomMeshFromRevitExport(
      data[id],
      materials[id],
      materialList,
      {
        renderUsingSpeckle: false,
      }
    );

    if (data.length > 1 && furnitureMesh) {
      furnitureMesh.rotation.y =
        2 * Math.PI - (data[id]["rotation"] - furnitureMesh.revitRotation);
      let center = data[id]["bbCenter"].map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      furnitureMesh.position = new BABYLON.Vector3(
        center[0] - mainMeshPosition[0],
        center[2] - mainMeshPosition[2],
        center[1] - mainMeshPosition[1]
      );
    } else if (data.length == 1 && furnitureMesh) {
      angle = furnitureMesh.revitRotation;
    }

    if (furnitureMesh) furnituresMesh.push(furnitureMesh);
  }
  if (furnituresMesh.length == 0) return;
  if (furnituresMesh.length > 1) {
    furnitureMesh = BABYLON.Mesh.MergeMeshes(
      furnituresMesh,
      true,
      true,
      undefined,
      true,
      true
    );

    material = furnitureMesh.material;
  } else {
    furnitureMesh = furnituresMesh[0];
  }

  if (furnitureMesh) {
    let furniture = new Furniture(furnitureMesh);
    furnitureMesh.position = new BABYLON.Vector3(10000, 0, 10000);
    furnitureMesh.computeWorldMatrix();
    furnitureMesh.structure_id = store.activeLayer.structure_id;

    furnitureMesh.type = "furniture"; //throwAway is overwritten when mesh is added to level
    // furnitureMesh.name = getRevitName(mainFurnitureMesh);
    furnitureMesh.name = options.name
    if (material) {
      const name = `${mainFurnitureMesh.type} ${mainFurnitureMesh.family}`;
      const isNewMaterial = store.scene.getMultiMaterialByID(name)
        ? false
        : true;
      material.id = name;
      material.name = name;
      // if(isNewMaterial){
      //   saveMaterialInBackend(material);
      // }
    }
    if (mainFurnitureMesh.length) {
      furniture.revitMetaData.length = mainFurnitureMesh.length;
    }
    if (mainFurnitureMesh.width) {
      furniture.revitMetaData.width = mainFurnitureMesh.width;
    }
    if (mainFurnitureMesh.height) {
      furniture.revitMetaData.height = mainFurnitureMesh.height;
    }
    furniture.storey = 1;

    furniture.room_type = "furniture";
    furniture.massType = "Furniture";
    furniture.revitMetaData.family = mainFurnitureMesh.family;
    furniture.revitMetaData.facingFlipped = mainFurnitureMesh?.isFaceFlipped;
    furniture.revitMetaData.type = mainFurnitureMesh?.type;
    if (
      mainFurnitureMesh.category == "Model Groups" ||
      mainFurnitureMesh.category == "Assemblies"
    ) {
      furniture.revitMetaData.offsetRotation = data[0].rotation;
    }

    let _basePoint = [
      mainFurnitureMesh.basePoint[0],
      mainFurnitureMesh.basePoint[2],
      mainFurnitureMesh.basePoint[1],
    ];
    let _basePointInMM = _basePoint.map((val) =>
      DisplayOperation.getOriginalDimension(val * FOOT_TO_MM, "millimeter")
    );
    furniture.revitMetaData.basePoint =
      BABYLON.Vector3.FromArray(_basePointInMM);

    furniture.revitMetaData.isHandFlipped = mainFurnitureMesh?.isHandFlipped;
    furniture.revitMetaData.mirrored = mainFurnitureMesh?.mirrored;

    let _bbCenter = [
      mainFurnitureMesh.bbCenter[0],
      mainFurnitureMesh.bbCenter[2],
      mainFurnitureMesh.bbCenter[1],
    ];
    let _bbCenterInMM = _bbCenter.map((val) =>
      DisplayOperation.getOriginalDimension(val, "millimeter")
    );
    furniture.revitMetaData.bbCenter = BABYLON.Vector3.FromArray(_bbCenterInMM);
    furniture.revitMetaData.elementsData = [];
    for (let id = 0; id < data.length; ++id) {
      if (data[id]?.type)
        furniture.revitMetaData.elementsData.push([
          data[id]?.type,
          data[id]?.family,
          data[id]?.category,
        ]);
    }

    furniture.revitMetaData.offset = furniture.revitMetaData.bbCenter.subtract(
      furniture.revitMetaData.basePoint
    );
    furniture.mesh.isVisible = false;
    furnitureMesh.storey = 1;
    furniture.storey = 1;
    furniture.height = furnitureMesh.height;
    furniture.structure_id = store.activeLayer.structure_id;
    furniture.revitRotation = angle;

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure = structureCollection.getStructureById(
      store.activeLayer.structure_id
    );
    const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
    talkingAboutLevel.addObjectToLevel(furniture, false);

    return furniture;
  }
};

const calculateCenterForFurnitureSystem = (data) => {
  let maxPoints = [],
    minPoints = [];
  for (let i = 0; i < data.length; ++i) {
    maxPoints.push(data[i]["upperRightFrontCorner"]);
    minPoints.push(data[i]["lowerLeftRearCorner"]);
  }
  let [xMax, yMax, zMax] = [
    Math.max(...maxPoints.map((point) => point[0])),
    Math.max(...maxPoints.map((point) => point[1])),
    Math.max(...maxPoints.map((point) => point[2])),
  ];
  let [xMin, yMin, zMin] = [
    Math.min(...minPoints.map((point) => point[0])),
    Math.min(...minPoints.map((point) => point[1])),
    Math.min(...minPoints.map((point) => point[2])),
  ];

  return [(xMax + xMin) / 2, (yMax + yMin) / 2, (zMax + zMin) / 2];
};
let createFurnitureSet = (
  furnitureSet,
  furnitureSetMap,
  furnituresList,
  furnitureMaterials,
  furnitureMaterial,
  materialList,
  furnituresInSet,
  locationArray
) => {
  let sourceFurnitureSet,
    furnituresData = [],
    furnitureData,
    center;
  let furnitureList = [],
    furnitureMaterialList = [];
  let requiredFurnitures = furnitureSetMap[furnitureSet.elementId];
  if (requiredFurnitures) {
    let furnitures = getElementFromFurnitureSet(
      requiredFurnitures,
      furnituresInSet
    );
    let basePoint;
    if (furnitureSet.basePoint) {
      basePoint = [
        furnitureSet.basePoint.x,
        furnitureSet.basePoint.y,
        furnitureSet.basePoint.z,
      ];
    } else {
      basePoint = [0, 0, 0];
    }
    center = getDimensionInSnaptrudeUnits([basePoint], "millimeter")[0];
    if (furnitures.length > 0) {
      furnitures.map((furniture) => {
        let furnitureName = `${furniture.type} ${furniture.family}`;
        if (furniture.parameters.FURNITURE_WIDTH?.value)
          furnitureName += `-${parseInt(
            furniture.parameters.FURNITURE_WIDTH.value
          )}`;
        else if (furniture.parameters.CASEWORK_WIDTH?.value)
          furnitureName += `-${parseInt(
            furniture.parameters.CASEWORK_WIDTH.value
          )}`;
        if (furniture.parameters.DOOR_HEIGHT?.value)
          furnitureName += `x${parseInt(
            furniture.parameters.DOOR_HEIGHT.value
          )}`;
        else if (furniture.parameters.WINDOW_HEIGHT?.value)
          furnitureName += `x${parseInt(
            furniture.parameters.WINDOW_HEIGHT.value
          )}`;
        else if (furniture.parameters.CASEWORK_HEIGHT?.value)
          furnitureName += `x${parseInt(
            furniture.parameters.CASEWORK_HEIGHT.value
          )}`;
        furnitureName += furniture.facingFlipped ? "-1" : "-0";
        let furnitureMaterial = furnitureMaterials[furnitureName];
        if (furnitureMaterial) {
          furnitureList.push(furniture);
          furnitureMaterialList.push(furnitureMaterial);
        }
      });

      try {
        for (let f = 0; f < furnitureList.length; f++) {
          furnitureData = createFurnituresWithMeshData(
            furnitureList[f],
            [],
            [],
            [],
            furnitureMaterialList[f],
            materialList,
            locationArray,
            {
              isFurnitureSet: true,
              createUsingSpeckle: false,
              furnitureSetPosition: center,
              offset: null,
            }
          );
          furnituresData.push(furnitureData);
        }
      } catch (error) {
        console.log(error);
        furnituresData.forEach((furniture) => furniture.dispose());
        return;
      }

      if (!furnituresData?.length) return;

      let mergedMesh = BABYLON.Mesh.MergeMeshes(
        furnituresData,
        true,
        true,
        undefined,
        true,
        true
      );
      let material = mergedMesh.material;

      mergedMesh.structure_id = store.activeLayer.structure_id;
      let _furnitureSet = new Furniture(mergedMesh);
      _furnitureSet.mesh.type = "furniture"; //throwAway is overwritten when mesh is added to level

      _furnitureSet.offset = center;
      _furnitureSet.rotation = furnitureSet.rotation;
      mergedMesh.position = new BABYLON.Vector3(10000, 0, 10000);
      mergedMesh.computeWorldMatrix();
      mergedMesh.name = `${furnitureSet.type} ${furnitureSet.family}`;
      if (furnitureSet.parameters.FURNITURE_WIDTH?.value)
        mergedMesh.name += `-${parseInt(
          furnitureSet.parameters.FURNITURE_WIDTH.value
        )}`;
      else if (furnitureSet.parameters.CASEWORK_WIDTH?.value)
        mergedMesh.name += `-${parseInt(
          furnitureSet.parameters.CASEWORK_WIDTH.value
        )}`;
      if (furnitureSet.parameters.DOOR_HEIGHT?.value)
        mergedMesh.name += `x${parseInt(
          furnitureSet.parameters.DOOR_HEIGHT.value
        )}`;
      else if (furnitureSet.parameters.WINDOW_HEIGHT?.value)
        mergedMesh.name += `x${parseInt(
          furnitureSet.parameters.WINDOW_HEIGHT.value
        )}`;
      else if (furnitureSet.parameters.CASEWORK_HEIGHT?.value)
        mergedMesh.name += `x${parseInt(
          furnitureSet.parameters.CASEWORK_HEIGHT.value
        )}`;
      mergedMesh.name += furnitureSet.facingFlipped ? "-1" : "-0";
      material.id = mergedMesh.name;
      material.name = mergedMesh.name;
      // saveMaterialInBackend(material);

      _furnitureSet.revitMetaData.type = "FurnitureSet";
      _furnitureSet.revitMetaData.offset = [-center[0], -center[2], -center[1]];
      _furnitureSet.revitMetaData.elementId = furnitureSet.elementId;
      _furnitureSet.revitMetaData.family = furnitureSet.family;
      _furnitureSet.revitMetaData.type = furnitureSet?.type;
      _furnitureSet.revitMetaData.facingFlipped = furnitureSet?.facingFlipped;
      _furnitureSet.mesh.isVisible = false;
      mergedMesh.storey = 1;
      _furnitureSet.storey = 1;
      _furnitureSet.height = mergedMesh.height;

      const structureCollection = StructureCollection.getInstance();
      const talkingAboutStructure = structureCollection.getStructureById(
        store.activeLayer.structure_id
      );
      const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
      talkingAboutLevel.addObjectToLevel(_furnitureSet, false);
      sourceFurnitureSet = _furnitureSet;
    }
  }
  return sourceFurnitureSet;
  // }
};

const createFurnitureSetInstance = (furnitureSet, _furnitureSet) => {
  let furnitureName =
    _furnitureSet.name + " Ins" + _furnitureSet.instances.length;
  let angle;
  let _furnitureDS = _furnitureSet.getSnaptrudeDS();
  angle = 2 * Math.PI - (furnitureSet.rotation - _furnitureDS.rotation);
  let basePoint;
  if (furnitureSet.basePoint) {
    basePoint = [
      furnitureSet.basePoint.x,
      furnitureSet.basePoint.y,
      furnitureSet.basePoint.z,
    ];
  } else {
    basePoint = [0, 0, 0];
  }

  let center = getDimensionInSnaptrudeUnits([basePoint], "millimeter")[0];

  let _furnitureInstance = _furnitureSet.createInstance(furnitureName);
  _furnitureInstance.setAbsolutePosition(
    _furnitureInstance.getAbsolutePosition()
  );

  _furnitureInstance.position = new BABYLON.Vector3(
    center[0],
    center[2],
    center[1]
  );
  _furnitureInstance.rotation.y = angle;

  _furnitureInstance.computeWorldMatrix();

  _furnitureInstance.type = "furniture";
  _furnitureInstance.structure_id = store.activeLayer.structure_id;

  let _newFurniture = new Furniture(_furnitureInstance);
  _newFurniture.autoInterior = true;
  _newFurniture.snapTo = "none";
  // _newFurniture.importType = _furnitureSet.getSnaptrudeDS().importType;
  _newFurniture.revitMetaData.elementId = furnitureSet.elementId;
  _newFurniture.revitMetaData.family = furnitureSet.family;
  _newFurniture.revitMetaData.familyRotation = _furnitureDS.rotation;
  _newFurniture.revitMetaData.speckleRotation = furnitureSet.rotation;
  _newFurniture.revitMetaData.facingFlipped = furnitureSet?.facingFlipped;
  _newFurniture.revitMetaData.type = furnitureSet?.type;

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  talkingAboutLevel.addObjectToLevel(_newFurniture, false);
  StoreyMutation.assignStorey(_newFurniture);
  // onSolid(_newFurniture.mesh, false, { enableFurnitureEdgesOnce: true });

  return _newFurniture;
};

const isFurnitureSetThrowAway = (furniture) => {
  return (
    furniture.revitMetaData.type == "FurnitureSet" &&
    furniture.mesh.type.toLowerCase() == "furniturethrowaway"
  );
};

export { createFurniture, createFurnitureSet, isFurnitureSetThrowAway };
