import React, { useRef, useEffect, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { terrainGeneration } from "../../snaptrude/modules/geo/terrainMap";
import styled from "styled-components";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw";

const MapSelection = styled.div`
  border: 2px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: ${(props) => props.scale > 5000 ? 2 : 10000/props.scale}%;
  height: ${(props) => props.scale > 5000 ? 2 : 10000/props.scale}%;
  pointer-events: none;
  display: ${(props) => props.scale < 100 ? "none" : "block"};
`


export default function Mapbox({ isHidden = false, satelliteStyle = false }) {
  const styles = {
    wrapper: {
      display: isHidden ? "none" : "flex",
      padding: "10px",
    },
    mapContainerWrapper: {
      // width: '300px',
      // height: '250px',
      width: "600px",
      height: "500px",
      // padding: "5px",
      position: "relative",
    },
    mapContainer: {
      width: "100%",
      height: "100%",
    },
  };

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-77.04);
  const [lat, setLat] = useState(38.907);
  const [zoom, setZoom] = useState(15);
  const [scale, setScale] = useState(100);

  // "mapbox://styles/mapbox/satellite-v9" for sat image
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: !satelliteStyle
        ? "mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g"
        : "mapbox://styles/poojaghodmode/ckw1osjtm9zg514qn4f32gt3g",
      center: [lng, lat],
      zoom: zoom,
    });
    store.terrainMap = map.current;
    terrainGeneration.init(map.current);
    // terrainGeneration.updateCenterAndZoomOfTerrain();
    terrainGeneration.moveMapToLastKnownLocation();
  }, []);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("style.load", function () {
      if (!map.current.getSource("dem")) {
        map.current.addSource("dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.terrain-rgb",
        });
      }

      if (!map.current.getSource("mapbox-dem")) {
        map.current.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 14,
        });
      }

      if (!map.current.getLayer("hillshading")) {
      map.current.addLayer(
        {
          id: "hillshading",
          source: "dem",
          type: "hillshade",
          // insert below waterway-river-canal-shadow;
          // where hillshading sits in the Mapbox Outdoors style
        },
        "waterway-river-canal-shadow"
      );}

      map.current.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
    });
  });

  useEffect(() => {
    map.current.on("load", () => {
      let unit = "metric";

      map.current.dragRotate.disable();
      map.current.touchZoomRotate.disableRotation();

      let scaleControl = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: unit,
      });

      if (!map.current.hasControl(scaleControl)) {
        map.current.addControl(scaleControl);
      }
      let geoCoderControl = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: terrainGeneration.coordinatesGeocoder,
        placeholder: 'Try: -77.04, 38.907',
        mapboxgl: mapboxgl,
        reverseGeocode: true
      });

      if (!map.current.hasControl(geoCoderControl)) {
        map.current.addControl(geoCoderControl);
      }
    });

    map.current.on("move", () => {
      const scaleControl = store.terrainMap._controls[2]
      if(scaleControl){
        let scaleValues = scaleControl._container.innerHTML.split("&nbsp;");
        if(scaleValues.length > 1){
          const unit = scaleValues[1];
          let val = parseInt(scaleValues[0]);
          if(unit === "km") val *= 1000;
          setScale(val);
        }
      }
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, []);

  return (
    <div style={styles.wrapper}>
      <div style={styles.mapContainerWrapper}>
        <MapSelection scale={scale} />
        <div ref={mapContainer} style={styles.mapContainer} />
      </div>
    </div>
  );
}
