import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import addIcon from "../../../assets/icons/addIcon.svg";
import PersonalSidebar from "./PersonalSidebar";
import TeamSidebar from "./TeamSidebar";

const Wrapper = styled.div`
    // padding-left: 10px;
    overflow: auto;
    overflow-x: hidden;
    flex: 1;
    overscroll-behavior: contain;
    img{
        display: block;
    }
    .titlebar{
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .titlebar:hover{
        background-color: ${colors.white};
        cursor: pointer;
    }
    .titlebar-dragover{
        background-color: ${colors.white};
    }
    .title{
        display: flex;
        flex: 1;
        align-items: center;
        color: ${colors.thinGray};
    }
`
function WorkspaceSidebar({ setShowCreateTeamModal }) {
    const { teamlist, selectedTeam, personalWorkspace } = useSelector(state => {
        const filteredTeamlist = state.teams.teamlist.filter(team => team.me.status !== "invited");
        return {
            teamlist: filteredTeamlist,
            selectedTeam: state.teams.teamlist.find(team => team.id === state.teams.selectedTeam?.id),
            personalWorkspace: state.teams.personalWorkspace
        }
    });
    const permissions = useMemo(() => {
        return selectedTeam?.roleBasedPermissions[selectedTeam.role];
    }, [selectedTeam])
    return (
        <Wrapper>
            <PersonalSidebar selectedTeamID={selectedTeam?.id} personalWorkspace={personalWorkspace} permissions={{"create_rename_delete_folder": true}} />
            {
                teamlist.map(team => {
                    return <TeamSidebar team={team} key={team.id} selectedTeamID={selectedTeam?.id} permissions={permissions} />
                })
            }
            <div className={`titlebar`} >
                <div className={`title`} onClick={setShowCreateTeamModal}>
                    <div className="icon"><img src={addIcon} alt="team" /></div>
                    <div className="titletext">Create your team</div>
                </div>
            </div>
        </Wrapper>
    );
}

export default WorkspaceSidebar;