import React, { useContext, useState } from "react";
import styled from "styled-components";

import * as constants from "./constants";
import { SearchInput } from "../../../components/searchInput/index";
import Card from "../../../components/Card/index";
import { Link } from "react-router-dom";
import apartement from "../../../assets/guidedProjects/apartement.jpg";
import contoured from "../../../assets/guidedProjects/contoured.jpg";
import interiors from "../../../assets/guidedProjects/interiors.jpg";
import residence from "../../../assets/guidedProjects/residence.jpg";
import twoStoreyResidence from "../../../assets/guidedProjects/twoStoreyResidence.jpg";
import broadway777 from "../../../assets/guidedProjects/799Broadway.jpg";
import Fuse from "fuse.js";
import HistoryContext from "../../../components/Context/HistoryContext";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;



const Projects = [
  // {
  //   id: "AGGVK6",
  //   name: "Apartment Block",
  //   src: apartement,
  //   link: "/model/AGGVK6/",
  //   desc: "",
  // },
  {
    id: "ALVAGF",
    name: "Contemporary Residence",
    src: residence,
    link: "/model/ALVAGF/",
    desc: "",
  },
  {
    id: "AOX5IU",
    name: "Contoured Terraces",
    src: contoured,
    link: "/model/AOX5IU/",
    desc: "",
  },
  {
    id: "AWZUMJ",
    name: "Residential Interiors",
    src: interiors,
    link: "/model/AWZUMJ/",
    desc: "",
  },
  {
    id: "A3NU2W",
    name: "Sample 2 Storey Residence",
    src: twoStoreyResidence,
    link: "/model/A3NU2W",
    desc: "",
  },
  {
    id: "AGUXE7",
    name: "799 Broadway Lookalike",
    src: broadway777,
    link: "/model/AGUXE7",
    desc: "",
  }
]

const fuseList = new Fuse(Projects, {
  shouldSort: true,
  keys: ["name", "desc"]
})

const GuidedProjects = () => {
  const [projects, setProjects] = useState(Projects);
  const [searchText, setSearchText] = useState("");
  const history = useContext(HistoryContext);
  const handleSearchProjects = (evt) => {
    evt.preventDefault();
    if(searchText === ""){
      setProjects(Projects);
      return;
    }
    const matchedProjects = fuseList.search(searchText);
    setProjects(matchedProjects);
  }
  const takeToProject = (link) => {
    history.push(link)
  }
  return (
    <MainContainer>
      <div className="content">
        <div className="header">
          <div className="project-header">
            <h3>{constants.PROJECT_HEADER}</h3>
          </div>
          <div className="header-div">
            <SearchInput value={searchText} onChange={(evt) => {setSearchText(evt.target.value)}}  onSubmit={handleSearchProjects} />
          </div>
        </div>
        <div className="cards">
          {projects.map((project, index) => {
            return (
              <Card
                onClick={() => {
                  window.analytics.track(`clicked sample project - ${project.id}`);
                  takeToProject(project.link)
                }}
                inner={<div />}
                headerText={project.name}
                description={project.desc}
                key={project.id}
                image={project.src}
              />
            );
          })}
        </div>
      </div>
    </MainContainer>
  );
};

export default GuidedProjects;
