import BABYLON from "../../babylonDS.module.js";
import _ from "lodash";
import { store } from "../../utilityFunctions/Store.js"
import { colorUtil } from "../../utilityFunctions/colorUtility.js";
import { isTwoDimension } from "../../../libs/twoDimension.js";
import { Mass } from "../../snaptrudeDS/mass.ds.js";
import { onSolid } from "../../extrafunc.js";
import { setLayerTransperancy } from "../../../libs/sceneFuncs.js";
import { uiIndicatorsHandler } from "../../uiIndicatorOperations/uiIndicatorsHandler.js";
import { getTopFaceVertices,sanitizeVerticesInPlace,getAllEdges } from "../../../libs/brepOperations.js";
const pseudoEdgeRenderer = (function () {
  const CONSTANTS = {
    EDGE_COLOR_BLACK: colorUtil.type.black,
    EDGE_COLOR_POST_SNAP: colorUtil.type.postSnap,
    EDGE_COLOR_COPIES: colorUtil.type.y, // lime green.
    LINE_WIDTH: 2,
    EDGE_WIDTH: 35.0,
    EDGE_COLOR_COPIES_COLOR4: null,
  };

  const init = () => {
    CONSTANTS.EDGE_COLOR_COPIES_COLOR4 = BABYLON.Color4.FromColor3(
      colorUtil.getColor(CONSTANTS.EDGE_COLOR_COPIES)
    );
  }

  let _onSolidComponents = [];
  let _onSolidCopyComponents = [];
  let _edgeDrawComponents = [];
  let _edgeDrawCopyComponents = [];

  /**
   * Replaces instances, if present, with sourceMeshes
   * Reduces the number of calls of onSolid
   *
   * @param components
   * @private
   */
  const _getSourceComponents = function (components) {
    const sourceComponents = components.map((c) => {
      return c.mesh.isAnInstance ? c.mesh.sourceMesh.getSnaptrudeDS() : c;
    });

    return _.uniq(sourceComponents);
  };

  const initialize = function (components) {
    
    flush();
    const sourceMeshesHandled = [];
    const primaryComponents = components;

    components.forEach((c) => {
      if (store.$scope.isTwoDimension) {
        if (c.storey === store.activeLayer.storey) {
          if (
            c.mesh.isAnInstance &&
            !sourceMeshesHandled.includes(c.mesh.sourceMesh)
          ) {
            sourceMeshesHandled.push(c.mesh.sourceMesh);

            const instanceComponents = c.mesh.sourceMesh.instances.map((i) =>
              i.getSnaptrudeDS()
            );
            if (instanceComponents.length === 1) _edgeDrawComponents.push(c);
            else {
              const instancesOnSameStorey = instanceComponents.filter(
                (ic) => ic.storey === c.storey
              );
              const instancesNotOnSameStorey = instanceComponents.filter(
                (ic) => ic.storey !== c.storey
              );
              _edgeDrawCopyComponents.push(...instancesOnSameStorey);
              _onSolidComponents.push(...instancesNotOnSameStorey);
            }
          } else {
            sourceMeshesHandled.push(c.mesh);
            _edgeDrawComponents.push(c);
          }
        } else {
          _onSolidComponents.push(c);
        }
      } else {
        if (
          c.mesh.isAnInstance &&
          !sourceMeshesHandled.includes(c.mesh.sourceMesh)
        ) {
          sourceMeshesHandled.push(c.mesh.sourceMesh);

          const instanceComponents = c.mesh.sourceMesh.instances.map((i) =>
            i.getSnaptrudeDS()
          );
          if (instanceComponents.length === 1) _onSolidComponents.push(c);
          else {
            if (c.mesh.type.toLowerCase() === "mass") {
              if (["Room"].includes(c.massType)) {
                // room
                _onSolidCopyComponents.push(...instanceComponents);
              } else if (c.massType === "Mass") {
                // mass
                _onSolidCopyComponents.push(...instanceComponents);
              } else {
                // column, pergola
                _edgeDrawCopyComponents.push(...instanceComponents);
              }
            } else if (c.mesh.type.toLowerCase() === "roof") {
              _onSolidCopyComponents.push(...instanceComponents);
            } else {
              _edgeDrawCopyComponents.push(...instanceComponents);
            }
          }
        } else {
          sourceMeshesHandled.push(c.mesh);
          _onSolidComponents.push(c);
        }
      }
    });

    _edgeDrawComponents.forEach((c) => c.mesh.disableEdgesRendering());
    _edgeDrawCopyComponents.forEach((c) => c.mesh.disableEdgesRendering());

    // _onSolidComponents = _getSourceComponents(_onSolidComponents);
    // _onSolidCopyComponents = _getSourceComponents(_onSolidCopyComponents);

    // in some cases, a few instances must have onSolid, and some edgeDraw. So, can't use the sourceMesh
    // and apply one rule to all instances; have to do it on a case by case basis

    // putting non instances in the beginning of the list
    // if multiColouredEdges is set, instances get different color, so put them last
    _edgeDrawComponents.sort((c1, c2) => {
      if (c1.mesh.isAnInstance && c2.mesh.isAnInstance) {
        if (primaryComponents.includes(c1) && primaryComponents.includes(c2))
          return 0;
        else if (primaryComponents.includes(c1)) return 1;
        else if (primaryComponents.includes(c2)) return -1;
      } else if (c1.mesh.isAnInstance) return 1;
      else if (c2.mesh.isAnInstance) return -1;
    });

    _edgeDrawCopyComponents.sort((c1, c2) => {
      if (c1.mesh.isAnInstance && c2.mesh.isAnInstance) {
        if (primaryComponents.includes(c1) && primaryComponents.includes(c2))
          return 0;
        else if (primaryComponents.includes(c1)) return 1;
        else if (primaryComponents.includes(c2)) return -1;
      } else if (c1.mesh.isAnInstance) return 1;
      else if (c2.mesh.isAnInstance) return -1;
    });

    update();
  };

  const update = function () {
    _onSolidComponents.forEach((c) => {
      onSolid(c.mesh, false);
      setLayerTransperancy(c.mesh, undefined, false);
    });

    _onSolidCopyComponents.forEach((c) => {
      const options = {
        edgesColor: CONSTANTS.EDGE_COLOR_COPIES_COLOR4,
        edgesWidth: CONSTANTS.EDGE_WIDTH,
      };

      onSolid(c.mesh, false, options);
    });

    uiIndicatorsHandler.edgeIndicator.massDestruct();

    const allEdgeDrawComponents = _.union(
      _edgeDrawComponents,
      _edgeDrawCopyComponents
    );

    const colors = [];
    const arrayOfVerticesOrArrayOfArrayOfEdges = allEdgeDrawComponents.map(
      (c) => {
        if (_edgeDrawCopyComponents.includes(c))
          colors.push(CONSTANTS.EDGE_COLOR_COPIES);
        else colors.push(CONSTANTS.EDGE_COLOR_BLACK);

        if (store.$scope.isTwoDimension) {
          const vertices = getTopFaceVertices(c);
          sanitizeVerticesInPlace(vertices);

          return vertices;
        } else {
          return getAllEdges(c);
        }
      }
    );

    const options = {
      colors,
    };

    if (store.$scope.isTwoDimension)
      uiIndicatorsHandler.edgeIndicator.massConstruct(
        arrayOfVerticesOrArrayOfArrayOfEdges,
        [],
        options
      );
    else {
      options.lineWidth = CONSTANTS.LINE_WIDTH;
      uiIndicatorsHandler.edgeIndicator.massConstructFromEdges(
        arrayOfVerticesOrArrayOfArrayOfEdges,
        options
      );
    }
  };

  const flush = function () {
    _onSolidComponents.length = 0;
    _onSolidCopyComponents.length = 0;
    _edgeDrawComponents.length = 0;
    _edgeDrawCopyComponents.length = 0;

    uiIndicatorsHandler.edgeIndicator.massDestruct();
  };

  return {
    CONSTANTS,
    init,
    initialize,
    update,
    flush,
  };
})();
export { pseudoEdgeRenderer };
