"use strict";
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { AutoSave } from "../socket/autoSave.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { Command } from "../commandManager/Command.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import earcut from "earcut";

var line2D = function (name, options, scene, sketchColor) {
  //Arrays for vertex positions and indices
  var positions = [];
  var indices = [];
  var normals = [];
  var standardUV;

  var width = options.width / 2 || 0.5;
  var path = options.path;
  var closed = options.closed || false;
  if (options.standardUV === undefined) {
    standardUV = true;
  } else {
    standardUV = options.standardUV;
  }

  var interiorIndex;

  //Arrays to hold wall corner data
  var innerBaseCorners = [];
  var outerBaseCorners = [];

  var outerData = [];
  var innerData = [];
  var angle = 0;

  var nbPoints = path.length;
  var line = BABYLON.Vector3.Zero();
  var nextLine = BABYLON.Vector3.Zero();
  path[1].subtractToRef(path[0], line);

  if (nbPoints > 2 && closed) {
    path[2].subtractToRef(path[1], nextLine);
    for (var p = 0; p < nbPoints; p++) {
      angle =
        Math.PI -
        Math.acos(
          BABYLON.Vector3.Dot(line, nextLine) /
            (line.length() * nextLine.length())
        );
      let direction = BABYLON.Vector3.Cross(line, nextLine).normalize().y;
      lineNormal = new BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize();
      line.normalize();
      innerData[(p + 1) % nbPoints] = path[(p + 1) % nbPoints]
        .subtract(lineNormal.scale(width))
        .subtract(line.scale((direction * width) / Math.tan(angle / 2)));
      outerData[(p + 1) % nbPoints] = path[(p + 1) % nbPoints]
        .add(lineNormal.scale(width))
        .add(line.scale((direction * width) / Math.tan(angle / 2)));
      line = nextLine.clone();
      path[(p + 3) % nbPoints].subtractToRef(
        path[(p + 2) % nbPoints],
        nextLine
      );
    }
  } else {
    var lineNormal = new BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize();
    line.normalize();
    innerData[0] = path[0].subtract(lineNormal.scale(width));
    outerData[0] = path[0].add(lineNormal.scale(width));

    for (let p = 0; p < nbPoints - 2; p++) {
      path[p + 2].subtractToRef(path[p + 1], nextLine);
      angle =
        Math.PI -
        Math.acos(
          BABYLON.Vector3.Dot(line, nextLine) /
            (line.length() * nextLine.length())
        );
      let direction = BABYLON.Vector3.Cross(line, nextLine).normalize().y;
      lineNormal = new BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize();
      line.normalize();
      innerData[p + 1] = path[p + 1]
        .subtract(lineNormal.scale(width))
        .subtract(line.scale((direction * width) / Math.tan(angle / 2)));
      outerData[p + 1] = path[p + 1]
        .add(lineNormal.scale(width))
        .add(line.scale((direction * width) / Math.tan(angle / 2)));
      line = nextLine.clone();
    }
    if (nbPoints > 2) {
      path[nbPoints - 1].subtractToRef(path[nbPoints - 2], line);
      lineNormal = new BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize();
      line.normalize();
      innerData[nbPoints - 1] = path[nbPoints - 1].subtract(
        lineNormal.scale(width)
      );
      outerData[nbPoints - 1] = path[nbPoints - 1].add(lineNormal.scale(width));
    } else {
      innerData[1] = path[1].subtract(lineNormal.scale(width));
      outerData[1] = path[1].add(lineNormal.scale(width));
    }
  }

  var maxX = Number.MIN_VALUE;
  var minX = Number.MAX_VALUE;
  var maxZ = Number.MIN_VALUE;
  var minZ = Number.MAX_VALUE;

  for (let p = 0; p < nbPoints; p++) {
    positions.push(innerData[p].x, innerData[p].y, innerData[p].z);
    maxX = Math.max(innerData[p].x, maxX);
    minX = Math.min(innerData[p].x, minX);
    maxZ = Math.max(innerData[p].z, maxZ);
    minZ = Math.min(innerData[p].z, minZ);
  }

  for (let p = 0; p < nbPoints; p++) {
    positions.push(outerData[p].x, outerData[p].y, outerData[p].z);
    maxX = Math.max(innerData[p].x, maxX);
    minX = Math.min(innerData[p].x, minX);
    maxZ = Math.max(innerData[p].z, maxZ);
    minZ = Math.min(innerData[p].z, minZ);
  }

  for (var i = 0; i < nbPoints - 1; i++) {
    indices.push(i, i + 1, nbPoints + i + 1);
    indices.push(i, nbPoints + i + 1, nbPoints + i);
  }

  if (nbPoints > 2 && closed) {
    indices.push(nbPoints - 1, 0, nbPoints);
    indices.push(nbPoints - 1, nbPoints, 2 * nbPoints - 1);
  }

  normals = [];
  var uvs = [];

  if (standardUV) {
    for (let p = 0; p < positions.length; p += 3) {
      uvs.push(
        (positions[p] - minX) / (maxX - minX),
        (positions[p + 2] - minZ) / (maxZ - minZ)
      );
    }
  } else {
    var flip = 0;
    var p1 = 0;
    var p2 = 0;
    var p3 = 0;
    var v0 = innerData[0];
    var v1 = innerData[1].subtract(v0);
    var v2 = outerData[0].subtract(v0);
    var v3 = outerData[1].subtract(v0);
    var axis = v1.clone();
    axis.normalize();

    p1 = BABYLON.Vector3.Dot(axis, v1);
    p2 = BABYLON.Vector3.Dot(axis, v2);
    p3 = BABYLON.Vector3.Dot(axis, v3);
    minX = Math.min(0, p1, p2, p3);
    maxX = Math.max(0, p1, p2, p3);

    uvs[2 * indices[0]] = -minX / (maxX - minX);
    uvs[2 * indices[0] + 1] = 1;
    uvs[2 * indices[5]] = (p2 - minX) / (maxX - minX);
    uvs[2 * indices[5] + 1] = 0;

    uvs[2 * indices[1]] = (p1 - minX) / (maxX - minX);
    uvs[2 * indices[1] + 1] = 1;
    uvs[2 * indices[4]] = (p3 - minX) / (maxX - minX);
    uvs[2 * indices[4] + 1] = 0;

    for (let i = 6; i < indices.length; i += 6) {
      flip = (flip + 1) % 2;
      v0 = innerData[0];
      v1 = innerData[1].subtract(v0);
      v2 = outerData[0].subtract(v0);
      v3 = outerData[1].subtract(v0);
      axis = v1.clone();
      axis.normalize();

      p1 = BABYLON.Vector3.Dot(axis, v1);
      p2 = BABYLON.Vector3.Dot(axis, v2);
      p3 = BABYLON.Vector3.Dot(axis, v3);
      minX = Math.min(0, p1, p2, p3);
      maxX = Math.max(0, p1, p2, p3);

      uvs[2 * indices[i + 1]] =
        flip + (Math.cos(flip * Math.PI) * (p1 - minX)) / (maxX - minX);
      uvs[2 * indices[i + 1] + 1] = 1;
      uvs[2 * indices[i + 4]] =
        flip + (Math.cos(flip * Math.PI) * (p3 - minX)) / (maxX - minX);
      uvs[2 * indices[i + 4] + 1] = 0;
    }
  }

  BABYLON.VertexData.ComputeNormals(positions, indices, normals);
  BABYLON.VertexData._ComputeSides(
    BABYLON.Mesh.DOUBLESIDE,
    positions,
    indices,
    normals,
    uvs
  );
  //console.log(uvs)
  //Create a custom mesh
  var customMesh = new BABYLON.Mesh(name, scene);

  //Create a vertexData object
  var vertexData = new BABYLON.VertexData();
  //Assign positions and indices to vertexData
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  //Apply vertexData to custom mesh
  vertexData.applyToMesh(customMesh);
  let color = sketchColor;
  if (!color) color = [0, 0, 0];
  let colors = customMesh.getVerticesData(BABYLON.VertexBuffer.ColorKind);
  if (!colors) {
    colors = [];
    for (let p = 0; p < positions.length / 3; p++) {
      colors.push(...color, 1);
    }
  }
  customMesh.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors);

  return customMesh;
};

let circle2D = function (radius, sides, scene, sketchColor) {
  var path = [];
  var pi2 = Math.PI * 2;
  var step = pi2 / sides;
  for (var i = 0; i < pi2; i += step) {
    var x = radius * Math.sin(i);
    var z = radius * Math.cos(i);
    var y = 0;
    path.push(new BABYLON.Vector3(x, y, z));
  }
  //path.push(path[0]);

  let filledCircle = BABYLON.MeshBuilder.CreatePolygon(
    "originalCircle",
    { shape: path, sideOrientation: BABYLON.Mesh.DOUBLESIDE },
    store.scene,
    earcut
  );
  filledCircle.position = BABYLON.Vector3.Zero();
  let color = sketchColor;
  if (!color) color = [0, 0, 0];
  let colors = filledCircle.getVerticesData(BABYLON.VertexBuffer.ColorKind);
  if (!colors) {
    colors = [];
    let positions = filledCircle.getVerticesData(
      BABYLON.VertexBuffer.PositionKind
    );
    for (let p = 0; p < positions.length / 3; p++) {
      colors.push(...color, 1);
    }
  }
  filledCircle.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors);
  return filledCircle;
};

var sketchOperation = (function () {
  "use strict";
  let sketchMesh = null;
  let sketchStartingPoint = null;
  let lines = {};
  let lineseed = [];
  let dist = null;
  let layerName = null;
  let defaultSketchLayer = "Wall";
  let sketchCommandName = "sketchOperation";
  let radius = 0.12;
  let numberOfEdgesOnCircle = 20;

  const _pointArrayToVectorArray = (pointsArray) => {
    const newPointsArr = [];
    pointsArray.forEach(function(points) {
        let newPoints = [];
        points.forEach(function(point) {
            newPoints.push(new BABYLON.Vector3(point[0], point[1], point[2]));
        });
        newPointsArr.push(newPoints);
    });
    return newPointsArr;
  };

  const getMeshPosition = (evt) => {
    let pickInfo = store.scene.pick(
      store.scene.pointerX,
      store.scene.pointerY,
      function (mesh) {
        return mesh == sketchMesh;
      }
    );
    if (pickInfo.hit) {
      return pickInfo.pickedPoint;
    }

    return null;
  };

  const lineSystemCreation = () => {
    if (lines.points.length) {
      let lineSystem = BABYLON.MeshBuilder.CreateLineSystem(
        "lineSystem",
        {
          lines: lines.points,
          updatable: true,
        },
        store.scene
      );
      lineSystem.name = "sketchLine";
      lineSystem.color = new BABYLON.Color3(0, 0, 0);
      lineSystem.isPickable = false;
      lines.mesh.forEach(function (mesh) {
        mesh.dispose();
      });
      let sketch = {};
      sketch.points = lines.points;
      sketch.mesh = lineSystem;
      sketch.mesh.structure_id = store.activeLayer.structure_id;
      sketch.mesh.type = "linesystem";
      store.activeLayer.sketches.push(sketch);
      lines = {};
      //resolve("Line system created successfully");
    }
  };

  const allLines = () => {
    const allLineMeshes = store.scene.meshes.filter(function (mesh) {
      return mesh.id === "lineSystem";
    });
    const curveArray = [];
    for (let i = 0; i < allLineMeshes.length; i++) {
      const oneCurve = allLineMeshes[i].getVerticesData(
        BABYLON.VertexBuffer.PositionKind
      );
      let oneCurveWorld = [];
      for (let j = 0; j < oneCurve.length; j += 3) {
        let vec = new BABYLON.Vector3(
          oneCurve[j],
          oneCurve[j + 1],
          oneCurve[j + 2]
        );
        vec = BABYLON.Vector3.TransformCoordinates(
          vec,
          allLineMeshes[i].getWorldMatrix()
        );
        oneCurveWorld.push(vec);
      }
      curveArray.push(oneCurveWorld);
    }
    return curveArray;
  };

  const allLinesOnNewSketch = () => {
    // const allLineMeshes = store.scene.meshes.filter(function (mesh) {
    //     return mesh.name === 'sketchLine';
    // });
    //
    let defStructure =
      StructureCollection.getInstance().getStructures()[
        store.activeLayer.structure_id
      ];
    let sketchOnActvLayer = defStructure
      .getStoreyData()
      .getStoreyByValue(store.activeLayer.storey)
      .layerData.getLayerByName(
        store.activeLayer.name,
        store.activeLayer.storey
      ).sketches;
    let allLineMeshes = [];
    for (let i = 0; i < sketchOnActvLayer.length; i++) {
      //allLineMeshes.push(sketchOnActvLayer[i].mesh);
      allLineMeshes.push(sketchOnActvLayer[i].points);
    }
    let curveArray = [];
    for (let i = 0; i < allLineMeshes.length; i++) {
      let eachLineMesh = allLineMeshes[i];
      let a = [];
      for (let j = 0; j < eachLineMesh.length; j++) {
        let eachPointPair = eachLineMesh[j];
        for (let k = 0; k < eachPointPair.length; k++) {
          a.push(eachPointPair[k]);
        }
      }
      curveArray.push(a);
    }
    return curveArray;
  };

  const findNearestPoint = (point, threshold) => {
    const allLine = allLinesOnNewSketch();
    let distance = 10000;
    let snapper = null;
    for (let i = 0; i < allLine.length; i++) {
      const oneLine = allLine[i];
      for (let j = 0; j < oneLine.length; j++) {
        const newDistance = BABYLON.Vector3.Distance(point, oneLine[j]);
        //console.log("DISTANCE", newDistance);
        if (newDistance < threshold) {
          if (newDistance < distance) {
            snapper = oneLine[j];
            distance = newDistance;
          }
        }
      }
    }
    return snapper;
  };

  let createSketch = function (data) {
    if(!data){
      data = this.data;
    }
    if (data.points.length) {
      // let lineSystem = BABYLON.MeshBuilder.CreateLineSystem("lineSystem", {
      //     lines: data.points,
      //     updatable: true
      // }, store.scene);

      //let lineSystem = BABYLON.Mesh.MergeMeshes(data.meshArray, true);

      let lineMesh = [];
      let sketchPoints = data.points;
      for (let i = 0; i < sketchPoints.length; i++) {
        let filledCircle = circle2D(
          data.thickness,
          numberOfEdgesOnCircle,
          store.scene,
          data.color
        );
        filledCircle.position = sketchPoints[i][0];
        lineMesh.push(filledCircle);
        if (sketchPoints[i].length > 1) {
          let line = line2D(
            "line",
            { path: sketchPoints[i], width: 1.987 * data.thickness },
            store.scene,
            data.color
          );
          lineMesh.push(line);
        }
      }
      let lineSystem = BABYLON.Mesh.MergeMeshes(lineMesh, true);
      //let lineSystem = data.mergedMeshArr;
      lineSystem.name = "sketchLine";
      // lineSystem.color = new BABYLON.Color3(0, 0, 0);
      lineSystem.isPickable = false;
      if (!data.sketchUniqueId) data.sketchUniqueId = lineSystem.uniqueId;
      else lineSystem.uniqueId = data.sketchUniqueId;

      if (!store.$scope.isTwoDimension) lineSystem.visibility = 0;

      let sketch = {};
      sketch.id = data.sketchUniqueId;
      sketch.points = sketchPoints;
      sketch.mesh = lineSystem;
      sketch.mesh.structure_id = data.structureId;
      sketch.color = data.color;
      sketch.thickness = data.thickness;

      // if(activeLayer.layerType.toLowerCase() == 'image' || store.activeLayer.layerType.toLowerCase() == 'cad'){
      //      // layerView.selectLayer('Wall', store.activeLayer.structure_id, store.activeLayer.storey);
      //      layerName = store.activeLayer.name;
      //      // showToast("Switched to Wall layer");
      // }else{
      //     layerName = data.layerName;
      // }
      // data.layerName = layerName;
      let structureCollection = StructureCollection.getInstance();
      let structure = structureCollection.getStructures()[data.structureId];
      let storey = structure.getStoreyData().getStoreyByValue(data.storey);
      let layerData = storey.layerData;
      let layer = layerData.getLayerByName(data.layerName, data.storey);
      layer.sketches.push(sketch);

      let levelLow = (parseInt(data.storey) - 1).toString();
      let levelHigh = (parseInt(levelLow) + 1).toString();
      let level;
      level = structure.getLevel(levelLow, levelHigh);
      if (!level) structure.addLevel(levelLow, levelHigh);
      let levelNum = levelLow + levelHigh;

      this.data.levelNum = levelNum;
      this.data.layerId = layer.id;
      this.data.storeyId = storey.id;
    } // CommandManager.saveCommandToLocalStorageCommandQueue(cmd);
  };

  let removeSketch = function (data) {
    if(!data){
      data = this.data;
    }
    if (data.sketchUniqueId) {
      let structureCollection = StructureCollection.getInstance();
      let sketches = structureCollection
        .getStructures()
        [data.structureId].getStoreyData()
        .getStoreyByValue(data.storey)
        .layerData.getLayerByName(data.layerName, data.storey).sketches;

      sketches[sketches.length - 1].mesh.dispose();
      sketches.length = sketches.length - 1;
      //scene.getMeshByUniqueID(data.sketchUniqueId).dispose();
    }
  };

  const executeFromSaveData = (saveData) => {
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    const data = {};
    data.structureId = identifier.structure_id;
    data.storey = parseInt(identifier.storey);
    data.layerId = identifier.layerId;

    data.points = _pointArrayToVectorArray(chosenData.points);
    data.sketchUniqueId = chosenData.id;
    data.layerName = chosenData.layerName;
    data.color = chosenData.color;
    data.thickness = chosenData.thickness;
    data.position = chosenData.position;
    data.scaling = chosenData.scaling;

    createSketch(data);

    const sketch = store.scene.getMeshByUniqueID(data.sketchUniqueId);
    sketch.position.x = chosenData.position[0];
    sketch.position.y = chosenData.position[1];
    sketch.position.z = chosenData.position[2];
    sketch.scaling.x = chosenData.position[0];
    sketch.scaling.y = chosenData.position[1];
    sketch.scaling.z = chosenData.position[2];
  };

  const unExecuteFromSaveData = (saveData) => {
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    const data = {};
    data.structureId = identifier.structure_id;
    data.storey = parseInt(identifier.storey);
    data.layerId = identifier.layerId;
    data.sketchUniqueId = chosenData.id;
    data.layerName = chosenData.layerName;

    removeSketch(data);
  };

  let getCommandLogic = function () {
    return {
      execute: createSketch,
      unexecute: removeSketch,
    };
  };

  let getSaveData = function () {
    function formatPoints(pointsArray) {
      let newPointsArr = [];
      pointsArray.forEach(function (points) {
        let newPoints = [];
        points.forEach(function (point) {
          newPoints.push(point.asArray());
        });
        newPointsArr.push(newPoints);
      });

      return newPointsArr;
    }

    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = this.id;
    saveData.data.saveType = "addSketch";

    let sketch = store.scene.getMeshByUniqueID(this.data.sketchUniqueId);
    saveData.data.identifier = {
      structure_id: this.data.structureId,
      storey: this.data.storey.toString(),
      layerId: this.data.layerId,
      floorkey: store.floorkey,
    };

    let formattedPoints = formatPoints(this.data.points);
    let dataAfter = {
      id: this.data.sketchUniqueId,
      points: formattedPoints,
      position: sketch.position.asArray(),
      scaling: sketch.scaling.asArray(),
      color: this.data.color,
      thickness: this.data.thickness,
      layerName: this.data.layerName,
    };

    saveData.data.afterOperationData = dataAfter;

    return saveData;
  };

  function setThickness(thickness) {
    radius = thickness;
  }

  return {
    getCommandLogic,
    createSketch,
    removeSketch,
    setThickness,
    executeFromSaveData,
    unExecuteFromSaveData,

    onPointerDown: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        if (evt.button !== 0) {
          return;
        }
        let pickInfo = null;
        if (evt.pickInfo) pickInfo = evt.pickInfo;
        else {
          let predicate = null;
          predicate = function (mesh) {
            return mesh.name.indexOf("layerRefPlane") !== -1;
          };
          pickInfo = store.newScene.pick(evt.clientX, evt.clientY, predicate);
        }
        if (pickInfo.hit) {
          //engine.resize();
          dist = pickInfo.distance;
          sketchMesh = pickInfo.pickedMesh;
          sketchStartingPoint = getMeshPosition(evt);
          //console.log("dist", dist);
          const threshold = dist / 150;
          if (store.activeLayer.layerType == "wall") {
            let snapper = findNearestPoint(sketchStartingPoint, threshold);
            if (snapper) {
              sketchStartingPoint = snapper;
            }
          }

          lineseed = [];
          lines = {};
          lines.points = [];
          lines.mesh = [];
          lineseed.push(sketchStartingPoint);
          if (sketchStartingPoint) {
            // we need to disconnect camera from canvas
            // store.scene.activeCamera.detachControl(canvas);
          }
        }
      }
    },

    onPointerUp: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        if (sketchStartingPoint) {
          let _executeEvent = function () {
            let data = {
              points: lines.points,
              color: ScopeUtils.getSketchColor(),
              thickness: radius,
              structureId: store.activeLayer.structure_id,
              storey: store.activeLayer.storey,
              layerName: store.activeLayer.name,
              layerId: store.activeLayer.id,
            };

            let cmd = new Command(
              sketchCommandName,
              data,
              getCommandLogic(),
              getSaveData
            );
            CommandManager.execute(cmd, true);
          };

          // store.scene.activeCamera.attachControl(canvas, true);
          sketchStartingPoint = null;

          let lastLine = lines.points.pop();
          let lastPoint = lastLine[1];
          //console.log("dist", dist);
          const threshold = dist / 150;
          if (store.activeLayer.layerType == "wall") {
            let snapper = findNearestPoint(lastPoint, threshold);
            if (snapper) {
              lastPoint = snapper;
            }
          }

          lastLine = [lastLine[0], lastPoint];
          lines.points.push(lastLine);

          _executeEvent();

          lines.mesh.forEach(function (mesh) {
            mesh.dispose();
          });
          lines = {};

          //lineSystemCreation();
          //updateModifications();
        }
      }
    },

    onPointerMove: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        if (!sketchStartingPoint) {
          return;
        }
        let current = getMeshPosition(evt);
        if (!current) {
          return;
        }
        let myPoints = [sketchStartingPoint, current];
        // let curve;
        // curve = BABYLON.Curve3.CreateCatmullRomSpline(myPoints, 3);
        // if (isiPad){
        //     curve = BABYLON.Curve3.CreateCatmullRomSpline(myPoints, 1);
        // }
        // let cpts = curve.getPoints();
        // let line = BABYLON.MeshBuilder.CreateLines("sketchLines", {points: cpts}, store.scene);
        // line.color = new BABYLON.Color3(0, 0, 0);
        // lines.points.push(myPoints);
        // lines.mesh.push(line);

        // var line = line2D("line", {path: myPoints, width: 0.35}, store.scene);
        // lines.points.push(myPoints);
        // lines.mesh.push(line);
        //previousPoint = sketchStartingPoint;
        let filledCircle = circle2D(
          radius,
          numberOfEdgesOnCircle,
          store.scene,
          ScopeUtils.getSketchColor()
        );
        filledCircle.position = sketchStartingPoint;

        //let lp = [previousPoint, current];
        let line = line2D(
          "line",
          { path: myPoints, width: 1.987 * radius },
          store.scene,
          ScopeUtils.getSketchColor()
        );

        /*This was done as an experiment to see how a line of circles work. It's too heavy.
                let distance = distanceBetweenPoints3DVec(sketchStartingPoint, current);
                //console.log( "sketchStart", sketchStartingPoint );
                //console.log( "current", current );
                let edge = [sketchStartingPoint, current];
                let numberOfCircles = 2 * Math.round(distance / radius );
                let i = 1;
                while ( i <= numberOfCircles ){
                    let m1 = i;
                    let m2 = numberOfCircles - i;
                    let nextCircle = circle2D(radius, numberOfEdgesOnCircle, store.scene);
                    nextCircle.position = sketchStartingPoint.clone();
                    nextCircle.position.x = ( 1 / numberOfCircles ) * ( m1 * sketchStartingPoint.x + m2 * current.x );
                    nextCircle.position.z = ( 1 / numberOfCircles ) * ( m1 * sketchStartingPoint.z + m2 * current.z );
                    //lines.points.push(nextCircle.position);
                    //lines.mesh.push(nextCircle);
                    //console.log( "nextCirclePos", [nextCircle.position.x, nextCircle.position.y, nextCircle.position.z] );
                    i = i + 1;
                 }
                */
        sketchStartingPoint = current;
        lines.points.push(myPoints);
        lines.mesh.push(filledCircle);
        lines.mesh.push(line);
      }
    },
  };
})();
export { line2D, circle2D, sketchOperation };
