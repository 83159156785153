const wall_thickness = 13;
const intersectArr = [];
var points;

function onSegment(a, b, c) {
  if (
    b[0] <= Math.max(a[0], c[0]) &&
    b[0] >= Math.min(a[0], c[0]) &&
    b[1] <= Math.max(a[1], c[1]) &&
    b[1] >= Math.min(a[1], c[1])
  )
    return 1;
  return 0;
}

function calculateEuclidianDistance(p1, p2) {
  return (p2[1] - p1[1]) * (p2[1] - p1[1]) + (p2[0] - p1[0]) * (p2[0] - p1[0]);
}

function getOrientation(a, b, c) {
  let val = (b[1] - a[1]) * (c[0] - b[0]) - (b[0] - a[0]) * (c[1] - b[1]);
  if (val === 0) return 0;

  return val > 0 ? 1 : 2;
}

function line_intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  var ua,
    ub,
    denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  console.log(Math.abs((y4 - y3) / (x4 - x3) - (y2 - y1) / (x2 - x1)));
  if (denom == 0) {
    return null;
  }
  ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
  ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
  return {
    x: x1 + ua * (x2 - x1),
    y: y1 + ua * (y2 - y1),
    seg1: ua >= 0 && ua <= 1,
    seg2: ub >= 0 && ub <= 1,
  };
}

function scaleLine(point1, point2, wall_thickness) {
  var line =
    calculateEuclidianDistance([0, 0], point1) <
    calculateEuclidianDistance([0, 0], point2)
      ? {
          start: JSON.parse(JSON.stringify(point1)),
          end: JSON.parse(JSON.stringify(point2)),
        }
      : {
          start: JSON.parse(JSON.stringify(point2)),
          end: JSON.parse(JSON.stringify(point1)),
        };
  let slope = 0;
  let length = Math.sqrt(calculateEuclidianDistance(line.start, line.end));
  if (line.end[0] - line.start[0] === 0) {
    slope = 0;
  } else slope = (line.end[1] - line.start[1]) / (line.end[0] - line.start[0]);

  line.start[0] += ((line.start[0] - line.end[0]) / length) * wall_thickness;
  line.start[1] += ((line.start[1] - line.end[1]) / length) * wall_thickness;
  line.end[0] += ((line.end[0] - line.start[0]) / length) * wall_thickness;
  line.end[1] += ((line.end[1] - line.start[1]) / length) * wall_thickness;

  return line;
}

function roundArr(p) {
  var point = JSON.parse(JSON.stringify(p));
  point[0] = Math.round(point[0] * 100) / 100;
  point[1] = Math.round(point[1] * 100) / 100;
  return point;
}

function lineInterect(point1, point2, point3, point4) {
  let p1 = roundArr(point1);
  let p2 = roundArr(point2);
  let p3 = roundArr(point3);
  let p4 = roundArr(point4);

  let orient1 = getOrientation(p1, p2, p3);
  let orient2 = getOrientation(p1, p2, p4);
  let orient3 = getOrientation(p3, p4, p1);
  let orient4 = getOrientation(p3, p4, p2);

  if (orient1 !== orient2 && orient3 !== orient4) {
    return 1;
  }

  if (orient1 === 0 && onSegment(p1, p3, p2)) return 1;
  if (orient2 === 0 && onSegment(p1, p4, p2)) return 1;
  if (orient3 === 0 && onSegment(p3, p1, p4)) return 1;
  if (orient4 === 0 && onSegment(p3, p2, p4)) return 1;

  return 0;
}

function removeDuplicates(a) {
  var prims = { boolean: {}, number: {}, string: {} },
    objs = [];

  return a.filter(function (item) {
    var type = typeof item;
    if (type in prims)
      return prims[type].hasOwnProperty(item)
        ? false
        : (prims[type][item] = true);
    else {
      item = JSON.stringify(item);
      return objs.indexOf(item) >= 0 ? false : objs.push(item);
    }
  });
}

function makePathByGrahamScanAlgo(wall_arr) {
  let y_min = { index: 0, value: 9999 };

  for (var i = wall_arr.length - 1; i >= 0; i--) {
    wall_arr[i].push(i);
  }

  for (let i = 0; i < wall_arr.length; i++) {
    if (
      y_min.value > wall_arr[i][1] ||
      (y_min.value === wall_arr[i][1] &&
        wall_arr[y_min.index][0] > wall_arr[i][0])
    ) {
      y_min.value = wall_arr[i][1];
      y_min.index = i;
    }
  }
  let temp = wall_arr[0];
  wall_arr[0] = wall_arr[y_min.index];
  wall_arr[y_min.index] = temp;

  let point1 = wall_arr.shift();

  wall_arr.sort(function (p, q) {
    let orient = getOrientation(point1, p, q);
    if (orient === 0)
      return calculateEuclidianDistance(point1, q) >=
        calculateEuclidianDistance(point1, p)
        ? -1
        : 1;

    return orient === 2 ? -1 : 1;
  });

  wall_arr.unshift(point1);
  let ctr = 0;
  for (let k of wall_arr) {
    console.log(k[0] + "," + k[1] + "," + ctr++);
  }
  let m = 1; // Initialize size of modified array
  for (let i = 1; i < wall_arr.length; i++) {
    while (
      i < wall_arr.length - 1 &&
      getOrientation(point1, wall_arr[i], wall_arr[i + 1]) === 0
    )
      i++;
    wall_arr[m] = wall_arr[i];
    m++; // Update size of modified array
  }

  if (m < 3) return;

  let Stack = [];
  Stack.push(wall_arr[0]);
  Stack.push(wall_arr[1]);
  Stack.push(wall_arr[2]);

  for (let i = 3; i < m; i++) {
    while (
      getOrientation(
        Stack[Stack.length - 2],
        Stack[Stack.length - 1],
        wall_arr[i]
      ) !== 2
    )
      Stack.splice(Stack.length - 1, 1);
    Stack.push(wall_arr[i]);
  }

  // wall_arr = wall_arr.filter(function(x,i){
  // 	return !tempIndex.includes(i);
  // });

  // console.log(Stack.reverse());

  // if(wall_arr[0].length !== 4) throw "Invalid Array for wall path";
  return Stack.reverse();
}

//[ 4, 5, 8, 9, 3, 2, 7, 13, 10, 11, 12

function findIntersect (obp = 0, start, end, en = 2) {
  let ob = intersectArr[obp];
  let f =
    ob.firsti.includes(start) &&
    ob.firsti.includes((start + 1) % points.length);
  let s =
    ob.secondi.includes(end) && ob.secondi.includes((end - 1) % points.length);

  let g =
    ob.secondi.includes(start) &&
    ob.secondi.includes((start + 1) % points.length);
  let d =
    ob.firsti.includes(end) && ob.firsti.includes((end - 1) % points.length);
  let r;

  if (!(f && s) && !(g && d) && en > 0) {
    if (en % 2 === 0)
      r = this.findIntersect(obp, (start - 1) % points.length, end, 1);
    else {
      r = this.findIntersect(
        obp,
        (start + 1) % points.length,
        (end + 1) % points.length,
        0
      );
    }
  } else {
    if (f && s) {
      return { min: Math.min(...ob.secondi), point: ob.intesectPoint };
    } else if (g && d) {
      return { min: Math.min(...ob.firsti), point: ob.intesectPoint };
    } else {
      r = this.findIntersect(++obp, start, (end - 1) % points.length);
    }
  }

  return r;
};

function putonWallPartition(partitionObj, index, point) {
  if (partitionObj.hasOwnProperty(index)) {
    partitionObj[index].push(point);
  } else {
    partitionObj[index] = [];
    partitionObj[index].push(point);
  }
}

onmessage = function (e) {
  return;
  points = e.data[0] || {};
  let json = JSON.parse(JSON.stringify(points));
  let len = points.length;

  for (let i = 0; i < len; i++) {
    for (let j = 0; j < len; j++) {
      if (
        !lineInterect(
          points[i],
          points[(i + 1) % len],
          points[j],
          points[(j + 1) % len]
        )
      ) {
        var newLine = JSON.parse(
          JSON.stringify(
            scaleLine(points[i], points[(i + 1) % len], wall_thickness)
          )
        );
        if (
          lineInterect(
            newLine.start,
            newLine.end,
            points[j],
            points[(j + 1) % len]
          )
        ) {
          var obj = {};
          obj.first = [points[i], points[(i + 1) % len]];
          obj.second = [points[j], points[(j + 1) % len]];
          obj.firsti = [i, (i + 1) % len];
          obj.secondi = [j, (j + 1) % len];
          obj.intesectPoint = line_intersect(
            points[i][0],
            points[i][1],
            points[(i + 1) % len][0],
            points[(i + 1) % len][1],
            points[j][0],
            points[j][1],
            points[(j + 1) % len][0],
            points[(j + 1) % len][1]
          );
          intersectArr.push(obj);
        }
      }
    }
  }

  let a = [];
  for (let ls of intersectArr) {
    Array.prototype.push.apply(a, ls.firsti);
    Array.prototype.push.apply(a, ls.secondi);
    console.log(ls);
  }
  a = a.sort(function (a, b) {
    return a - b;
  });

  console.log(a);

  let pts = makePathByGrahamScanAlgo(json);
  let ctr = 0;

  pts = pts.sort(function (a, b) {
    return a[2] - b[2];
  });

  a = removeDuplicates(a);
  let mko = [];

  for (let k of pts) {
    console.log(k[0] + "," + k[1] + "," + k[2] + "," + ctr++);
    mko.push(k[2]);
  }

  let nm = 0;
  let partition = { 0: [a[0]] };
  let ctn = 0;
  console.log("***********");
  console.log(mko);
  console.log("***********");
  console.log("***********");
  console.log(a);
  console.log("***********");

  for (let i = a[1]; i < points.length; i++) {
    if (i > Math.max(...a)) break;
    if (mko.includes(i)) {
      partition[ctn].push(i);
      partition[++ctn] = [];
    } else {
      partition[ctn].push(i);
    }
    // a.splice(i,1);
  }
  var partitionWallObj = {};

  for (let onj in partition) {
    let arr = partition[onj];
    if (partition[onj].length > 3) {
      try {
        let k = this.findIntersect(0, arr[0], arr[arr.length - 1]);
        let t = 2;
        let poi = k.point;
        if (!poi) {
          t = 1;
          putonWallPartition(partitionWallObj, onj, points[arr[0]]);
        } else {
          putonWallPartition(partitionWallObj, onj, points[arr[1]]);
          points[arr[1]] = [poi.x, poi.y];
          putonWallPartition(partitionWallObj, onj, [poi.x, poi.y]);
        }
        while (arr[t] <= k.min) {
          putonWallPartition(partitionWallObj, onj, points[arr[t]]);
          points[arr[t++]] = null;
        }
        if (partitionWallObj[onj].length === 3) {
          partitionWallObj[onj].push(points[arr[0]]);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  points = points.filter(function (a) {
    return a !== null;
  });

  postMessage({ points: points, paritionWalls: partitionWallObj });
};
export { findIntersect, wall_thickness,intersectArr,points,onSegment,calculateEuclidianDistance,getOrientation,line_intersect,scaleLine,roundArr,lineInterect,removeDuplicates,makePathByGrahamScanAlgo,putonWallPartition };
