"use strict"
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { Staircase } from "../snaptrudeDS/staircase.ds.js";
import {
  showVertexIndicator,
  disposeSnapToObjects,
} from "./moveOperations/moveUtil.js";
import { uiIndicatorsHandler } from "../uiIndicatorOperations/uiIndicatorsHandler.js";
import { findSecondarySnappedPoint } from "../../libs/snapFuncsPrimary.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { cursor } from "../../../themes/cursor.js";
import { updateCursor } from "../../../containers/editor/cursorHandler.js";


// import { Mesh } from "../../mda";

/*jshint esversion: 6 */

var staircaseOperation = (function () {
  "use strict";
  let myStair = null;
  // let staircaseType = "straight"
  let staircaseType = null;

  let staircasePlacementIndicator = null;

  let placeStair = false;
  let pickedMesh = null;
  let structure = null;

  let cachedStaircaseMeshes = {};

  function moveStairsToPointer(myStair, point) {
    myStair.positionStair(point);
    myStair.mesh.computeWorldMatrix(true);
  }

  return {
    initialize: function (recievedStaircaseType, evt) {
      // staircaseOperation.reset();
      staircaseType = recievedStaircaseType;
      let mesh = new BABYLON.Mesh();
      myStair = new Staircase(mesh, staircaseType, 11.811023622047244);
      myStair.createStaircase();
      staircasePlacementIndicator = showVertexIndicator(
        BABYLON.Vector3.Zero(),
        myStair.mesh,
        {
          indicator: uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator,
        }
      );
      // staircasePlacementIndicator.parent = myStair.mesh
      // staircasePlacementIndicator.visibility = 0
      myStair.mesh.visibility = 0;
      for (let i = 0; i < myStair.meshes.length; i++) {
        myStair.meshes[i].visibility = 0;
      }
      placeStair = false;
      pickedMesh = null;
      structure = null;
      // let myStairMesh = myStair.mesh;
    },

    reset: function (evt) {
      if (myStair != null) {
        myStair.deleteMeshes(true, false);
      }
      myStair = null;
      staircaseType = null;

      staircasePlacementIndicator = null;

      placeStair = false;
      pickedMesh = null;
      structure = null;
    },

    onPointerMove: function (evt) {
      placeStair = false;
      pickedMesh = null;
      structure = null;
      // let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
      // 	if (mesh.type) {
      // 		return mesh.isVisible && ["floor", "ground", "mass", "wall", "roof"].includes(mesh.type.toLowerCase())
      // 	}
      // });
      let pickInfo = store.newScene.sequentialPick([function (mesh) {
        return mesh.isVisible && ["floor", "mass", "wall", "roof"].includes(mesh.type.toLowerCase());
        }, function (mesh) {
          return ["ground"].includes(mesh.type.toLowerCase());
      }], {doAllOfThem: false, doSimplePick: true});

    /*

    getting some error here

    let pickInfo = scenePickController.sequentialPick([
        {
            predicate : function (mesh) {
                return ["floor", "mass", "wall", "roof"].includes(mesh.type.toLowerCase());
            }
        },
        {
            predicate : function (mesh) {
                return ["ground"].includes(mesh.type.toLowerCase());
            },
            pickInvisibleMeshes : true
        }
    ], {doAllOfThem: false, doSimplePick: true});

     */
      if (pickInfo.hit) {
        updateCursor(cursor.placeObject);
        pickedMesh = pickInfo.pickedMesh;
        let pickedMeshType = pickedMesh.type.toLowerCase(),
          point;
        if (
          myStair &&
          ["floor", "ground", "mass", "wall", "roof"].includes(pickedMeshType)
        ) {
          let structureId, pickedObject, storey, storeyHeight;
          myStair.mesh.visibility = 1;
          // staircasePlacementIndicator.visibility = 1
          for (let i = 0; i < myStair.meshes.length; i++) {
            myStair.meshes[i].isVisible = 1;
            myStair.meshes[i].visibility = 1;
          }
          let options = {
            excludedMeshes: myStair.meshes.slice(1),
            faceSnap: false,
            restrictYAxisMovement: true,
            doNotDoSecondaryScenePicks: true,
            // pickInfo: modifiedPickInfo
          };
          disposeSnapToObjects();
          let snapPoint = findSecondarySnappedPoint(
            myStair.mesh,
            null,
            null,
            options
          );
          if (!snapPoint) {
            snapPoint = pickInfo.pickedPoint;
          }

          if (pickedMeshType == "ground") {
            structureId = store.activeLayer.structure_id;
            structure =
              StructureCollection.getInstance().getStructures()[structureId];
            if (store.$scope.isTwoDimension) {
              storey = structure
                .getStoreyData()
                .getStoreyByValue(store.activeLayer.storey);
            } else {
              storey = structure.getStoreyData().getStoreyByValue(1);
            }
            storeyHeight = storey.height;
            snapPoint.y = storey.base;
          } else {
            pickedObject = pickedMesh.getSnaptrudeDS();
            structureId = pickedObject.structure_id;
            structure =
              StructureCollection.getInstance().getStructures()[structureId];
            storey = structure
              .getStoreyData()
              .getStoreyByValue(pickedMesh.storey);
            storeyHeight = storey.height;
            snapPoint.y = storey.base;
          }

          // switch (pickedMeshType) {
          // 	case "ground": {

          // 		break;
          // 	}
          // 	case "floor": {

          // 	}
          // 	case "mass": {
          // 		pickedObject = pickedMesh.getSnaptrudeDS()
          // 		structureId = pickedObject.structure_id;
          // 		structure = StructureCollection.getInstance().getStructures()[structureId];
          // 		storey = structure.getStoreyData().getStoreyByValue(pickedMesh.storey)
          // 		storeyHeight = storey.height
          // 		storeyBase = storey.base
          // 		snapPoint.y = storeyBase
          // 		break;
          // 	}
          // 	case "wall": {
          // 		pickedObject = pickedMesh.getSnaptrudeDS()
          // 		structureId = pickedObject.structure_id;
          // 		structure = StructureCollection.getInstance().getStructures()[structureId];
          // 		storey = structure.getStoreyData().getStoreyByValue(pickedMesh.storey)
          // 		storeyHeight = storey.height
          // 		storeyBase = storey.base
          // 		snapPoint.y = storeyBase
          // 		break;
          // 	}

          // }

          // Update staircase height to make it equal to storey height
          if (storeyHeight != myStair.getHeight()) {
            myStair.updateHeight(storeyHeight);
            myStair.createStaircase();
            for (let i = 0; i < myStair.meshes.length; i++) {
              myStair.meshes[i].visibility = 1;
            }
          }

          moveStairsToPointer(myStair, snapPoint);
          uiIndicatorsHandler.vertexIndicator.moveTo(snapPoint);
          placeStair = true;
        }
      }else{
        updateCursor(cursor.cantPlace);
      }
    },

    onPointerDown: function (evt) {
      if (store.isiPad) {
        // store.newScene.activeCamera.detachControl(canvas);
      }

      if (placeStair && evt.which !== 2) {
        let level = null,
          pickedObject = null,
          levelId = null,
          storeyNo = 1;
        if (pickedMesh.type.toLowerCase() === "ground") {
          level = structure.getLevelByName("01");
          if (store.$scope.isTwoDimension) {
            storeyNo = store.activeLayer.storey;
          }
          myStair.storey = storeyNo;
          myStair.mesh.storey = storeyNo;
        } else {
          pickedObject = pickedMesh.getSnaptrudeDS();
          levelId = pickedObject.level_id;
          level = structure.getLevelByUniqueId(levelId);
          myStair.storey = pickedObject.storey;
          myStair.mesh.storey = myStair.storey;
        }
        level.addStaircaseToLevel(myStair, false);
        // staircaseUndoRedo.commandManager_createStaircase(myStair)
        // staircasePlacementIndicator.dispose()
        uiIndicatorsHandler.vertexIndicator.remove();

        // Now that the staircase has been added to the structure collection at the given point, we can make a new Staircase for future use

        let addedStair = myStair;
        addedStair.isNewStair = true;
        addedStair.doUnion();
        // }
        let mesh = new BABYLON.Mesh();
        myStair = new Staircase(
          mesh,
          staircaseType,
          addedStair.staircaseHeight
        );
        myStair.createStaircase();
        for (let i = 0; i < myStair.meshes.length; i++) {
          myStair.meshes[i].visibility = 1;
        }
        staircasePlacementIndicator = showVertexIndicator(
          BABYLON.Vector3.Zero(),
          myStair.mesh,
          {
            indicator:
              uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator,
          }
        );
        // staircasePlacementIndicator.parent = myStair.mesh
        moveStairsToPointer(myStair, addedStair.position);
        uiIndicatorsHandler.vertexIndicator.moveTo(addedStair.position);
      }
    },

    onPointerUp: function (evt) {},
  };
})();

var staircaseUndoRedo = (function () {
  // let execute_createStaircase = function () {
  // 	let data = this.data
  // 	let mesh = new BABYLON.Mesh()
  // 	let stair = new Staircase(mesh, data.staircaseType, data.staircaseHeight)
  // 	stair.updateTread(data.tread)
  // 	stair.updateDepth(data.depth)
  // 	stair.updateRiser(data.riser)
  // 	stair.updateWidth(data.width)
  // 	stair.createStaircase()

  // 	stair.mesh.uniqueId = data.uniqueId
  // 	stair.positionStair(BABYLON.Vector3.FromArray(data.position))
  // 	stair.rotateStair(BABYLON.Vector3.FromArray(data.rotation))
  // 	stair.doUnion()

  // 	let structure = StructureCollection.getInstance().getStructures()[data.structure_id];
  // 	let level = structure.getLevelByName(data.level);
  // 	level.addStaircaseToLevel(stair, false);
  // }

  // let unexecute_createStaircase = function () {
  // 	let data = this.data
  // 	let mesh = scene.getMeshByUniqueID(data.uniqueId)
  // 	let obj = mesh.getSnaptrudeDS()
  // 	let structure = StructureCollection.getInstance().getStructures()[data.structure_id];
  // 	let level = structure.getLevelByName(data.level);
  // 	removeSelectionBox(mesh)
  // 	DisplayOperation.removeDimensions(mesh)
  // 	obj.deleteMeshes()
  // 	level.removeObjectToLevel(obj)
  // }

  // let execute_deleteStaircase = function(){
  // 	let data = this.data
  // }

  // let unexecute_deleteStaircase = function(){
  // 	let data = this.data
  // }

  // let execute_changeObjectProperty = function () {
  // 	let data = this.data
  // 	let mesh = scene.getMeshByUniqueID(data.uniqueId)
  // 	let obj = mesh.getSnaptrudeDS()
  // 	obj.objectPropertiesChanged(data.property, data.newValue, true)

  // }

  // let unexecute_changeObjectProperty = function () {
  // 	let data = this.data
  // 	let mesh = scene.getMeshByUniqueID(data.uniqueId)
  // 	let obj = mesh.getSnaptrudeDS()
  // 	obj.objectPropertiesChanged(data.property, data.oldValue, true)
  // }

  return {
    // commandManager_changeObjectProperty: function (staircaseObject, propertyChanged, oldPropertyValue, newPropertyValue) {
    // 	let data = {
    // 		uniqueId: staircaseObject.mesh.uniqueId,
    // 		// structure_id: staircaseObject.structure_id,
    // 		// level: staircaseObject.level_low + staircaseObject.level_high,
    // 		property: propertyChanged,
    // 		oldValue: oldPropertyValue,
    // 		newValue: newPropertyValue
    // 		// storey: staircaseObject.storey,
    // 	}
    // 	let cmd = new Command("changeStaircaseObjectProperty", data, {
    // 		execute: execute_changeObjectProperty,
    // 		unexecute: unexecute_changeObjectProperty
    // 	});
    // 	return cmd
    // },

    commandManager_changeObjectProperty: function (
      staircaseObject,
      propertyChanged,
      oldCommandData
    ) {
      let optionsProperty = {},
        propertyChangeCommandData,
        geometryChangeCommandData,
        optionsGeometry;
      if (propertyChanged == "steps" || propertyChanged == "riser") {
        optionsProperty.componentKeys = ["riser", "steps", "stepsArrangement"];
      } else if (propertyChanged == "staircaseHeight") {
        optionsProperty.componentKeys = [
          "staircaseHeight",
          "riser",
          "steps",
          "stepsArrangement",
        ];
      } else {
        optionsProperty.componentKeys = [propertyChanged];
      }
      optionsProperty.componentKeys.push(
        ...["stairLength", "stairWidth", "stairArea", "stairVolume"]
      );

      let allMeshes = [staircaseObject.mesh];
      staircaseObject.mesh.instances.forEach((instanceMesh) => {
        allMeshes.push(instanceMesh);
      });

      if (!oldCommandData) {
        //Before Change
        propertyChangeCommandData =
          commandUtils.propertyChangeOperations.getCommandData(
            allMeshes,
            optionsProperty
          );
        geometryChangeCommandData =
          commandUtils.geometryChangeOperations.getCommandData(
            staircaseObject.mesh
          );

        return {
          propertyChangeCommandData: propertyChangeCommandData,
          geometryChangeCommandData: geometryChangeCommandData,
        };
      } else {
        //After Change

        staircaseObject.mesh.instances.forEach((instanceMesh) => {
          optionsProperty.componentKeys.forEach((propName) => {
            instanceMesh.getSnaptrudeDS()[propName] = staircaseObject[propName];
          });
        });
        optionsProperty.data = oldCommandData.propertyChangeCommandData;
        optionsGeometry = oldCommandData.geometryChangeCommandData;
        propertyChangeCommandData =
          commandUtils.propertyChangeOperations.getCommandData(
            allMeshes,
            optionsProperty
          );
        geometryChangeCommandData =
          commandUtils.geometryChangeOperations.getCommandData(
            staircaseObject.mesh,
            optionsGeometry
          );

        let propertyChangeCommand =
          commandUtils.propertyChangeOperations.getCommand(
            "staircasePropertyChange",
            propertyChangeCommandData
          );
        let geometryChangeCommand =
          commandUtils.geometryChangeOperations.getCommand(
            "staircaseGeometryChange",
            geometryChangeCommandData
          );

        return [propertyChangeCommand, geometryChangeCommand];
      }
    },

    // commandManager_createStaircase: function (staircaseObject) {
    // 	let data = {
    // 		uniqueId: staircaseObject.mesh.uniqueId,
    // 		structure_id: staircaseObject.structure_id,
    // 		level: staircaseObject.level_low + staircaseObject.level_high,
    // 		// storey: staircaseObject.storey,
    // 		staircaseHeight: staircaseObject.staircaseHeight,
    // 		staircaseType: staircaseObject.staircaseType,
    // 		tread: staircaseObject.tread,
    // 		depth: staircaseObject.depth,
    // 		riser: staircaseObject.riser,
    // 		width: staircaseObject.width,
    // 		position: staircaseObject.mesh.position.asArray(),
    // 		rotation: staircaseObject.mesh.rotation.asArray()
    // 	}
    // 	let cmd = new Command("createStaircase", data, {
    // 		execute: execute_createStaircase,
    // 		unexecute: unexecute_createStaircase
    // 	});
    // 	CommandManager.execute(cmd, false);
    // },

    commandManager_createStaircase: function (staircaseObject) {
      let commandData = commandUtils.creationOperations.getCommandData(
        staircaseObject.mesh
      );
      let cmd = commandUtils.creationOperations.getCommand(
        "createStaircase",
        commandData
      );

      CommandManager.execute(cmd, false);
    },

    // commandManager_deleteStaircase: function (staircaseObject) {
    // 	let data = {
    // 		uniqueId: staircaseObject.mesh.uniqueId,
    // 		structure_id: staircaseObject.structure_id,
    // 		level: staircaseObject.level_low + staircaseObject.level_high,
    // 		// storey: staircaseObject.storey,
    // 		staircaseHeight: staircaseObject.staircaseHeight,
    // 		staircaseType: staircaseObject.staircaseType,
    // 		tread: staircaseObject.tread,
    // 		depth: staircaseObject.depth,
    // 		riser: staircaseObject.riser,
    // 		width: staircaseObject.width,
    // 		position: staircaseObject.mesh.position.asArray(),
    // 		rotation: staircaseObject.mesh.rotation.asArray()
    // 	}
    // 	let cmd = new Command("deleteStaircase", data, {
    // 		execute: unexecute_createStaircase,
    // 		unexecute: execute_createStaircase
    // 	});
    // 	return cmd
    // 	// CommandManager.execute(cmd, false);
    // }
  };
})();
export { staircaseOperation, staircaseUndoRedo };
