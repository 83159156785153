const GLOBAL_CONSTANTS = (function () {
  const snaps = {
    x: "x",
    y: "y",
    z: "z",
    normal: "normal",
    vertex: "vertex",
    edge: "edge",
    face: "face",
    orthogonal: "orthogonal",
    parallel: "parallel",
  };

  const identifiers = {
    throwAwayMesh: "throwAway",
    visualElement: "visualElement",
    utilityElement: "utilityElement",
    typeUnassigned: "typeUnassigned",
    cadSketch: "cadSketch",
    boundingBox: "boundingBox",
    uploadedSketch: "twoPlane",
    doorWindowIndicator: "doorWindowIndicator",
    doorIndicatorin2d: "linesystem",
    cursor3D: "cursor3d",
    floorPlan: "FloorPlan",
    pdf: "pdf",
  };

  const events = Object.freeze({
    cadUploadProgress: "cadUploadProgress",
    imageUploadProgress: "imageUploadProgress",
    modelUploadProgress: "modelUploadProgress",
    pdfUploadProgress: "pdfUploadProgress",
  });

  const strings = {
    snaps,
    identifiers,
    events,
  };

  const positions = {
    throwAwayMesh: 10000,
    site: -299.5,
    deck: -75,
    plinth: -150,
    road: -250,
    ground: -287.5,
  };

  const numbers = {
    positions,
  };

  const cadRequestIdsToCancel = [];

  return {
    strings,
    numbers,
    cadRequestIdsToCancel
  };
})();
export { GLOBAL_CONSTANTS };
