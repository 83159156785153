export const card = {
  contentContainerStyles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropDownParentStyles: { position: "absolute", right: "1rem" },
  customParentDivStyles: {
    // border: "2px solid",
  },
  customDropdownStyles: { 
    width: "7rem",
  },
};
