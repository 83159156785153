import React, { useRef, useState } from "react";
import MenuPopUp from "../../../../components/MenuPopUp/index";
import styled from "styled-components";
import Input from "../../../../components/Input/index";
import Toggle from "../../../../components/Toggle/index";
import MapComponent from "../../../../components/Map/index";
import ScrollableDropdown from "../../../../components/ScrollableDropdown/index";
import { colors } from "../../../../themes/constant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  mapToUnits,
  updateProjectProperties, updateProperty,
} from "../../../../snaptrude/stateManagers/reducers/objectProperties/projectPropertiesSlice";
import { projectPropertiesDictionary } from "./projectPropertiesDictionary";
import {updateHeightUnits} from "../../../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice";


const MainWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 1rem 0.625rem;
  input:checked + .slider {
    background-color: ${(props) => props.theme.colors.fullWhite};
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 0.78rem;
    width: 0.78rem;
    left: 0.0625em;
    bottom: 0.0625em;
    background-color: ${(props) => props.theme.colors.brightRed};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: -0.1875rem;
    margin-left: -0.1rem;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.fullWhite};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid ${colors.dullgrey};
  }
  .label {
    height: 0.8rem;
    width: 1.32rem;
  }
  .spaceBetween {
    justify-content: space-between;
    padding: 0.125rem 0;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
`;

const EachRow = styled.div`
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Label = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
  width: 5.75rem;
  min-width: 3.0625rem;
`;

const TextArea = styled.textarea`
  height: 2.75rem;
  width: 5.5rem;
  border: none;
  background-color: ${colors.white};
  margin: 0.5625rem 0;
  border-radius: 5px;
  border-color: ${colors.white};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ColoredSquare = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.color}
`;

const ConstructionSectionHeader = styled.div`
  color: #818181;
  font-size: 12px;
  font-weight: bold;
  margin-top: 1rem;
`;

const ToggleKey = (props) => {
  const { toggleValue, toggleValueReference } = props;
  // const [isActive, setIsActive] = useState(toggleValue);
  const dispatch = useDispatch();

  const onToggle = (toggleValueReference) => {
    // console.log("toggling " + toggleValueReference);
    dispatch(updateProperty({
        property: toggleValueReference,
        value: !toggleValue
      })
    )
    dispatch(updateProjectProperties({key: toggleValueReference, value: !toggleValue}));
    // setIsActive((prevstate) => !prevstate);
  };

  return <Toggle state={toggleValue} handleClick={() => {onToggle(toggleValueReference)}} />;
};

const SettingsPopUp = (props) => {
  const projectPropertiesData = useSelector(state => state.projectProperties);
  // const [projectPropertiesData, setProjectPropertiesData] = useState([]);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [currentInputPropertyHandle, setCurrentInputPropertyHandle] = useState(null);
  const ref = useRef(null);
  const { closePopUp } = props;

  const closeOnOutsideClick = (evt) => {
    if (currentInputPropertyHandle) {
      handleInputValueSubmit(currentInputPropertyHandle);
      setCurrentInputPropertyHandle(null);
    }
    const toggleButton = document.getElementById("project-settings-button");
    if(toggleButton && toggleButton.contains(evt.target)) return;
    if(!ref.current.contains(evt.target)) {
      closePopUp();
    }
  }

  const leftMenuItems = [
    { title: "Units" },
    { title: "Construction" },
  ]
  const dispatch = useDispatch();

  const handleInputValueChange = (index, value) => {
    // projectPropertiesData[index].value = value;
    dispatch(updateProperty(
      {
        property: index,
        value: value
      })
    )
    // const newProjectPropertiesData = projectPropertiesData.slice();
    // setProjectPropertiesData(newProjectPropertiesData);
  }

  const handleInputValueSubmit = (index, e) => {
    if(e) e.preventDefault();
    dispatch(updateProjectProperties({
      key: projectPropertiesData[index].valueReference, 
      // value: projectPropertiesData[index].value
    }));

    if (index === "length" || index === "tolerance") {
      dispatch(mapToUnits());
      dispatch(updateHeightUnits());
    }
  }


  const handleInputValueChangeAndSubmit = (index, value) => {
    handleInputValueChange(index, value);
    handleInputValueSubmit(index);
  }

  useEffect(() => {
    // const newProjectPropertiesData = [];
    // for(let key in projectProperties){
    //   if(!projectPropertiesDictionary[key]) continue;
    //   const propertyData = projectPropertiesDictionary[key];
    //   propertyData.value = projectProperties[propertyData.valueReference].value;
    //   if(propertyData.hasToggle) propertyData.toggleValue = projectProperties[propertyData.toggleValueReference].value;
    //   newProjectPropertiesData.push(propertyData)
    // }
    // setProjectPropertiesData(newProjectPropertiesData);
    // console.log(newProjectPropertiesData);
  }, []);


  useEffect(() => {
    window.addEventListener("mousedown", closeOnOutsideClick);
    return () => {
      window.removeEventListener("mousedown", closeOnOutsideClick);
    }
  }, [closeOnOutsideClick]);
  
  const getUnitsOptionByType = (item, index, handleInputValueChange, handleInputValueSubmit) => {
    switch (item.type) {
      case "input":
        return (
          <div style={{flex: 1}}>
          <form onSubmit={(e) => {handleInputValueSubmit(item.valueReference, e)}}>
          <Input
            type={"text"}
            value={item.value}
            customInput={{
              width: "2.125rem",
              height: "1.375rem",
              fontWeight: "bold",
              textAlign: "right",
            }}
            onPress={(e) => {handleInputValueChange(item.valueReference, e.target.value); setCurrentInputPropertyHandle(item.valueReference);}}
            onBlur={(e) => {handleInputValueSubmit(item.valueReference, null)}}
          />
          </form>
          </div>
        );
      case "dropdown":
        return (
          <div style={{flex: 1}}>

          <ScrollableDropdown
            options={item.options}
            selectedOption={item.value}
            height={
              item.options && item.options.length < 5 ? "auto" : "6.3125rem"
            }
            width="100%"
            cutomTextStyle={{ fontSize: "0.75rem", paddingLeft: 0 }}
            onChange={(value, evt) => {handleInputValueChangeAndSubmit(item.valueReference, value); evt.stopPropagation();}}
            identifier={item.valueReference}
            reduxState={true}
          />
          </div>

        );
      case "colorPicker":
        return (
          <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
            <ColoredSquare
              color={item.value}
            />
          </div>

        );
      default:
        return <div style={{flex: 1}} />;
    }
  };

  const getUnitsContainer = (handleInputValueChange, handleInputValueSubmit) => {
    return (
      <MainWrapper>
        {Object.keys(projectPropertiesData).map((item, index) => {
          const prop = projectPropertiesData[item];
          if(prop.partOf !== "unitData") return <></>;
          return (
            <EachRow key={index}>
              <Label>{prop.title}</Label>
              {getUnitsOptionByType(prop, index, handleInputValueChange, handleInputValueSubmit)}
              {prop.hasToggle && <ToggleKey toggleValue={prop.toggleValue} toggleValueReference={prop.toggleValueReference} />}
            </EachRow>
          );
        })}
      </MainWrapper>
    );
  };

  const getSiteContainer = () => {
    let siteAreaIndex, locationIndex;
    Object.keys(projectPropertiesData).forEach((item, index ) => {
      const prop = projectPropertiesData[item];
      if(prop.valueReference === "siteArea") siteAreaIndex = index;
      if(prop.valueReference === "location") locationIndex = index;
    })
    return (
      <MainWrapper>
        {/* {projectPropertiesData.map((item, index) => {
          if(item.partOf === "siteData" && item.valueReference === "siteArea") return (
            <EachRow key={index}>
              <Label>{item.title}</Label>
              {getUnitsOptionByType(item, index, handleInputValueChange, handleInputValueSubmit)}
              {item.hasToggle && <ToggleKey toggleValue={item.toggleValue} toggleValueReference={item.toggleValueReference} />}
            </EachRow>
          );
          return <></>
        })} */}
        {/*<EachRow key={siteAreaIndex}>*/}
        {/*  <Label>{projectPropertiesData[siteAreaIndex].title}</Label>*/}
        {/*  {getUnitsOptionByType(projectPropertiesData[siteAreaIndex], siteAreaIndex, handleInputValueChange, handleInputValueSubmit)}*/}
        {/*  /!* {projectPropertiesData[siteAreaIndex].hasToggle && <ToggleKey toggleValue={projectPropertiesData[siteAreaIndex].toggleValue} toggleValueReference={projectPropertiesData[siteAreaIndex].toggleValueReference} />} *!/*/}
        {/*  <Label style={{minWidth: "0px", width: "fit-content", marginLeft: "5px"}}>Sq. m.</Label>*/}
        {/*</EachRow>*/}
        {/* <EachRow>
          <Label>{projectPropertiesData[siteAreaIndex].title}</Label>
          <form onSubmit={(e) => {handleInputValueSubmit(siteAreaIndex, e)}}>
          <Input
            type={"text"}
            value={projectPropertiesData["siteArea"].value}
            customInput={{ width: "2.125rem", height: "1.375rem" }}
            onPress={(e) => {handleInputValueChange("siteArea", e.target.value)}}
          />
          </form>
          <Label style={{ textAlign: "right", marginLeft: "0.25rem" }}>
            Sq. m.
          </Label>
        </EachRow>
        {false &&  <>
          <EachRow>
            <Label>{projectPropertiesData["location"].title}</Label>
            <TextArea value={projectPropertiesData["location"].value} rows="10" cols="20" />
          </EachRow>
          <div>
            <MapComponent />
          </div>
        </>
        } */}
      </MainWrapper>
    );
  };

  const getConstructionContainer = (handleInputValueChange, handleInputValueSubmit) => {
    const externalWalls = [];
    const internalWalls = [];
    const parapetWalls = [];
    const slab = [];
    Object.keys(projectPropertiesData).forEach((item, index) => {
      const prop = projectPropertiesData[item];
      if(prop.partOf === "constructionData"){
        const row = <EachRow key={index}>
          <Label style={{ width: "4.3125rem", minWidth: "4.3125rem" }}>
            {prop.title}
          </Label>
          {getUnitsOptionByType(prop, item, handleInputValueChange, handleInputValueSubmit)}
        </EachRow>
        if(prop.superType === "ext") externalWalls.push(row);
        else if(prop.superType === "int") internalWalls.push(row);
        else if(prop.superType === "para") parapetWalls.push(row);
        else slab.push(row);
      }
    })

    return (
      <MainWrapper>
        <div>
          <ConstructionSectionHeader>External Walls</ConstructionSectionHeader>
          {externalWalls}
        </div>
        <div>
          <ConstructionSectionHeader>Internal Walls</ConstructionSectionHeader>
          {internalWalls}
        </div>
        <div>
          <ConstructionSectionHeader>Parapet Walls</ConstructionSectionHeader>
          {parapetWalls}
        </div>
        <div>
          <ConstructionSectionHeader>Slab</ConstructionSectionHeader>
          {slab}
        </div>
      </MainWrapper>
    );
  };

  const getChildContainer = (index, handleInputValueChange, handleInputValueSubmit) => {
    switch (index) {
      case 0:
        return getUnitsContainer(handleInputValueChange, handleInputValueSubmit);
      // case 1:
      //   return getSiteContainer();
      case 1:
        return getConstructionContainer(handleInputValueChange, handleInputValueSubmit);
      default:
        return null;
    }
  };

  return (
    <div
    ref={ref}
    >
      <MenuPopUp
        leftMenuItems={leftMenuItems}
        selectedIndex={selectedMenuIndex}
        setSelectedIndex={setSelectedMenuIndex}
        borderBottomLeft="0px"
        hasleftMenu={true}
        title={"Properties"}
        noBorder={true}
        hideEmptyBox={true}
        hasTopIcons={false}
        disableAdd={true}
        height={"17.6rem"}
        child={(index) => getChildContainer(index, handleInputValueChange, handleInputValueSubmit)}
        closePopUp={closePopUp}
        disableTopRightCss={true}
        isOpen={true}
      />
    </div>
  );
};

export default SettingsPopUp;
