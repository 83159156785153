import React, { useState, useEffect } from "react";
import { store } from "../../../../snaptrude/modules/utilityFunctions/Store";
import { useSelector } from "react-redux";
import { getCoveAnalysisState, Action } from "../../../../snaptrude/stateManagers/reducers/objectProperties/coveAnalysisSlice";
import * as S from './styles'
import { DaylightAnalysisRecord } from './other'
import { useDispatch } from "react-redux";
import API from "../../../../services/covetool.service";
import { fetchLatestAnalysis } from "../../../../snaptrude/modules/covetool/utils";

/**
 * Daylight Analysis Sidebar
 * @returns {JSX.Element}
 */
const SustainabilityMetricsSidebar = () => {
  const dispatch = useDispatch()
  // Redux State
  const coveAnalysisState = useSelector(getCoveAnalysisState);
  const { fetchingRecords, analysisTable, runningAnalysisTable, eui, blinkFake } = coveAnalysisState
  let analysis = analysisTable[store.floorkey]
  const euiData = eui[store.floorkey]
  const runningAnalysis = runningAnalysisTable[store.floorkey]
  let running = runningAnalysis && runningAnalysis[analysis?.floor_key]

  // local state
  const [loading, setLoading] = useState(true)

  // remove notification on mount
  useEffect(() => {
    if(loading) return
    dispatch(Action.setNotify(false))
  }, [loading])

  useEffect(() => () => {
    dispatch(Action.setNotify(false))
  }, [])

  // start fetching analysis
  useEffect(() => {
    if(fetchingRecords) return
    fetchLatestAnalysis((val) => setLoading(val))
  }, [])

  if(loading) {
    return (
      <S.MainContainer>
        <S.CenterElement>Loading...</S.CenterElement>
      </S.MainContainer>
    )
  }

  const euiDataWithMoreInfo = (function(){
    if(euiData?.runs?.length > 0) {
      if(typeof euiData.runs[0] == 'object') {
        return {
          ...euiData.runs[0],
          added: euiData?.added
        }
      }
    }

    return null
  })()

  if(blinkFake) {
    analysis = coveAnalysisState.tempDataForLoading
    running = true
  }

  return (
    <S.MainContainer>
      {
        analysis ?
        <DaylightAnalysisRecord running={running} data={analysis} euiData={euiDataWithMoreInfo} /> :
        <S.CenterElement>Run daylight analysis to see results</S.CenterElement>
      }
    </S.MainContainer>
  );
};

export default SustainabilityMetricsSidebar;
