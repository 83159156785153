var StoneFloor = {
  "_name": "StoneFloor",
  "_layers": [
      {check:false, value:"Stone",
      subtype: "",
      thickness:300,
      quantity: "Area",
      unit: "Square metres",
      core: true
    }],
}

var StoneFloorFace = {
  "_name": "StoneFloorFace",
  "_layers": [{check:false, value:"Stone Cladding",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Stone Cladding",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var BrickFloor = {
  "_name": "BrickFloor",
  "_layers": [
      {check:false, value:"Brick",
      subtype: "",
      thickness:200,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true
    }],
}

var BrickFloorFace = {
  "_name": "BrickFloorFace",
  "_layers": [{check:false, value:"Brick Cladding",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Brick Cladding",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var TileFloor = {
  "_name": "TileFloor",
  "_layers": [
      {check:false, value:"Tile",
      subtype: "",
      thickness:25,
      quantity: "Area",
      unit: "Square metres",
      core: true
    }, {check:false, value:"Screed",
      subtype: "",
      thickness:12,
      quantity: "Area",
      unit: "Square metres",
      core: false
    },  
  ],
}

var TileFloorFace = {
  "_name": "TileFloorFace",
  "_layers": [{check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var WoodFloor = {
  "_name": "WoodFloor",
  "_layers": [{check:false, value:"Wood",
    subtype: "",
    thickness:150,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

var WoodFloorFace = {
  "_name": "WoodFloorFace",
  "_layers": [{check:false, value:"Wooden Floor",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Wooden Floor",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }],
}

var ConcreteFloor = {
  "_name": "ConcreteFloor",
  "_layers": [{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

var ConcreteFloorFace = {
  "_name": "ConcreteFloorFace",
  "_layers": [{check:false, value:"Concrete Finish",
    subtype: "",
    thickness:5,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
 }, {check:false, value:"Concrete Finish",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

var PCCFloor = {
  "_name": "PCCFloor",
  "_layers": [{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

var PCCFloorFace = {
  "_name": "PCCFloorFace",
  "_layers": [{check:false, value:"PCC Finish",
    subtype: "",
    thickness:5,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
 }, {check:false, value:"PCC Finish",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

var RCCFloor = {
  "_name": "RCCFloor",
  "_layers": [{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

var RCCFloorFace = {
  "_name": "RCCFloorFace",
  "_layers": [{check:false, value:"RCC Finish",
    subtype: "",
    thickness:5,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
 }, {check:false, value:"RCC Finish",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

var ColorsFloor = {
  "_name": "TileFloorFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var ColorsFloorFace = {
  "_name": "TileFloorFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var PaintFloor = {
  "_name": "TileFloorFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var PaintFloorFace = {
  "_name": "TileFloorFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var MetalFloor = {
  "_name": "MetalFloor",
  "_layers": [{check:false, value:"Metal",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

var MetalFloorFace = {
  "_name": "MetalFloorFace",
  "_layers": [{check:false, value:"Metal Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Metal Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var GlassFloor = {
  "_name": "GlassFloor",
  "_layers": [{check:false, value:"Glass",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    transparency: 0.4,
    core: true
  }]
}

var GroundcoverFloor = {
  "_name": "GroundcoverFloor",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

var GroundcoverFloorFace = {
  "_name": "GroundcoverFloorFace",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  },{check:false, value:"Soil",
      subtype: "",
      thickness:25,
      quantity: "Area",
      unit: "Square metres",
    },
  ]
}

export {
  StoneFloor,
  StoneFloorFace,
  BrickFloor,
  BrickFloorFace,
  TileFloor,
  TileFloorFace,
  WoodFloor,
  WoodFloorFace,
  ConcreteFloor,
  ConcreteFloorFace,
  PCCFloor,
  PCCFloorFace,
  RCCFloor,
  RCCFloorFace,
  ColorsFloor,
  ColorsFloorFace,
  PaintFloor,
  PaintFloorFace,
  MetalFloor,
  MetalFloorFace,
  GlassFloor,
  GroundcoverFloor,
  GroundcoverFloorFace
}
