import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import personalWorkspaceIcon from "../../../assets/icons/personalWorkspaceIcon.svg";
import projectIcon from "../../../assets/icons/projectIcon.svg";
import shareIcon from "../../../assets/icons/shareIcon.svg";
import { Link, useLocation } from "react-router-dom";
import addFolderIcon from "../../../assets/icons/addFolderIcon.svg";
import FolderSidebar from "./FolderSidebar";
import { useFileSystemUpdate } from "../../../contexts/FileSystemContext";
import { REGEXP } from "../../../common/utils/constants";
import { routes } from "../../../routes/constants";
import { useDispatch } from "react-redux";
import { generatePathToFolder, selectTeam } from "../../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import { DragDropWrapper } from "../../DragDrop";
import { handleItemsMove } from "../../Folder/FoldersContainer";
import Tooltip from "../../Tooltip";

const Wrapper = styled.div`
    font-size: 0.875rem;
    font-weight: normal;
    color: ${colors.thinGray};
    cursor: pointer;

    img{
        display: block;
    }
    a{
        height: 2rem;
        color: ${colors.thinGray};
        text-decoration: none;
    }
    a:visited{
        color: ${colors.thinGray};
        text-decoration: none;
    }
    .titlebar{
        display: flex;
        align-items: center;
    }
    .titlebar.active{
        color: ${colors.darkGrey};
        font-weight: bold;
    }
    .titlebar.active .icon{
        filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg) brightness(93%) contrast(98%);
    }
    .titlebar.active-route{
        border-right: 0.125rem solid ${colors.brightRed};
    }
    .titlebar:hover{
        background-color: ${colors.white};
        cursor: pointer;
    }
    .title{
        display: flex;
        flex: 1;
        align-items: center;
    }
    .titletext{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .add-folder-button{
        position: sticky;
        right: 10px;
        visibility: hidden;
    }
    .titlebar:hover .add-folder-button{
        visibility: visible;
    }
    .expand-folder-button{
        padding: 0px 5px;
    }
    .rotate-90{
        transform: rotate(90deg);
    }
`

export const ProjectLink = ({ active, route, showAddFolder, onAddFolder, alt, isOverCurrent }) => {
    return (
        <div className={`titlebar ${active ? 'active active-route' : ''} ${isOverCurrent ? 'titlebar-dragover' : ''}`} >
            <Link className={`title`} to={route} >
                <div className="icon"><img src={projectIcon} alt={alt} /></div>
                <div className="titletext">Projects</div>
            </Link>
            {
                showAddFolder &&
                    <span className="add-folder-button" onClick={onAddFolder}>
                        <img data-tip data-for={`sidebar-project-folder-btn`}  src={addFolderIcon} alt="add" />
                        <Tooltip place="right" id={`sidebar-project-folder-btn`}>Create folder</Tooltip>
                    </span>
            }
        </div>
    )
}

function PersonalSidebar({ selectedTeamID, personalWorkspace, permissions }) {
    const location = useLocation();
    const fileSystemUpdate = useFileSystemUpdate();
    const dispatch = useDispatch();

    const personalRoutes = useMemo(() => {
        return {
            default: routes.dashboard,
            sharedWithMe: routes.sharedWithMe,
        }
    }, []);

    const selectedFolderID = useMemo(() => {
        if (location.pathname.match(REGEXP.personalFolder)) {
            const folderID = location.pathname.split("/").pop();
            if (folderID) return parseInt(folderID);
        }
        return null;
    }, [location.pathname]);

    const expandFolders = useMemo(() => {
        if (location.pathname.match(REGEXP.personalFolder)) return true;
        if (location.pathname === personalRoutes.default) return true;
        return false;
    }, [personalRoutes, location.pathname])

    const path = useMemo(() => {
        let pathToFolder = []
        if(selectedFolderID){
            pathToFolder = generatePathToFolder({ isDummy: true, folders: personalWorkspace.fileSystem.folders }, selectedFolderID).path;
        }
        return pathToFolder;
    }, [selectedFolderID, personalWorkspace.fileSystem]);

    const handlePersonalWorkspaceSelect = () => {
        dispatch(selectTeam(null))
    }

    return (
        <Wrapper>
            <Link className={`titlebar ${selectedTeamID == null ? 'active' : ''}`} to={personalRoutes.default} onClick={handlePersonalWorkspaceSelect}>
                <div className="icon"><img src={personalWorkspaceIcon} alt="team" /></div>
                <div className="titletext">My Workspace</div>
            </Link>
            {
                selectedTeamID == null &&
                <div style={{paddingLeft: "10px"}}>
                    <DragDropWrapper
                        ddId={"drop_perosnal_root"}
                        id="root"
                        bucketName="folders"
                        type="folder"
                        data={{ id: "root"}}
                        accept={["folder", "file"]}
                        onDrop={handleItemsMove}
                        style={{}}
                        selectionStyle={{}}
                        dropAreaStyle={{}}
                        draggable={false}
                        enableDrop
                    >
                        <ProjectLink
                            active={location.pathname === personalRoutes.default}
                            route={personalRoutes.default}
                            showAddFolder={permissions["create_rename_delete_folder"]}
                            onAddFolder={() => { fileSystemUpdate(null, null, "create") }}
                            alt="personal"
                        />
                    </DragDropWrapper>
                    {
                        expandFolders &&
                        <FolderSidebar
                            folders={personalWorkspace.fileSystem.folders}
                            workspaceRoute={personalRoutes.default}
                            selectedFolderID={selectedFolderID}
                            permissions={permissions}
                            path={path}
                        />
                    }
                    <div className={`titlebar ${location.pathname === personalRoutes.sharedWithMe ? 'active active-route' : ''}`} >

                        <Link className={`title`} to={personalRoutes.sharedWithMe}>
                            <div className="icon"><img src={shareIcon} alt="share" /></div>
                            <div className="titletext">Shared with me</div>
                        </Link>
                    </div>
                </div>
            }
        </Wrapper>
    );
}

export default PersonalSidebar;