import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { store } from "../utilityFunctions/Store";
import { createCustomMeshFromRevitExport } from "./extraFunctions";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { onSolid } from "../extrafunc";
import { checkStorey } from "./storey";

const createMasses = (massesData, materialList) => {
  const massMeshes = [];

  for (const massId in massesData) {
    try {
      const massData = massesData[massId];
      const data = massData["data"];

      for (const dataType in massData) {
        if (dataType === "data") continue;

        const meshData = massData[dataType];
        if (!meshData || !data) return;
        const mesh = createCustomMeshFromRevitExport(
          data,
          massData,
          materialList,
          { isMassType: true }
        );

        if (!mesh) continue;
        let massDS = new Mass(mesh);
        mesh.structure_id = store.activeLayer.structure_id;

        mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
        massDS.storey = 1;

        massDS.room_type = "Default";
        massDS.massType = "mass";
        // massDS.importType = "speckleRevitImport";
        massDS.revitMetaData = { elementId: data.elementId };
        mesh.storey = 1;
        massDS.storey = 1;
        massDS.structure_id = store.activeLayer.structure_id;

        const structureCollection = StructureCollection.getInstance();
        const talkingAboutStructure = structureCollection.getStructureById(
          store.activeLayer.structure_id
        );
        const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
        talkingAboutLevel.addObjectToLevel(massDS, false);
        const storey = checkStorey(data?.levelName);
        if (storey) {
          storey.addElement(massDS);
        } else {
          StoreyMutation.assignStorey(massDS);
        }
        onSolid(mesh);

        massMeshes.push(mesh);
      }
    } catch (e) {
      console.warn(e, "Corrupt Staircase data");
    }
  }
  return massMeshes;
};

export { createMasses };
