import * as log from "loglevel";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  checkIntersection,
  createRooms,
  createPartitionWalls,
} from "./wall_generation.js";
import { getSlope } from "./sketch/nearestProps0.js";
import { roundVector, toFixedVector } from "./mathFuncs.js";
import { roundArr, points } from "./polygon_dip_workers.js";
import { createMassBlocksForStructure } from "./massModeling.js";
import { getDoorDataFplan, getWindowDataFplan } from "./doors_windows.js";
import {
  ResolveEngine,
  ResolveEngineUtils,
} from "../modules/wallEngine/resolveEngine.js";
import {
  getFaceVerticesFromFace,
  getVertexObjectFromPositionArray,
} from "./brepOperations.js";

const snapmda = window.snapmda;

function findChildIntesection(index, collMeshes) {
  if (index == -1) return collMeshes;
  else {
    var mesh = store.wallLevelArray[index];
    for (let j = 0; j < collMeshes.length; j++) {
      var intersection = checkIntersection(mesh, collMeshes[j]);
      if (intersection) {
        mesh.isTakenAsChildIntersection = true;
        collMeshes.push(mesh);
        store.wallLevelArray.removeMesh(mesh);
        return findChildIntesection(
          store.wallLevelArray.length - 1,
          collMeshes
        );
      }
    }
    return findChildIntesection(index - 1, collMeshes);
  }
}
function calculateAreaOfTriangle(point1, point2, point3) {
  if (Math.abs(point2[1] - point1[1]) > Math.abs(point2[0] - point1[0])) {
    return Math.abs(0.5 * (point2[1] - point1[1]) * (point3[0] - point2[0]));
  } else
    return Math.abs(0.5 * (point2[0] - point1[0]) * (point3[1] - point2[1]));
}

function checkIfCollinear(mesh1, mesh2) {
  var indices1 = mesh1.getIndices();
  var verData1 = mesh1.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var indices2 = mesh2.getIndices();
  var verData2 = mesh2.getVerticesData(BABYLON.VertexBuffer.PositionKind);

  var builtPol1 = getBaseVertices(verData1, indices1);
  var builtPol2 = getBaseVertices(verData2, indices2);

  var or1 = getMeshSlope(mesh1);
  var or2 = getMeshSlope(mesh2);
  // console.log(or1, or2);

  var xSlope1 = builtPol1[1][0] - builtPol1[0][0];
  var ySlope1 = builtPol1[1][1] - builtPol1[0][1];
  var xSlope2 = builtPol2[1][0] - builtPol2[0][0];
  var ySlope2 = builtPol2[1][1] - builtPol2[0][1];

  var orient1 = null;
  if (Math.abs(xSlope1) > Math.abs(ySlope1)) {
    orient1 = "x";
  } else {
    orient1 = "y";
  }

  var orient2 = null;
  if (Math.abs(xSlope2) > Math.abs(ySlope2)) {
    orient2 = "x";
  } else {
    orient2 = "y";
  }
  // console.log(orient1, orient2, xSlope1, ySlope1, xSlope2, ySlope2);

  if (orient1 && orient2) {
    if (orient1 == orient2) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function calculateEuclidianDistance(p1, p2) {
  return (p2[1] - p1[1]) * (p2[1] - p1[1]) + (p2[0] - p1[0]) * (p2[0] - p1[0]);
}

function calulateAbsDistanceBtwPoint(point1, point2) {
  return Math.abs(point1 - point2);
}

function getMeshSlope(mesh) {
  var basePol = getBasePolygon(mesh);
  if (basePol.length > 1) {
    var index =
      calculateEuclidianDistance(basePol[0], basePol[1]) >
      calculateEuclidianDistance(basePol[1], basePol[2])
        ? 0
        : 1;
    var slope = getSlope(basePol, index);
    // if(slope < 0)
    //    return Math.abs(slope);
    // else
    return slope;
  } else return "-222";
}

function areaOfTriangle(pt1, pt2, pt3) {
  // return pt1.x * (pt2.y - pt3.y) + pt2.x * (pt3.y - pt1.y) + pt3.x * (pt1.y - pt2.y);
  return (
    pt1[0] * (pt2[1] - pt3[1]) +
    pt2[0] * (pt3[1] - pt1[1]) +
    pt3[0] * (pt1[1] - pt2[1])
  );
}

function removeCollinearPoints(pointsArr) {
  let threshold = 2;
  let len = pointsArr.length;
  if (len === 4) return pointsArr;

  for (let k = 0; k < pointsArr.length; ) {
    len = pointsArr.length;
    let area1 =
      pointsArr[k][0] * pointsArr[(k + 1) % len][1] +
      pointsArr[(k + 1) % len][0] * pointsArr[(k + 2) % len][1] +
      pointsArr[(k + 2) % len][0] * pointsArr[k][1];
    let area2 =
      pointsArr[k][1] * pointsArr[(k + 1) % len][0] +
      pointsArr[(k + 1) % len][1] * pointsArr[(k + 2) % len][0] +
      pointsArr[(k + 2) % len][1] * pointsArr[k][0];

    let d = Math.abs(area1 - area2) / 100;
    // console.log(d, pointsArr[k] );

    // console.log("Area of Triangle")
    // console.log(areaOfTriangle(pointsArr[k], pointsArr[(k+1)%len], pointsArr[(k+2)%len]));
    var areaofTriangle = Math.abs(
      areaOfTriangle(
        pointsArr[k],
        pointsArr[(k + 1) % len],
        pointsArr[(k + 2) % len]
      )
    );
    if (areaofTriangle < 200) {
      console.log(
        pointsArr[k],
        pointsArr[(k + 1) % len],
        pointsArr[(k + 2) % len],
        areaofTriangle
      );
    }
    //console.log(d, threshold);
    if (Math.round(d) <= threshold) {
      // if (Math.abs(areaOfTriangle(pointsArr[k], pointsArr[(k+1)%len], pointsArr[(k+2)%len])) < 500){
      //     console.log("Before :", pointsArr[k],pointsArr[(k+1)%len], pointsArr[(k+2)%len]);
      if (Math.abs(pointsArr[(k + 2) % len][1] - pointsArr[k][1]) < 2) {
        pointsArr[(k + 2) % len][1] = pointsArr[k][1];
      } else if (Math.abs(pointsArr[(k + 2) % len][0] - pointsArr[k][0]) < 2) {
        pointsArr[(k + 2) % len][0] = pointsArr[k][0];
      }

      // console.log("After :", pointsArr[k],pointsArr[(k+1)%len], pointsArr[(k+2)%len]);
      pointsArr.splice((k + 1) % len, 1);
    } else k++;
  }

  return pointsArr;
}

function getBasePolygon(mesh) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var bbinfo = mesh.getBoundingInfo();
  var builtPol = [];
  for (var i = 0; i < indices.length; i++) {
    if (verData[indices[i] * 3 + 1] < bbinfo.boundingBox.maximumWorld.y / 2) {
      builtPol.push([
        Math.round(verData[indices[i] * 3] * 1000) / 1000,
        Math.round(verData[indices[i] * 3 + 2] * 1000) / 1000,
      ]);
    }
  }
  // console.log(verData);
  // for (var i=0;i<verData.length;i+=3){
  // 	if (verData[i+1]==0){
  // 		builtPol.push([Math.round(verData[i]*100)/100, Math.round(verData[i+2]*100)/100]);
  // 	}
  // }
  builtPol = builtPol.uniq([].join);
  return builtPol;
}

function makeWallPath(wall_arr) {
  var wall_arr_2d = [],
    new_wall_arr = [];
  for (let i = 0; i < wall_arr.length; i++)
    wall_arr_2d.push(new BABYLON.Vector2(wall_arr[i][0], wall_arr[i][1]));
  wall_arr_2d = wall_arr_2d.map((x) => roundVector(x, 1000));
  // wall_arr_2d = getEnclosingVerticesInOrder(wall_arr_2d);
  for (let i = 0; i < wall_arr_2d.length; i++)
    new_wall_arr.push([wall_arr_2d[i].x, wall_arr_2d[i].y, wall_arr[i][2]]);
  return new_wall_arr;
}
function getBaseVertices(mesh) {
  let indices = mesh.getIndices();
  let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  var builtPol = [];
  for (let i = 0; i < indices.length; i++) {
    if (verData[indices[i] * 3 + 1] == 0) {
      builtPol.push([
        Math.round(verData[indices[i] * 3] * 1000) / 1000,
        Math.round(verData[indices[i] * 3 + 2] * 1000) / 1000,
      ]);
    }
  }
  // console.log(verData);
  // for (var i=0;i<verData.length;i+=3){
  // 	if (verData[i+1]==0){
  // 		builtPol.push([Math.round(verData[i]*100)/100, Math.round(verData[i+2]*100)/100]);
  // 	}
  // }
  builtPol = builtPol.uniq([].join);
  return builtPol;
}

function findMeshExtremePointsBBInfo(mesh) {
  const bbinfoCoords = mesh.getBoundingInfo().boundingBox.vectorsWorld;

  let x_max = -99999,
    x_min = 99999,
    y_max = -99999,
    y_min = 99999,
    z_min = 99999,
    z_max = -99999;
  for (var point of bbinfoCoords) {
    if (x_min >= point.x) {
      x_min = point.x;
    }
    if (y_min >= point.y) {
      y_min = point.y;
    }
    if (z_min >= point.z) {
      z_min = point.z;
    }
    if (x_max <= point.x) {
      x_max = point.x;
    }
    if (y_max <= point.y) {
      y_max = point.y;
    }
    if (z_max <= point.z) {
      z_max = point.z;
    }
  }
  return {
    xMax: x_max,
    xMin: x_min,
    yMax: y_max,
    yMin: y_min,
    zMin: z_min,
    zMax: z_max,
  };
}

function findWorldVectorExtremes(worldArr) {
  let x_max = -99999,
    x_min = 99999,
    y_max = -99999,
    y_min = 99999,
    z_min = 99999,
    z_max = -99999;
  for (var point of worldArr) {
    if (x_min >= point.x) {
      x_min = point.x;
    }
    if (y_min >= point.y) {
      y_min = point.y;
    }
    if (z_min >= point.z) {
      z_min = point.z;
    }
    if (x_max <= point.x) {
      x_max = point.x;
    }
    if (y_max <= point.y) {
      y_max = point.y;
    }
    if (z_max <= point.z) {
      z_max = point.z;
    }
  }
  return {
    xMax: x_max,
    xMin: x_min,
    yMax: y_max,
    yMin: y_min,
    zMin: z_min,
    zMax: z_max,
  };
}

function findMeshExtremePoints(mesh) {
  let x_max = -99999,
    x_min = 99999,
    y_max = -99999,
    y_min = 99999,
    z_min = 99999,
    z_max = -99999;
  for (var point of mesh.original_cords) {
    if (x_min >= point[0]) {
      x_min = point[0];
    }
    if (y_min >= point[1]) {
      y_min = point[1];
    }
    if (x_max <= point[0]) {
      x_max = point[0];
    }
    if (y_max <= point[1]) {
      y_max = point[1];
    }
    if (z_min >= point[2]) {
      z_min = point[2];
    }
  }
  for (let point of mesh.top_cords) {
    if (z_max <= point[2]) {
      z_max = point[2];
    }
  }
  return {
    xMax: x_max,
    xMin: x_min,
    YMax: y_max,
    YMin: y_min,
    zMin: z_min,
    zMax: z_max,
  };
}

function findWallPolExtremePoints(wallArr) {
  var x_max = -9999,
    x_min = 99999,
    y_max = -9999,
    y_min = 99999;
  for (var point of wallArr) {
    if (x_min >= point[0]) {
      x_min = point[0];
    }
    if (y_min >= point[1]) {
      y_min = point[1];
    }
    if (x_max <= point[0]) {
      x_max = point[0];
    }
    if (y_max <= point[1]) {
      y_max = point[1];
    }
  }
  return {
    xMax: Math.round(x_max * 100) / 100,
    xMin: Math.round(x_min * 100) / 100,
    YMax: Math.round(y_max * 100) / 100,
    YMin: Math.round(y_min * 100) / 100,
  };
}

function findWallPolExtremePointsWithoutRounding(wallArr) {
  var x_max = -9999,
    x_min = 99999,
    z_max = -9999,
    z_min = 99999;
  for (var point of wallArr) {
    if (x_min >= point[0]) {
      x_min = point[0];
    }
    if (z_min >= point[1]) {
      z_min = point[1];
    }
    if (x_max <= point[0]) {
      x_max = point[0];
    }
    if (z_max <= point[1]) {
      z_max = point[1];
    }
  }
  return { xMax: x_max, xMin: x_min, zMax: z_max, zMin: z_min };
}

function findDiagonalLength(arr) {
  let point1 = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (point1[0] != arr[i][0] && point1[1] != arr[i][1])
      //won't work in sloped case #TODO
      return calculateEuclidianDistance(arr[i], arr[i + 1]);
  }
  return 0;
}

function isPartOfBBinfoCustom(mesh1, mesh2) {
  let bbinfo1 = mesh1.getBoundingInfo();
  let bbinfo2 = mesh2.getBoundingInfo();

  let threshold =
    Math.round(mesh2._properties._thickness * store.unit_scale * 10) / 10;

  let mesh1_xMin = Math.round(bbinfo1.boundingBox.minimumWorld.x * 10) / 10;
  let mesh1_xMax = Math.round(bbinfo1.boundingBox.maximumWorld.x * 10) / 10;
  let mesh1_yMin = Math.round(bbinfo1.boundingBox.minimumWorld.y * 10) / 10;
  let mesh1_yMax = Math.round(bbinfo1.boundingBox.maximumWorld.y * 10) / 10;
  let mesh1_zMin = Math.round(bbinfo1.boundingBox.minimumWorld.z * 10) / 10;
  let mesh1_zMax = Math.round(bbinfo1.boundingBox.maximumWorld.z * 10) / 10;

  let mesh2_xMin = Math.round(bbinfo2.boundingBox.minimumWorld.x * 10) / 10;
  let mesh2_xMax = Math.round(bbinfo2.boundingBox.maximumWorld.x * 10) / 10;
  let mesh2_yMin = Math.round(bbinfo2.boundingBox.minimumWorld.y * 10) / 10;
  let mesh2_yMax = Math.round(bbinfo2.boundingBox.maximumWorld.y * 10) / 10;
  let mesh2_zMin = Math.round(bbinfo2.boundingBox.minimumWorld.z * 10) / 10;
  let mesh2_zMax = Math.round(bbinfo2.boundingBox.maximumWorld.z * 10) / 10;

  if (
    mesh2_xMax >= mesh1_xMax &&
    mesh2_xMin <= mesh1_xMin &&
    mesh2_zMax >= mesh1_zMax &&
    mesh2_zMin <= mesh1_zMin &&
    mesh2_yMax > mesh1_yMax &&
    mesh2_yMin <= mesh1_yMin
  ) {
    return true;
  }

  //check again after increasing size
  if (mesh1_xMax - mesh1_xMin > mesh1_zMax - mesh1_zMin) {
    if (mesh1_xMin >= mesh2_xMin)
      mesh1_xMax = Math.round((mesh1_xMax - threshold) * 10) / 10;
    else mesh1_xMin = Math.round((mesh1_xMin + threshold) * 10) / 10;
  } else {
    if (mesh1_zMin >= mesh2_zMin)
      mesh1_zMax = Math.round((mesh1_zMax - threshold) * 10) / 10;
    else mesh1_zMin = Math.round((mesh1_zMin + threshold) * 10) / 10;
  }

  if (
    mesh2_xMax >= mesh1_xMax &&
    mesh2_xMin <= mesh1_xMin &&
    mesh2_zMax >= mesh1_zMax &&
    mesh2_zMin <= mesh1_zMin &&
    mesh2_yMax > mesh1_yMax &&
    mesh2_yMin <= mesh1_yMin
  ) {
    return true;
  }

  return false;
}

function isPartOfBBInfo(mesh1, mesh2, threshold = 0) {
  let bbinfo1 = mesh1.getBoundingInfo();
  let bbinfo2 = mesh2.getBoundingInfo();
  let minXDiff =
    Math.round(
      (bbinfo1.boundingBox.minimumWorld.x -
        bbinfo2.boundingBox.minimumWorld.x) *
        100
    ) / 100;
  let maxXDiff =
    Math.round(
      (bbinfo1.boundingBox.maximumWorld.x -
        bbinfo2.boundingBox.maximumWorld.x) *
        100
    ) / 100;
  let minYDiff =
    Math.round(
      (bbinfo1.boundingBox.minimumWorld.y -
        bbinfo2.boundingBox.minimumWorld.y) *
        100
    ) / 100;
  let maxYDiff =
    Math.round(
      (bbinfo1.boundingBox.maximumWorld.y -
        bbinfo2.boundingBox.maximumWorld.y) *
        100
    ) / 100;
  let minZDiff =
    Math.round(
      (bbinfo1.boundingBox.minimumWorld.z -
        bbinfo2.boundingBox.minimumWorld.z) *
        100
    ) / 100;
  let maxZDiff =
    Math.round(
      (bbinfo1.boundingBox.maximumWorld.z -
        bbinfo2.boundingBox.maximumWorld.z) *
        100
    ) / 100;

  if (minXDiff >= 0 && maxXDiff <= 0) {
    if (minZDiff >= 0 && maxZDiff <= 0) {
      if (minYDiff >= 0 && maxYDiff < 0) {
        return 1;
      } else {
        return 0;
      }
    } else if (minZDiff <= 0 && maxZDiff >= 0) {
      if (minYDiff <= 0 && maxYDiff > 0) {
        return 2;
      } else {
        return 0;
      }
    }
  }

  if (minXDiff <= 0 && maxXDiff >= 0) {
    if (minZDiff <= 0 && maxZDiff >= 0) {
      if (minYDiff <= 0 && maxYDiff > 0) {
        return 2;
      } else {
        return 0;
      }
    } else if (minZDiff >= 0 && maxZDiff <= 0) {
      if (minYDiff >= 0 && maxYDiff < 0) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  return 0;
}

function isPartOf(mesh1, mesh2) {
  let mesh1_extremes = findMeshExtremePoints(mesh1);
  let mesh2_extremes = findMeshExtremePoints(mesh2);
  
  let bbinfo1 = mesh1.getBoundingInfo();
  let bbinfo2 = mesh2.getBoundingInfo();
  var minYDiff =
    Math.round(
      (bbinfo1.boundingBox.minimumWorld.y -
        bbinfo2.boundingBox.minimumWorld.y) *
        100
    ) / 100;
  var maxYDiff =
    Math.round(
      (bbinfo1.boundingBox.maximumWorld.y -
        bbinfo2.boundingBox.maximumWorld.y) *
        100
    ) / 100;

  var returnSmallerMesh = false;

  if (
    JSON.stringify(mesh1.original_cords) ===
    JSON.stringify(mesh2.original_cords)
  )
    return 2;

  let diag_diff =
    mesh1.getBoundingInfo().diagonalLength -
    mesh2.getBoundingInfo().diagonalLength;

  if (Math.abs(diag_diff) < 2) {
    returnSmallerMesh = true;
  }

  //approach based on 4th quardrant !! TODO - try to find another way
  if (isOverlapBtwMeshes(mesh1, mesh2)) {
    if (
      mesh1_extremes.xMax - mesh1_extremes.xMin <
      mesh1_extremes.YMax - mesh1_extremes.YMin
    ) {
      if (
        mesh1_extremes.YMin - mesh2_extremes.YMin < -1 &&
        mesh1_extremes.YMax - mesh2_extremes.YMax >= -2 &&
        (minYDiff < 0 || mesh1_extremes.zMin < mesh2_extremes.zMin)
      ) {
        if (returnSmallerMesh) {
          if (diag_diff > 0) return 1;
        }
        return 2;
      }
    } else {
      if (
        mesh1_extremes.xMin - mesh2_extremes.xMin < -1 &&
        mesh1_extremes.xMax - mesh2_extremes.xMax >= -2 &&
        (mesh1_extremes.zMax > mesh2_extremes.zMax ||
          mesh1_extremes.zMin < mesh2_extremes.zMin)
      ) {
        if (returnSmallerMesh) {
          if (diag_diff > 0) return 1;
        }
        return 2;
      }
    }

    if (
      mesh2_extremes.xMax - mesh2_extremes.xMin <
      mesh2_extremes.YMax - mesh2_extremes.YMin
    ) {
      if (
        mesh2_extremes.YMin - mesh1_extremes.YMin < -1 &&
        mesh2_extremes.YMax - mesh1_extremes.YMax >= -2 &&
        (mesh2_extremes.zMax > mesh1_extremes.zMax ||
          mesh2_extremes.zMin < mesh1_extremes.zMin)
      ) {
        if (returnSmallerMesh) {
          if (diag_diff > 0) return 2;
        }
        return 1;
      }
    } else {
      if (
        mesh2_extremes.xMin - mesh1_extremes.xMin < -1 &&
        mesh2_extremes.xMax - mesh1_extremes.xMax >= -2 &&
        (mesh2_extremes.zMax > mesh1_extremes.zMax ||
          mesh2_extremes.zMin < mesh1_extremes.zMin)
      ) {
        if (returnSmallerMesh) {
          if (diag_diff > 0) return 1;
        }
        return 1;
      }
    }
  }
  return 0;
}
function checkForParentMesh(index, mesh, collArr, indexArr) {
  if (index === -1) return null;
  else {
    let is_part = isPartOfBBinfoCustom(mesh, collArr[index]);
    if (is_part) {
      // store.doublyLinkedList.remove(mesh);
      try {
        var tempArr = JSON.parse(JSON.stringify(mesh.floor));
        if (typeof collArr[index].floor !== "number") {
          Array.prototype.push.apply(collArr[index].floor, tempArr);
        } else {
          tempArr.push(collArr[index].floor);
        }
        collArr[index].floor = tempArr;

        console.log("concat done ", collArr[index].floor);
        delete mesh.floor;
      } catch (e) {
        console.error("floor assignment failed for parent mesh ", e);
      }
      mesh.dispose();
      indexArr.push(index);
      return is_part;
    }
    return checkForParentMesh(index - 1, mesh, collArr, indexArr);
  }
}

function isOverlapBtwMeshes(mesh1, mesh2) {
  let mesh1_extremes = findMeshExtremePoints(mesh1);
  let mesh2_extremes = findMeshExtremePoints(mesh2);

  if (
    JSON.stringify(mesh1.original_cords) ===
    JSON.stringify(mesh2.original_cords)
  )
    return true;

  if (
    mesh1_extremes.xMax - mesh1_extremes.xMin <
    mesh1_extremes.YMax - mesh1_extremes.YMin
  ) {
    if (
      (mesh1_extremes.YMin < mesh2_extremes.YMax &&
        mesh1_extremes.YMax > mesh2_extremes.YMin) ||
      (mesh2_extremes.YMin < mesh1_extremes.YMax &&
        mesh2_extremes.YMax > mesh1_extremes.YMin)
    )
      return true;
  } else {
    if (
      (mesh1_extremes.xMin < mesh2_extremes.xMax &&
        mesh1_extremes.xMax > mesh2_extremes.xMin) ||
      (mesh2_extremes.xMin < mesh1_extremes.xMax &&
        mesh2_extremes.xMax > mesh1_extremes.xMin)
    )
      return true;
  }
  return false;
}

function getMeshWorldCoords(mesh) {
  const bbinfo = mesh.getBoundingInfo().boundingBox;
  const meshCoordsArr = bbinfo.vectorsWorld;
  return meshCoordsArr;
}

function getWorldVectorTopBottomCoords(worldArr) {
  let meshCoordsArr = worldArr;
  const coords = findWorldVectorExtremes(worldArr);
  const divider = coords.yMax - coords.yMin;

  const top_coords_arr = meshCoordsArr.filter(function (item, index) {
    if (item.y >= divider) return true;
  });

  const bottom_coords_arr = meshCoordsArr.filter(function (item, index) {
    if (item.y < divider) return true;
  });

  const major_axis =
    coords.xMax - coords.xMin > coords.yMax - coords.yMin
      ? { index: 0, value: "x" }
      : { index: 1, value: "y" };

  return { top: top_coords_arr, bottom: bottom_coords_arr, axis: major_axis };
}

function getMeshTopBottomWorldCoords(mesh) {
  let bbinfo = mesh.getBoundingInfo().boundingBox;
  let meshCoordsArr = bbinfo.vectorsWorld;

  const coords = findMeshExtremePointsBBInfo(mesh);
  const divider = coords.yMax - coords.yMin;

  const top_coords_arr = meshCoordsArr.filter(function (item, index) {
    if (item.y >= divider) return true;
  });

  const bottom_coords_arr = meshCoordsArr.filter(function (item, index) {
    if (item.y < divider) return true;
  });

  const major_axis =
    coords.xMax - coords.xMin > coords.yMax - coords.yMin
      ? { index: 0, value: "x" }
      : { index: 1, value: "y" };

  return { top: top_coords_arr, bottom: bottom_coords_arr, axis: major_axis };
}

function findTopIntersectionPoint(mesh1, mesh2) {
  let mesh1_coords = getMeshTopBottomWorldCoords(mesh1);
  let mesh2_coords = getMeshTopBottomWorldCoords(mesh2);
}

function getCollMeshRange(collArr) {
  var meshRangeArr = [];
  collArr.forEach(function (mesh, index) {
    let extremes = findMeshExtremePoints(mesh);
    if (extremes.xMax - extremes.xMin > extremes.YMax - extremes.YMin) {
      meshRangeArr.push({
        index: index,
        range: [
          Math.round(extremes.xMin * 100) / 100,
          Math.round(extremes.xMax * 100) / 100,
        ],
        height: mesh._properties._height,
        thickness: mesh._properties._thickness,
        floor: mesh.floor,
        roof: mesh.roof,
      });
    } else {
      meshRangeArr.push({
        index: index,
        range: [
          Math.round(extremes.YMin * 100) / 100,
          Math.round(extremes.YMax * 100) / 100,
        ],
        height: mesh._properties._height,
        thickness: mesh._properties._thickness,
        floor: mesh.floor,
        roof: mesh.roof,
      });
    }
  });

  for (let i = 0; i < meshRangeArr.length - 1; ) {
    if (
      (Math.abs(meshRangeArr[i].range[0] - meshRangeArr[i + 1].range[0]) <=
        meshRangeArr[i].thickness + 0.02 &&
        Math.abs(meshRangeArr[i].range[1] - meshRangeArr[i + 1].range[1]) <=
          meshRangeArr[i].thickness + 0.02) ||
      (Math.abs(meshRangeArr[i].range[1] - meshRangeArr[i + 1].range[1]) <=
        meshRangeArr[i].thickness + 0.02 &&
        Math.abs(meshRangeArr[i].range[0] - meshRangeArr[i + 1].range[0]) <=
          meshRangeArr[i].thickness + 0.02)
    ) {
      let k = meshRangeArr[i].height > meshRangeArr[i + 1].height ? i : i + 1;
      let ran =
        calulateAbsDistanceBtwPoint(
          meshRangeArr[i].range[0],
          meshRangeArr[i].range[1]
        ) >
        calulateAbsDistanceBtwPoint(
          meshRangeArr[i + 1].range[0],
          meshRangeArr[i + 1].range[1]
        )
          ? i
          : i + 1;
      var floorArr = JSON.parse(JSON.stringify(meshRangeArr[i].floor));
      Array.prototype.push.apply(floorArr, meshRangeArr[i + 1].floor);
      meshRangeArr.push({
        index: meshRangeArr[k].index,
        range: meshRangeArr[ran].range,
        height: meshRangeArr[k].height,
        thickness: meshRangeArr[k].thickness,
        floor: floorArr,
        roof: [meshRangeArr[i].roof, meshRangeArr[i + 1].roof],
      });
      meshRangeArr.splice(i, 2);
    } else i++;
  }
  return meshRangeArr;
}

function assignHeightForNewMesh(wallArr, collMeshRangeArr) {
  let arrExtremes = findWallPolExtremePoints(wallArr);
  let tempArr = [];

  if (
    arrExtremes.xMax - arrExtremes.xMin >
    arrExtremes.YMax - arrExtremes.YMin
  ) {
    collMeshRangeArr.forEach(function (item) {
      if (
        arrExtremes.xMin >= item["range"][0] &&
        arrExtremes.xMax <=
          item["range"][1] + Math.round(item.thickness * 100) / 100
      )
        tempArr.push(item.height);
    });
  } else {
    collMeshRangeArr.forEach(function (item) {
      if (
        arrExtremes.YMin >= item["range"][0] &&
        arrExtremes.YMax <=
          item["range"][1] + Math.round(item.thickness * 100) / 100
      )
        tempArr.push(item.height);
    });
  }
  if (tempArr.length === 0) return collMeshRangeArr[0].height;
  else return tempArr.max();
}

function pushInWallLinkedList(index, currNode, mergedMeshes) {
  if (index === mergedMeshes.length) return;
  else {
    var nextNode = currNode.append(mergedMeshes[index]);
    return pushInWallLinkedList(index + 1, nextNode, mergedMeshes);
  }
}

function checkWallLink(interval = 2500) {
  var ctr = -interval;
  for (var a = store.doublyLinkedList.head(); a != null; a = a.next) {
    var mesh = a.data;
    ctr += interval;
    // store.scene.render();
    window.setTimeout(
      function (mesh) {
        mesh.material = store.scene.getMaterialByName("floor_tile");
      },
      ctr,
      mesh
    );
  }
}

function checkForId(arr, id) {
  return (
    -1 ===
    arr.findIndex(function (item) {
      return item.mesh.uniqueId === id;
    })
  );
}

function onSegment(point1, point2, point3) {
  if (
    point2.x <= Math.max(point1.x, point3.x) &&
    point2.x >= Math.min(point1.x, point3.x) &&
    point2.y <= Math.max(point1.y, point3.y) &&
    point2.y >= Math.min(point1.y, point3.y)
  )
    return true;
  return false;
}

function getOrientation(point1, point2, point3) {
  let val =
    (point2.y - point1.y) * (point3.x - point2.x) -
    (point2.x - point1.x) * (point3.y - point2.y);
  if (val === 0) return 0; // colinear
  return val > 0 ? 1 : 2; //clock or counterclock wise
}

// function findFaceForFloorGeneration(origin, direction, floorMesh){
//      var ray = new BABYLON.Ray(origin, direction);
//      if(ray.intersectsMesh(floorMesh).hit){
//           // BABYLON.RayHelper.CreateAndShow(ray, store.scene, BABYLON.Color3.Red());
//           return true;
//      }
//      return false;
// }

function getWallMeshLineSegmentSlower(mesh) {
  try {
    let myBoundingBox = mesh.getBoundingInfo();
    let diagLen = myBoundingBox.diagonalLength * myBoundingBox.diagonalLength;
    let min_point = myBoundingBox.boundingBox.minimumWorld.y;
    let max_point = myBoundingBox.boundingBox.maximumWorld.y;
    let midPnt = (min_point + max_point) / 2;

    let bottomArr = [];
    let topArr = [];

    let veradt = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    for (let j = 0; j < veradt.length; j += 3) {
      let kol = [veradt[j], veradt[j + 2], veradt[j + 1]];
      if (kol[2] < midPnt) {
        bottomArr.push(kol);
      } else {
        topArr.push(kol);
      }
    }

    console.log(bottomArr.length, topArr.length);
    let point1 = new Array(3),
      point2 = new Array(3),
      point3 = new Array(3),
      point4 = new Array(3);

    let btmPts = findWallPolExtremePointsWithoutRounding(bottomArr);
    let topPts = findWallPolExtremePointsWithoutRounding(topArr);

    let btmPtsDiag =
      btmPts.xMax - btmPts.xMin > btmPts.zMax - btmPts.zMin
        ? (btmPts.xMax - btmPts.xMin) / 2
        : (btmPts.zMax - btmPts.zMin) / 2;
    let topPtsDiag =
      topPts.xMax - topPts.xMin > topPts.zMax - topPts.zMin
        ? (topPts.xMax - topPts.xMin) / 2
        : (topPts.zMax - topPts.zMin) / 2;

    let tempPoint1 = bottomArr[0];
    let tempPoint2 = topArr[0];
    if (btmPts.xMax - btmPts.xMin > btmPts.zMax - btmPts.zMin) {
      bottomArr.sort(function (a, b) {
        return a[0] - b[0];
      });
      topArr.sort(function (a, b) {
        return a[0] - b[0];
      });
    } else {
      bottomArr.sort(function (a, b) {
        return a[1] - b[1];
      });
      topArr.sort(function (a, b) {
        return a[1] - b[1];
      });
    }

    point1[0] = (bottomArr[0][0] + bottomArr[1][0]) / 2;
    point1[1] = (bottomArr[0][1] + bottomArr[1][1]) / 2;

    point2[0] =
      (bottomArr[bottomArr.length - 1][0] +
        bottomArr[bottomArr.length - 2][0]) /
      2;
    point2[1] =
      (bottomArr[bottomArr.length - 1][1] +
        bottomArr[bottomArr.length - 2][1]) /
      2;
    // point2[2] = bottomArr[0][2];

    point3[0] = (topArr[0][0] + topArr[1][0]) / 2;
    point3[1] = (topArr[0][1] + topArr[1][1]) / 2;

    point4[0] =
      (topArr[topArr.length - 1][0] + topArr[topArr.length - 2][0]) / 2;
    point4[1] =
      (topArr[topArr.length - 1][1] + topArr[topArr.length - 2][1]) / 2;

    point1[0] = (point1[0] + point3[0]) / 2;
    point1[1] = (point1[1] + point3[1]) / 2;
    point1[2] = midPnt;

    point2[0] = (point2[0] + point4[0]) / 2;
    point2[1] = (point2[1] + point4[1]) / 2;
    point2[2] = midPnt;

    console.log(point1.concat(point2));
    return point1.concat(point2);
  } catch (e) {
    return [];
  }
}

function getWallMeshLineSegment(mesh) {
  mesh.refreshBoundingInfo();
  let myBoundingBox = mesh.getBoundingInfo();
  let meshCoordsArr = myBoundingBox.boundingBox.vectorsWorld;
  let min_point = myBoundingBox.boundingBox.minimumWorld;
  let max_point = myBoundingBox.boundingBox.maximumWorld;

  let diagLen =
    calculateEuclidianDistance(
      [min_point.x, min_point.z],
      [max_point.x, max_point.z]
    ) / 2;

  let minArr = [];
  let maxArr = [];
  let point1 = { x: 0, y: 0, z: 0 };
  let point2 = { x: 0, y: 0, z: 0 };

  meshCoordsArr.forEach(function (item) {
    if (
      calculateEuclidianDistance(
        [min_point.x, min_point.z],
        [item.x, item.z]
      ) <= diagLen
    )
      minArr.push(item);
    else maxArr.push(item);
  });

  point1.x =
    minArr[0].x !== minArr[1].x
      ? (minArr[0].x + minArr[1].x) / 2
      : (minArr[0].x + minArr[2].x) / 2;
  point1.y =
    minArr[0].z !== minArr[1].z
      ? (minArr[0].z + minArr[1].z) / 2
      : (minArr[0].z + minArr[2].z) / 2;
  point1.z = minArr[0].y;

  point2.x =
    maxArr[0].x !== maxArr[1].x
      ? (maxArr[0].x + maxArr[1].x) / 2
      : (maxArr[0].x + maxArr[2].x) / 2;
  point2.y =
    maxArr[0].z !== maxArr[1].z
      ? (maxArr[0].z + maxArr[1].z) / 2
      : (maxArr[0].z + maxArr[2].z) / 2;
  point2.z = maxArr[0].y;

  mesh.slopeTheta = Math.atan((point2.y - point1.y) / (point2.x - point1.x));

  return [point1.x, point1.y, point1.z, point2.x, point2.y, point2.z];
}

//Slower but accurate
function meshLSIntersect(
  mesh1,
  mesh2,
  threshold = 0,
  checkCollinear = false,
  theta = 0
) {
  // function meshLSIntersect(point1, point2, point3, point4, threshold=0, checkCollinear = false, theta=0){
  try {
    var pts = getWallMeshLineSegment(mesh1);
    var point1 = new BABYLON.Vector3(pts[0], pts[1], pts[2]);
    var point2 = new BABYLON.Vector3(pts[3], pts[4], pts[5]);

    pts = getWallMeshLineSegment(mesh2);
    var point3 = new BABYLON.Vector3(pts[0], pts[1], pts[2]);
    var point4 = new BABYLON.Vector3(pts[3], pts[4], pts[5]);

    if (threshold) {
      //here y is z axis
      point1.x = point1.x - threshold * Math.cos(theta);
      point1.y = point1.y - threshold * Math.sin(theta);
      point2.x = point2.x + threshold * Math.cos(theta);
      point2.y = point2.y + threshold * Math.sin(theta);
    }
    point1 = toFixedVector(point1, 1);
    point2 = toFixedVector(point2, 1);
    point3 = toFixedVector(point3, 1);
    point4 = toFixedVector(point4, 1);

    let orient1 = getOrientation(point1, point2, point3);
    let orient2 = getOrientation(point1, point2, point4);
    let orient3 = getOrientation(point3, point4, point1);
    let orient4 = getOrientation(point3, point4, point2);

    if (orient1 !== orient2 && orient3 !== orient4)
      return { value: true, type: "nonCollinear" };

    if (checkCollinear) {
      if (orient1 === 0 && onSegment(point1, point3, point2))
        return { value: true, type: "collinear" };
      if (orient2 === 0 && onSegment(point1, point4, point2))
        return { value: true, type: "collinear" };
      if (orient3 === 0 && onSegment(point3, point1, point4))
        return { value: true, type: "collinear" };
      if (orient4 === 0 && onSegment(point3, point2, point4))
        return { value: true, type: "collinear" };
    }
  } catch (e) {
    return { value: false, type: "nonCollinear" };
  }

  return { value: false, type: "nonCollinear" };
}

function meshLSIntersectSlower(
  mesh1,
  mesh2,
  threshold = 0,
  checkCollinear = false,
  theta = 0
) {
  // function meshLSIntersect(point1, point2, point3, point4, threshold=0, checkCollinear = false, theta=0){
  var pts = getWallMeshLineSegmentSlower(mesh1);
  var point1 = new BABYLON.Vector3(pts[0], pts[1], pts[2]);
  var point2 = new BABYLON.Vector3(pts[3], pts[4], pts[5]);

  pts = getWallMeshLineSegmentSlower(mesh2);
  var point3 = new BABYLON.Vector3(pts[0], pts[1], pts[2]);
  var point4 = new BABYLON.Vector3(pts[3], pts[4], pts[5]);

  if (threshold) {
    //here y is z axis
    point1.x = point1.x - threshold * Math.cos(theta);
    point1.y = point1.y - threshold * Math.sin(theta);
    point2.x = point2.x + threshold * Math.cos(theta);
    point2.y = point2.y + threshold * Math.sin(theta);
  }
  point1 = toFixedVector(point1, 1);
  point2 = toFixedVector(point2, 1);
  point3 = toFixedVector(point3, 1);
  point4 = toFixedVector(point4, 1);

  let orient1 = getOrientation(point1, point2, point3);
  let orient2 = getOrientation(point1, point2, point4);
  let orient3 = getOrientation(point3, point4, point1);
  let orient4 = getOrientation(point3, point4, point2);

  if (orient1 !== orient2 && orient3 !== orient4)
    return { value: true, type: "nonCollinear" };

  if (checkCollinear) {
    if (orient1 === 0 && onSegment(point1, point3, point2))
      return { value: true, type: "collinear" };
    if (orient2 === 0 && onSegment(point1, point4, point2))
      return { value: true, type: "collinear" };
    if (orient3 === 0 && onSegment(point3, point1, point4))
      return { value: true, type: "collinear" };
    if (orient4 === 0 && onSegment(point3, point2, point4))
      return { value: true, type: "collinear" };
  }

  return { value: false, type: "nonCollinear" };
}

function getAngleBetweenVector(vec1, vec2) {
  let dot = BABYLON.Vector2.Dot(vec1, vec2);
  let len1 = vec1.length();
  let len2 = vec2.length();

  return Math.acos(dot / (len1 * len2));
}

function line_intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  var ua,
    ub,
    denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  let vec1 = new BABYLON.Vector2(x2 - x1, y2 - y1);
  let vec2 = new BABYLON.Vector2(x3 - x4, y3 - y4);

  let angle = getAngleBetweenVector(vec1, vec2);

  //0.4 => 22 degree, 2.2 => 126 degree
  if (denom == 0 || angle < 0.4 || angle > 2.2) {
    log.warn("Not intersecting " + angle);
    return null;
  }
  ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
  ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
  return {
    x: x1 + ua * (x2 - x1),
    y: y1 + ua * (y2 - y1),
    seg1: ua >= 0 && ua <= 1,
    seg2: ub >= 0 && ub <= 1,
  };
}

// Faster approach but not accurate
function meshIntersects(
  mesh1,
  mesh2,
  threshold = 0,
  checkCollinear = false,
  mesh1_extremes = null,
  mesh2_extremes = null
) {
  if (!mesh1_extremes || !mesh2_extremes) {
    mesh1_extremes = findMeshExtremePointsBBInfo(mesh1);
    mesh2_extremes = findMeshExtremePointsBBInfo(mesh2);
  }
  //here z is the vertical axis
  let point1 = { x: 0, y: 0, z: 0 };
  let point2 = { x: 0, y: 0, z: 0 };
  let point3 = { x: 0, y: 0, z: 0 };
  let point4 = { x: 0, y: 0, z: 0 };

  if (
    mesh1_extremes.xMax - mesh1_extremes.xMin >
    mesh1_extremes.zMax - mesh1_extremes.zMin
  ) {
    point1.x = mesh1_extremes.xMin - threshold;
    point1.y =
      mesh1_extremes.zMin +
      (mesh1._properties._thickness * store.unit_scale) / 2;
    point2.y =
      mesh1_extremes.zMax -
      (mesh1._properties._thickness * store.unit_scale) / 2;
    point2.x = mesh1_extremes.xMax + threshold;
  } else {
    point1.x =
      mesh1_extremes.xMin +
      (mesh1._properties._thickness * store.unit_scale) / 2;
    point1.y = mesh1_extremes.zMin - threshold;
    point2.y = mesh1_extremes.zMax + threshold;
    point2.x =
      mesh1_extremes.xMax -
      (mesh1._properties._thickness * store.unit_scale) / 2;
  }
  if (
    mesh2_extremes.xMax - mesh2_extremes.xMin >
    mesh2_extremes.zMax - mesh2_extremes.zMin
  ) {
    point3.x = mesh2_extremes.xMin - threshold;
    point3.y =
      mesh2_extremes.zMin +
      (mesh2._properties._thickness * store.unit_scale) / 2;
    point4.y =
      mesh2_extremes.zMax -
      (mesh2._properties._thickness * store.unit_scale) / 2;
    point4.x = mesh2_extremes.xMax + threshold;
  } else {
    point3.x =
      mesh2_extremes.xMin +
      (mesh2._properties._thickness * store.unit_scale) / 2;
    point3.y = mesh2_extremes.zMin - threshold;
    point4.y = mesh2_extremes.zMax + threshold;
    point4.x =
      mesh2_extremes.xMax -
      (mesh2._properties._thickness * store.unit_scale) / 2;
  }

  point1 = roundVector(point1, 100);
  point2 = roundVector(point2, 100);
  point3 = roundVector(point3, 100);
  point4 = roundVector(point4, 100);
  point1 = toFixedVector(point1, 1);
  point2 = toFixedVector(point2, 1);
  point3 = toFixedVector(point3, 1);
  point4 = toFixedVector(point4, 1);

  let orient1 = getOrientation(point1, point2, point3);
  let orient2 = getOrientation(point1, point2, point4);
  let orient3 = getOrientation(point3, point4, point1);
  let orient4 = getOrientation(point3, point4, point2);

  if (orient1 !== orient2 && orient3 !== orient4)
    return { value: true, type: "nonCollinear" };

  if (checkCollinear) {
    if (orient1 === 0 && onSegment(point1, point3, point2))
      return { value: true, type: "collinear" };
    if (orient2 === 0 && onSegment(point1, point4, point2))
      return { value: true, type: "collinear" };
    if (orient3 === 0 && onSegment(point3, point1, point4))
      return { value: true, type: "collinear" };
    if (orient4 === 0 && onSegment(point3, point2, point4))
      return { value: true, type: "collinear" };
  }

  return { value: false, type: "nonCollinear" };
}

function checkMeshLengthZero(mesh) {
  if (
    BABYLON.Vector3.DistanceSquared(
      mesh.getBoundingInfo().boundingBox.maximumWorld,
      mesh.getBoundingInfo().boundingBox.minimumWorld
    ) <= 0.3
  )
    return true;
  else return false;
}

function convertToWallCoords(vectorArr, partPoint) {
  var topCoordsArr = [];
  var bottomCoordsArr = [];

  vectorArr.forEach(function (item) {
    if (item.y < partPoint)
      bottomCoordsArr.push([
        item.x / store.unit_scale,
        (-1 * item.z) / store.unit_scale,
        item.y / store.unit_scale,
      ]);
    else
      topCoordsArr.push([
        item.x / store.unit_scale,
        (-1 * item.z) / store.unit_scale,
        item.y / store.unit_scale,
      ]);
  });
  return { top: topCoordsArr, bottom: bottomCoordsArr };
}

function scaleLine(point1, point2, wall_thickness) {
  var line =
    calculateEuclidianDistance([0, 0], point1) <
    calculateEuclidianDistance([0, 0], point2)
      ? {
          start: JSON.parse(JSON.stringify(point1)),
          end: JSON.parse(JSON.stringify(point2)),
        }
      : {
          start: JSON.parse(JSON.stringify(point2)),
          end: JSON.parse(JSON.stringify(point1)),
        };
  let slope = 0;
  let length = Math.sqrt(calculateEuclidianDistance(line.start, line.end));
  if (line.end[0] - line.start[0] === 0) {
    slope = 0;
  } else slope = (line.end[1] - line.start[1]) / (line.end[0] - line.start[0]);

  line.start[0] += ((line.start[0] - line.end[0]) / length) * wall_thickness;
  line.start[1] += ((line.start[1] - line.end[1]) / length) * wall_thickness;
  line.end[0] += ((line.end[0] - line.start[0]) / length) * wall_thickness;
  line.end[1] += ((line.end[1] - line.start[1]) / length) * wall_thickness;

  return line;
}
function lineInterect (point1, point2, point3, point4) {
  let p1 = roundArr(point1);
  let p2 = roundArr(point2);
  let p3 = roundArr(point3);
  let p4 = roundArr(point4);

  let orient1 = getOrientation(p1, p2, p3);
  let orient2 = getOrientation(p1, p2, p4);
  let orient3 = getOrientation(p3, p4, p1);
  let orient4 = getOrientation(p3, p4, p2);

  if (orient1 !== orient2 && orient3 !== orient4) {
    return 1;
  }

  if (orient1 === 0 && onSegment(p1, p3, p2)) return 1;
  if (orient2 === 0 && onSegment(p1, p4, p2)) return 1;
  if (orient3 === 0 && onSegment(p3, p1, p4)) return 1;
  if (orient4 === 0 && onSegment(p3, p2, p4)) return 1;

  return 0;
};

async function partitionRegions(floorkey_data) {
  points = floorkey_data.rooms.pols[0];
  let result = await getPointsFromWorker(points);

  floorkey_data.rooms.pols[0] = result.points;
  console.info(result.paritionWalls);

  createMassBlocksForStructure(floorkey_data);

  store.angular.element(function () {
    createRooms();
    getDoorDataFplan();
    getWindowDataFplan();
  });

  createPartitionWalls(result.paritionWalls);
}

function getPointsFromWorker(data) {
  return new Promise((resolve, reject) => {
    if (typeof Worker !== "undefined") {
      if (typeof w == "undefined") {
        store.w = new Worker("/static/js/libs/polygon_dip_workers.js");
        store.w.postMessage([data]);
      }
      store.w.onmessage = function (event) {
        resolve(event.data);
      };
    } else {
      reject("Worker not supported");
    }
  });
}

function stopWorker() {
  store.w.terminate();
  store.w = undefined;
}

function getPointsForWallGeneration(mesh, brep) {
  let resolveEngine = new ResolveEngine();
  let bottomPoints = null;
  let topPoints = null;
  let faces = brep.getFaces();
  faces.forEach((face, index) => {
    let coordsV3 = getFaceVerticesFromFace(face, mesh, BABYLON.Space.LOCAL);
    let Ys = coordsV3.map((v3) => v3.y);
    let YsAboveZero = Ys.filter((y) => y > 0);
    let coords = coordsV3.map((v3) => [v3.x, v3.z, v3.y]);

    if (YsAboveZero.length === 0) {
      //bottom face of the mass
      if (!bottomPoints) {
        bottomPoints = coords;
      } else {
        let intersectionInfo = resolveEngine.getPointsOfIntersection(
          coords,
          bottomPoints
        );

        if (intersectionInfo.found) {
          let vertices = resolveEngine.combineThePaths(
            coords,
            bottomPoints,
            intersectionInfo.p1,
            intersectionInfo.p2,
            intersectionInfo.q1,
            intersectionInfo.q2
          );
          if (!vertices) return;
          bottomPoints = vertices;
        }
      }
    } else if (YsAboveZero.length === Ys.length) {
      //top face
      if (!topPoints) {
        topPoints = coords;
      } else {
        let intersectionInfo = resolveEngine.getPointsOfIntersection(
          coords,
          topPoints
        );

        if (intersectionInfo.found) {
          let vertices = resolveEngine.combineThePaths(
            coords,
            topPoints,
            intersectionInfo.p1,
            intersectionInfo.p2,
            intersectionInfo.q1,
            intersectionInfo.q2
          );
          if (!vertices) return;
          topPoints = vertices;
        }
      }
    } else {
      //lateral faces
    }
  });

  bottomPoints.reverse();
  bottomPoints.unshift(bottomPoints.pop());

  topPoints = reorderPoints(bottomPoints, topPoints, brep);

  return [bottomPoints, topPoints];
}

/**
 * Points is [x, z, y] format
 * @param bottomPoints
 * @param topPoints
 * @param brep
 */
function reorderPoints(bottomPoints, topPoints, brep) {
  for (let i = 0; i < bottomPoints.length; i++) {
    // bottomPoints[i] = [bottomPoints[i][0], bottomPoints[i][2], bottomPoints[i][1]];
    // topPoints[i] = [topPoints[i][0], topPoints[i][2], topPoints[i][1]];
  }

  let refPoint = bottomPoints[0];
  refPoint = [refPoint[0], refPoint[2], refPoint[1]];

  let positions = brep.getPositions();
  let util = new ResolveEngineUtils();
  let vertex = getVertexObjectFromPositionArray(brep, refPoint);

  let neighbourVertices = snapmda.VertexNeighbors(vertex);

  let topRefIndex = NaN;
  neighbourVertices.some((vertex) => {
    let vertexPosition = positions[vertex.getIndex()];
    vertexPosition = [vertexPosition[0], vertexPosition[2], vertexPosition[1]];
    topPoints.some((topPoint, index) => {
      if (util.areArraysAlmostEqual(vertexPosition, topPoint)) {
        topRefIndex = index;
        return true;
      }
    });
    if (!isNaN(topRefIndex)) return true;
  });

  return arrayRotate(topPoints, topRefIndex);
}

function arrayRotate(arr, count) {
  count -= arr.length * Math.floor(count / arr.length);
  arr.push.apply(arr, arr.splice(0, count));
  return arr;
}
export {
  lineInterect,
  findChildIntesection,
  calculateAreaOfTriangle,
  checkIfCollinear,
  calculateEuclidianDistance,
  calulateAbsDistanceBtwPoint,
  getMeshSlope,
  areaOfTriangle,
  removeCollinearPoints,
  getBasePolygon,
  makeWallPath,
  getBaseVertices,
  findMeshExtremePointsBBInfo,
  findWorldVectorExtremes,
  findMeshExtremePoints,
  findWallPolExtremePoints,
  findWallPolExtremePointsWithoutRounding,
  findDiagonalLength,
  isPartOfBBinfoCustom,
  isPartOfBBInfo,
  isPartOf,
  checkForParentMesh,
  isOverlapBtwMeshes,
  getMeshWorldCoords,
  getWorldVectorTopBottomCoords,
  getMeshTopBottomWorldCoords,
  findTopIntersectionPoint,
  getCollMeshRange,
  assignHeightForNewMesh,
  pushInWallLinkedList,
  checkWallLink,
  checkForId,
  onSegment,
  getOrientation,
  getWallMeshLineSegmentSlower,
  getWallMeshLineSegment,
  meshLSIntersect,
  meshLSIntersectSlower,
  getAngleBetweenVector,
  line_intersect,
  meshIntersects,
  checkMeshLengthZero,
  convertToWallCoords,
  scaleLine,
  partitionRegions,
  getPointsFromWorker,
  stopWorker,
  getPointsForWallGeneration,
  reorderPoints,
  arrayRotate,
};
