import BABYLON from "../../babylonDS.module.js";
import _ from "lodash";
import { store } from "../../utilityFunctions/Store.js"
import { ConstraintSolverThreshold } from "../../projectProperties.js";
import { getDistanceBetweenVectors,areEdgesIntersecting,areEdgesParallel } from "../../extrafunc.js";
import { projectionOfPointOnLine,getAngleInRadians,isFloatEqual } from "../../../libs/snapFuncs.js";
import { degreeToRadian } from "../../../libs/snapUtilities.js";
import { getTopFaceVertices,sanitizeVerticesInPlace,getAllVertices } from "../../../libs/brepOperations.js";
import { getCorrespondingGlobalPointOfOtherInstance } from "./moveUtil.js";
import { areaOfPolygonV3 } from "../../../libs/areaFuncs.js";
import { areEdgesOverlapping } from "../../extrafunc.js";

// import { ResolveEngineUtils } from "../../wallEngine/resolveEngine.js";
const constraintSolver = (function () {
  let MIN_VERTEX_TO_EDGE_DISTANCE;
  const init = () => {
    MIN_VERTEX_TO_EDGE_DISTANCE = new ConstraintSolverThreshold().value;
  }
  // default value, will be overwritten later if user has changed this

  let ADJUSTMENT_FACTOR = 0.99;
  let MIN_VERTEX_TO_EDGE_DISTANCE_ADJUSTED =
    MIN_VERTEX_TO_EDGE_DISTANCE * ADJUSTMENT_FACTOR;

  let _components = [];

  const _isThresholdBreached = function (distance) {
    return distance < MIN_VERTEX_TO_EDGE_DISTANCE_ADJUSTED;
  };

  const _getTrue = function (){
    // just to make debugging easier
    return true;
  };

  const _areVerticesInvalidIndividually = function (vertices, options) {
    let areVerticesInvalid = false;

    const edges = [];
    vertices.forEach((v, i) => {
      const nextIndex = i === vertices.length - 1 ? 0 : i + 1;

      edges.push({
        headPt: v,
        tailPt: vertices[nextIndex],
      });
    });

    const area = areaOfPolygonV3(vertices);
    if (area < 1e-2) areVerticesInvalid = _getTrue();

    if (!areVerticesInvalid && options.checkEdgeLength){
      /*
            Check if any edge is getting smaller than allowed limit
             */
      edges.some((e) => {
        const length = getDistanceBetweenVectors(e.headPt, e.tailPt);
        if (_isThresholdBreached(length)) {
          areVerticesInvalid = _getTrue();
          return true;
        }
      });
    }

    if (!areVerticesInvalid && options.checkEdgeLength) {
      /*
            Check if any vertex is getting too close to an edge
             */
      vertices.some((v, vertexIndex) => {
        edges.some((e, edgeIndex) => {
          // if vertexIndex is i, it belongs to edges with indices i and i - 1
          // so they need not be checked

          const edgeIndicesToExclude = [vertexIndex];
          if (vertexIndex === 0) edgeIndicesToExclude.push(edges.length - 1);
          else edgeIndicesToExclude.push(vertexIndex - 1);

          if (edgeIndicesToExclude.includes(edgeIndex)) return;

          const projection = projectionOfPointOnLine(v, e.headPt, e.tailPt);

          // if (projection.almostEquals(e.headPt)|| projection.almostEquals(e.tailPt)) return;

          const distance = getDistanceBetweenVectors(v, projection);
          if (
            _isThresholdBreached(distance) &&
            store.resolveEngineUtils.onSegment3D(e.headPt, projection, e.tailPt)
          ) {
            areVerticesInvalid = true;

            if (options.attemptCorrection) {
              const normalToEdgeDirection = v.subtract(projection).normalize();
              const movementDirection = options.pivot
                .subtract(projection)
                .normalize();
              const angleBetweenThem = getAngleInRadians(
                normalToEdgeDirection,
                movementDirection
              );

              const threshold = degreeToRadian(10);
              if (isFloatEqual(angleBetweenThem, Math.PI / 2, threshold)) {
                return true;
              }

              const correctedPoint = projection.add(
                movementDirection.scale(
                  MIN_VERTEX_TO_EDGE_DISTANCE /
                    Math.abs(Math.cos(angleBetweenThem))
                )
              );

              if (!isNaN(correctedPoint.x))
                options.correctedAlternatePoint = correctedPoint;
            }

            return true;
          }
        });

        return areVerticesInvalid;
      });
    }

    if (!areVerticesInvalid) {
      /*
            Check if any edges are intersecting or getting closer than allowed
             */

      edges.some((e1, i1) => {
        edges.some((e2, i2) => {
          if (i2 < i1) return;
          // to prevent double checking : e1-e2 and e2-e1

          // for edge with index i, intersection need not be checked with i + 1 and i - 1

          const edgeIndicesToExclude = [i1];

          if (i1 === 0) edgeIndicesToExclude.push(i1 + 1, edges.length - 1);
          else if (i1 === edges.length - 1)
            edgeIndicesToExclude.push(0, i1 - 1);
          else edgeIndicesToExclude.push(i1 + 1, i1 - 1);

          if (edgeIndicesToExclude.includes(i2)) return;

          if (areEdgesIntersecting(e1, e2) || areEdgesOverlapping(e1, e2, true)){
            areVerticesInvalid = _getTrue();
            return true;
          } else if (areEdgesParallel(e1, e2)) {
            const m1 = BABYLON.Vector3.Center(e1.headPt, e1.tailPt);
            const m2 = BABYLON.Vector3.Center(e2.headPt, e2.tailPt);

            const m1Dash = projectionOfPointOnLine(m1, e2.headPt, e2.tailPt);
            const m2Dash = projectionOfPointOnLine(m2, e1.headPt, e1.tailPt);

            if (
              store.resolveEngineUtils.onSegment3D(e1.headPt, m2Dash, e1.tailPt) ||
              store.resolveEngineUtils.onSegment3D(e2.headPt, m1Dash, e2.tailPt)
            ) {
              const d1 = getDistanceBetweenVectors(m1, m1Dash);
              const d2 = getDistanceBetweenVectors(m2, m2Dash);

              if (_isThresholdBreached(d1) || _isThresholdBreached(d2)) {
                areVerticesInvalid = true;
                return true;
              }
            }
          }
        });

        return areVerticesInvalid;
      });
    }

    return areVerticesInvalid;
  };

  /**
   * To check if components are individually valid but invalid together.
   * Example - overlapping components
   *
   * @param setOfFaceVertices
   * @returns {boolean}
   * @private
   */
  const _areVerticesInvalidAsAWhole = function (setOfFaceVertices) {
    let areVerticesInvalid = false;
    // const resolveEngineUtils = new ResolveEngineUtils();
    setOfFaceVertices.some((vertices1, i1) => {
      const edges1 = [];
      vertices1.forEach((v1, i) => {
        const nextIndex = i === vertices1.length - 1 ? 0 : i + 1;

        edges1.push({
          headPt: v1,
          tailPt: vertices1[nextIndex],
        });
      });

      setOfFaceVertices.some((vertices2, i2) => {
        if (i2 === i1) return;

        const verticesOnEdges = [];
        const verticesOnEdgesIndices = [];

        const edgesToCheck = [];
        edges1.forEach((e1) => {
          vertices2.some((v2, i) => {
            const vertexLiesOnEdgeOfOtherMass = store.resolveEngineUtils.onSegment3D(
              e1.headPt,
              v2,
              e1.tailPt,
              1e-4,
              true
            );

            if (vertexLiesOnEdgeOfOtherMass) {
              verticesOnEdges.push(v2);
              verticesOnEdgesIndices.push(i);
            }

            return vertexLiesOnEdgeOfOtherMass;
          });
        });

        /*verticesOnEdges.forEach(v => {
                    edgesToCheck.push({
                        headPt : v,
                        tailPt : getCircularlyNextElementInArray(vertices2, v)
                    });

                    edgesToCheck.push({
                        headPt : v,
                        tailPt : getCircularlyNextElementInArray(vertices2, v)
                    });
                });

                edgesToCheck.some(edgeToCheck => {
                    edges1.some(e1 => {
                        areVerticesInvalid = areEdgesOverlapping(e1, edgeToCheck);
                        return areVerticesInvalid;
                    });
                    return areVerticesInvalid;
                });

                */

        if (verticesOnEdgesIndices.length === 2) {
          const differenceInIndex = Math.abs(
            verticesOnEdgesIndices[0] - verticesOnEdgesIndices[1]
          );

          if (
            differenceInIndex === 1 ||
            differenceInIndex === vertices2.length - 1
          ) {
            // vertices next to each other or first and last,
            // implying that they form an edge which overlaps with another mass

            areVerticesInvalid = true;
          }
        }

        return areVerticesInvalid;
      });

      return areVerticesInvalid;
    });

    return areVerticesInvalid;
  };

  const _filterComponents = function (components) {
    return components.filter(
      (c) =>
        c.type.toLowerCase() === "mass" && c.massType.toLowerCase() === "room"
    );
  };

  const initialize = function (components) {
    components = _filterComponents(components);

    const componentsWithAllInstances = [];

    components.forEach((c) => {
      if (c.mesh.isAnInstance && !componentsWithAllInstances.includes(c)) {
        const instanceComponents = c.mesh.sourceMesh.instances.map((i) =>
          i.getSnaptrudeDS()
        );
        componentsWithAllInstances.push(...instanceComponents);
      } else {
        componentsWithAllInstances.push(c);
      }
    });

    _components = componentsWithAllInstances;
  };

  const shouldConstrain = function (componentMovementDataMap, options = {}) {
    if (_.isEmpty(_components)) return false;

        if (!_.isBoolean(options.checkEdgeLength)) options.checkEdgeLength = true;
        if (!_.isBoolean(options.attemptCorrection)) options.attemptCorrection = false;

        let shouldConstrainMovement = false;

        const optionsForVertexSanitization = {
            duplicateCheck : false,
            overlapCheck : true,
            collinearCheck : true,
            onlyIfSystemGenerated : true,
            component : null
        };

        const componentsToRemove = [];
        // all instances are populated during init
        // but for edit not all of them must be involved

        const setOfTopFaceVertices = _.compact(_components.map(c => {
            const topFaceVertices = getTopFaceVertices(c);
            sanitizeVerticesInPlace(topFaceVertices);

            const movementDataForThisComponent = componentMovementDataMap.get(c);
            if (!movementDataForThisComponent){
                componentsToRemove.push(c);
                return null;
            }

            movementDataForThisComponent.forEach((toPosition, fromPosition) => {
                let updated = false;
                topFaceVertices.some(v => {
                    if (v.almostEquals(fromPosition)){
                        v.copyFrom(toPosition);
                        updated = true;
                        return true;
                    }
                });
                // if (!updated) console.warn('Constraint solving might fail');
            });

            optionsForVertexSanitization.component = c;
            sanitizeVerticesInPlace(topFaceVertices, optionsForVertexSanitization);
            return topFaceVertices;
        }));

        componentsToRemove.forEach(c => _.remove(_components, c));

        shouldConstrainMovement = _areVerticesInvalidAsAWhole(setOfTopFaceVertices, options);

        if (!shouldConstrainMovement){
            _components.some((c, i) => {
                const topFaceVertices = setOfTopFaceVertices[i];
                shouldConstrainMovement = _areVerticesInvalidIndividually(topFaceVertices, options);

                if (options.attemptCorrection && options.correctedAlternatePoint &&
                    options.mainInstanceComponent && options.mainInstanceComponent !== c){

                    /*

                    https://imgur.com/a/NgvpOLN

                    As of this writing, move edge attempts to suggest correctedPoints.
                    This extra logic is necessary because in the case shown, the movementDataMap contains values from storey 2

                    When moved further down, the copy on storey 1 which is flipped X collides with an edge.
                    So, have to map correctedAlternatePoint which is in storey 1 and that copy's context to storey 2

                    Mass on storey 2 is the options.mainInstanceComponent
                     */

                    const instanceComponents = _components.filter(c => {
                        return c.mesh.isAnInstance
                            && c !== options.mainInstanceComponent
                            && c.mesh.sourceMesh === options.mainInstanceComponent.mesh.sourceMesh;
                    });

                    let correctInstanceComponentForThePoint;
                    instanceComponents.some(c => {
                        const allGlobalVertices = getAllVertices(c);
                        if (allGlobalVertices.inArray(v => v.almostEquals(options.correctedAlternatePoint, 0.5))){
                            correctInstanceComponentForThePoint = c;
                            return true;
                        }
                    });

                    if (correctInstanceComponentForThePoint){
                        const correctedPointForTheMainInstance = getCorrespondingGlobalPointOfOtherInstance(
                            options.correctedAlternatePoint, correctInstanceComponentForThePoint, options.mainInstanceComponent);

                        options.correctedAlternatePoint = correctedPointForTheMainInstance;
                    }

                }

                return shouldConstrainMovement;
            });
        }

        if (shouldConstrainMovement && options.attemptCorrection && options.correctedAlternatePoint){
            // gave up on attempting constraint corrections. Will attempt later
          
            /*if (movementDataMap.size === 1){

                movementDataMap.forEach((v, k) => {
                    movementDataMap.set(k, options.correctedAlternatePoint);
                });

                options.attemptCorrection = false;
                if (shouldConstrain(movementDataMap, options)){
                    // suggested change is invalid
                    options.correctedAlternatePoint = null;
                }
                // else all's good
            }*/
        }

        return shouldConstrainMovement;
  };

  const flush = function () {
    _components = [];
  };

  const setThreshold = function (threshold) {
    MIN_VERTEX_TO_EDGE_DISTANCE = threshold;
    MIN_VERTEX_TO_EDGE_DISTANCE_ADJUSTED =
      MIN_VERTEX_TO_EDGE_DISTANCE * ADJUSTMENT_FACTOR;
  };

  const getThreshold = function () {
    return MIN_VERTEX_TO_EDGE_DISTANCE;
  };

  return {
    init,
    initialize,
    shouldConstrain,
    flush,
    setThreshold,
    getThreshold,
  };
})();
export { constraintSolver };
