import { onSolid } from "../extrafunc";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import { createCustomMeshFromRevitExport } from "./extraFunctions";
import { checkStorey } from "./storey";

const createDucts = (ductsData, materialList) => {
  const ductMeshes = [];

  for (const ductId in ductsData) {
    // if (type != "Panels" || type != "mullions") return;
    try {
      const ductData = ductsData[ductId];
      const data = ductData["data"];
      // const data = {
      //   category: ductData["category"],
      //   family: ductData["family"],
      //   type: ductData["type"],
      //   elementId: ductData["elementId"]
      // };
        if (!ductData || !data) return;
        const mesh = createCustomMeshFromRevitExport(
          data,
          ductData,
          materialList,
          { isMassType: true }
        );

        if (!mesh) continue;
        let ductDS = new Mass(mesh);
        mesh.structure_id = store.activeLayer.structure_id;

        mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
        ductDS.storey = 1;

        ductDS.room_type = "Default";
        ductDS.massType = data.category;
        // ductDS.importType = "speckleRevitImport";
        ductDS.revitMetaData = { elementId: data.elementId };
        mesh.storey = 1;
        ductDS.storey = 1;
        ductDS.structure_id = store.activeLayer.structure_id;

        const structureCollection = StructureCollection.getInstance();
        const talkingAboutStructure = structureCollection.getStructureById(
          store.activeLayer.structure_id
        );
        const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
        talkingAboutLevel.addObjectToLevel(ductDS, false);
        const storey = checkStorey(data?.levelName);
        if (storey) {
          storey.addElement(ductDS);
        } else {
          StoreyMutation.assignStorey(ductDS);
        }
        StoreyMutation.assignStorey(ductDS);
        // onSolid(mesh);

        ductMeshes.push(mesh);
      
    } catch (e) {
      console.warn(e, "Corrupt Ducts data");
    }
  }
  return ductMeshes;
};

export { createDucts };
