import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../../components/Input/index";
import { colors } from "../../../themes/constant";
import dropArrow from "../../../assets/icons/dropArrow.svg";
import dropOpenArrow from "../../../assets/icons/dropOpenArrow.svg";
import Divider from "../../../components/Divider/index";
import * as constants from "./constants";
import CheckBox from "../../../components/Checkbox/index";
import ScrollableDropdown from "../../../components/ScrollableDropdown/index";
import formatNumber from "../../../common/utils/formatNumber";
import settings from "../../../assets/subMenuIcon/views/settings.svg";
import { areaView, updateArea } from "../../../snaptrude/libs/areaView";
import { store } from "../../../snaptrude/modules/utilityFunctions/Store";
import * as StyledComponents from "../StyledEditorClasses";
import MenuPopUp from "../../../components/MenuPopUp";
import {ScopeUtils} from "../../../snaptrude/libs/scopeFunctions";
import {updateHeight} from "../../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice";
import {setUserSettingAndRecord} from "../../../snaptrude/modules/extrafunc";
import { useSelector } from "react-redux";

const MainContainer = styled.div`
  padding: 0 0.5rem 0 0.5rem;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .subRow {
    font-weight: 500;
    font-size: 12px;
    margin: 0.25em 0;
  }
  .subRowDim {
    font-weight: 400;
    font-size: 12px;
    margin: 0.25em 0;
    color: ${colors.thinGray};
  }
  .areafooter {
    padding: 0.5rem;
    justify-content: flex-end
  }
`;

const TopInputWrapper = styled.div`
  padding: 0.625em 0.25em;
  flex: 0.2;
  padding-top: 5rem;
`;

const InputOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.230052px;
    color: ${colors.thinGray};
  }
  .green {
    color: ${colors.green};
    font-weight: bold;
  }
  .mustard {
    color: ${colors.mustard};
    font-weight: bold;
  }
`;

const BottomTableWrapper = styled.div`
  padding: 0.25rem;
  overflow: auto;
  flex: 0.8;
  ::-webkit-scrollbar {
    width: 0.1875em;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: ${colors.thinGray};
    -webkit-box-shadow: inset 0 0 6px ${colors.black05};
  }
`;

const AreaTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    text-align: center;
    font-family: ${(props) => props.theme.font};
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    width: 3.25rem;
    text-align: center;
    letter-spacing: -0.230052px;
    color: ${colors.primeBlack};
  }
  p:nth-child(1) {
    text-align: left;
    width: 6.375rem;
  }
  p:nth-child(2) {
    text-align: right;
    width: 4.6875rem;
  }
  .rowHeader {
    margin: 0;
  }
  .subRowDimMargin {
    margin-left: 0.5em;
    width: 5.875rem;
  }
`;

const DropdownRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    text-align: left;
    width: fit-content;
  }
`;

const DropDownRowSubHeader = styled(DropdownRow)`
  justify-content: flex-start;
  width: 6.25em;
`;

const CheckboxContainer = styled.div`
  width: 4.25em;
  display: flex;
  justifycontent: center;
`;

const AreaWrapper = styled.div`
  paddingtop: 0.25em;
`;

const AreaSettingsTableRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
  p {
    text-align: center;
    font-family: DM Sans;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 3.25rem;
    text-align: center;
    letter-spacing: -0.230052px;
    color: #4F4F4F;
  }
  p:nth-child(1) {
    text-align: left;
    width: 5rem;
  }
  .rowHeader {
    margin: 0;
  }
`;

const EachRow = styled.div`
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Label = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.230052px;
  color: ${colors.thinGray};
  width: 4.625rem;
  min-width: 3.0625rem;
`;

const RoomName = ({room, isSelected}) => {
  const handleRoomSelect = () => {
    if(room && room.uniqueId){
      areaView.selectRoom(room.uniqueId, !isSelected, room);
    }
  }
  return (
    <span onClick={handleRoomSelect} style={{fontWeight: isSelected ? "bold" : "normal", cursor: "pointer"}}>{room.name}</span>
  )
}

const AreaTableRowComponent = ({key, body, title, builtUpCost, carpetAreaCost, fsiCost, metaData, setState}) => {
  // const { key, bomRow } = props;
  // const { header, body } = bomRow;
  // const { title, builtUpCost, carpetAreaCost, fsiCost } = header;
  const [collapsed, setCollapsed] = useState(true);
  const meshSelection = useSelector(state => state.meshSelection);
  
  const onFARChange = (room) => {
    room.far_included = !room.far_included;
    areaView.farChanged(metaData.structureId, room, room.far_included, metaData.level);
    setState(store.$scope.areas);
  }

  const getCollapsibleSubRow = (subRow, index) => {
    // const { subHeader, body } = subRow;
    // const { subTitle, builtUpCost, carpetAreaCost } = subHeader;
    return (
      <div key={index}>
        <AreaTableRow className="subRow" key={key}>
          <p className={body && body.length > 0 ? "subRow" : "subRowDim"}>
            <RoomName room={subRow} isSelected={meshSelection.selected.includes(subRow.uniqueId)} />
          </p>
          <p className="subRow">{formatNumber(subRow.room_bua.toFixed(2))}</p>
          <p className="subRow">{formatNumber(subRow.room_ca.toFixed(2))}</p>
          <CheckboxContainer>
            <CheckBox
              isChecked={subRow.far_included}
              onPress={() => {onFARChange(subRow)}}
            />
          </CheckboxContainer>
        </AreaTableRow>
        {/* {body.map((item, subindex) => {
          return (
            <AreaTableRow className="subRow" key={subindex}>
              <p className="subRowDim subRowDimMargin">{item.commodity}</p>
              <p className="subRowDim" />
              <p className="subRow">{formatNumber(item.cost)}</p>
              <CheckboxContainer>
                <CheckBox isChecked={item.isApplicable} />
              </CheckboxContainer>
            </AreaTableRow>
          );
        })} */}
      </div>
    );
  };

  return (
    <>
      <AreaWrapper>
        <AreaTableRow key={key}>
          <DropDownRowSubHeader
            onClick={() => setCollapsed((prevState) => !prevState)}
          >
            <DropdownRow>
              <img
                src={collapsed ? dropArrow : dropOpenArrow}
                style={{ marginRight: "0.5em" }}
                alt="drop"
              />
            </DropdownRow>
            <p style={{ width: "fit-content" }} className="rowHeader">
              {title}
            </p>
          </DropDownRowSubHeader>
          <p className="rowHeader">{formatNumber(builtUpCost.toFixed(2))}</p>
          <p className="rowHeader">{formatNumber(carpetAreaCost.toFixed(2))}</p>
          <p className="rowHeader">{fsiCost}</p>
        </AreaTableRow>
        {!collapsed && <Divider />}
      </AreaWrapper>
      {!collapsed && (
        <div>
          {body.map((subRow, index) => {
            return getCollapsibleSubRow(subRow, index);
          })}
          <Divider />
        </div>
      )}
    </>
  );
};

const AreaSettingsPopUp = (settings) => {
  const getChildContainer = () => {
    return (
      <>
        <AreaSettingsTableRow>
          <p>{constants.BUILT_UP_AREA}</p>
          <p>{constants.CARPET_AREA}</p>
        </AreaSettingsTableRow>
        <p>Rooms</p>
        <div style={{display: "flex", position: "relative", top: "-10px"}}>
          <p style={{fontWeight: "normal", width: "25rem", marginLeft: "6px"}}>External Walls</p>
          <CheckBox
            isChecked={settings.bua.extMult > 0}
            onPress={() => {
              settings.bua.extMult =  settings.bua.extMult > 0 ? 0 : 1;
            }}
          />
          <CheckBox
            isChecked={settings.ca.extMult > 0}
            onPress={() => {
              settings.ca.extMult = settings.ca.extMult ? 0 : 1;
            }}
          />
        </div>
        <div style={{display: "flex", position: "relative", top: "-15px"}}>
          <p style={{fontWeight: "normal", width: "25rem", marginLeft: "6px"}}>Internal Walls</p>
          <CheckBox
            isChecked={settings.bua.intMult > 0}
            onPress={() => {
              settings.bua.intMult = settings.bua.intMult ? 0 : 0.5;
            }}
          />
          <CheckBox
            isChecked={settings.ca.intMult > 0}
            onPress={() => {
              settings.ca.intMult = settings.ca.intMult ? 0 : 0.5;
            }}
          />
        </div>
      </>
    )
  }
  return(
    <MenuPopUp
      // leftMenuItems={leftMenuItems}
      // selectedIndex={selectedMenuIndex}
      // setSelectedIndex={setSelectedMenuIndex}
      borderBottomLeft="0px"
      hasleftMenu={false}
      title={"Area Settings"}
      noBorder={true}
      hideEmptyBox={true}
      hasTopIcons={false}
      height={"10rem"}
      child={(index) => getChildContainer()}
      // closePopUp={closePopUp}
      disableTopRightCss={true}
      isOpen={true}
      // transformCss={"translate(-18rem, 0rem)"}
    />
  );
}

const DEFAULT_AREAS = {structures: [], total_bua: 0, total_bua_far: 0, total_ca: 0, total_ca_far: 0, total_far: 'NA'};

const AreaSidebar = (props) => {
  const [unitType, setUnitType] = useState("Square meters");
  const [footerType, setFooterType] = useState("Groups");
  const [selectedAreasSettings, setSelectedAreasSettings] = useState(false);
  const [areas, setAreas] = useState(DEFAULT_AREAS);
  const [fsiValue, setFsiValue] = useState("3.25");
  const [siteArea, setSiteArea] = useState("0.00");
  useEffect(() => {
    updateAreas();
    window.addEventListener("room-name-updated", handleRoomNameUpdate);
    return () => {
      window.removeEventListener("room-name-updated", handleRoomNameUpdate);
    }
  }, [])

  const handleRoomNameUpdate = useCallback(() => {
    updateAreas();
  }, [])

  const updateAreas = async () => {
    setAreas(DEFAULT_AREAS);
    try{
      await updateArea()
      setAreas({...store.$scope.areas});
      setSiteArea(store.$scope.areas.site_area ?
        store.$scope.areas.site_area : "0.00");
      setFsiValue(store.$scope.areas.input_fsi ?
        store.$scope.areas.input_fsi : "3.25");
    }catch(error){
      console.error(error);
    }
  }

  const updateSiteArea = () => {
    ScopeUtils.updateSiteArea(null, siteArea);
    updateAreas();
  }

  const updateFSI = (newValue) => {
    setUserSettingAndRecord("inputFSI", newValue);
  }

  window.addEventListener("siteAreaUpdate", (evt) => {
    if (evt.detail.siteArea) {
      setSiteArea(evt.detail.siteArea)
    }
  })

  return (
    <MainContainer>
      <TopInputWrapper>
        <InputOptionsWrapper>
          <p>{constants.SITE_AREA}</p>
          <InputOptionsWrapper style={{ width: "60%" }}>
            <Input
              customInput={{
                width: "6.125em",
                textAlign: "end",
                padding: "0.25em",
                fontWeight: "bold",
                height: "1.375em",
              }}
              type={"text"}
              value={siteArea}
              onPress={(e) => {setSiteArea(e.target.value)}}
              keyPress={(e) => {
                if (e.keyCode === 13) {
                  updateSiteArea();
                }
              }}
            />
            <p>{unitType}</p>
          </InputOptionsWrapper>
        </InputOptionsWrapper>
        <InputOptionsWrapper>
          <p>FSI</p>
          <InputOptionsWrapper style={{ width: "60%" }}>
            <Input
              customInput={{
                width: "6.125em",
                textAlign: "end",
                padding: "0.25em",
                fontWeight: "bold",
                height: "1.375em",
              }}
              type={"text"}
              value={fsiValue}
              onPress={(e) => {setFsiValue(e.target.value)}}
              onBlur={(e) => updateFSI(e.target.value)}
            />
             <p className={ areas.total_far < fsiValue ? "green" : "mustard" }>
               { areas.total_far !== 'NA' ? areas.total_far : '' }
             </p>
          </InputOptionsWrapper>
        </InputOptionsWrapper>
        {/* <InputOptionsWrapper>
          <p>Units</p>
          <InputOptionsWrapper style={{ width: "60%" }}>
            <ScrollableDropdown
              options={[
                "Square Meters",
                "Acre",
                "Hectare",
                "Square Feet",
                "Square Yard",
                "Hectare",
                "Square Feet",
                "Square Yard",
              ]}
              selectedOption={unitType}
              onChange={setUnitType}
              height="6.3125rem"
              cutomTextStyle={{
                fontWeight: "bold",
                paddingLeft: 0,
                color: colors.darkGrey,
              }}
            />
            <p />
          </InputOptionsWrapper>
        </InputOptionsWrapper> */}
      </TopInputWrapper>
      <BottomTableWrapper>
        <AreaTableRow>
          <p>{constants.DESCRIPTION}</p>
          <p>{constants.BUILT_UP_AREA}</p>
          <p>{constants.CARPET_AREA}</p>
          <p>{constants.FSI}</p>
        </AreaTableRow>
        <AreaTableRow>
          <p>{constants.TOTAL}</p>
          <p>{formatNumber(areas.total_bua.toFixed(2))}</p>
          <p>{formatNumber(areas.total_ca.toFixed(2))}</p>
          <p>{areas.total_far}</p>
        </AreaTableRow>
        {areas.structures.length > 0 ? areas.structures[0].levels.map((level, index) => {
          return <AreaTableRowComponent 
            key={index}
            body={level.rooms}
            title={level.name}
            builtUpCost={level.level_bua}
            carpetAreaCost={level.level_ca}
            fsiCost={level.level_far}
            metaData={{structureId: areas.structures[0].structure_key, level: level}}
            setState={a => {setAreas({...a})}}
          />;
        })
        :
        <></>
      }
      </BottomTableWrapper>
      <AreaTableRow className="areafooter">
        {/* <AreaTableRow>
          <p className="subRowDim subRowDimMargin">View By</p>
          <ScrollableDropdown
            options={["Groups", "Storeys"]}
            selectedOption={footerType}
            onChange={setFooterType}
            height="auto"
            cutomTextStyle={{
              fontWeight: "bold",
              paddingLeft: 0,
              color: colors.darkGrey,
            }}
            bottomFix={true}
          />
        </AreaTableRow> */}
        <div style={{position: "relative"}}>
          {selectedAreasSettings && (
            <div
              style={{position: "absolute", bottom: "2.5rem", right: "0em"}}
            >
              {AreaSettingsPopUp(areas.settings)}
            </div>
          )}
          <StyledComponents.ImageButton
            style={{width: "1.375rem", height: "1.375rem"}}
            onClick={() =>
              setSelectedAreasSettings(!selectedAreasSettings)
            }
          >
            <img
              className={`${
                selectedAreasSettings
                  ? "selected_bottom_icon"
                  : "bottomMenuIcon"
              }`}
              src={settings}
              alt={"settings"}
            />
          </StyledComponents.ImageButton>
        </div>
      </AreaTableRow>
    </MainContainer>
  );
};

export default AreaSidebar;
