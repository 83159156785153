import { createSlice } from "@reduxjs/toolkit";
//import { store } from "../modules/utilityFunctions/Store.js";
import { store } from "../../../modules/utilityFunctions/Store.js";
import { SnapProperty } from "../../../libs/GUI/snap_properties.js";
import { SnapElement } from "../../../libs/GUI/snap_element.js";
import { computeAreaForMass, computeVolumeMass } from "../../../libs/areaFuncs.js";
import { DisplayOperation } from "../../../modules/displayOperations/displayOperation.js";
import { isMassDependant, changeLabelOfRoom, changeTypeInStructure, changeHeightOfObjects } from "../../../modules/extrafunc.js";
import { Mass } from "../../../modules/snaptrudeDS/mass.ds.js";
import { CommandManager } from "../../../modules/commandManager/CommandManager.js";
import { commandUtils } from "../../../modules/commandManager/CommandUtils.js";
import _ from 'lodash';
import { virtualSketcher } from "../../../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { drawingOperator } from "../../../libs/drawingEvents.js";
import { useDispatch } from "react-redux";
import { removeSelectionBox } from "../../../libs/meshEvents.js";
import objectPropertiesView from "../../../modules/objectProperties/objectPropertiesView.js";

const initialState = {
    objProps: [],
}

export const objectPropertiesSlice = createSlice({
  name: "objectProperties",
  initialState,
  reducers: {
    resetObjectProperties: state => initialState,
    refreshObjProperties: (state, action) => {
        const {index, value} = action.payload;
        state.objProps[index].value = value;
    },
    updateSelected: (state, action) => {
        state.objProps = action.payload;
    },

    /*onTypeChange: (state, mesh, newValue) => {

      if (newValue) {
          let meshDs = mesh.getSnaptrudeDS();
          //TODO: scope masstype
          if (store.$scope.massType.includes(newValue)) {
          // massType changes
              _updateMeshType(meshDs, newValue);
    
              state.objProps.forEach(property => {
                  if (property.name === 'Label'){
                      property.hidden = newValue.toLowerCase() !== 'room';
                  }
                  else if (property.name === 'Type'){
                      property.element.options = getTypeOptionsForObject(meshDs);
                  }
              });
          }
          else {
              // room type changes
              meshDs.room_type = newValue;
          }
      }
    
    },*/
    dimensionChange: (state, action) => {
        const {index, type, value, oldValue, options = {}} = action.payload;
        store.$scope.updateViaReact = true;
        objectPropertiesView.dimensionChange(type, value, oldValue, options);
        state.objProps[index].value = value;
        store.$scope.updateViaReact = false;
    },
    typeChange: (state, action) => {
        const {index, mesh, value} = action.payload;
        objectPropertiesView.onTypeChange(mesh, value);
        state.objProps[index].value = value;
    },
    labelChange: (state, action) => {
        const {index, mesh, value, options = {}} = action.payload;
        objectPropertiesView.onLabelChange(mesh, value, options);
        state.objProps[index].value = value;
    },
    wallTypeChange: (state, action) => {
        const { index, value } = action.payload;
        state.objProps[index].value = value;
    },
    /*dimensionChange: (type, value, oldValue, options = {}) => {
      let stack = Object.assign([], selectionStack);
      value = DisplayOperation.getOriginalDimension(value);
      if(!options.oldValueinSnapUnits)    oldValue = DisplayOperation.getOriginalDimension(oldValue);
      if (stack.length > 1) {
          let options = { returnCommand: true, ignoreSelectionStack: true };
          let commands = [], yetToExecutes = [];

          for (let i = 0; i <= stack.length - 1; i++) {
              let cmdData = DisplayOperation.updateDimensionScale(value, type, oldValue, stack[i], options);
              commands.push(...cmdData.cmd);
              yetToExecutes.push(...cmdData.yetToExecutes);
          }
          CommandManager.execute(commands, yetToExecutes);
      } else {
          DisplayOperation.updateDimensionScale(value, type, oldValue);
      }
    },*/

    updateHeight: (state, action) => {
      state.height = action.payload;
    },

    updateLength: (state, action) => {
      state.length = action.payload;
    },

    updateThickness: (state, action) => {
      state.thickness = action.payload;
    },

    makeUnique: (state) => {
      state.unique = true;
    },
  },
});

export const {
  updateSelected,
  resetObjectProperties,
  refreshObjProperties,
  updateHeight,
  updateLength,
  updateThickness,
  makeUnique,
  onTypeChange,
  wallTypeChange,
  dimensionChange,
  typeChange,
  labelChange
} = objectPropertiesSlice.actions;


const _dimensionChange = function (type, value, oldValue, options = {}) {
  let stack = Object.assign([], store.selectionStack);
  value = DisplayOperation.getOriginalDimension(value);
  if(!options.oldValueinSnapUnits)    oldValue = DisplayOperation.getOriginalDimension(oldValue);
  if (stack.length > 1) {
      let options = { returnCommand: true, ignoreSelectionStack: true };
      let commands = [], yetToExecutes = [];

      for (let i = 0; i <= stack.length - 1; i++) {
          let cmdData = DisplayOperation.updateDimensionScale(value, type, oldValue, stack[i], options);
          commands.push(...cmdData.cmd);
          yetToExecutes.push(...cmdData.yetToExecutes);
      }
      CommandManager.execute(commands, yetToExecutes);
  } else {
      DisplayOperation.updateDimensionScale(value, type, oldValue);
  }
};


/*const getTypeOptionsForObject = meshDS =>{

  let options = [];

  const wallType = 'Wall';
  const slabType = 'Slab';
  const massType = 'Mass';

  if (meshDS.isTypeChangeAllowed()){
      if (meshDS.type.toLowerCase() === massType.toLowerCase()){
          if (isMassDependant(meshDS)){
              options = Mass.TYPES.DEPENDENT;
          }
          else {
              options = [...Mass.TYPES.INDEPENDENT_ALL_EDITS];

              if (!meshDS.isEdited()){
                  options.push(...Mass.TYPES.INDEPENDENT_LIMITED_EDITS, slabType, wallType);
              }

          }

      }
      else if (meshDS.type.toLowerCase() === wallType.toLowerCase()){
          options = [...Mass.TYPES.INDEPENDENT_ALL_EDITS, wallType];

          if (!meshDS.isEdited()){
              options.push(...Mass.TYPES.INDEPENDENT_LIMITED_EDITS);
          }
      }

  }

  options.sort(); // alphabetical listing

  return options;
};*/

const getMeshDimensions = (mesh, options = {}) => {

  mesh.computeWorldMatrix(true);
  mesh.refreshBoundingInfo();
  let area = Math.abs(computeAreaForMass(mesh));
  area = Math.round(area * 100) / 100;
  let volume = Math.round(Math.abs(computeVolumeMass(mesh)) * 100) / 100;

  let scaling = mesh.absoluteScaling.clone();
  let depth = mesh.getBoundingInfo().boundingBox.extendSize.x * Math.abs(scaling.x) * 2;
  let breadth = mesh.getBoundingInfo().boundingBox.extendSize.z * Math.abs(scaling.z) * 2;
  let height = mesh.getBoundingInfo().boundingBox.extendSize.y * Math.abs(scaling.y) * 2;

  let returnObject = {};
  if(options.valueInSnapDim){
      returnObject["area"] = area;
      returnObject["volume"] = volume;
      returnObject["depth"] = depth;
      returnObject["breadth"] = breadth;
      returnObject["height"] = height;
  }
  else{
      returnObject["depth"] = DisplayOperation.convertToDefaultDimension(depth);
      returnObject["breadth"] = DisplayOperation.convertToDefaultDimension(breadth);
      returnObject["height"] = DisplayOperation.convertToDefaultDimension(height);
      // TODO: get units_type
      if(store.$scope.units_type.value === "feet-inches"){
          returnObject["area"] = DisplayOperation.convertToDefaultArea(area);
          returnObject["volume"] = DisplayOperation.convertToDefaultVolume(volume);
      }
      else{
          returnObject["area"] = parseFloat(DisplayOperation.convertToDefaultArea(area).toFixed(2));
          returnObject["volume"] = parseFloat(DisplayOperation.convertToDefaultVolume(volume).toFixed(2));
      }   
  }
  // if ($scope.units_type.value === "feet-inches") {
  //     //console.log("imin tolerance",$scope.units_type.value)
  //     return {
  //         area: (DisplayOperation.convertToDefaultArea(area)),
  //         volume: (DisplayOperation.convertToDefaultVolume(volume)),
  //         depth: (DisplayOperation.convertToDefaultDimension(depth)),
  //         breadth: (DisplayOperation.convertToDefaultDimension(breadth)),
  //         height: (DisplayOperation.convertToDefaultDimension(height))
  //     };
  // } else {


  //     return {
  //         area: parseFloat(DisplayOperation.convertToDefaultArea(area).toFixed(2)),
  //         volume: parseFloat(DisplayOperation.convertToDefaultVolume(volume).toFixed(2)),
  //         depth: parseFloat(DisplayOperation.convertToDefaultDimension(depth)),
  //         breadth: parseFloat(DisplayOperation.convertToDefaultDimension(breadth)),
  //         height: parseFloat(DisplayOperation.convertToDefaultDimension(height))
  //     };
  // }
  return returnObject;
};

/*
const generateDefaultProperties = mesh => {
  let defaultProps = [];
  let meshDimensions = getMeshDimensions(mesh);
  let meshDs = mesh.getSnaptrudeDS();
  //TODO: fix
  //let dispatch = useDispatch();
  let dispatch = () => {};
  // console.log(mesh);
  defaultProps.push(new SnapProperty("Category","Mass",SnapElement.getInputElement(null,false)));
  defaultProps.push(new SnapProperty("Type",meshDs.massType,SnapElement.getDropDown(getTypeOptionsForObject(meshDs),(meshes,name) => dispatch(onTypeChange(mesh,name)),true)));
  defaultProps.push(new SnapProperty("Label",mesh.room_type,SnapElement.getInputElement((meshs,name) =>_onLabelChange(mesh,name),true)));
  defaultProps.push(new SnapProperty("Length",meshDimensions.breadth,SnapElement.getInputElement((meshs,value,option) =>_dimensionChange("width",value,meshDimensions.breadth),true)));
  defaultProps.push(new SnapProperty("Width",meshDimensions.depth,SnapElement.getInputElement((meshs,value) =>_dimensionChange("thickness",value,meshDimensions.depth),true)));
  defaultProps.push(new SnapProperty("Height",meshDimensions.height,SnapElement.getInputElement((meshs,value) =>_dimensionChange("height",value,meshDimensions.height),true)));
  defaultProps.push(new SnapProperty("Storey",mesh.storey,SnapElement.getInputElement(null,false)));
  defaultProps.push(new SnapProperty("Area",meshDimensions.area,SnapElement.getInputElement(null,false)));
  defaultProps.push(new SnapProperty("Volume",meshDimensions.volume,SnapElement.getInputElement(null,false)));
  // defaultProps.push(new SnapProperty("Check",meshDimensions.volume,SnapElement.getCheckBox((d) => console.log(d),true)));
  defaultProps.push(new SnapProperty("object-buttons",this.mesh,null,true));

  return defaultProps;
};

const _updateMeshType = function(meshDS, meshTypeAfter){

  function _handlePlinth(meshes){
      let plinthsToRemove = [];
      meshes.forEach(mesh => {
          if(mesh.type.toLowerCase() === "mass"){
              mesh.childrenComp.forEach(child => {
                  if(Mass.isPlinth(child))    plinthsToRemove.push(child);
              });
          }

      });

      let removeParentCommand, deletionCommand;
      if(!_.isEmpty(plinthsToRemove)){
          // removeParentCommand = commandUtils.parentChildOperations.expressCheckout("plinthParentRelationRemoval", plinthsToRemove);

          deletionCommand = commandUtils.deletionOperations.getCommandData(plinthsToRemove);
          deletionCommand = commandUtils.deletionOperations.getCommand("plinthDeletion", deletionCommand);

          // return {"commands": [removeParentCommand, deletionCommand], "yets": [true, true]};
          return {"commands": [deletionCommand], "yets": [true]};

      }
      return {"commands": [], "yets": []};
  }

  function _addToGraph(){
      this.data.forEach(dataPoint => {
          const meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
          if (meshOfInterest){
              const component = meshOfInterest.getSnaptrudeDS();
              virtualSketcher.addWithoutGeometryEdit(component);
          }
      });
  }

  function _removeFromGraph(){
      this.data.forEach(dataPoint => {
          const meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
          if (meshOfInterest){
              const component = meshOfInterest.getSnaptrudeDS();
              virtualSketcher.removeWithoutGeometryEdit(component);
          }
      });
  }


  function _executeMassTypeChangeCommand(meshes, meshTypeAfter) {
      function _addProperties(meshes) {
          meshes.forEach(mesh => {
              let mass = mesh.getSnaptrudeDS();
              if (conversionToRoom){
                  drawingOperator.getMetadata().assignPropertiesForMassToGenerateWalls(mesh);
              }
              mass.massType = meshTypeAfter;
              mass.disallowTypeChange();
          });
      }

      let options = {
          componentKeys : ['massType', 'typeChangeAllowed']
      };
      let conversionToRoom = meshTypeAfter.toLowerCase() === 'room';
      if (conversionToRoom){
          options.meshKeys = ['room_type', 'room_unique_id'];
      }

      let extraCommands = [], extraYets = [];
      let plinthCommandInfo = _handlePlinth(meshes);
      extraCommands.push(...(plinthCommandInfo.commands));
      extraYets.push(...(plinthCommandInfo.yets));

      let propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(meshes, options);
      options.data = propertyChangeCommandData;

      const components = meshes.map(m => m.getSnaptrudeDS());
      const removalCommand = virtualSketcher.removeWithGeometryEdit(components);

      _addProperties(meshes);

      const additionCommand = virtualSketcher.addWithGeometryEdit(components);

      const geometryChangeFlattenedCommand = commandUtils.geometryChangeOperations.flattenCommands(
          [additionCommand, removalCommand]);

      propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(meshes, options);

      options.preExecuteCallback = _removeFromGraph;
      options.preUnExecuteCallback = _removeFromGraph;
      options.postExecuteCallback = _addToGraph;
      options.postUnExecuteCallback = _addToGraph;

      let propertyChangeCommand = commandUtils.propertyChangeOperations.getCommand('changeObjectType',
          propertyChangeCommandData, options);

      CommandManager.execute(
          _.compact([...extraCommands, propertyChangeCommand, geometryChangeFlattenedCommand]),
          [...extraYets, false, false]
      );
  }

  function _executeStructureChangeCommand(meshes, newType, options = {}) {

      /!*
      About the creation of commands for this case-

      There are 2 main commands - one for propertyChange and one for componentChange
      After the backend migration to broken mongo, each command reads and writes into the DB separately.

      propertyChange uses the identifier before change. So, let's say for mass to slab, in the backend
      propertyChange looks for the object in masses, updates name and material.id and writes the changes.
      Then, componentChange reads masses and writes to roofs.
      When undo is done, reverse happens. First, componentChange reads roofs and writes to masses.
      Then, propertyChange uses the same identifier and reads and updates masses.

      propertyChange and other commands there, like heightChange know nothing about the mass to slab conversion.

      Thus, propertyChange command needs to execute BEFORE componentChange command.

      Also, in the backend, this operation and its undo-redo is dealt with in a way similar to create and delete.
      So, after successive undo-redo, component.id is maintained.

      I've made changes to changeTypeInStructure to update old id via componentTypeChangeOperations in
      commandUtils.js so that id is preserved in front end as well.

       *!/

      let components = meshes.map(m => m.getSnaptrudeDS());
      let componentChangeCommandData = commandUtils.componentTypeChangeOperations.getCommandData(components);

      const optionsForPropertyChange = {
          meshKeys : ['name'], // changeTypeInStructure will handle mesh.type change
          meshCompoundKeys : [
              {
                  materialId : ['material', 'id']
              }
          ],
          componentKeys: ['properties']
      };

      let extraCommandsBefore = [], extraYetsBefore = [];
      let extraCommandsAfter = [], extraYetsAfter = [];

      if (newType === 'Mass' && options.massSubTypeAfter === 'Room'){
          optionsForPropertyChange.meshKeys.push('room_type', 'room_unique_id');
      }

      if(newType !== "Wall"){
          let plinthCommandInfo = _handlePlinth(meshes);
          extraCommandsBefore.push(...(plinthCommandInfo.commands));
          extraYetsBefore.push(...(plinthCommandInfo.yets));
      }
      else{
          options.retainChildrenComp = true;
      }

      virtualSketcher.removeWithoutGeometryEdit(components);

      if (options.callback){
          let cmds = [];
          cmds = options.callback(meshes);
          extraCommandsAfter.push(...cmds);
          extraYetsAfter.push(...cmds.map(c => false));
      }

      let propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
          meshes, optionsForPropertyChange);

      options.doPropertyChanges = true;
      meshes.forEach((m, i) => {
          const newComponent = changeTypeInStructure(m, newType, options);
          newComponent.disallowTypeChange();
          components[i] = newComponent;
      });

      if (newType === 'Mass' && options.massSubTypeAfter === 'Room'){
          meshes.forEach(mesh => {
              drawingOperator.getMetadata().assignPropertiesForMassToGenerateWalls(mesh);
          });
      }

      components.forEach(c => {
          virtualSketcher.addWithoutGeometryEdit(c);
      });

      optionsForPropertyChange.data = propertyChangeCommandData;
      propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(meshes, optionsForPropertyChange);

      optionsForPropertyChange.preExecuteCallback = _removeFromGraph;
      optionsForPropertyChange.preUnExecuteCallback = _removeFromGraph;
      optionsForPropertyChange.postExecuteCallback = _addToGraph;
      optionsForPropertyChange.postUnExecuteCallback = _addToGraph;
      const propertyChangeCommand = commandUtils.propertyChangeOperations.getCommand(
          'changeObjectType Property Change', propertyChangeCommandData, optionsForPropertyChange);

      componentChangeCommandData = commandUtils.componentTypeChangeOperations.getCommandData(
          components, {data : componentChangeCommandData});

      const componentChangeCommand = commandUtils.componentTypeChangeOperations.getCommand(
          'changeObjectType Structure Change', componentChangeCommandData);

      // Refer to the comment above before making any changes to this

      const allCommands = _.compact([...extraCommandsBefore, propertyChangeCommand, componentChangeCommand, ...extraCommandsAfter]);
      const yets = [...extraYetsBefore, false, false, ...extraYetsAfter];

      CommandManager.execute(allCommands, yets);

  }

  const meshTypeBefore = meshDS.massType || meshDS.type;
  if (meshTypeAfter === meshTypeBefore) return;

  const meshOfInterest = meshDS.mesh;

  const ALL_MASS_TYPES = [
      ...Mass.TYPES.DEPENDENT,
      ...Mass.TYPES.INDEPENDENT_LIMITED_EDITS,
      ...Mass.TYPES.INDEPENDENT_ALL_EDITS
  ];

  if (meshOfInterest) {

      let meshes = [];
      store.selectionStack.forEach(m => {
          if (m.isAnInstance) {
              meshes.push(m.sourceMesh, ...m.sourceMesh.instances);
              // meshes = [...meshOfInterest.sourceMesh.instances];
          }
          else {
              meshes.push(m);
          }
      });

      meshes = _.uniq(meshes);

      if (ALL_MASS_TYPES.includes(meshTypeBefore) && ALL_MASS_TYPES.includes(meshTypeAfter)) {
          _executeMassTypeChangeCommand(meshes, meshTypeAfter);
      }
      else {
          if (meshTypeBefore.toLowerCase() === 'wall' && ALL_MASS_TYPES.includes(meshTypeAfter)) {
              // meshes.forEach(mesh => mesh.material = store.scene.getMaterialByName("solid_mat"));
              _executeStructureChangeCommand(meshes, 'Mass', {massSubTypeAfter: meshTypeAfter});
          }
          else if (ALL_MASS_TYPES.includes(meshTypeBefore) && meshTypeAfter.toLowerCase() === 'wall') {

              _executeStructureChangeCommand(meshes, 'Wall');
          }
          else if (ALL_MASS_TYPES.includes(meshTypeBefore) && meshTypeAfter.toLowerCase() === 'slab') {

              if (meshDS.isEdited()) return;

              let boundingBox = meshOfInterest.getBoundingInfo().boundingBox;
              let currentHeightInBabylonUnits = boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;
              let newHeightInBabylonUnits = store.projectProperties.properties.slabThicknessProperty.getValue();

              const callback = function (meshes) {

                  const heightChangeCommand = changeHeightOfObjects(meshes, {
                      newHeightInBabylonUnits,
                      currentHeightInBabylonUnits,
                      commandName: 'roomTypeChange',
                      offsetY: -newHeightInBabylonUnits
                  });

                  return [heightChangeCommand];
              };

              const options = {
                  callback,
              };

              _executeStructureChangeCommand(meshes, 'Roof', options);

          }
      }
  }

  store.selectionStack.length = 0;
  removeSelectionBox();
};
*/

const _onLabelChange = function (mesh,newValue,option) {
  if(newValue){

      //  mesh.room_type = newValue;
      changeLabelOfRoom(mesh, newValue);

  }
};


export default objectPropertiesSlice.reducer;
