import { store } from "./Store";
import { CommandManager } from "../commandManager/CommandManager";
import { AutoSave } from "../socket/autoSave";
import { diagnostics } from "../diagnoses/diagnostics";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher";
import {
  getBottomFaceVertices,
  getFaceVerticesFromFace,
  getTopFaceVertices,
  getVertexObjectFromPositionV3,
  isComponentThin,
} from "../../libs/brepOperations";
import { autoSaveConfig } from "../socket/autoSaveConfig";
import { cachingService } from "./cachingService";
import autoLayout from "../autoLayout/autoLayout";
import { revitDataPreparation } from "../revitExport/revitExport"
import { drawSelectionBox } from "../../libs/meshEvents"

import {
  changeLabelOfRoom,
  correctMeshNormals,
  disposeNormals,
  getBabylonGUIElementByName,
  mmToSnaptrudeUnits,
  isRoomOfType,
  printFaceVertices,
  rubSandpaper,
  showNormals,
  showVertexNormals,
  getV3ProjectedOntoScreenSpace,
} from "../extrafunc";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { SendItToTeam } from '../diagnoses/copyProject';
import {removeMouseEvents, serializeScene} from "../../libs/toolbarEvents";
import {geometryUpdater} from "../sketchMassBIMIntegration/geometryUpdater";
import {panOperator} from "../cameraControl/pan";
import {cameraController} from "../cameraControl/cameraController";
import {drawingOperator} from "../../libs/drawingEvents";
import {handleDeleteKeyEvent} from "../../libs/keyEvents";
import { ForgeConnection } from "../forgeConnection/forgeConnection";
import {arrayFunctionOperator} from "../../libs/arrayFunctionEvents";
import { sketchCADFromJson } from "../forgeConnection/cadImportListeners";
import {stopwatch} from "./stopwatch";
import { GLOBAL_CONSTANTS } from "./globalConstants";
import {StateMachine} from "../Classes/StateMachine";
import cadSnaps from "../snappingEngine/cadSnaps";
import { DisplayOperation } from "../displayOperations/displayOperation";
import {massDissector} from "../createBuilding/massDissector";
import {setLayerTransperancy, takeToView} from "../../libs/sceneFuncs";
import {meshObjectMapping} from "../snaptrudeDS/mapping";
import {speckleRevitImport} from "../speckleRevitImport";
import stackedWallHelper from "../stackedWalls/stackedWallHelper";
import BABYLON from "../../modules/babylonDS.module"
import { LightGizmo, GizmoManager } from "babylonjs";
import {scenePickController} from "./scenePickController";
import {doorOperation} from "../meshoperations/doorOperation";
import {uiIndicatorsHandler} from "../uiIndicatorOperations/uiIndicatorsHandler";
import exportGLB from "../exports/gltfExporter";

store.exposed.debug = class {
  static spheres = [];
  static lines = [];

  static drawSphere =  (vertex) => {
    let sphere = BABYLON.MeshBuilder.CreateSphere("sphere", { diameter: 1 }, store.scene);
    sphere.position = vertex;

    let material = new BABYLON.StandardMaterial(store.scene);
    material.alpha = 0.7;
    material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);
    sphere.material = material;

    this.spheres.push(sphere);
  }

  static drawLine =  (vertices) => {
    let line = BABYLON.MeshBuilder.CreateTube("tube", { radius: 0.3, path: vertices }, store.scene);

    let material = new BABYLON.StandardMaterial(store.scene);
    material.alpha = 0.7;
    material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 0.0);
    line.material = material;

    this.lines.push(line);
  }

  static clearSpheres = () => {
    for (let v of this.spheres) { v.dispose(); }
    this.spheres = [];
  }

  static clearLines = () => {
    for (let l of this.lines) { l.dispose(); }
    this.lines = [];
  }

  static clear = () => {
    this.clearSpheres();
    this.clearLines();
  }
};
store.exposed.GLOBAL_CONSTANTS = GLOBAL_CONSTANTS;
store.exposed.sketchCADFromJson = sketchCADFromJson;
store.exposed.ForgeConnection = ForgeConnection;
store.exposed.structureCollection = StructureCollection;
store.exposed.CommandManager = CommandManager;
store.exposed.AutoSave = AutoSave;
store.exposed.diagnostics = diagnostics;
store.exposed.virtualSketcher = virtualSketcher;
store.exposed.massDissector = massDissector;
store.exposed.geometryUpdater = geometryUpdater;
store.exposed.autoSaveConfig = autoSaveConfig;
store.exposed.cachingService = cachingService;
store.exposed.autoLayout = autoLayout;
store.exposed.StoreyMutation = StoreyMutation;
store.exposed.drawingOperator = drawingOperator;
store.exposed.arrayFunctionOperator = arrayFunctionOperator;
store.exposed.scenePickController = scenePickController;
store.exposed.cameraController = cameraController;
store.exposed.uiIndicatorsHandler = uiIndicatorsHandler;
store.exposed.revitDataPreparation = revitDataPreparation;
store.exposed.stopwatch = stopwatch;
store.exposed.meshObjectMapping = meshObjectMapping;
store.exposed.SendItToTeam = SendItToTeam;
window.SendItToTeam = SendItToTeam;
// store.exposed.pan = panOperator;

store.exposed.getBottomFaceVertices = getBottomFaceVertices;
store.exposed.getTopFaceVertices = getTopFaceVertices;
store.exposed.showNormals = showNormals;
store.exposed.showVertexNormals = showVertexNormals;
store.exposed.disposeNormals = disposeNormals;
store.exposed.correctMeshNormals = correctMeshNormals;
store.exposed.rubSandpaper = rubSandpaper;
store.exposed.getBabylonGUIElementByName = getBabylonGUIElementByName;
store.exposed.serializeScene = serializeScene;
store.exposed.drawSelectionBox = drawSelectionBox;
store.exposed.removeMouseEvents = removeMouseEvents;
store.exposed.handleDeleteKeyEvent = handleDeleteKeyEvent;
store.exposed.printFaceVertices = printFaceVertices;
store.exposed.getVertexObjectFromPositionV3 = getVertexObjectFromPositionV3;
store.exposed.getFaceVerticesFromFace = getFaceVerticesFromFace;
store.exposed.getV3ProjectedOntoScreenSpace = getV3ProjectedOntoScreenSpace;
store.exposed.isComponentThin = isComponentThin;
store.exposed.changeLabelOfRoom = changeLabelOfRoom;
store.exposed.StateMachine = StateMachine;
store.exposed.printFaceVertices = printFaceVertices;
store.exposed.cadSnaps = cadSnaps;
store.exposed.displayOperation = DisplayOperation;
store.exposed.setLayerTransperancy = setLayerTransperancy;
store.exposed.speckleRevitImport = speckleRevitImport;
store.exposed.stackedWallHelper = stackedWallHelper;
store.exposed.createGizmo = (light) => {
  const lightGizmo = new LightGizmo();
  lightGizmo.scaleRatio = 2;
  lightGizmo.light = light;

  const gizmoManager = new GizmoManager(store.scene);
  gizmoManager.positionGizmoEnabled = true;
  gizmoManager.rotationGizmoEnabled = true;
  gizmoManager.usePointerToAttachGizmos = false;
  gizmoManager.attachToMesh(lightGizmo.attachedMesh);
}
store.exposed.isRoomOfType = isRoomOfType;
store.exposed.mmToSnaptrudeUnits = mmToSnaptrudeUnits;
store.exposed.doorOperation = doorOperation;
store.exposed.takeToView = takeToView;
store.exposed.exportGLB = exportGLB;

export default {};
