import axios from "axios";
import { ORIGIN } from "./url.constants";
import { cachingService } from "../snaptrude/modules/utilityFunctions/cachingService";
import _ from "lodash";
import { FakeProgressEvent } from "../snaptrude/modules/FakeProgressEvent/FakeProgressEvent";
import { store } from "../snaptrude/modules/utilityFunctions/Store";
import { getFormData } from "./helper";
import authService from "./auth.service";
import reduxStore from "../snaptrude/stateManagers/store/reduxStore";
import { setNotification } from "../snaptrude/stateManagers/reducers/objectProperties/editorNotificationSlice";
import { addFoldersRedux, removeFolderRedux, updateFolderRedux, updateProjectRedux } from "../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import { addProjectMetadata } from "../snaptrude/stateManagers/reducers/objectProperties/projectMetadataSlice";

const API_URL = "http://localhost:8000/";

export const getProjects = (offset) => {
  const data = {
    limit: 10,
    offset: offset
  }
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);
  return axios.post(ORIGIN + "/getprojects/", formData)
    .then((res) => {
      if (res.data.error) return { error: res.data.error, projects: [], length: 0 };
      return { projects: res.data, length: res.data.length };
    })
    .catch(error => {
      console.error(error);
      return { error: error, projects: [], length: 0 };
    })
};

export const getSharedWithMeProjects = async (offset) => {
  const data = {
    limit: 10,
    offset: offset
  }
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);
  try {
    const res = await axios.post(ORIGIN + "/sharedlist/", formData);
    const { status, data } = res.data;
    if (status === "success") {
      return { projects: data, length: data.length };
    } else {
      return { projects: [], length: 0 }
    }
  } catch (error) {
    console.error(error.message);
    return { error: error }
  }


}

export const createBimProject = async (projectName, projectUnit, parent) => {
  if (!parent) parent = "root";
  const data = {
    project_name: projectName,
    parent: parent
  }
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);
  try {
    const resp = await axios.post(ORIGIN + "/newBlankProject/", formData);
    if (resp.data.error === 0){
      const {project} = resp.data;
      const folders = [];
      const projects = [project];
      reduxStore.dispatch(addFoldersRedux({teamID: null, parentFolderID: parent, folders, projects, useParentFolderID: true, append: true}))
      return { status: "success", floorkey: project.key };
    } 
    return { status: "fail", message: "Could not create a new project" };
  } catch (error) {
    const returnObject = {
      status: "error",
      statusCode: null,
      message: "Something went wrong"
    }
    if (error && error.response) {
      returnObject.statusCode = error.response.status;
      returnObject.message = error.response.data?.error || returnObject.message;
    }
    return returnObject;
  }
};

export const updateProjectImage = (image, floorkey) => {
  const data = {
    floor_key: floorkey,
    image: image,
  };
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);
  return axios.post(ORIGIN + "/updateProjectImage/", formData);
};

export const updateProjectImageOne = (image, floorkey) => {
  const data = {
    floor_key: floorkey,
    image: image,
  };
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);
  return axios.post(ORIGIN + "/updateProjectImageOne/", formData);
};

export const formatSubType = (subType) => _.camelCase(subType);

export const formatMultipleSubType = (subType) => {
  const subTypeSplitArr = subType.split(";");
  return subTypeSplitArr.map(s => formatSubType(s));
}

const formatThumbnailData = function (data, objectType) {

  let formattedObject, item = data.uploadedObject;
  if (objectType === "material") {
    formattedObject = {
      img: ORIGIN + "/media/" + item[0],
      type: objectType,
      subType: item[1]
    };
  }
  else if (objectType === "furniture") {
    formattedObject = {
      zip: ORIGIN + "/media/" + item[0],
      type: objectType,
      subType: formatSubType(item[1]),
      babylon: ORIGIN + item[3],
      img: (ORIGIN + item[4]).replace(/\\/g, "/"),
      importType: item[6]
    };
  }
  else {
    formattedObject = {
      zip: ORIGIN + "/media/" + item[0],
      type: item[1],
      subType: formatSubType(item[2]),
      babylon: ORIGIN + item[3],
      img: (ORIGIN + item[4]).replace(/\\/g, "/"),
    };
  }

  return formattedObject;
}

export const uploadUserMaterial = function (materials, type) {

  const allPromises = [];
  const id = "material";

  materials.forEach(material => {
    const data = {
      mode1: type,
      mode2: id,
      // mode3 for file sent through 3D UI page and not userComps page
      mode3: "3dui",
    }

    const formData = new FormData();
    for (let item in data) formData.append(item, data[item]);
    formData.append(id, material, material.name);

    allPromises.push(
      axios
        .post(ORIGIN + "/materialcomps/", formData)
        .then((res) => {
          const cachedData = cachingService.get(cachingService.SERVICES.material);
          const uploadedData = formatThumbnailData(res.data, id);
          cachedData.push(uploadedData);
          return uploadedData;
        })
        .catch(err => {
          console.log(err);
          console.log("Error uploading", material.name);
        })
    );
  });

  return Promise.allSettled(allPromises).then(promiseOutcomes => {
    return _.compact(promiseOutcomes.map(p => p.value));
  });

}

export const uploadUserDWF = function (components, componentType, componentSubType, scale, userImportedType, storey) {

  const sendProgressEvent = function (completed, text) {
    const EVT_NAME = "dwfuploadprgress";
    if (!text) text = "Uploading...";
    const event = new CustomEvent(EVT_NAME, {
      detail: {
        progress: completed.toFixed(0),
        text: text
      }
    })
    window.dispatchEvent(event);
  }

  const allPromises = [];
  const totalComponents = components.length;
  const individualProgressArray = new Array(totalComponents).fill(0);
  const handleProgressEvent = (progressEvent, index) => {
    const individualProgress = (progressEvent.loaded / progressEvent.total) * 100;
    individualProgressArray[index] = individualProgress;
    let done = 0;
    let totalProgress = (individualProgressArray.reduce((prev, curr) => {
      if (curr === 100) ++done;
      return prev + curr;
    }, 0)) / totalComponents;
    let text = "Uploading..."
    if (totalComponents > 1) text = `Uploading: ${done}/${totalComponents} done`
    if (totalProgress === 100) {
      text = "Processing..."
      const fakeProgressEvent = new FakeProgressEvent(70, 95, "Processing...", 60, "dwfuploadprgress", sendProgressEvent);
      fakeProgressEvent.start();
    }
    totalProgress *= 0.7;
    sendProgressEvent(totalProgress, text);
  }
  components.forEach((component, index) => {
    const data = {
      objType: componentType,
      objSubType: componentSubType,
      scale: scale,
    };
    if (userImportedType) data['userImportedType'] = userImportedType;
    if (storey) data['storey'] = storey;

    const formData = new FormData();
    for (let item in data) formData.append(item, data[item]);
    formData.append("file", component, component.name)

    allPromises.push(
      new Promise((resolve, reject) => {
        axios
          .post(ORIGIN + "/import/", formData, {
            onUploadProgress: (progressEvent) => {
              handleProgressEvent(progressEvent, index)
            }
          })
          .then((res) => {
            let objectType = componentType;
            if (!componentSubType) objectType = "furniture";

            const cachedData = cachingService.get(
              cachingService.SERVICES[objectType]
            );
            const uploadedData = formatThumbnailData(res.data, objectType);
            if (cachedData) {
              cachedData.push(uploadedData);
            }
            resolve(uploadedData);
          })
          .catch(err => {
            console.log(err);
            console.log("Error uploading", component.name);
            reject(err);
          })
      })
    );
  });

  return Promise.allSettled(allPromises).then(promiseOutcomes => {
    return _.compact(promiseOutcomes.map(p => p.value));
  });

}

export const addCustomFurnitureType = function (newType) {

  const data = {
    objType: newType
  };

  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  return axios
    .post(ORIGIN + "/addCustomFurnitureType/", formData)
    .then((res) => {
      if (res.data === "success") return { success: true };
      else return { failure: true };
    })
    .catch(err => {
      console.log(err);
      console.log("Error adding type", newType);
      return { failure: true };
    })

}

export const getProjectMetadata = function (floorkey) {

  const data = {
    floorkey
  };

  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  return axios
    .post(ORIGIN + "/initproject/", formData)
    .then((res) => {
      if (res.status === 200) return { success: true, data: res.data };
      else return { failure: true };
    })
    .catch(err => {
      console.log(err);
      console.log("Error getting project metadata", floorkey);
      return { failure: true };
    })

}

export const flagSpeckleImportAsCompleted = function (floorkey) {

  const data = {
    floorkey
  };

  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  return axios
    .post(ORIGIN + "/flagSpeckleImportAsCompleted/", formData)
    .then((res) => {
      if (res.status === 200) {
        console.log("flagged speckle import as completed");
        return { success: true, data: res.data };
      }
      else {
        console.log(res);
        console.log("Error flagging speckle import", floorkey);
        return { failure: true };
      }
    })
    .catch(err => {
      console.log(err);
      console.log("Error flagging speckle import", floorkey);
      return { failure: true };
    })

}

export const renameProject = async function (floorkey, newName, teamId, parent) {
  if(!parent) parent = "root";
  const data = {
    project_name: newName,
    project_key: floorkey
  };
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  try {
    const resp = await axios.post(ORIGIN + "/renameproject/", formData);
    if(resp.status == 204){
      const updatedProperties = {name: newName};
      reduxStore.dispatch(updateFolderRedux({teamID: teamId, folderID: null, parentFolderID: parent, updatedProperties, projectKey: floorkey}))
      return { success: true };
    }
    return { failure: true };
  
  } catch (error) {
    console.log(error);
    console.log("Error renaming project to", newName);
    return { failure: true };
  }
}

export const saveAsDjango = function (floorkey, newName) {

  const data = {
    floorkey,
    name: newName,
  };

  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  return axios
    .post(ORIGIN + "/saveas/", formData)
    .then((res) => {
      if (res.data.message === "success") {
        const newFloorkey = res.data.floorkey;
        return { success: true, newFloorkey };
      }
      else return { failure: true };
    })
    .catch(err => {
      console.log(err);
      console.log("Error during saveAs in django");
      return { failure: true };
    })

}

export const deleteProject = async (floorkey, teamID, parent) => {
  if(!parent) parent = "root";
  const data = {
    floorkey
  }
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  try {
    const resp = await axios.post(ORIGIN + "/deleteProject/", formData);
    if(resp.status === 200){
      reduxStore.dispatch(removeFolderRedux({teamID, parentFolderID: parent, folderID: null, projectKey: floorkey}))
      return {success: true}
    }
    return {success: false}
  } catch (error) {
    console.log(error);
    console.log("Error during deleting");
    return { success: false }
  }
}

export const searchProjects = async (text) => {
  const data = {
    searchterm: text,
  }
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  try {
    const resp = await axios.post(ORIGIN + "/searchprojects/", formData);
    const { data } = resp;
    return { success: true, data: data };
  } catch (error) {
    let message = "Something went wrong while searching.";
    if (error?.message) message += " Reason: " + error.message;
    return { success: false, data: null, message: message }
  }
}

export const searchSharedProjects = async (text) => {
  const userid = localStorage.getItem("id");
  const jsonwebtoken = JSON.parse(localStorage.getItem("user"));
  const data = {
    searchterm: text,
    userid,
    jsonwebtoken
  }

  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);

  try {
    const resp = await axios.post(ORIGIN + "/searchsharedprojects/", formData);
    const { data } = resp;
    return { success: true, data: data };
  } catch (error) {
    let message = "Something went wrong while searching.";
    if (error?.message) message += " Reason: " + error.message;
    return { success: false, data: null, message: message }
  }
}

export const requestSignOff = async (requestedbyteammemberID, requestedtoteammemberID, requestedtouserID) => {
  const endpoint = `/signoff/${store.floorkey}/request/`;
  let data = {
    requestedbyteammember: requestedbyteammemberID,
    requestedtoteammember: requestedtoteammemberID,
    requestedtouser: requestedtouserID,
    origin: window.location.origin
  }
  data = _.omitBy(data, _.isNil);
  const formData = getFormData(data);
  try {
    const resp = await axios.post(ORIGIN + endpoint, formData);
    if (resp.status === 200) {
      return { status: "success", requestedSignOff: resp.data.payload }
    } else {
      return { status: 'fail' }
    }
  } catch (error) {
    let message = "Something went wrong while requesting sign-off";
    if (error && error.message) message = error.message;
    return { status: "error", message };
  }
}

export const getSignOff = async () => {
  const endpoint = `/signoff/${store.floorkey}/`;
  try {
    const resp = await axios.get(ORIGIN + endpoint);
    if (resp.status === 200) {
      const requestedSignOff = resp.data.signOff;
      const currentUser = authService.getCurrentUser();
      if (requestedSignOff && requestedSignOff.requested_to === currentUser.id && requestedSignOff.approved === false) {
        reduxStore.dispatch(setNotification({ type: "signoffRequested", count: 1 }))
      }
      return { status: 'success', requestedSignOff: requestedSignOff }
    } else {
      return { status: 'fail' }
    }
  } catch (error) {
    let message = "Something went wrong while getting existing sign-off";
    if (error && error.message) message = error.message;
    return { status: "error", message };
  }
}

export const approveSignOff = async () => {
  const endpoint = `/signoff/${store.floorkey}/approve/`;
  const data = {
    origin: window.location.origin
  }
  const formData = getFormData(data);
  try {
    const resp = await axios.post(ORIGIN + endpoint, formData);
    if (resp.status === 200) {
      const requestedSignOff = resp.data.payload;
      const currentUser = authService.getCurrentUser();
      if (requestedSignOff && requestedSignOff.requested_to === currentUser.id && requestedSignOff.approved) {
        reduxStore.dispatch(setNotification({ type: "signoffRequested", count: 0 }))
      }
      return { status: "success", requestedSignOff: requestedSignOff }
    } else {
      return { status: 'fail' }
    }
  } catch (error) {
    let message = "Something went wrong while approving sign-off";
    if (error && error.message) message = error.message;
    return { status: "error", message };
  }
}

export const getProjectsCount = async () => {
  const endpoint = '/projects/count/';
  try {
    const resp = await axios.get(ORIGIN + endpoint);
    if(resp.status === 200){
      const {projectsCount} = resp.data;
      reduxStore.dispatch(addProjectMetadata({projectsCount}))
    }
  } catch (error) {
    let message = "Something went wrong while approving sign-off";
    if (error && error.message) message = error.message;
    return { status: "error", message };
  }
}