import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { Factory } from "./meshFactory.module.js";
import { Roof } from "../snaptrudeDS/roof.ds.js";
import { convertGlobalCoordsToLocal,mmToSnaptrudeUnits } from "../extrafunc.js";
import { getBottomFaceVertices,getTopFaceVertices } from "../../libs/brepOperations.js";
import { offsetRoomPolsRoof } from "../../libs/wall_generation.js";
import { createCustomMesh } from "../../libs/massModeling.js";
/**
 * { creates a plain roof }
 *
 * @param      {BABYLON}  mesh    The mesh
 * @param offset
 * @return     {BABYLON}    { description_of_the_return_value }
 */
function plainroof(mesh) {
  let offset = plainRoofParameters.overhangBeforeUnion;
  // let offset = plainRoofParameters.overhangBeforeUnion - userSetBIMPropertiesHandler.getWallThickness() / 2;
  // let offset = userSetBIMPropertiesHandler.getRoofOverhang();

  let roofs = [];
  mesh.roof_pol.forEach((path_bottom) => {
    // path_bottom is in [x, z, y] form

    path_bottom = path_bottom.map((v) => [v[0], v[2], v[1]]);
    let roof_pol_offset = getOffsetValues(path_bottom, offset);

    var factory = new Factory();
    var roof = new Roof(factory.createRoof("plain", roof_pol_offset));

    roof.assignProperties();
    roof.mesh.structure_id = mesh.structure_id;
    roof.room_id = mesh.room_unique_id;

    roof.mesh.computeWorldMatrix(true);
    path_bottom = convertGlobalCoordsToLocal(
      path_bottom,
      roof.mesh.getWorldMatrix(),
      false,
      false
    );
    roof_pol_offset = convertGlobalCoordsToLocal(
      roof_pol_offset,
      roof.mesh.getWorldMatrix(),
      false,
      false
    );

    roof.setRoofPolBottom(path_bottom);
    roof.setRoofPolOffsetBottom(roof_pol_offset);

    const roofPolOffsetTop = [];
    roof_pol_offset.forEach((arr) => {
      // roof.roof_pol_offset_top.push([arr[0], arr[1] + plainRoofParameters.roofDepth, arr[2]]);
      roofPolOffsetTop.push([
        arr[0],
        arr[1] + store.projectProperties.properties.slabThicknessProperty.getValue(),
        arr[2],
      ]);
    });

    const roofPolTop = [];
    path_bottom.forEach((arr) => {
      // roof.roof_pol_top.push([arr[0], arr[1] + plainRoofParameters.roofDepth, arr[2]]);
      roofPolTop.push([
        arr[0],
        arr[1] + store.projectProperties.properties.slabThicknessProperty.getValue(),
        arr[2],
      ]);
    });

    roof.setRoofPolTop(roofPolTop);
    roof.setRoofPolOffsetTop(roofPolOffsetTop);
    roof.setRoofOffset(offset);

    roof.mesh.storey = mesh.storey;

    roofs.push(roof);
  });

  return roofs;
}

function populateRoofBoundInfo(roof) {
  let bottomFaceVertices = getBottomFaceVertices(roof, BABYLON.Space.LOCAL);
  let topFaceVertices = getTopFaceVertices(roof, BABYLON.Space.LOCAL);

  bottomFaceVertices = _.uniqWith(bottomFaceVertices, (v1, v2) =>
    v1.almostEquals(v2, plainRoofParameters.proximityThreshold)
  );
  topFaceVertices = _.uniqWith(topFaceVertices, (v1, v2) =>
    v1.almostEquals(v2, plainRoofParameters.proximityThreshold)
  );

  roof.setRoofPolOffsetBottom(bottomFaceVertices.map((v) => v.asArray()));
  roof.setRoofPolOffsetTop(_.reverse(topFaceVertices.map((v) => v.asArray())));
}

function adjustHeightOfHalfRoofs(path, height) {
  return path.map(v3 => {
    const clone = v3.clone();
    clone.y = height;
    return clone;
  });
}

function getOffsetValues(path, offsetValue, options = {}) {
  if (_.isNil(options.wallType)) options.wallType = "Ext";
  if (_.isNil(options.subtract)) options.subtract = false;
  if (path.length < 3) return path;
  let effectiveOffset;
  let wallThickness = store.projectProperties.properties[ "wallThicknessProperty" + options.wallType ].getValue();
  if (options.subtract) {
    effectiveOffset = offsetValue -  wallThickness / 2;
  } else {
    effectiveOffset = offsetValue + wallThickness / 2;
  }
  if (effectiveOffset === 0) return path;
  path = path.map((v) => [v[0], v[2], v[1]]);
  let offsetPath = offsetRoomPolsRoof(path, effectiveOffset);
  return offsetPath.map((v) => [v[0], v[2], v[1]]);
}

/**
 * { create plain roof object }
 *
 * @class      PlainRoof (name)
 * @return     {object}  { description_of_the_return_value }
 * @param points_bottom
 */
var PlainRoof = function (points_bottom) {
  // let roofMesh = createCustomMesh(points_bottom.map(v => [v[0], v[2], v[1]]), plainRoofParameters.roofDepth);
  let roofMesh = createCustomMesh(
    points_bottom.map((v) => [v[0], v[2], v[1]]),
    store.projectProperties.properties.slabThicknessProperty.getValue()
  );

  roofMesh.type = "Roof";

  return roofMesh;
};

var plainRoofParameters = (function () {
  let params = {};

  // params.roofDepth = store.projectProperties.properties.slabThicknessProperty.getValue();
  params.roofDepth = mmToSnaptrudeUnits(150);
  params.proximityThreshold = 1e-1;

  Object.defineProperty(params, "overhangBeforeUnion", {
    get: function overhangBeforeUnion() {
      // return 1e-1 - userSetBIMPropertiesHandler.getWallThickness() / 2;
      return 0;
    },
  });

  return params;
})();
export { plainroof,populateRoofBoundInfo,adjustHeightOfHalfRoofs,getOffsetValues,PlainRoof,plainRoofParameters };
