import { djangoUrl } from "../../../services/url.constants";
import CameraCursor from "../cursor/cameraCursor";
import { store } from "../utilityFunctions/Store";

const mouseMove = (function () {
  const CONSTANTS = {
      ANCHOR_OFFSET_X: 15,
      ANCHOR_OFFSET_Y: 10
  };

  let canvas;
  let socket;
  let collaborators = {};
  let pointers = {};
  let _broadcast = false;
  let pointer, pointerContainer, pointerAnchor;
  const availableColors = [
      'FF0642',
      'FFC200',
      'C90FEA',
      '0049FF',
      '1BC933',
      'FF6700',
      '571DB2',
      'FF00FF',
      '10C1B4',
      '8F00FF',
      '00B1FF',
  ];
  let usedColors = {};

  function init(skt) {
      canvas = document.getElementById('canvas');
      socket = skt;
      pointerContainer = document.getElementById("pointers");
      pointer = document.createElement("div");
      pointer.setAttribute("class", "pointer");
      pointerAnchor = document.createElement("span");
      pointerAnchor.setAttribute("class", "pointer-anchor");

      socket.on("moving", _receive);
      socket.on("optoutcollab", clientDisconnect);
  }

  function _transmit(e) {
      if(_broadcast){
          socket.emit("mousemove", {
              x: e.offsetX / canvas.getBoundingClientRect().width,
              y: e.offsetY / canvas.getBoundingClientRect().height,
              user: store.infoUser
          });
      }
  }

  function _receive(data) {
      if(_broadcast){
          if (!(data.id in collaborators)) {
              pointers[data.id] = {};
              pointers[data.id].pointer = pointerContainer.appendChild(pointer.cloneNode());
              pointers[data.id].pointerAnchor = pointerContainer.appendChild(pointerAnchor.cloneNode());
              pointers[data.id].pointer.style.position = "fixed";
              pointers[data.id].pointerAnchor.style.position = "fixed";
              const color = assignColor(data.user.id);
              pointers[data.id].pointer.style.background = `url('${djangoUrl}/static/cursors/${color}.svg')`;
              pointers[data.id].pointerAnchor.style.backgroundColor = '#' + color;
          }

          pointers[data.id].pointer.style.left = (data.x * canvas.getBoundingClientRect().width) + "px";
          pointers[data.id].pointer.style.top = (data.y * canvas.getBoundingClientRect().height) + "px";

          pointers[data.id].pointerAnchor.innerHTML = data.user.name;
          pointers[data.id].pointerAnchor.style.left = (data.x * canvas.getBoundingClientRect().width) + CONSTANTS.ANCHOR_OFFSET_X + "px";
          pointers[data.id].pointerAnchor.style.top = (data.y * canvas.getBoundingClientRect().height) + CONSTANTS.ANCHOR_OFFSET_Y + "px";
          collaborators[data.id] = data;
      }
  }

  function deleteAllClients(){
      for(const id in collaborators){
          clientDisconnect(id);
      }
  }

  function clientDisconnect(id) {
      delete collaborators[id];
      if (pointers[id]) {
          try{
              delete usedColors[pointers[id].pointerAnchor.style.color];
              pointerContainer.removeChild(pointers[id].pointer);
              pointerContainer.removeChild(pointers[id].pointerAnchor);
              // pointers[id].parentNode.removeChild(pointers[id].pointer);
              // pointers[id].parentNode.removeChild(pointers[id].pointerAnchor);
          } catch(err){
              console.log('Error in deleting pointer anchor collaboration');
          }
      }
  }

  function getCollaborators() {
      return collaborators;
  }

  function getPointers() {
      return pointers;
  }

  function broadcast() {
      _broadcast = true;
      canvas.addEventListener("mousemove", _transmit);
  }

  function unbroadcast() {
      _broadcast = false;
      deleteAllClients();
      if(canvas) canvas.removeEventListener("mousemove", _transmit);
      if(socket) socket.emit('requestoptoutcollab');
      _resetVariables();
  }

  function _resetVariables() {
      collaborators = {};
      pointers = {};
      if(pointerContainer) pointerContainer.innerHTML = '';
      usedColors = {};
  }

  function assignColor(userid) {
    const cameraCursor = new CameraCursor();
    const color = cameraCursor.getColorOfUser(userid);
    if(color){
        return color.toUpperCase();
    }
      let randomNumber = Math.floor(Math.random() * availableColors.length);
      if(usedColors[availableColors[randomNumber]]){
          randomNumber = Math.floor(Math.random() * availableColors.length);
      }
      usedColors[availableColors[randomNumber]] = true;
      return availableColors[randomNumber];
  }

  function toggleBroadcast(){
      if(_broadcast){
          unbroadcast();
          return;
      } else {
          broadcast();
          return;
      }
  }

  return {
      init,
      getCollaborators,
      getPointers,
      broadcast,
      unbroadcast,
      deleteAllClients,
      toggleBroadcast,
  };
})();

export {
  mouseMove,
};



/*
import { djangoUrl } from "../../../services/url.constants";
import BABYLON from "../babylonDS.module";
import CameraCursor from "../cursor/cameraCursor";
import { getV3ProjectedOntoScreenSpace } from "../extrafunc";
import { scenePickController } from "../utilityFunctions/scenePickController";
import { store } from "../utilityFunctions/Store";

const mouseMove = (function () {
  const CONSTANTS = {
      ANCHOR_OFFSET_X: 15,
      ANCHOR_OFFSET_Y: 10,
      BASE_URL: 'https://api.snaptrude.com/media/media/objects/'
  };

  let canvas;
  let socket;
  let collaborators = {};
  let pointers = {};
  let _broadcast = false;
  let pointer, pointerContainer, pointerAnchor;
  const availableColors = [
      'FF0642',
      'FFC200',
      'C90FEA',
      '0049FF',
      '1BC933',
      'FF6700',
      '571DB2',
      'FF00FF',
      '10C1B4',
      '8F00FF',
      '00B1FF',
  ];
  let usedColors = {};

  function init(skt) {
      canvas = document.getElementById('canvas');
      socket = skt;
      pointerContainer = document.getElementById("pointers");
      pointer = document.createElement("div");
      pointer.setAttribute("class", "pointer");
      pointerAnchor = document.createElement("span");
      pointerAnchor.setAttribute("class", "pointer-anchor");

      socket.on("moving", _receive);
      socket.on("optoutcollab", clientDisconnect);
  }

  function _transmit(e) {
      const x = store.scene.pointerX;
      const y = store.scene.pointerY;
      const { pickedPoint } = scenePickController.pickInvisibleMeshes(function(mesh){
          return mesh.name === 'ground1'
      });
      if(pickedPoint){
        const {_x, _y, _z} = pickedPoint;
        const newObject = {
            x: _x,
            y: _y,
            z: _z,
            user: store.infoUser,
        };
        if(_broadcast){
            socket.emit("mousemove", newObject);
        }
      }
  }

  function _receive(data) {
      if(_broadcast){
          if (!(data.id in collaborators)) {
              pointers[data.id] = {};
              pointers[data.id].pointer = pointerContainer.appendChild(pointer.cloneNode());
              pointers[data.id].pointerAnchor = pointerContainer.appendChild(pointerAnchor.cloneNode());
              pointers[data.id].pointer.style.position = "fixed";
              pointers[data.id].pointerAnchor.style.position = "fixed";
              const color = assignColor(data.user.id);
              pointers[data.id].pointer.style.background = `url('${djangoUrl}/static/cursors/${color}.svg')`;
              pointers[data.id].pointerAnchor.style.backgroundColor = '#' + color;
          }

          const coords2D = getV3ProjectedOntoScreenSpace(new BABYLON.Vector3(data.x, data.y, data.z));
   
          pointers[data.id].pointer.style.left = coords2D.x + "px";
          pointers[data.id].pointer.style.top = coords2D.y + "px";
          pointers[data.id].pointerAnchor.innerHTML = data.user.name;
          pointers[data.id].pointerAnchor.style.left = coords2D.x + CONSTANTS.ANCHOR_OFFSET_X + "px";
          pointers[data.id].pointerAnchor.style.top = coords2D.y + CONSTANTS.ANCHOR_OFFSET_Y + "px";
          collaborators[data.id] = data;
      }
  }

  function deleteAllClients(){
      for(const id in collaborators){
          clientDisconnect(id);
      }
  }

  function clientDisconnect(id) {
      delete collaborators[id];
      if (pointers[id]) {
          try{
              delete usedColors[pointers[id].pointerAnchor.style.color];
              pointerContainer.removeChild(pointers[id].pointer);
              pointerContainer.removeChild(pointers[id].pointerAnchor);
              // pointers[id].parentNode.removeChild(pointers[id].pointer);
              // pointers[id].parentNode.removeChild(pointers[id].pointerAnchor);
          } catch(err){
              console.log('Error in deleting pointer anchor collaboration');
          }
      }
  }

  function getCollaborators() {
      return collaborators;
  }

  function getPointers() {
      return pointers;
  }

  function broadcast() {
      _broadcast = true;
      canvas.addEventListener("mousemove", _transmit);
  }

  function unbroadcast() {
      _broadcast = false;
      deleteAllClients();
      if(canvas){
        canvas.removeEventListener("mousemove", _transmit);
      }
      if(socket){
        socket.emit('requestoptoutcollab');
      }   
      _resetVariables();
  }

  function _resetVariables() {
      collaborators = {};
      pointers = {};
      if(pointerContainer){
        pointerContainer.innerHTML = '';
      }
      usedColors = {};
  }

  function assignColor(userid) {
      const cameraCursor = new CameraCursor();
      const color = cameraCursor.getColorOfUser(userid);
      if(color){
          return color.toUpperCase();
      }
      let randomNumber = Math.floor(Math.random() * availableColors.length);
      if(usedColors[availableColors[randomNumber]]){
          randomNumber = Math.floor(Math.random() * availableColors.length);
      }
      usedColors[availableColors[randomNumber]] = true;
      return availableColors[randomNumber];
  }

  function toggleBroadcast(){
      if(_broadcast){
          unbroadcast();
          return;
      } else {
          broadcast();
          return;
      }
  }

  return {
      init,
      getCollaborators,
      getPointers,
      broadcast,
      unbroadcast,
      deleteAllClients,
      toggleBroadcast,
  };
})();

export {
  mouseMove,
};




*/
