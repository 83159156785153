import BABYLON from "../babylonDS.module";
import { ComponentManager } from "../componentManager/componentManager";
import CursorFactory from "../cursor/cursorFactory";
import { markMeshAsThrowAway } from "../extrafunc";

const PERSON_CURSOR_URL = 'https://api.snaptrude.com/media/media/objects';

class Cursor3d{
    constructor(color, load = false){
        this.type = 'cursor3d';
        this.color = color.toLowerCase();
        this.personBabylonUrl = `${PERSON_CURSOR_URL}/${color}/${color}.babylon`;
        this.mesh = null;
        this.position = null;
        this.rootUrl = `${PERSON_CURSOR_URL}/${this.color}/`;
        if(load){
            this.loadComponent();
        }
    }

    loadComponent(){
        const componentData = [{
            rootUrl: this.rootUrl,
            name: this.color,
        }];
        ComponentManager.addTask(componentData, ComponentManager.FORMAT.BABYLON);
        ComponentManager.load(this.loadCallback);
    }

    loadCallback(tasks){
        try{
            if(tasks && tasks.length){
                const mesh = tasks[0].loadedMeshes[0];
                mesh.name = tasks[0].name;
                mesh.type = 'cursor3d';
                mesh.doNotSerialize = true;
                mesh.isVisible = false;
                const cursorFactory = new CursorFactory();
                cursorFactory.getCursorOfColor(mesh.name).setMesh(mesh);

                mesh.convertToFlatShadedMesh();
                mesh.showBoundingBox = false;
                if (mesh.material) {
                    mesh.material.backFaceCulling = false;
                    if (mesh.material.subMaterials) {
                        mesh.material.subMaterials.forEach(function (material) {
                            material.backFaceCulling = false;
                            material.opacityTexture = null;
                            if (material.diffuseTexture) {
                            material.diffuseColor = new BABYLON.Color3(0.7, 0.7, 0.7);
                            }
                        });
                    } 
                }
                mesh.computeWorldMatrix(true);

                // Use this once we start using instances
                // markMeshAsThrowAway(mesh);
                // mesh.scaling = BABYLON.Vector3.Zero();
                // mesh.position = new BABYLON.Vector3(10000, 10000, 10000);
            }
        } catch(err){
            console.log(`Error in loading 3D Cursor ${err}`);
        } finally{
            ComponentManager.reset();
        }
    }

    // addToStructureCollection(){
        
    // }

    setMesh(mesh){
        this.mesh = mesh;
    }

    activate(){
        if(this.mesh){
            this.mesh.isVisible = true;
            this.mesh.scaling = new BABYLON.Vector3(10,10,10);
        }
    }

    deactivate(){
        if(this.mesh){
            this.mesh.isVisible = false;
        }
    }

    updatePosition(newPosition){
        if(this.mesh){
            this.mesh.position = newPosition;
        }
    }
}

export { Cursor3d };
export default Cursor3d;
