import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Sidebar from "../../components/sidebar/index";
import { routes } from "../../routes/constants";
import WorkSpace from "./workspace/index";
import Tutorials from "./tutorials/index";
import Profile from "./profile/index";
import GuidedProjects from "./guidedprojects/index";
import Modals from "./modals";
import { PLANS } from "./profile/constants";
import { decryptJWT } from "../../services/jwt.service";
import { useDispatch } from "react-redux";
import { resetProjectProperties } from "../../snaptrude/stateManagers/reducers/objectProperties/projectPropertiesSlice";
import { resetObjectProperties } from "../../snaptrude/stateManagers/reducers/objectProperties/objectPropertiesSlice";
import { resetStoreys } from "../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice";
import { resetMaterials } from "../../snaptrude/stateManagers/reducers/objectProperties/materialsSlice";
import { resetScreenshotUiData } from "../../snaptrude/stateManagers/reducers/objectProperties/screenshotUiDataSlice";
import mixpanel from "mixpanel-browser";
import SharedWithMe from "../SharedWithMe";
import Libraries from "./Libraries";
import TeamDashboard from "./TeamDashboard";
import { teamsConnect } from "../../snaptrude/modules/socket/teamSocket";
import ProfilePage from "./profile/Profile";
import { useHistory } from "react-router-dom";
import FileSystemProvider from "../../contexts/FileSystemContext";
import TeamRightClickMenuProvider from "../../contexts/TeamRightClickMenuContext";
import PersonalDashboard from "./PersonalDashboard";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { SIDEBAR_WIDTH } from "../../common/utils/constants";
import authService from "../../services/auth.service";

const StyledDashboard = styled.div`
  background: ${(props) => props.theme.colors.midWhite};
  display: flex;
  justify-content: flex-start;
  .content {
    padding: 1rem 3rem;
    width: 100%;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    &>div{
      flex-basis: 28%;
      margin: 1%;
    }
    // --gap: 2rem;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(18.4375rem, 1fr));
    // grid-gap: var(--gap);
    // margin-top: 1.5rem;
    // justify-content: space-between;
  }
  // .cards > div {
  //   background-color: ${(props) => props.theme.colors.fullWhite};
  //   max-height: 18rem;
  // }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }
  .header-div {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 1rem;
    align-items: center;
  }
  .project-header {
    display: flex;
    margin-bottom: 0rem;
    align-items: center;
  }
  .project-header h3 {
    font-family: ${(props) => props.theme.font};
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: 1.938rem;
    letter-spacing: -0.021rem;
    color: ${(props) => props.theme.colors.primeBlack};
    opacity: 0.8;
  }
  .create-project-graphic{
    position: absolute;
    left: ${SIDEBAR_WIDTH+15}px;
    top: 70px;
    z-index: 100;
  }
`;

const Dashboard = ({ selectedOption, workspaceHeading }) => {
  const [projectsCount, setProjectsCount] = useState(0);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const handleInviteModalClose = () => { setIsInviteModalOpen(false) }
  const handleInviteModalOpen = () => { setIsInviteModalOpen(true) }
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    // RESET REDUX STORE.
    dispatch(resetProjectProperties());
    dispatch(resetObjectProperties());
    dispatch(resetStoreys());
    dispatch(resetMaterials());
    dispatch(resetScreenshotUiData());
    const isUserPresent = Boolean(localStorage.getItem("user"));
    if(!isUserPresent){
      history.push(routes.login)
    }
  }, []);
  const getBodyWrtPath = () => {
    const currentPath = window.location.pathname;
    const profilePath =
      currentPath === routes.profile ||
      currentPath === routes.account ||
      currentPath === routes.teams ||
      currentPath === routes.plans ||
      currentPath === routes.billing;
    if (profilePath) return <ProfilePage selectedOption={selectedOption} />;
    else if (currentPath === routes.tutorials) {
      window.analytics.track('clicked tutorials');
      return <Tutorials />;
    }
    else if (currentPath === routes.guidedProjects) {
      window.analytics.track('clicked sample projects on dashboard');
      return <GuidedProjects />;
    }
    else if (currentPath === routes.libraries) {
      return <Libraries />
    }
    else if (currentPath.startsWith(routes.team.slice(0, -8))) {
      return <TeamDashboard projectsCount={projectsCount} />
    }
    else if (currentPath === routes.modals) {
      return <Modals />;
    }
    else if(currentPath.startsWith(routes.dashboard)){
      return <PersonalDashboard />
    }
    else return <PersonalDashboard />
  };

  return (
    <StyledDashboard>
      <TeamRightClickMenuProvider>
        <FileSystemProvider>
          <DndProvider backend={HTML5Backend}>
          {selectedOption !== PLANS && <Sidebar
            projectsCount={projectsCount}
            isInviteModalOpen={isInviteModalOpen}
            handleInviteModalClose={handleInviteModalClose}

          />}
          {getBodyWrtPath()}
          </DndProvider>
        </FileSystemProvider>
      </TeamRightClickMenuProvider>
    </StyledDashboard>
  );
};

Dashboard.propTypes = {
  selectedOption: PropTypes.any,
  workspaceHeading: PropTypes.string,
};

export default Dashboard;
