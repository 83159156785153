import { data } from "jquery";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { store } from "./Store";
const zeroCounts = {
    curtainwalls: 0,
    doors: 0,
    floors: 0,
    furnitures: 0,
    masses: 0,
    beams: 0,
    columns: 0,
    roofs: 0,
    staircases: 0,
    walls: 0,
    windows: 0,
}
const typeMapping = {
    "Column": "columns",
    "Beam": "beams",
    "Wall": "walls",
    "Window": "windows",
    "Staircase": "staircases",
    "Roof": "roofs",
    "Slab": "roofs",
    "Furniture": "furnitures",
    "Floor": "floors",
    "Flooring": "floors",
    "Door": "doors",
}
const snaptrudeDSCount = (() => {
    let flyweight = null;
    let counts = {
        curtainwalls: 0,
        doors: 0,
        floors: 0,
        furnitures: 0,
        masses: 0,
        beams: 0,
        columns: 0,
        roofs: 0,
        staircases: 0,
        walls: 0,
        windows: 0,
    }
    const init = () => {
        if(!store.activeLayer) return false;
        counts = zeroCounts;
        const structures = StructureCollection.getInstance().getStructures();
        const levelsContainer = structures[store.activeLayer.structure_id];
        if(!levelsContainer) return false;
        flyweight = levelsContainer.getAllLevels()["01"].flyweight;
        for(let item in flyweight){
            if(counts.hasOwnProperty(item)){
                counts[item] = flyweight[item].length;
            }
        }
        flyweight.masses.forEach(mass => {
            if(mass.massType === "Beam") ++counts.beams;
            else if(mass.massType === "Column") ++counts.columns;
        })
        return true;
    }
    const getLabel = (name) => {
        if(flyweight===null) {
            const isInitialised = init();
            if(!isInitialised) return name;
        } 
        const type = typeMapping[name];
        ++counts[type];
        const number = "" + counts[type];
        const label = name + "-" + number.padStart(3, "0");
        return label;
    }

    return {
        init,
        getLabel
    }
    
})();

export default snaptrudeDSCount;