import { data } from "jquery";
import { getDimensionInSnaptrudeUnits } from ".";
import { createCustomMesh } from "../../libs/massModeling";
import { setLayerTransperancy } from "../../libs/sceneFuncs";
import { recreateStandardMaterial } from "../../libs/sceneStateFuncs";
import { drawCircle } from "../../libs/twoD/twoDrawCircle";
import BABYLON from "../babylonDS.module";
import { Command } from "../commandManager/Command";
import { CommandManager } from "../commandManager/CommandManager";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { onSolid } from "../extrafunc";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  convertFacesToSnaptrude,
  convertVerticesToSnaptrude,
  createCustomMeshFromRevitExport,
  createMaterialFromSpeckleData,
  createMeshWithMeshData,
  getGeometryDataFromReferenceData,
  getMaterialIndex,
  getMaterialUrl,
  getMeshDataFromReferenceData,
  saveDataInBackend,
} from "./extraFunctions";
import speckleConstants from "./speckleConstants";
import { checkStorey } from "./storey";

const getColumnData = (data) => {
  let columnData = data.filter(
    (data) =>
      data.speckle_type ===
      "Objects.BuiltElements.Column:Objects.BuiltElements.Revit.RevitColumn"
  );
  return columnData;
};

const createColumn = (
  columns,
  columnTypes,
  columnsGeometryData,
  materialList
) => {
  let columnsData = [];
  let columnData;
  let columnsMesh = [];
  columns.forEach((column) => {
    try {
      // if (column.elementId == "17339409") {
      if (typeof column == "string") {
        column = JSON.parse(column);
        if (
          column?.createWithGeometry &&
          columnsGeometryData[column["elementId"]]
        ) {
          columnData = createColumnWithMeshData(
            columnsGeometryData[column["elementId"]]["data"],
            columnsGeometryData[column["elementId"]],
            materialList
          );
        } else {
          columnData = createRectangularColumn(column);
        }

        if (columnData) {
          for (let i = 0; i < columnTypes.length; ++i) {
            if (columnTypes[i][column.type]) {
              columnData.mesh.materialInfo = columnTypes[i][column.type];
              break;
            }
          }

          columnData.mesh.revitType = column.type;
          columnsData.push(columnData);
        }
      }
    } catch (e) {
      console.log(e);
    }
    // }
  });

  columnsData.forEach((column) => {
    columnsMesh.push(column.mesh);
  });

  columnsData.forEach(async function (column) {
    try {
      let newMaterial = false,
        columnMat;
      columnMat = store.scene.getMaterialByName(
        `${column.mesh?.materialInfo?.value?.name}`
      );

      if (!columnMat) {
        if (column.mesh?.materialInfo?.value?.name) {
          columnMat = createMaterialFromSpeckleData(
            column.mesh.materialInfo,
            column.mesh?.materialInfo?.value?.name,
            materialList,
            { category: column.mesh.name }
          );
        }
      }
      if (columnMat) {
        let materialIndex,
          isNewMaterial = false;
        if (column.mesh.getSnaptrudeDS()) {
          let object = column.mesh.getSnaptrudeDS();
          ({ materialIndex, isNewMaterial } = getMaterialIndex(
            column.mesh,
            columnMat
          ));
          column.mesh.subMeshes.forEach(function (subMesh) {
            subMesh.materialIndex = materialIndex;
          });
          if (object.brep) {
            let faces = object.brep.getFaces();
            faces.forEach(function (face) {
              face.materialIndex = materialIndex;
            });
          }
        }
        // if(column.mesh.material  && isNewMaterial){
        //   saveMaterialInBackend(column.mesh.material)
        // }
      }
    } catch (e) {
      console.log(e);
    }
  });

  return { columnsMesh };
};

const getColumnFromBaseObject = (data) => {
  let columns = [];
  data.forEach((column) => {
    columns.push(column.referencedId);
  });
  return columns;
};

const createColumnWithMeshData = (data, columnData, materialList) => {
  const columnMesh = createCustomMeshFromRevitExport(
    data,
    columnData,
    materialList,
    { isMassType: true }
  );

  columnMesh.structure_id = store.activeLayer.structure_id;
  let column = new Mass(columnMesh);
  columnMesh.type = "Mass"; //throwAway is overwritten when mesh is added to level
  column.room_type = "column";
  column.massType = "Column";
  column.assignProperties();
  column.revitMetaData.elementId = data.elementId;
  column.revitMetaData.family = data.family;
  column.mesh.isVisible = true;
  columnMesh.storey = 1;
  column.height = columnMesh.height;
  // let center = basePoint.map(point => DisplayOperation.getOriginalDimension(point, speckleConstants.speckleUnits[unit]));
  // columnMesh.position = new BABYLON.Vector3(center[0], center[2], center[1]);
  // columnMesh.computeWorldMatrix();

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  talkingAboutLevel.addObjectToLevel(column, false);
  StoreyMutation.assignStorey(column);
  // onSolid(columnMesh);
  // setLayerTransperancy(columnMesh);
  return column;
};

const createCircularColumn = (data) => {
  let height = data.baseLine.end.z - data.baseLine.start.z;
  let x = DisplayOperation.getOriginalDimension(
    data.baseLine.start.x,
    "millimeter"
  );
  let y = DisplayOperation.getOriginalDimension(
    data.baseLine.start.y,
    "millimeter"
  );
  let z = DisplayOperation.getOriginalDimension(
    data.baseLine.start.z,
    "millimeter"
  );
  height = DisplayOperation.getOriginalDimension(height, "millimeter");
  let radius = DisplayOperation.getOriginalDimension(
    data.parameters.b.value / 2,
    "millimeter"
  );
  let circleds = drawCircle(
    { _x: x, _y: z, _z: y },
    radius,
    { _x: 0, _y: 0, _z: 1 },
    z,
    height
  );
  console.log(circleds);
};

const createRectangularColumn = (
  data,
  options = { isStructuralColumn: false }
) => {
  let column,
    bottomFace = [];
  try {
    let height = DisplayOperation.getOriginalDimension(
      data.height,
      "millimeter"
    );
    if (data.columnBottomFace) {
      bottomFace = data.columnBottomFace;
    } else if (data.bottomFace) {
      bottomFace = data.bottomFace;
    }
    let points = getDimensionInSnaptrudeUnits(bottomFace, "millimeter");

    if (!points.length) return;

    const createdMesh = createCustomMesh(points, height, null);
    createdMesh.structure_id = store.activeLayer.structure_id;
    column = new Mass(createdMesh);
    createdMesh.type = "mass"; //throwAway is overwritten when mesh is added to level
    createdMesh.name = data.type;
    column.room_type = "column";
    column.massType = "Column";
    column.assignProperties();
    column.mesh.isVisible = true;
    createdMesh.storey = 1;
    column.height = createdMesh.height;
    column.structure_id = store.activeLayer.structure_id;
    column.storey = 1;
    if (options.isStructuralColumn && !data.materialInfo["value"]) {
      createdMesh.material = store.scene.getMaterialByName("solid_mat");
    }

    column.revitMetaData = {
      elementId: data.elementId,
      type: data?.type,
      family: data?.family,
    };

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure = structureCollection.getStructureById(
      store.activeLayer.structure_id
    );
    const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
    talkingAboutLevel.addObjectToLevel(column, false);
    const storey = checkStorey(data?.levelName);
    if (storey) {
      storey.addElement(column);
    } else {
      StoreyMutation.assignStorey(column);
    }
    onSolid(createdMesh);
    setLayerTransperancy(createdMesh);
  } catch (e) {
    console.log(e);
    return;
  }
  return column;
};

const createStructuralColumns = (columnsData) => {
  if (!columnsData) return;
  let structuralColumnsData = [];
  for (let id in columnsData) {
    const structuralColumn = createRectangularColumn(columnsData[id].data, {
      isStructuralColumn: true,
    });
    if (structuralColumn) {
      structuralColumnsData.push(structuralColumn.mesh);
    }
  }
  return { structuralColumnsData };
};

export {
  createColumn,
  getColumnData,
  getColumnFromBaseObject,
  createStructuralColumns,
};
