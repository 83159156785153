import { handleKeyEvents } from "../../libs/keyEvents";
import { store } from "../utilityFunctions/Store";
import { delayedExecutionEngine } from "../utilityFunctions/delayedExecution";
import { AutoSave } from "../socket/autoSave";
import { autoSaveConfig } from "../socket/autoSaveConfig";
import { showToast } from "../extrafunc";

const contextmenu = function (evt) {
  evt.preventDefault();
}

const enabledKeysForAnalysisPage = {
  27: true // escape key
}

const keydown = function (e) {
  if(store.isCoveToolProject) {
    const key = e.which || e.keyCode
    if(!(key in enabledKeysForAnalysisPage)) return
  }
  handleKeyEvents(e);
}

const resize = function () {
  store.engine.resize();
}

const beforeunload = function (e) {
  // if you add a breakpoint here, it'll stop when tab is closed but not when page reloads.
  // But the code is executed nevertheless.
  // This is done by chrome and they say it's a feature not a bug
  delayedExecutionEngine.executeAll();
  e.preventDefault();
  if(AutoSave.getQueue().length > 1) {
      showToast(`Hang tight... ${AutoSave.getQueue().length} steps still are auto saving...`, 0, autoSaveConfig.CONSTANTS.toastError);
      e.returnValue = '';
  } else if(AutoSave.getQueue().length == 1) {
      showToast(`Hang tight... Your last step is still auto saving...`, 0, autoSaveConfig.CONSTANTS.toastError);
      e.returnValue = '';
  } else {
      delete e['returnValue'];
  }
}

export {
  contextmenu,
  keydown,
  resize,
  beforeunload,
};

