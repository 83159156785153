import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import Button from "../../components/Button/index";
import ScrollableDropdown from "../../components/ScrollableDropdown/index";
import { useDropzone } from "react-dropzone";
import upload from "../../assets/images/upload.svg";
import GenericModal from "./index";
import ProgressBar from "../../components/ProgressBar";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1.75rem;
  .leftHoverable {
    width: 100%;
    .overlayContainer {
      display: none;
    }
    &:hover {
      .overlayContainer {
        display: flex;
        margin-top: -0.25rem;
      }
      .bottomFooter {
        opacity: 0;
      }
      .dropdown {
        opacity: 0;
      }
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    margin: 0 0.9375rem;
  }
`;
const Header = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  margin: 0;
`;

const RowDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0.9375rem 0 0 0;
  .extension {
    font-size: 12px;
    line-height: 16px;
    color: ${colors.thinGray};
    text-align: center;
    margin: 0;
  }
`;

const DescriptionText = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

const SelectUploadButton = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  white-space: nowrap;
  background-color: ${colors.transparent};
  color: ${colors.brightRed};
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
`;
const FooterWrapper = styled(StyledDiv)`
  width: 100%;
  align-items: center;
`;

const DropDownWrapper = styled(StyledDiv)`
  flex-direction: column;
  padding: 0.625rem 0.5rem;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  width: 40%;
`;

const InnerWrapper = styled(StyledDiv)`
  flex-direction: column;
  position: relative;
  .mainOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 140%;
    width: 33.125rem;
    align-self: center;
    background-color: ${colors.fullWhite};
    justify-content: center;
  }
  .innerOverlay {
    height: 100%;
    width: 100%;
    border: 2px dashed ${colors.borderRed};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .imageIconsContainer {
    padding-top: 1.5625rem;
  }
`;

export const DropdowndownDropzone = (props) => {
  const { customProps } = props;
  const {
    header,
    images,
    dropdownList,
    singleUpload,
    subHeader,
    headerFont,
  } = customProps;
  const [files, appendFiles] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [modalShow, setmodalShow] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (singleUpload) {
        appendFiles({
          file: acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
        setmodalShow(true);
      } else {
        acceptedFiles.map((file) => {
          appendFiles((prevstate) => {
            let newArray = prevstate.slice();
            newArray.push({ file, preview: URL.createObjectURL(file) });
            setmodalShow(true);
            return newArray;
          });
          return true;
        });
      }
      return;
    },
    [singleUpload]
  );

  const removeFile = (index) => {
    appendFiles((prevstate) => {
      let newArray = [];
      prevstate.map((item, innerindex) => {
        if (innerindex !== index) newArray.push(item);
        return true;
      });
      return newArray;
    });
    return;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      <Container
        style={{
          justifyContent: dropdownList ? "space-between" : "center",
        }}
      >
        <>
          <input {...getInputProps()} />
          <InnerWrapper
            style={dropdownList ? { width: "60%" } : { width: "100%" }}
            className="leftHoverable"
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => {
              setHovered(false);
            }}
            {...getRootProps()}
          >
            <>
              {header ? (
                <Header
                  style={{
                    paddingTop: dropdownList ? 0 : "0.625rem",
                    fontSize: headerFont ? headerFont : "12px",
                  }}
                >
                  {header}
                </Header>
              ) : null}
              {subHeader ? (
                <Header style={headerFont ? { fontSize: headerFont } : {}}>
                  {subHeader}
                </Header>
              ) : null}
              <RowDiv className="imageIconsContainer">
                {images.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item.src} alt={item.extension} />
                      <p className="extension">{item.extension}</p>
                    </div>
                  );
                })}
              </RowDiv>
              <RowDiv>
                <SelectUploadButton className="uploadSelect">
                  Select from Computer
                </SelectUploadButton>
                <DescriptionText>
                  &nbsp; or &nbsp;Drag and drop your file here
                </DescriptionText>
              </RowDiv>
            </>
            <div className="overlayContainer mainOverlay">
              <div className="innerOverlay">
                <img src={upload} alt="upload" />
                <Header>Drop files to upload</Header>
              </div>
            </div>
          </InnerWrapper>
        </>
        <DropDownWrapper
          className="dropdown"
          style={{
            opacity: hovered ? 0 : 1,
          }}
        >
          {dropdownList &&
            dropdownList.map((item, index) => {
              return (
                <TopContainer
                  key={index}
                  style={{ justifyContent: "space-between" }}
                >
                  <TopContainerLabel>{item.label}</TopContainerLabel>
                  <ScrollableDropdown
                    options={item.options}
                    height={item.height || "auto"}
                    width="auto"
                    cutomTextStyle={{ fontWeight: 700, width: "7.5rem" }}
                  />
                </TopContainer>
              );
            })}
        </DropDownWrapper>
      </Container>
      <GenericModal
        modalType={
          singleUpload ? "IMPORT SINGLE FILE OVERVIEW" : "IMPORT FILE OVERVIEW"
        }
        onClose={() => {
          setmodalShow(false);
        }}
        custonModalStyle={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(87, 87, 88, 0)",
            zIndex: 11,
          },
          content: {
            position: "fixed",
            top: "56%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            border: "none",
            borderRadius: 0,
            padding: 0,
            background: "transparent",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={modalShow}
        modalHeaderText={"Import Material"}
        customProps={{
          files,
          removeFile,
          leftTitle: singleUpload ? "Upload" : "Import",
          rightTitle: singleUpload ? "Change Image" : "Modify Selection",
        }}
      />
    </>
  );
};

export const ViewsModalFooter = (props) => {
  const { customProps, onClose } = props;
  const { rightTitle, leftTitle, rightTitleOnPress, leftTitleOnPress, customLeftTitleStyle, leftTitleIcon, leftTitleComponent } = customProps;

  const [showProgressBar, setShowProgressBar] = useState(false);

  const [progress, setProgress] = useState(0);
  const handleLeftTitlePress = () => {
    if(leftTitleOnPress) leftTitleOnPress();
    else onClose();
  }

  const fakeProgressBar = () => {
    if(progress < 10) setTimeout(() => {setProgress(40)}, 1);
  }

  const handleRightTitlePress = () => {
      if(rightTitleOnPress){
        const promise = rightTitleOnPress();
        if(!promise) return;
        setShowProgressBar(true);
        fakeProgressBar();
        promise.then(res => {
            setProgress(100);
            setTimeout(()=> {
                setProgress(0);
                setShowProgressBar(false);
                onClose();
            }, 1000)          

        })
      } 
      else{
        console.log("Pass a rightTitleOnPress prop")
      }
  }
  return (
    <Container style={{ width: "100%" }}>
        {
            showProgressBar ? 
            <ProgressBar bgColor={colors.red} completed={progress} text={"Exporting"}/>
            :
            <FooterWrapper className="bottomFooter">
                {
                leftTitleComponent ? leftTitleComponent :
                leftTitle ? (
                <Button
                    onPress={handleLeftTitlePress}
                    customButtonStyle={ customLeftTitleStyle ? customLeftTitleStyle : 
                    {
                        backgroundColor: colors.transparent,
                        color: colors.dullgrey,
                        padding: 0,
                        marginRight: "0.3125rem",
                        width: "auto",
                    }}
                    image={leftTitleIcon}
                    title={`${leftTitleIcon ? "" : "<"}${leftTitle}`}
                />
                ) : (
                <div />
                )}
                {rightTitle ? (
                <Button
                    customButtonStyle={{
                    width: "11.25rem",
                    height: "2.5rem",
                    }}
                    onPress={handleRightTitlePress}
                    primary={true}
                    title={rightTitle}
                />
                ) : (
                <div />
                )}
            </FooterWrapper>
        }
    </Container>
  );
};
