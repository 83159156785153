import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';
import { TEAM_ROLES } from '../../containers/dashboard/TeamDashboard/constants';

const Wrapper = styled.div`
    z-index: 50;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 200px;
    padding: 10px 0px;
    position: fixed;
    left: ${props => props.x}px;
    top: ${props => props.y}px;
    font-weight: 400;
    font-size: 12.5px;
    color: #202124;
    .menu-item{
        padding: 3px 25px 3px 25px;
        color: black;
        // margin: 4px 0px;
        cursor: pointer;
    }
    .menu-item:hover{
        background-color: #F2F2F2;
    }
`
function TeamContextMenu({x, y, hideMenu, handleClose, setShowRenameModal, setShowDeleteConfirmationModal, setShowLeaveConfirmationModal, contextMenuTeam}) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current.addEventListener("contextmenu", handleContextMenu);
        return () => {
          ref.current.removeEventListener("contextmenu", handleContextMenu);
        }
    }, []);
    useOutsideClickHandler(ref, handleClose);
    
    const handleContextMenu = (evt) => {
        evt.preventDefault();
    }
    const handleRenameSelect = () => {
        hideMenu();
        setShowRenameModal(true);
    }
    const handleDeleteSelect = () => {
        hideMenu();
        setShowDeleteConfirmationModal(true);
    }
    const handleLeaveSelect = () => {
        hideMenu();
        setShowLeaveConfirmationModal(true)
    }

    const ADMIN_CONTEXT_MENU = [
        {title: "Rename", handleClick: handleRenameSelect},
        {title: "Delete", handleClick: handleDeleteSelect},
        {title: "Leave", handleClick: handleLeaveSelect}
    ]
    const MEMBER_CONTEXT_MENU = [
        {title: "Leave", handleClick: handleLeaveSelect}
    ]
    const menuList = contextMenuTeam.role === TEAM_ROLES.ADMIN ? ADMIN_CONTEXT_MENU : MEMBER_CONTEXT_MENU
    return ( 
        <Wrapper x={x} y={y} ref={ref}>
            {
                menuList?.map(menuItem => {
                    return <div key={menuItem.title} className="menu-item" onClick={menuItem.handleClick}>
                        {menuItem.title}
                    </div>
                })
            }
        </Wrapper>
     );
}

export default TeamContextMenu;