import { colors } from "../../themes/constant";
import Text from "../../components/Text";
import styled from "styled-components";

// signup
export const paymentStyles = {
  termsAndConditionsButton: {
    backgroundColor: colors.transparent,
    color: colors.brightRed,
    padding: "0 0.25rem",
    margin: 0,
  },
  disclaimerText: {
    color: colors.primeBlack,
    opacity: 0.8,
    fontSize: "0.775rem",
    margin: "0 0.25rem",
  },
  checkoutButton: {},
  disabledCheckoutButton: { backgroundColor: "#A5A5A5" },
  widthStyle: {
    width: "45%",
  },
  rowWidth: {
    width: "11rem",
  },
  savingText: {
    fontSize: "0.813rem",
    color: colors.green,
  },
  inputMainWrapper: {
    justifyContent: "flex-start",
  },
  inputStyle: {
    width: "3.125rem",
  },
  buttonContainer: {
    width: "45%",
    paddingTop: "1.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  zipContainer: {
    width: "45%",
  },
  addressContainer: {
    margin: "0.5rem 0 0 0",
  },
  countryInput: {
    justifyContent: "space-between",
    background: colors.white,
    border: "none",
  },
  countryInputWrapper: {
    marginTop: "0.5rem",
  },
  cardNameContainer: {
    margin: "0.5rem 0 0 0",
  },
  yearContainer: {
    marginTop: "1.8rem",
  },
  monthContainer: {
    marginRight: "0.5rem",
  },
  customDropDownStyle: {
    width: "100%",
  },
};

export const MainWrapper = styled.div`
  height: 100%;
  width: 60vw;
  font-family: ${(props) => props.theme.font};
  margin: 1rem 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: ${(props) => props.theme.font};
`;

export const ArrowContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fullWhite};
  box-shadow: 0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey};
  border-radius: 2.063rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0 1em 0;
  border: 0 solid ${colors.veryLightGrey};
  border-bottom-width: 1px;
`;

export const Heading = styled.div`
  font-family: ${(props) => props.theme.font};
  color: ${colors.primeBlack};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1rem;
  line-height: 1.313rem;
  margin-left: 1rem;
  opacity: 0.8;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DataHeading = styled.p`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.021rem;
  color: ${colors.mediumGrey};
`;

export const ValueText = styled.p`
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  font-size: 1.375rem;
  color: ${colors.primeBlack};
  margin: 0;
  padding: 0;
  opacity: 0.8;
`;

export const CustomText = styled(Text)`
  display: ${({ d }) => d};
  font-family: ${(props) => props.theme.font};
  margin-right: ${({ marginRight }) => marginRight};
  margin: ${({ margin }) => margin};
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.theme.fontWeight[props.weight]};
  text-align: ${({ textAlign }) => textAlign};
  opacity: ${({ opacity }) => opacity};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const ButtonWrapper = styled.div`
  padding: 0.5rem 0 0.5rem 0;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
`;

export const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 0.813rem;
  letter-spacing: -0.021rem;
  color: ${(props) => props.theme.colors.mustard};
  max-width: 24rem;
`;

export const SuccessMessage = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 0.813rem;
  letter-spacing: -0.021rem;
  color: ${(props) => props.theme.colors.green};
  max-width: 24rem;
`;

export const ErrorContainer = styled.div`
  position: absolute;
  bottom: 0;
`;

export const StyledDropdown = styled.div`
  .dropdown {
    position: relative;
    display: inline-block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.3125em;
    background: ${colors.white};
    color: ${colors.primeBlack};
    outline: 0;
    border: none;
    padding: 0.75rem;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    top: 40px;
    left: 0px;
    box-shadow: 0px 8px 16px 0px ${colors.black02};
    z-index: 1;
    width: 100%;
    background-color: ${(props) => props.theme.colors.fullWhite};
    padding: 0.5rem 0;
    p {
      padding: 0.5rem 0.75rem;
      &:hover {
        background-color: ${(props) => props.theme.colors.white};
      }
    }
  }
  .dropdown:hover .dropdown-content {
    // display: block;
  }
  p {
    font-family: ${(props) => props.theme.font};
    font-size: ${(props) => props.theme.fontSizes.tiny};
    font-weight: 500;
    opacity: 0.8;
    margin: 0;
  }
`;