import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}
const editorNotificationSlice = createSlice({
    name: "editorNotification",
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        setNotification: (state, action) => {
            const {type, count} = action.payload;
            state[type] = count;
        },
        unsetNotification: (state, action) => {
            const {type} = action.payload;
            state[type] = 0;
        }
    }
})

export const {
    reset,
    setNotification,
    unsetNotification
} = editorNotificationSlice.actions;
export default editorNotificationSlice.reducer;
