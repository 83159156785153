/*jshint esversion: 6 */
"use strict";
import { meshObjectMapping } from "./mapping.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { plainFloorParameters } from "../factoryTypes/floor.types.js";
import { SnapProperty } from "../../libs/GUI/snap_properties.js";
import { SnapElement } from "../../libs/GUI/snap_element.js";
import { doorOperation } from "../meshoperations/doorOperation.js";
import { store } from "../utilityFunctions/Store.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import {doorProperties} from "../../libs/objectProperties";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService.js";
import { StructureCollection } from "./structure.ds.js";
/**
 * { Door datastructure }
 *
 * @class      Door (name)
 * @param      {Object}  mesh    The mesh
 */
var Door = function (mesh) {
  this.mesh = mesh;
  this.level = mesh.level;
  this.level_low = 0;
  this.level_hight = 0;
  this.height = 0;

  this.structure_id = mesh.structure_id;
  this.id = "d_" + Math.random().toString(36).substr(2, 9);
  this.type = "Door";
  this.mesh.type = "Door";
  this.subType = null;
  this.material = "";
  this.onclick = null;
  this.groupId = null;
  this.storey = null;
  this.mesh.showBoundingBox = false;
  this.mesh.childrenComp = [];
  this.storey = this.mesh.storey;
  this.edited = false;
  this.isLocked = false;
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  this.properties = Object.assign({}, doorProperties);
  this.revitMetaData = {};

  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  if(!this.mesh.room_type){
    this.mesh.room_type = snaptrudeDSCount.getLabel("Door");
  }

  this.onMove = {
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (evt) {
      //pass
    },

    onPointerMove: function (direction, diff, speed) {
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.name.indexOf("boxScale") != -1) {
        this.mesh.parentMesh.position = this.mesh.position;
      }
    },
  };
};


Door.prototype.cloneProperties = function (otherMass, unique = true) {
  otherMass.revitMetaData = this.revitMetaData;

  if (otherMass.revitMetaData.elementId) {
    otherMass.revitMetaData.isModified = true;
    otherMass.revitMetaData.sourceElementId = this.revitMetaData.elementId;
    delete otherMass.revitMetaData.elementId;
  }
};

/**
 * Remove a mass to level.
 *
 * @param      {<type>}  object  The object
 */
Door.prototype.removeDoorFromLevel = function (level) {
  meshObjectMapping.deleteMapping(this.mesh);
  for (let i = 0; i < level.flyweight.doors.length; i++) {
    if (this.mesh.uniqueId === level.flyweight.doors[i].mesh.uniqueId) {
      level.flyweight.doors.splice(i, 1);
      return;
    }
  }
};
/**
 * Make object visible in scene
 */
Door.prototype.show = function () {
  this.mesh.isVisible = true;
};
/**
 * Hides object in scene
 */
Door.prototype.hide = function () {
  this.mesh.isVisible = false;
};

Door.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
Door.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
Door.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};

Door.prototype.getMeshObjectProperties = function () {
  let props = [];
  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);

  let meshName = this.mesh.name;
  meshName = meshName.replace(/Ins[0-9]+/g, "");
  let lintelHeight = 0;
  if (this.revitMetaData?.elementId) {
    lintelHeight = DisplayOperation.convertToDefaultDimension(
      DisplayOperation.getOriginalDimension(meshDimensions.height)
    );
  } else {
    lintelHeight = DisplayOperation.convertToDefaultDimension(
      DisplayOperation.getOriginalDimension(meshDimensions.height) +
        plainFloorParameters.floorDepth
    );
  }
  
  props.push(
    new SnapProperty(
      "Category",
      "Door",
      SnapElement.getInputElement(null, false)
    )
  );
  let type;
  if(this.revitMetaData?.elementId && this.revitMetaData?.type){
    type = this.revitMetaData?.type
  }else{
    type = this.mesh.name
  }
  props.push(
    new SnapProperty(
      "Type",
      type,
      SnapElement.getInputElement(null, false)
    )
  );
  if(this.revitMetaData?.family){
    props.push(
      new SnapProperty(
        "Family",
        this.revitMetaData?.family,
        SnapElement.getInputElement(null, false)
      )
    );
  }
  props.push(
    new SnapProperty(
      "Label",
      this.mesh.room_type,
      SnapElement.getInputElement(
        (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
        true
      )
    )
  );
  props.push(
    new SnapProperty(
      "Width",
      meshDimensions.depth,
      SnapElement.getInputElement((meshs, value) => {
        return objectPropertiesView.dimensionChange("thickness", value, meshDimensions.depth), doorOperation.setDoor2d(this.mesh);
      }, true)
    )
  );
  props.push(
    new SnapProperty(
      "Lintel Height",
      lintelHeight,
      SnapElement.getInputElement((meshs, value) => {
        return objectPropertiesView.dimensionChange("height", value, lintelHeight), doorOperation.setDoor2d(this.mesh);
      }, true)
    )
  );
  let storey = StructureCollection.getInstance().getStructureById(this.mesh.structure_id).getStoreyData().getStoreyByValue(this.mesh.storey).name;
  let storeyValue = storey? storey : this.mesh.storey;

  props.push(
    new SnapProperty(
      "Storey",
      storeyValue,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(new SnapProperty("object-buttons", this.mesh, null, true));

  return props;
};

Door.prototype.setPlanViewSymbol = function () {
  if (this.mesh.isAnInstance) {
    doorOperation.setDoor2d(this.mesh);
  }
};

/**
 * Check if mesh is door or window.
 * Temporarily adding door/window check in door.ds.js
 * TODO: Move method to common module
 */
Door.isMeshDoorOrWindow = function (mesh) {
  return ["door",
    "window",
    "doorthrowaway",
    "windowthrowaway"
  ].includes(mesh.type.toLowerCase());
};

export { Door };
