import BABYLON from "../babylonDS.module.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { getPointsOnArc } from "../../libs/twoD/twoDrawCircle.js";
/**
 * CAD parser for Snaptrude.
 * Typical use case:
 * 1. let cadImporter = new parseCAD();
 * 2. cadImporter.loadJSON(cadJSON);
 * 3. cadImporter.setAllowedLayersSubstring(["wall"]);
 * 4. cadImporter.generateScaledEdgeArray();
 * 5. cadImporter.get1DScaledEdgeArray();
 *
 *
 * Some assumptions/tips about this module:
 * -> Methods prefixed with "get" just fetch those respective variables. Meaning, one might want to "generate" a variable
 *    before using it. Example, one might want to use generateEdgeArray() before using getEdgeArray().
 *
 *
 * @returns {{swapYAndZOfEdgeArray: (function(*=): Array), generateScaledEdgeArray: (function(*=): boolean), getEdgeArray: (function(): Array), getLayersDefinedInJSON: (function(): T[]), getScaledEdgeArray: (function(): Array), getAllowedLayers: (function(): Array), setScaleFactor: (function(*): boolean), get1DScaledEdgeArray: (function(): any[]), loadJSON: (function(*): boolean), get1DEdgeArray: (function(*=): (any[] | any[])), addAllowedLayersSubstring: (function(*): boolean), getScaleFactor: (function(): number), flushAllData: (function(): boolean), flushAllowedLayers: (function(): boolean), generateEdgeArray: (function(): boolean), getJSON: (function(): *), setAllowedLayersSubstring: (function(*): boolean), addAllowedLayerSubstring: (function(*=): boolean)}}
 */
const parseCAD = function () {
  let cadJSON = null;
  let scaleFactor = 0;
  let allowedInclusions = [];
  let edgeArray = [];
  let scaledEdgeArray = [];

  /**
   * Makes the module empty again, for reuse.
   * @returns {boolean}
   */
  const flushAllData = () => {
    cadJSON = null;
    scaleFactor = 0;
    allowedInclusions = [];
    edgeArray = [];
    scaledEdgeArray = [];
    scaledPointHash.flush();
    return true;
  };

  /**
   * Imports a JSON into the module. Also calculates scaleFactor.
   * @param JSONofCAD
   * @returns {boolean}
   */
  const loadJSON = (JSONofCAD) => {
    cadJSON = JSONofCAD;
    const scaleFactorArray = [
      1,
      1 / 0.254 / 0.0254,
      (1 / 0.254 / 0.0254) * 12,
      1 / 0.254,
      1 / 0.254 / 1000,
      1 / 0.254 / 100,
      1 / 0.254,
      (1 / 0.254) * 1000,
    ];
    scaleFactor = 1 / scaleFactorArray[0];
    return true;
    /* eslint-disable */
    if (cadJSON.unitsCode < 7)
      scaleFactor = scaleFactorArray[cadJSON.unitsCode];
    //const scaleFactor = getScaleFactorFromUnit(cadJSON.unit);
    return true;
    /* eslint-enable */
  };

  /**
   * Gives the JSON.
   * @returns {*}
   */
  const getJSON = () => {
    return cadJSON;
  };

  /**
   * Gives the calculated scaleFactor during loadJSON().
   * It is the relation between real world units and Snaptrude units.
   * @returns {number}
   */
  const getScaleFactor = () => {
    return scaleFactor;
  };

  /**
   * Allows to manually set scaleFactor.
   * @param sf
   * @returns {boolean}
   */
  const setScaleFactor = (sf) => {
    scaleFactor = sf;
    return true;
  };

  /**
   * Empties the list of allowed layers.
   * @returns {boolean}
   */
  const flushAllowedLayers = () => {
    allowedInclusions = [];
    return true;
  };

  /**
   * Gives the list of "allowed" layers. Allowed layers are the susbtrings which will be searched through in
   * cadJSON while doing stuff like generateEdgeArray, etc. In simple terms, these are the layers you are talking
   * about, and the module will skip the layers which do not contain one of the given substrings.
   * @returns {Array}
   */
  const getAllowedLayers = () => {
    return allowedInclusions;
  };

  /**
   * Allows you to "replace" the allowed layers list. Make sure not to use this method as much as possible,
   * and if you do, make sure that you pass a list of strings here.
   * @param layerArray
   * @returns {boolean}
   */
  const setAllowedLayersSubstring = (layerArray) => {
    allowedInclusions = layerArray;
    return true;
  };

  /**
   * Appends a single layer to the list of allowed layers.
   * @param layer
   * @returns {boolean}
   */
  const addAllowedLayerSubstring = (layer) => {
    allowedInclusions.push(layer);
    return true;
  };

  /**
   * Appends a list of layers to the list of current allowed layers.
   * @param layerArray
   * @returns {boolean}
   */
  const addAllowedLayersSubstring = (layerArray) => {
    allowedInclusions.push(...layerArray);
    return true;
  };

  /**
   * Gives the layers defined in the cadJSON.
   * @returns {T[]}
   */
  const getLayersDefinedInJSON = () => {
    const layerSet = new Set();
    for (let i = 0; i < cadJSON["geometry"].length; i++) {
      layerSet.add(cadJSON["geometry"][i]["layer"]);
    }
    return Array.from(layerSet);
  };

  /**
   * Gives the edgeArray.
   * Please note, this does not calculate the edgeArray, so you might want to generate it before trying to get it.
   * @returns {Array}
   */
  const getEdgeArray = () => {
    return edgeArray;
  };

  /**
   * Gives the scaledEdgeArray (edgeArray * scaleFactor).
   * Please note, this does not caculate the scaledEdgeArray, so you might want to generate it before trying to
   * get it.
   * @returns {Array}
   */
  const getScaledEdgeArray = () => {
    return scaledEdgeArray;
  };

  const getScaledPointArray = () => {
    return scaledPointHash.toArray();
  }

  /**
   * Generates the edgeArray based on the loaded JSON and the allowed layers.
   * @returns {boolean}
   */
  const generateEdgeArray = () => {
    edgeArray = [];
    for (let i = 0; i < cadJSON["geometry"].length; i++) {
      const entity = cadJSON["geometry"][i];
      const layer = entity["layer"];
      if (allowedInclusions.some((v) => layer.includes(v))) {
        if (entity.type.toLowerCase() === "polyline") {
          addPolylineToEdgeArray(entity);
        }
        if (entity.type.toLowerCase() === "line") {
          addLineToEdgeArray(entity["startPoint"], entity["endPoint"]);
        }
        if (entity.type.toLowerCase() === "multiline") {
          addMultiLineToEdgeArray(entity.vertices);
        }
        if (entity.type.toLowerCase() === "circle") {
          addCircleToEdgeArray(entity);
        }
        if (entity.type.toLowerCase() === "arc") {
          addArcToEdgeArray(entity);
        }
      }
    }
    return true;
  };

  /**
   * Gives the scaledEdgeArray.
   * If passed with a boolean "true" parameter, this also generates the edgeArray before scaling it.
   * Otherwise, it just scales the already "generated" edgeArray.
   * @param shallISkipGenerateEdgeArray
   * @returns {boolean}
   */
  const generateScaledEdgeArray = (skipGenerateEdgeArray) => {
    if (!skipGenerateEdgeArray) generateEdgeArray();
    scaledEdgeArray = [];
    for (let i = 0; i < edgeArray.length; i++) {

      const edge = scaleEdge(edgeArray[i]);
      scaledEdgeArray.push(edge);
    }
    return true;
  };

  const scaleEdge = (edge) => {
    const newEdge = [];
    for (let j = 0; j < edge.length; j++) {
      const point = [];
      point.push(edge[j][0] * scaleFactor);
      point.push(edge[j][1] * scaleFactor * -1);
      newEdge.push(point);
    }
    return newEdge;
  }

  /**
   * Flattens out the edgeArray into a one dimensional array.
   * (First index is X value of first edge's first point,
   * second index is Y value of first edge's first point and so on)
   * @param arrayParam
   * @returns {any[]}
   */
  const get1DEdgeArray = (arrayParam) => {
    if (arrayParam === undefined || arrayParam === null || arrayParam === [])
      arrayParam = edgeArray;
    return arrayParam.flat(2);
  };

  /**
   * Gives the 1D scaledEdgeArray.
   * @returns {any[]}
   */
  const get1DScaledEdgeArray = () => {
    return get1DEdgeArray(scaledEdgeArray);
  };

  /**
   * Swaps second and third parameter of the edgeArray and returns it.
   * Please note, this does not modify the edgeArray itself.
   * Also, you can pass your own edgeArray to this method and get the result.
   * @param arrayParam
   * @returns {Array}
   */
  const swapYAndZOfEdgeArray = (arrayParam) => {
    if (arrayParam === undefined || arrayParam === null || arrayParam === [])
      arrayParam = edgeArray;

    const newEdgeArray = [];
    for (let i = 0; i < arrayParam.length; i++) {
      const edge = arrayParam[i];
      newEdgeArray.push([edge[0], edge[2], edge[1]]);
    }
    return newEdgeArray;
  };

  /**
   * Swaps second and third parameter of scaledEdgeArray and returns it.
   * @returns {Array}
   */
  const swapYAndZOfScaledEdgeArray = () => {
    return swapYAndZOfEdgeArray(scaledEdgeArray);
  };


  const addCircleToEdgeArray = (circleObject) => {
    const points = getPointsOnArc(BABYLON.Vector3.FromArray(circleObject.center), circleObject.radius, new BABYLON.Vector3(0, 0, 1), false, 30);
    const pointArray = points.map(point => point.asArray());

    for(let i = 0; i < pointArray.length; i++) {
      if (i + 1 >= pointArray.length) continue;

      _addLineToEdgeArray(pointArray[i], pointArray[i+1]);

      // addToScaledPointArray(pointArray[i])
      // addToScaledPointArray(pointArray[i+1])
    }
  };

  const addArcToEdgeArray = (arcObject) => {
    // const points = getPointsOnArc(BABYLON.Vector3.FromArray(arcObject.center),
    //   arcObject.radius,
    //   new BABYLON.Vector3(0, 0, 1),
    //   false,
    //   80,
    //   BABYLON.Vector3.FromArray(arcObject.startPoint),
    //   BABYLON.Vector3.FromArray(arcObject.endPoint),
    //   arcObject.angle);
    // let pointArray = points.map(point => point.asArray())

    // pointArray = scalePointArray(arcObject.center, pointArray, arcObject.scaleFactors || [1, 1, 1]);

    let pointArray = arcObject.pointsOnArc;

    for (let i = 0; i < pointArray.length; i++) {
      if (i + 1 >= pointArray.length) continue;

      _addLineToEdgeArray(pointArray[i], pointArray[i + 1]);

      // addToScaledPointArray(pointArray[i]);
      // addToScaledPointArray(pointArray[i + 1]);
    }
  };

  const scalePointArray = (center, pointArray, scaleFactors) => {
    return pointArray.map(p => {
      let _p = [p[0] - center[0], p[1] - center[1], p[2] - center[2]];
      _p = [_p[0] * scaleFactors[0], _p[1] * scaleFactors[1], _p[2] * scaleFactors[2]]
      _p = [_p[0] + center[0], _p[1] + center[1], _p[2] + center[2]];

      return _p;
    });
  };

  /**
   * Adds edges of polyline to the edgeArray.
   * @param polyLineObject
   * @returns {boolean}
   */
  const addPolylineToEdgeArray = (polyLineObject) => {
    const lineSegments = polyLineObject["lineSegments3d"];
    if (lineSegments) {
      for (let i = 0; i < lineSegments.length; i++) {
        _addLineToEdgeArray(lineSegments[i][0], lineSegments[i][1]);

        addToScaledPointArray(lineSegments[i][0]);
        addToScaledPointArray(lineSegments[i][1]);
      }
    }

    return true;
  };

  class HashMap {
    constructor(buildKey) {
      this.hashmap = {};
      this.buildKey = buildKey;
    }

    flush() {
      this.hashmap = undefined;
      this.buildKey = undefined;
    }

    get(object) {
      return this.hashmap[this.buildKey(object)];
    }

    push(object) {
      if (this.get[object]) {
        return false;
      } else {
        this.hashmap[this.buildKey(object)] = object;
      }
    }

    toArray() {
      return Object.values(this.hashmap);
    }
  }

  let arrayToHashKey = object => object.map(o => o.toFixed(3)).join(',')
  let scaledPointHash = new HashMap(arrayToHashKey);

  const addToScaledPointArray = (point)  => {
    scaledPointHash.push(_scalePoint(point))
  };

  const _scalePoint = (point) => [point[0] * scaleFactor, point[1] * scaleFactor * -1];

  /**
   * Adds line to the edgeArray.
   * @param startPoint
   * @param endPoint
   * @returns {boolean}
   */
  const addLineToEdgeArray = (startPoint, endPoint) => {
    edgeArray.push(scaleEdge([[startPoint[0], startPoint[1]], [endPoint[0], endPoint[1]]]));

    addToScaledPointArray(startPoint);
    addToScaledPointArray(endPoint);

    return true;
  };

  const _addLineToEdgeArray = (startPoint, endPoint) => {
    edgeArray.push(scaleEdge([[startPoint[0], startPoint[1]], [endPoint[0], endPoint[1]]]));

    return true;
  };

  /**
   * Adds Multi Line to the EdgeArray
   * */
  const addMultiLineToEdgeArray = (vertices) => {
    for (let i = 0; i < vertices.length - 1; i++) {
      _addLineToEdgeArray(vertices[i], vertices[(i + 1) % vertices.length]);

      addToScaledPointArray(vertices[i]);
      addToScaledPointArray(vertices[(i + 1) % vertices.length]);
    }

    return true;
  };

  /**
   * Gives the scaleFactor according to the input string passed to this method.
   * @param unit
   * @returns {number}
   */
  const getScaleFactorFromUnit = function (unit) {
    return DisplayOperation.getOriginalDimension(1, unit.toLowerCase());
  };

  return {
    getJSON,
    loadJSON,
    flushAllData,
    getAllowedLayers,
    setAllowedLayersSubstring,
    addAllowedLayersSubstring,
    addAllowedLayerSubstring,
    flushAllowedLayers,
    getLayersDefinedInJSON,
    generateEdgeArray,
    generateScaledEdgeArray,
    getEdgeArray,
    getScaledEdgeArray,
    getScaledPointArray,
    getScaleFactor,
    setScaleFactor,
    getScaleFactorFromUnit,
    get1DEdgeArray,
    get1DScaledEdgeArray,
    swapYAndZOfEdgeArray,
    swapYAndZOfScaledEdgeArray,
    scaledPointHash,
  };
};

export { parseCAD };
