import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Bulb0 from "../../assets/menu_icons/Bulb0.svg";
import dim_bulb from "../../assets/icons/dim_bulb.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import heightMapToggleOff from "../../assets/icons/heightMapToggleOff.svg"
import heightMapToggleOn from "../../assets/icons/heightMapToggleOn.svg"
import { useDispatch } from "react-redux";
import {
  showOrHideLayer,
  deleteLayer,
  enableOrDisableHeightMap
} from "../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice.js"
import { store } from "../../snaptrude/modules/utilityFunctions/Store.js";
import { terrainGeneration } from "../../snaptrude/modules/geo/terrainMap.js"
import {layerView} from "../../snaptrude/libs/layerView";
import {deleteCadLayer} from "../../snaptrude/modules/cadImporter/cadServices";
import {Command} from "../../snaptrude/modules/commandManager/Command";
import {StoreyMutation} from "../../snaptrude/modules/storeyEngine/storeyMutations";
import {CommandManager} from "../../snaptrude/modules/commandManager/CommandManager";
import {setActiveLayerAndRecord} from "../../snaptrude/modules/extrafunc";
import {StructureCollection} from "../../snaptrude/modules/snaptrudeDS/structure.ds";
import {
  deleteNeighborhoodLayer,
} from "../../snaptrude/modules/geo/terrainNeighborhood";
import { handleFloorPlanDeletionFromLayersUI } from "../../snaptrude/libs/twoDimension";
import {importPdfOperations} from "../../snaptrude/libs/importPdfOperations";
import _ from "lodash";
import {removeMeshSelectionBox} from "../../snaptrude/libs/meshEvents";

const Row = styled.div`
  // border: 2px solid;
  box-sizing: border-box;
  padding: 0.125em;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  .storey-title{
    font-weight: ${props => props.isSelected ? '700' : '400'};
  }
`
const Col = styled.div`
  // border: 2px solid crimson;
  display: flex;
  flex: ${props => props.flex ? props.flex : 0};
  align-items: ${props => props.alignStart ? "flex-start": "stretch"};
  .layerRowImage{
    width: 3rem;
  }
  .layerIcon{
    width: 0.85rem;
    padding: 5px;
    cursor: pointer;
  }
`

export const StoreyLayerRow = (props) => {
    const [lockOpen, setLockOpen] = useState(false);
    const [dimBulb, setDimBulb] = useState(false);
    const [heightMapToggle, setHeightMapToggle] = useState(false);
    const { key, item, setShowEditExpansion, setSelectedLayer, selectedLayer, isOpen } = props;
    const [isSelected, setIsSelected] = useState(false);
  
    useEffect(() => {
      let layer = layerView.getLayerFromLayerId(
        store.activeLayer.structure_id,
        1,
        item.id
      );
      if (layer && layer.terrain.length > 0)
        setHeightMapToggle(layer.terrain[0].parameters.subdivisionsX > 1);
    }, []);

    useEffect(() => {
      if(isOpen){
        setIsSelected(store.activeLayer.id === item.id);
      }      
    }, [isOpen]);
  
    const dispatch = useDispatch();
  
    let handleShowOrHideLayer = (layerId, storeyValue, operation) => {
      let _showOrHideLayer = function () {
        let data = this.data;
        let layer = layerView.getLayerFromLayerId(
          store.activeLayer.structure_id,
          data.storeyValue,
          data.layerId
        );
        if (!layer.hidden) {
          setDimBulb(true);
          layer.hide();
          dispatch(
            showOrHideLayer({
              layerId: data.layerId,
              storeyValue: data.storeyValue,
              operation: "hide",
            })
          );

          // remove selection box for layer items whenlayer is hidden
          if(!_.isEmpty(store.selectionStack)){
            store.selectionStack = store.selectionStack.filter(m => {
              const match = m?.layerName == layer?.name
              if(match) removeMeshSelectionBox(m)
              return !match
            })
          }
        } else {
          setDimBulb(false);
          layer.show();
          dispatch(
            showOrHideLayer({
              layerId: data.layerId,
              storeyValue: data.storeyValue,
              operation: "show",
            })
          );
        }
      };
  
      let _getCommandLogic = function () {
        return {
          execute: _showOrHideLayer,
          unexecute: _showOrHideLayer,
        };
      };
  
      let _executeEvent = function () {
        let _layerShowOrHideData = {
          layerId: layerId,
          storeyValue: storeyValue,
          operation: operation,
        };
  
        let layerHideCommand = new Command(
          "HideLayer",
          _layerShowOrHideData,
          _getCommandLogic(),
          StoreyMutation.hideOrShowLayerGetSaveData
        );
  
        CommandManager.execute(layerHideCommand, true);
      };
  
      _executeEvent();
    };
  
    let handleDeleteLayer = (layerId, layerName, storeyValue) => {
      let layerData = {
        id: layerId,
        structure_id: store.activeLayer.structure_id,
        name: layerName,
      };

      if(layerName.toLowerCase().includes("image")){
        dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
        handleFloorPlanDeletionFromLayersUI(layerData, storeyValue);
      }
      else if (layerName.includes("buildings")) {
        deleteNeighborhoodLayer(layerData, storeyValue);
      } 
      else if(layerName.includes("terrain")){
        dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
        terrainGeneration.handleTerrainDeletionFromLayersUI(layerData, storeyValue);
      }
      else if (layerName.includes("cad")){
        dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
        deleteCadLayer(layerData, storeyValue);
      }
      else if (layerName.includes("pdf")){
        dispatch(deleteLayer({ layerId: layerId, storeyValue: storeyValue }));
        importPdfOperations.handlePdfDeletionFromUI(layerData, storeyValue);
      }
    };
  
    let handleHeightMapToggle = (layerId, storeyValue, operation) => {
      let layer = layerView.getLayerFromLayerId(
        store.activeLayer.structure_id,
        storeyValue,
        layerId
      );
      if (layer) {
        if (operation) {
          dispatch(
            enableOrDisableHeightMap({
              layerId: layerId,
              storeyValue: storeyValue,
              operation: operation,
            })
          );
        } else {
          dispatch(
            enableOrDisableHeightMap({
              layerId: layerId,
              storeyValue: storeyValue,
              operation: operation,
            })
          );
        }
      }
      terrainGeneration.enableOrDisableHeightMap(
        layerId,
        storeyValue,
        store.activeLayer.structure_id,
        operation
      );
    };
  
    let handleActiveLayerSelection = (layerId, storeyValue) => {
      if (
        store.activeLayer.id !== layerId &&
        store.activeLayer.storey === storeyValue
      ) {
        setIsSelected(true);
        let layer = layerView.getLayerFromLayerId(
          store.activeLayer.structure_id,
          storeyValue,
          layerId
        );
        if (layer) {
          setActiveLayerAndRecord(layer);
        } else {
          let structure =
            StructureCollection.getInstance().getStructures()[
              store.activeLayer.structure_id
            ];
          let str = structure.getStoreyData().getStoreyByValue(storeyValue);
          if (str) {
            setActiveLayerAndRecord(
              str.layerData.getLayerByName("wall", storeyValue)
            );
          }
        }
      }
    };
  
    return (
      <Row isSelected={isSelected}>
        <Col flex={1}>
          <Row onClick={() => {handleActiveLayerSelection(item.id, item.storey)}} style={{cursor:"pointer"}}>
            <Col>
              <img
                src={item.image}
                alt="imagePic"
                className="layerRowImage"
                style={item.title.includes("pdf") ? {width: "2rem"} : {}}
              />
            </Col>
            <Col flex={1} style={{flexDirection: "column", padding: "0px 5px", justifyContent: "space-around"}}>
              <div className="storey-title">{item.title}</div>
              <div><i>Storey {item.storey}</i></div>
            </Col>
          </Row>
        </Col>
        <Col alignStart>
          <img
            className="layerIcon"
            src={dimBulb ? dim_bulb : Bulb0}
            alt="show/hide"
            onClick={() => {
              const op = dimBulb ? "show" : "hide";
              handleShowOrHideLayer(item.id, item.storey, op);
            }}
          />
        </Col>
        {<Col alignStart>
          <img
            className="layerIcon"
            src={deleteIcon}
            alt="delete"
            onClick={() => {
              handleDeleteLayer(item.id, item.title, item.storey);
            }}
          />
        </Col>
        }
        {/* {
          item.title.includes("cad") && <Col alignStart>
            <img
              className="layerIcon"
              src={deleteIcon}
              alt="delete"
              onClick={() => {
                handleDeleteLayer(item.id, item.title, item.storey);
              }}
            />
          </Col>
        }
        {
          item.title.includes("buildings") && <Col alignStart>
            <img
              className="layerIcon"
              src={deleteIcon}
              alt="delete"
              onClick={() => {
                handleDeleteLayer(item.id, item.title, item.storey);
              }}
            />
          </Col>
        } */}
        <Col style={{ minWidth: '23.6px' }} alignStart>
          {
            item.title.includes("terrain") &&
            <img
              className="layerIcon"
              src={heightMapToggle ? heightMapToggleOn : heightMapToggleOff}
              onClick={() => {
                const op = !heightMapToggle;
                handleHeightMapToggle(item.id, item.storey, op);
                setHeightMapToggle((prevState) => !prevState);
              }}
            />
          }
        </Col>
      </Row>
    )
};