import { store } from "../../modules/utilityFunctions/Store.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import { isTwoDimension } from "../twoDimension.js";
import { calculateAreaOfIrregularPolygons } from "../sceneFuncs.js";
const detectFailChecker = (function () {
  let allDetectFails = 0;
  let numberOfDetectFails = 0;
  let numberOfDetectSuccesses = 0;
  const numberOfAllowedFails = 3;
  // const userpilotTriggerKey = "1588230589xWep9521";

  const addFailure = () => {
    numberOfDetectFails++;
    allDetectFails++;
    return numberOfDetectFails;
  };

  const addFailureAndCheck = () => {
    addFailure();
    return trigger();
  };

  const addSuccess = () => {
    numberOfDetectSuccesses++;
    return numberOfDetectSuccesses;
  };

  const trigger = () => {
    if (numberOfDetectFails >= numberOfAllowedFails) {
      // userpilot.trigger(userpilotTriggerKey);
      resetFailures();
      return true;
    }
    return false;
  };

  const resetFailures = () => {
    numberOfDetectFails = 0;
    return numberOfDetectFails;
  };

  const getFailures = () => numberOfDetectFails;

  const getTotalFailures = () => allDetectFails;

  const checkIfPolygonHasTooManySides = (polygon, threshold) => {
    return polygon.length >= threshold;
  };

  const checkIfTooBig = (polygon) => {
    const structureCollection = StructureCollection.getInstance();
    const structure =
      structureCollection.getStructures()[store.activeLayer.structure_id];
    const storeyOfInterest = structure
      .getStoreyData()
      .getStoreyByValue(store.activeLayer.storey);
    const imageLayer = storeyOfInterest.layerData.getLayerByName(
      "image",
      store.activeLayer.storey
    );
    if (imageLayer) {
      if (imageLayer.floorplans.length > 0 && store.$scope.isTwoDimension) {
        const areaOfImage =
          imageLayer.floorplans[0].floorPlanImageHeight *
          imageLayer.floorplans[0].floorPlanImageWidth;
        const areaOfPolygon = calculateAreaOfIrregularPolygons(polygon);
        if (areaOfPolygon > 0.8 * areaOfImage) return true;
      }
    }
    return false;
  };

  const doTheUsuals = (polygon, areaOfImage, numberOfSidesThreshold = 15) => {
    if (checkIfPolygonHasTooManySides(polygon, numberOfSidesThreshold)) {
      addFailureAndCheck();
    } else if (checkIfTooBig(polygon)) {
      addFailureAndCheck();
    }
  };

  return {
    addSuccess,
    addFailure,
    addFailureAndCheck,
    getFailures,
    getTotalFailures,
    resetFailures,
    trigger,
    doTheUsuals,
  };
})();
export { detectFailChecker };
