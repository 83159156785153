import { store } from "../modules/utilityFunctions/Store.js";
var removeObjects = function () {
  // store.newScene.meshes.length;
  let len = store.newScene.meshes.length;
  var all_meshes = [];
  let mesh;
  for (let p = 0; p < len; p++) {
    mesh = store.newScene.meshes[p];
    // (mesh.name);
    if (
      mesh.name.indexOf("boxScale") == -1 &&
      mesh.name.indexOf("axis") == -1 &&
      mesh.name.indexOf("ground") == -1
    ) {
      all_meshes.push(mesh.name);
    }
  }
  for (let p = 0; p < all_meshes.length; p++) {
    var tmesh = store.scene.getMeshByName(all_meshes[p]);
    // (all_meshes.length);
    // if (tmesh.children){
    //   for (var q=0; q<tmesh.children.length; q++){
    //     tmesh[p].children[q].dispose();
    //   }
    // }
    if (tmesh) {
      tmesh.dispose();
    }
  }
};

var removeRooms = function () {
  // store.newScene.meshes.length;
  let len = store.newScene.meshes.length;
  var all_meshes = [];
  let mesh;
  for (let p = 0; p < len; p++) {
    mesh = store.newScene.meshes[p];
    // (all_meshes.length);
    // mesh.room_type, mesh.name, p;
    if (mesh.name != undefined) {
      if (
        mesh.name.indexOf("boxScale") == -1 &&
        mesh.name.indexOf("axis") == -1 &&
        mesh.name.indexOf("ground") == -1
      ) {
        if (store.types_of_rooms.indexOf(mesh.room_type) > -1) {
          all_meshes.push(mesh);
        }
      }
    }
  }
  for (let p = 0; p < all_meshes.length; p++) {
    if (all_meshes[p].children) {
      for (var q = 0; q < all_meshes[p].children.length; q++) {
        all_meshes[p].children[q].dispose();
      }
    }
    all_meshes[p].dispose();
  }
};

var getGroundPosition = function (evt) {
  // Use a predicate to get position on the ground
  let ground;
  var pickinfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh == ground;
    }
  );
  if (pickinfo.hit) {
    return pickinfo.pickedPoint;
  }
  return null;
};

var hideAxis = function () {
  var axisX = store.scene.getMeshByName("axisX");
  axisX.dispose();
  var axisY = store.scene.getMeshByName("axisY");
  axisY.dispose();
  var axisZ = store.scene.getMeshByName("axisZ");
  axisZ.dispose();
};
export { removeObjects, removeRooms, getGroundPosition, hideAxis };
