import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { isMeshThrowAway } from "../extrafunc.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { store } from "../utilityFunctions/Store.js";
import { getBottomFaceVertices } from "../../libs/brepOperations.js";
import { getAngleBetweenVectors } from "../../libs/snapFuncs.js";
import { meshUniqueIdMapper } from "../utilityFunctions/meshUniqueIdMapper";

const meshDiagnostics = (function () {
  const diagnoseUVs = function () {
    const problematicMeshes = [];
    _.forEach(
      StructureCollection.getInstance().getStructures(),
      (structure, structureId) => {
        const meshes = structure.getAllMeshes();
        meshes.forEach((m) => {
          if (!m.isAnInstance) {
            const uvs = m.getVerticesData(BABYLON.VertexBuffer.UVKind);
            if (
              !uvs ||
              uvs.includes(NaN) ||
              uvs.includes(null) ||
              uvs.includes(undefined)
            ) {
              problematicMeshes.push(m);
              console.log("UVs ", uvs);
              console.log("uniqueId ", m.uniqueId);
              console.log("Type ", m.type);
              console.log("-------");
            }
          }
        });
      }
    );

    return problematicMeshes;
  };

  const diagnoseStoreys = function () {
    const problematicComponents = [];
    _.forEach(
      StructureCollection.getInstance().getStructures(),
      (structure, structureId) => {
        const components = structure.getAllComponents();
        components.forEach((c) => {
          const isThrowAway = isMeshThrowAway(c.mesh);
          const hasStoreyNumber = c.storey && c.mesh.storey;
          const storeyObject = StoreyMutation.getAParticularStorey(c.storey);

          if (isThrowAway && hasStoreyNumber) {
              let metadata;
              if (storeyObject.elements.includes(c)) metadata = 'Also present in storey data';
              else metadata = 'Not present in storey data';

              problematicComponents.push(c);
              console.log('throwAway with storey - ', c.mesh.uniqueId, metadata);
          }
          else if (!isThrowAway && !hasStoreyNumber){
              let metadata;
              if (storeyObject.elements.includes(c)) metadata = 'But present in storey data';
              else metadata = 'Not present in storey data as well';

              problematicComponents.push(c);
              console.log('non-throwAway w/o storey - ', c.mesh.uniqueId, metadata);
          }
        });
      }
    );

    return problematicComponents;
  };

  const correctStoreys = function () {
    StoreyMutation.assignStorey(diagnoseStoreys());
  };

  const diagnoseAngles = function (component){

    if (!component) {
        if (_.isEmpty(store.selectionStack)) return;
        component = store.selectionStack[0].getSnaptrudeDS();
    }

    const vertices = getBottomFaceVertices(component);
    const length = vertices.length;
    vertices.forEach((vertex, i) => {
        const nextIndex = i === length - 1 ? 0 : i + 1;
        const previousIndex = i === 0 ? length - 1 : i - 1;

        const nextVertex = vertices[nextIndex];
        const previousVertex = vertices[previousIndex];

        const angle = getAngleBetweenVectors(
            previousVertex.subtract(vertex),
            nextVertex.subtract(vertex),
            true
        );

        console.log(vertex);
        console.log(angle);
        console.log('--');
    });
  };
  
  const diagnoseUniqueIDs = function (){
    meshUniqueIdMapper.validate();
  };

  return {
    diagnoseUVs,
    diagnoseStoreys,
    correctStoreys,
    diagnoseAngles,
    diagnoseUniqueIDs
  };
})();
export { meshDiagnostics };
