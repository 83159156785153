import React, { useState, useEffect } from "react";
import styled from "styled-components";
import backArrow from "../../../assets/icons/back_arrow.svg";
import Plan from "../../../components/Card/Plan";
import Text from "../../../components/Text";
import Toggle from "../../../components/Toggle";
import { planStyles } from "./styles";
import * as constants from "./constants";
import { withRouter } from "react-router-dom";
import { colors } from "../../../themes/constant";
import { routes } from "../../../routes/constants";
import { CURRENCY_SYMBOLS, PRICES } from "../../payment/constants";
import { getCurrency } from "../../../services/payment.service";
import mixpanel from "mixpanel-browser";
import authService from "../../../services/auth.service";
const intlNumber = new Intl.NumberFormat('en-US');
const Container = styled.div`
  width: 67.375rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const ArrowContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fullWhite};
  box-shadow: 0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey};
  border-radius: 2.063rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  border: 0 solid ${colors.veryLightGrey};
  border-bottom-width: 0.063rem;
  margin: 1rem 0 2rem 0;
`;

const Heading = styled.div`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.secondaryBlack};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 1rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const PlanWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32.1875rem;
`;

const SpanContainer = styled.span`
  margin-top: ${({ marginTop }) => marginTop};
  text-align: center;
`;

const CustomText = styled(Text)`
  display: ${({ d }) => d};
  margin-right: ${({ marginRight }) => marginRight};
  margin: ${({ margin }) => margin};
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.theme.fontWeight[props.weight]};
  text-align: ${({ textAlign }) => textAlign};
  opacity: ${({ opacity }) => opacity};
  font-size: ${({ size }) => size};
  font-family: ${(props) => props.theme.font};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
`;

const PerUserPerMonth = ({ toggleState }) => {
  return (
    <SpanContainer style={{ display: "block", textAlign: "center" }}>
      <CustomText
        d="inline"
        size="0.75rem"
        color="primeBlack"
        lineHeight="1rem"
      >
        {constants.PER_USER} / {toggleState === constants.MONTHLY
          ? constants.PER_MONTH
          : constants.PER_YEAR}
      </CustomText>
    </SpanContainer>
  )
}

function Plans(props) {
  const [toggleState, setToggleState] = useState(constants.MONTHLY);
  const [proPrice, setProPrice] = useState('---');
  const [orgPrice, setOrgPrice] = useState('---');
  const [currency, setCurrency] = useState('---');
  const [trialActionText, setTrialActionText] = useState('---');
  const { history, showBackButton = false } = props;

  useEffect(() => {
    window.analytics.track('opened plans page');
    getCurrency().then((currency) => {
      setProPrice(CURRENCY_SYMBOLS[currency] + intlNumber.format(PRICES.pro[currency][toggleState].unitAmount / 100));
      setOrgPrice(CURRENCY_SYMBOLS[currency] + intlNumber.format(PRICES.organization[currency][toggleState].unitAmount / 100));
      setCurrency(currency)
    });

    let currentUser = authService.getCurrentUser();
    if (currentUser.isPro) {
      setTrialActionText('');
    } else {
      setTrialActionText((3 - currentUser.numberOfProjects) + ' free projects remaining');
    }
  }, [])

  const contactUs = (planType) => {
    window.analytics.track(`clicked upgrade ${planType} on plans page`);
    window.open(routes.bookademo, "_blank");
  }

  const upgardeProPlan = () => {
    window.analytics.track('clicked upgrade professional on plans page');
    history.push({ pathname: routes.payment, state: { planName: "Professional", plan: 'pro', period: toggleState } });
  };

  const upgardeOrganizationPlan = () => {
    window.analytics.track('clicked upgrade organization on plans page');
    history.push({ pathname: routes.payment, state: { planName: "Organization", plan: 'organization', period: toggleState } });
  };

  const handleToggle = () => {
    let newState = constants.MONTHLY;

    if (toggleState === constants.MONTHLY) newState = constants.YEARLY;

    setToggleState(newState);

    setProPrice(CURRENCY_SYMBOLS[currency] + intlNumber.format(PRICES.pro[currency][newState].unitAmount / 100));
    setOrgPrice(CURRENCY_SYMBOLS[currency] + intlNumber.format(PRICES.organization[currency][newState].unitAmount / 100));
  };
  return (
    <Container>
      <HeaderWrapper>
        <Flex>
          {
            showBackButton &&
            <ArrowContainer
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={backArrow} width={8} height={11} alt="<" />
            </ArrowContainer>
          }
          <Heading>{constants.CHOOSE_PLAN_TEXT}</Heading>
        </Flex>
        <Flex justifyContent="space-around">
          <CustomText
            size="0.875rem"
            lineHeight="1.125rem"
            color="primeBlack"
            opacity={toggleState === constants.MONTHLY ? 1 : 0.6}
            weight={toggleState === constants.MONTHLY ? "bold" : "normal"}
          >
            {constants.MONTHLY_TEXT}
          </CustomText>
          <Toggle handleClick={handleToggle} state={toggleState === constants.YEARLY} />
          <CustomText
            size="0.875rem"
            lineHeight="1.125rem"
            color="primeBlack"
            opacity={toggleState === constants.YEARLY ? 1 : 0.6}
            weight={toggleState === constants.YEARLY ? "bold" : "normal"}
          >
            {constants.YEARLY_TEXT}
          </CustomText>
        </Flex>
      </HeaderWrapper>
      <PlanWrapper>
        <Plan
          style={planStyles}
          selected
          type={
            <CustomText
              textAlign="center"
              size="0.9375rem"
              lineHeight="1.25rem"
              color="greyishBlack"
              weight="500"
              opacity="1"
            >
              {constants.FREE}
            </CustomText>
          }
          price={
            <TextContainer>
              <SpanContainer marginTop="0.313rem">
                <CustomText
                  textAlign="center"
                  margin={0}
                  size="2rem"
                  lineHeight="2.1rem"
                  color="primeBlack"
                  weight="bold"
                >
                  {CURRENCY_SYMBOLS[currency] + constants.FREE_PRICE}
                </CustomText>
                <PerUserPerMonth toggleState={toggleState} />
              </SpanContainer>
            </TextContainer>
          }
          actionText={trialActionText}
          checkList={constants.PLANS_CHECKLIST.FREE}
        />
        <Plan
          style={planStyles}
          buttonAction={() => {contactUs(constants.PROFESSIONAL)}}
          type={
            <CustomText
              textAlign="center"
              size="0.9375rem"
              lineHeight="1.25rem"
              color="greyishBlack"
              weight="500"
              opacity="1"
            >
              {constants.PROFESSIONAL}
            </CustomText>
          }
          price={
            <TextContainer>
              <SpanContainer marginTop="0.313rem">
                <CustomText
                  d="inline"
                  lineHeight="2rem"
                  color="primeBlack"
                  size="2rem"
                  weight="bold"
                  marginRight="0.3125rem"
                >
                  {proPrice}
                </CustomText>
                <PerUserPerMonth toggleState={toggleState} />
              </SpanContainer>
            </TextContainer>
          }
          actionText={constants.PROFESSIONAL_BUTTON_TEXT}
          primary
          checkList={constants.PLANS_CHECKLIST.PROFESSIONAL}
        />
        <Plan
          style={planStyles}
          buttonAction={() => {contactUs(constants.ORGANIZATION)}}
          type={
            <CustomText
              textAlign="center"
              size="0.9375rem"
              lineHeight="1.25rem"
              color="greyishBlack"
              weight="500"
              opacity="1"
            >
              {"ORGANIZATION"}
            </CustomText>
          }
          price={
            <TextContainer>
              <SpanContainer marginTop="0.313rem">
                <CustomText
                  d="inline"
                  lineHeight="2rem"
                  color="primeBlack"
                  size="2rem"
                  weight="bold"
                  marginRight="0.3125rem"
                >
                  {orgPrice}
                </CustomText>
                <PerUserPerMonth toggleState={toggleState} />
              </SpanContainer>
            </TextContainer>
          }
          actionText={constants.PROFESSIONAL_BUTTON_TEXT}
          primary
          checkList={constants.PLANS_CHECKLIST.ORGANIZATION}
        />
        <Plan
          style={planStyles}
          buttonAction={() => {contactUs(constants.ENTERPRISE)}}
          type={
            <CustomText
              textAlign="center"
              size="0.9375rem"
              lineHeight="1.25rem"
              color="greyishBlack"
              weight="500"
              opacity="1"
            >
              {constants.ENTERPRISE}
            </CustomText>
          }
          price={
            <TextContainer>
              <SpanContainer marginTop="0.313rem">
                <CustomText
                  d="inline"
                  lineHeight="2rem"
                  color="primeBlack"
                  size="2rem"
                  weight="bold"
                  marginRight="0.3125rem"
                >
                  {constants.ENTERPRISE_PRICE}
                </CustomText>
                <PerUserPerMonth toggleState={toggleState} />
              </SpanContainer>
            </TextContainer>
          }
          actionText={constants.PROFESSIONAL_BUTTON_TEXT}
          primary
          checkList={constants.PLANS_CHECKLIST.ENTERPRISE}
        />
      </PlanWrapper>
    </Container>
  );
}

export default withRouter(Plans);
