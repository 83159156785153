import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { offsetRoomPols } from "./sketch/wall_generation.js";
function bezierPointsFromPath(path) {
  var path = JSON.parse(path);
  var curPoint = [];
  var ribPaths = [];
  var temp_path1 = [];
  var temp_path2 = [];
  var temp_path3 = [];
  var temp_path4 = [];
  var temp_path = [];
  for (var j = 0; j < path.length; j++) {
    if (path[j][0] == "M") {
      var curPoint1 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        -path[j][2] * store.unit_scale,
        0
      );
      var curPoint2 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        0,
        -path[j][2] * store.unit_scale
      );
      var curPoint3 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        -path[j][2] * store.unit_scale,
        store.floor_height
      );
      var curPoint4 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        store.floor_height,
        -path[j][2] * store.unit_scale
      );
      temp_path1.push(curPoint1);
      temp_path2.push(curPoint2);
      temp_path3.push(curPoint3);
      temp_path4.push(curPoint4);
      temp_path.push([path[j][1], path[j][2]]);
    }
    if (path[j][0] == "L") {
      var pt1 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        -path[j][2] * store.unit_scale,
        0
      );
      var pt2 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        0,
        -path[j][2] * store.unit_scale
      );
      var pt3 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        -path[j][2] * store.unit_scale,
        store.floor_height
      );
      var pt4 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        store.floor_height,
        -path[j][2] * store.unit_scale
      );

      temp_path1.push(pt1);
      temp_path2.push(pt2);
      temp_path3.push(pt3);
      temp_path4.push(pt4);

      curPoint1 = pt1;
      curPoint2 = pt2;
      curPoint3 = pt3;
      curPoint4 = pt4;
      temp_path.push([path[j][1], path[j][2]]);
    } else if (path[j][0] == "C") {
      var control1 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        -path[j][2] * store.unit_scale,
        0
      );
      var control2 = new BABYLON.Vector3(
        path[j][3] * store.unit_scale,
        -path[j][4] * store.unit_scale,
        0
      );
      var dest1 = new BABYLON.Vector3(
        path[j][5] * store.unit_scale,
        -path[j][6] * store.unit_scale,
        0
      );
      var bezier3 = BABYLON.Curve3.CreateCubicBezier(
        curPoint1,
        control1,
        control2,
        dest1,
        10
      );
      curPoint1 = dest1;
      var temp_pts = bezier3.getPoints();
      for (var k = 0; k < temp_pts.length; k++) {
        temp_path1.push(temp_pts[k]);
      }

      var control1 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        0,
        -path[j][2] * store.unit_scale
      );
      var control2 = new BABYLON.Vector3(
        path[j][3] * store.unit_scale,
        0,
        -path[j][4] * store.unit_scale
      );
      var dest2 = new BABYLON.Vector3(
        path[j][5] * store.unit_scale,
        0,
        -path[j][6] * store.unit_scale
      );
      var bezier3 = BABYLON.Curve3.CreateCubicBezier(
        curPoint2,
        control1,
        control2,
        dest2,
        10
      );
      curPoint2 = dest2;
      var temp_pts = bezier3.getPoints();
      for (var k = 0; k < temp_pts.length; k++) {
        temp_path2.push(temp_pts[k]);
      }

      var control1 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        -path[j][2] * store.unit_scale,
        store.floor_height
      );
      var control2 = new BABYLON.Vector3(
        path[j][3] * store.unit_scale,
        -path[j][4] * store.unit_scale,
        store.floor_height
      );
      var dest3 = new BABYLON.Vector3(
        path[j][5] * store.unit_scale,
        -path[j][6] * store.unit_scale,
        store.floor_height
      );
      var bezier3 = BABYLON.Curve3.CreateCubicBezier(
        curPoint3,
        control1,
        control2,
        dest3,
        10
      );
      curPoint3 = dest3;
      var temp_pts = bezier3.getPoints();
      for (var k = 0; k < temp_pts.length; k++) {
        temp_path3.push(temp_pts[k]);
      }

      var control1 = new BABYLON.Vector3(
        path[j][1] * store.unit_scale,
        store.floor_height,
        -path[j][2] * store.unit_scale
      );
      var control2 = new BABYLON.Vector3(
        path[j][3] * store.unit_scale,
        store.floor_height,
        -path[j][4] * store.unit_scale
      );
      var dest4 = new BABYLON.Vector3(
        path[j][5] * store.unit_scale,
        store.floor_height,
        -path[j][6] * store.unit_scale
      );
      var bezier3 = BABYLON.Curve3.CreateCubicBezier(
        curPoint4,
        control1,
        control2,
        dest4,
        10
      );
      curPoint4 = dest4;
      var temp_pts = bezier3.getPoints();
      console.log(temp_pts);
      for (var k = 0; k < temp_pts.length; k++) {
        temp_path4.push(temp_pts[k]);
      }
      temp_path.push([path[j][1], path[j][2]]);
      temp_path.push([path[j][3], path[j][4]]);
      temp_path.push([path[j][5], path[j][6]]);
    }
  }
  return temp_path;
}

function offsetCurvePath(path, h) {
  var json_path = JSON.parse(path);
  var newPath = [];
  var points = [];
  var typeArray = [];
  for (var i = 0; i < json_path.length; i++) {
    var type = json_path[i][0];
    typeArray.push(type);
    if (type === "M" || type === "L") {
      points.push([json_path[i][1], json_path[i][2]]);
    }
    if (type === "C") {
      for (var j = 1; j < json_path[i].length; j += 2) {
        points.push([json_path[i][j], json_path[i][j + 1]]);
      }
    }
  }
  var newPoints = offsetRoomPols(points, h);
  newPoints.splice(0, 0, newPoints[newPoints.length - 1]);
  newPoints.pop();
  //console.log(newPoints);

  var newPointsIdx = 0;
  for (let i = 0; i < typeArray.length; ) {
    type = typeArray[i];
    if (type === "M" || type === "L") {
      newPath.push([
        type,
        newPoints[newPointsIdx][0],
        newPoints[newPointsIdx][1],
      ]);
      newPointsIdx++;
    } else if (type === "C") {
      newPath.push([
        type,
        newPoints[newPointsIdx][0],
        newPoints[newPointsIdx][1],
        newPoints[newPointsIdx + 1][0],
        newPoints[newPointsIdx + 1][1],
        newPoints[newPointsIdx + 2][0],
        newPoints[newPointsIdx + 2][1],
      ]);
      newPointsIdx += 3;
    }
    i++;
  }
  return newPath;
}

function generatePointsFromPath(path) {
  var json_path = JSON.parse(path);
  var points = [];
  var pointsInArray = [];
  for (var i = 0; i < json_path.length; i++) {
    var type = json_path[i][0];

    if (type === "M" || type === "L") {
      points.push(
        new BABYLON.Vector2(
          json_path[i][1] * store.unit_scale,
          -json_path[i][2] * store.unit_scale
        )
      );
    }
    if (type === "C") {
      var controlPoints = [];
      if (json_path[i - 1][0] === "M" || json_path[i - 1][0] === "L")
        controlPoints.push([json_path[i - 1][1], json_path[i - 1][2]]);
      else if (json_path[i - 1][0] === "C")
        controlPoints.push([json_path[i - 1][5], json_path[i - 1][6]]);

      for (var j = 1; j < json_path[i].length; j += 2) {
        controlPoints.push([json_path[i][j], json_path[i][j + 1]]);
      }

      var start = new BABYLON.Vector2(
        controlPoints[0][0] * store.unit_scale,
        -controlPoints[0][1] * store.unit_scale
      );
      var c1 = new BABYLON.Vector2(
        controlPoints[1][0] * store.unit_scale,
        -controlPoints[1][1] * store.unit_scale
      );
      var c2 = new BABYLON.Vector2(
        controlPoints[2][0] * store.unit_scale,
        -controlPoints[2][1] * store.unit_scale
      );
      var end = new BABYLON.Vector2(
        controlPoints[3][0] * store.unit_scale,
        -controlPoints[3][1] * store.unit_scale
      );

      var bezier3 = BABYLON.Curve3.CreateCubicBezier(start, c1, c2, end, 60);
      var bezierPoints = bezier3.getPoints();

      bezierPoints.forEach(function (vec) {
        points.push(vec);
      });
    }
  }
  // points.push(new BABYLON.Vector3(json_path[0][1] * store.unit_scale, 0, -json_path[0][2] * store.unit_scale));
  // pointsInArray.push(new BABYLON.Vector3(json_path[0][1] * store.unit_scale, 0, -json_path[0][2] * store.unit_scale));
  return points;
}

function pointsFromPath(path) {
  var path = JSON.parse(path);
  var pointType = [];
  var temp_path1 = [];
  for (var j = 0; j < path.length; j++) {
    if (path[j][0] == "M") {
      temp_path1.push([path[j][1], path[j][2]]);
      pointType.push("M");
    }
    if (path[j][0] == "L") {
      temp_path1.push([path[j][1], path[j][2]]);
      pointType.push("L");
    } else if (path[j][0] == "C") {
      temp_path1.push([path[j][1], path[j][2]]);
      temp_path1.push([path[j][3], path[j][4]]);
      temp_path1.push([path[j][5], path[j][6]]);
      pointType.push("C");
    }
  }
  return { points: temp_path1, pointType: pointType };
}
export {
  bezierPointsFromPath,
  offsetCurvePath,
  generatePointsFromPath,
  pointsFromPath,
};
