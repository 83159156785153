import axios from "axios";
import _ from "lodash";
import { getTeamObject } from "../class/Team";
import { generateNameFromImgPath } from "../containers/editor/components/rightmenuPopUps/helper";
import { FakeProgressEvent } from "../snaptrude/modules/FakeProgressEvent/FakeProgressEvent";
import { acceptTeamInvite, addFoldersRedux, addMaterialsRedux, addMembersRedux, addProjectsToTeam, addRoleBasedPermissionsRedux, addTeam, addTeamObjectsRedux, addTeams, appendRoleBasedPermissionsRedux, deleteTeamMaterialRedux, deleteTeamObjectRedux, leaveTeamUI, updateRoleBasedPermissionsRedux, updateTeamMaterialRedux, updateTeamMemberSettingRedux, updateTeamObjectRedux, uploadTeamMaterialsRedux, uploadTeamObjectsRedux } from "../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import reduxStore from "../snaptrude/stateManagers/store/reduxStore";
import authService from "./auth.service";
import { initializeFileSystem } from "./folder.service";
import { getFormData, getQueryParamsString } from "./helper"
import { ORIGIN } from "./url.constants";

export const initializeTeams = async () => {
    let resp = await getAllTeams();
    if(resp.status === "success"){
        const {teamsList} = resp;
        const promises = [];
        for(let team of teamsList){
            promises.push(initializeFileSystem(team.id))
            promises.push(getTeamPermissions(team.id));
            getTeamMaterials(team.id);
            getTeamObjects(team.id, "furnitures")
        }
        const settledPromises = await Promise.allSettled(promises);
        settledPromises.forEach((settledPromise, index) => {
            if(settledPromise.value.status !== "success"){
                console.error(`Could not get permissions for ${teamsList[index]?.id} ${teamsList[index]?.title}` )
            }
        })
        return {status: "success"}
    }else{
        return {status: "fail"}
    }
}

export const getAllTeams = async () => {
    const endpoint = "/user/teams/active";
    try {
        const resp = await axios.post(ORIGIN + endpoint, null, { validateStatus: false });
        if(resp.status === 200){
            const teamsList = resp.data.teams.map(team => {
                return getTeamObject(team.name, team.id, team.admin, team.membersCount, team.role, team.members, team.isManuallyPaid, team.manualPlanLastsUntil)
            })
            reduxStore.dispatch(addTeams(teamsList))
            return {status: "success", teamsList: teamsList}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    }catch(error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const searchExactTeam = async (teamname) => {
    const endpoint = "/team/search/"
    const data = {
        teamname
    }
    const formData = getFormData(data);

    try {   
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        if(resp.status === 200){
            return {teamExists: true, data: resp.data}
        }else if(resp.status === 404){
            return {teamExists: false}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {error: true, message};
    }
}

export const createTeam = async (teamname) => {
    const endpoint = "/team/"
    const data = {
        teamname
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false});
        if(resp.status === 201){
            const {name, id, members, admin, role} = resp.data.team;
            const newTeam = getTeamObject(name, id, admin, members.length, role, members);
            reduxStore.dispatch(addTeam(newTeam))

            await getTeamPermissions(id)

            return {status: "success", team: newTeam};
        }else if(resp.status === 406 || resp.status === 401){
            return {status: "fail", message: resp.data.message};
        }else if(resp.status === 500){
            return {status: "error", message: resp.statusText};
        }else{
            return {status: "fail", message: resp.data.message};
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const sendInvitation = async (emailList, teamId) => {
    const endpoint = `/team/${teamId}/invite/`;
    const data = {
        sourceuser: authService.getCurrentUser().email,
        destinationuser: null,
        origin: window.location.origin
    }

    const invitations = emailList.filter(emailid => emailid !== data.sourceuser).map(emailid => {
        data.destinationuser = emailid;
        const formData = getFormData(data);
        return axios.post(ORIGIN + endpoint, formData, {validateStatus: false})
    })

    const responses = await Promise.all(invitations);
    const failed = [];
    const invitedMembers = [];
    responses.forEach((resp, index) => {
        if(resp.status !== 200) {
            let reason = emailList[index] + " : ";
            reason += resp.data.message || resp.data.error;
            failed.push(reason);
        }else{
            invitedMembers.push(resp.data);
        }
    })
    if(invitedMembers.length > 0){
        reduxStore.dispatch(addMembersRedux({members: invitedMembers, teamId}))
    }
    return failed;
}

export const acceptTeamInvitation = async (token, teamId) => {
    const endpoint = `/team/${teamId}/accept/`;
    const data = {
        token: token
    }
    const formData = getFormData(data);

    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false});
        if(resp.status === 200){
            reduxStore.dispatch(acceptTeamInvite({teamId}))
            return {status: "success", data: resp.data}
        }else{
            return {status: "fail", message: resp.data.message || "Could not join team"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const rejectTeamInvitation = async (token, teamId) => {
    const endpoint = `/team/${teamId}/reject/`;
    const data = {
        token: token
    }
    const formData = getFormData(data);

    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false})
        if(resp.status === 200){
            reduxStore.dispatch(leaveTeamUI({teamId: teamId}))
            return {status: "success", data: resp.data}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const getTeamProjects = async (sort="latest", customOffset, customLimit, isNewlyCreatedProject=false, userfilter) => {
    const {teams} = reduxStore.getState();
    let offset = teams.selectedTeam?.projects?.length || 0;
    if(customOffset != null) offset = customOffset;
    const teamId = teams.selectedTeam.id;
    const endpoint = `/team/${teamId}/project?`
    let data = {
        limit: customLimit || 10,
        offset: offset,
        sort: sort
    }
    if(userfilter != null){
        data = {
            ...data,
            userfilter
        }
    }
    const queryParamsString = getQueryParamsString(data);

    try {
       const resp = await axios.get(ORIGIN + endpoint + queryParamsString, {validateStatus: false});
       if(resp.status === 200){
            reduxStore.dispatch(addProjectsToTeam({projects: resp.data.projects, teamId: teamId, isNewlyCreatedProject, offset}))
            return {status: "success", projects: resp.data.projects}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const createTeamProject = async (teamId, projectname, projectdescription, parent) => {
    if(!parent) parent = "root";
    const endpoint = `/team/${teamId}/project`;
    const data = {
        projectname,
        projectdescription,
        parent: parent,
    }

    const formData = getFormData(data);

    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        if(resp.status === 200){
            const {project} = resp.data;
            const folders = [];
            const projects = [project];
            reduxStore.dispatch(addFoldersRedux({teamID: teamId, parentFolderID: parent, folders, projects, useParentFolderID: true, append: true}))
            return {status: "success", floorkey: project.key}
        }else if(resp.status === 401){
            const message = resp.data.message || resp.data.error;
            return {status: "fail", message: message}
        }
        else{
            return {status: "fail", message: resp.data.error || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const getTeamPreferences = async (teamId) => {
    const endpoint = `/team/${teamId}/`;

    try {
        const resp = await axios.get(ORIGIN + endpoint, { validateStatus: false });
        if(resp.status === 200){
            return {status: "success", team: resp.data.team}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const updateTeamPreference = async(teamId, settingname, settingvalue) => {
    const endpoint = `/team/${teamId}/preferences/`;
    const data = {
        teamid: teamId,
        settingname: settingname,
        settingvalue: settingvalue
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        if(resp.status === 200){
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const searchTeamProjects = async (teamId, searchterm) => {
    const endpoint = `/team/${teamId}/searchprojects?`;
    const data = {
        searchterm,
    }
    const queryParamsString = getQueryParamsString(data);
    try {
        const resp = await axios.get(ORIGIN + endpoint +  queryParamsString, { validateStatus: false });
        if(resp.status === 200){
            return {status: "success", projects: resp.data.projects}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const updateTeamMemberSetting = async (teamId, teammemberId, newSetting) => {
    const endpoint = `/team/${teamId}/settings/`;
    const data = {
        teammember: teammemberId,
        ...newSetting
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        if(resp.status === 200){
            reduxStore.dispatch(updateTeamMemberSettingRedux({teamId, teammemberId, newSetting}));
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const renameTeam = async (teamId, teamname) => {
    const endpoint = `/team/${teamId}/update/`;
    const data = {
        teamname,
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        if(resp.status === 200){
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}


export const deleteTeam = async (teamId) => {
    const endpoint = `/team/${teamId}/delete/`;
    try {
        const resp = await axios.post(ORIGIN + endpoint, null, {validateStatus: false});
        if(resp.status === 200){
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const getPendingTeamInvites = async () => {
    const endpoint = `/user/teams/pending`;
    try {
        const resp = await axios.post(ORIGIN + endpoint, null, {validateStatus: false});
        if(resp.status === 200){
            return {status: "success", invites: resp.data.details}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const leaveTeam = async (teamId, teammember) => {
    const endpoint = `/team/${teamId}/leave/`;
    const data = {
        teammember,
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false});
        if(resp.status === 200){
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const getTeamPermissions = async (teamId) => {
    const endpoint = `/team/${teamId}/getrole/`;
    try {
        const resp = await axios.post(ORIGIN + endpoint, null, { validateStatus: false });
        if(resp.status === 200){
            const roleBasedPermissions = _.keyBy(resp.data.team.permissions, (o => o.name));
            reduxStore.dispatch(addRoleBasedPermissionsRedux({teamId, roleBasedPermissions}));
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const updateTeamPermission = async (teamId, role, settings) => {
    const endpoint = `/team/${teamId}/updaterole/`;
    const trueSettings = [];
    const falseSettings = [];
    _.mapKeys(settings, (value, key) => {
        if(value === true) trueSettings.push(key);
        else if(value === false) falseSettings.push(key);
    })
    const data = {
        currentname: role,
        newname: role,
        true_settings: trueSettings.join(","),
        false_settings: falseSettings.join(","), 
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false});
        if(resp.status === 200){
            reduxStore.dispatch(updateRoleBasedPermissionsRedux({teamId, role, newSettings: settings}))
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const createCustomRole = async (teamId, newRoleName, settings) => {
    const endpoint = `/team/${teamId}/addrole/`;
    const trueSettings = [];
    const falseSettings = [];
    _.mapKeys(settings, (value, key) => {
        if(value === true) trueSettings.push(key);
        else if(value === false) falseSettings.push(key);
    })
    const data = {
        name: newRoleName,
        true_settings: trueSettings.join(","),
        false_settings: falseSettings.join(","), 
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false});
        if(resp.status === 200){
            reduxStore.dispatch(updateRoleBasedPermissionsRedux({teamId, role: newRoleName, newSettings: settings}))
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Could not create custom role. Try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const getTeamMaterials = async (teamId) => {
    const endpoint = `/team/${teamId}/materials/`;
    try {
        const resp = await axios.get(ORIGIN + endpoint, { validateStatus: false });
        if(resp.status === 200){
            const materials = resp.data.materials.map(material => {
                let [id, relativePath, type, custom_name, cost, manufacturer, description] = material;
                let displayName = custom_name;
                if(!displayName){
                    displayName = generateNameFromImgPath(relativePath);
                }
                return {id, url: ORIGIN + '/media/' + relativePath, type, custom_name, cost, manufacturer, description, displayName}
            });
            reduxStore.dispatch(addMaterialsRedux({teamId, materials}))
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Could not load materials, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const uploadTeamMaterials = async(teamId, materials, type) => {

    const totalComponents = materials.length;
    const individualProgressArray = new Array(totalComponents).fill(0);

    const endpoint = `/team/${teamId}/addmaterial/`;
    const promises = [];
    let index = 0;
    for(let material of materials){
        const data = {
            materialtype: type
        }
        const formData = getFormData(data);
        formData.append("material", material, material.name);

        const promise = axios.post(ORIGIN + endpoint, formData, { validateStatus: false, onUploadProgress: (progressEvent) => {handleProgressEvent(progressEvent, index, "materials", individualProgressArray)} })
        .then(resp => {
            let [id, relativePath, type, custom_name, cost, manufacturer, description] = resp.data.material;
            let displayName = custom_name;
            if(!displayName){
                displayName = generateNameFromImgPath(relativePath);
            }
            const uploadedMaterial =  {id, url: ORIGIN + '/media/' + relativePath, type, custom_name, cost, manufacturer, description, displayName}
            if(resp.status === 200){
                return {status: "success", material: uploadedMaterial}
            }else{
                return {status: "fail", message: resp.data.error, data: resp.data}
            }
        })
        .catch(error => {
            return {status: "error", message: "Something went wrong"}
        })
        promises.push(promise);
        ++index;
    }

    const settledPromises = await Promise.allSettled(promises);
    const uploadedMaterials = [];
    settledPromises.forEach(settledPromise => {
        const value = settledPromise.value;
        if(value.status === "success"){
            uploadedMaterials.push(value.material)
        }
    })

    reduxStore.dispatch(uploadTeamMaterialsRedux({teamId, materials: uploadedMaterials}))
    return {status: "success"};
} 

export const updateTeamMaterialProperty = async (teamId, property, newValue, material) => {
    const endpoint = `/team/${teamId}/updatematerial/`;
    const data = {
        mat_file_id: material.id,
        [property]: newValue
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        
        if(resp.status === 200){
            let [id, relativePath, type, custom_name, cost, manufacturer, description] = resp.data.material;
            let displayName = custom_name;
            if(!displayName){
                displayName = generateNameFromImgPath(relativePath);
            }
            const updatedMaterial = {id, url: ORIGIN + '/media/' + relativePath, type, custom_name, cost, manufacturer, description, displayName}
            
            reduxStore.dispatch(updateTeamMaterialRedux({teamId, updatedMaterial}));
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const getTeamObjects = async (teamId, type) => {
    const endpoint = `/team/${teamId}/objects/`;
    try {
        const resp = await axios.get(ORIGIN + endpoint, { validateStatus: false });
        if(resp.status === 200){
            const objects = resp.data.objects.map(object => {
            let [id, relativePathImg, room, custom_name, cost, vendor, description, relativePathObj, subType, familyName, babylonPath] = object;
                let displayName = custom_name;
                if(!displayName){
                    displayName = generateNameFromImgPath(relativePathImg);
                }
                return {id, url: ORIGIN + relativePathImg, room, type, custom_name, displayName, cost, vendor, description, displayName, zip: ORIGIN + '/media/' + relativePathObj, subType, familyName, babylon: ORIGIN + babylonPath, teamId}
            });
            reduxStore.dispatch(addTeamObjectsRedux({teamId, objects, type}))
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Could not load materials, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

const sendProgressEvent = function (completed, text, {type}) {
    const EVT_NAME = `team-${type}-progress`;
    if (!text) text = "Uploading...";
    const event = new CustomEvent(EVT_NAME, {
      detail: {
        progress: completed.toFixed(0),
        text: text
      }
    })
    window.dispatchEvent(event);
}

const handleProgressEvent = (progressEvent, index, type, individualProgressArray) => {
    const individualProgress = (progressEvent.loaded/progressEvent.total)*100;
    individualProgressArray[index] = individualProgress;
    let done = 0;
    let totalProgress = (individualProgressArray.reduce((prev, curr) => {
      if(curr===100) ++done;
      return prev+curr;
    }, 0));
    let text = "Uploading..."
    if(totalProgress===100){
      text = "Processing..."
      const fakeProgressEvent = new FakeProgressEvent(70, 95, "Processing...", 60, `team-${type}-progress`, sendProgressEvent, {type});
      fakeProgressEvent.start();
    } 
    totalProgress *= 0.7;
    sendProgressEvent(totalProgress, text, {type});
}

export const uploadTeamObjects = async(teamId, objects, type, subtype) => {


    const totalComponents = objects.length;
    const individualProgressArray = new Array(totalComponents).fill(0);

    const endpoint = `/team/${teamId}/addobject/`;
    const promises = [];
    let index = 0;
    for(let object of objects){
        const data = {
            objecttype: type,
            objectsubtype: subtype,
            scale: "m"
        }
        const formData = getFormData(data);
        formData.append("object", object, object.name);

        const promise = axios.post(ORIGIN + endpoint, formData, { validateStatus: false, onUploadProgress: (progressEvent) => {handleProgressEvent(progressEvent, index, type, individualProgressArray)} })
        .then(resp => {
            let [id, relativePathImg, room, custom_name, cost, vendor, description, relativePathObj, _subType, familyName, babylonPath] = resp.data.object;
            let displayName = custom_name;
            if(!displayName){
                displayName = generateNameFromImgPath(relativePathImg);
            }
            const uploadedObject =  {id, url: ORIGIN + relativePathImg, room, type, custom_name, displayName, cost, vendor, description, displayName, zip: ORIGIN + '/media/' + relativePathObj, subType: _subType, familyName, babylon: ORIGIN + babylonPath, teamId}
            if(resp.status === 200){
                return {status: "success", object: uploadedObject}
            }else{
                return {status: "fail", message: resp.data.error, data: resp.data}
            }
        })
        .catch(error => {
            return {status: "error", message: "Something went wrong"}
        })
        promises.push(promise);
        ++index;
    }

    const settledPromises = await Promise.allSettled(promises);
    const uploadedObjects = [];
    settledPromises.forEach(settledPromise => {
        const value = settledPromise.value;
        if(value.status === "success"){
            uploadedObjects.push(value.object)
        }
    })

    reduxStore.dispatch(uploadTeamObjectsRedux({teamId, objects: uploadedObjects, type}))
    return {status: "success"};
} 

export const updateTeamObjectProperty = async (teamId, property, newValue, furniture, type) => {
    const endpoint = `/team/${teamId}/updateobject/`;
    const data = {
        obj_file_id: furniture.id,
        [property]: newValue
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData, { validateStatus: false });
        if(resp.status === 200){
            let [id, relativePathImg, room, custom_name, cost, vendor, description, relativePathObj, subType, familyName, babylonPath] = resp.data.object;
            let displayName = custom_name;
            if(!displayName){
                displayName = generateNameFromImgPath(relativePathImg);
            }
            const updatedObject = {id, url: ORIGIN + relativePathImg, room, type, custom_name, cost, vendor, description, displayName, zip: ORIGIN + '/media/' + relativePathObj, subType, familyName, babylon: ORIGIN + babylonPath, teamId}
            reduxStore.dispatch(updateTeamObjectRedux({teamId, updatedObject, type}));
            return {status: "success"}
        }else{
            return {status: "fail", message: resp.data.message || "Something went wrong"}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const isTeamPlanActive = (team) => {
    const {isManuallyPaid, manualPlanLastsUntil} = team;
    if(isManuallyPaid){
        const today = new Date();
        const expiry = new Date(manualPlanLastsUntil);
        return today < expiry;    
    }
    return false;
}

export const getObjectsFromIds = async (teamId, elements) => {
    const endpoint = `/team/${teamId}/objectarray/`;
    const data = {
        "elements[]": elements
    }
    const formData = getFormData(data)
    try {
        if(!teamId) throw new Error("Team ID not defined");
        if(elements.length === 0) return {status: "successs", objects: []}
        const type = 'furniture'
        const resp = await axios.post(ORIGIN + endpoint, formData, {validateStatus: false});
        if(resp.status === 200){
            const objects = resp.data.objects.map(object => {
            let [id, relativePathImg, room, custom_name, cost, vendor, description, relativePathObj, subType, familyName, babylonPath] = object;
                let displayName = custom_name;
                if(!displayName){
                    displayName = generateNameFromImgPath(relativePathImg);
                }
                return {id, url: ORIGIN + relativePathImg, room, type, custom_name, displayName, cost, vendor, description, displayName, zip: ORIGIN + '/media/' + relativePathObj, subType, familyName, babylon: ORIGIN + babylonPath, teamId}
            });
            return {status: "success", objects: objects}
        }else{
            return {status: "fail", message: resp.data.message || "Could not load materials, try again."}
        }
    } catch (error) {
        let message = "Something went wrong while getting changelog metadata";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const searchTeamObjects = async (teamId, searchterm, type ) => {
    const endpoint = `/team/${teamId}/searchobjects?`;
    const data = {
        searchterm,
    }
    const queryParamsString = getQueryParamsString(data);
    try {
        const resp = await axios.get(ORIGIN + endpoint + queryParamsString, { validateStatus: false });
        if(resp.status === 200){
            const objects = resp.data.objects.map(object => {
            let [id, relativePathImg, room, custom_name, cost, vendor, description, relativePathObj, subType, familyName, babylonPath] = object;
                let displayName = custom_name;
                if(!displayName){
                    displayName = generateNameFromImgPath(relativePathImg);
                }
                return {id, url: ORIGIN + relativePathImg, room, type, custom_name, displayName, cost, vendor, description, displayName, zip: ORIGIN + '/media/' + relativePathObj, subType, familyName, babylon: ORIGIN + babylonPath, teamId}
            });
            return {status: "success", teamId, objects, type}
        }else{
            return {status: "fail", message: resp.data.message || "Could not load materials, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const deleteTeamMaterial =  async (teamId, elementId) => {
    const endpoint = `/team/${teamId}/material/${elementId}/delete`;
    try {
        const resp = await axios.delete(ORIGIN + endpoint, { validateStatus: false });
        if(resp.status === 200){
            reduxStore.dispatch(deleteTeamMaterialRedux({teamId, elementId}));
            return {status: "success", teamId, elementId}
        }else{
            return {status: "fail", message: resp.data.message || "Could not delete material, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const deleteTeamObject =  async (teamId, elementId, type) => {
    const endpoint = `/team/${teamId}/object/${elementId}/delete`;
    try {
        const resp = await axios.delete(ORIGIN + endpoint, { validateStatus: false });
        if(resp.status === 200){
            reduxStore.dispatch(deleteTeamObjectRedux({teamId, elementId, type}));
            return {status: "success", teamId, elementId}
        }else{
            return {status: "fail", message: resp.data.message || "Could not delete material, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}

export const duplicateTeamMaterial = async (teamId, elementId) => {
    const endpoint = `/team/${teamId}/material/${elementId}/duplicate`;
    try {
        const resp = await axios.post(ORIGIN + endpoint, null, { validateStatus: false });
        if(resp.status === 200){
            let [id, relativePath, type, custom_name, cost, manufacturer, description] = resp.data.material;
            let displayName = custom_name;
            if(!displayName){
                displayName = generateNameFromImgPath(relativePath);
            }
            const duplicateMaterial =  {id, url: ORIGIN + '/media/' + relativePath, type, custom_name, cost, manufacturer, description, displayName, teamId};
            reduxStore.dispatch(uploadTeamMaterialsRedux({teamId, materials: [duplicateMaterial], elementId}))
            return {status: "success", teamId, elementId}
        }else{
            return {status: "fail", message: resp.data.message || "Could not duplicate material, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}


export const duplicateTeamObject = async (teamId, elementId, type) => {
    const endpoint = `/team/${teamId}/object/${elementId}/duplicate`;
    try {
        const resp = await axios.post(ORIGIN + endpoint, null, { validateStatus: false });
        if(resp.status === 200){
            let [id, relativePathImg, room, custom_name, cost, vendor, description, relativePathObj, _subType, familyName, babylonPath] = resp.data.object;
            let displayName = custom_name;
            if(!displayName){
                displayName = generateNameFromImgPath(relativePathImg);
            }
            const duplicateObject =  {id, url: ORIGIN + relativePathImg, room, type, custom_name, displayName, cost, vendor, description, displayName, zip: ORIGIN + '/media/' + relativePathObj, subType: _subType, familyName, babylon: ORIGIN + babylonPath, teamId}
            reduxStore.dispatch(uploadTeamObjectsRedux({teamId, objects: [duplicateObject], type, elementId}))
            return {status: "success", teamId, elementId}
        }else{
            return {status: "fail", message: resp.data.message || "Could not duplicate material, try again."}
        }
    } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
    }
}