import teamIcon from "../assets/icons/team.svg";
import projectIcon from "../assets/icons/project.svg";
import librariesIcon from "../assets/icons/libraries.svg";
import sharedIcon from "../assets/icons/shared.svg";
import templateIcon from "../assets/icons/template.svg";
import preferencesIcon from "../assets/icons/settings.svg";
import reportsIcon from "../assets/icons/report.svg";
import authService, { isCurrentUser } from "../services/auth.service";
import { TEAM_ROLES } from "../containers/dashboard/TeamDashboard/constants";

const isCurrentUserAdmin = (members) => {
    for(let member of members){
        if(isCurrentUser(member.user.email) && member.role === TEAM_ROLES.ADMIN) return true;
    }
    return false;
}
const getMe = (members) => {
    const currentUser = authService.getCurrentUser();
    return members.find(member => member.user.email === currentUser.email)
}
class Team {
    constructor(title, id, admin, membersCount, role, members, isManuallyPaid, manualPlanLastsUntil){
        this.title = title;
        this.id = id;
        // this.route = "/dashboard/team/" + title.toLocaleLowerCase().split(" ").join("-");
        this.route = "/dashboard/team/" + id;
        this.icon = teamIcon;
        this.icontext = "team";
        this.admin = admin;
        this.membersCount = membersCount;
        this.role = role.split(".").pop().toLowerCase();
        this.members = members;
        this.me = getMe(members);
        this.isManuallyPaid = isManuallyPaid;
        this.manualPlanLastsUntil = manualPlanLastsUntil;
        this.materials = [];
        this.objects = {
            furnitures: []
        }
        this.fileSystem = {
            folders: [],
            projects: []
        }
        this.children = !isCurrentUserAdmin(members) ? 
        [
            {
                title: "All Projects",
                route: this.route,
                icon: projectIcon,
                icontext: "project-icon",
                children: []
            },
            {
                title: "Libraries",
                route: this.route + "/libraries",
                icon: librariesIcon,
                icontext: "libraries-icon",
            },
        ] 
        : 
        [
            {
                title: "All Projects",
                route: this.route,
                icon: projectIcon,
                icontext: "project-icon",
            },
            // {
            //     title: "Templates",
            //     route: this.route + "/templates",
            //     icon: templateIcon,
            //     icontext: "templates-icon",
            // },
            {
                title: "Libraries",
                route: this.route + "/libraries",
                icon: librariesIcon,
                icontext: "libraries-icon",

            },
            {
                title: "Preferences",
                route: this.route + "/preferences",
                icon: preferencesIcon,
                icontext: "libraries-icon",
            },
            // {
            //     title: "Reports",
            //     route: this.route + "/reports",
            //     icon: reportsIcon,
            //     icontext: "reports-icon",
            // },
        ]
    }
}

export const getTeamObject = (title, id, admin, membersCount=0, role, members=[], isManuallyPaid, manualPlanLastsUntil) => {
    const team = new Team(title, id, admin, membersCount, role, members, isManuallyPaid, manualPlanLastsUntil);
    return JSON.parse(JSON.stringify(team));
}

export default Team;