import React, { useEffect, useRef } from "react";
import { useDragDropManager } from "react-dnd";
import { useScroll } from "./useScroll";

const defaultStyle = {
  height: "100%",
  overflow: "scroll"
};

export const DragDropScroller = React.forwardRef(({
    style={},
    children,
    threshold
}, ref) => {
    const _ref = useRef(null);

    const { updatePosition } = useScroll(_ref, threshold || 250);

    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();

    useEffect(() => {
      const unsubscribe = monitor.subscribeToOffsetChange(() => {
        const offset = monitor.getSourceClientOffset()?.y;
        updatePosition({ position: offset, isScrollAllowed: true });
      });

      return unsubscribe;
    }, [monitor, updatePosition]);

    return (
        <div
            ref={(r) => {
                _ref.current = r
                if(ref) ref.current = r
            }}
            style={{
                ...defaultStyle,
                ...style
            }}
        >
          { children }
        </div>
    )
})
