import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js"
import { nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import { prepareDataForCMD,addMaterialToLayers,deleteMaterialToLayersFace,recreateSubMeshes } from "./applyMaterialFuncs.js";
import { getFaceIdFromFacet } from "./brepOperations.js";
import { Command } from "../modules/commandManager/Command.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { AutoSave } from "../modules/socket/autoSave.js";
let onMatDeletePointerDown = function (evt) {
  let pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return nonDefaultMeshForSnapping(mesh) && !mesh.name.includes("terrain");
    }
  );
  if (pickInfo.hit) {
    if (pickInfo.pickedMesh.type) {
      let meshUID = null;
      let submeshId = null;
      let str_id = null;
      let face_id = null;
      let matName = null;
      let matUrl = null;
      let faceFlag = null;
      let matType = null;
      let deleteMaterialCommandData = {};

      if (
        ["wall", "mass", "floor", "roof", "staircase"].includes(
          pickInfo.pickedMesh.type.toLowerCase()
        )
      ) {
        let mesh = pickInfo.pickedMesh;
        if (mesh.isAnInstance) mesh = pickInfo.pickedMesh.sourceMesh;
        meshUID = mesh.uniqueId;
        submeshId = pickInfo.subMeshId;
        str_id = pickInfo.pickedMesh.structure_id;
        face_id = pickInfo.faceId;
        let materialIndex = mesh.subMeshes[pickInfo.subMeshId].materialIndex;
        // if (mesh.subMeshes.length > 1) {
        //     deleteMaterialToLayersFace(mesh, materialIndex);
        //     deleteMaterialCommandData._prevData = prepareDataForCMD(pickInfo.pickedMesh);
        //     mesh.subMeshes[pickInfo.subMeshId].materialIndex = 0;
        //     deleteMaterialCommandData._newData = prepareDataForCMD(pickInfo.pickedMesh);
        //     matName =mesh.subMeshes[pickInfo.subMeshId]._currentMaterial.id;
        //     if(mesh.material.subMaterials[materialIndex].albedoTexture) {
        //         matUrl = mesh.material.subMaterials[materialIndex].albedoTexture.url;
        //     }
        //     matType = mesh.material.subMaterials[materialIndex].materialType;
        //     faceFlag = true;
        // } else {
        //     deleteMaterialCommandData._prevData = prepareDataForCMD(pickInfo.pickedMesh);
        //     mesh.subMeshes[0].materialIndex = 0;
        //     deleteMaterialCommandData._newData = prepareDataForCMD(pickInfo.pickedMesh);
        //
        //     addMaterialToLayers(mesh, 0);
        //     matName =mesh.subMeshes[pickInfo.subMeshId]._currentMaterial.id;
        //     if(mesh.material.subMaterials[materialIndex].albedoTexture) {
        //         matUrl = mesh.material.subMaterials[materialIndex].albedoTexture.url;
        //     }
        //      matType = mesh.material.subMaterials[materialIndex].materialType;
        //      faceFlag = false;
        // }
        let object = mesh.getSnaptrudeDS();
        if (object) {
          // Check if all material indices are same for remove material by entity
          let materialIndices = [];
          mesh.subMeshes.forEach(function (subMesh) {
            materialIndices.push(subMesh.materialIndex);
          });

          materialIndices = _.uniq(materialIndices);
          deleteMaterialCommandData._prevData = prepareDataForCMD(
            pickInfo.pickedMesh,
            pickInfo.faceId
          );
          if (object.brep) {
            if (materialIndices.length === 1) {
              object.brep.getFaces().forEach(function (face) {
                face.materialIndex = 0;
              });
              addMaterialToLayers(mesh, 0);
            } else {
              let faceID = getFaceIdFromFacet(pickInfo.faceId, mesh);
              let face = object.brep.getFaces()[faceID];
              face.materialIndex = 0;
              deleteMaterialToLayersFace(mesh, face.materialIndex);
            }
          }

          if (materialIndices.length === 1) {
            mesh.subMeshes.forEach(function (subMesh) {
              subMesh.materialIndex = 0;
            });
            addMaterialToLayers(mesh, 0);
          } else {
            deleteMaterialToLayersFace(
              mesh,
              mesh.subMeshes[pickInfo.subMeshId].materialIndex
            );
            if (object.type.toLowerCase() == "staircase") {
              object.deleteMaterial(pickInfo);
            } else {
              mesh.subMeshes[pickInfo.subMeshId].materialIndex = 0;
            }
          }
          deleteMaterialCommandData._newData = prepareDataForCMD(
            pickInfo.pickedMesh,
            pickInfo.faceId
          );

          let _executeEvent = function () {
            let cmd = new Command(
              "deleteMaterial",
              deleteMaterialCommandData,
              CommandLogic(),
              getDeleteMaterialSaveData
            );
            CommandManager.execute(cmd, false);
          };

          _executeEvent();
        }
      }
    }
  }
};
let CommandLogic = function () {
  return {
    execute: deleteMaterial,
    unexecute: unDeleteMaterial,
  };
};

var deleteMaterial = function () {
  let data = this.data._newData;
  recreateSubMeshes(data);
};

var unDeleteMaterial = function () {
  let data = this.data._prevData;
  recreateSubMeshes(data);
};

let getDeleteMaterialSaveData = function () {
  let data = this.data;
  let saveData = AutoSave.getSaveDataPrototype();

  saveData.commandId = this.id;
  saveData.data.saveType = "deleteMaterial";

  let dataBefore = {};
  let dataAfter = {};

  let mesh = store.scene.getMeshByUniqueID(data._prevData.meshId);
  let material = mesh.material.serialize();
  dataBefore.brep = store.resurrect.stringify(data._prevData.brep);
  dataBefore.subMeshes = data._prevData.subMeshes;
  dataBefore.components = data._prevData.components;
  dataBefore.materialIndices = data._prevData.materialIndices;
  dataBefore.material = material;
  dataAfter.brep = store.resurrect.stringify(data._newData.brep);
  dataAfter.subMeshes = data._newData.subMeshes;
  dataAfter.components = data._newData.components;
  dataAfter.materialIndices = data._newData.materialIndices;
  dataAfter.material = material;

  let identifierMeshDS;
  if (mesh.isAnInstance) {
    identifierMeshDS = mesh.sourceMesh.getSnaptrudeDS();
  } else {
    identifierMeshDS = mesh.getSnaptrudeDS();
  }

  saveData.data.identifier = AutoSave.getComponentIdentifier(identifierMeshDS);

  saveData.data.afterOperationData = dataAfter;
  saveData.data.beforeOperationData = dataBefore;

  return saveData;
};

let onMatDeleteDoubleClick = function (evt) {
  // let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
  //     return nonDefaultMeshForSnapping(mesh);
  // });
  // if (pickInfo.hit) {
  //     if (pickInfo.pickedMesh.type) {
  //         if (['wall', 'mass', 'floor', 'roof'].includes(pickInfo.pickedMesh.type.toLowerCase())) {
  //             let mesh = pickInfo.pickedMesh;
  //             if (mesh.isAnInstance) mesh = pickInfo.pickedMesh.sourceMesh;
  //
  //             if (mesh.subMeshes.length > 1) {
  //                 mesh.subMeshes.forEach(function (subMesh) {
  //                     subMesh.materialIndex = 0;
  //                 });
  //             } else {
  //                 mesh.subMeshes[0].materialIndex = 0;
  //             }
  //             addMaterialToLayers(mesh, 0);
  //         }
  //     }
  // }
};
export { onMatDeletePointerDown,CommandLogic,deleteMaterial,unDeleteMaterial,getDeleteMaterialSaveData,onMatDeleteDoubleClick };
