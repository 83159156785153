import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import OuterCard from "../Outer";
import Text from "../../Text";
import Divider from "../../Divider";
import defaultCardIcon from "../../../assets/icons/mastercard.svg";

import * as styles from "./styles";
import * as constants from "./constants";
import { createCheckoutSession } from "../../../services/payment.service";

import { loadStripe } from '@stripe/stripe-js';
import { STRIP_PUBLISHABLE_KEY } from "../../../containers/payment/constants";

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  flex: ${({ flex }) => flex};
`;

const CustomText = styled(Text)`
  display: inline;
  opacity: ${({ opacity }) => opacity || 1};
  margin-right: ${({ marginRight }) => marginRight};
  margin: ${({ margin }) => margin};
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.theme.fontWeight[props.weight] || "500"};
  font-family: ${(props) => props.theme.font};
`;

const Card = styled(OuterCard)`
  height: 12.9375rem;
  width: 100%;
  padding: 1rem 1rem 0 1rem;
`;

const CardImageIcon = styled.img`
  margin-left: 0.75rem;
`;

const Anchor = styled.a`
  color: ${(props) => props.theme.colors.secondaryGrey};
  text-decoration: none;
  font-family: ${(props) => props.theme.font};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 0.75rem;
  line-height: 0.938rem;
  margin-top: 0.625rem;
  &:hover {
    color: ${(props) => props.theme.colors.brightRed};
  }
`;

const Container = styled.div`
  position: relative;
  top: 3rem;
`;


const stripePromise = loadStripe(STRIP_PUBLISHABLE_KEY);

function Payment({ cardIcon, cardName, cardNumber, showCancelSubModalHandler }) {

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    const { sessionId } = await createCheckoutSession();
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  return (
    <Card hover={true}>
      <CustomText opacity="0.8" color="secondaryBlack" weight="bold">
        {constants.PAYMENT_METHOD}
      </CustomText>
      <Divider customStyle={styles.DividerStyles} />
      <FlexContainer>
        <FlexContainer>
          <CustomText opacity="0.8" color="darkGrey">
            {cardName}
          </CustomText>
        </FlexContainer>
        <FlexContainer alignItems="center">
          <CustomText
            lineHeight="1.25rem"
            size="0.9375rem"
            color="darkGrey"
            margin={0}
          >
            {cardNumber}
          </CustomText>
          <CardImageIcon
            alt="card-icon"
            src={cardIcon ? cardIcon : defaultCardIcon}
          />
        </FlexContainer>
      </FlexContainer>
      <Container>
        <Divider customStyle={styles.DividerStyles} />
        <FlexContainer>
          <Anchor href="#" onClick={handleClick}>{constants.CHANGE_CARD_DETAILS}</Anchor>
          <Anchor href="#" onClick={() => showCancelSubModalHandler(true)}>{constants.CANCEL_SUBCRIPTION}</Anchor>
        </FlexContainer>
      </Container>
    </Card>
  );
}

Payment.propTypes = {
  cardIcon: PropTypes.element,
  cardName: PropTypes.string,
  cardNumber: PropTypes.string,
};

export default Payment;
