import axios from "axios";
import { ORIGIN, socketUrl } from "./url.constants";

export const getCadJson = (requestId) => {
  let promise = axios.get(socketUrl + `/cadImportRequest?requestId=${requestId}`)
    .then((res) => {
      return res.data;
    }).catch(err => {
      console.error("Get Cad json error response");
      // console.error(err.response.data);    // ***
      // console.error(err.response.status);  // ***
      // console.error(err.response.headers); // ***
      return err;
    });

  return promise;
};
