// export const objStructureController = ($scope, $mdDialog, activeObject, globalScope) => {
//   $scope.structureObjectProps = [];
//   $scope.structName = "";
//   $scope.activeFamilyName = activeObject.properties._components._name;
//   if (activeObject) {
//       try{
//           activeObject.setThickness();
//       }
//       catch(err){
//           console.log(err);
//       }
//       let layers;
//       if (activeObject.mesh.isAnInstance) {
//           layers = activeObject.mesh.sourceMesh.getSnaptrudeDS().properties._components._layers;
//       }
//       else {
//           layers = activeObject.properties._components._layers;
//       }
//       $scope.structureObjectProps = JSON.parse(JSON.stringify(layers));
//       $scope.structureObjectProps.forEach(function(layer){
//           if (layer.thickness !== "Variable"){
//               let thicknessInBabylonDimensions = DisplayOperation.getOriginalDimension(layer.thickness, "millimeter");
//               layer.thickness = DisplayOperation.convertToDefaultDimension(thicknessInBabylonDimensions);
//           }
//       });
//       $scope.structName = activeObject.properties._components._name;
//   }
//   const addNewStructure = function () {
//       var item = {check: false, value: "", subType: "", thickness: 0};
//       $scope.structureObjectProps.push(item);
//   }
//   //console.log($scope.structureObjectProps);
//   const deleteStructure = function () {
//       angular.forEach($scope.structureObjectProps, function (value, key) {
//           if (value.check) {
//               //console.log($scope.structureObjectProps, key);
//               $scope.structureObjectProps.splice(key, 1);
//           }
//       });
//   }

import { updateModifications } from "../../libs/sceneStateFuncs";
import { Command } from "../commandManager/Command";
import { CommandManager } from "../commandManager/CommandManager";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { AutoSave } from "../socket/autoSave";
import { store } from "../utilityFunctions/Store";
import _ from "lodash";

//   const hide = function () {
//       //console.log('hide')
//       $mdDialog.hide();
//   };

//   const cancel = function () {
//       $mdDialog.cancel();
//       // updatePropBlock();
//   };

//   const saveStructure = function () {
//       let object = activeObject.mesh.isAnInstance ? activeObject.mesh.sourceMesh.getSnaptrudeDS() : activeObject;
//       object.properties._components._layers = $scope.structureObjectProps;
//       object.properties._components._name = $scope.activeFamilyName;
//       // updatePropBlock();
//       $mdDialog.cancel();
//       //console.log(globalScope.wallClasses);
//       updateModifications();
//       updateMaterialProperty($scope.structureObjectProps);
//   };

//   const makeUnique = function () {
//       let object = activeObject.mesh.isAnInstance ? activeObject.mesh.sourceMesh.getSnaptrudeDS() : activeObject;

//       object.properties._components = jQuery.extend({}, BasicWallComp);
//       object.properties._components._name = $scope.activeFamilyName;
//       object.properties._components._layers = $scope.structureObjectProps;
//       //console.log(globalScope.compClasses);
//       globalScope.wallClasses = jQuery.extend([], globalScope.compClasses);
//       globalScope.wallClasses.push(activeObject.properties._components);
//       // globalScope.wallClasses.push(activeObject._properties._components);
//   };

//   const changeThickness = function(thickness){
//       if (activeObject.type.toLowerCase() === "wall"){
//           DisplayOperation.updateDimensionScale(DisplayOperation.getOriginalDimension(thickness), "thickness");
//       }
//       if (activeObject.type.toLowerCase() === "roof"){
//           DisplayOperation.updateDimensionScale(DisplayOperation.getOriginalDimension(thickness), "height");
//       }
//       if (activeObject.type.toLowerCase() === "floor"){
//           DisplayOperation.updateDimensionScale(DisplayOperation.getOriginalDimension(thickness), "height");
//       }
//   }

//   const answer = function(answer) {
//       //console.log('answer')
//       $mdDialog.hide(answer);
//   };

//   return {
//     addNewStructure,
//     deleteStructure,
//     hide,
//     cancel,
//     saveStructure,
//     makeUnique,
//     changeThickness
//   }

// }

const updateMaterialProperty = (prop) => {
    for (let j = 0; j < prop.length; j++) {
        var n = store.$scope.tabs.length;
        var flag = true;
        for (var i = 0; i < n; i++) {
            if (store.$scope.tabs[i].title.toLowerCase() === prop[j].value.toLowerCase()) {
                flag = false;
                break;
            }
        }
        if (flag) {
            var item = {title: prop[j].value, content: []};
            store.$scope.tabs.push(item);
            var matProp = {name: item.title, content: item.content, area: 0}
            store.$scope.matProps.push(matProp);
            // console.log($scope.tabs);
        }
    }
}

export const getStructureObjectProps = (activeObject) => {
  // const activeObject = store.canvas.getActiveObject();
  if(!activeObject || activeObject === "Varies"){
    if(store.selectionStack.length === 0){
        return {
            activeFamilyName: "",  
            structureObjectProps: [],
            improperObject: true,
        };
    }
    activeObject = store.selectionStack[0];
  }
  activeObject = activeObject.getSnaptrudeDS();
  let structureObjectProps = [];
  let structName = "";
  if(activeObject && !activeObject.properties) {
      return {
        activeFamilyName: "",  
        structureObjectProps: [],
        improperObject: true,
      }
  }
  if(activeObject.properties._name && ["Door", "Window", "Furniture"].includes(activeObject.properties._type)){
    return {
        activeFamilyName: "",  
        structureObjectProps: [],
        improperObject: true,
      }

  }
  let activeFamilyName = activeObject?.properties._components._name;
  if (activeObject) {
      try{
          activeObject.setThickness();
      }
      catch(err){
          console.log(err);
      }
      let layers;
      if (activeObject.mesh.isAnInstance) {
          layers = activeObject.mesh.sourceMesh.getSnaptrudeDS().properties._components._layers;
      }
      else {
          layers = activeObject.properties._components._layers;
      }
      structureObjectProps = JSON.parse(JSON.stringify(layers));
      structureObjectProps.forEach(function(layer){
          if (layer.thickness !== "Variable"){
              let thicknessInBabylonDimensions = DisplayOperation.getOriginalDimension(layer.thickness, "millimeter");
              layer.thickness = DisplayOperation.convertToDefaultDimension(thicknessInBabylonDimensions);
          }
      });
      structName = activeObject.properties._components._name;
  }
  return {
    activeFamilyName,  
    structureObjectProps,
    improperObject: false,
  } 
}

export const saveStructure = (activeObject, structureObjectProps, activeFamilyName) => {
  try{
    updateStructure(activeObject, structureObjectProps, activeFamilyName);
  }
  catch (e) {
    console.log("Error occurred while saving the structure", e);
  }
};


const updateStructure = (activeObject, structureObjectProps, activeFamilyName) => {

  activeObject = activeObject.getSnaptrudeDS();
  let object = activeObject.mesh.isAnInstance ? activeObject.mesh.sourceMesh.getSnaptrudeDS() : activeObject;

  let originalLayers = object.properties._components._layers;
  let originalName = object.properties._components._name;
  let originalIsModified = object.revitMetaData?.isModified;

  let _executeEvent = function () {
    let data = {};

    let commandLogic = { execute: _do, unexecute: _undo };

    let cmd = new Command("Update Structure", data, commandLogic, _getSaveData);
    CommandManager.execute(cmd, true);
  };

  let _getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = this.id;
    saveData.data.saveType = "updateStructure";

    saveData.data.identifier = AutoSave.getComponentIdentifier(object);

    saveData.data.beforeOperationData = {
      structureObjectProps: originalLayers,
      activeFamilyName: originalName,
      isModified: originalIsModified
    };
    saveData.data.afterOperationData = {
      structureObjectProps: structureObjectProps,
      activeFamilyName: activeFamilyName,
      isModified: true
    };

    return saveData;
  };

  let _undo = function (saveData) {
    if (!saveData) { saveData = this.data }

    object.properties._components._layers = this.getSaveData()[0].data.beforeOperationData.structureObjectProps;
    object.properties._components._name = activeFamilyName;
    updateMaterialProperty(this.getSaveData()[0].data.beforeOperationData.structureObjectProps);
  };

  let _do = function () {
    object.properties._components._layers = structureObjectProps;
    object.properties._components._name = activeFamilyName;
    updateMaterialProperty(structureObjectProps);

    if (!_.isEmpty(object.revitMetaData)) object.revitMetaData.isModified = true;
  };

  _executeEvent();
};