import React, {useState} from 'react'
import styled from 'styled-components'
import { colors } from '../../themes/constant'
import commentPin from "../../assets/icons/commentPin.svg";
import { socialConnect } from '../../snaptrude/modules/social/socialSocket';

const Wrapper = styled.div`
    // border: 2px solid;
    backgroudn: ${colors.fullWhite};
    padding: 10px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    & > div{
        margin: 1px 0px;
    }
`
const CommentNumber = styled.div`
    display: flex;
    align-items: flex-end;
    img{
        margin-right: 5px;
    }
`

const CommentInfo = styled.div`
    color: ${colors.thinGray}
`

const CommentText = styled.div``

const ReplyInfo = styled.div`
    color: ${colors.thinGray};
`
const Replies = styled.div`
    padding-left: 5px;
`

function SidebarComment({comment, index, onClick, ...restOfProps}) {
    const [showReplies, setShowReplies] = useState(false);
    return (
        <Wrapper onClick={onClick} {...restOfProps}>
            <CommentNumber>
                <img src={commentPin} alt="pin" />
                <span>{index + 1}</span>
            </CommentNumber>
            <CommentInfo>
                <span>{comment.author.name}, </span>
                <span>{socialConnect.timeDifference(new Date(comment.createdAt))}</span>
            </CommentInfo>
            <CommentText>
                {comment.comment}
            </CommentText>
            {
                comment.children.length > 0 && 
                    <ReplyInfo>
                        <span>{comment.children.length} replies</span>
                    </ReplyInfo>
            }
            {/* {
                showReplies && comment.children && 
                    <Replies>
                        {
                            comment.children.map((reply, index) => {
                                return <Wrapper>
                                    <CommentNumber>
                                        <img src={commentPin} alt="pin" />
                                        <span>{index}</span>
                                    </CommentNumber>
                                    <CommentInfo>
                                        <span>{reply.author.name}, </span>
                                        <span>{socialConnect.timeDifference(new Date(reply.createdAt))}</span>
                                    </CommentInfo>
                                    <CommentText>
                                        {reply.comment}
                                    </CommentText>
                                </Wrapper>      
                            })
                        }
                    </Replies>
            } */}
        </Wrapper>
    )
}

export default SidebarComment
