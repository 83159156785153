"use strict";

const fastAutoDetect = (() => {
  let _contours = null;

  const _setAutoDetectedRooms = (polygonList) => {
    _contours = polygonList;
    return true;
  };
  const _appendAutoDetectedRooms = (polygon) => {
    if (_contours == null) {
      _contours = new Array();
    }
    _contours.push(polygon);
    return true;
  };

  const _getAutoDetectedRooms = () => {
    return _contours;
  };

  const _clearData = () => {
    _contours = null;
    return true;
  };

  const setPolygons = (polygonList) => {
    return _setAutoDetectedRooms(polygonList);
  };
  const appendPolygons = (polygon) => {
    return _appendAutoDetectedRooms(polygon);
  };

  const getPolygons = () => {
    const contours = _getAutoDetectedRooms();
    _clearData();
    return contours;
  };

  const isEmpty = () => {
    if (_contours == null) {
      return true;
    }
    return false;
  };

  return {
    setPolygons: setPolygons,
    getPolygons: getPolygons,
    appendPolygons: appendPolygons,
    isEmpty: isEmpty,
    clear: function () {
      _contours = null;
    },
  };
})();
export { fastAutoDetect };
