import axios from "axios";
import _ from "lodash";
import { showToast } from "../snaptrude/modules/extrafunc";
import { Action as DnDAction } from "../snaptrude/stateManagers/reducers/objectProperties/dndSlice";
import { addFoldersRedux, findFolderRecursive, initializeFileSystemRedux, removeFolderRedux, updateFolderRedux, moveItemsRedux, removeItemsRedux, addItemsRedux } from "../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import reduxStore from "../snaptrude/stateManagers/store/reduxStore";
import { getFormData } from "./helper";
import { ORIGIN } from "./url.constants";

export const validateFolderName = (folderName) => {
    const status = {
        isValid: false,
        reason: "",
    }
    folderName = _.trim(folderName)
    if(folderName.length < 3){
        status.reason = "Folder name too short, must be at least 3 characters."
        return status;
    } 
    status.isValid = true;
    return status;
}
export const initializeFileSystem = async (teamID, folderID="root", offset=0, limit=100) => {
    limit = 20;
    let endpoint = "/folder/";
    if(teamID != null) endpoint = `/team/${teamID}/folder/`;
    const data = {
        folder: folderID,
        offset: offset,
        limit: limit,
        sortby: "latest"
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        const folders = resp.data.folders || [];
        const projects = resp.data.projects || [];
        reduxStore.dispatch(initializeFileSystemRedux({teamID: teamID, folders: folders, projects: projects}))
        return {status: "success", folders: folders, projects: projects};
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}


export const getFilesForFolder = async (teamID, folderID="root") => {
    let endpoint = "/folder/";
    if(teamID != null) endpoint = `/team/${teamID}/folder/`;

    let offset = 0;
    const limit = Number.MAX_SAFE_INTEGER;
    const data = {
        folder: folderID,
        offset: offset,
        limit: limit,
        sortby: "latest"
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        reduxStore.dispatch(addFoldersRedux({teamID, parentFolderID: folderID, folders: resp.data.folders, projects: resp.data.projects, parents: resp.data.parents}))
        return {status: "success", folders: resp.data.folders, projects: resp.data.projects};
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}

export const createFolder = async (name, teamID, parent) => {
    let endpoint = "/folder/create";
    if(teamID != null) endpoint = `/team/${teamID}/folder/create`;
    if(parent == null) parent = "root";
    const data = {
        parent: parent,
        name: name,
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        reduxStore.dispatch(addFoldersRedux({teamID, parentFolderID: parent, folders: [resp.data.folder], projects: [], useParentFolderID: true, append: true}))
        return {status: "success", folder: resp.data.folder};
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}

export const deleteFolder = async (teamID, folder) => {
    let endpoint = "/folder/delete";
    if(teamID != null) endpoint = `/team/${teamID}/folder/delete`;
    const data = {
        folder: folder.id,
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        reduxStore.dispatch(removeFolderRedux({teamID, parentFolderID: folder.immediate_parent, folderID: folder.id}))
        return {status: "success", folder: resp.data.folder};
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}

export const updateFolderProperties = async (teamID, folder, updatedProperties) => {
    let endpoint = "/folder/rename";
    if(teamID != null) endpoint = `/team/${teamID}/folder/rename`;
    const folderID = folder.id;
    const parentFolderID = folder.immediate_parent;
    const data = {
        folder: folderID,
        ...updatedProperties
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        reduxStore.dispatch(updateFolderRedux({teamID, parentFolderID, folderID, updatedProperties}))
        return {status: "success", folder: resp.data.folder};
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}

export const getFolderProjectCount = async (teamID, folderID) => {
    let endpoint = "/folder/count";
    if(teamID != null) endpoint = `/team/${teamID}/folder/count`;
    const data = {
        folder: folderID,
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        return { status: "success", folder_count: resp.data.folder_count, project_count: resp.data.project_count };
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}

const formatFoldersData = (folders) => {
    if(!Array.isArray(folders)) return []

    return folders.map(f => ({
        all_parents: f.all_parents,
        created_by: f.created_by_id,
        deleted: f.deleted,
        id: f.id,
        immediate_parent: f.immediate_parent_id,
        last_updated_by: f.last_updated_by_id,
        name: f.name,
        teamId: f.team_dashboard_id,
        team_dashboard: f.team_dashboard_id,
        user_dashboard: f.user_dashboard_id,
        type: "folder",
    }))
}

const formatProjectsData = (projects, localProjectsData) => {
    if(!Array.isArray(projects)) return []

    const lookUp = {}

    if(Array.isArray(localProjectsData)) localProjectsData.forEach(p => lookUp[p.key] = p)

    return projects.map(p => {
        const localData = lookUp[p.floorkey]

        return ({
            added: p.added,
            updated: p.updated,
            createdby: localData?.createdby,
            desc: localData?.desc,
            floorkey: p.floorkey,
            folderId: p.folder_id,
            isSpeckleImportDone: p.is_speckle_import_done,
            key: p.floorkey,
            name: localData?.name,
            public: p.public,
            speckleStreamId: p.speckle_stream_id,
            team_dashboard: p.team_id,
            threeDUrl: p.threeDImage,
            url: localData?.url,
            type: "file",
        })
    })
}

export const moveToFolder = async (teamID, sourceFolderId, destinationFolderId, foldersId, floorkeys, folders, projects) => {
    let endpoint = "/folder/move";
    if(teamID != null) endpoint = `/team/${teamID}/folder/move`;

    const data = {
        destinationfolder: destinationFolderId,
        folders: foldersId,
        floorkeys
    }

    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        delete resp.data.message
        const foldersData = formatFoldersData(resp.data?.folders)
        const projectsData = formatProjectsData(resp.data?.floorplans, projects)
        reduxStore.dispatch(removeItemsRedux([teamID, foldersData, projectsData, sourceFolderId]))
        reduxStore.dispatch(addItemsRedux([teamID, destinationFolderId, foldersData, projectsData]))
        reduxStore.dispatch(DnDAction.reset({ objectId: "folders" }))
        return { status: "success", data: resp.data };
    } catch (error) {
        console.log("Error move", error)
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong while moving. Please try again!"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.message || error.response.data?.error || returnObject.message;
        }
        showToast(returnObject.message, 3000)
        return returnObject;
    }
}

