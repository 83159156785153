import { colors } from "../../themes/constant";

export const topNav = {
  defaultOptionContainer: {
    backgroundColor: colors.transparent,
    borderWidth: 0,
    color: colors.darkGrey,
    width: "8rem",
    marginBottom: "0.25rem",
    marginRight: "1rem",
  },
  defaultText: {
    color: colors.darkGrey,
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  selectedText: {
    color: colors.primeBlack,
    fontSize: "1.125rem",
    fontWeight: "bold",
  },
  selectedOptionContainer: {
    backgroundColor: colors.transparent,
    color: colors.darkGrey,
    borderBottom: `0.25rem solid ${colors.brightRed}`,
    width: "8rem",
    borderRadius: 0,
    marginRight: "1rem",
  },
};
