import React from "react";
import { useHistory } from "react-router-dom";
import { PROJECT_DROPDOWN_OPTIONS, TEAM_ROLES } from "../../containers/dashboard/TeamDashboard/constants";
import { ORIGIN } from "../../services/url.constants";
import Card from "../Card";
import editAccessIcon from "../../assets/icons/editAccess.svg";
import viewAccessIcon from "../../assets/icons/viewAccess.svg";
import { getFooterText } from "../../common/utils/helper";

const ALLOWED_ROLES = [TEAM_ROLES.ADMIN, TEAM_ROLES.EDITOR, TEAM_ROLES.CREATOR];

const getImageUrl = threeDUrl => {
    return ORIGIN + '/media/' + threeDUrl;
};
function ProjectCard({ project, selectedTeam, viewBy, parentFolderID }) {
    const history = useHistory();

    const takeToProject = (floorkey) => {
        history.push("/model/" + floorkey, { dashboardRoute: window.location.pathname });
    }
    if (selectedTeam == null) {
        return (
            <React.Fragment>
                <Card
                    onClick={() => {
                        window.analytics.track('opened an existing project from dashboard');
                        takeToProject(project.key);
                    }}
                    headerText={project.name}
                    floorkey={project.key}
                    description={project.desc === "None" ? "" : project.desc}
                    footerText={getFooterText(viewBy, project.updated, project.added, project.createdby)}
                    dropdownOptions={PROJECT_DROPDOWN_OPTIONS}
                    avatars={[]}
                    key={`${project.key}`}
                    image={getImageUrl(project.threeDUrl)}
                    accessIcon={editAccessIcon}
                    isDisabled={project.speckleStreamId != null && project.isSpeckleImportDone === false}
                    teamId={null}
                    parentFolderID={parentFolderID}
                />
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Card
                onClick={() => {
                    window.analytics.track('opened an existing project from dashboard');
                    takeToProject(project.key);
                }}
                headerText={project.name}
                floorkey={project.key}
                description={project.desc === "None" ? "" : project.desc}
                footerText={getFooterText(viewBy, project.updated, project.added, project.createdby)}
                dropdownOptions={ALLOWED_ROLES.includes(selectedTeam.role) ? PROJECT_DROPDOWN_OPTIONS : []}
                avatars={[]}
                key={`${selectedTeam.id}-${project.key}`}
                image={getImageUrl(project.threeDUrl)}
                accessIcon={selectedTeam.roleBasedPermissions[selectedTeam.role]['edit_model'] ? editAccessIcon : viewAccessIcon}
                isDisabled={project.speckleStreamId != null && project.isSpeckleImportDone === false}
                teamId={selectedTeam.id}
                parentFolderID={parentFolderID}
            // cardWrapperStyle={{border: "5px solid cadetblue", width: "29rem"}}
            />
        </React.Fragment>
    );
}

export default ProjectCard;