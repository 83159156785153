import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input";
import { RecentBox } from "../../components/SortByBox";
import * as styles from "./styles";
import { colors } from "../../themes/constant";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { handleToolbar } from "../../snaptrude/libs/handleToolbar";
import ProgressBar from "../../components/ProgressBar";
import { useSelector } from "react-redux";
import {TailSpin} from "react-loader-spinner";

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
`;

export const updateExportProgressBar = (progressPercent, text, showProgressFooter = true) => {
  let event = new CustomEvent("exportProgress", {
    detail: {
      progress: progressPercent,
      text: text,
      showProgressFooter: showProgressFooter
    },
  })
  window.dispatchEvent(event);
}
function Export({ isOpen, onClose }) {
  const project = useSelector(state => state.projectMetadata);
  const [fileName, setFileName] = useState(project.name);
  const [selectedOption, setSelectedOption] = useState("trude");
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressText, setProgressText] = useState("Exporting ...");

  const handleExport = () => {
    setShowProgressBar(true);
    window.analytics.track(`export project - ${selectedOption}`);
    handleToolbar(selectedOption, fileName === "" ? "Untitled" : fileName);
  }
  const getExportBody = () => {
    return (
      <FlexContainer
        flexDirection="column"
        alignItems="normal"
        justifyContent="flex-start"
      >
        <FlexContainer>
          <Text opacity={1}>Project</Text>
          <Text opacity={1}>Project {store.floorkey}</Text>
        </FlexContainer>
        <FlexContainer>
          <Text opacity={1}>File Name</Text>
          <Input type="text" placeholder="Enter exported file name" value={fileName} customOnChange={(e) => {setFileName(e.target.value)}} />
        </FlexContainer>
        <FlexContainer>
          <Text opacity={1}>Select Format</Text>
          <RecentBox
            customDropDownStyle={styles.recentBoxStyle.customDropdownStyle}
            customBoxStyles={styles.recentBoxStyle.boxStyle}
            selectedItemColor={colors.primeBlack}
            isOptionElement={true}
            customSelectedTextStyle={styles.recentBoxStyle.selectedOptionStyle}
            selectOption={(opt) => {setSelectedOption(opt.key)}}
            options={[
              <FlexContainer key={"trude"}>
                <span>Snaptrude File</span>
                <span>.trude</span>
              </FlexContainer>,
              <FlexContainer key={"revitExport"}>
                <span>Revit export File</span>
                <span>.trude</span>
              </FlexContainer>,
              <FlexContainer key={"3ds"}>
                <span>3D Studio</span>
                <span>.3ds</span>
              </FlexContainer>,
              <FlexContainer key={"dwg"}>
                <span>AutoCAD Drawing</span>
                <span>.dwg</span>
              </FlexContainer>,
              <FlexContainer key={"fbx"}>
                <span>Motion Builder</span>
                <span>.fbx</span>
              </FlexContainer>,
              <FlexContainer key={"ifc"}>
                <span>Industry Foundation Classes</span>
                <span>.ifc</span>
              </FlexContainer>,
              <FlexContainer key={"obj"}>
                <span>OBJ</span>
                <span>.obj</span>
              </FlexContainer>,
              <FlexContainer key={"glb"}>
                <span>GLB</span>
                <span>.glb</span>
              </FlexContainer>,
              <FlexContainer key={"rvt"}>
                <span>Revit Model</span>
                <span>.rvt</span>
              </FlexContainer>,
              <FlexContainer key={"pdf"}>
                <span>PDF</span>
                <span>.pdf</span>
              </FlexContainer>,
            ]}
            withIcon={true}
          />
        </FlexContainer>
      </FlexContainer>
    );
  };

  const getExportFooter = () => {
    window.addEventListener("exportProgress", (evt) => {
      if (evt.detail) {
        setProgress(evt.detail.progress);
        setProgressText(evt.detail.text);
        setShowProgressBar(evt.detail.showProgressFooter);
      }
    });
    return (
      <>
        {showProgressBar ?
          <ProgressBar bgColor={colors.red} completed={progress} text={progressText} setParentShowProgressBar={p => {setShowProgressBar(p)}}/> :
          <Button
            title="Export"
            primary
            customButtonStyle={styles.exportStyles.exportCustomButtonStyle}
            onPress={handleExport}
          />
        }
        </>
    );
  };
  return (
    <Modal
      modalHeaderText="Export Model"
      modalBody={getExportBody()}
      customBodyStyle={styles.exportStyles.modalBodyStyle}
      modalFooter={getExportFooter()}
      customFooterStyle={showProgressBar ? styles.exportStyles.progressStyle : styles.exportStyles.footerStyle}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

Export.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Export;
