import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "../Button/index";
import { topNav } from "./styles";
import { colors } from "../../themes/constant";

const ContentContainer = styled.div`
  margin: 1rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0 solid ${colors.veryLightGrey};
  border-bottom-width: 0.063rem;
`;

const TopNav = ({ navOptions, selectedOption, onPress }) => {
  return (
    <ContentContainer>
      <Container>
        {navOptions.map((item, index) => {
          const isSelected = item === selectedOption;
          return (
            <Button
              key={index}
              customButtonStyle={
                isSelected
                  ? topNav.selectedOptionContainer
                  : topNav.defaultOptionContainer
              }
              onPress={() => onPress(item)}
              customButtonTextStyle={
                isSelected ? topNav.selectedText : topNav.defaultText
              }
              title={item}
            />
          );
        })}
      </Container>
    </ContentContainer>
  );
};

TopNav.propTypes = {
  navOptions: PropTypes.array,
  selectedOption: PropTypes.any,
  onPress: PropTypes.func,
};

export default TopNav;
