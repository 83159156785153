import { showToast } from "../../snaptrude/modules/extrafunc";

const jwt = require("jsonwebtoken");

export const getInvitedUserFromCode = (code) => {
    try {
        const invited = jwt.verify(code, process.env.REACT_APP_INVITATION_SECRET);
        return {success: true, data: invited}
    } catch (error) {
        console.error(error)
        return {success: false, data: null, message: error.message}
    }
}

export const showAutoSaveMessage = () => {
    showToast("Snaptrude automatically saves your project 😊");
}