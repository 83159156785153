import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { AutoSave } from "../socket/autoSave.js";
import { deepCopyObject } from "../extrafunc.js";
import { LayerCollection } from "./layer.ds.js";
import { DoublyLinkedList } from "../Classes/doublyLinkedList.js";
/*jshint esversion: 6 */
/**
 * { Storey Data Structure}
 * @param structure_id
 * @param value
 * @param base
 * @param height
 * @constructor
 */
var Storey = function (structure_id, value, height = 11.811023622047244) {
  this.id = "st_" + Math.random().toString(36).substr(2, 9);
  this.name = "";
  this.type = "storey";
  this.structure_id = structure_id;
  this.base = null;
  this.height = height;
  this.elements = [];
  this.value = value;
  this.hidden = false;
  this.layerData = null;
  this.revitMetaData = {};
};

Storey.prototype.getBase = function () {
  return this.base;
};

Storey.prototype.getHeight = function () {
  return this.height;
};

Storey.prototype.setHeight = function (newHeight) {
  this.height = newHeight;
};

Storey.prototype.setBase = function (newBase) {
  this.base = newBase;
};

Storey.prototype.setName = function(name){
  this.name = name
}

Storey.prototype.addRevitLevels = function(lowerLevel, upperLevel){
  this.revitMetaData.revitLowerLevel = lowerLevel;
  this.revitMetaData.revitUpperLevel = upperLevel;
};

Storey.prototype.addElement = function (element) {
  element.storey = this.value;
  element.mesh.storey = this.value;
  this.elements.push(element);
};

Storey.prototype.getElement = function (uniqueId) {
  return this.elements.filter((ele) => ele.mesh.uniqueId === uniqueId)[0];
};

Storey.prototype.isEmpty = function () {
  return this.elements.length === 0;
};

Storey.prototype.removeElement = function (element) {
  for (let i = 0; i < this.elements.length; i++) {
    let elem = this.elements[i];
    if (elem.mesh.uniqueId === element.mesh.uniqueId) {
      this.elements.splice(i, 1);
      return;
    }
  }
};

Storey.prototype.filterElements = function (types) {
  let typeArray = types.map((type) => type.toLowerCase());
  return this.elements.filter((m) =>
    typeArray.includes(m.mesh.type.toLowerCase())
  );
};

Storey.prototype.serialize = function () {
  let serializedData = {};

  let entries = Object.entries(this);
  entries.forEach(function (entry) {
    if (
      !_.isFunction(entry[1]) &&
      entry[0] !== "elements" &&
      entry[0] !== "layerData"
    ) {
      serializedData[entry[0]] = entry[1];
    }
  });

  serializedData.elements = this.elements.map(
    (element) => element.mesh.uniqueId
  );

  serializedData.layers = [];
  let layers = this.layerData.getAllLayers();
  for (let layer in layers) {
    serializedData.layers.push(layers[layer].serialize());
  }

  return serializedData;
};

function StoreyCollection() {
  var storeyCollection = {};
  var count = 1;
  var positiveStoreysCount = 1;
  var negativeStoreysCount = 0;

  let saveStoreyInBackend = function (newStorey) {
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = newStorey.id;
    saveData.data.identifier = {
      structure_id: newStorey.structure_id,
      floorkey: store.floorkey,
      storey: newStorey.value.toString(), //CHECK AUTO
    };
    saveData.data.afterOperationData = deepCopyObject(newStorey);
    saveData.data.afterOperationData.layers = [];

    saveData.data.saveType = "addStorey";

    AutoSave.directPublish(saveData);
  };

  return {
    addStorey: function (structure_id, value, height, options = {}) {
      function _addDefaultLayersToStorey(newStorey, options) {
        let layerData = new LayerCollection();

        // Add standard layers
        layerData.addLayer('Wall', structure_id, 'wall', value, options);
        layerData.addLayer('Rough', structure_id, 'rough', value, options);

        newStorey.layerData = layerData;
      }

      function _calculateBase(storey) {
        let value = storey.value;

        // First storey base is always zero
        if (value === 1) return 0;

        let previousValue;
        if (value > 0) previousValue = value - 1;
        else if (value === -1) previousValue = 1;
        else previousValue = value + 1;

        let previousStorey = this.getStoreyByValue(previousValue);

        if (previousStorey) {
          return value > 0
            ? previousStorey.getBase() + previousStorey.getHeight()
            : previousStorey.getBase() - storey.getHeight();
        } else {
          // Occurs during recreate scene. Base is updated later using storey data
          return null;
        }
      }

      if(options.autoSave === undefined ){
        options.autoSave = true;
      }

      

      for (let storeyId in storeyCollection) {
        let storey = storeyCollection[storeyId];
        if (parseInt(storey.value) === parseInt(value)) {
          storey.exists = true;
          return storey;
        }
      }
      storeyCollection[value] = new Storey(structure_id, value, height);

      count++;
      if (parseInt(value) > 0) positiveStoreysCount++;
      else negativeStoreysCount++;

      let newStorey = storeyCollection[value];
      let base = _calculateBase.call(this, newStorey);
      newStorey.setBase(base);
      if(options.revitLowerLevel || options.revitUpperLevel){
        newStorey.addRevitLevels(options.revitLowerLevel, options.revitUpperLevel);
      }
      if(options.name){
        newStorey.setName(options.name)
      }
      if (options.autoSave) {
        saveStoreyInBackend(newStorey);
      }
      _addDefaultLayersToStorey(newStorey, options);

      return newStorey;
    },
    getStoreysLength: function () {
      return count;
    },
    getPositiveStoreysLength: function () {
      return positiveStoreysCount;
    },
    getNegativeStoreysLength: function () {
      return negativeStoreysCount;
    },
    getAllStoreys: function () {
      return storeyCollection;
    },
    getStoreyByValue: function (value) {
      for (let storeyId in storeyCollection) {
        let storey = storeyCollection[storeyId];
        if (parseInt(storey.value) === parseInt(value)) return storey;
      }
      return null;
    },
    getStoreyByRevitLevel: function(levelName){
      for (let storeyId in storeyCollection) {
        let storey = storeyCollection[storeyId];
        if (storey.name === levelName) return storey;
      }
      return null;
    },
    deleteStorey: function (value) {
      for (let storeyId in storeyCollection) {
        if (storeyCollection[storeyId].value === value) {
          delete storeyCollection[storeyId];
          count--;
        }
      }
    },
    deleteAllStoreys: function () {
      for (let storeyId in storeyCollection) {
        delete storeyCollection[storeyId];
      }
      count = 1;
    },
    calculateStoreyValueByBase: function (element) {
      function getNegativeStoreyCollection() {
        return _.pickBy(storeyCollection, function (value) {
          return value.value < 0;
        });
      }

      function getPositiveStoreyCollection() {
        return _.pickBy(storeyCollection, function (value) {
          return value.value > 0;
        });
      }

      function getHeightsArray(negativeStoreysFlag) {
        let heightsArr = [];

        if (negativeStoreysFlag) {
          let tempStoreyCollection = getNegativeStoreyCollection();
          for (let storeyId in tempStoreyCollection) {
            heightsArr.push(tempStoreyCollection[storeyId].getHeight());
          }
        } else {
          let tempStoreyCollection = getPositiveStoreyCollection();
          for (let storeyId in tempStoreyCollection) {
            heightsArr.push(tempStoreyCollection[storeyId].getHeight());
          }
        }

        return heightsArr;
      }

      function calculateStoreyValueFromHeights(
        baseHeight,
        totalHeight,
        heightsArray
      ) {
        if (baseHeight <= totalHeight && baseHeight >= 0) {
          if (heightsArray.length > 1) {
            for (let i = 1; i <= heightsArray.length; i++) {
              let sumUpToIndex = _.round(
                heightsArray.slice(0, i).reduce((acc, cur) => acc + cur),
                4
              );

              if (baseHeight <= sumUpToIndex) {
                if (baseHeight < sumUpToIndex - heightsArray[i - 1] / 2)
                  return i;
                else return i + 1;
              }
            }
          } else {
            if (baseHeight < totalHeight / 2) return 1;
            else return 2;
          }
        }
        else if (baseHeight < 0 && baseHeight >= -totalHeight) {
          if (heightsArray.length > 0) {
            for (let i = 1; i <= heightsArray.length; i++) {
              let sumUpToIndex = _.round(
                heightsArray.slice(0, i).reduce((acc, cur) => acc + cur),
                4
              );

              if (baseHeight >= -sumUpToIndex) {
                if (baseHeight > -(sumUpToIndex - (heightsArray[i - 1] / 2))) return i === 1 ? 1 : -i + 1;
                else return -i;
              }
            }
          } else {
            if (baseHeight > -totalHeight / 2) return 1;
            else return -1;
          }
        } else if (baseHeight < 0 && baseHeight < -totalHeight) {
          let diff = baseHeight + totalHeight;
          let quotient = Math.ceil(Math.abs(diff) / store.floor_height);
          const tolerance = _.round(store.floor_height / 2, 4);

          if (
            baseHeight <
            -(
              totalHeight +
              _.round(quotient * store.floor_height, 4) -
              tolerance
            )
          )
            return -negativeStoreysCount - quotient;
          else return -negativeStoreysCount - quotient + 1;
        } else {
          let diff = baseHeight - totalHeight;
          let quotient = Math.ceil(diff / store.floor_height);
          const tolerance = _.round(store.floor_height / 2, 4);

          if (
            baseHeight >
            totalHeight + _.round(quotient * store.floor_height, 4) - tolerance
          )
            return positiveStoreysCount + quotient;
          else return positiveStoreysCount + quotient - 1;
        }
      }

      if (element) {
        element.mesh.computeWorldMatrix(true);
        let bBox = element.mesh.getBoundingInfo().boundingBox;
        let baseOfMesh;

        if (element.type.toLowerCase() === "roof") {
          baseOfMesh = _.round(bBox.maximumWorld.y, 4);
        } else {
          baseOfMesh = _.round(bBox.minimumWorld.y, 4);
        }

        let heightsArray = getHeightsArray(baseOfMesh < 0);
        let totalHeight =
          heightsArray.length !== 0
            ? _.round(
                heightsArray.reduce((acc, cur) => acc + cur),
                4
              )
            : 11.811;

        return calculateStoreyValueFromHeights(
          baseOfMesh,
          totalHeight,
          heightsArray
        );
      }
    },
    generateStoreys: function (structureId, value) {
      let storeysArr = [];
      if (value > 0) {
        for (let i = positiveStoreysCount; i <= value; i++) {
          this.addStorey(structureId, i);
          storeysArr.push(i);
        }
      } else {
        for (let i = -(negativeStoreysCount + 1); i >= value; i--) {
          this.addStorey(structureId, i);
          storeysArr.push(i);
        }
      }

      return storeysArr;
    },
    updateSubsequentBase: function (value) {
      let storeyArr = [];
      if (value > 0 && value < positiveStoreysCount) {
        for (let i = value; i < positiveStoreysCount - 1; i++) {
          let storey = this.getStoreyByValue(i);
          let nextStorey = this.getStoreyByValue(i + 1);

          nextStorey.setBase(storey.getBase() + storey.getHeight());
          storeyArr.push(i + 1);
        }
      } else if (value < 0 && value > -negativeStoreysCount) {
        for (let i = value; i > -negativeStoreysCount; i--) {
          let storey = this.getStoreyByValue(i);
          let nextStorey = this.getStoreyByValue(i - 1);

          nextStorey.setBase(storey.getBase() - nextStorey.getHeight());
          storeyArr.push(i - 1);
        }
      }

      return storeyArr;
    },
  };
}

function StoreysLinkedListCluster() {
  var cluster = {};
  var count = 0;

  return {
    addNewLinkedList: function (id) {
      if (id === undefined)
        id = "dll_" + Math.random().toString(36).substr(2, 9);
      Object.defineProperty(cluster, id, {
        enumerable: true,
        configurable: true,
        writable: true,
      });
      cluster[id] = new DoublyLinkedList(id);
      count++;
      return cluster[id];
    },
    getAllLinkedList: function () {
      return cluster;
    },
    getLinkedList: function (id) {
      return cluster[id];
    },
    removeLinkedList: function (id) {
      delete cluster[id];
    },
  };
}
export { Storey, StoreyCollection, StoreysLinkedListCluster };
