export const UNITS_TYPES = [
  {
      "id": 0,
      "value": "meters",
      "name": "Metres",
      "$$hashKey": "object:772",
      "$$mdSelectId": 6
  },
  {
      "id": 1,
      "value": "millimeter",
      "name": "Millimeters",
      "$$mdSelectId": 5,
      "$$hashKey": "object:773"
  },
  {
      "id": 2,
      "value": "centimeter",
      "name": "Centimeters",
      "$$hashKey": "object:774",
      "$$mdSelectId": 7
  },
  {
      "id": 3,
      "value": "inches",
      "name": "Inches",
      "$$hashKey": "object:775",
      "$$mdSelectId": 8
  },
  {
      "id": 4,
      "value": "feet-inches",
      "name": "Feet-inches",
      "$$hashKey": "object:776",
      "$$mdSelectId": 9
  }
]