import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js"
import { storeyView } from "./storeyView.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import { computeArea } from "./areaFuncs.js";
import {djangoUrl} from "../../services/url.constants";
import {setUserSettingAndRecord} from "../modules/extrafunc";
import reduxStore from "../stateManagers/store/reduxStore.js";
import { updateScreenshotUiData } from "../stateManagers/reducers/objectProperties/screenshotUiDataSlice.js";
/**
 * ScopeUtils module contains all Snaptrude UI related functions
 * which handles angular $scope data changes
 *
 * @module ScopeUtils
 *
 */
var ScopeUtils = (function () {
  let $scope = null;

  /**
   * Sets the angular scope
   * @param scope
   */
  function setScope(scope) {
    $scope = scope;
  }

  /**
   * Returns the angular scope
   * @returns {*}
   */
  function getScope() {
    return store.$scope;
  }

  /**
   * Gives the factor by which the BABYLON units must be multiplied to get the units set by the user.
   * Advantage: No using of $scope in your functionality code.
   * @returns {number}
   */
  function getUnitFactor() {
    if (store.$scope.units_type.value === "feet-inches") {
      return 10;
    }
    if (store.$scope.units_type.value === "inches") {
      return 10;
    } else if (store.$scope.units_type.value === "meters") {
      return 25.4;
    } else if (store.$scope.units_type.value === "millimeter") {
      return 25.4;
    } else if (store.$scope.units_type.value === "centimeter") {
      return 25.4;
    }
    return 1;
  }

  /**
   * Updates the entire storeys accordian
   */
  function updateStoreys() {
    storeyView.generateStoreyUiData();
  }

  /**
   * Adds new storey to the storeys accordian
   * @param storey
   */
  function addStorey(storey) {
    store.$scope.newStorey = storeyView.getStoreyData(storey);
    if (storey.value > 0) {
      store.$scope.storeysUiData.unshift(store.$scope.newStorey);
    } else {
      store.$scope.storeysUiData.push(store.$scope.newStorey);
    }
  }
  /**
   * Adds view img data to scope for accordion
   * @param view_data
   */
  function addViewData(view_data) {
    let pathOrg = djangoUrl + "/media/";
    if (store.$scope.sceneViewData.length > 0) store.$scope.sceneViewData.length = 0;
    if( !_.isEmpty(view_data) ){
      for (let i = 0; i < view_data.length; i++) {
        if(view_data[i][0].includes("None"))  continue;
        if (view_data[i][0].split("/")[3].split("_").length > 2) {
          let fName = view_data[i][0].split("/")[3].split("_");
          for (let j = 0; j < fName.length - 1; j++) {
            fName.pop();
          }
          let file =
              fName.join("_") + "." + view_data[i][0].split("/")[3].split(".")[1];
          let path = view_data[i][0].split("/");
          path.pop();
          let pathVal = path.join("/") + "/" + file;
          view_data[i][0] = pathVal;
        }
        store.$scope.sceneViewData.push({
          path: pathOrg + view_data[i][0],
          imgKey: JSON.parse(view_data[i][1]),
          camData: JSON.parse(view_data[i][2]),
          textureDetail: view_data[i][4],
          screenshotTitle: "ScreenShot Title " + JSON.parse(view_data[i][1]),
          highlighted: false,
        });
      }
    }
    reduxStore.dispatch(updateScreenshotUiData({sceneViewData: store.$scope.sceneViewData}))
  }

  /**
   * Adds new layer given storey value to the storeys accordian
   * @param layer
   */
  function addLayer(layer) {
    let storey = storeyView.getStoreyByValue(layer.storey);
    storey.layers.push(storeyView.getLayerData(layer));
  }

  /**
   * Changes a particular storey height given new height value
   * @param storey
   * @param height
   */
  function changeStoreyHeight(storey, height) {
    let _storeyOfInterest = storeyView.getStoreyByValue(storey);
    // $scope.timeoutService refers to $timeout dependency injected into main controller
    $scope.timeoutService(function () {
      _storeyOfInterest.height =
        DisplayOperation.convertToDefaultDimension(height);
    });
  }

  /**
   * Sets the toggle of storey to hide or show elements
   * @param storey
   * @param visibility
   */
  function toggleHideOrShowStorey(storey, visibility) {
    let _storeyOfInterest = storeyView.getStoreyByValue(storey);
    // $scope.timeoutService refers to $timeout dependency injected into main controller
    $scope.timeoutService(function () {
      expandAccordian("Storeys");
      _storeyOfInterest.hidden = !(
        StoreyMutation._CONSTANTS.show === visibility
      );
      $scope.storeyUItoggle[storey] = _storeyOfInterest.hidden;
    });
  }

  /**
   * Sets the toggle of layer to hide or show elements in a layer
   * @param storey
   * @param layerName
   */
  function toggleHideOrShowLayer(storey, layerName) {
    let layer = storeyView.getLayerByName(storey, layerName);
    // $scope.timeoutService refers to $timeout dependency injected into main controller
    $scope.timeoutService(function () {
      expandAccordian("Storeys");
      layer.hidden = !layer.hidden;
    });
  }

  /**
   * Function to expand a particular accordian if given heading, else expand entire accordian
   * @param heading
   */
  function expandAccordian(heading = null) {
    $scope.timeoutService(function () {
      if (heading) {
        let accordian = $scope.accordianData.filter(
          (acc) => acc.heading === heading
        )[0];
        accordian.expanded = true;
      } else {
        $scope.accordianData.forEach((acc) => {
          if (acc.heading !== "Areas") acc.expanded = true;
        });
      }
    });
  }

  /**
   * Function to close a particular accordian if given heading, else close entire accordian
   * @param heading
   */
  function closeAccordian(heading = null) {
    $scope.timeoutService(function () {
      if (heading) {
        let accordian = $scope.accordianData.filter(
          (acc) => acc.heading === heading
        )[0];
        accordian.expanded = false;
      } else {
        $scope.accordianData.forEach((acc) => {
          acc.expanded = false;
        });
      }
    });
  }

  /**
   * Function to set the display input value
   * @param value
   */
  function setDisplayInputValue(value) {
    store.$scope.tape_measure_value = value;
  }
  /**
   * Function to get Display input current value
   * @returns {number}
   */
  function getDisplayInputCurrentValue() {
    return store.$scope.tape_measure_value;
  }

  /**
   * Function to get Display input value before change
   * @returns {number|*}
   */
  function getDisplayInputOldValue() {
    return store.$scope.old_tape_measure_value;
  }

  /**
   * Function to get the type of object to snap to for an interior
   * @returns {string}
   */
  function getInteriorComponentSnapToProperty() {
    return store.$scope.snap_to.value;
  }

  /**
   * Function to check if cut hole on a wall is required
   * @returns {boolean}
   */
  function getInteriorComponentCutHoleProperty() {
    return store.$scope.cutHole;
  }

  function hideSketchTool() {
    $scope.sketchToolHidden = true;
  }

  function showSketchTool() {
    $scope.sketchToolHidden = false;
  }

  function getSketchColor() {
    return $scope.colorP.match(/\d+/g).map((val) => parseInt(val) / 255);
  }

  function updateSiteArea(mesh, value) {
    if (mesh) {
      let siteArea = store.$scope.areas.site_area;
      if (!siteArea) siteArea = 0.0;
      siteArea = parseFloat(siteArea);
      siteArea += Math.round(
        DisplayOperation.convertToDefaultArea(computeArea(mesh)) * 100
        ) / 100;

      store.$scope.areas.site_area = siteArea;
      const event = new CustomEvent("siteAreaUpdate", {
        detail: {
          siteArea: store.$scope.areas.site_area
        }
      });
      window.dispatchEvent(event);
    }
    else if (value) {
      store.$scope.areas.site_area = value;
    }

    setUserSettingAndRecord("siteArea", store.$scope.areas.site_area);
    // store.$scope.updateSiteArea();
  }

  function getRoomHeight(roomLabel) {
    let roomTypes = store.$scope.room_types;
    /*
        roomTypes format is {name, value, height, room_type}
        name and value are subTypes
         */
    let roomTypeInfo = _.find(roomTypes, (info) => {
      return info.name.toLowerCase() === roomLabel.toLowerCase();
    });

    if (roomTypeInfo) {
      return roomTypeInfo.height;
    }
  }

  function getRoomType(subType) {
    let roomTypes = store.$scope.room_types;
    /*
        roomTypes format is {name, value, height, room_type}
        name and value are subTypes
         */
    let roomTypeInfo = _.find(roomTypes, (info) => {
      return info.name.toLowerCase() === subType.toLowerCase();
    });

    if (roomTypeInfo) return roomTypeInfo.room_type;
    else return "default";
  }

  function updateProjectProperties() {
    for (let key in store.userSettingsInStructure) {
      switch (key) {  
        case "unitsType":
          store.$scope.units_type = _.find(store.$scope.units_types, function (unit) {
            return unit.id === store.userSettingsInStructure[key];
          });
          break;
        case "tolerance":
          store.$scope.unit_tolerance = _.find(
            store.tolerance_levels,
            function (tolerance) {
              return tolerance.id === store.userSettingsInStructure[key];
            }
          );
          break;
      }
    }
  }

  function populateRoomTypes() {
    store.$scope.room_types = store.room_labels;
    store.room_labels = null;
  }

  function getRoomSubTypes(mainType) {
    let roomTypes = store.room_types;
    /*
        roomTypes format is {name, value, height, room_type}
        name and value are subTypes
         */
    let subTypes = roomTypes
      .filter((obj) => obj.room_type.toLowerCase() === mainType.toLowerCase())
      .map((obj) => obj.value);

    return subTypes;
  }

  function hideToolBar() {
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: called hideToolBar()");
    // $scope.timeoutService(function () {
    //   $scope.snaptrudeToolbar = true;
    // });
  }

  function showToolBar() {
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: called showToolBar()");
    // $scope.timeoutService(function () {
    //   $scope.snaptrudeToolbar = false;
    // });
  }

  function hideSection() {
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: called hideSection()");
    // $scope.timeoutService(function () {
    //   $scope.snaptrudeSection = true;
    // });
  }

  function showSection() {
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: called showSection()");
    // $scope.timeoutService(function () {
    //   $scope.snaptrudeSection = false;
    // });
  }

  function getCurrentUnit() {
    return store.$scope.units_type.value;
  }

  function isStoreyUpUnique() {
    let input = document.getElementById("storey-up-unique");
    return input.value === "on";
  }

  function isStoreyDownUnique() {
    let input = document.getElementById("storey-down-unique");
    return input.value === "on";
  }

  function getExportFileName() {
    return $scope.export_file_name;
  }

  function applyAsync(callback) {
    // console.warn("AG-RE: called applyAsync");
    // $scope.$applyAsync(callback);
  }

  function updateDiffuseColor(value) {
    $scope.$apply(function () {
      $scope.matcolorP = value;
    });
  }

  function loadMaterialsByType(genMats, type, overrideLoad = false) {
    let matTypeIndexInTabs = $scope.tabs.findIndex(
      (t) => t.title.toLowerCase() === type.toLowerCase()
    );

    if (matTypeIndexInTabs === -1) {
      $scope.tabs.push({
        title: type,
        content: [],
      });
      matTypeIndexInTabs = $scope.tabs.length - 1;
    }

    if ($scope.tabs[matTypeIndexInTabs].loaded) {
      if (!overrideLoad) return;
    } else $scope.tabs[matTypeIndexInTabs].loaded = true;

    let genMatsFiltered = genMats.filter(
      (g) => g[1].toLowerCase() === type.toLowerCase()
    );

    genMatsFiltered.forEach((mat) => {
      $scope.tabs[matTypeIndexInTabs].content.push({
        name: mat[0],
        propurl: window.location.origin + "/media/" + mat[0],
        area: 0,
        volume: 0,
      });
    });
  }

  function loadFurnitureType(genObjs, type, overrideLoad = false) {
    let furnitureTypeIndexInTabs = $scope.intTabs.findIndex(
      (t) => t.title.toLowerCase() === type.toLowerCase()
    );

    if (furnitureTypeIndexInTabs === -1) {
      $scope.intTabs.push({
        title: type,
        content: [],
      });
      $scope.comp_types.push({ value: type, name: type });
      furnitureTypeIndexInTabs = $scope.intTabs.length - 1;
    }

    if ($scope.intTabs[furnitureTypeIndexInTabs].loaded) {
      if (!overrideLoad) return;
    } else $scope.intTabs[furnitureTypeIndexInTabs].loaded = true;

    genObjs.forEach((f) => {
      if (!f[1]) return;
      if (!f[3] || ["None"].includes(f[3])) return;
      if (!f[4] || ["None"].includes(f[4])) return;
      if (f[1].toLowerCase() !== type.toLowerCase()) return;
      $scope.intTabs[furnitureTypeIndexInTabs].content.push({
        name: f[0],
        type: f[1],
        subType: f[2],
        propurl: window.location.origin + f[3],
        imgurl: window.location.origin + f[4],
        area: 0,
        volume: 0,
      });
    });
  }

  function loadFenestrationByType(
    genObjs,
    type,
    subType,
    overrideLoad = false
  ) {
    let fenestrationTypeIndexInTabs = $scope.fenestrationTabs.findIndex(
      (t) => t.title.toLowerCase() === type.toLowerCase()
    );

    if (fenestrationTypeIndexInTabs === -1) return;

    let fenestrationContent =
      $scope.fenestrationTabs[fenestrationTypeIndexInTabs].content;
    let fenestrationSubTypeIndex = fenestrationContent.findIndex(
      (t) => t.subType.toLowerCase() === subType.toLowerCase()
    );

    if (fenestrationSubTypeIndex === -1) return;

    if (fenestrationContent[fenestrationSubTypeIndex].loaded) {
      if (!overrideLoad) return;
    } else fenestrationContent[fenestrationSubTypeIndex].loaded = true;

    genObjs.forEach((f) => {
      if (!f[1]) return;
      if (f[1].toLowerCase() !== type.toLowerCase()) return;
      if (!f[2] || ["null", "na", "None"].includes(f[2])) return;
      if (f[2].toLowerCase() !== subType.toLowerCase()) return;
      fenestrationContent[fenestrationSubTypeIndex].contents.push({
        name: f[0],
        type: f[1],
        subType: f[2],
        propurl: window.location.origin + f[3],
        imgurl: window.location.origin + f[4],
        area: 0,
        volume: 0,
      });
    });
  }

  function loadLibraryTypesInTabs(library, libraryType) {
    let types = library.map((m) => m[1]);

    let defaultTypes;
    switch (libraryType) {
      case "material":
        defaultTypes = [
          "colors",
          "concrete",
          "glass",
          "metal",
          "tiles",
          "stone",
          "wood",
        ];
        break;
      case "furniture":
        defaultTypes = [
          "default",
          "living",
          "bedroom",
          "kitchen",
          "dining",
          "bathroom",
          "balcony",
          "door",
          "window",
        ];
        break;
    }
    types = _.compact(types).filter(
      (t) => !defaultTypes.includes(t.toLowerCase())
    );
    types = types.map((t) => t.toLowerCase());
    types
      .filter((v, i, a) => a.indexOf(v) === i)
      .forEach((type) => {
        let tabs = libraryType === "material" ? $scope.tabs : $scope.intTabs;
        let title = type.charAt(0).toUpperCase() + type.slice(1);
        tabs.push({
          title: title,
          content: [],
        });

        if (libraryType === "furniture") {
          $scope.comp_types.push({ value: title, name: title });
        }
      });
  }

  return {
    setScope,
    getScope,
    getUnitFactor,
    updateStoreys,
    addStorey,
    addLayer,
    changeStoreyHeight,
    toggleHideOrShowStorey,
    toggleHideOrShowLayer,
    addViewData,
    expandAccordian,
    closeAccordian,
    setDisplayInputValue,
    getDisplayInputCurrentValue,
    getDisplayInputOldValue,
    getInteriorComponentSnapToProperty,
    getInteriorComponentCutHoleProperty,
    hideSketchTool,
    showSketchTool,
    getSketchColor,
    getRoomHeight,
    getRoomType,
    populateRoomTypes,
    updateProjectProperties,
    getRoomSubTypes,
    hideToolBar,
    showToolBar,
    hideSection,
    showSection,
    getCurrentUnit,
    isStoreyUpUnique,
    isStoreyDownUnique,
    getExportFileName,
    applyAsync,
    updateDiffuseColor,
    updateSiteArea,
    loadLibraryTypesInTabs,
    loadMaterialsByType,
    loadFurnitureType,
    loadFenestrationByType,
  };
})();
export { ScopeUtils };
