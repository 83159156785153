class APIError extends Error {
  constructor(message, code) {
    super("There was a problem with " +
      "request to server: " + message);
    this.code = code;
  }
}
class GFSLoadError extends Error {
  constructor(message, data) {
    super("Problem recreating GFS: " + message);
    this.GFS = data;
  }
}

export {APIError, GFSLoadError}

