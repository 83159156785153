'use strict';

import _ from "lodash";
import GridNode from "./GridNode";
import roomLayout from "./roomLayout";

class Grid {
  
  constructor(nRows, nColumns) {
    
    Grid.DEBUG.counter = 0;
    
    this.nRows = nRows;
    this.nColumns = nColumns;
    
    this.nRowsMinus1 = this.nRows - 1;
    this.nColumnsMinus1 = this.nColumns - 1;
    this._rawData = new Map();
    
    _.range(nRows).forEach(nRow => {
      
      const rowElements = [];
      
      _.range(nColumns).forEach(nColumn => {
        rowElements.push(new GridNode(nRow, nColumn, this));
      });
      
      this._rawData.set(nRow, rowElements);
    });
  }
  
  getElement(i, j){
    return this._rawData.get(i)[j];
  }
  
  getNeighbors(gridNode){
    
    const neighbors = [];
    
    const i = gridNode.i;
    const j = gridNode.j;
    
    let nextI = i === this.nRowsMinus1 ? null : i + 1;
    let nextJ = j === this.nColumnsMinus1 ? null : j + 1;
    
    let previousI = i === 0 ? null : i - 1;
    let previousJ = j === 0 ? null : j - 1;
    
    if (_.isNumber(previousI)) neighbors.push(this.getElement(previousI, j));
    if (_.isNumber(previousJ)) neighbors.push(this.getElement(i, previousJ));
    if (_.isNumber(nextI)) neighbors.push(this.getElement(nextI, j));
    if (_.isNumber(nextJ)) neighbors.push(this.getElement(i, nextJ));
    
    return neighbors;
  }
  
  sweep(){
    const startingNode = this._getStartingNode();
    if (!startingNode) return;
    
    const maxRight = Math.floor(this.nColumns / 2);
    const maxDown = Math.floor(this.nRows / 2);
    
    const i = startingNode.i;
    const j = startingNode.j;
    
    const nStepRightLimit = Math.min(this.nColumnsMinus1 - j, maxRight);
    let nStepRightMax = _.random(1, nStepRightLimit);
    let nStepRight = 1;
    // let nStepRight = Grid.DEBUG.steps[Grid.counter][0];
    
    let nStepRightNode = this.getElement(i, j + nStepRight);
    while (nStepRight <= nStepRightMax){
      if (nStepRightNode.isBoundary){
        const nStepRightNodeKeNeecheKa = this.getElement(i + 1, j + nStepRight);
        if (nStepRightNodeKeNeecheKa.isBoundary){
          // this means we've hit a wall, so no increment
          break;
        }
        else {
          nStepRight++;
          nStepRightNode = this.getElement(i, j + nStepRight);
        }
      }
      else if (nStepRightNode.isUncovered){
        nStepRight++;
        nStepRightNode = this.getElement(i, j + nStepRight);
      }
    }
    
    const nStepDownLimit = Math.min(this.nRowsMinus1 - i, maxDown);
    let nStepDownMax = _.random(1, nStepDownLimit);
    let nStepDown = 1;
    // let nStepDown = Grid.DEBUG.steps[Grid.counter][1];
    
    let nStepDownNode = this.getElement(i + nStepDown, j);
    while (nStepDown <= nStepDownMax){
      if (nStepDownNode.isBoundary){
        const nStepDownNodeKeRightSideKa = this.getElement(i + nStepDown, j + 1);
        if (nStepDownNodeKeRightSideKa.isBoundary){
          break;
        }
        else {
          nStepDown++;
          nStepDownNode = this.getElement(i + nStepDown, j);
        }
      }
      else {
        nStepDown++;
        nStepDownNode = this.getElement(i + nStepDown, j);
      }
    }
    
    if (roomLayout.isInDebugMode()){
      console.log('nStepRight', nStepRight);
      console.log('nStepDown', nStepDown);
    }
    
    const boundaryNodesForThisSweep = [];
    for (let currentRow = i; currentRow <= i + nStepDown; currentRow++){
      for (let currentColumn = j; currentColumn <= j + nStepRight; currentColumn++){
        const node = this.getElement(currentRow, currentColumn);
        
        if (currentRow === i || currentColumn === j ||
          currentRow === i + nStepDown || currentColumn === j + nStepRight) boundaryNodesForThisSweep.push(node);
        else node.cover();
      }
    }
    
    boundaryNodesForThisSweep.forEach(node => {
      const neighbors = this.getNeighbors(node);
      const notCoveredNeighbors = neighbors.filter(node => !node.isCovered);
      
      const uncoveredAndNotInCurrentSweep = _.difference(notCoveredNeighbors, boundaryNodesForThisSweep);
      if (_.isEmpty(uncoveredAndNotInCurrentSweep)) node.cover();
      else node.markAsBoundary();
    });
    
    Grid.DEBUG.counter++;
    
    return [
      this.getElement(i, j),
      this.getElement(i, j + nStepRight),
      this.getElement(i + nStepDown, j + nStepRight),
      this.getElement(i + nStepDown, j),
    ];
  }
  
  draw(){
    console.log('drawing...');
    
    this._rawData.forEach((rowNodes, nRow) => {
      const stringRepresentations = rowNodes.map(node => {
        return node.isCovered ? '*' : node.isBoundary ? '/' : 'o';
      });
      
      console.log(_.join(stringRepresentations, ' '));
    });
  }
  
  _getStartingNode(){
    const boundaryNodes = this._getBoundaryNodes();
    
    boundaryNodes.sort((node1, node2) => {
      if (node1.i === node2.i) return node1.j - node2.j;
      else return node1.i - node2.i;
    });
    
    return boundaryNodes[0];
  }
  
  _getAllNodes(){
    return _.flatten(Array.from(this._rawData.values()));
  }
  
  _getBoundaryNodes(){
    return this._getAllNodes().filter(node => node.isBoundary);
  }
  
}

Grid.DEBUG = {
  counter : 0,
  steps : [
    [1, 2],
    [3, 4],
    [3, 4],
    [1, 3],
    [1, 2],
    [2, 2],
    [3, 2],
    [2, 2],
    [1, 1],
    [3, 1],
    [1, 1],
  ]
};


export default Grid;
