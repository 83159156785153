import React, { useEffect, useRef, useState } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import logoNew from "../../assets/snaptrudeIcon.svg";
import dropButton from "../../assets/icons/angularDropDown.svg";
import whiteDropdown from "../../assets/icons/whiteDropdown.svg";
import Button from "../Button/index";
import * as NavConstants from "./TopNavImports";
import * as StyledComponents from "./StyledComponents";
import { CheckBoxList } from "./subMenuDropDowns/checkboxList";
import { ToggleList } from "./subMenuDropDowns/toggleList";
import { SliderDateTimePicker } from "./subMenuDropDowns/sliderDateTimePicker";
import { ExportButtonDropdown } from "./subMenuDropDowns/exportButtonDropdown";
import * as constants from "./constants";
import GenericModal from "../../containers/genericModal/index";
import image from "../../assets/icons/image.svg";
import cad from "../../assets/icons/autoCad.svg";
import cube from "../../assets/icons/objectImage.svg";
import pdf from "../../assets/subMenuIcon/import/importpdf.svg";
import { handleToolbar } from "../../snaptrude/libs/handleToolbar";
// import TopographyModal from "../../containers/genericModal/TopographyModal";
import SnaptrudeModal from "../SnaptrudeModal";
import { SnaptrudeDropzone } from "../SnaptrudeModal/SnaptrudeDropzone";
import TopographyModal from "../SnaptrudeModal/TopographyModal";
import DaylightAnalysisSettingsModal from "../SnaptrudeModal/DaylightAnalysisSettingsModal";
import SpeckleRevitImportModal from "../SnaptrudeModal/SpeckleRevitImportModal";
import { cadUpload, imageUpload, modelUpload } from "../../snaptrude/dropzone/dropzoneApiCalls";
import sunpath from "../../snaptrude/modules/sunpath/sunpathOperations";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import { routes } from "../../routes/constants";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { viewOperation } from "../../snaptrude/libs/viewOperation";
import { areaView, updateArea } from "../../snaptrude/libs/areaView";
import { materialScheduleView } from "../../snaptrude/libs/materialScheduleView";
import { materialScheduleViewSplit } from "../../snaptrude/libs/materialScheduleViewSplit";
import OnboardingTooltip from "../OnboardingTooltip";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import {
  setProjectName as setProjectNameAction
} from "../../snaptrude/stateManagers/reducers/objectProperties/projectMetadataSlice";
import { renameProject } from "../../services/projects.service";
import { blurOnEnter } from "../../snaptrude/libs/keyEvents";
import Tooltip from "../Tooltip";
import { TOOLS_MAPPING } from "../../snaptrude/modules/utilityFunctions/CONSTANTS/tools_mapping";
import { filterSelection } from "../../snaptrude/libs/selectToolFuncs";
import { updateFilter } from "../../snaptrude/stateManagers/reducers/objectProperties/meshSelection";
import { colors } from "../../themes/constant";
import CopyLink from "../CopyLink";
import { importPdfOperations } from "../../snaptrude/libs/importPdfOperations";
import SnaptrudeImportFunctionalityModal from "../SnaptrudeModal/SnaptrudeImportFunctionalityModal";
import { FakeProgressEvent } from "../../snaptrude/modules/FakeProgressEvent/FakeProgressEvent";
import Divider from "../Divider";
import styled from "styled-components";
import AddComment from "../Comment/AddComment";
import Comment from "../Comment/Comment";
import { commentOperator } from "../../snaptrude/libs/commentEvents";
import commentPinInactive from "../../assets/icons/commentPinInactive.svg";
import { shortcut } from "../../containers/editor/shortcutKeys";
import ActiveUserList from "../ActiveUserList";
import { updateCursor } from "../../containers/editor/cursorHandler";
import { cursor } from "../../themes/cursor";
import { terrainGeneration } from "../../snaptrude/modules/geo/terrainMap";
import { intSelect } from "../../snaptrude/libs/toolbarEvents";
import useOutsideClickHandler from "../../common/utils/useOutsideClickHandler";
import { isOrthographicView } from "../../snaptrude/libs/sceneFuncs";
import CoveHelper from "../../snaptrude/modules/covetool";
import reduxStore from "../../snaptrude/stateManagers/store/reduxStore";
import { PROJECT_DROPDOWN_OPTIONS } from "../../containers/dashboard/TeamDashboard/constants";
import SpeckleRevitImport from "../SnaptrudeModal/SpeckleRevitImport";
import TopRightIcons from "./TopRightIcons";
import RenderSettingsModal from "../SnaptrudeModal/RenderSettingsModal";

const LEAVE_PAGE_CONFIRMATION = "Your project is being AutoSaved. If you close your work might not get saved.";

const AddCommentWrapper = styled.div`
  position: fixed;
  display: none;
`
const ShowCommentWrapper = styled.div`
  position: fixed;
  display: none;
`

const MODEL_TYPE_OPTIONS = [
  // "Revit",
  "Rhino",
  "SketchUp",
  "3D Model"
]

const DROPDWON_OPTIONS = [
  {title: "Rename"},
  {title: "Save as", permissionKey: "save_as_model"},
  {title: "Divider"},
  {title: "Export Model/Drawing", permissionKey: "export_model"},
  {title: "Export Areas", permissionKey: "export_area_statements"},
  {title: "Export BoQ", permissionKey: "export_boq"},
  {title: "Export Detailed BoQ", permissionKey: "export_boq"},
  // {title: "Export Comment history"},
  {title: "Export Views"},
  {title: "Divider"},
  {title: "Recent Files"},
  // {title: "Create New Project"},
]

const handleCommentDialogClose = () => {
  const commentWrapper = document.getElementById("add-comment-wrapper");
  if(commentWrapper) commentWrapper.style.display = "none";
}

const FilteredSelection = ({handleFilterSelection, onClose, ...props}) => {
  const meshSelection = useSelector(state => state.meshSelection);
  const [filteredSelectionList, setFilteredSelectionList] = useState([]);
  useEffect(() => {
    setFilteredSelectionList([]);
    setTimeout(() => {
      setFilteredSelectionList(meshSelection.filtered);

    }, 10)
  }, [meshSelection]);
  return <CheckBoxList 
  list={
    filteredSelectionList.map(item => {
      return{
        title: item.title,
        isChecked: item.isChecked,
        onPress: (toSelect) => {handleFilterSelection(item.value, toSelect)}
      }
    })
  } 
  onClose={onClose}
  textAlign={'left'}
/>;
}

const ViewSettings = ({viewsSettings, onViewsListChange, onClose}) => {
  useEffect(() => {
    const isTextured = store.userSettingsInStructure.textureDetail === "texture";
    const key = isTextured ? "Textured" : "Monochrome";
    onViewsListChange(key, true);
    onViewsListChange("Orthographic", isOrthographicView());
  }, []);
  return <ToggleList list={viewsSettings} onListChange={onViewsListChange} onClose={onClose} />;
}

const ProjectDropdown = ({setInProjectRenameMode, setProjectRenameField, exportDirectOrToggleModalForExportButton, showProjectDropDown, onClose, projectMetadata, teams}) => {
  const [options, setOptions] = useState(DROPDWON_OPTIONS)
  const ref = useRef(null);
  useOutsideClickHandler(ref, onClose);
  useEffect(() => {
    let currentProjectTeam = null;
    if(projectMetadata.isTeamsProject){
      currentProjectTeam = teams.teamlist.find(team => team.id === projectMetadata.team.id);
    }
    if(currentProjectTeam){
      const permissions = currentProjectTeam.roleBasedPermissions[currentProjectTeam.role]
      const newOptions = DROPDWON_OPTIONS.filter(option => {
        const property = option.permissionKey;
        if(property){
          return permissions[property];
        }
        return true;
      })
      setOptions(newOptions);
    }
  }, [])
  return <div
    className="dropdown-content visible-dropdown-content"
    ref={ref}
  >
    {
      options.map((item, index) => {
    
        if(item.title === "Divider"){
          return <Divider key={index} />
        }
        else if (item.title === "Rename"){
          return (
            <p
              key={index}
              onClick={() => {
                setInProjectRenameMode(true);
                setProjectRenameField(projectMetadata.name);
              }}
              >
              {item.title}
            </p>
          );
        }
        else if (item.title === "Save as"){
          return (
            <p
              key={index}
              onClick={() =>
                exportDirectOrToggleModalForExportButton("SaveAs")
              }
            >
              {item.title}
            </p>
          );
        }
        else if (item.title === "Export Model/Drawing"){
          return (
            <p
              key={index}
              onClick={() =>
                exportDirectOrToggleModalForExportButton("Export")
              }
            >
              {item.title}
            </p>
          );
        }else if(item.title === "Export Areas"){
          return <p
              key={index}
              onClick={() =>
                exportDirectOrToggleModalForExportButton("Areas")
              }
            >
              {item.title}
            </p> 
        }else if(item.title === "Export BoQ"){
          return <p
              key={index}
              onClick={() =>
                exportDirectOrToggleModalForExportButton("BOQ")
              }
            >
              {item.title}
            </p>
        }else if(item.title === "Export Detailed BoQ"){
          return <p
              key={index}
              onClick={() =>
                exportDirectOrToggleModalForExportButton("BOQSplit")
              }
            >
              {item.title}
            </p>
        }
        else if(item.title === "Export Views"){
          return <p
              key={index}
              onClick={() =>
                exportDirectOrToggleModalForExportButton("Views")
              }
            >
              {item.title}
            </p>
        }
        else if (item.title === "Recent Files"){
          return <Link style={{textDecoration: "none"}} to={routes.dashboard} key={index}><p>{item.title}</p></Link>
        }
        else{
          return <p key={index}>{item.title}</p>;
        }
        
      })
    }
  </div>
}

const GetSubMenuDropdown = (props) => {
  const {
    icon,
    selectedTime,
    selectedDate,
    selectedMonth,
    selectedMonthText,
    selectedTimeText,
    viewsSettings, 
    onViewsSettingsChange,
    onViewsListChange,
    setSelectedDate,
    setSelectedMonth,
    setSelectedTime,
    setSelectedMonthText,
    setSelectedTimeText,
    onClose
  } = props;

  const dispatch = useDispatch();
  const storeys = useSelector(state => state.storeys);

  const [storeySelected, setStoreySelected] = useState(new Array(storeys.items.length).fill(false));

  const handleStoreyToggle = (storeyValue, toSelect, index) => {
    const newStoreySelected = storeySelected.slice();
    newStoreySelected[index] = toSelect;
    setStoreySelected(newStoreySelected);
    filterSelection("storey", toSelect, storeyValue);
  }

  const handleFilterSelection = (type, toSelect) => {
    filterSelection(type, toSelect);
    dispatch(updateFilter({ type, toSelect }));
  }

  switch (icon) {
    case "storeySelector":
      return <CheckBoxList 
        list={storeys.items.map((storey, index) => {
          return {
            title: `${storey.name ? storey.name : "Storey " + storey.value}`,
            isChecked: storeySelected[index],
            onPress: (value) => {handleStoreyToggle(storey.value, value, index)}
          }
        })}
        onClose={onClose}
      />;
    case "checkboxSelector":
        return <FilteredSelection 
          handleFilterSelection={handleFilterSelection}  
          onClose={onClose}
        />
    case "settings":
      return <ViewSettings viewsSettings={viewsSettings} onViewsListChange={onViewsListChange} onClose={onClose} />
    case "shadowsdropdown":
      return (
        <SliderDateTimePicker
          selectedDate={selectedDate}
          selectedMonth={selectedMonth}
          selectedTime={selectedTime}
          setSelectedDate={setSelectedDate}
          setSelectedMonth={setSelectedMonth}
          setSelectedTime={setSelectedTime}
          selectedMonthText={selectedMonthText}
          selectedTimeText={selectedTimeText}
          setSelectedMonthText={setSelectedMonthText}
          setSelectedTimeText={setSelectedTimeText}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};

class TopMenuBar extends React.Component {
  constructor(props) {
    super(props);

    const dateTime = new Date();
    this.state = {
      importModalVisible: false,
      uploadedFile: null,
      importStorey: null,
      page: 1,
      pdfPageCount: 1,
      isPdfPageCountLoading: false,
      scale: "",
      imageModalOpen: false,
      cadModalOpen: false,
      pdfModalOpen: false,
      materialModalOpen: false,
      topographyModalVisible: false,
      analysisModalVisible: false,
      speckleRevitModalVisible:false,
      recentArray: [],
      exportButtonDropVisible: false,
      permission: "Anyone With the link can view",
      subPermission: "Can View",
      openShareModal: false,
      exportModalType: "",
      showProjectDropDown: false,
      selectedDate: dateTime.getDate(),
      selectedMonth: dateTime.getMonth(),
      selectedTime: (dateTime.getHours() * 2) + parseInt(dateTime.getMinutes()/30) /* dateTime.getHours() * 60 + dateTime.getMinutes() */,
      selectedMonthText: constants.MONTH_ARRAY[dateTime.getMonth()],
      selectedTimeText: `${
        dateTime.getHours() < 10
          ? `0${dateTime.getHours()}`
          : dateTime.getHours()
      }:${
        dateTime.getMinutes() < 10
          ? `0${dateTime.getMinutes()}`
          : dateTime.getMinutes()
      }`,
      exportViewResolution: "1920 x 1080",
      exportViewFormat: ".jpg",
      showProgressBar: false,
      progress: 0,
      progressText: "Uploading",
      inProjectRenameMode: false,
      projectRenameField: props.projectMetadata.name,
      modelType: MODEL_TYPE_OPTIONS[0],
      selectedComment: null,
      hasInviteToModalPermission: true,
      currentProjectTeam: null,
    };
    this.dropdownNode = React.createRef();
  }

  setSelectedComment = (comment) => {
    this.setState({
      selectedComment: comment
    })
  }

  handleProgressChagne = (evt) => {
    if(evt.detail){
      this.setProgress(evt.detail.progress);
      this.setProgressText(evt.detail.text);
    }
  }

  handleCollabToolSelect = (evt) => {
    const {tool} = evt.detail;

    const isCommentModeSelected = this.props.selectedSubMenu === "commentmode";
    if(tool === null){
      if(isCommentModeSelected){
        this.props.handleToolSelection("commentmode", "top");
      }
    }
  }

  handleSetCommentEvent = (evt) => {
    this.setSelectedComment(evt.detail);
  }

  handleEscapeEvent = (evt) => {
    if(evt.key === "Escape"){
      const evt = {
        detail: {
          tool: null
        }
      }
      this.handleCollabToolSelect(evt);
      handleCommentDialogClose();
      handleToolbar("disableComment")
    }
  }
  
  clearCommentsIfNotSidebarOpen = () => {
    if(this.props.selectedPanelImage !== "Comments") commentOperator.clearCommentPins();
  }

  handleKeyUp = (evt) => {
    const key = evt.which || evt.keyCode;
    const isCtrlUp = key === 17;
    if(isCtrlUp && this.props.selectedSubMenu === "commentmode"){
      // document.body.style.cursor = `url(${commentPinInactive}), auto`;
      updateCursor(cursor.comment);
    }
  }
  componentDidMount = () => {
    document.addEventListener("mousedown", this.onPressOutSubMenu);
    window.addEventListener("dwfuploadprgress", this.handleProgressChagne);
    window.addEventListener("collaboration-tool-select", this.handleCollabToolSelect);
    window.addEventListener("set-comment", this.handleSetCommentEvent);
    window.addEventListener('keydown', this.handleEscapeEvent, true);
    window.addEventListener('clear-comments-if-not-sidebar-open', this.clearCommentsIfNotSidebarOpen);
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("screenshot-view", this.handleScreenshotViewEvent);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  };
  
  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.onPressOutSubMenu);
    window.removeEventListener("dwfuploadprgress", this.handleProgressChagne);
    window.removeEventListener("collaboration-tool-select", this.handleCollabToolSelect);
    window.removeEventListener("set-comment", this.handleSetCommentEvent);
    window.removeEventListener('keydown', this.handleEscapeEvent, true);
    window.removeEventListener('clear-comments-if-not-sidebar-open', this.clearCommentsIfNotSidebarOpen);
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("screenshot-view", this.handleScreenshotViewEvent);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.projectMetadata.speckleStreamId && prevProps.projectMetadata.speckleStreamId !== this.props.projectMetadata.speckleStreamId){
      this.setState({speckleRevitModalVisible: true})
    }
    if(prevProps.projectMetadata.team.id !== this.props.projectMetadata.team.id){
      const {teams, projectMetadata} = this.props;
      let currentProjectTeam = null;
      if(projectMetadata.isTeamsProject){
        currentProjectTeam = teams.teamlist.find(team => team.id === projectMetadata.team.id);
      }
      if(currentProjectTeam){
        const permissions = currentProjectTeam.roleBasedPermissions[currentProjectTeam.role];
        this.setState({
          hasInviteToModalPermission: permissions.invite_users_to_model,
          currentProjectTeam: currentProjectTeam
        })
      }
    }
  }
  
  handleScreenshotViewEvent = (evt) => {
    const {detail} = evt;
    const {shadowData, texture} = detail;
    const {shadowToggle, date} = shadowData;
    const shadowTool = "graphical";
    
    if(shadowToggle){
      // acitvate the shadow tool if not active
      if(!this.props.selectedTopMultipleMenus.includes(shadowTool)){
        this.props.handleToolSelection(shadowTool, "top")
      }
      // set the date
      this.setSelectedMonth(date.month);
      this.setSelectedDate(date.date);
      this.setSelectedTime(date.time);
    }else{
      if(this.props.selectedTopMultipleMenus.includes(shadowTool)){
        this.props.handleToolSelection(shadowTool, "top")
      }
    }

    // handle texture
    const key = texture === "texture" ? "Textured" : "Monochrome";
    const value = true;
    let newViewsSettings = JSON.parse(JSON.stringify(this.props.viewsSettings));
    if(["Shaded", "Textured", "Monochrome"].includes(key)){
      newViewsSettings[0].list = newViewsSettings[0].list.map(item => {
        if(item.title === key) return {...item, isActive: true};
        return {...item, isActive: false}
      })
      newViewsSettings[0].initalActiveItems = [`${key}`]
    }else{
      newViewsSettings = newViewsSettings.map(item => {
        if(item.isGroup) return item;
        if(item.title === key) return {...item, isActive: value}
        return item;
      })
    }
    this.props.onViewsSettingsChange(newViewsSettings);
  }
  
  setInProjectRenameMode = (inProjectRenameMode) => {
    this.setState({ inProjectRenameMode: inProjectRenameMode});
  }
  
  setProjectRenameField = (projectRenameField) => {
    this.setState({ projectRenameField: projectRenameField});
  }

  // setImageModalOpen = (imageModalOpen) => {
  //   this.setState({ imageModalOpen: imageModalOpen });
  // }
  setUploadedFile = (uploadedFile) => {
    this.setState({ uploadedFile: uploadedFile});
  }
  onPdfFileUpload = (uploadedFile) => {
    this.setState({ 
      uploadedFile: uploadedFile,
      page: 1,
      pdfPageCount: 1,
      isPdfPageCountLoading: true,
    });
    const fileReader = new FileReader();
    fileReader.readAsDataURL(uploadedFile.file);
    fileReader.onloadend = async (evt) => {
      let _pdfMetaData = await importPdfOperations.fetchPDFMetaData(evt.target.result);
      this.setState({
        pdfPageCount: _pdfMetaData.totalNumberOfPages,
        isPdfPageCountLoading: false,
      })
    }
  }
  setPdfPage = (newPage) => {
    this.setState({
      page: newPage
    })
  }
  setImportStorey = (storey) => {
    this.setState({ importStorey: storey});
  }
  setScale = (newScale) => {
    this.setState( {scale: newScale} )
  }
  closeImportModal = (modalName) => {
    this.setState({ showProgressBar: false });
    this.props.closeImportModal(modalName)
    // this.setState({ [modalName]: false });
    this.setUploadedFile(null);
    this.setState({ importStorey: null });
    terrainGeneration.storeLastSearchedLocation();
  };
  setModelType = (type) => {
    this.setState({
      modelType: type
    })
  }

  setSelectedDate = (date) => {
    this.setState({ selectedDate: date });
    sunpath.changeSunPos(this.state.selectedMonth, date, this.state.selectedTime);
  };

  setSelectedMonth = (month) => {
    this.setState({ selectedMonth: month });
    sunpath.changeSunPos(month, this.state.selectedDate, this.state.selectedTime);
  };

  setSelectedTime = (minutes) => {
    this.setState({ selectedTime: minutes });
    sunpath.changeSunPos(this.state.selectedMonth, this.state.selectedDate, minutes);
  };

  setSelectedMonthText = (value) => {
    this.setState({ selectedMonthText: value });
  };

  setSelectedTimeText = (value) => {
    this.setState({ selectedTimeText: value });
  };

  toggleShareModal = () => {
    const { openShareModal } = this.state;
    this.setState({
      openShareModal: !openShareModal,
    });
  };

  setPermission = (permission) => {
    this.setState({ permission });
  };
  setSubPermission = (subPermission) => {
    this.setState({ subPermission });
  };
  setProgress = (val) => {
    this.setState({
      progress: val
    })
  }
  setProgressText = (text) => {
    this.setState({
      progressText: text
    })
  }

  toggleExportDropdown = () => {
    const { exportButtonDropVisible } = this.state;
    this.setState({
      exportButtonDropVisible: !exportButtonDropVisible,
    });
  };

  handleExportView = () => {
    // console.log(store.$scope.screenshotsSelectedInUI);
    store.$scope.images = store.$scope.screenshotsSelectedInUI.filter(screenshot => screenshot.highlighted);
    if(store.$scope.images.length === 0 ) return false;
    // store.$scope.images = store.$scope.sceneViewData.map((screenshot) => {
    //   return {
    //     highlighted: true,
    //     ...screenshot
    //   }
    // })
    // console.log(store.$scope.images);
    return viewOperation.exportAllSelectedViews(this.state.exportViewResolution, this.state.exportViewFormat);
  }

  getSubMenuImagesArrayByOption = () => {
    const { selectedMenu, viewType2D } = this.props;
    const { recentArray } = this.state;
    let subMenuImagesArrayByOption = []
    switch (selectedMenu) {
      case "Select":
        subMenuImagesArrayByOption = NavConstants.SELECT_SUB_MENU;
        break;
      case "Design":
        if (viewType2D) subMenuImagesArrayByOption = NavConstants.DRAW_2D_SUB_MENU;
        else subMenuImagesArrayByOption = NavConstants.DRAW_3D_SUB_MENU;
        break;
      case "Import":
        subMenuImagesArrayByOption = NavConstants.IMAGE_SUB_MENU;
        break;
      case "Measure":
        if (viewType2D) subMenuImagesArrayByOption = NavConstants.MEASURE_SUB_MENU
        else subMenuImagesArrayByOption = NavConstants.MEASURE_3D_SUB_MENU;
        break;
      case "Views":
        subMenuImagesArrayByOption = NavConstants.VIEWS_SUB_MENU;
        break;
      case "ViewsForAnalysisPage":
        subMenuImagesArrayByOption = NavConstants.VIEWS_SUB_MENU_FOR_COVE_PROJECT;
        break;
      case "Automate":
        subMenuImagesArrayByOption = NavConstants.AUTOMATE_SUB_MENU;
        break;
      case "Collaborate":
        subMenuImagesArrayByOption = NavConstants.COLLABORATE_SUB_MENU;
        break;
      case "Recent":
        subMenuImagesArrayByOption = recentArray;
        break;
      case "Analysis":
        subMenuImagesArrayByOption = NavConstants.ANALYSIS_SUB_MENU;
        break;
      default:
        subMenuImagesArrayByOption = [
          ["", ""],
          ["", ""],
        ];
    }
    const {teams, projectMetadata} = this.props;
    let currentProjectTeam = null;
    if(projectMetadata.isTeamsProject){
      currentProjectTeam = teams.teamlist.find(team => team.id === projectMetadata.team.id);
    }
    if(currentProjectTeam){
      const permissions = currentProjectTeam.roleBasedPermissions[currentProjectTeam.role]
      subMenuImagesArrayByOption = subMenuImagesArrayByOption.filter(subMenuImageArray => {
        const property = subMenuImageArray[2]?.permissionKey
        if(property) {
          return permissions[property];
        }
        return true;
      })
    }
    return subMenuImagesArrayByOption;
  };

  toggleModalForExportButton = (option) => {
    this.setState({ exportModalType: option });
    this.toggleShareModal();
  };

  exportDirectOrToggleModalForExportButton = async (option) => {
    if (option === "Areas"){
      await updateArea();
      areaView.exportCSV();
    } 
    else if (option === "BOQ") materialScheduleView.exportCSV();
    else if (option === "BOQSplit") {
      materialScheduleViewSplit.generateMatSchedule();
    }
    else {
      this.setState({exportModalType: option});
      this.toggleShareModal();
    }
  }

  onPressOutSubMenu = (e) => {
    if (
      this.dropdownNode &&
      this.dropdownNode.current &&
      this.dropdownNode.current.contains(e && e.target)
    ) {
      return;
    }
  };
  
  formatProjectName = function (name){
    if (name.length > 15){
      return name.substring(0, 12) + "...";
    }
    else return name;
  };
  
  getTopLeftDropDown = function (){
    return [
      "Rename",
      "Save as",
      "Divider",
      "Export Model/Drawing",
      "Export Areas",
      "Export BoQ",
      "Export Detailed BoQ",
      // "Export Comment history",
      "Export Views",
      "Divider",
      "Recent Files",
      // "Create New Project",
    ].map((item, index) => {
  
      if(item === "Divider"){
        return <Divider key={index} />
      }
      else if (item === "Rename"){
        return (
          <p
            key={index}
            onClick={() => {
              this.setInProjectRenameMode(true);
              this.setProjectRenameField(this.props.projectMetadata.name);
            }}>
            {item}
          </p>
        );
      }
      else if (item === "Save as"){
        return (
          <p
            key={index}
            onClick={() =>
              this.exportDirectOrToggleModalForExportButton("SaveAs")
            }
          >
            {item}
          </p>
        );
      }
      else if (item === "Export Model/Drawing"){
        return (
          <p
            key={index}
            onClick={() =>
              this.exportDirectOrToggleModalForExportButton("Export")
            }
          >
            {item}
          </p>
        );
      }else if(item === "Export Areas"){
        return <p
            key={index}
            onClick={() =>
              this.exportDirectOrToggleModalForExportButton("Areas")
            }
          >
            {item}
          </p> 
      }else if(item === "Export BoQ"){
        return <p
            key={index}
            onClick={() =>
              this.exportDirectOrToggleModalForExportButton("BOQ")
            }
          >
            {item}
          </p>
      }else if(item === "Export Detailed BoQ"){
        return <p
            key={index}
            onClick={() =>
              this.exportDirectOrToggleModalForExportButton("BOQSplit")
            }
          >
            {item}
          </p>
      }
      else if(item === "Export Views"){
        return <p
            key={index}
            onClick={() =>
              this.exportDirectOrToggleModalForExportButton("Views")
            }
          >
            {item}
          </p>
      }
      else if (item === "Recent Files"){
        return <Link style={{textDecoration: "none"}} to={routes.dashboard} key={index}><p>{item}</p></Link>
      }
      else{
        return <p key={index}>{item}</p>;
      }
      
    })
    
  };

  getTopRightIcons = function (){
    let icons = NavConstants.TOP_RIGHT_ICONS;
    const {teams, projectMetadata} = this.props;
    let currentProjectTeam = null;
    if(projectMetadata.isTeamsProject){
      currentProjectTeam = teams.teamlist.find(team => team.id === projectMetadata.team.id);
    }
    if(currentProjectTeam){
      const permissions = currentProjectTeam.roleBasedPermissions[currentProjectTeam.role]
  
      icons = icons.filter(icon => {
        const property = icon[2]?.permissionKey;
        if(property){
          return permissions[property]
        }
        return true;
      }) 
    }else{
      icons = icons.filter(icon => icon[0] !== "Changelog")
    }
    return icons;
  }

  closeAlert = (e) => {
    if(store.exposed.AutoSave.getQueue().length > 0){
      if(!window.confirm(LEAVE_PAGE_CONFIRMATION)){
        e.preventDefault();
      }
    }

  }

  handleBeforeUnload = (e) => {
    if(store.exposed.AutoSave.getQueue().length > 0){
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
      }
      return '';
    }
  };

  render() {
    const {
      selectedPanelImage,
      selectedMenu,
      selectedSubMenu,
      selectedTopMultipleMenus,
      setSelectedMenu,
      projectMetadata,
      setProjectNameAction,
      coveAnalysis,
      screenshotUiData,
    } = this.props;
    const {
      importModalVisible,
      exportButtonDropVisible,
      permission,
      subPermission,
      openShareModal,
      exportModalType,
      showProjectDropDown,
      inProjectRenameMode,
      projectRenameField,
    } = this.state;
    this.topRightIcons = this.getTopRightIcons()

    const runningDaylightAnalysis = (() => {
      if(coveAnalysis.blinkFake) return true

      const latestAnalysis = coveAnalysis.analysisTable[store.floorkey]
      const parent_floor_key = latestAnalysis?.parent_floor_key

      if(parent_floor_key in coveAnalysis.runningAnalysisTable) {
        return coveAnalysis.runningAnalysisTable[parent_floor_key][latestAnalysis?.floor_key]
      }

      return false
    })()

    const runningEUI = (() => {
      if(coveAnalysis.blinkFake) return true

      const latestAnalysis = coveAnalysis.analysisTable[store.floorkey]
      const parent_floor_key = latestAnalysis?.parent_floor_key

      if(latestAnalysis && !coveAnalysis.eui[parent_floor_key]) return true
      else return false
    })()

    const isASEAnalysis = this.props?.location?.search?.includes("ASE")

    const showBlinkingRedDot = (name) => {
      if(name == "analysis_sda" || name == "analysis_ase") {
        if(runningDaylightAnalysis) return true
      }

      if(name == "eui_trigger" && runningEUI) return true

      return (name === "render" && screenshotUiData.renders.isRunning);

    }

    this.dashboardRoute = this.props.history.location.state?.dashboardRoute || this.state.currentProjectTeam?.route || routes.dashboard;

    return (
      <StyledComponents.TopBarMainContainer>
        <Prompt message={() => {
          if(store.exposed.AutoSave.getQueue().length > 0){
            return LEAVE_PAGE_CONFIRMATION;
          }
        }} />
        <StyledComponents.LeftMenu>
          <Link to={this.dashboardRoute} style={{ textDecoration: "none" }} onClick={this.closeAlert}>
            <img src={logoNew} alt="snaptrude" width={32} height={32} />
          </Link>
          <StyledComponents.Divider />
          { !projectMetadata.isCoveToolProject ?
          <div
            onClick={() => {
              this.setState((prevState) => {
                return { showProjectDropDown: !prevState.showProjectDropDown };
              });
            }}
            className="dropdown"
          >
            <div className="dropbtn">
              {inProjectRenameMode ?
                (
                  <StyledComponents.ProjectNameInput
                    type="text"
                    autoFocus={true}
                    value={projectRenameField}
                    onBlur={(e) => {
                      this.setInProjectRenameMode(false);
                      const text = e.target.value;
                      if(text !== "" && text !== projectMetadata.name){
                        renameProject(store.floorkey, text).then(res => {
                          if (res.success) setProjectNameAction(text);
                        });
                      }
                    }}
                    onKeyDown={blurOnEnter}
                    onChange={(e) => this.setProjectRenameField(e.target.value)}
                    maxLength={50}
                  />
                ):
                (
                  <StyledComponents.ProjectName>
                    <span
                      data-tip
                      data-for={"projectName"}
                    >
                      {this.formatProjectName(projectMetadata.name)}  
                    </span>
                    <Tooltip 
                      id={"projectName"}
                      place="bottom"
                      effect="solid"
                    >
                      {projectMetadata.name}
                    </Tooltip>
                  </StyledComponents.ProjectName>
                )
              }
              <img className="dropButtonImg" src={dropButton} alt=">" />
            </div>
            {showProjectDropDown && !projectMetadata.isCoveToolProject &&
              <ProjectDropdown 
                setInProjectRenameMode={this.setInProjectRenameMode} 
                setProjectRenameField={this.setProjectRenameField}
                exportDirectOrToggleModalForExportButton={this.exportDirectOrToggleModalForExportButton}
                showProjectDropDown={showProjectDropDown}
                onClose={() => {if(this.state.showProjectDropDown) this.setState({showProjectDropDown: false})}}
                projectMetadata={this.props.projectMetadata}
                teams={this.props.teams}
              />
            }
            {/* <div
              className={`${
                showProjectDropDown
                  ? "dropdown-content visible-dropdown-content"
                  : "dropdown-content"
              }`}
            >
              {this.getTopLeftDropDown.call(this)}
            </div> */}
          </div> :
            <StyledComponents.ProjectNameLink href={coveAnalysis?.coveProjectDetails?.parent_floor_key}>
              <span
                data-tip
                data-for={"projectName"}
                style={{
                  marginLeft: "0.5em"
                }}
              >
                {this.formatProjectName(coveAnalysis.coveProjectDetails?.details?.project_name || projectMetadata.name)}  
              </span>
              <Tooltip 
                id={"projectName"}
                place="bottom"
                effect="solid"
              >
                {coveAnalysis.coveProjectDetails?.details?.project_name || projectMetadata.name}
              </Tooltip>
            </StyledComponents.ProjectNameLink>
          }
          <StyledComponents.Divider />
          { !projectMetadata.isCoveToolProject &&
            <StyledComponents.MenuOptionsContainer>
            <StyledComponents.MenuDiv
                onClick={() => this.props.handleToolSelection(NavConstants.SELECT_SUB_MENU[0][0], "top")}
            >
              <StyledComponents.MenuOption style={{display: "flex", alignItems:"center"}}>
                <StyledComponents.TopMenuImg
                  // className={selectedSubMenu === NavConstants.SELECT_SUB_MENU[0][0] ? "active": ""}
                  active={selectedSubMenu === NavConstants.SELECT_SUB_MENU[0][0]}
                  src={NavConstants.SELECT_SUB_MENU[0][1]} 
                  alt={NavConstants.SELECT_SUB_MENU[0][0]}  
                  style={{width: "15px"}}
                />
                {/* <StyledComponents.TopMenuImg
                  // className={selectedSubMenu === NavConstants.SELECT_SUB_MENU[0][0] ? "active": ""}
                  className="dropButtonImg"
                  src={dropButton} 
                  alt={"Select"}
                  style={{marginLeft: "10px"}}
                  onClick={(evt) => {}}
                /> */}
              </StyledComponents.MenuOption>
            </StyledComponents.MenuDiv>
              {[
                "Select",
                "Design",
                "Import",
                "Measure",
                "Views",
                "Automate",
                "Collaborate",
                "Analysis",
                "Recent"
              ].map((option, index) => {
                return (
                  <StyledComponents.ColumnView key={index} style={option === "Select" ? {marginLeft: "-15px"} : {}}>
                    <StyledComponents.MenuDiv
                      className={`${
                        selectedMenu === option ? "selectedMenuContainer" : ""
                      }`}
                      onClick={() => setSelectedMenu(option)}
                    >
                      <StyledComponents.MenuOption>
                        {option === "Select" ? <img src={dropButton} alt={option} /> : option}
                      </StyledComponents.MenuOption>
                    </StyledComponents.MenuDiv>
                    {selectedMenu === option && (
                      <StyledComponents.SubMenu ref={this.dropdownNode}>
                        {this.getSubMenuImagesArrayByOption().map(
                          (item, index) => {
                            return (
                              <div key={index} style={TOOLS_MAPPING[item[0]]?.isDropdown ? {marginLeft: "-10px"} : {}}>
                                <StyledComponents.ImageButton
                                  className={`"subMenuImageButton"${showBlinkingRedDot(item[0]) ? " red-dot-blink": ""}`}
                                  onClick={() => this.props.handleToolSelection(item[0], "top")}
                                  isDropdown={TOOLS_MAPPING[item[0]]?.isDropdown}
                                >
                                  {item[1] && (
                                    <img
                                      data-tip
                                      data-for={item[0]}
                                      data-event='mouseenter' 
                                      data-event-off='mouseleave click'
                                      className={`${
                                        (selectedSubMenu === item[0] || selectedTopMultipleMenus.includes(item[0])) ? "selected_icon_top" : "topMenuIcon"
                                      }`}
                                      src={item[1]}
                                      alt={item[0]}
                                      onClick={() => {
                                        if(TOOLS_MAPPING[item[0]] && TOOLS_MAPPING[item[0]].mixpanel){
                                          window.analytics.track(TOOLS_MAPPING[item[0]].mixpanel);
                                        }

                                      }}
                                    />
                                  )}
                                </StyledComponents.ImageButton>
                                {(selectedSubMenu === item[0] || selectedTopMultipleMenus.includes(item[0])) && (
                                  <StyledComponents.SubMenuDropDown>
                                    <GetSubMenuDropdown 
                                      icon={item[0]}
                                      selectedTime={this.state.selectedTime}
                                      selectedDate={this.state.selectedDate}
                                      selectedMonth={this.state.selectedMonth}
                                      selectedMonthText={this.state.selectedMonthText}
                                      selectedTimeText={this.state.selectedTimeText}
                                      viewsSettings={this.props.viewsSettings}
                                      onViewsListChange={this.props.onViewsListChange}
                                      setSelectedDate={this.setSelectedDate}
                                      setSelectedTime={this.setSelectedTime}
                                      setSelectedMonth={this.setSelectedMonth}
                                      setSelectedMonthText={this.setSelectedMonthText}
                                      setSelectedTimeText={this.setSelectedTimeText}
                                      onViewsSettingsChange={this.props.onViewsSettingsChange}
                                      onClose={() => this.props.handleToolSelection(item[0], "top")}
                                    />
                                    {/* {this.getSubMenuDropdwn(item[0])} */}
                                  </StyledComponents.SubMenuDropDown>
                                )}
                                <OnboardingTooltip
                                  id={item[0]}
                                  toolid={item[0]}
                                >
                                  {item[0]}
                                </OnboardingTooltip>
                              </div>
                            );
                          }
                        )}
                      </StyledComponents.SubMenu>
                    )}
                  </StyledComponents.ColumnView>
                );
              })}
            </StyledComponents.MenuOptionsContainer>
          }
          {
            projectMetadata.isCoveToolProject &&
            <>
              <StyledComponents.TitleText>{ !isASEAnalysis ? "Spatial Daylight Autonomy" : "Annual Sunlight Exposure" }</StyledComponents.TitleText>
              <StyledComponents.Divider />
              <StyledComponents.MenuDiv
                  onClick={() => this.props.handleToolSelection(NavConstants.SELECT_SUB_MENU[0][0], "top")}
                  style={{ marginLeft: "0.5em" }}
              >
                <StyledComponents.MenuOption style={{display: "flex", alignItems:"center"}}>
                  <StyledComponents.TopMenuImg
                    // className={selectedSubMenu === NavConstants.SELECT_SUB_MENU[0][0] ? "active": ""}
                    active={selectedSubMenu === NavConstants.SELECT_SUB_MENU[0][0]}
                    src={NavConstants.SELECT_SUB_MENU[0][1]} 
                    alt={NavConstants.SELECT_SUB_MENU[0][0]}  
                    style={{width: "15px"}}
                  />
                </StyledComponents.MenuOption>
              </StyledComponents.MenuDiv>
              {[
                {
                  option: "Select",
                  name: "Select",
                },
                {
                  option: "ViewsForAnalysisPage",
                  name: "Views"
                }
              ].map(({ option, name }, index) => {
                return (
                  <StyledComponents.ColumnView key={index} style={option === "Select" ? {marginLeft: "-15px"} : {}}>
                    <StyledComponents.MenuDiv
                      className={`${
                        selectedMenu === option ? "selectedMenuContainer" : ""
                      }`}
                      onClick={() => setSelectedMenu(option)}
                    >
                      <StyledComponents.MenuOption>
                        {option === "Select" ? <img src={dropButton} alt={name} /> : name}
                      </StyledComponents.MenuOption>
                    </StyledComponents.MenuDiv>
                    {selectedMenu === option && (
                      <StyledComponents.SubMenu ref={this.dropdownNode}>
                        {this.getSubMenuImagesArrayByOption().map(
                          (item, index) => {
                            return (
                              <div key={index} style={TOOLS_MAPPING[item[0]].isDropdown ? {marginLeft: "-10px"} : {}}>
                                <StyledComponents.ImageButton
                                  className={`"subMenuImageButton"${showBlinkingRedDot(item[0]) ? " red-dot-blink": ""}`}
                                  onClick={() => this.props.handleToolSelection(item[0], "top")}
                                  isDropdown={TOOLS_MAPPING[item[0]].isDropdown}
                                >
                                  {item[1] && (
                                    <img
                                      data-tip
                                      data-for={item[0]}
                                      data-event='mouseenter' 
                                      data-event-off='mouseleave click'
                                      className={`${
                                        (selectedSubMenu === item[0] || selectedTopMultipleMenus.includes(item[0])) ? "selected_icon_top" : "topMenuIcon"
                                      }`}
                                      src={item[1]}
                                      alt={item[0]}
                                      onClick={() => {
                                        if(TOOLS_MAPPING[item[0]] && TOOLS_MAPPING[item[0]].mixpanel){
                                          window.analytics.track(TOOLS_MAPPING[item[0]].mixpanel);
                                        }

                                      }}
                                    />
                                  )}
                                </StyledComponents.ImageButton>
                                {(selectedSubMenu === item[0] || selectedTopMultipleMenus.includes(item[0])) && (
                                  <StyledComponents.SubMenuDropDown>
                                    <GetSubMenuDropdown 
                                      icon={item[0]}
                                      selectedTime={this.state.selectedTime}
                                      selectedDate={this.state.selectedDate}
                                      selectedMonth={this.state.selectedMonth}
                                      selectedMonthText={this.state.selectedMonthText}
                                      selectedTimeText={this.state.selectedTimeText}
                                      viewsSettings={this.props.viewsSettings}
                                      onViewsListChange={this.props.onViewsListChange}
                                      setSelectedDate={this.setSelectedDate}
                                      setSelectedTime={this.setSelectedTime}
                                      setSelectedMonth={this.setSelectedMonth}
                                      setSelectedMonthText={this.setSelectedMonthText}
                                      setSelectedTimeText={this.setSelectedTimeText}
                                      onViewsSettingsChange={this.props.onViewsSettingsChange}
                                      onClose={() => this.props.handleToolSelection(item[0], "top")}
                                    />
                                    {/* {this.getSubMenuDropdwn(item[0])} */}
                                  </StyledComponents.SubMenuDropDown>
                                )}
                                <OnboardingTooltip
                                  id={item[0]}
                                  toolid={item[0]}
                                >
                                  {item[0]}
                                </OnboardingTooltip>
                              </div>
                            );
                          }
                        )}
                      </StyledComponents.SubMenu>
                    )}
                  </StyledComponents.ColumnView>
                );
              })}
              { NavConstants.COVE_TOOLS_ICONS.map(
                (item, index) => {
                  return (
                    <div key={index} style={TOOLS_MAPPING[item[0]].isDropdown ? {marginLeft: "-10px"} : {}}>
                      <StyledComponents.ImageButton
                        className="subMenuImageButton"
                        onClick={() => this.props.handleToolSelection(item[0], "top")}
                        isDropdown={TOOLS_MAPPING[item[0]].isDropdown}
                      >
                        {item[1] && (
                          <img
                            data-tip
                            data-for={item[0]}
                            data-event='mouseenter' 
                            data-event-off='mouseleave click'
                            className={`${
                              (selectedSubMenu === item[0] || selectedTopMultipleMenus.includes(item[0])) ? "selected_icon_top" : "topMenuIcon"
                            }`}
                            src={item[1]}
                            alt={item[0]}
                            onClick={() => {
                              if(TOOLS_MAPPING[item[0]] && TOOLS_MAPPING[item[0]].mixpanel){
                                window.analytics.track(TOOLS_MAPPING[item[0]].mixpanel);
                              }

                            }}
                          />
                        )}
                      </StyledComponents.ImageButton>
                      {(selectedSubMenu === item[0] || selectedTopMultipleMenus.includes(item[0])) && (
                        <StyledComponents.SubMenuDropDown>
                          <GetSubMenuDropdown 
                            icon={item[0]}
                            selectedTime={this.state.selectedTime}
                            selectedDate={this.state.selectedDate}
                            selectedMonth={this.state.selectedMonth}
                            selectedMonthText={this.state.selectedMonthText}
                            selectedTimeText={this.state.selectedTimeText}
                            viewsSettings={this.props.viewsSettings}
                            onViewsListChange={this.props.onViewsListChange}
                            setSelectedDate={this.setSelectedDate}
                            setSelectedTime={this.setSelectedTime}
                            setSelectedMonth={this.setSelectedMonth}
                            setSelectedMonthText={this.setSelectedMonthText}
                            setSelectedTimeText={this.setSelectedTimeText}
                            onViewsSettingsChange={this.props.onViewsSettingsChange}
                            onClose={() => this.props.handleToolSelection(item[0], "top")}
                          />
                          {/* {this.getSubMenuDropdwn(item[0])} */}
                        </StyledComponents.SubMenuDropDown>
                      )}
                      <OnboardingTooltip
                        id={item[0]}
                        toolid={item[0]}
                      >
                        {item[0]}
                      </OnboardingTooltip>
                    </div>
                  );
                }
              )}
            </> 
          }
        </StyledComponents.LeftMenu>
        <StyledComponents.RightMenu>
          {/* <ActiveUserList /> */}
          { projectMetadata.isCoveToolProject && <span style={{ paddingRight: "15px", fontSize: "14px", fontWeight: 500 }}>Powered by Cove Tool</span>}
          <TopRightIcons 
            icons={projectMetadata.isCoveToolProject ? NavConstants.TOP_RIGHT_ICONS_COVE : this.topRightIcons}
            handleToolSelection={this.props.handleToolSelection}
            selectedPanelImage={selectedPanelImage}
          />
          {
            this.state.hasInviteToModalPermission && !projectMetadata.isCoveToolProject &&
              <Button
                primary={true}
                title={"Share"}
                customButtonStyle={{
                  padding: "0.25rem 1.5rem",
                  minHeight: "auto",

                }}
                rightImage={whiteDropdown}
                rightImageHeight="0.625rem"
                rightImageWidth="0.625rem"
                customButtonTextStyle={{
                  fontSize: "0.75rem",
                  fontWeight: "bold"
                }}
                onPress={() => {this.exportDirectOrToggleModalForExportButton("Share")}}
              />
          }
        </StyledComponents.RightMenu>
        <GenericModal
          modalType={"SHARE MODAL"}
          onClose={this.toggleShareModal}
          isOpen={openShareModal && exportModalType === "Share"}
          modalHeaderText={"Share"}
          customProps={{
            permission,
            setPermission: this.setPermission,
            subPermission,
            setSubPermission: this.setSubPermission,
            leftTitle: "Copy Link",
            customLeftTitleStyle: {
              backgroundColor: colors.transparent,
              color: colors.brightRed,
              padding: 0,
              marginRight: "0.3125rem",
              width: "auto",
            },
            leftTitleComponent: <CopyLink style={{marginLeft: "-1rem"}} textToCopy={window.location.href} />,
          }}
        />
        <GenericModal
          modalType={"EXPORT MODAL"}
          onClose={this.toggleShareModal}
          isOpen={openShareModal && exportModalType === "Export"}
          modalHeaderText={"Export"}
          customProps={{ buttonTitle: "Export" }}
        />
        <GenericModal
          modalType={"SAVE AS MODAL"}
          onClose={this.toggleShareModal}
          isOpen={openShareModal && exportModalType === "SaveAs"}
          modalHeaderText={"Save As"}
          customProps={{ buttonTitle: "Save As" }}
        />
        <GenericModal
          modalType={"VIEWS MODAL"}
          onClose={this.toggleShareModal}
          isOpen={openShareModal && exportModalType === "Views"}
          modalHeaderText={"Export Views"}
          customProps={{
            rightTitle: "Export",
            rightTitleOnPress: this.handleExportView,
            screenshotList: store.$scope.sceneViewData, 
            // screenshotList: store.$scope.sceneViewData.map((item) => {return {source: item.screenshot, name: "ScreenShot " + item.imgKey}}), 
            dropdownList: [
              {
                label: "Export Format",
                options: [
                  // "3840 x 2160",
                  "1920 x 1080",
                  "1280 x 720",
                  "960 x 540",
                  "800 x 240",
                  "560 x 240",
                ],
                height: "4.9375rem",
                onChange: (item) => {this.setState({exportViewResolution: item})}
              },
              {
                label: "Resolution",
                options: [".jpg", ".png", ".jpeg"],
                height: "auto",
                onChange: (item) => {this.setState({exportViewFormat: item})}
              },
            ],
          }}
        />
        <SnaptrudeModal
          isOpen={this.props.imageModalOpen}
          onClose={() => {
            this.closeImportModal("imageModalOpen");
          }}
          onUpload={() => {
            const regex = "^-?[1-9][0-9]*$";
            if (!this.state.importStorey.toString().match(regex)) {
              showToast("Invalid Storey!", 2000, TOAST_TYPES.error);
              return;
            }
            this.setState({ showProgressBar: true });
            window.scheduleCancelImageUpload = false;
            imageUpload(this.state.uploadedFile.file, this.state.importStorey)
              .then((response) => {
                if (response.success){
                  setTimeout(() => {this.closeImportModal("imageModalOpen");}, 1000);
                } 
                else throw (response.reason);
              })
              .catch((err) => {
                showToast(err);
                this.setState({showProgressBar: false});
                // this.closeImportModal("imageModalOpen");
              });
          }}
          modalHeaderText={"Image"}
          bodyHeaderText={"Select sketch image and upload"}
          allowedTypes={[
            {
              name: ".jpg",
              src: image
            },
            {
              name: ".jpeg",
              src: image,
            },
            {
              name: ".png",
              src: image
            },
            {
              name: ".bmp",
              src: image
            },
          ]}
          DropzoneComponent={<SnaptrudeDropzone setUploadedFile={this.setUploadedFile} dropzoneAccept="image/png, image/jpg, image/jpeg, image/bmp" />}
          uploadedFile={this.state.uploadedFile}
          selectedStorey={this.props.selectedStorey}
          storey={this.state.importStorey}
          setStorey={this.setImportStorey}
          resetUploadedFile={() => {
            this.setUploadedFile(null);
          }}
          showProgressBar={this.state.showProgressBar}
        />
        <SnaptrudeModal
          isOpen={this.props.cadModalOpen}
          onClose={() => {
            this.closeImportModal("cadModalOpen");
          }}
          onUpload={() => {
            const regex = "^-?[1-9][0-9]*$";
            if (!this.state.importStorey.toString().match(regex)) {
              showToast("Invalid Storey!", 2000, TOAST_TYPES.error);
              return;
            }
            this.setState({ showProgressBar: true });
            let file = this.state.uploadedFile.file;
            let fileName = file.name.replace(/[^a-z0-9]/gi, '_') + '.dwg';
            let fileWithCleanName = new File([file], fileName, {type: file.type});
            cadUpload(fileWithCleanName, this.state.importStorey)
              .then((data) => {
                // console.log(data);
                // window.addEventListener("cadUploadUIUpdate", (evt) => {
                //   if (evt.detail.success) {
                //     this.closeImportModal("cadModalOpen");
                //   }
                // });
              })
              .catch((err) => {
                console.log(err);
                this.closeImportModal("cadModalOpen")
              });
          }}
          modalHeaderText={"CAD Drawing"}
          bodyHeaderText={"Select AutoCAD drawing and upload"}
          allowedTypes={[
            {
              name: ".dwg",
              src: cad,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              setUploadedFile={this.setUploadedFile}
              dropzoneAccept=".dwg, application/acad, image/vnd.dwg, image/x-dwg, image/dwg, application/cad file/dwg "
            />
          }
          uploadedFile={this.state.uploadedFile}
          selectedStorey={this.props.selectedStorey}
          storey={this.state.importStorey}
          setStorey={this.setImportStorey}
          resetUploadedFile={() => {
            this.setUploadedFile(null)
          }}
          showProgressBar={this.state.showProgressBar}
          closeModal={() => this.closeImportModal("cadModalOpen")}
        />
        <SnaptrudeImportFunctionalityModal
          isOpen={this.props.materialModalOpen}
          onClose={() => {
            this.closeImportModal("materialModalOpen");
          }}
          onUpload={() => {
            const regex = "^-?[1-9][0-9]*$";
            if (!this.state.importStorey.toString().match(regex)) {
              showToast("Invalid Storey!", 2000, TOAST_TYPES.error);
              return;
            }
            this.setProgress(0);
            this.setState({ showProgressBar: true });
            // this.setProgress(40);
            modelUpload(this.state.uploadedFile.file, this.state.modelType, this.state.importStorey)
              .then(uploadedObjects => {
                // console.log(uploadedObjects)
                if (uploadedObjects?.[0]?.importType) {
                  const props = {
                    propurl: uploadedObjects[0].babylon,
                    subType: uploadedObjects[0].subType
                  }
                  const tabs = {
                    title: uploadedObjects[0].importType
                  }

                  intSelect(props, tabs, true);
                }
                const evtFound = FakeProgressEvent.find("dwfuploadprgress");
                if (evtFound) evtFound.stop();
                this.setProgress(100);
                // this.setUploadedFile(null);
                setTimeout(() => {
                  this.closeImportModal("materialModalOpen");
                }, 1000);
              })
              .catch(err => {
                console.error(err);
                this.closeImportModal("materialModalOpen")
              })
          }}
          // handleCancelFile={handleCancelFile}
          modalHeaderText={"Model"}
          bodyHeaderText={"Select 3D model and upload the file in .zip format"}
          allowedTypes={[
            // {
            //   name: ".rvt",
            //   src: cube,
            // },
            {
              name: ".skp",
              src: cube,
            },
            // {
            //   name: ".3dm",
            //   src: cube,
            // },
            {
              name: ".fbx",
              src: cube,
            },
            {
              name: ".3ds",
              src: cube,
            },
            {
              name: ".obj",
              src: cube,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              setUploadedFile={this.setUploadedFile}
              dropzoneAccept=".zip, .fbx, .skp"
            />
          }
          isSingleUpload
          uploadedFile={this.state.uploadedFile}
          selectedStorey={this.props.selectedStorey}
          storey={this.state.importStorey}
          setStorey={this.setImportStorey}
          resetUploadedFile={() => {
            this.setUploadedFile(null);
          }}
          typeOptionsTitle={"Category"}
          type={this.state.modelType}
          setType={this.setModelType}
          typeOptions={MODEL_TYPE_OPTIONS}
          // hasUnit={false}
          // unit={unit}
          // setUnit={setUnit}
          // unitOptions={unitOptions}
          showProgressBar={this.state.showProgressBar}
          closeModal={() => this.closeImportModal("materialModalOpen")}
          progress={this.state.progress}
          progressText={this.state.progressText}
        />
        {/* <SnaptrudeModal
          isOpen={this.state.materialModalOpen}
          onClose={() => {
            this.closeImportModal("materialModalOpen");
          }}
          onUpload={() => {
            const regex = "^-?[1-9][0-9]*$";
            if (!this.state.importStorey.toString().match(regex)) {
              showToast("Invalid Storey!", 2000, TOAST_TYPES.error);
              return;
            }
            this.setState({ showProgressBar: true });
            modelUpload(this.state.uploadedFile.file, this.state.importStorey)
          }}
          modalHeaderText={"Model"}
          bodyHeaderText={"Select 3D model and upload the file in .zip format"}
          allowedTypes={[
            {
              name: ".rvt",
              src: cube,
            },
            {
              name: ".skp",
              src: cube,
            },
            {
              name: ".3dm",
              src: cube,
            },
            {
              name: ".3ds",
              src: cube,
            },
            {
              name: ".obj",
              src: cube,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              setUploadedFile={this.setUploadedFile}
              dropzoneAccept=".zip"
            />
          }
          uploadedFile={this.state.uploadedFile}
          selectedStorey={this.props.selectedStorey}
          storey={this.state.importStorey}
          setStorey={this.setImportStorey}
          resetUploadedFile={() => {
            this.setUploadedFile(null);
          }}
          showProgressBar={this.state.showProgressBar}
          closeModal={() => this.closeImportModal("materialModalOpen")}
        /> */}
        <SnaptrudeModal
          isOpen={this.props.pdfModalOpen}
          onClose={() => {
            this.closeImportModal("pdfModalOpen");
          }}
          onUpload={() => {
            const regex = "^-?[1-9][0-9]*$";
            if (!this.state.importStorey.toString().match(regex)) {
              showToast("Invalid Storey!", 2000, TOAST_TYPES.error);
              return;
            }
            const pdfExists = importPdfOperations.checkIfPdfExistsOnStorey(this.state.importStorey)
            if(pdfExists){
              showToast("PDF already exists on this storey. Please upload to a different storey.", 2000, TOAST_TYPES.error);
              return;
            }
            // console.log(this.state.uploadedFile.file);
            // console.log(this.state.page, this.state.scale)
            this.setState({ showProgressBar: true });

            // @sravan, call your method here
            const fileReader = new FileReader();
            fileReader.readAsDataURL(this.state.uploadedFile.file);
            importPdfOperations.logPdf(this.state.uploadedFile.file, this.state.importStorey);
            // this.setScale("1:100");
            fileReader.onloadend = async (evt) => {
              // console.log(evt.target.result);
              // let _pdfMetaData = await importPdfOperations.fetchPDFMetaData(evt.target.result);
              // console.log(_pdfMetaData);
              // // @ADITYA _pdfMetaData.totalNumberOfPages gives you the total number of pages.

              importPdfOperations.init(evt.target.result, this.state.scale, this.state.importStorey, this.state.page)
              .then(() =>{
                setTimeout(()=> {this.closeImportModal("pdfModalOpen")}, 1000) 
              });
            }

          }}
          modalHeaderText={"Upload PDF"}
          bodyHeaderText={"Select PDF to upload"}
          allowedTypes={[
            {
              name: ".pdf",
              src: pdf,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              setUploadedFile={this.onPdfFileUpload}
              dropzoneAccept=".pdf"
            />
          }
          uploadedFile={this.state.uploadedFile}
          selectedStorey={this.props.selectedStorey}
          storey={this.state.importStorey}
          setStorey={this.setImportStorey}
          page={this.state.page}
          pdfPageCount={this.state.pdfPageCount}
          setPdfPage={this.setPdfPage}
          isPdfPageCountLoading={this.state.isPdfPageCountLoading}
          scale={this.state.scale}
          setScale={this.setScale}
          resetUploadedFile={() => {
            this.setUploadedFile(null)
          }}
          showProgressBar={this.state.showProgressBar}
          closeModal={() => this.closeImportModal("pdfModalOpen")}
        />
        <DaylightAnalysisSettingsModal
          isOpen={this.props.analysisModalVisible}
          onClose={() => this.closeImportModal("analysisModalVisible")}
          openTopographyModal={() => this.props.handleToolSelection("topo", "top", undefined)}
        />
        <TopographyModal
          isOpen={this.props.topographyModalVisible}
          onClose={() => {
            this.closeImportModal("topographyModalVisible");
            CoveHelper.toggleUseOflastSearchedLocation(true)
            if(store.daylightAnalysis.trigger) {
              if(CoveHelper.lastSearchedLocationChangedFromDefault()) {
                CoveHelper.startDaylightAnalysis(() => {})
              }
              store.daylightAnalysis.trigger = false;
            }
          }}
          modalHeaderText={"Topography"}
        />
        {/* Import Revit Modal for user initiated import */}
        {this.props.speckleRevitModalVisible && 
          <SpeckleRevitImport 
            isOpen={this.props.speckleRevitModalVisible} 
            onClose={()=> {this.closeImportModal("speckleRevitModalVisible")}} 
          />
        }
        {/* Import Revit Modal for streamId present import */}
        {this.state.speckleRevitModalVisible && 
          <SpeckleRevitImport 
            isOpen={this.state.speckleRevitModalVisible} 
            onClose={()=> {this.setState({speckleRevitModalVisible: false})}} 
            speckleStreamId={this.props.projectMetadata.speckleStreamId}
            team={this.state.currentProjectTeam}
          />
        }
        {this.props.renderSettingsModalVisible &&
          <RenderSettingsModal
            isOpen={this.props.renderSettingsModalVisible}
            onClose={() => {
              this.closeImportModal("renderSettingsModalVisible");
            }}
          />
        }


        <AddCommentWrapper id="add-comment-wrapper">
          <AddComment onClose={handleCommentDialogClose} />
        </AddCommentWrapper>
        <ShowCommentWrapper id="show-comment-wrapper">
          <Comment comment={this.state.selectedComment} onClose={() => {this.setSelectedComment(null)}} />
        </ShowCommentWrapper>
      </StyledComponents.TopBarMainContainer>
    );
  }
}

TopMenuBar.propTypes = {};

// export default withRouter(TopMenuBar);

const mapStateToProps = (state) => {
  return {
    projectMetadata : state.projectMetadata,
    teams: state.teams,
    coveAnalysis: state.coveAnalysis,
    screenshotUiData: state.screenshotUiData
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, { setProjectNameAction })
)(TopMenuBar);
