import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components';
import { TOOLS_MAPPING } from '../../snaptrude/modules/utilityFunctions/CONSTANTS/tools_mapping';
import { store } from '../../snaptrude/modules/utilityFunctions/Store';
const SHORT = 500;
const LONG = 1500;
const ReactTooltipStyled = styled(ReactTooltip)`
  font-weight: normal !important;
  font-size: 0.85em !important;
  padding: 5px !important;
`
function OnboardingTooltip({ toolid, ...props }) {
  const [DELAY, setDELAY] = useState(LONG);
  const debouncedInstructorCall = useRef(null);
  const projectMetadata = useSelector(state => state.projectMetadata);
  useEffect(() => {
    if(projectMetadata.user.projectsCount < 0){
      setDELAY(LONG);
    }else{
      if(projectMetadata.user.projectsCount < 3){
        setDELAY(SHORT);
      }else{
        setDELAY(LONG);
      }
    }
  }, [projectMetadata.user.projectsCount]);

  if (TOOLS_MAPPING[props.children]) props.children = TOOLS_MAPPING[props.children].displayText;

  function displayInstructor() {
    if (!store.$scope.userSetBIMProperties.disableTooltips && TOOLS_MAPPING[toolid] && TOOLS_MAPPING[toolid].hasTrigger){
      const instructorEvent = new CustomEvent("instructor", {
        detail: {
          showInstructor: true,
          tool: TOOLS_MAPPING[toolid]
        }
      })
      window.dispatchEvent(instructorEvent)
    }
  }

  const handleAfterHide = () => {
    if(debouncedInstructorCall.current){
      debouncedInstructorCall.current.cancel();
    }

    const instructorEvent = new CustomEvent("instructor", {
      detail: {
        showInstructor: false,
      }
    })
    window.dispatchEvent(instructorEvent)
  }

  const handleAfterShow = useMemo(() => { 
    debouncedInstructorCall.current = _.debounce(displayInstructor, DELAY); 
    return debouncedInstructorCall.current; 
  }, [DELAY])

  return (
    <ReactTooltipStyled
      place="bottom"
      effect="solid"
      afterShow={handleAfterShow}
      afterHide={handleAfterHide}
      {...props}
    />
  )
}

export default OnboardingTooltip
