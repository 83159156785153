import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js"
import { loadSolidMaterial } from "./mats.js";
function createBasicMaterials() {
  // material
  var mat = new BABYLON.StandardMaterial("basicObjectMaterial", store.scene);
  mat.alpha = 1.0;
  mat.diffuseColor = new BABYLON.Color3(0.7, 0.7, 0.7);
  mat.backFaceCulling = false;

  var wall_mat1 = new BABYLON.StandardMaterial("wall_mat1", store.scene);
  wall_mat1.alpha = 1.0;
  // wall_mat1.diffuseTexture = new BABYLON.Texture("/media" + "/Textures/" + "walls/" + "wall1.png", store.scene);
  wall_mat1.diffuseColor = new BABYLON.Color3(0.8, 0.8, 0.8);
  wall_mat1.backFaceCulling = false;

  var wall_mat2 = new BABYLON.StandardMaterial("wall_mat2", store.scene);
  wall_mat2.alpha = 1.0;
  // wall_mat2.diffuseTexture = new BABYLON.Texture("/media" + "/Textures/" + "walls/" + "wall2.jpg", store.scene);
  wall_mat2.diffuseColor = new BABYLON.Color3(0.8, 0.8, 0.8);
  wall_mat2.backFaceCulling = false;

  var wall_mat3 = new BABYLON.StandardMaterial("wall_mat3", store.scene);
  wall_mat3.alpha = 1.0;
  // wall_mat3.diffuseTexture = new BABYLON.Texture("/media" + "/Textures/" + "walls/" + "wall8.jpg", store.scene);
  wall_mat3.diffuseColor = new BABYLON.Color3(0.8, 0.8, 0.8);
  wall_mat3.backFaceCulling = false;

  var wall_mat4 = new BABYLON.StandardMaterial("wall_mat4", store.scene);
  wall_mat4.alpha = 1.0;
  wall_mat4.diffuseColor = new BABYLON.Color3(0.3, 0.3, 0.3);
  // wall_mat4.diffuseTexture = new BABYLON.Texture("/media" + "/Textures/" + "walls/" + "wall4.jpg", store.scene);
  wall_mat4.backFaceCulling = false;

  // var multimat2 = new BABYLON.MultiMaterial("multiMat", store.scene);
  // // multimat2.subMaterials.push(wall_mat4);
  // multimat2.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat2.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat2.subMaterials.push(scene.getMaterialByName("wall_mat"));
  // multimat2.backFaceCulling = false;

  var floor_mat = new BABYLON.StandardMaterial("floor_mat", store.scene);
  // floor_mat.diffuseTexture = new BABYLON.Texture("/media" + "/Textures/" + "granite/" + "tile2.jpg", store.scene);
  floor_mat.diffuseColor = new BABYLON.Color3(0.3, 0.3, 0.3);
  floor_mat.backFaceCulling = false;

  mat = new BABYLON.StandardMaterial("mat1", store.scene);
  mat.alpha = 1.0;
  mat.diffuseColor = new BABYLON.Color3(0.7, 0.7, 0.7);
  mat.backFaceCulling = false;

  var material = new BABYLON.StandardMaterial("boxm1", store.newScene);
  material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);

  material = new BABYLON.StandardMaterial("boxvert", store.newScene);
  material.diffuseColor = new BABYLON.Color3(0.0, 0.0, 1.0);

  material = new BABYLON.StandardMaterial("redMaterial", store.newScene);
  material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);

  material = new BABYLON.StandardMaterial("greenMaterial", store.newScene);
  material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 0.0);

  material = new BABYLON.StandardMaterial("blueMaterial", store.newScene);
  material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 1.0);

  material = new BABYLON.StandardMaterial("scaleBoxMat1", store.newScene);
  material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 0.0);

  material = new BABYLON.StandardMaterial("scaleBoxMat2", store.newScene);
  material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);

  var startingPointMaterial = new BABYLON.StandardMaterial(
    "startingPointMaterial",
    store.newScene
  );
  startingPointMaterial.diffuseColor = new BABYLON.Color3(0.1, 0.1, 1.0);

  loadSolidMaterial();
}

function isComponentMaterialDefault(component) {
  return component.mesh.material === getDefaultComponentMaterial(component);
}

function getDefaultComponentMaterial(component) {
  if (component.type.toLowerCase().includes("mass")){
    return store.scene.getMaterialByName("solid_mat");
  }
  else if (component.type.toLowerCase().includes("floor")){
    return store.scene.getMaterialByName("floor_tile");
  }
  else if (component.type.toLowerCase().includes("roof")){
    return store.scene.getMaterialByName("ceiling_mat");
  }
  else if (component.type.toLowerCase().includes("wall")){
    let wallType = component.properties.wallMaterialType, mat;
    if (wallType) mat = store.scene.getMultiMaterialByID("multi" + wallType);
    if (mat) return mat;
    else return store.scene.getMaterialByName("wall_mat");
  }
}

function assignDefaultComponentMaterial(component) {
  component.mesh.material = getDefaultComponentMaterial(component);
}
export { createBasicMaterials,isComponentMaterialDefault,getDefaultComponentMaterial,assignDefaultComponentMaterial };
