import { routes } from "../../routes/constants";

import projectIcon from "../../assets/icons/project.svg";
import librariesIcon from "../../assets/icons/libraries.svg";
import sharedIcon from "../../assets/icons/shared.svg";
import templateIcon from "../../assets/icons/template.svg";
import preferencesIcon from "../../assets/icons/settings.svg";
import reportsIcon from "../../assets/icons/report.svg";
import myworkspaceIcon from "../../assets/icons/individual.svg";

export const NAVITEMS_PRO = [
    {
        title: "My Projects",
        route: routes.dashboard,
        icon: projectIcon,
        icontext: "project-icon",
    },
    {
        title: "Shared with Me",
        route: routes.sharedWithMe,
        icon: sharedIcon,
        icontext: "people-icon",
    },
    // {
    //     title: "Templates",
    //     route: routes.templates,
    //     icon: templateIcon,
    //     icontext: "templates-icon",
    // },
    // {
    //     title: "Libraries",
    //     route: routes.libraries,
    //     icon: librariesIcon,
    //     icontext: "libraries-icon",
    // },
    // {
    //     title: "Preferences",
    //     route: routes.preferences,
    //     icon: preferencesIcon,
    //     icontext: "libraries-icon",
    // },
    // {
    //     title: "Reports",
    //     route: routes.reports,
    //     icon: reportsIcon,
    //     icontext: "reports-icon",
    // },
]

export const NAVITEMS_ENTERPRISE = [
    {
        title: "My Workspace",
        route: routes.dashboard,
        icon: myworkspaceIcon,
        icontext: "workspace-icon",
        children: NAVITEMS_PRO
    },
]