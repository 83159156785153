import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc';
import { socialConnect } from '../../snaptrude/modules/social/socialSocket';
import { colors } from '../../themes/constant';
import Button from '../Button';
import AddComment from './AddComment';
import resolvedIcon from "../../assets/icons/resolved.svg";
import resolvedIconActive from "../../assets/icons/resolvedActive.svg";
import resolveIcon from "../../assets/icons/resolve.svg";
import reopenIcon from "../../assets/icons/reopen.svg";
import menuVertical from "../../assets/icons/menu-vertical.svg";
import menuVerticalActive from "../../assets/icons/menu-vertical-active.svg";
import Divider from '../Divider';
import NamedAvatar from '../Avatar/NamedAvatar';
import { commentOperator } from '../../snaptrude/libs/commentEvents';
import MenuList from '../MenuList';
import reduxStore from '../../snaptrude/stateManagers/store/reduxStore';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
    display: flex;
    z-index: 20;
    box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
`
const CommentNumber = styled.div`
    margin: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EB1E45;
    color: white;
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 1024px;
    border-bottom-right-radius: 0px;
    transform: rotate(45deg);
    span{
        transform: rotate(-45deg);
    }
`
const TextArea = styled.textarea`
    width: 100%;
    resize: none;
    box-sizing: border-box;
    outline: none;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    min-height: 2.5em;
    padding: 0.5em;
    background-color: ${colors.white};
    border-radius: 5px;
    margin: 5px 0px;
    color: ${colors.darkGrey};

`
const CommentBox = styled.div`
    background: ${colors.fullWhite};
    padding: 10px 20px;
    width: 25rem;
    border-radius: ${props => props.showReplyBox ? "8px 8px 0px 0px" : "8px"};
`
const CommentMeta = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`
const Username = styled.div`
    font-weight: bold;
    flex: 1;
    font-size: 14px;
`
const Time = styled.div`
    color: ${colors.thinGray};
    font-size: 12px;
`
const ResolveStatus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0.5em;
    margin-left: 1em;
`
const StyledMenu = styled.div`
    cursor: pointer;
    display: flex;  
    justify-content: center;
    align-items: center;
    position: relative;
    img{
        width: 20px;
    }
`
const CommentText = styled.div`
    margin-bottom: 30px;
    padding-left: 40px;
    font-size: 12px;
    color: ${colors.darkGrey};
`
const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid ${colors.veryLightGrey};
    // padding: 0px 20px;
    // margin: 0px -20px;
`

const Menu = ({actions, onSelect, commentId}) => {
    const [showMenu, setShowMenu] = useState(false);
    const handleSelect = (selectedItem) => {
        onSelect(selectedItem, commentId);
        setShowMenu(false);
    }
    return (
        <StyledMenu>
            <img onClick={() => {setShowMenu(!showMenu)}} src={showMenu ? menuVerticalActive : menuVertical} alt="options" />
            {
                showMenu &&
                <MenuList 
                    items={actions} 
                    listStyle={{width: "100px", textAlign: "right", position: "absolute", top: "25px", left: "-75px"}} 
                    itemStyle={{paddingRight: "10px"}}
                    onItemClick={(selectedItem) => {handleSelect(selectedItem)}}
                    handleClose={() => {setShowMenu(false)}}
                />
            }
        </StyledMenu>
    )
}

const EditCommentBox = ({commentId, originalText, handleClose, comment}) => {
    const [updateCommentText, setUpdateCommentText] = useState(originalText);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const handleUpdateCommentText = async () => {
        setIsSubmitLoading(true);
        const success = await socialConnect.editComment(commentId, updateCommentText);
        setIsSubmitLoading(false);
        if(success) {
            handleClose(updateCommentText);
            commentOperator.selectCommentFromOutside(comment);
        }
        else showToast("Something went wrong while updating the comment, try again.", 3000, TOAST_TYPES.error);
    }
    return (
        <>
            <TextArea value={updateCommentText} onChange={(evt) => {setUpdateCommentText(evt.target.value)}} />
            <Controls style={{borderTop: "none"}}>
                <ButtonWrapper style={{paddingLeft: "0em"}}>
                    <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} title={"Cancel"} onPress={() => {handleClose(originalText)}} />
                </ButtonWrapper>
                <ButtonWrapper style={{paddingRight: "0em"}}>
                    <Button 
                        primary={updateCommentText!==originalText && updateCommentText !== ""} 
                        disabled={updateCommentText===originalText || updateCommentText === ""} 
                        title={"Edit"} 
                        onPress={handleUpdateCommentText}
                        isLoading={isSubmitLoading}
                    />
                </ButtonWrapper>
            </Controls>
        </>
    );
}

const CommentTextContainer = ({comment, editComment, setEditComment}) => {
    const [commentText, setCommentText] = useState(comment.comment);
    const handleCloseEditMode = (text) => {
        setCommentText(text);
        setEditComment(null);
    }
    useEffect(() => {
      setCommentText(comment.comment);
    }, [comment])
    
    return <CommentText>
        {
            editComment === comment.id ? <EditCommentBox 
                commentId={comment.id} 
                handleClose={handleCloseEditMode} 
                originalText={commentText}
                comment={comment}
            /> : commentText
        }
    </CommentText>
}

function Comment({comment, onClose}) {
    const {teamlist, projectMetadata} = useSelector(state => {
        return {
            teamlist: state.teams.teamlist,
            projectMetadata: state.projectMetadata
        }
    });
    const [isResolved, setIsResolved] = useState(false);
    const [showReplyBox, setShowReplyBox] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [updateCommentText, setUpdateCommentText] = useState(null);
    const [showEditBox, setShowEditBox] = useState(false);
    const [editComment, setEditComment] = useState(null);
    const [commentText, setCommentText] = useState(null);
    const [currentUserEmail, setCurrentUserEmail] = useState("");
    const [hasResolvePermission, setHasResolvePermission] = useState(true);
    const [hasEditDeletePermission, setHasEditDeletePermission] = useState(true);
    useEffect(() => {
        if(comment){
            setIsResolved(comment.isResolved)
            setCommentText(comment.comment)
        } 
        setUpdateCommentText(null);
        setShowEditBox(false);
        setShowMenu(false);
        const userEmail = socialConnect.getAuthor().email;
        setCurrentUserEmail(userEmail);
        let currentProjectTeam = null;
        if(projectMetadata?.isTeamsProject){
            currentProjectTeam = teamlist.find(team => team.id === projectMetadata.team.id);
        }
        if(currentProjectTeam){
            const permissions = currentProjectTeam.roleBasedPermissions[currentProjectTeam.role];
            console.log(permissions["edit_and_delete_comments"])
            setHasResolvePermission(permissions["resolve_comments"]);
            setHasEditDeletePermission(permissions["edit_and_delete_comments"])
        }
    }, [comment]); 

    const handleReply = async (reply) => {
        const success = await socialConnect.postComment(reply, comment.id);
        if(!success) showToast("Could not add reply, try again.");
        else handleCancel();
    }

    const handleCancel = () => {
        setShowReplyBox(false);
        commentOperator.onCancelComment(comment)
        onClose();
    }

    const handleResolve = () => {
        if(isResolved){
            socialConnect.unresolveComment(comment.id);
        }else{
            socialConnect.resolveComment(comment.id);
        }
        onClose();
    }

    const handleCommentAction = async (action, commentId) => {
        if(action === "Edit"){
            setShowMenu(false);
            setEditComment(commentId);
        }else if(action === "Delete"){
            const success = await socialConnect.deleteComment(commentId)
            if(success) handleCancel();
            else showToast("Something went wrong while deleting the comment, try again.", 3000, TOAST_TYPES.error);
        }
    }

    if(!comment) return <></>
    return (
        <>
        <Wrapper id="comment-container">
            <CommentBox showReplyBox={showReplyBox}>
                <CommentMeta>
                    <NamedAvatar id={comment.author.email} text={comment.author.name} />
                    <Username>{comment.author.name}</Username>
                    <Time>{socialConnect.timeDifference(new Date(comment.createdAt))}</Time>
                    {
                        hasResolvePermission &&
                            <ResolveStatus onClick={handleResolve}>
                                <img src={isResolved ? reopenIcon : resolveIcon} alt="resolved" />
                            </ResolveStatus>
                    }
                    {
                        hasEditDeletePermission &&
                        currentUserEmail === comment.author.email && <Menu actions={["Edit", "Delete"]} commentId={comment.id} onSelect={handleCommentAction} />
                    }
                </CommentMeta>
                <CommentTextContainer comment={comment} editComment={editComment} setEditComment={setEditComment} />
                {
                    comment.children.map(childComment => {
                        if(childComment.isDeleted) return <></>
                        return <> 
                        <CommentMeta>
                            <NamedAvatar key={childComment.author.email} id={childComment.author.email} text={childComment.author.name} />
                            <Username>{childComment.author.name}</Username>
                            <Time>{socialConnect.timeDifference(new Date(childComment.createdAt))}</Time>
                            {
                                hasEditDeletePermission &&
                                currentUserEmail === childComment.author.email &&
                                    <Menu actions={["Edit", "Delete"]} onSelect={handleCommentAction} commentId={childComment.id} />
                            }
                        </CommentMeta>
                        <CommentTextContainer comment={childComment} editComment={editComment} setEditComment={setEditComment} />
                    </>
                    }) 
                }
                {
                    !showReplyBox &&
                    <Controls>
                        <Divider />
                        <ButtonWrapper style={{paddingLeft: "0em"}}>
                            <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} title={"Cancel"} onPress={handleCancel} />
                        </ButtonWrapper>
                        <ButtonWrapper style={{paddingRight: "0em"}}>
                            <Button primary title={"Reply"} onPress={() => {if(!isResolved) setShowReplyBox(true)}} />
                        </ButtonWrapper>
                    </Controls>
                }
            </CommentBox>
        </Wrapper>
        {
            showReplyBox &&
                <AddComment handleReply={handleReply} isReply onClose={() =>{setShowReplyBox(false)}} />
        }
        </>
    )
}

export default Comment
