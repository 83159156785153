import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";

const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  bottom: 0;
  height: 4.25rem;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

function Modal({
  modalHeaderText,
  modalBody,
  modalFooter,
  customHeaderStyle,
  customBodyStyle,
  customFooterStyle,
  isOpen,
  onClose,
  custonModalStyle,
}) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={custonModalStyle || modalStylesDefault}
    >
      <Card>
        <ModalHeader style={customHeaderStyle}>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              {modalHeaderText}
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody style={customBodyStyle}>{modalBody}</ModalBody>
        <ModalFooter style={customFooterStyle}>{modalFooter}</ModalFooter>
      </Card>
    </ReactModal>
  );
}

Modal.propTypes = {
  modalHeaderText: PropTypes.string,
  modalBody: PropTypes.element,
  modalFooter: PropTypes.element,
  customBodyStyle: PropTypes.instanceOf(Object),
  customHeaderStyle: PropTypes.instanceOf(Object),
  customFooterStyle: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Modal;
