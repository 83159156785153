import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../themes/constant";

const TopBorderBox = styled.div`
  width: ${({ width }) => width};
  border: ${({ dividerColor }) =>
    dividerColor
      ? `0 solid ${dividerColor}`
      : `0 solid ${colors.veryLightGrey}`};
  border-top-width: 0.063rem;
  margin: 0.25em;
`;

const Divider = ({ customStyle, width, dividerColor, ...props }) => {
  return (
    <TopBorderBox
      dividerColor={dividerColor}
      width={width}
      style={customStyle}
      {...props}
    />
  );
};

Divider.propTypes = {
  customStyle: PropTypes.instanceOf(Object),
};

export default Divider;
