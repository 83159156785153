"use strict";
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { textureOnLoadCallback } from "../../libs/mats.js";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs.js";
import { markMeshAsThrowAway } from "../extrafunc.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
/*jshint esversion: 6 */
var ComponentManager = (function (scene) {
  let assetsManager;
  let FORMAT = {
    BABYLON: ".babylon",
    GLTF: ".gltf",
    OBJ: ".obj",
  };

  const init = () => {
    assetsManager = new BABYLON.AssetsManager(store.scene);
  }
  function prepareLoadedMesh(mesh) {
    function prepareMesh(mesh) {
      mesh.state = "off";
      mesh.storey = 1;
      mesh.structure_id = store.activeLayer.structure_id;

      mesh.convertToFlatShadedMesh();
      mesh.showBoundingBox = false;
      if (mesh.material) {
        // mesh.material.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        mesh.material.backFaceCulling = false;
        if (mesh.material.subMaterials) {
          mesh.material.subMaterials.forEach(function (material) {
            // material.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
            material.backFaceCulling = false;
            material.opacityTexture = null;

            if (material.diffuseTexture) {
              material.diffuseColor = new BABYLON.Color3(0.7, 0.7, 0.7);
              textureOnLoadCallback.bind(material);
            }
          });
        }

        saveMaterialInBackend(mesh.material);
      }
      mesh.computeWorldMatrix(true);
      // let boundingInfo = mesh.getBoundingInfo();
      // let pivotPoint = BABYLON.Vector3.Center(boundingInfo.maximum, boundingInfo.minimum).negate();
      // mesh.setPivotMatrix(BABYLON.Matrix.Translation(pivotPoint.x, pivotPoint.y, pivotPoint.z), false);
      mesh.originalScaling = mesh.scaling.clone();
    }

    function throwAwayMesh(mesh) {
      markMeshAsThrowAway(mesh);
      mesh.scaling = BABYLON.Vector3.Zero();
      mesh.position = new BABYLON.Vector3(10000, 10000, 10000);
    }

    function addMeshToStructure(mesh, structure) {
      let level =
        structure.getAllLevels()[Object.keys(structure.getAllLevels())[0]];
      prepareMesh(mesh);
      level.addMeshToLevel(mesh, false);
    }

    let structureCollection = StructureCollection.getInstance().getStructures();
    let structure = structureCollection[store.activeLayer.structure_id];

    addMeshToStructure(mesh, structure);
    throwAwayMesh(mesh);

    return mesh.getSnaptrudeDS();
  }

  function addTask(componentData, loadFormat) {
    if (!componentData) return;

    for (const component of componentData) {
      let mesh = store.scene.getMeshByName(component.name);

      if (!mesh) {
        let taskName = component.name;
        let sceneFileName = component.name + loadFormat;
        let rootUrl = component.rootUrl;

        assetsManager.addMeshTask(taskName, "", rootUrl, sceneFileName);
      }
    }
  }

  function load(callback) {
    document.body.style.cursor = "wait";
    assetsManager.onFinish = callback;
    assetsManager.load();
  }

  function reset() {
    assetsManager.reset();
  }

  return {
    init,
    addTask,
    load,
    prepareLoadedMesh,
    reset,
    FORMAT,
  };
})(store.scene);
export { ComponentManager };
