import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { LevelCollection } from "./levels.ds.js";
import {
  isMeshOfType,
  setMeshVisibility,
  removeMeshThrowAwayIdentifier,
  onSolid,
} from "../extrafunc.js";
import { Wall } from "./wall.ds.js";
import { Floor } from "./floor.ds.js";
import { Roof } from "./roof.ds.js";
import { Mass } from "./mass.ds.js";
import { Furniture } from "./furniture.ds.js";
import { Staircase } from "./staircase.ds.js";
import { backwardCompatibilityChecker } from "../backwardCompatibility/backwardCompatibility.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import {
  edgeArrayToBabylonVector,
  drawCADLineSystem,
  pointArrayToV3,
} from "../cadImporter/cadServices.js";
import { circle2D, line2D } from "../meshoperations/sketchOperation.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { getGeometryById } from "../../libs/sceneStateFuncs.js";
import { FloorPlan } from "./floorplan.ds.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { makeid } from "../../libs/arrayFuncs.js";
import { loaderView } from "../../libs/loaderView.js";
import { _changeTextureDetail } from "../../libs/mats.js";
import { meshUniqueIdMapper } from "../utilityFunctions/meshUniqueIdMapper";
import { GFSLoadError } from "../exceptions/sceneLoad.exceptions";
import reduxStore from "../../stateManagers/store/reduxStore";
import { appendLayer } from "../../stateManagers/reducers/objectProperties/storeysSlice";
import topoLayer from "../../../assets/tiles/topoLayer.svg";
import cadLayer from "../../../assets/tiles/cadLayer.svg";
import { Pdf } from "./pdf.ds";
import pdfIcon from "../../../assets/subMenuIcon/import/importpdf.svg";
import snapEngine from "../snappingEngine/snapEngine";
import { revitDataPreparation } from "../revitExport/revitExport.js";
import { Locker } from "../locker/locker.js";
import {getFilterSelectionRevitLinks} from "../speckleRevitImport/revitLinks";
import {addFilter} from "../../stateManagers/reducers/objectProperties/meshSelection";

/**
 * { structure list datastructure }
 *
 * @class      StructureList (name)
 * @return     {(Object|number)}  { description_of_the_return_value }
 */
function StructureList() {
  var structures = {};
  var storeyCollection = {};
  var count = 0;
  // Defines left over data to be recreated using gfs data
  var leftOvers = {
    instances: [],
    activeLayer: null,
  };

  return {
    addStructure: function (key) {
      if (!structures.hasOwnProperty(key)) {
        structures[key] = new LevelCollection();
        count++;
      }
      return structures[key];
    },
    flushLevels: function () {
      if (structures) {
        for (const structure in structures) {
          structures[structure].deleteAllLevels();
        }
      }
    },
    createStoreyCollection: function (storeyData) {
      storeyCollection = {};
    },
    recreateStoreys: function (storeyData) {},
    getStructureById: function (key) {
      return structures[key];
    },
    changeKey: function (key1, key2) {
      structures[key2] = structures[key1];
      this.deleteStructure(key1);
    },
    deleteStructure: function (key) {
      delete structures[key];
    },
    getStructures: function () {
      return structures;
    },
    getAllComponents: function () {
      return Object.values(structures).reduce((componentsArray, structure) => {
        componentsArray.push(...structure.getAllComponents());
        return componentsArray;
      }, []);
    },
    getCount: function () {
      return count;
    },
    getLeftOvers: function () {
      return leftOvers;
    },
    flushLeftOvers: function () {
      leftOvers = {
        instances: [],
        activeLayer: null,
      };
    },
    deleteAllStructures: function () {
      structures = {};
    },
    addLevel: function (low, high, height = 12) {
      this.add(low, high, height);
      return this.get(low, high);
    },
    addMeshTolevel: function (low, high, mesh, type = "") {
      if (!this.get(low, high)) throw "you need to add level first";

      if (!type) this.addMesh(low, high, mesh);
      else this.addMesh(low, high, type, mesh);
    },
    recreateLevels: function (newLevels, structure) {
      let st = this.getStructureById(structure);
      st.recreateLevels(newLevels);
    },
    getSerializedComponent: function (component, forRevit=false) {
      //Component could be mass/ wall/ roof whatever

      let dsProps = {};
      let mesh = {};
      let parent = null;
      let drawnMassOrVoid =
        component.mesh.parent &&
        ["mass", "void"].includes(component.type.toLowerCase());
      if (component.mesh.parent) {
        parent = component.mesh.parent.uniqueId;
        component.mesh.setParent(null);
        component.mesh.computeWorldMatrix(true);
      }
      if (component.mesh.sourceMesh) {
        mesh.meshes = [{}];
        mesh.meshes[0].uniqueIdSource = component.mesh.sourceMesh.uniqueId;
        if (drawnMassOrVoid) {
          //for instances of drawn masses
          mesh.meshes[0].position = component.mesh
            .getAbsolutePosition()
            .asArray();
        } else {
          mesh.meshes[0].position = component.mesh.position.asArray();
        }
        mesh.meshes[0].rotation = component.mesh.rotation.asArray();
        if (component.mesh.rotationQuaternion)
          mesh.meshes[0].rotationQuaternion =
            component.mesh.rotationQuaternion.asArray();
        mesh.meshes[0].scaling = component.mesh.scaling.asArray();
        mesh.meshes[0].animations = component.mesh.animations;
        mesh.meshes[0].id = component.mesh.id;
        mesh.meshes[0].name = component.mesh.name;

        component.mesh.assignAdditionalProps(component.mesh, mesh.meshes[0]);

        if (
          isMeshOfType(component.mesh, [
            "wall",
            "mass",
            "floor",
            "roof",
            "void",
            "door",
          ])
        ) {
          let childrenComp = [];
          if (component.mesh.childrenComp) {
            if (component.mesh.childrenComp.length > 0) {
              // console.log("CHILDRENCOMP", component.mesh.childrenComp);
              component.mesh.childrenComp.forEach(function (child) {
                childrenComp.push(child.uniqueId);
              });
            }
          }
          mesh.meshes[0].childrenComp = childrenComp;
          dsProps["keepSourceMesh"] = true;
        }
      } else {
        mesh = BABYLON.SceneSerializer.SerializeMesh(component.mesh);
        delete mesh.materials;

        // let dsProps = {};
        if (
          isMeshOfType(component.mesh, [
            "wall",
            "mass",
            "floor",
            "roof",
            "void",
            "door",
          ])
        ) {
          let childrenComp = [];
          if (component.mesh.childrenComp) {
            if (component.mesh.childrenComp.length > 0) {
              // console.log("CHILDRENCOMP", component.mesh.childrenComp);
              component.mesh.childrenComp.forEach(function (child) {
                childrenComp.push(child.uniqueId);
              });
            }
          }
          mesh.meshes[0].childrenComp = childrenComp;
          dsProps["keepSourceMesh"] = true;
        }

        if (isMeshOfType(component.mesh, ["door", "window", "furniture"])) {
          dsProps["keepSourceMesh"] = false;
        }

        if (mesh.meshes)
          component.mesh.assignAdditionalProps(component.mesh, mesh.meshes[0]);

        if (component.mesh.originalScaling)
          mesh.meshes[0].originalScaling =
            component.mesh.originalScaling.asArray();
        if (component.mesh.originalRotation)
          mesh.meshes[0].originalRotation =
            component.mesh.originalRotation.asArray();
        if (component.mesh.storey)
          mesh.meshes[0].storey = component.mesh.storey;
        dsProps["instancesLength"] = component.mesh.instances.length;
      }
      let dsPropEntries = Object.entries(component);
      dsPropEntries.forEach(function (entry) {
        if (!_.isFunction(entry[1]) && entry[0].toLowerCase() !== "mesh") {
          if (["brep"].includes(entry[0])) {
            // dsProps[entry[0]] = Flatted.stringify(entry[1]);
            // const resurrect = new Resurrect({
            //   resolver: new Resurrect.NamespaceResolver(snapmda),
            //   cleanup: true
            // });
            dsProps[entry[0]] = store.resurrect.stringify(entry[1]);
          } else dsProps[entry[0]] = entry[1];
        }
      });
      dsProps["uniqueID"] = component.mesh.uniqueId;
      if (dsProps["meta"]) {
        dsProps["meta"].length = 0;
      }
      // console.log(dsProps);
      // snaptrudeDS["structures"][str][level][key][object]["dsProps"] = {};

      if (parent) {
        component.mesh.setParent(store.scene.getMeshByUniqueID(parent));
      }

      mesh.dsProps = dsProps;

      // if(mesh.dsProps.brep){
      //     delete mesh.geometries
      // }

      let originalWallMesh = mesh.dsProps.originalWallMesh;
      if (originalWallMesh) {
        delete originalWallMesh.materials;
      }

      if (forRevit) {
        mesh.geometries = {}
      }

      return mesh;
    },
    getSnaptrudeStructureDataSerialized: function(forRevit=false) {
      //console.log("serializing Snaptrude");

      let snaptrudeDS = {};
      snaptrudeDS["structures"] = {};

      snaptrudeDS["metadata"] = {
        revitDeleteIds: revitDataPreparation.deletedElements,
        filterSelectionRevitLinks: getFilterSelectionRevitLinks()
      }

      let structures = this.getStructures();
      for (let str in structures) {
        snaptrudeDS["structures"][str] = {};
        snaptrudeDS["structures"][str]["storeys"] = {};
        snaptrudeDS["structures"][str]["llCluster"] = {};

        let storeys = structures[str].getStoreyData().getAllStoreys();
        for (let storey in storeys) {
          snaptrudeDS["structures"][str]["storeys"][storey] = {};
          snaptrudeDS["structures"][str]["storeys"][storey].id =
            storeys[storey].id;
          snaptrudeDS["structures"][str]["storeys"][storey].structure_id =
            storeys[storey].structure_id;
          snaptrudeDS["structures"][str]["storeys"][storey].type =
            storeys[storey].type;
          snaptrudeDS["structures"][str]["storeys"][storey].name =
            storeys[storey].name;
          snaptrudeDS["structures"][str]["storeys"][storey].base =
            storeys[storey].base;
          snaptrudeDS["structures"][str]["storeys"][storey].height =
            storeys[storey].height;
          snaptrudeDS["structures"][str]["storeys"][storey].value =
            storeys[storey].value;
          snaptrudeDS["structures"][str]["storeys"][storey].hidden =
            storeys[storey].hidden;
          snaptrudeDS["structures"][str]["storeys"][storey].revitMetaData =
            storeys[storey].revitMetaData ? storeys[storey].revitMetaData : {};

          let _elements = storeys[storey].elements;
          snaptrudeDS["structures"][str]["storeys"][storey].elements = [];

          for (let i = 0; i < _elements.length; i++) {
            snaptrudeDS["structures"][str]["storeys"][storey].elements.push(
              _elements[i].mesh.uniqueId
            );
          }

          let layers = storeys[storey].layerData.getAllLayers();
          snaptrudeDS["structures"][str]["storeys"][storey]["layers"] = [];

          for (let layer in layers) {
            snaptrudeDS["structures"][str]["storeys"][storey]["layers"][layer] =
              {};
            let layerData =
              snaptrudeDS["structures"][str]["storeys"][storey]["layers"][
                layer
              ];
            layerData.id = layers[layer].id;
            layerData.name = layers[layer].name;
            layerData.structure_id = layers[layer].structure_id;
            layerData.type = layers[layer].type;
            layerData.layerType = layers[layer].layerType;
            layerData.storey = layers[layer].storey;
            layerData.hidden = layers[layer].hidden;
            layerData.sketches = [];
            layerData.floorplans = [];
            layerData.terrain = layers[layer].terrain;
            layerData.isLocked = layers[layer].isLocked;

            layers[layer].sketches.forEach(function (sketch) {
              let newSketch = {
                points: null,
                scaling: null,
                position: null,
                edgeArray: null,
              };
              newSketch.points = sketch.points;
              newSketch.color = sketch.color;
              newSketch.thickness = sketch.thickness;
              newSketch.edgeArray = sketch.edgeArray;

              if (!_.isArray(sketch.mesh)) {
                if (!sketch.edgeArray) {
                  //If edgeArray is true, it's CAD
                  newSketch.id = sketch.id;
                  newSketch.scaling = sketch.mesh.scaling.asArray();
                  newSketch.position = sketch.mesh.position.asArray();
                } else {
                  newSketch.position = sketch.position;
                }
              }

              layerData.sketches.push(newSketch);
            });

            layers[layer].floorplans.forEach(function (plan) {
              let floorplan = {};
              floorplan.id = plan.id;
              floorplan.type = plan.type;
              floorplan.structure_id = plan.structure_id;
              floorplan.storey = plan.storey;
              floorplan.height = plan.height;
              floorplan.scaleFactor = plan.scaleFactor;
              floorplan.floorPlanImageHeight = plan.floorPlanImageHeight;
              floorplan.floorPlanImageWidth = plan.floorPlanImageWidth;
              floorplan.strNum = plan.strNum;
              floorplan.mesh = BABYLON.SceneSerializer.SerializeMesh(plan.mesh);
              floorplan.mesh.meshes[0].uniqueId = plan.mesh.uniqueId;
              layerData.floorplans.push(floorplan);
            });
          }
        }

        let llCluster = structures[str]
          .getLinkedListCluster()
          .getAllLinkedList();
        for (let dLLId in llCluster) {
          let dLL = llCluster[dLLId];
          snaptrudeDS["structures"][str]["llCluster"][dLLId] = [];
          dLL.traverse((node) => {
            snaptrudeDS["structures"][str]["llCluster"][dLLId].push(node.data);
          });
        }

        let levels = structures[str].getAllLevels();
        for (let level in levels) {
          // snaptrudeDS[str] = levels[level].flyweight;
          snaptrudeDS["structures"][str][level] = {};
          for (let key in levels[level].flyweight) {
            snaptrudeDS["structures"][str][level][key] = {}; //flyweight.masses[] is becoming masses{} here
            let objects = levels[level].flyweight[key];
            if (typeof objects === "object") {
              for (let object in objects) {
                if (objects[object] && objects[object].mesh) {
                  if (objects[object].mesh.name.includes("terrain")) {
                    continue;
                  }
                  let serializedComponent =
                    StructureCollection.getInstance().getSerializedComponent(
                      objects[object],
                      forRevit
                    );
                  snaptrudeDS["structures"][str][level][key][object] =
                    serializedComponent;
                }
                // else{
                //     console.log(objects[object]);
                //     snaptrudeDS["structures"][str][level][key][object] = objects[object];
                // }
              }
            } else {
              snaptrudeDS["structures"][str][level][key] =
                levels[level].flyweight[key];
            }
          }
        }
      }
      // let serialScene = BABYLON.SceneSerializer.Serialize(scene);
      // snaptrudeDS["materials"] = serialScene["materials"];
      // snaptrudeDS["multiMaterials"] = serialScene["multiMaterials"];
      snaptrudeDS["materials"] = store.scene.serializeMaterials();
      snaptrudeDS["multiMaterials"] = store.scene.serializeMultiMaterials();
      snaptrudeDS["cameras"] = store.scene.serializeCameras();
      snaptrudeDS["lights"] = store.scene.serializeLights();

      snaptrudeDS["userSettings"] = store.userSettingsInStructure;

      // snaptrudeDS["storeys"] = store.storeysDS;
      if (store.activeLayer)
        snaptrudeDS["activeLayer"] = {
          name: store.activeLayer.name,
          structure_id: store.activeLayer.structure_id,
          storey: store.activeLayer.storey,
        };
      return snaptrudeDS;
    },
    getSnaptrudeJSON: function(forRevit=false) {
      let serializedData = this.getSnaptrudeStructureDataSerialized(forRevit);
      return JSON.stringify(serializedData);
    },
    recreateSceneStructure: function (
      data,
      reloadMat = false,
      deleteStructures = true
    ) {
      if (deleteStructures) {
        this.deleteAllStructures();
      }
      var instancesData = [];
      var childMissingWalls = [];
      revitDataPreparation.resetRevitDeletedElements();

      if (data?.metadata?.revitDeleteIds) {
        revitDataPreparation.deletedElements.push(...data.metadata.revitDeleteIds);
      }

      if (data?.metadata?.filterSelectionRevitLinks) {
        const filterRvtLinks = data.metadata.filterSelectionRevitLinks;
        filterRvtLinks.forEach(frl => {
          reduxStore.dispatch(
              addFilter({
                type: frl,
              })
          );
        })
      }

      for (let str in data["structures"]) {
        this.addStructure(str);
        let totalBytes = 1;
        let elementStr = Object.assign({}, data["structures"][str]);
        delete elementStr.storeys;
        delete elementStr.llCluster;
        let counter = 0;

        if (str !== "default") {
          totalBytes = JSON.stringify(elementStr).length;
        }
        let structure = this.getStructureById(str);
        structure.deleteAllLevels();
        structure.getStoreyData().deleteAllStoreys();
        for (let storeyId in data["structures"][str]["storeys"]) {
          let storeyData = data["structures"][str]["storeys"][storeyId];
          let storey = structure
            .getStoreyData()
            .addStorey(storeyData.structure_id, storeyData.value, null, {
              autoSave: false,
            });
          storey.id = storeyData.id;
          storey.type = storeyData.type;
          storey.height = storeyData.height;
          storey.base = storeyData.base;
          storey.hidden = storeyData.hidden;
          storey.revitMetaData = storeyData?.revitMetaData ? storeyData?.revitMetaData: {};
          if (storeyData.name) storey.name = storeyData.name;
          /*
                    for (let i = 0; i < storeyData.elements.length; i++) {
                        let object = structure.getObjectByUniqueId(storeyData.elements[i]);
                        if (object) storey.elements.push(object);
                    }
                    */

          storey.layerData.deleteAllLayers();

          let layers = storeyData["layers"];
          layers = backwardCompatibilityChecker.layersChecker(layers);

          for (let layerData of layers) {
            // let layerData = layers[layerId];
            let layer = storey.layerData.addLayer(
              layerData.name,
              layerData.structure_id,
              layerData.layerType,
              null,
              { autoSave: false }
            );
            layer.id = layerData.id;
            layer.type = layerData.type;
            layer.storey = layerData.storey;
            layer.hidden = layerData.hidden;
            layer.terrain = layerData.terrain;
            layer.pdfs = layer.pdfs ? layer.pdfs : [];
            layer.neighborhood = layerData.neighborhood
              ? layerData.neighborhood
              : [];
            layer.isLocked = layerData.isLocked;

            layerData.sketches.forEach(function (sketchPoints) {
              if (layerData.layerType === "cad" && sketchPoints.edgeArray) {
                const data = sketchPoints;
                const babyArray = edgeArrayToBabylonVector(data.edgeArray);
                const pointArrayV3 = data.pointArray
                  ? pointArrayToV3(data.pointArray)
                  : null;

                const cadSystem = drawCADLineSystem(babyArray, pointArrayV3);
                cadSystem.layerName = layerData.name;
                cadSystem.storey = layerData.storey;
                if (data.position) {
                  cadSystem.position = new BABYLON.Vector3(...data.position);
                }
                if (data.rotationQuaternion) {
                  cadSystem.rotationQuaternion = new BABYLON.Quaternion(
                    data.rotationQuaternion[0],
                    data.rotationQuaternion[1],
                    data.rotationQuaternion[2],
                    data.rotationQuaternion[3]
                  );
                }

                layer.sketches = [
                  {
                    edgeArray: data.edgeArray,
                    position: data.position,
                    mesh: cadSystem,
                  },
                ];
                layer.mesh = cadSystem;
                snapEngine.cadSnaps.update(cadSystem);
                if(layer.isLocked){
                  Locker.lockOnRecreate([{mesh: layer.mesh}]);
                }
              } else {
                if (sketchPoints.points.length === 0) return;

                if (!sketchPoints.points) sketchPoints.points = sketchPoints;

                sketchPoints =
                  backwardCompatibilityChecker.sketchPointsToVector3(
                    sketchPoints
                  );
                if (
                  sketchPoints.points.length > 0 &&
                  sketchPoints.points[sketchPoints.points.length - 1].length < 2
                ) {
                  sketchPoints.points.pop();
                }

                let lineMesh = [];
                let numberOfEdgesOnCircle = 20;
                let uniqId = sketchPoints.id;
                let points = sketchPoints.points;
                for (let i = 0; i < points.length; i++) {
                  let filledCircle = circle2D(
                    sketchPoints.thickness,
                    numberOfEdgesOnCircle,
                    store.scene,
                    sketchPoints.color
                  );
                  filledCircle.position = points[i][0];
                  let line = line2D(
                    "line",
                    {
                      path: points[i],
                      width: 1.987 * sketchPoints.thickness,
                    },
                    store.scene,
                    sketchPoints.color
                  );
                  lineMesh.push(filledCircle);
                  lineMesh.push(line);
                }
                let lineSystem = BABYLON.Mesh.MergeMeshes(lineMesh, true, true);
                lineSystem.name = "sketchLine";
                lineSystem.isPickable = false;

                if (!store.$scope.isTwoDimension) lineSystem.visibility = 0;

                let sketch = {};
                sketch.id = uniqId;
                sketch.points = points;
                sketch.mesh = lineSystem;
                sketch.mesh.structure_id = str;
                sketch.color = sketchPoints.color;
                sketch.thickness = sketchPoints.thickness;

                sketch.mesh.isVisible = !layer.hidden;
                sketch.mesh.type = "linesystem";
                layer.sketches.push(sketch);
              }
            });

            if (layerData.floorplans) {
              try {
                layerData.floorplans.forEach(function (plan) {
                  //Backward compatibility for projects with floorplans missing geometries.
                  if (
                    !backwardCompatibilityChecker.doesGeometriesExistForFloorplan(
                      plan
                    )
                  ) {
                    return;
                  }
                  let geom = getGeometryById(
                    plan.mesh.geometries.vertexData,
                    plan.mesh.meshes[0].geometryId
                  );
                  let createdMesh = store.scene.recreateMesh(
                    plan.mesh.meshes[0],
                    geom,
                    null
                  );

                  createdMesh.layer_id = plan.mesh.layer_id;
                  let floorPlan = new FloorPlan(createdMesh);
                  createdMesh.strNum = plan.strNum;
                  createdMesh.storey = plan.storey;
                  // createdMesh.uniqueId = plan.mesh.meshes[0].uniqueId;
                  createdMesh.structure_id = plan.structure_id;
                  if (layer.hidden || !plan.mesh?.isVisible) {
                    createdMesh.isVisible = false;
                    store.makeFloorPlanOrCADInvisible = true;
                  }
                  floorPlan.id = plan.id;
                  floorPlan.type = plan.type;
                  floorPlan.structure_id = plan.structure_id;
                  floorPlan.storey = plan.storey;
                  floorPlan.height = plan.height;
                  floorPlan.scaleFactor = plan.scaleFactor;
                  floorPlan.initialScaleFactor = plan.initialScaleFactor
                    ? plan.initialScaleFactor
                    : 1;
                  floorPlan.floorPlanImageHeight = plan.floorPlanImageHeight;
                  floorPlan.floorPlanImageWidth = plan.floorPlanImageWidth;
                  floorPlan.identifierForBackend = plan.identifierForBackend;
                  layer.addFloorPlan(floorPlan);
                  if(layer.isLocked){
                    Locker.lockOnRecreate([{mesh: createdMesh}]);
                    floorPlan.isLocked = true;
                  }
                });
              } catch (e) {
                console.log("Error in recreating floorplan", e);
              }
            }

            if (layerData.terrain) {
              try {
                layerData.terrain.forEach((saveData, index) => {
                  let data = saveData.parameters;
                  let terrain = BABYLON.MeshBuilder.CreateGround(
                    "terrain",
                    {
                      width: data.width,
                      height: data.height,
                      subdivisionsX: data.subdivisionsX,
                      subdivisionsY: data.subdivisionsY,
                      updatable: true,
                    },
                    store.scene
                  );

                  meshUniqueIdMapper.update(terrain, data.uniqueId);
                  layerData.terrain[index].parameters.layerId = layerData.id;

                  let mapData = terrain.getVerticesData(
                    BABYLON.VertexBuffer.PositionKind
                  );
                  if (data.subdivisionsX !== 0) {
                    for (let y = 0; y < data.imgHeight; y++) {
                      for (let x = 0; x < data.imgWidth; x++) {
                        let i = (y * data.imgWidth + x) * 4;
                        let h =
                          -10000 +
                          (data.imgPixels.data[i] * 256 * 256 +
                            data.imgPixels.data[i + 1] * 256 +
                            data.imgPixels.data[i + 2]) *
                            0.1;
                        h =
                          DisplayOperation.getOriginalDimension(
                            h,
                            "millimeter"
                          ) * 1000;
                        mapData[(y * data.imgWidth + x) * 3 + 1] = h;
                      }
                    }
                    layerData.heightMapToggle = true; // If subdivisions exist, then height map should be toggled on, this is for the ui.
                  }

                  let terrainMaterial = new BABYLON.StandardMaterial(
                    "terrainMaterial",
                    store.scene
                  );
                  terrainMaterial.backFaceCulling = false;
                  terrainMaterial.diffuseTexture =
                    new BABYLON.Texture.CreateFromBase64String(
                      data.textURL,
                      "terrain" + makeid(3),
                      store.scene
                    );
                  terrain.updateVerticesData(
                    BABYLON.VertexBuffer.PositionKind,
                    mapData,
                    true
                  );
                  // terrain.position.y -= terrain.getBoundingInfo().boundingBox.minimumWorld.y;
                  // terrain.position.x += (data.width + 1) / 2;
                  // terrain.position.z -= (data.height + 1) / 2;
                  terrain.position = BABYLON.Vector3.FromArray(
                    data.terrainPosition
                  );
                  if (data.rotationQuaternion) {
                    terrain.rotationQuaternion = new BABYLON.Quaternion(
                      data.rotationQuaternion[0],
                      data.rotationQuaternion[1],
                      data.rotationQuaternion[2],
                      data.rotationQuaternion[3]
                    );
                  }
                  terrain.material = terrainMaterial;
                  if (data.mapCenter) {
                    terrain.mapCenter = data.mapCenter;
                  }
                  if (data.mapZoom) {
                    terrain.mapZoom = data.mapZoom;
                  }

                  let structure =
                    StructureCollection.getInstance().getStructures()[
                      data.structure
                    ];
                  let structureID = data.structure;
                  // let levelLow = (parseInt(data.storey) - 1).toString();
                  // let levelHigh = (parseInt(levelLow) + 1).toString();

                  terrain.structure_id = structureID;
                  terrain.type = "Mass";
                  terrain.storey = data.storey;

                  let level;
                  level = structure.getLevel("0", "1");
                  if (!level) structure.addLevel("0", "1");
                  let levelID = "01";

                  // const structureCollection = StructureCollection.getInstance();
                  // const talkingAboutStructure =
                  //   structureCollection.getStructureById(structureID);
                  const talkingAboutLevel = structure.getLevelByName(levelID);
                  const mass = new Mass(terrain);
                  talkingAboutLevel.addObjectToLevel(mass, false);

                  // layerView.addAndSelectLayer("terrain", data.structure, 1);
                  // ScopeUtils.addLayer(activeLayer);
                  // store.activeLayer.addTerrain(terrain);
                  // store.activeLayer.name = data.layerName;
                  terrain.getSnaptrudeDS().massType = "Mass";
                  terrain.layerName = data.layerName;
                  if(layer.isLocked){
                    Locker.lockOnRecreate([{mesh: terrain}]);
                    Locker.lockOnRecreate([terrain.getSnaptrudeDS(), mass]);
                  }
                });
              } catch (e) {
                console.log("Error in Terrain Recreation", e);
              }
            }

            if (layerData.pdfs) {
              try {
                layerData.pdfs.forEach((saveData) => {
                  let data = saveData;

                  let _pdfMesh = BABYLON.MeshBuilder.CreatePlane(
                    "pdfMesh",
                    { width: data.width, height: data.height },
                    store.scene
                  );
                  if( data.uniqueId ){
                    meshUniqueIdMapper.update(_pdfMesh, data.uniqueId);
                  }
                  let pdfMat = new BABYLON.StandardMaterial(
                    "pdfMaterial",
                    store.scene
                  );
                  pdfMat.backFaceCulling = false;
                  pdfMat.diffuseTexture =
                    new BABYLON.Texture.CreateFromBase64String(
                      data.sourcePdfInBase64,
                      "pdfMat_" + makeid(3),
                      store.scene
                    );

                  _pdfMesh.material = pdfMat;
                  _pdfMesh.position = BABYLON.Vector3.FromArray(
                    data.pdfPosition
                  );
                  // _pdfMesh.rotation.x = Math.PI / 2;
                  if (data.rotationQuaternion) {
                    _pdfMesh.rotationQuaternion = BABYLON.Quaternion.FromArray(
                      data.rotationQuaternion
                    );
                  } else {
                    // _pdfMesh.rotationQuaternion = BABYLON.Quaternion.Identity();
                    _pdfMesh.rotation.x = Math.PI / 2;
                  }
                  _pdfMesh.layer_id = data.layerId;
                  _pdfMesh.storey = data.storey;
                  _pdfMesh.structure_id = data.structure_id;

                  let _pdfDS = new Pdf(_pdfMesh);
                  _pdfDS.type = "pdf";
                  _pdfDS.structure_id = data.structure_id;
                  _pdfDS.storey = data.storey;
                  _pdfDS.scaleFactor = data.scaleFactor;
                  _pdfDS.mesh.isVisible = true;
                  _pdfDS.width = data.width;
                  _pdfDS.height = data.height;
                  _pdfDS.sourcePdfInBase64 = data.sourcePdfInBase64;

                  layer.addPdf(_pdfDS);
                  if(layer.isLocked){
                    Locker.lockOnRecreate([{mesh: _pdfMesh}]);
                    _pdfDS.isLocked = true;
                  }
                });
              } catch (e) {
                console.log("Error re-creating pdf mesh");
              }
            }
          }
        }

        let linkedListCluster = structure.getLinkedListCluster();
        if(data && data["structures"] && data["structures"][str] && data["structures"][str]["llCluster"]){
          for (let dLLId in data["structures"][str]["llCluster"]) {
            let dataArr = data["structures"][str]["llCluster"][dLLId];
  
            if (dataArr.length > 0) {
              let dLL = linkedListCluster.addNewLinkedList(dLLId);
  
              dataArr.map((data) => {
                dLL.append(data);
              });
            }
          }
        }
        for (let level in data["structures"][str]) {
          if (
            level === "storeys" ||
            level === "llCluster" ||
            level === "layers"
          )
            continue;
          let low = data["structures"][str][level]["low"];
          let high = data["structures"][str][level]["high"];

          let levelObj = structure.getLevel(low, high);
          if (!levelObj) {
            levelObj = structure.addLevel(low, high, level, {
              autoSave: false,
            });
          }
          // levelObj.flyweight.uniqueId = data["structures"][str][level]["uniqueId"];
          levelObj.flyweight.uniqueId =
            data["structures"][str][level]["uniqueId"];
          for (let levelProp in data["structures"][str][level]) {
            if (typeof data["structures"][str][level][levelProp] === "object") {
              for (let obj in data["structures"][str][level][levelProp]) {
                try {
                  counter += JSON.stringify(
                    data["structures"][str][level][levelProp][obj]
                  ).length;
                  // TODO: make loader
                  //loaderView.showProgress(totalBytes, counter);
                  if (!data["structures"][str][level][levelProp][obj]) continue;
                  if (data["structures"][str][level][levelProp][obj].meshes) {
                    for (
                      let mesh = 0;
                      mesh <
                      data["structures"][str][level][levelProp][obj]["meshes"]
                        .length;
                      mesh++
                    ) {
                      mesh = mesh.toString();
                      if (
                        backwardCompatibilityChecker.isComponentCorrupt(
                          data["structures"][str][level][levelProp][obj]
                        )
                      )
                        continue;
                      if (
                        data["structures"][str][level][levelProp][obj][
                          "geometries"
                        ]
                      ) {
                        if (
                          data["structures"][str][level][levelProp][obj][
                            "meshes"
                          ][mesh].type
                        ) {
                          if (
                            typeof data["structures"][str][level][levelProp][
                              obj
                            ]["meshes"][mesh] == "object"
                          ) {
                            let geom = getGeometryById(
                              data["structures"][str][level][levelProp][obj][
                                "geometries"
                              ]["vertexData"],
                              data["structures"][str][level][levelProp][obj][
                                "meshes"
                              ][mesh]["geometryId"]
                            );

                            let uid =
                              data["structures"][str][level][levelProp][obj][
                                "dsProps"
                              ]["uniqueID"];

                            if (meshUniqueIdMapper.get(uid)){
                              // somehow the uniqueId has already been used
                              console.warn("Duplicate uniqueId found, skipping mesh creation", uid);
                              continue;
                            }

                            let createdMesh = store.scene.recreateMesh(
                              data["structures"][str][level][levelProp][obj][
                                "meshes"
                              ][mesh],
                              geom,
                              data["structures"][str][level][levelProp][obj][
                                "dsProps"
                              ]["instancesLength"],
                              data["structures"][str][level][levelProp][obj][
                                "dsProps"
                              ]["keepSourceMesh"]
                            );

                            meshUniqueIdMapper.update(createdMesh, uid);

                            createdMesh.assignAdditionalProps(
                              data["structures"][str][level][levelProp][obj][
                                "meshes"
                              ][mesh],
                              createdMesh
                            );
                            if (
                              data["structures"][str][level][levelProp][obj][
                                "meshes"
                              ][mesh]["childrenComp"]
                            ) {
                              // console.log(data["structures"][str][level][levelProp][obj]["meshes"][mesh]["childrenComp"]);
                              createdMesh.childrenComp = [];
                              createdMesh.childrenCompMissing = [];
                              let childrenComp =
                                data["structures"][str][level][levelProp][obj][
                                  "meshes"
                                ][mesh]["childrenComp"];
                              //console.log(childrenComp);
                              if (childrenComp.length > 0) {
                                childrenComp.forEach(function (child) {
                                  let childMesh =
                                    store.scene.getMeshByUniqueID(child);
                                  if (childMesh) {
                                    // let pos = childMesh.position.clone();
                                    if (
                                      childMesh.type.toLowerCase() === "mass" ||
                                      childMesh.type.toLowerCase() === "void"
                                    ) {
                                      childMesh.setParent(createdMesh);
                                    } else {
                                      //doors and windows
                                      childMesh.setParent(createdMesh);
                                      // childMesh.parent = createdMesh;
                                      // childMesh.position = new BABYLON.Vector3(pos.x, pos.y,pos.z);
                                    }
                                    createdMesh.childrenComp.push(childMesh);
                                  } else {
                                    createdMesh.childrenCompMissing.push(child);
                                  }
                                });
                                if (createdMesh.childrenCompMissing)
                                  childMissingWalls.push(createdMesh);
                              }
                            }
                            // let meshProp = data["structures"][str][level][levelProp][obj]["meshes"][mesh];
                            // createdMesh._absolutePosition = new BABYLON.Vector3(0, 0, 0);
                            // console.log(createdMesh._absolutePosition);
                            // if (meshProp.instances){
                            //     // console.log(mesh.instances);
                            //     for (let inst in meshProp.instances){
                            //         var newInstance = createdMesh.createInstance(meshProp.instances[inst].name);
                            //         newInstance.id = meshProp.instances[inst].id;
                            //         newInstance._properties = meshProp._properties;
                            //         newInstance.structure_id = meshProp.structure_id;
                            //         // console.log(mesh.instances[inst]);
                            //         // console.log(mesh.instances[inst].position)
                            //         if (meshProp.instances[inst].position)
                            //             newInstance.position = new BABYLON.Vector3(meshProp.instances[inst].position[0], meshProp.instances[inst].position[1], meshProp.instances[inst].position[2]);
                            //         if (meshProp.instances[inst].rotation)
                            //             newInstance.rotation = new BABYLON.Vector3(meshProp.instances[inst].rotation[0], meshProp.instances[inst].rotation[1], meshProp.instances[inst].rotation[2]);
                            //         if (meshProp.instances[inst].scaling)
                            //             newInstance.scaling = new BABYLON.Vector3(meshProp.instances[inst].scaling[0], meshProp.instances[inst].scaling[1], meshProp.instances[inst].scaling[2]);
                            //     }
                            // }
                            // for (let inst in createdMesh.instances) {
                            //     levelObj.addMeshToLevel(createdMesh.instances[inst], false);
                            // }

                            _assignMeshTypeBasedProperties(
                              createdMesh,
                              data["structures"][str][level][levelProp][obj][
                                "dsProps"
                              ],
                              levelObj
                            );

                            // createdMesh.getSnaptrudeDS().originalWallMesh =
                          }
                        }
                      } else {
                        // console.log(data["structures"][str][level][levelProp][obj]["meshes"][mesh]);
                        if (
                          data["structures"][str][level][levelProp][obj][
                            "dsProps"
                          ]["uniqueID"]
                        ) {
                          if (
                            typeof data["structures"][str][level][levelProp][
                              obj
                            ]["meshes"][mesh] == "object"
                          ) {
                            let sourceMesh = store.scene.getMeshByUniqueID(
                              data["structures"][str][level][levelProp][obj][
                                "meshes"
                              ][mesh].uniqueIdSource
                            );
                            let instanceData =
                              data["structures"][str][level][levelProp][obj][
                                "meshes"
                              ][mesh];
                            if (sourceMesh) {
                              let createdMesh = sourceMesh.createInstance(
                                instanceData["name"]
                              );
                              createdMesh.position = new BABYLON.Vector3(
                                instanceData.position[0],
                                instanceData.position[1],
                                instanceData.position[2]
                              );
                              // createdMesh.position.y = instanceData.position.y;
                              // createdMesh.position.z = instanceData.position.z;
                              if (instanceData.rotationQuaternion) {
                                createdMesh.rotationQuaternion =
                                  new BABYLON.Quaternion(
                                    instanceData.rotationQuaternion[0],
                                    instanceData.rotationQuaternion[1],
                                    instanceData.rotationQuaternion[2],
                                    instanceData.rotationQuaternion[3]
                                  );
                              } else {
                                createdMesh.rotation = new BABYLON.Vector3(
                                  instanceData.rotation[0],
                                  instanceData.rotation[1],
                                  instanceData.rotation[2]
                                );
                              }
                              // createdMesh.rotation.y = instanceData.rotation.y;
                              // createdMesh.rotation.z = instanceData.rotation.z;
                              createdMesh.scaling = new BABYLON.Vector3(
                                instanceData.scaling[0],
                                instanceData.scaling[1],
                                instanceData.scaling[2]
                              );
                              createdMesh.isVisible = true;
                              createdMesh.isPickable = true;

                              if (_.isBoolean(instanceData.isVisible))
                                createdMesh.isVisible = instanceData.isVisible;
                              if (_.isBoolean(instanceData.pickable))
                                createdMesh.isPickable = instanceData.pickable;

                              meshUniqueIdMapper.update(
                                createdMesh,
                                data["structures"][str][level][levelProp][obj][
                                  "dsProps"
                                ]["uniqueID"]
                              );
                              createdMesh.assignAdditionalProps(
                                data["structures"][str][level][levelProp][obj][
                                  "meshes"
                                ][mesh],
                                createdMesh
                              );

                              if (
                                data["structures"][str][level][levelProp][obj][
                                  "meshes"
                                ][mesh]["childrenComp"]
                              ) {
                                // console.log(data["structures"][str][level][levelProp][obj]["meshes"][mesh]["childrenComp"]);
                                createdMesh.childrenComp = [];
                                createdMesh.childrenCompMissing = [];
                                let childrenComp =
                                  data["structures"][str][level][levelProp][
                                    obj
                                  ]["meshes"][mesh]["childrenComp"];
                                //console.log(childrenComp);
                                if (childrenComp.length > 0) {
                                  childrenComp.forEach(function (child) {
                                    let childMesh =
                                      store.scene.getMeshByUniqueID(child);
                                    if (childMesh) {
                                      // let pos = childMesh.position.clone();
                                      if (
                                        childMesh.type.toLowerCase() ===
                                          "mass" ||
                                        childMesh.type.toLowerCase() === "void"
                                      ) {
                                        childMesh.setParent(createdMesh);
                                      } else {
                                        //doors and windows
                                        childMesh.setParent(createdMesh);
                                        // childMesh.parent = createdMesh;
                                        // childMesh.position = new BABYLON.Vector3(pos.x, pos.y,pos.z);
                                      }
                                      createdMesh.childrenComp.push(childMesh);
                                    } else {
                                      createdMesh.childrenCompMissing.push(
                                        child
                                      );
                                    }
                                  });
                                  if (
                                    createdMesh.childrenCompMissing.length > 0
                                  )
                                    childMissingWalls.push(createdMesh);
                                }
                              }

                              _assignMeshTypeBasedProperties(
                                createdMesh,
                                data["structures"][str][level][levelProp][obj][
                                  "dsProps"
                                ],
                                levelObj,
                                sourceMesh
                              );
                              // levelObj.addMeshToLevel(createdMesh, false);
                            } else if (instanceData) {
                              let iData = {};
                              iData.structure = str;
                              iData.level = level;
                              iData.levelProp = levelProp;
                              iData.levelObj = levelObj;
                              iData.obj = obj;
                              iData.mesh = mesh;
                              instancesData.push(iData);
                            }
                          }
                        }
                      }
                      mesh = parseInt(mesh);
                    }
                  }
                } catch (e) {
                  console.warn(e);
                  console.warn("Creation failed for source mesh-");
                  console.warn(data["structures"][str][level][levelProp][obj]);
                }
              }
            }
          }
        }
      }
      // if (childMissingWalls){
      //     for (let mesh in childMissingWalls){
      //         for (let child in childMissingWalls[mesh].childrenCompMissing){
      //             let childMesh = store.scene.getMeshByUniqueID(childMissingWalls[mesh].childrenCompMissing[child]);
      //             if (childMesh){
      //                 childMesh.setParent(childMissingWalls[mesh]);
      //                 childMissingWalls[mesh].childrenComp.push(childMesh);
      //             }
      //         }
      //     }
      // }

      if (instancesData) {
        for (let inst of instancesData) {
          let str = inst["structure"];
          let level = inst["level"];
          let levelProp = inst["levelProp"];
          let obj = inst["obj"];
          let mesh = inst["mesh"];
          let levelObj = inst["levelObj"];
          if (!str) continue;

          try {
            let sourceMesh = store.scene.getMeshByUniqueID(
              data["structures"][str][level][levelProp][obj]["meshes"][mesh]
                .uniqueIdSource
            );
            let instanceData =
              data["structures"][str][level][levelProp][obj]["meshes"][mesh];
            if (sourceMesh) {
              let createdMesh = sourceMesh.createInstance(instanceData["name"]);
              createdMesh.position = new BABYLON.Vector3(
                instanceData.position[0],
                instanceData.position[1],
                instanceData.position[2]
              );
              // createdMesh.position.y = instanceData.position.y;
              // createdMesh.position.z = instanceData.position.z;
              if (instanceData.rotationQuaternion) {
                createdMesh.rotationQuaternion = new BABYLON.Quaternion(
                  instanceData.rotationQuaternion[0],
                  instanceData.rotationQuaternion[1],
                  instanceData.rotationQuaternion[2],
                  instanceData.rotationQuaternion[3]
                );
              } else {
                createdMesh.rotation = new BABYLON.Vector3(
                  instanceData.rotation[0],
                  instanceData.rotation[1],
                  instanceData.rotation[2]
                );
              }
              // createdMesh.rotation.y = instanceData.rotation.y;
              // createdMesh.rotation.z = instanceData.rotation.z;
              createdMesh.scaling = new BABYLON.Vector3(
                instanceData.scaling[0],
                instanceData.scaling[1],
                instanceData.scaling[2]
              );
              // createdMesh.scaling.y = instanceData.scaling.y;
              // createdMesh.scaling.z = instanceData.scaling.z;

              meshUniqueIdMapper.update(
                createdMesh,
                data["structures"][str][level][levelProp][obj]["dsProps"][
                  "uniqueID"
                ]
              );

              createdMesh.assignAdditionalProps(
                data["structures"][str][level][levelProp][obj]["meshes"][mesh],
                createdMesh
              );

              if (
                data["structures"][str][level][levelProp][obj]["meshes"][mesh][
                  "childrenComp"
                ]
              ) {
                // console.log(data["structures"][str][level][levelProp][obj]["meshes"][mesh]["childrenComp"]);
                createdMesh.childrenComp = [];
                createdMesh.childrenCompMissing = [];
                let childrenComp =
                  data["structures"][str][level][levelProp][obj]["meshes"][
                    mesh
                  ]["childrenComp"];
                //console.log(childrenComp);
                if (childrenComp.length > 0) {
                  childrenComp.forEach(function (child) {
                    let childMesh = store.scene.getMeshByUniqueID(child);
                    if (childMesh) {
                      // let pos = Object.assign({}, childMesh.position);
                      if (
                        childMesh.type.toLowerCase() === "mass" ||
                        childMesh.type.toLowerCase() === "void"
                      ) {
                        childMesh.setParent(createdMesh);
                      } else {
                        //doors and windows
                        childMesh.setParent(createdMesh);
                        // childMesh.parent = createdMesh;
                        // childMesh.position = new BABYLON.Vector3(pos.x, pos.y,pos.z);
                      }
                      createdMesh.childrenComp.push(childMesh);
                    } else {
                      createdMesh.childrenCompMissing.push(child);
                    }
                  });
                  if (createdMesh.childrenCompMissing.length > 0)
                    childMissingWalls.push(createdMesh);
                }
              }

              _assignMeshTypeBasedProperties(
                createdMesh,
                data["structures"][str][level][levelProp][obj]["dsProps"],
                levelObj,
                sourceMesh
              );
              // let object = createdMesh.getSnaptrudeDS();
              // let structure = StructureCollection.getInstance().getStructures()[object.structure_id];
              // let storeyVal = object.storey;

              // let storey = structure.getStoreyData().getStoreyByValue(storeyVal);
              // storey.elements.push(object);
              // levelObj.addMeshToLevel(createdMesh, false);
            } else {
              inst["mesh"] =
                data["structures"][str][level][levelProp][obj]["meshes"][mesh];
              inst["dsProps"] =
                data["structures"][str][level][levelProp][obj]["dsProps"];

              leftOvers.instances.push(inst);
            }
          } catch (e) {
            console.warn(e);
            console.warn("Creation failed for instance-");
            console.warn(data["structures"][str][level][levelProp][obj]);
          }
        }
      }
      if (childMissingWalls) {
        childMissingWalls.forEach(function (wall) {
          for (let i = 0; i < wall.childrenCompMissing.length; i++) {
            let childMesh = store.scene.getMeshByUniqueID(
              wall.childrenCompMissing[i]
            );
            if (childMesh) {
              // let pos = childMesh.position.clone();
              if (
                childMesh.type.toLowerCase() === "mass" ||
                childMesh.type.toLowerCase() === "void"
              ) {
                childMesh.setParent(wall);
              } else {
                //doors and windows
                childMesh.setParent(wall);
                // childMesh.parent = wall;
                // childMesh.position = new BABYLON.Vector3(pos.x, pos.y,pos.z);
              }
              wall.childrenComp.push(childMesh);
            }
          }
        });
      }
      if (data["activeLayer"]) {
        try {
          const activeLayer = StructureCollection.getInstance()
            .getStructures()
            [data["activeLayer"].structure_id].getStoreyData()
            .getStoreyByValue(data["activeLayer"].storey)
            .layerData.getLayerByName(
              data["activeLayer"].name,
              data["activeLayer"].storey
            );
          if (activeLayer) {
            store.activeLayer = activeLayer;
          } else {
            leftOvers.activeLayer = data["activeLayer"];
            store.activeLayer = StructureCollection.getInstance()
              .getStructures()
              [data["activeLayer"].structure_id].getStoreyData()
              .getStoreyByValue(1)
              .layerData.getLayerByName("Wall", 1);
          }
        } catch (err) {
          store.activeLayer = StructureCollection.getInstance()
            .getStructures()
            [data["activeLayer"].structure_id].getStoreyData()
            .getStoreyByValue(1)
            .layerData.getLayerByName(data["activeLayer"].name, 1);
        }
      }
      if (data["storeys"]) store.storeysDS = data["storeys"];

      store.userSettingsInStructure = data["userSettings"] || {};
      backwardCompatibilityChecker.userSettingsTypeValidator(
        store.userSettingsInStructure
      );
      _changeTextureDetail(store.userSettingsInStructure.textureDetail);

      store.projectProperties.initialize(store.userSettingsInStructure);
      if (store.userSettingsInStructure.lastSearchedLocationData) {
        if (
          store.userSettingsInStructure.lastSearchedLocationData
            .lastKnownCoordinates &&
          store.userSettingsInStructure.lastSearchedLocationData.lastKnownZoom
        ) {
          store.lastSearchedLocationData =
            store.userSettingsInStructure.lastSearchedLocationData;
        }
      }
      // autoInteriorStateGlobal = store.userSettingsInStructure.autoInteriorState;
      // onSolid();
    },
    loadGFSComponents: function (data) {
      function handleChildren(mesh, childrenComp) {
        mesh.childrenComp = [];
        childrenComp.forEach((child) => {
          const childMesh = store.scene.getMeshByUniqueID(child);
          if (childMesh) {
            childMesh.setParent(mesh);
            mesh.childrenComp.push(childMesh);
          }
        });
      }

      try {
        if (!data.structures) return;

        const structureJSON = data.structures[store.activeLayer.structure_id];
        if (!structureJSON) return;

        const structure = this.getStructureById(store.activeLayer.structure_id);

        for (let storeyId in structureJSON.storeys) {
          const storeyJSON = structureJSON.storeys[storeyId];
          const storey = structure.getStoreyData().getAllStoreys()[storeyId];
          if (storey) {
            let layers = storeyJSON.layers;
            layers = backwardCompatibilityChecker.layersChecker(layers);
            layers.forEach((layerData) => {
              if (_.isEmpty(layerData)) return;
              let layer = storey.layerData.getLayerBylayerId(layerData.id);
              if (!layer) {
                layer = storey.layerData.addLayer(
                  layerData.name,
                  layerData.structure_id,
                  layerData.layerType,
                  null,
                  { autoSave: false }
                );
                layer.id = layerData.id;
                layer.type = layerData.type;
                layer.storey = layerData.storey;
                layer.hidden = layerData.hidden;
                layer.terrain = layerData.terrain;
                layer.isLocked = layerData.isLocked;

                if (layer.name.includes("cad")) {
                  reduxStore.dispatch(
                    appendLayer({
                      id: layer.id,
                      title: layer.name,
                      storey: storey.value,
                      hidden: layer.hidden,
                      heightMapToggle: layer.heightMapToggle,
                      image: cadLayer,
                    })
                  );
                } else if (layer.name.includes("pdf")) {
                  reduxStore.dispatch(
                    appendLayer({
                      id: layer.id,
                      title: layer.name,
                      storey: layer.storey,
                      hidden: layer.hidden,
                      image: pdfIcon,
                    })
                  );
                } else if (layer.name.includes("terrain")) {
                  reduxStore.dispatch(
                    appendLayer({
                      id: layer.id,
                      title: layer.name,
                      storey: layer.storey,
                      hidden: layer.hidden,
                      heightMapToggle: layer.heightMapToggle,
                      image: topoLayer,
                    })
                  );
                }
              }
              if (layerData.layerType === "cad") {
                layerData.sketches.forEach(function (sketchPoints) {
                  if (layerData.layerType === "cad" && sketchPoints.edgeArray) {
                    const data = sketchPoints;
                    const babyArray = edgeArrayToBabylonVector(data.edgeArray);
                    const pointArrayV3 = data.pointArray
                      ? pointArrayToV3(data.pointArray)
                      : null;

                    const cadSystem = drawCADLineSystem(
                      babyArray,
                      pointArrayV3
                    );
                    cadSystem.layerName = layerData.name;
                    cadSystem.storey = layerData.storey;
                    if (data.position) {
                      cadSystem.position = new BABYLON.Vector3(
                        ...data.position
                      );
                    }
                    if (data.rotationQuaternion) {
                      cadSystem.rotationQuaternion = new BABYLON.Quaternion(
                        data.rotationQuaternion[0],
                        data.rotationQuaternion[1],
                        data.rotationQuaternion[2],
                        data.rotationQuaternion[3]
                      );
                    }

                    layer.sketches = [
                      {
                        edgeArray: data.edgeArray,
                        position: data.position,
                        mesh: cadSystem,
                      },
                    ];
                    layer.mesh = cadSystem;

                    snapEngine.cadSnaps.update(cadSystem);
                  }
                });
              }

              if (layerData.terrain) {
                layerData.terrain.forEach((saveData, index) => {
                  let data = saveData.parameters;
                  let terrain = BABYLON.MeshBuilder.CreateGround(
                    "terrain",
                    {
                      width: data.width,
                      height: data.height,
                      subdivisionsX: data.subdivisionsX,
                      subdivisionsY: data.subdivisionsY,
                      updatable: true,
                    },
                    store.scene
                  );

                  meshUniqueIdMapper.update(terrain, data.uniqueId);
                  layerData.terrain[index].parameters.layerId = layerData.id;

                  let mapData = terrain.getVerticesData(
                    BABYLON.VertexBuffer.PositionKind
                  );
                  if (data.subdivisionsX !== 0) {
                    for (let y = 0; y < data.imgHeight; y++) {
                      for (let x = 0; x < data.imgWidth; x++) {
                        let i = (y * data.imgWidth + x) * 4;
                        let h =
                          -10000 +
                          (data.imgPixels.data[i] * 256 * 256 +
                            data.imgPixels.data[i + 1] * 256 +
                            data.imgPixels.data[i + 2]) *
                            0.1; // this is always in meters.
                        h = DisplayOperation.getOriginalDimension(h, "meters");
                        mapData[(y * data.imgWidth + x) * 3 + 1] = h;
                      }
                    }
                    layerData.heightMapToggle = true; // If subdivisions exist, then height map should be toggled on, this is for the ui.
                  }

                  let terrainMaterial = new BABYLON.StandardMaterial(
                    "terrainMaterial",
                    store.scene
                  );
                  terrainMaterial.backFaceCulling = false;
                  terrainMaterial.diffuseTexture =
                    new BABYLON.Texture.CreateFromBase64String(
                      data.textURL,
                      "terrain" + makeid(3),
                      store.scene
                    );
                  terrain.updateVerticesData(
                    BABYLON.VertexBuffer.PositionKind,
                    mapData,
                    true
                  );
                  terrain.position = BABYLON.Vector3.FromArray(
                    data.terrainPosition
                  );
                  if (data.rotationQuaternion) {
                    terrain.rotationQuaternion = new BABYLON.Quaternion(
                      data.rotationQuaternion[0],
                      data.rotationQuaternion[1],
                      data.rotationQuaternion[2],
                      data.rotationQuaternion[3]
                    );
                  }
                  terrain.material = terrainMaterial;
                  if (data.mapCenter) {
                    terrain.mapCenter = data.mapCenter;
                    store.terrainMapData.center = data.mapCenter;
                  }
                  if (data.mapZoom) {
                    terrain.mapZoom = data.mapZoom;
                    store.terrainMapData.zoom = data.mapZoom;
                  }
                  if (data.mapBounds) {
                    store.terrainMapData.bounds = data.mapBounds;
                  }

                  terrain.structure_id = data.structure;
                  terrain.type = "Mass";
                  terrain.storey = data.storey;

                  let level;
                  level = structure.getLevel("0", "1");
                  if (!level) structure.addLevel("0", "1");
                  let levelID = "01";

                  const talkingAboutLevel = structure.getLevelByName(levelID);
                  const mass = new Mass(terrain);
                  talkingAboutLevel.addObjectToLevel(mass, false);

                  terrain.getSnaptrudeDS().massType = "Mass";
                  terrain.layerName = data.layerName;

    
                  if(layer.isLocked){
                    Locker.lockOnRecreate([mass]);
                  }

                  // parent for neighborhood building
                  terrain.isNeighborhoodEnabled = data.isNeighborhoodEnabled
                    ? data.isNeighborhoodEnabled
                    : false;
                  if (data.isNeighborhoodEnabled) {
                    let neighborhoodLayer = storey.layerData.getLayerBylayerId(
                      data.neighborhoodLayer
                    );
                    if (
                      neighborhoodLayer &&
                      neighborhoodLayer.neighborhood.parentID ===
                        terrain.uniqueId
                    ) {
                      store.neighborhoodBuildings[data.layerName] =
                        neighborhoodLayer.neighborhood.mesh;
                    }
                    let elevation = terrain._subdivisionsX === 1 ? false : true;
                    let buildings = store.neighborhoodBuildings[data.layerName];
                    buildings.forEach((building) => {
                      let mesh = store.scene.getMeshByUniqueID(building);
                      if (mesh) {
                        mesh.layerName = neighborhoodLayer?.name
                        mesh.setParent(terrain);
                        if (elevation) {
                          mesh.position.y =
                            mesh.getSnaptrudeDS().elevation + mesh.height / 2;
                        } else {
                          mesh.position.y = mesh.height / 2;
                        }
                      }
                    });
                  }
                });
              }

              if (layerData.pdfs) {
                layerData.pdfs.forEach((saveData) => {
                  let data = saveData;

                  let _pdfMesh = BABYLON.MeshBuilder.CreatePlane(
                    "pdfMesh",
                    { width: data.width, height: data.height },
                    store.scene
                  );

                  let pdfMat = new BABYLON.StandardMaterial(
                    "pdfMaterial",
                    store.scene
                  );
                  pdfMat.backFaceCulling = false;
                  pdfMat.diffuseTexture =
                    new BABYLON.Texture.CreateFromBase64String(
                      data.sourcePdfInBase64,
                      "pdfMat_" + makeid(3),
                      store.scene
                    );

                  _pdfMesh.material = pdfMat;
                  _pdfMesh.position = BABYLON.Vector3.FromArray(
                    data.pdfPosition
                  );
                  // _pdfMesh.rotation.x = Math.PI / 2;
                  if (data.rotationQuaternion) {
                    _pdfMesh.rotationQuaternion = BABYLON.Quaternion.FromArray(
                      data.rotationQuaternion
                    );
                  } else {
                    // _pdfMesh.rotationQuaternion = BABYLON.Quaternion.Identity();
                    _pdfMesh.rotation.x = Math.PI / 2;
                  }
                  _pdfMesh.layer_id = data.layerId;
                  _pdfMesh.storey = data.storey;
                  _pdfMesh.structure_id = data.structure_id;

                  let _pdfDS = new Pdf(_pdfMesh);
                  _pdfDS.type = "pdf";
                  _pdfDS.structure_id = data.structure_id;
                  _pdfDS.storey = data.storey;
                  _pdfDS.scaleFactor = data.scaleFactor;
                  _pdfDS.mesh.isVisible = true;
                  _pdfDS.width = data.width;
                  _pdfDS.height = data.height;
                  _pdfDS.sourcePdfInBase64 = data.sourcePdfInBase64;
                  _pdfDS.layer_id = data.layerId;

                  layer.addPdf(_pdfDS);
                });
              }
            });
          }
        }

        const levelJSON = structureJSON["01"];
        if (levelJSON) {
          const level = structure.getLevelByName("01");

          if (levelJSON.furnitures) {
            for (const id in levelJSON.furnitures) {
              const furnitureData = levelJSON.furnitures[id];
              if (furnitureData.geometries) {
                const geom = getGeometryById(
                  furnitureData.geometries.vertexData,
                  furnitureData.meshes[0].geometryId
                );

                const createdMesh = store.scene.recreateMesh(
                  furnitureData.meshes[0],
                  geom,
                  furnitureData.dsProps.instancesLength,
                  furnitureData.dsProps.keepSourceMesh
                );

                const uid = furnitureData.dsProps.uniqueID;
                if (!store.scene.getMeshByUniqueID(uid)) {
                  meshUniqueIdMapper.update(createdMesh, uid);
                }

                createdMesh.assignAdditionalProps(
                  furnitureData.meshes[0],
                  createdMesh
                );
                const childrenComp = furnitureData.meshes[0].childrenComp;
                if (childrenComp) {
                  handleChildren(createdMesh, childrenComp);
                }

                _assignMeshTypeBasedProperties(
                  createdMesh,
                  furnitureData.dsProps,
                  level
                );
              }
            }
          }
          for (const instance of leftOvers.instances) {
            if (!instance.dsProps.uniqueID) continue;
            const sourceMesh = store.scene.getMeshByUniqueID(
              instance.mesh.uniqueIdSource
            );
            if (sourceMesh) {
              const createdMesh = sourceMesh.createInstance(instance.mesh.name);
              createdMesh.position = BABYLON.Vector3.FromArray(
                instance.mesh.position
              );
              createdMesh.rotation = BABYLON.Vector3.FromArray(
                instance.mesh.rotation
              );
              createdMesh.scaling = BABYLON.Vector3.FromArray(
                instance.mesh.scaling
              );
              if (instance.mesh.rotationQuaternion) {
                createdMesh.rotationQuaternion = BABYLON.Quaternion.FromArray(
                  instance.mesh.rotationQuaternion
                );
              }
              if (_.isBoolean(instance.mesh.isVisible)) {
                createdMesh.isVisible = instance.mesh.isVisible;
              }
              if (_.isBoolean(instance.mesh.pickable)) {
                createdMesh.isPickable = instance.mesh.pickable;
              }

              meshUniqueIdMapper.update(createdMesh, instance.dsProps.uniqueID);
              createdMesh.assignAdditionalProps(instance.mesh, createdMesh);
              const childrenComp = instance.mesh.childrenComp;
              if (childrenComp) {
                handleChildren(createdMesh, childrenComp);
              }

              _assignMeshTypeBasedProperties(
                createdMesh,
                instance.dsProps,
                level
              );
            }
          }
        }

        if (leftOvers.activeLayer) {
          const activeLayer = structure
            .getStoreyData()
            .getStoreyByValue(leftOvers.activeLayer.storey)
            .layerData.getLayerByName(
              leftOvers.activeLayer.name,
              leftOvers.activeLayer.storey
            );

          store.activeLayer = activeLayer
            ? activeLayer
            : structure
                .getStoreyData()
                .getStoreyByValue(1)
                .layerData.getLayerByName("Wall", 1);
        }
      } catch (err) {
        console.log(err);
        throw new GFSLoadError(err.message, data);
      } finally {
        this.flushLeftOvers();
      }
    },
  };
}

/**
 * { structure collection datastructure }
 *
 * @class      StructureCollection (name)
 * @return     {(Function|Object|StructureList)}  { description_of_the_return_value }
 */
var StructureCollection = (function () {
  "use strict";
  var instance;

  function createInstance() {
    const object = new StructureList();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    reset: function reset() {
      if (instance) {
        instance.flushLevels();
      }
      instance = undefined;
    },
  };
})();

const _assignMeshTypeBasedProperties = function (
  createdMesh,
  dsProps,
  levelObj,
  sourceMesh
) {
  let type = createdMesh.type;
  var mass;
  if (createdMesh.type.toLowerCase().includes("wall")) {
    var wall = new Wall(createdMesh, dsProps["room_id"]);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    wall.originalWallMesh = dsProps["originalWallMesh"];
    wall.neighbours = dsProps["neighbours"];
    wall.neighboursDetails = dsProps["neighboursDetails"];
    wall.setTopCoords(dsProps["topCoords"]);
    wall.setBottomCoords(dsProps["bottomCoords"]);
    wall.setMidYHeight(dsProps["midY"]);
    wall.properties = dsProps["properties"];
    wall.localLineSegment = dsProps["localLineSegment"];
    wall.wThickness = dsProps["wThickness"];
    wall.revitMetaData = dsProps["revitMetaData"];
    // wall.createdByCB = dsProps["createdByCB"];
    // wall.wallType = dsProps["wallType"];
    // wall.wallMaterialType = dsProps["wallMaterialType"];

    // This causes issue while reloading.
    // updateDefaultMaterial -> setThickness -> DisplayOperation -> unit_tolerance(this has not been initialized at this stage)
    // wall.updateDefaultMaterial();

    if (dsProps.hasOwnProperty("typeChangeAllowed")) {
      // if wall was drawn using draw mass tool
      wall.typeChangeAllowed = dsProps["typeChangeAllowed"];
    } else {
      delete wall.typeChangeAllowed;
      // will be assigned an appropriate value in backwardCompatibility module
    }

    if (sourceMesh) {
      wall.brep = sourceMesh.getSnaptrudeDS().brep;
      wall.faceFacetMapping = sourceMesh.getSnaptrudeDS().faceFacetMapping;
    } else {
      let brep = dsProps["brep"];
      // const resurrect = new Resurrect({resolver: new Resurrect.NamespaceResolver(snapmda), cleanup: true, })
      // if (brep) wall.brep = resurrect.resurrect(brep);
      if (brep) wall.brep = store.resurrect.resurrect(brep);
      wall.faceFacetMapping = dsProps["faceFacetMapping"];
    }

    if(wall.revitMetaData?.containsCurtainWall){
      wall.mesh.containsCurtainWall = true;
    }

    wall.storey = createdMesh.storey;
    wall.height = createdMesh.height;
    levelObj.addWallToLevel(wall, false);
  } else if (createdMesh.type.toLowerCase().includes("floor")) {
    var floor = new Floor(createdMesh);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    floor.room_type = dsProps["room_type"];
    floor.properties = dsProps["properties"];
    floor.room_id = dsProps["room_id"];
    floor.storey = createdMesh.storey;
    floor.height = createdMesh.height;
    floor.revitMetaData = dsProps["revitMetaData"];

    if (sourceMesh) {
      floor.brep = sourceMesh.getSnaptrudeDS().brep;
      floor.faceFacetMapping = sourceMesh.getSnaptrudeDS().faceFacetMapping;
    } else {
      let brep = dsProps["brep"];
      // const resurrect = new Resurrect({resolver: new Resurrect.NamespaceResolver(snapmda), cleanup: true, })
      // if (brep) floor.brep = resurrect.resurrect(brep);
      if (brep) floor.brep = store.resurrect.resurrect(brep);
      floor.faceFacetMapping = dsProps["faceFacetMapping"];
    }
    levelObj.addFloorToLevel(floor, false);
  } else if (createdMesh.type.toLowerCase().includes("roof")) {
    var roof = new Roof(createdMesh);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    roof.storey = createdMesh.storey;
    roof.height = dsProps["height"];
    roof.baseHeight = dsProps["baseHeight"];
    roof.offset = dsProps["offset"];
    roof.roof_pol_bottom = dsProps["roof_pol_bottom"];
    roof.roof_pol_top = dsProps["roof_pol_top"];
    roof.roof_pol_offset_top = dsProps["roof_pol_offset_top"];
    roof.roof_pol_offset_bottom = dsProps["roof_pol_offset_bottom"];
    roof.room_id = dsProps["room_id"];
    roof.slabType = dsProps["slabType"];
    roof.overhangDisabled = dsProps["overhangDisabled"];
    roof.revitMetaData = dsProps["revitMetaData"];
    // roof.createdByCB = dsProps["createdByCB"];

    if (dsProps.hasOwnProperty("typeChangeAllowed")) {
      roof.typeChangeAllowed = dsProps["typeChangeAllowed"];
    } else {
      delete roof.typeChangeAllowed;
      // will be assigned an appropriate value in backwardCompatibility module
    }

    if (sourceMesh) {
      roof.brep = sourceMesh.getSnaptrudeDS().brep;
      roof.faceFacetMapping = sourceMesh.getSnaptrudeDS().faceFacetMapping;
    } else {
      let brep = dsProps["brep"];
      // const resurrect = new Resurrect({resolver: new Resurrect.NamespaceResolver(snapmda), cleanup: true, })
      // if (brep) roof.brep = resurrect.resurrect(brep);
      if (brep) roof.brep = store.resurrect.resurrect(brep);
      roof.faceFacetMapping = dsProps["faceFacetMapping"];
    }
    roof.properties = dsProps["properties"];
    levelObj.addRoofToLevel(roof, false);
  } else if (createdMesh.type.toLowerCase().includes("void")) {
    mass = new Mass(createdMesh);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    levelObj.addMassToLevel(mass, false);
    createdMesh.type = "Void";
    mass.massType = "Mass";

    if (dsProps.hasOwnProperty("typeChangeAllowed")) {
      mass.typeChangeAllowed = dsProps["typeChangeAllowed"];
    } else {
      delete mass.typeChangeAllowed;
      // will be assigned an appropriate value in backwardCompatibility module
    }

    setMeshVisibility(createdMesh, 0.01);
  } else if (createdMesh.type.toLowerCase().includes("mass")) {
    //to include throwaway
    mass = new Mass(createdMesh);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level

    if (sourceMesh) {
      mass.brep = sourceMesh.getSnaptrudeDS().brep;
      mass.faceFacetMapping = sourceMesh.getSnaptrudeDS().faceFacetMapping;
    } else {
      let brep = dsProps["brep"];
      // const resurrect = new Resurrect({resolver: new Resurrect.NamespaceResolver(snapmda), cleanup: true, })
      // if (brep) mass.brep = resurrect.resurrect(brep);
      if (brep) mass.brep = store.resurrect.resurrect(brep);
      mass.faceFacetMapping = dsProps["faceFacetMapping"];
    }

    mass.setIsCircularMass(dsProps["_isCircularMass"]);
    mass.room_curve = dsProps["room_curve"];
    mass.room_type = dsProps["room_type"];
    mass.massType = dsProps["massType"];
    mass.properties = dsProps["properties"];
    mass.geomRepMapHalfEdgesEdges = dsProps["geomRepMapHalfEdgesEdges"];
    mass.geomRepRevMapEdgesHalfEdges = dsProps["geomRepRevMapEdgesHalfEdges"];
    mass.dependantMass = dsProps["dependantMass"];
    mass.createBuildingDone = dsProps["createBuildingDone"];
    mass.heightFromFloor = dsProps["heightFromFloor"];
    mass.revitMetaData = dsProps["revitMetaData"];
    mass.storey = createdMesh.storey;
    mass.height = createdMesh.height;

    if (mass.massType.toLowerCase() === "building") {
      mass.elevation = dsProps["elevation"];
    }

    if (dsProps.hasOwnProperty("typeChangeAllowed")) {
      mass.typeChangeAllowed = dsProps["typeChangeAllowed"];
    } else {
      delete mass.typeChangeAllowed;
      // will be assigned an appropriate value in backwardCompatibility module
    }

    levelObj.addMassToLevel(mass, false);
  } else if (createdMesh.type.toLowerCase().includes("furniture")) {
    var furniture = new Furniture(createdMesh);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    furniture.snapTo = dsProps["snapTo"];
    furniture.cutHole = dsProps["cutHole"];
    furniture.importType = dsProps["importType"];
    furniture.autoInterior = dsProps["autoInterior"];
    furniture.storey = createdMesh.storey;
    furniture.height = createdMesh.height;
    furniture.revitMetaData = dsProps["revitMetaData"];
    if (!furniture.mesh.isAnInstance) furniture.mesh.convertToFlatShadedMesh();
    if(dsProps['breed'] == 'team'){
      furniture.addTeamMetaData(dsProps['teamId'], dsProps['elementId'], dsProps['cost'], dsProps['familyName']);
    }
    levelObj.addFurnitureToLevel(furniture, false);
    // if(isFurnitureSetThrowAway(furniture)){
    //   furniture.handleRevitFurnitureSetOnReload(furniture.revitMetaData.offset)
    // }
    // onSolid(furniture.mesh, false, { enableFurnitureEdgesOnce: true });
  } else if (createdMesh.type.toLowerCase().includes("staircase")) {
    let staircase = new Staircase(
      createdMesh,
      dsProps.staircaseType,
      dsProps.staircaseHeight
    );
    // Seems redundant, so commented it
    // createdMesh.snaptrudeFunctions().copyVertexDataToMesh(createdMesh);
    staircase.assignBackObjectProperties(dsProps, sourceMesh);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    levelObj.addStaircaseToLevel(staircase, false);
  } else {
    removeMeshThrowAwayIdentifier(createdMesh);
    levelObj.addMeshToLevel(createdMesh, false);
    createdMesh.type = type; //throwAway is overwritten when mesh is added to level
    createdMesh.getSnaptrudeDS().storey = createdMesh.storey;
    createdMesh.getSnaptrudeDS().height = createdMesh.height;

    if (["door", "window"].includes(type.toLowerCase())) {
      onSolid(createdMesh);
      let meshDS = createdMesh.getSnaptrudeDS();
      meshDS.revitMetaData = dsProps["revitMetaData"];
    }
    if (
      type.toLowerCase().includes("door") ||
      type.toLowerCase().includes("window")
    ) {
      let meshDS = createdMesh.getSnaptrudeDS();
      meshDS.symbolData = dsProps["symbolData"];
    }
  }

  const createdComponent = createdMesh.getSnaptrudeDS();

  if (dsProps["linkedListId"])
    createdComponent.linkedListId = dsProps["linkedListId"];
  if (dsProps["groupId"]) createdComponent.groupId = dsProps["groupId"];
  createdComponent.far_included = dsProps["far_included"];
  if (dsProps["subType"]) createdComponent.subType = dsProps["subType"];
  if (dsProps["id"]) createdComponent.id = dsProps["id"];
  if (dsProps["edited"]) createdComponent.markAsEdited();
  if (dsProps["isLocked"]) createdComponent.isLocked = dsProps["isLocked"];

  backwardCompatibilityChecker.typeChangeAllowedPropertyPopulator.handleComponent(
    createdComponent
  );

  if(createdComponent.isLocked){
    Locker.lockOnRecreate([createdComponent]);
  }

  if (!createdMesh.isAnInstance)
    createdMesh.BrepToMesh(null, {
      doNotUpdateSubMeshes: createdMesh.subMeshes.length === 1,
    });
  virtualSketcher.addWithoutGeometryEdit(createdComponent);
};

export { StructureList, StructureCollection };

