import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logs: []
}

const changelogSlice = createSlice({
    name: 'changelogs',
    initialState,
    reducers: {
        resetChangelogs: () => initialState,
        initChangelogs: (state, action) => {
            const {logs} = action.payload;
            state.logs = logs;
        },
        addIncomingChangelog: (state, action) => {
            const {log} = action.payload;
            const newLogs = [log, ...state.logs];
            state.logs = newLogs;
        }

    }
});

export const {
    resetChangelogs,
    initChangelogs,
    addIncomingChangelog,
} = changelogSlice.actions;
export default changelogSlice.reducer;