import _ from "lodash";
import BABYLON from "../babylonDS.module";
import {FloorPlan} from "./floorplan.ds";

class Pdf{

  constructor(mesh) {
    this.type = null;
    this.mesh = mesh;
    this.storey = !mesh.storey ? 1 : mesh.storey;
    this.scaleFactor = null;
    this.level_low = 0;
    this.level_high = 1;
    this.id = "pdf_" + Math.random().toString(36).substr(2, 9);
    this.type = "pdf";
    this.mesh.type = "pdf";
    this.mesh.name = "pdfMesh";
    this.height = null;
    this.width = null;
    this.structure_id = null;
    this.layer_id = null;
    this.sourcePdfInBase64 = null;
    this.isLocked = false;
    this.revitMetaData = {};
  }

  /**
   * Activate Pdf
   */
  activate() {
    this.mesh.isVisible = true;
  };

  /**
   * Deactivate Pdf
   */
  deactivate() {
    this.mesh.isVisible = false;
  }

  // Added this for now, so that save doesn't break.
  setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  markAsEdited() {
    if (this.mesh.isAnInstance)
      this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
    else this.edited = true;
  }

  removeAsEdited() {
    if (this.mesh.isAnInstance)
      this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
    else this.edited = false;
  }

  isEdited() {
    if (this.mesh.isAnInstance)
      return this.mesh.sourceMesh.getSnaptrudeDS().edited;
    else return this.edited;
  }


  serialize() {
    let serializedData = {};

    let entries = Object.entries(this);
    entries.forEach(function (entry) {
      if (!_.isFunction(entry[1]) && entry[0] !== "mesh") {
        serializedData[entry[0]] = entry[1];
      }
    });
    serializedData.mesh = BABYLON.SceneSerializer.SerializeMesh(this.mesh);
    delete serializedData.mesh.materials;

    return serializedData;
  };

}

export { Pdf }