import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import styled, { css } from "styled-components";
import MaterialImage from "../../assets/images/Mask Group.png";
import { colors } from "../../themes/constant";
import { handleGetResourceOperations } from "../../services/resources.service";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { intSelect, onMatMode, removeMouseEvents } from "../../snaptrude/libs/toolbarEvents";
import { useDispatch, useSelector } from "react-redux";
import LibraryTooltip from "../Tooltip/LibraryTooltip";
import Input from "../Input";
import { generateNameFromImgPath } from "../../containers/editor/components/rightmenuPopUps/helper";
import { libraryService } from "../../snaptrude/modules/utilityFunctions/libraryService";
import searchIcon from "../../assets/icons/search.svg";
import { StoreyLayerRow } from "../StoreyLayerRow";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import authService from "../../services/auth.service";
import { getElementByIdAsync } from "./helper";
import { debounce } from "lodash";

const Container = styled.div`
  display: flex;
  overflow-y: auto;
  width: 100%;
  flex: 1;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background: ${({bg}) => bg};
  display: flex;
  flex-direction: column;
  flex: ${({flex}) => flex};
  box-shadow: ${({boxShadow}) => boxShadow};
  border: ${({border}) => border};
  transform: ${({transform}) => transform};
  align-items: center;

  .add {
    width: 0.9375em;
    height: 0.9375em;
    padding-left: 0.9375em;
    margin-top: 0.8125em;
    cursor: pointer;
  }

  .add-material {
    display: none;
  }
`;

const ScrollContainer = styled.ul`
  overflow-y: auto;
  list-style: none;
  padding: 0.5em;
  width: 90%;
  display: flex;
  flex-wrap: wrap;

  .selected {
    border-bottom: 2px solid ${colors.borderRed};
  }

  .m-selected {
    border-width: 0.0625em;
    border-style: solid;
    border-color: ${colors.borderRed};
    margin: -0.0625em;
    &:hover {
      cursor: pointer;
    }
  }

  .m-outer {
    border-radius: 0.3125em;
    &:hover {
      background: #ddd;
      cursor: pointer;
    }
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    ${({grid}) =>
  !grid &&
  css`
        &:hover {
          background: ${colors.white};
          border-radius: 0.3125em;
          cursor: pointer;
        }
      `};
  }

  li > p {
    margin-left: 1em;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
  .tooltip {
    height: 1.125rem;
    width: 5.3125rem;
    padding: 0.0625rem 0.625rem;
    &::after {
      display: none;
    }
  }
`;

const MaterialItem = styled.div`
  background-image: ${({imageUrl}) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-position: center; 
  background-size: ${({maintainAspectRatio}) => maintainAspectRatio ? "contain" : "cover"};
  width: 3.5em;
  height: 3.5em;
  margin: 0.125em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SearchWrapper = styled.form`
  width: 80%;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  position: relative;
  img{
    height: 1.25em;
    position: absolute;
    left: 0.5em;
  }
  input{
    width: 100%;
    height: 2em;
    padding-left: 2.5em;
  }
`;

const LibraryItemsContainer = styled.div`
  flex: 1;
  max-height: 16em;
  overflow-y: auto;
  width: 90%;
  .items{
    display: flex;
    flex-wrap: wrap;
    padding: 0.4em;
  }
  .items div{
    margin: 0.1em;
  }
  .m-selected {
    border-width: 0.0625em;
    border-style: solid;
    border-color: ${colors.borderRed};
    margin: -0.0625em;
    &:hover {
      cursor: pointer;
    }
  }

  .m-outer {
    border-radius: 0.3125em;
    &:hover {
      background: #ddd;
      cursor: pointer;
    }
  }
`

const ImgCadTopo = ["image", "autocad", "topography", "pdf"];
const LayerTitleToFilterSubType = {
  "Image": "image",
  "cad": "autocad",
  "terrain": "topography",
  "buildings": "topography",
  "pdf": "pdf"
}
let previousSelected = {}
let currentFilterSubType = null;

const ScrollViewCommon = forwardRef((props, ref) => {

  const {
    filterType,
    filterSubType,
    selectedThumbnail,
    setSelectedThumbnail,
    setSelectedSubMenu,
    thumbnailImageItems,
    setThumbnailImageItems,
    resetSelectedTopSubMenu,
    maintainAspectRatio,
    isOpen,
    selectedTopMenu,
    activateEyeDropper,
    importedObjects
  } = props;
  const scrollContainer = useRef(null);
  const [searchString, setSearchString] = useState("");
  const [searchTeamFurniture, setSearchTeamFurniture] = useState("");
  const [originalThumbnailImageItems, setOriginalThumbnailImageItems] = useState([]);
  const [isImgCadTopo, setIsImgCadTopo] = useState(false);
  const {storeyArrayRedux, projectMetadata, teamlist} = useSelector(state => {
    return {
      storeyArrayRedux: state.storeys.items,
      projectMetadata: state.projectMetadata,
      teamlist: state.teams.teamlist
    }
  });
  const thumbnailRef = useRef([]);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [filterSubtypeCounts, setFilterSubtypeCounts] = useState({});

  const handleKeyB = useCallback((evt) => {
    const {code, ctrlKey, altKey, shiftKey} = evt;
    if(code === "KeyB"){
      const selectedImg = store.$scope.applyMaterialProperties.imgURL;
      if(!selectedImg){
        evt.stopPropagation();
        if(previousSelected["material"] && currentFilterSubType && previousSelected["material"][currentFilterSubType]){
          const {item, index} = previousSelected["material"][currentFilterSubType];
          handleThumbnailItemClick(item, index);
        }
      }
    }
    if(code === "KeyI"){
      evt.stopPropagation();
      
      setSelectedThumbnail({
        imgURL: null,
        type: null,
        name: null
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;

      const eyeDropperTool = document.getElementById("eyeDropperForMaterial");
      if(eyeDropperTool){
        const className = eyeDropperTool.childNodes[0].className;
        if(className === "selected-top-submenu-icon") return;
        eyeDropperTool.click();
      } 
    }
  }, []) 
  useEffect(() => {
    window.addEventListener("keydown", handleEscapeEvent);
    return () => {
      window.removeEventListener("keydown", handleEscapeEvent);
    }
  }, []);
  
  useEffect(() => {
    if(filterType === "material"){
      if(isOpen) {
        document.addEventListener("keydown", handleKeyB);
      } else {
        document.removeEventListener("keydown", handleKeyB);
      }
    }else{
      document.removeEventListener("keydown", handleKeyB);
    }
  }, [isOpen, filterType]);

  useEffect(() => {
    if (filterType === "userImportedFile") {
      if (ImgCadTopo.includes(filterSubType)) {
        setIsImgCadTopo(true);
        return;
      }
    }
    setIsImgCadTopo(false);
    
  }, [filterSubType])
  
  useEffect(() => {
    if(isOpen){
      const user = authService.getCurrentUser();
      if(!user.email) return;
      if(ImgCadTopo.includes(filterSubType)) return;
      currentFilterSubType = filterSubType;
      const isEyeDropperSelection = store.isEyeDropperSelection;
      if(filterType === "material" && isEyeDropperSelection){
        store.isEyeDropperSelection = false;
      }
      const options = {
        resourceName: filterType === "material" ? "materials" : "objects",
        floorkey: store.floorkey,
        filterType: filterType,
        filterSubType: filterSubType,
      }
      handleGetResourceOperations(options)
      .then(result => {
        setThumbnailImageItems(result);
        setOriginalThumbnailImageItems(result);
        setHasMore(true);
        if (result.length < 12) setHasMore(false);
        let count = filterSubtypeCounts[filterSubType] || 12;
        setItems([...result.slice(0, count)])
        const newFilterSubtypeCounts = filterSubtypeCounts;
        newFilterSubtypeCounts[filterSubType] = count;
        setFilterSubtypeCounts(newFilterSubtypeCounts);
      })
      async function waitForDOMtoLoadAndSelect(){
        if(filterType && filterSubType) {
          let index = 0;
          if(previousSelected[filterType] && previousSelected[filterType][filterSubType]){
            index = previousSelected[filterType][filterSubType].index || 0;
          }
          const libraryItem = await getElementByIdAsync(`${filterType}-${filterSubType}-list-item-${index}`);
          if(!libraryItem) return;
          if(index === 0){
            document.querySelector(`#${filterType}-${filterSubType}-container`).scrollTop = 0;
          }else{
            libraryItem.scrollIntoView({behavior: "smooth"})
          }
          const imageurl = libraryItem.getAttribute("data-imageurl");
          if(selectedThumbnail.imgURL !== imageurl)libraryItem.click();
        }
      }
      if(activateEyeDropper){
        const eyeDropperTool = document.getElementById("eyeDropperForMaterial");
        if(eyeDropperTool) eyeDropperTool.click();
        return;
      } 
      if(!isEyeDropperSelection) waitForDOMtoLoadAndSelect();
  } else{
      setSelectedThumbnail({
        imgURL: null,
        type: null,
        name: null
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;

    }
  }, [isOpen, filterSubType, activateEyeDropper]);

  const dispatch = useDispatch();
  const addCopiedMaterialsToList = useCallback((evt) => {
    const {newMaterials, resourceName} = evt.detail;
    if(filterType === resourceName) setItems([...items, ...newMaterials]);
  }, [items]);

  useEffect(() => {
    window.addEventListener("material-copied", addCopiedMaterialsToList);
    return () => {
      window.removeEventListener("material-copied", addCopiedMaterialsToList);
    }
  }, [addCopiedMaterialsToList]);

  useEffect(() => {
    if(filterType === "material"){
      if(isOpen && selectedTopMenu){
      
        setSelectedThumbnail({
          imgURL: null,
          type: null,
          name: null
        });
        store.$scope.applyMaterialProperties.imgURL = null;
        store.$scope.applyMaterialProperties.type = null;
      }
    }
  }, [isOpen, filterType, selectedTopMenu]);

  useEffect(() => {
    if(importedObjects){
      setItems([...items, ...importedObjects])
    }
  }, [importedObjects]);

  const handleEscapeEvent = (evt) => {
    if (evt.key === "Escape") {
      // deselect the selected thumbnail
      setSelectedThumbnail({
        imgURL: null,
        type: null,
        name: null
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;

      const eyeDropperTool = document.querySelector("#eyeDropperForMaterial img");
      const removeMaterialTool = document.querySelector("#removeMaterial img");
      eyeDropperTool.classList.remove("selected-top-submenu-icon");
      eyeDropperTool.classList.add("top-submenu-icon");
      removeMaterialTool.classList.remove("selected-top-submenu-icon");
      removeMaterialTool.classList.add("top-submenu-icon");

      // removeMouseEvents();

      // reset the selected tool
      // resetSelectedTopSubMenu();
    }
  }

  const handleThumbnailItemClick = (item, index) => {
    const {img, type, subType, fullName, babylon, teamId, elementId, cost, familyName} = item;
    if(filterType && filterSubType){
      previousSelected[filterType] = {
        ...previousSelected[filterType],
        [filterSubType]: {
          item,
          index
        }
      }
    }
    let prop, tabs;

    if (selectedThumbnail.imgURL === img) {
      // clicking on the already selected thumbnail
      setSelectedThumbnail({
        imgURL: null,
        type: null,
        name: null
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;
      store.$scope.applyMaterialProperties.name = null;

      removeMouseEvents();

    } else {
      setSelectedSubMenu("");
      setSelectedThumbnail({
        imgURL: img,
        type: subType,
        name: fullName
      });

      switch (filterType) {
        case "material":
          store.$scope.applyMaterialProperties.imgURL = img;
          store.$scope.applyMaterialProperties.type = subType;
          store.$scope.applyMaterialProperties.name = fullName
          onMatMode();
          break;
        case "furniture":
          prop = {
            propurl: babylon,
            subType: ""
          }
          tabs = {
            title: subType
          }
          if(teamId){
            prop['teamId'] = teamId;
            prop['elementId'] = elementId;
            prop['cost'] = cost;
            prop['familyName'] = familyName;
          }
          intSelect(prop, tabs);
          break;
        case "userImportedFile":
          prop = {
            propurl: babylon,
            subType: ""
          }
          tabs = {
            title: subType
          }
          intSelect(prop, tabs);
          break;
      }
    }
  }

  const scrollToView = async ({imgURL, type, thumbnailItems}) => {
    store.isEyeDropperSelection = false;
    type = type.toLowerCase()
    const idx = thumbnailItems.findIndex(item => item.img === imgURL && item.subType === type.toLowerCase());
    if (idx !== -1) {
      const currentCounts = filterSubtypeCounts[filterSubType];
      if(filterType && filterSubType){
        previousSelected[filterType] = {
          ...previousSelected[filterType],
          [type]: {
            item: thumbnailItems[idx],
            index: idx
          }
        }
      }
      if(currentCounts <= idx){
        const newItems = thumbnailItems.slice(0, idx+12);
        setItems(newItems);
        const newFilterSubtypeCounts = filterSubtypeCounts;
        newFilterSubtypeCounts[filterSubType] = newItems.length;
        setFilterSubtypeCounts(newFilterSubtypeCounts);
      }
      const libraryItem = await getElementByIdAsync(`${filterType}-${filterSubType}-list-item-${idx}`);
      libraryItem.scrollIntoView({behavior: "smooth"});
    }
  }
 
  

  useImperativeHandle(ref, () => ({scrollToView}), []);

  const fetchMoreData = () => {
    if (items.length >= thumbnailImageItems.length) {
      setHasMore(false);
      return;
    }
    
    const newItems = [...items, ...thumbnailImageItems.slice(items.length - 1, items.length + 11)];
    const newFilterSubtypeCounts = filterSubtypeCounts;
    newFilterSubtypeCounts[filterSubType] = newItems.length;
    setFilterSubtypeCounts(newFilterSubtypeCounts);
    setItems(newItems);
  };

  const getNameForItem = (item) => {
    if (item.fullName) return item.fullName;
    return generateNameFromImgPath(item.img);
  }

  const handleSearch = (evt, value="") => {
    if(evt) evt.preventDefault();
    let queryString = searchString;
    if(value) queryString = value;
    if (queryString === "") {
      setThumbnailImageItems(originalThumbnailImageItems);
      setItems([]);
      setHasMore(true);
      setSearchTeamFurniture("");
      if (originalThumbnailImageItems.length <= 12) setHasMore(false);
      setItems([...originalThumbnailImageItems.slice(0, 12)])
      return;
    }
    setSearchTeamFurniture(queryString)
    const list = libraryService.getList(filterType);
    if (list) {
      const matchedList = list.search(queryString);
      setItems([]);
      setHasMore(true);
      if (matchedList.length <= 12) setHasMore(false);
      setThumbnailImageItems([...matchedList]);
      setItems([...matchedList.slice(0, 12)]);
    }
  }

  const debounceSearch = useCallback(
    debounce((value) => {
      handleSearch(null, value);
    }, 500),
    []
  )

  const handleSearchTextChange = (evt) => {
    const {value} = evt.target;
    setSearchString(value);
    debounceSearch(value);
  }

  let currentProjectTeam = null;
  if(projectMetadata.isTeamsProject){
    currentProjectTeam = teamlist.find(team => team.id === projectMetadata.team.id);
  }

  return (
    <>
      <Container>
        <Inner>
          <SearchWrapper onSubmit={handleSearch}>
            <img src={searchIcon} alt="Q"/>
            <Input type="text" placeholder="Search" value={searchString} onPress={handleSearchTextChange}/>
          </SearchWrapper>
          {
            isImgCadTopo ?
              <ScrollContainer ref={scrollContainer}>
                {
                  storeyArrayRedux.map(storey => {

                    return storey.layers.map(layer => {
                      // console.log(layer);
                      const title = layer.title.split("-")[0];
                      if (LayerTitleToFilterSubType[title] !== filterSubType) return <></>
                      return <StoreyLayerRow
                        item={layer}
                        isOpen={isOpen}
                      />
                    })
                  })
                }
              </ScrollContainer>
              :
                <LibraryItemsContainer id={`${filterType}-${filterSubType}-container`}>
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <TailSpin color={colors.brightRed} height={40} width={40}/>
                  </div>}
                  scrollableTarget={`${filterType}-${filterSubType}-container`}
                >
                  <div className="items" id={`${filterType}-${filterSubType}-list`}>
                    {
                      filterType === "material" && currentProjectTeam &&
                        currentProjectTeam.materials?.filter(teamMaterial => teamMaterial.type.toLowerCase().includes(filterSubType.toLowerCase())).map(teamMaterial => {
                          return {
                            img: teamMaterial.url,
                            type: "material",
                            subType: filterSubType.toLowerCase(),
                            fullName: teamMaterial.displayName
                          }
                        })
                        .map((item, index) => {
                          return <>
                            <div
                              key={index + "div"}
                              className={
                                selectedThumbnail
                                  ? selectedThumbnail.imgURL === item.img
                                    ? "m-selected"
                                    : "m-outer"
                                  : "m-outer"
                              }
                              ref={el => thumbnailRef.current[index] = el}
                              style={{padding: "0.0625rem", width: "fit-content"}}
                            >
                              <MaterialItem
                                data-tip
                                data-for={`team-material-${index}`}
                                data-imageurl={item.img}
                                key={index + "img"}
                                imageUrl={(item && item.img) || MaterialImage}
                                onClick={() => {
                                  handleThumbnailItemClick(item, index);
                                }}
                                maintainAspectRatio={maintainAspectRatio}
                                id={`${filterType}-${filterSubType}-list-item-${index}`}
                              />
                              <LibraryTooltip id={`team-material-${index}`}>{item.fullName}</LibraryTooltip>
                            </div>
                          </>
                        })
                    }
                    {
                      filterType === "furniture" && currentProjectTeam &&
                        currentProjectTeam.objects?.furnitures.filter(teamFurniture => {
                          if(!searchTeamFurniture) return teamFurniture.subType.toLowerCase().includes(filterSubType.toLowerCase());
                          return teamFurniture.displayName.toLowerCase().includes(searchTeamFurniture.toLowerCase())
                        }).map(teamFurniture => {
                          return {
                            zip: teamFurniture.zip,
                            type: teamFurniture.type,
                            subType: filterSubType,
                            babylon: teamFurniture.babylon,
                            img: teamFurniture.url,
                            fullName: teamFurniture.displayName,
                            teamId: teamFurniture.teamId,
                            elementId: teamFurniture.id,
                            cost: teamFurniture.cost,
                            familyName: teamFurniture.familyName
                          }
                        })
                        .map((item, index) => {
                          return <>
                            <div
                              key={index + "div"}
                              className={
                                selectedThumbnail
                                  ? selectedThumbnail.imgURL === item.img
                                    ? "m-selected"
                                    : "m-outer"
                                  : "m-outer"
                              }
                              ref={el => thumbnailRef.current[index] = el}
                              style={{padding: "0.0625rem", width: "fit-content"}}
                            >
                              <MaterialItem
                                data-tip
                                data-for={`team-furniture-${index}`}
                                data-imageurl={item.img}
                                key={index + "img"}
                                imageUrl={(item && item.img) || MaterialImage}
                                onClick={() => {
                                  handleThumbnailItemClick(item, index);
                                }}
                                maintainAspectRatio={maintainAspectRatio}
                                id={`${filterType}-${filterSubType}-list-item-${index}`}
                              />
                              <LibraryTooltip id={`team-furniture-${index}`}>{item.fullName}</LibraryTooltip>
                            </div>
                          </>
                        })
                    }
                  {items.map((item, index) => {
                    item.fullName = getNameForItem(item);
                    return <>
                      <div
                        key={index + "div"}
                        className={
                          selectedThumbnail
                            ? selectedThumbnail.imgURL === item.img
                              ? "m-selected"
                              : "m-outer"
                            : "m-outer"
                        }
                        ref={el => thumbnailRef.current[index] = el}
                        style={{padding: "0.0625rem", width: "fit-content"}}
                      >
                        <MaterialItem
                          data-tip
                          data-for={`${index}`}
                          data-imageurl={item.img}
                          key={index + "img"}
                          imageUrl={(item && item.img) || MaterialImage}
                          onClick={() => {
                            handleThumbnailItemClick(item, index);
                          }}
                          maintainAspectRatio={maintainAspectRatio}
                          id={`${filterType}-${filterSubType}-list-item-${index}`}
                        />
                        <LibraryTooltip id={`${index}`}>{item.fullName}</LibraryTooltip>
                      </div>
                    </>
                  })}
                  </div>
                </InfiniteScroll>
                </LibraryItemsContainer>
          }
        </Inner>
      </Container>
    </>
  );
})

export default ScrollViewCommon;
