import _ from "lodash";
import stackedWallCommandHelper from "./stackedWallCommandHelper";

const stackedWallHelper = (function (){
  
  const allStackedWallMeshIds = [];
  const meshCompanionMap = new Map();
  
  const _addToData = function (mesh, companion){
    allStackedWallMeshIds.push(mesh.uniqueId);
    meshCompanionMap.set(mesh, companion);
    
    allStackedWallMeshIds.push(companion.uniqueId);
    meshCompanionMap.set(companion, mesh);
  };
  
  const add = function (mesh, companion){
    _addToData(mesh, companion);
    
    return stackedWallCommandHelper.getCommand([[mesh, companion]]);
  };
  
  const _removeFromData = function (mesh, companion){
    _.remove(allStackedWallMeshIds, id => id === mesh.uniqueId);
    meshCompanionMap.delete(mesh);
    if (companion) {
      _.remove(allStackedWallMeshIds, id => id === companion.uniqueId);
      meshCompanionMap.delete(companion);
    }
    
  };
  
  const remove = function (mesh, companion) {
    _removeFromData(mesh, companion);
    
    return stackedWallCommandHelper.getCommand([], [[mesh, companion]]);
  };
  
  const update = function (oldMesh, newMesh){
    if (!isPartOfStackedWall(oldMesh)) return;
  
    const companion = meshCompanionMap.get(oldMesh);
    
    _removeFromData(oldMesh, companion);
    _addToData(newMesh, companion);
    
    return stackedWallCommandHelper.getCommand([[newMesh, companion]], [[oldMesh, companion]]);
    
  };
  
  const populate = function (walls){
    const toAddArray = [];
    
    const revitImportStackedWalls = walls.filter(w => w.revitMetaData && w.revitMetaData.isStackedWall);
    
    revitImportStackedWalls.forEach((wall1, i1) => {
      if (isPartOfStackedWall(wall1.mesh)) return;
      
      revitImportStackedWalls.some((wall2, i2) => {
        if (i2 <= i1) return;
        if (isPartOfStackedWall(wall2.mesh)) return;
        
        if (wall1.revitMetaData.stackedWallParentId){
          // top wall
          if (wall2.revitMetaData.stackedWallParentId) return;
          else if (wall2.revitMetaData.elementId === wall1.revitMetaData.stackedWallParentId){
            _addToData(wall1.mesh, wall2.mesh);
            toAddArray.push([wall1.mesh, wall2.mesh]);
            return true;
          }
        }
        else {
          // bottom wall
          if (!wall2.revitMetaData.stackedWallParentId) return;
          else if (wall1.revitMetaData.elementId === wall2.revitMetaData.stackedWallParentId){
            _addToData(wall1.mesh, wall2.mesh);
            toAddArray.push([wall1.mesh, wall2.mesh]);
            return true;
          }
        }
      });
    });
    
    return stackedWallCommandHelper.getCommand(toAddArray);
  };
  
  const getCompanionWall = function (mesh){
    return meshCompanionMap.get(mesh);
  };
  
  const isPartOfStackedWall = function (mesh){
    if (mesh.type.toLowerCase() !== "wall") return false;
    
    return allStackedWallMeshIds.includes(mesh.uniqueId);
  };
  
  const getData = function (){
    return {
      allStackedWallMeshIds,
      meshCompanionMap
    };
  }
  
  
  return {
    add,
    update,
    remove,
    populate,
    
    getCompanionWall,
    isPartOfStackedWall,
    getData,
    
    rawAccess : {
      addToData: _addToData,
      removeFromData: _removeFromData,
    }
  };
  
})();

export default stackedWallHelper;