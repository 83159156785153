import React, { useState } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import * as styles from "./styles";

import playButton from "../../assets/icons/playButton.svg";
import pauseIcon from "../../assets/icons/pauseIcon.png";

const Player = (props) => {
  const [playing, setPlaying] = useState(true);
  const { videoUrl, thumbnailUrl, width, height } = props;

  const onVideoStart = () => {
    screenfull.request(document.querySelector(`#p-${props.id}`));
  };

  return (
    <div
      style={{ height, width, position: "relative" }}
      onClick={() => {
        setPlaying((prevstate) => !prevstate);
      }}
    >
      <ReactPlayer
        url={videoUrl}
        id={`p-${props.id}`}
        style={styles.player.playerStyles}
        width="100%"
        height="100%"
        playing={playing}
        onPlay={onVideoStart}
        loop={true}
        playIcon={
          <button
            onClick={() => {
              setPlaying((prevstate) => !prevstate);
            }}
            style={styles.player.playerPlay}
          >
            <img width={20} height={11} src={playButton} alt=">" />
          </button>
        }
        light={thumbnailUrl}
        controls={true}
      />
      {!playing && (
        <div style={styles.player.pauseParent}>
          <button style={styles.player.pauseButton}>
            <img width={20} height={11} src={pauseIcon} alt=">" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Player;
