import _ from "lodash";
import { calculateEuclidianDistance } from "../../libs/polygon_dip_workers.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
var RelationshipEngine = (function () {
  let _intersectThres = 1;
  let _relationThres = 0.8;
  let _tempWalls = [];

  let _getOrientation = function (p1, p2, p3) {
    let val =
      (p2[1] - p1[1]) * (p3[0] - p2[0]) - (p2[0] - p1[0]) * (p3[1] - p2[1]);
    if (val == 0) return 0;
    return val > 0 ? 1 : 2;
  };

  let _onSegment = function (p1, p2, p3) {
    if (
      p2[0] <= Math.max(p1[0], p3[0]) &&
      p2[0] >= Math.min(p1[0], p3[0]) &&
      p2[1] <= Math.max(p1[1], p3[1]) &&
      p2[1] >= Math.min(p1[1], p3[1])
    )
      return true;
    return false;
  };

  let _pushInObject = function (
    pwall,
    cwall,
    key,
    end,
    cwallEndIndex,
    pwallEndIndex
  ) {
    if (!pwall.neighboursDetails.hasOwnProperty(key)) {
      pwall.neighboursDetails[key] = {};
    }
    if (!pwall.neighboursDetails[key].hasOwnProperty(cwall.id)) {
      pwall.neighboursDetails[key][cwall.id] = {};
    } else {
      pwall.neighboursDetails[key][cwall.id] = {};
    }
    pwall.neighboursDetails[key][cwall.id] = {
      wall_id: cwall.id,
      end: JSON.stringify(end),
      endIndex: cwallEndIndex,
      indices: [],
      orientation: 0,
    };

    const endS = JSON.stringify(end);
    if (!cwall.neighboursDetails.hasOwnProperty(endS)) {
      cwall.neighboursDetails[endS] = {};
    }
    if (!cwall.neighboursDetails[endS].hasOwnProperty(pwall.id)) {
      cwall.neighboursDetails[endS][pwall.id] = {};
    } else {
      cwall.neighboursDetails[endS][pwall.id] = {};
    }
    cwall.neighboursDetails[endS][pwall.id] = {
      wall_id: pwall.id,
      end: key,
      endIndex: pwallEndIndex,
      indices: [],
      orientation: 0,
    };
  };

  let _addNeighbourDetails = function (pwall, cwall, pwalLS, cwallLS) {
    var a, b, c, d;
    const minDis1 =
      (a = calculateEuclidianDistance(pwalLS[0], cwallLS[0])) <
      (b = calculateEuclidianDistance(pwalLS[0], cwallLS[1]))
        ? a
        : b;
    const minDis2 =
      (c = calculateEuclidianDistance(pwalLS[1], cwallLS[0])) <
      (d = calculateEuclidianDistance(pwalLS[1], cwallLS[1]))
        ? c
        : d;
    if (minDis1 < minDis2) {
      const key = JSON.stringify(pwalLS[0]);
      const end = a < b ? cwallLS[0] : cwallLS[1];
      const cwallEndIndex =
        calculateEuclidianDistance(end, cwall.bottomCoords[0]) <
        calculateEuclidianDistance(end, cwall.bottomCoords[2])
          ? [0, 1]
          : [2, 3];
      const pwallEndIndex =
        calculateEuclidianDistance(pwalLS[0], pwall.bottomCoords[0]) <
        calculateEuclidianDistance(pwalLS[0], pwall.bottomCoords[2])
          ? [0, 1]
          : [2, 3];
      _pushInObject(pwall, cwall, key, end, cwallEndIndex, pwallEndIndex);
      // console.log("**************");
      // console.log(key, end, cwall.bottomCoords, cwallEndIndex, pwallEndIndex);
    } else {
      const key = JSON.stringify(pwalLS[1]);
      const end = c < d ? cwallLS[0] : cwallLS[1];
      const cwallEndIndex =
        calculateEuclidianDistance(end, cwall.bottomCoords[0]) <
        calculateEuclidianDistance(end, cwall.bottomCoords[2])
          ? [0, 1]
          : [2, 3];
      const pwallEndIndex =
        calculateEuclidianDistance(pwalLS[1], pwall.bottomCoords[0]) <
        calculateEuclidianDistance(pwalLS[1], pwall.bottomCoords[2])
          ? [0, 1]
          : [2, 3];
      _pushInObject(pwall, cwall, key, end, cwallEndIndex, pwallEndIndex);
      // console.log("**************");
      // console.log(key, end, cwall.bottomCoords, cwallEndIndex, pwallEndIndex);
    }
    // cwall.neighboursDetails[end].push({'wall_id': pwall.id, 'end': key});
    // cwall.neighboursDetails[end] = _.uniqBy(cwall.neighboursDetails[end], 'wall_id');
  };

  var _assignOrientation = function (pwall, cwall) {
    return new Promise((resolve, reject) => {
      let pwallLS = pwall.getLineSegment();
      let cwallLS = cwall.getLineSegment();

      let _cwallLS = _.cloneDeep(cwallLS);
      _cwallLS = _lineIncrementor(_cwallLS[0], _cwallLS[1], -2);

      const orientation = _getOrientation(pwallLS[0], pwallLS[1], _cwallLS[0]);
      resolve(orientation);
    });
  };

  let _lineIncrementor = function (p1, p2, units) {
    if (Math.abs(p1[0] - p2[0]) > Math.abs(p1[1] - p2[1])) {
      if (p1[0] <= p2[0]) {
        p1[0] -= units;
        p2[0] += units;
      } else {
        p1[0] += units;
        p2[0] -= units;
      }
    } else {
      if (p1[1] <= p2[1]) {
        p1[1] -= units;
        p2[1] += units;
      } else {
        p1[1] += units;
        p2[1] -= units;
      }
    }
    return [p1, p2];
  };

  let _relationFinder = function (p1, p2, q1, q2) {
    p1 = p1.map((x) => _.round(x, _intersectThres));
    p2 = p2.map((x) => _.round(x, _intersectThres));
    q1 = q1.map((x) => _.round(x, _intersectThres));
    q2 = q2.map((x) => _.round(x, _intersectThres));

    //for rounding up further
    for (let j = 0; j < 2; j++) {
      if (p1[j] !== q1[j] && Math.abs(p1[j] - q1[j]) < 0.2) {
        p1[j] = q1[j];
      }
      if (p1[j] !== q2[j] && Math.abs(p1[j] - q2[j]) < 0.2) {
        p1[j] = q2[j];
      }
      if (p2[j] !== q1[j] && Math.abs(p2[j] - q1[j]) < 0.2) {
        p2[j] = q1[j];
      }
      if (p2[j] !== q2[j] && Math.abs(p2[j] - q2[j]) < 0.2) {
        p2[j] = q2[j];
      }
    }

    let o1 = _getOrientation(p1, p2, q1);
    let o2 = _getOrientation(p1, p2, q2);
    let o3 = _getOrientation(q1, q2, p1);
    let o4 = _getOrientation(q1, q2, p2);

    if (o1 != o2 && o3 != o4) return { value: true, type: "non_collinear" }; //TODO modify this in future

    // Special Cases
    // p1, q1 and p2 are colinear and p2 lies on segment p1q1
    if (o1 === 0 && _onSegment(p1, q1, p2))
      return { value: true, type: "collinear" };

    // p1, q1 and q2 are colinear and q2 lies on segment p1q1
    if (o2 === 0 && _onSegment(p1, q2, p2))
      return { value: true, type: "collinear" };

    // p2, q2 and p1 are colinear and p1 lies on segment p2q2
    if (o3 === 0 && _onSegment(q1, p1, q2))
      return { value: true, type: "collinear" };

    // p2, q2 and q1 are colinear and q1 lies on segment p2q2
    if (o4 === 0 && _onSegment(q1, p2, q2))
      return { value: true, type: "collinear" };

    return { value: false, type: "none" }; // Doesn't fall in any of the above cases
  };

  var _init = function (walls = null, reEstablish = false) {
    return new Promise((resolve, reject) => {
      var structureCollection = StructureCollection.getInstance();
      var structures = structureCollection.getStructures();
      try {
        for (let structure in structures) {
          let levels = structures[structure].getAllLevels();

          for (let id in levels) {
            let level = levels[id];
            let currentWalls = reEstablish ? walls : level.getWalls();
            let length = reEstablish
              ? walls.length - 1
              : currentWalls.length - 1;
            for (let i = 0; i < length; i++) {
              let pwall;
              if (reEstablish) {
                pwall = walls[i];
              } else {
                pwall = currentWalls[i];
              }
              if (!pwall) continue;
              let pwalLS = pwall.getLineSegment(true);
              for (let j = i + 1; j < currentWalls.length; j++) {
                let cwall = currentWalls[j];
                if (cwall.id === pwall.id) continue;
                let cwallLS = cwall.getLineSegment(true);
                if (!pwalLS || !cwallLS) continue;
                let intersectObj = _relationFinder(
                  pwalLS[0],
                  pwalLS[1],
                  cwallLS[0],
                  cwallLS[1]
                );
                if (!intersectObj.value) {
                  let _pwalLS = _.cloneDeep(pwalLS);
                  let _cwallLS = _.cloneDeep(cwallLS);
                  _pwalLS = _lineIncrementor(
                    _pwalLS[0],
                    _pwalLS[1],
                    _relationThres
                  );
                  _cwallLS = _lineIncrementor(
                    _cwallLS[0],
                    _cwallLS[1],
                    _relationThres
                  );

                  intersectObj = _relationFinder(
                    _pwalLS[0],
                    _pwalLS[1],
                    _cwallLS[0],
                    _cwallLS[1]
                  );
                  if (intersectObj.value) {
                    if (
                      _.findIndex(pwall.neighbours, (o) => {
                        return o.wall_id === cwall.id;
                      }) === -1
                    ) {
                      pwall.neighbours.push({
                        wall_id: cwall.id,
                        type: intersectObj.type,
                      });
                      _addNeighbourDetails(pwall, cwall, pwalLS, cwallLS);
                    }
                    if (
                      _.findIndex(cwall.neighbours, (o) => {
                        return o.wall_id === pwall.id;
                      }) === -1
                    ) {
                      cwall.neighbours.push({
                        wall_id: pwall.id,
                        type: intersectObj.type,
                      });
                      _addNeighbourDetails(pwall, cwall, pwalLS, cwallLS);
                    }
                  }
                } else {
                  if (
                    _.findIndex(pwall.neighbours, (o) => {
                      return o.wall_id === cwall.id;
                    }) === -1
                  ) {
                    pwall.neighbours.push({
                      wall_id: cwall.id,
                      type: intersectObj.type,
                    });
                    _addNeighbourDetails(pwall, cwall, pwalLS, cwallLS);
                  }
                  if (
                    _.findIndex(cwall.neighbours, (o) => {
                      return o.wall_id === pwall.id;
                    }) === -1
                  ) {
                    cwall.neighbours.push({
                      wall_id: pwall.id,
                      type: intersectObj.type,
                    });
                    _addNeighbourDetails(pwall, cwall, pwalLS, cwallLS);
                  }
                }
              }
            }

            if (reEstablish) {
              return resolve("**********Relationship Re-Established");
            }
          }
        }
        return resolve("**********Relationship Established");
      } catch (e) {
        console.error(e);
        return reject(e);
      }
    });
  };

  var _reEstablishRelation = async function (walls = null) {
    if (!walls.length) {
      walls = [walls];
    }
    await _init(walls, true);

    let parent_wall = walls.splice(0, 1)[0];

    let sc = StructureCollection.getInstance();
    sc = sc.getStructureById(parent_wall.structure_id);

    parent_wall.neighbours.forEach((neighbour) => {
      let wall = sc.getWallByAssignedId(neighbour.wall_id);
      parent_wall.utils.checkForExtention(parent_wall, wall);
    });

    walls.length = 0;
  };

  return {
    init: _init,
    lineIncrementor: _lineIncrementor,
    assignOrientation: _assignOrientation,
    tempWalls: _tempWalls,
    reEstablishRelation: _reEstablishRelation,
    relationFinder: _relationFinder,
  };
})();
export { RelationshipEngine };
