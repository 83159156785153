import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { updateLevelsAngularUI } from "../modules/extrafunc.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { updateModifications } from "./sceneStateFuncs.js";
var scaleMeshDir = null;
var scaleMeshStartingPosition = null;
var scaleMesh = null;
var scaleMeshThresh = 30;
var scaleBox = null;
var _startPoint = null;
var st = null;
var _diff = null;
var onScaleMeshDown = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("ground1") === -1 &&
        mesh.name.indexOf("backwall") === -1 &&
        mesh.visibility > 0
      );
    }
  );
  var box = store.scene.getMeshByName("scaleMeshBox");
  // box.material.alpha = 1;
  // box.visibilty = 1;
  if (scaleMeshDir) {
    scaleMeshDir = null;
    DisplayOperation.removeDimensions();
    removeScaleMeshAxis();
    drawScaleMeshAxis(scaleMesh);
    if (store.scene.getMeshByName("scaleWall"))
      store.scene.getMeshByName("scaleWall").dispose();

    // rescaleScaleMeshAxis();
    // onSolid();
    try {
      if (box) {
        let _moveObject = box.parentMesh.getSnaptrudeDS();
        let newLevels = _moveObject.notifyAll("resetlevel");
        updateLevelsAngularUI(store.storeysDS);
        let sc = StructureCollection.getInstance();
        sc.recreateLevels(newLevels, _moveObject.structure_id);
      }
    } catch (e) {
      console.error(e);
    }
    updateModifications();
  } else if (pickInfo.hit) {
    if (pickInfo.pickedMesh.name.indexOf("scaleMeshAxis") !== -1) {
      pickInfo.pickedMesh.material =
        store.scene.getMaterialByName("scaleBoxMat2");
      scaleBox = pickInfo.pickedMesh;
      scaleMeshDir = pickInfo.pickedMesh.name.split("scaleMeshAxis")[1];
      // console.log(scaleMeshDir);
      scaleMeshStartingPosition = getScenePositionForScaleMesh(evt);
      st = scaleMesh.position.clone();

      _startPoint = new BABYLON.Vector2(
        store.newScene.pointerX,
        store.newScene.pointerY
      );
      createScaleSupportWall(scaleMeshStartingPosition);
      // store.newScene.activeCamera.detachControl(canvas);
      // var meshes = store.scene.getMeshesByID("scaleMeshAxis");
      // for (var i=0; i<meshes.length; i++)
      //     meshes[i].parent = scaleMesh;
    } else {
      scaleMeshDir = null;
      drawScaleMeshAxis(pickInfo.pickedMesh);
      scaleMesh = pickInfo.pickedMesh;
    }
  } else if (box) {
    scaleMeshDir = null;
    drawScaleMeshAxis(box);
  } else {
    scaleMeshDir = null;
    removeScaleMeshAxis();
    DisplayOperation.removeDimensions();
    scaleMesh = null;
    if (store.scene.getMeshByName("scaleMeshBox"))
      store.scene.getMeshByName("scaleMeshBox").dispose();
    if (store.scene.getMeshByName("scaleWall"))
      store.scene.getMeshByName("scaleWall").dispose();
    // store.newScene.activeCamera.attachControl(canvas, true, false);
  }
};

var onScaleMeshUp = function (evt) {
  // scaleMeshDir = null;
  // if (scaleBox){
  //     scaleBox.material = store.scene.getMaterialByName("scaleBoxMat1");
  //     scaleBox = null;
  // }
  // var meshes = store.scene.getMeshesByID("scaleMeshAxis");
  // if (meshes){
  //     for (var i=0; i<meshes.length; i++)
  //         meshes[i].parent = null;
  // }
  // if (scaleMesh){
  //     removeScaleMeshAxis();
  //     drawScaleMeshAxis(scaleMesh);
  // }
};

var onScaleMeshMove = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("ground1") !== -1 ||
        mesh.name.indexOf("scaleWall") !== -1 ||
        mesh.visibility > 0
      );
    }
  );
  // var pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY);

  if (pickInfo.hit) {
    // console.log(pickInfo.pickedMesh.name);
    if (scaleMeshDir) {
      if (!scaleMeshStartingPosition) {
        return;
      }
      var scaleMeshCurrentPosition = getScenePositionForScaleMesh(evt);
      var _currentPoint = new BABYLON.Vector2(
        store.newScene.pointerX,
        store.newScene.pointerY
      );
      var _dist = Math.ceil(
        BABYLON.Vector2.Distance(_startPoint, _currentPoint)
      );
      // console.log(_dist);

      if (!scaleMeshCurrentPosition) {
        return;
      }
      var diff = scaleMeshStartingPosition.subtract(scaleMeshCurrentPosition);
      // console.log(diff);
      //to update the scale on keyInput taking it as gload variable;
      _diff = diff;
      scaleScaleMesh(scaleMesh, diff, scaleMeshDir);
      let end = scaleMeshCurrentPosition.clone();
      DisplayOperation.displayScaleDimensions(
        st,
        scaleMesh,
        diff,
        scaleMeshDir
      );
      scaleMeshStartingPosition = scaleMeshCurrentPosition;
      console.log("scale direction", scaleMeshDir, diff);
    } else {
      /*else if (scene.getMeshByName("scaleMeshaxisX") || store.scene.getMeshByName("scaleMeshaxisY") || store.scene.getMeshByName("scaleMeshaxisZ")) {
        }*/
      var box = store.scene.getMeshByName("scaleMeshBox");
      if (box) box.dispose();
    }
  }
};

function updateScaleMesh(distance) {
  DisplayOperation.removeDimensions();
  let dist = DisplayOperation.getOriginalDimension(distance);
  let mesh = scaleMesh;
  let diff = _diff;
  removeScaleMeshAxis();
  mesh.prevScaling = mesh.scaling.clone();
  var prevScaling = mesh.prevScaling;
  var bbinfo = mesh.getBoundingInfo();

  if (scaleMeshDir === "x1") {
    mesh.scaling.x = dist;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
  }
  if (scaleMeshDir === "x2") {
    mesh.scaling.x = dist;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
  }
  if (scaleMeshDir === "y1") {
    mesh.scaling.y += diff.y / scaleMeshThresh;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
  }
  if (scaleMeshDir === "y2") {
    mesh.scaling.y = dist;
    console.log(mesh.scaling.y);
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
  }
  if (scaleMeshDir === "z1") {
    mesh.scaling.z = dist;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "z2") {
    mesh.scaling.z = dist;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y1z1") {
    mesh.scaling.x = dist;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y1z1") {
    mesh.scaling.x = dist;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y2z1") {
    mesh.scaling.x = dist;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y2z1") {
    mesh.scaling.x = dist;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y1z2") {
    mesh.scaling.x = dist;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y1z2") {
    mesh.scaling.x = dist;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y2z2") {
    mesh.scaling.x = dist;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y2z2") {
    mesh.scaling.x = dist;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y = dist;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z = dist;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (mesh.name.indexOf("boxScale") != -1) {
    mesh.parentMesh.scaling = mesh.scaling;
    mesh.parentMesh.position = mesh.position;
  }

  resetSacle();
}

function drawScaleMeshBox(scaleMesh, pt, dir) {
  var bbinfo = scaleMesh.getBoundingInfo();
  var boxSize = 0.05 * bbinfo.diagonalLength;
  if (boxSize > 0.75) {
    boxSize = 0.75;
  }
  if (scaleMesh.name.toLowerCase() === "wall") {
    boxSize = 0.25;
  }
  var box = BABYLON.MeshBuilder.CreateBox(
    "scaleMeshAxis" + dir,
    {
      height: boxSize,
      width: boxSize,
      depth: boxSize,
    },
    store.newScene
  );
  // box.renderingGroupId = 1;
  // console.log(jQuery.extend({}, pt));
  box.position = pt;
  // box.parent = scaleMesh;
  box.material = store.scene.getMaterialByName("scaleBoxMat1");
  box.id = "scaleMeshAxis";
}

function getDir(bbinfo, pt, points) {
  var dir = "";
  // if (Math.abs(pt.x - bbinfo.boundingBox.minimumWorld.x) < 0.001) {
  //     dir += "x1";
  // }
  // if (Math.abs(pt.x - bbinfo.boundingBox.maximumWorld.x) < 0.001) {
  //     dir += "x2";
  // }
  // if (Math.abs(pt.y - bbinfo.boundingBox.minimumWorld.y) < 0.001) {
  //     dir += "y1";
  // }
  // if (Math.abs(pt.y - bbinfo.boundingBox.maximumWorld.y) < 0.001) {
  //     dir += "y2";
  // }
  // if (Math.abs(pt.z - bbinfo.boundingBox.minimumWorld.z) < 0.001) {
  //     dir += "z1";
  // }
  // if (Math.abs(pt.z - bbinfo.boundingBox.maximumWorld.z) < 0.001) {
  //     dir += "z2";
  // }

  if (Math.abs(pt.x - points[0].x) < 0.001) {
    dir += "x1";
  }
  if (Math.abs(pt.x - points[1].x) < 0.001) {
    dir += "x2";
  }
  if (Math.abs(pt.y - points[2].y) < 0.001) {
    dir += "y1";
  }
  if (Math.abs(pt.y - points[3].y) < 0.001) {
    dir += "y2";
  }
  if (Math.abs(pt.z - points[4].z) < 0.001) {
    dir += "z1";
  }
  if (Math.abs(pt.z - points[5].z) < 0.001) {
    dir += "z2";
  }
  // console.log(dir);
  return dir;
}

function drawScaleMeshAxis(scaleMesh) {
  // scaleMesh.refreshBoundingInfo();
  removeScaleMeshAxis();
  var bbinfo = scaleMesh.getBoundingInfo();
  // scaleMesh.freezeWorldMatrix();
  // bbinfo.update(scaleMesh._worldMatrix);
  // var wMatrix = scaleMesh.getWorldMatrix();
  // var maximumWorldPoint = jQuery.extend({}, bbinfo.boundingBox.maximum);
  // var minimumWorldPoint = jQuery.extend({}, bbinfo.boundingBox.minimum);
  // var centerWorldPoint = jQuery.extend({}, bbinfo.boundingBox.center);
  // var newMaximumWorldPoint = BABYLON.Vector3.TransformCoordinates(maximumWorldPoint, wMatrix);
  // var newMinimumWorldPoint = BABYLON.Vector3.TransformCoordinates(minimumWorldPoint, wMatrix);
  // var newCenterWorldPoint = BABYLON.Vector3.TransformCoordinates(centerWorldPoint, wMatrix);
  // scaleMesh.unfreezeWorldMatrix();
  // console.log(maximumWorldPoint, newMaximumWorldPoint);
  // console.log(minimumWorldPoint, newMinimumWorldPoint);
  // console.log(centerWorldPoint, newCenterWorldPoint);
  // console.log(bbinfo.boundingBox.vectorsWorld);
  // console.log(bbinfo.boundingBox.minimumWorld);
  // console.log(bbinfo.boundingBox.centerWorld);
  var points = getMaxMinPoints(scaleMesh);
  for (var i = 0; i < bbinfo.boundingBox.vectorsWorld.length; i++) {
    var pt1 = bbinfo.boundingBox.vectorsWorld[i].clone();
    // var pt2 = jQuery.extend({}, bbinfo.boundingBox.vectorsWorld[i + 1]);
    // var pt1 = bbinfo.boundingBox.vectorsWorld[i];
    // var pt2 = bbinfo.boundingBox.vectorsWorld[i + 1];
    // var dir1 = getDir(bbinfo, pt1, points);
    // var color = new BABYLON.Color3(1/i+1)
    // var dir2 = getDir(bbinfo, pt2);
    // console.log(jQuery.extend({}, pt1));
    // var size = i + 1;
    var dir1 = null;
    if (i === 0) {
      dir1 = "x1y1z1";
    }
    if (i === 1) {
      dir1 = "x2y2z2";
    }
    if (i === 2) {
      dir1 = "x2y1z1";
    }
    if (i === 3) {
      dir1 = "x1y2z1";
    }
    if (i === 4) {
      dir1 = "x1y1z2";
    }
    if (i === 5) {
      dir1 = "x2y2z1";
    }
    if (i === 6) {
      dir1 = "x1y2z2";
    }
    if (i === 7) {
      dir1 = "x2y1z2";
    }
    drawScaleMeshBox(scaleMesh, pt1, dir1);
    // drawScaleMeshBox(scaleMesh, pt2, dir2);
  }
  // console.log(points);
  drawScaleMeshBox(scaleMesh, points[0], "x1");
  drawScaleMeshBox(scaleMesh, points[1], "x2");
  drawScaleMeshBox(scaleMesh, points[2], "y1");
  drawScaleMeshBox(scaleMesh, points[3], "y2");
  drawScaleMeshBox(scaleMesh, points[4], "z1");
  drawScaleMeshBox(scaleMesh, points[5], "z2");
  // var pt = new BABYLON.Vector3(bbinfo.boundingBox.minimumWorld.x, bbinfo.boundingBox.centerWorld.y, bbinfo.boundingBox.centerWorld.z);
  // drawScaleMeshBox(scaleMesh, pt, "x1");
  // var pt = new BABYLON.Vector3(bbinfo.boundingBox.maximumWorld.x, bbinfo.boundingBox.centerWorld.y, bbinfo.boundingBox.centerWorld.z);
  // drawScaleMeshBox(scaleMesh, pt, "x2");
  // var pt = new BABYLON.Vector3(bbinfo.boundingBox.centerWorld.x, bbinfo.boundingBox.minimumWorld.y, bbinfo.boundingBox.centerWorld.z);
  // drawScaleMeshBox(scaleMesh, pt, "y1");
  // var pt = new BABYLON.Vector3(bbinfo.boundingBox.centerWorld.x, bbinfo.boundingBox.maximumWorld.y, bbinfo.boundingBox.centerWorld.z);
  // drawScaleMeshBox(scaleMesh, pt, "y2");
  // var pt = new BABYLON.Vector3(bbinfo.boundingBox.centerWorld.x, bbinfo.boundingBox.centerWorld.y, bbinfo.boundingBox.minimumWorld.z);
  // drawScaleMeshBox(scaleMesh, pt, "z1");
  // var pt = new BABYLON.Vector3(bbinfo.boundingBox.centerWorld.x, bbinfo.boundingBox.centerWorld.y, bbinfo.boundingBox.maximumWorld.z);
  // drawScaleMeshBox(scaleMesh, pt, "z2");
  // var pt = new BABYLON.Vector3(newMinimumWorldPoint.x, newCenterWorldPoint.y, newCenterWorldPoint.z);
  // drawScaleMeshBox(scaleMesh, pt, "x1");
  // var pt = new BABYLON.Vector3(newMaximumWorldPoint.x, newCenterWorldPoint.y, newCenterWorldPoint.z);
  // drawScaleMeshBox(scaleMesh, pt, "x2");
  // var pt = new BABYLON.Vector3(newCenterWorldPoint.x, newMinimumWorldPoint.y, newCenterWorldPoint.z);
  // drawScaleMeshBox(scaleMesh, pt, "y1");
  // var pt = new BABYLON.Vector3(newCenterWorldPoint.x, newMaximumWorldPoint.y, newCenterWorldPoint.z);
  // drawScaleMeshBox(scaleMesh, pt, "y2");
  // var pt = new BABYLON.Vector3(newCenterWorldPoint.x, newCenterWorldPoint.y, newMinimumWorldPoint.z);
  // drawScaleMeshBox(scaleMesh, pt, "z1");
  // var pt = new BABYLON.Vector3(newCenterWorldPoint.x, newCenterWorldPoint.y, newMaximumWorldPoint.z);
  // drawScaleMeshBox(scaleMesh, pt, "z2");
}

function getMaxMinPoints(mesh) {
  var bbinfo = mesh.getBoundingInfo();
  var vectorsWorld = bbinfo.boundingBox.vectorsWorld;

  var points = [];
  var point = vectorsWorld[0]
    .add(vectorsWorld[4])
    .add(vectorsWorld[6])
    .add(vectorsWorld[3])
    .scale(0.25);
  points.push(point);

  point = vectorsWorld[2]
    .add(vectorsWorld[7])
    .add(vectorsWorld[1])
    .add(vectorsWorld[5])
    .scale(0.25);
  points.push(point);

  point = vectorsWorld[0]
    .add(vectorsWorld[2])
    .add(vectorsWorld[7])
    .add(vectorsWorld[4])
    .scale(0.25);
  points.push(point);

  point = vectorsWorld[3]
    .add(vectorsWorld[5])
    .add(vectorsWorld[1])
    .add(vectorsWorld[6])
    .scale(0.25);
  points.push(point);

  point = vectorsWorld[0]
    .add(vectorsWorld[2])
    .add(vectorsWorld[5])
    .add(vectorsWorld[3])
    .scale(0.25);
  points.push(point);

  point = vectorsWorld[4]
    .add(vectorsWorld[7])
    .add(vectorsWorld[1])
    .add(vectorsWorld[6])
    .scale(0.25);
  points.push(point);

  return points;
}

function removeScaleMeshAxis() {
  var meshes = store.scene.getMeshesByID("scaleMeshAxis");
  for (var i = 0; i < meshes.length; i++) meshes[i].dispose();
}

function scaleMeshAxisscaleMeshventFunc(mesh) {
  mesh.actionManager = new BABYLON.ActionManager(store.newScene);
  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          if (mesh.name === "scaleMeshaxisX") {
            if (store.scene.getMeshByName("scaleMeshaxisX"))
              store.scene.getMeshByName("scaleMeshaxisX").visibility = 1.0;
            if (store.scene.getMeshByName("scaleMeshaxisY"))
              store.scene.getMeshByName("scaleMeshaxisY").visibility = 0.2;
            if (store.scene.getMeshByName("scaleMeshaxisZ"))
              store.scene.getMeshByName("scaleMeshaxisZ").visibility = 0.2;
          }
          if (mesh.name === "scaleMeshaxisY") {
            if (store.scene.getMeshByName("scaleMeshaxisX"))
              store.scene.getMeshByName("scaleMeshaxisX").visibility = 0.2;
            if (store.scene.getMeshByName("scaleMeshaxisY"))
              store.scene.getMeshByName("scaleMeshaxisY").visibility = 1.0;
            if (store.scene.getMeshByName("scaleMeshaxisZ"))
              store.scene.getMeshByName("scaleMeshaxisZ").visibility = 0.2;
          }
          if (mesh.name === "scaleMeshaxisZ") {
            if (store.scene.getMeshByName("scaleMeshaxisX"))
              store.scene.getMeshByName("scaleMeshaxisX").visibility = 0.2;
            if (store.scene.getMeshByName("scaleMeshaxisY"))
              store.scene.getMeshByName("scaleMeshaxisY").visibility = 0.2;
            if (store.scene.getMeshByName("scaleMeshaxisZ"))
              store.scene.getMeshByName("scaleMeshaxisZ").visibility = 1.0;
          }
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          if (store.scene.getMeshByName("scaleMeshaxisX"))
            store.scene.getMeshByName("scaleMeshaxisX").visibility = 1.0;
          if (store.scene.getMeshByName("scaleMeshaxisY"))
            store.scene.getMeshByName("scaleMeshaxisY").visibility = 1.0;
          if (store.scene.getMeshByName("scaleMeshaxisZ"))
            store.scene.getMeshByName("scaleMeshaxisZ").visibility = 1.0;
        }
      )
    );
}

var getScenePositionForScaleMesh = function (evt) {
  // if (scene.getMeshByName("backwallnew")) store.scene.getMeshByName("backwallnew").dispose();
  // var wall = BABYLON.Mesh.CreatePlane("backwallnew", 10000, store.newScene);
  // wall.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
  // // var cam = store.newScene.activeCamera;
  // // console.log(cam.alpha, cam.beta, cam.radius);
  // // wall.material = new BABYLON.StandardMaterial("wallMat", store.newScene);
  // // wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
  // // wall.parent = store.newScene.activeCamera;
  // // console.log(wall.parent);
  // wall.visibility = 0;
  var pickinfo1 = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("ground1");
    }
  );
  var pickinfo2 = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("scaleWall");
    }
  );
  if (pickinfo1.hit && pickinfo2.hit) {
    var pt = new BABYLON.Vector3(
      pickinfo1.pickedPoint.x,
      pickinfo2.pickedPoint.y,
      pickinfo1.pickedPoint.z
    );
    // console.log("p1p2");
    return pt;
  } else if (pickinfo2.hit) {
    // console.log("p2");
    return pickinfo2.pickedPoint;
  } else if (pickinfo1.hit) {
    // console.log("p1");
    return pickinfo1.pickedPoint;
  }
  return null;
};

function scaleScaleMesh(mesh, diff, scaleMeshDir) {
  removeScaleMeshAxis();
  mesh.prevScaling = mesh.scaling.clone();
  var prevScaling = mesh.prevScaling;
  var bbinfo = mesh.getBoundingInfo();
  // var sign1 = Math.sign(mesh.position.x-newScene.activeCamera.position.x)*Math.sign(mesh.position.y-newScene.activeCamera.position.x)*Math.sign(mesh.position.z-newScene.activeCamera.position.x);
  // var sign2 = -Math.sign(diff.x)*Math.sign(diff.y)*Math.sign(diff.z);
  // var scaleValue = sign1*sign2*Math.pow(diff.x**2 + diff.y**2 + diff.z**2, 0.5);
  // Object.keys(diff).forEach(function (key) {
  //     diff[key] = Math.abs(diff[key]);
  // });
  // console.log(diff);
  var scaleValue = (diff.x + diff.y + diff.z) / 3;
  // var scaleValue = diff / scaleMeshThresh;
  console.log(diff.y);
  // console.log(scaleMeshDir, mesh.position.subtract(newScene.activeCamera.position));

  if (scaleMeshDir === "x1") {
    mesh.scaling.x += diff.x / scaleMeshThresh;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
  }
  if (scaleMeshDir === "x2") {
    mesh.scaling.x -= diff.x / scaleMeshThresh;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
  }
  if (scaleMeshDir === "y1") {
    mesh.scaling.y += diff.y / scaleMeshThresh;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
  }
  if (scaleMeshDir === "y2") {
    mesh.scaling.y -= diff.y / scaleMeshThresh;
    console.log(mesh.scaling.y);
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
  }
  if (scaleMeshDir === "z1") {
    mesh.scaling.z += diff.z / scaleMeshThresh;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "z2") {
    mesh.scaling.z -= diff.z / scaleMeshThresh;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y1z1") {
    mesh.scaling.x += scaleValue / scaleMeshThresh;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y += scaleValue / scaleMeshThresh;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z += scaleValue / scaleMeshThresh;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y1z1") {
    mesh.scaling.x += scaleValue / scaleMeshThresh;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y += scaleValue / scaleMeshThresh;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z += scaleValue / scaleMeshThresh;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y2z1") {
    mesh.scaling.x += scaleValue / scaleMeshThresh;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y += scaleValue / scaleMeshThresh;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z += scaleValue / scaleMeshThresh;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y2z1") {
    mesh.scaling.x += scaleValue / scaleMeshThresh;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y += scaleValue / scaleMeshThresh;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z += scaleValue / scaleMeshThresh;
    mesh.position.z -=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y1z2") {
    mesh.scaling.x -= scaleValue / scaleMeshThresh;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y -= scaleValue / scaleMeshThresh;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z -= scaleValue / scaleMeshThresh;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y1z2") {
    mesh.scaling.x -= scaleValue / scaleMeshThresh;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y -= scaleValue / scaleMeshThresh;
    mesh.position.y -=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z -= scaleValue / scaleMeshThresh;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x1y2z2") {
    mesh.scaling.x -= scaleValue / scaleMeshThresh;
    mesh.position.x -=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y -= scaleValue / scaleMeshThresh;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z -= scaleValue / scaleMeshThresh;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (scaleMeshDir === "x2y2z2") {
    mesh.scaling.x -= scaleValue / scaleMeshThresh;
    mesh.position.x +=
      (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    mesh.scaling.y -= scaleValue / scaleMeshThresh;
    mesh.position.y +=
      (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    mesh.scaling.z -= scaleValue / scaleMeshThresh;
    mesh.position.z +=
      (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
  }
  if (mesh.name.indexOf("boxScale") != -1) {
    mesh.parentMesh.scaling = mesh.scaling;
    mesh.parentMesh.position = mesh.position;
  }
}

var resetSacle = function () {
  if (scaleMesh) {
    scaleMeshDir = null;
    scaleMeshStartingPosition = null;
    scaleMesh = null;
    scaleMeshThresh = 30;
    scaleBox = null;
    _startPoint = null;
    st = null;
    _diff = null;
  }
};

var createScaleSupportWall = function (position) {
  var ground = store.newScene.getMeshByName("ground1");
  ground.isPickable = true;
  var scaleWall = store.newScene.getMeshByName("scaleWall");
  if (!scaleWall) {
    scaleWall = BABYLON.Mesh.CreatePlane("scaleWall", 10000, store.newScene);
    scaleWall.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
    scaleWall.visibility = 0;
    scaleWall.position = position;
  }
};
export {
  scaleMeshDir,
  scaleMeshStartingPosition,
  scaleMesh,
  scaleMeshThresh,
  scaleBox,
  _startPoint,
  st,
  _diff,
  onScaleMeshDown,
  onScaleMeshUp,
  onScaleMeshMove,
  updateScaleMesh,
  drawScaleMeshBox,
  getDir,
  drawScaleMeshAxis,
  getMaxMinPoints,
  removeScaleMeshAxis,
  scaleMeshAxisscaleMeshventFunc,
  getScenePositionForScaleMesh,
  scaleScaleMesh,
  resetSacle,
  createScaleSupportWall,
};
