import _ from "lodash";
import { store } from "./Store";

const meshUniqueIdMapper = (function (){

    let uniqueIdMeshMap = new Map();

    const add = function (mesh){
        if(mesh.uniqueId){
            uniqueIdMeshMap.set(mesh.uniqueId, mesh);
        }
    };

    const remove = function (mesh){
        uniqueIdMeshMap.delete(mesh.uniqueId);
    };

    const update = function (mesh, newUniqueId){
        remove(mesh);
        mesh.uniqueId = newUniqueId;
        add(mesh);
    };

    const get = function (uniqueId){
        return uniqueIdMeshMap.get(uniqueId);
    };

    const length = function (){
        return uniqueIdMeshMap.size;
    };

    const getMap = function (){
        return uniqueIdMeshMap;
    };

    const validate = function (){
        const trueIds = store.scene.meshes.map(m => m.uniqueId);
        const apnaData = Array.from(uniqueIdMeshMap.keys());

        const missingFromMap = _.difference(trueIds, apnaData);
        if (!_.isEmpty(missingFromMap)){
            console.log('These meshes are missing in the map');
            console.log(missingFromMap);
        }

        const notRemovedFromMap = _.difference(apnaData, trueIds);
        if (!_.isEmpty(notRemovedFromMap)){
            console.log('These meshes have not been removed from the map');
            console.log(notRemovedFromMap);
        }

        if (_.isEmpty(_.union(missingFromMap, notRemovedFromMap))){
            console.log("All's good 👍");
        }
    };

    const flush = () => {
        uniqueIdMeshMap = new Map();
    };

    return {
        add,
        remove,
        update,
        validate,
        flush,

        // queries
        get,
        length,
        getMap
    };

})();

export {
  meshUniqueIdMapper,
};
