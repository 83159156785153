import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  isStoreyHidden,
  isMeshThrowAway,
  setUserSettingAndRecord,
} from "../modules/extrafunc.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { appElement } from "./bimDataFuncs.js";
import { isTwoDimension } from "./twoDimension.js";
import { Mass } from "../modules/snaptrudeDS/mass.ds.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { virtualSketcher } from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { userSetBIMPropertiesHandler } from "../modules/utilityFunctions/CONSTANTS/userSetBimPropertiesHandler.js";
import {setUserSetting} from "../modules/extrafunc";
import {setLayerTransperancy} from "./sceneFuncs";

/*
viewChange isn't used now. Logic moved to setLayerTransparency
 */
function showAllRoofs(userInitiated, viewChange) {
  // viewChange is true when 2D <-> 3D
  let showAllRoofCommandName = "Show All Roofs";
  let stack = [];

  if (viewChange) {
    stack = store.roofsVisibleIn3D;
  } else {
    for (let mesh of store.scene.meshes) {
      if (mesh.type.toLowerCase() === "roof") {
        if (!mesh.isVisible) {
          let roofDS = mesh.getSnaptrudeDS();
          if (!isStoreyHidden(roofDS.storey)) {
            stack.push(mesh);
          }
        }
      }
    }
  }

  if (userInitiated) {
    objectVisibilityUtil(showAllRoofCommandName, stack, false);
  } else {
    showObjectUtil(stack);
    updateRoofAccordion();
  }
}

function hideAllRoofs(userInitiated, viewChange) {
  let hideAllRoofCommandName = "Hide All Roofs";
  let stack = [];

  store.roofsVisibleIn3D = [];
  for (let mesh of store.scene.meshes) {
    if (mesh.type.toLowerCase() === "roof") {
      if (mesh.isVisible) {
        if (viewChange) store.roofsVisibleIn3D.push(mesh);
        stack.push(mesh);
      }
    }
  }

  if (userInitiated) {
    objectVisibilityUtil(hideAllRoofCommandName, stack, true);
  } else {
    hideObjectUtil(stack);
    updateRoofAccordion();
  }
}

function toggleRoofVisibility(mesh, toHide) {
  let stack = [mesh, ...mesh.getChildMeshes()];
  let commandName = "individualRoofVisibilityToggle";

  objectVisibilityUtil(commandName, stack, toHide);
}

function updateRoofAccordion(recalculate) {
  if (store.roofsInScene.length === 0 || recalculate) {
    store.roofsInScene = [];
    store.scene.meshes.forEach((mesh) => {
      if (!mesh.type) return;
      // if (mesh.type.toLowerCase() === "roof") store.roofsInScene.push(mesh.uniqueId);
      if (mesh.type.toLowerCase() === "roof") store.roofsInScene.push(mesh);
    });
  }

  store.roofsInScene.sort((roofMesh1, roofMesh2) => {
    if (roofMesh1.storey === roofMesh2.storey) {
      return (
        roofMesh1.getAbsolutePosition().length() -
        roofMesh2.getAbsolutePosition().length()
      );
    } else {
      return roofMesh1.storey - roofMesh2.storey;
    }
  });

  let objectOfArrayOfRoofsSortedByStorey = {};

  let masterShowHideButtonStatus = false;
  let masterOffset = NaN;

  store.roofsInScene.forEach((roofMesh) => {
    let roof = roofMesh.getSnaptrudeDS();
    if (roofMesh.isDisposed() || !roof || !roofMesh.storey) return;
    if (isMeshThrowAway(roofMesh)) return;
    if (roof.isBasementSlab && roof.isBasementSlab()) return;

    if (!objectOfArrayOfRoofsSortedByStorey[roofMesh.storey]) {
      objectOfArrayOfRoofsSortedByStorey[roofMesh.storey] = [];
    }

    let obj = {};
    //Didn't want to use store.scene.getMeshByUniqueId because it loops through all the meshes,
    //but when meshes are in $applyAsync directly, it's damn slow, so using id itself
    obj.roofMesh = roofMesh.uniqueId;
    // obj.overhang = Math.round(roofMesh.getSnaptrudeDS().offset * 254);
    obj.overhang = DisplayOperation.convertToDefaultDimension(roof.offset);
    obj.roofShown = roofMesh.isVisible && roofMesh.visibility === 1;

    masterShowHideButtonStatus = masterShowHideButtonStatus || obj.roofShown;

    if (isNaN(masterOffset)) {
      masterOffset = obj.overhang;
    } else {
      /* eslint-disable */
      if (masterOffset === "") {
      } else if (masterOffset === obj.overhang) {
      } else {
        /* eslint-enable */
        masterOffset = "";
      }
    }

    objectOfArrayOfRoofsSortedByStorey[roofMesh.storey].push(obj);
  });

  let roofsPresent = true;
  if (Object.keys(objectOfArrayOfRoofsSortedByStorey).length === 0)
    roofsPresent = false;

  objectOfArrayOfRoofsSortedByStorey["roofsPresent"] = roofsPresent;

  // objectOfArrayOfRoofsSortedByStorey["masterRoofShown"] = masterShowHideButtonStatus;
  // objectOfArrayOfRoofsSortedByStorey["roofOverhangValue"] = masterOffset;

  /* AG-RE: ANGULAR REFERENCE */
  // let $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;

  let roofOverhangProjectProperty;
  if (roofsPresent) {
    if (masterOffset) {
      roofOverhangProjectProperty = masterOffset;
    } else {
      roofOverhangProjectProperty = DisplayOperation.convertToDefaultDimension(
        userSetBIMPropertiesHandler.getDefaultRoofOverhangProperty()
      );
    }
  } else {
    roofOverhangProjectProperty = DisplayOperation.convertToDefaultDimension(
      userSetBIMPropertiesHandler.getDefaultRoofOverhangProperty()
    );
  }

  // $scope.hideRoofsSelected = !masterShowHideButtonStatus;
  // $scope.highlightElement(
  //   $scope.hideRoofsSelected,
  //   document.querySelector("#hideRoofsToggle")
  // );

  // $scope.objectOfArrayOfRoofsSortedByStorey = objectOfArrayOfRoofsSortedByStorey;
  store.$scope.userSetBIMProperties.roofOverhang = roofOverhangProjectProperty;

  // $scope.$applyAsync();
}

function hideObjectUtil(stack, isolate, options = {}) {
  function predicate(mesh) {
    return mesh.name.toLowerCase() !== "floor";
  }

  stack.forEach((mesh) => {
    if (mesh) {
      if(options && !options.donotAddToHiddenMeshes){
        store.hiddenMeshes.push(mesh.uniqueId);
      }
      mesh.isVisible = false;
      mesh.isPickable = false;
      mesh.state = "off";
      //mesh.visibility = 0;
      mesh.modifiedBySetLayerTransparency = false;

      let children = mesh.getChildren(predicate);

      children.forEach(function (child) {
        if (typeof isolate == "undefined") {
          child.isVisible = false;
          child.isPickable = false;
          if (store.$scope.isTwoDimension) {
            child.getChildMeshes().forEach(function (grandchild) {
              grandchild.isVisible = false;
            });
          }
        } else {
          if (store.$scope.isTwoDimension) {
            child.getChildMeshes().forEach(function (grandchild) {
              grandchild.isVisible = false;
            });
          }
        }
      });
    }
  });
}

function showObjectUtil(stack) {
  stack.forEach((mesh) => {
    store.hiddenMeshes.removeElem(mesh.uniqueId);

    if(!store.$scope.isTwoDimension && ["floorplan", "cadsketch"].includes(mesh.type.toLowerCase())){
      return;
    }
    mesh.isVisible = true;
    //mesh.visibility = 1;
    mesh.isPickable = true;
    let children = mesh.getChildren();
    setLayerTransperancy(mesh);
    children.forEach(function (child) {
      child.isVisible = true;
      child.isPickable = true;
      //This is to hide 2d door representations in 3d view.
      if(!store.$scope.isTwoDimension){
        if(["typeUnassigned", "doorWindowIndicator"].includes(child.type) && child.name.includes("merged") ){
          child.isVisible = false;
          child.isPickable = false;
        }
      }
      if(store.$scope.isTwoDimension){
        child.getChildMeshes().forEach(function (grandchild) {
          grandchild.isVisible = true;
        });
      }
    });
  });
}

function objectVisibilityUtil(name, stack, toHide, isolate, opts = {}) {
  if (!_.isArray(stack)) stack = [stack];
  stack = _.compact(stack);
  if (_.isEmpty(stack)) return;

  let options = {
    meshKeys: ["isVisible", "isPickable"],
  };

  if (opts.handleChildren) {
    options.handleChildren = true;
  }

  let plinths = [];
  stack.forEach((mesh) => {
    mesh.getChildren().forEach((child) => {
      if (Mass.isPlinth(child)) {
        plinths.push(child);
      }
    });
  });

  let commandData = commandUtils.propertyChangeOperations.getCommandData(
    [...stack, ...plinths],
    options
  );
  options.data = commandData;

  if (toHide) {
    store.makeFloorPlanOrCADInvisible = true;
    hideObjectUtil(stack, isolate);
  } else {
    store.makeFloorPlanOrCADInvisible = false;
    showObjectUtil(stack);
  }

  updateRoofAccordion();

  commandData = commandUtils.propertyChangeOperations.getCommandData(
    [...stack, ...plinths],
    options
  );

  let optionsForExecute = {
    postExecuteCallback: updateRoofAccordion,
    postUnExecuteCallback: updateRoofAccordion,
  };

  commandUtils.propertyChangeOperations.executeCommand(
    name,
    commandData,
    optionsForExecute
  );
}

function overhangRoof(value, mesh, options = {}) {
  // let overhangValue = value/(1000 * unit_absolute_scale * 10);
  let overhangValue = DisplayOperation.getOriginalDimension(value);
  let currentOverhangValue = store.userSettingsInStructure["roofOverhang"];
  let roofMeshes = [];

  let changeOnAllRoofs = false;
  if (mesh) {
    roofMeshes = [mesh];
  } else {
    if (overhangValue === currentOverhangValue) {
      return;
    }
    changeOnAllRoofs = true;

    if (store.selectionStack.length !== 0) {
      store.selectionStack.forEach(function (mesh) {
        if (mesh.name.toLowerCase() === "roof") {
          roofMeshes.push(mesh);
        }
      });
    } else {
      setUserSettingAndRecord("roofOverhang", overhangValue);

      for (let i = 0, j = store.scene.meshes.length; i < j; i++) {
        /*
                When overhang() returns, new roof is created and old one disposed, store.scene.meshes gets updated within the loop.
                Thus, this functionality is erratic with forEach().
                 */
        let mesh = store.scene.meshes[i];
        if (mesh.name.toLowerCase() === "roof") {
          roofMeshes.push(mesh);
          // i--;
          // j--;
          //Comment if roof overhang is reverted to being verData manipulation
        }
      }
    }
  }

  if (_.isEmpty(roofMeshes)) return;

  roofMeshes = _.uniq(
    roofMeshes.map((m) => m.getSnaptrudeDS().getDataStore().mesh)
  );

  let geometryChangeCommandData =
    commandUtils.geometryChangeOperations.getCommandData(roofMeshes);

  let optionsForPropertyChange = {
    componentKeys: ["roof_pol_offset_top", "roof_pol_offset_bottom", "offset"],
  };
  let propertyChangeCommandData =
    commandUtils.propertyChangeOperations.getCommandData(
      roofMeshes,
      optionsForPropertyChange
    );

  const modifiedMeshes = roofMeshes.filter((roofMesh) => {
    let roof = roofMesh.getSnaptrudeDS();
    if (
      (roof.isIntermediateSlab() || options.codeInitiated) &&
      !roof.isOverhangDisabled()
    ) {
      return roof.overhang(overhangValue);
    }
  });

  if (_.isEmpty(modifiedMeshes)) return;

  updateRoofAccordion();

  geometryChangeCommandData =
    commandUtils.geometryChangeOperations.getCommandData(
      roofMeshes,
      geometryChangeCommandData
    );

  optionsForPropertyChange.data = propertyChangeCommandData;
  propertyChangeCommandData =
    commandUtils.propertyChangeOperations.getCommandData(
      roofMeshes,
      optionsForPropertyChange
    );

  let geometryChangeCommand = commandUtils.geometryChangeOperations.getCommand(
    "roofOverhangGeometry",
    geometryChangeCommandData
  );

  let callBacksForPropertyChange = {
    postExecuteCallback: function () {
      updateRoofAccordion();
      if (changeOnAllRoofs) {
        setUserSettingAndRecord("roofOverhang", overhangValue);
      }
    },
    postUnExecuteCallback: function () {
      updateRoofAccordion();
      if (changeOnAllRoofs) {
        setUserSetting("roofOverhang", currentOverhangValue);
      }
    },
  };

  let propertyChangeCommand = commandUtils.propertyChangeOperations.getCommand(
    "roofOverhangProperty",
    propertyChangeCommandData,
    callBacksForPropertyChange
  );

  /*let uiChangeCommandData = commandUtils.changeInUIOperations.getCommandDataPrototype();

    let userSetBIMPropertiesKey = "userSetBIMProperties";
    let userSetBIMProperties = ScopeUtils.getScope()[userSetBIMPropertiesKey];

    uiChangeCommandData.explicitSave.valuesBefore[userSetBIMPropertiesKey] = deepCopyObject(userSetBIMProperties);
    uiChangeCommandData.explicitSave.valuesAfter[userSetBIMPropertiesKey] = deepCopyObject(userSetBIMProperties);

    let uiChangeCommand = commandUtils.changeInUIOperations.getCommand("roofOverhang", uiChangeCommandData);*/

  const uiDataPoint = ["userSetBIMProperties.roofOverhang", DisplayOperation.convertToDefaultDimension(currentOverhangValue), value];
  const saveDataPoint = ["roofOverhang", currentOverhangValue, overhangValue];
  const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(uiDataPoint, saveDataPoint);
  const cmdUI = commandUtils.changeInUIOperations.getCommand("changeProjectPropUIRoofOverhang", cmdDataUI);

  const components = roofMeshes.map((m) => m.getSnaptrudeDS());
  const parametricEditCommand = virtualSketcher.updateWithGeometryEdit(
    components,
    true
  );

  let commands = _.compact([
    geometryChangeCommand,
    propertyChangeCommand,
    parametricEditCommand,
    cmdUI
  ]);
  let yetToExecutes = commands.map((_) => false);
  if (options.command) {
    commands.push(...options.command.commands);
    yetToExecutes.push(...options.command.yetToExecutes);
  }
  CommandManager.execute(commands, yetToExecutes);
}
export {
  showAllRoofs,
  hideAllRoofs,
  toggleRoofVisibility,
  updateRoofAccordion,
  hideObjectUtil,
  showObjectUtil,
  objectVisibilityUtil,
  overhangRoof,
};
