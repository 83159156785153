import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { returnArrayOfV3s,areThreeVectorsCollinear } from "../modules/extrafunc.js";
import { calculateEuclidianDistance } from "./polygon_dip_workers.js";
/*jshint esversion: 6 */

function cross(a, b) {
  let x = a[1] * b[2] - a[2] * b[1];
  let y = a[2] * b[0] - a[0] * b[2];
  let z = a[0] * b[1] - a[1] * b[0];
  return [x, y, z];
}

function dot(a, b) {
  return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

function det(a) {
  return (
    a[0][0] * a[1][1] * a[2][2] +
    a[0][1] * a[1][2] * a[2][0] +
    a[0][2] * a[1][0] * a[2][1] -
    a[0][2] * a[1][1] * a[2][0] -
    a[0][1] * a[1][0] * a[2][2] -
    a[0][0] * a[1][2] * a[2][1]
  );
}

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

function round2DArr(p) {
  var point = JSON.parse(JSON.stringify(p));
  point[0] = Math.round(point[0] * 100) / 100;
  point[1] = Math.round(point[1] * 100) / 100;
  return point;
}

function round3DArr(point) {
  point[0] = Math.round(point[0] * 100000) / 100000;
  point[1] = Math.round(point[1] * 100000) / 100000;
  point[2] = Math.round(point[2] * 100000) / 100000;
  return point;
}

function unit_normal(a, b, c) {
  let x = det([
    [1, a[1], a[2]],
    [1, b[1], b[2]],
    [1, c[1], c[2]],
  ]);

  let y = det([
    [a[0], 1, a[2]],
    [b[0], 1, b[2]],
    [c[0], 1, c[2]],
  ]);

  let z = det([
    [a[0], a[1], 1],
    [b[0], b[1], 1],
    [c[0], c[1], 1],
  ]);
  let magnitude = Math.pow(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2), 0.5);
  return [x / magnitude, y / magnitude, z / magnitude];
}

/**
 * For CCW points, returns a vector going out of the mesh
 *
 * The direction of normal is as follows-
 * First 3 non-collinear points are taken, 1 is connected to 3 and then normal is calculated.
 * So depending on the shape of the face, even if points as a whole is in CCW order, points 1,2,3 might not be.
 * So, direction cannot be guaranteed.
 *
 * @param points
 * @param round
 * @returns {null|number[]}
 */
function getNormalVector(points, round = false) {
  if (points.length < 3) return;

  points = returnArrayOfV3s(points);

  /*
  let unitNorm = new BABYLON.Vector3.Zero();
  for(let i = 0; i < points.length; i++)
  {
    let prevId = (i === 0) ? points.length - 1: i - 1;
    let nextId = (i + 1) % points.length;
    let A = points[i].subtract(points[prevId]);
    let B = points[nextId].subtract(points[i]);
    let cross = BABYLON.Vector3.Cross(B,A); // Babylon uses left-handed system by default
    unitNorm = unitNorm.add(cross);
  }
  unitNorm = unitNorm.negate().normalize();

  return unitNorm.asArray();
   */

  let vector1 = points[0];
  let vector2 = points[1];
  let vector3 = points[2];

  let index = 3;

  while (areThreeVectorsCollinear(vector1, vector2, vector3)) {
    if (index >= points.length) return null;
    else vector2 = points[index++];
    // when this is vector2 instead of vector3, randomisation is more,
    // in the next iteration, the third element of first iteration is swapped
  }

  var Ax = vector1.x;
  var Ay = vector1.y;
  var Az = vector1.z;

  var Bx = vector2.x;
  var By = vector2.y;
  var Bz = vector2.z;

  var Cx = vector3.x;
  var Cy = vector3.y;
  var Cz = vector3.z;

  var a = (By - Ay) * (Cz - Az) - (Cy - Ay) * (Bz - Az);
  var b = (Bz - Az) * (Cx - Ax) - (Cz - Az) * (Bx - Ax);
  var c = (Bx - Ax) * (Cy - Ay) - (Cx - Ax) * (By - Ay);
  var d = -(a * Ax + b * Ay + c * Az);
  if (round) {
    a = Math.round(a);
    b = Math.round(b);
    c = Math.round(c);
  }

  return [a, b, c];
}

/**
 * For points that are CCW when looked at from outside the mesh,
 * returns a vector going out of the mesh, that is towards the viewer
 *
 * Example- getUnitNormalVectorV3(getTopFaceVertices(sm.getSnaptrudeDS()))
 *           {_isDirty: true, _x: -0, _y: 1, _z: -0}
 * Top vertices are CCW when looked at from above
 *
 * The direction of normal is as follows-
 * First 3 non-collinear points are taken, 1 is connected to 3 and then normal is calculated.
 * So depending on the shape of the face, even if points as a whole is in CCW order, points 1,2,3 might not be.
 * So, direction cannot be guaranteed.
 *
 *
 * @param points
 * @param round
 * @returns {[number, number, number]}
 */
function getUnitNormalVectorV3(points, round) {
  let normal = getNormalVector(points, round);
  if (normal) {
    normal = BABYLON.Vector3.FromArray(normal).normalize().negate();
  }

  return normal;
}

/**
 *
 * Returns reliable unit normal, but takes more time
 * Fixes the problem mentioned above
 *
 * For a complicated shape, imagine a convex hull and its direction, the normal returned will be as if
 * the hull was passed
 *
 * @param points
 * @param round
 * @returns {*}
 */
function getUnitNormalVectorV3CyclicCheck(points, round) {
  const normals = [];
  const occurrences = [];

  let unitNorm = new BABYLON.Vector3();
  for(var i=0; i<points.length; i++)
  {
    let prevId = (i==0)? points.length -1: i-1;
    let nextId = (i+1)%points.length;
    let A = points[i].subtract(points[prevId]);
    let B = points[nextId].subtract(points[i]);
    let cross = BABYLON.Vector3.Cross(A,B);
    unitNorm = unitNorm.add(cross);
  }
  unitNorm = unitNorm.normalize();
  return unitNorm;
  // const normals = [];
  // const occurrences = [];

  // const length = points.length;
  // points.forEach((point, i) => {
  //   const nextIndex = i === length - 1 ? 0 : i + 1;
  //   const nextNextIndex = nextIndex === length - 1 ? 0 : nextIndex + 1;

  //   const pointsTriad = [points[i], points[nextIndex], points[nextNextIndex]];
  //   const normal = getUnitNormalVectorV3(pointsTriad, round);

  //   if (!normal) return;
  //   // collinear points

  //   const normalIndexInNormals = _.findIndex(normals, (v) =>
  //     v.almostEquals(normal)
  //   );
  //   if (normalIndexInNormals !== -1) {
  //     occurrences[normalIndexInNormals]++;
  //   } else {
  //     normals.push(normal);
  //     occurrences.push(1);
  //   }
  // });

  // const maxOccurrence = _.max(occurrences);
  // const maxIndex = _.findIndex(occurrences, (e) => e === maxOccurrence);
  // return normals[maxIndex];
}

function toFixedVector(point, digit) {
  point.x = parseFloat(point.x.toFixed(digit));
  point.y = parseFloat(point.y.toFixed(digit));
  point.z = parseFloat(point.z.toFixed(digit));
  return point;
}

function roundPoint(point) {
  if (!point) return null;
  let temp = [];
  temp[0] = point[0].toString().split(".")[0];
  temp[1] = point[1].toString().split(".")[0];
  return temp;
}

function roundVector(point, prec) {
  point.x = Math.round(point.x * prec) / prec;
  point.y = Math.round(point.y * prec) / prec;
  point.z = Math.round(point.z * prec) / prec;
  return point;
}

function getOrientationPoints(point1, point2, point3) {
  let val =
    (point2[1] - point1[1]) * (point3[0] - point2[0]) -
    (point2[0] - point1[0]) * (point3[1] - point2[1]);
  if (val === 0) return 0; // colinear

  return val > 0 ? 1 : 2; //clock or counterclock wise
}

function getCentroidVectors(coords) {
  let temp = new BABYLON.Vector3.Zero();
  for (let i = 0; i < coords.length; i++) {
    temp.addInPlace(coords[i]);
  }
  temp.scaleInPlace(1 / coords.length);
  return temp;
}

function getMeshMedianY(verdata, wmtrix = null) {
  let tempArr = [];

  if (wmtrix) {
    for (let vr = 0; vr < verdata.length; vr += 3) {
      let point = new BABYLON.Vector3(
        verdata[vr],
        verdata[vr + 1],
        verdata[vr + 2]
      );
      let nepoint = BABYLON.Vector3.TransformCoordinates(point, wmtrix);
      tempArr.push(nepoint.y);
    }
  } else {
    for (let i = 0; i < verdata.length; i += 3) {
      tempArr.push(verdata[i + 1]);
    }
  }

  tempArr.sort(function (a, b) {
    return a - b;
  });
  if (tempArr.length % 2 !== 0) {
    return tempArr[tempArr.length / 2];
  } else {
    let index1 = tempArr.length / 2;
    let index2 = tempArr.length / 2 - 1;
    return (tempArr[index1] + tempArr[index2]) / 2;
  }
}

function makePathByGrahamScanAlgo(wall_arr) {
  let y_min = { index: 0, value: -9999 };

  for (let i = 0; i < wall_arr.length; i++) {
    if (
      y_min.value < wall_arr[i][1] ||
      (y_min.value === wall_arr[i][1] &&
        wall_arr[y_min.index][0] < wall_arr[i][0])
    ) {
      y_min.value = wall_arr[i][1];
      y_min.index = i;
    }
  }
  let temp = wall_arr[0];
  wall_arr[0] = wall_arr[y_min.index];
  wall_arr[y_min.index] = temp;

  let point1 = wall_arr.shift();

  wall_arr.sort(function (p, q) {
    let orient = getOrientationPoints(point1, p, q);
    if (orient === 0)
      return calculateEuclidianDistance(point1, q) >=
        calculateEuclidianDistance(point1, p)
        ? -1
        : 1;

    return orient === 2 ? -1 : 1;
  });

  wall_arr.unshift(point1);
  return wall_arr;
}

function getStandardDeviation(array) {
  let avg = average(array);

  let squareDiffs = array.map(function (value) {
    let diff = value - avg;
    let sqrDiff = diff * diff;
    return sqrDiff;
  });

  let avgSquareDiff = average(squareDiffs);

  let stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
}

function average(array) {
  let sum = array.reduce(function (sum, value) {
    if (isNaN(value)) throw new Error("Not a number");
    return sum + value;
  }, 0);

  let avg = sum / array.length;
  return avg;
}

function areFloatsAlmostEqual(x1, x2, threshold) {
  return Math.abs(x1 - x2) < threshold;
}

/**
 * Converts numbers to fractions:
 * - 1.25 to 1 1/4
 * - 2 to 2
 */
var numberToFraction = function (amount) {
  // This is a whole number and doesn't need modification.
  if (parseFloat(amount) === parseInt(amount)) {
    return amount;
  }
  // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
  var gcd = function (a, b) {
    if (b < 0.0000001) {
      return a;
    }
    return gcd(b, Math.floor(a % b));
  };
  var len = amount.toString().length - 2;
  var denominator = Math.pow(10, len);
  var numerator = amount * denominator;
  var divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  var base = 0;
  // In a scenario like 3/2, convert to 1 1/2
  // by pulling out the base number and reducing the numerator.
  if (numerator > denominator) {
    base = Math.floor(numerator / denominator);
    numerator -= base * denominator;
  }
  amount = Math.floor(numerator) + "/" + Math.floor(denominator);
  if (base) {
    amount = base + " " + amount;
  }
  return amount;
};

function fractionBounds(number, numerator, denominator) {
  var frac = denominator / numerator;
  return {
    lower: Math.floor(frac * number) / frac,
    upper: Math.ceil(frac * number) / frac,
  };
}

/*
If your number x falls between a1 and a2, and you would like y to fall between b1 and b2, 
you can apply the following linear transform:

  y = (x-a1)/(a2-a1) * (b2-b1) + b1
 */
function mapOneSetOfNumbersToAnother(a1, a2, b1, b2, x){
  return ((x - a1) / (a2 - a1)) * (b2 - b1) + b1;
}

export {
  cross,
  dot,
  det,
  toRadians,
  round2DArr,
  round3DArr,
  unit_normal,
  getNormalVector,
  getUnitNormalVectorV3,
  getUnitNormalVectorV3CyclicCheck,
  toFixedVector,
  roundPoint,
  roundVector,
  getOrientationPoints,
  getCentroidVectors,
  getMeshMedianY,
  makePathByGrahamScanAlgo,
  getStandardDeviation,
  average,
  areFloatsAlmostEqual,
  numberToFraction,
  fractionBounds,
  mapOneSetOfNumbersToAnother,
};
