import React from "react";

export default class ColourWheel extends React.Component {
  constructor(props) {
    super(props);
    this.options = {
      width: this.props.width || 200,
      height: this.props.width || 200,
      img: this.props.img || "../../assets/images/colorWheel.png",
    };
  }

  componentDidMount() {
    this.addImageInCanvas();
  }

  render() {
    return (
      <canvas
        onMouseUp={(e) => this.selectColor(e)}
        style={{ cursor: "crosshair", borderRadius: "100%" }}
        // eslint-disable-next-line react/no-string-refs
        ref="canvas"
        width={this.options.width}
        height={this.options.height}
      />
    );
  }

  addImageInCanvas() {
    // eslint-disable-next-line react/no-string-refs
    let { canvas } = this.refs;
    this.ctx = canvas.getContext("2d");
    let img = new Image();
    img.src = this.props.img;
    img.onload = () =>
      this.ctx.drawImage(img, 0, 0, this.options.width, this.options.height);
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  selectColor(e) {
    let { offsetX, offsetY } = e.nativeEvent;
    const { returnHex } = this.props;
    let { data } = this.ctx.getImageData(offsetX, offsetY, 1, 1);
    let color;
    if (returnHex) {
      color = this.rgbToHex(data[0], data[1], data[2]);
    } else {
      color = `rgb(${data[0]}, ${data[1]}, ${data[2]})`;
    }
    return this.props.callback(color);
  }
}
