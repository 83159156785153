import BABYLON from "../modules/babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  getRefGroundPoint,
  getBackWallPoint,
  axisSnapFactor,
  projectionOfPointOnLine,
  projectionOfPointOnFace,
} from "./snapFuncs.js";
import {
  checkOperationBIMFlowConditions,
  areTwoLinesCollinear,
  deepCopyObject,
  areThreeVectorsCollinear,
  getVertexCloserToPoint,
  areThreeVectorsCollinearIn2D,
  isPointOnThePlane,
  cloneEdge,
  getDistanceBetweenVectors,
  areVectorsParallel,
  getDistanceIn2DBetweenVectors,
  getVector2FromVector3,
  areEdgesParallel,
} from "../modules/extrafunc.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import {
  disposeAxisLine,
  showAxisLine,
  disposeAllAxisLines,
  showAxisIndicator,
  disposeHighlightedVertex,
  showVertexIndicator,
  disposeHightlightedEdge,
  showEdgeIndicator,
  disposeHighlightedFace,
  showFaceIndicator,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import {
  changeCheckPointFor2D,
  getDynamicThreshold,
  getTertiarySnapObject,
  getVertexComparator,
  storeEdgeInformation,
  areEdgesSame,
  getEdgeComparator,
  degreeToRadian,
  setAngleBetweenPoints, getAxisSnapConstraintDirection, isCADMesh, getIntersectionOfSnapPoints,
} from "./snapUtilities.js";
import {
  snapHorizontallyAndVertically,
  findTheNormalSnapPoint,
  findSnapPointPerpendicularToEdge,
  findTeleportationSnapPoint,
} from "./snapFuncsSecondary.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import {is2D, isTwoDimension} from "./twoDimension.js";
import { externalUtil } from "../modules/externalUtil.js";
import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import {
  findNearestVertexFromBRep,
  findNearestEdgeFromBRep,
  findNearestGeomEdgeFromBrep,
  getFaceVerticesFromFacetID,
} from "./brepOperations.js";
import { isSnaptrudePlaneMesh } from './snapUtilities';
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";


import { ScopeUtils } from "./scopeFunctions.js";
import { userSetBIMPropertiesHandler } from "../modules/utilityFunctions/CONSTANTS/userSetBimPropertiesHandler.js";
import movementConstrainer from "../modules/meshoperations/moveOperations/movementConstrainer";
import snapEngine from "../modules/snappingEngine/snapEngine";
/**
 *
 * options = {
 *     wantMetadata : true,
 *     firstIndicators : true,
 *     doPreClickAxisSnaps,
 *     disableTertiarySnaps,
 *     doTeleportSnap,
 *     operationSometimesLimitedToY,
 *     ignoreY,
 *     axisIndicatorExtensionNotRequired,
 *     doNotReturnUnsnappedPoint,
 *     disableDimensionSnap
 *
 *
 *     axis snap options-
 *       axisSnapPoint,
 *       restrictYAxisMovement,
 *       normal snap options-
 *        vertexMovement,
 *        edgeMovement,
 *        faceMovement,
 *
 *     tertiary snap options-
 *       tertiarySnapVertexArray,
 *       tertiarySnapEdgeArray,
 *       tertiaryEdgeSnapCallback : function
 *
 *     secondary snap options-
 *       vertexSnap,
 *       edgeSnap,
 *       faceSnap,
 *       material,
 *       indicatorType,
 *       storeyCheck,
 *       pickInfo,
 *       recursiveCall,
 *       secondPredicate : function,
 *       excludedMeshes,
 *       meshExclusionCriterion : function,
 *       lessStringent : false,
 *       parallelFaceSnap,
 *       doNotDoSecondaryScenePicks
 *       showRelatedEdges: boolean
 *        shows indicators for face edges if snapped to vertex/edge in 2D
 *       doNotShowIndicators: boolean
 *       attemptCadSnaps: boolean
 *
 *     angle snap options-
 *       rotationAxis
 *
 *     dimension snap options-
 *       dimensionSnapRefPointAdjuster : function,
 *       gridSnapEnabled
 *
 *     snappedToTwoAxes,
 *     snappedToEdge,
 *
 *     metadata : {
 *         axis,
 *         tertiarySnappedPointDash,
 *         tertiarySnappedPoint2Dash,
 *         snappedAngleValue,
 *         pickInfo,
 *
 *         indicator,
 *         snappedToVertex,
 *         snappedToCadVertex: boolean,
 *         snappedToEdge,
 *         snappedToEdgeMidpoint,
 *         snappedToFace,
 *         snappedToFaceParallely,
 *         snappedToFaceId,
 *         snappedSecondarily,
 *         secondarySnapType: vertex/edge/face,
 *         secondarySnappedPoint
 *         isTeleportSnapped: boolean,
 *
 *         teleportSnapAxis,
 *         storeEdge,
 *
 *         constrainedAxisSnap : string - x/y/z - if axis snap if because of user snap locks
 *         snappedPoint : the final snap point
 *     }
 *
 * }
 *
 *
 * @param startingPoint
 * @param axisPoint
 * @param meshOfInterest
 * @param options
 * @returns {null}
 */

function findPrioritizedSnapPoint(
  startingPoint,
  axisPoint,
  meshOfInterest,
  options = {}
) {
  /*
    Need to structure axis snaps better.
    Axis snaps can be identified by 2 points.
    findAxisSnappedPoint finds the x, y, z snaps (should remove normal snap from there
    or make it huge to include tertiary snaps. These are identified by startingPoint and
    the other end on the axis. But tertiarySnaps are identified by two points neither of
    which are startingPoint. If there's a structured way to represent them,
    intersection problems in secondarySnaps can be avoided.)
     */

  options.wantMetadata = true; //redesign - metadata will be available by default
  if (options.wantMetadata) {
    options.metadata = {};
  }

  if (!startingPoint) {
    if (options.firstIndicators === undefined) options.firstIndicators = true;
  }

  store.snappingGlobalVariables.snappedToTwoAxes = false;
  if (options.snappedToTwoAxes !== undefined)
    store.snappingGlobalVariables.snappedToTwoAxes = options.snappedToTwoAxes;

  options.snappedToTwoAxes = store.snappingGlobalVariables.snappedToTwoAxes;
  
  const axisSnapConstraintDirection = getAxisSnapConstraintDirection();
  
  if (
    options.parallelFaceSnap
    && !options.axisSnapPoint
    && axisSnapConstraintDirection === GLOBAL_CONSTANTS.strings.snaps.parallel
  ) {
    // used in move and array for moving drawn masses on angled walls with parallel snap locked (down arrow)
    options.axisSnapPoint = store.snappingGlobalVariables.sessionVariables.previousSecondarySnappedPoint;
    // options.axisSnapPoint = startingPoint;
  }
  
  if (options.parallelFaceSnap && axisSnapConstraintDirection === GLOBAL_CONSTANTS.strings.snaps.parallel){
    options.disableTertiarySnaps = true;
  }

  let groundPoint = getRefGroundPoint();
  if (!axisPoint && startingPoint)
    axisPoint = findAxisSnappedPoint(startingPoint, options);

  let tertiarySnappedPoint;
  if (
    (startingPoint || options.doPreClickAxisSnaps) &&
    !options.disableTertiarySnaps
  ) {
    tertiarySnappedPoint = findTertiarySnappedPoint(
      meshOfInterest,
      startingPoint,
      axisPoint,
      options
    );
  }

  axisPoint = tertiarySnappedPoint || axisPoint;

  if (options.operationSometimesLimitedToY) {
    if (
      !checkOperationBIMFlowConditions({
        operation: "finalChangesInXZ",
        mesh: meshOfInterest,
      })
    ) {
      options.faceSnap = false;
    }
  }

  let secondarySnappedPoint = findSecondarySnappedPoint(
    meshOfInterest,
    startingPoint,
    axisPoint,
    options
  );

  let toReturn;
  
  if (options.metadata.constrainedAxisSnap) {
    toReturn = axisPoint;
    if (secondarySnappedPoint){
      if (options.metadata.constrainedAxisSnap === GLOBAL_CONSTANTS.strings.snaps.x){
        toReturn.x = secondarySnappedPoint.x;
      }
      else if (options.metadata.constrainedAxisSnap === GLOBAL_CONSTANTS.strings.snaps.y){
        toReturn.y = secondarySnappedPoint.y;
      }
      else if (options.metadata.constrainedAxisSnap === GLOBAL_CONSTANTS.strings.snaps.z){
        toReturn.z = secondarySnappedPoint.z;
      }
      else if (options.metadata.constrainedAxisSnap === GLOBAL_CONSTANTS.strings.snaps.parallel){
        if (options.metadata.pickInfo && options.metadata.pickInfo.pickedMesh === meshOfInterest?.parent){
          store.snappingGlobalVariables.sessionVariables.previousSecondarySnappedPoint =
            options.metadata.pickInfo.pickedPoint;
        }
      }
    }
  }
  else {
    if (
      options.parallelFaceSnap &&
      options.metadata.pickInfo?.pickedMesh === meshOfInterest?.parent
    ) {
      store.snappingGlobalVariables.sessionVariables.previousSecondarySnappedPoint =
        options.metadata.pickInfo?.pickedPoint;
    }

    toReturn = secondarySnappedPoint || axisPoint;
  }

  const dimensionSnapStartPoint =
      startingPoint ||
      options.metadata.tertiarySnappedPointDash ||
      options.metadata.tertiarySnappedPoint2Dash;

  const dimensionSnapEndPoint = toReturn || groundPoint;

  if (dimensionSnapStartPoint && dimensionSnapEndPoint) {
    if (options.disableDimensionSnap){
      // already disabled, do nothing
    }
    else if (secondarySnappedPoint){
      if (options.metadata.snappedToFaceParallely){
        // dimension snap should happen in this case
        options.disableDimensionSnap = false;
      }
      else if (options.metadata.snappedToEdge){
        // do dimension snap only if line parallel to snapped edge
        options.disableDimensionSnap = !areEdgesParallel(options.metadata.snappedToEdge, {
          headPt: dimensionSnapEndPoint,
          tailPt: dimensionSnapStartPoint
        });
      }
      else {
        options.disableDimensionSnap = true;
      }
    }

    if (!options.disableDimensionSnap) {
      toReturn = findDimensionSnappedPoint(dimensionSnapStartPoint, dimensionSnapEndPoint, options);
    }
  }

  const twoAxisIndicatorsPresent =
    uiIndicatorsHandler.axisIndicator.getMetadata().getActiveIndicators()
      .length === 2;
  const noVertexIndicatorsPresent =
    uiIndicatorsHandler.vertexIndicator.getMetadata().getActiveIndicators()
      .length === 0;

  if (twoAxisIndicatorsPresent && noVertexIndicatorsPresent && axisPoint) {
    let indicator;
    if (startingPoint)
      indicator = uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator;
    else indicator = uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;

    uiIndicatorsHandler.vertexIndicator.show(axisPoint, null, {
      indicator,
    });
  }

  /* eslint-disable */
  if (options.doNotReturnUnsnappedPoint) {
  } else {
    /* eslint-enable */
    toReturn = toReturn || groundPoint;
  }

  if (options.operationSometimesLimitedToY) {
    if (
      !checkOperationBIMFlowConditions({
        operation: "finalChangesInXZ",
        mesh: meshOfInterest,
      })
    ) {
      disposeAxisLine("y");
      // disposeSnapToObjects();
      if (toReturn && startingPoint) {
        toReturn.x = startingPoint.x;
        toReturn.z = startingPoint.z;
      }
    }
  }

  if (toReturn) {
    options.metadata.snappedPoint = toReturn.clone();
    if (startingPoint) changeCheckPointFor2D(toReturn, startingPoint);
  }
  
  return toReturn;
}

function findAxisSnappedPoint(startingPoint, options) {
  if (!options) options = {};
  if (!options.metadata) options.metadata = {};
  let groundPoint = options.axisSnapPoint || getRefGroundPoint();
  let backWallPoint = options.axisSnapPoint || getBackWallPoint();

  let axisPoint = null;
  
  if (
    backWallPoint &&
    !store.$scope.isTwoDimension &&
    !options.restrictYAxisMovement
  ) {
    options.metadata.isBackwallPoint = true;
    let ySnappedPointObject = snapHorizontallyAndVertically(
      backWallPoint,
      startingPoint,
      true,
      options
    );
    if (ySnappedPointObject) {
      if (options.wantMetadata)
        options.metadata.axis = ySnappedPointObject.axis;
      showAxisLine(
        startingPoint,
        ySnappedPointObject.pt,
        ySnappedPointObject.axis,
        true,
        {extension: options.extension}
      );
      axisPoint = ySnappedPointObject.pt;
    }
  }

  if (groundPoint && !axisPoint) {
    options.metadata.isBackwallPoint = false;
    let xzSnappedPointObject = snapHorizontallyAndVertically(
      groundPoint,
      startingPoint,
      true,
      options
    );

    if (xzSnappedPointObject) {
      if (options.wantMetadata)
        options.metadata.axis = xzSnappedPointObject.axis;
      showAxisLine(
        startingPoint,
        xzSnappedPointObject.pt,
        xzSnappedPointObject.axis,
        true,
        {extension: options.extension}
      );
      axisPoint = xzSnappedPointObject.pt;
    } else {
      if (
        options.vertexMovement ||
        options.edgeMovement ||
        options.faceMovement
      ) {
        axisPoint = findTheNormalSnapPoint(
          startingPoint,
          groundPoint,
          axisSnapFactor / 3,
          options
        );
        if (axisPoint) {
          if (options.wantMetadata)
            options.metadata.axis = GLOBAL_CONSTANTS.strings.snaps.normal;
          showAxisLine(
            startingPoint,
            axisPoint,
            GLOBAL_CONSTANTS.strings.snaps.normal,
            true,
            {extension: options.extension}
          );
        } else {
          disposeAllAxisLines();
        }
      } else {
        disposeAllAxisLines();
      }
    }
  }
  
  return axisPoint;
}

function findTertiarySnappedPoint(
  meshOfInterest,
  startingPoint,
  axisPoint,
  options = {}
) {

  const _changeCheckPointFor2D = function (point) {
    changeCheckPointFor2D(point, checkPoint);
  };

  const _edgeSnapExecutor = function (snapObject) {
    if (tertiarySnappedPoint) {
      //checking if two snaps is possible
      if (
        areTwoLinesCollinear(
          snapObject.snapPoint.subtract(snapObject.snapPointDash),
          tertiarySnappedPointDash.subtract(tertiarySnappedPoint)
        )
      )
        return;

      let intersection = getIntersectionOfSnapPoints(
        [
          snapObject.snapPointDash,
          snapObject.snapPoint,
          tertiarySnappedPointDash,
          tertiarySnappedPoint,
        ],
        { type: "vector-vector", ignoreY: options.ignoreY }
      );

      if (intersection) {
        if (options.ignoreY) intersection.y = tertiarySnappedPoint.y;
        tertiarySnappedPoint = intersection;
        tertiarySnappedPoint2Dash = snapObject.snapPointDash;
        if (snapObject.snapType) {
          showAxisIndicator(tertiarySnappedPoint, snapObject.snapPointDash, {
            indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
            extensionNotRequired: options.axisIndicatorExtensionNotRequired,
            axisType: snapObject.snapType,
          });
        }

        uiIndicatorsHandler.axisIndicator.alignAxis1Indicator(intersection);
        store.snappingGlobalVariables.snappedToTwoAxes = true;
        options.snappedToTwoAxes = true;
      }
      /*else {

            }*/
    } else {
      if (axisPoint) {
        if (
          areTwoLinesCollinear(
            snapObject.snapPoint.subtract(snapObject.snapPointDash),
            startingPoint.subtract(axisPoint)
          )
        )
          return;

        let intersection = getIntersectionOfSnapPoints(
          [
            snapObject.snapPointDash,
            snapObject.snapPoint,
            startingPoint,
            axisPoint,
          ],
          { type: "vector-vector", ignoreY: options.ignoreY }
        );

        if (intersection) {
          if (options.ignoreY) intersection.y = axisPoint.y;
          tertiarySnappedPoint = intersection;
          tertiarySnappedPointDash = snapObject.snapPointDash;
          if (snapObject.snapType) {
            showAxisIndicator(tertiarySnappedPoint, snapObject.snapPointDash, {
              indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
              extensionNotRequired: options.axisIndicatorExtensionNotRequired,
              axisType: snapObject.snapType,
            });
          }

          uiIndicatorsHandler.axisIndicator.alignAxis1Indicator(intersection);
          store.snappingGlobalVariables.snappedToTwoAxes = true;
          options.snappedToTwoAxes = true;
        } /*else {

                }*/
      } else {
        tertiarySnappedPoint = snapObject.snapPoint;
        tertiarySnappedPointDash = snapObject.snapPointDash;

        if (snapObject.snapType) {
          showAxisIndicator(tertiarySnappedPoint, snapObject.snapPointDash, {
            indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis1,
            extensionNotRequired: options.axisIndicatorExtensionNotRequired,
            axisType: snapObject.snapType,
          });
        }
      }
    }
  }; 

  const util = new ResolveEngineUtils();

  const parallelSnapType = GLOBAL_CONSTANTS.strings.snaps.parallel;
  const orthogonalSnapType = GLOBAL_CONSTANTS.strings.snaps.orthogonal;
  const normalSnapType = GLOBAL_CONSTANTS.strings.snaps.normal;

  const parallelSnapEnabled = store.projectProperties.properties.parallelSnapEnabled.getValue();
  const orthogonalSnapEnabled = store.projectProperties.properties.orthogonalSnapEnabled.getValue();
  
  const pastEdges =
    options.tertiarySnapEdgeArray ||
    store.snappingGlobalVariables.lastSnappedEdgeArray;
  const pastVertices =
    options.tertiarySnapVertexArray ||
    store.snappingGlobalVariables.lastSnappedVertexArray;

  let tertiarySnappedPoint = null;
  let tertiarySnappedPointDash = null;
  let tertiarySnappedPoint2Dash = null;

  let checkPoint =
     axisPoint || options.axisSnapPoint || getRefGroundPoint();
  
  if (!checkPoint) return;
  else checkPoint = checkPoint.clone();

  // const adjustedThreshold = getDynamicThreshold(checkPoint);

  const simultaneousSnapPoints = [];

  for (let edge of pastEdges) {
    if (options.snappedToTwoAxes) break;

    edge = cloneEdge(edge);

    _changeCheckPointFor2D(edge.headPt);
    _changeCheckPointFor2D(edge.tailPt);

    if (
      axisPoint &&
      areThreeVectorsCollinear(edge.headPt, edge.tailPt, axisPoint)
    )
      continue;
    //prevents jittery effect when axis and edge are collinear

    let snapObject = getTertiarySnapObject();

    if (_.isEmpty(simultaneousSnapPoints)) {
      let projectionPoint = projectionOfPointOnLine(
        checkPoint,
        edge.headPt,
        edge.tailPt
      );

      if (
        parallelSnapEnabled &&
        snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(projectionPoint) &&
        !util.onSegment3D(edge.headPt, projectionPoint, edge.tailPt)
      ) {

        snapObject.snapPoint = projectionPoint;

        const closerVertexToProjection = getVertexCloserToPoint(
          edge,
          projectionPoint
        );

        snapObject.snapPointDash = closerVertexToProjection;
        snapObject.snapType = parallelSnapType;
      } else {
        // edge far away, parallel snap not happening, check normal snap
        let { normalSnapPoint, firstSnapPoint } =
          findSnapPointPerpendicularToEdge(
            checkPoint,
            edge.headPt,
            edge.tailPt
          );
        if (normalSnapPoint) {
          snapObject.snapPoint = normalSnapPoint;
          snapObject.snapPointDash = firstSnapPoint;
          snapObject.snapType = normalSnapType;
        } else if (!options.tertiaryEdgeSnapCallback) {
          continue;
        }

        if (options.tertiaryEdgeSnapCallback) {
          let snapObjectArray = options.tertiaryEdgeSnapCallback(
            checkPoint,
            edge,
            options
          );

          let primarySnapObject = null;
          if (snapObjectArray.length === 0) {
            if (!normalSnapPoint) continue;
          } else {
            if (normalSnapPoint) {
              simultaneousSnapPoints.push(...snapObjectArray);
            } else {
              primarySnapObject = snapObjectArray[0];

              if (snapObjectArray.length > 1) {
                snapObjectArray.shift();
                simultaneousSnapPoints.push(...snapObjectArray);
              }

              snapObject = primarySnapObject;
            }
          }
        }
      }
    } else {
      snapObject = simultaneousSnapPoints.pop();
    }

    if (!snapObject) continue;

    _edgeSnapExecutor(snapObject);
    if (!_.isEmpty(simultaneousSnapPoints) && !options.snappedToTwoAxes)
      _edgeSnapExecutor(simultaneousSnapPoints.pop());
  }

  for (let vertex of pastVertices) {
    if (!orthogonalSnapEnabled) break;
    if (options.snappedToTwoAxes) break;

    vertex = vertex.clone();
    _changeCheckPointFor2D(vertex);

    let vertexSnapPoint;
    let snappedPointObject = snapHorizontallyAndVertically(checkPoint, vertex);
    if (snappedPointObject) {
      vertexSnapPoint = snappedPointObject.pt;
    } else {
      continue;
    }

    if (tertiarySnappedPoint) {
      if (
        areTwoLinesCollinear(
          vertex.subtract(vertexSnapPoint),
          tertiarySnappedPointDash.subtract(tertiarySnappedPoint)
        )
      )
        continue;

      let intersection = getIntersectionOfSnapPoints(
        [
          vertex,
          vertexSnapPoint,
          tertiarySnappedPointDash,
          tertiarySnappedPoint,
        ],
        { type: "vector-vector", ignoreY: options.ignoreY }
      );

      if (intersection) {
        if (options.ignoreY) intersection.y = tertiarySnappedPoint.y;
        tertiarySnappedPoint = intersection;
        tertiarySnappedPoint2Dash = vertex;
        showAxisIndicator(tertiarySnappedPoint, vertex, {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
          extensionNotRequired: options.axisIndicatorExtensionNotRequired,
          axisType: orthogonalSnapType,
        });

        uiIndicatorsHandler.axisIndicator.alignAxis1Indicator(intersection);
        store.snappingGlobalVariables.snappedToTwoAxes = true;
        options.snappedToTwoAxes = true;
        break;
      }
    } else {
      if (axisPoint) {
        if (
          areTwoLinesCollinear(
            vertex.subtract(vertexSnapPoint),
            startingPoint.subtract(axisPoint)
          )
        )
          continue;

        let intersection = getIntersectionOfSnapPoints(
          [vertex, vertexSnapPoint, startingPoint, axisPoint],
          { type: "vector-vector", ignoreY: options.ignoreY }
        );

        if (intersection) {
          if (options.ignoreY) intersection.y = axisPoint.y;
          tertiarySnappedPoint = intersection;
          tertiarySnappedPointDash = vertex;
          showAxisIndicator(tertiarySnappedPointDash, tertiarySnappedPoint, {
            indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
            extensionNotRequired: options.axisIndicatorExtensionNotRequired,
            axisType: orthogonalSnapType,
          });

          uiIndicatorsHandler.axisIndicator.alignAxis1Indicator(intersection);
          store.snappingGlobalVariables.snappedToTwoAxes = true;
          options.snappedToTwoAxes = true;
          break;
        }
      } else {
        tertiarySnappedPoint = vertexSnapPoint;
        tertiarySnappedPointDash = vertex;
        showAxisIndicator(tertiarySnappedPointDash, tertiarySnappedPoint, {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis1,
          extensionNotRequired: options.axisIndicatorExtensionNotRequired,
          axisType: orthogonalSnapType,
        });
      }
    }
  }

  options.metadata.tertiarySnappedPointDash = tertiarySnappedPointDash;
  options.metadata.tertiarySnappedPoint2Dash = tertiarySnappedPoint2Dash;

  return tertiarySnappedPoint;
}

function findSecondarySnappedPoint(
  meshOfInterest,
  startingPoint,
  axisPoint,
  options = {}
) {
  options.wantMetadata = true; //redesign - metadata will be available by default
  if (!options.metadata) options.metadata = {};
  
  if (movementConstrainer.isActive()){
    return movementConstrainer.getSecondarySnappedPoint(startingPoint, axisPoint, options);
  }

  const util = new ResolveEngineUtils();
  const refGroundPoint = getRefGroundPoint();

  let vertexSnap = true;
  let edgeSnap = true;
  let faceSnap = !store.$scope.isTwoDimension;
  if (options.vertexSnap !== undefined) vertexSnap = options.vertexSnap;
  if (options.edgeSnap !== undefined) edgeSnap = options.edgeSnap;
  if (options.faceSnap !== undefined) faceSnap = options.faceSnap;
  
  if (!startingPoint) options.firstIndicators = true;

  let material =
    options.material || colorUtil.getMaterial(colorUtil.type.postSnap);
  let indicatorType = options.indicatorType;
  if (indicatorType === undefined) {
    if (options.firstIndicators){
      indicatorType =
        uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;
      material = colorUtil.getMaterial(colorUtil.type.preSnap);
    }
    else
      indicatorType =
        uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator;
  }
  
  
  let vertexName, edgeName, faceName;
  if (options.firstIndicators) {
    vertexName = "vertexBox";
    edgeName = "edgeBox";
    faceName = "faceBox";
  } else {
    vertexName = "vertexBox2";
    edgeName = "edgeBox2";
    faceName = "faceBox2";
  }

  let secondarySnappedPoint = null;
  let pickInfo = options.pickInfo;

  if (!options.excludedMeshes) options.excludedMeshes = [];
  if (!pickInfo) {
    let secondPredicate, secondPickInvisibleMeshes = false;

    if (options.snappedToEdge && !options.recursiveCall) {
      secondPredicate = (mesh) => mesh === meshOfInterest;
    } else {
      if (!options.secondPickRequest) options.secondPickRequest = {};
      if (_.isBoolean(options.secondPickRequest.pickInvisibleMeshes))
          secondPickInvisibleMeshes = options.secondPickRequest.pickInvisibleMeshes;

      secondPredicate = options.secondPickRequest.predicate;
    }

    let optionsForPick = {
      doAllOfThem: false,
      doSimplePick: false,
      ignoreLowerStorey : null // populated below
    };

    /*
        In scenePickController, ignoreLowerStorey by default would be true.
        But since this function is used for both init and post init snapping
        in both the first and the recursive snap, behaviour would differ

        init snapping - ignoreLowerStorey would be true everywhere except places like draw
        post init - ignoreLowerStorey should be false

        Below logic hasn't been implemented
        **********
        In a case where a mass m2 in storey 2 lies on top of a mass m1 in storey 1 while exposing a part of it,
        if I go towards m2, even if I'm on top of m1, m2 should be picked because priority.

        If ignoreLowerStorey isn't set differently based on options.recursiveCall, m1 will always get
        picked because compoundPick first checks with simple pick.

        So, ignoreLowerStorey would be false only during recursive calls
        **********

         */

    if (options.firstIndicators){
      optionsForPick.ignoreLowerStorey = options.ignoreLowerStorey ?? true;
    }
    else {
        optionsForPick.ignoreLowerStorey = false;
    }

    if (!options.firstIndicators && !store.$scope.isTwoDimension){
        // optionsForPick.doSimplePick = true;
    }

    if (options.recursiveCall) {
      // optionsForPick.doSimplePick = true;
      // why do compound pick here
      // why not
      
      optionsForPick.ignoreLowerStorey = true;
    }
    
    const requests = [
      {
        predicate: function (mesh) {
          if (options.excludedMeshes.includes(mesh)) return false;
          if (mesh === meshOfInterest) return false;

          if (isSnaptrudePlaneMesh(mesh)) return false;
          if (options.considerOnlySelectedMeshes) {
            if (!_.isEmpty(store.selectionStack) && !store.selectionStack.includes(mesh)) return false;
          }

          return true;
        },
        pickInvisibleMeshes: false,
        pickNeighborhoodMeshes: options.pickNeighborhoodMeshes,
      },
    ];

    if (secondPredicate) requests.push({
        predicate : mesh => {
          if (options.considerOnlySelectedMeshes) {
            if (!_.isEmpty(store.selectionStack) && !store.selectionStack.includes(mesh)) return false;
          }
          return secondPredicate(mesh);
        },
        pickInvisibleMeshes : secondPickInvisibleMeshes
    });

    // pickInfo = scene.sequentialPick(requests.map(r => r.predicate), optionsForPick);
    pickInfo = scenePickController.sequentialPick(requests, optionsForPick);
  }
  
  let cadSnappedPoint, cadPickInfo;
  if (!options.recursiveCall && options.attemptCadSnaps) {
    const cadSnapData = snapEngine.cadSnaps.getSnapPoint(meshOfInterest, pickInfo);
    if (cadSnapData.cadSnappedPoint) {
      cadSnappedPoint = cadSnapData.cadSnappedPoint;
      cadPickInfo = cadSnapData.cadPickInfo;
      if (!pickInfo.hit) pickInfo = cadPickInfo;
    }
  }

  if (pickInfo.hit) {
    if (pickInfo.secondaryPick && !store.$scope.isTwoDimension) {
      // edgeSnap = false;
      faceSnap = false;
    }
    if (options.wantMetadata) options.metadata.pickInfo = pickInfo;
    _vertexSnap(cadSnappedPoint, cadPickInfo);

    _handleOverlappingMasses();
  }

  _handleTeleportInformation();

  if (secondarySnappedPoint) {
    options.metadata.snappedSecondarily = true;
  }

  return secondarySnappedPoint;

  function _vertexSnap(cadSnappedPoint, cadPickInfo) {
    if (!vertexSnap) {
      _edgeSnap();
      return;
    }

    let vertex, actualVertex;
    let optionsForVertex = {};
    
    if (cadSnappedPoint) {
      if (isCADMesh(pickInfo.pickedMesh)) {
        // only cad mesh in the vicinity
        vertex = cadSnappedPoint;
      }
      else {
        // cad vertex present, determine if it's the best
        const otherVertex = findNearestVertexFromBRep(
          pickInfo,
          BABYLON.Space.WORLD,
          optionsForVertex
        );
        
        if (otherVertex){
          const d1 = getDistanceBetweenVectors(cadSnappedPoint, refGroundPoint);
          const d2 = getDistanceBetweenVectors(otherVertex, refGroundPoint);
          
          if (d1 < d2) {
            vertex = cadSnappedPoint;
            pickInfo.pickedPoint = cadPickInfo.pickedPoint;
            pickInfo.pickedMesh = cadPickInfo.pickedMesh;
            optionsForVertex = {};
          }
          else {
            vertex = otherVertex;
          }
        }
        else {
          vertex = cadSnappedPoint;
          pickInfo.pickedPoint = cadPickInfo.pickedPoint;
          pickInfo.pickedMesh = cadPickInfo.pickedMesh;
          optionsForVertex = {};
        }
        
      }
    }
    else {
      // no cad vertex, see if there's anything else
      vertex = findNearestVertexFromBRep(
        pickInfo,
        BABYLON.Space.WORLD,
        optionsForVertex
      );
    }
    

    if (vertex) {
      const _snapAndShowVertexIndicator = function () {
        const _storeInformation = function (vertex) {
          if (
            !store.snappingGlobalVariables.lastSnappedVertexArray.inArray(
              getVertexComparator(vertex)
            )
          ) {
            store.snappingGlobalVariables.lastSnappedVertexArray.unshift(
              vertex.clone()
            );
            // latest vertex gets highest snap priority

            if (
              store.snappingGlobalVariables.lastSnappedVertexArray.length >
              store.snappingGlobalVariables.vertexMemorySize
            ) {
              store.snappingGlobalVariables.lastSnappedVertexArray.pop();
              //remove last edge of low priority
            }

            if (optionsForVertex.faceNeighbours) {
              const neighboringEdge1 = {
                headPt: optionsForVertex.faceNeighbours[0],
                tailPt: vertex,
              };

              const neighboringEdge2 = {
                headPt: optionsForVertex.faceNeighbours[1],
                tailPt: vertex,
              };

              storeEdgeInformation(neighboringEdge1);
              storeEdgeInformation(neighboringEdge2);
            }
          }
        };

        secondarySnappedPoint = vertex;
        
        let indicator;
        if (!options.doNotShowIndicators){
          if (options.recursiveCall) disposeHighlightedVertex(vertexName);

          indicator = showVertexIndicator(vertex, pickInfo.pickedMesh, {
            indicator: indicatorType,
          });
          
          if (options.showRelatedEdges && optionsForVertex.faceVertices){
            uiIndicatorsHandler.edgeIndicator.massDestruct();
            uiIndicatorsHandler.edgeIndicator.massConstruct(
              optionsForVertex.faceVertices
            );
          }
        }

        if (options.wantMetadata) {
          options.metadata.snappedToVertex = vertex;
          options.metadata.indicator = indicator;
          options.metadata.secondarySnapType = GLOBAL_CONSTANTS.strings.snaps.vertex;
          options.metadata.snappedToCadVertex = isCADMesh(pickInfo.pickedMesh);
          options.metadata.secondarySnappedPoint = actualVertex;
        }

        _storeInformation(vertex.clone());
      };

      if (options.snappedToEdge && pickInfo.pickedMesh === meshOfInterest) {
        //snaps on the active mesh itself now
        if (
          options.snappedToEdge.headPt.almostEquals(vertex) ||
          options.snappedToEdge.tailPt.almostEquals(vertex)
        ) {
          //prevents snapping of edge being edited to its vertices
          _edgeSnap();
          return;
        }
      }

      actualVertex = vertex.clone();

      if (axisPoint) {
        let threshold = 0.001;
        if (options.lessStringent) threshold = 0.1;
        if (options.ignoreY) {
          vertex.y = axisPoint.y;
        }
        let otherEndOfAxis =
          options.metadata.tertiarySnappedPointDash || startingPoint;
        
        if (options.snappedToTwoAxes) {
          // will not try to do dual axis snap + vertex snap (should I?)
          // vertex snap gets precedence over axis snap
          
          _snapAndShowVertexIndicator();
          _resetTertiarySnapData();
          _disposeAxisIndicators();
        }
        else if (otherEndOfAxis){
          let projection = projectionOfPointOnLine(
            vertex,
            otherEndOfAxis,
            axisPoint
          );
          
          const d = BABYLON.Vector3.Distance(vertex, projection);
          if (d < threshold) {
            // maintain axis snap and do vertex snap
            _snapAndShowVertexIndicator();
          } else {
            // break out of axis snap and do vertex snap
            _snapAndShowVertexIndicator();
            _resetTertiarySnapData();
            _disposeAxisIndicators();
          } /*else {
            // maintain axis snap and attempt edge snap
            _edgeSnap();
          }*/
        }

        /*if (
          BABYLON.Vector3.Distance(vertex, projection) < threshold &&
          !options.snappedToTwoAxes
        ) {
          _snapAndShowVertexIndicator();
        } else if (
          BABYLON.Vector3.Distance(vertex, projection) > 10 * threshold ||
          options.snappedToTwoAxes
        ) {
          _snapAndShowVertexIndicator();
          _disposeAxisIndicators();
        } else {
          _edgeSnap();
        }*/
      } else {
        _snapAndShowVertexIndicator();
      }
    } else {
      _edgeSnap();
    }
  }
  
  function _resetTertiarySnapData(){
    options.metadata.tertiarySnappedPointDash = null;
    options.metadata.tertiarySnappedPoint2Dash = null;
  }
  
  function _disposeAxisIndicators(){
    if (!options.metadata.constrainedAxisSnap) disposeAllAxisLines();
  }

  function _edgeSnap() {
    if (!edgeSnap) {
      _faceSnap();
      return;
    }

    const optionsForEdgeSnap = {
      usePointerLocation: true,
      faceVertices:[],
      edgeId:null,
      edgeVertices:[],
    };

    if (options.snappedToTwoAxes){
      optionsForEdgeSnap.axisSnapPoint = axisPoint;
    }

    let edge = findNearestEdgeFromBRep(pickInfo, BABYLON.Space.WORLD, optionsForEdgeSnap);
    let edgeArray = findNearestGeomEdgeFromBrep(edge, pickInfo.pickedMesh);
    if (edge) {
      const _snapAndShowEdgeIndicator = function (storeEdge = true) {
        const _handleTeleportation = function () {
          const _findIntersection = function () {
            let teleportSnapPoint = findTeleportationSnapPoint(
              startingPoint,
              options
            );
            if (teleportSnapPoint) {
              secondarySnappedPoint = teleportSnapPoint;
              store.snappingGlobalVariables.sessionVariables.activeTeleportEdge = cloneEdge(edge);
              
              options.metadata.isTeleportSnapped = true;

              if (
                !util.onSegment2D(edge.headPt, teleportSnapPoint, edge.tailPt)
              ) {
                const closerVertex = getVertexCloserToPoint(
                  edge,
                  teleportSnapPoint
                );

                showAxisIndicator(closerVertex, teleportSnapPoint, {
                  indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis1,
                  extensionNotRequired:
                    options.axisIndicatorExtensionNotRequired,
                  axisType: GLOBAL_CONSTANTS.strings.snaps.parallel,
                });

                showAxisIndicator(startingPoint, teleportSnapPoint, {
                  indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
                  extensionNotRequired:
                    options.axisIndicatorExtensionNotRequired,
                  axisType: options.metadata.teleportSnapAxis,
                });

                uiIndicatorsHandler.vertexIndicator.show(
                  teleportSnapPoint,
                  null,
                  {
                    indicator:
                      uiIndicatorsHandler.vertexIndicator.TYPES
                        .postSnapIndicator,
                  }
                );
              } else {
                uiIndicatorsHandler.axisIndicator.remove();

                uiIndicatorsHandler.vertexIndicator.remove(
                  uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator
                );
                // remove the midpoint snap point, in case snap is teleported after going near midpoint
              }
            }
          };

          if (
            store.snappingGlobalVariables.sessionVariables.activeTeleportEdge
          ) {
            if (
              areEdgesSame(
                store.snappingGlobalVariables.sessionVariables
                  .activeTeleportEdge,
                edge
              )
            ) {
              /* eslint-disable */
              if (
                areThreeVectorsCollinearIn2D(
                  edge.headPt,
                  edge.tailPt,
                  startingPoint
                )
              ) {
              } else {
                /* eslint-enable */
                _findIntersection();
              }
            }
          } else if (options.doTeleportSnap && !options.metadata.constrainedAxisSnap) {
            if (
              store.$scope.isTwoDimension &&
              startingPoint &&
              // && !axisPoint
              !store.snappingGlobalVariables.sessionVariables.excludedTeleportEdges.inArray(
                getEdgeComparator(edge)
              )
            ) {
              _findIntersection();
            }
          }
        };

        let indicator;
        if (!options.doNotShowIndicators){
          if (options.recursiveCall) disposeHightlightedEdge(edgeName);
          indicator = showEdgeIndicator(edge, pickInfo.pickedMesh, {
            indicator: indicatorType,
          });
          
          if(edgeArray)
          {
            uiIndicatorsHandler.edgeIndicator.curveIndicatorDestruct();
            let CurvPtsArr = [];
            CurvPtsArr.push(edgeArray);
            uiIndicatorsHandler.edgeIndicator.curveIndicatorConstruct(
              CurvPtsArr,
            [],
            {
              indicator: indicatorType,
              showMassEdge: true,
            }
          );
          }
          if (options.showRelatedEdges && optionsForEdgeSnap.faceVertices){
            const exclusions = [edge.headPt, edge.tailPt];
            
            uiIndicatorsHandler.edgeIndicator.massDestruct();
            uiIndicatorsHandler.edgeIndicator.massConstruct(
              optionsForEdgeSnap.faceVertices,
              exclusions
            );
          }
          
          const edgeMidPoint = BABYLON.Vector3.Center(edge.headPt, edge.tailPt);
        
          // const dynamicThreshold = getDynamicThreshold(secondarySnappedPoint);
          // const d = BABYLON.Vector3.Distance(edgeMidPoint, secondarySnappedPoint);
          let dynamicThreshold = snapEngine.snapVerifier.getScreenSpaceThreshold();
          const d = snapEngine.snapVerifier.getScreenSpaceDistanceBetweenVectors(edgeMidPoint, secondarySnappedPoint);

          if (axisPoint) dynamicThreshold = 0.1 * dynamicThreshold;
          
          if (d < dynamicThreshold) {
            secondarySnappedPoint = edgeMidPoint;
            options.metadata.snappedToEdgeMidpoint = true;
            
            uiIndicatorsHandler.vertexIndicator.show(
              secondarySnappedPoint,
              pickInfo.pickedMesh,
              {
                indicator: indicatorType,
              }
            );
          }
        }
        
        
        if (options.wantMetadata) {
          options.metadata.storeEdge = storeEdge;
          options.metadata.snappedToEdge = edge; //tertiary snap kicks in without this
          options.metadata.indicator = indicator;
          options.metadata.secondarySnapType = GLOBAL_CONSTANTS.strings.snaps.edge;
          options.metadata.secondarySnappedPoint = projectionOfPointOnLine(
            secondarySnappedPoint,
            edge.headPt,
            edge.tailPt,
          );
        }

        if (storeEdge) storeEdgeInformation(edge);
        _handleTeleportation();
      };

      if (options.snappedToEdge && pickInfo.pickedMesh === meshOfInterest) {
        //snaps on the active mesh itself now
        if (
          options.snappedToEdge.headPt.almostEquals(edge.headPt) ||
          options.snappedToEdge.headPt.almostEquals(edge.tailPt) ||
          options.snappedToEdge.tailPt.almostEquals(edge.headPt) ||
          options.snappedToEdge.tailPt.almostEquals(edge.tailPt)
        ) {
          //prevents snapping of edge being edited to itself
          _faceSnap();
          return;
        }
      }

      let ignoreY = store.$scope.isTwoDimension;
      if (options.ignoreY) ignoreY = options.ignoreY;

      let tertiaryPointDashOfInterest = null;
      let tubelightEffectPoint =
        startingPoint ||
        options.metadata.tertiarySnappedPointDash ||
        options.metadata.tertiarySnappedPoint2Dash;

      if (axisPoint && tubelightEffectPoint) {
        const _checkIfCollinear = function (point) {
          let checkFunction;
          if (ignoreY) checkFunction = areThreeVectorsCollinearIn2D;
          else checkFunction = areThreeVectorsCollinear;

          return checkFunction(edge.headPt, edge.tailPt, point);
        };

        const _disposeStuff = function () {
          axisPoint = null;
          _disposeAxisIndicators();
          options.metadata.snappedAngleValue = null;
        };

        const pointsArray = _.compact([
          startingPoint,
          options.metadata.tertiarySnappedPointDash,
          options.metadata.tertiarySnappedPoint2Dash,
        ]);

        pointsArray.some((checkPoint, i) => {
          if (_checkIfCollinear(checkPoint)) {
            if (i === pointsArray.length - 1) {
              // all the points are collinear
              _disposeStuff();
            }
          } else {
            tertiaryPointDashOfInterest = checkPoint;
            return true;
          }
        });
      }
      //prevents jittery effect when axis and edge are collinear

      if (axisPoint) {
        let otherEndOfAxis = tertiaryPointDashOfInterest;
        
        let intersection;

        if (otherEndOfAxis){
          intersection = getIntersectionOfSnapPoints(
            [edge.headPt, edge.tailPt, otherEndOfAxis, axisPoint],
            { type: "vector-vector", ignoreY }
          );
        }

        if (intersection) {
          let util = new ResolveEngineUtils();

          if (ignoreY) intersection.y = otherEndOfAxis.y;

          if (options.snappedToTwoAxes) {
            options.metadata.snappedAngleValue = null;
          }

          if (util.onSegment3D(edge.headPt, intersection, edge.tailPt)) {
            secondarySnappedPoint = intersection;
            _snapAndShowEdgeIndicator();
          } else {
            if (ignoreY) {
              //snaps to lower storey edges
              if (util.onSegment2D(edge.headPt, intersection, edge.tailPt)) {
                secondarySnappedPoint = intersection;
                _snapAndShowEdgeIndicator();
              } else {
                let projection = projectionOfPointOnLine(
                  pickInfo.pickedPoint,
                  edge.headPt,
                  edge.tailPt
                );
                projection.y = otherEndOfAxis.y;
                secondarySnappedPoint = projection;
                _snapAndShowEdgeIndicator(false);
                _disposeAxisIndicators();
                _resetTertiarySnapData();
                options.metadata.snappedAngleValue = null;
              }
            } else {
              let projection = projectionOfPointOnLine(
                pickInfo.pickedPoint,
                edge.headPt,
                edge.tailPt
              );
              projection.y = otherEndOfAxis.y;
              secondarySnappedPoint = projection;
              _snapAndShowEdgeIndicator(false);
              _disposeAxisIndicators();
              _resetTertiarySnapData();
              options.metadata.snappedAngleValue = null;
            }
          }
        } else if (faceSnap) {
          //go to face snap
          _faceSnap();
        } else {
          // no intersection but hovering over edge, so snap should be released
          let projection = projectionOfPointOnLine(
            pickInfo.pickedPoint,
            edge.headPt,
            edge.tailPt
          );
          if (ignoreY && otherEndOfAxis) projection.y = otherEndOfAxis.y;
          secondarySnappedPoint = projection;
          _snapAndShowEdgeIndicator(false);
          _disposeAxisIndicators();
          _resetTertiarySnapData();
          options.metadata.snappedAngleValue = null;
        }
      } else {
        // secondarySnappedPoint = projectionOfPointOnLine(groundPoint, edge.headPt, edge.tailPt);
        secondarySnappedPoint = projectionOfPointOnLine(
          pickInfo.pickedPoint,
          edge.headPt,
          edge.tailPt
        );

        _snapAndShowEdgeIndicator();
      }
    } else {
      _faceSnap();
    }
  }

  function _faceSnap() {
    if (!faceSnap) {
      return;
    }

    const optionsForFaceSnap = {};
    let face = getFaceVerticesFromFacetID(
      pickInfo.faceId,
      pickInfo.pickedMesh,
      BABYLON.Space.WORLD,
      optionsForFaceSnap
    );
    if (face) {
      const _snapAndShowFaceIndicator = function () {
        let indicator;
        if (!options.doNotShowIndicators){
          if (options.recursiveCall) disposeHighlightedFace(faceName);
          let facetId = pickInfo.faceId;
          indicator = showFaceIndicator(
            face,
            facetId,
            pickInfo.pickedMesh,
            faceName,
            material,
          );
        }
        
        if (options.wantMetadata) {
          options.metadata.snappedToFace = face;
          options.metadata.snappedToFaceId = optionsForFaceSnap.faceId;
          options.metadata.indicator = indicator;
          options.metadata.secondarySnapType = GLOBAL_CONSTANTS.strings.snaps.face;
          options.metadata.secondarySnappedPoint = secondarySnappedPoint;
        }
      };

      let plane = BABYLON.Plane.FromPoints(face[0], face[1], face[2]);

      if (axisPoint) {
        let otherEndOfAxis =
          options.metadata.tertiarySnappedPointDash || startingPoint;
        let intersection;

        if (
          isPointOnThePlane(otherEndOfAxis, plane) &&
          isPointOnThePlane(axisPoint, plane)
        ) {
          options.metadata.snappedToFaceParallely = true;
          intersection = axisPoint;
        } else {
          intersection = getIntersectionOfSnapPoints(
            [otherEndOfAxis, axisPoint, plane],
            { type: "vector-plane" }
          );
        }

        if (intersection) {
          if (BABYLON.Vector3.Distance(intersection, axisPoint) < 1) {
            secondarySnappedPoint = intersection;
            _snapAndShowFaceIndicator();
          }
          else {
            secondarySnappedPoint = pickInfo.pickedPoint;
            uiIndicatorsHandler.axisIndicator.remove();
            _snapAndShowFaceIndicator();
          }
        }
      } else if (options.firstIndicators) {
        secondarySnappedPoint = pickInfo.pickedPoint;
        _snapAndShowFaceIndicator();
      } else if (Math.abs(plane.signedDistanceTo(startingPoint)) > 0.25) {
        //when one of two parallel faces are being edited, snap to other face is a problem, makes no functional sense.
        //This condition bypasses that
        secondarySnappedPoint = pickInfo.pickedPoint;
        _snapAndShowFaceIndicator();
      } else if (options.parallelFaceSnap) {
        // For some operations like drawMass the face snap
        // should happen even when startingPoint lies on the face
        // condition above wouldn't apply

        // TODO - Document this crap. It's bloating up quickly

        secondarySnappedPoint = pickInfo.pickedPoint;
        options.metadata.snappedToFaceParallely = true;
        _snapAndShowFaceIndicator();
      }
    } // else if (options.firstIndicators) {
    else {
      secondarySnappedPoint = pickInfo.pickedPoint;
      
      if (axisPoint){
        const distance = getDistanceBetweenVectors(axisPoint, secondarySnappedPoint);
        if (distance < 1) secondarySnappedPoint = axisPoint;
      }
      
    }
  }

  function _handleTeleportInformation() {
    if (!secondarySnappedPoint) {
      if (store.snappingGlobalVariables.sessionVariables.activeTeleportVertex) {
        store.snappingGlobalVariables.sessionVariables.excludedTeleportVertices.push(
          store.snappingGlobalVariables.sessionVariables.activeTeleportVertex
        );
        store.snappingGlobalVariables.sessionVariables.activeTeleportVertex =
          null;
      } else if (
        store.snappingGlobalVariables.sessionVariables.activeTeleportEdge
      ) {
        store.snappingGlobalVariables.sessionVariables.excludedTeleportEdges.push(
          store.snappingGlobalVariables.sessionVariables.activeTeleportEdge
        );
        store.snappingGlobalVariables.sessionVariables.activeTeleportEdge =
          null;
      }
    } else {
      if (store.snappingGlobalVariables.sessionVariables.activeTeleportVertex) {
        if (options.metadata.snappedToVertex) {
          if (
            store.snappingGlobalVariables.sessionVariables.activeTeleportVertex.equals(
              options.metadata.snappedToVertex
            )
          ) {
            //all's well, keep it up
            return;
          }
        }
        store.snappingGlobalVariables.sessionVariables.excludedTeleportVertices.push(
          store.snappingGlobalVariables.sessionVariables.activeTeleportVertex
        );
        store.snappingGlobalVariables.sessionVariables.activeTeleportVertex =
          null;
      } else if (
        store.snappingGlobalVariables.sessionVariables.activeTeleportEdge
      ) {
        if (options.metadata.snappedToEdge) {
          if (
            areEdgesSame(
              store.snappingGlobalVariables.sessionVariables.activeTeleportEdge,
              options.metadata.snappedToEdge
            )
          ) {
            //all's well, keep it up
            return;
          }
        }
        store.snappingGlobalVariables.sessionVariables.excludedTeleportEdges.push(
          store.snappingGlobalVariables.sessionVariables.activeTeleportEdge
        );
        store.snappingGlobalVariables.sessionVariables.activeTeleportEdge =
          null;
      }
    }
  }

  function _handleOverlappingMasses() {
    // if (!options.firstIndicators) return; // doing an experiment, during initial pick, overlap will be handled but not later
    if (options.doNotDoSecondaryScenePicks) return;
    if (!is2D() && (options.metadata.snappedToVertex || options.metadata.snappedToEdge)) return; //already highest priority
    if (options.recursiveCall) return; //do only one check for now
    if (options.metadata.isTeleportSnapped) return;
    if (
      ["door", "window", "furniture", "void"].includes(
        options.metadata.pickInfo?.pickedMesh?.type.toLowerCase()
      )
    )
      return;

    /*
    Handling grips is an extra step here. Removal of grip while creating a new one makes sure there's
    always max one grip of each kind, but have to display only the higher priority one
    
    UPDATE -
    Not creating grips during recursive call, will do it manually based on priority
     */

    const _attemptRecursiveSnap = function () {
      return findSecondarySnappedPoint(
        meshOfInterest,
        startingPoint,
        axisPoint,
        options
      );
    };

    const _snapPreferenceHandling = function (oldMetadata) {
      options.faceSnap = false;
      /*if (oldMetadata.snappedToEdge) {
        options.edgeSnap = false;
      }*/
    };

    const _snapPriorityHandling = function (overlapSnappedPoint, oldMetadata, newMetadata) {
      
      const _deletePreviousGripsAndCreateNewOnes = function (){
        if (oldMetadata.snappedToVertex) {
          disposeHighlightedVertex(vertexName);
          uiIndicatorsHandler.edgeIndicator.massDestruct();
        }
        else if (oldMetadata.snappedToEdge) {
          disposeHightlightedEdge(edgeName);
          uiIndicatorsHandler.edgeIndicator.massDestruct();
        }
        else if (oldMetadata.snappedToFace) disposeHighlightedFace(faceName);
        
        let newIndicator;
        if (newMetadata.snappedToVertex) {
          newIndicator = showVertexIndicator(newMetadata.snappedToVertex, newMetadata.pickInfo.pickedMesh, {
            indicator: indicatorType,
          });
        }
        else if (newMetadata.snappedToEdge) {
          newIndicator = showEdgeIndicator(newMetadata.snappedToEdge, newMetadata.pickInfo.pickedMesh, {
            indicator: indicatorType,
          });
          
          if (newMetadata.snappedToEdgeMidpoint) {
            showVertexIndicator(overlapSnappedPoint, newMetadata.pickInfo.pickedMesh, {
              indicator: indicatorType,
            });
          }
        }
        
        newMetadata.indicator = newIndicator;
      }
      
      const _getEdge2D = function (edge) {
        return [
          getVector2FromVector3(edge.headPt),
          getVector2FromVector3(edge.tailPt),
        ]
      }
      
      const _getDistanceBetweenRefPointAndEdge = function (edge){
        const projection = projectionOfPointOnLine(refPointV2, ..._getEdge2D(edge), true);
        return getDistanceIn2DBetweenVectors(
          new BABYLON.Vector3(projection.x, refPoint.y, projection.y),
          refPoint
        );
      }
      
      let isTheOverlappingSnapBetter = false;
      
      const refPoint = is2D() ? refGroundPoint : oldMetadata.pickInfo.pickedPoint;
      const refPointV2 = getVector2FromVector3(refPoint);
      
      if (oldMetadata.snappedToVertex) {
        // only in 2D
        if (newMetadata.snappedToVertex) {
          const d1 = getDistanceIn2DBetweenVectors(oldMetadata.snappedToVertex, refPoint);
          const d2 = getDistanceIn2DBetweenVectors(newMetadata.snappedToVertex, refPoint);
          
          if (d2 < d1) isTheOverlappingSnapBetter = true;
        } else if (newMetadata.snappedToEdge) {
          if (!store.resolveEngineUtils.onSegment2D(
            newMetadata.snappedToEdge.headPt,
            oldMetadata.snappedToVertex,
            newMetadata.snappedToEdge.tailPt,
          )){
            
            // true when a new vertex isn't dynamically added to the edge
            const d1 = getDistanceIn2DBetweenVectors(oldMetadata.snappedToVertex, refPoint);
            const d2 = _getDistanceBetweenRefPointAndEdge(newMetadata.snappedToEdge);
            
            if (d2 < d1) isTheOverlappingSnapBetter = true;
          }
        } else if (newMetadata.snappedToFace) {
          // not possible, won't snap to face in recursive call
        }
      } else if (oldMetadata.snappedToEdge) {
        // only in 2D
        if (newMetadata.snappedToVertex) {
          const d1 = _getDistanceBetweenRefPointAndEdge(oldMetadata.snappedToEdge);
          const d2 = getDistanceIn2DBetweenVectors(newMetadata.snappedToVertex, refPoint);
          
          if (d2 < d1) isTheOverlappingSnapBetter = true;
        } else if (newMetadata.snappedToEdge) {
          const d1 = _getDistanceBetweenRefPointAndEdge(oldMetadata.snappedToEdge);
          const d2 = _getDistanceBetweenRefPointAndEdge(newMetadata.snappedToEdge);
          
          if (d2 < d1) isTheOverlappingSnapBetter = true;
        } else if (newMetadata.snappedToFace) {
          //not possible, won't snap to face in recursive call
        }
      // } else if (oldMetadata.snappedToFace) {
      } else {
        if (newMetadata.snappedToVertex || newMetadata.snappedToEdge) {
          if (!is2D() && oldMetadata.snappedToFace) {
            let newlyPickedPoint = newMetadata.pickInfo.pickedPoint;
            let projection = projectionOfPointOnFace(
              newlyPickedPoint,
              oldMetadata.snappedToFace
            );
    
            if (projection) {
              if (BABYLON.Vector3.Distance(newlyPickedPoint, projection) < 0.1) {
                isTheOverlappingSnapBetter = true;
              }
            }
          } else {
            isTheOverlappingSnapBetter = true;
          }
        }
      }
      
      if (isTheOverlappingSnapBetter){
        _deletePreviousGripsAndCreateNewOnes();
        secondarySnappedPoint = overlapSnappedPoint;
      }
      else {
        options.metadata = oldMetadata;
      }
      
    };

    
    options.excludedMeshes = jQuery.extend([], options.excludedMeshes);
    options.excludedMeshes.push(pickInfo.pickedMesh);
    options.recursiveCall = true;

    options.pickInfo = null;
    options.wantMetadata = true;

    let oldMetadata = jQuery.extend({}, options.metadata);
    options.metadata = {};
    
    if (!startingPoint && axisPoint){
      // in the case of pre click axis snaps in draw
      // tertiarySnappedPoint will be used as axisPoint, it's corresponding endpoint needs
      // to be restored for use in findSecondarySnappedPoint
  
      if (oldMetadata.tertiarySnappedPointDash){
        options.metadata.tertiarySnappedPointDash = oldMetadata.tertiarySnappedPointDash.clone();
      }
      else {
        // not sure when this happens
        // mostly when snappedToTwoAxes, but not sure why
      }
  
    }

    _snapPreferenceHandling(oldMetadata);

    options.doNotShowIndicators = true;
    let overlapSnappedPoint = _attemptRecursiveSnap();
    options.doNotShowIndicators = false;

    const newMetadata = options.metadata;
    _snapPriorityHandling(overlapSnappedPoint, oldMetadata, newMetadata);
    
  }
}

function findDimensionSnappedPoint(
  startingPoint,
  referencePoint,
  options = {}
) {
  let dimensionSnapEnabled = userSetBIMPropertiesHandler.isGridSnapEnabled();
  // let dimensionSnapEnabled = false;
  // let dimensionSnapEnabled = true;

  if (options.snappedToTwoAxes) return referencePoint;
  if (!dimensionSnapEnabled) return referencePoint;

  if (!options.metadata) options.metadata = {};

  let startingPointRef = startingPoint.clone();

  // startingPoint is set to tertiary snap point's other end
  if (options.dimensionSnapRefPointAdjuster) {
    referencePoint = options.dimensionSnapRefPointAdjuster(
      startingPoint,
      referencePoint
    );
  } else {
    if (options.metadata.tertiarySnappedPointDash)
      startingPointRef = options.metadata.tertiarySnappedPointDash;
  }

  const gridSnapThreshold = userSetBIMPropertiesHandler.getGridSnapThreshold();
  // const gridSnapThreshold = 0.3937007874015748;

  changeCheckPointFor2D(startingPointRef, referencePoint);
  const distanceBetweenPoints = BABYLON.Vector3.Distance(
    startingPointRef,
    referencePoint
  );
  const ratio = distanceBetweenPoints / gridSnapThreshold;
  const round = _.round(ratio);

  return startingPointRef.add(
    referencePoint
      .subtract(startingPointRef)
      .normalize()
      .scale(round * gridSnapThreshold)
  );
}

function findAngleSnappedPoint(refPoint1, refPoint2, pointToSnap, options) {
  if (!store.projectProperties.properties.angleSnapEnabled.getValue()) return;

  const rotationAxis = options.rotationAxis;
  if (!rotationAxis) return null;

  const lineOfInterest = refPoint1.subtract(refPoint2);
  const currentLine = pointToSnap.subtract(refPoint2);
  
  const areParallel = areVectorsParallel(lineOfInterest, rotationAxis);
  if (areParallel) return;

  const angleStep = degreeToRadian(
    store.projectProperties.properties.angleSnapThreshold.getValue()
  );
  const angle = BABYLON.Vector3.GetAngleBetweenVectors(
    lineOfInterest,
    currentLine,
    rotationAxis
  );

  const ratio = angle / angleStep;
  const round = _.round(ratio);
  const angleToSnap = angleStep * round;
  
  const coarseSnapPoint = setAngleBetweenPoints(
    refPoint1,
    refPoint2,
    pointToSnap,
    rotationAxis,
    angleToSnap
  );

  let fineSnapPoint, snappedAngleValue;
  /*if (getDistanceBetweenVectors(coarseSnapPoint, pointToSnap) < dynamicThreshold){
        snapPoint = coarseSnapPoint;
        snappedAngleValue = angleToSnap;
    }
    else {

        const angleStep = degreeToRadian(1);

        const ratio = angle / angleStep;
        const round = _.round(ratio);
        const angleToSnap = angleStep * round;

        fineSnapPoint = setAngleBetweenPoints(refPoint1, refPoint2, pointToSnap, rotationAxis, angleToSnap);
        snappedAngleValue = angleToSnap;

        options.metadata.minorAngleSnap = true;
    }*/

  let snapPoint = coarseSnapPoint;
  snappedAngleValue = angleToSnap;
  if (options.metadata)
    options.metadata.snappedAngleValue = Math.abs(snappedAngleValue);

  return fineSnapPoint || coarseSnapPoint;
}
export {
  findPrioritizedSnapPoint,
  findAxisSnappedPoint,
  findTertiarySnappedPoint,
  findSecondarySnappedPoint,
  findDimensionSnappedPoint,
  findAngleSnappedPoint,
};
