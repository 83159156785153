
import React from "react";
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { Action } from "../../stateManagers/reducers/objectProperties/coveAnalysisSlice.js";
import { store } from "../utilityFunctions/Store.js";
import API from '../../../services/covetool.service'
import ENERGY_CODES_MAPPING from './ENERGY_CODES_MAPPING.json'
import ENERGY_CODES_MAPPING_RESIDENTIAL from './ENERGY_CODES_MAPPING_RESIDENTIAL.json'
import axios from "axios";
import { showToast } from "../extrafunc.js";

/**
 * For Polling
 */
export class Polling {
    _interval = 60000
    _threshold = 20
    _fn = () => {}
    _intervalID = null;
    _cbStart = () => {};
    _cbCompletion = () => {};

    _stop = () => {
        clearInterval(this._intervalID)
        this._cbCompletion(this._intervalID)
    };

    _poll = () => {
        if(this._threshold === 0) return this._stop();
        this._fn(this._stop);

        this._threshold--;
    }

    /**
     * polling starter
     * @param {Function} fn - function to be called
     * @param {{
     *  interval: number,
     *  threshold: number,
     *  immediate: boolean
     * } | null} config - config object
     * @param {(intervalID) => {}} cbStart - function called after initialization is completed
     * @param {(intervalID) => {}} cbCompletion - function called before ending polling
     */
    constructor(fn, config, cbStart = null, cbCompletion = null) {
        this._fn = fn

        if(config.interval) this._interval = config?.interval
        if(config.threshold) this._threshold = config.threshold

        if(cbStart) this._cbStart = cbStart
        if(cbCompletion) this._cbCompletion = cbCompletion

        if(config?.immediate) {
            this._poll();
            
            this._intervalID = setInterval(this._poll, this._interval);
            if(this._cbStart) this._cbStart(this._intervalID)
        }
    }

    start() {
        if(this._intervalID) return

        this._intervalID = setInterval(this._poll, this._interval);
        if(this._cbStart) this._cbStart(this._intervalID)
    }
}

const showCompletionModal = (record) => {
    reduxStore.dispatch(Action.addNewRecord({ floor_key: store.floorkey, record }))
}

export const euiPollStartCallback = (parent_key) => (intervalId) => {
    store.daylightAnalysis.euiPoll[parent_key] = intervalId
}

export const euiPollCompleteCallback = (parent_key) => (intervalId) => {
    delete store.daylightAnalysis.euiPoll[parent_key]
}

export const startPollingEUI = (floorkey, parentFloorkey, cbStart, cbCompletion) => {
    const poller = new Polling(async (stop) => {
        const data = await API.getEUIBreakdown(floorkey);

        if(data) {
            stop()
            reduxStore.dispatch(Action.setEUI({
                floor_key: parentFloorkey,
                data
            }))
        }
    }, {}, cbStart, cbCompletion)
    poller.start()
}

export const handleRecordStatus = (record) => {
    const fk = record?.floor_key, parentFk = store.floorkey

    if(!fk || !parentFk) {
        console.log("handleRecordStatus exiting: No key found; record: ", record)
        return
    }

    if(fk in store.daylightAnalysis.pollsFloorKey) {
        // console.log("already polling for:", fk)
        return
    }

    // add in running analysis
    reduxStore.dispatch(Action.addRunningAnalysis(record))
    // set as analysis for showing in sidebar
    reduxStore.dispatch(Action.setAnalysis(record))
    // remove EUI, as lastest will be fetched
    reduxStore.dispatch(Action.removeEUI({ floor_key: parentFk }))

    if(parentFk in store.daylightAnalysis.euiPoll) {
        clearInterval(store.daylightAnalysis.euiPoll[parentFk])
        delete store.daylightAnalysis.euiPoll[parentFk]
    }

    let checking = false

    return async(stop) => {
        try {
            if(checking) return

            checking = true;
            const res = await API.checkAnalysisStatus(fk, true)
            const errMsg = String(res?.error?.msg)

            if(errMsg.includes("failed") || errMsg.includes("start daylight analysis")) {
                stop()
                
                showToast("Oh no! Daylight Analysis has been failed. Please start daylight analysis again!")
                reduxStore.dispatch(Action.removeRunningAnalysis(record))
                fetchLatestAnalysis()
                return
            }

            const data = res.data

            const euiData = await API.getEUIBreakdown(fk);

            if(euiData) {
                reduxStore.dispatch(Action.setEUI({
                    floor_key: parentFk,
                    data: euiData
                }))
            }

            if(data?.completed == true) {
                stop() // stop polling

                if(!euiData) startPollingEUI(fk, parentFk, euiPollStartCallback(parentFk), euiPollCompleteCallback(parentFk))

                const newRecord = await API.getDaylightAnalysisInfo(fk)

                reduxStore.dispatch(Action.setAnalysis(newRecord))

                reduxStore.dispatch(Action.removeRunningAnalysis(record))

                showCompletionModal(newRecord)
            }

            checking = false;
        } catch(err) {
            console.log("error while polling", err)
            checking = false;
        }
    }
}

export const handleUncompletedRecord = (record) => {
    const fk = record?.floor_key

    if(fk in store.daylightAnalysis.pollsFloorKey) {
        // console.log("already polling for:", fk)
        return
    }

    // add in running analysis
    reduxStore.dispatch(Action.addRunningAnalysis(record))

    return async(stop) => {
        try {
            const res = await API.checkAnalysisStatus(fk, true)
            const errMsg = String(res?.error?.msg)

            if(errMsg.includes("failed") || errMsg.includes("start daylight analysis")) {
                stop()
                return
            }

            const euiData = await API.getEUIBreakdown(fk);

            const data = res.data

            if(data?.completed && euiData) {
                stop() // stop polling

                const newRecord = await API.getDaylightAnalysisInfo(fk)
                
                reduxStore.dispatch(Action.updateRecord(newRecord))

                reduxStore.dispatch(Action.removeRunningAnalysis(record))
                delete store.daylightAnalysis.pollsFloorKey[fk]
                
            }
        } catch(err) {
            console.log("error while polling", err)
        }
    }
}

export const pollStartCallback = (key) => (intervalID) => {
    store.daylightAnalysis.polls.push(intervalID)

    // add in store, polling records
    store.daylightAnalysis.pollsFloorKey[key] = intervalID
    // console.log("polling for: ", key, "intervalID: ", intervalID)
    reduxStore.dispatch(Action.stopFakeLoading())
}

export const pollCompleteCallback = (key) => (intervalID) => {
    const polls = store.daylightAnalysis.polls
    store.daylightAnalysis.polls = polls.filter(id => id !== intervalID)
    delete store.daylightAnalysis.pollsFloorKey[key]
    // console.log("polling completed", key)
}

export async function fetchLatestAnalysis(setLoader = (val) => {}) {
    try {
        // console.log("fetching latest analysis")
        reduxStore.dispatch(Action.setFetchingRecordsAs(true))
        setLoader(true)
        const record = await API.getDaylightAnalysisInfoLatest(store.floorkey)
        
        if(!record) {
            reduxStore.dispatch(Action.setFetchingRecordsAs(false))
            setLoader(false)
            return
        }
        
        if(!record?.completed && record?.floor_key) {
            const validRecordHandler = handleRecordStatus(record)
            if(validRecordHandler) {
                const poller = new Polling(validRecordHandler, {},
                    pollStartCallback(record?.floor_key),
                    pollCompleteCallback(record?.floor_key)
                )

                poller.start()
            }
        }

        if(record?.floor_key) {
            const data = await API.getEUIBreakdown(record?.floor_key);

            if(data) {
                reduxStore.dispatch(Action.setEUI({
                    floor_key: store.floorkey,
                    data
                }))
            } else { // start polling
                startPollingEUI(record?.floor_key, record?.parent_floor_key, euiPollStartCallback(record?.parent_floor_key), euiPollCompleteCallback(record?.parent_floor_key))
            }
        }

        // console.log("Fetched done")
        reduxStore.dispatch(Action.setAnalysis(record))
        reduxStore.dispatch(Action.setFetchingRecordsAs(false))
        setLoader(false)
    } catch(err) {
        console.log(err)
        reduxStore.dispatch(Action.setFetchingRecordsAs(false))
        setLoader(false)
    }
}

export async function fetchAndSaveEnergyCodes(location) {
    try {
      if(!store.floorkey) {
        // console.log("fetchAndSaveEnergyCodes - no floor_key", store.floorkey)
        return
      }

      reduxStore.dispatch(Action.setFetchingEnergyCodesAs(true))

      let data = null

      if(location) {
        data = await API.getEnergyCodes(store.floorkey, location?.lat, location?.lng)
      } else {
        data = await API.getEnergyCodes()
      }

      if(data) reduxStore.dispatch(Action.setEnergyCodes(data))
      
      reduxStore.dispatch(Action.setFetchingEnergyCodesAs(false))
    } catch(err) {
      console.log(err)
      reduxStore.dispatch(Action.setFetchingEnergyCodesAs(false))
    }
}

export async function fetchAndSaveDefaultSettings() {
      try {
        if(!store.floorkey) {
        //   console.log("fetchDefaultSettings - no floor_key", store.floorkey)
          return
        }

        reduxStore.dispatch(Action.setFetchingSettingsAs(true))
        const data = await API.getDaylightAnalysisInfo(store.floorkey)

        const locationArray = data?.details?.location?.split(',')
        const buildingTypesArray = data?.details?.building_types?.split(',')
        const buildingTypes = Array.isArray(buildingTypesArray) && buildingTypesArray.length > 0 ? buildingTypesArray : ["Apartments"]

        const parsedData = {
            energyCode: data?.details?.energy_code || 12,
            energyCodeName: data?.details?.energy_code_name || "ASHRAE 2019",
            buildingTypes: buildingTypes,
            location: { lat: null, lng: null },
        }

        if(Array.isArray(locationArray) && locationArray.length == 2) {
            parsedData.location.lat = locationArray[0]
            parsedData.location.lng = locationArray[1]
        }

        reduxStore.dispatch(Action.updateSettings(parsedData))
        reduxStore.dispatch(Action.setFetchingSettingsAs(false))
      } catch(err) {
        console.log(err)
        reduxStore.dispatch(Action.setFetchingSettingsAs(false))
      }
    }


export const getLocationInfo = async (lat, lng) => {
    if(!lat || !lng) return

    try {
        const res = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?types=country,region&access_token=pk.eyJ1IjoiYWx0YWZnYW5paGFyIiwiYSI6ImNrYTgyemIwMjBhM2szM283c2E2eWtsMW8ifQ.9PMFI6Y0-WpeNf_22NDjXw`)

        let country = null, place = null

        if(res.data) {
            if(Array.isArray(res.data.features)) {
                res.data.features.forEach(item => {
                    if(item?.id?.includes("country")) {
                        country = item?.text
                    } else if(item?.id?.includes("region")) {
                        place = item?.text
                    }
                })
            }
        }

        if(country == null) return

        return {
            country,
            place
        }
    } catch(err) {
        console.log(err)
    }
}

export const getEnergyCodeFromMapping = (country, place, residential) => {
    try {
        const mapping = residential ? ENERGY_CODES_MAPPING_RESIDENTIAL : ENERGY_CODES_MAPPING
        if(country in mapping) {
            if(place in mapping[country]) {
                return mapping[country][place][0]
            } else {
                return mapping[country].default[0]
            }
        } else {
            return mapping.default[0]
        }
    } catch(err) {
        console.log(err)
    }
}

const _isBetween = (n, start, end) => n > start && n < end

export const getDefaultBuildingTypes = () => {
    try {
        const structure = store.exposed.structureCollection.getInstance().getStructures()[store.activeLayer.structure_id];
        const levels = Object.values(structure?.getAllLevels() || {})
        
        // Collecting labels
        const labels = []

        levels.forEach((level) => {
            const floors = level?.getFloors()

            if(Array.isArray(floors)) {
                floors.forEach(floor => {
                    if(floor.room_type) labels.push(String(floor.room_type).toLowerCase())
                })
            }
        })

        const commonThings = [
            "bedroom",
            "bathroom",
            "toilet",
            "washroom",
            "stair",
            "lift",
            "office",
            "cabin",
            "workspace",
            "living room",
            "kitchen",
            "classroom",
            "doctor",
            "medical",
            "ward",
            "reception",
            "foyer",
            "shop",
            "storage",
            "warehouse"
        ]

        const commonThingsInterchangeable = {
            "bedroom": "bedroom",
            "bathroom": "bathroom",
            "toilet": "bathroom",
            "washroom": "washroom",
            "stair": "stair",
            "lift": "stair",
            "office": "office",
            "cabin": "office",
            "workspace": "workspace",
            "living room": "living room",
            "kitchen": "kitchen",
            "classroom": "classroom",
            // for finding Hospital
            "doctor": "medical",
            "medical": "medical",
            "ward": "medical",
            // -------------------
            "reception": "reception",
            "foyer": "reception",
            // for finding Retail
            "shop": "shop",
            "storage": "shop",
            "warehouse": "shop"
        }

        // common things count
        const countTable = {
            "bedroom": 0,
            "bathroom": 0,
            "washroom": 0,
            "stair": 0,
            "office": 0,
            "workspace": 0,
            "living room": 0,
            "kitchen": 0,
            "classroom": 0,
            "medical": 0,
            "reception": 0,
            "shop": 0
        }

        labels.forEach(item => {
            const label = String(item)
            commonThings.forEach(commonThing => {
                if(label.indexOf(commonThing) !== -1) {
                    countTable[commonThingsInterchangeable[commonThing]]++;
                }
            })
        })

        // check for Single Family Home
        if(_isBetween(countTable.bedroom, 0, 3) &&
        _isBetween(countTable.kitchen, 0, 3) &&
        _isBetween(countTable.bathroom, 0, 3) &&
        _isBetween(countTable.stair, -1, 3)
        ) {
            return ["Single Family Home"]
        }

        // check is Apartments
        if(
            countTable.bedroom > 5 &&
            countTable.bathroom > 5 &&
            countTable.kitchen > 3 &&
            countTable["living room"] > 2 &&
            countTable.stair > 2
        ) {
            return ["Apartments"]
        }

        if(countTable.medical > 0) {
            return ["Hospital"]
        }

        // check if Education building
        if(countTable.classroom > 4) {
            return ["Education"]
        }

        // check if Retail
        if(countTable.shop > 0) {
            return ["Retail"]
        }

        if(
            countTable.office > 0 &&
            countTable.workspace > 0
        ) {
            return ["Office"]
        }

        if(
            countTable["living room"] > 0 &&
            countTable.reception > 0
        ) {
            return ["Hotel"]
        }

        return ["Apartments"]
    } catch (err) {
        console.log(err)
    }
}

export const useStateWithCallbackLazy = initialValue => {
  const callbackRef = React.useRef(null);

  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = React.useCallback(
    (newValue, callback) => {
      callbackRef.current = callback;

      return setValue(newValue);
    },
    [],
  );

  return [value, setValueWithCallback];
};

const ignoreList = {
    "axisx": true,
    "axisy": true,
    "axisz": true,
    "daylight-grids": true,
    "terrain": true,
    "analysis_grid": true
}

export const hideBuilding = () => {
    if(!store.daylightAnalysis.hiddenMeshes) store.daylightAnalysis.hiddenMeshes = []
    const stack = store.daylightAnalysis.hiddenMeshes

    for (let mesh of store.scene.meshes) {
        const name = mesh?.name?.toLowerCase()
        const type = mesh?.type?.toLowerCase()
        if(name in ignoreList || type in ignoreList) continue

        if (mesh.isVisible) {
            mesh.isVisible = false
            stack.push(mesh);
        }
    }
}

export const showBuilding = () => {
    if(!store.daylightAnalysis.hiddenMeshes) return

    for (let mesh of store.daylightAnalysis.hiddenMeshes) {
        mesh.isVisible = true
    }

    store.daylightAnalysis.hiddenMeshes = null
}

export const toggleBuilding = () => {
    if(store.daylightAnalysis.hiddenMeshes) showBuilding()
    else hideBuilding()
}

export const toggleTransparency = () => {
    if(store.scene.meshes) {
        store.scene.meshes.forEach(mesh => {
            if(mesh?.name?.toLowerCase() == "roof" || mesh?.name?.toLowerCase() == "wall") {
                if(mesh.material) {
                    mesh.material.alpha = 0.3
                }
                if(mesh._effectiveMaterial) {
                    mesh._effectiveMaterial.alpha = 0.3
                }
            }
        })
    }
}