import React, { useState, useEffect, setState } from "react";
import styled from "styled-components";
import Input from "../../components/Input";
import ScrollableDropdown from "../../components/ScrollableDropdown";
import SpinLoader from "../../components/SpinLoader";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { colors } from "../../themes/constant";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.625rem 1.75rem;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    margin: 0 0.9375rem;
  }
`;

const ImageContainer = styled.div`
  margin: 0.3125rem;
  display: flex;
  border-radius: 8px;
`;
const  scaleOptions = {
  metric: ["1:25", "1:50", "1:100", "1:200", "1:300", "1:500", "1:1000"],
  imperial: [ `1/64" = 1’-0"`, `1/32" = 1’-0"`, `1/16" = 1’-0"`, `1/8" = 1’-0"`, `1/4" = 1’-0"`, `1/2" = 1’-0"`, `3/64" = 1’-0"`, `3/32" = 1’-0"`, `3/16" = 1’-0"`, `3/8" = 1’-0"`, `3/4" = 1’-0"`, `1" = 10’-0"`, `1" = 20’-0"`, `1" = 30’-0"`, `1" = 40’-0"`, `1" = 50’-0"`, `1" = 60’-0"`, `1" = 80’-0"`],
}

export const SingleUploadOverView = (props) => {
  const { customProps } = props;
  const { files, storey, setStorey, selectedStorey, showProgressBar, page, pdfPageCount, isPdfPageCountLoading, setPdfPage, scale, setScale } = customProps;
  const [scaleUnit, setScaleUnit] = useState("metric");
  const [isScaleUnitLoading, setIsScaleUnitLoading] = useState(true);
  useEffect(() => {
    if(storey) return;
    const defaultStorey = selectedStorey ? selectedStorey : 1;
    setStorey(defaultStorey);
    return () => {}
  }, [selectedStorey]);

  useEffect(() => {
    if(!setScale) return;
    setIsScaleUnitLoading(true);
    let defaultScale, defaultScaleUnit;
    const unit = store.$scope.units_type.value;
    if(unit === "feet-inches" || unit === "inches"){
      defaultScale = scaleOptions.imperial[0];
      defaultScaleUnit = "imperial"
    } else{
      defaultScale = scaleOptions.metric[2];
      defaultScaleUnit = "metric";
    }
    setScale(defaultScale);
    setScaleUnit(defaultScaleUnit);
    setIsScaleUnitLoading(false);
  }, [files]);

  return (
    <Container>
      <StyledDiv style={{ minHeight: "9.375rem", width: "100%" }}>
        <StyledDiv style={{ flexDirection: "column", width: "50%" }}>
          <Input
            label={"Storey"}
            labelWidth="6.25rem"
            type={"number"}
            value={storey}
            customOnChange={(e) => {
              setStorey(e.target.value);
            }}
            required
            onPress={(e) => {}}
            customInputContainerStyle={{
              flexDirection: "row",
              alignItems: "center",
            }}
            customInput={{
              width: "2.125rem",
              height: "1.375rem",
              textAlign: "end",
              fontWeight: "bold",
              fontSize: "12px",
            }}
            labelStyle={{
              width: "6.25rem",
              fontSize: "12px",
              lineHeight: "16px",
              color: colors.thinGray,
              fontWeight: 400,
            }}
          />
          {
            page && <div style={{display: "flex"}}>
            <div style={{
              width: "6.25rem",
              fontSize: "12px",
              lineHeight: "16px",
              color: colors.thinGray,
              fontWeight: 400,
            }}>Page</div>
            {
              isPdfPageCountLoading ? <div>1</div> :
              <ScrollableDropdown 
                selectedOption={page}
                options={new Array(pdfPageCount).fill(0).map((x, index) => index+1)}
                onChange={setPdfPage}
              />
            }
          </div>
            /* <Input
              label={"Page"}
              labelWidth="6.25rem"
              type={"number"}
              value={page}
              customOnChange={(e) => {
                // setStorey(e.target.value);
              }}
              required
              min={1}
              max={pdfPageCount}
              isInputDisabled={isPdfPageCountLoading}
              onPress={(e) => {}}
              customInputContainerStyle={{
                flexDirection: "row",
                alignItems: "center",
              }}
              customInput={{
                width: "2.125rem",
                height: "1.375rem",
                textAlign: "end",
                fontWeight: "bold",
                fontSize: "12px",
              }}
              labelStyle={{
                width: "6.25rem",
                fontSize: "12px",
                lineHeight: "16px",
                color: colors.thinGray,
                fontWeight: 400,
              }}
           />
           /{pdfPageCount} */
          //  </div>
          }
          {
            isScaleUnitLoading ? <div></div> :
            scale && <div style={{display: "flex"}}>
            <div style={{
              width: "6.25rem",
              fontSize: "12px",
              lineHeight: "16px",
              color: colors.thinGray,
              fontWeight: 400,
            }}>Scale</div>
            <ScrollableDropdown 
              selectedOption={scaleUnit === "imperial" ? scaleOptions.imperial[0] : scaleOptions.metric[2]}
              options={scaleUnit === "imperial" ? scaleOptions.imperial : scaleOptions.metric}
              onChange={setScale}
              // cutomTextStyle={{
              //   fontWeight: 700, width: "7.5rem",
              //   fontSize: "12px",
              //   paddingLeft: 0,
              // }}
              // width="auto"
            />
          </div>
          }
          <div>
            <Input
              label={"Layer Name"}
              type={"text"}
              labelWidth="6.25rem"
              value={(files && files.file && files.file.name) || ""}
              required
              onPress={(e) => {}}
              customInputContainerStyle={{
                flexDirection: "row",
                alignItems: "center",
              }}
              customInput={{
                width: "211",
                height: "1.375rem",
                fontSize: "12px",
              }}
              labelStyle={{
                width: "6.25rem",
                fontSize: "12px",
                lineHeight: "16px",
                color: colors.thinGray,
                fontWeight: 400,
              }}
            />
            <StyledDiv style={{ justifyContent: "flex-start" }}>
              <p
                style={{
                  width: "6.5rem",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: colors.thinGray,
                  fontWeight: 400,
                }}
              >
                File Size
              </p>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "-0.333333px",
                  color: colors.darkGrey,
                }}
              >
                {`${
                  files &&
                  files.file &&
                  Math.round(files.file.size / Math.pow(1024, 1))
                } KB` || "NA"}
              </p>
            </StyledDiv>
          </div>
        </StyledDiv>
        {!files.file.name.includes(".dwg") && (
          <div>
            <ImageContainer>
              <img
                style={{
                  width: "161.07px",
                  height: "161.47px",
                  borderRadius: "10px",
                }}
                src={files.preview}
                alt=""
              />
            </ImageContainer>
          </div>
        )}
      </StyledDiv>
    </Container>
  );
};
