import BABYLON from "../modules/babylonDS.module.js";
import jQuery from "jquery";
import { store } from "../modules/utilityFunctions/Store.js"
import { findWallPolExtremePoints,getMeshTopBottomWorldCoords,getWallMeshLineSegment } from "./wall_generation_helper.js";
import { calculateEuclidianDistance,line_intersect } from "./polygon_dip_workers.js";
import { removeDuplicates,makeid } from "./arrayFuncs.js";
import { roundVector } from "./mathFuncs.js";
import { appElement } from "./bimDataFuncs.js";
import { setUVScale } from "./mats.js";
import { unclick } from "./meshEvents.js";
import { assignProperties,updateModifications } from "./sceneStateFuncs.js";
function assignFloorForExtendedMesh(wallArr, meshArr, thickness) {
  let arrExtremes = findWallPolExtremePoints(wallArr);
  let flag = false;
  if (
    arrExtremes.xMax - arrExtremes.xMin >
    arrExtremes.YMax - arrExtremes.YMin
  ) {
    meshArr.forEach(function (item) {
      if (
        arrExtremes.xMin - thickness <= item["range"][0] &&
        arrExtremes.xMax + thickness >= item["range"][1]
      ) {
        flag = true;
      }
    });
    return flag;
  } else {
    meshArr.forEach(function (item) {
      if (
        arrExtremes.YMin - thickness <= item["range"][0] &&
        arrExtremes.YMax + thickness >= item["range"][1]
      ) {
        flag = true;
      }
    });
    return flag;
  }
}

function assignFloorAndRoofForNewMesh(wallArr, collMeshRangeArr) {
  let arrExtremes = findWallPolExtremePoints(wallArr);
  let floorArr = [];
  let roofArr = [];

  if (
    arrExtremes.xMax - arrExtremes.xMin >
    arrExtremes.YMax - arrExtremes.YMin
  ) {
    collMeshRangeArr.forEach(function (item) {
      if (
        arrExtremes.xMin >=
          item["range"][0] - Math.round(item.thickness * 200) / 100 &&
        arrExtremes.xMax <=
          item["range"][1] + Math.round(item.thickness * 200) / 100
      ) {
        console.log(typeof item.floor);
        item.floor.forEach(function (floorid) {
          if (typeof floorid !== "number") console.log(item);
          floorArr.push(floorid);
        });
      }
    });
  } else {
    collMeshRangeArr.forEach(function (item) {
      if (
        arrExtremes.YMin >=
          item["range"][0] - Math.round(item.thickness * 200) / 100 &&
        arrExtremes.YMax <=
          item["range"][1] + Math.round(item.thickness * 200) / 100
      ) {
        console.log(typeof item.floor);
        item.floor.forEach(function (floorid) {
          if (typeof floorid !== "number") console.log(item);
          floorArr.push(floorid);
        });
      }
    });
  }
  if (floorArr.length === 0) {
    console.log("No floor neighbors found !");
    return { floor: collMeshRangeArr[0].floor, roof: collMeshRangeArr[0].roof };
  } else
    return {
      floor: JSON.parse(JSON.stringify(floorArr)),
      roof: JSON.parse(JSON.stringify(roofArr)),
    };
}

function getWallMeshPointsForFloor(
  mesh,
  floorId,
  centroid_floor,
  floor_height_2
) {
  // mesh = mesh.refreshBoundingInfo();
  //mesh.freezeWorldMatrix();

  var bbinfo = mesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mesh.setPivotPoint(centroid);

  let myBoundingBox = mesh.getBoundingInfo();
  // mesh.showBoundingBox =true;
  //myBoundingBox.updateWorldMatrix(mesh._worldMatrix);
  let world = mesh.getWorldMatrix();
  // let wallCenter = jQuery.extend({}, myBoundingBox.boundingBox.centerWorld);
  // let wallCenter = jQuery.extend({}, mesh.position);
  //let wallCenter = mesh.position;
  // console.log("wall_center before " + jQuery.extend({}, wallCenter));
  //wallCenter = BABYLON.Vector3.TransformCoordinates(wallCenter, world);
  // var box = BABYLON.Mesh.CreateBox(mesh.name + "boxScale", 1.0, newScene);
  //box.position = wallCenter;
  // console.log("wall_center before " + jQuery.extend({}, wallCenter));
  // let meshCoordsArr = myBoundingBox.boundingBox.vectorsWorld;
  // let min_point = myBoundingBox.boundingBox.minimumWorld;
  // let max_point = myBoundingBox.boundingBox.maximumWorld;
  // let minimum_dist = 9999;

  let obj = getMeshTopBottomWorldCoords(mesh);
  let newPoint1 = new BABYLON.Vector3(0, floor_height_2, 0);
  let newPoint2 = new BABYLON.Vector3(0, floor_height_2, 0);

  let pointArr = getWallMeshLineSegment(mesh);
  let angleArr = [75, -75];

  let change_z = -(pointArr[1] - pointArr[4]);
  let change_x = pointArr[3] - pointArr[0];

  let dist =
    Math.sqrt(
      calculateEuclidianDistance(
        [pointArr[0], pointArr[4]],
        [pointArr[3], pointArr[1]]
      )
    ) / 2;
  centroid_floor.push([pointArr[0] / store.unit_scale, -pointArr[1] / store.unit_scale]);
  centroid_floor.push([pointArr[3] / store.unit_scale, -pointArr[4] / store.unit_scale]);

  return [
    { x: pointArr[0], z: pointArr[4], y: 0 },
    { x: pointArr[3], z: pointArr[1], y: 0 },
  ];

  //return [{x:pointArr[0]/unit_scale, z:-pointArr[1]/unit_scale, y:0}, {x:pointArr[3]/unit_scale, z:-pointArr[4]/unit_scale, y:0}];

  // wallCenter.y = 0;

  // if(Math.abs(change_z) === 0){
  //     newPoint1.x = wallCenter.x;
  //     newPoint1.z = wallCenter.z  + dist;

  //     newPoint2.x = wallCenter.x;
  //     newPoint2.z = wallCenter.z - dist;
  // }
  // else if(change_x === 0){
  //     newPoint1.x = wallCenter.x + dist;
  //     newPoint1.z = wallCenter.z;

  //     newPoint2.x = wallCenter.x - dist;
  //     newPoint2.z = wallCenter.z;
  // }
  // else{
  //     let m = change_z/change_x;
  //     let dx = (1 / Math.sqrt( 1 + (m*m)));
  //     let dy = m* dx;

  //     newPoint1.x = wallCenter.x + dx;
  //     newPoint1.z = wallCenter.z + dy;

  //     newPoint2.x = wallCenter.x - dx;
  //     newPoint2.z = wallCenter.z - dy;
  // }

  // let direction1 = BABYLON.Vector3.Normalize(newPoint1.subtract(wallCenter));
  // let direction2 =  BABYLON.Vector3.Normalize(newPoint2.subtract(wallCenter));
  // let directionArr = [direction1, direction2];

  // if(typeof floorId !== "number")
  //     floorId = floorId[0];

  // let floorMesh = store.scene.getMeshByUniqueID(floorId);
  // let min_dist= 9999;

  // if(findFaceForFloorGeneration(wallCenter, direction1, floorMesh) && findFaceForFloorGeneration(wallCenter, direction2, floorMesh)){
  //         let centroid = {x:0,z:0,y:0}, distSq = {x:0,z:0,y:0}; x_arr = []; z_arr =[];
  //         centroid_floor.forEach(function(point){
  //             centroid.x += point.x;
  //             x_arr.push(point.x);
  //             centroid.z += point.z;
  //             z_arr.push(point.z);
  //         });

  //         centroid.x /= centroid_floor.length;
  //         centroid.z /= centroid_floor.length;

  //         x_arr.sort();
  //         z_arr.sort();

  //         if(x_arr.length % 2 === 0){
  //             distSq.x = x_arr[x_arr.length/2];
  //             distSq.z = z_arr[z_arr.length/2];
  //         }
  //         else{
  //             distSq.x = (x_arr[x_arr.length/2] + x_arr[x_arr.length/2 + 1])/2;
  //             distSq.z = (z_arr[z_arr.length/2] + z_arr[z_arr.length/2 + 1])/2;
  //         }

  //         // centroid_floor.forEach(function(point){
  //         //     distSq.x += (point.x - centroid.x)*(point.x - centroid.x);
  //         //     distSq.z += (point.z - centroid.z)*(point.z - centroid.z);
  //         // });

  //         // distSq.x /= centroid_floor.length -1;
  //         // distSq.z /= centroid_floor.length -1;

  //         // distSq.x = Math.sqrt(distSq.x);
  //         // distSq.z = Math.sqrt(distSq.z);

  //         // centroid_floor = centroid_floor.pop();
  //         var box = BABYLON.Mesh.CreateSphere("sphere" + Math.round(distSq.x), 3, 0.6, store.scene, true);
  //         box.position = JSON.parse(JSON.stringify(distSq));
  //          setTimeout(function(box){
  //                 box.dispose();
  //               }, 5000, box);
  //         if(calculateEuclidianDistance([distSq.x, distSq.z], [newPoint1.x, newPoint1.z]) < calculateEuclidianDistance([distSq.x, distSq.z], [newPoint2.x, newPoint2.z])){
  //              obj.bottom.forEach(function(point){
  //                 let d = calculateEuclidianDistance([point.x, point.z], [newPoint1.x, newPoint1.z]);
  //                 centroid_floor.push(newPoint1);
  //                 if(min_dist >= d)
  //                     min_dist = d;
  //                 });
  //              var box = BABYLON.Mesh.CreateSphere("sphere" + Math.round(newPoint1.x), 3, 1, store.scene, true);
  //               box.position = JSON.parse(JSON.stringify(newPoint1));
  //               setTimeout(function(box){
  //                 box.dispose();
  //               }, 5000, box);
  //              obj.bottom = obj.bottom.filter(function(point){
  //                 return Math.abs(calculateEuclidianDistance([point.x, point.z], [newPoint1.x, newPoint1.z]) - min_dist) <= 0.05
  //              });
  //              return obj.bottom;
  //         }
  //         else{
  //               obj.bottom.forEach(function(point){
  //                 let d = calculateEuclidianDistance([point.x, point.z], [newPoint2.x, newPoint2.z]);
  //                 centroid_floor.push(newPoint2);
  //                 if(min_dist >= d)
  //                     min_dist = d;
  //                 });
  //               var box = BABYLON.Mesh.CreateSphere("sphere" + Math.round(newPoint1.x), 3, 1, store.scene, true);
  //               box.position = JSON.parse(JSON.stringify(newPoint2));
  //                setTimeout(function(box){
  //                 box.dispose();
  //               }, 5000, box);

  //              obj.bottom = obj.bottom.filter(function(point){
  //                 return Math.abs(calculateEuclidianDistance([point.x, point.z], [newPoint2.x, newPoint2.z]) - min_dist) <= 0.05
  //              });
  //              return obj.bottom;
  //         }
  // }

  //  if(findFaceForFloorGeneration(wallCenter, direction1, floorMesh)){
  //     console.log("Intersect with 1 " + direction1 + " mesh " + mesh.uniqueId);
  //     obj.bottom.forEach(function(point){
  //         let d = calculateEuclidianDistance([point.x, point.z], [newPoint1.x, newPoint1.z]);
  //         centroid_floor.push(newPoint1);
  //         if(min_dist >= d)
  //             min_dist = d;
  //     });

  //      obj.bottom = obj.bottom.filter(function(point){
  //         return Math.abs(calculateEuclidianDistance([point.x, point.z], [newPoint1.x, newPoint1.z]) - min_dist) <= 0.05
  //      });
  //          console.log("wall_center before " + jQuery.extend({}, wallCenter));

  //      // var ray = new BABYLON.Ray();
  //      // var rayHelper = new BABYLON.RayHelper(ray);
  //      // rayHelper.attachToMesh(mesh, direction1, wallCenter, dist/2);
  //      // rayHelper.show(scene);

  //  }
  //  else{
  //      if(findFaceForFloorGeneration(wallCenter, direction2, floorMesh)){
  //         console.log("Intersect with 2" + direction2 + " mesh " + mesh.uniqueId);
  //         obj.bottom.forEach(function(point){
  //             let d = calculateEuclidianDistance([point.x, point.z], [newPoint2.x, newPoint2.z]);
  //             centroid_floor.push(newPoint2);
  //             if(min_dist >= d)
  //                 min_dist = d;
  //          });

  //          obj.bottom = obj.bottom.filter(function(point){
  //             return Math.abs(calculateEuclidianDistance([point.x, point.z], [newPoint2.x, newPoint2.z]) - min_dist) <= 0.05
  //          });
  //          console.log("wall_center before " + jQuery.extend({}, wallCenter));
  //          // var rayq = new BABYLON.Ray();
  //          // var rayHelperq = new BABYLON.RayHelper(rayq);
  //          // rayHelperq.attachToMesh(mesh, direction2, wallCenter, dist/2);
  //          // rayHelperq.show(scene);

  //      }
  //      else{
  //         for(let l=0;l<directionArr.length;l++){
  //             let directionOld = directionArr[l];
  //             for(let k=0; k<angleArr.length;k++){
  //               let rotationY = BABYLON.Matrix.RotationY(toRadians(angleArr[k]));
  //               let newdirection = BABYLON.Vector3.TransformNormal(directionOld, rotationY);
  //               let ray = new BABYLON.Ray(wallCenter, newdirection, dist*2);
  //               let newPoint;
  //               if(ray.intersectsMesh(floorMesh).hit){
  //                     // BABYLON.RayHelper.CreateAndShow(ray, store.scene, BABYLON.Color3.Blue());
  //                     console.log("Intersect with 3" + direction2 + " mesh " + mesh.uniqueId);
  //                     if(l===0)
  //                         newPoint = newPoint1;
  //                     else
  //                         newPoint = newPoint2;

  //                     centroid_floor.push(newPoint);

  //                      obj.bottom.forEach(function(point){
  //                         let d = calculateEuclidianDistance([point.x, point.z], [newPoint.x, newPoint.z]);
  //                         if(min_dist >= d)
  //                             min_dist = d;
  //                      });

  //                      obj.bottom = obj.bottom.filter(function(point){
  //                         return Math.abs(calculateEuclidianDistance([point.x, point.z], [newPoint.x, newPoint.z]) - min_dist) <= 0.05
  //                      });

  //                      // var ray1 = new BABYLON.Ray();
  //                      // var rayHelper = new BABYLON.RayHelper(ray1);
  //                      // rayHelper.attachToMesh(mesh, newdirection, wallCenter, dist*2);
  //                      // rayHelper.show(scene);

  //                      return obj.bottom;

  //               }
  //             }
  //         }
  //      }

  //  }

  // var ray = new BABYLON.Ray();
  // var rayHelper = new BABYLON.RayHelper(ray);
  // rayHelper.attachToMesh(mesh, direction1, wallCenter, dist/2);
  // rayHelper.show(scene);

  // var rayHelper2 = new BABYLON.RayHelper(ray);
  // rayHelper2.attachToMesh(mesh, direction2, wallCenter, dist/2);
  // rayHelper2.show(scene);

  //  var hit = store.scene.pickWithRay(ray);

  //  if (hit.pickedMesh && hit.pickedMesh.uniqueId === floorId){
  //        hit.pickedMesh.material = store.scene.getMaterialByName('wall_mat4');
  //        hit.pickedMesh.scaling.y += 0.01;
  //        console.log("hit for mesh " + mesh.uniqueId);
  // }
  // else{
  //       var ray = new BABYLON.Ray(wallCenter, direction2);
  //       var hit = store.scene.pickWithRay(ray);
  //       if (hit.pickedMesh && hit.pickedMesh.uniqueId === floorId){
  //          hit.pickedMesh.material = store.scene.getMaterialByName('wall_mat4');
  //          hit.pickedMesh.scaling.y += 0.01;
  //          console.log("M2 hit for mesh " + mesh.uniqueId);
  //       }
  // }

  // if(calculateEuclidianDistance([wallCenter.x + change_z, wallCenter.z + change_x], [centroid_floor.x, centroid_floor.z]) <
  //     calculateEuclidianDistance([wallCenter.x -change_z, wallCenter.z - change_x], [centroid_floor.x, centroid_floor.z]))
  //     pass;
  // console.log(obj.bottom, obj.bottom.length);
  //return obj.bottom;
  // meshCoordsArr.forEach(function (item){
  //     if(calculateEuclidianDistance([min_point.x, min_point.z],[item.x,item.z]) <= diagLen)
  //         minArr.push(item);
  //     else
  //         maxArr.push(item);
  // });

  // minArr.forEach(function(point){
  //     let dist = calculateEuclidianDistance([point.x, point.z],[centroid_floor.x, centroid_floor.z]);
  //     if(dist < minimum_dist)
  //         minimum_dist = dist;
  // });

  // let near1 = minArr.filter(function(point){
  //     let dist = calculateEuclidianDistance([point.x, point.z],[centroid_floor.x, centroid_floor.z]);
  //     if(Math.abs(dist - minimum_dist) <= 0.06 )
  //         return true;
  //     return false;
  // });
  // minimum_dist = 99999;

  //  maxArr.forEach(function(point){
  //     let dist = calculateEuclidianDistance([point.x, point.z],[centroid_floor.x, centroid_floor.z]);
  //     if(dist < minimum_dist)
  //         minimum_dist = dist;
  // });

  // let near2 = maxArr.filter(function(point){
  //     let dist = calculateEuclidianDistance([point.x, point.z],[centroid_floor.x, centroid_floor.z]);
  //     if(Math.abs(dist - minimum_dist) <= 0.06)
  //         return true;
  //     return false;
  // });

  // return near1.concat(near2);
}

function findFloorForNewWall(walllMesh, floorMesh) {
  try {
    this.myBoundingBox = walllMesh.getBoundingInfo();
    let wallCenter = this.myBoundingBox.boundingBox.centerWorld;

    wallCenter.y = 1 * walllMesh.level + 0.02;
    this.newPoint1 = new BABYLON.Vector3(0, walllMesh.level * 1 + 0.08, 0);
    this.newPoint2 = new BABYLON.Vector3(0, walllMesh.level * 1 + 0.08, 0);

    this.pointArr = getWallMeshLineSegment(walllMesh);

    this.change_z = -(this.pointArr[1] - this.pointArr[4]);
    this.change_x = this.pointArr[3] - this.pointArr[0];

    let dist =
      Math.sqrt(
        calculateEuclidianDistance(
          [this.pointArr[0], this.pointArr[4]],
          [this.pointArr[3], this.pointArr[1]]
        )
      ) / 4;

    if (Math.abs(this.change_z) === 0) {
      this.newPoint1.x = wallCenter.x;
      this.newPoint1.z = wallCenter.z + dist;

      this.newPoint2.x = wallCenter.x;
      this.newPoint2.z = wallCenter.z - dist;
    } else if (this.change_x === 0) {
      this.newPoint1.x = wallCenter.x + dist;
      this.newPoint1.z = wallCenter.z;

      this.newPoint2.x = wallCenter.x - dist;
      this.newPoint2.z = wallCenter.z;
    } else {
      let m = this.change_z / this.change_x;
      let dx = 1 / Math.sqrt(1 + m * m);
      let dy = m * dx;

      this.newPoint1.x = wallCenter.x + dx;
      this.newPoint1.z = wallCenter.z + dy;

      this.newPoint2.x = wallCenter.x - dx;
      this.newPoint2.z = wallCenter.z - dy;
    }

    let direction1 = BABYLON.Vector3.Normalize(
      this.newPoint1.subtract(wallCenter)
    );
    let direction2 = BABYLON.Vector3.Normalize(
      this.newPoint2.subtract(wallCenter)
    );

    let ray = new BABYLON.Ray(wallCenter.subtract(direction1), direction1);
    if (ray.intersectsMesh(floorMesh).hit) {
      walllMesh.floor.push(floorMesh.uniqueId);
      walllMesh.floor = removeDuplicates(walllMesh.floor);
      walllMesh.removeExtraFloor = true;
      return;
    } else {
      ray = new BABYLON.Ray(wallCenter.subtract(direction2), direction2);
      if (ray.intersectsMesh(floorMesh).hit) {
        walllMesh.floor.push(floorMesh.uniqueId);
        walllMesh.floor = removeDuplicates(walllMesh.floor);
        walllMesh.removeExtraFloor = true;
        return;
      }
    }
    return;
  } catch (e) {
    console.error(e);
    return;
  }
}

function generateNewFloorMesh(meshArr, floorId) {
  this._findCollinear = function (point1, point2, point3, point4) {
    if (
      Math.abs(point2.x - point1.x) > Math.abs(point2.z - point1.z) &&
      Math.abs(point4.x - point3.x) > Math.abs(point4.z - point3.z)
    ) {
      if (Math.abs(point2.y - point3.y) < 1) return true;
    } else {
      if (Math.abs(point2.x - point3.x) < 1) return true;
    }
    return false;
  };

  this._getSlope = function (point1, point2) {
    if (point2.x - point1.x === 0) return 90;
    else return (point2.z - point1.z) / (point2.x - point1.x);
  };

  this._findCollinearM2 = function (point1, point2, point3) {
    let threshold = 2;
    let area =
      point1.x * (point2.z - point3.z) +
      point2.x * (point3.z - point1.z) +
      point3.x * (point1.z - point2.z);
    // let dist2 = calculateEuclidianDistance([point2.x, point2.z], [point3.x, point3.z]);
    // let dist3 =calculateEuclidianDistance([point3.x, point3.z], [point1.x, point1.z]);
    console.log(Math.abs(area));

    if (Math.abs(area) < threshold) return true;
    return false;
  };

  return new Promise(function (resolve, reject) {
    try {
      let x_pos = 0,
        y_pos = 0,
        z_pos = 0,
        global_path1 = [],
        global_path2 = [],
        path1 = [],
        path2 = [];
      // meshArr.forEach(function(id){
      //     let mesh = store.scene.getMeshByUniqueID(parseInt(id));
      //     let center = mesh.getBoundingInfo().boundingBox.centerWorld;
      //     x_pos += center.x;
      //     y_pos += center.y;
      //     z_pos += center.z;
      // });

      // let centroid_floor = {x:x_pos/meshArr.length, y: y_pos/meshArr.length, z: z_pos/meshArr.length}
      let obj_height = 4 * store.unit_scale;
      let meshArrObj = [];
      let connArrForPoints = [];
      let defaultNearPoint = [];
      let thickness = 0;
      let ctr = 1;

      meshArr.forEach(function (id) {
        let mesh = store.scene.getMeshByUniqueID(parseInt(id));
        let center = mesh.getBoundingInfo().boundingBox.centerWorld;
        let vectorWorld = mesh.getBoundingInfo().boundingBox.vectorsWorld;
        //let meanHeight = (mesh.getBoundingInfo().maximum.y + mesh.getBoundingInfo().minimum.y)/2;
        thickness = mesh._properties._thickness / 2;

        let pathArr = getWallMeshPointsForFloor(
          mesh,
          floorId,
          defaultNearPoint,
          obj_height / 2
        );

        // let max_y = -9999, min_y = 9999;
        // let topArr =  pathArr.filter(function(point){
        //     return point.y <= meanHeight;
        // });

        // mesh.material = store.scene.getMaterialByName("floor_tile");
        // ctr += 2600;
        // setTimeout(function(mesh){
        //     mesh.material = store.scene.getMaterialByName("wall_mat");
        // }, ctr, mesh);
        // let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        // let newpoints = [];
        // for(let m=0;m<verData.length;m+=3){
        //     newpoints.push({x:verData[m],y:verData[m+1],z:verData[m+2]});
        // }

        // newpoints.map(point=> roundVector(point, 100));
        // newpoints = removeDuplicates(newpoints);
        meshArrObj.push({ mesh: mesh, facePoints: pathArr });
        //console.log(meshArrObj);
      });

      global_path1 = meshArrObj[0].facePoints;

      for (let n = 1; n < meshArrObj.length; n++) {
        try {
          let dist1 = calculateEuclidianDistance(
            [global_path1[2 * n - 1].x, global_path1[2 * n - 1].z],
            [meshArrObj[n].facePoints[0].x, meshArrObj[n].facePoints[0].z]
          );
          let dist2 = calculateEuclidianDistance(
            [global_path1[2 * n - 1].x, global_path1[2 * n - 1].z],
            [meshArrObj[n].facePoints[1].x, meshArrObj[n].facePoints[1].z]
          );
          if (dist1 < dist2) {
            global_path1.push(meshArrObj[n].facePoints[0]);
            global_path1.push(meshArrObj[n].facePoints[1]);
          } else {
            global_path1.push(meshArrObj[n].facePoints[1]);
            global_path1.push(meshArrObj[n].facePoints[0]);
          }
        } catch (e) {
          console.error(meshArrObj[n].facePoints, e);
          meshArrObj[n].mesh.material = store.scene.getMaterialByName("wall_mat4");
        }
        // if(getOrientation(roundVector(global_path1[2*n-2],100), roundVector(global_path1[2*n-1],100), roundVector(global_path1[2*n],100)) === 0){
        //     global_path1.splice(2*n-1,1);
        // }
      }

      try {
        if (
          calculateEuclidianDistance(
            [global_path1[0].x, global_path1[0].z],
            [global_path1[3].x, global_path1[3].z]
          ) <
          calculateEuclidianDistance(
            [global_path1[1].x, global_path1[1].z],
            [global_path1[3].x, global_path1[3].z]
          )
        ) {
          let t = global_path1[1];
          global_path1[1] = global_path1[0];
          global_path1[0] = t;
        }
      } catch (e) {
        console.error("Floor generation failed !", e);
      }

      //  defaultNearPoint = defaultNearPoint.map(item => (({x:item[0], y:item[1], z:0})));

      //  for(let ok=0;ok<defaultNearPoint.length-2;){
      //    if(getOrientation(roundVector(defaultNearPoint[ok],100), roundVector(defaultNearPoint[ok+1],100), roundVector(defaultNearPoint[ok+2],100)) === 0){
      //         defaultNearPoint.splice(ok+1,1);
      //     }
      //     else
      //         ok++;
      // }
      //  defaultNearPoint = defaultNearPoint.map(item => [item.x, item.y, item.z]);

      // global_path1.map(item => new Array(item.x, item.z, item.y));

      // for(let k=0;k<global_path1.length-3;k+=2){
      //     let len = global_path1.length;
      //     let point = line_intersect(global_path1[k][0], global_path1[k][1], global_path1[(k+1)%len][0], global_path1[(k+1)%len][1],
      //         global_path1[(k+2)%len][0], global_path1[(k+2)%len][1], global_path1[(k+3)%len][0], global_path1[(k+3)%len][1]);
      //     if(point){
      //         global_path1[(k+1)%len][0] = point.x;
      //         global_path1[(k+1)%len][1] = point.y;
      //         global_path1.splice((k+2)%len, 1);
      //     }
      // }
      // let myAqq = [];
      // global_path1.forEach(function(point){
      //     myAqq.push([point.x, point.z, point.y]);
      // });

      // let tempGlobal_path1 = offsetRoomPols(myAqq, thickness);
      // global_path1 = [];
      // tempGlobal_path1.forEach(function(item){
      //     global_path1.push({x:item[0] * store.unit_scale,z:item[1] * (-unit_scale), y:0});
      // });
      // for(let ok=0;ok<global_path1.length-2;){
      //    if(getOrientation(roundVector(global_path1[ok],100), roundVector(global_path1[ok+1],100), roundVector(global_path1[ok+2],100)) === 0){
      //         global_path1.splice(ok+1,1);
      //     }
      // }
      global_path1.map((item) => roundVector(item, 100));
      // global_path1 = removeDuplicates(global_path1);
      let tempIndexArr = [];

      for (let g = 0; g < global_path1.length - 2; g += 2) {
        // if(this._findCollinearM2(global_path1[g], global_path1[g+1], global_path1[g+2]) || this._findCollinear(global_path1[g], global_path1[g+1], global_path1[g+2])){
        //     global_path1.splice(g+1, 1);
        // }
        // else
        //     g++;
        let len = global_path1.length;
        let slope1 = this._getSlope(
          global_path1[g],
          global_path1[(g + 1) % len]
        );
        let slope2 = this._getSlope(
          global_path1[(g + 2) % len],
          global_path1[(g + 3) % len]
        );

        if (
          Math.abs(slope2 - slope1) < 2 &&
          this._findCollinear(
            global_path1[g],
            global_path1[(g + 1) % len],
            global_path1[(g + 2) % len],
            global_path1[(g + 3) % len]
          )
        ) {
          tempIndexArr.push((g + 1) % len);
          tempIndexArr.push((g + 2) % len);
        }
      }
      // tempIndexArr.forEach(function(index){
      //     global_path1.splice(index, 2);
      // });

      global_path1 = global_path1.filter(function (point, index) {
        return !tempIndexArr.includes(index);
      });

      let glen = global_path1.length / 2;
      tempIndexArr = [];
      for (let g = 0, j = 0; j < glen; j++, g += 2) {
        let l = global_path1.length;
        let point = line_intersect(
          global_path1[g].x,
          global_path1[g].z,
          global_path1[(g + 1) % l].x,
          global_path1[(g + 1) % l].z,
          global_path1[(g + 2) % l].x,
          global_path1[(g + 2) % l].z,
          global_path1[(g + 3) % l].x,
          global_path1[(g + 3) % l].z
        );
        if (point) {
          global_path1[(g + 1) % l].x = point.x;
          global_path1[(g + 1) % l].z = point.y;
          tempIndexArr.push((g + 2) % l);
          // global_path1.splice((g+1)%l,1);
        }
      }

      global_path1 = global_path1.filter(function (point, index) {
        return !tempIndexArr.includes(index);
      });
      tempIndexArr = [];

      // for(lo=2;lo<global_path1.length;lo+=2){
      //     global_path1.splice(lo,1);
      // // }
      //   let myAqq = [];
      // global_path1.forEach(function(point){
      //     myAqq.push([point.x/unit_scale, -point.z/unit_scale, 0]);
      // });

      // let tempGlobal_path1 = offsetRoomPols(myAqq, thickness);
      // global_path1 = [];
      // tempGlobal_path1.forEach(function(item){
      //     global_path1.push({x:item[0] * store.unit_scale,z:item[1] * (-unit_scale), y:0});
      // });

      for (let g = 0; g < global_path1.length; g++) {
        //global_path1[g] = roundVector(global_path1[g],100);
        let x = global_path1[g].x;
        let y = global_path1[g].y;
        let z = global_path1[g].z;

        global_path2.push(new BABYLON.Vector3(x, z, obj_height));
        path1.push(new BABYLON.Vector3(x, 0, z));
        path2.push(new BABYLON.Vector3(x, obj_height, z));
      }

      global_path1 = global_path1.map(
        (item) => new BABYLON.Vector3(item.x, item.z, 0)
      );

      let ribPol1 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        global_path1,
        store.scene
      ).build(true);
      // adjustCoords(ribPol1, coords1);
      let ribPol2 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        global_path2,
        store.scene
      ).build(true);
      ribPol2.position.y = obj_height;
      // adjustCoords(ribPol1, coords2);
      let ribbon = BABYLON.Mesh.CreateRibbon(
        "ribbon",
        [path1, path2],
        false,
        false,
        0,
        store.scene,
        true,
        BABYLON.DOUBLESIDE
      );

      let meshVert1 = ribPol1.getTotalVertices();
      let meshInd1 = ribPol1.getIndices().length;

      let meshVert2 = ribbon.getTotalVertices();
      let meshInd2 = ribbon.getIndices().length;
      // splitFaces(ribbon1);

      let meshVert3 = ribPol2.getTotalVertices();
      let meshInd3 = ribPol2.getIndices().length;

      var mergedMesh = BABYLON.Mesh.MergeMeshes(
        [ribPol1, ribPol2, ribbon],
        true,
        true
      );
      var $scope = store.angular.element(appElement).scope();
      $scope = $scope.$$childHead;

      mergedMesh.checkCollisions = true;
      mergedMesh.name = "Floor";
      mergedMesh.type = "floor";
      mergedMesh.checkCollisions = true;
      mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      // mergedMesh.level = mesh.level; TODO
      mergedMesh.convertToFlatShadedMesh();
      let bbinfo = mergedMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      mergedMesh.setPivotPoint(centroid);
      //mergedMesh.position.x = mesh.position.x;
      // mergedMesh.position.z = mesh.position.z;
      // mergedMesh.rotation = mesh.rotation;
      // mergedMesh.scaling = mesh.scaling;

      mergedMesh.id = makeid(5);
      //click(mergedMesh);
      mergedMesh.visibility = 1.0;
      mergedMesh.material = store.scene.getMaterialByName("floor_tile");
      mergedMesh.material.backFaceCulling = false;
      setUVScale(
        mergedMesh,
        bbinfo.boundingBox.extendSizeWorld.x / 5,
        bbinfo.boundingBox.extendSizeWorld.z / 5
      );
      unclick(mergedMesh);
      // adjustRoofCoords(mergedMesh, coords2, coords1, obj_height);
      assignProperties(mergedMesh, -1, "floor");
      resolve({
        mesh: mergedMesh,
        global_path1: JSON.parse(JSON.stringify(global_path1)),
      });
    } catch (e) {
      console.error(e);
      //reject("failed");
    }
  });
}

function generateFloorPath(mesh, floorid) {
  if (typeof floorid !== "number") {
    console.info("check generateFloorPath");
    floorid = floorid[0];
  }
  return new Promise(function (resolve, reject) {
    var floorWallArr = [];
    findFloorRecursive(mesh, floorid, floorWallArr);
    resolve(floorWallArr);
  });
}

function findFloorRecursive(mesh, floorid, floorWallArr) {
  if (mesh.visitedForFloor[floorid]) return;

  let startWallId = mesh.uniqueId;
  let neighbours = removeDuplicates(mesh.neighborsWallMesh);
  for (let i = 0; i < neighbours.length; i++) {
    let nid = neighbours[i].id;
    let flag = false;
    let curr_mesh = store.scene.getMeshByUniqueID(nid);

    if (!curr_mesh.visitedForFloor) curr_mesh.visitedForFloor = {};
    flag = curr_mesh.floor.includes(floorid);
    if (flag) {
      mesh.visitedForFloor[floorid] = true;
      floorWallArr.push({ mesh: curr_mesh, conn: startWallId + "-" + nid });
      findFloorRecursive(curr_mesh, floorid, floorWallArr);
    }
  }
}

function generateNewCeilingMesh(
  floormesh,
  global_path1,
  oldCeilMesh,
  level,
  roof_depth = 4
) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  let obj_height = roof_depth * store.unit_scale;
  global_path1 = global_path1.map(
    (item) => new BABYLON.Vector3(item.x, item.z, item.y)
  );

  return new Promise(function (resolve, reject) {
    let global_path2 = [],
      path1 = [],
      path2 = [];

    for (let g = 0; g < global_path1.length; g++) {
      //global_path1[g] = roundVector(global_path1[g],100);
      let x = global_path1[g].x;
      let y = global_path1[g].y;
      let z = global_path1[g].z;

      global_path2.push(new BABYLON.Vector3(x, z, obj_height));
      path1.push(new BABYLON.Vector3(x, 0, z));
      path2.push(new BABYLON.Vector3(x, obj_height, z));
    }

    let ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      global_path1,
      store.scene
    ).build(true);
    // adjustCoords(ribPol1, coords1);
    let ribPol2 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      global_path2,
      store.scene
    ).build(true);
    ribPol2.position.y = obj_height;
    // adjustCoords(ribPol1, coords2);
    let ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );

    var mergedMesh = BABYLON.Mesh.MergeMeshes(
      [ribPol1, ribPol2, ribbon],
      true,
      true
    );

    mergedMesh.checkCollisions = true;
    mergedMesh.name = "Roof";
    mergedMesh.type = "Roof";
    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    mergedMesh.level = oldCeilMesh.level;
    mergedMesh.structure = oldCeilMesh.structure;
    mergedMesh.convertToFlatShadedMesh();
    mergedMesh.material = store.scene.getMaterialByName("ceiling_mat");
    mergedMesh.material.backFaceCulling = false;

    let bbinfo = mergedMesh.getBoundingInfo();
    let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mergedMesh.setPivotPoint(centroid);
    //mergedMesh.position.x = floormesh;
    if (level)
      mergedMesh.position.y +=
        $scope.selectedItemLevelHeight[level + 1] / store.inch_to_mtr;

    mergedMesh.position.y =
      $scope.selectedItemLevelHeight[level + 2] / store.inch_to_mtr +
      0 * roof_depth * store.unit_scale;
    mergedMesh.position.y +=
      mergedMesh.getBoundingInfo().boundingBox.extendSizeWorld.y;
    mergedMesh.visibility = 0;
    mergedMesh.isPickable = false;
    mergedMesh.isVisible = false;
    unclick(mergedMesh);
    assignProperties(mergedMesh, -1, "roof");
    resolve(mergedMesh);
  });
}

function updateFloorIds(floorObj) {
  floorObj.commonParent.forEach(function (mesh) {
    if (typeof mesh.floor !== "number") {
      let len = mesh.floor.length;
      if (mesh.floor.length > 2) {
        for (let ok = 0; ok < mesh.floor.length; ok++) {
          if (
            floorObj.hasOwnProperty(mesh.floor[ok]) &&
            mesh.removeExtraFloor
          ) {
            mesh.floor.splice(ok, 1);
            delete mesh.removeExtraFloor;
          }
        }
      }
      mesh.floor = mesh.floor.map(function (id) {
        if (floorObj.hasOwnProperty(id)) return floorObj[id];
        return id;
      });
    }
  });

  delete floorObj.commonParent;
  floorObj.commonParent = [];
}

async function generateNewFloorPath(mesh) {
  let floors = JSON.parse(JSON.stringify(mesh.floor));
  let floorArrObj = {};
  let newFloorMeshIds = { commonParent: [] };
  mesh.visitedForFloor = {};

  if (floors.length > 1) {
    //limiting to only 2
    generateFloorPath(mesh, floors[0]).then(function (result) {
      floorArrObj[floors[0]] = result;
      let printArr = [];
      let noNode = [];

      result.forEach(function (connObj) {
        connObj.mesh.visitedForFloor = {};
        printArr.push(connObj.conn);
        noNode.push(connObj.mesh.uniqueId);
        //console.log(mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      });
      console.log(printArr);
      if (window.Worker) {
        var myWorker1 = new Worker("/static/js/libs/graph-worker.js");
        myWorker1.postMessage([printArr, noNode.uniq()]);
        myWorker1.onmessage = function (e) {
          console.log("Message received from worker", e.data);
          // let ctr =0;
          // e.data.forEach(function(id){
          //    let mesh = store.scene.getMeshByUniqueID(parseInt(id));
          //    mesh.material = store.scene.getMaterialByName("wall_mat4");
          //    ctr += 2000;
          //     setTimeout(function(mesh){
          //        mesh.material = store.scene.getMaterialByName("wall_mat");
          //    }, ctr, mesh);
          // });
          generateNewFloorMesh(e.data, floors[0]).then(function (floorMeshObj) {
            let floormesh = store.scene.getMeshByUniqueID(floors[0]);
            newFloorMeshIds[floors[0]] = floorMeshObj.mesh.uniqueId;
            e.data.forEach(function (id) {
              let cmesh = store.scene.getMeshByUniqueID(parseInt(id));
              let cflag = true;
              let tflag = true;
              if (cmesh.floor.length > 1) {
                floors.forEach(function (id) {
                  if (!cmesh.floor.includes(parseInt(id))) tflag = false;
                });
                if (cflag) {
                  newFloorMeshIds.commonParent.push(cmesh);
                }
              }
              if (cmesh.floor) {
                let index = cmesh.floor.indexOf(floors[0]);
                cmesh.floor.splice(
                  index,
                  1,
                  JSON.parse(JSON.stringify(floorMeshObj.mesh.uniqueId))
                );
              }
            });
            let childMesh = floormesh.getChildMeshes();
            generateNewCeilingMesh(
              floorMeshObj.mesh,
              floorMeshObj.global_path1,
              childMesh,
              mesh.level
            )
              .then(function (newCeiling) {
                newCeiling.parent = floorMeshObj.mesh;
                childMesh[0].dispose();
                // console.log(newFloorMeshIds);
              })
              .catch(function () {
                console.error("Roof generation failed !");
              });
            floormesh.dispose();
            setTimeout(updateModifications, 500);
            myWorker1.terminate();
          });
        };
      }
    });
    generateFloorPath(mesh, floors[1]).then(function (result) {
      floorArrObj[floors[1]] = result;
      let printArr = [];
      let noNode = [];

      result.forEach(function (connObj) {
        connObj.mesh.visitedForFloor = {};
        printArr.push(connObj.conn);
        noNode.push(connObj.mesh.uniqueId);
        //console.log(mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      });
      console.log(printArr);
      if (window.Worker) {
        var myWorker2 = new Worker("/static/js/libs/graph-worker.js");
        myWorker2.postMessage([printArr, noNode.uniq()]);
        myWorker2.onmessage = function (e) {
          console.log("Message received from worker", e.data);
          // let ctr=1;
          // e.data.forEach(function(id){
          //     let tmesh = store.scene.getMeshByUniqueID(parseInt(id));
          //     tmesh.material = store.scene.getMaterialByName("floor_tile");
          //     ctr += 600;
          //      setTimeout(function(mesh){
          //         tmesh.material = store.scene.getMaterialByName("wall_mat");
          //     }, ctr, mesh);
          // });
          generateNewFloorMesh(e.data, floors[1]).then(function (floorMeshObj) {
            let floormesh = store.scene.getMeshByUniqueID(floors[1]);
            newFloorMeshIds[floors[1]] = floorMeshObj.mesh.uniqueId;
            e.data.forEach(function (id) {
              let tmesh = store.scene.getMeshByUniqueID(parseInt(id));
              let tflag = true;
              if (tmesh.floor.length > 1) {
                floors.forEach(function (id) {
                  if (!tmesh.floor.includes(parseInt(id))) tflag = false;
                });
                if (tflag) {
                  newFloorMeshIds.commonParent.push(tmesh);
                }
              }
              if (tmesh.floor) {
                let index = tmesh.floor.indexOf(floors[1]);
                tmesh.floor.splice(
                  index,
                  1,
                  JSON.parse(JSON.stringify(floorMeshObj.mesh.uniqueId))
                );
              }
            });
            let childMesh = floormesh.getChildMeshes();
            generateNewCeilingMesh(
              floorMeshObj.mesh,
              floorMeshObj.global_path1,
              childMesh,
              mesh.level
            )
              .then(function (newCeiling) {
                newCeiling.parent = floorMeshObj.mesh;
                updateFloorIds(newFloorMeshIds);
                childMesh[0].dispose();
              })
              .catch(function () {
                console.error("Roof generation failed !");
                updateFloorIds(newFloorMeshIds);
              });
            floormesh.dispose();
            setTimeout(updateModifications, 400);
            myWorker2.terminate();
          });
        };
      }
    });
  } else {
    generateFloorPath(mesh, floors).then(function (result) {
      floorArrObj[floors[0]] = result;
      let printArr = [];
      let noNode = [];
      result.forEach(function (connObj) {
        connObj.mesh.visitedForFloor = {};
        printArr.push(connObj.conn);
        noNode.push(connObj.mesh.uniqueId);
        //console.log(mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      });
      console.log(printArr);
      if (window.Worker) {
        var myWorker = new Worker("/static/js/libs/graph-worker.js");
        myWorker.postMessage([printArr, noNode.uniq()]);
        myWorker.onmessage = function (e) {
          console.log("Message received from worker", e.data);
          //let ctr =0;
          //  e.data.forEach(function(id){
          //     let mesh = store.scene.getMeshByUniqueID(parseInt(id));
          //     mesh.material = store.scene.getMaterialByName("floor_tile");
          //     ctr += 2000;
          //      setTimeout(function(mesh){
          //         mesh.material = store.scene.getMaterialByName("wall_mat");
          //     }, ctr, mesh);
          // });
          generateNewFloorMesh(e.data, floors).then(function (floorMeshObj) {
            newFloorMeshIds[floors] = floorMeshObj.mesh.uniqueId;
            floors = floors[0];
            let floormesh = store.scene.getMeshByUniqueID(floors);
            e.data.forEach(function (id) {
              let emesh = store.scene.getMeshByUniqueID(parseInt(id));
              if (emesh.floor) {
                let index = emesh.floor.indexOf(floors);
                emesh.floor.splice(
                  index,
                  1,
                  JSON.parse(JSON.stringify(floorMeshObj.mesh.uniqueId))
                );
              }
            });
            let childMesh = floormesh.getChildMeshes();
            generateNewCeilingMesh(
              floorMeshObj.mesh,
              floorMeshObj.global_path1,
              childMesh,
              mesh.level
            )
              .then(function (newCeiling) {
                newCeiling.parent = floorMeshObj.mesh;
                childMesh[0].dispose();
                updateFloorIds(newFloorMeshIds);
              })
              .catch(function () {
                console.error("Roof generation failed !");
                updateFloorIds(newFloorMeshIds);
              });
            floormesh.dispose();
            setTimeout(updateModifications, 400);
            myWorker.terminate();
          });
        };
      }
    });
  }
}
export { assignFloorForExtendedMesh,assignFloorAndRoofForNewMesh,getWallMeshPointsForFloor,findFloorForNewWall,generateNewFloorMesh,generateFloorPath,findFloorRecursive,generateNewCeilingMesh,updateFloorIds,generateNewFloorPath };
