import BABYLON from "../babylonDS.module";
import { store } from "../utilityFunctions/Store";
import { labelView } from "../../libs/labelView";
import { lineWithSlopeAndAtDistanceFromPoint } from "../../libs/twoD/twoServices";
import { terrainGeneration } from "../geo/terrainMap";
import tzlookup from "tz-lookup";
import * as luxon from "luxon";
import $ from "jquery";
import { sphtocat } from "../../libs/snapUtilities";
import { computeBounds } from "../../libs/zoomFuncs";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { isMeshThrowAway, onSolid } from "../extrafunc";
import * as SunCalc from "suncalc";
import { nonDefaultMesh } from "../../libs/sceneStateFuncs";
import { disableEdgesRendering } from "../../libs/sceneFuncs";
import { isNeighborhoodBuilding } from "../geo/terrainNeighborhood";
import _ from "lodash";
var sunpath = (function () {
  let sector, lines2;
  const _createAngleLine = (pt, angle, center) => {
    let scale = 1.01;
    if (angle % 15 === 0) scale = 1.03;
    let p1 = pt;
    let p2 = pt.scale(scale);
    let line = BABYLON.Mesh.CreateLines(
      "sunpathSectorLine",
      [p1, p2],
      store.scene
    );
    line.color = BABYLON.Color3.Black();
    if (angle % 15 === 0) {
      let input = labelView.createInput("sunpathSectorAngle");
      input.linkWithMesh(line);
      input.text = angle;
      input.linkOffsetXInPixels = 15 * Math.sin((angle * Math.PI) / 180);
      input.linkOffsetYInPixels = -15 * Math.cos((angle * Math.PI) / 180);
      input.isFocusInvisible = true;
      input.isHitTestVisible = false;
      input.isPointerBlocker = true;
    }
    line.position.x += center.x;
    line.position.z += center.z;
    return line;
  };

  const _createSunDirectionLine = (center, sunPos) => {
    let line = BABYLON.Mesh.CreateLines(
      "sunLine",
      [center, sunPos],
      store.scene
    );
    line.color = BABYLON.Color3.Blue();
  };

  const drawTrueNorth = (center, north, angle, sector) => {
    let points = [center, north];
    let p1 = north;
    let p2 = new BABYLON.Vector3(p1.x, 0, p1.z);
    p2.z = p2.z * 0.97;
    let [p3, p4] = lineWithSlopeAndAtDistanceFromPoint([p2.x, p2.z], 0, 0.7);
    p3 = new BABYLON.Vector3(p3[0], 0, p3[1]);
    p4 = new BABYLON.Vector3(p4[0], 0, p4[1]);
    let line = BABYLON.MeshBuilder.CreateLines(
      "sunpathNorthLine",
      { points: [center, north, p3, p4, north] },
      store.scene
    );
    line.color = BABYLON.Color3.Black();

    let line2 = BABYLON.MeshBuilder.CreateLines(
      "sunpathNorthLine",
      { points: [p2, north] },
      store.scene
    );
    line2.color = BABYLON.Color3.Black();
    let input = labelView.createInput("northAngleLabel");
    input.linkWithMesh(line2);
    input.text = "N";
    input.linkOffsetXInPixels = 20;
    line2.parent = sector;
    line.parent = sector;
    input.isFocusInvisible = true;
    input.isHitTestVisible = false;
    input.isPointerBlocker = true;
  };

  const _drawAngleCircle = (radius, center) => {
    let angle = 2 * Math.PI;
    let points = [];
    let minNb = 4;
    let factor = 1;
    let vector = new BABYLON.Vector3(0, 0, 1);
    let cross = new BABYLON.Vector3(0, 1, 0);
    let nbPoints = Math.floor(((angle * 180) / Math.PI) * factor);
    nbPoints = nbPoints < minNb ? minNb : nbPoints;
    let firstPoint = BABYLON.Vector3.Normalize(vector).scale(radius);
    let matrix;
    let ang = angle / nbPoints;
    let rotated;
    let lines = [];
    sector = BABYLON.Mesh.CreateLines("sunpathSectorArc", points, store.scene);
    sector.color = BABYLON.Color3.Black();
    sector.position.x += center.x;
    sector.position.z += center.z;
    for (var i = 0; i < nbPoints; i++) {
      matrix = BABYLON.Matrix.RotationAxis(cross, ang * i);
      rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
      let line = _createAngleLine(rotated, i, center);
      line.parent = sector;
      // lines.push();
      points.push(rotated.add(origin));
    }
    // points.push(lastPoint.add(origin));
    return sector;
    // let secSys = BABYLON.Mesh.MergeMeshes([sector, ...lines], true);
    // secSys.name = "sunpathSector";
    // secSys.position.x += center.x;
    // secSys.position.z += center.z;
  };

  const clearSunpath = () => {
    let sectorArc = store.scene.getMeshByName("sunpathSectorArc");
    let sunPathRibbon = store.scene.getMeshByName("sunpathribbon");
    if (sunPathRibbon) {
      sunPathRibbon.dispose();
    }
    for (let i = 0; i < 2; i++) {
      let northLine = store.scene.getMeshByName("sunpathNorthLine");
      if (northLine) northLine.dispose();
    }
    if (sectorArc) {
      sectorArc.dispose();
    }
    for (let i = 0; i < 360; i++) {
      let lineArc = store.scene.getMeshByName("sunpathSectorLine");
      if (lineArc) lineArc.dispose();
    }
    store.advancedTexture.executeOnAllControls((control) => {
      if (control) {
        control.dispose();
      }
    });

    for (let i = 0; i < 200; i++) {
      let sunpath = store.scene.getMeshByName("sunpathLines");
      if (sunpath) {
        sunpath.dispose();
      } else {
        break;
      }
    }
  };

  const toggleSunpath = () => {
    let sunpath = store.scene.getMeshByName("sunpathLines");
    if (sunpath) {
      clearSunpath();
      // $("#sunpath_button").css("border", "none");
    } else {
      const bounds = computeBounds();
      const visPos = bounds.boundingSphere.center;
      let r = [
        bounds.boundingBox.extendSizeWorld.x,
        bounds.boundingBox.extendSizeWorld.y,
        bounds.boundingBox.extendSizeWorld.z,
      ].max();
      if (r < 70) {
        r *= 3.5;
      } else if (70 <= r && r < 150) {
        r *= 2.25;
      } else if (150 <= r && r < 300) {
        r *= 1.5;
      }

      let sector = _drawAngleCircle(r, visPos);
      drawTrueNorth(
        new BABYLON.Vector3(visPos.x, 0, visPos.z),
        new BABYLON.Vector3(visPos.x, 0, visPos.z + r * 1.2),
        0,
        sector
      );

      let latlong = store.lastSearchedLocationData?.lastKnownCoordinates;
      let northAngle = 0;
      let terr = store.scene.getMeshByName("terrain");
      if (terr) {
        if (terr.rotationQuaternion) {
          let rotAngle = terr.rotationQuaternion.toEulerAngles().y;
          if (rotAngle > 0) {
            northAngle += 2 * Math.PI + rotAngle;
          } else {
            northAngle += rotAngle;
          }
        }
      }
      if(!latlong){
        latlong = {
          lat: 38.907,
          lng: -77.04
        }
      }
      // For Old Projects where map center is stored as an array.
      else if(!_.isEmpty(latlong) && _.isArray(latlong)){
        latlong = {
          lat: latlong[1],
          lng: latlong[0]
        }
      }
      const t = tzlookup(latlong.lat, latlong.lng);
      const dtemp = new Date();
      let dt1 = luxon.DateTime.local().setZone(t);
      let points = [];
      let excludeMonths = [11, 7, 8, 9, 10];
      let ribbonMonths = [6, 12];
      let ribbonPath = [];
      for (let i = 1; i < 13; i++) {
        let pts = [];
        for (let j = 1; j < 2; j++) {
          for (let k = 0; k < 24; k++) {
            for (let m = 0; m < 60; m += 5) {
              if (i === 2 && j > 28) continue;
              let dt = dt1.set({ month: i, day: 21, hour: k, minute: m });
              // if (dt.isInDST){
              //     dt = dt1.set({month: i, day: 21, hour: k+1, minute: m});
              // }

              const date = new Date(dt.toString());
              let angles = SunCalc.getPosition(date, latlong.lat, latlong.lng);
              let pos = sphtocat(angles, r);
              let dir = new BABYLON.Vector3(1, -1, 1);
              const dirDiff = pos.subtract(new BABYLON.Vector3.Zero());
              dir.y = -(dirDiff.y / dirDiff.length());
              if (angles.altitude > 0) {
                pos.x += visPos.x;
                pos.z += visPos.z;
                pts.push(pos);
              }
            }
          }
        }
        if (pts[0]) {
          if (excludeMonths.includes(i)) continue;

          const options = {
            points: pts, //vec3 array,
            dashNb: 200,
            updatable: true,
          };
          let lines;
          if (i === 12 || i === 6) {
            lines = BABYLON.Mesh.CreateLines("sunpathLines", pts, store.scene);
          } else {
            lines = BABYLON.MeshBuilder.CreateDashedLines(
              "sunpathLines",
              options,
              store.scene
            );
          }
          lines.parent = sector;
          lines.color = new BABYLON.Color3(1.0, 0.2, 0.2);
          ribbonPath.push(pts);

          // if (ribbonMonths.includes(i)){
          // }
        }
      }
      const options = {
        pathArray: ribbonPath, //[vector3 array, vector3 array, vector3 array......]
        //updatable: true,
        //closePath: true,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      };
      let ribbon = BABYLON.MeshBuilder.CreateRibbon(
        "sunpathribbon",
        options,
        store.scene
      );
      ribbon.material = new BABYLON.StandardMaterial(
        "ribbonMaterial",
        store.scene
      );
      ribbon.material.diffuseColor = BABYLON.Color3.Yellow();
      ribbon.material.alpha = 0.15;
      ribbon.material.emissiveColor = BABYLON.Color3.Yellow();
      ribbon.parent = sector;
      points = [];
      for (let k = 0; k < 24; k++) {
        for (let i = 1; i < 13; i++) {
          for (let j = 1; j < 32; j += 6) {
            if (i === 1 && j > 28) continue;
            let dt;
            dt = dt1.set({ month: i, day: j, hour: k, minute: 0 });
            if (dt.isInDST) {
              dt = dt1.set({ month: i, day: j, hour: k + 1, minute: 0 });
            }
            const date = new Date(dt.toString());
            let angles = SunCalc.getPosition(date, latlong.lat, latlong.lng);
            let pos = sphtocat(angles, r);
            let dir = new BABYLON.Vector3(1, -1, 1);
            const dirDiff = pos.subtract(new BABYLON.Vector3.Zero());
            dir.y = -(dirDiff.y / dirDiff.length());
            if (dir.y < 0) {
              pos.x += visPos.x;
              pos.z += visPos.z;
              points.push(pos);
            }
          }
        }
        const options = {
          points: points,
          dashNb: 200,
          //dashSize: 2,
          //gapSize: 0.1,
          //updatable: true,
        };
        lines2 = BABYLON.MeshBuilder.CreateDashedLines(
          "sunpathLines",
          options,
          store.scene
        );
        lines2.parent = sector;
        lines2.color = new BABYLON.Color3(1.0, 0.3, 0.3);
        points = [];
      }
      sector.setPivotPoint(new BABYLON.Vector3(visPos.x, 0, visPos.z));
      sector.position.x -= visPos.x;
      sector.position.z -= visPos.z;
      sector.rotation.y = northAngle;
      $("#sunpath_button").css("border", "1px solid #d30041");
    }
  };

  const changeSunPos = (month, day, time) => {

    store.$scope.month = ( month ) ? month : 6;
    store.$scope.date = ( day ) ? day : 16;
    store.$scope.time = ( time ) ? time : 25;

    month = month.toString();
    day = day.toString();
    time = time.toString();
    let hour, minute;
    if (time % 2) {
      hour = Math.ceil(time / 2);
      minute = 0;
    } else {
      hour = Math.ceil(time / 2);
      minute = 30;
    }
    let sunSphere = store.scene.getMeshByName("sunSphere");

    if(sunSphere){

      let latlong = store.lastSearchedLocationData?.lastKnownCoordinates;
      if(!latlong){
        latlong = {
          lat: 38.907,
          lng: -77.04
        }
      }
      // For Old Projects where map center is stored as an array.
      else if(!_.isEmpty(latlong) && _.isArray(latlong)){
        latlong = {
          lat: latlong[1],
          lng: latlong[0]
        }
      }
      const t = tzlookup(latlong.lat, latlong.lng);
      let dt1 = luxon.DateTime.local(
        2020,
        parseInt(month) + 1,
        parseInt(day),
        hour,
        minute
      ).setZone(t);
      let dt = dt1.set({ hour: hour, minute: minute });
      if (dt.isInDST) {
        dt = dt1.set({ hour: hour + 1, minute: minute });
      }

      const date = new Date(dt.toString());
      // const date = new Date(2020, parseInt(month), parseInt(day), hour, minute, 0, 0);
      /**
       * Calculate the new position and direction of the sun
       */

      let angles = SunCalc.getPosition(date, latlong.lat, latlong.lng);
      let terr = store.scene.getMeshByName("terrain");
      if (terr) {
        if (terr.rotationQuaternion) {
          let rotAngle = terr.rotationQuaternion.toEulerAngles().y;
          if (rotAngle > 0) {
            angles.azimuth += 2 * Math.PI + rotAngle;
          } else {
            angles.azimuth += rotAngle;
          }
        }
      }
      const pos = sphtocat(angles, sunSphere.radius);

      let l = store.terrainMapData?.bounds;
      if(!l){
        l = {
          "_sw": {
            "lng": -77.04651846254605,
            "lat": 38.90321444514254
          },
          "_ne": {
            "lng": -77.03348153745493,
            "lat": 38.911269609540454
          }
        }
      }

      let d = terrainGeneration.terrainBoundsInMeters(
        l._ne.lat,
        l._sw.lat,
        l._ne.lng,
        l._sw.lng
      );
      d = DisplayOperation.getOriginalDimension(d, "meters");
      const latlongpos = new BABYLON.Vector3(d / 2, 0, -d / 2);

      let dir = new BABYLON.Vector3(1, -1, 1);
      // const dirDiff = latlongpos.subtract(pos);
      const dirDiff = pos.subtract(new BABYLON.Vector3.Zero());
      dir.x = -(dirDiff.x / dirDiff.length());
      dir.y = -(dirDiff.y / dirDiff.length());
      dir.z = -(dirDiff.z / dirDiff.length());

      if (dir.y > 0) {
        dir.x = 0;
        dir.y = 0;
        dir.z = 0;
      }

      let dirLight = store.scene.getLightByName("dirLight");
      let sunSphere2 = store.scene.getMeshByName("sunSphere2");
      sunSphere.position = pos;
      sunSphere.position.x += sunSphere.visPos.x;
      sunSphere.position.z += sunSphere.visPos.z;
      sunSphere2.position = sunSphere.position;
      dirLight.position = pos;
      dirLight.direction = dir;

    }
  };

  function enableEdgesRenderingOfNeighborhood() {
    store.scene.meshes.forEach((m) => {
      if (isNeighborhoodBuilding(m)) {
        m.enableEdgesRendering();
      }
    });
  }

  const clearShadows = () => {
    let hemiLight = store.scene.getLightByName("light1");
    if (hemiLight) hemiLight.intensity = 0.6;

    let sunSphere = store.scene.getMeshByName("sunSphere");
    if (sunSphere) sunSphere.dispose();

    let sunSphere2 = store.scene.getMeshByName("sunSphere2");
    if (sunSphere2) sunSphere2.dispose();

    let dirLight = store.scene.getLightByName("dirLight");
    if (dirLight) {
      dirLight.intensity = 0;
      dirLight.getShadowGenerator().dispose();
      dirLight.dispose();
    }

    let ground = store.scene.getMeshByName("ground1");
    ground.renderingGroupId = -1.0;
    if(store.scene.activeCamera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA){
      onSolid(null, true, {isOrtho: true});
    }
    else {
      onSolid();
    }

    enableEdgesRenderingOfNeighborhood();
  };

  const toggleShadows = (options = {}) => {
    let hemiLight = store.scene.getLightByName("light1");
    if (hemiLight.intensity !== 0) {
      hemiLight.intensity = 0;
      // sunpathDiagram();

      let latlong = store.lastSearchedLocationData?.lastKnownCoordinates;
      if(!latlong){
        latlong = {
          lat: 38.907,
          lng: -77.04
        }
      }
      // For Old Projects where map center is stored as an array.
      else if(!_.isEmpty(latlong) && _.isArray(latlong)){
        latlong = {
          lat: latlong[1],
          lng: latlong[0]
        }
      }
      const t = tzlookup(latlong.lat, latlong.lng);
      const dtemp = new Date();
      let dt1 = luxon.DateTime.local().setZone(t);
      let dt = dt1.set({ hour: dtemp.getHours(), minute: dtemp.getMinutes() });
      const date = new Date(dt.toString());

      let angles = SunCalc.getPosition(date, latlong.lat, latlong.lng);
      let terr = store.scene.getMeshByName("terrain");
      if (terr) {
        if (terr.rotationQuaternion) {
          let rotAngle = terr.rotationQuaternion.toEulerAngles().y;
          if (rotAngle > 0) {
            angles.azimuth += 2 * Math.PI + rotAngle;
          } else {
            angles.azimuth += -rotAngle;
          }
        }
      }
      const bounds = computeBounds();
      const visPos = bounds.boundingSphere.center;
      let r = [
        bounds.boundingBox.extendSizeWorld.x,
        bounds.boundingBox.extendSizeWorld.y,
        bounds.boundingBox.extendSizeWorld.z,
      ].max();
      if (r < 70) {
        r *= 3.25;
      } else if (70 <= r && r < 150) {
        r *= 2.25;
      } else if (150 <= r && r < 300) {
        r *= 1.5;
      }

      const pos = sphtocat(angles, r);

      let l = store.terrainMapData?.bounds;
      if(!l){
        l = {
          "_sw": {
            "lng": -77.04651846254605,
            "lat": 38.90321444514254
          },
          "_ne": {
            "lng": -77.03348153745493,
            "lat": 38.911269609540454
          }
        }
      }
      let d = terrainGeneration.terrainBoundsInMeters(
        l._ne.lat,
        l._sw.lat,
        l._ne.lng,
        l._sw.lng
      );
      d = DisplayOperation.getOriginalDimension(d, "meters");
      const latlongpos = new BABYLON.Vector3(d / 2, 0, -d / 2);
      let dir = new BABYLON.Vector3(1, -1, 1);
      const dirDiff = pos.subtract(new BABYLON.Vector3.Zero());
      dir.x = -(dirDiff.x / dirDiff.length());
      dir.y = -(dirDiff.y / dirDiff.length());
      dir.z = -(dirDiff.z / dirDiff.length());

      let sunSphere = BABYLON.MeshBuilder.CreateSphere(
        "sunSphere",
        { diameter: 4 },
        store.scene
      );
      sunSphere.position = pos;
      sunSphere.material = new BABYLON.StandardMaterial();
      sunSphere.material.diffuseColor = new BABYLON.Color3(1, 0, 0);
      sunSphere.position.x += visPos.x;
      sunSphere.position.z += visPos.z;
      sunSphere.visPos = visPos;
      sunSphere.radius = r;
      sunSphere.isMeshThrowAway = true;

      let sunSphere2 = BABYLON.MeshBuilder.CreateSphere(
        "sunSphere2",
        { diameter: 18 },
        store.scene
      );
      sunSphere2.position = sunSphere.position;
      sunSphere2.material = new BABYLON.StandardMaterial();
      sunSphere2.material.alpha = 0.5;
      sunSphere2.material.diffuseColor = new BABYLON.Color3(1, 0, 0);
      sunSphere2.material.emissiveColor = new BABYLON.Color3(1, 1, 0);
      sunSphere2.isMeshThrowAway = true;

      var dirLight = new BABYLON.DirectionalLight("dirLight", dir, store.scene);
      dirLight.position = pos;
      dirLight.autoCalcShadowZBounds = true;
      dirLight.intensity = 0.8;
      let shadowGenerator = new BABYLON.ShadowGenerator(1024, dirLight);

      if (!store.$scope.isTwoDimension) {
        shadowGenerator = new BABYLON.CascadedShadowGenerator(
          2048,
          dirLight,
          true
        );
      }
      shadowGenerator.usePercentageCloserFiltering = true;
      shadowGenerator.numCascades = 4;
      shadowGenerator.lamda = 1;
      // shadowGenerator.cascadeBlendPercentage = 0.05;
      shadowGenerator.stabilizeCascades = false;
      shadowGenerator.bias = 0.0003; //default is 0.00005, helps reduce the shadow artifacts
      // shadowGenerator.freezeShadowCastersBoundingInfo = true;
      shadowGenerator.autoCalcDepthBounds = true;
      shadowGenerator.autoCalcDepthBoundsRefreshRate = 30;
      shadowGenerator.depthClamp = true;
      shadowGenerator.filteringQuality = BABYLON.ShadowGenerator.QUALITY_HIGH;

      for (let i = 0; i < store.scene.meshes.length; i++) {
        if (
          nonDefaultMesh(store.scene.meshes[i]) &&
          !isMeshThrowAway(store.scene.meshes[i]) &&
          !store.scene.meshes[i].name.includes("terrain")
        ) {
          // if (store.scene.meshes[i].getSnaptrudeDS()){
          shadowGenerator.getShadowMap().renderList.push(store.scene.meshes[i]);
          // }
        }
        if (store.scene.meshes[i].isAnInstance) {
          store.scene.meshes[i].sourceMesh.receiveShadows = true;
        } else {
          store.scene.meshes[i].receiveShadows = true;
        }
      }
      let ground = store.scene.getMeshByName("ground1");

      let terrain = store.scene.getMeshByName("terrain");
      if (terrain) {
        terrain.receiveShadows = true;
        terrain.renderingGroupId = 0.0;
      } else {
        ground.receiveShadows = true;
        ground.renderingGroupId = 0.0;
      }
      if( store.scene.activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
        disableEdgesRendering();
      }
    } else {
      if (options && options.goingInto2D) {
        clearShadows();
        toggleShadows();
      } else {
        clearShadows();
      }
    }
  };

  return {
    _createAngleLine,
    _drawAngleCircle,
    toggleSunpath,
    toggleShadows,
    clearShadows,
    clearSunpath,
    changeSunPos,
  };
})();

export default sunpath;