/*jshint esversion: 6 */
"use strict"
import * as babylon from "babylonjs";
import {AdvancedDynamicTexture, Control, Ellipse, Line, MultiLine, Image, InputText, Rectangle, TextBlock, Checkbox, Button} from "babylonjs-gui";
import * as babylonMaterials from "babylonjs-materials";
import * as babylonSerializers from "babylonjs-serializers";
import earcut from "earcut";
import _ from "lodash";
import { store } from "./utilityFunctions/Store.js";
import { meshObjectMapping } from "./snaptrudeDS/mapping.js";
import { StructureCollection } from "./snaptrudeDS/structure.ds.js";
import {
  findMaterialByID,
  meshVisibilityProcessing,
} from "../libs/sceneStateFuncs.js";
import { backwardCompatibilityChecker } from "./backwardCompatibility/backwardCompatibility.js";
import { makeid } from "../libs/arrayFuncs.js";
import {convertBrepPositionsFromTypedArrayToArray, generateBrepFromPositionsAndCells} from "../libs/brepOperations.js";
import {
  getNearestFaceOrientation,
  changeOrderForEarcut,
} from "../libs/massModeling.js";
import {
  convertLocalVector3ToGlobal,
  correctMeshNormals,
  onSolid,
  updateSubMeshes,
  isMeshThrowAway,
  removeMeshThrowAwayIdentifier, getDistanceBetweenVectors,
} from "./extrafunc.js";
import { getNormalVector } from "../libs/mathFuncs.js";
import { setLayerTransperancy } from "../libs/sceneFuncs.js";
import { GLOBAL_CONSTANTS } from "./utilityFunctions/globalConstants.js";
import { meshUniqueIdMapper } from "./utilityFunctions/meshUniqueIdMapper.js";
import { scenePickController } from "./utilityFunctions/scenePickController.js";
import { getDynamicThreshold, isCADMesh } from "../libs/snapUtilities";
import snapEngine from "./snappingEngine/snapEngine";

const snapmda = window.snapmda;
const BABYLON = {...babylon, ...babylonMaterials, ...babylonSerializers};


// BABYLON.AbstractMesh.prototype.getUniqueID = function(){
//     if(this.uniqueId){
//         return this.uniqueId
//     }
//     else{
//         console.log("uniqueId for this mesh does not exist")
//     }
// }

// BABYLON.AbstractMesh.prototype.setUniqueID = function(id){
//     this.uniqueId = id;
// }

// BABYLON.AbstractMesh.prototype.uniqueId = get{
//     console.log("here")
// }

// Object.defineProperty(BABYLON.AbstractMesh.prototype, 'uniqueId', {
//     get: function uniqueId(){
//         // console.log("In the getter")
//         // if(this.uniqueId){
//         //     return this.uniqueId
//         // }
//         // else{
//         //     console.log("uniqueId for this mesh does not exist")
//         // }
//         return this.snaptrudeUniqueId
//     },
//     set: function uniqueId(id){
//         // console.log("In the setter")
//         this.snaptrudeUniqueId = id;
//     }
// });

BABYLON.AbstractMesh.prototype.getSnaptrudeDS = function (throwError=true) {
  // let t0 = performance.now();
  let obj = meshObjectMapping.getObjectByMesh(this, throwError);
  // let t1 = performance.now();
  // let timeTaken = t1 - t0;
  // storeMaxTimeTaken(timeTaken);
  // console.log("Time Taken by new getSnaptrudeDS = " + timeTaken + "ms");
  return obj;
};

// let t0 = performance.now()

// let t1 = performance.now()
// let timeTaken = t1 - t0
// storeMaxTimeTaken(timeTaken)

// BABYLON.AbstractMesh.prototype.getSnaptrudeDS = function () {
//      let t0 = performance.now()
//     if(!this.structure_id) {
//         console.error("Structure ID not assigned!");
//         console.error(this.name);
//         return;
//     }

//     let sc = StructureCollection.getInstance();
//     let structure = sc.getStructureById(this.structure_id);
//     if (!structure) return null;

//     let object;
//     if (this.type) {
//         if (this.type.toLowerCase() === 'floorplan')
//             object = structure.getStoreyData().getStoreyByValue(this.storey).layerData.getObjectByUniqueId(this.uniqueId);
//         else
//             object = structure.getObjectByUniqueId(this.uniqueId);
//     }
//     if(!object) return null;
//    let t0 = performance.now()

//     let t1 = performance.now()
//     let timeTaken = t1 - t0
//     console.log("Time Taken by old getSnaptrudeDS = " + timeTaken + "ms");
//     storeMaxTimeTaken(timeTaken)
//     return object;
// };
// let t1 = performance.now()
// let timeTaken = t1 - t0
// storeMaxTimeTaken(timeTaken)

BABYLON.AbstractMesh.prototype.snaptrudeFunctions = function () {
  let _this = this;
  return {
    /**
     * Provides the a new VertexData Object with Positions, Indices, Normals, UVs
     */
    getVertexData: function () {
      let verData = new BABYLON.VertexData();
      verData.positions = _this.getVerticesData(
        BABYLON.VertexBuffer.PositionKind
      );
      verData.indices = _this.getIndices();
      verData.normals = _this.getVerticesData(BABYLON.VertexBuffer.NormalKind);
      verData.uvs = _this.getVerticesData(BABYLON.VertexBuffer.UVKind);
      return verData;
    },

    /**
     * Creates a new VertexData Object with Positions, Indices, Normals, UVs of the given mesh and assigns it to the passed mesh
     * @param {*} mesh
     */
    copyVertexDataToMesh: function (mesh) {
      this.getVertexData().applyToMesh(mesh, true);
    },
  };
};

BABYLON.AbstractMesh.prototype.getSnaptrudeLevel = function () {
  if (!this.structure_id) throw "Structure not assigned !";

  let sc = StructureCollection.getInstance();
  let structure = sc.getStructureById(this.structure_id);

  let object = structure.getObjectByUniqueId(this.uniqueId);
  if (!object) return null; //throw  "object not found!";
  let level = structure.getLevelByUniqueId(object.level_id);
  if (!level) throw "level not found";
  return level;
};

BABYLON.Mesh.prototype.copyWorldMatrixProperties = function (
  otherMeshOrInstanceOrClone
) {
  this.setAbsolutePosition(
    otherMeshOrInstanceOrClone.getAbsolutePosition().clone()
  );
  this.scaling.copyFrom(otherMeshOrInstanceOrClone.scaling);
  this.rotation.copyFrom(otherMeshOrInstanceOrClone.rotation);
  if (otherMeshOrInstanceOrClone.rotationQuaternion) {
    if (!this.rotationQuaternion)
      this.rotationQuaternion = BABYLON.Quaternion.Zero();
    this.rotationQuaternion.copyFrom(
      otherMeshOrInstanceOrClone.rotationQuaternion
    );
  }
  else {
    this.rotationQuaternion = null;
  }
};

/**
 * TODO: To be shifted to BabylonDS.module.js after pull from rest3d
 * @param id
 */
 BABYLON.Mesh.prototype.getSubMeshByID = function (id) {
  var subMesh = null;
  this.subMeshes.forEach(function (sMesh) {
    if (sMesh._id === id) {
      //console.log(sMesh);
      subMesh = sMesh;
      return;
    }
  });
  return subMesh;
};

BABYLON.InstancedMesh.prototype.copyWorldMatrixProperties = function (
  otherMeshOrInstanceOrClone
) {
  BABYLON.Mesh.prototype.copyWorldMatrixProperties.call(
    this,
    otherMeshOrInstanceOrClone
  );
};

/**
 * Overriding the method because sometimes duplicate ID is being generated
 * @returns {number}
 */
BABYLON.Scene.prototype.getUniqueId = function () {
  /* Original BabylonJS Code

    let result = Scene._uniqueIdCounter;
    Scene._uniqueIdCounter++;
    return result;

    */

  let result = BABYLON.Scene._uniqueIdCounter;
  BABYLON.Scene._uniqueIdCounter++;

  while (this.getMeshByUniqueID(result)) {
    result = BABYLON.Scene._uniqueIdCounter;
    BABYLON.Scene._uniqueIdCounter++;
  }

  return result;
};

BABYLON.Scene.prototype.getMeshByUniqueID = function(uniqueId){
  return meshUniqueIdMapper.get(uniqueId);
};

BABYLON.Scene.prototype.recreateMesh = function (
  mesh,
  geom,
  instancesLength,
  keepSourceMesh
) {
  var customMesh = new BABYLON.Mesh("custom", this);

  var positions = geom.positions;
  var indices = geom.indices;
  var uvs = geom.uvs;
  var normals = geom.normals;

  var vertexData = new BABYLON.VertexData();

  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  vertexData.applyToMesh(customMesh, true);

  // console.log(mesh);
  // customMesh.setPivotPoint(customMesh.getBoundingInfo().boundingBox.centerWorld);

  if (mesh.subMeshes) {
    if (mesh.subMeshes.length > 0) {
      customMesh.subMeshes = [];
      var verticesCount = customMesh.getTotalVertices();
      for (var i = 0; i < mesh.subMeshes.length; i++) {
        // customMesh.subMeshes.push(new BABYLON.SubMesh(mesh.subMeshes[i].materialIndex, 0, verticesCount, mesh.subMeshes[i].indexStart, mesh.subMeshes[i].indexCount, customMesh)); // Works perfectly
        customMesh.subMeshes.push(
          new BABYLON.SubMesh(
            mesh.subMeshes[i].materialIndex,
            mesh.subMeshes[i].verticesStart,
            mesh.subMeshes[i].verticesCount,
            mesh.subMeshes[i].indexStart,
            mesh.subMeshes[i].indexCount,
            customMesh
          )
        ); // Works perfectly
        customMesh.subMeshes.pop();
      }
    }
  }
  if (mesh.materialId) {
    var mat = this.getMaterialByID(mesh.materialId);
    if (!mat) {
      // var mat = findMultiMaterial(data, mesh.materialId);
      let multiMat = this.getMultiMaterialByID(mesh.materialId);
      if (multiMat) {
        // var multimat = new BABYLON.MultiMaterial(mat.name, this);
        // console.log(multimat);
        // for (var i = 0; i < mat.subMaterials.length; i++) {
        //     // var tempMat = this.getMaterialByID(mat.subMaterials[i]);
        //     var tempMat = mat.subMaterials[i];
        //     if (tempMat){
        //         tempMat.backFaceCulling = false;
        //         multimat.subMaterials.push(tempMat);
        //     }
        // }
        customMesh.material = multiMat;
      } else {
        mat = findMaterialByID(store.scene, mesh.materialId);
        if (mat) {
          mat.backFaceCulling = true;
          customMesh.material = mat;
        }
      }
    } else {
      mat.backFaceCulling = true;
      customMesh.material = mat;
    }
  }
  // if (mesh.name === "wall"){
  //     var multimat = new BABYLON.MultiMaterial("wall_multimat", store.scene);
  //     multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  //     multimat.subMaterials.push(scene.getMaterialByName("floor_mat"));
  //     multimat.subMaterials.push(scene.getMaterialByName("wall_mat4"));
  //     customMesh.material = multimat;
  // }
  // else{
  //     customMesh.material = newScene.getMaterialByID(mesh.materialId);
  // }

  // if (mesh.name === 'wall'){
  //     customMesh.subMeshes = [];
  //     var verticesCount = customMesh.getTotalVertices();
  //     for (var i=0; i<mesh.subMeshes.length; i++)
  //         customMesh.subMeshes.push(new BABYLON.SubMesh(mesh.subMeshes[i].materialIndex, 0, verticesCount, mesh.subMeshes[i].indexStart, mesh.subMeshes[i].indexCount, customMesh)); // Works perfectly
  //
  //     customMesh.material = newScene.getMaterialByID(mesh.materialId);
  //     console.log(customMesh.material);
  // }
  // else{
  //     customMesh.material = newScene.getMaterialByID(mesh.materialId);
  // }
  if (instancesLength) {
    if (keepSourceMesh) {
      //For non-furniture/door/window instances' sourceMeshes
      customMesh.position = new BABYLON.Vector3(
        mesh.position[0],
        mesh.position[1],
        mesh.position[2]
      );
      if (mesh.rotation) {
        customMesh.rotation = new BABYLON.Vector3(
          mesh.rotation[0],
          mesh.rotation[1],
          mesh.rotation[2]
        );
      }
      if (mesh.rotationQuaternion) {
        customMesh.rotationQuaternion = new BABYLON.Quaternion(
          mesh.rotationQuaternion[0],
          mesh.rotationQuaternion[1],
          mesh.rotationQuaternion[2],
          mesh.rotationQuaternion[3]
        );
      }
      customMesh.scaling = new BABYLON.Vector3(
        mesh.scaling[0],
        mesh.scaling[1],
        mesh.scaling[2]
      );
    } else {
      customMesh.position = new BABYLON.Vector3(10000, 0, 10000);
      customMesh.rotation = new BABYLON.Vector3(
        mesh.rotation[0],
        mesh.rotation[1],
        mesh.rotation[2]
      );
      customMesh.scaling = new BABYLON.Vector3(0, 0, 0);
    }
  } else {
    customMesh.position = new BABYLON.Vector3(
      mesh.position[0],
      mesh.position[1],
      mesh.position[2]
    );
    if (mesh.rotation) {
      customMesh.rotation = new BABYLON.Vector3(
        mesh.rotation[0],
        mesh.rotation[1],
        mesh.rotation[2]
      );
    }
    if (mesh.rotationQuaternion) {
      customMesh.rotationQuaternion = new BABYLON.Quaternion(
        mesh.rotationQuaternion[0],
        mesh.rotationQuaternion[1],
        mesh.rotationQuaternion[2],
        mesh.rotationQuaternion[3]
      );
    }
    customMesh.scaling = new BABYLON.Vector3(
      mesh.scaling[0],
      mesh.scaling[1],
      mesh.scaling[2]
    );
  }

  customMesh.name = mesh.name;
  customMesh.id = mesh.id;
  // if (mesh.name.toLowerCase() === "roof"){
  //     console.log(mesh.name);
  //     console.log(jQuery.extend({}, mesh));
  // }
  customMesh.isVisible = mesh.isVisible;
  customMesh.isPickable = mesh.pickable;
  // console.log(mesh.isPickable, mesh.isVisible, mesh.visibility);
  customMesh.visibility = mesh.visibility;
  customMesh.offsetFlag = true;
  customMesh._properties = mesh._properties;
  let originalScaling = backwardCompatibilityChecker.validateVector3ToArray(
    mesh.originalScaling
  );
  if (originalScaling)
    customMesh.originalScaling = BABYLON.Vector3.FromArray(originalScaling);
  let originalRotation = backwardCompatibilityChecker.validateVector3ToArray(
    mesh.originalRotation
  );
  if (originalRotation)
    customMesh.originalRotation = BABYLON.Vector3.FromArray(originalRotation);
  if (mesh.storey) customMesh.storey = mesh.storey;
  if (mesh.strNum) customMesh.strNum = mesh.strNum;
  // log.warn(customMesh._properties);

  // if (mesh.room_type) customMesh.room_type = mesh.room_type;
  // if (mesh.room_id) customMesh.room_id = mesh.room_id;
  // if (mesh.wall_type) customMesh.wall_type = mesh.wall_type;
  // if (mesh.wall_id) customMesh.wall_id = mesh.wall_id;
  // if (mesh.level) customMesh.level = mesh.level;
  // if (mesh.level === 0) customMesh.level = mesh.level;
  // if (mesh.type) customMesh.type = mesh.type;
  // if (mesh.structure) customMesh.structure = mesh.structure;
  // if (mesh.wall_pol) customMesh.wall_pol = mesh.wall_pol;
  // if (mesh.prevVectorData) customMesh.prevVectorData = mesh.prevVectorData;
  // if (mesh.wallJunctions) customMesh.wallJunctions = JSON.parse(mesh.wallJunctions);
  // if (mesh.uniqueId) customMesh.uniqueId = mesh.uniqueId;
  // if (mesh.prevVectorData) customMesh.prevVectorData = mesh.prevVectorData;
  // if (mesh.floor) customMesh.floor = mesh.floor;
  // if (mesh.neighborsWallMesh) customMesh.neighborsWallMesh = JSON.parse(mesh.neighborsWallMesh);
  // if (mesh.parent){
  //     customMesh.parent = this.getMeshByUniqueID(mesh.parent);
  //     customMesh.parentId = mesh.parent;
  // }
  // if (mesh.checkForExtention) customMesh.checkForExtention = mesh.checkForExtention;
  // if (mesh.visibilty == 0) customMesh.visibility = 0;
  // if (mesh.isVisible == 0) customMesh.isVisible = 0;
  // if(mesh.moveMeshObj){
  //     customMesh.moveMeshObj = {};
  //     if(mesh.moveMeshObj.clkWiseMesh){
  //         customMesh.moveMeshObj.clkWiseMesh = [];
  //         mesh.moveMeshObj.clkWiseMesh.forEach(function(obj){
  //             customMesh.moveMeshObj.clkWiseMesh.push({mesh:obj.mesh, nearPointIndexes:obj.nearPointIndexes});
  //         });
  //     }
  //     if(mesh.moveMeshObj.counterClkWiseMesh){
  //         customMesh.moveMeshObj.counterClkWiseMesh = [];
  //         mesh.moveMeshObj.counterClkWiseMesh.forEach(function(obj){
  //             customMesh.moveMeshObj.counterClkWiseMesh.push({mesh:obj.mesh, nearPointIndexes:obj.nearPointIndexes});
  //         });
  //     }
  // }

  meshVisibilityProcessing(customMesh);
  return customMesh;
};

BABYLON.AbstractMesh.prototype.assignAdditionalProps = function (mesh1, mesh2) {
  mesh2._properties = mesh1._properties;
  if (mesh1.properties) mesh2.properties = mesh1.properties;
  if (mesh1.copies) mesh2.copies = mesh1.copies;
  if (mesh1.structure_id) mesh2.structure_id = mesh1.structure_id;
  if (mesh1.room_type) mesh2.room_type = mesh1.room_type;
  if (mesh1.room_name) mesh2.room_name = mesh1.room_name;
  if (mesh1.room_path) mesh2.room_path = mesh1.room_path;
  if (mesh1.room_unique_id) mesh2.room_unique_id = mesh1.room_unique_id;
  if (mesh1.room_id) mesh2.room_id = mesh1.room_id;
  if (mesh1.room_unique_id) mesh2.room_unique_id = mesh1.room_unique_id;
  if (mesh1.level) mesh2.level = mesh1.level;
  if (mesh1.level === 0) mesh2.level = mesh1.level;
  if (mesh1.wall_type) mesh2.wall_type = mesh1.wall_type;
  if (mesh1.wall_id) mesh2.wall_id = mesh1.wall_id;
  if (mesh1.type) mesh2.type = mesh1.type;
  if (mesh1.structure) mesh2.structure = mesh1.structure;
  if (mesh1.wallJunctions)
    mesh2.wallJunctions = JSON.stringify(mesh1.wallJunctions);
  if (mesh1.checkForExtention)
    mesh2.checkForExtention = mesh1.checkForExtention;
  if (mesh1.floor) mesh2.floor = mesh1.floor;
  if (mesh1.uniqueId) {
    if (!store.scene.getMeshByUniqueID(mesh1.uniqueId))
      mesh2.uniqueId = mesh1.uniqueId;
  }
  if (mesh1.wall_pol) mesh2.wall_pol = mesh1.wall_pol;
  if (mesh1.neighborsWallMesh)
    mesh2.neighborsWallMesh = JSON.stringify(mesh1.neighborsWallMesh);
  if (mesh1.storey) mesh2.storey = mesh1.storey;
  if (mesh1.height) mesh2.height = mesh1.height;
  if (mesh1.parent) {
    mesh2.parent = mesh1.parent.uniqueId;
  }
  if (mesh1.moveMeshObj) {
    mesh2.moveMeshObj = {};
    if (mesh1.moveMeshObj.clkWiseMesh) {
      mesh2.moveMeshObj.clkWiseMesh = [];
      mesh1.moveMeshObj.clkWiseMesh.forEach(function (obj) {
        mesh2.moveMeshObj.clkWiseMesh.push({
          mesh2: obj.mesh2.uniqueId,
          nearPointIndexes: obj.nearPointIndexes,
        });
      });
    }
    if (mesh1.moveMeshObj.counterClkWiseMesh) {
      mesh2.moveMeshObj.counterClkWiseMesh = [];
      mesh1.moveMeshObj.counterClkWiseMesh.forEach(function (obj) {
        mesh2.moveMeshObj.counterClkWiseMesh.push({
          mesh2: obj.mesh2.uniqueId,
          nearPointIndexes: obj.nearPointIndexes,
        });
      });
    }
  }
  if (mesh2.type === "furniture") {
    let bbinfo = mesh2.getBoundingInfo();
    let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mesh2.setPivotPoint(centroid);
  }
};

BABYLON.Scene.prototype.serializeMaterials = function () {
  let serialMats = [];
  this.materials.forEach(function (material) {
    if (material.id !== "colorShader" && material.id !== "lineShader") {
      let mat = material.serialize();
      mat.materialType = material.materialType;
      mat.inStatic = material.inStatic;
      if(material?.metadata){
        mat.metadata = material.metadata
      }
      serialMats.push(mat);
    }
  });
  return serialMats;
};

BABYLON.Scene.prototype.serializeMultiMaterials = function () {
  let serialMats = [];
  this.multiMaterials.forEach(function (multiMaterial) {
    serialMats.push(multiMaterial.serialize());
  });
  return serialMats;
};

BABYLON.Scene.prototype.serializeCameras = function () {
  let serializedCameras = [];
  this.cameras.forEach(function (camera) {
    serializedCameras.push(camera.serialize());
  });
  return serializedCameras;
};

BABYLON.Scene.prototype.serializeLights = function () {
  let serializedLights = [];
  this.lights.forEach(function (light) {
    serializedLights.push(light.serialize());
  });
  return serializedLights;
};

BABYLON.ArcRotateCamera.prototype.spinTo = function (
  whichprop,
  targetval,
  speed
) {
  var ease = new BABYLON.CubicEase();
  ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
  BABYLON.Animation.CreateAndStartAnimation(
    "at4",
    this,
    whichprop,
    speed,
    120,
    this[whichprop],
    targetval,
    0,
    ease
  );
};

/*BABYLON.Scene.prototype.compoundPick = function(predicate){
    let primaryPick = null;
    let secondaryPick = null;

    let nudges = [
        BABYLON.Vector3.Left(),
        BABYLON.Vector3.Right(),
        BABYLON.Vector3.Up(),
        BABYLON.Vector3.Down(),
        BABYLON.Vector3.Forward(),
        BABYLON.Vector3.Backward(),
        BABYLON.Vector3.Left().add(BABYLON.Vector3.Forward()),
        BABYLON.Vector3.Left().add(BABYLON.Vector3.Backward()),
        BABYLON.Vector3.Right().add(BABYLON.Vector3.Forward()),
        BABYLON.Vector3.Right().add(BABYLON.Vector3.Backward())
    ];
    let scaleFactor = 0.005;

    primaryPick = store.scene.pick(scene.pointerX, store.scene.pointerY, predicate);
    if (!primaryPick.hit){
        let primaryRay = primaryPick.ray;

        nudges.some(nudge => {
            nudge = nudge.scale(scaleFactor);

            let secondaryRay = new BABYLON.Ray(primaryRay.origin, primaryRay.direction.add(nudge));

            secondaryPick = store.scene.pickWithRay(secondaryRay, predicate);
            if (secondaryPick.hit){
                secondaryPick.secondaryPick = true;
                return true;
            }
            else {
                secondaryPick = null;
            }
        })

    }

    return secondaryPick || primaryPick;
};*/


BABYLON.Scene.prototype._internalPick = function (rayFunction, predicate, fastCheck, onlyBoundingInfo, trianglePredicate) {
  if (!BABYLON.PickingInfo) {
      return null;
  }

  // const eligibleMeshes = this.meshes;
  const eligibleMeshes = scenePickController.getPickEligibleMeshes(this);
  const startIndex = 0;

  var pickingInfo = null;
  for (var meshIndex = startIndex; meshIndex < eligibleMeshes.length; meshIndex++) {
      var mesh = eligibleMeshes[meshIndex];
      if (predicate) {
          if (!predicate(mesh)) {
              continue;
          }
      }
      else if (!mesh.isEnabled() || !mesh.isVisible || !mesh.isPickable) {
          continue;
      }
      
      if (isCADMesh(mesh)) {
        if (!onlyBoundingInfo) continue;
        // nonBoundingInfo pick for CAD is not done because of cadSnaps.js
        
        if (scenePickController.isPickSecondary()) continue;
        // since LinesMesh has an intersectionThreshold property, compoundPick on them is unnecessary
        // if simple pick fails, compoundPick would also fail, but very slowly, so excluding CAD meshes
        // which can be potentially really heavy
      }
      
      var world = mesh.skeleton && mesh.skeleton.overrideMesh ? mesh.skeleton.overrideMesh.getWorldMatrix() : mesh.getWorldMatrix();
      if (mesh.hasThinInstances && mesh.thinInstanceEnablePicking) {
          // first check if the ray intersects the whole bounding box/sphere of the mesh
          var result = this._internalPickForMesh(pickingInfo, rayFunction, mesh, world, true, true, trianglePredicate);
          if (result) {
              if (onlyBoundingInfo) {
                  // the user only asked for a bounding info check so we can return
                  return pickingInfo;
              }
              // TODO: Will have to handle this when thinInstances are introduced
              var tmpMatrix;
              // var tmpMatrix = _Maths_math_vector__WEBPACK_IMPORTED_MODULE_1__["TmpVectors"].Matrix[1];
              var thinMatrices = mesh.thinInstanceGetWorldMatrices();
              for (var index = 0; index < thinMatrices.length; index++) {
                  var thinMatrix = thinMatrices[index];
                  thinMatrix.multiplyToRef(world, tmpMatrix);
                  var result_1 = this._internalPickForMesh(pickingInfo, rayFunction, mesh, tmpMatrix, fastCheck, onlyBoundingInfo, trianglePredicate, true);
                  if (result_1) {
                      pickingInfo = result_1;
                      pickingInfo.thinInstanceIndex = index;
                      if (fastCheck) {
                          return pickingInfo;
                      }
                  }
              }
          }
      }
      else {
          // eslint-disable-next-line no-redeclare
          var result = this._internalPickForMesh(pickingInfo, rayFunction, mesh, world, fastCheck, onlyBoundingInfo, trianglePredicate);
          if (result) {
              pickingInfo = result;
              if (fastCheck) {
                  return pickingInfo;
              }
          }
      }
  }
  return pickingInfo || new BABYLON.PickingInfo();
};

BABYLON.Scene.prototype._internalMultiPick = function (rayFunction, predicate, trianglePredicate) {
  if (!BABYLON.PickingInfo) {
      return null;
  }

  // const eligibleMeshes = this.meshes;
  const eligibleMeshes = scenePickController.getPickEligibleMeshes(this);

  var pickingInfos = new Array();
    for (var meshIndex = 0; meshIndex < eligibleMeshes.length; meshIndex++) {
        var mesh = eligibleMeshes[meshIndex];
        if (predicate) {
            if (!predicate(mesh)) {
                continue;
            }
        }
        else if (!mesh.isEnabled() || !mesh.isVisible || !mesh.isPickable) {
            continue;
        }
        
        if (isCADMesh(mesh)) {
          continue;
        }
        
        var world = mesh.skeleton && mesh.skeleton.overrideMesh ? mesh.skeleton.overrideMesh.getWorldMatrix() : mesh.getWorldMatrix();
        if (mesh.hasThinInstances && mesh.thinInstanceEnablePicking) {
            var result = this._internalPickForMesh(null, rayFunction, mesh, world, true, true, trianglePredicate);
            if (result) {
                // TODO: Will have to handle this when thinInstances are introduced
                var tmpMatrix;
                // var tmpMatrix = _Maths_math_vector__WEBPACK_IMPORTED_MODULE_1__["TmpVectors"].Matrix[1];
                var thinMatrices = mesh.thinInstanceGetWorldMatrices();
                for (var index = 0; index < thinMatrices.length; index++) {
                    var thinMatrix = thinMatrices[index];
                    thinMatrix.multiplyToRef(world, tmpMatrix);
                    var result_2 = this._internalPickForMesh(null, rayFunction, mesh, tmpMatrix, false, false, trianglePredicate, true);
                    if (result_2) {
                        result_2.thinInstanceIndex = index;
                        pickingInfos.push(result_2);
                    }
                }
            }
        }
        else {
            var result = this._internalPickForMesh(null, rayFunction, mesh, world, false, false, trianglePredicate);
            if (result) {
                pickingInfos.push(result);
            }
        }
    }
    return pickingInfos;
};

BABYLON.Scene.prototype.compoundPick = function (predicate, useMultiPick = false, referencePoint) {
  let primaryPick = null;
  let secondaryPick = null;

  // let nudgeValue = 4;
  let nudgeValue = snapEngine.snapVerifier.getScreenSpaceThreshold();

  let nudges = [
    [1, 0],
    [-1, 0],
    [0, 1],
    [0, -1],
    [1, 1],
    [1, -1],
    [-1, 1],
    [-1, -1],
  ];

  if (useMultiPick){
    const pickInfos = [];
    const pickedMeshes = [];

    nudges.unshift([0, 0]);

    const nudgeValues = [2, 4, 8, 12];
    let threshold = getDynamicThreshold(referencePoint);

    nudgeValues.forEach(nudgeValue => {
      nudges.forEach((nudge) => {
        let pointerX = store.scene.pointerX + nudge[0] * nudgeValue;
        let pointerY = store.scene.pointerY + nudge[1] * nudgeValue;

        const picks = store.scene.multiPick(pointerX, pointerY, (mesh) => {
          // return predicate(mesh) && !pickedMeshes.includes(mesh);
          return predicate(mesh);
        }).filter((p) => p.hit);

        /*const pick = store.scene.pick(pointerX, pointerY, predicate);
        const picks = [pick].filter((p) => p.hit);*/

        picks.forEach((pickInfo) => {

          if (store.$scope.isTwoDimension){
            // in 2D the distance between points doesn't matter, point at the bottom of the storey is equivalent to the one on top
            pickInfos.push(pickInfo);
            pickedMeshes.push(pickInfo.pickedMesh);
          }
          else {
            // in 3D distance matters. If not for this check, some random vertices/edges behind a mesh are snapped to
            const distance = getDistanceBetweenVectors(referencePoint, pickInfo.pickedPoint);
            if (distance < threshold){
              pickInfos.push(pickInfo);
              pickedMeshes.push(pickInfo.pickedMesh);
            }
          }

        });
      });
    });

    return pickInfos;
  }
  else {

    primaryPick = store.scene.pick(
      store.scene.pointerX,
      store.scene.pointerY,
      predicate
    );

    if (!primaryPick.hit) {
      
      scenePickController.markPickAsSecondary();
      
      nudges.some((nudge) => {
        let pointerX = store.scene.pointerX + nudge[0] * nudgeValue;
        let pointerY = store.scene.pointerY + nudge[1] * nudgeValue;

        secondaryPick = store.scene.pick(pointerX, pointerY, predicate);
        if (secondaryPick.hit) {
          secondaryPick.secondaryPick = true;
          return true;
        } else {
          secondaryPick = null;
        }
      });
    }
    
    scenePickController.markPickAsPrimary();

    return secondaryPick || primaryPick;
  }

};

BABYLON.Scene.prototype.sequentialPick = function (
  predicateArray,
  options = {}
) {
  let toReturn;
  let doAllOfThem = options.doAllOfThem || false;
  let doSimplePick = options.doSimplePick || false;
  //Handling options such that default value is false, enables  using || like ?? for boolean values

  let callback = (predicate) => {
    if (!predicate) return;

    let pickInfo;
    if (doSimplePick)
      pickInfo = store.scene.pick(
        store.scene.pointerX,
        store.scene.pointerY,
        predicate
      );
    else pickInfo = store.scene.compoundPick(predicate);

    if (doAllOfThem) toReturn.push(pickInfo);
    else toReturn = pickInfo;

    if (pickInfo.hit && !doAllOfThem) {
      return true;
    }
  };

  if (doAllOfThem) {
    toReturn = [];
    predicateArray.forEach(callback);
  } else {
    predicateArray.some(callback);
  }

  return toReturn;
};

// CSG toMesh modified function
BABYLON.CSG.prototype.toSnaptrudeMesh = function (
  name,
  material,
  scene,
  keepSubMeshes
) {
  var mesh = this.buildMeshGeometry(name, scene, keepSubMeshes);
  mesh.material = material;
  // mesh.position.copyFrom(this.position);
  mesh.rotation.copyFrom(this.rotation);
  if (this.rotationQuaternion) {
    mesh.rotationQuaternion = this.rotationQuaternion.clone();
  }
  mesh.scaling.copyFrom(this.scaling);
  mesh.computeWorldMatrix(true);
  return mesh;
};

BABYLON.InstancedMesh.prototype.BrepToMesh = function (brep, options = {}) {
  BABYLON.Mesh.prototype.BrepToMesh.call(this.sourceMesh, brep);
};

BABYLON.InstancedMesh.prototype.assignProperties = function (mesh) {
  let source = mesh || this.sourceMesh;
  this.position.copyFrom(source.position);
  this.rotation.copyFrom(source.rotation);
  this.scaling.copyFrom(source.scaling);
  if (source.rotationQuaternion) {
    this.rotationQuaternion.copyFrom(source.rotationQuaternion);
  }
  this.type = source.type;

  if (this.type.toLowerCase() === "mass") {
    this.room_unique_id = makeid(3);
    this.room_path = source.room_path;
    this.room_type = source.room_type;
  }
};

BABYLON.Mesh.prototype.getBrep = function (sort = false) {
  let indices = this.getIndices();
  let verData = this.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let positions = [];
  let cells = [];

  let verticesLength = this.verticesLength;
  for (let i = 0; i < verticesLength * 2 * 3; i += 3) {
    positions.push([verData[i], verData[i + 1], verData[i + 2]]);
  }

  let indicesLimit = this.indicesLimit;
  for (let i = 0; i < indicesLimit; i += indicesLimit / 2) {
    let j = indicesLimit / 2;
    let k = 0;
    let arr = [];
    while (j > 0) {
      arr.push(indices[i + k++]);
      j--;
    }

    arr = arr.uniq();
    if (sort || j > 6) arr.sort((a, b) => a - b);
    cells.push([arr]);
  }

  if (_.isEmpty(cells)) {
    console.warn("Empty cells during BRep generation");
    return;
  }

  cells[0][0].reverse();
  cells[0][0].unshift(cells[0][0].pop());

  for (let i = 0; i < verticesLength; i++) {
    if (i + 1 >= verticesLength) {
      cells.push([[i, 0, verticesLength, i + verticesLength]]);
    } else {
      cells.push([[i, i + 1, i + 1 + verticesLength, i + verticesLength]]);
    }
  }

  let brep = generateBrepFromPositionsAndCells(positions, cells);

  // This assignment was done here, but it is not required as the BREP is being returned. There is no need to access the object here.
  // this.getSnaptrudeDS().brep = brep;

  delete this.verticesLength;
  delete this.indicesLimit;
  return brep;
};

BABYLON.Mesh.prototype.BrepToMesh = function (brep, options = {}) {
  const _populatePositions = function (faceVertices) {
    faceVertices.forEach((v) => {
      verData.push(...v);
    });
  };

  const _populateUVs = function (faceVerticesGlobal) {
    let minX = 1e6;
    let maxX = -1e6;
    let minY = 1e6;
    let maxY = -1e6;
    let minZ = 1e6;
    let maxZ = -1e6;
    for (let w = 0; w < faceVerticesGlobal.length; w++) {
      minX = Math.min(faceVerticesGlobal[w].x, minX);
      maxX = Math.max(faceVerticesGlobal[w].x, maxX);
      minY = Math.min(faceVerticesGlobal[w].y, minY);
      maxY = Math.max(faceVerticesGlobal[w].y, maxY);
      minZ = Math.min(faceVerticesGlobal[w].z, minZ);
      maxZ = Math.max(faceVerticesGlobal[w].z, maxZ);
    }

    let diffX = maxX - minX;
    let diffY = maxY - minY;
    let diffZ = maxZ - minZ;

    let orientation = getNearestFaceOrientation(faceVerticesGlobal);

    faceVerticesGlobal.forEach((v) => {
      let uv1 = NaN;
      let uv2 = NaN;

      switch (orientation) {
        case "xy":
          uv1 = v.x - minX;
          uv2 = v.y - minY;
          break;
        case "-xy":
          uv1 = maxX - v.x;
          uv2 = v.y - minY;
          break;
        case "zy":
          uv1 = v.z - minZ;
          uv2 = v.y - minY;
          break;
        case "-zy":
          uv1 = maxZ - v.z;
          uv2 = v.y - minY;
          break;
        case "xz": //uv maps to x,z
          uv1 = v.x - minX;
          uv2 = v.z - minZ;
          break;
        case "x-z": //uv maps to x, -z
          uv1 = v.x - minX;
          uv2 = maxZ - v.z;
          break;
      }

      // if (isNaN(uv1) || isNaN(uv2)) log.warn("UVs problematic");
      uvData.push(
        uv1 * store.unit_absolute_scale * 10,
        uv2 * store.unit_absolute_scale * 10
      );
    });
  };

  const _populateIndices = function (
    faceID,
    faceVertices,
    faceIndices,
    holeIndicesEarcut
  ) {
    let earcutPath = [];
    faceVertices.forEach((vertex) => earcutPath.push(...vertex));

    let triangles = earcut(earcutPath, holeIndicesEarcut, 3);

    let positions = brep.positions;
    for (let i = 0, j = triangles.length; i < j; i += 3) {
      faceFacetMapping[faceID].push(++facetID);
      let vec1 = BABYLON.Vector3.FromArray(
        positions[faceIndices[triangles[i]]]
      );
      let vec2 = BABYLON.Vector3.FromArray(
        positions[faceIndices[triangles[i + 1]]]
      );
      let vec3 = BABYLON.Vector3.FromArray(
        positions[faceIndices[triangles[i + 2]]]
      );

      vec1 = convertLocalVector3ToGlobal(vec1, this);
      vec2 = convertLocalVector3ToGlobal(vec2, this);
      vec3 = convertLocalVector3ToGlobal(vec3, this);

      let u1 = getNormalVector([vec1, vec2, vec3]); //if points are counter clockwise, normal goes away from you
      let u2 = getNormalVector([vec1, vec3, vec2]);

      if (!u1 || !u2) continue;

      u1 = BABYLON.Vector3.FromArray(u1).normalize();
      u2 = BABYLON.Vector3.FromArray(u2).normalize();

      let p1 = vec1.add(u1).length();
      let p2 = vec1.add(u2).length();
      /*
            If p1 < p2, it means p1 is closer to local origin (centre of the mesh)
            This would mean u1 was inwards, thus points are in clockwise direction when looked at from the inside.
            So they would be counter clockwise when looked at from the outside which would mean Babylon normals
            would face outwards
             */

      if (p1 > p2 + 0) {
        //hot fix for p1 very slightly less than p2, might create other issues
        let temp = triangles[i];
        triangles[i] = triangles[i + 2];
        triangles[i + 2] = temp;
      }
    }

    triangles.forEach(function (point) {
      // indices.push(faceIndices[point]);
      indices.push(point + length);
    });
  };

  const _updateInternalData = function (mesh) {
    /*
        Facet data needs to be updated for instances separately
         */

    // mesh.createNormals(true);

    /*
        Found this solution after a lot of clawing around in the source code.

        Just calling updateFacetData results in an array indexing error since this function doesn't
        expect new normals. So for operations like addVertex and extrude it was failing.
        So have to call disableFacetData first to flush all the facetData.
        Calling updateFacetData later counts the number of facets as number of indices / 3, which makes sense. So now,
        it can expect new normals. But disableFacetData sets
        mesh._internalAbstractMeshDataInfo._facetData.facetParameters = null
        This is probably a bug. So initialising it manually.
        Working as expected for now.
         */

    mesh._internalAbstractMeshDataInfo._facetData.facetParameters = {};
    mesh.updateFacetData();

    if (!mesh.isAnInstance) correctMeshNormals(mesh);
  };

  let presentInStructure = true;
  if (brep) {
    presentInStructure = false;
  } else {
    brep = this.getSnaptrudeDS().brep;
  }

  if (!brep) {
    console.warn("No BRep present");
    return;
  }

  convertBrepPositionsFromTypedArrayToArray(brep);

  let verData = [];
  let indices = [];

  let faceFacetMapping = {};
  let facetID = -1;
  let uvData = [];
  let length = 0;

  brep.getFaces().forEach((face, index) => {
    faceFacetMapping[index] = [];
    let faceVertices = snapmda.FaceVerticesWithHoles(face);
    let faceIndices = [];



    for (let i = 0; i < faceVertices.length; i++) {
      faceIndices.push(faceVertices[i].map((vertex) => vertex.index));
    }

    let faceVerticesLocal = [];
    for (let i = 0; i < faceIndices.length; i++) {
      let f = faceIndices[i].map((index) => brep.positions[index]);
      faceVerticesLocal = faceVerticesLocal.concat(f);
    }
 //for other face which has mroe than 4 points
    if(index>1 && faceVerticesLocal.length>4){

      _populatePositions(faceVerticesLocal);
      // let stHe = face.getHalfEdge();
      // let prevHe = stHe.getPreviousHalfEdge();
      // let prevPrev  = prevHe.getPreviousHalfEdge();
      // let currHe = stHe;
      // let topHe = prevHe;
      // let topHePrev = prevPrev;
      // for(;;)
      // {
      //   let nextHe = stHe.getNextHalfEdge();
      // }
      var cell = faceIndices[0];
      //reverse the    loop
      for(var i=0, j=cell.length-1; i<cell.length/2-1; i++, j--)
      {
        var id0 = i + length;
        var id1 = i + 1+ length;;
        var id2 = j -1 + length;;
        var id3 = j + length;;
        faceFacetMapping[index].push(++facetID);
        indices.push(id0);
        indices.push(id1);
        indices.push(id2);

        faceFacetMapping[index].push(++facetID);
        indices.push(id0);
        indices.push(id2);
        indices.push(id3);
      }
      let faceVerticesGlobal = faceVerticesLocal.map((v) => {
        return BABYLON.Vector3.TransformCoordinates(
          new BABYLON.Vector3(v[0], v[1], v[2]),
          this.getWorldMatrix()
        );
      });
      _populateUVs(faceVerticesGlobal);
      length = verData.length / 3;
    }
    else{
    	      //following code will work for    
      //index 0 is bottom face
      //index 1 is top face
      // face with 4 points
      let holeVertices = snapmda.HoleVertices(face);
      let holeIndices = [];
      for (let i = 0; i < holeVertices.length; i++) {
        holeIndices.push(holeVertices[i].map((vertex) => vertex.index));
      }
      let holeVerticesLocal = [];
      for (let i = 0; i < holeIndices.length; i++) {
        let h = holeIndices[i].map((index) => brep.positions[index]);
        holeVerticesLocal = holeVerticesLocal.concat(h);
      }
      let faceVerticesWithHoles = faceVerticesLocal;
      let faceVerticesGlobal = faceVerticesWithHoles.map((v) => {
        return BABYLON.Vector3.TransformCoordinates(
          new BABYLON.Vector3(v[0], v[1], v[2]),
          this.getWorldMatrix()
        );
      });

      let faceVerticesWithHolesEarcut = changeOrderForEarcut(
        faceVerticesWithHoles
      );

      let faceInd = [];
      for (let i = 0; i < faceIndices.length; i++) {
        faceInd = faceInd.concat(faceIndices[i]);
      }

      let holeIndicesEarcut = [];
      if (faceVertices[1] === undefined) {
        holeIndicesEarcut = null;
      } else {
        let temp = [];
        for (let i = 0; i < faceIndices.length - 1; i++) {
          if (i > 0) {
            temp.push(temp[i - 1] + faceIndices[i].length);
          } else {
            temp.push(faceIndices[i].length);
          }
        }
        holeIndicesEarcut = temp;
      }

      _populatePositions(faceVerticesWithHoles);
      _populateUVs(faceVerticesGlobal);
      _populateIndices.call(
        this,
        index,
        faceVerticesWithHolesEarcut,
        faceInd,
        holeIndicesEarcut
      );

      length = verData.length / 3;
    }
  });

  this.disableFacetData();
  this.instances.forEach((i) => i.disableFacetData());
  let subMeshes = Object.assign([], this.subMeshes);

  this.geometry.setVerticesData(
    BABYLON.VertexBuffer.PositionKind,
    verData,
    true
  );
  this.geometry.setVerticesData(BABYLON.VertexBuffer.UVKind, uvData, true);
  this.geometry.setIndices(indices, null, true);
  let normals = [];
  BABYLON.VertexData.ComputeNormals(verData, indices, normals);
  this.geometry.setVerticesData(BABYLON.VertexBuffer.NormalKind, normals, true);

  /*normals = [];
  BABYLON.VertexData.ComputeNormals(verData, indices, normals);
  this.geometry.setVerticesData(BABYLON.VertexBuffer.NormalKind, normals, true);*/

  if (presentInStructure) {
    // this.subMeshes = subMeshes;

    // this.getSnaptrudeDS().faceFacetMapping = faceFacetMapping;
    // doing this doesn't update faceFacetMapping of instances

    if (
      _.size(faceFacetMapping) < _.size(this.getSnaptrudeDS().faceFacetMapping)
    ) {
      //some keys have to be removed from the mapping
      let key = _.size(this.getSnaptrudeDS().faceFacetMapping);
      let newLength = _.size(faceFacetMapping);
      while (key > newLength) {
        delete this.getSnaptrudeDS().faceFacetMapping[key - 1];
        key--;
      }
    }

    let snaptrudeDS = this.getSnaptrudeDS();

    _.forEach(faceFacetMapping, function (value, key) {
      snaptrudeDS.faceFacetMapping[key] = value;
    });
  } else {
    this.faceFacetMapping = faceFacetMapping;
  }

  _updateInternalData(this);
  this.refreshBoundingInfo();

  this.instances.forEach((i) => {
    _updateInternalData(i);
    i.refreshBoundingInfo();
  });

  onSolid(this);
  setLayerTransperancy(this);

   // Preserve subMeshes materialIndex after vertexData update unless updated explicitly
  if (subMeshes.length === 1) {
    this.subMeshes[0].materialIndex = subMeshes[0].materialIndex;
  }

  if (!options.doNotUpdateSubMeshes) updateSubMeshes(this, brep.getFaces(), faceFacetMapping);
};

BABYLON.InstancedMesh.prototype.cleanClone = function (
  name,
  newParent,
  doNotCloneChildren,
  clonePhysicsImpostor
) {
  return this.sourceMesh.cleanClone(
    name,
    newParent,
    doNotCloneChildren,
    clonePhysicsImpostor
  );
};

/**
 * When clones and instances coexist, clone() is creating 2 new meshes.
 * This method avoids that
 *
 * @param name
 * @param newParent
 * @param doNotCloneChildren
 * @param clonePhysicsImpostor
 * @returns {Mesh}
 */
BABYLON.Mesh.prototype.cleanClone = function (
  name,
  newParent,
  doNotCloneChildren,
  clonePhysicsImpostor
) {
  // let meshes = store.scene.meshes.map(m => m);
  //this problem of >1 mesh being created on clone was because of a mesh being assigned as a property
  //now it's working as expected
  let childrenCompTemp = [];
  if (this.childrenComp) {
    if (this.childrenComp.length > 0) {
      this.childrenComp.forEach(function (mesh) {
        mesh.setParent(null);
      });
      childrenCompTemp = Object.assign([], this.childrenComp);
      this.childrenComp.length = 0;
    }
  }
  let clone = this.clone(
    name,
    newParent,
    doNotCloneChildren,
    clonePhysicsImpostor
  );

  if (isMeshThrowAway(this)) removeMeshThrowAwayIdentifier(clone);
  this.childrenComp = childrenCompTemp;
  let parentMesh = this;
  this.childrenComp.forEach(function (mesh) {
    mesh.setParent(parentMesh);
  });
  /*let newMeshes = store.scene.meshes;

    if (newMeshes.length > meshes.length + 1){
        meshes.push(clone);
        let extraStupidMeshes = _.differenceWith(newMeshes, meshes, (m1, m2) => {
            return m1.uniqueId === m2.uniqueId;
        });
        extraStupidMeshes.forEach(e => e.dispose());
    }*/

  return clone;
};

// Snaptrude version of Mesh.prototype.clone
BABYLON.Mesh.prototype.snaptrudeClone = function (
  name,
  newParent,
  doNotCloneChildren,
  clonePhysicsImpostor
) {
  if (clonePhysicsImpostor === void 0) {
    clonePhysicsImpostor = true;
  }
  return new BABYLON.Mesh(
    name,
    this.getScene(),
    newParent,
    this,
    doNotCloneChildren,
    clonePhysicsImpostor
  );
};

// Snaptrude getMultiMaterialByID
BABYLON.Scene.prototype.getMultiMaterialByID = function (id) {
  for (var index = 0; index < this.multiMaterials.length; index++) {
    if (this.multiMaterials[index].id === id) {
      return this.multiMaterials[index];
    }
  }
  return null;
};

var ArcRotateCameraPointersInput = BABYLON.ArcRotateCameraPointersInput;

/*ArcRotateCameraPointersInput.prototype.attachControl = function (element, noPreventDefault) {
    var _this = this;
    var engine = this.camera.getEngine();
    var cacheSoloPointer; // cache pointer object for better perf on camera rotation
    var pointA, pointB;
    var previousPinchDistance = 0;
    var previousPanPosition = BABYLON.Vector2.Zero();
    this._pointerInput = function (p, s) {
        var evt = p.event;
        if (p.type !== BABYLON.PointerEventTypes.POINTERMOVE && _this.buttons.indexOf(evt.button) === -1) {
            return;
        }
        if (p.type === BABYLON.PointerEventTypes.POINTERDOWN) {
            try {
                evt.srcElement.setPointerCapture(evt.pointerId);
            }
            catch (e) {
                //Nothing to do with the error. Execution will continue.
            }
            // Manage panning with pan button click
            _this._isPanClick = evt.button === _this.camera._panningMouseButton;
            _this.camera._useCtrlForPanning = !panMode;
            _this._isPanClick = panMode;
            // _this._isPanClick = true;
            // manage pointers
            cacheSoloPointer = { x: evt.clientX, y: evt.clientY, pointerId: evt.pointerId, type: evt.pointerType };
            if (pointA === undefined) {
                pointA = cacheSoloPointer;
            }
            else if (pointB === undefined) {
                pointB = cacheSoloPointer;
            }
            if (!noPreventDefault) {
                evt.preventDefault();
                element.focus();
            }
        }
        else if (p.type === BABYLON.PointerEventTypes.POINTERUP) {
            try {
                evt.srcElement.releasePointerCapture(evt.pointerId);
            }
            catch (e) {
                //Nothing to do with the error.
            }
            cacheSoloPointer = null;
            previousPinchDistance = 0;
            previousPanPosition = BABYLON.Vector2.Zero();
            //would be better to use pointers.remove(evt.pointerId) for multitouch gestures,
            //but emptying completly pointers collection is required to fix a bug on iPhone :
            //when changing orientation while pinching camera, one pointer stay pressed forever if we don't release all pointers
            //will be ok to put back pointers.remove(evt.pointerId); when iPhone bug corrected
            pointA = pointB = undefined;
            if (!noPreventDefault) {
                evt.preventDefault();
            }
        }
        else if (p.type === BABYLON.PointerEventTypes.POINTERMOVE) {
            if (!noPreventDefault) {
                evt.preventDefault();
            }
            // One button down
            if (pointA && pointB === undefined) {
                if (_this.panningSensibility !== 0 &&
                    ((evt.ctrlKey && _this.camera._useCtrlForPanning) ||
                        (!_this.camera._useCtrlForPanning && _this._isPanClick))) {
                    _this.camera.inertialPanningX += -(evt.clientX - cacheSoloPointer.x) / _this.panningSensibility;
                    _this.camera.inertialPanningY += (evt.clientY - cacheSoloPointer.y) / _this.panningSensibility;
                }
                else {
                    var offsetX = evt.clientX - cacheSoloPointer.x;
                    var offsetY = evt.clientY - cacheSoloPointer.y;
                    _this.camera.inertialAlphaOffset -= offsetX / _this.angularSensibilityX;
                    _this.camera.inertialBetaOffset -= offsetY / _this.angularSensibilityY;
                }
                cacheSoloPointer.x = evt.clientX;
                cacheSoloPointer.y = evt.clientY;
            }
            else if (pointA && pointB) {
                //if (noPreventDefault) { evt.preventDefault(); } //if pinch gesture, could be useful to force preventDefault to avoid html page scroll/zoom in some mobile browsers
                var ed = (pointA.pointerId === evt.pointerId) ? pointA : pointB;
                ed.x = evt.clientX;
                ed.y = evt.clientY;
                var direction = _this.pinchInwards ? 1 : -1;
                var distX = pointA.x - pointB.x;
                var distY = pointA.y - pointB.y;
                var panPosition = new BABYLON.Vector2((pointA.x + pointB.x) / 2, (pointA.y + pointB.y) / 2);
                var pinchSquaredDistance = (distX * distX) + (distY * distY);
                var comeback = previousPinchDistance === 0 || previousPanPosition.equals(BABYLON.Vector2.Zero());
                if (previousPinchDistance === 0) previousPinchDistance = pinchSquaredDistance;
                if (previousPanPosition.equals(BABYLON.Vector2.Zero())) previousPanPosition = panPosition;
                if (comeback) return;
                if (pinchSquaredDistance !== previousPinchDistance) {
                    _this.camera.inertialRadiusOffset +=
                        (pinchSquaredDistance - previousPinchDistance)
                        /
                        (_this.pinchPrecision *
                            ((_this.angularSensibilityX + _this.angularSensibilityY) / 2) *
                            direction);
                    previousPinchDistance = pinchSquaredDistance;
                }
                if (panPosition !== previousPanPosition) {
                    var diff = previousPanPosition.subtract(panPosition);
                    _this.camera.inertialPanningX += diff.x / _this.panningSensibility;
                    _this.camera.inertialPanningY -= diff.y / _this.panningSensibility;
                    previousPanPosition = panPosition;
                }
            }
        }
    };
    this._observer = this.camera.getScene().onPointerObservable.add(this._pointerInput, BABYLON.PointerEventTypes.POINTERDOWN | BABYLON.PointerEventTypes.POINTERUP | BABYLON.PointerEventTypes.POINTERMOVE);
    this._onContextMenu = function (evt) {
        evt.preventDefault();
    };
    if (!this.camera._useCtrlForPanning) {
        element.addEventListener("contextmenu", this._onContextMenu, false);
    }
    this._onLostFocus = function () {
        //this._keys = [];
        pointA = pointB = undefined;
        previousPinchDistance = 0;
        cacheSoloPointer = null;
    };
    this._onMouseMove = function (evt) {
        if (!engine.isPointerLock) {
            return;
        }
        var offsetX = evt.movementX || evt.mozMovementX || evt.webkitMovementX || evt.msMovementX || 0;
        var offsetY = evt.movementY || evt.mozMovementY || evt.webkitMovementY || evt.msMovementY || 0;
        _this.camera.inertialAlphaOffset -= offsetX / _this.angularSensibilityX;
        _this.camera.inertialBetaOffset -= offsetY / _this.angularSensibilityY;
        if (!noPreventDefault) {
            evt.preventDefault();
        }
    };
    this._onGestureStart = function (e) {
        if (window.MSGesture === undefined) {
            return;
        }
        if (!_this._MSGestureHandler) {
            _this._MSGestureHandler = new MSGesture();
            _this._MSGestureHandler.target = element;
        }
        _this._MSGestureHandler.addPointer(e.pointerId);
    };
    this._onGesture = function (e) {
        _this.camera.radius *= e.scale;
        if (e.preventDefault) {
            if (!noPreventDefault) {
                e.stopPropagation();
                e.preventDefault();
            }
        }
    };
    element.addEventListener("mousemove", this._onMouseMove, false);
    element.addEventListener("MSPointerDown", this._onGestureStart, false);
    element.addEventListener("MSGestureChange", this._onGesture, false);
    BABYLON.Tools.RegisterTopRootEvents(window, [
        { name: "blur", handler: this._onLostFocus }
    ]);
};*/

BABYLON.Vector3.prototype.almostEquals = function (v, precision = 1e-3) {
  if (
    Math.abs(this.x - v.x) > precision ||
    Math.abs(this.y - v.y) > precision ||
    Math.abs(this.z - v.z) > precision
  ) {
    return false;
  }
  return true;
};

Control.prototype.getScene = function () {
  return store.scene;
};

Object.defineProperty(BABYLON.AbstractMesh.prototype, "type", {
  get: function type() {
    return this._type || GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned;
  },
  set: function type(string) {
    // this._type = _.upperFirst(string);
    // this will break a lot of things where type checking assumes a starting small letter
    this._type = string;
  },
});

Object.defineProperty(BABYLON.InstancedMesh.prototype, "visibility", {
  set: function visibility(number) {
    // console.warn("No setter available for instances");
    // this throws an error
    // Instances don't have a setter for visibility
    // Changes to source mesh's visibility gets reflected on all instances
  },
});

Object.defineProperty(BABYLON.StandardMaterial.prototype, "materialType", {
  writable: true,
  value: "Colors",
});

Object.defineProperty(BABYLON.StandardMaterial.prototype, "materialType", {
  writable: true,
  value: "Colors",
});

Object.defineProperty(BABYLON.StandardMaterial.prototype, 'inStatic', {
  writable: true,
  value: false
});

// Object.defineProperty(BABYLON.StandardMaterial.prototype, 'materialType', {
//     writable: true,
//     value: 'Colors'
// });

Object.defineProperty(BABYLON.Scene, '_uniqueIdCounter', {
  value: 0,
  writable: true
});

BABYLON.Vector3.prototype.snaptrudeFunctions = function () {
  let _this = this;
  return {
    /**
     * Provides the a new VertexData Object with Positions, Indices, Normals, UVs
     */
    roundOff: function (precision) {
      _this.x = _.round(_this.x, precision);
      _this.y = _.round(_this.y, precision);
      _this.z = _.round(_this.z, precision);
      return _this;
    },

    almostEquals2D: function (v, precision = 1e-3) {
      if (
        Math.abs(_this.x - v.x) > precision ||
        Math.abs(_this.z - v.z) > precision
      ) {
        return false;
      }
      return true;
    },
  };
};

function generateFacetFaceMapping(faceFacetMapping) {
  let facetFaceMapping = {},
    facetsInFace,
    face;
  for (face in faceFacetMapping) {
    facetsInFace = faceFacetMapping[face].length;
    for (let i = 0; i < facetsInFace; i++) {
      facetFaceMapping[faceFacetMapping[face][i]] = parseInt(face);
    }
  }
  return facetFaceMapping;
}
export default BABYLON;
export {
  Ellipse,
  Line,
  MultiLine,
  InputText,
  Image,
  Rectangle,
  TextBlock,
  Checkbox,
  Button,
  Control,
  AdvancedDynamicTexture,
  ArcRotateCameraPointersInput,
  generateFacetFaceMapping,
};
