"use strict";

import {getDynamicThreshold, isCADMesh} from "../../libs/snapUtilities";
import {convertLocalVector3ToGlobal, getActiveStoreyObject, getDistanceBetweenVectors} from "../extrafunc";
import { uiIndicatorsHandler } from "../uiIndicatorOperations/uiIndicatorsHandler";
import { store } from "../utilityFunctions/Store";
import {getRefGroundPoint} from "../../libs/snapFuncs";
import {scenePickController} from "../utilityFunctions/scenePickController";
import {is2D} from "../../libs/twoDimension";
import {geometryUpdater} from "../sketchMassBIMIntegration/geometryUpdater";
import snapEngine from "./snapEngine";

const cadSnaps = (function (){
  
  let cadMeshLocalVerticesMap = new Map();
  let cadMeshGlobalVerticesMap = new Map();
  
  const add = function (mesh, localVertices){
    if (!isCADMesh(mesh)) return;
    
    cadMeshLocalVerticesMap.set(mesh, localVertices);
  }
  
  const remove = function (mesh) {
    cadMeshLocalVerticesMap.delete(mesh);
    cadMeshGlobalVerticesMap.delete(mesh);
  };
  
  const update = function (mesh) {
    mesh.computeWorldMatrix();
    
    const localVertices = cadMeshLocalVerticesMap.get(mesh);
    if (!localVertices) {
      console.warn("Mesh not found");
      return;
    }
    
    cadMeshGlobalVerticesMap.set(
      mesh,
      localVertices.map((v) => convertLocalVector3ToGlobal(v, mesh, false))
    );
  }
  
  const show = function () {
    cadMeshGlobalVerticesMap.forEach((globalVertices, mesh) => {
      const vertices = cadMeshGlobalVerticesMap.get(mesh);
      uiIndicatorsHandler.vertexIndicator.multiIndicators.show(vertices, mesh);
      
      console.log(vertices.length);
    });
  }
  
  const flush = function (){
    cadMeshLocalVerticesMap = new Map();
    cadMeshGlobalVerticesMap = new Map();
  }
  
  const getRawData = function (){
    return [cadMeshLocalVerticesMap, cadMeshGlobalVerticesMap];
  }
  
  const getSnapPoint = function (meshOfInterest, pickInfo, highThreshold = false) {
    
    const snapData = {
      cadSnappedPoint: null,
      cadPickInfo: null,
      closeVertices: [],
    };
    
    if (!is2D()) {
      return snapData;
    }
    
    if (snapEngine.alternateSnaps.isActive()){
      if (pickInfo.pickedMesh && isCADMesh(pickInfo.pickedMesh)){
        snapData.cadSnappedPoint = pickInfo.pickedPoint;
        snapData.cadPickInfo = pickInfo;
        
        return snapData;
      }
    }
    
    const layerPick = scenePickController.pickInvisibleMeshes(m => m.name === "ground1");
    // CAD files are always at y = 0 for some reason
    
    const groundPoint = layerPick.pickedPoint;
    
    // const baseThreshold = getDynamicThreshold(groundPoint);
    const baseThreshold = snapEngine.snapVerifier.getScreenSpaceThreshold();
    
    // let highThreshold = false;
    const threshold = highThreshold ? 3 * baseThreshold : baseThreshold;
    
    const cadMeshes = Array.from(cadMeshGlobalVerticesMap.keys());
    const activeMeshes = cadMeshes.filter(
      m => m.isVisible && m.visibility === 1 && m.intersectsPoint(groundPoint)
    );
    
    let closeVertices = [];
    let closestVertex;
    let closestVertexDistance = 1e5;
    let cadPickInfo;
    
    activeMeshes.forEach(mesh => {
      if (mesh === meshOfInterest) return;
      
      const globalVertices = cadMeshGlobalVerticesMap.get(mesh);
      
      globalVertices.forEach((v) => {
        // const d = getDistanceBetweenVectors(v, groundPoint);
        const d = snapEngine.snapVerifier.getScreenSpaceDistanceFromPointer(v);
        if (d < threshold){
          closeVertices.push(v);
          
          if (d < closestVertexDistance) {
            closestVertexDistance = d;
            closestVertex = v;
            cadPickInfo = geometryUpdater.util.getFakePickInfo(v, { mesh });
          }
          
        }
      });
    });
    
    if (closestVertex) {
      closestVertex = closestVertex.clone();
      closestVertex.y = getActiveStoreyObject().height;
    }
    
    snapData.cadSnappedPoint = closestVertex;
    snapData.cadPickInfo = cadPickInfo;
    snapData.closeVertices = closeVertices;
    
    return snapData;
  }
  
  return {
    add,
    remove,
    update,
    show,
    flush,
    getRawData,
    
    getSnapPoint,
  };
  
})();

export default cadSnaps;
