import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useOnClickOutside from "../../../../common/utils/onOutsideClickHandler";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import ScrollableDropdown from "../../../../components/ScrollableDropdown";
import { store } from "../../../../snaptrude/modules/utilityFunctions/Store";
import { colors } from "../../../../themes/constant";
import { RIGHT_SUB_MENU_ICONS } from "../../Icons";
import add_icon from "../../../../assets/menu_icons/add_storey.svg";
import delete_icon from "../../../../assets/menu_icons/delete.svg";
import enable_drag_icon from "../../../../assets/menu_icons/enable_drag.svg";
import drag_icon from "../../../../assets/menu_icons/drag.svg";
import { getStructureObjectProps, saveStructure } from "../../../../snaptrude/modules/objectProperties/objectStructureController";
import Tooltip from "../../../../components/Tooltip";
import dropArrowIcon from '../../../../assets/icons/dropArrow.svg';
import { wallTypeChange } from "../../../../snaptrude/stateManagers/reducers/objectProperties/objectPropertiesSlice";


const Main = styled.div`
  position: relative;
  box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
  .container:before {
    content: "";
    position: absolute;
    top: 0;
    right: -2.1875rem;
    height: 2.1875rem;
    width: 2.1875rem;
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 20px 0 0 white;
    transform: rotate(90deg);
    pointer-events: none;
  }
  pointer-events: ${props => props.isLocked ? 'none' : 'auto'};
`;

const Container = styled.div`
  display: flex;
  width: 20.9375rem;
  height: 21.875rem;
  border-radius: 0.625em;
  background-color: ${colors.fullWhite};
  .multiple-items{
    font-weight: bold;
    text-align: left;
    background: rgb(255, 255, 255);
    padding: 0.025rem 0.75rem;
    font-size: 0.8em;
    color: ${colors.primeBlack};
  }
  .show-hide-buttons{
    font-weight: bold;
    text-align: left;
    background: rgb(255, 255, 255);
    padding: 0.025rem 0.75rem;
    font-size: 0.8em;
    color: ${colors.primeBlack};
    cursor: pointer;
  }
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: center;
  padding: 1rem 1.375rem;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0.125rem 0;
  // justify-content: space-between;
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${colors.darkGrey};
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.230052px;
  flex: 2;
`;

const TopContainerValue = styled.div`
  P {
    font-family: ${(props) => props.theme.font};
    color: ${colors.primeBlack};
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.230052px;
    opacity: 0.9;
    text-align: right;
  }
  width: 7.625rem;
  flex: 5;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Col = styled.div`
// border: 2px solid crimson;
  flex: ${props => props.flex ? props.flex : "1"};
  margin: 1px 5px 1px 0px;
  img{
    cursor: pointer;
  }
`;
const EditStructureWrapper = styled.div`
  height: 100%;
  width: 100%;
  font-family: ${props => props.theme.font};
  font-size: 12px;
  input{
    font-family: ${props => props.theme.font};
  }
`

function ObjectsInGroup({group}){
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleClick = (evt, newCollapsedState) => {
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    } 
    setIsCollapsed(newCollapsedState);
  }
  return <>
    {isCollapsed && <div className="show-hide-buttons" onClick={(evt) => {handleClick(evt, false)}} >Show Objects in Group <img src={dropArrowIcon} alt=">" /></div>}  
    {
      !isCollapsed && group.map(val => {
        return <div className="multiple-items">{val}</div>
      })
    }
    {!isCollapsed && <div className="show-hide-buttons" onClick={(evt) => {handleClick(evt, true)}} >Hide Objects in Group <img style={{transform: "rotate(180deg)"}} src={dropArrowIcon} alt=">" /></div>}
  </>
}

const GetItemByType = ({item, index, handleSubmit}) => {
  const [value, setValue] = useState(item.value);
  switch (item.type) {
    case "input-box":
      if(Array.isArray(item.value)){
        return <ObjectsInGroup group={item.value} />
      }
      return (
        <form onSubmit={(e) => {
           e.preventDefault();
           handleSubmit(index, value, item.value)
          }} 
        >
        <Input
          type={"text"}
          required
          value={value}
          labelStyle={{ padding: 0 }}
          onPress={(e) => {if(item.isEditable) setValue(e.target.value)}}
          onBlur={(e) => {if(item.isEditable) handleSubmit(index, e.target.value, item.value)}}
          customInput={{
            width: "6.375rem",
            height: "1.25rem",
            fontWeight: "bold",
            textAlign: item.isEditable ? "right" : !isNaN(item.value) ? "right" : "left",
            background: item.isEditable ? colors.white : colors.fullWhite,
          }}
          isInputDisabled={!item.isEditable}
        />
        </form>
      );
    case "drop-down":
      return (
        <ScrollableDropdown
          cutomTextStyle={{
            fontWeight: "bold",
            fontSize: "12px",
            paddingLeft: 0,
          }}
          options={item.options}
          selectedOption={item.value}
          onChange={(value, evt) => {if(item.isEditable) {
            handleSubmit(index, value, item.value)}
            // evt.stopPropagation();
          }
          } 
          width="100%"
          height="auto"
        />
      );
    case "fixedValue":
      return (
        <TopContainerValue>
          <p
            style={
              item.align && item.align === "left"
                ? { textAlign: "left", fontSize: "12px" }
                : {}
            }
          >
            {value}
          </p>
        </TopContainerValue>
      );
    default:
      return null;
  }
};

const ObjectProperties = ({reduxProperties, closePopUp}) => {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    if(store.selectionStack.length === 0 ){
      closePopUp();
    }
    else if(store.selectionStack[0].name.includes("terrain")){
      closePopUp();
    }
    setProperties(reduxProperties);
  }, [reduxProperties])
  const dispatch = useDispatch();

  const handleUpdateObjectProperties = (index, value, oldValue) => {
    if (value === oldValue) return;
    reduxProperties[index].element.onChange(null, value);
    if(reduxProperties[index].name === "Wall Type"){
      dispatch(wallTypeChange({index, value}))
    }
  }

  return <>
    {
    store.selectionStack.length === 0 ? 
      <Row><Col>Please select an object</Col></Row>
     :
     store.selectionStack.length !== 0 && properties.length === 0 ? 
      <Row>
        <Col>Object</Col>
        <Col>Varies</Col>
      </Row>
      :
      properties.map((property, index) => {
        return <>
          {
          property.name === "object-buttons" ? 
          <></> 
          : 
            <TopContainer key={index} >
              <TopContainerLabel>
                {property.name}
              </TopContainerLabel>
              <TopContainerValue>
                <GetItemByType item={{
                    type: property.type, 
                    value: property.value, 
                    isEditable: property.editable,
                    options: property.options
                  }} 
                  index={index} 
                  handleSubmit={handleUpdateObjectProperties}  
                />
              </TopContainerValue>
            </TopContainer>
          }
          
        </> 
      })
    }
  </>
}

const StructureObjectProperty = ({index, value, thickness, core, onChange, onDeleteMode, deleteProperty, style, onDragLeave, onDrop, onDragStart, onDragOver, onDragMode}) => {

  if(onDeleteMode || onDragMode){
    return (
      <Row 
      isHoverable={true}
      isDraggable={true}
      style={style}
      draggable={onDragMode}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      
    >
      <Col className="dragIcon" flex="none"><img src={drag_icon} alt="drag" style={{visibility: onDragMode ? "visible" : "hidden"}} /></Col>
      <Col>
        <TopContainerValue style={{width: "100%", padding: "5px"}}>{core ? "Structure" : "Finish"}</TopContainerValue>
      </Col>
      <Col>
        <TopContainerValue style={{width: "100%", padding: "5px"}}>{value}</TopContainerValue>
      </Col>
      <Col>
        <TopContainerValue style={{width: "100%", padding: "5px"}}>{thickness}</TopContainerValue>
      </Col>
      <Col className="deleteIcon" flex="none"><img src={delete_icon} alt="delete" style={{width: "13px", visibility: onDeleteMode ? "visible" : "hidden"}} onClick={() => {deleteProperty(index)}} /></Col>
    </Row>
    )
  }

  return (
    <Row 
      isHoverable={true}
      isDraggable={true}
      style={style}
      draggable={onDragMode}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      
    >
      <Col className="dragIcon" flex="none"><img src={drag_icon} alt="drag" style={{visibility: onDragMode ? "visible" : "hidden"}} /></Col>
      <Col>
        <ScrollableDropdown 
          width="100%"
          cutomTextStyle={{
            fontSize: "12px",
            paddingLeft: "0px",
          }}
          selectedOption={core ? "Structure" : "Finish"}
          options={["Structure", "Finish"]}
          onChange={(opt, evt) => {onChange(index, "core", opt === "Structure"); evt.stopPropagation()}}
        />
      </Col>
      <Col>
        <Input 
          type="text" 
          value={value} 
          // placeholder="Enter material"
          onPress={(e) => {onChange(index, "value", e.target.value)}} 
          customInput={{width: "100%", height: "auto", fontSize: "12px", padding: "5px"}} 
        />
      </Col>
      <Col>
        {core ? 
          <TopContainerValue style={{width: "100%"}}><p>{thickness}</p></TopContainerValue>
          :
          <Input 
            type="text" 
            value={thickness} 
            // placeholder="Thickness" 
            onPress={(e) => {onChange(index, "thickness", e.target.value)}} 
            customInput={{width: "100%", height: "auto", fontSize: "12px", padding: "5px", textAlign: "right"}} 
          />
        }
      </Col>
      <Col className="deleteIcon" flex="none"><img src={delete_icon} alt="delete" style={{width: "13px", visibility: onDeleteMode ? "visible" : "hidden"}} onClick={() => {deleteProperty(index)}} /></Col>
    </Row>
  )
}

const EditStructure = ({reduxProperties, setShowEditStructure}) => {
  const [structureObjProps, setStructureObjProps] = useState([]);
  const [activeFamilyName, setActiveFamilyName] = useState("");
  const [isFamilyNameEditable, setIsFamilyNameEditable] = useState(false);
  const [onDeleteMode, setOnDeleteMode] = useState(false);
  const [onDragMode, setOnDragMode] = useState(false);
  const [improperObject, setImproperObject] = useState(false);
  const [styles, setStyles] = useState([]);
    useEffect(() => {
    if(store.selectionStack.length === 0) return;
    let activeObject = null;
    reduxProperties.forEach(property => {
      if(property.name === "object-buttons"){
        activeObject = property.value;
      }
    })
    const { activeFamilyName, structureObjectProps, improperObject } = getStructureObjectProps(activeObject);
    
    const newStyles = structureObjectProps.map(item => {
        return {
            margin: "0px",
        }
    })
    setStyles(newStyles);
    setImproperObject(improperObject);
    setStructureObjProps(structureObjectProps);
    setActiveFamilyName(activeFamilyName);
    // console.log(structureObjectProps);
  }, []);

  const onChange = (index, property, newValue) => {
    const newStructureObjProps = structureObjProps.slice();
    newStructureObjProps[index][property] = newValue;
    setStructureObjProps(newStructureObjProps);
  }

  const handleFamilyNameSubmit = (e) => {
    e.preventDefault();
    setIsFamilyNameEditable(false);
  }

  const addProperty = () => {
    setOnDeleteMode(false);
    setOnDragMode(false);
    const newProperty = {
      "check": false,
      "value": "",
      "subtype": "",
      "thickness": "",
      "quantity": "volume",
      "unit": "Cubic metres",
      "core": false
    }
    const newStructureObjProps = structureObjProps.slice();
    newStructureObjProps.push(newProperty);
    setStructureObjProps(newStructureObjProps);
  }

  const deleteProperty = (indexToDelete) => {
    const newStructureObjProps = structureObjProps.filter((property, index) => index !== indexToDelete);
    setStructureObjProps(newStructureObjProps);
  }

  const handleSaveStructure = () => {
    let activeObject = null;
    reduxProperties.forEach(property => {
      if(property.name === "object-buttons"){
        activeObject = property.value;
      }
    })
    saveStructure(activeObject, structureObjProps, activeFamilyName);
  }

  // FOR DRAG AND DROP
  const handleDragStart = (evt, index) => {
    evt.dataTransfer.setData("text", index);
  }
  const handleDragOverItem = (evt, index) => {
      evt.preventDefault();
      const newStyles = styles.slice();
      newStyles[index] = {
          padding: "10px 0px 0px 0px",
      }
      setStyles(newStyles);
  }
  const handleDragLeaveItem = (evt, index) => {
      evt.preventDefault();
      const newStyles = styles.slice();
      newStyles[index] = {
          padding: "0px",
      }
      setStyles(newStyles);
  }
  const handleDrop = (evt, droppedOn) => {
      evt.preventDefault();
      const draggedIndex = evt.dataTransfer.getData("text");
      // console.log("Dropped " + draggedIndex + " on " + droppedOn);
      const newStyles = styles.slice();
      newStyles[droppedOn] = {
          padding: "0px",
      }
      setStyles(newStyles);
      if(draggedIndex === droppedOn) return;
      if(droppedOn > draggedIndex) --droppedOn;
      const newList = structureObjProps.slice();
      const draggedItem = newList[draggedIndex];
      newList.splice(draggedIndex, 1);
      // console.log(newList);
      newList.splice(droppedOn, 0, draggedItem);
      setStructureObjProps(newList)
  }

  return (
    <EditStructureWrapper>
      {
        improperObject ? <></>
        :
        <StyledContainer style={{height: "100%"}}>
          <div style={{ overflowY: "auto", overflowX: "hidden" , flex: 1}}>
            <Row style={{fontSize: "14px"}}>
              <Col>
                {
                  isFamilyNameEditable ? 
                    <form onBlur={handleFamilyNameSubmit} onSubmit={handleFamilyNameSubmit}>
                    <Input 
                      type="text"
                      value={activeFamilyName}
                      customInput={{width: "100%", height: "auto", fontSize: "12px", padding: "5px"}} 
                      onPress={(e) => {setActiveFamilyName(e.target.value)}}
                    />
                    </form>
                  :
                    activeFamilyName
                }
              </Col>
              <Col flex="none">
                <img 
                  onClick={() => {setIsFamilyNameEditable(!isFamilyNameEditable)}} 
                  src={RIGHT_SUB_MENU_ICONS.editMaterial} 
                  alt="edit" 
                  data-tip
                  data-for="edit-family-name"
                />
                <Tooltip id="edit-family-name">Edit Name</Tooltip>
              </Col>
            </Row>
            <Row style={{fontWeight: "bold", marginBottom: "5px"}}>
            <Col className="dragIcon" flex="none"><img src={drag_icon} alt="drag" style={{visibility:"hidden"}} /></Col>
              <Col>Function</Col>
              <Col>Material</Col>
              <Col>Thickness</Col>
            </Row>
            {
              structureObjProps.map((property, index) => {
                return <StructureObjectProperty 
                  index={index}
                  key={index} 
                  core={property.core} 
                  value={property.value} 
                  thickness={property.thickness} 
                  onChange={onChange}
                  onDeleteMode={onDeleteMode}
                  deleteProperty={deleteProperty}
                  
                  style={styles[index]}
                  onDragMode={onDragMode}
                  onDragStart={(evt) => {handleDragStart(evt, index)}}
                  onDragOver={(evt) => {handleDragOverItem(evt, index)}}
                  onDragLeave={(evt) => {handleDragLeaveItem(evt, index)}}
                  onDrop={(evt) => {handleDrop(evt, index)}}
                  
                />
              })
            }
          </div>
          <div>
            <Row>
              <Col flex="none">
                <img src={add_icon} 
                  alt="add" 
                  onClick={addProperty} 
                  style={{width: "15px"}}
                  data-tip
                  data-for="insert"
              />
              <Tooltip id="insert">Insert</Tooltip>
              </Col>
              <Col flex="none">
                <img src={enable_drag_icon} alt="drag" 
                onClick={() => {setOnDeleteMode(false); setOnDragMode(!onDragMode);}}
                style={{width: "13px", filter: onDragMode ? "invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg) brightness(93%) contrast(98%)" : "none"}}
                data-tip
                data-for="arrange" 
              />
              <Tooltip id="arrange">Arrange</Tooltip>
              </Col>
              <Col flex="none"><img src={delete_icon} 
                alt="delete" 
                onClick={() => { setOnDragMode(false); setOnDeleteMode(!onDeleteMode);}} 
                style={{width: "13px", filter: onDeleteMode ? "invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg) brightness(93%) contrast(98%)" : "none"}} 
                data-tip
                data-for="delete" 
              /></Col>
              <Tooltip id="delete">Delete</Tooltip>
            </Row>

            <Row>
              <Col style={{display: "flex", justifyContent: "center"}}>
              <Button
                onPress={()=> {setShowEditStructure(false)}}
                customButtonStyle={{
                  backgroundColor: colors.transparent,
                  color: colors.dullgrey
                }}
                title={`< Back`}
              />
            </Col>
            <Col style={{display: "flex", justifyContent: "center"}}>
                <Button 
                  customButtonStyle={{minHeight: "0px", width: "auto", padding: "2px 5px"}} 
                  outline 
                  title="Save Structure"
                  onPress={handleSaveStructure}
                ></Button>
            </Col>
          </Row>

          </div>
        </StyledContainer>
      }
    </EditStructureWrapper>
  )
}

function ObjectPropertiesEdits(props) {
  const { data, buttonsData, closePopUp, showEditStructure, setShowEditStructure } = props;
  // eslint-disable-next-line no-unused-vars
  const [inputValuesCopy, setInputValuesCopy] = useState(data);
  const objectProperties = useSelector(state => state.objectProperties);
  // const [showObjectProperties, setShowObjectProperties] = useState(true);
  const [isRefreshed, setIsRefreshed] = useState(true);
  const [objectPropertiesUiData, setObjectPropertiesUiData] = useState([]);
  const [isLocked, setIsLocked] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();
  
  // useOnClickOutside(ref, () => {
  //   if (closePopUp) closePopUp();
  // });

  useEffect(() => {
    setIsLocked(true);
    let isAnyOneLocked = false;
    store.selectionStack.forEach(selection => {
      if(selection.getSnaptrudeDS()?.isLocked) isAnyOneLocked = true;
    })
    setIsLocked(isAnyOneLocked);
  }, [objectProperties]);

  const closeOnOutsideClick = (evt) => {
    if(!ref.current.contains(evt.target)) {
      if(store.selectionStack.length === 0) closePopUp();
      else {
        // setShowObjectProperties(false);
        setIsRefreshed(false);
        setTimeout(() => {setIsRefreshed(true)}, 1);
      }
    }
  }

  const handleDeleteKey = (evt) => {
    const key = evt.which || evt.keyCode;
    if(key === 46){
      closePopUp();
    }
  }

  useEffect(() => {
    window.addEventListener("click", closeOnOutsideClick);
    window.addEventListener("keyup", handleDeleteKey);
    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
      window.removeEventListener("keyup", handleDeleteKey);

    }
  }, [closeOnOutsideClick]);
  
  const updateInputValue = (index, item, e) => {
    e.persist();
    setInputValuesCopy((prevState) => {
      prevState[index] = {
        title: item.title,
        value: parseInt((e.target && e.target.value) || 0, 10),
      };
      return [...prevState];
    });
  };

  return (
    <Main ref={ref} isLocked={isLocked}>
      {
        isRefreshed ? 
      <Container className="container">
        
        {
          showEditStructure ? 
          <Inner>
            <EditStructure reduxProperties={objectProperties.objProps} setShowEditStructure={setShowEditStructure} />
          </Inner>
          :
          <Inner>
            <div style={{ width: "100%", overflowY: "auto", overflowX: "hidden", height: "100%" }}>
              <StyledContainer>
                    <ObjectProperties reduxProperties={objectProperties.objProps} closePopUp={closePopUp} />
              </StyledContainer>

            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  buttonsData && buttonsData.length === 1
                    ? "center"
                    : "space-between",
                width: "100%",
                paddingTop: "1rem",
              }}
            >
              {store.selectionStack.length !== 0 && buttonsData && (!["Door", "Window", "Furniture", "Varies"].includes(objectProperties.objProps[0]?.value))  &&
                buttonsData.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      title={item.title}
                      customButtonTextStyle={{
                        fontSize: "11px",
                        lineHeight: "14px",
                        letterSpacing: "-0.333333px;",
                      }}
                      customButtonStyle={{
                        // backgroundColor: colors.dullgrey,
                        height: "2.5rem",
                        width: "4.375rem",
                        minHeight: "auto",
                        padding: "0.375rem 0.25em",
                      }}
                      onPress={() => {if(item.onClick) item.onClick()}}
                      outline
                    />
                  );
                })}
            </div>
          </Inner>
        }
      </Container>  
        :
        <></>
      }
    </Main>
  );
}

export default ObjectPropertiesEdits;
