import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import dropArrowIcon from "../../assets/icons/dropArrow.svg"
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';

const StyledTable = styled.div`
    // border: 5px solid;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
`
const StyledTableRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    min-width: 100%;
    width: fit-content;
    box-sizing: border-box;
    border-bottom: ${(props) => props.borderedBottom ? "1px solid rgba(96, 129, 159, 0.2)" : "0px"};
    position: ${props => props.sticky ? "sticky" : "static"};
    top: ${props => props.sticky ? "0px" : "auto"};
    background: ${props => props.sticky ? "#fcfcfc" : "transparent"};
    z-index: ${props => props.sticky ? 10 : "auto"};
`
const StyledTableData = styled.div`
    padding: 5px;
    width: 10em;
    min-width: 3em;
`
const StyledTableHeading = styled(StyledTableData)`
    font-weight: bold;
    position: relative;
`
const StyledTableHeadingButton = styled.span`
    position: absolute;
    background: #F2F2F2;
    border-radius: 3px;
    width: 1.25em;
    height: 1.25em;
    right: 5px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const StyledTableText = styled.div`
    background: #F2F2F2;
    border-radius: 5px;
    padding: 2px 8px;
`
const StyledTableSelect = styled.div`
    position: relative;
    box-sizing: border-box;
    text-transform: capitalize;
    .selection-wrapper{
        display: flex;
        cursor: pointer;
        font-weight: 500;
        color: #2D2D2E;
    }

    .selected{
        flex: 1;
        text-align: right;
        padding: 0em 0.25em;
    }

    .options-wrapper{
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        right: 0;
        border: 2px solid crimson;
        display: flex;
        flex-direction: column;
        text-align: right;
        z-index: 5;

        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        box-sizing: border-box;
        box-shadow: 0px 4px 30px rgba(197, 202, 220, 0.3);
        border-radius: 12px;
        padding: 0.25em;
        color: #878787;
        font-weight: 500;
        max-height: 15em;
        overflow: auto;
        overscroll-behavior: contain;

    }

    .option{
        padding: 0.15em 0.75em;
        cursor: pointer;
    }
    .option.active{
        color: #2D2D2E;
    }
`
// Table component
function DataTable({...props}) {
    return ( 
        <StyledTable {...props} />
    );
}

// Table Row (tr) component
function TableRow({borderedBottom, sticky, ...props}) {
    return ( 
        <StyledTableRow borderedBottom={borderedBottom} sticky={sticky} {...props} />
     );
}

// Table Heading (th) component
function TableHeading({...props}) {
    return ( 
        <StyledTableHeading {...props} />
     );
}

// Table heading button component
function TableHeadingButton({onClick, ...props}) {
    const handleClick = () => {
        if(onClick) onClick();
    }
    return (
        <StyledTableHeadingButton onClick={handleClick} {...props} />
    )
}

// Table Data (td) component.
function TableData({...props}) {
    return ( 
        <StyledTableData {...props} />
     );
}

function TableText({...props}){
    return (
        <StyledTableText {...props} />
    )
}

function TableSelect({selectedOption, options, onSelect, ...props}){
    const ref = useRef(null);
    const [showOptions, setShowOptions] = useState(false);
    const [selected, setSelected] = useState(selectedOption || options[0]);
    useOutsideClickHandler(ref, () => {setShowOptions(false)});
    const handleOptionClick = (clickedOption, index) => {
        setSelected(clickedOption);
        setShowOptions(false);
        if(onSelect) onSelect(clickedOption, index);
    }
    return (
        <StyledTableSelect ref={ref} {...props}>
                {
                    showOptions &&
                    <div className='options-wrapper'>
                        {
                            options.map((option,index) => {
                                return <div className={`option ${option === selected ? "active" : ""}`} key={`${option}-${index}`} onClick={() => {handleOptionClick(option, index)}}>{option.displayValue || option}</div>
                            })
                        }
                    </div>
                }
                            <div className='selection-wrapper' onClick={() => {setShowOptions(true)}}>
                                <div className='selected'>{selected.displayValue || selected}</div>
                                <img src={dropArrowIcon} alt=">"/>
                            </div>
            
        </StyledTableSelect>
    )
}

export default DataTable;
export {
    TableRow as Tr, 
    TableHeading as Th, 
    TableData as Td,
    TableHeadingButton as ThButton,
    TableText as Text,
    TableSelect as Select,
}