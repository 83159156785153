import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
var appElement = document.querySelector("[ng-app=Webtrude]");
// document.getElementById("Material Schedule").onclick = function () {
//   computeAreaMats();
// }

// document.getElementById("Project Properties").onclick = function () {
//   BuiltUpArea();
// }

$("#mheight").on("input", function () {
  store.max_height = parseFloat($(this).val());
  if (
    store.floor_height > (store.max_height * 100 * store.unit_scale) / 2.54 &&
    store.max_height != 0
  ) {
    $("#totHeightId").css("color", "red");
  } else {
    $("#totHeightId").css("color", "black");
  }
  // store.max_height_box.scaling.y = store.max_height*100/2.54*unit_scale;
  // store.max_height_box.position.y = store.max_height_box.scaling.y/2;
});

$("#fheight").on("input", function () {
  store.floor_height =
    ((parseFloat($(this).val()) * 100) / 2.54) * store.unit_scale;
});

$("#sar").on("input", function () {
  store.sar = parseFloat($(this).val());
  store.allowed_built_area = store.far * store.sar;
  // store.allowed_built_area;
});

$("#far").on("input", function () {
  store.far = parseFloat($(this).val());
  store.allowed_built_area = store.far * store.sar;
  // store.allowed_built_area;
});
export { appElement };
