import CameraCursor from "../cursor/cameraCursor";
import CursorFactory from "../cursor/cursorFactory";
import { ForgeConnection } from "../forgeConnection/forgeConnection";
import { socialConnect } from "../social/socialSocket";
import { AutoSave } from "../socket/autoSave";
import { autoSaveConfig } from "../socket/autoSaveConfig";
import { mouseMove } from "../socket/mouseMove";
import { contextmenu, keydown, resize, beforeunload } from "./eventListeners";

const unmount = () => {
  window.removeEventListener("contextmenu", contextmenu);
  document.body.removeEventListener("keydown", keydown, false);
  window.removeEventListener("resize", resize);
  window.removeEventListener("beforeunload", beforeunload);
  autoSaveConfig.forceDisconnect();
  autoSaveConfig.emptyCounter();
  autoSaveConfig.enable();
  AutoSave.emptyCounter();
  AutoSave.emptyHistory();
  ForgeConnection.forceDisconnect();
  socialConnect.forceDisconnect();
  const cameraCursor = new CameraCursor();
  cameraCursor.destory();
  const cursorFactory = new CursorFactory();
  cursorFactory.destroy();
  mouseMove.unbroadcast();
};

export {
  unmount,
};
