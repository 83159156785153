import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import Button from "../../Button";
import Input from "../../Input";
import closeButtonIcon from "../../../assets/icons/close.svg";
import { speckleRevitImport } from "../../../snaptrude/modules/speckleRevitImport";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { djangoUrl } from "../../../services/url.constants";


const Wrapper = styled.div`
    position: relative;
    width: 36.5625rem;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: ${colors.primeBlack};
    header{
        padding: 0.75em 1em;
        border-radius: 12px 12px 0px 0px;
        background-color: #F2F2F2;
        display: flex;
        align-items: center;
    }
    .content{
        min-height: 15em;
        max-height: 17em;
        overflow: auto;
        padding: 1em 6em;
        text-align: center;
        font-weight: normal;
        color: #4F4F4F;
        font-size: 14px;
    }
    .content strong{
      color: #2D2D2E;
    }
`
function LabelAndInput({id, labelText, value, handleChange}){
    return (
        <div style={{display: "flex", alignItems: "center", margin: "0.5em 0em"}}>
            <label htmlFor={id} style={{width: "35%"}}>{labelText}</label>
            <Input type={"text"} id={id} value={value} placeholder={labelText} onPress={handleChange} customInput={{padding: "0.5em", height: "auto"}} />
        </div>
    )
}
function ImportRevit({updatePage, handleClose, nextPage, speckleStreamId, ...props}) {
    const [isLoading, setIsLoading] = useState(false);
    const [speckleId, setSpeckleId] = useState(speckleStreamId || '');
    const [speckleAccount, setSpeckleAccount] = useState("");
    const [commitId, setCommitId] = useState("");
    const [revitFileName, setRevitFileName] = useState("");
    let team ;
    const { storeyArrayRedux, projectMetadata, teamList } = useSelector(
      (state) => {
        return {
          storeyArrayRedux: state.storeys.items,
          projectMetadata: state.projectMetadata,
          teamList: state.teams.teamlist,
        };
      }
    );
    if (projectMetadata.isTeamsProject) {
      let currentProjectTeam = teamList.find(
        (team) => team.id === projectMetadata.team.id
      );
      team = currentProjectTeam;
    }
    const handleSubmit = async (evt) => {
      if (evt) evt.preventDefault();
      const anchorElement = document.createElement('a');
      anchorElement.setAttribute("id", "adityaw")
      anchorElement.setAttribute("href", djangoUrl + "/media/manager/snaptrude-manager-setup.exe");
      anchorElement.setAttribute("downlaod", "true");
      anchorElement.click();
      anchorElement.remove();
      handleClose();
    };
    return ( 
        <Wrapper>
            <header>
                <div style={{flex: 1, fontWeight: 700}}>Import Revit</div>
                <div style={{cursor: 'pointer'}}><img src={closeButtonIcon} alt="Close" onClick={handleClose} /></div>
            </header>
            <div className="content">
                <p>Download and install the <strong>Snaptrude Manager</strong> plugin for Revit to import Revit models.</p>
                <p style={{marginTop: "3em"}}>You can download the latest version by clicking on the button.</p>
            </div>
            <footer>
                <div className="button-wrapper">
                    <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} title={"Cancel"} onPress={handleClose} />
                </div>
                <div className="button-wrapper">                    
                    <Button isLoading={isLoading} primary={true} title={"Download Snaptrude Manager"} onPress={handleSubmit} />
                </div>
            </footer>
        </Wrapper>
    );
}

export default ImportRevit;