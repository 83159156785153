/*jshint esversion: 6 */
"use strict";
import { meshObjectMapping } from "./mapping.js";
/**
 * { CurtainWalls datastructure }
 *
 * @class      Curtain Walls (name)
 * @param      {Object}  mesh    The mesh
 */
var CurtainWall = function (mesh) {
  this.mesh = mesh;
  this.level = mesh.level;
  this.level_low = 0;
  this.level_hight = 0;
  this.height = 0;

  this.structure_id = mesh.structure_id;
  this.id = "s_" + Math.random().toString(36).substr(2, 9);
  this.type = "CurtainWalls";
  this.mesh.type = "CurtainWalls";
  this.material = "";
  this.onclick = null;
  this.groupId = null;
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  this.isLocked = false;
  this.onMove = {
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (evt) {
      //pass
    },

    onPointerMove: function (direction, diff, speed) {
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.name.indexOf("boxScale") != -1) {
        this.mesh.parentMesh.position = this.mesh.position;
      }
    },
  };
};
/**
 * Remove a CurtainWall from level.
 *
 * @param      {<type>}  object  The object
 */
CurtainWall.prototype.removeCurtainWallFromLevel = function (level) {
  meshObjectMapping.deleteMapping(this.mesh);
  for (let i = 0; i < level.flyweight.curtainwalls.length; i++) {
    if (this.uniqueId === level.flyweight.curtainwalls.uniqueId) {
      level.flyweight.curtainwalls.splice(i, 1);
      return;
    }
  }
};
export { CurtainWall };
