import BABYLON from "../../modules/babylonDS.module.js";
import { store } from "../../modules/utilityFunctions/Store.js";
import { commandUtils } from "../../modules/commandManager/CommandUtils.js";

const snapmda = window.snapmda;

const scaleWholeScene = (factor) => {
  // unit_scale = factor;
  let meshes = store.scene.meshes;
  console.log("MESH", meshes);
  let meshesNeedScaling = meshes.filter((mesh) => {
    return (
      mesh.id != "axisX" &&
      mesh.id != "axisY" &&
      mesh.id != "axisZ" &&
      mesh.id != "ground1" &&
      mesh.id != "backwall"
    );
  });
  console.log("SCALETHESE", meshesNeedScaling);
  for (let i = 0; i < meshesNeedScaling.length; i++) {
    let mesh = meshesNeedScaling[i];
    mesh.setPivotPoint(new BABYLON.Vector3.Zero());
    mesh.scaling = mesh.scaling.multiply(
      new BABYLON.Vector3(factor, factor, factor)
    );
    let bbinfo = mesh.getBoundingInfo();
    let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mesh.setPivotPoint(centroid);
  }
};

const updateFPScale = (factor) => {};

var scaleSceneMeshes = (factor, scaleType) => {
  // let meshes = store.scene.meshes.filter(mesh => mesh.type !== undefined && mesh.type.toLowerCase() !== 'ground' && mesh.type.toLowerCase() !== 'backwall');
  // let meshes = store.selectionStack;
  if (scaleType === "imageScale") {
    let imagePlane = store.activeLayer.floorplans[0].mesh;
    imagePlane.scaling.multiplyInPlace(
      new BABYLON.Vector3(factor, factor, factor)
    );

    imagePlane.computeWorldMatrix(true);
    let bbInfo = imagePlane.getBoundingInfo();
    imagePlane.position.x -= bbInfo.boundingBox.minimumWorld.x;
    imagePlane.position.z -= bbInfo.boundingBox.maximumWorld.z;
  } else {
    if (store.selectionStack.length > 0) {
      let optionsBeforeSetScale = {
        params: [
          commandUtils.worldMatrixChangeOperations.PARAMS.position,
          commandUtils.worldMatrixChangeOperations.PARAMS.scale,
        ],
      };

      let setScaleBeforeCommandData =
        commandUtils.worldMatrixChangeOperations.getCommandData(
          null,
          optionsBeforeSetScale
        );

      let parentMesh = store.selectionStack[0];

      store.selectionStack.forEach(function (mesh) {
        if (mesh.uniqueId !== parentMesh.uniqueId) {
          if (mesh.parent) mesh._prevParent = mesh.parent.uniqueId;
          mesh.setParent(parentMesh);
        }
      });

      parentMesh.scaling.multiplyInPlace(
        new BABYLON.Vector3(factor, 1, factor)
      );
      parentMesh.computeWorldMatrix(true);
      // let bbInfo = parentMesh.getBoundingInfo();

      // parentMesh.position.x -= bbInfo.boundingBox.minimumWorld.x;
      // parentMesh.position.z -= bbInfo.boundingBox.maximumWorld.z;

      store.selectionStack.forEach(function (mesh) {
        mesh.state = "off";
        if (mesh._prevParent)
          mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
        else mesh.setParent(null);
      });

      let optionAfterSetScale = {
        data: setScaleBeforeCommandData,
        params: [
          commandUtils.worldMatrixChangeOperations.PARAMS.position,
          commandUtils.worldMatrixChangeOperations.PARAMS.scale,
        ],
      };

      let setScaleAfterCommandData =
        commandUtils.worldMatrixChangeOperations.getCommandData(
          null,
          optionAfterSetScale
        );

      commandUtils.worldMatrixChangeOperations.executeCommand(
        "setScale",
        setScaleAfterCommandData
      );
    }
  }
};


export { scaleWholeScene, updateFPScale, scaleSceneMeshes };
