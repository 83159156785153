import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const ReactTooltipStyled = styled(ReactTooltip)`
  font-weight: normal !important;
  font-size: 0.7em !important;
  padding: 5px !important;
`

function LibraryTooltip(props) {
  return (
    <ReactTooltipStyled
      place="bottom"
      effect="solid"
      {...props}
    />
  )
}

export default LibraryTooltip
