import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useOutsideClickHandler from "../../../common/utils/useOutsideClickHandler";
import { colors } from "../../../themes/constant";
import CheckBox from "../../Checkbox/index";

export const Container = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  padding: 0.3125rem 0.5rem;
  width: 100%;
  max-height: 15rem;
  overflow-y: auto;
  border-bottom-left-radius: ${({ borderBottomLeft }) =>
    borderBottomLeft ? borderBottomLeft : "8px"};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: ${({ borderTopRight }) =>
    borderTopRight ? borderTopRight : 0};
`;

export const CheckBoxList = (props) => {
  const { list, borderBottomLeft, borderTopRight, onClose, textAlign } = props;
  const ref = useRef(null);
  useOutsideClickHandler(ref, onClose);
  return (
    <Container
      ref={ref}
      borderBottomLeft={borderBottomLeft}
      borderTopRight={borderTopRight}
    >
      {list &&
        list.map((item, index) => {
          return (
            <CheckBox
              key={index}
              label={item.title}
              isChecked={item.isChecked}
              onPress={item.onPress}
              textAlign={textAlign}
            />
          );
        })}
    </Container>
  );
};
