import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import check from "../../../assets/images/check.svg";
import Button from "../../Button";
import Text from "../../Text";
import { colors } from "../../../themes/constant";

const PlanCard = styled.div`
  font-family: ${(props) => props.theme.font};
  border-radius: 0.75rem;
  height: 120%;
  margin-top: 5%;
  width: 15.875rem;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  position: relative;
  transition: box-shadow 0.5s ease-in-out;
  &:hover {
    box-shadow: 0 0.25rem 1.25rem rgba(197, 202, 220, 0.9);
  }
`;

const Selected = styled.div`
  ${({ onlyHeight }) => {
    if (onlyHeight) {
      return css`
        height: 0.25rem;
        width: 100%;
      `;
    } else {
      return css`
        height: 0.25rem;
        width: 100%;
        background: ${colors.brightRed};
        box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
        border-radius: 0.75rem;
        margin: 0 auto;
      `;
    }
  }}
`;

const CustomText = styled(Text)`
  opacity: ${({ opacity }) => opacity || 1};
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.theme.fontWeight[props.weight] || "normal"};
  font-family: ${(props) => props.theme.font};
`;

const Horizontal = styled.hr`
  border: 0.0625em solid ${colors.veryLightGrey};
  margin: 0 1rem 0 1rem;
`;

const Check = styled.div`
  margin-top: 1.75rem;
`;

const CheckList = styled.ul`
  list-style-image: url(${check});
`;

const ListItem = styled.li`
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  padding-right: 15px;
`;

const ActionWrapper = styled.div`
  height: auto;
  position: absolute;
  bottom: 0.625rem;
  right: 0;
  width: 90%;
  left: 0;
  margin: 0 auto;
`;

const Plan = ({
  primary,
  actionText,
  checkList,
  type,
  price,
  selected,
  buttonAction,
  ...rest
}) => {
  return (
    <PlanCard {...rest}>
      {selected ? <Selected /> : <Selected onlyHeight />}
      {type}
      {price}
      <Horizontal />
      <Check>
        <CheckList>
          {checkList.map((item, index) => (
            <ListItem key={index}>
              <CustomText size="0.875rem" color="primaryBlack">
                {item}
              </CustomText>
            </ListItem>
          ))}
        </CheckList>
      </Check>
      <ActionWrapper>
        {primary ? (
          <Button primary title={actionText} onPress={buttonAction} />
        ) : (
          <Button
            customButtonStyle={{
              background: "transparent",
              borderRadius: "none",
            }}
            onPress={buttonAction}
            customButtonTextStyle={{
              cursor: "auto",
              color: colors.brightRed,
            }}
            title={actionText}
          >
            {actionText}
          </Button>
        )}
      </ActionWrapper>
    </PlanCard>
  );
};

Plan.propTypes = {
  selected: PropTypes.bool,
  checkList: PropTypes.array,
  primary: PropTypes.bool,
  actionText: PropTypes.string,
  type: PropTypes.element,
  price: PropTypes.element,
  buttonAction: PropTypes.func,
};

export default Plan;
