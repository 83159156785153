"use strict";
/*jshint esversion: 6 */
import X2JS from "x2js";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { storeyView } from "./storeyView.js";
import { layerView } from "./layerView.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { AutoSave } from "../modules/socket/autoSave.js";
import reduxStore from "../stateManagers/store/reduxStore";
import { appendLayer } from "../stateManagers/reducers/objectProperties/storeysSlice";
import cadLayer from "../../assets/tiles/cadLayer.svg";

var cadImport = (function () {
  let comparePoints = function (pt1, pt2) {
    if (pt1.x == pt2.x && pt1.y == pt2.y && pt1.z == pt2.z) {
      return true;
    } else {
      return false;
    }
  };

  const importSVG = function (svg) {
    let x2js = new X2JS();
    svg = x2js.xml_str2json(svg);
    let polylines = svg["svg"]["polyline"];
    let lines = [];
    let curPoint2 = null;
    const defaultScale = 1;
    const offsetScale = 0;
    for (let i = 0; i < polylines.length; i++) {
      let pointSet = polylines[i]["_points"];
      let temp_path1 = [];
      let curPoint1 = null;
      if (!pointSet) continue;
      pointSet = pointSet.split(" ");
      for (let j = 0; j < pointSet.length; j++) {
        let arr = [];
        let pt1 = pointSet[j].split(",");
        curPoint1 = new BABYLON.Vector3(
          parseFloat(pt1[0]) / defaultScale - offsetScale,
          0,
          -parseFloat(pt1[1]) / defaultScale + offsetScale
        );
        if (j < pointSet.length - 1) {
          let pt2 = pointSet[j + 1].split(",");
          curPoint2 = new BABYLON.Vector3(
            parseFloat(pt2[0]) / defaultScale - offsetScale,
            0,
            -parseFloat(pt2[1]) / defaultScale + offsetScale
          );
        }
        if (!comparePoints(curPoint1, curPoint2))
          temp_path1.push([curPoint1, curPoint2]);
        if (temp_path1.length != 0 && temp_path1[0].length > 1) {
          let line = BABYLON.MeshBuilder.CreateLines(
            "sketchLine",
            { points: temp_path1[0] },
            store.scene
          );
          // let line = line2D("sketchLine", {path: temp_path1, width: 1.987 * 0.12}, store.scene, [0, 0, 0]);
          line.color = new BABYLON.Color3(0, 0, 0);
          let sketch = {};
          sketch.points = temp_path1;
          sketch.mesh = line;
          sketch.mesh.structure_id = store.activeLayer.structure_id;
          sketch.mesh.type = "linesystem";
          lines.push(sketch);
          temp_path1 = [];
        }
      }
    }

    return lines;
  };

  let createLayer = function (storeyCollection, storey, {layerId = null, layerName = null, autoSave = true, structure_id = null }) {
    let count = storeyCollection.getStoreyByValue(storey).layerData.getCountOfLayerByType("cad", storey);
    count = count + 1;

    let _layerName = layerName ? layerName : "cad-" + count;
    if(!structure_id){
      structure_id = store.activeLayer.structure_id;
    }
    layerView.addAndSelectLayer(_layerName, structure_id , storey, {layerId: layerId, autoSave: autoSave});

    let layer = store.activeLayer;
    reduxStore.dispatch(
      appendLayer({
        id: layer.id,
        title: layer.name,
        storey: storey,
        hidden: layer.hidden,
        heightMapToggle: layer.heightMapToggle,
        image: cadLayer,
        // subView: {
        //   allIcons: true,
        //   heightToggle: layer.heightMapToggle,
        //   thicknessSelector: false,
        //   colorPicker: false,
        // },
      })
    );
    // ScopeUtils.addLayer(store.activeLayer);
    return _layerName;
  };
  // let pushCadLayer = function (lines, fileName, storey, mesh) {
  //     store.activeLayer.sketches.push(...lines);
  //     store.activeLayer.mesh = mesh;
  //     layerView.selectLayer(fileName, store.activeLayer.structure_id, storey);
  //     // saveCad();
  // };

  const pushCADLayer = (edgeArray, fileName, storey, mesh, shift, position=[0,0,0], options) => {
    store.activeLayer.sketches.push({
      edgeArray,
      position,
      mesh,
      shift,
    });
    store.activeLayer.mesh = mesh;
    layerView.selectLayer(fileName, store.activeLayer.structure_id, storey, options);
  };

  let saveCad = function () {
    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = store.activeLayer.name;
    saveData.data.saveType = "addSketch";

    saveData.data.identifier = {
      structure_id: store.activeLayer.structure_id,
      storey: store.activeLayer.storey,
      layerName: store.activeLayer.name,
      floorkey: store.floorkey,
      layerId: store.activeLayer.id,
    };
    let allPoints = store.activeLayer.sketches.map(
      (sketch) => sketch.edgeArray
    );
    let justPoints = allPoints.map((pt) => pt.map((p) => p[0]));
    let afterData = {
      points: justPoints,
    };
    saveData.data.afterOperationData = afterData;
    AutoSave.directPublish(saveData);
  };

  return {
    import: importSVG,
    createCADLayer: createLayer,
    assignLayer: pushCADLayer,
    autoSaveCad: saveCad
  };
})();
export { cadImport };
