import React from "react";
import styled from "styled-components";
import logoIcon from "../../assets/images/logoIcon.svg";
import logoText from "../../assets/images/logoText.svg";
import snaptrudelogo from "../../assets/snaptrude.svg";
import snaptrudeIcon from "../../assets/snaptrudeIcon.svg";


const LogoContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = ({ tinyLogo }) => {
  return (
    <LogoContainer>
      {tinyLogo ? (
        <img src={snaptrudeIcon} alt="snaptrude" width={56} />
      ) : (
        <>
          <img src={snaptrudelogo} alt="snaptrude" width={192} />
        </>
      )}
    </LogoContainer>
  );
};

export default Logo;
