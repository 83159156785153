import BABYLON from "../modules/babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js"
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { StateMachine } from "../modules/Classes/StateMachine.js";
import { disposeHighlightedVertex,getComponentsLinkedToEdge,getCorrespondingGlobalPointOfOtherInstance,disposeAllVertexIndicators,indicateAllVertices,handleRoofPropertyChangeCommand,disposeHightlightedEdge } from "../modules/meshoperations/moveOperations/moveUtil.js";
import { isTwoDimension } from "./twoDimension.js";
import { findSecondarySnappedPoint } from "./snapFuncsPrimary.js";
import { getVertexNeighbours,getLowerVertexOfTheEdge,getTopFaceVertices,getFaceVerticesFromFacetID,getVertexObjectFromPositionV3,removeVertexFromComponent } from "./brepOperations.js";
import { vertexIndicator } from "../modules/uiIndicatorOperations/vertexIndicator.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { virtualSketcher } from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { deepCopyObject,isMeshCurved,convertGlobalVector3ToLocal } from "../modules/extrafunc.js";
import { pseudoEdgeRenderer } from "../modules/meshoperations/moveOperations/pseudoEdgeRenderer.js";
import { areaOfPolygon3D } from "./areaFuncs.js";
import { nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import { geometryUpdater } from "../modules/sketchMassBIMIntegration/geometryUpdater.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
import { areaOfPolygonV3 } from "./areaFuncs.js";

var removeElementsOperator = (function () {
  let _CONSTANTS = {
    operatingSpace: null,
    preSnapMaterial: null,
    postSnapMaterial: null,
    vertexBoxOGName: "vertexBoxOG",
  };
  const init = () => {
    _CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
    _CONSTANTS.preSnapMaterial = colorUtil.getMaterial(colorUtil.type.preSnap);
    _CONSTANTS.postSnapMaterial = colorUtil.getMaterial(colorUtil.type.postSnap);
  }
  let _EVENTS = {
    0: _cleanUp,
    0.5: _showAllVertices,
    1: _highlightElementToRemove,
    2: _removeElement,
  };

  let _mouseSequence = {
    pointermove: {
      0: [1, 1, 0],
      0.5: [1, 1, 0.5],
      1: [1, 1, [0, 0, 0]],
    },
    pointerdown: {
      0: [0.5, 0.5, 0],
      0.5: [0.5, 0.5, [0, 0, 0]],
      1: [2, [0, 0, 0], 1],
    },
  };

  let _touchSequence = {
    pointerdown: {
      0: [1, [2, [0, 0, 0], [0, 0, 0]], [0.5, 0.5, 0]],
      0.5: [1, [2, [0, 0, 0], [0, 0, 0]], [0, 0, 0]],
    },

    pointermove: {},
  };

  let STATE_MACHINE = new StateMachine(_EVENTS, _mouseSequence, _touchSequence);

  let _meshOfInterest = null;
  let _componentOfInterest = null;

  let _meshClickedOnId = null;
  let _edgeIndex = NaN;
  let _upperVertexPosition = null;
  let _upperFacetId = null;
  let _faceVertices = null;
  let _commandData = null;

  let _componentForGapFilling = null;
  let _gapFillDestination = null;

  const _componentsEligibleForGapFilling = [];

  const _meshesOfInterest = [];
  const _componentsOfInterest = [];

  let _removalPreviewData = {
    isEditable: null,
    topFaceVerticesOfEachComponent: null,
    modifiedTopFaceVerticesOfEachComponent: null,
    gapFillerComponentIndex: null,
    upperVertex: null,
    neighbouringVerticesOfEachComponent: null,
  };

  function onPointerDown(evt) {
    STATE_MACHINE.nextEvent(evt);
  }

  function onPointerMove(evt) {
    STATE_MACHINE.nextEvent(evt);
  }

  const _displayVertexAndShowPreview = function (pickInfo, aMeshWasClickedOn) {
    disposeHighlightedVertex();

    if (!pickInfo.hit) {
      _cleanUp();
      return false;
    }

    if (store.$scope.isTwoDimension) {
      let options = {
        edgeSnap: false,
        faceSnap: false,
        pickInfo,
        doNotDoSecondaryScenePicks: aMeshWasClickedOn,
        wantMetadata: true,
      };

      let vertex = findSecondarySnappedPoint(null, null, null, options);

      if (vertex) {
        if (options.metadata.pickInfo) pickInfo = options.metadata.pickInfo;

        let neighbours = getVertexNeighbours(
          vertex,
          pickInfo.pickedMesh,
          _CONSTANTS.operatingSpace
        );
        if (neighbours.length > 3) return false;

        const previousUpperVertex = _upperVertexPosition;

        _upperVertexPosition = vertex;
        _upperFacetId = pickInfo.faceId;
        _meshOfInterest = pickInfo.pickedMesh;
        _componentOfInterest = _meshOfInterest.getSnaptrudeDS();

        let resolved = true;
        if (previousUpperVertex) {
          if (vertex.almostEquals(previousUpperVertex)) {
            resolved = _removalPreviewData.isEditable;
          } else {
            resolved = _determineMassForGapFilling();
          }
        } else {
          resolved = _determineMassForGapFilling();
        }

        if (!resolved) {
          vertexIndicator.markAsUneditable(options.metadata.indicator);
          _removalPreviewData.isEditable = false;
        }

        return resolved;
      } else {
        _cleanUp();
        return false;
      }
    }
  };

  const _determineMassForGapFilling = function () {
    uiIndicatorsHandler.edgeIndicator.massDestruct();
    _componentsEligibleForGapFilling.length = 0;
    _componentsOfInterest.length = 0;
    _meshesOfInterest.length = 0;
    _removalPreviewData.isEditable = true;
    _removalPreviewData.upperVertexToRemoveOfEachComponent = [];

    const options = { facetId: _upperFacetId, faceId: NaN };
    const lowerVertex = getLowerVertexOfTheEdge(
      _upperVertexPosition,
      _meshOfInterest,
      options,
      BABYLON.Space.WORLD
    );

    if (virtualSketcher.util.isComponentPlanar(_componentOfInterest)) {
      _componentsOfInterest.push(_componentOfInterest);
      _componentsOfInterest.forEach((_) =>
        _removalPreviewData.upperVertexToRemoveOfEachComponent.push(
          _upperVertexPosition
        )
      );
    } else {
      _componentsOfInterest.push(
        ...getComponentsLinkedToEdge(
          {
            headPt: _upperVertexPosition,
            tailPt: lowerVertex,
          },
          _componentOfInterest
        )
      );

      _componentsOfInterest.forEach((_) =>
        _removalPreviewData.upperVertexToRemoveOfEachComponent.push(
          _upperVertexPosition
        )
      );

      const nodesAlongEdge = virtualSketcher.structuralFindNodesAlongEdge(
        _upperVertexPosition,
        lowerVertex
      );
      if (!_.isEmpty(nodesAlongEdge)) {
        nodesAlongEdge.forEach((node) => {
          node.components.forEach((c) => {
            if (c.storey !== _componentOfInterest.storey) return;

            const doesComponentGrowDownwards =
              virtualSketcher.util.doesComponentGrowDownwards(c);
            const vertexToRemove = doesComponentGrowDownwards
              ? lowerVertex
              : node.vector;
            if (!_componentsOfInterest.includes(c)) {
              _componentsOfInterest.push(c);
              _removalPreviewData.upperVertexToRemoveOfEachComponent.push(
                vertexToRemove
              );
            }
          });
        });
      }
    }

    if (_componentsOfInterest.length > 3) {
      return false;
    }

    _removalPreviewData.topFaceVerticesOfEachComponent =
      _componentsOfInterest.map((c) => {
        return getTopFaceVertices(c, _CONSTANTS.operatingSpace);
      });

    _meshesOfInterest.push(..._componentsOfInterest.map((c) => c.mesh));

    _removalPreviewData.modifiedTopFaceVerticesOfEachComponent = [];
    _removalPreviewData.gapFillerComponentIndex = 0;

    if (_componentsOfInterest.length > 2) {
      const neighbouringVerticesOfEachComponent = _componentsOfInterest.map(
        _getNeighbourVertices
      );

      neighbouringVerticesOfEachComponent.forEach(
        ([previousVertex, nextVertex], i) => {
          if (
            store.resolveEngineUtils.onSegment3D(
              previousVertex,
              _removalPreviewData.upperVertexToRemoveOfEachComponent[i],
              nextVertex
            )
          )
            return;
          else {
            _componentsEligibleForGapFilling.push(_componentsOfInterest[i]);
            if (!_componentForGapFilling) {
              _componentForGapFilling = _componentsOfInterest[i];
            }
          }
        }
      );

      _removalPreviewData.neighbouringVerticesOfEachComponent =
        neighbouringVerticesOfEachComponent;
    }

    return _showRemovalPreview();
  };

  const chooseMassForGapFilling = function () {
    if (!_upperVertexPosition) return false;

    const numberOfComponents = _componentsEligibleForGapFilling.length;
    if (numberOfComponents > 1) {
      const nextIndex =
        _removalPreviewData.gapFillerComponentIndex === numberOfComponents - 1
          ? 0
          : _removalPreviewData.gapFillerComponentIndex + 1;

      _componentForGapFilling = _componentsEligibleForGapFilling[nextIndex];
      _removalPreviewData.gapFillerComponentIndex = nextIndex;

      uiIndicatorsHandler.edgeIndicator.massDestruct();
      _showRemovalPreview();
    }

    return true;
  };

  const _showRemovalPreview = function () {
    /*
        Determine gap fill destination
         */
    if (_componentForGapFilling) {
      const index = _.findIndex(_componentsOfInterest, _componentForGapFilling);
      const gapFillerComponentTopFaceVertices =
        _removalPreviewData.topFaceVerticesOfEachComponent[index];

      _componentsOfInterest.some((c, i) => {
        if (c === _componentForGapFilling) return;

        const currentComponentNeighbours =
          _removalPreviewData.neighbouringVerticesOfEachComponent[i];
        const neighboursOfCurrentComponentNotInExpandingComponent =
          _.differenceWith(
            currentComponentNeighbours,
            gapFillerComponentTopFaceVertices,
            (v1, v2) => v1.snaptrudeFunctions().almostEquals2D(v2)
          );

        if (neighboursOfCurrentComponentNotInExpandingComponent.length === 1) {
          _gapFillDestination =
            neighboursOfCurrentComponentNotInExpandingComponent[0];
          _gapFillDestination.y = gapFillerComponentTopFaceVertices[0].y;
          return true;
        }
      });
    }

    const allModifiedVertices = [];
    const optionsForEdgeHighlight = {
      colors: [],
    };

    let doesRemovalTurnAMassIntoAPlane = false;
    /*
        Determine after edit vertices
         */
    _componentsOfInterest.forEach((c, i) => {
      const isGapFiller = c === _componentForGapFilling;

      const topFaceVertices =
        _removalPreviewData.topFaceVerticesOfEachComponent[i];
      const modifiedVertices = deepCopyObject(topFaceVertices);

      const upperVertex =
        _removalPreviewData.upperVertexToRemoveOfEachComponent[i];
      if (isGapFiller) {
        const index = _.findIndex(topFaceVertices, (v) =>
          v.almostEquals(upperVertex)
        );
        modifiedVertices[index] = _gapFillDestination;
      } else {
        _.remove(modifiedVertices, (v) => v.almostEquals(upperVertex));
      }

      _removalPreviewData.modifiedTopFaceVerticesOfEachComponent[i] =
        modifiedVertices;
      allModifiedVertices.push(modifiedVertices);
      optionsForEdgeHighlight.colors.push(
        pseudoEdgeRenderer.CONSTANTS.EDGE_COLOR_POST_SNAP
      );

      if (c.mesh.isAnInstance) {
        const allInstanceComponents = c.mesh.sourceMesh.instances.map((i) =>
          i.getSnaptrudeDS()
        );
        _.remove(allInstanceComponents, c);

        allInstanceComponents.forEach((instanceComponent) => {
          const verticesForThisInstance =
            getCorrespondingGlobalPointOfOtherInstance(
              modifiedVertices,
              c,
              instanceComponent
            );

          allModifiedVertices.unshift(verticesForThisInstance);
          optionsForEdgeHighlight.colors.unshift(
            pseudoEdgeRenderer.CONSTANTS.EDGE_COLOR_COPIES
          );
          // insert at the start of the array so that selected components get highlighting preference
        });
      }

      if (!doesRemovalTurnAMassIntoAPlane) 
        doesRemovalTurnAMassIntoAPlane = areaOfPolygonV3(modifiedVertices) < 2;
    });

    /*
        Display new edges
         */
    uiIndicatorsHandler.edgeIndicator.massConstruct(
      allModifiedVertices,
      [],
      optionsForEdgeHighlight
    );

    return !doesRemovalTurnAMassIntoAPlane;
  };

  const _predicate = (mesh) => {
    return ['mass', 'floor', 'roof'].includes(mesh.type.toLowerCase())
            && !isMeshCurved(mesh);
  };


  function _highlightElementToRemove() {
    let aMeshWasClickedOn = !!_meshClickedOnId; //damn. Converts truthy, falsy to true, false

    return new Promise((resolve, reject) => {
      let resolved = false;

      let showVertexPredicate;

      if (aMeshWasClickedOn) {
        showVertexPredicate = (mesh) => mesh.uniqueId === _meshClickedOnId;
      } else {
        showVertexPredicate = _predicate;
      }

      let showVertexPick = scenePickController.compoundPick(showVertexPredicate);

      resolved = _displayVertexAndShowPreview(
        showVertexPick,
        aMeshWasClickedOn
      );

      if (resolved) resolve();
      else reject();
    });
  }

  function _showAllVertices() {
    return new Promise((resolve, reject) => {
      let resolved = false;

      let showVerticesPredicate = _predicate;

      disposeAllVertexIndicators();
      _faceVertices = null;

      let showVerticesPick = scenePickController.compoundPick(showVerticesPredicate);

      if (showVerticesPick.hit) {
        _faceVertices = getFaceVerticesFromFacetID(
          showVerticesPick.faceId,
          showVerticesPick.pickedMesh,
          _CONSTANTS.operatingSpace
        );
        if (_faceVertices) {
          _meshClickedOnId = showVerticesPick.pickedMesh.uniqueId;
          indicateAllVertices(_faceVertices, showVerticesPick.pickedMesh);
          resolve();
          resolved = true;
        }
      } else {
        _meshClickedOnId = null;
      }

      if (!resolved) reject();
    });
  }

  const _getNeighbourVertices = function (component, i) {
    const topFaceVertices = getTopFaceVertices(component);
    const upperVertex =
      _removalPreviewData.upperVertexToRemoveOfEachComponent[i];

    const currentVertexIndex = _.findIndex(topFaceVertices, (v) =>
      v.almostEquals(upperVertex)
    );
    const length = topFaceVertices.length;
    const nextIndex =
      currentVertexIndex === length - 1 ? 0 : currentVertexIndex + 1;
    const previousIndex =
      currentVertexIndex === 0 ? length - 1 : currentVertexIndex - 1;

    const nextVertex = topFaceVertices[nextIndex];
    const previousVertex = topFaceVertices[previousIndex];

    return [previousVertex, nextVertex];
  };

  const _moveVerticesAround = function (component, destinationV3) {
    const i = _componentsOfInterest.indexOf(component);
    const upperVertex =
      _removalPreviewData.upperVertexToRemoveOfEachComponent[i];

    const vertexObject = getVertexObjectFromPositionV3(
      component.mesh,
      upperVertex,
      _CONSTANTS.operatingSpace
    );
    const vertexIndex = vertexObject.getIndex();

    const faceId = geometryUpdater.util.getTopFaceId(component);
    const lowerVertex = getLowerVertexOfTheEdge(
      upperVertex,
      component.mesh,
      { faceId },
      _CONSTANTS.operatingSpace
    );

    const lowerVertexObject = getVertexObjectFromPositionV3(
      component.mesh,
      lowerVertex,
      _CONSTANTS.operatingSpace
    );
    const lowerVertexIndex = lowerVertexObject.getIndex();

    const lowerVertexDestination = destinationV3.clone();
    lowerVertexDestination.y = lowerVertex.y;

    component.brep.positions[vertexIndex] = convertGlobalVector3ToLocal(
      destinationV3,
      component.mesh
    ).asArray();
    component.brep.positions[lowerVertexIndex] = convertGlobalVector3ToLocal(
      lowerVertexDestination,
      component.mesh
    ).asArray();

    component.mesh.BrepToMesh();
  };

  function _removeElement() {
    return new Promise((resolve, reject) => {
      let resolved = false;

      if (_upperVertexPosition) {
        _commandData =
          commandUtils.geometryChangeOperations.getCommandData(
            _meshesOfInterest
          );
        const propertyChangeCommandData = handleRoofPropertyChangeCommand(
          _componentsOfInterest
        );

        if (_componentForGapFilling)
          _moveVerticesAround(_componentForGapFilling, _gapFillDestination);

        const optionsForRemoval = {};
        optionsForRemoval.operatingSpace = _CONSTANTS.operatingSpace;
        optionsForRemoval.forcedRemoval = true;

        let removed = false;
        
        let faceIdFetchFunction;
        
        const topFaceIdOfInterest = geometryUpdater.util.getTopFaceId(_componentOfInterest);
        const topFacets = _componentOfInterest.faceFacetMapping[topFaceIdOfInterest];
        if (topFacets.includes(_upperFacetId)){
          // top face has been picked initially
          // normal case
          faceIdFetchFunction = geometryUpdater.util.getTopFaceId;
        }
        else {
          // bottom face has been picked initially
          // happens when camera is very very zoomed in and is "below" the top face
          faceIdFetchFunction = geometryUpdater.util.getBottomFaceId;
        }
        
        _componentsOfInterest.forEach((c, i) => {
          if (c === _componentForGapFilling) return;

          const upperVertex =
            _removalPreviewData.upperVertexToRemoveOfEachComponent[i];
          optionsForRemoval.faceId = faceIdFetchFunction(c);
          removed =
            removeVertexFromComponent(upperVertex, c, optionsForRemoval) ||
            removed;
        });

        disposeAllVertexIndicators();

        let faceVerticesBefore, faceVerticesAfter;
        if (_faceVertices) {
          faceVerticesBefore = _faceVertices.map(v => v.clone());
          _.remove(_faceVertices, (vertex) => {
            return _upperVertexPosition.equals(vertex);
          });
          faceVerticesAfter = jQuery.extend([], _faceVertices);

          indicateAllVertices(_faceVertices, _meshOfInterest);
        }

        if (removed) {
          _commandData = commandUtils.geometryChangeOperations.getCommandData(
            _meshesOfInterest,
            _commandData
          );

          let callBackOptions = {};

          if (faceVerticesBefore && faceVerticesAfter) {
            _commandData.indicatorsLinkedToMesh = _meshOfInterest.uniqueId;
            _commandData.faceVerticesBefore = faceVerticesBefore;
            _commandData.faceVerticesAfter = faceVerticesAfter;

            callBackOptions = {
              postExecuteCallback: _indicatorExecute,
              postUnExecuteCallback: _indicatorUnExecute,
            };
          }

          const removeCommand =
            commandUtils.geometryChangeOperations.getCommand(
              commandUtils.CONSTANTS.removeElementsOperation,
              _commandData,
              callBackOptions
            );

          const integrationGeometryChangeCommand =
            virtualSketcher.updateWithGeometryEdit(_componentsOfInterest, true);

          const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
            [removeCommand, integrationGeometryChangeCommand]);

          const propertyChangeCommand = handleRoofPropertyChangeCommand(
            _componentsOfInterest,
            propertyChangeCommandData
          );

          const commands = _.compact([
            commonGeometryChangeCommand,
            propertyChangeCommand,
          ]);
          const yets = commands.map((_) => false);

          CommandManager.execute(commands, yets);

          resolve();
          resolved = true;
        }
      }

      if (!resolved) reject();
    });
  }

  function _indicatorExecute() {
    let data = this.data;
    _updateVertexIndicators(
      data.faceVerticesAfter,
      data.indicatorsLinkedToMesh
    );
  }

  function _indicatorUnExecute() {
    let data = this.data;
    _updateVertexIndicators(
      data.faceVerticesBefore,
      data.indicatorsLinkedToMesh
    );
  }

  function _updateVertexIndicators(vertices, indicatorsLinkedToMesh) {
    if (!uiIndicatorsHandler.vertexIndicator.multiIndicators.isActive()) return; //indicators not present in store.scene
    if (
      uiIndicatorsHandler.vertexIndicator.multiIndicators.getActiveMesh()
        .uniqueId !== indicatorsLinkedToMesh
    )
      return; //operation going on on a different mesh

    disposeAllVertexIndicators();
    indicateAllVertices(
      vertices,
      store.scene.getMeshByUniqueID(indicatorsLinkedToMesh)
    );
    _faceVertices = vertices;
  }

  function _cleanUp() {
    return new Promise((resolve) => {
      disposeHighlightedVertex();
      disposeHightlightedEdge();
      uiIndicatorsHandler.edgeIndicator.massDestruct();

      _meshOfInterest = null;
      _edgeIndex = NaN;
      _upperVertexPosition = null;
      _upperFacetId = null;

      _componentsOfInterest.length = 0;
      _meshesOfInterest.length = 0;
      _componentsEligibleForGapFilling.length = 0;
      _componentOfInterest = null;

      _componentForGapFilling = null;
      _gapFillDestination = null;

      _removalPreviewData = {};

      resolve();
    });
  }

  let cleanUp = function () {
    _cleanUp();
    STATE_MACHINE.reset();
    disposeAllVertexIndicators();
    _meshClickedOnId = null;

    _faceVertices = null;
  };

  return {
    init,
    onPointerDown,
    onPointerMove,
    cleanUp,
    chooseMassForGapFilling,
  };
})();

/*
Code for edge removal in 3D

else if (false) {
    let brep = _meshOfInterest.getSnaptrudeDS().brep;
    let edge = snapOnEdge(pickInfo);
    if (edge) {
        drawEdge(edge, _meshOfInterest);

        let faceID = getFaceIdFromFacet(pickInfo.faceId, _meshOfInterest);
        let face = brep.getFaces()[faceID];
        let point = edge.pt;

        let HEs = snapmda.FaceHalfEdges(face);

        HEs.some(he => {
            let edge = he.getEdge();
            let Vs = getVerticesFromEdgeObject(_meshOfInterest, edge, _CONSTANTS.operatingSpace);
            // console.log(Vs);

            let edgeEndPoint1 = [];
            let edgeEndPoint2 = [];

            Vs[0].toArray(edgeEndPoint1);
            Vs[1].toArray(edgeEndPoint2);

            let util = new ResolveEngineUtils();

            if (util.getOrientation3D(edgeEndPoint1, point.asArray(), edgeEndPoint2) === 0) {
                _edgeIndex = edge.getIndex();
                return true;
            }
        })
    }
}



else if (!isNaN(_edgeIndex)){ //Not being used currently

    // let removed = removeEdgeAndReconstruct(_meshOfInterest, _edgeIndex);
    // if (removed) updateModifications();

    let brep = _meshOfInterest.getSnaptrudeDS().brep;
    snapmda.DeleteEdgeOperator(brep, _edgeIndex);
    _meshOfInterest.BrepToMesh();
    _cleanUp();
}
 */
export { removeElementsOperator };
