import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";
import LoadingScreen from "../../../components/Loading/LoadingScreen";
import { routes } from "../../../routes/constants";
import { selectTeam } from "../../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import AllProjects from "./AllProjects";
import { TEAM_ROLES } from "./constants";
import Libraries from "./Libraries";
import Preferences from "./Preferences";

function TeamDashboard({ projectsCount }) {
    const [selectingTeam, setSelectingTeam] = useState(true);
    const { path } = useRouteMatch();
    const { teamlist, selectedTeam } = useSelector(state => {
        return {
            teamlist: state.teams.teamlist,
            selectedTeam: state.teams.teamlist.find(team => team.id === state.teams.selectedTeam?.id),
        }
    });
    const { teamId } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        setSelectingTeam(true);
        const teamIdInt = parseInt(teamId);
        dispatch(selectTeam(teamIdInt));
        setSelectingTeam(false);
    }, [teamId]);
    
    const permissions = useMemo(() => {
        if(selectedTeam){
            return selectedTeam.roleBasedPermissions[selectedTeam.role]
        }
        return {}
    }, [selectedTeam])

    if (selectingTeam) return <LoadingScreen style={{ height: "100vh" }} />
    if (!selectedTeam) return <Redirect path={routes.dashboard} />
    if (selectedTeam.id !== parseInt(teamId)) return <LoadingScreen style={{ height: "100vh" }} />
    return (
        <React.Fragment>
                <Switch>
                    <Route path={`${path}/libraries`}>
                        {
                            permissions["view_library"] ?
                            <Libraries selectedTeam={selectedTeam} teamlist={teamlist} />
                            :
                            <Redirect to={selectedTeam.route} />
                        }
                    </Route>
                    <Route path={`${path}/preferences`}>
                        {
                            selectedTeam.role === TEAM_ROLES.ADMIN ?
                                <Preferences selectedTeam={selectedTeam} teamlist={teamlist} />
                                :
                                <Redirect to={selectedTeam.route} />
                        }
                    </Route>
                    <Route path={`${path}/folder/:folderID`}>
                        <AllProjects selectedTeam={selectedTeam} teamlist={teamlist} projectsCount={projectsCount} />
                    </Route>
                    <Route exact path={path}>
                        <AllProjects selectedTeam={selectedTeam} teamlist={teamlist} projectsCount={projectsCount} />
                    </Route>
                </Switch>
        </React.Fragment>
    )
}

export default TeamDashboard;