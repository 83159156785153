import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { meshObjectMapping } from "./mapping.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { AutoSave } from "../socket/autoSave.js";
import { deepCopyObject } from "../extrafunc.js";
import BABYLON from "../babylonDS.module";
/*jshint esversion: 6 */
/**
 *
 * {Layer Data Structure}
 *
 * @param name
 * @param level
 * @param structure_id
 * @param layerType
 * @constructor
 */
var Layer = function (name, structure_id, layerType = "default") {
  this.id = "ly_" + Math.random().toString(36).substr(2, 9);
  this.name = name;
  this.structure_id = structure_id;
  this.type = "layer";
  this.layerType = layerType;
  this.sketches = [];
  this.floorplans = [];
  this.pdfs = [];
  this.storey = 1;
  this.hidden = false;
  this.terrain = [];
  this.heightMapToggle = false;
  this.isLocked = false;
  this.neighborhood = {mesh:[], bounds: null, center : null, zoom: null};
};

Layer.prototype.addFloorPlan = function (floorPlan) {
  meshObjectMapping.addMapping(floorPlan.mesh, floorPlan);
  this.floorplans.push(floorPlan);
};

Layer.prototype.addPdf = function (pdf){
  meshObjectMapping.addMapping(pdf.mesh, pdf);
  this.pdfs.push(pdf);
}

Layer.prototype.addTerrain = function (mesh, parameters) {
  this.terrain.push({ parameters: parameters });
};

Layer.prototype.addNeighborhood = function (mesh) {
  this.neighborhood.mesh.push(mesh);
};

Layer.prototype.deleteAllSketches = function () {
  if (this.layerType !== "cad") {
    this.sketches.forEach(function (sketch) {
      sketch.mesh.dispose();
    });
  } else {
    this.mesh.dispose();
  }
  this.sketches.length = 0;
};

Layer.prototype.hideFloorPlan = function () {
  if (this.layerType.toLowerCase() === "image") {
    if (this.floorplans.length > 0) {
      this.floorplans[0].deactivate();
    }
  }
};

Layer.prototype.hide = function () {
  this.hidden = true;
  if (this.layerType.toLowerCase() === "image") {
    if (this.floorplans.length > 0) {
      this.floorplans[0].deactivate();
    }
  }
  else if( this.layerType.toLowerCase().includes("pdf") ){
    if ( !_.isEmpty(this.pdfs)) {
      this.pdfs.forEach(pdf => {
        pdf.deactivate();
      })
    }
  }
  else if (this.layerType.toLowerCase() === "cad") {
    for (let i = 0; i < Object.keys(this.sketches).length; i++) {
      this.mesh.isVisible = false;
      //this.sketches[i].mesh.forEach(m => { m.isVisible = false; });
      // this.sketches[i].mesh.forEach(m => { m.visibility = 0; });
    }
  } else if (this.layerType.toLowerCase() === "buildings") {
    for (let i = 0; i < this.neighborhood.mesh.length; i++) {
      let mesh = store.scene.getMeshByUniqueID(this.neighborhood.mesh[i]);
      if (mesh) {
        mesh.isVisible = false;
      }
    }
  } else {
    for (let i = 0; i < Object.keys(this.sketches).length; i++) {
      this.sketches[i].mesh.isVisible = false;
      this.sketches[i].mesh.visibility = 0;
    }
  }
  if (this.terrain.length > 0) {
    this.terrain.forEach((t) => {
      let mesh = store.scene.getMeshByUniqueID(t.parameters.uniqueId);
      if (mesh) {
        mesh.isVisible = false;
      }
    });
  }
  this.hidden = true;
};

Layer.prototype.show = function () {
  if (store.$scope.isTwoDimension) {
    this.hidden = false;
    if (this.layerType.toLowerCase() === "image") {
      if (this.floorplans.length > 0) {
        if (store.activeLayer.storey === this.floorplans[0].storey) {
          this.floorplans[0].activate();
        }
      }
    }
    else if (this.layerType.toLowerCase() === "cad") {
      for (let i = 0; i < Object.keys(this.sketches).length; i++) {
        if (this.mesh.storey === store.activeLayer.storey) {
          this.mesh.isVisible = true;
          this.mesh.visibility = 1;
          this.mesh.color = new BABYLON.Color3(0, 0, 0);
        } else {
          this.mesh.isVisible = true;
          this.mesh.visibility = 0.4;
          new BABYLON.Color3(0.8, 0.8, 0.8);
        }
        // this.sketches[i].mesh.forEach(m => {
        //     m.isVisible = true;
        // });
      }
    } else if (this.layerType.toLowerCase() === "buildings") {
      for (let i = 0; i < this.neighborhood.mesh.length; i++) {
        let mesh = store.scene.getMeshByUniqueID(this.neighborhood.mesh[i]);
        if (mesh) {
          mesh.isVisible = true;
          mesh.edgesWidth = store.$scope.isTwoDimension ? 7 : 70;
        }
      }
    } else {
      for (let i = 0; i < Object.keys(this.sketches).length; i++) {
        if (this.sketches[i].mesh.storey === store.activeLayer.storey) {
          this.sketches[i].mesh.isVisible = true;
          this.sketches[i].mesh.visibility = 1;
        } else {
          this.sketches[i].mesh.isVisible = true;
          this.sketches[i].mesh.visibility = 0.4;
        }
      }
    }
  }

  if( this.layerType.toLowerCase().includes("pdf") ){
    if (this.pdfs.length > 0) {
      this.pdfs[0].activate();
    }
  }
  if (this.terrain.length > 0) {
    this.terrain.forEach((t) => {
      let mesh = store.scene.getMeshByUniqueID(t.parameters.uniqueId);
      if (mesh) {
        mesh.isVisible = true;
      }
    });
  } else if (this.layerType.toLowerCase() === "buildings") {
    for (let i = 0; i < this.neighborhood.mesh.length; i++) {
      let mesh = store.scene.getMeshByUniqueID(this.neighborhood.mesh[i]);
      if (mesh) {
        mesh.isVisible = true;
        mesh.edgesWidth = store.$scope.isTwoDimension ? 7 : 70;
      }
    }
  }

  this.hidden = false;
};

Layer.prototype.enableOrDisableHeightMapToggle = function () {
  this.heightMapToggle = !this.heightMapToggle;
};

Layer.prototype.serialize = function () {
  let serializedData = {};

  let entries = Object.entries(this);
  entries.forEach(function (entry) {
    if (
      !_.isFunction(entry[1]) &&
      entry[0] !== "sketches" &&
      entry[0] !== "floorplans" &&
      entry[0] !== "pdfs" &&
      entry[0] !== "mesh"
    ) {
      serializedData[entry[0]] = entry[1];
    }
  });

  serializedData.sketches = [];
  this.sketches.forEach(function (sketch) {
    let serializedSketch = {};
    serializedSketch.points = sketch.points;
    serializedSketch.color = sketch.color;
    serializedSketch.thickness = sketch.thickness;
    if (!_.isArray(sketch.mesh)) {
      serializedSketch.id = sketch.id;
      serializedSketch.scaling = sketch.mesh.scaling.asArray();
      serializedSketch.position = sketch.mesh.position.asArray();
    }
    serializedData.sketches.push(serializedSketch);
  });

  serializedData.floorplans = [];
  this.floorplans.forEach(function (floorPlan) {
    serializedData.floorplans.push(floorPlan.serialize());
  });

  serializedData.pdfs = [];
  this.pdfs.forEach(function (pdf) {
    serializedData.pdfs.push(pdf.serialize());
  });

  return serializedData;
};

function LayerCollection() {
  var layerCollection = {};
  var count = 0;

  const saveLayerInBackend = function (newLevel) {
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = newLevel.id;
    saveData.data.identifier = {
      structure_id: newLevel.structure_id,
      floorkey: store.floorkey,
      storey: newLevel.storey.toString(), //CHECK AUTO
      id: newLevel.id,
    };

    saveData.data.afterOperationData = deepCopyObject(newLevel);

    saveData.data.saveType = "addLayer";

    AutoSave.directPublish(saveData);
  };

  return {
    addLayer: function (name, structure_id, layerType, storey, options = {}) {
      if(options.autoSave === undefined){
        options.autoSave = true;
      }
      let nameFlag = false;
      for (let layerId in layerCollection) {
        let layer = layerCollection[layerId];
        if (
          layer.name.toLowerCase() === name.toLowerCase() &&
          parseInt(layer.storey) === parseInt(storey)
        ) {
          nameFlag = true;
          break;
        }
      }
      if (!nameFlag) {
        // Make sure that there aren't any missing indices
        let layers = Object.values(layerCollection);
        layerCollection = {};
        let i = 0;
        for (let layer of layers) {
          layerCollection[i++] = layer;
        }
        layerCollection[count] = new Layer(name, structure_id, layerType);
        layerCollection[count].storey = parseInt(storey);
        let index = count;
        count++;

        if(options && options.layerId){
          layerCollection[index].id = options.layerId;
        }

        if (options && options.autoSave) {
          saveLayerInBackend(layerCollection[index]);
        }

        return layerCollection[index];
      } else {
        return this.getLayerByName(name, storey);
      }
    },
    getAllLayers: function () {
      return layerCollection;
    },
    getLayerByName: function (name, storey) {
      for (let layerId in layerCollection) {
        let layer = layerCollection[layerId];
        if (
          layer.name.toLowerCase() === name.toLowerCase() &&
          parseInt(layer.storey) === parseInt(storey)
        )
          return layer;
      }
      return null;
    },
    getLayerBylayerId: function (id){
      for(let layerId in layerCollection){
        let layer = layerCollection[layerId];
        if(layer.id === id){
          return layer;
        }
      }
      return null;
    },
    getCountOfLayerByType: function (type, storey) {
      let count = 0;
      for (let layerId in layerCollection) {
        let layer = layerCollection[layerId];
        if (
          layer.layerType.toLowerCase() === type.toLowerCase() &&
          parseInt(layer.storey) === parseInt(storey)
        )
          count++;
      }
      return count;
    },
    getAllLayersByType: function (type, storey){
      let _allLayers = [];
      for (let layerId in layerCollection) {
        let layer = layerCollection[layerId];
        if (layer.layerType.toLowerCase() === type.toLowerCase() && parseInt(layer.storey) === parseInt(storey)){
          _allLayers.push(layer);
        }
      }
      return _allLayers;
    },
    getAllLayersInStorey: function (storey) {
      let layerArray = [];
      for (let layerId in layerCollection) {
        let layer = layerCollection[layerId];
        if (parseInt(layer.storey) === parseInt(storey)) {
          layerArray.push(layer);
        }
      }
      return layerArray;
    },
    deleteAllLayers: function () {
      for (let layer_id in layerCollection) {
        delete layerCollection[layer_id];
      }
      count = 0;
    },
    deleteLayer: function (layer_id) {
      for (let layer in layerCollection) {
        if (layerCollection[layer].id === layer_id) {
          delete layerCollection[layer];
          count--;
        }
      }
    },
    getObjectByUniqueId: function (uniqueId) {
      for (let layer_id in layerCollection) {
        for (let i = 0; i < layerCollection[layer_id].floorplans.length; i++) {
          if (
            layerCollection[layer_id].floorplans[i].mesh.uniqueId === uniqueId
          )
            return layerCollection[layer_id].floorplans[i];
        }
      }
      return null;
    },
    getTerrainObject: function (uniqueId) {
      for (let layer_id in layerCollection) {
        for (let i = 0; i < layerCollection[layer_id].terrain.length; i++) {
          if (
            layerCollection[layer_id].terrain[i].parameters.uniqueId ===
            uniqueId
          )
            return layerCollection[layer_id].terrain[i];
        }
      }
      return null;
    },
  };
}
export { Layer, LayerCollection };
