import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    self: {
        color: 'ff0000',
        user: null,
    },
    others: [],
};

const filterSelfUser = (allUsers, selfUser) => {
    return allUsers.filter(user => user.user.id != selfUser.user.id);  
};

export const activeUsersSlice = createSlice({
    name: 'activeUsers',
    initialState,
    reducers: {
        resetActiveUsers: state => initialState,
        initSelf: (state, action) => {
            const { self } = action.payload;
            state.self = self;
        },
        updateActiveUsers: (state, action) => {
            const { allActiveUsers } = action.payload;
            let realOthers = filterSelfUser(allActiveUsers, state.self);
            realOthers = _.uniqBy(realOthers, (e) => {
                return e.user.id
            })
            state.others = realOthers;
        }
    },
});

export const { resetActiveUsers, initSelf, updateActiveUsers } = activeUsersSlice.actions;

export default activeUsersSlice.reducer;