import _ from "lodash";
import {store} from "../utilityFunctions/Store.js";
import {StoreyMutation} from "../storeyEngine/storeyMutations.js";
import {
  generateBrepForComponents,
  getBottomFaceVertices,
  honeyIShrunkTheMass,
  sanitizeVertices,
} from "../../libs/brepOperations.js";
import {virtualSketcher} from "../sketchMassBIMIntegration/virtualSketcher.js";
import {
  getEmptyFunction,
  hideToast,
  isRoomOfType,
  onSolid,
  removeComponentFromStructure,
  showToast,
  updateButtonsOnTop,
} from "../extrafunc.js";
import {setLayerTransperancy} from "../../libs/sceneFuncs.js";
import {updateRoofAccordion} from "../../libs/roofVisibilityFuncs.js";
import {ScopeUtils} from "../../libs/scopeFunctions.js";
import {autoSaveConfig} from "../socket/autoSaveConfig.js";
import {Mass} from "../snaptrudeDS/mass.ds.js";
import {StructureCollection} from "../snaptrudeDS/structure.ds.js";
import {commandUtils} from "../commandManager/CommandUtils.js";
import {CommandManager} from "../commandManager/CommandManager.js";
import {straightwall} from "../factoryTypes/wall.types.js";
import {massDissector} from "./massDissector.js";
import {csgOperator} from "../meshoperations/csgOperation.js";
import {createCustomMesh} from "../../libs/massModeling.js";
import {plainFloorParameters} from "../factoryTypes/floor.types.js";
import {dimensionsTuner} from "../sketchMassBIMIntegration/dimensionsTuner.js";
import {signedArea} from "../../libs/polyFuncs.js";
import {is2D} from "../../libs/twoDimension";
import BABYLON from "../babylonDS.module";
import {diagnostics} from "../diagnoses/diagnostics";

/**
 * Create Building flow-
 *
 * Traverse the structure and process masses one by one.
 * In each iteration, gather points for walls (don't build), build floors and slabs
 *
 * After this, combine the slabs. While response from CGAL is awaited,
 * proceed to solve wall junctions and the build all the walls at once.
 *
 * Execute the commands
 *
 */

const createBuildingEngine = (function () {
  let previousCreateBuildingComponents = [];

  let concernedMasses = {
    allMasses: [],
    siteMasses: [],
    waterBodyMasses: [],
  };

  const CONSTANTS = {
    progressMessage: "Create Building in progress...",
  };

  let componentsCreated = [];
  let componentsToBeDeleted = [];
  let plinthDeleted = [];
  let massesNotDeleted = [];
  
  let miscellaneousCommands = [];

  let initiated = false;

  let startTime;
  let endTime;

  let isCreateRoom = null;

  let massWallPointsMapping = new Map();

  const _assignWallThicknessPropertiesToMass = function (mass) {
    if (mass.massType === "Room") {
      mass.mesh.wallThicknessInt =
        store.projectProperties.properties.wallThicknessPropertyInt.getValue();
      mass.mesh.wallThicknessExt =
        store.projectProperties.properties.wallThicknessPropertyExt.getValue();
    }
  };

  const _defaultPostProcessor = function (componentsCreated) {
    /*const overhang = userSetBIMPropertiesHandler.getRoofOverhang();
    let _checkBottomMostSlab = function(slabMesh){
        slabMesh.position.y -= 0.1;
        slabMesh.computeWorldMatrix(true);
        let intersection = scene.meshes.some(mesh => {
            if((mesh.storey === -1) && (mesh.type.toLowerCase() == "wall")){
                return slabMesh.intersectsMesh(mesh);
            }
        });
        slabMesh.position.y += 0.1;
        return !intersection;
    };*/

    componentsCreated.forEach((component) => {
      try {
        let mesh = component.mesh,
          meshType = mesh.type.toLowerCase();

        if (meshType === "roof") {
          StoreyMutation.assignStorey(component);
          if (!createBuildingEngine.isCreateRoom) component.setSlabType();
          if (is2D()) store.roofsVisibleIn3D.push(mesh);
          /*if (mesh.storey === 1 && _checkBottomMostSlab(mesh)){
            component.slabType = "Plinth";
            let oldSlabThickness = DisplayOperation.getOriginalDimension(objectPropertiesView.getMeshDimensions(mesh).height);
            DisplayOperation.updateDimensionScale(projectProperties.properties.plinthHeightProperty.getValue(), "height", oldSlabThickness, mesh, {returnCommand: true});
          }
          else if(mesh.storey < 1){
            component.slabType = "Basement Slab";
            component.overhang(0);
          }
          else {
            if (component.isOverhangDisabled()){
              component.overhang(0);
            }
            else {
              component.overhang(overhang);
            }
          }*/
        } else if (meshType === "wall") {
          component.properties.wallThicknessChanged = false;
        } else if (meshType === "floor") {
          if (isRoomOfType(mesh, "deck")){ // mesh is floor
            const materialChangeCommand = component.updateDefaultMaterial();
            if (materialChangeCommand) miscellaneousCommands.push(materialChangeCommand);
          }
        }

        try {
          // fails when slabs from cgal aren't proper
          generateBrepForComponents(mesh);
          virtualSketcher.addWithoutGeometryEdit(component);
        } catch (e) {
          console.warn(e);
          console.warn("Brep generation or addition to graph failed");
        }

        onSolid(mesh);
        setLayerTransperancy(mesh);
        // component.createdByCB = true;
        component.properties.createdByCB = true;
      } catch (e) {
        console.warn("Storey assignment failed for the mesh");
        console.warn(e);
      }
    });

    concernedMasses.allMasses.forEach((m) => {
      if(m?.mesh?.name == 'terrain' || m?.mesh?.name == 'Building') return
      m.show()
    });

    concernedMasses.siteMasses.forEach((m) => {
      virtualSketcher.removeWithoutGeometryEdit(m);
    });

    updateRoofAccordion(true);
  };

  const _hideButtonsAndShowToast = function () {
    document.getElementById("canvas").style.zIndex = 15;
    ScopeUtils.hideToolBar();
    ScopeUtils.hideSection();

    showToast(
      CONSTANTS.progressMessage,
      0,
      autoSaveConfig.CONSTANTS.toastSuccess
    );
  };

  const _showButtonsAndHideToast = function () {
    document.getElementById("canvas").style.zIndex = 1;
    ScopeUtils.showToolBar();
    ScopeUtils.showSection();

    hideToast();
  };

  const init = function (stack = []) {
    function _removePlinthMasses(masses) {
      // Removing Plinths from the list of masses to be converted to Rooms
      _.remove(masses, (mass) => {
        if (mass.massType === "Plinth") {
          plinthDeleted.push(mass);
          // removeComponent(mass);
          // removeComponentFromStructure(mass);
          // mass.mesh.dispose();
          return true;
        }
      });

      // To create a command to regenerate the Plinth for Undo in case of Create Rooom in Hide/Isolate
      masses.forEach((mass) => {
        if (mass.mesh && mass.mesh.childrenComp) {
          mass.mesh.childrenComp.forEach((child) => {
            if (Mass.isPlinth(child))
              plinthDeleted.push(child.getSnaptrudeDS());
          });
        }
      });

      plinthDeleted = _.uniqBy(plinthDeleted, "mesh.uniqueId");
    }

    cleanUp();

    startTime = performance.now();

    let getMassesFromStructure = true;

    stack = _.filter(stack, (m) => m.type.toLowerCase() === "mass");
    
    if (!_.isEmpty(stack)) {
      concernedMasses.allMasses = stack.map((m) => m.getSnaptrudeDS());
      getMassesFromStructure = false;
    }

    let structureCollection = StructureCollection.getInstance();
    let structures = structureCollection.getStructures();

    for (let structure in structures) {
      let levels = structures[structure].getAllLevels();

      for (let id in levels) {
        let level = levels[id];
        let masses = level.getMasses();
        previousCreateBuildingComponents.push(
          ...level.getWalls(),
          ...level.getRoofs(),
          ...level.getFloors()
        );

        if (getMassesFromStructure) concernedMasses.allMasses.push(...masses);
      }
    }

    _removePlinthMasses(concernedMasses.allMasses);

    // Remove masses like Site whose create building has been done before but were not removed
    _.remove(concernedMasses.allMasses, (mass) => mass.createBuildingDone);

    if (concernedMasses.allMasses.length === 0) return;

    concernedMasses.allMasses.forEach((m) => {
      _assignWallThicknessPropertiesToMass(m);
    });

    concernedMasses.siteMasses = concernedMasses.allMasses.filter((m) =>
      virtualSketcher.util.isComponentPlanar(m)
    );
    concernedMasses.waterBodyMasses = concernedMasses.allMasses.filter((m) =>
      virtualSketcher.util.doesComponentGrowDownwards(m)
    );

    concernedMasses.siteMasses.forEach((m) => {
      virtualSketcher.addWithoutGeometryEdit(m, true);
    });

    if (!isCreateRoom && dimensionsTuner.isTunerActive())
      massDissector.findMasterNodes();
    initiated = true;
  };

  const generateComponents = async function (generator) {
    if (!initiated) return false;

    await generator(concernedMasses.allMasses);
  };

  const processCreatedComponents = function (processor) {
    if (!initiated) return false;

    if (processor) {
      processor(componentsCreated);
    } else {
      _defaultPostProcessor(componentsCreated);
    }
  };

  const prepareLinkedListCommandData = function () {
    let structureId = store.activeLayer.structure_id;
    let linkedListCluster = StructureCollection.getInstance()
      .getStructures()
      [structureId].getLinkedListCluster();
    let linkedListData = {
      structure_id: structureId,
    };
    let dllIds = [];
    componentsToBeDeleted.forEach(function (component) {
      dllIds.push(component.linkedListId);
    });
    dllIds = _.uniq(dllIds);
    dllIds.forEach(function (dllId) {
      let dLL = linkedListCluster.getLinkedList(dllId);
      if (dLL) {
        linkedListData[dLL.id] = {
          prevData: dLL.serialize()[dLL.id],
          newData: dLL.serialize()[dLL.id],
        };
      }
    });

    return linkedListData;
  };

  const executeCommands = function () {
    if (!initiated) return false;

    concernedMasses.allMasses.forEach((mass) => delete mass.floors);

    let options = {
      preserveChildren: true,
      postExecuteCallback: () => updateButtonsOnTop(true),
      postUnExecuteCallback: () => updateButtonsOnTop(false),
    };

    let dllCommandData = prepareLinkedListCommandData();
    let dllCommand = commandUtils.linkedListOperations.getCommand(
      "updateLinkedListCluster",
      dllCommandData
    );

    componentsToBeDeleted.push(...plinthDeleted);

    let massDeletionCommandData =
      commandUtils.deletionOperations.getCommandData(
        componentsToBeDeleted.map((c) => c.mesh),
        null,
        options
      );

    let massDeletionCommand = commandUtils.deletionOperations.getCommand(
      "massDeletion",
      massDeletionCommandData,
      options
    );

    let createBuildingCommandData =
      commandUtils.creationOperations.getCommandData(
        componentsCreated.map((c) => c.mesh)
      );
    let createBuildingCommand = commandUtils.creationOperations.getCommand(
      "createBuilding",
      createBuildingCommandData
    );

    let allCommands = [
      createBuildingCommand,
      massDeletionCommand,
      dllCommand,
      ...miscellaneousCommands,
    ];
    let yets = [false, true, true, ...miscellaneousCommands.map(() => false)];

    if (massesNotDeleted.length > 0) {
      
      /*const massesToBeShrunk = massesNotDeleted.filter((mass) =>
        isRoomOfType(mass.mesh, "water body"));*/
      
      const massesToBeShrunk = _.uniqWith(massesNotDeleted,(c1, c2) => {
        if (c1.mesh.isAnInstance && c2.mesh.isAnInstance){
          return c1.mesh.sourceMesh === c2.mesh.sourceMesh;
        }
        else {
          return false;
        }
      });
      
      
      const shrinkCommands = [];
      const optionsForShrink = {
        generateCommand: true,
        useGraph: true,
      };
      
      massesToBeShrunk.forEach(mass => {
        try {
          const command = honeyIShrunkTheMass(mass, null, optionsForShrink);
          if (command) shrinkCommands.push(command);
        } catch (e) {
          console.error(e);
        }
      });
      
      if (!_.isEmpty(shrinkCommands)){
        
        // Sometimes shrinking creates additional zero area faces (duplicate edges)
        // those will be removed in updateWithGeometryEdit, so replacing updateWithoutGeometryEdit with this
        const geometryUpdateCommand = virtualSketcher.updateWithGeometryEdit(massesToBeShrunk, true);
        massesToBeShrunk.forEach(mass => {
          if (mass.mesh.isAnInstance) mass.mesh.sourceMesh.synchronizeInstances();
        });
        
        const oneShrinkCommand = commandUtils.geometryChangeOperations.flattenCommands(
          [...shrinkCommands, geometryUpdateCommand]
        );
        allCommands.push(oneShrinkCommand);
        yets.push(false);
      }
      
      // if undefined, command data isn't captured and undo doesn't work
      massesNotDeleted.forEach((mass) => mass.createBuildingDone = false);
      
      const optionsForPropertyChange = {
        componentKeys: ["createBuildingDone", "massType", "typeChangeAllowed"],
      };
      
      let massesNotDeletedPropertyChangeCmdData =
        commandUtils.propertyChangeOperations.getCommandData(
          massesNotDeleted.map((c) => c.mesh),
          optionsForPropertyChange
        );
      
      massesNotDeleted.forEach((mass) => {
        mass.createBuildingDone = true;
        mass.massType = "Mass";
        mass.typeChangeAllowed = false;
      });
      
      
      optionsForPropertyChange.data = massesNotDeletedPropertyChangeCmdData;
      massesNotDeletedPropertyChangeCmdData =
        commandUtils.propertyChangeOperations.getCommandData(
          massesNotDeleted.map((c) => c.mesh),
          optionsForPropertyChange
        );
      
      let massesNotDeletedPropertyChangeCmd =
        commandUtils.propertyChangeOperations.getCommand(
          "massesNotDeleted",
          massesNotDeletedPropertyChangeCmdData
        );
      
      allCommands.push(massesNotDeletedPropertyChangeCmd);
      yets.push(false);
      
    }
    
    CommandManager.execute(allCommands, yets);

    endTime = performance.now();

    console.log(`Create building took ${(endTime - startTime) / 1000} seconds`);
  };

  const addComponent = function (component) {
    componentsCreated.push(component);
  };

  const removeComponent = function (component) {
    if (
      previousCreateBuildingComponents.includes(component) ||
      concernedMasses.allMasses.includes(component)
    ) {
      componentsToBeDeleted.push(component);
    } else {
      _.remove(componentsCreated, component);

      removeComponentFromStructure(component);
      component.mesh.dispose();
    }
  };

  const markAsMassNotDeleted = function (mass) {
    massesNotDeleted.push(mass);
  };

  const cleanUp = function () {
    previousCreateBuildingComponents.length = 0;
    componentsCreated.length = 0;
    componentsToBeDeleted.length = 0;
    concernedMasses.allMasses.length = 0;
    plinthDeleted.length = 0;
    massesNotDeleted.length = 0;
    miscellaneousCommands.length = 0;
    isCreateRoom = null;

    massWallPointsMapping = new Map();

    initiated = false;
  };

  const expressCheckout = async function (stack, generator, processor) {
    _hideButtonsAndShowToast();

    return new Promise((resolve) => {
      _.defer(async () => {
        // because hide works after setTimeout
        try {
          init(stack);
          await generateComponents(generator);
          processCreatedComponents(processor);
        } catch (e) {
          console.error("Error in CB");
          console.error(e);
        } finally {
          _showButtonsAndHideToast();
          executeCommands();
          cleanUp();
          resolve();
        }
      });
    });
  };

  const addToMassWallPointsMapping = function (mass, wallPoints) {
    massWallPointsMapping.set(mass, wallPoints);
  };

  const _generateWalls = function () {
    massWallPointsMapping.forEach((wallPoints, mass) => {
      const mesh = mass.mesh;
      let walls = straightwall(wallPoints, mass);

      const structureCollection = StructureCollection.getInstance();
      const structure = structureCollection.getStructureById(mass.structure_id);
      const level = structure.getLevelByUniqueId(mass.level_id);

      walls.forEach((wall) => {
        level.addWallToLevel(wall, false);
        wall.groupId = mass.groupId;

        createBuildingEngine.addComponent(wall);
        generateBrepForComponents(wall.mesh);
        wall.updateDefaultMaterial();
      });

      // This is to mark the walls generated from edited masses as Edited
      if (mass.isEdited()) {
        walls.forEach((wall) => {
          wall.markAsEdited();
        });
      }

      if (store.createBuildingGlobalVariables.changeParentChildRelationships) {
        //for non-room masses
        mesh.getChildren().forEach((child) => {
          walls.some((wall) => {
            if (wall.mesh.intersectsMesh(child, true)) {
              child.setParent(wall.mesh);
              wall.mesh.childrenComp.push(child);
              return true;
            }
          });
        });
      }
    });
  };

  const _generateFlooring = async function () {
    // let masses = concernedMasses.allMasses;
    // let walls = [];
    massWallPointsMapping.forEach((wallPointsData, mass) => {
      if (!mass.floors) return;
      let walls = [];
      // let edges = [];

      // Getting walls using wallPointsData
      wallPointsData.forEach((wallPointData) =>
        walls.push(wallPointData.createdWall)
      );
      for (let i = 0; i < mass.getBoundaryCoords().length; i++) {
        // Getting walls using edgeData
        let edge = virtualSketcher.lookupEdge(
          mass.getBoundaryCoords()[i],
          mass.getBoundaryCoords()[(i + 1) % mass.getBoundaryCoords().length]
        );
        if (edge)
          walls.push(
            massDissector
              .getMetaDataPersist()
              .wallResolutionData.edgeIdOccupierMap.get(edge.id).createdWall
          );

        // Getting additional walls using nodeIdJunctionOccupierMap
        let node = virtualSketcher.lookup(mass.getBoundaryCoords()[i]);
        if (node) {
          let wallsData = massDissector
            .getMetaDataPersist()
            .wallJunctionData.nodeIdJunctionOccupierMap.get(node.id);
          if (wallsData)
            wallsData.forEach((wallData) => walls.push(wallData.createdWall));
        }
      }
      walls = _.compact(walls);
      walls = _.uniqBy(walls, "mesh.uniqueId");
      mass.floors.forEach((floor) => {
        let result = csgOperator.promisifiedDifference(
          [floor.mesh, ...walls.map((wall) => wall.mesh)],
          [floor.brep, ...walls.map((wall) => wall.brep)]
        );
        result.then((res) => {
          // res.mesh.isVisible = true;
          floor.brep = res.brep;
          floor.mesh.BrepToMesh();
          floor.mesh.position = res.mesh.position;
          floor.mesh.snaptrudeFunctions().copyVertexDataToMesh(floor.mesh);
          res.mesh.dispose();
        });
      });
      // console.log(result);
      delete mass.floors;
    });
  };

  const _modifyFlooring = function () {
    const _getWallsData = function (wallPointsData, mass) {
      let walls = [];

      // Getting walls using wallPointsData
      // wallPointsData.forEach(wallPointData => walls.push(wallPointData.createdWall));
      walls.push(...wallPointsData);

      let bottomFaceVertices = getBottomFaceVertices(mass);
      for (let i = 0; i < bottomFaceVertices.length; i++) {
        // Getting walls using edgeData
        let edge = virtualSketcher.lookupEdge(
          bottomFaceVertices[i],
          bottomFaceVertices[(i + 1) % bottomFaceVertices.length]
        );
        if (edge) {
          walls.push(
            massDissector
              .getMetaDataPersist()
              .wallResolutionData.edgeIdOccupierMap.get(edge.id)
          );
        }

        // Getting additional walls using nodeIdJunctionOccupierMap
        let node = virtualSketcher.lookup(bottomFaceVertices[i]);
        if (node) {
          let wallsData = massDissector
            .getMetaDataPersist()
            .wallJunctionData.nodeIdJunctionOccupierMap.get(node.id);
          if (wallsData) walls.push(...wallsData);
        }
      }
      
      walls = _.uniqBy(_.compact(walls), "createdWallUniqueId");
      walls = _.filter(walls, (wall) => !!wall.createdWallUniqueId);
      
      if (!virtualSketcher.util.doesComponentGrowDownwards(mass)) {
        walls = _.filter(
          walls,
          (wall) =>
            wall.preGenerationEdit !==
            massDissector.CONSTANTS.preGenerationEditTypes.scaleDown
        );
      }

      return walls.map((wall) =>
        wall.bottomCoords.map((vertex) => [
          parseFloat(vertex.x.toFixed(3)),
          parseFloat(vertex.z.toFixed(3)),
        ])
      );
    };

    const _processPoints = function (points) {
      // points = removeDuplicateVerticesModified(points);
      // points = removecollinear(points);
      // points = roundAndRemoveDuplicates(points);
      // points = equateDecimalPoints(points);
      points = points.map((point) => [
        parseFloat(point[0].toFixed(3)),
        parseFloat(point[1].toFixed(3)),
      ]);
      return points;
    };

    const _generateAndImposeNewFloor = function (newFloorPoints, floor) {
      const newFloorMesh = createCustomMesh(
        newFloorPoints,
        plainFloorParameters.floorDepth
      );
      
      floor.brep = newFloorMesh.brep;
      floor.mesh.BrepToMesh();
      floor.mesh.position.x = newFloorMesh.position.x;
      floor.mesh.position.z = newFloorMesh.position.z;
      
      newFloorMesh.dispose();
    };

    // bool used is from polybooljs(https://github.com/velipso/polybooljs)
    // bool.epsilon(1e-5);
    massWallPointsMapping.forEach((wallPointsData, mass) => {
      if (!mass.floors) return;
      let wallsData = _getWallsData(wallPointsData, mass);
      mass.floors.forEach((floor) => {
        
        const currentVertices = getBottomFaceVertices(floor);
        let newFloorPoints = {
          regions: [
            _processPoints(
              currentVertices.map((vertex) => [vertex.x, vertex.z])
            ),
          ],
          inverted: false,
        };
        
        wallsData.forEach((wallData) => {
          try {
            newFloorPoints = store.bool.difference(newFloorPoints, {
              regions: [wallData],
              inverted: false,
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        });

        // NOTE
        // The current implementation creates only one floor per mass.
        // However, in some cases, multiple floors might need to be created due to things like inproper wall resolution.
        // Currently, the floor with the maximum area is chosen. This can be changed in subsequent updates.
        let regionWithMaxArea = 0;
        let maxArea = Math.abs(
          signedArea(newFloorPoints.regions[regionWithMaxArea])
        );
        
        newFloorPoints.regions.forEach((region, i) => {
          let area = Math.abs(signedArea(region));
          if (area > maxArea) {
            regionWithMaxArea = i;
            maxArea = area;
          }
        });
        
        newFloorPoints = _processPoints(
          newFloorPoints.regions[regionWithMaxArea]
        ).map((point) => [point[0], point[1], 0]);
        
        let pointsForFloorGeneration;
        
        // let shouldSanitizeVertices = diagnostics.debugDiagnostics.isToggled();
        let shouldSanitizeVertices = true;
        
        if (shouldSanitizeVertices){
          const newFloorV3 = newFloorPoints.map(p => new BABYLON.Vector3(p[0], p[2], p[1]));
          const sanitizedNewFloorV3 = sanitizeVertices(newFloorV3, {
            duplicateThreshold: 1e-2,
            overlapThreshold: 1e-1,
          });
          
          pointsForFloorGeneration = sanitizedNewFloorV3.map(v3 => [v3.x, v3.z, v3.y]);
        }
        else {
          pointsForFloorGeneration = newFloorPoints;
        }
        
        _generateAndImposeNewFloor(pointsForFloorGeneration, floor);
      });
      
      delete mass.floors;
    });
    // bool.epsilon(1e-10);
  };

  const resolveJunctionsAndGenerateWalls = function () {
    try {
      massDissector.findAndFixOverlappingWalls(massWallPointsMapping);
      massDissector.allotJunctionOccupationRights();
      // massDissector.handleWallToWallDimensionCase(massWallPointsMapping);
    } catch (e) {
      console.warn(e);
    }

    massWallPointsMapping.forEach((wallPoints, mass) => {
      try {
        massDissector.handleWallJunctions(wallPoints);
        // delete mass.floors;
      } catch (e) {
        console.warn(e);
      }
    });

    _generateWalls();

    // _generateFlooring();
    _modifyFlooring();

    massDissector.cleanUp();
  };

  const getConcernedMasses = function () {
    return concernedMasses.allMasses;
  };

  const getComponentsCreated = function () {
    return componentsCreated;
  };

  return {
    init,
    isCreateRoom,
    generateComponents,
    processCreatedComponents,
    executeCommands,
    addComponent,
    removeComponent,
    expressCheckout,
    markAsMassNotDeleted,

    addToMassWallPointsMapping,
    resolveJunctionsAndGenerateWalls,
    getConcernedMasses,
    getComponentsCreated,
  };
})();
export { createBuildingEngine };
