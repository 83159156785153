import BABYLON, { Rectangle } from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { nonDefaultMesh, nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import { MeshGroups } from "../modules/constants.module.js";
import { Mass } from "../modules/snaptrudeDS/mass.ds.js";
import {
  drawSelectionBox,
  enableMassMode,
  hideButtonStatus,
  removeMeshSelectionChildren,
  removeSelectionBox,
} from "./meshEvents.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { areaView } from "./areaView.js";
import { updatePropBlock } from "./propBlocks.js";
import { is2D } from "./twoDimension.js";
import { labelView } from "./labelView.js";
import { isMeshNonSelectable, showToast, TOAST_TYPES } from "../modules/extrafunc.js";
import reduxStore from "../stateManagers/store/reduxStore";
import objectPropertiesView from "../modules/objectProperties/objectPropertiesView.js";
import { deselectMesh, resetMeshSelection } from "../stateManagers/reducers/objectProperties/meshSelection";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController";
import { isMeshDWIndicator } from "../modules/meshoperations/moveOperations/moveUtil";
import {isCADMesh} from "./snapUtilities";
import _ from "lodash";

var selectionRect;
var initPoint;
var rectPoint;
let group;
let _firstClickTime = null;
let firstClick = false;
let labelFirstMesh = null;
var disablePlinthSelection = true;

var onPointerDownSelect = function (evt) {
  if (
    evt.pointerType === "pen" ||
    evt.pointerType === "mouse" ||
    evt.pointerType === ""
  ) {
    if (evt.which === 1) {
      _firstClickTime = new Date();
      
      const options = { includeFloorPlans: true, pickLockedMeshes: true };
      let pickInfo = scenePickController.pickWithBoundingInfo(null, options);
      
      if (firstClick === true) {
        if (pickInfo.hit) {
          if (pickInfo.pickedMesh) {
            if (labelFirstMesh === null) {
              labelFirstMesh = pickInfo.pickedMesh.room_unique_id;
            } else {
              if (labelFirstMesh === pickInfo.pickedMesh.room_unique_id) {
                let timeDiff = new Date() - _firstClickTime;
                if (timeDiff / 1000 < 0.5) {
                  onPointerDoubleClick(evt);
                }
              }
            }
          }
        }

        firstClick = false;
      } else {
        if (pickInfo.hit) {
          if (pickInfo.pickedMesh) {
            firstClick = true;
            labelFirstMesh = pickInfo.pickedMesh.room_unique_id;
          }
        }
      }

      // store.newScene.activeCamera.detachControl(canvas);
      // console.log(evt);
      selectionRect = new Rectangle();
      selectionRect.left = store.newScene.pointerX - store.canvas.width / 2;
      selectionRect.top = store.newScene.pointerY - store.canvas.height / 2;
      selectionRect.width = 0;
      selectionRect.height = 0;
      selectionRect.color = "Red";
      selectionRect.thickness = 1;
      store.advancedTexture.addControl(selectionRect);
      initPoint = {
        x: store.newScene.pointerX - store.canvas.width / 2,
        y: store.newScene.pointerY - store.canvas.height / 2,
      };
      rectPoint = { x: store.newScene.pointerX, y: store.newScene.pointerY };
      // group = MeshGroups.getGroupByUniqueId("my_group");
    } else {
      // console.log("middle")
    }
  }
};

var onPointerUpSelect = function (evt) {
  if (
    evt.pointerType === "pen" ||
    evt.pointerType === "mouse" ||
    evt.pointerType === ""
  ) {
    // var $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    if (selectionRect) {
      if (selectionRect.widthInPixels > 5 || selectionRect.heightInPixels > 5) {
        var width = parseFloat(selectionRect.width.slice(0, -2));
        var height = parseFloat(selectionRect.height.slice(0, -2));
        store.advancedTexture.removeControl(selectionRect);
        if (!group) {
          group = MeshGroups.createNewGroup("my_group");
        }
        /* eslint-disable */
        if (evt.ctrlKey || evt.shiftKey || evt.metaKey) {
        } else {
          for (let i = 0; i < store.selectionStack.length; i++) {
            removeMeshSelectionChildren(store.selectionStack[i]);
            store.selectionStack[i].state = "off";
          }
          store.selectionStack = [];
        }
        /* eslint-enable */
        store.newScene.activeCamera.getActiveMeshes().forEach(function (mesh) {
          if (mesh.state === "on") return;
          let pt = BABYLON.Vector3.Project(
            mesh.getBoundingInfo().boundingBox.centerWorld,
            BABYLON.Matrix.Identity(),
            store.newScene.activeCamera
              .getViewMatrix()
              .multiply(store.newScene.activeCamera.getProjectionMatrix()),
            store.newScene.activeCamera.viewport.toGlobal(
              store.engine.getRenderWidth(),
              store.engine.getRenderHeight()
            )
          );

          if (
            nonDefaultMeshForSnapping(mesh) &&
            mesh.name.indexOf("sketchLine") === -1 &&
            !(Mass.isPlinth(mesh) && disablePlinthSelection)
          ) {
            if (
              rectPoint.x < store.newScene.pointerX &&
              rectPoint.y < store.newScene.pointerY
            ) {
              if (
                checkIfInSR(
                  rectPoint.x,
                  rectPoint.y,
                  rectPoint.x + width,
                  rectPoint.y,
                  rectPoint.x + width,
                  rectPoint.y + height,
                  rectPoint.x,
                  rectPoint.y + height,
                  pt.x,
                  pt.y
                )
              ) {
                if (
                  store.scene.activeCamera.mode ==
                  BABYLON.Camera.ORTHOGRAPHIC_CAMERA
                ) {
                  if (mesh.storey == store.activeLayer.storey) {
                    store.selectionStack.push(mesh);
                    // const dispatch = useDispatch();
                    // dispatch(updateSelected(mesh));
                    objectPropertiesView.updateObjectProperties(mesh);
                    group.addTemporaryMember(mesh);
                    mesh.state = "on";
                    drawSelectionBox(mesh);
                  }
                } else {
                  store.selectionStack.push(mesh);
                  // const dispatch = useDispatch();
                  // dispatch(updateSelected(mesh));
                  objectPropertiesView.updateObjectProperties(mesh);
                  group.addTemporaryMember(mesh);
                  mesh.state = "on";
                  drawSelectionBox(mesh);
                }

                // group.addTemporaryMember(mesh);
              }
            } else if (
              rectPoint.x > store.newScene.pointerX &&
              rectPoint.y < store.newScene.pointerY
            ) {
              if (
                checkIfInSR(
                  rectPoint.x,
                  rectPoint.y,
                  rectPoint.x,
                  rectPoint.y + height,
                  rectPoint.x - width,
                  rectPoint.y + height,
                  rectPoint.x - width,
                  rectPoint.y,
                  pt.x,
                  pt.y
                )
              ) {
                if (
                  store.scene.activeCamera.mode ==
                  BABYLON.Camera.ORTHOGRAPHIC_CAMERA
                ) {
                  if (mesh.storey == store.activeLayer.storey) {
                    store.selectionStack.push(mesh);
                    // const dispatch = useDispatch();
                    // dispatch(updateSelected(mesh));
                    objectPropertiesView.updateObjectProperties(mesh);
                    group.addTemporaryMember(mesh);
                    mesh.state = "on";
                    drawSelectionBox(mesh);
                  }
                } else {
                  store.selectionStack.push(mesh);
                  // const dispatch = useDispatch();
                  // dispatch(updateSelected(mesh));
                  objectPropertiesView.updateObjectProperties(mesh);
                  group.addTemporaryMember(mesh);
                  mesh.state = "on";
                  drawSelectionBox(mesh);
                }
              }
            } else if (
              rectPoint.x < store.newScene.pointerX &&
              rectPoint.y > store.newScene.pointerY
            ) {
              if (
                checkIfInSR(
                  rectPoint.x,
                  rectPoint.y,
                  rectPoint.x,
                  rectPoint.y - height,
                  rectPoint.x + width,
                  rectPoint.y - height,
                  rectPoint.x + width,
                  rectPoint.y,
                  pt.x,
                  pt.y
                )
              ) {
                if (
                  store.scene.activeCamera.mode ==
                  BABYLON.Camera.ORTHOGRAPHIC_CAMERA
                ) {
                  if (mesh.storey == store.activeLayer.storey) {
                    store.selectionStack.push(mesh);
                    objectPropertiesView.updateObjectProperties(mesh);
                    group.addTemporaryMember(mesh);
                    mesh.state = "on";
                    drawSelectionBox(mesh);
                  }
                } else {
                  store.selectionStack.push(mesh);
                  objectPropertiesView.updateObjectProperties(mesh);
                  group.addTemporaryMember(mesh);
                  mesh.state = "on";
                  drawSelectionBox(mesh);
                }
              }
            } else if (
              rectPoint.x > store.newScene.pointerX &&
              rectPoint.y > store.newScene.pointerY
            ) {
              if (
                checkIfInSR(
                  rectPoint.x,
                  rectPoint.y,
                  rectPoint.x - width,
                  rectPoint.y,
                  rectPoint.x - width,
                  rectPoint.y - height,
                  rectPoint.x,
                  rectPoint.y - height,
                  pt.x,
                  pt.y
                )
              ) {
                if (
                  store.scene.activeCamera.mode ==
                  BABYLON.Camera.ORTHOGRAPHIC_CAMERA
                ) {
                  if (mesh.storey == store.activeLayer.storey) {
                    store.selectionStack.push(mesh);
                    objectPropertiesView.updateObjectProperties(mesh);
                    group.addTemporaryMember(mesh);
                    mesh.state = "on";
                    drawSelectionBox(mesh);
                  }
                } else {
                  store.selectionStack.push(mesh);
                  objectPropertiesView.updateObjectProperties(mesh);

                  group.addTemporaryMember(mesh);
                  mesh.state = "on";
                  drawSelectionBox(mesh);
                }
              }
            }
          }
        });
      } else {
        // let pickInfo = store.scene.pick(
        //   store.scene.pointerX,
        //   store.scene.pointerY,
        //   function (mesh) {
        //     return (
        //       nonDefaultMeshForSnapping(mesh) &&
        //       mesh.name.indexOf("sketchLine") === -1 &&
        //       mesh.isVisible &&
        //       !(Mass.isPlinth(mesh) && disablePlinthSelection)
        //     );
        //   }
        // );

        const requests = [{
          predicate: null,
          pickWithBoundingInfo: false,
          doSimplePick: true,
          includeFloorPlans: true,
          pickLockedMeshes: true,
          includeVoids: true
        }];
        
        // theoretically, pickWithBoundingInfo: true should work
        // but getting incorrect pick results when thin glass curtain walls are involved
        // since it's set to false now, have to have a separate request for cad meshes

        if (is2D()) {
          requests.unshift({
            predicate: null,
            pickDWIndicators: true
          });
          
          requests.push({
            predicate: isCADMesh,
            pickWithBoundingInfo: true
          });
        }
        const pickInfo = scenePickController.sequentialPick(requests);
        let mesh = pickInfo.pickedMesh;
        if (selectionRect) {
          selectionRect.dispose();
          selectionRect = null;
        }
        if (pickInfo.hit) {
          // var $scope = store.angular.element(appElement).scope();
          // $scope = $scope.$$childHead;
          let bbox = mesh.getBoundingInfo();
          let boxes = [];
          //  console.log(mesh.uniqueId);
          //console.log("storey ->", mesh.storey);
          //console.log(mesh.getSnaptrudeDS());
          if (isMeshDWIndicator(mesh)) {
            mesh = mesh.parent;
          }
          if (mesh.name.toLowerCase() === "wall") {
            let wallPos = mesh.position;
            wallPos = new BABYLON.Vector3(wallPos.x, 0, wallPos.z);

            let sphere = store.scene.getMeshByName("isolatedViewSphere");
            if (!sphere) {
              sphere = BABYLON.MeshBuilder.CreateSphere(
                "isolatedViewSphere",
                { diameter: 3 },
                store.scene
              );
              sphere.checkCollisions = true;
              sphere.showBoundingBox = false;
            }

            sphere.setAbsolutePosition(wallPos);
            sphere.refreshBoundingInfo();
            sphere.isVisible = false;
            sphere.visibility = 0.0;

            // findWallNeighboursSync([mesh]);
          }

          // if(mesh.floor){
          //     try{
          //         if(mesh.floor.length){
          //              mesh.floor.forEach(function(id){
          //                 let mesh1 = store.scene.getMeshByUniqueID(id);
          //                 mesh1.material = store.scene.getMaterialByName("wall_mat");
          //                 setTimeout(function(mesh1){
          //                     mesh1.material = store.scene.getMaterialByName("floor_tile");
          //                 }, 3000, mesh1);
          //             });
          //         }
          //         else{
          //              let mesh1 = store.scene.getMeshByUniqueID(mesh.floor);
          //             mesh1.material = store.scene.getMaterialByName("wall_mat");
          //              setTimeout(function(mesh1){
          //                 mesh1.material = store.scene.getMaterialByName("floor_tile");
          //             }, 3000, mesh1);
          //         }
          //     }
          //     catch(e){
          //         console.log(e);
          //     }
          // }
          //checkWallLink(500);
          // if ($scope.assignBaseObject) {
          //   let object = mesh.getSnaptrudeDS();
          //   if (object) {
          //     object.properties._components =
          //       $scope.assignBaseObject.properties._components;
          //   }
          // }
          //moveMeshInGroup(mesh);
          if (mesh.state === "on") {
            // removeMeshSelectionChildren(mesh);
            if (evt.ctrlKey || evt.shiftKey || evt.metaKey) {
              for (let i = 0; i < store.selectionStack.length; i++) {
                if (store.selectionStack[i] === mesh) {
                  store.selectionStack.splice(i, 1);
                  mesh.state = "off";
                  removeMeshSelectionChildren(mesh);
                  break;
                }
              }
            } else {
              for (let i = 0; i < store.selectionStack.length; i++) {
                removeMeshSelectionChildren(store.selectionStack[i]);
                store.selectionStack[i].state = "off";
              }
              store.selectionStack = [];
              // document.getElementById("cw").style.visibility = "hidden";
              // document.getElementById("ccw").style.visibility = "hidden";
            }
            DisplayOperation.removeDimensions();
          } else {
            //console.log(mesh.state);
            mesh.children = [];
            if (evt.ctrlKey || evt.shiftKey || evt.metaKey) {
              store.selectionStack.push(mesh);
              // let group = MeshGroups.getGroupByUniqueId("my_group");
              // group.addTemporaryMember(mesh);
              mesh.state = "on";
            } else {
              //console.log(mesh.state);
              for (let i = 0; i < store.selectionStack.length; i++) {
                removeMeshSelectionChildren(store.selectionStack[i]);
                store.selectionStack[i].state = "off";
              }
              store.selectionStack = [];
              areaView.resetOperation();
              let group = MeshGroups.createNewGroup("my_group");
              group.addTemporaryMember(mesh);
              store.selectionStack.push(mesh);
              mesh.state = "on";
            }

            drawSelectionBox(mesh);
            DisplayOperation.displayMeshDimensions(mesh);
            updatePropBlock();
            objectPropertiesView.updateObjectProperties(mesh);
            enableMassMode(mesh);
            hideButtonStatus(mesh, true);
            // document.getElementById("cw").style.visibility = "visible";
            // document.getElementById("ccw").style.visibility = "visible";
          }
          // $scope = store.angular.element(appElement).scope();
          // $scope = $scope.$$childHead;
          // $scope.$apply(function () {
          //   // $scope.levelButtonDisp = false;
          //   $scope.activeObjectName = mesh.name;
          //   $scope.activeObject = mesh;
          //   $scope.instruction = "";
          // });
        } else {
          for (var i = 0; i < store.selectionStack.length; i++) {
            removeMeshSelectionChildren(store.selectionStack[i]);
            store.selectionStack[i].state = "off";
          }
          // store.highlighter.removeAllMeshes();
          store.selectionStack = [];
          areaView.resetOperation();
          DisplayOperation.removeDimensions();
          objectPropertiesView.updateObjectProperties(store.selectionStack[0]);
        }
      }
      if (store.selectionStack.length) {
        // $scope.$apply(function () {
        //   // $scope.levelButtonDisp = false;
        //   $scope.activeObjectName = store.selectionStack[0].name;
        //   $scope.activeObject = store.selectionStack[0];
        // });
        // objectPropertiesView.updateObjectProperties(store.selectionStack[0])
      } else {
        // $scope.$apply(function () {
        //   // $scope.levelButtonDisp = true;
        //   $scope.activeObjectName = "";
        // });
      }
      //const dispatch = useDispatch();
      // reduxStore.dispatch(updateSelected({}));
      initPoint = null;
      selectionRect = null;
      rectPoint = null;
    }
    // if ($scope.assignBaseObject) {
    //   store.selectionStack.forEach((mesh) => {
    //     let object = mesh.getSnaptrudeDS();
    //     if (object) {
    //       object.properties._components = jQuery.extend({}, BasicWallComp);
    //       object.properties._components._name =
    //         $scope.assignBaseObject.properties._components._name;
    //       object.properties._components._layers = jQuery.extend(
    //         [],
    //         $scope.assignBaseObject.properties._components._layers
    //       );
    //     }
    //   });
    // }

    if (selectionRect) {
      selectionRect.dispose();
      selectionRect = null;
    }

    // store.newScene.activeCamera.attachControl(canvas, true, false);
    // var selectionCamera = store.newScene.activeCamera.clone();
    // console.log(selectionCamera, store.newScene.activeCamera);
    // var x = (initPoint.x + store.canvas.width / 2) / store.canvas.width;
    // var y = (canvas.height - (initPoint.y + store.canvas.height / 2 + parseFloat(selectionRect.height.slice(0, -2)))) / store.canvas.height;
    // console.log(newScene.pointerX, store.newScene.pointerY);

    //selectionCamera.viewport = new BABYLON.Viewport(x, y, width, height);
    // var target = new BABYLON.Vector3(selectionRect.centerX/ store.canvas.width,0,selectionRect.centerY/canvas.height);
    // store.newScene.activeCamera.target = target;

    //newScene.activeCameras.push(selectionCamera);
    // store.newScene.activeCamera = selectionCamera;
    //console.log(selectionCamera);
    //console.log(newScene.activeCamera.getActiveMeshes(), selectionCamera.getActiveMeshes());
    //initPoint = null;

    //console.log("onpointerup");
    // console.log(width, height);
    //var pt1 = BABYLON.Vector3.Unproject(new BABYLON.Vector3(selectionRect.centerX, selectionRect.centerY, 0), store.newScene.activeCamera.viewport.width, store.newScene.activeCamera.viewport.height, new BABYLON.Matrix(), store.newScene.activeCamera._viewMatrix, store.newScene.activeCamera._projectionMatrix);
    //console.log(pt1);

    // var pickInfo = store.newScene.pick(selectionRect.centerX, selectionRect.centerY, function (mesh) {
    //     return (mesh.name === "ground1")
    // });
    // console.log(pickInfo);me

    // var matrix = store.newScene.activeCamera._projectionMatrix;
    // var matrix2 = new BABYLON.Matrix.Identity();
    // BABYLON.Matrix.ScalingToRef(canvas.width / width, store.canvas.height / height, 1, matrix2);
    // // var projection = matrix2.multiply(matrix);
    // store.newScene.activeCamera._projectionMatrix = matrix2.multiply(matrix);
    //
    // store.newScene.activeCamera.target = pickInfo.pickedPoint;

    // console.log(projection);
    // var matrix3 = store.newScene.activeCamera._viewMatrix;
    // var matrix4 = new BABYLON.Matrix.Identity();
    // BABYLON.Matrix.TranslationToRef(selectionRect.centerX/canvas.width, selectionRect.centerY/canvas.height, 1, matrix4);
    // var view = matrix4.multiply(matrix3);
    // console.log(view);
    // store.newScene.activeCamera._viewMatrix = matrix4.multiply(matrix3);
    // var transformation = projection.multiply(view);
    // console.log(transformation);
    // store.newScene.activeCamera._transformMatrix = transformation;
    //var matrix3 = new BABYLON.Matrix();
    // var pickInfo = store.newScene.pick(selectionRect.centerX, selectionRect.centerY, function (mesh) {
    //     return (mesh === store.scene.getMeshByName('ground1'));
    // });
    //BABYLON.Matrix.TranslationToRef(pickInfo.pickedPoint.x, pickInfo.pickedPoint.z, pickInfo.pickedPoint.y, matrix3);
    //var matrix4 = matrix3.multiply(matrix2);
    //console.log(matrix.multiply(matrix4));
    //console.log(matrix);
    //newScene.activeCamera._projectionMatrix = store.newScene.activeCamera._projectionMatrix.multiply(matrix4);
    // var frustumPlanes1 = BABYLON.Frustum.GetPlanes();
    // var frustumPlanes2 = BABYLON.Frustum.GetPlanes(newScene.activeCamera.getTranformationMatrix());
    // store.newScene.activeCamera.getActiveMeshes().data.forEach(function(mesh){
    //     if(mesh.isInFrustum(frustumPlanes2)) console.log(mesh.name);
    //     // if(mesh.isInFrustum(frustumPlanes2)) console.log(mesh.name);
    // });
    //
    // BABYLON.Frustum.GetPlanesToRef(newScene.activeCamera.getTranformationMatrix(), frustumPlanes2);
    // console.log(jQuery.extend({}, frustumPlanes2));
    // var frustumPlanes3 = [];
    // frustumPlanes2.forEach(function (plane) {
    //     console.log(jQuery.extend({}, plane.asArray()));
    //     var pl = plane.transform(matrix2);
    //     console.log(pl.asArray());
    //     frustumPlanes3.push(pl);
    // });
    //
    // console.log(frustumPlanes3);
    // console.log(matrix2);
    // store.newScene.activeCamera.getActiveMeshes().data.forEach(function(mesh){
    //     if(mesh.isInFrustum(frustumPlanes3)) console.log(mesh.name);
    //     // if(mesh.isInFrustum(frustumPlanes2)) console.log(mesh.name);
    // });

    // store.newScene.activeCamera.detachControl(canvas);
  }
};

function areaSR(x1, y1, x2, y2, x3, y3) {
  return Math.round(
    Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2.0)
  );
}

function checkIfInSR(x1, y1, x2, y2, x3, y3, x4, y4, x, y) {
  // Calculate area of rectangle ABCD
  var A = areaSR(x1, y1, x2, y2, x3, y3) + areaSR(x1, y1, x4, y4, x3, y3);

  // Calculate area of triangle PAB
  var A1 = areaSR(x, y, x1, y1, x2, y2);

  // Calculate area of triangle PBC
  var A2 = areaSR(x, y, x2, y2, x3, y3);

  // Calculate area of triangle PCD
  var A3 = areaSR(x, y, x3, y3, x4, y4);

  // Calculate area of triangle PAD
  var A4 = areaSR(x, y, x1, y1, x4, y4);

  // Check if sum of A1, A2, A3
  // and A4 is same as A
  var AA = A1 + A2 + A3 + A4;
  return AA <= A + 5 && AA >= AA - 5;
}

var onPointerMoveSelect = function (evt) {
  if (store.$scope.isTwoDimension) {
    let pickInfo = scenePickController.pick(
      mesh => ["mass", "floor"].includes(mesh.type.toLowerCase())
    );

    if (pickInfo.hit && !initPoint) {
      if (pickInfo.pickedMesh.type) {
        labelView.displayLabel(pickInfo.pickedMesh, pickInfo.faceId, true);
      }
    } else {
      labelView.resetOperation(true);
    }
  }

  if (
    evt.pointerType === "pen" ||
    evt.pointerType === "mouse" ||
    evt.pointerType === ""
  ) {
    if (!selectionRect) return;
    if (!initPoint) return;
    if (
      initPoint.x + store.canvas.width / 2 < store.newScene.pointerX &&
      initPoint.y + store.canvas.height / 2 < store.newScene.pointerY
    ) {
      selectionRect.width =
        store.newScene.pointerX - store.canvas.width / 2 - initPoint.x + "px";
      selectionRect.height =
        store.newScene.pointerY - store.canvas.height / 2 - initPoint.y + "px";
      selectionRect.left =
        initPoint.x +
        (store.newScene.pointerX - store.canvas.width / 2 - initPoint.x) / 2;
      selectionRect.top =
        initPoint.y +
        (store.newScene.pointerY - store.canvas.height / 2 - initPoint.y) / 2;
    } else if (
      initPoint.x + store.canvas.width / 2 > store.newScene.pointerX &&
      initPoint.y + store.canvas.height / 2 < store.newScene.pointerY
    ) {
      selectionRect.width =
        initPoint.x + store.canvas.width / 2 - store.newScene.pointerX + "px";
      selectionRect.height =
        store.newScene.pointerY - store.canvas.height / 2 - initPoint.y + "px";
      selectionRect.left =
        store.newScene.pointerX +
        (initPoint.x - store.canvas.width / 2 - store.newScene.pointerX) / 2;
      selectionRect.top =
        initPoint.y +
        (store.newScene.pointerY - store.canvas.height / 2 - initPoint.y) / 2;
    } else if (
      initPoint.x + store.canvas.width / 2 < store.newScene.pointerX &&
      initPoint.y + store.canvas.height / 2 > store.newScene.pointerY
    ) {
      selectionRect.width =
        store.newScene.pointerX - store.canvas.width / 2 - initPoint.x + "px";
      selectionRect.height =
        initPoint.y + store.canvas.height / 2 - store.newScene.pointerY + "px";
      selectionRect.left =
        initPoint.x +
        (store.newScene.pointerX - store.canvas.width / 2 - initPoint.x) / 2;
      selectionRect.top =
        store.newScene.pointerY +
        (initPoint.y - store.canvas.height / 2 - store.newScene.pointerY) / 2;
    } else if (
      initPoint.x + store.canvas.width / 2 > store.newScene.pointerX &&
      initPoint.y + store.canvas.height / 2 > store.newScene.pointerY
    ) {
      selectionRect.width =
        initPoint.x + store.canvas.width / 2 - store.newScene.pointerX + "px";
      selectionRect.height =
        initPoint.y + store.canvas.height / 2 - store.newScene.pointerY + "px";
      selectionRect.left =
        store.newScene.pointerX +
        (initPoint.x - store.canvas.width / 2 - store.newScene.pointerX) / 2;
      selectionRect.top =
        store.newScene.pointerY +
        (initPoint.y - store.canvas.height / 2 - store.newScene.pointerY) / 2;
    } /*else {
        }*/
  }
};

var onPointerLeaveSelect = function (evt) {
  if (selectionRect) {
    showToast("Selection out of bounds", 3000, TOAST_TYPES.error);
    selectionRect.dispose();
    selectionRect = null;
  }
  initPoint = null;
  rectPoint = null;
  _firstClickTime = null;
  firstClick = null;
  labelFirstMesh = null;
};

var onPointerDoubleClick = function (evt) {
  let pickInfo = scenePickController.pick();

  if (pickInfo.hit) {
    if (pickInfo.pickedMesh.type) {
      // if (['door', 'window', 'furniture'].includes(pickInfo.pickedMesh.type.toLowerCase())) {
      //     pickInfo.pickedMesh.rotation.y += Math.PI;
      // }
      if (["floor"].includes(pickInfo.pickedMesh.type.toLowerCase())) {
        labelView.displayLabel(
          pickInfo.pickedMesh,
          pickInfo.faceId,
          false,
          evt
        );
      } else if (["mass"].includes(pickInfo.pickedMesh.type.toLowerCase())) {
        if (
          // pickInfo.pickedMesh.getSnaptrudeDS().massType.toLowerCase() === "room"
          pickInfo.pickedMesh.room_type
        ) {
          // massType.toLowerCase() === "room" check is insufficient because
          // water bodies after CB should support label change but they are "mass"es
          
          labelView.displayLabel(
            pickInfo.pickedMesh,
            pickInfo.faceId,
            false,
            evt
          );
        }
      }
    }
  }
};

function clearSelection(){
  removeSelectionBox();
  store.selectionStack.length = 0;
  reduxStore.dispatch(resetMeshSelection());
}


const filterSelection = (type, toSelect, storeyVal) => {

  let snaptrudeDSArray = [];
  if(type==="storey"){
    let structures = StructureCollection.getInstance().getStructureById(store.activeLayer.structure_id);
    const storeyData = structures.getStoreyData().getStoreyByValue(storeyVal);
    snaptrudeDSArray = storeyData.elements;
  }else{
    const structures = StructureCollection.getInstance().getStructures();
    const flyweight = structures[store.activeLayer.structure_id].getAllLevels()["01"].flyweight;

    if (!flyweight[type]) {
      const massesSnaptrudeDSArray = flyweight["masses"];
      snaptrudeDSArray = massesSnaptrudeDSArray.filter(
          s => s?.massType === "LinkedModel" &&
              s?.revitMetaData?.revitLinkName === type
      );
    }
    else {
      snaptrudeDSArray = flyweight[type];
    }
  }
  let deselectMeshIDs = [];
  let group = MeshGroups.createNewGroup("my_group");
  for(let snaptrudeDS of snaptrudeDSArray) {
    let mesh = snaptrudeDS.mesh;
    if(toSelect){
      if (
        mesh.state !== "on" &&
        nonDefaultMesh(mesh) &&
        mesh.name.indexOf("twoPlane") === -1 &&
        mesh.name.indexOf("sketchLine") === -1 &&
        mesh.type !== GLOBAL_CONSTANTS.strings.identifiers.cadSketch
      ) {
        // if ((mesh.scaling.x === 0) && (mesh.scaling.y === 0) && (mesh.scaling.z === 0)) continue;
        if (isMeshNonSelectable(mesh)) continue;
        if (Mass.isPlinth(mesh)) continue;
        mesh.state = "on";
        store.selectionStack.push(mesh);
        mesh.children = [];
        drawSelectionBox(mesh);
        group.addTemporaryMember(mesh);
      }
    }else{
      if (
        mesh.name.indexOf("boxScale") == -1 &&
        mesh.name.indexOf("axis") == -1 &&
        mesh.name.indexOf("ground") == -1
      ) {
        mesh.state = "off";
        deselectMeshIDs.push({uniqueId: mesh.uniqueId});
        reduxStore.dispatch(deselectMesh({uniqueId: mesh.uniqueId}))
        if (mesh.children) {
          if (mesh.children.length) {
            for (let j = 0; j < mesh.children.length; j++) {
              if (mesh.children[j]) {
                mesh.children[j].dispose();
              }
            }
          }
          mesh.children = [];
        }
      } else if (mesh.name.indexOf("boxScale") !== -1) {
        mesh.dispose();
        // j--;
      }
    }
  }
  if(deselectMeshIDs.length > 0){
    store.selectionStack = _.differenceBy(store.selectionStack, deselectMeshIDs, 'uniqueId')
  }
}

export {
  selectionRect,
  initPoint,
  rectPoint,
  group,
  _firstClickTime,
  firstClick,
  labelFirstMesh,
  onPointerDownSelect,
  onPointerUpSelect,
  areaSR,
  checkIfInSR,
  onPointerMoveSelect,
  onPointerLeaveSelect,
  onPointerDoubleClick,
  clearSelection,
  filterSelection,
};
