import { getDimensionInSnaptrudeUnits } from ".";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import { drawDoor } from "../../libs/drawDoor2d";
import { copyMaterialData } from "../../libs/mats";
import BABYLON from "../babylonDS.module";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { getCSGFormOfMesh, onSolid } from "../extrafunc";
import { Door } from "../snaptrudeDS/doors.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { Wall } from "../snaptrudeDS/wall.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  convertFacesToSnaptrude,
  convertVerticesToSnaptrude,
  createCustomMeshFromRevitExport,
  createCustomMeshFromSpeckleData,
  createMaterialFromSpeckleData,
  createMeshWithMeshData,
  getGeometryDataFromReferenceData,
  getMeshDataFromReferenceData,
  getRevitName,
} from "./extraFunctions";
import stackedWallHelper from "../stackedWalls/stackedWallHelper";
import { _getMiddleSectionOfAWall } from "../../libs/brepOperations";
import { checkStorey } from "./storey";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants";
import { scenePickController } from "../utilityFunctions/scenePickController";

const createDoor = (
  doors,
  allDoorsData,
  doorMaterials = {},
  doorTypes,
  revitWallMap,
  doorsWallMap,
  materialList
) => {
  const structures = StructureCollection.getInstance();
  const str = structures.getStructureById(store.activeLayer.structure_id);
  const level = str.getLevelByName("01");
  let doorsData = [];
  let newWallData = [];
  let sourceDoorData = [];
  const options = {};

  for (let idx in doors) {
    try {
      const doorComponents = {};
      let doorData = allDoorsData[idx];
      const doorName = getRevitName(doorData);
      doorComponents[idx] = doorName;
      let doorSubComponentsData = doors[idx].subComponents;
      if (Object.keys(doorSubComponentsData).length > 0) {
        for (let subIdx = 0; subIdx < doorSubComponentsData.length; ++subIdx) {
          const doorId = doorSubComponentsData[subIdx];
          const doorData = allDoorsData[doorId];
          if (!doorData) {
            continue; // Door can contain other elements as security devices eg. 30672208 in cybercity project
          }
          const doorName = getRevitName(doorData);
          doorComponents[doorId] = doorName;
        }
      }
      let _door = store.scene.getMeshByName(doorName);
      let isSourceMesh = false;
      let door2dData = doorTypes[idx];
      if (!_door) {
        let doorMaterialsData = [];
        let doorComponentsData = [];
        for (let id in doorComponents) {
          let doorMaterial = doorMaterials[doorComponents[id]];
          if (!doorMaterial && !Object.keys(doorMaterial).length > 1) {
            continue;
          } else {
            options.createUsingSpeckle = false;
            doorMaterialsData.push(doorMaterial);
            doorComponentsData.push(allDoorsData[id]);
          }
        }
        let isSourceMesh = false;
        let door2dData = doorTypes[idx];
        doorData = createDoorsWithMeshData(
          doorComponentsData,
          doorMaterialsData,
          materialList,
          options
        );
        isSourceMesh = true;
        if (doorData) {
          sourceDoorData.push(doorData.mesh);
        }
      }

      let door = allDoorsData[idx];
      _door = store.scene.getMeshByName(doorName);
      if (_door) {
        let doorName = _door.name + "Ins" + _door.instances.length;
        let _doorInstance = _door.createInstance(doorName);
        _doorInstance.setAbsolutePosition(_doorInstance.getAbsolutePosition());
        _doorInstance.type = "door";
        _doorInstance.structure_id = store.activeLayer.structure_id;

        let doorDS = _door.getSnaptrudeDS();
        let angle;

        angle = 2 * Math.PI - door.rotation; // for revit import


        let basePoint;

        if (door.basePoint) {
          basePoint = door.basePoint.map((point) => point * 304.8);
        } else {
          basePoint = [0, 0, 0];
        }
        if (checkPointIfZero(basePoint) && door.bbCenter) {
          basePoint = door.bbCenter;
        }

        let center = getDimensionInSnaptrudeUnits([basePoint], "millimeter")[0];

        const bbinfo = _doorInstance.getBoundingInfo().boundingBox;
        const maximum = bbinfo.maximum.asArray();
        const minimum = bbinfo.minimum.asArray();

        let height = maximum[1] - minimum[1];
        center[2] += height / 2;
        _doorInstance.position = new BABYLON.Vector3(
          center[0],
          center[2],
          center[1]
        );
        _doorInstance.rotation.y = angle;
        _doorInstance.computeWorldMatrix();

        let _newDoor = new Door(_doorInstance);
        _newDoor.autoInterior = true;
        _newDoor.snapTo = "none";
        // _newDoor.importType = _door.getSnaptrudeDS().importType;
        let handOrientation, facingOrientation;
        if (door?.HandOrientation)
          handOrientation = BABYLON.Vector3.FromArray([
            door?.HandOrientation[0],
            door?.HandOrientation[2],
            door?.HandOrientation[1],
          ]);
        if (door?.FacingOrientation)
          facingOrientation = BABYLON.Vector3.FromArray([
            door?.FacingOrientation[0],
            door?.FacingOrientation[2],
            door?.FacingOrientation[1],
          ]);
        _newDoor.revitMetaData = {
          elementId: idx,
          family: door?.family,
          facingFlipped: door?.isFaceFlipped,
          type: door?.type,
          handOrientation,
          facingOrientation,
        };

        let _wallId = doorsWallMap[idx];

        let _wall = revitWallMap[_wallId];
        let { wall, oldWallMesh } = createCSGForDoor(_doorInstance, _wall);
        if (wall) {
          if (_wallId) revitWallMap[_wallId] = wall;
          let newWallMesh = wall.mesh;
          newWallMesh.type = "wall";
          newWallMesh.elementId = door.elementId;

          wall.assignProperties();
          let oldWallDS = oldWallMesh.getSnaptrudeDS();
          if (oldWallDS.revitMetaData?.elementId) {
            const _wallId = oldWallDS.revitMetaData?.elementId;
            revitWallMap[_wallId] = wall;
          }

          wall.wThickness = oldWallDS.wThickness;
          wall.localLineSegment = oldWallDS.localLineSegment;
          wall.originalWallMesh = oldWallDS.originalWallMesh;

          // Storing brep in originalWallMesh. Needed after deletion of fenestration
          if (oldWallDS.brep) {
            wall.originalWallMesh.brep = store.resurrect.stringify(
              oldWallDS.brep
            );
          }

          wall.neighbours = oldWallDS.neighbours;
          wall.neighboursDetails = oldWallDS.neighboursDetails;
          wall.setTopCoords(oldWallDS.topCoords);
          wall.setBottomCoords(oldWallDS.bottomCoords);
          wall.setMidYHeight(oldWallDS.midY);
          wall.direction = oldWallDS.direction;
          wall.bottomLineSegment = oldWallDS.bottomLineSegment;
          wall.room_id = oldWallDS.room_id;
          wall.profile = oldWallDS.profile;
          wall.topLineSegment = oldWallDS.topLineSegment;
          wall.properties = oldWallDS.properties;
          wall.revitMetaData = oldWallDS.revitMetaData;
          // wall.mesh.uniqueId = oldWallDS.mesh.uniqueId

          if (!wall.brep) {
            copyMaterialData(oldWallMesh, newWallMesh);
          }

          wall.mesh.structure_id = oldWallMesh.structure_id;
          _newDoor.subType = "revitImport";
          let oldStorey = str.getStoreyData().getStoreyByValue(oldWallMesh.storey)
          oldStorey.removeElement(oldWallDS)
          level.removeObjectToLevel(oldWallMesh.getSnaptrudeDS());
          level.addWallToLevel(wall, false);
          newWallMesh.childrenComp = oldWallMesh.childrenComp;
          newWallMesh.childrenComp.push(_doorInstance);
          newWallMesh.childrenComp.forEach(function (child) {
            child.setParent(newWallMesh);
          });
          onSolid(wall.mesh);

          stackedWallHelper.update(oldWallMesh, newWallMesh);
          newWallData.push(newWallMesh);

          oldWallMesh.dispose();
        }
        level.addDoorToLevel(_newDoor, false);
        const storey = checkStorey(door?.levelName);
        if (storey) {
          storey.addElement(_newDoor);
        } else {
          StoreyMutation.assignStorey(_newDoor);
        }
        let door2D = set2dDoorRevitImport(_newDoor, door2dData, door);
        _newDoor.symbolData = door2D?.door2dSymbolData;
        onSolid(_newDoor.mesh, false, { enableFurnitureEdgesOnce: true });
        doorData = _newDoor.mesh;
        doorsData.push(doorData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  let wallsWithDoorHoles = newWallData.filter((wall) => {
    let mesh = store.scene.getMeshByUniqueID(wall.uniqueId);
    if (mesh) return wall;
  });

  // doorsData.forEach((f) => stack.push(f.mesh));
  return { sourceDoorData, doorsData, wallsWithDoorHoles };
};

const createDoorsWithMeshData = (
  data,
  materials,
  materialList,
  options
) => {
  let doorMesh,
    doorsMesh = [];
  let material;
  //
  for (let id = 0; id < data.length; ++id) {
    doorMesh = createCustomMeshFromRevitExport(
      data[id],
      materials[id],
      materialList,
      {
        renderUsingSpeckle: false,
      }
    );
    doorsMesh.push(doorMesh);
  }
  if (doorsMesh.length == 0) return;
  if (doorsMesh.length > 1) {
    doorMesh = BABYLON.Mesh.MergeMeshes(
      doorsMesh,
      true,
      true,
      undefined,
      true,
      false
    );

    material = doorMesh.material;
  } else {
    doorMesh = doorsMesh[0];
  }

  if (doorMesh) {
    let mainDoorMeshData = data[0];
    doorMesh.position = new BABYLON.Vector3(10000, 0, 10000);
    doorMesh.computeWorldMatrix();

    doorMesh.structure_id = store.activeLayer.structure_id;
    let door = new Door(doorMesh);
    doorMesh.type = "door"; //throwAway is overwritten when mesh is added to level
    doorMesh.name = `${mainDoorMeshData.type} ${mainDoorMeshData.family}`;
    if (mainDoorMeshData.width)
      doorMesh.name += `-${parseInt(mainDoorMeshData.width)}`;
    if (mainDoorMeshData.height)
      doorMesh.name += `x${parseInt(mainDoorMeshData.height)}`;

    doorMesh.name += mainDoorMeshData.isFaceFlipped ? "-1" : "-0";
    if (material) {
      const name = `${mainDoorMeshData.type} ${mainDoorMeshData.family}`;
      const isNewMaterial = store.scene.getMultiMaterialByID(name)
        ? false
        : true;
      material.id = name;
      material.name = name;
      // if(isNewMaterial){
      //   saveMaterialInBackend(material);
      // }
    }
    door.room_type = "door";
    door.massType = "Door";
    // door.importType = "speckleRevitImport";
    door.revitMetaData.facingFlipped = mainDoorMeshData.isFaceFlipped;
    door.revitMetaData.family = mainDoorMeshData.family;
    door.revitMetaData.type = mainDoorMeshData?.type;
    door.storey = 1;
    // door.subType = "revitImport";
    door.subType = `${mainDoorMeshData.type} ${mainDoorMeshData.family}`;
    door.mesh.isVisible = false;
    doorMesh.storey = 1;
    door.height = doorMesh.height;
    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure = structureCollection.getStructureById(
      store.activeLayer.structure_id
    );
    const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
    talkingAboutLevel.addObjectToLevel(door, false);

    return door;
  }
};

const _getWallProfileForWallsWithDoor = function (component) {
  let _middleProfile = null;
  if (component) {
    _middleProfile = _getMiddleSectionOfAWall(component);
  }
  return _middleProfile;
};

const createCSGForDoor = (createdMesh, _wall = null) => {
  if (_wall) {
    let oldWallMesh = _wall.mesh;
    let oldWallMeshDS = oldWallMesh.getSnaptrudeDS();
    if (oldWallMeshDS && oldWallMeshDS.revitMetaData) {
      try {
        if (!_wall.revitMetaData.wallProfile) {
          let _oldWallProfile = _getWallProfileForWallsWithDoor(oldWallMeshDS);
          _wall.revitMetaData.originalWallMeshPosition = new BABYLON.Vector3(
            oldWallMesh.position.x,
            oldWallMesh.position.y,
            oldWallMesh.position.z
          );
          _wall.revitMetaData.wallProfile = _oldWallProfile
            ? _oldWallProfile
            : {};
        }
      } catch (e) {
        console.log(e);
      }
    }
    let doorSelectionBox = createSelectionBox(createdMesh, oldWallMeshDS);
    let doorCSG = getCSGFormOfMesh(doorSelectionBox);
    let wallCSG = getCSGFormOfMesh(oldWallMesh);
    let newCSG = wallCSG.subtract(doorCSG);
    let newWallMesh = newCSG.toSnaptrudeMesh("wall", null, store.scene);
    newWallMesh.position = oldWallMesh.position;
    newWallMesh.storey = oldWallMesh.storey;
    let wall = new Wall(newWallMesh, oldWallMesh.room_id);
    doorSelectionBox.dispose();

    if (!newWallMesh.subMeshes) {
      wall = null;
      newWallMesh.dispose();
      // TODO - no submeshes on newWalMeshes results in save errors
      // refactor
    }
    // wall.revitMetaData = _wall.revitMetaData ? _wall.revitMetaData : {};

    return { wall, oldWallMesh };
  }
  let allMeshes = store.scene.meshes.filter((mesh) => mesh.type == "wall");
  for (let i = 0; i < allMeshes.length; i++) {
    let intersect = createdMesh.intersectsMesh(allMeshes[i]);
    if (intersect) {
      let oldWallMesh = allMeshes[i];
      let oldWallMeshDS = oldWallMesh.getSnaptrudeDS();
      let doorSelectionBox = createSelectionBox(createdMesh, oldWallMeshDS);

      let doorCSG = getCSGFormOfMesh(doorSelectionBox);
      let wallCSG = getCSGFormOfMesh(oldWallMesh);
      let newCSG = wallCSG.subtract(doorCSG);
      let newWallMesh = newCSG.toSnaptrudeMesh("wall", null, store.scene);
      newWallMesh.position = oldWallMesh.position;
      newWallMesh.storey = oldWallMesh.storey;
      let wall = new Wall(newWallMesh, allMeshes[i].room_id);
      doorSelectionBox.dispose();

      if (!newWallMesh.subMeshes) {
        wall = null;
        newWallMesh.dispose();
        // TODO - no submeshes on newWalMeshes results in save errors
        // refactor
      }

      return { wall, oldWallMesh };
    }
  }
  return false;
};

const createSelectionBox = (createdMesh, wallMeshDS) => {
  const wallThickness = wallMeshDS.calculateWidth();
  let wallBottomVertices;
  if (wallMeshDS.revitMetaData.bottomRefLine)
    wallBottomVertices = wallMeshDS.revitMetaData.bottomRefLine;
  else {
    wallBottomVertices = wallMeshDS.bottomLineSegment;
  }
  let bbinfo = createdMesh.getBoundingInfo();
  try {
    createdMesh.freezeWorldMatrix();
  } catch (e) {
    console.log(e);
  }
  bbinfo.update(createdMesh._worldMatrix);
  let height = bbinfo.boundingBox.extendSizeWorld.y * 2;
  let width, depth;
  if (
    bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z
  ) {
    width = bbinfo.boundingBox.extendSizeWorld.x * 2;
    depth = bbinfo.boundingBox.extendSizeWorld.z * 2 + 2;
  } else {
    depth = bbinfo.boundingBox.extendSizeWorld.x * 2 + 2;
    width = bbinfo.boundingBox.extendSizeWorld.z * 2;
  }

  let center = bbinfo.boundingBox.centerWorld;
  try {
    createdMesh.unfreezeWorldMatrix();
  } catch (e) {
    console.log(e);
  }

  let wallAngle = 0;
  try {
    const slopeX = wallBottomVertices[1][0] - wallBottomVertices[0][0];
    const slopeY = wallBottomVertices[1][1] - wallBottomVertices[0][1];
    wallAngle = 2 * Math.PI - Math.atan2(slopeY, slopeX);
  } catch {
    wallAngle = 0;
  }
  let box = BABYLON.MeshBuilder.CreateBox(
    "boxScale",
    { height: height, width: width, depth: depth },
    store.scene
  );
  box.position = center;
  box.rotation.y = wallAngle;
  box.originalScaling = box.scaling.clone();
  return box;
};

// input => doorDS, 2d symbol data, speckle door data
const set2dDoorRevitImport = (door, doorData, data) => {
  let door2D;
  let doorMesh = door.mesh;

  door2D = draw2dRevitImport(doorData, data, doorMesh);
  if (door2D) {
    let door2dMesh = door2D.mergedMesh;
    door2dMesh.setParent(doorMesh);
    door2dMesh.position = BABYLON.Vector3.Zero();
    door2dMesh.isVisible =
      store.$scope.isTwoDimension && door.storey == store.activeLayer.storey;
    door2dMesh.type = GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator;
    scenePickController.add(door2dMesh);
  }
  return door2D;
};

const draw2dRevitImport = (doorData, data, doorMesh) => {
  let mergeMeshes = [],
    door2D;
  let door2dSymbolData = [];
  for (let d = 0; d < doorData.length; d++) {
    if (doorData[d].type == "Arc") {
      let start = doorData[d].startPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      let end = doorData[d].endPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );

      let typeofArc =
        doorData[d]?.startAngle > 88 && doorData[d]?.startAngle < 91
          ? "negetive"
          : "positive";

      door2dSymbolData.push({
        startPoint: start,
        endPoint: end,
        typeOfArc: typeofArc,
        type: "Arc",
      });

      door2D = drawDoor.drawCurve(
        "curve1",
        start[0],
        end[0],
        start[1],
        end[1],
        typeofArc
      );
      mergeMeshes.push(door2D);
    } else if (doorData[d].type == "Line") {
      let startPoint = doorData[d].startPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      startPoint = new BABYLON.Vector3(startPoint[0], 0, startPoint[1]);
      let endPoint = doorData[d].endPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      endPoint = new BABYLON.Vector3(endPoint[0], 0, endPoint[1]);
      door2dSymbolData.push({
        startPoint: startPoint.asArray(),
        endPoint: endPoint.asArray(),
        type: "Line",
      });
      door2D = drawDoor.drawThickLine(null, startPoint, 0.06, endPoint);
      mergeMeshes.push(door2D);
    }
  }

  if (mergeMeshes.length == 0) return;

  let mergedMesh = BABYLON.Mesh.MergeMeshes(
    mergeMeshes,
    true,
    true,
    undefined,
    false,
    false
  );
  mergedMesh.renderingGroupId = 2;
  mergedMesh.isPickable = false;
  mergedMesh.rotation.y = 2 * Math.PI - data.rotation;
  door2dSymbolData.push({ rotation: data.rotation });
  return { mergedMesh, door2dSymbolData };
};

const checkPointIfZero = (point) => {
  return point[0] == 0 && point[1] == 0 && point[2] == 0;
};

export { createDoor, _getWallProfileForWallsWithDoor, createSelectionBox, checkPointIfZero };
