import axios from 'axios';
import { ORIGIN, socketUrl } from './url.constants';
import { store } from '../snaptrude/modules/utilityFunctions/Store';
import { getJWT, refreshAccessToken } from './jwt.service';
import { autoSaveConfig } from '../snaptrude/modules/socket/autoSaveConfig';
import { StructureCollection } from '../snaptrude/modules/snaptrudeDS/structure.ds';
import {STRUCTURE_QUERY_RESPONSE} from "../snaptrude/libs/sceneLoad.constants";
import {showToast, TOAST_TYPES} from "../snaptrude/modules/extrafunc";
import { updateExportProgressBar } from '../containers/genericModal/export';
import JSZip from 'jszip';
import { socialConnect } from '../snaptrude/modules/social/socialSocket';
import reduxStore from '../snaptrude/stateManagers/store/reduxStore';
import {strToU8, compressSync} from "fflate";

let numberOfRetries = 0;
let numberOfSaveRetries = 0;

export const getStructureCollectionFromSaveMicro = (floorkey, userId, userEmail) => {
  const data = {
    floorkey,
    userId,
    userEmail
  };
  const formData = new FormData();
  for(let item in data) formData.append(item, data[item]);
  const promise = axios.post(socketUrl + '/migrateorstructure', formData);
  return promise;
};

export const getStructureCollection = async () => {
  // TODO: PRANAV
  // Handle if history
  const jwt = getJWT();
  const floorkey = store.floorkey;
  const data = { jwt, floorkey };
  try{
    const response = await axios.post(socketUrl + '/structure', data, {
      validateStatus: false
    });
    if(response && response.data && response.data.message && response.data.message === autoSaveConfig.CONSTANTS.expired){
      // console.log("Token expired");
      // showToast("Session timed out, Please login again");
      return response.data;
      // if(numberOfRetries > 3){
      //   return response.data;
      // }
      // numberOfRetries++;
      // await refreshAccessToken();
      // return await getStructureCollection();
    } else {
      return response.data;
    }
  } catch (err){
    console.log(err?.message);
    console.log(`Error in fetching structure collection ${err}`);
    return { message: "error" };
  }
};


export const firstManualSave = async (showProgressToast = false) => {
  store.sceneLoadInProgress = true;
  const saveData = StructureCollection.getInstance().getSnaptrudeJSON();
  const {teams} = reduxStore.getState();
  try {
    if (showProgressToast) {
      showToast(
          "Please wait. Your project is getting saved...",
          0,
          autoSaveConfig.CONSTANTS.toastError
      );
    }

    const buf = strToU8(saveData);

    // The default compression method is gzip
    // Increasing mem may increase performance at the cost of memory
    // The mem ranges from 0 to 12, where 4 is the default
    const compressed = compressSync(buf, {level: 6, mem: 8});
    const file = new File([compressed], "canvas.zip");

    const data = {
      canvas: file,
      floorkey: store.floorkey,
      jwt: getJWT(),
      team: teams.selectedTeam?.id || null,
    };
    const formData = new FormData();
    for (const item in data) {
      formData.append(item, data[item]);
    }

    const response = await axios.post(socketUrl + '/firstsave', formData);
    if (response && response.data && response.data.message && response.data.message === autoSaveConfig.CONSTANTS.expired) {
      if (numberOfSaveRetries > 3) {
        return {status: 'disaster'};
      }
      numberOfSaveRetries++;
      await refreshAccessToken();
      return await firstManualSave();
    } else {
      if (showProgressToast) {
        showToast(
            "Project saved!",
            3000,
            autoSaveConfig.CONSTANTS.toastSuccess
        );
      }
      store.sceneLoadInProgress = false;
      socialConnect.populatePermissions();
      autoSaveConfig.enableToasts();
      return {status: 'success'};
    }
  } catch (err) {
    if (showProgressToast) {
      showToast(
          "An error occurred while saving",
          3000,
          autoSaveConfig.CONSTANTS.toastError
      );
      autoSaveConfig.enableToasts();
    }
    console.log(`Error in first save ${err}`);
    return {status: 'disaster'};
  }
};

export const saveAsSaveMicro = function (fromFloorkey, toFloorkey, copyroles, copycomments){
  
  const data = {
    source: fromFloorkey,
    destination: toFloorkey,
    copyroles: copyroles.toString(),
    copycomments: copycomments.toString(),
    jwt: getJWT()
  };
  
  return axios
    .post(autoSaveConfig.getSocketUrl() + "/saveas/", data)
    .then((res) => {
      if(res.data.message && res.data.message === 'success'){
        return { success: true };
      }
      else return { failure: true };
    })
    .catch(err => {
      console.log(err);
      console.log("Error during saveAs in saveMicro");
      return { failure: true };
    })
  
};

export const getGFSComponents = async () => {
  showToast("Loading Remaining Data ...", 0);
  const jwt = getJWT();
  const floorkey = store.floorkey;
  const data = { jwt, floorkey };
  try{
    const response = await axios.post(socketUrl + '/readbigbig', data);
    if(response && response.data && response.data.message && response.data.message === autoSaveConfig.CONSTANTS.expired){
      // if(numberOfRetries > 3){
      //   return null;
      // }
      // numberOfRetries++;
      // await refreshAccessToken();
      // return await getGFSComponents();
    } else{
      return response.data;
    }
  } catch (err){
    console.log(`Error in fetching structure collection ${err}`);
    return null;
  }
};

export const exportTrude = async (filename) => {
  const jwt = getJWT();
  const floorkey = store.floorkey;
  const data = { jwt, floorkey };
  try{
    const response = await axios.post(socketUrl + '/exporttrude', data);
    if(response && response.data && response.data.message && response.data.message === autoSaveConfig.CONSTANTS.expired){
      console.log("Token expired");
      updateExportProgressBar(0, 'Exporting', false);
      showToast("Session timed out, Please login again");
      return response.data;
    
    } else {
      // This is the zip file
      const { data } = response;
      JSZip.loadAsync(data).then((zip => {
        zip.generateAsync({type: "base64", compression: "DEFLATE"}).then(base64 => {
          const dlAnchorElem = document.getElementById("downloadAnchorElem");
          dlAnchorElem.setAttribute("href", "data:application/zip;base64," + base64);
          dlAnchorElem.setAttribute("download", filename + ".trude");
          dlAnchorElem.click();
    
          updateExportProgressBar(100, 'Downloading');
          setTimeout(() => { }, 5000);
          updateExportProgressBar(0, 'Exporting', false);
        })
      }));
    }
  } catch (err){
    console.log(`Error in fetching structure collection ${err}`);
    updateExportProgressBar(0, 'Exporting', false);
    let messageDuration = 3000;
    showToast('Error in fetching structure collection.', messageDuration, TOAST_TYPES.error);
    return { message: "error" };
  }
};
