import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import Button from "../../Button";
import Input from "../../Input";
import closeButtonIcon from "../../../assets/icons/close.svg";
import checkboxActiveIcon from "../../../assets/icons/checkboxActive.svg";
import checkboxInactiveIcon from "../../../assets/icons/checkboxInactive.svg";
import DataTable, { Td, Text, Th, Tr } from "../../DataTable";
import { speckleRevitImport } from "../../../snaptrude/modules/speckleRevitImport";

const Wrapper = styled.div`
  position: relative;
  width: 36.5625rem;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 14px;
  color: ${colors.primeBlack};
  header {
    padding: 0.75em 1em;
    border-radius: 12px 12px 0px 0px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
  }
  .content {
    min-height: 15em;
    max-height: 17em;
    overflow: auto;
    padding: 1em;
  }
`;

function ChooseStackedWallFamilies({
  updatePage,
  handleClose,
  nextPage,
  prevPage,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const allWallTypes = speckleRevitImport.getAllWallTypes();
  
  const [stackedWallData, setStackedWallData] = useState(allWallTypes.map(type => {
    return {
      familyName: type,
      isStacked: false,
      height: 2400,
    };
  }));
  
  const handleSubmit = async (evt) => {
    if (evt) evt.preventDefault();
    setIsLoading(true);
    
    speckleRevitImport.setStackedWallsData(stackedWallData);
    speckleRevitImport.createElements();
    
    handleClose();
    setIsLoading(false);
  };

  const handleItemClick = (index) => {
    const updatedData = stackedWallData.map((data) => data);
    updatedData[index].isStacked = !updatedData[index].isStacked;
    setStackedWallData(updatedData);
  };
  
  const onChange = function (e, index){
    const updatedData = stackedWallData.map((data) => data);
    updatedData[index].height = parseFloat(e.target.value);
    setStackedWallData(updatedData);
  };

  useEffect(() => {
    // speckleRevitImport.updateAllLevelsStatus(levels);
  }, []);

  return (
    <Wrapper>
      <header>
        <div style={{ flex: 1, fontWeight: 700 }}>Choose Stacked Families</div>
        <div style={{ cursor: "pointer" }}>
          <img src={closeButtonIcon} alt="Close" onClick={handleClose} />
        </div>
      </header>
      <div className="content">
        <DataTable style={{ fontWeight: "500", fontSize: "12px" }}>
          <Tr>
            <Th style={{ width: "20em" }}>Family Name</Th>
            <Th style={{ width: "7em", textAlign: "center" }}>Stacked</Th>
            <Th style={{ width: "5em", textAlign: "center" }}>Lower Height</Th>
          </Tr>
          {stackedWallData.map((wallData, idx) => {
            return (
              <Tr key={idx} style={{ height: "2.5em"}}>
                <Td style={{ width: "20em" }}>{wallData.familyName}</Td>
                <Td style={{ minWidth: "1em", width: "7em"}}>
                  <img
                    src={
                      wallData.isStacked
                        ? checkboxActiveIcon
                        : checkboxInactiveIcon
                    }
                    alt="action"
                    onClick={() => handleItemClick(idx)}
                    style={{ cursor: "pointer", width: "15%",  marginLeft: "2em" }}
                  />
                </Td>
                {
                  wallData.isStacked &&
                  <Td style={{width: "5em", textAlign: "center"}}>
                    <Input
                      type={"text"}
                      required
                      value={wallData.height}
                      onPress={(e) => {onChange(e, idx)}}
                      customInput={{
                        width: "5em",
                        height: "1.5em",
                        padding: "0em",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    />
                  </Td>
                }
              </Tr>
            );
          })}
        </DataTable>
      </div>
      <footer>
        <div className="button-wrapper">
          <Button
            customButtonStyle={{
              backgroundColor: colors.fullWhite,
              color: colors.secondaryGrey,
            }}
            title={"Back"}
            onPress={() => {
              updatePage(prevPage);
            }}
          />
        </div>
        <div className="button-wrapper">
          <Button
            isLoading={isLoading}
            primary={true}
            title={"Import"}
            onPress={handleSubmit}
          />
        </div>
      </footer>
    </Wrapper>
  );
}

export default ChooseStackedWallFamilies;
