//Tiles
let TilesRiser = {check:false, value:"Tiles",
  subtype: "",
  thickness:12,
  stairProp: "Riser",
  quantity: "Area",
  unit: "Square metres",
  core: false
}
let TilesTread = {check:false, value:"Tiles",
  subtype: "",
  thickness:12,
  stairProp: "Tread",
  quantity: "Area",
  unit: "Square metres",
  core: false
}
let TilesSide = {check:false, value:"Tiles",
  subtype: "",
  thickness:12,
  stairProp: "Side",
  quantity: "Area",
  unit: "Square metres",
  core: false
}
//Paint
let PaintRiser = {check:false, value:"Paint",
  subtype: "",
  thickness:2,
  stairProp: "Riser",
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
let PaintTread = {check:false, value:"Paint",
  subtype: "",
  thickness:2,
  stairProp: "Tread",
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
let PaintSide = {check:false, value:"Paint",
  subtype: "",
  thickness:2,
  stairProp: "Side",
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
let Paint = {check:false, value:"Paint",
  subtype: "",
  thickness:2,
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
//Screed
let ScreedRiser = {check:false, value:"Screed",
  subtype: "",
  thickness:25,
  stairProp: "Riser",
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
let ScreedTread = {check:false, value:"Screed",
  subtype: "",
  thickness:25,
  stairProp: "Tread",
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
let ScreedSide = {check:false, value:"Screed",
  subtype: "",
  thickness:25,
  stairProp: "Side",
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
let Screed = {check:false, value:"Screed",
  subtype: "",
  thickness:25,
  quantity: "Volume",
  unit: "Cubic metres",
  core: false
}
//Cladding
let CladdingRiser = {check:false, value:"Cladding",
  subtype: "",
  thickness:25,
  stairProp: "Riser",
  quantity: "Area",
  unit: "Square metres",
  core: false
}
let CladdingTread = {check:false, value:"Cladding",
  subtype: "",
  thickness:25,
  stairProp: "Tread",
  quantity: "Area",
  unit: "Square metres",
  core: false
}
let CladdingSide = {check:false, value:"Cladding",
  subtype: "",
  thickness:25,
  stairProp: "Side",
  quantity: "Area",
  unit: "Square metres",
  core: false
}
let Cladding = {check:false, value:"Cladding",
  subtype: "",
  thickness:25,
  quantity: "Area",
  unit: "Square metres",
  core: false
}
//RCC
let RCC = {check:false, value:"RCC",
  subtype: "",
  thickness:"letiable",
  quantity: "Volume",
  unit: "Cubic metres",
  core: true
}
//Brick
let Brick = {check:false, value:"Brick",
    subtype: "",
    thickness:100,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
let BrickRiser = {check:false, value:"Brick",
    subtype: "",
    thickness:100,
    stairProp: "Riser",
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
let BrickTread = {check:false, value:"Brick",
    subtype: "",
    thickness:100,
    stairProp: "Tread",
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
let BrickSide = {check:false, value:"Brick",
    subtype: "",
    thickness:100,
    stairProp: "Side",
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
//Stone
let Stone = {check:false, value:"Stone",
    subtype: "",
    thickness:100,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
}
let StoneRiser = {check:false, value:"Stone",
    subtype: "",
    thickness:100,
    stairProp: "Riser",
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
let StoneTread = {check:false, value:"Stone",
    subtype: "",
    thickness:100,
    stairProp: "Tread",
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
let StoneSide = {check:false, value:"Stone",
    subtype: "",
    thickness:100,
    stairProp: "Side",
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
}
//Wood
let Wood = {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: true
}
let WoodRiser = {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    stairProp: "Riser",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
let WoodTread = {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    stairProp: "Tread",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
let WoodSide = {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    stairProp: "Side",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
//Metal
let Metal = {check:false, value:"Metal",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: true
}
let MetalRiser = {check:false, value:"Metal",
    subtype: "",
    thickness:12,
    stairProp: "Riser",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
let MetalTread = {check:false, value:"Metal",
    subtype: "",
    thickness:12,
    stairProp: "Tread",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
let MetalSide = {check:false, value:"Metal",
    subtype: "",
    thickness:12,
    stairProp: "Side",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
//Glass
let Glass = {check:false, value:"Glass",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
}
let GlassRiser = {check:false, value:"Glass",
    subtype: "",
    thickness:20,
    stairProp: "Riser",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
let GlassTread = {check:false, value:"Glass",
    subtype: "",
    thickness:20,
    stairProp: "Tread",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
let GlassSide = {check:false, value:"Glass",
    subtype: "",
    thickness:20,
    stairProp: "Side",
    quantity: "Area",
    unit: "Square metres",
    core: false
}
//Concrete
let Concrete = {check:false, value:"Glass",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
}



//Stair Components
let BrickStaircase = {
  "_name": "BrickStaircase",
  "_layers": [
    PaintRiser, PaintTread, PaintSide, Screed, Brick
  ],
}
let StoneStaircase = {
  "_name": "StoneStaircase",
  "_layers": [
    TilesRiser, TilesTread, PaintSide, Screed, Stone
  ],
}
let MetalStaircase = {
  "_name": "MetalStaircase",
  "_layers": [
    Metal
  ],
}
let GlassStaircase = {
  "_name": "GlassStaircase",
  "_layers": [
    Glass
  ],
}
let TilesStaircase = {
  "_name": "TilesStaircase",
  "_layers": [
    TilesRiser, TilesTread, PaintSide, Screed, RCC
  ],
}
let WoodStaircase = {
  "_name": "WoodStaircase",
  "_layers": [
    Wood
  ],
}
let ConcreteStaircase = {
  "_name": "ConcreteStaircase",
  "_layers": [
    TilesRiser, TilesTread, PaintSide, Screed, RCC
  ],
}
let ColorsStaircase = {
  "_name": "ConcreteStaircase",
  "_layers": [
    PaintRiser, PaintTread, PaintSide, Screed, RCC
  ],
}
let PaintStaircase = {
  "_name": "ConcreteStaircase",
  "_layers": [
    PaintRiser, PaintTread, PaintSide, Screed, RCC
  ],
}
let RCCStaircase = {
  "_name": "RCCStaircase",
  "_layers": [
    TilesRiser, TilesTread, PaintSide, Screed, RCC
  ],
}

export {
  BrickStaircase,
  StoneStaircase,
  MetalStaircase,
  GlassStaircase,
  TilesStaircase,
  WoodStaircase,
  ConcreteStaircase,
  ColorsStaircase,
  PaintStaircase,
  RCCStaircase,
  TilesRiser,
  TilesSide,
  TilesTread,
  PaintRiser,
  PaintTread,
  PaintSide,
  ScreedRiser,
  ScreedSide,
  ScreedTread,
  CladdingRiser,
  CladdingSide,
  CladdingTread,
  BrickRiser,
  BrickSide,
  BrickTread,
  StoneRiser,
  StoneSide,
  StoneTread,
  WoodRiser,
  WoodSide,
  WoodTread,
  MetalRiser,
  MetalSide,
  MetalTread,
  GlassRiser,
  GlassSide,
  GlassTread
}
