import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../../../themes/constant";

import DaylightBlockLoader1 from '../../../../assets/icons/daylight_analysis/daylight_loader_1.svg'
import DaylightBlockLoader2 from '../../../../assets/icons/daylight_analysis/daylight_loader_2.svg'
import DaylightBlockLoader3 from '../../../../assets/icons/daylight_analysis/daylight_loader_3.svg'

/* Styled Components */

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 0.2rem;
  flex: 1;
  height: 100%;
`;

const RecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  padding-bottom: 50px;

  .fw-500 {
    font-weight: 500;
  }

  .fw-600 {
    font-weight: 600;
  }

  .pd-v {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .fn-12 {
    font-size: 12px;
  }

  .fn-14 {
    font-size: 14px;
  }

  .pd-v {
    padding: 0.4em 0;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const ImageWrapperLink = styled.a`
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background: ${colors.white};
  padding: 0.5rem 0;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background: ${colors.fullWhite};
  padding: 0.5rem 0;
  cursor: pointer;
  border: 2px solid ${colors.white};
`

const ImageWrapper = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  padding: 0.5rem 0;
  cursor: pointer;
`

const RecordPreview = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

const Divider = styled.div`
  border-top: 2px solid #DFE6EC;
  margin: 0.5rem;
`

const CenterElement = styled.div`
  display: flex;
  justify-content: center;
  height: 85%;
  align-items: center;
`;

const EuiItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  padding: 0.5em;
`;

const EuiItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;

  img {
    padding: 5px;
    width: 22px;
    height: 22px;
  }
`;

const DaylightLoaderAnimation = keyframes`
  0% { background-image: url(${DaylightBlockLoader1}) }
  50% { background-image: url(${DaylightBlockLoader2}) }
  100% { background-image: url(${DaylightBlockLoader3}) }
`

const DaylightAnalysisLoader = styled.div`
  display: block;
  height: ${({ height }) => height || "100%"};
  width: ${({ width }) => width || "100%"};
  background-repeat: no-repeat;
  background-position: center;
  animation: 1s ${DaylightLoaderAnimation} linear infinite;
`

const WrapLabelAndRange = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  padding-top: 3px;
`

const RangeBar = styled.div`
  display: block;
  width: 100%;
  height: 50px;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ direction }) => direction || 'row'};
`

const PercentageText = styled.div`
  font-size: 40px;
  color: ${({ fail }) => fail ? '#F2994A' : '#27AE60'}
`

export {
    MainContainer,
    RecordContainer,
    Header,
    ImageWrapperLink,
    Block,
    ImageWrapper,
    RecordPreview,
    Divider,
    CenterElement,
    EuiItemsContainer,
    EuiItem,
    DaylightAnalysisLoader,
    WrapLabelAndRange,
    FlexBox,
    RangeBar,
    PercentageText
}