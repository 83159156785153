import { routes } from "../../../routes/constants";

// common
export const NAV_OPTIONS = [{ title: "Account", route: routes.account }, { title: "Team", route: routes.teams }, { title: "Billing", route: routes.billing }, { title: "Plans", route: routes.plans }];
export const ACCOUNT = "Account";
export const TEAM = "Team";
export const BILLING = "Billing";
export const PLANS = "Plans";

// account
export const EMAIL_TEXT = "mehul@snaptrude.com";
export const NAME_TEXT = "Mehul Pangtey";
export const PHONE_TEXT = "9999999999";
export const EMAIL_ADDRESS_LABEL = "Email Address";
export const NAME_LABEL = "Your Full Name";
export const PHONE_LABEL = "Phone Number";
export const CHANGE_PASSWORD = "Change Password";
export const ADD_PROFILE_PICTURE = "Add Profile Picture";
export const EDIT_PROFILE_PICTURE = "Edit Profile Picture";
export const ENTER_CURRENT_PASSWORD = "Enter Current Password";
export const ENTER_NEW_PASSORD = "Enter new Password";
export const RECONFIRM_NEW_PASSWORD = "Reconfirm new Password";

// billing
export const MANAGE_USERS = 5;
export const MANAGE_TOTAL_PRICE = 1000;
export const MANAGE_PROFESSIONAL_PRICE = 200;
export const MANAGE_RENEWAL = "22 August 2020";
export const PAYMENT_CARD_NAME = "MasterCard";
export const PAYMENT_CARD_NUMBER = "xxxx 8822";
export const VIEW = "View";
export const TABLE_HEADING = "Past Invoices";
export const TABLE_HEADERS = ["Invoice No.", "Month", "Amount", "Invoice"];
export const TABLE_DATA = [
  ["#122423542356", "June 2020", "$800", VIEW],
  ["#122423542356", "July 2020", "$800", VIEW],
  ["#122423542356", "August 2020", "$800", VIEW],
  ["#122423542356", "September 2020", "$800", VIEW],
  ["#122423542356", "October 2020", "$800", VIEW],
];

// plan
export const YEARLY = "Yearly";
export const MONTHLY = "Monthly";
export const CHOOSE_PLAN_TEXT = "Choose a plan";
export const MONTHLY_TEXT = "Monthly";
export const YEARLY_TEXT = "Yearly";
export const FREE = "Free";
export const FREE_PRICE = "0";
export const PROFESSIONAL = "PROFESSIONAL";
export const ENTERPRISE = "ENTERPRISE";
export const ORGANIZATION = "ORGANIZATION";
export const TRIAL_CHECKLIST = ["All Features", "Unlimited Storage"];
export const TRIAL_BUTTON_TEXT = "4 days remaining";
export const PROFESSIONAL_PRICE_MONTHLY = "$200";
export const PROFESSIONAL_PRICE_YEARLY = "$2000";
export const PER_MONTH = "Per month";
export const PER_YEAR = "Per year";
export const PER_USER = "Per user";
export const PROFESSIONAL_BUTTON_TEXT = "Contact us";
export const PROFESSIONAL_CHECKLIST = [
  "All Features",
  "Unlimited Storage",
  "Up to 10 users",
];
export const ENTERPRISE_PRICE = "Custom";
export const ENTERPRISE_BUTTON_TEXT = "Request CallBack";
export const ENTERPRISE_CHECKLIST = [
  "All Features",
  "Unlimited Storage",
  "More than 10 users",
];

// team
export const PERMISSIONS_ARRAY = [
  "Can View",
  "Can Comment",
  "Can Edit",
  "Remove",
];
export const HEADERS = ["Name", "Email Address", "Permissions"];
export const EMAIL_DUMMY_TEXT = "Rahul@hadid.com";
export const NAME_DUMMY_TEXT = "User Name";
export const ENTER_USER_NAME = "Enter User Name";
export const ENTER_EMAIL = "Enter User Email Address";
export const SEND_INVITE = "Send Invite";
export const ADD_USERS = "Add Users";

export const PLANS_CHECKLIST = {
  FREE: [
    "3 Projects",
    "All Design Features",
    "Share and Comment",
    "Object Libraries",
    "Areas, BoQ and Views",
  ],
  PROFESSIONAL: [
    "Everything in Free +",
    "Unlimited Projects",
    "Shared Team workspace",
    "Organisation wide libraries",
    "Automated Costing",
    "All imports and exports",
    "Revit <> Snaptrude bi-directional link",
    "Email Support",
  ],
  ORGANIZATION: [
    "Everything in Pro +",
    "Unlimited Teams",
    "Building Performance Analysis with Cove.Tool",
    "Project sign-offs",
    "Customised team and license management",
    "Advanced Security + SSO",
    "Account Manager",
    "Onboarding planning and zoom tutorials",
  ],
  ENTERPRISE: [
    "Everything in Organization +",
    "Customization on Request",
    "Analytics Logs",
    "3D model embed on websites",
    "Dedicated Account Manager",
    "Hands-On Training",
    "Pilot projects",
    "Library upload and management support",
  ]
}