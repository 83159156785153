import React, { useState, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import * as constants from "./constants";
import tile from "../../assets/images/tile.svg";
import Edit from "../../assets/icons/edit.svg";
import Input from "../Input/index";
import Button from "../Button/index";
import ScrollableDropdown from "../../components/ScrollableDropdown/index";
import useOnClickOutside from "../../common/utils/onOutsideClickHandler";

const Main = styled.div`
  position: relative;
  box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
  .container:before {
    content: "";
    position: absolute;
    top: 0px;
    right: -2.1875rem;
    height: 2.1875rem;
    width: 2.1875rem;
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 20px 0 0 white;
    transform: rotate(90deg);
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 16.9375rem;
  border-radius: 0.625em;
  background-color: ${colors.fullWhite};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: center;
`;

const TopContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: flex-start;
`;

const Heading = styled.div`
  align-self: flex-start;
  padding: 0.4375rem;
  padding-left: 0;
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.211789px;
    color: ${colors.darkGrey};
    margin: 0;
  }
`;

const EditImage = styled.div`
  position: relative;
  margin-right: 0.3125rem;
  img {
    border-radius: 0.3125rem;
    width: 7.0625rem;
  }
`;

const EditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.3125rem;
  display: flex;
  justify-content: flex-end;
  img {
    width: 0.9375rem;
    height: 0.9375rem;
  }
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
  padding: 0;
  margin: 0;
`;

const TopEditContainer = styled(TopContainer)`
  justify-content: space-between;
  padding: 0.5rem 0;
`;

const ImageEditContainer = styled(TopContainer)`
  alignitems: flex-start;
  .inputWrapperTop {
    justify-content: space-between;
    margin: 0 0.3125rem;
    align-items: flex-start;
  }
  .scaleInput {
    margin-bottom: 0.3125rem;
  }
`;

const DropdownWrapper = styled(TopContainer)`
  justify-content: space-between;
  margin: 0.25rem 0.625rem;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 8.75rem;
  height: 1.5625rem;
  padding: 0.3125rem 0 0.9375rem 0;
`;

function CommonEdit(props) {
  const { inputValues, dropdown, closePopUp } = props;
  const [editHeader, setEditHeader] = useState("");
  const [inputValuesCopy, setInputValuesCopy] = useState(inputValues);
  const [editable, setEditable] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (closePopUp) closePopUp();
  });

  const updateInputValue = (index, item, e) => {
    e.persist();
    setInputValuesCopy((prevState) => {
      prevState[index] = {
        title: item.title,
        value: parseInt((e.target && e.target.value) || 0, 10),
      };
      return [...prevState];
    });
  };

  return (
    <Main ref={ref}>
      <Container className="container">
        <Inner>
          {editable ? (
            <TopContainer style={{ padding: "0.625rem 0" }}>
              <Input
                type={"text"}
                required
                value={editHeader}
                customInputContainerStyle={{ width: "100%" }}
                placeholder={constants.MATERIAL_NAME_PLACEHOLDER}
                onPress={(e) => {
                  setEditHeader(e.target.value);
                }}
              />
            </TopContainer>
          ) : (
            <TopEditContainer>
              <Heading>
                <p>{constants.MATERIAL_OVERLAY_HEADER}</p>
              </Heading>
              <div onClick={() => setEditable(true)}>
                <img
                  style={{ marginRight: "0.625rem" }}
                  src={Edit}
                  alt="edit"
                />
              </div>
            </TopEditContainer>
          )}

          <ImageEditContainer>
            <EditImage>
              <img src={tile} alt="tile" />
              <EditOverlay>
                <img src={Edit} alt="edit" />
              </EditOverlay>
            </EditImage>
            <TopContainer className="inputWrapperTop">
              <div>
                {inputValuesCopy &&
                  inputValuesCopy.map((item, index) => {
                    return (
                      <div key={index} className="scaleInput">
                        <Input
                          label={item.title}
                          labelStyle={{
                            fontSize: "9px",
                            lineHeight: "12px",
                            display: "flex",
                            alignItems: "center",
                            letterSpacing: "-0.211789px",
                            marginLeft: "0.25rem",
                            color: `${colors.thinGray}`,
                            paddingBottom: 0,
                          }}
                          type={"text"}
                          required
                          value={item.value}
                          onPress={(e) => updateInputValue(index, item, e)}
                          customInput={{
                            width: "2.125rem",
                            height: "1.375rem",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </TopContainer>
          </ImageEditContainer>
          <>
            {dropdown &&
              dropdown.map((item, index) => {
                return (
                  <DropdownWrapper key={index}>
                    <TopContainerLabel>{item.title}</TopContainerLabel>
                    <ScrollableDropdown
                      width="8.3125rem"
                      options={item.options}
                    />
                  </DropdownWrapper>
                );
              })}
          </>
          <ButtonContainer>
            <Button
              title={constants.SELECT_OVERLAY}
              customButtonTextStyle={{ fontSize: "11px" }}
              customButtonStyle={{
                backgroundColor: colors.dullgrey,
                height: "1.5625rem",
                width: "8.75rem",
                minHeight: "auto",
                padding: "0.375rem 0",
              }}
              onPress={() => {}}
            />
          </ButtonContainer>
        </Inner>
      </Container>
    </Main>
  );
}

export default CommonEdit;
