import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import Button from "../Button";
import Input from "../Input";
import inviteGraphic from "../../assets/backgrounds/inviteGraphic.svg";
import copylinkIcon from "../../assets/icons/copylink.svg";


import greenCursor from "../../assets/icons/green_tag_cursor.png";
import pinkCursor from "../../assets/icons/pink_tag_cursor.png";
import yellowCursor from "../../assets/icons/yellow_tag_cursor.png";

import { VALIDATION_REGEXES } from "../Input/constants";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import Badge from "../Badge";
import { sendInvitation } from "../../services/team.service";
import _ from "lodash";
import { useSelector } from "react-redux";

const emailRegex = VALIDATION_REGEXES.email;


const Wrapper = styled.div`
    position: relative;
    width: 35em;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: ${colors.primeBlack};
    padding: 1em;
    & > div{
        // border: 2px solid;
        margin: 0em 0.5em;
        padding: 0.25em;
        text-align: center;
    }
    .failed-invites{
        text-align: left;
    }
    .failed-invites .list{
        color: #ffae42;
        font-weight: normal;
    }
    .email-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        z-index: 5;
        max-height: 100px;
        overflow-y: auto;
    }
    .pill{
        border: 1px solid;
        padding: 2px 5px;
        border-radius: 15px;
        margin: 0.25em;
        background: white;
    }

`
const DELIMITERS = [' ', ';', ','];
function PageTwo({handleClose, teamId, ...props}) {
    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [failedInvites, setFailedInvites] = useState([]);
    const {teamlist} = useSelector(state => state.teams);
    const addEmailToList = (emailid) => {
        if(emailid === "") return;
        if(!emailRegex.test(emailid)){
            showToast("Invalid email");
            return;
        }
        // check if it's an existing team member
        const currentTeam = teamlist.find(team => team.id === teamId);
        if(currentTeam){
            const existingTeamMember = currentTeam.members.find(teamMember => (teamMember.status === "approved" && teamMember.email === emailid));
            if(existingTeamMember){
                showToast(`Team member exists with email ${emailid}`);
                return;
            }
        }
        if(emailList.find((existingEmail) => existingEmail === emailid)){
            showToast("Email already exists");
            return;
        }
        const newEmailList = emailList;
        newEmailList.push(emailid);
        setEmail("");
    }
    const handleEmailChange = (evt) => {
        const value = evt.target.value;
        if(value.endsWith(" ") || value.endsWith(",") || value.endsWith(";")){
            const emailid = _.trim(value, DELIMITERS);
            addEmailToList(emailid);
            return;
        }
        setEmail(value)
    }

    const handleSubmit = async (evt, addAndSend = false) => {
        if(evt) evt.preventDefault();
        if(email){
            addEmailToList(email);
            if(!addAndSend) return;
        }
        if(emailList.length===0) {
            showToast("Enter team mate email.");
            return;
        } 
        setIsLoading(true);
        const failed = await sendInvitation(emailList, teamId);
        setFailedInvites(failed);
        setIsLoading(false);
        if(failed.length === 0){
            showToast("All invitations sent.", 3000, TOAST_TYPES.success);
            setTimeout(() => {
                handleClose();
            }, 100)
        }
    }

    const handleRemoveEmail = (index) => {
        const newEmailList = emailList.filter((emailid, position) => position !== index);
        setEmailList(newEmailList);
    }

    return ( 
        <Wrapper>
            <img src={inviteGraphic} alt="invite-background" style={{position: "absolute", top: "-10%", left: "10%"}} />
            <img src={greenCursor} alt="cursor-green" style={{position: "absolute", top: "-10%", left: "60%"}} />
            <img src={pinkCursor} alt="cursor-pink" style={{position: "absolute", top: "60%"}} />
            <img src={yellowCursor} alt="cursor-yellow" style={{position: "absolute", top: "80%", left: "90%"}} />
            <div className="heading" style={{marginTop: "1em"}}>Invite your team</div>
            {/* <div className="subheading">The go to collaborative design tool for<br />architecture teams</div> */}
            <div style={{marginTop: "5em"}}>Add collaborators to design together </div>
            <div style={{zIndex: 5, marginTop: "1em"}}>
                {/* <div className="button-wrapper">
                    <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.red, border: "1px solid #EB1E45", borderRadius: "5px"}} title={"Copy Invite Link"} onPress={() => {alert("Copy link")}} />
                </div> */}
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    <Input autoFocus={true} type="text" placeholder="Or add collaborators emails, separated by commas" value={email} onPress={handleEmailChange} customInput={{textAlign: "center", zIndex: 2}} />
                </form>
            </div>
            {
                failedInvites.length > 0 && 
                <div className="failed-invites">
                    <div>Could not invite</div>
                    <div className="list">
                    {
                        failedInvites.map((failedInvite, index) => {
                            return <div key={failedInvite.split(" ")}>{failedInvite}</div>
                        })
                    }
                    </div>
                </div>
            }
            <div className="email-list">
                {
                    emailList.map((emailid, index) => {
                        return <div className="pill" key={`${emailid}-${index}`}>{emailid} <span style={{cursor: "pointer"}} onClick={() => {handleRemoveEmail(index)}}>&#10006;</span></div>
                    })
                }
            </div>
            <div className="actions" style={{marginTop: "3em"}}>
                <div className="button-wrapper">
                    <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} title={"Skip for now"} onPress={handleClose} />
                </div>
                <div className="button-wrapper">                    
                    <Button isLoading={isLoading} primary={true} title={"Send Invites"} onPress={() => {handleSubmit(null, true)}} />
                </div>
            </div>
            <div className="page-indicator" style={{marginTop: "2em"}}>
                <span className="indicator"></span>
                <span className="indicator active"></span>
            </div>
        </Wrapper>
    );
}

export default PageTwo;