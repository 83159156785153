import BABYLON from "../babylonDS.module";
import { ComponentManager } from "../componentManager/componentManager";
import Cursor3d from "../snaptrudeDS/cursor3d.ds";

const CONST_COLORS = [
    '00b1ff',
    '1bc933',
    '8f00ff',
    '10c1b4',
    '0049ff',
    '571db2',
    'c90fea',
    'ff00ff',
    'ff0642',
    'ff6700',
    'ffc200',  
];

class CursorFactory{
    constructor(){
        if(CursorFactory._instance){
            return CursorFactory._instance;
        }
        CursorFactory._instance = this;

        this.cursors = {};
        // this.addDefaultColorCursors();
        // this.loadDefaultColorCursors();
    }

    addDefaultColorCursors(){
        for(let i = 0; i < CONST_COLORS.length; i++){
            this.cursors[CONST_COLORS[i]] = new Cursor3d(CONST_COLORS[i]);
        }
    }

    loadDefaultColorCursors(){
        const componentDataArray = [];
        for(const color in this.cursors){
            const componentData = {
                rootUrl: this.cursors[color].rootUrl,
                name: this.cursors[color].color,
            };
            componentDataArray.push(componentData);
        }
        ComponentManager.addTask(componentDataArray, ComponentManager.FORMAT.BABYLON);
        ComponentManager.load(this.loadMultipleColorCursorsCallback);
    }

    loadMultipleColorCursorsCallback(tasks){
        try{
            if(tasks && tasks.length){
                for(let i = 0; i < tasks.length; i++){
                    const mesh = tasks[i].loadedMeshes[0];
                    mesh.name = tasks[i].name;
                    mesh.type = 'cursor3d';
                    mesh.isVisible = false;
                    const cursorFactory = new CursorFactory();
                    cursorFactory.getCursorOfColor(mesh.name).setMesh(mesh);

                    mesh.convertToFlatShadedMesh();
                    mesh.showBoundingBox = false;
                    if (mesh.material) {
                        mesh.material.backFaceCulling = false;
                        mesh.material.diffuseColor = BABYLON.Color3.FromHexString(`#${mesh.name}`);
                    }
                    mesh.computeWorldMatrix(true);

                    // Use this once we start using instances
                    // markMeshAsThrowAway(mesh);
                    // mesh.scaling = BABYLON.Vector3.Zero();
                    // mesh.position = new BABYLON.Vector3(10000, 10000, 10000);
                }
            }
        } catch(err){
            console.log(`Error in loading 3D Cursor ${err}`);
        } finally{
            ComponentManager.reset();
        }
    }

    getCursorOfColor(color){
        return this.cursors[color];
    }

    deactivateAll(){
        for(const color in this.cursors){
            this.cursors[color].deactivate();
        }
    }

    destroy(){
        CursorFactory._instance = undefined;
        this._instance = undefined;
    }
}

export { CursorFactory };
export default CursorFactory;
