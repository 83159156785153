import axios from "axios";
import { getFormData } from "./helper";
import { ORIGIN } from "./url.constants";

export const universalSearch = async (search) => {
    const endpoint = "/search";
    const data = {
        search: search
    }
    const formData = getFormData(data);
    try {
        const resp = await axios.post(ORIGIN + endpoint, formData);
        return {status: "success", teams: resp.data.teams, user: resp.data.user, searchedText: search}
    } catch (error) {
        const returnObject = {
            status: "error",
            statusCode: null,
            message: "Something went wrong"
        }
        if(error && error.response){
            returnObject.statusCode = error.response.status;
            returnObject.message = error.response.data?.error || returnObject.message;
        }
        return returnObject;
    }
}