import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { updateButtonColor } from "./sketch/updateEventModes.js";
import { serializeScene } from "./toolbarEvents.js";
import { imageURL } from "./apiCalls/backendCalls.js";
import { ORIGIN } from "../../services/url.constants.js";
import axios from "axios";
import { djangoUrl } from "../../services/url.constants";
import { updateExportProgressBar } from "../../containers/genericModal/export.js";
import { showToast, TOAST_TYPES } from "../modules/extrafunc.js";
import JSZip from "jszip";
import { FakeProgressEvent } from "../modules/FakeProgressEvent/FakeProgressEvent";

function createBabylon() {
  var serializedScene = BABYLON.SceneSerializer.Serialize(store.scene);
  serializedScene.geometries.editor_scale = store.editor_scale / 0.254;
  serializedScene.geometries.floor_height =
    store.floor_height / store.unit_scale;
  serializedScene.geometries.mass_mode = $("#mass_mode").prop("checked");
  serializedScene.fplan_mode = store.fplan_mode;
  console.log(serializedScene.materials);
  for (var p = 0; p < serializedScene.meshes.length; p++) {
    if (serializedScene.meshes[p].name.indexOf("wall") != -1) {
      serializedScene.meshes[p].name = "wall";
      serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
    }
  }
  var strScene = JSON.stringify(serializedScene);
  return strScene;
}

$("#skp").click(function () {
  // if($('#mass_mode').prop('checked')){
  var serializedScene = BABYLON.SceneSerializer.Serialize(store.scene);
  serializedScene.geometries.editor_scale = store.editor_scale / 0.254;
  serializedScene.geometries.floor_height =
    store.floor_height / store.unit_scale;
  serializedScene.geometries.mass_mode = $("#mass_mode").prop("checked");
  serializedScene.fplan_mode = store.fplan_mode;
  for (var p = 0; p < serializedScene.meshes.length; p++) {
    if (serializedScene.meshes[p].name.indexOf("room") != -1) {
      serializedScene.meshes[p].name = "mass";
      serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
    }
  }
  var strScene = JSON.stringify(serializedScene);
  send_scene_serialize_data(strScene);
  // }
});

$("#vr").click(function () {
  updateButtonColor("vr");
  if (document.getElementById("vr").style.backgroundColor != "") {
    var camera = new BABYLON.VRDeviceOrientationFreeCamera(
      "Camera",
      new BABYLON.Vector3(0, 3, 0),
      store.scene
    );
    // var camera = new BABYLON.WebVRFreeCamera("camera1", new BABYLON.Vector3(45, 30, -45), store.scene, false, { trackPosition: true });
    var targMesh = store.scene.meshes[0];
    for (var i = 0; i < store.scene.meshes.length; i++) {
      if (store.scene.meshes[i].name.indexOf("axis") == -1) {
        if (store.scene.meshes[i].name.indexOf("ground1") == -1) {
          targMesh = store.scene.meshes[i];
        }
      }
    }
    camera.deviceScaleFactor = 1;
    store.newScene.activeCamera = camera;
    camera.attachControl(store.canvas, true);
    camera.setTarget(targMesh.position);

    store.newScene.activeCamera.inertia = 0.2;
    // store.newScene.activeCamera.inertialPanningY = 0.00002;
    store.newScene.activeCamera.panningSensibility = 50;
    store.newScene.activeCamera.inertialPanningX = 0.0001;
    store.newScene.activeCamera.inertialPanningY = 0.0001;
    store.newScene.activeCamera.panningInertia = 0.2;
    store.newScene.activeCamea.applyGravity = true;

    // required for chrome
    // store.scene.onPointerDown = function () {
    //     store.scene.onPointerDown = undefined;
    //     store.newScene.activeCamera = camera;
    //     camera.attachControl(canvas, true);
    //     camera.controllers.forEach((gp) => {
    //         console.log(gp);
    //         let mesh = gp.hand === 'right' ? rightBox : leftBox;
    //
    //         gp.onPadValuesChangedObservable.add(function (stateObject) {
    //             let r = (stateObject.x + 1) / 2;
    //             let g = (stateObject.y + 1) / 2;
    //             mesh.material.diffuseColor.copyFromFloats(r, g, 1);
    //         });
    //         gp.onTriggerStateChangedObservable.add(function (stateObject) {
    //             let scale = 2 - stateObject.value;
    //             mesh.scaling.x = scale;
    //         });
    //         // oculus only
    //         /*gp.onSecondaryTriggerStateChangedObservable.add(function (stateObject) {
    //             let scale = 2 - stateObject.value;
    //             mesh.scaling.z = scale;
    //         });*/
    //         gp.attachToMesh(mesh);
    //     });
    // }
  } else {
    var camera3 = new BABYLON.ArcRotateCamera(
      "ArcRotateCamera3",
      0.5,
      0,
      15,
      BABYLON.Vector3.Zero(),
      store.newScene
    );
    store.newScene.activeCamera = camera3;
    camera3.attachControl(store.canvas, true, false);
    store.canvas.addEventListener("pointerdown", () => {console.log("SHOULD CALL ON POINTER DOWN")}, false);
  }
});

$("#download").click(function () {
  $("#export_download_button").text("Downloading...");
  // if($('#mass_mode').prop('checked')){
  var serializedScene = BABYLON.SceneSerializer.Serialize(store.scene);
  serializedScene.geometries.editor_scale = store.editor_scale / 0.254;
  serializedScene.geometries.floor_height =
    store.floor_height / store.unit_scale;
  serializedScene.geometries.mass_mode = $("#mass_mode").prop("checked");
  serializedScene.fplan_mode = store.fplan_mode;
  for (var p = 0; p < serializedScene.meshes.length; p++) {
    if (serializedScene.meshes[p].type) {
      if (serializedScene.meshes[p].type.toLowerCase() === "mass") {
        serializedScene.meshes[p].name = "mass";
        serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
      }
    }
  }
  let filename = $("#filename").val();
  var strScene = JSON.stringify(serializedScene);
  send_scene_serialize_data(strScene, filename);
  // }
});

var downloadTrudeFile = function (filename) {
  $("#export_download_button").text("Downloading...");
  // if($('#mass_mode').prop('checked')){
  var serializedScene = serializeScene();
  // serializedScene.geometries.editor_scale = store.editor_scale / 0.254;
  // serializedScene.geometries.floor_height = store.floor_height / store.unit_scale;
  // serializedScene.geometries.mass_mode = $('#mass_mode').prop('checked');
  // serializedScene.fplan_mode = store.fplan_mode;
  // for (var p = 0; p < serializedScene.meshes.length; p++) {
  //     if (serializedScene.meshes[p].type) {
  //         if (serializedScene.meshes[p].type.toLowerCase() === 'mass') {
  //             serializedScene.meshes[p].name = "mass";
  //             serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
  //         }
  //     }
  //
  // }

  var strScene = JSON.stringify(serializedScene);
  send_scene_serialize_data(strScene, filename);
};

function send_scene_serialize_data(data, filename) {
  store.url = store.serverURL;
  $("#export_download_button").text("Downloading...");
  $.ajax({
    url: store.serverURL,
    data: {
      mode1: data,
      mode2: "scene_data",
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      // console.log(data);
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", window.location.origin + "/" + data);
      dlAnchorElem.setAttribute("download", filename + ".trude");
      dlAnchorElem.click();
      $("#export_download_button").text("Submit");
      // window.open(window.location.origin+"/"+data);
    },
    dataType: "json",
    type: "POST",
  });
}

function send_scene_serialize_data_for_download_obj(data, filename) {
  const fdata = {
    mode1: data,
    mode2: "obj_download_obj",
  }
  const formData = new FormData();
  for(let item in fdata){
    formData.append(item, fdata[item]);
  }
  const EXPORT_URL = ORIGIN + "/model/" + store.floorkey + "/";
  axios.post(EXPORT_URL, formData, {responseType: "json"})
  .then(response => {
    console.log(response);
    const {data} = response;
    if(!data) console.log("Error in requesting download file response.");
    else{
      const path = String(data);
      const dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", window.location.origin + path);
      dlAnchorElem.setAttribute("download", filename + ".zip");
      dlAnchorElem.click();
    }
  })
  .catch(err => {
    console.log("Error in requesting download file for obj", err);
  })
  // store.url = store.serverURL;
  // $("#export_download_button").text("Downloading...");
  // $.ajax({
  //   url: store.url,
  //   data: {
  //     mode1: data,
  //     mode2: "obj_download_obj",
  //     csrfmiddlewaretoken: store.csrf_token,
  //   },
  //   success: function (data) {
  //     var path = String(data);
  //     // path = path.split('..\\')[1];
  //     // console.log(path);

  //     var dlAnchorElem = document.getElementById("downloadAnchorElem");
  //     dlAnchorElem.setAttribute("href", window.location.origin + path);
  //     dlAnchorElem.setAttribute("download", filename + ".zip");
  //     dlAnchorElem.click();
  //     $("#export_download_button").text("Submit");
  //     // window.open(window.location.origin+"/"+data);
  //   },
  //   dataType: "json",
  //   type: "POST",
  // });
}

function send_scene_serialize_data_for_download_fbx(data, filename) {
  const fdata = {
    mode1: data,
    mode2: "obj_download_fbx",
  }
  const formData = new FormData();
  for(let item in fdata){
    formData.append(item, fdata[item]);
  }
  const EXPORT_URL = ORIGIN + "/model/" + store.floorkey + "/";
  axios.post(EXPORT_URL, formData, {responseType: "json"})
  .then(async response => {
    console.log(response);
    const {data} = response;
    if(!data) console.log("Error in requesting download file response.");
    else{
      const path = String(data);
      const blobURL = djangoUrl + path;
      const blob = await fetch(blobURL).then(r => r.blob());
      const url = window.URL.createObjectURL(blob);
      const dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", url);
      dlAnchorElem.setAttribute("download", filename + ".zip");
      dlAnchorElem.click();
    }
  })
  .catch(err => {
    console.log("Error in requesting download file for fbx", err);
  })
  // $("#export_download_button").text("Downloading...");
  // store.url = store.serverURL;
  // // console.log(data);
  // $.ajax({
  //   url: store.url,
  //   data: {
  //     mode1: data,
  //     mode2: "obj_download_fbx",
  //     csrfmiddlewaretoken: store.csrf_token,
  //   },
  //   success: function (data) {
  //     var path = String(data);
  //     // path = path.split('..\\')[1];
  //     // console.log(path);
  //     var dlAnchorElem = document.getElementById("downloadAnchorElem");
  //     dlAnchorElem.setAttribute("href", window.location.origin + path);
  //     dlAnchorElem.setAttribute("download", filename + ".zip");
  //     dlAnchorElem.click();
  //     $("#export_download_button").text("Submit");
  //     // window.open(window.location.origin+"/"+data);
  //   },
  //   error: function (err) {
  //     console.log(err);
  //   },
  //   dataType: "json",
  //   type: "POST",
  // });
}

function send_scene_serialize_data_for_download_3ds(data, filename) {
  const fdata = {
    mode1: data,
    mode2: "obj_download_3ds",
  }
  const formData = new FormData();
  for(let item in fdata){
    formData.append(item, fdata[item]);
  }
  const EXPORT_URL = ORIGIN + "/model/" + store.floorkey + "/";
  axios.post(EXPORT_URL, formData, {responseType: "json"})
  .then(response => {
    console.log(response);
    const {data} = response;
    if(!data) console.log("Error in requesting download file response.");
    else{
      const path = String(data);
      const dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", window.location.origin + path);
      dlAnchorElem.setAttribute("download", filename + ".zip");
      dlAnchorElem.click();
    }
  })
  .catch(err => {
    console.log("Error in requesting download file for 3ds", err);
  })
  // store.url = store.serverURL;
  // $("#export_download_button").text("Downloading...");
  // $.ajax({
  //   url: store.url,
  //   data: {
  //     mode1: data,
  //     mode2: "obj_download_3ds",
  //     csrfmiddlewaretoken: store.csrf_token,
  //   },
  //   success: function (data) {
  //     var path = String(data);
  //     // path = path.split('..\\')[1];
  //     // console.log(path);
  //     let filename = "SOMEFILENAME";
  //     var dlAnchorElem = document.getElementById("downloadAnchorElem");
  //     dlAnchorElem.setAttribute("href", window.location.origin + path);
  //     dlAnchorElem.setAttribute("download", filename + ".zip");
  //     dlAnchorElem.click();
  //     // window.open(window.location.origin+"/"+data);
  //     $("#export_download_button").text("Submit");
  //   },
  //   dataType: "json",
  //   type: "POST",
  // });
}

function exportModel(trudeData, filename, exportType) {
  return new Promise((resolve, reject) => {
    const data = {
      floorkey: store.floorkey,
      userId: store.infoUser.id,
      exportType: exportType,
    }

    const zip = new JSZip();
    zip.file("Scene.trude", trudeData);

    zip.generateAsync({type: "blob", compression: "DEFLATE"})
      .then((blobInput) => {
        const formData = new FormData();
        for (const item in data) {
          formData.append(item, data[item]);
        }
        formData.append("mode1", blobInput);

        const EXPORT_URL = ORIGIN + "/exportmodel/";
        axios.post(EXPORT_URL, formData, {
          responseType: "json",
          // onUploadProgress: (progressEvent) => {
          //   updateExportProgressBar(parseInt((progressEvent.loaded / progressEvent.total) * 20))
          // }
        })
          .then(async response => {
            const {data} = response;
            if (!data) console.log("Error in requesting download file response.");
            else {
              const path = String(data);
              const blobURL = ORIGIN + path;
              const blob = await fetch(blobURL).then(r => r.blob());
              const url = window.URL.createObjectURL(blob);
              const dlAnchorElem = document.getElementById("downloadAnchorElem");
              dlAnchorElem.setAttribute("href", url);
              dlAnchorElem.setAttribute("download", filename + ".zip");
              dlAnchorElem.click();

              updateExportProgressBar(100, 'Exporting...');
              const evtFound = FakeProgressEvent.find("exportProgress");
              if (evtFound) evtFound.stop();
              // setTimeout(() => { }, 5000);
              updateExportProgressBar(0, 'Exporting...', false);
            }
          })
          .catch(err => {
            console.log("Error in requesting download file", err);
            const evtFound = FakeProgressEvent.find("exportProgress");
            if (evtFound) evtFound.stop();
            updateExportProgressBar(0, 'Exporting...', false);
            let messageDuration = 3000;
            showToast('Error in requesting download file', messageDuration, TOAST_TYPES.error);
          })
        const evtFound = FakeProgressEvent.find("exportProgress");
        if (evtFound) evtFound.stop();
        const size = blobInput.size / 1024 / 1024;
        let duration;
        if (exportType === "fbx") {
          duration = size > 0 && size < 10 ? 22 : size > 10 && size < 15 ? 30 : 140;
        }
        else {
          duration = size > 0 && size < 3 ? 40 : size > 3 && size < 10 ? 120 : 240;
        }
        const fakeProgressEvent = new FakeProgressEvent(5, 95, "Exporting...",
          duration, "exportProgress", updateExportProgressBar);
        fakeProgressEvent.start();
      });
    resolve();
  });
}

function getRenderAPI(data, res, sky) {
  let renderURL = "/render-cycles/";
  $.ajax({
    url: renderURL,
    data: {
      floor_key: store.floorkey,
      trude_data: data,
      res: res,
      sky: sky,
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (rsp) {
      let dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", window.location.origin + imageURL);
      dlAnchorElem.setAttribute("download", "render.png");
      dlAnchorElem.click();
    },
    error: function (err) {
      console.log(err);
      // let imageURL = err.responseText.slice(2);
      // let dlAnchorElem = document.getElementById('downloadAnchorElem');
      // dlAnchorElem.setAttribute("href", window.location.origin + imageURL);
      // dlAnchorElem.setAttribute("download", 'render.png');
      // dlAnchorElem.click();
    },
    dataType: "application/json",
    type: "POST",
  });
}
export {
  createBabylon,
  downloadTrudeFile,
  send_scene_serialize_data,
  send_scene_serialize_data_for_download_obj,
  send_scene_serialize_data_for_download_fbx,
  send_scene_serialize_data_for_download_3ds,
  exportModel,
  getRenderAPI,
};
