import reduxStore from "../../stateManagers/store/reduxStore";
import BABYLON from "../babylonDS.module";
import { store } from "../utilityFunctions/Store";
import CursorFactory from "./cursorFactory";

class CameraCursor{
    constructor(){
        return;
        if(CameraCursor._instance){
            return CameraCursor._instance;
        }
        CameraCursor._instance = this;

        this.socket = undefined;
        this.brainstorm = false;
    }

    updateSocket(socket){
        return;
        this.socket = socket;
        this.updateSocketIncoming();
    }

    updateSocketIncoming(){
        return;
        if(this.socket){
            this.socket.on('incamera', this.incomingCamera);
        }
    }

    onCameraUpdate(){
        return;
        if(this.socket){
            this.socket.emit('outcamera', this.getBroadcastCameraDetails());
        }
    }

    incomingCamera(cameraData){
        return;
        if(!this.brainstorm && cameraData){
            const color = cameraData.user.color;
            const position = cameraData.camera.position;
            const cursorFactory = new CursorFactory();
            const cursor = cursorFactory.getCursorOfColor(color);
            cursor.updatePosition(new BABYLON.Vector3(position[0], position[1], position[2]));
            cursor.activate();
        }
    }

    onCameraChangeView(newView){

    }

    getSelfUserDetails(){
        const { activeUsers } = reduxStore.getState();
        return activeUsers.self;
    }

    getActiveUsers(){
        const { activeUsers } = reduxStore.getState();
        return activeUsers;
    }

    getColorOfUser(userId){
        const activeUsers = this.getActiveUsers();
        const list = [];
        list.push(activeUsers.self);
        list.push(...activeUsers.others);
        const user = list.filter(userList => userList.user.id == userId);
        if(user && user[0] && user[0].color){
            return user[0].color;
        } else {
            return null;
        }
    }

    getBroadcastCameraDetails(){
        const broadcastObject = {
            user: {
                user: {
                    id: this.getSelfUserDetails().user.id,
                    email: this.getSelfUserDetails().user.email,
                    name: this.getSelfUserDetails().user.name
                },
                color: this.getSelfUserDetails().color,
            },
            camera: {
                position: store.scene.activeCamera.position.asArray()
            },
            mouse: null,
        };
        return broadcastObject;
    }


    goIntoBrainStorm(){
        return;
        this.brainstorm = true;
        const cursorFactory = new CursorFactory();
        cursorFactory.deactivateAll();
    }

    goOutOfBrainStorm(){
        return;
        this.brainstorm = false;
    }

    isBrainStormActive(){
        return this.brainstorm;
    }

    destory(){
        this.socket = undefined;
    }
}

export { CameraCursor };
export default CameraCursor;
