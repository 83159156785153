import BABYLON, { AdvancedDynamicTexture, Checkbox, TextBlock } from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { StateMachine } from "../modules/Classes/StateMachine.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import {
  findSecondarySnappedPoint,
  findPrioritizedSnapPoint,
} from "./snapFuncsPrimary.js";
import {
  isPointerOverGUIElement,
  checkOperationBIMFlowConditions,
  getEmptyFunction,
  removeMeshFromStructure,
  getBabylonGUIElementByName,
  markMeshAsThrowAway,
  showToast,
  isPointInTheVicinityOfMesh,
  isMeshThrowAway, areTwoMeshesCloseBy, getComponentInTheVicinityOfThisComponent, isMeshOrphanProof, updateCSG,
} from "../modules/extrafunc.js";
import { nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import {drawSelectionBox, drawSelectionBoxRotate, removeSelectionBox} from "./meshEvents.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import {
  disposeHighlightedVertex,
  disposeHightlightedEdge,
  disposeHighlightedFace,
  disposeSnapToObjects,
  disposeAllAxisLines, handleVisibilityChanges, updatePosition,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import { delayedExecutionEngine } from "../modules/utilityFunctions/delayedExecution.js";
import { pasteObject } from "./defaultEvents.js";
import {
  handleOptionsForAlternateSnaps,
  initializeSnappingEngine,
  turnOffSnappingEngine,
} from "./snapUtilities.js";
import { setLayerTransperancy } from "./sceneFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { meshObjectMapping } from "../modules/snaptrudeDS/mapping.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { Mass } from "../modules/snaptrudeDS/mass.ds.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { virtualSketcher } from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import {
  isNumberKey,
  isBackspaceKey,
  isArrowKey,
  isEnterKey,
  isForwardSlashKey,
  isEscapeKey, isTabKey, handleTabKey,
} from "./keyEvents.js";
import { updateRoofAccordion } from "./roofVisibilityFuncs.js";
import { Feature } from "../modules/Classes/Feature.js";
import { isSnaptrudePlaneMesh } from "./snapUtilities.js";
import { meshUniqueIdMapper } from "../modules/utilityFunctions/meshUniqueIdMapper.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
import {projectionOfPointOnFace} from "./snapFuncs";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler";
import snapEngine from "../modules/snappingEngine/snapEngine";
import movementConstrainer from "../modules/meshoperations/moveOperations/movementConstrainer";

/*

_level0Meshes are the ones sitting where the original selected meshes were. They can be the same meshes
or the instancesToReplaceOriginals, depending on what mesh was selected and uniqueness toggle mode
_level1Meshes are the ones moving with the pointer. Could be instances or unique meshes

_meshOfInterest and _sourceMeshLeftBehind are the respective entries for the clicked mesh in level1 and level0

None of the modifications apply to children, since move is applied to them implicitly and even clone() is recursive

Regarding command generation and saving-
Using delayedExecutionEngine to execute these. At any point, the executable for the engine,
works on the array of _newlyCreatedMeshes. No commands are created in between. No commands for move or for toggle.
They're all created at once, as if they were created at the final position.
Only other commands are for throwAway and propertyChange

Useful properties-
1. mesh.clones is a property on every mesh in _level0Meshes, it is used to set distance between clones
2. mesh.wasClonedFrom is a property on level1 and subsequent meshes to identify their corresponding level0 mesh
3. level0Mesh.isAReplacer is used to determine if a level0 mesh is a replaced instance during pasteObject or original


EVENT #4 is a secondary state. The code is executed but the state is never set to 4.
It decomposes to other primary states 0 -> 3

*/

const arrayFunctionOperator = (function () {
  let _EVENTS = {
    0: cleanUp,
    1: _initialGripCreation,
    2: _createCopy,
    3: _moveMesh,
    4: _prepareForCloneMesh,
  };

  let _mouseSequence = {
    pointermove: {
      0: [1, 1, 0],
      1: [1, 1, 0],
      2: [3, 3, 0], // redundant, state isn't set to 2 anymore
      3: [3, 3, [0, 0, 0]],
    },

    pointerdown: {
      0: [0, 0, 0], // click outside to finish operation
      1: [
        2,
        3, // directly setting state as 3, no need for the system to remain in state 2
        0,
      ],
      3: [4, 0, 0],
    },
  };

  let _touchSequence = {
    pointermove: {
      1: [2, 2, 0],
      2: [3, 3, 0],
      3: [3, 3, [0, 0, 0]],
    },

    pointerdown: {
      0: [1, 1, [0, 0, 0]],
    },

    pointerup: {
      3: [4, 0, 0],
    },
  };

  let STATE_MACHINE = new StateMachine(_EVENTS, _mouseSequence, _touchSequence);

  let _CONSTANTS = {
    operatingSpace: null,
    maxCopiesPerOperation: 100,
    graphUpdateLimit: 100
  };

  const init = () => {
    _CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
  }

  let _meshOfInterest = null;
  let _componentOfInterest = null;
  let _meshWithGrips = null;
  let _meshOfInterestInitialPosition = null;

  let _meshWithNoRestriction = null;
  let _meshWithNoRestrictionInitialPoint = null;

  let _meshOfInterestInitialClickPoint = null;
  let _meshOfInterestOnMovePoint = null;
  let _lastUsedInput = null;
  let _totalNumberOfClones = 0;

  let _sourceMeshLeftBehind = null;
  let _sourceMeshLeftBehindWithNoRestriction = null;

  let _commandPushedForFirstCloneCreation = false;
  let _furnitureBound = "boxScaleFurniture";

  let _linkedListIdMetadata = {};

  let _optionsForThrow = null;
  let _optionsForPropertyChange = null;
  const _newlyCreatedMeshes = [];
  const _firstSetOfClones = [];
  const _firstSetOfClonesWithChildren = [];
  const _throwAwayMeshes = [];
  const _level0Meshes = [];
  const _level1Meshes = [];
  let _level1Components = [];
  const _allMeshesWhosePropertyNeedsToBeChanged = [];
  let _establishedMovementDirection = null;

  let _dimensionLineMesh = null;

  let _roofsCloned = false;
  let _currentPick;

  function _initialGripCreation(pickSnapData) {
    function _displayPrioritizedGrip(pickInfo) {
      _disposeGrips();

      const options = {
        faceSnap: true,
        firstIndicators: true,
        pickInfo: pickInfo,
        storeyCheck: true,
        showRelatedEdges: true,
      };

      let snaptrudeDS = pickInfo.pickedMesh.getSnaptrudeDS(false);
      if (!!snaptrudeDS && snaptrudeDS.type.toLowerCase() === "mass" && snaptrudeDS.isCircularMass()) {
        options.showRelatedEdges = false;
      }

      handleOptionsForAlternateSnaps(pickSnapData, options);

      const snappedPoint = findSecondarySnappedPoint(null, null, null, options);

      if (snappedPoint) {
        if (store.selectionStack.length) {
          if (
              !store.selectionStack.includes(options.metadata.pickInfo.pickedMesh)
          ) {
            // original pick was twoPlane, so have to check if this new pickedMesh
            // is present in store.selectionStack or not
            if (pickInfo.pickedMesh.name.includes("twoPlane")) {
              _disposeGrips();
              return Promise.reject();
            } else {
              // not entirely sure if something different needs to happen here
              _disposeGrips();
              return Promise.reject();
            }
          }
        }

        pickInfo = options.metadata.pickInfo;
        //because if overlapping masses were handled, pickInfo would be different from what was passed

        _currentPick = pickInfo;

        _meshWithGrips = options.metadata.pickInfo.pickedMesh;
        _meshOfInterestInitialClickPoint = snappedPoint;


        return Promise.resolve();
      }

      return Promise.reject();
    }

    if (isPointerOverGUIElement()) {
      _disposeGrips();
      return Promise.reject("ignorePromise");
    }

    let resolved = false;
    return new Promise(async (resolve, reject) => {

      let pickInfo;

      if (pickSnapData){
        pickInfo = pickSnapData.pickInfo;
      }
      else {
        pickInfo = scenePickController.sequentialPick([{
          predicate: (mesh) => {
            return mesh.name === _CONSTANTS.furnitureBound;
          }
        }, {
          predicate : mesh => {
            return !isSnaptrudePlaneMesh(mesh)
                && !mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.cadSketch);
          },
          includeVoids: true,
        }]);

        snapEngine.alternateSnaps.invalidate();
      }

      if (pickInfo.hit) {
        if (store.selectionStack.length) {
          // if twoPlane isn't excluded, compoundPick doesn't happen in recursiveSnap in
          // findSecondarySnappedPoint. So picking vertex becomes difficult
          if (pickInfo.pickedMesh.name.includes("twoPlane")) {
          } else if (!store.selectionStack.includes(pickInfo.pickedMesh)) {
            //can't select something not in selection stack
            _disposeGrips();
            reject();
            return;
          }
        } else {
          if (pickInfo.pickedMesh.type.toLowerCase() === "furniture") {
            _disposeSelectionBox();
            let boundingBox = drawSelectionBoxRotate(pickInfo.pickedMesh);
            boundingBox.name = _furnitureBound;
            boundingBox.type = GLOBAL_CONSTANTS.strings.identifiers.boundingBox;
          }
        }

        let options = {
          operation: "array",
          mesh: pickInfo.pickedMesh,
        };
        if (!checkOperationBIMFlowConditions(options)) {
          _disposeGrips();
          reject();
          return;
        }

        // _meshOfInterest = pickInfo.pickedMesh;
        await _displayPrioritizedGrip(pickInfo).then(() => {
          _meshOfInterestOnMovePoint = _meshOfInterestInitialClickPoint.clone();
          resolved = true;
          // resolve();
        }, getEmptyFunction());
      } else {
        _disposeGrips();
        _disposeSelectionBox();
      }

      if (resolved) resolve();
      else reject();
    });
  }

  function _disposeGrips() {
    disposeHighlightedVertex();
    disposeHightlightedEdge();
    disposeHighlightedFace();
    uiIndicatorsHandler.edgeIndicator.massDestruct();
  }

  function _disposeSelectionBox() {
    let m = store.newScene.getMeshByName(_furnitureBound);
    if (m) {
      m.dispose();
    }
  }
  
  function _clearSelection(){
    store.selectionStack.forEach(m => {
      m.state = "off";
    });
    store.selectionStack.length = 0;
    removeSelectionBox();
  }
  
  function _addToSelection(meshes){
    store.selectionStack.push(...meshes);
    meshes.forEach(m => {
      m.state = "on";
      drawSelectionBox(m);
    });
  }

  function _createCopy() {
    const _populateLinkedListData = function (replaceeDS, replacerDS) {
      const linkedListData = StoreyMutation.replaceElementInDLL(
          replaceeDS,
          replacerDS
      );

      if (!_.isEmpty(linkedListData)) {
        let dllId = replaceeDS.linkedListId;
        if (_linkedListIdMetadata[dllId]) {
          _linkedListIdMetadata[dllId].newData = linkedListData[dllId].newData;
        } else {
          _linkedListIdMetadata[dllId] = linkedListData[dllId];
        }
        _linkedListIdMetadata.structure_id = linkedListData.structure_id;
      }
    };

    if (isPointerOverGUIElement()) {
      return Promise.reject("ignorePromise");
    } else if (delayedExecutionEngine.kitneBacheHai()) {
      delayedExecutionEngine.executeAll();
    }

    if (
        _meshWithGrips.type === GLOBAL_CONSTANTS.strings.identifiers.boundingBox
    )
      _meshWithGrips = _meshWithGrips.parentMesh;

    let selectedMeshes;
    if (_.isEmpty(store.selectionStack)) {
      selectedMeshes = [_meshWithGrips];
    } else {
      const descendants = [];
      store.selectionStack.forEach(m => {
        descendants.push(...m.getChildMeshes());
      });
      
      /*
      This fix relates to how Babylon uses camera.activeMeshes
      If door is placed in 2D, door is there in activeMeshes and not the small floor beneath it
      If placed in 3D and then moved to 2D, it's the opposite
       */
      
      selectedMeshes = store.selectionStack.filter((m) => {
        if (descendants.includes(m)) return false;

        if (!m.isVisible) return false;
        if (m.isDisposed()) return false; // necessary because of a weird case involving auto-interiors
        if(m.name?.includes("terrain")) return false;
        if(m.name?.includes("cad")) return false;
        if(["floorplan", "pdf"].includes(m.type.toLowerCase())) return false;

        return true;
      });
    }

    _throwAwayMeshes.length = 0;

    return new Promise((resolve) => {
      let optionsForPaste = {
        uniqueObject: store.arrayFunctionGlobalVariables.uniqueObjects,
        sendOriginalToInfinity: true,
        arrayOperation: true,
      };

      let checkParent = false;
      let meshForDrawingLineIndicator;
      if (!selectedMeshes.includes(_meshWithGrips)){
        // when _meshWithGrips is a child of a parent also in the selection
        checkParent = true;
      }

      selectedMeshes.forEach((mesh) => {
        const meshDS = mesh.getSnaptrudeDS();
        let clone = pasteObject(mesh, optionsForPaste);
        if (clone) {
          if (clone.instanceToReplaceOriginal) {
            _throwAwayMeshes.push(mesh);
            delete mesh.clones;

            const instanceToReplaceOriginalDS =
                clone.instanceToReplaceOriginal.getSnaptrudeDS();

            instanceToReplaceOriginalDS.groupId = meshDS.groupId;
            // instanceToReplaceOriginalDS.linkedListId = meshDS.linkedListId;
            _populateLinkedListData(meshDS, instanceToReplaceOriginalDS);

            _firstSetOfClones.push(clone.newInstance);
            _firstSetOfClones.push(clone.instanceToReplaceOriginal);
            _level0Meshes.push(clone.instanceToReplaceOriginal);
            _level1Meshes.push(clone.newInstance);

            clone.instanceToReplaceOriginal.isAReplacer = true;
          } else {
            _firstSetOfClones.push(clone);
            _level0Meshes.push(mesh);
            _level1Meshes.push(clone);
          }

          if (mesh === _meshWithGrips || (checkParent && mesh.getChildMeshes().includes(_meshWithGrips))) {
            _sourceMeshLeftBehind = _.last(_level0Meshes);
            _meshOfInterest = _.last(_level1Meshes);
            _componentOfInterest = _meshOfInterest.getSnaptrudeDS();

            meshForDrawingLineIndicator = mesh;
          }

          if (mesh.type.toLowerCase() !== "staircase") {
            if (!_sourceMeshLeftBehindWithNoRestriction) {
              _sourceMeshLeftBehindWithNoRestriction = _.last(_level0Meshes);
              _meshWithNoRestriction = _.last(_level1Meshes);
            }
          }

          if (mesh.type.toLowerCase() === "roof") {
            _roofsCloned = true;
          }
        }
      });

      _level1Components = _level1Meshes.map((m) => m.getSnaptrudeDS());

      _firstSetOfClonesWithChildren.length = 0;
      _firstSetOfClones.forEach(c => {
        _firstSetOfClonesWithChildren.push(c);
        _firstSetOfClonesWithChildren.push(...c.getChildMeshes());
      })

      // store.newScene.activeCamera.detachControl(canvas);

      if (!_sourceMeshLeftBehindWithNoRestriction) {
        _sourceMeshLeftBehindWithNoRestriction = _sourceMeshLeftBehind;
        _meshWithNoRestriction = _meshOfInterest;
      }

      _meshOfInterestInitialPosition = meshForDrawingLineIndicator
          .getAbsolutePosition()
          .clone();
      _meshWithNoRestrictionInitialPoint = _meshWithNoRestriction
          .getAbsolutePosition()
          .clone();

      _totalNumberOfClones = 1;

      initializeSnappingEngine(_meshOfInterestInitialClickPoint);

      if (isMeshOrphanProof(_meshOfInterest)){

        handleVisibilityChanges(_meshOfInterest);
        movementConstrainer.init(_componentOfInterest, _currentPick);
      }

      _showUIHelpers();
      _linkUIHelpers();
      setLayerTransperancy(_sourceMeshLeftBehind);
      uiIndicatorsHandler.edgeIndicator.massDestruct();

      resolve();
    });
  }

  function _moveMesh(snappedPoint) {
    let resolved = false;
    return new Promise((resolve, reject) => {
      disposeSnapToObjects();

      if (!snappedPoint){
        let options = {
          excludedMeshes: _firstSetOfClonesWithChildren,
          wantMetadata: true,
          attemptCadSnaps: true,
        };
  
        if (_meshWithNoRestriction.type === "staircase") {
          options.restrictYAxisMovement = true;
        }
  
        if (_componentOfInterest.dependantMass || isMeshOrphanProof(_meshOfInterest)) {
          options.parallelFaceSnap = true;
        }
  
        snappedPoint = findPrioritizedSnapPoint(
            _meshOfInterestInitialClickPoint,
            null,
            _meshOfInterest,
            options
        );
      }
      

      if (snappedPoint) {
        let movementAmount = snappedPoint.subtract(_meshOfInterestOnMovePoint);

        let component, moveAmount;
        _level1Components.forEach((c) => {
          component = c;
          moveAmount = movementAmount.clone();

          if (component.onElementMove) {
            component.onElementMove(moveAmount);
          }

          if (c === _componentOfInterest){
            _meshOfInterestOnMovePoint.addInPlace(moveAmount);
          }

          // component.mesh.position.addInPlace(moveAmount);
          updatePosition(component, component.mesh, moveAmount);
        });

        let distanceMoved = BABYLON.Vector3.Distance(
            _meshWithNoRestrictionInitialPoint,
            _meshWithNoRestriction.getAbsolutePosition()
        );

        _dimensionLineMesh = DisplayOperation.drawOnMove(
            _meshOfInterestInitialPosition,
            _meshOfInterest.getAbsolutePosition(),
            "",
            "",
            ""
        );
        DisplayOperation.displayOnMove(distanceMoved, "arrayMoveInput", true, {
          inputFocus: true,
        });
        // getBabylonGUIElementByName("arrayMoveInput").linkWithMesh(_sourceMeshLeftBehind);
        _linkUIHelpers();

        resolve();
        resolved = true;
      }

      // if (!resolved) reject();
      resolve();

      // if rejected, the operation stops when moving at particular angles parallel to the ground

    });
  }

  function _toggleUniquenessOfClonesOfTheMesh(level0Mesh, shouldBeUnique) {
    const _isMeshAlwaysAnInstance = function (mesh) {
      const typeLowercase = mesh.type.toLowerCase();

      return (
          typeLowercase === "furniture" ||
          typeLowercase === "door" ||
          typeLowercase === "window"
      );
    };

    let meshesInDifferentClothing = [];

    if (level0Mesh.isAReplacer) meshesInDifferentClothing.push(level0Mesh);

    level0Mesh.clones.forEach((cloneId) => {
      let clone = store.scene.getMeshByUniqueID(cloneId);
      if (_isMeshAlwaysAnInstance(clone)) {
        meshesInDifferentClothing.push(clone);
        return;
      }

      let options = {
        uniqueObject: shouldBeUnique,
        arrayOperation: true,
      };

      let newMesh = pasteObject(level0Mesh, options);
      newMesh.setAbsolutePosition(clone.getAbsolutePosition());
      meshesInDifferentClothing.push(newMesh);

      if (clone === _meshOfInterest) {
        _meshOfInterest = newMesh;
        _componentOfInterest = _meshOfInterest.getSnaptrudeDS();
      } else if (clone === _meshWithNoRestriction) {
        _meshWithNoRestriction = newMesh;
      }

      let newMeshUID = newMesh.uniqueId,
          cloneMeshUID = clone.uniqueId;
      removeMeshFromStructure(clone);
      // meshUniqueIdMapper.update(newMesh, cloneMeshUID);
      // meshObjectMapping.updateMesh(newMeshUID, cloneMeshUID);

      clone.getChildMeshes().forEach((progeny) => {
        removeMeshFromStructure(progeny);
        progeny.dispose();
      });

      clone.dispose();
      level0Mesh.clones.pop();
      // pasteObject pushes the id of the newly created clone

      meshUniqueIdMapper.update(newMesh, cloneMeshUID);
      meshObjectMapping.updateMesh(newMeshUID, cloneMeshUID);
    });

    _newlyCreatedMeshes.push(...meshesInDifferentClothing);
  }

  function _showUIHelpers() {
    _removeUIHelpers();

    store.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
    store.advancedTexture.useInvalidateRectOptimization = false;
    // this stops the UI not responding issue

    let style = store.advancedTexture.createStyle();
    style.fontSize = 12;
    // style.fontStyle = "italic";
    style.fontFamily = "Verdana";
    let _newTextBlock = function (name, text) {
      let element = new TextBlock();
      element.name = name;
      element.text = text;
      element.color = "black";
      element.background = "transparent";
      element.style = style;
      store.advancedTexture.addControl(element);

      return element;
    };

    let _newCheckBox = function (name, observable) {
      let checkbox = new Checkbox();
      checkbox.name = name;
      checkbox.width = "20px";
      checkbox.height = "20px";
      checkbox.isChecked = store.arrayFunctionGlobalVariables.uniqueObjects;
      checkbox.background = "white";
      checkbox.color = "black";

      store.advancedTexture.addControl(checkbox);

      let safeObservable = (checkedState) => {
        // Any exception in observable is causing problems in UI
        try {
          observable(checkedState);
        } catch (e) {
          console.log(e);
        }
      };

      checkbox.onIsCheckedChangedObservable.add(safeObservable);

      return checkbox;
    };

    _newTextBlock("arrayMove", "Distance - ");
    _newTextBlock("arrayClone", "Copies - ");

    _newTextBlock("arrayCheckboxDescription", "Unique - ");

    function _checkBoxObservable(shouldBeUnique) {
      store.arrayFunctionGlobalVariables.uniqueObjects = shouldBeUnique;

      _newlyCreatedMeshes.length = 0;
      _level0Meshes.forEach((m) => {
        _toggleUniquenessOfClonesOfTheMesh(m, shouldBeUnique);
      });
    }

    let checkBox = _newCheckBox("arrayCheckbox", _checkBoxObservable);

    if (["furniture", "door", "window"].includes(_meshOfInterest.type.toLowerCase()))
      checkBox.isEnabled = false;

    _dimensionLineMesh = DisplayOperation.drawOnMove(
        _meshOfInterestInitialPosition,
        _meshOfInterestInitialPosition
    );
    const moveInputBox = DisplayOperation.displayOnMove(
        0,
        "arrayMoveInput",
        false,
        { inputFocus: true }
    );
    const cloneInputBox = DisplayOperation.displayOnMove(
        1,
        "arrayCloneInput",
        false,
        { inputFocus: false }
    );
    getBabylonGUIElementByName("arrayCloneInput").text = 1;
    /* AG-RE: ANGULAR REFERENCE */
    ScopeUtils.getScope().selected_display_unit = null;

    _setObservables(moveInputBox);
    _setObservables(cloneInputBox);
  }

  function _linkUIHelpers() {
    let linker = _dimensionLineMesh;
    // let linker = store.scene.getMeshByName('dimline');
    // let linker = _sourceMeshLeftBehind;
    let arrayMove = getBabylonGUIElementByName("arrayMove");
    let arrayMoveInput = getBabylonGUIElementByName("arrayMoveInput");
    let arrayClone = getBabylonGUIElementByName("arrayClone");
    let arrayCloneInput = getBabylonGUIElementByName("arrayCloneInput");
    let arrayCheckboxDescription = getBabylonGUIElementByName(
        "arrayCheckboxDescription"
    );
    let arrayCheckBox = getBabylonGUIElementByName("arrayCheckbox");
    /* GET CURSOR POSITION TO ATTACH THE INPUT TO CURSOR */

    let offsetY = 20;
    let offsetX = -75;

    arrayMove.linkWithMesh(linker);
    arrayMove.linkOffsetY = -offsetY;
    arrayMove.linkOffsetX = offsetX;

    arrayMoveInput.linkWithMesh(linker);
    arrayMoveInput.linkOffsetY = -offsetY;

    arrayClone.linkWithMesh(linker);
    arrayClone.linkOffsetY = offsetY;
    arrayClone.linkOffsetX = offsetX;

    arrayCloneInput.linkWithMesh(linker);
    arrayCloneInput.linkOffsetY = offsetY;

    arrayCheckboxDescription.linkWithMesh(linker);
    arrayCheckboxDescription.linkOffsetY = 3 * offsetY;
    arrayCheckboxDescription.linkOffsetX = offsetX;

    arrayCheckBox.linkWithMesh(linker);
    arrayCheckBox.linkOffsetY = 3 * offsetY;
  }

  function _removeUIHelpers() {
    if (getBabylonGUIElementByName("arrayMove"))
      getBabylonGUIElementByName("arrayMove").dispose();

    if (getBabylonGUIElementByName("arrayMoveInput"))
      getBabylonGUIElementByName("arrayMoveInput").dispose();

    if (getBabylonGUIElementByName("arrayClone"))
      getBabylonGUIElementByName("arrayClone").dispose();

    if (getBabylonGUIElementByName("arrayCloneInput"))
      getBabylonGUIElementByName("arrayCloneInput").dispose();

    if (getBabylonGUIElementByName("arrayCheckboxDescription"))
      getBabylonGUIElementByName("arrayCheckboxDescription").dispose();

    if (getBabylonGUIElementByName("arrayCheckbox"))
      getBabylonGUIElementByName("arrayCheckbox").dispose();

    DisplayOperation.removeDimensions();
  }

  function _removeAssignedProperties() {
    _level0Meshes.forEach((m) => {
      delete m.clones;
      delete m.isAReplacer;
    });

    _newlyCreatedMeshes.forEach((m) => delete m.wasClonedFrom);
  }

  function _handlePlinthForYMovement(components) {
    // Deleting the Plinth in case the mass is arrayed in the y direction
    components.forEach((component) => {
      _.remove(component.mesh.childrenComp, (mesh) => {
        if (
            mesh.type.toLowerCase() === "mass" &&
            mesh.getSnaptrudeDS().massType === "Plinth"
        ) {
          let plinthMesh = mesh;
          if (
              _.round(
                  plinthMesh.getBoundingInfo().boundingBox.maximumWorld.y,
                  1
              ) !== 0
          ) {
            removeMeshFromStructure(plinthMesh);
            plinthMesh.dispose();
            return true;
          }
        }
      });
    });
  }

  function _handlePlinthForUniqueObjectsCase(components) {
    components.forEach((component) => {
      let drawPlinth = false;
      _.remove(component.mesh.childrenComp, (mesh) => {
        if (Mass.isPlinth(mesh)) {
          removeMeshFromStructure(mesh);
          mesh.dispose();
          drawPlinth = true;
          return true;
        }
      });
      if (drawPlinth) Mass.drawPlinth(component.mesh);
    });
  }

  function _registerDelayedExecutable() {
    delayedExecutionEngine.addExecutable(() => {
      let dllCommand, throwCommand, propertyChangeCommand;
      if (!_.isEmpty(_linkedListIdMetadata)) {
        dllCommand = commandUtils.linkedListOperations.getCommand(
          "updateLinkedListClusterArray",
          _linkedListIdMetadata
        );
      }

      if (_optionsForPropertyChange) {
        const propertyChangeCommandData =
          commandUtils.propertyChangeOperations.getCommandData(
            _allMeshesWhosePropertyNeedsToBeChanged,
            _optionsForPropertyChange
          );

        propertyChangeCommand =
          commandUtils.propertyChangeOperations.getCommand(
            "arrayPropertyChange",
            propertyChangeCommandData
          );
      }

      if (_optionsForThrow) {
        const throwCommandData =
          commandUtils.worldMatrixChangeOperations.getCommandData(
            null,
            _optionsForThrow
          );

        throwCommandData.linkedListIdMetadata = _linkedListIdMetadata;

        throwCommand = commandUtils.worldMatrixChangeOperations.getCommand(
          "throwAway",
          throwCommandData,
          _optionsForThrow
        );
      }

      handleVisibilityChanges(_meshOfInterest, true);

      let optionsCreation = {
        preserveChildren: false,
        postUnExecuteCallback: _removeUIHelpers,
      };

      const _newlyCreatedComponents = _newlyCreatedMeshes.map((m) =>
        m.getSnaptrudeDS()
      );

      _handlePlinthForYMovement(_newlyCreatedComponents);

      if (store.arrayFunctionGlobalVariables.uniqueObjects) {
        _handlePlinthForUniqueObjectsCase(_newlyCreatedComponents);
      }

      try {
        StoreyMutation.assignStorey(_newlyCreatedComponents);
      } catch (e) {
        console.error("Storey assignment during array failed");
        console.error(e);
      }
      
      let newChildren = [], newParents = [];
      let parentingCommand;
      let updateCSGCommands = {
        creationCommands: [],
        deletionCommands: [],
        dllCommands: [],
      };

      _newlyCreatedComponents.forEach(c => {
        const mesh = c.mesh;
        if (mesh.parent){

          const currentParent = mesh.parent;
          mesh.setParent(null);

          // just executing the creation command does not add the mesh to childrenComp of parent,
          // so relationship is not re-established after reload
          // so executing parentChildOperations command explicitly for the child copies

          if (areTwoMeshesCloseBy(mesh, currentParent)){
            newChildren.push(mesh);
            newParents.push(currentParent);
          }
          else {
            /*const nearBy = getComponentInTheVicinityOfThisComponent(c);
            if (nearBy) newParents.push(nearBy?.mesh);
            else {
              if (isMeshOrphanProof(mesh)){
                newParents.push(_.last(newParents));
              }
              else newParents.push(null);
            }*/

            if (isMeshOrphanProof(mesh)){
              newChildren.push(mesh);
              newParents.push(_.last(newParents));
            }
            // else newParents.push(null);
          }
        }
      });
      
      let creationCommandData = commandUtils.creationOperations.getCommandData(
        _newlyCreatedMeshes,
        null,
        optionsCreation
      );
      
      let creationCommand = commandUtils.creationOperations.getCommand(
        "arrayOperation",
        creationCommandData,
        optionsCreation
      );

      _throwAwayMeshes.forEach(mesh => {
        if (mesh.parent){
          newChildren.push(mesh);
          newParents.push(null);
        }
      });

      if (!_.isEmpty(newChildren)){
        parentingCommand = commandUtils.parentChildOperations.expressCheckout(
          "arrayChildParent",
          newChildren,
          newParents
        );

        const childrenWithUniqueParents = _.uniqBy(newChildren, c => c.parent);
        // if this is not done, if a void is copied 10 times, unnecessarily ~20 updateCSG commands are created.
        // now it'll be just 2

        childrenWithUniqueParents.forEach(mesh => {
          if (!mesh.parent) return;

          const optionsForCSG = {};
          optionsForCSG.updatePosition = false;
          optionsForCSG.rePosition =
            mesh.parent.absolutePosition.asArray();
          optionsForCSG.reScale =
            mesh.parent.absoluteScaling.asArray();

          let updateCommands = updateCSG(mesh, optionsForCSG);
          if (updateCommands) {

            updateCSGCommands.creationCommands.push(
              ...updateCommands.creationCommands
            );
            updateCSGCommands.deletionCommands.push(
              ...updateCommands.deletionCommands
            );
            updateCSGCommands.dllCommands.push(
              ...updateCommands.dllCommands
            );
          }
        });
      }

      let integrationGeometryChangeCommand;

      // copying a 2000 mass structure takes ~4 minutes if geometry update is true,
      // which is also totally unnecessary in that case
      if (_newlyCreatedComponents.length > _CONSTANTS.graphUpdateLimit) virtualSketcher.addWithoutGeometryEdit(_newlyCreatedComponents);
      else integrationGeometryChangeCommand = virtualSketcher.addWithGeometryEdit(_newlyCreatedComponents);

      let nonCSGCommands = _.compact([
        creationCommand,
        parentingCommand,
        throwCommand,
        dllCommand,
        propertyChangeCommand,
        integrationGeometryChangeCommand,
      ]);

      const allCommands = [
        ...nonCSGCommands,
        ...updateCSGCommands.creationCommands,
        ...updateCSGCommands.deletionCommands,
        ...updateCSGCommands.dllCommands,
      ];

      const yetToExecutes = [
        ...nonCSGCommands.map(() => false),
        ...updateCSGCommands.creationCommands.map(() => false),
        ...updateCSGCommands.deletionCommands.map(() => true),
        ...updateCSGCommands.dllCommands.map(() => false),
      ];

      CommandManager.execute(allCommands, yetToExecutes);

      _cleanUpExceptInitialGripCreationData();
    }, "arrayRecordOperationExecutable");
  }

  function _prepareForCloneMesh() {
    _disposeGrips();
    disposeAllAxisLines();
    disposeSnapToObjects();
    
    if(!_.isEmpty(store.selectionStack)){
      _clearSelection();
      _addToSelection(_level0Meshes);
    }
    
    setLayerTransperancy(_meshOfInterest);

    if (!_commandPushedForFirstCloneCreation) {
      if (_.isEmpty(_throwAwayMeshes)) {
      } else {
        /*
        mesh.getChildMeshes(), unlike mesh.getChildren() returns all progeny by default
         */

        const _postExecuteCallback = function () {
          let data = this.data;
          let stack = [];
          data.forEach((dataPoint) => {
            let meshOfInterest = store.scene.getMeshByUniqueID(
                dataPoint.meshId
            );
            if (meshOfInterest) {
              stack.push(meshOfInterest.getSnaptrudeDS());
              meshOfInterest.getChildMeshes().forEach((child) => {
                stack.push(child.getSnaptrudeDS());
              });
            }
          });

          stack = _.compact(stack);
          // some child meshes like door/window symbols aren't in structure

          StoreyMutation.removeObjectFromStorey(stack, true);
        };

        const _postUnExecuteCallback = function () {
          let data = this.data;
          let stack = [];
          data.forEach((dataPoint) => {
            let meshOfInterest = store.scene.getMeshByUniqueID(
                dataPoint.meshId
            );
            if (meshOfInterest) {
              stack.push(meshOfInterest.getSnaptrudeDS());
              meshOfInterest.getChildMeshes().forEach((child) => {
                stack.push(child.getSnaptrudeDS());
              });
            }
          });

          stack = _.compact(stack);
          // some child meshes like door/window symbols aren't in structure

          StoreyMutation.assignStorey(stack, null, true);
        };

        const _throwMeshProperties = function (mesh) {
          markMeshAsThrowAway(mesh, true);
          mesh.isVisible = false;

          // if really really zoomed out, can see these meshes as dots even though isVisible is false
          // but can't set visibility = 0 because that'll make the instances invisible too
        };

        const _throwMeshes = function () {
          _throwAwayMeshes.forEach((mesh) => {
            // scaling change messes with BRep
            // mesh.scaling = BABYLON.Vector3.Zero();

            // was intended for iPad escape
            // mesh.prevPosition = mesh.position.clone();

            /*mesh.position = BABYLON.Vector3.One().scale(
                GLOBAL_CONSTANTS.numbers.positions.throwAwayMesh
            );
            mesh.position.y = 0; // so that if anything goes wrong, this doesn't lead to generation of 10000 storeys
            */

            const positionToBe = BABYLON.Vector3.One().scale(
                GLOBAL_CONSTANTS.numbers.positions.throwAwayMesh
            );
            positionToBe.y = 0;
            mesh.setAbsolutePosition(positionToBe);

          });

          // _throwAwayMeshes.length = 0;

          _allMeshesWhosePropertyNeedsToBeChanged.forEach(_throwMeshProperties);

          delayedExecutionEngine.addExecutable(() => {
            let allComponents = _allMeshesWhosePropertyNeedsToBeChanged.map(
                (m) => m.getSnaptrudeDS()
            );
            StoreyMutation.removeObjectFromStorey(allComponents, true);
          }, "arrayThrowExecutable");
        };

        _optionsForThrow = {
          params: [commandUtils.worldMatrixChangeOperations.PARAMS.position],
          stack: _throwAwayMeshes,
          postUnExecuteCallback: _postUnExecuteCallback,
          postExecuteCallback: _postExecuteCallback,
        };

        let throwCommandData =
            commandUtils.worldMatrixChangeOperations.getCommandData(
                null,
                _optionsForThrow
            );

        _allMeshesWhosePropertyNeedsToBeChanged.length = 0;
        _throwAwayMeshes.forEach((mesh) => {
          _allMeshesWhosePropertyNeedsToBeChanged.push(mesh);

          mesh.getChildMeshes().forEach((descendant) => {
            if (descendant.getSnaptrudeDS())
              _allMeshesWhosePropertyNeedsToBeChanged.push(descendant);
          });
        });

        _optionsForPropertyChange = {
          meshKeys: ["type", "isVisible"],
          // componentKeys : ["storey"]
        };

        let propertyChangeCommandData =
            commandUtils.propertyChangeOperations.getCommandData(
                _allMeshesWhosePropertyNeedsToBeChanged,
                _optionsForPropertyChange
            );

        _throwMeshes();
        _updateRoofUI();

        _optionsForPropertyChange.data = propertyChangeCommandData;

        _optionsForThrow.data = throwCommandData;

        // throw command, propertyChange command and dll commands will be created in delayedExecutable to capture correct storey value
      }

      let firstClonePosition = store.scene
        .getMeshByUniqueID(_sourceMeshLeftBehindWithNoRestriction.clones[0])
        .getAbsolutePosition();

      _establishedMovementDirection = firstClonePosition
        .subtract(_meshWithNoRestrictionInitialPoint)
        .normalize();

      _newlyCreatedMeshes.length = 0;
      _newlyCreatedMeshes.push(..._firstSetOfClones);
      _commandPushedForFirstCloneCreation = true;
      _registerDelayedExecutable();
    }

    return Promise.resolve();
  }

  function _getInputBox() {
    if (_lastUsedInput) return getBabylonGUIElementByName(_lastUsedInput);
    else {
      return getBabylonGUIElementByName("arrayMoveInput");
      /*
            let arrayFunctionCurrentState = STATE_MACHINE.currentState;
            if (arrayFunctionCurrentState === 3 ){
                _lastUsedInput = "arrayMoveInput";
                return getBabylonGUIElementByName("arrayMoveInput");
            }
            else if (arrayFunctionCurrentState === 4  || arrayFunctionCurrentState === 0){
                _lastUsedInput = "arrayCloneInput";
                return getBabylonGUIElementByName("arrayCloneInput");
            }
            */
    }
  }

  function _setObservables(inputBox) {
    function onBlur() {
      store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput = false;
      arrayFunctionOperator.handleUserInput(inputBox.text, inputBox.name);
    }

    function _setLastUsedInput(input) {
      _lastUsedInput = input;
    }

    // store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput = true;
    inputBox.onFocusSelectAll = true;

    /*if (inputBox.name === "arrayMoveInput"){
            inputBox.promptMessage = "Enter the distance";
        }
        else if (inputBox.name === "arrayCloneInput"){
            inputBox.promptMessage = "Enter the number of copies";
        }*/

    if (store.isiPad) {
      /*inputBox.onTextChangedObservable.add(() => {
                if (STATE_MACHINE.currentState === 0 || STATE_MACHINE.currentState === 4) {

                    if (isNaN(Number(inputBox.text))) {
                        showToast("Enter valid input");
                        return;
                    }

                    _setLastUsedInput(inputBox.name);
                    onBlur();
                }
            });*/

      inputBox.onPointerDownObservable.clear();

      inputBox.onPointerDownObservable.add(function (e, input) {
        DisplayOperation.inputTextPointerDownModalCallback(
            _,
            input,
            (inputValue) => {
              if (
                  STATE_MACHINE.currentState === 0 ||
                  STATE_MACHINE.currentState === 4
              ) {
                inputBox.text = inputValue;

                const cleanText = inputBox.text.replace("'", "").replace('"', "");
                if (isNaN(Number(cleanText))) {
                  showToast("Enter valid input");
                  return;
                }

                _setLastUsedInput(inputBox.name);
                onBlur();
              }
            }
        );
      });
    } else {
      inputBox.onBlurObservable.add(onBlur);
    }

    /*
        There's a small quirk here.
        When user clicks the input boxes back and forth, onPointerDown observable
        is called first and onBlur later. So the required properties are being unset.
        Have not dealt with this properly yet.
         */

    inputBox.onFocusObservable.add(() => {
      store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput = true;
      _setLastUsedInput(inputBox.name);
    });

    inputBox.processKeyboard = function (evt) {
      if (!store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput)
        return; //safe side
      
      if (inputBox.color !== "black") inputBox.color = "black";
      // made red if > 100 copy input is entered
      
      if (
          isNumberKey(evt) ||
          isBackspaceKey(evt) ||
          isArrowKey(evt) ||
          isEnterKey(evt) ||
          isForwardSlashKey(evt) ||
          evt.keyCode === 222
      ) {
        this.autoStretchWidth = true;
        this.processKey(evt.keyCode, evt.key);
      }
      if (isEscapeKey(evt)) {
        cancelOperation();
      }
      if (isTabKey(evt)){
        handleTabKey(evt);
      }
    };
  }

  function _updateRoofUI() {
    if (_roofsCloned) updateRoofAccordion(true);
  }

  function eventHandler(evt) {
    STATE_MACHINE.nextEvent(evt);
  }

  function handleTab(e){
    if (STATE_MACHINE.currentState === 1){

      const nextSnap = snapEngine.alternateSnaps.getNext(
        _currentPick,
        e.shiftKey
      );
      _initialGripCreation(nextSnap);

      return true;
    }
  }

  function handleUserInput(input, type) {
    if (_meshOfInterest) {
      if (_lastUsedInput && type !== _lastUsedInput) return;
      // let type = _lastUsedInput;
      store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput = false;

      let _moveMesh = function (level0Mesh, diff) {
        let moveAmount, object;
        level0Mesh.clones.forEach((cloneId, i) => {
          moveAmount = diff.clone();
          let cloneMesh = store.scene.getMeshByUniqueID(cloneId);
          object = cloneMesh.getSnaptrudeDS();
          if (object.onElementMove) {
            object.onElementMove(moveAmount);
          }
          cloneMesh.position.addInPlace(moveAmount.scale(i + 1));
        });
      };

      let _cloneMesh = function (level0Mesh, input, cloneInBetween) {
        let _getScaleFactor = function (i) {
          if (cloneInBetween) {
            return totalLength.scale((i + 1) / _totalNumberOfClones);
          } else {
            return posDiff.scale(i + 1);
          }
        };

        let cloneMeshes = level0Mesh.clones.map((cloneId) =>
            store.scene.getMeshByUniqueID(cloneId)
        );

        let firstClone = _.first(cloneMeshes);
        let lastClone = _.last(cloneMeshes);

        let positionZero = level0Mesh.getAbsolutePosition();
        let totalLength = lastClone
            .getAbsolutePosition()
            .subtract(positionZero);
        let posDiff = firstClone.getAbsolutePosition().subtract(positionZero);

        let options = {
          uniqueObject: store.arrayFunctionGlobalVariables.uniqueObjects,
          sendOriginalToInfinity: false,
          arrayOperation: true,
        };

        for (let i = 1; i <= input; i++) {
          let newClone = pasteObject(level0Mesh, options);
          _newlyCreatedMeshes.push(newClone);
          cloneMeshes.push(newClone);
        }

        cloneMeshes.forEach((newClone, i) => {
          newClone.computeWorldMatrix();
          newClone.setAbsolutePosition(positionZero.add(_getScaleFactor(i)));
          setLayerTransperancy(newClone);
        });

        // StoreyMutation.assignStorey(cloneMeshes.map(m => m.getSnaptrudeDS()));

        setLayerTransperancy(level0Mesh);
      };

      if (type === "arrayMoveInput") {
        _lastUsedInput = "arrayMoveInput";
        input = DisplayOperation.getOriginalDimension(input);
        let firstClonePosition = store.scene
          .getMeshByUniqueID(_sourceMeshLeftBehindWithNoRestriction.clones[0])
          .getAbsolutePosition();

        if (!_establishedMovementDirection){
          _establishedMovementDirection = firstClonePosition
            .subtract(_meshWithNoRestrictionInitialPoint)
            .normalize();
        }

        let positionToGoTo = _meshWithNoRestrictionInitialPoint.add(
          _establishedMovementDirection.scale(input)
        );

        let amountToMove = positionToGoTo.subtract(firstClonePosition);

        if (amountToMove.almostEquals(BABYLON.Vector3.Zero())) return;

        _level0Meshes.forEach((m) => _moveMesh(m, amountToMove));

        _prepareForCloneMesh();
      } else if (type === "arrayCloneInput") {
        let _resetText = function () {
          let inputBox = _getInputBox();
          inputBox.text = inputBox._highlightedText;
        };

        _lastUsedInput = "arrayCloneInput";

        let cloneInBetween = input.includes("/");

        if (cloneInBetween) {
          let regExp = new RegExp("^/(\\d{1,2})$"); //start, /,  group of 1 or 2 digits, end
          let match = regExp.exec(input);
          if (match) {
            input = parseInt(match[1]); //group of 1 or 2 digits part
          } else {
            _resetText();
            return;
          }
        } else {
          input = parseInt(input);
        }

        if (input === _totalNumberOfClones) return;

        if (
          input > _CONSTANTS.maxCopiesPerOperation ||
          input < 1
        ) {
          DisplayOperation.signalInputError(_getInputBox());
          return;
        }

        if (input > _totalNumberOfClones) {

          // create new copies
          const numberOfNewCopies = input - _totalNumberOfClones;
          _totalNumberOfClones = input;

          _level0Meshes.forEach((m) => _cloneMesh(m, numberOfNewCopies, cloneInBetween));

        }
        else {
          // delete some copies

          const numberOfCopiesToDelete = _totalNumberOfClones - input;
          _totalNumberOfClones = input;

          _level0Meshes.forEach((m) => {
            const cloneIdsToRemove = [];
            for (let i = 1; i <= numberOfCopiesToDelete; i++){
              cloneIdsToRemove.push(m.clones.pop());
            }
            cloneIdsToRemove.forEach(id => {
              const cloneMesh = store.scene.getMeshByUniqueID(id);

              cloneMesh.dispose();
              removeMeshFromStructure(cloneMesh);
              _.pull(_newlyCreatedMeshes, cloneMesh);
            });
          });

        }

      }

      _dimensionLineMesh = DisplayOperation.drawOnMove(
          _sourceMeshLeftBehind.getAbsolutePosition(),
          _meshOfInterest.getAbsolutePosition()
      );
      DisplayOperation.displayOnMove(
          BABYLON.Vector3.Distance(
              _sourceMeshLeftBehind.getAbsolutePosition(),
              _meshOfInterest.getAbsolutePosition()
          ),
          "arrayMoveInput",
          true,
          { inputFocus: false }
      );

      _linkUIHelpers();

      _updateRoofUI();
      STATE_MACHINE.reset();
    }
  }

  function cancelOperation() {
    if (_meshOfInterest) {
      _removeUIHelpers();
      handleVisibilityChanges(_meshOfInterest, true);

      /*
      States below are the 'resting' states for iPad and desktop where escape is allowed
       */
      if (store.isiPad) {
        if (STATE_MACHINE.currentState === 0 && false) {
          // won't come here. Disabled array escape on iPad.

          _firstSetOfClones.forEach((m) => {
            removeMeshFromStructure(m);
            m.dispose();
          });

          _throwAwayMeshes.forEach((m) => {
            m.position = m.prevPosition.clone();
            delete m.prevPosition;
          });

          // this is insufficient
        }
      } else {
        if (STATE_MACHINE.currentState === 3) {
          _firstSetOfClones.forEach((m) => {
            removeMeshFromStructure(m);
            m.dispose();
          });
        }
      }

      _updateRoofUI();
      cleanUp();

      return true;
    }
  }
  
  function copyComponents(
    distanceBetweenCopies,
    numberOfCopies = 1,
    direction = BABYLON.Vector3.Right()
  ) {
    if (_.isEmpty(store.selectionStack)) return;
    
    _meshOfInterest =
      _meshWithGrips =
      _meshWithNoRestriction =
        store.selectionStack[0];
    
    _componentOfInterest = _meshOfInterest.getSnaptrudeDS();
    
    _meshOfInterestInitialPosition =
      _meshOfInterestInitialClickPoint =
      _meshWithNoRestrictionInitialPoint =
        _meshOfInterest.getAbsolutePosition();
    
    _createCopy();
    
    _meshOfInterestOnMovePoint = _meshOfInterestInitialPosition.add(direction.scale(1));
    const snappedPoint = _meshOfInterestInitialPosition.add(direction.scale(2));
    
    _moveMesh(snappedPoint);
    
    handleUserInput(distanceBetweenCopies, "arrayMoveInput");
    _lastUsedInput = null;
    handleUserInput(numberOfCopies.toString(), "arrayCloneInput");
    
    cleanUp();
  }
  

  function getMetadata() {
    return {
      getCurrentState: () => {
        return STATE_MACHINE.currentState;
      },
      isOperationInLimbo: () => {
        // limbo is when operation could be finished or not. Depends on what user does.
        // for this operation, it's after first copy is generated and the input boxes are
        // available for further modification

        // For desktop we're forcing the user to finish the operation
        // For iPad we should cancel the operation, but too complex, because of throwAway and linkedList issues.

        if (STATE_MACHINE.currentState === 0 || STATE_MACHINE.currentState === 1) {
          // even if the pointer highlights a vertex/edge/face
          // the limbo will persist

          // returning true for iPad as well so that delayedExecution runs all the time

          return !!_getInputBox();
        } else {
          return false;
        }
      },
      getInputBox: _getInputBox,
    };
  }

  const _cleanUpExceptInitialGripCreationData = function () {
    _removeAssignedProperties();
    store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput = false;
    store.arrayFunctionGlobalVariables.uniqueObjects = false;
    _lastUsedInput = null;
    _totalNumberOfClones = 0;
    _firstSetOfClones.length = 0;
    _firstSetOfClonesWithChildren.length = 0;
    _level0Meshes.length = 0;
    _level1Meshes.length = 0;
    _throwAwayMeshes.length = 0;
    _level1Components.length = 0;
    _allMeshesWhosePropertyNeedsToBeChanged.length = 0;
    _meshOfInterest = null;
    _componentOfInterest = null;
    _meshWithNoRestriction = null;
    _sourceMeshLeftBehindWithNoRestriction = null;
    _commandPushedForFirstCloneCreation = false;
    _roofsCloned = false;
    _establishedMovementDirection = null;

    _sourceMeshLeftBehind = null;

    _linkedListIdMetadata = {};

    _newlyCreatedMeshes.length = 0;
    _optionsForThrow = null;
    _optionsForPropertyChange = null;

    _removeUIHelpers();
  };

  function cleanUp() {
    return new Promise((resolve) => {
      if (isPointerOverGUIElement()) {
        resolve();
        return;
      }

      STATE_MACHINE.reset();

      delayedExecutionEngine.executeAll();
      movementConstrainer.flush();

      _cleanUpExceptInitialGripCreationData();
      _meshWithNoRestrictionInitialPoint = null;
      _meshWithGrips = null;
      _meshOfInterestInitialPosition = null;
      _meshOfInterestInitialClickPoint = null;
      _meshOfInterestOnMovePoint = null;

      _disposeGrips();
      _disposeSelectionBox();
      disposeAllAxisLines();
      disposeSnapToObjects();
      turnOffSnappingEngine();

      resolve();
    });
  }

  return {
    eventHandler,
    handleUserInput,
    handleTab,
    cancelOperation,
    getMetadata,
    cleanUp,
    init,
    copyComponents,
  };
})();
export { arrayFunctionOperator };