import BABYLON from "../modules/babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { filterCameraAffectingMeshes } from "./cameraFuncs.js";
import { setScaleOperation } from "../modules/setScaleOperations/setScaleOperation.js";
import { labelView } from "./labelView.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler";
import { cameraFollow } from "../modules/socket/cameraFollow";
import CameraCursor from "../modules/cursor/cameraCursor.js";
import { StateMachine } from "../modules/Classes/StateMachine";
import { scenePickController } from "../modules/utilityFunctions/scenePickController";

var zoomDiffSum = 0;
function computeBounds(onlyForStorey) {
  
  const largeObjectThreshold = 1e6;
  // Infinity > 1e6 is true
  
  function _refreshMinMaxInfo(mesh) {
    mesh.computeWorldMatrix(true);
    let bounds = mesh.getBoundingInfo();
    if (
      bounds.boundingBox.extendSizeWorld.x > largeObjectThreshold ||
      bounds.boundingBox.extendSizeWorld.y > largeObjectThreshold ||
      bounds.boundingBox.extendSizeWorld.z > largeObjectThreshold
    )
      return;

    if (bounds.boundingBox.minimumWorld.x < min.x)
      min.x = bounds.boundingBox.minimumWorld.x;
    if (bounds.boundingBox.minimumWorld.y < min.y)
      min.y = bounds.boundingBox.minimumWorld.y;
    if (bounds.boundingBox.minimumWorld.z < min.z)
      min.z = bounds.boundingBox.minimumWorld.z;
    if (max.x < bounds.boundingBox.maximumWorld.x)
      max.x = bounds.boundingBox.maximumWorld.x;
    if (max.y < bounds.boundingBox.maximumWorld.y)
      max.y = bounds.boundingBox.maximumWorld.y;
    if (max.z < bounds.boundingBox.maximumWorld.z)
      max.z = bounds.boundingBox.maximumWorld.z;
  }

  let min = new BABYLON.Vector3(1e5, 1e5, 1e5);
  let max = new BABYLON.Vector3(-1e5, -1e5, -1e5);

  for (let i = 0; i < store.scene.meshes.length; i++) {
    if (filterCameraAffectingMeshes(store.scene.meshes[i], onlyForStorey)) {
      _refreshMinMaxInfo(store.scene.meshes[i]);
    }
  }

  if (onlyForStorey) {
    // because sketches don't have storey property
    store.activeLayer.floorplans.forEach((floorPlanObj) =>
      _refreshMinMaxInfo(floorPlanObj.mesh)
    );
    // if (activeLayer.layerType === 'cad') {
    //     // for (let i = 0; i < store.activeLayer.sketches.length; i++) {
    //     //     let eachSketchObj = store.activeLayer.sketches[i];
    //     //     // eachSketchObj.mesh.forEach(m => {
    //     //     //     _refreshMinMaxInfo(m);
    //     //     // });
    //     //
    //     //     _refreshMinMaxInfo(eachSketchObj.mesh);
    //     // }
    //     store.activeLayer.sketches.forEach(sketchObj => _refreshMinMaxInfo(sketchObj.mesh));
    // }
    // else{
    //     store.activeLayer.sketches.forEach(sketchObj => _refreshMinMaxInfo(sketchObj.mesh));
    // }
    store.activeLayer.sketches.forEach((sketchObj) =>
      _refreshMinMaxInfo(sketchObj.mesh)
    );
  }

  if (min.x >= 5000 || min.y >= 5000 || min.z >= 5000) {
    min.x = -40;
    min.y = -40;
    min.z = -40;
  }
  if (max.x <= -5000 || max.y <= -5000 || max.z <= -5000) {
    max.x = 40;
    max.y = 40;
    max.z = 40;
  }

  let bound = new BABYLON.BoundingInfo(min, max);
  return bound;
}

function computeBoundsForMeshes(meshes) {
  let vect_arr = [];
  meshes.forEach(function (obj) {
    let bbinfo;
    if (obj.mesh) {
      bbinfo = obj.mesh.getBoundingInfo();
    } else {
      bbinfo = obj.getBoundingInfo();
    }

    vect_arr.push(bbinfo.boundingBox.maximumWorld);
    vect_arr.push(bbinfo.boundingBox.minimumWorld);
  });

  // statement
  let x_min = _.minBy(vect_arr, "x");
  let y_min = _.minBy(vect_arr, "y");
  let z_min = _.minBy(vect_arr, "z");

  let x_max = _.maxBy(vect_arr, "x");
  let y_max = _.maxBy(vect_arr, "y");
  let z_max = _.maxBy(vect_arr, "z");

  let newBoundingBox = new BABYLON.BoundingInfo(
    new BABYLON.Vector3(x_min.x, y_min.y, z_min.z),
    new BABYLON.Vector3(x_max.x, y_max.y, z_max.z)
  );
  return newBoundingBox;
}

function computeStructureBounds(structure_number) {
  let min = new BABYLON.Vector3(1e5, 1e5, 1e5);
  let max = new BABYLON.Vector3(-1e5, -1e5, -1e5);
  for (var i = 0; i < store.scene.meshes.length; i++) {
    if (store.scene.meshes[i].visibility === 0) continue;
    if (store.scene.meshes[i].isVisible === false) continue;
    if (store.scene.meshes[i].name === "ground1") continue;
    if (store.scene.meshes[i].name === "ground2") continue;
    if (store.scene.meshes[i].name === "backwall") continue;
    if (store.scene.meshes[i].name === "axisX") continue;
    if (store.scene.meshes[i].name === "axisY") continue;
    if (store.scene.meshes[i].name === "axisZ") continue;
    if (store.scene.meshes[i].structure !== structure_number) continue;
    var bounds = store.scene.meshes[i].getBoundingInfo();
    if (bounds.boundingBox.minimumWorld.x < min.x)
      min.x = bounds.boundingBox.minimumWorld.x;
    if (bounds.boundingBox.minimumWorld.y < min.y)
      min.y = bounds.boundingBox.minimumWorld.y;
    if (bounds.boundingBox.minimumWorld.z < min.z)
      min.z = bounds.boundingBox.minimumWorld.z;
    if (max.x < bounds.boundingBox.maximumWorld.x)
      max.x = bounds.boundingBox.maximumWorld.x;
    if (max.y < bounds.boundingBox.maximumWorld.y)
      max.y = bounds.boundingBox.maximumWorld.y;
    if (max.z < bounds.boundingBox.maximumWorld.z)
      max.z = bounds.boundingBox.maximumWorld.z;
  }
  return new BABYLON.BoundingInfo(min, max);
}

function ZoomAll2() {
  var bounds = computeBounds();
  var aspect = store.canvas.clientHeight / store.canvas.clientWidth;
  var factor = 1;
  var camera = store.newScene.activeCamera;
  console.log(camera, bounds, aspect);
  camera.orthoLeft = bounds.minimum.x * factor;
  camera.orthoRight = bounds.maximum.x * factor;
  camera.orthoTop = bounds.maximum.y * factor * aspect;
  camera.orthoBottom = bounds.minimum.y * factor * aspect;
  camera.setTarget(bounds.boundingSphere.center);
}

function ZoomAll() {
  store.newScene.activeCamera = store.scene.getCameraByName("ArcRotateCamera3");
  if (store.newScene.activeCamera) {
    // store.newScene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
    // store.newScene.activeCamera.alpha = -Math.PI / 6;
    // store.newScene.activeCamera.beta = Math.PI / 2.5;
    // store.newScene.activeCamera.minZ = 0;
  }
  // store.newScene.clipPlane = null;
  // store.scene.getMeshByName("ground1").visibility = 1.0;

  return;
  // var camera3 = new BABYLON.ArcRotateCamera("ArcRotateCamera4", 0.5, 0, 15, BABYLON.Vector3.Zero(), store.newScene);
  // store.newScene.activeCamera.dispose();
  // store.newScene.activeCamera = camera3;
  // store.newScene.activeCamera.setPosition(new BABYLON.Vector3(-30, 30, 45));
  // return;
  /*newScene.activeCamera = store.scene.getCameraByName("ArcRotateCamera3");
    store.newScene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
    // store.canvas.addEventListener("mousewheel", scrollZoom);
    var camera = store.newScene.activeCamera;
    var prevAlpha = camera.alpha;
    var prevBeta = camera.beta;
    var bounds = computeBounds();
    var objectSize;
    if (Math.abs(bounds.maximum.x - bounds.minimum.x) > Math.abs(bounds.maximum.y - bounds.minimum.y)) {
        objectSize = Math.abs(bounds.maximum.x - bounds.minimum.x);
    }
    else {
        objectSize = Math.abs(bounds.maximum.y - bounds.minimum.y);
    }
    // Convert camera fov degrees to radians
    var fov = camera.fov;

    // Calculate the camera distance
    var distance = Math.abs(objectSize / Math.sin(fov / 2)) / 2;
    camera.radius = distance;
    // let campos = camera.position;
    // campos.x = 12*distance/10;
    // campos.y = 4*distance/10;
    // campos.z = -12*distance/10;
    // camera.setPosition(campos);
    camera.setPosition(new BABYLON.Vector3(12 * distance / 10, 4 * distance / 10, -12 * distance / 10));
    camera.setTarget(bounds.boundingSphere.center);
    // camera.alpha = prevAlpha;
    // camera.beta = prevBeta;
    camera.alpha = -Math.PI / 6;
    camera.beta = Math.PI / 2.5;
    camera.minZ = 0;
    store.scene.clipPlane = null;
    store.scene.getMeshByName("ground1").visibility = 1.0;
    delete bounds; */
}

var newSonZoomDown = function (evt) {
  store.pointerDown = true;
  store.controlerInput.sourisX = evt.pageX;
  store.controlerInput.sourisY = evt.pageY;
  var pickResult = store.scene.pick(
    store.scene.pointerX,
    store.scene.pointerY,
    function (mesh) {
      return mesh.name == "ground2";
    }
  );
  if (pickResult.hit) {
    store.controlerInput.posX = pickResult.pickedPoint.x;
    store.controlerInput.posZ = pickResult.pickedPoint.z;
  }
  // store.newScene.activeCamera.detachControl(canvas);
  rectangleStart();
  document.getElementById("canvas").style.cursor = "none";
};

var onZoomUp = function (evt) {
  store.pointerDown = false;
  // store.newScene.activeCamera.attachControl(canvas, true, false);
  removeSelecRectangles();
  document.getElementById("canvas").style.cursor = "default";
  endRectangle();
};

var onZoomMove = function (evt) {
  if (!store.pointerDown) return;
  // store.newScene.activeCamera.detachControl(canvas);
  // var diff = current.subtract(startingPoint);
  // removeSelecRectangles();
  // createSelectRectangles(startingPoint, current);
  updateRectangle();
};

function rectangleStart() {
  store.controlerInput.rectangleSelection = {};
  store.controlerInput.rectangleSelection["x1"] = store.controlerInput.posX;
  store.controlerInput.rectangleSelection["z1"] = store.controlerInput.posZ;
  store.controlerInput.rectangleSelection["sx1"] = store.controlerInput.sourisX;
  store.controlerInput.rectangleSelection["sy1"] = store.controlerInput.sourisY;
  (function ($) {
    $("#rectangleSelection").css({
      top: store.controlerInput.sourisY - 5,
      left: store.controlerInput.sourisX - 5,
    });
    $("#rectangleSelection").fadeIn(200);
  })(jQuery);
}

function updateRectangle() {
  store.controlerInput.rectangleSelection["x2"] = store.controlerInput.posX;
  store.controlerInput.rectangleSelection["z2"] = store.controlerInput.posZ;
  store.controlerInput.rectangleSelection["sx2"] = store.controlerInput.sourisX;
  store.controlerInput.rectangleSelection["sy2"] = store.controlerInput.sourisY;
  (function ($) {
    $("#rectangleSelection").fadeOut(200);
    $("#rectangleSelection").css({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    });
  })(jQuery);
}

function endRectangle() {
  var widthRectangle = Math.abs(
    store.controlerInput.rectangleSelection["sx1"] - store.controlerInput.sourisX
  );
  var heightRectangle = Math.abs(
    store.controlerInput.rectangleSelection["sy1"] - store.controlerInput.sourisY
  );
  (function ($) {
    $("#rectangleSelection").css({
      width: widthRectangle - 5,
      height: heightRectangle - 5,
    });
  })(jQuery);
  // On recale la div suivant si on séléctionne depuis la gauche vers la droite, ou de bas en haut
  // Depending that if you select from left, right, up or down
  if (
    store.controlerInput.sourisX <= store.controlerInput.rectangleSelection["sx1"] &&
    store.controlerInput.sourisY >= store.controlerInput.rectangleSelection["sy1"]
  ) {
    (function ($) {
      $("#rectangleSelection").css({
        width: widthRectangle - 5,
        height: heightRectangle - 5,
        left: store.controlerInput.sourisX + 5,
      });
    })(jQuery);
  } else if (
    store.controlerInput.sourisY <= store.controlerInput.rectangleSelection["sy1"] &&
    store.controlerInput.sourisX >= store.controlerInput.rectangleSelection["sx1"]
  ) {
    (function ($) {
      $("#rectangleSelection").css({
        width: widthRectangle - 5,
        height: heightRectangle - 5,
        top: store.controlerInput.sourisY + 5,
      });
    })(jQuery);
  } else if (
    store.controlerInput.sourisY < store.controlerInput.rectangleSelection["sy1"] &&
    store.controlerInput.sourisX < store.controlerInput.rectangleSelection["sx1"]
  ) {
    (function ($) {
      $("#rectangleSelection").css({
        width: widthRectangle - 5,
        height: heightRectangle - 5,
        left: store.controlerInput.sourisX + 5,
        top: store.controlerInput.sourisY + 5,
      });
    })(jQuery);
  }
}

function removeSelecRectangles() {
  if (store.scene.getMeshByName("rectSelect1"))
    store.scene.getMeshByName("rectSelect1").dispose();
  if (store.scene.getMeshByName("rectSelect2"))
    store.scene.getMeshByName("rectSelect2").dispose();
  if (store.scene.getMeshByName("rectSelect3"))
    store.scene.getMeshByName("rectSelect3").dispose();
  if (store.scene.getMeshByName("rectSelect4"))
    store.scene.getMeshByName("rectSelect4").dispose();
}

function createSelectRectangles(st, cur) {
  var p1 = st;
  var p2 = st;
  var p3 = cur;
  var p4 = cur;
  // p1.y = 0;
  // p2.y = 0;
  // p3.y = 0;
  // p4.y = 0;
  p2.z = cur.z;
  p3.x = st.x;
  var l1 = BABYLON.Mesh.CreateLines("rectSelect1", [p1, p2], store.newScene);
  var l2 = BABYLON.Mesh.CreateLines("rectSelect2", [p2, p3], store.newScene);
  var l3 = BABYLON.Mesh.CreateLines("rectSelect3", [p3, p2], store.newScene);
  var l4 = BABYLON.Mesh.CreateLines("rectSelect4", [p2, p1], store.newScene);
  l1.color = new BABYLON.Color3(0, 1, 0);
  l2.color = new BABYLON.Color3(0, 1, 0);
  l3.color = new BABYLON.Color3(0, 1, 0);
  l4.color = new BABYLON.Color3(0, 1, 0);
}

const onWheelEventListener = function (event) {
  if (event.preventDefault) event.preventDefault(); // prevent page zooming in case of track pad pinch
  scrollZoom(event);
  StateMachine.EventValidation.markUserScroll();
  if (setScaleOperation && setScaleOperation.isInputBoxEnabled()) {
    setScaleOperation.updateSpheres();
  }
};

let totalZoom = 0;

var scrollZoom = function (evt, mag = 1) {
  // console.log("scrollzoom");
  let pt = new BABYLON.Vector3(0, 0, 0);
  let camera = store.newScene.activeCamera;
  if (camera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA) {
    const mul = Math.abs(camera.orthoTop - camera.orthoBottom) / 6;
    const delta = (Math.max(-1, Math.min(1,
        (evt.wheelDelta || -evt.detail || evt.deltaY))))
      * mul;
    if (delta > 0 || delta < 0) {
      // totalZoom += delta;
      uiIndicatorsHandler.markForUpdate();
      cameraFollow.onCameraUpdate();
      const cameraCursor = new CameraCursor();
      cameraCursor.onCameraUpdate();
      zoom2DView(camera, delta);
    }
    // if (evt.deltaY > 0) {
    //     camera.fov += 0.1;
    // } else if (evt.deltaY < 0) {
    //     camera.fov -= 0.1;
    // }
    let direction = evt.deltaY;
    let zoom = direction / 10;
    /*camera.orthoLeft -= zoom*1.4;
        camera.orthoRight += zoom*1.4;
        camera.orthoBottom -= zoom;
        camera.orthoTop += zoom;*/

    let zoomingIn = Math.sign(direction) === -1;
    let percentageDecrease = -10; //on zooming in
    let percentageIncrease =
      (100 * percentageDecrease) / (100 + percentageDecrease); // 100/11 for x = 10
    //this is done so that 1 forward scroll and 1 backward causes no change in ortho properties
    let multiplier;
    if (zoomingIn) {
      multiplier = (percentageDecrease / 100) * mag;
    } else {
      multiplier = (-percentageIncrease / 100) * mag;
    }

    if (direction && mag) {
      // camera.orthoLeft += camera.orthoLeft * multiplier;
      // camera.orthoRight += camera.orthoRight * multiplier;
      // camera.orthoBottom += camera.orthoBottom * multiplier;
      // camera.orthoTop += camera.orthoTop * multiplier;
      let c = store.advancedTexture._linkedControls[0];
      if (c) {
        if (c.style) {
          let f = parseFloat(c.style.fontSize);
          let newf = 1 / camera.orthoRight * 500;
          if ((f !== 0) && (parseInt(f) !== parseInt(newf))) {
            if (newf < 8) {
              f = 0;
              if (labelView) {
                labelView.hideLines();
              }
              store.advancedTexture._linkedControls.forEach(c => {
                if (c.name.includes("autoDim")) {
                  c.style.fontSize = f + "px";
                }
              });
            }
          } else if ((f === 0) && (newf > 9)) {
            f = 12;
            labelView.showLines();
            store.advancedTexture._linkedControls.forEach(c => {
              if (c.name.includes("autoDim")) {
                c.style.fontSize = f + "px";
              }
            });
          }
        }
      }
    }

    /*camera.update();

        console.log(camera.orthoLeft);
        console.log(camera.orthoRight);
        console.log(camera.orthoBottom);
        console.log(camera.orthoTop);
        console.log(direction);
        console.log("------------------------------");
        store.scene.render();
        console.log(newScene.activeCamera.position);*/

    // let pickResult = store.scene.pick(
    //   store.scene.pointerX,
    //   store.scene.pointerY,
    //   function (mesh) {
    //     return mesh.name === "ground1";
    //   }
    // );
    // if (pickResult.hit) {
    //   pt.x = pickResult.pickedPoint.x;
    //   pt.z = pickResult.pickedPoint.z;
    //   // pt.y = store.scene.getMeshByName('ground1').position.y;
    //   pt.y = 0; // store.scene.getMeshByName('ground1').position.y = 0
    // }
    // let diff = store.newScene.activeCamera.position.subtract(pt);
    //
    // if (diff.length())
    //   store.newScene.activeCamera.panningSensibility = 2250 / diff.length() + 1;
    // if (!store.isiPad && !pt.equals(BABYLON.Vector3.Zero())) {
    //   moveCameraScroll(store.newScene.activeCamera, diff, evt.deltaY);
    // }
  } else {
    let pickResult = scenePickController.pickInvisibleMeshes(
      function (mesh) {
        return mesh.name === "ground1";
      }
    );
    if (pickResult.hit) {
      pt.x = pickResult.pickedPoint.x;
      pt.z = pickResult.pickedPoint.z;
      // pt.y = store.scene.getMeshByName('ground1').position.y;
      pt.y = 0; // store.scene.getMeshByName('ground1').position.y = 0
    }
    let diff = camera.position.subtract(pt);
    if (diff.length())
      store.newScene.activeCamera.panningSensibility = 2250 / diff.length() + 1;

    moveCameraScroll(camera, diff, evt.deltaY);
  }
};

const zoom2DView = async (camera, delta) => {
  if (store.zoomTarget) {
    const totalX = Math.abs(camera.orthoLeft - camera.orthoRight);
    const totalY = Math.abs(camera.orthoTop - camera.orthoBottom);

    const aspectRatio = totalY / totalX;

    {
      const fromCoord = camera.orthoLeft - store.zoomTarget.x;
      const ratio = fromCoord / totalX;
      camera.orthoLeft -= ratio * delta;
    }

    {
      const fromCoord = camera.orthoRight - store.zoomTarget.x;
      const ratio = fromCoord / totalX;
      camera.orthoRight -= ratio * delta;
    }

    {
      const fromCoord = camera.orthoTop - store.zoomTarget.y;
      const ratio = fromCoord / totalY;
      camera.orthoTop -= ratio * delta * aspectRatio;
    }

    {
      const fromCoord = camera.orthoBottom - store.zoomTarget.y;
      const ratio = fromCoord / totalY;
      camera.orthoBottom -= ratio * delta * aspectRatio;
    }

    // decrease pan sensitivity the closer the zoom level.
    // camera.panningSensibility = 6250 / Math.abs(totalX / 2);
  }
};

// var scrollZoom2 = function (event) {
//   const camera = store.scene.activeCamera;
//   console.log(camera.orthoTop);
//   const multiplier = camera.orthoTop < 20 ? 7 : camera.orthoTop / 2;
//   const delta = (Math.max(-1, Math.min(1,
//     (event.wheelDelta || -event.detail || event.deltaY))))
//     * multiplier;
//   if (delta > 0 && totalZoom < 200 || delta < 0) {
//     totalZoom += delta;
//     // console.log(totalZoom);
//     zoom2DView(camera, delta);
//   }
// }

var scrollZoomTopView = function (evt) {
  var pt = new BABYLON.Vector3(0, 0, 0);
  // console.log(evt.deltaY);
  store.newScene.activeCamera.orthoTop += (evt.deltaY / 120) * 0.9 * 10;
  store.newScene.activeCamera.orthoBottom -= (evt.deltaY / 120) * 0.9 * 10;
  store.newScene.activeCamera.orthoLeft -= (evt.deltaY / 120) * 1.4 * 10;
  store.newScene.activeCamera.orthoRight += (evt.deltaY / 120) * 1.4 * 10;
  var pickResult = store.scene.pick(
    store.scene.pointerX,
    store.scene.pointerY,
    function (mesh) {
      return mesh.name == "ground1";
    }
  );
  if (pickResult.hit) {
    pt.x = pickResult.pickedPoint.x;
    pt.z = pickResult.pickedPoint.z;
    // pt.y = store.scene.getMeshByName('ground1').position.y;
    pt.y = 0; // store.scene.getMeshByName('ground1').position.y = 0
  }
  var diff = store.newScene.activeCamera.position.subtract(pt);
  moveCameraScroll(store.newScene.activeCamera, diff, evt.deltaY);
};

function moveCameraScroll(camera, diff, delta, scrollScaleFlag = false) {
  // camera.position.x += diff.x / store.cameraScrollScale;
  // camera.position.y += diff.y / store.cameraScrollScale;
  // camera.position.z += diff.z / store.cameraScrollScale;
  let scaleValue;
  if (!scrollScaleFlag) scaleValue = store.cameraScrollScale;
  else scaleValue = 70;
  let pt = camera.target;
  pt.x += (Math.sign(delta) * diff.x) / scaleValue;
  pt.y += (Math.sign(delta) * diff.y) / scaleValue;
  pt.z += (Math.sign(delta) * diff.z) / scaleValue;
  // camera.setTarget(pt);
  // var bounds = computeBounds();
  // camera.setTarget(bounds.boundingSphere.center);
  zoomDiffSum += delta;
}

function getTargetPoint() {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh;
    }
  );
  if (pickInfo.hit) {
    return pickInfo.pickedPoint;
  } else {
    return false;
  }
}
export {
  zoomDiffSum,
  computeBounds,
  computeBoundsForMeshes,
  computeStructureBounds,
  ZoomAll2,
  ZoomAll,
  newSonZoomDown,
  onZoomUp,
  onZoomMove,
  rectangleStart,
  updateRectangle,
  endRectangle,
  removeSelecRectangles,
  createSelectRectangles,
  onWheelEventListener,
  scrollZoom,
  scrollZoomTopView,
  moveCameraScroll,
  getTargetPoint,
};
