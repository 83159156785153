import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import addIcon from '../../../../assets/icons/addButton.svg'
import LoadingScreen from '../../../../components/Loading/LoadingScreen';
import CreateCustomRoleModal from '../../../../components/SnaptrudeModal/CreateCustomRoleModal';
import Toggle from '../../../../components/Toggle';
import { getTeamPermissions, updateTeamPermission } from '../../../../services/team.service';
import { showToast, TOAST_TYPES } from '../../../../snaptrude/modules/extrafunc';
import { PERMISSIONS } from '../constants';
const Wrapper = styled.div`
    // border: 2px solid;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    .disabled{
        opacity: 0.5;
        pointer-events: none;
    }
    .noneditable{
        pointer-events: none;
    }
    thead{
        position: sticky;
        top: 0px;
        z-index: 5;
        background-color: #fcfcfc;
        border-bottom: 1px solid #ddd;
    }
    thead th{
        border-bottom: 1px solid #ddd;
        text-transform: capitalize;
    }
    th:first-child, td:first-child{
        text-align: left;
        min-width: 250px;
    }
    th, td{
        padding: 0.25em;
        min-width: 100px;
        text-align: center;
    }
`
function Permissions({selectedTeam}) {
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(selectedTeam.roleBasedPermissions === null);
    const [showCreateCustomRoleModal, setShowCreateCustomModal] = useState(false);
    const handleTogglePermission = async (role, permissionKey) => {
        const currentPermissionEle = document.getElementById("permissions-table");
        if(currentPermissionEle) currentPermissionEle.classList.add('disabled');
        
        const newRoleBasedPermissions = {...selectedTeam.roleBasedPermissions[role]};
        const newValue = !newRoleBasedPermissions[permissionKey];
        newRoleBasedPermissions[permissionKey] = !newRoleBasedPermissions[permissionKey]
        if(permissionKey === "create_project" && newValue){
            newRoleBasedPermissions.edit_model = true;
        }
        const newSettings = newRoleBasedPermissions;
        const resp = await updateTeamPermission(selectedTeam.id, role, newSettings);
        if(resp.status === "success"){
            
        }else{
            showToast("Could not update the permission", 3000, TOAST_TYPES.error)
        }

        if(currentPermissionEle) currentPermissionEle.classList.remove('disabled');

    }

    const handleAddCustomRole = () => {
        setShowCreateCustomModal(true)
    }
    if(showLoadingAnimation) return <LoadingScreen />
    return ( 
        <Wrapper>
            <table id="permissions-table">
                <thead>
                    <tr>
                        <th>Function</th>
                        {
                            selectedTeam.roleBasedPermissions &&
                            _.keys(selectedTeam.roleBasedPermissions).map(role => {
                                return <th key={role}>{role}</th>
                            })
                        }
                        <th><img src={addIcon} alt="+" style={{cursor: 'pointer'}} onClick={handleAddCustomRole} /></th>
                    </tr>
                </thead>
                <tbody>
                    {/* PROJECTS */}
                    <tr>
                        <th>Projects</th>
                    </tr>
                    {
                        PERMISSIONS.PROJECTS.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    {
                                        selectedTeam.roleBasedPermissions &&
                                        _.keys(selectedTeam.roleBasedPermissions).map(role => {
                                            const isDisabled = permission.isDisabled.includes(role) || (permission.isDisabledIf && selectedTeam.roleBasedPermissions[role][permission.isDisabledIf])
                                            return <td key={role}><Toggle state={selectedTeam.roleBasedPermissions[role][permission.key]} handleClick={() => {handleTogglePermission(role, permission.key)}} unEditable={isDisabled} /></td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                    {/* LIBRARIES */}
                    <tr>
                        <th>Libraries</th>
                    </tr>
                    {
                        PERMISSIONS.LIBRARIES.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    {
                                        selectedTeam.roleBasedPermissions &&
                                        _.keys(selectedTeam.roleBasedPermissions).map(role => {
                                            return <td key={role} ><Toggle state={selectedTeam.roleBasedPermissions[role][permission.key]} handleClick={() => {handleTogglePermission(role, permission.key)}} unEditable={permission.isDisabled.includes(role)} /></td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                    {/* TEMPLATES */}
                    {/* <tr>
                        <th>Templates</th>
                    </tr>
                    {
                        PERMISSIONS.TEMPLATES.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    {
                                        selectedTeam.roleBasedPermissions &&
                                        _.keys(selectedTeam.roleBasedPermissions).map(role => {
                                            return <td key={role}><Toggle state={selectedTeam.roleBasedPermissions[role][permission.key]} handleClick={() => {handleTogglePermission(role, permission.key)}} unEditable={permission.isDisabled.includes(role)} /></td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                     */}
                    {/* GENERAL */}
                    <tr>
                        <th>General</th>
                    </tr>
                    {
                        PERMISSIONS.GENERAL.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    {
                                        selectedTeam.roleBasedPermissions &&
                                        _.keys(selectedTeam.roleBasedPermissions).map(role => {
                                            return <td key={role}><Toggle state={selectedTeam.roleBasedPermissions[role][permission.key]} handleClick={() => {handleTogglePermission(role, permission.key)}} unEditable={permission.isDisabled.includes(role)} /></td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <CreateCustomRoleModal 
                isOpen={showCreateCustomRoleModal}
                onClose={() => {setShowCreateCustomModal(false)}}
                viewerPermissions={selectedTeam.roleBasedPermissions["viewer"]}
                existingRoles={_.keys(selectedTeam.roleBasedPermissions)}
                teamId={selectedTeam.id}
            />
        </Wrapper>
     );
}

export default Permissions;