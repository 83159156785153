import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import {
  getEmptyFunction,
  isPointerOverGUIElement,
  isTouchEvent,
} from "../extrafunc.js";
/**
 * Class to handle the flow of "events" in the execution of a feature.
 * Does the plumbing work and allows quick changes
 *
 */

class StateMachine {
  constructor(events, mouseSequence, touchSequence) {
    this.events = events;
    this.mouseSequence = mouseSequence;
    this.touchSequence = touchSequence;
    this._currentState = 0;
    this._currentEvent = null;
  }

  get currentState() {
    return this._currentState;
  }

  set currentState(state) {
    this._currentState = state;
  }

  executeEvent(id) {
    this._currentEvent = this.events[id];
    return this._currentEvent();
  }

  nextEvent(trigger) {
    const _handleFlow = function (flow) {
      const _getFunction = function (state) {
        let returnFunction = null;
        if (_.isArray(state)) {
          returnFunction = _handleFlow.bind(this, state);
        } else if (!isNaN(state)) {
          returnFunction = (m) => {
            this._currentState = state;
          };
        } else if (isNaN(state)) {
          returnFunction = getEmptyFunction();
        }

        return returnFunction;
      };

      const eventToExecute = flow[0];
      const stateAfterSuccessfulExecution = flow[1];
      const stateAfterFailedExecution = flow[2];

      const onResolve = _getFunction.call(this, stateAfterSuccessfulExecution);
      const onReject = _getFunction.call(this, stateAfterFailedExecution);

      this.executeEvent(eventToExecute).then(onResolve, (rejectionReason) => {
        if (rejectionReason) {
          if (rejectionReason === "ignorePromise") return;
          else console.warn(rejectionReason);
        }
        onReject();
      });
    };

    const _filterTriggerTypes = function (trigger) {
      if (StateMachine.EventValidation.isPointerDownWithMiddleButton(trigger))
        return false;
      if (
        StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(trigger)
      )
        return false;

      if (store.isiPad && (isPointerOverGUIElement() || isTouchEvent(trigger)))
        return false;

      return true;
    };

    if (!_filterTriggerTypes(trigger)) return;

    const triggerType = trigger.type;
    const currentState = this._currentState;

    let flow = null;
    try {
      if (store.isiPad) {
        flow = this.touchSequence[triggerType][currentState];
      } else {
        flow = this.mouseSequence[triggerType][currentState];
      }
    } catch (e) {
      // console.log(e);
      return;
    }

    if (!flow) return;

    _handleFlow.call(this, flow);
  }

  reset() {
    this._currentState = 0;
    this._currentEvent = null;
  }

  setState(state) {
    this._currentState = state;
  }
  
  fakeAPointerDown(){
    this.nextEvent(StateMachine.EventForger.getPointerDown());
  }
}

StateMachine.EVENT_TYPE = {
  pointerDown: "pointerdown",
  pointerUp: "pointerup",
  pointerMove: "pointermove",
};

StateMachine.EventValidation = (function () {
  
  const LEFT_CLICK_ID = 0;
  const MIDDLE_CLICK_ID = 1;
  const RIGHT_CLICK_ID = 2;
  
  let shiftKeyDown = false;
  let isUserScrollActive = false;
  let scrollTimerId = null;
  
  const POINTER_MOVE_FAST_THRESHOLD = 20; // pixels
  const USER_SCROLL_RESET_TIME_THRESHOLD = 100; // ms
  
  const isPointerMoveWhileMiddleButtonDown = function (evt) {
    if (evt.type === StateMachine.EVENT_TYPE.pointerMove) {
      if (evt.buttons === 4) return true;
    }
    
    shiftKeyDown = evt.shiftKey;

    return false;
  };

  const isPointerMoveWhileRightButtonDown = function (evt) {
    if (evt.type === StateMachine.EVENT_TYPE.pointerMove) {
      if (evt.buttons === 2) return true;
    }

    return false;
  };

  const isPointerDownWithMiddleButton = function (evt) {
    if (evt.type === StateMachine.EVENT_TYPE.pointerDown) {
      if (evt.button === MIDDLE_CLICK_ID) return true;
    }

    return false;
  };
  
  const isPointerDownWithRightButton = function (evt) {
    if (evt.type === StateMachine.EVENT_TYPE.pointerDown) {
      if (evt.button === RIGHT_CLICK_ID) return true;
    }

    return false;
  };
  
  const isPointerDownWithLeftButton = function (evt) {
    if (evt.type === StateMachine.EVENT_TYPE.pointerDown) {
      if (evt.button === LEFT_CLICK_ID) return true;
    }

    return false;
  };
  
  const isShiftKeyDown = () => shiftKeyDown;
  
  const isPointerMoveFastEnoughToIgnore = function (evt) {
    if (evt.type === StateMachine.EVENT_TYPE.pointerMove) {
      
      const d = Math.sqrt(
        Math.pow(evt.movementX, 2) + Math.pow(evt.movementY, 2)
      );
      // console.log(d);
      
      return d > POINTER_MOVE_FAST_THRESHOLD;
    }
  };
  
  const markUserScroll = function () {
    isUserScrollActive = true;
    
    if (scrollTimerId) clearTimeout(scrollTimerId);
    
    scrollTimerId = setTimeout(() => {
      isUserScrollActive = false;
      scrollTimerId = null;
    }, USER_SCROLL_RESET_TIME_THRESHOLD);
  };
  
  const isUserScrolling = function () {
    return isUserScrollActive;
  };

  return {
    isPointerDownWithLeftButton,
    isPointerDownWithMiddleButton,
    isPointerDownWithRightButton,
    
    isPointerMoveWhileMiddleButtonDown,
    isPointerMoveWhileRightButtonDown,
    isPointerMoveFastEnoughToIgnore,
    
    isShiftKeyDown,
    markUserScroll,
    isUserScrolling,
  };
})();

StateMachine.EventForger = (function (){
  
  const LEFT_CLICK_ID = 0;
  const MIDDLE_CLICK_ID = 1;
  const RIGHT_CLICK_ID = 2;
  
  const getPointerDown = function (){
    return {
      type: StateMachine.EVENT_TYPE.pointerDown,
      button: LEFT_CLICK_ID,
    }
  }
  
  return {
    getPointerDown
  };
  
})();

export { StateMachine };
