"use strict";
/*jshint esversion: 6 */
import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js"
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { getAngleBetweenVectors,isFloatEqual,projectionOfPointOnLine } from "./snapFuncs.js";
import { showVertexIndicator,showAxisIndicator,showEdgeIndicator,disposeSnapToObjects } from "../modules/meshoperations/moveOperations/moveUtil.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { isTwoDimension } from "./twoDimension.js";
import { snapMeshforMeasure } from "./sceneStateFuncs.js";
import { findPrioritizedSnapPoint } from "./snapFuncsPrimary.js";
import { snapHorizontallyAndVertically } from "./snapFuncsSecondary.js";
import { externalUtil } from "../modules/externalUtil.js";
import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
import { doesPointLieOnEdge, cloneEdge } from "../modules/extrafunc.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController";

var measureFunction = (function () {
  let startPoint = null;
  let endPoint = null;
  let onMovePoint = null;
  let clickPickInfo = null;
  let highlightPickInfo = null;
  let downClick = 0;
  let projectPoint = null;
  let secondPoint = null;
  let projectPoint1 = null;
  let projectPoint2 = null;
  let edge1 = null;
  let edge2 = null;
  let options = null;
  let edgeAngle = null;
  let intersection = null;
  let intersection1 = null;
  let intersection2 = null;
  let threshold = 0.5;

  let disposeDashedPinkLines = function () {
    disposeDashedPinkLine1();
    disposeDashedPinkLine2();
  };

  let disposeHighlightedEdges = function () {
    uiIndicatorsHandler.edgeIndicator.remove();
  };

  let edgeToVector = function (edge) {
    let x = edge.tailPt.x - edge.headPt.x;
    let y = edge.tailPt.y - edge.headPt.y;
    let z = edge.tailPt.z - edge.headPt.z;
    return new BABYLON.Vector3(x, y, z);
  };
  let checkEdges = function (firstEdge) {
    let secondEdge = (options.metadata.hasOwnProperty('snappedToEdge')) ? cloneEdge(options.metadata.snappedToEdge) : null;
    if (firstEdge && secondEdge) {
      let vec1 = edgeToVector(firstEdge);
      let vec2 = edgeToVector(secondEdge);
      let angle = getAngleBetweenVectors(vec1, vec2);

      angle = _.round(angle, 4);

      if (angle == 0 || angle == 180) {
        return "parallel";
      } else if (angle == 90 || angle == 270) {
        return "perpendicular";
      } else {
        return "intersecting";
      }
    }
  };
  let disposeDashedBlueLines = function () {
    // let line1 = scene.getMeshByName('dashedLine1');
    // if (line1) line1.dispose();
    uiIndicatorsHandler.axisIndicator.remove();
  };
  let disposeDashedPinkLine1 = function () {
    // let line = scene.getMeshByName('dashedLinePink1');
    // if (line) line.dispose();
    //uiIndicatorsHandler.axisIndicator.remove();
  };
  let disposeDashedPinkLine2 = function () {
    // let line1 = scene.getMeshByName('dashedLinePink2');
    // if (line1) line1.dispose();
    //  uiIndicatorsHandler.axisIndicator.remove();
  };
  let disposeVertex = function () {
    uiIndicatorsHandler.vertexIndicator.remove();
  };

  let drawInitVertex = function (vertex, name) {
    if (name == "startVertex") {
      disposeVertex();
      disposeHighlightedEdges();
    }
    const options = {};
    if (name === "startVertex")
      options.indicator =
        uiIndicatorsHandler.vertexIndicator.TYPES.measureIndicator1;
    if (name === "secondVertex")
      options.indicator =
        uiIndicatorsHandler.vertexIndicator.TYPES.measureIndicator2;
    showVertexIndicator(vertex, {}, options);
  };

  let compareObjectPoint = function (point1, point2) {
    if (point1.x == point2.x && point1.y == point2.y && point1.z == point2.z)
      return true;
    else return false;
  };

  let drawDashedLinesAxisColor = function (pt1, pt2, disposeLine = true) {
    let point1 = new BABYLON.Vector3(pt1.x, pt1.y, pt1.z);
    let point2 = new BABYLON.Vector3(pt2.x, pt2.y, pt2.z);
    if (disposeLine) disposeDashedBlueLines();
    // showAxisIndicator(point1, point2, {
    //                     indicator : uiIndicatorsHandler.axisIndicator.TYPES.axis2,
    //                     extensionNotRequired: true,
    //                     color : colorUtil.type.preSnap
    //                 });
    // let attrib = {
    //     points: [point1, point2],
    //     dashNb: 10,
    //     dashSize: 3,
    //     gapSize: 1
    // }
    // let dashedLine = BABYLON.MeshBuilder.CreateDashedLines("dashedLine1", attrib, store.newScene);
    // dashedLine.visibility = 0.5;
    // dashedLine.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    // let axis = lineParallelToWhichAxis(pt1, pt2, 100);
    // dashedLine.color = (axis) ? colorUtil.getColor(colorUtil.getColorNameForType(axis)) : colorUtil.color.magenta;
    let showDist = calculate3dDistance(point1, point2);
    if (!isFloatEqual(showDist, 0)) {
      disposeDashedBlueLines();
      DisplayOperation.drawOnMove(point1, point2, "projDimLine");
      DisplayOperation.displayOnMove(showDist, "measureBox");
    }
  };

  let drawDashedLinesPink1 = function (pt1, pt2, disposeLine = true) {
    let point1 = new BABYLON.Vector3(pt1.x, pt1.y, pt1.z);
    let point2 = new BABYLON.Vector3(pt2.x, pt2.y, pt2.z);
    if (disposeLine) disposeDashedPinkLine1();
    showAxisIndicator(point1, point2, {
      indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis1,
      extensionNotRequired: true,
      color: colorUtil.type.parallel,
    });
    // let attrib = {
    //     points: [point1, point2],
    //     dashNb: 10,
    //     dashSize: 3,
    //     gapSize: 1
    // }
    // let dashedLine = BABYLON.MeshBuilder.CreateDashedLines("dashedLinePink1", attrib, store.newScene);
    // dashedLine.visibility = 0.5;
    // dashedLine.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    // let axis = lineParallelToWhichAxis(pt1, pt2, 100);
    // dashedLine.color = (axis) ? colorUtil.getColor(colorUtil.getColorNameForType(axis)) : colorUtil.color.magenta;
  };
  let drawDashedLinesPink2 = function (pt1, pt2, disposeLine = true) {
    let point1 = new BABYLON.Vector3(pt1.x, pt1.y, pt1.z);
    let point2 = new BABYLON.Vector3(pt2.x, pt2.y, pt2.z);
    if (disposeLine) disposeDashedPinkLine2();
    showAxisIndicator(point1, point2, {
      indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
      extensionNotRequired: true,
      color: colorUtil.type.parallel,
    });
    // let attrib = {
    //     points: [point1, point2],
    //     dashNb: 10,
    //     dashSize: 3,
    //     gapSize: 1
    // }
    // let dashedLine = BABYLON.MeshBuilder.CreateDashedLines("dashedLinePink2", attrib, store.newScene);
    // dashedLine.visibility = 0.5;
    // dashedLine.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    // let axis = lineParallelToWhichAxis(pt1, pt2, 100);
    // dashedLine.color = (axis) ? colorUtil.getColor(colorUtil.getColorNameForType(axis)) : colorUtil.color.magenta;
  };
  let calculate3dDistance = function (cords1, cords2) {
    let diffX = cords2.x - cords1.x;
    let diffY = cords2.y - cords1.y;
    let diffZ = cords2.z - cords1.z;
    let distance = Math.abs(
      Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2) + Math.pow(diffZ, 2))
    );
    return distance;
  };
  let lineDisplay = function (coordSet1, coordSet2, val) {
    if (coordSet1 && coordSet2) {
      if (coordSet2 != coordSet1) {
        DisplayOperation.drawOnMove(coordSet1, coordSet2);
        let dist = val ? val : calculate3dDistance(coordSet1, coordSet2);
        DisplayOperation.displayOnMove(dist, "measureBox");
      }
    }
  };
  let checkPointOnEdge = function (point, edge) {
    let edgeDis = calculate3dDistance(edge.headPt, edge.tailPt);
    let headToPoint = calculate3dDistance(point, edge.headPt);
    let tailToPoint = calculate3dDistance(point, edge.tailPt);
    if (edgeDis == headToPoint + tailToPoint) return true;
    else return false;
  };
  let resetMeasure = function () {
    let isWorking = !_.isEmpty(startPoint);
    startPoint = null;
    endPoint = null;
    downClick = 0;
    clickPickInfo = null;
    projectPoint = null;
    projectPoint1 = null;
    projectPoint2 = null;
    //options = null;
    edge1 = null;
    edge2 = null;
    edgeAngle = null;
    secondPoint = null;
    intersection = null;
    intersection1 = null;
    intersection2 = null;
    return isWorking;
  };
  return {
    onPointerDown: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        let panMode = false;
        if ((evt.ctrlKey && evt.which == 2) || evt.which == 2) {
          // store.newScene.activeCamera.attachControl(canvas, true);
          panMode = true;
        }

        if (!panMode) {
          clickPickInfo = scenePickController.pick(null, {
            pickVisibleAndInvisibleMeshes: true,
          });
          
          if (downClick == 0) {
            disposeVertex();
            disposeHighlightedEdges();
          }
          downClick = clickPickInfo.hit ? downClick + 1 : downClick;

          if (downClick == 1) {
            // store.newScene.activeCamera.detachControl(canvas);
            if (clickPickInfo.hit) {
              if (!startPoint){
                startPoint = onMovePoint
                  ? onMovePoint
                  : clickPickInfo.pickedPoint;
              }
              //  createReferenceGround(startPoint);
            }
            edge1 = (options.metadata.hasOwnProperty('snappedToEdge')) ? cloneEdge(options.metadata.snappedToEdge) : null;
            if (edge1) {
              disposeHighlightedEdges();
              disposeVertex();
              showEdgeIndicator(edge1, clickPickInfo.pickedMesh, {
                indicator:
                  uiIndicatorsHandler.edgeIndicator.TYPES.measureIndicator1,
              });
            } else if (startPoint) drawInitVertex(startPoint, "startVertex");
          }
          if (downClick == 2) {
            if (edge2) {
              showEdgeIndicator(edge2, clickPickInfo.pickedMesh, {
                indicator:
                  uiIndicatorsHandler.edgeIndicator.TYPES.measureIndicator2,
              });
            }
            if (projectPoint) {
              if (store.$scope.isTwoDimension && projectPoint) {
                projectPoint.y = startPoint.y;
                lineDisplay(startPoint, projectPoint);
                if (projectPoint) drawInitVertex(projectPoint, "secondVertex");
              } else {
                if (edgeAngle) {
                  if (edgeAngle == "parallel") {
                    // if (calculate3dDistance(projectPoint1, startPoint) > threshold) drawDashedLinesAxisColor(projectPoint1, startPoint);
                    //  if (calculate3dDistance(secondPoint, projectPoint2) > threshold) drawDashedLinesPink2(secondPoint, projectPoint2);
                    // lineDisplay(projectPoint1, projectPoint2);

                    //if both projection1 and projection2 are on the same edge, don't show
                    /* eslint-disable */
                    if (
                      doesPointLieOnEdge(projectPoint1, edge1) &&
                      doesPointLieOnEdge(projectPoint2, edge1)
                    ) {
                    } else if (
                      doesPointLieOnEdge(projectPoint1, edge2) &&
                      doesPointLieOnEdge(projectPoint2, edge2)
                    ) {
                      drawDashedLinesPink1(secondPoint, projectPoint1);
                    } else {
                      lineDisplay(projectPoint1, projectPoint2);
                    }
                    /* eslint-enable */
                  }
                  if (edgeAngle == "perpendicular") {
                    drawDashedLinesPink1(projectPoint1, startPoint);
                    if (projectPoint1 == projectPoint2)
                      lineDisplay(projectPoint1, projectPoint2, "0");
                    else lineDisplay(projectPoint1, projectPoint2);
                    if (edge2) {
                      if (
                        compareObjectPoint(projectPoint1, edge2.headPt) ||
                        compareObjectPoint(projectPoint1, edge2.tailPt)
                      ) {
                        lineDisplay(projectPoint1, projectPoint2, "0");
                      }
                    }
                    drawDashedLinesPink2(secondPoint, projectPoint2);
                  }
                  if (edgeAngle == "intersecting") {
                    // if (intersection1 && intersection2) {
                    //     if (startPoint.y != secondPoint.y) {
                    //         if (calculate3dDistance(startPoint, projectPoint) > threshold)drawDashedLinesPink1(startPoint, intersection1);
                    //         lineDisplay(intersection1, intersection2);
                    //         drawDashedLinesPink2(intersection2, secondPoint);
                    //     } else {
                    //         drawDashedLinesPink1(startPoint, intersection);
                    //         drawDashedLinesPink2(intersection, secondPoint);
                    //     }
                    // }
                    if (intersection && projectPoint) {
                      //draw extenstion at y level of measure.
                      //draw perpendicular drop to the projected point.
                      // measure perpendicular drop
                      if (startPoint.y != secondPoint.y) {
                        projectPoint1.y = startPoint.y;
                        projectPoint2.y = secondPoint.y;
                        if (
                          calculate3dDistance(projectPoint1, startPoint) >
                          threshold
                        )
                          drawDashedLinesAxisColor(projectPoint1, startPoint);
                        if (
                          calculate3dDistance(secondPoint, projectPoint2) >
                          threshold
                        )
                          drawDashedLinesPink2(secondPoint, projectPoint2);
                        if (
                          calculate3dDistance(projectPoint1, projectPoint2) >
                          threshold
                        )
                          lineDisplay(projectPoint1, projectPoint2);
                      } else {
                        if (
                          calculate3dDistance(startPoint, projectPoint) >
                          threshold
                        )
                          lineDisplay(startPoint, projectPoint);
                        if (
                          calculate3dDistance(secondPoint, projectPoint) >
                          threshold
                        )
                          drawDashedLinesPink2(secondPoint, projectPoint);
                      }
                    }
                  }
                } else {
                  lineDisplay(startPoint, secondPoint);
                  if (secondPoint) drawInitVertex(secondPoint, "secondVertex");
                }
              }
            } else {
              lineDisplay(startPoint, secondPoint);
              if (secondPoint) drawInitVertex(secondPoint, "secondVertex");
            }
          }
        }
      }
    },
    onPointerMove: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        disposeSnapToObjects();
        projectPoint = null;

        let predicate = function (mesh) {
          return snapMeshforMeasure(mesh);
        };

        highlightPickInfo = scenePickController.pick();
        /*
                options = {
                    wantMetadata: true,
                    faceSnap: false,
                    pickInfo:highlightPickInfo
                }
                onMovePoint = findPrioritizedSnapPoint(highlightPickInfo.pickedPoint, null, highlightPickInfo.pickedMesh, options);
                */

        options = {
          wantMetadata: true,
          faceSnap: false,
          indicatorType:
            uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
          doNotDoSecondaryScenePicks: !store.$scope.isTwoDimension,
          attemptCadSnaps: true,
          pickNeighborhoodMeshes: true,
          doNotReturnUnsnappedPoint: false
        };

        onMovePoint = findPrioritizedSnapPoint(null, null, null, options);

        secondPoint = onMovePoint ? onMovePoint : highlightPickInfo.pickedPoint;

        if (downClick >= 2) {
          resetMeasure();
        }
        if (downClick == 1) {
          disposeDashedBlueLines();
          disposeDashedPinkLines();
          DisplayOperation.removeDimensions();
          if (store.$scope.isTwoDimension) secondPoint.y = startPoint.y;
          let axisData = snapHorizontallyAndVertically(secondPoint, startPoint);
          if (axisData) {
            secondPoint = axisData.pt;
          }
          if (options.metadata) {
            if (options.metadata.hasOwnProperty("snappedToEdge")) {
              disposeDashedBlueLines();
              disposeDashedPinkLines();
              projectPoint = projectionOfPointOnLine(
                startPoint,
                options.metadata.snappedToEdge.headPt,
                options.metadata.snappedToEdge.tailPt
              );
              projectPoint1 = projectPoint.clone();
              projectPoint2 = projectPoint.clone();

              if (store.$scope.isTwoDimension) {
                projectPoint.y = startPoint.y;
                lineDisplay(projectPoint, startPoint);
                drawDashedLinesPink1(secondPoint, projectPoint);
                edge2 = (options.metadata.hasOwnProperty('snappedToEdge')) ? cloneEdge(options.metadata.snappedToEdge) : null;
              } else if (edge1) {
                edgeAngle = checkEdges(edge1);
                edge2 = (options.metadata.hasOwnProperty('snappedToEdge')) ? cloneEdge(options.metadata.snappedToEdge) : null;
                if (edgeAngle == "parallel") {
                  projectPoint1.y = startPoint.y;
                  projectPoint2.y = secondPoint.y;
                  if (
                    calculate3dDistance(projectPoint1, startPoint) > threshold
                  )
                    drawDashedLinesAxisColor(projectPoint1, startPoint);
                  if (
                    calculate3dDistance(secondPoint, projectPoint2) > threshold
                  )
                    drawDashedLinesPink2(secondPoint, projectPoint2);

                  //if both projection1 and projection2 are on the same edge, don't show
                  /* eslint-disable */
                  if (
                    doesPointLieOnEdge(projectPoint1, edge1) &&
                    doesPointLieOnEdge(projectPoint2, edge1)
                  ) {
                  } else if (
                    doesPointLieOnEdge(projectPoint1, edge2) &&
                    doesPointLieOnEdge(projectPoint2, edge2)
                  ) {
                    drawDashedLinesPink1(secondPoint, projectPoint1);
                  } else {
                    if (
                      calculate3dDistance(projectPoint1, projectPoint2) >
                      threshold
                    )
                      lineDisplay(projectPoint1, projectPoint2);
                  }
                  /* eslint-enable */
                  // only projection distance between them if y is equal.
                  //else both drop and projection if y is not equal.
                }
                if (edgeAngle == "perpendicular") {
                  projectPoint1.y = startPoint.y;
                  projectPoint2.y = secondPoint.y;
                  drawDashedLinesPink1(projectPoint1, startPoint);
                  if (projectPoint1 == projectPoint2)
                    lineDisplay(projectPoint1, projectPoint2, "0");
                  else lineDisplay(projectPoint1, projectPoint2);
                  if (
                    compareObjectPoint(projectPoint1, edge2.headPt) ||
                    compareObjectPoint(projectPoint1, edge2.tailPt)
                  ) {
                    lineDisplay(projectPoint1, projectPoint2, "0");
                  }
                  drawDashedLinesPink2(secondPoint, projectPoint2);
                }
                if (edgeAngle == "intersecting") {
                  let args = [
                    edge1.headPt,
                    edge1.tailPt,
                    options.metadata.snappedToEdge.headPt,
                    options.metadata.snappedToEdge.tailPt,
                  ];
                  let atrib = {
                    type: "vector-vector",
                    ignoreY: true,
                  };
                  let edge2 = options.metadata.snappedToEdge;
                  intersection = externalUtil.getPointOfIntersection(
                    args,
                    atrib
                  );
                  if (intersection && projectPoint) {
                    //draw extenstion at y level of measure.
                    //draw perpendicular drop to the projected point.
                    // measure perpendicular drop
                    if (startPoint.y != secondPoint.y) {
                      projectPoint1.y = startPoint.y;
                      projectPoint2.y = secondPoint.y;
                      if (
                        calculate3dDistance(projectPoint1, startPoint) >
                        threshold
                      )
                        drawDashedLinesAxisColor(projectPoint1, startPoint);
                      if (
                        calculate3dDistance(secondPoint, projectPoint2) >
                        threshold
                      )
                        drawDashedLinesPink2(secondPoint, projectPoint2);
                      if (
                        calculate3dDistance(projectPoint1, projectPoint2) >
                        threshold
                      )
                        lineDisplay(projectPoint1, projectPoint2);
                    } else {
                      if (
                        calculate3dDistance(startPoint, projectPoint) >
                        threshold
                      )
                        lineDisplay(startPoint, projectPoint);
                      if (
                        calculate3dDistance(secondPoint, projectPoint) >
                        threshold
                      )
                        drawDashedLinesPink2(secondPoint, projectPoint);
                    }
                  }
                  //only extension no measure.
                  // if (startPoint.y != secondPoint.y) {,
                  //     intersection1 = Object.assign({}, intersection);
                  //     intersection2 = Object.assign({}, intersection);
                  //     intersection1.y = startPoint.y;
                  //     intersection2.y = secondPoint.y;
                  //     drawDashedLinesPink1(startPoint, intersection1);
                  //     lineDisplay(intersection1, intersection2);
                  //     drawDashedLinesPink2(intersection2, secondPoint);
                  // } else {
                  //     intersection.y = startPoint.y;
                  //     drawDashedLinesPink1(startPoint, intersection);
                  //     drawDashedLinesPink2(intersection, secondPoint);
                  // }
                  else {
                    let projection = projectionOfPointOnLine(
                      startPoint,
                      edge2.headPt,
                      edge2.tailPt
                    );
                    if (projection) {
                      lineDisplay(startPoint, projection);
                      if (
                        !new ResolveEngineUtils().onSegment3D(
                          edge2.headPt,
                          projection,
                          edge2.tailPt
                        )
                      ) {
                        // projection is not on edge
                        let closerVertex = _.minBy(
                          [edge2.headPt, edge2.tailPt],
                          (e) => BABYLON.Vector3.Distance(e, projection)
                        );
                        drawDashedLinesPink1(closerVertex, projection);
                      }
                    }
                  }
                }
              } else if (startPoint) {
                lineDisplay(startPoint, secondPoint);
              }
            } else if (startPoint) lineDisplay(startPoint, secondPoint);
          }
        }
      }
    },
    onPointerUp: function (evt) {
      // store.newScene.activeCamera.attachControl(canvas, true);
    },
    cleanUp: function () {
      let isWorking = resetMeasure();
      disposeDashedBlueLines();
      disposeDashedPinkLines();
      disposeVertex();
      disposeVertex();
      disposeHighlightedEdges();
      disposeHighlightedEdges();
      DisplayOperation.removeDimensions();
      return isWorking;
    },
    distanceBtwPts: function (pt1, pt2) {
      return calculate3dDistance(pt1, pt2);
    },
  };
})();
export { measureFunction };
