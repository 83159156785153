import Victor from "victor";
import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { loadIntComponent, offsetInterior } from "./interiors_gen.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds.js";
function loadSofa(name, room_pol, flag) {
  if (flag) {
    var scale_factor = store.unit_scale;
    var comp_path =
      window.location.origin + "/media/models/living/sofa/" + name + ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/living/sofa/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadSofa(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function loadChair(name, room_pol, flag) {
  if (flag) {
    //console.log(name);
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/living/chair/" +
      name +
      ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/living/chair/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadChair(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function loadTable(name, room_pol, flag) {
  if (flag) {
    //console.log(name);
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/living/table/" +
      name +
      ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/living/table/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadTable(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function loadSideTable(name, room_pol, flag) {
  if (flag) {
    //console.log(name);
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/living/stable/" +
      name +
      ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/living/stable/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadSideTable(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function loadCoffeeTable(name, room_pol, flag) {
  if (flag) {
    //console.log(name);
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/living/ctable/" +
      name +
      ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/living/ctable/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadCoffeeTable(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function placeSofaComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "sofa",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeSofaComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeSofaComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  newInstance.storey = storey.value;

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForSofa(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function placeChairComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "chair",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeChairComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeChairComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  newInstance.storey = storey.value;

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForChair(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function placeTableComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "table",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeTableComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeTableComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  newInstance.storey = storey.value;

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForTable(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function placeSideTableComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "sidetable",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeSideTableComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeSideTableComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  newInstance.storey = storey.value;

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForSideTable(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function placeCoffeeTableComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "coffeetable",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeCoffeeTableComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeCoffeeTableComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  newInstance.structure_id = storey.structure_id;
  newInstance.storey = storey.value;

  let level = structure.getLevel(0, 1);

  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForCoffeeTable(coords, newInstance);
  furniture.updateBase(base);

  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function getRoomOrientationForSofa(coords, newInstance) {
  let sofaOffset = 7.5;
  offsetInterior(coords, newInstance, sofaOffset);
}

function getRoomOrientationForChair(coords, newInstance) {
  let chairOffset1 = 4.5;
  let chairOffset2 = 5.5;
  offsetInterior(coords, newInstance, chairOffset1);

  // Victor
  let c1 = coords[0];
  let c2 = coords[1];

  let vecC1 = new Victor.fromArray([(c1[0] + c2[0]) / 2, (c1[1] + c2[1]) / 2]);
  let vecC2 = new Victor(newInstance.position.x, newInstance.position.z);

  let vecPerpendicular = new Victor.fromArray([
    vecC1.y - vecC2.y,
    vecC2.x - vecC1.x,
  ]);
  let vecInterior = vecC2.add(
    vecPerpendicular
      .normalize()
      .multiply(new Victor(chairOffset2, chairOffset2))
  );

  newInstance.position.x = vecInterior.x;
  newInstance.position.z = vecInterior.y;
}

function getRoomOrientationForTable(coords, newInstance) {
  let wallThickness = 200 / (store.unit_absolute_scale * 10000);
  offsetInterior(coords, newInstance, wallThickness);
}

function getRoomOrientationForSideTable(coords, newInstance) {
  let sideTableOffset1 = 4.5;
  let sideTableOffset2 = 5.5;
  offsetInterior(coords, newInstance, sideTableOffset1);

  // Victor
  let c1 = coords[0];
  let c2 = coords[1];

  let vecC1 = new Victor.fromArray([(c1[0] + c2[0]) / 2, (c1[1] + c2[1]) / 2]);
  let vecC2 = new Victor(newInstance.position.x, newInstance.position.z);

  let vecPerpendicular = new Victor.fromArray([
    vecC1.y - vecC2.y,
    vecC2.x - vecC1.x,
  ]);
  let vecInterior = vecC2.subtract(
    vecPerpendicular
      .normalize()
      .multiply(new Victor(sideTableOffset1, sideTableOffset2))
  );

  newInstance.position.x = vecInterior.x;
  newInstance.position.z = vecInterior.y;
}

function getRoomOrientationForCoffeeTable(coords, newInstance) {
  let coffeeTableOffset = 3.5;
  offsetInterior(coords, newInstance, coffeeTableOffset);
}

// function getRoomOrientationForSofa(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     var sofa_offset = 3.5 * store.inch_to_mtr * 10;
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * store.unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * store.unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var intersectWallThickness = 0;
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + sofa_offset) * Math.cos(angle);
//     }
// }
//
// function getRoomOrientationForChair(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     var chair_offset1 = 1.5 * store.inch_to_mtr * 10;
//     var chair_offset2 = 2.5 * store.inch_to_mtr * 10;
//     var intersectWallThickness = 0;
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * store.unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * store.unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     // var aa = newInstance.position.x;
//     // var bb = newInstance.position.z;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + chair_offset1) * Math.cos(angle);
//     }
//     //console.log(newInstance.position);
//     var xx = newInstance.position.x;
//     var zz = newInstance.position.z;
//     if (orientation_room === "q3") {
//         // newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (chair_offset2) * Math.sin(angle);
//         newInstance.position.z += (chair_offset2) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         // newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (chair_offset2) * Math.sin(angle);
//         newInstance.position.z += (chair_offset2) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         // newInstance.rotation.y = angle;
//         newInstance.position.x -= (chair_offset2) * Math.sin(angle);
//         newInstance.position.z -= (chair_offset2) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         // newInstance.rotation.y = -angle;
//         newInstance.position.x += (chair_offset2) * Math.sin(angle);
//         newInstance.position.z -= (chair_offset2) * Math.cos(angle);
//     }
//     var x = newInstance.position.x;
//     var z = newInstance.position.z;
//     newInstance.position.x = -(z - zz);
//     newInstance.position.z = (x - xx);
//     // newInstance.setPivotMatrix(BABYLON.Matrix.Translation(xx, 0, zz));
//     // newInstance.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.LOCAL);
//     // newInstance.setPivotMatrix((BABYLON.Matrix.Translation(newInstance.position)));
//     newInstance.position.x += xx;
//     newInstance.position.z += zz;
//     //console.log(newInstance.position);
// }
//
// function getRoomOrientationForTable(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     var table_offset = 0;
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * store.unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * store.unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var intersectWallThickness = 0;
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + table_offset) * Math.cos(angle);
//     }
// }
//
// function getRoomOrientationForSideTable(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     var stable_offset1 = 1.5 * store.inch_to_mtr * 10;
//     var stable_offset2 = 2 * store.inch_to_mtr * 10;
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * store.unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * store.unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var intersectWallThickness = 0;
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     //console.log(XX, YY, ZZ);
//
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + stable_offset1) * Math.cos(angle);
//     }
//     //console.log(newInstance);
//     // newInstance.computeWorldMatrix(true);
//     // newInstance.freezeWorldMatrix();
//     // bbinfo.update(newInstance._worldMatrix);
//     var xx = newInstance.position.x;
//     var zz = newInstance.position.z;
//     var yy = newInstance.position.y;
//     // newInstance.unfreezeWorldMatrix();
//     if (orientation_room === "q3") {
//         // newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (stable_offset2) * Math.sin(angle);
//         newInstance.position.z += (stable_offset2) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         // newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (stable_offset2) * Math.sin(angle);
//         newInstance.position.z += (stable_offset2) * Math.cos(angle);
//         //console.log((stable_offset2) * Math.cos(angle));
//     }
//     else if (orientation_room === "q1") {
//         // newInstance.rotation.y = angle;
//         newInstance.position.x -= (stable_offset2) * Math.sin(angle);
//         newInstance.position.z -= (stable_offset2) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         // newInstance.rotation.y = -angle;
//         newInstance.position.x += (stable_offset2) * Math.sin(angle);
//         newInstance.position.z -= (stable_offset2) * Math.cos(angle);
//     }
//     // newInstance.setPivotMatrix(BABYLON.Matrix.Translation(xx, yy, zz));
//     // newInstance.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.LOCAL);
//     // newInstance.setPivotMatrix((BABYLON.Matrix.Translation(newInstance.position)));
//     var x = newInstance.position.x;
//     var z = newInstance.position.z;
//     // //console.log(z, zz);
//     // //console.log(x, xx);
//     newInstance.position.x = (z - zz);
//     newInstance.position.z = -(x - xx);
//     newInstance.position.x += xx;
//     newInstance.position.z += zz;
//     //console.log(newInstance.position);
//     //console.log(newInstance);
// }
//
// function getRoomOrientationForCoffeeTable(coords, newInstance) {
//     var c1 = coords[0];
//     var c2 = coords[1];
//     var c3 = coords[2];
//     var angle = getSlopeAngle(c1, c2);
//     var orientation = getCompOrientation(newInstance);
//     var orientation_room = getRoomAngle(c1, c2, c3);
//     var bbinfo = newInstance.getBoundingInfo();
//     var ctable_offset = 1.5 * store.inch_to_mtr * 10;
//     newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * store.unit_scale;
//     newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * store.unit_scale;
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
//     newInstance.unfreezeWorldMatrix();
//     if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
//
//     newInstance.freezeWorldMatrix();
//     bbinfo.update(newInstance._worldMatrix);
//     var intersectWallThickness = 0;
//     // //console.log(bbinfo.boundingBox.maximumWorld.x);
//     // //console.log(bbinfo.boundingBox.minimumWorld.x);
//     var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
//     var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
//     var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
//     newInstance.unfreezeWorldMatrix();
//
//     //console.log(orientation_room);
//     //console.log(angle);
//     // //console.log(bbinfo);
//     // //console.log(XX, YY, ZZ);
//
//     for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
//         if (newInstance.intersectsMesh(a.data, true)) {
//             intersectWallThickness = a.data._properties._thickness;
//             break;
//         }
//     }
//
//     if (orientation_room === "q3") {
//         newInstance.rotation.y = Math.PI + angle;
//         newInstance.position.x += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.sin(angle);
//         newInstance.position.z += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q4") {
//         newInstance.rotation.y = -angle - Math.PI;
//         newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.sin(angle);
//         newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q1") {
//         newInstance.rotation.y = angle;
//         newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.sin(angle);
//         newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.cos(angle);
//     }
//     else if (orientation_room === "q2") {
//         newInstance.rotation.y = -angle;
//         newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.sin(angle);
//         newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale + ctable_offset) * Math.cos(angle);
//     }
// }
export {
  loadSofa,
  loadChair,
  loadTable,
  loadSideTable,
  loadCoffeeTable,
  placeSofaComp,
  arrangeSofaComp,
  placeChairComp,
  arrangeChairComp,
  placeTableComp,
  arrangeTableComp,
  placeSideTableComp,
  arrangeSideTableComp,
  placeCoffeeTableComp,
  arrangeCoffeeTableComp,
  getRoomOrientationForSofa,
  getRoomOrientationForChair,
  getRoomOrientationForTable,
  getRoomOrientationForSideTable,
  getRoomOrientationForCoffeeTable,
};
