import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/closeBg.svg";
import NotificationImg from "../../assets/images/notification.svg";

const Card = styled(Outer)`
  width: 13.9375rem;
  background-color: ${(props) => props.theme.colors.clearWhite};
  padding: 0 1rem 0 0;
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  padding-left: 1rem;
`;

const NotificationImage = styled.img`
  width: 4rem;
  height: 4rem;
  padding-top: 0.875rem;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const CloseButtonContainer = styled(Flex)`
  z-index: 100,
  position: absolute;
  top: 0.875rem;
  right: 0.5rem;
`;

function Notification({ heading, body, onClose, isOpen }) {
  return (
    <>
      {isOpen && (
        <Card>
          <CloseButtonContainer>
            <CloseButtonIcon src={CloseButton} onClick={onClose} />
          </CloseButtonContainer>
          <Flex flexDirection="column">
            <NotificationImage src={NotificationImg} />
            <Text opacity={1} weight="bold">
              {heading}
            </Text>
            <Text style={{ marginTop: 0 }} opacity={0.8} lineHeight="1.3125em">
              {body}
            </Text>
          </Flex>
        </Card>
      )}
    </>
  );
}

Notification.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Notification;
