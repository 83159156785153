const signedArea = (polygon) => {
  let area = 0.0;
  const n = polygon.length;
  for (let i = 0; i < n; i++) {
    let j = (i + 1) % n;
    area += polygon[i][0] * polygon[j][1];
    area -= polygon[j][0] * polygon[i][1];
  }
  return area / 2.0;
};

const area = (polygon) => Math.abs(signedArea(polygon));

const isPolygonClockwise = (polygon) => {
  return signedArea(polygon) < 0;
};

const isPolygonAntiClockwise = (polygon) => {
  return signedArea(polygon) > 0;
};
export { signedArea, area, isPolygonClockwise, isPolygonAntiClockwise };
