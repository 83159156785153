import BABYLON from "../babylonDS.module.js";
import * as math from "mathjs";
/**
 * Gives a list of points from a given path.
 * @param path Array of array of 3D points.
 * @param numberOfPoints Number of points in array.
 * @returns {Array} Array of 3D points.
 *
 * Example path:
 * [ [[0, 0, 0], [1, 1, 1]], [[1, 1, 1], [2, 2, 2], [3, 3, 3]], [[3, 3, 3], [0, 0, 0]] ]
 */
const pathToPoints = (path, numberOfPoints = 100) => {
  const pointArray = [];
  for (let i = 0; i < path.length; i++) {
    const singlePath = path[i];
    if (singlePath.length === 2) {
      pointArray.push(...singlePath);
    } else if (singlePath.length === 3) {
      const p1 = new BABYLON.Vector3(
        singlePath[0][0],
        singlePath[0][1],
        singlePath[0][2]
      );
      const p2 = new BABYLON.Vector3(
        singlePath[1][0],
        singlePath[1][1],
        singlePath[1][2]
      );
      const p3 = new BABYLON.Vector3(
        singlePath[2][0],
        singlePath[2][1],
        singlePath[2][2]
      );
      const bezier2 = BABYLON.Curve3.CreateQuadraticBezier(
        p1,
        p2,
        p3,
        numberOfPoints
      );
      const points = bezier2.getPoints();
      const arrayPoints = points.map((point) => point.asArray());
      pointArray.push(...arrayPoints);
    } else if (singlePath.length === 4) {
      const p1 = new BABYLON.Vector3(
        singlePath[0][0],
        singlePath[0][1],
        singlePath[0][2]
      );
      const p2 = new BABYLON.Vector3(
        singlePath[1][0],
        singlePath[1][1],
        singlePath[1][2]
      );
      const p3 = new BABYLON.Vector3(
        singlePath[2][0],
        singlePath[2][1],
        singlePath[2][2]
      );
      const p4 = new BABYLON.Vector3(
        singlePath[3][0],
        singlePath[3][1],
        singlePath[3][2]
      );
      const bezier3 = BABYLON.Curve3.CreateCubicBezier(
        p1,
        p2,
        p3,
        p4,
        numberOfPoints
      );
      const points = bezier3.getPoints();
      const arrayPoints = points.map((point) => point.asArray());
      pointArray.push(...arrayPoints);
    }
  }
  return pointArray;
};

/**
 * Converts a list of 2D path into 3D path, by appending the last coordinate as 0.
 * @param path 2D Path
 * @returns {Array} 3D Path
 */
const path2ToPath3 = (path) =>
  path.map((setOfControlPoints) =>
    setOfControlPoints.map((point) => [point[0], point[1], 0])
  );

/**
 * Useful in adding height to path 3D.
 * @param path 3D path
 * @param Z
 * @returns {Array} 3D path
 */
const addZToPath = (path, Z) =>
  path.map((setOfControlPoints) =>
    setOfControlPoints.map((point) => [point[0], point[1], point[2] + Z])
  );

/**
 * Useful in adding height to pointArray 3D.
 * @param pointArray 3D pointArray
 * @param Z
 * @returns {Array} 3D pointArray
 */
const addZToPointArray = (pointArray, Z) =>
  pointArray.map((point) => [point[0], point[1], point[2] + Z]);

/**
 * Gives Y-Z swapped path array. Useful in converting XY systems into XZ.
 * @param pathArray
 * @returns {Array}
 */
const swapYAndZInPathArray = (pathArray) => {
  const swappedPath = [];
  for (let i = 0; i < pathArray.length; i++) {
    const singlePath = pathArray[i];
    const swappedSinglePath = [];
    for (let j = 0; j < singlePath.length; j++) {
      const point = singlePath[j];
      swappedSinglePath.push([point[0], point[2], point[1]]);
    }
    swappedPath.push(swappedSinglePath);
  }
  return swappedPath;
};

/**
 * Checks if these two arrays have minimum two points in common.
 * @param faceVertices
 * @param path
 * @returns {boolean}
 */
const checkIfCommonPoint = (faceVertices, path) => {
  for (let i = 0; i < faceVertices.length; i++) {
    for (let j = 0; j < path.length; j++) {
      if (faceVertices[i].almostEquals(path[j])) return true;
    }
  }
  return false;
};

/**
 * Checks if a given point is in given array.
 * @param point
 * @param array
 * @returns {boolean}
 */
const checkIfPointInArray = (point, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].almostEquals(point)) return true;
  }
  return false;
};

/**
 * If you take an array of first elements of all elements of a path, it becomes as good as a point array for simple polygon
 * This function does that
 * @param path
 * @returns {*}
 */
const pathToFaceVerticesForBRep = (path) =>
  path.map((singlePath) => singlePath[0]);

/**
 * Gives the dimension of array. Prefer using getDimensionOfArray for simplicity.
 * @param arr
 * @returns {number[]|*[]}
 */
const dimensionOfArray = (arr) => {
  if (arr instanceof Array) {
    return [arr.length].concat(dimensionOfArray(arr[0]));
  } else {
    return [];
  }
};

/**
 * Gives the dimension of the array.
 * @param arr
 * @returns {number}
 */
const getDimensionOfArray = (arr) => dimensionOfArray(arr).length;

/**
 * Returns the difference of the two points.
 * @param point1
 * @param point2
 * @returns {number[]}
 */
const subtractPoint = (point1, point2) => [
  point2[0] - point1[0],
  point2[1] - point1[1],
  point2[2] - point1[2],
];

/**
 * Scalar division of point.
 * @param point
 * @param scalar
 * @returns {number[]}
 */
const dividePoint = (point, scalar) => [
  point[0] / scalar,
  point[1] / scalar,
  point[2] / scalar,
];

const transform3Dto2D = (pointArray) => {
  const loc0 = pointArray[0];
  let locX = subtractPoint(pointArray[1], pointArray[0]);
  const normal = math.cross(
    pointArray[0],
    subtractPoint(pointArray[2], pointArray[1])
  );
};

const samplePath1 = [
  [
    [
      [27, -17, 0],
      [27, -8, 0],
    ],
    [
      [27, -8, 0],
      [22, -8, 0],
      [16, -8, 0],
    ],
    [
      [16, -8, 0],
      [16, -11, 0],
      [16, -14, 0],
      [16, -17, 0],
    ],
    [
      [16, -17, 0],
      [27, -17, 0],
    ],
  ],
];

const samplePath2 = [
  [
    [
      [27, -17, 0],
      [27, -8, 0],
    ],
    [
      [27, -8, 0],
      [22, -4, 0],
      [16, -8, 0],
    ],
    [
      [16, -8, 0],
      [16, -17, 0],
    ],
    [
      [16, -17, 0],
      [22, -21, 0],
    ],
    [
      [22, -21, 0],
      [27, -17, 0],
    ],
  ],
];

const sampleBigPath = [
  [
    [
      [752, 384],
      [804.9167363746016, 856.2445714472922],
      [1487.0021925306505, 757.8583015974065],
      [1383, 1268],
    ],
    [
      [1383, 1268],
      [1417.8705624572856, 1311.9341886955467],
      [1471.4318500026905, 1362.3209786733357],
      [1567, 1351],
    ],
    [
      [1567, 1351],
      [1479, 598],
    ],
    [
      [1479, 598],
      [1393.9056863171477, 604.0439229621549],
      [1329.4511990961757, 601.48370807633],
      [1240, 627],
    ],
    [
      [1240, 627],
      [1215, 348],
    ],
    [
      [1215, 348],
      [1096.8321747600955, 346.89855890024955],
      [886.0205251908751, 369.5244297910835],
      [767, 384],
    ],
  ],
  [],
];
export { pathToPoints,path2ToPath3,addZToPath,addZToPointArray,swapYAndZInPathArray,checkIfCommonPoint,checkIfPointInArray,pathToFaceVerticesForBRep,dimensionOfArray,getDimensionOfArray,subtractPoint,dividePoint,transform3Dto2D,samplePath1,samplePath2,sampleBigPath };
