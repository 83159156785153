/*jshint esversion: 6 */
"use strict";
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js"
import { massDissector } from "../createBuilding/massDissector.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";
import { offsetCurvePath,generatePointsFromPath } from "../../libs/curvedWallFuncs.js";
/**
 * { creates normal room }
 *
 * @param      {<type>}  mesh        The mesh
 * @param      {number}  h_internal  The h internal
 * @param      {number}  h_external  The h external
 * @return     {Array}   { description_of_the_return_value }
 */
function normal(mesh) {
  /*function _populateRoofInformation(){

        let roofInfoHolderObject = shelf.retrieve();
        if (!roofInfoHolderObject[mass.storey]) {
            let object = {};
            object.level_id= mass.level_id;
            object.roofs = [];
            roofInfoHolderObject[mass.storey] = object;
        }

        dissection.roofs.forEach(points => {

            if (mesh.room_type.toLowerCase() === "balcony") {

                let storeyData = StructureCollection.getInstance().getStructureById(mesh.structure_id).getStoreyData();
                let heightObj = storeyData.getStoreyByValue(mesh.storey);

                points = adjustHeightOfHalfRoofs(points, heightObj.base + heightObj.height - plainRoofParameters.roofDepth);
            }

            roofInfoHolderObject[mass.storey].roofs.push(points);

        })

    }*/

  // bool used is from polybooljs(https://github.com/velipso/polybooljs)
  function determineFloorPoints(dissection) {
    function processPoints(points) {
      // points = removeDuplicateVerticesModified(points);
      // points = removecollinear(points);
      // points = roundAndRemoveDuplicates(points);
      // points = equateDecimalPoints(points);
      points = points.map((point) => [
        parseFloat(point[0].toFixed(4)),
        parseFloat(point[1].toFixed(4)),
      ]);
      return points;
    }
    let walls = dissection.walls;
    let floors = dissection.floors;
    let floorsPoints = [];
    store.bool.epsilon(1e-5);
    floors.forEach((floor) => {
      let newFloor;
      newFloor = {
        regions: [floor.map((point) => [point[0], point[1]])],
        inverted: false,
      };
      newFloor.regions[0] = processPoints(newFloor.regions[0]);
      walls.forEach((wall) => {
        let wallPoints = processPoints(
          wall.bottomCoords.map((point) => [point[0], point[1]])
        );
        newFloor = store.bool.difference(newFloor, {
          regions: [wallPoints],
          inverted: false,
        });
        newFloor.regions[0] = processPoints(newFloor.regions[0]);
      });
      floorsPoints.push(
        newFloor.regions[0].map((point) => [point[0], point[1], 0])
      );
      // floorsPoints.push(bool.difference(
      //     {regions: [floor.map(point => [point[0], point[1]])], inverted: false},
      //     {regions: walls.map(wall => wall.bottomCoords.map(point => [point[0], point[1]])), inverted: false}
      // ).regions[0]);
    });

    // floors.forEach(floor => {
    //     let newFloor;
    //     // newFloor = {regions: [floor.map(point => [point[0], point[1]])], inverted: false};
    //     newFloor = floor.map(point => [point[0], point[1]]);
    //     // newFloor.regions[0] = processPoints(newFloor.regions[0]);
    //     walls.forEach(wall => {
    //         let wallPoints = wall.bottomCoords.map(point => [point[0], point[1]]);
    //         newFloor = greinerHormann.diff(newFloor, wallPoints);
    //         newFloor = newFloor[0];
    //         // newFloor = bool.difference(newFloor, {regions: [wallPoints], inverted: false});
    //         // newFloor.regions[0] = processPoints(newFloor.regions[0]);
    //     });
    //     floorsPoints.push(newFloor.map(point => [point[0], point[1], 0]));
    //     // floorsPoints.push(bool.difference(
    //     //     {regions: [floor.map(point => [point[0], point[1]])], inverted: false},
    //     //     {regions: walls.map(wall => wall.bottomCoords.map(point => [point[0], point[1]])), inverted: false}
    //     // ).regions[0]);
    // });

    store.bool.epsilon(1e-10);
    return floorsPoints;
  }

  let mass = mesh.getSnaptrudeDS();
  let dissection = massDissector.dissect(mesh);

  if (!dissection) {
    console.warn("Mass dissection failed");
    return;
  }

  createBuildingEngine.addToMassWallPointsMapping(mass, dissection.walls);

  // mesh.floor_pol = determineFloorPoints(dissection);

  mesh.floor_pol = dissection.floors;

  // mesh.midY = dissection.metadata.midY;

  mesh.roof_pol = dissection.roofs;
  // _populateRoofInformation();

  /*
    mesh.walls = dissection.walls;
    let walls = straightwall(mesh);

    // This is to mark the walls generated from edited masses as Edited
    if(mesh.getSnaptrudeDS().isEdited()){
        walls.forEach(wall => {
            wall.markAsEdited();
        });
    }

    if (createBuildingGlobalVariables.changeParentChildRelationships){
        //for non-room masses
        mesh.getChildren().forEach(child => {
            walls.some(wall => {
                if (wall.mesh.intersectsMesh(child, true)) {
                    child.setParent(wall.mesh);
                    wall.mesh.childrenComp.push(child);
                    return true;
                }
            });
        });
    }

    return walls;
    */

  return [];
}

/**
 * { creates curved rooms }
 *
 * @param      {<type>}   mesh        The mesh
 * @param      {<type>}   h_internal  The h internal
 * @param      {<type>}   h_external  The h external
 * @return     {BABYLON}  { description_of_the_return_value }
 */
function curved(mesh, h_internal, h_external) {
  let wall, midY, wall_path_bottom, wall_path_top;
  var offsetPathExternal = offsetCurvePath(mesh.room_path, h_external);
  var offsetPathInternal = offsetCurvePath(mesh.room_path, h_internal);
  var bezierPoints1 = generatePointsFromPath(
    JSON.stringify(offsetPathExternal)
  );
  var bezierPoints2 = generatePointsFromPath(
    JSON.stringify(offsetPathInternal)
  );
  var ribPolCurveWall = new BABYLON.PolygonMeshBuilder(
    "ribpolcurveWall",
    bezierPoints2,
    store.scene
  );
  ribPolCurveWall.addHole(bezierPoints1);
  var curveWall = ribPolCurveWall.build(null, 10);
  var curveBBinfo = curveWall.getBoundingInfo();

  if (curveBBinfo.boundingBox.minimumWorld.y < 0)
    curveWall.position.y += Math.abs(curveBBinfo.boundingBox.minimumWorld.y);
  // curveWall.position.y += 2*Math.abs(curveBBinfo.boundingBox.minimumWorld.y);

  curveWall.name = "wall";
  curveWall.type = "wall";
  curveWall.room_name = mesh.room_name;
  curveWall.room_type = mesh.room_type;
  curveWall.room_path = mesh.room_path;
  curveWall.room_curve = mesh.room_curve;
  wall.assignProperties();
  wall.setBottomCoords(wall_path_bottom);
  wall.setTopCoords(wall_path_top);
  wall.setMidYHeight(midY);
  curveWall.structure_id = mesh.structure_id;

  curveWall.storey = mesh.storey;
  // curveWall.assignProperties();

  // curveWall.refreshBoundingInfo();
  // let bbinfo = curveWall.getBoundingInfo();
  // let center = bbinfo.boundingBox.centerWorld;
  // let relativePosition = center.subtract(curveWall.position);
  // curveWall.setPivotPoint(relativePosition);
  let bbinfo = curveWall.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  curveWall.setPivotPoint(centroid);
  curveWall.structure_id = mesh.structure_id;

  var ribPolCurveFloor = new BABYLON.PolygonMeshBuilder(
    "ribpolcurveFloor",
    bezierPoints1,
    store.scene
  );
  var curveFloor = ribPolCurveFloor.build(null, 0.1);
  curveFloor.position.y = 0.1;
  curveFloor.name = "Floor";
  curveFloor.type = "Floor";
  curveFloor.room_name = mesh.room_name;
  curveFloor.room_type = mesh.room_type;
  curveFloor.room_path = mesh.room_path;
  curveFloor.room_curve = mesh.room_curve;
  curveFloor.material = store.scene.getMaterialByName("floor_tile");

  var ribPolCurveCeiling = new BABYLON.PolygonMeshBuilder(
    "ribpolcurveCeiling",
    bezierPoints2,
    store.scene
  );
  var curveCeiling = ribPolCurveCeiling.build(null, 0.5);
  curveCeiling.position.y = 10.5;
  curveCeiling.name = "Roof";
  curveCeiling.type = "Roof";
  curveCeiling.room_name = mesh.room_name;
  curveCeiling.room_type = mesh.room_type;
  curveCeiling.room_path = mesh.room_path;
  curveCeiling.room_curve = mesh.room_curve;
  curveCeiling.material = store.scene.getMaterialByName("ceiling_mat");

  //assign pol1 to mesh for floor creation
  // mesh.floor_pol1 = pol1;

  //assign pol1 to mesh for roof creation
  // mesh.roof_pol1 = pol1;
  // return curveCeiling;
}
export { normal,curved };
