import $ from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { MeshGroups } from "../modules/constants.module.js";
import { CMRedo, CMUndo, nonDefaultMesh, updateModifications, } from "./sceneStateFuncs.js";
import {
  deepCopyObject,
  focusBabylonGUIElement,
  getEmptyFunction,
  getSerializedFormOfMesh,
  isMeshNonSelectable,
  isMeshThrowAway,
} from "../modules/extrafunc.js";
import { Mass } from "../modules/snaptrudeDS/mass.ds.js";
import { drawSelectionBox, removeChildren, removeRotationLines, removeSelectionBox, } from "./meshEvents.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import { delayedExecutionEngine } from "../modules/utilityFunctions/delayedExecution.js";
import { pasteObject } from "./defaultEvents.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { updateRoofAccordion } from "./roofVisibilityFuncs.js";
import { drawingOperator } from "./drawingEvents.js";
import { removeElementsOperator } from "./removeElementsEvents.js";
import { editPolygonOperator } from "./editPolygonEvents.js";
import { NewLogger } from "../modules/logger/logger.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { ScaleOperation } from "../modules/meshoperations/scaleOperation.js";
import { door, updateDoorPosition } from "./drawDoorEvents.js";
import { _globalWindow, updateWindowPosition } from "./drawWindowEvents.js";
import { doorOperation } from "../modules/meshoperations/doorOperation.js";
import { windowOperation } from "../modules/meshoperations/windowOperation.js";
import { furnitureOperation } from "../modules/meshoperations/furnitureOperation.js";
import { updateExtrude } from "./extrudeEvents.js";
import { moveOperator } from "../modules/meshoperations/moveOperations/moveOperation.js";
import { arrayFunctionOperator } from "./arrayFunctionEvents.js";
import { setScaleOperation } from "../modules/setScaleOperations/setScaleOperation.js";
import { RotateOperation } from "../modules/meshoperations/rotateOperation.js";
import { virtualSketcher } from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { meshObjectMapping } from "../modules/snaptrudeDS/mapping.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { terrainGeneration } from "../modules/geo/terrainMap.js";
import { AutoSave } from "../modules/socket/autoSave.js";
import { Command } from "../modules/commandManager/Command.js";
import { staircaseOperation } from "../modules/meshoperations/staircaseOperation.js";
import {
  disposeAllVertexIndicators,
  disposeSnappingObjects,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import { removeMoveMeshAxis } from "./moveMeshEvents.js";
import { removeScaleMeshAxis, resetSacle } from "./scaleMeshEvents.js";
import { areaView } from "./areaView.js";
import { splitFaceOperator } from "../modules/meshoperations/splitFaceOperations.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { lockAxisSnapConstraintDirection, resetConstrainedSnapAxisInformation, } from "./snapUtilities";
import reduxStore from "../stateManagers/store/reduxStore.js";
import { deselectMesh } from "../stateManagers/reducers/objectProperties/meshSelection.js";
import { createNeighborhood, deleteNeighborhood, } from "../modules/geo/terrainNeighborhood.js";
import { deleteFloorPlan, initFloorplan } from "./twoDimension";
import { createCadSketch, deleteCadSketch, } from "../modules/cadImporter/cadServices";
import BABYLON from "../modules/babylonDS.module";
import { drawCylinder } from "./drawCylinder.js";
import { importPdfOperations } from "./importPdfOperations";
import { UIHandler } from "./handleToolbar";
import { measureFunction } from "./measureEvents";
import { angleMeasure } from "./angleMeasure";
import { revitDataPreparation } from "../modules/revitExport/revitExport.js";
import stackedWallHelper from "../modules/stackedWalls/stackedWallHelper";
import {meshUniqueIdMapper} from "../modules/utilityFunctions/meshUniqueIdMapper";
import { Locker } from "../modules/locker/locker.js";

function handleKeyEvents(e) {
  // do not process events on html inputs (input and textarea)
  if(["INPUT", "TEXTAREA"].includes(e.target?.tagName)) return;

  e = e || window.event;
  // console.log(e);
  var key = e.which || e.keyCode; // keyCode detection
  if (store.arrayFunctionGlobalVariables.overrideCustomKeyboardInput) return;
  //Processing every key press locally when inputBox is clicked on

  var ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // ctrl detection
  var escKey = e.escKey ? e.ctrlKey : key === 27 ? true : false; // ctrl detection
  var cmd = e.metaKey; // ctrl detection
  var shiftKey = e.shiftKey
    ? e.shiftKey
    : key === 16
    ? (shiftKey = true)
    : (shiftKey = false); // ctrl detection
  /* AG-RE: ANGULAR REFERENCES */
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  /*document.addEventListener("keydown", function (zEvent) {
    if (zEvent.code === 16 && zEvent.code === 222) {
      // DO YOUR STUFF HERE
      console.log("hi feetfalg is false");
      feetFlag = false;
    }
  });*/

  if (ctrl) {
    document.body.style.cursor = "grab";
  }

  // CTRL + A for selecting all meshes
  // if(key == 186){
  //     goOutOfTwoD();
  // }
  // if(key == 222){
  //     goIntoTwoD();
  // }
  if (key == 65) {
    if ((ctrl || cmd)) {
      let group = MeshGroups.createNewGroup("my_group");
      e.preventDefault();
      trackKey("select all");
      /* AG-RE: ANGULAR REFERENCES */
      // $scope.$apply(function () {
      //   // $scope.levelButtonDisp = false;
      // });

      for (let j = 0; j < store.newScene.meshes.length; j++) {
        var mesh = store.newScene.meshes[j];
        if (
          mesh.state !== "on" &&
          nonDefaultMesh(mesh) &&
          mesh.name.indexOf("sketchLine") === -1 &&
          mesh.type !== "doorWindowIndicator"
        ) {
          // if ((mesh.scaling.x === 0) && (mesh.scaling.y === 0) && (mesh.scaling.z === 0)) continue;
          if (isMeshNonSelectable(mesh)) continue;
          if (Mass.isPlinth(mesh)) continue;
          mesh.state = "on";
          store.selectionStack.push(mesh);
          mesh.children = [];
          drawSelectionBox(mesh);
          group.addTemporaryMember(mesh);
          // bbox = mesh.getBoundingInfo();
          // boxes = [];
          // for ( let k = 0; k < bbox.boundingBox.vectorsWorld.length; k++) {
          //     var box = BABYLON.Mesh.CreateBox(mesh.name + "boxScale" + k, 0.5, store.newScene);
          //     box.position = bbox.boundingBox.vectorsWorld[k];
          //     box.material = new BABYLON.StandardMaterial("boxm1", store.newScene);
          //     box.material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);
          //     // (box.material);
          //     boxes.push(box);
          //     mesh.children.push(box);
          // }
        }
      }
    }
    else
    {
      drawingOperator.handleKeyInput(key);
    }
  }
  if (key === 76)
    drawingOperator.handleKeyInput(key);
  if (key === 38 && (ctrl || cmd)) {
    trackKey("increase storey");
    // store.newScene.activeCamera.detachControl(canvas);
    StoreyMutation.duplicateStoreys(store.activeLayer.structure_id, 1);
    // store.newScene.activeCamera.attachControl(canvas, true, false);
  }
  if (key === 40 && (ctrl || cmd)) {
    trackKey("decrease storey");
    // store.newScene.activeCamera.detachControl(canvas);
    StoreyMutation.duplicateStoreys(store.activeLayer.structure_id, 0);
    // store.newScene.activeCamera.attachControl(canvas, true, false);
  }
  if (e.keyCode === 189 || e.keyCode === 109) {
    trackKey("minus key");
    //  handleMinusKey(e,$scope);
  }
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 105)
  ) {
    // 0-9 only
    trackKey("number key");

    /* AG-RE: ANGULAR REFERENCES */

    // console.warn("AG-RE: call handleNumberKeys(e, $scope)");
    handleNumberKeys(e, store.$scope);
  }

  if (isArrowKey(e)) {
    lockAxisSnapConstraintDirection(e);
    // e.stopImmediatePropagation();
  }

  if (e.keyCode === 222 && e.shiftKey) {
    trackKey("feetFlag key");
    feetFlag = false;
  }

  if (e.keyCode === 9) {
    trackKey("tab key");

    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: call handleTabKey(e, $scope)");
    handleTabKey(e);
  }

  if (e.keyCode === 222) {
    trackKey("shift key");
    shiftKey = true;
    handleQuoteKey(e, shiftKey);
  }

  if (key === 8) {
    //backspace
    trackKey("backspace");
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: call handleBackSpaceKey(e, $scope)");
    handleBackSpaceKeyEvent(e, store.$scope);
  }

  if (key === 110 || key === 190) {
    // the dot key "."
    trackKey("dot");
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: call handleDotKeyEvent(e, $scope)");
    handleDotKeyEvent(e, store.$scope);
  }

  if (key === 13) {
    //enter key
    trackKey("Enter");
    /* AG-RE: ANGULAR REFERENCES */
    // console.warn("AG-RE: call handleEnterKey(e, $scope)");
    handleEnterKey(e, store.$scope);
  }

  if (key === 27) {
    //escape
    trackKey("Escape");
    handleEscapeKeyEvent();
  }
  // if (key === 82) { //R
  //     trackKey("camera rotate");
  //     onCamRot();
  // }
  // Ctrl + Z for Copy
  if (key === 90 && (ctrl || cmd)) {
    if (shiftKey) {
      e.preventDefault();
      trackKey("redo");
      delayedExecutionEngine.executeAll();
      CMRedo();
    } else {
      e.preventDefault();
      trackKey("undo");
      delayedExecutionEngine.executeAll();
      store.undoSketchUploadBeforeScale = true; //necessary for set-scale indicators to go away
      CMUndo();
    }
  }
  if (key === 89 && (ctrl || cmd)) {
    e.preventDefault();
    trackKey("redo");
    delayedExecutionEngine.executeAll();
    CMRedo();
    // setTimeout(function(){ updateLevelsAngularUI(); }, 1000);
    // setTimeout(function(){ setLayerTransperancy(); }, 1000);
  }
  // Ctrl + G for Copy
  if (key === 71) {
    trackKey("group slection");
    store.copy_stack = store.selectionStack;
    let group = MeshGroups.createNewGroup();
    store.copy_stack.forEach(function (mesh) {
      group.addMember(mesh);
    });
  }
  // Ctrl + C for Copy
  if (key === 67 && (ctrl || cmd)) {
    store.copy_stack = store.selectionStack;
    // let group = MeshGroups.createNewGroup();
    // store.copy_stack.forEach(function(mesh){
    //     group.addMember(mesh);
    // });
  } else if (key === 86 && (ctrl || cmd)) {
    // Ctrl + V for Paste
    trackKey("paste");
    removeSelectionBox();
    let newCopyStack = [];
    let newMeshesArray = [];
    let optionsForPaste = {
      uniqueObject: !shiftKey,
      sendOriginalToInfinity: false,
      arrayOperation: false,
    };

    store.copy_stack = store.copy_stack?.filter((m) => {
      if(m.name?.includes("terrain")) return false;
      if(m.name?.includes("cad")) return false;
      if(["floorplan", "pdf"].includes(m.type.toLowerCase())) return false;

      return true;
    });

    for (var i = 0; i < store.copy_stack?.length; i++) {
      store.copy_mesh = store.copy_stack[i];
      let clone = pasteObject(store.copy_mesh, optionsForPaste);

      if (clone) {
        if (clone.instanceToReplaceOriginal) {
          newMeshesArray.push(clone.instanceToReplaceOriginal);
          clone = clone.newInstance;
        }

        clone.position.x += 5;
        clone.position.z += 5;
        drawSelectionBox(clone);
        newCopyStack.push(clone);
        newMeshesArray.push(clone);
      }
    }
    store.copy_stack = newCopyStack;
    store.selectionStack = newCopyStack;

    let options = {
      preserveChildren: false,
    };

    if(newCopyStack.length===0) return;

    let commandData = commandUtils.creationOperations.getCommandData(
      newCopyStack,
      null,
      options
    );

    commandUtils.creationOperations.executeCommand(
      "ctrlV",
      commandData,
      options
    );

    virtualSketcher.addWithoutGeometryEdit(newCopyStack.map(m => m.getSnaptrudeDS()));

    updateRoofAccordion(true);
    updateModifications();
  } else if (key === 46 || (e.metaKey && key === 8)) {
    trackKey("delete"); ///Delete Key
    handleDeleteKeyEvent();
  } else if ((key === 115 || key === 83) && (ctrl || cmd)) {
    e.preventDefault();
    updateModifications();
    //send_complete_scene_data("save");
  }
}

function handleQuoteKey(e, shiftKey) {
  if (feetFlag) {
    feetFlag = false;
  } else {
    feetFlag = true;
  }
}

function handleTabKey(e) {
  const scope = store.$scope;
  let preventDefault = false;
  let inputs = scope.display_units;
  //console.log(inputs);
  if (inputs.length > 1) {
    preventDefault = true;
    store.numberFlag = 0;
    /*let changed = false;
        inputs.some((input) =>{
            scope.selected_display_unit.background = 'transparent';
            scope.selected_display_unit.borderColor = 'transparent';
            if(input.name === scope.selected_display_unit.name){
                //console.log("selected i",scope.selected_display_unit);
                input.thickness = 0;
                input.borderColor = 'transparent';
            }else{
                scope.selected_display_unit = input;
                input.background = 'transparent';
                input.borderColor = 'black';
                input.thickness = 1;
                //console.log("new selected",scope.selected_display_unit);
                changed = true;
            }
            if(changed){return true;}
        });*/

    const focusedControl = store.advancedTexture.focusedControl;

    const inputInFocusIndex = inputs.indexOf(focusedControl);
    let inputToBeInFocusIndex;

    if (isNaN(inputInFocusIndex)) {
      inputToBeInFocusIndex = 0;
    } else {
      inputToBeInFocusIndex = inputInFocusIndex + 1;
      if (inputToBeInFocusIndex >= inputs.length) {
        inputToBeInFocusIndex = 0;
      }
    }

    focusBabylonGUIElement(inputs[inputToBeInFocusIndex]);

    preventDefault = drawingOperator.handleTabKey(
      e,
      inputs[inputInFocusIndex],
      inputs[inputToBeInFocusIndex]
    );
  } else {

    preventDefault = drawingOperator.handleTabKey(e);

    if (!preventDefault) preventDefault = removeElementsOperator.chooseMassForGapFilling();
    if (!preventDefault) preventDefault = editPolygonOperator.handleTab();

    if (!preventDefault) preventDefault = moveOperator.handleTab(e);

    if (!preventDefault) preventDefault = arrayFunctionOperator.handleTab(e);
  }

  if (preventDefault) e.preventDefault();
}

function trackKey(track) {
  //if (store.isProduction) {
  //  if (store.mixpanel) {
  window.analytics.track("keyboard " + track);
  //  }
  NewLogger.logAction("keyboard" + track);
  //console.logInfo("keyboard "+track);
  //}
}

function isNumberKey(evt) {
  return (
    (evt.keyCode >= 48 &&
      evt.keyCode <= 57 &&
      !evt.shiftKey) /* PROCESS THE INPUT ONLY IF IT'S NUMERIC [0-9] */ ||
    (evt.keyCode >= 96 && evt.keyCode <= 105)
  );
}

function isAlphabetKey(evt) {
  return evt.keyCode >= 65 && evt.keyCode <= 90;
}

function isEscapeKey(evt) {
  return evt.keyCode === 27;
}

function isShiftKey(evt) {
  return evt.keyCode === 16;
}

function isBackspaceKey(evt) {
  return evt.keyCode === 8;
}

function isArrowKey(evt) {
  return evt.keyCode >= 37 && evt.keyCode <= 40;
}

function isLeftArrowKey(evt) {
  return evt.keyCode === 37;
}

function isUpArrowKey(evt) {
  return evt.keyCode === 38;
}

function isRightArrowKey(evt) {
  return evt.keyCode === 39;
}

function isDownArrowKey(evt) {
  return evt.keyCode === 40;
}

function isEnterKey(evt) {
  return evt.keyCode === 13;
}

function isDotKey(evt) {
  return evt.keyCode === 110 || evt.keyCode === 190;
}

function isCommaKey(evt) {
  return evt.keyCode === 188;
}

function isForwardSlashKey(evt) {
  return evt.keyCode === 111 || evt.keyCode === 191;
}

function isTabKey(evt) {
  return evt.keyCode === 9;
}

function isMinusKey(evt) {
  return evt.keyCode === 109 || evt.keyCode === 189;
}

function blurOnEnter(e) {
  if (isEnterKey(e)) {
    e.target.blur();
  }
}

function basicInputKeyPressHandler(
  e,
  additionalAllowCondition = getEmptyFunction()
) {
  if (
    isNumberKey(e) ||
    isBackspaceKey(e) ||
    isArrowKey(e) ||
    isMinusKey(e) ||
    isDotKey(e) ||
    additionalAllowCondition(e)
  ) {
    // let the event propagate
  } else if (isEnterKey(e)) {
    e.target.blur();
  } else {
    e.preventDefault();
  }
}

var numberFlag = 0;
var feetFlag = true;
var prevKey = 1;
function handleNumberKeys(e, scope) {
  // console.log(e.key,feetFlag);
  let input = DisplayOperation.getInputToEdit(scope);

  //scope.selected_display_unit === null ? input = store.advancedTexture._rootContainer.getChildByName("distBox") : input = scope.selected_display_unit;

  if (input) {
    if (input.name === "distBox" || input.name === "angleInput") {
      return;
    }

    if (input.color !== "black") input.color = "black";
    if (input.text.indexOf('"') > -1) {
      let str = input.text;
      if (store.numberFlag > 0) {
        if (feetFlag) {
          let bfrVal = str.split("'")[0];
          //console.log("aftrwards", str.split("'")[1]);
          if (parseInt(bfrVal) === 0 && store.numberFlag === 0) {
            if (bfrVal.indexOf("-") > -1) {
              input.text = "-" + e.key + "'" + str.split("'")[1];
            } else {
              input.text = e.key + "'" + str.split("'")[1];
              store.numberFlag = 1;
            }
          } else {
            parseInt(bfrVal) === 0 ? (bfrVal = e.key) : (bfrVal += e.key);
            input.text = bfrVal + "'" + str.split("'")[1];
            store.numberFlag++;
          }
        } else {
          let btwVal = str.split("'").pop().split('"')[0];
          if (parseInt(btwVal) === 0 && prevKey !== 0) {
            input.text = input.text.replace(/'.*"/, `'${e.key}"`);
            prevKey = 0;
          } else {
            btwVal += e.key;
            input.text = input.text.replace(/'.*"/, `'${btwVal}"`);
          }
        }
      } else {
        /* eslint-disable */
        input.text = e.key + "'" + '0"';
        /* eslint-enable */
        store.numberFlag++;
      }
    } else {
      if (input) {
        if (store.numberFlag > 0) {
          input.text += e.key;
          store.numberFlag++;
        } else {
          input.text = e.key;
          store.numberFlag++;
        }
      }
    }
    input.edited = true;
    input.autoStretchWidth = true;
    if (input.name === "measureBox") {
      scope.tape_measure_value = input.text;
      if (input.previous_unit_type.value === "millimeter") {
        input.text = input.text.replace(" mm", "");
        scope.tape_measure_value = input.text;
        input.text += " mm";
      } else if (input.previous_unit_type.value === "centimeter") {
        input.text = input.text.replace(" cm", "");
        scope.tape_measure_value = input.text;
        input.text += " cm";
      } else if (input.previous_unit_type.value === "meters") {
        input.text = input.text.replace("  m", "");
        scope.tape_measure_value = input.text;
        input.text += "  m";
      } else if (input.previous_unit_type.value === "feet-inches") {
        input.text = input.text.replace(" ft", "");
        scope.tape_measure_value = input.text;
        input.text += " ft";
      } else if (input.previous_unit_type.value === "inches") {
        input.text = input.text.replace(" in", "");
        scope.tape_measure_value = input.text;
        input.text += " in";
      }
    }
  }

  let rotateBox =
    store.advancedTexture._rootContainer.getChildByName("rotateBox");
  if (rotateBox) {
    let text = rotateBox.text;
    let degree = text.split(" degrees");

    if (store.numberFlag > 0) {
      rotateBox.text = degree[0] + e.key + " degrees";
      store.numberFlag++;
    } else {
      rotateBox.text = e.key + " degrees";
      store.numberFlag = 1;
    }

    rotateBox.isEnabled = false;
    rotateBox.autoStretchWidth = true;
  }
}

function handleMinusKey(e, scope) {
  let input = DisplayOperation.getInputToEdit(scope);
  //scope.selected_display_unit === null ? input = store.advancedTexture._rootContainer.getChildByName("distBox") : input = scope.selected_display_unit;
  if (input) {
    if (input.text.indexOf("'") > -1) {
      let str = input.text;
      /* eslint-disable */
      if (str.indexOf("-") > -1) {
      } else {
        input.text = "-" + str.split("'")[0] + "'" + str.split("'")[1];
      }
      /* eslint-enable */
    } else {
      if (input.text === "" || store.numberFlag === 0) {
        input.text = "-";
      }
    }
    input.edited = true;
    store.numberFlag++;
  }
}

function handleBackSpaceKeyEvent(e, scope) {
  let input = DisplayOperation.getInputToEdit(scope);
  //scope.selected_display_unit === null ? input = store.advancedTexture._rootContainer.getChildByName("distBox") : input = scope.selected_display_unit;

  if (input) {
    if (input.name === "distBox" || input.name === "angleInput") {
      return;
    }
    // store.advancedTexture.moveFocusToControl(input);
    let str = input.text;
    if (input.text.indexOf('"') > -1) {
      /*input.split("'")[1].split('"')[0];*/
      let btwVal = str.split("'").pop().split('"')[0];
      //console.log("btwl", btwVal);
      if (btwVal.length > 1) {
        input.text = input.text.replace(/'.*"/, `'${btwVal.slice(0, -1)}"`);
      } else {
        if (parseInt(btwVal) === 0) {
          let bfrVal = str.split("'")[0];
          //console.log("bfrVal", bfrVal);
          if (bfrVal.length > 1) {
            input.text = `${bfrVal.slice(0, -1)}'${btwVal}"`;
          } else {
            input.text = "0'" + str.split("'")[1];
            prevKey = 1;
          }
        } else {
          input.text = input.text.replace(/'.*"/, `'0"`);
        }
      }
    } else {
      input.text = input.text.slice(0, -1);
      store.numberFlag++;
    }
    input.edited = true;
    if (input.name === "measureBox") {
      scope.tape_measure_value = input.text;
    }
  }

  let rotateBox =
    store.advancedTexture._rootContainer.getChildByName("rotateBox");
  if (rotateBox) {
    let text = rotateBox.text;
    let degree = text.split(" degrees");
    rotateBox.text = degree[0].toString().slice(0, -1) + " degrees";
    store.numberFlag++;
    rotateBox.isFocusInvisible = true;
    rotateBox.autoStretchWidth = true;
  }
}

function handleDotKeyEvent(e, scope) {
  let input = DisplayOperation.getInputToEdit(scope);
  //scope.selected_display_unit === null ? input = store.advancedTexture._rootContainer.getChildByName("distBox") : input = scope.selected_display_unit;
  if (input) {
    /* eslint-disable */
    if (input.text.indexOf(".") > -1) {
    } else {
      input.text += ".";
      store.numberFlag++;
    }
    /* eslint-enable */
    input.edited = true;
  }
}

function handleEnterKey(e, scope) {
  let input = DisplayOperation.getInputToEdit(scope);
  //scope.selected_display_unit === null ? input = store.advancedTexture._rootContainer.getChildByName("distBox") : input = scope.selected_display_unit;
  feetFlag = true;
  store.numberFlag = 0;
  if (input && store.numberFlag > 0) {
    // MoveOperation.updatePosition(input.text);
  }

  if (input) {
    drawingOperator.handleEnterKey(input);
    drawCylinder.handleUserInput(input);
    splitFaceOperator.handleUserInput(input);
  }

  if (input) {
    ScaleOperation.updateScaleMesh(
      input.text.indexOf('"') > -1 ? input.text : parseFloat(input.text)
    );
    if (door) {
      updateDoorPosition(
        input.text.indexOf('"') > -1 ? input.text : parseFloat(input.text)
      );
    }
    if (_globalWindow) {
      updateWindowPosition(input.text);
    }
    doorOperation.updateDoorPosition(input.text);
    windowOperation.updateWindowPosition(input.text);
    furnitureOperation.updateFurniturePosition(scope.display_units);
    // updateVertPosition(input.text);
    // updateEdgePosition(input.text);
    // updatePushPull(input.text);
    updateExtrude(input.text);
    // updateFreeMove(input.text);
    moveOperator.handleUserInput(input.text);
    editPolygonOperator.updateEditPolygon(input.text);
    arrayFunctionOperator.handleUserInput(input.text, input.name);
    if (setScaleOperation.isInputBoxEnabled()) {
      if (!$("#setScaleModal").hasClass("show")) {
        // setScaleForFPAndMeshes();
        setScaleOperation.scale();
      }
      store.numberFlag = 0;
    }
  }
  let rotateBox =
    store.advancedTexture._rootContainer.getChildByName("rotateBox");

  if (rotateBox) {
    // console.log(rotateBox.text.split(" degrees")[0]);
    if (rotateBox.isVisible) {
      RotateOperation.updateRotateAngle(rotateBox.text);
    }
  }
}

function handleDeleteKeyEvent() {
  if (_.isEmpty(store.selectionStack)) return;

  let updateCSGRequired = false;
  let graphUpdateRequired = false;
  let commandData = null;
  let extraCommands = [];

  /*
    Remove all the children whose parents are also in the stack
     */
  let ineligibleChildren = [];
  store.selectionStack.forEach((m) => {
    ineligibleChildren.push(...m.getChildren());
    m.getChildren().forEach(c => {
      c.getChildren().forEach(gc => {
        if (!ineligibleChildren.includes(gc)) {
          ineligibleChildren.push(gc);
        }
      })
    })
  });

  let filteredStack = store.selectionStack;
  if (!_.isEmpty(ineligibleChildren)) {
    filteredStack = store.selectionStack.filter((m) => {
      return !ineligibleChildren.includes(m);
    });
  }

  const meshCompanionMap = new Map();
  filteredStack.forEach(mesh => {
    if (stackedWallHelper.isPartOfStackedWall(mesh)){
      const companions = Array.from(meshCompanionMap.values());
      if (companions.includes(mesh)) return;

      const companion = stackedWallHelper.getCompanionWall(mesh);
      if (!filteredStack.includes(companion)){
        filteredStack.push(companion);
      }

      meshCompanionMap.set(mesh, companion);
    }
  });

  const stackedWallCommands = [];
  meshCompanionMap.forEach((companion, mesh) => {
    stackedWallCommands.push(stackedWallHelper.remove(mesh, companion));
  });


  filteredStack = Locker.filterLockedMeshes(filteredStack);

  let terrainStack = [];
  let cadStack = [];
  let floorplanStack = [];
  let pdfStack = [];

  filteredStack.forEach((m, index) => {
    if (m.name.includes("terrain")) {
      terrainStack.push(m);
    }
    if (m.name.toLowerCase().includes("cad")) {
      cadStack.push(m);
    }
    if (m.name.toLowerCase().includes("twoplane")) {
      floorplanStack.push(m);
    }
    if(m.name.toLowerCase().includes("pdfmesh")){
      pdfStack.push(m);
    }
  });

  let filteredComponentStack = filteredStack.map((m) => m.getSnaptrudeDS());
  filteredComponentStack = filteredComponentStack.filter(
    (m) => m !== undefined
  );

  /*
    Out of these, it's possible that multiple children of the same unselected mesh are present.
    Only the last of them should have updateCSG set to true
     */

  filteredComponentStack.forEach((c) => {
    const m = c.mesh;
    if (m.parent) {
      if (
        ["door", "window", "void", "furniture"].includes(m.type.toLowerCase())
      ) {
        m.parent.getChildren().forEach((c) => delete c.updateCSGInfo);
        if (m.type.toLowerCase() === "furniture" && !m.getSnaptrudeDS().cutHole)
          return;
        m.updateCSGInfo = true;
        updateCSGRequired = true;
      }
    }
    if (virtualSketcher.util.shouldAddComponentToGraph(c)) {
      graphUpdateRequired = true;
    }
  });

  filteredStack = filteredStack.filter(
    (m) => !m.name.toLowerCase().includes("twoplane")
  );
  filteredStack = filteredStack.filter(
    (m) => !m.name.toLowerCase().includes("cad")
  );
  filteredStack = filteredStack.filter(
    (m) => !m.name.toLowerCase().includes("pdfmesh")
  );
  filteredStack = filteredStack.filter(
    (m) => m.name !== "terrain" && !isMeshThrowAway(m)
  );

  filteredComponentStack = filteredComponentStack.filter(
    (m) => !m.mesh.name.toLowerCase().includes("twoplane")
  );
  filteredComponentStack = filteredComponentStack.filter(
    (m) => !m.mesh.name.toLowerCase().includes("cad")
  );
  filteredComponentStack = filteredComponentStack.filter(
    (m) => !m.mesh.name.toLowerCase().includes("pdfmesh")
  );
  filteredComponentStack = filteredComponentStack.filter(
    (c) => c.mesh.name !== "terrain"
  );

  // If a Mass from Storey -1 is deleted, need to check if there is a Mass on Storey 1 in the Storey Linked List
  // If yes, need to generate a Plinth for that Mass as the Mass on Storey -1 is not present anymore
  filteredStack.forEach((mesh) => {
    if (mesh.type.toLowerCase() === "mass" && mesh.storey === -1) {
      let meshDS = mesh.getSnaptrudeDS();
      let ll = StructureCollection.getInstance()
        .getStructures()
        [meshDS.structure_id].getLinkedListCluster()
        .getAllLinkedList()[meshDS.linkedListId];
      if (ll) {
        let node = ll.search(mesh.uniqueId).node;
        let nextElement = node.next;
        if (nextElement) {
          let nextMesh = meshObjectMapping.getObjectByUniqueId(
            nextElement.data
          ).mesh;
          if (nextMesh.storey === 1) {
            extraCommands.push(Mass.drawPlinth(nextMesh));
          }
        }
      }
    }
  });

  let _allCommandsArray = [];
  let _yetsArray = [];

  let csgDeletedRevitIds = [];

  let revitDeleteIds = [];
  for (let m of filteredStack) {
    try {
      let snaptrudeDs = m.getSnaptrudeDS();

      if (!snaptrudeDs.revitMetaData) continue;

      // console.log(snaptrudeDs.revitElementId);
      let elementId = snaptrudeDs.revitMetaData.elementId;
      if (elementId) {
        revitDeleteIds.push(elementId);

        if (m._children) {
          for (var child of m._children) {
            let childSnaptrudeDs = child.getSnaptrudeDS();
            if (childSnaptrudeDs.revitMetaData.elementId) {
              revitDeleteIds.push(childSnaptrudeDs.revitMetaData.elementId);
            }
          }
        }
      }
    } catch (e) {
      console.log("Failed to add deleted element to revetDeletedElements");
    }
  }

  let revitDeleteCommands = [];
  revitDeleteCommands.push(deleteRevitIds(revitDeleteIds));

  if (updateCSGRequired || graphUpdateRequired) {
    let options = {
      preserveChildren: false,
      handleLinkedList: true,
    };

    commandUtils.deletionOperations.flushSecondaryCommandInformation();
    commandData = commandUtils.deletionOperations.getCommandData(
      filteredStack,
      null,
      options
    );
    let deleteCommandObject =
      commandUtils.deletionOperations.halfAssExecuteCommand(
        "delete",
        commandData,
        filteredComponentStack,
        options
      ); // contains info about what is actually selected by user to delete.

    let secondaryCommands = deleteCommandObject.secondaryCommands;
    let csgUpdateCreateCommands = secondaryCommands.creationCommands;
    let csgUpdateDeleteCommands = secondaryCommands.deletionCommands; // contains info about what is deleted due to snaptrude processing.
    let csgUpdateDllCommands = secondaryCommands.dllCommands;
    let csgUpdateOtherCommands = secondaryCommands.otherCommands;

    for (let command of csgUpdateDeleteCommands) {
      for (let data of command.data) {
        try {
          let revitElementId = store.scene.getMeshByUniqueID(data.meshId).getSnaptrudeDS().revitElementId;
          if (revitElementId) csgDeletedRevitIds.push(revitElementId);
        } catch (e) {

        }
      }
    }

    let ogDeletionCommand = deleteCommandObject.command;

    let parametricChangesCommand = secondaryCommands.parametricCommands;

    //removing null, undefined from the array.
    csgUpdateCreateCommands = _.compact(csgUpdateCreateCommands);
    csgUpdateDeleteCommands = _.compact(csgUpdateDeleteCommands);
    parametricChangesCommand = _.compact(parametricChangesCommand);
    csgUpdateDllCommands = _.compact(csgUpdateDllCommands);
    extraCommands = _.compact(extraCommands);

    let allCommands = [
      ...stackedWallCommands,
      ogDeletionCommand,
      ...csgUpdateCreateCommands,
      ...csgUpdateOtherCommands,
      ...csgUpdateDeleteCommands,
      ...parametricChangesCommand,
      ...csgUpdateDllCommands,
      ...extraCommands,
    ];

    let yets = [
      ...stackedWallCommands.map((_) => false),
      false,
      ...csgUpdateCreateCommands.map((_) => false),
      ...csgUpdateOtherCommands.map((_) => false),
      ...csgUpdateDeleteCommands.map((_) => true),
      ...parametricChangesCommand.map((_) => false),
      ...csgUpdateDllCommands.map((_) => false),
      ...extraCommands.map((_) => false),
    ];

    // Sometimes these CSG commands are undefined, example - for furniture

    // let allCommands = [ogDeletionCommand, ...csgUpdateCreateCommands, ...csgUpdateDeleteCommands, ...staircaseDeleteCommands];
    // let yets = [false, ...csgUpdateCreateCommands.map(c => false), ...csgUpdateDeleteCommands.map(c => true), ...staircaseDeleteCommands.map(c => true)];

    // CommandManager.execute(_.compact(allCommands), yets);
    _allCommandsArray = _.compact(allCommands);
    _yetsArray = yets;

  } else {
    let options = {
      preserveChildren: false,
      handleLinkedList: true,
    };

    commandData = commandUtils.deletionOperations.getCommandData(
      filteredStack,
      null,
      options
    );

    let command = commandUtils.deletionOperations.getCommand(
      "delete",
      commandData,
      options
    );

    // CommandManager.execute(command, true);
    command = [
      ...stackedWallCommands,
      command,
      ...extraCommands,
    ];

    let yets = [
      ...stackedWallCommands.map((c) => false),
      true,
      ...extraCommands.map((c) => false),
    ];

    _allCommandsArray = _.compact(command);
    _yetsArray = yets;
  }

  let terrainDeleteCommands = [];

  if (terrainStack.length > 0) {
    for (let i = 0; i < terrainStack.length; i++) {
      let meshSnaptrudeDs = terrainStack[i].getSnaptrudeDS();
      let structure =
        StructureCollection.getInstance().getStructures()[
          meshSnaptrudeDs.structure_id
          ];
      let terrain = structure
        .getStoreyData()
        .getStoreyByValue(meshSnaptrudeDs.storey)
        .layerData.getTerrainObject(terrainStack[i].uniqueId);

      let neighborhoodLayer;

      if (terrain.parameters.isNeighborhoodEnabled) {
        const storeyOfInterest = structure
          .getStoreyData()
          .getStoreyByValue(store.activeLayer.storey);
        neighborhoodLayer = storeyOfInterest.layerData.getLayerBylayerId(
          terrain.parameters.neighborhoodLayer,
          store.activeLayer.storey
        );// change buildings* layer name
        if (neighborhoodLayer) {
          let data = {
            storey: 1,
            structure_id: neighborhoodLayer.structure_id,
            layer: neighborhoodLayer,
            layerId: neighborhoodLayer.id,
            storeyData: 1,
          };

          let _createNeighborhood = function () {
            let neighborhood = this.data.layer.neighborhood;
            createNeighborhood(
              neighborhood.bounds,
              { center: neighborhood.center, zoom: neighborhood.zoom },
              neighborhood.parentID,
              neighborhood.parameters,
              { layerId: this.data.layerId }
            );
          };

          let _deleteNeighborhood = function () {
            deleteNeighborhood(this.data.layer);
          };

          let getCommandLogic = function () {
            return {
              execute: _deleteNeighborhood,
              unexecute: _createNeighborhood,
            };
          };

          let getSaveData = function () {
            let saveData = AutoSave.getSaveDataPrototype();
            saveData.commandId = this.id;
            saveData.data.saveType = "deleteNeighborhoodLayer";
            saveData.data.identifier = {
              structure_id: this.data.structure_id,
              floorkey: store.floorkey,
              storey: this.data.storey.toString(),
              id: this.data.layer.id,
            };

            let dataAfter = { neighborhood: this.data.layer.neighborhood };
            saveData.data.afterOperationData = dataAfter;
            return saveData;
          };

          let cmd = new Command(
            "deleteNeighborhoodLayer",
            data,
            getCommandLogic(),
            getSaveData
          );
          terrainDeleteCommands.push(cmd);
        }
      }
      // eslint-disable-next-line no-inner-declarations
      function getCommandLogic() {
        return {
          execute: terrainGeneration.deleteTerrain,
          unexecute: terrainGeneration.createTerrain,
        };
      }

      let getSaveData = function () {
        let saveData = AutoSave.getSaveDataPrototype();
        saveData.commandId = this.id;
        saveData.data.saveType = "terrainMapDeletion";
        saveData.data.identifier = {
          structure_id: this.data.structure,
          storey: this.data.storey.toString(),
          layerName: this.data.layerName,
          floorkey: store.floorkey,
          id: this.data.layerId,
        };
        let dataAfter = {
          parameters: {
            uniqueId: this.data.uniqueId,
            width: this.data.width,
            height: this.data.height,
            subdivisionsX: this.data.subdivisionsX,
            subdivisionsY: this.data.subdivisionsY,
            imgPixels: this.data.imgPixels,
            textURL: this.data.textURL,
            imgHeight: this.data.imgHeight,
            imgWidth: this.data.imgWidth,
            terrainPosition: this.data.terrainPosition,
            rotationQuaternion: this.data.rotationQuaternion,
            layerName: this.data.layerName,
            structure: this.data.structure,
            storey: this.data.storey,
            mapCenter: this.data.mapCenter,
            mapZoom: this.data.mapZoom,
            tilesArray: this.data.tilesArray,
            mapBounds: this.data.mapBounds,
            isNeighborhoodEnabled: this.data.isNeighborhoodEnabled,
            neighborhoodLayer: this.data.neighborhoodLayer,
          },
        };
        saveData.data.afterOperationData = dataAfter;
        return saveData;
      };
      let _terrainData = null;
      terrainStack[i].computeWorldMatrix(true);
      if (!terrain.parameters.rotationQuaternion) {
        _terrainData = {
          rotationQuaternion: terrainStack[i].rotationQuaternion?.asArray(),
          ...terrain.parameters,
        };
      } else {
        terrain.parameters.rotationQuaternion =
          terrainStack[i].rotationQuaternion?.asArray();
        _terrainData = terrain.parameters;
      }

      let cmd = new Command(
        "TerrainMapDeletion",
        _terrainData,
        getCommandLogic(),
        getSaveData
      );
      terrainDeleteCommands.push(cmd);
    }
    // terrainDeleteCommands = terrainGeneration.returnCommandsForDelete(terrainStack);
  }

  let floorPlanDeleteCommands = [];

  if (floorplanStack.length > 0) {
    for (let i = 0; i < floorplanStack.length; i++) {
      let meshSnaptrudeDs = floorplanStack[i].getSnaptrudeDS();
      let rotationQuaternion = floorplanStack[i].rotationQuaternion?.asArray();
      // let structure = StructureCollection.getInstance().getStructures()[meshSnaptrudeDs.structure_id];

      let _getCommandLogic = () => {
        return {
          execute: deleteFloorPlan,
          unexecute: initFloorplan,
        };
      };

      let _getSaveData = function () {
        let addFloorPlanSaveData = AutoSave.getSaveDataPrototype();
        addFloorPlanSaveData.commandId =
          "Floorplan_Deletion_" + floorplanStack[i].layer_id;
        addFloorPlanSaveData.data.saveType = "deleteFloorPlan";
        addFloorPlanSaveData.data.identifier = {
          structure_id: meshSnaptrudeDs.structure_id,
          storey: meshSnaptrudeDs.storey,
          floorkey: store.floorkey,
          layer_id: floorplanStack[i].layer_id,
        };
        addFloorPlanSaveData.data.afterOperationData =
          deepCopyObject(meshSnaptrudeDs);
        addFloorPlanSaveData.data.afterOperationData.mesh =
          this.data.serializedMesh;
        addFloorPlanSaveData.data.afterOperationData.mesh.layer_id =
          floorplanStack[i].layer_id;
        return addFloorPlanSaveData;
      };

      let _dataNeededToRecreate = {
        fromDeleteKeyEvent: true,
        materialTexture: floorplanStack[i].material?.diffuseTexture,
        storeyVal: meshSnaptrudeDs.storey,
        structureNum: meshSnaptrudeDs.strNum,
        uniqueId: floorplanStack[i].uniqueId,
        layerId: floorplanStack[i].layer_id,
        scaleFactor: meshSnaptrudeDs.scaleFactor,
        floorPlanImageWidth: meshSnaptrudeDs.floorPlanImageWidth,
        floorPlanImageHeight: meshSnaptrudeDs.floorPlanImageHeight,
        uniqueIdOfThePlanInBackend: meshSnaptrudeDs.identifierForBackend,
        rotationQuaternion: rotationQuaternion,
        serializedMesh: getSerializedFormOfMesh(floorplanStack[i]),
      };

      let cmd = new Command(
        "FloorPlanDeletion",
        _dataNeededToRecreate,
        _getCommandLogic(),
        _getSaveData
      );
      floorPlanDeleteCommands.push(cmd);
    }
  }

  let cadDeletionCommands = [];

  if (cadStack.length > 0) {
    for (let i = 0; i < cadStack.length; i++) {
      let structure =
        StructureCollection.getInstance().getStructures()[
          store.activeLayer.structure_id
          ];
      let layer = structure
        .getStoreyData()
        .getStoreyByValue(cadStack[i].storey)
        .layerData.getLayerByName(cadStack[i].layerName, cadStack[i].storey);

      let sketch = layer.sketches[0];

      let data = {
        structureId: layer.structure_id,
        storey: cadStack[i].storey,
        edgeArray: sketch.edgeArray,
        position: sketch.position,
        layerId: layer.id,
        uniqueId: cadStack[i]?.uniqueId,
        layerName: layer.name,
        rotationQuaternion: cadStack[i].rotationQuaternion?.asArray(),
      };

      let _getSaveData = function () {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = this.id;
        saveData.data.saveType = "cadSketchDeletion";

        saveData.data.identifier = {
          structure_id: this.data.structureId,
          storey: this.data.storey,
          layerName: this.data.layerName,
          layerId: this.data.layerId,
          floorkey: store.floorkey,
        };

        saveData.data.beforeOperationData = {
          edgeArray: this.data.edgeArray,
          position: this.data.position,
          structureId: this.data.structureId,
          storey: this.data.storey,
          layerName: this.data.layerName,
          rotationQuaternion: this.data.rotationQuaternion,
        };
        saveData.data.afterOperationData = {};

        return saveData;
      };

      let _reCreateCadSketch = function () {
        createCadSketch(this.data, {
          layerId: this.data.layerId,
          layerName: this.data.layerName,
        });

        let structureCollection = StructureCollection.getInstance();
        let structure =
          structureCollection.getStructures()[this.data.structureId];
        let storey = structure
          .getStoreyData()
          .getStoreyByValue(this.data.storey);
        let layer = storey.layerData.getLayerByName(
          this.data.layerName,
          this.data.storey
        );
        let sketches = layer.sketches;

        layer.mesh.position = new BABYLON.Vector3(...this.data.position);
        sketches[0].position = layer.mesh.position.asArray();
      };

      let _deleteCadSketch = function () {
        deleteCadSketch(
          this.data.structureId,
          this.data.storey,
          this.data.layerName
        );
      };

      let commandLogic = {
        execute: _deleteCadSketch,
        unexecute: _reCreateCadSketch,
      };

      let cmd = new Command(
        "deleteCadFromDeleteKeyEvent",
        data,
        commandLogic,
        _getSaveData
      );

      cadDeletionCommands.push(cmd);
    }
  }

  let pdfDeletionCommands = [];

  if(pdfStack.length > 0){

    for (let i = 0; i < pdfStack.length; i++) {

      let meshSnaptrudeDs = pdfStack[i].getSnaptrudeDS();
      // let structure = StructureCollection.getInstance().getStructures()[meshSnaptrudeDs.structure_id];

      let _dataNeededForRegeneration = {
        sourcePdfInBase64: meshSnaptrudeDs.sourcePdfInBase64,
        scaleFactor: meshSnaptrudeDs.scaleFactor,
        storey: meshSnaptrudeDs.storey,
        structure_id: meshSnaptrudeDs.structure_id,
        width: meshSnaptrudeDs.width,
        height: meshSnaptrudeDs.height,
        uniqueId: pdfStack[i].uniqueId,
        pdfPosition: pdfStack[i].position?.asArray(),
        rotationQuaternion: pdfStack[i].rotationQuaternion?.asArray(),
        layerId: pdfStack[i].layer_id,
      }

      let _getCommandLogic = () => {
        return {
          execute: importPdfOperations.deletePdf,
          unexecute: importPdfOperations.addPdf,
        };
      };

      let _getSaveData = function () {

        let pdfSaveData = AutoSave.getSaveDataPrototype();
        pdfSaveData.commandId = "Pdf_Deletion_" + pdfStack[i].layer_id;
        pdfSaveData.data.saveType = "deletePdf";

        pdfSaveData.data.identifier = {
          structure_id: meshSnaptrudeDs.structure_id,
          storey: meshSnaptrudeDs.storey,
          floorkey: store.floorkey,
          layerId: pdfStack[i].layer_id,
        };

        pdfSaveData.data.afterOperationData = {
          sourcePdfInBase64: this.data.sourcePdfInBase64,
          scaleFactor: this.data.scaleFactor,
          storey: this.data.storey,
          structure_id: this.data.structure_id,
          layerId: this.data.layerId,
          width: this.data.width,
          height: this.data.height,
          pdfPosition: this.data.pdfPosition,
          uniqueId: this.data.uniqueId
        };

        return pdfSaveData;
      };

      let cmd = new Command(
        "PdfDeletion",
        _dataNeededForRegeneration,
        _getCommandLogic(),
        _getSaveData
      );
      pdfDeletionCommands.push(cmd);
    }
  }

  _allCommandsArray = [
    ..._allCommandsArray,
    ...terrainDeleteCommands,
    ...floorPlanDeleteCommands,
    ...cadDeletionCommands,
    ...pdfDeletionCommands,
    ...revitDeleteCommands,

  ]
  _yetsArray = [
    ..._yetsArray,
    ...terrainDeleteCommands.map((c) => true),
    ...floorPlanDeleteCommands.map((c) => true),
    ...cadDeletionCommands.map((c) => true),
    ...pdfDeletionCommands.map((c) => true),
    ...revitDeleteCommands.map((c) => true),

  ]

  CommandManager.execute(_.compact(_allCommandsArray), _yetsArray);

  terrainStack.length = 0;
  floorplanStack.length = 0;
  cadStack.length = 0;
  pdfStack.length = 0;
  store.selectionStack.length = 0;
  removeSelectionBox();
}

const deleteRevitIds = (revitIds) => {

  var originalDeletedElements = [...revitDataPreparation.deletedElements];

  let _executeEvent = function () {
    let data = { revitIds: revitIds };

    let commandLogic = { execute: _deleteRevitIds, unexecute: _undoDeleteRevitIds };

    let cmd = new Command("delete revit ids", data, commandLogic, _getSaveData);

    return cmd;
    // CommandManager.execute(cmd, true);
  };

  let _getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = this.id;
    saveData.data.saveType = "deleteRevitIds";

    saveData.data.identifier = { floorkey: store.floorkey };

    saveData.data.beforeOperationData = { revitIds: [...originalDeletedElements] };
    saveData.data.afterOperationData = { revitIds: [...originalDeletedElements, ...revitIds] };

    return saveData;
  };

  let _undoDeleteRevitIds = function (saveData) {
    if (!saveData) {
      saveData = this.data
    }
    revitDataPreparation.deletedElements = this.getSaveData()[0].data.beforeOperationData.revitIds;
  };

  let _deleteRevitIds = function () {
    revitDataPreparation.deletedElements.push(...revitIds);
  };

  return _executeEvent();
};

function handleEscapeKeyEvent(isEscapeHandled=false) {
  let escapeHandled = isEscapeHandled;
  const reset = resetConstrainedSnapAxisInformation();
  if (reset) return;

  /*
    Interaction of escape with delayedExecution is a bit heterogeneous.
    For desktop, in operations like move, rotate and scale, user has only one chance to press escape.
    When the operation gets over is definite and escape has no impact after that.
    So delayedExecution should always be flushed. If escape is pressed in the window, change is not recorded, and if not, no effect.

    For array it's different. After one copy is generated, escape does not reverse the action on desktop.

    Mostly the window for escape in above scenarios is during pointer move.
    Since it's not possible to do that in iPad, there escape is allowed after pointer up, i.e during limbo.

    The pattern is like this-
    When in limbo, cancel operation. For iPad all edits will be in limbo because of inputs.
    On desktop, only array goes to limbo
    Array tool breaks this pattern on both iPad and desktop. Escape in limbo completes the operation.
 */

  if (arrayFunctionOperator.getMetadata().isOperationInLimbo()) {
    delayedExecutionEngine.executeAll();
  } else {
    delayedExecutionEngine.flush();
  }

  if (!escapeHandled) escapeHandled = updateExtrude(0);
  if (!escapeHandled) escapeHandled = moveOperator.cancelOperation();
  if (!escapeHandled) escapeHandled = arrayFunctionOperator.cancelOperation();

  if (!escapeHandled) escapeHandled = drawingOperator.cancelOperation();
  if (!escapeHandled) escapeHandled = drawCylinder.cancelOperation();

  if (!escapeHandled) escapeHandled = editPolygonOperator.cancelOperation();

  if (!escapeHandled) escapeHandled = RotateOperation._reset();
  if (!escapeHandled) escapeHandled = doorOperation.clearEvents();
  if (!escapeHandled) escapeHandled = windowOperation.clearEvents();
  if (!escapeHandled) escapeHandled = furnitureOperation.clearEvents();
  if (!escapeHandled) escapeHandled = splitFaceOperator.cancelOperation();
  if (!escapeHandled) escapeHandled = measureFunction.cleanUp();
  if (!escapeHandled) escapeHandled = angleMeasure.resetAngleMeasure();

  if (store.undoSketchUploadBeforeScale ||
    !setScaleOperation.isUpload() &&
    store.ACTIVE_EVENT.event === "set-scale") {
    ScaleOperation._reset();
    setScaleOperation._reset();
    ScopeUtils.showToolBar();
    ScopeUtils.showSection();
  }

  if (!escapeHandled) escapeHandled = staircaseOperation.reset();
  if (!escapeHandled) escapeHandled = areaView.resetOperation();

  // if (!escapeHandled) escapeHandled = !_.isEmpty(store.selectionStack);
  if (!escapeHandled && !_.isEmpty(store.selectionStack)) {
    for (let i = 0; i < store.selectionStack.length; i++) {
      let mesh = store.selectionStack[i];
      if (
        mesh.name.indexOf("boxScale") === -1 &&
        mesh.name.indexOf("axis") === -1 &&
        mesh.name.indexOf("ground") === -1
      ) {
        mesh.state = "off";
        reduxStore.dispatch(deselectMesh({uniqueId: mesh.uniqueId}));
        if (mesh.children) {
          if (mesh.children.length) {
            for (let j = 0; j < mesh.children.length; j++) {
              if (mesh.children[j]) {
                mesh.children[j].dispose();
              }
            }
          }
          mesh.children = [];
        }
      } else if (mesh.name.indexOf("boxScale") !== -1) {
        mesh.dispose();
        i--;
      }
    }
    store.selectionStack.length = 0;
    escapeHandled = true;
  }

  if (!escapeHandled) {
    if (store.ACTIVE_EVENT.event !== "select_tool") {
      store.selectionStack = [];
      const tool = "pointer";
      UIHandler.setTool(tool);
    }
  }
  DisplayOperation.removeDimensions();

  if (store.ACTIVE_EVENT.event === "panMode") {
    store.ACTIVE_EVENT.event = "";
  }

  /*
    commented this portion to not hide autodimensioned numbers when escape key is pressed
     */
  store.advancedTexture._linkedControls.forEach((control) => {
    if (!control.name.includes("autoDim")) {
      control.dispose();
    }
  });

  store.numberFlag = 0;
  feetFlag = true;
  let input = null;
  store.$scope.selected_display_unit === null
    ? (input = store.advancedTexture._rootContainer.getChildByName("distBox"))
    : (input = store.$scope.selected_display_unit);
  if (input) {
    ScaleOperation.updateScaleMesh(
      input.text.indexOf('"') > -1 ? input.text : 1
    )
    store.numberFlag = 0;
  }

  try {
    var gr = MeshGroups.getGroupByUniqueId("my_group");
    // gr.members.forEach(function (member) {
    //     member.isPartOfTempGroup = false;
    //     member.tempGroupId = null;
    // });
    gr.members.length = 0;
  } catch (e) {
    console.log("Error in getting meshes", e);
  }

  if (store.scene.getMeshByName("sectionPlane"))
    store.scene.getMeshByName("sectionPlane").dispose()
  var door = store.scene.getMeshByName("DoorsTemp");
  if (door) door.dispose();
  var window = store.scene.getMeshByName("WindowsTemp");
  if (window) window.dispose();

  if (store.face_ribbon) store.face_ribbon.dispose();
  store.curtains = [];
  var curtain1 = store.scene.getMeshByName("CurtainsTemp");
  if (curtain1) curtain1.dispose();
  var curtain2 = store.scene.getMeshByName("CurtainsTemp2");
  if (curtain2) curtain2.dispose();

  if (store.currentMesh) {
    if (store.currentMesh.type) {
      if (
        store.currentMesh.type.toLowerCase() !== "floorplan" &&
        store.currentMesh.name.toLowerCase() !== "layerrefplane"
      )
        store.currentMesh.visibility = 1.0;
    }
  }
  //for add and remove vertex indicators
  disposeAllVertexIndicators()

  removeRotationLines();
  removeMoveMeshAxis();
  removeScaleMeshAxis();
  disposeSnappingObjects();
  resetSacle();

  /* AG-RE: SOCKET, BACKEND REFERENCE */
  // console.warn("AG-RE: call WebsocketFunctions.getSocketObject()");
  store.undoSketchUploadBeforeScale = false;
}

// var dblEscKey = 0;
// window.addEventListener("keydown", (evt) => {
//   if (dblEscKey !== 0 && evt.key === "Escape") {
//     if (store.ACTIVE_EVENT.event !== "drawingMode") {
//       handleToolbar("REMOVE");
//       UIHandler.clearMenu();
//     }
//     dblEscKey = 0;
//   } else {
//     dblEscKey = setTimeout(() => {
//       dblEscKey = 0;
//     }, 500);
//   }
// })

function removeAllChildren() {
  var iter = 0;
  while (iter < store.scene.meshes.length) {
    if (store.scene.meshes[iter].children) {
      removeChildren(store.scene.meshes[iter]);
    }
    iter++;
  }
}

// function removeChildren(mesh){
//     for ( let i = 0; i < mesh.children.length; i++) {
//         mesh.children[i].dispose();
//     }
// }
export {
  handleKeyEvents,
  handleQuoteKey,
  handleTabKey,
  trackKey,
  isNumberKey,
  isEscapeKey,
  isBackspaceKey,
  isArrowKey,
  isLeftArrowKey,
  isUpArrowKey,
  isRightArrowKey,
  isDownArrowKey,
  isEnterKey,
  isDotKey,
  isCommaKey,
  isForwardSlashKey,
  isTabKey,
  isMinusKey,
  numberFlag,
  feetFlag,
  prevKey,
  handleNumberKeys,
  handleMinusKey,
  handleBackSpaceKeyEvent,
  handleDotKeyEvent,
  handleEnterKey,
  handleDeleteKeyEvent,
  handleEscapeKeyEvent,
  removeAllChildren,
  basicInputKeyPressHandler,
  blurOnEnter,
};
