import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { Command } from "../modules/commandManager/Command.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { getScope } from "./toolbarEvents.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { AutoSave } from "../modules/socket/autoSave.js";
import reduxStore from "../stateManagers/store/reduxStore";
import {setMaterialEditValues, setMenuMode} from "../stateManagers/reducers/objectProperties/materialsSlice";
import {MATERIAL_EDIT_MENU, MATERIAL_MENU} from "../../components/rightMenus/constants";
import _ from "lodash";
import {uploadUserMaterial} from "../../services/projects.service";
import {saveMaterialInBackend} from "./applyMaterialFuncs";
import {createMaterialFromImage} from "./mats";

const editMaterial = (function (){
  
  let material, previousProperties, materialSelect;
  
  const propertyUpdaterMapping = {
    uScale : (value) => scaleHandler("uScale", value),
    vScale : (value) => scaleHandler("vScale", value),
    uOffset : (value) => offsetHandler("uOffset", value),
    vOffset : (value) => offsetHandler("vOffset", value),
    angle : (value) => angleHandler(value),
    opacity : (value) => opacityHandler(value),
    overlay : (value) => overlayHandler(value)
  };

  const setMaterialSelect = function (setSelectedMaterial) {
    materialSelect = setSelectedMaterial;
  }

  const scaleHandler = function (property, value){
    material.diffuseTexture[property] = 1 /
            (store.unit_absolute_scale *
              10 *
              DisplayOperation.getOriginalDimension(value));
  };
  
  const offsetHandler = function (property, value){
    material.diffuseTexture[property] =
            (store.unit_absolute_scale *
              10 *
              DisplayOperation.getOriginalDimension(value));
  };
  
  const angleHandler = function (degree){
    material.diffuseTexture.wAng = degreeToRadian(degree)
  };
  
  const opacityHandler = function (opacity){
    material.alpha = opacity / 100;
  };
  
  const overlayHandler = function (color){
    let colors = color
      .substring(4, color.length - 1)
      .replace(/ /g, "")
      .split(",");
    
    material.diffuseColor.r = parseInt(colors[0]) / 255;
    material.diffuseColor.g = parseInt(colors[1]) / 255;
    material.diffuseColor.b = parseInt(colors[2]) / 255;
  };
  
  let pickMaterialFromScene = function (pickInfo) {
    let mesh = pickInfo.pickedMesh;
    if (mesh.isAnInstance) mesh = mesh.sourceMesh;
    
    let material;
    
    if (mesh.material.subMaterials) {
      if (mesh.subMeshes.length > 1) {
        material =
          mesh.material.subMaterials[
            mesh.subMeshes[pickInfo.subMeshId].materialIndex
            ];
      } else {
        material = mesh.material.subMaterials[mesh.subMeshes[0].materialIndex];
      }
    }
    
    if (!material) return;
    if (!material.isDefault) {
      if(material.inStatic) {
        openMaterialEditModal(material);
        materialSelect({reset: true})
      }
      else {
        cleanUp();
        materialSelect({
          img: material.diffuseTexture.url,
          type: material.materialType,
          fullName: material.name
        });
      }
    }
  };
  
  /*const closeMaterialEditModal = function (){
    reduxStore.dispatch(setMenuMode(MATERIAL_MENU));
  };*/
  
  const getMaterialFromURL = function (url){
    
    const materialName = url
      .replace(/^.*[\\/]/, "")
      .split(".")[0];
    
    return store.scene.getMaterialByName(materialName);
  }
  
  let onMaterialEdit = function (selectedMaterial) {
    if (!selectedMaterial.imgURL) return;
    if (!selectedMaterial.name) return;

    // let material = getMaterialFromURL(selectedMaterial.imgURL);
    let material = store.scene.getMaterialByName(selectedMaterial.name);
    if (!material) {
      material = createMaterialFromImage(selectedMaterial.imgURL, selectedMaterial.type, selectedMaterial.name);
      saveMaterialInBackend(material);
    }
    openMaterialEditModal(material);
  }
  
  let cloneMaterial = function (selectedMaterial) {
    if (!selectedMaterial.imgURL) return;
    
    let material = getMaterialFromURL(selectedMaterial.imgURL);
    let disposeAfter = false;
    if (!material) {
      material = createMaterialFromImage(selectedMaterial.imgURL, selectedMaterial.type);
      disposeAfter = true;
      // saveMaterialInBackend(material);
    }
    return makeMaterialUnique(material, disposeAfter);
  }
  
  const degreeToRadian = (degree) => (parseFloat(degree) * Math.PI) / 180;
  const radianToDegree = (radian) => (Math.round((radian * 180) / Math.PI)).toString();
  
  const getMaterialPropertiesForUI = function (material){
    const materialProperties = {
      name: material.name,
      thumbnailURL: material.diffuseTexture?.url,
      opacity: material.alpha * 100,
      overlay: null,
      uScale: null,
      vScale: null,
      uOffset: null,
      vOffset: null,
      angle: null,
    }
    
    if (material.diffuseTexture) {
      materialProperties.uScale = DisplayOperation.convertToDefaultDimension(
        1 / (material.diffuseTexture.uScale * store.unit_absolute_scale * 10)
      );
      materialProperties.vScale = DisplayOperation.convertToDefaultDimension(
        1 / (material.diffuseTexture.vScale * store.unit_absolute_scale * 10)
      );
      materialProperties.uOffset = DisplayOperation.convertToDefaultDimension(
        material.diffuseTexture.uOffset / (store.unit_absolute_scale * 10)
      );
      materialProperties.vOffset = DisplayOperation.convertToDefaultDimension(
        material.diffuseTexture.vOffset / (store.unit_absolute_scale * 10)
      );
      materialProperties.angle = radianToDegree(material.diffuseTexture.wAng);
    }
    
    if (material.diffuseColor) {
      let value = material.diffuseColor;
      materialProperties.overlay =
        "rgb(" +
        value.r * 255 +
        ", " +
        value.g * 255 +
        ", " +
        value.b * 255 +
        ")";
    }
    
    return materialProperties;
    
  };
  
  const getPureMaterialProperties = function (material){
    const materialProperties = {
      opacity: material.alpha,
      overlay: null,
      uScale: null,
      vScale: null,
      uOffset: null,
      vOffset: null,
      angle: null,
    }
    
    if (material.diffuseTexture) {
      materialProperties.uScale = material.diffuseTexture.uScale;
      materialProperties.vScale = material.diffuseTexture.vScale;
      materialProperties.uOffset = material.diffuseTexture.uOffset;
      materialProperties.vOffset = material.diffuseTexture.vOffset;
      materialProperties.angle = material.diffuseTexture.wAng;
    }
    
    if (material.diffuseColor) {
      materialProperties.overlay = material.diffuseColor.asArray();
    }
    
    return materialProperties;
    
  };

  const executeCommand = function (){
    if (!material) return;
    const updatedMaterialProperties = getPureMaterialProperties(material);
    
    const commands = [];
    _.forEach(updatedMaterialProperties, (updatedValue, property) => {
      const oldValue = previousProperties[property];
      let propertyHasChanged = !_.isEqual(oldValue, updatedValue)
      
      if (propertyHasChanged){
        const data = {};
        data.structure_id = store.activeLayer.structure_id;
        data._prevData = {
          materialID: material.id,
          propertyID: property,
        };
        
        data._newData = {
          materialID: material.id,
          propertyID: property,
        };
        
        data._prevData[property] = oldValue;
        data._newData[property] = updatedValue;
        
        let cmd = new Command(
          "edit" + property,
          data,
          getEditMaterialCommandLogic(),
          getEditMaterialSaveData
        );
        
        commands.push(cmd);
      }
    });
    
    if (!_.isEmpty(commands)) CommandManager.execute(commands, false);
    cleanUp();
    
  }

  const updateName = function (name) {
    if (material) {
      material.name = name;
      material.id = name;
    }
  }

  const cleanUp = function (){
    material = null;
    previousProperties = null;
  }
  
  const openMaterialEditModal = function (mat) {
    if (material === mat) return;

    const materialPropertiesForUI = getMaterialPropertiesForUI(mat);
    const materialProperties = getPureMaterialProperties(mat);

    material = mat;
    previousProperties = materialProperties;
    
    reduxStore.dispatch(setMenuMode(MATERIAL_EDIT_MENU));
    dispatchRedux(materialPropertiesForUI)
    
  };

  const makeMaterialUnique = function (material, disposeAfter) {
    const image = new Image();
    
    if (material.diffuseTexture) image.src = material.diffuseTexture.url;
    
    if (image.src) {
      let fileName = image.src.substr(image.src.lastIndexOf("/") + 1);
      const fileNameList = fileName.split(".");
      fileName =
        fileNameList[0] +
        "_" +
        Math.random().toString(36).substring(2, 10) +
        "." +
        fileNameList[1];
  
      return fetch(image.src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], fileName, blob);
          return uploadUserMaterial([file], material.materialType)
        }).then((newObjects) => {
          if (disposeAfter) material.dispose();
          return newObjects;
        });
    }
    
    return Promise.reject();
  };
  
  let getEditMaterialCommandLogic = function () {
    return {
      execute: editMaterial,
      unexecute: reEditMaterial,
    };
  };
  
  const dispatchRedux = function (materialPropertiesForUI){
    reduxStore.dispatch(setMaterialEditValues(materialPropertiesForUI));
  }
  
  function editMaterial() {
    let data = this.data._newData;
    
    let material = store.scene.getMaterialByID(data.materialID);
    // let uiElement = document.getElementById(data.propertyID);
    editMaterialProperty(data.propertyID, data[data.propertyID], material);
    
    const materialPropertiesForUI = getMaterialPropertiesForUI(material);
    dispatchRedux(materialPropertiesForUI)
  }
  
  function reEditMaterial() {
    let data = this.data._prevData;
    
    let material = store.scene.getMaterialByID(data.materialID);
    // let uiElement = document.getElementById(data._newData.propertyID);
    editMaterialProperty(data.propertyID, data[data.propertyID], material);
    
    const materialPropertiesForUI = getMaterialPropertiesForUI(material);
    dispatchRedux(materialPropertiesForUI)
  }
  
  function editMaterialProperty(propId, value, material) {
    switch (propId) {
      case "opacity":
        material.alpha = Number.parseFloat(value);
        // prop.value = Number.parseFloat(value) * 100;
        break;
      case "overlay":
        material.diffuseColor = BABYLON.Color3.FromArray(value);
        /*ScopeUtils.getScope().updateMatColorP(
          "rgb(" +
          value[0] * 255 +
          ", " +
          value[1] * 255 +
          ", " +
          value[2] * 255 +
          ")"
        );*/
        break;
      case "uScale":
        material.diffuseTexture.uScale = Number.parseFloat(value);
        /*prop.value = DisplayOperation.convertToDefaultDimension(
          1 / (material.diffuseTexture.uScale * store.unit_absolute_scale * 10)
        );*/
        break;
      case "vScale":
        material.diffuseTexture.vScale = Number.parseFloat(value);
        /*prop.value = DisplayOperation.convertToDefaultDimension(
          1 / (material.diffuseTexture.vScale * store.unit_absolute_scale * 10)
        );*/
        break;
      case "uOffset":
        material.diffuseTexture.uOffset = Number.parseFloat(value);
        /*prop.value = DisplayOperation.convertToDefaultDimension(
          material.diffuseTexture.uOffset / (store.unit_absolute_scale * 10)
        );*/
        break;
      case "vOffset":
        material.diffuseTexture.vOffset = Number.parseFloat(value);
        /*prop.value = DisplayOperation.convertToDefaultDimension(
          material.diffuseTexture.vOffset / (store.unit_absolute_scale * 10)
        );*/
        break;
      case "angle":
        material.diffuseTexture.wAng = Number.parseFloat(value);
        // prop.value = Math.round((material.diffuseTexture.wAng * 180) / Math.PI);
        break;
    }
  }
  
  let getEditMaterialSaveData = function () {
    let data = this.data;
    let saveData = AutoSave.getSaveDataPrototype();
    
    saveData.commandId = this.id;
    saveData.data.saveType = "editMaterial";
    
    saveData.data.identifier = {
      structure_id: data.structure_id,
      floorkey: store.floorkey,
      id: data._newData.materialID,
    };
    
    saveData.data.afterOperationData = data._newData;
    saveData.data.beforeOperationData = data._prevData;
    
    return saveData;
  };
  
  const updateProperty = function (property, value){
    propertyUpdaterMapping[property](value);
  };
  
  return {
    onMaterialEdit,
    executeCommand,
    updateProperty,
    cloneMaterial,
    getMaterialFromURL,
    pickMaterialFromScene,
    setMaterialSelect,
    updateName,
    cleanUp,
    // getIsMaterialPicked,
    // setIsMaterialPicked
  }
  
  /*
  
  Old logic
  
  var opacity = document.getElementById("opacity");
    var uscale = document.getElementById("uscale");
    var vscale = document.getElementById("vscale");
    var uoffset = document.getElementById("uoffset");
    var voffset = document.getElementById("voffset");
    var angle = document.getElementById("matAngle");
    var makeUnique = document.getElementById("mat-save");
    var cpick = document.getElementById("diffuseColor");
    
    // let propertyData = {};
    // propertyData = {
    //     materialID: mat.id,
    //     opacity: mat.alpha,
    //     uscale: mat.diffuseTexture.uScale,
    //     vscale: mat.diffuseTexture.vScale,
    //     uoffset: mat.diffuseTexture.uOffset,
    //     voffset: mat.diffuseTexture.vOffset,
    //     matAngle: mat.diffuseTexture.wAng
    // };
    
    opacity.value = mat.alpha * 100;
    opacity.onmousedown = function () {
      this.data = {
        _prevData: {
          materialID: mat.id,
          propertyID: "opacity",
          opacity: mat.alpha,
        },
        structure_id: store.activeLayer.structure_id,
      };
    };
    opacity.oninput = function () {
      mat.alpha = this.value / 100;
    };
    opacity.onmouseup = function () {
      if (!Number.isNaN(Number.parseFloat(this.value))) {
        if (Number.parseFloat(this.value) / 100 !== this.data._prevData.uscale) {
          this.data._newData = {
            materialID: mat.id,
            propertyID: "opacity",
            opacity: mat.alpha,
          };
          
          let cmd = new Command(
            "editOpacity",
            this.data,
            getEditMaterialCommandLogic(),
            getEditMaterialSaveData
          );
          CommandManager.execute(cmd, false);
        }
      }
    };
    
    if (mat.diffuseColor) {
      let value = mat.diffuseColor;
      let cval =
        "rgb(" +
        value.r * 255 +
        ", " +
        value.g * 255 +
        ", " +
        value.b * 255 +
        ")";
      ScopeUtils.getScope().updateMatColorP(cval);
      
      cpick.updateColorChange = function (value) {
        if (mat.diffuseColor && value) {
          this.data = {
            _prevData: {
              materialID: mat.id,
              propertyID: "diffuseColor",
              diffuseColor: mat.diffuseColor.asArray(),
            },
            structure_id: store.activeLayer.structure_id,
          };
          
          let colors = value
            .substring(4, value.length - 1)
            .replace(/ /g, "")
            .split(",");
          mat.diffuseColor.r = parseInt(colors[0]) / 255;
          mat.diffuseColor.g = parseInt(colors[1]) / 255;
          mat.diffuseColor.b = parseInt(colors[2]) / 255;
          
          this.data._newData = {
            materialID: mat.id,
            propertyID: "diffuseColor",
            diffuseColor: mat.diffuseColor.asArray(),
          };
          
          let cmd = new Command(
            "matColorPickerButton",
            this.data,
            getEditMaterialCommandLogic(),
            getEditMaterialSaveData
          );
          CommandManager.execute(cmd, false);
        }
      };
    }
    
    if (mat.diffuseTexture) {
      uscale.value = DisplayOperation.convertToDefaultDimension(
        1 / (mat.diffuseTexture.uScale * store.unit_absolute_scale * 10)
      );
      vscale.value = DisplayOperation.convertToDefaultDimension(
        1 / (mat.diffuseTexture.vScale * store.unit_absolute_scale * 10)
      );
      uoffset.value = DisplayOperation.convertToDefaultDimension(
        mat.diffuseTexture.uOffset / (store.unit_absolute_scale * 10)
      );
      voffset.value = DisplayOperation.convertToDefaultDimension(
        mat.diffuseTexture.vOffset / (store.unit_absolute_scale * 10)
      );
      angle.value = Math.round((mat.diffuseTexture.wAng * 180) / Math.PI);
      
      uscale.onfocus = function () {
        this.data = {
          _prevData: {
            materialID: mat.id,
            propertyID: "uscale",
            uscale: mat.diffuseTexture.uScale,
          },
          structure_id: store.activeLayer.structure_id,
        };
      };
      uscale.oninput = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          mat.diffuseTexture.uScale =
            1 /
            (store.unit_absolute_scale *
              10 *
              DisplayOperation.getOriginalDimension(this.value));
        }
      };
      uscale.onfocusout = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          if (mat.diffuseTexture.uScale !== this.data._prevData.uscale) {
            this.data._newData = {
              materialID: mat.id,
              propertyID: "uscale",
              uscale: mat.diffuseTexture.uScale,
            };
            
            let cmd = new Command(
              "editUScale",
              this.data,
              getEditMaterialCommandLogic(),
              getEditMaterialSaveData
            );
            CommandManager.execute(cmd, false);
          }
        } else {
          mat.diffuseTexture.uScale = this.data._prevData.uscale;
          this.value = DisplayOperation.convertToDefaultDimension(
            1 / (mat.diffuseTexture.uScale * store.unit_absolute_scale * 10)
          );
        }
      };
      
      vscale.onfocus = function () {
        this.data = {
          _prevData: {
            materialID: mat.id,
            propertyID: "vscale",
            vscale: mat.diffuseTexture.vScale,
          },
          structure_id: store.activeLayer.structure_id,
        };
      };
      vscale.oninput = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          mat.diffuseTexture.vScale =
            1 /
            (store.unit_absolute_scale *
              10 *
              DisplayOperation.getOriginalDimension(this.value));
        }
      };
      vscale.onfocusout = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          if (mat.diffuseTexture.vScale !== this.data._prevData.vscale) {
            this.data._newData = {
              materialID: mat.id,
              propertyID: "vscale",
              vscale: mat.diffuseTexture.vScale,
            };
            
            let cmd = new Command(
              "editVScale",
              this.data,
              getEditMaterialCommandLogic(),
              getEditMaterialSaveData
            );
            CommandManager.execute(cmd, false);
          }
        } else {
          mat.diffuseTexture.vScale = this.data._prevData.vscale;
          this.value = DisplayOperation.convertToDefaultDimension(
            1 / (mat.diffuseTexture.vScale * store.unit_absolute_scale * 10)
          );
        }
      };
      
      uoffset.onfocus = function () {
        this.data = {
          _prevData: {
            materialID: mat.id,
            propertyID: "uoffset",
            uoffset: mat.diffuseTexture.uOffset,
          },
          structure_id: store.activeLayer.structure_id,
        };
      };
      uoffset.oninput = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          mat.diffuseTexture.uOffset =
            DisplayOperation.getOriginalDimension(this.value) *
            store.unit_absolute_scale *
            10;
        }
      };
      uoffset.onfocusout = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          if (mat.diffuseTexture.uOffset !== this.data._prevData.uoffset) {
            this.data._newData = {
              materialID: mat.id,
              propertyID: "uoffset",
              uoffset: mat.diffuseTexture.uOffset,
            };
            
            let cmd = new Command(
              "editUOffset",
              this.data,
              getEditMaterialCommandLogic(),
              getEditMaterialSaveData
            );
            CommandManager.execute(cmd, false);
          }
        } else {
          mat.diffuseTexture.uOffset = this.data._prevData.uoffset;
          this.value = DisplayOperation.convertToDefaultDimension(
            mat.diffuseTexture.uOffset / (store.unit_absolute_scale * 10)
          );
        }
      };
      
      voffset.onfocus = function () {
        this.data = {
          _prevData: {
            materialID: mat.id,
            propertyID: "voffset",
            voffset: mat.diffuseTexture.vOffset,
          },
          structure_id: store.activeLayer.structure_id,
        };
      };
      voffset.oninput = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          mat.diffuseTexture.vOffset =
            DisplayOperation.getOriginalDimension(this.value) *
            store.unit_absolute_scale *
            10;
        }
      };
      voffset.onfocusout = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          if (mat.diffuseTexture.vOffset !== this.data._prevData.voffset) {
            this.data._newData = {
              materialID: mat.id,
              propertyID: "voffset",
              voffset: mat.diffuseTexture.vOffset,
            };
            
            let cmd = new Command(
              "editVOffset",
              this.data,
              getEditMaterialCommandLogic(),
              getEditMaterialSaveData
            );
            CommandManager.execute(cmd, false);
          }
        } else {
          mat.diffuseTexture.vOffset = this.data._prevData.voffset;
          this.value = DisplayOperation.convertToDefaultDimension(
            mat.diffuseTexture.vOffset / (store.unit_absolute_scale * 10)
          );
        }
      };
      
      angle.onfocus = function () {
        this.data = {
          _prevData: {
            materialID: mat.id,
            propertyID: "matAngle",
            matAngle: mat.diffuseTexture.wAng,
          },
          structure_id: store.activeLayer.structure_id,
        };
      };
      angle.oninput = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          mat.diffuseTexture.wAng = (parseFloat(this.value) * Math.PI) / 180;
        }
      };
      angle.onfocusout = function () {
        if (!Number.isNaN(Number.parseFloat(this.value))) {
          if (
            (Number.parseFloat(this.value) * Math.PI) / 180 !==
            this.data._prevData.matAngle
          ) {
            this.data._newData = {
              materialID: mat.id,
              propertyID: "matAngle",
              matAngle: mat.diffuseTexture.wAng,
            };
            
            let cmd = new Command(
              "editMaterialAngle",
              this.data,
              getEditMaterialCommandLogic(),
              getEditMaterialSaveData
            );
            CommandManager.execute(cmd, false);
          }
        } else {
          mat.diffuseTexture.wAng = this.data._prevData.matAngle;
          this.value = Math.round((mat.diffuseTexture.wAng * 180) / Math.PI);
        }
      };
    }
    
    makeUnique.onclick = function () {
      onMakeMaterialUnique(mat);
    };
    
    $("#material-edit-collapse").collapse("show");
    //
    // $('#babylonMaterialModal').modal({
    //     backdrop: false,
    //     show: true
    // });
    //
    // $('.modal-dialog').draggable({
    //     handle: ".modal-header"
    // });
  
   */
  
})();

// TODO: MANVITH CHECK
// TODO: ADITYA CHECK
// comment: this file does not match with the materialEditUi.js in the original snaptrude repo.

function editMaterialOriginal() {
  let data = this.data._newData;

  let material = store.scene.getMaterialByID(data.materialID);
  // let uiElement = document.getElementById(data.propertyID);
  editMaterialProperty({id: data.propertyID}, data[data.propertyID], material);
}

function reEditMaterial() {
  let data = this.data;

  let material = store.scene.getMaterialByID(data._prevData.materialID);
  let uiElement = document.getElementById(data._newData.propertyID);
  editMaterialProperty(uiElement, data._prevData[data._newData.propertyID], material);
}

function editMaterialProperty(prop, value, material) {
  switch (prop.id) {
      case "opacity":
          material.alpha = Number.parseFloat(value);
          prop.value = Number.parseFloat(value) * 100;
          break;
      case "diffuseColor":
          material.diffuseColor = BABYLON.Color3.FromArray(value);
          // ScopeUtils.getScope().updateMatColorP('rgb(' + value[0] * 255 + ', ' + value[1] * 255 + ', ' + value[2] * 255 + ')');
          break;
      case "overlay":
        material.diffuseColor = BABYLON.Color3.FromArray(value);
        // ScopeUtils.getScope().updateMatColorP('rgb(' + value[0] * 255 + ', ' + value[1] * 255 + ', ' + value[2] * 255 + ')');
        break;
      case "uscale":
          material.diffuseTexture.uScale = Number.parseFloat(value);
          prop.value = DisplayOperation.convertToDefaultDimension(1 / (material.diffuseTexture.uScale * store.unit_absolute_scale * 10));
          break;
      case "vscale":
          material.diffuseTexture.vScale = Number.parseFloat(value);
          prop.value = DisplayOperation.convertToDefaultDimension(1 / (material.diffuseTexture.vScale * store.unit_absolute_scale * 10));
          break;
      case "uoffset":
          material.diffuseTexture.uOffset = Number.parseFloat(value);
          prop.value = DisplayOperation.convertToDefaultDimension(material.diffuseTexture.uOffset / (store.unit_absolute_scale * 10));
          break;
      case "voffset":
          material.diffuseTexture.vOffset = Number.parseFloat(value);
          prop.value = DisplayOperation.convertToDefaultDimension(material.diffuseTexture.vOffset / (store.unit_absolute_scale * 10));
          break;
      case "matAngle":
          material.diffuseTexture.wAng = Number.parseFloat(value);
          prop.value = Math.round(material.diffuseTexture.wAng * 180 / Math.PI);
          break;
  }
}

export default editMaterial;

export {
  editMaterialOriginal,
  reEditMaterial,
};
