"use strict";
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { getCSGFormOfMesh, getSerializedFormOfMesh, onSolid, removeMeshFromStructure, } from "../extrafunc.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";
import { copyMaterialData } from "../../libs/mats.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { Furniture } from "../snaptrudeDS/furniture.ds.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { updateModifications } from "../../libs/sceneStateFuncs.js";
import { ComponentManager } from "../componentManager/componentManager.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { StateMachine } from "../Classes/StateMachine.js";
import { getFace } from "../../libs/faceFuncs.js";
import { getWallAngle } from "../../libs/drawStairEvents.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { updateCursor } from "../../../containers/editor/cursorHandler.js";
import { cursor } from "../../../themes/cursor.js";

var furnitureOperation = (function () {
  "use strict";
  let _furnitureMeshName = null;
  let _furniture = null;
  let _selectionBox = null;
  let wallst1 = null;
  let wallst2 = null;
  var updateFurnitureOffset = null;
  var updateFurnitureOffsetY = null;
  var wallsty1 = null;
  var wallsty2 = null;
  var furnitureCommandName = "furnitureOperation";
  let _newFurnitureInScene = false;

  var _getNearestPoint = function (vw, point) {
    let pt1 = null;
    let pt2 = null;
    let pts = [];
    switch (point) {
      case "start":
        if (
          BABYLON.Vector3.Distance(vw[0], vw[4]) <
          BABYLON.Vector3.Distance(vw[0], vw[2])
        ) {
          pt1 = _getFaceMidPoint(vw, [3, 6, 0, 4]);
          pt2 = _getFaceMidPoint(vw, [5, 1, 2, 7]);
        } else {
          pt1 = _getFaceMidPoint(vw, [0, 2, 5, 3]);
          pt2 = _getFaceMidPoint(vw, [7, 4, 1, 6]);
        }
        pts = [pt1, pt2];
        break;
      case "end":
        if (
          BABYLON.Vector3.Distance(vw[0], vw[2]) <
          BABYLON.Vector3.Distance(vw[0], vw[4])
        ) {
          pt1 = _getFaceMidPoint(vw, [5, 2, 3, 0]);
          pt2 = _getFaceMidPoint(vw, [1, 7, 6, 4]);
        } else {
          pt1 = _getFaceMidPoint(vw, [3, 0, 6, 4]);
          pt2 = _getFaceMidPoint(vw, [5, 2, 1, 7]);
        }
        pts = [pt1, pt2];
        break;
      case "start-y":
        pt1 = _getFaceMidPoint(vw, [1, 5, 3, 6]);
        pt2 = _getFaceMidPoint(vw, [2, 7, 0, 4]);
        pts = [pt1, pt2];
        break;
      case "end-y":
        pt1 = _getFaceMidPoint(vw, [1, 5, 3, 6]);
        pt2 = _getFaceMidPoint(vw, [2, 7, 0, 4]);
        pts = [pt1, pt2];
        break;
    }

    return pts;
  };

  var _getFaceMidPoint = function (vw, points) {
    return vw[points[0]]
      .add(vw[points[1]])
      .scale(0.5)
      .add(vw[points[2]].add(vw[points[3]]).scale(0.5))
      .scale(0.5);
  };

  var _drawFurniture = function (pickInfo, _furnitureInstance, _furniture) {
    let placementUid = null;
    let strId = null;
    let potentialParent = null;
    let wallDeletionCommand = null;
    let wallCreationCommand = null;

    if (_furniture.getSnaptrudeDS().snapTo === "wall") {
      if (_selectionBox && _furniture.getSnaptrudeDS().cutHole) {
        let furnitureCSG = getCSGFormOfMesh(_selectionBox);

        // if (pickInfo.pickedMesh.source){
        //     pickInfo.pickedMesh.makeGeometryUnique();
        // }
        if (pickInfo.pickedMesh.childrenComp.length === 0)
          pickInfo.pickedMesh.getSnaptrudeDS().originalWallMesh =
            getSerializedFormOfMesh(pickInfo.pickedMesh);

        let options = {
          preserveChildren: true,
        };

        let wallDeletionCommandData =
          commandUtils.deletionOperations.getCommandData(
            pickInfo.pickedMesh,
            null,
            options
          );

        let wallCSG = getCSGFormOfMesh(pickInfo.pickedMesh);

        let newCSG = wallCSG.subtract(furnitureCSG);

        let newWall = newCSG.toMesh("wall", null, store.scene);
        newWall.type = "wall";

        newWall.position = pickInfo.pickedMesh.position;
        newWall.storey = pickInfo.pickedMesh.storey;
        // console.log(jQuery.extend({}, newWall.position), jQuery.extend({}, pickInfo.pickedMesh.position));
        let wall = new Wall(newWall, pickInfo.pickedMesh.room_id);

        wall.assignProperties();
        wall.originalWallMesh =
          pickInfo.pickedMesh.getSnaptrudeDS().originalWallMesh;

        // Storing brep in originalWallMesh. Needed after deletion of fenestration
        if (pickInfo.pickedMesh.getSnaptrudeDS().brep) {
          wall.originalWallMesh.brep = store.resurrect.stringify(
            pickInfo.pickedMesh.getSnaptrudeDS().brep
          );
        }

        wall.neighbours = pickInfo.pickedMesh.getSnaptrudeDS().neighbours;
        wall.neighboursDetails =
          pickInfo.pickedMesh.getSnaptrudeDS().neighboursDetails;
        wall.setTopCoords(pickInfo.pickedMesh.getSnaptrudeDS().topCoords);
        wall.setBottomCoords(pickInfo.pickedMesh.getSnaptrudeDS().bottomCoords);
        wall.setMidYHeight(pickInfo.pickedMesh.getSnaptrudeDS().midY);
        wall.direction = pickInfo.pickedMesh.getSnaptrudeDS().direction;
        wall.bottomLineSegment =
          pickInfo.pickedMesh.getSnaptrudeDS().bottomLineSegment;
        wall.room_id = pickInfo.pickedMesh.getSnaptrudeDS().bottomLineSegment;
        wall.profile = pickInfo.pickedMesh.getSnaptrudeDS().profile;
        wall.topLineSegment =
          pickInfo.pickedMesh.getSnaptrudeDS().topLineSegment;
        wall.properties = pickInfo.pickedMesh.getSnaptrudeDS().properties;

        // console.log(pickInfo.pickedMesh.getSnaptrudeDS().originalWallMesh);

        if (!wall.brep) {
          copyMaterialData(pickInfo.pickedMesh, newWall);
        }

        // newWall.setAbsolutePosition(new BABYLON.Vector3.Zero());
        // let bbinfo = newWall.getBoundingInfo();
        // let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
        // newWall.setPivotPoint(centroid);

        let structures = StructureCollection.getInstance();
        wall.mesh.structure_id = pickInfo.pickedMesh.structure_id;
        let structure_id = pickInfo.pickedMesh.structure_id;
        let str = structures.getStructureById(wall.mesh.structure_id);
        let level = str.getLevelByUniqueId(
          str.getObjectByUniqueId(pickInfo.pickedMesh.uniqueId).level_id
        );
        level.addWallToLevel(wall, false);

        newWall.childrenComp = pickInfo.pickedMesh.childrenComp;
        // newWall.childrenComp.push(_furnitureInstance);
        _furnitureInstance.structure_id = structure_id;
        _furnitureInstance.storey = newWall.storey;

        let _newFurniture = new Furniture(_furnitureInstance);
        level.addFurnitureToLevel(_newFurniture, false);
        const _furnitureDS = _furniture.getSnaptrudeDS();
        _newFurniture.cutHole = _furnitureDS.cutHole;
        _newFurniture.snapTo = "wall";
        _newFurniture.addTeamMetaData(_furnitureDS.teamId, _furnitureDS.elementId, _furnitureDS.cost, _furnitureDS.familyName);
        _newFurniture.importType = _furnitureDS.importType;
        newWall.childrenComp.forEach(function (child) {
          child.setParent(newWall);
        });

        potentialParent = newWall;

        let wallCreationCommandData =
          commandUtils.creationOperations.getCommandData(newWall);
        wallCreationCommand = commandUtils.creationOperations.getCommand(
          "wallWithHoleCreation",
          wallCreationCommandData
        );
        wallDeletionCommand = commandUtils.deletionOperations.getCommand(
          "wallDeletion",
          wallDeletionCommandData,
          options
        );
      } else {
        let wall = pickInfo.pickedMesh;
        // let bbinfo = _furnitureInstance.getBoundingInfo();
        let structures = StructureCollection.getInstance();
        let structure_id = wall.structure_id;
        let str = structures.getStructureById(wall.structure_id);
        let level = str.getLevelByUniqueId(
          str.getObjectByUniqueId(wall.uniqueId).level_id
        );
        _furnitureInstance.structure_id = structure_id;
        _furnitureInstance.storey = wall.storey;

        let _newFurniture = new Furniture(_furnitureInstance);
        _newFurniture.snapTo = "wall";
        const _furnitureDS = _furniture.getSnaptrudeDS();
        _newFurniture.importType = _furnitureDS.importType;
        _newFurniture.addTeamMetaData(_furnitureDS.teamId, _furnitureDS.elementId, _furnitureDS.cost, _furnitureDS.familyName);
        level.addFurnitureToLevel(_newFurniture, false);
        potentialParent = wall;
        // wall.childrenComp.push(_furnitureInstance);
        // _furnitureInstance.setParent(wall);
        strId = structure_id;
        placementUid = wall.uniqueId;
        //   let interior_offset = bbinfo.boundingBox.minimumWorld.y;
        // _furnitureInstance.position.x = pickInfo.pickedPoint.x;
        // _furnitureInstance.position.y += interior_offset;
        // _furnitureInstance.position.z = pickInfo.pickedPoint.z;
        // _furnitureInstance.position.y += Math.abs(interior_offset);

        // pickInfo.pickedMesh.dispose();
      }
    }
    else if (_furniture.getSnaptrudeDS().snapTo === "floor") {
      let floor = pickInfo.pickedMesh;
      // let bbinfo = _furnitureInstance.getBoundingInfo();
      let structures = StructureCollection.getInstance();
      let structure_id = floor.structure_id;
      let str = structures.getStructureById(floor.structure_id);
      let level = str.getLevelByUniqueId(
        str.getObjectByUniqueId(floor.uniqueId).level_id
      );
      _furnitureInstance.structure_id = structure_id;
      _furnitureInstance.storey = floor.storey;

      let _newFurniture = new Furniture(_furnitureInstance);
      const _furnitureDS = _furniture.getSnaptrudeDS();
      _newFurniture.snapTo = "floor";
      _newFurniture.importType = _furnitureDS.importType;
      _newFurniture.addTeamMetaData(_furnitureDS.teamId, _furnitureDS.elementId, _furnitureDS.cost, _furnitureDS.familyName);
      level.addFurnitureToLevel(_newFurniture, false);
      potentialParent = floor;
      // floor.childrenComp.push(_furnitureInstance);
      // _furnitureInstance.setParent(floor);
      strId = structure_id;
      placementUid = floor.uniqueId;
      //   let interior_offset = bbinfo.boundingBox.minimumWorld.y;
      // _furnitureInstance.position.x = pickInfo.pickedPoint.x;
      // _furnitureInstance.position.y += interior_offset;
      // _furnitureInstance.position.z = pickInfo.pickedPoint.z;
      // _furnitureInstance.position.y += Math.abs(interior_offset);

      // _furniture.parent = floor;
    }
    else {
      let structure =
        StructureCollection.getInstance().getStructures()[
          store.activeLayer.structure_id
        ];
      let level = structure.getLevel(0, 1);
      _furnitureInstance.structure_id = store.activeLayer.structure_id;
      _furnitureInstance.storey = store.activeLayer.storey;

      let _newFurniture = new Furniture(_furnitureInstance);
      _newFurniture.snapTo = "none";
      const _furnitureDS = _furniture.getSnaptrudeDS();
      _newFurniture.importType = _furnitureDS.importType;
      _newFurniture.addTeamMetaData(_furnitureDS.teamId, _furnitureDS.elementId, _furnitureDS.cost, _furnitureDS.familyName);
      level.addFurnitureToLevel(_newFurniture, false);
    }

    let parentChangeCommand = null;
    if (potentialParent) {
      parentChangeCommand = commandUtils.parentChildOperations.expressCheckout(
        "moveParentChange",
        _furnitureInstance,
        potentialParent
      );
    }

    let commandsList = [];
    let commandsExecuteList = [];
    let furnitureInstanceCreationCommandData =
      commandUtils.creationOperations.getCommandData(_furnitureInstance);
    let furnitureInstanceCreationCommand =
      commandUtils.creationOperations.getCommand(
        furnitureCommandName,
        furnitureInstanceCreationCommandData
      );

    _furniture.scaling = new BABYLON.Vector3(0, 0, 0);
    _furniture.position = new BABYLON.Vector3(10000, 0, 10000);

    if (wallCreationCommand) {
      commandsList.push(wallCreationCommand, wallDeletionCommand);
      commandsExecuteList.push(false, true);
    }

    if (_newFurnitureInScene) {
      let furnitureCreationCommandData =
        commandUtils.creationOperations.getCommandData(_furniture);
      let furnitureCreationCommand = commandUtils.creationOperations.getCommand(
        furnitureCommandName,
        furnitureCreationCommandData
      );

      commandsList.push(
        furnitureCreationCommand,
        furnitureInstanceCreationCommand
      );
      commandsExecuteList.push(false, false);

      if (parentChangeCommand) {
        commandsList.push(parentChangeCommand);
        commandsExecuteList.push(true);
      }
      CommandManager.execute(commandsList, commandsExecuteList);

      _newFurnitureInScene = false;
    } else {
      commandsList.push(furnitureInstanceCreationCommand);
      commandsExecuteList.push(false);

      if (parentChangeCommand) {
        commandsList.push(parentChangeCommand);
        commandsExecuteList.push(true);
      }
      CommandManager.execute(commandsList, commandsExecuteList);
    }

    // _furniture.rotation = new BABYLON.Vector3(0, 0, 0);
    // _door.visibility = 0;
    onSolid(_furnitureInstance, false, {enableFurnitureEdgesOnce: true});
    updateModifications();
  };

  function loadFurnitureMesh(importType=null, extraData = {direct: false}, tasks) {
    const direct = extraData.direct;
    try {
      if (tasks.length > 0) {
        let mesh = tasks[0].loadedMeshes[0];
        mesh.name = tasks[0].name;
        mesh.type = "furniture";
        mesh.originalScaling = mesh.scaling.clone();
        mesh.originalRotation = mesh.rotation.clone();
        let furniture = ComponentManager.prepareLoadedMesh(mesh);
        furniture.snapTo = ScopeUtils.getInteriorComponentSnapToProperty();
        furniture.cutHole = ScopeUtils.getInteriorComponentCutHoleProperty();
        if(extraData.teamId){
          furniture.addTeamMetaData(extraData.teamId, extraData.elementId, extraData.cost, extraData.familyName);
        }

        if (importType) {
          furniture.importType = importType;
          furniture.snapTo = "none";
        }

        if (direct) {
          placeDirect(mesh);
        } else {
          dispatchEvents.call(this, mesh);
          this.setMetadata();
        }
      }
      ComponentManager.reset();
    } catch (err) {
      console.log(err);
      document.body.style.cursor = "default";
      ComponentManager.reset();
    }
  }

  function dispatchEvents(mesh) {
    document.body.style.cursor = "default";
    this.setFurnitureMeshName(mesh.name);
    store.canvas.addEventListener("pointerdown", this.onPointerDown, false);
    store.canvas.addEventListener("pointermove", this.onPointerMove, false);
    store.canvas.addEventListener("pointerup", this.onPointerUp, false);
    store.ACTIVE_EVENT = { event: "furnitureOperation" };
  }

  function setFurnitureMeshName(name) {
    _furnitureMeshName = name;
  }

  function clearEvents() {
    store.canvas.removeEventListener("pointerdown", this.onPointerDown, false);
    store.canvas.removeEventListener("pointerup", this.onPointerUp, false);
    store.canvas.removeEventListener("pointermove", this.onPointerMove, false);
    return this._reset();
  }

  function placeDirect(furnitureMesh) {
    _furniture = furnitureMesh;
    _newFurnitureInScene = true;
    furnitureMesh.scaling = new BABYLON.Vector3(
      furnitureMesh.originalScaling.x,
      furnitureMesh.originalScaling.y,
      furnitureMesh.originalScaling.z
    );
    let furnitureName =
      furnitureMesh.name + "Ins" + furnitureMesh.instances.length;
    let _furnitureInstance = furnitureMesh.createInstance(furnitureName);
    _furnitureInstance.setAbsolutePosition(
      _furnitureInstance.getAbsolutePosition()
    );
    _furnitureInstance.position = BABYLON.Vector3.Zero();
    _furnitureInstance.computeWorldMatrix(true);
    const bBox = _furnitureInstance.getBoundingInfo().boundingBox;
    _furnitureInstance.position.y += bBox.extendSizeWorld.y;
    _furnitureInstance.type = "furniture";
    // _furnitureInstance.parent = pickInfo.pickedMesh;

    _drawFurniture({}, _furnitureInstance, furnitureMesh);
    furnitureOperation._reset();
  }

  return {
    onPointerDown: function (evt) {
      if (StateMachine.EventValidation.isPointerDownWithMiddleButton(evt))
        return;
      if (store.isiPad) {
        // store.newScene.activeCamera.detachControl(canvas);
      }
      let nfur = store.newScene.getMeshByName(_furnitureMeshName);
      let snap_to = ScopeUtils.getInteriorComponentSnapToProperty();
      if (nfur) {
        if (nfur.getSnaptrudeDS()?.importType) {
          snap_to = "none";
        }
      }
      let pickInfo = store.newScene.pick(
        store.newScene.pointerX,
        store.newScene.pointerY,
        function (mesh) {
          // if (mesh.type) {
          if (snap_to === "wall" && mesh.isVisible) {
            return mesh.type.toLowerCase() === "wall";
          } else if (snap_to === "floor" && mesh.isVisible) {
            return mesh.type.toLowerCase() === "floor";
          } else {
            return mesh.name.toLowerCase() === "layerrefplane";
          }
          // }
        }
      );

      if (pickInfo.hit) {
        if (_furniture) {
          let furnitureName =
            _furniture.name + "Ins" + _furniture.instances.length;
          let _furnitureInstance = _furniture.createInstance(furnitureName);
          _furnitureInstance.setAbsolutePosition(
            _furnitureInstance.getAbsolutePosition()
          );
          _furnitureInstance.type = "furniture";
          // _furnitureInstance.parent = pickInfo.pickedMesh;

          _drawFurniture(pickInfo, _furnitureInstance, _furniture);
        }
      }
    },

    onPointerUp: function (evt) {},

    onPointerMove: function (evt) {
      if (StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(evt))
        return;
      let nfur = store.newScene.getMeshByName(_furnitureMeshName);
      let snap_to = ScopeUtils.getInteriorComponentSnapToProperty();
      if (nfur) {
        if (nfur.getSnaptrudeDS()?.importType) {
          snap_to = "none";
        }
      }
      let pickInfo = store.newScene.pick(
        store.newScene.pointerX,
        store.newScene.pointerY,
        function (mesh) {
          // if (mesh.type) {
          if (snap_to === "wall" && mesh.isVisible) {
            return mesh.type.toLowerCase() === "wall";
          } else if (snap_to === "floor" && mesh.isVisible) {
            return mesh.type.toLowerCase() === "floor";
          } else {
            return mesh.name.toLowerCase() === "layerrefplane";
          }
          // }
        }
      );

      if (pickInfo.hit) {
        updateCursor(cursor.placeObject);
        let bbinfo1 = pickInfo.pickedMesh.getBoundingInfo();
        let pickedPoint = pickInfo.pickedPoint.clone();

        let face = getFace(pickInfo);
        let angle = getWallAngle(face);

        _furniture = store.newScene.getMeshByName(_furnitureMeshName);
        _furniture.visibility = 1;

        let bbinfo = _furniture.getBoundingInfo();
        _furniture.scaling = new BABYLON.Vector3(
          _furniture.originalScaling.x,
          _furniture.originalScaling.y,
          _furniture.originalScaling.z
        );
        _furniture.position.x = pickedPoint.x;
        _furniture.position.z = pickedPoint.z;
        _furniture.position.y = pickedPoint.y;

        _furniture.isPickable = false;
        _furniture.getSnaptrudeDS().snapTo = snap_to;
        if (_furniture.getSnaptrudeDS().snapTo === "wall") {
          if (_furniture.getSnaptrudeDS().cutHole) {
            if (!_selectionBox)
              _selectionBox = furnitureOperation.createFurnitureSelectionBox(
                _furniture,
                pickInfo.pickedMesh
              );
            _selectionBox.visibility = 0;
            _selectionBox.position = _furniture.position;

            let _offset =
              pickInfo.pickedMesh._properties._components._thickness /
              (store.unit_absolute_scale * 10000) /
              2;
            _furniture.position.x -= _offset * Math.sin(angle + Math.PI / 2);
            _furniture.position.z -= _offset * Math.cos(angle + Math.PI / 2);
          }
        } else {
          _furniture.position.y =
            bbinfo1.boundingBox.maximumWorld.y +
            bbinfo.boundingBox.extendSizeWorld.y;
        }
        _furniture.rotation = new BABYLON.Vector3(
          _furniture.originalRotation.x,
          _furniture.originalRotation.y,
          _furniture.originalRotation.z
        );
        _furniture.rotation.y =
          _furniture.originalRotation.y + angle + Math.PI / 2;
        if (_selectionBox) _selectionBox.rotation.y = _furniture.rotation.y;

        if (_furniture.getSnaptrudeDS().snapTo === "wall") {
          if (!_furniture.getSnaptrudeDS().cutHole) {
            _furniture.position.x +=
              bbinfo.boundingBox.extendSizeWorld.x *
              Math.sin(angle + Math.PI / 2);
            _furniture.position.z +=
              bbinfo.boundingBox.extendSizeWorld.z *
              Math.cos(angle + Math.PI / 2);
          }
        }

        if (_furniture.getSnaptrudeDS().snapTo !== "none") {
          let vw = bbinfo1.boundingBox.vectorsWorld;
          let vw2 = bbinfo.boundingBox.vectorsWorld;
          let end = Object.assign({}, _furniture.position);
          //st = jQuery.extend({}, door.parent.position);//(vw[5].add(vw[2])).scale(0.5);
          let st1 = _getNearestPoint(vw, "start")[0];
          let st2 = _getNearestPoint(vw, "start")[1];
          let end1 = _getNearestPoint(vw2, "end")[0];
          let end2 = _getNearestPoint(vw2, "end")[1];

          //doing it to get this in global cope to update on keypress event
          wallst1 = st1;
          wallst2 = st2;

          if (
            BABYLON.Vector3.Distance(st1, end) <=
            BABYLON.Vector3.Distance(st2, end)
          ) {
            BABYLON.Vector3.Distance(st1, end1) <=
            BABYLON.Vector3.Distance(st1, end2)
              ? (end = end1)
              : (end = end2);
            st1.y = end.y;
            updateFurnitureOffset = BABYLON.Vector3.Distance(
              _furniture.position,
              end
            );
            DisplayOperation.drawOnMove(st1, end);
            DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st1, end));
          } else {
            BABYLON.Vector3.Distance(st2, end1) <=
            BABYLON.Vector3.Distance(st2, end2)
              ? (end = end1)
              : (end = end2);
            st2.y = end.y;
            updateFurnitureOffset = BABYLON.Vector3.Distance(
              _furniture.position,
              end
            );
            DisplayOperation.drawOnMove(st2, end);
            DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st2, end));
          }
          // _selectionBox.position.x -= _offset * Math.sin(angle + Math.PI / 2);
          // _selectionBox.position.z -= _offset * Math.cos(angle + Math.PI / 2);
          //display in y direction
          let sty1 = _getNearestPoint(vw, "start-y")[0];
          let sty2 = _getNearestPoint(vw, "start-y")[1];
          let endy1 = _getNearestPoint(vw2, "start-y")[0];
          let endy2 = _getNearestPoint(vw2, "start-y")[1];

          wallsty1 = sty1;
          wallsty2 = sty2;

          if (
            BABYLON.Vector3.Distance(sty1, end) <=
            BABYLON.Vector3.Distance(sty2, end)
          ) {
            BABYLON.Vector3.Distance(sty1, endy1) <=
            BABYLON.Vector3.Distance(sty1, endy2)
              ? (end = endy1)
              : (end = endy2);
            //console.log("wallsty1",end);

            sty1.x = end.x;
            sty1.z = end.z;
            updateFurnitureOffsetY = BABYLON.Vector3.Distance(
              end,
              _furniture.position
            );
            DisplayOperation.drawOnMove(sty1, end, "dimline2");
            DisplayOperation.displayOnMove(
              BABYLON.Vector3.Distance(sty1, end),
              "inputy"
            );
          } else {
            BABYLON.Vector3.Distance(sty2, endy1) <=
            BABYLON.Vector3.Distance(sty2, endy2)
              ? (end = endy1)
              : (end = endy2);
            //console.log("wallsty2",end);

            sty2.x = end.x;
            sty2.z = end.z;
            updateFurnitureOffsetY = BABYLON.Vector3.Distance(
              end,
              _furniture.position
            );
            DisplayOperation.drawOnMove(sty2, end, "dimline2");
            DisplayOperation.displayOnMove(
              BABYLON.Vector3.Distance(sty2, end),
              "inputy"
            );
          }
        }
      }else{
        updateCursor(cursor.cantPlace);
      }
    },

    createFurnitureSelectionBox: function (furniture, pickedMesh) {
      let bbInfo = furniture.getBoundingInfo();
      // console.log(furniture);
      furniture.freezeWorldMatrix();
      bbInfo.update(furniture._worldMatrix);
      let height = bbInfo.boundingBox.extendSizeWorld.y * 2;

      let width = bbInfo.boundingBox.extendSizeWorld.x * 2;
      let depth =
        pickedMesh._properties._components._thickness /
          (store.unit_absolute_scale * 10000) +
        0.2;
      furniture.unfreezeWorldMatrix();

      let box = BABYLON.MeshBuilder.CreateBox(
        "furnitureBoxScale",
        {
          height: height,
          width: width,
          depth: depth,
        },
        store.scene
      );
      box.enableEdgesRendering();
      box.edgesWidth = 8.0;
      box.edgesColor = new BABYLON.Color4(0, 1, 0, 1);
      box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

      let mat = store.scene.getMaterialByName("furnitureBBMat");
      if (!mat) {
        mat = new BABYLON.StandardMaterial("furnitureBBMat", store.scene);
        mat.diffuseColor = new BABYLON.Color3(0.3, 1, 0.5);
        mat.alpha = 0.2;
        mat.backFaceCulling = false;
      }
      box.material = mat;
      box.isPickable = false;

      if (furniture.children) {
        furniture.children.push(box);
      } else {
        furniture.children = [];
        furniture.children.push(box);
      }
      box.parentMesh = furniture;
      box.originalScaling = box.scaling.clone();
      return box;
    },

    _reset: function () {
      // if (isiPad) store.newScene.activeCamera.attachControl(canvas, true, false);
      let isWorking = false;
      if (_newFurnitureInScene) {
        let redundantFurniture = store.scene.getMeshByName(_furnitureMeshName);
        if (redundantFurniture) {
          removeMeshFromStructure(redundantFurniture);
          redundantFurniture.dispose();
        }
        _furniture = null;
        if (_selectionBox) _selectionBox.dispose();
        _selectionBox = null;
        // isWorking = true;
      }
      if (_furniture) {
        _furniture.scaling = new BABYLON.Vector3(0, 0, 0);
        _furniture.position = new BABYLON.Vector3(10000, 0, 10000);
        _furniture.rotation = new BABYLON.Vector3(0, 0, 0);
        if (_selectionBox) _selectionBox.dispose();
        _selectionBox = null;
        _furniture = null;
        // isWorking = true;
      }
      _furnitureMeshName = null;
      furnitureOperation.furnitureMeshName = null;
      _newFurnitureInScene = false;
      return isWorking;
    },

    updateFurniturePosition: function (inputs) {
      if (_furniture) {
        let pickInfo = store.newScene.pick(store.newScene.pointerX, store.newScene.pointerY, function (mesh) {
          return ((mesh.type.toLowerCase() === 'wall' || mesh.type.toLowerCase() === "floor") && (mesh.isVisible));
        });
        let _furniturey = _furniture.position.clone();
        let _furnitureXZ = _furniture.position.clone();
        let px = null;
        let py = null;
        let pz = null;
        let xzEdited = false;
        let yEdited = false;
        console.log(inputs);
        DisplayOperation.removeDimensions();
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].name === "distBox" && inputs[i].edited) {
            xzEdited = true;
          } else if (inputs[i].name === "inputy" && inputs[i].edited) {
            yEdited = true;
          }
          console.log("first step", 1);
        }
        for (let i = 0; i < inputs.length; i++) {
          let input = inputs[i];
          if (
            input.name === "inputy" &&
            input.edited === true &&
            xzEdited === false
          ) {
            let dist = DisplayOperation.getOriginalDimension(input.text);
            if (
              BABYLON.Vector3.Distance(wallsty1, _furniture.position) <=
              BABYLON.Vector3.Distance(wallsty2, _furniture.position)
            ) {
              let pd = wallsty1.subtract(_furniture.position).normalize();
              let p = wallsty1.subtract(
                pd.scale(dist + updateFurnitureOffsetY)
              );
              let endpt = wallsty1.subtract(pd.scale(dist));
              console.log(p, updateFurnitureOffsetY);
              _furniture.position = p;
              if (_selectionBox) {
                _selectionBox.position = p;
              }

              wallsty1.x = _furniture.position.x;
              wallsty1.z = _furniture.position.z;
              let vw = _furniture.getBoundingInfo().boundingBox.vectorsWorld;
              DisplayOperation.drawOnMove(wallsty1, endpt, "dimline2");
              DisplayOperation.displayOnMove(
                BABYLON.Vector3.Distance(wallsty1, endpt),
                "inputy"
              );
            } else {
              let pd = wallsty2.subtract(_furniture.position).normalize();
              let p = wallsty2.subtract(
                pd.scale(dist + updateFurnitureOffsetY)
              );
              let endpt = wallsty2.subtract(pd.scale(dist));
              _furniture.position = p;
              console.log(p, updateFurnitureOffsetY);
              if (_selectionBox) {
                _selectionBox.position = p;
              }

              wallsty2.x = _furniture.position.x;
              wallsty2.z = _furniture.position.z;
              //let vw = _furniture.getBoundingInfo().boundingBox.vectorsWorld;
              DisplayOperation.drawOnMove(wallsty2, endpt, "dimline2");
              DisplayOperation.displayOnMove(
                BABYLON.Vector3.Distance(wallsty2, endpt),
                "inputy"
              );
            }
            let furnitureName =
              _furniture.name + "Ins" + _furniture.instances.length;
            let _furnitureInstance = _furniture.createInstance(furnitureName);
            _furnitureInstance.type = "furniture";
            console.log("second step", 2);
            _drawFurniture(pickInfo, _furnitureInstance, _furniture);
          } else if (
            input.name === "distBox" &&
            input.edited &&
            yEdited === false
          ) {
            let dist = DisplayOperation.getOriginalDimension(input.text);

            if (
              BABYLON.Vector3.Distance(wallst1, _furniture.position) <=
              BABYLON.Vector3.Distance(wallst2, _furniture.position)
            ) {
              let pd = wallst1.subtract(_furniture.position).normalize();
              let p = wallst1.subtract(pd.scale(dist + updateFurnitureOffset));
              let endpt = wallst1.subtract(pd.scale(dist));
              console.log(p, updateFurnitureOffset);
              _furniture.position = p;
              if (_selectionBox) {
                _selectionBox.position = p;
              }

              wallst1.y = _furniture.position.y;
              let vw = _furniture.getBoundingInfo().boundingBox.vectorsWorld;
              DisplayOperation.drawOnMove(wallst1, endpt);
              DisplayOperation.displayOnMove(
                BABYLON.Vector3.Distance(wallst1, endpt)
              );
            } else {
              let pd = wallst2.subtract(_furniture.position).normalize();
              let p = wallst2.subtract(pd.scale(dist + updateFurnitureOffset));
              let endpt = wallst2.subtract(pd.scale(dist));
              console.log(p, updateFurnitureOffset);
              _furniture.position = p;
              if (_selectionBox) {
                _selectionBox.position = p;
              }
              wallst2.y = _furniture.position.y;
              //let vw = _furniture.getBoundingInfo().boundingBox.vectorsWorld;
              DisplayOperation.drawOnMove(wallst2, endpt);
              DisplayOperation.displayOnMove(
                BABYLON.Vector3.Distance(wallst2, endpt)
              );
            }
            console.log("second step", 2);
            let furnitureName =
              _furniture.name + "Ins" + _furniture.instances.length;
            let _furnitureInstance = _furniture.createInstance(furnitureName);
            _furnitureInstance.type = "furniture";
            _drawFurniture(pickInfo, _furnitureInstance, _furniture);
          } else if (
            (input.name === "distBox" && input.edited && yEdited === true) ||
            (input.name === "inputy" &&
              input.edited === true &&
              xzEdited === true)
          ) {
            if (input.name === "inputy" && input.edited === true) {
              let dist = DisplayOperation.getOriginalDimension(input.text);
              if (BABYLON.Vector3.Distance(wallsty1, _furniturey) <= BABYLON.Vector3.Distance(wallsty2, _furniturey)) {
                let pd = (wallsty1.subtract(_furniturey)).normalize();
                let p = wallsty1.subtract(
                  pd.scale(dist + updateFurnitureOffsetY)
                );
                let endpt = wallsty1.subtract(pd.scale(dist));
                // console.log("pp----------------",p, updateFurnitureOffsetY);

                py = p.y;
                // _furniture.position = p;
                // if(_selectionBox){_selectionBox.position.y =p.y; }

                wallsty1.x = p.x;
                wallsty1.z = p.z;
                // let vw = _furniturey.getBoundingInfo().boundingBox.vectorsWorld;
                DisplayOperation.drawOnMove(wallsty1, endpt, "dimline2");
                DisplayOperation.displayOnMove(
                  BABYLON.Vector3.Distance(wallsty1, endpt),
                  "inputy"
                );
              } else {
                let pd = (wallsty2.subtract(_furniturey)).normalize();
                let p = wallsty2.subtract(
                  pd.scale(dist + updateFurnitureOffsetY)
                );
                let endpt = wallsty2.subtract(pd.scale(dist));
                // _furniture.position = p;
                // console.log("pp----------------",p, updateFurnitureOffsetY);
                py = p.y;
                // if(_selectionBox){_selectionBox.position =p }

                wallsty2.x = p.x;
                wallsty2.z = p.z;
                //let vw = _furniture.getBoundingInfo().boundingBox.vectorsWorld;
                DisplayOperation.drawOnMove(wallsty2, endpt, "dimline2");
                DisplayOperation.displayOnMove(
                  BABYLON.Vector3.Distance(wallsty2, endpt),
                  "inputy"
                );
              }
            } else if (input.name === "distBox" && input.edited) {
              let dist = DisplayOperation.getOriginalDimension(input.text);

              if (BABYLON.Vector3.Distance(wallst1, _furnitureXZ) <= BABYLON.Vector3.Distance(wallst2, _furnitureXZ)) {
                let pd = (wallst1.subtract(_furnitureXZ)).normalize();
                let p = wallst1.subtract(
                  pd.scale(dist + updateFurnitureOffset)
                );
                let endpt = wallst1.subtract(pd.scale(dist));
                // console.log(p, updateFurnitureOffset);
                //  _furniture.position.x = p.x;
                //  _furniture.position.z = p.z;
                px = p.x;
                pz = p.z;
                // if(_selectionBox){
                //     _selectionBox.position.x =p.x;
                //     _selectionBox.position.z = p.z;
                // }

                wallst1.y = p.y;
                // let vw = _furnitureXZ.getBoundingInfo().boundingBox.vectorsWorld;
                DisplayOperation.drawOnMove(wallst1, endpt);
                DisplayOperation.displayOnMove(
                  BABYLON.Vector3.Distance(wallst1, endpt)
                );
              } else {
                let pd = (wallst2.subtract(_furnitureXZ)).normalize();
                let p = wallst2.subtract(
                  pd.scale(dist + updateFurnitureOffset)
                );
                let endpt = wallst2.subtract(pd.scale(dist));
                //console.log(p, updateFurnitureOffset);
                //_furniture.position = p;

                px = p.x;
                pz = p.z;
                // if(_selectionBox){
                //     _selectionBox.position = p;
                //
                // }
                wallst2.y = p.y;
                //let vw = _furnitureXZ.getBoundingInfo().boundingBox.vectorsWorld;
                DisplayOperation.drawOnMove(wallst2, endpt);
                DisplayOperation.displayOnMove(
                  BABYLON.Vector3.Distance(wallst2, endpt)
                );
              }
            }
          }
        }

        if (xzEdited && yEdited) {
          _furniture.position.x = px;
          _furniture.position.y = py;
          _furniture.position.z = pz;
          let furnitureName =
            _furniture.name + "Ins" + _furniture.instances.length;
          let _furnitureInstance = _furniture.createInstance(furnitureName);
          _furnitureInstance.type = "furniture";
          _drawFurniture(pickInfo, _furnitureInstance, _furniture);
        }
      }
    },
    loadFurnitureMesh,
    dispatchEvents,
    furnitureMeshName: _furnitureMeshName,
    setFurnitureMeshName,
    setMetadata: function () {
      _newFurnitureInScene = true;
    },
    clearEvents,
    placeDirect
  };
})();
export { furnitureOperation };
