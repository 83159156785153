/* 
This service will be used for searching library items.
- Create a list based on category ["material", "door", "furniture", "window"]
- Search any one of the category at a time.

Uses Fuse.js for storing and searching.
*/

import Fuse from "fuse.js"

const libraryService = (function () {
  const list = {
    material: null,
    door: null,
    window: null,
    furniture: null
  }
  const createList = (listName, listItems) => {
    list[listName] = new Fuse(listItems, {
      shouldSort: true,
      keys: ["fullName"]
    })
  }
  const getList = (listName) => {
    return list[listName];
  }
  const addToList = (listName, item) => {
    list[listName].add(item);
  }
  return {
    getList,
    createList,
    addToList
  }
})()

export {
  libraryService
}