import _ from "lodash";
import { makeid } from "../../libs/arrayFuncs.js";
import { verifyBRepIntegrity } from "../../libs/brepOperations.js";
import { createCustomMesh } from "../../libs/massModeling.js";
import { room_types_db } from "../../libs/obj_base.js";
import { setLayerTransperancy } from "../../libs/sceneFuncs.js";
import { assignProperties } from "../../libs/sceneStateFuncs.js";
import {
  doMassPostProcessing,
  handleIncorrectDetection,
} from "../../libs/twoD/twoDrawRooms.js";
import {
  changeOrderForHoles,
  convertArray2ToArray3,
  removecollinear,
  removeDuplicateVertices,
  removeDuplicateVerticesModified,
  removeZeroArrays,
  zeroDownZ,
} from "../../libs/twoD/twoServices.js";
import { DisplayOperation } from "../../modules/displayOperations/displayOperation.js";
import BABYLON from "../babylonDS.module.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import {
  getRoomHeight,
  isMeshThrowAway,
  onSolid,
  showToast,
  TOAST_TYPES,
} from "../extrafunc.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { Floor } from "../snaptrudeDS/floor.ds.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";
import { store } from "../utilityFunctions/Store.js";
import { createFloors } from "./floor.js";
import {
  createCurvedWalls,
  createWalls,
  createWallsWithInsert,
  filterCurvedWalls,
  getWallDataFromBaseObject,
} from "./wall.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { Roof } from "../snaptrudeDS/roof.ds.js";
import { createColumn, createStructuralColumns } from "./column.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import {
  fetchLatestCommit,
  fetchListOfObjects,
  fetchMetaData,
  fetchReferenceIdOfCommit,
  fetchSingleObject,
  fetchRevitMetaData
} from "./sampledata.js";
import { getCommandDataFromData } from "./extraFunctions.js";
import { handleGetResourceOperations } from "../../../services/resources.service.js";
import { cachingService } from "../utilityFunctions/cachingService.js";
import { createDoor } from "./door.js";
import { createWindow } from "./window.js";
import { createFurniture, createFurnitureSet } from "./furniture.js";
import { createCeilings } from "./ceillings.js";
import { createTestMesh, createTestMeshFromSpeckle } from "./testMesh.js";
import { createStoreys, getSelectedLevels } from "./storey.js";
import { createRailings } from "./railings.js";
import { createCurtainWalls } from "./curtainWall.js";
import { createRevitLinks } from "./revitLinks.js";
import { Locker } from "../locker/locker.js";
import { createStaircases } from "./staircase.js";
import speckleConstants from "./speckleConstants.js";
import {AutoSave} from "../socket/autoSave";
import {autoSaveConfig} from "../socket/autoSaveConfig";
import {flagSpeckleImportAsCompleted} from "../../../services/projects.service";
import { createDucts } from "./ducts.js";
import {firstManualSave} from "../../../services/saveMicro.service";
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { goOutOfTwoD } from "../../libs/twoDimension.js";
import { createMasses } from "./mass.js";
import { createStructuralFramings } from "./structuralFraming.js";

const speckleRevitImport = (function () {
  let revitData = {};
  let streamId = "";
  let baseObjectId = "";
  let typeData = {};
  let elementsData = {};
  let levelStoreyMapping = [];
  let stackedWallData = {
    familyNames: [],
    heights: [],
  };
  let teamMaterials = [];
  let currentProjectTeam;
  const teamParameters = {};

  const resetRevitImport = () => {
    revitData = {};
    currentProjectTeam = {};
  };

  const createSnaptrudeProject = (speckleStreamId, commitId) => {
    init(speckleStreamId, commitId).then(() => {
      getLevelStoreyMapping();
      createElements();
    })
  
  }

  const init = async (speckleStreamId, commitId, team) => {
    resetRevitImport();
    goOutOfTwoD();
    streamId = speckleStreamId;
    currentProjectTeam = team;

    if (currentProjectTeam) {
      teamParameters["furnitureMap"] = setTeamFurnitureMap();
      teamParameters["Id"] = currentProjectTeam.id;
      teamMaterials = currentProjectTeam.materials;
    }

    try {
      if (!commitId) {
        let latestCommit = await fetchLatestCommit(streamId);
        baseObjectId =
          latestCommit.data.data.stream.branch.commits.items[0]
            .referencedObject;
      } else {
        let response = await fetchReferenceIdOfCommit(streamId, commitId);
        baseObjectId = response?.data?.data?.stream?.commit?.referencedObject;
      }
      revitData = await fetchMetaData(streamId, baseObjectId);
      elementsData.levels = revitData.levels;
      elementsData.levels.sort((l1, l2) => l1.elevation - l2.elevation);
      elementsData.levels = changeLevelUnitToMM(elementsData.levels);
      // getLevelStoreyMapping();
    } catch (e) {
      console.log(e);
      showToast("Failed to fetch revit model", 3000, TOAST_TYPES.error);
      throw new Error("Failed to fetch revit model!");
    }
  };

  const getLatestCommit = async (speckleStreamId) => {
    let latestCommit = await fetchLatestCommit(speckleStreamId);
    let baseObjectId =
      latestCommit.data.data.stream.branch.commits.items[0].referencedObject;
    return baseObjectId;
  };

  const changeLevelUnitToMM = (levels = elementsData.levels) => {
    for (let i = 0; i < levels.length; ++i) {
      const levelUnit = levels[i]?.units ? levels[i]?.units : "mm";
      levels[i].elevation = speckleConstants.convertIntomm(
        levels[i].elevation,
        levelUnit
      );
      levels[i].units = "mm";
    }
    return levels;
  };

  const getLevelStoreyMapping = (levels = elementsData.levels) => {
    let { selected_levels, probable_storeys } = getSelectedLevels(levels);
    elementsData.selected_levels = selected_levels;
    elementsData.probable_storeys = probable_storeys;
    elementsData.levels = getAllLevels(selected_levels);
    // createStoreys(elementsData.levels)
    // return levelStoreyMapping
  };

  const getAllLevels = (selectedLevels) => {
    let allLevels = elementsData.levels;
    for (let level = 0; level < allLevels.length; ++level) {
      for (let s = 0; s < selectedLevels.length; ++s) {
        if (selectedLevels[s].name == allLevels[level].name) {
          allLevels[level].isSelected = true;
          break;
        }
      }
      if (!allLevels[level].isSelected) allLevels[level].isSelected = false;
    }

    return allLevels;
  };

  const getAllWallTypes = function () {
    return revitData.wallsTypeData.map((wallTypeData) => {
      return Object.keys(wallTypeData)[0];
    });
  };
  const updateAllLevelsStatus = (levels) => {
    elementsData.levels = levels;
  };

  const updateLevelStoreyMapping = () => {
    let selectedLevels = [];
    let allLevels = elementsData.levels;
    for (let level = 0; level < allLevels.length; ++level) {
      if (allLevels[level].isSelected) {
        selectedLevels.push(allLevels[level]);
      }
    }
    getLevelStoreyMapping(selectedLevels);
  };

  const getRevitData = function () {
    return revitData;
  };

  const getStackedWallsData = function () {
    return stackedWallData;
  };

  const setStackedWallsData = function (stackedWallTypesData) {
    /*
    Data format-
    
    stackedWallTypesData = [
      {
        familyName: type,
        isStacked: false,
        height: 2400,
      },
      .
      .
    ];
    
     */

    stackedWallTypesData.forEach((data) => {
      if (data.isStacked) {
        stackedWallData.familyNames.push(data.familyName);
        stackedWallData.heights.push(data.height);
      }
    });
  };

  const createElements = async () => {
    let res = revitData;
    let filteredStack,
      filteredWallInsertStack = [];
    let ceilingsMesh,
      ceilingMaterialCommands = [];
    let wallsMesh = [],
      wallMaterialCommands = [],
      revitWallMap = {};
    let floorsMesh = [],
      floorMaterialCommands = [];
    let columnsMesh,
      columnMaterialCommands = [];
    let sourceDoorData, doorsData, wallsWithDoorHoles;
    let sourceWindowData, windowsData, wallsWithWindowHoles;
    let furnituresData = [],
      sourceFurnitureData = [];
    let railingMeshes;
    let curtainWallMeshes= [] ,curtainPanelsMullionsMap = {}, wallsWithInsert =[] ;
    let curvedWallsMesh = [];
    let revitLinks = [];
    let stairs = [];
    let structuralColumnsData = [];
    let MEPComponentsData = [];

    let startTime = performance.now();

    let materialList = cachingService.get("2") ? cachingService.get("2") : [];

    // showToast("Importing Project...", 0, autoSaveConfig.CONSTANTS.toastSuccess);
    // AutoSave.loaderController.start();
    autoSaveConfig.disableToasts();

    // if (!materialList) {
    //   const options = {
    //     resourceName: "materials",
    //     floorkey: store.floorkey,
    //     filterType: "material",
    //     filterSubType: "colors",
    //   };

    //   materialList = await handleGetResourceOperations(options);
    // }
    materialList = [...teamMaterials];

    let wallTypes = res.wallsTypeData;
    let columnTypes = res.columnTypeData;
    let floorTypes = res.floorTypeData;
    let ceilingTypes = res.ceilingTypeData;
    let revitExportData = res.revitExportData;
    let familyData = res.familyData;
    let componentData = res.componentData;
    let wallSideFacesData = res.wallSideFacesData;

    createStoreys(elementsData.selected_levels);

    if (res.wallsData && res.wallsData.length) {
      let curvedWallsData = filterCurvedWalls(res.wallsData);
      let meshData = curvedWallsData.map((wall) => {
        if (wall.displayMesh) {
          return wall?.displayMesh.referencedId;
        } else if (wall?.displayValue) {
          return wall?.displayValue.map((m) => m.referencedId);
        }
      });
      meshData = [].concat(...meshData);
      meshData = await fetchListOfObjects(streamId, meshData);
      let verticesReferenceIds = [].concat(
        ...meshData?.map((mesh) => {
          return mesh.vertices.map((v) => v.referencedId);
        })
      );
      let facesReferenceIds = [].concat(
        ...meshData?.map((mesh) => {
          return mesh.faces.map((f) => f.referencedId);
        })
      );
      let wallVertexData = await fetchListOfObjects(
        streamId,
        verticesReferenceIds
      );
      let wallFaceData = await fetchListOfObjects(streamId, facesReferenceIds);
      try {
        curvedWallsMesh = createCurvedWalls(
          curvedWallsData,
          meshData,
          wallVertexData,
          wallFaceData
        );
      } catch (e) {
        console.log(e);
      }
    }
    // create walls
    try {
      ({ wallsMesh, revitWallMap } = createWalls(
        res.wallsData,
        wallTypes,
        materialList,
        wallSideFacesData
      ));
    } catch (error) {
      console.log(error);
      console.warn("Couldn't create walls");
    }

    console.log("After creating walls");

    let curtainWalls = res.newCurtainWallData;
    if (curtainWalls && Object.keys(curtainWalls).length > 0) {
      try {
        ({ curtainWallMeshes, curtainPanelsMullionsMap } = createCurtainWalls(
          curtainWalls,
          materialList
        ));
      } catch (e) {
        console.log(e);
      }

      console.log("After creating curtainWalls");
    }

    let curtainWallInserts = res.newCurtainWallData["CurtainWallInsertMap"];
    if (curtainWallInserts && Object.keys(curtainWallInserts).length > 0) {
      try {
        ({ wallsWithInsert } = createWallsWithInsert(
          curtainWallInserts,
          revitWallMap,
          curtainPanelsMullionsMap
        ));
      } catch (e) {
        console.log(e);
      }
    }

    // create floors
    let floorGeometryData = { ...familyData["Floors"] };
    try {
      ({ floorsMesh } = createFloors(
        res.floorsData,
        floorTypes,
        floorGeometryData,
        materialList
      ));
    } catch (e) {
      console.log(e);
    }
    console.log("After creating floors");

    if ( res.ceilingsData && Object.keys( res.ceilingsData).length > 0 ) {
      try {
        ({ ceilingsMesh, ceilingMaterialCommands } = createCeilings(
          res.ceilingsData,
          ceilingTypes,
          materialList
        ));
      } catch (e) {
        console.log(e);
      }
    }

    //create columns
    let columns = res.columnsData;
    let columnsGeometryData  = res?.columnsGeometryData
    let columMeshData = [];
    if (columns && columns.length > 0) {
      try {
        ({ columnsMesh } = createColumn(
          columns,
          columnTypes,
          columnsGeometryData,
          materialList
        ));
      } catch (error) {
        console.log(error);
      }
    }

    // create structural columns
    let structuralColumns = revitExportData["Structural Columns"];
    if (structuralColumns && Object.keys(structuralColumns).length > 0) {
      try {
        ({ structuralColumnsData } =
          createStructuralColumns(structuralColumns));
      } catch (e) {
        console.log(e);
      }
    }


    // create doors

    let doors = componentData?.Doors;
    const allDoorsData = revitExportData?.Doors;
    let doorsWallMap = res.wallMapData?.door
      ? JSON.parse(res.wallMapData?.door?.join())
      : {};
    let doorMaterials = familyData?.Doors;
    if (doors && Object.keys(doors).length > 0) {
      let doorTypeData = JSON.parse(res.doorTypeData.join(""));

      ({ sourceDoorData, doorsData, wallsWithDoorHoles } = createDoor(
        doors,
        allDoorsData,
        doorMaterials,
        doorTypeData,
        revitWallMap,
        doorsWallMap,
        materialList
      ));
    }

    // //***********Create windows**************/
    let windows = componentData?.Windows;
    const allWindowsData = revitExportData?.Windows;
    let windowsWallMap = res.wallMapData?.window
      ? JSON.parse(res.wallMapData?.window?.join(""))
      : {};
    let windowMaterials = familyData?.Windows;

    if (windows && Object.keys(windows).length > 0) {
      let windowTypeData = JSON.parse(res.windowTypeData.join());
      ({ sourceWindowData, windowsData, wallsWithWindowHoles } = createWindow(
        windows,
        allWindowsData,
        windowMaterials,
        windowTypeData,
        revitWallMap,
        windowsWallMap,
        materialList
      ));
      console.log("After creating windows");
    }

    // //***********Create furnitures**************/

    let typeSupported = [
      "Lighting Fixtures",
      "Communication Devices",
      "Casework",
      "Furniture Systems",
      "Planting",
      "Security Devices",
      "Electrical Fixtures",
      "Furniture",
      "Fire Alarm Devices",
      "Data Devices",
      "Lighting Devices",
      "Specialty Equipment",
      "Generic Models",
      "Assemblies",
      "Model Groups",
      "Mechanical Equipment",
      "Plumbing Fixtures",
      "Electrical Equipment",
      "Sprinklers"
    ];

    let furnitures = {};
    typeSupported.forEach((type) => {
      furnitures = { ...furnitures, ...componentData[type] };
    });

    let allFurnituresData = {};
    typeSupported.forEach((type) => {
      allFurnituresData = { ...allFurnituresData, ...revitExportData[type] };
    });

    let furnitureMaterials = {};
    typeSupported.forEach((type) => {
      furnitureMaterials = { ...furnitureMaterials, ...familyData[type] };
    });

    try {
      ({ furnituresData, sourceFurnitureData } = createFurniture(
        furnitures,
        allFurnituresData,
        furnitureMaterials,
        materialList,
        { outermostFamily: true }
      ));
    } catch (error) {
      console.log(error);
    }

    console.log("After creating furnitures");

    let railings = res.railingsData;
    if (railings && Object.keys(railings).length > 0) {
          try {
            ({ railingMeshes } = createRailings(
              railings,
              materialList
            ));
          } catch (error) {
            console.log(error);
          }
          console.log("After creating railings");
        }
    

    let MEPComponents = res.MEPData;
    if (MEPComponents && Object.keys(MEPComponents).length > 0) {
      try {
        MEPComponentsData = createDucts(MEPComponents, materialList);
      } catch (e) {
        console.log(e);
      }

      console.log("After creating ducts");
    }

    let staircasesData = res.stairsData;
    if (staircasesData && Object.keys(staircasesData).length > 0) {
      try {
        stairs = createStaircases(staircasesData, materialList);
      } catch (e) {
        console.log(e);
      }

      console.log("After creating Staircase");
    }

    let structuralFramingData = res.structuralFramingData;
    if (structuralFramingData && Object.keys(structuralFramingData).length > 0) {
      try {
        createStructuralFramings(structuralFramingData, materialList);
      } catch (e) {
        console.log(e);
      }

      console.log("After creating Structural Framing elements");
    }

    let massData = res.massData;
    if (massData && Object.keys(massData).length > 0) {
      try {
        createMasses(massData, materialList);
      } catch (e) {
        console.log(e);
      }

      console.log("After creating masses");
    }

    // Create Revit Links
    const revitLinksData = res.revitExportData["RVT Links"];
    try {
      ({ revitLinks } = createRevitLinks(revitLinksData, materialList));
      Locker.LockMeshes(revitLinks, false);
    } catch (e) {
      console.log(e);
    }

    firstManualSave(true).then((msg = {}) => {
      if (msg.status === "success") flagSpeckleImportAsCompleted(store.floorkey);
    });
    
    if(getTeamId()){
    let furnitureInstanceCreationCommand = getCommandDataFromData(
      furnituresData,
      "FurnitureInstancesCreation"
    );
    if(furnitureInstanceCreationCommand){
      let saveData  = furnitureInstanceCreationCommand.getSaveData()
      AutoSave.addChangeLog(saveData, "revitImport");
    }
    }
    autoSaveConfig.enableToasts();
  };

  const setTeamFurnitureMap = () => {
    let furnitures = currentProjectTeam?.objects?.furnitures;
    const furnitureMap = [];

    for (let i = 0; i < furnitures.length; i++) {
      const furniture = furnitures[i];
      const name = furniture?.displayName;
      const elementId = furniture?.id;
      const cost = furniture?.cost;
      const family = furniture?.familyName
      if (elementId && name) {
        furnitureMap.push({ elementId, cost, family, name});
      }
    }

    return furnitureMap;
  };

  const getFurnitureMap = () => {
    return teamParameters?.furnitureMap;
  };

  const getTeamId = () => {
    return teamParameters?.Id;
  };

  return {
    init,
    getLevelStoreyMapping,
    updateLevelStoreyMapping,
    createElements,
    elementsData,
    updateAllLevelsStatus,
    getLatestCommit,
    getAllWallTypes,
    setStackedWallsData,
    getStackedWallsData,
    getRevitData,
    getFurnitureMap,
    getTeamId,
    createSnaptrudeProject
  };
})();

const getDimensionInSnaptrudeUnits = (parameter, units) => {
  for (let i = 0; i < parameter.length; ++i) {
    parameter[i][0] = DisplayOperation.getOriginalDimension(
      parameter[i][0],
      units
    );
    parameter[i][1] = DisplayOperation.getOriginalDimension(
      parameter[i][1],
      units
    );
    parameter[i][2] = DisplayOperation.getOriginalDimension(
      parameter[i][2],
      units
    );
  }
  return parameter;
};

const setTeamFurnitureMap = (furnitures) => {
  const furnitureMap = [];

  for (let i = 0; i < furnitures.length; i++) {
    const furniture = furnitures[i];
    const name = furniture?.displayName;
    const elementId = furniture?.id;
    const cost = furniture?.cost;
    const family = furniture?.familyName
    if (elementId && name) {
      furnitureMap.push({ elementId, cost, family, name });
    }
  }

  return furnitureMap;
};

const getFurnitureFromFurnitureMap = (furnitureMap = [], type, family) =>{
  if(family){
    return furnitureMap?.find(o => o.name === type && o.family === family)
  }else{
    return furnitureMap?.find(o => o.name == type)
  }
}

const handleLibraryFurnitureImportedAfterRevitImport = async () =>{
  const {teams, projectMetadata} = reduxStore.getState();
  if(!projectMetadata.isTeamsProject) return;

  let libraryAddedFurniture = []

  const furnitures = store.exposed.structureCollection.getInstance().getStructures()[store.activeLayer.structure_id].getAllComponents().filter(mesh => mesh.type == "Furniture")
  let currentProjectTeam = teams?.teamlist?.find(
    (team) => team.id === projectMetadata.team.id
  );

  let libraryObjects = currentProjectTeam.objects.furnitures;
  const furnitureMap = setTeamFurnitureMap(libraryObjects);

  for(let idx = 0; idx < furnitures.length; ++idx){
    let isAdded = false;
    if(!isMeshThrowAway(furnitures[idx].mesh) && furnitures[idx].breed == "team" && furnitures[idx]?.teamId && furnitures[idx]?.revitMetaData?.type){
      const furnitureDS = furnitures[idx]

      if(furnitureDS.revitMetaData?.teamObjectInfo) continue;

      const type = furnitureDS?.revitMetaData?.type.replace(".", "");
      const family = furnitureDS?.revitMetaData?.family;
      const mappedElement = getFurnitureFromFurnitureMap(furnitureMap, type, family)
      if(mappedElement && mappedElement.cost ){
        if(!isAdded){
          furnitureDS.beforeRevitMetaData = JSON.parse(JSON.stringify( furnitures[idx].revitMetaData));
        }
        isAdded = true;
        furnitures[idx].revitMetaData.teamObjectInfo = {
          elementId: mappedElement.elementId,
          cost: mappedElement.cost,
          family: furnitureDS?.revitMetaData?.family,
          category: furnitureDS?.revitMetaData?.category
        }
      }else{
        for(let id = 0; id < furnitures[idx]?.revitMetaData?.elements.length; ++id){
          const element = furnitures[idx]?.revitMetaData?.elements[id];
          if(element.elementId) continue;
          const type = element.type.replace(".", "");
          const family = element?.family;
          const mappedElement = getFurnitureFromFurnitureMap(furnitureMap,type, family )
          if (mappedElement && mappedElement.cost ) {
            if(!isAdded){
              furnitureDS.beforeRevitMetaData =JSON.parse(JSON.stringify( furnitures[idx].revitMetaData));
            }
            isAdded = true;
            furnitures[idx].revitMetaData.elements[id].elementId = mappedElement.elementId;
            furnitures[idx].revitMetaData.elements[id].cost = mappedElement.cost;
          }
        }
      }
      
    if(isAdded){
      libraryAddedFurniture.push(furnitureDS.mesh)
    }
    }
  }
  // console.log(libraryAddedFurniture);
  let libraryAddedFurnitureCommand = getCommandDataFromData(
    libraryAddedFurniture,
    "CalculateCostOfFurniture"
  );

  if(libraryAddedFurnitureCommand){
    let saveData  = libraryAddedFurnitureCommand.getSaveData();
    let revitMetaDataChangeData = commandUtils.changeRevitDataOperation.getCommandData(libraryAddedFurniture);
    let revitMetaDataChangeCommand = commandUtils.changeRevitDataOperation.getCommand(revitMetaDataChangeData);
    CommandManager.execute(revitMetaDataChangeCommand, false);
    AutoSave.addChangeLog(saveData, "priceChange");
  }
}

export {
  getDimensionInSnaptrudeUnits,
  speckleRevitImport,
  handleLibraryFurnitureImportedAfterRevitImport,
  getFurnitureFromFurnitureMap
};
