import React, {useState} from "react";
import MenuPopUp from "../../../../components/MenuPopUp/index";
import TileWithBottomStats from "../../../../components/rightMenus/tileWithBottomStats";
import _ from "lodash";
import {formatSubType} from "../../../../services/projects.service";

const StaircasePopUps = (props) => {
  const { closePopUp, resetSelectedTopSubMenu, style, isOpen } = props;
  const leftMenuItems = [
    { title: "Straight" },
    { title: "Dog - legged" },
    { title: "L-shaped" },
    { title: "Square" },
  ];
  
  leftMenuItems.forEach(item => {
    item.key = formatSubType(item.title);
  });
  
  const topSubMenuOptions = [];
  
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [thumbnailImageItems, setThumbnailImageItems] = useState([]);
  const [importedObjects, setImportedObjects] = useState([]);

  
  return (
    <MenuPopUp
      disableAdd={true}
      leftMenuItems={leftMenuItems}
      selectedIndex={selectedMenuIndex}
      setSelectedIndex={setSelectedMenuIndex}
      hasTopIcons={false}
      topSubMenuOptions={topSubMenuOptions}
      hasleftMenu={true}
      title={"Staircase"}
      isOpen={isOpen}
      style={style}
      child={() => {
        return (
          <TileWithBottomStats
            statsArray={[
              { title: "Width", value: 1500 },
              { title: "Landing", value: 1500 },
              { title: "Riser", value: 2100 },
              { title: "Tread", value: 2100 },
              { title: "Steps", value: 22 },
            ]}
            filterSubType={leftMenuItems[selectedMenuIndex].key}
            filterType="staircase"
            thumbnailImageItems={thumbnailImageItems}
            setThumbnailImageItems={setThumbnailImageItems}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            isOpen={isOpen}
            importedObjects={importedObjects}
          />
        );
      }}
      noBorder={true}
      closePopUp={closePopUp}
      selectedSubMenu={selectedSubMenu}
      setSelectedSubMenu={setSelectedSubMenu}
      thumbnailImageItems={thumbnailImageItems}
      setThumbnailImageItems={setThumbnailImageItems}
      setImportedObjects={setImportedObjects}
    />
  );
};

export default StaircasePopUps;
