"use strict";
import _ from "lodash";
import { GLOBAL_CONSTANTS } from "./globalConstants";
import { store } from "./Store";
import { isTwoDimension } from "../../libs/twoDimension";
import { isThrowAwayIdentifier, isMeshThrowAway } from "../extrafunc";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { cachingService } from "./cachingService";
import { isNeighborhoodBuilding } from "../geo/terrainNeighborhood";
import { isMeshAVoid, isMeshDWIndicator } from "../meshoperations/moveOperations/moveUtil";
import BABYLON from "../babylonDS.module";

const scenePickController = (function () {
  const pickableWhenVisibleMeshes = [];
  const pickableWhenInvisibleMeshes = [];
  const neighborhoodBuildingMeshes = [];
  const doorWindowIndicatorMeshes = [];
  const lockedMeshes = [];
  
  let _isPickSecondary = false;
  
  const typesToIgnore = [
    GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned,
    GLOBAL_CONSTANTS.strings.identifiers.visualElement,
  ];

  const add = function (mesh, isCoreSnaptrudeComponent = false) {
    if (isThrowAwayIdentifier(mesh.type)) return;
    
    if (isNeighborhoodBuilding(mesh)) {
      neighborhoodBuildingMeshes.push(mesh);
    }
    else if (isMeshDWIndicator(mesh)){
      doorWindowIndicatorMeshes.push(mesh);
    }
    else if (_isVisible(mesh) || isCoreSnaptrudeComponent){
      pickableWhenVisibleMeshes.push(mesh);
    }
    else pickableWhenInvisibleMeshes.push(mesh);
  };

  /*
    Currently remove works with the meshDisposed observable. So it comes here for every mesh dispose
    Have to see if I should make it manual
     */
  const remove = function (mesh) {
    if (typesToIgnore.includes(mesh.type)) return;

    let removed = [];
    
    if (_.isEmpty(removed)) removed = _.remove(pickableWhenVisibleMeshes, mesh);
    if (_.isEmpty(removed)) removed = _.remove(pickableWhenInvisibleMeshes, mesh);
    if (_.isEmpty(removed)) removed = _.remove(neighborhoodBuildingMeshes, mesh);
    if (_.isEmpty(removed)) removed = _.remove(doorWindowIndicatorMeshes, mesh);
  };

  const _isVisible = function (mesh) {
    return mesh.isVisible && mesh.visibility !== 0 && !mesh.isDisposed();
  };

  const _getInvisibleList = function () {
    return pickableWhenInvisibleMeshes;
  };

  const getVisibleList = function () {
    return pickableWhenVisibleMeshes;
  };

  const _getCurrentlyVisibleList = function () {
    return pickableWhenVisibleMeshes.filter(_isVisible);
  };

  const _getCurrentList = function () {
    return _.concat(_getCurrentlyVisibleList(), _getInvisibleList());
  };

  const _returnTrue = function () {
    return true;
  };

  const _lowerStoreyCheck = function (mesh) {
    // storey check happens only in 2D for meshes that have an assigned storey
    // otherwise, they will be considered for picking
    return store.$scope.isTwoDimension
      ? mesh.storey
        ? mesh.storey === store.activeLayer.storey
        : true
      : true;
  };
  
  const _getCurrentlyEligibleMeshes = function (predicate, options){
    
    let currentlyEligibleMeshes;
    if (options.pickDWIndicators){
      currentlyEligibleMeshes = doorWindowIndicatorMeshes.filter(_isVisible);
    }
    else {
      currentlyEligibleMeshes = options.pickVisibleAndInvisibleMeshes
        ? [..._getInvisibleList(), ..._getCurrentlyVisibleList()]
        : options.pickAllFromVisibleList
          ? getVisibleList()
          : options.pickInvisibleMeshes
            ? _getInvisibleList()
            : _getCurrentlyVisibleList();
  
      if (options.pickNeighborhoodMeshes)
        currentlyEligibleMeshes.push(...neighborhoodBuildingMeshes.filter(_isVisible));

      if(options.pickLockedMeshes){
        currentlyEligibleMeshes.push(...lockedMeshes.filter(_isVisible));
      }
    }
    
    if (!options.includeVoids){
      currentlyEligibleMeshes = currentlyEligibleMeshes.filter(mesh => !isMeshAVoid(mesh));
    }
    
    if (!options.includeFloorPlans){
      currentlyEligibleMeshes = currentlyEligibleMeshes.filter(mesh => {
        return mesh.type !== GLOBAL_CONSTANTS.strings.identifiers.floorPlan &&
          mesh.type !== GLOBAL_CONSTANTS.strings.identifiers.pdf;
      });
      
      // wherever floorplans need to be included for picking, PDFs should also be
      // so not adding a different condition for that
    }
   
    if (predicate)
      currentlyEligibleMeshes = currentlyEligibleMeshes.filter(predicate);
    
    return currentlyEligibleMeshes;
  };
  
  const pickWithBoundingInfo = function (predicate, options = {}) {
    options.pickWithBoundingInfo = true;
    return pick(predicate, options);
  }

  const pick = function (predicate, options = {}) {
    const ignoreLowerStorey = options.ignoreLowerStorey ?? true;

    let currentlyEligibleMeshes = _getCurrentlyEligibleMeshes(predicate, options);
    
    if (!options.pickInvisibleMeshes && ignoreLowerStorey)
      currentlyEligibleMeshes =
        currentlyEligibleMeshes.filter(_lowerStoreyCheck);

    cachingService.set(cachingService.SERVICES.pick, currentlyEligibleMeshes);
    
    let pickWithBoundingInfo = options.pickWithBoundingInfo;
    if (options.pickDWIndicators) pickWithBoundingInfo = true;

    let pickInfo;
    
    if (pickWithBoundingInfo){
      pickInfo = store.scene.pickWithBoundingInfo(
        store.scene.pointerX,
        store.scene.pointerY,
        _returnTrue
      );
    }
    else if (options.pickWithRay){
      const ray = new BABYLON.Ray(options.rayOrigin, options.rayDirection);
      if (options.useMultiPick){
        pickInfo = store.scene.multiPickWithRay(
          ray,
          _returnTrue
        );
      }
      else {
        pickInfo = store.scene.pickWithRay(
          ray,
          _returnTrue,
          options.fastCheck // returns the first hit instead of looking for the "closest" hit
        );
      }
    }
    else {
      pickInfo = store.scene.pick(
        store.scene.pointerX,
        store.scene.pointerY,
        _returnTrue,
        options.fastCheck // returns the first hit instead of looking for the "closest" hit
      );
    }
    
    invalidateCache();

    return pickInfo;
  };

  const pickInvisibleMeshes = function (predicate) {
    return pick(predicate, {
      pickInvisibleMeshes: true,
    });
  };

  const compoundPick = function (predicate, options = {}) {
    const ignoreLowerStorey = options.ignoreLowerStorey ?? true;

    let currentlyEligibleMeshes = _getCurrentlyEligibleMeshes(predicate, options);
    
    if (ignoreLowerStorey)
      currentlyEligibleMeshes =
        currentlyEligibleMeshes.filter(_lowerStoreyCheck);

    if (options.useMultiPick) {
      const meshesCurrentlyInFOV =
        store.scene.activeCamera.getActiveMeshes().data;
      
      if (!_.isEmpty(meshesCurrentlyInFOV)) {
        // fov result is a little inconsistent
        currentlyEligibleMeshes = _.intersection(
          currentlyEligibleMeshes,
          meshesCurrentlyInFOV
        );
      }
    }

    cachingService.set(cachingService.SERVICES.pick, currentlyEligibleMeshes);

    const pickInfo = store.scene.compoundPick(
      _returnTrue,
      options.useMultiPick,
      options.referencePoint
    );
    invalidateCache();

    return pickInfo;
  };

  const multiPick = function (predicate, options = {}) {
    options.useMultiPick = true;
    return compoundPick(predicate, options);
  };

  const sequentialPick = function (requests, options = {}) {
    const pickInfos = [];

    requests.some((request) => {
      const predicate = request.predicate;
      const doSimplePick =
        options.doSimplePick ||
        request.doSimplePick ||
        request.pickInvisibleMeshes ||
        request.pickWithBoundingInfo ||
        request.pickDWIndicators;
  
      let pickInfo;
      if (doSimplePick) {
        pickInfo = pick(predicate, {
          ...options,
          ...request,
        });
      }
      else {
        pickInfo = compoundPick(predicate, {
          ...options,
          ...request,
        });
      }

      pickInfos.push(pickInfo);

      if (pickInfo.hit && !options.doAllOfThem) return true;
    });

    return options.doAllOfThem ? pickInfos : _.last(pickInfos);
  };
  

  const invalidateCache = function () {
    cachingService.invalidate(cachingService.SERVICES.pick);
  };

  const getPickEligibleMeshes = function (scene) {
    const cachedValue = cachingService.get(cachingService.SERVICES.pick);

    if (cachedValue) return cachedValue;
    else {
      if (scene === store.scene) return _getCurrentList();
      return scene.meshes;
    }

    // this change was made to support onDrag observables. More details in setScaleOperation.js
  };

  const getCurrentlyVisibleMeshes = function (predicate) {
    let currentlyVisibleMeshes = _getCurrentlyVisibleList();
    if (predicate)
      currentlyVisibleMeshes = currentlyVisibleMeshes.filter(predicate);

    return currentlyVisibleMeshes;
  };

  const validate = function () {
    const allMeshesInStructure = StructureCollection.getInstance()
      .getStructures()
      [store.activeLayer.structure_id].getAllMeshes()
      .filter((m) => !isMeshThrowAway(m));

    const meshesNotAccountedFor = _.difference(
      allMeshesInStructure,
      pickableWhenVisibleMeshes
    );
    if (!_.isEmpty(meshesNotAccountedFor)) {
      console.warn("Following meshes won't be picked");
      console.warn(meshesNotAccountedFor);
    }
  };
  
  const markPickAsSecondary = function (){
    _isPickSecondary = true;
  };
  
  const markPickAsPrimary = function (){
    _isPickSecondary = false;
  };
  
  const isPickSecondary = function (){
    return _isPickSecondary;
  };

  const flush = function () {
    pickableWhenVisibleMeshes.length = 0;
    pickableWhenInvisibleMeshes.length = 0;
  };

  const addLockedMesh = mesh => {
    const existingMeshIndex = pickableWhenVisibleMeshes.indexOf(mesh);
    if(existingMeshIndex === -1) return false;
    pickableWhenVisibleMeshes.splice(existingMeshIndex, 1);
    lockedMeshes.push(mesh);
    return true;
  };

  const removeLockedMesh = mesh => {
    const existingMeshIndex = lockedMeshes.indexOf(mesh);
    if(existingMeshIndex === -1) return false;
    lockedMeshes.splice(existingMeshIndex, 1);
    pickableWhenVisibleMeshes.push(mesh);
    return true;
  };

  return {
    add,
    remove,
    validate,
    flush,

    getCurrentlyVisibleMeshes,
    getPickEligibleMeshes,
    getVisibleList,
    markPickAsPrimary,
    markPickAsSecondary,
    isPickSecondary,
    
    pick,
    pickWithBoundingInfo,
    pickInvisibleMeshes,
    compoundPick,
    sequentialPick,
    multiPick,

    addLockedMesh,
    removeLockedMesh,
  };
})();

export { scenePickController };
