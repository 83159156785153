import alternateSnaps from "./alternateSnaps";
import cadSnaps from "./cadSnaps";
import snapVerifier from "./snapVerifier";

const snapEngine = {
  alternateSnaps,
  cadSnaps,
  snapVerifier,
};

export default snapEngine;