"use strict";
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { AutoSave } from "../socket/autoSave.js";
import { RotateOperation } from "../meshoperations/rotateOperation.js";
import { ScaleOperation } from "../meshoperations/scaleOperation.js";
import { layerView } from "../../libs/layerView.js";
import {
  showToast,
  updateCSG,
  extrafunc,
  changeHeightOfObjects,
  markMeshAsThrowAway,
  removeMeshFromStructure,
} from "../extrafunc.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { Command } from "../commandManager/Command.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import {
  removeMeshSelectionChildren,
  drawSelectionBox,
} from "../../libs/meshEvents.js";
import {
  updateRoofAccordion,
} from "../../libs/roofVisibilityFuncs.js";
import { MeshGroups } from "../constants.module.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { pasteObject } from "../../libs/defaultEvents.js";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants.js";
import { Roof } from "../snaptrudeDS/roof.ds.js";
import { meshObjectMapping } from "../snaptrudeDS/mapping.js";
import { makeid } from "../../libs/arrayFuncs.js";
import { appendStorey, toggleHideOrShowStorey, updateStorey } from '../../../snaptrude/stateManagers/reducers/objectProperties/storeysSlice';
import reduxStore from "../../stateManagers/store/reduxStore.js";
import objectPropertiesView from "../../modules/objectProperties/objectPropertiesView.js"
import {is2D} from "../../libs/twoDimension";
import { setLayerTransperancy } from "../../libs/sceneFuncs";

var StoreyMutation = (function () {
  // Define constants
  let _CONSTANTS = {
    show: 1,
    hide: 0,
    storeyUp: 1,
    storeyDown: 0,
    balconyHeight: 8.2677,
    slabHeight: 0.5905511811023622,
    duplicateStoreysCommand: "duplicateStoreys",
    dLLMappingCommand: "dLLMapping",
    hideOrShowStoreyCommand: "hideOrShowStorey",
  };

  let storeyUnique = false;

  function getStructure(id) {
    return StructureCollection.getInstance().getStructures()[id];
  }

  function getStoreyCollection(structureId) {
    return StructureCollection.getInstance()
      .getStructures()
      [structureId].getStoreyData();
  }
  /**
   * Set the storey value of element given new storey value
   * @param element
   * @param newStoreyVal
   * @private
   */
  function _setElementStorey(element, newStoreyVal) {
    element.storey = newStoreyVal;
    element.mesh.storey = newStoreyVal;
  }

  function isOfBalconyHeightOrLess(mesh) {
    let boundingBox = mesh.getBoundingInfo().boundingBox;
    return boundingBox.extendSizeWorld.y * 2 <= _CONSTANTS.balconyHeight;
  }

  function _setNewHeightUI() {
    let data = this.data;
    let storeyCollection = getStoreyCollection(data.structureId);
    let storey = storeyCollection.getStoreyByValue(data.storey);
    storey.setHeight(data.newHeight);

    if (data.storey > 0) {
      let storeysLength = storeyCollection.getPositiveStoreysLength();
      for (let i = parseInt(storey.value) + 1; i < storeysLength; i++) {
        let nextStorey = storeyCollection.getStoreyByValue(i);
        let prevStorey = storeyCollection.getStoreyByValue(i - 1);

        if (prevStorey)
          nextStorey.setBase(prevStorey.getBase() + prevStorey.getHeight());
      }
    } else {
      let storeysLength = storeyCollection.getNegativeStoreysLength();
      for (let i = parseInt(storey.value) - 1; i > -storeysLength; i--) {
        let nextStorey = storeyCollection.getStoreyByValue(i);
        let prevStorey = storeyCollection.getStoreyByValue(i + 1);

        if (prevStorey)
          nextStorey.setBase(prevStorey.getBase() - prevStorey.getHeight());
      }
    }

    // ScopeUtils.changeStoreyHeight(data.storey, data.newHeight);
    reduxStore.dispatch(
      updateStorey({
        storeyValue: data.storey,
        property: 'height',
        value: DisplayOperation.convertToDefaultDimension(data.newHeight)
      })
    );
  }

  function _setPrevHeightUI() {
    let data = this.data;
    let storeyCollection = getStoreyCollection(data.structureId);
    let storey = storeyCollection.getStoreyByValue(data.storey);
    storey.setHeight(data.prevHeight);

    if (data.storey > 0) {
      let storeysLength = storeyCollection.getPositiveStoreysLength();
      for (let i = parseInt(storey.value) + 1; i < storeysLength; i++) {
        let nextStorey = storeyCollection.getStoreyByValue(i);
        let prevStorey = storeyCollection.getStoreyByValue(i - 1);

        if (prevStorey)
          nextStorey.setBase(prevStorey.getBase() + prevStorey.getHeight());
      }
    } else {
      let storeysLength = storeyCollection.getNegativeStoreysLength();
      for (let i = parseInt(storey.value) - 1; i > -storeysLength; i--) {
        let nextStorey = storeyCollection.getStoreyByValue(i);
        let prevStorey = storeyCollection.getStoreyByValue(i + 1);

        if (prevStorey)
          nextStorey.setBase(prevStorey.getBase() - prevStorey.getHeight());
      }
    }
    // ScopeUtils.changeStoreyHeight(data.storey, data.prevHeight);
    reduxStore.dispatch(
      updateStorey({
        storeyValue: data.storey,
        property: 'height',
        value: DisplayOperation.convertToDefaultDimension(data.prevHeight)
      })
    );
  }

  let _getUpdateHeightUICommandLogic = function () {
    return {
      execute: _setNewHeightUI,
      unexecute: _setPrevHeightUI,
    };
  };

  function _invertHideOrShowStorey() {
    let data = this.data;
    if (data.visibility === _CONSTANTS.show) {
      StoreyMutation.showOrHideStorey(
        data.structure_id,
        data.storey,
        _CONSTANTS.hide
      );
      ScopeUtils.toggleHideOrShowStorey(data.storey, _CONSTANTS.hide);
      this.data.visibility = _CONSTANTS.hide;
    } else {
      StoreyMutation.showOrHideStorey(
        data.structure_id,
        data.storey,
        _CONSTANTS.show
      );
      ScopeUtils.toggleHideOrShowStorey(data.storey, _CONSTANTS.show);
      this.data.visibility = _CONSTANTS.show;
    }
  }

  let _getHideOrShowStoreyCommandLogic = function () {
    return {
      execute: _invertHideOrShowStorey,
      unexecute: _invertHideOrShowStorey,
    };
  };

  function _invertHideOrShowLayer() {
    let data = this.data;
    let layer = getStoreyCollection(data.structure_id)
      .getStoreyByValue(data.storey)
      .layerData.getLayerByName(data.layerName, data.storey);
    if (layer) {
      if (data.visibility === false) {
        layer.hide();
        ScopeUtils.toggleHideOrShowLayer(data.storey, data.layerName);
        store.$scope.layerUIToggles[layer.id] = true;
        this.data.visibility = true;
      } else {
        layer.show();
        ScopeUtils.toggleHideOrShowLayer(data.storey, data.layerName);
        store.$scope.layerUIToggles[layer.id] = false;
        this.data.visibility = false;
      }
    }
  }

  let _getHideOrShowLayerCommandLogic = function () {
    return {
      execute: _invertHideOrShowLayer,
      unexecute: _invertHideOrShowLayer,
    };
  };

  let hideOrShowLayerGetSaveData = function () {
    let data = this.data;
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = this.id;
    saveData.data.identifier = {
      structure_id: store.activeLayer.structure_id,
      storey: data.storeyValue,
      layerName: data.layerName,
      layerId: data.layerId,
      floorkey: store.floorkey,
    };

    if (this.data.visibility) {
      saveData.data.saveType = "hideLayer";
    } else {
      saveData.data.saveType = "showLayer";
    }

    return saveData;
  };

  function resetParentRelatedOperations() {
    RotateOperation._reset();
    ScaleOperation._reset();
  }
  /**
   * Initialize storeys on new project
   * @param structure_id
   */
  function init(structure_id) {
    const storeyCollection = StructureCollection.getInstance()
      .getStructures()
      [structure_id].getStoreyData();
    let storey = storeyCollection.addStorey(structure_id, 1);
    store.activeLayer = storey.layerData.getLayerByName("wall", storey.value);
    layerView.selectLayer("wall", structure_id, storey.value);
    // const payload = {
    //   items: [{
    //     id: storey.id,
    //     value: storey.value,
    //     name: storey.name,
    //     height: DisplayOperation.convertToDefaultDimension(storey.height),
    //     hidden: storey.hidden,
    //     layers: []
    //   }],
    // };
    // reduxStore.dispatch(appendStorey(payload));
  }

  /**
   * Create new blank storey
   */
  function createNewBlankStorey(structure_id, isAbove) {
    const storeyCollection = StructureCollection.getInstance()
      .getStructures()
      [structure_id].getStoreyData();

    if (isAbove) {
      let newStoreyVal = storeyCollection.getPositiveStoreysLength();
      return storeyCollection.addStorey(structure_id, newStoreyVal);
    } else {
      let newStoreyVal = storeyCollection.getNegativeStoreysLength();
      return storeyCollection.addStorey(structure_id, -(newStoreyVal + 1));
    }
  }

  /**
   * Change storey height based on user input
   * @param structure_id
   * @param storey
   * @param newHeight
   */
  async function updateHeight(structure_id, storey, newHeight) {
    // execute/unexecute callbacks to handle children for height change
    function priorHeightChange() {
      this.data.forEach(function (dataPoint) {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (mesh && dataPoint.scaleChange.y !== 0) {
          if (mesh.childrenComp) {
            mesh.childrenComp.forEach(function (child) {
              child.setParent(null);
            });
          }
        }
      });
    }

    function postHeightChange() {
      this.data.forEach(function (dataPoint) {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (mesh && dataPoint.scaleChange.y !== 0) {
          if (mesh.childrenComp) {
            mesh.childrenComp.forEach(function (child) {
              child.setParent(mesh);
            });
          }
        }
      });
    }

    function areWallChildrenMass(wallMesh) {
      for (let child of wallMesh.childrenComp) {
        if (child.type.toLowerCase() !== "mass") {
          return false;
        }
      }
      return true;
    }

    function isCSGEligibleChild(mesh) {
      return ["door", "window", "void"].includes(mesh.type.toLowerCase());
    }

    if (newHeight !== undefined && newHeight !== null && !isNaN(newHeight)) {
      const storeyCollection = StructureCollection.getInstance()
        .getStructures()
      [structure_id].getStoreyData();
      let storeyAfterUpdateHeightCommandData = [];
      let storeyAfterUpdateHeightMeshes = [];
      let updateCSGCommands = [];

      let _storeyOfInterest = storeyCollection.getStoreyByValue(storey);
      let oldHeight = _storeyOfInterest.height;
      let diff;

      if (_.round(newHeight, 4) <= _CONSTANTS.balconyHeight) {
        showToast(
          "Storey height cannot be less than " +
          DisplayOperation.convertToDefaultDimension(_CONSTANTS.balconyHeight)
        );
        reduxStore.dispatch(
          updateStorey({
            storeyValue: storey,
            property: 'height',
            value: DisplayOperation.convertToDefaultDimension(oldHeight)
          })
        );
        // ScopeUtils.changeStoreyHeight(_storeyOfInterest.value, oldHeight);
        return;
      }

      if (_.round(newHeight, 4) !== _.round(oldHeight, 4)) {
        let _executeUIChangeEvent = function () {
          let storey = _storeyOfInterest.value;
          let prevHeight = _storeyOfInterest.height;
          let structureId = _storeyOfInterest.structure_id;

          let data = {
            storey: storey,
            newHeight: newHeight,
            prevHeight: prevHeight,
            structureId: structureId,
          };

          let getSaveData = function () {
            let saveData = AutoSave.getSaveDataPrototype();

            saveData.commandId = this.id;
            saveData.data.saveType = "changeStoreyHeight";
            saveData.data.identifier = {
              floorkey: store.floorkey,
              structure_id: structureId,
              storey,
            };

            saveData.data.afterOperationData = {
              height: newHeight,
            };

            saveData.data.beforeOperationData = {
              height: prevHeight,
            };

            return saveData;
          };

          return new Command(
            "updatedStoreyHeightUI",
            data,
            _getUpdateHeightUICommandLogic(),
            getSaveData
          );
        };
        let updateStoreyHeightUICommand = _executeUIChangeEvent();

        let optionsBeforeUpdate = {
          params: [
            commandUtils.worldMatrixChangeOperations.PARAMS.position,
            commandUtils.worldMatrixChangeOperations.PARAMS.scale,
          ],
          stack: [],
        };

        let staircases,
          staircasesUndoCmds = [],
          staircasesUndoYets = [];
        if (_storeyOfInterest.elements) {
          let _tempStoreyOfInterestElements = Object.assign(
            [],
            _storeyOfInterest.elements
          );
          for (let i = 0; i < _tempStoreyOfInterestElements.length; i++) {
            let element = _tempStoreyOfInterestElements[i];
            let storeyBeforeUpdateHeightCommandData = null;

            if (!element.mesh.isDisposed()) {
              _.remove(element.mesh.childrenComp, (child) =>
                child.isDisposed()
              );
              if (["wall", "mass"].includes(element.type.toLowerCase())) {
                // if(element.type.toLowerCase() === 'staircase' && element.)  continue;
                if (isOfBalconyHeightOrLess(element.mesh)) {
                  if (storey < 0) {
                    storeyBeforeUpdateHeightCommandData =
                      commandUtils.worldMatrixChangeOperations.getCommandData(
                        element.mesh,
                        optionsBeforeUpdate
                      );
                    element.updateBase(newHeight - oldHeight);
                    storeyAfterUpdateHeightMeshes.push({
                      object: element,
                      storeyBeforeUpdateHeightCommandData:
                        storeyBeforeUpdateHeightCommandData,
                    });
                  }
                } else {
                  if (
                    element.type.toLowerCase() === "wall" &&
                    element.mesh.childrenComp &&
                    element.mesh.childrenComp.length > 0 &&
                    !areWallChildrenMass(element.mesh)
                  ) {
                    if (element.originalWallMesh) {
                      let newScaling = element.mesh.scaling.clone();
                      let newPosition = element.mesh.position.clone();
                      let newWallHeight = newHeight - _CONSTANTS.slabHeight;
                      let oldWallHeight = oldHeight - _CONSTANTS.slabHeight;

                      newScaling.y =
                        element.mesh.scaling.y *
                        (newWallHeight / oldWallHeight);
                      newPosition.y += (newHeight - oldHeight) / 2;

                      let optionsForCSG = {};
                      optionsForCSG.differenceY =
                        element.storey > 0 ? 0 : newHeight - oldHeight;
                      optionsForCSG.updatePosition = false;
                      optionsForCSG.rePosition = newPosition.asArray();
                      optionsForCSG.reScale = newScaling.asArray();
                      const childCSGIndex = element.mesh.childrenComp.findIndex(isCSGEligibleChild);
                      let csgObject = updateCSG(element.mesh.childrenComp[childCSGIndex], optionsForCSG);
                      updateCSGCommands.push(csgObject);
                    }
                  } else {
                    storeyBeforeUpdateHeightCommandData =
                      commandUtils.worldMatrixChangeOperations.getCommandData(
                        element.mesh,
                        optionsBeforeUpdate
                      );
                    if (element.mesh.childrenComp) {
                      element.mesh.childrenComp.forEach((c) => {
                        c.setParent(null);
                      });
                    }
                    element.updateHeight(oldHeight, newHeight);
                    if (element.mesh.childrenComp) {
                      element.mesh.childrenComp.forEach((c) => {
                        c.setParent(element.mesh);
                      });
                    }
                    storeyAfterUpdateHeightMeshes.push({
                      object: element,
                      storeyBeforeUpdateHeightCommandData:
                        storeyBeforeUpdateHeightCommandData,
                    });
                  }
                }
              }
            }
          }
          _storeyOfInterest.setHeight(newHeight);
          diff = _storeyOfInterest.height - oldHeight;
          if (storey < 0) {
            _storeyOfInterest.setBase(_storeyOfInterest.getBase() - diff);
          }

          // Callback to change height of all the staircases
          staircases = _storeyOfInterest.elements.filter(
            (element) => element.type.toLowerCase() === "staircase"
          );
        }
        let count = parseInt(storey);

        if (count > 0) {
          for (
            let i = count + 1;
            i < storeyCollection.getPositiveStoreysLength();
            i++
          ) {
            if (i === 0) continue;
            let storey = storeyCollection.getStoreyByValue(i);

            let previousStoreyVal = i === 1 ? -1 : i - 1;
            let previousStorey =
              storeyCollection.getStoreyByValue(previousStoreyVal);

            storey.setBase(
              previousStorey.getBase() + previousStorey.getHeight()
            );

            if (storey.elements) {
              for (let j = 0; j < storey.elements.length; j++) {
                let element = storey.elements[j];

                if (!element.mesh.isDisposed()) {
                  if (
                    [
                      "wall",
                      "mass",
                      "roof",
                      "floor",
                      "furniture",
                      "staircase",
                    ].includes(element.type.toLowerCase())
                  ) {
                    if (
                      element.type.toLowerCase() === "floor" &&
                      element.isSupportFloor()
                    )
                      continue;
                    let storeyBeforeUpdateHeightCommandData =
                      commandUtils.worldMatrixChangeOperations.getCommandData(
                        element.mesh,
                        optionsBeforeUpdate
                      );
                    element.updateBase(diff);
                    storeyAfterUpdateHeightMeshes.push({
                      object: storey.elements[j],
                      storeyBeforeUpdateHeightCommandData:
                        storeyBeforeUpdateHeightCommandData,
                    });
                  }
                }
              }
            }
          }
        } else {
          for (
            let i = count;
            i > -(storeyCollection.getNegativeStoreysLength() + 1);
            i--
          ) {
            if (i === 0) continue;
            let storey = storeyCollection.getStoreyByValue(i);

            let previousStoreyVal = i === -1 ? 1 : i + 1;
            let previousStorey =
              storeyCollection.getStoreyByValue(previousStoreyVal);

            storey.setBase(previousStorey.getBase() - storey.getHeight());

            if (storey.elements) {
              for (let j = 0; j < storey.elements.length; j++) {
                let element = storey.elements[j];
                if (
                  ["wall", "mass"].includes(element.type.toLowerCase()) &&
                  i === count
                )
                  continue;

                if (!element.mesh.isDisposed()) {
                  if (
                    [
                      "wall",
                      "mass",
                      "roof",
                      "floor",
                      "furniture",
                      "staircase",
                    ].includes(element.type.toLowerCase())
                  ) {
                    if (
                      element.type.toLowerCase() === "floor" &&
                      element.isSupportFloor()
                    )
                      continue;
                    let storeyBeforeUpdateHeightCommandData =
                      commandUtils.worldMatrixChangeOperations.getCommandData(
                        element.mesh,
                        optionsBeforeUpdate
                      );
                    element.updateBase(diff);
                    storeyAfterUpdateHeightMeshes.push({
                      object: storey.elements[j],
                      storeyBeforeUpdateHeightCommandData:
                        storeyBeforeUpdateHeightCommandData,
                    });
                  }
                }
              }
            }
          }
        }

        let sourceMeshIDs = [],
          makeUniqueStairs = [];
        for (let i = 0; i < staircases.length; i++) {
          if (staircases[i].mesh.sourceMesh) {
            makeUniqueStairs.push(staircases[i].mesh);
            // let sourceMeshUniqueId = staircases[i].mesh.sourceMesh.uniqueId;
            // let sourceStaircase = staircases[i].mesh.sourceMesh.getSnaptrudeDS();
            // if(!(sourceMeshIDs.includes(sourceMeshUniqueId))){
            //     // eslint-disable-next-line no-await-in-loop
            //     staircasesUndoCmds.push(... await sourceStaircase._objectPropertiesChanged("staircaseHeight", newHeight, {returnUndoCmd: true, updateUI: false}));
            //     sourceMeshIDs.push(sourceMeshUniqueId);
            // }
          } else {
            // eslint-disable-next-line no-await-in-loop
            staircasesUndoCmds.push(
              ...await staircases[i]._objectPropertiesChanged(
                "staircaseHeight",
                newHeight,
                { returnUndoCmd: true, updateUI: false }
              )
            );
            staircasesUndoYets.push(false);
          }
        }
        if (makeUniqueStairs.length > 0) {
          let makeUniqueStairsCmd = objectPropertiesView.makeUnique(
            makeUniqueStairs,
            { returnCommand: true }
          );
          let uniqueMeshes = [];
          // makeUniqueStairsCmd["cmds"][1].data.forEach(data => uniqueMeshes.push(scene.getMeshByUniqueID(data.meshId)));
          _.remove(makeUniqueStairsCmd.cmds, (cmd) => {
            if (cmd.name === "createUnique") {
              cmd.data.forEach((data) =>
                uniqueMeshes.push(store.scene.getMeshByUniqueID(data.meshId))
              );
              return true;
            }
          });

          staircasesUndoCmds.push(...makeUniqueStairsCmd.cmds);
          staircasesUndoYets.push(...makeUniqueStairsCmd.yets);

          for (let i = 0; i < uniqueMeshes.length; i++) {
            // eslint-disable-next-line no-await-in-loop
             await uniqueMeshes[i]
              .getSnaptrudeDS()
              ._objectPropertiesChanged("staircaseHeight", newHeight, {
                returnUndoCmd: true,
                updateUI: false,
              });
          }
          let creationCommandData =
            commandUtils.creationOperations.getCommandData(uniqueMeshes, null);
          let creationCommand = commandUtils.creationOperations.getCommand(
            "createUnique",
            creationCommandData
          );
          staircasesUndoCmds.push(creationCommand);
        }

        let optionsAfterUpdate;
        storeyAfterUpdateHeightMeshes.forEach((data) => {
          // StoreyMutation.assignStorey(data.object);
          optionsAfterUpdate = {
            data: data.storeyBeforeUpdateHeightCommandData,
            params: [
              commandUtils.worldMatrixChangeOperations.PARAMS.position,
              commandUtils.worldMatrixChangeOperations.PARAMS.scale,
            ],
          };
          storeyAfterUpdateHeightCommandData.push(
            ...commandUtils.worldMatrixChangeOperations.getCommandData(
              data.object.mesh,
              optionsAfterUpdate
            )
          );
        });

        let mainOptions = {};
        mainOptions.preExecuteCallback = priorHeightChange;
        mainOptions.postExecuteCallback = postHeightChange;
        mainOptions.preUnExecuteCallback = priorHeightChange;
        mainOptions.postUnExecuteCallback = postHeightChange;
        let updateStoreyHeightCommand =
          commandUtils.worldMatrixChangeOperations.getCommand(
            "updateStoreyHeight",
            storeyAfterUpdateHeightCommandData,
            mainOptions
          );
        let updateHeightCommands = [updateStoreyHeightUICommand];
        let executeCommandList = [false];
        if (updateCSGCommands.length > 0) {
          updateCSGCommands.forEach(function (cmd) {
            updateHeightCommands.push(
              cmd.creationCommands[0],
              cmd.deletionCommands[0],
              cmd.dllCommands[0]
            );
            updateHeightCommands = _.compact(updateHeightCommands);
            cmd.dllCommands[0]
              ? executeCommandList.push(false, true, false)
              : executeCommandList.push(false, true);
          });
        }
        updateHeightCommands.push(
          ...staircasesUndoCmds,
          updateStoreyHeightCommand
        );
        executeCommandList.push(...staircasesUndoYets, false);
        updateHeightCommands.executeLeftToRight = true;
        CommandManager.execute(updateHeightCommands, executeCommandList);

        const componentsChanged = storeyAfterUpdateHeightMeshes.map(
          (data) => data.object
        );
        virtualSketcher.updateWithoutGeometryEdit(componentsChanged);
      }
    }
    if (store.selectionStack.length > 0) {
      store.selectionStack.forEach(function (mesh) {
        removeMeshSelectionChildren(mesh);
        mesh.state = "off";
      });
    }
    store.selectionStack.length = 0;

  }

  /**
   * Adjust storey visibility
   * @param structure_id
   * @param storey
   * @param hidden
   */
  function showOrHideStorey(structure_id, storey, hidden) {
    const storeyCollection = StructureCollection.getInstance()
      .getStructures()
      [structure_id].getStoreyData();

    let _storeyOfInterest = storeyCollection.getStoreyByValue(storey);
    _storeyOfInterest.hidden = hidden;

    if (_storeyOfInterest) {
      let _elements = _storeyOfInterest.elements;

      if (_elements.length > 0) {
        if (store.selectionStack.length > 0) {
          store.selectionStack.forEach(function (mesh) {
            removeMeshSelectionChildren(mesh);
            mesh.state = "off";
          });
        }
        store.selectionStack.length = 0;
        for( let i = 0; i < _elements.length; i++ ){
          _elements[i].isElementHiddenFromLayerToggle = false;
          if(_elements[i].mesh &&
            (_elements[i].mesh.name.includes("terrain") ||
             _elements[i].mesh.name.includes("Building"))
          ){
            let _layer = _storeyOfInterest.layerData.getLayerByName(_elements[i].mesh.layerName, 1);
            if(_layer && _layer.hidden){
              _elements[i].isElementHiddenFromLayerToggle = true;
            }
          }
        }

        let _meshes = _elements.map((e) => e.mesh);

        let options = {
          meshKeys: ["isVisible", "isPickable"],
        };
        let commandData = commandUtils.propertyChangeOperations.getCommandData(
          _meshes,
          options
        );

        options.data = commandData;

        let onExecute, onUnExecute;
        if (!hidden) {
          onExecute = _CONSTANTS.show;
          onUnExecute = _CONSTANTS.hide;
          _elements.forEach(function (elem) {
            if(!elem.isElementHiddenFromLayerToggle){
              elem.show();
              setLayerTransperancy(elem.mesh);
            }
            store.hiddenMeshes.removeElem(elem.mesh.uniqueId);
          });
        } else {
          onExecute = _CONSTANTS.hide;
          onUnExecute = _CONSTANTS.show;
          _elements.forEach(function (elem) {
            elem.hide();
            store.hiddenMeshes.push(elem.mesh.uniqueId);
            
            // this toggle gets priority over which roofs were visible in 3D
            if (is2D()) _.pull(store.roofsVisibleIn3D, elem.mesh);
          });
        }

        commandData = commandUtils.propertyChangeOperations.getCommandData(
          _meshes,
          options
        );

        let optionsForExecute = {
          postExecuteCallback: function () {
            // ScopeUtils.toggleHideOrShowStorey(storey, onExecute);
            reduxStore.dispatch(toggleHideOrShowStorey({storey, visibility: onExecute}));
            updateRoofAccordion();
          },
          postUnExecuteCallback: function () {
            // ScopeUtils.toggleHideOrShowStorey(storey, onUnExecute);
            reduxStore.dispatch(toggleHideOrShowStorey({storey, visibility: onUnExecute}));
            updateRoofAccordion();
          },
        };

        commandUtils.propertyChangeOperations.executeCommand(
          _CONSTANTS.hideOrShowStoreyCommand,
          commandData,
          optionsForExecute
        );
        updateRoofAccordion();
      }
    }
  }

  /**
   * Select a particular storey
   * @param structure_id
   * @param storey
   */
  function selectStorey(structure_id, storey) {
    const storeyCollection = StructureCollection.getInstance()
      .getStructures()
      [structure_id].getStoreyData();

    let _storeyOfInterest = storeyCollection.getStoreyByValue(storey);

    if (_storeyOfInterest) {
      let _elements = _storeyOfInterest.elements;
      if (_elements.length > 0) {
        if (store.selectionStack.length > 0) {
          store.selectionStack.forEach(function (mesh) {
            removeMeshSelectionChildren(mesh);
            mesh.state = "off";
          });
        }
        store.selectionStack.length = 0;

        let group;
        //  group = MeshGroups.getGroupByUniqueId("my_group");
        if (!group) group = MeshGroups.createNewGroup("my_group");

        _elements.forEach(function (elem) {
          if (!elem.mesh.isVisible) return;
          if (Mass.isPlinth(elem.mesh)) return;
          if (elem.children) {
            if (elem.children.length > 0) {
              elem.children.forEach((child) => {
                child.dispose();
              });
            }
            elem.children.length = 0;
          }
          store.selectionStack.push(elem.mesh);
          group.addTemporaryMember(elem.mesh);
          elem.mesh.state = "on";
          drawSelectionBox(elem.mesh);
        });
      }
    }
  }

  /**
   * Duplicate storeys based on selected objects. Create new storeys if necessary
   * @param structure_id
   * @param direction
   * @param num
   */
  function duplicateStoreys(structure_id, direction, num = 1) {
    const str = StructureCollection.getInstance().getStructures()[structure_id];
    const storeyCollection = str.getStoreyData();
    const storeyLLCluster = str.getLinkedListCluster();
    const clonedMeshes = [];
    const _throwAwayMeshes = [];
    const _replaceMeshes = [];
    let meshesDeleted = [];
    let _allMeshesWhosePropertyNeedsToBeChanged = [];
    let linkedListData = {
      structure_id: structure_id,
    };
    let storeyData = {
      structure_id: structure_id,
      idList: [],
    };

    function _getLinkedList(elem) {
      let _dLL = storeyLLCluster.getLinkedList(elem.linkedListId);
      if (!_dLL) {
        _dLL = storeyLLCluster.addNewLinkedList(elem.linkedListId);
        _dLL.append(elem.mesh.uniqueId);
      }

      return _dLL;
    }

    function _getApexElement(element, dLL) {
      let apexElem;
      if (direction === _CONSTANTS.storeyUp) {
        if (dLL.tail.data) apexElem = str.getObjectByUniqueId(dLL.tail.data);
      } else if (direction === _CONSTANTS.storeyDown) {
        if (dLL.head.data) apexElem = str.getObjectByUniqueId(dLL.head.data);
      } else apexElem = element;

      return apexElem;
    }

    function _cloneElement(elem) {
      function isFurniture(elem) {
        return elem.type.toLowerCase() === "furniture";
      }

      let newElementDS;
      let newElement;

      let uniqueObject = storeyUnique && !isFurniture(elem);
      let options = {
        uniqueObject: uniqueObject,
        sendOriginalToInfinity: false,
        arrayOperation: false,
      };

      newElement = pasteObject(elem.mesh, options);
      newElement.state = "off";
      newElement.isVisible = true;
      newElementDS = newElement.getSnaptrudeDS();

      return newElementDS;
    }

    function _positionElement(elem, apexElem) {
      elem.mesh.computeWorldMatrix(true);
      apexElem.mesh.computeWorldMatrix(true);
      let bbInfo = elem.mesh.getBoundingInfo();
      let apexBBInfo = apexElem.mesh.getBoundingInfo();

      let storey = storeyCollection.getStoreyByValue(elem.storey);
      let height = storey.height;

      let apexStorey = storeyCollection.getStoreyByValue(apexElem.storey);
      let apexStoreyHeight = apexStorey.getHeight();
      let apexStoreyBase = apexStorey.getBase();

      let elemType = elem.type.toLowerCase();

      switch (elemType) {
        case "mass":
          if (direction === _CONSTANTS.storeyUp) {
            if (
              _.round(bbInfo.boundingBox.extendSizeWorld.y * 2, 4) >=
              _CONSTANTS.balconyHeight
            ) {
              let editMeshOffset =
                elem.mesh.position.y - bbInfo.boundingBox.minimumWorld.y;
              elem.mesh.position.y =
                editMeshOffset + apexBBInfo.boundingBox.maximumWorld.y;
              if (
                _.round(apexBBInfo.boundingBox.extendSizeWorld.y * 2, 4) <=
                _CONSTANTS.balconyHeight
              ) {
                elem.mesh.position.y +=
                  apexStoreyHeight -
                  apexBBInfo.boundingBox.extendSizeWorld.y * 2;
              }
            } else {
              let editMeshOffset =
                bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y;
              let offsetDifference =
                bbInfo.boundingBox.extendSizeWorld.y * 2 - editMeshOffset;
              elem.mesh.position.y =
                offsetDifference + apexBBInfo.boundingBox.maximumWorld.y;

              if (
                _.round(apexBBInfo.boundingBox.extendSizeWorld.y * 2, 4) <=
                _CONSTANTS.balconyHeight
              ) {
                elem.mesh.position.y +=
                  apexStoreyHeight -
                  apexBBInfo.boundingBox.extendSizeWorld.y * 2;
              }
            }
          } else {
            if (
              _.round(bbInfo.boundingBox.extendSizeWorld.y * 2, 4) >=
              _CONSTANTS.balconyHeight
            ) {
              let editMeshOffset = Math.abs(
                bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y
              );
              elem.mesh.position.y =
                apexBBInfo.boundingBox.minimumWorld.y - editMeshOffset;
              if (
                _.round(apexBBInfo.boundingBox.extendSizeWorld.y * 2, 4) <=
                _CONSTANTS.balconyHeight
              ) {
                elem.mesh.position.y -=
                  apexStoreyHeight -
                  apexBBInfo.boundingBox.extendSizeWorld.y * 2;
              }
            } else {
              let editMeshOffset =
                bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y;
              let offsetDifference =
                bbInfo.boundingBox.extendSizeWorld.y * 2 - editMeshOffset;
              elem.mesh.position.y =
                apexBBInfo.boundingBox.minimumWorld.y - offsetDifference;

              if (
                _.round(apexBBInfo.boundingBox.extendSizeWorld.y * 2, 4) <=
                _CONSTANTS.balconyHeight
              ) {
                if (!elem.isEdited()) {
                  elem.mesh.position.y -=
                    apexStoreyHeight -
                    apexBBInfo.boundingBox.extendSizeWorld.y * 2;
                }
              }
            }
          }
          break;
        case "wall":
          if (direction === _CONSTANTS.storeyUp) {
            if (
              _.round(bbInfo.boundingBox.extendSizeWorld.y * 2, 4) >=
              _CONSTANTS.balconyHeight
            ) {
              if (
                apexElem.isEdited() &&
                apexBBInfo.boundingBox.extendSizeWorld.y * 2 > apexStoreyHeight
              ) {
                elem.mesh.position.y = apexBBInfo.boundingBox.maximumWorld.y;
                elem.mesh.computeWorldMatrix(true);
                let intersectingSlab = store.scene.meshes.find((mesh) => {
                  if (
                    mesh.storey >= apexElem.storey &&
                    mesh.type.toLowerCase() == "roof"
                  ) {
                    return elem.mesh.intersectsMesh(mesh);
                  }
                });
                let slabHeight;
                if (intersectingSlab) {
                  slabHeight = DisplayOperation.getOriginalDimension(
                    objectPropertiesView.getMeshDimensions(intersectingSlab)
                      .height
                  );
                } else {
                  slabHeight =
                    store.projectProperties.properties.slabThicknessProperty.getValue();
                }
                let editMeshOffset =
                  elem.mesh.position.y - bbInfo.boundingBox.minimumWorld.y;
                elem.mesh.position.y =
                  editMeshOffset +
                  apexBBInfo.boundingBox.maximumWorld.y +
                  slabHeight;
              } else {
                let editMeshOffset =
                  elem.mesh.position.y - bbInfo.boundingBox.minimumWorld.y;
                elem.mesh.position.y =
                  apexBBInfo.boundingBox.minimumWorld.y +
                  apexStoreyHeight +
                  editMeshOffset;
              }
            } else {
              let editMeshOffset =
                bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y;
              let offsetDifference =
                bbInfo.boundingBox.extendSizeWorld.y - editMeshOffset;
              elem.mesh.position.y =
                offsetDifference +
                apexBBInfo.boundingBox.centerWorld.y +
                apexStoreyHeight;
            }
          } else {
            if (
              _.round(bbInfo.boundingBox.extendSizeWorld.y * 2, 4) >=
              _CONSTANTS.balconyHeight
            ) {
              if (apexElem.isEdited()) {
                elem.mesh.position.y = apexBBInfo.boundingBox.minimumWorld.y;
                elem.mesh.computeWorldMatrix(true);
                let intersectingSlab = store.scene.meshes.find((mesh) => {
                  if (
                    mesh.storey >= apexElem.storey &&
                    mesh.type.toLowerCase() == "roof"
                  ) {
                    return elem.mesh.intersectsMesh(mesh);
                  }
                });
                let slabHeight;
                if (intersectingSlab) {
                  slabHeight = DisplayOperation.getOriginalDimension(
                    objectPropertiesView.getMeshDimensions(intersectingSlab)
                      .height
                  );
                } else {
                  slabHeight =
                    store.projectProperties.properties.slabThicknessProperty.getValue();
                }
                let editMeshOffset = Math.abs(
                  bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y
                );
                elem.mesh.position.y =
                  apexBBInfo.boundingBox.minimumWorld.y -
                  editMeshOffset -
                  slabHeight;
              } else {
                let slab;
                // Made this change for Sanity_511. Please review this case before making any changes
                if (elem.storey != 1)
                  slab = extrafunc.checkWallInsideSlab(elem.mesh, elem.storey);
                if (slab) {
                  let slabHeight = DisplayOperation.getOriginalDimension(
                    objectPropertiesView.getMeshDimensions(slab.mesh).height
                  );
                  let effectiveWallHeight = apexStoreyHeight - slabHeight;
                  let wallHeight = DisplayOperation.getOriginalDimension(
                    objectPropertiesView.getMeshDimensions(elem.mesh).height
                  );
                  let hDiff = wallHeight - effectiveWallHeight;
                  if (Math.abs(hDiff) > 1e-3) {
                    changeHeightOfObjects([elem.mesh], {
                      newHeightInBabylonUnits: effectiveWallHeight,
                    });
                    elem.mesh.computeWorldMatrix();
                    elem.mesh.position.y -= hDiff;
                  }
                }
                let editMeshOffset = Math.abs(
                  bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y
                );
                elem.mesh.position.y =
                  apexBBInfo.boundingBox.maximumWorld.y -
                  apexStoreyHeight -
                  editMeshOffset;
              }
            } else {
              let editMeshOffset =
                bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y;
              let offsetDifference =
                bbInfo.boundingBox.extendSizeWorld.y - editMeshOffset;
              elem.mesh.position.y =
                apexBBInfo.boundingBox.centerWorld.y -
                offsetDifference -
                height;
            }
          }
          break;
        case "roof":
          if (direction === _CONSTANTS.storeyUp) {
            let editMeshOffset =
              bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y;
            let offsetDifference =
              bbInfo.boundingBox.extendSizeWorld.y - editMeshOffset;
            elem.mesh.position.y =
              apexBBInfo.boundingBox.centerWorld.y +
              apexStoreyHeight +
              offsetDifference;
          } else {
            let pseudoApexStorey = storeyCollection.getStoreyByValue(
              apexElem.storey === 1 ? -1 : apexElem.storey - 1
            );
            let pseudoApexStoreyHeight;
            if (pseudoApexStorey) {
              pseudoApexStoreyHeight = pseudoApexStorey.getHeight();
            } else {
              pseudoApexStoreyHeight = 11.811023622047244;
            }
            let editMeshOffset =
              bbInfo.boundingBox.maximumWorld.y - elem.mesh.position.y;
            let offsetDifference =
              bbInfo.boundingBox.extendSizeWorld.y - editMeshOffset;
            elem.mesh.position.y =
              apexBBInfo.boundingBox.centerWorld.y -
              pseudoApexStoreyHeight +
              offsetDifference;
          }
          break;
        case "floor":
          if (direction === _CONSTANTS.storeyUp) {
            elem.mesh.position.y =
              apexBBInfo.boundingBox.centerWorld.y + apexStoreyHeight;
          } else {
            elem.mesh.position.y =
              apexBBInfo.boundingBox.centerWorld.y - height;
          }
          break;
        case "furniture":
          elem.mesh.position.x = apexElem.mesh.position.x;
          elem.mesh.position.z = apexElem.mesh.position.z;
          if (direction === _CONSTANTS.storeyUp) {
            elem.mesh.position.y =
              apexBBInfo.boundingBox.centerWorld.y + apexStoreyHeight;
          } else {
            elem.mesh.position.y =
              apexBBInfo.boundingBox.centerWorld.y - height;
          }
          break;
        case "staircase":
          if (direction === _CONSTANTS.storeyUp) {
            elem.mesh.position.y = apexElem.mesh.position.y + apexStoreyHeight;
          } else {
            elem.mesh.position.y = apexElem.mesh.position.y - height;
          }
          break;
      }
    }

    function computeNewStoreyValue(newElementDS, apexElementDS) {
      // Override for painting
      if (newElementDS.type.toLowerCase() === "furniture") {
        if (newElementDS.mesh.name.toLowerCase().indexOf("painting01") !== -1) {
          if (direction === _CONSTANTS.storeyDown) {
            return apexElementDS.storey === 1 ? -1 : apexElementDS.storey - 1;
          } else {
            return apexElementDS.storey === -1 ? 1 : apexElementDS.storey + 1;
          }
        }
      }

      let newStoreyVal;
      if (direction === _CONSTANTS.storeyDown) {
        newStoreyVal =
          apexElementDS.storey === 1 ? -1 : apexElementDS.storey - 1;
        let copyStorey = storeyCollection.getStoreyByValue(newStoreyVal);
        if (!copyStorey || copyStorey.isEmpty()) {
          return newStoreyVal;
        }
      }
      return storeyCollection.calculateStoreyValueByBase(newElementDS);
    }

    function generateStoreys(structureId, value) {
      const payload = {
        items: [],
      };
      let storey = storeyCollection.getStoreyByValue(value);

      if (!storey) {
        let list = storeyCollection.generateStoreys(structureId, value);
        list.forEach(function (value) {
          let storeyOfInterest = storeyCollection.getStoreyByValue(value);
          payload.items.push({
            id: storeyOfInterest.id,
            value: storeyOfInterest.value,
            name: storeyOfInterest.name,
            height: DisplayOperation.convertToDefaultDimension(storeyOfInterest.height),
            hidden: storeyOfInterest.hidden,
            layers: []
          });
          // addStoreyUI(storeyOfInterest);
        });
        storeyData.idList.push(...list);
      } else {
        storeyData.idList.push(value);
      }

      reduxStore.dispatch(appendStorey(payload));
    }

    function addElementToStorey(element, storeyVal) {
      if (storeyVal !== element.storey) {
        let storey = storeyCollection.getStoreyByValue(storeyVal);
        let prevStorey = storeyCollection.getStoreyByValue(element.storey);

        prevStorey.removeElement(element);
        storey.addElement(element);

        if (element.mesh.childrenComp) {
          element.mesh.childrenComp.forEach(function (child) {
            let childDS = child.getSnaptrudeDS();
            prevStorey.removeElement(childDS);
            storey.addElement(childDS);

            if (child.type.toLowerCase() === "door") {
              child.childrenComp.forEach((supportFloor) => {
                if (supportFloor.type.toLowerCase() === "floor") {
                  let supportFloorDS = supportFloor.getSnaptrudeDS();
                  prevStorey.removeElement(supportFloorDS);
                  storey.addElement(supportFloorDS);
                }
              });
            }
          });
        }
        storeyData.idList.push(prevStorey.value);
      }
    }

    function copyStoreyHeight(copyStoreyValue, originalStoreyValue, element) {
      function isOfBalconyHeightOrLess(mesh) {
        let boundingBox = mesh.getBoundingInfo().boundingBox;
        return (
          boundingBox.extendSizeWorld.y * 2 <=
          StoreyMutation._CONSTANTS.balconyHeight
        );
      }

      function satisfyHeightChangeConditions() {
        if (element.isEdited()) return false;
        else if (isOfBalconyHeightOrLess(element.mesh)) return false;
        else if (["floor", "roof"].includes(element.type.toLowerCase()))
          return false;
        else return true;
      }

      function isCopyEligibleEmpty(storey) {
        const elements = storey.elements;
        const types = elements.map(e => e.type);
        return (_.uniq(types).length === 1 && types[0].toLowerCase() === "roof") ||
            _.isEmpty(elements);
      }

      let copyStorey = storeyCollection.getStoreyByValue(copyStoreyValue);
      let originalStorey =
        storeyCollection.getStoreyByValue(originalStoreyValue);

      if (copyStorey.getHeight() !== originalStorey.getHeight()) {
        if (isCopyEligibleEmpty(copyStorey)) {
          if (satisfyHeightChangeConditions()) {
            copyStorey.setHeight(originalStorey.getHeight());
            // changeStoreyHeightUI(copyStorey.value, copyStorey.getHeight());
            reduxStore.dispatch(updateStorey({
                storeyValue: copyStorey.value,
                property: "height",
                value: DisplayOperation.convertToDefaultDimension(copyStorey.getHeight())
              })
            );

            if (copyStoreyValue < 0) {
              let previousStoreyValue;
              if (copyStoreyValue === -1) previousStoreyValue = 1;
              else previousStoreyValue = copyStoreyValue + 1;

              let previousStorey =
                storeyCollection.getStoreyByValue(previousStoreyValue);
              copyStorey.setBase(
                previousStorey.getBase() - copyStorey.getHeight()
              );
            }

            let storeyArr =
              storeyCollection.updateSubsequentBase(copyStoreyValue);
            storeyData.idList.push(...storeyArr);
          }
        }
      }
    }

    function assignDll(dLL, element) {
      // Check if dll exists in linkedListData
      if (!linkedListData[dLL.id]) {
        linkedListData[dLL.id] = {
          prevData: dLL.serialize()[dLL.id],
          newData: dLL.serialize()[dLL.id],
        };
      }

      element.linkedListId = dLL.id;

      if (direction === _CONSTANTS.storeyUp) {
        dLL.append(element.mesh.uniqueId);
      } else {
        dLL.appendAt(0, element.mesh.uniqueId);
      }

      linkedListData[dLL.id].newData = dLL.serialize()[dLL.id];
    }

    function _throwMeshProperties(mesh) {
      markMeshAsThrowAway(mesh, true);
      mesh.isVisible = false;
    }

    function _throwMeshes() {
      _throwAwayMeshes.forEach((mesh) => {
        // scaling change messes with BRep
        // mesh.scaling = BABYLON.Vector3.Zero();

        // was intended for iPad escape
        // mesh.prevPosition = mesh.position.clone();

        mesh.position = BABYLON.Vector3.One().scale(
          GLOBAL_CONSTANTS.numbers.positions.throwAwayMesh
        );
        mesh.position.y = 0; // so that if anything goes wrong, this doesn't lead to generation of 10000 storeys
        _throwMeshProperties(mesh);

        mesh.getChildren().forEach((child) => {
          _throwMeshProperties(child);
          child.getChildren().forEach((coc) => {
            if (coc.type.toLowerCase() === "floor") {
              _throwMeshProperties(coc);
            }
          });
        });
      });

      _throwAwayMeshes.length = 0;

      let allComponents = _allMeshesWhosePropertyNeedsToBeChanged.map((m) =>
        m.getSnaptrudeDS()
      );

      if (allComponents.length > 0) {
        StoreyMutation.removeObjectFromStorey(allComponents, true);
      }
    }

    function _postExecuteThrowAwayCallback() {
      let data = this.data;
      let stack = [];
      data.forEach((dataPoint) => {
        let meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (meshOfInterest) {
          stack.push(meshOfInterest.getSnaptrudeDS());
          meshOfInterest.getChildMeshes().forEach((child) => {
            stack.push(child.getSnaptrudeDS());
          });
        }
      });

      stack = _.compact(stack);
      // some child meshes like door/window symbols aren't in structure

      StoreyMutation.removeObjectFromStorey(stack, true);
    }

    function _postUnExecuteThrowAwayCallback() {
      let data = this.data;
      let stack = [];
      data.forEach((dataPoint) => {
        let meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (meshOfInterest) {
          stack.push(meshOfInterest.getSnaptrudeDS());
          meshOfInterest.getChildMeshes().forEach((child) => {
            stack.push(child.getSnaptrudeDS());
          });
        }
      });

      stack = _.compact(stack);
      // some child meshes like door/window symbols aren't in structure

      StoreyMutation.assignStorey(stack, null, true);
    }

    function postFilterSelection() {
      /*
            On account of main meshes being replaced by
            instances/clones, the same need to be replaced
            in the store.selectionStack as well. Hence the following
            piece of code.
             */
      if (_replaceMeshes.length > 0) {
        // Remove selectionBox of throwAway meshes
        _throwAwayMeshes.forEach((mesh) => {
          mesh.throwAwayTag = "throwAway";
          removeMeshSelectionChildren(mesh);
          mesh.state = "off";
        });

        // Update store.selectionStack
        store.selectionStack = store.selectionStack.filter(
          (mesh) => !mesh.throwAwayTag
        );

        _throwAwayMeshes.forEach((mesh) => {
          delete mesh.throwAwayTag;
        });

        // Draw selection boxes for replaced meshes
        _replaceMeshes.forEach((mesh) => {
          virtualSketcher.addWithoutGeometryEdit(mesh)
          store.selectionStack.push(mesh);
          mesh.state = "on";
          drawSelectionBox(mesh);
        });

        _replaceMeshes.length = 0;
      }
    }

    function sortSelectionByType() {
      let sortedRoofFloors = [];
      let sortedStack = [];

      for (let i = 0; i < store.selectionStack.length; i++) {
        if (
          ["floor", "roof"].includes(store.selectionStack[i].type.toLowerCase())
        ) {
          sortedRoofFloors.push(store.selectionStack[i]);
        } else {
          sortedStack.push(store.selectionStack[i]);
        }
      }

      sortedStack.push(...sortedRoofFloors);
      store.selectionStack = sortedStack;
    }

    function _handleDuplication(elem, options) {
      let dLL = _getLinkedList(elem);
      let apexElementDS = _getApexElement(elem, dLL);
      let replaceElementDS = null;
      let baseElem = elem;
      let plinthDimChangeCmd;
      function isTerrainMap(elem) {
        if (elem.mesh) {
          return elem.mesh.name.includes("terrain");
        }
        return false;
      }

      // Need not handle a mesh if it has a parent. Will be taken care of by pasteObject()
      if (!elem.mesh.parent && !isTerrainMap(elem)) {
        // Checks if the object has already been replicated or not
        // Condition returns true if it has not been replicated
        // Thus throwing away the current mesh and putting an instance in it's place
        if (!elem.mesh.isAnInstance && !storeyUnique) {
          replaceElementDS = _cloneElement(elem);
          replaceElementDS.mesh.replacedObject = true
          _throwAwayMeshes.push(elem.mesh);
          _replaceMeshes.push(replaceElementDS.mesh);
          let replaceDllData = StoreyMutation.replaceElementInDLL(
            elem,
            replaceElementDS
          );
          // linkedListData[dLL.id] = replaceDllData[dLL.id];

          if (!linkedListData[dLL.id]) {
            linkedListData[dLL.id] = replaceDllData[dLL.id];
          } else {
            linkedListData[dLL.id].newData = replaceDllData[dLL.id].newData;
          }
          baseElem = replaceElementDS;
        }

        // Convert Plinth Slab Instance to a Normal Slab. Will reach here only in case of Storey Down.
        // A Plinth does not replicate in Storey Up
        if (Roof.isPlinth(baseElem)) {
          let oldSlabThickness = DisplayOperation.getOriginalDimension(objectPropertiesView.getMeshDimensions(baseElem.mesh).height);
          plinthDimChangeCmd = DisplayOperation.updateDimensionScale(store.projectProperties.properties.slabThicknessProperty.getValue(), 
              "height", oldSlabThickness, baseElem.mesh, {returnCommand: true, ignoreWallAdjustments: true, doNotClearSelection: true});
          baseElem.slabType = "Basement Slab";
          options.extraCmds.push(...plinthDimChangeCmd.cmd);
          options.plinthMeshesSlabTypeChange.push(baseElem.mesh);
        }

        let newElementDS = _cloneElement(elem);
        _positionElement(newElementDS, apexElementDS);
        let newStoreyValue = computeNewStoreyValue(newElementDS, apexElementDS);
        generateStoreys(newElementDS.structure_id, newStoreyValue);
        copyStoreyHeight(newStoreyValue, elem.storey, newElementDS);
        addElementToStorey(newElementDS, newStoreyValue);
        assignDll(dLL, newElementDS);

        // Specific Handling of objects based on Object Type
        let newElementType = newElementDS.type.toLowerCase();
        if (["mass", "wall"].includes(newElementType)) {
          // Deleting any Plinth which might be a child of a copied Mass
          _.remove(newElementDS.mesh.childrenComp, (mesh) => {
            if (mesh.getSnaptrudeDS().massType === "Plinth") {
              removeMeshFromStructure(mesh);
              mesh.dispose();
              return true;
            }
          });

          // Delete the orignal Plinth in case of Storey Down
          if (newStoreyValue === -1) {
            let dll = storeyLLCluster.getLinkedList(newElementDS.linkedListId);
            let bottomMostElem = meshObjectMapping.getObjectByUniqueId(
              dll.head.next.data
            );
            if (bottomMostElem.storey === 1) {
              _.remove(bottomMostElem.mesh.childrenComp, (m) => {
                if (m.getSnaptrudeDS().massType === "Plinth") {
                  // removeMeshFromStructure(m);
                  // m.dispose();
                  meshesDeleted.push(m);
                  return true;
                }
              });
            }
          }

          // Draw a Plinth in case the new Mass is made on Storey 1 (Case where storey down is done from storey 2)
          else if (newStoreyValue === 1) {
            Mass.drawPlinth(newElementDS.mesh);
          }
        } else if (newElementType === "roof") {
          // Convert newly made slab from Plinth Slab to Normal Slab
          if (newElementDS.slabType === "Plinth") {
            let oldSlabThickness = DisplayOperation.getOriginalDimension(objectPropertiesView.getMeshDimensions(newElementDS.mesh).height);
            DisplayOperation.updateDimensionScale(store.projectProperties.properties.slabThicknessProperty.getValue(), 
                "height", oldSlabThickness, newElementDS.mesh, {returnCommand: true, doNotClearSelection: true});
          }

          // else if(newStoreyValue === -1){
          //     let dll = storeyLLCluster.getLinkedList(newElementDS.linkedListId);
          //     let bottomMostElem = meshObjectMapping.getObjectByUniqueId(dll.head.next.data);
          //     if(bottomMostElem.storey === 1){
          //         _.remove(bottomMostElem.mesh.childrenComp, (m => {
          //             if(m.getSnaptrudeDS().massType === "Plinth"){
          //                 // removeMeshFromStructure(m);
          //                 // m.dispose();
          //                 meshesDeleted.push(m);
          //                 return true;
          //             }
          //         }));
          //     }
          // }

          // Convert newly made slab from Normal Slab to Plinth Slab
          else if (newStoreyValue === 1) {
            let oldSlabThickness = DisplayOperation.getOriginalDimension(
              objectPropertiesView.getMeshDimensions(newElementDS.mesh).height
            );
            DisplayOperation.updateDimensionScale(
              store.projectProperties.properties.plinthHeightProperty.getValue(),
              "height",
              oldSlabThickness,
              newElementDS.mesh,
              { returnCommand: true, doNotClearSelection: true }
            );
            newElementDS.slabType = "Plinth";
          }

          if (newStoreyValue > 1) newElementDS.slabType = "Intermediate Slab";
          else if (newStoreyValue < 1) newElementDS.slabType = "Basement Slab";
        }

        if (replaceElementDS) {
          clonedMeshes.push(replaceElementDS.mesh);
        }
        clonedMeshes.push(newElementDS.mesh);
      }
    }

    try {
      resetParentRelatedOperations();
      if (store.selectionStack.length > 0) {
        /*
                Sorting the selection stack based on storey value
                to ascertain order of selection of objects in store.scene
                on duplication.
                Warning: Can cause decrease in performance
                 */
        store.selectionStack.sort(function (a, b) {
          return a.storey - b.storey;
        });
        sortSelectionByType();
        let extraCmds = [], plinthMeshesSlabTypeChange = [];
        for (let i = 0; i < num; i++) {
          let stack = _.clone(store.selectionStack);
          for (let j = 0; j < stack.length; j++) {
              let elementDS = stack[j].getSnaptrudeDS();

            if (elementDS && !elementDS.isLocked) {
              let elementType = elementDS.type.toLowerCase();

              // No Replication for Plinth Masses
              if (elementType === "mass" && elementDS.massType === "Plinth")
                continue;

              // No Storey Up Replication for Plinth Slabs
              if (
                direction === _CONSTANTS.storeyUp &&
                elementType === "roof" &&
                elementDS.slabType === "Plinth"
              )
                continue;

              if (
                [
                  "wall",
                  "floor",
                  "roof",
                  "furniture",
                  "mass",
                  "staircase",
                ].includes(elementType)
              ) {
                let options = {extraCmds: extraCmds, plinthMeshesSlabTypeChange: plinthMeshesSlabTypeChange};
                _handleDuplication(elementDS, options);
              }
            }
          }
          postFilterSelection();
        }

        storeyData.idList = storeyData.idList.filter(
          (item, index) => storeyData.idList.indexOf(item) === index
        );
        let options = {
          preserveChildren: false,
        };
        let cloneCommandData = commandUtils.creationOperations.getCommandData(
          clonedMeshes,
          null,
          options
        );
        let cloneCommand = commandUtils.creationOperations.getCommand(
          _CONSTANTS.duplicateStoreysCommand,
          cloneCommandData,
          options
        );
        let storeysCommand = commandUtils.storeyOperations.getCommand(
          "updateStoreys",
          storeyData
        );
        let dllCommand = commandUtils.linkedListOperations.getCommand(
          "updateLinkedListCluster",
          linkedListData
        );

        let optionsForThrow = {
          params: [commandUtils.worldMatrixChangeOperations.PARAMS.position],
          stack: _throwAwayMeshes,
          postUnExecuteCallback: _postUnExecuteThrowAwayCallback,
          postExecuteCallback: _postExecuteThrowAwayCallback,
        };

        let throwCommandData =
          commandUtils.worldMatrixChangeOperations.getCommandData(
            null,
            optionsForThrow
          );

        // let _allMeshesWhosePropertyNeedsToBeChanged = [];
        _throwAwayMeshes.forEach((mesh) => {
          _allMeshesWhosePropertyNeedsToBeChanged.push(mesh);

          mesh.getChildren().forEach((child) => {
            _allMeshesWhosePropertyNeedsToBeChanged.push(child);
            child.getChildren().forEach((coc) => {
              if (coc.type.toLowerCase() === "floor") {
                _allMeshesWhosePropertyNeedsToBeChanged.push(coc);
              }
            });
          });
        });

        let optionsForPropertyChange = {
          meshKeys: ["type", "isVisible", "storey"],
          componentKeys: ["storey"],
        };

        let plinthMeshesSlabTypeChangeCmd;
        if(plinthMeshesSlabTypeChange.length > 0){
            plinthMeshesSlabTypeChange.forEach(plinthMesh => plinthMesh.getSnaptrudeDS().slabType = "Plinth");
            let cmdData = commandUtils.propertyChangeOperations.getCommandData(plinthMeshesSlabTypeChange, {componentKeys: ["slabType"]});
            plinthMeshesSlabTypeChange.forEach(plinthMesh => plinthMesh.getSnaptrudeDS().slabType = "Basement Slab");
            cmdData = commandUtils.propertyChangeOperations.getCommandData(plinthMeshesSlabTypeChange, {componentKeys: ["slabType"], data: cmdData});
            plinthMeshesSlabTypeChangeCmd = commandUtils.propertyChangeOperations.getCommand("plinthMeshesSlabTypeChange", cmdData);
        }

        let propertyChangeCommandData =
          commandUtils.propertyChangeOperations.getCommandData(
            _allMeshesWhosePropertyNeedsToBeChanged,
            optionsForPropertyChange
          );

        _throwMeshes();

        optionsForPropertyChange.data = propertyChangeCommandData;

        propertyChangeCommandData =
          commandUtils.propertyChangeOperations.getCommandData(
            _allMeshesWhosePropertyNeedsToBeChanged,
            optionsForPropertyChange
          );

        let _propertyChangeCommand = null;

        if (propertyChangeCommandData.length > 0) {
          _propertyChangeCommand =
            commandUtils.propertyChangeOperations.getCommand(
              "arrayPropertyChange",
              propertyChangeCommandData
            );
        }

        optionsForThrow.data = throwCommandData;

        throwCommandData =
          commandUtils.worldMatrixChangeOperations.getCommandData(
            null,
            optionsForThrow
          );

        let _throwCommand = null;
        if (throwCommandData.length > 0) {
          _throwCommand = commandUtils.worldMatrixChangeOperations.getCommand(
            "throwAway",
            throwCommandData,
            optionsForThrow
          );
        }

        let deletionCommand = null;
        if (meshesDeleted.length > 0) {
          let deletionCommandData =
            commandUtils.deletionOperations.getCommandData(meshesDeleted, null);

          deletionCommand = commandUtils.deletionOperations.getCommand(
            "meshesDeletion",
            deletionCommandData
          );
        }

        let allCommands = [
          cloneCommand,
          _throwCommand,
          _propertyChangeCommand,
          storeysCommand,
          dllCommand, 
          ...extraCmds,
          plinthMeshesSlabTypeChangeCmd
        ];

        allCommands = _.compact(allCommands);
        let toExecute = allCommands.map((cmd) => false);

        if (deletionCommand) {
          allCommands.push(deletionCommand);
          toExecute.push(true);
        }

        allCommands.executeLeftToRight = true;
        CommandManager.execute(allCommands, toExecute);

        const clonedComponents = clonedMeshes.map((m) => m.getSnaptrudeDS());
        clonedComponents.forEach((c) => {
          virtualSketcher.addWithoutGeometryEdit(c);
          setLayerTransperancy(c.mesh);
        });

        storeyData.idList.forEach((s) => {
          // ScopeUtils.toggleHideOrShowStorey(s, _CONSTANTS.show);
        });
        objectPropertiesView.updateObjectProperties();
      }
    } catch (e) {
      console.error(e);
      console.error("Duplicate Storeys failed");
    }
  }

  function _getHeightsArray(storeyCollection, negativeStoreysFlag) {
    let heightsArr = [];

    if (negativeStoreysFlag) {
        let tempStoreyCollection = _getNegativeStoreyCollection(storeyCollection);

        for (let storeyId in tempStoreyCollection) heightsArr.push(tempStoreyCollection[storeyId].getHeight());
    } else {
        let tempStoreyCollection = _getPositiveStoreyCollection(storeyCollection);

        for (let storeyId in tempStoreyCollection) heightsArr.push(tempStoreyCollection[storeyId].getHeight());
    }

    return heightsArr;
}

function _calculateStoreyValueFromHeights(baseHeight, totalHeight, heightsArray, storeysLength, negativeStoreysLength) {
    if (baseHeight <= totalHeight && baseHeight >= 0) {
        if (heightsArray.length > 1) {
            for (let i = 1; i <= heightsArray.length; i++) {
                let sumUpToIndex = _.round(heightsArray.slice(0, i).reduce((acc, cur) => acc + cur), 4);

                if (baseHeight <= sumUpToIndex) {
                    if (baseHeight < (sumUpToIndex - (heightsArray[i - 1] / 2))) return i;
                    else return i + 1;
                }
            }
        } else {
            if (baseHeight < totalHeight / 2) return 1;
            else return 2;
        }
    } else if (baseHeight < 0 && baseHeight >= -totalHeight) {
        if (heightsArray.length > 0) {
            for (let i = 1; i <= heightsArray.length; i++) {
                let sumUpToIndex = _.round(heightsArray.slice(0, i).reduce((acc, cur) => acc + cur), 4);

                if (baseHeight >= -sumUpToIndex) {
                    if (baseHeight > -(sumUpToIndex - (heightsArray[i - 1] / 2))){
                        // Storey Value should never be zero
                        if(i === 1) return 1;
                        else    return -i + 1;
                    }
                    else return -i;
                }
            }
        } else {
            if (baseHeight > -totalHeight / 2) return 1;
            else return -1;
        }
    } else if (baseHeight < 0 && baseHeight < -totalHeight) {
        let diff = baseHeight + totalHeight;
        let quotient = Math.ceil(Math.abs(diff) / store.floor_height);
        const tolerance = _.round(store.floor_height / 2, 4);

        if (baseHeight < -(totalHeight + _.round(quotient * store.floor_height, 4) - tolerance)) return negativeStoreysLength - quotient;
        else return negativeStoreysLength - quotient + 1 === 0 ? -1 : negativeStoreysLength - quotient + 1;
    } else {
        let diff = baseHeight - totalHeight;
        let quotient = Math.ceil(diff / store.floor_height);
        const tolerance = _.round(store.floor_height / 2, 4);

        if (baseHeight > (totalHeight + _.round(quotient * store.floor_height, 4) - tolerance)) return storeysLength + quotient;
        else return storeysLength + quotient - 1;
    }
}

function _getNegativeStoreyCollection(storeyCollection) {
    return _.pickBy(storeyCollection, function (value, key) {
        return value.value < 0;
    });
}

function _getPositiveStoreyCollection(storeyCollection) {
    return _.pickBy(storeyCollection, function (value, key) {
        return value.value > 0;
    });
}

function _getMaxNegativeStorey(storeyCollection) {
    let storeyValArr = [];
    let tempStoreyCollection = _getNegativeStoreyCollection(storeyCollection);
    _.forOwn(tempStoreyCollection, function (value, key) {
        storeyValArr.push(value.value);
    });
    return storeyValArr.length > 0 ? Math.min(...storeyValArr) : 0;
}

function _getMaxPositiveStorey(storeyCollection) {
    let storeyValArr = [];
    let tempStoreyCollection = _getPositiveStoreyCollection(storeyCollection);
    _.forOwn(tempStoreyCollection, function (value, key) {
        storeyValArr.push(value.value);
    });
    return storeyValArr.length > 0 ? Math.max(...storeyValArr) : 0;
}

/**
 * Assign storey on base change of elements {Operations: freeMove, move, extrude, moveFace, scale}
 * @param elemStack
 * @param heightCopyStorey
 * @param autoSave
 */
function assignStorey(elemStack, heightCopyStorey, autoSave = false) {

    function sendInfoToBackend() {
        let saveData = [];
        _.forEach(uniqueIdStoreyMapping, (value, uniqueId) => {
            let saveDataPoint = AutoSave.getSaveDataPrototype();

            saveDataPoint.commandId = makeid(5);
            saveDataPoint.data.saveType = "addObjectToStorey";

            saveDataPoint.data.identifier = {
                structure_id: store.activeLayer.structure_id,
                floorkey: store.floorkey,
                storey: value.storey.toString()
            };

            saveDataPoint.data.afterOperationData = {
                storey : value.storey,
                linkedListId : value.linkedListId,
                uniqueId : parseInt(uniqueId)
            };

            saveData.push(saveDataPoint);
        });

        AutoSave.directPublish(saveData);
    }

    function isOfBalconyHeightOrLess(mesh) {
        let boundingBox = mesh.getBoundingInfo().boundingBox;
        return boundingBox.extendSizeWorld.y * 2 <= StoreyMutation._CONSTANTS.balconyHeight;
    }

    if (!_.isArray(elemStack)) {
        elemStack = [elemStack];
    }

    let uniqueIdStoreyMapping = {};

    for (let i = 0; i < elemStack.length; i++) {
        let storeyCollection = StructureCollection.getInstance().getStructures()[elemStack[i].structure_id].getStoreyData();
        let storeys = storeyCollection.getAllStoreys();
        let negativeStoreysLength = storeyCollection.getNegativeStoreysLength();
        let positiveStoreysLength = storeyCollection.getPositiveStoreysLength();

        let mesh = elemStack[i].mesh;
        mesh.computeWorldMatrix(true);

        let bBox = mesh.getBoundingInfo().boundingBox;
        let baseOfMesh;


        if (mesh.type) {
            if (mesh.type.toLowerCase() === 'roof') baseOfMesh = _.round(bBox.maximumWorld.y, 4);
            else baseOfMesh = _.round(bBox.minimumWorld.y, 4);

            let heightsArray = _getHeightsArray(storeys, baseOfMesh < 0);

            let totalHeight = heightsArray.length !== 0 ? _.round(heightsArray.reduce((acc, cur) => acc + cur), 4) : 11.811;

            let newStoreyVal = _calculateStoreyValueFromHeights(baseOfMesh, totalHeight, heightsArray, positiveStoreysLength, -negativeStoreysLength);

            let storeyVal = elemStack[i].storey;

            if (!(newStoreyVal === storeyVal)) {
                if (storeyVal) {
                    // let storey = storeyCollection.getStoreyByValue(storeyVal);
                    removeObjectFromStorey(elemStack[i], autoSave);
                }

                _setElementStorey(elemStack[i], newStoreyVal);

                let newStorey;
                if (newStoreyVal > (positiveStoreysLength - 1)) {
                    for (let j = positiveStoreysLength; j <= newStoreyVal; j++) {
                        newStorey = storeyCollection.addStorey(elemStack[i].structure_id, j);
                        this.addStoreyUI(newStorey);
                    }
                    // this.updateEntireStoreysUI();
                } else if (newStoreyVal < 0) {

                    if (negativeStoreysLength !== 0 && typeof negativeStoreysLength !== "undefined") {
                        let absoluteNewStoreyVal = Math.abs(newStoreyVal);

                        if (absoluteNewStoreyVal > negativeStoreysLength) {
                            for (let j = negativeStoreysLength + 1; j <= absoluteNewStoreyVal; j++) {
                                newStorey = storeyCollection.addStorey(elemStack[i].structure_id, -j);
                                this.addStoreyUI(newStorey);
                            }
                        } else newStorey = storeyCollection.getStoreyByValue(newStoreyVal);
                    } else {
                        newStorey = storeyCollection.addStorey(elemStack[i].structure_id, -1);
                        this.addStoreyUI(newStorey);
                    }

                    // this.updateEntireStoreysUI();
                } else newStorey = storeyCollection.getStoreyByValue(newStoreyVal);

                if (!isOfBalconyHeightOrLess(elemStack[i].mesh) && !elemStack[i].isEdited()) {
                    let filteredElements = newStorey.elements.filter(element => !['roof', 'floor'].includes(element.type.toLowerCase()));
                    if (heightCopyStorey && filteredElements.length === 0) {
                        let copyStorey = storeyCollection.getStoreyByValue(heightCopyStorey);
                        if (newStoreyVal > 0) {
                            newStorey.setHeight(copyStorey.getHeight());
                        } else {
                            newStorey.setHeight(copyStorey.getHeight());
                        }
                        ScopeUtils.changeStoreyHeight(newStoreyVal, newStorey.getHeight());
                    }
                }
                newStorey.addElement(elemStack[i]);

            }
            else {
                if (!isOfBalconyHeightOrLess(elemStack[i].mesh) && !elemStack[i].isEdited()) {
                    if (heightCopyStorey && storeyVal) {
                        let storey = storeyCollection.getStoreyByValue(storeyVal);
                        let filteredElements = storey.elements.filter(element => !['roof', 'floor'].includes(element.type.toLowerCase()));
                        if (filteredElements.length === 0) {
                            let copyStorey = storeyCollection.getStoreyByValue(heightCopyStorey);
                            if (newStoreyVal > 0) {
                                storey.setHeight(copyStorey.getHeight());
                            } else {
                                storey.setHeight(copyStorey.getHeight());
                            }
                            ScopeUtils.changeStoreyHeight(storeyVal, storey.getHeight());
                        }
                    }
                }
            }

            uniqueIdStoreyMapping[mesh.uniqueId] = {
                storey : newStoreyVal,
                linkedListId : mesh.getSnaptrudeDS().linkedListId
            };

        }
    }

    if (autoSave) sendInfoToBackend();
}

  /**
   * Remove an element from the storey. Object will remain in Snaptrude structure.
   * Accepts a mesh or an array of meshes
   * @param elemStack
   * @param autoSave
   */
  function removeObjectFromStorey(elemStack, autoSave = false) {
    function sendInfoToBackend() {
      let saveData = [];
      _.forEach(uniqueIdStoreyMapping, (value, uniqueId) => {
        let saveDataPoint = AutoSave.getSaveDataPrototype();

        saveDataPoint.commandId = makeid(5);
        saveDataPoint.data.saveType = "removeObjectFromStorey";

        saveDataPoint.data.identifier = {
          structure_id: store.activeLayer.structure_id,
          floorkey: store.floorkey,
          storey: value.storey.toString(),
          linkedListId: value.linkedListId,
        };

        saveDataPoint.data.afterOperationData = {
          storey: value.storey,
          linkedListId: value.linkedListId,
          uniqueID: parseInt(uniqueId),
        };

        saveData.push(saveDataPoint);
      });

      AutoSave.directPublish(saveData);
    }

    if (!_.isArray(elemStack)) elemStack = [elemStack];

    let uniqueIdStoreyMapping = {};

    elemStack.forEach((object) => {
      let mesh = object.mesh;
      // if (isMeshThrowAway(mesh)) return;
      let storeyObject = StructureCollection.getInstance()
        .getStructureById(mesh.structure_id)
        .getStoreyData()
        .getStoreyByValue(object.storey);
      storeyObject.removeElement(object);
      uniqueIdStoreyMapping[mesh.uniqueId] = {
        storey: object.storey,
        linkedListId: object.linkedListId,
      };

      object.storey = null;
      mesh.storey = null;
    });

    if (autoSave) sendInfoToBackend();
  }

  /**
   * Update Storeys accordian on creation of new storey
   * @param storey
   */
  function addStoreyUI(storey) {
    // ScopeUtils.addStorey(storey);
    const payload = {
      items: [{
        id: storey.id,
        value: storey.value,
        name: storey.name,
        height: DisplayOperation.convertToDefaultDimension(storey.height),
        hidden: storey.hidden,
        layers: []
      }],
    };
    reduxStore.dispatch(appendStorey(payload));
  }

  /**
   * Update Storeys accordian on addition of all new storeys
   */
  function updateEntireStoreysUI() {
    ScopeUtils.updateStoreys();
  }

  /**
   * Reflects change of storey height in UI
   * @param storey
   * @param height
   */
  function changeStoreyHeightUI(storey, height) {
    ScopeUtils.changeStoreyHeight(storey, height);
  }

  /**
   * Assigns linkedListId and appends to corresponding dLL
   * @param replaceeDS
   * @param replacerDS
   */
  function replaceElementInDLL(replaceeDS, replacerDS) {
    try {
      let linkedListData = {};
      if (replaceeDS.linkedListId) {
        let str = getStructure(replaceeDS.structure_id);
        let dll = str
          .getLinkedListCluster()
          .getLinkedList(replaceeDS.linkedListId);

        if (dll) {
          // Prepare data for CommandUtils
          linkedListData.structure_id = replacerDS.structure_id;
          linkedListData[dll.id] = {
            prevData: dll.serialize()[dll.id],
            newData: dll.serialize()[dll.id],
          };

          //replace the element in dll
          replacerDS.linkedListId = replaceeDS.linkedListId;
          dll.replace(replaceeDS.mesh.uniqueId, replacerDS.mesh.uniqueId);

          // Obtain new dll data
          linkedListData[dll.id].newData = dll.serialize()[dll.id];
          return linkedListData;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function setStoreyUnique(unique) {
    storeyUnique = unique;
  }

  function getAllStoreys() {
    return getStoreyCollection(store.activeLayer.structure_id).getAllStoreys();
  }

  function getAParticularStorey(storeyNumber) {
    return getAllStoreys()[storeyNumber];
  }

  function onWhichStoreyDoesTheHeightBelong(vector3DotY){

    // logic borrowed from assignStorey

    const storeyCollection = StructureCollection.getInstance().getStructures()[store.activeLayer.structure_id].getStoreyData();
    const storeys = storeyCollection.getAllStoreys();
    const negativeStoreysLength = storeyCollection.getNegativeStoreysLength();
    const positiveStoreysLength = storeyCollection.getPositiveStoreysLength();

    const heightsArray = _getHeightsArray(storeys, vector3DotY < 0);
    const totalHeight = heightsArray.length !== 0 ? _.round(heightsArray.reduce((acc, cur) => acc + cur), 4) : 11.811;

    const storeyNumber = _calculateStoreyValueFromHeights(
        vector3DotY, totalHeight, heightsArray, positiveStoreysLength, -negativeStoreysLength);

    if (_.isNumber(storeyNumber)) return getAParticularStorey(storeyNumber);

  }
  
  function getCurrentMaxStoreyHeight(){
    const storeyCollection = StructureCollection.getInstance().getStructures()[store.activeLayer.structure_id].getStoreyData();
    const positiveStoreysLength = storeyCollection.getPositiveStoreysLength();
    
    const maxStorey = getAParticularStorey(positiveStoreysLength - 1);
    
    return maxStorey.base + maxStorey.height;
  }

  return {
    init,
    createNewBlankStorey,
    updateHeight,
    showOrHideStorey,
    selectStorey,
    duplicateStoreys,
    assignStorey,
    removeObjectFromStorey,
    addStoreyUI,
    updateEntireStoreysUI,
    changeStoreyHeightUI,
    getHideOrShowStoreyCommandLogic: _getHideOrShowStoreyCommandLogic,
    getHideOrShowLayerCommandLogic: _getHideOrShowLayerCommandLogic,
    hideOrShowLayerGetSaveData,
    replaceElementInDLL,
    setStoreyUnique,
    getAllStoreys,
    getAParticularStorey,
    getStoreyCollection,
    _CONSTANTS: _CONSTANTS,
    queries : {
      onWhichStoreyDoesTheHeightBelong,
      getCurrentMaxStoreyHeight
    }
  };
})();
export { StoreyMutation };
