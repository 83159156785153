import { USER_SET_BIM_PROPERTIES } from "./user_set_bim_properties";

export const USER_SET_BIM_PROPERTIES_HANDLER = {
  getDefaultRoofOverhangProperty: () => {
    return USER_SET_BIM_PROPERTIES.roofOverhang;
  },
  getRoofOverhang: () => {
    return USER_SET_BIM_PROPERTIES.roofOverhang;
  },
  getGridUnit: () => {
    return USER_SET_BIM_PROPERTIES.gridUnitVal
  }
}