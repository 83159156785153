import roomLayout from "./roomLayout";
import gardener from "./gardener";

const autoLayout = (function (){
  return {
    roomLayout,
    gardener,
  }
})();

export default autoLayout;