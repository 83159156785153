import Victor from "victor";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { AutoSave } from "../modules/socket/autoSave.js";
import { Command } from "../modules/commandManager/Command.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { saveMaterialInBackend } from "./applyMaterialFuncs.js";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds.js";
import { textureOnLoadCallback } from "./mats.js";
import { appElement } from "./bimDataFuncs.js";
import {
  loadBed,
  loadWardrobe,
  getLengthandBreadthofInterior,
  placeBedComp,
  placeWardrobeComp,
} from "./bedroom.js";
import {
  loadSofa,
  loadChair,
  loadTable,
  loadCoffeeTable,
  loadSideTable,
  placeSofaComp,
  placeTableComp,
  placeChairComp,
  placeCoffeeTableComp,
  placeSideTableComp,
} from "./living.js";
import { loadBathroom, placeBathroomComp } from "./bathroom.js";
import {
  loadKitchen1,
  loadKitchen2,
  placeKitchen1Comp,
  placeKitchen2Comp,
} from "./kitchen.js";
import {
  loadDiningTable,
  loadPainting,
  placeDiningTableComp,
  placePaintingComp,
} from "./dining.js";
import { loadCabin, placeCabinSetComp } from "./cabin.js";
import { loadConf, placeConfComp } from "./conference.js";
function genInteriors(room_pols, room_types, room_names) {
  console.log("generating Interiors");
  console.log(room_pols);
  var bedroom_flag = true;
  var living_flag = true;
  var kitchen_flag = true;
  var dining_flag = true;
  var bathroom_flag = true;
  var cabin_flag = true;
  var conference_flag = true;
  var bedroomCompNames = {};
  bedroomCompNames.bed_name = "bed01";
  bedroomCompNames.wardrobe_name = "wardrobe01";
  bedroomCompNames.dummy_name = "dummy01";
  var livingCompNames = {};
  livingCompNames.sofa_name = "sofa01";
  livingCompNames.chair_name = "chair01";
  livingCompNames.stable_name = "stable01";
  livingCompNames.ctable_name = "ctable01";
  livingCompNames.table_name = "table01";
  var kitchenCompNames = {};
  kitchenCompNames.kitchen1_name = "kitchen01";
  kitchenCompNames.kitchen2_name = "kitchen01";
  var diningCompNames = {};
  diningCompNames.diningtable_name = "dining01";
  diningCompNames.painting_name = "painting01";
  var bathroomCompNames = {};
  bathroomCompNames.sink_name = "bathroom01";
  var cabinCompNames = {};
  cabinCompNames.cabin_name = "cabin01";
  var confCompNames = {};
  confCompNames.conf_name = "conf01";
  console.log(room_types);
  for (var i = 0; i < room_pols.length; i++) {
    if (!store.room_curves[i]) {
      console.log(room_types[i]);
      if (room_types[i].toLowerCase().indexOf("bedroom") !== -1) {
        if (bedroom_flag) {
          // console.log(bedroomCompNames.bed_name);
          loadBedroomComps(room_pols[i], bedroomCompNames);
          bedroom_flag = false;
          placeBedroomComps(room_pols[i], bedroomCompNames);
        } else {
          placeBedroomComps(room_pols[i], bedroomCompNames);
        }
      } else if (room_types[i].toLowerCase().indexOf("living") !== -1) {
        if (living_flag) {
          loadLivingComps(room_pols[i], livingCompNames);
          living_flag = false;
          placeLivingComps(room_pols[i], livingCompNames);
        } else {
          placeBedroomComps(room_pols[i], livingCompNames);
        }
      } else if (room_types[i].toLowerCase().indexOf("kitchen") !== -1) {
        if (kitchen_flag) {
          loadKitchenComps(room_pols[i], kitchenCompNames);
          kitchen_flag = false;
          placeKitchenComps(room_pols[i], kitchenCompNames);
        } else {
          placeKitchenComps(room_pols[i], kitchenCompNames);
        }
      } else if (room_types[i].toLowerCase().indexOf("dining") !== -1) {
        if (dining_flag) {
          console.log("dining here");
          loadDiningComps(room_pols[i], diningCompNames);
          dining_flag = false;
          placeDiningComps(room_pols[i], diningCompNames);
        } else {
          placeDiningComps(room_pols[i], diningCompNames);
        }
      } else if (room_types[i].toLowerCase().indexOf("bathroom") !== -1) {
        if (bathroom_flag) {
          // bathroomCompNames.toilet_name = "kitchen01";
          loadBathroomComps(room_pols[i], bathroomCompNames);
          bathroom_flag = false;
          placeBathroomComps(room_pols[i], bathroomCompNames);
        } else {
          placeBathroomComps(room_pols[i], bathroomCompNames);
        }
      } else if (room_types[i].toLowerCase().indexOf("cabin") !== -1) {
        if (cabin_flag) {
          // bathroomCompNames.toilet_name = "kitchen01";
          loadCabinComps(room_pols[i], cabinCompNames);
          cabin_flag = false;
          placeCabinComps(room_pols[i], cabinCompNames);
        } else {
          placeCabinComps(room_pols[i], cabinCompNames);
        }
      } else if (room_types[i].toLowerCase().indexOf("conference") !== -1) {
        if (conference_flag) {
          // bathroomCompNames.toilet_name = "kitchen01";
          loadConfComps(room_pols[i], confCompNames);
          conference_flag = false;
          placeConfComps(room_pols[i], confCompNames);
        } else {
          placeConfComps(room_pols[i], confCompNames);
        }
      }
    }
  }
}

function genInteriors2(autoInteriorState, commandFlag) {
  console.log("generating Interiors");
  // console.log(room_pols);
  let structures = StructureCollection.getInstance().getStructures();
  let str = structures[store.activeLayer.structure_id];
  // let level = str.getAllLevels()[Object.keys(str.getAllLevels())[0]];
  let levels = str.getLevelsByLow(0);
  // console.log(level);

  if (autoInteriorState) {
    store.userSettingsInStructure.autoInteriorState = true;
    let hosts = [];

    levels.forEach(function (level) {
      level.flyweight.floors.forEach(function (floor) {
        hosts.push(floor);
      });
      level.flyweight.masses.forEach(function (mass) {
        hosts.push(mass);
      });
    });
    // console.log(floors[0].room_type);
    // floors.forEach(function (floor) {
    //     console.log(floor.getBoundaryCoords());
    // });
    var bedroom_flag = true;
    var living_flag = true;
    var kitchen_flag = true;
    var dining_flag = true;
    var bathroom_flag = true;
    var cabin_flag = true;
    var conference_flag = true;
    var bedroomCompNames = {};
    bedroomCompNames.bed_name = "bed01";
    bedroomCompNames.wardrobe_name = "wardrobe01";
    bedroomCompNames.dummy_name = "dummy01";
    var livingCompNames = {};
    livingCompNames.sofa_name = "sofa01";
    livingCompNames.chair_name = "chair01";
    livingCompNames.stable_name = "stable01";
    livingCompNames.ctable_name = "ctable01";
    livingCompNames.table_name = "table01";
    var kitchenCompNames = {};
    kitchenCompNames.kitchen1_name = "kitchen01";
    kitchenCompNames.kitchen2_name = "kitchen01";
    var diningCompNames = {};
    diningCompNames.diningtable_name = "dining01";
    diningCompNames.painting_name = "painting01";
    var bathroomCompNames = {};
    bathroomCompNames.sink_name = "bathroom01";
    var cabinCompNames = {};
    cabinCompNames.cabin_name = "cabin01";
    var confCompNames = {};
    confCompNames.conf_name = "conf01";
    // console.log(room_types);
    for (var i = 0; i < hosts.length; i++) {
      if (!store.room_curves[i]) {
        if (
          hosts[i].storey === 1 &&
          hosts[i].type.toLowerCase().indexOf("throwaway") === -1
        ) {
          if (hosts[i].room_type.toLowerCase().indexOf("bedroom") !== -1) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());

            if (bedroom_flag) {
              // console.log(bedroomCompNames.bed_name);
              loadBedroomComps(coords, bedroomCompNames);
              bedroom_flag = false;
              placeBedroomComps(coords, bedroomCompNames);
            } else {
              placeBedroomComps(coords, bedroomCompNames);
            }
          } else if (
            hosts[i].room_type.toLowerCase().indexOf("living") !== -1
          ) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());

            if (living_flag) {
              loadLivingComps(coords, livingCompNames);
              living_flag = false;
              placeLivingComps(coords, livingCompNames);
            } else {
              placeLivingComps(coords, livingCompNames);
            }
          } else if (
            hosts[i].room_type.toLowerCase().indexOf("kitchen") !== -1
          ) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());
            if (kitchen_flag) {
              loadKitchenComps(coords, kitchenCompNames);
              kitchen_flag = false;
              placeKitchenComps(coords, kitchenCompNames);
            } else {
              placeKitchenComps(coords, kitchenCompNames);
            }
          } else if (
            hosts[i].room_type.toLowerCase().indexOf("dining") !== -1
          ) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());
            if (dining_flag) {
              console.log("dining here");
              loadDiningComps(coords, diningCompNames);
              dining_flag = false;
              placeDiningComps(coords, diningCompNames);
            } else {
              placeDiningComps(coords, diningCompNames);
            }
          } else if (
            hosts[i].room_type.toLowerCase().indexOf("bathroom") !== -1
          ) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());
            if (bathroom_flag) {
              // bathroomCompNames.toilet_name = "kitchen01";
              loadBathroomComps(coords, bathroomCompNames);
              bathroom_flag = false;
              placeBathroomComps(coords, bathroomCompNames);
            } else {
              placeBathroomComps(coords, bathroomCompNames);
            }
          } else if (hosts[i].room_type.toLowerCase().indexOf("cabin") !== -1) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());
            if (cabin_flag) {
              // bathroomCompNames.toilet_name = "kitchen01";
              loadCabinComps(coords, cabinCompNames);
              cabin_flag = false;
              placeCabinComps(coords, cabinCompNames);
            } else {
              placeCabinComps(coords, cabinCompNames);
            }
          } else if (
            hosts[i].room_type.toLowerCase().indexOf("conference") !== -1
          ) {
            let coords = convertVector3To2DArray(hosts[i].getBoundaryCoords());
            if (conference_flag) {
              // bathroomCompNames.toilet_name = "kitchen01";
              loadConfComps(coords, confCompNames);
              conference_flag = false;
              placeConfComps(coords, confCompNames);
            } else {
              placeConfComps(coords, confCompNames);
            }
          }
        }
      }
    }
  } else {
    store.userSettingsInStructure.autoInteriorState = false;
    levels.forEach((level) => {
      let furniture = level.flyweight.furnitures.filter(
        (object) => object.autoInterior
      );

      furniture.forEach(function (object) {
        level.removeObjectToLevel(object);
        object.mesh.dispose();
      });
    });
  }

  //updateModifications();
  if (!commandFlag) {
    let _executeEvent = function () {
      let data = {
        state: autoInteriorState,
      };

      let getSaveData = function () {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = this.id;
        saveData.data.saveType = "toggleAutoInteriorsState";
        saveData.data.identifier = {
          structure_id: store.activeLayer.structure_id,
        };
        saveData.data.afterOperationData = autoInteriorState;
        saveData.data.beforeOperationData = !autoInteriorState;

        return saveData;
      };

      let cmd = new Command("autoInterior", data, commandLogic(), getSaveData);
      CommandManager.execute(cmd, false);
    };
    _executeEvent();
  }
}

function commandLogic() {
  return {
    execute: autoInteriorsExe,
    unexecute: autoInteriorsUnExe,
  };
}
function autoInteriorsExe() {
  let data = this.data;
  let stateVal = data.state ? false : true;
  this.data.state = stateVal;
  genInteriors2(stateVal, true);
}
function autoInteriorsUnExe() {
  let data = this.data;
  let stateVal = data.state ? false : true;
  this.data.state = stateVal;
  genInteriors2(stateVal, true);
}

function convertVector3To2DArray(coords) {
  let arrayCoords = [];
  coords.forEach(function (vec) {
    arrayCoords.push([vec.x, vec.z]);
  });
  return arrayCoords;
}

function cloneMaterial(material) {
  console.log("here", material);
  var mat = new BABYLON.StandardMaterial(material.name, store.newScene);
  console.log("here");
  var mat_url = material.diffuseTexture.url;
  mat.diffuseTexture = new BABYLON.Texture(mat_url, store.scene);
  // store.scene.getMeshByName("ground1").material = mat;
  return mat;
}

function checkURL(url) {
  var request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status === 404) {
        console.log("Oh no, it doesurl not exist!");
      }
    }
  };
  request.send();
}

function loadComponent2(comp_path_dir, comp_name, type, subType) {
  document.body.style.cursor = "wait";
  BABYLON.SceneLoader.ImportMesh(
    "",
    comp_path_dir + "/",
    comp_name + ".babylon",
    store.scene,
    function (meshes) {
      // console.log(meshes, name);
      var newMesh = meshes[0];
      newMesh.name = comp_name;
      console.log(newMesh.rotation);
      var bbinfo = newMesh.getBoundingInfo();
      // newMesh.scaling = new BABYLON.Vector3(1, 1, 1);
      // newMesh.visibility = 0;
      // newMesh.normalizeToUnitCube(true);
      // console.log(newMesh.scaling);
      // newMesh.scaling.x /= inch_to_mtr;
      // newMesh.scaling.y /= inch_to_mtr;
      // newMesh.scaling.z /= inch_to_mtr;
      newMesh.position.x = 0;
      newMesh.position.z = 0;
      newMesh.position.y = 0;
      newMesh.convertToFlatShadedMesh();
      // newMesh.enableEdgesRendering(0.9);
      newMesh.freezeWorldMatrix();
      bbinfo.update(newMesh._worldMatrix);
      var x = Math.abs(bbinfo.boundingBox.minimumWorld.y);
      newMesh.unfreezeWorldMatrix();
      if (bbinfo.minimum.y < 0) newMesh.position.y += x;
      store.scene.onBeforeCameraRenderObservable.add(
        modifyImportedMeshes.bind(this, meshes)
      );

      if (["Door", "Window"].includes(type)) {
        newMesh.type = type.toLowerCase();
        newMesh.visibility = 0;
        newMesh.originalScaling = newMesh.scaling.clone();
      } else {
        newMesh.type = "furniture";
        newMesh.visibility = 0;
        newMesh.originalScaling = newMesh.scaling.clone();
        newMesh.originalRotation = newMesh.rotation.clone();
      }

      let structures = StructureCollection.getInstance().getStructures();
      newMesh.structure_id = Object.keys(structures)[1];
      let str = structures[Object.keys(structures)[1]];
      let level = str.getAllLevels()[Object.keys(str.getAllLevels())[0]];
      level.addMeshToLevel(newMesh, false);

      let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      newMesh.setPivotPoint(centroid);
      newMesh.getSnaptrudeDS().subType = subType ? subType : null;

      saveMaterialInBackend(newMesh.material);

      // newMesh.bakeCurrentTransformIntoVertices();
      // mesh = newMesh;
      // console.log(mesh);
      // let structures = StructureCollection.getInstance().getStructures();
      // newMesh.structure_id = Object.keys(structures)[0];
      // let str = structures[Object.keys(structures)[0]];
      // let level = str.getAllLevels()[Object.keys(str.getAllLevels())[0]];
      // level.addFurnitureToLevel(new Furniture(newMesh));
      // console.log(level);

      // meshes[i].scaling = new BABYLON.Vector3(0.0, 0.0, 0.0);
      // meshes[i].visibility = 0;
    },
    function (evt) {
      if (evt.loaded === evt.total) {
        // document.body.style.cursor  = "auto";
        // console.log(evt);
        // window.setTimeout(function () {
        //     ZoomAll();
        //     onCamRot();
        // },10);
      }
    }
  );
}

function loadComponent(comp_path, comp_path_dir, scale_factor, name) {
  BABYLON.SceneLoader.ImportMesh(
    "",
    comp_path_dir + "/",
    name + ".babylon",
    store.scene,
    function (meshes) {
      var newMesh = meshes[0];
      newMesh.name = name;
      newMesh.type = "Furniture";
      var bbinfo = newMesh.getBoundingInfo();
      // newMesh.scaling = new BABYLON.Vector3(1, 1, 1);
      newMesh.visibility = 1;
      // newMesh.normalizeToUnitCube(true);
      // console.log(newMesh.scaling);
      // newMesh.scaling.x /= inch_to_mtr;
      // newMesh.scaling.y /= inch_to_mtr;
      // newMesh.scaling.z /= inch_to_mtr;
      newMesh.position.x = 0;
      newMesh.position.z = 0;
      newMesh.position.y = 0;
      newMesh.convertToFlatShadedMesh();
      newMesh.freezeWorldMatrix();
      bbinfo.update(newMesh._worldMatrix);
      var x = Math.abs(bbinfo.boundingBox.minimumWorld.y);
      newMesh.unfreezeWorldMatrix();
      if (bbinfo.minimum.y < 0) newMesh.position.y += x;
      store.scene.onBeforeCameraRenderObservable.add(
        modifyImportedMeshes.bind(this, meshes)
      );
      let structures = StructureCollection.getInstance().getStructures();
      newMesh.structure_id = Object.keys(structures)[1];
      let str = structures[Object.keys(structures)[1]];
      let level = str.getAllLevels()[Object.keys(str.getAllLevels())[0]];
      level.addFurnitureToLevel(new Furniture(newMesh));
      console.log(level);
      // meshes[i].scaling = new BABYLON.Vector3(0.0, 0.0, 0.0);
      // meshes[i].visibility = 0;
    },
    function (evt) {
      if (evt.loaded === evt.total) {
        console.log(evt);
        // window.setTimeout(function () {
        //     ZoomAll();
        //     onCamRot();
        // },10);
      }
    }
  );
}

function modifyImportedMeshes(meshes) {
  for (var i = 0; i < meshes.length; i++) {
    meshes[i].showBoundingBox = false;
    meshes[i].sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    if (meshes[i].material) {
      // meshes[i].material.alphaMode = 2;
      meshes[i].material.backFaceCulling = false;
      // if (meshes[i].material.alpha < 0.5)
      // meshes[i].material.alphaMode = BABYLON.Engine.ALPHA_ADD;
      meshes[i].material.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      // meshes[i].material.directIntensity = 1.5;
      // meshes[i].material.environmentIntensity = 1.0;
      // meshes[i].material.specularIntensity = 0.3;
      meshes[i].material.cameraExposure = 0.9;
      meshes[i].material.cameraContrast = 1.6;
      // meshes[i].material.specularTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
      // meshes[i].material.useMicroSurfaceFromReflectivityMapAlpha = true;
      // meshes[i].material.useLogarithmicDepth = true;
      meshes[i].material.opacityTexture = null;
      if (meshes[i].material.diffuseTexture) {
        meshes[i].material.diffuseColor = new BABYLON.Color3(0.7, 0.7, 0.7);
        textureOnLoadCallback.bind(meshes[i].material);
        // meshes[i].material.specularColor = new BABYLON.Vector3(0, 0, 0);
        // meshes[i].material.specularPower = 10;
      }
      //newScene.meshes[i].alphaIndex = 1;
      // store.newScene.meshes[i].material.emissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1);
      if (meshes[i].material.subMaterials) {
        for (var j = 0; j < meshes[i].material.subMaterials.length; j++) {
          meshes[i].material.subMaterials[j].backFaceCulling = false;
          // meshes[i].material.subMaterials[j].alphaMode = 2;
          // if (meshes[i].material.subMaterials[j].alpha < 0.5)
          // meshes[i].material.subMaterials[j].alphaMode = BABYLON.Engine.ALPHA_ADD;
          // meshes[i].material.subMaterials[j].useLogarithmicDepth = true;
          meshes[i].material.subMaterials[j].sideOrientation =
            BABYLON.Mesh.DOUBLESIDE;
          // meshes[i].material.subMaterials[j].directIntensity = 1.5;
          // meshes[i].material.subMaterials[j].environmentIntensity = 1.0;
          // meshes[i].material.subMaterials[j].specularIntensity = 0.3;
          meshes[i].material.subMaterials[j].cameraExposure = 0.9;
          meshes[i].material.subMaterials[j].cameraContrast = 1.6;
          // meshes[i].material.subMaterials[j].specularTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
          // meshes[i].material.useMicroSurfaceFromReflectivityMapAlpha = true;
          meshes[i].material.subMaterials[j].opacityTexture = null;
          if (meshes[i].material.subMaterials[j].diffuseTexture) {
            meshes[i].material.subMaterials[j].diffuseColor =
              new BABYLON.Color3(0.7, 0.7, 0.7);
            textureOnLoadCallback.bind(meshes[i].material.subMaterials[j]);
            // meshes[i].material.subMaterials[j].specularColor = new BABYLON.Vector3(0, 0, 0);
            // meshes[i].material.subMaterials[j].specularPower = 10;
          }
          // store.newScene.meshes[i].material.subMaterials[j].emissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1);
        }
      }
    }

    if (i !== 0) {
      meshes[i].scaling = new BABYLON.Vector3(0.0, 0.0, 0.0);
      meshes[i].position = new BABYLON.Vector3(100000, 100000, 100000);
      meshes[i].visibility = 1;
    }

    // meshes[i].scaling = new BABYLON.Vector3(1, 1, 1);
    // meshes[i].normalizeToUnitCube(true);
    // meshes[i].scaling.x /= inch_to_mtr;
    // meshes[i].scaling.y /= inch_to_mtr;
    // meshes[i].scaling.z /= inch_to_mtr;
    // meshes[i].position = new BABYLON.Vector3(0, 0, 0);
    //
    // var bbInfo = meshes[i].getBoundingInfo();
    // meshes[i].freezeWorldMatrix();
    // bbInfo.update(meshes[i]._worldMatrix);
    // var minWorldY = Math.abs(bbInfo.boundingBox.minimumWorld.y);
    // meshes[i].unfreezeWorldMatrix();
    // if (bbInfo.minimum.y < 0) meshes[i].position.y += minWorldY;
  }
  // var parentMesh = meshes[0];
  //
  // for (var k=1;k<meshes.length;k++){
  //     meshes[k].parent = parentMesh;
  // }
}

function loadIntComponentExt(comp_path, comp_path_dir, scale_factor, name) {
  // console.log("---------------")
  BABYLON.SceneLoader.ImportMesh(
    "",
    comp_path_dir + "/",
    name + ".babylon",
    store.scene,
    function (mesh) {
      console.log(mesh);
      for (var i = 0; i < mesh.length; i++) {
        if (mesh[i].material) {
          if (newMesh) {
            if (mesh[i].getIndices().length > newMesh.getIndices().length) {
              var newMesh = mesh[i];
              newMesh.prevScaling = new BABYLON.Vector3(
                newMesh.scaling.x,
                newMesh.scaling.y,
                newMesh.scaling.z
              );
            }
          } else {
            let newMesh = mesh[i];
            newMesh.prevScaling = new BABYLON.Vector3(
              newMesh.scaling.x,
              newMesh.scaling.y,
              newMesh.scaling.z
            );
          }
        }
        mesh[i].scaling = new BABYLON.Vector3(0, 0, 0);
      }
      newMesh.name = name;
      newMesh.type = "furniture";
      newMesh.material.id = newMesh.name + "MultiMaterial";
      newMesh.material.name = newMesh.name + "MultiMaterial";
      newMesh.material.backFaceCulling = false;
      newMesh.showBoundingBox = false;
      // newMesh.scaling = new BABYLON.Vector3(0.0, 0.0, 0.0);
      // newMesh.position = new BABYLON.Vector3(100000,100000,100000);
      newMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      var bbinfo = newMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      newMesh.setPivotPoint(centroid);

      // newMesh.normalizeToUnitCube();
      if (newMesh.material.subMaterials) {
        for (let i = 0; i < newMesh.material.subMaterials.length; i++) {
          newMesh.material.subMaterials[i].backFaceCulling = false;
        }
      }
      console.log(newMesh);
    },
    function (evt) {
      if (evt.lengthComputable) {
        // document.getElementById("loadingText").innerHTML = "Loading, please wait..." + (evt.loaded * 100 / evt.total).toFixed() + "%";
        var $scope = store.angular.element(appElement).scope();
        // console.log($scope);
        $scope = $scope.$$childHead;
        // $scope.$apply(function () {
        //     if (evt.loaded === evt.total) {
        //         $scope.progressVisibilityForImport = true;
        //     }
        //     else {
        //         $scope.progressVisibilityForImport = false;
        //     }
        //     $scope.loadingText = "Loading, please wait..." + (evt.loaded * 100 / evt.total).toFixed() + "%";
        // });
      } else {
        var dlCount = evt.loaded / (1024 * 1024);
        // document.getElementById("loadingText").innerHTML = "Loading, please wait..." + Math.floor(dlCount * 100.0) / 100.0 + " MB already loaded.";
        let $scope = store.angular.element(appElement).scope();
        $scope = $scope.$$childHead;
        $scope.loadingText =
          "Loading, please wait..." +
          Math.floor(dlCount * 100.0) / 100.0 +
          " MB already loaded.";
      }
    }
  );
}

function loadIntComponent(comp_path, comp_path_dir, scale_factor, name) {
  BABYLON.SceneLoader.ImportMesh(
    "",
    comp_path_dir,
    name + ".babylon",
    store.scene,
    function (mesh) {
      var newMesh = mesh[0];
      newMesh.name = name;
      newMesh.type = "furniture";
      newMesh.material.id = newMesh.name + "MultiMaterial";
      newMesh.material.name = newMesh.name + "MultiMaterial";
      newMesh.material.backFaceCulling = false;
      newMesh.showBoundingBox = false;
      newMesh.scaling = new BABYLON.Vector3(0.0, 0.0, 0.0);
      newMesh.visibility = 1;
      newMesh.position = new BABYLON.Vector3(100000, 100000, 100000);
      newMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      newMesh.storey = 1;
      newMesh.convertToFlatShadedMesh();
      // newMesh.showBoundingBox = false;
      // var bbinfo = newMesh.getBoundingInfo();
      // var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      // newMesh.setPivotPoint(centroid);
      /*de
        TODO: to be changed dynamically to the structure id of the room to which the imported component is associated with.
         */
      // newMesh.structure_id = Object.keys(sc.getStructures())[0];
      // newMesh.normalizeToUnitCube();
      // if (newMesh.material.subMaterials) {
      //     for (var i = 0; i < newMesh.material.subMaterials.length; i++) {
      //         newMesh.material.subMaterials[i].backFaceCulling = false;
      //     }
      // }
      store.scene.onBeforeCameraRenderObservable.add(
        modifyImportedMeshes.bind(this, mesh)
      );
      let structures = StructureCollection.getInstance().getStructures();
      newMesh.structure_id = Object.keys(structures)[1];
      let str = structures[Object.keys(structures)[1]];
      let level = str.getAllLevels()[Object.keys(str.getAllLevels())[0]];
      level.addMeshToLevel(newMesh, false);
      let object = newMesh.getSnaptrudeDS();
      if (object.hasOwnProperty("autoInterior")) object.autoInterior = true;
    },
    function (evt) {
      if (evt.lengthComputable) {
        // document.getElementById("loadingText").innerHTML = "Loading, please wait..." + (evt.loaded * 100 / evt.total).toFixed() + "%";
        var $scope = store.angular.element(appElement).scope();
        // console.log($scope);
        $scope = $scope.$$childHead;
        // $scope.$apply(function () {
        //     if (evt.loaded === evt.total) {
        //         $scope.progressVisibilityForImport = true;
        //     }
        //     else {
        //         $scope.progressVisibilityForImport = false;
        //     }
        //     $scope.loadingText = "Loading, please wait..." + (evt.loaded * 100 / evt.total).toFixed() + "%";
        // });
      } else {
        var dlCount = evt.loaded / (1024 * 1024);
        // document.getElementById("loadingText").innerHTML = "Loading, please wait..." + Math.floor(dlCount * 100.0) / 100.0 + " MB already loaded.";
        let $scope = store.angular.element(appElement).scope();
        $scope = $scope.$$childHead;
        $scope.loadingText =
          "Loading, please wait..." +
          Math.floor(dlCount * 100.0) / 100.0 +
          " MB already loaded.";
      }
    }
  );
}

// function loadIntComponent(comp_path, comp_path_dir, scale_factor, name) {
//     var meshLoadedFlag = false;
//     var newMesh;
//     BABYLON.SceneLoader.ImportMesh("", "", comp_path, store.scene, function (mesh) {
//             meshLoadedFlag = true;
//             newMesh = mesh[0];
//             for ( let i = 0; i < mesh.length; i++) {
//                 if (mesh[i].material)
//                     newMesh.material = mesh[i].material;
//             }
//             if (newMesh.material.diffuseTexture) {
//                 // newMesh.material.diffuseTexture.url = comp_path_dir +"/"+ newMesh.material.diffuseTexture.url;
//                 // var mat = cloneMaterial(newMesh.material, comp_path_dir);
//                 var mat_url = comp_path_dir + "/" + newMesh.material.diffuseTexture.url;
//                 newMesh.material.diffuseTexture.url = mat_url;
//                 // newMeshMat = mat;
//             }
//             else if (newMesh.material.subMaterials) {
//                 for (var i = 0; i < newMesh.material.subMaterials.length; i++) {
//                     if (newMesh.material.subMaterials[i].diffuseTexture) {
//                         console.log(newMesh.material.subMaterials[i].diffuseTexture.url.indexOf(comp_path_dir))
//                         if (newMesh.material.subMaterials[i].diffuseTexture.url.indexOf(comp_path_dir) === -1) {
//                             var text_url = comp_path_dir + newMesh.material.subMaterials[i].diffuseTexture.url;
//                         }
//                         else {
//                             var text_url = newMesh.material.subMaterials[i].diffuseTexture.url;
//                         }
//                         text_url.replace(/\-/g, "");
//                         console.log(text_url, newMesh.name);
//                         newMesh.material.subMaterials[i].backFaceCulling = false;
//                         //  var text = new BABYLON.Texture(comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url, store.scene);
//                         newMesh.material.subMaterials[i].diffuseTexture = new BABYLON.Texture(text_url, store.scene);
//                     }
//                 }
//                 // if (newMesh.material){
//                 //     console.log("Here");
//                 //     newMesh.material[p].diffuseTexture = new BABYLON.Texture(comp_path_dir + newMesh.material.diffuseTexture.url, store.scene);
//                 // }
//             }
//             newMesh.name = name;
//             newMesh.visibility = 0;
//             console.log(newMesh);
//             newMesh.material.id = newMesh.name + "MultiMaterial";
//             newMesh.material.name = newMesh.name + "MultiMaterial";
//             newMesh.material.backFaceCulling = false;
//             newMesh.showBoundingBox = false;
//             var bbinfo = newMesh.getBoundingInfo();
//             var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
//             newMesh.setPivotPoint(centroid);
//             newMesh.scaling = new BABYLON.Vector3(scale_factor, scale_factor, scale_factor);
//             newMesh.scaling = new BABYLON.Vector3(0.0, 0.0, 0.0);
//             newMesh.position = new BABYLON.Vector3(100000, 100000, 100000);
//         },
//         function (evt) {
//         }
//     );
//
//     return newMesh;
// }

function loadBedroomComps(room_pol, names) {
  loadBed(names.bed_name, room_pol, true);
  loadWardrobe(names.wardrobe_name, room_pol, true);
  // loadDummy(names.dummy_name, room_pol, true);
  return;
}

function loadLivingComps(room_pol, names) {
  loadSofa(names.sofa_name, room_pol, true);
  loadChair(names.chair_name, room_pol, true);
  loadTable(names.table_name, room_pol, true);
  loadCoffeeTable(names.ctable_name, room_pol, true);
  loadSideTable(names.stable_name, room_pol, true);
  return;
}

function loadBathroomComps(room_pol, names) {
  loadBathroom(names.sink_name, room_pol, true);
  return;
}

function loadKitchenComps(room_pol, names) {
  loadKitchen1(names.kitchen1_name, room_pol, true);
  loadKitchen2(names.kitchen2_name, room_pol, true);
  return;
}

function loadDiningComps(room_pol, names) {
  console.log("here");
  loadDiningTable(names.diningtable_name, room_pol, true);
  loadPainting(names.painting_name, room_pol, true);
  return;
}

function loadCabinComps(room_pol, names) {
  loadCabin(names.cabin_name, room_pol, true);
  return;
}

function loadConfComps(room_pol, names) {
  loadConf(names.conf_name, room_pol, true);
  return;
}

function getCMean(c1, c2, c3) {
  var x1 = c1[0];
  var x2 = c2[0];
  var x3 = c3[0];
  var y1 = c1[1];
  var y2 = c2[1];
  var y3 = c3[1];
  var k =
    ((y2 - y1) * (x3 - x1) - (x2 - x1) * (y3 - y1)) /
    (Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2));
  var x4 = x3 - k * (y2 - y1);
  var y4 = y3 + k * (x2 - x1);
  return [x4, y4];
}

function getRoomAngle(c1, c2, c3) {
  // var cmeanX = (c1[0]+c2[0])/2;
  // var cmeanY = (c1[1]+c2[1])/2;
  // var cmean = [cmeanX, cmeanY];
  var cmean = getCMean(c1, c2, c3);
  var angle = getSlopeAngle2(cmean, c3);

  var angle2 = parseFloat(Number(angle).toFixed(4));
  var pi = parseFloat(Number(Math.PI).toFixed(4));
  var piby2 = parseFloat(Number(Math.PI / 2).toFixed(4));
  // console.log(angle, cmean, c1, c2, c3);
  // if (angle < Math.PI / 2 && (angle >= 0)) {
  //     if (angle == 0 && cmean[0] < c3[0]) {
  //         return "q1";
  //     }
  //     else if (angle == 0 && cmean[0] > c3[0]) {
  //         return "q3";
  //     }
  //     else {
  //         return "q1";
  //     }
  // }
  // else if ((angle < Math.PI) && (angle >= Math.PI / 2)) {
  //     return "q2";
  // }
  // else if ((angle < 0) && (angle > -Math.PI / 2)) {
  //     return "q4";
  // }
  // else if ((angle >= -Math.PI / 2) && (angle > -Math.PI)) {
  //     return "q3";
  // }
  if (angle2 >= 0 && angle2 < piby2) {
    return "q1";
  } else if (angle2 >= piby2 && angle2 < pi) {
    return "q2";
  } else if (angle2 === pi) {
    return "q3";
  } else if (angle2 >= -pi && angle2 < -piby2) {
    return "q3";
  } else if (angle2 >= -piby2 && angle2 < 0) {
    return "q4";
  }
}

function getCompOrientation(newInstance) {
  var bbinfo = newInstance.getBoundingInfo();
  var ZZ = bbinfo.maximum.z - bbinfo.minimum.z;
  var YY = bbinfo.maximum.y - bbinfo.minimum.y;
  var XX = bbinfo.maximum.x - bbinfo.minimum.x;
  if (XX >= YY) {
    return "x";
  } else if (YY > XX) {
    return "y";
  }
}

function getSlopeAngle(p1, p2) {
  var xs = p1[0] - p2[0];
  var ys = p1[1] - p2[1];
  return Math.atan2(Math.abs(ys), Math.abs(xs));
  /*if (xs == 0 && ys == 0) {
        return 0;
    }
    else if (xs == 0) {
        return Math.PI / 2;
    }
    else {
        return Math.atan2(Math.abs(ys), Math.abs(xs));
    }*/
}

function getSlopeAngle2(p1, p2) {
  var xs = p1[0] - p2[0];
  var ys = p1[1] - p2[1]; //p1 first because z is in the negative quadrant
  // console.log(xs, ys);
  return Math.atan2(ys, xs);
  /*if (xs == 0 && ys == 0) {
        return 0;
    }
    else if (xs == 0) {
        if (ys > 0) {
            return Math.PI / 2;
        }
        else {
            return -Math.PI / 2;
        }
    }
    else if (xs < 0 && ys < 0) {
        return -Math.atan2((xs), (ys));
    }
    else {
        return Math.atan2((xs), (ys));
    }*/
}

function offsetInterior(coords, newInstance, distance) {
  let c1 = coords[0];
  let c2 = coords[1];
  let c3 = coords[2];
  newInstance.position.y = 0;

  newInstance.computeWorldMatrix(true);
  let bbinfo = newInstance.getBoundingInfo();
  // newInstance.freezeWorldMatrix();
  // bbinfo.update(newInstance._worldMatrix);
  let interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
  // newInstance.unfreezeWorldMatrix();
  if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;

  let lb = getLengthandBreadthofInterior(newInstance);
  let angle = getSlopeAngle(c1, c2);
  let orientation_room = getRoomAngle(c1, c2, c3);

  // Victor implementation
  let vecC1 = new Victor.fromArray(c1);
  let vecC2 = new Victor.fromArray(c2);
  let vecCmid = new Victor.fromArray([
    (c1[0] + c2[0]) / 2,
    (c1[1] + c2[1]) / 2,
  ]);

  let vecPerpendicular = new Victor.fromArray([
    vecC1.y - vecC2.y,
    vecC2.x - vecC1.x,
  ]);

  let vecInterior = vecCmid.add(
    vecPerpendicular
      .normalize()
      .multiply(new Victor(lb.zz / 2 + distance, lb.zz / 2 + distance))
  );

  if (orientation_room === "q3") {
    newInstance.rotation.y = Math.PI + angle;
  } else if (orientation_room === "q4") {
    newInstance.rotation.y = Math.PI - angle;
  } else if (orientation_room === "q1") {
    newInstance.rotation.y = angle;
  } else if (orientation_room === "q2") {
    newInstance.rotation.y = -angle;
  }
  newInstance.position.x = vecInterior.x;
  newInstance.position.z = vecInterior.y;
}

function placeBedroomComps(room_pol, names) {
  console.log(names.bed_name);
  placeBedComp(room_pol, names.bed_name);
  placeWardrobeComp(room_pol, names.wardrobe_name);
  // placeDummyComp(room_pol, names.dummy_name);
  return;
}

function placeLivingComps(room_pol, names) {
  placeSofaComp(room_pol, names.sofa_name);
  placeTableComp(room_pol, names.table_name);
  placeChairComp(room_pol, names.chair_name);
  placeCoffeeTableComp(room_pol, names.ctable_name);
  placeSideTableComp(room_pol, names.stable_name);
  return;
}

function placeKitchenComps(room_pol, names) {
  placeKitchen1Comp(room_pol, names.kitchen1_name);
  placeKitchen2Comp(room_pol, names.kitchen2_name);
  return;
}

function placeBathroomComps(room_pol, names) {
  placeBathroomComp(room_pol, names.sink_name);
  return;
}

function placeDiningComps(room_pol, names) {
  placeDiningTableComp(room_pol, names.diningtable_name);
  placePaintingComp(room_pol, names.painting_name);
  return;
}

function placeCabinComps(room_pol, names) {
  placeCabinSetComp(room_pol, names.cabin_name);
  return;
}

function placeConfComps(room_pol, names) {
  placeConfComp(room_pol, names.conf_name);
  return;
}
export {
  genInteriors,
  genInteriors2,
  commandLogic,
  autoInteriorsExe,
  autoInteriorsUnExe,
  convertVector3To2DArray,
  cloneMaterial,
  checkURL,
  loadComponent2,
  loadComponent,
  modifyImportedMeshes,
  loadIntComponentExt,
  loadIntComponent,
  loadBedroomComps,
  loadLivingComps,
  loadBathroomComps,
  loadKitchenComps,
  loadDiningComps,
  loadCabinComps,
  loadConfComps,
  getCMean,
  getRoomAngle,
  getCompOrientation,
  getSlopeAngle,
  getSlopeAngle2,
  offsetInterior,
  placeBedroomComps,
  placeLivingComps,
  placeKitchenComps,
  placeBathroomComps,
  placeDiningComps,
  placeCabinComps,
  placeConfComps,
};
