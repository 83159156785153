import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  loadIntComponent,
  getSlopeAngle,
  getCompOrientation,
  getRoomAngle,
} from "./interiors_gen.js";
function loadConf(name, room_pol, flag) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin + "/media/models/conference/" + name + ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/conference/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
    console.log(bed_mesh);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadConf(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function placeConfComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "conference",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeConfComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeConfComp(coords, name) {
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      arrangeConfComp(coords, name);
    } else {
      var int_mesh = store.scene.getMeshByName(name);
      var newInstance = int_mesh.clone(name);
      newInstance.visibility = 1;
      newInstance.scaling = new BABYLON.Vector3(
        store.unit_scale,
        store.unit_scale,
        store.unit_scale
      );
      var bbinfo = newInstance.getBoundingInfo();
      var height = bbinfo.maximum.z - bbinfo.minimum.z;
      var width = bbinfo.maximum.y - bbinfo.minimum.y;
      getRoomOrientationForConf(coords, newInstance);
      // newInstance.rotation.y = angle;
      // newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale;
      // newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale;
      newInstance.position.y = (height / 2) * store.unit_scale;
      // newInstance.position.y = 0;
    }
  }, 1000);
}

function getRoomOrientationForConf(coords, newInstance) {
  var c1 = coords[0];
  var c2 = coords[1];
  var c3 = coords[2];
  // console.log(c1, c2, c3);
  var angle = getSlopeAngle(c1, c2);
  var orientation = getCompOrientation(newInstance);
  var orientation_room = getRoomAngle(c1, c2, c3);
  var bbinfo = newInstance.getBoundingInfo();
  // var ZZ = bbinfo.maximum.z - bbinfo.minimum.z;
  // var YY = bbinfo.maximum.y - bbinfo.minimum.y;
  // var XX = bbinfo.maximum.x - bbinfo.minimum.x;

  newInstance.position.x = coords[2][0];
  newInstance.position.z = coords[2][1];
  newInstance.freezeWorldMatrix();
  bbinfo.update(newInstance._worldMatrix);
  var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
  newInstance.unfreezeWorldMatrix();
  if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;
  // newInstance.freezeWorldMatrix();
  // bbinfo.update(newInstance._worldMatrix);
  //
  // // console.log(bbinfo.boundingBox.maximumWorld.x);
  // // console.log(bbinfo.boundingBox.minimumWorld.x);
  // var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
  // var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
  // var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
  // newInstance.unfreezeWorldMatrix();

  // console.log(orientation_room);
  // console.log(angle);
  // console.log(bbinfo);
  // console.log(XX, YY, ZZ);

  if (orientation_room === "q3") {
    newInstance.rotation.y = Math.PI + angle;
    // newInstance.position.x += XX / 2 * Math.sin(angle);
    // newInstance.position.z += ZZ / 2 * Math.cos(angle);
  } else if (orientation_room === "q4") {
    newInstance.rotation.y = Math.PI - angle;
    // newInstance.position.x -= XX / 2 * Math.sin(angle);
    // newInstance.position.z += ZZ / 2 * Math.cos(angle);
  } else if (orientation_room === "q1") {
    newInstance.rotation.y = angle;
    // newInstance.position.x -= XX / 2 * Math.sin(angle);
    // newInstance.position.z -= ZZ / 2 * Math.cos(angle);
  } else if (orientation_room === "q2") {
    newInstance.rotation.y = -angle;
    // newInstance.position.x += XX / 2 * Math.sin(angle);
    // newInstance.position.z -= ZZ / 2 * Math.cos(angle);
  }
}
export { loadConf, placeConfComp, arrangeConfComp, getRoomOrientationForConf };
