import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import Button from "../../Button";
import Input from "../../Input";
import closeButtonIcon from "../../../assets/icons/close.svg";
import borderedCloseIcon from "../../../assets/icons/bordered-close.svg";
import DataTable, { Td, Text, Th, Tr } from "../../DataTable";
import { speckleRevitImport } from "../../../snaptrude/modules/speckleRevitImport";

const Wrapper = styled.div`
  position: relative;
  width: 36.5625rem;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 14px;
  color: ${colors.primeBlack};
  header {
    padding: 0.75em 1em;
    border-radius: 12px 12px 0px 0px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
  }
  .content {
    min-height: 15em;
    max-height: 17em;
    overflow: auto;
    padding: 1em;
    display: flex;
    flex-direction: row;
  }
`;

function MapLevels({ updatePage, handleClose, nextPage, optionalPage, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const selected_levels = speckleRevitImport.elementsData.selected_levels;
  const probable_storeys = speckleRevitImport.elementsData.probable_storeys;
  const [levels, setLevels] = useState(selected_levels);
  const [storeys, setStoreys] = useState(probable_storeys);

  const handleSubmit = async (evt) => {
    if (evt) evt.preventDefault();
    setIsLoading(true);
    speckleRevitImport.createElements();
    handleClose();
    setIsLoading(false);
    // updatePage(nextPage);
  };

  const handleItemClick = (index) => {
    if (levels.length <= 1) return;
    let newLevels = levels.filter((_, i) => i !== index);
    setLevels(newLevels);
    
  };

  useEffect(() => {
    speckleRevitImport.getLevelStoreyMapping(levels);
    setStoreys(speckleRevitImport.elementsData.probable_storeys);
  }, [levels]);

  return (
    <Wrapper>
      <header>
        <div style={{ flex: 1, fontWeight: 700 }}>Map Levels to Storeys</div>
        <div style={{ cursor: "pointer" }}>
          <img src={closeButtonIcon} alt="Close" onClick={handleClose} />
        </div>
      </header>
      <div className="content">
        <DataTable style={{ fontWeight: "500", fontSize: "12px" }}>
          <Tr>
            <Th style={{ minWidth: "1em", width: "1em", height: "1.5em" }}></Th>
            <Th style={{ width: "10.5em" , height: "1.5em"}}>Level</Th>
            <Th style={{ width: "7.5em", textAlign: "center", height: "1.5em" }}>Elevation</Th>
          </Tr>
          {levels.map((value, index) => {
            return (
              <Tr key={index}>
                <Td style={{ minWidth: "1em", width: "1em", height: "1.5em" }}>
                  <img
                    src={borderedCloseIcon}
                    alt="action"
                    onClick={() => handleItemClick(index)}
                    style={{
                      cursor: levels.length <= 1 ? "not-allowed" : "pointer",
                    }}
                  />
                </Td>
                <Td style={{ width: "10.5em", height: "1.5em" }}>{value.name}</Td>
                <Td style={{ width: "7.5em", textAlign: "center", height: "1.5em" }}>
                  {value.elevation.toFixed(3)}
                </Td>
              </Tr>
            );
          })}
        </DataTable>
        <DataTable style={{ fontWeight: "500", fontSize: "12px" }}>
          <Tr>
            <Th style={{ width: "10.5em", height: "1.5em" }}>Storey on Snaptrude</Th>
            <Th style={{ width: "7.5em", textAlign: "center", height: "1.5em" }}>Storey Height</Th>
          </Tr>
          {storeys.map((value) => {
            return (
              <Tr>
                <Td style={{ width: "10.5em", height: "1.5em" }}>
                  <Text>{value?.storeyName}</Text>
                </Td>
                <Td style={{ width: "7.5em", textAlign: "center", height: "1.5em" }}>
                  <Text>{value?.elevation.toFixed(3)}</Text>
                </Td>
              </Tr>
            );
          })}
        </DataTable>
      </div>
      <footer>
        <div className="button-wrapper">
          <Button
            outline={true}
            title={"Use different levels"}
            onPress={() => {
              updatePage(optionalPage);
            }}
          />
        </div>
        <div className="button-wrapper">
          <Button
            isLoading={isLoading}
            primary={true}
            title={"Continue"}
            onPress={handleSubmit}
          />
        </div>
      </footer>
    </Wrapper>
  );
}

export default MapLevels;
