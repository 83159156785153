export const getFooterText = (viewBy, updatedDate, createdDate, createdBy) => {
    let text = "";
    switch (viewBy) {
        case "createdon":
            text = `Created on ${new Date(createdDate).toLocaleString()}`;
            break;
        case "createdby":
            text = `Created by ${createdBy}`;
            break;
        case "updatedon":
            text = `Last updated on ${new Date(updatedDate).toLocaleString()}`;
        default:
            text = `Last updated on ${new Date(updatedDate).toLocaleString()}`;
            break;
    }
    return text;
}

export const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
}