import React from "react";
import CommonEdit from "../../../../components/rightMenus/commonEdit.js";

const WindowEdit = (props) => {
  return (
    <CommonEdit
      closePopUp={props.closePopUp}
      inputValues={[
        { title: "width", value: 1500 },
        { title: "Lintel", value: 2100 },
        { title: "Sil", value: 900 },
      ]}
      dropdown={[
        {
          title: "Door Type",
          options: ["Swing", "Sliding", "Folding", "Pivot", "Revolving"],
        },
        {
          title: "Shutters",
          options: [
            "1 Shutter",
            "2 Shutter",
            "3 Shutter",
            "4 Shutter",
            "5 Shutter",
            "6 Shutter",
          ],
        },
      ]}
    />
  );
};

export default WindowEdit;
