"use strict";
import BABYLON from "../babylonDS.module.js";
import {store} from "../utilityFunctions/Store.js";
import {meshObjectMapping} from "./mapping.js";
import {StoreyMutation} from "../storeyEngine/storeyMutations.js";
import {SnapProperty} from "../../libs/GUI/snap_properties.js";
import {SnapElement} from "../../libs/GUI/snap_element.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import {familyProperties} from "../../libs/objectProperties";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService.js";
import {isMeshThrowAway} from "../extrafunc";
/*jshint esversion: 6 */
/**
 * { Furniture datastructure }
 *
 * @class      Furniture Walls (name)
 * @param      {Object}  mesh    The mesh
 */
var Furniture = function (mesh) {
  this.mesh = mesh;
  this.level = mesh.level;
  this.level_low = 0;
  this.level_hight = 1;
  this.height = 0;
  this.snapTo = "wall";
  this.cutHole = false;
  this.importType = null;
  // this.storey = (!mesh.storey) ? 1 : mesh.storey;
  this.structure_id = mesh.structure_id;
  this.id = "f_" + Math.random().toString(36).substr(2, 9);
  this.type = "Furniture";
  this.mesh.type = "Furniture";
  this.material = "";
  this.onclick = null;
  this.groupId = null;
  this.autoInterior = false;
  this.storey = this.mesh.storey;
  this.edited = false;
  this.isLocked = false;
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  this.properties = Object.assign({}, familyProperties);
  this.revitMetaData = {};
  this.breed = undefined; // should be "general", "user" or "team"
  this.teamId = undefined; // only valid when this.breed is true
  this.elementId = undefined; // currently only valid for teams, but will extend in future
  this.cost = undefined; // currently only valid for teams, but will extend in future
  this.familyName = undefined;
  
  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  if(!this.mesh.room_type){
    this.mesh.room_type = snaptrudeDSCount.getLabel("Furniture");
  }

  let bbinfo = this.mesh.getBoundingInfo();
  let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  // this.mesh.setPivotPoint(centroid);
  // if (this.mesh.isAnInstance)
    // this.mesh.setPivotMatrix(
    //   BABYLON.Matrix.Translation(-centroid.x, -centroid.y, -centroid.z),
    //   false
    // );
  // this.mesh.setAbsolutePosition(new BABYLON.Vector3(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z));

  // let invertedPivotMatrix = BABYLON.Matrix.Identity();
  // this.mesh.getPivotMatrix().invertToRef(invertedPivotMatrix);
  // BABYLON.Vector3.TransformCoordinatesToRef(this.mesh._absolutePosition, invertedPivotMatrix, this.mesh._absolutePosition);
  this.mesh.computeWorldMatrix(true);

  this.onMove = {
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (evt) {
      //pass
    },

    onPointerMove: function (direction, diff, speed) {
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.name.indexOf("boxScale") != -1) {
        this.mesh.parentMesh.position = this.mesh.position;
      }
    },
  };

  // store.scene.lights[2].includedOnlyMeshes.push(this.mesh);
  // store.scene.lights[2].intensity = 1;
};


Furniture.prototype.cloneProperties = function (otherFurniture, unique = true) {
  otherFurniture.revitMetaData = this.revitMetaData;

  if (otherFurniture.revitMetaData.elementId) {
    otherFurniture.revitMetaData.sourceElementId = this.revitMetaData.elementId;
    delete otherFurniture.revitMetaData.elementId;
  }

  otherFurniture.breed = this.breed;
  otherFurniture.teamId = this.teamId;
  otherFurniture.elementId = this.elementId;
  otherFurniture.cost = this.cost;
  otherFurniture.familyName = this.familyName;
};

/**
 * Remove a Furniture from level.
 *
 * @param      {<type>}  object  The object
 */
Furniture.prototype.removeFurnitureFromLevel = function (level) {
  // if (store.scene.lights[2].includedOnlyMeshes.length === 0) {
  //   store.scene.lights[2].intensity = 0;
  // }

  meshObjectMapping.deleteMapping(this.mesh);
  for (let i = 0; i < level.flyweight.furnitures.length; i++) {
    if (this.mesh.uniqueId === level.flyweight.furnitures[i].mesh.uniqueId) {
      level.flyweight.furnitures.splice(i, 1);
      return;
    }
  }
};
Furniture.prototype.updateBase = function (heightDifference) {
  function isOfBalconyHeightOrLess() {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;
    return (
      boundingBox.extendSizeWorld.y * 2 <=
      StoreyMutation._CONSTANTS.balconyHeight
    );
  }

  if (!this.mesh.parent) {
    // if (!(!isOfBalconyHeightOrLess.call(this) && this.isEdited())) {
    //     this.mesh.position.y += heightDifference;
    // }
    if (this.storey > 0) {
      this.mesh.position.y += heightDifference;
    } else {
      this.mesh.position.y -= heightDifference;
    }
  }
};
/**
 * Make object visible in store.scene
 */
Furniture.prototype.show = function () {
  this.mesh.isVisible = true;
};
/**
 * Hides object in store.scene
 */
Furniture.prototype.hide = function () {
  this.mesh.isVisible = false;
};
/**
 * Notifies all.
 */
Furniture.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
Furniture.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
Furniture.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};
Furniture.prototype.notifyAll = function (action) {};

Furniture.prototype.getMeshObjectPropertiesMap = function() {
  return Object.fromEntries(this.getMeshObjectProperties().map(prop => [prop['name'].toLowerCase(), prop.getValue()]));
}

Furniture.prototype.getMeshObjectProperties = function () {
  let props = [];
  let meshName = this.mesh.name;
  meshName = meshName.replace(/Ins[0-9]+/g, "");

  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);
  props.push(
    new SnapProperty(
      "Category",
      "Furniture",
      SnapElement.getInputElement(null, false)
    )
  );
  let type;
  if(this.revitMetaData?.elementId && this.revitMetaData?.type){
    type = this.revitMetaData?.type
  }else{
    type = meshName
  }
  props.push(
    new SnapProperty(
      "Type",
      type,
      SnapElement.getInputElement(null, false)
    )
  );
  if(this.revitMetaData?.family || this.familyName){
    props.push(
      new SnapProperty(
        "Family",
        this.revitMetaData?.family || this.familyName,
        SnapElement.getInputElement(null, false)
      )
    );
  }
  const subElementsData = this.revitMetaData?.elements?.filter(element => !(element?.type == this.revitMetaData?.type && element?.family == this.revitMetaData?.family))
  let subElements = subElementsData?.map(element => element.type);
  const subElementsSet = new Set(subElements);
  if(subElementsSet.size > 0) {
    props.push(
      new SnapProperty(
        "Objects in Group",
        Array.from(subElementsSet),
        SnapElement.getInputElement(
          null,
          false
        )
      )
    );
  }
  
  props.push(
    new SnapProperty(
      "Label",
      this.mesh.room_type,
      SnapElement.getInputElement(
        (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
        true
      )
    )
  );
  props.push(
    new SnapProperty(
      "Length",
      meshDimensions.depth,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange(
            "thickness",
            value,
            meshDimensions.depth
          ),
        true
      )
    )
  );
  props.push(
    new SnapProperty(
      "Width",
      meshDimensions.breadth,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange(
            "width",
            value,
            meshDimensions.breadth
          ),
        true
      )
    )
  );
  props.push(
    new SnapProperty(
      "Height",
      meshDimensions.height,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange(
            "height",
            value,
            meshDimensions.height
          ),
        true
      )
    )
  );
  props.push(
    new SnapProperty(
      "Location",
      this.mesh.room_type,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Snap to",
      this.snapTo,
      SnapElement.getDropDown(
        ["floor", "wall", "none"],
        (meshes, name) => objectPropertiesView.onTypeChange(this.mesh, name),
        true
      )
    )
  );
  props.push(new SnapProperty("object-buttons", this.mesh, null, true));

  return props;
};

Furniture.prototype.clipAtStorey = function (height, clear=false) {
  if (isMeshThrowAway(this.mesh)) return;
  if (!this.importType) return;

  let mesh = this.mesh.sourceMesh || this.mesh;
  mesh.onBeforeRenderObservable.clear();
  mesh.onAfterRenderObservable.clear();
  if (!clear) {
    mesh.onBeforeRenderObservable.add(() => {
      store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, -height);
    });
    mesh.onAfterRenderObservable.add(() => {
      store.scene.clipPlane = null;
    });
  }
}

Furniture.isMeshFurniture = function (mesh) {
  return mesh.type.toLowerCase().includes("furniture");
}

Furniture.prototype.handleRevitFurnitureSetOnReload = function(offset){
  this.mesh.setPivotMatrix(
    BABYLON.Matrix.Translation(offset[0], offset[1], offset[2]),
    false
  );
}

Furniture.prototype.addTeamMetaData = function(teamId, elementId, cost, familyName){
  this.breed = 'team';
  this.teamId = teamId;
  this.elementId = elementId;
  this.cost = cost;
  this.familyName = familyName;
}

export { Furniture };
