import React, { useState } from "react";
import Button from "../../../components/Button";
import { Content, Controls, Footer, Header, MainContainer, Nav, NavItem, Title } from "../styles";
import Furnitures from "./Furnitures";
import Materials from "./Materials";

// const TABS = ["Materials", "Doors", "Windows", "Furniture", "Files"];
const TABS = ["Materials", "Furniture"];

function Libraries(){
    const [activeTab, setActiveTab] = useState(TABS[0]);
    
    return (
        <MainContainer>
            <Header>
                <Title>Libraries</Title>
                {/* <Controls></Controls> */}
            </Header>
            <Nav>
                {
                    TABS.map(tab => {
                        return <NavItem key={tab} isActive={activeTab === tab} onClick={() => {setActiveTab(tab)}}>{tab}</NavItem>
                    })
                }
            </Nav>
            <Content>
                {activeTab === "Materials" && <Materials />}
                {activeTab === "Furniture" && <Furnitures />}

            </Content>
            <Footer>
                <Button outline={true} title={"Upload new materials"} onPress={() => {alert("Upload new materials")}} />
                <Button outline={true} title={"Add new material type"} onPress={() => {alert("Add new material type")}} style={{borderColor: "#2D2D2E", color: "#2D2D2E"}} />
                <Button outline={true} title={"Add new property"} onPress={() => {alert("Add new property")}} style={{borderColor: "#2D2D2E", color: "#2D2D2E"}} />
            </Footer>
        </MainContainer>
    )
}

export default Libraries;