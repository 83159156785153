import React from 'react'
import styled from 'styled-components'
import close from '../../assets/icons/close.svg';

const Container = styled.div`
  // border: 2px solid crimson;
  width: 95%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`
const Row = styled.div`
  // border: 2px solid lightblue;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: baseline;
  justify-content: flex-start;
  overflow-x: auto;
`
const Col = styled.div`
  // border: 2px solid cadetblue;
  background: url(${props => props.preview});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 65px;
  height: 65px;
  margin: 10px;
  position: relative;
`
const CancelButton = styled.img`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 30%;
  cursor: pointer;
`;

const FileName = styled.p`
  text-align: center;
`;

function MultipleUploadOverview({files, handleCancelFile}) {
  return (
    <Container>
      <Row>
        {files.map((fileData, index) => {
          return <div>
            <Col preview={fileData.preview}>
              <CancelButton src={close} alt="cancel" onClick={() => {handleCancelFile(index)}} />
            </Col>
            <FileName title={fileData.file.name}> {fileData.file.name.substring(0, 5) + "..." } </FileName>
          </div>
          
        })}
      </Row>
    </Container>
  )
}

export default MultipleUploadOverview
