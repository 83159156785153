import _ from "lodash";
import { convertLocalCoordsToGlobal,localiseThePointsForMeshGeneration } from "../extrafunc.js";
import { csgAPI } from "../../libs/apiCalls/backendCalls.js";
import { generateBrepFromPositionsAndCells,generateMeshFromBrep,verifyBRepIntegrity } from "../../libs/brepOperations.js";

const snapmda = window.snapmda;

var csgOperator = (function () {
  let _CONSTANTS = {
    unionID: "union",
    intersectionID: "intersection",
    differenceID: "difference",
  };

  let meshesInput = null;

  /**
   *
   * @param {[]} meshes
   * @param {[]} breps
   */
  function _formatMeshes(meshes, breps, options = {}) {
    let returnObjects = [];
    if (!options.roundThreshold) options.roundThreshold = 5;

    breps.forEach((brep, i) => {
      let mesh = meshes[i];
      let returnObject = {
        positions: [],
        cells: [],
      };

      mesh.computeWorldMatrix(true);
      returnObject.positions = convertLocalCoordsToGlobal(
        brep.getPositions(),
        mesh.getWorldMatrix()
      );
      for (let i = 0; i < returnObject.positions.length; i++) {
        for (let j = 0; j < returnObject.positions[i].length; j++) {
          returnObject.positions[i][j] = _.round(
            returnObject.positions[i][j],
            options.roundThreshold
          );
        }
      }

      let faces = brep.getFaces();

      faces.forEach((face) => {
        let faceIndices = snapmda
          .FaceVertices(face)
          .map((vertex) => vertex.index);
        returnObject.cells.push(faceIndices);
      });

      returnObjects.push(returnObject);
    });

    return JSON.stringify(returnObjects);
  }

  function formatMeshes(meshes, breps, options) {
    return _formatMeshes(meshes, breps, options);
  }

  function _callAPI(operation, meshesFormatted, options) {
    return csgAPI(operation, meshesFormatted, options);
  }

  function _sanitizeOutput(brepPrecursor) {
    const _almostEquals = function (position1, position2, threshold = 1e-1) {
      let distanceBetweenPoints =
        ((position2[0] - position1[0]) ** 2 +
          (position2[1] - position1[1]) ** 2 +
          (position2[2] - position1[2]) ** 2) **
        0.5;

      return distanceBetweenPoints <= threshold;
    };

    let positions = brepPrecursor.positions;
    let cells = brepPrecursor.cells;

    let indexToRemove = NaN;
    positions.some((position1, i) => {
      let toBreak = false;
      positions.some((position2, j) => {
        if (j <= i) return;

        if (_almostEquals(position1, position2)) {
          indexToRemove = j;
          toBreak = true;
          return true;
        }
      });

      if (toBreak) return true;
    });

    if (!isNaN(indexToRemove)) {
      positions.splice(indexToRemove, 1);

      cells.forEach((cell) => {
        _.pull(cell, indexToRemove);
        cell.forEach((vertexIndex, i) => {
          if (vertexIndex > indexToRemove) {
            cell[i] = vertexIndex - 1;
          }
        });
      });

      _.remove(cells, (cell) => {
        return cell.length < 3;
      });

      _sanitizeOutput(brepPrecursor);
    }
  }

  function _generateMeshFromBRepPrecursor(brepPrecursor, options) {
    // _sanitizeOutput(brepPrecursor);

    brepPrecursor.cells.map((cell) => _.reverse(cell));

    let lengthBrepCells = brepPrecursor.cells.length;
    let brepCells = [];
    for (let i = 0; i < lengthBrepCells; i++) {
      brepCells.push([brepPrecursor.cells[i]]);
    }

    let mesh, brep;
    if (options.keepGlobalCoordinates) {
      brep = generateBrepFromPositionsAndCells(
        brepPrecursor.positions,
        brepCells
      );
      mesh = generateMeshFromBrep(brep);
    } else {
      let { points, centre } = localiseThePointsForMeshGeneration(
        brepPrecursor.positions,
        false
      );
      brepPrecursor.positions = points;
      brep = generateBrepFromPositionsAndCells(
        brepPrecursor.positions,
        brepCells
      );
      mesh = generateMeshFromBrep(brep);
      // mesh.type = meshesInput[0].type;
      mesh.setAbsolutePosition(centre);
    }

    if (!verifyBRepIntegrity(brep)) return null;

    return { mesh: mesh, brep: brep };
  }

  /**
   *
   * @param {Array} meshes Meshes to Union
   * @param {Array} breps BRep of the Meshes
   * @param {function()} options Options
   */
  function union(meshes, breps, options) {
    if (meshes == null || breps == null) {
      return false;
    }
    if (meshes.length !== breps.length) {
      return false;
    }
    meshesInput = meshes;
    let meshesFormatted = _formatMeshes(meshesInput, breps, options);

    _callAPI(_CONSTANTS.unionID, meshesFormatted, options);
  }

  /**
   *
   * @param {Array} meshes Meshes to Union
   * @param {Array} breps BRep of the Meshes
   * @param options
   *
   * returns the resultant mesh wrapped in a a promise
   */
  function promisifiedUnion(meshes, breps, options = {}) {
    meshesInput = meshes;
    let meshesFormatted;
    if (options.meshesFormatted) {
      meshesFormatted = meshes;
    } else {
      if (!meshes || !breps) {
        return Promise.reject();
      }
      if (meshes.length !== breps.length) {
        return Promise.reject();
      }
      meshesFormatted = _formatMeshes(meshesInput, breps, options);
    }

    return _callAPI(_CONSTANTS.unionID, meshesFormatted, options);
  }

  function intersection(meshes, breps, options) {
    if (meshes == null || breps == null) {
      return false;
    }
    if (meshes.length !== breps.length) {
      return false;
    }
    meshesInput = meshes;
    let meshesFormatted = _formatMeshes(meshesInput, breps, options);

    _callAPI(_CONSTANTS.intersectionID, meshesFormatted, options);
  }

  function difference(meshes, breps, options) {
    if (meshes == null || breps == null) {
      return false;
    }
    if (meshes.length !== breps.length) {
      return false;
    }
    meshesInput = meshes;
    let meshesFormatted = _formatMeshes(meshesInput, breps, options);

    _callAPI(_CONSTANTS.differenceID, meshesFormatted, options);
  }

  function promisifiedDifference(meshes, breps, options = {}) {
    meshesInput = meshes;
    let meshesFormatted;
    if (options.meshesFormatted) {
      meshesFormatted = meshes;
    } else {
      if (!meshes || !breps) {
        return Promise.reject();
      }
      if (meshes.length !== breps.length) {
        return Promise.reject();
      }
      meshesFormatted = _formatMeshes(meshesInput, breps, options);
    }

    return _callAPI(_CONSTANTS.differenceID, meshesFormatted, options);
  }

  function apiReturnHandler(brepPrecursor, options) {
    return _generateMeshFromBRepPrecursor(brepPrecursor, options);
  }

  return {
    union,
    promisifiedUnion,
    intersection,
    difference,
    promisifiedDifference,
    formatMeshes,
    apiReturnHandler,
  };
})();
export { csgOperator };
