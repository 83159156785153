import styled from "styled-components";
import { colors } from "../../themes/constant";

export const TopBarMainContainer = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 4.00278px 30.0208px ${colors.lightGreyShadow};
  position: sticky;
  z-index: 3;
  top: 0;
  left: 0;
  height: 2.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${colors.snowTint};
    width: 7.5em;
    box-shadow: 0px 8.45143px 19.5033px ${colors.shadowGrey};
    z-index: 1;
    border-bottom-left-radius: 0.625em;
    border-bottom-right-radius: 0.625em;
    margin-left: -0.5em;
    padding: 0.5em 0 0.25em 0;
  }

  .dropdown-content p {
    font-family: ${(props) => props.theme.font};
    font-size: 10px;
    display: flex;
    align-items: center;
    letter-spacing: -0.224153px;
    color: ${colors.thinGray};
    padding: 0.35em 0.5em 0.5em 0.35em;
    cursor: pointer;
    margin: 0 0.25em;
    border-radius: 2px;
  }

  .dropdown-content p:hover {
    background-color: ${colors.whiteSmoke};
    color: ${colors.primeBlack};
  }

  .visible-dropdown-content {
    display: block;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    margin-left: 0.5em;
  }
  .dropButton {
    display: flex;
    justify-content: space-between;
  }
  .dropButtonImg {
    height: 0.5em;
    width: 0.625rem;
    margin-left: 0.25em;
  }
  .dropbtn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .red-dot::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${colors.red};
    position: absolute;
    top: 0;
    right: -5px;
  }
  
  .red-dot-blink::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${colors.red};
    position: absolute;
    top: -5px;
    right: -5px;
    animation: blink-animation 1s steps(5, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 60%;
`;

export const RightMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  .rightMenuIcon {
    width: 1.38em;
    height: 1.35em;
  }
  .selected_icon {
    width: 1.38em;
    height: 1.35em;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  .top_right_icon {
    margin: 0 0.5rem;
  }
  .export_button {
    padding-left: 0.5rem;
  }
`;

export const Divider = styled.div`
  border: 0 solid rgba(45, 45, 46, 0.2);
  border-right-width: 1.50104px;
  height: 1em;
  width: 0.5em;
`;

export const ProjectName = styled.span`
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
`;

export const ProjectNameLink = styled.a`
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  text-decoration: none;

  ${({ width }) => width && `
    width: ${width};
  `}
`;

export const ProjectNameInput = styled.input`
  font-family: ${(props) => props.theme.font};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
`;

export const MenuOptionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  margin: 0 0.375rem;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  .topmenuicon {
    padding: 0.25rem;
    border: 0.125rem;
  }
  .selectedMenuContainer {
    border-radius: 0.125rem;
    border: 0 solid ${colors.borderRed};
    border-bottom-width: 3px;
    padding-top: 0;
    height: 100%;
    span {
      color: ${colors.primeBlack};
      font-weight: bold;
    }
    &:hover {
      background: ${colors.transparent};
    }
  }
`;

export const MenuOption = styled.span`
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333565px;
  color: ${colors.thinGray};
  // margin-left: 0.25rem;
  padding: 5px 10px;
`;

export const MenuDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  // width: 4.1875rem;
  align-items: center;
  border-radius: 3px;
  margin-right: 0.1875rem;
  &:hover {
    background: ${colors.white};
    span {
      color: ${colors.primeBlack};
      font-weight: bold;
    }
  }
`;

export const ImageButton = styled.div`
  position: relative;
  border: none;
  background: ${colors.fullWhite};
  cursor: pointer;
  border-radius: 8px;
  // margin: ${(props) => props.isDropdown ? "0em" : "0.45em"};
  margin: 0.45em;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    border: none;
    background: ${colors.white};
  }
  .rightMenuIcon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1875rem;
  }
  .topMenuIcon {
    width: ${(props) => props.isDropdown ? "0.75em" : "1.25em"};
    height: ${(props) => props.isDropdown ? "0.75em" : "1.25em"};
    padding: 0.1875rem;
  }
  .selected_icon {
    width: ${(props) => props.isDropdown ? "0.75em" : "1.25em"};
    height: ${(props) => props.isDropdown ? "0.75em" : "1.25em"};
    padding: 0.1875rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  .selected_icon_top {
    width: ${(props) => props.isDropdown ? "0.75em" : "1.25em"};
    height: ${(props) => props.isDropdown ? "0.75em" : "1.25em"};
    padding: 0.1875rem;
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  .highlight {
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1875rem;
  }
  &.show-notification-dot::after{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${colors.brightRed};
  }
`;

export const ColumnView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  // width: 4.5625rem;
  justify-content: center;
`;

export const SubMenu = styled.div`
  position: absolute;
  top: 2.84em;
  background: ${colors.fullWhite};
  border: 1px solid ${colors.fullWhite};
  box-sizing: border-box;
  box-shadow: 0px 13px 30px ${colors.shadowGrey};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 4em;
  min-height: 2.55em;
  flex-wrap: wrap;
  padding: 0.1875rem;
  .subMenuImageButton {
    margin: 0.4375rem;
    padding: 0;
    border-radius: 3px;
  }
`;

export const SubMenuDropDown = styled.div`
  position: absolute;
`;

export const TopMenuImg = styled.img`
  background-color: transparent;
  filter: ${props => props.active ? "invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg) brightness(93%) contrast(98%)" : ""};
`

export const CustomLink = styled.a`
  text-decoration: none;
  padding: 0 1em;
  color: ${colors.primeBlack};
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
`

export const TitleText = styled.span`
  text-decoration: none;
  padding: 0 1em;
  color: ${colors.primeBlack};
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
`