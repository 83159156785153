import BABYLON from "../../babylonDS.module.js";
import _, { update } from "lodash";
import {store} from "../../utilityFunctions/Store.js";
import {StateMachine} from "../../Classes/StateMachine.js";
import {colorUtil} from "../../utilityFunctions/colorUtility.js";
import {
  areTwoMeshesCloseBy,
  checkOperationBIMFlowConditions,
  isMeshCurved,
  isMeshOrphanProof,
  isPointInTheVicinityOfMesh,
  setIsModifiedAndGetCommand,
  updateCSG,
} from "../../extrafunc.js";
import {GLOBAL_CONSTANTS} from "../../utilityFunctions/globalConstants.js";
import {findPrioritizedSnapPoint, findSecondarySnappedPoint,} from "../../../libs/snapFuncsPrimary.js";
import {
  disposeAllAxisLines,
  disposeHighlightedFace,
  disposeHighlightedVertex,
  disposeHightlightedEdge,
  disposeSnapToObjects,
  handleVisibilityChanges, isMeshDW, isMeshDWF, isMeshDWIndicator, updatePosition,
} from "./moveUtil.js";
import {uiIndicatorsHandler} from "../../uiIndicatorOperations/uiIndicatorsHandler.js";
import {delayedExecutionEngine} from "../../utilityFunctions/delayedExecution.js";
import {drawSelectionBoxRotate} from "../../../libs/meshEvents.js";
import {
  areEdgesSame,
  getDynamicThreshold,
  getFaceAreaFactor, getUnitNormalToEdge, handleOptionsForAlternateSnaps,
  initializeSnappingEngine, isCADMesh,
  turnOffSnappingEngine,
} from "../../../libs/snapUtilities.js";
import {getAllSketchMeshesFromCADLayer, undoRedoForMoveCAD,} from "../../cadImporter/cadServices.js";
import {commandUtils} from "../../commandManager/CommandUtils.js";
import {DisplayOperation} from "../../displayOperations/displayOperation.js";
import {moveVertex} from "./moveVertex.js";
import {moveEdge} from "./moveEdge.js";
import {moveFace} from "./moveFace.js";
import {StoreyMutation} from "../../storeyEngine/storeyMutations.js";
import {StructureCollection} from "../../snaptrudeDS/structure.ds.js";
import {virtualSketcher} from "../../sketchMassBIMIntegration/virtualSketcher.js";
import {CommandManager} from "../../commandManager/CommandManager.js";
import {keypadController} from "../../../libs/keyboardView.js";
import {scenePickController} from "../../utilityFunctions/scenePickController";
import {getRefGroundPoint, projectionOfPointOnFace} from "../../../libs/snapFuncs";
import snapEngine from "../../snappingEngine/snapEngine";
import movementConstrainer from "./movementConstrainer";
import {is2D} from "../../../libs/twoDimension";
import { updateCursor } from "../../../../containers/editor/cursorHandler.js";
import { cursor } from "../../../../themes/cursor.js";
import stackedWallHelper from "../../stackedWalls/stackedWallHelper";
import { Locker } from "../../locker/locker.js";

const moveOperator = (function () {

  /*
  A note about the EVENTS-

  The sequences in move are a bit more complicated compared to other features because it supports
  both single click and double click

  In the move object mode, the flow is simple 0 -> 1 -> 2 -> 3 -> 4
  In the move vertex/edge/face mode, it's 0 -> 1 -> 2 -> 4 -> 3 -> 4

  So, event 4 gets fired after both pointerdowns and it behaves differently each time
  It should've been a different event (#2.5) ideally but there's a complication in event firing sequence.
  After each down, a move gets fired and hence 2 -> 2.5 through a pointerdown will never happen

  Adding a standalone edit mode which edits objects with a single click
  That's handled through a separate STATE_MACHINE operating on the same EVENTS
  We just pretend like the first click is the second click and everything runs like butter

   */
  let _EVENTS = {
    0: cleanUp,
    1: _initialGripCreation,
    2: _prepareDataForMoveMesh,
    3: _moveMeshOrVertexOrEdgeOrFace,
    4: _postMoveOperations,
  };

  let STATE_MACHINE;

  const _CONSTANTS = {
    operatingSpace: null,
    preSnapMaterial: null,
    preSnapMaterialNoEdit: null,
    moveMeshOperation: "moveMesh",
    moveVertexOperation: "moveVertex",
    moveEdgeOperation: "moveEdge",
    moveFaceOperation: "moveFace",
    furnitureBound: "boxScaleFurniture",
    displayBoxName: "distBox",
    operatingModes: {
      move: 1,
      edit: 2
    },
    vertexIndicator: "vertexIndicator",
    edgeIndicator: "edgeIndicator",
    faceIndicator: "faceBox",
  };

  const init = () => {
    _CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
    _CONSTANTS.preSnapMaterial = colorUtil.getMaterial(colorUtil.type.preSnap);
    _CONSTANTS.preSnapMaterialNoEdit = colorUtil.getMaterial(colorUtil.type.nonEditable);
  }

  let _operatingMode = null; // move or edit

  let _meshOfInterest = null;
  let _componentOfInterest = null;
  let _meshesOfInterestArray = [];
  let _componentsOfInterest = [];
  let _meshWithGrips = null;
  let _meshOfInterestInitialPosition = null;
  let _meshOfInterestInitialClickPoint = null;
  let _meshOfInterestOnMovePoint = null;

  let _meshWithNoRestriction = null;
  let _meshWithNoRestrictionInitialPosition = null;

  let _commandData = null;

  let _modeOfOperation = null;
  let _componentSelected = {};
  let _firstClickTime = null;
  let _selectionMetadata = null;
  let _editsNotAllowed = false;
  let _meshMoved = false;
  let _potentialParentChange = false;
  let _potentialNewParent = false;
  let _snapMetadata = null;

  let _currentPick;
  

  let BIMFlowCheckObject = {
    operation: "freeMoveEdit",
    mesh: null,
  };

  let _initialMovementThreshold = 1;

  function setOperatingMode(mode) {
    _operatingMode = mode;

    if (_operatingMode === _CONSTANTS.operatingModes.move){
      let mouseSequence = {
        pointermove: {
          0: [1, 1, 0],
          1: [1, 1, 0],
          2: [3, 3, 0],
          3: [3, 3, [0, 0, 0]],
        },

        pointerdown: {
          1: [2, 2, 0],
          2: [4, NaN, 0], //NaN implies state value doesn't change
          3: [4, NaN, [0, 0, 0]],
        },
      };

      let touchSequence = {
        pointermove: {
          2: [3, 3, 0],
          3: [3, 3, [0, 0, 0]],
        },

        pointerdown: {
          0: [1, [2, 2, 0], 0],
          4: [1, 1, 0],
        },

        pointerup: {
          3: [4, NaN, [0, 0, 0]],
        },
      };

      STATE_MACHINE = new StateMachine(_EVENTS, mouseSequence, touchSequence);
    }
    else if (_operatingMode === _CONSTANTS.operatingModes.edit){
      let mouseSequence = {
        pointermove: {
          0: [1, 1, 0],
          1: [1, 1, 0],
          3: [3, 3, [0, 0, 0]],
        },

        pointerdown: {
          1: [2, [4, 3, 0], 0],
          3: [4, 0, [0, 0, 0]],
        },
      };

      let touchSequence = {
        pointermove: {
          3: [3, 3, [0, 0, 0]],
        },

        pointerdown: {
          0: [1, [2, [4, 3, 0], 0], 0],
        },

        pointerup: {
          3: [4, 0, [0, 0, 0]],
        },
      };

      STATE_MACHINE = new StateMachine(_EVENTS, mouseSequence, touchSequence);
    }
  }

  function getOperatingModes() {
    return _CONSTANTS.operatingModes;
  }

  function _initialGripCreation(pickSnapData) {
    function _displayPrioritizedGrip(pickInfo) {
      function _areEditsAllowed() {
        let data = options.metadata;

        BIMFlowCheckObject = {
          operation: "freeMoveEdit",
          mesh: data.pickInfo.pickedMesh,
        };

        if (data.snappedToVertex) {
          BIMFlowCheckObject.mode = "vertex";
          BIMFlowCheckObject.vertex = data.snappedToVertex;
        } else if (data.snappedToEdge) {
          BIMFlowCheckObject.mode = "edge";
          BIMFlowCheckObject.edge = data.snappedToEdge;
        } else if (data.snappedToFace) {
          BIMFlowCheckObject.mode = "face";
          BIMFlowCheckObject.face = data.snappedToFace;
        }

        if (
          isMeshCurved(BIMFlowCheckObject.mesh) ||
          BIMFlowCheckObject.mesh.type === "staircase" ||
          /*(BIMFlowCheckObject.mesh.type.toLowerCase() === "mass" &&
            BIMFlowCheckObject.mesh.getSnaptrudeDS().massType === "Plinth") ||*/
          BIMFlowCheckObject.mesh.type ===
            GLOBAL_CONSTANTS.strings.identifiers.cadSketch ||
          isMeshDWF(BIMFlowCheckObject.mesh) ||
          isMeshDWIndicator(BIMFlowCheckObject.mesh)
        ) {
          // edits on curved objects disabled in both massing and BIM projects
          return false;
        }

        return checkOperationBIMFlowConditions(BIMFlowCheckObject);
      }

      _disposeGrips();

      let options = {
        material: _CONSTANTS.preSnapMaterial,
        faceSnap: true,
        wantMetadata: true,
        firstIndicators: true,
        pickInfo: pickInfo,
        storeyCheck: true,
        showRelatedEdges: true,
        considerOnlySelectedMeshes: true,
        attemptCadSnaps: true,
      };
      
      if (isCADMesh(pickInfo.pickedMesh)) options.pickInfo = null;

      let snaptrudeDS = pickInfo.pickedMesh.getSnaptrudeDS(false);
      if (!!snaptrudeDS && snaptrudeDS.type.toLowerCase() === "mass" && snaptrudeDS.isCircularMass()) {
        options.showRelatedEdges = false;
      }

      if (store.$scope.isTwoDimension && _operatingMode === _CONSTANTS.operatingModes.edit){
        options.faceSnap = false;
      }
      
      handleOptionsForAlternateSnaps(pickSnapData, options);

      try {
        let snappedPoint = findSecondarySnappedPoint(null, null, null, options);

        if (snappedPoint) {
          if (
            store.selectionStack.length &&
            pickInfo.pickedMesh.name.includes("twoPlane")
          ) {
            // original pick was twoPlane, so have to check if this new pickedMesh
            // is present in store.selectionStack or not
            if (
              !store.selectionStack.includes(
                options.metadata.pickInfo.pickedMesh
              )
            ) {
              _disposeGrips();
              return Promise.reject();
            }
          }

          pickInfo = options.metadata.pickInfo;
          //because if overlapping masses were handled, pickInfo would be different from what was passed

          _currentPick = pickInfo;

          if (!_areEditsAllowed()) {
            _editsNotAllowed = true;
            if (options.metadata.indicator) {
              _markAsUneditable(options.metadata.indicator);
            }
          } else _editsNotAllowed = false;

          _selectionMetadata = options.metadata;
          _meshOfInterestInitialClickPoint = snappedPoint;
          _meshWithGrips = pickInfo.pickedMesh;
          if(store.ACTIVE_EVENT.event === "editObject" && _selectionMetadata.indicator){
            if(_selectionMetadata.indicator.name === "edgeIndicator") updateCursor(cursor.pushpullEdit);
            else if(_selectionMetadata.indicator.name === "vertexIndicator") updateCursor(cursor.move);
            else if(_selectionMetadata.indicator.name === "faceBox") updateCursor(cursor.pushpull);
            else {
              console.log(_selectionMetadata.indicator.name);
            }
          }
          

          return Promise.resolve();
        }

        return Promise.reject();
      } catch (e) {
        console.error(e);
        return Promise.reject();
      }
    }

    delayedExecutionEngine.executeAll();
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      /* eslint-enable */

      let pickInfo;

      if (pickSnapData){
        pickInfo = pickSnapData.pickInfo;
      }
      else {
  
        const requests = [{
          predicate: (mesh) => {
            return mesh.name === _CONSTANTS.furnitureBound;
          }
        }];
        
        const primaryRequest = {
          predicate: null,
        };
        
        if (_operatingMode === _CONSTANTS.operatingModes.move){
          primaryRequest.includeVoids = true;
          primaryRequest.includeFloorPlans = true;
        }
        
        requests.push(primaryRequest);
  
        if (is2D()){
          
          const requestForPickingDWIndicator = {
            pickDWIndicators : true
          };
          
          const requestForPickingHiddenDW = {
            pickAllFromVisibleList : true,
            predicate : mesh => ["door", "window"].includes(mesh.type.toLowerCase())
          };
          
          const requestForPickingCADBoundingInfo = {
            pickWithBoundingInfo: true,
            predicate: mesh => isCADMesh(mesh),
          }
          
          requests.unshift(
            requestForPickingDWIndicator,
            // requestForPickingHiddenDW,
          );
          
          requests.push(requestForPickingCADBoundingInfo);
          
          /*
          Seems like the first request is sufficient
          Keeping the second just in case a d/w doesn't have indicators
          
          UPDATE -
          Removing d/w pick, causing weird cases but offers no advantage
          
          UPDATE -
          Adding pick for CAD files
           */
        }
        
        pickInfo = scenePickController.sequentialPick(requests);

        snapEngine.alternateSnaps.invalidate();
      }

      if (pickInfo.hit) {
        if (isMeshDWIndicator(pickInfo.pickedMesh)){
          const correspondingDW = pickInfo.pickedMesh.parent;
          
          // since pickWithBoundingInfo is used, pickInfo.pickedPoint will not be present
          // so all we know is if the indicator is picked, not where
          
          if (!pickInfo.pickedPoint) pickInfo.pickedPoint = correspondingDW.getAbsolutePosition().clone();
          pickInfo.pickedMesh = correspondingDW;
        }
        
        if (
          store.selectionStack.length &&
          !pickInfo.pickedMesh.name.includes("twoPlane")
        ) {
          // if twoPlane isn't excluded, compoundPick doesn't happen in recursiveSnap in
          // findSecondarySnappedPoint. So picking vertex becomes difficult
          if (!store.selectionStack.includes(pickInfo.pickedMesh)) {
            //can't select something not in selection stack
            _disposeGrips();
            reject();
            return;
          }
        } else {
          if (pickInfo.pickedMesh.type.toLowerCase() === "furniture") {
            _disposeSelectionBox();
            let boundingBox = drawSelectionBoxRotate(pickInfo.pickedMesh);
            boundingBox.name = _CONSTANTS.furnitureBound;
            boundingBox.type =
              GLOBAL_CONSTANTS.strings.identifiers.boundingBox;
            // boundingBox.parent = pickInfo.pickedMesh;
          }
        }

        await _displayPrioritizedGrip(pickInfo).then(
          () => {
            _initialMovementThreshold = getDynamicThreshold(
              _currentPick.pickedPoint,
              getFaceAreaFactor(_currentPick)
            );
            _meshOfInterestOnMovePoint =
              _meshOfInterestInitialClickPoint.clone();
            resolve();
          },
          () => {
            reject();
          }
        );
      } else {
        _disposeGrips();
        _disposeSelectionBox();
        reject();
      }
    });
  }

  function _prepareDataForMoveMesh() {
    const _setComponentSelected = function (metadata) {
      if (!metadata) return;

      _componentSelected = {};

      if (metadata.snappedToVertex) {
        _componentSelected[_CONSTANTS.moveVertexOperation] =
          metadata.snappedToVertex;
      } else if (metadata.snappedToEdge) {
        _componentSelected[_CONSTANTS.moveEdgeOperation] =
          metadata.snappedToEdge;
      } else if (metadata.snappedToFace) {
        _componentSelected[_CONSTANTS.moveFaceOperation] =
          metadata.snappedToFace;
      }
    };

    return new Promise((resolve, reject) => {
      let operationOnChild = false;

      if (_meshWithGrips.name === _CONSTANTS.furnitureBound) {
        _meshOfInterest = _meshWithGrips.parentMesh;
      } else _meshOfInterest = _meshWithGrips;

      _componentOfInterest = _meshOfInterest.getSnaptrudeDS();

      _setComponentSelected(_selectionMetadata);

      const notMultiSelection = store.selectionStack.length === 0 || store.selectionStack.length === 1;
      if (notMultiSelection) {
        if (
          _meshOfInterest.type ===
          GLOBAL_CONSTANTS.strings.identifiers.cadSketch
        ) {
          _meshesOfInterestArray = getAllSketchMeshesFromCADLayer(
            _meshOfInterest.layerName,
            store.activeLayer.structure_id,
            store.activeLayer.storey
          );
        } else if (
          _meshOfInterest.type.toLowerCase() === "mass" &&
          _meshOfInterest.getSnaptrudeDS().massType === "Plinth"
        ) {
          _meshesOfInterestArray = [];
          reject();
          return;
        } else {
          if (stackedWallHelper.isPartOfStackedWall(_meshOfInterest)){
            const companion = stackedWallHelper.getCompanionWall(_meshOfInterest);
            if (companion) _meshesOfInterestArray = [_meshOfInterest, companion];
            else _meshesOfInterestArray = [_meshOfInterest];
          }
          else {
            _meshesOfInterestArray = [_meshOfInterest];
          }
        }

        if (_meshOfInterest.parent) {
          operationOnChild = true;
          _potentialParentChange = true;
        }

        if (
          _meshOfInterest.type !==
          GLOBAL_CONSTANTS.strings.identifiers.cadSketch
        ) {
          if (_meshOfInterest.getSnaptrudeDS().dependantMass) {
            // _potentialParentChange = true;
          }
        }

        if (isMeshOrphanProof(_meshOfInterest)){
          
          if (is2D()){
            const wallMesh = _meshOfInterest.parent;
            const wallProfile = wallMesh.getSnaptrudeDS().edgeMidPts();
            const normal = getUnitNormalToEdge(...wallProfile);
            const planeData = {
              position: _meshOfInterest.getAbsolutePosition(),
              normal,
            };
            
            movementConstrainer.init(_componentOfInterest, null, planeData);
            
            // Uncomment when *where* d/w indicator is clicked is determined
            
            /*_meshOfInterestInitialClickPoint = movementConstrainer.util.projectPoint(_meshOfInterestInitialClickPoint);
            _meshOfInterestOnMovePoint = _meshOfInterestInitialClickPoint.clone();*/
          }
          else movementConstrainer.init(_componentOfInterest, _currentPick);
          
        }

      } else {
        _meshesOfInterestArray = store.selectionStack.filter((m) => {
          //return false removes the element
          if (
            m.type.toLowerCase() === "mass" &&
            m.getSnaptrudeDS().massType === "Plinth"
          ) {
            return false;
          } else if (m.parent) {
            if (_.find(store.selectionStack, (mesh) => mesh === m.parent)) {
              // child element whose parent is also selected
              return false;
            } else {
              operationOnChild = true;
              return true;
            }
          } else {
            return true;
          }
        });
        _meshesOfInterestArray = Locker.filterLockedMeshes(_meshesOfInterestArray);
        if (_.isEmpty(_meshesOfInterestArray)) {
          reject();
          return;
        }
      }

      handleVisibilityChanges(_meshOfInterest);

      if (_selectionMetadata.snappedToEdge) {
        // remove mid-point indicator
        uiIndicatorsHandler.vertexIndicator.remove(
          uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator
        );
      }

      if (
        _meshOfInterest.type !== GLOBAL_CONSTANTS.strings.identifiers.cadSketch
      ) {
        let options = {
          stack: _meshesOfInterestArray,
          params: [commandUtils.worldMatrixChangeOperations.PARAMS.position],
          operationOnChild,
        };
        _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
          null,
          options
        );
      }

      _modeOfOperation = _CONSTANTS.moveMeshOperation;
      _firstClickTime = new Date();

      _meshOfInterestInitialPosition = _meshOfInterest
        .getAbsolutePosition()
        .clone();
      _componentsOfInterest = _meshesOfInterestArray.map(m => m.getSnaptrudeDS());

      for (let i = 0; i < _meshesOfInterestArray.length; i++) {
        if (_meshesOfInterestArray[i].type !== "staircase") {
          _meshWithNoRestriction = _meshesOfInterestArray[i];
          break;
        }
      }
      if (!_meshWithNoRestriction) {
        _meshWithNoRestriction = _meshOfInterest;
      }
      _meshWithNoRestrictionInitialPosition = _meshWithNoRestriction
        .getAbsolutePosition()
        .clone();

      initializeSnappingEngine(_meshOfInterestOnMovePoint);
      uiIndicatorsHandler.edgeIndicator.massDestruct();
      // store.newScene.activeCamera.detachControl(canvas);

      _setInitialExecutable();

      resolve();
    });
  }

  function _setInitialExecutable() {
    delayedExecutionEngine.executeAll();

    if (_modeOfOperation === _CONSTANTS.moveMeshOperation) {
      delayedExecutionEngine.addExecutable(_concludeMoveObject);
    }
  }

  function _moveMesh(snappedPoint) {
    /* eslint-disable */
    function _moveIndicators(movementAmount) {
      /* eslint-enable */

      if (BIMFlowCheckObject.vertex) {
        uiIndicatorsHandler.vertexIndicator.moveBy(movementAmount);
      } else if (BIMFlowCheckObject.edge) {
        uiIndicatorsHandler.edgeIndicator.moveBy(movementAmount);
      } else if (BIMFlowCheckObject.face) {
        uiIndicatorsHandler.faceIndicator.moveBy(movementAmount);
      }
    }

    let mainMovementAmount = snappedPoint.subtract(_meshOfInterestOnMovePoint);
    if (store.$scope.isTwoDimension) mainMovementAmount.y = 0;

    // const oldOnMovePoint = _meshOfInterestOnMovePoint.clone();

    if (!_meshMoved){
      let threshold = store.isiPad ? _initialMovementThreshold : 0.1;
      const dynamicThreshold = getDynamicThreshold(snappedPoint);

      if (dynamicThreshold < threshold) threshold = dynamicThreshold;

      if (mainMovementAmount.length() < threshold){
        mainMovementAmount = BABYLON.Vector3.Zero();
        uiIndicatorsHandler.axisIndicator.remove();
        uiIndicatorsHandler.vertexIndicator.remove(uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator);
      }
      else {
        // _meshOfInterestOnMovePoint = snappedPoint;
        _meshMoved = true;
      }
    }
    else {
      // _meshOfInterestOnMovePoint = snappedPoint;
    }

    let movementAmountArray = _.range(0, _meshesOfInterestArray.length).map(
      () => mainMovementAmount.clone());

    let moved = true;

    _componentsOfInterest.forEach((component, i) => {
      let mesh = _meshesOfInterestArray[i];
      let movementAmount = movementAmountArray[i];

      if (component?.onElementMove){
        component.onElementMove(movementAmount);
      }

      if (!checkOperationBIMFlowConditions({
        operation: "freeMoveY",
        mesh
      })) {
        movementAmount.y = 0;
      }

      let optionsForBIMFlow = {
        operation: "freeMoveXZ",
        mesh
      };

      if (_meshesOfInterestArray.length === 1 && !checkOperationBIMFlowConditions(optionsForBIMFlow)) {
        movementAmount = BABYLON.Vector3.Zero();
        if (mesh === _meshOfInterest){
          disposeAllAxisLines();
          disposeSnapToObjects();
        }
      }

      if (mesh === _meshOfInterest) {
        

        if (movementAmount.equals(BABYLON.Vector3.Zero())) moved = false;

        _moveIndicators(movementAmount);
        _meshOfInterestOnMovePoint.addInPlace(movementAmount);
      }

      updatePosition(component, mesh, movementAmount);

    });

    let distanceMoved = BABYLON.Vector3.Distance(
      _meshWithNoRestrictionInitialPosition,
      _meshWithNoRestriction.getAbsolutePosition()
    );

    DisplayOperation.drawOnMove(
      _meshOfInterestInitialPosition,
      _meshOfInterest.getAbsolutePosition(),
      "",
      "",
      ""
    );
    DisplayOperation.displayOnMove(distanceMoved, null, true, {
      onChangeCallback: handleUserInput,
    });

    return moved;
  }

  function _moveMeshOrVertexOrEdgeOrFace() {
    let resolved = false;
    return new Promise((resolve, reject) => {
      if (_modeOfOperation === _CONSTANTS.moveMeshOperation) {
        disposeSnapToObjects();

        let options = {
          excludedMeshes: [
            ..._meshesOfInterestArray,
            ..._meshOfInterest.getChildren(),
          ],
          wantMetadata: true,
          storeyCheck: false,
          /*secondPickRequest: {
            predicate : function (mesh) {
              return mesh.type === GLOBAL_CONSTANTS.strings.identifiers.cadSketch;
            },
            pickInvisibleMeshes : false
          }*/
          attemptCadSnaps: true,
        };

        if (_meshWithNoRestriction.type === "staircase") {
          options.restrictYAxisMovement = true;
        }

        if (_componentOfInterest?.dependantMass || isMeshOrphanProof(_meshOfInterest)) {
          options.parallelFaceSnap = true;
        }
        
        let axisPoint;
        if (is2D() && isMeshDW(_meshOfInterest)){
          axisPoint = _meshOfInterest.getAbsolutePosition();
          // axisPoint = movementConstrainer.util.projectPoint(getRefGroundPoint());
        }

        let snappedPoint = findPrioritizedSnapPoint(
          _meshOfInterestInitialClickPoint,
          axisPoint,
          _meshOfInterest,
          options
        );

        if (snappedPoint) {
          _snapMetadata = options.metadata;
          resolved = _moveMesh(snappedPoint);
        }

        resolve();
      } else if (_modeOfOperation === _CONSTANTS.moveVertexOperation) {
        moveVertex.move();
        resolve();
      } else if (_modeOfOperation === _CONSTANTS.moveEdgeOperation) {
        moveEdge.move();
        resolve();
      } else if (_modeOfOperation === _CONSTANTS.moveFaceOperation) {
        moveFace.move();
        resolve();
      }
    });
  }

  function _concludeMoveObject() {
    let meshesToModify = [];

    if (
      !_.isEmpty(_meshesOfInterestArray) &&
      _meshesOfInterestArray[0].type !==
      GLOBAL_CONSTANTS.strings.identifiers.cadSketch
    ) {
      const _componentsOfInterestArray = _meshesOfInterestArray.map((m) =>
        m.getSnaptrudeDS()
      );
      if (!_meshesOfInterestArray[0].name.includes("terrain")) {
         //Removing "undefined" components
        StoreyMutation.assignStorey(_.compact(_componentsOfInterestArray));
      }

      _meshesOfInterestArray.forEach((m) => {
        let ds = m.getSnaptrudeDS();
        // ds.setIsModified();

        meshesToModify.push(m)

        if (m.childrenComp) {
          for (let childMesh of m.childrenComp) {
            meshesToModify.push(childMesh);
          }
        }

        if (m.name.includes("terrain")) {
          let meshDS = m.getSnaptrudeDS();
          let structure =
            StructureCollection.getInstance().getStructures()[
              meshDS.structure_id
              ];
          let storey = structure
            .getStoreyData()
            .getStoreyByValue(meshDS.storey);
          let layer = storey.layerData.getLayerByName(
            m.layerName,
            meshDS.storey
          );

          layer.terrain[0].parameters.terrainPosition = m.position.asArray();
        }
      });

      let options = {
        data: _commandData,
      };

      _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        null,
        options
      );

      let moveCommand = commandUtils.worldMatrixChangeOperations.getCommand(
        commandUtils.CONSTANTS.moveOperation,
        _commandData
      );

      let multipleCSG = false;
      let previousParent;
      let parentChangeCommand;

      if (_potentialParentChange) {
        if (
          _meshOfInterest.parent &&
          areTwoMeshesCloseBy(_meshOfInterest, _meshOfInterest.parent)
        ) {
          // do nothing, the relationship still holds good
          _potentialNewParent = _meshOfInterest.parent;
        }
        else if (_snapMetadata.pickInfo) {
          if (
            isPointInTheVicinityOfMesh(
              _snapMetadata.pickInfo.pickedPoint,
              _meshOfInterest
            )
          ) {
            _potentialNewParent = _snapMetadata.pickInfo.pickedMesh;
          } else {
            _potentialNewParent = null;
          }
        } else {
          _potentialNewParent = null;
        }

        if (isMeshOrphanProof(_meshOfInterest) && !_potentialNewParent) {
          // left in the air somewhere still attached to a parent
        } else {
          if (_meshOfInterest.parent !== _potentialNewParent) {
            previousParent = _meshOfInterest.parent;
            parentChangeCommand =
              commandUtils.parentChildOperations.expressCheckout(
                "moveParentChange",
                _meshOfInterest,
                _potentialNewParent
              );
            multipleCSG = true;
          }
        }
      }

      let optionsForCSG = {
        multipleCSG,
        previousParent,
      };
      if (_meshOfInterest.parent) {
        optionsForCSG.updatePosition = false;
        optionsForCSG.rePosition =
          _meshOfInterest.parent.absolutePosition.asArray();
        optionsForCSG.reScale =
          _meshOfInterest.parent.absoluteScaling.asArray();
      }

      const integrationGeometryChangeCommand =
        virtualSketcher.updateWithGeometryEdit(_componentsOfInterestArray);

      const isModifiedCommand = setIsModifiedAndGetCommand(meshesToModify);

      let updateCommands = updateCSG(_meshOfInterest, optionsForCSG);

      if (updateCommands) {
        let yetsForCreation = updateCommands.creationCommands.map((c) => false);
        let yetsForDeletion = updateCommands.deletionCommands.map((c) => true);
        let yetsForDll = updateCommands.dllCommands.map((c) => false);
        let yetsForOthers = updateCommands.otherCommands.map((c) => false);

        let nonCSGCommands = _.compact([
          moveCommand,
          parentChangeCommand,
          integrationGeometryChangeCommand,
        ]);
        let nonCSGYets = nonCSGCommands.map((c) => false);

        CommandManager.execute(
          [
            ...nonCSGCommands,
            ...updateCommands.creationCommands,
            ...updateCommands.otherCommands, // time where both created and deleted walls will be in the scene
            ...updateCommands.deletionCommands,
            ...updateCommands.dllCommands,
            isModifiedCommand
          ],
          [...nonCSGYets, ...yetsForCreation, ...yetsForOthers, ...yetsForDeletion, ...yetsForDll]
        );
      } else {
        let allCommands = _.compact([
          moveCommand,
          parentChangeCommand,
          integrationGeometryChangeCommand,
          isModifiedCommand
        ]);
        let yets = allCommands.map((c) => false);

        CommandManager.execute(allCommands, yets);
      }
    } else {
      //CAD Block

      let data = {
        initialPosition: _meshOfInterestInitialPosition,
        currentPosition: _meshOfInterest.position,
        layerName: _meshOfInterest.layerName,
      };

      data.storey = _meshOfInterest.storey
        ? _meshOfInterest.storey
        : store.activeLayer.storey;
      
      snapEngine.cadSnaps.update(_meshOfInterest);

      let layer = StructureCollection.getInstance()
        .getStructures()
        [store.activeLayer.structure_id].getStoreyData()
        .getStoreyByValue(data.storey)
        .layerData.getLayerByName(data.layerName, data.storey);
      let sketches = layer.sketches;
      data.layerId = layer.id;

      // data.positionAtCreation = new BABYLON.Vector3.FromArray(sketches[0].position);
      // sketches[0].moveAmount = data.currentPosition.subtract(data.positionAtCreation).asArray();
      sketches[0].position = data.currentPosition.asArray();
      // let difference = data.currentPosition.subtract(data.initialPosition);
      // sketches[0].centre._x += difference.x;
      // sketches[0].centre._y += difference.y;
      // sketches[0].centre._z += difference.z;
      undoRedoForMoveCAD(data);
    }
    cleanUp(false);
  }

  function _concludeMoveOperation(intermediateConclusion) {
    /* eslint-disable */
    if (_modeOfOperation === _CONSTANTS.moveMeshOperation) {
    } else if (_modeOfOperation === _CONSTANTS.moveVertexOperation) {
      /* eslint-enable */
      moveVertex.finishOperation(intermediateConclusion);
    } else if (_modeOfOperation === _CONSTANTS.moveEdgeOperation) {
      moveEdge.finishOperation(intermediateConclusion);
    } else if (_modeOfOperation === _CONSTANTS.moveFaceOperation) {
      moveFace.finishOperation(intermediateConclusion);
    }

    if (intermediateConclusion) {
      STATE_MACHINE.reset();
      _removeVisualElements();
      // keypadController.showKeypad(getBabylonGUIElementByName(_CONSTANTS.displayBoxName), handleUserInput);
    } else {
      delayedExecutionEngine.executeAll();
      cleanUp();
    }
  }

  function _postMoveOperations() {
    const _reconcileDifferencesIn2D3D = function () {
      if (store.$scope.isTwoDimension) {
        if (_componentSelected[_CONSTANTS.moveVertexOperation]) {
          dataForEdits.vertex =
            _componentSelected[_CONSTANTS.moveVertexOperation];
          dataForEdits.facetId = _selectionMetadata.pickInfo.faceId;
          dataForEdits.mesh = _meshOfInterest;
          dataForEdits.projection = _meshOfInterestOnMovePoint;
          _modeOfOperation = _CONSTANTS.moveEdgeOperation;
        } else if (_componentSelected[_CONSTANTS.moveEdgeOperation]) {
          dataForEdits.edge = _componentSelected[_CONSTANTS.moveEdgeOperation];
          dataForEdits.mesh = _meshOfInterest;
          dataForEdits.pickInfo = _selectionMetadata.pickInfo;
          _modeOfOperation = _CONSTANTS.moveFaceOperation;
        }
      } else {
        if (_componentSelected[_CONSTANTS.moveVertexOperation]) {
          dataForEdits.mesh = _meshOfInterest;
          dataForEdits.vertex =
            _componentSelected[_CONSTANTS.moveVertexOperation];
          _modeOfOperation = _CONSTANTS.moveVertexOperation;
        } else if (_componentSelected[_CONSTANTS.moveEdgeOperation]) {
          dataForEdits.mesh = _meshOfInterest;
          dataForEdits.edge = _componentSelected[_CONSTANTS.moveEdgeOperation];
          dataForEdits.projection = _meshOfInterestOnMovePoint;
          _modeOfOperation = _CONSTANTS.moveEdgeOperation;
        } else if (_componentSelected[_CONSTANTS.moveFaceOperation]) {
          dataForEdits.mesh = _meshOfInterest;
          dataForEdits.face = _componentSelected[_CONSTANTS.moveFaceOperation];
          dataForEdits.pickInfo = _selectionMetadata.pickInfo;
          _modeOfOperation = _CONSTANTS.moveFaceOperation;
        }
      }
    };

    const _prepareForEdits = function () {
      if (_modeOfOperation === _CONSTANTS.moveVertexOperation) {
        moveVertex.setInitialData(dataForEdits);
      } else if (_modeOfOperation === _CONSTANTS.moveEdgeOperation) {
        moveEdge.setInitialData(dataForEdits);
      } else if (_modeOfOperation === _CONSTANTS.moveFaceOperation) {
        moveFace.setInitialData(dataForEdits);
      }
    };

    let dataForEdits = {};

    handleVisibilityChanges(_meshOfInterest, true);

    return new Promise((resolve, reject) => {
      let rejected = false;
      let timeDiff = new Date() - _firstClickTime;

      let tooManyMeshesSelected = false;
      if (_meshesOfInterestArray.length > 2) tooManyMeshesSelected = true;
      else if (_meshesOfInterestArray.length === 2){
        if (stackedWallHelper.getCompanionWall(_meshesOfInterestArray[0]) === _meshesOfInterestArray[1]){
          tooManyMeshesSelected = false;
        }
        else tooManyMeshesSelected = true;
      }

      if (
        timeDiff / 1000 < 0.5 &&
        _modeOfOperation === _CONSTANTS.moveMeshOperation &&
        !_meshMoved &&
        !tooManyMeshesSelected
      ) {
        if (_editsNotAllowed) {
          cleanUp();
          rejected = true;
          reject();
        } else {
          // setting the stage for edits
          delayedExecutionEngine.flush(false);
          _reconcileDifferencesIn2D3D();
          _prepareForEdits();
        }
      } else {
        //finishing the operation
        _concludeMoveOperation(store.isiPad);
      }

      if (!rejected) resolve();
    });
  }

  function _disposeGrips() {
    disposeHighlightedVertex();
    disposeHightlightedEdge();
    disposeHighlightedFace();

    uiIndicatorsHandler.edgeIndicator.massDestruct();

    // _disposeSelectionBox();
  }

  function _markAsUneditable(indicator) {
    if (indicator.name === _CONSTANTS.vertexIndicator){
      uiIndicatorsHandler.vertexIndicator.markAsUneditable();
    }
    else if (indicator.name === _CONSTANTS.edgeIndicator){
      uiIndicatorsHandler.edgeIndicator.markAsUneditable();
    }
    else if (indicator.name === _CONSTANTS.faceIndicator){
      uiIndicatorsHandler.faceIndicator.markAsUneditable();
    }
  }

  function _disposeSelectionBox() {
    let m = store.newScene.getMeshByName(_CONSTANTS.furnitureBound);
    if (m) {
      m.dispose();
    }
  }

  function eventHandler(evt) {
    STATE_MACHINE.nextEvent(evt);
  }

  function handleUserInput(input) {
    if (!input) return;

    let endOperation = true;

    if (!_.isEmpty(_meshesOfInterestArray)) {
      input = DisplayOperation.getOriginalDimension(input);

      if (_modeOfOperation === _CONSTANTS.moveMeshOperation) {
        let currentPositionOfMesh =
          _meshWithNoRestriction.getAbsolutePosition();

        let positionToGoTo = _meshOfInterestInitialClickPoint.add(
          currentPositionOfMesh
            .subtract(_meshWithNoRestrictionInitialPosition)
            .normalize()
            .scale(input)
        );
        
        if (movementConstrainer.isActive()){
          const isInRange = movementConstrainer.util.isPointInRange(positionToGoTo);
          if (isInRange) endOperation = _moveMesh(positionToGoTo);
          else endOperation = false;
        }
        else endOperation = _moveMesh(positionToGoTo);

      } else if (_modeOfOperation === _CONSTANTS.moveVertexOperation) {
        moveVertex.handleUserInput(input);
      } else if (_modeOfOperation === _CONSTANTS.moveEdgeOperation) {
        endOperation = moveEdge.handleUserInput(input);
      } else if (_modeOfOperation === _CONSTANTS.moveFaceOperation) {
        endOperation = moveFace.handleUserInput(input);
      }

      if (endOperation) _concludeMoveOperation();
      else DisplayOperation.signalInputError();
    }
  }

  function cancelOperation() {
    if (!_.isEmpty(_meshesOfInterestArray)) {
      if (_modeOfOperation === _CONSTANTS.moveMeshOperation) {
        let movementRevertAmount =
          _meshWithNoRestrictionInitialPosition.subtract(
            _meshWithNoRestriction.getAbsolutePosition()
          );

        _componentsOfInterest.forEach((component, i) => {
          let m = _meshesOfInterestArray[i];
          let movementAmount = movementRevertAmount.clone();
          if (m.type !== GLOBAL_CONSTANTS.strings.identifiers.cadSketch) {
            if (component.onElementMove) {
              component.onElementMove(movementAmount);
            }
          }
          updatePosition(component, m, movementAmount);
        });

        handleVisibilityChanges(_meshOfInterest, true);
      } else if (_modeOfOperation === _CONSTANTS.moveVertexOperation) {
        moveVertex.cancelOperation();
      } else if (_modeOfOperation === _CONSTANTS.moveEdgeOperation) {
        moveEdge.cancelOperation();
      } else if (_modeOfOperation === _CONSTANTS.moveFaceOperation) {
        moveFace.cancelOperation();
      }

      cleanUp();
      return true;
    }
  }

  function handleTab(e){
    if (STATE_MACHINE?.currentState === 1){

      const nextSnap = snapEngine.alternateSnaps.getNext(
        _currentPick,
        e.shiftKey
      );
      _initialGripCreation(nextSnap);

      return true;
    }
  }

  function getMetadata() {
    return {
      setOperatingMode,
      getOperatingModes
    };
  }

  function _removeVisualElements() {
    _disposeGrips();
    _disposeSelectionBox();
    disposeAllAxisLines();
    disposeSnapToObjects();
  }

  function cleanUp(handleDelayedExecution = true) {
    return new Promise((resolve, reject) => {
      STATE_MACHINE.reset();
      // store.newScene.activeCamera.attachControl(canvas, true, false);
      _meshOfInterest = null;
      _componentOfInterest = null;
      _meshesOfInterestArray.length = 0;
      _componentsOfInterest.length = 0;
      _meshWithNoRestriction = null;
      _meshWithNoRestrictionInitialPosition = null;
      _meshWithGrips = null;
      _meshOfInterestInitialPosition = null;
      _meshOfInterestInitialClickPoint = null;
      _meshOfInterestOnMovePoint = null;
      _commandData = null;
      _editsNotAllowed = false;
      _meshMoved = false;
      _potentialParentChange = false;
      _potentialNewParent = null;
      _snapMetadata = null;

      _firstClickTime = null;
      _modeOfOperation = null;
      _selectionMetadata = null;
      _componentSelected = {};

      _removeVisualElements();
      turnOffSnappingEngine();
      DisplayOperation.removeDimensions();
      movementConstrainer.flush();

      if (handleDelayedExecution) delayedExecutionEngine.flush();

      if (store.isiPad) keypadController.removeKeypad();

      moveVertex.resetData();
      moveEdge.resetData();
      moveFace.resetData();

      resolve();
    });
  }

  return {
    eventHandler,
    handleUserInput,
    cancelOperation,
    getMetadata,
    cleanUp,
    init,
    handleTab
  };
})();
export { moveOperator };
