import kdTree from "kd-tree-javascript";
import * as d3 from "d3";
import { fabric } from "fabric";
import $ from "jquery";
import { store } from "../../modules/utilityFunctions/Store.js";
import { updateModifications } from "../sceneStateFuncs.js";
import { snapNewObjectDimensions } from "./snapFuncs.js";
import { snapNewObject, snapObject } from "../fplan/snapFuncsFplan.js";
import {
  transformPoints,
  getFillColor,
  transformPath,
} from "./canvasServices.js";
import { removeAllGripsWithId } from "./objectGrips.js";
let allWallsArray = null;

function distance(a, b) {
  var dx = a.x - b.x;
  var dy = a.y - b.y;
  return dx * dx + dy * dy;
}

function draw_interior_polToC(door, mode) {
  var points = [];
  var tree_points = [];
  var temp_pts = [];
  for (var i = 0; i < door.length; i++) {
    temp_pts.push([door[i][0], door[i][1]]);
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
    points.push(new fabric.Point(door[i][0], door[i][1]));
    var point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  var fillColor;
  var n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  if (true) {
    var svg = d3
      .select("body")
      .append("svg")
      .attr("width", 600)
      .attr("height", 500);

    var path = svg
      .append("path")
      .data([door])
      .attr("d", d3.line().curve(d3.curveBasis))
      .attr("stroke-weight", "5px")
      .attr("fill", "none")
      .attr("class", "poly");
    var pathHTML = svg._groups[0][0].innerHTML;
    var n = window.name.slice(1, window.name.length - 1);
    pathHTML = pathHTML.split('"');
    var path = new fabric.Path(pathHTML[1], {
      angle: 0,
      stroke: "black",
      strokeWidth: 2,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      evented: true,
      id: "rooms",
      pol_points: temp_pts,
      fill: fillColor,
      objectCaching: false,
    });
    store.canvas.renderAll();
    store.canvas.add(path);
    let pol;
    pol.perPixelTargetFind = true;
    updateModifications(true);
    return;
  }
}

function draw_interior(door, mode) {
  var fillColor;
  var n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";

  if ($("#curve_tog").prop("checked")) {
    var str_bezier;
    for (var k = 0; k < door.length; k++) {
      if (door[k].length === 4) {
        var bezier_obj = {
          start: new fabric.Point(
            door[k][0][0] / store.scale_factor,
            door[k][0][1] / store.scale_factor
          ),
          c1: new fabric.Point(
            door[k][1][0] / store.scale_factor,
            door[k][1][1] / store.scale_factor
          ),
          c2: new fabric.Point(
            door[k][2][0] / store.scale_factor,
            door[k][2][1] / store.scale_factor
          ),
          end: new fabric.Point(
            door[k][3][0] / store.scale_factor,
            door[k][3][1] / store.scale_factor
          ),
        };
        //CubicBezier(canvas, bezier_obj);
        if (k === 0) {
          str_bezier = "M " + bezier_obj.start.toString() + " ";
          str_bezier += "C " + bezier_obj.c1.toString() + " ";
          str_bezier += bezier_obj.c2.toString() + " ";
          str_bezier += bezier_obj.end.toString() + " ";
        } else if (k === door.length - 1) {
          // str_bezier += "C " + bezier_obj.start.toString() + " ";
          str_bezier += "C " + bezier_obj.c1.toString() + " ";
          str_bezier += bezier_obj.c2.toString() + " ";
          str_bezier += bezier_obj.end.toString() + " ";
          str_bezier += "Z";
        } else {
          // str_bezier += "C " + bezier_obj.start.toString() + " ";
          str_bezier += "C " + bezier_obj.c1.toString() + " ";
          str_bezier += bezier_obj.c2.toString() + " ";
          str_bezier += bezier_obj.end.toString() + " ";
        }
      } else {
        var line_obj = {
          start: new fabric.Point(
            door[k][0][0] / store.scale_factor,
            door[k][0][1] / store.scale_factor
          ),
          end: new fabric.Point(
            door[k][1][0] / store.scale_factor,
            door[k][1][1] / store.scale_factor
          ),
        };

        if (k === 0) {
          str_bezier = "M " + line_obj.start.toString() + " ";
          str_bezier += "L " + line_obj.end.toString() + " ";
        } else if (k === door.length - 1) {
          // str_bezier += "L " + line_obj.start.toString() + " ";
          // str_bezier += "L " + line_obj.end.toString() + " ";
          str_bezier += "Z";
        } else {
          str_bezier += "L " + line_obj.end.toString() + " ";
        }

        // var line = new fabric.BSPath(str_line, {
        //     angle: 0,
        //     stroke: 'black',
        //     strokeWidth: 2,
        //     hasControls: false,
        //     lockMovementX: true,
        //     lockMovementY: true,
        //     lockScalingX: true,
        //     lockScalingY: true,
        //     evented: true,
        //     ktree: tree,
        //     id: 'rooms',
        //     fill: fillColor
        // });
        //
        // line.selectable = false;
        // store.canvas.add(line);
      }
    }
    var curve = new fabric.Path(str_bezier, {
      angle: 0,
      stroke: "black",
      strokeWidth: 2,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      evented: true,
      ktree: tree,
      id: "rooms",
      pol_points: temp_pts,
      fill: fillColor,
      objectCaching: false,
    });
    //console.log(curve);
    curve.selectable = false;
    store.canvas.add(curve);
    curve.origLeft = curve.left;
    curve.origTop = curve.top;
    curve.perPixelTargetFind = true;
    store.canvas.renderAll();
    // store.canvas.add(curve);
    updateModifications(true);
    return;
  }
  var points = [];
  var tree_points = [];
  var temp_pts = [];
  if (door.length > 4) {
    var loop_length = door.length - 1;
  } else {
    var loop_length = door.length;
  }
  for (var i = 0; i < loop_length; i++) {
    temp_pts.push([door[i][0], door[i][1]]);
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
    points.push(new fabric.Point(door[i][0], door[i][1]));
    var point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  var tree = new kdTree(tree_points, distance, ["x", "y"]);
  var pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "black",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    ktree: tree,
    id: "rooms",
    fill: fillColor,
    objectCaching: false,
  });
  store.canvas.renderAll();
  store.canvas.add(pol);
  pol.perPixelTargetFind = true;
  pol.setCoords();
  pol = snapNewObjectDimensions(pol, 0.1);
  pol = snapNewObject(
    pol,
    (25 * store.backImg.width) / 1000,
    (25 * store.backImg.width) / 1000
  );
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  pol.perPixelTargetFind = true;
  store.canvas.renderAll();
  updateModifications(true);
}

function removeDuplicates(pol) {
  var points = pol.points;
  var n = points.length;
  for (var i = 0; i < points.length; i++) {
    if (
      checkCollinear(points[i], points[(i + 1) % n], points[(i + 2) % n]) === 0
    ) {
      points.splice(i + 1, 1);
    }
    if (
      points[i].x === points[(i + 1) % n].x &&
      points[i].y === points[(i + 1) % n].y
    ) {
      points.splice(i + 1, 1);
    }
  }
}

function checkCollinear(pt1, pt2, pt3) {
  return [
    pt1.x * (pt2.y - pt3.y) + pt2.x * (pt3.y - pt1.y) + pt3.x * (pt1.y - pt2.y),
  ];
}

function draw_interior_init(door, mode, room_pos, room_rot, room_scaling) {
  var points = [];
  var tree_points = [];
  // ("draw interior init");
  for (var i = 0; i < door.length; i++) {
    door[i][0] = door[i][0];
    door[i][1] = door[i][1];
    points.push(
      new fabric.Point(
        (door[i][0] * store.prev_scale_factor) / store.scale_factor,
        (door[i][1] * store.prev_scale_factor) / store.scale_factor
      )
    );
    var point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  var fillColor;
  var n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  // var n = parseInt(fillColor.split(5,10));

  var tree = new kdTree(tree_points, distance, ["x", "y"]);
  var pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "black",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    ktree: tree,
    id: "rooms",
    fill: fillColor,
    objectCaching: false,
  });
  if (room_pos[0] != 0) {
    pol.left = room_pos[0] - pol.width / 2;
  }
  if (room_pos[2] != 0) {
    pol.top = Math.abs(room_pos[2]) - pol.height / 2;
  }

  pol.origLeft = pol.left;
  pol.origTop = pol.top;

  store.canvas.renderAll();
  store.canvas.add(pol);
  pol.perPixelTargetFind = true;
}

function draw_interior_init_curve(
  door,
  mode,
  room_pos,
  room_rot,
  room_scaling
) {
  var tree_points = [];
  var fillColor;
  var n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";

  // (door);
  var pol = new fabric.Path(door, {
    angle: 0,
    stroke: "black",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    id: "rooms",
    fill: fillColor,
    objectCaching: false,
  });
  if (room_pos[0] != 0) {
    pol.left = room_pos[0] - pol.width / 2;
  }
  if (room_pos[2] != 0) {
    pol.top = Math.abs(room_pos[2]) - pol.height / 2;
  }
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  store.canvas.renderAll();
  store.canvas.add(pol);
  pol.perPixelTargetFind = true;
  updateModifications(true);
  return;
}

function draw_interior_cToPol(door, mode) {
  var points = [];
  var tree_points = [];
  for (var i = 0; i < door.length; i++) {
    points.push(
      new fabric.Point(
        door[i][0] / store.scale_factor,
        door[i][1] / store.scale_factor
      )
    );
    var point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  var fillColor;
  var n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";

  var tree = new kdTree(tree_points, distance, ["x", "y"]);
  var pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "black",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    ktree: tree,
    id: "rooms",
    fill: fillColor,
    objectCaching: false,
  });
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  store.canvas.renderAll();
  store.canvas.add(pol);
  snapObject(pol, 15, 15);
  store.canvas.renderAll();
  pol.perPixelTargetFind = true;
  updateModifications(true);
  // undoStack.push("Door");
}

function draw_interior_drag(door, obj, fillColor) {
  let transformedPoints = transformPoints(obj);
  if (obj.path) {
    //console.log(door);
    //console.log(objSelected.path);
    var str_bezier = JSON.stringify(store.objSelected.path);
    //var str_bezier = transformPath(obj);
    //console.log(str_bezier);
    var curve = new fabric.Path(str_bezier, {
      angle: 0,
      stroke: "black",
      strokeWidth: 2,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      evented: true,
      //ktree: tree,
      id: "rooms",
      fill: fillColor,
      objectCaching: false,
    });

    curve.selectable = false;
    store.canvas.add(curve);
    curve.origLeft = curve.left;
    curve.origTop = curve.top;
    curve.perPixelTargetFind = true;
    store.canvas.renderAll();
    updateModifications(true);
    return curve;
  }
  var pol = new fabric.Polygon(transformedPoints, {
    angle: 0,
    stroke: "black",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    //ktree: tree,
    id: "rooms",
    fill: fillColor,
    objectCaching: false,
    centeredRotation: true,
  });
  //pol.scaleX = obj.scaleX;
  //pol.scaleY = obj.scaleY;

  // pol.left = obj.left;
  // pol.top = obj.top;
  //pol.origLeft = pol.left;
  //pol.origTop = pol.top;
  store.canvas.renderAll();
  store.canvas.add(pol);
  pol.perPixelTargetFind = true;
  return pol;
}
// ##########################################

function reDrawAllRoomsAfterTransform() {
  let allObjects = store.canvas.getObjects();
  let allRooms = allObjects.filter(function (obj) {
    return obj.id == "rooms" && !obj.hasOwnProperty("path");
  });
  for (let i = 0; i < allRooms.length; i++) {
    draw_interior_drag(
      allRooms[i].points,
      allRooms[i],
      getFillColor(allRooms[i])
    );
    store.canvas.remove(allRooms[i]);
  }
}

function reDrawPathAfterTransform() {
  let allObjects = store.canvas.getObjects();
  let allPaths = allObjects.filter(function (obj) {
    if (obj.hasOwnProperty("path")) {
      if (obj.hasOwnProperty("stroke")) {
        if (obj.stroke == "rgb(0,0,0)" || obj.stroke == "rgb(200,200,200)") {
          return obj;
        }
      }
    }
  });
  for (let i = 0; i < allPaths.length; i++) {
    let transformedCurve = transformPath(allPaths[i]);
    let curve = new fabric.Path(transformedCurve, {
      angle: 0,
      stroke: allPaths[i].stroke,
      strokeWidth: 5,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      evented: true,
      id: "drawn",
      objectCaching: false,
      fill: null,
    });

    curve.selectable = false;
    store.canvas.add(curve);
    curve.perPixelTargetFind = true;
    store.canvas.remove(allPaths[i]);
  }
  updateModifications(true);
  store.canvas.renderAll();
}

function reDrawAllRoomsAfterTransformKeepActiveSelection(toBeSelected) {
  let allObjects = store.canvas.getObjects();
  let allRooms = allObjects.filter(function (obj) {
    return obj.id == "rooms";
  });
  let flag = null;
  for (let i = 0; i < allRooms.length; i++) {
    if (allRooms[i] == toBeSelected) {
      flag = i;
    }
    draw_interior_drag(
      allRooms[i].points,
      allRooms[i],
      getFillColor(allRooms[i])
    );
    store.canvas.remove(allRooms[i]);
  }
  //console.log("SelectedObjAfterDraw", flag, allRooms[flag]);
  store.canvas.setActiveObject(store.canvas.item(0));
  //console.log("SelectedObject", store.canvas.getActiveObject());
}

const drawWall = (wall) => {
  let points = [];
  let tree_points = [];
  let temp_pts = [];
  for (var i = 0; i < wall.length; i++) {
    temp_pts.push([wall[i][0], wall[i][1]]);
    let x_val = wall[i][0] / store.scale_factor;
    let y_val = wall[i][1] / store.scale_factor;
    points.push(new fabric.Point(x_val, y_val));
    let point = {
      x: x_val,
      y: y_val,
    };
    tree_points.push(point);
  }

  let n = 1;
  let fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";

  let tree = new kdTree(tree_points, distance, ["x", "y"]);
  let pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "blue",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: false,
    ktree: tree,
    id: "walls",
    fill: fillColor,
  });
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  store.canvas.renderAll();
  store.canvas.add(pol);
  store.canvas.renderAll();
};

const drawAllWalls = (allWalls, thresh) => {
  removeAllGripsWithId("walls");
  let arrayIndex = (thresh - 70) / 4;
  let wallsWithThresh = allWalls[arrayIndex];
  for (let i = 0; i < wallsWithThresh.length; i++) {
    drawWall(wallsWithThresh[i]);
  }
};

function drawDoor(door) {
  // var list = door.split(',');
  // var numb = [];
  for (var i = 0; i < door.length; i++) {
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
  }

  var pol = new fabric.Door(
    [
      { x: door[0][0], y: door[0][1] },
      { x: door[1][0], y: door[1][1] },
      { x: door[3][0], y: door[3][1] },
      { x: door[2][0], y: door[2][1] },
    ],
    {
      angle: 0,
      stroke: "green",
      strokeWidth: 2,
      evented: true,
      id: "door",
      fill: "rgba(0,0,0,0)",
    }
  );
  store.canvas.add(pol);
  updateModifications(true);
}

function drawWindow(window) {
  for (var i = 0; i < window.length; i++) {
    window[i][0] = window[i][0] / store.scale_factor;
    window[i][1] = window[i][1] / store.scale_factor;
  }

  var pol = new fabric.Polygon(
    [
      { x: window[0][0], y: window[0][1] },
      { x: window[1][0], y: window[1][1] },
      { x: window[3][0], y: window[3][1] },
      { x: window[2][0], y: window[2][1] },
    ],
    {
      angle: 0,
      stroke: "red",
      strokeWidth: 2,
      evented: false,
      id: "window",
      fill: "rgba(0,0,0,0)",
    }
  );
  store.canvas.add(pol);
  updateModifications(true);
  store.undoStack.push("Window");
}
export {
  distance,
  draw_interior_polToC,
  draw_interior,
  removeDuplicates,
  checkCollinear,
  draw_interior_init,
  draw_interior_init_curve,
  draw_interior_cToPol,
  draw_interior_drag,
  reDrawAllRoomsAfterTransform,
  reDrawPathAfterTransform,
  reDrawAllRoomsAfterTransformKeepActiveSelection,
  drawWall,
  drawAllWalls,
  drawDoor,
  drawWindow,
};
