import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../../themes/constant";
import useOnClickOutside from "../../../../common/utils/onOutsideClickHandler";

const MainWrapper = styled.div`
  padding: 0.3125rem 0.625rem;
  background: ${colors.fullWhite};
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  border-radius: 8px;
  border-bottom-left-radius: 0;
  width: 9.25rem;
`;

const EachRow = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  margin: 0.325rem 0;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.192708px;
  color: ${colors.darkGrey};
  width: 2.9375rem;
`;

const Value = styled.p`
  margin: 0.325rem 0;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.192708px;
  color: ${colors.thinGray};
`;

const data = [
  { label: "Ctrl + A", value: "Select All" },
  { label: "Ctrl + C", value: "Copy" },
  { label: "Ctrl + V", value: "Paste" },
  { label: "Ctrl + Z", value: "Undo" },
  { label: "Ctrl + Y", value: "Redo" },
  { label: "Ctrl + ↑", value: "Copy Storey Up" },
  { label: "Ctrl + ↓", value: "Copy Storey Down" },
];

const ShortCutKeyModal = (props) => {
  const { closePopUp } = props;
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (closePopUp) closePopUp();
  });
  return (
    <MainWrapper ref={ref}>
      {data.map((item, index) => {
        return (
          <EachRow key={index}>
            <Label>{item.label}</Label>
            <Value>{item.value}</Value>
          </EachRow>
        );
      })}
    </MainWrapper>
  );
};

export default ShortCutKeyModal;
