import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  margin: 0;
`;

const Heading = styled.h3`
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSizes.heading};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 1.813rem;
`;

const SubHeading = styled.div`
  font-family: ${(props) => props.theme.font};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-top: -1rem;
`;

const Body = styled.p`
  font-family: ${(props) => props.theme.font};
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const Paragraph = (props) => {
  const {
    heading,
    subHeading,
    body,
    customContainerStyle,
    customHeaderStyle,
    customSubHeadingStyle,
    customTextStyle,
  } = props;
  return (
    <Container style={customContainerStyle}>
      {heading ? <Heading style={customHeaderStyle}>{heading}</Heading> : null}
      {subHeading ? <SubHeading style={customSubHeadingStyle}>{subHeading}</SubHeading> : null}
      {body &&
        body.map((item, index) => (
          <Body key={`${index}`} style={customTextStyle}>
            {item}
          </Body>
        ))}
    </Container>
  );
};

Paragraph.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.arrayOf(String),
  customContainerStyle: PropTypes.instanceOf(Object),
  customHeaderStyle: PropTypes.instanceOf(Object),
  customTextStyle: PropTypes.instanceOf(Object),
};

export default Paragraph;
