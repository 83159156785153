import cadLayer from "../../../assets/tiles/cadLayer.svg";
import topoLayer from "../../../assets/tiles/topoLayer.svg";
import editLayer from "../../../assets/tiles/editLayer.svg";
import imageLayer from "../../../assets/tiles/imageLayer.svg";

export const STANDARD_COST_TEXT = "Standard Cost";
export const DESIGN_COST_TEXT = "Design Cost";
export const DESCRIPTION = "Description";
export const QUANTITY = "Quantity";
export const UNIT = "Unit";
export const COST = "Cost";
export const DOLLAR = "$";
export const SITE_AREA = "Site Area";
export const BUILT_UP_AREA = "Gross External Area";
export const CARPET_AREA = "Gross Internal Area";
export const FSI = "FSI";
export const TOTAL = "Total";
export const RIGHT_SIDEBAR_OPEN_WIDTH = "22.4em";

export const PIE_CHART_DATA = [
  { title: "Staircases", value: 5900, color: "#4F4F4F" }, // change the color accordingly
  { title: "Slabs", value: 20084, color: "#FB545B" },
  { title: "Flooring", value: 20084, color: "#1B3A97" },
  { title: "Walls", value: 40084, color: "#FFCF55" },
];

export const BOM_TABLE_DATA = [
  {
    header: {
      item: "Walls",
      cost: 25500,
    },
    body: [
      {
        title: "Concrete",
        subBody: [
          {
            subTitle: "Rough Finish RCC",
            amount: 5.63,
            unit: "Cubic Meters",
            cost: 5050,
          },
          {
            subTitle: "Grooved",
            amount: 2.22,
            unit: "Cubic Meters",
            cost: 5050,
          },
        ],
      },
      {
        title: "Brick",
        subBody: [
          {
            subTitle: "100mm Standard",
            amount: 5.63,
            unit: "Cubic Meters",
            cost: 5050,
          },
          {
            subTitle: "porotherm",
            amount: 2.22,
            unit: "Cubic Meters",
            cost: 5050,
          },
          {
            subTitle: "Rouch Cement",
            amount: 2.22,
            unit: "Cubic Meters",
            cost: 5050,
          },
        ],
      },
    ],
  },
  {
    header: {
      item: "Slabs",
      cost: 35500,
    },
    body: [
      {
        title: "Concrete",
        subBody: [
          {
            subTitle: "Rough Finish RCC",
            amount: 5.63,
            unit: "Cubic Meters",
            cost: 5050,
          },
          {
            subTitle: "Grooved",
            amount: 2.22,
            unit: "Cubic Meters",
            cost: 5050,
          },
        ],
      },
      {
        title: "Brick",
        subBody: [
          {
            subTitle: "100mm Standard",
            amount: 5.63,
            unit: "Cubic Meters",
            cost: 5050,
          },
          {
            subTitle: "porotherm",
            amount: 2.22,
            unit: "Cubic Meters",
            cost: 5050,
          },
          {
            subTitle: "Rouch Cement",
            amount: 2.22,
            unit: "Cubic Meters",
            cost: 5050,
          },
        ],
      },
    ],
  },
];

export const AREA_TABLE_DATA = [
  {
    header: {
      title: "Storey 5",
      builtUpCost: 5679,
      carpetAreaCost: 5244,
      fsiCost: 5244,
    },
    body: [
      {
        subHeader: {
          subTitle: "3BHK Apartment 1",
          builtUpCost: 5679,
          carpetAreaCost: 5244,
        },
        body: [
          {
            commodity: "Living",
            cost: 300,
            isApplicable: true,
          },
          {
            commodity: "Dining",
            cost: 300,
            isApplicable: false,
          },
          {
            commodity: "Kitchen",
            cost: 300,
            isApplicable: true,
          },
        ],
      },
      {
        subHeader: {
          subTitle: "3BHK Apartment 2",
          builtUpCost: 5679,
          carpetAreaCost: 5244,
        },
        body: [
          {
            commodity: "Living",
            cost: 300,
            isApplicable: true,
          },
          {
            commodity: "Dining",
            cost: 300,
            isApplicable: false,
          },
          {
            commodity: "Kitchen",
            cost: 300,
            isApplicable: true,
          },
        ],
      },
      {
        subHeader: {
          subTitle: "Corridor",
          builtUpCost: 5679,
          carpetAreaCost: 5244,
          isApplicable: true,
        },
        body: [],
      },
    ],
  },
  {
    header: {
      title: "Storey 5",
      builtUpCost: 5679,
      carpetAreaCost: 5244,
      fsiCost: 5244,
    },
    body: [
      {
        subHeader: {
          subTitle: "3BHK Apartment 1",
          builtUpCost: 5679,
          carpetAreaCost: 5244,
        },
        body: [
          {
            commodity: "Living",
            cost: 300,
            isApplicable: true,
          },
          {
            commodity: "Dining",
            cost: 300,
            isApplicable: false,
          },
          {
            commodity: "Kitchen",
            cost: 300,
            isApplicable: true,
          },
        ],
      },
      {
        subHeader: {
          subTitle: "3BHK Apartment 2",
          builtUpCost: 5679,
          carpetAreaCost: 5244,
        },
        body: [
          {
            commodity: "Living",
            cost: 300,
            isApplicable: true,
          },
          {
            commodity: "Dining",
            cost: 300,
            isApplicable: false,
          },
          {
            commodity: "Kitchen",
            cost: 300,
            isApplicable: true,
          },
        ],
      },
      {
        subHeader: {
          subTitle: "Corridor",
          builtUpCost: 5679,
          carpetAreaCost: 5244,
          isApplicable: true,
        },
        body: [],
      },
    ],
  },
];

export const LAYER_EXPANDABLE_ROWS = [
  {
    image: topoLayer,
    title: "Layer Name abcd",
    subView: {
      allIcons: true,
      heightToggle: true,
      thicknessSelector: false,
      colorPicker: false,
    },
  },
  {
    image: imageLayer,
    title: "Layer Name xxsfg",
    subView: {
      allIcons: true,
      heightToggle: false,
      thicknessSelector: false,
      colorPicker: false,
    },
  },
  {
    image: cadLayer,
    title: "Layer Name",
    subView: {
      allIcons: true,
      heightToggle: false,
      thicknessSelector: true,
      colorPicker: true,
    },
  },
  {
    image: editLayer,
    title: "Layer Name",
    subView: {
      allIcons: true,
      heightToggle: false,
      thicknessSelector: true,
      colorPicker: true,
    },
  },
  {
    image: editLayer,
    title: "Layer Name",
    subView: {
      allIcons: true,
      heightToggle: false,
      thicknessSelector: true,
      colorPicker: true,
    },
  },
  {
    image: imageLayer,
    title: "Layer Name xxsfg",
    subView: {
      allIcons: true,
      heightToggle: false,
      thicknessSelector: false,
      colorPicker: false,
    },
  },
  {
    image: topoLayer,
    title: "Layer Name abcd",
    subView: {
      allIcons: true,
      heightToggle: true,
      thicknessSelector: false,
      colorPicker: false,
    },
  },
  {
    image: topoLayer,
    title: "Layer Name abcd",
    subView: {
      allIcons: true,
      heightToggle: true,
      thicknessSelector: false,
      colorPicker: false,
    },
  },
  {
    image: cadLayer,
    title: "Layer Name",
    subView: {
      allIcons: true,
      heightToggle: false,
      thicknessSelector: true,
      colorPicker: true,
    },
  },
];
