export const USER_SET_BIM_PROPERTIES = {
  "roofOverhang": "0.00",
  "gridSnapEnabled": true,
  "gridSnapThreshold": "100.00",
  "gridUnitVal": "100.00",
  "gridEnabled": false,
  "storeyUIToggle": {},
  "layerUIToggle": {},
  "slabThicknessProperty": "150.00",
  "wallTypePropertyExt": "BRICK",
  "wallThicknessPropertyExt": "200.00",
  "wallTypePropertyInt": "Brick Wall with Plaster and Paint",
  "wallThicknessPropertyInt": "100.00",
  "wallTypePropertyParapet": "Glass Curtain Wall",
  "wallThicknessPropertyParapet": "25.00",
  "angleSnapThreshold": "15°",
  "angleSnapEnabled": true,
  "disableTooltips": false,
  "constraintSolverThreshold": "10.00",
  "plinthHeightProperty": "300.00",
  "toggleInternalDimensionsProperty": false
}