import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { computeBounds } from "./zoomFuncs.js";
import { onCamRot } from "../modules/extrafunc.js";
import { getAngleInRadians } from "./snapFuncs.js";
import {
  onSectionViewDown,
  onSectionViewUp,
  onSectionViewMove,
} from "./sectionViewFuncs.js";
import { sectionViews } from "./sectionView.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations";

const MAXZ_MIN_THRESHOLD = 500;

function changeToOrthoViewCamera(
  fitImage = false,
  callOnCamRot = true,
  init = false
) {
  // calling onCamRot removes active mouse events

  let centPoint = findCentPoint(store.activeLayer.storey);
  let bounds = computeBounds(store.activeLayer.storey);

  /*if(centPoint.equals(BABYLON.Vector3.Zero())){
    if (!_.isEmpty(scene.getMeshesByTags("FloorPlan"))){
      fitImage = true;
    }
  }*/

  bounds.minimum.x = 0;
  bounds.minimum.y = 0;
  bounds.minimum.z = 0;

  let d1 =
    ((bounds.maximum.x - bounds.minimum.x) ** 2 +
      (bounds.maximum.z - bounds.minimum.z) ** 2) **
    0.5;

  let topViewPos =
    d1 * Math.sin(Math.PI / 2 - store.newScene.activeCamera.fov / 2) * 1.5;

  store.newScene.activeCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

  let maxZ = topViewPos * 100;
  if (maxZ < MAXZ_MIN_THRESHOLD) maxZ = MAXZ_MIN_THRESHOLD;

  let targetX = 1;
  let targetZ = 1;

  if (fitImage) {
    store.newScene.activeCamera.orthoTop = (store.canvas.height * d1) / 1000;
    store.newScene.activeCamera.orthoBottom =
      (-store.canvas.height * d1) / 1000;
    store.newScene.activeCamera.orthoRight = (store.canvas.width * d1) / 1000;
    store.newScene.activeCamera.orthoLeft = (-store.canvas.width * d1) / 1000;
  } else {
    //sets the initial store.scene dimensions to 15000mm x 35000 mm
    if (store.isiPad) {
      store.newScene.activeCamera.orthoTop = (store.canvas.height * d1) / 1000;
      store.newScene.activeCamera.orthoBottom =
        (-store.canvas.height * d1) / 1000;
      store.newScene.activeCamera.orthoRight = (store.canvas.width * d1) / 1000;
      store.newScene.activeCamera.orthoLeft = (-store.canvas.width * d1) / 1000;
    } else {
      store.newScene.activeCamera.orthoTop = (store.canvas.height * d1) / 1000;
      store.newScene.activeCamera.orthoBottom =
        (-store.canvas.height * d1) / 1000;
      store.newScene.activeCamera.orthoRight = (store.canvas.width * d1) / 1000;
      store.newScene.activeCamera.orthoLeft = (-store.canvas.width * d1) / 1000;
    }
  }

  if (store.isiPad) {
    topViewPos *= 1.5;
    targetX = 1.2;
    targetZ = 1.5;
  } else if (store.isMobile) {
    topViewPos *= 1.5;
    targetX = 0.5;
    targetZ = 0.9;
  } else {
    topViewPos *= 1.5;
    targetX = 2.0;
    targetZ = 1.0;
  }

  const storeyBase =
    StoreyMutation.getAParticularStorey(store.activeLayer.storey)?.base || 0;
  topViewPos += storeyBase;

  if (centPoint.x === 0 && centPoint.y === 0 && centPoint.z === 0) {
    if (init) {
      // store.newScene.activeCamera.setPosition(
      //   new BABYLON.Vector3(bounds.maximum.x, topViewPos, -bounds.maximum.z)
      // );
      store.newScene.activeCamera.setTarget(
        new BABYLON.Vector3(
          targetX * bounds.maximum.x,
          0,
          -bounds.maximum.z * targetZ
        )
      );
    }
    //so that axes on initial page load are at top left corner
  } else {
    /*
      This part is a little tricky
      setPosition basically does nothing to the 2D view
      Ortho values are what makes the view change
      But, if ortho and position.y aren't equivalent snap thresholds are messed up
      
      So, maybe setPosition isn't even necessary, let BABYLON figure out that equivalence
      Tried that, doesn't work.
      camera.position and radius are tightly linked. So, if setPosition is not used, the resultant position
      depends heavily on the 3D radius, so not dependable.
      
      Ortho params are the only dependable parameters, shall use them directly for snap thresholds
      
      Ortho params define the corners of the rectangle to be shown in 2D wrt camera target (mostly)
      So, radius of the camera should be based on the ortho values as done below?
      
      position.y has one significance - picking layerRefPlane
      If camera.position.y < layerRefPlane.position.y pick will fail
      
      So, accommodating storey base now

      Update/31-01-2022
      position.x and position.z change does have impact on camera view unlike position.y
     */

    let increaseInY;
    increaseInY = topViewPos;
    // increaseInY = Math.max(Math.abs(bounds.maximum.x), Math.abs(bounds.maximum.z));
    // increaseInY = (store.newScene.activeCamera.orthoTop ** 2 + store.newScene.activeCamera.orthoRight ** 2) ** 0.5;

    if (init) {
      // store.newScene.activeCamera.setPosition(
      //   new BABYLON.Vector3(centPoint.x, centPoint.y + increaseInY, centPoint.z)
      // );

      store.newScene.activeCamera.setTarget(
        new BABYLON.Vector3(centPoint.x, centPoint.y, centPoint.z)
      );
    }
  }
  store.newScene.activeCamera.cameraDirection = new BABYLON.Vector3(0, -1, 0);
  store.newScene.activeCamera.lowerBetaLimit = 0;
  store.newScene.activeCamera.upperBetaLimit = 0;
  store.newScene.activeCamera.lowerAlphaLimit = -Math.PI / 2;
  store.newScene.activeCamera.upperAlphaLimit = -Math.PI / 2;
  store.newScene.activeCamera.alpha = -Math.PI / 2;
  store.newScene.activeCamera.beta = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  // store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, -4.8);
  store.newScene.activeCamera.minZ = -5;
  store.newScene.activeCamera.maxZ = maxZ;

  // calling onCamRot removes active mouse events
  if (callOnCamRot) onCamRot();

  $("#ortho_camera_button").css("border", "1px solid #d30041");

  // store.canvas.addEventListener("mousewheel", scrollZoomTopView);
}

function changeToTopViewCamera() {
  // store.newScene.activeCamera.setTarget(new BABYLON.Vector3(0,0,0));

  var centPoint = findCentPoint();
  var bounds = computeBounds();
  var d1 =
    ((bounds.maximum.x - bounds.minimum.x) ** 2 +
      (bounds.maximum.z - bounds.minimum.z) ** 2) **
    0.5;
  var topViewPos =
    d1 * Math.sin(Math.PI / 2 - store.newScene.activeCamera.fov / 2) * 1.5;
  var off = 5;
  // store.newScene.activeCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
  // store.newScene.activeCamera.orthoTop = 50*d1/100;
  // store.newScene.activeCamera.orthoBottom = -50*d1/100;
  // store.newScene.activeCamera.orthoRight = 70*d1/100;
  // store.newScene.activeCamera.orthoLeft = -70*d1/100;

  // store.newScene.activeCamera.orthoTop = (centPoint.z - bounds.minimum.z + off);
  // store.newScene.activeCamera.orthoBottom = (centPoint.z + bounds.maximum.z + off);
  // store.newScene.activeCamera.orthoRight = (centPoint.x - bounds.minimum.x - 0*off);
  // store.newScene.activeCamera.orthoLeft = (centPoint.x + bounds.minimum.x + 0*off);

  store.newScene.activeCamera.setPosition(
    new BABYLON.Vector3(centPoint.x, centPoint.y + topViewPos, centPoint.z)
  );
  store.newScene.activeCamera.setTarget(
    new BABYLON.Vector3(centPoint.x, centPoint.y, centPoint.z)
  );
  store.newScene.activeCamera.cameraDirection = new BABYLON.Vector3(0, -1, 0);

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = -Math.PI / 2;
  store.newScene.activeCamera.beta = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  // store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, 2.0);
  store.newScene.activeCamera.minZ = 0;
  // store.canvas.addEventListener("mousewheel", scrollZoomTopView);
}

function changeToFrontViewCamera() {
  store.newScene.activeCamera = store.scene.getCameraByName("ArcRotateCamera3");
  store.newScene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
  var centPoint = findCentPoint();
  var bounds = computeBounds();
  var d1 =
    ((bounds.maximum.x - bounds.minimum.x) ** 2 +
      (bounds.maximum.y - bounds.minimum.y) ** 2) **
    0.5;
  var frontViewPos =
    d1 * Math.sin(Math.PI / 2 - store.newScene.activeCamera.fov / 2);
  store.newScene.activeCamera.cameraDirection = new BABYLON.Vector3(-1, 0, 0);
  store.newScene.activeCamera.setPosition(
    new BABYLON.Vector3(frontViewPos + centPoint.x, centPoint.y, centPoint.z)
  );
  store.newScene.activeCamera.setTarget(
    new BABYLON.Vector3(centPoint.x, centPoint.y, centPoint.z)
  );

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = -Math.PI / 2;
  store.newScene.activeCamera.beta = Math.PI / 2;
  store.newScene.activeCamera.minZ = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  // store.canvas.addEventListener("mousewheel", scrollZoom);
  store.scene.clipPlane = null;
}

function changeToIsolatedViewCamera(wall) {
  store.newScene.activeCamera = store.scene.getCameraByName("ArcRotateCamera3");
  store.newScene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  /*newScene.activeCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
    store.newScene.activeCamera.orthoTop = 38/100;
    store.newScene.activeCamera.orthoBottom = -40/100;
    store.newScene.activeCamera.orthoRight = 80/100;
    store.newScene.activeCamera.orthoLeft = -80/100;*/

  var centPoint = findCentPoint();
  var bounds = computeBounds();
  var d1 =
    ((bounds.maximum.x - bounds.minimum.x) ** 2 +
      (bounds.maximum.y - bounds.minimum.y) ** 2) **
    0.5;
  var frontViewPos =
    d1 * Math.sin(Math.PI / 2 - store.newScene.activeCamera.fov / 2);

  let alpha = 0;
  let floorPos = null;
  let wallPos = wall.mesh.position;

  let p1 = wall.bottomLineSegment[0];
  let p2 = wall.bottomLineSegment[1];

  let side = new BABYLON.Vector3(p1[0], p1[2], p1[1]).subtract(
    new BABYLON.Vector3(p2[0], p2[2], p2[1])
  );
  let angle = getAngleInRadians(new BABYLON.Vector3(0, 0, -1), side);

  //console.log(angle);
  if (side.x > 0) alpha = angle;
  else alpha = -angle;

  store.newScene.activeCamera.setTarget(
    new BABYLON.Vector3(centPoint.x, centPoint.y, centPoint.z)
  );

  store.newScene.activeCamera.alpha = alpha;
  store.newScene.activeCamera.beta = Math.PI / 2;
  store.newScene.activeCamera.radius = 25;
  store.newScene.render();

  let id = wall.room_id;
  let level = wall.level_low;
  let ids = [];
  for (let i = 0; i < id.length; i += 3) {
    ids.push(id.substring(i, i + 3));
  }

  for (let id of ids) {
    let floor = null;
    store.scene.meshes.some(function (mesh) {
      if (mesh.type === "Floor") {
        let meshDS = mesh.getSnaptrudeDS();
        if (meshDS.room_id === id && meshDS.level_low === level) {
          floor = mesh.getSnaptrudeDS();

          return true;
        }
      }
    });
    let sphere = store.scene.getMeshByName("isolatedViewSphere");

    let bool = floor.mesh.intersectsMesh(sphere);
    /*console.log(floor.mesh.uniqueId);
        console.log(sphere.uniqueId);
        console.log("Intersection " + bool);*/

    if (bool) {
      floorPos = floor.mesh.position;
      break;
    }
  }

  if (
    BABYLON.Vector3.Distance(floorPos, store.newScene.activeCamera.position) >
    BABYLON.Vector3.Distance(wallPos, store.newScene.activeCamera.position)
  ) {
    store.newScene.activeCamera.alpha += Math.PI;
  }

  store.newScene.activeCamera.minZ = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  // store.canvas.addEventListener("mousewheel", scrollZoom);
  store.scene.clipPlane = null;

  //if (box = store.newScene.getMeshByName(wall.mesh.name + "boxScale")) box.dispose();
}

function changeToIsoViewCamera() {
  var centPoint = findCentPoint();
  var bounds = computeBounds();
  var d1 =
    ((bounds.maximum.x - bounds.minimum.x) ** 2 +
      (bounds.maximum.z - bounds.minimum.z) ** 2) **
    0.5;
  var topViewPos =
    d1 * Math.sin(Math.PI / 2 - store.newScene.activeCamera.fov / 2) * 1.5;
  var off = 5;

  if (topViewPos < 1000) topViewPos += 1000;

  //console.log(bounds);

  store.newScene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
  store.newScene.activeCamera.setPosition(
    new BABYLON.Vector3(centPoint.x, centPoint.y + topViewPos, centPoint.z)
  );
  store.newScene.activeCamera.setTarget(
    new BABYLON.Vector3(centPoint.x, centPoint.y, centPoint.z)
  );
  store.newScene.activeCamera.cameraDirection = new BABYLON.Vector3(0, -1, 0);

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = -Math.PI / 6;
  store.newScene.activeCamera.beta = Math.PI / 3;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  // store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, 2.0);
  store.newScene.activeCamera.minZ = 0;
  let maxZ = topViewPos * 100;
  if (maxZ < MAXZ_MIN_THRESHOLD) maxZ = MAXZ_MIN_THRESHOLD;
  store.newScene.activeCamera.maxZ = maxZ;
  store.newScene.clipPlane = null;
  // store.canvas.addEventListener("mousewheel", scrollZoomTopView);
}

function changeToIsoViewCameraSW() {
  store.newScene.activeCamera.setTarget(new BABYLON.Vector3(0, 0, 0));
  store.newScene.activeCamera.setPosition(new BABYLON.Vector3(45, 30, -45));

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = -Math.PI / 4;
  store.newScene.activeCamera.beta = Math.PI / 4;
  store.newScene.radius = 15;
  store.newScene.activeCamera.minZ = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  store.scene.clipPlane = null;
}

function changeToIsoViewCameraNW() {
  store.newScene.activeCamera.setTarget(new BABYLON.Vector3(0, 0, 0));
  store.newScene.activeCamera.setPosition(new BABYLON.Vector3(45, 30, -45));

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = (3 * Math.PI) / 4;
  store.newScene.activeCamera.beta = Math.PI / 4;
  store.newScene.radius = 15;
  store.newScene.activeCamera.minZ = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  store.scene.clipPlane = null;
}

function changeToIsoViewCameraNE() {
  store.newScene.activeCamera.setTarget(new BABYLON.Vector3(0, 0, 0));
  store.newScene.activeCamera.setPosition(new BABYLON.Vector3(45, 30, -45));

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = Math.PI / 4;
  store.newScene.activeCamera.beta = Math.PI / 4;
  store.newScene.radius = 15;
  store.newScene.activeCamera.minZ = 0;
  store.scene.getMeshByName("ground1").visibility = 1.0;
  store.scene.clipPlane = null;
}

function changeToSectionViewCamera() {
  var centPoint = findCentPoint();
  var bounds = computeBounds();
  var d1 =
    ((bounds.maximum.x - bounds.minimum.x) ** 2 +
      (bounds.maximum.z - bounds.minimum.z) ** 2) **
    0.5;
  var topViewPos =
    d1 * Math.sin(Math.PI / 2 - store.newScene.activeCamera.fov / 2);
  store.newScene.activeCamera.setPosition(
    new BABYLON.Vector3(centPoint.x, centPoint.y + topViewPos, centPoint.z)
  );
  store.newScene.activeCamera.setTarget(
    new BABYLON.Vector3(centPoint.x, centPoint.y, centPoint.z)
  );
  store.newScene.activeCamera.cameraDirection = new BABYLON.Vector3(0, -1, 0);

  store.newScene.activeCamera.upperBetaLimit = null;
  store.newScene.activeCamera.lowerAlphaLimit = null;
  store.newScene.activeCamera.upperAlphaLimit = null;

  store.newScene.activeCamera.alpha = 0;
  store.newScene.activeCamera.beta = 0;
  store.canvas.addEventListener("pointerdown", onSectionViewDown, false);
  store.canvas.addEventListener("pointerup", onSectionViewUp, false);
  store.canvas.addEventListener("pointermove", onSectionViewMove, false);
  store.scene.clipPlane = null;
  // store.scene.getMeshByName("ground1").visibility = 0.0;
}

function onSectionViewCamera() {
  // let sectionPlane = store.newScene.getMeshByName("sectionPlane");
  // if(!sectionPlane){
  //     sectionViews.createInitialPlane();
  // }
  // else{
  //     sectionPlane.isVisible = true;
  // }

  sectionViews.createInitialPlane();

  store.canvas.addEventListener(
    "pointerdown",
    sectionViews.onPointerDown,
    false
  );
  store.canvas.addEventListener("pointerup", sectionViews.onPointerUp, false);
  store.canvas.addEventListener(
    "pointermove",
    sectionViews.onPointerMove,
    false
  );
}

function findCentPoint(onlyForStorey) {
  function _addToCentPoint(mesh) {
    mesh.computeWorldMatrix(true);
    let bbinfo = mesh.getBoundingInfo();
    centPoint.addInPlace(bbinfo.boundingBox.centerWorld);
    j++;
  }

  var centPoint = new BABYLON.Vector3(0, 0, 0);
  var j = 0;
  for (var i = 0; i < store.scene.meshes.length; i++) {
    if (filterCameraAffectingMeshes(store.scene.meshes[i], onlyForStorey)) {
      _addToCentPoint(store.scene.meshes[i]);
    }
  }

  if (onlyForStorey) {
    // store.activeLayer.floorplans.forEach(floorPlanObj => _addToCentPoint(floorPlanObj.mesh));
    // if(activeLayer.layerType === 'cad'){
    //     // for( let i = 0; i < store.activeLayer.sketches.length; i++ ){
    //     //     let eachSketchObj = store.activeLayer.sketches[i];
    //     //     eachSketchObj.mesh.forEach(m => { _addToCentPoint(m); } );
    //     // }
    // }
    // else{
    //     store.activeLayer.sketches.forEach(sketchObj => _addToCentPoint(sketchObj.mesh));
    // }
    store.activeLayer.sketches.forEach((sketchObj) =>
      _addToCentPoint(sketchObj.mesh)
    );
  }

  if (j === 0) {
    return centPoint;
  }
  centPoint.x = centPoint.x / j;
  centPoint.y = centPoint.y / j;
  centPoint.z = centPoint.z / j;

  return centPoint;
}

function filterCameraAffectingMeshes(mesh, onlyForStorey) {
  let ignoreMesh = false;

  if (mesh.visibility === 0) ignoreMesh = true;
  else if (mesh.scaling.x === 0 && mesh.scaling.y === 0 && mesh.scaling.z === 0)
    ignoreMesh = true;
  else if (mesh.isVisible === false) ignoreMesh = true;
  else if (!mesh.name) ignoreMesh = true;
  else if (mesh.name === "ground1") ignoreMesh = true;
  else if (mesh.name === "ground2") ignoreMesh = true;
  else if (mesh.name === "backwall") ignoreMesh = true;
  else if (mesh.name === "axisX") ignoreMesh = true;
  else if (mesh.name === "axisY") ignoreMesh = true;
  else if (mesh.name === "axisZ") ignoreMesh = true;
  else if (mesh.name.indexOf("boxScale") !== -1) ignoreMesh = true;
  else if (mesh.name.indexOf("dimline") !== -1) ignoreMesh = true;
  else if (mesh.name.indexOf("layerRefPlane") !== -1) ignoreMesh = true;
  else if (mesh.name.indexOf("terrain") !== -1) ignoreMesh = true;
  else if (mesh.name.indexOf("sunpath") !== -1) ignoreMesh = true;
  else if (
    mesh.type.indexOf(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh) !== -1
  )
    ignoreMesh = true;
  else if (
    mesh.getAbsolutePosition().x >
    GLOBAL_CONSTANTS.numbers.positions.throwAwayMesh * 0.8
  )
    ignoreMesh = true;

  if (!ignoreMesh) {
    if (mesh.parent) {
      if (
        mesh.parent.type.indexOf(
          GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh
        ) !== -1
      )
        ignoreMesh = true;
    }
    if (onlyForStorey) {
      if (parseInt(mesh.storey) !== onlyForStorey) ignoreMesh = true;
    }
  }

  return !ignoreMesh;
}

function moveCameraToStructure(structureID) {
  // take camera to structure ..
  // hide other structures ..
  //
  for (let i = 0; i < store.scene.meshes.length; i++) {
    if (
      store.scene.meshes[i].name.indexOf("axis") == -1 &&
      store.scene.meshes[i].name.indexOf("ground") == -1
    ) {
      if (store.scene.meshes[i].name.indexOf("backwall") == -1) {
        if (store.scene.meshes[i].visibility) {
          if (store.scene.meshes[i].structure_id != structureID) {
            store.scene.meshes[i].visibility = 0.4;
            changeToOrthoViewCamera();
          }
        }
      }
    }
  }
}

function easyMoveCamera(cameraObj) {
  if (cameraObj != null && !store.$scope.isTwoDimension) {
    store.scene.activeCamera.spinTo("alpha", cameraObj.alpha, 80);
    store.scene.activeCamera.spinTo("beta", cameraObj.beta, 80);
    store.scene.activeCamera.spinTo("radius", cameraObj.radius, 70);
    store.scene.activeCamera.setPosition(
      new BABYLON.Vector3(
        cameraObj.position._x,
        cameraObj.position._y,
        cameraObj.position._z
      )
    );
    store.scene.activeCamera.target.x = cameraObj.target._x;
    store.scene.activeCamera.target.y = cameraObj.target._y;
    store.scene.activeCamera.target.z = cameraObj.target._z;
  }
}

function saveCameraState() {
  let structures = StructureCollection.getInstance();
  // let str = structures.getStructureById(strId);
  // let storeyData = str.getStoreyData().getStoreyByValue(storeyVal);
  if (store.$scope.isTwoDimension) {
    let lastLayer = store.activeLayer ? store.activeLayer.name : "Wall";
    let camData = {
      pos: store.scene.activeCamera.position.clone(),
      target: store.scene.activeCamera.target.clone(),
    };
    store.activeLayer.lastActiveLayer = lastLayer;
    store.activeLayer.cameraInfo = camData;
  }
}

export {
  changeToOrthoViewCamera,
  changeToTopViewCamera,
  changeToFrontViewCamera,
  changeToIsolatedViewCamera,
  changeToIsoViewCamera,
  changeToIsoViewCameraSW,
  changeToIsoViewCameraNW,
  changeToIsoViewCameraNE,
  changeToSectionViewCamera,
  onSectionViewCamera,
  findCentPoint,
  filterCameraAffectingMeshes,
  moveCameraToStructure,
  easyMoveCamera,
  saveCameraState,
};
