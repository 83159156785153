import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useCallback } from 'react'
import { useDragLayer } from 'react-dnd'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getDNDState, Action as DNDAction } from '../../snaptrude/stateManagers/reducers/objectProperties/dndSlice'
import { colors } from '../../themes/constant';

const defaultStyle = {
  border: `2px solid transparent`,
  borderRadius: "0.938rem",
  transition: "all 0.1s linear"
}
const defaultSelectionStyle = {
  border: `2px solid ${colors.primeBlack}`,
  borderRadius: "0.938rem",
  transition: "all 0.1s linear"
}
const defaultDragOverStyle = {
  border: `2px solid transparent`,
  borderRadius: "0.938rem",
  transition: "all 0.1s linear",
  transform: 'scale(1.05)'
}

const DragDropState = {
    selectionRef: {},
    nodesRef: {},
    addedEventListener: false,
}

/**
 * Enables Drag or Drop for any components;
 * @Note Wrap only one component
 */
export const DragDropWrapper = ({
  ddId,
  id,
	type,
  bucketName,
	data,
	enableDrop,
  onDrop,
  draggable=true,
  accept=[],
  children,
  style=defaultStyle,
  selectionStyle=defaultSelectionStyle,
  dropAreaStyle=defaultDragOverStyle,
}) => {
  const state = useSelector(getDNDState)
  const dispatch = useDispatch()

  const { selection, selectionList } = useMemo(() => {
    if(state && bucketName in state.selection) {
      return {
        selection: state.selection[bucketName],
        selectionList: Object.values(state.selection[bucketName])
      }
    }
    return { selection: {}, selectionList: [] }
  }, [state.selection])

  DragDropState.selectionRef = cloneDeep(selection)

  const isSelected = selection && id in selection

  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { ...data, type },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()

      if(dropResult && item?.id == dropResult?.id) return

      if(dropResult && onDrop) {
          let items = Object.values(DragDropState.selectionRef)
          items = items.filter(item => item?.id != dropResult.id)
          onDrop(items, dropResult)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const [{ isOverCurrent }, drop] = useDrop(() => ({
    accept,
    drop: (_item, monitor) => {
      const didDrop = monitor.didDrop()

      if(didDrop) return
    
      return { ...data, type }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    }),
  }))

  useEffect(() => {
    dispatch(DNDAction.setDragging(isDragging))
  }, [isDragging])

  useEffect(() => {
      // do something on mount here

      return () => {
        if(bucketName in DragDropState.nodesRef) delete DragDropState.nodesRef[bucketName][id]
      }
  }, []);

  const handleOutsideClick = useCallback((e) => {
    let contains = false

    if(bucketName in DragDropState.nodesRef) {
      const nodes = Object.values(DragDropState.nodesRef[bucketName])

      nodes.forEach(itm => {
        if(itm?.contains(e.target)) contains = true
      })
    }

    if(contains == false) {
      dispatch(DNDAction.reset({ objectId: bucketName }))
    }
  }, [])

  // handle out cancel selection on outside click
  useEffect(() => {
    if(selectionList.length && !DragDropState.addedEventListener) {
      DragDropState.addedEventListener = true
      document.body.addEventListener('click', handleOutsideClick)
    } else if(DragDropState.addedEventListener && !selectionList.length){
      DragDropState.addedEventListener = false
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [selectionList.length])

  const getStyle = () => {
    if(isSelected) return selectionStyle
    if(isOverCurrent) return dropAreaStyle
    return style 
  }

  return (
    <div
      ref={(r) => {
        if(!(bucketName in DragDropState.nodesRef)) DragDropState.nodesRef[bucketName] = {}
        DragDropState.nodesRef[bucketName][id] = r
      }}
      style={{ opacity: isDragging || (isSelected && state.dragging) ? 0.4 : 1 }}
    >
      <div
        id={ddId}
        ref={(r) => {
          if(draggable) drag(r);
          if(enableDrop && !isSelected) drop(r);
        }}
        onDragStart={(e) => {
          if(!isSelected) {
            dispatch(DNDAction.reset({ objectId: bucketName }))
            dispatch(DNDAction.addItem({ objectId: bucketName, id, value: { ...data, type } }))
          }
        }}
        onClickCapture={(e) => {
          if(e.metaKey || e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation()
            if(isSelected) {
              dispatch(DNDAction.removeItem({ objectId: bucketName, id }))
            } else {
              dispatch(DNDAction.addItem({ objectId: bucketName, id, value: { ...data, type } }))
            }
          } else {
            dispatch(DNDAction.reset({ objectId: bucketName }))
          }
        }}
        style={getStyle()}
      >
        { React.cloneElement(React.Children.only(children), { isOverCurrent }) }
      </div>
    </div>
  )
}

function getItemStyles(
  initialCursorOffset,
  initialOffset,
  currentOffset
) {
  if (!initialOffset || !currentOffset || !initialCursorOffset) {
    return {
      display: "none",
    };
  }

  const x = initialCursorOffset?.x + (currentOffset.x - initialOffset.x);
  const y = initialCursorOffset?.y + (currentOffset.y - initialOffset.y);
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
    background: "red",
    width: "200px",
  };
}


export const CustomDragLayer = ({
    selected
}) => {
  const {
    itemType,
    isDragging,
    initialCursorOffset,
    initialFileOffset,
    currentFileOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialCursorOffset,
          initialFileOffset,
          currentFileOffset
        )}
      >
        <div></div>
      </div>
    </div>
  );
};

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};