import { store } from "../utilityFunctions/Store";
import { Locker } from "./locker";

const onClickLock = () => {
    Locker.LockMeshes(store.selectionStack);
};

const onClickUnlock = () => {
    Locker.UnlockMeshes(store.selectionStack);
};

export { onClickLock, onClickUnlock };
