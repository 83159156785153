import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  findMeshExtremePointsBBInfo,
  meshIntersects,
  meshLSIntersect,
  getWorldVectorTopBottomCoords,
  findWorldVectorExtremes,
  meshLSIntersectSlower,
  getWallMeshLineSegmentSlower,
  calulateAbsDistanceBtwPoint,
} from "./wall_generation_helper.js";
import { moveWallMesh, moveMeshInGroup } from "./wallMovement.js";
import { unclick } from "./meshEvents.js";
import { removeDuplicates } from "./arrayFuncs.js";
import { calculateEuclidianDistance } from "./polygon_dip_workers.js";
function findWallNeighboursSync(meshes) {
  var tTypeMeshArr = [];
  for (let i = 0; i < meshes.length; i++) {
    let curr_mesh = meshes[i];
    let thickness = 15.50003056361041657544 * store.unit_absolute_scale * 1.5;
    const mesh1_extremes = findMeshExtremePointsBBInfo(curr_mesh);
    curr_mesh.neighborsWallMesh = [];

    for (let b = 0; b < store.doublyLinkedList.size(); b++) {
      let meshbNode = store.doublyLinkedList.item(b);
      let meshb = meshbNode.data;
      const mesh2_extremes = findMeshExtremePointsBBInfo(meshb);

      let intersectObj = meshIntersects(
        curr_mesh,
        meshb,
        thickness,
        true,
        mesh1_extremes,
        mesh2_extremes
      );
      if (
        curr_mesh.uniqueId !== meshb.uniqueId &&
        curr_mesh.level === meshb.level &&
        intersectObj.value
      ) {
        if (!meshb.neighborsWallMesh) meshb.neighborsWallMesh = [];

        curr_mesh.neighborsWallMesh.push({
          id: meshb.uniqueId,
          type: intersectObj.type,
        });
        let flag = false;
        for (let k = 0; k < meshb.neighborsWallMesh.length; k++) {
          if (meshb.neighborsWallMesh[k].id === curr_mesh.uniqueId) {
            meshb.neighborsWallMesh[k] = {
              id: curr_mesh.uniqueId,
              type: intersectObj.type,
            };
            flag = true;
          }
        }
        if (!flag)
          meshb.neighborsWallMesh.push({
            id: curr_mesh.uniqueId,
            type: intersectObj.type,
          });
      }

      if (
        curr_mesh.uniqueId !== meshb.uniqueId &&
        curr_mesh.level === meshb.level &&
        meshLSIntersect(curr_mesh, meshb, 0, false).value
      ) {
        tTypeMeshArr.push({
          mesh: curr_mesh.uniqueId,
          height: mesh1_extremes.yMax,
        });
        tTypeMeshArr.push({
          mesh: meshb.uniqueId,
          height: mesh2_extremes.yMax,
        });
      }
    }
  }
  return tTypeMeshArr;
}

function getRelationMeshRange(newCoords, oldCoords) {
  var meshRangeArr = [];
  let bottomCoords1 = getWorldVectorTopBottomCoords(newCoords).bottom;
  let bottomCoords2 = getWorldVectorTopBottomCoords(oldCoords).bottom;

  let bbinfo1 = findWorldVectorExtremes(bottomCoords1);
  let bbinfo2 = findWorldVectorExtremes(bottomCoords2);

  if (
    Math.abs(bbinfo1.xMax - bbinfo1.xMin) >
    Math.abs(bbinfo1.zMax - bbinfo1.zMin)
  ) {
    meshRangeArr.push({
      index: 0,
      range: [bbinfo1.xMin / store.unit_scale, bbinfo1.xMax / store.unit_scale],
    });
    meshRangeArr.push({
      index: 1,
      range: [bbinfo2.xMin / store.unit_scale, bbinfo2.xMax / store.unit_scale],
    });
  } else {
    meshRangeArr.push({
      index: 0,
      range: [
        -bbinfo1.zMax / store.unit_scale,
        -bbinfo1.zMin / store.unit_scale,
      ],
    });
    meshRangeArr.push({
      index: 1,
      range: [
        -bbinfo2.zMax / store.unit_scale,
        -bbinfo2.zMin / store.unit_scale,
      ],
    });
  }
  return meshRangeArr;
}

function findWallNeighbours() {
  return new Promise(function (resolve, reject) {
    var tTypeMeshArr = [];
    let len = store.doublyLinkedList.size();
    for (let a = 0; a < len - 1; a++) {
      let meshaNode = store.doublyLinkedList.item(a);
      let mesha = meshaNode.data;
      let thickness = mesha._properties._thickness * 2 * store.unit_scale;
      const mesh1_extremes = findMeshExtremePointsBBInfo(mesha);
      if (!mesha.neighborsWallMesh) {
        mesha.neighborsWallMesh = [];
      }
      for (let b = a + 1; b < len; b++) {
        let meshbNode = store.doublyLinkedList.item(b);
        let meshb = meshbNode.data;
        const mesh2_extremes = findMeshExtremePointsBBInfo(meshb);

        let intersectObj = meshIntersects(
          mesha,
          meshb,
          thickness,
          true,
          mesh1_extremes,
          mesh2_extremes
        );
        if (
          mesha.uniqueId !== meshb.uniqueId &&
          mesha.level === meshb.level &&
          intersectObj.value
        ) {
          if (!meshb.neighborsWallMesh) {
            meshb.neighborsWallMesh = [];
          }
          mesha.neighborsWallMesh.push({
            id: meshb.uniqueId,
            type: intersectObj.type,
          });
          meshb.neighborsWallMesh.push({
            id: mesha.uniqueId,
            type: intersectObj.type,
          });
        }
        if (
          mesha.uniqueId !== meshb.uniqueId &&
          mesha.level === meshb.level &&
          meshLSIntersect(mesha, meshb, 0, false).value
        ) {
          tTypeMeshArr.push({
            mesh: mesha.uniqueId,
            height: mesh1_extremes.yMax,
          });
          tTypeMeshArr.push({
            mesh: meshb.uniqueId,
            height: mesh2_extremes.yMax,
          });
        }
      }
    }
    resolve(tTypeMeshArr);
  });
}

async function assignWallNeighbours() {
  /*
    TODO: Do not resolve TJoints for angled walls (Angled in top view)
     */
  var tTypeMeshArr = await findWallNeighbours();
  if (tTypeMeshArr.length > 1) {
    var resolveJoints = new Promise(function (resolve, reject) {
      for (let i = 0; i < tTypeMeshArr.length; i += 2) {
        this.mesh1 = store.scene.getMeshByUniqueID(tTypeMeshArr[i].mesh);
        this.mesh2 = store.scene.getMeshByUniqueID(tTypeMeshArr[i + 1].mesh);

        if (!meshLSIntersectSlower(this.mesh1, this.mesh2, 0, false).value) {
          tTypeMeshArr.splice(i, 2);
        }
      }
      //  resolveTtypeJoints(tTypeMeshArr);
      resolve();
    });
    resolveJoints.then(function () {
      return;
    });
  }
}

function assignWallNeighboursSync(meshArr) {
  let neighbours = meshArr[0].neighborsWallMesh;

  for (let l = 0; l < neighbours.length; l++) {
    let mesh = store.scene.getMeshByUniqueID(neighbours[l].id);
    for (let k = 0; k < mesh.neighborsWallMesh.length; ) {
      if (mesh.neighborsWallMesh[k].id === meshArr[0].uniqueId) {
        mesh.neighborsWallMesh.splice(k, 1);
        console.log("**********Deleted ************");
      } else k++;
    }
  }

  var tTypeMeshArr = findWallNeighboursSync(meshArr);

  for (let i = 0; i < tTypeMeshArr.length - 2; i += 2) {
    for (let j = i + 2; j < tTypeMeshArr.length; j += 2) {
      if (
        tTypeMeshArr[i].mesh + tTypeMeshArr[i + 1].mesh ===
        tTypeMeshArr[j].mesh + tTypeMeshArr[j + 1].mesh
      )
        tTypeMeshArr.splice(j, 2);
    }
  }
  if (tTypeMeshArr.length > 1) {
    // resolveTtypeJoints(tTypeMeshArr);
  }
}

function resolveTtypeJoints(meshes, extendJoints = false) {
  let len = meshes.length;
  let d, e, f;
  for (let i = 0, j = 1; i < len - 1; i += 2, j += 2) {
    let mesh1 = store.scene.getMeshByUniqueID(meshes[i].mesh);
    let mesh2 = store.scene.getMeshByUniqueID(meshes[j].mesh);
    let face = {};

    if (meshes[i].height > meshes[j].height || mesh1.isResolvedTjoint) {
      mesh1 = store.scene.getMeshByUniqueID(meshes[j].mesh);
      mesh2 = store.scene.getMeshByUniqueID(meshes[i].mesh);
    }
    console.log("*** T joints ***");
    console.log(mesh2._properties._thickness);
    let mesh1_ls = getWallMeshLineSegmentSlower(mesh1);
    let mesh2_ls = getWallMeshLineSegmentSlower(mesh2);

    let vec1 = new BABYLON.Vector3(
      mesh1_ls[3] - mesh1_ls[0],
      0,
      mesh1_ls[4] - mesh1_ls[1]
    );
    let vec2 = new BABYLON.Vector3(
      mesh2_ls[3] - mesh2_ls[0],
      0,
      mesh2_ls[4] - mesh2_ls[1]
    );

    let mesh2_thickness = mesh2._properties._thickness * store.unit_scale;
    // let cos_theta = (BABYLON.Vector3.Dot(vec1, vec2))/(vec1.length() * vec2.length)
    // mesh2_thickness = mesh2_thickness/cos_theta;

    let mesh1BBinfoMin = mesh1.getBoundingInfo().boundingBox.minimumWorld;
    let mesh1BBinfoMax = mesh1.getBoundingInfo().boundingBox.maximumWorld;
    let mesh2BBinfoMin = mesh2.getBoundingInfo().boundingBox.minimumWorld;
    let mesh2BBinfoMax = mesh2.getBoundingInfo().boundingBox.maximumWorld;

    if (
      mesh1BBinfoMax.x - mesh1BBinfoMin.x >
      mesh1BBinfoMax.z - mesh1BBinfoMin.z
    ) {
      let near_point1 =
        (store.c = calulateAbsDistanceBtwPoint(
          mesh1BBinfoMax.x,
          mesh2BBinfoMin.x
        )) >
        (d = calulateAbsDistanceBtwPoint(mesh1BBinfoMax.x, mesh2BBinfoMax.x))
          ? d
          : store.c;
      let near_point2 =
        (e = calulateAbsDistanceBtwPoint(mesh1BBinfoMin.x, mesh2BBinfoMin.x)) >
        (f = calulateAbsDistanceBtwPoint(mesh1BBinfoMin.x, mesh2BBinfoMax.x))
          ? f
          : e;
      face =
        near_point1 < near_point2
          ? { type: "max", value: mesh1BBinfoMax.x, axis: "x" }
          : { type: "min", value: mesh1BBinfoMin.x, axis: "x" };
    } else {
      let near_point1 =
        (store.c = calulateAbsDistanceBtwPoint(
          mesh1BBinfoMax.z,
          mesh2BBinfoMin.z
        )) >
        (d = calulateAbsDistanceBtwPoint(mesh1BBinfoMax.z, mesh2BBinfoMax.z))
          ? d
          : store.c;
      let near_point2 =
        (e = calulateAbsDistanceBtwPoint(mesh1BBinfoMin.z, mesh2BBinfoMin.z)) >
        (f = calulateAbsDistanceBtwPoint(mesh1BBinfoMin.z, mesh2BBinfoMax.z))
          ? f
          : e;
      face =
        near_point1 < near_point2
          ? { type: "max", value: mesh1BBinfoMax.z, axis: "z" }
          : { type: "min", value: mesh1BBinfoMin.z, axis: "z" };
    }

    // let nearest_point_x = ((a = calculateEuclidianDistance([mesh1.extremes.xMax,0],[mesh2_extremes.xMin, 0]))) > (( b= calculateEuclidianDistance([mesh1.extremes.xMax,0],[mesh2_extremes.xMax, 0])))?b:a;
    //let nearest_point_y = ((c = calculateEuclidianDistance([0,mesh1.extremes.zMax],[0,mesh2_extremes.zMin]))) > (( d= calculateEuclidianDistance([0,mesh1.extremes.zMax],[0,mesh2_extremes.xMax])))?d:c;
    console.log("mesh2_thickness :" + mesh2_thickness);
    console.log(face, mesh1.uniqueId, mesh2.uniqueId);
    mesh1.isResolvedTjoint = true;

    if (extendJoints) moveWallMesh(mesh1, face, -mesh2_thickness);
    else moveWallMesh(mesh1, face, mesh2_thickness);
    // delete NOT ALLOWED IN STRICT MODE
    // delete mesh1;
    // delete mesh2;
    // ALTERNATIVE
    mesh1 = null;
    mesh2 = null;
  }
}

function refreshConnectionBefore(mesh) {
  let neighbours = mesh.neighborsWallMesh;
  let curr_mesh;
  mesh.moveMeshObj = null;
  mesh.lineSegmentForm = null;
  unclick(mesh);

  neighbours.forEach(function (item) {
    try {
      let currMesh = store.scene.getMeshByUniqueID(item.id);
      currMesh.lineSegmentForm = null;
      delete curr_mesh.moveMeshObj;

      moveMeshInGroup(currMesh);
    } catch (e) {
      console.log(e);
    }
  });
}

function refeshConnection(mesh) {
  let neighbours = mesh.neighborsWallMesh;

  mesh.moveMeshObj = null;
  mesh.lineSegmentForm = null;
  //unclick(mesh);

  neighbours.forEach(function (item) {
    try {
      let currMesh = store.scene.getMeshByUniqueID(item.id);

      if (!currMesh) {
        let index = mesh.neighborsWallMesh.findIndex(function (element) {
          return element.id === item.id;
        });
        mesh.neighborsWallMesh.splice(index, 1);
      }
      currMesh.lineSegmentForm = null;
      moveMeshInGroup(currMesh);
      // let bbinfo = currMesh.getBoundingInfo();
      // let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      // currMesh.setPivotPoint(centroid);

      if (currMesh.checkForExtention) {
        extendMesh(currMesh, mesh);
      }
      // if(currMesh.originalVerData) {
      //     currMesh.geometry.setVerticesData(BABYLON.VertexBuffer.PositionKind, currMesh.originalVerData, true);
      //     let bbinfo = currMesh.getBoundingInfo();
      //  let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      //      currMesh.setPivotPoint(centroid);
      //     delete currMesh.originalVerData;
      // }
      // if(currMesh.checkForOverlap) {
      //     console.log("checking for new wall overlap");
      //     currMesh.top_cords = [];
      //     currMesh.original_cords = [];
      //     let worldCoords = getMeshTopBottomWorldCoords(currMesh);
      //     worldCoords.top.forEach(function(item){
      //             currMesh.top_cords.push([item.x/unit_scale, -item.z/unit_scale, item.y/unit_scale]);
      //     });
      //     worldCoords.bottom.forEach(function(item){
      //             currMesh.original_cords.push([item.x/unit_scale, -item.z/unit_scale, item.y/unit_scale]);
      //     });
      // checkWallOverlap("", currMesh);
      // }

      delete currMesh.checkForOverlap;
      delete currMesh.checkForExtention;

      delete currMesh.moveMeshObj;
      delete currMesh.wallJunctions;

      moveMeshInGroup(currMesh);

      let bbinfo = currMesh.getBoundingInfo();
      let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      currMesh.setPivotPoint(centroid);
    } catch (e) {
      console.log(e, "Mesh by id " + item.id + " not found !");
    }
  });
  // let bbinfo = mesh.getBoundingInfo();
  // let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  // mesh.setPivotPoint(centroid);
}

function extendVerData(mesh, nearPointIndexes, thickness, theta = 0) {
  let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  for (let i = 0; i < nearPointIndexes.length; i++) {
    verData[nearPointIndexes[i]] += thickness * Math.cos(theta);
    verData[nearPointIndexes[i] + 2] += thickness * Math.sin(theta);
  }

  mesh.geometry.setVerticesData(
    BABYLON.VertexBuffer.PositionKind,
    verData,
    true
  );
  mesh.refreshBoundingInfo();
  let bbinfo = mesh.getBoundingInfo();
  let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mesh.setPivotPoint(centroid);
}

function extendMesh(mesh, movingMesh) {
  let neighbors = mesh.moveMeshObj;
  let junction = mesh.wallJunctions;

  let doneFlag = false;

  for (let point in junction) {
    let meshArr;
    if (junction[point].nonCollinear) {
      junction[point].nonCollinear = removeDuplicates(
        junction[point].nonCollinear
      );
      meshArr = junction[point].nonCollinear;
    } else meshArr = [];

    let meshArr2;
    if (junction[point].collinear) {
      junction[point].collinear = removeDuplicates(junction[point].collinear);
      meshArr2 = junction[point].collinear;
    } else meshArr2 = [];

    let curr_mesh;
    if (meshArr.length > 0) {
      for (let j = 0; j < meshArr.length; j++) {
        curr_mesh = store.scene.getMeshByUniqueID(meshArr[j]);
        doneFlag =
          meshLSIntersect(
            mesh,
            curr_mesh,
            curr_mesh._properties._thickness * store.unit_scale * 2,
            false,
            mesh.slopeTheta
          ).value ||
          meshLSIntersect(
            curr_mesh,
            mesh,
            mesh._properties._thickness * store.unit_scale * 2,
            false,
            curr_mesh.slopeTheta
          ).value;
        if (doneFlag) break;
      }
      if (!doneFlag) {
        for (let k = 0; k < meshArr2.length; k++) {
          let curr_mesh2 = store.scene.getMeshByUniqueID(meshArr2[k]);
          doneFlag = meshLSIntersect(
            mesh,
            curr_mesh2,
            0,
            true,
            mesh.slopeTheta
          ).value;
          if (doneFlag) break;
        }
      }
      if (!doneFlag) {
        let bbinfo = curr_mesh.getBoundingInfo();
        let tempN = mesh.neighborsWallMesh.find(function (item) {
          return item.id === curr_mesh.uniqueId;
        });
        let diff = mesh._properties._thickness * store.unit_scale;
        diff =
          calculateEuclidianDistance(JSON.parse(tempN.nearPoint), [
            bbinfo.maximum.x,
            bbinfo.maximum.z,
          ]) <
          calculateEuclidianDistance(JSON.parse(tempN.nearPoint), [
            bbinfo.minimum.x,
            bbinfo.minimum.z,
          ])
            ? diff
            : -diff;

        for (let orient in neighbors) {
          let obj = neighbors[orient].find(function (item) {
            return (
              item.mesh.uniqueId === curr_mesh.uniqueId ||
              item.mesh === curr_mesh.uniqueId
            );
          });
          if (obj) {
            extendVerData(
              curr_mesh,
              obj.nearPointIndexes,
              diff,
              curr_mesh.slopeTheta
            );
          }
        }

        delete curr_mesh.moveMeshObj;
        moveMeshInGroup(curr_mesh);
      }
    }
  }

  delete mesh.moveMeshObj;
  delete mesh.wallJunctions;

  moveMeshInGroup(mesh);

  // for(let j=0;j<neighbors.clkWiseMesh.length;j++){
  //     let curr_mesh = neighbors.clkWiseMesh[j].mesh;
  //     if(movingMesh.uniqueId !== curr_mesh.id || true){
  //         flagClkWise = meshLSIntersect(mesh, curr_mesh, curr_mesh._properties._thickness*unit_scale*2, false, mesh.slopeTheta).value;
  //         if(flagClkWise) break;
  //     }
  // }
  //
  // for(let j=0;j<neighbors.counterClkWiseMesh.length;j++){
  //     let curr_mesh = neighbors.counterClkWiseMesh[j].mesh;
  //     if(movingMesh.uniqueId !== curr_mesh.id || true){
  //         flagCounterClkWise = meshLSIntersect(mesh, curr_mesh, curr_mesh._properties._thickness*unit_scale*2, false, mesh.slopeTheta).value;
  //         if(flagCounterClkWise) break;
  //     }
  // }

  // if(!flagClkWise) {
  //     console.log("**************Extending Neighbour Mesh***************");
  //     let bbinfo = neighbors.clkWiseMesh[0].mesh.getBoundingInfo().boundingBox;
  //     let tempN = mesh.neighborsWallMesh.find(function (item) {
  //         return item.id === neighbors.clkWiseMesh[0].mesh.uniqueId;
  //     });
  //     let diff = mesh._properties._thickness*unit_scale;
  //     diff = calculateEuclidianDistance(JSON.parse(tempN.nearPoint), [bbinfo.maximumWorld.x, bbinfo.maximumWorld.z]) < calculateEuclidianDistance(JSON.parse(tempN.nearPoint), [bbinfo.minimumWorld.x, bbinfo.minimumWorld.z])? diff:-diff;
  //     extendVerData(neighbors.clkWiseMesh[0].mesh, neighbors.clkWiseMesh[0].nearPointIndexes, diff, neighbors.clkWiseMesh[0].mesh.slopeTheta);
  //     //flagCounterClkWise = true;
  // }
  //  else if(!flagCounterClkWise) {
  //     console.log("**************Extending Neighbour Mesh***************");
  //     let bbinfo = neighbors.clkWiseMesh[0].mesh.getBoundingInfo().boundingBox;
  //     let tempN = mesh.neighborsWallMesh.find(function (item) {
  //         return item.id === neighbors.clkWiseMesh[0].mesh.uniqueId;
  //     });
  //     let diff = mesh._properties._thickness*unit_scale;
  //     diff = calculateEuclidianDistance(JSON.parse(tempN.nearPoint), [bbinfo.maximumWorld.x, bbinfo.maximumWorld.z]) < calculateEuclidianDistance(JSON.parse(tempN.nearPoint), [bbinfo.minimumWorld.x, bbinfo.minimumWorld.z])? diff:-diff;
  //
  //     extendVerData(neighbors.counterClkWiseMesh[0].mesh, neighbors.counterClkWiseMesh[0].nearPointIndexes, diff, neighbors.counterClkWiseMesh[0].mesh.slopeTheta);
  // }
}
export {
  findWallNeighboursSync,
  getRelationMeshRange,
  findWallNeighbours,
  assignWallNeighbours,
  assignWallNeighboursSync,
  resolveTtypeJoints,
  refreshConnectionBefore,
  refeshConnection,
  extendVerData,
  extendMesh,
};
