import { dimensionsTuner } from "../sketchMassBIMIntegration/dimensionsTuner";
import { isTwoDimension } from "../../libs/twoDimension";
import _ from "lodash";
import { getActiveStoreyObject } from "../extrafunc";
import { getFaceVerticesFromFace } from "../../libs/brepOperations";
import BABYLON from '../babylonDS.module';
import { store } from "../utilityFunctions/Store";

const dimensionsDiagnostics = (function (){

  const enable = function (){
      dimensionsTuner.debug.on();
  };

  const disable = function (){
      dimensionsTuner.debug.off();
  };

  const clean = function (){
      dimensionsTuner.debug.clean();
  };

  const checkDimensions = function (components){
      if (!store.$scope.isTwoDimension) return;
      if (!components) components = getActiveStoreyObject().elements;

      dimensionsTuner.debug.highlight(components);
  };

  const printMetadata = function (components){
      if (!_.isArray(components)) components = [components];

      components.forEach(c => {
          c.brep.faces.forEach(face => {
              console.log(face.autoDimensionData?.movementAmount);
              console.log(getFaceVerticesFromFace(face, c.mesh, BABYLON.Space.WORLD));
          });
      });
  };

  return {
      enable,
      disable,
      clean,
      checkDimensions,
      printMetadata
  };

})();

export {
  dimensionsDiagnostics,
};
