import io from "socket.io-client";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { AutoSave } from "./autoSave.js";
import { refreshAccessToken, getJWT } from "../../../services/jwt.service.js";
import { socketUrl as globalSocketUrl } from "../../../services/url.constants.js";
import { Role } from "./role.js";
import { cameraFollow } from "./cameraFollow.js";
import { mouseMove } from "./mouseMove.js";
import { ForgeConnection } from "../forgeConnection/forgeConnection";
import { autoListener } from "./autoListener.js";
import { ActiveUserList, CollaborationNotification } from "./notification.js";
import { socialConnect } from "../social/socialSocket.js";
import { showToast } from "../extrafunc.js";
import authService from "../../../services/auth.service.js";
import CameraCursor from "../cursor/cameraCursor.js";
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { ChangelogConnect } from "./changelog.js";


const autoSaveConfig = (function () {
  let socketUrl = globalSocketUrl;
  const author = {
    id: store.infoUser.id,
    email: store.infoUser.email,
    name: store.infoUser.name
  };
  let autoSaveSocket;
  let room_name = "room";
  let refreshCounter = 0;
  let REFRESH_THRESHOLD = 20;

  var socketStatus = {
    value: "disconnected",
    connected: false,
    connected_room: room_name,
  };

  const connectMessage =
    "Connection re-established! Your work is getting auto saved.";
  const disconnectMessage =
    "Please check the network connectivity. Your work might not get saved.";
  const autoSaveFailureMessage = "AutoSave is facing problems";
  const savingMessage =
    "Your project is being AutoSaved. Please do not close or refresh the page ";
  const saveCompleteMessage = "Project has been AutoSaved 👍";
  const autoSaveFailureNewMessage =
    "That last operation wasn't saved. Don't worry, just refresh your project and continue designing.";

  const asciiSpinners = [
    "⢀⠀",
    "⡀⠀",
    "⠄⠀",
    "⢂⠀",
    "⡂⠀",
    "⠅⠀",
    "⢃⠀",
    "⡃⠀",
    "⠍⠀",
    "⢋⠀",
    "⡋⠀",
    "⠍⠁",
    "⢋⠁",
    "⡋⠁",
    "⠍⠉",
    "⠋⠉",
    "⠋⠉",
    "⠉⠙",
    "⠉⠙",
    "⠉⠩",
    "⠈⢙",
    "⠈⡙",
    "⢈⠩",
    "⡀⢙",
    "⠄⡙",
    "⢂⠩",
    "⡂⢘",
    "⠅⡘",
    "⢃⠨",
    "⡃⢐",
    "⠍⡐",
    "⢋⠠",
    "⡋⢀",
    "⠍⡁",
    "⢋⠁",
    "⡋⠁",
    "⠍⠉",
    "⠋⠉",
    "⠋⠉",
    "⠉⠙",
    "⠉⠙",
    "⠉⠩",
    "⠈⢙",
    "⠈⡙",
    "⠈⠩",
    "⠀⢙",
    "⠀⡙",
    "⠀⠩",
    "⠀⢘",
    "⠀⡘",
    "⠀⠨",
    "⠀⢐",
    "⠀⡐",
    "⠀⠠",
    "⠀⢀",
    "⠀⡀",
  ];

  const failureMessageDuration = 5000; //5s
  const shortMessageDuration = 3000; //3s
  const saveSuccessMessage = "resolved";
  const toastError = "error";
  const toastSuccess = "success";
  const unauthorized = "unauthorized";
  const expired = "expired";
  const owner = "owner";

  let toastsAllowed = true;
  let saveDisabled = false;

  let CONSTANTS = {
    connectMessage,
    disconnectMessage,
    autoSaveFailureMessage,
    autoSaveFailureNewMessage,
    failureMessageDuration,
    connectionMessageDuration: failureMessageDuration,
    disconnectionMessageDuration: 0,
    shortMessageDuration,
    saveSuccessMessage,
    savingMessage,
    saveCompleteMessage,
    asciiSpinners,
    toastError,
    toastSuccess,
    unauthorized,
    expired,
    owner,
    waitForReconnectionDelay: 3000,
  };

  const connectDisconnectSynchronizer = {
    waitingForReconnection: false,
    disconnectTime: null,
    showingDisconnectMessage: false,
    timeoutId: null,
  };

  const connectSocket = (floorkey) => {
    if(!floorkey) floorkey = store.floorkey;
    const selfObject = {
      color: 'ff0000',
      user: store.infoUser,
    };
    ActiveUserList.selfPopulate(selfObject);
    const {projectMetadata} = reduxStore.getState();
    const joinProject = (socket) => {
      room_name = "room-" + store.floorkey;
      let params = {
        room: room_name,
        user: store.infoUser.id,
        lastCommandId: AutoSave.getLatestSave() ? AutoSave.getLatestSave().id : null,
        sessionId: store.sessionId ? store.sessionId : false,
        author: author,
        jwt: getJWT(),
        teamId: projectMetadata.isTeamsProject ? projectMetadata.team.id : null
      };

      if(!Role.get()){
        params.lookForUpdate = {
            role: true
        };
      }

      socket.emit("join", params, response => {
        if(response && response.message && response.message === autoSaveConfig.CONSTANTS.expired){
          if(refreshCounter > REFRESH_THRESHOLD){
            console.log("Token expired");
            const user = authService.getCurrentUser();
            if(user.email) showToast("Session timed out, Please login again");
            return;
          }
          refreshCounter++;
          refreshAccessToken()
            .then(() => {
              joinProject(socket);
            })
            .catch(err => {
              console.log(`Error in joining project ${err}`);
            });
        }
        if (response.version !== store.version) {
          // userpilot.trigger("1616150204uBja8137");
        }
        if(response.missedCommands){
          for(let i = 0; i < response.missedCommands.length; i++){
              autoListener(response.missedCommands[i]);
          }
        }
        if(response.sessionId){
            store.sessionId = response.sessionId;
        }
        if(response.role){
            Role.set(response.role);
        }
        if(response.color){
          const selfObject = {
            color: response.color,
            user: store.infoUser,
          };
          ActiveUserList.selfPopulate(selfObject);
        }
        if(response.activeSession){
          ActiveUserList.populate(response.activeSession);
        }
        cameraFollow.init(autoSaveSocket);

        // TODO: PRANAV
        // TODO: ADITYA
        // get canvas for collab
        mouseMove.init(autoSaveSocket);
        // mouseMove.broadcast();
        const cameraCursor = new CameraCursor();
        cameraCursor.updateSocket(autoSaveSocket);
        ForgeConnection.connectSocket();
        socialConnect.connectSocket(store.floorkey);
        ChangelogConnect.init(store.floorkey);
      });
    };

    if(!floorkey){
      floorkey = store.floorkey;
    }
    autoSaveSocket = io(socketUrl, {
      reconnection: true,
      forceNew: false,
      transports: ["websocket"],
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
    });

    autoSaveSocket.on("connect", () => {
      console.log("Socket Connected");
      joinProject(autoSaveSocket);
      // room_name = "room-" + store.floorkey;
      // let params = {
      //   room: room_name,
      //   jwt: localStorage.user,
      // };

      // autoSaveSocket.emit("join", params, function (response) {
      //   if(response && response.message && response.message === autoSaveConfig.CONSTANTS.expired){

      //   }
      //   if (response.version !== store.version) {
      //     userpilot.trigger("1616150204uBja8137");
      //   }
      // });
      socketStatus.value = "connected";
      socketStatus.connected = true;
      socketStatus.connected_room = room_name;

      AutoSave.markAsReady();

      if (connectDisconnectSynchronizer.waitingForReconnection) {
        const waitedFor =
          window.performance.now() -
          connectDisconnectSynchronizer.disconnectTime;
        console.log("Socket reconnected in", waitedFor / 1000, "s");
        if (connectDisconnectSynchronizer.showingDisconnectMessage) {
          // was disconnected for more than 'waitForReconnectionDelay' milliseconds
          // disconnected toast would've been shown, so show the reconnected toast as well
          AutoSave.showToast(
            connectMessage,
            CONSTANTS.connectionMessageDuration,
            toastSuccess
          );
        }
        else {
          clearTimeout(connectDisconnectSynchronizer.timeoutId);
        }

        _resetConnectDisconnectSynchronizer();
      }

      AutoSave.executeNext();
    });

    autoSaveSocket.on("disconnect", (reason) => {
      mouseMove.deleteAllClients();
      console.log(`Socket Disconnected: ${reason}`);
      socketStatus.value = `Disconnected: ${reason}`;

      // show toast only if disconnected even after 'waitForReconnectionDelay' milliseconds
      const timeoutId = setTimeout(() => {
        if (autoSaveSocket && autoSaveSocket.disconnected && connectDisconnectSynchronizer.waitingForReconnection) {
          AutoSave.showToast(
            disconnectMessage,
            CONSTANTS.disconnectionMessageDuration,
            toastError
          );
          connectDisconnectSynchronizer.showingDisconnectMessage = true;
        }
      }, CONSTANTS.waitForReconnectionDelay);

      AutoSave.markAsWaiting();

      connectDisconnectSynchronizer.waitingForReconnection = true;
      connectDisconnectSynchronizer.disconnectTime = window.performance.now();
      connectDisconnectSynchronizer.timeoutId = timeoutId;
    });

    autoSaveSocket.on("reconnect_attempt", (attemptNumber) => {
      // console.log("Attempting reconnection...", attemptNumber);
    });

    autoSaveSocket.on('justdo', autoListener);

    autoSaveSocket.on('joincollab', CollaborationNotification.joined);

    autoSaveSocket.on('leftcollab', CollaborationNotification.left);

    autoSaveSocket.on('incomingactivesessionlist', ActiveUserList.receivedList);

    autoSaveSocket.on('incominglog', ChangelogConnect.handleIncomingChangelog)

    autoSaveSocket.open();
  };

  const _resetConnectDisconnectSynchronizer = function () {
    connectDisconnectSynchronizer.waitingForReconnection = false;
    connectDisconnectSynchronizer.disconnectTime = null;
    connectDisconnectSynchronizer.showingDisconnectMessage = false;
    connectDisconnectSynchronizer.timeoutId = null;
  };

  const getSocketObject = () => {
    if (autoSaveSocket) {
      // verifyConnection();
    } else {
      connectSocket(store.floorkey);
    }
    return autoSaveSocket;
  };

  const verifyConnection = () => {
    // if (autoSaveSocket.disconnected) autoSaveSocket.connect();
    // trying to connect manually, overrides and disables automatic reconnection attempts
    // if (autoSaveSocket.disconnected) AutoSave.handleFailure(autoSaveConfig.CONSTANTS.disconnectMessage);
  };

  const isDisconnected = () => {
    return autoSaveSocket.disconnected;
  };

  const areToastsAllowed = function () {
    return toastsAllowed;
  };

  const disableToasts = function () {
    toastsAllowed = false;
  };

  const enableToasts = function () {
    toastsAllowed = true;
  };

  const disable = function () {
    saveDisabled = true;
  };

  const enable = function () {
    saveDisabled = false;
  };

  const isDisabled = function () {
    return saveDisabled;
  };

  const getSocketUrl = function () {
    return socketUrl;
  };

  const getSocketStatus = function(){
    return socketStatus.value;
  };

  const forceDisconnect = function(){
    if(autoSaveSocket){
      autoSaveSocket.emit('forceDisconnect');
    }
    autoSaveSocket = undefined;
  };

  const emptyCounter = () => {
    refreshCounter = 0;
  };

  return {
    CONSTANTS,
    connectSocket,
    getSocketObject,
    getSocketStatus,
    verifyConnection,
    areToastsAllowed,
    enableToasts,
    disableToasts,
    isDisabled,
    enable,
    disable,
    getSocketUrl,
    isDisconnected,
    forceDisconnect,
    emptyCounter,
  };
})();
export { autoSaveConfig };
