import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import * as constants from "./constants";
import tile from "../../assets/images/tile.svg";
import Edit from "../../assets/icons/edit.svg";
import Input from "../Input/index";
import CustomSlider from "../Slider/index";
import overlay from "../../assets/icons/overlay.svg";
import Button from "../Button/index";
import ScrollableDropdown from "../ScrollableDropdown/index";
import useOnClickOutside from "../../common/utils/onOutsideClickHandler";
import {useDispatch, useSelector} from "react-redux";
import {setMenuMode, updateMaterialValue} from "../../snaptrude/stateManagers/reducers/objectProperties/materialsSlice"
import {MATERIAL_MENU, MATERIAL_OVERLAY_MENU, SAVE_EDITS} from "./constants";
import reduxStore from "../../snaptrude/stateManagers/store/reduxStore";
import * as StyledComponents from "../../containers/editor/StyledEditorClasses";
import {
   basicInputKeyPressHandler
} from "../../snaptrude/libs/keyEvents";
import editMaterial from "../../snaptrude/libs/materialEditUI";
import _ from "lodash";
import Tooltip from "../Tooltip";

const Main = styled.div`
  position: relative;
  box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
  .container:before {
    content: "";
    position: absolute;
    top: 0;
    right: -2.1875rem;
    height: 2.1875rem;
    width: 2.1875rem;
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 20px 0 0 white;
    transform: rotate(90deg);
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 20.9375rem;
  height: 21.875rem;
  border-radius: 0.625em;
  background-color: ${colors.fullWhite};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  display: flex;
  align-items: center;
  flex-direction: center;
  .imageEditContainer {
    align-items: flex-start;
  }
`;

const TopContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
`;

const Heading = styled.div`
  align-self: flex-start;
  padding: 0.4375rem;
  padding-left: 0;
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.211789px;
    color: ${colors.darkGrey};
    margin: 0;
  }
`;

const EditImage = styled.div`
  position: relative;
  margin-right: 0.3125rem;
  img {
    border-radius: 0.3125rem;
    width: 5.3125rem;
    height: 5.3125rem;
  }
`;

const EditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.3125rem;
  display: flex;
  justify-content: flex-end;
  img {
    width: 0.9375rem;
    height: 0.9375rem;
  }
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
  padding: 0;
  margin: 0;
`;

const TopEditContainer = styled(TopContainer)`
  justify-content: space-between;
  padding: 0.25rem 0;
`;

const ButtonContainer = styled.div`
  width: 8.75rem;
  height: 1.5625rem;
  padding: 0.3125rem 0 0.9375rem 0;
  margin-top: 3rem;
`;

const DropdownWrapper = styled(TopContainer)`
  justify-content: space-between;
  margin: 0.25rem 0.625rem;
`;

const InputWrapperTop = styled(TopContainer)`
  justify-content: flex-start;
  margin: 0 0.3125rem;
  align-items: flex-start;
  .scaleInput {
    margin-bottom: 0.3125rem;
  }
`;

const InputColumn = styled.div`
  margin: 0 0.3125rem;
`;

const SliderContainer = styled(TopContainer)`
  justify-content: space-between;
  margin: 0.6rem 0.625rem 0.6rem;
`;

const OverlayContainer = styled(TopContainer)`
  margin-right: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`;

function MaterialEdit(props) {
  const { closePopUp, setSelectedSubMenu } = props;
  const [opacity, setOpacity] = useState(1);
  const [editHeader, setEditHeader] = useState("");
  const [editable, setEditable] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => closePopUp());
  
  const materialProperties = useSelector(state => state.materials.editMaterial);
  const dispatch = useDispatch();

  const [materialName, setMaterialName] = useState(materialProperties.name);

  useEffect(() => {
    setMaterialName(materialProperties.name);
  }, [materialProperties])
  const updateMaterialName = function (name) {
    editMaterial.updateName(name);
  }

  const saveAndGoBackToMainMenu = function (){
    editMaterial.executeCommand();
    // setSelectedSubMenu("");
    reduxStore.dispatch(setMenuMode(MATERIAL_MENU));
  };
  
  const goToOverlayMenu = function (){
    reduxStore.dispatch(setMenuMode(MATERIAL_OVERLAY_MENU));
  };
  
  const onSliderChange = function (value, property){
    const payload = {};
    payload[property] = value;
    reduxStore.dispatch(updateMaterialValue(payload));
    editMaterial.updateProperty(property, value);
  };
  
  const onSliderChangeComplete = function (){
  };
  
  const onInputComplete = function (value, property){
    if (_.isEmpty(value)) return;
    
    editMaterial.updateProperty(property, value);
  }
  
  const onChange = function (e, property){
    const payload = {};
    payload[property] = e.target.value;
    reduxStore.dispatch(updateMaterialValue(payload));
    editMaterial.updateProperty(property, e.target.value);
  };
  
  const onBlur = function (e, property){
    // onInputComplete(e.target.value, property);
  }
  
  const onKeyPress = (e) => basicInputKeyPressHandler(e);
  
  const formatMaterialName = function (name){
    if (!name) return "";
    if (name.length > 15){
      return name.substring(0, 12) + "...";
    }
    else return name;
  };

  return (
    <Main ref={ref}>
      <Container className="container">
        <Inner>
          {editable ? (
            <TopContainer style={{ padding: "0.625rem 0" }}>
              <Input
                type={"text"}
                required
                value={editHeader}
                customInputContainerStyle={{ width: "100%", zIndex: 10 }}
                placeholder={constants.MATERIAL_NAME_PLACEHOLDER}
                onPress={(e) => {
                  setEditHeader(e.target.value);
                }}
              />
            </TopContainer>
          ) : (
            <TopEditContainer>
              {/*<Heading>*/}
                {/*<p title={materialProperties.name}>{formatProjectName(materialProperties.name)}</p>*/}
                {/*<span*/}
                {/*  data-tip*/}
                {/*  data-for={"materialName"}*/}
                {/*>*/}
                {/*  {formatMaterialName(materialProperties.name)}*/}
                {/*</span>*/}
                <div
                  data-tip
                  data-for={"materialName"}
                  >
                   <Input
                    data-tip
                    data-for={"materialName"}
                    type={"text"}
                    required
                    value={materialName}
                    onPress={(e) => {
                      setMaterialName(e.target.value);
                    }}
                    keyPress={(e) => {
                      if (e.keyCode === 13) {
                        updateMaterialName(e.target.value);
                      }
                    }}
                    // onBlur={(e) => onBlur(e, constants.VOFFSET)}
                    customInput={{
                      width: "6.5rem",
                      height: "2rem",
                      textAlign: "left",
                      fontSize: "16px",
                      color: "3A3A3A",
                      border: "none",
                      backgroundColor: "white",
                      fontFamily: `DM Sans,sans-serif`
                    }}
                  />
                </div>
                <Tooltip
                  id={"materialName"}
                  place="bottom"
                  effect="solid"
                >
                  {materialName}
                </Tooltip>
              {/*</Heading>*/}
              {/*<div onClick={() => setEditable(true)}>
                <img
                  style={{ marginRight: "0.625rem" }}
                  src={Edit}
                  alt="edit"
                />
              </div>*/}
            </TopEditContainer>
          )}

          <TopContainer className="imageEditContainer">
            <EditImage>
              <img src={materialProperties.thumbnailURL || tile} alt="thumbnail" style={{objectFit : "contain"}}/>
              {/*<EditOverlay>
                <img src={Edit} alt="edit" />
              </EditOverlay>*/}
            </EditImage>
            <InputWrapperTop>
              <InputColumn>
                <div className="scaleInput">
                  <Input
                    label={constants.VSCALE}
                    labelStyle={{
                      fontSize: "9px",
                      lineHeight: "12px",
                      display: "flex",
                      alignItems: "center",
                      letterSpacing: "-0.211789px",
                      color: `${colors.thinGray}`,
                      paddingBottom: "0.25em",
                    }}
                    type={"text"}
                    required
                    value={materialProperties.vScale}
                    onPress={(e) => {onChange(e, constants.VSCALE)}}
                    keyPress={(e) => onKeyPress(e, constants.VSCALE)}
                    onBlur={(e) => onBlur(e, constants.VSCALE)}
                    customInput={{
                      width: "2.5rem",
                      height: "1.375rem",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  />
                </div>
                <div>
                  <Input
                    label={constants.VOFFSET}
                    labelStyle={{
                      fontSize: "9px",
                      lineHeight: "12px",
                      display: "flex",
                      alignItems: "center",
                      letterSpacing: "-0.211789px",
                      color: `${colors.thinGray}`,
                      paddingBottom: "0.25em",
                    }}
                    type={"text"}
                    required
                    value={materialProperties.vOffset}
                    onPress={(e) => {onChange(e, constants.VOFFSET)}}
                    keyPress={(e) => onKeyPress(e, constants.VOFFSET)}
                    onBlur={(e) => onBlur(e, constants.VOFFSET)}
                    customInput={{
                      width: "2.5rem",
                      height: "1.375rem",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  />
                </div>
              </InputColumn>
              <InputColumn>
                <Input
                  label={constants.ANGLE}
                  labelStyle={{
                    fontSize: "9px",
                    lineHeight: "12px",
                    display: "flex",
                    alignItems: "center",
                    letterSpacing: "-0.211789px",
                    color: `${colors.thinGray}`,
                    paddingBottom: "0.25em",
                  }}
                  type={"text"}
                  required
                  value={materialProperties.angle}
                  onPress={(e) => {onChange(e, constants.ANGLE.toLowerCase())}}
                  keyPress={(e) => onKeyPress(e, constants.ANGLE.toLowerCase())}
                  onBlur={(e) => onBlur(e, constants.ANGLE.toLowerCase())}
                  customInput={{
                    width: "1.25rem",
                    height: "1.375rem",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                />
              </InputColumn>
            </InputWrapperTop>
          </TopContainer>
          <TopContainer style={{ marginTop: "0.375rem" }}>
            <div style={{ marginRight: "0.5rem" }}>
              <Input
                label={constants.USCALE}
                labelStyle={{
                  fontSize: "9px",
                  lineHeight: "12px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "-0.211789px",
                  color: `${colors.thinGray}`,
                  paddingBottom: "0.25em",
                }}
                type={"text"}
                required
                value={materialProperties.uScale}
                onPress={(e) => {onChange(e, constants.USCALE)}}
                keyPress={(e) => onKeyPress(e, constants.USCALE)}
                onBlur={(e) => onBlur(e, constants.USCALE)}
                customInput={{
                  width: "2.5rem",
                  height: "1.375rem",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              />
            </div>
            <div>
              <Input
                label={constants.UOFFSET}
                labelStyle={{
                  fontSize: "9px",
                  lineHeight: "12px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "-0.211789px",
                  color: `${colors.thinGray}`,
                  paddingBottom: "0.25em",
                }}
                type={"text"}
                required
                value={materialProperties.uOffset}
                onPress={(e) => {onChange(e, constants.UOFFSET)}}
                keyPress={(e) => onKeyPress(e, constants.UOFFSET)}
                onBlur={(e) => onBlur(e, constants.UOFFSET)}
                customInput={{
                  width: "2.5rem",
                  height: "1.375rem",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              />
            </div>
          </TopContainer>
          <SliderContainer>
            <div style={{ width: "8rem" }}>
              <TopContainerLabel>{constants.OPACITY}</TopContainerLabel>
              <CustomSlider
                max={100}
                min={0}
                step={1}
                value={materialProperties.opacity}
                onChange={(v) => {onSliderChange(v, constants.OPACITY.toLowerCase())}}
                onAfterChange={(v) => {onSliderChangeComplete(v, constants.OPACITY.toLowerCase())}}
              />
            </div>
            <OverlayContainer>
              <div>
                <TopContainerLabel>{constants.OVERLAY}</TopContainerLabel>
                <StyledComponents.ImageButton
                  style={{ margin: 0 }}
                  key={0}
                  onClick={goToOverlayMenu}
                  subMenuIcon
                >
                  <img
                    src={overlay}
                    alt="overlay"
                  />
                </StyledComponents.ImageButton>
              </div>
              <Input
                type={"text"}
                value={materialProperties.overlay}
                isInputDisabled={true}
                required
                onPress={(e) => {}}
                customInput={{
                  width: "4.125rem",
                  height: "1.375rem",
                  // fontWeight: "bold",
                  textAlign: "right",
                }}
              />
            </OverlayContainer>
          </SliderContainer>
          {/*<DropdownWrapper>
            <TopContainerLabel>{constants.MATERIAL_TYPE}</TopContainerLabel>
            <ScrollableDropdown
              options={[
                "Concrete",
                "Colours",
                "Glass",
                "Metal",
                "Tiles",
                "Texture",
                "Grid",
              ]}
            />
          </DropdownWrapper>*/}
          <ButtonContainer>
            <Button
              title={constants.SAVE_EDITS}
              customButtonStyle={{
                backgroundColor: colors.red,
                height: "1.5625rem",
                width: "8.75rem",
                minHeight: "auto",
                padding: "0.375rem 0",
              }}
              onPress={saveAndGoBackToMainMenu}
              customButtonTextStyle={{ fontSize: "11px" }}
            />
          </ButtonContainer>
        </Inner>
      </Container>
    </Main>
  );
}

export default MaterialEdit;
