import React from 'react';
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import Button from '../Button';
import Input from '../Input';
import * as styles from "../../containers/genericModal/styles";
import { RecentBox } from '../SortByBox';
import { Card, ModalHeader, ModalBody, ModalChild, ModalFooter, modalStylesDefault, StyledRow, StyledCol, recentBoxStyle, CloseButtonIcon, ButtonWrapper, FlexContainer, ICON, uneditableContainerStyle } from "./style";
import { findFolderRecursive } from '../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice';
import { useSelector } from 'react-redux';
import _ from 'lodash';

function CreateProjectModal({
  isOpen,
  onClose,
  handleCreateProject,
  newProjectName,
  setNewProjectName,
  setSelectedOption,
  selectedTeam,
  folderID
}) {
  const { parentFolder } = useSelector(state => {
    let team, parentFolder;
    if (selectedTeam?.id) {
      team = state.teams.teamlist.find(team => team.id === selectedTeam.id);
      parentFolder = findFolderRecursive(team.fileSystem?.folders, folderID);
    } else {
      team = null;
      parentFolder = findFolderRecursive(state.teams.personalWorkspace.fileSystem.folders, folderID);
    }
    return {
      team,
      parentFolder
    }
  })
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem" style={{ maxWidth: "31rem", whiteSpace: "nowrap", overflow: "hidden" }}>
            <Text opacity={1} weight="bold">
              Create New Project
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={ICON.CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          {
            selectedTeam &&
            <StyledRow>
              <StyledCol>Team</StyledCol>
              <StyledCol style={uneditableContainerStyle}>{selectedTeam.title}</StyledCol>
            </StyledRow>
          }
          {
            parentFolder &&
            <StyledRow>
              <StyledCol>Folder</StyledCol>
              <StyledCol style={uneditableContainerStyle}>{parentFolder.name}</StyledCol>
            </StyledRow>
          }
          <StyledRow>
            <StyledCol>
              Project Name
            </StyledCol>
            <StyledCol>
              <form onSubmit={handleCreateProject}>
                <Input
                  placeholder={"Enter new project name"}
                  value={newProjectName}
                  onPress={(e) => { setNewProjectName(e.target.value) }}
                  maxLength={50}
                  autoFocus={true}
                />
              </form>
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol>
              Units
            </StyledCol>
            <StyledCol>
              <RecentBox
                customDropDownStyle={recentBoxStyle.customDropdownStyle}
                customBoxStyles={recentBoxStyle.boxStyle}
                selectedItemColor={colors.primeBlack}
                isOptionElement={true}
                customSelectedTextStyle={recentBoxStyle.selectedOptionStyle}
                selectOption={(opt) => { setSelectedOption(opt.key) }}
                options={[
                  <FlexContainer key={1}>
                    <span>Millimeter</span>
                  </FlexContainer>,
                  <FlexContainer key={2}>
                    <span>Centimeter</span>
                  </FlexContainer>,
                  <FlexContainer key={0}>
                    <span>Meter</span>
                  </FlexContainer>,
                  <FlexContainer key={3}>
                    <span>Inches</span>
                  </FlexContainer>,
                  <FlexContainer key={4}>
                    <span>Feet-Inches</span>
                  </FlexContainer>,
                ]}
                withIcon={true}
              />
            </StyledCol>
          </StyledRow>

          {/* <StyledRow>
            <StyledCol>
              {
                selectedTeam ? selectedTeam.title + " project" : "Personal project"
              }
            </StyledCol>
          </StyledRow> */}
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            {/* <Button onPress={onClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Cancel" /> */}
          </ButtonWrapper>
          <ButtonWrapper>
            <Button primary title="Create" onPress={handleCreateProject} />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  )
}

export default CreateProjectModal
