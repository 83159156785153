import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    roles: [],
    isPublic: false,
};

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        resetRoles: state => initialState,
        initRoles: (state, action) => {
            const { roles } = action.payload;
            state.roles = roles;
        },
        initIsPublic: (state, action) => {
            const { isPub } = action.payload;
            state.isPublic = isPub;
        },
        makePublic: (state, action) => {
            state.isPublic = true;
        },
        unmakePublic: (state, action) => {
            state.isPublic = false;
        },
        addRole: (state, action) => {
            // use updateRole instead
            const { role } = action.payload;
            state.roles.push(role);
        },
        deleteRole: (state, action) => {
            const { userEmail } = action.payload;
            const newRoles = state.roles.slice().filter(rl => rl.user.email !== userEmail);
            state.roles = newRoles;
        },
        updateRole: (state, action) => {
            const { role } = action.payload;
            const alreadyExists = state.roles.filter(rl => rl.user.email === role.user.email);
            if(alreadyExists.length){
                const index = state.roles.indexOf(alreadyExists[0]);
                state.roles[index] = role;
            } else {
                state.roles.push(role);
            }
        },
    }
});

export const { resetRoles, initRoles, initIsPublic, makePublic, unmakePublic, addRole, deleteRole, updateRole } = rolesSlice.actions;

export default rolesSlice.reducer;
