import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import NamedAvatar from '../Avatar/NamedAvatar';
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`
function ActiveUserList() {
    const activeUsers = useSelector(state => state.activeUsers);
    
    return ( 
        <Wrapper>
            {
                activeUsers.others.map((activeUser, index) => {
                    const bgcolor = "#"+activeUser.color;
                    return <NamedAvatar 
                        key={activeUser.user.email}
                        id={activeUser.user.email} 
                        text={activeUser.user.email} 
                        backgroundColor={bgcolor} 
                        style={{marginRight: index===activeUsers.others.length-1 ? "0px" : "-8px", border: "1px solid " + bgcolor, outline: "2px solid white", transform: "scale(0.85)"}}
                    />
                })
            }
        </Wrapper>
    );
}

export default ActiveUserList;