"use strict";
import BABYLON, { Image } from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import {
  cloneEdge,
  getBabylonGUIElementByName,
  getV2ProjectedOntoModelSpace,
  getV3ProjectedOntoScreenSpace,
} from "../extrafunc";
import rightArrow from "../../../assets/images/rightArrow.svg";
import { lineParallelToWhichAxis } from "../../libs/snapUtilities";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants";

const arrowIndicator = (function () {
  const CONSTANTS = {
    arrowIndicator: "arrowIndicator",
    lineWidth: 6,
    imageSize: 28.347, // value from the svg
    padding: 10,
  };

  const TYPES = {
    allIndicators: 0,
    editPolygonDirectionIndicator: 1,
  };

  let texture;
  let editPolygonDirectionIndicator;

  const activeIndicators = [];

  const _addToTexture = function (control, texture) {
    if (!control.host) {
      texture.addControl(control);
    }
    if (!control.parent) {
      texture.rootContainer.addControl(control);
    }
  };

  const _newArrow = function () {
    let arrow = new Image(CONSTANTS.arrowIndicator, rightArrow);
    arrow.width = CONSTANTS.imageSize + "px";
    arrow.height = CONSTANTS.imageSize + "px";
    arrow.isVisible = false;
    arrow.snaptrudeProperties = {};
    
    // arrow.shadowColor = colorUtil.type.black;
    // arrow.shadowBlur = 5;

    _addToTexture(arrow, texture);

    return arrow;
  };

  const init = function () {
    texture = store.advancedTexture;
    editPolygonDirectionIndicator = _newArrow();

    editPolygonDirectionIndicator.zIndex = 1;
  };
  
  // was using this when arrow was built using MultiLine
  const _moveToVector3 = function (activeIndicator, v3){
    activeIndicator.reset();
    
    const anchorPoint2D = getV3ProjectedOntoScreenSpace(v3);
    
    anchorPoint2D.x += 5;
    
    const point1 = {
      x: anchorPoint2D.x - 10,
      y: anchorPoint2D.y - 5,
    };
    
    const point3 = {
      x: anchorPoint2D.x - 10,
      y: anchorPoint2D.y + 5,
    };
    
    const points = [point1, anchorPoint2D, point3];
    points.forEach((point) => {
      activeIndicator.add({
        x: point.x + "px",
        y: point.y + "px",
      });
    });
  }
  
  const _alignIndicatorWithTextBox = function (indicator){
    const textBox = getBabylonGUIElementByName("distBox");
    
    const xLength = textBox.left.length;
    const x = parseFloat(textBox.left.substring(0, xLength - 2));
    
    const yLength = textBox.top.length;
    const y = parseFloat(textBox.top.substring(0, yLength - 2));
    
    indicator.top = (y - 20) + "px";
    indicator.left = (x + textBox.widthInPixels / 3) + "px";
  }
  
  const _alignIndicatorOnTheEdge = function (indicator){
    
    const edge = indicator.snaptrudeProperties.edge;
    
    const midPoint = getV3ProjectedOntoScreenSpace(
      indicator.snaptrudeProperties.edgeMidPt
    );
    
    const tailPoint = getV3ProjectedOntoScreenSpace(
      edge.tailPt
    );
    
    const textBox = getBabylonGUIElementByName("distBox");
    
    let offset =
      indicator.snaptrudeProperties.edgeDirection ===
      GLOBAL_CONSTANTS.strings.snaps.x
        ? textBox.widthInPixels / 2
        : textBox.heightInPixels / 2;
    
    offset += CONSTANTS.padding;
    
    const placeArrowAtPoint2D = midPoint.add(
      tailPoint.subtract(midPoint).normalize().scale(offset));
  
    /*indicator.left = (placeArrowAtPoint2D.x) + "px";
    indicator.top = (placeArrowAtPoint2D.y) + "px";*/
    
    const correspondingV3 = getV2ProjectedOntoModelSpace(placeArrowAtPoint2D);
    
    correspondingV3.y = indicator.snaptrudeProperties.edgeMidPt.y;
    
    if (store.resolveEngineUtils.onSegment2D(edge.headPt, correspondingV3, edge.tailPt, 0.1)){
      indicator.moveToVector3(correspondingV3, store.scene);
    }
    else {
      indicator.moveToVector3(edge.tailPt, store.scene);
    }
    
  }

  const show = function (edge, mesh, options = {}) {
    let activeIndicator;
    if (options.indicator === TYPES.editPolygonDirectionIndicator)
      activeIndicator = editPolygonDirectionIndicator;

    if (!activeIndicator.isVisible) activeIndicator.isVisible = true;
    _addToTexture(activeIndicator, texture);
    activeIndicator.snaptrudeProperties.mesh = mesh;
    activeIndicator.snaptrudeProperties.edge = cloneEdge(edge);
    activeIndicator.snaptrudeProperties.edgeMidPt = BABYLON.Vector3.Center(
      edge.headPt,
      edge.tailPt
    );
    
    activeIndicator.snaptrudeProperties.edgeDirection = lineParallelToWhichAxis(
      edge.headPt,
      edge.tailPt
    );
    
    _alignIndicatorOnTheEdge(activeIndicator);
    // _alignIndicatorWithTextBox(activeIndicator);
    // _moveToVector3(activeIndicator, edge.tailPt);
    
    activeIndicator.rotation = BABYLON.Vector3.GetAngleBetweenVectors(
      edge.tailPt.subtract(edge.headPt).normalize(),
      new BABYLON.Vector3(1, 0, 0),
      new BABYLON.Vector3(0, -1, 0)
    );

    activeIndicators.push(activeIndicator);
    
    return activeIndicator;
  };

  const remove = function (type = TYPES.allIndicators) {
    const _deactivate = function (indicator) {
      indicator.isVisible = false;
      _.remove(activeIndicators, indicator);
    };

    if (type === TYPES.allIndicators) {
      for (let i = 0; i < activeIndicators.length; ) {
        // no increment
        _deactivate(activeIndicators[i]);
      }
    } else if (type === TYPES.editPolygonDirectionIndicator) {
      _deactivate(editPolygonDirectionIndicator);
    }
  };
  

  const update = function () {
    activeIndicators.forEach((ai) => {
      // ai.moveToVector3(ai.snaptrudeProperties.edge.tailPt, store.scene);
      _alignIndicatorOnTheEdge(ai);
    });
  };
  

  return {
    TYPES,
    init,
    show,
    remove,
    update,
  };
})();
export default arrowIndicator;
