// common
export const PARAGRAPH_TEXT = {
  heading: "Collaborative Building Design",
  subheading: "Powered by Automation and Intelligent BIM ",
  body: [
    "Design buildings with your team, with real world data - on any device, from anywhere in the world."
  ],
};
export const OR = "or";
export const EMAIL_ADDRESS_LABEL = "Email Address";
export const PASSWORD_LABEL = "Password";
export const DISCLAIMER_TEXT = "By signing up I agree to Snaptrude's";
export const TERMS_AND_CONDITONS = "Terms of service & Privacy policy";

// signup page
export const SIGN_UP_CARD_HEADER = "Get Started absolutely Free";
export const SIGN_UP_WITH_GOOGLE = "Signup With Google";
export const NAME_LABEL = "Your Full Name";
export const PHONE_LABEL = "Phone Number";
export const SIGN_UP = "Sign Up";
export const EMAIL_ERROR_MESSAGE = "Please enter a valid email";
export const INVALID_PHONE_NUMBER = "Please enter a valid phone number";
export const CARD_LABEL = "Credit Card Number"

// login page
export const LOGIN_CARD_HEADER = "Sign in to Snaptrude";
export const LOGIN_WITH_GOOGLE = "Login with Google";
export const SIGNIUP_WITH_GOOGLE = "Signup with Google";
export const FORGOT_PASSWORD = "Forgot Password?";
export const SIGN_IN = "Sign In";
export const LOG_IN = "Log in";
export const NEW_USER = "Sign Up here";
// forgot password page
export const FORGOT_PASSWORD_CARD_HEADER = "Enter registered email";
export const RESET_PASSWORD_CARD_HEADER = "Enter new password";
export const ENTER_CURRENT_PASSWORD = "Enter Current Password";
export const ENTER_NEW_PASSORD = "Enter new Password";
export const ENTER_REGISTERED_EMAIL = "Enter registered email";
export const GET_PASSWORD_REST_LINK = "Get password reset link";
export const RECONFIRM_NEW_PASSWORD = "Reconfirm new Password";
export const CHANGE_PASSWORD = "Change Password";
export const FORGOT_PASSWORD_HEADER = "Create a new Password";

//selectProfession page
export const SELECT_PROFESSION_CARD_HEADER = "Tell us about yourself";
export const INDUSTRIES = [
  // {name: "Select Industry", value: ""},
  { name: "Architect", value: "architect"},
  { name: "Interior Design", value: "interior design" },
  { name: "Real estate", value: "real estate" },
  { name: "Contractor", value: "Contractor" },
  { name: "Design Build", value: "design build" },
  { name: "Education", value: "education" },
  { name: "Other", value: "other" },
];
export const ROLES = [
  // {name: "Select Role", value: ""},
  { name: "Principal", value: "principal" },
  { name: "Manager", value: "manager" },
  { name: "Designer", value: "designer" },
  { name: "Engineer", value: "engineer" },
  { name: "Student", value: "student" },
];
export const USE_CASES = [
  {name: "Quick modelling with data & BIM", value: "Quick modelling with data & BIM"},
  {name: "Design collaboratively with remote teams", value: "Design collaboratively with remote teams"},
  {name: "Improve design communication with clients", value: "Improve design communication with clients"},
]