import windows from "../../assets/menu_icons/windows.svg";
import staircases from "../../assets/menu_icons/staircases.svg";
import materials from "../../assets/menu_icons/materials.svg";
import objectPropertiesIcon from "../../assets/menu_icons/Object properties.svg";
import furniture from "../../assets/menu_icons/furniture.svg";
import doors from "../../assets/menu_icons/doors.svg";
import userimportedfiles from "../../assets/menu_icons/userimportedfiles.svg";
import help from "../../assets/menu_icons/Help.svg";
import Undo from "../../assets/menu_icons/Undo.svg";
import Redo from "../../assets/menu_icons/Redo.svg";
import Settings from "../../assets/menu_icons/Settings.svg";
import Bulb0 from "../../assets/menu_icons/Bulb0.svg";
import Bulb from "../../assets/menu_icons/Bulb.svg";
import NoView from "../../assets/menu_icons/NoView.svg";
import ToggleBuilding from "../../assets/menu_icons/toggleBuilding.svg";
import ObjectIcon from "../../assets/menu_icons/Object.svg";
import lockIcon from "../../assets/menu_icons/lock.svg";
import unlockIcon from "../../assets/menu_icons/unlock.svg";
import ellipse from "../../assets/menu_icons/ellipse.svg";
import delete_icon from "../../assets/menu_icons/delete.svg";
import storey_up from "../../assets/menu_icons/storey_up.svg";
import storey_down from "../../assets/menu_icons/storey_down.svg";
import addStorey from "../../assets/menu_icons/add_storey.svg";
import Toggle2d3d from "../../assets/images/2d3dtoggle.svg";
import goInto2d from "../../assets/images/goInto2d.svg";
import goInto3d from "../../assets/images/goInto3d.svg";
import comments from "../../assets/icons/comments.svg";
import hand from "../../assets/icons/hand.svg";
import info from "../../assets/icons/info.svg";
import resize from "../../assets/icons/resize.svg";
import searchBox from "../../assets/icons/searchBox.svg";
import share from "../../assets/icons/share.svg";
import solar from "../../assets/icons/solar.svg";
import zoomIn from "../../assets/icons/zoomIn.svg";
import zoomOut from "../../assets/icons/zoomOut.svg";
import dropArrow from "../../assets/icons/dropArrow.svg";
import dropOpenArrow from "../../assets/icons/dropOpenArrow.svg";
import copy from "../../assets/icons/copy layer.svg";
import deleteIcon from "../../assets/icons/delete layer.svg";
import edit from "../../assets/icons/edit.svg";
import copyMenu from "../../assets/icons/copy.svg";
import importIcon from "../../assets/icons/Import.svg";
import remove from "../../assets/icons/Remove.svg";
import cutHoleIcon from "../../assets/icons/cutHole.svg";
import eyeDropperIcon from "../../assets/icons/eyeDropper.svg";

export const RIGHT_MENU_ICONS = [
  ["objectProperties", objectPropertiesIcon],
  ["materials", materials],
  ["doors", doors],
  ["windows", windows],
  ["staircases", staircases],
  ["furniture", furniture],
  ["userimportedfiles", userimportedfiles]
];

export const RIGHT_SUB_MENU_ICONS = {
  importMaterial: importIcon,
  editMaterial: edit,
  removeMaterial: remove,
  cloneMaterial: copyMenu,
  eyeDropperForMaterial: eyeDropperIcon,
  importDoor: importIcon,
  importWindow: importIcon,
  importFurniture: importIcon,
  cutHoleFurniture: cutHoleIcon,
};

export const BOTTOM_MENU_ICONS = [
  [["help", help, { isExpandable: false }]],
  [
    ["Undo", Undo, { isExpandable: false }],
    ["Redo", Redo, { isExpandable: false }],
  ],
  [["Settings", Settings, { isExpandable: false }]],
  [
    ["Bulb0", Bulb0, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["Bulb", Bulb, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["NoView", NoView, { isExpandable: false }],
    ["ObjectIcon", ObjectIcon, { isExpandable: false }],
    ["Lock", lockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects" }],
    ["Unlock", unlockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
  ],
  [["ellipse", ellipse, { isExpandable: true }]],
  [["delete_icon", delete_icon, { isExpandable: false }]],
];

export const BOTTOM_MENU_ICONS_ELLIPSE_EXPANDED = [
  [
    // ["help", help, { isExpandable: false }]
  ],
  [
    ["Undo", Undo, { isExpandable: false }],
    ["Redo", Redo, { isExpandable: false }],
  ],
  [["Settings", Settings, { isExpandable: false }]],
  [
    ["Bulb0", Bulb0, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["Bulb", Bulb, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["NoView", NoView, { isExpandable: false }],
    ["ObjectIcon", ObjectIcon, { isExpandable: false }],
    ["Lock", lockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
    ["Unlock", unlockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
  ],
  [
    ["ellipse", ellipse, { isExpandable: true }],
    ["pan", hand, { isExpandable: false }],
    ["orbit", solar, { isExpandable: false }],
    ["zoomIn", zoomIn, { isExpandable: false }],
    ["zoomOut", zoomOut, { isExpandable: false }],
    ["zoomExtents", resize, { isExpandable: false }],
  ],
  [["delete_icon", delete_icon, { isExpandable: false }]],
];

export const BOTTOM_MENU_ICONS_HELP_EXPANDED = [
  [
    // ["help", help, { isExpandable: false }],
    ["searchBox", searchBox, { isExpandable: false }],
    ["chatwithus", comments, { isExpandable: false }],
    ["info", info, { isExpandable: false }],
    ["share", share, { isExpandable: false }],
  ],
  [
    ["Undo", Undo, { isExpandable: false }],
    ["Redo", Redo, { isExpandable: false }],
  ],
  [["Settings", Settings, { isExpandable: false }]],
  [
    ["Bulb0", Bulb0, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["Bulb", Bulb, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["NoView", NoView, { isExpandable: false }],
    ["ObjectIcon", ObjectIcon, { isExpandable: false }],
    ["Lock", lockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
    ["Unlock", unlockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
  ],
  [["ellipse", ellipse, { isExpandable: true }]],
  [["delete_icon", delete_icon, { isExpandable: false }]],
];


export const FULL_BOTTOM_MENU_ICONS = [
  [
    // ["help", help, { isExpandable: false }],
    ["searchBox", searchBox, { isExpandable: false }],
    ["chatwithus", comments, { isExpandable: false }],
    ["info", info, { isExpandable: false }],
    ["share", share, { isExpandable: false }],
  ],
  [
    ["Undo", Undo, { isExpandable: false }],
    ["Redo", Redo, { isExpandable: false }],
  ],
  [["Settings", Settings, { isExpandable: false }]],
  [
    ["Bulb0", Bulb0, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["Bulb", Bulb, { isExpandable: false, permissionKey: "hide_and_show_objects" }],
    ["NoView", NoView, { isExpandable: false }],
    ["ObjectIcon", ObjectIcon, { isExpandable: false }],
    ["Lock", lockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
    ["Unlock", unlockIcon, { isExpandable: false, permissionKey: "lock_and_unlock_objects"  }],
  ],
  [
    ["ellipse", ellipse, { isExpandable: true }],
    ["pan", hand, { isExpandable: false }],
    ["orbit", solar, { isExpandable: false }],
    ["zoomIn", zoomIn, { isExpandable: false }],
    ["zoomOut", zoomOut, { isExpandable: false }],
    ["zoomExtents", resize, { isExpandable: false }],
  ],
  [["delete_icon", delete_icon, { isExpandable: false }]],
];

export const FULL_BOTTOM_MENU_ICONS_COVE = [
  [
    // ["help", help, { isExpandable: false }],
    ["searchBox", searchBox, { isExpandable: false }],
    ["chatwithus", comments, { isExpandable: false }],
    ["info", info, { isExpandable: false }],
    ["share", share, { isExpandable: false }],
  ],
  [
    ["Undo", Undo, { isExpandable: false }],
    ["Redo", Redo, { isExpandable: false }],
  ],
  [["Settings", Settings, { isExpandable: false }]],
  [
    ["Bulb0", Bulb0, { isExpandable: false }],
    ["Bulb", Bulb, { isExpandable: false }],
    ["NoView", NoView, { isExpandable: false }],
    ["HideBuilding", ToggleBuilding, { isExpandable: false }],
    ["ObjectIcon", ObjectIcon, { isExpandable: false }],
    ["Lock", lockIcon, { isExpandable: false }],
    ["Unlock", unlockIcon, { isExpandable: false }],
  ],
  [
    ["ellipse", ellipse, { isExpandable: true }],
    ["pan", hand, { isExpandable: false }],
    ["orbit", solar, { isExpandable: false }],
    ["zoomIn", zoomIn, { isExpandable: false }],
    ["zoomOut", zoomOut, { isExpandable: false }],
    ["zoomExtents", resize, { isExpandable: false }],
  ]
];

export const FULL_BOTTOM_MENU_ICONS_RENDER_PAGE = [
  [
    // ["help", help, { isExpandable: false }],
    ["searchBox", searchBox, { isExpandable: false }],
    ["chatwithus", comments, { isExpandable: false }],
    ["info", info, { isExpandable: false }],
    ["share", share, { isExpandable: false }],
  ],
  [
    ["pan", hand, { isExpandable: false }],
    ["orbit", solar, { isExpandable: false }],
    ["zoomIn", zoomIn, { isExpandable: false }],
    ["zoomOut", zoomOut, { isExpandable: false }],
    ["zoomExtents", resize, { isExpandable: false }],
  ]
];

export const STOREY_UP = ["storey_up", addStorey];
export const STOREY_DOWN = ["storey_down", addStorey];
export const TOGGLE_VIEW_2D_3D = Toggle2d3d;
export const GO_INTO_2D = goInto2d;
export const GO_INTO_3D = goInto3d;
export const TOP_NAV_ARROW = dropOpenArrow;
export const BOTTOM_NAV_ARROW = dropArrow;
