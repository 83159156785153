import React, {useEffect, useState} from "react";
import MenuPopUp from "../../../../components/MenuPopUp/index";
import ScrollViewCommon from "../../../../components/rightMenus/scrollViewCommon";
import {RIGHT_SUB_MENU_ICONS} from "../../Icons";
import {store} from "../../../../snaptrude/modules/utilityFunctions/Store";
import _ from "lodash";
import { populateComponentLibraryData} from "../../../../services/resources.service";
import {cachingService} from "../../../../snaptrude/modules/utilityFunctions/cachingService";

const FurniturePopUp = (props) => {
  const { closePopUp, resetSelectedTopSubMenu, style, isOpen } = props;
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [selectedFurniture, setSelectedFurniture] = useState({
    imgURL: null,
    type: null
  });
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [cutHole, setCutHole] = useState(store.$scope.cutHole);
  const [thumbnailImageItems, setThumbnailImageItems] = useState([]);
  const [leftMenuItems, setLeftMenuItems] = useState([
    {title : "", key: ""}
  ]);
  const [importedObjects, setImportedObjects] = useState([]);
  

  const furnitureCategoriesInPreferredOrder = [
    "living",
    "bedroom",
    "kitchen",
    "dining",
    "bathroom",
    "balcony",
    "landscape",
    "people",
    "office",
    "private office",
    "meeting room",
    "cafeteria",
    "reception"
  ];
  
  useEffect(() => {
    populateComponentLibraryData()
    .then(result => {
      const furnitureCategoriesInDB = cachingService.get(cachingService.SERVICES.furnitureCategories);
      
      const additionalCategories = _.difference(
        furnitureCategoriesInDB,
        furnitureCategoriesInPreferredOrder
      );
      const furnitureCategories = _.union(
        furnitureCategoriesInPreferredOrder,
        additionalCategories
      );
      
      setLeftMenuItems(
        furnitureCategories.map((fc) => {
          return {
            title : _.startCase(fc),
            key : fc
          }
      }));
    })
  }, [])
  
  /*
  const leftMenuItems = [
    { title: "Living" },
    { title: "Bedroom" },
    { title: "Kitchen" },
    { title: "Dining" },
    { title: "Bathroom" },
    { title: "Balcony" },
    { title: "Landscape" },
    { title: "People" },
    { title: "Office" },
    { title: "Private Office" },
    { title: "Meeting Room" },
    { title: "Cafeteria" },
    { title: "Reception" },
    { title: "Stage" },
    { title: "Cabins" },
    { title: "Lights" },
    { title: "Meeting Rooms - Imported" },
    { title: "Light Fixtures" },
    { title: "Parking" },
    { title: "Fridge" },
    { title: "DK" },
    { title: "Gear" },
  ];
  
  leftMenuItems.forEach(item => {
    item.key = formatSubType(item.title);
  });
  */
  
  const typeOptions = leftMenuItems.map(item => item.title);
  
  const toggleCutHole = function (){
    setCutHole(!cutHole);
    store.$scope.cutHole = !cutHole;
  };
  
  const topSubMenuOptions = [];
  
  topSubMenuOptions.push({
    icon : RIGHT_SUB_MENU_ICONS.importFurniture,
    callback : null,
    name : "importFurniture",
    tooltipText: "Add furniture",
  });
  
  topSubMenuOptions.push({
    icon : RIGHT_SUB_MENU_ICONS.cutHoleFurniture,
    callback : toggleCutHole,
    name : "cutHoleFurniture",
    persistent : true,
    isSelected : cutHole,
    tooltipText: "Cut hole",
  });
  
  const onDropdownChange = (value) => {
    store.$scope.snap_to.value = value.toLowerCase();
  };
  
  const selectedDropdownOption = _.capitalize(store.$scope.snap_to.value);
  
  return (
    <MenuPopUp
      leftMenuItems={leftMenuItems}
      setLeftMenuItems={setLeftMenuItems}
      selectedIndex={selectedMenuIndex}
      setSelectedIndex={setSelectedMenuIndex}
      hasleftMenu={true}
      hasTopIcons={true}
      topSubMenuOptions={topSubMenuOptions}
      title={"Furniture"}
      isOpen={isOpen}
      style={style}
      child={() => {
        return <ScrollViewCommon
          noBorder={true}
          filterType="furniture"
          filterSubType={leftMenuItems[selectedMenuIndex].key}
          selectedThumbnail={selectedFurniture}
          setSelectedThumbnail={setSelectedFurniture}
          setSelectedSubMenu={setSelectedSubMenu}
          thumbnailImageItems={thumbnailImageItems}
          setThumbnailImageItems={setThumbnailImageItems}
          resetSelectedTopSubMenu={resetSelectedTopSubMenu}
          maintainAspectRatio={false}
          isOpen={isOpen}
          importedObjects={importedObjects}
          />;
      }}
      noBorder={true}
      showDropdowns={true}
      dropdownTitle={"Snap To"}
      dropdownTooltipId={"snapto"}
      dropdownOptions={["Floor", "Wall", "None"]}
      closePopUp={closePopUp}
      typeOptions={typeOptions}
      setSelectedThumbnail={setSelectedFurniture}
      setImportedObjects={setImportedObjects}
      selectedSubMenu={selectedSubMenu}
      setSelectedSubMenu={setSelectedSubMenu}
      onDropdownChange={onDropdownChange}
      selectedDropdownOption={selectedDropdownOption}
      thumbnailImageItems={thumbnailImageItems}
      setThumbnailImageItems={setThumbnailImageItems}
    />
  );
};

export default FurniturePopUp;
