import React from "react";
import styled from "styled-components";
import Sidebar from "../../components/sidebar/index";

const StyledDashboard = styled.div`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: flex-start;
  .content {
    padding: 1rem 3rem;
    width: 100vw;
  }
`;

const NotFound = () => {
  return (
    <StyledDashboard>
      <Sidebar />
      <div className="content">
        <h3>Page Not Found</h3>
      </div>
    </StyledDashboard>
  );
};

export default NotFound;
