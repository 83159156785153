"use strict";
import { vertexIndicator } from "./vertexIndicator.js";
import { edgeIndicator } from "./edgeIndicator.js";
import { axisIndicator } from "./axisIndicator.js";
import { faceIndicator } from "./faceIndicator.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import arrowIndicator from "./arrowIndicator";
import { commentOperator } from "../../libs/commentEvents.js";

const uiIndicatorsHandler = (function () {
  const _loopUpdateAmount = 5;
  let _loopUpdateCounter = 0;

  const init = function () {
    vertexIndicator.init();
    edgeIndicator.init();
    axisIndicator.init();
    arrowIndicator.init();
  };

  const remove = function () {
    vertexIndicator.remove();
    vertexIndicator.multiIndicators.remove();
    edgeIndicator.remove();
    faceIndicator.remove();
    axisIndicator.remove();
    arrowIndicator.remove();
  };

  const update = function () {
    vertexIndicator.update();
    edgeIndicator.update();
    // faceIndicator.update();
    axisIndicator.update();
    arrowIndicator.update();
    // ADD HERE
    commentOperator.update();
    DisplayOperation.update2DPosition();
  };

  const updateLoop = function () {
    if (_loopUpdateCounter > 0) {
      _loopUpdateCounter--;
      update();
    }
  };

  const markForUpdate = function () {
    _loopUpdateCounter = _loopUpdateAmount;
  };

  const markAsUneditable = function () {
    vertexIndicator.markAsUneditable();
    edgeIndicator.markAsUneditable();
    faceIndicator.markAsUneditable();
  };

  return {
    init,
    remove,
    update,
    updateLoop,
    markForUpdate,
    markAsUneditable,
    vertexIndicator,
    edgeIndicator,
    faceIndicator,
    axisIndicator,
    arrowIndicator,
  };
})();
export { uiIndicatorsHandler };
