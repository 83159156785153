"use strict";
/*jshint esversion: 6 */
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { line2D, circle2D } from "../modules/meshoperations/sketchOperation.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";

var drawDoor = (function () {
  "use strict";
  let thickness1 = 0.04;
  let thickness2 = 0.03;
  let thickness3 = 0.015;
  let thickness4 = 0.01;
  let glassThickness = 12;
  let frameWidth = null;
  let frameLength = null;
  let sliderLength = null;
  let sliderWidth = null;

  let drawLine = function (radius, start) {
    let end = new BABYLON.Vector3(start.x + radius, start.y, start.z);
    let myLines = [start, end];
    var linesystem = BABYLON.MeshBuilder.CreateLineSystem(
      "door2dLine",
      { lines: [myLines], updatable: true },
      store.scene
    );
    linesystem.color = new BABYLON.Color3(0, 0, 0);
    return linesystem;
  };
  
  let drawThickLine = function (radius, start, thickness, end, name) {
    if (!start) start = new BABYLON.Vector3.Zero();
    if (!thickness) thickness = 0.02;
    if (!name) name = "dline";
    if (!end) end = new BABYLON.Vector3(start.x + radius, start.y, start.z);
    let path = [start, end];
    // var linesystem = BABYLON.MeshBuilder.CreateLineSystem("test", {lines: [path], updatable: true}, store.scene);
    //  linesystem.color = new BABYLON.Color3(0, 1, 0);
    let line = line2D(name, { path: path, width: thickness }, store.scene);
    return line;
  };
  
  let drawDoorWidthLine = function (radius, start, thickness, name) {
    if (!start) start = new BABYLON.Vector3.Zero();
    if (!thickness) thickness = 0.02;
    let end = new BABYLON.Vector3(start.x + radius, start.y, start.z);
    let path = [start, end];
    if (!name) name = "dline";
    // var linesystem = BABYLON.MeshBuilder.CreateLineSystem("test", {lines: [path], updatable: true}, store.scene);
    //  linesystem.color = new BABYLON.Color3(0, 1, 0);
    let line = line2D(name, { path: path, width: thickness }, store.scene);
    return line;
  };

  let drawCurveFromPoints = function (points, thickness) {
    let finalLine = [];
    let path = [];
    for (let i = 0; i < points.length - 1; i++) {
      path.push([points[i], points[i + 1]]);
    }
    for (let i = 0; i < path.length; i++) {
      let line = line2D("curve", { path: path[i], width: thickness }, store.scene);
      finalLine.push(line);
    }
    let lineSystem = BABYLON.Mesh.MergeMeshes(finalLine, true);
    lineSystem.color = new BABYLON.Color3(0, 0, 0);
    lineSystem.isPickable = false;
    return lineSystem;
  };
  let drawDoorFrame = function (name, length, width, start, thickness, radius) {
    let path = [];
    let lineMesh = [];
    if (!start) start = new BABYLON.Vector3.Zero();
    if (!length)
      length = DisplayOperation.getOriginalDimension("50", "millimeter"); // x direction
    if (!width)
      width = DisplayOperation.getOriginalDimension("100", "millimeter"); //z direction
    if (!thickness) thickness = 0.02;
    let end = new BABYLON.Vector3(start.x + radius, start.y, start.z);
    path.push([start, new BABYLON.Vector3(length, 0, 0)]);
    path.push([
      new BABYLON.Vector3(length, 0, 0),
      new BABYLON.Vector3(length, 0, -width),
    ]);
    path.push([
      new BABYLON.Vector3(length, 0, -width),
      new BABYLON.Vector3(0, 0, -width),
    ]);
    path.push([new BABYLON.Vector3(0, 0, -width), start]);
    for (let i = 0; i < path.length; i++) {
      let line = line2D("dline", { path: path[i], width: thickness }, store.scene);
      lineMesh.push(line);
    }
    let lineSystem = BABYLON.Mesh.MergeMeshes(lineMesh, true);
    //let lineSystem = data.mergedMeshArr;
    lineSystem.name = name;
    lineSystem.color = new BABYLON.Color3(0, 0, 0);
    lineSystem.isPickable = false;
    return lineSystem;
  };
  let showAngleSector = function (
    origin,
    vector1,
    vector2,
    radius,
    sectorType,
    name
  ) {
    radius = radius || 1;
    sectorType = sectorType || 0;
    var cross = BABYLON.Vector3.Cross(vector1, vector2);
    var dot = BABYLON.Vector3.Dot(vector1, vector2);
    var angle = Math.acos(dot / (vector1.length() * vector2.length()));
    var points = [];
    var pointsSector = [];
    var minNb = 4;
    var factor = 2;
    var nbPoints = Math.floor(radius * angle * factor);
    nbPoints = nbPoints < minNb ? minNb : nbPoints;

    var firstPoint = BABYLON.Vector3.Normalize(vector1).scale(radius);
    var lastPoint = BABYLON.Vector3.Normalize(vector2).scale(radius);
    var matrix;
    var ang = angle / nbPoints;
    var rotated;
    for (var i = 0; i < nbPoints; i++) {
      matrix = BABYLON.Matrix.RotationAxis(cross, ang * i);
      rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
      pointsSector.push(rotated.add(origin));
    }
    pointsSector.push(lastPoint.add(origin));

    let operand = new BABYLON.Vector3(0.25, 0, 0);
    points.push(
      vector1.add(operand),
      vector1,
      origin,
      origin.add(operand),
      vector1.add(operand)
    );
    points.push(...pointsSector);
    var door2D;
    switch (sectorType) {
      case 0:
        door2D = BABYLON.Mesh.CreateLines(name, points, store.scene);
        break;
      case 1:
        door2D = BABYLON.Mesh.CreateDashedLines(
          "sector",
          points,
          3,
          1,
          nbPoints,
          store.scene
        );
        break;
      case 2:
        var pointO = [];
        for (var j = 0; j < points.length; j++) {
          pointO.push(origin);
        }
        door2D = BABYLON.Mesh.CreateRibbon(
          "sector",
          [points, pointO],
          null,
          null,
          0,
          store.scene
        );
        break;
      default:
        door2D = BABYLON.Mesh.CreateLines("sector", points, store.scene);
        break;
    }
    return door2D;
  };
  let drawGlass = function (radius, frameLength) {
    let merged = [];
    let glass = drawDoorWidthLine(radius, null, thickness2);
    glass.position.x = frameLength;
    glass.position.z = DisplayOperation.getOriginalDimension("6", "millimeter");
    let glass2 = drawDoorWidthLine(radius, null, thickness2);
    glass2.position.x = frameLength;
    glass2.position.z = -DisplayOperation.getOriginalDimension(
      "6",
      "millimeter"
    );
    // glass2.setParent(glass);
    merged.push(glass);
    merged.push(glass2);
    let final = BABYLON.Mesh.MergeMeshes(merged, true, true, undefined, false, false);
    return final;
  };

  let drawCurve = function (name, X1, X2, Z1, Z2, type) {
    if (type == "positive") {
      let quadraticBezierVectors = BABYLON.Curve3.CreateQuadraticBezier(
        new BABYLON.Vector3(X1, 0, Z1),
        new BABYLON.Vector3(X1, 0, Z2),
        new BABYLON.Vector3(X2, 0, Z2),
        8
      );
      let quadraticBezierCurve = drawCurveFromPoints(
        quadraticBezierVectors.getPoints(),
        thickness4
      );
      return quadraticBezierCurve;
    }
    if (type == "negetive") {
      let quadraticBezierVectors = BABYLON.Curve3.CreateQuadraticBezier(
        new BABYLON.Vector3(X1, 0, Z1),
        new BABYLON.Vector3(X2, 0, Z1),
        new BABYLON.Vector3(X2, 0, Z2),
        8
      );
      let quadraticBezierCurve = drawCurveFromPoints(
        quadraticBezierVectors.getPoints(),
        thickness4
      );
      return quadraticBezierCurve;
    }
  };
  let vectorArc = function (origin, radius, name) {
    let vector1 = new BABYLON.Vector3(0, 0, -radius);
    let vector2 = new BABYLON.Vector3(radius, 0, 0);
    return showAngleSector(origin, vector1, vector2, radius, 0, name);
  };
  let drawBothFrames = function (radius) {
    let frameMrg = [];
    let frame1 = drawDoorFrame("frame1", null, null, null, thickness1, radius);
    frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.z = frameWidth - thickness1;
    frame1.position.x = 0;
    let frame2 = drawDoorFrame("frame2", null, null, null, thickness1, radius);
    frame2.position.x = radius - frameLength + thickness1;
    frame2.position.z = frameWidth - thickness1;
    frameMrg.push(frame1);
    frameMrg.push(frame2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(frameMrg, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawFramesGuide = function (radius) {
    let frameMrg = [];
    let frame = drawBothFrames(radius);
    let remRadius = radius - frameLength * 2;

    let doorRail = drawDoorWidthLine(remRadius + thickness1, null, thickness4);
    doorRail.position.z = frameWidth / 2 - frameWidth / 4 + thickness1;
    doorRail.position.x = frameLength - thickness1 / 2;

    let doorRail2 = drawDoorWidthLine(remRadius + thickness1, null, thickness4);
    doorRail2.position.z = frameWidth / 2 + frameWidth / 4 - thickness1;
    doorRail2.position.x = frameLength - thickness1 / 2;
    frameMrg.push(frame);
    frameMrg.push(doorRail);
    frameMrg.push(doorRail2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(frameMrg, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawFoldingEven = function (radius, folds, name) {
    let oneDoor = [];
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let remRadius = radius - frameLength * 2;
    let fDoor1 = drawDoorFrame(
      "fDoor1",
      remRadius / folds - frameLength,
      frameWidth / 4,
      null,
      thickness1
    );
    let doorWidth = fDoor1._boundingInfo.boundingBox.extendSize.z * 2;
    let doorLength = fDoor1._boundingInfo.boundingBox.extendSize.x * 2;
    // fDoor1.position.x = frameLength;
    // fDoor1.position.z = frameWidth / 2;
    fDoor1.rotation.y = -Math.PI / 3;
    let fDoor2 = drawDoorFrame(
      "fDoor2",
      remRadius / folds - frameLength,
      frameWidth / 4,
      null,
      thickness1
    );
    fDoor2.position.x = doorLength + doorWidth / 2 + thickness1;
    fDoor2.position.z = -thickness1;
    fDoor2.rotation.y = (-2 / 3) * Math.PI;

    let circle = circle2D(doorWidth / 2, 12, store.scene);
    circle.position = fDoor1._boundingInfo.boundingBox.maximumWorld;
    oneDoor.push(fDoor1);
    oneDoor.push(fDoor2);
    oneDoor.push(circle);
    let mergedMesh = BABYLON.Mesh.MergeMeshes(oneDoor, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawFoldingOdd = function (radius, folds, name) {
    let oneDoor = [];
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let remRadius = radius - frameLength * 2;
    let fDoor1 = drawDoorFrame(
      "fDoor1",
      remRadius / folds - frameLength,
      frameWidth / 4,
      null,
      thickness1
    );
    let doorWidth = fDoor1._boundingInfo.boundingBox.extendSize.z * 2;
    let doorLength = fDoor1._boundingInfo.boundingBox.extendSize.x * 2;
    fDoor1.rotation.y = -Math.PI / 3;

    let fDoor2 = drawDoorFrame(
      "fDoor2",
      remRadius / folds - frameLength,
      frameWidth / 4,
      null,
      thickness1
    );
    fDoor2.position.x = doorLength + doorWidth / 2 + thickness1;
    fDoor2.position.z = -thickness1;
    fDoor2.rotation.y = (-2 / 3) * Math.PI;

    let fDoor3 = drawDoorFrame(
      "fDoor3",
      radius / folds - frameLength,
      frameWidth / 4,
      null,
      thickness1
    );
    fDoor3.position.x = doorLength + doorWidth * 2;
    fDoor3.rotation.y = -Math.PI / 3;

    let circle = circle2D(doorWidth / 2, 12, store.scene);
    circle.position = fDoor1._boundingInfo.boundingBox.maximumWorld;
    let circle2 = circle2D(doorWidth / 2, 12, store.scene);
    circle2.position.x = doorLength + doorWidth * 2 - thickness1;
    circle2.position.z = -thickness1;
    //
    oneDoor.push(fDoor1);
    oneDoor.push(fDoor2);
    oneDoor.push(fDoor3);
    oneDoor.push(circle);
    oneDoor.push(circle2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(oneDoor, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawFrameSliders = function (radius) {
    let frameMrg = [];
    let frame = drawBothFrames(radius);
    let remRadius = radius - frameLength * 2;
    let doorRail = drawDoorWidthLine(remRadius + thickness1, null, thickness3);
    doorRail.position.z = frameWidth - thickness1;
    doorRail.position.x = frameLength - thickness1 / 2;
    let doorRail2 = drawDoorWidthLine(remRadius + thickness1, null, thickness3);
    doorRail2.position.z = 0;
    doorRail2.position.x = frameLength - thickness1 / 2;
    frameMrg.push(frame);
    frameMrg.push(doorRail);
    frameMrg.push(doorRail2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(frameMrg, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawPivotDoor = function (radius, number, name) {
    let oneDoor = [];

    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let remRadius = radius - frameLength * 2;
    let door1 = drawDoorFrame(
      name,
      remRadius / number - frameLength,
      frameWidth / 4,
      null,
      thickness1
    );
    let doorLength = door1._boundingInfo.boundingBox.extendSize.x * 2;
    let doorWidth = door1._boundingInfo.boundingBox.extendSize.z * 2;

    door1.position.x = doorWidth / 2 - thickness1 / 2;
    door1.position.z = doorLength / 2 - thickness1 / 2;
    door1.rotation.y = Math.PI / 2;

    let circle1 = circle2D(doorWidth / 2, 12, store.scene);

    let curve1 = drawCurve(
      "curve1",
      -doorLength / 2 - frameLength / 2 + thickness1 / 4,
      0,
      0,
      doorLength / 2,
      "positive"
    );
    //  curve1.position.x = -doorWidth/2;
    let curve2 = drawCurve(
      "curve2",
      0,
      doorLength / 2 + frameLength / 2 - thickness1 / 4,
      -doorLength / 2,
      0,
      "negetive"
    );
    //  curve2.position.x = doorWidth/2;

    oneDoor.push(door1);
    oneDoor.push(circle1);
    oneDoor.push(curve1);
    oneDoor.push(curve2);
    let mergedMesh = BABYLON.Mesh.MergeMeshes(oneDoor, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let singleDoor = function (radius) {
    let mergeArr = [];
    //frames
    let frame1 = drawDoorFrame(
      "singleDoor",
      null,
      null,
      null,
      thickness1,
      radius
    );
    let frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    let frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.z = frameWidth - thickness1;
    frame1.position.x = thickness1 / 2;
    let frame2 = drawDoorFrame("frame2", null, null, null, thickness1, radius);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;
    //shutter
    let fDoor1 = drawDoorFrame(
      "fDoor1",
      radius - frameLength,
      frameWidth / 2,
      null,
      thickness1
    );
    fDoor1.position.x = frameLength - thickness1;
    fDoor1.rotation.y = -Math.PI / 2;
    let doorLength = fDoor1._boundingInfo.boundingBox.extendSize.x * 2;
    let doorWidth = fDoor1._boundingInfo.boundingBox.extendSize.z * 2;
    //curve
    let curve1 = drawCurve(
      "curve1",
      radius - frameLength,
      frameLength + doorWidth / 2,
      0,
      doorLength,
      "positive"
    );
    mergeArr.push(frame1);
    mergeArr.push(frame2);
    mergeArr.push(fDoor1);
    mergeArr.push(curve1);
    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let doubleDoor = function (radius) {
    let mergeArr = [];
    //frame
    let frame1 = drawDoorFrame(
      "doubleDoor",
      null,
      null,
      null,
      thickness1,
      radius
    );
    let frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    let frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.z = frameWidth - thickness1;
    frame1.position.x = thickness1 / 2;
    let frame2 = drawDoorFrame("frame2", null, null, null, thickness1, radius);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;

    //shutters
    let fDoor1 = drawDoorFrame(
      "fDoor1",
      radius / 2 - frameLength,
      frameWidth / 2,
      null,
      thickness1
    );
    fDoor1.position.x = frameLength - thickness1;
    fDoor1.rotation.y = -Math.PI / 2;
    let doorLength = fDoor1._boundingInfo.boundingBox.extendSize.x * 2;
    let doorWidth = fDoor1._boundingInfo.boundingBox.extendSize.z * 2;
    let fDoor2 = drawDoorFrame(
      "fDoor2",
      radius / 2 - frameLength,
      frameWidth / 2,
      null,
      thickness1
    );
    fDoor2.position.x = radius - frameLength * 2 + 1.5 * thickness1;
    fDoor2.rotation.y = -Math.PI / 2;

    //curve
    let curve1 = drawCurve(
      "curve1",
      radius / 2,
      frameLength + doorWidth / 2,
      0,
      doorLength,
      "positive"
    );
    let curve2 = drawCurve(
      "curve2",
      radius / 2,
      radius - frameLength - doorWidth / 2,
      0,
      doorLength,
      "positive"
    );

    mergeArr.push(frame1);
    mergeArr.push(frame2);
    mergeArr.push(fDoor1);
    mergeArr.push(fDoor2);
    mergeArr.push(curve1);
    mergeArr.push(curve2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawSlidingDoor = function (radius, shutters) {
    let oneDoor = [];
    let remRadius = radius - frameLength * 2;

    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let drawSlider1 = drawDoorFrame(
      "sdFrame1",
      frameLength * 2,
      frameWidth / shutters - thickness1,
      null,
      thickness1
    );
    sliderLength = drawSlider1._boundingInfo.boundingBox.extendSize.x * 2;
    sliderWidth = drawSlider1._boundingInfo.boundingBox.extendSize.z * 2;
    let drawRadius = (remRadius + (shutters - 1) * sliderLength) / shutters;
    let drawSlider2 = drawDoorFrame(
      "sdFrame1",
      frameLength * 2,
      frameWidth / shutters - thickness1,
      null,
      thickness1
    );
    drawSlider2.position.x = drawRadius - sliderLength + thickness1;
    let shutterSill = drawDoorWidthLine(drawRadius, null, thickness4);
    shutterSill.position.z = thickness1 / 2;
    let shutterSill2 = drawDoorWidthLine(drawRadius, null, thickness4);
    shutterSill2.position.z = -sliderWidth + thickness1 / 2;
    let glass = drawGlass(
      drawRadius - sliderLength * 2 + thickness1 * 2,
      sliderLength - thickness1
    );
    glass.position.z = -sliderWidth / 2 + thickness1 / 2;
    oneDoor.push(drawSlider1);
    oneDoor.push(drawSlider2);
    oneDoor.push(shutterSill);
    oneDoor.push(shutterSill2);
    oneDoor.push(glass);
    let mergedMesh = BABYLON.Mesh.MergeMeshes(oneDoor, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let sliding = function (radius, type) {
    let shutters = type.substr(type.length - 1);
    let mergeArr = [];
    let doorGen = [];
    let frames = drawFrameSliders(radius);

    for (let i = 0; i < shutters; i++) {
      let doorShutters = drawSlidingDoor(radius, shutters);
      doorGen.push(doorShutters);
    }

    sliderLength = sliderLength ? sliderLength : frameLength * 2;
    sliderWidth = sliderWidth
      ? sliderWidth
      : frameWidth / shutters - thickness1;
    let remRadius = radius - frameLength * 2;
    let doorSpan = doorGen[0].getBoundingInfo().boundingBox.extendSize.x * 2;
    let sliderLen = 0;
    let doorWidSpan = frameWidth / shutters;
    let doorWid = frameWidth - thickness1;
    let doorLen = 0;
    let posX = 0;
    for (let i = 1; i < doorGen.length + 1; i++) {
      let positionX;
      positionX = posX + frameLength + doorLen - thickness1;
      posX = positionX;
      doorGen[i - 1].position.x = positionX;
      let positionZ = doorWid - doorWidSpan * (i - 1);
      doorGen[i - 1].position.z = positionZ;
      sliderLen = sliderLength;
      doorLen = doorSpan - sliderLen - frameLength + thickness1;
    }
    mergeArr.push(frames);
    doorGen.forEach((ele) => mergeArr.push(ele));

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let folding = function (radius, type) {
    let shutters = type.substr(type.length - 1);
    let mergeArr = [];
    let doorGen = [];
    let frames = drawFramesGuide(radius);
    if (shutters == 2) {
      let fdoor = drawFoldingEven(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      doorGen.push(fdoor);
    }
    if (shutters == 4) {
      let fdoor = drawFoldingEven(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      let fdoor2 = drawFoldingEven(radius, shutters);
      fdoor2.position.z = frameWidth / 2;
      fdoor2.rotation.z = Math.PI;
      fdoor2.position.x = radius - frameLength + thickness1;
      doorGen.push(fdoor);
      doorGen.push(fdoor2);
    }
    if (shutters == 3) {
      let fdoor = drawFoldingOdd(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      doorGen.push(fdoor);
    }
    if (shutters == 6) {
      let fdoor = drawFoldingOdd(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      let fdoor2 = drawFoldingOdd(radius, shutters);
      fdoor2.position.z = frameWidth / 2;
      fdoor2.rotation.z = Math.PI;
      fdoor2.position.x = radius - frameLength + thickness1;
      doorGen.push(fdoor);
      doorGen.push(fdoor2);
    }
    mergeArr.push(frames);
    doorGen.forEach((ele) => mergeArr.push(ele));

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let pivot = function (radius, type) {
    let shutters = type.substr(type.length - 1);
    let mergeArr = [];
    let frames = drawBothFrames(radius);
    let doorGen = [];
    for (let i = 0; i < shutters; i++) {
      let name = "fdoor" + i;
      let doorShutter = drawPivotDoor(radius, shutters, name);
      doorGen.push(doorShutter);
    }
    let remRadius = radius - frameLength * 2;
    let doorSpan = remRadius / shutters;
    let doorLen = 0;
    for (let i = 1; i < doorGen.length + 1; i++) {
      let position = doorSpan / 2 + doorLen + frameLength;
      doorGen[i - 1].position.x = position;
      doorGen[i - 1].position.z = frameWidth / 2;
      doorLen = doorSpan * i;
    }
    mergeArr.push(frames);
    doorGen.forEach((ele) => mergeArr.push(ele));

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  
  /*
  Borrowed from fixed window representation
   */
  let fixedDoor = function (radius, name) {
    // console.log(type);
    let mergeArr = [];
    //Frame
    let frame1 = drawDoorFrame(
      name + "1",
      null,
      null,
      null,
      thickness1,
      radius
    );
    frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.x = thickness1 / 2;
    frame1.position.z = frameWidth - thickness1;
    let frame2 = drawDoorFrame(name + "2", null, null, null, thickness1, radius);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;

    //WindowSill
    let windowSill = drawThickLine(radius, null, thickness4);
    let windowSill2 = drawThickLine(radius, null, thickness4);
    windowSill.position.z =
      frameWidth / 2 +
      DisplayOperation.getOriginalDimension("100", "millimeter") -
      thickness1;
    windowSill2.position.z =
      frameWidth / 2 -
      DisplayOperation.getOriginalDimension("100", "millimeter");

    //Glass
    let glassLength = radius - 2 * frameLength;
    let glass = drawGlass(glassLength, frameLength);
    glass.position.z = frameWidth / 2 - thickness1 / 2;
    glass.position.x = 0;

    //Shutter bottom
    let shutterBottom = drawThickLine(glassLength, null, thickness3);
    shutterBottom.position.x = frameLength;
    shutterBottom.position.z = frameWidth - thickness1;
    let shutterBottom2 = drawThickLine(glassLength, null, thickness3);
    shutterBottom2.position.z = 0;
    shutterBottom2.position.x = frameLength;

    mergeArr.push(frame1);
    mergeArr.push(frame2);
    mergeArr.push(windowSill);
    mergeArr.push(windowSill2);
    mergeArr.push(glass);
    mergeArr.push(shutterBottom);
    mergeArr.push(shutterBottom2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(
      mergeArr,
      true,
      true,
      undefined,
      false,
      true
    );
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };

  let revitImportDoor = function (symbolData) {
    let mergeMeshes = [],
      door2D;
    let rotation = symbolData[symbolData.length - 1].rotation;
    for (let d = 0; d < symbolData.length - 1; d++) {
      if (symbolData[d].type == "Arc") {
        door2D = drawCurve(
          "curve1",
          symbolData[d].startPoint[0],
          symbolData[d].endPoint[0],
          symbolData[d].startPoint[1],
          symbolData[d].endPoint[1],
          symbolData[d].typeOfArc
        );
        mergeMeshes.push(door2D);
      } else if (symbolData[d].type == "Line") {
        let startPoint = new BABYLON.Vector3.FromArray(
          symbolData[d].startPoint
        );
        let endPoint = new BABYLON.Vector3.FromArray(symbolData[d].endPoint);
        door2D = drawThickLine(null, startPoint, 0.06, endPoint);
        mergeMeshes.push(door2D);
      }
    }

    let mergedMesh = BABYLON.Mesh.MergeMeshes(
      mergeMeshes,
      true,
      true,
      undefined,
      false,
      false
    );
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    mergedMesh.rotation.y = 2 * Math.PI - rotation;
    return mergedMesh;
  };

  let _drawDoor2D = function (origin, radius, type, symbolData) {
    let genDoor;
    switch (type) {
      case "singleDoor":
        genDoor = singleDoor(radius);
        break;
      case "doubleDoor":
        genDoor = doubleDoor(radius);
        break;
      case "sliding2":
        genDoor = sliding(radius, type);
        break;
      case "sliding4":
        genDoor = sliding(radius, type);
        break;
      case "sliding6":
        genDoor = sliding(radius, type);
        break;
      case "sliding3":
        genDoor = sliding(radius, type);
        break;
      case "folding2":
        genDoor = folding(radius, type);
        break;
      case "folding4":
        genDoor = folding(radius, type);
        break;
      case "folding3":
        genDoor = folding(radius, type);
        break;
      case "folding6":
        genDoor = folding(radius, type);
        break;
      case "pivot3":
        genDoor = pivot(radius, type);
        break;
      case "pivot2":
        genDoor = pivot(radius, type);
        break;
      case "pivot4":
        genDoor = pivot(radius, type);
        break;
      case "pivot6":
        genDoor = pivot(radius, type);
        break;
      case "fixed":
        genDoor = fixedDoor(radius, type);
        break;
      case "garage":
        genDoor = fixedDoor(radius, type);
        break;
      case "rollingShutter":
        genDoor = fixedDoor(radius, type);
        break;
      case "revitImport":
        genDoor = revitImportDoor(symbolData);
        break;
      default:
        return null;
    }
    return genDoor;
  };

  return {
    createDoor2D: function (doorType, radius, symbolData) {
      return _drawDoor2D(BABYLON.Vector3.Zero(), radius, doorType, symbolData);
    },
    vectorArc,
    showAngleSector,
    drawThickLine,
    drawCurve,
  };
})();
export { drawDoor };
