import _ from "lodash";
import { getDimensionInSnaptrudeUnits } from ".";
import { djangoUrl } from "../../../services/url.constants";
import { getApplyMaterialSaveData, getMaterialCommandLogic, prepareDataForCMD, saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import { createCustomMesh } from "../../libs/massModeling";
import { recreateStandardMaterial } from "../../libs/sceneStateFuncs";
import BABYLON from "../babylonDS.module";
import { Command } from "../commandManager/Command";
import { CommandManager } from "../commandManager/CommandManager";
import { commandUtils } from "../commandManager/CommandUtils";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { store } from "../utilityFunctions/Store";
import speckleConstants from "./speckleConstants";

const getPointsData3 = (data) => {
  let points = [],
    firstPoint = [],
    nextPoint = [];
  let i = 0;
  if (data[i].speckle_type === "Objects.Geometry.Line") {
    firstPoint = [data[i].start.x, data[i].start.y, data[i].start.z];
    nextPoint = [data[i].end.x, data[i].end.y, data[i].end.z];
  } else if (data[i].speckle_type === "Objects.Geometry.Arc") {
    firstPoint = [
      data[i].startPoint.x,
      data[i].startPoint.y,
      data[i].startPoint.z,
    ];
    nextPoint = [data[i].endPoint.x, data[i].endPoint.y, data[i].endPoint.z];
  }
  points.push(firstPoint);

  while (points.length < data.length) {
    for (let j = 0; j < data.length; ++j) {
      let currentStartPoint, currentEndPoint;
      if (data[j].speckle_type === "Objects.Geometry.Line") {
        currentStartPoint = [data[j].start.x, data[j].start.y, data[j].start.z];
        currentEndPoint = [data[j].end.x, data[j].end.y, data[j].end.z];
      } else if (data[j].speckle_type === "Objects.Geometry.Arc") {
        currentStartPoint = [
          data[j].startPoint.x,
          data[j].startPoint.y,
          data[j].startPoint.z,
        ];
        currentEndPoint = [
          data[j].endPoint.x,
          data[j].endPoint.y,
          data[j].endPoint.z,
        ];
      }
      if (
        Math.round(nextPoint[0], 5) === Math.round(currentStartPoint[0], 5) &&
        Math.round(nextPoint[1], 5) === Math.round(currentStartPoint[1], 5)
      ) {
        points.push([
          currentStartPoint[0],
          currentStartPoint[1],
          currentStartPoint[2],
        ]);
        nextPoint = [
          currentEndPoint[0],
          currentEndPoint[1],
          currentEndPoint[2],
        ];
        break;
      }
    }
  }
  return points;
};

const getPointsData = (data) => {
  let points = [],
    firstPoint = [],
    nextPoint = [];
  let i = 0;
  if (data[i].speckle_type === "Objects.Geometry.Line") {
    firstPoint = [data[i].start.x, data[i].start.y, data[i].start.z];
    nextPoint = [data[i].end.x, data[i].end.y, data[i].end.z];
  } else if (data[i].speckle_type === "Objects.Geometry.Arc") {
    firstPoint = [
      data[i].startPoint.x,
      data[i].startPoint.y,
      data[i].startPoint.z,
    ];
    nextPoint = [data[i].endPoint.x, data[i].endPoint.y, data[i].endPoint.z];
  }
  points.push(firstPoint);

    for (let j = 0; j < data.length; ++j) {
      let currentStartPoint, currentEndPoint;
      if (data[j].speckle_type === "Objects.Geometry.Line") {
        currentStartPoint = [data[j].start.x, data[j].start.y, data[j].start.z];
        currentEndPoint = [data[j].end.x, data[j].end.y, data[j].end.z];
        points.push(currentEndPoint)
      } else if (data[j].speckle_type === "Objects.Geometry.Arc") {
        currentStartPoint = [
          data[j].startPoint.x,
          data[j].startPoint.y,
          data[j].startPoint.z,
        ];
        currentEndPoint = [
          data[j].endPoint.x,
          data[j].endPoint.y,
          data[j].endPoint.z,
        ];
        // const curvedPoints = getPointsOfArc(data[j])
        points.push(currentEndPoint)
      }
    }
  return points;
};

const getPointsData2 = (data) => {
  let points = [],
    firstPoint = [],
    nextPoint = [];
  let i = 0;
  if (data[i].speckle_type === "Objects.Geometry.Line") {
    firstPoint = [data[i].start.x, data[i].start.y, data[i].start.z];
    nextPoint = [data[i].end.x, data[i].end.y, data[i].end.z];
    points.push(firstPoint);
  } else if (data[i].speckle_type === "Objects.Geometry.Arc") {
    firstPoint = [
      data[i].startPoint.x,
      data[i].startPoint.y,
      data[i].startPoint.z,
    ];
    nextPoint = [data[i].endPoint.x, data[i].endPoint.y, data[i].endPoint.z];
  }
  let noOfPoints = points.length;
  while (noOfPoints < data.length) {
    for (let j = 0; j < data.length; ++j) {
      let currentStartPoint, currentEndPoint, pointsToPushed;
      if (data[j].speckle_type === "Objects.Geometry.Line") {
        currentStartPoint = [data[j].start.x, data[j].start.y, data[j].start.z];

        currentEndPoint = [data[j].end.x, data[j].end.y, data[j].end.z];
        pointsToPushed = currentStartPoint;
      } else if (data[j].speckle_type === "Objects.Geometry.Arc") {
        currentStartPoint = [
          data[j].startPoint.x,
          data[j].startPoint.y,
          data[j].startPoint.z,
        ];
        currentEndPoint = [
          data[j].endPoint.x,
          data[j].endPoint.y,
          data[j].endPoint.z,
        ];
        // pointsToPushed = getPointsOfArc(data[j]);
      }
      if (
        Math.round(nextPoint[0], 5) === Math.round(currentStartPoint[0], 5) &&
        Math.round(nextPoint[1], 5) === Math.round(currentStartPoint[1], 5)
      ) {
        // points.push([
        //   currentStartPoint[0],
        //   currentStartPoint[1],
        //   currentStartPoint[2],
        // ]);
        if (data[j].speckle_type === "Objects.Geometry.Arc") {
          pointsToPushed = getPointsOfArc(data[j]);
          points.push(...pointsToPushed);
        } else {
          points.push(pointsToPushed);
        }
        ++noOfPoints;
        nextPoint = [
          currentEndPoint[0],
          currentEndPoint[1],
          currentEndPoint[2],
        ];
        break;
      }
    }
  }

  return points;
};

const getPointsOfArc = (parameter) => {
  let points = [];
  // for (let point = 0; point < parameters.length; ++point) {
  let arcPoints = [];
  let centerPoint = parameter.plane.origin;
  centerPoint = [centerPoint.x, centerPoint.z, centerPoint.y];

  let startingAngle = parameter.startAngle;
  let endingAngle =
    parameter.endAngle > parameter.startAngle
      ? parameter.endAngle
      : parameter.startAngle - parameter.angleRadians;
  let radius = parameter.radius;
  let n = 8;

  for (let k = 0; k < n; ++k) {
    let x =
      centerPoint[0] +
      radius *
        Math.cos(startingAngle + (k * (endingAngle - startingAngle)) / (n - 1));
    let y =
      centerPoint[2] +
      radius *
        Math.sin(startingAngle + (k * (endingAngle - startingAngle)) / (n - 1));
    points.push([x, y, centerPoint[1]]);
  }
  // }
  return points;
};



const getCommandDataFromData = (data, name) => {
  if (!data || data.length == 0) return;
  let dataCreationCommand;
  try {
    let dataCreation = commandUtils.creationOperations.getCommandData(data);
    dataCreationCommand = commandUtils.creationOperations.getCommand(
      name,
      dataCreation
    );
  } catch (error) {
    console.log(error);
  }
  return dataCreationCommand;
};

const saveDataInBackend = (stack) => {
  let commandData = commandUtils.creationOperations.getCommandData(stack);
  let detectCommandName = "sketchObjectDetection";

  const creationCommand = commandUtils.creationOperations.getCommand(
    detectCommandName,
    commandData
  );

  CommandManager.execute(creationCommand, false);
};

const getMaterialIndex = (mesh, mat) => {
  let materialIndex, isNewMaterial;
  if (mesh.material.subMaterials) {
    let multiMatSubMaterials = mesh.material.subMaterials;
    let materialExistFlag = false;
    let materialExistIndex = -1;
    for (let p = 0; p < multiMatSubMaterials.length; p++) {
      if (multiMatSubMaterials[p].id === mat.id) {
        materialExistFlag = true;
        materialExistIndex = p;
      }
    }
    if (!materialExistFlag) {
      multiMatSubMaterials.push(mat);
      materialIndex = multiMatSubMaterials.length - 1;
    } else {
      materialIndex = materialExistIndex;
    }
  } else {
    let multiMat;
    let id = "multiMat-" + mat.id ;
    multiMat = store.scene.getMultiMaterialByID( id ) 
    isNewMaterial = multiMat ? false : true;
    if(isNewMaterial){
      multiMat = new BABYLON.MultiMaterial(id, store.scene);
      multiMat.subMaterials.push(mesh.material);
      multiMat.subMaterials.push(mat);
    }
    mesh.material = multiMat;
    materialIndex = multiMat.subMaterials.length - 1;
  }
  return {materialIndex, isNewMaterial};
};

/**
 * Return material URL from given materialList
 *@param {*} path name of material file
 *@param {*} materialList list of materials
 */
 const getMaterialUrl = (path, materialList) => {
  let name = path;
  name = name.replaceAll(" ", "_");
  name = name.replaceAll("&", "");

  for (let i = 0; i < materialList.length; ++i) {
    let mat = materialList[i].url.replace(/^.*[\\\/]/, "");
    if (name === mat) {
      return materialList[i].url;
    }
  }
  return `/media/media/revitMaterialFolder/${path}`;
};
const getMeshDataFromReferenceData = (meshData, referenceMeshData) => {
  for (let j = 0; j < meshData.length; ++j) {
    if (meshData[j].id === referenceMeshData.referencedId) {
      return meshData[j];
    }
  }
  return [];
};

const getGeometryDataFromReferenceData = (
  geometryData,
  referenceGeometryData
) => {
  let geometry = [];
  for (let j = 0; j < geometryData.length; ++j) {
    for (let v = 0; v < referenceGeometryData.length; ++v) {
      if (referenceGeometryData[v].id === geometryData[j].referencedId) {
        geometry.push(...referenceGeometryData[v].data);
        break;
      }
    }
  }
  return geometry;
};

const convertVerticesToSnaptrude = (vertex, basePoint, unit = "mm") => {
  for (let v = 0; v < vertex.length; v += 3) {
    [vertex[v], vertex[v + 1], vertex[v + 2]] = [
      vertex[v] - basePoint[0],
      vertex[v + 2] - basePoint[2],
      vertex[v + 1] - basePoint[1],
    ];
  }

  for (let v = 0; v < vertex.length; v++) {
    vertex[v] = DisplayOperation.getOriginalDimension(vertex[v], speckleConstants.speckleUnits[unit]);
  }
  return vertex;
};

const convertFacesToSnaptrude = (face) => {
  let faces = [];
  for (let f = 0; f < face.length; ++f) {
    if (f % 4 == 0) {
      if (face[f] == 1) console.log(face(f), f);
      continue;
    }
    faces.push(face[f]);
  }
  return faces;
};

const createMeshWithMeshData = (vertex, face, uv) => {
  let normals = [];
  const createdMesh = new BABYLON.Mesh("custom");
  let positions = vertex;
  let indices = face;
  BABYLON.VertexData.ComputeNormals(positions, indices, normals);

  const vertexData = new BABYLON.VertexData();
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  if (uv && uv.length > 0) vertexData.uvs = uv;

  vertexData.applyToMesh(createdMesh, true);
  return createdMesh;
};

const createMaterialFromSpeckleData = (
  materialInfo,
  materialName,
  materialList,
  options = { createUsingSpeckle: false, getDefaultMaterial: false }
) => {
  let material, materialUrl;
  if (options?.createUsingSpeckle) {
    let color = argbToRgb(materialInfo.diffuse).map((c) => c / 255);
    let emissive = argbToRgb(materialInfo.emissive).map((c) => c / 255);
    let alpha = materialInfo.opacity;
    material = {
      name: materialName,
      id: materialName,
      diffuse: color,
      alpha: alpha,
      emissive: emissive,
    };
  }
  // else if (
  //   materialInfo.type === speckleConstants.material.WALLPAINT ||
  //   materialInfo.type === speckleConstants.material.CERAMIC ||
  //   materialInfo.type === speckleConstants.material.METAL ||
  //   materialInfo.type === speckleConstants.material.GLAZING ||
  //   materialInfo.type === speckleConstants.material.PLASTIC ||
  //   materialInfo.type === speckleConstants.material.COLOR
  // ) {
  //   let materialColorInfo = materialInfo.value["color"];
  //   let color = materialColorInfo ? materialColorInfo.slice(0, 3) : [1, 1, 1];
  //   let alpha = materialColorInfo ? materialColorInfo[3] : 1;
  //   material = {
  //     name: materialName,
  //     id: materialName,
  //     diffuse: color,
  //     alpha: alpha,
  //   };
  // }
  // else if (
  //   materialInfo.type === speckleConstants.material.TEXTURE ||
  //   materialInfo.type === speckleConstants.material.WOOD ||
  //   materialInfo.type === speckleConstants.material.CONCRETE
  // ) {
  else if (materialInfo?.value?.name) {
    if (materialInfo.value.path) {
      materialUrl = getMaterialUrl(materialInfo.value.path, materialList);
    }
    if (!materialUrl && materialInfo.value.path) {
      materialUrl = `/media/revitMaterialFolder/${materialInfo.value.path}`;
    }
    let materialColorInfo = materialInfo.value;
    let color =
      materialColorInfo?.diffuseColor && !materialUrl
        ? materialColorInfo.diffuseColor.slice(0, 3)
        : [1, 1, 1];

    let fallbackColor;
    if (
      options?.category.toLowerCase() != "wall" &&
      options?.category.toLowerCase() != "floor" &&
      options?.category.toLowerCase() != "roof"
    ) {
      fallbackColor = materialColorInfo?.diffuseColor;
    }
    let alpha = materialColorInfo?.diffuseColor
      ? materialColorInfo.diffuseColor[3]
      : 1;

    material = {
      name: materialName,
      id: materialName,
      diffuse: color,
      fallbackColor: fallbackColor,
      diffuseTexture: materialUrl
        ? {
            url: materialUrl,
            uScale:
              1 /
              DisplayOperation.getOriginalDimension(
                materialColorInfo.uScale,
                "millimeter"
              ),
            vScale:
              1 /
              DisplayOperation.getOriginalDimension(
                materialColorInfo.vScale,
                "millimeter"
              ),
            wAng: materialColorInfo.wAng,
            uOffset: materialColorInfo.uOffset,
            vOffset: materialColorInfo.vOffset,
          }
        : {},
      alpha: alpha,
    };
  } else if (!materialInfo?.type || options?.getDefaultMaterial) {
    material = {
      name: materialName,
      id: materialName,
      diffuse: [80 / 255, 80 / 255, 80 / 255],
      alpha: 1,
    };
  }
  // if (material) {
  //   recreateStandardMaterial(material).then(() => {
  //     material = store.scene.getMaterialByName(`${materialName}`);
  //   if (material) {
  //     saveMaterialInBackend(material);
  //   }
  //   return material
  //   });
  // }
  if (material) {
    material.revitImport = true;
    recreateStandardMaterial(material, options?.category?.toLowerCase());
    material = store.scene.getMaterialByName(`${materialName}`);
    // if (!materialUrl) {
    //   saveMaterialInBackend(material);
    // }
  }
  return material;
};

const createCustomMeshFromSpeckleData = (
  data,
  meshData,
  verticesData,
  faceData,
  materialInfo,
  materialList,
  options = { renderUsingSpeckle: true }
) => {
  
  let mesh, vertex, face, basePoint, uv;
  if (data.basePoint)
    basePoint = [data.basePoint.x, data.basePoint.y, data.basePoint.z];
  if (data.baseLine)
    basePoint = [
      data.baseLine.start.x,
      data.baseLine.start.y,
      data.baseLine.start.z,
    ];
  if (!basePoint) {
    basePoint = [0, 0, 0];
  }
  let customMesh, createdMesh;

  if (data.displayValue?.length) {
    mesh = [];
    let MultiMaterialName = `multiMat-${data.type}-${data.family}`;
    let multiMat = new BABYLON.MultiMaterial(MultiMaterialName, store.scene);
    let customMeshes = [];
    for (let i = 0; i < data.displayValue.length; ++i) {
      vertex = [];
      face = [];

      let materials;
      let materialName;
      // uv = [].concat(...uv);
      mesh = getMeshDataFromReferenceData(meshData, data.displayValue[i]);
      let unit = mesh?.units ? mesh?.units : "mm";
      if (options.renderUsingSpeckle) {
        materials = mesh.renderMaterial;
        materialName = materials?.name
          ? `${data.type}-${materials?.name}`
          : undefined; // if renderMaterial is undefined
      } else {
        uv = materialInfo[materialInfo["order"][i]]["uvs"];
        let materialName = materialInfo["order"][i];
        uv = [].concat(...uv);
        materials = materialInfo[materialName]["materialInfo"];
      }

      if (mesh) {
        vertex = getGeometryDataFromReferenceData(mesh.vertices, verticesData);
        vertex = convertVerticesToSnaptrude(vertex, basePoint, unit);
        face = getGeometryDataFromReferenceData(mesh.faces, faceData);
        face = convertFacesToSnaptrude(face);
        createdMesh = createMeshWithMeshData(vertex, face, uv);
        let material = store.scene.getMaterialByName(`${materialName}`);
        if (!material && materialName) {
          material = createMaterialFromSpeckleData(
            materials,
            materialName,
            materialList,
            { createUsingSpeckle: true }
          );
        } else if (!materialName) {
          materialName = `${data.type}`;
          material = createMaterialFromSpeckleData([], materialName, [], {
            getDefaultMaterial: true,
          });
        }
        if (material) multiMat.subMaterials.push(material);
        customMeshes.push(createdMesh);
      }
    }
    // if (multiMat) {
    //   saveMaterialInBackend(multiMat);
    // }
    if (customMeshes.length > 1) {
      customMesh = BABYLON.Mesh.MergeMeshes(
        customMeshes,
        true,
        true,
        undefined,
        true,
        false
      );
    } else {
      customMesh = customMeshes[0];
    }
    customMesh.material = multiMat;

    for (let i = 0; i < customMeshes.length; ++i) {
      customMesh.subMeshes[i].materialIndex = i;
    }
  }
  return customMesh;
};

const createCustomMeshWithSpeckleDataWithoutMaterial = (
  data,
  meshData,
  verticesData,
  faceData,
  options = { renderUsingSpeckle: true }
) => {
  let mesh, vertex, face, basePoint, uv;

  let customMesh, createdMesh;

  if (data.displayValue?.length) {
    let customMeshes = [];
    for (let i = 0; i < data.displayValue.length; ++i) {
      vertex = [];
      face = [];
      // uv = [].concat(...uv);
      mesh = getMeshDataFromReferenceData(meshData, data.displayValue[i]);

      if (mesh) {
        vertex = getGeometryDataFromReferenceData(mesh.vertices, verticesData);
        vertex = convertVerticesToSnaptrude(vertex, [0, 0, 0]);
        face = getGeometryDataFromReferenceData(mesh.faces, faceData);
        face = convertFacesToSnaptrude(face);
        createdMesh = createMeshWithMeshData(vertex, face, uv);
        customMeshes.push(createdMesh);
      }
    }
    if (customMeshes.length > 1) {
      customMesh = BABYLON.Mesh.MergeMeshes(
        customMeshes,
        true,
        true,
        undefined,
        true,
        false
      );
    } else {
      customMesh = customMeshes[0];
    }
  }
  return customMesh;
};
const createCustomMeshFromRevitExport = (
  data,
  meshData,
  materialList,
  options = {
    renderUsingSpeckle: true,
    isRevitLinkedModel: false,
    isCurtainWallComponent: false,
    category: null,
  },
  locationArray = {}
) => {
  if (!meshData || !data) return;
  let materialData, multiMat, isNewMaterial;
  let mesh, vertex, face, basePoint, uvs;
  let customMesh, createdMesh;
  let offset = [0, 0, 0];
  let meshCount = Object.keys(meshData).length - 1
  if (
    Object.keys(meshData).length > 1 ||
    (Object.keys(meshData).length >= 2 && options.isMassType)
  ) {
    mesh = [];
    if(meshCount > 1){
      let MultiMaterialName = `multiMat-${data.type}-${data.family}`;
      isNewMaterial = store.scene.getMultiMaterialByID(MultiMaterialName) ? false : true;
      multiMat = new BABYLON.MultiMaterial(MultiMaterialName, store.scene);
    }
    
    let customMeshes = [];

    let x = [],
      y = [],
      z = [],
      len = 0;

    if (!options.isMassType) {
      if (
        data.category == "Windows" ||
        data.category == "Doors"
      ) {
        for (let mat in meshData) {
          if (mat == "data") continue;
          for (let i = 0; i < meshData[mat]["vertices"].length; ++i) {
            x.push(meshData[mat]["vertices"][i][0] * 304.8);
            y.push(meshData[mat]["vertices"][i][1] * 304.8);
            z.push(meshData[mat]["vertices"][i][2] * 304.8);
          }
        }
      }
      if (data.category == "Windows" || data.category == "Doors" ) {
        offset[0] = Math.max(...x) + Math.min(...x);
        offset[1] = Math.max(...y) + Math.min(...y);
        offset[2] = Math.max(...z) + Math.min(...z);
        offset = offset.map((v) => v / 2);
      }


      if (data.category == "Doors" || data.category == "Windows") {
        offset = [0, 0, offset[2]];
        // offset = offset
      } else {
        offset = meshData["data"]["bbCenter"];
      }
    }

      for (let mat in meshData) {
        if (mat == "data") continue;
        if(!meshData[mat]["vertices"]) continue;
        vertex = [].concat(...meshData[mat]["vertices"]);
        vertex = vertex.map((v) => v * 304.8);
        vertex = convertVerticesToSnaptrude(vertex, offset);

        face = [].concat(...meshData[mat]["faces"]);
        uvs = [].concat(...meshData[mat]["uvs"]);
        let createdMesh = createMeshWithMeshData(vertex, face, uvs);

        let materials;
        let materialName;
        if (options.renderUsingSpeckle) {
          materials = mesh.renderMaterial;
          materialName = materials?.name
            ? `${data.type}-${materials?.name}`
            : undefined; // if renderMaterial is undefined
        } else {
          materials = meshData[mat]["materialInfo"];
          materialName =  meshData[mat]["materialInfo"]["value"]["name"] ?  meshData[mat]["materialInfo"]["value"]["name"] : mat ;
        }
        let material = store.scene.getMaterialByName(`${materialName}`);
        if (!material && materialName) {
          material = createMaterialFromSpeckleData(
            materials,
            materialName,
            materialList,
            {
              createUsingSpeckle: false,
              category: options.category || "furniture",
            }
          );
        }
        if(!material){
          material = store.scene.getMaterialByName("default material");
        }
        if(meshCount > 1){
          if (material) multiMat.subMaterials.push(material);
        }else{
          materialData = material
        }
        
        customMeshes.push(createdMesh);
      }

      if(!customMeshes.length) return;
      if (customMeshes.length > 1) {
        customMesh = BABYLON.Mesh.MergeMeshes(
          customMeshes,
          true,
          true,
          undefined,
          true,
          false
        );
      } else {
        customMesh = customMeshes[0];
      }
      if(multiMat && meshCount > 1){
        customMesh.material = multiMat;
        // if(isNewMaterial) saveMaterialInBackend(multiMat)
        for (let i = 0; i < customMeshes.length; ++i) {
          customMesh.subMeshes[i].materialIndex = i;
        }
      }else if(meshCount == 1){
        customMesh.material = materialData
      }
      customMesh.revitRotation = meshData["data"]["rotation"];
    }
    return customMesh;
};

const argbToRgb = (color) => {
  return hexToRgb(argbToHex(color));
};
const argbToHex = (color) => {
  return "#" + ("000000" + (color & 0xffffff).toString(16)).slice(-6);
};

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const checkIfImageIsPresent = async (relative_url) => {
  let url = djangoUrl + relative_url;
  let isImageExists = await imageExists(url);
  if (isImageExists) {
    return relative_url;
  } else {
    return `/media/media/materials/RAL_9010_JNZAKPF.jpg`;
  }
};

const imageExists = (url) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function () {
      if (request.status == 200) {
        //if(statusText == OK)
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
};

const getRevitName = (element) => {
  let name = element.type;
  if (element.family) {
    name += ` ${element.family}`;
  }
  if (element.width) {
    name += `-${parseInt(element.width)}`;
  }
  if (element.height) {
    name += `x${parseInt(element.height)}`;
  }
  if(element.isFaceFlipped != null)
    name += element.isFaceFlipped ? "-1" : "-0";
  return name;
};

const applyMaterialToFlippedGeometry = (
  object,
  subMeshId,
  materialName,
  materialByFace,
  selectedMaterial
) => {
  if (!["door", "window", "furniture"].includes(object.mesh.type.toLowerCase()))
    return;

  const applyMaterialCommandName = "applyMaterial";
  let applyMaterialCommandData = {};
  let name = object.mesh.sourceMesh.name.split("-");
  let flippedVersion = name.pop();
  name = name.join("-");

  if (!["1", "0"].includes(flippedVersion)) return;
  if (flippedVersion == "1") {
    name += "-0";
  } else {
    name += "-1";
  }
  let mesh = store.scene.getMeshByName(name);
  let material = store.scene.getMaterialByName(materialName);
  if (mesh) {
    applyMaterialCommandData._prevData = prepareDataForCMD(mesh, null);
    if (materialByFace) {
      let { materialIndex } = getMaterialIndex(mesh, material);
      const previousMaterialIndex = mesh.subMeshes[subMeshId].materialIndex
      mesh.subMeshes[subMeshId].materialIndex = materialIndex;
      mesh.subMeshes.map((submesh, index) =>{
        if(mesh.subMeshes[index].materialIndex == previousMaterialIndex){
          mesh.subMeshes[index].materialIndex = materialIndex
        }
      })
      applyMaterialCommandData._newData = prepareDataForCMD(mesh, null);
    } else {
      let materialIndex;
      var mat_name = selectedMaterial.replace(/^.*[\\/]/, "").split(".")[0];
      /* eslint-enable */
      let temp_mat = store.scene.getMaterialByName(materialName);

      if (mesh.material.subMaterials) {
        var multimatSubs = mesh.material.subMaterials;
        var mat_exist_flag = false;
        var mat_exist_index = -1;
        for (var p = 0; p < mesh.material.subMaterials.length; p++) {
          if (mesh.material.subMaterials[p].id === temp_mat.id) {
            mat_exist_flag = true;
            mat_exist_index = p;
          }
        }
        if (!mat_exist_flag) {
          mesh.material.subMaterials.push(temp_mat);

          materialIndex = multimatSubs.length - 1;
        } else {
          materialIndex = mat_exist_index;
        }
      } else {
        let id = Math.random().toString(36).substr(2, 6) + "_";
        var multimat = new BABYLON.MultiMaterial(
          temp_mat.name + id,
          store.scene
        );
        multimat.subMaterials.push(mesh.material);
        multimat.subMaterials.push(temp_mat);
        mesh.material = multimat;
        materialIndex = multimat.subMaterials.length - 1;
      }
      if (mesh.getSnaptrudeDS()) {
        let object = mesh.getSnaptrudeDS();
        mesh.subMeshes.forEach(function (subMesh) {
          subMesh.materialIndex = materialIndex;
        });
        applyMaterialCommandData._newData = prepareDataForCMD(mesh);
      }
    }

    mesh.synchronizeInstances();
    let cmd = new Command(
      applyMaterialCommandName,
      applyMaterialCommandData,
      getMaterialCommandLogic(),
      getApplyMaterialSaveData
    );

    return cmd;
  }
};

export {
  getPointsData,
  saveDataInBackend,
  getMaterialIndex,
  getMaterialUrl,
  getCommandDataFromData,
  getMeshDataFromReferenceData,
  getGeometryDataFromReferenceData,
  convertVerticesToSnaptrude,
  convertFacesToSnaptrude,
  createMeshWithMeshData,
  createMaterialFromSpeckleData,
  createCustomMeshFromSpeckleData,
  createCustomMeshFromRevitExport,
  getPointsData2,
  createCustomMeshWithSpeckleDataWithoutMaterial,
  checkIfImageIsPresent,
  getRevitName,
  applyMaterialToFlippedGeometry
};
