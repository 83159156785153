"use strict";
import BABYLON, { Ellipse } from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { colorUtil } from "../utilityFunctions/colorUtility.js";

const vertexIndicator = (function () {
  const CONSTANTS = {
    vertexIndicator: "vertexIndicator",
    size: 10,
    thickness: 1.5,
  };

  const TYPES = {
    allIndicators: 0,
    preSnapIndicator: 1,
    postSnapIndicator: 2,
    measureIndicator1: 3,
    measureIndicator2: 4,
    measureIndicator3: 5,
  };

  let texture;
  let preSnapIndicator;
  let postSnapIndicator;
  let measureIndicator1;
  let measureIndicator2;
  let measureIndicator3;

  const activeIndicators = [];

  const _addToTexture = function (control, texture) {
    if (!control.host) {
      texture.addControl(control);
    }
    if (!control.parent) {
      texture.rootContainer.addControl(control);
    }
  };

  const _newEllipse = function (color) {
    // preSnapIndicator = new BABYLON.GUI.Image(CONSTANTS.vertexIndicator, CONSTANTS.vertexIndicatorImagePath);

    let ellipse = new Ellipse("vertexIndicator");
    ellipse.color = color;
    ellipse.background = color;
    ellipse.disabledColor = colorUtil.type.nonEditable;
    ellipse.thickness = CONSTANTS.thickness;
    ellipse.widthInPixels = CONSTANTS.size;
    ellipse.heightInPixels = CONSTANTS.size;
    ellipse.isVisible = false;
    ellipse.snaptrudeProperties = {};
    
    // ellipse.shadowColor = colorUtil.type.black;
    // ellipse.shadowBlur = 5;

    _addToTexture(ellipse, texture);

    return ellipse;
  };

  const init = function () {
    texture = store.advancedTexture;
    preSnapIndicator = _newEllipse(colorUtil.type.preSnap);
    postSnapIndicator = _newEllipse(colorUtil.type.postSnap);
    measureIndicator1 = _newEllipse(colorUtil.type.postSnap);
    measureIndicator2 = _newEllipse(colorUtil.type.postSnap);
    measureIndicator3 = _newEllipse(colorUtil.type.postSnap);

    preSnapIndicator.zIndex = 1;
    postSnapIndicator.zIndex = 2;
  };

  const show = function (vertex, mesh, options = {}) {
    let activeIndicator;
    if (options.indicator === TYPES.preSnapIndicator)
      activeIndicator = preSnapIndicator;
    else if (options.indicator === TYPES.postSnapIndicator)
      activeIndicator = postSnapIndicator;
    else if (options.indicator === TYPES.measureIndicator1)
      activeIndicator = measureIndicator1;
    else if (options.indicator === TYPES.measureIndicator2)
      activeIndicator = measureIndicator2;
    else if (options.indicator === TYPES.measureIndicator3)
      activeIndicator = measureIndicator3;

    if (!activeIndicator.isVisible) activeIndicator.isVisible = true;
    _addToTexture(activeIndicator, texture);
    activeIndicator.snaptrudeProperties.mesh = mesh;
    activeIndicator.snaptrudeProperties.vertex = vertex.clone();
    activeIndicator.moveToVector3(vertex, store.scene);

    activeIndicators.push(activeIndicator);

    return activeIndicator;
  };

  const moveBy = function (movementAmount) {
    let activeIndicator = preSnapIndicator;
    if (!activeIndicator.isVisible) return;

    _addToTexture(activeIndicator, texture);
    activeIndicator.snaptrudeProperties.vertex.addInPlace(movementAmount);
    activeIndicator.moveToVector3(
      activeIndicator.snaptrudeProperties.vertex,
      store.scene
    );
  };

  const moveTo = function (destination) {
    let activeIndicator = preSnapIndicator;
    if (!activeIndicator.isVisible) return;

    moveBy(destination.subtract(activeIndicator.snaptrudeProperties.vertex));
  };

  const remove = function (type = TYPES.allIndicators) {
    const _deactivate = function (indicator) {
      indicator.isVisible = false;
      indicator.isEnabled = true;
      if (indicator.color === colorUtil.type.nonEditable) {
        const color =
          indicator === preSnapIndicator
            ? colorUtil.type.preSnap
            : colorUtil.type.postSnap;
        indicator.color = color;
        indicator.background = color;
      }
      _.remove(activeIndicators, indicator);
    };

    if (type === TYPES.allIndicators) {
      for (let i = 0; i < activeIndicators.length; ) {
        // no increment
        _deactivate(activeIndicators[i]);
      }
      // multiIndicators.remove();
    } else if (type === TYPES.preSnapIndicator) {
      _deactivate(preSnapIndicator);
    } else if (type === TYPES.postSnapIndicator) {
      _deactivate(postSnapIndicator);
    } else if (type === TYPES.measureIndicator1) {
      _deactivate(measureIndicator1);
    } else if (type === TYPES.measureIndicator2) {
      _deactivate(measureIndicator2);
    } else if (type === TYPES.measureIndicator3) {
      _deactivate(measureIndicator3);
    }
  };

  const markAsUneditable = function () {
    activeIndicators.forEach((indicator) => {
      indicator.color = colorUtil.type.nonEditable;
      indicator.background = colorUtil.type.nonEditable;
    });
  };

  const update = function () {
    activeIndicators.forEach((ai) => {
      ai.moveToVector3(ai.snaptrudeProperties.vertex, store.scene);
    });

    multiIndicators.update();
  };

  const getMetadata = function () {
    const getActiveIndicators = function () {
      return activeIndicators;
    };

    return {
      getActiveIndicators,
    };
  };

  const multiIndicators = (function () {
    let _multiMesh = null;
    const _multiActiveIndicators = [];

    const _addIndicator = function (v) {
      const indicator = _newEllipse(colorUtil.type.preSnap);
      indicator.isVisible = true;
      indicator.snaptrudeProperties.mesh = _multiMesh;
      indicator.snaptrudeProperties.vertex = v.clone();
      indicator.moveToVector3(v, store.scene);

      _multiActiveIndicators.push(indicator);
    };

    const add = function (v) {
      _addIndicator(v);

      store.scene.render();
      _.defer(update);
    };

    const show = function (vertices, mesh) {
      remove();

      _multiMesh = mesh;

      vertices.forEach((v) => {
        _addIndicator(v);
      });

      store.scene.render();
      _.defer(update);
    };

    const remove = function (v) {
      if (v) {
        _multiActiveIndicators.some((ai) => {
          if (ai.snaptrudeProperties.vertex.almostEquals(v)) {
            ai.dispose();
            _.remove(_multiActiveIndicators, ai);
            return true;
          }
        });
      } else {
        _multiActiveIndicators.forEach((ai) => {
          ai.dispose();
        });
        _multiActiveIndicators.length = 0;
      }
    };

    const update = function () {
      _multiActiveIndicators.forEach((ai) => {
        ai.moveToVector3(ai.snaptrudeProperties.vertex, store.scene);
      });
    };

    const isActive = function () {
      return _multiActiveIndicators.length > 1;
    };

    const getActiveMesh = function () {
      if (isActive()) {
        return _multiMesh;
      } else {
        return null;
      }
    };

    return {
      add,
      remove,
      show,
      update,
      isActive,
      getActiveMesh,
    };
  })();

  return {
    TYPES,
    multiIndicators,
    init,
    show,
    moveBy,
    markAsUneditable,
    getMetadata,
    moveTo,
    remove,
    update,
  };
})();
export { vertexIndicator };
