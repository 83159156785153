/*jshint esversion: 6 */
"use strict"
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { AutoSave } from "../socket/autoSave.js";
import { deepCopyObject, isMeshThrowAway } from "../extrafunc.js";
import { meshObjectMapping } from "./mapping.js";
import { LayerCollection } from "./layer.ds.js";
import { StoreyCollection, StoreysLinkedListCluster } from "./storey.ds.js";
import { Mass } from "./mass.ds.js";
import { Wall } from "./wall.ds.js";
import { Floor } from "./floor.ds.js";
import { Roof } from "./roof.ds.js";
import { Door } from "./doors.ds.js";
import { Window } from "./window.ds.js";
import { Staircase } from "./staircase.ds.js";
import { CurtainWall } from "./curtainwalls.ds.js";
import { Furniture } from "./furniture.ds.js";
import { Sketch } from "./sketch.ds.js";
import { StructureCollection } from "./structure.ds.js";
/**
 * { levels object }
 *
 * @class      LevelFltWeight (name)
 * @param      {String}  low     The low
 * @param      {String}  high    The high
 * @param      {number}  height  The height
 */
var LevelFltWeight = function (low, high, height) {
  this.low = low;
  this.high = high;
  this.walls = [];
  this.doors = [];
  this.windows = [];
  this.staircases = [];
  this.curtainwalls = [];
  this.furnitures = [];
  this.sketches = [];
  this.floors = [];
  this.roofs = [];
  this.masses = [];
  this.layers = [];
  this.height = height;
  this.uniqueId = "l_" + Math.random().toString(36).substr(3, 6);
  //add more
};

/**
 * { level created using flightweight pattern }
 *
 * @class      LevelFlyweightFactory (name)
 * @return     {(Object|number)}  { description_of_the_return_value }
 */
var LevelFlyweightFactory = (function () {
  "use strict";
  var levelFltWeight = {};

  return {
    get: function (low, high, height) {
      low = low.toString();
      high = high.toString();
      if (typeof low !== "string" && typeof high !== "string")
        throw "limts should be in string format";

      if (!levelFltWeight[low + high]) {
        levelFltWeight[low + high] = new LevelFltWeight(low, high, height);
      }
      return levelFltWeight[low + high];
    },
    getCount: function () {
      var count = 0;
      for (var s in levelFltWeight) count++;
      return count;
    },
    deleteAll: function () {
      levelFltWeight = {};
    },
    //add more
  };
})();

/**
 * { level collection data structure }
 *
 * @class      LevelCollection (name)
 * @return     {(Array|Object|number)}  { description_of_the_return_value }
 */
function LevelCollection() {
  var levelCollection = {};
  var count = 0;
  var layerData = null;
  var storeyData = null;
  var storeyCluster = null;

  let addLevelInBackend = function (newLevel, identifier) {
    function _accommodateArrayToObjectConversion(data) {
      _.forEach(data, (value, key) => {
        if (_.isArray(value)) {
          data[key] = {};
        }
      });
    }

    let saveData = AutoSave.getSaveDataPrototype();

    saveData.commandId = newLevel.flyweight.uniqueId;
    saveData.data.saveType = "addLevel";

    saveData.data.identifier = {
      structure_id: "default",
      floorkey: store.floorkey,
      name: identifier, //CHECK AUTO
    };

    if (store.activeLayer)
      saveData.data.identifier.structure_id = store.activeLayer.structure_id;

    saveData.data.afterOperationData = deepCopyObject(newLevel.flyweight);

    _accommodateArrayToObjectConversion(saveData.data.afterOperationData);
    // this is a problem. Should not happen, but legacy problems.

    AutoSave.directPublish(saveData);
  };

  return {
    addLevel: function (low, high, height = 12, options = {}) {
      levelCollection[low + high] = new Level(low, high, height);
      count++;

      if(options.autoSave === undefined ){
        options.autoSave = true;
      }
      if(options.autoSave){
        addLevelInBackend(levelCollection[low + high], low + high);
      }
      

      return levelCollection[low + high];
    },
    addMesh: function (low, high, object) {
      //TODO add check for object type
      if (!levelCollection[low + high]) throw "Add level first";
      let defaultMeshes = levelCollection[low + high].flyweight.getDefault();
      defaultMeshes.push(object);
    },
    getLevel: function (low, high) {
      low = low.toString();
      high = high.toString();

      return levelCollection[low + high];
    },
    getLevelByName: function (name) {
      return levelCollection[name];
    },
    getLevelsByLow: function (low) {
      low = low.toString();
      //console.log(Object.values(levelCollection));
      return Object.values(levelCollection).filter(
        (level) => level.flyweight.low === low
      );
    },
    getLevelByUniqueId: function (id) {
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];
        if (level.getUniqueId() === id) return level;
      }
      return null;
    },
    getAllLevels: function () {
      return levelCollection;
    },
    getCount: function () {
      return count;
    },
    deleteLevel: function (low, high) {
      delete levelCollection[low + high];
    },
    deleteAllLevels: function () {
      for (let level_id in levelCollection) {
        delete levelCollection[level_id];
      }
      count = 0;
      LevelFlyweightFactory.deleteAll();
    },
    recreateLevels: function (levelCollec) {
      // this.deleteAllLevels();
      for (let level_id in levelCollec) {
        let low_high = level_id.split(":");
        let level = this.addLevel(low_high[0], low_high[1], 12);
        for (let object of levelCollec[level_id]) {
          level.addObjectToLevel(object.item.obj, false);
        }
        delete levelCollec[level_id];
      }
    },
    getWallByAssignedId: function (id) {
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];
        //search in masses
        for (let wall of level.getWalls()) {
          if (wall.id === id) {
            return wall;
          }
        }
      }
      return null;
    },
    getObjectByUniqueId: function (uniqueId) {
      //iterate through all level collection
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];
        //search in masses
        for (let mass of level.getMasses()) {
          if (mass.mesh.uniqueId === uniqueId) {
            return mass;
          }
        }
        //search in walls
        for (let wall of level.getWalls()) {
          if (wall.mesh.uniqueId === uniqueId) {
            return wall;
          }
        }

        //search in staircase
        for (let staircase of level.getStaircases()) {
          if (staircase.mesh.uniqueId === uniqueId) {
            return staircase;
          }
        }
        //search in doors
        for (let doors of level.getDoors()) {
          if (doors.mesh.uniqueId === uniqueId) {
            return doors;
          }
        }
        //search in windows
        for (let win of level.getWindows()) {
          if (win.mesh.uniqueId === uniqueId) {
            return win;
          }
        }
        //search in roofs
        for (let roof of level.getRoofs()) {
          if (roof.mesh.uniqueId === uniqueId) {
            return roof;
          }
        }
        //search in floors
        for (let floor of level.getFloors()) {
          if (floor.mesh.uniqueId === uniqueId) {
            return floor;
          }
        }
        //search in furniture
        for (let furniture of level.getFurnitures()) {
          if (furniture.mesh.uniqueId === uniqueId) {
            return furniture;
          }
        }
      }
      return null;
    },
    getObjectByAssignedId: function(componentId){
      //iterate through all level collection
      for(let level_id in levelCollection){

        let level = levelCollection[level_id];
        //search in masses
        for(let mass of level.getMasses()){
          if(mass.id === componentId){
            return mass;
          }
        }
        //search in walls
        for(let wall of level.getWalls()){
          if(wall.id === componentId){
            return wall;
          }
        }

        //search in staircase
        for(let staircase of level.getStaircases()){
          if(staircase.id === componentId){
            return staircase;
          }
        }
        //search in doors
        for(let doors of level.getDoors()){
          if(doors.id === componentId){
            return doors;
          }
        }
        //search in windows
        for(let win of level.getWindows()){
          if(win.id === componentId){
            return win;
          }
        }
        //search in roofs
        for(let roof of level.getRoofs()){
          if(roof.id === componentId){
            return roof;
          }
        }
        //search in floors
        for(let floor of level.getFloors()){
          if(floor.id === componentId){
            return floor;
          }
        }
        //search in furniture
        for(let furniture of level.getFurnitures()){
          if(furniture.id === componentId){
            return furniture;
          }
        }
      }
      return null;
    },
    replaceObjectByUniqueId: function (uniqueId, structObject) {
      // Replace object from storey
      let storey = this.getStoreyData().getStoreyByValue(structObject.storey);
      meshObjectMapping.updateStructure(uniqueId, structObject);

      if (storey) {
        let element = storey.getElement(uniqueId);
        if (element) {
          storey.removeElement(element);
          storey.addElement(structObject);
        }
      }

      // meshObjectMapping.updateStructure(uniqueId, structObject);

      //iterate through all level collection
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];
        //search in masses
        for (let mass of level.getMasses()) {
          if (mass.mesh.uniqueId === uniqueId) {
            level.replaceMass(mass, structObject);
            return true;
          }
        }
        //search in walls
        for (let wall of level.getWalls()) {
          if (wall.mesh.uniqueId === uniqueId) {
            level.replaceWall(wall, structObject);
            return true;
          }
        }

        //search in staircase
        for (let staircase of level.getStaircases()) {
          if (staircase.mesh.uniqueId === uniqueId) {
            level.replaceStaircase(staircase, structObject);
            return true;
          }
        }
        //search in doors
        for (let door of level.getDoors()) {
          if (door.mesh.uniqueId === uniqueId) {
            level.replaceDoor(door, structObject);
            return true;
          }
        }
        //search in windows
        for (let win of level.getWindows()) {
          if (win.mesh.uniqueId === uniqueId) {
            level.replaceWindow(win, structObject);
            return true;
          }
        }
        //search in roofs
        for (let roof of level.getRoofs()) {
          if (roof.mesh.uniqueId === uniqueId) {
            level.replaceRoof(roof, structObject);
            return true;
          }
        }
        //search in floors
        for (let floor of level.getFloors()) {
          if (floor.mesh.uniqueId === uniqueId) {
            level.replaceFloor(floor, structObject);
            return true;
          }
        }
        //search in furniture
        for (let furniture of level.getFurnitures()) {
          if (furniture.mesh.uniqueId === uniqueId) {
            level.replaceFurniture(furniture, structObject);
            return true;
          }
        }
      }

      return null;
    },
    getLayerData: function () {
      if (layerData) return layerData;
      else {
        layerData = new LayerCollection();
        return layerData;
      }
    },
    getStoreyData: function () {
      if (storeyData) return storeyData;
      else {
        storeyData = new StoreyCollection();
        return storeyData;
      }
    },
    getLinkedListCluster: function () {
      if (storeyCluster) return storeyCluster;
      else {
        storeyCluster = new StoreysLinkedListCluster();
        return storeyCluster;
      }
    },
    getAllMeshes: function () {
      let meshData = [];
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];
        //search in masses
        for (let mass of level.getMasses()) {
          meshData.push(mass.mesh);
        }
        //search in walls
        for (let wall of level.getWalls()) {
          meshData.push(wall.mesh);
        }

        //search in staircase
        for (let staircase of level.getStaircases()) {
          meshData.push(staircase.mesh);
        }
        //search in doors
        for (let doors of level.getDoors()) {
          meshData.push(doors.mesh);
        }

        //search in windows
        for (let win of level.getWindows()) {
          meshData.push(win.mesh);
        }
        //search in roofs
        for (let roof of level.getRoofs()) {
          meshData.push(roof.mesh);
        }
        //search in floors
        for (let floor of level.getFloors()) {
          meshData.push(floor.mesh);
        }
        //search in furniture
        for (let furniture of level.getFurnitures()) {
          meshData.push(furniture.mesh);
        }
      }
      return meshData;
    },
    getAllComponents: function () {
      let components = [];
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];

        components.push(...level.getMasses());
        components.push(...level.getWalls());
        components.push(...level.getStaircases());
        components.push(...level.getDoors());
        components.push(...level.getWindows());
        components.push(...level.getRoofs());
        components.push(...level.getFloors());
        components.push(...level.getFurnitures());
      }
      return components;
    },
    getActiveStoreyMeshes: function (storey) {
      let meshes = [];
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];

        for (let mass of level.getMasses()) {
          if (parseInt(mass.mesh.storey) === parseInt(storey))
            meshes.push(mass.mesh);
        }
      }

      let layers = this.getStoreyData()
        .getStoreyByValue(storey)
        .layerData.getAllLayers();
      for (let layer_id in layers) {
        if (layers[layer_id].floorplans.length > 0) {
          meshes.push(layers[layer_id].floorplans[0].mesh);
        }
        for (let i = 0; i < layers[layer_id].sketches.length; i++) {
          if (layers[layer_id].layerType !== "cad") {
            meshes.push(layers[layer_id].sketches[i].mesh);
          }
        }
      }

      return meshes;
    },
    hideNonActiveStoreyMeshes: function (storey) {
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];

        for (let mass of level.getMasses()) {
          if (
            mass.mesh.isAnInstance ||
            mass.mesh.type.toLowerCase().includes("void")
          )
            continue;

          if (parseInt(mass.mesh.storey) !== parseInt(storey))
            mass.mesh.visibility = 0.5;
          else mass.mesh.visibility = 1;
        }
      }
      let storeys = this.getStoreyData().getAllStoreys();

      for (let storeyId in storeys) {
        let layers = storeys[storeyId].layerData.getAllLayers();
        for (let layer_id in layers) {
          if (layers[layer_id].storey !== parseInt(storey)) {
            for (let i = 0; i < layers[layer_id].sketches.length; i++) {
              if (!layers[layer_id].hidden) {
                layers[layer_id].sketches[i].mesh.visibility = 0.5;
              }
            }
          } else {
            for (let i = 0; i < layers[layer_id].sketches.length; i++) {
              if (!layers[layer_id].hidden) {
                layers[layer_id].sketches[i].mesh.visibility = 1;
              }
            }
          }

          if (layers[layer_id].floorplans.length > 0)
            layers[layer_id].floorplans[0].deactivate();
        }
      }

      let _storeyOfInterest = this.getStoreyData().getStoreyByValue(storey);
      let imageLayer = _storeyOfInterest.layerData.getLayerByName(
        "image",
        storey
      );

      if (imageLayer) imageLayer.floorplans[0].activate();
    },
    showNonActiveStoreyMeshes: function () {
      for (let level_id in levelCollection) {
        let level = levelCollection[level_id];

        for (let mass of level.getMasses()) {
          if (
            mass.mesh.isAnInstance ||
            mass.mesh.type.toLowerCase().includes("void")
          )
            continue;
          mass.mesh.visibility = 1;
        }
      }

      let storeys = this.getStoreyData().getAllStoreys();

      for (let storeyId in storeys) {
        let layers = storeys[storeyId].layerData.getAllLayers();
        for (let layer_id in layers) {
          for (let i = 0; i < layers[layer_id].sketches.length; i++) {
            layers[layer_id].sketches[i].mesh.visibility = 0;
          }
          if (layers[layer_id].floorplans.length > 0)
            layers[layer_id].floorplans[0].deactivate();
        }
      }
    },
  };
}
/*
{Storey Datastructure}

 */

/**
 * { Level datastructure }
 *
 * @class      Level (name)
 * @param      {<type>}  low     The low
 * @param      {<type>}  high    The high
 * @param      {number}  height  The height
 * @return     {<type>}  { returns all utils functions }
 */
var Level = function (low, high, height = 12) {
  /**
   * { flyweight pattern to store the level collection }
   */
  this.flyweight = LevelFlyweightFactory.get(low, high, height);
  /**
   * Gets the walls.
   *
   * @return     {<type>}  The walls.
   */
  this.getWalls = function () {
    return this.flyweight.walls;
  };

  this.replaceWall = function (replacee, replacer) {
    _.remove(this.flyweight.walls, (wall) => {
      return wall.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.walls.push(replacer);
  };
  /**
   * Gets the doors.
   *
   * @return     {<type>}  The doors.
   */
  this.getDoors = function () {
    return this.flyweight.doors;
  };

  this.replaceDoor = function (replacee, replacer) {
    _.remove(this.flyweight.doors, (door) => {
      return door.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.doors.push(replacer);
  };

  /**
   * Gets the windows.
   *
   * @return     {<type>}  The windows.
   */
  this.getWindows = function () {
    return this.flyweight.windows;
  };

  this.replaceWindow = function (replacee, replacer) {
    _.remove(this.flyweight.windows, (window) => {
      return window.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.windows.push(replacer);
  };
  /**
   * Gets the staircases.
   *
   * @return     {<type>}  The staircases.
   */
  this.getStaircases = function () {
    return this.flyweight.staircases;
  };

  this.replaceStaircase = function (replacee, replacer) {
    _.remove(this.flyweight.staircases, (staircase) => {
      return staircase.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.staircases.push(replacer);
  };
  /**
   * Gets the floors.
   *
   * @return     {<type>}  The floors.
   */
  this.getFloors = function () {
    return this.flyweight.floors;
  };

  this.replaceFloor = function (replacee, replacer) {
    _.remove(this.flyweight.floors, (floor) => {
      return floor.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.floors.push(replacer);
  };
  /**
   * Gets the roofs.
   *
   * @return     {<type>}  The roofs.
   */
  this.getRoofs = function () {
    return this.flyweight.roofs;
  };

  this.replaceRoof = function (replacee, replacer) {
    _.remove(this.flyweight.roofs, (roof) => {
      return roof.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.roofs.push(replacer);
  };
  /**
   * Gets the height.
   *
   * @return     {<type>}  The height.
   */
  this.getHeight = function () {
    return this.flyweight.height;
  };
  /**
   * Gets the unique identifier.
   *
   * @return     {<type>}  The unique identifier.
   */
  this.getUniqueId = function () {
    return this.flyweight.uniqueId;
  };
  /**
   * Gets the masses.
   *
   * @return     {<type>}  The masses.
   */
  this.getMasses = function () {
    return this.flyweight.masses;
  };

  this.replaceMass = function (replacee, replacer) {
    _.remove(this.flyweight.masses, (mass) => {
      return mass.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.masses.push(replacer);
  };
  /**
   * Gets the furnitures.
   *
   * @return     {<type>}  Furnitures.
   */
  this.getFurnitures = function () {
    return this.flyweight.furnitures;
  };

  this.replaceFurniture = function (replacee, replacer) {
    _.remove(this.flyweight.furnitures, (furniture) => {
      return furniture.mesh.uniqueId === replacee.mesh.uniqueId;
    });

    this.flyweight.furnitures.push(replacer);
  };

  /**
   * Remove Object  tolevel.
   *
   * @param      {<type>}  object  The object
   */
  this.removeObjectToLevel = function (object) {
    this.removeObjectFromStorey(object);
    if (object.type) {
      if (object.type.toLowerCase() === "mass")
        object.removeMassFromLevel(this);
      if (object.type.toLowerCase() === "void")
        object.removeMassFromLevel(this);
      if (object.type.toLowerCase() === "wall")
        object.removeWallFromLevel(this);
      if (object.type.toLowerCase() === "floor")
        object.removeFloorFromLevel(this);
      if (object.type.toLowerCase() === "roof")
        object.removeRoofFromLevel(this);
      if (object.type.toLowerCase() === "door")
        object.removeDoorFromLevel(this);
      if (object.type.toLowerCase() === "staircase")
        object.removeStaircaseFromLevel(this);
      if (object.type.toLowerCase() === "curtainwall")
        object.removeCurtainWallFromLevel(this);
      if (object.type.toLowerCase() === "window")
        object.removeWindowFromLevel(this);
      if (object.type.toLowerCase() === "furniture")
        object.removeFurnitureFromLevel(this);
      if (object.type.toLowerCase() === "layer")
        object.removeLayerFromLevel(this);
      if (object.type.toLowerCase() === "sketch")
        object.removeSketchFromLevel(this);
    }
  };

  /**
   * Adds a Mesh tolevel.
   *
   * @param      {<type>}  object  The object
   */
  this.addMeshToLevel = function (mesh, offset = true) {
    if (mesh.type) {
      if (mesh.type.toLowerCase() === "mass")
        this.addMassToLevel(new Mass(mesh), offset);
      if (mesh.type.toLowerCase() === "void")
        this.addMassToLevel(new Mass(mesh), offset);
      if (mesh.type.toLowerCase() === "wall")
        this.addWallToLevel(new Wall(mesh), offset);
      if (mesh.type.toLowerCase() === "floor")
        this.addFloorToLevel(new Floor(mesh), offset);
      if (mesh.type.toLowerCase() === "roof")
        this.addRoofToLevel(new Roof(mesh), offset);
      if (mesh.type.toLowerCase() === "door")
        this.addDoorToLevel(new Door(mesh), offset);
      if (mesh.type.toLowerCase() === "staircase")
        this.addStaircaseToLevel(new Staircase(mesh), offset);
      if (mesh.type.toLowerCase() === "curtainwall")
        this.addCurtainWallToLevel(new CurtainWall(mesh), offset);
      if (mesh.type.toLowerCase() === "window")
        this.addWindowToLevel(new Window(mesh), offset);
      if (mesh.type.toLowerCase() === "furniture")
        this.addFurnitureToLevel(new Furniture(mesh), offset);
      if (mesh.type.toLowerCase() === "sketch")
        this.addSketchToLevel(new Sketch(mesh), offset);
    }
  };
  /**
   * Adds a object tolevel.
   *
   * @param      {<type>}  object  The object
   */
  this.addObjectToLevel = function (object, offset = true) {
    if (object.type) {
      if (object.type.toLowerCase() === "mass")
        this.addMassToLevel(object, offset);
      else if (object.type.toLowerCase() === "wall")
        this.addWallToLevel(object, offset);
      else if (object.type.toLowerCase() === "floor")
        this.addFloorToLevel(object, offset);
      else if (object.type.toLowerCase() === "roof")
        this.addRoofToLevel(object, offset);
      else if (object.type.toLowerCase() === "door")
        this.addDoorToLevel(object, offset);
      else if (object.type.toLowerCase() === "staircase")
        this.addStaircaseToLevel(object, offset);
      else if (object.type.toLowerCase() === "curtainwall")
        this.addCurtainWallToLevel(object, offset);
      else if (object.type.toLowerCase() === "window")
        this.addWindowToLevel(object, offset);
      else if (object.type.toLowerCase() === "furniture")
        this.addFurnitureToLevel(object, offset);
    }
  };
  this.addObjectToStorey = function (object) {
    if (isMeshThrowAway(object.mesh)) return;

    let storeyCollection = StructureCollection.getInstance()
      .getStructures()
      [object.structure_id].getStoreyData();
    if (!object.storey) object.storey = object.mesh.storey;
    let storeyVal = object.storey;
    if (!storeyVal) return;
    //For objects like door windows, after refresh throwAway identifier in type is overwritten

    let storey = storeyCollection.addStorey(object.structure_id, storeyVal);

    storey.addElement(object);
  };
  this.removeObjectFromStorey = function (object, removeFromDLL = true) {
    let structureCollection = StructureCollection.getInstance().getStructures();
    let storeyCollection =
      structureCollection[object.structure_id].getStoreyData();
    if (!object.storey) object.storey = object.mesh.storey;
    let storeyVal = object.storey;

    if (!storeyVal) return;
    // some components like throwAways are there within the structure but not the storey

    let storey = storeyCollection.getStoreyByValue(storeyVal);

    storey.removeElement(object);

    if (removeFromDLL) {
      if (object.linkedListId) {
        let dll = structureCollection[object.structure_id]
          .getLinkedListCluster()
          .getLinkedList(object.linkedListId);
        if (dll && dll.length() !== 1) {
          dll.remove(object.mesh.uniqueId);
        }
      }
    }
  };
  /**
   * Adds a mass tolevel.
   *
   *
   *
   * @param      {<type>}  object  The object
   */
  this.addMassToLevel = function (object, offset = true) {
    // console.log(object);
    // console.log(object.level_id);
    // if (object.level_id){
    //     let structures = StructureCollection.getInstance();
    //     let level = structures.getStructureById(object.structure_id).getLevelByUniqueId(object.level_id);
    //     console.log(level);
    //     object.removeMassFromLevel(level);
    // }
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeMassFromLevel(levelObj);

    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    object.storey = object.mesh.storey;
    this.flyweight.masses.push(object);

    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
    //console.log(offset);
    // if (offset)
    //     object.mesh.position.y = object.mesh.getBoundingInfo().boundingBox.center.y + parseInt(this.flyweight.low) * this.getHeight(); //change this
  };
  /**
   * Adds a wall tolevel.
   *
   * @param      {<type>}  object  The object
   */
  this.addWallToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    // let levelObj = structures.getStructureById(object.structure_id).getLevelByUniqueId(object.level_id);
    if (levelObj) object.removeWallFromLevel(levelObj);

    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;

    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.walls.push(object);
    //console.log(offset);
    // object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (offset)
      object.mesh.position.y =
        object.mesh.getBoundingInfo().boundingBox.center.y +
        parseInt(this.flyweight.low) * this.getHeight(); //change this

    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   * Adds a roof tolevel.
   *
   * @param      {<type>}  object  The object
   */
  this.addRoofToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeRoofFromLevel(levelObj);

    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.roofs.push(object);
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    // object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   * Adds a floor tolevel.
   *
   * @param      {<type>}  object  The object
   */

  this.addFloorToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeFloorFromLevel(levelObj);

    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.floors.push(object);
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    // object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   * Adds a furniture tolevel.
   *
   * @param      {<type>}  object  The object
   */

  this.addFurnitureToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeFurnitureFromLevel(levelObj);

    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.furnitures.push(object);
    // object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   * Adds a Sketch tolevel.
   *
   * @param      {<type>}  object  The object
   */

  this.addSketchToLevel = function (object, offset = false) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeSketchFromLevel(levelObj);

    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.sketches.push(object);
    // object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };

  /**
   * Adds a door tolevel.
   *
   * @param      {<type>}  object  The object
   */

  this.addDoorToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeDoorFromLevel(levelObj);
    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.doors.push(object);
    //console.log(offset);
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   * Adds a window tolevel.
   *
   * @param      {<type>}  object  The object
   */

  this.addWindowToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeWindowFromLevel(levelObj);
    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.windows.push(object);
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };

  // this.addStaircaseToLevel = function(object, offset = true){
  //     meshObjectMapping.addMapping(object.mesh, object)
  //     let structures = StructureCollection.getInstance();
  //     let levelObj = object.mesh.getSnaptrudeLevel();
  //     if (levelObj)
  //         object.removeStaircaseFromLevel(levelObj);
  //     object.level_low = this.flyweight.low;
  //     object.level_high = this.flyweight.high;
  //     object.height = this.flyweight.height;
  //     object.level_id = this.flyweight.uniqueId;
  //     object.structure_id = object.mesh.structure_id || 0;
  //     this.flyweight.staircases.push(object);
  //     if (offset)
  //         object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
  //     if (!object.mesh.originalScaling)
  //         this.addObjectToStorey(object);
  // };

  /**
   * Adds a staircase tolevel.
   *
   * @param      {<type>}  object  The object
   */
  this.addStaircaseToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeStaircaseFromLevel(levelObj);
    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.staircases.push(object);
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   * Adds a curtain wall tolevel.
   *
   * @param      {<type>}  object  The object
   */

  this.addCurtainWallToLevel = function (object, offset = true) {
    meshObjectMapping.addMapping(object.mesh, object);
    let structures = StructureCollection.getInstance();
    let levelObj = object.mesh.getSnaptrudeLevel();
    if (levelObj) object.removeCurtainWallFromLevel(levelObj);
    object.level_low = this.flyweight.low;
    object.level_high = this.flyweight.high;
    object.height = this.flyweight.height;
    object.level_id = this.flyweight.uniqueId;
    object.structure_id = object.mesh.structure_id || 0;
    this.flyweight.curtainwalls.push(object);
    if (offset)
      object.mesh.position.y += parseInt(this.flyweight.low) * this.getHeight(); //change this
    if (!object.mesh.originalScaling) this.addObjectToStorey(object);
  };
  /**
   *  Delete function
   */
  this.deleteAll = function () {
    for (let prop in this.flyweight) {
      delete this.flyweight[prop];
    }
  };
  //.. add more
};

var storeyObj = function (number, level = []) {
  this.number = number;
  this.level = level;
};

storeyObj.prototype.valueOf = function () {};

storeyObj.prototype.equals = function () {};

storeyObj.prototype.toString = function () {};
export {
  LevelFltWeight,
  LevelFlyweightFactory,
  LevelCollection,
  Level,
  storeyObj,
};
