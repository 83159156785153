import { createSlice } from "@reduxjs/toolkit"
import { store } from "../../../modules/utilityFunctions/Store";

/* 
    {
        title: Name to be displayed in filter selection UI.
        value: Must match the corresponding flyweight property.
        isChecked: default state of the filter.
    }
*/
const filtered = [
    {
        title: "Walls",
        value: "walls",
        isChecked: false,
    },
    {
        title: "Slabs",
        value: "roofs",
        isChecked: false,
    },
    {
        title: "Doors",
        value: "doors",
        isChecked: false,
    },
    {
        title: "Windows",
        value: "windows",
        isChecked: false,
    },
    {
        title: "Furniture",
        value: "furnitures",
        isChecked: false,
    },
    {
        title: "Staircases",
        value: "staircases",
        isChecked: false,
    },
    {
        title: "Floors",
        value: "floors",
        isChecked: false,
    },
    // {
    //     title: "LinkedModels",
    //     value: "LinkedModels",
    //     isChecked: false
    // }
]

const initialState = {
    selected: [],
    filtered: filtered
}

export const meshSelectionSlice = createSlice({
    name: "meshSelection",
    initialState,
    reducers: {
        resetMeshSelection: state => initialState,
        selectMesh: (state, action) => {
            const {uniqueId} = action.payload;
            if(!state.selected.includes(uniqueId)){
                const newState = state;
                newState.selected.push(uniqueId);
                state = newState;
            }
        },
        deselectMesh: (state, action) => {
            const {uniqueId} = action.payload;
            const newState = state;
            newState.filtered = newState.filtered.map(filter => {
                return {
                    ...filter,
                    isChecked: false
                }
            })
            newState.selected = newState.selected.filter(id => id !== uniqueId);
            state = newState;
        },
        updateFilter: (state, action) => {
            const {type, toSelect} = action.payload;
            const newState = state;
            newState.filtered = newState.filtered.map(filter => {
                if(filter.value === type){
                    filter.isChecked = toSelect;
                }
                return filter;
            })
            return newState;
        },
        addFilter: (state, action) => {
            const {type} = action.payload;
            const newState = state;
            newState.filtered.push({
                title: type,
                value: type,
                isChecked: false
            });
            return newState;
        }
    }
});

export const {resetMeshSelection, selectMesh, deselectMesh, updateFilter, addFilter} = meshSelectionSlice.actions;
export default meshSelectionSlice.reducer;