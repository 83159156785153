import React, { useState, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import Input from "../Input/index";
import Button from "../Button/index";
import ScrollableDropdown from "../../components/ScrollableDropdown/index";
import useOnClickOutside from "../../common/utils/onOutsideClickHandler";

const Main = styled.div`
  position: relative;
  box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
  .container:before {
    content: "";
    position: absolute;
    top: 0;
    right: -2.1875rem;
    height: 2.1875rem;
    width: 2.1875rem;
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 20px 0 0 white;
    transform: rotate(90deg);
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 16.9375rem;
  border-radius: 0.625em;
  background-color: ${colors.fullWhite};
  height: 18.875rem;
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: center;
  padding: 1rem 1.375rem;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0.125rem 0;
  justify-content: space-between;
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${colors.darkGrey};
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.230052px;
`;

const TopContainerValue = styled.div`
  P {
    font-family: ${(props) => props.theme.font};
    color: ${colors.primeBlack};
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.230052px;
    opacity: 0.9;
    text-align: right;
  }
  width: 7.625rem;
`;

function EditMenu(props) {
  const { data, buttonsData, closePopUp } = props;
  // eslint-disable-next-line no-unused-vars
  const [inputValuesCopy, setInputValuesCopy] = useState(data);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (closePopUp) closePopUp();
  });

  const updateInputValue = (index, item, e) => {
    e.persist();
    setInputValuesCopy((prevState) => {
      prevState[index] = {
        title: item.title,
        value: parseInt((e.target && e.target.value) || 0, 10),
      };
      return [...prevState];
    });
  };

  const getItemByType = (item, index) => {
    switch (item.type) {
      case "input":
        return (
          <Input
            type={"text"}
            required
            value={item.value}
            labelStyle={{ padding: 0 }}
            onPress={(e) => updateInputValue(index, item, e)}
            customInput={{
              width: "6.375rem",
              height: "1.25rem",
              fontWeight: "bold",
              textAlign: "right",
            }}
          />
        );
      case "dropdown":
        return (
          <ScrollableDropdown
            cutomTextStyle={{
              fontWeight: "bold",
              fontSize: "12px",
              paddingLeft: 0,
            }}
            options={item.options}
            width="7.625rem"
            height="auto"
          />
        );
      case "fixedValue":
        return (
          <TopContainerValue>
            <p
              style={
                item.align && item.align === "left"
                  ? { textAlign: "left", fontSize: "12px" }
                  : {}
              }
            >
              {item.value}
            </p>
          </TopContainerValue>
        );
      default:
        return null;
    }
  };

  return (
    <Main ref={ref}>
      <Container className="container">
        <Inner>
          <div style={{ width: "100%" }}>
            {data &&
              data.map((item, index) => {
                return (
                  <TopContainer
                    style={item.padding ? { padding: item.padding } : {}}
                    key={index}
                  >
                    <TopContainerLabel
                      style={
                        item.type !== "fixedValue"
                          ? { alignSelf: "center" }
                          : {}
                      }
                    >
                      {item.title}
                    </TopContainerLabel>
                    {getItemByType(item, index)}
                  </TopContainer>
                );
              })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent:
                buttonsData && buttonsData.length === 1
                  ? "center"
                  : "space-between",
              width: "100%",
              paddingTop: "1rem",
            }}
          >
            {buttonsData &&
              buttonsData.map((item, index) => {
                return (
                  <Button
                    key={index}
                    title={item.title}
                    customButtonTextStyle={{
                      fontSize: "11px",
                      lineHeight: "14px",
                      letterSpacing: "-0.333333px;",
                    }}
                    customButtonStyle={{
                      backgroundColor: colors.dullgrey,
                      height: "2.5rem",
                      width: "4.375rem",
                      minHeight: "auto",
                      padding: "0.375rem 0.25em",
                    }}
                    onPress={() => {}}
                  />
                );
              })}
          </div>
        </Inner>
      </Container>
    </Main>
  );
}

export default EditMenu;
