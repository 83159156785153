import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import ChooseLevels from "./ChooseLevels";
import ImportRevit from "./ImportRevit";
import MapLevels from "./MapLevels";
import ChooseStackedWallFamilies from "./ChooseStackedWallFamilies";
import { speckleRevitImport } from "../../../snaptrude/modules/speckleRevitImport";
import { showToast } from "../../../snaptrude/modules/extrafunc";
import { autoSaveConfig } from "../../../snaptrude/modules/socket/autoSaveConfig";

const Wrapper = styled.div`
    // border: 2px solid;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
`
const ModalWrapper = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    .heading{
        font-weight: 700;
        font-size: 24px;
        color: ${colors.primeBlack};
    }
    .subheading{
        font-weight: 400;
        font-size: 14px;
        color: ${colors.greyishBlack};
    }
    footer{
        display: flex;
        flex-direction: row;
        z-index: 5;
        padding: 1em;
        border-top: 1px solid rgba(96, 129, 159, 0.2);
        justify-content: space-around;
    }
    .button-wrapper{
        z-index: 5;
    }
    .button-wrapper button{
        min-width: 12em;
        width: fit-content;
    }
    .page-indicator{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .indicator{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 0px 1px;
        background: #F2F2F2;
    }
    .active{
        background: #818181;
    }
`
const pages = ["import-revit", "map-levels", "choose-levels", "choose-stacked-families"];
function SpeckleRevitImport({isOpen, onClose, speckleStreamId, team}) {
    const [currentPage, setCurrentPage] = useState(pages[0]);
    const [isInitializingRevitImport, setIsInitializingRevitImport] = useState(speckleStreamId != null);
    const ref = useRef(null);
    const updatePage = (newPage) => {
        setCurrentPage(newPage);
    }
    useEffect(() => {
        if(speckleStreamId != null){
            showToast("Importing Project...", 0,
            autoSaveConfig.CONSTANTS.toastSuccess);
            speckleRevitImport.init(speckleStreamId, null, team)
                .then(() => {
                speckleRevitImport.getLevelStoreyMapping();
                    setIsInitializingRevitImport(false);
                    updatePage(pages[1]);
                })
                .catch((error) => {
                    // handleClose();
                });
        }
    }, [speckleStreamId, team]);
    if(!isOpen) return <></>;
    if(isInitializingRevitImport) return null;
    return ( 
        <Wrapper>
            <ModalWrapper ref={ref}>
                { currentPage === pages[0] && <ImportRevit updatePage={updatePage} handleClose={onClose} nextPage={pages[1]} speckleStreamId={speckleStreamId} /> } 
                { currentPage === pages[1] && <MapLevels updatePage={updatePage} handleClose={onClose} optionalPage={pages[2]} nextPage={pages[2]} /> }
                { currentPage === pages[2] && <ChooseLevels updatePage={updatePage} handleClose={onClose} nextPage={pages[1]} prevPage={pages[1]} /> } 
                {/*{ currentPage === pages[3] && <ChooseStackedWallFamilies updatePage={updatePage} handleClose={onClose} prevPage={pages[1]} /> }*/}
            </ModalWrapper>
        </Wrapper>
    );
}

export default SpeckleRevitImport;