import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { checkIfAreaCounts, offsetRoomPols } from "./sketch/wall_generation.js";
import { appElement } from "./bimDataFuncs.js";
import { getFaceVerticesFromFace } from "./brepOperations.js";
import { getNormalVector } from "./mathFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { removeDuplicates } from "./arrayFuncs.js";
import { getUnitNormalVectorV3CyclicCheck } from "./mathFuncs.js";
import {signedArea} from "./polyFuncs";
import _ from "lodash";

var BuiltUpArea = function () {
  var area = 0;
  for (let i = 0; i < store.newScene.meshes.length; i++) {
    var mesh = store.newScene.meshes[i];
    if (
      mesh.name.indexOf("boxScale") == -1 &&
      mesh.name.indexOf("axis") == -1 &&
      mesh.name.indexOf("ground") == -1
    ) {
      if (checkIfAreaCounts(mesh.room_type)) {
        area += computeArea(store.newScene.meshes[i]);
      }
      // if ((area > store.allowed_built_area) && (allowed_built_area != -1)){
      //   store.newScene.meshes[i].material = viol_mat;
      //   (newScene.meshes[i].material, store.newScene.meshes[i].name);
      //   ("Violation");
      // }
    }
  }
  area = Math.round(area * 100) / 100;
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  if ($scope.$$phase) {
    if (area > store.allowed_built_area && store.allowed_built_area != -1) {
      $scope.customColor = "red";
      $("#bua").css("color", "red");
      // ("Violation2");
    } else {
      $scope.customColor = "black";
      $("#bua").css("color", "black");
    }
    $scope.projProps[6].value = area;
  } else {
    $scope.$apply(function () {
      // $scope.builtArea = area;
      if (area > store.allowed_built_area && store.allowed_built_area != -1) {
        $scope.customColor = "red";
        $("#bua").css("color", "red");
        // ("Violation2");
      } else {
        $scope.customColor = "black";
        $("#bua").css("color", "black");
      }
      $scope.projProps[6].value = area;
    });
  }
};

var computerDoorCAArea = function (mesh) {
  let bbinfo = mesh.getBoundingInfo();
  let area =
    4 *
    bbinfo.boundingBox.extendSizeWorld.x *
    bbinfo.boundingBox.extendSizeWorld.z;
  return area;
};

var computeArea = function (mesh) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) {
    mesh.disableFacetData();
    mesh._internalAbstractMeshDataInfo._facetData.facetParameters = {};
    /*
        Found this solution after a lot of clawing around in the source code.

        Just calling updateFacetData results in an array indexing error since this function doesn't
        expect new normals.
        So have to call disableFacetData first to flush all the facetData.
        Calling updateFacetData later counts the number of facets as number of indices / 3, which makes sense. So now,
        it can expect new normals. But disableFacetData sets
        mesh._internalAbstractMeshDataInfo._facetData.facetParameters = null
        This is probably a bug. So initialising it manually.
        Working as expected for now.
        */
    mesh.updateFacetData();
  }
  let area = 0;
  let n1 = new BABYLON.Vector3(0, 1, 0);
  mesh._updateBoundingInfo();
  let m = mesh.getWorldMatrix();
  for (let i = 0; i < mesh.facetNb; i++) {
    let n = mesh.getFacetNormal(i);
    if (n.almostEquals(n1, 1e-5)) {
      let p1 = new BABYLON.Vector3(
        verData[indices[i * 3] * 3],
        verData[indices[i * 3] * 3 + 1],
        verData[indices[i * 3] * 3 + 2]
      );
      let p2 = new BABYLON.Vector3(
        verData[indices[i * 3 + 1] * 3],
        verData[indices[i * 3 + 1] * 3 + 1],
        verData[indices[i * 3 + 1] * 3 + 2]
      );
      let p3 = new BABYLON.Vector3(
        verData[indices[i * 3 + 2] * 3],
        verData[indices[i * 3 + 2] * 3 + 1],
        verData[indices[i * 3 + 2] * 3 + 2]
      );
      p1 = BABYLON.Vector3.TransformCoordinates(p1, m);
      p2 = BABYLON.Vector3.TransformCoordinates(p2, m);
      p3 = BABYLON.Vector3.TransformCoordinates(p3, m);
      area +=
        p1.x * (p2.z - p3.z) + p2.x * (p3.z - p1.z) + p3.x * (p1.z - p2.z);
    }
  }
  return area / 2;
};

var computeAreaWall = function (mesh, thickness) {
  let brep = mesh.getSnaptrudeDS().brep;
  if (brep) {
    let faces = mesh.getSnaptrudeDS().brep.getFaces();
    let areaArray = [];
    let area = 0;
    faces.forEach((f) => {
      let v = getFaceVerticesFromFace(f, mesh, BABYLON.Space.WORLD);
      let n = getNormalVector(v);
      let flatFaceFlag = BABYLON.Vector3.FromArray(n)
        .normalize()
        .almostEquals(new BABYLON.Vector3(0, 1, 0));
      flatFaceFlag = BABYLON.Vector3.FromArray(n)
        .normalize()
        .almostEquals(new BABYLON.Vector3(0, -1, 0));
      if (flatFaceFlag) return;
      let verts = [];
      for (let i = 0; i < v.length; i++) {
        verts.push([v[i].x, v[i].y, v[i].z]);
      }
      area += areaOfPolygon3D(verts);
    });
    return area;
  } else {
    return computeAreaForMass(mesh, 0);
  }
};

var computeVolumeWall = function (mesh, thickness) {
  let brep = mesh.getSnaptrudeDS().brep;
  if (brep) {
    let faces = mesh.getSnaptrudeDS().brep.getFaces();
    let areaArray = [];
    faces.forEach((f) => {
      let v = getFaceVerticesFromFace(f, mesh, BABYLON.Space.WORLD);
      let area = 0;
      let verts = [];
      for (let i = 0; i < v.length; i++) {
        verts.push([v[i].x, v[i].y, v[i].z]);
      }
      area = areaOfPolygon3D(verts);
      areaArray.push(area);
    });
    return (
      areaArray[areaArray.sortByIndices()[areaArray.length - 1]] *
      DisplayOperation.getOriginalDimension(thickness, "millimeter")
    );
  } else {
    return computeVolumeWallOld(mesh, thickness);
  }
};

var computeVolumeWallOld = function (mesh, thickness) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(1, 0, 0);
  if (
    bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z
  ) {
    n1.x = 0;
    n1.z = 1;
  }
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  mesh.freezeWorldMatrix();
  let m = mesh.getWorldMatrix();
  for (let i = 0; i < normals.length; i++) {
    let n = normals[i];
    if (n.almostEquals(n1)) {
      let p11 = new BABYLON.Vector3(
        verData[indices[i * 3] * 3],
        verData[indices[i * 3] * 3 + 1],
        verData[indices[i * 3] * 3 + 2]
      );
      let p22 = new BABYLON.Vector3(
        verData[indices[i * 3 + 1] * 3],
        verData[indices[i * 3 + 1] * 3 + 1],
        verData[indices[i * 3 + 1] * 3 + 2]
      );
      let p33 = new BABYLON.Vector3(
        verData[indices[i * 3 + 2] * 3],
        verData[indices[i * 3 + 2] * 3 + 1],
        verData[indices[i * 3 + 2] * 3 + 2]
      );
      let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
      let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
      let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
      let ab = p2.subtract(p1);
      let ac = p3.subtract(p1);
      area += BABYLON.Vector3.Cross(ab, ac).length() / 2;
      // area += p1.x*(p2.y-p3.y)+p2.x*(p3.y-p1.y)+p3.x*(p1.y-p2.y);
    }
  }
  mesh.unfreezeWorldMatrix();
  return area * DisplayOperation.getOriginalDimension(thickness, "millimeter");
};

var computeAreaForMass = function (mesh, thickness) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(1, 0, 0);
  if (
    bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z
  ) {
    n1.x = 0;
    n1.z = 1;
  }
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  mesh.freezeWorldMatrix();
  let m = mesh.getWorldMatrix();
  for (let i = 0; i < normals.length; i++) {
    let p11 = new BABYLON.Vector3(
      verData[indices[i * 3] * 3],
      verData[indices[i * 3] * 3 + 1],
      verData[indices[i * 3] * 3 + 2]
    );
    let p22 = new BABYLON.Vector3(
      verData[indices[i * 3 + 1] * 3],
      verData[indices[i * 3 + 1] * 3 + 1],
      verData[indices[i * 3 + 1] * 3 + 2]
    );
    let p33 = new BABYLON.Vector3(
      verData[indices[i * 3 + 2] * 3],
      verData[indices[i * 3 + 2] * 3 + 1],
      verData[indices[i * 3 + 2] * 3 + 2]
    );
    let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
    let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
    let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
    let ab = p2.subtract(p1);
    let ac = p3.subtract(p1);
    area += BABYLON.Vector3.Cross(ab, ac).length() / 2;
  }
  mesh.unfreezeWorldMatrix();
  return area;
  // return area * DisplayOperation.getOriginalDimension(thickness);
};

var computeVolumeStairsLayer = function (mesh, thickness) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(1, 0, 0);
  if (
    bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z
  ) {
    n1.x = 0;
    n1.z = 1;
  }
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  mesh.freezeWorldMatrix();
  let m = mesh.getWorldMatrix();
  for (let i = 0; i < normals.length; i++) {
    let p11 = new BABYLON.Vector3(
      verData[indices[i * 3] * 3],
      verData[indices[i * 3] * 3 + 1],
      verData[indices[i * 3] * 3 + 2]
    );
    let p22 = new BABYLON.Vector3(
      verData[indices[i * 3 + 1] * 3],
      verData[indices[i * 3 + 1] * 3 + 1],
      verData[indices[i * 3 + 1] * 3 + 2]
    );
    let p33 = new BABYLON.Vector3(
      verData[indices[i * 3 + 2] * 3],
      verData[indices[i * 3 + 2] * 3 + 1],
      verData[indices[i * 3 + 2] * 3 + 2]
    );
    let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
    let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
    let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
    area += computeAreaTriangle3D(p1, p2, p3);
    // area += p1.x*(p2.z-p3.z)+p2.x*(p3.z-p1.z)+p3.x*(p1.z-p2.z);
  }
  mesh.unfreezeWorldMatrix();
  // return Math.abs(area/2);
  return area * DisplayOperation.getOriginalDimension(thickness, "millimeter");
};

var computeVolumeStairsLayerType = function (
  mesh,
  thickness,
  stairProp,
  returnType = "Volume"
) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let obj = mesh.getSnaptrudeDS();

  let faces = mesh.getSnaptrudeDS().brep.getFaces();
  for (let i = 0; i < faces.length; i++) {
    if (obj.getFaceType(i) === stairProp.toLowerCase()) {
      let faceCoords = getFaceVerticesFromFace(
        faces[i],
        mesh,
        BABYLON.Space.WORLD
      );
      let faceCoordsArray = faceCoords.map((f) => {
        return [f.x, f.y, f.z];
      });
      area += areaOfPolygon3D(faceCoordsArray);
    }
  }

  // for (let i=0; i<mesh.subMeshes.length; i++) {
  //     if (obj.getFaceType(i) === stairProp.toLowerCase()) {
  //         area += computeAreaofSubMesh(mesh, i);
  //     }
  // }
  if (returnType == "Volume") {
    return (
      area * DisplayOperation.getOriginalDimension(thickness, "millimeter")
    );
  } else {
    return area;
  }
};

var computeVolumeMass = function (mesh, thickness) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let volume = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(1, 0, 0);
  if (
    bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z
  ) {
    n1.x = 0;
    n1.z = 1;
  }
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  mesh.freezeWorldMatrix();
  let m = mesh.getWorldMatrix();
  for (let i = 0; i < normals.length; i++) {
    let p11 = new BABYLON.Vector3(
      verData[indices[i * 3] * 3],
      verData[indices[i * 3] * 3 + 1],
      verData[indices[i * 3] * 3 + 2]
    );
    let p22 = new BABYLON.Vector3(
      verData[indices[i * 3 + 1] * 3],
      verData[indices[i * 3 + 1] * 3 + 1],
      verData[indices[i * 3 + 1] * 3 + 2]
    );
    let p33 = new BABYLON.Vector3(
      verData[indices[i * 3 + 2] * 3],
      verData[indices[i * 3 + 2] * 3 + 1],
      verData[indices[i * 3 + 2] * 3 + 2]
    );
    let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
    let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
    let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
    let t1 = BABYLON.Vector3.Cross(p1, p2);
    // let t2 = BABYLON.Vector3.Cross(p1, t1);
    volume += BABYLON.Vector3.Dot(t1, p3);
  }
  mesh.unfreezeWorldMatrix();
  return Math.abs(volume / 6);
  // return area * DisplayOperation.getOriginalDimension(thickness);
};

var computeVolumeFloor = function (mesh, thickness) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(0, 1, 0);
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  mesh.freezeWorldMatrix();
  let m = mesh.getWorldMatrix();
  let faceCount = 0;
  for (let i = 0; i < normals.length; i++) {
    let n = normals[i];
    if (n.almostEquals(n1, 1e-3)) {
      let p11 = new BABYLON.Vector3(
        verData[indices[i * 3] * 3],
        verData[indices[i * 3] * 3 + 1],
        verData[indices[i * 3] * 3 + 2]
      );
      let p22 = new BABYLON.Vector3(
        verData[indices[i * 3 + 1] * 3],
        verData[indices[i * 3 + 1] * 3 + 1],
        verData[indices[i * 3 + 1] * 3 + 2]
      );
      let p33 = new BABYLON.Vector3(
        verData[indices[i * 3 + 2] * 3],
        verData[indices[i * 3 + 2] * 3 + 1],
        verData[indices[i * 3 + 2] * 3 + 2]
      );
      let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
      let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
      let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
      area +=
        p1.x * (p2.z - p3.z) + p2.x * (p3.z - p1.z) + p3.x * (p1.z - p2.z);
      faceCount++;
    }
  }
  mesh.unfreezeWorldMatrix();
  return (
    ((area / 2) *
      DisplayOperation.getOriginalDimension(thickness, "millimeter")) /
    1
  );
};

var computeAreaWallOld = function (mesh) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(1, 0, 0);
  if (
    bbinfo.boundingBox.extendSizeWorld.x > bbinfo.boundingBox.extendSizeWorld.z
  ) {
    n1.x = 0;
    n1.z = 1;
  }
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  mesh.freezeWorldMatrix();
  let m = mesh.getWorldMatrix();
  for (let i = 0; i < normals.length; i++) {
    let n = normals[i];
    if (n.almostEquals(n1)) {
      let p1 = new BABYLON.Vector3(
        verData[indices[i * 3] * 3],
        verData[indices[i * 3] * 3 + 1],
        verData[indices[i * 3] * 3 + 2]
      );
      let p2 = new BABYLON.Vector3(
        verData[indices[i * 3 + 1] * 3],
        verData[indices[i * 3 + 1] * 3 + 1],
        verData[indices[i * 3 + 1] * 3 + 2]
      );
      let p3 = new BABYLON.Vector3(
        verData[indices[i * 3 + 2] * 3],
        verData[indices[i * 3 + 2] * 3 + 1],
        verData[indices[i * 3 + 2] * 3 + 2]
      );
      let p11 = BABYLON.Vector3.TransformCoordinates(p1, m);
      let p22 = BABYLON.Vector3.TransformCoordinates(p2, m);
      let p33 = BABYLON.Vector3.TransformCoordinates(p3, m);
      let ab = p22.subtract(p11);
      let ac = p33.subtract(p11);
      area += BABYLON.Vector3.Cross(ab, ac).length() / 2;
      // area += p1.x*(p2.y-p3.y)+p2.x*(p3.y-p1.y)+p3.x*(p1.y-p2.y);
    }
  }
  mesh.unfreezeWorldMatrix();
  return area;
};

var computeAreaFloor = function (mesh) {
  let meshObj = mesh.getSnaptrudeDS();
  if (meshObj) {
    if (meshObj.brep) {
      let faces = meshObj.brep.getFaces();
      let areas = [];
      faces.forEach((f) => {
        let v = getFaceVerticesFromFace(f, mesh, BABYLON.Space.WORLD);
        let v2 = [];
        v.forEach((v1) => {
          v2.push([v1.x, v1.y, v1.z]);
        });
        areas.push(areaOfPolygon3D(v2));
      });
      areas = areas.sort(function(a, b) { return a - b});
      return areas[areas.length - 1];
    } else {
      return computeAreaFloorOld(mesh);
    }
  }
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(0, 1, 0);
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  let m = mesh.getWorldMatrix();
  let faceCount = 0;
  for (let i = 0; i < normals.length; i++) {
    let n = normals[i];
    if (n.almostEquals(n1)) {
      let p11 = new BABYLON.Vector3(
        verData[indices[i * 3] * 3],
        verData[indices[i * 3] * 3 + 1],
        verData[indices[i * 3] * 3 + 2]
      );
      let p22 = new BABYLON.Vector3(
        verData[indices[i * 3 + 1] * 3],
        verData[indices[i * 3 + 1] * 3 + 1],
        verData[indices[i * 3 + 1] * 3 + 2]
      );
      let p33 = new BABYLON.Vector3(
        verData[indices[i * 3 + 2] * 3],
        verData[indices[i * 3 + 2] * 3 + 1],
        verData[indices[i * 3 + 2] * 3 + 2]
      );
      let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
      let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
      let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
      area +=
        p1.x * (p2.z - p3.z) + p2.x * (p3.z - p1.z) + p3.x * (p1.z - p2.z);
      faceCount++;
    }
  }
  return area / 2;
};

var computeAreaFloorOld = function (mesh) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  if (!mesh.isFacetDataEnabled) mesh.updateFacetData();
  let area = 0;
  let bbinfo = mesh.getBoundingInfo();
  let n1 = new BABYLON.Vector3(0, 1, 0);
  let normals = mesh.getFacetLocalNormals();
  mesh._updateBoundingInfo();
  let m = mesh.getWorldMatrix();
  let faceCount = 0;
  for (let i = 0; i < normals.length; i++) {
    let n = normals[i];
    if (n.almostEquals(n1)) {
      let p11 = new BABYLON.Vector3(
        verData[indices[i * 3] * 3],
        verData[indices[i * 3] * 3 + 1],
        verData[indices[i * 3] * 3 + 2]
      );
      let p22 = new BABYLON.Vector3(
        verData[indices[i * 3 + 1] * 3],
        verData[indices[i * 3 + 1] * 3 + 1],
        verData[indices[i * 3 + 1] * 3 + 2]
      );
      let p33 = new BABYLON.Vector3(
        verData[indices[i * 3 + 2] * 3],
        verData[indices[i * 3 + 2] * 3 + 1],
        verData[indices[i * 3 + 2] * 3 + 2]
      );
      let p1 = BABYLON.Vector3.TransformCoordinates(p11, m);
      let p2 = BABYLON.Vector3.TransformCoordinates(p22, m);
      let p3 = BABYLON.Vector3.TransformCoordinates(p33, m);
      area +=
        p1.x * (p2.z - p3.z) + p2.x * (p3.z - p1.z) + p3.x * (p1.z - p2.z);
      faceCount++;
    }
  }
  return area / 2;
};

function computeAreaTriangle3D(p1, p2, p3) {
  let AB = p2.subtract(p1);
  let AC = p3.subtract(p1);
  let area = BABYLON.Vector3.Cross(AB, AC).length();
  return area / 2;
}

function _almostEquals(v1, v2, epsilon = 0.001) {
  return Math.abs(v1 - v2) <= epsilon;
}

function _handleIncorrectOrientationOfPolygonVertices (verticesData){

  if(!_.isEmpty( verticesData )){
    if( _almostEquals(signedArea(verticesData), 0) ){
      verticesData = [verticesData[0], verticesData[3], verticesData[2], verticesData[1]];
      if(_almostEquals(signedArea(verticesData), 0)){
        verticesData = [verticesData[0], verticesData[1], verticesData[3], verticesData[2]];
      }
      if(_almostEquals(signedArea(verticesData), 0)){
        verticesData = [verticesData[0], verticesData[2], verticesData[1], verticesData[3]];
      }
    }
  }

  return verticesData;
}

var computeAreaMass = function (mesh, h) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if (!verData) return 0;

  var bbinfo = mesh.getBoundingInfo();
  var builtPol = [];
  const midY = mesh.midY || (bbinfo.boundingBox.maximumWorld.y + bbinfo.boundingBox.minimumWorld.y) / 2;
  let wmtrix = mesh.getWorldMatrix();
  let _storey = mesh.storey;

  for (let i = 0; i < verData.length; i += 3) {
    if( _storey > 0 && verData[i + 1] <= midY || _storey < 0 && verData[i + 1] >= midY ){
      let point = new BABYLON.Vector3(
          verData[i],
          verData[i + 1],
          verData[i + 2]
      );
      let nepoint = BABYLON.Vector3.TransformCoordinates(point, wmtrix);
      builtPol.push([nepoint.x, nepoint.z]);
    }
  }
  // builtPol = builtPol.uniq([].join);
  builtPol = removeDuplicates(builtPol);
  if(mesh.name === 'wall'){
    builtPol = _handleIncorrectOrientationOfPolygonVertices(builtPol);
  }
  var area = 0;
  builtPol = offsetRoomPols(builtPol, h);
  var j = builtPol.length - 1;
  for (let i = 0; i < builtPol.length; i++) {
    area =
      area +
      (builtPol[j][0] + builtPol[i][0]) * (builtPol[j][1] - builtPol[i][1]);
    j = i;
  }
  // area *= (editor_scale * editor_scale);
  // area /= (unit_scale * unit_scale * 100);
  // area = Math.abs(area * 1000) / 2000;
  // console.log(area);
  // console.log("Signed Area: ", signedArea(builtPol));
  // console.log("Area: ", area);
  return area / 2;
};

// function computeAreaMats(){
//     var $scope = store.angular.element(appElement).scope();
//     $scope = $scope.$$childHead;
//     for (var k = 0; k < $scope.matProps.length; k++) {
//         $scope.matProps[k].area = 0;
//         for (var i = 0; i < $scope.matProps[k].content.length; i++) {
//             $scope.matProps[k].content[i].area = 0;
//         }
//     }

//     for (var i=0; i<scene.meshes.length; i++){
//         var mesh = scene.meshes[i];
//         if (mesh._properties){
//             if (mesh._properties._components){
//                 for (var j=0; j<$scope.matProps.length; j++){
//                     for (var k=0; k<mesh._properties._components._layers.length; k++){
//                         if ($scope.matProps[j].name.toLowerCase() === mesh._properties._components._layers[k].value.toLowerCase()){
//                             var area = computeArea(mesh);
//                             var volume = area*mesh._properties._components._layers[k].thickness/100;
//                             volume = Math.round(volume*1000)/1000;
//                             $scope.matProps[j].area += area;
//                             $scope.matProps[j].area = Math.round($scope.matProps[j].area*1000)/1000;
//                             $scope.matProps[j].volume += volume;
//                             $scope.matProps[j].volume = Math.round($scope.matProps[j].volume*1000)/1000;
//                             if ($scope.matProps[j].content.length === 0){
//                                 var tmp = {
//                                     name: mesh._properties._components._layers[k].value,
//                                     propurl: window.location.origin + "/media/" + "/media/materials/wall6.jpg",
//                                     area: area,
//                                     volume: volume
//                                 };
//                                 // console.log(tmp.name);
//                                 $scope.matProps[j].content.push(tmp)
//                             }
//                             else{
//                                 $scope.matProps[j].content[0].area += area;
//                                 $scope.matProps[j].content[0].volume += volume;
//                                 $scope.matProps[j].content[0].volume = Math.round($scope.matProps[j].content[0].volume*1000)/1000;
//                                 // console.log($scope.matProps[j].content[0].name);
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// function computeAreaMats2() {
//     var $scope = store.angular.element(appElement).scope();
//     $scope = $scope.$$childHead;
//     for (var k = 0; k < $scope.matProps.length; k++) {
//         $scope.matProps[k].area = 0;
//         for (var i = 0; i < $scope.matProps[k].content.length; i++) {
//             $scope.matProps[k].content[i].area = 0;
//         }
//     }

//     for (var i = 0; i < scene.meshes.length; i++) {
//         var mesh = scene.meshes[i];
//         var mesh_mat = mesh.material;
//         // console.log(mesh, mesh_mat);
//         if (!mesh.subMeshes) {
//             return;
//         }
//         for (var j = 0; j < mesh.subMeshes.length; j++) {
//             var mat_id = mesh.subMeshes[j].materialIndex;
//             // console.log(mat_id, mesh_mat);
//             if (!mesh_mat) continue;
//             if (mesh_mat.subMaterials) {
//                 if (!mesh_mat.subMaterials[mat_id]) continue;
//                 var mat_name = mesh_mat.subMaterials[mat_id].name;
//                 // console.log(mat_name)
//                 if (mat_name.indexOf("jpg") != -1) {
//                     var mat_type = mat_name.substring(mat_name.indexOf("jpg") + 3, mat_name.length);
//                     var mat_url = mat_name.substring(0, mat_name.indexOf("jpg") + 3);
//                     console.log(mat_name, mat_url);
//                 }
//                 else if (mat_name.indexOf("png") != -1) {
//                     var mat_type = mat_name.substring(mat_name.indexOf("png") + 3, mat_name.length);
//                     var mat_url = mat_name.substring(0, mat_name.indexOf("png") + 3);
//                     // console.log(mat_name, mat_url);
//                 }
//                 else {
//                     if (mat_name == "wall_mat") {
//                         // var mat_type = "Colors";
//                         var mat_type = "Walls";
//                     }
//                     else if (mat_name == "floor_tile") {
//                         var mat_type = "Tile";
//                     }
//                 }

//                 var area = computerAreaofSubMesh(mesh, j);
//                 updateAreaInfo(mat_type, area);
//                 updateTextAreaInfo(mat_type, mat_url, area);
//                 // console.log(mat_type, mat_url, area);
//             }
//         }
//     }
// }

// function computeAreaTexts() {
//     for (var i = 0; i < scene.meshes.length; i++) {
//         var mesh = scene.meshes[i];
//         var mesh_mat = mesh.material;
//         for (var j = 0; j < mesh.subMeshes.length; j++) {
//             var mat_id = mesh.subMeshes[j].materialIndex;
//             if (mesh_mat.subMaterials) {
//                 var mat_name = mesh_mat.subMaterials[mat_id].name;
//                 if (mat_name.indexOf("jpg") != -1) {
//                     var mat_type = mat_name.substring(0, mat_name.indexOf("jpg") + 3);
//                 }
//                 else if (mat_name.indexOf("png") != -1) {
//                     var mat_type = mat_name.substring(0, mat_name.indexOf("png") + 3);
//                 }
//                 else {
//                     if (mat_name == "wall_mat") {
//                         // var mat_type = "Colors";
//                         var mat_type = "Walls";
//                     }
//                     else if (mat_name == "floor_tile") {
//                         var mat_type = "Tile";
//                     }
//                 }
//                 // (mat_type);
//                 var area = computerAreaofSubMesh(mesh, j);
//                 updateTextAreaInfo(mat_name, area);
//                 // (area);
//             }
//         }
//     }
// }

function updateAreaInfo(mat_type, area) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  for (var i = 0; i < $scope.matProps.length; i++) {
    if ($scope.matProps[i].name == mat_type) {
      $scope.$apply(function () {
        $scope.matProps[i].area += Math.round(area * 100) / 100;
        return;
      });
    }
  }
}

function updateTextAreaInfo(mat_type, mat_url, area) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  var prop_idx = -1;
  for (let i = 0; i < $scope.matProps.length; i++) {
    if ($scope.matProps[i].name == mat_type) {
      prop_idx = i;
      break;
    }
  }
  if (prop_idx == -1) {
    return;
  }
  for (let i = 0; i < $scope.matProps[prop_idx].content.length; i++) {
    if ($scope.matProps[prop_idx].content[i].propurl == mat_url) {
      $scope.$apply(function () {
        $scope.matProps[prop_idx].content[i].area +=
          Math.round(area * 100) / 100;
        return;
      });
    }
  }
}

function areaOfPolygon2D(poly) {
  let area = 0;
  let j = poly.length - 1;
  for (let i = 0; i < poly.length; i++) {
    area += (poly[j][0] + poly[i][0]) * (poly[j][1] - poly[i][1]);
    j = i;
  }
  return Math.abs(area / 2);
}

function areaOfPolygon3D(poly) {
  //determinant of matrix a
  function det(a) {
    return (
      a[0][0] * a[1][1] * a[2][2] +
      a[0][1] * a[1][2] * a[2][0] +
      a[0][2] * a[1][0] * a[2][1] -
      a[0][2] * a[1][1] * a[2][0] -
      a[0][1] * a[1][0] * a[2][2] -
      a[0][0] * a[1][2] * a[2][1]
    );
  }
  //unit normal vector of plane defined by points a, b, and c
  function unit_normal(a, b, c) {
    let x = det([
      [1, a[1], a[2]],
      [1, b[1], b[2]],
      [1, c[1], c[2]],
    ]);
    let y = det([
      [a[0], 1, a[2]],
      [b[0], 1, b[2]],
      [c[0], 1, c[2]],
    ]);
    let z = det([
      [a[0], a[1], 1],
      [b[0], b[1], 1],
      [c[0], c[1], 1],
    ]);
    let magnitude = Math.pow(
      Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2),
      0.5
    );
    return [x / magnitude, y / magnitude, z / magnitude];
  }
  // dot product of vectors a and b
  function dot(a, b) {
    return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
  }
  // cross product of vectors a and b
  function cross(a, b) {
    let x = a[1] * b[2] - a[2] * b[1];
    let y = a[2] * b[0] - a[0] * b[2];
    let z = a[0] * b[1] - a[1] * b[0];
    return [x, y, z];
  }

  // area of polygon poly
  function area(poly) {
    if (poly.length < 3) {
      console.log("not a plane - no area");
      return 0;
    } else {
      let total = [0, 0, 0];
      for (let i = 0; i < poly.length; i++) {
        var vi1 = poly[i];
        var vi2;
        if (i === poly.length - 1) {
          vi2 = poly[0];
        } else {
          vi2 = poly[i + 1];
        }
        let prod = cross(vi1, vi2);
        total[0] = total[0] + prod[0];
        total[1] = total[1] + prod[1];
        total[2] = total[2] + prod[2];
      }
      let result = dot(total, unit_normal(poly[0], poly[1], poly[2]));

      return Math.abs(result / 2);
    }
  }
  return area(poly);
}

function areaOfPolygonV3(polyV3){
  if (polyV3.length < 3) {
      return 0;
  }
  else {
      const sumOfCrossProducts = BABYLON.Vector3.Zero();
      const length = polyV3.length;
      polyV3.forEach((v1, i) => {
          const nextIndex = i === length - 1 ? 0 : i + 1;
          const v2 = polyV3[nextIndex];

          const crossProduct = v1.cross(v2);
          sumOfCrossProducts.addInPlace(crossProduct);
      });

      const result = BABYLON.Vector3.Dot(sumOfCrossProducts, getUnitNormalVectorV3CyclicCheck(polyV3));
      return Math.abs(result/2);
  }
}

const computeAreaSlab = (mesh) => {
  let brep = mesh.getSnaptrudeDS().brep;
  if (brep){
      let faces = mesh.getSnaptrudeDS().brep.getFaces();
      let areaArray = [];
      let area = 0;
      faces.forEach((f) => {
          let v=getFaceVerticesFromFace(f, mesh, BABYLON.Space.WORLD);
          let n = getNormalVector(v);
          let verts = [];
          for (let i=0; i<v.length; i++){
              verts.push([v[i].x, v[i].y, v[i].z]);
          }
          let a = areaOfPolygon3D(verts);
          areaArray.push(a);
      });
      let ids = areaArray.sortByIndices().reverse();
      area = areaArray[ids[1]];
      return area;
  }
  else{
      computeAreaMass(mesh, 0);
  }    
};

const computeAreaColumn = (mesh) => {
  let brep = mesh.getSnaptrudeDS().brep;
  if (brep){
      let faces = mesh.getSnaptrudeDS().brep.getFaces();
      let areaArray = [];
      let area = 0;
      faces.forEach((f) => {
          let v=getFaceVerticesFromFace(f, mesh, BABYLON.Space.WORLD);
          let n = getNormalVector(v);
          let verts = [];
          for (let i=0; i<v.length; i++){
              verts.push([v[i].x, v[i].y, v[i].z]);
          }
          let a = areaOfPolygon3D(verts);
          areaArray.push(a);
      });
      let ids = areaArray.sortByIndices();
      for (let i=2; i<ids.length; i++){
          area += areaArray[ids[i]];
      }

      return area;
  }
  else{
      computeAreaMass(mesh, 0);
  }
};

const computeAreaBeam = (mesh) => {
  let brep = mesh.getSnaptrudeDS().brep;
  if (brep){
      let faces = mesh.getSnaptrudeDS().brep.getFaces();
      let areaArray = [];
      let area = 0;
      faces.forEach((f) => {
          let v=getFaceVerticesFromFace(f, mesh, BABYLON.Space.WORLD);
          let n = getNormalVector(v);
          let verts = [];
          for (let i=0; i<v.length; i++){
              verts.push([v[i].x, v[i].y, v[i].z]);
          }
          let a = areaOfPolygon3D(verts);
          areaArray.push(a);
      });
      let ids = areaArray.sortByIndices().reverse();
      for (let i=1; i<ids.length; i++){
          area += areaArray[ids[i]];
      }
      return area;
  }
  else{
      computeAreaMass(mesh, 0);
  }    
};

export {
  BuiltUpArea,
  computerDoorCAArea,
  computeArea,
  computeAreaWall,
  computeVolumeWall,
  computeVolumeWallOld,
  computeAreaForMass,
  computeVolumeStairsLayer,
  computeVolumeStairsLayerType,
  computeVolumeMass,
  computeVolumeFloor,
  computeAreaWallOld,
  computeAreaFloor,
  computeAreaFloorOld,
  computeAreaTriangle3D,
  computeAreaMass,
  updateAreaInfo,
  updateTextAreaInfo,
  areaOfPolygon2D,
  areaOfPolygon3D,
  areaOfPolygonV3,
  computeAreaSlab,
  computeAreaColumn,
  computeAreaBeam
};
