import React, { useState } from "react";
import MenuPopUp from "../../../../components/MenuPopUp/index";
import TileWithBottomStats from "../../../../components/rightMenus/tileWithBottomStats";
import {RIGHT_SUB_MENU_ICONS} from "../../Icons";

const DoorPopUps = (props) => {
  const { closePopUp, resetSelectedTopSubMenu, style, isOpen } = props;
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [importedObjects, setImportedObjects] = useState([]);

  const leftMenuItems=[
    {
      title: "Swing",
      options: ["1 Shutter", "2 Shutter"],
    },
    { title: "Sliding",
      options: ["2 Shutter", "3 Shutter", "4 Shutter", "6 Shutter"],
    },
    { title: "Folding",
      options: ["2 Shutter", "3 Shutter", "4 Shutter", "6 Shutter"],
    },
    { title: "Pivot",
      options: ["2 Shutter", "3 Shutter", "4 Shutter", "6 Shutter"],
    },
    { title: "Rolling Shutter" },
    { title: "Fixed" },
    { title: "Garage" },
  ];
  
  const doorTypeMapping = {
    "Swing - 1 Shutter" : "singleDoor",
    "Swing - 2 Shutter" : "doubleDoor",
    "Sliding - 2 Shutter" : "sliding2",
    "Sliding - 3 Shutter" : "sliding3",
    "Sliding - 4 Shutter" : "sliding4",
    "Sliding - 6 Shutter" : "sliding6",
    "Folding - 2 Shutter" : "folding2",
    "Folding - 3 Shutter" : "folding3",
    "Folding - 4 Shutter" : "folding4",
    "Folding - 6 Shutter" : "folding6",
    "Pivot - 2 Shutter" : "pivot2",
    "Pivot - 3 Shutter" : "pivot3",
    "Pivot - 4 Shutter" : "pivot4",
    "Pivot - 6 Shutter" : "pivot6",
    "Rolling Shutter" : "rollingShutter",
    "Fixed" : "fixed",
    "Garage" : "garage",
  }
  
  const typeOptions = Object.keys(doorTypeMapping);
  
  const topSubMenuOptions = [];
  
  topSubMenuOptions.push({
    icon : RIGHT_SUB_MENU_ICONS.importDoor,
    callback : null,
    name : "importDoor",
    tooltipText: "Add door",
  });
  
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  
  const [selectedSubIndex, setSelectedSubIndex] = useState(0);
  const [thumbnailImageItems, setThumbnailImageItems] = useState([]);
  

  const getFilterSubType = function (){
  
    const selectedMenu = leftMenuItems[selectedMenuIndex];
    const separator = " - ";
    
    let uiType = selectedMenu.title;
    if (selectedMenu.options){
      uiType += separator + selectedMenu.options[selectedSubIndex];
    }
    
    return getDBTypeFromUIType(uiType);
    
  };
  
  const getDBTypeFromUIType = function (uiType){
    return doorTypeMapping[uiType];
  };
  
  return (
    <MenuPopUp
      leftMenuItems={leftMenuItems}
      selectedIndex={selectedMenuIndex}
      setSelectedIndex={setSelectedMenuIndex}
      selectedSubIndex={selectedSubIndex}
      setSelectedSubIndex={setSelectedSubIndex}
      hasleftMenu={true}
      hasTopIcons={true}
      topSubMenuOptions={topSubMenuOptions}
      title={"Doors"}
      isOpen={isOpen}
      style={style}
      child={() => {
        return (
          <TileWithBottomStats
            statsArray={[
              { title: "Width", value: 1500 },
              { title: "Height", value: 1500 },
            ]}
            filterType="door"
            filterSubType={getFilterSubType()}
            thumbnailImageItems={thumbnailImageItems}
            setThumbnailImageItems={setThumbnailImageItems}
            importedObjects={importedObjects}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            isOpen={isOpen}
          />
        );
      }}
      noBorder={true}
      closePopUp={closePopUp}
      typeOptions={typeOptions}
      hideEmptyBox={true}
      getDBTypeFromUIType={getDBTypeFromUIType}
      selectedSubMenu={selectedSubMenu}
      setSelectedSubMenu={setSelectedSubMenu}
      thumbnailImageItems={thumbnailImageItems}
      setThumbnailImageItems={setThumbnailImageItems}
      setImportedObjects={setImportedObjects}
      disableAdd={true}
    />
  );
};

export default DoorPopUps;
