import BABYLON from "../babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { setLayerTransperancy } from "../../libs/sceneFuncs.js";
import { copyBrep } from "../../libs/brepOperations.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import {
  addMeshToStructure,
  changeTypeInStructure,
  deepCopyObject,
  deSerializeMesh,
  disposeMeshById,
  getRotationQuaternion,
  getSerializedFormOfMesh,
  isMeshThrowAway,
  isThrowAwayIdentifier,
  markMeshAsThrowAway,
  onSolid,
  removeComponentFromStructure,
  removeMeshThrowAwayIdentifier,
  updateCSG,
} from "../extrafunc.js";
import { AutoSave } from "../socket/autoSave.js";
import { CommandManager } from "./CommandManager.js";
import { Command } from "./Command.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { updateRoofAccordion } from "../../libs/roofVisibilityFuncs.js";
import { meshObjectMapping } from "../snaptrudeDS/mapping.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { Floor } from "../snaptrudeDS/floor.ds.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { meshUniqueIdMapper } from '../utilityFunctions/meshUniqueIdMapper';
import { materialLoader } from '../../libs/mats.js';
import {deleteFloorPlan, scaleFactor} from '../../libs/twoDimension';
import { FloorPlan } from '../snaptrudeDS/floorplan.ds';
import { layerView } from '../../libs/layerView';
import {getGeometryById, recreateMaterials, recreateMultiMaterials} from '../../libs/sceneStateFuncs';
import { applyMaterial, removeMaterial } from '../../libs/applyMaterialFuncs';
import { editMaterialOriginal as editMaterial, reEditMaterial } from '../../libs/materialEditUI';
import reduxStore from "../../stateManagers/store/reduxStore";
import { updateProperty } from "../../stateManagers/reducers/objectProperties/projectPropertiesSlice";
import { isCADMesh } from "../../libs/snapUtilities";
import snapEngine from "../snappingEngine/snapEngine";
import { appendStorey } from "../../stateManagers/reducers/objectProperties/storeysSlice.js";
import CostCalculator from "../costCalculator/index.js";
import {appendLayer} from "../../stateManagers/reducers/objectProperties/storeysSlice";
import imageLayer from "../../../assets/tiles/imageLayer.svg";
import {backwardCompatibilityChecker} from "../backwardCompatibility/backwardCompatibility";

/*
INSTRUCTIONS -
Gather command data for deletion before any change to the mesh.
Gather command data for creation after every change to the mesh.

Assign additional data before the command creation for custom changes
For pre and post execute/unexecute callbacks, pass regular functions and NOT lambda functions

This list will grow
 */
let commandUtils = (function () {
  let CONSTANTS = {
    freeMoveOperation: "freeMove",
    moveOperation: "move",
    rotateOperation: "rotate",
    scaleOperation: "scale",
    moveVertexOperation: "moveVertex",
    moveEdgeOperation: "moveEdge",
    moveFaceOperation: "moveFace",
    extrudeOperation: "extrude",
    editPolygonOperation: "editPolygon",
    removeElementsOperation: "removeElements",
    flipOperation: "flip",
    addVertexOperation: "addVertex",
    addEdgeOperation: "addEdge",
    drawMassOperation: "drawMass",
    drawVoidOperation: "drawVoid",
    errorType: {
      COLLABORATION: "Collaboration: "
    },
  };

  let geometryChangeOperations = (function () {
    function _applyGeometryAndBRepToMesh(meshDS, brep) {
      let meshOfInterest = meshDS.mesh;
      if (meshOfInterest.isAnInstance) {
        _applyGeometryAndBRepToMesh(
          meshOfInterest.sourceMesh.getSnaptrudeDS(),
          brep
        );
        // onSolid(meshOfInterest);
        setLayerTransperancy(meshOfInterest);
      } else {
        meshDS.brep = copyBrep(brep);
        meshOfInterest.BrepToMesh();
        // onSolid(meshOfInterest);
        setLayerTransperancy(meshOfInterest);

        if (meshOfInterest.instances && !_.isEmpty(meshOfInterest.instances)) {
          meshOfInterest.instances.forEach((instance) => {
            const instanceDS = instance.getSnaptrudeDS();
            instanceDS.brep = meshDS.brep;
            instance.refreshBoundingInfo();
          });

          virtualSketcher.updateWithoutGeometryEdit(
            meshOfInterest.instances[0].getSnaptrudeDS(),
            true
          );
        } else {
          virtualSketcher.updateWithoutGeometryEdit(meshDS);
        }
      }
    }

    function _execute() {
      function _handleDataPoint(dataPoint) {
        let meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
        let brep = dataPoint.brepAfter;
        if (meshOfInterest && brep) {
          let meshDS = meshOfInterest.getSnaptrudeDS();
          _applyGeometryAndBRepToMesh(meshDS, brep);

          if (dataPoint.assignStorey) {
            StoreyMutation.assignStorey(meshDS);
          }
          if (dataPoint.editedAfter) {
            meshDS.markAsEdited();
          }
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _unExecute() {
      function _handleDataPoint(dataPoint) {
        let meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
        let brep = dataPoint.brepBefore;
        if (meshOfInterest && brep) {
          let meshDS = meshOfInterest.getSnaptrudeDS();
          _applyGeometryAndBRepToMesh(meshDS, brep);

          if (dataPoint.assignStorey) {
            StoreyMutation.assignStorey(meshDS);
          }
          if (!dataPoint.editedBefore) {
            meshDS.removeAsEdited();
          }
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function getCommandData(stack, data) {
      if (!data) {
        //before edit

        function _handleMesh(mesh) {
          let meshDS = mesh.getSnaptrudeDS();
          if (!meshDS.brep) return;

          let dataPoint = {
            meshId: mesh.uniqueId,
            brepBefore: copyBrep(meshDS.brep),
            meshBefore: getSerializedFormOfMesh(mesh), //for auto save
            storeyBefore: mesh.storey,
            meshDS,
          };

          if (meshDS) {
            if (
              !["door", "window", "furniture"].includes(mesh.type.toLowerCase())
            ) {
              if (meshDS.isEdited()) {
                dataPoint.editedBefore = true;
              }
            }
          }

          data.push(dataPoint);
        }

        data = [];
        if (!_.isArray(stack)) {
          stack = [stack];
        }
        stack.forEach(_handleMesh);
      } else {
        //after edit

        function _handleDataPoint(dataPoint) {
          let meshDS = dataPoint.meshDS;
          let mesh = meshDS.mesh;

          dataPoint.brepAfter = copyBrep(meshDS.brep);
          if (meshDS) {
            if (
              !["door", "window", "furniture"].includes(mesh.type.toLowerCase())
            ) {
              if (meshDS.isEdited()) {
                dataPoint.editedAfter = true;
              }
            }
          }

          if (mesh.storey !== dataPoint.storeyBefore) {
            dataPoint.assignStorey = true;
          }

          dataPoint.storeyAfter = mesh.storey;
          // delete dataPoint.storeyBefore;
          delete dataPoint.meshDS;
        }

        data.forEach(_handleDataPoint);
      }

      return data;
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function getSaveData() {
      let saveDataCollective = [];

      let data = this.data;
      data.forEach((dataPoint) => {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = this.id;
        saveData.data.saveType = "updateGeometry";

        let dataBefore = {};
        let dataAfter = {};
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (!mesh) return;

        dataBefore.brep = store.resurrect.stringify(dataPoint.brepBefore);
        dataBefore.vertexData = dataPoint.meshBefore.geometries.vertexData;
        dataBefore.geometryId = dataBefore.vertexData[0].id;

        dataAfter.brep = store.resurrect.stringify(dataPoint.brepAfter);
        dataAfter.vertexData =
          getSerializedFormOfMesh(mesh).geometries.vertexData;
        dataAfter.geometryId = dataAfter.vertexData[0].id;

        dataAfter.storey = dataPoint.storeyAfter;
        dataAfter.edited = dataPoint.editedAfter;

        dataBefore.storey = dataPoint.storeyBefore;
        dataBefore.edited = dataPoint.editedBefore;

        let identifierMeshDS;
        if (mesh.isAnInstance) {
          identifierMeshDS = mesh.sourceMesh.getSnaptrudeDS();
        } else {
          identifierMeshDS = mesh.getSnaptrudeDS();
        }

        saveData.data.identifier =
          AutoSave.getComponentIdentifier(identifierMeshDS);

        saveData.data.afterOperationData = dataAfter;
        saveData.data.beforeOperationData = dataBefore;

        if (identifierMeshDS.revitMetaData) {
          saveData.data.operationData = { isModified: true };
          identifierMeshDS.revitMetaData.isModified = true;
        }

        saveDataCollective.push(saveData);
      });

      return saveDataCollective;
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);

      CommandManager.execute(command, false);
    }

    function getCommand(name, data, options = {}) {
      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };
      return new Command(name, data, logic, getSaveData);
    }

  function executeFromSaveData(saveData){
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = afterOperationData;

    const caller = {
        data: []
    };
    const data = caller.data;
    data.push({
        meshId: identifier.component_id,
        brepAfter: store.resurrect.resurrect(chosenData.brep)
    });

    _execute.call(caller);
  }

  function unExecuteFromSaveData(saveData){
    const identifier = saveData.identifier;
    const afterOperationData = saveData.afterOperationData;
    const beforeOperationData = saveData.beforeOperationData;
    const chosenData = beforeOperationData;

    const caller = {
        data: []
    };
    const data = caller.data;
    data.push({
        meshId: identifier.component_id,
        brepAfter: store.resurrect.resurrect(chosenData.brep)
    });

    _execute.call(caller);
  }

    function expressCheckout(name, stack, callback) {
      let commandData = getCommandData(stack);
      callback();
      commandData = getCommandData(stack, commandData);

      return getCommand(name, commandData);
    }

    function flattenCommands(commands) {
      commands = _.compact(commands);
      if (_.isEmpty(commands)) return null;
      const flattenedCommand = commands[0];
      const meshCommandDataMapping = {};
      commands.forEach((command) => {
        command.data.forEach((datum) => {
          /*
            datum = {
                meshId,
                brepBefore,
                brepAfter,
                .
                .
            }
             */
          
          let meshIdToCheck;
          const mesh = store.scene.getMeshByUniqueID(datum.meshId);
          
          if (mesh.isAnInstance) meshIdToCheck = mesh.sourceMesh.uniqueId;
          else meshIdToCheck = datum.meshId;
          
          if (!meshCommandDataMapping[meshIdToCheck])
            meshCommandDataMapping[meshIdToCheck] = [];
          meshCommandDataMapping[meshIdToCheck].push(datum);
        });
      });
      flattenedCommand.data = [];
      _.forEach(meshCommandDataMapping, (data) => {
        const firstDatum = _.first(data);
        if (data.length > 1) {
          const lastDatum = _.last(data);
          firstDatum.brepAfter = lastDatum.brepAfter;
          firstDatum.storeyAfter = lastDatum.storeyAfter;
        }
        flattenedCommand.data.push(firstDatum);
      });
      return flattenedCommand;
    }


    return {
      executeCommand,
      getCommandData,
      getCommand,
      executeFromSaveData,
      unExecuteFromSaveData,
      expressCheckout,
      flattenCommands,
    };
  })();

  let worldMatrixChangeOperations = (function () {
      let PARAMS = {
        position: 1,
        rotation: 2,
        scale: 3,
      };

      function _handleChildren(mesh, options = {}) {
        if (options.excludeChildren) {
          if (!options.excludedChildren) {
            let children = mesh.getChildren();
            options.excludedChildren = [];
            children.forEach((child) => {
              if (options.excludeChildren.includes(child.uniqueId)) {
                options.excludedChildren.push(child);
                child.setParent(null);
              }
            });
            options.afterOperation = true;
          } else {
            options.excludedChildren.forEach((child) => {
              child.computeWorldMatrix(true);
              mesh.computeWorldMatrix(true);
              child.setParent(mesh)
            });
            delete options.excludedChildren;
          }
        }
      }

      function _applyTranslation(mesh, changeVector, options = {}) {
        _handleChildren(mesh, options);
        // mesh.position.addInPlace(changeVector);
        const positionToBe = mesh.getAbsolutePosition().add(changeVector);
        mesh.setAbsolutePosition(positionToBe);
        _handleChildren(mesh, options);
      }

      function _applyRotation(mesh, rotationVector, quaternion, options = {}) {
        _handleChildren(mesh, options);
        if (mesh.rotationQuaternion) {
          if (quaternion) {
            mesh.rotationQuaternion.copyFrom(quaternion);
          } else {
            mesh.rotationQuaternion = getRotationQuaternion(rotationVector);
          }
        } else {
          if (quaternion) {
            if (!mesh.rotationQuaternion)
              mesh.rotationQuaternion = BABYLON.Quaternion.Zero();
            mesh.rotationQuaternion.copyFrom(quaternion);
          } else {
            mesh.rotation = rotationVector.clone();
          }
        }
        _handleChildren(mesh, options);
      }

      function _applyScaling(mesh, changeVector, options = {}) {
        _handleChildren(mesh, options);
        mesh.scaling.addInPlace(changeVector);
        _handleChildren(mesh, options);
        // Calling onSolid() for possible object type change; Ex: Room <-> Slab
        if (store.scene._activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA) onSolid(mesh);
      }

      function _execute() {
        function _handleDataPoint(data) {
          let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
          if (meshOfInterest) {
            if (meshOfInterest.parent && !data.operationOnChild) return;
            let meshDS = meshOfInterest.getSnaptrudeDS();

            if (data.positionChange) {
              _applyTranslation(
                meshOfInterest,
                data.positionChange,
                data.options
              );
            }
            if (data.rotationChange) {
              _applyRotation(
                meshOfInterest,
                data.rotationAfter,
                data.rotationQuaternionAfter,
                data.options
              );
            }
            if (data.scaleChange) {
              _applyScaling(meshOfInterest, data.scaleChange, data.options);
              if (data.synchronizeInstances) {
                meshOfInterest.isAnInstance ? meshOfInterest.sourceMesh.synchronizeInstances() :
                  meshOfInterest.synchronizeInstances();
              }
            }
            if (data.assignStorey) {
              StoreyMutation.assignStorey(meshDS);
            }
            if (data.editedAfter) {
              meshDS.markAsEdited();
            }

            if (isCADMesh(meshOfInterest)) snapEngine.cadSnaps.update(meshOfInterest);

            if(meshDS){
              virtualSketcher.updateWithoutGeometryEdit(meshDS);
            }
          }
        }

        let data = this.data;
        data.forEach(_handleDataPoint);
      }

      function _unExecute() {
        function _handleDataPoint(data) {
          let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
          if (meshOfInterest) {
            if (meshOfInterest.parent && !data.operationOnChild) return;
            let meshDS = meshOfInterest.getSnaptrudeDS();

            if (data.positionChange) {
              _applyTranslation(
                meshOfInterest,
                data.positionChange.negate(),
                data.options
              );
            }
            if (data.rotationChange) {
              _applyRotation(
                meshOfInterest,
                data.rotationBefore,
                data.rotationQuaternionBefore,
                data.options
              );
            }
            if (data.scaleChange) {
              _applyScaling(
                meshOfInterest,
                data.scaleChange.negate(),
                data.options
              );
              if (data.synchronizeInstances) {
                meshOfInterest.isAnInstance ? meshOfInterest.sourceMesh.synchronizeInstances() :
                  meshOfInterest.synchronizeInstances();
              }
            }
            if (data.assignStorey) {
              StoreyMutation.assignStorey(meshDS);
            }
            if (data.editedBefore === false) {
              //undefined was going off as false for !data.editedBefore
              meshDS.removeAsEdited();
            }

            if (isCADMesh(meshOfInterest)) snapEngine.cadSnaps.update(meshOfInterest);

            if(meshDS){
              virtualSketcher.updateWithoutGeometryEdit(meshDS);
            }
          }
        }

        let data = this.data;
        data.forEach(_handleDataPoint);
      }

      function _doSomeOperationOnEntireSelectionStack(
        excludedMesh,
        operationCallback,
        stack
      ) {
        let stackLocal = stack || store.selectionStack;
        stackLocal.forEach(function (mesh) {
          if (excludedMesh) if (mesh.uniqueId === excludedMesh.uniqueId) return;

          operationCallback(mesh);
          if (mesh.children) {
            if (mesh.children.length > 0) {
              if (mesh.children[0]) {
                if (mesh.children[0].name.indexOf("boxScale") > 0) {
                  operationCallback(mesh.children[0]);
                }
              }
            }
          }
        });
      }

      function _getExecute(options) {
        let preCallback = options.preExecuteCallback;
        let postCallback = options.postExecuteCallback;

        return function () {
          if (preCallback) preCallback.call(this);
          _execute.call(this);
          if (postCallback) postCallback.call(this);
        };
      }

      function _getUnExecute(options) {
        let preCallback = options.preUnExecuteCallback;
        let postCallback = options.postUnExecuteCallback;

        return function () {
          if (preCallback) preCallback.call(this);
          _unExecute.call(this);
          if (postCallback) postCallback.call(this);
        };
      }

      function getCommandDataWithoutData(stack, options) {
          const _getStack = function (){
            const secondaryStack = options.stack || store.selectionStack || [];
            return [
              ...stack,
              ...secondaryStack,
            ]
          };

          const _handleChildDataPoint = function (mesh, parentDataPoint) {
            const childDataPoint = {
              meshId: mesh.uniqueId,
              storeyBefore: mesh.storey,
            };

            options.params.forEach((param) => {
              switch (param) {
                case PARAMS.position:
                  childDataPoint.positionBefore = mesh.absolutePosition.clone();
                  break;
                case PARAMS.rotation:
                  childDataPoint.rotationBefore = mesh.rotation.clone();
                  if (mesh.rotationQuaternion)
                    childDataPoint.rotationQuaternionBefore =
                      mesh.absoluteRotationQuaternion.clone();
                  else if (parentDataPoint.rotationQuaternionBefore)
                    childDataPoint.rotationQuaternionBefore =
                      parentDataPoint.rotationQuaternionBefore.clone();
                  break;
                case PARAMS.scale:
                  childDataPoint.scaleBefore = mesh.absoluteScaling.clone();
                  break;
              }
            });

            return childDataPoint;
          }

          const _handleDataPoint = function (mesh) {
            if (!mesh) return;
            if (mesh.parentMesh) return; //box scales
            if (mesh.parent && mesh.parent.state === "on" && !options.overrideMeshParentSelectionForCommandData) return;
            let meshDS = mesh.getSnaptrudeDS();
            if (!meshDS && !mesh.type.toLowerCase().includes("cadsketch")) return;

            let dataPoint = {
              meshId: mesh.uniqueId,
              storeyBefore: mesh.storey,
              revitMetaData: meshDS?.revitMetaData
            };

            if (meshDS) {
              if (
                !["door", "window", "furniture"].includes(mesh.type.toLowerCase())
              ) {
                if (meshDS.isEdited()) {
                  dataPoint.editedBefore = meshDS.edited;
                }
              }
            }
            options.params.forEach((param) => {
              switch (param) {
                case PARAMS.position:
                  dataPoint.positionBefore = mesh.getAbsolutePosition().clone();
                  break;
                case PARAMS.rotation:
                  dataPoint.rotationBefore = mesh.rotation.clone();
                  if (mesh.rotationQuaternion)
                    dataPoint.rotationQuaternionBefore =
                      mesh.rotationQuaternion.clone();
                  break;
                case PARAMS.scale:
                  dataPoint.scaleBefore = mesh.scaling.clone();
                  break;
              }
            });

            // Overriding data for support floor
            if (meshDS && meshDS.isSupportFloor && meshDS.isSupportFloor()) {
              if (dataPoint.positionBefore) dataPoint.positionBefore = mesh.absolutePosition.clone();
              if (dataPoint.scaleBefore) dataPoint.scaleBefore = mesh.absoluteScaling.clone();
            }
            if (options.operationOnChild) {
              dataPoint.operationOnChild = true;
            }
            if (options.synchronizeInstances) {
              dataPoint.synchronizeInstances = true;
            }

            if (options.determineIfChildAlternatively){
              if (mesh.parent && !alternateStack.includes(mesh.parent)){
                dataPoint.operationOnChild = true;
              }
            }

            if (options.dataOptions) {
              dataPoint.options = options.dataOptions;
            }

            if (options.doNotHandleChildrenForSave) {
              dataPoint.doNotHandleChildrenForSave = true;
            }

            /* Kinda complicated way to handle child meshes transform for auto-save
               Needs refactoring or decoupling for improved functionality and code readability
             */
            if (!_.isEmpty(mesh.childrenComp)) {
              const meshChildrenData = {};
              mesh.childrenComp.forEach(c => {
                meshChildrenData[c.uniqueId] = _handleChildDataPoint(c, dataPoint);
                if (!_.isEmpty(c.childrenComp)) {
                  const meshGrandChildrenData = {};
                  c.childrenComp.forEach(gc => {
                    meshGrandChildrenData[gc.uniqueId] = _handleChildDataPoint(gc, meshChildrenData[c.uniqueId]);
                  })
                  meshChildrenData[c.uniqueId].childrenData = meshGrandChildrenData;
                }
              });
              dataPoint.childrenData = meshChildrenData;
            }
            data.push(dataPoint);
          }

          let data = [];
          if (!_.isArray(stack)) stack = [stack];

          let alternateStack = [];
          if (options.determineIfChildAlternatively){
            alternateStack = _getStack();
          }

          stack.forEach(mesh => _handleDataPoint(mesh));
          if (!options.ignoreSelectionStack)
            _doSomeOperationOnEntireSelectionStack(
              stack[0],
              _handleDataPoint,
              options.stack
            );

          return data;
      }

      const getCommandDataWithData = (options) => {
        let data = options.data;

        const setChildrenAfterData = function (mesh, dataPoint, parentDataPoint) {
          if (dataPoint.positionBefore)
            dataPoint.positionChange = mesh.absolutePosition.subtract(
              dataPoint.positionBefore
            );

          if (dataPoint.rotationBefore) {
            dataPoint.rotationChange = mesh.rotation.subtract(
              dataPoint.rotationBefore
            );
            dataPoint.rotationAfter = mesh.rotation.clone();
            if (mesh.rotationQuaternion)
              dataPoint.rotationQuaternionAfter =
                mesh.absoluteRotationQuaternion.clone();
            else if (dataPoint.rotationQuaternionBefore)
              dataPoint.rotationQuaternionAfter =
                parentDataPoint.rotationQuaternionAfter.clone();
          }

          if (dataPoint.scaleBefore)
            dataPoint.scaleChange = mesh.absoluteScaling.subtract(
              dataPoint.scaleBefore
            );

          if (mesh.storey !== dataPoint.storeyBefore) {
            dataPoint.assignStorey = true;
          }
          dataPoint.storeyAfter = mesh.storey;
        }

        const _handleDataPoint = function (dataPoint) {
          let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
          if (!mesh) return;

          if (dataPoint.positionBefore)
            dataPoint.positionChange = mesh.getAbsolutePosition().subtract(
              dataPoint.positionBefore
            );

          if (dataPoint.rotationBefore) {
            dataPoint.rotationChange = mesh.rotation.subtract(
              dataPoint.rotationBefore
            );
            dataPoint.rotationAfter = mesh.rotation.clone();
            if (mesh.rotationQuaternion)
              dataPoint.rotationQuaternionAfter =
                mesh.rotationQuaternion.clone();
          }

          if (dataPoint.scaleBefore)
            dataPoint.scaleChange = mesh.scaling.subtract(
              dataPoint.scaleBefore
            );

          if (mesh.storey !== dataPoint.storeyBefore) {
            dataPoint.assignStorey = true;
          }
          let meshDS = mesh.getSnaptrudeDS();

          if (meshDS.revitMetaData) dataPoint.revitMetaData = meshDS.revitMetaData;

          if (meshDS) {
            if (
              !["door", "window", "furniture"].includes(mesh.type.toLowerCase())
            ) {
              if (meshDS.isEdited()) {
                dataPoint.editedAfter = meshDS.edited;
              }
            }
          }

          // Overriding data for support floor
          if (meshDS && meshDS.isSupportFloor && meshDS.isSupportFloor()) {
            if (dataPoint.positionBefore) dataPoint.positionChange = mesh.absolutePosition.subtract(dataPoint.positionBefore);
            if (dataPoint.scaleBefore) dataPoint.scaleChange = mesh.absoluteScaling.subtract(dataPoint.scaleBefore);
          }
          dataPoint.storeyAfter = mesh.storey;
          // delete dataPoint.storeyBefore;
          if (dataPoint.childrenData) {
            for (const dp in dataPoint.childrenData) {
              const child = store.scene.getMeshByUniqueID(parseInt(dp));
              if (child) {
                setChildrenAfterData(child, dataPoint.childrenData[parseInt(dp)], dataPoint);
                if (dataPoint.childrenData[parseInt(dp)].childrenData) {
                  for (const gdp in dataPoint.childrenData[parseInt(dp)].childrenData) {
                    const grandChild = store.scene.getMeshByUniqueID(parseInt(gdp));
                    if (grandChild) {
                      setChildrenAfterData(grandChild, dataPoint.childrenData[parseInt(dp)].childrenData[parseInt(gdp)],
                        dataPoint.childrenData[parseInt(dp)]
                      )
                    }
                  }
                }
              }
            }
          }
        }

        data.forEach(_handleDataPoint);

        return data;
      };

      function getCommandData(stack, options) {
        if (!options.data) {
          //before edit
          return getCommandDataWithoutData(stack, options);
        } else {
          //after edit
          return getCommandDataWithData(options)
        }
      }

      function executeCommand(name, data, options) {
        let command = getCommand(name, data, options);
        CommandManager.execute(command, false);
      }

      function getSaveData() {
        let saveDataCollective = [];

        function _handleMesh(mesh, dataPoint, options = {}) {
          let meshDS = mesh.getSnaptrudeDS();
          if (!meshDS) return;

          let saveData = AutoSave.getSaveDataPrototype();

          saveData.commandId = this.id;
          saveData.data.identifier = AutoSave.getComponentIdentifier(meshDS);

          if (mesh.type.toLowerCase() === "floorplan") {
            saveData.data.saveType = "updateFloorPlanWM";
            saveData.data.identifier.layer_id = mesh.layer_id;
          } else if (mesh.name === "terrain") {
            saveData.data.saveType = "updateTerrainWM";
            let layerName = mesh.layerName;

            let layerData = StructureCollection.getInstance()
              .getStructureById(meshDS.structure_id)
              .getStoreyData()
              .getStoreyByValue(mesh.storey).layerData;
            let layer = layerData.getLayerByName(layerName, mesh.storey)
            saveData.data.identifier.id = layer.id;

            if(dataPoint.rotationChange){ // no operation for rotate to change parameter in current layer
              if (mesh.rotationQuaternion)
              layer.terrain[0].parameters.rotationQuaternion = mesh.rotationQuaternion.asArray();
            else
              layer.terrain[0].parameters.rotationQuaternion = getRotationQuaternion(
                mesh.rotation
              ).asArray();
            }
            // saveData.data.identifier.layerName = meshDS.layerName;
            // saveData.data.afterOperationData.uniqueId = mesh.uniqueId;
          } else if(mesh.type.includes("pdf")){
            saveData.data.saveType = "updatePdfWM";
            let layerId = mesh.layer_id;

            let layerData = StructureCollection.getInstance()
              .getStructureById(meshDS.structure_id)
              .getStoreyData()
              .getStoreyByValue(mesh.storey).layerData;
            let layer = layerData.getLayerBylayerId(layerId);
            saveData.data.identifier.id = layer?.id;

            if(dataPoint.rotationChange){ // no operation for rotate to change parameter in current layer
              if (mesh.rotationQuaternion)
                layer.pdfs[0].rotationQuaternion = mesh.rotationQuaternion.asArray();
              else
                layer.pdfs[0].rotationQuaternion = getRotationQuaternion(
                  mesh.rotation
                ).asArray();
            }
          }
          else {
            saveData.data.saveType = "updateWM";
          }

          let dataBefore = {};
          let dataAfter = {};

          if (dataPoint.positionChange) {
            dataAfter.position = mesh.absolutePosition.asArray();
            dataBefore.position = mesh.absolutePosition
              .subtract(dataPoint.positionChange)
              .asArray();
          }
          if (dataPoint.rotationChange) {
            // dataAfter.rotation = mesh.rotation.asArray();
            if (mesh.rotationQuaternion)
              dataAfter.rotationQuaternion = mesh.absoluteRotationQuaternion.asArray();
            else
              dataAfter.rotationQuaternion = getRotationQuaternion(
                mesh.rotation
              ).asArray();

            // dataBefore.rotation = mesh.rotation.subtract(dataPoint.rotationChange).asArray();
            if (dataPoint.rotationQuaternionBefore)
              dataBefore.rotationQuaternion =
                dataPoint.rotationQuaternionBefore.asArray();
            else
              dataBefore.rotationQuaternion = getRotationQuaternion(
                dataPoint.rotationBefore
              ).asArray();
          }
          if (dataPoint.scaleChange) {
            dataAfter.scaling = mesh.absoluteScaling.asArray();
            dataBefore.scaling = mesh.scaling
              .subtract(dataPoint.scaleChange)
              .asArray();
          }

          // Overriding data for support floor
          if (meshDS && meshDS.isSupportFloor && meshDS.isSupportFloor()) {
            if (dataPoint.positionChange) {
                dataAfter.position = mesh.absolutePosition.asArray();
                dataBefore.position = mesh.absolutePosition.subtract(dataPoint.positionChange).asArray();
            }
            if (dataPoint.scaleBefore) {
                dataAfter.scaling = mesh.absoluteScaling.asArray();
                dataBefore.scaling = mesh.absoluteScaling.subtract(dataPoint.scaleChange).asArray();
            }
          }

          saveData.data.afterOperationData = {
            data: dataAfter,
            storey: dataPoint.storeyAfter,
            edited: dataPoint.editedAfter,
          };

          saveData.data.beforeOperationData = {
            data: dataBefore,
            storey: dataPoint.storeyBefore,
            edited: dataPoint.editedBefore,
          };

          if (dataPoint.revitMetaData) dataPoint.revitMetaData.isModified = true;
          saveData.data.operationData = {
            isModified: dataPoint?.revitMetaData?.isModified
          };

          saveDataCollective.push(saveData);
        }

        function _handleCADMesh(mesh, dataPoint){

          let saveData = AutoSave.getSaveDataPrototype();
          saveData.data.saveType = "rotateCAD";

          let layerData = StructureCollection.getInstance()
            .getStructureById(store.activeLayer.structure_id)?.getStoreyData()
            .getStoreyByValue(mesh.storey).layerData;

          let layer = layerData?.getLayerByName(mesh.layerName, mesh.storey);

          saveData.data.identifier = {
            structure_id: store.activeLayer.structure_id,
            storey: mesh.storey,
            layerId: layer.id,
            floorkey: store.floorkey,
          };

          let dataBefore = {
            rotationQuaternion: dataPoint.rotationQuaternionBefore.asArray()
          };

          let dataAfter = {
            rotationQuaternion: dataPoint.rotationQuaternionAfter.asArray()
          };

          saveData.data.beforeOperationData = dataBefore;
          saveData.data.afterOperationData = dataAfter;

          saveDataCollective.push(saveData);
        }

        _handleMesh = _handleMesh.bind(this);

        this.data.forEach((dataPoint) => {
          let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);

          if (dataPoint.operationOnChild) {
            let parent = mesh.parent;
            mesh.setParent(null);
            mesh.computeWorldMatrix();
            _handleMesh(mesh, dataPoint);
            mesh.setParent(parent);
          } else {
            if(mesh.type.toLowerCase().includes("cadsketch")){
              _handleCADMesh(mesh, dataPoint)
            }
            else{
              _handleMesh(mesh, dataPoint);
            }
          }

          if (!dataPoint.doNotHandleChildrenForSave) {
            mesh.getChildren().forEach(child => {
                child.setParent(null);
                child.computeWorldMatrix();

                let childDataPoint = null;
                if (dataPoint?.childrenData?.[child.uniqueId]) {
                  childDataPoint = dataPoint.childrenData[child.uniqueId];
                }
                _handleMesh(child, childDataPoint || dataPoint);
                child.getChildren().forEach(coc => {
                    if (coc.type.toLowerCase() === "floor") {
                        coc.setParent(null);
                        coc.computeWorldMatrix();

                        let grandChildDataPoint = null;
                        if (childDataPoint?.childrenData?.[coc.uniqueId]) {
                          grandChildDataPoint = childDataPoint.childrenData[coc.uniqueId];
                        }
                        _handleMesh(coc, grandChildDataPoint || childDataPoint || dataPoint);
                        coc.setParent(child);
                    }
                });
                child.setParent(mesh);
            });
          }

          /*
                  Parent child mess is because in backend,
                  even for children, absolute values are stored
                   */
        });

        return saveDataCollective;
      }

      function getCommand(name, data, options = {}) {
        let logic = {
          execute: _getExecute(options),
          unexecute: _getUnExecute(options),
        };

        return new Command(name, data, logic, getSaveData);
      }

      function executeFromSaveData(saveData){
        const identifier = saveData.identifier;
        const opData = saveData.afterOperationData;

        const meshOfInterest = store.scene.getMeshByUniqueID(identifier.component_id);

        //COLAB CHECK
        //COLAB TODO - scale and storey changes
        //COLAB TODO - handle plinth
        if (meshOfInterest){
            const meshDS = meshOfInterest.getSnaptrudeDS();

            if (meshOfInterest.parent) {
                meshOfInterest._prevParent = meshOfInterest.parent;
                meshOfInterest.setParent(null);
            }
            if (meshOfInterest.childrenComp && !_.isEmpty(meshOfInterest.childrenComp)) {
                meshOfInterest.childrenComp.forEach(m => {
                    m.setParent(null);
                })
            }
            if (opData.data.position){
                meshOfInterest.position = BABYLON.Vector3.FromArray(opData.data.position);
            }
            if (opData.data.rotation) {
                meshOfInterest.rotation = BABYLON.Vector3.FromArray(opData.data.rotation);
            }
            if (opData.data.rotationQuaternion){
                meshOfInterest.rotationQuaternion = BABYLON.Quaternion.FromArray(opData.data.rotationQuaternion);
            }
            if (opData.data.scaling){
                meshOfInterest.scaling = BABYLON.Vector3.FromArray(opData.data.scaling);
            }
            if (!opData.storey) {
                let stack = [];
                stack.push(meshDS);
                meshDS.mesh.getChildMeshes().forEach(c => {
                    stack.push(c.getSnaptrudeDS());
                })

                stack = _.compact(stack);
                stack = stack.filter(s => s.storey);
                StoreyMutation.removeObjectFromStorey(stack);
            }
            // if (data.assignStorey){
            //     StoreyMutation.assignStorey(meshDS);
            // }
            // if (data.editedBefore === false){ //undefined was going off as false for !data.editedBefore
            //     meshDS.removeAsEdited();
            // }
            if (meshOfInterest._prevParent) {
                meshOfInterest.setParent(meshOfInterest._prevParent);
                delete meshOfInterest._prevParent;
            }
            if (meshOfInterest.childrenComp && !_.isEmpty(meshOfInterest.childrenComp)) {
                meshOfInterest.childrenComp.forEach(m => {
                    m.setParent(meshOfInterest);
                })
            }
            virtualSketcher.updateWithoutGeometryEdit(meshDS);

        }
    }

      function unExecuteFromSaveData(saveData) {
        const identifier = saveData.identifier;
        const opData = saveData.beforeOperationData;
        //Operate with chosen data over here (negation etc)

        const meshOfInterest = store.scene.getMeshByUniqueID(identifier.component_id);

        //COLAB CHECK
        //COLAB TODO - scale and storey changes
        //COLAB TODO - handle plinth
        if (meshOfInterest){
            if (meshOfInterest.parent) {
                meshOfInterest._prevParent = meshOfInterest.parent;
                meshOfInterest.setParent(null);
            }
            if (meshOfInterest.childrenComp && !_.isEmpty(meshOfInterest.childrenComp)) {
                meshOfInterest.childrenComp.forEach(m => {
                    m.setParent(null);
                })
            }
            const meshDS = meshOfInterest.getSnaptrudeDS();

            if (opData.data.position){
                meshOfInterest.position = BABYLON.Vector3.FromArray(opData.data.position);
            }
            if (opData.data.rotation) {
                meshOfInterest.rotation = BABYLON.Vector3.FromArray(opData.data.rotation);
            }
            if (opData.data.rotationQuaternion){
                meshOfInterest.rotationQuaternion = BABYLON.Quaternion.FromArray(opData.data.rotationQuaternion);
            }
            if (opData.data.scaling){
                meshOfInterest.scaling = BABYLON.Vector3.FromArray(opData.data.scaling);
            }
            if (meshOfInterest._prevParent) {
                meshOfInterest.setParent(meshOfInterest._prevParent);
                delete meshOfInterest._prevParent;
            }
            if (meshOfInterest.childrenComp && !_.isEmpty(meshOfInterest.childrenComp)) {
                meshOfInterest.childrenComp.forEach(m => {
                    m.setParent(meshOfInterest);
                })
            }
            // if (data.assignStorey){
            //     StoreyMutation.assignStorey(meshDS);
            // }
            // if (data.editedBefore === false){ //undefined was going off as false for !data.editedBefore
            //     meshDS.removeAsEdited();
            // }

            virtualSketcher.updateWithoutGeometryEdit(meshDS);

        }
      }

      return {
        PARAMS,
        executeCommand,
        getCommandData,
        getCommand,
        executeFromSaveData,
        unExecuteFromSaveData,
      };
  })();

  let _creationDeletionUtil = (function () {
    function _replaceObjectInStructure(mesh, structureObject) {
      let sc = StructureCollection.getInstance();
      let structure = sc.getStructureById(mesh.structure_id);
      if (!structure) return null;

      structure.replaceObjectByUniqueId(mesh.uniqueId, structureObject);
    }

    function unSerialize() {
      function recreateSubMeshes(mesh, data) {
        if (!data) return;

        mesh.subMeshes.length = 0;

        let subMeshesMetaData = data.subMeshes;

        if (mesh.material) {
          if (mesh.material.id !== data.materialID) {
            let material = store.scene.getMultiMaterialByID(data.materialID);
            if (!material)
              material = store.scene.getMaterialByID(data.materialID);
            if (material) mesh.material = material;
          }
        }

        subMeshesMetaData.forEach(function (subMesh) {
          BABYLON.SubMesh.AddToMesh(
            subMesh.materialIndex,
            subMesh.verticesStart,
            subMesh.verticesCount,
            subMesh.indexStart,
            subMesh.indexCount,
            mesh
          );
        });
      }

      function _handleDataPoint(dataPoint) {
        let mesh;
        if (dataPoint.isAnInstance) {
          let sourceMesh = store.scene.getMeshByUniqueID(
            dataPoint.sourceMeshId
          );
          if (sourceMesh) {
            if (
              ["door", "window", "furniture"].includes(
                dataPoint.type.toLowerCase()
              )
            ) {
              let meshName =
                sourceMesh.name + "Ins" + sourceMesh.instances.length;
              mesh = sourceMesh.createInstance(meshName);
            } else {
              mesh = sourceMesh.createInstance(sourceMesh.name + "instance");
            }

            if (!dataPoint.parentId)
              mesh.position = dataPoint.myPosition.clone();

            // if (dataPoint.originalScaling) mesh.scaling = dataPoint.originalScaling.clone();
            mesh.scaling = dataPoint.scaling.clone();

            mesh.rotation = dataPoint.rotation.clone();
            if (dataPoint.rotationQuaternion) {
              mesh.rotationQuaternion = dataPoint.rotationQuaternion.clone();
            }
          }
          // else{
          //     meshesToRetry.push(dataPoint);
          // }
        } else {
          mesh = deSerializeMesh(dataPoint.serializedMesh);
          recreateSubMeshes(mesh, dataPoint.material);
        }

        if (mesh) {
          disposeMeshById(dataPoint.meshId);
          // mesh.uniqueId = dataPoint.meshId;
          meshUniqueIdMapper.update(mesh, dataPoint.meshId);
        }

        dataPoint.mesh = mesh;
        return dataPoint;
      }

      // let meshesToReTry = [];
      let data = this.data;
      data.forEach((dataPoint) => _handleDataPoint(dataPoint));
      // meshesToRetry.forEach(dataPoint => _handleDataPoint(dataPoint));

      // Reorder the data list to avoid issues in recreation of instances
      // let retryMeshesUID = [];
      // meshesToReTry.forEach(dataPoint => retryMeshesUID.push(dataPoint.meshId));
    }

    function creation() {
      function updatePropertiesFromCommandData(component, data) {
        for (let [key, value] of data) {
            if (!_.isFunction(value)) {
                if (key in component) {
                    if (key === "brep"){
                      component[key] = copyBrep(value);
                    }
                    else component[key] = value;
                }
            }
        }
      }


      function _handleDataPoint(dataPoint) {
        const onSolidOptions = {};
        let mesh = dataPoint.mesh;
        if (!mesh) {
          console.warn("Mesh for creation not found");
          return;
        }

        mesh.storey = dataPoint.storey;
        let meshTypeLC = dataPoint.type.toLowerCase();
        mesh.type = dataPoint.type;
        // mesh.material = dataPoint.material;

        mesh.room_path = dataPoint.room_path;
        mesh.room_type = dataPoint.room_type;
        mesh.room_unique_id = dataPoint.room_unique_id;
        mesh.room_id = dataPoint.room_id;

        let struct = _.fromPairs(dataPoint.struct);

        if (dataPoint.parentId && !mesh.parent){
          let parent = store.scene.getMeshByUniqueID(dataPoint.parentId);
          //Mesh in dataPoint.parent will be disposed
          if (parent) {
            mesh.setParent(parent);
            if (
              ["mass", "void", "door", "window", "floor", "furniture"].includes(
                meshTypeLC
              )
            ) {
              if (parent.childrenComp) {
                let childrenCompUIDs = parent.childrenComp.map(
                  (mesh) => mesh.uniqueId
                );
                if (!childrenCompUIDs.includes(mesh.uniqueId)) {
                  parent.childrenComp.push(mesh);
                }
              }
            }
            mesh.position = dataPoint.myPosition.clone();
            mesh.computeWorldMatrix(true);
            // onSolid(parent, false);
          }
        }

        if (meshTypeLC === "void") {
          mesh.visibility = 0.01;
          // mesh.disableEdgesRendering();
        }

        addMeshToStructure(
          mesh,
          null,
          dataPoint.level_id,
          dataPoint.structure_id
        );

        let meshDS = mesh.getSnaptrudeDS();
        if (meshDS.assignProperties) meshDS.assignProperties();

        if (meshDS.assignBackObjectProperties) {
          meshDS.assignBackObjectProperties(struct);
        } else {
          updatePropertiesFromCommandData(meshDS, dataPoint.struct);
        }

        dataPoint.children.forEach((child) => {
          onSolidOptions.forceWillHaveRedundantEdges = true;
          let childMesh = store.scene.getMeshByUniqueID(child.id);
          if (childMesh) {
            // if (!childMesh.parent){
            childMesh.setParent(mesh);
            let childrenCompUIDs = mesh.childrenComp.map(
              (mesh) => mesh.uniqueId
            );
            if (!childrenCompUIDs.includes(childMesh.uniqueId)) {
              mesh.childrenComp.push(childMesh);
            }
            childMesh.position = child.position.clone();
            // }
          }
        });

        if (struct.autoInterior) {
          mesh.scaling = dataPoint.scaling.clone();
        }
        mesh.type = dataPoint.type;

        meshDS = mesh.getSnaptrudeDS();

        if (dataPoint.isAnInstance) {
          let sourceDS = mesh.sourceMesh.getSnaptrudeDS();
          meshDS.brep = sourceDS.brep;
          meshDS.faceFacetMapping = sourceDS.faceFacetMapping;
        }

        if (dataPoint.linkedListUpdateData) {
          let llData = dataPoint.linkedListUpdateData;
          let structure = StructureCollection.getInstance().getStructureById(
            meshDS.structure_id
          );
          let linkedListCluster = structure.getLinkedListCluster();

          linkedListCluster.removeLinkedList(meshDS.linkedListId);
          let dll = linkedListCluster.addNewLinkedList(meshDS.linkedListId);

          llData[dll.id].forEach((node) => {
            dll.append(node);
          });
        }

        if (meshDS.brep) {
          if (mesh.isAnInstance) {
            onSolid(mesh, false, onSolidOptions);
          } else {
            mesh.BrepToMesh();
          }
        } else {
          onSolid(mesh, false, onSolidOptions);
        }

        if (meshDS.setPlanViewSymbol) {
          meshDS.setPlanViewSymbol();
        }
        virtualSketcher.addWithoutGeometryEdit(meshDS);
        setLayerTransperancy(mesh, false, false);
      }

      let data = this.data;
      data.forEach((dataPoint) => _handleDataPoint(dataPoint));

      updateRoofAccordion(true);
    }

    function deletion(options) {
      function _handleDataPoint(dataPoint) {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (!mesh) {
          try {
            let meshDS = meshObjectMapping.getObjectByUniqueId(
              dataPoint.meshId
            );

            // TO DO: This needs to be removed for cases where a mass is drawn on the Plinth.
            removeComponentFromStructure(meshDS);
            return;
          } catch (error) {
            return;
          }
        }
        if (!mesh) return;
        if (mesh.children)
          mesh.children.forEach((child) => {
            if (child) child.dispose();
          });

        if (mesh?.childrenComp?.length > 0) {
          mesh.childrenComp.forEach(child => {
            if (child.children)
              child.children.forEach((child) => {
                if (child) child.dispose();
              });
            if (child?.childrenComp?.length > 0) {
              child.childrenComp.forEach(gc => {
                if (gc.children)
                  gc.children.forEach(c => {
                    if (c) c.dispose();
                  })
              })
            }
          })
        }

        if (options.preserveChildren) {
          mesh.getChildren().forEach((child) => {
            child.setParent(null);
          });
        }

        if (mesh.parent) {
          if (_.isArray(mesh.parent.childrenComp)) {
            _.remove(mesh.parent.childrenComp, (child) => {
              return child.uniqueId === mesh.uniqueId;
            });
          }

          if (mesh.updateCSGInfo) {
            let optionsForCSG = {
              reScale: mesh.parent.scaling.asArray(),
              rePosition: mesh.parent.position.asArray(),
            };
            let updateCommands = updateCSG(mesh, optionsForCSG);

            if (updateCommands) {
              deletionOperations.addCreationCommand(
                ...updateCommands.creationCommands
              );
              deletionOperations.addDeletionCommand(
                ...updateCommands.deletionCommands
              );
              deletionOperations.addDllCommand(...updateCommands.dllCommands);
              deletionOperations.addOtherCommand(...updateCommands.otherCommands);
            }
          }
        }

        const meshDS = mesh.getSnaptrudeDS();

        virtualSketcher.removeWithoutGeometryEdit(meshDS);
        mesh.dispose();
        removeComponentFromStructure(meshDS);
      }

      if (!options) {
        options = { preserveChildren: true };
      }

      let data = this.data;
      
      if (this.rearrangeComponents){
        // this flag changes the order of deletion such that instances are deleted first and sourceMesh later
        data = [...data].sort((dataPoint1, dataPoint2) => {
          const mesh1 = store.scene.getMeshByUniqueID(dataPoint1.meshId);
          const mesh2 = store.scene.getMeshByUniqueID(dataPoint2.meshId);
          
          if (mesh1.isAnInstance && mesh2.isAnInstance) return 0;
          else if (mesh1.isAnInstance) {
            if (mesh2 === mesh1.sourceMesh) return -1;
            else return 0;
          }
          else if (mesh2.isAnInstance) {
            if (mesh1 === mesh2.sourceMesh) return 1;
            else return 0;
          }
          else return 0;
        });
      }
      
      data.forEach((dataPoint) => _handleDataPoint(dataPoint));

      DisplayOperation.removeDimensions();
      updateRoofAccordion(true);
    }

    function getCreationSaveData() {
      let saveDataCollective = [];

      this.data.forEach((dataPoint) => {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = this.id;
        saveData.data.saveType = "creation";

        let dataAfter = {};
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        let meshDS = mesh.getSnaptrudeDS();
        saveData.data.identifier = AutoSave.getComponentIdentifier(meshDS);

        const changelog = CostCalculator.masterCalculate(meshDS);
        saveData.data.change = {
          cost: changelog.cost,
          identifier: changelog.identifier,
          operation: 'creation',
          isRevitImport: changelog?.revitImport
        };
        dataAfter = dataPoint.serializedComponent;

        delete dataPoint.serializedComponent;

        saveData.data.operationData = dataAfter;

        saveDataCollective.push(saveData);
      });

      return saveDataCollective;
    }

    function getDeletionSaveData() {
      let saveDataCollective = [];

      this.data.forEach((dataPoint) => {
        let saveData = AutoSave.getSaveDataPrototype();
        saveData.commandId = this.id;
        saveData.data.saveType = "deletion";

        let dataBefore = {};
        let struct = _.fromPairs(dataPoint.struct);
        struct.mesh = {};
        struct.mesh.uniqueId = dataPoint.meshId;
        saveData.data.identifier = AutoSave.getComponentIdentifier(struct);

        const changelog = CostCalculator.masterCalculate(struct, false);
        let cost = null;
        if(changelog.cost != null){
          if(changelog?.revitImport){
            cost = changelog.cost.map(cost => -cost);
          }else{
            cost = -changelog.cost;
          }
        }
        saveData.data.change = {
          cost:  cost,
          identifier: changelog.identifier,
          operation: 'deletion',
          isRevitImport: changelog?.revitImport
        }
        dataBefore = dataPoint.serializedComponent;

        delete dataPoint.serializedComponent;

        saveData.data.operationData = dataBefore;

        saveDataCollective.push(saveData);
      });

      return saveDataCollective;
    }

    function getCommandData(stack, data, options) {
      function _getDataPoint(mesh, dataPoint) {
        let meshDS = mesh.getSnaptrudeDS();
        if (!meshDS) return null;
        if (!dataPoint) dataPoint = {};

        dataPoint.storey = mesh.storey;
        dataPoint.structure_id = mesh.structure_id;
        dataPoint.level_id = meshDS.level_id;
        dataPoint.type = mesh.type;

        dataPoint.myPosition = mesh.position.clone(); //works when mesh is a child
        dataPoint.material = mesh.material;
        dataPoint.meshId = mesh.uniqueId;

        dataPoint.room_type = mesh.room_type;
        dataPoint.room_path = mesh.room_path;
        dataPoint.room_unique_id = mesh.room_unique_id;
        dataPoint.room_id = mesh.room_id;

        let subMeshes = Object.assign([], mesh.subMeshes);
        dataPoint.material = {};
        if (mesh.material) dataPoint.material.materialID = mesh.material.id;
        dataPoint.material.subMeshes = [];

        subMeshes.forEach(function (subMesh) {
          let subMeshMetaData = {};
          subMeshMetaData.materialIndex = subMesh.materialIndex;
          subMeshMetaData.verticesStart = subMesh.verticesStart;
          subMeshMetaData.verticesCount = subMesh.verticesCount;
          subMeshMetaData.indexStart = subMesh.indexStart;
          subMeshMetaData.indexCount = subMesh.indexCount;
          dataPoint.material.subMeshes.push(subMeshMetaData);
        });

        dataPoint.struct = _.toPairsIn(meshDS);
        if(meshDS.properties){
          _.remove(dataPoint.struct, kvPair => (kvPair[0] === "mesh" || kvPair[0] === "properties"));
          dataPoint.struct.push(["properties", _.cloneDeep(meshDS.properties)]);
        } else{
          _.remove(dataPoint.struct, kvPair => kvPair[0] === "mesh");
        }
        
        const i = _.findIndex(dataPoint.struct, kvPair => kvPair[0] === "brep");

        // Conditional check as entities like Furniture don't have a brep
        // User issue #85
        if(i != -1) dataPoint.struct[i][1] = copyBrep(dataPoint.struct[i][1]);

        if (mesh.parent) dataPoint.parentId = mesh.parent.uniqueId;

        let sourceMesh = mesh.sourceMesh || mesh;

        dataPoint.serializedComponent = jQuery.extend(
          {},
          StructureCollection.getInstance().getSerializedComponent(meshDS)
        );

        const collaboration = dataPoint.serializedComponent.collaboration = {};
        collaboration.parentId = dataPoint.parentId;
        collaboration.children = [];
        collaboration.myPosition = dataPoint.myPosition.asArray();

        if (options.handleLinkedList && meshDS.linkedListId) {
          let linkedListUpdateData;
          let structure = StructureCollection.getInstance().getStructureById(
            meshDS.structure_id
          );
          let linkedListCluster = structure.getLinkedListCluster();

          let dll = linkedListCluster.getLinkedList(meshDS.linkedListId);

          if (dll) {
            linkedListUpdateData = dll.serialize();
            dataPoint.serializedComponent.linkedListUpdateData =
              linkedListUpdateData;
            dataPoint.linkedListUpdateData = linkedListUpdateData;
          }
        }

        if (mesh.isAnInstance) {
          dataPoint.isAnInstance = mesh.isAnInstance;
          dataPoint.sourceMeshId = sourceMesh.uniqueId;
          if (sourceMesh.originalScaling) {
            dataPoint.originalScaling = sourceMesh.originalScaling.clone();
          }

          if (Floor.isSupportFloor(mesh)) {
            dataPoint.rotation =
              mesh.absoluteRotationQuaternion.toEulerAngles();
          } else {
            dataPoint.rotation = mesh.rotation?.clone();
          }

          if (Mass.isPlinth(mesh)) {
            dataPoint.scaling = mesh.absoluteScaling?.clone();
            dataPoint.rotationQuaternion = mesh.absoluteRotationQuaternion?.clone();
          } else {
            dataPoint.scaling = mesh.absoluteScaling?.clone();
            dataPoint.rotationQuaternion = mesh.rotationQuaternion?.clone();
          }
        } else {
          // meshDS.brep = copyBrep(meshDS.brep);
          dataPoint.serializedMesh = jQuery.extend(
            {},
            dataPoint.serializedComponent
          );
        }

        if (!_.isEmpty(mesh.instances)) {
          // dataPoint.instances = mesh.instances.map(m => m.uniqueId);
        }

        dataPoint.children = [];
        mesh.getChildren().forEach((child) => {
          if (!options.preserveChildren && !isMeshThrowAway(child))
            extraMeshes.push(child);
          dataPoint.children.push({
            id: child.uniqueId,
            position: child.position.clone(),
          });
          // children meta-data to be consumed by collaboration events
          const mChildren = collaboration.children;
          mChildren.push({
              id: child.uniqueId,
              position: child.position.asArray()
          });

          if(mesh?.replacedObject) child.replacedObject = true
        });

        if (dataPoint.serializedMesh && dataPoint.children.length > 0) {
          if (
            dataPoint.serializedMesh.meshes[0].childrenComp &&
            dataPoint.serializedMesh.meshes[0].childrenComp.length === 0
          ) {
            dataPoint.children.forEach((d) => {
              dataPoint.serializedMesh.meshes[0].childrenComp.push(d.id);
            });
          }
        }

        return dataPoint;
      }

      if (!data) data = [];
      if (!options) {
        options = { preserveChildren: true };
      }
      if (!_.isArray(stack)) stack = [stack];

      let extraMeshes = [];
      stack.forEach((mesh) => data.push(_getDataPoint(mesh)));

      let extraMeshesLength = extraMeshes.length;
      extraMeshes.forEach((mesh) => data.push(_getDataPoint(mesh)));

      extraMeshes.splice(0, extraMeshesLength);
      extraMeshes.forEach((mesh) => data.push(_getDataPoint(mesh)));
      return _.compact(data); //removes falsey values;
    }

    function parseSaveToCommand(opData, identifier) {
      let pseudoCommand = {
          data: [{}]
      };
      const data = pseudoCommand.data[0];

      const meshData = opData.meshes[0];
      const dsProps = opData.dsProps;
      const collaboration = opData.collaboration;

      if (opData.geometries) {
          data.serializedMesh = opData;
      } else {
          data.isAnInstance = true;
          data.sourceMeshId = meshData.uniqueIdSource;
          data.scaling = meshData.scaling ?
              BABYLON.Vector3.FromArray(meshData.scaling) : undefined;
          data.rotation = meshData.rotation ?
              BABYLON.Vector3.FromArray(meshData.rotation) : undefined;
          data.rotationQuaternion = meshData.rotationQuaternion ?
              BABYLON.Quaternion.FromArray(meshData.rotationQuaternion) : undefined;
      }
      data.level_id = identifier.level_id;
      data.material = {
          materialID: meshData.materialId,
          subMeshes: meshData.subMeshes
      }
      data.meshId = identifier.component_id;
      data.myPosition = BABYLON.Vector3.FromArray(collaboration.myPosition);
      data.room_id = meshData.room_id;
      data.room_path = meshData.room_path;
      data.room_type = meshData.room_type;
      data.room_unique_id = meshData.room_unique_id;
      data.storey = meshData.storey;

      if (dsProps.brep) {
          dsProps.brep = store.resurrect.resurrect(dsProps.brep);
      }
      data.parentId = collaboration.parentId;

      data.children = [];
      const mChildren = collaboration.children;
      mChildren.forEach(mC => {
          data.children.push({
              id: mC.id,
              position: BABYLON.Vector3.FromArray(mC.position)
          })
      })

      data.struct = _.toPairsIn(dsProps);
      data.structure_id = identifier.structure_id;
      data.type = meshData.type;

      return pseudoCommand;
    }

    return {
      creation,
      deletion,
      unSerialize,
      getCommandData,
      getCreationSaveData,
      getDeletionSaveData,
      parseSaveToCommand,
    };
  })();

  let creationOperations = (function () {
    function _execute() {
      _creationDeletionUtil.creation.call(this);
    }

    function _unExecute(options) {
      _creationDeletionUtil.deletion.call(this, options);
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this, options);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      if (!_.isFunction(preCallback))
        preCallback = _creationDeletionUtil.unSerialize;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this, options);
        if (postCallback) postCallback.call(this);
      };
    }

    function getCommandData(stack, data, options) {
      return _creationDeletionUtil.getCommandData(stack, data, options);
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);
      CommandManager.execute(command, false);
    }

    function getCommand(name, data, options) {
      if (!options) options = {};
      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };
      let command = new Command(
        name,
        data,
        logic,
        _creationDeletionUtil.getCreationSaveData
      );

      return command;
    }

    function executeFromSaveData(saveData) {
      const identifier = saveData.identifier;
      const operationData = saveData.operationData;

      const command = _creationDeletionUtil.parseSaveToCommand(operationData, identifier);
      _creationDeletionUtil.unSerialize.call(command);
      _execute.call(command);
    }

    function unExecuteFromSaveData(saveData) {
      const identifier = saveData.identifier;
      const operationData = saveData.operationData;

      const command = _creationDeletionUtil.parseSaveToCommand(operationData, identifier);
      _unExecute.call(command);
    }

    return {
      executeCommand,
      getCommandData,
      getCommand,
      executeFromSaveData,
      unExecuteFromSaveData,
    };
  })();

  let deletionOperations = (function () {
    let secondaryCommands = {
      creationCommands: [],
      deletionCommands: [],
      parametricCommands: [],
      dllCommands: [],
      otherCommands: [],
    };

    function addCreationCommand(command) {
      secondaryCommands.creationCommands.push(command);
    }

    function addDeletionCommand(command) {
      secondaryCommands.deletionCommands.push(command);
    }

    function addDllCommand(command) {
      secondaryCommands.dllCommands.push(command);
    }

    function addOtherCommand(command) {
      secondaryCommands.otherCommands.push(command);
    }

    function _unExecute() {
      _creationDeletionUtil.creation.call(this);
    }

    function _execute(options) {
      _creationDeletionUtil.deletion.call(this, options);
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this, options);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      if (!_.isFunction(preCallback))
        preCallback = _creationDeletionUtil.unSerialize;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function getCommandData(stack, data, options) {
      return _creationDeletionUtil.getCommandData(stack, data, options);
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);
      CommandManager.execute(command, true);
    }

    function flushSecondaryCommandInformation() {
      secondaryCommands = {
        creationCommands: [],
        deletionCommands: [],
        parametricCommands: [],
        dllCommands: [],
        otherCommands: [],
      };
    }

    function halfAssExecuteCommand(name, data, components, options) {
      let command = getCommand(name, data, options);

      secondaryCommands.parametricCommands.push(
        virtualSketcher.removeWithGeometryEdit(components)
      );

      command.execute();

      // this is a half ass-ed execution basically.
      // only when command is executed, secondary (updateCSG, graphUpdate) commands are generated. So that is done
      // but it's not recorded by CommandManager. That happens in keyEvents

      return {
        command,
        secondaryCommands,
      };
    }

    function getCommand(name, data, options) {
      if (!options) options = {};
      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };
      let command = new Command(
        name,
        data,
        logic,
        _creationDeletionUtil.getDeletionSaveData
      );

      return command;
    }

    function executeFromSaveData(saveData) {
      const identifier = saveData.identifier;
      const operationData = saveData.operationData;

      const command = _creationDeletionUtil.parseSaveToCommand(operationData, identifier);
      _execute.call(command);
    }

    function unExecuteFromSaveData(saveData) {
      const identifier = saveData.identifier;
      const operationData = saveData.operationData;

      const command = _creationDeletionUtil.parseSaveToCommand(operationData, identifier);
      _creationDeletionUtil.unSerialize.call(command);
      _unExecute.call(command);
    }

    return {
      executeCommand,
      halfAssExecuteCommand,
      flushSecondaryCommandInformation,
      getCommandData,
      getCommand,
      addCreationCommand,
      addDeletionCommand,
      addDllCommand,
      addOtherCommand,
      executeFromSaveData,
      unExecuteFromSaveData,
    };
  })();

  let propertyChangeOperations = (function () {
    function _execute() {
      function _handleDataPoint(data) {
        let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
        if (meshOfInterest) {
          let component = meshOfInterest.getSnaptrudeDS();
          _.forEach(data.afterKeyValuesMesh, (value, key) => {
            if (key === "type") {
              if (isThrowAwayIdentifier(value)) {
                markMeshAsThrowAway(meshOfInterest, true);
              } else if (isThrowAwayIdentifier(data.beforeKeyValuesMesh[key])) {
                removeMeshThrowAwayIdentifier(meshOfInterest, true);
              } else {
                meshOfInterest[key] = deepCopyObject(value);
              }
            } else {
              meshOfInterest[key] = deepCopyObject(value);
            }
          });

          _.forEach(data.afterKeyValuesComponent, (value, key) => {
            component[key] = deepCopyObject(value);
          });
          if(meshOfInterest.type.toLowerCase() === "wall"){
            let mat = materialLoader.loadWallMaterial(component.properties.wallMaterialType);
            if(mat) {
              if (!meshOfInterest.isAnInstance) meshOfInterest.material = mat;
            }
          }
          if (store.scene._activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA) onSolid(meshOfInterest);
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _unExecute() {
      function _handleDataPoint(data) {
        let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
        if (meshOfInterest) {
          let component = meshOfInterest.getSnaptrudeDS();
          _.forEach(data.beforeKeyValuesMesh, (value, key) => {
            if (key === "type") {
              if (isThrowAwayIdentifier(value)) {
                markMeshAsThrowAway(meshOfInterest, true);
              } else if (isThrowAwayIdentifier(data.afterKeyValuesMesh[key])) {
                removeMeshThrowAwayIdentifier(meshOfInterest, true);
              } else {
                meshOfInterest[key] = deepCopyObject(value);
              }
            } else {
              meshOfInterest[key] = deepCopyObject(value);
            }
          });

          _.forEach(data.beforeKeyValuesComponent, (value, key) => {
            component[key] = deepCopyObject(value);
          });
          if (store.scene._activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA) onSolid(meshOfInterest);
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this, options);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getSaveData() {
      let saveDataCollective = [];

      this.data.forEach((dataPoint) => {
        let saveData = AutoSave.getSaveDataPrototype();
        let _mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);

        if (_mesh && _mesh.name.includes("terrain")) {
          let structure =
            StructureCollection.getInstance().getStructures()[
              dataPoint.identifier.structure_id
            ];
          let layer = structure
            .getStoreyData()
            .getStoreyByValue(dataPoint.identifier.storey)
            .layerData.getLayerByName(
              _mesh.layerName,
              dataPoint.identifier.storey
            );
          let layerId = layer.id;

          saveData.data.saveType = "hideLayer";
          if (!dataPoint.beforeKeyValuesMesh.isVisible) {
            saveData.data.saveType = "showLayer";
          }

          saveData.data.identifier = {
            structure_id: dataPoint.identifier.structure_id,
            storey: dataPoint.identifier.storey,
            layerName: _mesh.layerName,
            layerId: layerId,
            floorkey: store.floorkey,
          };

          delete dataPoint.identifier;
          saveDataCollective.push(saveData);
        } else {
          saveData.commandId = this.id;
          saveData.data.saveType = "propertyChange";

          let dataBefore = {};
          let dataAfter = {};

          dataBefore.meshKeyValues = dataPoint.beforeKeyValuesMesh;
          dataBefore.meshCompoundKeyValues =
            dataPoint.beforeCompoundKeyValuesMesh;
          dataBefore.componentKeyValues = dataPoint.beforeKeyValuesComponent;
          dataBefore.meshId = dataPoint.meshId;
          dataAfter.meshKeyValues = dataPoint.afterKeyValuesMesh;
          dataAfter.meshCompoundKeyValues =
            dataPoint.afterCompoundKeyValuesMesh;
          dataAfter.componentKeyValues = dataPoint.afterKeyValuesComponent;
          dataAfter.meshId = dataPoint.meshId;

          saveData.data.identifier = dataPoint.identifier;

          saveData.data.afterOperationData = dataAfter;
          saveData.data.beforeOperationData = dataBefore;

          delete dataPoint.identifier;
          saveDataCollective.push(saveData);
        }
      });

      return saveDataCollective;
    }

    function _parseSaveToCommand(saveData) {
      const afterData = saveData.afterOperationData;
      const beforeData = saveData.beforeOperationData;
      const pseudoCommand = { data: [{}] };
      const dataPoint = pseudoCommand.data[0];

      dataPoint.meshId = afterData.meshId;
      dataPoint.afterKeyValuesMesh = afterData.meshKeyValues;
      dataPoint.afterCompoundKeyValuesMesh = afterData.meshCompoundKeyValues;
      dataPoint.afterKeyValuesComponent = afterData.componentKeyValues;
      dataPoint.beforeKeyValuesMesh = beforeData.meshKeyValues;
      dataPoint.beforeCompoundKeyValuesMesh = beforeData.meshCompoundKeyValues;
      dataPoint.beforeKeyValuesComponent = beforeData.componentKeyValues;

      return pseudoCommand;
    }

    function getCommandData(stack, options) {
      if (!options.data) {
        function _handleMesh(mesh) {
          let dataPoint = {};
          dataPoint.meshId = mesh.uniqueId;
          dataPoint.beforeKeyValuesMesh = {};
          dataPoint.beforeCompoundKeyValuesMesh = {};
          dataPoint.beforeKeyValuesComponent = {};

          options.meshKeys.forEach((key) => {
            dataPoint.beforeKeyValuesMesh[key] = deepCopyObject(mesh[key]);
          });

          options.meshCompoundKeys.forEach((dataObject) => {
            const key = Object.keys(dataObject)[0];
            const lookupKeys = dataObject[key];

            let value = mesh;
            lookupKeys.forEach((k) => {
              value = value[k];
            });

            dataPoint.beforeCompoundKeyValuesMesh[key] = deepCopyObject(value);
          });

          let component = mesh.getSnaptrudeDS();
          if (!component) {
            if(isCADMesh(mesh)){
              let _layerName = mesh.layerName;
              let _structureId = store.activeLayer.structure_id;
              let _structure = StructureCollection.getInstance().getStructureById(_structureId);
              let _storeyCollection = _structure.getStoreyData();
              let _storey = _storeyCollection.getStoreyByValue(mesh.storey);
              let _layerData = _storey.layerData;
              let _layer = _layerData.getLayerByName(_layerName, mesh.storey);
              let _layerId = _layer?.id;

              dataPoint.identifier = {
                type: mesh.type.toLowerCase(),
                floorkey: store.floorkey,
                structure_id: _structureId,
                storey: mesh.storey,
                layer_id: _layerId
              }
            }
            else{
              console.warn('No component for property change');
              console.warn(mesh.uniqueId);
              return;
            }
          }
          else{
            options.componentKeys.forEach((key) => {
              dataPoint.beforeKeyValuesComponent[key] = deepCopyObject(
                component[key]
              );
            });

            if(["floorplan", "pdf"].includes(component.type.toLowerCase() )){
              dataPoint.identifier = {
                type: component.type.toLowerCase(),
                floorkey: store.floorkey,
                structure_id: component.structure_id,
                storey: component.storey,
                layer_id: component.mesh?.layer_id
              }
            }

            else{
              dataPoint.identifier = AutoSave.getComponentIdentifier(component);
            }
          }

          data.push(dataPoint);

          if (options.handleChildren) {
            if (mesh.childrenComp) {
              mesh.childrenComp.forEach((m) => {
                _handleMesh(m);
              });
            }
          }
        }

        let data = [];
        if (!_.isArray(stack)) stack = [stack];
        if (!options.meshKeys) options.meshKeys = [];
        if (!options.componentKeys) options.componentKeys = [];
        if (!options.meshCompoundKeys) options.meshCompoundKeys = [];

        stack.forEach(_handleMesh);

        return data;
      } else {
        function _handleDataPoint(dataPoint) {
          let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
          let component = mesh.getSnaptrudeDS();
          dataPoint.afterKeyValuesMesh = {};
          dataPoint.afterCompoundKeyValuesMesh = {};
          dataPoint.afterKeyValuesComponent = {};

          _.forEach(dataPoint.beforeKeyValuesMesh, (value, key) => {
            dataPoint.afterKeyValuesMesh[key] = deepCopyObject(mesh[key]);
          });

          _.forEach(dataPoint.beforeKeyValuesComponent, (value, key) => {
            dataPoint.afterKeyValuesComponent[key] = deepCopyObject(
              component[key]
            );
          });

          options.meshCompoundKeys.forEach((dataObject) => {
            const key = Object.keys(dataObject)[0];
            const lookupKeys = dataObject[key];

            let value = mesh;
            lookupKeys.forEach((k) => {
              value = value[k];
            });

            dataPoint.afterCompoundKeyValuesMesh[key] = deepCopyObject(value);
          });
        }

        let data = options.data;
        if (!options.meshCompoundKeys) options.meshCompoundKeys = [];

        data.forEach(_handleDataPoint);

        return data;
      }
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);
      CommandManager.execute(command, false);
    }

    function getCommand(name, data, options) {
      if (!options) options = {};

      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };

      let command = new Command(name, data, logic, _getSaveData);

      return command;
    }

    function executeFromSaveData(saveData) {
      const command = _parseSaveToCommand(saveData);
      _execute.call(command);
    }

    function unExecuteFromSaveData(saveData) {
      const command = _parseSaveToCommand(saveData);
      _unExecute.call(command);
    }

    return {
      getCommand,
      getCommandData,
      executeCommand,
      executeFromSaveData,
      unExecuteFromSaveData,
    };
  })();

  let parentChildOperations = (function () {
    function changeRelationships(mesh, parentBefore, parentAfter) {
      if (parentBefore) {
        if (_.isArray(parentBefore.childrenComp)) {
          _.remove(
            parentBefore.childrenComp,
            (m) => m.uniqueId === mesh.uniqueId
          );
        }
      }

      if (parentAfter) {
        mesh.setParent(parentAfter);
        if (_.isArray(parentAfter.childrenComp)) {
          parentAfter.childrenComp.push(mesh);
        }
      } else {
        mesh.setParent(null);
      }
    }

    function _execute() {
      function _handleDataPoint(data) {
        let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
        if (meshOfInterest) {
          let parentAfter = store.scene.getMeshByUniqueID(data.parentAfter);
          changeRelationships(
            meshOfInterest,
            meshOfInterest.parent,
            parentAfter
          );
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _unExecute() {
      function _handleDataPoint(data) {
        let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
        if (meshOfInterest) {
          let parentBefore = store.scene.getMeshByUniqueID(data.parentBefore);
          changeRelationships(
            meshOfInterest,
            meshOfInterest.parent,
            parentBefore
          );
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this, options);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getSaveData() {
      let saveDataCollective = [];

      this.data.forEach((dataPoint) => {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = this.id;
        saveData.data.saveType = "parentChildChange";
        saveData.data.identifier.floorkey = store.floorkey;

        let parentBefore, parentAfter;
        let dataBefore = {},
          dataAfter = {};

        if (dataPoint.parentBefore) {
          parentBefore = store.scene.getMeshByUniqueID(dataPoint.parentBefore);
          dataBefore.parentIdentifier = AutoSave.getComponentIdentifier(
            parentBefore.getSnaptrudeDS()
          );
          dataBefore.parentIdentifier.componentClass =
            AutoSave.getComponentClass(dataBefore.parentIdentifier.type);
          dataBefore.childId = dataPoint.meshId;
        }
        if (dataPoint.parentAfter) {
          parentAfter = store.scene.getMeshByUniqueID(dataPoint.parentAfter);
          dataAfter.parentIdentifier = AutoSave.getComponentIdentifier(
            parentAfter.getSnaptrudeDS()
          );
          dataAfter.parentIdentifier.componentClass =
            AutoSave.getComponentClass(dataAfter.parentIdentifier.type);
          dataAfter.childId = dataPoint.meshId;
        }

        saveData.data.afterOperationData = dataAfter;
        saveData.data.beforeOperationData = dataBefore;

        saveDataCollective.push(saveData);
      });

      return saveDataCollective;
    }

    function getCommandData(stack, options = {}) {
      if (!options.data) {
        function _handleMesh(mesh) {
          let dataPoint = {};
          dataPoint.meshId = mesh.uniqueId;

          if (mesh.parent) {
            dataPoint.parentBefore = mesh.parent.uniqueId;
          }
          data.push(dataPoint);
        }

        let data = [];
        if (!_.isArray(stack)) stack = [stack];

        stack.forEach(_handleMesh);

        return data;
      } else {
        function _handleDataPoint(dataPoint) {
          let meshParent = store.scene.getMeshByUniqueID(
            dataPoint.meshId
          ).parent;
          if (meshParent) {
            dataPoint.parentAfter = meshParent.uniqueId;
          }
        }

        let data = options.data;
        data.forEach(_handleDataPoint);

        return data;
      }
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);
      CommandManager.execute(command, false);
    }

    function getCommand(name, data, options) {
      if (!options) options = {};

      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };

      let command = new Command(name, data, logic, _getSaveData);

      return command;
    }

    function expressCheckout(name, stack, potentialNewParentStack) {
      if (!_.isArray(stack)) stack = [stack];
      if (!_.isArray(potentialNewParentStack)) {
        const commonNewParent = potentialNewParentStack;
        potentialNewParentStack = stack.map(m => commonNewParent);
      }

      let parentChangeCommandData = getCommandData(stack);
      stack.forEach((mesh, i) => {
        const potentialNewParent = potentialNewParentStack[i];
        commandUtils.parentChildOperations.changeRelationships(
          mesh,
          mesh.parent,
          potentialNewParent
        );
      });
      parentChangeCommandData = getCommandData(stack, {
        data: parentChangeCommandData,
      });

      return getCommand(name, parentChangeCommandData);
    }

    function parseSaveToCommand(saveData) {
      const pseudoCommand = {
          data: [{}]
      }

      const data = pseudoCommand.data[0];
      const afterData = saveData.afterOperationData;
      const beforeData = saveData.beforeOperationData;

      data.meshId = afterData.childId;

      if (afterData.parentIdentifier) {
          data.parentAfter = afterData.parentIdentifier.component_id;
      }

      if (beforeData.parentIdentifier) {
          data.parentBefore = beforeData.parentIdentifier.component_id;
      }

      return pseudoCommand;
    }

    function executeFromSaveData(saveData) {
      const opData = saveData.afterOperationData;

      const childId = saveData.beforeOperationData.childId || saveData.afterOperationData.childId;
      const mesh = store.scene.getMeshByUniqueID(childId);
      
      const parentAfter = store.scene.getMeshByUniqueID(opData?.parentIdentifier?.component_id);
      changeRelationships(mesh, mesh.parent, parentAfter);
    }

    function unExecuteFromSaveData(saveData) {
      const opData = saveData.beforeOperationData;

      const childId = saveData.beforeOperationData.childId || saveData.afterOperationData.childId;
      const mesh = store.scene.getMeshByUniqueID(childId);
      
      const parentBefore = store.scene.getMeshByUniqueID(opData?.parentIdentifier?.component_id);
      changeRelationships(mesh, mesh.parent, parentBefore);
    }

    return {
      getCommand,
      getCommandData,
      executeCommand,
      changeRelationships,
      expressCheckout,
      executeFromSaveData,
      unExecuteFromSaveData,
    };
  })();

  let changeInUIOperations = (function () {
    function _saveProperty(data) {
      _.forEach(data, (dataPoint) => {
        _.set(store.userSettingsInStructure, dataPoint.key, dataPoint.value);
      });
    }

    function _applyToScope(data) {
      let scope = ScopeUtils.getScope();
      // scope.$applyAsync(function () {
      _.forEach(data, (dataPoint) => {
        _.set(scope, dataPoint.key, dataPoint.value);
        if (dataPoint.key.includes("userSetBIMProperties")) {
          reduxStore.dispatch(updateProperty({property: dataPoint.key.split(".")[1],
            value: dataPoint.value, codeInitiated: true}));
        }
        // });
      });
    }

    function _execute() {
      _applyToScope(this.data.valuesAfter.uiData);
      _saveProperty(this.data.valuesAfter.saveData);
    }

    function _unExecute() {
      _applyToScope(this.data.valuesBefore.uiData);
      _saveProperty(this.data.valuesBefore.saveData);
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this, options);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getSaveData() {
      const data = this.data;

      let saveData = AutoSave.getSaveDataPrototype();

      saveData.commandId = this.id;
      saveData.data.saveType = "setUserSetting";
      saveData.data.identifier.floorkey = store.floorkey;

      if (_.isEmpty(data.valuesAfter.saveData)) {
        saveData = null;
      } else {
        saveData.data.afterOperationData = data.valuesAfter.saveData;
        saveData.data.beforeOperationData = data.valuesBefore.saveData;
      }

      return saveData;
    }

    function getCommandDataPrototype() {
      return {
        valuesBefore: {
          uiData: [],
          saveData: [],
        },
        valuesAfter: {
          uiData: [],
          saveData: [],
        },
      };
    }

    function _getGenericObject() {
      return {
        key: null,
        value: null,
      };
    }

    function _getDataPointObject(dataPoint) {
      const key = dataPoint[0];
      const valueBefore = dataPoint[1];
      const valueAfter = dataPoint[2];

      const beforeObject = _getGenericObject();
      beforeObject.key = key;
      beforeObject.value = valueBefore;

      const afterObject = _getGenericObject();
      afterObject.key = key;
      afterObject.value = valueAfter;

      return { beforeObject, afterObject };
    }

    function getCommandData(arrayOfUIDataPoints, arrayOfSaveDataPoints) {
      if (!_.isArray(arrayOfUIDataPoints[0]))
        arrayOfUIDataPoints = [arrayOfUIDataPoints];
      if (!_.isArray(arrayOfSaveDataPoints[0]))
        arrayOfSaveDataPoints = [arrayOfSaveDataPoints];

      const commandDataPrototype = getCommandDataPrototype();

      arrayOfUIDataPoints.forEach((uiDataPoint) => {
        const { beforeObject, afterObject } = _getDataPointObject(uiDataPoint);

        commandDataPrototype.valuesBefore.uiData.push(beforeObject);
        commandDataPrototype.valuesAfter.uiData.push(afterObject);
      });

      arrayOfSaveDataPoints.forEach((saveDataPoint) => {
        const { beforeObject, afterObject } =
          _getDataPointObject(saveDataPoint);

        commandDataPrototype.valuesBefore.saveData.push(beforeObject);
        commandDataPrototype.valuesAfter.saveData.push(afterObject);
      });

      return commandDataPrototype;
    }

    function expressCheckout(
      name,
      arrayOfUIDataPoints,
      arrayOfSaveDataPoints,
      options
    ) {
      const commandData = getCommandData(
        arrayOfUIDataPoints,
        arrayOfSaveDataPoints
      );
      executeCommand(name, commandData, options);
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);
      CommandManager.execute(command, false);
    }

    function getCommand(name, data, options) {
      if (!options) options = {};

      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };

      let command = new Command(name, data, logic, _getSaveData);

      return command;
    }

    return {
      getCommand,
      getCommandDataPrototype,
      getCommandData,
      executeCommand,
      expressCheckout,
    };
  })();

  let storeyOperations = (function () {
    const CONSTANTS = {
      ADD: 'addStorey',
      UPDATE: 'updateStoreys',
      ADD_LAYER: 'addLayer',
      UPDATE_NAME: 'updateStoreyName',
      UPDATE_HEIGHT: 'changeStoreyHeight',
      ADD_FLOORPLAN: 'addFloorPlan',
      UPDATE_FLOORPLAN_WM: 'updateFloorPlanWM'
    };

    function _execute() {}

    function _unExecute() {}

    function _executeAddStorey(saveData) {
      const opData = saveData.afterOperationData;
      const structureId = saveData.identifier.structure_id;

      const storeyCollection = StoreyMutation.getStoreyCollection(structureId);
      const storeys = storeyCollection.getAllStoreys();

      if (!storeys[opData.value]) {
          const storey = storeyCollection.addStorey(structureId, opData.value,
              opData.height, {autoSave: false});
          storey.id = opData.id;
          ScopeUtils.addStorey(storey);
          const payload = {
            items: [
              {
                id: storey.id,
                value: storey.value,
                name: storey.name,
                height: DisplayOperation.convertToDefaultDimension(
                  storey.height
                ),
                hidden: storey.hidden,
                layers: [],
              },
            ],
          };
          reduxStore.dispatch(appendStorey(payload));
      }
    }

    function _executeUpdateStorey(saveData) {
      function copyData(storey, storeyData) {
          storey.id = storeyData.id;
          storey.type = storeyData.type;
          storey.base = storeyData.base;
          storey.hidden = storeyData.hidden;
          storey.name = storeyData.name || "";

          storeyData.elements.forEach(e => {
              try {
                  const element = meshObjectMapping.getObjectByUniqueId(e);
                  storey.addElement(element);
              } catch (e) {
                  console.warn(CONSTANTS.errorType.COLLABORATION +
                      "Could not find element to add to storey", e);
              }
          })
      }

      const opData = saveData.afterOperationData;
      const structureId = saveData.identifier.structure_id;

      const storeyCollection = StoreyMutation.getStoreyCollection(structureId);
      const storeys = storeyCollection.getAllStoreys();

      for (const storeyId in opData) {
          const storeyData = opData[storeyId];
          let storey;
          if (!storeys[storeyId]) {
              storey = storeyCollection.addStorey(structureId, storeyData.value,
                  storeyData.height, {autoSave: false});
              copyData(storey, storeyData);

              ScopeUtils.addStorey(storey);
          } else {
              storey = storeys[storeyId];
              copyData(storey, storeyData);
          }
      }
    }

    function _executeAddLayer(saveData) {
      const opData = saveData.afterOperationData;
      const structureId = saveData.identifier.structure_id;

      const storeyCollection = StoreyMutation.getStoreyCollection(structureId);
      const storeys = storeyCollection.getAllStoreys();
      const storey = storeys[opData.storey];

      if (!storey) {
          return;
      }

      const layer = storey.layerData.addLayer(opData.name, structureId,
          opData.layerType, opData.storey, { autoSave: false });
      layer.id = opData.id;
      layer.type = opData.type;
      layer.hidden = opData.hidden;
      layer.heightMapToggle = opData.heightMapToggle;
    }

    function _executeNameChange(saveData, execute = true) {
      let opData = saveData.afterOperationData;
      if (!execute){
        opData = saveData.beforeOperationData;
      }
      const identifier = saveData.identifier;

      const storeyCollection = StoreyMutation.getStoreyCollection(identifier.structure_id);
      const storeys = storeyCollection.getAllStoreys();
      const storey = storeys[parseInt(identifier.storey)];

      if (!storey) {
          return;
      }

      storey.name = opData.name;
      ScopeUtils.changeStoreyName(storey.value, storey.name);
    }

    function _executeHeightChange(saveData, execute) {
      const identifier = saveData.identifier;

      const storeyCollection = StoreyMutation.getStoreyCollection(identifier.structure_id);
      const storeys = storeyCollection.getAllStoreys();
      const storey = storeys[parseInt(identifier.storey)];

      if (execute) {
          const opData = saveData.afterOperationData;
          storey.height = opData.height;
      }
      else {
          const opData = saveData.beforeOperationData;
          storey.height = opData.height;
      }

      storeyCollection.updateSubsequentBase(storey.value);
      // ScopeUtils.changeStoreyHeight(storey.value, storey.height);
    }

    function _executeAddFloorplan(saveData) {
      const opData = saveData.afterOperationData;

      const imageSrc = opData.mesh.materials[0].diffuseTexture.url;
      const uniqueId = opData?.mesh?.meshes[0]?.uniqueId;

      // const twoMaterialPlane = new BABYLON.StandardMaterial("twoMaterialPlane_" + opData.storey + '_' + opData.strNum, store.scene);
      // twoMaterialPlane.diffuseTexture = new BABYLON.Texture(imageSrc, store.scene);
      // twoMaterialPlane.specularColor = new BABYLON.Color3(0, 0, 0);
      // twoMaterialPlane.backFaceCulling = false;
      //
      // const twoPlane = BABYLON.MeshBuilder.CreatePlane("twoPlane_" + opData.storey + '_' + opData.strNum, {width: (opData.floorPlanImageWidth / opData.scaleFactor)
      //     , height: (opData.floorPlanImageHeight / opData.scaleFactor)}, store.scene);
      // twoPlane.alphaIndex = 0;
      // twoPlane.rotation.x = Math.PI / 2;
      // twoPlane.material = twoMaterialPlane;
      // twoPlane.material.backFaceCulling = false;

      // twoPlane.position.x = opData.mesh.meshes[0].position[0];
      // twoPlane.position.z = opData.mesh.meshes[0].position[2];

      if(!backwardCompatibilityChecker.doesGeometriesExistForFloorplan(opData)){
        return;
      }
      let geom = getGeometryById(
          opData.mesh.geometries.vertexData,
          opData.mesh.meshes[0].geometryId
      );
      let twoPlane = store.scene.recreateMesh(
          opData.mesh.meshes[0],
          geom,
          null
      );
      

      let bbInfo = twoPlane.getBoundingInfo();
      let len = bbInfo.boundingBox.extendSizeWorld.x * 2;
      // this is to bring any image to default snaptrude size.
      let _initialScaleFactor = DisplayOperation.getOriginalDimension(38000, "millimeter") / len;
      twoPlane.scaling.multiplyInPlace(
          new BABYLON.Vector3(_initialScaleFactor, _initialScaleFactor, _initialScaleFactor)
      );

      let _scaleFactor;
      if(opData.scaleFactor){
        _scaleFactor = opData.scaleFactor
      }
      else{
        _scaleFactor = scaleFactor;
      }

      twoPlane.scaling.multiplyInPlace(
          new BABYLON.Vector3(_scaleFactor, _scaleFactor, _scaleFactor)
      );

      twoPlane.computeWorldMatrix(true);
      bbInfo = twoPlane.getBoundingInfo();
      twoPlane.position.x -= bbInfo.boundingBox.minimumWorld.x;
      twoPlane.position.z -= bbInfo.boundingBox.maximumWorld.z;

      const twoMaterialPlane = new BABYLON.StandardMaterial("twoMaterialPlane_" + opData.storey + '_' + opData.strNum, store.scene);
      twoMaterialPlane.diffuseTexture = new BABYLON.Texture(imageSrc, store.scene);
      twoMaterialPlane.specularColor = new BABYLON.Color3(0, 0, 0);
      twoMaterialPlane.backFaceCulling = false;
      twoPlane.material = twoMaterialPlane;
      twoPlane.material.backFaceCulling = false;

      twoPlane.type = 'sketchPlane';
      // twoPlane.strNum = opData.strNum;
      twoPlane.storey = opData.storey;
      twoPlane.structure_id = opData.structure_id;
      // twoImageWidth = opData.floorPlanImageWidth;
      // twoImageHeight = opData.floorPlanImageHeight;
      twoPlane.computeWorldMatrix(true);

      const floorPlan = new FloorPlan(twoPlane);
      floorPlan.storey = opData.storey;
      floorPlan.initialScaleFactor = opData.initialScaleFactor ? opData.initialScaleFactor : 1;
      floorPlan.floorPlanImageHeight = opData.floorPlanImageHeight;
      floorPlan.floorPlanImageWidth = opData.floorPlanImageWidth;
      floorPlan.strNum = opData.strNum;
      floorPlan.layer_id = opData.layer_id;
      floorPlan.identifierForBackend = opData.identifierForBackend;

      const _storeyOfInterest = StructureCollection.getInstance().getStructures()[floorPlan.structure_id]
          .getStoreyData()
          .getStoreyByValue(twoPlane.storey);
      // const layer = _storeyOfInterest.layerData
      //     .addLayer('Image', floorPlan.structure_id, 'image', twoPlane.storey);

      twoPlane.position.y = _storeyOfInterest.base;

      let layer = _storeyOfInterest.layerData.getLayerBylayerId(opData.layer_id);
      if(uniqueId){
        // floorPlan.mesh.uniqueId = data.uniqueId;
        // recreateImageInTheBackend(opData.uniqueIdOfThePlanInBackend, opData.storey);
        meshUniqueIdMapper.update(floorPlan.mesh, uniqueId);
      }
      if(layer){
        layer.addFloorPlan(floorPlan);
        reduxStore.dispatch(
            appendLayer({
              id: layer.id,
              title: layer.name,
              storey: twoPlane.storey,
              hidden: layer.hidden,
              heightMapToggle: layer.heightMapToggle,
              image: imageLayer,
            })
        );
      }

      // ScopeUtils.addLayer(layer);
      setLayerTransperancy();
    }

    function _unexecuteAddFloorPlan(saveData){
      const data = saveData.afterOperationData;
      data.uniqueId = data?.mesh?.meshes[0]?.uniqueId;
      data.layerId = data?.layer_id;
      data.storeyVal = data?.storey;
      data.structureNum = data?.strNum;
      deleteFloorPlan(data);
    }

    function _executeUpdateFloorplan(saveData, execute = true) {
      let opData = saveData.afterOperationData;
      if(!execute){
        opData = saveData.beforeOperationData;
      }
      const identifier = saveData.identifier;

      const structure = StructureCollection.getInstance().getStructureById(identifier.structure_id);
      const storey = structure.getStoreyData().getStoreyByValue(identifier.storey);
      const imageLayer = storey.layerData.getLayerByName('Image', storey.value);
      const imagePlane = imageLayer.floorplans[0].mesh;
      if(opData.data.scaling){
        let _actualScaleValue = opData.data.scaling[0] / imagePlane.scaling._x;
        imagePlane.scaling.multiplyInPlace(new BABYLON.Vector3(_actualScaleValue, _actualScaleValue, _actualScaleValue));
        // imagePlane.scaling = new BABYLON.Vector3(opData.data.scaling[0], opData.data.scaling[1], opData.data.scaling[2]);
        store.scene.render();
      }
      imagePlane.computeWorldMatrix(true);
      // imagePlane.position.x = opData.data.position[0];
      // imagePlane.position.y = opData.data.position[1];
      // imagePlane.position.z = opData.data.position[2];

      let bbInfo = imagePlane.getBoundingInfo();
      if( opData?.data?.position ){
        imagePlane.position = BABYLON.Vector3.FromArray(opData.data.position);
        imagePlane.setAbsolutePosition(imagePlane.position);
      }
      else{
        imagePlane.position.x -= bbInfo.boundingBox.minimumWorld.x;
        imagePlane.position.z -= bbInfo.boundingBox.maximumWorld.z;
      }

      layerView.generateRefPlane(storey);
      imageLayer.floorplans[0].mesh.computeWorldMatrix(true);
      imagePlane.layer_id = imageLayer.id;
    }

    function getSaveData() {
      let data = this.data;
      let storeyCollection = StructureCollection.getInstance()
        .getStructureById(data.structure_id)
        .getStoreyData()
        .getAllStoreys();

      let saveData = AutoSave.getSaveDataPrototype();

      saveData.commandId = this.id;
      saveData.data.saveType = "updateStoreys";

      let dataBefore = {};
      let dataAfter = {};

      data.idList.forEach((id) => {
        dataAfter[id] = storeyCollection[id].serialize();
      });

      saveData.data.identifier = {
        structure_id: data.structure_id,
        floorkey: store.floorkey,
      };

      saveData.data.afterOperationData = dataAfter;
      saveData.data.beforeOperationData = dataBefore;

      return saveData;
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);

      CommandManager.execute(command, false);
    }

    function getCommandData(data) {
      return data;
    }

    function getCommand(name, data, options = {}) {
      let logic = {
        execute: _execute,
        unexecute: _unExecute,
      };
      return new Command(name, data, logic, getSaveData);
    }

    function executeFromSaveData(saveData) {
      switch (saveData.saveType) {
          case CONSTANTS.ADD:
              _executeAddStorey(saveData);
              break;
          case CONSTANTS.UPDATE:
              _executeUpdateStorey(saveData);
              break;
          case CONSTANTS.ADD_LAYER:
              _executeAddLayer(saveData);
              break;
          case CONSTANTS.UPDATE_NAME:
              _executeNameChange(saveData);
              break;
          case CONSTANTS.UPDATE_HEIGHT:
              _executeHeightChange(saveData, true);
              break
          case CONSTANTS.ADD_FLOORPLAN:
              _executeAddFloorplan(saveData);
              break;
          case CONSTANTS.UPDATE_FLOORPLAN_WM:
              _executeUpdateFloorplan(saveData, true);
              break;
      }
    }

    function unExecuteFromSaveData(saveData) {
      switch (saveData.saveType) {
          case CONSTANTS.UPDATE_HEIGHT:
              _executeHeightChange(saveData, false);
              break;
          case CONSTANTS.UPDATE_FLOORPLAN_WM:
              _executeUpdateFloorplan(saveData, false);
              break;
          case CONSTANTS.UPDATE_NAME:
              _executeNameChange(saveData, false);
              break;
          case CONSTANTS.ADD_FLOORPLAN:
              _unexecuteAddFloorPlan(saveData);
              break;
      }
    }



    return {
      executeCommand,
      getCommandData,
      getCommand,
      executeFromSaveData,
      unExecuteFromSaveData,
      _executeAddFloorplan,
      _executeUpdateFloorplan
    };
  })();

  let linkedListOperations = (function () {
    function populateDLL(dll, data) {
      data.forEach((node) => {
        dll.append(node);
      });
    }

    function _execute() {
      let data = this.data;

      let linkedListCluster = StructureCollection.getInstance()
        .getStructureById(data.structure_id)
        .getLinkedListCluster();

      for (let id in data) {
        if (data.hasOwnProperty(id) && id !== "structure_id") {
          linkedListCluster.removeLinkedList(id);
          let dll = linkedListCluster.addNewLinkedList(id);
          populateDLL(dll, data[id].newData);
        }
      }
    }

    function _unExecute() {
      let data = this.data;

      let linkedListCluster = StructureCollection.getInstance()
        .getStructureById(data.structure_id)
        .getLinkedListCluster();

      for (let id in data) {
        if (data.hasOwnProperty(id) && id !== "structure_id") {
          linkedListCluster.removeLinkedList(id);
          let dll = linkedListCluster.addNewLinkedList(id);
          populateDLL(dll, data[id].prevData);
        }
      }
    }

    function getSaveData() {
      let data = this.data;

      let saveData = AutoSave.getSaveDataPrototype();

      saveData.commandId = this.id;
      saveData.data.saveType = "updateLinkedList";

      saveData.data.identifier = {
        structure_id: data.structure_id,
        floorkey: store.floorkey,
      };

      saveData.data.afterOperationData = data;

      return saveData;
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);

      CommandManager.execute(command, false);
    }

    function getCommandData(data) {
      return data;
    }

    function getCommand(name, data, options = {}) {
      let logic = {
        execute: _execute,
        unexecute: _unExecute,
      };
      return new Command(name, data, logic, getSaveData);
    }

    function executeFromSaveData(saveData) {
      const opData = saveData.afterOperationData;
      _execute.call(opData);
    }

    function unExecuteFromSaveData(saveData) {
      const opData = saveData.afterOperationData;
      _unExecute.call(opData);
    }



    return {
      executeCommand,
      getCommandData,
      getCommand,
      executeFromSaveData,
      unExecuteFromSaveData,
    };
  })();

  let componentTypeChangeOperations = (function () {
    function _execute() {
      function _handleDataPoint(data) {
        let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
        if (meshOfInterest) {
          const options = data.extraData;
          options.doPropertyChanges = true;
          options.componentId = data.componentIdAfter;
          const newComponent = changeTypeInStructure(
            meshOfInterest,
            data.meshTypeAfter,
            options
          );
          newComponent.disallowTypeChange();
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _unExecute() {
      function _handleDataPoint(data) {
        let meshOfInterest = store.scene.getMeshByUniqueID(data.meshId);
        if (meshOfInterest) {
          const options = data.extraData;
          options.doPropertyChanges = true;
          options.componentId = data.componentIdBefore;
          const newComponent = changeTypeInStructure(
            meshOfInterest,
            data.meshTypeBefore,
            options
          );
          newComponent.allowTypeChange();
        }
      }

      let data = this.data;
      data.forEach(_handleDataPoint);
    }

    function _getExecute(options) {
      let preCallback = options.preExecuteCallback;
      let postCallback = options.postExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _execute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getUnExecute(options) {
      let preCallback = options.preUnExecuteCallback;
      let postCallback = options.postUnExecuteCallback;

      return function () {
        if (preCallback) preCallback.call(this);
        _unExecute.call(this);
        if (postCallback) postCallback.call(this);
      };
    }

    function _getSaveData() {
      let saveDataCollective = [];

      this.data.forEach((dataPoint) => {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = this.id;
        saveData.data.saveType = "changeComponentType";

        let dataBefore = {};
        let dataAfter = {};

        dataBefore.updatedMeshType = dataPoint.meshTypeBefore;
        dataBefore.component = dataPoint.serializedComponentBefore;
        dataBefore.identifier = AutoSave.getComponentIdentifier(
          dataPoint.componentBefore
        );
        dataBefore.identifier.componentClass = AutoSave.getComponentClass(
          dataBefore.identifier.type
        );

        dataAfter.updatedMeshType = dataPoint.meshTypeAfter;
        dataAfter.component = dataPoint.serializedComponentAfter;
        dataAfter.identifier = AutoSave.getComponentIdentifier(
          dataPoint.componentAfter
        );
        dataAfter.identifier.componentClass = AutoSave.getComponentClass(
          dataAfter.identifier.type
        );

        saveData.data.afterOperationData = dataAfter;
        saveData.data.beforeOperationData = dataBefore;

        delete dataPoint.serializedComponentBefore;
        delete dataPoint.serializedComponentAfter;
        delete dataPoint.componentBefore;
        delete dataPoint.componentAfter;

        saveDataCollective.push(saveData);
      });

      return saveDataCollective;
    }

    function getCommandData(components, options = {}) {
      if (!options.data) {
        //before edit

        const _handleDataPoint = function (component) {
          const dataPoint = {
            extraData: {},
          };
          dataPoint.meshTypeBefore = component.mesh.type;
          dataPoint.componentIdBefore = component.id;
          dataPoint.meshId = component.mesh.uniqueId;

          if (component.mesh.type.toLowerCase() === "mass") {
            dataPoint.extraData.massSubTypeAfter = component.massType;
          }

          dataPoint.componentBefore = component;
          dataPoint.serializedComponentBefore =
            StructureCollection.getInstance().getSerializedComponent(
              component
            ).dsProps;

          delete dataPoint.serializedComponentBefore.brep;
          delete dataPoint.serializedComponentBefore.faceFacetMapping;

          data.push(dataPoint);
        };

        let data = [];
        components.forEach(_handleDataPoint);

        return data;
      } else {
        //after edit

        const data = options.data;
        const _handleDataPoint = function (component, i) {
          const dataPoint = data[i];
          dataPoint.meshTypeAfter = component.mesh.type;
          dataPoint.componentIdAfter = component.id;

          if (component.mesh.type.toLowerCase() === "mass") {
            dataPoint.extraData.massSubTypeAfter = component.massType;
          }

          dataPoint.componentAfter = component;
          dataPoint.serializedComponentAfter =
            StructureCollection.getInstance().getSerializedComponent(
              component
            ).dsProps;

          delete dataPoint.serializedComponentAfter.brep;
          delete dataPoint.serializedComponentAfter.faceFacetMapping;
        };

        components.forEach(_handleDataPoint);

        return data;
      }
    }

    function executeCommand(name, data, options) {
      let command = getCommand(name, data, options);
      CommandManager.execute(command, false);
    }

    function getCommand(name, data, options = {}) {
      let logic = {
        execute: _getExecute(options),
        unexecute: _getUnExecute(options),
      };

      return new Command(name, data, logic, _getSaveData);
    }

    return {
      executeCommand,
      getCommandData,
      getCommand,
    };
  })();

  let materialOperations = (function () {
    const CONSTANTS = {
        ADD: "addMaterial",
        APPLY: "applyMaterial",
        DELETE: "deleteMaterial",
        EDIT: "editMaterial"
    }

    function _executeAdd(saveData) {
        const opData = saveData.afterOperationData;
        const data = {
            materials: opData.subMaterials || [],
            multiMaterials: opData.multiMaterial ? [opData.multiMaterial] : []
        }

        recreateMaterials(data);
        recreateMultiMaterials(data);
    }

    function _executeApply(saveData, execute=true) {
        function parseSaveToCommand(opData) {
            const data = {};

            data.meshId = identifier.component_id;
            data.brep = store.resurrect.resurrect(opData.brep);
            data.components = opData.components;
            data.materialID = opData.material.id;
            data.materialIndices = opData.materialIndices;
            data.subMeshes = opData.subMeshes;

            return data;
        }

        function createMultiMaterial(material) {
            const multiMaterial = store.scene.getMultiMaterialByID(material.id);
            if (!multiMaterial) {
                recreateMultiMaterials({ multiMaterials: [material] });
            }
            else {
                if (multiMaterial.subMaterials.length !== material.materials.length) {
                    const newMaterial = store.scene.getMaterialByName(
                        material.materials[material.materials.length - 1]);
                    if (newMaterial) multiMaterial.subMaterials.push(newMaterial);
                }
            }
        }

        const identifier = saveData.identifier;
        const pseudCommand = { data: {} }
        const data = pseudCommand.data;
        if (execute) {
            data._newData = parseSaveToCommand(saveData.afterOperationData);
            const matData = saveData.afterOperationData.material;
            if (matData.materials) {
                createMultiMaterial(saveData.afterOperationData.material);
            }
            applyMaterial.call(pseudCommand);
        }
        else {
            data._prevData = parseSaveToCommand(saveData.beforeOperationData);
            const matData = saveData.beforeOperationData.material;
            if (matData.materials) {
                createMultiMaterial(saveData.beforeOperationData.material);
            }
            removeMaterial.call(pseudCommand);
        }
    }

    function _executeEdit(saveData, execute=true) {
        const pseudoCommand = { data: {} };
        const data = pseudoCommand.data;

        if (execute) {
            data._newData = saveData.afterOperationData;
            editMaterial.call(pseudoCommand);
        }
        else {
            data._prevData = saveData.beforeOperationData;
            reEditMaterial.call(pseudoCommand);
        }
    }

    function executeFromSaveData(saveData) {
        switch (saveData.saveType) {
            case CONSTANTS.ADD:
                _executeAdd(saveData);
                break;
            case CONSTANTS.APPLY:
            case CONSTANTS.DELETE:
                _executeApply(saveData, true);
                break;
            case CONSTANTS.EDIT:
                _executeEdit(saveData, true);
                break;
        }
    }

    function unExecuteFromSaveData(saveData) {
        switch (saveData.saveType) {
            case CONSTANTS.APPLY:
            case CONSTANTS.DELETE:
                _executeApply(saveData, false);
                break;
            case CONSTANTS.EDIT:
                _executeEdit(saveData, false);
                break;
        }
    }

    return {
        executeFromSaveData,
        unExecuteFromSaveData
    }
})();

  let changeRevitDataOperation = (function () {
    function getCommand(data, options) {
      let cmd = new Command("changeRevitMetaData", data, {}, getSaveData);
      return cmd;
    }

    function getSaveData() {
      let saveDataCollective = [];

      this.data.forEach((dataPoint) => {
        const mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (!mesh) return;
        const meshDS = mesh.getSnaptrudeDS();
        if (!mesh) return;
        let saveData = AutoSave.getSaveDataPrototype();
        saveData.commandId = this.id;
        saveData.data.saveType = "changeRevitMetaData";
        saveData.data.identifier = AutoSave.getComponentIdentifier(meshDS);

        let dataAfter = {
          revitMetaData: dataPoint.revitMetaData,
        };
        saveData.data.afterOperationData = dataAfter;

        saveDataCollective.push(saveData);
      });

      return saveDataCollective;
    }

    function getCommandData(meshes, options = {}) {
      const _handleDataPoint = function (mesh) {
        let component = mesh.getSnaptrudeDS();
        if (!component) return;
        const dataPoint = {};
        dataPoint.storey = component.mesh.storey;
        dataPoint.layer = component.layer;
        dataPoint.level_id = component.level_id;
        dataPoint.structure_id = component.structure_id;
        dataPoint.beforeOperationData = component.beforeRevitMetaData;
        dataPoint.revitMetaData = component.revitMetaData;
        dataPoint.meshId = component.mesh.uniqueId;
        dataPoint.dsPropsId = component.id;
        dataPoint.type = component.mesh.type;

        delete component.beforeRevitMetaData;

        data.push(dataPoint);
      };

      let data = [];
      meshes.forEach(_handleDataPoint);

      return data;
    }
    return {
      getCommand,
      getSaveData,
      getCommandData,
    };
  })();


  return {
    CONSTANTS,
    geometryChangeOperations,
    worldMatrixChangeOperations,
    creationOperations,
    deletionOperations,
    propertyChangeOperations,
    parentChildOperations,
    changeInUIOperations,
    storeyOperations,
    linkedListOperations,
    componentTypeChangeOperations,
    materialOperations,
    changeRevitDataOperation,
  };
})();
export { commandUtils };
