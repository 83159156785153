import React from "react";
import {  withRouter } from "react-router-dom";
import TopMenuBar from "../../components/TopMenuBar";
import * as icons from "./Icons";
import * as StyledComponents from "./StyledEditorClasses";
import RightSidebar from "../../components/RightSidebar/index";
import AreaSidebar from "./components/areaSidebar";
import BOMSidebar from "./components/bomSidebar";
import ViewSidebar from "./components/viewSidebar";
import SpaceSidebar from "./components/spacesSidebar";
import GenericModal from "../genericModal/index";
import image from "../../assets/icons/image.svg";
import { colors } from "../../themes/constant";
import Divider from "../../components/Divider/index";
import SettingsPopUp from "./components/bottomPopUps/settingsPopUp";
import ShortCutKeyModal from "./components/bottomPopUps/shotCutKeyModal";
import { SETTINGS_MENU_OPTIONS } from "../../components/TopMenuBar/constants";
import { babylonInit } from "../../snaptrude/libs/babylonInit";
import { handleToolbar, UIHandler } from "../../snaptrude/libs/handleToolbar";
import LeftMenu from "./components/leftMenu";
import reduxStore from "../../snaptrude/stateManagers/store/reduxStore";
import {setMenuMode} from "../../snaptrude/stateManagers/reducers/objectProperties/materialsSlice";
import {MATERIAL_MENU} from "../../components/rightMenus/constants";
import editMaterial from "../../snaptrude/libs/materialEditUI";
import loader from "../../assets/loader.gif";
import { runOnUiClick } from "../../snaptrude/modules/uiClickOperations/uiClickOperations";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import OnboardingTooltip from "../../components/OnboardingTooltip";
import { TOOLS_MAPPING } from "../../snaptrude/modules/utilityFunctions/CONSTANTS/tools_mapping";
import Instructor from "../../components/Instructor";
import authService from "../../services/auth.service";
import {routes} from "../../routes/constants";
import { getProjectMetadata, getSignOff } from '../../services/projects.service';
import { updateProjectProperties } from "../../snaptrude/stateManagers/reducers/objectProperties/projectPropertiesSlice";
import { unmount } from "../../snaptrude/modules/utilityFunctions/unmountEditor";
import CommentsSidebar from "./components/commentsSidebar";
import { cameraFollow } from "../../snaptrude/modules/socket/cameraFollow";
import LatLongInput from "../../components/LatLongInput";
import ProgressBar from "../../components/ProgressBar";
import SharedProject from "../sharedProject";
import { getInvitedUserFromCode, showAutoSaveMessage } from "./helper";
import { randomIntFromInterval, showToast } from "../../snaptrude/modules/extrafunc";
import { shortcut, shortcutsOnAnalysisPage } from "./shortcutKeys";
import RightMenuPopUp from "./components/RightMenuPopUp";
import { resetDefaultCursor, updateCursor } from "./cursorHandler";
import CADConfirmationModal from "../../components/CADConfirmationModal";
import { toggleHiddenLine } from "../../snaptrude/libs/sceneFuncs";
import * as Sentry from "@sentry/react";
import SustainabilityMetrics from "./components/DASidebars/SustainabilityMetricsSidebar";
import EUISidebar from "./components/DASidebars/EUISidebar";
import DARecordsSidebar from "./components/DASidebars/DARecordsSidebar";

import coveToolHelpers from "../../snaptrude/modules/covetool";
import { connect } from "react-redux";
import ChangelogSidebar from "./components/ChangelogSidebar";
import SignOffModal from "../../components/SnaptrudeModal/SignOffModal";
let projectProgressInterval;
class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRightMenuImage: "",
      activateEyeDropper: false,
      selectedBottomMenuImage: "",
      layerArray: [1], // set this array for default layer value
      copyLayerArray: [1],
      selectedPanelImage: "",
      selectedMenu: "Design",
      selectedSubMenu: "",
      selectedTopMultipleMenus: [],
      viewType2D: true,
      color: "",
      showEllipseMenu: true,
      showHelpMenu: true,
      selectedLayer: null,
      selectedStorey: 1,
      topIndex: 0,
      bottomIndex: 0,
      layerWindow: [],
      floorkey: null,
      isLoading: true,
      showEditStructure: false,
      showInstructor: false,
      disableInstructor: false,
      triggeredTool: null,
      checkingIfProjectExists: true,
      doesProjectExist: null,
      viewsSettings: SETTINGS_MENU_OPTIONS,
      projectLoadProgress: 0,
      sharedProjectSignUp: false,
      showSignUpForm: true,
      projectTitle: "",
      invited: null,
      recentArray: [],
      imageModalOpen: false,
      cadModalOpen: false,
      materialModalOpen: false,
      topographyModalVisible: false,
      analysisModalVisible: false,
      speckleRevitModalVisible:false,
      renderSettingsModalVisible: false,
      pdfModalOpen: false,
      showCADConfirmationModal: false,
      viaCommentSelect: false,
      currentProjectTeam: null,
      showSignOffModal: true,
    };
    this.viewToggleRef = React.createRef();
    this.dropdownNode = React.createRef();
  }

  canShowShareProjectSignUp = (evt) => {
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get("code");
    const user = authService.getCurrentUser();
    if(user.email){
      // window.history.pushState({}, document.title, window.location.pathname);
    }else if(code){
      const resp = getInvitedUserFromCode(code);
      if(resp.success){
        const {invited} = resp.data;
        this.setState({
          sharedProjectSignUp: true,
          invited: invited
        })
      }else{
        // REDIRECT TO SIGN UP PAGE
        let url = routes.signup;
        const floorkey = this.props.match.params.floorkey;
        if(floorkey){
          url = `${routes.signup}?redirect=/model/${floorkey}`;
        }
        this.props.history.push(url);
      }
    }else{
      this.setState({
        sharedProjectSignUp: true,
        invited: {email: ""},
        showSignUpForm: false
      })
    }
  }

  handleSessionExpiredEvent = (evt) => {
    if (evt.detail && evt.detail.logout) {
      const {history} = this.props;
      authService
        .logout()
        .then((resp) => {
        })
        .catch((err) => {
          console.log(err);
        });

      let url = routes.login;
      const query = new URLSearchParams(this.props.location.search);
      const code = query.get("code");
      if(code) url = routes.signup;
      const floorkey = this.props.match.params.floorkey;
      if(url === routes.login) {
        showToast("Session timed out, Please login again");
      }
      if(floorkey){
        url = `${url}?redirect=/model/${floorkey}`;
      }
      history.push(url);
    }
  }

  handleSceneLoadedEvt = (evt) => {
    if(projectProgressInterval) {
      clearInterval(projectProgressInterval)
      projectProgressInterval=null;
      this.setState({
        projectLoadProgress: 100
      })
    }
    setTimeout(() => {
      if(evt.detail.success) {
        this.setState({isLoading: false});
        this.setState({disableInstructor: store.projectProperties.properties.disableTooltips.getValue()})
      }
    }, 500)
  };
  handleCanvasClick = (evt) => {
    if(evt.target.id === 'canvas'){
      return;
    }
    runOnUiClick();
  };

  handleShortcuts = (evt) => {
    if(this.state.sharedProjectSignUp && !this.state.showSignUpForm) return;
    if(evt.target.nodeName === "INPUT" || evt.target.nodeName === "TEXTAREA") return;
    const focusedControl = store.advancedTexture._focusedControl;
    if(focusedControl && focusedControl.name.startsWith("label-input") && focusedControl._linkedMesh) return;
    const {code, ctrlKey, altKey, shiftKey, metaKey} = evt;
    if((ctrlKey || metaKey) && code === "KeyS") showAutoSaveMessage()
    if (metaKey) return;
    let key = code;

    if(shiftKey && key === "Tab") evt.preventDefault();

    if(ctrlKey) key = `Ctrl + ${key}`;
    if(shiftKey) key = `Shift + ${key}`;
    if(altKey) key = `Alt + ${key}`;

    let operation = store.isCoveToolProject ? shortcutsOnAnalysisPage[key] : shortcut[key];
    if(!operation) return;

    let {tool, toolLocation, menu} = operation;
    if(!this.state.viewType2D){
      if(operation.toolIn3d) tool = operation.toolIn3d;
    }
    this.handleToolSelection(tool, toolLocation, menu);

  }

  handleCADComfirmationModal = (evt) => {
    const {show, layer} = evt.detail;
    this.setState({
      showCADConfirmationModal: show,
      layer: layer,
    })
  }

  handleEscapeKey = (evt) => {
    const {code} = evt;
    const {selectedBottomMenuImage} = this.state;
    if(code === "Escape" && selectedBottomMenuImage !== "" && selectedBottomMenuImage !== "NoView"){
      this.setState({
        selectedBottomMenuImage: "",
      })
    }
  }

  componentDidMount = () => {
    const floorkey = this.props.match.params.floorkey;
    Sentry.setTag("floorkey", floorkey);
    getProjectMetadata(floorkey).then(response => {
      this.setState({projectLoadProgress: 30});
      projectProgressInterval = setInterval(() => {
        this.setState((previous, props) => {
          if(previous.projectLoadProgress>=95) clearInterval(projectProgressInterval);
          else return {projectLoadProgress: previous.projectLoadProgress+randomIntFromInterval(0, 3)}
          return {};
        }
        );
      }, 500);
      if (response.success){
        const metadata = response.data.project;
        if(metadata && metadata.name){
          document.title = metadata.name;
          this.setState({
            projectTitle: metadata.name
          })
        }
        this.setState({checkingIfProjectExists: false});
        this.setState({doesProjectExist: true});
        // TODO: Kuladeep
        // Can we get the project init data from here itself?
      } else if(response.failure){
        this.setState({checkingIfProjectExists: false});
        this.setState({doesProjectExist: false});
        // TODO: Aditya
        // Can we stop scene load in this case here itself?
      }
    }).catch(response => {
      this.setState({checkingIfProjectExists: false});
      this.setState({doesProjectExist: false});
    });
    this.setState({floorkey: floorkey});
    babylonInit(floorkey);
    document.addEventListener("mousedown", this.onPressOutRightSidebar);
    window.addEventListener("sceneLoaded", this.handleSceneLoadedEvt);
    window.addEventListener("click", this.handleCanvasClick);
    window.addEventListener("viewTypeUpdated", this.onViewTypeChange);
    window.addEventListener("orthoViewUpdated", this.changeOrthoViewUi);
    window.addEventListener("instructor", this.handleInstructorEvent);
    window.addEventListener("sessionExpired", this.handleSessionExpiredEvent);
    window.addEventListener("can-show-shared-project-signup", this.canShowShareProjectSignUp);
    window.addEventListener("keydown", this.handleShortcuts);
    window.addEventListener("keyup", this.handleEscapeKey);
    window.addEventListener("showhide-CAD-confirmation-modal", this.handleCADComfirmationModal);
    // window.addEventListener("mousemove", this.handleMouseDown);
  };

  componentWillUnmount = () => {
    coveToolHelpers.handleReset()
    document.removeEventListener("mousedown", this.onPressOutRightSidebar);
    window.removeEventListener("sceneLoaded", this.handleSceneLoadedEvt);
    window.removeEventListener("click", this.handleCanvasClick);
    window.removeEventListener("viewTypeUpdated", this.onViewTypeChange);
    window.removeEventListener("orthoViewUpdated", this.changeOrthoViewUi);
    window.removeEventListener("instructor", this.handleInstructorEvent);
    window.removeEventListener("sessionExpired", this.handleSessionExpiredEvent);
    window.removeEventListener("can-show-shared-project-signup", this.canShowShareProjectSignUp);
    window.removeEventListener("keydown", this.handleShortcuts);
    window.removeEventListener("keyup", this.handleEscapeKey);
    window.removeEventListener("showhide-CAD-confirmation-modal", this.handleCADComfirmationModal);
    unmount();
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.projectMetadata.team.id !== this.props.projectMetadata.team.id){
      const {teams, projectMetadata} = this.props;
      let currentProjectTeam = null;
      if(projectMetadata.isTeamsProject){
        currentProjectTeam = teams.teamlist.find(team => team.id === projectMetadata.team.id);
      }
      this.setState({currentProjectTeam: currentProjectTeam})
    }
  }

  handleInstructorEvent = (evt) => {
    if(evt.detail.showInstructor){
      this.setState({triggeredTool: evt.detail.tool})
    }
    this.setState({showInstructor: evt.detail.showInstructor});
  }

  onPressOutRightSidebar = (e) => {
    const { selectedRightMenuImage } = this.state;
    const snaptrudeImportFunctionalityModalElement = document.getElementById("snaptrudeImportFunctionalityModal");
    if(snaptrudeImportFunctionalityModalElement && snaptrudeImportFunctionalityModalElement.contains(e.target)) return;
    if (
      this.dropdownNode &&
      this.dropdownNode.current &&
      this.dropdownNode.current.contains(e && e.target)
    ) {
      return;
    }
    if (selectedRightMenuImage && selectedRightMenuImage.length > 0) {
      this.handleToolSelection("", "right");
    }
  };

  onViewTypeChange  = (evt) => {
    const {selectedBottomMenuImage,  selectedSubMenu} = this.state;
    const prevSelectedSubMenu = selectedSubMenu;
    this.setState({
      selectedSubMenu: "",
    });
    handleToolbar("REMOVE");
    if(selectedBottomMenuImage && selectedBottomMenuImage !== "") this.handleToolSelection(selectedBottomMenuImage, "bottom");
    this.setState({
      viewType2D: evt.detail.viewType2D,
      selectedStorey: evt.detail.selectedStorey,
      selectedBottomMenuImage: "",
    });
    // console.log("Image modal state: ", this.state.imageModalOpen);
    if(this.state.imageModalOpen) return;
    setTimeout(() => {
      if(!prevSelectedSubMenu) this.handleToolSelection("pointer", "top");
      else this.handleToolSelection(prevSelectedSubMenu, "top");
    }, 100)
  }

  onViewsSettingsChange = (newSettings) => {
    this.setState({viewsSettings: newSettings})
  }

  onViewsListChange = (key, value) => {
    let newViewsSettings = JSON.parse(JSON.stringify(this.state.viewsSettings));
    if(["Shaded", "Textured", "Monochrome"].includes(key)){
      newViewsSettings[0].list = newViewsSettings[0].list.map(item => {
        if(item.title === key) return {...item, isActive: true};
        return {...item, isActive: false}
      })
      newViewsSettings[0].initalActiveItems = [`${key}`]
    }else{
      newViewsSettings = newViewsSettings.map(item => {
        if(item.isGroup) return item;
        if(item.title === key) return {...item, isActive: value}
        return item;
      })
    }
    this.onViewsSettingsChange(newViewsSettings);
  }

  changeOrthoViewUi = (evt) => {
    const newSettings = this.state.viewsSettings.slice().map(item => {
      if(item.isGroup) return item;
      if(item.title === "Orthographic") return {...item, isActive: evt.detail.isActive}
      return item;
    });
    this.setState({viewsSettings: newSettings});
  }

  setSelectedStorey = (value, viaCommentSelect=false) => {
    this.setState({
      selectedStorey: value,
      viaCommentSelect: viaCommentSelect
    });
  }
  setSelectedLayer = (value) => {
    this.setState({ selectedLayer: value });
  };

  expandEllipseMenu = () => {
    const { showEllipseMenu } = this.state;
    this.setState({ showEllipseMenu: !showEllipseMenu });
  };

  expandHelpMenu = () => {
    const { showHelpMenu } = this.state;
    this.setState({ showHelpMenu: !showHelpMenu });
  };

  handleToolSelection = (tool, toolLocation, menu) => {
    if (tool !== "REMOVE") {
      // if you are deselecting a tool then activate the select tool.
      if (tool && toolLocation === "top" && tool === this.state.selectedSubMenu) {
        tool = "pointer";
      }
    }
    if(tool && TOOLS_MAPPING[tool]){
      const {cursor} = TOOLS_MAPPING[tool];
      if(cursor){
        updateCursor(cursor);
      }
    }
    if(toolLocation === "top"){
      this.setSelectedSubMenu(tool);
      if(menu && menu !== this.selectedMenu){
        this.setSelectedMenu(menu);
      }
    }else if(toolLocation === "topright"){
      this.onRightPanelClick(tool);
    }
    else if(toolLocation === "right"){
      this.setSelectedRightMenuImage(tool);
    }else if(toolLocation === "bottom"){
      this.setSelectedBottomMenuImage(tool);
    }else if(toolLocation === "bottomRight"){
      this.toggleView()
    }
  }

  setSelectedMenu = (option) => {
    const { selectedMenu } = this.state;
    if(option === "" || option === selectedMenu){
      option = "Draw";
    }
    this.setState({
      selectedMenu: option,
    });
  };

  handleModalSelection = (option) => {
    switch (option) {
      case "image":
        this.setState({ imageModalOpen: true });
        break;
      case "file":
        this.setState({ cadModalOpen: true });
        break;
      case "cube":
        this.setState({ materialModalOpen: true });
        break;
      case "topo":
        this.setState({ topographyModalVisible: true });
        break;
      case "daylight_settings":
        this.setState({ analysisModalVisible: true });
        break;
      case "importpdf":
        this.setState({ pdfModalOpen: true });
        break;
      case "importRevit":
        this.setState({ speckleRevitModalVisible: true });
        break;
      case "render":
        this.setState({ renderSettingsModalVisible: true });
        break;
      default:
        break;
    }
  }

  setSelectedSubMenu = (option) => {

    if(option === "pointer" && this.state.selectedSubMenu === option) return;

    handleToolbar(option, null, null, () => this.handleModalSelection("topo"));

    this.setRecentArray(option);
    this.handleModalSelection(option);


    if(TOOLS_MAPPING[option]?.type === "immediateAction") return;

    if(TOOLS_MAPPING[option]?.type === "multipleSelect"){
      const newSelectedTopMultipleMenus = this.state.selectedTopMultipleMenus.slice();
      if(newSelectedTopMultipleMenus.includes(option)){
        newSelectedTopMultipleMenus.splice(newSelectedTopMultipleMenus.indexOf(option), 1);
        if(option === "graphical"){
          this.onViewsListChange("Hidden Line", false);
          if(newSelectedTopMultipleMenus.includes("shadowsdropdown")){
            newSelectedTopMultipleMenus.splice(newSelectedTopMultipleMenus.indexOf("shadowsdropdown"), 1);
          }
        } 
      }else{
        newSelectedTopMultipleMenus.push(option);
        if(option === "graphical") {
          this.onViewsListChange("Hidden Line", true);
          if(!newSelectedTopMultipleMenus.includes("shadowsdropdown")){
            newSelectedTopMultipleMenus.push("shadowsdropdown");
          }
        } 
        
      }
      this.setState({
        selectedTopMultipleMenus: newSelectedTopMultipleMenus,
      })
    }else{
      const { selectedSubMenu } = this.state;
      if(selectedSubMenu === option) handleToolbar("REMOVE");
      this.setState({
        selectedSubMenu: selectedSubMenu === option ? "" : option,
        selectedBottomMenuImage: ""
      });
    }

    if(option === "commentmode"){
      const isCommentModeSelected = this.state.selectedSubMenu === "commentmode";

        if(isCommentModeSelected){
          // turn off comment mode
          window.analytics.track('exited comment in 3d mode');
          this.handleCommentDialogClose();
        }else{
          // turn on comment mode
          // handleToolbar("comment");
        }
    }


  };

  setRecentArray = (selectedTool) => {
    const { recentArray } = this.state;
    // if present in recent array return
    for(let tool of recentArray){
      if(tool === selectedTool) return;
    }

    // else add to the array
    let newarr = recentArray.slice();
    if (recentArray.length < 3) {
      newarr.push(selectedTool);
    } else {
      newarr.shift();
      newarr.push(selectedTool);
    }
    this.setState({
      recentArray: newarr,
    });
  };

  handleCommentDialogClose = () => {
    const commentWrapper = document.getElementById("add-comment-wrapper");
    if(commentWrapper) commentWrapper.style.display = "none";
  }

  closeImportModal = (modalName) => {
    this.setState({ [modalName]: false });
  }

  onRightPanelClick = (panel) => {
    const { selectedPanelImage } = this.state;
    this.setState({
      selectedPanelImage: selectedPanelImage === panel ? "" : panel,
    });
  }
  resetSelectedTopSubMenu = () => {
    this.setState({selectedSubMenu: ""})
  }

  setSelectedRightMenuImage = (option) => {
    const { selectedRightMenuImage } = this.state;
    if (selectedRightMenuImage === "materials"){
      reduxStore.dispatch(setMenuMode(MATERIAL_MENU));
      // hooks not allowed inside a class component
      editMaterial.executeCommand();
      // feeling gross about using editMaterial here, but no idea how else to do it
    }
    if(option === "objectProperties" && store.selectionStack.length === 0) option = "";
    let activateEyeDropper = false;
    if(option === "eyeDropperForMaterial"){
      option = "materials"
      activateEyeDropper = true;
    } 
    this.setState({
      selectedRightMenuImage: option === selectedRightMenuImage ? "" : option,
      showEditStructure: false,
      activateEyeDropper: activateEyeDropper
    });
  };

  toggleView = () => {
    const {viewType2D, selectedTopMultipleMenus} = this.state;
    const newView = viewType2D ? "3d-view" : "2d-view";
    if(selectedTopMultipleMenus.includes("autoScale")) handleToolbar("autoScale");
    const newSelectedTopMultipleMenus = [];
    if(selectedTopMultipleMenus.includes("graphical")) newSelectedTopMultipleMenus.push("graphical");
    if(selectedTopMultipleMenus.includes("format")) newSelectedTopMultipleMenus.push("format");
    if(selectedTopMultipleMenus.includes("brainstormmode")) newSelectedTopMultipleMenus.push("brainstormmode");
    this.setState({
      selectedTopMultipleMenus: newSelectedTopMultipleMenus,
    })

    handleToolbar(newView);
    cameraFollow.onClickTwoDButton(newView);
  }

  setSelectedBottomMenuImage = (option) => {
    // console.log(option);
    const { selectedBottomMenuImage } = this.state;
    if (option && option === "ellipse") {
      const { showEllipseMenu } = this.state;
      this.setState({ showEllipseMenu: !showEllipseMenu });
      return;
    }
    if (option && option === "help") {
      const { showHelpMenu } = this.state;
      this.setState({ showHelpMenu: !showHelpMenu });
    }
    if (option && option === "Bulb") {
      const { selectedBottomMenuImage } = this.state;
      if(selectedBottomMenuImage === "NoView") {
        this.setState({
          selectedBottomMenuImage: ""
        })
      }
    }
    if(option === "" || TOOLS_MAPPING[option].type !== "immediateAction"){
      let newSelectedSubMenu = "";
      if(["Settings", "NoView"].includes(option)){
        newSelectedSubMenu = this.state.selectedSubMenu
      }
      this.setState({
        selectedBottomMenuImage: selectedBottomMenuImage === option ? "" : option,
        selectedSubMenu: newSelectedSubMenu
      });
      if(selectedBottomMenuImage === option){
        if(["pan", "orbit", "zoomIn", "zoomOut"].includes(option)){
          resetDefaultCursor();
          setTimeout(()=> {this.handleToolSelection("pointer", "top")}, 100)
        }
      } 
    }
    if(option && option === "info"){
      const {disableInstructor} = this.state;
      reduxStore.dispatch(updateProjectProperties({key: "disableTooltips", value: !disableInstructor}));
      this.setState({
        disableInstructor: !disableInstructor
      })
    }
    handleToolbar(option);
  };

  getRightSidebarInnerContent = () => {
    const { selectedPanelImage } = this.state;
    switch (selectedPanelImage) {
      case "Sustainability Metrics":
        return <SustainabilityMetrics />;
      case "Energy Use Intensity":
        return <EUISidebar />
      case "Daylight Analysis History":
        return <DARecordsSidebar />
      case "Areas":
        return <AreaSidebar />;
      case "BOQ":
        return <BOMSidebar />;
      case "Spaces":
        return <SpaceSidebar />;
      case "Changelog":
        return <ChangelogSidebar />
      case "Comments":
        return <CommentsSidebar selectedStorey={this.state.selectedStorey} viewType2D={this.state.viewType2D} setSelectedStorey={this.setSelectedStorey} viaCommentSelect={this.state.viaCommentSelect} currentProjectTeam={this.state.currentProjectTeam} />;
      case "Views":
        return <ViewSidebar />;
      default:
        return <div />;
    }
  };

  getBottomPopup = () => {
    const { selectedBottomMenuImage } = this.state;
    switch (selectedBottomMenuImage) {
      case "Settings":
        return <SettingsPopUp closePopUp={() => {this.handleToolSelection("", "bottom")}} />;
      case "Bulb":
        return <></>;
        // return <LastStatusModal />;
      case "Bulb0":
        return <></>;
        // return (
        //   <CheckBoxList
        //     borderBottomLeft="0"
        //     borderTopRight="8px"
        //     list={ITEM_CHECKLIST_OPTIONS}
        //   />
        // );
      case "share":
        return <ShortCutKeyModal />;
      default:
        return null;
    }
  };

  shouldLoaderDisplay = () => {
    if(this.state.isLoading) return true;
    if(this.state.checkingIfProjectExists) return true;
    if(!this.state.doesProjectExist) return true;
  };

  shouldProjectNotExistMessageDisplay = () => {
    if(this.state.checkingIfProjectExists) return false;
    if(this.state.doesProjectExist === false){
      if(projectProgressInterval){
        clearInterval(projectProgressInterval)
        projectProgressInterval=null;
        this.setState({
          projectLoadProgress: 100
        })
      }
      return true;
    }
  };

  getBottomIconSet(showEllipseMenu, showHelpMenu){
    const {teams, projectMetadata} = this.props;
    let bottomIconsSet = []
    if (showEllipseMenu || showHelpMenu) {
      if (showEllipseMenu && showHelpMenu) {
        if(projectMetadata.isCoveToolProject){
          bottomIconsSet = icons.FULL_BOTTOM_MENU_ICONS_COVE;
        }else{
          bottomIconsSet = icons.FULL_BOTTOM_MENU_ICONS
        }
      } else {
        if (showEllipseMenu) {
          bottomIconsSet = icons.BOTTOM_MENU_ICONS_ELLIPSE_EXPANDED
        } else {
          bottomIconsSet = icons.BOTTOM_MENU_ICONS_HELP_EXPANDED
        }
      }
    } else {
      bottomIconsSet = icons.BOTTOM_MENU_ICONS;
    }

    // check with team's role based permissions.
    let currentProjectTeam = null;
    if(projectMetadata.isTeamsProject){
      currentProjectTeam = teams.teamlist.find(team => team.id === projectMetadata.team.id);
    }
    if(currentProjectTeam){
      const permissions = currentProjectTeam.roleBasedPermissions[currentProjectTeam.role]
      bottomIconsSet = bottomIconsSet.map(iconSet => {
        iconSet = iconSet.filter(icon => {
          if(icon[2] && icon[2].hasOwnProperty("permissionKey")){
            const property = icon[2].permissionKey;
            return permissions[property]
          }
          return true;
        })
        return iconSet;
      })
    }
    return bottomIconsSet;
  }

  render() {
    const {
      selectedRightMenuImage,
      selectedBottomMenuImage,
      layerArray,
      selectedPanelImage,
      selectedMenu,
      selectedSubMenu,
      selectedTopMultipleMenus,
      viewType2D,
      showEllipseMenu,
      showHelpMenu,
      selectedLayer,
      copyLayerArray,
    } = this.state;
    const { projectMetadata } = this.props
    let bottomIconsSet = this.getBottomIconSet(showEllipseMenu, showHelpMenu)

    // Give control to back channel
    UIHandler.setSelector(this.handleToolSelection);
    return (
      <StyledComponents.EditorWrapper>
        <StyledComponents.LoadingScreen style={{display: this.shouldLoaderDisplay() ? "flex" : "none"}}>
          {/* <div style={{backgroundColor:"#eb1e45"}} className="spinner"></div> */}
          <img src={loader} alt="loading..." />
          <div style={{width: "500px", maxWidth: "80vw"}}>
            <ProgressBar completed={this.state.projectLoadProgress} text={"Loading..."} />
          </div>
          <div style={{display: this.shouldProjectNotExistMessageDisplay() ? "flex": "none"}}> Project does not exist.</div>
        </StyledComponents.LoadingScreen>
        <div id="mapContainer" />
        <a id="downloadAnchorElem" style={{display: "none"}} />
        <div id="canvas-zone"></div>
        <LatLongInput />
        <StyledComponents.CanvasWrapper id="canvas-wrapper">
          <canvas id="canvas" style={{touchAction: "none", position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%", zIndex: 1}}></canvas>
        </StyledComponents.CanvasWrapper>
        { this.state.sharedProjectSignUp &&
            <SharedProject
              onClose={() => {this.setState({sharedProjectSignUp: false})}}
              projectTitle={this.state.projectTitle}
              invited={this.state.invited}
              showSignUpForm={this.state.showSignUpForm}
            />
        }
        <div id="hubspot-conversations-inline-parent" style={{position: "absolute", left: "0.5em", bottom: "3.5em", zIndex: 3}}>
        </div>

        {this.state.showInstructor && <Instructor tool={this.state.triggeredTool} />}
        {!this.state.sharedProjectSignUp &&
          <TopMenuBar
            selectedPanelImage={selectedPanelImage}
            selectedMenu={selectedMenu}
            selectedSubMenu={selectedSubMenu}
            selectedTopMultipleMenus={selectedTopMultipleMenus}
            viewType2D={viewType2D}
            setSelectedMenu={this.setSelectedMenu}
            selectedStorey={this.state.selectedStorey ? this.state.selectedStorey : 1}
            viewsSettings={this.state.viewsSettings}
            onViewsSettingsChange={this.onViewsSettingsChange}
            onViewsListChange={this.onViewsListChange}
            imageModalOpen={this.state.imageModalOpen}
            cadModalOpen={this.state.cadModalOpen}
            materialModalOpen={this.state.materialModalOpen}
            topographyModalVisible={this.state.topographyModalVisible}
            analysisModalVisible={this.state.analysisModalVisible}
            speckleRevitModalVisible = {this.state.speckleRevitModalVisible}
            renderSettingsModalVisible = {this.state.renderSettingsModalVisible}
            pdfModalOpen={this.state.pdfModalOpen}
            closeImportModal={this.closeImportModal}
            handleToolSelection={this.handleToolSelection}
          />
        }
        <div style={{zIndex: 5, position: "fixed"}} id="pointers"></div>
        {/* <StyledComponents.AvatarContainer
          style={{
            zIndex: selectedPanelImage ? 2 : 0,
          }}
        >
          <Avatar
            width="1.5rem"
            height="1.5rem"
            backgroundSize="1.25rem 1.25rem"
            marginLeft="0.55375rem"
            urls={["", "", ""]}
          />
        </StyledComponents.AvatarContainer> */}
        <StyledComponents.MainContainer >
          {!this.state.sharedProjectSignUp && <><LeftMenu selectedStorey={this.state.selectedStorey} setSelectedStorey={this.setSelectedStorey} /><div></div></>}

          <StyledComponents.RightSidebarWrapper ref={this.dropdownNode}  style={{zIndex: 2}} isRightSidebarOpen={selectedPanelImage}>
          <StyledComponents.RightMenuWrapper>
           {!this.state.sharedProjectSignUp && <RightMenuPopUp
              selectedRightMenuImage={this.state.selectedRightMenuImage}
              handleToolSelection={this.handleToolSelection}
              showEditStructure={this.state.showEditStructure}
              resetSelectedTopSubMenu={this.resetSelectedTopSubMenu}
              setShowEditStructure={(showEditStructure) => {this.setState({showEditStructure: showEditStructure})}}
              selectedTopMenu={this.state.selectedSubMenu}
              activateEyeDropper={this.state.activateEyeDropper}
            />}
            {
              !projectMetadata.isCoveToolProject && !this.state.sharedProjectSignUp &&
              <StyledComponents.RightMenu>
                {icons.RIGHT_MENU_ICONS.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <StyledComponents.ImageButton
                        data-tip
                        data-for={item[0]}
                        data-event='mouseenter'
                        data-event-off='mouseleave click'
                        style={{ margin: 0 }}
                        key={index}
                        onClick={() => this.handleToolSelection(item[0], "right")}
                      >
                        <img
                          className={`${
                            selectedRightMenuImage === item[0]
                              ? "right_icon selected_right_icon"
                              : "right_icon rightMenuIcon"
                          }`}
                          src={item[1]}
                          alt={item[0]}
                        />
                        <OnboardingTooltip toolid={item[0]} id={item[0]} place={"left"}>{item[0]}</OnboardingTooltip>
                      </StyledComponents.ImageButton>
                      {index < icons.RIGHT_MENU_ICONS.length - 1 && (
                        <Divider width="100%" dividerColor={colors.white} />
                      )}
                    </React.Fragment>
                  );
                })}
              </StyledComponents.RightMenu>
            }
              <StyledComponents.BottomRightView
                ref={this.viewToggleRef}
                isRightSidebarOpen={selectedPanelImage}
            
                onClick={() => {
                    this.handleToolSelection("newView", "bottomRight")
                    // cameraFollow.onClickTwoDButton(newView);
                    // const cameraCursor = new CameraCursor();
                    // cameraCursor.onCameraChangeView(newView);
                  }
                }
              >
                <img
                  data-tip
                  data-for={"2d-3d-view-toogle"}
                  data-event='mouseenter'
                  data-event-off='mouseleave click'
                  src={this.state.viewType2D ? icons.GO_INTO_3D : icons.GO_INTO_2D}
                  style={{ }}
                  alt="Toggle 2d<->3d"
                />
                <OnboardingTooltip toolid={"viewToggle"} id="2d-3d-view-toogle">Go to {this.state.viewType2D ? "3D" : "2D"} (Shift + &gt;)</OnboardingTooltip>
              </StyledComponents.BottomRightView>

          </StyledComponents.RightMenuWrapper>
          {!this.state.sharedProjectSignUp && <RightSidebar
            sidebarElelment={this.getRightSidebarInnerContent()}
            sidenavOpen={selectedPanelImage}
          />}
          </StyledComponents.RightSidebarWrapper>

         {!this.state.sharedProjectSignUp &&  <StyledComponents.BottomContainer style={{zIndex: 2}}>
            <StyledComponents.BottomLeftContainer>
              {bottomIconsSet.map((item, index) => {
                return (
                  <StyledComponents.ImageGrouping key={index}>
                    {item.map((subItem, subIndex) => {
                      // if(index ===0 && subIndex === 0 && this.state.showInstructor ) return <Instructor tool={this.state.triggeredTool} />
                      return (
                        <div style={{ position: "relative" }} key={subIndex}>
                          {selectedBottomMenuImage === subItem[0] && (
                            <div
                              style={{ position: "absolute", bottom: "2.5rem" }}
                            >
                              {this.getBottomPopup()}
                            </div>
                          )}
                          <StyledComponents.ImageButton
                            style={{ width: "2rem", height: "2rem" }}
                            onClick={() =>
                              this.handleToolSelection(subItem[0], "bottom")
                            }
                            id={subItem[0]==="Settings" ? 'project-settings-button' : null}
                          >
                            <img
                              data-tip
                              data-for={subItem[0]}
                              data-event='mouseenter'
                              data-event-off='mouseleave click'
                              className={`${
                                subItem[0] === "info" ?
                                  this.state.disableInstructor ?
                                  "bottomMenuIcon"
                                  :
                                  "selected_bottom_icon"
                                :
                                  selectedBottomMenuImage === subItem[0]
                                    ? "selected_bottom_icon"
                                    : "bottomMenuIcon"
                              }`}
                              src={subItem[1]}
                              alt={subItem[0]}
                              onClick={() => {
                                if(TOOLS_MAPPING[subItem[0]] && TOOLS_MAPPING[subItem[0]].mixpanel){
                                  window.analytics.track(TOOLS_MAPPING[subItem[0]].mixpanel);
                                }
                              }}
                            />
                          </StyledComponents.ImageButton>
                          <OnboardingTooltip id={subItem[0]} toolid={subItem[0]}>{subItem[0]}</OnboardingTooltip>
                        </div>
                      );
                    })}
                  </StyledComponents.ImageGrouping>
                );
              })}
            </StyledComponents.BottomLeftContainer>
          </StyledComponents.BottomContainer>}
        </StyledComponents.MainContainer>
        <CADConfirmationModal isOpen={this.state.showCADConfirmationModal} layer={this.state.layer} onClose={() => {console.log("CLOSE")}} />
        {this.state.showSignOffModal && <SignOffModal currentProjectTeam={this.state.currentProjectTeam} handleClose={() => {this.setState({ showSignOffModal: false })}} /> }
        <GenericModal
          modalType={"IMPORT WITH DROPDOWN"}
          onClose={() => {}}
          isOpen={false}
          modalHeaderText={"Import Material"}
          customProps={{
            singleUpload: true,
            header: "Select texture and upload the file",
            images: [
              {
                src: image,
                extension: ".png",
              },
              {
                src: image,
                extension: ".png",
              },
              {
                src: image,
                extension: ".png",
              },
            ],
            leftTitle: "Import",
            rightTitle: "Back to materials",
            dropdownList: [
              {
                label: "Type",
                options: ["Concrete", "Concrete", "Concrete"],
              },
              {
                label: "Type",
                options: ["Swing door- single shutter", "Concrete", "Concrete"],
              },
            ],
          }}
        />
      </StyledComponents.EditorWrapper>
    );
  }
}

Editor.propTypes = {};

function mapStateToProps(state){
  return {
    projectMetadata: state.projectMetadata,
    teams: state.teams
  }
}
export default connect(mapStateToProps, {})(withRouter(Editor));
