import { store } from "../../modules/utilityFunctions/Store.js"
import { searchForId } from "../arrayFuncs.js";
import { room_types_db } from "../obj_base.js";
function createRoomPolygons(pols) {
  var pols1 = [];
  var pols2 = [];
  var h = 100 * store.unit_scale;
  for (var i = 0; i < pols.length; i++) {
    pols[i] = pols[i].uniq([].join);
    // pols[i].push(pols[i][0]);
    var pol1 = offsetRoomPols(pols[i], h);
    var pol2 = offsetRoomPols(pols[i], -h);
    pol2.push(pol2[0]);
    pol1.push(pol1[0]);
    pols1.push(pol1);
    pols2.push(pol2);
  }
  return [pols1, pols2];
}

function offsetRoomPols(pol, h) {
  var n = pol.length;
  var offPol = [];
  var flag = false;
  for (var i = 0; i < pol.length; i++) {
    var mi = getSlope(pol, i);
    var mii = getSlope(pol, i + 1);
    // var len_i = getDistance(pol, i);
    var si = getOffsetPointSi(pol, i, h);
    var ri = getOffsetPointRi(pol, i, h);
    var sii = getOffsetPointSi(pol, i + 1, h);
    var rii = getOffsetPointRi(pol, i + 1, h);
    // if (!si || !ri || !sii || !rii){
    // 	continue;
    // }
    if (Math.abs(mii - mi) < 0.01) {
      continue;
    }
    if (mi != "nan" && mii != "nan") {
      var x = (mii * rii - mi * ri + si - sii) / (mii - mi);
      var y =
        (mi * mii * rii - mi * mii * ri + mii * si - mi * sii) / (mii - mi);
    } else if (mi == "nan" && mii == "nan") {
      continue;
    } else if (mi == "nan") {
      var x = ri;
      var y = sii;
    } else if (mii == "nan") {
      var x = rii;
      var y = si;
    }
    if (!x || !y) {
      console.log(mi, mii, si, sii, ri, rii);
    }
    offPol.push([x, y]);
  }
  return offPol;
}

function getSlope(p, i) {
  var n = p.length;
  var xs = p[(i + 1) % n][0] - p[i % n][0];
  var ys = p[(i + 1) % n][1] - p[i % n][1];
  if (Math.abs(xs) == 0) {
    return "nan";
  }
  if (Math.abs(xs) < 0.1) {
    return "nan";
  }

  return ys / xs;
}

function getOffsetPointSi(pol, i, h) {
  var n = pol.length;
  var len_i = getDistance(pol, i);
  var si =
    pol[(i + 1) % n][1] - (h * (pol[(i + 1) % n][0] - pol[i % n][0])) / len_i;
  if (!si) {
    console.log(pol[(i + 1) % n], pol[i % n], len_i);
  }
  return si;
}

function getOffsetPointRi(pol, i, h) {
  var n = pol.length;
  var len_i = getDistance(pol, i);
  var ri =
    pol[(i + 1) % n][0] + (h * (pol[(i + 1) % n][1] - pol[i % n][1])) / len_i;
  if (!ri) {
    console.log(pol[(i + 1) % n], pol[i % n], len_i);
  }
  return ri;
}

function getDistance(p, i) {
  var n = p.length;
  return Math.sqrt(
    (p[(i + 1) % n][0] - p[i % n][0]) ** 2 +
      (p[(i + 1) % n][1] - p[i % n][1]) ** 2
  );
}

function checkIfAreaCounts(obj_type) {
  var id = searchForId(room_types_db, obj_type);
  var obj_props = room_types_db[id];
  if (obj_props) {
    var obj_name = obj_props.name;
    var obj_height = obj_props.props.height * store.floor_height;
    var obj_far = obj_props.props.far;
    var obj_mat = obj_props.props.mat;
  } else {
    var obj_name = store.room_types[id]; /* UNKNOWN: CHANGED i TO id */
    var obj_height = store.floor_height;
    var obj_far = false;
    var obj_mat = "none";
  }
  return obj_far;
}
export { createRoomPolygons,offsetRoomPols,getSlope,getOffsetPointSi,getOffsetPointRi,getDistance,checkIfAreaCounts };
