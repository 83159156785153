import $ from "jquery";
import {djangoUrl, ORIGIN} from "./url.constants";
import {store} from "../snaptrude/modules/utilityFunctions/Store";
import axios from "axios";
import { ScopeUtils } from "../snaptrude/libs/scopeFunctions";

function getViewsData() {
  let dataObject = {
    csrfmiddlewaretoken: store.csrf_token,
    floor_key: store.floorkey,
  }
  const formData = new FormData();
  for(let item in dataObject) formData.append(item, dataObject[item]);
  return axios.post(djangoUrl + "/getViewsData/", formData)
    .then((res) => {
      // console.log(res);
      ScopeUtils.addViewData(res.data);
    });
}

function saveViewMetaData (data, floorkey, key, TextureDetail) {
  let dataObject = {
    csrfmiddlewaretoken: store.csrf_token,
    floor_key: floorkey,
    data: data,
    fileName: key,
    textureDetail: TextureDetail,
  }
  const formData = new FormData();
  for(let item in dataObject) formData.append(item, dataObject[item]);
  return new Promise((resolve, reject) => {
    axios.post( djangoUrl + "/saveviewmetadata/", formData)
      .then((res) => {
        resolve(res);
        // if(res.data.error) return {error: res.data.error};
        // console.log(res);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function saveLowResImage(image, floorkey, key) {
  let dataObject = {
    csrfmiddlewaretoken: store.csrf_token,
    floor_key: floorkey,
    image: image,
    fileName: key,
  }
  const formData = new FormData();
  for(let item in dataObject) formData.append(item, dataObject[item]);
  return new Promise((resolve, reject) => {
    axios.post( djangoUrl + "/saveviewscreenshotlowres/", formData)
      .then((res) => {
        resolve(res);
        // if(res.data.error) return {error: res.data.error};
        // console.log(res);
      })
      .catch(error => {
        reject(error)
      })
  });
}

function saveHighResImage(image, floorkey, key) {
  let dataObject = {
    csrfmiddlewaretoken: store.csrf_token,
    floor_key: floorkey,
    image: image,
    fileName: key,
  }
  const formData = new FormData();
  for(let item in dataObject) formData.append(item, dataObject[item]);
  return new Promise((resolve, reject) => {
    axios.post( djangoUrl + "/saveviewscreenshothighres/", formData)
      .then((res) => {
        resolve(res);
        // if(res.data.error) return {error: res.data.error};
        // console.log(res);
      })
      .catch(error => {
        reject(error);
      });
  });

  return
}

function updateViewsData(imageKeys) {
  let dataObject = {
    csrfmiddlewaretoken: store.csrf_token,
    floor_key: store.floorkey,
    imagekeys: imageKeys.toString(),
  }
  const formData = new FormData();
  for(let item in dataObject) formData.append(item, dataObject[item]);
  return new Promise((resolve, reject) => {
    axios.post( djangoUrl + "/updateviewscreeshot/", formData)
      .then((res) => {
        resolve(res);
        // if(res.data.error) return {error: res.data.error};
        // console.log(res);
      })
      .catch(error => {
        reject(error);
      })
  });
}

export {
  getViewsData,
  saveViewMetaData,
  saveLowResImage,
  saveHighResImage,
  updateViewsData,
}
