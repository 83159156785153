import _ from 'lodash';
import { createGrid } from "../../../libs/sceneFuncs";
import { DisplayOperation } from "../../displayOperations/displayOperation";
import { mmToSnaptrudeUnits, setUserSetting, setUserSettingAndRecord } from "../../extrafunc";
import { commandUtils } from "../../commandManager/CommandUtils";
import { store } from "../Store";
import { overhangRoof } from '../../../libs/roofVisibilityFuncs';

export const userSetBIMPropertiesHandler = (function () {
  const _adjustUserEnteredValueWithTolerance = function (dataKey) {
    const userEnteredValue = _.get( store.$scope, dataKey);
    const valueInSnaptrudeUnits = parseFloat(
      DisplayOperation.getOriginalDimension(userEnteredValue)
    );
    const adjustedValue = DisplayOperation.convertToDefaultDimension(
      valueInSnaptrudeUnits
    );

    // console.warn("AG-RE: call $applyAsync")
    //  store.$scope.$applyAsync(() => {
    //   _.set( store.$scope, dataKey, adjustedValue);
    // });

    return adjustedValue;
  };

  const changeProjectProperty = function (property, options = {}) {
    store.projectProperties.properties[property].changeValue(
      store.$scope.userSetBIMProperties[property],
      options
    );
  };

  const getWallTypes = function () {
    let types =
      store.projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES;
    let typeArr = [];
    for (let type in types) {
      typeArr.push(types[type]);
    }
    return typeArr;
  };

  const changeRoofOverhang = function () {
    const overhangRoofLocal = function (value, meshId) {
      let mesh;
      if (meshId) mesh = store.scene.getMeshByUniqueID(meshId);
      overhangRoof(value, mesh);
    };
    overhangRoofLocal( store.$scope.userSetBIMProperties.roofOverhang);
  };

  const changeGridSnapEnabled = function () {
    const saveDataKey = "gridSnapEnabled";
    const uiDataKey = "userSetBIMProperties.gridSnapEnabled";

    const valueAfter =  store.$scope.userSetBIMProperties.gridSnapEnabled;
    const valueBefore = setUserSetting(saveDataKey, valueAfter);

    if (valueAfter === valueBefore) return;

    const uiDataPoint = [uiDataKey, valueBefore, valueAfter];
    const saveDataPoint = [saveDataKey, valueBefore, valueAfter];

    commandUtils.changeInUIOperations.expressCheckout(
      "dimensionSnapToggle",
      uiDataPoint,
      saveDataPoint
    );
  };

  const changeGridEnabled = function () {
    const saveDataKey = "gridEnabled";
    const uiDataKey = "userSetBIMProperties.gridEnabled";

    const valueAfter =  store.$scope.userSetBIMProperties.gridEnabled;
    const valueBefore = setUserSetting(saveDataKey, valueAfter);

    if (valueAfter === valueBefore) return;

    const uiDataPoint = [uiDataKey, valueBefore, valueAfter];
    const saveDataPoint = [saveDataKey, valueBefore, valueAfter];
    const optionsForToggle = {
      postExecuteCallback: createGrid,
      postUnExecuteCallback: createGrid,
    };
    commandUtils.changeInUIOperations.expressCheckout(
      "enableGridToggle",
      uiDataPoint,
      saveDataPoint,
      optionsForToggle
    );

    setUserSettingAndRecord(
      "gridEnabled",
       store.$scope.userSetBIMProperties.gridEnabled
    );
    createGrid( store.$scope.userSetBIMProperties.gridEnabled);
    //  store.$scope.$applyAsync();
  };

  const changeGridSnapThreshold = function () {
    const saveDataKey = "gridSnapThreshold";
    const uiDataKey = "userSetBIMProperties.gridSnapThreshold";

    const userEnteredValue = _.get( store.$scope, uiDataKey);

    const saveValueAfter = parseFloat(
      DisplayOperation.getOriginalDimension(userEnteredValue)
    );
    const saveValueBefore = setUserSetting(saveDataKey, saveValueAfter);

    if (saveValueBefore === saveValueAfter) return;

    const uiValueBefore =
      DisplayOperation.convertToDefaultDimension(saveValueBefore);
    const uiValueAfter = _adjustUserEnteredValueWithTolerance(uiDataKey);

    const uiDataPoint = [uiDataKey, uiValueBefore, uiValueAfter];
    const saveDataPoint = [saveDataKey, saveValueBefore, saveValueAfter];

    commandUtils.changeInUIOperations.expressCheckout(
      "dimensionSnapThresholdChange",
      uiDataPoint,
      saveDataPoint
    );
  };

  const changeGridUnit = function () {
    const saveDataKey = "gridUnitVal";
    const uiDataKey = "userSetBIMProperties.gridUnitVal";

    const userEnteredValue = _.get( store.$scope, uiDataKey);

    const saveValueAfter = parseFloat(
      DisplayOperation.getOriginalDimension(userEnteredValue)
    );
    const saveValueBefore = setUserSetting(saveDataKey, saveValueAfter);

    if (saveValueBefore === saveValueAfter) return;

    const uiValueBefore =
      DisplayOperation.convertToDefaultDimension(saveValueBefore);
    const uiValueAfter = _adjustUserEnteredValueWithTolerance(uiDataKey);

    const uiDataPoint = [uiDataKey, uiValueBefore, uiValueAfter];
    const saveDataPoint = [saveDataKey, saveValueBefore, saveValueAfter];
    const optionsForGridVal = {
      postExecuteCallback: createGrid,
      postUnExecuteCallback: createGrid,
    };
    commandUtils.changeInUIOperations.expressCheckout(
      "gridUnitValData",
      uiDataPoint,
      saveDataPoint,
      optionsForGridVal
    );
    let value = parseFloat(
      DisplayOperation.getOriginalDimension(
         store.$scope.userSetBIMProperties.gridUnitVal
      )
    );
    setUserSettingAndRecord("gridUnitVal", value);
    if ( store.$scope.userSetBIMProperties.gridEnabled)
      createGrid(
         store.$scope.userSetBIMProperties.gridEnabled,
         store.$scope.userSetBIMProperties.gridUnitVal
      );
  };

  const setProperty = function (property, value, setUnits) {
    setUserSettingAndRecord(property, value);
    if (setUnits) value = DisplayOperation.convertToDefaultDimension(value);
     store.$scope.userSetBIMProperties[property] = value;
  };

  // const getWallThickness = function () {
  //     // return DisplayOperation.getOriginalDimension( store.$scope.userSetBIMProperties.wallThickness);
  //     return  store.userSettingsInStructure.wallThickness;
  // };

  const getRoofOverhang = function () {
    // return DisplayOperation.getOriginalDimension( store.$scope.userSetBIMProperties.roofOverhang);
    return  store.userSettingsInStructure.roofOverhang;
  };

  const isGridSnapEnabled = function () {
    return  store.userSettingsInStructure.gridSnapEnabled;
  };

  const isGridEnabled = function () {
    return  store.userSettingsInStructure.gridEnabled;
  };

  const getGridSnapThreshold = function () {
    return  store.userSettingsInStructure.gridSnapThreshold;
  };

  const getGridUnit = function () {
    return  store.userSettingsInStructure.gridUnitVal;
  };

  const setInitialValues = function () {
    let roofOverhang = getDefaultRoofOverhangProperty();
    let gridSnapEnabled =  store.userSettingsInStructure.gridSnapEnabled;
    let gridEnabled =  store.userSettingsInStructure.gridEnabled;
    let gridSnapThreshold =
       store.userSettingsInStructure.gridSnapThreshold || mmToSnaptrudeUnits(100);
    let gridUnitVal =
       store.userSettingsInStructure.gridUnitVal || mmToSnaptrudeUnits(100);
    // let storeyUIToggle =  store.userSettingsInStructure.storeyUIToggle;
    // let layerUIToggle =  store.userSettingsInStructure.layerUIToggle;

    if (!_.isBoolean(gridSnapEnabled)) gridSnapEnabled = true;
    if (!_.isBoolean(gridEnabled)) gridEnabled = false;

    setProperty("roofOverhang", roofOverhang, true);
    setProperty("gridSnapEnabled", gridSnapEnabled, false);
    setProperty("gridSnapThreshold", gridSnapThreshold, true);
    setProperty("gridUnitVal", gridUnitVal, true);
    setProperty("gridEnabled", gridEnabled, false);
    // setProperty("storeyUIToggle", storeyUIToggle);
    // setProperty("layerUIToggle", layerUIToggle);
  };

  const getDefaultRoofOverhangProperty = function () {
    return  store.userSettingsInStructure.roofOverhang || mmToSnaptrudeUnits(0);
  };

  const updateUnitType = function (newUnit, e) {
    const defaultGridSnapThreshold = {
      0: "0.1",
      1: "100",
      2: "10",
      3: "4",
      4: "0'4\"",
    };

    const properties = ["roofOverhang", "gridSnapThreshold", "gridUnitVal"];
    store.projectProperties.updateUI();

    properties.forEach((property) => {
      if (property === "gridSnapThreshold") {
        let newValue = DisplayOperation.getOriginalDimension(
          defaultGridSnapThreshold[newUnit.id]
        );
         store.$scope.userSetBIMProperties[property] =
          DisplayOperation.convertToDefaultDimension(newValue);
        setUserSettingAndRecord(property, newValue);
      } else {
         store.$scope.userSetBIMProperties[property] =
          DisplayOperation.convertToDefaultDimension(
             store.userSettingsInStructure[property]
          );
      }
    });
  };

  return {
    changeRoofOverhang,
    changeGridSnapEnabled,
    changeGridSnapThreshold,
    changeGridUnit,
    getRoofOverhang,
    getGridSnapThreshold,
    isGridSnapEnabled,
    isGridEnabled,
    changeGridEnabled,
    setInitialValues,
    getDefaultRoofOverhangProperty,
    updateUnitType,
    getGridUnit,
    changeProjectProperty,
    getWallTypes,
  };
})();
