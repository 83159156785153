import straight from "../../assets/images/staricases/straight.png";
import dogLegged from "../../assets/images/staricases/dogLegged.png";
import lShaped from "../../assets/images/staricases/lShaped.png";
import square from "../../assets/images/staricases/square.png";
export const MATERIAL_OVERLAY_HEADER = "Material.jpg";
export const OVERLAY = "Overlay";
export const OPACITY = "Opacity";
export const SELECT_OVERLAY = "Select Overlay";
export const SAVE_EDITS = "Save Edits";
export const MATERIAL_NAME_PLACEHOLDER = "Enter Material Name";
export const VSCALE = "vScale";
export const VOFFSET = "vOffset";
export const ANGLE = "Angle";
export const USCALE = "uScale";
export const UOFFSET = "uOffset";
export const MATERIAL_TYPE = "Material Type";
export const MATERIAL_MENU = "materialMenu";
export const MATERIAL_EDIT_MENU = "materialEditMenu";
export const MATERIAL_OVERLAY_MENU = "materialOverlayMenu";
export const STAIRCASE = [
  {
    zip: null,
    type: "staircase",
    babylon: null,
    img: straight,
    subType: "straight",
    fullName: "Straight",
  },
  {
    zip: null,
    type: "staircase",
    babylon: null,
    img: dogLegged,
    subType: "dogLegged",
    fullName: "Dog Legged",
  },
  {
    zip: null,
    type: "staircase",
    babylon: null,
    img: lShaped,
    subType: "lShaped",
    fullName: "L Shaped",
  },
  {
    zip: null,
    type: "staircase",
    babylon: null,
    img: square,
    subType: "square",
    fullName: "Square",
  },
]