import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { removeObjects } from "./sceneEvents.js";
import { createRooms } from "./wall_generation.js";
import { $scope } from "./twoDimension.js";
import { toggleSavingLoopDisplay } from "./toolbarFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { autoSaveConfig } from "../modules/socket/autoSaveConfig.js";
import { djangoUrl } from "../../../app/services/url.constants.js";
// ##########################################
// Function to send data to the server
function send_scene_data(data, data2) {
  store.url = store.serverURL;
  $.ajax({
    url: store.url,
    data: {
      mode1: data,
      mode2: data2,
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      console.log(data);
      if (!$("#mass_mode").prop("checked")) {
        store.wall_pols = [data[0], data[1]];
        // room_pos = data[2];
        // room_rot = data[3];
        // room_scale = data[3];
        removeObjects();

        createRooms();
      }
      // draw_tape(data);
    },
    dataType: "json",
    type: "POST",
  });
}
//-------------------------------------------
function update_project_image(image, floor_key) {
  store.url = store.serverURL;
  $.ajax({
    url: "/updateProjectImage/",
    data: {
      csrfmiddlewaretoken: store.csrf_token,
      floor_key: floor_key,
      image: image,
    },
    success: function (data) {
      // empty
      // draw_tape(data);
    },
    dataType: "json",
    type: "POST",
  });
}
/*function saveViewMetaData(data, floorkey, key, TextureDetail) {
  $.ajax({
    url: djangoUrl + "/saveviewmetadata/",
    async: false,
    data: {
      csrfmiddlewaretoken: store.csrf_token,
      floor_key: floorkey,
      data: data,
      fileName: key,
      textureDetail: TextureDetail,
    },
    success: function () {
      console.log("saved");
    },
    dataType: "json",
    type: "POST",
  });
}

function saveLowResImage(image, floorkey, key) {
  store.url = store.serverURL;
  return new Promise((resolve, reject) => {
    $.ajax({
      url: djangoUrl + "/saveviewscreenshotlowres/",
      async: false,
      data: {
        csrfmiddlewaretoken: store.csrf_token,
        floor_key: floorkey,
        image: image,
        fileName: key,
      },
      success: function (res) {
        resolve(res);
      },
      error: function () {
        // check why there is error even for 200?
        resolve(0);
      },
      dataType: "json",
      type: "POST",
    });
  });
}

function saveHighResImage(image, floorkey, key) {
  $.ajax({
    url: djangoUrl + "/saveviewscreenshothighres/",
    async: false,
    data: {
      csrfmiddlewaretoken: store.csrf_token,
      floor_key: floorkey,
      image: image,
      fileName: key,
    },
    success: function () {
      console.log("saved");
    },
    dataType: "json",
    type: "POST",
  });
}

function updateViewsData(imageKeys) {
  $.ajax({
    url: djangoUrl + "/updateviewscreeshot/",
    data: {
      csrfmiddlewaretoken: store.csrf_token,
      floor_key: store.floorkey,
      imagekeys: imageKeys.toString(),
    },
    success: function () {
      console.log("Updated DB");
    },
    dataType: "json",
    type: "POST",
  });
}*/

function getRoomTypes() {
  store.url = store.serverURL;
  $.ajax({
    url: "/getRoomTypes/",
    data: {
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      //console.log("room types",data);
      store.$scope.room_types = data;
      // draw_tape(data);
    },
    dataType: "json",
    type: "GET",
  });
}

function update_project_name(project_name, floor_key) {
  store.url = store.serverURL;
  $.ajax({
    url: "/updateProjectName/",
    data: {
      csrfmiddlewaretoken: store.csrf_token,
      floor_key: floor_key,
      project_name: project_name,
    },
    success: function (data) {
      console.log(data);
      // draw_tape(data);
    },
    dataType: "json",
    type: "POST",
  });
}
// ##########################################

function auto_save() {
  let data = store.state[store.state.length - 1];
  store.chat.emit("save data", { canvas: data, key: store.floorkey_pk });
}

function updateProjectNameOnSever(project_name, floor_key) {
  let data = { floor_key: floor_key, project_name: project_name };
  let $http;
  $http.post("/updateProjectName/", data).then(
    function (success) {
      console.log(success.data);
    },
    function (error) {
      alert("err");
    }
  );
}

async function send_complete_scene_data_old(data2) {
  // if (fplan_mode != "True") {
  //     toggleSavingLoopDisplay(true);
  //     var data = getMeshData();
  // }
  // else {
  //     var data = createBabylon();
  //     data2 = data2 + "_fplan";
  // }
  BABYLON.Tools.CreateScreenshot(
    store.engine,
    store.scene.activeCamera,
    { height: 400 },
    function (data) {
      update_project_image(data, store.floorkey);
    }
  );
  toggleSavingLoopDisplay(true);
  /* eslint-disable */
  return new Promise(async function (resolve, reject) {
    // let  state = null
    // await clientDb.getLocalState("state"+floorkey).then((stat) =>{
    //     store.state = stat;
    // });
    let project_name = $("#saveAs").val();
    $("#saveAs").val("");
    let saveButton = $("#saveAsButton");
    //$('#saveAsButton').hide();
    $("#saveas_saving_loop").removeAttr("hidden");

    let saveData = StructureCollection.getInstance().getSnaptrudeJSON();
    url = store.serverURL;
    $.ajax({
      url: url,
      data: {
        canvasTrude: saveData,
        mode2: data2,
        FPScale: store.tape_scale_factor,
        name: project_name,
        // mode3: floor_height,
        // mode4: unit_scale,
        csrfmiddlewaretoken: store.csrf_token,
      },
      success: function (data) {
        // (data);
        // setTimeout(function() {
        //   send_complete_scene_data("save");
        //   ("sent");
        // }, 5000);
        // draw_tape(data);
        toggleSavingLoopDisplay(false);
        if (data === "done_saveas") {
          $("#saveAsButton").show();
          $("#saveas_saving_loop").attr("hidden", true);
          $("#saveAsModal").modal("toggle");
        }
        resolve("resolved");
      },
      dataType: "json",
      type: "POST",
    });
    $.ajax({
      url: autoSaveConfig.getSocketUrl() + "manualsave",
      data: {
        canvas: saveData,
        floorkey: store.floorkey,
        user: store.infoUser,
        csrfmiddlewaretoken: store.csrf_token,
      },
      success: function (data) {
        toggleSavingLoopDisplay(false);
        if (data.message && data.message === "success") {
          $("#saveAsButton").show();
          $("#saveas_saving_loop").attr("hidden", true);
          //$('#saveAsModal').modal('toggle');
        }
        resolve("resolved");
      },
      dataType: "json",
      type: "POST",
    });
  });
  /* eslint-enable */
}

async function send_complete_scene_data(saveOrSaveAs) {
  store.sceneLoadInProgress = true;
  BABYLON.Tools.CreateScreenshot(
    store.engine,
    store.scene.activeCamera,
    { height: 400 },
    function (data) {
      update_project_image(data, store.floorkey);
    }
  );
  toggleSavingLoopDisplay(true);
  let saveData =
    saveOrSaveAs === "saveas"
      ? null
      : StructureCollection.getInstance().getSnaptrudeJSON();
  let project_name = $("#saveAs").val();

  $("#saveAs").val("");
  let saveButton = $("#saveAsButton");
  $("#saveas_saving_loop").removeAttr("hidden");

  return new Promise((resolveOut, rejectOut) => {
    const promiseToMonoloith = new Promise((resolve, reject) => {
      $.ajax({
        url: store.serverURL,
        data: {
          canvasTrude: "notSavingAnymore",
          mode2: saveOrSaveAs,
          FPScale: store.tape_scale_factor,
          name: project_name,
          csrfmiddlewaretoken: store.csrf_token,
        },
        success: (data) => {
          if (
            !(
              data &&
              data.message &&
              data.message === "success" &&
              data.floorkey
            )
          ) {
            reject(new Error("Error in saving project"));
          }
          resolve(data.floorkey);
        },
        error: (error) => {
          reject(new Error("Error in saving project"));
        },
        dataType: "json",
        type: "POST",
      });
    });
    promiseToMonoloith
      .then((talkingAboutFloorkey) => {
        //Promise to saveMicro
        return new Promise((resolve, reject) => {
          let saveURL = undefined;
          if (talkingAboutFloorkey === store.floorkey) {
            saveURL = autoSaveConfig.getSocketUrl() + "manualsave";
            $.ajax({
              url: saveURL,
              data: {
                canvas: saveData,
                floorkey: store.floorkey,
                user: store.infoUser,
                csrfmiddlewaretoken: store.csrf_token,
              },
              success: (data) => {
                if (data.message && data.message === "success") {
                  resolve(true);
                  return;
                }
                // userpilot.trigger("1615973495vDdj4974");
                reject(new Error("Error in saving project #2"));
              },
              error: (error) => {
                // userpilot.trigger("1615973495vDdj4974");
                reject(new Error("Error in saving project #2"));
              },
              dataType: "json",
              type: "POST",
            });
          } else {
            saveURL = autoSaveConfig.getSocketUrl() + "saveas";
            $.ajax({
              url: saveURL,
              data: {
                source: store.floorkey,
                destination: talkingAboutFloorkey,
                user: store.infoUser,
                csrfmiddlewaretoken: store.csrf_token,
              },
              success: (data) => {
                if (data.message && data.message === "success") {
                  resolve(true);
                }
                reject(new Error("Error in saving project #2"));
              },
              error: (error) => {
                reject(new Error("Error in saving project #2"));
              },
              dataType: "json",
              type: "POST",
            });
          }
        });
      })
      .then((isSaved) => {
        store.sceneLoadInProgress = false;
        if (isSaved) {
          toggleSavingLoopDisplay(false);
          if (saveOrSaveAs === "saveas") {
            $("#saveAsButton").show();
            $("#saveas_saving_loop").attr("hidden", true);
            $("#saveAsModal").modal("toggle");
          }
          resolveOut(true);
        }
      })
      .catch((error) => {
        rejectOut(false);
        throw new Error("Error in saving project.");
      });
  });
}
export {
  send_scene_data,
  update_project_image,
  // saveViewMetaData,
  // saveLowResImage,
  // saveHighResImage,
  // updateViewsData,
  getRoomTypes,
  update_project_name,
  auto_save,
  updateProjectNameOnSever,
  send_complete_scene_data_old,
  send_complete_scene_data,
};
