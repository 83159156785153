import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from '../Button';
import Input from '../Input';
import * as styles from "../../containers/genericModal/styles";
import { RecentBox } from '../SortByBox';
import NamedAvatar from '../Avatar/NamedAvatar';
import authService from '../../services/auth.service';
import { color } from 'd3-color';
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc';
import inviteGraphic from "../../assets/icons/inviteGraphic.png";

const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  min-height: 200px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
  .invite-items{
    position: relative;
    max-height: 30vh;
    overflow-y: auto;
  }
  .invite-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    margin: 5px 0px;
    position: relatvie;
  }
  .invite-item .status{
    position: absolute;
    right: 30px;
    text-transform: capitalize;
  }
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: col;
  padding: 0px 10px;
  align-items: center;
`
const StyledCol = styled.div`
  flex: 1;
  padding: 10px;
`
const recentBoxStyle = {
  boxStyle: {
    border: "none",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: colors.primeBlack,
    width: "100%",
  },
  customDropdownStyle: {
    position: "absolute",
    top: "-1.25rem",
    left: 0,
    right: 0,
    borderRadius: "0.313rem",
    paddingLeft: 0,
  },
};

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
`;
function InviteModal({
  isOpen,
  onClose,
}) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [invites, setInvites] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  useEffect(() => {
    setEmail("");
    setEmailError(false)
    if(isOpen){
      authService.getAllInvites().then(resp => {
        const {success, invites} = resp;
        if(success && invites){
          setInvites(invites);
        }
      })
    }
  }, [isOpen])

  const handleInvite = async () => {
    if(emailError || !email) return;
    // alert("Send invite to " + email);
    const alreadyExists = invites.filter(invite => invite.email === email)
    if(alreadyExists.length !== 0){
      showToast(email + " is already invited");
      return;
    }
    setIsSubmitLoading(true);
    const {success, message} = await authService.sendInvite(email);
    setIsSubmitLoading(false);
    if(success){
      setEmail("");
      showToast(message, 3000, TOAST_TYPES.success)
      const newInvites = invites.slice();
      newInvites.push({email, status: "Sent"});
      setInvites(newInvites);
    }else{
      if(message){
        if(message.endsWith("is already a Snaptrude user.")) showToast(message)
        else showToast(message, 3000, TOAST_TYPES.error)
      } 
    }
  }
  
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Invite to Snaptrude
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
            <StyledRow>
                <StyledCol style={{textAlign: "center", position: "relative", paddingBottom: "5rem"}}>
                    Invite your classmates to extend your subscription.<br />
                    1 month free for every friend who signs up!
                    <img src={inviteGraphic} alt="invite-graphic" style={{position: "absolute", transform: "scale(0.85)", left: "6.5rem", top: "-5rem", zIndex: "-1"}} />
                </StyledCol>
            </StyledRow>
            <StyledRow>
                <StyledCol>
                <Input 
                    placeholder={"Enter email address to invite someone"}
                    type={"email"}
                    value={email}
                    onPress={(e)=>{setEmail(e.target.value)}}
                    errorMessage={"Please enter a valid email"}
                    error={emailError}
                    setError={setEmailError}
                />
                </StyledCol>
            </StyledRow>
            <div className="invite-items">
              {
                invites.map(invite => {
                  return <div className="invite-item" >
                    <span>
                      <NamedAvatar id={invite.email} text={invite.email} /> 
                      {/* <img className="profile-img" src={profile} alt="profile" /> */}
                    </span>
                    <span>{invite.email}</span>
                    <span className="status" style={invite.status === "accepted" ? {color: "#27AE60"} : {}}>{invite.status}</span>
                  </div>
                })
              }
            </div>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button onPress={onClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Cancel" />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button isLoading={isSubmitLoading} primary title="Send Invite" onPress={handleInvite} />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  )
}

export default InviteModal
