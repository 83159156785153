import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useOutsideClickHandler from "../../../common/utils/useOutsideClickHandler";
import { colors } from "../../../themes/constant";
import CustomSlider from "../../Slider";
import Text from "../../Text";

export const Container = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  padding: 0.5125rem 0.625rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  width: 10.25rem;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SliderWrapper = styled.div`
  flex: 0.8;
`;

const HeaderText = styled(Text)`
  margin: 0;
  font-size: 9px;
  color: ${colors.thinGray};
  margin-top: 0.375rem;
`;

const SelectedTextWrapper = styled.input`
  width: 2.625rem;
  height: 1.0625rem;
  border-radius: 5px;
  background-color: ${colors.white};
  padding: 0.125rem 0.3125rem 0.1875rem 0;
  margin: 0;
  font-size: 12px;
  text-align: right;
  line-height: 17px;
  font-weight: 500;
  border: none;
  text-transform: capitalize;
  &:focus {
    outline: none;
  }
`;

const MONTH_ARRAY = [
  "NaN",
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

const TIME_REGEX = /^\d{1,2}:{0,1}\d{0,2}/

export const SliderDateTimePicker = (props) => {
  const {
    selectedDate,
    selectedMonth,
    selectedTime,
    setSelectedDate,
    setSelectedMonth,
    setSelectedTime,
    setSelectedMonthText,
    selectedMonthText,
    onClose
  } = props;

  const [selectedTimeText, setSelectedTimeText] = useState(null);
  const ref = useRef(null);
  useOutsideClickHandler(ref, onClose)

  const getFormattedTime = (input) => {
    let time = selectedTime;
    if(input) time = input;
    const hours = ("0" + Math.floor(selectedTime / 2)).slice(-2);
    const minutes = selectedTime%2 ? "30" : "00";
    return `${hours}:${minutes}`
  };

  const handleTimeInput = (evt) => {
    let value = evt.target.value;
    if(evt.nativeEvent.inputType === "deleteContentBackward"){
      setSelectedTimeText(value);
      return;
    }
    if(!TIME_REGEX.test(value)) return;
    if(value.length === 6) return;
    if(value.length === 2) value += ":";
    if(value.length === 5) {
      let [hours, minutes] = value.split(":");
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      let newValue = hours*2;
      if(minutes>30) newValue += 1;
      setSelectedTime(newValue);
    }
    setSelectedTimeText(value);
  }

  const handleTimeSlider = (value) => {
    setSelectedTimeText(getFormattedTime(value));
    setSelectedTime(value)
  }

  useEffect(() => {
    const dateTime = new Date();
    const dateToSet = props.selectedDate || dateTime.getDate();
    const monthToSet = props.selectedMonth || dateTime.getMonth();
    const timeToSet = props.selectedTime || (dateTime.getHours() * 2) + parseInt(dateTime.getMinutes()/30); 
    setSelectedDate(dateToSet);
    setSelectedMonth(monthToSet);
    setSelectedMonthText(MONTH_ARRAY[monthToSet + 1]);
    setSelectedTime(timeToSet);
    setSelectedTimeText(getFormattedTime());
    return () => {}
  }, []);

  useEffect(() => {
    
    const dateToSet = props.selectedDate;
    const monthToSet = props.selectedMonth;
    const timeToSet = props.selectedTime; 

    setSelectedDate(dateToSet);
    setSelectedMonth(monthToSet);
    setSelectedMonthText(MONTH_ARRAY[monthToSet + 1]);
    setSelectedTime(timeToSet);
    setSelectedTimeText(getFormattedTime());
  }, [props.selectedDate, props.selectedTime, props.selectedMonth]);

  return (
    <Container ref={ref}>
      <HeaderText>Date</HeaderText>
      <Row>
        <SliderWrapper>
          <CustomSlider
            max={31}
            min={1}
            step={1}
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
          />
        </SliderWrapper>
        <SelectedTextWrapper
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </Row>
      <HeaderText>Month</HeaderText>
      <Row>
        <SliderWrapper>
          <CustomSlider
            max={11}
            min={0}
            step={1}
            value={selectedMonth}
            onChange={(value) => {
              setSelectedMonth(value);
              setSelectedMonthText(MONTH_ARRAY[value+1]);
            }}
          />
        </SliderWrapper>
        <SelectedTextWrapper
          value={selectedMonthText}
          onChange={(evt) => {
            if(evt.target.value && MONTH_ARRAY.indexOf(evt.target.value.toLowerCase()) !== -1){
              setSelectedMonth(MONTH_ARRAY.indexOf(evt.target.value.toLowerCase()));
            }
            setSelectedMonthText(evt.target.value);
          }}
        />
      </Row>
      <HeaderText>Time</HeaderText>
      <Row>
        <SliderWrapper>
          <CustomSlider
            max={47}
            min={0}
            step={1}
            value={selectedTime}
            onChange={handleTimeSlider}
          />
        </SliderWrapper>
        <SelectedTextWrapper 
          value={selectedTimeText} 
          onChange={handleTimeInput} 
        />
        {/* <SelectedTextWrapper value={selectedTime} onChange={(e) => {}} /> */}
      </Row>
    </Container>
  );
};
