import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../themes/constant'

const slideIn = keyframes`
0%: {opacity: 0.5;}
100%: {opacity: 1;}
`

const Wrapper = styled.div`
  font-family: DM Sans;
  position: absolute;
  left: 0.5em;
  bottom: 1rem;
  opacity: 0;
  align-self: flex-end;
  background: white;
  width: 14rem;
  min-height: 20rem;
  font-size: 0.8em;
  box-shadow: 0px 4px 30px rgba(197, 202, 220, 0.3);
  border-radius: 8px;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  z-index: 5;
`
const GifWrapper = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  height:12rem;
  width: 100%;

`

const Gif = styled.img`
  width: 100%;
`
const Title = styled.strong`
  color: ${colors.borderRed};
  font-size: 1.2em;
`

const Instructions = styled.div`
  color: ${colors.thinGray}
`


function Instructor({tool}) {
  const ref = useRef(null);
  useEffect(() => {
    if(ref && ref.current){
      ref.current.style.animation = "instructorAnimation 0.4s forwards";
    }
    return () => {
      
    }
  }, [])
  return (
    <>
    {tool ? 
      <Wrapper ref={ref}>
        <GifWrapper>
          {tool.gif ? 
          <Gif src={tool.gif} alt="gif" />
          :
          <></>
          }
        </GifWrapper>
        <Title>{tool.displayText}</Title>
        <Instructions>
          {tool.instructions?.map((instruction, index) => {
            return instruction
          })}
        </Instructions>
      </Wrapper>
    : <></>}
    </>
  )
}

export default Instructor
