import io from "socket.io-client";
import axios from "axios";
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { store } from "../utilityFunctions/Store.js";
import { socialUrl, socketUrl as saveUrl } from "../../../services/url.constants.js";
import { addComment, initComments, resolveComment as resolveCommentRedux, unresolveComment as unresolveCommentRedux, incomingComment as incomingCommentRedux, resetComments, removeComment, editCommentText } from '../../stateManagers/reducers/objectProperties/commentsSlice';
import { deleteRole, initIsPublic, makePublic as makePublicReducer, unmakePublic as unmakePublicReducer, initRoles, updateRole } from "../../stateManagers/reducers/objectProperties/rolesSlice.js";
import { getJWT } from "../../../services/jwt.service.js";

const socialConnect = (() => {
  if(!store.infoUser){
    store.infoUser = {id: localStorage.id, email: localStorage.email};
  }

  let author = {
    id: store.infoUser.id,
    email: store.infoUser.email,
    name: store.infoUser.name,
  };

  if (!author.name) {
    author.name = author.email;
  }

  const initAuthor = () => {
    if(!store.infoUser){
      store.infoUser = {id: localStorage.id, email: localStorage.email};
    }
  
    author = {
      id: store.infoUser.id,
      email: store.infoUser.email,
      name: store.infoUser.name,
    };

    if (!author.name) {
      author.name = author.email;
    }
  }

  const socketStatus = {
    value: "disconnected",
    connected: false,
    connected_room: null,
  };
  let socialSocket;  

  const connectSocket = floorkey => {
    socialSocket = io(socialUrl, {
      reconnection: true,
      forceNew: false,
      transports: ["websocket"],
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
    });

    socialSocket.on("connect", () => {
      console.log("Social Socket Connected");

      socialSocket.emit("join", { floorkey }, () => {
        socialSocket.emit('commentstree', {floorkey}, comments => {
        reduxStore.dispatch(initComments({ comments: comments }));
      });
      });
      socketStatus.value = "connected";
      socketStatus.connected = true;
    });

    socialSocket.on("disconnect", (reason) => {
      console.log(`Social Socket Disconnected: ${reason}`);
      socketStatus.value = `Disconnected: ${reason}`;
    });

    socialSocket.on("incomingcomment", (commentObject) => {
      _incomingComment(commentObject);
    });

    socialSocket.on('resolvecomment', commentID => {
      _resolveComment(commentID);
    });

    socialSocket.on('unresolvecomment', commentID => {
        _unresolveComment(commentID);
    });
  };

  const getSocket = () => socialSocket;

  const _postCommentAxios = (commentText, parentComment = null, sceneData = null) => {
    return new Promise((resolve, reject) => {
      // console.log(store.newComment.sceneData)
      const data = {
        comment: commentText,
        authorID: author.id,
        authorEmail: author.email,
        authorName: author.name,
        floorkey: store.floorkey,
        parentComment,
        origin: socialSocket.id,
        jwt: getJWT(),
        isAttachedToScene: false,
      };

      if(sceneData){
        data.isAttachedToScene = sceneData.isAttachedToScene ? true : false;
        data.cameraPosition = sceneData.camera.position;
        data.cameraAlpha = sceneData.camera.alpha;
        data.cameraBeta = sceneData.camera.beta;
        data.cameraRadius = sceneData.camera.radius;
        data.cameraTarget = sceneData.camera.target;
        data.cameraIsOrtho = store.$scope.isTwoDimension; /* isTwoDimension */
        data.cameraOrthoLeft = sceneData.camera.orthoLeft;
        data.cameraOrthoRight = sceneData.camera.orthoRight;
        data.cameraOrthoBottom = sceneData.camera.orthoBottom;
        data.cameraOrthoTop = sceneData.camera.orthoTop;

        data.absolutePosition = sceneData.absolutePosition;

        data.componentAttachedTo = sceneData.component.dsPropsId;
      }

      axios.post(socialUrl + '/postcomment', data)
        .then(data => {
          if(data && data.data && data.data.comment){
            resolve(data.data.comment);
            return;
          } else {
            console.log('Empty comment from backend', data);
            reject(false);
          }
        })
        .catch(error => {
          console.log('Error in _postCommentAxios', error);
          reject(error);
        });
    });
  };

  const postComment = (commentText, parentComment = null, sceneData = null) => {
    return new Promise((resolve, reject) => {
      _postCommentAxios(commentText, parentComment, sceneData)
        .then(data => {
          reduxStore.dispatch(addComment({ comment: data }));
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  };

  const _deleteCommentAxios = (commentID) => {
    return new Promise((resolve, reject) => {
      // console.log(store.newComment.sceneData)
  
      const data = {
        commentID: commentID,
  
        floorkey: store.floorkey,
  
        origin: socialSocket.id,
  
        jwt: getJWT(),
      };
  
      axios
        .post(socialUrl + "/deletecomment", data)
        .then((data) => {
          if (data && data.data) {
            resolve(data.data);
            return;
          } else {
            console.log("Empty comment from backend", data);
            reject(false);
          }
        })
        .catch((error) => {
          console.log("Error in _deleteCommentAxios", error);
          reject(error);
        });
    });
  };
  
  const deleteComment = (commentID) => {
    return new Promise((resolve, reject) => {
      _deleteCommentAxios(commentID)
        .then((data) => {
          // console.log(data);
          reduxStore.dispatch(removeComment({ commentID }));
          resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  };
  
  const _editCommentAxios = (commentID, newtext) => {
    return new Promise((resolve, reject) => {
      // console.log(store.newComment.sceneData)
  
      const data = {
        commentID: commentID,
  
        newtext: newtext,
  
        floorkey: store.floorkey,
  
        origin: socialSocket.id,
  
        jwt: getJWT(),
      };
  
      axios
        .post(socialUrl + "/editcomment", data)
        .then((data) => {
          if (data && data.data) {
            resolve(data.data);
            return;
          } else {
            console.log("Empty comment from backend", data);
            reject(false);
          }
        })
        .catch((error) => {
          console.log("Error in _editCommentAxios", error);
  
          reject(error);
        });
    });
  };
  
  const editComment = (commentID, newtext) => {
    return new Promise((resolve, reject) => {
      _editCommentAxios(commentID, newtext)
        .then((data) => {
          // console.log(data);
          reduxStore.dispatch(editCommentText({ commentID, newtext }));
          resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  };
  

  const _getAllCommentsAxios = () => {
    return new Promise((resolve, reject) => {
      const data = {
        floorkey: store.floorkey
      };

      axios.post(socialUrl + '/getall', data)
        .then(data => {
          resolve(data? data.data : null);
          return;
        })
        .catch(error => {
          reject(error);
        });

    });
  };

  const _getCommentsTreeAxios = () => {
    return new Promise((resolve, reject) => {
      const data = {
        floorkey: store.floorkey,
        jwt: getJWT()
      };

      axios.post(socialUrl + '/commentstree', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in _getCommentsTreeAxios');
            return;
          }
        })
        .catch(error => {
          reject(error);
          return;
        });
    });
  };

  const populateComments = () => {
    return new Promise((resolve, reject) => {
      _getCommentsTreeAxios
        .then(comments => {
          reduxStore.dispatch(initComments({ comments: comments}));
          resolve(true);
          return;
        })
        .catch(error => {
          console.log('Error in filling up comments', error);
          resolve(0);
          return;
        });
    });
  };

  const likeCommentAxios = commentID => {
    return new Promise((resolve, reject) => {
      const data = {
        commentID: commentID,
        user: author.id,
        floorkey: store.floorkey,
        jwt: getJWT()
      };

      axios.post(socialUrl + '/likecomment', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in likeCommentAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in liking comment', error);
          reject(error);
          return;
        })
    });
  };

  const unlikeCommentAxios = commentID => {
    return new Promise((resolve, reject) => {
      const data = {
        commentID: commentID,
        user: author.id,
        floorkey: store.floorkey,
        jwt: getJWT()
      };

      axios.post(socialUrl + '/unlikecomment', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in unlikeCommentAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in unliking comment', error);
          reject(error);
          return;
        })
    });
  };

  const _resolveCommentAxios = commentID => {
    return new Promise((resolve, reject) => {
      const data = {
        commentID: commentID,
        user: author.id,
        floorkey: store.floorkey,
        origin: socialSocket.id,
        jwt: getJWT()
      };

      axios.post(socialUrl + '/resolvecomment', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in _resolveCommentAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in resolving comment', error);
          reject(error);
          return;
        })
    });
  };

  const resolveComment = commentID => {
    return new Promise((resolve, reject) => {
      _resolveCommentAxios(commentID)
        .then(data => {
          reduxStore.dispatch(resolveCommentRedux({ commentID: commentID }));
          resolve(true);
          return;
        })
        .catch(error => {
          resolve(false);
          return;
        });
    });
  };

  const _unresolveCommentAxios = commentID => {
    return new Promise((resolve, reject) => {
      const data = {
        commentID: commentID,
        user: author.id,
        origin: socialSocket.id,
        jwt: getJWT()
      };

      axios.post(socialUrl + '/unresolvecomment', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in _unresolveCommentAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in unresolving comment', error);
          reject(error);
          return;
        });
    });
  };

  const unresolveComment = commentID => {
    return new Promise((resolve, reject) => {
      _unresolveCommentAxios(commentID)
        .then(data => {
          reduxStore.dispatch(unresolveCommentRedux({ commentID: commentID }));
          resolve(true);
          return;
        })
        .catch(error => {
          resolve(false);
          return;
        });
    });
  };

  const getAuthor = () => author;

  const _incomingComment = (comment) => {
    if(comment.origin === socialSocket.id){
      return;
    }
    reduxStore.dispatch(incomingCommentRedux({ commentObject: comment }));
  };

  const _resolveComment = returnObject => {
    if(returnObject.origin === socialSocket.id){
      return;
    }
    reduxStore.dispatch(resolveCommentRedux({ commentID: returnObject.commentID }));
  };

  const _unresolveComment = returnObject => {
    if(returnObject.origin === socialSocket.id){
      return;
    }
    reduxStore.dispatch(unresolveCommentRedux({ commentID: returnObject.commentID }));
  };

  const timeDifference = (previous, current = new Date()) => {
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;

    let elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.abs(Math.round(elapsed / 1000)) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < msPerYear) {
      return (
        Math.round(elapsed / msPerMonth) + " months ago"
      );
    } else {
      return Math.round(elapsed / msPerYear) + " years ago";
    }
  };

  const updatePermissionAxios = (userEmail, userID, permissionLevel) => {
    return new Promise((resolve, reject) => {
      const data = {
        authorEmail: author.email,
        authorName: author.name,
        authorID: author.id,
        floorkey: store.floorkey,
        userEmail: userEmail,
        permission: permissionLevel,
        jwt: getJWT()
      };
      axios.post(saveUrl + '/sharewith', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in updatePermissionAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in updating a permission', error);
          reject(error);
          return;
        });
    });
  };

  const updatePermission = (userObject, permissionLevel) => {
    return new Promise((resolve, reject) => {
      updatePermissionAxios(userObject.email, userObject.id, permissionLevel)
        .then(response => {
          const reduxObject = response.permissionObject;
          reduxObject.user = userObject;
          reduxStore.dispatch(updateRole({ role: reduxObject }));
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        })
    });
  };

  const removePermissionAxios = (userEmail, userID, permissionLevel) => {
    return new Promise((resolve, reject) => {
      const data = {
        authorID: author.id,
        floorkey: store.floorkey,
        userEmail: userEmail,
        jwt: getJWT()
      };
      axios.post(saveUrl + '/unsharewith', data)
        .then(data => {
          if(data && data.data){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in removePermissionAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in removing permission', error);
          reject(error);
          return;
        });
    });
  };

  const removePermission = (userObject, permissionLevel) => {
    return new Promise((resolve, reject) => {
      removePermissionAxios(userObject.email, userObject.id, permissionLevel)
        .then(response => {
          reduxStore.dispatch(deleteRole({ userEmail: userObject.email }));
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  };

  const getPermissionsAxios = () => {
    return new Promise((resolve, reject) => {
      const data = {
        authorID: author.id,
        floorkey: store.floorkey,
        authorEmail: author.email,
        jwt: getJWT()
      };
      axios.post(saveUrl + '/permissionlist', data)
        .then(data => {
          if(data && data.data && data.data.message === "success"){
            resolve(data.data);
            return;
          } else {
            reject('No data.data in getPermissionsAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in getting permission', error);
          reject(error);
          return;
        });
    });
  };

  const makePublicAxios = () => {
    return new Promise((resolve, reject) => {
      const data = {
        floorkey: store.floorkey,
        jwt: getJWT()
      };
      axios.post(saveUrl + '/makepublic', data)
        .then(data => {
          if(data && data.data && data.data.message === "success"){
            resolve(data.data.data);
            return;
          } else {
            reject('No data.data in getPermissionsAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in getting permission', error);
          reject(error);
          return;
        });
    });
  }

  const unmakePublicAxios = () => {
    return new Promise((resolve, reject) => {
      const data = {
        floorkey: store.floorkey,
        jwt: getJWT()
      };
      axios.post(saveUrl + '/unmakepublic', data)
        .then(data => {
          if(data && data.data && data.data.message === "success"){
            resolve(data.data.data);
            return;
          } else {
            reject('No data.data in getPermissionsAxios');
            return;
          }
        })
        .catch(error => {
          console.log('Error in getting permission', error);
          reject(error);
          return;
        });
    });
  }

  const populatePermissions = () => {
    return new Promise((resolve, reject) => {
      getPermissionsAxios()
        .then(permissionList => {
          reduxStore.dispatch(initRoles({ roles: permissionList.data }));
          reduxStore.dispatch(initIsPublic( { isPub: permissionList.isPublic } ));
          resolve(1);
          return;
        })
        .catch(error => {
          console.log('Error in populating permissions', error);
          resolve(0);
          return;
        });
    });
  };

  const makePublic = () => {
    return new Promise((resolve, reject) => {
      makePublicAxios().then(message => {
        reduxStore.dispatch(makePublicReducer());
        resolve(1);
        return;
      })
      .catch(error => {
        console.log('Error in updating permissions', error);
        resolve(0);
        return;
      })
    })
  }

  const unmakePublic = () => {
    return new Promise((resolve, reject) => {
      unmakePublicAxios().then(message => {
        reduxStore.dispatch(unmakePublicReducer());
        resolve(1);
        return;
      })
      .catch(error => {
        console.log('Error in updating permissions', error);
        resolve(0);
        return;
      })
    })
  }

  const forceDisconnect = () => {
    if(socialSocket){
      socialSocket.emit('forceDisconnect');
    }
    socialSocket = undefined;
  };

  const resetData = () => {
    reduxStore.dispatch(resetComments());
  };

  return {
    initAuthor,
    connectSocket,
    getSocket,
    getAuthor,
    postComment,
    deleteComment,
    editComment,
    likeCommentAxios,
    unlikeCommentAxios,
    populateComments,
    resolveComment,
    unresolveComment,
    timeDifference,
    updatePermission,
    removePermission,
    populatePermissions,
    makePublic,
    unmakePublic,
    forceDisconnect,
    resetData,
  };
})();
store.socialSocket = socialConnect;
window.socialSocket = socialConnect;
export { socialConnect };
