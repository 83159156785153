import BABYLON from "../../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../../modules/utilityFunctions/Store.js";
import {
  multiplyPathArrayByFactor,
  removeDuplicateVertices,
  multiplyPointArrayByFactor,
  convertArray3ToArray2,
  getSmallestEdge,
  isOrthogonal,
  changePolygonShape,
  negateYOfPointArray,
  convertArray2ToArray3,
  getTopVertices,
  getMidVerticesMod,
  getLongestEdge,
  lengthofEdge3D,
  getWallAngleMod,
  getMidVertices,
  removeDuplicatesNew,
  removecollinear, rotatePolygon, rotateCADPolygon,
} from "../twoD/twoServices.js";
import {
  drawCurveRoomMass,
  drawRoomMass,
  drawSketchMass,
  handleIncorrectDetection,
} from "../twoD/twoDrawRooms.js";
import { detectFailChecker } from "../twoD/detectFailService.js";
import {
  twoSnapDimension,
  snapDimensionFromEdgesOfCanvas,
  twoSnapShape,
  twoSnapShapeforSketch,
  twoSnapShapeForHoles,
} from "../twoD/twoSnap.js";
import { updateModifications, assignProperties } from "../sceneStateFuncs.js";
import {
  showToast,
  mmToSnaptrudeUnits,
  onSolid,
} from "../../modules/extrafunc.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import { massDissector } from "../../modules/createBuilding/massDissector.js";
import { makeid } from "../arrayFuncs.js";
import { straightwall } from "../../modules/factoryTypes/wall.types.js";
import { setLayerTransperancy } from "../sceneFuncs.js";
import {
  generateBrepForComponents,
  verifyBRepIntegrity,
} from "../brepOperations.js";
import { loadComponent2 } from "../interiors_gen.js";
import { Command } from "../../modules/commandManager/Command.js";
import { doorOperation } from "../../modules/meshoperations/doorOperation.js";
import { CommandManager } from "../../modules/commandManager/CommandManager.js";
import { csgOperator } from "../../modules/meshoperations/csgOperation.js";
import { PlainFloor } from "../../modules/factoryTypes/floor.types.js";
import { Floor } from "../../modules/snaptrudeDS/floor.ds.js";
import { StoreyMutation } from "../../modules/storeyEngine/storeyMutations.js";
import { ORIGIN } from "../../../services/url.constants.js";
import axios from "axios";
const imageURL = "/hosadetect/";
const sketchURL = "/detect/";
const cadURL = "/detectcad/";
const doorURL = "/detectdoor/";
const csgURL = "https://csgcgal.snaptru.de/csg/";
//const csgURL = 'http://0.0.0.0:8080/csg/'
const nodeURL = "https://wall.snaptru.de/splitcontours/";
//const nodeURL = 'http://127.0.0.1:6003/splitcontours/';
let wallThresh = 18;

const twoGetRooms = (
  point,
  roomName,
  roomType,
  roomID,
  levelID,
  structureID,
  isCurve,
  points,
  scale,
  strNum,
  storey,
  imageScaleFactor,
  imagePos
) => {
  const formData = new FormData();
  const data = {
    floorkey: store.floorkey,
    structure: strNum,
    storey: storey,
    x: point.x,
    y: point.y,
    curve: isCurve,
    // csrfmiddlewaretoken: store.csrf_token,
  }
  for(let item in data){
    formData.append(item, data[item]);
  }
  axios.post(ORIGIN+imageURL, formData)
  .then(response => {
    let {data} = response;
    if(!data) console.log("Error in twoGetRooms backend API call");
    else{
      if (isCurve) {
        /* AG-RE */
        // let elem = document.getElementById("load" + points[0] + points[1]);
        // if (elem) {
        //   elem.parentNode.removeChild(elem);
        // }
        const pathArray = multiplyPathArrayByFactor(
          data,
          1 / imageScaleFactor,
          scale
        );
        drawCurveRoomMass(
          pathArray,
          roomName,
          roomType,
          roomID,
          levelID,
          structureID,
          isCurve,
          storey
        );
      } else {
        detectFailChecker.doTheUsuals(data);
        //console.log("point", point);
        data = removeDuplicateVertices(data);
        let polygon = multiplyPointArrayByFactor(
          data,
          1 / imageScaleFactor,
          scale
        );
        if (imagePos) {
          polygon = polygon.map((point) => [
            point[0] + imagePos.x,
            point[1] - imagePos.z,
          ]);
        }
        try {
          // polygon = rotatePolygon(polygon, angle, centroidForRotation, rotationQuaternion);
          polygon = twoSnapDimension(polygon);
        } catch (err) {
          console.log("Error in snapping dimension. ", err);
        }

        try {
          polygon = snapDimensionFromEdgesOfCanvas(polygon);
        } catch (err) {
          console.log("Error in snapping from edges. ", err);
        }

        let threshold = 4; // ~ 1 metre
        let angleThreshold = 10; // degrees
        let waterTightThreshold = 4;
        if (scale > 0.5) {
          threshold = 10 * scale * imageScaleFactor; // ~ 1 metre
          angleThreshold = 10; // degrees
          waterTightThreshold = 10 * scale * imageScaleFactor;
        }
        //polygon = twoSnapShape(polygon, structureID, levelID, threshold, waterTightThreshold, angleThreshold);

        try {
          polygon = twoSnapShape(
            polygon,
            structureID,
            levelID,
            threshold,
            waterTightThreshold,
            angleThreshold,
            storey
          );
        } catch (err) {
          console.log("Error in snapping shape. ", err);
        }
        /* AG-RE */
        let elem = document.getElementById("load" + points[0] + points[1]);
        if (elem) {
          elem.parentNode.removeChild(elem);
        }
        drawRoomMass(
          polygon,
          roomName,
          roomType,
          roomID,
          levelID,
          structureID,
          isCurve,
          storey,
          null
        );
      }
      updateModifications();
    }
  })
  .catch(err => {
    console.log("Error in twoGetRooms backend call", err);
    let elem = document.getElementById("load" + points[0] + points[1]);
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  })
  // $.ajax({
  //   url: ORIGIN + imageURL,
  //   data: {
  //     floorkey: store.floorkey,
  //     structure: strNum,
  //     storey: storey,
  //     x: point.x,
  //     y: point.y,
  //     curve: isCurve,
  //     // csrfmiddlewaretoken: store.csrf_token,
  //   },
  //   success: function (data) {
  //     if (isCurve) {
  //       let elem = document.getElementById("load" + points[0] + points[1]);
  //       if (elem) {
  //         elem.parentNode.removeChild(elem);
  //       }
  //       const pathArray = multiplyPathArrayByFactor(
  //         data,
  //         1 / imageScaleFactor,
  //         scale
  //       );
  //       drawCurveRoomMass(
  //         pathArray,
  //         roomName,
  //         roomType,
  //         roomID,
  //         levelID,
  //         structureID,
  //         isCurve,
  //         storey
  //       );
  //     } else {
  //       detectFailChecker.doTheUsuals(data);
  //       //console.log("point", point);
  //       data = removeDuplicateVertices(data);
  //       let polygon = multiplyPointArrayByFactor(
  //         data,
  //         1 / imageScaleFactor,
  //         scale
  //       );
  //       if (imagePos) {
  //         polygon = polygon.map((point) => [
  //           point[0] + imagePos.x,
  //           point[1] - imagePos.z,
  //         ]);
  //       }
  //       try {
  //         polygon = twoSnapDimension(polygon);
  //       } catch (err) {
  //         console.log("Error in snapping dimension. ", err);
  //       }

  //       try {
  //         polygon = snapDimensionFromEdgesOfCanvas(polygon);
  //       } catch (err) {
  //         console.log("Error in snapping from edges. ", err);
  //       }

  //       let threshold = 4; // ~ 1 metre
  //       let angleThreshold = 10; // degrees
  //       let waterTightThreshold = 4;
  //       if (scale > 0.5) {
  //         threshold = 10 * scale * imageScaleFactor; // ~ 1 metre
  //         angleThreshold = 10; // degrees
  //         waterTightThreshold = 10 * scale * imageScaleFactor;
  //       }
  //       //polygon = twoSnapShape(polygon, structureID, levelID, threshold, waterTightThreshold, angleThreshold);

  //       try {
  //         polygon = twoSnapShape(
  //           polygon,
  //           structureID,
  //           levelID,
  //           threshold,
  //           waterTightThreshold,
  //           angleThreshold,
  //           storey
  //         );
  //       } catch (err) {
  //         console.log("Error in snapping shape. ", err);
  //       }
  //       let elem = document.getElementById("load" + points[0] + points[1]);
  //       if (elem) {
  //         elem.parentNode.removeChild(elem);
  //       }
  //       drawRoomMass(
  //         polygon,
  //         roomName,
  //         roomType,
  //         roomID,
  //         levelID,
  //         structureID,
  //         isCurve,
  //         storey,
  //         null
  //       );
  //     }
  //     updateModifications();
  //   },
  //   error: function (e, status) {
  //     detectFailChecker.addFailureAndCheck();
  //     let elem = document.getElementById("load" + points[0] + points[1]);
  //     if (elem) {
  //       elem.parentNode.removeChild(elem);
  //     }
  //     console.log("Error in detecting rooms out of image", e, status);
  //     //console.logInfo("Error in detecting rooms out of image", e, status);
  //     showToast("Error in detecting room.");
  //   },
  //   dataType: "json",
  //   type: "POST",
  // });
};

const twoDetectSketch = (
  point,
  edgeArray,
  roomName,
  roomType,
  roomID,
  structureID,
  scale,
  isCurve,
  yPos,
  levelID,
  points,
  holes = false,
  angle = 0,
  centroidForRotation
) => {
  const data = {
      point: [point.x, point.y, point.z].toString(),
      edgearray: edgeArray.toString(),
      csrfmiddlewaretoken: store.csrf_token,
      cadFlag: store.isCADFile,
      ipad: store.isiPad,
      isHoleFlag: holes,
  };
  const formData = new FormData();
  for(let item in data){
    formData.append(item, data[item]);
  }
  axios.post(ORIGIN+sketchURL, formData)
    .then(response => {
      let result = response?.data;
      if (!result) console.log("Error in sketch detect, response null");
      else{
        if (store.isCADFile) {
          let polygon = [];
          let holesList2D = [];
          let polygonWithHoles = [];

          if (result.holes) {
            let holesList = result.holes;
            for (let i = 0; i < holesList.length; i++) {
              holesList[i] = convertArray3ToArray2(holesList[i]);
              holesList2D.push(holesList[i]);
            }
            for (let i = 0; i < holesList2D.length; i++) {
              holesList2D[i] = twoSnapDimension(holesList2D[i]);
            }
          }
          polygon = convertArray3ToArray2(result.polygon);
          // polygon = rotateCADPolygon(polygon, angle, centroidForRotation);

          let elem = document.getElementById("load" + points[0] + points[1]);
          if (elem) {
            elem.parentNode.removeChild(elem);
          }

          polygonWithHoles.push(polygon);
          polygonWithHoles.push(holesList2D);

          drawSketchMass(
            polygonWithHoles,
            roomName,
            roomType,
            roomID,
            structureID,
            isCurve,
            yPos,
            levelID
          );
          updateModifications();
        } else {
          let polygon = [];
          let holesList2D = [];
          polygon = convertArray3ToArray2(result.polygon);
          if (result.holes) {
            let holesList = result.holes;
            for (let i = 0; i < holesList.length; i++) {
              holesList[i] = convertArray3ToArray2(holesList[i]);
              holesList2D.push(holesList[i]);
            }
            for (let i = 0; i < holesList2D.length; i++) {
              holesList2D[i] = twoSnapDimension(holesList2D[i]);
            }
          }
          let threshold = 4; // ~ 1 metre
          let angleThreshold = 10; // degrees
          let waterTightThreshold = 4;
          try {
            polygon = twoSnapDimension(polygon);
          } catch (err) {
            console.log("Error in snapping dimension. ", err);
          }

          try {
            polygon = snapDimensionFromEdgesOfCanvas(polygon);
          } catch (err) {
            console.log("Error in snapping from edges. ", err);
          }

          try {
            polygon = twoSnapShapeforSketch(
              polygon,
              structureID,
              levelID,
              threshold,
              waterTightThreshold,
              angleThreshold
            );
          } catch (err) {
            console.log("Error in snapping shape. ", err);
          }
          let elem = document.getElementById("load" + points[0] + points[1]);
          if (elem) {
            elem.parentNode.removeChild(elem);
          }
          try {
            holesList2D = twoSnapShapeForHoles(
              holesList2D,
              structureID,
              levelID,
              threshold,
              waterTightThreshold,
              angleThreshold
            );
          } catch (err) {
            console.log("error unionizing the holes");
          }
          let polygonWithHoles = [];
          polygonWithHoles.push(polygon);
          polygonWithHoles.push(holesList2D);
          drawSketchMass(
            polygonWithHoles,
            roomName,
            roomType,
            roomID,
            structureID,
            isCurve,
            yPos,
            levelID
          );
          updateModifications();
        }

      }
    })
    .catch(err => {
      let elem = document.getElementById("load" + points[0] + points[1]);
      if (elem) {
        elem.parentNode.removeChild(elem);
      }
      console.log("Error in sketch detection", err);
    })
};

const detectDoors = (
  point,
  storey,
  strNum,
  roomName,
  roomID,
  levelID,
  structureID,
  roomType,
  imageScaleFactor,
  scale
) => {
  $.ajax({
    url: doorURL,
    data: {
      floorkey: store.floorkey,
      structure: strNum,
      storey: storey,
      x: point.x,
      y: point.y,
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      //let doors = JSON.parse(data.rsp);
      let doors = [];
      //data.splice(2,2);
      doors.push(data);
      let wallThickness = mmToSnaptrudeUnits(200);
      for (let i = 0; i < doors.length; i++) {
        let smallestEdge = getSmallestEdge(doors[i]);
        //doors[i] = multiplyPointArrayByFactor(doors[i], 1/
        doors[i] = multiplyPointArrayByFactor(
          doors[i],
          1 / imageScaleFactor,
          scale
        );
        // let smallestEdge = getSmallestEdge (doors[i]);
        // let projectedPoints = addWallThickness(doors[i], wallThickness);
        // doors[i] = removeDuplicateVerticesModified(doors[i]);
        // doors[i] = doors[i].concat(projectedPoints.reverse());
        doors[i].push(doors[i][0]);
        if (isOrthogonal(doors[i]) === false) {
          doors[i] = [
            doors[i][0],
            doors[i][2],
            doors[i][3],
            doors[i][1],
            doors[i][0],
          ];
        }
        doors[i] = changePolygonShape(doors[i], smallestEdge, wallThickness);
        try {
          //drawRoomMass(polygon, roomName, "", roomID, levelID, structureID, false, storey);

          let scaledZPolygon = negateYOfPointArray(
            convertArray2ToArray3(doors[i])
          );
          scaledZPolygon.pop();
          let bottomVertices = scaledZPolygon.map(
            (array) => new BABYLON.Vector3(array[0], array[2], array[1])
          );
          let storeyData = StructureCollection.getInstance()
            .getStructureById(store.activeLayer.structure_id)
            .getStoreyData();
          let storeyHeight = storeyData.getStoreyByValue(
            store.activeLayer.storey
          ).height;
          //let wallHeight = storeyHeight - plainRoofParameters.roofDepth;
          let topVertices = getTopVertices(bottomVertices, storeyHeight);

          let midTopVertices = getMidVerticesMod(topVertices, wallThickness);
          let midBottomVertices = getMidVerticesMod(
            bottomVertices,
            wallThickness
          );
          midTopVertices.reverse();
          let faceVertices = midBottomVertices.concat(midTopVertices);
          //console.log("topVertices", topVertices);z

          //console.log("Bottom Vertices", bottomVertices);
          //faceVertices = [faceVertices[0], faceVertices[2], faceVertices[1], faceVertices[3]];
          let wallPoints = massDissector.getWallPoints(faceVertices);
          //console.log("Wall Bottom Coords", wallPoints.bottomCoords);
          //wallPoints = modifyWallPoints(wallPoints);
          let mesh = {
            walls: [wallPoints],
            structure_id: structureID,
            room_unique_id: makeid(3),
            storey: store.activeLayer.storey,
          };
          let wall = straightwall(mesh)[0];
          //massDissector.getUtilFunctions().handleWallJunctions.call(massDissector, mesh.walls);
          let structureCollection = StructureCollection.getInstance();
          let structure = structureCollection.getStructureById(structureID);
          let level = structure.getLevel(
            levelID.substr(0, 1),
            levelID.substr(1)
          );

          level.addWallToLevel(wall, false);
          onSolid(wall.mesh);
          setLayerTransperancy(wall.mesh);
          generateBrepForComponents(wall.mesh);

          let door = [];

          let bigEdge = getLongestEdge(bottomVertices);
          let changeInX = Math.abs(bigEdge[0].x - bigEdge[1].x);
          let changeInZ = Math.abs(bigEdge[0].z - bigEdge[1].z);

          let lengthOfWall = lengthofEdge3D(bigEdge);

          if (lengthOfWall <= mmToSnaptrudeUnits(800)) {
            door = "wooddoor_800";
          } else if (
            lengthOfWall > mmToSnaptrudeUnits(800) &&
            lengthOfWall < mmToSnaptrudeUnits(1200)
          ) {
            door = "wooddoor_900";
          } else if (
            lengthOfWall >= mmToSnaptrudeUnits(1200) &&
            lengthOfWall < mmToSnaptrudeUnits(2000)
          ) {
            door = "wooddoor_1200";
          } else if (
            lengthOfWall >= mmToSnaptrudeUnits(2000) &&
            lengthOfWall < mmToSnaptrudeUnits(3000)
          ) {
            door = "double_door_1500x2100";
          } else if (
            lengthOfWall >= mmToSnaptrudeUnits(3000) &&
            lengthOfWall < mmToSnaptrudeUnits(4000)
          ) {
            door = "double_door_1800x2100";
          } else if (
            lengthOfWall >= mmToSnaptrudeUnits(4000) &&
            lengthOfWall < mmToSnaptrudeUnits(5000)
          ) {
            door = "double_wooden_door_2400x2100";
          } else if (
            lengthOfWall >= mmToSnaptrudeUnits(5000) &&
            lengthOfWall < mmToSnaptrudeUnits(6000)
          ) {
            door = "FoldingSlidingDoor4t";
          } else if (lengthOfWall >= mmToSnaptrudeUnits(6000)) {
            door = "FoldingSlidingDoor6t";
            //door = "sliding_glass_door_4000";
          }

          let comp_path_dir = ORIGIN + "/media/1/objects";

          let doorMesh = store.scene.getMeshByName(door);
          if (!doorMesh) {
            loadComponent2(comp_path_dir, door, "Door");
            doorMesh = store.scene.getMeshByName(door);
          }
          doorMesh.scaling = new BABYLON.Vector3(
            doorMesh.originalScaling.x,
            doorMesh.originalScaling.y,
            doorMesh.originalScaling.z
          );
          doorMesh.visibility = 1;
          let doorUniqueId = doorMesh.uniqueId;
          let rotation = [];
          let position = [];
          let posY;

          // let doorInstance = doorMesh.createInstance(doorMesh.name);
          //let pos = new BABYLON.Vector3(position[0], position[1], position[2]);
          //doorInstance.setAbsolutePosition();

          // doorInstance.type = 'door';

          wall.mesh.computeWorldMatrix(true);
          doorMesh.computeWorldMatrix(true);

          let bbinfo1 = wall.mesh.getBoundingInfo();
          let bbinfo = doorMesh.getBoundingInfo();
          posY =
            bbinfo1.boundingBox.minimumWorld.y +
            bbinfo.boundingBox.extendSizeWorld.y;

          let wallAngle = getWallAngleMod(faceVertices);

          if (changeInX > changeInZ) {
            rotation = [0, 0, 0];
            position = [
              (bigEdge[0].x + bigEdge[1].x) / 2,
              posY,
              (bigEdge[0].z + bigEdge[1].z) / 2 - mmToSnaptrudeUnits(100),
            ];
          } else {
            rotation = [0, Math.PI / 2, 0];
            position = [
              (bigEdge[0].x + bigEdge[1].x) / 2 + mmToSnaptrudeUnits(100),
              posY,
              (bigEdge[0].z + bigEdge[1].z) / 2,
            ];
          }

          let doorCommandName = "addDoorOnFloorPlan";

          let _executeEvent = function () {
            let doorData = {
              srcDoor: doorUniqueId,
              doorInstance: null,
              wallUId: wall.mesh.uniqueId,
              structure_id: structureID,
              position: position,
              rotation: rotation,
            };
            let cmd = new Command(
              doorCommandName,
              doorData,
              doorOperation.getCommandLogic()
            );
            CommandManager.execute(cmd, true);
          };
          _executeEvent();

          //console.log("wallPoints", wallPoints);
          //straightwall()
        } catch (e) {
          console.log(
            "Error in creating door.",
            e,
            "\nVertices:",
            doors[i].toString()
          );
          showToast("Error in detecting door.");
        }
        //doors[i].reverse();
        //drawRoomMass(doors[i], roomName, roomType, roomID, levelID, structureID,false,  storey);
      }
    },
    error: function (e, status) {
      console.log("Error in detecting doors out of image", e, status);
      showToast("Error in detecting doors.");
    },
    dataType: "json",
    type: "POST",
  });
};

const detectAllWalls = (
  roomName,
  roomType,
  roomID,
  levelID,
  structureID,
  scale,
  strNum,
  storey,
  imageScaleFactor
) => {
  $.ajax({
    url: "/contours/",
    data: {
      floorkey: store.floorkey,
      structure: strNum,
      storey: storey,
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      //allWalls = JSON.parse(data.allWalls);
      //wall18 = allWalls[wallThresh];
      let wall18 = JSON.parse(data.wall18);
      // for(let i =0; i < wall18.length; i++){
      //     wall18[i] = multiplyPointArrayByFactor(wall18[i], 1/imageScaleFactor, scale);
      //     drawRoomMass(wall18[i], roomName, roomType, roomID, levelID, structureID,false,  storey);
      // }
      wallSplitter(
        wall18,
        imageScaleFactor,
        scale,
        roomName,
        roomType,
        roomID,
        levelID,
        structureID,
        storey
      );
      updateModifications();
    },
    error: function (e, status) {
      console.log("Error in detecting walls out of image", e, status);
      //console.logInfo("Error in detecting walls out of image", e, status);
      showToast("Error in detecting walls.");
    },
    dataType: "json",
    type: "POST",
  });
};

const wallSplitter = (
  contour,
  imageScaleFactor,
  scale,
  roomName,
  roomType,
  roomID,
  levelID,
  structureID,
  storey
) => {
  $.ajax({
    url: nodeURL,
    crossDomain: true,
    data: {
      contours: JSON.stringify(contour),
    },
    success: function (data) {
      let walls = data.walls;
      //console.log("walls ", walls);
      let nonBim = data.nonBim;
      //console.log("nonBim", nonBim);

      for (let i = 0; i < walls.length; i++) {
        let oneWall = removeDuplicateVertices(walls[i]);
        let polygon = multiplyPointArrayByFactor(
          oneWall,
          1 / imageScaleFactor,
          scale
        );
        polygon = removeDuplicateVertices(polygon);
        let smallestEdge = getSmallestEdge(polygon);
        let wallThickness = mmToSnaptrudeUnits(200);
        polygon = changePolygonShape(polygon, smallestEdge, wallThickness);
        polygon.push(polygon[0]);
        try {
          //drawRoomMass(polygon, roomName, "", roomID, levelID, structureID, false, storey);

          let scaledZPolygon = negateYOfPointArray(
            convertArray2ToArray3(polygon)
          );
          scaledZPolygon.pop();
          let bottomVertices = scaledZPolygon.map(
            (array) => new BABYLON.Vector3(array[0], array[2], array[1])
          );
          let storeyData = StructureCollection.getInstance()
            .getStructureById(store.activeLayer.structure_id)
            .getStoreyData();
          let storeyHeight = storeyData.getStoreyByValue(
            store.activeLayer.storey
          ).height;
          //let wallHeight = storeyHeight - plainRoofParameters.roofDepth;
          let topVertices = getTopVertices(bottomVertices, storeyHeight);

          let midTopVertices = getMidVertices(topVertices, wallThickness);
          let midBottomVertices = getMidVertices(bottomVertices, wallThickness);
          midTopVertices.reverse();
          let faceVertices = midBottomVertices.concat(midTopVertices);
          //console.log("topVertices", topVertices);z

          //console.log("Bottom Vertices", bottomVertices);
          //faceVertices = [faceVertices[0], faceVertices[2], faceVertices[1], faceVertices[3]];
          let wallPoints = massDissector.getWallPoints(faceVertices);
          //console.log("Wall Bottom Coords", wallPoints.bottomCoords);
          //wallPoints = modifyWallPoints(wallPoints);
          let mesh = {
            walls: [wallPoints],
            structure_id: structureID,
            room_unique_id: makeid(3),
            storey: store.activeLayer.storey,
          };
          let wall = straightwall(mesh)[0];
          //massDissector.getUtilFunctions().handleWallJunctions.call(massDissector, mesh.walls);

          let structureCollection = StructureCollection.getInstance();
          let structure = structureCollection.getStructureById(structureID);
          let level = structure.getLevel(
            levelID.substr(0, 1),
            levelID.substr(1)
          );

          level.addWallToLevel(wall, false);
          onSolid(wall.mesh);
          setLayerTransperancy(wall.mesh);
          generateBrepForComponents(wall.mesh);
          //console.log("wallPoints", wallPoints);
          //straightwall()
        } catch (e) {
          console.log(
            "Error in creating wall.",
            e,
            "\nVertices:",
            polygon.toString()
          );
        }
      }
      for (let i = 0; i < nonBim.length; i++) {
        let oneWall = removeDuplicateVertices(nonBim[i]);
        let polygon = multiplyPointArrayByFactor(
          oneWall,
          1 / imageScaleFactor,
          scale
        );
        polygon = removeDuplicateVertices(polygon);
        polygon.push(polygon[0]);
        try {
          drawRoomMass(
            polygon,
            roomName,
            "",
            roomID,
            levelID,
            structureID,
            false,
            storey
          );
        } catch (e) {
          console.log(
            "Error in creating wall.",
            e,
            "\nVertices:",
            polygon.toString()
          );
        }
      }
    },
    error: function (e, status) {
      console.log("Error in splitting walls", e, status);
    },
    dataType: "json",
    type: "POST",
  });
};

const csgAPI = (operation, meshesFormatted, options) => {
  // console.log("Calling CGAL");

  return new Promise((resolve, reject) => {
    $.ajax({
      url: csgURL,
      crossDomain: true,
      data: {
        operation,
        meshes: meshesFormatted,
        csrfmiddlewaretoken: store.csrf_token,
      },
      success: function (data) {
        if (options.callback) {
          options.callback(data);
        } else {
          let resultant = csgOperator.apiReturnHandler(data, options);
          if (!resultant) reject("BRep invalid for the combined roof");
          else resolve(resultant);
        }
      },
      error: function (e, status) {
        reject(e);
        console.log("Error in CSG ", e, status);
      },
      dataType: "json",
      type: "POST",
      // async: true
    });
  });
};

const wallSplitterCheck = (contour) => {
  let imageScaleFactor, scale, roomName, roomID, levelID, structureID, storey;
  $.ajax({
    url: nodeURL,
    data: {
      contours: JSON.stringify(contour),
    },
    success: function (data) {
      let walls = data.walls;
      let nonBim = data.nonBim;
      for (let i = 0; i < walls.length; i++) {
        let oneWall = removeDuplicateVertices(walls[i]);
        let polygon = multiplyPointArrayByFactor(
          oneWall,
          1 / imageScaleFactor,
          scale
        );
        polygon = removeDuplicateVertices(polygon);
        polygon.push(polygon[0]);
        try {
          drawRoomMass(
            polygon,
            roomName,
            "",
            roomID,
            levelID,
            structureID,
            false,
            storey
          );
        } catch (e) {
          console.log(
            "Error in creating wall.",
            e,
            "\nVertices:",
            polygon.toString()
          );
        }
      }
      for (let i = 0; i < nonBim.length; i++) {
        let oneWall = removeDuplicateVertices(nonBim[i]);
        let polygon = multiplyPointArrayByFactor(
          oneWall,
          1 / imageScaleFactor,
          scale
        );
        polygon = removeDuplicateVertices(polygon);
        polygon.push(polygon[0]);
        try {
          drawRoomMass(
            polygon,
            roomName,
            "",
            roomID,
            levelID,
            structureID,
            false,
            storey
          );
        } catch (e) {
          console.log(
            "Error in creating wall.",
            e,
            "\nVertices:",
            polygon.toString()
          );
        }
      }
    },
    error: function (e, status) {
      console.log("Error in splitting walls", e, status);
    },
    dataType: "json",
    type: "POST",
  });
};

const autoDetectCad = (
  edgeArray,
  roomName,
  roomType,
  roomID,
  scale,
  structureID,
  yPos,
  levelID,
  storey,
  strNum,
  imageScaleFactor
) => {
  $.ajax({
    url: cadURL,
    data: {
      edgearray: edgeArray.toString(),
      csrfmiddlewaretoken: store.csrf_token,
      cadFlag: store.isCADFile,
    },
    success: function (result) {
      let allPolygons = result;
      let holesList2D = [];
      for (let i = 0; i < allPolygons.length; i++) {
        let polygonWithHoles = [];
        allPolygons[i] = convertArray3ToArray2(allPolygons[i]);
        //if (((allPolygons[i][0][0] === 1 || allPolygons[i][0][1] === 1) && (allPolygons[i][allPolygons[i].length - 1][0] === 1 || allPolygons[i][allPolygons[i].length - 1][1] === 1))) {
        //    continue;
        //}
        allPolygons[i] = multiplyPointArrayByFactor(
          allPolygons[i],
          1 / imageScaleFactor,
          scale
        );
        polygonWithHoles.push(allPolygons[i]);
        polygonWithHoles.push(holesList2D);
        drawSketchMass(
          polygonWithHoles,
          roomName,
          roomType,
          roomID,
          structureID,
          0,
          yPos,
          levelID
        );
      }
    },
    error: function (e, status) {
      console.log("Error in detecting rooms out of CAD.", status, e);
      showToast("Error in detecting room.");
    },
    dataType: "json",
    type: "POST",
  });
};

const detectRoomOnFloorPlan = (
  roomName,
  roomType,
  roomID,
  levelID,
  structureID,
  scale,
  strNum,
  storey,
  imageScaleFactor
) => {
  $.ajax({
    url: "/detectroomonfloorplan/",
    data: {
      floorkey: store.floorkey,
      structure: strNum,
      storey: storey,
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      let allPolygons = data;
      for (let i = 0; i < allPolygons.length; i++) {
        let polygon = allPolygons[i];
        if (
          (polygon[0][0] === 1 || polygon[0][1] === 1) &&
          (polygon[polygon.length - 1][0] === 1 ||
            polygon[polygon.length - 1][1] === 1)
        ) {
          continue;
        }
        polygon = multiplyPointArrayByFactor(
          allPolygons[i],
          1 / imageScaleFactor,
          scale
        );
        polygon = negateYOfPointArray(convertArray2ToArray3(polygon));
        polygon.reverse().pop();

        polygon = removeDuplicatesNew(polygon);
        polygon = removecollinear(polygon);

        let floor = PlainFloor(polygon);
        floor.storey = storey;
        floor.type = "Floor";
        floor.structure_id = structureID;

        const structureCollection = StructureCollection.getInstance();
        const talkingAboutStructure =
          structureCollection.getStructureById(structureID);
        const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelID);

        talkingAboutLevel.addObjectToLevel(new Floor(floor), false);

        floor.getSnaptrudeDS().assignProperties();
        StoreyMutation.assignStorey(floor.getSnaptrudeDS());

        if (!verifyBRepIntegrity(floor.getSnaptrudeDS().brep)) {
          handleIncorrectDetection(floor);
          return false;
        }

        assignProperties(floor, null, "floor");
        onSolid(floor);
        setLayerTransperancy(floor);
        //drawRoomMass(polygon, roomName, roomType, roomID, levelID, structureID, false, storey);
      }
    },
    error: function (e, status) {
      console.log("", e, status);
      showToast("Error in detecting rooms.");
    },
    dataType: "json",
    type: "POST",
  });
};

const notifier = (message) => {
  const url = "/notify/";
  return new Promise((resolve, reject) => {
    if (window.location.hostname.toLowerCase().includes("127.0.0.1")) {
      resolve(200);
    } else if (window.location.hostname.toLowerCase().includes("localhost")) {
      resolve(200);
    } else {
      $.ajax({
        url: url,
        data: {
          message: message,
          csrfmiddlewaretoken: store.csrf_token,
        },
        success: (response) => resolve(response),
        error: (error) => reject(error),
        dataType: "json",
        type: "POST",
      });
    }
  });
};

const typicalNotifier = async(whatHappened) => {
  try {
    if(window.location.hostname.toLowerCase().includes("127.0.0.1") ||
       window.location.hostname.toLowerCase().includes("localhost"))
    {
      return { success: false }
    }

      const url = ORIGIN + "/notify/";

      const formData = new FormData()

      formData.append("message", JSON.stringify(whatHappened))
      formData.append("userid", store.infoUser.id)
      formData.append("useremail", store.infoUser.email)
      formData.append("floorkey", store.floorkey)
      formData.append("origin", window.origin)
      formData.append("url", window.origin + "/model/" + store.floorkey + "/")
      
      const res = await axios.post(url, formData)
      
      if(res.status == 200) return { success: true }
      else return { success: false }
  } catch(err) {
    console.log("Unable to notify on slack. Error: ", err)
    return { success: false }
  }
};
export {
  imageURL,
  sketchURL,
  cadURL,
  doorURL,
  csgURL,
  nodeURL,
  wallThresh,
  twoGetRooms,
  twoDetectSketch,
  detectDoors,
  detectAllWalls,
  wallSplitter,
  csgAPI,
  wallSplitterCheck,
  autoDetectCad,
  detectRoomOnFloorPlan,
  notifier,
  typicalNotifier,
};
