import { forgeUrl } from '../../../services/url.constants'

export const forgeConfig = Object.freeze({
  activityName: 'CADLinePolylineMlineActivity+dev',
  signalRURL: forgeUrl + '/api/signalr/designautomation',
  workItemURL: forgeUrl + '/api/forge/designautomation/workitems',
  revitWorkItemURL: forgeUrl + '/api/forge/designautomation/revit/workitems',
  cancelWorkItemURL: forgeUrl + '/api/forge/designautomation/cancel_workitems',
  graphURL: 'http://127.0.0.1:7000/cycle',
  wallSplitterURL: 'https://wall.snaptru.de/splitcontours/',
});
