import io from "socket.io-client";
import { store } from "../utilityFunctions/Store.js";
import { createRevitExportWorkItem } from "../../../services/export.service.js";
import { v4 as uuidv4 } from 'uuid';
import { rvtExportDownloadResult, rvtExportOperationStatus } from "./revitExportListeners.js";
import { cadImportDownloadResult, cadImportOnComplete, cadImportOnError, cadImportOperationStatus } from "./cadImportListeners.js";
import { forgeUrl } from '../../../services/url.constants'

export const ForgeConnection = (() => {
  let forgeSocket;
  let roomId;

  const _connect = () => {
    forgeSocket = io(forgeUrl, {
      reconnection: true,
      forceNew: false,
      transports: ["websocket"],
      reconnectionAttempts: 100,
      reconnectionDelay: 7000,
    });
  };

  const reset = () => {
    roomId = undefined;
    if (forgeSocket) forgeSocket.disconnect();
    forgeSocket = undefined;
  }

  const getRoomId = () => {
    if (roomId) return roomId;

    roomId = store.sessionId || `${store.floorkey}_${uuidv4()}`

    return roomId;
  };

  const revitExport = (outputFormat) => {
    createRevitExportWorkItem(outputFormat).then(data => {
      console.log(data)
    })
  };

  const connectSocket = () => {
    if (forgeSocket) return;

    _connect();

    // forgeSocket.removeAllListeners('connect');
    forgeSocket.on("connect", () => {
      console.log(`Connected to Forge with ID ${forgeSocket.id}`);

      const params = {
        roomId: getRoomId(),
      };
      forgeSocket.emit("join", params, function () {
        console.log(`ForgeSocket joined room ${params.roomId}`);

      });
    });

    forgeSocket.on("downloadCADResult", cadImportDownloadResult, 1000);
    forgeSocket.on("onComplete", cadImportOnComplete);
    forgeSocket.on("onCadImportError", cadImportOnError);
    forgeSocket.on('cadOperationStatus', cadImportOperationStatus);

    forgeSocket.on("downloadRVTResult", rvtExportDownloadResult);
    forgeSocket.on('operationStatus', rvtExportOperationStatus);

    forgeSocket.on("disconnect", (reason) => {
      console.log("forge socket disconnected", reason);
    })

    forgeSocket.on("reconnecting", (attemptCount) => {
      console.log("forge socket reconnecting, reconnect attempt:", attemptCount);
    })
  };

  const getSocket = () => forgeSocket;

  const forceDisconnect = () => {
    if(forgeSocket) forgeSocket.emit('forceDisconnect');

    forgeSocket = undefined;
  };

  return {
    connectSocket,
    getRoomId,
    getSocket,
    revitExport,
    forceDisconnect,
    reset
  };
})();