import { getDimensionInSnaptrudeUnits } from ".";
import { createCustomMesh } from "../../libs/massModeling";
import { setLayerTransperancy } from "../../libs/sceneFuncs";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { onSolid } from "../extrafunc";
import { Roof } from "../snaptrudeDS/roof.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import { getPointsData } from "./extraFunctions";
import { drawWithGeometryData } from "./floor";
import speckleConstants from "./speckleConstants";
import { drawVoids } from "./ceillings";

const createRoof = (path_bottom, height, storey, options) => {
  let createdMesh;
  if (options.geometry) {
    createdMesh = drawWithGeometryData(options.geometry);
  } else {
    createdMesh = createCustomMesh(path_bottom, height, null);
    let voidsData = options.voids;
    createdMesh = drawVoids(voidsData, createdMesh, height);

    // let segments = floor.outline.segments;

    // let path_bottom = getPointsData(segments);
    // path_bottom = getDimensionInSnaptrudeUnits(path_bottom, speckleConstants.speckleUnits[options.unit]);
    // path_bottom = path_bottom.map((parameter) => [
    //   parameter[0],
    //   parameter[1],
    //   parameter[2] - thickness,
    // ]);
    // createdMesh = createCustomMesh(path_bottom, thickness, null);
  }
  createdMesh.structure_id = store.activeLayer.structure_id;
  createdMesh.layerMaterials = options.layerMaterials;
  createdMesh.floorComponent = options.floorComponent;
  let roof = new Roof(createdMesh);
  createdMesh.type = "Roof"; //throwAway is overwritten when mesh is added to level
  roof.storey = storey?.value;
  roof.room_type = "roof";
  roof.assignProperties({ floorComponent: options.floorComponent });
  roof.mesh.isVisible = true;
  let originalPosition = roof.mesh.position.clone();
  roof.revitMetaData = {
    elementId: options.elementId,
    type: options.type,
    family: options.family,
    curveId: options.curveId,
    originalVoids: !!options.voids
      ? Object.keys(options.voids).map((k) => {
          return { id: k, path: options.voids[k] };
        })
      : null,
    originalProfile: path_bottom,
    originalPosition: originalPosition,
  };
  roof.height = createdMesh.height;

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  talkingAboutLevel.addRoofToLevel(roof, false);
  if (storey) {
    if (roof.storey !== storey?.value) {
      storey.addElement(roof);
    }
  } else {
    StoreyMutation.assignStorey(roof);
  }
  onSolid(createdMesh);
  setLayerTransperancy(createdMesh);
  return roof;
};

export { createRoof };
