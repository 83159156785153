import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes/constant';

const Wrapper = styled.span`
    position: absolute;
    top: -10px;
    right: -10px;
    color: ${colors.fullWhite};
    background: ${colors.brightRed};
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    padding: 0.5em;
`

function Badge({style, ...props}) {
    return (
        <Wrapper style={style} {...props} />
    )
}

export default Badge
