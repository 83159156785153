import BABYLON from "../babylonDS.module.js";
import {store} from "../utilityFunctions/Store.js";
import {cameraController} from "./cameraController.js";
import {scrollZoom} from "../../libs/zoomFuncs.js";
import {scenePickController} from "../utilityFunctions/scenePickController";
import {addTemporaryCursor, removeTemporaryCursor} from "../../../containers/editor/cursorHandler";
import {cursor} from "../../../themes/cursor.js";
import {is2D} from "../../libs/twoDimension.js";
import {nonDefaultMesh} from "../../libs/sceneStateFuncs";

let pointA = null;
let pointB = null;
let prevPinchDistance = null;
let pinchPrecision = 5;
let wasPointerDownFired = false;

var onPointerDownRotate = function (evt) {
  if (!cameraController.validate(evt)) return;
  if (!is2D()) {
    const {which, ctrlKey} = evt;
    let isGoingToOrbit = false;
    if (which === 1 && store.isOrbitModeActive) {
      isGoingToOrbit = true;
    } else if (which === 2 && !ctrlKey) {
      isGoingToOrbit = true;
    }
    if (isGoingToOrbit) addTemporaryCursor(cursor.orbit);
    wasPointerDownFired = true;
  }

  if (pointA) {
    if (pointA.pointerId === evt.pointerId) {
      pointA.x = evt.clientX;
      pointA.y = evt.clientY;
    } else {
      pointB = {};
      pointB.x = evt.clientX;
      pointB.y = evt.clientY;
      pointB.pointerId = evt.pointerId;
    }
  } else {
    pointA = {};
    pointA.x = evt.clientX;
    pointA.y = evt.clientY;
    pointA.pointerId = evt.pointerId;
  }

  let pt2;

  if (store.selectionStack.length) {
    let pos = store.selectionStack[0].position.clone();
    for (let i = 1; i < store.selectionStack.length; i++) {
      pos.x += store.selectionStack[i].position.x;
      pos.y += store.selectionStack[i].position.y;
      pos.z += store.selectionStack[i].position.z;
    }
    pos.x /= store.selectionStack.length;
    pos.y /= store.selectionStack.length;
    pos.z /= store.selectionStack.length;

    pt2 = findProjection(
      pos,
      store.newScene.activeCamera.position,
      store.newScene.activeCamera.target
    );
  } else {
    let pos = scenePickController.pick()?.pickedPoint;
    if (!pos) {
      pos = _getCombinedMeshCenter(
        store.newScene.activeCamera.getActiveMeshes()
      );
    }
    pt2 = findProjection(
      pos,
      store.newScene.activeCamera.position,
      store.newScene.activeCamera.target
    );
  }

  if (store.newScene.activeCamera.position.y > pt2.y) {
    store.newScene.activeCamera.setTarget(pt2);
  }
};

var onPointerMoveRotate = function (evt) {
  if (!cameraController.validate(evt)) return;

  if (store.isiPad || store.isMobile) {
    if (
      store.newScene.activeCamera.mode === BABYLON.Camera.ORTHOGRAPHIC_CAMERA
    ) {
      if (pointA && pointB) {
        let ed = pointA.pointerId === evt.pointerId ? pointA : pointB;
        ed.x = evt.clientX;
        ed.y = evt.clientY;
        let distX = (pointA.x - pointB.x) / store.canvas.width;
        let distY = (pointA.y - pointB.y) / store.canvas.height;
        let pinchSquaredDistance = distX * distX + distY * distY;
        if (prevPinchDistance) {
          if (prevPinchDistance > pinchSquaredDistance) {
            evt.deltaY = 1 / pinchPrecision;
          } else {
            evt.deltaY = -1 / pinchPrecision;
          }
        }
        if (Math.abs(pinchSquaredDistance - prevPinchDistance) > 0.001) {
          scrollZoom(evt, Math.abs(evt.deltaY));
          store.newScene.activeCamera.multiTouchPanAndZoom = false;
          // store.newScene.activeCamera.panningInertia = 0;
          // console.log("Zoom", pinchSquaredDistance - prevPinchDistance);
        } else {
          // store.newScene.activeCamera.panningInertia = 0.2;
        }
        prevPinchDistance = pinchSquaredDistance;
      }
    }
  }
  // console.log("ontouch move");
};

var onPointerUpRotate = function (evt) {
  if (!cameraController.validate(evt)) return;
  if(!is2D()){
    const {which, ctrlKey} = evt;
    let wasOrbiting = false;
    if(which === 1 && store.isOrbitModeActive){
      wasOrbiting = true;
    }else if(which === 2 && !ctrlKey){
      wasOrbiting = true;
    }
    // console.log("%c wasOrbiting " + wasOrbiting, "background-color: #3b82f6; color: white;");
    if(wasOrbiting && wasPointerDownFired) removeTemporaryCursor();
    wasPointerDownFired = false;
  }
  // store.newScene.activeCamera.attachControl(canvas, true, false);
  pointA = null;
  pointB = null;
  //console.log("ontouch up");
};

var findProjection = function (P, A, B) {
  let AP = getVector(A, P);
  let AB = getVector(A, B);
  let t1 = dotProduct(AP, AB);
  let t2 = dotProduct(AB, AB);
  return new BABYLON.Vector3(
    A.x + (t1 / t2) * AB.x,
    A.y + (t1 / t2) * AB.y,
    A.z + (t1 / t2) * AB.z
  );
};

function getVector(A, B) {
  return new BABYLON.Vector3(B.x - A.x, B.y - A.y, B.z - A.z);
}

function dotProduct(A, B) {
  return A.x * B.x + A.y * B.y + A.z * B.z;
}

function computeVisibleBounds(meshes) {
  // Initialize min and max values for reference
  let min = new BABYLON.Vector3(1e5, 1e5, 1e5);
  let max = new BABYLON.Vector3(-1e5, -1e5, -1e5);

  for (let i = 0; i < meshes.length; i++) {
    const mesh = meshes[i]

    // Ignore mesh if not in camera bounds or is support mesh
    if (!nonDefaultMesh(mesh)) continue;

    mesh.computeWorldMatrix(true)
    const bounds = mesh.getBoundingInfo();
    if (bounds.minimum.x < min.x) min.x = bounds.minimum.x;
    if (bounds.minimum.y < min.y) min.y = bounds.minimum.y;
    if (bounds.minimum.z < min.z) min.z = bounds.minimum.z;
    if (max.x < bounds.maximum.x) max.x = bounds.maximum.x;
    if (max.y < bounds.maximum.y) max.y = bounds.maximum.y;
    if (max.z < bounds.maximum.z) max.z = bounds.maximum.z;
  }

  return new BABYLON.BoundingInfo(min, max);
}

/**
 * Calculate the combined center of given meshes
 * @param {Mesh[]} meshes
 * @returns {Vector3}
 * @private
 */
function _getCombinedMeshCenter(meshes) {
  let count = 0;
  let result = BABYLON.Vector3.Zero();

  for (let i = 0; i < meshes.length; i++) {
    const mesh = meshes.data[i]

    // Ignore mesh if not in camera bounds or is support mesh
    if (!nonDefaultMesh(mesh)) continue;

    mesh.computeWorldMatrix(true)
    result.addInPlace(mesh.absolutePosition.clone());
    count++;
  }

  return result.scale(1 / count);
}

export {
  pointA,
  pointB,
  prevPinchDistance,
  pinchPrecision,
  onPointerDownRotate,
  onPointerMoveRotate,
  onPointerUpRotate,
  getVector,
  dotProduct,
  computeVisibleBounds,
};
