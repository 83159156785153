import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { getAllVertices } from "../../libs/brepOperations.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
const graphDiagnostics = (function () {
  const _analyzeNodeLabel = function (nodeLabel, vertex, component) {
    let faulty = false;
    if (nodeLabel) {
      if (nodeLabel.components.includes(component)) {
        const index = _.findIndex(nodeLabel.components, component);
        const preciseVector = nodeLabel.preciseVectors[index];
        if (preciseVector.equals(vertex)) {
          // all's good
        } else {
          faulty = true;
          console.warn("Precise vector doesn't match");
        }
      } else {
        faulty = true;
        console.error("This component is absent from the node ", nodeLabel);
      }
    } else {
      faulty = true;
      console.error("This vertex is absent from the graph ", vertex);
    }

    return faulty;
  };

  const doesComponentDataMatch = function (components) {
    if (!_.isArray(components)) components = [components];

    const partition = _.partition(components, (c) =>
      virtualSketcher.util.shouldAddComponentToGraph(c)
    );
    const graphyComponents = partition[0];
    const nonGraphyComponents = partition[1];

    const bottomAdjacencyGraph = virtualSketcher.getBottomAdjacencyGraph();
    const allComponentsInGraph = Object.values(
      bottomAdjacencyGraph._nodes
    ).reduce((components, nodeLabel) => {
      nodeLabel.components.forEach((c) => {
        if (!components.includes(c)) components.push(c);
      });

      return components;
    }, []);

    const componentsInGraphButShouldNotBe = _.intersection(
      allComponentsInGraph,
      nonGraphyComponents
    );
    if (!_.isEmpty(componentsInGraphButShouldNotBe)) {
      console.error(
        "These components shouldn't be there in the graph, but are."
      );
      console.log(componentsInGraphButShouldNotBe);
    }

    const problematicComponents = componentsInGraphButShouldNotBe;

    graphyComponents.forEach((c) => {
      let bottomFaulty,
        structuralFaulty = false;
      const vertices = virtualSketcher.util.getAppropriateVerticesToAdd(c);
      vertices.forEach((v) => {
        const nodeLabel = virtualSketcher.lookup(v);
        bottomFaulty = bottomFaulty || _analyzeNodeLabel(nodeLabel, v, c);
      });

      if (bottomFaulty) console.log("Bottom Adjacency Graph");

      const allVertices = getAllVertices(c);
      allVertices.forEach((v) => {
        const nodeLabel = virtualSketcher.structuralLookup(v);
        structuralFaulty =
          structuralFaulty || _analyzeNodeLabel(nodeLabel, v, c);
      });

      if (structuralFaulty) console.log("Structural Graph");

      if (bottomFaulty || structuralFaulty) {
        console.log(c.id);
        console.log("------");
        problematicComponents.push(c);
      }
    });

    if (_.isEmpty(problematicComponents)) console.log("All's good 👍");
    else return problematicComponents;
  };

  const diagnoseSelectedComponents = function () {
    return doesComponentDataMatch(
      store.selectionStack.map((sm) => sm.getSnaptrudeDS())
    );
  };

  const diagnoseAllComponents = function () {
    const allComponents = StructureCollection.getInstance().getAllComponents();
    return doesComponentDataMatch(allComponents);
  };

  return {
    doesComponentDataMatch,
    diagnoseSelectedComponents,
    diagnoseAllComponents,
  };
})();
export { graphDiagnostics };
