import React from "react";
import selectgif from "../../../../assets/instructor/Top toolbar/Draw/select tool.gif";
import drawgif from "../../../../assets/instructor/Top toolbar/Draw/draw mass cropped.gif";
import movegif from "../../../../assets/instructor/Top toolbar/Draw/freemove.gif";
import copygif from "../../../../assets/instructor/Top toolbar/Draw/array 3d.gif";
import rotategif from "../../../../assets/instructor/Top toolbar/Draw/rotate.gif";
import flipXgif from "../../../../assets/instructor/Top toolbar/Draw/flip x.gif";
import flipZgif from "../../../../assets/instructor/Top toolbar/Draw/flip z.gif";
import flipYgif from "../../../../assets/instructor/Top toolbar/Draw/flip y.gif";
import editLengthgif from "../../../../assets/instructor/Top toolbar/Draw/edit length.gif";
import addVertexgif from "../../../../assets/instructor/Top toolbar/Draw/add a vertex.gif";
import addVertex3dgif from "../../../../assets/instructor/Top toolbar/Draw/split face.gif";
import removeVertexgif from "../../../../assets/instructor/Top toolbar/Draw/Remove a vertex.gif";
import drawVoidgif from "../../../../assets/instructor/Top toolbar/Draw/draw void.gif";
import topogif from "../../../../assets/instructor/Top toolbar/import topography.gif";
import setScalegif from "../../../../assets/instructor/Top toolbar/Measure/set scale 3.gif";
import measuregif from "../../../../assets/instructor/Top toolbar/Measure/measure.gif";
import angleMeasuregif from "../../../../assets/instructor/Top toolbar/Measure/angle measure.gif";
import setDatumgif from "../../../../assets/instructor/Top toolbar/Measure/Datum 2.gif";
import frontViewgif from "../../../../assets/instructor/Top toolbar/Views/front view.gif";
import positionCameraViewgif from "../../../../assets/instructor/Top toolbar/Views/position camera.gif";
import rightViewgif from "../../../../assets/instructor/Top toolbar/Views/right view.gif";
import shadedgif from "../../../../assets/instructor/Top toolbar/Views/shaded.gif";
import shadowAnalysisgif from "../../../../assets/instructor/Top toolbar/Views/shadow gif compressed.gif";
import texturedgif from "../../../../assets/instructor/Top toolbar/Views/textured.gif";
import topViewgif from "../../../../assets/instructor/Top toolbar/Views/top view.gif";
import autoDimensionsgif from "../../../../assets/instructor/Top toolbar/Automate/auto_dimensions_crop.gif";
import autoInteriorsgif from "../../../../assets/instructor/Top toolbar/Automate/auto interiors.gif";
import createBuildinggif from "../../../../assets/instructor/Top toolbar/Automate/create building.gif";
import createDrawinggif from "../../../../assets/instructor/Top toolbar/Automate/Create drawing.gif";
import viewTogglegif from "../../../../assets/instructor/2D 3D toggle.gif";
import doorgif from "../../../../assets/instructor/Doors.gif";
import furnituregif from "../../../../assets/instructor/Furniture.gif";
import furnitureCutHolegif from "../../../../assets/instructor/Furniture cut hole.gif";
import materialRemovegif from "../../../../assets/instructor/material remove.gif";
import materialgif from "../../../../assets/instructor/Materials.gif";
import snapToWallgif from "../../../../assets/instructor/snap to wall.gif";
import staircasegif from "../../../../assets/instructor/Staircases.gif";
import windowsgif from "../../../../assets/instructor/Windows.gif";
import dimensionSnapgif from "../../../../assets/instructor/Bottom toolbar/Dimension Snap.gif";
import gridsgif from "../../../../assets/instructor/Bottom toolbar/Grids.gif";
import hideObjectsgif from "../../../../assets/instructor/Bottom toolbar/Hide Objects.gif";
import hideSlabsgif from "../../../../assets/instructor/Bottom toolbar/Hide slabs.gif";
import hideStoreygif from "../../../../assets/instructor/Bottom toolbar/hide storey.gif";
import isolateObjectgif from "../../../../assets/instructor/Bottom toolbar/Isolate Object.gif";
import showAllgif from "../../../../assets/instructor/Bottom toolbar/Show All.gif";
import slabOverhanggif from "../../../../assets/instructor/Bottom toolbar/Slab overhang.gif";
import { cursor } from "../../../../themes/cursor";
// import gif from "../../../../assets/instructor/Bottom toolbar/";
/*
 "tool name":{
    name: "tool name",
    displayText: "Tooltip name for tool",
    hasTrigger: has a userpilot trigger or not,
    trigger: "userpilot trigger id",
    shortcutKey: "shortcut key",
    type: "singleSelect" | "multipleSelect" | "immediateAction"
  },
*/
export const TOOLS_MAPPING = {
  // DRAW SUB MENU
  "pointer":{
    name: "pointer",
    displayText: "Select (Space)",
    hasTrigger: true,
    trigger: "1592568818mJrp1417",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.selectShadowed,
    gif: selectgif,
    instructions: [
      <p><strong>Click</strong> to select room or object.</p>,
      <p><strong>Ctrl/cmd + Click</strong> to select multiple objects.</p>,
      <p><strong>Click and drag</strong> to marquee select objects.</p>,
      <p><strong>Hover</strong> to see room label.</p>,
      <p><strong>Double click</strong> to edit room label.</p>,
    ],
    mixpanel: "clicked select_tool",
  },
  "storeySelector": {
    name: "storeySelector",
    displayText: "Storey Selection",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "checkboxSelector": {
    name: "checkboxSelector",
    displayText: "Filter Selection",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "optionSelector": {
    name: "optionSelector",
    displayText: "Option Selector",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "draw" : {
    name: "draw",
    displayText: "Draw (L)",
    hasTrigger: true,
    trigger: "1576000808fFjz6148",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.draw,
    gif: drawgif,
    instructions: [
      <p><strong>Draw rooms as complete loops.</strong></p>,
      <p><strong>Click-Move-Click</strong> to draw</p>,
      <p><strong>Double click</strong> to complete a loop</p>,
      <p><strong>Esc key</strong> to undo last line</p>,
    ],
    mixpanel: "clicked drawingMode"
  },
  "drawCircle" : {
    name: "drawCircle",
    displayText: "Draw Circle",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.drawCircle,
    gif: null,
    instructions: [ ],
    mixpanel: "clicked drawingCircleMode"
  },
  "move": {
    name: "move",
    displayText: "Move (M)",
    hasTrigger: true,
    trigger: "1575992612gPdx7924",
    shortcutKey: "",
    type: "singleSelect",
    gif: movegif,
    cursor: cursor.move,
    instructions: [
      <p><strong>Click</strong> to move room or object.</p>,
      <p><strong>Double click</strong> to move <strong>edge/ vertex/ face</strong> of room or object.</p>,
      <p><strong>Type</strong> to move by a fixed distance.</p>,
    ],
    mixpanel: "clicked moveBlocks",
  },
  "edit": {
    name: "edit",
    displayText: "Edit (P)",
    hasTrigger: true,
    trigger: "1575992612gPdx7924",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.pushpullEdit,
    gif: movegif,
    instructions: [
      <p><strong>Click</strong> to move <strong>edge/ vertex/ face</strong> of room or object.</p>,
      <p><strong>Type</strong> to move by a fixed distance.</p>,
    ],
    mixpanel: "clicked editObject",
  },
  "pushpull": {
    name: "pushpull",
    displayText: "Push Pull (P)",
    hasTrigger: true,
    trigger: "1575992612gPdx7924",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.pushpull,
    gif: movegif,
    instructions: [
      <p><strong>Click</strong> to move <strong>edge/ vertex/ face</strong> of room or object.</p>,
      <p><strong>Type</strong> to move by a fixed distance.</p>,
    ],
    mixpanel: "clicked editObject",
  },
  "copy": {
    name: "copy",
    displayText: "Copy/Array (C)",
    hasTrigger: true,
    trigger: "1576000694xRcg4042",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.copy,
    gif: copygif,
    instructions: [
      <p><strong>Click</strong> to move the room</p>,
      <p><strong>Double Click</strong> to move an edge/vertex/face</p>,
      <p><strong>Type</strong> in distance to move</p>,
    ],
    mixpanel: "clicked arrayFunction",
  },
  "rotate": {
    name: "rotate",
    displayText: "Rotate (Q)",
    hasTrigger: true,
    trigger: "1575995319yPvx0644",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.rotate,
    gif: rotategif,
    instructions: [
      <p><strong>Click-Click</strong> to place reference line on object.</p>,
      <p><strong>Click</strong> again to rotate.</p>,
      <p><strong>Type</strong> in to specify degree of rotation.</p>,
    ],
    mixpanel: "clicked rotateMesh",
  },
  "resize": {
    name: "resize",
    displayText: "resize",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    mixpanel: "resized window",
  },
  "flipX": {
    name: "flipX",
    displayText: "Flip X (X)",
    hasTrigger: true,
    trigger: "1576000275eQux078",
    shortcutKey: "",
    type: "immediateAction",
    gif: flipXgif,
    instructions: [
      <p><strong>Select</strong> object and <strong>click on flip</strong> to flip it along red axis.</p>,
    ],
    mixpanel: "clicked flipX",
  },
  "flipXY": {
    name: "flipXY",
    displayText: "Flip Z (Z)",
    hasTrigger: true,
    trigger: "1579951141mTys6399",
    shortcutKey: "",
    type: "immediateAction",
    gif: flipZgif,
    instructions: [
      <p><strong>Select</strong> object and <strong>click on flip</strong> to flip it along blue axis.</p>
    ],
    mixpanel: "clicked flipZ"
  },
  "verticalResize": {
    name: "verticalResize",
    displayText: "Edit Length",
    hasTrigger: true,
    trigger: "1575992988sZzp1462",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.editLength,
    gif: editLengthgif,
    instructions: [
      <p><strong>Type</strong> in required length of side.</p>
    ],
    mixpanel: "clicked editPolygon",
  },
  "addLayer": {
    name: "addLayer",
    displayText: "Add vertex",
    hasTrigger: true,
    trigger: "1575993312pOcr5301",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.addVertex,
    gif: addVertexgif,
    instructions: [
      <p><strong>Click on room's edge</strong> to add extra vertex.</p>,
      <p>Use the new vertex to modify the geometry.</p>,
    ],
    mixpanel: "clicked addVertex",
  },
  "addLayer3d": {
    name: "addLayer3d",
    displayText: "Split Face",
    hasTrigger: true,
    trigger: "1575993312pOcr5301",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.splitFace,
    gif: addVertex3dgif,
    instructions: [],
    mixpanel: "clicked splitFace",
  },
  "removeLayer": {
    name: "removeLayer",
    displayText: "Remove Vertex",
    hasTrigger: true,
    trigger: "1579951775hRlf5591",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.removeVertex,
    gif: removeVertexgif,
    instructions: [
      <p><strong>Click</strong> a room, and <strong>click on vertex</strong> to remove.</p>,
    ],
    mixpanel: "clicked removeVertex",
  },
  "flipY": {
    name: "flipY",
    displayText: "Flip Vertical",
    hasTrigger: true,
    trigger: "1579969818rOmk2366",
    shortcutKey: "",
    type: "singleSelect",
    gif: flipYgif,
    instructions: [
      <p><strong>Select</strong> object and <strong>click on flip</strong> to flip vertically.</p>,
    ]
  },
  "drawVoid": {
    name: "drawVoid",
    displayText: "Draw Void",
    hasTrigger: true,
    trigger: "1579973897rXmw2476",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.drawVoid,
    gif: drawVoidgif,
    instructions: [
      <p>Draw in a complete loop to create empty openings on walls.</p>,
      <p><strong>Click-Move-Click</strong> on a wall to draw opening.</p>,
    ]
  },
  "moveUp": {
    name: "moveUp",
    displayText: "Move Up",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "reorder": {
    name: "reorder",
    displayText: "Reorder",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },

  // IMPORT SUBMENU
  "image": {
    name: "image",
    displayText: "Upload Image",
    hasTrigger: true,
    trigger: "1580123045xFfr4676",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions: [
      <p>Drag and drop an image or a sketch from your computer, in .jpg, .png, .tiff and .bmp</p>,
    ],
    mixpanel: "clicked dropzone",
  },
  "file": {
    name: "file",
    displayText: "Upload CAD",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    mixpanel: "clicked dropzone for CAD",
  },
  "topo": {
    name: "topo",
    displayText: "Load Topography",
    hasTrigger: true,
    trigger: "1600674568hFbh3971",
    shortcutKey: "",
    type: "immediateAction",
    gif: topogif,
    instructions: [
      <p><strong>Type in</strong> your site location.</p>,
      <p><strong>Zoom in</strong> to the desired levels.</p>,
      <p>Turn the <strong>Height checkbox ON</strong> if you want a 3D contour map.</p>,
    ]
  },
  "importpdf": {
    name: "importpdf",
    displayText: "Import PDF",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions: [],
    mixpanel: "clicked import pdf in top bar"
  },
  "cube": {
    name: "cube",
    displayText: "Import Model",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions: [],
    mixpanel: "clicked import model in top bar"
  },
  
  "importRevit": {
    name: "importRevit",
    displayText: "Import Revit",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions: [],
    mixpanel: "clicked import revit in top bar"
  },

  // MEASURE SUB MENU
  "scaling": {
    name: "scaling",
    displayText: "Set Scale",
    hasTrigger: true,
    trigger: "1575992338eMmo9535",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.setScale,
    gif: setScalegif,
    instructions: [
      <p>Scale the sketch, drawing, uploaded image or uploaded ACad.</p>,
      <p><strong>Drag</strong> the reference line and <strong>type in</strong> scaled dimension.</p>,
    ],
    mixpanel: "clicked set-scale",
  },
  "scale": {
    name: "scale",
    displayText: "Measuring Tape (T)",
    hasTrigger: true,
    trigger: "1576001312iJno9557",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.tape,
    gif: measuregif,
    instructions: [
      <p><strong>Click-move-click</strong> to measure.</p>,
    ],
    mixpanel: "clicked measureMesh",
  },
  "angle": {
    name: "angle",
    displayText: "Angle Measurement",
    hasTrigger: true,
    trigger: "1592570276pJkl7211",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.angleMeasure,
    gif: angleMeasuregif,
    instructions: [
      <p>Click on <strong>2 edges</strong> to measure angle.</p>,
      <p>Click on <strong>3 vertices</strong> to measure angle.</p>,
    ],
    mixpanel: "clicked angleMeasure",
  },
  "setdatum": {
    name: "setdatum",
    displayText: "Set Datum",
    hasTrigger: true,
    trigger: "1600679192kIjs8471",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.setDatum,
    gif: setDatumgif,
    instructions: [
      <p><strong>Click</strong> a point on the imported topography.</p>,
      <p>Storey 1 now starts from that point.</p>,
    ]
  },
  "latlong": {
    name: "latlong",
    displayText: "Set Alignment Coordinates",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.setalignment,
    gif: null,
    instructions: []
  },

  // VIEWS SUB MENU
  "screenshot": {
    name: "screenshot",
    displayText: "Postion Camera  (V)",
    hasTrigger: true,
    trigger: "1592824207pSab8502",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.positioncamera,
    gif: positionCameraViewgif,
    instructions: [
      <p>Click on 2 points to set camera angle.</p>,
      <p>Camera will be set at eye level.</p>,
    ]
  },
  "snapshot": {
    name: "snapshot",
    displayText: "Save Scene",
    hasTrigger: true,
    trigger: "1592824210oCre9804",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions:[
      <p>Click to save a screenshot of the canvas.</p>,
    ]
  },
  "sectional": {
    name: "sectional",
    displayText: "sectional",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "frontView": {
    name: "frontView",
    displayText: "Front View",
    hasTrigger: true,
    trigger: "1592824176nGxz4694",
    shortcutKey: "",
    type: "singleSelect",
    gif: frontViewgif,
    instructions: [
      <p>Go to Front View.</p>
    ]
  },
  "topView": {
    name: "topView",
    displayText: "Top View",
    hasTrigger: true,
    trigger: "1592824194vVam411",
    shortcutKey: "",
    type: "singleSelect",
    gif: topViewgif,
    instructions: [
      <p>Go to Top View.</p>
    ]
  },
  "backView": {
    name: "backView",
    displayText: "Back View",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "leftView": {
    name: "leftView",
    displayText: "Left View",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "rightView": {
    name: "rightView",
    displayText: "Right View",
    hasTrigger: true,
    trigger: "1592825307tNlk3875",
    shortcutKey: "",
    type: "singleSelect",
    gif: rightViewgif,
    instructions: [
      <p>Go to Right view.</p>
    ]
  },
  "graphical": {
    name: "graphical",
    displayText: "Shadow Analysis (S)",
    hasTrigger: true,
    trigger: "1600678641nAdt0876",
    shortcutKey: "",
    type: "multipleSelect",
    gif: shadowAnalysisgif,
    instructions: [
      <p><strong>Click</strong> to turn shadows ON.</p>,
      <p><strong>Use the sliders</strong> to set date, month and time of study.</p>
    ]
  },
  "shadowsdropdown": {
    name: "shadowsdropdown",
    displayText: "Shadow Options",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "multipleSelect",
    isDropdown: true
  },
  "format": {
    name: "format",
    displayText: "Sunpath Diagram",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "multipleSelect",
  },
  "analysis_sda": {
    name: "analysis_sda",
    displayText: "Spatial Daylight Autonomy (sDA)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
  },
  "analysis_ase": {
    name: "analysis_ase",
    displayText: "Annual Sunlight Exposure (ASE)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
  },
  "eui_trigger": {
    name: "eui_trigger",
    displayText: "Energy Use Intensity (EUI)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
  },
  "daylight_settings": {
    name: "daylight_settings",
    displayText: "Daylight Analysis Settings",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
  },
  "settings": {
    name: "settings",
    displayText: "Settings",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "render": {
    name: "render",
    displayText: "Render",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
  },

  // AUTOMATE SUBMENU
  "autoReorder": {
    name: "autoReorder",
    displayText: "Detect from Image",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    gif: createDrawinggif,
    instructions: [
      <p>Click any <strong>enclosed space</strong> in your sketch</p>,
    ],
    mixpanel: "clicked detectMass",
  },
  "autoResize": {
    name: "autoResize",
    displayText: "Create Building",
    hasTrigger: true,
    trigger: "1576001151uHol6213",
    shortcutKey: "",
    type: "immediateAction",
    gif: createBuildinggif,
    instructions: [
      <p>Click to create BIM model automatically. </p>,
    ],
    mixpanel: "clicked createBuilding",
  },
  "auto": {
    name: "auto",
    displayText: "Auto Interior",
    hasTrigger: true,
    trigger: "1579963823gCbt3754",
    shortcutKey: "",
    type: "immediateAction",
    gif: autoInteriorsgif,
    instructions: [
      <p><strong>Click</strong> to auto-place furniture into rooms.</p>,
      <p><strong>Pro-Tip</strong>: Remember to <strong>label</strong> rooms first.</p>,
    ],
    mixpanel: "clicked onAutoInteriors",
  },
  "autoScale": {
    name: "autoScale",
    displayText: "Auto Dimension",
    hasTrigger: true,
    trigger: "1592565072fGlf9524",
    shortcutKey: "",
    type: "multipleSelect",
    gif: autoDimensionsgif,
    instructions: [
      <p>Click to display all dimensions and labels.</p>,
    ]
  },

  // COLLABORATE MENU
  "commentmode": {
    name: "commentmode",
    displayText: "Add Comment",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.comment,
    gif: null,
    instructions: []
  },
  "brainstormmode": {
    name: "brainstormmode",
    displayText: "Brainstorm Mode",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "multipleSelect",
    gif: null,
    instructions: []
  },

  // RIGHT MENU
  "objectProperties": {
    name: "objectProperties",
    displayText: "Object Properties",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "materials": {
    name: "materials",
    displayText: "Materials (B)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: materialgif,
    instructions: [
      <p>Click on material and click on object to apply.</p>
    ],
    mixpanel: "clicked addMaterial",
  },
  "doors": {
    name: "doors",
    displayText: "Doors (D)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: doorgif,
    instructions: [
      <p>Click on door type, click on door.</p>,
      <p><strong>Hover</strong> on wall in 3D and <strong>Click</strong> to place it.</p>,
      <p>Or <strong>Type</strong> to place it at a specific distance from corner.</p>,
    ]
  },
  "windows": {
    name: "windows",
    displayText: "Windows (W)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: windowsgif,
    instructions: [
      <p><strong>Click</strong> on window type, click on window.</p>,
      <p><strong>Hover</strong> on wall in 3D and <strong>Click</strong> to place it.</p>,
      <p>Or <strong>Type</strong> to place it at a specific distance from wall corner.</p>,
    ]
  },
  "staircases": {
    name: "staircases",
    displayText: "Staircases",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: staircasegif,
    instructions: [
      <p>Click on a staircase and click in the canvas to place it.</p>,
    ],
    mixpanel: "clicked staircase",
  },
  "furniture": {
    name: "furniture",
    displayText: "Furniture (F)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: furnituregif,
    instructions: [
      <p><strong>Hover-Click</strong> to place furniture.</p>,
    ]
  },
  "userimportedfiles": {
    name: "userimportedfiles",
    displayText: "Imported Files",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "importMaterial": {
    name: "importMaterial",
    displayText: "Add Material",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "removeMaterial": {
    name: "removeMaterial",
    displayText: "Remove Material",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    cursor: cursor.erase,
    gif: materialRemovegif,
    instructions: [
      <p>Click on an applied material to remove it.</p>
    ],
  },
  "eyeDropperForMaterial": {
    name: "eyeDropperForMaterial",
    displayText: "Eyedropper",
    hasTrigger: false,
    trigger: "",
    cursor: null,
    shortcutKey: "",
  },
  "editMaterial": {
    name: "editMaterial",
    displayText: "Edit Material",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "cloneMaterial": {
    name: "cloneMaterial",
    displayText: "Copy Material",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "importDoor": {
    name: "importDoor",
    displayText: "Add door",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "importWindow": {
    name: "importWindow",
    displayText: "Add window",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "importFurniture": {
    name: "importFurniture",
    displayText: "Add furniture",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
  },
  "cutHoleFurniture": {
    name: "cutHoleFurniture",
    displayText: "Cut hole",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: furnitureCutHolegif,
    instructions: [
      <p>Select Snap To Wall. Check Cut Hole ON.</p>,
      <p>Hover-click to place the furniture object and cut a hole in the wall at that location.</p>
    ]
  },
  "snapto": {
    name: "snapto",
    displayText: "Furniture Position",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: snapToWallgif,
    instructions: [
      <p>Select Snap To option in the dropdown.</p>,
      <p>Click on Furniture to select it.</p>,
      <p>Hover-Click to place snapped furniture.</p>,
    ]
  },
  // BOTTOM RIGHT MENU
  "viewToggle": {
    name: "viewToggle",
    displayText: "Toggle View",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    gif: viewTogglegif,
    instructions: [
      <p>Click to see design in 3D.</p>
    ],
  },

  // BOTTOM LEFT MENU
  "help": {
    name: "help",
    displayText: "Help",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "searchBox": {
    name: "searchBox",
    displayText: "Search Topics",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
  },
  "chatwithus": {
    name: "chatwithus",
    displayText: "Chat With Us",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "info": {
    name: "info",
    displayText: "Instructor",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "share": {
    name: "share",
    displayText: "Shortcuts",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "Undo": {
    name: "Undo",
    displayText: "Undo",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    mixpanel: "clicked undo",
  },
  "Redo": {
    name: "Redo",
    displayText: "Redo",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    mixpanel: "clicked redo",
  },
  "Settings": {
    name: "Settings",
    displayText: "Project Settings",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "Bulb0": {
    name: "Bulb0",
    displayText: "Hide (H)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: hideObjectsgif,
    instructions: [
      <p>Click on an object and click to hide it.</p>,
      <p><strong>Ctrl/Cmd + click</strong> multiple objects and click to hide them.</p>
    ]
  },
  "Bulb": {
    name: "Bulb",
    displayText: "Show (U)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: showAllgif,
    instructions: [
      <p>Click to unhide all hidden objects.</p>
    ],
    mixpanel: "clicked showAll",
  },
  "NoView": {
    name: "NoView",
    displayText: "Hide Slabs",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    gif: hideSlabsgif,
    instructions: [
      <p>Click to hide all slabs in 3D.</p>
    ]
  },
  "HideBuilding": {
    name: "HideBuilding",
    displayText: "Hide Building",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    gif: undefined,
    instructions: [
      <p>Click to hide/show building in 3D.</p>
    ]
  },
  "ObjectIcon": {
    name: "ObjectIcon",
    displayText: "Isolate (\\)",
    hasTrigger: true,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: isolateObjectgif,
    instructions: [
      <p>Click on an object and click to isolate it.</p>,
      <p><strong>Ctrl/Cmd + click</strong> multiple objects and click to isolate them.</p>
    ],
    mixpanel: "hide object clicked",
  },
  "Lock": {
    name: "Lock",
    displayText: "Lock",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions: [],
  },
  "Unlock": {
    name: "Unlock",
    displayText: "Unlock",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    gif: null,
    instructions: [],
  },
  "ellipse": {
    name: "ellipse",
    displayText: "Navigate",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "pan": {
    name: "pan",
    displayText: "Pan",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    cursor: cursor.pan,
    mixpanel: "clicked pan",
  },
  "orbit": {
    name: "orbit",
    displayText: "Orbit (O)",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    cursor: cursor.orbit,
    type: "singleSelect",
  },
  "zoomIn": {
    name: "zoomIn",
    displayText: "Zoom In",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    cursor: cursor.zoomIn,
    type: "singleSelect",
  },
  "zoomOut": {
    name: "zoomOut",
    displayText: "Zoom Out",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    cursor: cursor.zoomOut,
    type: "singleSelect",
  },
  "zoomExtents": {
    name: "zoomExtents",
    displayText: "Zoom Extents (Shift+Z)",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    mixpanel: "clicked zoomfit2d",
  },
  "delete_icon": {
    name: "delete_icon",
    displayText: "Delete",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "immediateAction",
    mixpanel: "clicked delete",
  },
  "Daylight Analysis": {
    name: "Daylight Analysis",
    displayText: "Daylight Analysis",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "Areas": {
    name: "Areas",
    displayText: "Areas",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "BOQ": {
    name: "BOQ",
    displayText: "Bill of Quantities",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    mixpanel: "Material Schedule View",
  },
  "Changelog": {
    name: "Changelog",
    displayText: "Costing",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
  },
  "Comments": {
    name: "Comments",
    displayText: "Comments",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    mixpanel: 'clicked comments on top bar',
  },
  "Views": {
    name: "Views",
    displayText: "Views",
    hasTrigger: false,
    trigger: "",
    shortcutKey: "",
    type: "singleSelect",
    mixpanel: "clicked exportViews",
  },
}
