import BABYLON from "../../modules/babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../../modules/utilityFunctions/Store.js";
import { showToast } from "../../modules/extrafunc.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import {
  twoGetRooms,
  twoDetectSketch,
  detectAllWalls,
  detectDoors,
} from "../apiCalls/backendCalls.js";
let walls = false;
let curves = false;

// check why this is not working
// document.getElementById('curve_tog').addEventListener('click', function(){
//     curves = !curves;
// });

const onDetectDownHead = () => {
  // if (
  //   !(
  //     store.activeLayer.layerType.toLowerCase() === "image" ||
  //     store.activeLayer.layerType.toLowerCase() === "wall" ||
  //     store.activeLayer.layerType.toLowerCase() === "cad"
  //   )
  // ) {
  //   showToast("Cannot detect rooms in " + store.activeLayer.name + " layer");
  //   return;
  // }
  let pickInfo1 = store.scene.pick(
    store.scene.pointerX,
    store.scene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("twoPlane") !== -1 &&
        mesh.visibility > 0 &&
        mesh.isVisible
      );
    }
  );
  if (pickInfo1.hit) {
    if (walls == true) {
      showAllWalls(pickInfo1);
    } else {
      if(pickInfo1.pickedMesh.type.toLowerCase().includes("floorplan")){
        onDetectDown(pickInfo1);
      }
    }
  } else {
    let layerRef = store.scene.getMeshByName("layerRefPlane");
    if (layerRef) {
      let pickInfo2 = store.scene.pick(
        store.scene.pointerX,
        store.scene.pointerY,
        function (mesh) {
          return mesh.name === "layerRefPlane";
        }
      );
      if (pickInfo2.hit) onSketchDetectDown(pickInfo2);
    } else {
      let pickInfo3 = store.scene.pick(
        store.scene.pointerX,
        store.scene.pointerY,
        function (mesh) {
          return mesh.name === "ground1";
        }
      );
      if (pickInfo3.hit) onSketchDetectDown(pickInfo3);
    }
  }
};

const onDetectDownHeadForHoles = () => {
  if (
    !(
      store.activeLayer.layerType.toLowerCase() === "image" ||
      store.activeLayer.layerType.toLowerCase() === "wall" ||
      store.activeLayer.layerType.toLowerCase() === "cad"
    )
  ) {
    showToast("Cannot detect rooms in " + store.activeLayer.name + " layer");
    return;
  }
  let pickInfo1 = store.scene.pick(
    store.scene.pointerX,
    store.scene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("twoPlane") !== -1 &&
        mesh.visibility > 0 &&
        mesh.isVisible
      );
    }
  );
  if (pickInfo1.hit) {
    if (walls == true) {
      showAllWalls(pickInfo1);
    } else {
      onDetectDown(pickInfo1);
    }
  } else {
    let layerRef = store.scene.getMeshByName("layerRefPlane");
    if (layerRef) {
      let pickInfo2 = store.scene.pick(
        store.scene.pointerX,
        store.scene.pointerY,
        function (mesh) {
          return mesh.name === "layerRefPlane";
        }
      );
      if (pickInfo2.hit) onSketchDetectDown(pickInfo2, true);
    } else {
      let pickInfo3 = store.scene.pick(
        store.scene.pointerX,
        store.scene.pointerY,
        function (mesh) {
          return mesh.name === "ground1";
        }
      );
      if (pickInfo3.hit) onSketchDetectDown(pickInfo3, true);
    }
  }
};

const onDetectDown = (pickInfo) => {
  let point = new BABYLON.Vector2.Zero();
  let scale = pickInfo.pickedMesh.scaling.x;
  // let rotationQuaternion = pickInfo.pickedMesh.rotationQuaternion;
  // let angle = rotationQuaternion?.toEulerAngles().y;
  // let centroidForRotation = pickInfo.pickedMesh?.getBoundingInfo().boundingBox.centerWorld?.asArray();

  if (pickInfo.hit) {
    let height = pickInfo.pickedMesh.getSnaptrudeDS().floorPlanImageHeight;
    let width = pickInfo.pickedMesh.getSnaptrudeDS().floorPlanImageWidth;
    let textureCoordinates = pickInfo.getTextureCoordinates();
    point.x = textureCoordinates.x * width;
    point.y = height - textureCoordinates.y * height;
  }

  if (point) {
    let scaledPoint = point;
    //All should be dynamic
    let roomType = localStorage.getItem("room_type") || "Default";
    let isCurve = jQuery("#curve_tog").is(":checked") || curves;
    let roomName = localStorage.getItem("room_name") || "room1";
    let roomID = localStorage.getItem("room_id") || 1;

    let structure =
      StructureCollection.getInstance().getStructures()[
        pickInfo.pickedMesh.structure_id
      ];
    let storey = structure
      .getStoreyData()
      .getStoreyByValue(pickInfo.pickedMesh.storey);
    let layer = storey.layerData.getLayerByName("image", storey.value);
    let floorPlan;
    if (layer.floorplans.length > 0) {
      floorPlan = layer.floorplans[0];
    }

    let structureID = layer.structure_id;
    // let levelLow = (parseInt(storey.value) - 1).toString();
    // let levelHigh = (parseInt(levelLow) + 1).toString();

    let level;
    level = structure.getLevel("0", "1");
    if (!level) structure.addLevel("0", "1");

    let levelID = "01";
    /*if(pickInfo.pickedMesh.name.indexOf('twoPlane')==0){
           layerView.selectLayer('Image', store.activeLayer.structure_id, store.activeLayer.storey);
        }*/

    let x = document.createElement("div");
    x.className = "detection-loader";
    x.id = "load" + point[0] + point[1];
    x.style.zIndex = 9999999;
    // window.componentHandler.upgradeElement(x);
    document.body.appendChild(x);
    x.style.position = "absolute";
    let cx = store.scene.pointerX;
    let cy = store.scene.pointerY;
    x.style.left = cx + "px";
    x.style.top = cy + "px";

    let boundingBox = pickInfo.pickedMesh.getBoundingInfo().boundingBox;
    let refPoint = new BABYLON.Vector3(
      boundingBox.minimumWorld.x,
      boundingBox.centerWorld.y,
      boundingBox.maximumWorld.z
    );

    let meshDS = pickInfo.pickedMesh.getSnaptrudeDS();

    let _initialScaleFactor = meshDS?.initialScaleFactor;
    let _scaleFactorDuringSetScale = meshDS?.scaleFactor;
    let _scaleFactor;

    if(_initialScaleFactor){
      _scaleFactor = _initialScaleFactor;
    }
    else{
      _scaleFactor = _scaleFactorDuringSetScale;
    }

    twoGetRooms(
      scaledPoint,
      roomName,
      roomType,
      roomID,
      levelID,
      structureID,
      isCurve,
      point,
      scale,
      floorPlan.strNum,
      pickInfo.pickedMesh.storey,
      _scaleFactor,
      refPoint,
    );
    // else{
    //     showToast("Cannot detect rooms in this layer");
    //     console.error("cannot detect rooms in this layer", store.activeLayer.layerType);
    // }
    // if(document.getElementById(roomName))
    //     document.getElementById(roomName).click();
  }
};

const onSketchDetectDown = (pickInfo, holes = false) => {
  let roomType = localStorage.getItem("room_type") || "Default";
  let isCurve = jQuery("#curve_tog").is(":checked") || curves;
  let roomName = localStorage.getItem("room_name") || "room1";
  let roomID = localStorage.getItem("room_id") || 1;
  let point = null;
  let scale = pickInfo.pickedMesh.scaling.x;
  let allLineMeshes = [];
  if (pickInfo.hit) {
    point = pickInfo.pickedPoint;
  }
  let structureID;
  let defStructure =
    StructureCollection.getInstance().getStructures()[
      store.activeLayer.structure_id
    ];
  if (pickInfo.pickedMesh.name == "layerRefPlane") {
    structureID = store.activeLayer.structure_id;
  } else {
    structureID = store.activeLayer.structure_id;
  }
  let structure =
    StructureCollection.getInstance().getStructures()[
      store.activeLayer.structure_id
    ];
  let newMassPos = pickInfo.pickedMesh.position.y;
  // let levelLow = (parseInt(store.activeLayer.storey) - 1).toString();
  // let levelHigh = (parseInt(levelLow) + 1).toString();

  let level;
  level = structure.getLevel("0", "1");
  if (!level) structure.addLevel("0", "1");

  let levelID = "01";
  /*if (activeLayer.layerType.toLowerCase() === 'image') {
        if (activeLayer.lastActiveLayer) {
            let sketchLayer = (activeLayer.lastActiveLayer.toLowerCase() != 'image') ? store.activeLayer.lastActiveLayer : 'Wall';
            layerView.selectLayer(sketchLayer, store.activeLayer.structure_id, store.activeLayer.storey)
        } else {
            layerView.selectLayer('Wall', store.activeLayer.structure_id, store.activeLayer.storey)
        }
    }*/

  if (point) {
    let sketchOnActvLayer = defStructure
      .getStoreyData()
      .getStoreyByValue(store.activeLayer.storey)
      .layerData.getLayerByName(
        store.activeLayer.name,
        store.activeLayer.storey
      ).sketches;
    for (let i = 0; i < sketchOnActvLayer.length; i++) {
      //allLineMeshes.push(sketchOnActvLayer[i].mesh);
      allLineMeshes.push(sketchOnActvLayer[i]);
    }
    const curveArray = [];
    let angle = 0;
    let centroidForRotation = [0, 0, 0];
    if (store.activeLayer.layerType === "cad") {
      // let rotationQuaternion = allLineMeshes[0].mesh?.absoluteRotationQuaternion;
      // angle = rotationQuaternion?.toEulerAngles().y;
      // centroidForRotation = allLineMeshes[0].mesh?.getBoundingInfo().boundingBox.centerWorld?.asArray()
      // let centroidForRotation = pickInfo.pickedMesh?.getBoundingInfo().boundingBox.centerWorld?.asArray();

      for (let i = 0; i < allLineMeshes.length; i++) {
        let oneCurve = _.flatten(allLineMeshes[i].edgeArray);
        let position = allLineMeshes[i].position;
        oneCurve = oneCurve.map((m) => [
          m[0] + position[0],
          0,
          -m[1] + position[2],
        ]);
        // if(allLineMeshes[i].moveAmount){
        //     oneCurve = oneCurve.map( m => [m[0] + allLineMeshes[i].position[0], m[1], m[2] + allLineMeshes[i].position[2]] );
        // }
        curveArray.push(_.flatten(oneCurve));
      }
    } else {
      for (let i = 0; i < allLineMeshes.length; i++) {
        let oneCurve = allLineMeshes[i].points;
        for (let j = 0; j < oneCurve.length; j++) {
          let oneCurveLocal = oneCurve[j];
          for (let k = 0; k < oneCurveLocal.length; k++) {
            let sketchPoint = oneCurveLocal[k];
            curveArray.push(sketchPoint.x, sketchPoint.y, sketchPoint.z);
          }
        }
      }
    }

    // for (let i = 0; i < allLineMeshes.length; i++) {
    //     const oneCurve = allLineMeshes[i].getVerticesData(BABYLON.VertexBuffer.PositionKind);
    //     let oneCurveWorld = [];
    //     for (let j = 0; j < oneCurve.length; j+=3){
    //         let vec = new BABYLON.Vector3(oneCurve[j], oneCurve[j+1], oneCurve[j+2]);
    //         vec = BABYLON.Vector3.TransformCoordinates(vec, allLineMeshes[i].getWorldMatrix());
    //         oneCurveWorld.push(vec.x, vec.y, vec.z);
    //     }
    //     curveArray.push(oneCurveWorld);
    // }

    if (store.activeLayer.layerType.toLowerCase() === "wall") {

      let x = document.createElement("div");
      x.className = "detection-loader";
      x.id = "load" + point[0] + point[1];
      x.style.zIndex = 9999999;
      // window.componentHandler.upgradeElement(x);
      document.body.appendChild(x);
      x.style.position = "absolute";
      let cx = store.scene.pointerX;
      let cy = store.scene.pointerY;
      x.style.left = cx + "px";
      x.style.top = cy + "px";
      twoDetectSketch(
        point,
        curveArray,
        roomName,
        roomType,
        roomID,
        structureID,
        scale,
        isCurve,
        newMassPos,
        levelID,
        point,
        holes
      );
    } else if (store.activeLayer.layerType.toLowerCase() === "cad") {
      let x = document.createElement("div");
      x.className = "detection-loader";
      x.id = "load" + point[0] + point[1];
      x.style.zIndex = 9999999;
      // window.componentHandler.upgradeElement(x);
      document.body.appendChild(x);
      x.style.position = "absolute";
      let cx = store.scene.pointerX;
      let cy = store.scene.pointerY;
      x.style.left = cx + "px";
      x.style.top = cy + "px";
      twoDetectSketch(
        point,
        curveArray,
        roomName,
        roomType,
        roomID,
        structureID,
        scale,
        isCurve,
        newMassPos,
        levelID,
        point,
        false,
        angle,
        centroidForRotation
      );
    } else {
      showToast("Cannot detect rooms in " + store.activeLayer.name + " layer");
      console.error(
        "Cannot detect rooms in this layertype",
        store.activeLayer.layerType
      );
    }
  }
  // document.getElementById(roomName).click();
};

const showAllWalls = (pickInfo) => {
  let scale = pickInfo.pickedMesh.scaling.x;

  let roomType = localStorage.getItem("room_type");
  let roomName = localStorage.getItem("room_name");
  let roomID = localStorage.getItem("room_id");

  let structure =
    StructureCollection.getInstance().getStructures()[
      store.activeLayer.structure_id
    ];
  let structureID = store.activeLayer.structure_id;
  let levelLow = (parseInt(store.activeLayer.storey) - 1).toString();
  let levelHigh = (parseInt(levelLow) + 1).toString();

  let level;
  level = structure.getLevel(levelLow, levelHigh);
  if (!level) structure.addLevel(levelLow, levelHigh);

  let levelID = levelLow + levelHigh;

  detectAllWalls(
    roomName,
    roomType,
    roomID,
    levelID,
    structureID,
    scale,
    pickInfo.pickedMesh.strNum,
    pickInfo.pickedMesh.storey,
    pickInfo.pickedMesh.getSnaptrudeDS().scaleFactor
  );
};

function showDoors() {
  let pickInfo = store.scene.pick(
    store.scene.pointerX,
    store.scene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("twoPlane") !== -1 &&
        mesh.visibility > 0 &&
        mesh.isVisible
      );
    }
  );
  let scale = pickInfo.pickedMesh.scaling.x;
  let point = new BABYLON.Vector2.Zero();

  if (pickInfo.hit) {
    let height = pickInfo.pickedMesh.getSnaptrudeDS().floorPlanImageHeight;
    let width = pickInfo.pickedMesh.getSnaptrudeDS().floorPlanImageWidth;
    let textureCoordinates = pickInfo.getTextureCoordinates();
    point.x = textureCoordinates.x * width;
    point.y = height - textureCoordinates.y * height;
  }
  if (point) {
    let roomType = localStorage.getItem("room_type");
    let roomName = localStorage.getItem("room_name");
    let roomID = localStorage.getItem("room_id");

    let structure =
      StructureCollection.getInstance().getStructures()[
        store.activeLayer.structure_id
      ];
    let structureID = store.activeLayer.structure_id;
    let levelLow = (parseInt(store.activeLayer.storey) - 1).toString();
    let levelHigh = (parseInt(levelLow) + 1).toString();

    let level;
    level = structure.getLevel(levelLow, levelHigh);
    if (!level) structure.addLevel(levelLow, levelHigh);
    let levelID = levelLow + levelHigh;

    let storey = structure
      .getStoreyData()
      .getStoreyByValue(pickInfo.pickedMesh.storey);
    let layer = storey.layerData.getLayerByName("image", storey.value);
    let floorPlan;
    if (layer.floorplans.length > 0) {
      floorPlan = layer.floorplans[0];
    } else {
      throw "Floorplan does nor exist";
    }

    detectDoors(
      point,
      pickInfo.pickedMesh.storey,
      floorPlan.strNum,
      roomName,
      roomID,
      levelID,
      structureID,
      roomType,
      pickInfo.pickedMesh.getSnaptrudeDS().scaleFactor,
      scale
    );
  } else {
    showToast("Cannot detect doors");
    console.error("cannot detect doors");
  }
}
// const detectUpdate1 = onSketchDetectDown().then(updateLevelsAngularUI, null);
// const detectUpdate2 = onDetectDown().then(updateLevelsAngularUI, null);
//
export {
  walls,
  curves,
  onDetectDownHead,
  onDetectDownHeadForHoles,
  onDetectDown,
  onSketchDetectDown,
  showAllWalls,
  showDoors,
};
