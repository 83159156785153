import React, { useState, useRef } from "react";
import styled from "styled-components";
import colorWheel from "../../assets/images/colorWheel.svg";
import ColourWheel from "../ColorWheel/index";
import Button from "../Button/index";
import CustomSlider from "../Slider/index";
import { colors } from "../../themes/constant";
import * as constants from "./constants";
import useOnClickOutside from "../../common/utils/onOutsideClickHandler";
import reduxStore from "../../snaptrude/stateManagers/store/reduxStore";
import {setMenuMode, updateMaterialValue} from "../../snaptrude/stateManagers/reducers/objectProperties/materialsSlice";
import {MATERIAL_EDIT_MENU, MATERIAL_MENU} from "./constants";
import {useSelector} from "react-redux";
import editMaterial from "../../snaptrude/libs/materialEditUI";

const Main = styled.div`
  position: relative;
  box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
  .container:before {
    content: "";
    position: absolute;
    top: 0;
    right: -2.1875rem;
    height: 2.1875rem;
    width: 2.1875rem;
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 20px 0 0 white;
    transform: rotate(90deg);
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 20.9375rem;
  height: 21.875rem;
  border-radius: 0.625em;
  background-color: ${colors.fullWhite};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: center;
  border-radius: 0.625em;
  padding: 0 1rem;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.2rem;
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
  padding: 0;
  margin: 0;
`;

const ColorHexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ColorTile = styled.div`
  border-radius: 3px;
  height: 1.375rem;
  width: 1.375rem;
  border: 1px solid ${colors.dullgrey};
`;

const ColorLabel = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  // width: 4.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25em;
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 10px;
    line-height: 18px;
    color: ${colors.primeBlack};
    margin: 0;
    // text-transform: capitalize;
  }
`;

const SliderContainer = styled.div`
  width: 6.9375rem;
`;

const EachColumn = styled.div`
  padding: 0.625rem;
`;

const ColorWheelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.6875rem;
`;

const Heading = styled.div`
  align-self: flex-start;
  padding: 1rem 0 0 0.625rem;
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.211789px;
    color: ${colors.darkGrey};
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  width: 8.75rem;
  padding: 0.9375rem;
  margin-top: 3rem;
`;

function MaterialColor(props) {
  
  const materialProperties = useSelector(state => state.materials.editMaterial);
  const color = materialProperties.overlay;
  
  // const [color, setColor] = useState(materialProperties.overlay || colors.red);
  // const [opacity, setOpacity] = useState(1);
  const ref = useRef();
  useOnClickOutside(ref, () => props.setModalOpen());
  
  const goBackToEditMenu = function (){
    reduxStore.dispatch(setMenuMode(MATERIAL_EDIT_MENU));
  };
  
  const onColorChange = function (property, color){
    const payload = {};
    payload[property] = color;
    reduxStore.dispatch(updateMaterialValue(payload));
    editMaterial.updateProperty(property, color);
  }
  
  return (
    <Main ref={ref}>
      <Container className="container">
        <Inner>
          {/*<Heading>
            <p>{constants.MATERIAL_OVERLAY_HEADER}</p>
          </Heading>*/}
          <TopContainer>
            <EachColumn>
              <TopContainerLabel>{constants.OVERLAY}</TopContainerLabel>
              <ColorHexContainer>
                <ColorTile>
                  <ColorTile
                    style={{
                      backgroundColor: color,
                      // opacity: opacity,
                      border: "none",
                    }}
                  />
                </ColorTile>
                <ColorLabel>
                  <p>{color}</p>
                </ColorLabel>
              </ColorHexContainer>
            </EachColumn>
            {/*<EachColumn>
              <SliderContainer>
                <TopContainerLabel>{constants.OPACITY}</TopContainerLabel>
                <CustomSlider
                  max={1}
                  min={0}
                  step={0.1}
                  value={opacity}
                  onChange={setOpacity}
                />
              </SliderContainer>
            </EachColumn>*/}
          </TopContainer>
          <ColorWheelContainer>
            <ColourWheel
              img={colorWheel}
              returnHex={false}
              width={140}
              callback={(color) => {onColorChange(constants.OVERLAY.toLowerCase(), color);}}
            />
          </ColorWheelContainer>
          <ButtonContainer>
            <Button
              title={constants.SELECT_OVERLAY}
              customButtonStyle={{
                backgroundColor: colors.red,
                minHeight: "auto",
                padding: "0.25rem",
              }}
              customButtonTextStyle={{ fontSize: "11px" }}
              onPress={goBackToEditMenu}
            />
          </ButtonContainer>
        </Inner>
      </Container>
    </Main>
  );
}

export default MaterialColor;
