import axios from "axios";
import { ORIGIN } from "./url.constants";
import noProfileIcon from "../assets/icons/noProfileIcon.svg";

export const updateProfilePicture = (imageFile) => {
  const formData = new FormData();
  formData.append('file', imageFile, imageFile.name);

  return axios
    .post(ORIGIN + "/account/profilePicture/", formData, {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      }
    })
    .then((response) => {
      console.log(response);
      localStorage.setItem("profilePictureUrl", response.data.profilePictureUrl);
      return response.data;
    });
};

export const updateAccount = (firstName, lastName, phoneNumber, country) => {
  return axios
    .post(ORIGIN + '/account/userInfo/', {
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber,
      country: country
    })
    .then(response => {
      console.log(response)
      localStorage.setItem("name", response.data.fullname);
      localStorage.setItem("phone", response.data.phone);
      localStorage.setItem("country", response.data.country);
      return response.data
    });
}

export const getUserProfilePictureUrl = () => {
  let url = localStorage.getItem("profilePictureUrl");

  if (_is_falsey(url)) return noProfileIcon;

  return url;
}

export const getUserCountry = (defaultValue=true) => {
  let country = localStorage.getItem('country');

  if (_is_falsey(country) && defaultValue) return 'US';

  return country;
}

const _is_falsey = (object) => {
  if (!object || object == 'undefined' || object == 'null') return true;

  return false;
}