/*jshint esversion: 6 */
"use strict";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { click } from "../../libs/meshEvents.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { meshObjectMapping } from "./mapping.js";
import { copyBrep } from "../../libs/brepOperations.js";
import { deepCopyObject, getActiveStoreyObject } from "../extrafunc.js";
import { labelView } from "../../libs/labelView.js";
import { convexHullNew } from "../../libs/hull.js";
import { perpendicularLineToEdge } from "../../libs/snapFuncs.js";
import {
  slopeOfLine,
  lineWithSlopeAurAtDistanceFromPoint,
} from "../../libs/twoD/twoServices.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { WALL_NOTIFY_FUNCTIONS } from "./wall.ds.js";
import { SnapProperty } from "../../libs/GUI/snap_properties.js";
import { SnapElement } from "../../libs/GUI/snap_element.js";
import { floorProperties } from "../../libs/objectProperties.js";
import { BasicFloorComp } from "../../libs/basicCompProperties.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import {ScopeUtils} from "../../libs/scopeFunctions";
import {
  addMaterialToLayers,
  getApplyMaterialSaveData,
  getMaterialCommandLogic,
  prepareDataForCMD
} from "../../libs/applyMaterialFuncs";
import {materialLoader} from "../../libs/mats";
import {Command} from "../commandManager/Command";
import {Mass} from "./mass.ds";
import {Roof} from "./roof.ds";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService.js";
import { StructureCollection } from "./structure.ds.js";

/**
 * { Floor datastructure }
 *
 * @class      Floor (name)
 * @param      {Object}  mesh    The mesh
 */
var Floor = function (mesh) {
  this.id = "fl_" + Math.random().toString(36).substr(2, 9);
  this.mesh = mesh;
  this.properties = {};
  this.level = 0; //TODO
  this.structure = 0; //TODO
  this.type = "Floor";
  this.room_id = null;
  this.storey = mesh.storey;
  this.groupId = null;
  this.far_included = true;
  this.height = Math.abs(mesh.height);
  this.isLocked = false;
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  delete mesh.height;
  this.revitMetaData = {}
  

  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  this.faceFacetMapping = mesh.faceFacetMapping;
  delete mesh.faceFacetMapping;
  
  this.room_type = mesh.room_type;

  this.assignProperties = function (options) {
    this.properties = _.cloneDeep(floorProperties);
    this.properties._type = "Floor";
    this.properties._rooms.push(mesh.room_name);
    this.properties._id = mesh.room_id;
    this.properties._level = mesh.level;
    this.properties._indices = mesh.room_path;
    this.properties._curve = mesh.room_curve;
    this.properties._components = options?.floorComponent ? options.floorComponent : BasicFloorComp;
    if (!this.mesh.sourceMesh && !this.mesh.material) {
      // this.mesh.convertToFlatShadedMesh();
      this.mesh.material = store.scene.getMaterialByName("floor_tile");
      this.mesh.material.backFaceCulling = false;
      // this.mesh.childrenComp = [];
    }
    this.mesh.childrenComp = [];
  };

  this.mesh.name = "Floor";
  this.mesh.type = "Floor";
  this.mesh.checkCollisions = true;
  this.mesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  this.mesh.level = mesh.level;
  this.edited = false;
  this.brep = mesh.brep;

  if(!this.mesh.room_type){
    this.mesh.room_type = snaptrudeDSCount.getLabel("Floor");
  }

  delete mesh.brep;
  let bbinfo = this.mesh.getBoundingInfo();
  let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  // this.mesh.setPivotPoint(centroid);

  this.onMove = {
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (evt) {
      //pass
    },

    onPointerMove: function (direction, diff, speed) {
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.name.indexOf("boxScale") != -1) {
        this.mesh.parentMesh.position = this.mesh.position;
      }
    },
  };

  // setUVScale(this.mesh, bbinfo.boundingBox.extendSizeWorld.x / 5, bbinfo.boundingBox.extendSizeWorld.z / 5);
  click(this.mesh);
};
/**
 * Make object visible in store.scene
 */
Floor.prototype.show = function () {
  this.mesh.isVisible = true;
};
/**
 * Hides object in store.scene
 */
Floor.prototype.hide = function () {
  this.mesh.isVisible = false;
};
Floor.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
Floor.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
Floor.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};

Floor.prototype.isTypeChangeAllowed = function () {
  return this.typeChangeAllowed;
};

Floor.prototype.allowTypeChange = function () {
  this.typeChangeAllowed = true;
};

Floor.prototype.disallowTypeChange = function () {
  this.typeChangeAllowed = false;
};

Floor.prototype.updateBase = function (heightDifference) {
  function isOfBalconyHeightOrLess() {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;
    return (
      boundingBox.extendSizeWorld.y * 2 <=
      StoreyMutation._CONSTANTS.balconyHeight
    );
  }

  // if (!(!isOfBalconyHeightOrLess.call(this) && this.isEdited())) {
  //     this.mesh.position.y += heightDifference;
  // }
  if (this.storey > 0) {
    this.mesh.position.y += heightDifference;
  } else {
    this.mesh.position.y -= heightDifference;
  }
};
/**
 * Remove a Floor to level.
 *
 * @param      {<type>}  object  The object
 */
Floor.prototype.removeFloorFromLevel = function (level) {
  meshObjectMapping.deleteMapping(this.mesh);
  level.flyweight.floors = level.flyweight.floors.filter(
    (floor) => floor.mesh.uniqueId !== this.mesh.uniqueId
  );
};

Floor.prototype.cloneProperties = function (otherFloor, unique = true) {
  if (unique) {
    otherFloor.brep = copyBrep(this.brep);
    otherFloor.faceFacetMapping = deepCopyObject(this.faceFacetMapping);
  } else {
    otherFloor.brep = this.brep;
    otherFloor.faceFacetMapping = this.faceFacetMapping;
  }
};

/**
 * Method to compute if the geometry of the floor is changed
 */
Floor.prototype.getGeometryChangeBoolean = function () {
  if (labelView) {
    let edgePoints1 = labelView._getEdgePoints(
      this.mesh,
      new BABYLON.Vector3(0, 1, 0)
    );
    let edgePoints2 = labelView._getEdgePoints(
      this.mesh,
      new BABYLON.Vector3(0, -1, 0)
    );
    if (edgePoints1.length != edgePoints2.length) {
      return true;
    }
    let thickness = edgePoints1[0].y - edgePoints2[0].y;
    for (let i = 1; i < edgePoints1.length; i++) {
      let thick = edgePoints1[i].y - edgePoints2[i].y;
      if (Math.abs(thick - thickness) > 1e-3) {
        return true;
      }
    }
    return false;
  }
  return false;
};

Floor.prototype.edgeMidPts = function () {
  let twoPts = [];
  let drawPts = [];
  let midPoints = [];
  let coords = this.getBoundaryCoords();
  for (let i = 0; i < coords.length; i++) {
    let point = new BABYLON.Vector2(coords[i].x, coords[i].z);
    twoPts.push(point);
  }
  let pts = convexHullNew(twoPts);
  for (let j = 0; j < pts.length; j++) {
    drawPts.push(new BABYLON.Vector3(pts[j].x, this.midY, pts[j].y));
  }
  drawPts.push(drawPts[0]); // loop closed
  for (let k = 0; k < drawPts.length - 1; k++) {
    let set = {
      pointSet: [drawPts[k], drawPts[k + 1]],
      dis: BABYLON.Vector3.Distance(drawPts[k], drawPts[k + 1]),
    };
    midPoints.push(set);
  }
  let sorted = midPoints.sort((a, b) => a.dis - b.dis);
  let pt1 = new BABYLON.Vector3.Center(
    sorted[0].pointSet[0],
    sorted[0].pointSet[1]
  );
  let pt2 = new BABYLON.Vector3.Center(
    sorted[1].pointSet[0],
    sorted[1].pointSet[1]
  );
  return [pt1, pt2];
};

/**
 * Get bottom boundary coordinates of Floor
 * @returns {Array}
 */
Floor.prototype.getBoundaryCoords = function () {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let verDataArr = [];
  let bBox = this.mesh.getBoundingInfo().boundingBox;
  let maxY = Math.round(bBox.maximumWorld.y * 10000) / 10000;

  for (let i = 0; i < verData.length; i += 3) {
    let vec = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
    vec = BABYLON.Vector3.TransformCoordinates(vec, this.mesh.getWorldMatrix());
    vec = new BABYLON.Vector3(
      Math.round(vec.x * 10000) / 10000,
      Math.round(vec.y * 10000) / 10000,
      Math.round(vec.z * 10000) / 10000
    );
    if (vec.y === maxY) {
      verDataArr.push(vec);
    }
  }
  verDataArr = verDataArr.filter(
    (vec, index, self) =>
      index === self.findIndex((t) => t.x === vec.x && t.z === vec.z)
  );

  // [verDataArr[2], verDataArr[3]] = [verDataArr[3], verDataArr[2]];
  return verDataArr;
};

Floor.prototype.updateDefaultMaterial = function () {
  let mesh = this.mesh.sourceMesh || this.mesh;
  let applyMaterialCommandData = {};
  let roomType = ScopeUtils.getRoomType(this.room_type);
  roomType = roomType.replace(/\s/g, "");
  
  if (roomType !== "Deck") return;
  // only supporting decks for now
  
  let mat;

  applyMaterialCommandData._prevData = prepareDataForCMD(mesh);

  let functionType = "load" + roomType + "Material";
  
  const defaultMaterialName = "floor_tile";

  if (functionType in materialLoader) {
    mat = materialLoader[functionType]();
  } else {
    mat = store.scene.getMaterialByName(defaultMaterialName);
    mesh.material = mat;
  }

  let mat_index;
  if (mesh.material.subMaterials) {
    var multimatSubs = mesh.material.subMaterials;
    var mat_exist_flag = false;
    var mat_exist_index = -1;
    for (var p = 0; p < mesh.material.subMaterials.length; p++) {
      if (mesh.material.subMaterials[p].id === mat.id) {
        mat_exist_flag = true;
        mat_exist_index = p;
      }
    }
    if (!mat_exist_flag) {
      mesh.material.subMaterials.push(mat);

      mat_index = multimatSubs.length - 1;
    } else {
      mat_index = mat_exist_index;
    }
  } else if (mat.name === defaultMaterialName) {
    mat_index = 0;
  } else {
    let id = Math.random().toString(36).substr(2, 6) + "_";
    var multimat = new BABYLON.MultiMaterial(mat.name + id, store.scene);
    multimat.subMaterials.push(mesh.material);
    multimat.subMaterials.push(mat);
    mesh.material = multimat;
    mat_index = multimat.subMaterials.length - 1;
  }

  // applyMaterialCommandData._prevData = prepareDataForCMD(mesh);
  mesh.subMeshes.forEach(function (subMesh) {
    subMesh.materialIndex = mat_index;
  });
  if (this.brep) {
    let faces = this.brep.getFaces();
    faces.forEach(function (face) {
      face.materialIndex = mat_index;
    });
  }
  addMaterialToLayers(mesh, mat_index);
  applyMaterialCommandData._newData = prepareDataForCMD(mesh);
  mesh.synchronizeInstances();

  return new Command(
    "applyDefaultRoomTypeMaterial",
    applyMaterialCommandData,
    getMaterialCommandLogic(),
    getApplyMaterialSaveData
  );
};

/**
 * Updates the thickness in the layers of the floor
 * Invoke this function before material schedule is calculated or
 * When the floor's layers are accessed
 */
Floor.prototype.setThickness = function () {
  /*
    TODO: write the logic to compute the thickness
     */
  if (this.getGeometryChangeBoolean()) {
    this.properties._components._layers.forEach(function (layer) {
      if (layer.core) {
        layer.thickness = "Variable";
      }
    });
    return;
  }

  if (labelView) {
    let edgePoints;
    try {
      edgePoints = labelView._getEdgePoints(
        this.mesh,
        new BABYLON.Vector3(1, 0, 0)
      );
    } catch (err) {
      this.properties._components._layers.forEach(function (layer) {
        if (layer.core) {
          layer.thickness = "Variable";
        }
      });
      return;
    }
    let thickness = 1000000;
    for (let i = 0; i < edgePoints.length; i++) {
      let pt1 = new BABYLON.Vector2(edgePoints[i].y, edgePoints[i].z);
      let pt2 = new BABYLON.Vector2(
        edgePoints[(i + 1) % edgePoints.length].y,
        edgePoints[(i + 1) % edgePoints.length].z
      );
      let perpendicularPoints1 = perpendicularLineToEdge(pt1, pt1, pt2);
      let perpendicularPoints2 = perpendicularLineToEdge(pt2, pt1, pt2);

      let slop1 = slopeOfLine([
        perpendicularPoints1[0].asArray(),
        perpendicularPoints1[1].asArray(),
      ]);
      let slop2 = slopeOfLine([
        perpendicularPoints2[0].asArray(),
        perpendicularPoints2[1].asArray(),
      ]);
      let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
      let slope2 = -1 / slop2 === "inf" ? 0 : slop2;

      let points1 = lineWithSlopeAurAtDistanceFromPoint(
        pt1.asArray(),
        slope1.toString().includes("Inf") ? "inf" : slope1,
        1
      );
      let points2 = lineWithSlopeAurAtDistanceFromPoint(
        pt2.asArray(),
        slope2.toString().includes("Inf") ? "inf" : slope2,
        1
      );

      let storeyObject = getActiveStoreyObject(store.activeLayer.storey);
      let st = null;
      let end = null;
      if (slop1 === 0) {
        st = new BABYLON.Vector3(
          points1[1][0],
          storeyObject.height,
          points1[1][1]
        );
        end = new BABYLON.Vector3(
          points2[1][0],
          storeyObject.height,
          points2[1][1]
        );
      } else if (slop1 === "inf") {
        st = new BABYLON.Vector3(
          points1[0][0],
          storeyObject.height,
          points1[0][1]
        );
        end = new BABYLON.Vector3(
          points2[0][0],
          storeyObject.height,
          points2[0][1]
        );
      } else {
        st = new BABYLON.Vector3(
          points1[1][0],
          storeyObject.height,
          points1[1][1]
        );
        end = new BABYLON.Vector3(
          points2[1][0],
          storeyObject.height,
          points2[1][1]
        );
      }
      let thick = BABYLON.Vector3.Distance(st, end);
      if (thick < thickness) {
        thickness = thick;
      }
    }
    if (this.properties) {
      if (this.properties._components) {
        if (thickness != 1000000) {
          this.properties._components._layers.forEach(function (layer) {
            if (layer.core) {
              layer.thickness =
                DisplayOperation.convertDimensionTo(thickness).mMts;
            }
          });
          this.properties._components._thickness =
            DisplayOperation.convertDimensionTo(thickness).mMts;
          return this.properties._components._thickness;
        }
      }
    }
  }
};

/**
 * { function_description }
 *
 * @param      {number}  pol_bottom  The pol bottom
 * @param      {number}  midY        The middle y
 */
Floor.prototype.adjustHeight = function (pol_bottom, midY = 0) {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbinfo = this.mesh.getBoundingInfo();
  if (!midY) {
    midY = (bbinfo.maximum.y + bbinfo.minimum.y) / 2;
  }
  for (let i = 0; i < verData.length; i += 3) {
    if (verData[i + 1] < midY) {
      for (let j = 0; j < pol_bottom.length; j++) {
        if (
          Math.abs(verData[i] - pol_bottom[j][0]) < 0.1 &&
          Math.abs(verData[i + 2] - pol_bottom[j][1]) < 0.1
        ) {
          verData[i + 1] = pol_bottom[j][2] - this.height;
          break;
        }
      }
    } else {
      for (let j = 0; j < pol_bottom.length; j++) {
        if (
          Math.abs(verData[i] - pol_bottom[j][0]) < 0.1 &&
          Math.abs(verData[i + 2] - pol_bottom[j][1]) < 0.1
        ) {
          verData[i + 1] = pol_bottom[j][2];
          break;
        }
      }
    }
  }
  this.mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, verData);
};
/**
 * Get boundary coordinates of Floor plane
 * @returns {Array}
 */
Floor.prototype.getBoundaryCoords = function () {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbInfo = this.mesh.getBoundingInfo();
  let minY = bbInfo.boundingBox.minimumWorld.y;
  let verDataArr = [];

  for (let i = 0; i < verData.length; i += 3) {
    let vec = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
    vec = BABYLON.Vector3.TransformCoordinates(vec, this.mesh.getWorldMatrix());
    vec = new BABYLON.Vector3(
      Math.round(vec.x * 100) / 100,
      Math.round(vec.y * 100) / 100,
      Math.round(vec.z * 100) / 100
    );
    if (vec.y === Math.round(minY * 100) / 100) {
      verDataArr.push(vec);
    }
  }
  verDataArr = verDataArr.filter(
    (vec, index, self) =>
      index === self.findIndex((t) => t.x === vec.x && t.z === vec.z)
  );
  // verDataArr.push(verDataArr[0]);
  // verDataArr.shift();
  verDataArr.reverse();
  return verDataArr;
};
/**
 * Function to check if floor is of default roomType
 * @returns {boolean}
 */
Floor.prototype.hasNonDefaultRoomType = function () {
  if (!this.room_type) return false;
  if (this.mesh.type.toLowerCase().indexOf("throwaway") !== -1) return false;
  return this.room_type.toLowerCase() !== "default";
};

Floor.prototype.isSupportFloor = function () {
  if (this.mesh.parent) {
    let parentType = this.mesh.parent.type;
    if (parentType && parentType.toLowerCase() === "door") return true;
  }

  return false;
};

/**
 * Notifies all.
 */
Floor.prototype.notifyAll = function (action) {
  if (action === "resetlevel") {
    WALL_NOTIFY_FUNCTIONS.resetLevel.call(this);
  }
};

Floor.prototype.getMeshObjectProperties = function () {
  let props = [];
  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);
  props.push(
    new SnapProperty(
      "Category",
      "Floor",
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Label",
      this.mesh.room_type,
      SnapElement.getInputElement(
        (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
        true
      )
    )
  );

  if(this.revitMetaData.floorType){
    props.push(
      new SnapProperty(
        "Floor Type",
        this.revitMetaData.floorType,
        SnapElement.getInputElement(null, false)
      )
    );
  }
  //props.push(new SnapProperty("Type",'floor1',SnapElement.getDropDown(['floor1','floor2','floor3','floor4'],(meshes,name) => objectPropertiesView.onTypeChange(this.mesh,name),true)));
  props.push(
    new SnapProperty(
      "Thickness",
      meshDimensions.height,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange(
            "height",
            value,
            meshDimensions.height
          ),
        true
      )
    )
  );
  let storey = StructureCollection.getInstance().getStructureById(this.mesh.structure_id).getStoreyData().getStoreyByValue(this.mesh.storey).name;
  let storeyValue = storey? storey : this.mesh.storey;

  props.push(
    new SnapProperty(
      "Storey",
      storeyValue,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Surface Area",
      meshDimensions.area,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Volume",
      meshDimensions.volume,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Length",
      meshDimensions.breadth,
      SnapElement.getInputElement(
        (meshs, value) => objectPropertiesView.dimensionChange("width", value),
        false
      )
    )
  );
  props.push(
    new SnapProperty(
      "Width",
      meshDimensions.depth,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange("thickness", value),
        false
      )
    )
  );
  props.push(new SnapProperty("object-buttons", this.mesh, null, true));

  return props;
};

/**
 * Check if mesh is a support floor
 * @param mesh
 * @return {boolean}
 */
Floor.isSupportFloor = function (mesh) {
  if (mesh.type.toLowerCase() !== "floor") return false;
  if (mesh.parent) return true;

  return false;
};
export { Floor };
