import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const _sliceName = "dnd"

const initialState = {
    dragging: false,
    selection: {},
};

export const dndSlice = createSlice({
    name: _sliceName,
    initialState,
    reducers: {
        setDragging: (state, action) => {
            state.dragging = action.payload
        },
        addItem: (state, action) => {
            const objectId = action.payload?.objectId
            const id = action.payload?.id
            const value = action.payload?.value

            const selection = state.selection

            if(!selection[objectId]) selection[objectId] = {}

            selection[objectId][id] = value
        },
        removeItem: (state, action) => {
            const objectId = action.payload?.objectId
            const id = action.payload?.id
            
            const selection = state.selection

            if(objectId in selection) {
                delete selection[objectId][id]
            }
        },
        reset: (state, action) => {
            const objectId = action.payload?.objectId
            
            const selection = state.selection

            delete selection[objectId]
        },
        resetAll: () => initialState,
    },
});

export const Action = dndSlice.actions;
export const getDNDState = (state) => state[_sliceName];

export default dndSlice.reducer;