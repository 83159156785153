import {store} from "../utilityFunctions/Store";
import {
  checkOperationBIMFlowConditions,
  getComponentInTheVicinityOfThisComponent,
  isPointInTheVicinityOfMesh,
  isTerrainMesh,
  showToast
} from "../extrafunc";
import _ from "lodash";
import {Mass} from "../snaptrudeDS/mass.ds";
import {isNeighborhoodBuilding} from "../geo/terrainNeighborhood";
import {commandUtils} from "../commandManager/CommandUtils";
import {computeBoundsForMeshes} from "../../libs/zoomFuncs";
import {drawSelectionBox, removeSelectionBox} from "../../libs/meshEvents";
import {virtualSketcher} from "../sketchMassBIMIntegration/virtualSketcher";
import {CommandManager} from "../commandManager/CommandManager";
import BABYLON from "../babylonDS.module";
import {Locker} from "../locker/locker";

const snapmda = window.snapmda;

const flipOperator = (function (){
  
  const _flipMesh = function (bbox, dir, selectedMeshes) {
    let vec, rotation;
    if (dir === "x") {
      vec = new BABYLON.Vector3(1, 1, -1);
      rotation = BABYLON.Axis.X;
    }
    if (dir === "y") {
      vec = new BABYLON.Vector3(1, -1, 1);
      rotation = BABYLON.Axis.Y;
    }
    if (dir === "z") {
      vec = new BABYLON.Vector3(-1, 1, 1);
      rotation = BABYLON.Axis.Z;
    }
    let parentMesh = store.scene.getMeshByName("tempFlipBox");
    if (!parentMesh) {
      parentMesh = BABYLON.MeshBuilder.CreateBox("tempFlipBox", {}, store.scene);
    }
    parentMesh.position = bbox.boundingBox.centerWorld;
    parentMesh.visibility = 0;
  
    selectedMeshes.forEach(function (mesh) {
      if (mesh.type === "staircase" && dir === "y") {
        return;
      }
      // if (
      //   mesh.type.toLowerCase() === "door" ||
      //   mesh.type.toLowerCase() === "window"
      // ) {
      //   mesh._prevParent = mesh.parent.uniqueId;
      //   if (rotation) mesh.rotate(rotation, Math.PI, BABYLON.Space.LOCAL);
      //   mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
      //   return;
      // }
      if (mesh.uniqueId !== parentMesh.uniqueId) {
        if (mesh.parent) mesh._prevParent = mesh.parent.uniqueId;
        mesh.setParent(parentMesh);
      }
    });
  
    parentMesh.scaling.multiplyInPlace(vec);
    parentMesh.computeWorldMatrix(true);
    selectedMeshes.forEach(function (mesh) {
      let object = mesh.getSnaptrudeDS();
      if (object.brep) {
        if (!mesh.isAnInstance) {
          snapmda.InvertOperator(object.brep);
          mesh.BrepToMesh();
        }
        if (mesh.isAnInstance) mesh.sourceMesh.synchronizeInstances();
        // correctMeshNormals(mesh);
        // updateSubMeshes(mesh, object.brep.getFaces(), object.faceFacetMapping);
      }
      mesh.state = "off";
      if (mesh._prevParent)
        mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
      else mesh.setParent(null);
  
      if (mesh.type.toLowerCase() === "furniture") {
        let bbinfo = mesh.getBoundingInfo();
        let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
        if(!mesh.getSnaptrudeDS()?.revitMetaData?.type){
          mesh.setPivotMatrix(
            BABYLON.Matrix.Translation(-centroid.x, -centroid.y, -centroid.z),
            false
          );
        }
      }
    });
  
    parentMesh.visibility = 0;
  };
  
  const flip = function (dir) {
    
    if (store.selectionStack.length < 1) {
      showToast("Please select an object first");
    }
    else {
      let selection = _.clone(store.selectionStack);
      _.remove(selection, mesh => {
        if (Mass.isPlinth(mesh)) {
          // mesh.children.forEach(child => child.dispose());
          return true;
        } else if (isTerrainMesh(mesh) || isNeighborhoodBuilding(mesh)) {
          return true
        }
      });
      selection = Locker.filterLockedMeshes(selection);
      if (selection.length === 0) {
        showToast("Please select other object. Can not flip Topography.");
        return
      }
    
    
      const selectedComponents = selection.map(m => m.getSnaptrudeDS());
    
      let options = {
        operation: "flip",
        mesh: selection[0]
      };
      if (!checkOperationBIMFlowConditions(options)) {
        showToast("Floors can be flipped only if grouped with walls");
        return;
      }
    
      options = {
        params: [commandUtils.worldMatrixChangeOperations.PARAMS.scale,
          commandUtils.worldMatrixChangeOperations.PARAMS.position,
          commandUtils.worldMatrixChangeOperations.PARAMS.rotation]
      };
    
      /*let nonChildMeshPresent = false;
      selection.some(mesh => {
          if (!mesh.parent){
              nonChildMeshPresent = true;
              return true;
          }
      });
  
      if (!nonChildMeshPresent) options.operationOnChild = true;*/
    
      options.determineIfChildAlternatively = true;
      options.synchronizeInstances = true;
    
      let _flipCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        null, options
      );
    
      let flipGeometryChangeCommandData = commandUtils.geometryChangeOperations.getCommandData(store.selectionStack);
    
      //flip direction is the axis about which the mesh mirrors
      //for x flip, scaling direction will be z
    
      let bbox = computeBoundsForMeshes(selection);
      _flipMesh(bbox, dir, selection);
    
      options = {
        data: _flipCommandData
      };
    
      _flipCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        null, options
      );
      const flipCommand = commandUtils.worldMatrixChangeOperations.getCommand(
        commandUtils.CONSTANTS.flipOperation, _flipCommandData
      );
    
      flipGeometryChangeCommandData = commandUtils.geometryChangeOperations.getCommandData(store.selectionStack,
        flipGeometryChangeCommandData);
    
      const flipBRepInversionCommand = commandUtils.geometryChangeOperations.getCommand('flipBRepInversion', flipGeometryChangeCommandData);
    
      removeSelectionBox();
    
      let integrationGeometryChangeCommand;
      
      if (selection.length > 5){
        virtualSketcher.updateWithoutGeometryEdit(selectedComponents);
      }
      else {
        integrationGeometryChangeCommand = virtualSketcher.updateWithGeometryEdit(selectedComponents);
      }
    
      const meshNewParentMap = new Map();
      selectedComponents.forEach(c => {
        if (c.mesh.parent) {
          if (c.mesh.type.toLowerCase() !== "mass") return;
        
          const childMesh = c.mesh;
          if (!isPointInTheVicinityOfMesh(childMesh.getAbsolutePosition(), childMesh.parent)) {
            const potentialParentComponent = getComponentInTheVicinityOfThisComponent(c);
            meshNewParentMap.set(childMesh, potentialParentComponent?.mesh);
            // if no potentialParentComponent, mesh will be orphaned
          }
        }
      });
    
      let parentChangeCommand;
      if (!_.isEmpty(meshNewParentMap)) {
        parentChangeCommand = commandUtils.parentChildOperations.expressCheckout(
          "flipParentChange",
          Array.from(meshNewParentMap.keys()),
          Array.from(meshNewParentMap.values())
        );
      }
    
      selection.forEach(function (activeObject) {
        drawSelectionBox(activeObject);
        // if (activeObject.parent) return;
        // activeObject.setPivotPoint(activeObject.tempPivot, BABYLON.Space.LOCAL);
        // delete activeObject.tempPivot;
        // if (activeObject.type){
        //     if (activeObject.type.toLowerCase() === "roof"){
        //         // activeObject.getSnaptrudeDS().updateStoredVertices(bbox.boundingBox.center, dir);
        //     }
        // }
      });
    
      const commands = _.compact([flipBRepInversionCommand, flipCommand, integrationGeometryChangeCommand, parentChangeCommand]);
      commands.executeLeftToRight = true;
      const yets = commands.map(_ => false);
    
      CommandManager.execute(commands, yets);
    }
  };
  
  return {
    flip,
  }
  
})();

export default flipOperator;