import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { colors } from "../../themes/constant";

const StyledTable = styled.div`
  width: 100%;
  table {
    font-family: ${(props) => props.theme.font};
    font-size: 1rem;
    border-collapse: collapse;
    border-radius: 0.4rem;
    width: 100%;
  }
  tbody .trHover:hover {
    background-color: ${colors.white};
    border-radius: 0.313rem;
  }
  tbody .trHover:hover td:first-child {
    border-top-left-radius: 0.313rem;
    border-bottom-left-radius: 0.313rem;
  }
  tbody .trHover:hover td:last-child {
    border-top-right-radius: 0.313rem;
    border-bottom-right-radius: 0.313rem;
  }
  th {
    color: ${(props) => props.theme.colors.secondaryBlack};
    font-size: 0.75rem;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    line-height: 1rem;
    letter-spacing: -0.021rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    margin-bottom: 2rem;
    text-align: right;
    width: 0;
  }
  .headerRow {
    border-bottom: 0.063rem solid ${(props) => props.theme.colors.mercury};
  }
  h3 {
    margin-left: 0.8rem;
    color: ${(props) => props.theme.colors.secondaryBlack};
    font-family: ${(props) => props.theme.font};
  }
  td {
    font-size: 0.875rem;
    font-family: ${(props) => props.theme.font};
    color: ${(props) => props.theme.colors.secondaryBlack};
    text-align: right;
    width: 0;
    padding: 0 0.5rem;
  }
  tr {
    border-radius: 0.313rem;
  }
  .alignLeft {
    text-align: left;
  }
`;

const Table = (props) => {
  const {
    heading,
    headers,
    body,
    customTableContainer,
    customTableHeading,
    customTableHeaderRow,
    customTableHeaderColumn,
    customTableRow,
    customTableColumn,
    noOfcolumnAlignedLeft,
    disableHover,
    hoverableRows,
  } = props;
  const hoverable = hoverableRows ? hoverableRows : body.length;
  return (
    <StyledTable>
      {heading ? <h3 style={customTableHeading}>{heading}</h3> : null}
      <table style={customTableContainer}>
        <thead>
          {headers && (
            <tr className="headerRow" style={customTableHeaderRow}>
              {headers.map((item, index) => {
                return (
                  <th
                    className={`${
                      noOfcolumnAlignedLeft > index ? "alignLeft" : ""
                    }`}
                    style={customTableHeaderColumn}
                    key={index}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {body.map((item, index) => {
            return (
              <tr
                className={`${
                  disableHover || index > hoverable - 1 ? "" : "trHover"
                }`}
                key={index}
                style={customTableRow}
              >
                {item.map((innerItem, innerIndex) => {
                  return (
                    <td
                      className={`${
                        noOfcolumnAlignedLeft > innerIndex ? "alignLeft" : ""
                      }`}
                      style={customTableColumn}
                      key={innerIndex}
                    >
                      {innerItem}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledTable>
  );
};

Table.propTypes = {
  heading: PropTypes.string,
  headers: PropTypes.array,
  body: PropTypes.array,
  customTableContainer: PropTypes.instanceOf(Object),
  customTableHeading: PropTypes.instanceOf(Object),
  customTableHeaderRow: PropTypes.instanceOf(Object),
  customTableHeaderColumn: PropTypes.instanceOf(Object),
  customTableRow: PropTypes.instanceOf(Object),
  customTableColumn: PropTypes.instanceOf(Object),
  noOfcolumnAlignedLeft: PropTypes.number,
  disableHover: PropTypes.any,
  hoverableRows: PropTypes.number,
};

export default Table;
