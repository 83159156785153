import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../components/Button'
import { routes } from '../../routes/constants'
import { colors } from '../../themes/constant'
import logo from "../../assets/snaptrude.svg";

const Wrapper = styled.div`
    font-family: DM Sans;
    background-color: ${colors.fullWhite};
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
const Logo = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    img{
        width: 10em;
    }
`

const Content = styled.div`
    width: 25em;
    a{
        text-decoration: none;
    }
`
const Heading = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.333333px;
    margin-bottom: 40px;
`
const Text = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.333333px;
    margin-bottom: 40px;

`

function FileNotFound() {
    return (
        <Wrapper>
            <Logo>
                <Link to={routes.dashboard}>
                    <img src={logo} alt="Snaptrude" />
                </Link>
            </Logo>
            <Content>
                <Heading>File not found</Heading>
                <Text>Either this file doesn’t exist or you don’t have permission to view it. Ask the file owner to verify the link and/or update permissions.</Text>
                <Link to={routes.dashboard}><Button primary title="Login" /></Link>
            </Content>
        </Wrapper>
    )
}

export default FileNotFound
