import React from "react";
import styled from "styled-components";
import cross from "../../assets/icons/cross.svg";
import { colors } from "../../themes/constant";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.625rem 1.75rem;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    margin: 0 0.9375rem;
  }
`;

const ImageContainer = styled.div`
  width: 5.8125rem;
  margin: 0.3125rem;
  position: relative;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  div {
    height: min-content;
    position: absolute;
    top: 0.1875rem;
    right: 0.5rem;
    padding: 0.25rem;
    background-color: ${colors.fullWhite};
    border-radius: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DropdowndownOverview = (props) => {
  const { customProps } = props;
  const { files, removeFile } = customProps;

  return (
    <Container>
      <StyledDiv style={{ minHeight: "150px", justifyContent: "flex-start" }}>
        {files &&
          files.map((file, index) => {
            return (
              <ImageContainer key={index}>
                <img
                  style={{
                    width: "5.1875rem",
                    height: "5.375rem",
                    borderRadius: "8px",
                  }}
                  src={file.preview}
                  alt=""
                />
                <div onClick={() => removeFile(index)}>
                  <img
                    style={{
                      width: "0.4375rem",
                      height: "0.4375rem",
                      margin: 0,
                    }}
                    src={cross}
                    alt="x"
                  />
                </div>
              </ImageContainer>
            );
          })}
      </StyledDiv>
    </Container>
  );
};
