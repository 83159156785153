import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
// import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine";
import { StateMachine } from "../modules/Classes/StateMachine.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { isTwoDimension } from "./twoDimension.js";
import { nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import {
  findNearestEdgeFromBRep,
  getTopFaceVertices,
  updateBrepPositions,
  getLowerVertexOfTheEdge,
  getEdgeObjectFromVertexPositions,
  getFaceIdFromFacet,
  getPositionFromVertex,
} from "./brepOperations.js";
import { lineParallelToWhichAxis, areEdgesSame } from "./snapUtilities.js";
import {
  areEdgesParallel,
  deepCopyObject,
  focusBabylonGUIElement,
  mmToSnaptrudeUnits,
  getDistanceBetweenVectors,
  getIndicesOfPointInVerData,
  convertGlobalVector3ToLocal, invertEdge,
} from "../modules/extrafunc.js";
import { virtualSketcher } from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import {
  getComponentsLinkedToEdge,
  getComponentsLinkedToEdge2D,
  showEdgeIndicator,
  disposeHightlightedEdge,
  getCorrespondingGlobalPointOfOtherInstance,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import { geometryUpdater } from "../modules/sketchMassBIMIntegration/geometryUpdater.js";
import { moveFace } from "../modules/meshoperations/moveOperations/moveFace.js";
import {
  getCircularlyNextElementInArray,
  getCircularlyPreviousElementInArray,
} from "./arrayFuncs.js";
import { pseudoEdgeRenderer } from "../modules/meshoperations/moveOperations/pseudoEdgeRenderer.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { isMinusKey } from "./keyEvents.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { externalUtil } from "../modules/externalUtil.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants";

var editPolygonOperator = (function () {
  const EVENTS = {
    0: cleanUp,
    1: _highlightEdgeAndShowLength,
    2: _lockEdge,
    3: _intermediateCleanUp,
    4: _openKeypad,
  };

  const mouseSequence = {
    pointermove: {
      0: [1, 1, 0],
      1: [1, 1, [3, 0, 0]],
    },

    pointerdown: {
      1: [2, 2, 0],
      2: [0, 0, 0],
    },
  };

  const iPadTapSequence = [1, [2, 2, [0, 0, 0]], [0, 0, 0]];

  const touchSequence = {
    pointerdown: {
      0: iPadTapSequence,
      2: iPadTapSequence,
    },
  };

  const STATE_MACHINE = new StateMachine(EVENTS, mouseSequence, touchSequence);

  const _CONSTANTS = {
    operatingSpace: null,
    roundOffFactor: 5,
    preSnapMaterial: null,
    postSnapMaterial: null,
    safeReject: "ignorePromise",
  };
  const init = () => {
    _CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
    _CONSTANTS.roundOffFactor = 5;
    _CONSTANTS.preSnapMaterial = colorUtil.getMaterial(colorUtil.type.preSnap);
    _CONSTANTS.postSnapMaterial = colorUtil.getMaterial(colorUtil.type.postSnap);
  }
  let _selectedEdge = null;
  let _edgeLength = null;
  let _meshOfInterest = null;
  let _componentOfInterest = null;
  let _vertexMovementArray = [];
  let _options = null;
  let _edgeDirection = null;

  const _componentsOfInterest = [];
  const _meshesOfInterest = [];

  let _componentEdgeDataMap = new Map();

  let _dataForMoveFace = {};
  let _inputTextBox;

  let _inputVerificationNotRequired = false;

  function _highlightEdgeAndShowLength() {
    return new Promise((resolve, reject) => {
      let resolved = false;

      if (!store.$scope.isTwoDimension) {
        reject(_CONSTANTS.safeReject);
        return;
      }

      let pickInfo = scenePickController.compoundPick(mesh => {
        return mesh.type !== "staircase";
      });

      if (pickInfo.hit) {
        const options = {};
        let highlightedEdge = findNearestEdgeFromBRep(
          pickInfo,
          _CONSTANTS.operatingSpace,
          options
        );
        if (highlightedEdge) {
          _edgeDirection = lineParallelToWhichAxis(
            highlightedEdge.headPt,
            highlightedEdge.tailPt
          );
          if (
            !_edgeDirection
          ) {
            /*showEdgeIndicator(edge, _meshOfInterest, {
                indicator : uiIndicatorsHandler.edgeIndicator.TYPES.preSnapIndicator
            });
            uiIndicatorsHandler.edgeIndicator.markAsUneditable();*/
            reject(_CONSTANTS.safeReject);
            return;
          }

          if (
            _meshesOfInterest.includes(pickInfo.pickedMesh) &&
            _selectedEdge &&
            areEdgesParallel(_selectedEdge, highlightedEdge)
          ) {
            resolve();
            return;
          } else {
            _intermediateCleanUp();
          }

          _meshOfInterest = pickInfo.pickedMesh;
          _componentOfInterest = _meshOfInterest.getSnaptrudeDS();

          if (
            !virtualSketcher.isComponentPresentInGraph(_componentOfInterest) ||
            virtualSketcher.util.isComponentPlanar(_componentOfInterest) ||
            virtualSketcher.util.doesComponentGrowDownwards(
              _componentOfInterest
            )
          ) {
            _componentsOfInterest.push(_componentOfInterest);
          } else {
            const options = {
              doNotFilterInstances: true,
            };
            _componentsOfInterest.push(
              ...getComponentsLinkedToEdge(
                highlightedEdge,
                _componentOfInterest,
                options
              )
            );
          }

          if (_componentsOfInterest.length > 2) {
            console.error("More than 2 components found for selected edge");
            reject(_CONSTANTS.safeReject);
            return;
          }

          _componentsOfInterest.forEach((c) => {
            let edgeOfC, facetId;

            if (c === _componentOfInterest) {
              facetId = pickInfo.faceId;
              edgeOfC = highlightedEdge;
            } else {
              facetId = geometryUpdater.util.getTopFacetId(c);
              edgeOfC = {
                headPt: highlightedEdge.tailPt,
                tailPt: highlightedEdge.headPt,
              };
            }

            _componentEdgeDataMap.set(c, {
              edge: edgeOfC,
              facetId,
              edges: null,
              components: null,
              faceVertices: null,
            });
          });

          const parallelFaceData = {};
          const dataForParallelFaceSearch = {
            edge: highlightedEdge,
            pickInfo,
            component: _componentOfInterest,
          };

          moveFace.util.getFaceObjectFromTopEdgeAndLookForParallelFaces(
            dataForParallelFaceSearch,
            parallelFaceData
          );

          if (parallelFaceData.pickData) {
            const linkedComponent = parallelFaceData.pickData.component;
            _componentsOfInterest.push(linkedComponent);
            
            _componentEdgeDataMap.set(linkedComponent, {
              edge: {
                headPt: parallelFaceData.pickData.edge.tailPt,
                tailPt: parallelFaceData.pickData.edge.headPt,
              },
              facetId: geometryUpdater.util.getTopFacetId(linkedComponent),
              edges: null,
              components: null,
              faceVertices: null,
            });
          }

          _meshesOfInterest.push(..._componentsOfInterest.map((c) => c.mesh));

          _componentsOfInterest.forEach((c) => {
            const edgeData = _componentEdgeDataMap.get(c);
            const faceVertices = getTopFaceVertices(c);

            edgeData.edges = _getExtensionsOfTheEdge(
              faceVertices,
              edgeData.edge,
              false
            );
            edgeData.components = edgeData.edges.map((edge) => {
              return getComponentsLinkedToEdge2D(edge, c, {
                excludeActual: true,
              })[0];
            });

            edgeData.faceVertices = faceVertices;
          });

          resolved = _processEdge(pickInfo);

          if (resolved) resolve();
        }
      }

      if (!resolved) {
        reject();
      }
    });
  }

  const _processEdge = function (pickInfo, showIndicators = true) {
    const edgeData = _componentEdgeDataMap.get(_componentOfInterest);

    const faceVertices = edgeData.faceVertices;
    const allEdgesAlongSide = edgeData.edges;
    const edge = {
      headPt: _.first(allEdgesAlongSide).headPt,
      tailPt: _.last(allEdgesAlongSide).tailPt,
    };

    _selectedEdge = edge;
    _edgeLength = BABYLON.Vector3.Distance(edge.headPt, edge.tailPt);
    _options = { facetId: pickInfo.faceId };

    const nextHeadPt = edge.tailPt;
    const nextTailPt = getCircularlyNextElementInArray(faceVertices, (v) =>
      v.almostEquals(nextHeadPt)
    );

    if (!nextHeadPt || !nextTailPt) {
      console.log("Edge to be moved not determined");
      return;
    }

    const nextEdge = {
      headPt: nextHeadPt,
      tailPt: nextTailPt,
    };

    const edgesToBeMoved = _getExtensionsOfTheEdge(
      faceVertices,
      nextEdge,
      false
    );

    const firstEdgeBeingMoved = edgesToBeMoved[0];
    const componentOnTheOtherSide = getComponentsLinkedToEdge2D(
      firstEdgeBeingMoved,
      _componentOfInterest,
      {
        excludeActual: true,
      }
    )[0];

    if (componentOnTheOtherSide) {
      const allComponentsInvolved = [];
      Array.from(_componentEdgeDataMap.values()).forEach((data) => {
        allComponentsInvolved.push(...data.components);
      });

      if (!allComponentsInvolved.includes(componentOnTheOtherSide))
        _inputVerificationNotRequired = true;
    } else {
      // no other mass is being impacted
      _inputVerificationNotRequired = true;
    }

    _dataForMoveFace.edgesToBeMoved = edgesToBeMoved;

    _dataForMoveFace.pickInfo = pickInfo;
    _dataForMoveFace.hoveredEdge = edge;
    
    if (showIndicators){
      const headIndex = _.findIndex(faceVertices, (v) =>
        v.almostEquals(edge.headPt)
      );
      const tailIndex = _.findIndex(faceVertices, (v) =>
        v.almostEquals(edge.tailPt)
      );
  
      let verticesToExclude = [];
      if (headIndex < tailIndex) {
        verticesToExclude = faceVertices.slice(headIndex, tailIndex + 1);
      } else {
        verticesToExclude = faceVertices.slice(headIndex);
        verticesToExclude.push(...faceVertices.slice(0, tailIndex + 1));
      }
  
      const optionsForEdgeHighlight = {
        colors: [],
      };
      let allFaceVertices = [];
  
      if (_componentOfInterest.mesh.isAnInstance) {
        const allInstanceComponents =
          _componentOfInterest.mesh.sourceMesh.instances.map((i) =>
            i.getSnaptrudeDS()
          );
        _.remove(allInstanceComponents, _componentOfInterest);
        _.remove(
          allInstanceComponents,
          (c) => c.storey !== _componentOfInterest.storey
        );
        allFaceVertices = allInstanceComponents.map((c) => getTopFaceVertices(c));
        optionsForEdgeHighlight.colors = allFaceVertices.map(
          (v) => pseudoEdgeRenderer.CONSTANTS.EDGE_COLOR_COPIES
        );
      }
  
      allFaceVertices.push(faceVertices);
      optionsForEdgeHighlight.colors.push(
        pseudoEdgeRenderer.CONSTANTS.EDGE_COLOR_POST_SNAP
      );
  
      uiIndicatorsHandler.edgeIndicator.massConstruct(
        allFaceVertices,
        verticesToExclude,
        optionsForEdgeHighlight
      );
  
      showEdgeIndicator(edge, _meshOfInterest, {
        indicator: uiIndicatorsHandler.edgeIndicator.TYPES.preSnapIndicator,
      });
      
      _inputTextBox = DisplayOperation.displayTextBox(
        _edgeLength,
        BABYLON.Vector3.Center(edge.headPt, edge.tailPt),
        {
          onChangeCallback: updateEditPolygon,
          keyFilter: isMinusKey,
        }
      );
      
      if (_edgeDirection === GLOBAL_CONSTANTS.strings.snaps.x){
        _inputTextBox.linkOffsetY = -15;
      }
      else if (_edgeDirection === GLOBAL_CONSTANTS.strings.snaps.z){
        // _inputTextBox.linkOffsetX = 15;
      }
      
      uiIndicatorsHandler.markForUpdate();
  
      if (!store.isiPad) {
        // to prevent the cursor from coming somewhere in the middle of the text box
        // problem happens when the click to lock edge is picked up by the text box as well
        _inputTextBox.isHitTestVisible = false;
      }
      
    }

    return true;
  };

  /**
   * Extends the edge in place (modifies the edge arg) and returns next edge, in CCW order for now
   * @param faceVertices
   * @param edge
   * @param combineEdges
   * @private
   */
  const _getExtensionsOfTheEdge = function (faceVertices, edge, combineEdges) {
    // const resolveEngineUtils = new ResolveEngineUtils();
    const edges = [edge];
    edge = deepCopyObject(edge);

    let loopLimit = 10;
    while (loopLimit--) {
      const potentialNextHeadPt = edge.tailPt;
      const potentialNextTailPt = getCircularlyNextElementInArray(
        faceVertices,
        (v) => v.almostEquals(potentialNextHeadPt)
      );

      const potentialPreviousTailPt = edge.headPt;
      const potentialPreviousHeadPt = getCircularlyPreviousElementInArray(
        faceVertices,
        (v) => v.almostEquals(potentialPreviousTailPt)
      );

      let edgeDoesNotExtend = true;

      // to check if edge extends in direction of movement
      if (
        store.resolveEngineUtils.onSegment2D(
          edge.headPt,
          edge.tailPt,
          potentialNextTailPt
        )
      ) {
        edge.tailPt = potentialNextTailPt;

        edges.push({
          headPt: potentialNextHeadPt.clone(),
          tailPt: potentialNextTailPt.clone(),
        });

        edgeDoesNotExtend = false;
      }

      // to check if edge extends in opposite direction of movement
      if (
        store.resolveEngineUtils.onSegment2D(
          edge.tailPt,
          edge.headPt,
          potentialPreviousHeadPt
        )
      ) {
        edge.headPt = potentialPreviousHeadPt;

        // so that the order of edges in edges[] is maintained
        edges.unshift({
          headPt: potentialPreviousHeadPt.clone(),
          tailPt: potentialPreviousTailPt.clone(),
        });

        edgeDoesNotExtend = false;
      }

      if (edgeDoesNotExtend) {
        break;
      }
    }

    return combineEdges ? edge : edges;
  };

  function _lockEdge() {
    return new Promise((resolve, reject) => {
      let resolved = false;

      disposeHightlightedEdge();
      showEdgeIndicator(_selectedEdge, _meshOfInterest, {
        indicator: uiIndicatorsHandler.edgeIndicator.TYPES.postSnapIndicator,
      });
      
      uiIndicatorsHandler.arrowIndicator.show(_selectedEdge, _meshOfInterest, {
        indicator: uiIndicatorsHandler.arrowIndicator.TYPES.editPolygonDirectionIndicator,
      });

      if (_componentOfInterest.mesh.isAnInstance) {
        const optionsForEdgeHighlight = {
          colors: [],
        };

        let otherEdges = [];

        const allInstanceComponents =
          _componentOfInterest.mesh.sourceMesh.instances.map((i) =>
            i.getSnaptrudeDS()
          );
        _.remove(allInstanceComponents, _componentOfInterest);
        _.remove(
          allInstanceComponents,
          (c) => c.storey !== _componentOfInterest.storey
        );

        allInstanceComponents.forEach((c) => {
          const edgePointsForThisInstance =
            getCorrespondingGlobalPointOfOtherInstance(
              [_selectedEdge.headPt, _selectedEdge.tailPt],
              _componentOfInterest,
              c
            );
          otherEdges.push(edgePointsForThisInstance);
        });

        optionsForEdgeHighlight.colors = otherEdges.map(
          (v) => pseudoEdgeRenderer.CONSTANTS.EDGE_COLOR_COPIES
        );

        uiIndicatorsHandler.edgeIndicator.massConstruct(
          otherEdges,
          [],
          optionsForEdgeHighlight
        );
      }
      

      if (!store.isiPad) {
        focusBabylonGUIElement(_inputTextBox);
      }

      resolve();
      resolved = true;

      if (!resolved) {
        reject();
      }
    });
  }

  function _openKeypad() {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  const eventHandler = function (evt) {
    STATE_MACHINE.nextEvent(evt);
  };

  const _verifyIfInputIsWithinLimits = function (input) {
    /*
        If edgesBeingMoved.length > 1, limits for that will be taken care of in moveFace
        Also, the case where edgesBeingMoved[0] doesn't have any neighbour but when moved after a certain extent
        causes overlap  is not handled here.

        _inputVerificationNotRequired will be true for both the above cases

        These two cases-
        https://imgur.com/a/J70rPIa
         */

    if (_inputVerificationNotRequired) return true;

    let legitInput = true;

    const currentComponent = _componentOfInterest;
    const otherComponent = getCircularlyNextElementInArray(
      _componentsOfInterest,
      currentComponent
    );

    const currentEdgeData = _componentEdgeDataMap.get(currentComponent);
    const otherEdgeData = _componentEdgeDataMap.get(otherComponent);

    const currentEdges = currentEdgeData.edges;
    const otherEdges = otherEdgeData.edges;

    const absoluteLowerLimitOfRoomLength = mmToSnaptrudeUnits(500);
    // threshold

    const staggeredSide = currentEdges.length > otherEdges.length;
    const individualSide = currentEdges.length < otherEdges.length;

    if (staggeredSide) {
      // current room length is longer and staggered
      // In both CW and CCW, no upper limit, lower limit is
      // current room length - length of last individual edge in the given direction + absoluteLowerLimitOfRoomLength

      const lastStaggeredEdge = _.last(currentEdges);

      const lengthOfLastStaggeredEdge = getDistanceBetweenVectors(
        lastStaggeredEdge.headPt,
        lastStaggeredEdge.tailPt
      );

      const roomLengthLowerLimitInThisCase =
        _edgeLength -
        lengthOfLastStaggeredEdge +
        absoluteLowerLimitOfRoomLength;

      legitInput = input > roomLengthLowerLimitInThisCase;
    } else if (individualSide) {
      // current room length is smaller, other side is staggered
      // In both CW and CCW, lower limit is absoluteLowerLimitOfRoomLength
      // upper limit is current length + length of next staggered edge - absoluteLowerLimitOfRoomLength
      // if the current edge is the last of the staggered edges, then no upper limit

      // otherEdges.reverse();
      // fixing the direction

      const currentIndividualEdge = _selectedEdge;
      const positionInStaggeredEdges = _.findIndex(otherEdges, (e) =>
        areEdgesSame(currentIndividualEdge, e)
      );

      if (positionInStaggeredEdges === -1){
        legitInput = true;
        // will be checked in moveFace, better than denial of service
      } else if (positionInStaggeredEdges === 0) {
        // last staggered edge, no upper limit
        legitInput = input > absoluteLowerLimitOfRoomLength;
      } else {
        const nextStaggeredEdge = otherEdges[positionInStaggeredEdges - 1];

        const lengthOfNextStaggeredEdge = getDistanceBetweenVectors(
          nextStaggeredEdge.headPt,
          nextStaggeredEdge.tailPt
        );

        const lowerLimit = absoluteLowerLimitOfRoomLength;
        const upperLimit =
          _edgeLength +
          lengthOfNextStaggeredEdge -
          absoluteLowerLimitOfRoomLength;

        legitInput = input < upperLimit && input > lowerLimit;
      }
    } else {
      // simple connected edge, not staggered
      legitInput = true;
    }

    return legitInput;
  };

  const _signalInputError = function () {
    DisplayOperation.signalInputError(_inputTextBox);
  };

  function cancelOperation(){
    if (_meshOfInterest){
      cleanUp();
      return true;
    }
  }

  function updateEditPolygon(input) {
    if (STATE_MACHINE.currentState === 2) {
      input = DisplayOperation.getOriginalDimension(input);

      if (input === 0) cleanUp();
      else {
        /*_
        changeShape(input);
        const _componentOfInterest = _meshOfInterest.getSnaptrudeDS();
        delete _componentOfInterest.brep.indexes;
        onSolid(_meshOfInterest);
        _meshOfInterest.BrepToMesh();

        if (_meshOfInterest.isAnInstance){
            _meshOfInterest.sourceMesh.instances.forEach(i => i.refreshBoundingInfo());
        }
        else {
            _meshOfInterest.refreshBoundingInfo();
        }

        _commandData = commandUtils.geometryChangeOperations.getCommandData(
            _meshOfInterest, _commandData);

        const editPolygonCommand = commandUtils.geometryChangeOperations.getCommand(
            commandUtils.CONSTANTS.editPolygonOperation, _commandData);

        const integrationGeometryChangeCommand = virtualSketcher.updateWithGeometryEdit(
            _componentOfInterest, true);

        const commands = _.compact([editPolygonCommand, integrationGeometryChangeCommand]);
        const yets = commands.map(_ => false);

        CommandManager.execute(commands, yets);
        */

        if (!_verifyIfInputIsWithinLimits(input)) {
          _signalInputError();
          return;
        }

        const movementAmount = input - _edgeLength;
        const movementDirection = _dataForMoveFace.hoveredEdge.tailPt.subtract(_dataForMoveFace.hoveredEdge.headPt).normalize();
  
        const movementData = _dataForMoveFace.edgesToBeMoved.map(edge => {
          return {
            edge,
            movementAmount,
            movementDirection,
            doesEdgeBelongToTopFace : true
          };
        });

        const moveOptions = {
          component: _componentOfInterest,
          saveResults: true,
          movementAmount: input - _edgeLength,
          hoveredEdge: _dataForMoveFace.hoveredEdge,
          movementData
        };

        const moved = moveFace.justMoveIt(moveOptions);

        if (moved) cleanUp();
        else _signalInputError();
      }
    }
  }

  function changeMassBeingEdited() {
    if (_componentEdgeDataMap.size > 1) {
      uiIndicatorsHandler.edgeIndicator.massDestruct();
      DisplayOperation.removeDimensions();

      const otherComponent = getCircularlyNextElementInArray(
        _componentsOfInterest,
        _componentOfInterest
      );
      const edgeData = _componentEdgeDataMap.get(otherComponent);

      _componentOfInterest = otherComponent;
      _meshOfInterest = _componentOfInterest.mesh;

      const fakePickInfo = geometryUpdater.util.getFakePickInfo(
        edgeData.edge.headPt,
        _componentOfInterest,
        edgeData.facetId
      );

      _processEdge(fakePickInfo);
    }

    return true;
  }
  
  function changeDirectionOfMovement() {
    
    const edgeData = _componentEdgeDataMap.get(_componentOfInterest);
    edgeData.faceVertices.reverse();
    edgeData.edges.reverse();
    edgeData.edges = edgeData.edges.map(invertEdge);
    edgeData.edge = invertEdge(edgeData.edge);
    
    const fakePickInfo = geometryUpdater.util.getFakePickInfo(
      edgeData.edge.headPt,
      _componentOfInterest,
      edgeData.facetId
    );

    _processEdge(fakePickInfo, false);
    
    uiIndicatorsHandler.arrowIndicator.remove();
    
    uiIndicatorsHandler.arrowIndicator.show(_selectedEdge, _meshOfInterest, {
      indicator: uiIndicatorsHandler.arrowIndicator.TYPES.editPolygonDirectionIndicator,
    });
    
    return true;
  }
  
  function handleTab(){
    if (STATE_MACHINE.currentState === 1) {
      // edge highlighted but not locked
      return changeMassBeingEdited();
    }
    else if (STATE_MACHINE.currentState === 2){
      // edge locked
      return changeDirectionOfMovement();
    }
    else {
      return false;
    }
  }

  const _changeShape = function (input) {
    /*
    faceVertices are in CCW order.
    In edit polygon, changes should happen to the edge next in CCW order.
    So edge.tailPt should move and edge.headPt should be constant.

    So, if v0-v1 edge is selected,

    v2---------v1
    |           |
    |           |
    |           |
    v3---***---v0

    */

    const _vertexMovementObject = function (vertexSource, vertexDestination) {
      this.vertexSource = vertexSource;
      this.vertexDestination = vertexDestination;

      this.vertexIndices = getIndicesOfPointInVerData(
        vertexSource,
        _componentOfInterest,
        _CONSTANTS.operatingSpace
      );
    };

    const _moveVertices = function () {
      let oldPos = [];
      let newPos = [];
      let verData = _meshOfInterest.getVerticesData(
        BABYLON.VertexBuffer.PositionKind
      );

      _vertexMovementArray.forEach((vertexMovementObject) => {
        let vertexDestinationLocal = convertGlobalVector3ToLocal(
          vertexMovementObject.vertexDestination,
          _meshOfInterest
        );

        oldPos.push([
          verData[vertexMovementObject.vertexIndices[0][0]],
          verData[vertexMovementObject.vertexIndices[0][1]],
          verData[vertexMovementObject.vertexIndices[0][2]],
        ]);
        for (var i = 0; i < vertexMovementObject.vertexIndices.length; i++) {
          verData[vertexMovementObject.vertexIndices[i][0]] =
            vertexDestinationLocal.x;
          verData[vertexMovementObject.vertexIndices[i][1]] =
            vertexDestinationLocal.y;
          verData[vertexMovementObject.vertexIndices[i][2]] =
            vertexDestinationLocal.z;
        }
        newPos.push([
          verData[vertexMovementObject.vertexIndices[0][0]],
          verData[vertexMovementObject.vertexIndices[0][1]],
          verData[vertexMovementObject.vertexIndices[0][2]],
        ]);
      });

      let geometry =
        _meshOfInterest.geometry || _meshOfInterest.sourceMesh.geometry;
      geometry.setVerticesData(
        BABYLON.VertexBuffer.PositionKind,
        verData,
        true
      );
      updateBrepPositions(brep, oldPos, newPos);
      if (_meshOfInterest.type.toLowerCase() === "roof")
        _meshOfInterest.getSnaptrudeDS().updateRoofOutline(oldPos, newPos);
    };

    let brep = _meshOfInterest.getSnaptrudeDS().brep;
    let movementAmountPhase1 = input - _edgeLength;
    let vertex0 = _selectedEdge.headPt;
    let vertex1 = _selectedEdge.tailPt;

    _vertexMovementArray.push(
      new _vertexMovementObject(
        vertex1,
        vertex1.add(
          vertex1.subtract(vertex0).normalize().scale(movementAmountPhase1)
        )
      )
    );

    let vertex0Dash = getLowerVertexOfTheEdge(
      vertex0,
      _meshOfInterest,
      _options,
      _CONSTANTS.operatingSpace
    );
    let vertex1Dash = getLowerVertexOfTheEdge(
      vertex1,
      _meshOfInterest,
      _options,
      _CONSTANTS.operatingSpace
    );

    _vertexMovementArray.push(
      new _vertexMovementObject(
        vertex1Dash,
        vertex1Dash.add(
          vertex1Dash
            .subtract(vertex0Dash)
            .normalize()
            .scale(movementAmountPhase1)
        )
      )
    );

    let edge01Object = getEdgeObjectFromVertexPositions(
      _meshOfInterest,
      brep,
      vertex0,
      vertex1,
      _CONSTANTS.operatingSpace
    );
    let faceId = getFaceIdFromFacet(_options.facetId, _meshOfInterest);

    let edge01HalfEdge = edge01Object.getHalfEdge();
    if (edge01HalfEdge.getFace().getIndex() !== faceId) {
      edge01HalfEdge = edge01HalfEdge.getFlipHalfEdge();
    }

    let edge12HalfEdge = edge01HalfEdge.getNextHalfEdge();
    let edge23HalfEdge = edge12HalfEdge.getNextHalfEdge();
    let edge34HalfEdge = edge23HalfEdge.getNextHalfEdge();

    let vertex2 = getPositionFromVertex(
      edge23HalfEdge.getVertex(),
      _meshOfInterest,
      _CONSTANTS.operatingSpace
    );
    let vertex3 = getPositionFromVertex(
      edge34HalfEdge.getVertex(),
      _meshOfInterest,
      _CONSTANTS.operatingSpace
    );

    let edge12Direction = vertex2.subtract(vertex1);
    let vertex1ShouldGoTo = _vertexMovementArray[0].vertexDestination;
    let vertex2ShouldGoToImproper = vertex1ShouldGoTo.add(edge12Direction);

    let vertex2ShouldGoTo = externalUtil.getPointOfIntersection(
      [vertex2, vertex3, vertex1ShouldGoTo, vertex2ShouldGoToImproper],
      { type: "vector-vector", ignoreY: true }
    );

    if (vertex2ShouldGoTo) {
      vertex2ShouldGoTo.y = vertex2.y;
      _vertexMovementArray.push(
        new _vertexMovementObject(vertex2, vertex2ShouldGoTo)
      );
    }

    let vertex2Dash = getLowerVertexOfTheEdge(
      vertex2,
      _meshOfInterest,
      _options,
      _CONSTANTS.operatingSpace
    );
    let vertex3Dash = getLowerVertexOfTheEdge(
      vertex3,
      _meshOfInterest,
      _options,
      _CONSTANTS.operatingSpace
    );

    let edge12DashDirection = vertex2Dash.subtract(vertex1Dash);
    let vertex1DashShouldGoTo = _vertexMovementArray[1].vertexDestination;
    let vertex2DashShouldGoToImproper =
      vertex1DashShouldGoTo.add(edge12DashDirection);

    let vertex2DashShouldGoTo = externalUtil.getPointOfIntersection(
      [
        vertex2Dash,
        vertex3Dash,
        vertex1DashShouldGoTo,
        vertex2DashShouldGoToImproper,
      ],
      { type: "vector-vector", ignoreY: true }
    );

    if (vertex2DashShouldGoTo) {
      vertex2DashShouldGoTo.y = vertex2Dash.y;
      _vertexMovementArray.push(
        new _vertexMovementObject(vertex2Dash, vertex2DashShouldGoTo)
      );
    }

    // if (_vertexMovementArray.length === 4) _moveVertices();

    _moveVertices();
    // because of mass-bim many cases are not possible, so let something happen instead of nothing
  };

  function _intermediateCleanUp() {
    _selectedEdge = null;
    _edgeLength = null;
    _options = null;
    uiIndicatorsHandler.edgeIndicator.massDestruct();
    uiIndicatorsHandler.arrowIndicator.remove();
    disposeHightlightedEdge();
    DisplayOperation.removeDimensions();

    _meshOfInterest = null;
    _componentOfInterest = null;
    _componentsOfInterest.length = 0;
    _meshesOfInterest.length = 0;
    _inputVerificationNotRequired = false;

    _componentEdgeDataMap = new Map();

    return Promise.resolve();
  }

  function cleanUp() {
    _intermediateCleanUp();

    _dataForMoveFace = {};
    _vertexMovementArray = [];

    STATE_MACHINE.reset();

    return Promise.resolve();
  }

  return {
    init,
    eventHandler,
    updateEditPolygon,
    cancelOperation,
    handleTab,
    cleanUp,
  };
})();
export { editPolygonOperator };
