import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as constants from "./constants";
import * as styles from "./styles";
import ReactModal from "react-modal";

import backArrow from "../../../assets/icons/back_arrow.svg";
import Manage from "../../../components/Card/Manage";
import Payment from "../../../components/Card/Payment";
import Table from "../../../components/Table";
import Outer from "../../../components/Card/Outer";
import Button from "../../../components/Button";
import { routes } from "../../../routes/constants";

import { cancelSubscription, retrieveStripeSubscription, updateCard } from "../../../services/payment.service";
import { capitalize } from "lodash";
import { CURRENCY_SYMBOLS } from "../../payment/constants";

import queryString from 'query-string';

import CloseButton from "../../../assets/icons/close.svg";

import { colors } from "../../../themes/constant";
import Text from "../../../components/Text";
import mixpanel from "mixpanel-browser";

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;
const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.025rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: 2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

const Row = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Flex = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  flex: 1;
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
`;

const Card = styled(Outer)`
  width: 100%;
  padding: 1rem;
`;

const CustomButton = styled(Button)`
  background: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.colors.secondaryGrey};
  padding: 1.2rem 0.5rem 1.2rem 1rem;
  text-align: right;
`;

function Billing ({ history }) {
  const [currency, setCurrency] = useState('inr')
  const [interval, setInterval] = useState('month')
  const [displayAmount, setDisplayAmount] = useState('xxx')
  const [totalDisplayAmount, setTotalDisplayAmount] = useState('xxx')
  const [numberOfUsers, setNumberOfUsers] = useState('X')
  const [renewalDate, setRenewalDate] = useState('x/x/x')
  const [cardBrand, setCardBrand] = useState('xxxxx')
  const [cardNoLast4, setCardNoLast4] = useState('xxxx')
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);

  const showCancelSubModalHandler = (isShow) => {
    setShowCancelSubModal(isShow);
  }

  const onClose = () => {
    setShowCancelSubModal(false);
  }

  useEffect((effect) => {
    window.analytics.track('opened billing page');
    retrieveStripeSubscription().then((data) => {
      console.log(data)
      if (data.status == 'not found') {
        setIsSubscribed(false);
      } else {
        setIsSubscribed(true);
        setCurrency(data.price.currency);
        setInterval(data.price.interval);
        setDisplayAmount(data.price.displayAmount);
        setTotalDisplayAmount(data.price.totalDisplayAmount);
        setNumberOfUsers(data.quantity);
        let unixTime = data.renewalDate;
        let t = new Date(unixTime * 1000);
        let formatted = t.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: "numeric" })
        setRenewalDate(formatted);
        setCardNoLast4(data.paymentMethod.card.last4)
        setCardBrand(data.paymentMethod.card.brand)

        let invoices = [];
        data.invoices.forEach(invoice => {
          let unixTime = invoice.createdOn;
          let t = new Date(unixTime * 1000);
          let formatted = t.toLocaleDateString('en-US', { month: 'long', year: "numeric" })

          let viewElement = <a href={invoice.url}>View</a>;

          let displayAmount = CURRENCY_SYMBOLS[invoice.currency] + ' ' + (invoice.amount_due / 100);
          invoices.push(
            [invoice.number, formatted, displayAmount, viewElement]
          )
        });

        setInvoices(invoices);
      }
    });

    let query = queryString.parse(window.location.search)
    let sessionId = query.session_id

    if (sessionId) {
      updateCard(sessionId).then(data => {
        console.log(data);
      })
    }

  }, [])

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(87, 87, 88, 0.6)",
      zIndex: 10,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "none",
      background: "transparent",
      overflow: 'visible',
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div>
      <ReactModal
        ariaHideApp={false}
        isOpen={showCancelSubModal}
        onRequestClose={() => { setShowCancelSubModal(false) }}
        style={customStyles}
      >
        <Card>
          <ModalHeader>
            <ModalChild left="1.0625rem">
              <Text opacity={1} weight="bold">
                Cancel Subscription
              </Text>
            </ModalChild>
            <ModalChild right="1.0625rem">
              <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
            </ModalChild>
          </ModalHeader>
          <ModalBody>
            <Row>
              Cancelling your subscription will result in your entire team losing access to their files.
            </Row>
            <Row>
              <strong>Are you sure you want to cancel?</strong>
            </Row>
          </ModalBody>
          <ModalFooter>

            <ButtonWrapper>
              <Button image={backArrow}
                customButtonStyle={{ background: colors.transparent }}
                customButtonTextStyle= {{ color: colors.brightRed }}
                primary={true}
                disabled={false}
                title="Back to Billing"
                onPress={onClose} />
            </ButtonWrapper>

            <ButtonWrapper>
              <Button primary={false} disabled={false} title="Cancel Subscription" onPress={() => { cancelSubscription(); onClose(); }} />
            </ButtonWrapper>

          </ModalFooter>
        </Card>
      </ReactModal>

      <Flex flexDirection="column">
        <Flex>
          <Flex marginRight="1.0625rem">
            <Manage
              isSubscribed={isSubscribed}
              users={numberOfUsers}
              interval={capitalize(interval)}
              total={totalDisplayAmount}
              professional={displayAmount}
              renewal={renewalDate}
              currencySymbol={CURRENCY_SYMBOLS[currency]}
              onPress={() => {
                window.analytics.track('clicked manage subscription on billing page');
                history.push(routes.plans);
              }}
            />
          </Flex>
          <Flex>
            <Payment
              cardName={capitalize(cardBrand)}
              cardNumber={"xxxx " + cardNoLast4}
              showCancelSubModalHandler={showCancelSubModalHandler}
            />
          </Flex>
        </Flex>

        {/* ------------------------------- invoices ---------------------------------- */}
        <Flex marginTop="2.6875rem">
          <Card hover={true}>
            <Table
              customTableContainer={styles.billingStyles.customTableContainer}
              customTableRow={styles.billingStyles.customTableRow}
              customTableHeading={styles.billingStyles.customTableHeading}
              noOfcolumnAlignedLeft={2}
              heading={constants.TABLE_HEADING}
              headers={constants.TABLE_HEADERS}
              body={invoices}
            />
          </Card>
        </Flex>
        {/*//------------------------------- invoices ---------------------------------- */}
      </Flex>
    </div>
  );
}

Billing.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Billing);
