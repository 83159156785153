import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TOOLS_MAPPING } from '../../snaptrude/modules/utilityFunctions/CONSTANTS/tools_mapping';
import OnboardingTooltip from '../OnboardingTooltip';
import {ImageButton} from "./StyledComponents";

function TopRightIcons({icons, handleToolSelection, selectedPanelImage}) {
    const editorNotification = useSelector(state => state.editorNotification);
    return (
        <>
            {icons.map((item, index) => {
                return (
                    <ImageButton
                        className={`top_right_icon ${item[0] === "Comments" && editorNotification.signoffRequested > 0 ? 'show-notification-dot' : ''}`}
                        key={index}
                        onClick={() => handleToolSelection(item[0], "topright")}
                    >
                        <img
                            data-tip
                            data-for={item[0]}
                            data-event='mouseenter'
                            data-event-off='mouseleave click'
                            className={`${selectedPanelImage === item[0]
                                    ? "selected_icon"
                                    : "rightMenuIcon"
                                }`}
                            src={item[1]}
                            alt={item[0]}
                            onClick={() => {
                                if (TOOLS_MAPPING[item[0]] && TOOLS_MAPPING[item[0]].mixpanel) {
                                    window.analytics.track(TOOLS_MAPPING[item[0]].mixpanel);
                                }
                            }}
                        />
                        <OnboardingTooltip id={item[0]} toolid={item[0]}>{item[0]}</OnboardingTooltip>
                    </ImageButton>
                );
            })}
        </>
    );
}

export default TopRightIcons;