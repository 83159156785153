import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import Button from "../../Button";
import Input from "../../Input";
import closeButtonIcon from "../../../assets/icons/close.svg";
import checkboxActiveIcon from "../../../assets/icons/checkboxActive.svg";
import checkboxInactiveIcon from "../../../assets/icons/checkboxInactive.svg";
import DataTable, { Td, Text, Th, Tr } from "../../DataTable";
import { speckleRevitImport } from "../../../snaptrude/modules/speckleRevitImport";

const Wrapper = styled.div`
  position: relative;
  width: 36.5625rem;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 14px;
  color: ${colors.primeBlack};
  header {
    padding: 0.75em 1em;
    border-radius: 12px 12px 0px 0px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
  }
  .content {
    min-height: 15em;
    max-height: 17em;
    overflow: auto;
    padding: 1em;
  }
`;
// const values = [
//     {
//         level: "basement",
//         elevation: "-3500",
//         storey: "Storey -1",
//         height: "3500"
//     },
//     {
//         level: "ground floor",
//         elevation: "0",
//         storey: "Storey 1",
//         height: "5000"
//     },
// ]
function ChooseLevels({
  updatePage,
  handleClose,
  nextPage,
  prevPage,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const all_levels = speckleRevitImport.elementsData.levels;
  const [levels, setLevels] = useState(all_levels);
  const handleSubmit = async (evt) => {
    if (evt) evt.preventDefault();
    setIsLoading(true);
    // call here
    speckleRevitImport.updateLevelStoreyMapping();
    updatePage(prevPage);
    setIsLoading(false);
  };

  const checkIfLevelChecked = (level, otherLevel) => {
    if (otherLevel && otherLevel.isSelected) {
      let elevationDifference = level.elevation - otherLevel.elevation;
      if (Math.abs(elevationDifference) > 2100) {
        return true;
      }
      return false;
    }else if(!otherLevel.isSelected){
        return true
    }
  };

  const handleItemClick = (index) => {
    let newLevels = levels.map((level, idx) => {
      if (index == idx) level.isSelected = !level.isSelected;
      return level
    });

    // let newLevels = []

    // for (let level = 0; level < levels.length; ++level) {
    //   let previousLevel = null;
    //   let nextLevel = null;
    //   let elevationDifference = 0;
    //   if (index == level) {
    //     if (level == 0) {
    //       if (!levels[level].isSelected) {
    //         nextLevel = levels[level + 1];
    //         levels[level].isSelected = checkIfLevelChecked(
    //           levels[level],
    //           nextLevel
    //         );
    //       } else {
    //         levels[level].isSelected = false;
    //       }
    //     } else if (level == levels.length - 1) {
    //       if (!levels[level].isSelected) {
    //         previousLevel = levels[level - 1];
    //         levels[level].isSelected = checkIfLevelChecked(
    //           levels[level],
    //           previousLevel
    //         );
    //       } else {
    //         levels[level].isSelected = false;
    //       }
    //     } else {
    //       if (!levels[level].isSelected) {
    //         nextLevel = levels[level + 1];
    //         previousLevel = levels[level - 1];
    //         levels[level].isSelected =
    //           checkIfLevelChecked(levels[level], nextLevel) &&
    //           checkIfLevelChecked(levels[level], previousLevel);
    //       } else {
    //         levels[level].isSelected = false;
    //       }
    //     }
    //   }
    //   newLevels.push(levels[level])
    // }
    setLevels(newLevels);
  };

  useEffect(() => {
    speckleRevitImport.updateAllLevelsStatus(levels);
  }, [levels]);

  return (
    <Wrapper>
      <header>
        <div style={{ flex: 1, fontWeight: 700 }}>Choose Levels</div>
        <div style={{ cursor: "pointer" }}>
          <img src={closeButtonIcon} alt="Close" onClick={handleClose} />
        </div>
      </header>
      <div className="content">
        <DataTable style={{ fontWeight: "500", fontSize: "12px" }}>
          <Tr>
            <Th style={{ minWidth: "1em", width: "1em" }}></Th>
            <Th style={{ width: "8em" }}>Level</Th>
            <Th style={{ width: "5em", textAlign: "center" }}>Elevation</Th>
          </Tr>
          {levels.map((value, idx) => {
            return (
              <Tr key={idx}>
                <Td style={{ minWidth: "1em", width: "1em" }}>
                  <img
                    src={
                      value.isSelected
                        ? checkboxActiveIcon
                        : checkboxInactiveIcon
                    }
                    alt="action"
                    onClick={() => handleItemClick(idx)}
                    style={{ cursor: "pointer", width: "90%" }}
                  />
                </Td>
                <Td style={{ width: "8em" }}>{value.name}</Td>
                <Td style={{ width: "5em", textAlign: "center" }}>
                  {value.elevation.toFixed(3)}
                </Td>
              </Tr>
            );
          })}
        </DataTable>
      </div>
      <footer>
        <div className="button-wrapper">
          <Button
            customButtonStyle={{
              backgroundColor: colors.fullWhite,
              color: colors.secondaryGrey,
            }}
            title={"Back"}
            onPress={() => {
              updatePage(prevPage);
            }}
          />
        </div>
        <div className="button-wrapper">
          <Button
            isLoading={isLoading}
            primary={true}
            title={"Select"}
            onPress={handleSubmit}
          />
        </div>
      </footer>
    </Wrapper>
  );
}

export default ChooseLevels;
