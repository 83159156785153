import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as constants from "./constants";
import Button from "../../components/Button/index";
import Divider from "../../components/Divider/index";
import Input from "../../components/Input/index";
import { signUpStyles } from "./styles";
import { routes } from "../../routes/constants";
import AuthService from "../../services/auth.service";
import mixpanel from "mixpanel-browser";


const Wrapper = styled.div`
  width: 25rem;
`
const Header = styled.h4`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.primeBlack};
  font-size: ${(props) => props.theme.fontSizes.tiny};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;
const ButtonWrapper = styled.div`
  padding: 0.5rem 0 0.5rem 0;
`;
const ErrorText = styled.p`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.mustard};
  font-size: 0.775rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
`

const mailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      isLoading: false
    };
  }

  handleEmailChange = (evt) => {
    this.setState({
      email: evt.target.value
    })
  }

  handleSumbit = () => {
    window.analytics.track('forgot password submit');
    this.setState({
      message: "",
      isLoading: true
    })
    AuthService.sendResetPasswordMail(this.state.email)
    .then(data => {
      window.analytics.track('forgot password success');
      this.setState({
        message: data.message,
        isLoading: false
      })
    }).catch(err => {
      this.setState({isLoading: false})
      window.analytics.track('forgot password error');
      console.log(err);
    })
  }

  componentDidMount(){
    window.analytics.track('opened forgot password');
  }

  render() {
    const { history } = this.props;
    const { email } = this.state;
    const shouldEmailSubmitDisable = !email.match(mailPattern);
    return (
      <Wrapper>
        <Header>{constants.FORGOT_PASSWORD_CARD_HEADER}</Header>
        <ErrorText style={{ color: "#F2994A" }}>{this.state.message}</ErrorText>
        <Divider customStyle={signUpStyles.dividerStyle} />
        <Input
          value={email}
          placeholder={constants.ENTER_REGISTERED_EMAIL}
          type={"text"}
          onPress={this.handleEmailChange}
        />
        <ButtonWrapper>
          <Button
            title={constants.GET_PASSWORD_REST_LINK}
            disabled={shouldEmailSubmitDisable}
            onPress={this.handleSumbit}
            customButtonStyle={
              shouldEmailSubmitDisable
                ? signUpStyles.signUpButtonDisabled
                : signUpStyles.signUpButtonEnabled
            }
            isLoading={this.state.isLoading}
          />
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

ForgotPassword.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(ForgotPassword);
