export const colors = {
  red: "#EC1C45",
  fullWhite: "#FFFFFF",
  greyishBlack: "#575758",
  white: "#F2F2F2",
  primeBlack: "#2D2D2E",
  secondaryBlack: "#575758",
  brightRed: "#EC1C45",
  lightGrey: "#A5A5A5",
  transparent: "transparent",
  secondaryGrey: "#BDBBBB",
  clearWhite: "#FFFFFF",
  lightGreyShadow: "rgba(197, 202, 220, 0.3)",
  mercury: "#E5E5E5",
  blackRgb: "rgba(87, 87, 88, 0.2);",
  darkGrey: "#4F4F4F",
  gray: "#878787",
  thinGray: "#818181",
  veryLightGrey: "rgba(96, 129, 159, 0.2)",
  orangeYellow: "#ffae42",
  boxShadowgrey: "#e0e0e0",
  green: "#27AE60",
  mediumGrey: "#565657",
  dullgrey: "#bdbbbb",
  mustard: "#F2994A",
  snowTint: "#f9f9f9",
  black02: "rgba(0, 0, 0, 0.2)",
  black05: "rgba(0, 0, 0, 0.5)",
  whiteSmoke: "#f1f1f1",
  borderRed: "#eb1e45",
  shadowGrey: "rgba(197, 202, 220, 0.5)",
  backgroundGrey: "#e5e5e5",
  lightred: "#ff0c3cbf",
  veryLightGrey2: "#dcdcdc",
  veryDimDullGrey: "#cccccc",
  midWhite: "#FCFCFC",
  midGrey: "#cccccc",
};

export const fontSizes = {
  veryTiny: "0.625rem",
  tiny: "0.875rem",
  small: "1rem",
  large: "3rem",
  heading: "1.375rem",
};
