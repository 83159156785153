/*jshint esversion: 6 */
"use strict";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
/**
 * { FloorPlan Data Structure }
 *
 * @class      FloorPlan (name)
 * @param      {Object}  mesh    The mesh
 */
var FloorPlan = function (mesh) {
  this.mesh = mesh;
  this.height = 0;
  this.storey = !mesh.storey ? 1 : mesh.storey;
  this.structure_id = mesh.structure_id;
  this.scaleFactor = 1;
  this.initialScaleFactor = 1;
  this.id = "fp_" + Math.random().toString(36).substr(2, 9);
  this.type = "FloorPlan";
  this.mesh.type = "FloorPlan";
  this.groupId = null;
  this.mesh.storey = this.storey;
  this.floorPlanImageHeight = null;
  this.floorPlanImageWidth = null;
  this.strNum = "1";
  this.edited = false;
  this.mesh.computeWorldMatrix(true);
  this.identifierForBackend = null;
  this.isLocked = false;
  // addTagToMesh(this.mesh, "FloorPlan");
  this.revitMetaData = {};

  // Added this for now, so that save doesn't break.
  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }
};
/**
 * Activate FloorPlan
 */
FloorPlan.prototype.activate = function () {
  this.mesh.isVisible = true;
};

/**
 * Deactivate FloorPlan
 */
FloorPlan.prototype.deactivate = function () {
  this.mesh.isVisible = false;
};

FloorPlan.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
FloorPlan.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
FloorPlan.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};

FloorPlan.prototype.serialize = function () {
  let serializedData = {};

  let entries = Object.entries(this);
  entries.forEach(function (entry) {
    if (!_.isFunction(entry[1]) && entry[0] !== "mesh") {
      serializedData[entry[0]] = entry[1];
    }
  });
  serializedData.mesh = BABYLON.SceneSerializer.SerializeMesh(this.mesh);
  delete serializedData.mesh.materials;

  return serializedData;
};

/**
 * Notifies all.
 */
FloorPlan.prototype.notifyAll = function (action) {};
export { FloorPlan };
