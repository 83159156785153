import BABYLON, { InputText } from "../modules/babylonDS.module.js";
import $ from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  getFaceVerticesFromFacetID,
  getFaceVerticesFromFace,
} from "./brepOperations.js";
import { getNormalVector } from "./mathFuncs.js";
import { getAngleBetweenVector } from "./wall_generation_helper.js";
import { handleEscapeKeyEvent, isBackspaceKey, isEnterKey, isEscapeKey } from "./keyEvents.js";
import {
  changeLabelOfRoom,
  getActiveStoreyObject,
} from "../modules/extrafunc.js";
import { $scope } from "./twoDimension.js";
import {
  lineWithAngleAndAtDistanceFromPoint,
  slopeOfLine,
  lineWithSlopeAurAtDistanceFromPoint,
} from "./twoD/twoServices.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { computeBounds } from "./zoomFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { perpendicularLineToEdge } from "./snapFuncs.js";
import { isInsidePoly1 } from "./twoD/twoSnap.js";
import { offsetRoomPols } from "./sketch/wall_generation.js";
import { appElement } from "./bimDataFuncs.js";
import { ROOM_TYPES } from "../modules/utilityFunctions/CONSTANTS/room_types.js";
import { isNeighborhoodBuilding } from "../modules/geo/terrainNeighborhood.js";

var labelView = (function () {
  let _lines = [];
  let _mesh = null;
  let _hoverInputs = [];
  let _storeyAutoDimensionMap = {};
  let status = false;
  const _createInput = function createInputForLabel(name) {
    let input = new InputText();
    let style = store.advancedTexture.createStyle();
    input.style = style;
    input.name = "label-input-" + name;
    input.height = "15px";
    // input.maxWidthInPixels = "15px"; /* AG-RE: TypeError: Cannot set property maxWidthInPixels of #<e> which has only a getter */
    input.color = "black";
    input._fontSize.ignoreAdaptiveScaling = true;
    input.paddingLeft = 0;
    input.paddingRight = 0;
    input.background = "transparent";
    input.disableMobilePrompt = true;
    input._verticalAlignment += 1;
    input.edited = false;
    input.focusedBackground = "#cccccc";
    input.highligherOpacity = 0.6;
    input.textHighlightColor = "#cccccc";
    input.thickness = 0;
    input.onFocusSelectAll = true;
    input.disabledColor = "transparent";
    input.fontStyle = "capitalize";
    if (name.includes("autoDim")) {
      // let fSize = (1 / store.scene.activeCamera.orthoRight) * 500;
      // if (fSize > 12) fSize = 12;
      input.style.fontSize = 12;
    } else {
      input.style.fontSize = 15;
    }
    store.advancedTexture.addControl(input);
    return input;
  };

  const _getMatchedString = function (query) {
    let result = "";
    if (query !== "") {
      for (var i = 0; i <= ROOM_TYPES.length - 1; i++) {
        if (ROOM_TYPES[i].name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          var currentLength = query.length;
          result = "" + ROOM_TYPES[i].name.toLowerCase().substring(currentLength);
          break;
        }
      }
      return result;
    } else {
      return "";
    }
  };

  const _getPoint = function getCenterPointOfTheMeshToDisplayInputBox(
    mesh,
    facetId
  ) {
    if (mesh.name !== "terrain") {
      let faceVertices = null;
      if (facetId) {
        faceVertices = getFaceVerticesFromFacetID(
          facetId,
          mesh,
          BABYLON.Space.WORLD
        );
      } else {
        faceVertices = getFaceVerticesFromFacetID(0, mesh, BABYLON.Space.WORLD);
      }

      if(!faceVertices){
        const bbinfo = mesh.getBoundingInfo().boundingBox;
        const max = bbinfo.maximumWorld;
        const min = bbinfo.minimumWorld;
        let yPosition = max.y - min.y;
        let center = bbinfo.centerWorld;
        center.y = yPosition;
        return center
      }

      let verticesSum = faceVertices.reduce((total, vertex) => {
        return total.add(vertex);
      }, BABYLON.Vector3.Zero());
      return verticesSum.scale(1 / faceVertices.length);
    }
    return null;
  };

  const _getEdgePoints = function getEdgePointsForTheGivenMesh(
    mesh,
    requiredNormal = null
  ) {
    function _getNormal(faceVertices) {
      return BABYLON.Vector3.FromArray(
        getNormalVector(faceVertices)
      ).normalize();
    }
    try {
      let zerothFaceVertices = getFaceVerticesFromFacetID(
        0,
        mesh,
        BABYLON.Space.WORLD
      );
      let zerothNormal = _getNormal(zerothFaceVertices);

      let downVector = new BABYLON.Vector3(0, -1, 0);
      let upVector = new BABYLON.Vector3(0, 1, 0);
      if (requiredNormal) {
        downVector = requiredNormal;
        upVector = requiredNormal.scale(-1);
      }
      if (
        zerothNormal.almostEquals(downVector) ||
        zerothNormal.almostEquals(upVector)
      ) {
        return zerothFaceVertices;
      } else {
        let brep = mesh.getSnaptrudeDS().brep;
        if (!brep) return;

        let faces = brep.getFaces();

        let arrayOfFaceVertices = [];
        let arrayOfNormals = [];

        let bottomFaceVertices = null;
        faces.some((face) => {
          let faceVertices = getFaceVerticesFromFace(
            face,
            mesh,
            BABYLON.Space.WORLD
          );
          let normal = _getNormal(faceVertices);

          if (
            normal.almostEquals(downVector) ||
            normal.almostEquals(upVector)
          ) {
            bottomFaceVertices = faceVertices;
            return true;
          } else {
            arrayOfFaceVertices.push(faceVertices);
            arrayOfNormals.push(normal);
          }
        });

        if (!bottomFaceVertices) {
          let arrayOfAnglesFormedWithDown = arrayOfNormals.map((normal) =>
            getAngleBetweenVector(normal, downVector)
          );

          let bottomFaceIndex = _.indexOf(
            arrayOfAnglesFormedWithDown,
            _.min(arrayOfAnglesFormedWithDown)
          );

          bottomFaceVertices = arrayOfFaceVertices[bottomFaceIndex];
        }

        return bottomFaceVertices;
      }
    } catch (err) {
      return null;
    }
  };

  const _handleKeys = function handleActionKeysForAutoComplete(
    input,
    meshDS,
    scope,
    mesh
  ) {
    input.processKeyboard = function (evt) {
      if (isBackspaceKey(evt)) {
        if (input.prevText) {
          input.text =
            input.prevText.slice(0, -1)
            _getMatchedString(input.prevText.slice(0, -1));
          input.prevText = input.prevText.slice(0, -1);
          input._cursorOffset = input.text.length - input.prevText.length;
          input._isTextHighlightOn = true;
          input._startHighlightIndex = input.prevText.length;
          input._endHighlightIndex = input.text.length;
          input._cursorOffset = input.text.length - input.prevText.length;
        } else {
          this.processKey(evt.keyCode, evt.key);
        }
      } else if (isEnterKey(evt)) {
        store.advancedTexture.moveFocusToControl(null);
        // mesh.room_type = input.text;
        // meshDS.room_type = input.text;
        changeLabelOfRoom(mesh, input.text);
      } else if (isEscapeKey(evt)) {
        handleEscapeKeyEvent();
      } else {
        this.processKey(evt.keyCode, evt.key);
        // evt.stopPropagation(); // stopped propagation in order to not interact with shortcut keys
      }
      input.autoStretchWidth = true;
      if (input.text) {
        input.text = input.text[0].toUpperCase() + input.text.slice(1);
      }
    };
  };

  const _handleInput = function handleUserInputForAutoComplete(
    input,
    scope,
    event,
    mesh
  ) {
    input.onBeforeKeyAddObservable.add((input, key) => {
      let value = "";
      
      if (input.prevText) {
        value = _getMatchedString(
          input.prevText + input.currentKey
        );
      } else {
        value = _getMatchedString(input.currentKey);
      }
      if (value !== null) {
        if (input.prevText) {
          input.text = input.prevText + input.currentKey + value;
          input.prevText = input.prevText + input.currentKey;
        } else {
          input.text = input.currentKey + value;
          input.prevText = input.currentKey;
        }

        input.placeholderText = input.text + value;
        if (input.text) {
          input.text = input.text[0].toUpperCase() + input.text.slice(1);
        }
        input.highlightedText = value;
        input._isTextHighlightOn = true;
        input._startHighlightIndex = input.prevText.length;
        input._endHighlightIndex = input.text.length;
        input._cursorOffset = input.text.length - input.prevText.length;
        input.addKey = false;
        input.autoStretchWidth = true;
      }
    });

    input.onPointerOutObservable.add((input) => {
      input.thickness = 0;
    });

    if (store.isiPad) {
      /* AG-RE: ANGULAR REFERENCE */
      // input.onFocusObservable.add((input) => {
      //   //
      //   $scope.selectedMeshForLael = mesh;
      //   $scope.showPrerenderedDialog(null, "myLabelDialog");
      // });
    }

    input.onPointerEnterObservable.add((input) => {
      input.thickness = 1;
      input.outlineColor = "black";
      input.hoverCursor = "pointer";
    });
  };

  const _removeLabels = function () {
    _hoverInputs.forEach((input) => input.dispose());
  };

  const _hideLines = () => {
    for (let i = 0; i < _lines.length; i++) {
        if (_lines[i].name.includes("autoLineMesh")){
            _lines[i].isVisible = false;                        
        }
    }
  };

  const _showLines = () => {
      for (let i = 0; i < _lines.length; i++) {
          if (_lines[i].name.includes("autoLineMesh")){
              _lines[i].isVisible = true;
          }
      }
  };

  const _resetOperation = function removeAllDimensionDisplayed(hover) {
    if (hover) {
      if (_lines.length > 1) {
        return;
      }
      _removeLabels();
      _mesh = { uniqueId: "false" };
      _hoverInputs = [];
      return;
    } else {
      store.advancedTexture.executeOnAllControls((control) => {
        if (control) {
          control.dispose();
        }
      });
    }

    for (let i = 0; i < _lines.length; i++) {
      _lines[i].dispose();
    }
    _lines = [];
    if (_mesh && _mesh.uniqueId !== "false" && !_mesh.isDisposed()) {
      let meshDs = _mesh.getSnaptrudeDS();
      if (meshDs) meshDs.inputName = null;
    }
  };

  const displayAutoDimensionsElev = function () {
    if (_lines.length > 0) {
      _resetOperation();
    }
    let distBoxesAndDimesh = [];
    function showAngleSector(origin, vector1, vector2, radius, sectorType) {
      radius = radius || 1;
      sectorType = sectorType || 0;
      var cross = BABYLON.Vector3.Cross(vector1, vector2);
      var dot = BABYLON.Vector3.Dot(vector1, vector2);
      var angle = Math.acos(dot / (vector1.length() * vector2.length()));
      var points = [];
      var minNb = 4;
      var factor = 2;
      var nbPoints = Math.floor(radius * angle * factor);
      nbPoints = nbPoints < minNb ? minNb : nbPoints;

      var firstPoint = BABYLON.Vector3.Normalize(vector1).scale(radius);
      var lastPoint = BABYLON.Vector3.Normalize(vector2).scale(radius);
      var matrix;
      var ang = angle / nbPoints;
      var rotated;
      for (var i = 0; i < nbPoints; i++) {
        matrix = BABYLON.Matrix.RotationAxis(cross, ang * i);
        rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
        points.push(rotated.add(origin));
      }
      points.push(lastPoint.add(origin));

      var sector;
      switch (sectorType) {
        case 0:
          sector = BABYLON.Mesh.CreateLines("sector", points, store.scene);
          sector.color = BABYLON.Color3.Black();
          break;
        case 1:
          var pointO = [];
          for (var j = 0; j < points.length; j++) {
            pointO.push(origin);
          }
          sector = BABYLON.Mesh.CreateRibbon(
            "sector",
            [points, pointO],
            null,
            null,
            0,
            store.scene
          );
          sector.material = new BABYLON.StandardMaterial("sm", store.scene); // sector3 is a Ribbon
          sector.material.diffuseColor = BABYLON.Color3.Blue();
          sector.material.backFaceCulling = false;
          break;
        default:
          sector = BABYLON.Mesh.CreateLines("sector", points, store.scene);
          break;
      }
      return sector;
    }

    const drawSectorCircle = (pt, _lines) => {
      let v1 = new BABYLON.Vector3(1, 0, 0);
      let v2 = new BABYLON.Vector3(0, -1, 0);
      let v3 = new BABYLON.Vector3(-1, 0, 0);
      let v4 = new BABYLON.Vector3(0, 1, 0);
      if (
        store.scene.activeCamera.alpha === 0 ||
        store.scene.activeCamera.alpha === Math.PI
      ) {
        v1 = new BABYLON.Vector3(0, 0, 1);
        v2 = new BABYLON.Vector3(0, -1, 0);
        v3 = new BABYLON.Vector3(0, 0, -1);
        v4 = new BABYLON.Vector3(0, 1, 0);
      }
      let rad = (store.scene.activeCamera.orthoRight * 2) / 100;
      if (rad > 1.5) rad = 1.5;
      _lines.push(showAngleSector(pt, v1, v2, rad, 0));
      _lines.push(showAngleSector(pt, v2, v3, rad, 1));
      _lines.push(showAngleSector(pt, v3, v4, rad, 0));
      _lines.push(showAngleSector(pt, v4, v1, rad, 1));
    };

    const drawDimLines = (st, end, st1, end1, storey, slab = false) => {
      let dimMesh = BABYLON.Mesh.CreateLines(
        "ElevLineMesh",
        [st, end],
        store.scene
      );
      dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
      dimMesh.intersectionThreshold = 100;
      let distBox = _createInput("ElevDim" + dimMesh.uniqueId);
      let crsPts1 = lineWithAngleAndAtDistanceFromPoint(st.asArray(), 90, 20);
      let crsPts2 = lineWithAngleAndAtDistanceFromPoint(end.asArray(), 90, 20);
      let crsPt1st = new BABYLON.Vector3(st1.x, st.y, st1.z);
      let crsPt1end = new BABYLON.Vector3(end1.x, st.y, end1.z);
      let crsPt2st = new BABYLON.Vector3(st1.x, end1.y, st1.z);
      let crsPt2end = new BABYLON.Vector3(end1.x, end1.y, end1.z);
      let crosDimMesh1 = new BABYLON.MeshBuilder.CreateDashedLines(
        "ElevLineMesh",
        { points: [crsPt1st, crsPt1end], dashSize: 15, gapSize: 10 },
        store.scene
      );
      let crosDimMesh2 = new BABYLON.MeshBuilder.CreateDashedLines(
        "ElevLineMesh",
        { points: [crsPt2st, crsPt2end], dashSize: 15, gapSize: 10 },
        store.scene
      );
      let levelBox, gLevelBox, levelMesh;
      if (slab) {
        // levelMesh = BABYLON.Mesh.CreateLines(
        //   "ElevLineMesh",
        //   [st, st],
        //   store.scene
        // );
        // levelBox = _createInput("ElevName" + crosDimMesh1.uniqueId);
        // levelBox.text = "Level 0";

        gLevelBox = _createInput("Ground" + crosDimMesh1.uniqueId);
        gLevelBox.text = "Ground";
        gLevelBox.linkOffsetYInPixels = 22;
        gLevelBox.linkWithMesh(dimMesh);
        distBoxesAndDimesh.push(gLevelBox.text);

        distBox.text = BABYLON.Vector3.Distance(st, end).toString();
        distBox.linkOffsetYInPixels = 0;
        distBox.linkWithMesh(dimMesh);
        distBox.isEnabled = false;
        distBox.thickness = 0;

        // levelBox.linkWithMesh(levelMesh);
        // levelBox.linkOffsetYInPixels = -20;
        // levelBox.isEnabled = false;
        // levelBox.thickness = 0;
        // levelMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
      // levelMesh.intersectionThreshold = 100;
      } else {
        levelMesh = BABYLON.Mesh.CreateLines(
          "ElevLineMesh",
          [st, st],
          store.scene
        );
        levelBox = _createInput("ElevName" + crosDimMesh2.uniqueId);
        if (storey.name) {
          levelBox.text = storey.name;
        } else {
          levelBox.text = "Level " + parseInt(storey.value);
        }
        distBox.text = BABYLON.Vector3.Distance(st, end).toString();
        distBox.linkOffsetYInPixels = -10;

        distBox.linkWithMesh(dimMesh);
        distBox.isEnabled = false;
        distBox.thickness = 0;

        levelBox.linkWithMesh(levelMesh);
        levelBox.linkOffsetYInPixels = -10;
        levelBox.isEnabled = false;
        levelBox.thickness = 0;
        levelMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
        levelMesh.intersectionThreshold = 100;
      }

      
      let secCircle = drawSectorCircle(crsPt1st, _lines);

      dimMesh.isPickable = true;
      dimMesh.color = new BABYLON.Color3(0.15, 0.15, 0.15);
      dimMesh.renderingGroupId = 1;

      crosDimMesh1.isPickable = false;
      crosDimMesh1.color = new BABYLON.Color3(0.15, 0.15, 0.15);
      crosDimMesh1.renderingGroupId = 1;

      crosDimMesh2.isPickable = false;
      crosDimMesh2.color = new BABYLON.Color3(0.15, 0.15, 0.15);
      crosDimMesh2.renderingGroupId = 1;

      if (distBox.text === "0.00") {
        dimMesh.dispose();
        crosDimMesh1.dispose();
        crosDimMesh2.dispose();
        distBox.dispose();
        levelBox.dispose();
      }
      dimMesh.isVisible = false;
      distBoxesAndDimesh.push(distBox.text);
      if(levelBox){
        distBoxesAndDimesh.push(levelBox.text);
        _lines.push(levelMesh);
      }
      
      _lines.push(dimMesh);
      
      _lines.push(crosDimMesh1);
      _lines.push(crosDimMesh2);
      distBox.text = DisplayOperation.convertToDefaultDimension(distBox.text);
    };

    const checkIfPointExists = (st, end, points) => {
      for (let i = 0; i < points.length; i++) {
        if (points[i].st.almostEquals(st) && points.end.almostEquals(end)) {
          return true;
        }
      }
      return false;
    };

    let bounds = computeBounds();
    let offset = (store.scene.activeCamera.orthoRight * 15) / 100;
    let boundsXMax = bounds.boundingBox.maximumWorld.x + offset;
    let boundsZMax = bounds.boundingBox.maximumWorld.z + offset;
    let boundsXMin = bounds.boundingBox.minimumWorld.x - offset;
    let boundsZMin = bounds.boundingBox.minimumWorld.z - offset;
    let storeys = StructureCollection.getInstance()
      .getStructures()
      [store.activeLayer.structure_id].getStoreyData()
      .getAllStoreys();
    for (let storeyId in storeys) {
      let storey = storeys[parseInt(storeyId)];
      let st = new BABYLON.Vector3(
        boundsXMax - (offset * 2) / 3,
        storey.base,
        boundsZMax - (offset * 2) / 3
      );
      let end = new BABYLON.Vector3(
        boundsXMax - (offset * 2) / 3,
        storey.base + storey.height,
        boundsZMax - (offset * 2) / 3
      );
      let st1, end1;

      if (storeyId === "1") {
        let roofs = storey.filterElements(["Roof"]);
        let stPoints = [];
        let endPoints = [];
        let points = [];
        for (let i = 0; i < roofs.length; i++) {
          let rBound = roofs[i].mesh.getBoundingInfo().boundingBox;
          let stVec = new BABYLON.Vector3(st.x, rBound.minimumWorld.y, st.z);
          let enVec = new BABYLON.Vector3(st.x, rBound.maximumWorld.y, st.z);
          if (!checkIfPointExists(st, end, points)) {
            if (
              store.scene.activeCamera.alpha === 0 ||
              store.scene.activeCamera.alpha === Math.PI
            ) {
              st1 = new BABYLON.Vector3(
                boundsXMax,
                rBound.minimumWorld.y,
                boundsZMax
              );
              end1 = new BABYLON.Vector3(
                boundsXMax,
                rBound.maximumWorld.y,
                boundsZMin
              );
            } else {
              st1 = new BABYLON.Vector3(
                boundsXMax,
                rBound.minimumWorld.y,
                boundsZMax
              );
              end1 = new BABYLON.Vector3(
                boundsXMin,
                rBound.maximumWorld.y,
                boundsZMax
              );
            }
            points.push({ st: stVec, end: enVec });
            drawDimLines(stVec, enVec, st1, end1, storey, true);
          }
        }
      }
      // else{
      if (
        store.scene.activeCamera.alpha === 0 ||
        store.scene.activeCamera.alpha === Math.PI
      ) {
        st1 = new BABYLON.Vector3(boundsXMax, storey.base, boundsZMax);
        end1 = new BABYLON.Vector3(
          boundsXMax,
          storey.base + storey.height,
          boundsZMin
        );
      } else {
        st1 = new BABYLON.Vector3(boundsXMax, storey.base, boundsZMax);
        end1 = new BABYLON.Vector3(
          boundsXMin,
          storey.base + storey.height,
          boundsZMax
        );
      }
      drawDimLines(st, end, st1, end1, storey);
      // }
    }
  };

  const displayAutoDimensions = function () {
    if (_lines.length > 0) {
      _resetOperation();
    }

    let meshes = StructureCollection.getInstance()
      .getStructures()
      [store.activeLayer.structure_id].getStoreyData()
      .getStoreyByValue(store.activeLayer.storey)
      .filterElements(["mass", "wall", "floor"]);
    let floors = StructureCollection.getInstance()
      .getStructures()
      [store.activeLayer.structure_id].getStoreyData()
      .getStoreyByValue(store.activeLayer.storey)
      .filterElements(["floor"]);

    let bounds = computeBounds();
    let offset = 2.5;
    let boundsXMax = bounds.boundingBox.maximumWorld.x + offset;
    let boundsZMax = bounds.boundingBox.maximumWorld.z + offset;
    let boundsXMin = bounds.boundingBox.minimumWorld.x - offset;
    let boundsZMin = bounds.boundingBox.minimumWorld.z - offset;

    meshes.forEach((mesh) => {
      let floor;
      let dimOffsetVar = 1.3;
      if (mesh.type == "Floor") {
        if (!mesh.mesh.parent) displayLabel(mesh.mesh, null);
        else return;
      } else if (mesh.type === "Wall") {
        mesh.assignRoomIds(floors);
        if (mesh.room_id) {
          if (mesh.room_id.length === 3) {
            floor = floors.filter((a) => {
              return a.room_id === mesh.room_id;
            });
          }
        }
      } else if (mesh.type == "Mass"){
        const extents = mesh.mesh.getBoundingInfo().boundingBox.extendSizeWorld;
        let off = extents.x > extents.z ? extents.x: extents.z;
        dimOffsetVar = off/20;
        if (dimOffsetVar < 0.3) dimOffsetVar = 0.3;
      }
      let edgePoints;
      try {
        if (!isNeighborhoodBuilding(mesh))
        edgePoints = _getEdgePoints(mesh.mesh);
      } catch (err) {
        edgePoints = [];
      }
      // let elements = StructureCollection.getInstance().getStructureById(activeLayer.structure_id).getStoreyData().getStoreyByValue(activeLayer.storey).elements;
      // console.log(elements);
      // /* eslint-disable */
      // for( element in elements){
      //     if(element.type === 'Wall'){
      //         let children = element.mesh.getChildren();
      //         if(children.length > 0){
      //             for( child in children){
      //                 if(["door", "window"].includes(child.type.toLowerCase())){
      //
      //                 }
      //             }
      //         }
      //     }
      // }

      if (mesh.type.toLowerCase() === "wall") {
        //                 return;
        if (mesh.mesh.getChildren()) {
          let children = mesh.mesh.getChildren();
          if (children.length > 0) {
            let pointSet = mesh.edgeMidPts();
            let st1 = pointSet[0];
            let st2 = pointSet[1];
            let storeyObject = getActiveStoreyObject(store.activeLayer.storey);
            let edgePointsFloor;
            if (floor) {
              edgePointsFloor = floor[0].getBoundaryCoords();
              edgePointsFloor = edgePointsFloor.map((e) => {
                return [e.x, e.z];
              });
            }

            let offsetDim = 2.5;
            let st;
            let prevSt;
            let initSt;
            for (let k = 0; k <= 2 * children.length + 1; k++) {
              let child;
              if (k === 0) {
                child = children[0];
              } else if (k >= 2 * children.length) {
                child = children[children.length - 1];
              } else {
                child = children[Math.ceil((k - 1) / 2)];
              }

              if (["door", "window"].includes(child.type.toLowerCase())) {
                let distBoxesAndDimesh = [];
                let end = child.getAbsolutePosition().clone();
                let vw = child.getBoundingInfo().boundingBox.vectorsWorld;
                let a1 = BABYLON.Vector3.Center(vw[0], vw[4]);
                let a2 = BABYLON.Vector3.Center(vw[2], vw[7]);
                let dir = true;
                if (k === 0) {
                  if (
                    BABYLON.Vector3.Distance(st1, end) <=
                    BABYLON.Vector3.Distance(st2, end)
                  ) {
                    st = st1;
                  } else {
                    dir = false;
                    st = st2;
                  }
                  initSt = st.clone();

                  if (
                    BABYLON.Vector3.Distance(st, a1) <=
                    BABYLON.Vector3.Distance(st, a2)
                  ) {
                    end = a1;
                    prevSt = new BABYLON.Vector2(a1.x, a1.z);
                  } else {
                    end = a2;
                    prevSt = new BABYLON.Vector2(a2.x, a2.z);
                  }
                  st = new BABYLON.Vector2(st.x, st.z);
                  end = new BABYLON.Vector2(end.x, end.z);
                } else if (k === 2 * children.length + 1) {
                  if (
                    BABYLON.Vector3.Distance(st1, end) <=
                    BABYLON.Vector3.Distance(st2, end)
                  ) {
                    if (initSt.equals(st1)) {
                      dir = true;
                      end = st2;
                    } else {
                      dir = false;
                      end = st1;
                    }
                  } else {
                    if (initSt.equals(st2)) {
                      dir = false;
                      end = st1;
                    } else {
                      dir = true;
                      end = st2;
                    }
                  }
                  // end = prevSt;
                  st = child.getAbsolutePosition().clone();
                  if (
                    BABYLON.Vector3.Distance(end, a1) <=
                    BABYLON.Vector3.Distance(end, a2)
                  ) {
                    // dir = true;
                    st = a1;
                  } else {
                    // dir = false;
                    st = a2;
                  }

                  st = new BABYLON.Vector2(st.x, st.z);
                  // st = prevSt;
                  end = new BABYLON.Vector2(end.x, end.z);
                } else {
                  dir = false;
                  st = prevSt;
                  a1 = new BABYLON.Vector2(a1.x, a1.z);
                  a2 = new BABYLON.Vector2(a2.x, a2.z);
                  if (st.equals(a1)) {
                    end = a2;
                    prevSt = a2;
                  } else if (st.equals(a2)) {
                    end = a1;
                    prevSt = a1;
                  } else {
                    if (
                      BABYLON.Vector2.Distance(st, a1) <=
                      BABYLON.Vector2.Distance(st, a2)
                    ) {
                      end = a1;
                      prevSt = a1;
                    } else {
                      end = a2;
                      prevSt = a2;
                    }
                  }
                }
                let perpendicularPoints1 = perpendicularLineToEdge(st, st, end);
                let perpendicularPoints2 = perpendicularLineToEdge(
                  end,
                  st,
                  end
                );

                let slop1 = slopeOfLine([
                  perpendicularPoints1[0].asArray(),
                  perpendicularPoints1[1].asArray(),
                ]);
                let slop2 = slopeOfLine([
                  perpendicularPoints2[0].asArray(),
                  perpendicularPoints2[1].asArray(),
                ]);
                let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
                let slope2 = -1 / slop2 === "inf" ? 0 : slop2;
                if (Math.abs(slope1) > 1e5) slope1 = "inf";
                if (Math.abs(slope2) > 1e5) slope2 = "inf";
                let intWall = mesh.room_id.length > 3? true: false;

                let points1 = lineWithSlopeAurAtDistanceFromPoint(
                  st.asArray(),
                  slope1.toString().includes("Inf") ? "inf" : slope1,
                  offsetDim
                );
                let points2 = lineWithSlopeAurAtDistanceFromPoint(
                  end.asArray(),
                  slope2.toString().includes("Inf") ? "inf" : slope2,
                  offsetDim
                );                

                if ((Math.round(slope1,4) === 0) && !intWall){
                  if (edgePointsFloor){
                    let mP0 = [
                      (points1[0][0] + points2[0][0]) / 2,
                      (points1[0][1] + points2[0][1]) / 2,
                    ];
                    if (
                      !isInsidePoly1(mP0, edgePointsFloor) 
                    ) {
                      if (Math.abs(mP0[0]-boundsXMin) < Math.abs(st.x-boundsXMin)){
                        st.x = boundsXMin;
                        st.y = storeyObject.height;
                        end.x = boundsXMin;
                        end.y = storeyObject.height;
                      }
                      else{
                        st.x = boundsXMax;                    
                        st.y = storeyObject.height;
                        end.x = boundsXMax;                    
                        end.y = storeyObject.height;
                      }  
                    }
                    else{
                      if (Math.abs(mP0[0]-boundsXMin) > Math.abs(st.x-boundsXMin)){
                        st.x = boundsXMin;
                        st.y = storeyObject.height;
                        end.x = boundsXMin;
                        end.y = storeyObject.height;
                      }
                      else{
                        st.x = boundsXMax;                    
                        st.y = storeyObject.height;
                        end.x = boundsXMax;                    
                        end.y = storeyObject.height;
                      }  
                    }
                  }
                  else{
                    if (Math.abs(st.x-boundsXMin) < Math.abs(st.x-boundsXMax)){
                      st.x = boundsXMin;
                      st.y = storeyObject.height;
                      end.x = boundsXMin;
                      end.y = storeyObject.height;
                    }
                    else{
                      st.x = boundsXMax;                    
                      st.y = storeyObject.height;
                      end.x = boundsXMax;                    
                      end.y = storeyObject.height;
                    }  
                  }
                }
                else if ((slope1 === "inf") && !intWall){
                  if (edgePointsFloor){
                    let mP0 = [
                      (points1[0][0] + points2[0][0]) / 2,
                      (points1[0][1] + points2[0][1]) / 2,
                    ];
                    if (
                      !isInsidePoly1(mP0, edgePointsFloor) 
                    ) {
                      if (Math.abs(mP0[1]-boundsZMin) < Math.abs(st.z-boundsZMin)){
                        st.z = boundsZMin;
                        st.y = storeyObject.height;
                        end.z = boundsZMin;
                        end.y = storeyObject.height;
                      }
                      else{
                        st.z = boundsZMax;                    
                        st.y = storeyObject.height;
                        end.z = boundsZMax;                    
                        end.y = storeyObject.height;
                      }  
                    }
                    else{
                      if (Math.abs(mP0[1]-boundsZMin) > Math.abs(st.z-boundsZMin)){
                        st.z = boundsZMin;
                        st.y = storeyObject.height;
                        end.z = boundsZMin;
                        end.y = storeyObject.height;
                      }
                      else{
                        st.z = boundsZMax;                    
                        st.y = storeyObject.height;
                        end.z = boundsZMax;                    
                        end.y = storeyObject.height;
                      }  
                    }
                  }
                  else{
                    if (Math.abs(st.z-boundsZMin) < Math.abs(st.z-boundsZMax)){
                      st.z = boundsZMin;
                      st.y = storeyObject.height;
                      end.z = boundsZMin;
                      end.y = storeyObject.height;
                    }
                    else{
                      st.z = boundsZMax;                    
                      st.y = storeyObject.height;
                      end.z = boundsZMax;                    
                      end.y = storeyObject.height;
                    }
          
                  }
                }
                else if (intWall){
                  st = new BABYLON.Vector3(0, storeyObject.height, 0);
                  end = new BABYLON.Vector3(0, storeyObject.height, 0);
        
                  // if (edgePointsFloor) {
                  //   let mP0 = [
                  //     (points1[0][0] + points2[0][0]) / 2,
                  //     (points1[0][1] + points2[0][1]) / 2,
                  //   ];
                  //   // let mP1 = [(points1[1][0]+points2[1][0])/2, (points1[1][1]+points2[1][1])/2];
                  //   if (
                  //     !isInsidePoly1(points1[0], edgePointsFloor) &&
                  //     !isInsidePoly1(points2[0], edgePointsFloor)
                  //   ) {
                  //     st.x = points1[0][0];
                  //     st.y = storeyObject.height;
                  //     st.z = points1[0][1];
                  //     end.x = points2[0][0];
                  //     end.y = storeyObject.height;
                  //     end.z = points2[0][1];
                  //   } else {
                  //     st.x = points1[1][0];
                  //     st.y = storeyObject.height;
                  //     st.z = points1[1][1];
                  //     end.x = points2[1][0];
                  //     end.y = storeyObject.height;
                  //     end.z = points2[1][1];
                  //   }
                  // } else {
                    if (Math.round(slop1 * 1000) === 0) {
                      end = new BABYLON.Vector3(
                        Math.round(points1[1][0]),
                        storeyObject.height,
                        points1[1][1]
                      );
                      st = new BABYLON.Vector3(
                        Math.round(points2[1][0]),
                        storeyObject.height,
                        points2[1][1]
                      );
                    } else if (slop1 === "inf" || Math.abs(slop1) > 1e5) {
                      end = new BABYLON.Vector3(
                        points1[0][0],
                        storeyObject.height,
                        Math.round(points1[0][1])
                      );
                      st = new BABYLON.Vector3(
                        points2[0][0],
                        storeyObject.height,
                        Math.round(points2[0][1])
                      );
                    } else {
                      if (dir) {
                        end = new BABYLON.Vector3(
                          points1[0][0],
                          storeyObject.height,
                          points1[0][1]
                        );
                        st = new BABYLON.Vector3(
                          points2[0][0],
                          storeyObject.height,
                          points2[0][1]
                        );
                      } else {
                        st = new BABYLON.Vector3(
                          points1[0][0],
                          storeyObject.height,
                          points1[0][1]
                        );
                        end = new BABYLON.Vector3(
                          points2[0][0],
                          storeyObject.height,
                          points2[0][1]
                        );
                      }
                    }
                  // }                  
                }                

                let dimMesh = BABYLON.Mesh.CreateLines(
                  "autoLineMesh",
                  [st, end],
                  store.scene
                );
                dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
                dimMesh.intersectionThreshold = 100;
                let distBox = _createInput("autoDim" + dimMesh.uniqueId);
                let crsPts1 = lineWithAngleAndAtDistanceFromPoint(
                  st.asArray(),
                  80,
                  2
                );
                let crsPts2 = lineWithAngleAndAtDistanceFromPoint(
                  end.asArray(),
                  80,
                  2
                );
                let crsPt1st = new BABYLON.Vector3(
                  crsPts1[0][0],
                  storeyObject.height,
                  st.z
                );
                let crsPt1end = new BABYLON.Vector3(
                  crsPts1[1][0],
                  storeyObject.height,
                  st.z
                );
                let crsPt2st = new BABYLON.Vector3(
                  crsPts2[0][0],
                  storeyObject.height,
                  end.z
                );
                let crsPt2end = new BABYLON.Vector3(
                  crsPts2[1][0],
                  storeyObject.height,
                  end.z
                );
                let crosDimMesh1 = new BABYLON.Mesh.CreateLines(
                  "autoLineMesh",
                  [crsPt1st, crsPt1end],
                  store.scene
                );
                let crosDimMesh2 = new BABYLON.Mesh.CreateLines(
                  "autoLineMesh",
                  [crsPt2st, crsPt2end],
                  store.scene
                );

                crosDimMesh1.setPivotPoint(st);
                crosDimMesh2.setPivotPoint(end);
                crosDimMesh1.rotation.y += Math.PI / 4;
                crosDimMesh2.rotation.y += Math.PI / 4;

                // if ((slop1 === "inf") || (Math.abs(slop1) > 1e5)) {
                //     crosDimMesh1.rotation.y += Math.PI / 2;
                //     crosDimMesh2.rotation.y += Math.PI / 2;
                // }
                // else if (Math.round(slop1*10000) === 0) {
                //     // crosDimMesh1.rotation.y += Math.PI / 2;
                //     // crosDimMesh2.rotation.y += Math.PI / 2;
                // }
                // else {
                //     let angle1 = Math.atan2(-end.y + st.y, end.x - st.x);
                //     if (angle1 >= Math.PI / 2 && angle1 < Math.PI) angle1 += Math.PI;
                //     else if (angle1 > -Math.PI && angle1 <= -Math.PI / 2) angle1 += Math.PI;
                //     distBox.rotation = angle1;
                //     distBox.linkOffsetYInPixels = -10;
                //     crosDimMesh1.rotation.y = angle1 + Math.PI / 2;
                //     crosDimMesh2.rotation.y = angle1 + Math.PI / 2;
                // }

                distBox.text = BABYLON.Vector3.Distance(st, end).toString();
                distBox.linkOffsetYInPixels = -10;
                let angle1 = Math.atan2(-end.z + st.z, end.x - st.x);
                if (!(angle1 - 0)<1e-2 && !(Math.abs(Math.abs(angle1)-Math.abs(Math.PI)) < 1e-2)){
                  distBox.linkOffsetXInPixels = -10;
                  distBox.rotation = -Math.abs(angle1);
                }

                distBox.linkWithMesh(dimMesh);
                distBox.isEnabled = false;

                distBox.thickness = 0;

                dimMesh.isPickable = true;
                dimMesh.color = new BABYLON.Color3(0.15, 0.15, 0.15);
                dimMesh.renderingGroupId = 1;

                crosDimMesh1.isPickable = false;
                crosDimMesh1.color = new BABYLON.Color3(0.15, 0.15, 0.15);
                crosDimMesh1.renderingGroupId = 1;

                crosDimMesh2.isPickable = false;
                crosDimMesh2.color = new BABYLON.Color3(0.15, 0.15, 0.15);
                crosDimMesh2.renderingGroupId = 1;

                distBox.text = DisplayOperation.convertToDefaultDimension(
                  distBox.text
                );

                if (distBox.text === "0.00") {
                  dimMesh.dispose();
                  crosDimMesh1.dispose();
                  crosDimMesh2.dispose();
                  distBox.dispose();
                }
                _lines.push(dimMesh);
                if (distBoxesAndDimesh.length > 0) {
                  if (distBoxesAndDimesh.includes(distBox.text)) {
                    dimMesh.dispose();
                    crosDimMesh1.dispose();
                    crosDimMesh2.dispose();
                    distBox.dispose();
                  } else {
                    distBoxesAndDimesh.push(distBox.text);
                  }
                } else {
                  distBoxesAndDimesh.push(distBox.text);
                }
                // _lines.push(dimMesh2);
                // _lines.push(dimMesh3);
                _lines.push(crosDimMesh1);
                _lines.push(crosDimMesh2);
              }
            }
          }
        }
      }
      /* eslint-enable */
      if (
        mesh.type.toLowerCase() === "wall" &&
        edgePoints &&
        edgePoints.length === 4
      ) {        
        // return;
        let distBoxesAndDimesh = [];

        let pointSet = mesh.edgeMidPts();
        let st = pointSet[0];
        let end = pointSet[1];
        let storeyObject = getActiveStoreyObject(store.activeLayer.storey);
        let perpendicularPoints1 = perpendicularLineToEdge(st, st, end);
        let perpendicularPoints2 = perpendicularLineToEdge(
          end,
          st,
          end
        );
        let dir = true;

        let edgePointsFloor;
        if (floor) {
          edgePointsFloor = floor[0].getBoundaryCoords();
          edgePointsFloor = edgePointsFloor.map((e) => {
            return [e.x, e.z];
          });
        }

        let offsetDim = 2.5;

        let slop1 = slopeOfLine([
          perpendicularPoints1[0].asArray(),
          perpendicularPoints1[1].asArray(),
        ]);
        let slop2 = slopeOfLine([
          perpendicularPoints2[0].asArray(),
          perpendicularPoints2[1].asArray(),
        ]);
        let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
        let slope2 = -1 / slop2 === "inf" ? 0 : slop2;
        // if (slop1 === "inf") slope1 = 0;
        // if (slop2 === "inf") slope2 = 0;
        if (Math.abs(slope1) > 1e5) slope1 = "inf";
        if (Math.abs(slope2) > 1e5) slope2 = "inf";
        let intWall = mesh.room_id.length > 3? true: false;

        let points1 = lineWithSlopeAurAtDistanceFromPoint(
          [st.x, st.z],
          slope1.toString().includes("Inf") ? "inf" : slope1,
          offsetDim
        );
        let points2 = lineWithSlopeAurAtDistanceFromPoint(
          [end.x, end.z],
          slope2.toString().includes("Inf") ? "inf" : slope2,
          offsetDim
        );

        if ((Math.round(slope1,4) === 0) && !intWall){
          if (edgePointsFloor){
            let mP0 = [
              (points1[0][0] + points2[0][0]) / 2,
              (points1[0][1] + points2[0][1]) / 2,
            ];
            if (
              !isInsidePoly1(mP0, edgePointsFloor) 
            ) {
              if (Math.abs(mP0[0]-boundsXMin) < Math.abs(st.x-boundsXMin)){
                st.x = boundsXMin;
                st.y = storeyObject.height;
                end.x = boundsXMin;
                end.y = storeyObject.height;
              }
              else{
                st.x = boundsXMax;                    
                st.y = storeyObject.height;
                end.x = boundsXMax;                    
                end.y = storeyObject.height;
              }  
            }
            else{
              if (Math.abs(mP0[0]-boundsXMin) > Math.abs(st.x-boundsXMin)){
                st.x = boundsXMin;
                st.y = storeyObject.height;
                end.x = boundsXMin;
                end.y = storeyObject.height;
              }
              else{
                st.x = boundsXMax;                    
                st.y = storeyObject.height;
                end.x = boundsXMax;                    
                end.y = storeyObject.height;
              }  
            }
          }
          else{
            if (Math.abs(st.x-boundsXMin) < Math.abs(st.x-boundsXMax)){
              st.x = boundsXMin;
              st.y = storeyObject.height;
              end.x = boundsXMin;
              end.y = storeyObject.height;
            }
            else{
              st.x = boundsXMax;                    
              st.y = storeyObject.height;
              end.x = boundsXMax;                    
              end.y = storeyObject.height;
            }  
          }
        }
        else if ((slope1 === "inf") && !intWall){
          if (edgePointsFloor){
            let mP0 = [
              (points1[0][0] + points2[0][0]) / 2,
              (points1[0][1] + points2[0][1]) / 2,
            ];
            if (
              !isInsidePoly1(mP0, edgePointsFloor) 
            ) {
              if (Math.abs(mP0[1]-boundsZMin) < Math.abs(st.z-boundsZMin)){
                st.z = boundsZMin;
                st.y = storeyObject.height;
                end.z = boundsZMin;
                end.y = storeyObject.height;
              }
              else{
                st.z = boundsZMax;                    
                st.y = storeyObject.height;
                end.z = boundsZMax;                    
                end.y = storeyObject.height;
              }  
            }
            else{
              if (Math.abs(mP0[1]-boundsZMin) > Math.abs(st.z-boundsZMin)){
                st.z = boundsZMin;
                st.y = storeyObject.height;
                end.z = boundsZMin;
                end.y = storeyObject.height;
              }
              else{
                st.z = boundsZMax;                    
                st.y = storeyObject.height;
                end.z = boundsZMax;                    
                end.y = storeyObject.height;
              }  
            }
          }
          else{
            if (Math.abs(st.z-boundsZMin) < Math.abs(st.z-boundsZMax)){
              st.z = boundsZMin;
              st.y = storeyObject.height;
              end.z = boundsZMin;
              end.y = storeyObject.height;
            }
            else{
              st.z = boundsZMax;                    
              st.y = storeyObject.height;
              end.z = boundsZMax;                    
              end.y = storeyObject.height;
            }
  
          }
        }
        else if (intWall){
          st = new BABYLON.Vector3(0, storeyObject.height, 0);
          end = new BABYLON.Vector3(0, storeyObject.height, 0);

          // if (edgePointsFloor) {
          //   let mP0 = [
          //     (points1[0][0] + points2[0][0]) / 2,
          //     (points1[0][1] + points2[0][1]) / 2,
          //   ];
          //   // let mP1 = [(points1[1][0]+points2[1][0])/2, (points1[1][1]+points2[1][1])/2];
          //   if (
          //     !isInsidePoly1(points1[0], edgePointsFloor) &&
          //     !isInsidePoly1(points2[0], edgePointsFloor)
          //   ) {
          //     st.x = points1[0][0];
          //     st.y = storeyObject.height;
          //     st.z = points1[0][1];
          //     end.x = points2[0][0];
          //     end.y = storeyObject.height;
          //     end.z = points2[0][1];
          //   } else {
          //     st.x = points1[1][0];
          //     st.y = storeyObject.height;
          //     st.z = points1[1][1];
          //     end.x = points2[1][0];
          //     end.y = storeyObject.height;
          //     end.z = points2[1][1];
          //   }
          // } else {
            if (Math.round(slop1 * 1000) === 0) {
              end = new BABYLON.Vector3(
                Math.round(points1[1][0]),
                storeyObject.height,
                points1[1][1]
              );
              st = new BABYLON.Vector3(
                Math.round(points2[1][0]),
                storeyObject.height,
                points2[1][1]
              );
            } else if (slop1 === "inf" || Math.abs(slop1) > 1e5) {
              end = new BABYLON.Vector3(
                points1[0][0],
                storeyObject.height,
                Math.round(points1[0][1])
              );
              st = new BABYLON.Vector3(
                points2[0][0],
                storeyObject.height,
                Math.round(points2[0][1])
              );
            } else {
              if (dir) {
                end = new BABYLON.Vector3(
                  points1[0][0],
                  storeyObject.height,
                  points1[0][1]
                );
                st = new BABYLON.Vector3(
                  points2[0][0],
                  storeyObject.height,
                  points2[0][1]
                );
              } else {
                st = new BABYLON.Vector3(
                  points1[0][0],
                  storeyObject.height,
                  points1[0][1]
                );
                end = new BABYLON.Vector3(
                  points2[0][0],
                  storeyObject.height,
                  points2[0][1]
                );
              }
            }
          // }                  
        }

        let dimMesh = BABYLON.Mesh.CreateLines(
          "autoLineMesh",
          [st, end],
          store.scene
        );
        dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
        dimMesh.intersectionThreshold = 100;
        let distBox = _createInput("autoDim" + dimMesh.uniqueId);
        let crsPts1 = lineWithAngleAndAtDistanceFromPoint(
          st.asArray(),
          80,
          2
        );
        let crsPts2 = lineWithAngleAndAtDistanceFromPoint(
          end.asArray(),
          80,
          2
        );
        let crsPt1st = new BABYLON.Vector3(
          crsPts1[0][0],
          storeyObject.height,
          st.z
        );
        let crsPt1end = new BABYLON.Vector3(
          crsPts1[1][0],
          storeyObject.height,
          st.z
        );
        let crsPt2st = new BABYLON.Vector3(
          crsPts2[0][0],
          storeyObject.height,
          end.z
        );
        let crsPt2end = new BABYLON.Vector3(
          crsPts2[1][0],
          storeyObject.height,
          end.z
        );
        let crosDimMesh1 = new BABYLON.Mesh.CreateLines(
          "autoLineMesh",
          [crsPt1st, crsPt1end],
          store.scene
        );
        let crosDimMesh2 = new BABYLON.Mesh.CreateLines(
          "autoLineMesh",
          [crsPt2st, crsPt2end],
          store.scene
        );

        crosDimMesh1.setPivotPoint(st);
        crosDimMesh2.setPivotPoint(end);
        crosDimMesh1.rotation.y += Math.PI / 4;
        crosDimMesh2.rotation.y += Math.PI / 4;
        distBox.text = BABYLON.Vector3.Distance(st, end).toString();
        distBox.linkOffsetYInPixels = -10;
        let angle1 = Math.atan2(-end.z + st.z, end.x - st.x);
        if (!(angle1 - 0)<1e-2 && !(Math.abs(Math.abs(angle1)-Math.abs(Math.PI)) < 1e-2)){
          distBox.linkOffsetXInPixels = -10;
          distBox.rotation = -Math.abs(angle1);
        }

        distBox.linkWithMesh(dimMesh);
        distBox.isEnabled = false;

        distBox.thickness = 0;

        dimMesh.isPickable = true;
        dimMesh.color = new BABYLON.Color3(0.15, 0.15, 0.15);
        dimMesh.renderingGroupId = 1;

        crosDimMesh1.isPickable = false;
        crosDimMesh1.color = new BABYLON.Color3(0.15, 0.15, 0.15);
        crosDimMesh1.renderingGroupId = 1;

        crosDimMesh2.isPickable = false;
        crosDimMesh2.color = new BABYLON.Color3(0.15, 0.15, 0.15);
        crosDimMesh2.renderingGroupId = 1;

        distBox.text = DisplayOperation.convertToDefaultDimension(
          distBox.text
        );

        if (distBox.text === "0.00") {
          dimMesh.dispose();
          crosDimMesh1.dispose();
          crosDimMesh2.dispose();
          distBox.dispose();
        }
        _lines.push(dimMesh);
        if (distBoxesAndDimesh.length > 0) {
          if (distBoxesAndDimesh.includes(distBox.text)) {
            dimMesh.dispose();
            crosDimMesh1.dispose();
            crosDimMesh2.dispose();
            distBox.dispose();
          } else {
            distBoxesAndDimesh.push(distBox.text);
          }
        } else {
          distBoxesAndDimesh.push(distBox.text);
        }
        // _lines.push(dimMesh2);
        // _lines.push(dimMesh3);
        _lines.push(crosDimMesh1);
        _lines.push(crosDimMesh2);
        
        // let distBoxesAndDimesh = [];
        // let majorEdges = [];
        // let minorEdges = [];
        // let majorEdgesPerpendicular = [];
        // let minorEdgesPerpendicular = [];
        // let wallEdges = [];
        // let lengths = [];
        // var perpendiculars = [];
        // for (let i=0; i<edgePoints.length; i++) {
        //     let pt1 = new BABYLON.Vector2(edgePoints[i].x, edgePoints[i].z);
        //     let pt2 = new BABYLON.Vector2(edgePoints[(i + 1) % edgePoints.length].x, edgePoints[(i + 1) % edgePoints.length].z);
        //     lengths.push(BABYLON.Vector2.Distance(pt1, pt2));
        // }
        // const sortedIndices = lengths.sortByIndices();
        // majorEdges.push([edgePoints[sortedIndices[2]], edgePoints[(sortedIndices[2]+1)%4]]);
        // majorEdges.push([edgePoints[sortedIndices[3]], edgePoints[(sortedIndices[3]+1)%4]]);
        // // minorEdges.push([edgePoints[sortedIndices[0]], edgePoints[(sortedIndices[0]+1)%4]]);
        // // minorEdges.push([edgePoints[sortedIndices[1]], edgePoints[(sortedIndices[2]+1)%4]]);
        // wallEdges.push([edgePoints[sortedIndices[2]], edgePoints[(sortedIndices[2]+1)%4]]);
        // wallEdges.push([edgePoints[sortedIndices[3]], edgePoints[(sortedIndices[3]+1)%4]]);
        // wallEdges.push([edgePoints[sortedIndices[0]], edgePoints[(sortedIndices[0]+1)%4]]);
        // wallEdges.push([edgePoints[sortedIndices[1]], edgePoints[(sortedIndices[1]+1)%4]]);
        //
        // for (let i=0; i<edgePoints.length/2; i++){
        //     let pt1 = new BABYLON.Vector2(edgePoints[i].x, edgePoints[i].z);
        //     let pt2 = new BABYLON.Vector2(edgePoints[(i + 1) % edgePoints.length].x, edgePoints[(i + 1) % edgePoints.length].z);
        //     let pt3 = new BABYLON.Vector2(edgePoints[(i + 2) % edgePoints.length].x, edgePoints[(i + 2) % edgePoints.length].z);
        //     let perpendicularPoints1 = perpendicularLineToEdge(pt1, pt1, pt2);
        //     let perpendicularPoints2 = perpendicularLineToEdge(pt2, pt1, pt2);
        //     let perpendicularPoints3 = perpendicularLineToEdge(pt2, pt2, pt3);
        //     let perpendicularPoints4 = perpendicularLineToEdge(pt3, pt2, pt3);
        //
        //     let slop1 = slopeOfLine([perpendicularPoints1[0].asArray(), perpendicularPoints1[1].asArray()]);
        //     let slop2 = slopeOfLine([perpendicularPoints2[0].asArray(), perpendicularPoints2[1].asArray()]);
        //     let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
        //     let slope2 = -1 / slop2 === "inf" ? 0 : slop2;
        //     if (Math.abs(slope1) > 1e7) slope1 = "inf";
        //     if (Math.abs(slope2) > 1e7) slope2 = "inf";
        //     let points1 = lineWithSlopeAurAtDistanceFromPoint(pt1.asArray(), slope1.toString().includes("Inf") ? "inf" : slope1, 0.7);
        //     let points2 = lineWithSlopeAurAtDistanceFromPoint(pt2.asArray(), slope2.toString().includes("Inf") ? "inf" : slope2, 0.7);
        //
        //
        //     if (i==sortedIndices[0] || i==sortedIndices[1]){
        //         //project pt1 on perpindicularpoints3 line
        //         let b = [pt1].map(pt11=>[pt11.x, pt11.y])[0];
        //         let a = perpendicularPoints3.map(pp3=>[pp3.x, pp3.y]);
        //         let c = projectionOfPointOnLine2D(b, a[0], a[1]);
        //         pt1.x = c[0];
        //         pt1.y = c[1];
        //
        //         slop1 = slopeOfLine([pt2.asArray(), pt3.asArray()]);
        //         slop2 = slopeOfLine([pt2.asArray(), pt3.asArray()]);
        //         slope1 = -1 / slop1 === "inf" ? 0 : slop1;
        //         slope2 = -1 / slop2 === "inf" ? 0 : slop2;
        //         points1 = lineWithSlopeAurAtDistanceFromPoint(pt1.asArray(), slope1.toString().includes("Inf") ? "inf" : slope1, 0.7);
        //         points2 = lineWithSlopeAurAtDistanceFromPoint(pt2.asArray(), slope2.toString().includes("Inf") ? "inf" : slope2, 0.7);
        //     }
        //
        //     let storeyObject = getActiveStoreyObject(activeLayer.storey);
        //     let st = null;
        //     let end = null;
        //     let dimMesh = null;
        //     let distBox = null;
        //     if (Math.round(slop1*10000) === 0) {
        //         st = new BABYLON.Vector3(points1[1][0], storeyObject.height, points1[1][1]);
        //         end = new BABYLON.Vector3(points2[1][0], storeyObject.height, points2[1][1]);
        //         dimMesh = BABYLON.Mesh.CreateLines("autoLineMesh", [st, end], store.scene);
        //         dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
        //         dimMesh.intersectionThreshold = 100;
        //         distBox = _createInput("autoDim"+dimMesh.uniqueId);
        //         distBox.rotation = -Math.PI / 2;
        //         distBox.linkOffsetXInPixels = -10;
        //     }
        //     else if (slop1 === "inf") {
        //         st = new BABYLON.Vector3(points1[0][0], storeyObject.height, points1[0][1]);
        //         end = new BABYLON.Vector3(points2[0][0], storeyObject.height, points2[0][1]);
        //
        //         dimMesh = BABYLON.Mesh.CreateLines("autoLineMesh", [st, end], store.scene);
        //         dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
        //         dimMesh.intersectionThreshold = 100;
        //         distBox = _createInput("autoDim"+dimMesh.uniqueId);
        //         // distBox.rotation = 0;
        //         //distBox.linkOffsetYInPixels = -10;a
        //     }
        //     else {
        //         end = new BABYLON.Vector3(points1[0][0], storeyObject.height, points1[0][1]);
        //         st = new BABYLON.Vector3(points2[0][0], storeyObject.height, points2[0][1]);
        //
        //         dimMesh = BABYLON.Mesh.CreateLines("autoLineMesh", [st, end], store.scene);
        //         dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
        //         dimMesh.intersectionThreshold = 100;
        //         distBox = _createInput("autoDim"+dimMesh.uniqueId);
        //     }
        //
        //     let crsPts1 = lineWithAngleAndAtDistanceFromPoint(st.asArray(), 80, 2);
        //     let crsPts2 = lineWithAngleAndAtDistanceFromPoint(end.asArray(), 80, 2);
        //     let crsPt1st = new BABYLON.Vector3(crsPts1[0][0], storeyObject.height, st.z);
        //     let crsPt1end = new BABYLON.Vector3(crsPts1[1][0], storeyObject.height, st.z);
        //     let crsPt2st = new BABYLON.Vector3(crsPts2[0][0], storeyObject.height, end.z);
        //     let crsPt2end = new BABYLON.Vector3(crsPts2[1][0], storeyObject.height, end.z);
        //     let crosDimMesh1 = new BABYLON.Mesh.CreateLines("autoLineMesh", [crsPt1st, crsPt1end], store.scene);
        //     let crosDimMesh2 = new BABYLON.Mesh.CreateLines("autoLineMesh", [crsPt2st, crsPt2end], store.scene);
        //
        //     crosDimMesh1.setPivotPoint(st);
        //     crosDimMesh2.setPivotPoint(end);
        //     if (slop1 == "inf") {
        //
        //         crosDimMesh1.rotation.y += Math.PI / 2;
        //         crosDimMesh2.rotation.y += Math.PI / 2;
        //     }
        //     else if (slop1 === 0) {
        //
        //         // crosDimMesh1.rotation.y += Math.PI / 2;
        //         // crosDimMesh2.rotation.y += Math.PI / 2;
        //     }
        //     else {
        //         let angle1 = Math.atan2(-pt2.y + pt1.y, pt2.x - pt1.x);
        //         //console.log('ANGLEEEEEEEE', angle1 * 180 / Math.PI, 'DISTVALUE', BABYLON.Vector3.Distance(st, end).toFixed(2).toString());
        //         if (angle1 >= Math.PI / 2 && angle1 < Math.PI) angle1 += Math.PI;
        //         else if (angle1 > -Math.PI && angle1 <= -Math.PI / 2) angle1 += Math.PI;
        //         //console.log(angle1);
        //         // if (angle1 < 0) angle1 += 2 * Math.PI;
        //         distBox.rotation = angle1;
        //         distBox.linkOffsetYInPixels = -10;
        //         crosDimMesh1.rotation.y = angle1 + Math.PI / 2;
        //         crosDimMesh2.rotation.y = angle1 + Math.PI / 2;
        //
        //
        //     }
        //
        //     distBox.text = BABYLON.Vector3.Distance(st, end).toString();
        //
        //     distBox.linkWithMesh(dimMesh);
        //     distBox.isEnabled = false;
        //
        //     distBox.thickness = 0;
        //
        //
        //     //
        //     // let dimMesh2 = BABYLON.Mesh.CreateLines("autoLineMesh", [new BABYLON.Vector3(points1[0][0], storeyObject.height, points1[0][1]), new BABYLON.Vector3(points1[1][0], storeyObject.height, points1[1][1])], store.scene);
        //     // let dimMesh3 = BABYLON.Mesh.CreateLines("autoLineMesh", [new BABYLON.Vector3(points2[0][0], storeyObject.height, points2[0][1]), new BABYLON.Vector3(points2[1][0], storeyObject.height, points2[1][1])], store.scene);
        //
        //     dimMesh.isPickable = true;
        //     dimMesh.color = new BABYLON.Color3(0.150, 0.150, 0.150);
        //     dimMesh.renderingGroupId = 1;
        //     // dimMesh2.isPickable = false;
        //     // dimMesh2.color = new BABYLON.Color3(0.150, 0.150, 0.150);
        //     // dimMesh2.renderingGroupId = 1;
        //     // dimMesh3.isPickable = true;
        //     // dimMesh3.color = new BABYLON.Color3(0.150, 0.150, 0.150);
        //     // dimMesh3.renderingGroupId = 1;
        //
        //     crosDimMesh1.isPickable = false;
        //     crosDimMesh1.color = new BABYLON.Color3(0.150, 0.150, 0.150);
        //     crosDimMesh1.renderingGroupId = 1;
        //
        //     crosDimMesh2.isPickable = false;
        //     crosDimMesh2.color = new BABYLON.Color3(0.150, 0.150, 0.150);
        //     crosDimMesh2.renderingGroupId = 1;
        //
        //     if (distBox.text === '0.00') {
        //         dimMesh.dispose();
        //         crosDimMesh1.dispose();
        //         crosDimMesh2.dispose();
        //         distBox.dispose();
        //
        //     }
        //     _lines.push(dimMesh);
        //     if (distBoxesAndDimesh.length > 0) {
        //         if (distBoxesAndDimesh.includes(distBox.text)) {
        //             dimMesh.dispose();
        //             crosDimMesh1.dispose();
        //             crosDimMesh2.dispose();
        //             distBox.dispose();
        //         } else {
        //             distBoxesAndDimesh.push(distBox.text);
        //         }
        //     } else {
        //         distBoxesAndDimesh.push(distBox.text);
        //     }
        //     distBox.text = DisplayOperation.convertToDefaultDimension(distBox.text);
        //     // _lines.push(dimMesh2);
        //     // _lines.push(dimMesh3);
        //     _lines.push(crosDimMesh1);
        //     _lines.push(crosDimMesh2);
        //
        //
        // }
      } else if (edgePoints) {
        return;
        let distBoxesAndDimesh = [];
        let iterLength = edgePoints.length;
        // let dimOffsetVar = 1.3;
        let edgePointsOffTemp = [];
        let edgePointsOff = [];
        let storeyObject = getActiveStoreyObject(store.activeLayer.storey);
        edgePoints.forEach((e) => {
          edgePointsOffTemp.push([e.x, e.z]);
        });
        edgePointsOffTemp = offsetRoomPols(edgePointsOffTemp, -dimOffsetVar);
        edgePointsOffTemp.forEach((e) => {
          edgePointsOff.push(
            new BABYLON.Vector3(e[0], storeyObject.height, e[1])
          );
        });
        if (edgePoints.length === 4) {
          if (
            Math.abs(
              BABYLON.Vector3.Distance(edgePoints[0], edgePoints[1]) -
                BABYLON.Vector3.Distance(edgePoints[2], edgePoints[3])
            ) < 1e-3
          ) {
            iterLength = 2;
          }
        }

        for (let i = 0; i < iterLength; i++) {
          console.log("itervalue", i);
          let pt1 = new BABYLON.Vector2(edgePoints[i].x, edgePoints[i].z);
          let pt2 = new BABYLON.Vector2(
            edgePoints[(i + 1) % edgePoints.length].x,
            edgePoints[(i + 1) % edgePoints.length].z
          );
          let perpendicularPoints1 = perpendicularLineToEdge(pt1, pt1, pt2);
          let perpendicularPoints2 = perpendicularLineToEdge(pt2, pt1, pt2);

          let slop1 = slopeOfLine([
            perpendicularPoints1[0].asArray(),
            perpendicularPoints1[1].asArray(),
          ]);
          let slop2 = slopeOfLine([
            perpendicularPoints2[0].asArray(),
            perpendicularPoints2[1].asArray(),
          ]);
          let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
          let slope2 = -1 / slop2 === "inf" ? 0 : slop2;
          if (Math.abs(slope1) > 1e7) slope1 = "inf";
          if (Math.abs(slope2) > 1e7) slope2 = "inf";

          let points1 = lineWithSlopeAurAtDistanceFromPoint(
            pt1.asArray(),
            slope1.toString().includes("Inf") ? "inf" : slope1,
            dimOffsetVar
          );
          let points2 = lineWithSlopeAurAtDistanceFromPoint(
            pt2.asArray(),
            slope2.toString().includes("Inf") ? "inf" : slope2,
            dimOffsetVar
          );

          let st = new BABYLON.Vector3(0, storeyObject.height, 0);
          let end = new BABYLON.Vector3(0, storeyObject.height, 0);

          let edgePointsArr = edgePoints.map((e) => {
            return [e.x, e.z];
          });
          let mP0 = [
            (points1[0][0] + points2[0][0]) / 2,
            (points1[0][1] + points2[0][1]) / 2,
          ];
          // let mP1 = [(points1[1][0]+points2[1][0])/2, (points1[1][1]+points2[1][1])/2];
          if (isInsidePoly1(mP0, edgePointsArr)) {
            st.x = points1[0][0];
            st.z = points1[0][1];
            end.x = points2[0][0];
            end.z = points2[0][1];
          } else {
            st.x = points1[1][0];
            st.z = points1[1][1];
            end.x = points2[1][0];
            end.z = points2[1][1];
          }

          let dimMesh = BABYLON.Mesh.CreateLines(
            "autoLineMesh",
            [st, end],
            store.scene
          );
          dimMesh.geometry.boundingBias = new BABYLON.Vector2(0, 100);
          dimMesh.intersectionThreshold = 100;
          let distBox = _createInput("autoDim" + dimMesh.uniqueId);

          let crsPts1 = lineWithAngleAndAtDistanceFromPoint(
            st.asArray(),
            80,
            2
          );
          let crsPts2 = lineWithAngleAndAtDistanceFromPoint(
            end.asArray(),
            80,
            2
          );
          let crsPt1st = new BABYLON.Vector3(
            crsPts1[0][0],
            storeyObject.height,
            st.z
          );
          let crsPt1end = new BABYLON.Vector3(
            crsPts1[1][0],
            storeyObject.height,
            st.z
          );
          let crsPt2st = new BABYLON.Vector3(
            crsPts2[0][0],
            storeyObject.height,
            end.z
          );
          let crsPt2end = new BABYLON.Vector3(
            crsPts2[1][0],
            storeyObject.height,
            end.z
          );
          let crosDimMesh1 = new BABYLON.Mesh.CreateLines(
            "autoLineMesh",
            [crsPt1st, crsPt1end],
            store.scene
          );
          let crosDimMesh2 = new BABYLON.Mesh.CreateLines(
            "autoLineMesh",
            [crsPt2st, crsPt2end],
            store.scene
          );

          crosDimMesh1.setPivotPoint(st);
          crosDimMesh2.setPivotPoint(end);
          // if (slop1 == "inf") {

          crosDimMesh1.rotation.y += Math.PI / 4;
          crosDimMesh2.rotation.y += Math.PI / 4;
          // }
          // else if (Math.round(slop1*10000) === 0) {

          //     // crosDimMesh1.rotation.y += Math.PI / 2;
          //     // crosDimMesh2.rotation.y += Math.PI / 2;
          // }
          // else {
          //     let angle1 = Math.atan2(-pt2.y + pt1.y, pt2.x - pt1.x);
          //     //console.log('ANGLEEEEEEEE', angle1 * 180 / Math.PI, 'DISTVALUE', BABYLON.Vector3.Distance(st, end).toFixed(2).toString());
          //     if (angle1 >= Math.PI / 2 && angle1 < Math.PI) angle1 += Math.PI;
          //     else if (angle1 > -Math.PI && angle1 <= -Math.PI / 2) angle1 += Math.PI;
          //     //console.log(angle1);
          //     // if (angle1 < 0) angle1 += 2 * Math.PI;
          //     distBox.linkOffsetYInPixels = -10;
          //     crosDimMesh1.rotation.y = angle1 + Math.PI / 2;
          //     crosDimMesh2.rotation.y = angle1 + Math.PI / 2;

          // }

          distBox.text = BABYLON.Vector3.Distance(st, end).toString();
          distBox.linkOffsetYInPixels = -10;
          let angle1 = Math.atan2(-end.z + st.z, end.x - st.x);
          if (!(angle1 - 0)<1e-2 && !(Math.abs(Math.abs(angle1)-Math.abs(Math.PI)) < 1e-2)){
            distBox.linkOffsetXInPixels = -4;
            distBox.rotation = -Math.abs(angle1);
          }

          distBox.linkWithMesh(dimMesh);
          distBox.isEnabled = false;

          distBox.thickness = 0;

          //
          // let dimMesh2 = BABYLON.Mesh.CreateLines("autoLineMesh", [new BABYLON.Vector3(points1[0][0], storeyObject.height, points1[0][1]), new BABYLON.Vector3(points1[1][0], storeyObject.height, points1[1][1])], store.scene);
          // let dimMesh3 = BABYLON.Mesh.CreateLines("autoLineMesh", [new BABYLON.Vector3(points2[0][0], storeyObject.height, points2[0][1]), new BABYLON.Vector3(points2[1][0], storeyObject.height, points2[1][1])], store.scene);

          dimMesh.isPickable = true;
          dimMesh.color = new BABYLON.Color3(0.15, 0.15, 0.15);
          dimMesh.renderingGroupId = 1;
          // dimMesh2.isPickable = false;
          // dimMesh2.color = new BABYLON.Color3(0.150, 0.150, 0.150);
          // dimMesh2.renderingGroupId = 1;
          // dimMesh3.isPickable = true;
          // dimMesh3.color = new BABYLON.Color3(0.150, 0.150, 0.150);
          // dimMesh3.renderingGroupId = 1;

          crosDimMesh1.isPickable = false;
          crosDimMesh1.color = new BABYLON.Color3(0.15, 0.15, 0.15);
          crosDimMesh1.renderingGroupId = 1;

          crosDimMesh2.isPickable = false;
          crosDimMesh2.color = new BABYLON.Color3(0.15, 0.15, 0.15);
          crosDimMesh2.renderingGroupId = 1;
          distBox.text = DisplayOperation.convertToDefaultDimension(
            distBox.text
          );

          if (distBox.text === "0.00") {
            dimMesh.dispose();
            crosDimMesh1.dispose();
            crosDimMesh2.dispose();
            distBox.dispose();
          }
          _lines.push(dimMesh);
          if (distBoxesAndDimesh.length > 0) {
            if (distBoxesAndDimesh.includes(distBox.text)) {
              dimMesh.dispose();
              crosDimMesh1.dispose();
              crosDimMesh2.dispose();
              distBox.dispose();
            } else {
              distBoxesAndDimesh.push(distBox.text);
            }
          } else {
            distBoxesAndDimesh.push(distBox.text);
          }
          // _lines.push(dimMesh2);
          // _lines.push(dimMesh3);
          _lines.push(crosDimMesh1);
          _lines.push(crosDimMesh2);
        }
      }
    });
  };

  const dimensionsForStorey = function displayDimensionForSelectedStorey() {
    if (_storeyAutoDimensionMap[store.activeLayer.storey] === undefined) {
      _storeyAutoDimensionMap[store.activeLayer.storey] = true;
      changeAutoDimButtonStatus(store.activeLayer.storey);
      // displayAutoDimensions();
      return;
    }

    if (_storeyAutoDimensionMap[store.activeLayer.storey]) {
      _storeyAutoDimensionMap[store.activeLayer.storey] = false;
      changeAutoDimButtonStatus(store.activeLayer.storey);
    } else {
      _storeyAutoDimensionMap[store.activeLayer.storey] = true;
      changeAutoDimButtonStatus(store.activeLayer.storey);
      // displayAutoDimensions();
    }
  };

  const changeAutoDimButtonStatus =
    function changeTheButtonColorToActiveOrInactiveBasedOnStorey(storey) {
      status = _storeyAutoDimensionMap[storey];
      if (status === undefined) {
        $("#displayAutoDimensions").css("border", "none");
        return;
      }
      if (status) {
        $("#displayAutoDimensions").css("border", "1px solid #d30041");
        displayAutoDimensions();
      } else {
        $("#displayAutoDimensions").css("border", "none");
        labelView.resetOperation();
      }
    };

  const displayLabel =
    function displayTheAutoCompleteInputAtTheCentreOfGivenMesh(
      mesh,
      facetId,
      hover,
      event
    ) {
      let meshDS = mesh.getSnaptrudeDS();
      if (hover) {
        if (meshDS.inputName) {
          if (mesh.uniqueId === _mesh.uniqueId) {
            return;
          } else {
            labelView.resetOperation(true);
          }
        }
      }
      _mesh = mesh;
      //_input = input;
      /* ANGULAR REFERENCE */
      // let scope = store.angular.element(appElement).scope();
      let input = null;

      if (meshDS.inputName) {
        let oldInput = store.advancedTexture._rootContainer.getChildByName(
          meshDS.inputName
        );
        if (oldInput) {
          oldInput.dispose();
          input = _createInput("room-label" + mesh.room_unique_id);
        } else {
          input = _createInput("room-label" + mesh.room_unique_id);
        }
      } else {
        input = _createInput("room-label" + mesh.room_unique_id);
      }
      let point = _getPoint(mesh, facetId);
      //input._host.focusedControl = input;
      if (mesh.room_type) {
        input.text = mesh.room_type;
        input.linkWithMesh(mesh);
        input.moveToVector3(point, store.newScene);
      }

      // console.warn("AG-RE: call _handleKeys.");
      _handleKeys(input, meshDS, store.$scope, mesh);
      // console.warn("AG-RE: call _handleInput.");
      _handleInput(input, store.$scope, event, mesh);
      meshDS.labelAdded = true;
      meshDS.inputName = input.name;
      if (!hover) {
        store.advancedTexture.moveFocusToControl(input);
      } else {
        input.editable = false;
        _hoverInputs.push(input);
      }
      input.isVisible = true;
    };

  var handlePaste = function (text) {
    //_input.text = 'pasted'
  };

  return {
    displayAutoDimensions,
    displayAutoDimensionsElev,
    _getEdgePoints,
    changeAutoDimButtonStatus,
    dimensionsForStorey,
    createInput: _createInput,
    lines: _lines,
    hideLines: _hideLines,
    showLines: _showLines,
    displayLabel: function (mesh, faceId, hover, event) {
      return displayLabel(mesh, faceId, hover, event);
    },
    status,
    resetOperation: function (hover) {
      return _resetOperation(hover);
    },
    getBottomEdgePoints: function (mesh) {
      return _getEdgePoints(mesh);
    },
    handlePaste,
  };
})();
export { labelView };
