import React, {useState, useEffect} from 'react';
import { SnaptrudeDropzone } from '../../../../components/SnaptrudeModal/SnaptrudeDropzone';
import SnaptrudeImportFunctionalityModal from '../../../../components/SnaptrudeModal/SnaptrudeImportFunctionalityModal';
import imageIcon from '../../../../assets/icons/image.svg';
import cubeIcon from "../../../../assets/icons/objectImage.svg";
import { uploadTeamMaterials, uploadTeamObjects } from '../../../../services/team.service';
import { FURNITURE_TYPE_OPTIONS, MATERIAL_TYPE_OPTIONS } from './constants';
import { FakeProgressEvent } from '../../../../snaptrude/modules/FakeProgressEvent/FakeProgressEvent';


function Modal({modalFor, isOpen, onClose, selectedTeam}) {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressText, setProgressText] = useState("Uploading");
    const [progress, setProgress] = useState(0);
    
    const [materialType, setMaterialType] = useState(MATERIAL_TYPE_OPTIONS[0]);
    const [furnitureType, setFurnitureType] = useState(FURNITURE_TYPE_OPTIONS[0]);
    
    const handleCancelFile = (index) => {
        let newUploadedFiles = uploadedFile.slice();
        newUploadedFiles.splice(index, 1);
        setUploadedFile(newUploadedFiles);
    };

    useEffect(() => {
        const handleProgressChagne = (evt) => {
          if(evt.detail){
            setProgress(evt.detail.progress);
            setProgressText(evt.detail.text);
          }
        }
        window.addEventListener("team-materials-progress", handleProgressChagne);
        window.addEventListener("team-furnitures-progress", handleProgressChagne);
        return () => {
          window.removeEventListener("team-materials-progress", handleProgressChagne);
          window.removeEventListener("team-furnitures-progress", handleProgressChagne);
          
        }
      }, [])

    if(modalFor === "materials"){
        return (
            <SnaptrudeImportFunctionalityModal
                isOpen={isOpen}
                onClose={() => {
                    if(showProgressBar) setShowProgressBar(false);
                    onClose()
                }}
                onUpload={() => {
                    setShowProgressBar(true);
                    // setProgress(40);
                    const uploadedFiles = uploadedFile.map((uf) => uf.file);
                    uploadTeamMaterials(selectedTeam.id, uploadedFiles, materialType).then(
                    (resp) => {
                        if(resp.status === "success"){
                            setProgress(100);
                        }
                        setShowProgressBar(false);
                        setUploadedFile(null);
                        onClose();
                    }
                    );
                }}
                handleCancelFile={handleCancelFile}
                modalHeaderText={"Material"}
                bodyHeaderText={
                    "Select and upload material images. You can upload upto 10 files"
                }
                allowedTypes={[
                    {
                    name: ".jpg",
                    src: imageIcon,
                    },
                    {
                    name: ".png",
                    src: imageIcon,
                    },
                    {
                    name: ".bmp",
                    src: imageIcon,
                    },
                ]}
                DropzoneComponent={
                    <SnaptrudeDropzone
                        multiple={true}
                        setUploadedFile={setUploadedFile}
                        dropzoneAccept="image/*"
                    />
                }
                uploadedFile={uploadedFile}
                resetUploadedFile={() => {
                    setUploadedFile(null);
                }}
                type={materialType}
                setType={setMaterialType}
                typeOptions={MATERIAL_TYPE_OPTIONS}
                showProgressBar={showProgressBar}
                progress={progress}
                progressText={progressText}
            />
        )
    }
    if(modalFor === "furnitures"){
        return (
            <SnaptrudeImportFunctionalityModal
                isOpen={isOpen}
                onClose={() => {
                    if(showProgressBar) setShowProgressBar(false);
                    onClose();
                }}
                onUpload={() => {
                    setProgress(0);
                    setShowProgressBar(true);
                    const uploadedFiles = uploadedFile.map((uf) => uf.file);
                    uploadTeamObjects(selectedTeam.id, uploadedFiles, "furnitures", furnitureType).then(
                    (resp) => {
                        const evtFound = FakeProgressEvent.find(`team-furnitures-progress`);
                        if(evtFound) evtFound.stop();
                        
                        if(resp.status){
                            setProgress(100);
                        }
                        setTimeout(() => {
                            setShowProgressBar(false);
                            
                            setUploadedFile(null);
                            onClose()

                        }, 1000)
                    }
                    );
                }}
                handleCancelFile={handleCancelFile}
                modalHeaderText={"Furniture"}
                bodyHeaderText={
                    "Select and upload 3D models in .zip format.\nYou can upload upto 10 files"
                }
                allowedTypes={[
                    {
                    name: ".zip",
                    src: cubeIcon,
                    },
                    {
                    name: ".skp",
                    src: cubeIcon,
                    },
                    {
                    name: ".3ds",
                    src: cubeIcon,
                    },
                    {
                    name: ".obj",
                    src: cubeIcon,
                    },
                    {
                    name: ".fbx",
                    src: cubeIcon,
                    }
                ]}
                DropzoneComponent={
                    <SnaptrudeDropzone
                    multiple={true}
                    setUploadedFile={setUploadedFile}
                    dropzoneAccept=".zip, .fbx, .skp, .3ds, .obj"
                    />
                }
                uploadedFile={uploadedFile}
                resetUploadedFile={() => {
                    setUploadedFile(null);
                }}
                type={furnitureType}
                setType={setFurnitureType}
                typeOptions={FURNITURE_TYPE_OPTIONS}
                showProgressBar={showProgressBar}
                progress={progress}
                progressText={progressText}
            />
        )
    }
    return null
}

export default Modal;