let ORIGIN, djangoUrl, socketUrl, socialUrl, forgeUrl, speckleUrl, speckleApiToken, environment;
if (window.location.hostname.toLowerCase().includes("united")) {
  djangoUrl = "https://united.snaptru.de";
  socketUrl = "https://save.snaptru.de";
  socialUrl = "https://socialunited.snaptru.de";
  forgeUrl =  'https://forgenodede.herokuapp.com';
  environment = "dotde";
} else if (window.location.hostname.toLowerCase().includes("app.snaptru.de")) {
  djangoUrl = "https://api.snaptru.de";
  socketUrl = "https://auto.snaptru.de";
  socialUrl = "https://socialdotde.snaptru.de";
  forgeUrl =  'https://forgenodede.herokuapp.com';
  environment = "dotde";
} else if (window.location.hostname.toLowerCase().includes("app.snaptrude.com")) {
  djangoUrl = "https://api.snaptrude.com";
  socketUrl = "https://auto.snaptrude.com";
  socialUrl = "https://social.snaptrude.com";
  forgeUrl =  'https://forgenode.herokuapp.com';
  speckleUrl = "https://speckle.snaptrude.com";
  speckleApiToken = "f9218fa674bdf1a1c57034164767ea525878b47d6f"; // info
  environment = "dotcom";
} else if (window.location.hostname.toLowerCase().includes("preview.snaptrude.com")) {
  djangoUrl = "https://api.snaptrude.com";
  socketUrl = "https://auto.snaptrude.com";
  socialUrl = "https://social.snaptrude.com";
  forgeUrl =  'https://forgenode.herokuapp.com';
  environment = "dotcom";
} else if (window.location.hostname.toLowerCase().includes("staging.snaptru.de")) {
  djangoUrl = "https://api.snaptru.de";
  socketUrl = "https://auto.snaptru.de";
  socialUrl = "https://socialdotde.snaptru.de";
  forgeUrl =  'https://forgenodede.herokuapp.com';
  speckleUrl = "https://specklestaging.snaptru.de"
  speckleApiToken = "e098fe862cd64ff0a5efb583401556f3ee318ded9e"; // info specklestaging
  environment = "dotde";
} else if (window.location.hostname.toLowerCase().includes("lunch.snaptru.de")) {
  djangoUrl = "https://api.snaptru.de";
  socketUrl = "https://auto.snaptru.de";
  socialUrl = "https://socialdotde.snaptru.de";
  forgeUrl =  'https://forgenodede.herokuapp.com';
  environment = "dotde";
} else if (window.location.hostname.toLowerCase().includes("staging.snaptrude.com")) {
  djangoUrl = "https://api.snaptrude.com";
  socketUrl = "https://auto.snaptrude.com";
  socialUrl = "https://social.snaptrude.com";
  forgeUrl =  'https://forgenode.herokuapp.com';
  environment = "dotcom";
} else if (window.location.hostname.toLowerCase().includes("dqu4bz8fhxdvj.amplifyapp.com")) {
  djangoUrl = "https://api.snaptrude.com";
  socketUrl = "https://auto.snaptrude.com";
  forgeUrl =  'https://forgenode.herokuapp.com';
  environment = "dotcom";
} else if(window.location.hostname.toLowerCase().includes("dud74h9smedhd.amplifyapp.com")) {
  djangoUrl = "https://api.snaptru.de";
  socketUrl = "https://auto.snaptru.de";
  socialUrl = "https://socialdotde.snaptru.de";
  forgeUrl =  'https://forgenodede.herokuapp.com';
  speckleUrl = "https://specklestaging.snaptru.de"
  speckleApiToken = "e098fe862cd64ff0a5efb583401556f3ee318ded9e"; // info specklestaging
  environment = "dotde";
} else if(window.location.hostname.toLowerCase().includes("testing.snaptru.de")) {
  djangoUrl = "https://api.snaptru.de";
  socketUrl = "https://auto.snaptru.de";
  socialUrl = "https://socialdotde.snaptru.de";
  forgeUrl =  'https://forgenodede.herokuapp.com';
  speckleUrl = "https://specklestaging.snaptru.de"
  speckleApiToken = "e098fe862cd64ff0a5efb583401556f3ee318ded9e"; // info specklestaging
  environment = "dotde";
} else if(window.location.hostname.toLowerCase().includes("wework.snaptrude.co")) {
  djangoUrl = "https://weworkapi.snaptrude.co";
  socketUrl = "https://weworksave.snaptrude.co";
  socialUrl = "https://weworksocial.snaptrude.co";
  forgeUrl =  'https://forgenode.herokuapp.com';
  speckleUrl = "https://weworkspeckle.snaptrude.co";
  speckleApiToken = "330fa8b863e694329c423a16f9e05a05945a7013f5"; // info
  environment = "dotde";
} else {
  djangoUrl = "http://" + window.location.hostname + ":8000";
  socketUrl = "http://" + window.location.hostname + ":6012";
  socialUrl = "http://" + window.location.hostname + ":6045";
  forgeUrl =  'http://localhost:6016';
  speckleUrl = "https://speckle.xyz";
  speckleApiToken = "e1d2e9a6981737e8bd06a4c7efddf832a9e3141524"; // kul xyz
  // speckleApiToken = "c9cc243ad8ba4543b5769448680f173cbc3648a1ba"; // pooja xyz
  environment = "localhost";
}
ORIGIN = djangoUrl;
export {
  ORIGIN,
  socketUrl,
  djangoUrl,
  socialUrl,
  forgeUrl,
  speckleUrl,
  speckleApiToken,
  environment,
};
