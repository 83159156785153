import * as log from "loglevel";
import BABYLON from "../modules/babylonDS.module.js";
import * as math from "mathjs";
import earcut from "earcut";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  roundPoint,
  roundVector,
  toRadians,
  getNormalVector,
} from "./mathFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { drawInitialModel } from "./sceneFuncs.js";
import {
  getBottomFaceVertices,
  getFaceVerticesFromFace,
  sortFaceIndicesForHalfEdge,
  correctVerticesOrderOfBottomFaceXZ,
  generateBrepFromPositionsAndCells,
  generateMeshFromBrep,
} from "./brepOperations.js";
import { appElement } from "./bimDataFuncs.js";
import { searchForId, makeid } from "./arrayFuncs.js";
import { removeCollinearPoints } from "./wall_generation_helper.js";
import {
  snapVertData,
  getAngleBetweenVectors,
  isFloatEqual,
} from "./snapFuncs.js";
import { Mass } from "../modules/snaptrudeDS/mass.ds.js";
import { assignProperties } from "./sceneStateFuncs.js";
import { addMaterialToMesh } from "./mats.js";
import { getVector3FromArray } from "../modules/extrafunc.js";
import { room_types_db } from "./obj_base.js";
/*jshint esversion: 6 */
// Creating Mass Blocks Start

function findPartition(array, i, start, end, wall_partition) {
  if (start < 0) {
    start += array.length;
  }
  if (end > array.length - 1) {
    end = end % array.length;
  }
  if (
    JSON.stringify(roundPoint(array[start - 1])) ===
    JSON.stringify(roundPoint(array[end + 1]))
  ) {
    wall_partition.push(store.room_pols[i][start - 1]);
    wall_partition.push(store.room_pols[i][end + 1]);
    findPartition(array, i, start - 1, end + 1, wall_partition);
  }
}

async function createMassBlocks() {
  // debugger;
  let structureColl = StructureCollection.getInstance();
  let structures = structureColl.getStructures();
  for (let id in structures) {
    let structure = structures[id];
    structure.deleteAllLevels();
    delete structures[id];
  }
  drawInitialModel(true).finally(function () {});
}

function splitMassIntoStoreys(mass, [lowerStorey, higherStorey]) {
  let mesh = mass.mesh;
  let worldMatrix = mesh.getWorldMatrix();
  let transformMatrix = store.scene.getTransformMatrix();
  let position = mesh.position;
  let positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let indices = mesh.getIndices();
  let numberOfVertices = positions.length / 3;
  let structures = StructureCollection.getInstance().getStructures();
  let structure;
  for (let str in structures) {
    if (str !== "default") {
      structure = structures[str];
    }
  }
  let storeys = structure.getStoreyData().getAllStoreys();
  const b = getBottomFaceVertices(mass, BABYLON.Space.WORLD);
  // let splitArray = [[...b,b[0]]];

  let splitArray = [];
  for (let q = lowerStorey; q < higherStorey; q++) {
    if (storeys[q]) {
      let storey = storeys[q].base;
      let plane;
      if (q === higherStorey - 1) {
        let height = storeys[mass.mesh.storey] + mass.mesh.height;
        plane = new BABYLON.Plane(0, 1, 0, height);
      } else if (q === lowerStorey) {
        plane = new BABYLON.Plane(0, 1, 0, storey + 1e-3);
      } else {
        plane = new BABYLON.Plane(0, 1, 0, storey);
      }
      // getSplitLines(positions, indices, worldMatrix, plane);
      const temp = getSplitLines(mass, plane);
      if (temp && temp.length) {
        splitArray.push(temp);
        if (q > 1) {
          let downPolygon = splitArray[splitArray.length - 2];
          let upPolygon = splitArray[splitArray.length - 1];
          let polygonObject = {
            path_bottom_vec: downPolygon,
            path_top_vec: upPolygon,
          };
          let mergedMesh = createCustomMeshAccordingToNormal(polygonObject);
          mergedMesh.type = "Mass";
          mergedMesh.hideEdges = true;
          // mergedMesh.material = store.scene.getMaterialByName("basicObjectMaterial");
          mergedMesh.storey = q;
          mergedMesh.height =
            mergedMesh.getBoundingInfo().boundingBox.maximumWorld.y -
            mergedMesh.getBoundingInfo().boundingBox.minimumWorld.y;
        }
      }
    }
  }

  function getPointOfIntersection(line, plane) {
    let pt = math.intersect(
      [line[0].x, line[0].y, line[0].z],
      [line[1].x, line[1].y, line[1].z],
      [plane.normal.x, plane.normal.y, plane.normal.z, plane.d]
    );

    if (isFinite(pt[0]) && isFinite(pt[1]) && isFinite(pt[2])) {
      if (!isNaN(pt[0]) && !isNaN(pt[1]) && !isNaN(pt[2])) {
        return new BABYLON.Vector3(pt[0], pt[1], pt[2]);
      }
    }
  }

  function checkIfPointInBounds(pt, mesh) {
    let bb = mesh.getBoundingInfo().boundingBox;
    let maxWorld = bb.maximumWorld;
    let minWorld = bb.minimumWorld;
    if (
      pt.x >= minWorld.x &&
      pt.x <= maxWorld.x &&
      pt.y >= minWorld.y &&
      pt.y <= maxWorld.y &&
      pt.z >= minWorld.z &&
      pt.z <= maxWorld.z
    ) {
      return true;
    } else {
      return false;
    }
  }

  function getSplitLines(meshObj, plane) {
    let faces = meshObj.brep.faces;
    let intersectingPolygon = [];

    for (let i = 0; i < faces.length; i++) {
      let faceVert = getFaceVerticesFromFace(
        faces[i],
        mesh,
        BABYLON.Space.WORLD
      );
      for (let j = 0; j < faceVert.length; j++) {
        let line = [faceVert[j], faceVert[(j + 1) % faceVert.length]];
        let p = getPointOfIntersection(line, plane);
        if (p) {
          if (checkIfPointInBounds(p, mesh)) {
            intersectingPolygon.push(p);
          }
        }
      }
    }
    let a = intersectingPolygon;
    for (let i = 0; i < a.length; i++) {
      a[i].x = Math.round(a[i].x * 1e5) / 1e5;
      a[i].y = Math.round(a[i].y * 1e5) / 1e5;
      a[i].z = Math.round(a[i].z * 1e5) / 1e5;
    }

    let b = a.uniq();
    if (b.length) {
      const t = b[1];
      b[1] = b[0];
      b[0] = t;
      b.push(b[0]);
      // selectionStack[0].material.wireframe = true;
      let lines = BABYLON.MeshBuilder.CreateLines(
        "lines",
        { points: b },
        store.scene
      );
      lines.color.g = 0;
      return b;
    } else {
      return [];
    }
  }

  function getSplitLinesMesh(positions, indices, worldMatrix, plane) {
    let intersectingPolygon = [];
    for (let i = 0; i < indices.length - 3; i += 3) {
      let p1 = new BABYLON.Vector3(
        positions[indices[i] * 3],
        positions[indices[i] * 3 + 1],
        positions[indices[i] * 3 + 2]
      );
      let p2 = new BABYLON.Vector3(
        positions[indices[i + 1] * 3],
        positions[indices[i + 1] * 3 + 1],
        positions[indices[i + 1] * 3 + 2]
      );
      let p3 = new BABYLON.Vector3(
        positions[indices[i + 2] * 3],
        positions[indices[i + 2] * 3 + 1],
        positions[indices[i + 2] * 3 + 2]
      );
      p1 = BABYLON.Vector3.TransformCoordinates(p1, worldMatrix);
      p2 = BABYLON.Vector3.TransformCoordinates(p2, worldMatrix);
      p3 = BABYLON.Vector3.TransformCoordinates(p3, worldMatrix);
      let lineAB = [p1, p2];
      let lineBC = [p2, p3];
      let lineCA = [p3, p1];
      let p11 = getPointOfIntersection(store.line, plane);
      if (p11) {
        if (checkIfPointInBounds(p11, mesh)) {
          intersectingPolygon.push(p11);
        }
      }
      p11 = getPointOfIntersection(lineBC, plane);
      if (p11) {
        if (checkIfPointInBounds(p11, mesh)) {
          intersectingPolygon.push(p11);
        }
      }
      p11 = getPointOfIntersection(lineCA, plane);
      if (p11) {
        if (checkIfPointInBounds(p11, mesh)) {
          intersectingPolygon.push(p11);
        }
      }
    }
    let a = intersectingPolygon;
    for (let i = 0; i < a.length; i++) {
      a[i].x = Math.round(a[i].x * 1e5) / 1e5;
      a[i].y = Math.round(a[i].y * 1e5) / 1e5;
      a[i].z = Math.round(a[i].z * 1e5) / 1e5;
    }

    let b = a.uniq();
    if (b.length) {
      b.push(b[0]);
      // selectionStack[0].material.wireframe = true;
      let lines = BABYLON.MeshBuilder.CreateLines(
        "lines",
        { points: b },
        store.scene
      );
      lines.color.g = 0;
      return b;
    } else {
      return [];
    }
  }
}

function createMassBlocksForStructure(floorkey_data, structure) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  var mat = store.scene.getMaterialByName("basicObjectMaterial");
  var room_pols = floorkey_data["rooms"]["pols"];
  var room_types = floorkey_data["rooms"]["types"];
  var room_curves = floorkey_data["rooms"]["curves"];
  var room_levels = floorkey_data["rooms"]["levels"];
  var room_ids = floorkey_data["rooms"]["ids"];
  var room_pos = floorkey_data["rooms"]["pos"];
  var room_rot = floorkey_data["rooms"]["rot"];
  var room_scaling = floorkey_data["rooms"]["scaling"];
  var room_names = floorkey_data["rooms"]["names"];
  var low_level = floorkey_data.fl_details.level_id;
  var high_level = (parseInt(low_level) + 1).toString();
  //  var addFloorPlan = false;
  // debugger;

  var level = structure.addLevel(low_level, high_level);

  if ($scope.units_type.value) {
    if ($scope.units_type.value === "millimeter") {
      store.floor_height = 0.3 / store.unit_absolute_scale;
    } else if ($scope.units_type.value === "meters") {
      store.floor_height = 0.3 / store.unit_absolute_scale;
    } else if ($scope.units_type.value === "centimeter") {
      store.floor_height = 0.3 / store.unit_absolute_scale;
    } else if ($scope.units_type.value === "inches") {
      store.floor_height = 12.0;
    } else if ($scope.units_type.value === "feet-inches") {
      store.floor_height = 12.0;
    }
  }
  for (var i = 0; i < store.room_pols.length; i++) {
    if (!room_curves[i]) {
      var id = searchForId(room_types_db, store.room_types[i]);
      var obj_props = room_types_db[id];
      if (obj_props) {
        var obj_name = obj_props.name;
        var obj_height = obj_props.props.height * store.floor_height;
        var obj_far = obj_props.props.far;
        var obj_mat = obj_props.props.mat;
      } else {
        var obj_name = store.room_types[i];
        var obj_height = store.floor_height;
        var obj_far = true;
        var obj_mat = "none";
      }
      var shape_temp1 = [];
      var shape_temp2 = [];
      var path = [];
      var path1 = [];
      var path2 = [];
      try {
        for (let j = 0; j < store.room_pols[i].length - 2; j++) {
          if (
            JSON.stringify(room_pols[i][j]) ==
              JSON.stringify(room_pols[i][j + 3]) &&
            JSON.stringify(room_pols[i][j + 1]) ==
              JSON.stringify(room_pols[i][j + 2])
          ) {
            store.wall_partition.push(room_pols[i][j]);
            store.wall_partition.push(room_pols[i][j + 1]);
            store.wall_partition.push(room_pols[i][j + 2]);
            store.wall_partition.push(room_pols[i][j + 3]);
          }
          // store.room_pols[i].splice(j,3);
        }
        store.room_pols[i] = removeCollinearPoints(room_pols[i]);
      } catch (e) {}
      store.room_pols[i].push(room_pols[i][0]);
      for (var j = 0; j < store.room_pols[i].length; j++) {
        var point = new BABYLON.Vector3(
          store.room_pols[i][j][0] * store.unit_scale,
          -room_pols[i][j][1] * store.unit_scale,
          0.0
        );
        point = snapVertData(point);
        shape_temp1.push(point);
        var point = new BABYLON.Vector3(
          store.room_pols[i][j][0] * store.unit_scale,
          -room_pols[i][j][1] * store.unit_scale,
          obj_height
        );
        point = snapVertData(point);
        shape_temp2.push(point);
        var point = new BABYLON.Vector3(
          store.room_pols[i][j][0] * store.unit_scale,
          obj_height,
          -room_pols[i][j][1] * store.unit_scale
        );
        point = snapVertData(point);
        path1.push(point);
        var point = new BABYLON.Vector3(
          store.room_pols[i][j][0] * store.unit_scale,
          0.0,
          -room_pols[i][j][1] * store.unit_scale
        );
        point = snapVertData(point);
        path2.push(point);
      }
      path1 = path1.map((x) => roundVector(x, 10000));
      path2 = path2.map((x) => roundVector(x, 10000));
      shape_temp1 = shape_temp1.map((x) => roundVector(x, 10000));
      shape_temp2 = shape_temp2.map((x) => roundVector(x, 10000));
      //console.log("PATH", path1, path2, "\nShapeTemp", shape_temp1, shape_temp2);

      // var ribPol1 = new BABYLON.PolygonMeshBuilder("RibPol", shape_temp1, store.scene).build(true);
      // ribPol1.material = mat;
      // // invertNormals(ribPol1);
      // var ribPol2 = new BABYLON.PolygonMeshBuilder("RibPol", shape_temp2, store.scene).build(true);
      // ribPol2.position.y = obj_height;
      // ribPol2.material = mat;
      // // invertNormals(ribPol2);
      // var ribbon = BABYLON.Mesh.CreateRibbon("ribbon", [path1, path2], false, false, 0, store.scene, true, BABYLON.DOUBLESIDE);
      // ribbon.material = mat;
      // // console.log(ribPol1.getFacetLocalNormals());
      // // console.log(ribPol2.getFacetLocalNormals());
      // // console.log(ribbon.getFacetLocalNormals());
      // var mergedMesh = BABYLON.Mesh.MergeMeshes([ribPol1, ribPol2, ribbon], true, true);

      let points = shape_temp1.map((v3) => [v3.x, v3.y, v3.z]);
      points.pop();
      let mergedMesh = createCustomMesh(points, obj_height);
      mergedMesh.material = mat;

      // invertNormals(mergedMesh);
      // console.log(mergedMesh.getFacetLocalNormals());
      // console.log(mergedMesh.subMeshes);
      mergedMesh.checkCollisions = true;
      mergedMesh.name = room_names[i];
      mergedMesh.room_type = store.room_types[i];
      mergedMesh.room_id = room_ids[i];
      mergedMesh.room_curve = room_curves[i];
      mergedMesh.room_path = JSON.stringify(room_pols[i]);
      log.warn(mergedMesh.room_path);
      mergedMesh.height = obj_height;
      mergedMesh.showBoundingBox = true;
      mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      mergedMesh.level = room_levels[i];
      mergedMesh.room_unique_id = makeid(3);
      mergedMesh.structure = store.room_structures[i];
      mergedMesh.offsetFlag = false;
      mergedMesh.type = "Mass";

      if (room_pos[i][0] !== 0 || store.room_pos[i][2] !== 0) {
        // mergedMesh.position.x = store.room_pos[i][0] * store.unit_scale;
        // mergedMesh.position.z = store.room_pos[i][2] * store.unit_scale;
      }
      // mergedMesh.position.y = obj_height * room_levels[i]; //+ 0.5 * obj_height;

      // mergedMesh.refreshBoundingInfo();
      // let bbinfo = mergedMesh.getBoundingInfo();
      // let center = bbinfo.boundingBox.centerWorld;
      // let relativePosition = center.subtract(mergedMesh.position);
      // mergedMesh.setPivotPoint(relativePosition);
      var bbinfo = mergedMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      // mergedMesh.setPivotPoint(centroid);
      mergedMesh.rotation.x = room_rot[i][0];
      mergedMesh.rotation.y = room_rot[i][1];
      mergedMesh.rotation.z = room_rot[i][2];

      mergedMesh.scaling.x = room_scaling[i][0];
      mergedMesh.scaling.y = room_scaling[i][1];
      mergedMesh.scaling.z = room_scaling[i][2];
      mergedMesh.structure_id = floorkey_data.fl_details.structure_id;

      // mergedMesh.convertToFlatShadedMesh();
      // level.addMassToLevel(new Mass(mergedMesh));

      //let storeyHeight = findStoreyHeight(low_level);
      // debugger;
      if (parseInt(low_level) != 0) {
        let storeyHeight = null;
        if (!store.storeysDS[low_level - 1]) {
          store.storeysDS.push({
            low: store.storeysDS[low_level - 2].low,
            height: store.storeysDS[low_level - 2].height,
          });
        }
        storeyHeight =
          store.storeysDS[low_level - 1].low +
          store.storeysDS[low_level - 1].height;
        mergedMesh.storey = parseInt(low_level) + 1;

        mergedMesh.position.y = storeyHeight + mergedMesh.height / 2;
      }

      level.addMassToLevel(new Mass(mergedMesh));

      sortFaceIndicesForHalfEdge(mergedMesh);
      assignProperties(mergedMesh, i, "mass");

      addMaterialToMesh(mergedMesh, obj_mat);
    } else {
      var path = JSON.parse(store.room_paths[i]);
      var curPoint = [];
      var ribPaths = [];
      var temp_path1 = [];
      var temp_path2 = [];
      var temp_path3 = [];
      var temp_path4 = [];
      var id = searchForId(room_types_db, store.room_types[i]);
      var obj_props = store.room_types_db[id];
      if (obj_props) {
        var obj_name = obj_props.name;
        var obj_height = obj_props.props.height * store.floor_height;
        var obj_far = obj_props.props.far;
        var obj_mat = obj_props.props.mat;
      } else {
        var obj_name = store.room_types[i];
        var obj_height = store.floor_height;
        var obj_far = true;
        var obj_mat = "none";
      }
      for (var j = 0; j < path.length; j++) {
        if (path[j][0] == "M") {
          var curPoint1 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            -path[j][2] * store.unit_scale,
            0
          );
          var curPoint2 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            0,
            -path[j][2] * store.unit_scale
          );
          var curPoint3 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            -path[j][2] * store.unit_scale,
            store.floor_height
          );
          var curPoint4 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            store.floor_height,
            -path[j][2] * store.unit_scale
          );
          temp_path1.push(curPoint1);
          temp_path2.push(curPoint2);
          temp_path3.push(curPoint3);
          temp_path4.push(curPoint4);
        }
        if (path[j][0] == "L") {
          var pt1 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            -path[j][2] * store.unit_scale,
            0
          );
          var pt2 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            0,
            -path[j][2] * store.unit_scale
          );
          var pt3 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            -path[j][2] * store.unit_scale,
            store.floor_height
          );
          var pt4 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            store.floor_height,
            -path[j][2] * store.unit_scale
          );

          temp_path1.push(pt1);
          temp_path2.push(pt2);
          temp_path3.push(pt3);
          temp_path4.push(pt4);

          curPoint1 = pt1;
          curPoint2 = pt2;
          curPoint3 = pt3;
          curPoint4 = pt4;
        } else if (path[j][0] == "C") {
          var control1 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            -path[j][2] * store.unit_scale,
            0
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] * store.unit_scale,
            -path[j][4] * store.unit_scale,
            0
          );
          var dest1 = new BABYLON.Vector3(
            path[j][5] * store.unit_scale,
            -path[j][6] * store.unit_scale,
            0
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint1,
            control1,
            control2,
            dest1,
            10
          );
          curPoint1 = dest1;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path1.push(temp_pts[k]);
          }

          var control1 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            0,
            -path[j][2] * store.unit_scale
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] * store.unit_scale,
            0,
            -path[j][4] * store.unit_scale
          );
          var dest2 = new BABYLON.Vector3(
            path[j][5] * store.unit_scale,
            0,
            -path[j][6] * store.unit_scale
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint2,
            control1,
            control2,
            dest2,
            10
          );
          curPoint2 = dest2;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path2.push(temp_pts[k]);
          }

          var control1 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            -path[j][2] * store.unit_scale,
            store.floor_height
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] * store.unit_scale,
            -path[j][4] * store.unit_scale,
            store.floor_height
          );
          var dest3 = new BABYLON.Vector3(
            path[j][5] * store.unit_scale,
            -path[j][6] * store.unit_scale,
            store.floor_height
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint3,
            control1,
            control2,
            dest3,
            10
          );
          curPoint3 = dest3;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path3.push(temp_pts[k]);
          }

          var control1 = new BABYLON.Vector3(
            path[j][1] * store.unit_scale,
            store.floor_height,
            -path[j][2] * store.unit_scale
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] * store.unit_scale,
            store.floor_height,
            -path[j][4] * store.unit_scale
          );
          var dest4 = new BABYLON.Vector3(
            path[j][5] * store.unit_scale,
            store.floor_height,
            -path[j][6] * store.unit_scale
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint4,
            control1,
            control2,
            dest4,
            10
          );
          curPoint4 = dest4;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path4.push(temp_pts[k]);
          }
        }
      }
      var ribPol1 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        temp_path1,
        store.scene
      ).build(true);
      ribPol1.material = mat;
      var ribPol2 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        temp_path3,
        store.scene
      ).build(true);
      ribPol2.position.y = store.floor_height;
      ribPol2.material = mat;
      var ribbon = BABYLON.Mesh.CreateRibbon(
        "ribbon",
        [temp_path2, temp_path4],
        false,
        false,
        0,
        store.scene,
        true,
        BABYLON.DOUBLESIDE
      );
      ribbon.material = mat;
      var mergedMesh = BABYLON.Mesh.MergeMeshes(
        [ribPol1, ribPol2, ribbon],
        true,
        true
      );
      mergedMesh.checkCollisions = true;
      mergedMesh.name = room_names[i];
      mergedMesh.room_type = store.room_types[i];
      mergedMesh.room_id = room_ids[i];
      mergedMesh.room_curve = room_curves[i];
      mergedMesh.room_path = store.room_paths[i];
      mergedMesh.height = obj_height;
      mergedMesh.checkCollisions = true;
      mergedMesh.showBoundingBox = true;
      mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      mergedMesh.level = room_levels[i];
      mergedMesh.structure = store.room_structures[i];
      mergedMesh.offsetFlag = false;
      mergedMesh.type = "Mass";

      var bbinfo = mergedMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      // mergedMesh.setPivotPoint(centroid);
      // (room_pos[i][0] * store.unit_scale, store.room_pos[i][1] * store.unit_scale, mergedMesh.position, Math.abs(bbinfo.maximum.x - bbinfo.minimum.x) / 2, Math.abs(bbinfo.maximum.z - bbinfo.minimum.z) / 2);

      if (room_pos[i][0] != 0 || store.room_pos[i][2] != 0) {
        // mergedMesh.position.x = store.room_pos[i][0] * store.unit_scale;
        // mergedMesh.position.z = -Math.abs(room_pos[i][2] * store.unit_scale);
      }

      // mergedMesh.position.y = obj_height * room_levels[i] + 0.5 * obj_height;

      mergedMesh.rotation.x = room_rot[i][0];
      mergedMesh.rotation.y = room_rot[i][1];
      mergedMesh.rotation.z = room_rot[i][2];

      mergedMesh.scaling.x = room_scaling[i][0];
      mergedMesh.scaling.y = room_scaling[i][1];
      mergedMesh.scaling.z = room_scaling[i][2];

      mergedMesh.structure_id = floorkey_data.fl_details.structure_id;
      if (parseInt(low_level) != 0) {
        let storeyHeight = null;
        if (!store.storeysDS[low_level - 1]) {
          store.storeysDS.push({
            low: store.storeysDS[low_level - 2].low,
            height: store.storeysDS[low_level - 2].height,
          });
        }
        storeyHeight =
          store.storeysDS[low_level - 1].low +
          store.storeysDS[low_level - 1].height;
        mergedMesh.storey = parseInt(low_level) + 1;

        mergedMesh.position.y = storeyHeight + mergedMesh.height / 2;
      }
      level.addMassToLevel(new Mass(mergedMesh));

      assignProperties(mergedMesh, i, "mass");
      mergedMesh.structure_id = floorkey_data.fl_details.structure_id;
    }
    // offsetStructures();
  }
}

function xmlToJson(xml) {
  // Create the return object
  var obj = {};

  if (xml.nodeType == 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof obj[nodeName] == "undefined") {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push == "undefined") {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}

var pointsOnEllipticalArc = function (
  p0,
  rx,
  ry,
  xAxisRotation,
  largeArcFlag,
  sweepFlag,
  p1,
  nb_of_points
) {
  rx = Math.abs(rx);
  ry = Math.abs(ry);
  let angleBetween;
  function mod(n, x) {
    return ((n % x) + x) % x;
  }

  xAxisRotation = mod(xAxisRotation, 360);
  var xAxisRotationRadians = toRadians(xAxisRotation);
  if (p0.x === p1.x && p0.y === p1.y) {
    return p0;
  }
  
  if (rx === 0 || ry === 0) {
    let t;
    return this.pointOnLine(p0, p1, t);
  }

  // Step #1: Compute transformedPoint
  var dx = (p0.x - p1.x) / 2;
  var dy = (p0.y - p1.y) / 2;
  var transformedPoint = {
    x:
      Math.cos(xAxisRotationRadians) * dx + Math.sin(xAxisRotationRadians) * dy,
    y:
      -Math.sin(xAxisRotationRadians) * dx +
      Math.cos(xAxisRotationRadians) * dy,
  };
  // Ensure radii are large enough
  var radiiCheck =
    Math.pow(transformedPoint.x, 2) / Math.pow(rx, 2) +
    Math.pow(transformedPoint.y, 2) / Math.pow(ry, 2);
  if (radiiCheck > 1) {
    rx = Math.sqrt(radiiCheck) * rx;
    ry = Math.sqrt(radiiCheck) * ry;
  }

  // Step #2: Compute transformedCenter
  var cSquareNumerator =
    Math.pow(rx, 2) * Math.pow(ry, 2) -
    Math.pow(rx, 2) * Math.pow(transformedPoint.y, 2) -
    Math.pow(ry, 2) * Math.pow(transformedPoint.x, 2);
  var cSquareRootDenom =
    Math.pow(rx, 2) * Math.pow(transformedPoint.y, 2) +
    Math.pow(ry, 2) * Math.pow(transformedPoint.x, 2);
  var cRadicand = cSquareNumerator / cSquareRootDenom;
  // Make sure this never drops below zero because of precision
  cRadicand = cRadicand < 0 ? 0 : cRadicand;
  var cCoef = (largeArcFlag !== sweepFlag ? 1 : -1) * Math.sqrt(cRadicand);
  var transformedCenter = {
    x: cCoef * ((rx * transformedPoint.y) / ry),
    y: cCoef * (-(ry * transformedPoint.x) / rx),
  };

  // Step #3: Compute center
  var center = {
    x:
      Math.cos(xAxisRotationRadians) * transformedCenter.x -
      Math.sin(xAxisRotationRadians) * transformedCenter.y +
      (p0.x + p1.x) / 2,
    y:
      Math.sin(xAxisRotationRadians) * transformedCenter.x +
      Math.cos(xAxisRotationRadians) * transformedCenter.y +
      (p0.y + p1.y) / 2,
  };

  // Step #4: Compute start/sweep angles
  // Start angle of the elliptical arc prior to the stretch and rotate operations.
  // Difference between the start and end angles
  var startVector = {
    x: (transformedPoint.x - transformedCenter.x) / rx,
    y: (transformedPoint.y - transformedCenter.y) / ry,
  };
  var startAngle = angleBetween(
    {
      x: 1,
      y: 0,
    },
    startVector
  );

  var endVector = {
    x: (-transformedPoint.x - transformedCenter.x) / rx,
    y: (-transformedPoint.y - transformedCenter.y) / ry,
  };
  var sweepAngle = angleBetween(startVector, endVector);

  if (!sweepFlag && sweepAngle > 0) {
    sweepAngle -= 2 * Math.PI;
  } else if (sweepFlag && sweepAngle < 0) {
    sweepAngle += 2 * Math.PI;
  }
  // We use % instead of `mod(..)` because we want it to be -360deg to 360deg(but actually in radians)
  sweepAngle %= 2 * Math.PI;

  let points = [];
  let t;
  for (let i = 0; i < nb_of_points; i++) {
    var angle = startAngle + sweepAngle * t;
    var ellipseComponentX = rx * Math.cos(angle);
    var ellipseComponentY = ry * Math.sin(angle);

    let point = new BABYLON.Vector3.Zero();
    // (point.x =
    //   Math.cos(xAxisRotationRadians) * ellipseComponentX -
    //   Math.sin(xAxisRotationRadians) * ellipseComponentY +
    //   center.x),
    //   (point.z =
    //     Math.sin(xAxisRotationRadians) * ellipseComponentX +
    //     Math.cos(xAxisRotationRadians) * ellipseComponentY +
    //     center.y);

    // Attach some extra info to use
    // point.ellipticalArcStartAngle = startAngle;
    // point.ellipticalArcEndAngle = startAngle + sweepAngle;
    // point.ellipticalArcAngle = angle;
    //
    // point.ellipticalArcCenter = center;
    // point.resultantRx = rx;
    // point.resultantRy = ry;
    points.push(point);
  }

  return points;
};

function createMassBlocksFromData(meshArray) {
  var coords = meshArray.coord;
  var names = meshArray.name;
  var ids = meshArray.id;
  var types = meshArray.room;
  var pos = meshArray.pos;
  var rot = meshArray.rot;
  var scaling = meshArray.scaling;

  var id = searchForId(room_types_db, types);
  var obj_props = room_types_db[id];
  if (obj_props) {
    var obj_name = obj_props.name;
    var obj_height = obj_props.props.height * store.floor_height;
    var obj_far = obj_props.props.far;
    var obj_mat = obj_props.props.mat;
  } else {
    var obj_name = store.room_types[i];
    var obj_height = store.floor_height;
    var obj_far = true;
    var obj_mat = "none";
  }

  for (var i = 0; i < coords.length; i++) {
    if (!store.meshData.curves[i]) {
      var shape_temp1 = [];
      var shape_temp2 = [];
      var path = [];
      var path1 = [];
      var path2 = [];
      for (var j = 0; j < coords[i].length; j++) {
        var point = new BABYLON.Vector3(
          coords[i][j][0] * store.unit_scale,
          -coords[i][j][1] * store.unit_scale,
          0.0
        );
        point = snapVertData(point);
        shape_temp1.push(point);
        var point = new BABYLON.Vector3(
          coords[i][j][0] * store.unit_scale,
          -coords[i][j][1] * store.unit_scale,
          store.floor_height
        );
        point = snapVertData(point);
        shape_temp2.push(point);
        var point = new BABYLON.Vector3(
          coords[i][j][0] * store.unit_scale,
          store.floor_height,
          -coords[i][j][1] * store.unit_scale
        );
        point = snapVertData(point);
        path1.push(point);
        var point = new BABYLON.Vector3(
          coords[i][j][0] * store.unit_scale,
          0.0,
          -coords[i][j][1] * store.unit_scale
        );
        point = snapVertData(point);
        path2.push(point);
      }
      path1 = path1.map((x) => roundVector(x, 10000));
      path2 = path2.map((x) => roundVector(x, 10000));
      shape_temp1 = shape_temp1.map((x) => roundVector(x, 10000));
      shape_temp2 = shape_temp2.map((x) => roundVector(x, 10000));
      //console.log("PATH", path1, path2, "\nShapeTemp", shape_temp1, shape_temp2);
      var ribPol1 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        shape_temp1,
        store.scene
      ).build(true);
      ribPol1.material = store.mat;
      var ribPol2 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        shape_temp2,
        store.scene
      ).build(true);
      ribPol2.position.y = store.floor_height;
      ribPol2.material = store.mat;
      var ribbon = BABYLON.Mesh.CreateRibbon(
        "ribbon",
        [path1, path2],
        false,
        false,
        0,
        store.scene,
        true,
        BABYLON.DOUBLESIDE
      );
      ribbon.material = store.mat;
      var mergedMesh = BABYLON.Mesh.MergeMeshes(
        [ribPol1, ribPol2, ribbon],
        true,
        true
      );
      mergedMesh.checkCollisions = true;
      mergedMesh.name = names[i];
      mergedMesh.room_type = types[i];
      mergedMesh.room_id = ids[i];
      mergedMesh.room_curve = store.meshData.curves[i];
      mergedMesh.room_path = store.room_paths[i];
      mergedMesh.checkCollisions = true;
      mergedMesh.showBoundingBox = true;
      mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
      mergedMesh.type = "Mass";
      mergedMesh.room_unique_id = makeid(3);

      var bbinfo = mergedMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      mergedMesh.setPivotPoint(centroid);

      if (store.room_pos[i][0] != 0 || store.room_pos[i][2] != 0) {
        mergedMesh.position.x = pos[i][0] * store.unit_scale;
        mergedMesh.position.y = pos[i][1] * store.unit_scale;
        mergedMesh.position.z = pos[i][2] * store.unit_scale;
      }

      mergedMesh.rotation.x = rot[i][0];
      mergedMesh.rotation.y = rot[i][1];
      mergedMesh.rotation.z = rot[i][2];

      mergedMesh.scaling.x = scaling[i][0];
      mergedMesh.scaling.y = scaling[i][1];
      mergedMesh.scaling.z = scaling[i][2];
    }
  }
}

function offsetRoomPolygons() {
  for (var i = 0; i < store.room_pols.length; i++) {
    // store.room_pols[i];
    var temp_pol = [];
    for (var j = 0; j < store.room_pols[i].length; j++) {
      var pt = { X: store.room_pols[i][j][0], Y: store.room_pols[i][j][1] };
    }
  }
}

function removeRedundantVertices(mesh) {
  var verData = jQuery.extend(
    [],
    mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
  );
  var indices = jQuery.extend([], mesh.getIndices());
  //console.log(verData, indices);
  // var vData = jQuery.extend([], verData)
  for (var i = 0; i < indices.length - 1; i += 1) {
    for (var j = i + 1; j < indices.length; j += 1) {
      // if (i === j) continue;
      if (
        verData[indices[j] * 3] === verData[indices[i] * 3] &&
        verData[indices[j] * 3 + 1] === verData[indices[i] * 3 + 1] &&
        verData[indices[j] * 3 + 2] === verData[indices[i] * 3 + 2]
      ) {
        var temp_index = indices[j];
        if (indices[i] === indices[j]) continue;
        console.log(indices[i], indices[j], i, j);
        indices[j] = indices[i];
        verData.splice(indices[j] * 3, 3);
        for (var k = 0; k < indices.length; k++) {
          if (indices[k] > temp_index) {
            indices[k] -= 1;
          }
        }
      }
    }
  }
  // mesh.geometry.setVerticesData(BABYLON.VertexBuffer.PositionKind, verData, true);
  // mesh.setIndices(indices);

  //console.log(verData, indices);
}

/**
 * This function takes bottom points in [x, z, y] form
 * and the height (strictly along Y) to raise it by.
 * Used for roofs, floors and mass generation.
 *
 * For more control, use the function createCustomMeshAccordingToNormal
 *
 * @param path_bottom
 * @param height
 * @param path_top
 * @param holesPath
 * @param shiftCentre
 */
function createCustomMesh(
  path_bottom,
  height,
  path_top,
  holesPath = [],
  shiftCentre = true
) {
  correctVerticesOrderOfBottomFaceXZ(path_bottom);

  if (!path_top) {
    path_top = [];
    path_bottom.forEach(function (vertex) {
      path_top.push([vertex[0], vertex[1], vertex[2] + height]);
    });
  }

  if (height < 0) {
    let holder = path_top;
    path_top = path_bottom;
    path_bottom = holder;
  }

  let path_bottom_vec = path_bottom.map(
    (point) => new BABYLON.Vector3(point[0], point[2], point[1])
  );
  let path_top_vec = path_top.map(
    (point) => new BABYLON.Vector3(point[0], point[2], point[1])
  );

  let positions = [];
  let path_vec = [];

  let holesPathGlobal = [];
  for (let i = 0; i < holesPath.length; i++) {
    let holesTopInternal = [];
    holesPath[i].forEach(function (vertex) {
      holesTopInternal.push([vertex[0], vertex[1] + height, vertex[2]]);
    });
    holesPathGlobal.push(holesPath[i].concat(holesTopInternal));
  }

  let holePositionsVector = [];
  for (let i = 0; i < holesPath.length; i++) {
    let holesPathVector = holesPathGlobal[i].map(
      (point) => new BABYLON.Vector3(point[0], point[1], point[2])
    );
    holePositionsVector.push(holesPathVector);
  }

  let pathBottomFinal = [];
  pathBottomFinal.push(
    path_bottom_vec[0].x,
    path_bottom_vec[0].z,
    path_bottom_vec[0].y
  );
  for (let i = 0, j = path_bottom_vec.length - 1; i <= j; i++) {
    path_vec.push(path_bottom_vec[i]);
    if (i !== j)
      pathBottomFinal.push(
        path_bottom_vec[j - i].x,
        path_bottom_vec[j - i].z,
        path_bottom_vec[j - i].y
      );
  }

  let pathTopFinal = [];
  pathTopFinal.push(path_top_vec[0].x, path_top_vec[0].z, path_top_vec[0].y);
  for (let i = 0, j = path_top_vec.length - 1; i <= j; i++) {
    path_vec.push(path_top_vec[i]);
    if (i !== j)
      pathTopFinal.push(
        path_top_vec[j - i].x,
        path_top_vec[j - i].z,
        path_top_vec[j - i].y
      );
  }

  let centre = BABYLON.Vector3.Zero(),
    holes_path_vec = holePositionsVector;

  if (shiftCentre) {
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;
    path_vec.forEach((point) => {
      sumX += point.x;
      sumY += point.y;
      sumZ += point.z;
    });

    centre.x = sumX / path_vec.length;
    centre.y = sumY / path_vec.length;
    centre.z = sumZ / path_vec.length;

    path_bottom_vec = path_bottom_vec.map((vec) => vec.subtract(centre));
    path_top_vec = path_top_vec.map((vec) => vec.subtract(centre));
    path_vec = path_vec.map((vec) => vec.subtract(centre));

    holes_path_vec = [];
    for (let i = 0; i < holePositionsVector.length; i++) {
      holes_path_vec.push(
        holePositionsVector[i].map((vec) => vec.subtract(centre))
      );
    }
  }
  let holesPathFinal = [];
  for (let i = 0; i < holes_path_vec.length; i++) {
    let hpFinal = [];
    let hole = holes_path_vec[i];
    for (let j = 0; j < hole.length; j++) {
      hpFinal.push([hole[j].x, hole[j].y, hole[j].z]);
    }
    holesPathFinal.push(hpFinal);
  }

  let positionsOfTopandBottomFaces = [];
  // let topPositions = path_top.map(point => [point[0], point[2], point[1]]);
  // let bottomPositions = path_bottom.map(point => [point[0], point[2], point[1]]);
  // positionsOfTopandBottomFaces = bottomPositions.concat(topPositions);

  for (let i = 0; i < path_vec.length; i++) {
    positionsOfTopandBottomFaces.push([
      path_vec[i].x,
      path_vec[i].y,
      path_vec[i].z,
    ]);
  }

  let facesWithHoles = positionsOfTopandBottomFaces;
  for (let i = 0; i < holesPathFinal.length; i++) {
    facesWithHoles = facesWithHoles.concat(holesPathFinal[i]);
  }

  let count = 0;
  for (let i = 0; i < holesPath.length; i++) {
    count = count + holesPath[i].length;
  }
  let cells = new Array(2);

  let outerVerticesLength = positionsOfTopandBottomFaces.length;
  let faceLength = outerVerticesLength / 2;

  let firstIndexOfBottomOuterFace = 0;
  let lastIndexOfBottomFace = positionsOfTopandBottomFaces.length / 2 - 1;

  let firstIndexOfTopOuterFace = positionsOfTopandBottomFaces.length / 2;
  let lastIndexOfTopOuterFace = positionsOfTopandBottomFaces.length - 1;

  //Bottom and Top Indices
  for (let i = 0; i < cells.length; i++) {
    cells[i] = [];
  }

  cells[0][0] = [];
  cells[1][0] = [];

  for (let i = firstIndexOfBottomOuterFace; i <= lastIndexOfBottomFace; i++) {
    cells[0][0].push(i);
  }

  for (let i = firstIndexOfTopOuterFace; i <= lastIndexOfTopOuterFace; i++) {
    cells[1][0].push(i);
  }

  let k = 0;
  let firstIndexOfBottomHole = positionsOfTopandBottomFaces.length;
  while (k < holesPathFinal.length) {
    let lastIndexOfBottomHole =
      firstIndexOfBottomHole + holesPathFinal[k].length / 2 - 1;
    let firstIndexOfTopHole = lastIndexOfBottomHole + 1;
    let lastIndexOfTopHole =
      firstIndexOfTopHole + holesPathFinal[k].length / 2 - 1;

    let bottomIndicesInternal = [];
    for (let i = firstIndexOfBottomHole; i <= lastIndexOfBottomHole; i++) {
      bottomIndicesInternal.push(i);
    }
    let topIndicesInternal = [];
    for (let i = firstIndexOfTopHole; i <= lastIndexOfTopHole; i++) {
      topIndicesInternal.push(i);
    }

    cells[0].push(bottomIndicesInternal);
    cells[1].push(topIndicesInternal);

    k = k + 1;
    firstIndexOfBottomHole = lastIndexOfTopHole + 1;
  }

  for (let i = 0; i < path_bottom.length; i++) {
    let bottom1 = i;
    let bottom2 = (i + 1) % path_bottom.length;

    let top1 = bottom1 + lastIndexOfBottomFace + 1;
    let top2 = bottom2 + lastIndexOfBottomFace + 1;

    // let sideFace = [bottom1, bottom2, top2, top1];
    let sideFace = [bottom1, top1, top2, bottom2];

    cells.push([sideFace]);
  }

  //populate hole Faces
  let temp = positionsOfTopandBottomFaces.length;
  let outerAndbottomInnerHolesLength = positionsOfTopandBottomFaces.length;
  for (let i = 0; i < holesPath.length; i++) {
    outerAndbottomInnerHolesLength =
      outerAndbottomInnerHolesLength + holesPath[i].length;
    let k = 0;
    while (k < holesPath[i].length) {
      let bottom1 = temp;
      let bottom2 = temp + 1;
      if (bottom2 >= outerAndbottomInnerHolesLength) {
        bottom2 = bottom2 - holesPath[i].length;
      }

      let top1 = bottom1 + holesPath[i].length;
      let top2 = bottom2 + holesPath[i].length;

      // let sideFace = [bottom1, bottom2, top2, top1];
      let sideFace = [bottom1, top1, top2, bottom2];

      cells.push([sideFace]);

      k = k + 1;
      temp = bottom2;
    }
    outerAndbottomInnerHolesLength =
      outerAndbottomInnerHolesLength + holesPath[i].length;
    temp = temp + holesPath[i].length * 2;
  }

  /*
    Reverse the top face vertices to correct order.
    Since this function starts working on CW bottom vertices (when looked at from above),
    the top vertices are also CW
     */

  cells[1].forEach((cell) => cell.reverse());

  let brepOfMeshWithHoles = generateBrepFromPositionsAndCells(
    facesWithHoles,
    cells
  );
  let meshWithHoles = generateMeshFromBrep(brepOfMeshWithHoles);

  meshWithHoles.brep = brepOfMeshWithHoles;

  meshWithHoles.setAbsolutePosition(centre);
  return meshWithHoles;

  /*
        let holePositionsFinal = [];
        for( let i = 0; i < holesPath.length; i++ ){
            holePositionsFinal.push(holesPath[i].concat(holesPathTop[i]));
        }
    */

  /*
    Earcut paths for top and bottom faces. Required for triangulation of faces.
     */

  /*let earcutPathBottom = [];
    earcutPathBottom.push(path_bottom_vec[0].x, path_bottom_vec[0].z, path_bottom_vec[0].y);

    for (let i=0, j=path_bottom_vec.length-1; i<=j; i++){
        path_vec.push(path_bottom_vec[i]);
        if (i !== j) earcutPathBottom.push(path_bottom_vec[j-i].x, path_bottom_vec[j-i].z, path_bottom_vec[j-i].y);
    };

    let earcutPathTop = [];
    earcutPathTop.push(path_top_vec[0].x, path_top_vec[0].z, path_top_vec[0].y);


    for (let i=0, j=path_top_vec.length-1; i<=j; i++){
        path_vec.push(path_top_vec[i]);
        if (i !== j) earcutPathTop.push(path_top_vec[j-i].x, path_top_vec[j-i].z, path_top_vec[j-i].y);
    };


    let centre = BABYLON.Vector3.Zero();
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;
    path_vec.forEach(point => {
        sumX += point.x;
        sumY += point.y;
        sumZ += point.z;
    })

    centre.x = sumX/path_vec.length;
    centre.y = sumY/path_vec.length;
    centre.z = sumZ/path_vec.length;

    path_bottom_vec = path_bottom_vec.map(vec => vec.subtract(centre));
    path_top_vec = path_top_vec.map(vec => vec.subtract(centre));
    path_vec = path_vec.map(vec => vec.subtract(centre));

    let minX = 1e6;
    let maxX = -1e6;
    let minZ = 1e6;
    let maxZ = -1e6;
    for(w = 0; w < path_bottom_vec.length; w++) {
        minX = Math.min(path_bottom_vec[w].x, minX);
        maxX = Math.max(path_bottom_vec[w].x, maxX);
        minZ = Math.min(path_bottom_vec[w].z, minZ);
        maxZ = Math.max(path_bottom_vec[w].z, maxZ);
    }

    let diffX = maxX - minX;
    let diffZ = maxZ - minZ;

    /!*
    Populate positions and UVs for top and bottom faces
    Positions pushed in counter clockwise direction.
     *!/
    let uvs = [];
    let faceUV = new BABYLON.Vector4(0, 0, 1, 1);

    positions.push(path_bottom_vec[0].x, path_bottom_vec[0].y, path_bottom_vec[0].z);
    uvs.push((path_bottom_vec[0].x - minX)/diffX, (path_bottom_vec[0].z - minZ)/diffZ);

    let len = path_bottom_vec.length;
    let i = 1;
    while (i < len){
        positions.push(path_bottom_vec[i].x, path_bottom_vec[i].y, path_bottom_vec[i].z);
        // positions.push(path_bottom_vec[len-i].x, path_bottom_vec[len-i].y, path_bottom_vec[len-i].z);
        // uvs.push(path_bottom_vec[len-i].x/maxX + faceUV.x, path_bottom_vec[len-i].z/maxZ + faceUV.y);
        uvs.push((path_bottom_vec[i].x - minX)/diffX, (path_bottom_vec[i].z - minZ)/diffZ);
        i++;
    }


    len = path_top_vec.length;
    i = 0;
    while (i < len){
        positions.push(path_top_vec[i].x, path_top_vec[i].y, path_top_vec[i].z);
        uvs.push((path_bottom_vec[i].x - minX)/diffX, (path_bottom_vec[i].z - minZ)/diffZ);
        i++;
    }
    // populate positions for lateral faces
    for (let i = 0; i < len-1; i++){
        positions.push(path_vec[i].x, path_vec[i].y, path_vec[i].z);
        positions.push(path_vec[i+1].x, path_vec[i+1].y, path_vec[i+1].z);
        positions.push(path_vec[i+len+1].x, path_vec[i+len+1].y, path_vec[i+len+1].z);
        positions.push(path_vec[i+len].x, path_vec[i+len].y, path_vec[i+len].z);

    }

    positions.push(path_vec[len-1].x, path_vec[len-1].y, path_vec[len-1].z);
    positions.push(path_vec[0].x, path_vec[0].y, path_vec[0].z);
    positions.push(path_vec[len].x, path_vec[len].y, path_vec[len].z);
    positions.push(path_vec[2*len-1].x, path_vec[2*len-1].y, path_vec[2*len-1].z);

    // Calculate indices and uvs for lateral faces
    let indices = [];
    let faceFacetMapping = {};
    let faceID = -1;
    let facetID = -1;

    let verticesLength = 0;
    let triangles = [];
    for (let k=0; k < len+2; k++){

        faceFacetMapping[++faceID] = [];

        if (k === 0){ //bottom face
            triangles = earcut(earcutPathBottom, null, 3);
            /!*
            The points are in clockwise direction. Thus, reversing.
            They need to be in counter clockwise direction for normals to be facing outside the mesh.
             *!/
            for (let i=0, j=triangles.length; i<j; i += 3){
                let temp = triangles[i + 1];
                triangles[i + 1] = triangles[i + 2];
                triangles[i + 2] = temp;

                faceFacetMapping[faceID].push(++facetID);
            }

            triangles.forEach(function (point) {
                indices.push(Math.abs(point - len) % len);
            });
            // indices.push(...triangles);
            verticesLength += len;

            continue;
        }
        if (k === 1){//top face
            triangles = earcut(earcutPathTop, null, 3);

            for (let i=0, j=triangles.length; i<j; i += 3){
                faceFacetMapping[faceID].push(++facetID);
            }

            triangles.forEach(function (point) {
                // indices.push(point + verticesLength);
                indices.push(Math.abs(point - len) % len + verticesLength);
            });

            verticesLength += len;

            var indicesLimit = indices.length;

            continue;
        }

        for (let i=0; i<2; i++){
            faceFacetMapping[faceID].push(++facetID);
        }

        //lateral faces
        indices.push(verticesLength);
        indices.push(verticesLength + 1);
        indices.push(verticesLength + 2);
        indices.push(verticesLength);
        indices.push(verticesLength + 2);
        indices.push(verticesLength + 3);

        uvs.push(faceUV.x, faceUV.y);
        uvs.push(faceUV.z, faceUV.y);
        uvs.push(faceUV.z, faceUV.w);
        uvs.push(faceUV.x, faceUV.w);
        verticesLength += 4;

    }

    // Compute Normals
    let normals = [];
    BABYLON.VertexData.ComputeNormals(positions, indices, normals);

    //Create a custom mesh
    let mesh = new BABYLON.Mesh("customMesh", store.scene);
    mesh.setAbsolutePosition(centre);
    mesh.faceFacetMapping = faceFacetMapping;

    mesh.indicesLimit = indicesLimit;
    mesh.verticesLength = path_bottom_vec.length;

    //Create a vertexData object
    let vertexData = new BABYLON.VertexData();

    //Assign positions, indices, normals and uvs to vertexData
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.normals = normals;
    vertexData.uvs = uvs;


    //Apply vertexData to custom mesh
    vertexData.applyToMesh(mesh, true);

    return mesh;

*/
}

/**
 * This function takes the bottom points in BABYLON.Vector3(x, y, z) format
 * and raises them according to normal and height params.
 * You can alternatively provide the bottom points and top points directly
 * and leave out the normal and height.
 *
 * Note - If normal is not provided, then the determined normal
 * will depend on the order of the points provided.
 *
 * @param path_bottom_vec
 * @param path_top_vec
 * @param normal
 * @param vertical
 * @param shiftCentre
 */
function createCustomMeshAccordingToNormal({
  path_bottom_vec,
  path_top_vec,
  normal,
  height,
  shiftCentre = true, 
  faceLoops = null,
}) {
  let maxX = 0;
  let maxY = 0;
  let maxZ = 0;
  for (let w = 0; w < path_bottom_vec.length; w++) {
    maxX = Math.max(path_bottom_vec[w].x, maxX);
    maxY = Math.max(path_bottom_vec[w].y, maxY);
    maxZ = Math.max(Math.abs(path_bottom_vec[w].z), maxZ);
  }

  if (!normal) normal = getNormalVector(path_bottom_vec);
  if (!normal) return;
  //check if all the points are not planar
  //in this case the earcut algorithm wont work
  //we have to either write the earcut 3d or 
  let orientation = getNearestFaceOrientation(path_bottom_vec);

  if (!path_top_vec) {
    path_top_vec = [];
    if (!height || !normal) return;
    let unitNormalVector = new BABYLON.Vector3(
      normal[0],
      normal[1],
      normal[2]
    ).normalize();
    let diff = new BABYLON.Vector3(height, height, height);
    for (let i = 0; i < path_bottom_vec.length; i++) {
      let heightenedPoint = new BABYLON.Vector3(
        path_bottom_vec[i].x + diff.x * unitNormalVector.x,
        path_bottom_vec[i].y + diff.y * unitNormalVector.y,
        path_bottom_vec[i].z + diff.z * unitNormalVector.z
      );
      path_top_vec.push(heightenedPoint);
    }
  }

  let path_vec = [...path_bottom_vec, ...path_top_vec];
  let centre = BABYLON.Vector3.Zero();

  if (shiftCentre === true) {
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;
    path_vec.forEach((point) => {
      sumX += point.x;
      sumY += point.y;
      sumZ += point.z;
    });

    centre.x = sumX / path_vec.length;
    centre.y = sumY / path_vec.length;
    centre.z = sumZ / path_vec.length;

    path_bottom_vec = path_bottom_vec.map((vec) => vec.subtract(centre));
    path_top_vec = path_top_vec.map((vec) => vec.subtract(centre));
    path_vec = path_vec.map((vec) => vec.subtract(centre));
  }

  /*
    Earcut paths for top and bottom faces. Required for triangulation of faces.
     */

  let earcutPathBottom = changeOrderForEarcut(path_bottom_vec);
  let earcutPathTop = changeOrderForEarcut(path_top_vec);

  earcutPathBottom = _.flatten(earcutPathBottom);
  earcutPathTop = _.flatten(earcutPathTop);

  /*
    Populate positions and UVs for top and bottom faces
    Positions pushed in counter clockwise direction.
     */
  let uvs = [];
  let faceUV = new BABYLON.Vector4(0, 0, 1, 1);

  let positions = [];
  positions.push(
    path_bottom_vec[0].x,
    path_bottom_vec[0].y,
    path_bottom_vec[0].z
  );

  if (orientation === "xy")
    uvs.push(
      path_bottom_vec[0].x / maxX + faceUV.x,
      path_bottom_vec[0].y / maxY + faceUV.y
    );
  else if (orientation === "yz")
    uvs.push(
      path_bottom_vec[0].y / maxY + faceUV.x,
      path_bottom_vec[0].z / maxZ + faceUV.y
    );
  else
    uvs.push(
      path_bottom_vec[0].x / maxX + faceUV.x,
      path_bottom_vec[0].z / maxZ + faceUV.y
    );

  let len = path_bottom_vec.length;
  let i = 1;
  while (i < len) {
    positions.push(
      path_bottom_vec[i].x,
      path_bottom_vec[i].y,
      path_bottom_vec[i].z
    );
    // positions.push(path_bottom_vec[len-i].x, path_bottom_vec[len-i].y, path_bottom_vec[len-i].z);
    // uvs.push(path_bottom_vec[len-i].x/maxX + faceUV.x, path_bottom_vec[len-i].z/maxZ + faceUV.y);

    if (orientation === "xy")
      uvs.push(
        path_bottom_vec[i].x / maxX + faceUV.x,
        path_bottom_vec[i].y / maxY + faceUV.y
      );
    else if (orientation === "yz")
      uvs.push(
        path_bottom_vec[i].y / maxY + faceUV.x,
        path_bottom_vec[i].z / maxZ + faceUV.y
      );
    else
      uvs.push(
        path_bottom_vec[0].x / maxX + faceUV.x,
        path_bottom_vec[0].z / maxZ + faceUV.y
      );
    i++;
  }

  len = path_top_vec.length;
  i = 0;
  while (i < len) {
    positions.push(path_top_vec[i].x, path_top_vec[i].y, path_top_vec[i].z);

    if (orientation === "xy")
      uvs.push(
        path_top_vec[i].x / maxX + faceUV.x,
        path_top_vec[i].y / maxY + faceUV.y
      );
    else if (orientation === "yz")
      uvs.push(
        path_top_vec[i].y / maxY + faceUV.x,
        path_top_vec[i].z / maxZ + faceUV.y
      );
    else
      uvs.push(
        path_top_vec[0].x / maxX + faceUV.x,
        path_top_vec[0].z / maxZ + faceUV.y
      );
    i++;
  }
  // Calculate indices and uvs for lateral faces
  let indices = [];
  let faceFacetMapping = {};
  let faceID = -1;
  let facetID = -1;
  let verticesLength = 0;
  let triangles = [];
  if(faceLoops)
  {
    // populate positions for lateral face
    for (let k = 0; k < faceLoops.length ; k++) {
      faceFacetMapping[k] = [];
      if (k === 0) {
        //bottom face
        triangles = earcut(earcutPathBottom, null, 3);
        /*
              The points are in clockwise direction. Thus, reversing.
              They need to be in counter clockwise direction for normals to be facing outside the mesh.
               */
        for (let i = 0, j = triangles.length; i < j; i += 3) {
          faceFacetMapping[0].push(++facetID);
        }
  
        triangles.forEach(function (point) {
          indices.push(point + verticesLength);
        });
  
        verticesLength += earcutPathTop.length/3;
  
        continue;
      }
      if (k === 1) {
      //top face
        triangles = earcut(earcutPathTop, null, 3);
  
        for (let i = 0, j = triangles.length; i < j; i += 3) {
          faceFacetMapping[1].push(++facetID);
        }
  
        triangles.forEach(function (point) {
          indices.push(point + verticesLength);
        });
  
        verticesLength += earcutPathTop.length/3;
        var indicesLimit = indices.length;
        continue;
      }
      var cellPts = faceLoops[k];
      var cell = cellPts[0];
      let cellMaxX = 0;
      let cellMaxY = 0;
      let cellMaxZ = 0;
      for (let i = 0; i < cell.length; i++) {
        let pid = cell[i];
        cellMaxX = Math.max(path_vec[pid].x, cellMaxX);
        cellMaxY = Math.max(path_vec[pid].y, cellMaxY);
        cellMaxZ = Math.max(Math.abs(path_vec[pid].z), cellMaxZ);
        positions.push(path_vec[pid].x, path_vec[pid].y, path_vec[pid].z);
      }

      for (let i = 0; i < cell.length; i++) {
        let pid = cell[i];
        if (orientation === "xy")
          uvs.push(
            path_vec[pid].x / cellMaxX + faceUV.x,
            path_vec[pid].y / cellMaxY + faceUV.y
          );
        else if (orientation === "yz")
          uvs.push(
            path_vec[pid].y / cellMaxY + faceUV.x,
            path_vec[pid].z / cellMaxZ + faceUV.y
          );
        else
          uvs.push(
            path_vec[pid].x / cellMaxX + faceUV.x,
            path_vec[pid].z / cellMaxZ + faceUV.y
          );
        i++;
      }

      var pathLen = cell.length/2;
      for (let i = 0; i < pathLen-1; i++){
        var id0 = cell[i]; var id1 = cell[i+1];
        var id2 = cell[i+ pathLen+1]; var id3 = cell[i+ pathLen];
        faceFacetMapping[k].push(++facetID);
        indices.push(id0);
        indices.push(id1);
        indices.push(id2);

        faceFacetMapping[k].push(++facetID);
        indices.push(id0);
        indices.push(id2);
        indices.push(id3);

      }
      verticesLength +=cell.length;
    }
  }
  else
  {
    // populate positions for lateral faces
    for (let i = 0; i < len - 1; i++) {
      positions.push(path_vec[i].x, path_vec[i].y, path_vec[i].z);
      positions.push(path_vec[i + 1].x, path_vec[i + 1].y, path_vec[i + 1].z);
      positions.push(
        path_vec[i + len + 1].x,
        path_vec[i + len + 1].y,
        path_vec[i + len + 1].z
      );
      positions.push(
        path_vec[i + len].x,
        path_vec[i + len].y,
        path_vec[i + len].z
      );
    }

    positions.push(path_vec[len - 1].x, path_vec[len - 1].y, path_vec[len - 1].z);
    positions.push(path_vec[0].x, path_vec[0].y, path_vec[0].z);
    positions.push(path_vec[len].x, path_vec[len].y, path_vec[len].z);
    positions.push(
      path_vec[2 * len - 1].x,
      path_vec[2 * len - 1].y,
      path_vec[2 * len - 1].z
    );

    for (let k = 0; k < len + 2; k++) {
      faceFacetMapping[++faceID] = [];
      if (k === 0) {
        //bottom face
        triangles = earcut(earcutPathBottom, null, 3);
        /*
              The points are in clockwise direction. Thus, reversing.
              They need to be in counter clockwise direction for normals to be facing outside the mesh.
               */
        for (let i = 0, j = triangles.length; i < j; i += 3) {
          let temp = triangles[i + 1];
          triangles[i + 1] = triangles[i + 2];
          triangles[i + 2] = temp;
  
          faceFacetMapping[faceID].push(++facetID);
        }
  
        /*triangles.forEach(function (point) {
                  indices.push(Math.abs(point - len) % len);
              });*/
        indices.push(...triangles);
        verticesLength += len;
  
        continue;
      }
      if (k === 1) {
      //top face
        triangles = earcut(earcutPathTop, null, 3);
  
        /*for (let i=0, j=triangles.length; i<j; i += 3){
                  let temp = triangles[i + 1];
                  triangles[i + 1] = triangles[i + 2];
                  triangles[i + 2] = temp;
              }*/
  
        for (let i = 0, j = triangles.length; i < j; i += 3) {
          faceFacetMapping[faceID].push(++facetID);
        }
  
        triangles.forEach(function (point) {
          indices.push(point + verticesLength);
          // indices.push(Math.abs(point - len) % len + verticesLength);
        });
  
        verticesLength += len;
        var indicesLimit = indices.length;
  
        continue;
      }
  
      for (let i = 0; i < 2; i++) {
        faceFacetMapping[faceID].push(++facetID);
      }
      //lateral faces
      indices.push(verticesLength);
      indices.push(verticesLength + 1);
      indices.push(verticesLength + 2);
      indices.push(verticesLength);
      indices.push(verticesLength + 2);
      indices.push(verticesLength + 3);
  
      uvs.push(faceUV.x, faceUV.y);
      uvs.push(faceUV.z, faceUV.y);
      uvs.push(faceUV.z, faceUV.w);
      uvs.push(faceUV.x, faceUV.w);
      verticesLength += 4;
    }

  }


  // Compute Normals
  let normals = [];
  BABYLON.VertexData.ComputeNormals(positions, indices, normals);

  //Create a custom mesh
  let mesh = new BABYLON.Mesh("customMesh", store.scene);
  mesh.setAbsolutePosition(centre);
  mesh.faceFacetMapping = faceFacetMapping;
  mesh.indicesLimit = indicesLimit;
  mesh.verticesLength = path_bottom_vec.length;
  //Create a vertexData object
  var vertexData = new BABYLON.VertexData();

  //Assign positions, indices, normals and uvs to vertexData
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  //Apply vertexData to custom mesh
  vertexData.applyToMesh(mesh, true);

  return mesh;
}

function getNearestFaceOrientation(faceVerticesGlobal) {
  let normal = getNormalVector(faceVerticesGlobal, false);
  if (!normal) {
    // console.warn("UVs not assigned");
    return;
  }

  normal = getVector3FromArray(normal).normalize().negate();

  let angleWithX = getAngleBetweenVectors(normal, BABYLON.Vector3.Right());
  let angleWithY = getAngleBetweenVectors(normal, BABYLON.Vector3.Up());
  let angleWithMinusZ = getAngleBetweenVectors(
    normal,
    BABYLON.Vector3.Forward().negate()
  );

  let orientation = null;

  let threshold = 46;
  if (isFloatEqual(angleWithX, 0, threshold)) {
    orientation = "zy";
  } else if (isFloatEqual(angleWithX, 180, threshold)) {
    orientation = "-zy";
  } else if (isFloatEqual(angleWithY, 0, threshold)) {
    orientation = "xz";
  } else if (isFloatEqual(angleWithY, 180, threshold)) {
    orientation = "x-z";
  } else if (isFloatEqual(angleWithMinusZ, 0, threshold)) {
    orientation = "xy";
  } else if (isFloatEqual(angleWithMinusZ, 180, threshold)) {
    orientation = "-xy";
  }

  return orientation;
}

function changeOrderForEarcut(vertices) {
  function _pushElements(which) {
    let elements;
    if (which === "x") {
      pushedX = true;
      elements = Xs;
    } else if (which === "y") {
      pushedY = true;
      elements = Ys;
    } else if (which === "z") {
      pushedZ = true;
      elements = Zs;
    }

    elements.forEach((e, i) => {
      newVertices[i].push(e);
    });
  }

  let Xs, Ys, Zs;

  if (_.isArray(vertices[0])) {
    Xs = vertices.map((vertex) => vertex[0]);
    Ys = vertices.map((vertex) => vertex[1]);
    Zs = vertices.map((vertex) => vertex[2]);
  } else {
    Xs = vertices.map((vertex) => vertex.x);
    Ys = vertices.map((vertex) => vertex.y);
    Zs = vertices.map((vertex) => vertex.z);
  }

  let newVertices = vertices.map((v) => []);
  let orientation = getNearestFaceOrientation(vertices);
  if (!orientation) return vertices;

  let pushedX, pushedY, pushedZ;
  for (let char of orientation) {
    if (char === "-") continue;
    else _pushElements(char);
  }

  if (!pushedX) _pushElements("x");
  else if (!pushedY) _pushElements("y");
  else if (!pushedZ) _pushElements("z");

  return newVertices;
}

var _null = "  null  ";
var SvgCalibration = 0.00001;

function dim(v, u) {
  return Math.sqrt(
    Math.pow(u.x - v.x, 2) +
      Math.pow(u.y - v.y, 2) +
      (def(u.z) ? Math.pow(u.z - v.z, 2) : 0)
  );
}

function def(a, d) {
  if (a != undefined && a != null)
    return d != undefined && d != null ? a : true;
  else if (d != _null) return d != undefined && d != null ? d : false;
  return null;
}

var GetPoints = function (op) {
  var h1 = 1;
  function getLenRounded(pat, i) {
    var i = pat.getPointAtLength(i);
    return i; //{ x: round(i.x * ik) / ik, y: round(i.y * ik) / ik };
  }
  op.step = def(op.step, 0.5);
  var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", op.path);
  var result = [];
  var len = path.getTotalLength();
  if (
    def(op.inLine, _null) &&
    (!def(op.pointLength, _null) || op.pointLength < 1000)
  ) {
    op.step = 0.3;
  }
  if (def(op.pointLength, _null)) {
    op.min = len / op.pointLength;
  }
  var plen = 0.0;
  var s = getLenRounded(path, 0);
  op.density = def(op.density, [1]);
  // function getDensityMapStep(index) {
  //     var ps = Math.floor(op.density.length * (index / len));
  //     return op.step / op.density[ps];
  // }
  var p = s;
  var c = getLenRounded(path, op.step);
  plen += op.step;
  op.push(result, s);
  var p_o = 0;
  var oo_p = { x: 0, y: 0 };
  for (var i = op.step * 2; i < len; i++) {
    h1++;
    var n = getLenRounded(path, i);
    plen += op.step;
    if (def(op.inLine, true)) {
      if (i == op.step * 2) op.push(result, c);
      if (plen > def(op.min, 10)) {
        op.push(result, n);
        plen = 0.0;
      }
    } else {
      var d1 = dim(p, c);
      var d2 = dim(c, n);
      var d3 = dim(p, n);
      var d4 = 0;
      var d5 = 0;
      if (def(p_o, _null)) {
        d4 = dim(p_o, c);
        d5 = dim(p_o, n);
      }
      var iilen = Math.abs(d3 - (d2 + d1));
      var lll = SvgCalibration;
      if (iilen > lll || p_o > lll) {
        if (dim(n, oo_p) > 4.0) {
          op.push(result, n);
          oo_p = n;
        }
        plen = 0.0;
        p_o = 0;
      } else {
        p_o += iilen;
      }
    }
    p = c;
    c = n;
  }
  result = op.push(result, getLenRounded(path, len), true);
  var sr = [];
  var i = 0;
  for (let i = def(op.start, 0); i < result.length - def(op.end, 0); i++) {
    sr.push(result[i]);
  }
  return sr;
};

var createSVG = function (pathArray) {
  let sphere;
  let allPath = "";
  if (!store.scene.getMeshByName("layerRefPlane")) {
    // sphere = BABYLON.Mesh.CreatePlane("layerRefPlane", 10000, store.scene);
  } else {
    sphere = store.scene.getMeshByName("layerRefPlane");
  }
  sphere.visibility = 1;
  sphere.isVisible = true;
  var sresult = `
      
      float a , b , c;
      vec4 lineF3(vec3 a,vec3  b, vec3 p) { 
      float thickness = 1.0/100.0; 
         vec2 pa = p - a;
    vec2 ba = b - a;

    float h = clamp( dot(pa,ba)/dot(ba,ba), 0.0, 1.0 );
    // ????????
    float idk = length(pa - ba*h);

    return smoothstep(0.0, thickness, idk);
        
         vec3 vUV  , p1 ,p2;
         vec4 l1;
         float len,la;
        float line(vec2 point1 , vec2 point2,vec2 dir,float tick){
            vUV = vec2(dir.x,dir.y);
            p1 = vec2(point1.x,point1.y);
            p2 = vec2(point2.x,point2.y);
           l1 = lineF3(p1,p2,vUV);
            
          return l1;  
        }
        
         `;

  sphere.material = new BABYLON.CustomMaterial("mat", store.scene);
  sphere.material.Fragment_Definitions(sresult);
  sphere.material.alpha = 0;
  //sphere.material.diffuseTexture = new BABYLON.Texture('https://i.imgur.com/JMfgqFR.png',scene);

  var points = pointsFromSVG(pathArray);
  //    var scale = 2.;
  //    let pointsArr = [];
  //    for (let i = 0 ; i<pathArray.length; i++) {
  //
  //        let points = GetPoints({
  //            path: pathArray[i]._d,
  //            density: [1, 1, 1, 1, 1],
  //            push: function (r, n, e) {
  //                r.push({x: (n.x + 526) * scale, y: 0.0, z: (-n.y - 526) * scale});
  //                if (e) {
  //                    return r;
  //                }
  //            }, pointLength: 100, inLine: 0
  //        });
  //        pointsArr.push(points);
  //    }
  //sphere.material.diffuseTexture = new BABYLON.Texture('https://i.imgur.com/JMfgqFR.png',scene);
  function drawPath(points, tick, color) {
    let init = "vec3 pos = vPositionW.xyz;float tick = 10.;";
    let str = "";
    for (let k = 0; k < points.length; k++) {
      let pointsub = points[k];
      var s = " tick = float(" + tick + ");";
      for (var i = 0; i < pointsub.length; i++) {
        // console.log("point x-->",points[i].x);
        s +=
          `if( length( vec3(float(` +
          pointsub[i].x +
          `) ,0.,float(` +
          pointsub[i].z +
          `))- vec3(pos.x,0.,pos.z) ) < tick || 
            ( float(` +
          i +
          `) > 0. &&  line( vec2(float(` +
          pointsub[i].x +
          `) ,float(` +
          pointsub[i].z +
          `)),
                 vec2(float(` +
          pointsub[Math.max(0, i - 1)].x +
          `) ,float(` +
          pointsub[Math.max(0, i - 1)].z +
          `) ),pos.xz,tick)>0.)){
                 color = vec4(vec3` +
          color +
          `,1.);
            } `;
      }

      str += s;
    }

    return init + str;
  }

  function drawPath2(points) {
    let drawInit = "vec3 pos = vPositionW.xyz;";
    let s = "";
    for (let k = 0; k < points.length; k++) {
      let pointsub = points[k];
      s +=
        `if(line(vec2(` +
        pointsub[0].x +
        ` ,` +
        pointsub[0].z +
        `),vec2(` +
        pointsub[1].x +
        ` ,` +
        pointsub[1].z +
        `),pos.xz,1.00)>0.){
            color = vec4(0.,0.,0.,1.);
        }`;
    }
    drawInit += s;
    return drawInit;
  }

  if (pathArray) {
    sphere.material.Fragment_Before_FragColor(drawPath2(points));

    //  for (let i = 0; i < pathArray.length; i++) {
    //
    //     allPath += pathArray[i]._d;
    //    // allPath+= "Z";
    //     console.log("svg path i ->",pathArray[i]._d);
    // }
    // console.log("svg path->",allPath);

    //
    //  }
    //        var path = allPath;

    // console.log(points);

    // function drawMultiplePaths() {
    //     let res = drawPath()
    // }
  }
  store.scene.render();
};

function pointsFromSVG(pathList) {
  // function drawPath(points,tick,color){
  //
  //         var s =  ' tick = float('+tick+');';
  //      for(var i= 0;i< points.length;i++){
  //         // console.log("point x-->",points[i].x);
  //         s+=  `if( length( vec3(float(`+points[i].x+`) ,0.,float(`+points[i].z+`))- vec3(pos.x,0.,pos.z) ) < tick ||
  //         ( float(`+i+`) > 0. &&  line( vec2(float(`+points[i].x+`) ,float(`+points[i].z+`)),
  //              vec2(float(`+points[Math.max(0,i-1)].x+`) ,float(`+points[Math.max(0,i-1)].z+`) ),pos.xz,tick)>0.)){
  //              color = vec4(vec3`+color+`,1.);
  //         } `;
  //      }
  //      return s;
  //     };
  let pointsData = [];
  var paths = pathList;

  for (let pathIndex in paths) {
    let path = paths[pathIndex]["_d"];
    let temp_path1 = [];
    let curPoint1 = null;
    if (!path) continue;
    let j = 0;
    path = path.split(/(?=[LMC])/);
    for (let j = 0; j < path.length; j++) {
      if (path[j][0] == "M" || path[j][0] == "m") {
        let pt = path[j].split(path[j][0])[1].split(" ");
        curPoint1 = new BABYLON.Vector3(
          parseFloat(pt[0]),
          0,
          -parseFloat(pt[1])
        );
        // temp_path1 = [];
        if (temp_path1.length > 1) {
          // let line = BABYLON.MeshBuilder.CreateLines("sketchLine", {points: temp_path1}, store.scene);
          // line.color = new BABYLON.Color3(0, 0, 0);
          // let sketch = {};
          // sketch.points = temp_path1;
          // sketch.mesh = line;
          // sketch.mesh.structure_id = activeLayer.structure_id;
          // sketch.mesh.type = "linesystem";
          // lines.push(sketch);
          //     sphere.material.Fragment_Before_FragColor(`
          //  // line a to b
          //      vec3 pos = vPositionW.xyz;
          //       float tick = 10.;
          //     ` +
          //         drawPath(temp_path1, 4, '(0.,0.,0.)')
          //         + `
          //     `);

          temp_path1 = [];
        }
        temp_path1.push(curPoint1);
      }
      if (path[j][0] == "L" || path[j][0] == "l") {
        let pt = path[j].split(path[j][0])[1].split(" ");
        let pt1 = new BABYLON.Vector3(parseFloat(pt[0]), 0, -parseFloat(pt[1]));
        temp_path1.push(pt1);

        //    sphere.material.Fragment_Before_FragColor(`
        // // line a to b
        //     vec3 pos = vPositionW.xyz;
        //      float tick = 10.;
        //    ` +
        //        drawPath(temp_path1, 4, '(0.,0.,0.)')
        //        + `
        //    `);

        curPoint1 = pt1;
      } else if (path[j][0] == "Q" || path[j][0] == "q") {
        let pt = path[j].split(path[j][0])[1].split(" ");
        let control1 = new BABYLON.Vector3(
          parseFloat(pt[0]),
          0,
          -parseFloat(pt[1])
        );
        let dest1 = new BABYLON.Vector3(
          parseFloat(pt[2]),
          0,
          -parseFloat(pt[3])
        );
        let bezier2 = BABYLON.Curve3.CreateQuadraticBezier(
          curPoint1,
          control1,
          dest1,
          10
        );
        curPoint1 = dest1;
        let temp_pts = bezier2.getPoints();
        for (let k = 0; k < temp_pts.length; k++) {
          temp_path1.push(temp_pts[k]);
        }
        // sphere.material.Fragment_Before_FragColor(`
        //      // line a to b
        //          vec3 pos = vPositionW.xyz;
        //           float tick = 10.;
        //         ` +
        //             drawPath(temp_path1, 4, '(0.,0.,0.)')
        //             + `
        //         `);
      } else if (path[j][0] == "A" || path[j][0] == "a") {
        let pt = path[j].split(path[j][0])[1].split(" ");
        let rx = parseFloat(pt[0]);
        let ry = parseFloat(pt[1]);
        let angle = parseFloat(pt[2]);
        let large_arc = parseFloat(pt[3]);
        let sweep_flag = parseFloat(pt[4]);
        let dest1 = new BABYLON.Vector3(
          parseFloat(pt[5]),
          0,
          -parseFloat(pt[6])
        );
        let temp_pts = pointsOnEllipticalArc(
          curPoint1,
          rx,
          ry,
          angle,
          large_arc,
          sweep_flag,
          dest1,
          10
        );
        curPoint1 = dest1;
        for (let k = 0; k < temp_pts.length; k++) {
          temp_path1.push(temp_pts[k]);
        }
        // sphere.material.Fragment_Before_FragColor(`
        //      // line a to b
        //          vec3 pos = vPositionW.xyz;
        //           float tick = 10.;
        //         ` +
        //             drawPath(temp_path1, 4, '(0.,0.,0.)')
        //             + `
        //         `);
      } else if (path[j][0] == "C" || path[j][0] == "c") {
        let pt = path[j].split(path[j][0])[1].split(" ");
        let control1 = new BABYLON.Vector3(
          parseFloat(pt[0]),
          0,
          -parseFloat(pt[1])
        );
        let control2 = new BABYLON.Vector3(
          parseFloat(pt[2]),
          0,
          -parseFloat(pt[3])
        );
        let dest1 = new BABYLON.Vector3(
          parseFloat(pt[4]),
          0,
          -parseFloat(pt[5])
        );
        let bezier3 = BABYLON.Curve3.CreateCubicBezier(
          curPoint1,
          control1,
          control2,
          dest1,
          10
        );
        curPoint1 = dest1;
        let temp_pts = bezier3.getPoints();
        for (var k = 0; k < temp_pts.length; k++) {
          temp_path1.push(temp_pts[k]);
        }
        // sphere.material.Fragment_Before_FragColor(`
        //      // line a to b
        //          vec3 pos = vPositionW.xyz;
        //           float tick = 10.;
        //         ` +
        //             drawPath(temp_path1, 4, '(0.,0.,0.)')
        //             + `
        //         `);
      } else if (path[j][0] == "Z" || path[j][0] == "z") {
        let pt = path[j].split(path[0][0])[1].split(" ");
        let pt1 = new BABYLON.Vector3(parseFloat(pt[0]), 0, -parseFloat(pt[1]));
        temp_path1.push(pt1);
        //    sphere.material.Fragment_Before_FragColor(`
        // // line a to b
        //     vec3 pos = vPositionW.xyz;
        //      float tick = 10.;
        //    ` +
        //        drawPath(temp_path1, 4, '(0.,0.,0.)')
        //        + `
        //    `);
      }
    }
    pointsData.push(temp_path1);
  }
  // let newPointsArray = [];
  // for(let i=0; i<pointsData.length; i++){
  //     for(let j=0; j<pointsData[i].length; j++){
  //         newPointsArray.push(pointsData[i][j]);
  //     }
  // }
  return pointsData;
}
export {
  findPartition,
  createMassBlocks,
  splitMassIntoStoreys,
  createMassBlocksForStructure,
  xmlToJson,
  pointsOnEllipticalArc,
  createMassBlocksFromData,
  offsetRoomPolygons,
  removeRedundantVertices,
  createCustomMesh,
  createCustomMeshAccordingToNormal,
  getNearestFaceOrientation,
  changeOrderForEarcut,
  _null,
  SvgCalibration,
  dim,
  def,
  GetPoints,
  createSVG,
  pointsFromSVG,
};
