"use strict"
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { JSONToCSVConvertor, isMeshThrowAway, removeComponentFromStructure } from "../modules/extrafunc.js";
import {
  computeAreaMass,
  computeArea,
  computerDoorCAArea,
} from "./areaFuncs.js";
import { makeid } from "./arrayFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { group } from "./selectToolFuncs.js";
import { MeshGroups } from "../modules/constants.module.js";
import { setLayerTransperancy } from "./sceneFuncs.js";
import { ConverMassToComponents, Procedure } from "../modules/wallEngine/snaptrudeEngine.js";
import { createBuildingEngine } from "../modules/createBuilding/createBuildingEngine.js";
import {commandUtils} from "../modules/commandManager/CommandUtils";
import {CommandManager} from "../modules/commandManager/CommandManager";
import { drawSelectionBox, removeMeshSelectionChildren } from "./meshEvents.js";

var areaView = null;

// store.angular.element(function () {
  areaView = (function () {
    // var $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    var structureCollection;
    var selectedRooms = [];
    const init = () => {
      structureCollection = StructureCollection.getInstance(); 
    }
    var _area = null;
    var areas = {
      total_ca: "",
      total_bua: 0,
      total_far: 0,
      settings: {
        bua: { intMult: 0.5, extMult: 1 },
        ca: { intMult: 0, extMult: 0 },
      },
      levels: [],
      site_area: store.$scope.areas.site_area,
      input_fsi: store.$scope.areas.input_fsi
    };
    var getStrucuters = function () {
      return structureCollection.getStructures();
    };

    var getFloorsWithId = function (floors, room_id) {
      let floorsRID = [];
      floors.forEach((f) => {
        if (f.room_id) {
          if (room_id.includes(f.room_id)) {
            floorsRID.push(f);
          }
        }
      });
      return floorsRID;
    };

    var getLevelsInStructure = function (id) {
      let levels = getStrucuters()[id].getAllLevels();
      //console.log(levels)
      return getStrucuters()[id].getAllLevels();
    };

    var exportCSV = function () {
      const fields = ["BUA", "CA", "FAR"];
      let data = [];
      // generateArea();
      for (let i = 0; i < _area.structures.length; i++) {
        let str = _area.structures[i];
        for (let j = 0; j < str.levels.length; j++) {
          let level = str.levels[j];
          for (let k = 0; k < level.rooms.length; k++) {
            let room = level.rooms[k];
            data.push({
              Storey: "",
              "Group Name": "",
              "Room Name": room.name,
              "Built Up Area": room.room_bua.toFixed(2),
              "Carpet Area": room.room_ca.toFixed(2),
              FAR: room.far_included,
            });
          }
          for (let k = 0; k < Object.keys(level.groups).length; k++) {
            let group = level.groups[Object.keys(level.groups)[k]];
            data.push({
              Storey: "",
              "Group Name": "",
              "Room Name": "",
              "Built Up Area": "",
              "Carpet Area": "",
              FAR: "",
            });
            for (let m = 0; m < group.elements.length; m++) {
              let room = group.elements[m];
              data.push({
                Storey: "",
                "Group Name": "",
                "Room Name": room.name,
                "Built Up Area": room.room_bua.toFixed(2),
                "Carpet Area": room.room_ca.toFixed(2),
                FAR: room.far_included,
              });
            }
            data.push({
              Storey: "",
              "Group Name": Object.keys(level.groups)[k] + " Total",
              "Room Name": "",
              "Built Up Area": group.total_bua.toFixed(2),
              "Carpet Area": group.total_ca.toFixed(2),
              FAR: "",
            });
          }
          data.push({
            Storey: level.name,
            "Group Name": "",
            "Room Name": "",
            "Built Up Area": level.level_bua.toFixed(2),
            "Carpet Area": level.level_ca.toFixed(2),
            FAR: level.level_far,
          });
          data.push({
            Storey: "",
            "Group Name": "",
            "Room Name": "",
            "Built Up Area": "",
            "Carpet Area": "",
            FAR: "",
          });
        }
        data.push({
          Storey: "Grand Total",
          "Group Name": "",
          "Room Name": "",
          "Built Up Area": _area.total_bua.toFixed(2),
          "Carpet Area": _area.total_ca.toFixed(2),
          FAR: _area.total_far,
        });
      }
      JSONToCSVConvertor(data.reverse(), "Areas", true);
    };

    var calculateLevelBUA = function () {
      return 0;
    };

    var calculateLevelCA = function () {
      return 0;
    };

    var calculateLevelFAR = function () {
      return 0;
    };

    var calculateRoomBUA = function (object) {
      if (object.type.toLowerCase() === "mass") {
        if (object.mesh) {
          let h = 0.01 / store.unit_absolute_scale / 2;
          return Math.abs(computeAreaMass(object.mesh, h));
        }
      } else if (object.type.toLowerCase() === "floor") {
        if (object.mesh) {
          let h = 0.02 / store.unit_absolute_scale;
          return Math.abs(computeAreaMass(object.mesh, 0));
        }
        // return Math.abs(computeArea(object.mesh));
      } else if (object.type.toLowerCase() === "wall") {
        if (object.mesh) return Math.abs(computeAreaMass(object.mesh, 0));
      }

      return 0;
    };

    var calculateRoomCA = function (object) {
      if (object.type.toLowerCase() === "mass") {
        if (object.mesh) {
          let h = 0.01 / store.unit_absolute_scale / 2;
          return Math.abs(computeAreaMass(object.mesh, -h));
        }
      } else if (object.type.toLowerCase() === "floor") {
        if (object.mesh) {
          let h = 0.02 / store.unit_absolute_scale;
          return Math.abs(computeAreaMass(object.mesh, 0));
        }
      } else if (object.type.toLowerCase() === "wall") {
        if (object.mesh) return Math.abs(computeAreaMass(object.mesh, 0));
      } else if (object.type.toLowerCase() === "door") {
        if (object.mesh) return Math.abs(computerDoorCAArea(object.mesh));
      }
      return 0;
    };

    var calculateRoomFAR = function (ca, bua) {
      if (store.$scope.areas.site_area != null) {
        return (bua / store.$scope.areas.site_area).toFixed(2);
      } else {
        return "NA";
      }
    };

    let getUniqueMassID = function (id, masses) {
      for (let i = 0; i < masses.length; i++) {
        if (masses[i].mesh.room_id === id) {
          return masses[i].mesh.uniqueId;
        }
      }
    };

    let getStoreyNumber = function (storeyNumber, levels) {
      let keys = Object.keys(levels);
      for (let i = 0; i < keys.length; i++) {
        if (levels[keys[i]].value === storeyNumber) {
          return parseInt(keys[i]) + 1;
        }
      }
    };

    var generateArea = function () {
      areas = {
        structures: [],
        total_ca: 0,
        total_bua: 0,
        total_far: 0,
        settings: areas.settings,
        site_area: store.$scope.areas.site_area,
        input_fsi: store.$scope.areas.input_fsi
      };
      //var str_object = {};
      let structures = getStrucuters();

      //console.log("generated structrires============", structures);
      for (let k = 0; k < Object.keys(structures).length; k++) {
        let str_key = Object.keys(structures)[k];
        if (str_key === "default") {
          continue;
        }
        let levels = getLevelsInStructure(str_key);
        let str_object = {
          structure_key: str_key,
          structure_name: "str-" + str_key.split("_")[1],
          levels: [],
        };

        let numberOfStoreys =
          structures[store.activeLayer.structure_id]
            .getStoreyData()
            .getStoreysLength() - 1;
        let storeys = structures[store.activeLayer.structure_id]
          .getStoreyData()
          .getAllStoreys();
        let storeyKeys = Object.keys(storeys);
        for (let i = 0; i < numberOfStoreys; i++) {
          if (!str_object.levels[i]) {
            const storeyName = storeys[storeyKeys[i]].name ? storeys[storeyKeys[i]].name :"Storey(" + storeys[storeyKeys[i]].value + ")"
            str_object.levels.push({
              id: "storey-" + (i + 1),
              name: storeyName,
              value: storeys[storeyKeys[i]].value,
              level_ca: 0,
              level_bua: 0,
              level_far: 0,
              settings: areas.settings,
              rooms: [],
              groups: {},
            });
          }
        }

        //console.log("hi==========",levels);
        for (let j = 0; j < Object.keys(levels).length; j++) {
          let level = levels[Object.keys(levels)[j]];
          let level_bua = 0;
          let level_ca = 0;
          let level_far = 0;
          if (!str_object.levels[j]) {
            str_object.levels.push({
              id: "storey-" + Object.keys(levels)[j] + 1,
              name: "Storey" + Object.keys(levels)[j] + 1,
              level_ca: calculateLevelCA(level),
              level_bua: calculateLevelBUA(level),
              level_far: calculateLevelFAR(level),
              settings: areas.settings,
              rooms: [],
              groups: {},
            });
          }
          let fw = level.flyweight;
          //console.log("flyweight",fw)
          let room_ca = 0;
          let room_bua = 0;
          let room_far = 0;
          // if (fw.masses.length > 0) {
          //     for (let i = 0; i < fw.masses.length; i++) {
          //         let room = fw.masses[i];
          //         room_ca = calculateRoomCA(room);
          //         room_bua = calculateRoomBUA(room);
          //         room_far = calculateRoomFAR(room_ca, room_bua);
          //         level_bua += room_bua;
          //         level_far += room_far;
          //         level_ca += room_ca;
          //         areas.total_bua += room_bua;
          //         areas.total_ca += room_ca;
          //         areas.total_far += room_far;
          //         let storey = room.storey;
          //         if(str_object.levels[storey - 1]){
          //             str_object.levels[storey -1].rooms.push({
          //                 'name': room.mesh.room_type,
          //                 'room_ca': DisplayOperation.convertToDefaultArea(room_ca),
          //                 'room_bua': DisplayOperation.convertToDefaultArea(room_bua),
          //                 'room_far': DisplayOperation.convertToDefaultArea(room_far),
          //                 'uniqueId': room.mesh.uniqueId,
          //                 far_included: true
          //             });
          //             str_object.levels[storey - 1].level_ca += room_ca;
          //             str_object.levels[storey - 1].level_bua += room_bua;
          //         }
          //     }
          // }

          if (fw.floors.length > 0) {
            let iterArray = [...fw.floors, ...fw.walls];
            for (let t = 0; t < iterArray.length; t++) {
              let floor = iterArray[t];
              if (isMeshThrowAway(floor.mesh)) continue;
              room_ca = parseFloat(calculateRoomCA(floor).toFixed(2));
              room_bua = parseFloat(calculateRoomBUA(floor).toFixed(2));
              room_far = parseFloat(calculateRoomFAR(room_ca, room_bua));
              level_bua += room_bua;
              level_far += room_far;
              level_ca += room_ca;

              let storey = floor.mesh.storey;
              storey = getStoreyNumber(storey, str_object.levels);
              if (!storey) continue;
              let supportObject;
              let roomFloors = [];

              let intMultBUA =
                str_object.levels[storey - 1].settings.bua.intMult;
              let extMultBUA =
                str_object.levels[storey - 1].settings.bua.extMult;
              let intMultCA = str_object.levels[storey - 1].settings.ca.intMult;
              let extMultCA = str_object.levels[storey - 1].settings.ca.extMult;

              if (floor.type.toLowerCase() === "wall") {
                floor.assignRoomIds(fw.floors);
                roomFloors = getFloorsWithId(fw.floors, floor.room_id);
              }
              if (floor.mesh.parent) {
                if (floor.mesh.parent.parent) {
                  let pObj = floor.mesh.parent.parent.getSnaptrudeDS();
                  let prid = pObj.room_id;
                  if (!prid) {
                    if (pObj.type.toLowerCase() === "wall") {
                      pObj.assignRoomIds(fw.floors);
                      prid = pObj.room_id;
                    } else {
                      floor.mesh.parent.parent.getSnaptrudeDS().room_id =
                        makeid(3).toLowerCase();
                      prid = floor.mesh.parent.parent.getSnaptrudeDS().room_id;
                    }
                  }
                  roomFloors = getFloorsWithId(fw.floors, prid);
                }
              }
              if (roomFloors && roomFloors.length) {
                supportObject = floor.mesh.uniqueId;
                if (floor.type.toLowerCase() === "wall") {
                  for (let k = 0; k < roomFloors.length; k++) {
                    //roomFloors.length > 1 then it is an internal wall (as it is shared between multiple floors)
                    floor = roomFloors[k];
                    if (isMeshThrowAway(floor.mesh)) continue;

                    let mbua, mca; // Multiplication factors for CA & BUA for internal or external walls
                    if (roomFloors.length === 1) {
                      // If external wall
                      mbua = str_object.levels[storey - 1].settings.bua.extMult;
                      mca = str_object.levels[storey - 1].settings.ca.extMult;
                    } else if (roomFloors.length > 1) {
                      //if internal wall
                      mbua = str_object.levels[storey - 1].settings.bua.intMult;
                      mca = str_object.levels[storey - 1].settings.ca.intMult;
                    }

                    if (str_object.levels[storey - 1]) {
                      if (floor.groupId) {
                        //If any of the floor which wall belongs to is a part of the group -> then area will be added to the group
                        if (
                          str_object.levels[storey - 1].groups[floor.groupId]
                        ) {
                          let group =
                            str_object.levels[storey - 1].groups[floor.groupId];
                          let elemIndex = null;

                          str_object.levels[storey - 1].groups[
                            floor.groupId
                          ].elements.forEach((elem, eIndex) => {
                            if (elem.room_id === floor.room_id) {
                              elemIndex = eIndex;
                            }
                          });
                          if (elemIndex !== null) {
                            str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]["walls"].push(supportObject);
                            str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]["room_ca"] =
                              parseFloat(
                                str_object.levels[storey - 1].groups[
                                  floor.groupId
                                ].elements[elemIndex]["room_ca"]
                              ) +
                              parseFloat(
                                DisplayOperation.convertToDefaultArea(room_ca)
                              ) *
                                mca;
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements[elemIndex]["room_bua"] =
                              parseFloat(
                                str_object.levels[storey - 1].groups[
                                  floor.groupId
                                ].elements[elemIndex]["room_bua"]
                              ) +
                              parseFloat(
                                DisplayOperation.convertToDefaultArea(room_bua)
                              ) *
                                mbua;
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements[elemIndex]["room_far"] =
                              parseFloat(
                                str_object.levels[storey - 1].groups[
                                  floor.groupId
                                ].elements[elemIndex]["room_far"]
                              ) +
                              parseFloat(
                                DisplayOperation.convertToDefaultArea(room_far)
                              );
                            // str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_ca'] = Math.round(str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_ca'] * 100) / 100;
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements[elemIndex]["room_bua"] =
                              Math.round(
                                str_object.levels[storey - 1].groups[
                                  floor.groupId
                                ].elements[elemIndex]["room_bua"] * 100
                              ) / 100;
                            str_object.levels[storey - 1].groups[floor.groupId][
                              "total_bua"
                            ] +=
                              Math.round(
                                (DisplayOperation.convertToDefaultArea(
                                  room_bua
                                ) /
                                  roomFloors.length) *
                                  100
                              ) / 100;
                            // str_object.levels[storey - 1].groups[floor.groupId]['total_ca'] += Math.round(DisplayOperation.convertToDefaultArea(room_ca) / roomFloors.length * 100) / 100;
                          }
                        }
                      } else {
                        let existingRoomIndex = null;
                        str_object.levels[storey - 1].rooms.forEach(
                          (room, roomIndex) => {
                            if (room.room_id === floor.room_id) {
                              existingRoomIndex = roomIndex;
                            }
                          }
                        );
                        if (existingRoomIndex !== null) {
                          // The floor would already be in the list of rooms as floor's area is calculated first
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["walls"].push(supportObject);
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_ca"] =
                            parseFloat(
                              str_object.levels[storey - 1].rooms[
                                existingRoomIndex
                              ]["room_ca"]
                            ) +
                            parseFloat(
                              DisplayOperation.convertToDefaultArea(room_ca)
                            ) *
                              mca;
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_bua"] =
                            parseFloat(
                              str_object.levels[storey - 1].rooms[
                                existingRoomIndex
                              ]["room_bua"]
                            ) +
                            parseFloat(
                              DisplayOperation.convertToDefaultArea(room_bua)
                            ) *
                              mbua;
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_far"] =
                            parseFloat(
                              str_object.levels[storey - 1].rooms[
                                existingRoomIndex
                              ]["room_far"]
                            ) +
                            parseFloat(
                              DisplayOperation.convertToDefaultArea(room_far)
                            );
                          // str_object.levels[storey - 1].rooms[existingRoomIndex]['room_ca'] = Math.round(str_object.levels[storey - 1].rooms[existingRoomIndex]['room_ca'] * 100) / 100;
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_bua"] =
                            Math.round(
                              str_object.levels[storey - 1].rooms[
                                existingRoomIndex
                              ]["room_bua"] * 100
                            ) / 100;
                        }
                      }
                      str_object.levels[storey - 1].level_ca += room_ca * mca;
                      str_object.levels[storey - 1].level_bua +=
                        room_bua * mbua;
                    }
                  }
                } else if (floor.type.toLowerCase() !== "wall") {
                  //If the floor is a support floor for the door & is being shared by multiple rooms
                  for (let k = 0; k < roomFloors.length; k++) {
                    floor = roomFloors[k];
                    if (isMeshThrowAway(floor.mesh)) continue;

                    let mbua, mca; // Multiplication factors for CA & BUA for internal or external walls
                    if (roomFloors.length === 1) {
                      // If external wall
                      // mbua = str_object.levels[storey-1].settings.bua.extMult;
                      // mca = str_object.levels[storey-1].settings.ca.extMult;
                      mbua = 0;
                      mca = 1;
                    } else if (roomFloors.length > 1) {
                      //if internal wall
                      // mbua = str_object.levels[storey-1].settings.bua.intMult;
                      // mca = str_object.levels[storey-1].settings.ca.intMult;
                      mbua = 0;
                      mca = 0.5;
                    }

                    if (str_object.levels[storey - 1]) {
                      if (floor.groupId) {
                        if (
                          str_object.levels[storey - 1].groups[floor.groupId]
                        ) {
                          let group =
                            str_object.levels[storey - 1].groups[floor.groupId];
                          let group_object = {
                            name: floor.room_type ? floor.room_type : "Default",
                            room_ca:
                              Math.round(
                                DisplayOperation.convertToDefaultArea(room_ca) *
                                  mca *
                                  100
                              ) / 100,
                            room_bua: 0,
                            room_far:
                              Math.round(
                                (DisplayOperation.convertToDefaultArea(
                                  room_far
                                ) /
                                  roomFloors.length) *
                                  100
                              ) / 100,
                            uniqueId:
                              getUniqueMassID(floor.room_id, fw.masses) ||
                              floor.mesh.uniqueId,
                            meshes: [],
                            walls: [],
                            level: storey - 1,
                            storey_id: "storey-" + storey,
                            room_id: floor.room_id,
                            far_included:
                              floor.far_included === undefined
                                ? true
                                : floor.far_included,
                          };
                          let elemIndex = null;
                          str_object.levels[storey - 1].groups[
                            floor.groupId
                          ].elements.forEach((elem, eIndex) => {
                            if (elem.room_id === floor.room_id) {
                              elemIndex = eIndex;
                            }
                          });
                          if (elemIndex !== null) {
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements[elemIndex]["meshes"].push(supportObject);
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements[elemIndex]["room_ca"] =
                              parseFloat(
                                str_object.levels[storey - 1].groups[
                                  floor.groupId
                                ].elements[elemIndex]["room_ca"]
                              ) +
                              parseFloat(
                                DisplayOperation.convertToDefaultArea(room_ca)
                              ) *
                                mca;
                            // str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_bua'] = parseFloat(str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_bua']) + parseFloat(DisplayOperation.convertToDefaultArea(room_bua)) * mbua;
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements[elemIndex]["room_far"] =
                              parseFloat(
                                str_object.levels[storey - 1].groups[
                                  floor.groupId
                                ].elements[elemIndex]["room_far"]
                              ) +
                              parseFloat(
                                DisplayOperation.convertToDefaultArea(room_far)
                              ) /
                                roomFloors.length;
                            // str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_ca'] = Math.round(str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_ca'] * 100) / 100;
                            // str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_bua'] = Math.round(str_object.levels[storey - 1].groups[floor.groupId].elements[elemIndex]['room_bua'] * 100) / 100;
                            str_object.levels[storey - 1].groups[floor.groupId][
                              "total_bua"
                            ] += parseFloat(group_object.room_bua);
                            str_object.levels[storey - 1].groups[floor.groupId][
                              "total_ca"
                            ] += parseFloat(group_object.room_ca);
                          } else {
                            str_object.levels[storey - 1].groups[
                              floor.groupId
                            ].elements.push(group_object);
                            str_object.levels[storey - 1].groups[floor.groupId][
                              "total_bua"
                            ] += parseFloat(group_object.room_bua);
                            str_object.levels[storey - 1].groups[floor.groupId][
                              "total_ca"
                            ] += parseFloat(group_object.room_ca);
                          }
                        } else {
                          group = MeshGroups.createNewGroup(floor.groupId);
                          let group_object = {
                            name: floor.room_type ? floor.room_type : "Default",
                            room_ca:
                              DisplayOperation.convertToDefaultArea(room_ca) *
                              mca,
                            room_bua: 0,
                            room_far:
                              DisplayOperation.convertToDefaultArea(room_far) /
                              roomFloors.length,
                            uniqueId:
                              getUniqueMassID(floor.room_id, fw.masses) ||
                              floor.mesh.uniqueId,
                            meshes: [],
                            walls: [],
                            level: storey - 1,
                            storey_id: "storey-" + storey,
                            room_id: floor.room_id,
                            far_included:
                              floor.far_included === undefined
                                ? true
                                : floor.far_included,
                          };
                          str_object.levels[storey - 1].groups[group.id] = {
                            elements: [group_object],
                          };
                          str_object.levels[storey - 1].groups[group.id][
                            "total_bua"
                          ] = parseFloat(group_object.room_bua);
                          str_object.levels[storey - 1].groups[group.id][
                            "total_ca"
                          ] = parseFloat(group_object.room_ca);
                        }
                      } else {
                        let existingRoomIndex = null;

                        str_object.levels[storey - 1].rooms.forEach(
                          (room, roomIndex) => {
                            if (room.room_id === floor.room_id) {
                              existingRoomIndex = roomIndex;
                            }
                          }
                        );
                        if (existingRoomIndex !== null) {
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["meshes"].push(supportObject);
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_ca"] =
                            parseFloat(
                              str_object.levels[storey - 1].rooms[
                                existingRoomIndex
                              ]["room_ca"]
                            ) +
                            parseFloat(
                              DisplayOperation.convertToDefaultArea(room_ca)
                            ) *
                              mca;
                          // str_object.levels[storey - 1].rooms[existingRoomIndex]['room_bua'] = parseFloat(str_object.levels[storey - 1].rooms[existingRoomIndex]['room_bua']) + parseFloat(DisplayOperation.convertToDefaultArea(room_bua)) * mbua;
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_far"] =
                            parseFloat(
                              str_object.levels[storey - 1].rooms[
                                existingRoomIndex
                              ]["room_far"]
                            ) +
                            parseFloat(
                              DisplayOperation.convertToDefaultArea(room_far)
                            );
                            str_object.levels[storey - 1].rooms[existingRoomIndex]['room_bua'] = parseFloat(str_object.levels[storey - 1].rooms[existingRoomIndex]['room_bua']) + parseFloat(DisplayOperation.convertToDefaultArea(room_bua)) * mbua;
                          // str_object.levels[storey - 1].rooms[existingRoomIndex]['room_ca'] = Math.round(str_object.levels[storey - 1].rooms[existingRoomIndex]['room_ca'] * 100) / 100;
                          // str_object.levels[storey - 1].rooms[existingRoomIndex]['room_bua'] = Math.round(str_object.levels[storey - 1].rooms[existingRoomIndex]['room_bua'] * 100) / 100;
                        } else {
                          str_object.levels[storey - 1].rooms.push({
                            name: floor.room_type ? floor.room_type : "Default",
                            room_ca:
                              Math.round(
                                DisplayOperation.convertToDefaultArea(room_ca) *
                                  mca *
                                  100
                              ) / 100,
                            room_bua: 0,
                            room_far:
                              Math.round(
                                (DisplayOperation.convertToDefaultArea(
                                  room_far
                                ) /
                                  roomFloors.length) *
                                  100
                              ) / 100,
                            uniqueId:
                              getUniqueMassID(floor.room_id, fw.masses) ||
                              floor.mesh.uniqueId,
                            meshes: [],
                            walls: [],
                            level: storey - 1,
                            storey_id: "storey-" + storey,
                            room_id: floor.room_id,
                            far_included:
                              floor.far_included === undefined
                                ? true
                                : floor.far_included,
                          });
                        }
                      }
                      str_object.levels[storey - 1].level_ca += room_ca * mca;
                      str_object.levels[storey - 1].level_bua +=
                        room_bua * mbua;
                    }
                  }
                }
              } else if (floor.type === "Floor") {
                if (str_object.levels[storey - 1]) {
                  if (floor.groupId) {
                    if (str_object.levels[storey - 1].groups[floor.groupId]) {
                      let group =
                        str_object.levels[storey - 1].groups[floor.groupId];
                      let group_object = {
                        name: floor.room_type ? floor.room_type : "Default",
                        room_ca: DisplayOperation.convertToDefaultArea(room_ca),
                        room_bua:
                          DisplayOperation.convertToDefaultArea(room_bua),
                        room_far:
                          DisplayOperation.convertToDefaultArea(room_far),
                        uniqueId:
                          getUniqueMassID(floor.room_id, fw.masses) ||
                          floor.mesh.uniqueId,
                        meshes: [],
                        walls: [],
                        level: storey - 1,
                        storey_id: "storey-" + storey,
                        room_id: floor.room_id,
                        far_included:
                          floor.far_included === undefined
                            ? true
                            : floor.far_included,
                      };
                      let elemIndex;
                      str_object.levels[storey - 1].groups[
                        floor.groupId
                      ].elements.forEach((elem, eIndex) => {
                        if (elem.room_id === floor.room_id) {
                          elemIndex = eIndex;
                        }
                      });
                      if (elemIndex) {
                        str_object.levels[storey - 1].groups[
                          floor.groupId
                        ].elements[elemIndex]["meshes"].push(supportObject);
                        str_object.levels[storey - 1].groups[
                          floor.groupId
                        ].elements[elemIndex]["room_ca"] =
                          parseFloat(
                            str_object.levels[storey - 1].groups[floor.groupId]
                              .elements["room_ca"]
                          ) +
                          parseFloat(
                            DisplayOperation.convertToDefaultArea(room_ca)
                          );
                        str_object.levels[storey - 1].groups[
                          floor.groupId
                        ].elements[elemIndex]["room_bua"] =
                          parseFloat(
                            str_object.levels[storey - 1].groups[floor.groupId]
                              .elements["room_bua"]
                          ) +
                          parseFloat(
                            DisplayOperation.convertToDefaultArea(room_bua)
                          );
                        str_object.levels[storey - 1].groups[
                          floor.groupId
                        ].elements[elemIndex]["room_far"] =
                          parseFloat(
                            str_object.levels[storey - 1].groups[floor.groupId]
                              .elements["room_far"]
                          ) +
                          parseFloat(
                            DisplayOperation.convertToDefaultArea(room_far)
                          );
                        str_object.levels[storey - 1].groups[floor.groupId][
                          "total_bua"
                        ] += parseFloat(group_object.room_bua);
                        str_object.levels[storey - 1].groups[floor.groupId][
                          "total_ca"
                        ] += parseFloat(group_object.room_ca);
                      } else {
                        str_object.levels[storey - 1].groups[
                          floor.groupId
                        ].elements.push(group_object);
                        str_object.levels[storey - 1].groups[floor.groupId][
                          "total_bua"
                        ] += parseFloat(group_object.room_bua);
                        str_object.levels[storey - 1].groups[floor.groupId][
                          "total_ca"
                        ] += parseFloat(group_object.room_ca);
                      }
                    } else {
                      group = MeshGroups.createNewGroup(floor.groupId);
                      let group_object = {
                        name: floor.room_type ? floor.room_type : "Default",
                        room_ca: DisplayOperation.convertToDefaultArea(room_ca),
                        room_bua:
                          DisplayOperation.convertToDefaultArea(room_bua),
                        room_far:
                          DisplayOperation.convertToDefaultArea(room_far),
                        uniqueId:
                          getUniqueMassID(floor.room_id, fw.masses) ||
                          floor.mesh.uniqueId,
                        meshes: [],
                        walls: [],
                        level: storey - 1,
                        storey_id: "storey-" + storey,
                        room_id: floor.room_id,
                        far_included:
                          floor.far_included === undefined
                            ? true
                            : floor.far_included,
                      };
                      str_object.levels[storey - 1].groups[group.id] = {
                        elements: [group_object],
                      };
                      str_object.levels[storey - 1].groups[group.id][
                        "total_bua"
                      ] = parseFloat(group_object.room_bua);
                      str_object.levels[storey - 1].groups[group.id][
                        "total_ca"
                      ] = parseFloat(group_object.room_ca);
                    }
                  } else {
                    let existingRoomIndex = null;
                    str_object.levels[storey - 1].rooms.forEach(
                      (room, roomIndex) => {
                        if (room.room_id && room.room_id === floor.room_id) {
                          existingRoomIndex = roomIndex;
                        }
                      }
                    );
                    if (existingRoomIndex !== null) {
                      str_object.levels[storey - 1].rooms[existingRoomIndex][
                        "meshes"
                      ].push(supportObject);
                      str_object.levels[storey - 1].rooms[existingRoomIndex][
                        "room_ca"
                      ] =
                        parseFloat(
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_ca"]
                        ) +
                        parseFloat(
                          DisplayOperation.convertToDefaultArea(room_ca)
                        );
                      str_object.levels[storey - 1].rooms[existingRoomIndex][
                        "room_bua"
                      ] =
                        parseFloat(
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_bua"]
                        ) +
                        parseFloat(
                          DisplayOperation.convertToDefaultArea(room_bua)
                        );
                      str_object.levels[storey - 1].rooms[existingRoomIndex][
                        "room_far"
                      ] =
                        parseFloat(
                          str_object.levels[storey - 1].rooms[
                            existingRoomIndex
                          ]["room_far"]
                        ) +
                        parseFloat(
                          DisplayOperation.convertToDefaultArea(room_far)
                        );
                    } else {
                      str_object.levels[storey - 1].rooms.push({
                        name: floor.room_type ? floor.room_type : "Default",
                        room_ca: DisplayOperation.convertToDefaultArea(room_ca),
                        room_bua:
                          DisplayOperation.convertToDefaultArea(room_bua),
                        room_far:
                          DisplayOperation.convertToDefaultArea(room_far),
                        uniqueId:
                          getUniqueMassID(floor.room_id, fw.masses) ||
                          floor.mesh.uniqueId,
                        meshes: [],
                        walls: [],
                        level: storey - 1,
                        storey_id: "storey-" + storey,
                        room_id: floor.room_id,
                        far_included:
                          floor.far_included === undefined
                            ? true
                            : floor.far_included,
                      });
                    }
                  }

                  str_object.levels[storey - 1].level_ca += room_ca;
                  str_object.levels[storey - 1].level_bua += room_bua;
                }
              }
            }
          }
        }

        for (let j = 0; j < numberOfStoreys; j++) {
          areas.total_bua += str_object.levels[j].level_bua;
          areas.total_ca += str_object.levels[j].level_ca;

          str_object.levels[j].level_ca = DisplayOperation.convertToDefaultArea(
            str_object.levels[j].level_ca
          );
          str_object.levels[j].level_bua =
            DisplayOperation.convertToDefaultArea(
              str_object.levels[j].level_bua
            );
          str_object.levels[j].level_far = calculateRoomFAR(
            str_object.levels[j].level_ca,
            str_object.levels[j].level_bua
          );

          str_object.levels[j].level_ca_far = str_object.levels[j].level_ca;
          str_object.levels[j].level_bua_far = str_object.levels[j].level_bua;
        }
        let levelIndexArray = [];
        str_object.levels.forEach(function (level) {
          levelIndexArray.push(level.value);
        });
        let len = levelIndexArray.length;
        let indices = new Array(len);
        for (var i = 0; i < len; ++i) indices[i] = i;
        indices.sort(function (a, b) {
          return levelIndexArray[a] > levelIndexArray[b]
            ? -1
            : levelIndexArray[a] > levelIndexArray[b]
            ? 1
            : 0;
        });
        let levelIndexArraySorted = [];
        indices.forEach(function (index) {
          levelIndexArraySorted.push(str_object.levels[index]);
        });
        str_object.levels = levelIndexArraySorted;
        areas.structures.push(str_object);
      }

      areas.total_ca = DisplayOperation.convertToDefaultArea(areas.total_ca);
      areas.total_bua = DisplayOperation.convertToDefaultArea(areas.total_bua);
      areas.total_ca_far = areas.total_ca;
      areas.total_bua_far = areas.total_bua;
      if (store.$scope.areas.site_area != null) {
        let far_bua = 0;
        areas.structures.forEach((s) => {
          s.levels.forEach((l) => {
            l.rooms.forEach((r) => {
              if (r.far_included) {
                far_bua += parseFloat(r.room_bua);
              }
            });
          });
        });
        areas.total_far = parseFloat((far_bua / store.$scope.areas.site_area).toFixed(2));
      } else {
        areas.total_far = "NA";
      }

      // setTimeout(function () {
      //   $scope.$apply(function () {
      //     $scope.areas = areas;
      //   });
      // });
      store.$scope.areas = areas; 
      // console.log(areas);
      _area = areas;
      setLayerTransperancy();
    };

    let _resetOperation = function () {
      // if ($scope.areas.structures.length >= 1) {
      //   let timId = setTimeout(function () {
      //     $scope.$apply(function () {
      //       $scope.selectedAreaRooms = [];
      //       $scope.selectedAreaGroups = [];
      //       $scope.areas.structures[0].levels.forEach((level) => {
      //         level.rooms.forEach((room) => (room.selected = false));
      //         _.forEach(level.groups, (group) => {
      //           group.selected = false;
      //           group.elements.forEach((grpRoom) => {
      //             grpRoom.selected = false;
      //           });
      //         });
      //       });
      //     });
      //     clearTimeout(timId);
      //   }, 0);
      // }
    };

    const farChanged = (structure, room, far_included, level, command = true) => {
      let mesh = store.scene.getMeshByUniqueID(room.uniqueId);
      let mesdDs = mesh.getSnaptrudeDS();
      let data = {};
      let stack = [];
      stack.push(mesh);
      store.$scope.areas.structures.forEach((str) => {
        if (str.structure_key === structure) {
          str.levels.forEach((lvl) => {
            if (lvl.id === level.id) {

              if (far_included === false) {
                // lvl.level_ca_far = (parseFloat(lvl.level_ca_far) - parseFloat(room.room_ca)).toFixed(2);
                lvl.level_bua_far = (parseFloat(lvl.level_bua_far) - parseFloat(room.room_bua)).toFixed(2);
                // store.$scope.areas.total_ca_far = (parseFloat(store.$scope.areas.total_ca_far) - parseFloat(room.room_ca)).toFixed(2);
                store.$scope.areas.total_bua_far = (parseFloat(store.$scope.areas.total_bua_far) - parseFloat(room.room_bua)).toFixed(2);
                if (store.$scope.areas.site_area) {
                  store.$scope.areas.total_far = (store.$scope.areas.total_bua_far / store.$scope.areas.site_area).toFixed(2);
                } else {
                  store.$scope.areas.total_far = 'NA';
                }
                lvl.level_far = parseFloat(lvl.level_bua_far / store.$scope.areas.site_area).toFixed(2);
                mesdDs.far_included = false;
              } else {
                // lvl.level_ca_far = (parseFloat(lvl.level_ca_far) + parseFloat(room.room_ca)).toFixed(2);
                lvl.level_bua_far = (parseFloat(lvl.level_bua_far) + parseFloat(room.room_bua)).toFixed(2);
                // store.$scope.areas.total_ca_far = (parseFloat(store.$scope.areas.total_ca_far) + 0*parseFloat(room.room_ca)).toFixed(2);
                store.$scope.areas.total_bua_far = (parseFloat(store.$scope.areas.total_bua_far) + parseFloat(room.room_bua)).toFixed(2);
                if (store.$scope.areas.site_area) {
                  store.$scope.areas.total_far = (store.$scope.areas.total_bua_far / store.$scope.areas.site_area).toFixed(2)
                } else {
                  store.$scope.areas.total_far = "NA";
                }
                lvl.level_far = parseFloat(lvl.level_bua_far / store.$scope.areas.site_area).toFixed(2);

                mesdDs.far_included = true;
              }

              let optionsForFarChange = {
                meshKeys: ["far_included"],
                componentKeys: ["far_included"]
              };

              function _changePropertiesOfMesh(mesh, value) {
                mesh.far_included = value;
                mesh.getSnaptrudeDS().far_included = value;
              }


              let farChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(stack, optionsForFarChange);
              optionsForFarChange.data = farChangeCommandData;

              stack.forEach(m => _changePropertiesOfMesh(m, far_included));


              farChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(stack, optionsForFarChange);
              var farChangeCommand = commandUtils.propertyChangeOperations.getCommand("farChangeCreate", farChangeCommandData);

              if (command) {
                // let farGroupCommand = new Command('deleteFarCommand', data, getDeleteCommandLogic());
                // CommandManager.execute([farChangeCommand, farGroupCommand], [false, false]);
                CommandManager.execute(farChangeCommand, false);
                CommandManager.popLastItemFromExecuted();
              }
            }
          })
        }
      });

    }

    const selectRoom = (id, selected, room) => {
      //Todo: Select the mass here
      let mesh = store.scene.getMeshByUniqueID(id);
      if (selected) {
        // $(element.target).css('border', '1px solid #d30041');
        store.selectionStack.push(mesh);
        mesh.state = "on";
        drawSelectionBox(mesh);
        if (room.groupId) {
            return;
        }
        let multipleLevels = false;
        selectedRooms.push(room);
        selectedRooms.forEach((selectedRoom, index) => {
            if (selectedRoom.level !== room.level) {

                multipleLevels = true;
                // $('#create-group').prop("disabled", true);
            } else {
                if (!multipleLevels) {
                    // $('#create-group').prop("disabled", false);
                }
            }
        });

      } else {
        //console.log("not selected");
        for (let i = 0; i < store.selectionStack.length; i++) {
            if (store.selectionStack[i].name === mesh.name) {
                // $(element.target).css('border', 'none');
                mesh.state = "off";
                store.selectionStack.splice(i, 1);
                removeMeshSelectionChildren(mesh);
                break;
            }
        }

        if (!room.groupId) {
            for (let j = 0; j < selectedRooms.length; j++) {
                if (selectedRooms[j].uniqueId == room.uniqueId) {
                    selectedRooms.splice(j, 1);
                    console.log(selectedRooms);
                    break;
                }
            }

            let levels = _.map(selectedRooms, 'level');
            if ((_.uniq(levels).length > 1)) {
                // $('#create-group').prop("disabled", true);
            } else {
                // $('#create-group').prop("disabled", false);
            }

        }
      }
    };

    return {
      init,
      getStrucuters: function () {
        return getStrucuters();
      },
      exportCSV: function () {
        return exportCSV();
      },
      generateArea: function () {
        return generateArea();
      },
      areas: _area,
      resetOperation: function () {
        return _resetOperation();
      },
      farChanged,
      selectRoom,
    };
  })();
// });

const updateArea = async function (expanded = true) {
  
  if (expanded) {
    //setting true as Areas accordin is open
    
    const _componentGenerator = async function (concernedMasses) {
      let procedure = new Procedure();
      let proc = new ConverMassToComponents();
      await procedure.constructForAreas(proc, concernedMasses);
    };
    
    const _componentProcessor = function (componentsCreated) {
      componentsCreated.forEach(component => {
        removeComponentFromStructure(component);
        component.mesh.dispose();
      })
    };
    
    try {
      createBuildingEngine.init();
      await createBuildingEngine.generateComponents(_componentGenerator);
      areaView.generateArea();
    } catch (err) {
      console.log(err);
    }
    
    createBuildingEngine.processCreatedComponents(_componentProcessor);
    
    store.$scope.areasUpdateNeeded = true;
  } else {
    //setting false as Areas accordin is closed here
    store.$scope.areasUpdateNeeded = false;
  }
};

export { areaView, updateArea };
