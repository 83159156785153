import {scenePickController} from "../utilityFunctions/scenePickController";
import {findSecondarySnappedPoint} from "../../libs/snapFuncsPrimary";
import _ from "lodash";
import {areEdgesSame, getDynamicThreshold, isCADMesh} from "../../libs/snapUtilities";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray} from "../../libs/arrayFuncs";
import {getDistanceBetweenVectors} from "../extrafunc";
import BABYLON from "../babylonDS.module";
import { store } from "../utilityFunctions/Store";
import {geometryUpdater} from "../sketchMassBIMIntegration/geometryUpdater";
import {projectionOfPointOnLine} from "../../libs/snapFuncs";
import snapEngine from "./snapEngine";
import {GLOBAL_CONSTANTS} from "../utilityFunctions/globalConstants";

const alternateSnaps = (function (){
  
  let _alternateSnapsData = null;
  let _snapCurrentlyInUse = null;
  
  const _addAndSortSnapData = function (nonRedundantSnapsMetadataGroupedAccordingToPickedMesh, currentPick){
    
    // Step 3, identify snaps to width edge of walls and modify them so that it snaps to edge midpoint
    
    /*
    Pushing new elements during forEach ->
    "The range of elements processed by forEach is set before the first call to callbackfn.
    Elements which are appended to the array after the call to forEach begins will not be visited by callbackfn."
    
    https://262.ecma-international.org/5.1/#sec-15.4.4.18
     */
    
    _.forEach(nonRedundantSnapsMetadataGroupedAccordingToPickedMesh, (snapDataPoints, uniqueId) => {
        snapDataPoints.forEach((data) => {
          if (data.pickInfo.pickedMesh.type.toLowerCase() === "wall" && data.snappedToEdge && !data.snappedToEdgeMidpoint){
            const edgeLength = getDistanceBetweenVectors(data.snappedToEdge.headPt, data.snappedToEdge.tailPt);
            const widthLength = data.pickInfo.pickedMesh.getSnaptrudeDS().calculateWidth();
            
            if (edgeLength === widthLength){
              data.pickInfo.pickedPoint = BABYLON.Vector3.Center(data.snappedToEdge.headPt, data.snappedToEdge.tailPt);
              data.snappedToEdgeMidpoint = true;
            }
          }
        });
    });
    
    // Step 4, sort the snaps according to the priority order
    // https://www.evernote.com/shard/s368/sh/c79e706e-10a3-a8eb-6cd6-f7b967cdccda/8b6ce7577f8b33cc67687400c2d86ff6
  
    _.forEach(nonRedundantSnapsMetadataGroupedAccordingToPickedMesh, (snapDataPoints, uniqueId) => {
    
      const meshType = snapDataPoints[0].pickInfo.pickedMesh.type.toLowerCase();
      snapDataPoints.sort((data1, data2) => {
      
        if (data1.snappedToVertex){
          if (data2.snappedToVertex) {
            const d1 = getDistanceBetweenVectors(data1.snappedToVertex, currentPick.pickedPoint);
            const d2 = getDistanceBetweenVectors(data2.snappedToVertex, currentPick.pickedPoint);
            
            if (d1 < d2) return -1;
            else return 1;
          }
          else if (data2.snappedToEdge){
            if (meshType === "floor") return 1;
            else if (meshType === "wall" && data2.snappedToEdgeMidpoint) return 1;
            else return -1;
          }
          else if (data2.snappedToFace) return -1;
        }
        else if (data1.snappedToEdge){
          if (data2.snappedToVertex){
            if (meshType === "floor") return -1;
            else if (meshType === "wall" && data1.snappedToEdgeMidpoint) return -1;
            else return 1;
          }
          else if (data2.snappedToEdge){
            if (data1.snappedToEdgeMidpoint && data2.snappedToEdgeMidpoint) return 0;
            else if (data1.snappedToEdgeMidpoint) return -1;
            else if (data2.snappedToEdgeMidpoint) return 1;
            else return 0;
          }
          else if (data2.snappedToFace) return -1;
        }
        else if (data1.snappedToFace){
          if (data2.snappedToFace) return 0;
          else return 1;
        }
      });
    });
    
    // Step 5, sort the snaps according to the priority order of meshes (CAD meshes go last)
    
    const allSnapsMetadata = _.flatMap(nonRedundantSnapsMetadataGroupedAccordingToPickedMesh);
    
    allSnapsMetadata.sort((data1, data2) => {
      
      const isData1Cad = isCADMesh(data1.pickInfo.pickedMesh);
      const isData2Cad = isCADMesh(data2.pickInfo.pickedMesh);
      
      if (isData1Cad && isData2Cad) return 0;
      else if (isData1Cad) return 1;
      else if (isData2Cad) return -1;
      else return 0;
      
    });
    
    return allSnapsMetadata;
  };
  
  const _uniqueSnapDataComparator = function (d1, d2){
    if (d1.pickInfo.pickedMesh === d2.pickInfo.pickedMesh){
      if (d1.snappedToVertex && d2.snappedToVertex){
        return d1.snappedToVertex.almostEquals(d2.snappedToVertex);
      }
      else if (d1.snappedToEdge && d2.snappedToEdge){
        if (areEdgesSame(d1.snappedToEdge, d2.snappedToEdge)){
          return d1.snappedToEdgeMidpoint === d2.snappedToEdgeMidpoint;
        }
        else {
          return false;
        }
      }
      else if (d1.snappedToFace && d2.snappedToFace){
        return d1.snappedToFaceId === d2.snappedToFaceId;
      }
    }
    else return false;
  };
  
  const _getUniqueSnapData = function (snappingData){
    return _.uniqWith(snappingData, _uniqueSnapDataComparator);
  };
  
  const _pruneSnapData = function (snappingMetadataForAllPicks){
    
    // Step 1 - no filters are put in multi picks
    // so multiple picks of the same mesh and same vertex/edge/face is possible, remove them
    
    const uniqueSnappingMetadata = _getUniqueSnapData(snappingMetadataForAllPicks);
    
    const groupedAccordingToPickedMesh = _.groupBy(
      uniqueSnappingMetadata,
      "pickInfo.pickedMesh.uniqueId"
    );
    
    // Step 2 - if a vertex/edge is picked, pick of that particular face is redundant, remove those
    
    // this step is applicable only in 2D
    
    if (store.$scope.isTwoDimension){
      _.forEach(groupedAccordingToPickedMesh, (snapDataPoints, uniqueId) => {
        const nonRedundantMetadata = snapDataPoints.filter(data1 => {
          let redundant = false;
          snapDataPoints.some(data2 => {
            if (data1 === data2) return;
            
            if (data1.snappedToFace){
              if (data2.snappedToVertex){
                if (data1.snappedToFace.inArray(v => v.almostEquals(data2.snappedToVertex))){
                  redundant = true;
                }
              }
              else if (data2.snappedToEdge){
                if (data1.snappedToFace.inArray(v => v.almostEquals(data2.snappedToEdge.headPt))
                    && data1.snappedToFace.inArray(v => v.almostEquals(data2.snappedToEdge.tailPt))){
                  redundant = true;
                }
              }
            }
            
            return redundant;
          });
          
          return !redundant;
        });
        
        groupedAccordingToPickedMesh[uniqueId] = nonRedundantMetadata;
      });
    }
    
    return groupedAccordingToPickedMesh;
  };
  
  const _gatherAlternateSnapOptions = function (currentPick){
    
    const _getSnapData = function (pickInfo, onlyFace){
      const options = {
        vertexSnap: !onlyFace,
        edgeSnap: !onlyFace,
        faceSnap: true,
        pickInfo: pickInfo,
        doNotDoSecondaryScenePicks: true,
        doNotShowIndicators: true,
      };
      
      findSecondarySnappedPoint(null, null, null, options);
      
      return options.metadata;
    };
    
    const referencePoint = currentPick.pickedPoint;
    const allPicks = scenePickController.multiPick(null, {
      referencePoint,
    });
    
    let snappingMetadataForAllPicksOnlyFace = allPicks.map(p => _getSnapData(p, true));
    
    /*const currentPickSnapDataOnlyFace = _getSnapData(currentPick, true);
    const currentPickSnapFaceVertices = currentPickSnapDataOnlyFace.snappedToFace;*/
    
    snappingMetadataForAllPicksOnlyFace = _getUniqueSnapData(
      snappingMetadataForAllPicksOnlyFace.filter((d) => d.snappedSecondarily && d.snappedToFace));
    
    const facePicks = snappingMetadataForAllPicksOnlyFace.map(data => data.pickInfo);
    const nonFacePicks = [];
    
    const threshold = getDynamicThreshold(currentPick.pickedPoint) * 4;
    
    snappingMetadataForAllPicksOnlyFace.forEach((snapData) => {
      const pickInfo = snapData.pickInfo;
      const faceVertices = snapData.snappedToFace;
      faceVertices.forEach((v) => {
        
        let checkPoint = referencePoint.clone();
        if (store.$scope.isTwoDimension) checkPoint.y = v.y;
        
        if (getDistanceBetweenVectors(v, checkPoint) < threshold){
          const pickClone = geometryUpdater.util.getFakePickInfo(
            v,
            {
              mesh: pickInfo.pickedMesh,
            },
            pickInfo.faceId
          );
          
          nonFacePicks.push(pickClone);
        }
        
        const nextVertex = getCircularlyNextElementInArray(faceVertices, v);
        
        const projection = projectionOfPointOnLine(checkPoint, v, nextVertex);
        const dFromEdge = getDistanceBetweenVectors(checkPoint, projection);
        
        if (dFromEdge < threshold){
          const edgeMidPoint = BABYLON.Vector3.Center(v, nextVertex);
        
          let pickedPoint = projection;
          if (getDistanceBetweenVectors(edgeMidPoint, projection) < threshold){
            pickedPoint = edgeMidPoint;
          }
          
          const pickClone = geometryUpdater.util.getFakePickInfo(
            pickedPoint,
            {
              mesh: pickInfo.pickedMesh,
            },
            pickInfo.faceId
          );
          
          nonFacePicks.push(pickClone);
        }
        
        
      });
      
    });
    
    const snappingMetadataForAllPicksNonFace = nonFacePicks.map(p => _getSnapData(p, false));
    
    const { cadSnappedPoint, cadPickInfo, closeVertices } = snapEngine.cadSnaps.getSnapPoint(null, null, true);
    
    if (cadSnappedPoint) {
      closeVertices.forEach(vertex => {
        
        const snappingMetadata = {
          pickInfo: geometryUpdater.util.getFakePickInfo(vertex, { mesh: cadPickInfo.pickedMesh }),
          snappedToVertex: vertex,
          snappedToCadVertex: true,
          snappedSecondarily: true,
          secondarySnapType: GLOBAL_CONSTANTS.strings.snaps.vertex,
        };
        
        snappingMetadataForAllPicksNonFace.push(snappingMetadata);
      });
    }
    
    const snappingMetadataForAllPicks = _.concat(snappingMetadataForAllPicksOnlyFace, snappingMetadataForAllPicksNonFace);
    
    const nonRedundantSnapsMetadataGroupedAccordingToPickedMesh = _pruneSnapData(snappingMetadataForAllPicks);
    const sortedSnapsMetadata = _addAndSortSnapData(nonRedundantSnapsMetadataGroupedAccordingToPickedMesh, currentPick);
    
    _alternateSnapsData = sortedSnapsMetadata;
    
    const currentPickSnapData = _getSnapData(currentPick, false);
    
    let currentIndex = _.findIndex(sortedSnapsMetadata, (data) =>
      _uniqueSnapDataComparator(data, currentPickSnapData)
    );
    
    // Above logic identified where the current pick fell on the priority list and tabbed to the next one
    // But it's better to tab to the highest priority snap right away
    // And if current snap is the highest priority, then the next
    
    if (currentIndex === 0) {
      _snapCurrentlyInUse = _.first(_alternateSnapsData);
      // current snap already highest priority
      // tab goes to next highest priority aka [1]
    }
    else {
      _snapCurrentlyInUse = _.last(_alternateSnapsData);
      // tab goes to highest priority aka [0]
    }
    
  };
  
  const getNext = function (currentPick, getPreviousSnap){
    
    if (!_alternateSnapsData) _gatherAlternateSnapOptions(currentPick);
    
    let nextSnap;
    if (getPreviousSnap){
      nextSnap = getCircularlyPreviousElementInArray(
        _alternateSnapsData,
        _snapCurrentlyInUse
      );
    }
    else {
      nextSnap = getCircularlyNextElementInArray(
        _alternateSnapsData,
        _snapCurrentlyInUse
      );
    }
    
    _snapCurrentlyInUse = nextSnap;
    
    return nextSnap;
  };
  
  const invalidate = function (){
    _alternateSnapsData = null;
    _snapCurrentlyInUse = null;
  };
  
  const isActive = function (){
    return !!_alternateSnapsData;
  };
  
  return {
    getNext,
    invalidate,
    isActive,
  };
  
})();

export default alternateSnaps;