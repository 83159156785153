"use strict";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import {store} from "../utilityFunctions/Store.js";
import {
  areThreeVectorsCollinear,
  areTwoLinesCollinear,
  areVectorsParallel,
  convertGlobalCoordsToLocal,
  convertGlobalVector3ToLocal,
  deepCopyObject,
  getBabylonPlane,
  getDistanceBetweenVectors,
  getRoomTypeProperties,
  isRoomOfType,
  isRoomOfTypeBalcony,
  metresToSnaptrudeUnits,
  removeCollinearVertices,
} from "../extrafunc.js";
import {virtualSketcher} from "../sketchMassBIMIntegration/virtualSketcher.js";
import {
  getBottomFaceVertices,
  getEdgeAdjacentFace,
  getEdgeObjectsAroundTheFace,
  getFaceVerticesFromFace,
  getPositionFromVertex,
  getTopFaceVertices,
  getVerticesFromEdgeObject,
} from "../../libs/brepOperations.js";
import {
  areFloatsAlmostEqual,
  getNormalVector,
  getUnitNormalVectorV3,
  getUnitNormalVectorV3CyclicCheck,
} from "../../libs/mathFuncs.js";
import {
  getAngleBetweenVectors,
  isFloatEqual,
  getAngleInRadians,
  projectionOfPointOnLine,
} from "../../libs/snapFuncs.js";
import {ResolveEngineUtils} from "../wallEngine/resolveEngine.js";
import {StructureCollection} from "../snaptrudeDS/structure.ds.js";
import {adjustHeightOfHalfRoofs} from "../factoryTypes/roof.types.js";
import {ScopeUtils} from "../../libs/scopeFunctions.js";
import {StoreyMutation} from "../storeyEngine/storeyMutations.js";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray, makeid} from "../../libs/arrayFuncs.js";
import {areLinesSimilar, degreeToRadian} from "../../libs/snapUtilities.js";
import {externalUtil} from "../externalUtil.js";
import {dimensionsTuner} from "../sketchMassBIMIntegration/dimensionsTuner";
import {offsetRoomPolsRoof} from "../../libs/wall_generation";
import {commandUtils} from "../commandManager/CommandUtils";

/**
 * Responsible for creating walls, floors and roofs from a non-curved mass
 * */

const snapmda = window.snapmda;

const massDissector = (function () {
  const CONSTANTS = {
    duplicatePointThreshold: 1e-1,
    preGenerationEditTypes: {
      scaleDown: "scaleDown",
    },
    wallTypes: {
      internal: "Int",
      external: "Ext",
      parapet: "Parapet",
    },
    wall: "wall",
    floor: "floor",
    roof: "roof",
  };

  // data accumulates after each dissect and used in handleWallJunctions
  const metadataPersist = {
    wallJunctionData: {
      nodeIdWallDataMap: new Map(), // string to array of wall data map
      nodeIdJunctionOccupierMap: new Map(), // string to array of wall data map
      irregularWalls: [],
    },
    wallResolutionData: {
      edgeIdOccupierMap: new Map(), // prefix - added for inverted walls like water body; string to wall data map
      edgeIdAllWallsMap: new Map(),
    },
    internalDimensionData: {
      nodeLabelToMasterNodeLabelMap: new Map(),
    },

    // TODO - Keep a uniform pattern in maps
  };

  // overwritten after each dissect, data used in getWallPoints only
  const metadata = {
    wallThickness: null,
    roomTypeProperties: null,
    faceObjectAfterCBDataMap: new Map(),
  };

  const debugData = [];

  let _meshOfInterest, _componentOfInterest;

  const dissect = function (mesh) {
    const dissection = {
      walls: [],
      floors: [],
      roofs: [],
    };

    _meshOfInterest = mesh;
    _componentOfInterest = _meshOfInterest.getSnaptrudeDS();
    metadata.wallThickness = _meshOfInterest.wallThickness;

    let roomTypeProperties = getRoomTypeProperties(_meshOfInterest.room_type);
    if (!roomTypeProperties) {
      roomTypeProperties = { mass: {}, bim: {} };
    }

    metadata.roomTypeProperties = roomTypeProperties;

    try {
      const mass = _componentOfInterest;
      const facesData = _populateMetadata(mass);

      facesData.forEach(data => {
        metadata.faceObjectAfterCBDataMap.set(data.face, data);
      });

      facesData.forEach((faceData) => {
        let vertices = faceData.vertices;
        let verticesArray = vertices.map((v3) => [v3.x, v3.z, v3.y]);
        let type = faceData.type;
        let massOccurenceCount = {},
          count = 0;

        if (type === CONSTANTS.wall){

          const options = {
            faceObject: faceData.face
          };
          if(vertices.length>4) //this must be an arc case
          {
            for(var i=0, j = vertices.length-1;i<(vertices.length/2 -1); i++, j--)
            {
              let verts = [vertices[i], vertices[i+1], vertices[j-1], vertices[j]];
              let wallPoints = getWallPoints(verts, null, options);
              if (wallPoints) 
                dissection.walls.push(wallPoints);
            }
          }
          else
          {
            const wallPoints = getWallPoints(vertices, null, options);
            if (wallPoints) dissection.walls.push(wallPoints);
          }
        } else if (type === CONSTANTS.floor){

          dissection.floors.push(getFloorPoints(vertices));

          // Grade Slab
          // Check to see if a slab is required below the mass
          vertices.forEach((vertex) => {
            let components =
              virtualSketcher.structuralLookup(vertex).components;
            components.forEach((component) => {
              if (component.storey === mass.storey) return;

              if (!massOccurenceCount[component.id])
                massOccurenceCount[component.id] = 1;
              else massOccurenceCount[component.id]++;
            });
          });
          for (let key in massOccurenceCount)
            if (massOccurenceCount[key] === vertices.length) count++;
          if (count < 1) {
            // else there's a mass directly beneath the current mass, so no grade slab required
              dissection.roofs.push(getRoofPoints(vertices, true));
          }
        } else if (type === CONSTANTS.roof){
          dissection.roofs.push(getRoofPoints(vertices, false));
        } else {
          // face won't become anything
          // eg - wall faces of a deck
        }

      });

      _meshOfInterest.convertedToComponents = true;

      return dissection;
    } catch (e) {
      console.warn("Room generation failed for a mass");
      console.log(e);
      _meshOfInterest.convertedToComponents = false;
    }
  };

  function _populateMetadata(mass) {
    function _assignType(faceData, type) {
      switch (type) {
        case CONSTANTS.wall:
          if (roomTypeBIMProperties.noWalls) return;
          break;
        case CONSTANTS.roof:
          if (roomTypeBIMProperties.noRoofs) return;
          break;
        case CONSTANTS.floor:
          if (roomTypeBIMProperties.noFloors) return;
          break;
      }

      faceData.type = type;
    }

    let invertVertices = false;

    const bottomFaceVertices = getBottomFaceVertices(mass);
    let normal = getUnitNormalVectorV3CyclicCheck(bottomFaceVertices);

    if (normal.almostEquals(BABYLON.Vector3.Up())) invertVertices = true;

    const brep = mass.brep;
    let roofAngleThresold = 45;

    let precision = 1e-3;

    const facesData = [];
    let minAngle = 10000;
    brep.getFaces().forEach((face) => {
      let vertices = getFaceVerticesFromFace(
        face,
        _meshOfInterest,
        BABYLON.Space.WORLD
      );
      if (invertVertices) vertices.reverse();
      let unitNormalVector = getUnitNormalVectorV3CyclicCheck(vertices);

      if (normal) {
        let angleNormalMakesWithY = getAngleBetweenVectors(
          unitNormalVector,
          BABYLON.Vector3.Up().negate()
        );
        minAngle = Math.min(minAngle, angleNormalMakesWithY);
        facesData.push({ face, angleNormalMakesWithY, vertices });
      } else {
        facesData.push({ face, NaN, vertices });
      }
    });

    let roomTypeBIMProperties = metadata.roomTypeProperties.bim;

    facesData.forEach((faceData) => {
      let angle = faceData.angleNormalMakesWithY;
      if (angle < roofAngleThresold || angle > 180 - roofAngleThresold) {
        let face = faceData.face;
        /*let vertices = getFaceVerticesFromFace(face, _meshOfInterest, BABYLON.Space.WORLD);
                for (let i=0; i<vertices.length; i++){
                    if ((vertices[i].y - lowerHeight) < precision) {
                        faceData.face.type = CONSTANTS.floor;
                    }
                }
                if (!faceData.face.type) faceData.face.type = CONSTANTS.roof;*/
        let vertexObjects = snapmda.FaceVertices(face);
        if (invertVertices) vertexObjects.reverse();
        let verticesOnFace = faceData.vertices;
        let faceNormal = getUnitNormalVectorV3CyclicCheck(verticesOnFace);
        if (faceNormal.almostEquals(BABYLON.Vector3.Up()) || angle == 180)
        {
           _assignType(faceData, CONSTANTS.roof); return;
        }
        vertexObjects.some((vertex) => {
          let faceIsFloor = true;
          let neighbours = snapmda.VertexNeighbors(vertex);
          let vertexPos = getPositionFromVertex(
            vertex,
            _meshOfInterest,
            BABYLON.Space.WORLD
          );

          let neighboursNotOnFace = [];
          neighbours.forEach((n) => {
            let onYourFace = false;
            let neighbourVertexGlobal = getPositionFromVertex(
              n,
              _meshOfInterest,
              BABYLON.Space.WORLD
            );
            for (let i = 0; i < verticesOnFace.length; i++) {
              if (
                verticesOnFace[i].almostEquals(neighbourVertexGlobal, precision)
              ) {
                onYourFace = true;
                break;
              }
            }

            if (!onYourFace) neighboursNotOnFace.push(neighbourVertexGlobal);
          });

          neighboursNotOnFace.forEach((neighbour) => {
            if (
              neighbour.y <= vertexPos.y ||
              areFloatsAlmostEqual(neighbour.y, vertexPos.y, precision)
            ) {
              faceIsFloor = false;
            }
          });

          if (faceIsFloor) {
            _assignType(faceData, CONSTANTS.floor);
            return true;
          }
        });

        if (!faceData.type) _assignType(faceData, CONSTANTS.roof);
      } else {
        _assignType(faceData, CONSTANTS.wall);
      }
    });

    return facesData;
  }
  
  /**
   * Dumb offset is just regular offset of points
   * It moves all the edges
   *
   * Smart offset excludes edges based on any criterion
   * Here, it's shared edges
   *
   * @param vectors
   * @param offsetAmount
   * @private
   */
  function _smartOffset(vectors, offsetAmount, isRoofOffset){
    
    /*
    Decks are weird because they generate no walls but they generate floors and slabs
    Other no wall types don't generate floors and slabs as well
     */
    const _isDeck = function (component) {
      return isRoomOfType(component.mesh, "deck");
    }
    
    const _shouldEdgeBeMoved = function (v1, v2){
      const edge = virtualSketcher.lookupEdge(v1, v2);
      if (edge) {
        return _shouldEdgeLabelBeMoved(edge);
      }
    }
    
    const _shouldEdgeLabelBeMoved = function (edge){
      const otherDecks = edge.components.filter(
        c => c !== _componentOfInterest && _isDeck(c)
      );
      
      // don't move if other decks are present
      return _.isEmpty(otherDecks);
    }
    
    const _dumbOffset = function (vectors, offsetAmount){
      const vectorsArray = vectors.map(v3 => [v3.x, v3.z, v3.y]);
      const offsetArray = offsetRoomPolsRoof(vectorsArray, offsetAmount);
      
      return offsetArray.map(array => new BABYLON.Vector3(array[0], array[2], array[1]));
    }
    
    const dumbOffsetVectors = _dumbOffset(vectors, offsetAmount);
    
    const offsetVectors = [];
    const collinearIndices = [];
    
    const edges = vectors.map(v => {
      return [
        v,
        getCircularlyNextElementInArray(vectors, v),
      ]
    });
    
    const edgeLabels = edges.map(e => {
      return virtualSketcher.lookupEdge(...e);
    });
    
    const edgeLabelsThatShouldNotMove = edgeLabels.filter(el => !_shouldEdgeLabelBeMoved(el));
    
    edgeLabels.forEach((el, i) => {
      if (edgeLabelsThatShouldNotMove.includes(el)) return;
      
      const edge = edges[i];
      const previousEdge = getCircularlyPreviousElementInArray(edges, edge);
      const nextEdge = getCircularlyNextElementInArray(edges, edge);
      
      const previousLabel = getCircularlyPreviousElementInArray(edgeLabels, el);
      const nextLabel = getCircularlyNextElementInArray(edgeLabels, el);
      
      const edgeD = edge[0].subtract(edge[1]);
      const previousEdgeD = previousEdge[0].subtract(previousEdge[1]);
      const nextEdgeD = nextEdge[0].subtract(nextEdge[1]);
      
      if (
        areTwoLinesCollinear(edgeD, previousEdgeD) &&
        edgeLabelsThatShouldNotMove.includes(previousLabel)
      ) {
        edgeLabelsThatShouldNotMove.push(el);
      }
      else if (
        areTwoLinesCollinear(edgeD, nextEdgeD) &&
        edgeLabelsThatShouldNotMove.includes(nextLabel)
      ) {
        edgeLabelsThatShouldNotMove.push(el);
      }
      
    });
    
    const vectorsThatShouldNotMove = _.flatten(edgeLabelsThatShouldNotMove.map(el => {
      return el.getNodeVectors(_componentOfInterest);
    }));
    
    vectors.forEach((currentV3, i) => {
      const previousV3 = getCircularlyPreviousElementInArray(vectors, currentV3);
      const nextV3 = getCircularlyNextElementInArray(vectors, currentV3);
      
      const currentV3Clone = currentV3.clone();
      offsetVectors[i] = currentV3Clone;
      
      if (store.resolveEngineUtils.onSegment3D(previousV3, currentV3, nextV3)){
        collinearIndices.push(i);
      }
      else {
        
        let shouldMovePrevious = true;
        let shouldMoveNext = true;
        
        
        /*
          For floors, common edges stay put, others move outwards
          
          For roofs, common edges move inwards, others stay put
            Roof points are offset outwards in roof.types.js
         */
        
        if (vectorsThatShouldNotMove.inArray(v3 => v3.almostEquals(currentV3))){
          if (isRoofOffset) {
            shouldMovePrevious = vectorsThatShouldNotMove.inArray(v3 => v3.almostEquals(previousV3));
            shouldMoveNext = vectorsThatShouldNotMove.inArray(v3 => v3.almostEquals(nextV3));
          }
          else {
            shouldMovePrevious = !vectorsThatShouldNotMove.inArray(v3 => v3.almostEquals(previousV3));
            shouldMoveNext = !vectorsThatShouldNotMove.inArray(v3 => v3.almostEquals(nextV3));
          }
        }
        else {
          if (isRoofOffset) return;
        }
        
        let previousDirection = previousV3.subtract(currentV3).normalize();
        let nextDirection = nextV3.subtract(currentV3).normalize();
        
        const possibleOffsetVector = currentV3Clone
          .add(previousDirection.scale(offsetAmount))
          .add(nextDirection.scale(offsetAmount));
        
        if (!possibleOffsetVector.almostEquals(dumbOffsetVectors[i])){
          previousDirection = previousDirection.negate();
          nextDirection = nextDirection.negate();
        }
        
        /*const angle = BABYLON.Vector3.GetAngleBetweenVectors(
          previousDirection,
          nextDirection,
          BABYLON.Vector3.Up()
        );
        
        if (angle < Math.PI / 2){
          // convex -  movement direction is previous -> current for  positive offset
          previousDirection = previousDirection.negate();
          nextDirection = nextDirection.negate();
        }
        else {
          // concave -  movement direction is current -> previous for  positive offset
          // correct directions
        }*/
        
        if (shouldMoveNext) currentV3Clone.addInPlace(previousDirection.scale(offsetAmount));
        if (shouldMovePrevious) currentV3Clone.addInPlace(nextDirection.scale(offsetAmount));
      }
      
    });
    
    collinearIndices.forEach(index => {
      
      const v3 = offsetVectors[index];
      
      const previousV3 = getCircularlyPreviousElementInArray(offsetVectors, v3);
      const nextV3 = getCircularlyNextElementInArray(offsetVectors, v3);
      
      v3.copyFrom(projectionOfPointOnLine(v3, previousV3, nextV3));
    });
    
    return offsetVectors;
  }

  function getFloorPoints(vertices) {
    vertices = _.uniqWith(vertices, (v1, v2) => {
      return v1.almostEquals(
        v2,
        CONSTANTS.duplicatePointThreshold
      );
    });
    
    let floorPoints;

    if (isRoomOfType(_meshOfInterest, "deck")) {
      // decks don't generate walls, so there's a wall shaped gap between floor and slab
      floorPoints = _smartOffset(
        vertices,
        store.projectProperties.properties.wallThicknessPropertyExt.getValue() / 2
      );
    } else {
      floorPoints = vertices;
    }
    
    return floorPoints.map(v3 => [v3.x, v3.z, v3.y]);
  }

  function getRoofPoints(vertices, isGradeSlab) {
    let util = new ResolveEngineUtils();
    vertices = _.uniqWith(vertices, (v1, v2) => {
      return v1.almostEquals(
        v2,
        CONSTANTS.duplicatePointThreshold
      );
    });

    const adjustHeight = !isGradeSlab;
    if (adjustHeight && isRoomOfTypeBalcony(_meshOfInterest)) {
      let storeyData = StructureCollection.getInstance()
        .getStructureById(_meshOfInterest.structure_id)
        .getStoreyData();
      let heightObj = storeyData.getStoreyByValue(_meshOfInterest.storey);

      vertices = adjustHeightOfHalfRoofs(
        vertices,
        heightObj.base + heightObj.height
      );
    }
    
    let roofPoints;
    if (isRoomOfType(_meshOfInterest, "deck") && isGradeSlab) {
      roofPoints = _smartOffset(
        vertices,
        -store.projectProperties.properties.wallThicknessPropertyExt.getValue() / 2,
        true
      );
    } else {
      roofPoints = vertices;
    }
    
    const thickness = store.projectProperties.properties.slabThicknessProperty.getValue();
    roofPoints.forEach((v) => {
      v.y -= thickness;
    });
    
    return roofPoints.map(v3 => [v3.x, v3.z, v3.y]);
  }

  function _addWaterbodyCustomProperties(wallPoints) {
    wallPoints.shouldOccupyJunctions = true;
    wallPoints.preGenerationEdit = CONSTANTS.preGenerationEditTypes.scaleDown;
  }

  function _removeWaterbodyCustomProperties(wallPoints) {
    wallPoints.shouldOccupyJunctions = false;
    wallPoints.preGenerationEdit = null;
  }

  function _doesComponentGenerateGradeSlab(c) {
    let yesGradeSlabs = true;
    const noGradeSlabTypes = ["site", "ground", "road"];
    // deck generates grade slab

    noGradeSlabTypes.every((type) => {
      if (isRoomOfType(c.mesh, type)) yesGradeSlabs = false;
      return yesGradeSlabs;
    });

    return yesGradeSlabs;
  }

  function _getMasterNodeLabel(nodeLabel) {
    return metadataPersist.internalDimensionData.nodeLabelToMasterNodeLabelMap.get(
      nodeLabel
    );
  }

  function _getDimensionTuningThreshold() {
    return 2 * getInternalWallThickness();
  }

  function getWallPoints(vertices, wallType, options = {}) {
    if (!wallType) {
      let roomType = ScopeUtils.getRoomType(
        _componentOfInterest.room_type
      ).toLowerCase();
      let massOccurrenceCount = {},
        count = 0;

      if (roomType === "balcony") {
        wallType = CONSTANTS.wallTypes.parapet;
      } else if (roomType !== "site") {
        // Check to separate Internal and External Walls
        vertices.forEach((vertex) => {
          let components = virtualSketcher.structuralLookup(vertex).components;
          components.forEach((component) => {
            if (!massOccurrenceCount[component.id])
              massOccurrenceCount[component.id] = 1;
            else massOccurrenceCount[component.id]++;
          });
        });

        for (let key in massOccurrenceCount)
          if (massOccurrenceCount[key] === vertices.length) count++;

        if (count > 1) wallType = CONSTANTS.wallTypes.internal;
        else wallType = CONSTANTS.wallTypes.external;
      } else {
        wallType = CONSTANTS.wallTypes.external;
      }
    }

    const isAnExternalWall = wallType === CONSTANTS.wallTypes.external;
    let isAnInvertedWall
    if (options.isMasslessWallGeneration){
      isAnInvertedWall = false;
    }
    else {
      isAnInvertedWall =
        virtualSketcher.util.doesComponentGrowDownwards(_componentOfInterest);
    }
    
    let verticesObject = _getShiftedVertices(vertices, options);
    if (!verticesObject) {
      console.warn("Shifted vertices calculation failed");
      return;
    }

    let wallThickness =
      store.projectProperties.properties[
        "wallThicknessProperty" + wallType
      ].getValue();
    
    if (options.thickness){
      wallThickness = options.thickness;
    }
    
    let normal = verticesObject.normal;

    const movementVectorInward = normal.scale(wallThickness / 2);
    const movementVectorOutward = normal.scale(-wallThickness / 2);

    const verticesInward = verticesObject.shiftedVertices
      .map((v3) => v3.add(movementVectorInward));

    const verticesOutward = verticesObject.shiftedVertices
      .map((v3) => v3.add(movementVectorOutward));

    const bottomInward = verticesObject.bottomVertices
      .map((v3) => v3.add(movementVectorInward));

    const bottomOutward = verticesObject.bottomVertices
      .map((v3) => v3.add(movementVectorOutward));

    const topInward = verticesObject.topVertices
      .map((v3) => v3.add(movementVectorInward));

    const topOutward = verticesObject.topVertices
      .map((v3) => v3.add(movementVectorOutward));

    const bottomNeighbourInward = verticesObject.bottomNeighbourVertices
      .map((v3) => v3.add(movementVectorInward));

    const bottomNeighbourOutward = verticesObject.bottomNeighbourVertices
      .map((v3) => v3.add(movementVectorOutward));

    const topNeighbourInward = verticesObject.topNeighbourVertices
      .map((v3) => v3.add(movementVectorInward));

    const topNeighbourOutward = verticesObject.topNeighbourVertices
      .map((v3) => v3.add(movementVectorOutward));

    const sideCoords = {};
    sideCoords.side1LowerInner = bottomInward[1];
    sideCoords.side1LowerOuter = bottomOutward[1];
    sideCoords.side1UpperInner = topInward[1];
    sideCoords.side1UpperOuter = topOutward[1];
    sideCoords.side1LowerInnerNeighbour = bottomNeighbourInward[1];
    sideCoords.side1LowerOuterNeighbour = bottomNeighbourOutward[1];
    sideCoords.side1UpperInnerNeighbour = topNeighbourInward[1];
    sideCoords.side1UpperOuterNeighbour = topNeighbourOutward[1];

    sideCoords.side1LowerMiddleV3 = verticesObject.bottomVertices[1];
    sideCoords.side1UpperMiddleV3 = verticesObject.topVertices[1];
    sideCoords.side1LowerMiddleNodeLabel = virtualSketcher.lookup(
      sideCoords.side1LowerMiddleV3
    );

    sideCoords.side2LowerInner = bottomInward[0];
    sideCoords.side2LowerOuter = bottomOutward[0];
    sideCoords.side2UpperInner = topInward[0];
    sideCoords.side2UpperOuter = topOutward[0];
    sideCoords.side2LowerInnerNeighbour = bottomNeighbourInward[0];
    sideCoords.side2LowerOuterNeighbour = bottomNeighbourOutward[0];
    sideCoords.side2UpperInnerNeighbour = topNeighbourInward[0];
    sideCoords.side2UpperOuterNeighbour = topNeighbourOutward[0];

    sideCoords.side2LowerMiddleV3 = verticesObject.bottomVertices[0];
    sideCoords.side2UpperMiddleV3 = verticesObject.topVertices[0];
    sideCoords.side2LowerMiddleNodeLabel = virtualSketcher.lookup(
      sideCoords.side2LowerMiddleV3
    );

    if (dimensionsTuner.isTunerActive()) {
      const side1MasterNodeLabel = _getMasterNodeLabel(
        sideCoords.side1LowerMiddleNodeLabel
      );
      
      const side2MasterNodeLabel = _getMasterNodeLabel(
        sideCoords.side2LowerMiddleNodeLabel
      );
      
      if (side1MasterNodeLabel) sideCoords.side1LowerMiddleNodeLabel = side1MasterNodeLabel;
      if (side2MasterNodeLabel) sideCoords.side2LowerMiddleNodeLabel = side2MasterNodeLabel;
    }

    const direction = sideCoords.side1LowerMiddleV3
      .subtract(sideCoords.side2LowerMiddleV3)
      .normalize();

    // const bottomCoords = bottomInward.concat(bottomOutward);
    const bottomCoords = bottomInward.concat(bottomOutward.reverse());
    // const topCoords = topInward.concat(topOutward);
    const topCoords = topInward.concat(topOutward.reverse());

    let isWallOfFullHeight = true;
    let storeyOfMass;
    
    if (options.storey){
      storeyOfMass = StoreyMutation.getAParticularStorey(
          options.storey
      );
    }
    else {
      storeyOfMass = StoreyMutation.getAParticularStorey(
        _componentOfInterest.storey
      );
    }
    
    if (storeyOfMass) {
      const adjustedStoreyHeight = 0.95 * storeyOfMass.height;
      if (verticesObject.height < adjustedStoreyHeight) {
        isWallOfFullHeight = false;
      }
    }

    let isWallIrregular = false;
    if (
      !sideCoords.side1LowerMiddleNodeLabel ||
      !sideCoords.side2LowerMiddleNodeLabel
    ) {
      isWallIrregular = true;
    }


    const wallPoints = {
      innerCoords: verticesInward,
      outerCoords: verticesOutward,
      midY: verticesObject.midY,
      height: Math.abs(verticesObject.height),
      bottomCoords: bottomCoords,
      topCoords: topCoords,
      sideCoords: sideCoords,
      direction,
      isAnExternalWall,
      isAnInternalWall: !isAnExternalWall,
      isWallOfFullHeight,
      isAnInvertedWall,
      isWallIrregular, // eg- a weird wall after split face
      mass: _componentOfInterest,
      preGenerationEdit: null,
      edgeLabel: null, // to prevent another lookup
      id: "w_" + makeid(3),
      wallToWallDimensionData: {
        // for internal wallToWall dimension case
        isLockedInPosition: false,
        hasMovedInwards: false,
        hasMovedOutwards: false,
        movementAmount: null,
      },
      wallType,
      revitData : { id : options?.revitData?.id,
        wallType: options?.revitData?.wallType,
        layersData: options?.revitData?.layersData,
        isStackedWall: null,
        fullHeight: null,
        lowerHeight: null,
        stackedWallParentId: null, // top portion will refer to bottom with this
        bottomRefLine: options?.revitData?.bottomRefLine,
      },
      getProfile: () => {
        return [sideCoords.side1LowerMiddleV3, sideCoords.side2LowerMiddleV3];
      },
      getProfile2D: () => {
        const v1 = sideCoords.side1LowerMiddleV3.clone();
        const v2 = sideCoords.side2LowerMiddleV3.clone();

        v1.y = v2.y;
        return v1.subtract(v2).normalize();
      },
    };

    if (isWallIrregular){
      metadataPersist.wallJunctionData.irregularWalls.push(wallPoints);
    }

    let currentWallWontBeBuilt = false;
    let wallToBeRemoved = null;

    let edgeLabel = virtualSketcher.lookupEdge(
      sideCoords.side1LowerMiddleV3,
      sideCoords.side2LowerMiddleV3
    );
    if (edgeLabel) {
      const _handleWaterbodyWallPreEdit = function () {
        if (edgeLabel.weight > 1) {
          const components = edgeLabel.components;
          const componentsAboveStoreyBaseAndGenerateGradeSlabs =
            components.filter((c) => {
              return (
                !virtualSketcher.util.doesComponentGrowDownwards(c) &&
                _doesComponentGenerateGradeSlab(c)
              );
            });

          if (_.isEmpty(componentsAboveStoreyBaseAndGenerateGradeSlabs)) {
            // do nothing, full height water body walls valid
          } else {
            // reduce water body wall height
            _addWaterbodyCustomProperties(wallPoints);
          }

          if (wallInMap) {
            _removeWaterbodyCustomProperties(wallInMap);
          }
        }
      };

      if (dimensionsTuner.isTunerActive()) {
        // remove small walls
        const threshold = _getDimensionTuningThreshold();
        const isAWeirdJunctionWall =
          getDistanceBetweenVectors(
            sideCoords.side1LowerMiddleV3,
            sideCoords.side2LowerMiddleV3
          ) < threshold;

        if (isAWeirdJunctionWall) return null;
      }
      let edgeId = edgeLabel.id;
      if (isAnInvertedWall) edgeId = _getIdForInvertedWall(edgeId);
      // pre generation wall resolution

      let wallsOnThisEdge =
        metadataPersist.wallResolutionData.edgeIdAllWallsMap.get(edgeId);
      if (!wallsOnThisEdge) {
        wallsOnThisEdge = [];
        metadataPersist.wallResolutionData.edgeIdAllWallsMap.set(
          edgeId,
          wallsOnThisEdge
        );
      }

      wallsOnThisEdge.push(wallPoints);

      // had a edgeLabel.weight > 1 condition here before
      // but for instances handling, that condition has to be removed
      // because when vertices are added, an edge label with weight 1 should actually be of weight 2

      const sameHeightThreshold = 0.01;
      const wallInMap =
        metadataPersist.wallResolutionData.edgeIdOccupierMap.get(edgeId);
      if (wallInMap) {
        if (
          isFloatEqual(wallInMap.height, wallPoints.height, sameHeightThreshold)
        ) {
          if (
            (wallInMap.isAnExternalWall && wallPoints.isAnInternalWall) ||
            options.forceReplacement
          ) {
            // exclusively for instance walls
            wallToBeRemoved = wallInMap;
            metadataPersist.wallResolutionData.edgeIdOccupierMap.set(
              edgeId,
              wallPoints
            );
          } else {
            currentWallWontBeBuilt = true;
          }
        } else if (wallInMap.height > wallPoints.height) {
          // do nothing
          // the wall already on the map should be generated
          currentWallWontBeBuilt = true;
        } else {
          if (isAnInvertedWall) {
            _handleWaterbodyWallPreEdit();
          }
          wallToBeRemoved = wallInMap;
          metadataPersist.wallResolutionData.edgeIdOccupierMap.set(
            edgeId,
            wallPoints
          );
        }
      } else {
        if (isAnInvertedWall) {
          _handleWaterbodyWallPreEdit();
        }
        metadataPersist.wallResolutionData.edgeIdOccupierMap.set(
          edgeId,
          wallPoints
        );
      }
    } else {
      // crate new edge in graph for newly created instance walls
      // TODO - not sure if necessary
    }

    wallPoints.edgeLabel = edgeLabel;

    if (!currentWallWontBeBuilt) {
      // not gonna add the walls that won't be built to the map to nullify the
      // chances of such walls being picked as junction occupiers

      // wallToBeRemoved serves the same purpose

      const _addToMap = function (nodeLabel) {

        if (!nodeLabel) {
          if (!wallPoints.isWallIrregular)
            console.warn("No side node labels on a regular wall");
          return;
        }

        const data = nodeIdWallDataMap.get(nodeLabel.id);
        if (data) {
          data.push(wallPoints);
          if (wallToBeRemoved) _.remove(data, wallToBeRemoved);
        } else {
          nodeIdWallDataMap.set(nodeLabel.id, [wallPoints]);
        }
      };

      const nodeIdWallDataMap =
        metadataPersist.wallJunctionData.nodeIdWallDataMap;
      _addToMap(wallPoints.sideCoords.side1LowerMiddleNodeLabel);
      _addToMap(wallPoints.sideCoords.side2LowerMiddleNodeLabel);
    }

    return wallPoints;
  }

  function _getIdForInvertedWall(id) {
    return "-" + id;
  }

  function _isBalcony(component) {
    return (
      ScopeUtils.getRoomType(component.room_type).toLowerCase() === "balcony"
    );
  }

  function _isSite(component) {
    return (
      ScopeUtils.getRoomType(component.room_type).toLowerCase() === "site"
    );
  }

  function _isBottomShiftRequired(component) {
    let required = true;

    if (
      _isBalcony(component) ||
      _isSite(component) ||
      virtualSketcher.util.doesComponentGrowDownwards(component)
    )
      required = false;

    return required;
  }

  /**
   *
   * Returns the vertices shifted according to various conditions such as room_type
   * vertices are obtained from face data
   * shiftedVertices are actually what's used for wall generation
   *
   * @param faceVertices
   * @param options
   */
  function _getShiftedVertices(faceVertices, options) {

    const _assignEndPointsFromBrep = function (currentFaceObject){

      /*
      https://imgur.com/a/LQ0QN7X

      Here, brep traversal will give v1v2 and v2v3 as the neighboring edges
      What we need as endpoints are v1 and v3 because v2 will be removed during collinear removal
       */
      const _mergeEdgesIfNecessary = function (){
        if (wallBorderingEdgeData.length >= 2){

          wallBorderingEdgeData.some(data1 => {
            if (!topFaceVertices.inArray(v => v.almostEquals(data1.vertices.upperVertex))) {

              let merged = false;
              wallBorderingEdgeData.some(data2 => {
                if (data2 === data1) return;

                if (data2.vertices.lowerVertex.almostEquals(data1.vertices.upperVertex)){

                  const direction1 = data1.vertices.upperVertex.subtract(data1.vertices.lowerVertex).normalize();
                  const direction2 = data2.vertices.upperVertex.subtract(data2.vertices.lowerVertex).normalize();

                  if (direction1.almostEquals(direction2)){
                    data1.vertices.upperVertex.copyFrom(data2.vertices.upperVertex);
                    _mergeEdgesIfNecessary();
                    merged = true;
                  }

                }

                return merged;
              });

              return merged;

            }
          });
        }
      };

      const edgesOfTheFace = getEdgeObjectsAroundTheFace(currentFaceObject);
      const currentFaceNormal = normal;

      const wallBorderingEdgeData = [];
      edgesOfTheFace.forEach(edge => {
        const otherFace = getEdgeAdjacentFace(edge, currentFaceObject);

        const afterCBData = metadata.faceObjectAfterCBDataMap.get(otherFace);
        if (afterCBData.type === CONSTANTS.wall){
          const otherFaceVertices = afterCBData.vertices;
          const otherFaceNormal = getUnitNormalVectorV3(otherFaceVertices);

          const [v1, v2] = getVerticesFromEdgeObject(_componentOfInterest, edge);

          if (isFloatEqual(v1.y, v2.y, equalityThreshold)) return;
          // horizontal edge

          let lowerVertex, upperVertex;
          if (v1.y < v2.y){
            lowerVertex = v1;
            upperVertex = v2;
          }
          else {
            lowerVertex = v2;
            upperVertex = v1;
          }

          if (topFaceVertices.inArray(v => v.almostEquals(upperVertex))) {
            upperVertex.addInPlace(bottomShiftOfTopVertices)
          }

          const edgeData = {
            vertices: {
              lowerVertex,
              upperVertex,
            },
            normal: otherFaceNormal,
          };

          wallBorderingEdgeData.push(edgeData);

        }
      });

      /*

      A sample case for determining the end points
      https://imgur.com/a/3C8pAN5

      Sorting criteria-

      1. Faces with different normal and lower point on graph
      2. Faces with same normal and lower point on graph
      3. Different normal, lower point not on graph
      4. Same normal, lower point not on graph

       */
      wallBorderingEdgeData.sort((data1, data2) => {

        const isData1LowerPointOnGraph = virtualSketcher.lookup(data1.vertices.lowerVertex);
        const isData2LowerPointOnGraph = virtualSketcher.lookup(data2.vertices.lowerVertex);

        if (
          (isData1LowerPointOnGraph && isData2LowerPointOnGraph) ||
          (!isData1LowerPointOnGraph && !isData2LowerPointOnGraph)
        ) {
          // either both are on the graph or neither
          // in both cases, normal is the deciding factor

          // different normal first

          const d1NormalDiff = currentFaceNormal.subtract(data1.normal).length();
          const d2NormalDiff = currentFaceNormal.subtract(data2.normal).length();

          return d2NormalDiff - d1NormalDiff;

        }
        else if (isData1LowerPointOnGraph){
          // order remains, data1 should be before data2
          return -1;
        }
        else if (isData2LowerPointOnGraph){
          // order swaps, data2 should be before data1
          return 1;
        }

      });

      _mergeEdgesIfNecessary();

      // have determined the most logical edges that border neighboring walls
      // they're sorted and first 2 will be used
      if (wallBorderingEdgeData.length >= 2){

        let side1Index = null, side2Index = null;
        const firstSet = wallBorderingEdgeData[0].vertices;

        shiftedVertices.forEach(v => {
          if (v.almostEquals(firstSet.lowerVertex)){

            // lowerPoint1 -> lowerPoint2 should follow faceVertices direction (usually CCW)

            const nextVertex = getCircularlyNextElementInArray(shiftedVertices, v);
            if (nextVertex.almostEquals(firstSet.upperVertex)){
              // this should be side1
              side1Index = 1;
              side2Index = 0;
            }
            else {
              side1Index = 0;
              side2Index = 1;
            }
            return true;
          }
        });
        if(side1Index != null && side2Index != null)
        {
          lowerPoint1 = wallBorderingEdgeData[side1Index].vertices.lowerVertex;
          upperPoint1 = wallBorderingEdgeData[side1Index].vertices.upperVertex;
  
          lowerPoint2 = wallBorderingEdgeData[side2Index].vertices.lowerVertex;
          upperPoint2 = wallBorderingEdgeData[side2Index].vertices.upperVertex;
        }
      }

    }

    const _assignEndPointsFromHeightData = function (){

      shiftedVertices.forEach((currentVertex) => {

        const nextVertex = getCircularlyNextElementInArray(
          shiftedVertices,
          currentVertex
        );
        const previousVertex = getCircularlyPreviousElementInArray(
          shiftedVertices,
          currentVertex
        );

        if (
          isFloatEqual(currentVertex.y, minY, equalityThreshold) &&
          isFloatEqual(nextVertex.y, minY, equalityThreshold)
        ) {
          lowerPoint1 = currentVertex;
          lowerPoint2 = nextVertex;
        }

        if (
          isFloatEqual(currentVertex.y, maxY, equalityThreshold) &&
          isFloatEqual(previousVertex.y, maxY, equalityThreshold)
        ) {
          upperPoint1 = currentVertex;
          upperPoint2 = previousVertex;
        }

      });
    }

    const _getNeighourPoint = function (point, otherPoint){
      let neighbourPoint;
      shiftedVertices.forEach(vertex => {
        if (point.almostEquals(vertex)){
          const currentDirection = otherPoint.subtract(point).normalize();

          const previousVertex = getCircularlyPreviousElementInArray(shiftedVertices, vertex);
          const nextVertex = getCircularlyNextElementInArray(shiftedVertices, vertex);

          const previousDirection = previousVertex.subtract(vertex).normalize();
          const nextDirection = nextVertex.subtract(vertex).normalize();

          if (currentDirection.almostEquals(previousDirection)){
            neighbourPoint = nextVertex;
          } else if (currentDirection.almostEquals(nextDirection)){
            neighbourPoint = previousVertex;
          }

          return neighbourPoint;
        }
      });

      return neighbourPoint;
    }

    const nonCollinearVertices = removeCollinearVertices(faceVertices);
    const shiftedVertices = nonCollinearVertices.map(v => v.clone());

    const equalityThreshold = 1e-3;

    // This is done so that top vertices move down, so that floor to roof height remains
    // same as mass height (3000 mm by default). Roof is also moved own.

    let topFaceVertices = [];
    let bottomShiftAmountOfTopVertices;
    let bottomShiftOfTopVertices;

    if (_componentOfInterest) {
      topFaceVertices = getTopFaceVertices(_componentOfInterest);

      if (_isBottomShiftRequired(_componentOfInterest)){
        bottomShiftAmountOfTopVertices =
          store.projectProperties.properties.slabThicknessProperty.getValue();
      }
      else if (_isSite(_componentOfInterest)){
        if (metadata.roomTypeProperties?.bim) {
          let height = metresToSnaptrudeUnits(
            metadata.roomTypeProperties.bim.wallHeight
          );

          if (_.isNumber(height)) {
            bottomShiftAmountOfTopVertices = -height;
          }
        }
      }
    }

    if (bottomShiftAmountOfTopVertices) {
      bottomShiftOfTopVertices = BABYLON.Vector3.Down().scale(
        bottomShiftAmountOfTopVertices
      );

      shiftedVertices.forEach(currentVertex => {
        if (topFaceVertices.inArray(v => v.almostEquals(currentVertex))) {
          currentVertex.addInPlace(bottomShiftOfTopVertices)
        }
      });
    }
    else {
      bottomShiftOfTopVertices = BABYLON.Vector3.Zero();
    }

    const Ys = shiftedVertices.map(v => v.y);
    const uniqueYs = _.uniqWith(Ys,
      (y1, y2) => isFloatEqual(y1, y2, equalityThreshold));

    uniqueYs.sort((a, b) => a - b);

    const minY = _.first(uniqueYs);
    const maxY = _.last(uniqueYs);

    const midY = (minY + maxY) / 2;
    const normal = getUnitNormalVectorV3(shiftedVertices);

    let lowerPoint1 = null;
    let upperPoint1 = null;
    let lowerPoint2 = null;
    let upperPoint2 = null;
    let edgesOfTheFace =[];
    if(options.faceObject)
      edgesOfTheFace = getEdgeObjectsAroundTheFace(options.faceObject);
    if(edgesOfTheFace.length != (shiftedVertices.length)) //case of arc
    {
      _assignEndPointsFromHeightData();
    }
    else if (_componentOfInterest && !_isSite(_componentOfInterest)) {
      _assignEndPointsFromBrep(options.faceObject);
    }
    else {
      _assignEndPointsFromHeightData();
    }

    if ([lowerPoint1, lowerPoint2, upperPoint1, upperPoint2].includes(null)) {
      return;
    }

    if (_componentOfInterest && virtualSketcher.util.doesComponentGrowDownwards(_componentOfInterest)) {
      [lowerPoint1, upperPoint1] = [upperPoint1, lowerPoint1];
      [lowerPoint2, upperPoint2] = [upperPoint2, lowerPoint2];
    }

    let bottomVerticesUnmoved = [lowerPoint1, lowerPoint2];
    let bottomVertices = [lowerPoint1, lowerPoint2];
    let topVertices = [upperPoint1, upperPoint2];

    if(options && options.revitData?.isProfiledWall){
      const wallHeight = options.revitData?.height
      bottomVerticesUnmoved = options?.revitData?.bottomCenterLine;
      bottomVertices = options?.revitData?.bottomCenterLine;
      topVertices = []
      bottomVertices.forEach(point => {
        topVertices.push(BABYLON.Vector3.FromArray([point.x, point.y+wallHeight, point.z]))
      })
    }

    let lowerPoint1Neighbour = _getNeighourPoint(lowerPoint1, upperPoint1);
    let upperPoint1Neighbour = _getNeighourPoint(upperPoint1, lowerPoint1);
    let lowerPoint2Neighbour = _getNeighourPoint(lowerPoint2, upperPoint2);
    let upperPoint2Neighbour = _getNeighourPoint(upperPoint2, lowerPoint2);

    const bottomNeighbourVertices = [lowerPoint1Neighbour || lowerPoint2, lowerPoint2Neighbour || lowerPoint1];
    const topNeighbourVertices = [upperPoint1Neighbour || upperPoint2, upperPoint2Neighbour || upperPoint1];

    const height = maxY - minY;

    return {
      shiftedVertices,
      bottomVertices,
      topVertices,
      bottomNeighbourVertices,
      topNeighbourVertices,
      midY,
      height,
      normal,
    };
  }

  function allotJunctionOccupationRights() {
    const _getOccupier = function (allWalls) {
      
      const preExistingWalls = allWalls.filter(w => w.createdWallUniqueId);
      if (!_.isEmpty(preExistingWalls)){
        return preExistingWalls[0];
      }
      
      const [externalWalls, internalWalls] = _.partition(
        allWalls,
        (w) => w.isAnExternalWall
      );
      const [fullHeightWalls, halfHeightWalls] = _.partition(
        allWalls,
        (w) => w.isWallOfFullHeight
      );

      const fullHeightAndExternalWalls = _.intersection(
        externalWalls,
        fullHeightWalls
      );
      const fullHeightAndInternalWalls = _.intersection(
        internalWalls,
        fullHeightWalls
      );
      const halfHeightAndExternalWalls = _.intersection(
        externalWalls,
        halfHeightWalls
      );
      const halfHeightAndInternalWalls = _.intersection(
        internalWalls,
        halfHeightWalls
      );

      // sorting in descending order of height so that the taller wall covers the junction

      halfHeightAndExternalWalls.sort((w1, w2) => {
        return w2.height - w1.height;
      });

      halfHeightAndInternalWalls.sort((w1, w2) => {
        return w2.height - w1.height;
      });

      return (
        fullHeightAndExternalWalls[0] ||
        fullHeightAndInternalWalls[0] ||
        halfHeightAndExternalWalls[0] ||
        halfHeightAndInternalWalls[0]
      );
    };

    metadataPersist.wallJunctionData.nodeIdWallDataMap.forEach(
      (allWalls, id) => {
        const [allWallsBelowStoreyBase, allWallsAboveStoreyBase] = _.partition(
          allWalls,
          (w) => w.isAnInvertedWall
        );

        const topOccupier = _getOccupier(allWallsAboveStoreyBase);

        let bottomOccupier;
        if (!_.isEmpty(allWallsBelowStoreyBase)) {
          const preDeterminedOccupier = allWallsBelowStoreyBase.filter(
            (w) => w.shouldOccupyJunctions
          )[0];
          if (preDeterminedOccupier) bottomOccupier = preDeterminedOccupier;
          else bottomOccupier = _getOccupier(allWallsBelowStoreyBase);
        }

        const nodeOccupiers = _.compact([topOccupier, bottomOccupier]);
        metadataPersist.wallJunctionData.nodeIdJunctionOccupierMap.set(
          id,
          nodeOccupiers
        );
      }
    );
    /*
    metadataPersist.wallJunctionData.irregularWalls.forEach(wall => {

      const junctionOccupiers =

      const currentWallProfile2D = currentWall.getProfile2D();
      junctionOccupiers.some(occupier => {
        if (occupier.isAnInvertedWall === currentWall.isAnInvertedWall){
          if (occupier.getProfile2D().almostEquals(currentWallProfile2D)) currentWallShouldOccupyJunction = true;
        }
      });
    });*/
  }

  function _handleDebug(side1Point, side2Point) {
    debugData.some((l) => {
      if (areLinesSimilar([side1Point, side2Point], l)) {
        debugger;
        return true;
      }
    });
  }

  function _determineNeighbours(currentWall, options = {}) {
    const _getAppropriateWallAtTheJunction = function (junctionNodeLabel) {
      let allWallsPlusCurrentWall =
        metadataPersist.wallJunctionData.nodeIdWallDataMap.get(
          junctionNodeLabel.id
        );

      const wallDirectionMap = new Map();
      allWallsPlusCurrentWall.forEach((w) => {
        wallDirectionMap.set(
          w,
          _getAppropriateDirection(w, junctionNodeLabel.vector)
        );
      });

      if (options.additionalFilter) {
        allWallsPlusCurrentWall = allWallsPlusCurrentWall.filter(
          options.additionalFilter
        );
      }

      const allWallsAllHeights = allWallsPlusCurrentWall.filter((w) => {
        if (w === currentWall) return false;
        else return w.isAnInvertedWall === currentWall.isAnInvertedWall;
      });

      const nonParallelWalls = [];
      const parallelWalls = [];
      const externalWalls = [];
      const internalWalls = [];

      allWallsAllHeights.forEach((wallData) => {
        const thisWallDirection = wallData.direction;

        const nonParallel = !areVectorsParallel(
          currentWall.direction,
          thisWallDirection
        );
        const external = wallData.isAnExternalWall;
        const internal = wallData.isAnInternalWall;

        if (nonParallel) nonParallelWalls.push(wallData);
        else parallelWalls.push(wallData);

        if (external) externalWalls.push(wallData);
        if (internal) internalWalls.push(wallData);
      });

      const externalAndNonParallelWalls = _.intersection(
        externalWalls,
        nonParallelWalls
      );
      const internalAndNonParallelWalls = _.intersection(
        internalWalls,
        nonParallelWalls
      );
      const externalAndParallelWalls = _.intersection(
        externalWalls,
        parallelWalls
      );
      const internalAndParallelWalls = _.intersection(
        internalWalls,
        parallelWalls
      );

      let currentWallDirectionFromThisJunction =
        wallDirectionMap.get(currentWall);

      externalAndNonParallelWalls.sort((w1, w2) => {
        return (
          getAngleBetweenVectors(
            currentWallDirectionFromThisJunction,
            wallDirectionMap.get(w1)
          ) -
          getAngleBetweenVectors(
            currentWallDirectionFromThisJunction,
            wallDirectionMap.get(w2)
          )
        );
      });

      internalAndNonParallelWalls.sort((w1, w2) => {
        return (
          getAngleBetweenVectors(
            currentWallDirectionFromThisJunction,
            wallDirectionMap.get(w1)
          ) -
          getAngleBetweenVectors(
            currentWallDirectionFromThisJunction,
            wallDirectionMap.get(w2)
          )
        );
      });

      const notBelongingToCurrentMassWalls = allWallsAllHeights.filter((w) => {
        // this is because for walls of other masses, their 'inner' and 'outer' mean opposite
        // to the current wall
        return w.mass?.id !== currentWall.mass?.id;
      });

      const appropriateNeighbour =
        externalAndNonParallelWalls[0] ||
        internalAndNonParallelWalls[0] ||
        externalAndParallelWalls[0] ||
        internalAndParallelWalls[0];

      if (notBelongingToCurrentMassWalls.includes(appropriateNeighbour)) {
        // matlab can't be sure of inner and outer
        options.needToVerifyInnerAndOuter = true;
      }

      options.needToVerifyInnerAndOuter = true;
      // something is missing, failing for certain cases, will check for all cases for now

      return appropriateNeighbour;
    };

    const _getAppropriateWallFromSideNodeLabels = function(junctionVector1, junctionVector2){
      let neighbor
      options.allWalls.some(wall =>{
        const node1 =  wall.sideCoords.side1LowerMiddleV3;
        const node2 =  wall.sideCoords.side2LowerMiddleV3;
        // check if walls are collinear, in that case ignore the neighbor wall
        if(((areThreeVectorsCollinear(node1, node2, junctionVector1, 0.1)) && !(areThreeVectorsCollinear(node1, node2, junctionVector2, 0.1))) && wall.id !== currentWall.id && store.resolveEngineUtils.onSegment3D(node1, junctionVector1, node2)){
          neighbor = wall
          return true
        }

      })
      return neighbor
    }

    const currentWallSide1LowerMiddleNodeLabel =
      currentWall.sideCoords.side1LowerMiddleNodeLabel;
    const currentWallSide2LowerMiddleNodeLabel =
      currentWall.sideCoords.side2LowerMiddleNodeLabel;

    let previousWall, nextWall;
    if (currentWallSide1LowerMiddleNodeLabel && !options.onlyPreviousWall) {
      nextWall = _getAppropriateWallAtTheJunction(
        currentWallSide1LowerMiddleNodeLabel
      );
    }
    if (currentWallSide2LowerMiddleNodeLabel && !options.onlyNextWall) {
      previousWall = _getAppropriateWallAtTheJunction(
        currentWallSide2LowerMiddleNodeLabel
      );
    }

    if (options.isRevitImport){
      
      /*
      This is to handle the case where the walls are as shown below
      https://imgur.com/a/HBBbNb9
      
      Neighbour should be determined even though no other wall lies at
      the side node label of the vertical walls
      
       */
      
      if (!nextWall){
        nextWall = _getAppropriateWallFromSideNodeLabels(currentWall.sideCoords.side1LowerMiddleV3, currentWall.sideCoords.side2LowerMiddleV3 );
        options.forcedSide1InnerJunctions = true;
      }
      if (!previousWall){
        previousWall = _getAppropriateWallFromSideNodeLabels(currentWall.sideCoords.side2LowerMiddleV3, currentWall.sideCoords.side1LowerMiddleV3 );
        options.forcedSide2InnerJunctions = true;
      }
    }

    if (options.onlyPreviousWall) return previousWall;
    else if (options.onlyNextWall) return nextWall;
    else
      return {
        previousWall,
        nextWall,
      };
  }

  /*
  Direction of wall such that direction originates at refVector
   */
  const _getAppropriateDirection = function (wall, refVector) {
    if (wall.sideCoords.side1LowerMiddleV3.almostEquals(refVector)) {
      return wall.sideCoords.side2LowerMiddleV3
        .subtract(wall.sideCoords.side1LowerMiddleV3)
        .normalize();
    } else if (wall.sideCoords.side2LowerMiddleV3.almostEquals(refVector)) {
      return wall.sideCoords.side1LowerMiddleV3
        .subtract(wall.sideCoords.side2LowerMiddleV3)
        .normalize();
    } else {
      // don't know when it'll come here, just preventive
      return wall.direction;
    }
  };
  
  /**
   * Using the metadata accumulated while generating wall points,
   * the points are modified here to have proper junctions
   *
   * After the addition of draw walls mode, dynamically resolving junctions became necessary
   * In those cases, when the wall corresponding to some wallPoints is already created,
   * geometry is modified and the command is returned
   *
   * @param walls
   * @param options
   * @returns {*}
   */
  function handleWallJunctions(walls, options = {}) {
    /*
    Orientation is like this-
  
     previousWall                  nextWall
     s2  _                       _ s1
        | |                     | |
        | |                     | |
        |_|       _____         |_|
     s1     side2|_____|side1      s2
                   wall
  
    side1 moves to the right, into the nextWall
    side2 moves to the right as well, out of previousWall
  
    Inner, outer corresponds to towards and away from centre of the mass
    */

    function _findIntersection(point1, point2, point3, point4, point5) {

      return externalUtil.getPointOfIntersection(
        [point1, point2, [point3, point4, point5]],
        { type: "vector-plane" }
      );
    }

    function _getAngleBetweenWalls(wall1, wall2, refVector) {
      const wall1Direction = _getAppropriateDirection(wall1, refVector);
      const wall2Direction = _getAppropriateDirection(wall2, refVector);

      return getAngleInRadians(wall1Direction, wall2Direction);
    }

    function _areWallsObtuse(wall1, wall2, refVector) {
      const threshold = degreeToRadian(149);
      const angle = _getAngleBetweenWalls(wall1, wall2, refVector);

      return angle > threshold;
    }
    
    function _changeThePoint(
      currentWall,
      oldPoint,
      newPoint,
      includeSideCoords = false
    ) {
      if (!newPoint) {
        // console.warn("Wall junctions might be messed up");
        return;
      }
  
      if (isNaN(newPoint.x) || isNaN(newPoint.y) || isNaN(newPoint.z)) return;
  
      oldPoint = oldPoint.clone();
  
      _replaceV3InArray(currentWall.bottomCoords, oldPoint, newPoint);
      _replaceV3InArray(currentWall.topCoords, oldPoint, newPoint);
      _replaceV3InArray(currentWall.innerCoords, oldPoint, newPoint);
      _replaceV3InArray(currentWall.outerCoords, oldPoint, newPoint);
      
      if (includeSideCoords) {
        const sideCoordsArray = [
          currentWall.sideCoords.side1LowerInner,
          currentWall.sideCoords.side1LowerOuter,
          currentWall.sideCoords.side1UpperInner,
          currentWall.sideCoords.side1UpperOuter,
          currentWall.sideCoords.side2LowerInner,
          currentWall.sideCoords.side2LowerOuter,
          currentWall.sideCoords.side2UpperInner,
          currentWall.sideCoords.side2UpperOuter,
        ];
        // TODO - make this better
        _replaceV3InArray(sideCoordsArray, oldPoint, newPoint);
      }
      
      if (options.changeGeometry && existingWallObject){
        const brep = existingWallObject.brep;
        
        const oldPointLocal = convertGlobalVector3ToLocal(
          oldPoint,
          existingWallObject.mesh,
          true,
        );
        
        const newPointLocal = convertGlobalVector3ToLocal(
          newPoint,
          existingWallObject.mesh,
          true,
        );
        
        const slightlyHigherThreshold = 0.5;
        _replacePointInArray(brep.getPositions(), oldPointLocal.asArray(), newPointLocal.asArray(), slightlyHigherThreshold);
      }
      
      oldPoint.copyFrom(newPoint);
    }
  
    function _replacePointInArray(arrayOfPointArrays, oldPoint, newPoint, threshold) {
      arrayOfPointArrays.forEach((pointArray, index) => {
        if (store.resolveEngineUtils.areArraysAlmostEqual(pointArray, oldPoint, threshold)) {
          // arrayOfPointArrays[index] = newPoint;
          arrayOfPointArrays[index][0] = newPoint[0];
          arrayOfPointArrays[index][1] = newPoint[1];
          arrayOfPointArrays[index][2] = newPoint[2];
        }
      });
    }

    function _replaceV3InArray(arrayOfPoints, oldPoint, newPoint, threshold) {
      arrayOfPoints.forEach((point) => {
        if (point.almostEquals(oldPoint, threshold)) {
          point.copyFrom(newPoint);
        }
      });
    }

    function _handleSide1Junction() {
      const areWallsObtuse = _areWallsObtuse(
        currentWall,
        nextWall,
        currentWallSide1LowerMiddle
      );
      if (areWallsObtuse) {
        _makeSide1Lateral();
        return;
      }


      let areDirectionsInverted = false;
      if (needToVerifyInnerAndOuter) {
        const referenceVector = currentWallSide2LowerMiddle;

        const outerPoints = [
          nextWall.sideCoords.side1LowerOuter,
          nextWall.sideCoords.side2LowerOuter,
          nextWall.sideCoords.side1UpperOuter,
        ];

        

        const outerPlane = getBabylonPlane(outerPoints);

        const outerDistance = Math.abs(
          outerPlane.signedDistanceTo(referenceVector)
        );

        const innerPoints = [
          nextWall.sideCoords.side1LowerInner,
          nextWall.sideCoords.side2LowerInner,
          nextWall.sideCoords.side1UpperInner,
        ];

        const innerPlane = getBabylonPlane(innerPoints);

        const innerDistance = Math.abs(
          innerPlane.signedDistanceTo(referenceVector)
        );

        if (outerDistance < innerDistance) areDirectionsInverted = true;
      }

      const junctionOccupiers =
        metadataPersist.wallJunctionData.nodeIdJunctionOccupierMap.get(
          currentWallSide1LowerMiddleNodeLabel.id
        );

      let currentWallShouldOccupyJunction;

      if (currentWall.isWallIrregular){
        // check if the wall below this is the junction occupier, then this has to be too

        const currentWallProfile2D = currentWall.getProfile2D();
        junctionOccupiers.some(occupier => {
          if (occupier.isAnInvertedWall === currentWall.isAnInvertedWall){
            if (occupier.getProfile2D().almostEquals(currentWallProfile2D)) currentWallShouldOccupyJunction = true;
          }
        });

      }
      else {
        currentWallShouldOccupyJunction =
          junctionOccupiers.includes(currentWall) && !forcedSide1InnerJunctions;
      }

      if (currentWallShouldOccupyJunction) {
        if (areDirectionsInverted) _makeSide1Inner();
        else _makeSide1Outer();
      } else {
        if (areDirectionsInverted) _makeSide1Outer();
        else _makeSide1Inner();
      }
    }

    function _handleSide2Junction() {
      const areWallsObtuse = _areWallsObtuse(
        currentWall,
        previousWall,
        currentWallSide2LowerMiddle
      );
      if (areWallsObtuse) {
        _makeSide2Lateral();
        return;
      }
   


      let areDirectionsInverted = false;
      if (needToVerifyInnerAndOuter) {
        const referenceVector = currentWallSide1LowerMiddle;

        const outerPoints = [
          previousWall.sideCoords.side1LowerOuter,
          previousWall.sideCoords.side2LowerOuter,
          previousWall.sideCoords.side1UpperOuter,
        ];

        const outerPlane = getBabylonPlane(outerPoints);

        const outerDistance = Math.abs(
          outerPlane.signedDistanceTo(referenceVector)
        );

        const innerPoints = [
          previousWall.sideCoords.side1LowerInner,
          previousWall.sideCoords.side2LowerInner,
          previousWall.sideCoords.side1UpperInner,
        ];

        const innerPlane = getBabylonPlane(innerPoints);

        const innerDistance = Math.abs(
          innerPlane.signedDistanceTo(referenceVector)
        );

        if (outerDistance < innerDistance) areDirectionsInverted = true;
      }

      const junctionOccupiers =
        metadataPersist.wallJunctionData.nodeIdJunctionOccupierMap.get(
          currentWallSide2LowerMiddleNodeLabel.id
        );

      let currentWallShouldOccupyJunction;

      if (currentWall.isWallIrregular){
        // check if the wall below this is the junction occupier, then this has to be too

        const currentWallProfile2D = currentWall.getProfile2D();
        junctionOccupiers.some(occupier => {
          if (occupier.isAnInvertedWall === currentWall.isAnInvertedWall){
            if (occupier.getProfile2D().almostEquals(currentWallProfile2D)) currentWallShouldOccupyJunction = true;
          }
        });

      }
      else {
        currentWallShouldOccupyJunction =
          junctionOccupiers.includes(currentWall) && !forcedSide2InnerJunctions;
      }

      if (currentWallShouldOccupyJunction) {
        if (areDirectionsInverted) _makeSide2Inner();
        else _makeSide2Outer();
      } else {
        if (areDirectionsInverted) _makeSide2Outer();
        else _makeSide2Inner();
      }
    }

    function _makeSide1Inner() {
      pointsThatFormThePlaneForProjectionSide1Lower = [
        nextWall.sideCoords.side1LowerInner,
        nextWall.sideCoords.side2LowerInner,
        nextWall.sideCoords.side1UpperInner,
      ];

      pointsThatFormThePlaneForProjectionSide1Upper = [
        nextWall.sideCoords.side1UpperInner,
        nextWall.sideCoords.side2UpperInner,
        nextWall.sideCoords.side1LowerInner,
      ];
    }

    function _makeSide1Outer() {
      pointsThatFormThePlaneForProjectionSide1Lower = [
        nextWall.sideCoords.side1LowerOuter,
        nextWall.sideCoords.side2LowerOuter,
        nextWall.sideCoords.side1UpperOuter,
      ];

      pointsThatFormThePlaneForProjectionSide1Upper = [
        nextWall.sideCoords.side1UpperOuter,
        nextWall.sideCoords.side2UpperOuter,
        nextWall.sideCoords.side1LowerOuter,
      ];
    }

    function _makeSide1Lateral() {
      if (
        currentWallSide1LowerMiddle.almostEquals(
          nextWall.sideCoords.side1LowerMiddleV3
        )
      ) {
        pointsThatFormThePlaneForProjectionSide1Lower = [
          nextWall.sideCoords.side1LowerInner,
          nextWall.sideCoords.side1LowerOuter,
          nextWall.sideCoords.side1UpperInner,
        ];

        pointsThatFormThePlaneForProjectionSide1Upper = [
          nextWall.sideCoords.side1UpperInner,
          nextWall.sideCoords.side1UpperOuter,
          nextWall.sideCoords.side1LowerInner,
        ];
      } else if (
        currentWallSide1LowerMiddle.almostEquals(
          nextWall.sideCoords.side2LowerMiddleV3
        )
      ) {
        pointsThatFormThePlaneForProjectionSide1Lower = [
          nextWall.sideCoords.side2LowerInner,
          nextWall.sideCoords.side2LowerOuter,
          nextWall.sideCoords.side2UpperInner,
        ];

        pointsThatFormThePlaneForProjectionSide1Upper = [
          nextWall.sideCoords.side2UpperInner,
          nextWall.sideCoords.side2UpperOuter,
          nextWall.sideCoords.side2LowerInner,
        ];
      }
    }

    function _makeSide2Inner() {
      pointsThatFormThePlaneForProjectionSide2Lower = [
        previousWall.sideCoords.side1LowerInner,
        previousWall.sideCoords.side2LowerInner,
        previousWall.sideCoords.side1UpperInner,
      ];

      pointsThatFormThePlaneForProjectionSide2Upper = [
        previousWall.sideCoords.side1UpperInner,
        previousWall.sideCoords.side2UpperInner,
        previousWall.sideCoords.side1LowerInner,
      ];
    }

    function _makeSide2Outer() {
      pointsThatFormThePlaneForProjectionSide2Lower = [
        previousWall.sideCoords.side1LowerOuter,
        previousWall.sideCoords.side2LowerOuter,
        previousWall.sideCoords.side1UpperOuter,
      ];

      pointsThatFormThePlaneForProjectionSide2Upper = [
        previousWall.sideCoords.side1UpperOuter,
        previousWall.sideCoords.side2UpperOuter,
        previousWall.sideCoords.side1LowerOuter,
      ];
    }

    function _makeSide2Lateral() {
      if (
        currentWallSide2LowerMiddle.almostEquals(
          previousWall.sideCoords.side1LowerMiddleV3
        )
      ) {
        pointsThatFormThePlaneForProjectionSide2Lower = [
          previousWall.sideCoords.side1LowerInner,
          previousWall.sideCoords.side1LowerOuter,
          previousWall.sideCoords.side1UpperInner,
        ];

        pointsThatFormThePlaneForProjectionSide2Upper = [
          previousWall.sideCoords.side1UpperInner,
          previousWall.sideCoords.side1UpperOuter,
          previousWall.sideCoords.side1LowerInner,
        ];
      } else if (
        currentWallSide2LowerMiddle.almostEquals(
          previousWall.sideCoords.side2LowerMiddleV3
        )
      ) {
        pointsThatFormThePlaneForProjectionSide2Lower = [
          previousWall.sideCoords.side2LowerInner,
          previousWall.sideCoords.side2LowerOuter,
          previousWall.sideCoords.side2UpperInner,
        ];

        pointsThatFormThePlaneForProjectionSide2Upper = [
          previousWall.sideCoords.side2UpperInner,
          previousWall.sideCoords.side2UpperOuter,
          previousWall.sideCoords.side2LowerInner,
        ];
      }
    }

    function _doPreGenerationEdits() {
      if (
        currentWall.preGenerationEdit ===
        CONSTANTS.preGenerationEditTypes.scaleDown
      ) {
        const plinthHeight =
          store.projectProperties.properties.plinthHeightProperty.getValue();
        if (plinthHeight > currentWall.height) {
          // maybe should delete the wall
          wallIndicesToRemove.push(i);
          return;
        }

        let point, newPoint;

        // for water bodies upper and lower are ulta

        point = currentWall.sideCoords.side1LowerOuter;
        newPoint = point.clone();
        newPoint.y -= plinthHeight;
        _changeThePoint(currentWall, point, newPoint);

        point = currentWall.sideCoords.side1LowerInner;
        newPoint = point.clone();
        newPoint.y -= plinthHeight;
        _changeThePoint(currentWall, point, newPoint);

        point = currentWall.sideCoords.side2LowerOuter;
        newPoint = point.clone();
        newPoint.y -= plinthHeight;
        _changeThePoint(currentWall, point, newPoint);

        point = currentWall.sideCoords.side2LowerInner;
        newPoint = point.clone();
        newPoint.y -= plinthHeight;
        _changeThePoint(currentWall, point, newPoint);
      }
    }

    function _getNodeBelow(v1, v2){

      // v1-v2 should be uppper-lower
      const verticallyBelowNodes = virtualSketcher.structuralFindNodesAlongEdge(v1, v2);

      const verticallyBelowSameStorey = verticallyBelowNodes.filter(nodeLabel => {
        const storeyObject = StoreyMutation.queries.onWhichStoreyDoesTheHeightBelong(nodeLabel.vector.y);
        return storeyObject?.value === _componentOfInterest.storey;
      });

      const verticallyBelowSameStoreyOnBottomFaceGraph = verticallyBelowSameStorey.map(nodeLabel => {
        return virtualSketcher.lookup(nodeLabel.vector);
      });

      return _.compact(verticallyBelowSameStoreyOnBottomFaceGraph)[0];
    }

    const wallIndicesToRemove = [];

    let previousWall, currentWall, nextWall;

    let pointsThatFormThePlaneForProjectionSide1Lower,
      pointsThatFormThePlaneForProjectionSide1Upper,
      pointsThatFormThePlaneForProjectionSide2Lower,
      pointsThatFormThePlaneForProjectionSide2Upper;

    let currentWallSide1LowerMiddle, currentWallSide2LowerMiddle;

    let currentWallSide1LowerMiddleNodeLabel,
      currentWallSide2LowerMiddleNodeLabel;

    let needToVerifyInnerAndOuter = false;
    let forcedSide2InnerJunctions = false;
    let forcedSide1InnerJunctions = false;
    
    let existingWallObject;
    let junctionGeometryChangeCommandForAllWalls;

    let i, length;
    for (i = 0, length = walls.length; i < length; i++) {
      currentWall = walls[i];
      currentWallSide1LowerMiddle = currentWall.sideCoords.side1LowerMiddleV3;
      currentWallSide2LowerMiddle = currentWall.sideCoords.side2LowerMiddleV3;

      if (currentWall.isWallIrregular){
        if (!currentWall.sideCoords.side1LowerMiddleNodeLabel){
          const possibleNode = _getNodeBelow(
            currentWall.sideCoords.side1UpperMiddleV3,
            currentWall.sideCoords.side1LowerMiddleV3,
          );

          if (possibleNode) {
            currentWall.sideCoords.side1LowerMiddleNodeLabel = possibleNode;
          }
        }

        if (!currentWall.sideCoords.side2LowerMiddleNodeLabel){
          const possibleNode = _getNodeBelow(
            currentWall.sideCoords.side2UpperMiddleV3,
            currentWall.sideCoords.side2LowerMiddleV3,
          );

          if (possibleNode) {
            currentWall.sideCoords.side2LowerMiddleNodeLabel = possibleNode;
          }
        }

        if (
          !currentWall.sideCoords.side1LowerMiddleNodeLabel &&
          !currentWall.sideCoords.side2LowerMiddleNodeLabel
        ) {
          // at least one of them should be there
          continue;
        }
      }

      const edgeLabel = currentWall.edgeLabel;
      if (edgeLabel) {
        let edgeId = edgeLabel.id;
        if (currentWall.isAnInvertedWall)
          edgeId = _getIdForInvertedWall(edgeId);

        const wallForThisEdge =
          metadataPersist.wallResolutionData.edgeIdOccupierMap.get(edgeId);
        if (wallForThisEdge !== currentWall) {
          wallIndicesToRemove.push(i);
          continue;
        }
      }
      
      if (currentWall.createdWallUniqueId){
        // this is a pre existing wall, do not create again
        wallIndicesToRemove.push(i);
        
        existingWallObject = store.scene
          .getMeshByUniqueID(currentWall.createdWallUniqueId)
          .getSnaptrudeDS();
      }
      
      let junctionChangeCommandData;
      if (options.changeGeometry && existingWallObject){
        junctionChangeCommandData = commandUtils.geometryChangeOperations.getCommandData(
          existingWallObject.mesh
        );
      }

      currentWallSide1LowerMiddleNodeLabel =
        currentWall.sideCoords.side1LowerMiddleNodeLabel;
      currentWallSide2LowerMiddleNodeLabel =
        currentWall.sideCoords.side2LowerMiddleNodeLabel;

      if(!currentWallSide1LowerMiddleNodeLabel || !currentWallSide2LowerMiddleNodeLabel) continue;

      needToVerifyInnerAndOuter = false;

      previousWall = null;
      nextWall = null;

      _handleDebug(currentWallSide1LowerMiddle, currentWallSide2LowerMiddle);

      // const optionsForNeighbours = {isRevitImport : false, allWalls : walls};
      const optionsForNeighbours = { isRevitImport: options.isRevitImport, allWalls: walls };
      const neighbours = _determineNeighbours(currentWall, optionsForNeighbours);

      previousWall = neighbours.previousWall;
      nextWall = neighbours.nextWall;

      if (optionsForNeighbours.needToVerifyInnerAndOuter) needToVerifyInnerAndOuter = true;
      forcedSide2InnerJunctions = optionsForNeighbours.forcedSide2InnerJunctions
      forcedSide1InnerJunctions = optionsForNeighbours.forcedSide1InnerJunctions

      if (!(previousWall && nextWall)) {
        console.warn("Wall neighbours not determined");
    
      }

      let intersection;

      if (nextWall) {
        _handleSide1Junction();

        //Bottom coordinates Side 1

        intersection = _findIntersection(
          currentWall.sideCoords.side1LowerOuter,
          currentWall.sideCoords.side1LowerOuterNeighbour,
          ...pointsThatFormThePlaneForProjectionSide1Lower
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side1LowerOuter,
          intersection
        );

        intersection = _findIntersection(
          currentWall.sideCoords.side1LowerInner,
          currentWall.sideCoords.side1LowerInnerNeighbour,
          ...pointsThatFormThePlaneForProjectionSide1Lower
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side1LowerInner,
          intersection
        );

        // Top coordinates Side 1

        intersection = _findIntersection(
          currentWall.sideCoords.side1UpperOuter,
          currentWall.sideCoords.side1UpperOuterNeighbour,
          ...pointsThatFormThePlaneForProjectionSide1Upper
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side1UpperOuter,
          intersection
        );

        intersection = _findIntersection(
          currentWall.sideCoords.side1UpperInner,
          currentWall.sideCoords.side1UpperInnerNeighbour,
          ...pointsThatFormThePlaneForProjectionSide1Upper
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side1UpperInner,
          intersection
        );
      }

      if (previousWall) {
        _handleSide2Junction();
        // Bottom coordinates side 2

        intersection = _findIntersection(
          currentWall.sideCoords.side2LowerOuterNeighbour,
          currentWall.sideCoords.side2LowerOuter,
          ...pointsThatFormThePlaneForProjectionSide2Lower
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side2LowerOuter,
          intersection
        );

        intersection = _findIntersection(
          currentWall.sideCoords.side2LowerInnerNeighbour,
          currentWall.sideCoords.side2LowerInner,
          ...pointsThatFormThePlaneForProjectionSide2Lower
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side2LowerInner,
          intersection
        );

        // top coordinates side 2

        intersection = _findIntersection(
          currentWall.sideCoords.side2UpperOuterNeighbour,
          currentWall.sideCoords.side2UpperOuter,
          ...pointsThatFormThePlaneForProjectionSide2Upper
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side2UpperOuter,
          intersection
        );

        intersection = _findIntersection(
          currentWall.sideCoords.side2UpperInnerNeighbour,
          currentWall.sideCoords.side2UpperInner,
          ...pointsThatFormThePlaneForProjectionSide2Upper
        );
        _changeThePoint(
          currentWall,
          currentWall.sideCoords.side2UpperInner,
          intersection
        );
      }
      
      if (options.changeGeometry && existingWallObject){
        
        junctionChangeCommandData = commandUtils.geometryChangeOperations.getCommandData(
          existingWallObject.mesh,
          junctionChangeCommandData
        );
        
        const geometryChangeCommandForCurrentWall = commandUtils.geometryChangeOperations.getCommand(
          "junctionChangeOfExistingWall",
          junctionChangeCommandData
        );
        
        junctionGeometryChangeCommandForAllWalls = commandUtils.geometryChangeOperations.flattenCommands(
          [junctionGeometryChangeCommandForAllWalls, geometryChangeCommandForCurrentWall]
        );
        
        existingWallObject.mesh.BrepToMesh();
      }

      _doPreGenerationEdits();
    }

    wallIndicesToRemove.forEach((index, i) => {
      index = index - i;
      walls.splice(index, 1);
    });
    
    options.junctionGeometryChangeCommand = junctionGeometryChangeCommandForAllWalls;
    
    return walls;
  }

  const findAndFixOverlappingWalls = function (massWallDataMap) {
    const _onSegment3D = function (point1, point2, point3) {
      return store.resolveEngineUtils.onSegment3D(
        point1,
        point2,
        point3,
        null,
        true
      );
    };

    const _areWallsOverlapping = function (wallData1, wallData2, metadata) {
      const _getWallType = function (
        endPoint1,
        endPoint2,
        mass,
        commonPortion = false
      ) {
        let wallType = CONSTANTS.wallTypes.external;
        let components;
        const edgeLabel = virtualSketcher.lookupEdge(endPoint1, endPoint2);

        if (commonPortion) {
          components = [wallData1.mass, wallData2.mass];

          const fullHeightComponents = components.filter((c) => {
            return (
              !virtualSketcher.util.isComponentPlanar(c) &&
              !virtualSketcher.util.doesComponentGrowDownwards(c) &&
              !virtualSketcher.util.isComponentNotOfFullHeight(c)
            );
          });

          if (fullHeightComponents.length > 1)
            wallType = CONSTANTS.wallTypes.internal;
        } else if (edgeLabel) {
          components = edgeLabel.components;

          const fullHeightComponents = components.filter((c) => {
            return (
              !virtualSketcher.util.isComponentPlanar(c) &&
              !virtualSketcher.util.doesComponentGrowDownwards(c) &&
              !virtualSketcher.util.isComponentNotOfFullHeight(c)
            );
          });

          if (fullHeightComponents.length === 0) {
            if (_isBalcony(mass)) wallType = CONSTANTS.wallTypes.parapet;
            else wallType = CONSTANTS.wallTypes.external;
          } else {
            wallType = CONSTANTS.wallTypes.internal;
          }
        } else {
          if (_isBalcony(mass)) wallType = CONSTANTS.wallTypes.parapet;
          else wallType = CONSTANTS.wallTypes.external;
        }

        return wallType;
      };

      const _getWallTypeForCommonPortion = function (
        endPoint1,
        endPoint2,
        mass
      ) {
        return _getWallType(endPoint1, endPoint2, mass, true);
      };

      const _getYChange = function (wallHeight, massToWhichWallBelongs) {
        let totalHeight;
        if (_isBottomShiftRequired(massToWhichWallBelongs)) {
          totalHeight =
            wallHeight +
            store.projectProperties.properties.slabThicknessProperty.getValue();
        } else {
          totalHeight = wallHeight;
        }

        if (
          virtualSketcher.util.doesComponentGrowDownwards(
            massToWhichWallBelongs
          )
        ) {
          totalHeight = -totalHeight;
        }

        return totalHeight;
      };

      const _markAsOverlapping = function () {
        // makes it much easier to debug
        areOverlapping = true;
      };

      const _getWallPoints = function (vertices, wallType) {
        return getWallPoints(vertices, wallType, {
          forceReplacement: true,
        });

        // setting forceReplacement replaces any wall that might already be there that's identical
        // this is inefficient but makes a flow a little more predictable i.e all overlapping walls will be replaced
        // with new ones without exception
      };

      const direction1 = wallData1.direction;
      let direction2 = wallData2.direction;
      const threshold = 2;

      const angleBetweenThem = getAngleBetweenVectors(direction1, direction2);
      const areParallel =
        isFloatEqual(angleBetweenThem, 0, threshold) ||
        isFloatEqual(angleBetweenThem, 180, threshold);

      if (!areParallel) return false;

      let areInverted = false;
      if (isFloatEqual(angleBetweenThem, 180, threshold)) areInverted = true;

      const wall1Side1Middle = wallData1.sideCoords.side1LowerMiddleV3;
      const wall1Side2Middle = wallData1.sideCoords.side2LowerMiddleV3;

      let wall2Side1Middle, wall2Side2Middle;

      if (areInverted) {
        wall2Side1Middle = wallData2.sideCoords.side1LowerMiddleV3;
        wall2Side2Middle = wallData2.sideCoords.side2LowerMiddleV3;
      } else {
        wall2Side1Middle = wallData2.sideCoords.side2LowerMiddleV3;
        wall2Side2Middle = wallData2.sideCoords.side1LowerMiddleV3;
      }

      const areCollinear =
        areThreeVectorsCollinear(
          wall1Side1Middle,
          wall1Side2Middle,
          wall2Side1Middle
        ) &&
        areThreeVectorsCollinear(
          wall1Side1Middle,
          wall1Side2Middle,
          wall2Side2Middle
        );
      if (!areCollinear) return false;

      let areOverlapping = false;

      _handleDebug(wall1Side1Middle, wall1Side2Middle);
      _handleDebug(wall2Side1Middle, wall2Side2Middle);

      const fourPoints = [
        wall1Side1Middle,
        wall1Side2Middle,
        wall2Side1Middle,
        wall2Side2Middle,
      ];
      const equalityThreshold = 0.1;
      const uniquePoints = _.uniqWith(fourPoints, (p1, p2) =>
        p1.almostEquals(p2, equalityThreshold)
      );

      /*
      New lower points are generated in side2 -> side1 direction to maintain counter clockwise direction
       */

      const tallerWallHeight = Math.max(wallData1.height, wallData2.height);

      if (uniquePoints.length === 2) {
        // comes here when the vertices are far enough to create separate nodes
        // for normal masses, that would add a new vertex. For instances, it's resolved here

        if (wallData1.isAnInvertedWall !== wallData2.isAnInvertedWall) return;

        _markAsOverlapping();

        const lowerPoints1 = [wall1Side2Middle, wall1Side1Middle];
        const yChangeWall1 = _getYChange(tallerWallHeight, wallData1.mass);
        const upperPoints1 = lowerPoints1.map(
          (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
        );

        const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];

        const newWall1Type = _getWallTypeForCommonPortion(
          ...lowerPoints1,
          wallData1.mass
        );

        _componentOfInterest = wallData1.mass;
        const newWall1 = _getWallPoints(vertices1, newWall1Type);

        metadata.newWalls = [newWall1];
      } else if (uniquePoints.length === 3) {
        // meeting at a point, but overlap not established

        const wall1Side1Middle = wallData1.sideCoords.side1LowerMiddleV3;
        const wall1Side2Middle = wallData1.sideCoords.side2LowerMiddleV3;

        const wall2Side1Middle = wallData2.sideCoords.side1LowerMiddleV3;
        const wall2Side2Middle = wallData2.sideCoords.side2LowerMiddleV3;

        if (wall1Side1Middle.almostEquals(wall2Side1Middle)) {
          const wall1Side1ToWall1Side2Direction = wall1Side2Middle
            .subtract(wall1Side1Middle)
            .normalize();
          const wall1Side1ToWall2Side2Direction = wall2Side2Middle
            .subtract(wall1Side1Middle)
            .normalize();

          if (
            wall1Side1ToWall1Side2Direction.almostEquals(
              wall1Side1ToWall2Side2Direction
            )
          ) {
            if (
              _onSegment3D(wall1Side1Middle, wall2Side2Middle, wall1Side2Middle)
            ) {
              /*
                               side2    side1
                                 --------  wall2
                  wall1  ----------------
                      side2            side1

               */

              _markAsOverlapping();

              const lowerPoints1 = [wall1Side2Middle, wall2Side2Middle];
              const lowerPoints2 = [wall2Side2Middle, wall1Side1Middle];

              const yChangeWall1 = _getYChange(
                wallData1.height,
                wallData1.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                tallerWallHeight,
                wallData1.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallType(
                ...lowerPoints1,
                wallData1.mass
              );
              const newWall2Type = _getWallTypeForCommonPortion(
                ...lowerPoints2,
                wallData1.mass
              );

              _componentOfInterest = wallData1.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            } else if (
              _onSegment3D(wall2Side1Middle, wall1Side2Middle, wall2Side2Middle)
            ) {
              /*
                      side2              side1
                        ------------------  wall2
                      wall1  -------------
                          side2            side1

               */

              _markAsOverlapping();

              const lowerPoints1 = [wall2Side2Middle, wall1Side2Middle];
              const lowerPoints2 = [wall1Side2Middle, wall2Side1Middle];

              const yChangeWall1 = _getYChange(
                wallData2.height,
                wallData2.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                tallerWallHeight,
                wallData2.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallType(
                ...lowerPoints1,
                wallData2.mass
              );
              const newWall2Type = _getWallTypeForCommonPortion(
                ...lowerPoints2,
                wallData2.mass
              );

              _componentOfInterest = wallData2.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            }
          } else {
            /*
                             side1    side2
                               --------  wall2
                wall1  ---------
                    side2      side1

             */
            // no overlap
          }
        } else if (wall1Side1Middle.almostEquals(wall2Side2Middle)) {
          const wall1Side1ToWall1Side2Direction = wall1Side2Middle
            .subtract(wall1Side1Middle)
            .normalize();
          const wall1Side1ToWall2Side1Direction = wall2Side1Middle
            .subtract(wall1Side1Middle)
            .normalize();

          if (
            wall1Side1ToWall1Side2Direction.almostEquals(
              wall1Side1ToWall2Side1Direction
            )
          ) {
            if (
              _onSegment3D(wall1Side1Middle, wall2Side1Middle, wall1Side2Middle)
            ) {
              /*
                               side1    side2
                                 --------  wall2
                  wall1  ----------------
                      side2            side1
  
               */

              _markAsOverlapping();

              const lowerPoints1 = [wall1Side2Middle, wall2Side1Middle];
              const lowerPoints2 = [wall2Side1Middle, wall1Side1Middle];

              const yChangeWall1 = _getYChange(
                wallData1.height,
                wallData1.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                tallerWallHeight,
                wallData1.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallType(
                ...lowerPoints1,
                wallData1.mass
              );
              const newWall2Type = _getWallTypeForCommonPortion(
                ...lowerPoints2,
                wallData1.mass
              );

              _componentOfInterest = wallData1.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            } else if (
              _onSegment3D(wall2Side1Middle, wall1Side2Middle, wall2Side2Middle)
            ) {
              /*
                      side1              side2
                        ------------------  wall2
                      wall1  -------------
                          side2            side1
  
               */

              _markAsOverlapping();

              const lowerPoints1 = [wall2Side2Middle, wall1Side2Middle];
              const lowerPoints2 = [wall1Side2Middle, wall2Side1Middle];

              const yChangeWall1 = _getYChange(
                tallerWallHeight,
                wallData2.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                wallData2.height,
                wallData2.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallTypeForCommonPortion(
                ...lowerPoints1,
                wallData2.mass
              );
              const newWall2Type = _getWallType(
                ...lowerPoints2,
                wallData2.mass
              );

              _componentOfInterest = wallData2.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            }
          } else {
            /*
                             side2    side1
                               --------  wall2
                wall1  ---------
                    side2      side1

             */
            // no overlap
          }
        } else if (wall1Side2Middle.almostEquals(wall2Side1Middle)) {
          const wall1Side2ToWall1Side1Direction = wall1Side1Middle
            .subtract(wall1Side2Middle)
            .normalize();
          const wall1Side2ToWall2Side2Direction = wall2Side2Middle
            .subtract(wall1Side2Middle)
            .normalize();

          if (
            wall1Side2ToWall1Side1Direction.almostEquals(
              wall1Side2ToWall2Side2Direction
            )
          ) {
            if (
              _onSegment3D(wall1Side1Middle, wall2Side2Middle, wall1Side2Middle)
            ) {
              /*
                               side2    side1
                                 --------  wall2
                  wall1  ----------------
                      side1            side2

               */

              _markAsOverlapping();

              const lowerPoints1 = [wall1Side2Middle, wall2Side2Middle];
              const lowerPoints2 = [wall2Side2Middle, wall1Side1Middle];

              const yChangeWall1 = _getYChange(
                tallerWallHeight,
                wallData1.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                wallData1.height,
                wallData1.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallTypeForCommonPortion(
                ...lowerPoints1,
                wallData1.mass
              );
              const newWall2Type = _getWallType(
                ...lowerPoints2,
                wallData1.mass
              );

              _componentOfInterest = wallData1.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            } else if (
              _onSegment3D(wall2Side1Middle, wall1Side1Middle, wall2Side2Middle)
            ) {
              /*
                      side2              side1
                        ------------------  wall2
                      wall1  -------------
                          side1            side2
  
               */

              _markAsOverlapping();

              const lowerPoints1 = [wall2Side2Middle, wall1Side1Middle];
              const lowerPoints2 = [wall1Side1Middle, wall2Side1Middle];

              const yChangeWall1 = _getYChange(
                wallData2.height,
                wallData2.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                tallerWallHeight,
                wallData2.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallType(
                ...lowerPoints1,
                wallData2.mass
              );
              const newWall2Type = _getWallTypeForCommonPortion(
                ...lowerPoints2,
                wallData2.mass
              );

              _componentOfInterest = wallData2.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            }
          } else {
            /*
                             side1    side2
                               --------  wall2
                wall1  ---------
                    side1      side2
  
             */
            // no overlap
          }
        } else if (wall1Side2Middle.almostEquals(wall2Side2Middle)) {
          const wall1Side2ToWall1Side1Direction = wall1Side1Middle
            .subtract(wall2Side1Middle)
            .normalize();
          const wall1Side2ToWall2Side1Direction = wall2Side1Middle
            .subtract(wall2Side1Middle)
            .normalize();

          if (
            wall1Side2ToWall1Side1Direction.almostEquals(
              wall1Side2ToWall2Side1Direction
            )
          ) {
            if (
              _onSegment3D(wall1Side1Middle, wall2Side1Middle, wall1Side2Middle)
            ) {
              /*
                               side1    side2
                                 --------  wall2
                  wall1  ----------------
                      side1            side2

               */

              _markAsOverlapping();

              const lowerPoints1 = [wall1Side2Middle, wall2Side1Middle];
              const lowerPoints2 = [wall2Side1Middle, wall1Side1Middle];

              const yChangeWall1 = _getYChange(
                tallerWallHeight,
                wallData1.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                wallData1.height,
                wallData1.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallTypeForCommonPortion(
                ...lowerPoints1,
                wallData1.mass
              );
              const newWall2Type = _getWallType(
                ...lowerPoints2,
                wallData1.mass
              );

              _componentOfInterest = wallData1.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            } else if (
              _onSegment3D(wall2Side1Middle, wall1Side1Middle, wall2Side2Middle)
            ) {
              /*
                      side1              side2
                        ------------------  wall2
                      wall1  -------------
                          side1           side2

               */

              _markAsOverlapping();

              const lowerPoints1 = [wall2Side2Middle, wall1Side1Middle];
              const lowerPoints2 = [wall1Side1Middle, wall2Side1Middle];

              const yChangeWall1 = _getYChange(
                tallerWallHeight,
                wallData2.mass
              );
              const upperPoints1 = lowerPoints1.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
              );

              const yChangeWall2 = _getYChange(
                wallData2.height,
                wallData2.mass
              );
              const upperPoints2 = lowerPoints2.map(
                (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
              );

              const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
              const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

              const newWall1Type = _getWallTypeForCommonPortion(
                ...lowerPoints1,
                wallData2.mass
              );
              const newWall2Type = _getWallType(
                ...lowerPoints2,
                wallData2.mass
              );

              _componentOfInterest = wallData2.mass;
              const newWall1 = _getWallPoints(vertices1, newWall1Type);
              const newWall2 = _getWallPoints(vertices2, newWall2Type);

              metadata.newWalls = [newWall1, newWall2];
            }
          } else {
            /*
                             side2    side1
                               --------  wall2
                wall1  ---------
                    side1      side2

             */
            // no overlap
          }
        }
      } else if (uniquePoints.length === 4) {
        const allDistances = [
          getDistanceBetweenVectors(wall1Side1Middle, wall2Side1Middle),
          getDistanceBetweenVectors(wall1Side1Middle, wall2Side2Middle),
          getDistanceBetweenVectors(wall1Side2Middle, wall2Side1Middle),
          getDistanceBetweenVectors(wall1Side2Middle, wall2Side2Middle),
        ];

        const maxDistance = _.max(allDistances);

        const sumOfLengths =
          getDistanceBetweenVectors(wall1Side1Middle, wall1Side2Middle) +
          getDistanceBetweenVectors(wall2Side1Middle, wall2Side2Middle);

        if (maxDistance > sumOfLengths) return;

        const side1ToSide1Direction = wall1Side1Middle
          .subtract(wall2Side1Middle)
          .normalize();
        const side1ToSide2Direction = wall1Side1Middle
          .subtract(wall2Side2Middle)
          .normalize();
        const side2ToSide1Direction = wall1Side2Middle
          .subtract(wall2Side1Middle)
          .normalize();
        const side2ToSide2Direction = wall1Side2Middle
          .subtract(wall2Side2Middle)
          .normalize();

        // when collinearity has been established, equality check can be used as a substitute for same
        // and opposite direction

        const equalityThreshold = 0.5;

        const junctionNotOnSide1 = side1ToSide1Direction.almostEquals(
          side1ToSide2Direction,
          equalityThreshold
        );
        const junctionNotOnSide2 = side2ToSide1Direction.almostEquals(
          side2ToSide2Direction,
          equalityThreshold
        );

        /*
        Example-

        junctionNotOnSide1 is true for this case, junctionNotOnSide2 is false (values wrt wall1)

                    side1   side2
                      --------  wall2
          wall1  ---------
            side1       side2

         */

        if (junctionNotOnSide1 === junctionNotOnSide2) {
          // either complete between wall1 and wall2 or no overlap
          if (
            _onSegment3D(
              wall1Side1Middle,
              wall2Side1Middle,
              wall1Side2Middle
            ) &&
            _onSegment3D(wall1Side1Middle, wall2Side2Middle, wall1Side2Middle)
          ) {
            /*
                  side1/2     side2/1
                       --------  wall2
              wall1  --------------------
                  side1                 side2

           */

            // wall 2 is 'within' wall 1, so stays as is
            _markAsOverlapping();

            const lowerPoints1 = [wall1Side2Middle, null];
            const lowerPoints2 = [null, wall1Side1Middle];

            if (areInverted) {
              lowerPoints1[1] = wall2Side1Middle;
              lowerPoints2[0] = wall2Side2Middle;
            } else {
              lowerPoints1[1] = wall2Side2Middle;
              lowerPoints2[0] = wall2Side1Middle;
            }

            const yChangeWalls1And3 = _getYChange(
              wallData1.height,
              wallData1.mass
            );
            const upperPoints1 = lowerPoints1.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWalls1And3, v3.z)
            );
            const upperPoints2 = lowerPoints2.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWalls1And3, v3.z)
            );

            const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
            const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

            const newWall1Type = _getWallType(...lowerPoints1, wallData1.mass);
            const newWall2Type = _getWallType(...lowerPoints2, wallData1.mass);

            _componentOfInterest = wallData1.mass;
            const newWall1 = _getWallPoints(vertices1, newWall1Type);
            const newWall2 = _getWallPoints(vertices2, newWall2Type);

            _componentOfInterest = wallData2.mass;
            const lowerPoints3 = [
              wallData2.sideCoords.side2LowerMiddleV3,
              wallData2.sideCoords.side1LowerMiddleV3,
            ];
            const yChangeWall2 = _getYChange(tallerWallHeight, wallData2.mass);
            const upperPoints3 = lowerPoints3.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
            );
            const vertices3 = [...lowerPoints3, ...upperPoints3.reverse()];

            const newWall3Type = _getWallTypeForCommonPortion(
              ...lowerPoints3,
              wallData2.mass
            );
            const newWall3 = _getWallPoints(vertices3, newWall3Type);

            metadata.newWalls = [newWall1, newWall2, newWall3];
          } else if (
            _onSegment3D(
              wall2Side1Middle,
              wall1Side1Middle,
              wall2Side2Middle
            ) &&
            _onSegment3D(wall2Side1Middle, wall1Side2Middle, wall2Side2Middle)
          ) {
            /*
                  side1/2             side2/1
                    -----------------------  wall2
                   wall1 --------------
                      side1             side2

           */

            // wall 1 is 'within' wall 2
            _markAsOverlapping();

            const lowerPoints1 = [wall2Side2Middle, null];
            const lowerPoints2 = [null, wall2Side1Middle];

            if (areInverted) {
              lowerPoints1[1] = wall1Side1Middle;
              lowerPoints2[0] = wall1Side2Middle;
            } else {
              lowerPoints1[1] = wall1Side2Middle;
              lowerPoints2[0] = wall1Side1Middle;
            }

            const yChangeWalls1And3 = _getYChange(
              wallData2.height,
              wallData2.mass
            );
            const upperPoints1 = lowerPoints1.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWalls1And3, v3.z)
            );
            const upperPoints2 = lowerPoints2.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWalls1And3, v3.z)
            );

            const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
            const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

            const newWall1Type = _getWallType(...lowerPoints1, wallData2.mass);
            const newWall2Type = _getWallType(...lowerPoints2, wallData2.mass);

            _componentOfInterest = wallData2.mass;
            const newWall1 = _getWallPoints(vertices1, newWall1Type);
            const newWall2 = _getWallPoints(vertices2, newWall2Type);

            _componentOfInterest = wallData1.mass;
            const lowerPoints3 = [
              wallData1.sideCoords.side2LowerMiddleV3,
              wallData1.sideCoords.side1LowerMiddleV3,
            ];
            const yChangeWall2 = _getYChange(tallerWallHeight, wallData1.mass);
            const upperPoints3 = lowerPoints3.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
            );
            const vertices3 = [...lowerPoints3, ...upperPoints3.reverse()];

            const newWall3Type = _getWallTypeForCommonPortion(
              ...lowerPoints3,
              wallData1.mass
            );
            const newWall3 = _getWallPoints(vertices3, newWall3Type);

            metadata.newWalls = [newWall1, newWall2, newWall3];
          } else {
            /*
                                        side1/2     side2/1
                                             --------  wall2
            wall1  --------------------
                side1                 side2

             */
            // no overlap
          }
        } else {
          _markAsOverlapping();

          const junctionOnSide1 = !junctionNotOnSide1;
          const junctionOnSide2 = !junctionNotOnSide2;

          if (junctionOnSide1) {
            /*
              side1       side2               side2       side1   if (areInverted)
                  -----------  wall2             ------------  wall2
                wall1  ---------
                  side1       side2

           */

            const lowerPoints1 = [wall1Side2Middle, null];
            const lowerPoints2 = [null, wall1Side1Middle];

            if (areInverted) {
              lowerPoints1[1] = wall2Side1Middle;
              lowerPoints2[0] = wall2Side1Middle;
            } else {
              lowerPoints1[1] = wall2Side2Middle;
              lowerPoints2[0] = wall2Side2Middle;
            }

            const yChangeWall1 = _getYChange(wallData1.height, wallData1.mass);
            const upperPoints1 = lowerPoints1.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
            );
            const yChangeWall2 = _getYChange(tallerWallHeight, wallData1.mass);
            const upperPoints2 = lowerPoints2.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
            );

            const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
            const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

            const newWall1Type = _getWallType(...lowerPoints1, wallData1.mass);
            const newWall2Type = _getWallTypeForCommonPortion(
              ...lowerPoints2,
              wallData1.mass
            );

            _componentOfInterest = wallData1.mass;
            const newWall1 = _getWallPoints(vertices1, newWall1Type);
            const newWall2 = _getWallPoints(vertices2, newWall2Type);

            _componentOfInterest = wallData2.mass;

            let lowerPoints3;

            if (areInverted) {
              lowerPoints3 = [wall2Side2Middle, wall1Side1Middle];
            } else {
              lowerPoints3 = [wall1Side1Middle, wall2Side1Middle];
            }

            const yChangeWall3 = _getYChange(wallData2.height, wallData2.mass);
            const upperPoints3 = lowerPoints3.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall3, v3.z)
            );
            const vertices3 = [...lowerPoints3, ...upperPoints3.reverse()];

            const newWall3Type = _getWallType(...lowerPoints3, wallData2.mass);
            const newWall3 = _getWallPoints(vertices3, newWall3Type);

            metadata.newWalls = [newWall1, newWall2, newWall3];
          } else if (junctionOnSide2) {
            /*
                      side1   side2
                        --------  wall2
            wall1  ---------
              side1       side2

           */

            const lowerPoints1 = [wall1Side2Middle, null];
            const lowerPoints2 = [null, wall1Side1Middle];

            if (areInverted) {
              lowerPoints1[1] = wall2Side2Middle;
              lowerPoints2[0] = wall2Side2Middle;
            } else {
              lowerPoints1[1] = wall2Side1Middle;
              lowerPoints2[0] = wall2Side1Middle;
            }

            const yChangeWall1 = _getYChange(tallerWallHeight, wallData1.mass);
            const upperPoints1 = lowerPoints1.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall1, v3.z)
            );
            const yChangeWall2 = _getYChange(wallData1.height, wallData1.mass);
            const upperPoints2 = lowerPoints2.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall2, v3.z)
            );

            const vertices1 = [...lowerPoints1, ...upperPoints1.reverse()];
            const vertices2 = [...lowerPoints2, ...upperPoints2.reverse()];

            const newWall1Type = _getWallTypeForCommonPortion(
              ...lowerPoints1,
              wallData1.mass
            );
            const newWall2Type = _getWallType(...lowerPoints2, wallData1.mass);

            _componentOfInterest = wallData1.mass;
            const newWall1 = _getWallPoints(vertices1, newWall1Type);
            const newWall2 = _getWallPoints(vertices2, newWall2Type);

            _componentOfInterest = wallData2.mass;

            let lowerPoints3;

            if (areInverted) {
              lowerPoints3 = [wall1Side2Middle, wall2Side1Middle];
            } else {
              lowerPoints3 = [wall2Side2Middle, wall1Side2Middle];
            }

            const yChangeWall3 = _getYChange(wallData2.height, wallData2.mass);
            const upperPoints3 = lowerPoints3.map(
              (v3) => new BABYLON.Vector3(v3.x, v3.y + yChangeWall3, v3.z)
            );
            const vertices3 = [...lowerPoints3, ...upperPoints3.reverse()];

            const newWall3Type = _getWallType(...lowerPoints3, wallData2.mass);
            const newWall3 = _getWallPoints(vertices3, newWall3Type);

            metadata.newWalls = [newWall1, newWall2, newWall3];
          }
        }
      }

      return areOverlapping;
    };

    const nodeIdWallDataMap =
      metadataPersist.wallJunctionData.nodeIdWallDataMap;

    let areOverlapping;
    massWallDataMap.forEach((wallsData1, mass1) => {
      wallsData1.forEach((wallData1) => {
        if (wallData1.edgeLabel && wallData1.edgeLabel.weight > 1) return;

        massWallDataMap.forEach((wallsData2, mass2) => {
          if (mass2 === mass1) return;
          if (mass2.storey !== mass1.storey) return;
          if (areOverlapping) return;

          wallsData2.some((wallData2) => {
            if (wallData2.edgeLabel && wallData2.edgeLabel.weight > 1) return;
            if (wallData1.isAnInvertedWall !== wallData2.isAnInvertedWall)
              return;

            try {
              const metadata = {};
              areOverlapping = _areWallsOverlapping(
                wallData1,
                wallData2,
                metadata
              );
              if (areOverlapping) {
                /*
                const oneUpOneDown = wallData1.isAnInvertedWall !== wallData2.isAnInvertedWall;
  
                if (wallData1.isAnInvertedWall){
                    if (oneUpOneDown){
                        _addWaterbodyCustomProperties(wallData1);
                    }
                }
                else {
                    const wall1Side1NodeData = nodeIdWallDataMap.get(wallData1.sideCoords.side1LowerMiddleNodeLabel.id);
                    const wall1Side2NodeData = nodeIdWallDataMap.get(wallData1.sideCoords.side2LowerMiddleNodeLabel.id);
                    _.remove(wall1Side1NodeData, wallData1);
                    _.remove(wall1Side2NodeData, wallData1);
                    _.remove(wallsData1, wallData1);
                }
  
                if (wallData2.isAnInvertedWall){
                    if (oneUpOneDown){
                        _addWaterbodyCustomProperties(wallData2);
                    }
                }
                else {
                    const wall2Side1NodeData = nodeIdWallDataMap.get(wallData2.sideCoords.side1LowerMiddleNodeLabel.id);
                    const wall2Side2NodeData = nodeIdWallDataMap.get(wallData2.sideCoords.side2LowerMiddleNodeLabel.id);
                    _.remove(wall2Side1NodeData, wallData2);
                    _.remove(wall2Side2NodeData, wallData2);
                    _.remove(wallsData2, wallData2);
                }
                */

                const wall1Side1NodeData = nodeIdWallDataMap.get(
                  wallData1.sideCoords.side1LowerMiddleNodeLabel.id
                );
                const wall1Side2NodeData = nodeIdWallDataMap.get(
                  wallData1.sideCoords.side2LowerMiddleNodeLabel.id
                );
                _.remove(wall1Side1NodeData, wallData1);
                _.remove(wall1Side2NodeData, wallData1);
                _.remove(wallsData1, wallData1);

                const wall2Side1NodeData = nodeIdWallDataMap.get(
                  wallData2.sideCoords.side1LowerMiddleNodeLabel.id
                );
                const wall2Side2NodeData = nodeIdWallDataMap.get(
                  wallData2.sideCoords.side2LowerMiddleNodeLabel.id
                );
                _.remove(wall2Side1NodeData, wallData2);
                _.remove(wall2Side2NodeData, wallData2);
                _.remove(wallsData2, wallData2);

                // Kuladeep Incomplete Changes
                // virtualSketcher.coreGraphOperations.removeIndividualEdge(
                //     wallData1.sideCoords.side1LowerMiddleNodeLabel.vector,
                //     wallData1.sideCoords.side2LowerMiddleNodeLabel.vector,
                //     wallData1.mass
                // );

                // virtualSketcher.coreGraphOperations.removeIndividualEdge(
                //     wallData2.sideCoords.side1LowerMiddleNodeLabel.vector,
                //     wallData2.sideCoords.side2LowerMiddleNodeLabel.vector,
                //     wallData2.mass
                // );

                metadata.newWalls.forEach((wallData) => {
                  if (wallData.mass === mass1) wallsData1.push(wallData);
                  else if (wallData.mass === mass2) wallsData2.push(wallData);

                  // Kuladeep Incomplete Changes
                  // wallData.edgeLabel = virtualSketcher.coreGraphOperations.addIndividualEdge(
                  //     wallData.sideCoords.side1LowerMiddleNodeLabel.vector,
                  //     wallData.sideCoords.side2LowerMiddleNodeLabel.vector,
                  //     wallData.mass
                  // );
                });
              }
            } catch (e) {
              console.warn(e);
            }

            return areOverlapping;
          });
        });

        areOverlapping = false;
      });
    });
  };

  const findMasterNodes = function () {
    const bottomAdjacencyGraph = virtualSketcher.getBottomAdjacencyGraph();
    const nodeLabelToMasterNodeLabelMap =
      metadataPersist.internalDimensionData.nodeLabelToMasterNodeLabelMap;

    const thresholdDistance = _getDimensionTuningThreshold();

    bottomAdjacencyGraph.nodes().forEach((node) => {
      const nodeLabel = bottomAdjacencyGraph.node(node);
      if (nodeLabelToMasterNodeLabelMap.has(nodeLabel)) return;

      nodeLabelToMasterNodeLabelMap.set(nodeLabel, nodeLabel);

      const neighbours = bottomAdjacencyGraph.neighbors(node);
      const neighbourLabels = neighbours.map((n) =>
        bottomAdjacencyGraph.node(n)
      );

      const closeNeighbourLabels = neighbourLabels.filter((neighbourLabel) => {
        return (
          getDistanceBetweenVectors(nodeLabel.vector, neighbourLabel.vector) <
          thresholdDistance
        );
      });

      if (_.isEmpty(closeNeighbourLabels)) return;

      closeNeighbourLabels.forEach((c) =>
        nodeLabelToMasterNodeLabelMap.set(c, nodeLabel)
      );
    });
  };
  
  const cleanUp = function () {
    metadataPersist.wallJunctionData.nodeIdWallDataMap = new Map();
    metadataPersist.wallJunctionData.nodeIdJunctionOccupierMap = new Map();
    metadataPersist.wallJunctionData.irregularWalls = [];
    metadataPersist.wallResolutionData.edgeIdOccupierMap = new Map();
    metadataPersist.wallResolutionData.edgeIdAllWallsMap = new Map();
    metadataPersist.internalDimensionData.nodeLabelToMasterNodeLabelMap =
      new Map();
    
    _componentOfInterest = null;
    _meshOfInterest = null;
  };

  const addForDebug = function (v1, v2, flush = false) {
    if (flush) debugData.length = 0;
    debugData.push([v1, v2]);
  };

  const getExternalWallThickness = function () {
    return store.projectProperties.properties[
      "wallThicknessProperty" + CONSTANTS.wallTypes.external
    ].getValue();
  };

  const getInternalWallThickness = function () {
    return store.projectProperties.properties[
      "wallThicknessProperty" + CONSTANTS.wallTypes.internal
    ].getValue();
  };

  const getParapetWallThickness = function () {
    return store.projectProperties.properties[
      "wallThicknessProperty" + CONSTANTS.wallTypes.parapet
    ].getValue();
  };

  const getMetaDataPersist = function () {
    return metadataPersist;
  };

  return {
    CONSTANTS,
    dissect,
    handleWallJunctions,
    allotJunctionOccupationRights,
    getWallPoints,
    cleanUp,
    findAndFixOverlappingWalls,
    findMasterNodes,
    addForDebug,
    getMetaDataPersist,
    getExternalWallThickness,
    getInternalWallThickness,
    getParapetWallThickness,
  };
})();
export { massDissector };
