export const MONTHLY = "Monthly";
export const YEARLY = "Yearly";
export const DISCLAIMER_TEXT = "By placing your order, you agree with our";
export const TERMS_AND_CONDITONS = "Terms of service & Privacy policy";
export const PROCEED_TO_CHECKOUT = "Proceed to Checkout";
export const MAKE_PAYMENT = "Make Payment";
export const YOUR_PLAN = "Your Plan";
export const NUMBER_OF_LICENCES = "Number of Licenses";
export const APPLY_CODE = "Apply Code";
export const LICENSE_COST = "License Cost";
export const BILLED = "Billed";
export const TOTAL_BILLED = "Total Billed";
export const DOLLAR_PRICE = (price) => `$${price}`;
export const PER_USER = "/ Year Per User";
export const PER_USER_MONTH = "/ Month Per User";
export const PER_USER_YEAR = "/ Year Per User";
export const YOU_SAVED = (price, currencySymbol) => `Total Savings ${currencySymbol}${price}`;
export const NAME_ON_CARD = "Name on Card";
export const CARD_NUMBER = "Card Number";
export const EXPIRY = "Expiry";
export const MM = "MM";
export const YY = "YY";
export const CVV_LABEL = "CVC / CVV";
export const COUNTRY = "Country";
export const ADDRESS = "Address";
export const CITY = "City";
export const STATE = "State";
export const ZIP = "Zip";

// temporary dummy constants
export const PROFESSIONAL = "Professional";
export const PRICE_YEARLY = 2000;
export const PRICE_MONTHLY = 200;
export const SAVED_PRICE = 400;

// export const STRIP_PUBLISHABLE_KEY = 'pk_test_wcIF99fJ5lDpspKLKrGVLPV400ffAf5lkA';
export const STRIP_PUBLISHABLE_KEY = 'pk_live_Br7g2EFk35yRAJUAqRI3Jgim00faouqzwe';

export const CURRENCY_SYMBOLS = {
  'inr': '₹',
  'usd': '$',
  'gbp': '£'
}

export const PRICES ={
  pro: {
    inr : {
      'Monthly': {
        id: 'price_1JjL4DKK4Vw3YYjTpE90WW7A',
        unitAmount: 450000
      },
      'Yearly': {
        id: 'price_1JjL4bKK4Vw3YYjTzLNOWyCZ',
        unitAmount: 4500000
      }
    },
    usd : {
      'Monthly': {
        id: 'price_1JWfsmKK4Vw3YYjTRPw90yAO',
        unitAmount: 4900
      },
      'Yearly': {
        id: 'price_1JWfsmKK4Vw3YYjTF3mUB3oe',
        unitAmount: 49900
      }
    },
    gbp : {
      'Monthly': {
        id: 'price_1JWfsmKK4Vw3YYjTBwvFo3qb',
        unitAmount: 3900
      },
      'Yearly': {
        id: 'price_1JWfsmKK4Vw3YYjTKa9iWCvI',
        unitAmount: 39900
      }
    },
  },
  organization: {
    inr : {
      'Monthly': {
        id: 'price_1JjL7tKK4Vw3YYjT9oKOgnkA',
        unitAmount: 750000
      },
      'Yearly': {
        id: 'price_1JjL8BKK4Vw3YYjT14gD7WgG',
        unitAmount: 7500000
      }
    },
    usd : {
      'Monthly': {
        id: 'price_1JWfqUKK4Vw3YYjTQk0wayNK',
        unitAmount: 11900
      },
      'Yearly': {
        id: 'price_1JWfqUKK4Vw3YYjTpgsQ3i3k',
        unitAmount: 119900
      }
    },
    gbp : {
      'Monthly': {
        id: 'price_1JWfqUKK4Vw3YYjTScSJqNLV',
        unitAmount: 9000
      },
      'Yearly': {
        id: 'price_1JWfqUKK4Vw3YYjTGfVBQ1KC',
        unitAmount: 90000
      }
    },
  }
}