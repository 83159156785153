import React, { useEffect } from "react";
import { store } from "../../../../snaptrude/modules/utilityFunctions/Store";
import { useSelector } from "react-redux";
import { Action, getCoveAnalysisState } from "../../../../snaptrude/stateManagers/reducers/objectProperties/coveAnalysisSlice";
import * as S from './styles'
import { DaylightAnalysisRecord } from './other'
import API from "../../../../services/covetool.service";
import { useDispatch } from "react-redux";
import { handleUncompletedRecord, pollCompleteCallback, pollStartCallback, Polling } from "../../../../snaptrude/modules/covetool/utils";

/**
 * Sustainability Metrics Sidebar
 * @returns {JSX.Element}
 */
const DARecordsSidebar = () => {
  const dispatch = useDispatch()
  // Redux State
  const coveAnalysisState = useSelector(getCoveAnalysisState);
  const { runningAnalysisTable, records, fetchingRecords: loading, coveProjectDetails } = coveAnalysisState
  const runningAnalysis = runningAnalysisTable[store.floorkey]

  useEffect(() => {
    if(loading) return

    // set loader true for fetching all records
    dispatch(Action.setFetchingRecordsAs(true))

    // fetch all records
    API.getDaylightAnalysisInfoAll(coveProjectDetails?.parent_floor_key)
    .then(recordsData => {
        // sort by latest asc
        recordsData = recordsData.sort((a, b) => new Date(b.added) - new Date(a.added))

        // poll for uncompleted records
        recordsData.forEach(record => {
            if(!record?.completed) {
              const poller = new Polling(handleUncompletedRecord(record), {},
                pollStartCallback(record?.floor_key),
                pollCompleteCallback(record?.floor_key)
              )
              poller.start()
            }
        })

        // set all records in redux
        dispatch(Action.setRecords(recordsData))
        // set loader false for fetching all records
        dispatch(Action.setFetchingRecordsAs(false))
        
    })
  }, [])

  if(loading) {
    return (
      <S.MainContainer>
        <S.CenterElement>Loading...</S.CenterElement>
      </S.MainContainer>
    )
  }

  return (
    <S.MainContainer>
      {
        Array.isArray(records) && records.length > 0 ? records.map(record => 
        <DaylightAnalysisRecord key={record?.floor_key} running={runningAnalysis && runningAnalysis[record?.floor_key]} data={record} /> ):
        <S.CenterElement>No data</S.CenterElement>
      }
    </S.MainContainer>
  );
};

export default DARecordsSidebar;
