import React from 'react';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './app/App.js';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import { ORIGIN, socketUrl, environment } from './app/services/url.constants';
import { version } from '../package.json';

Sentry.init({
  dsn: "https://feddbf973deb4ec39b1c4be6d5b6a397@o82159.ingest.sentry.io/6208151",
  integrations: [new BrowserTracing(
    {
      tracingOrigins: [ORIGIN, socketUrl, /^\//],
    }
  )],
  release: "snaptrudereact@" + version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment,
});

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
window.hsConversationsSettings = {
  loadImmediately: false,
  inlineEmbedSelector: '#hubspot-conversations-inline-parent',
  enableWidgetCookieBanner: true,
  disableAttachment: true
};

axios.interceptors.request.use(
  (request) => {
    const ignorePaths = ["/register/", "/snaplogin/", "/refreshAccessToken/", "/sendResetPasswordMail/", "/resetPassword/"];
    const path = new URL(request.url).pathname;

    if (ignorePaths.includes(path)) return request;

    const accessToken = JSON.parse(localStorage.getItem("user"));
    request.headers.Auth = "Bearer " + accessToken;
    // const fd = new FormData();
    // if(request.data){
    //   for (let item of request.data.entries()){
    //     console.log(item[0], item[1]);
    //     fd.append(item[0], item[1]);
    //   }
    // }
    // fd.append("accessToken", accessToken);
    // request.data = fd;

    // let csrftoken = document.cookie.replace(/(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    // request.headers['X-CSRFToken'] = csrftoken;

    return request;
  },
  (err) => {
    console.log(err);
    return Promise.reject();
  }
);

axios.interceptors.response.use(
  async (response) => {
    if (response.data.error && response.data.isTokenExpired) {
      // HANDLE TOKEN EXPIRED
      const accessToken = JSON.parse(localStorage.getItem("user"));
      const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

      // GET NEW ACCESS TOKEN.
      const resp = await axios.post(
        ORIGIN + "/refreshAccessToken/",
        {
          accessToken,
          refreshToken,
        }
      );

      if (resp.data.accessToken) {
        // UPDATE ACCESS TOKEN AND RETRY THE ORIGINAL REQUEST.

        localStorage.setItem("user", JSON.stringify(resp.data.accessToken));

        const originalResponse = await axios(response.config);

        if (!originalResponse.data.error) return originalResponse;
      }
      return resp;
    }else if(response.data.error === 2) {
      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
    }
    return response;
  },
  (err) => {
    console.log(err)
    return Promise.reject(err);
  }
);



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
