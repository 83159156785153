import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js"
import { getUnitNormalToEdge,lineParallelToWhichAxis } from "./snapUtilities.js";
import { projectionOfPointOnLine,getAngleBetweenVectors,isFloatEqual } from "./snapFuncs.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { externalUtil } from "../modules/externalUtil.js";
import snapEngine from "../modules/snappingEngine/snapEngine";

function findSnapPointPerpendicularToEdge(
  referencePoint,
  edgePoint1,
  edgePoint2,
  threshold
) {
  /*
      For the given edge, there are two main lines that are snap eligible
      1. Line passing through first point and perpendicular to the edge
      2. Line passing through second point and perpendicular to the edge

      Considers only edges which intersect with xz plane
     */

  let normalSnapPoint, firstSnapPoint;
  
  const normalSnapEnabled = store.projectProperties.properties.normalSnapEnabled.getValue();
  if (!normalSnapEnabled) return { normalSnapPoint };

  let unitNormal = getUnitNormalToEdge(edgePoint1, edgePoint2);
  if (unitNormal) {
    const midPoint = BABYLON.Vector3.Center(edgePoint1, edgePoint2);

    let trajectory1 = [
      edgePoint1.add(unitNormal),
      edgePoint1.add(unitNormal.negate()),
    ];
    let trajectory2 = [
      edgePoint2.add(unitNormal),
      edgePoint2.add(unitNormal.negate()),
    ];
    let trajectory3 = [
      midPoint.add(unitNormal),
      midPoint.add(unitNormal.negate()),
    ];

    let projectionPoint = projectionOfPointOnLine(
      referencePoint,
      trajectory1[0],
      trajectory1[1]
    );

    if (snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(projectionPoint)) {
      normalSnapPoint = projectionPoint;
      firstSnapPoint = edgePoint1;
    } else {
      let projectionPoint = projectionOfPointOnLine(
        referencePoint,
        trajectory3[0],
        trajectory3[1]
      );

      if (
        snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(projectionPoint)
      ) {
        normalSnapPoint = projectionPoint;
        firstSnapPoint = midPoint;
      } else {
        let projectionPoint = projectionOfPointOnLine(
          referencePoint,
          trajectory2[0],
          trajectory2[1]
        );

        if (
          snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(projectionPoint)
        ) {
          normalSnapPoint = projectionPoint;
          firstSnapPoint = edgePoint2;
        }
      }
    }
  }

  return { normalSnapPoint, firstSnapPoint };
}

function snapHorizontallyAndVertically(movingPoint, staticPoint, considerShiftKey, options = {}) {
  options.considerShiftKey = considerShiftKey;
  options.usePointerLocation = true;
  let axis = lineParallelToWhichAxis(staticPoint, movingPoint, options);

  let point = { pt: null, axis: null };
  let returnPoint = movingPoint.clone();

  if (axis === GLOBAL_CONSTANTS.strings.snaps.x) {
    returnPoint.y = staticPoint.y;
    returnPoint.z = staticPoint.z;
    point.axis = axis;
  } else if (axis === GLOBAL_CONSTANTS.strings.snaps.y) {
    returnPoint.x = staticPoint.x;
    returnPoint.z = staticPoint.z;
    point.axis = axis;
  } else if (axis === GLOBAL_CONSTANTS.strings.snaps.z) {
    returnPoint.x = staticPoint.x;
    returnPoint.y = staticPoint.y;
    point.axis = axis;
  } else if (axis === GLOBAL_CONSTANTS.strings.snaps.parallel) {
    returnPoint.y = staticPoint.y;
    point.axis = axis;
  }

  if (returnPoint.equals(movingPoint)) return null;

  point.pt = returnPoint;
  
  return point;
}

function findTeleportationSnapPoint(startingPoint, options) {
  const parallelSnapEnabled = store.projectProperties.properties.parallelSnapEnabled.getValue();
  if (!parallelSnapEnabled) return;
  
  if (options.metadata.snappedToVertex) {
    let vectorToCheck;
    let vertexCopy = options.metadata.snappedToVertex.clone();
    vertexCopy.y = startingPoint.y;

    vectorToCheck = vertexCopy.subtract(startingPoint);

    let xAxis = new BABYLON.Vector3(1, 0, 0);
    let zAxis = new BABYLON.Vector3(0, 0, 1);

    let angleWithZ = getAngleBetweenVectors(vectorToCheck, zAxis);

    let vertexExtension, startingPointExtension;
    let scalar = 10;
    if (Math.abs(angleWithZ) < 45) {
      //should snap to Z axis
      vertexExtension = xAxis.scale(scalar);
      startingPointExtension = zAxis.scale(scalar);
    } else {
      //should snap to X axis
      vertexExtension = zAxis.scale(scalar);
      startingPointExtension = xAxis.scale(scalar);
    }

    let intersection = externalUtil.getPointOfIntersection([
      vertexCopy,
      vertexCopy.add(vertexExtension),
      startingPoint,
      startingPoint.add(startingPointExtension),
    ]);

    return intersection;
  } else if (options.metadata.snappedToEdge) {
    let pickedPoint = options.metadata.pickInfo.pickedPoint.clone();
    pickedPoint.y = startingPoint.y;

    let xAxis = new BABYLON.Vector3(1, 0, 0);
    let zAxis = new BABYLON.Vector3(0, 0, 1);
    let scalar = 10;

    let startingPointExtension = xAxis.scale(scalar);

    let xAxisIntersection = externalUtil.getPointOfIntersection(
      [
        options.metadata.snappedToEdge.headPt,
        options.metadata.snappedToEdge.tailPt,
        startingPoint,
        startingPoint.add(startingPointExtension),
      ],
      { ignoreY: true }
    );

    startingPointExtension = zAxis.scale(scalar);

    let zAxisIntersection = externalUtil.getPointOfIntersection(
      [
        options.metadata.snappedToEdge.headPt,
        options.metadata.snappedToEdge.tailPt,
        startingPoint,
        startingPoint.add(startingPointExtension),
      ],
      { ignoreY: true }
    );

    const snapPoint = _.minBy(
      _.compact([xAxisIntersection, zAxisIntersection]),
      (e) => {
        return BABYLON.Vector3.Distance(pickedPoint, e);
      }
    );

    let snappedToAxis;
    if (snapPoint === xAxisIntersection) {
      snappedToAxis = GLOBAL_CONSTANTS.strings.snaps.x;
    } else if (snapPoint === zAxisIntersection) {
      snappedToAxis = GLOBAL_CONSTANTS.strings.snaps.z;
    }

    options.metadata.teleportSnapAxis = snappedToAxis;

    return snapPoint;
  }
}

function findTheNormalSnapPoint(
  startingPoint,
  groundPoint,
  thresholdFactor,
  options
) {
  let normalPoint = null;
  
  const normalSnapEnabled = store.projectProperties.properties.normalSnapEnabled.getValue();
  if (!normalSnapEnabled) return normalPoint;
  
  let angleThreshold = BABYLON.Vector3.Distance(
    groundPoint,
    store.scene.activeCamera.position
  );
  if (angleThreshold > 30) angleThreshold = angleThreshold / thresholdFactor;
  else angleThreshold = 50 / 1000 / 0.254; //50mm

  if (angleThreshold > 5) angleThreshold = 5;

  let distanceThreshold = angleThreshold / 3;
  if (options.vertexMovement) {
    let neighbours = options.vertexMovement.neighbours;
    if (neighbours) {
      neighbours.some((neighbour) => {
        let projection = projectionOfPointOnLine(
          groundPoint,
          startingPoint,
          neighbour
        );
        if (
          BABYLON.Vector3.Distance(groundPoint, projection) < distanceThreshold
        ) {
          normalPoint = projection;
          return true;
        }
      });
    }
  } else if (options.edgeMovement) {
    let edgePoint1 = options.edgeMovement.edge.headPt;
    let edgePoint2 = options.edgeMovement.edge.tailPt;
    let edgeVector = edgePoint1.subtract(edgePoint2);
    let trajectoryVector = groundPoint.subtract(startingPoint);
    let angle = getAngleBetweenVectors(edgeVector, trajectoryVector);
    if (isFloatEqual(angle, 90, angleThreshold)) {
      let unitNormal = getUnitNormalToEdge(edgePoint1, edgePoint2);
      if (unitNormal) {
        let length = BABYLON.Vector3.Distance(startingPoint, groundPoint);
        let point1 = startingPoint.add(unitNormal.scale(length));
        let point2 = startingPoint.add(unitNormal.negate().scale(length));

        if (
          BABYLON.Vector3.Distance(groundPoint, point1) <
          BABYLON.Vector3.Distance(groundPoint, point2)
        ) {
          normalPoint = point1;
        } else {
          normalPoint = point2;
        }
      }
    }
  } else if (options.faceMovement) {
    
    // used to happen in move face during "free movement" mode which has been removed now
    
    let normal = options.faceMovement.normal;
    let unitNormals = [normal, normal.negate()];
    let length = BABYLON.Vector3.Distance(startingPoint, groundPoint);
    normal = normal.scale(length);
    // let resultantVector = startingPoint.add(normal);
    let trajectoryVector = groundPoint.subtract(startingPoint);
    let angle = getAngleBetweenVectors(normal, trajectoryVector);
    if (
      isFloatEqual(angle, 0, angleThreshold) ||
      isFloatEqual(angle, 180, angleThreshold)
    ) {
      let point1 = startingPoint.add(unitNormals[0].scale(length));
      let point2 = startingPoint.add(unitNormals[1].scale(length));

      if (
        BABYLON.Vector3.Distance(groundPoint, point1) <
        BABYLON.Vector3.Distance(groundPoint, point2)
      ) {
        normalPoint = point1;
      } else {
        normalPoint = point2;
      }
    }
  }

  return normalPoint;
}
export { findSnapPointPerpendicularToEdge,snapHorizontallyAndVertically,findTeleportationSnapPoint,findTheNormalSnapPoint };
