import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { deleteFolder, getFolderProjectCount } from '../../services/folder.service';
import { findFolderRecursive } from '../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice';
import { colors } from '../../themes/constant';
import Button from '../Button';
import { CloseButtonIcon, ModalChild, ModalHeader, ICON, modalStylesDefault, ModalBody, StyledRow, StyledCol, ModalFooter, ButtonWrapper, Card } from './style';
import ReactModal from 'react-modal';
import Text from '../Text';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
function DeleteFolderModal({teamID, folderID, handleClose, ...props}) {
    const [isLoading, setIsLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(true);
    const [folderMetaData, setFolderMetaData] = useState({
        folder_count: null,
        project_count: null,
    });
    const history = useHistory();
    const {currentFolder} = useSelector(state => {
        let team, currentFolder;
        if(teamID){
            team = state.teams.teamlist.find(team => team.id === teamID);
            currentFolder = findFolderRecursive(team.fileSystem?.folders, folderID);
        }else{
            team=null;
            currentFolder = findFolderRecursive(state.teams.personalWorkspace.fileSystem.folders, folderID);
        }
        return {
            team,
            currentFolder
        }
    })
    const handleSubmit = async (evt) => {
        if(evt) evt.preventDefault();
        setIsLoading(true);

        const {team_dashboard, immediate_parent} = currentFolder;
        let redirectToParent = "/dashboard";
        if(team_dashboard) redirectToParent += `/team/${team_dashboard}`;
        if(immediate_parent) redirectToParent += `/folder/${immediate_parent}`;

        const resp = await deleteFolder(teamID, currentFolder);
        if(resp.status === "success"){
            history.push(redirectToParent);
            setTimeout(()=>{
                handleClose();
            }, 100);
        }
        setIsLoading(false);
    }
    const ref = useRef(null);

    useEffect(() => {
        getFolderProjectCount(teamID, folderID)
            .then((data) => {
                const {folder_count, project_count} = data;
                setFolderMetaData({
                    folder_count: folder_count-1,
                    project_count
                })
                setFetchLoading(false);
            })
            .catch(() => setFetchLoading(false))
    }, [])

    return (

        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={handleClose}
            style={modalStylesDefault}
        >
            <Card>
            <ModalHeader>
                <ModalChild left="1.0625rem" style={{ maxWidth: "31rem", whiteSpace: "nowrap", overflow: "hidden" }}>
                    <Text opacity={1} weight="bold">
                        Delete Folder 
                        <strong style={{ fontSize: "16px" }}> {_.truncate(currentFolder?.name, {length: 48})}</strong>
                    </Text>
                </ModalChild>
                <ModalChild right="1.0625rem">
                    <CloseButtonIcon onClick={handleClose} src={ICON.CloseButton} alt="icon" />
                </ModalChild>
            </ModalHeader>
            <ModalBody style={{whiteSpace: "normal", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "14px", lineHeight: "normal"}}>
                <StyledRow>
                    {
                        fetchLoading ? 
                        <StyledCol>
                            Loading...
                        </StyledCol>
                        :
                        <StyledCol>
                            Are you sure you want to delete <strong>{currentFolder?.name}</strong> with <strong>{folderMetaData.folder_count}</strong> folders and <strong>{folderMetaData.project_count}</strong> projects inside?
                        </StyledCol>
                    }
                    
                </StyledRow>
            </ModalBody>
            <ModalFooter>
                <ButtonWrapper>
                    <Button onPress={handleClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Cancel" />
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button isLoading={isLoading} primary title="Delete" onPress={handleSubmit} />
                </ButtonWrapper>
            </ModalFooter>
            </Card>
        </ReactModal>
     );
}

export default DeleteFolderModal;