import { CadImporter, clearCadLoader, sendCadImportProgressEvent } from "../modules/forgeConnection/cadImportListeners";
import { forgeConfig } from "../modules/forgeConnection/forgeConfig";
import { ForgeConnection } from "../modules/forgeConnection/forgeConnection";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants";
import { store } from "../modules/utilityFunctions/Store";
import axios from "axios";
import { randomIntFromInterval, showToast, TOAST_TYPES } from "../modules/extrafunc";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds";
import { _floorPlanFunctions, deleteImageFromBackend } from "../libs/twoDimension";
import { setScaleOperation } from "../modules/setScaleOperations/setScaleOperation";
import { environment, ORIGIN } from "../../services/url.constants";
import { appendStorey } from "../stateManagers/reducers/objectProperties/storeysSlice";
import { DisplayOperation } from "../modules/displayOperations/displayOperation";
import reduxStore from "../stateManagers/store/reduxStore";
import { uploadUserDWF } from "../../services/projects.service";
import { handleGetResourceOperations } from "../../services/resources.service";
// Commented import mixpanel from 'mixpanel-browser';

const URLS = {
  IMAGE: ORIGIN + "/hosaplan/",
  CAD: forgeConfig.workItemURL,
};

export const imageUpload = (file, storey) => {
  let imageProgressInterval= null;
  let imageProgress = null;
  const sendProgressEvent = function (completed, text) {
    const EVT_NAME = GLOBAL_CONSTANTS.strings.events.imageUploadProgress;
    if (!text) text = "Uploading Image ...";
    const event = new CustomEvent(EVT_NAME, {
      detail: {
        progress: completed,
        text: text
      }
    })
    window.dispatchEvent(event);
  }

  return new Promise((resolve, reject) => {
    let structure = 1;
    const data = {
      floorplan: file,
      floorkey: store.floorkey,
      structure: structure,
      structureID: store.activeLayer.structure_id,
      storey: storey,
      mode: "floorplan",
    };
    const formData = new FormData();
    for (let item in data) {
      formData.append(item, data[item]);
    }

    let CancelToken = axios.CancelToken;
    let cancelSource = CancelToken.source();

    axios
      .post(URLS.IMAGE, formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
        cancelToken: cancelSource.token,
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round((progressEvent.loaded * 70) / progressEvent.total);
          sendProgressEvent(percentCompleted, "Uploading Image ...");
          if(percentCompleted===70){
            if(imageProgressInterval) clearInterval(imageProgressInterval);
            imageProgress = 70;
            imageProgressInterval = setInterval(() => {
              imageProgress += randomIntFromInterval(1,2);
              if(imageProgress <= 85){
                sendProgressEvent(imageProgress)
              }else{
                clearInterval(imageProgressInterval);
              }
            }, 200);
          }
        }
      })
      .then((response) => {
        const { data } = response;
        if (!data) console.log("Image upload request failed.");
        else {
          if (data.description === "Exists") {
            window.analytics.track("Uploaded on existing storey");
            // showToast("Plan exists at this storey. Choose different storey");
            resolve({
              success: false,
              reason:
                "Plan already exists on this storey. Please upload in a different storey.",
            });
          } else {
            if(window.scheduleCancelImageUpload) {
              cancelSource.cancel('Cancelled by the user');
              window.scheduleCancelImageUpload = false;
              deleteImageFromBackend(data.uniqueIdFromTheBackend, storey);
              return;
            }
            window.analytics.track("Uploaded sketch successfully");
            let image = new Image();
            image.src = ORIGIN + data.imageLink;
            showToast("Pick two points on image to set scale");
            image.onload = async function () {
              if(imageProgressInterval) clearInterval(imageProgressInterval);
              sendProgressEvent(85, "Processing Image ...");
              imageProgress = 85;
              imageProgressInterval = setInterval(() => {
                imageProgress += randomIntFromInterval(1,2);
                if(imageProgress <= 100){
                  sendProgressEvent(imageProgress)
                }else{
                  clearInterval(imageProgressInterval);
                }
              }, 100);
              setTimeout(function(){
                let structures = StructureCollection.getInstance();
                let str = structures.getStructureById(
                  store.activeLayer.structure_id
                );
                let storeyCollection = str.getStoreyData();

                let storeyNum = storey;
                let payload = {
                  items: [],
                };
                if (storeyNum > 0) {
                  let posLength = storeyCollection.getPositiveStoreysLength();
                  if (storeyNum > posLength - 1) {
                    for (let i = posLength; i <= storeyNum; i++) {
                      const storey = storeyCollection.addStorey(
                        store.activeLayer.structure_id,
                        i
                      );
                      // const existStoreyData = selectStorey(state, storey.value);

                      if (!storey.exists) {
                        payload.items.push({
                          id: storey.id,
                          value: storey.value,
                          name: storey.name,
                          height: DisplayOperation.convertToDefaultDimension(
                            storey.height
                          ),
                          hidden: storey.hidden,
                          layers: [],
                        });
                        delete storey.exists;
                      }
                    }
                  }
                } else {
                  let negLength = storeyCollection.getNegativeStoreysLength();
                  if (Math.abs(storeyNum) > negLength) {
                    for (let i = negLength + 1; i <= Math.abs(storeyNum); i++) {
                      const storey = storeyCollection.addStorey(
                        store.activeLayer.structure_id,
                        -i
                      );
                      if (!storey.exists) {
                        payload.items.push({
                          id: storey.id,
                          value: storey.value,
                          name: storey.name,
                          height: DisplayOperation.convertToDefaultDimension(
                            storey.height
                          ),
                          hidden: storey.hidden,
                          layers: [],
                        });
                        delete storey.exists;
                      }
                    }
                  }
                }
                reduxStore.dispatch(appendStorey(payload));
                // storeyView.generateStoreyUiData();
                // initFloorplan(image, storey, structure);
                _floorPlanFunctions(image, storey, structure, data.uniqueIdFromTheBackend);
                // $('#uploadSketchModal').modal('toggle');
                // global_tape = true;
                // measureMeshEvent();
                setScaleOperation.createScale(
                  "imageScale",
                  storey,
                  structure,
                  true
                );
                store.ACTIVE_EVENT = {"event": 'set-scale'};
                if(imageProgressInterval) clearInterval(imageProgressInterval);
                sendProgressEvent(100, "Finished Uploading");
                setTimeout(function () {
                  resolve({success: true});
                }, 1000);
              } , 1000);
            };
          }
        }
      })
      .catch((err) => {
        if(axios.isCancel(err)){
          showToast("cancelled on user request", 2000, TOAST_TYPES.error);
        }
        console.log("error in dropzone API calls image upload ", err);
        reject({ success: false, reason: "Error in uploading image." });
      });
  });
};

var cadRequestIdCount = 0;
export const cadUpload = (file, storey) => {
  window.scheduleCancelCadImport = false;


  const cadRequestId =  `${store.sessionId}_${new Date().getTime()}_${cadRequestIdCount++}`;
  console.log('CAD REQUEST ID', cadRequestId);
  store.currentCadRequestId = cadRequestId;

  store.cadImporter = new CadImporter(cadRequestId);
  store.cadImporter.startPollingCadJson();

  return new Promise((resolve, reject) => {
    console.log("Inside cadUpload: ", file);
    store.storeyNumForImport = storey;
    const data = {
      activityName: forgeConfig.activityName,
      floorkey: store.floorkey,
      roomId: ForgeConnection.getRoomId(),
      origin: environment,
      cadRequestId: store.currentCadRequestId
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    // formData.append("csrfmiddlewaretoken", "uS6Hufr1T1zoczZU1cqyi89bA56QfilCP9EnI0HlRvqPef7PyFeL6dFgdpXHokHA");
    formData.append("inputFile", file);
    axios
      .post(URLS.CAD, formData, {
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round((progressEvent.loaded * 30) / progressEvent.total);
          sendCadImportProgressEvent(percentCompleted, "Uploading CAD file ...");
        }
      })
      .then((response) => {
        resolve({ success: true, data: response });
      })
      .catch((err) => {
        store.cadImporter.stopPollingCadJson();
        console.log("Error in upload CAD: ", err);
        showToast( "Error uploading the CAD file, please try uploading the file again");
        clearCadLoader("Error in upload CAD");
        reject({ success: false, message: "Error in upload CAD", error: err });
      });
  });
};

export const modelUpload = (file, type, storey) => {
  return new Promise((resolve, reject) => {
    const filesArray = [];
    if (file) filesArray.push(file);
    uploadUserDWF(filesArray, "furniture", null, "m", type, storey)
      .then((uploadedObjects) => {
        const mockResource = {
          resourceName: "objects",
          floorkey: store.floorkey,
          filterType: "furniture",
          filterSubType: type
        }
        handleGetResourceOperations(mockResource, true);
        resolve(uploadedObjects);
      })
      .catch((err) => {
        if (err) console.log(err);
        reject(err);
        showToast("Something went wrong while uploading, Please try again");
      })
  });
}
