import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  description: "",
  isTeamsProject: false,
  team: {},
  speckleStreamId: null,
  isCoveToolProject: null,
  user: {
    projectsCount: -1
  }
};

export const projectMetadataSlice = createSlice({
  name: "projectMetadata",
  initialState,
  reducers: {
    resetProjectMetadata: () => initialState,
    setProjectName: (state, action) => {
      state.name = action.payload;
    },
    setProjectMetadata: (state, action) => {
      const {name, description, isTeamsProject, team, speckleStreamId, isCoveToolProject} = action.payload;
      state.name = name;
      state.description = description;
      state.isTeamsProject = isTeamsProject;
      state.team = team
      state.speckleStreamId = speckleStreamId;
      state.isCoveToolProject = isCoveToolProject
    },
    addProjectMetadata: (state, action) => {
      const {projectsCount} = action.payload;
      state.user.projectsCount = projectsCount;
    },
    setIsCoveToolProject: (state, action) => {
      state.isCoveToolProject = action.payload
    }
  }
})

export const { resetProjectMetadata, setProjectName, setProjectMetadata, addProjectMetadata, setIsCoveToolProject } = projectMetadataSlice.actions;
export default projectMetadataSlice.reducer;