import $ from "jquery";
import { store } from "../utilityFunctions/Store.js";
import { fastAutoDetect } from "../roomOperations/autoDetect.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import {
  removeDuplicateVertices,
  multiplyPointArrayByFactor,
  removeDuplicateDecimals,
} from "../../libs/twoD/twoServices.js";
import {
  twoSnapDimension,
  snapDimensionFromEdgesOfCanvas,
  twoSnapShape,
} from "../../libs/twoD/twoSnap.js";
import { drawRoomMass } from "../../libs/twoD/twoDrawRooms.js";
var AutoDetectDisplay = (function () {
  var AutodetectDisplayFunction = function () {
    let allPol = [];
    if (!fastAutoDetect.isEmpty()) {
      let polygons = fastAutoDetect.getPolygons();
      for (let i = 0; i < polygons.length; i++) {
        if (polygons[i].length <= 4) {
          polygons.splice(i, 1);
          i--;
        }
        if (i >= 0) {
          let len = polygons[i].length;
          if (
            (polygons[i][0][0] === 1 || polygons[i][0][1] === 1) &&
            (polygons[i][len - 1][0] === 1 || polygons[i][len - 1][1] === 1)
          ) {
            polygons.splice(i, 1);
            i--;
          }
        }
      }
      let structureID = store.activeLayer.structure_id;
      let structure =
        StructureCollection.getInstance().getStructures()[
          store.activeLayer.structure_id
        ];
      let storey = structure
        .getStoreyData()
        .getStoreyByValue(store.activeLayer.storey);
      let imageLayer = storey.layerData.getLayerByName("Image", storey.value);
      const imageScaleFactor = imageLayer.floorplans[0].scaleFactor;
      const scale = imageLayer.floorplans[0].mesh.scaling.x;
      let levelLow = (parseInt(store.activeLayer.storey) - 1).toString();
      let levelHigh = (parseInt(levelLow) + 1).toString();

      let level;
      level = structure.getLevel(levelLow, levelHigh);
      if (!level) structure.addLevel(levelLow, levelHigh);

      let levelID = levelLow + levelHigh;
      for (let i = 0; i < polygons.length; i++) {
        let polygon = removeDuplicateVertices(polygons[i]);
        polygon = multiplyPointArrayByFactor(
          polygon,
          1 / imageScaleFactor,
          scale
        );
        polygon = twoSnapDimension(polygon);
        polygon = snapDimensionFromEdgesOfCanvas(polygon);
        let threshold = 4; // ~ 1 metre
        let angleThreshold = 10; // degrees
        let waterTightThreshold = 4;
        //polygon = twoSnapShape(polygon, structureID, levelID, threshold, waterTightThreshold, angleThreshold);
        allPol.push(polygon);
      }
      //console.log("allPol length before: ", allPol.length);
      allPol = allPol
        .map(JSON.stringify)
        .reverse()
        .filter(function (item, index, allPol) {
          return allPol.indexOf(item, index + 1) === -1;
        })
        .reverse()
        .map(JSON.parse);
      ///console.log("allPol length after: ", allPol.length);

      for (let i = 0; i < allPol.length; i++) {
        let threshold = 4; // ~ 1 metre
        let angleThreshold = 10; // degrees
        let waterTightThreshold = 4;
        allPol[i] = twoSnapShape(
          allPol[i],
          structureID,
          levelID,
          threshold,
          waterTightThreshold,
          angleThreshold,
          storey
        );
        allPol = removeDuplicateDecimals(allPol);
        try {
          drawRoomMass(
            allPol[i],
            "",
            "",
            "",
            levelID,
            structureID,
            false,
            $("#auto_storey_number").val()
          );
        } catch (e) {
          console.log("something happened");
        }
      }
    }
  };

  return {
    AutodetectDisplay: function () {
      AutodetectDisplayFunction();
    },
  };
})();
export { AutoDetectDisplay };
