import BABYLON from "../babylonDS.module";
import { store } from "../utilityFunctions/Store";
import { scenePickController } from "../utilityFunctions/scenePickController";
import { cameraController } from "./cameraController";
import { StateMachine } from "../Classes/StateMachine";
import {is2D} from "../../libs/twoDimension";

const panOperator = /** @class panOperator **/ (function () {
  "use strict";
  let plane;
  let pickOrigin;
  let isPanning = false;
  let identity = null;
  let camera = null;
  let dist;
  let panExclusive = false;

  function isValidInput(evt) {
    let ctrlHit = evt.ctrlKey;
    let auxiliaryButtonHit;

    if (store.idle) {
      auxiliaryButtonHit = evt.button === 2 || evt.button === 1;
      ctrlHit = evt.button === 2 ? true : evt.ctrlKey;
    }
    else if (panExclusive) {
      auxiliaryButtonHit = evt.button === 0;
      ctrlHit = true;
    }
    else {
      auxiliaryButtonHit = evt.button !== 0;
      if(evt.button === 2) ctrlHit = true;
    }

    return ctrlHit && auxiliaryButtonHit;
  }

  function onPointerDown(evt) {
    if (isValidInput(evt)) {
      
      const options = {
        pickVisibleAndInvisibleMeshes: true,
        fastCheck: is2D(),
      };
      
      let pickResult = scenePickController.pick(null, options);
      if (pickResult.pickedMesh) {
        let normal = camera.position.subtract(pickResult.pickedPoint).normalize();
        plane = BABYLON.Plane.FromPositionAndNormal(pickResult.pickedPoint, normal);
        pickOrigin = pickResult.pickedPoint;
        dist = BABYLON.Vector3.Distance(pickOrigin, camera.position);
        isPanning = true;
        cameraController.detach();
      }
    }
  }

  function onPointerUp() {
    if (isPanning) {
      cameraController.attach();
      if (store.idle) {
        cameraController.deactivateOrbitMode();
        cameraController.activateOrbitMode();
      }
    }
    isPanning = false;
  }

  function onPointerMove(evt) {
    const validation = panExclusive ? !panExclusive :
      store.idle ? false :
      !(StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(evt) || StateMachine.EventValidation.isPointerMoveWhileRightButtonDown(evt));
    if (validation)
      return;
    if (isPanning) {
      let ray = store.scene.createPickingRay(store.scene.pointerX, store.scene.pointerY, identity, camera, false);
      let distance = ray.intersectsPlane(plane);

      if (distance === null) {
        return;
      }
      let pickedPoint = ray.direction.scale(dist).add(ray.origin);
      let diff = pickedPoint.subtract(pickOrigin);
      if (store.$scope.isTwoDimension) diff.y = 0;
      camera.target.subtractInPlace(diff);
    }
  }

  function dispatchEvents() {
    store.canvas.addEventListener('pointerdown', this.onPointerDown);
    store.canvas.addEventListener('pointerup', this.onPointerUp);
    store.canvas.addEventListener('pointermove', this.onPointerMove);
  }

  function setCamera(newCamera) {
    camera = newCamera;
    identity = BABYLON.Matrix.Identity();
  }

  function setPanExclusive(isExclusive) {
    panExclusive = isExclusive;
  }

  return {
    onPointerDown: onPointerDown,
    onPointerUp: onPointerUp,
    onPointerMove: onPointerMove,
    dispatchEvents: dispatchEvents,
    setCamera: setCamera,
    setPanExclusive: setPanExclusive
  };
})();

export {panOperator}
