import React from 'react';
import styled from 'styled-components';
import { colors } from '../../themes/constant';
import logo from "../../assets/snaptrude.svg";
import snaptrudeIcon from "../../assets/snaptrudeIcon.svg";
import { routes } from '../../routes/constants';
import { withRouter } from 'react-router';
import Button from "../../components/Button";
import Signup from '../loginSignup/Signup';
import { showToast } from '../../snaptrude/modules/extrafunc';
import { autoSaveConfig } from '../../snaptrude/modules/socket/autoSaveConfig';
import { store } from '../../snaptrude/modules/utilityFunctions/Store';

const Wrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  // height: 100%;
  // width: 100%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.2);
`

const TopMenuBarContainer = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 4.00278px 30.0208px ${colors.lightGreyShadow};
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 2.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  padding: 0 0.5em;
  box-sizing: border-box;
  .projectTitle{
    font-family: "DM Sans","sans-serif";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.333333px;
    color: #4F4F4F;
  }
`
const Col = styled.div`
  // border: 2px solid crimson;
  display: flex;
  align-items: center;
  margin: 0px 5px;
`

const SignUpContainer = styled.div`
  // border: 2px solid;
  border-radius: 10px;
  position: fixed;
  right: 10%;
  top: 10%;
  box-shadow: 0px 4px 30px rgba(79, 79, 79, 0.3);
  background: #FFFFFF;
  padding: 2rem;
`
const Divider = styled.div`
  border: 0 solid rgba(45, 45, 46, 0.2);
  border-right-width: 1.50104px;
  height: 1em;
  width: 0.5em;
`;

function SharedProject({history, onClose, projectTitle="", invited, showSignUpForm=true}) {
  const postSuccessfulSignUp = (signedUpEmail) => {
    if(signedUpEmail === invited.email){
      window.location = window.location.pathname;
      // window.history.pushState({}, document.title, window.location.pathname);
      // autoSaveConfig.connectSocket(store.floorkey)
      // onClose();
    }else{
      showToast(`${signedUpEmail} was not invited to this project. Redirecting to dashboard.`)
      setTimeout(() => {
        history.push(routes.dashboard);
      }, 1000)
    }
  }
  return <Wrapper>
    <TopMenuBarContainer>
      <Col style={{margin: "0px"}}>
        <img src={snaptrudeIcon} alt="Snaptrude"  width={32} height={32} style={{cursor: "pointer"}} onClick={() => {history.push(routes.dashboard)}} />
      </Col>
      <Divider />
      <Col style={{flex: 1, marginLeft: "0.5em"}} className="projectTitle">
        {projectTitle}
      </Col>
    </TopMenuBarContainer>
    { showSignUpForm && <SignUpContainer>
      <Col style={{justifyContent: "center"}}>
        <img src={logo} alt="Snaptrude" style={{width: "16rem", padding: "8px", cursor: "pointer"}} onClick={() => {history.push(routes.dashboard)}} />
      </Col>
      <Signup 
        headerElement={() => {return "Sign up to access and view this project"}}
        history={history}
        postSuccessfulSignUp={postSuccessfulSignUp}
        showLoginOption={false}
        invitedEmail={invited.email}
      />
    </SignUpContainer>
    }
  </Wrapper>;
}

export default withRouter(SharedProject);
