import {
  appendStorey,
  updateStorey,
} from "../../stateManagers/reducers/objectProperties/storeysSlice";
import reduxStore from "../../stateManagers/store/reduxStore";
import { CommandManager } from "../commandManager/CommandManager";
import { commandUtils } from "../commandManager/CommandUtils";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { store } from "../utilityFunctions/Store";
import speckleConstants from "./speckleConstants";

const checkIfStoreyExist = () => {};

const checkStorey = (levelName) => {
  if (!levelName) return;
  const structureCollection = StructureCollection.getInstance();
  const structureId = store.activeLayer.structure_id;
  const structure = structureCollection.getStructures()[structureId];
  let allStoreyData = structure.getStoreyData();
  const storey = allStoreyData.getStoreyByRevitLevel(levelName);
  return storey;
};

const createStorey = (storeyNo, elevation, lowerLevel, upperLevel) => {
  // check if storey exists;
  const structureCollection = StructureCollection.getInstance();
  const structureId = store.activeLayer.structure_id;
  const structure = structureCollection.getStructures()[structureId];
  let storey = structure.getStoreyData().getStoreyByValue(storeyNo);
  if (storey) {
    let height = DisplayOperation.getOriginalDimension(
      elevation,
      "millimeters"
    );
    storey.setHeight(height);
    if (lowerLevel) {
      storey.setName(lowerLevel?.name);
    }
    storey.addRevitLevels(lowerLevel?.elementId, upperLevel?.elementId);
    reduxStore.dispatch(
      updateStorey({
        storeyValue: storeyNo,
        property: "height",
        value: DisplayOperation.convertToDefaultDimension(height),
      })
    );
    if (lowerLevel)
      reduxStore.dispatch(
        updateStorey({
          storeyValue: storeyNo,
          property: "name",
          value: lowerLevel?.name,
        })
      );
    // storey = structure.getStoreyData().getStoreyByValue(storeyNo);
    // let storeyData = {
    //   structure_id: structureId,
    //   idList: [],
    // };
    // storeyData.idList.push(storey.value);
    // let storeyUpdateCommandData =
    //   commandUtils.storeyOperations.getCommandData(storeyData);
    // let storeyUpdateCommand = commandUtils.storeyOperations.getCommand(
    //   "changeStoreyHeight",
    //   storeyUpdateCommandData
    // );
    //
    // CommandManager.execute(storeyUpdateCommand, false);
  } else {
    let height = DisplayOperation.getOriginalDimension(
      elevation,
      "millimeters"
    );

    storey = structure
      .getStoreyData()
      .addStorey(structureId, storeyNo, height, {
        revitLowerLevel: lowerLevel?.elementId,
        revitUpperLevel: upperLevel?.elementId,
        name: lowerLevel?.name,
        autoSave: false
      });

    const payload = {
      items: [
        {
          id: storey.id,
          value: storey.value,
          name: storey.name,
          height: DisplayOperation.convertToDefaultDimension(height),
          hidden: storey.hidden,
          layers: [],
        },
      ],
    };
    reduxStore.dispatch(appendStorey(payload));
  }
  return storey;
};

const createStoreys2 = (selected_levels, positive_levels, negative_levels) => {
  const structureCollection = StructureCollection.getInstance();
  const structureId = store.activeLayer.structure_id;
  const structure = structureCollection.getStructures()[structureId];

  let storeyNo = 1;

  let negativeStorey = selected_levels.filter((level) => level.elevation < 0);
  let positiveStorey = selected_levels.filter((level) => level.elevation >= 0);
  negativeStorey.sort((l1, l2) => l2.elevation - l1.elevation);

  let lastPositiveStorey = structure.getStoreyData().getStoreyByValue(1);

  for (let level = 0; level < positiveStorey.length; ++level) {
    if (level == 0) {
      let storey = createStorey(storeyNo, positiveStorey[level + 1].elevation);
      storeyNo = lastPositiveStorey.value + 1;
    } else {
      let elevation =
        level != positiveStorey.length - 1
          ? positiveStorey[level + 1].elevation -
            positiveStorey[level].elevation
          : 3000;
      let storey = createStorey(storeyNo, elevation);
      lastPositiveStorey = storey;
      storeyNo = lastPositiveStorey.value + 1;
    }
  }

  if (positiveStorey < positive_levels) {
    //check if more storey needed and create those
  }

  storeyNo = 0;
  let lastStorey = structure.getStoreyData().getStoreyByValue(1);

  for (let level = 0; level < negativeStorey.length; ++level) {
    let factor = level === 0 ? 0 : negativeStorey[level - 1].elevation;

    storeyNo = storeyNo - 1;
    let height = DisplayOperation.getOriginalDimension(
      factor - negativeStorey[level].elevation,
      "millimeters"
    );
    let storey = structure
      .getStoreyData()
      .addStorey(structureId, storeyNo, height, {autoSave: false});
    storey.setBase(
      DisplayOperation.getOriginalDimension(
        negativeStorey[level].elevation,
        "millimeters"
      )
    );
    lastStorey = storey;
    const payload = {
      items: [
        {
          id: storey.id,
          value: storey.value,
          name: storey.name,
          height: DisplayOperation.convertToDefaultDimension(height),
          hidden: storey.hidden,
          layers: [],
        },
      ],
    };
    reduxStore.dispatch(appendStorey(payload));
  }

  if (negativeStorey < negative_levels) {
    //check if more storey needed and create those
    for (let level = 0; level < negative_levels.length; ++level) {
      if (lastStorey.base > negative_levels[level].elevation) {
        let elevation =
          lastStorey.base - negative_levels[level].elevation > 2100
            ? lastStorey.base - negative_levels[level].elevation
            : 3000;
        storeyNo = storeyNo - 1;
        let height = DisplayOperation.getOriginalDimension(
          elevation,
          "millimeters"
        );
        let storey = structure
          .getStoreyData()
          .addStorey(structureId, storeyNo, height, {autoSave: false});
        storey.setBase(
          DisplayOperation.getOriginalDimension(
            lastStorey.base - elevation,
            "millimeters"
          )
        );
        lastStorey = storey;
        const payload = {
          items: [
            {
              id: storey.id,
              value: storey.value,
              name: storey.name,
              height: DisplayOperation.convertToDefaultDimension(height),
              hidden: storey.hidden,
              layers: [],
            },
          ],
        };
        reduxStore.dispatch(appendStorey(payload));
        break;
      }
    }
  }
};

const createStoreys = (levels) => {
  let positive_levels = [],
    negative_levels = [];
  positive_levels = levels.filter((l) => l.elevation >= 0);
  console.log(positive_levels);
  positive_levels.sort((l1, l2) => l1.elevation - l2.elevation);
  negative_levels = levels.filter((l) => l.elevation < 0);
  console.log(negative_levels);
  negative_levels.sort((l1, l2) => l2.elevation - l1.elevation);
  console.log(positive_levels, negative_levels);

  let storey = 1;

  let startLevel = positive_levels[0].elevation == 0 ? 1 : 0;
  let levelUnit = positive_levels[0].units;
  let isFirstStorey = startLevel == 0 ? true : false;
  let nextLevel = positive_levels[1];
  let previousLevel = positive_levels[0];

  for (let level = startLevel; level < positive_levels.length + 1; ++level) {
    try {
      let lowerLevel, upperLevel;
      let elevation, newStorey;
      if (isFirstStorey) {
        elevation = positive_levels[level].elevation;
        upperLevel = positive_levels[level];
      } else if (level == positive_levels.length) {
        elevation = 3000;
        lowerLevel = previousLevel;
        upperLevel = null;
      } else {
        elevation = positive_levels[level].elevation - previousLevel.elevation;
        upperLevel = positive_levels[level];
        lowerLevel = previousLevel;
      }
      elevation = speckleConstants.convertIntomm(elevation, levelUnit);
      if (elevation > 2100) {
        newStorey = createStorey(storey, elevation, lowerLevel, upperLevel);
        storey++;
        isFirstStorey = false;
        previousLevel = positive_levels[level];
      }
    } catch (e) {
      console.log(e);
    }
  }

  storey = 0;
  const structureCollection = StructureCollection.getInstance();
  const structureId = store.activeLayer.structure_id;
  const structure = structureCollection.getStructures()[structureId];

  previousLevel = null;
  for (let level = 0; level < negative_levels.length; ++level) {
    try {
      let newStorey;
      let storeyNo = storey == 0 ? 1 : storey;
      let storeyData = structure.getStoreyData().getStoreyByValue(storeyNo);
      let previousStoreyBase = DisplayOperation.convertToDefaultDimension(
        storeyData.base,
        "millimeter"
      );
      let elevation = previousStoreyBase - negative_levels[level].elevation;
      let lowerLevel = negative_levels[level];
      let upperLevel = previousLevel;
      elevation = speckleConstants.convertIntomm(elevation, levelUnit);
      if (elevation > 2100) {
        --storey;
        newStorey = createStorey(storey, elevation, lowerLevel, upperLevel);
        previousLevel = lowerLevel;
      }
    } catch (e) {
      console.log(e);
    }
  }
};

const getSelectedLevels = (levels) => {
  let positive_levels = [],
    negative_levels = [];

  let selected_levels = [];
  let probable_storeys = [];
  positive_levels = levels.filter((l) => l.elevation >= 0);
  positive_levels.sort((l1, l2) => l1.elevation - l2.elevation);
  negative_levels = levels.filter((l) => l.elevation < 0);
  negative_levels.sort((l1, l2) => l2.elevation - l1.elevation);

  let storey = 1;

  if (positive_levels.length >= 1) {
    let startLevel = positive_levels[0].elevation == 0 ? 1 : 0;
    let isFirstStorey = startLevel == 0 ? true : false;
    let nextLevel = positive_levels[1];
    let previousLevel = positive_levels[0];

    for (let level = startLevel; level < positive_levels.length + 1; ++level) {
      try {
        let isSelected = false;
        let lowerLevel, upperLevel;
        let elevation, newStorey;
        if (isFirstStorey) {
          elevation = positive_levels[level].elevation;
          upperLevel = positive_levels[level];
          previousLevel = null;
        } else if (level == positive_levels.length) {
          elevation = 3000;
          lowerLevel = previousLevel;
          upperLevel = null;
        } else {
          elevation =
            positive_levels[level].elevation - previousLevel.elevation;
          upperLevel = positive_levels[level];
          lowerLevel = previousLevel;
        }

        if (elevation > 2100) {
          // newStorey = createStorey(storey, elevation, lowerLevel, upperLevel);
          let storeyName = `Storey ${storey}`;
          if (previousLevel) {
            previousLevel.storey = storey;
            selected_levels.push(previousLevel);
            storeyName = previousLevel.name;
          }

          probable_storeys.push({ storeyName, elevation });
          storey++;
          isFirstStorey = false;
          previousLevel = positive_levels[level];
          isSelected = true;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  storey = 0;
  const structureCollection = StructureCollection.getInstance();
  const structureId = store.activeLayer.structure_id;
  const structure = structureCollection.getStructures()[structureId];

  let previousLevel = null;
  for (let level = 0; level < negative_levels.length; ++level) {
    try {
      let isSelected = false;
      let newStorey, storeyData, previousStoreyBase;
      let storeyNo = storey == 0 ? 1 : storey;
      if (storeyNo == 1) {
        storeyData = structure.getStoreyData().getStoreyByValue(storeyNo);
        previousStoreyBase = DisplayOperation.convertToDefaultDimension(
          storeyData.base,
          "millimeter"
        );
      } else {
        previousStoreyBase = previousLevel.elevation;
      }

      let elevation = previousStoreyBase - negative_levels[level].elevation;
      let lowerLevel = negative_levels[level];
      let upperLevel = previousLevel;
      if (elevation > 2100) {
        --storey;
        let storeyName = `Storey-${storey}`;
        if (lowerLevel) {
          storeyName = lowerLevel.name;
        }
        isSelected = true;
        probable_storeys.unshift({ storeyName, elevation });
        selected_levels.unshift(lowerLevel);
        previousLevel = lowerLevel;
      }
    } catch (e) {
      console.log(e);
    }
  }
  return { selected_levels, probable_storeys };
};

export { createStoreys, checkStorey, createStoreys2, getSelectedLevels };
