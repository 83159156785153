"use strict";
import BABYLON, { InputText, TextBlock } from "../babylonDS.module.js";
import $ from "jquery";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { appElement } from "../../libs/bimDataFuncs.js";
import { drawSelectionBox } from "../../libs/meshEvents.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { plainFloorParameters } from "../factoryTypes/floor.types.js";
import {
  updateCSG,
  getEmptyFunction,
  getBabylonGUIElementByName,
} from "../extrafunc.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import {
  isNumberKey,
  isBackspaceKey,
  isArrowKey,
  isDotKey,
  isCommaKey,
} from "../../libs/keyEvents.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { setScaleOperation } from "../setScaleOperations/setScaleOperation.js";
import { AutoDetectDisplay } from "./AutoDetectDisplay.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { onWheelEventListener } from "../../libs/zoomFuncs.js";
import { arrayFunctionOperator } from "../../libs/arrayFunctionEvents.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";

var DisplayOperation = (function () {
  // var $scope = null;
  // store.angular.element(function () {
  //   $scope = store.angular.element(appElement).scope();
  //   $scope = $scope.$$childHead;
  // });
  let _dimMesh = null;
  let _dimMesh2 = null;
  let _dimMesh3 = null;
  let _input = null;
  let _input2 = null;
  let _input3 = null;
  let lines = [];
  var inputs = [];
  let inputEditedNumber = 0;

  const limitBreachSignallingColor = "#d00404";

  var convertDimensionTo = function (input, options = {}) {
    let inchOutput = null;
    let mtsOutput = null;
    let cmOutput = null;
    let mmtsOutput = null;
    let feetOutput = null;

    let a = Math.floor((input * 10) / 12);
    let b = ((input * 10) % 12).toFixed(store.$scope.unit_tolerance.value);
    // let b = parseInt(((input * 10) % 12).toFixed(0));
    if (b === 12) {
      a += 1;
      b = 0;
    }
    
    if (options.roundOff){
      if (b >= 7){
        a++;
        b = 0;
      }
      else if (b <= 5){
        b = 0;
      }
    }
    
    feetOutput = a.toString() + "'" + b.toString().replace(/-/g, "") + '"';
    
    mtsOutput = (input * store.unit_absolute_scale * 10).toFixed(
      store.$scope.unit_tolerance.value
    );
    cmOutput = (input * store.unit_absolute_scale * 1000).toFixed(
      store.$scope.unit_tolerance.value
    );
    mmtsOutput = (input * store.unit_absolute_scale * 10000).toFixed(
      store.$scope.unit_tolerance.value
    );
    inchOutput = (input * 10).toFixed(store.$scope.unit_tolerance.value);

    return {
      inch: inchOutput,
      mts: mtsOutput,
      cm: cmOutput,
      feet: feetOutput,
      mMts: mmtsOutput,
    };
  };
  /*
    Converts input in snaptrude units to project specific units
     */
  var _convertToDefaultDimension = function (input, options) {
    let convertedDimension = convertDimensionTo(input, options);
    if (store.$scope.units_type.value === "feet-inches") {
      return convertedDimension.feet;
    } else if (store.$scope.units_type.value === "meters") {
      return convertedDimension.mts;
    } else if (store.$scope.units_type.value === "centimeter") {
      return convertedDimension.cm;
    } else if (store.$scope.units_type.value === "millimeter") {
      return convertedDimension.mMts;
    } else {
      return convertedDimension.inch;
    }
  };

  var _convertToDefaultArea = function (input) {
    let convertedDimension = convertDimensionTo(input);
    if (store.$scope.units_type.value === "feet-inches") {
      return parseFloat((convertedDimension.inch / 12 / 12) * 10);
    } else if (store.$scope.units_type.value === "meters") {
      return parseFloat(
        (store.unit_absolute_scale * convertedDimension.mMts) / 100
      );
    } else if (store.$scope.units_type.value === "centimeter") {
      return parseFloat(
        (store.unit_absolute_scale * convertedDimension.mMts) / 100
      );
    } else if (store.$scope.units_type.value === "millimeter") {
      return parseFloat(
        (store.unit_absolute_scale * convertedDimension.mMts) / 100
      );
    } else {
      return parseFloat(convertedDimension.inch * 10);
    }
  };

  var _convertToDefaultVolume = function (input) {
    let convertedDimension = convertDimensionTo(input);
    if (store.$scope.units_type.value === "feet-inches") {
      return parseFloat((convertedDimension.inch / 12 / 12 / 12) * 100);
    } else if (store.$scope.units_type.value === "meters") {
      return parseFloat(
        store.unit_absolute_scale *
          store.unit_absolute_scale *
          convertedDimension.cm
      );
    } else if (store.$scope.units_type.value === "centimeter") {
      return parseFloat(
        store.unit_absolute_scale *
          store.unit_absolute_scale *
          convertedDimension.cm
      );
    } else if (store.$scope.units_type.value === "millimeter") {
      return parseFloat(
        store.unit_absolute_scale *
          store.unit_absolute_scale *
          convertedDimension.cm
      );
      // return parseFloat((unit_absolute_scale * store.unit_absolute_scale * convertedDimension.mMts)*1000000).toFixed(2);
    } else {
      return parseFloat(convertedDimension.inch * 1000);
    }
  };

  /*
    Converts the 'input' in the specified 'unit' to snaptrude units
     */
  var _getOriginalDimension = function (input, unit) {
    let unitsValue = unit || store.$scope.units_type.value;
    if (unitsValue === "feet-inches") {
      let str = String(input);

      if (!str.includes("'")) str += "'";
      if(str[str.length-1] === "'")   str += "0";
      if (!str.includes("\"")) str += "\"";

      // w/o the quotes, for an input like 45, feetVal and inchVal will both be 45 messing up the
      // user inputs. Don't want to make changes to the below portion, it looks fragile

      let feetVal = str.split("'")[0];
      let inchVal = str.split("'").pop().split('"')[0];
      let inchInFeet = inchVal / 12;
      let finalFeet = parseFloat(feetVal) * 12;
      if (inchVal) finalFeet += parseFloat(inchVal);
      return finalFeet / 10;
    } else if (unitsValue === "inches") {
      return input / 10;
    } else if (unitsValue === "meters") {
      return input / (store.unit_absolute_scale * 10);
    } else if (unitsValue === "kilometers") {
      return (input * 100) / store.unit_absolute_scale;
    } else if (unitsValue === "miles") {
      return (input * 100) / (store.unit_absolute_scale * 1.609344);
    } else if (unitsValue === "centimeter" || unitsValue === "centimeters") {
      return input / (store.unit_absolute_scale * 1000);
    } else if (unitsValue === "millimeter" || unitsValue === "millimeters") {
      return input / (store.unit_absolute_scale * 10000);
    } else {
      return input / 10;
    }
  };

  var _selectedInputType = function (input) {
    switch (store.$scope.units_type.value) {
      case "meters":
        return convertDimensionTo(input).mts;
      case "centimeter":
        return convertDimensionTo(input).cm;
      case "millimeter":
        return convertDimensionTo(input).mts;
      case "inches":
        return convertDimensionTo(input).inch;
      case "feet-inches":
        return convertDimensionTo(input).feet;
    }
  };

  // Update: This function accepts dist and oldValue in babylon units
  var _updateDimensionScale = function (
    dist,
    type,
    oldValue,
    mesh = null,
    options = {}
  ) {
    function updateSelection(mesh) {
      store.selectionStack.length = 0;
      drawSelectionBox(mesh);
      store.selectionStack.push(mesh);
      mesh.state = "on";
      _removeDimensions();
      _newDisplayMeshDimensions(mesh);
    }

    function clearSelection(mesh) {
      store.selectionStack.length = 0;
      //removeMeshSelectionChildren(mesh);
      if (mesh.children) {
        mesh.children.forEach((m) => {
          m.dispose();
        });
      }
      mesh.state = "off";
    }

    function priorScaleChange() {
      this.data.forEach(function (dataPoint) {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (mesh) {
          if (mesh.childrenComp) {
            mesh.childrenComp.forEach(function (child) {
              let childDS = child.getSnaptrudeDS();
              if (
                childDS &&
                childDS.type.toLowerCase() === "floor" &&
                childDS.isSupportFloor()
              ) {
                if (dataPoint.scaleChange.y === 0) return;
              }
              child.setParent(null);
            });
          }
        }
      });
    }

    function postScaleChange() {
      this.data.forEach(function (dataPoint) {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (mesh) {
          if (mesh.childrenComp) {
            mesh.childrenComp.forEach(function (child) {
              child.setParent(mesh);
            });
          }
        }
      });
    }
    
    function _createPropertyChangeCommand(component){
      const optionsForPropertyChange = {
        componentKeys : ["edited"],
      };
      let propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
        component.mesh, optionsForPropertyChange
      );
      
      component.markAsEdited();
      
      optionsForPropertyChange.data = propertyChangeCommandData;
      propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
        component.mesh, optionsForPropertyChange
      );
      
      propertyChangeCommand = commandUtils.propertyChangeOperations.getCommand(
        "propChangeOnHeightChange", propertyChangeCommandData
      );
    }

    let currentMesh;
    if (mesh) {
      currentMesh = mesh;
    } else {
      currentMesh = store.selectionStack[store.selectionStack.length - 1];
    }

    if (!currentMesh) return;

    const currentMeshDS = currentMesh.getSnaptrudeDS();
    let currentMeshType = currentMesh.type.toLowerCase();

    let scale = currentMesh.scaling;
    let bbInfo = currentMesh.getBoundingInfo();
    let prevScaling = currentMesh.scaling.clone();

    let meshDimensions = objectPropertiesView.getMeshDimensions(currentMesh);

    let cmdOptions = {
      params: [
        commandUtils.worldMatrixChangeOperations.PARAMS.scale,
        commandUtils.worldMatrixChangeOperations.PARAMS.position,
      ],
    };

    if (options.ignoreSelectionStack) {
      cmdOptions.ignoreSelectionStack = true;
    }
    if (options.overrideMeshParentSelectionForCommandData) {
      cmdOptions.overrideMeshParentSelectionForCommandData = true;
    }
    if (currentMesh.parent) {
      cmdOptions.operationOnChild = true;
    }

    let excludeChildren = [];
    if (currentMeshType === "mass" && currentMesh.childrenComp) {
      let plinthMesh = currentMesh.childrenComp.find((mesh) => {
        return (
          mesh.type.toLowerCase() === "mass" &&
          mesh.getSnaptrudeDS().massType === "Plinth"
        );
      });
      if (plinthMesh) {
        excludeChildren.push(plinthMesh.uniqueId);
      }
    } else if (currentMeshType === "roof") {
      currentMesh.getChildren().forEach((child) => {
        if (child.type.toLowerCase() === "mass")
          excludeChildren.push(child.uniqueId);
      });
    }

    if (excludeChildren.length > 0) {
      cmdOptions.dataOptions = {};
      cmdOptions.dataOptions.excludeChildren = excludeChildren;
    }


    let stack = null;

    if (["door", "window"].includes(currentMesh.type.toLowerCase())) {
        stack = [];
        stack.push(currentMesh);
        if (currentMesh.childrenComp) {
            currentMesh.childrenComp.forEach(m => {
                if (m.type.toLowerCase() === "floor" && m.getSnaptrudeDS().isSupportFloor()) {
                    stack.push(m);
                }
            });
        }
        cmdOptions.excludeChildren = true;
        cmdOptions.doNotHandleChildrenForSave = true;
    }
    
    
    let childrenComp = currentMesh.childrenComp;
    let componentType = currentMesh.type.toLowerCase();

    let wallWithChildrenAndNeedsCSG = false;
    
    if (componentType === "wall" && childrenComp && childrenComp.length > 0) {
      childrenComp.some((child) => {
        if (
          ["door", "window", "furniture", "void"].includes(
            child.type.toLowerCase()
          )
        ) {
          wallWithChildrenAndNeedsCSG = true;
          return true;
        }
      });
    }
    
    /*
    Handling is slightly more complicated for the case - height change of a wall with d/w
    First, it's scaled up and then CSG is done.
    The deletion cmd is captured on the scaled up wall, so scale command should also be executed
    Property change command is done on the new wall created after updateCSG
     */

    let scaleChangeBeforeCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(stack || currentMesh, cmdOptions);

    let propertyChangeCommand;
    let newPosition = currentMesh.position.clone();
    let newScaling = currentMesh.scaling.clone();

    let additionalCmds = [],
      additionalYets = [];

    let breadth = parseFloat(meshDimensions.breadth),
      depth = parseFloat(meshDimensions.depth);

    if (type === "height") {
      let ratio =
        currentMeshType === "door"
          ? (dist - plainFloorParameters.floorDepth) / oldValue
          : dist / oldValue;

      newScaling.y = scale.y * ratio;

      if (virtualSketcher.util.doesComponentGrowDownwards(currentMeshDS)) {
        // Works for roof. Haven't thought of it for other objects. Can be used in the future
        // Concept: Top should remain same. (mesh.position.y - bbox.minimum.y) would remain same
        newPosition.y -=
          (newScaling.y - prevScaling.y) * bbInfo.boundingBox.maximum.y;
        // newPosition.y -= (newScaling.y - prevScaling.y) * bbInfo.boundingBox.minimum.y

        if (!options.ignoreWallAdjustments) {
          currentMesh.scaling.y += 0.1;
          currentMesh.computeWorldMatrix(true);
          let intersectingWalls = store.scene.meshes.filter((mesh) => {
            if (
              mesh.storey < currentMesh.storey &&
              mesh.type.toLowerCase() == "wall"
            ) {
              return currentMesh.intersectsMesh(mesh);
            }
          });
          currentMesh.scaling.y -= 0.1;
          intersectingWalls.forEach((wallMesh) => {
            let wallHeight = _getOriginalDimension(
              objectPropertiesView.getMeshDimensions(wallMesh).height
            );
            let cmdData = _updateDimensionScale(wallHeight - (dist - oldValue), "height", wallHeight, wallMesh, {returnCommand: true, doNotClearSelection: options.doNotClearSelection});
            additionalCmds.push(...cmdData.cmd);
            additionalYets.push(...cmdData.yetToExecutes);
          });
        }
      } else if(currentMeshType === "wall"){
        // Works for roof. Haven't thought of it for other objects. Can be used in the future
        // Concept: Base should remain same. (mesh.position.y - bbox.minimum.y) would remain same
        newPosition.y -= (newScaling.y - prevScaling.y) * bbInfo.boundingBox.minimum.y;
      }


      else if (
        currentMeshType === "mass" &&
        currentMeshDS.massType === "Plinth"
      ) {
        newPosition.y -=
          (newScaling.y - prevScaling.y) * bbInfo.boundingBox.maximum.y;
      } else {
        newPosition.y +=
          (newScaling.y - prevScaling.y) * bbInfo.boundingBox.extendSize.y;
      }

      if (
        _.round(prevScaling.y, 4) !== _.round(newScaling.y, 4) &&
        !options.returnCommand
      ) {
        if (["mass", "wall", "floor", "roof"].includes(currentMeshType) && !wallWithChildrenAndNeedsCSG) {
          _createPropertyChangeCommand(currentMeshDS);
        }
      }
    } else if (type === "width") {
      if (currentMeshType === "furniture") {
        newScaling.z = (scale.z * dist) / oldValue;
      } else if (currentMeshType === "mass") {
        newScaling.z = (scale.z * dist) / oldValue;

        let plinthMesh;
        if (currentMesh.childrenComp) {
          plinthMesh = currentMesh.childrenComp.find((mesh) => {
            return (
              mesh.type.toLowerCase() === "mass" &&
              mesh.getSnaptrudeDS().massType === "Plinth"
            );
          });
        }
        if (plinthMesh) {
          let plinthDimension =
            objectPropertiesView.getMeshDimensions(plinthMesh).breadth;
          let cmdData = _updateDimensionScale(
            dist,
            "width",
            plinthDimension,
            plinthMesh,
            { returnCommand: true }
          );
          additionalCmds.push(...cmdData.cmd);
          additionalYets.push(...cmdData.yetToExecutes);
        }
      } else if(currentMeshType === "wall" && !currentMeshDS.isOrthogonal())    return;

      else {
        // newScaling.z = (scale.z * dist) / _input3.originalValue;
        if (breadth < depth) {
          newScaling.x = (scale.x * dist) / oldValue;
        } else {
          newScaling.z = (scale.z * dist) / oldValue;
        }
      }
    } else if (type === "thickness") {
      if (currentMesh._type === "wall") {
        if(!currentMeshDS.isOrthogonal())   return;
        if (breadth > depth) {
          newScaling.x = (scale.x * dist) / oldValue;
        } else {
          newScaling.z = (scale.z * dist) / oldValue;
        }
      } else if (currentMesh._type === "Mass") {
        newScaling.x = (scale.x * dist) / oldValue;

        let plinthMesh;
        if (currentMesh.childrenComp) {
          plinthMesh = currentMesh.childrenComp.find((mesh) => {
            return (
              mesh.type.toLowerCase() === "mass" &&
              mesh.getSnaptrudeDS().massType === "Plinth"
            );
          });
        }
        if (plinthMesh) {
          let plinthDimension =
            objectPropertiesView.getMeshDimensions(plinthMesh).depth;
            let cmdData = _updateDimensionScale(dist, "thickness", plinthDimension, plinthMesh, {returnCommand: true, doNotClearSelection: options.doNotClearSelection});
          additionalCmds.push(...cmdData.cmd);
          additionalYets.push(...cmdData.yetToExecutes);
        }
      } else if (currentMesh._type === "Furniture") {
        newScaling.x = (scale.x * dist) / oldValue;
      } else if (currentMeshType === "void") {
        if (breadth < depth) {
          newScaling.z = (scale.z * dist) / oldValue;
        } else {
          newScaling.x = (scale.x * dist) / oldValue;
        }
      } else {
        if (breadth > depth) {
          newScaling.z = (scale.z * dist) / oldValue;
        } else {
          newScaling.x = (scale.x * dist) / oldValue;
        }
      }
    } else if (type === 'radius') {
        newScaling.z = (scale.z * dist) / oldValue;
        newScaling.x = (scale.x * dist) / oldValue;
    }

    if (!options.doNotClearSelection) clearSelection(currentMesh);

    let updateCommands;
    
    let voidThicknessChange = false;
    
    if (wallWithChildrenAndNeedsCSG) {
      let optionsForCSG = {};
      optionsForCSG.updatePosition = false;
      optionsForCSG.rePosition = newPosition.asArray();
      optionsForCSG.reScale = newScaling.asArray();
      updateCommands = updateCSG(childrenComp[0], optionsForCSG);

      if (updateCommands) {
        if (!options.returnCommand) {
          updateSelection(updateCommands.newWall.mesh);
        }
        objectPropertiesView.updateObjectProperties(
          updateCommands.newWall.mesh
        );
        
        if (type === "height") _createPropertyChangeCommand(updateCommands.newWall);
      }
    } else {
      // prevent scaling of children
      let children = currentMesh.getChildren();
      children.forEach((child) => {
        let childDS = child.getSnaptrudeDS();
        if (
          childDS &&
          childDS.type.toLowerCase() === "floor" &&
          childDS.isSupportFloor()
        ) {
          if (currentMesh.scaling.y - newScaling.y === 0) return;
        }
        child.setParent(null);
      });

      if (type === "thickness" && currentMeshType === "void") {
        currentMesh._prevParent = currentMesh.parent.uniqueId;
        currentMesh.setParent(null);
        currentMesh.computeWorldMatrix(true);
        voidThicknessChange = true;
      }

      currentMesh.scaling = newScaling;

      let optionsForCSG = {};

      if (!voidThicknessChange) {
        if (currentMesh.parent) {
          optionsForCSG.updatePosition = false;
          optionsForCSG.rePosition =
            currentMesh.parent.absolutePosition.asArray();
          optionsForCSG.reScale = currentMesh.parent.absoluteScaling.asArray();
        }
        updateCommands = updateCSG(currentMesh, optionsForCSG);
        currentMesh.position = newPosition;
      }
      else {
        currentMesh.setParent(store.scene.getMeshByUniqueID(currentMesh._prevParent));
        currentMesh.computeWorldMatrix(true);
        delete currentMesh._prevParent;
      }

      // reassign parent
      children.forEach((child) => child.setParent(currentMesh));
      if (!options.returnCommand) {
        updateSelection(currentMesh);
      }
      objectPropertiesView.updateObjectProperties(currentMesh);
    }

    cmdOptions.data = scaleChangeBeforeCommandData;

    // Handle children for undo/redo
    cmdOptions.preExecuteCallback = priorScaleChange;
    cmdOptions.postExecuteCallback = postScaleChange;
    cmdOptions.preUnExecuteCallback = priorScaleChange;
    cmdOptions.postUnExecuteCallback = postScaleChange;

    let scaleChangeAfterCommandData =
      commandUtils.worldMatrixChangeOperations.getCommandData(
        currentMesh,
        cmdOptions
      );
    let scalingCommand = commandUtils.worldMatrixChangeOperations.getCommand(
      "Object Properties",
      scaleChangeAfterCommandData,
      cmdOptions
    );

    let cmd, yetToExecutes;
    // currentMesh.computeWorldMatrix(true);
    if (updateCommands) {
      cmd = [
        scalingCommand,
        ...updateCommands.creationCommands,
        ...updateCommands.deletionCommands,
        ...updateCommands.dllCommands,
      ];
      yetToExecutes = [
        false,
        ...updateCommands.creationCommands.map((c) => false),
        ...updateCommands.deletionCommands.map((c) => true),
        ...updateCommands.dllCommands.map((c) => false),
      ];
    } else {
      cmd = [scalingCommand];
      yetToExecutes = [false];
    }
    cmd.push(...additionalCmds);
    yetToExecutes.push(...additionalYets);

    const integrationGeometryChangeCommand =
      virtualSketcher.updateWithGeometryEdit(currentMeshDS);

    if (integrationGeometryChangeCommand) {
      cmd.push(integrationGeometryChangeCommand);
      yetToExecutes.push(false);
    }
    
    if (propertyChangeCommand){
      cmd.push(propertyChangeCommand);
      yetToExecutes.push(false);
    }

    if (options.returnCommand) {
      return { cmd: cmd, yetToExecutes: yetToExecutes };
    } else {
      CommandManager.execute(cmd, yetToExecutes);
    }
  };

  // var _updateDimensionMaterial = function (dist, type, mesh = null, storeEvent = true) {
  //     let _commandData = null;
  //     let currentMesh = store.selectionStack[selectionStack.length - 1];
  //     if (mesh) {
  //         currentMesh = mesh;
  //     }
  //     if (!currentMesh) {
  //         return
  //     }
  //     let scale = currentMesh.scaling;
  //     let bbinfo = currentMesh.getBoundingInfo();
  //     let prevScaling = jQuery.extend({}, currentMesh.scaling);

  //     let childrens = currentMesh.getChildren();

  //     let meshDimensions = objectPropertiesView.getMeshDimensions(currentMesh);

  //     // currentMesh.getChildren().forEach((child) => {
  //     //     child.parent = null;
  //     // });

  //     let options = {
  //         params: [commandUtils.worldMatrixChangeOperations.PARAMS.scale,
  //             commandUtils.worldMatrixChangeOperations.PARAMS.position]
  //     };

  //     if (currentMesh.parent) {
  //         options.operationOnChild = true;
  //     }
  //     _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(currentMesh, options);

  //     if (type === "height") {
  //         currentMesh.scaling.y = (scale.y * dist) / meshDimensions.height;
  //         currentMesh.position.y += (currentMesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
  //         if (_.round(prevScaling.y, 4) !== _.round(currentMesh.scaling.y, 4)) {
  //             if (["mass", 'wall', 'floor', 'roof'].includes(currentMesh.type.toLowerCase())) {
  //                 currentMesh.getSnaptrudeDS().markAsEdited();
  //             }
  //         }
  //     } else if (type === "width") {
  //         let depth = DisplayOperation.getOriginalDimension(meshDimensions.depth);
  //         let breadth = DisplayOperation.getOriginalDimension(meshDimensions.breadth);
  //         if (breadth > depth) {
  //             currentMesh.scaling.z = (scale.z * dist) / meshDimensions.breadth;
  //         }
  //         else {
  //             currentMesh.scaling.x = (scale.x * dist) / meshDimensions.depth;
  //         }
  //     } else if (type === "thickness") {
  //         // currentMesh.scaling.x = (scale.x * dist)/_input.originalValue;
  //         let depth = DisplayOperation.getOriginalDimension(meshDimensions.depth);
  //         let breadth = DisplayOperation.getOriginalDimension(meshDimensions.breadth);
  //         if (breadth < depth) {
  //             currentMesh.scaling.z = (scale.z * dist) / meshDimensions.breadth;
  //         }
  //         else {
  //             currentMesh.scaling.x = (scale.x * dist) / meshDimensions.depth;
  //         }
  //     }

  //     options.data = _commandData;
  //     _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(currentMesh, options);
  //     let scalingCommand = commandUtils.worldMatrixChangeOperations.getCommand(
  //         "Object Properties", _commandData);

  //     // childrens.forEach((child) =>{
  //     //     child.parent = currentMesh;
  //     // });

  //     let updateCommands = updateCSG(currentMesh);

  //     if (currentMesh.children) {
  //         currentMesh.children.forEach((mesh) => {
  //             if (mesh.name.indexOf("boxScale") > -1) {
  //                 mesh.dispose();
  //             }
  //         });

  //         drawSelectionBox(currentMesh);
  //         _removeDimensions();
  //         _newDisplayMeshDimensions(currentMesh);
  //     }
  //     if (updateCommands) {
  //         CommandManager.execute([scalingCommand,
  //             ...updateCommands.creationCommands, ...updateCommands.deletionCommands],
  //         [false, false, true]);
  //     }
  //     else {
  //         CommandManager.execute(scalingCommand);
  //     }

  // };

  var _updateInputValues = function (type) {
    if (type === "tolerance") {
      if (store.$scope.units_type.value === "feet-inches") {
        //console.log("imin tolerance",store.$scope.units_type.value)
        return null;
      }
      _input ? _setProperUnit(_input, _input.originalValue) : (_input = null);
      _input2
        ? _setProperUnit(_input2, _input2.originalValue)
        : (_input2 = null);
      _input3
        ? _setProperUnit(_input3, _input3.originalValue)
        : (_input3 = null);
    } else if (type === "units_type") {
      _input ? _setProperUnit(_input, _input.originalValue) : (_input = null);
      _input2
        ? _setProperUnit(_input2, _input2.originalValue)
        : (_input2 = null);
      _input3
        ? _setProperUnit(_input3, _input3.originalValue)
        : (_input3 = null);
    }
  };

  var _drawDimensionLine = function (st, end, name) {
    if (!name) {
      name = "dimline";
    }
    _dimMesh = store.scene.getMeshByName(name);
    if (_dimMesh) {
      _dimMesh.dispose();
      _dimMesh = createLine(name, st, end);
      //console.log(_dimMesh);
    } else {
      _dimMesh = createLine(name, st, end);
      // console.log(_dimMesh);
    }

    //BABYLON.AbstractMesh.Mesh.LinesMesh.MergeMeshes()
    // let bbinfo = _moveObject.mesh.getBoundingInfo();
    // let offset = 0;
    // if (_direction === "x"){
    //     _dimMesh.position.y = bbinfo.boundingBox.maximumWorld.y + offset ;
    // }
    // else if (_direction === "y"){
    //     _dimMesh.position.x = bbinfo.boundingBox.maximumWorld.x  + offset;
    // }
    // else if (_direction === "z"){
    //     _dimMesh.position.y = bbinfo.boundingBox.maximumWorld.y  + offset;
    // }

    return _dimMesh;
  };

  var _updateDistance = function (dist, _moveObject, _direction) {
    //console.log("hello hi", dist)
    _moveObject.onMove.onPointerMove.call(_moveObject, _direction, dist, 1);
  };

  var _removeDimensions = function () {
    //
    // console.log(advancedTexture._rootContainer.children);
    // store.advancedTexture._rootContainer.children.forEach((child) =>{
    //     console.log(child);
    //     child.dispose();
    // });
    // // _dimMesh ? _dimMesh.dispose() : _dimMesh = null;
    // // _dimMesh2 ? _dimMesh2.dispose() : _dimMesh2 = null;
    // // _dimMesh3 ? _dimMesh3.dispose() : _dimMesh3 = null;
    //
    // let distBox1 = store.scene.getMeshByName("dimline2");
    // console.log(distBox1);
    // if (distBox1){distBox1.dispose()};
    // _input ? _input.dispose() : _input = null;
    // _input2 ? _input2.dispose() : _input2 = null;
    // _input3 ? _input3.dispose() : _input3 = null;
    lines.forEach((line) => {
      line.dispose();
    });

    inputs.forEach((input) => {
      input.dispose();
    });
    let distBox =
      store.advancedTexture._rootContainer.getChildByName("distBox");
    if (distBox) {
      distBox.dispose();
    }

    store.numberFlag = 0;
    inputs = [];
    lines = [];
    store.$scope.display_units = [];
    store.$scope.selected_display_unit = null;
    inputEditedNumber = 0;
    //console.log("removing mesh")
  };

  const focusOnCanvas = function () {
    if (document.activeElement !== store.canvas) store.canvas.focus();
  };

  var _displayDimension = function (diff, name, setUnit, options = {}) {
    let setPretext = options.setPretext;
    let onChangeCallback = options.onChangeCallback;
    let inputFocus = options.inputFocus;

    if (setPretext === undefined) setPretext = null;
    if (onChangeCallback === undefined) onChangeCallback = getEmptyFunction();
    if (inputFocus === undefined) inputFocus = true;

    if (name == "projDimLine") {
      _dimMesh = store.scene.getMeshByName("projDimLine");
    }
    if (!name) {
      name = "distBox";
      store.$scope.selected_display_unit =
        store.advancedTexture._rootContainer.getChildByName(name);
    }
    if (setUnit === undefined) {
      setUnit = true;
    }
    _input = store.advancedTexture._rootContainer.getChildByName(name);
    if (_input) {
      // _input.linkWithMesh(_dimMesh);
      // linkWithMesh messes up animation on the inputBox

      const position =
        options.inputTextPosition || _dimMesh.snaptrudeProperties.position;
      _input.snaptrudeProperties.position = position;

      if (options.followMouse) {
        const deviationInPixels = 50;
        _input.leftInPixels = options.pointerLocation.x - deviationInPixels;
        _input.topInPixels = options.pointerLocation.y - deviationInPixels;
      } else {
        _input.moveToVector3(position, store.scene);
      }

      _input.linkOffsetY = -15;
      _input.originalValue = diff;
      _input.rotation = 0;
      // _input.thickness = 0;
      _input.focusedBackground = "transparent";

      // eslint-disable-next-line no-empty
      if (!inputFocus) {
      } else if (_input.inputFocus) {
        store.advancedTexture.moveFocusToControl(_input);
        _input.inputFocus = true;
      }

      /*
            if (!inputFocus && store.advancedTexture.focusedControl === _input){
                _input.blur();
            }
            else if(_input.inputFocus && store.advancedTexture.focusedControl !== _input){
                _input.focus();
                _input.inputFocus = true;
            }
            */

      focusOnCanvas();

      /*
            _input's processKeyboard is called from store.scene.onPreKeyboardObservable, when _input is
            store.advancedTexture.focusedControl

            The observable links to keydown listener on store.canvas.
            Babylon adds and removes these event listeners everytime store.canvas element comes into and goes out of
            focus.

            When we go from 2D to 3D, store.canvas goes out of focus. When draw is selected, pre click snap is used and
            keys are pressed, the keydown listeners of the store.canvas don't fire, thus the above step;

            Use getEventListeners(elements) for debugging
             */

      if (_input.color === limitBreachSignallingColor) _input.color = "black";

      _setProperUnit(_input, diff, name);

      if (setUnit) {
        _setProperUnit(_input, diff, name);
      } else {
        _input.text = diff.toFixed(2);
      }
      _input.edited = false;
      inputEditedNumber = 0;
      _input.autoStretchWidth = true;
    } else {
      if (name === "measureBox" || name == "dashedLine1")
        _input = new createTextBlock(name, diff);
      else
        _input = new createInput(name, diff, inputFocus, {
          onChangeCallback,
        });
      // _input.onBlurObservable.add((e) => {
      //    //console.log("text changed", e.text);
      //     _updateDistance(e.text)
      // });

      if (_input.name === "distBox") {
        _input.background = "transparent";
        // _input.thickness = 1;
      }
      store.$scope.selected_display_unit = _input;
      // _input.linkWithMesh(_dimMesh);
      // linkWithMesh messes up animation on the inputBox
      const position =
        options.inputTextPosition || _dimMesh.snaptrudeProperties.position;
      _input.moveToVector3(position, store.scene);
      _input.snaptrudeProperties.position = position;

      _input.linkOffsetY = -15;
      // _input.thickness = 0;
      _input.scaleX = 1;
      _input.scaleY = 1;
      _input.rotation = 0;
      _input.focusedBackground = "transparent";
      _input.isPointerBlocker = true;
      _input.disableMobilePrompt = true;
    }
    if (name === "measureBox") {
      store.$scope.old_tape_measure_value = _input.text.substring(
        0,
        _input.text.length - 3
      );
      if (setPretext) {
        _input.text =
          "Enter actual dimension in " + ScopeUtils.getCurrentUnit();
      }
      if (setScaleOperation.isInputBoxEnabled()) {
        if (store.isiPad) {
          _input.disableMobilePrompt = true;
          if (_input.onPointerDownObservable._observers.length < 1) {
            _input.onPointerDownObservable.add(function () {
              console.log("In INPUT");
              $("#setScaleModal").modal({
                backdrop: false,
                show: true,
              });

              $(".modal-dialog").draggable({
                handle: ".modal-header",
              });

              let setScaleOk = document.getElementById("ss-ok");
              let setScaleCancel = document.getElementById("ss-cancel");
              let scaleValue = document.getElementById("scalevalue");

              setScaleOk.onclick = function (ev) {
                // if (!parseFloat(scaleValue.value)) return;
                let allPol = [];
                store.$scope.tape_measure_value = scaleValue.value;
                // setScaleForFPAndMeshes();
                setScaleOperation.scale();
                AutoDetectDisplay.AutodetectDisplay();
                $("#setScaleModal").modal("toggle");
              };

              setScaleCancel.onclick = function (ev) {
                $("#setScaleModal").modal("toggle");
              };
            });
          }
        }
      }
    }

    return _input;
  };

  const update2DPosition = function () {
    inputs.forEach((input) => {
      if (input.snaptrudeProperties && input.snaptrudeProperties.position) {
        input.moveToVector3(input.snaptrudeProperties.position, store.scene);
      }
    });
  };

  var displayTextBox = function (text, position, options = {}) {
    let name = "distBox";
    _input = createInput(name, text, false, options);

    _input.snaptrudeProperties.position = position;

    // _input.linkWithMesh(positionMesh);
    // _input.linkOffsetY = -15;
    _input.moveToVector3(position, store.scene);
    _input.thickness = 0;
    _input.background = "transparent";
    _input.focusedBackground = "transparent";

    _input.isPointerBlocker = true;
    _input.disableMobilePrompt = true;

    return _input;
  };

  var _newDisplayMeshDimensions = function (mesh) {
    _removeDimensions();

    //console.log("mesh------------>", mesh.name);
    //mesh.getBoundingInfo().update();
    //if (mesh.state !== "on") return null;
    mesh.computeWorldMatrix(true);
    mesh.refreshBoundingInfo();
    let scaling = mesh.absoluteScaling.clone();
    let vw = mesh.getBoundingInfo().boundingBox.vectorsWorld;
    let depth = mesh.getBoundingInfo().boundingBox.extendSize.x * scaling.x * 2;
    let breadth =
      mesh.getBoundingInfo().boundingBox.extendSize.z * scaling.z * 2;
    let height =
      mesh.getBoundingInfo().boundingBox.extendSize.y * scaling.y * 2;
    let x1 = vw[0].add(vw[3]).scale(0.5);
    let x2 = vw[5].add(vw[2]).scale(0.5);
    let dist1 = BABYLON.Vector3.Distance(x1, x2);

    // console.log("breadth",_selectedInputType(breadth));
    //  console.log("depth", _selectedInputType(depth));
    //  console.log("height",_selectedInputType(height));

    _drawDimensionLine(x1, x2, "", "", "");
    _input = createInput("distBox1", Math.abs(depth), false);
    _input.linkWithMesh(_dimMesh);
    _input.top = 10;

    // $scope.objectProps[8].value = _input.text;
    // $scope.room_thickness = _input.text;
    // $scope.room_name = mesh.name;

    $("#rname").val(mesh.name);
    $("#rthickness").val(_input.text);

    let y1 = vw[2].add(vw[7]).scale(0.5);
    let y2 = vw[5].add(vw[1]).scale(0.5);
    let dist2 = BABYLON.Vector3.Distance(y1, y2);

    if (!store.$scope.isTwoDimension) {
      _dimMesh2 = createLine("dimLine2", y1, y2);
      _input2 = createInput("dist2", Math.abs(height), false);
      _input2.linkWithMesh(_dimMesh2);
      _input2.top = "50px";

      // $scope.objectProps[7].value = _input2.text;
      // $scope.room_height = _input2.text;
      $("#rheight").val(_input2.text);
    } else {
      _input2 = createInput("dist2", height, false);
      _input2.isVisible = false;
    }
    let z1 = vw[2].add(vw[5]).scale(0.5);
    let z2 = vw[7].add(vw[1]).scale(0.5);
    //console.log(z1);
    z1.y += 2;
    z2.y += 2;

    let dist3 = BABYLON.Vector3.Distance(z1, z2);

    _input3 = createInput("dist3", Math.abs(breadth), false);
    _dimMesh3 = createLine("dimLine3", z1, z2);
    _input3.linkWithMesh(_dimMesh3);
    // $scope.objectProps[6].value = _input3.text;
    // $scope.room_width = _input3.text;
    $("#rwidth").val(_input3.text);

    //console.log(_dimMesh, mesh.position);
    _input.linkWithMesh(_dimMesh);
  };

  var _displayMeshDimensions = function (mesh) {
    _removeDimensions();
    if (mesh.state !== "on") return null;
    //console.log("mesh ---->", mesh);
    mesh.refreshBoundingInfo();
    let bbox = mesh.getBoundingInfo().boundingBox;

    // get points for line1 along the
    let st = new BABYLON.Vector3.Zero();
    st.x = mesh.position.x - bbox.extendSizeWorld.x;
    st.y = mesh.position.y;
    st.z = mesh.position.z - bbox.extendSizeWorld.z;

    let end = new BABYLON.Vector3.Zero();
    end.x = mesh.position.x + bbox.extendSizeWorld.x;
    end.y = mesh.position.y;
    end.z = mesh.position.z - bbox.extendSizeWorld.z;

    let dist1 = BABYLON.Vector3.Distance(st, end);

    let st2 = new BABYLON.Vector3.Zero();
    st2.x = mesh.position.x + bbox.extendSizeWorld.x;
    st2.y = mesh.position.y + 2;
    st2.z = mesh.position.z + bbox.extendSizeWorld.z;

    let end2 = new BABYLON.Vector3.Zero();
    end2.x = mesh.position.x + bbox.extendSizeWorld.x;
    end2.y = mesh.position.y + 2;
    end2.z = mesh.position.z - bbox.extendSizeWorld.z;

    let dist2 = BABYLON.Vector3.Distance(st2, end2);

    _input2 = createInput("dist2", dist2, false);
    let st3 = new BABYLON.Vector3.Zero();
    st3.x = mesh.position.x + bbox.extendSizeWorld.x;
    st3.y = mesh.position.y + bbox.extendSizeWorld.y;
    st3.z = mesh.position.z;

    let end3 = new BABYLON.Vector3.Zero();
    end3.x = mesh.position.x + bbox.extendSizeWorld.x;
    end3.y = mesh.position.y - bbox.extendSizeWorld.y;
    end3.z = mesh.position.z;

    let dist3 = BABYLON.Vector3.Distance(st3, end3);
    _input3 = createInput("dist3", dist3, false);
    _dimMesh3 = createLine("dimLine3", st3, end3);
    _input3.linkWithMesh(_dimMesh3);
    _input3.top = "50px";
    _dimMesh3.parent = mesh;

    _drawDimensionLine(st, end, "", "", "");
    _input = createInput("distBox", dist1, false);
    _input.linkWithMesh(_dimMesh);
    _input.top = 10;

    _dimMesh2 = createLine("dimLine2", st2, end2);
    _input2.linkWithMesh(_dimMesh2);
    _input2.top = "50px";
    _dimMesh2.parent = mesh;
    _dimMesh.parent = mesh;

    //let mergedMesh = BABYLON.Mesh.MergeMeshes([mesh, _dimMesh2,_dimMesh3,_dimMesh]);
  };

  var _displayScaleDimensions = function (
    st,
    mesh,
    diff,
    scaleMeshDir,
    originalScaling,
    options
  ) {
    let bbinfo = mesh.getBoundingInfo();

    //let end = Object.assign({},mesh.positon);
    //console.log("scaling------------>", mesh.scaling);
    if (scaleMeshDir === "x1") {
      //end.x -= bbinfo.boundingBox.extendSizeWorld.x;
      //let end =  ((((bbinfo.boundingBox.vectorsWorld[0].add(bbinfo.boundingBox.vectorsWorld[3]) ).scale(0.5) ).add( bbinfo.boundingBox.vectorsWorld[6].add(bbinfo.boundingBox.vectorsWorld[4]) ).scale(0.5))).scale(0.5);
      let v1 = bbinfo.boundingBox.vectorsWorld[0]
        .add(bbinfo.boundingBox.vectorsWorld[3])
        .scale(0.5);
      let v2 = bbinfo.boundingBox.vectorsWorld[6]
        .add(bbinfo.boundingBox.vectorsWorld[4])
        .scale(0.5);
      let end1 = v1.add(v2).scale(0.5);
      DisplayOperation.drawOnMove(st, end1, "", "", "");
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x2") {
      let v1 = bbinfo.boundingBox.vectorsWorld[5]
        .add(bbinfo.boundingBox.vectorsWorld[2])
        .scale(0.5);
      let v2 = bbinfo.boundingBox.vectorsWorld[1]
        .add(bbinfo.boundingBox.vectorsWorld[7])
        .scale(0.5);
      let end1 = v1.add(v2).scale(0.5);
      DisplayOperation.drawOnMove(st, end1, "", "", "");
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "y1") {
      let v1 = bbinfo.boundingBox.vectorsWorld[0]
        .add(bbinfo.boundingBox.vectorsWorld[2])
        .scale(0.5);
      let v2 = bbinfo.boundingBox.vectorsWorld[7]
        .add(bbinfo.boundingBox.vectorsWorld[4])
        .scale(0.5);
      let end1 = v1.add(v2).scale(0.5);
      DisplayOperation.drawOnMove(st, end1, "", "", "");
      _displayDimension(
        1 - (originalScaling.y - mesh.scaling.y),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "y2") {
      let v1 = bbinfo.boundingBox.vectorsWorld[3]
        .add(bbinfo.boundingBox.vectorsWorld[5])
        .scale(0.5);
      let v2 = bbinfo.boundingBox.vectorsWorld[6]
        .add(bbinfo.boundingBox.vectorsWorld[1])
        .scale(0.5);
      let end1 = v1.add(v2).scale(0.5);
      DisplayOperation.drawOnMove(st, end1, "", "", "");
      _displayDimension(
        1 - (originalScaling.y - mesh.scaling.y),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "z1") {
      let v1 = bbinfo.boundingBox.vectorsWorld[3]
        .add(bbinfo.boundingBox.vectorsWorld[5])
        .scale(0.5);
      let v2 = bbinfo.boundingBox.vectorsWorld[0]
        .add(bbinfo.boundingBox.vectorsWorld[2])
        .scale(0.5);
      let end1 = v1.add(v2).scale(0.5);
      DisplayOperation.drawOnMove(st, end1, "", "", "");
      _displayDimension(
        1 - (originalScaling.z - mesh.scaling.z),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "z2") {
      let v1 = bbinfo.boundingBox.vectorsWorld[4]
        .add(bbinfo.boundingBox.vectorsWorld[7])
        .scale(0.5);
      let v2 = bbinfo.boundingBox.vectorsWorld[6]
        .add(bbinfo.boundingBox.vectorsWorld[1])
        .scale(0.5);
      let end1 = v1.add(v2).scale(0.5);
      DisplayOperation.drawOnMove(st, end1, "", "", "");
      _displayDimension(
        1 - (originalScaling.z - mesh.scaling.z),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x1y1z1") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[0],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x2y1z1") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[2],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x1y2z1") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[3],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x2y2z1") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[5],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x1y1z2") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[4],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x2y1z2") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[7],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x1y2z2") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[6],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
    if (scaleMeshDir === "x2y2z2") {
      DisplayOperation.drawOnMove(
        st,
        bbinfo.boundingBox.vectorsWorld[1],
        "",
        "",
        ""
      );
      _displayDimension(
        1 - (originalScaling.x - mesh.scaling.x),
        "",
        false,
        options
      );
    }
  };

  var inputTextPointerDownModalCallback = function (
    e,
    input,
    onChangeCallback
  ) {
    store.uiCoordinationVariables.clickedOnInputBox = true;

    $("#code").val("");
    // $scope.showPrerenderedDialog(null, "keyboardModal");

    input.target.isVisible = false;

    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });

    let keyboardButton = document.getElementById("keyboardEnter");
    let inputValue = document.getElementById("code");

    keyboardButton.onclick = function () {
      console.log(inputValue.value);

      input.target.isVisible = true;
      input.text = inputValue.value;
      if (store.$scope.units_type.value === "feet-inches") {
        if (!(inputValue.value.indexOf("'") > -1)) {
          inputValue.value += "'";
        }
      }
      onChangeCallback(inputValue.value);
      input.text = inputValue.value;
      // $scope.clearKeyboardBuffer();
      inputValue.value = "";
      // $scope.hideMdDialog();
    };

    // $scope.showPrerenderedDialog(null)
  };

  var createInput = function (name, text, inputFocus = true, options = {}) {
    if (store.isiPad) {
      inputFocus = false;
    }

    let onChangeCallback = options.onChangeCallback;
    if (onChangeCallback === undefined) onChangeCallback = getEmptyFunction();

    let input = new InputText(name);
    input.autoStretchWidth = true;
    input.top = "10px";
    input.height = "30px";
    input.originalValue = text;
    input.previous_unit_type = store.$scope.units_type;
    input.focusedBackground = "transparent";
    //input.text = convertDimensionTo(parseFloat(text)).feet;
    input.color = "black";
    input.fontWeight = "10px";
    input.disableMobilePrompt = true;

    input.background = "transparent";
    input._verticalAlignment += 1;
    input.edited = false;
    input.isEnabled = true;
    input.thickness = 0;

    input.snaptrudeProperties = {};

    store.advancedTexture.addControl(input);

    //console.log(store.$scope.units_type.value);
    _setProperUnit(input, text, name);
    input.oldText = input.text;
    inputs.push(input);
    if (inputFocus) {
      store.advancedTexture.moveFocusToControl(input);
      input.inputFocus = true;
    }

    store.$scope.display_units.push(input);
    //console.log(inputs);

    if (store.isiPad && input.onPointerDownObservable._observers.length < 1) {
      input.onPointerDownObservable.add(function (e, input) {
        inputTextPointerDownModalCallback(e, input, onChangeCallback);
      });
    }

    input.onWheelObservable.add((eventData, eventState) => {
      //to support store.scene zoom in and out when mouse wheel scroll happens on the input box
      const mockEvent = {
        deltaY: eventData.y,
        wheelDelta: -eventData.y,
      };
      onWheelEventListener(mockEvent);
    });

    //console.log("-------------->",input.processKeyboard._observers.length);
    input.processKeyboard = function (evt) {
      if (options.keyFilter) {
        if (options.keyFilter(evt)) return;
      }

      if (input.color === limitBreachSignallingColor) input.color = "black";

      if (isNumberKey(evt)) {
        if (inputEditedNumber <= 0) {
          input.text = evt.key;
          inputEditedNumber++;
        } else {
          this.processKey(evt.keyCode, evt.key);
        }
        this.edited = true;
      }

      if (isBackspaceKey(evt)) {
        this.processKey(evt.keyCode, evt.key);
      }

      if (
        isArrowKey(evt) &&
        !store.snappingGlobalVariables.sessionVariables.inProgress
      ) {
        this.processKey(evt.keyCode, evt.key);
      }

      if (isDotKey(evt)) {
        this.processKey(evt.keyCode, evt.key);
      }

      if (isCommaKey(evt)) {
        this.processKey(evt.keyCode, evt.key);
      }

      if ((evt.keyCode === 189 && !evt.shiftKey) || evt.keyCode === 109) {
        if (inputEditedNumber <= 0) {
          input.text = evt.key;
          inputEditedNumber++;
        } else {
          this.processKey(evt.keyCode, evt.key);
        }
      }

      if (evt.keyCode === 222) {
        if (!input.text.indexOf("'") > -1) {
          this.processKey(evt.keyCode, evt.key);
        } else if (!input.text.indexOf('"')) {
          this.processKey(evt.keyCode, evt.key);
        }
      }

      input.autoStretchWidth = true;
    };
    //input.onFocusSelectAll = true;

    // input.onBeforeKeyAddObservable.add((input) => {
    //        let key = input.currentKey;
    //        if (!isNaN(key)) {
    //            input.addKey = true;
    //        } else {
    //            input.addkey = false;
    //        }
    //
    //    });
    /*input.onPointerClickObservable.add((e,event) =>{
                if(!isiPad){
                    KeyboardView.showNumPad(input,e,event)
                }
            });*/
    return input;
  };

  var createTextBlock = function (name, text) {
    let textBlock = new TextBlock(name, text);
    textBlock.autoStretchWidth = true;
    textBlock.top = "10px";
    textBlock.height = "20px";
    textBlock.originalValue = text;
    textBlock.previous_unit_type = store.$scope.units_type;
    //textBlock.focusedBackground = "white";
    //textBlock.text = convertDimensionTo(parseFloat(text)).feet;
    textBlock.color = "black";
    textBlock.fontWeight = "10px";
    textBlock.outlineColor = "black";
    textBlock.background = "transparent";
    textBlock._verticalAlignment += 1;
    textBlock.edited = false;
    textBlock.resizeToFit = true;
    textBlock.disableMobilePrompt = true;
    textBlock.snaptrudeProperties = {};
    store.advancedTexture.addControl(textBlock);
    textBlock.thickness = 0;
    _setProperUnit(textBlock, text, name);
    textBlock.oldText = textBlock.text;
    inputs.push(textBlock);
    // $scope.display_units.push(textBlock);
    store.numberFlag = 0;
    return textBlock;
  };

  var _setProperUnit = function (input, text, name) {
    if (name === "angleInput") {
      input.text = text + String.fromCharCode(176);
    } else if (store.$scope.units_type.value === "feet-inches") {
      input.text = convertDimensionTo(parseFloat(text)).feet;
      store.floor_height = 12;
      if (setScaleOperation.isInputBoxEnabled()) {
        input.text = convertDimensionTo(parseFloat(text)).feet + " ft";
      }
    } else if (store.$scope.units_type.value === "meters") {
      input.text = convertDimensionTo(parseFloat(text)).mts;
      store.floor_height = 0.3 / store.unit_absolute_scale;
      if (setScaleOperation.isInputBoxEnabled()) {
        input.text = convertDimensionTo(parseFloat(text)).mts + "  m";
      }
    } else if (store.$scope.units_type.value === "centimeter") {
      input.text = convertDimensionTo(parseFloat(text)).cm;
      store.floor_height = 0.3 / store.unit_absolute_scale;
      if (setScaleOperation.isInputBoxEnabled()) {
        input.text = convertDimensionTo(parseFloat(text)).cm + " cm";
      }
    } else if (store.$scope.units_type.value === "millimeter") {
      input.text = convertDimensionTo(parseFloat(text)).mMts;
      store.floor_height = 0.3 / store.unit_absolute_scale;
      if (setScaleOperation.isInputBoxEnabled()) {
        input.text = convertDimensionTo(parseFloat(text)).mMts + " mm";
      }
    } else {
      input.text = convertDimensionTo(parseFloat(text)).inch;
      store.floor_height = 12;
      if (setScaleOperation.isInputBoxEnabled()) {
        input.text = convertDimensionTo(parseFloat(text)).inch + " in";
      }
    }
    input.autoStretchWidth = true;
  };

  var _getInputToEdit = function (scope) {
    if (scope.selected_display_unit === null) {
      if (store.advancedTexture._rootContainer.getChildByName("distBox")) {
        return store.advancedTexture._rootContainer.getChildByName("distBox");
      } else if (
        store.advancedTexture._rootContainer.getChildByName("measureBox")
      ) {
        return store.advancedTexture._rootContainer.getChildByName(
          "measureBox"
        );
      } else if (
        store.advancedTexture._rootContainer.getChildByName("distTextBox")
      ) {
        return store.advancedTexture._rootContainer.getChildByName(
          "distTextBox"
        );
      }

      return arrayFunctionOperator.getMetadata().getInputBox();
    } else if (scope.display_units.length > 1) {
      return store.advancedTexture.focusedControl;
    } else {
      return scope.selected_display_unit;
    }
  };

  var _setDimMesh = function (mesh) {
    _dimMesh = mesh;
  };

  var createLine = function (name, st, end) {
    let dimMesh = BABYLON.Mesh.CreateLines(name, [st, end], store.scene);
    lines.push(dimMesh);
    dimMesh.isPickable = false;
    dimMesh.color = new BABYLON.Color3(0.266, 0.266, 0.266);
    dimMesh.renderingGroupId = 1;

    dimMesh.snaptrudeProperties = {
      position: BABYLON.Vector3.Center(st, end),
    };

    // var head1 = BABYLON.Mesh.CreateLines("head1"+name, [st, new BABYLON.Vector3(st.x+1, st.y,st.z+1) ],scene);
    // head1.position = st;
    // head1.addRotation(Math.PI/2, 0, 0).addRotation(0, Math.PI/4, 0).addRotation(0, Math.PI/6, 0);
    // var head2 = BABYLON.Mesh.CreateLines("head2"+name, [st,new BABYLON.Vector3(end.x+1, end.y,end.z+1) ],scene);
    // head2.position = end;
    // head2.addRotation(Math.PI/2, 0, 0).addRotation(0, Math.PI/4, 0).addRotation(0, Math.PI/6, 0)
    //  let p = (st.subtract(end).normalize() * 0.2) + st;
    //
    //     if(scene.getMeshByName("crossLine1")){
    //             store.scene.getMeshByName("crossLine1").dispose();
    //             var cross1 =  BABYLON.Mesh.CreateLines("crossLine1", [st, p], store.scene);
    //             cross1.color = new BABYLON.Color3(1,1,1);
    //             cross1.rotation.x = Math.PI/4;
    //             cross1.renderingGroupId = 1;
    //     }else{
    //         var cross1 =  BABYLON.Mesh.CreateLines("crossLine1", [st, p], store.scene);
    //         cross1.color = new BABYLON.Color3(1,1,1);
    //         cross1.rotation.x = Math.PI/4;
    //         cross1.renderingGroupId = 1;
    //     }

    return dimMesh;
  };

  const signalInputError = function (
    inputText = getBabylonGUIElementByName("distBox")
  ) {
    if (!inputText) {
      console.error("Input box not found");
      return;
    }

    inputText.color = limitBreachSignallingColor;

    const deviationInPixels = 5;
    const frameRate = 10;
    const timeForOneLoop = 0.15;
    const animationDuration = 0.45;

    const initialPosition = inputText.leftInPixels;

    const shakeAnimation = new BABYLON.Animation(
      "xSlide",
      "leftInPixels",
      frameRate,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const keyFrames = [];
    const timeForOneLeg = timeForOneLoop / 4;

    let numberOfLoops = Math.round(animationDuration / timeForOneLoop);
    let currentTime = 0;

    while (numberOfLoops > 0) {
      keyFrames.push({
        frame: (currentTime + timeForOneLeg) * frameRate,
        value: initialPosition - deviationInPixels,
      });

      keyFrames.push({
        frame: (currentTime + 3 * timeForOneLeg) * frameRate,
        value: initialPosition + deviationInPixels,
      });

      keyFrames.push({
        frame: (currentTime + 4 * timeForOneLeg) * frameRate,
        value: initialPosition,
      });

      numberOfLoops--;
      currentTime += timeForOneLoop;
    }

    shakeAnimation.setKeys(keyFrames);

    inputText.animations = [];
    inputText.animations.push(shakeAnimation);

    store.scene.beginAnimation(
      inputText,
      0,
      animationDuration * frameRate,
      true,
      1.0
    );

    resetCounter();
  };

  const resetCounter = function () {
    inputEditedNumber = 0;
    // so that on the next keydown, present text gets cleared and fresh input is taken
  };

  const _getInputs = () => {
    return inputs;
  }

  return {
    input: inputs,
    getInputs: _getInputs,
    drawOnMove: function (st, end, name) {
      return _drawDimensionLine(st, end, name);
    },

    displayOnMove: function (diff, name, setUnit, options) {
      return _displayDimension(diff, name, setUnit, options);
    },
    displayTextBox,
    removeDimensions: function () {
      _removeDimensions();
    },

    displayMeshDimensions: function (mesh) {
      _newDisplayMeshDimensions(mesh);
    },

    displayScaleDimensions: function (
      st,
      mesh,
      diff,
      scaleMeshDir,
      originalScaling,
      options
    ) {
      _displayScaleDimensions(
        st,
        mesh,
        diff,
        scaleMeshDir,
        originalScaling,
        options
      );
    },
    updateInputValues: function (type) {
      _updateInputValues(type);
    },
    getOriginalDimension: function (dist, unit) {
      return _getOriginalDimension(dist, unit);
    },
    updateDimensionScale: function (
      dist,
      type,
      oldValue,
      mesh = null,
      options
    ) {
      return _updateDimensionScale(dist, type, oldValue, mesh, options);
    },
    // updateDimensionMaterial: function (dist, type, mesh = null, storeEvent = true) {
    //     return _updateDimensionMaterial(dist, type, mesh, storeEvent);
    // },
    getInputToEdit: function (scope) {
      return _getInputToEdit(scope);
    },
    convertToDefaultDimension: function (input, options) {
      return _convertToDefaultDimension(input, options);
    },
    convertToDefaultArea: function (input) {
      return _convertToDefaultArea(input);
    },
    convertToDefaultVolume: function (input) {
      return _convertToDefaultVolume(input);
    },
    setDimMesh: function (mesh) {
      return _setDimMesh(mesh);
    },
    convertDimensionTo,
    inputTextPointerDownModalCallback,
    createInput,
    signalInputError,
    resetCounter,
    update2DPosition,
    focusOnCanvas,
    convertDimensionTo
  };
})();
export { DisplayOperation };
