import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { createRotationComps } from "./meshEvents.js";
var rotateMeshDir = null;
var rotateMeshStartingPosition = null;
var rotateMesh = null;
var rotationScale = 50;
var onRotateMeshDown1 = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("ground1") === -1 &&
        mesh.name.indexOf("backwall") === -1 &&
        mesh.name.indexOf("axis") === -1 &&
        mesh.visibility > 0
      );
    }
  );
  var box = store.scene.getMeshByName("rotateMeshBox");
  console.log(box);

  if (rotateMeshDir) {
    rotateMeshDir = null;
    rerotateRotateMeshAxis();
    // onSolid();
    updateModifications();
  } else if (pickInfo.hit) {
    if (pickInfo.pickedMesh.name === "rotateMeshaxisX") {
      store.scene.getMeshByName("rotateMeshaxisY").dispose();
      store.scene.getMeshByName("rotateMeshaxisZ").dispose();
      rotateMeshDir = "x";
      // drawRotationAxis(rotateMeshDir, pickInfo.pickedMesh);
      rotateMeshStartingPosition = getScenePositionForRotateMesh(evt);
    } else if (pickInfo.pickedMesh.name === "rotateMeshaxisY") {
      store.scene.getMeshByName("rotateMeshaxisX").dispose();
      store.scene.getMeshByName("rotateMeshaxisZ").dispose();
      rotateMeshDir = "y";
      rotateMeshStartingPosition = getScenePositionForRotateMesh(evt);
    } else if (pickInfo.pickedMesh.name === "rotateMeshaxisZ") {
      store.scene.getMeshByName("rotateMeshaxisX").dispose();
      store.scene.getMeshByName("rotateMeshaxisY").dispose();
      rotateMeshDir = "z";
      rotateMeshStartingPosition = getScenePositionForRotateMesh(evt);
    } else {
      rotateMeshDir = null;
      drawRotateMeshAxis(pickInfo.pickedMesh);
      rotateMesh = pickInfo.pickedMesh;
      rotateMeshStartingPosition = getScenePositionForRotateMesh(evt);
    }
  } else if (box) {
    rotateMeshDir = null;
    drawRotateMeshAxis(box);
  } else {
    rotateMeshDir = null;
    rerotateRotateMeshAxis();
    rotateMesh = null;
    if (store.scene.getMeshByName("rotateMeshBox"))
      store.scene.getMeshByName("rotateMeshBox").dispose();
  }
};

var onRotateMeshUp1 = function (evt) {
  // store.newScene.activeCamera.attachControl(canvas, true, false);
  // rotateMeshDir = null;
  // var rotLine1 = store.scene.getMeshByName("rotLine1");
  // if (rotLine1)
  //     rotLine1.color = BABYLON.Color3.Red();
};

var onRotateMeshMove1 = function (evt) {
  for (var i = 0; i < store.scene.meshes.length; i++) {
    // unclick(scene.meshes[i]);
    if (store.scene.meshes[i].name.indexOf("boxScale") > 0) {
      store.scene.meshes[i].dispose();
    }
  }
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("ground1") === -1 &&
        mesh.name.indexOf("backwall") === -1 &&
        mesh.name.indexOf("axis") === -1 &&
        mesh.visibility > 0 &&
        mesh.isPickable
      );
    }
  );
  if (rotateMeshDir) {
    if (!rotateMeshStartingPosition) {
      return;
    }
    var rotateMeshCurrentPosition = getScenePositionForRotateMesh(evt);
    if (!rotateMeshCurrentPosition) {
      return;
    }
    var diff = rotateMeshStartingPosition.subtract(rotateMeshCurrentPosition);
    // console.log(rotateMeshStartingPosition, rotateMeshCurrentPosition);
    var angle = computeDiffAngle(
      rotateMeshStartingPosition,
      rotateMeshCurrentPosition,
      rotateMeshDir
    );
    rotateRotateMesh(rotateMesh, angle, rotateMeshDir);
    // rotateMeshStartingPosition = rotateMeshCurrentPosition;
  } else if (pickInfo.hit && !store.scene.getMeshByName("rotateMeshBox")) {
    createRotationComps(pickInfo.pickedMesh);
  } else {
    /*else if (scene.getMeshByName("rotateMeshaxisX") || store.scene.getMeshByName("rotateMeshaxisY") || store.scene.getMeshByName("rotateMeshaxisZ")) {
    }*/
    var box = store.scene.getMeshByName("rotateMeshBox");
    if (box) box.dispose();
  }
};

function drawRotateMeshAxis(rotateMesh) {
  var rotLine1 = store.scene.getMeshByName("rotLine1");
  rotLine1.color = new BABYLON.Color3(0, 1, 0);
  if (
    rotLine1.rotation.x === 0 &&
    rotLine1.rotation.y === 0 &&
    rotLine1.rotation.z === 0
  )
    rotateMeshDir = "y";
  else if (
    rotLine1.rotation.x > 0 &&
    rotLine1.rotation.y === 0 &&
    rotLine1.rotation.z === 0
  )
    rotateMeshDir = "x1";
  else if (
    rotLine1.rotation.x < 0 &&
    rotLine1.rotation.y === 0 &&
    rotLine1.rotation.z === 0
  )
    rotateMeshDir = "x2";
  else if (
    rotLine1.rotation.x === 0 &&
    rotLine1.rotation.y === 0 &&
    rotLine1.rotation.z > 0
  )
    rotateMeshDir = "z1";
  else if (
    rotLine1.rotation.x === 0 &&
    rotLine1.rotation.y === 0 &&
    rotLine1.rotation.z < 0
  )
    rotateMeshDir = "z2";
  var bbinfo = rotateMesh.getBoundingInfo();
  // rotationScale /= bbinfo.diagonalLength;
  var dist = BABYLON.Vector3.Distance(
    store.newScene.activeCamera.position,
    rotateMesh.position
  );
  // console.log(dist);
  if (dist < 12) rotationScale /= dist / 5;
}

function rerotateRotateMeshAxis(mesh) {
  var mesh1 = store.scene.getMeshByName("rotateMeshaxisX");
  if (mesh1) {
    mesh1.dispose();
  }
  var mesh2 = store.scene.getMeshByName("rotateMeshaxisY");
  if (mesh2) {
    mesh2.dispose();
  }
  var mesh3 = store.scene.getMeshByName("rotateMeshaxisZ");
  if (mesh3) {
    mesh3.dispose();
  }
}

function rotateMeshAxisrotateMeshventFunc(mesh) {
  mesh.actionManager = new BABYLON.ActionManager(store.newScene);
  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          if (mesh.name === "rotateMeshaxisX") {
            if (store.scene.getMeshByName("rotateMeshaxisX"))
              store.scene.getMeshByName("rotateMeshaxisX").visibility = 1.0;
            if (store.scene.getMeshByName("rotateMeshaxisY"))
              store.scene.getMeshByName("rotateMeshaxisY").visibility = 0.2;
            if (store.scene.getMeshByName("rotateMeshaxisZ"))
              store.scene.getMeshByName("rotateMeshaxisZ").visibility = 0.2;
          }
          if (mesh.name === "rotateMeshaxisY") {
            if (store.scene.getMeshByName("rotateMeshaxisX"))
              store.scene.getMeshByName("rotateMeshaxisX").visibility = 0.2;
            if (store.scene.getMeshByName("rotateMeshaxisY"))
              store.scene.getMeshByName("rotateMeshaxisY").visibility = 1.0;
            if (store.scene.getMeshByName("rotateMeshaxisZ"))
              store.scene.getMeshByName("rotateMeshaxisZ").visibility = 0.2;
          }
          if (mesh.name === "rotateMeshaxisZ") {
            if (store.scene.getMeshByName("rotateMeshaxisX"))
              store.scene.getMeshByName("rotateMeshaxisX").visibility = 0.2;
            if (store.scene.getMeshByName("rotateMeshaxisY"))
              store.scene.getMeshByName("rotateMeshaxisY").visibility = 0.2;
            if (store.scene.getMeshByName("rotateMeshaxisZ"))
              store.scene.getMeshByName("rotateMeshaxisZ").visibility = 1.0;
          }
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          if (store.scene.getMeshByName("rotateMeshaxisX"))
            store.scene.getMeshByName("rotateMeshaxisX").visibility = 1.0;
          if (store.scene.getMeshByName("rotateMeshaxisY"))
            store.scene.getMeshByName("rotateMeshaxisY").visibility = 1.0;
          if (store.scene.getMeshByName("rotateMeshaxisZ"))
            store.scene.getMeshByName("rotateMeshaxisZ").visibility = 1.0;
        }
      )
    );
}

var getScenePositionForRotateMesh = function (evt) {
  if (store.scene.getMeshByName("backwall"))
    store.scene.getMeshByName("backwall").dispose();
  var wall = BABYLON.Mesh.CreatePlane("backwall", 10000.0, store.newScene);
  // wall.material = new BABYLON.StandardMaterial("wallMat", store.newScene);
  wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
  wall.visibility = 0.0;
  var pickinfo1 = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("ground1");
    }
  );
  var pickinfo2 = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("backwall");
    }
  );
  if (pickinfo1.hit && pickinfo2.hit) {
    var pt = new BABYLON.Vector3(
      pickinfo1.pickedPoint.x,
      pickinfo2.pickedPoint.y,
      pickinfo1.pickedPoint.z
    );
    return pt;
  } else if (pickinfo2.hit) {
    return pickinfo2.pickedPoint;
  } else if (pickinfo1.hit) {
    return pickinfo1.pickedPoint;
  }
  return null;
};

function rotateRotateMesh(box, angle, rotateMeshDir) {
  angle = snapAngle(angle);
  if (rotateMeshDir == "x2") {
    // box.rotation.x -= (+diff.z)/rotationScale*5;
    // box.rotate(BABYLON.Axis.Z, -(diff.z/rotationScale*5), BABYLON.Space.WORLD);
    box.rotation.z = angle;
    if (box.name.indexOf("boxScale") != -1) {
      box.parentMesh.rotation.z = box.rotation.z;
    }
  }
  if (rotateMeshDir == "x1") {
    // box.rotation.x += (diff.z)/rotationScale*5;
    // box.rotate(BABYLON.Axis.Z, +(diff.z/rotationScale*5), BABYLON.Space.WORLD);
    box.rotation.z = Math.PI - angle;
    if (box.name.indexOf("boxScale") != -1) {
      box.parentMesh.rotation.z = box.rotation.z;
    }
  }
  if (rotateMeshDir == "y") {
    // box.rotation.y += (diff.x+diff.z)/rotationScale*5;
    // box.rotate(BABYLON.Axis.Y, +((diff.z+diff.x)/rotationScale*10), BABYLON.Space.WORLD);
    box.rotation.y = Math.PI - angle;
    // box.rotation.y = Math.PI - angle;
    if (box.name.indexOf("boxScale") != -1) {
      box.parentMesh.rotation.y = box.rotation.y;
    }
  }
  if (rotateMeshDir == "z2") {
    // box.rotation.z -= (diff.x)/rotationScale*5;
    // box.rotate(BABYLON.Axis.X, -(diff.x/rotationScale*5), BABYLON.Space.WORLD);
    box.rotation.x = Math.PI - angle;
    if (box.name.indexOf("boxScale") != -1) {
      box.parentMesh.rotation.x = box.rotation.x;
    }
  }
  if (rotateMeshDir == "z1") {
    // box.rotation.z += (diff.x)/rotationScale*5;
    // box.rotate(BABYLON.Axis.X, (diff.x/rotationScale*5), BABYLON.Space.WORLD);
    box.rotation.x = angle;
    if (box.name.indexOf("boxScale") != -1) {
      box.parentMesh.rotation.x = box.rotation.x;
    }
  }
  if (box.name.indexOf("boxScale") != -1) {
    box.parentMesh.refreshBoundingInfo();
  }
  // console.log(rotateMeshDir, angle);
  // console.log(box.rotation);
}

function computeDiffAngle(pt1, pt2, dir) {
  var xdiff = pt2.x - pt1.x;
  var ydiff = pt2.y - pt1.y;
  var zdiff = pt2.z - pt1.z;
  let angle;
  if (dir == "x1") {
    angle = Math.atan2(zdiff, xdiff);
  } else if (dir == "x2") {
    angle = Math.atan2(zdiff, xdiff);
  } else if (dir == "y") {
    angle = Math.atan2(zdiff, xdiff);
  } else if (dir == "z1") {
    angle = Math.atan2(xdiff, ydiff);
  } else if (dir == "z2") {
    angle = Math.atan2(xdiff, ydiff);
  }
  return angle;
}

function snapAngle(angle) {
  var angleThresh = 0.08;
  if (Math.abs(angle - Math.PI / 4) < angleThresh) {
    return Math.PI / 4;
  } else if (Math.abs(angle - Math.PI / 2) < angleThresh) {
    return Math.PI / 2;
  } else if (Math.abs(angle - (3 * Math.PI) / 4) < angleThresh) {
    return (3 * Math.PI) / 4;
  } else if (Math.abs(angle - Math.PI) < angleThresh) {
    return Math.PI;
  } else if (Math.abs(-angle - Math.PI / 4) < angleThresh) {
    return -Math.PI / 4;
  } else if (Math.abs(-angle - Math.PI / 2) < angleThresh) {
    return -Math.PI / 2;
  } else if (Math.abs(-angle - (3 * Math.PI) / 4) < angleThresh) {
    return (-3 * Math.PI) / 4;
  } else if (Math.abs(-angle - Math.PI) < angleThresh) {
    return -Math.PI;
  } else if (Math.abs(-angle - 0) < angleThresh) {
    return 0;
  } else {
    return angle;
  }
}

function drawRotationAxis(rotateMeshDir, mesh) {
  let colors1 = [];
  var lines1 = BABYLON.MeshBuilder.CreateLines(
    "rotAxisLine",
    { points: {}, colors: colors1 },
    store.scene
  );
}

// let _dnClick = 0;
// let _startingPoint = null;
// let _handlePoint1 = null;
// let _direction = null;
// let _mesh = null;

// let onRotateMeshDown = function (evt) {
//     _dnClick++;
//     console.log(_dnClick);
//     if (_dnClick === 1) {
//         let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
//             return mesh.name === "boxScale";
//         });
//         _mesh = pickInfo.pickedMesh;
//         console.log(_mesh);
//         if (pickInfo.hit) {
//             _startingPoint = pickInfo.pickedPoint;
//
//             let rotationSupportWall = BABYLON.Mesh.CreatePlane("rotationSupportWall", 10000.0, store.newScene);
//             let mat = store.newScene.getMaterialByName("rotationWallMat");
//             if (!mat) {
//                 mat = new BABYLON.StandardMaterial("rotationWallMat", store.newScene);
//             }
//             rotationSupportWall.material = store.newScene.getMaterialByName("rotationWallMat");
//             rotationSupportWall.material.alpha = 0;
//             rotationSupportWall.type = "rotationSupportWall";
//             rotationSupportWall.isPickable = true;
//
//             let normal = pickInfo.getNormal();
//             normal = normal.normalize();
//
//             if (normal.x !== 0) {
//                 _direction = "x";
//                 rotationSupportWall.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD);
//                 rotationSupportWall.position.x = _startingPoint.x;
//             }
//             else if (normal.y !== 0) {
//                 _direction = "y";
//                 rotationSupportWall.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD);
//                 rotationSupportWall.position.y = _startingPoint.y;
//             }
//             else if (normal.z !== 0) {
//                 _direction = "z";
//                 rotationSupportWall.position.z = _startingPoint.z;
//             }
//         }
//     }
//     else if (_dnClick === 2) {
//         let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
//             return mesh.name === "rotationSupportWall";
//         });
//         _handlePoint1 = new BABYLON.Vector3(_startingPoint.x, pickInfo.pickedPoint.y, pickInfo.pickedPoint.z);
//     }
//     else if (_dnClick === 3) {
//         _dnClick = 0;
//         removeRotationSupportElements();
//         let _startingPoint = null;
//         let _handlePoint1 = null;
//         let _direction = null;
//         let _mesh = null;
//     }
//
// };

// let onRotateMeshMove = function (evt) {
//     if (_dnClick === 1) {
//         let points = null;
//         let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
//             return mesh.name === "rotationSupportWall";
//         });
//         if (newScene.getMeshByName("lines")) {
//             store.newScene.getMeshByName("lines").dispose();
//         }
//         if (_direction === "x") {
//             points = [
//                 _startingPoint,
//                 new BABYLON.Vector3(_startingPoint.x, pickInfo.pickedPoint.y, pickInfo.pickedPoint.z)
//             ];
//         }
//         else if (_direction === "y") {
//             points = [
//                 _startingPoint,
//                 new BABYLON.Vector3(pickInfo.pickedPoint.x, _startingPoint.y, pickInfo.pickedPoint.z)
//             ];
//         }
//         else if (_direction === "z") {
//             points = [
//                 _startingPoint,
//                 new BABYLON.Vector3(pickInfo.pickedPoint.x, pickInfo.pickedPoint.y, _startingPoint.z)
//             ];
//         }
//
//         let line = BABYLON.MeshBuilder.CreateLines("lines", {points: points, updatable: true}, store.newScene);
//         line.material = new BABYLON.StandardMaterial("", store.newScene);
//     }
//     else if (_dnClick === 2) {
//         let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
//             return mesh.name === "rotationSupportWall";
//         });
//         if (_direction === "x") {
//             let _handlePoint2 = new BABYLON.Vector3(_startingPoint.x, pickInfo.pickedPoint.y, pickInfo.pickedPoint.z);
//             let angle = calculateAngleForRotation(_startingPoint, _handlePoint1, _handlePoint2);
//
//             _rotateMesh(_mesh, angle, _startingPoint, _direction);
//         }
//         else if (_direction === "y") {
//             let _handlePoint2 = new BABYLON.Vector3(pickInfo.pickedPoint.x, _startingPoint.y, pickInfo.pickedPoint.z);
//             let angle = calculateAngleForRotation(_startingPoint, _handlePoint1, _handlePoint2);
//
//             _rotateMesh(_mesh, angle, _startingPoint, _direction);
//         }
//         else if (_direction === "z") {
//             let _handlePoint2 = new BABYLON.Vector3(pickInfo.pickedPoint.x, pickInfo.pickedPoint.y, _startingPoint.z);
//             let angle = calculateAngleForRotation(_startingPoint, _handlePoint1, _handlePoint2);
//
//             _rotateMesh(_mesh, angle, _startingPoint, _direction);
//         }
//     }
//     else if (_dnClick === 0) {
//         let pickInfo = store.newScene.pick(newScene.pointerX, store.newScene.pointerY);
//         removeRotationSupportElements();
//         if (pickInfo.pickedMesh) {
//             if (pickInfo.pickedMesh.type) {
//                 if (['wall', 'mass', 'furniture'].includes(pickInfo.pickedMesh.type.toLowerCase())) {
//                     drawSelectionBoxRotate(pickInfo.pickedMesh);
//                 }
//             }
//         }
//         else {
//             if (newScene.getMeshByName("boxScale")) {
//                 store.newScene.getMeshByName("boxScale").dispose();
//             }
//         }
//     }
// };

let drawRotationSupportElements = function (pickInfo) {
  let bb_info = pickInfo.pickedMesh.getBoundingInfo();
  let normal = pickInfo.getNormal(true, false);

  if (!normal) return;
  let centroid = bb_info.boundingBox.centerWorld;

  let myPoints1 = [];
  let myPoints2 = [];

  let deltaTheta = 0.1;
  let radius1 = bb_info.diagonalLength / 8;
  let radius2 = bb_info.diagonalLength / 12;
  let theta = 0;
  let Y = 0;
  let colors1 = [];

  for (let i = 0; theta <= 6.3; i++) {
    myPoints1.push(
      new BABYLON.Vector3(
        radius1 * Math.cos(theta),
        Y,
        radius1 * Math.sin(theta)
      )
    );
    if (theta <= 3.2)
      myPoints2.push(
        new BABYLON.Vector3(
          radius2 * Math.cos(theta),
          Y,
          radius2 * Math.sin(theta)
        )
      );
    colors1.push(new BABYLON.Color4(1, 0, 0, 1));
    theta += deltaTheta;
  }

  let lines1 = BABYLON.MeshBuilder.CreateLines(
    "rotLine1",
    { points: myPoints1, colors: colors1 },
    store.scene
  );
  let lines2 = BABYLON.MeshBuilder.CreateDashedLines(
    "rotLine2",
    { points: myPoints2, dashNb: 40 },
    store.scene
  );
  lines2.color = BABYLON.Color3.Blue();
  lines1.isPickable = false;
  lines2.isPickable = false;
  lines1.position = centroid.clone();
  lines2.position = centroid.clone();

  if (
    Math.abs(normal.x) >= 0.01 &&
    Math.abs(normal.x) > Math.abs(normal.y) &&
    Math.abs(normal.x) > Math.abs(normal.z)
  ) {
    if (normal.x > 0) {
      lines1.position.x = bb_info.boundingBox.maximumWorld.x;
      lines2.position.x = bb_info.boundingBox.maximumWorld.x;
    } else {
      lines1.position.x = bb_info.boundingBox.minimumWorld.x;
      lines2.position.x = bb_info.boundingBox.minimumWorld.x;
    }
    lines1.rotation.z = (Math.PI / 2) * Math.sign(normal.x);
    lines2.rotation.z = (Math.PI / 2) * Math.sign(normal.x);
  } else if (
    Math.abs(normal.y) >= 0.01 &&
    Math.abs(normal.y) > Math.abs(normal.x) &&
    Math.abs(normal.y) > Math.abs(normal.z)
  ) {
    if (normal.y > 0) {
      lines1.position.y = bb_info.boundingBox.maximumWorld.y;
      lines2.position.y = bb_info.boundingBox.maximumWorld.y;
    } else {
      lines1.position.y = bb_info.boundingBox.minimumWorld.y;
      lines2.position.y = bb_info.boundingBox.minimumWorld.y;
    }
  } else if (
    Math.abs(normal.z) >= 0.01 &&
    Math.abs(normal.z) > Math.abs(normal.x) &&
    Math.abs(normal.z) > Math.abs(normal.y)
  ) {
    if (normal.z > 0) {
      lines1.position.z = bb_info.boundingBox.maximumWorld.z;
      lines2.position.z = bb_info.boundingBox.maximumWorld.z;
    } else {
      lines1.position.z = bb_info.boundingBox.minimumWorld.z;
      lines2.position.z = bb_info.boundingBox.minimumWorld.z;
    }
    lines1.rotation.x = (Math.PI / 2) * Math.sign(normal.z);
    lines2.rotation.x = (Math.PI / 2) * Math.sign(normal.z);
  }
};

let removeRotationSupportElements = function () {
  let rotLines = store.newScene.getMeshByName("lines");
  if (rotLines) rotLines.dispose();

  let rotationSupportWall = store.newScene.getMeshByName("rotationSupportWall");
  if (rotationSupportWall) rotationSupportWall.dispose();
};

let calculateAngleForRotation = function (
  startingPoint,
  handlePoint1,
  handlePoint2
) {
  let _direction;
  let _AB = handlePoint1.subtract(startingPoint);
  let _BC = startingPoint.subtract(handlePoint2);
  let angle = null;
  if (_direction === "x") {
    angle = Math.atan2(
      _AB.y * _BC.y + _AB.z * _BC.z,
      _AB.z * _BC.y + _AB.y * _BC.z
    );
  } else if (_direction === "y") {
    angle =
      Math.PI -
      Math.atan2(_AB.x * _BC.x + _AB.z * _BC.z, _AB.z * _BC.x + _AB.x * _BC.z);
  } else if (_direction === "z") {
    angle =
      Math.PI -
      Math.atan2(_AB.x * _BC.x + _AB.y * _BC.y, _AB.y * _BC.x + _AB.x * _BC.y);
  }
  return angle;
};

let _rotateMesh = function (selectionBox, angle, startingPoint) {
  // angle = snapAngle(angle);
  let _direction = null;
  console.log(angle);
  if (_direction === "x") {
    selectionBox.rotation.x = angle;
    selectionBox.parentMesh.rotation.x = angle;
  } else if (_direction === "y") {
    selectionBox.rotation.y = angle;
    selectionBox.parentMesh.rotation.y = angle;
  } else if (_direction === "z") {
    selectionBox.rotation.z = angle;
    selectionBox.parentMesh.rotation.z = angle;
  }
};
export {
  rotateMeshDir,
  rotateMeshStartingPosition,
  rotateMesh,
  rotationScale,
  onRotateMeshDown1,
  onRotateMeshUp1,
  onRotateMeshMove1,
  drawRotateMeshAxis,
  rerotateRotateMeshAxis,
  rotateMeshAxisrotateMeshventFunc,
  getScenePositionForRotateMesh,
  rotateRotateMesh,
  computeDiffAngle,
  snapAngle,
  drawRotationAxis,
  drawRotationSupportElements,
  removeRotationSupportElements,
  calculateAngleForRotation,
  _rotateMesh,
};
