import React, { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';
import authService from '../../services/auth.service';
import { acceptTeamInvitation, deleteTeam, leaveTeam, rejectTeamInvitation } from '../../services/team.service';
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc';
import { leaveTeamUI } from '../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice';
import { colors } from '../../themes/constant';
import NamedAvatar from '../Avatar/NamedAvatar';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { modalStylesTeam } from './style';

const Wrapper = styled.div`
    // border: 2px solid;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
`
const ModalWrapper = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    // min-width: 20%;
    // min-height: 20%;
    .heading{
        font-weight: 700;
        font-size: 24px;
        color: ${colors.primeBlack};
    }
    .subheading{
        font-weight: 400;
        font-size: 14px;
        color: ${colors.greyishBlack};
    }
    .actions{
        display: flex;
        flex-direction: row;
        z-index: 5;
    }
    .button-wrapper{
        // border: 2px solid crimson;
        width: 100%;
        // padding: 0.5em 3em;
        z-index: 5;
    }
    .page-indicator{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .indicator{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 0px 1px;
        background: #F2F2F2;
    }
    .active{
        background: #818181;
    }
`

const BodyWrapper = styled.div`
    position: relative;
    width: 35em;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: ${colors.primeBlack};
    padding: 1em;
    & > div{
        // border: 2px solid;
        margin: 0em 0.5em;
        padding: 0.25em;
        text-align: center;
    }
    .disabled{
        opacity: 0.5;
        pointer-events: none;
    }

`
function AcceptTeamInviteModal({teamInvites, handleClose, setTeamInvites, ...props}) {
    const ref = useRef(null);
    useEffect(() => {
        if(teamInvites.filter(invite => invite.status === "pending").length === 0) handleClose();
    }, [teamInvites]);
    const updateTeamInviteStatus = (status, index) => {
        const newTeamInvites = teamInvites.map((invite, idx) => {
            if(index === idx){
                invite.status = status;
            }
            return {
                ...invite
            }
        })
        setTeamInvites(newTeamInvites);
    }
    const handleAccept = async (invite, index) => {
        const ele = document.getElementById(`${invite.team.id}-${invite.team.name}`);
        ele.classList.add('disabled');
        const resp = await acceptTeamInvitation(invite.invitation.token, invite.team.id);
        if(resp.status === "success"){
            if(resp.data.status.includes("approved")){
                updateTeamInviteStatus("accepted", index)
                showToast("Team invitation accepted");
            }else{
                showToast(resp.data.status, 3000, TOAST_TYPES.error);
            }
        }else{
            ele.classList.remove('disabled');
            showToast(resp.message, 3000, TOAST_TYPES.error);
        }
    }
    
    const handleReject = async (invite, index) => {
        const ele = document.getElementById(`${invite.team.id}-${invite.team.name}`);
        ele.classList.add('disabled');
        const resp = await rejectTeamInvitation(invite.invitation.token, invite.team.id);
        if(resp.status === "success"){
            if(resp.data.status.includes("rejected")){
                updateTeamInviteStatus("rejected", index)
                showToast("Team invitation rejected");
            }else{
                showToast(resp.data.status, 3000, TOAST_TYPES.error);
            }
        }else{
            ele.classList.remove('disabled');
            showToast("Could not reject invitation", 3000, TOAST_TYPES.error);
        }
    }
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={handleClose}
            style={modalStylesTeam}
        >
        <Wrapper>
            <ModalWrapper ref={ref}>
            <BodyWrapper>
                <div className='heading'>Team Invitations</div>
                <div className='invitation-list' style={{marginTop: "1em"}}>
                {
                        teamInvites.filter(invite => invite.status === "pending").map((invite, index) => {
                            return <div id={`${invite.team.id}-${invite.team.name}`} key={`${invite.team.id}-${invite.team.name}`} style={{display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                                <NamedAvatar id={invite.team.name} text={invite.team.name} />
                                <div style={{flex: 1}}>
                                    <span>{invite.team.name}</span>{" "}
                                    <span data-tip data-for={`${invite.team.id}-admin-name`}>({invite.team.admin.name})</span>
                                    <Tooltip id={`${invite.team.id}-admin-name`}>{invite.team.admin.email}</Tooltip>
                                </div>
                                <div style={{padding: "5px"}}>
                                    <Button outline style={{width: "8em"}} title={"Reject"} onPress={() => {handleReject(invite, index)}} />
                                </div>
                                <div style={{padding: "5px"}}>
                                    <Button primary style={{width: "8em"}} title={"Accept"} onPress={() => {handleAccept(invite, index)}} />
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="actions" style={{marginTop: "1em"}}>
                    <div className="button-wrapper">
                        <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} title={"Cancel"} onPress={handleClose} />
                    </div>
                    {/* <div className="button-wrapper">                    
                        <Button isLoading={isLoading} primary={true} title={"Accept All"} onPress={handleAcceptAll} />
                    </div> */}
                </div>
            </BodyWrapper>
            </ModalWrapper>
        </Wrapper>
        </ReactModal>
     );
}

export default AcceptTeamInviteModal;