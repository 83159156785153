import _ from "lodash";
import {computeAreaForMass, computeVolumeMass} from "../../libs/areaFuncs";
import {pasteObject} from "../../libs/defaultEvents";
import {drawingOperator} from "../../libs/drawingEvents";
import {SnapElement} from "../../libs/GUI/snap_element";
import {SnapProperty} from "../../libs/GUI/snap_properties";
import {removeSelectionBox} from "../../libs/meshEvents";
import {updateSelected} from "../../stateManagers/reducers/objectProperties/objectPropertiesSlice";
import reduxStore from "../../stateManagers/store/reduxStore";
import {CommandManager} from "../commandManager/CommandManager";
import {commandUtils} from "../commandManager/CommandUtils";
import {DisplayOperation} from "../displayOperations/displayOperation";
import {
  changeHeightOfObjects,
  changeLabelOfRoom,
  changeTypeInStructure,
  getLabelChangeCommand,
  isMassDependant, mmToSnaptrudeUnits,
  onSolid,
  showToast
} from "../extrafunc";
import {virtualSketcher} from "../sketchMassBIMIntegration/virtualSketcher";
import {Mass} from "../snaptrudeDS/mass.ds";
import {StoreyMutation} from "../storeyEngine/storeyMutations";
import {store} from "../utilityFunctions/Store";
import BABYLON from "../babylonDS.module";
import {plainFloorParameters} from "../factoryTypes/floor.types";
import {isFloatEqual} from "../../libs/snapFuncs";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService";
import {setLayerTransperancy} from "../../libs/sceneFuncs";

var objectPropertiesView = (function () {
  // let $scope = null;
  // angular.element(function () {
  //     $scope= angular.element(appElement).scope();
  //     $scope = $scope.$$childHead;
  // });
  let _mesh = null;
  const _onNameChange = function (mesh, newValue) {
    console.log(mesh, newValue);
  };

  const _onTypeChange = function (mesh, newValue) {
    if (newValue) {
      let meshDs = mesh.getSnaptrudeDS();
      
      const allTypesInDropdown = [
        ...Mass.ALL_TYPES,
        "Wall",
        "Slab",
        "Flooring",
      ];
      
      if (allTypesInDropdown.includes(newValue)) {
        // massType changes
        _updateMeshType(meshDs, newValue);

        store.$scope.newObjectProps.forEach((property) => {
          if (property.name === "Label") {
            property.setHidden(newValue.toLowerCase() !== "room")
          } else if (property.name === "Type") {
            property.element.options = getTypeOptionsForObject(meshDs);
          }
        });
      } else {
        // room type changes
        meshDs.room_type = newValue;
      }
    }
  };

  const getTypeOptionsForObject = function (meshDS) {
    let options = [];

    const wallType = "Wall";
    const slabType = "Slab";
    const massType = "Mass";
    const floorType = "Flooring";

    if (meshDS.isTypeChangeAllowed()) {
      /*if (meshDS.type.toLowerCase() === massType.toLowerCase()) {
        if (isMassDependant(meshDS)) {
          options = Mass.TYPES.DEPENDENT;
        } else {
          options = [...Mass.TYPES.INDEPENDENT_ALL_EDITS];

          if (!meshDS.isEdited()) {
            options.push(
              ...Mass.TYPES.INDEPENDENT_LIMITED_EDITS,
              slabType,
              wallType,
              floorType
            );
          }
        }
      } else if (meshDS.type.toLowerCase() === wallType.toLowerCase()) {
        options = [...Mass.TYPES.INDEPENDENT_ALL_EDITS, wallType];

        if (!meshDS.isEdited()) {
          options.push(...Mass.TYPES.INDEPENDENT_LIMITED_EDITS);
        }
      }*/
      
      options = [
        ...Mass.ALL_TYPES,
        wallType,
        slabType,
        floorType
      ];
    }

    options = options.slice().sort(); // alphabetical listing

    return options;
  };

  const _updateMeshType = function (meshDS, meshTypeAfter) {
    /*function _handlePlinth(meshes) {
      let plinthsToRemove = [];
      meshes.forEach((mesh) => {
        if (mesh.type.toLowerCase() === "mass") {
          mesh.childrenComp.forEach((child) => {
            if (Mass.isPlinth(child)) plinthsToRemove.push(child);
          });
        }
      });

      let removeParentCommand, deletionCommand;
      if (!_.isEmpty(plinthsToRemove)) {
        // removeParentCommand = commandUtils.parentChildOperations.expressCheckout("plinthParentRelationRemoval", plinthsToRemove);

        deletionCommand =
          commandUtils.deletionOperations.getCommandData(plinthsToRemove);
        deletionCommand = commandUtils.deletionOperations.getCommand(
          "plinthDeletion",
          deletionCommand
        );

        // return {"commands": [removeParentCommand, deletionCommand], "yets": [true, true]};
        return { commands: [deletionCommand], yets: [true] };
      }
      return { commands: [], yets: [] };
    }*/

    function _addToGraph() {
      this.data.forEach((dataPoint) => {
        const meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (meshOfInterest) {
          const component = meshOfInterest.getSnaptrudeDS();
          virtualSketcher.addWithoutGeometryEdit(component);
        }
      });
    }

    function _removeFromGraph() {
      this.data.forEach((dataPoint) => {
        const meshOfInterest = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (meshOfInterest) {
          const component = meshOfInterest.getSnaptrudeDS();
          virtualSketcher.removeWithoutGeometryEdit(component);
        }
      });
    }

    function _executeMassTypeChangeCommand(meshes, meshTypeAfter, options = {}) {
      function _addProperties(meshes) {
        meshes.forEach((mesh) => {
          let mass = mesh.getSnaptrudeDS();
          if (conversionToRoom) {
            drawingOperator
              .getMetadata()
              .assignPropertiesForMassToGenerateWalls(mesh);
          }
          mass.massType = meshTypeAfter;
          mass.disallowTypeChange();
          if (store.scene._activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA) onSolid(mesh);
        });
      }

      let optionsForPropertyChangeCommand = {
        componentKeys: ["massType", "typeChangeAllowed"],
      };
      
      if (options.componentKeys){
        optionsForPropertyChangeCommand.componentKeys.push(...options.componentKeys);
      }
      
      let conversionToRoom = meshTypeAfter.toLowerCase() === "room";
      if (conversionToRoom) {
        optionsForPropertyChangeCommand.meshKeys = ["room_type", "room_unique_id"];
      }

      let extraCommands = [],
        extraYets = [];
      /*let plinthCommandInfo = _handlePlinth(meshes);
      extraCommands.push(...plinthCommandInfo.commands);
      extraYets.push(...plinthCommandInfo.yets);*/

      let propertyChangeCommandData =
        commandUtils.propertyChangeOperations.getCommandData(meshes, optionsForPropertyChangeCommand);
      optionsForPropertyChangeCommand.data = propertyChangeCommandData;

      const components = meshes.map((m) => m.getSnaptrudeDS());
      const removalCommand = virtualSketcher.removeWithGeometryEdit(components);

      if (options.callback) {
        let cmds = options.callback(meshes);
        extraCommands.push(...cmds);
        extraYets.push(...cmds.map((c) => false));
      }
      
      _addProperties(meshes);

      const additionCommand = virtualSketcher.addWithGeometryEdit(components);

      const geometryChangeFlattenedCommand =
        commandUtils.geometryChangeOperations.flattenCommands([
          additionCommand,
          removalCommand,
        ]);

      propertyChangeCommandData =
        commandUtils.propertyChangeOperations.getCommandData(meshes, optionsForPropertyChangeCommand);

      optionsForPropertyChangeCommand.preExecuteCallback = _removeFromGraph;
      optionsForPropertyChangeCommand.preUnExecuteCallback = _removeFromGraph;
      optionsForPropertyChangeCommand.postExecuteCallback = _addToGraph;
      optionsForPropertyChangeCommand.postUnExecuteCallback = _addToGraph;

      let propertyChangeCommand =
        commandUtils.propertyChangeOperations.getCommand(
          "changeObjectType",
          propertyChangeCommandData,
          optionsForPropertyChangeCommand
        );

      CommandManager.execute(
        _.compact([
          ...extraCommands,
          propertyChangeCommand,
          geometryChangeFlattenedCommand,
        ]),
        [...extraYets, false, false]
      );
    }

    function _executeStructureChangeCommand(meshes, newType, options = {}) {
      /*
          About the creation of commands for this case-

          There are 2 main commands - one for propertyChange and one for componentChange
          After the backend migration to broken mongo, each command reads and writes into the DB separately.

          propertyChange uses the identifier before change. So, let's say for mass to slab, in the backend
          propertyChange looks for the object in masses, updates name and material.id and writes the changes.
          Then, componentChange reads masses and writes to roofs.
          When undo is done, reverse happens. First, componentChange reads roofs and writes to masses.
          Then, propertyChange uses the same identifier and reads and updates masses.

          propertyChange and other commands there, like heightChange know nothing about the mass to slab conversion.

          Thus, propertyChange command needs to execute BEFORE componentChange command.

          Also, in the backend, this operation and its undo-redo is dealt with in a way similar to create and delete.
          So, after successive undo-redo, component.id is maintained.

          I've made changes to changeTypeInStructure to update old id via componentTypeChangeOperations in
          commandUtils.js so that id is preserved in front end as well.

           */

      let components = meshes.map((m) => m.getSnaptrudeDS());
      let componentChangeCommandData =
        commandUtils.componentTypeChangeOperations.getCommandData(components);

      const optionsForPropertyChange = {
        meshKeys: ["name", "room_type", "room_unique_id"], // changeTypeInStructure will handle mesh.type change
        /*meshCompoundKeys: [
          {
            materialId: ["material", "id"],
          },
        ],*/
        componentKeys: ["properties", "heightFromFloor"],
      };

      let extraCommandsBefore = [],
        extraYetsBefore = [];
      let extraCommandsAfter = [],
        extraYetsAfter = [];
      

      if (newType !== "Wall") {
        /*let plinthCommandInfo = _handlePlinth(meshes);
        extraCommandsBefore.push(...plinthCommandInfo.commands);
        extraYetsBefore.push(...plinthCommandInfo.yets);*/
      } else {
        options.retainChildrenComp = true;
      }

      virtualSketcher.removeWithoutGeometryEdit(components);

      if (options.callback) {
        let cmds = [];
        cmds = options.callback(meshes);
        extraCommandsAfter.push(...cmds);
        extraYetsAfter.push(...cmds.map((c) => false));
      }

      let propertyChangeCommandData =
        commandUtils.propertyChangeOperations.getCommandData(
          meshes,
          optionsForPropertyChange
        );

      options.doPropertyChanges = true;
      meshes.forEach((m, i) => {
        const newComponent = changeTypeInStructure(m, newType, options);
        newComponent.disallowTypeChange();
        components[i] = newComponent;
        if (store.scene._activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA) onSolid(m);
      });

      if (newType === "Mass" && options.massSubTypeAfter === "Room") {
        meshes.forEach((mesh) => {
          drawingOperator
            .getMetadata()
            .assignPropertiesForMassToGenerateWalls(mesh);
        });
      }

      components.forEach((c) => {
        virtualSketcher.addWithoutGeometryEdit(c);
      });

      optionsForPropertyChange.data = propertyChangeCommandData;
      propertyChangeCommandData =
        commandUtils.propertyChangeOperations.getCommandData(
          meshes,
          optionsForPropertyChange
        );

      optionsForPropertyChange.preExecuteCallback = _removeFromGraph;
      optionsForPropertyChange.preUnExecuteCallback = _removeFromGraph;
      optionsForPropertyChange.postExecuteCallback = _addToGraph;
      optionsForPropertyChange.postUnExecuteCallback = _addToGraph;
      const propertyChangeCommand =
        commandUtils.propertyChangeOperations.getCommand(
          "changeObjectType Property Change",
          propertyChangeCommandData,
          optionsForPropertyChange
        );

      componentChangeCommandData =
        commandUtils.componentTypeChangeOperations.getCommandData(components, {
          data: componentChangeCommandData,
        });

      const componentChangeCommand =
        commandUtils.componentTypeChangeOperations.getCommand(
          "changeObjectType Structure Change",
          componentChangeCommandData
        );

      // Refer to the comment above before making any changes to this

      // TO CHANGE THE LABEL ON TYPE CHANGE
      if(meshTypeAfter === "Beam" || meshTypeAfter === "Column" || meshTypeAfter === "Slab" || meshTypeAfter === "Wall" || meshTypeAfter === "Flooring" || meshTypeAfter === "Furniture"){
        const newLabel = snaptrudeDSCount.getLabel(meshTypeAfter);
        const labelChangeOperation = getLabelChangeCommand(meshOfInterest, newLabel);
        if(labelChangeOperation){
          extraCommandsAfter.push(labelChangeOperation.command);
          extraYetsAfter.push(labelChangeOperation.yets);
        }
      }

      const allCommands = _.compact([
        ...extraCommandsBefore,
        propertyChangeCommand,
        componentChangeCommand,
        ...extraCommandsAfter,
      ]);
      const yets = [...extraYetsBefore, false, false, ...extraYetsAfter];

      CommandManager.execute(allCommands, yets);
    }
    
    const _attachHeightChangeCallback = function (options){
      const boundingBox = meshOfInterest.getBoundingInfo().boundingBox;
      const currentHeightInBabylonUnits =
        boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;
  
      if (["Ceiling", "Beam", "Flooring", "Slab", "Room"].includes(meshTypeAfter)) {
  
        const heightChangeFunction = function (meshes, assignStorey = false) {
          const heightChangeCommand = changeHeightOfObjects(meshes, {
            newHeightInBabylonUnits,
            currentHeightInBabylonUnits,
            commandName: "roomTypeChange",
            offsetY,
            assignStorey,
          });
    
          return [heightChangeCommand];
        };
  
        let newHeightInBabylonUnits, offsetY;
        if (meshTypeAfter === "Beam") {
          newHeightInBabylonUnits = mmToSnaptrudeUnits(600);
          offsetY = -newHeightInBabylonUnits;
    
          options.callback = () => heightChangeFunction(meshes);
        }
        else if (meshTypeAfter === "Slab"){
          if (meshTypeBefore === "Mass"){
            // this value is massType
            options.callback = null;
            // adding this to make sure that masses drawn in section don't get modified
            // because they're drawn as they're required
          }
          else {
            newHeightInBabylonUnits =
              store.projectProperties.properties.slabThicknessProperty.getValue();
            offsetY = -newHeightInBabylonUnits;
      
            options.callback = () => heightChangeFunction(meshes);
          }
        }
        else if (meshTypeAfter === "Flooring"){
          newHeightInBabylonUnits = plainFloorParameters.floorDepth;
          offsetY = 0;
    
          options.callback = () => heightChangeFunction(meshes);
        }
        else if (meshTypeAfter === "Ceiling") {
    
          const defaultCeilingHeight = store.projectProperties.properties.ceilingHeightProperty.getValue();
    
          newHeightInBabylonUnits = mmToSnaptrudeUnits(25);
          offsetY =
            newHeightInBabylonUnits +
            defaultCeilingHeight +
            mmToSnaptrudeUnits(plainFloorParameters.floorDepth);
          // 2.1 m from the top face of flooring to bottom face of ceiling
          
    
          options.callback = function (meshes) {
            const heightChangeCommand = heightChangeFunction(meshes, true);
      
            // assignStorey should be called in heightChangeFunction
            // because then that command will itself handle storey change on undo
            
            // when room to ceiling is done in 2D, the ceiling goes to activeStorey + 1
            // so its visibility should change
            
            const components = meshes.map(m => m.getSnaptrudeDS());
            components.forEach(c => {
              c.heightFromFloor = defaultCeilingHeight;
              setLayerTransperancy(c.mesh);
            });
      
            return heightChangeCommand;
          };
        }
        else if (meshTypeAfter === "Room"){
          if (meshTypeBefore.toLowerCase() === "wall"){
            newHeightInBabylonUnits = currentHeightInBabylonUnits +
              store.projectProperties.properties.slabThicknessProperty.getValue();
            offsetY = 0;
      
            options.callback = () => heightChangeFunction(meshes);
            
          }
        }
        
      }
    }

    const meshTypeBefore = meshDS.massType || meshDS.type;
    if (meshTypeAfter === meshTypeBefore) return;

    const meshOfInterest = meshDS.mesh;

    const ALL_MASS_TYPES = Mass.ALL_TYPES;
    const wallType = "Wall";
    const roofType = "Roof";
    const massType = "Mass";
    const floorType = "Floor";

    let meshes = [];
    
    if (meshOfInterest) {
       store.selectionStack.forEach((m) => {
        if (m.isAnInstance) {
          meshes.push(m.sourceMesh, ...m.sourceMesh.instances);
          // meshes = [...meshOfInterest.sourceMesh.instances];
        } else {
          meshes.push(m);
        }
      });

      meshes = _.uniq(meshes);

      /*
      if (
        ALL_MASS_TYPES.includes(meshTypeBefore) &&
        ALL_MASS_TYPES.includes(meshTypeAfter)
      ) {
        const options = {};
        const boundingBox = meshOfInterest.getBoundingInfo().boundingBox;
        const currentHeightInBabylonUnits =
          boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;
        
        if (["Ceiling", "Beam"].includes(meshTypeAfter)){
          
          const heightChangeFunction = function (meshes) {
            const heightChangeCommand = changeHeightOfObjects(meshes, {
              newHeightInBabylonUnits,
              currentHeightInBabylonUnits,
              commandName: "roomTypeChange",
              offsetY,
            });
  
            return [heightChangeCommand];
          };
          
          let newHeightInBabylonUnits, offsetY;
          if (meshTypeAfter === "Beam"){
            newHeightInBabylonUnits = mmToSnaptrudeUnits(600);
            offsetY = -newHeightInBabylonUnits;
            
            options.callback = () => heightChangeFunction(meshes);
          }
          else if (meshTypeAfter === "Ceiling"){
            
            const defaultCeilingHeight = store.projectProperties.properties.ceilingHeightProperty.getValue();
            
            newHeightInBabylonUnits = mmToSnaptrudeUnits(25);
            offsetY =
              newHeightInBabylonUnits +
              defaultCeilingHeight +
              mmToSnaptrudeUnits(plainFloorParameters.floorDepth);
            // 2.1 m from the top face of flooring to bottom face of ceiling
            
            options.componentKeys = ["heightFromFloor"];
            
            options.callback = function (meshes) {
              const heightChangeCommand = heightChangeFunction(meshes);
              
              meshes.forEach(m => {
                m.getSnaptrudeDS().heightFromFloor = defaultCeilingHeight;
              })
    
              return heightChangeCommand;
            };
          }
        }
        
        _executeMassTypeChangeCommand(meshes, meshTypeAfter, options);
      } else {
        if (
          meshTypeBefore.toLowerCase() === "wall" &&
          ALL_MASS_TYPES.includes(meshTypeAfter)
        ) {
          // meshes.forEach(mesh => mesh.material = scene.getMaterialByName("solid_mat"));
          _executeStructureChangeCommand(meshes, "Mass", {
            massSubTypeAfter: meshTypeAfter,
          });
        } else if (
          ALL_MASS_TYPES.includes(meshTypeBefore) &&
          meshTypeAfter.toLowerCase() === "wall"
        ) {
          _executeStructureChangeCommand(meshes, "Wall");
        } else if (
          ALL_MASS_TYPES.includes(meshTypeBefore) &&
          (meshTypeAfter.toLowerCase() === "slab" || meshTypeAfter.toLowerCase() === "flooring")
        ) {
          if (meshDS.isEdited()) return;

          let newType, newHeightInBabylonUnits, offsetY;
          if (meshTypeAfter.toLowerCase() === "slab"){
            newHeightInBabylonUnits =
              store.projectProperties.properties.slabThicknessProperty.getValue();
            newType = "Roof";
            offsetY = -newHeightInBabylonUnits;
          }
          else if (meshTypeAfter.toLowerCase() === "flooring"){
            newHeightInBabylonUnits = plainFloorParameters.floorDepth;
            newType = "Floor";
            offsetY = 0;
          }
          
          let boundingBox = meshOfInterest.getBoundingInfo().boundingBox;
          let currentHeightInBabylonUnits =
            boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;

          const callback = function (meshes) {
            const heightChangeCommand = changeHeightOfObjects(meshes, {
              newHeightInBabylonUnits,
              currentHeightInBabylonUnits,
              commandName: "roomTypeChange",
              offsetY,
            });

            return [heightChangeCommand];
          };

          const options = {
            callback,
          };

          _executeStructureChangeCommand(meshes, newType, options);
        }
      }*/
      
      const userSystemValueMap = {
        "Slab" : roofType,
        "Flooring" : floorType,
        "Wall" : wallType,
      }
      
      const componentTypeBefore = meshDS.type;
      let componentTypeAfter = ALL_MASS_TYPES.includes(meshTypeAfter) ? massType : userSystemValueMap[meshTypeAfter];
      
      const isStructureChange = componentTypeBefore !== componentTypeAfter;
  
      const options = {};
      
      _attachHeightChangeCallback(options);
      
      if (isStructureChange){
        if (componentTypeAfter === massType){
          options.massSubTypeAfter = meshTypeAfter;
        }
        
        _executeStructureChangeCommand(meshes, componentTypeAfter, options);
      }
      else {
        _executeMassTypeChangeCommand(meshes, meshTypeAfter, options);
      }
      
    }
  
    store.selectionStack.length = 0;
    removeSelectionBox();
  };

  const _onLabelChange = function (mesh, newValue) {
    if (newValue) {
      //  mesh.room_type = newValue;
     return changeLabelOfRoom(mesh, newValue);
    }
  };
  
  /*
  Used for only ceilings currently
   */
  const heightChange = function (value, oldValue, options = {}){
    let meshes = Object.assign([],  store.selectionStack);
    value = DisplayOperation.getOriginalDimension(value);
    if (!options.oldValueinSnapUnits)
      oldValue = DisplayOperation.getOriginalDimension(oldValue);
    
    const movementInY = value - oldValue;
    if (isFloatEqual(movementInY, 0, 1e-3)) return;
    
    const components = meshes.map(m => m.getSnaptrudeDS());
    
    let optionsForPropertyChangeCommand = {
      componentKeys: ["heightFromFloor"],
    };

    let propertyChangeCommandData =
      commandUtils.propertyChangeOperations.getCommandData(meshes, optionsForPropertyChangeCommand);
    optionsForPropertyChangeCommand.data = propertyChangeCommandData;
    
    const optionsForWMChange = {
      params: [
        commandUtils.worldMatrixChangeOperations.PARAMS.position,
      ]
    };
    
    let commandData = commandUtils.worldMatrixChangeOperations.getCommandData(meshes, optionsForWMChange);
    
    components.forEach(c => {
      c.mesh.position.y += movementInY;
      c.heightFromFloor += movementInY;
    });
    
    StoreyMutation.assignStorey(components);
    
    optionsForWMChange.data = commandData;
    commandData = commandUtils.worldMatrixChangeOperations.getCommandData(meshes, optionsForWMChange);
    
    propertyChangeCommandData =
      commandUtils.propertyChangeOperations.getCommandData(meshes, optionsForPropertyChangeCommand);
    
    let propertyChangeCommand =
      commandUtils.propertyChangeOperations.getCommand(
        "ceilingHeightChangeProp",
        propertyChangeCommandData,
        optionsForPropertyChangeCommand
      );
    
    const heightChangeCommand = commandUtils.worldMatrixChangeOperations.getCommand("ceilingHeightChange", commandData);
    
    CommandManager.execute(
      [heightChangeCommand, propertyChangeCommand],
      [false, false]
    );
    
  };

  const _dimensionChange = function (type, value, oldValue, options) {
    let stack = Object.assign([], store.selectionStack);
    value = DisplayOperation.getOriginalDimension(value);
    if (!(options && options.oldValueinSnapUnits))
      oldValue = DisplayOperation.getOriginalDimension(oldValue);
    if (stack.length > 1) {
      let options = { returnCommand: true, ignoreSelectionStack: true };
      let commands = [],
        yetToExecutes = [];

      for (let i = 0; i <= stack.length - 1; i++) {
        let cmdData = DisplayOperation.updateDimensionScale(value, type, oldValue, stack[i], options);
        commands.push(...cmdData.cmd);
        yetToExecutes.push(...cmdData.yetToExecutes);
      }

      CommandManager.execute(commands, yetToExecutes);
    } else {
      DisplayOperation.updateDimensionScale(value, type, oldValue);
    }
  };

  const _calculateObjectArea = function () {
    return "899";
  };

  const _getMeshDimensions = function (mesh, options = {}) {
    mesh.computeWorldMatrix(true);
    mesh.refreshBoundingInfo();
    let area = Math.abs(computeAreaForMass(mesh));
    let volume = Math.abs(computeVolumeMass(mesh));

    let scaling = mesh.absoluteScaling.clone();
    let depth =
      mesh.getBoundingInfo().boundingBox.extendSize.x * Math.abs(scaling.x) * 2;
    let breadth =
      mesh.getBoundingInfo().boundingBox.extendSize.z * Math.abs(scaling.z) * 2;
    let height =
      mesh.getBoundingInfo().boundingBox.extendSize.y * Math.abs(scaling.y) * 2;
    let radius =
      mesh.getBoundingInfo().boundingBox.extendSize.x * Math.abs(scaling.x);

    let returnObject = {};
    if (options.valueInSnapDim) {
      returnObject["area"] = area;
      returnObject["volume"] = volume;
      returnObject["depth"] = depth;
      returnObject["radius"] = radius;
      returnObject["breadth"] = breadth;
      returnObject["height"] = height;
    } else {
      returnObject["depth"] = DisplayOperation.convertToDefaultDimension(depth);
      returnObject["radius"] = DisplayOperation.convertToDefaultDimension(radius);
      returnObject["breadth"] =
        DisplayOperation.convertToDefaultDimension(breadth);
      returnObject["height"] =
        DisplayOperation.convertToDefaultDimension(height);
      if (store.$scope.units_type.value === "feet-inches") {
        returnObject["area"] = DisplayOperation.convertToDefaultArea(area);
        returnObject["volume"] =
          DisplayOperation.convertToDefaultVolume(volume);
      } else {
        returnObject["area"] = parseFloat(
          DisplayOperation.convertToDefaultArea(area).toFixed(store.$scope.unit_tolerance.value)
        );
        returnObject["volume"] = parseFloat(
          DisplayOperation.convertToDefaultVolume(volume).toFixed(store.$scope.unit_tolerance.value)
        );
      }
    }
    // if ($scope.units_type.value === "feet-inches") {
    //     //console.log("imin tolerance",$scope.units_type.value)
    //     return {
    //         area: (DisplayOperation.convertToDefaultArea(area)),
    //         volume: (DisplayOperation.convertToDefaultVolume(volume)),
    //         depth: (DisplayOperation.convertToDefaultDimension(depth)),
    //         breadth: (DisplayOperation.convertToDefaultDimension(breadth)),
    //         height: (DisplayOperation.convertToDefaultDimension(height))
    //     };
    // } else {

    //     return {
    //         area: parseFloat(DisplayOperation.convertToDefaultArea(area).toFixed(2)),
    //         volume: parseFloat(DisplayOperation.convertToDefaultVolume(volume).toFixed(2)),
    //         depth: parseFloat(DisplayOperation.convertToDefaultDimension(depth)),
    //         breadth: parseFloat(DisplayOperation.convertToDefaultDimension(breadth)),
    //         height: parseFloat(DisplayOperation.convertToDefaultDimension(height))
    //     };
    // }
    return returnObject;
  };

  const _generateDefaultProperties = function (mesh) {
    let defaultProps = [];
    let meshDimensions = _getMeshDimensions(mesh);
    let meshDs = mesh.getSnaptrudeDS();
    // console.log(mesh);
    defaultProps.push(
      new SnapProperty(
        "Category",
        "Mass",
        SnapElement.getInputElement(null, false)
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Type",
        meshDs.massType,
        SnapElement.getDropDown(
          getTypeOptionsForObject(meshDs),
          (meshes, name) => _onTypeChange(mesh, name),
          true
        )
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Label",
        mesh.room_type,
        SnapElement.getInputElement(
          (meshs, name) => _onLabelChange(mesh, name),
          true
        )
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Length",
        meshDimensions.breadth,
        SnapElement.getInputElement(
          (meshs, value, option) =>
            _dimensionChange("width", value, meshDimensions.breadth),
          true
        )
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Width",
        meshDimensions.depth,
        SnapElement.getInputElement(
          (meshs, value) =>
            _dimensionChange("thickness", value, meshDimensions.depth),
          true
        )
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Height",
        meshDimensions.height,
        SnapElement.getInputElement(
          (meshs, value) =>
            _dimensionChange("height", value, meshDimensions.height),
          true
        )
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Storey",
        mesh.storey,
        SnapElement.getInputElement(null, false)
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Area",
        meshDimensions.area,
        SnapElement.getInputElement(null, false)
      )
    );
    defaultProps.push(
      new SnapProperty(
        "Volume",
        meshDimensions.volume,
        SnapElement.getInputElement(null, false)
      )
    );
    // defaultProps.push(new SnapProperty("Check",meshDimensions.volume,SnapElement.getCheckBox((d) => console.log(d),true)));
    defaultProps.push(
      new SnapProperty("object-buttons", this.mesh, null, true)
    );

    return defaultProps;
  };

  const updateObjectProperties = function updatingObjectPropertiesOnSelect(
    mesh
  ) {
    let allValuesVaries = false;

    if ( store.selectionStack.length > 1) {
      //check if selected elements are of same type
      if ( store.selectionStack.every((val, i, arr) => val.type === arr[0].type)) {
        allValuesVaries = true;
      } else {
        store.$scope.newObjectProps =[new SnapProperty("Object", "Varies", SnapElement.getInputElement(null, false))];
        updateObjectPropertiesStore();
        return;
      }
    }
    mesh == null ? (mesh =  store.selectionStack[0]) : (_mesh = mesh);
    if (mesh == null) {
      store.$scope.newObjectProps = [];
      return;
    }
    if(mesh.name.toLowerCase().includes("pdf")){
      store.$scope.newObjectProps = [];
      return;
    }
    if( mesh.name.toLowerCase().includes("cad") || mesh.name.toLowerCase().includes("twoplane") ){
      store.$scope.newObjectProps = [];
      return;
    }
    _mesh = mesh;
    let meshDs = mesh.getSnaptrudeDS();
    if (!meshDs) return;
    let objProps = meshDs.getMeshObjectProperties();
    if (objProps) {
      store.$scope.newObjectProps = objProps;
      if (allValuesVaries) {
        let mesh2Props =  store.selectionStack[0].getSnaptrudeDS().getMeshObjectProperties();
        const updatedObjProps = store.$scope.newObjectProps.map((ele, index) => {
          if (mesh2Props[index]) {
            if (ele.name !== "Category") {
              if (store.$scope.newObjectProps[index].value === mesh2Props[index].value) {
                return ele;
              } else {
                ele.value = "Varies";
              }
              if (ele.name !== "object-buttons") {
                ele.element.editable = false;
              }
            }
          }
          return ele;
        })
        // console.log(updatedObjProps)
        store.$scope.newObjectProps = updatedObjProps;
      }
    } else {
      store.$scope.newObjectProps = _generateDefaultProperties(mesh);
      if (allValuesVaries) {
        const updatedObjProps = store.$scope.newObjectProps.map((ele, index) => {
          let mesh2Props = _generateDefaultProperties( store.selectionStack[0]);
          if (ele.name !== "Category") {
            if (store.$scope.newObjectProps[index].value === mesh2Props[index].value) {
            } else {
              ele.value = "Varies";
            }
            ele.element.editable = false;
          }
          return ele;
        });
        store.$scope.newObjectProps = updatedObjProps;
      }
    }
    // console.log(store.$scope.newObjectProps);
    updateObjectPropertiesStore();
  };

  const updateObjectPropertiesStore = () => {
    if(!store.$scope.updateViaReact){
      store.$scope.newObjectProps = store.$scope.newObjectProps.map(ele => {
        return {
            name: ele.name,
            // value: ele.name !== "object-buttons" ? ele.value : null,
            value: ele.value,
            hidden: ele.hidden,
            element: ele.element,
            type: ele.element ? ele.element.type : null,
            editable: ele.element ? ele.element.editable : null,
            options: ele.element ? ele.element.options : null,
            setHidden: ele.setHidden,
        }
      })
      reduxStore.dispatch(updateSelected(store.$scope.newObjectProps));
    }
  }

  const makeUnique = function (stack, options = {}) {
    function isValidMesh(mesh) {
      return !["furniture", "door", "window"].includes(mesh.type.toLowerCase());
    }

    function populateLinkedListData(replaceeDS, replacerDS) {
      let linkedListData = StoreyMutation.replaceElementInDLL(
        replaceeDS,
        replacerDS
      );

      if (!_.isEmpty(linkedListData)) {
        let dllId = replaceeDS.linkedListId;
        if (linkedListCollectiveData[dllId]) {
          linkedListCollectiveData[dllId].newData =
            linkedListData[dllId].newData;
        } else {
          linkedListCollectiveData[dllId] = linkedListData[dllId];
        }
        linkedListCollectiveData.structure_id = linkedListData.structure_id;
      }
    }

    let meshesToBeDeleted = [];
    let plinthCmds = [];
    let componentsToBeCloned = [];
    // let correspondingUniqueIds = [];
    let linkedListCollectiveData = {};

    let meshes = stack ||  store.selectionStack;

    meshes.forEach(function (mesh) {
      if (mesh.isAnInstance && isValidMesh(mesh)) {
        let objectOfInterest = mesh.getSnaptrudeDS();

        let options = {
          uniqueObject: true,
          sendOriginalToInfinity: false,
          handleChildren: false,
          arrayOperation: false,
        };

        let cloneMesh = pasteObject(mesh, options);
        cloneMesh.state = "off";
        cloneMesh.isVisible = true;

        if (mesh.childrenComp && mesh.childrenComp.length > 0) {
          cloneMesh.childrenComp = mesh.childrenComp;
          cloneMesh.childrenComp.forEach(function (child) {
            if (Mass.isPlinth(child)) {
              meshesToBeDeleted.push(child);
              plinthCmds.push(Mass.drawPlinth(cloneMesh));
            } else {
              child.setParent(cloneMesh);
            }
          });
        }

        const cloneComponent = cloneMesh.getSnaptrudeDS();
        populateLinkedListData(objectOfInterest, cloneComponent);
        // cloneMesh.getSnaptrudeDS().linkedListId = objectOfInterest.linkedListId;
        // delete objectOfInterest.linkedListId;
        meshesToBeDeleted.push(mesh);
        componentsToBeCloned.push(cloneComponent);
        // correspondingUniqueIds.push(mesh.uniqueId);
      }
    });

    if (componentsToBeCloned.length > 0) {
      const meshesToBeCloned = componentsToBeCloned.map((c) => c.mesh);

      let deletionCommandData = commandUtils.deletionOperations.getCommandData(
        meshesToBeDeleted,
        null,
        { preserveChildren: true }
      );
      let creationCommandData = commandUtils.creationOperations.getCommandData(
        meshesToBeCloned,
        null
      );

      virtualSketcher.removeWithoutGeometryEdit(
        meshesToBeDeleted.map((m) => m.getSnaptrudeDS())
      );

      virtualSketcher.addWithoutGeometryEdit(componentsToBeCloned);

      // doing addWithGeometryEdit directly, won't do geometry sanitization of the current mass,
      // thus doing it in two steps
      const integrationCommand =
        virtualSketcher.updateWithGeometryEdit(componentsToBeCloned);

      let deletionCommand = commandUtils.deletionOperations.getCommand(
        "deleteNonUnique",
        deletionCommandData,
        { preserveChildren: true }
      );
      let creationCommand = commandUtils.creationOperations.getCommand(
        "createUnique",
        creationCommandData
      );

      let dllCommand = null;
      if (!_.isEmpty(linkedListCollectiveData)) {
        dllCommand = commandUtils.linkedListOperations.getCommand(
          "updateLinkedListCluster",
          linkedListCollectiveData
        );
      }

      let allCommands = [deletionCommand, creationCommand];
      let yets = [true, false];
      if (dllCommand) {
        allCommands.push(dllCommand);
        yets.push(false);
      }
      if (integrationCommand) {
        allCommands.push(integrationCommand);
        yets.push(false);
      }
      allCommands.push(...plinthCmds);
      yets.push(...plinthCmds.map((c) => false));
      // allCommands.executeLeftToRight = true;
      if (!stack)  store.selectionStack = [];
      if (options.returnCommand) {
        return { cmds: allCommands, yets: yets };
      } else {
        CommandManager.execute(allCommands, yets);
      }

      // meshesToBeCloned.forEach(function (mesh, index) {
      //     meshObjectMapping.updateMesh(mesh.uniqueId, correspondingUniqueIds[index]);
      //     mesh.uniqueId = correspondingUniqueIds[index];
      // })
    } else {
      showToast("Objects already Unique");
    }
  };

  function onWallTypeChange(newType) {
    if (_.isEmpty(store.selectionStack)) return;
    const wall = store.selectionStack[0].getSnaptrudeDS();
    if (!wall) return;
    wall.changeWallMaterialType(newType);
  }

  return {
    updateObjectProperties: function (mesh) {
      return updateObjectProperties(mesh);
    },
    getMeshDimensions: function (mesh, options) {
      return _getMeshDimensions(mesh, options);
    },
    radiusChange: function (value, oldValue, options) {
      let _value = DisplayOperation.getOriginalDimension(value);
      if (_value.toFixed(5) === oldValue.toFixed(5)) return;

      _dimensionChange('radius', value, oldValue, options);
    },
    dimensionChange: function (type, value, oldValue, options) {
      _dimensionChange(type, value, oldValue, options);
    },
    heightChange,
    onLabelChange: _onLabelChange,
    updateMeshType: function (meshDs, value, oldValue) {
      _updateMeshType(meshDs, value, oldValue);
    },
    onTypeChange: function (mesh, value) {
      _onTypeChange(mesh, value);
    },
    onWallTypeChange,
    getTypeOptionsForObject,
    makeUnique,
  };
})();

export default objectPropertiesView;
export {
  objectPropertiesView
};
