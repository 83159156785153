import React, { useEffect } from 'react'
import styled from 'styled-components'

const StyledTag = styled.span`
    background-color: #F2F2F280;
    color: #878787;
    padding: 11px 12px;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    margin: 5px;
    cursor: pointer;
`

const Tag = ({value, isSelected=false, handleSelect}) => {
    return <StyledTag style={isSelected ? {border: "1px solid #2D2D2E", color: "#4F4F4F"} : {}} onClick={() => {handleSelect(value)}}>
        {value}
    </StyledTag>
}

function SelectableTags({tags, selected = null, handleSelect, multiselect=false}) {
    return tags.map(tag => {
        return <Tag value={tag} isSelected={multiselect ? selected.includes(tag) : selected===tag} handleSelect={handleSelect} />
    })
}

export default SelectableTags
