export const ACTIVE_LAYER = {
  "id": "ly_pzbml3vqx",
  "name": "Wall",
  "structure_id": "wdewpi_0",
  "type": "layer",
  "layerType": "wall",
  "sketches": [],
  "floorplans": [],
  "storey": 1,
  "hidden": false,
  "terrain": [],
  "heightMapToggle": false,
  "neighborhood":[]
}