import { getDimensionInSnaptrudeUnits } from ".";
import { copyMaterialData } from "../../libs/mats";
import BABYLON from "../babylonDS.module";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { getCSGFormOfMesh, onSolid } from "../extrafunc";
import { windowOperation } from "../meshoperations/windowOperation";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { Wall } from "../snaptrudeDS/wall.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import { Window } from "../snaptrudeDS/window.ds";
import { drawDoor } from "../../libs/drawDoor2d";
import {
  convertFacesToSnaptrude,
  convertVerticesToSnaptrude,
  getGeometryDataFromReferenceData,
  getMeshDataFromReferenceData,
  createMeshWithMeshData,
  saveDataInBackend,
  createCustomMeshFromSpeckleData,
  createCustomMeshFromRevitExport,
  getRevitName,
} from "./extraFunctions";
import stackedWallHelper from "../stackedWalls/stackedWallHelper";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import { createSelectionBox, _getWallProfileForWallsWithDoor } from "./door";
import { checkStorey } from "./storey";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants";
import { scenePickController } from "../utilityFunctions/scenePickController";

const createWindow = (
  windows,
  allWindowsData,
  windowMaterials = {},
  windowTypes,
  revitWallMap,
  windowsWallMap,
  materialList
) => {
  const structures = StructureCollection.getInstance();
  const str = structures.getStructureById(store.activeLayer.structure_id);
  const level = str.getLevelByName("01");

  let windowsData = [];
  let windowData;
  let newWallData = [];
  let sourceWindowData = [];
  const options = {};

  for (let idx in windows) {
    try {
      const windowComponents = {};
      let windowData = allWindowsData[idx];
      const windowName = getRevitName(windowData);
      windowComponents[idx] = windowName;
      let windowSubComponentsData = windows[idx].subComponents;
      if (Object.keys(windowSubComponentsData).length > 0) {
        for (
          let subIdx = 0;
          subIdx < windowSubComponentsData.length;
          ++subIdx
        ) {
          const windowId = windowSubComponentsData[subIdx];
          const windowData = allWindowsData[windowId];
          if (!windowData) {
            continue; // Door can contain other elements as security devices eg. 30672208 in cybercity project
          }
          const windowName = getRevitName(windowData);
          windowComponents[windowId] = windowName;
        }
      }
      let _window = store.scene.getMeshByName(windowName);
      let isSourceMesh = false;
      let window2dData = windowTypes[idx];
      if (!_window) {
        let windowMaterialsData = [];
        let windowComponentsData = [];
        for (let id in windowComponents) {
          let windowMaterial = windowMaterials[windowComponents[id]];
          if (!windowMaterial && !Object.keys(windowMaterial).length > 1) {
            continue;
          } else {
            options.createUsingSpeckle = false;
            windowMaterialsData.push(windowMaterial);
            windowComponentsData.push(allWindowsData[id]);
          }
        }
        let isSourceMesh = false;
        let window2dData = windowTypes[idx];
        windowData = createWindowsWithMeshData(
          windowComponentsData,
          windowMaterialsData,
          materialList,
          options
        );
        isSourceMesh = true;
        if (windowData) {
          sourceWindowData.push(windowData.mesh);
        }
      }

      let window = allWindowsData[idx];
      _window = store.scene.getMeshByName(windowName);
      if (_window) {
        let windowDS = _window.getSnaptrudeDS();
        let windowName = _window.name + "Ins" + _window.instances.length;
        let _windowInstance = _window.createInstance(windowName);
        _windowInstance.setAbsolutePosition(
          _windowInstance.getAbsolutePosition()
        );
        _windowInstance.type = "window";
        _windowInstance.structure_id = store.activeLayer.structure_id;

        let angle;

        angle = 2 * Math.PI - window.rotation;
        _windowInstance.rotation.y = angle;

        let basePoint;

        if (window.basePoint) {
          basePoint = window.basePoint.map((point) => point * 304.8);
        } else {
          basePoint = [0, 0, 0];
        }

        let center = getDimensionInSnaptrudeUnits([basePoint], "millimeter")[0];

        const bbinfo = _windowInstance.getBoundingInfo().boundingBox;
        const maximum = bbinfo.maximum.asArray();
        const minimum = bbinfo.minimum.asArray();

        let height = maximum[1] - minimum[1];
        center[2] += height / 2;

        _windowInstance.position = new BABYLON.Vector3(
          center[0],
          center[2],
          center[1]
        );
        _windowInstance.computeWorldMatrix();

        let _newWindow = new Window(_windowInstance);
        _newWindow.autoInterior = true;
        _newWindow.snapTo = "none";
        // _newWindow.importType = _window.getSnaptrudeDS().importType;
        let handOrientation, facingOrientation
        if(window?.HandOrientation)
        handOrientation = BABYLON.Vector3.FromArray([window?.HandOrientation[0], window?.HandOrientation[2], window?.HandOrientation[1]])
        if(window?.FacingOrientation)
        facingOrientation = BABYLON.Vector3.FromArray([window?.FacingOrientation[0], window?.FacingOrientation[2], window?.FacingOrientation[1]])
        _newWindow.revitMetaData = {
          elementId: idx,
          family: window.family,
          facingFlipped: window?.isFaceFlipped,
          type: window?.type,
          handOrientation,
          facingOrientation
        };
      

        let _wallId = windowsWallMap[idx];
        let _wall = revitWallMap[_wallId];

        let { wall, oldWallMesh } = createCSGForWindow(_windowInstance, _wall);
        if (wall) {
          if (_wallId) revitWallMap[_wallId] = wall;
          let newWallMesh = wall.mesh;
          newWallMesh.type = "wall";

          wall.assignProperties();
          let oldWallDS = oldWallMesh.getSnaptrudeDS();

          wall.wThickness = oldWallDS.wThickness;
          wall.localLineSegment = oldWallDS.localLineSegment;
          wall.originalWallMesh = oldWallDS.originalWallMesh;

          // Storing brep in originalWallMesh. Needed after deletion of fenestration
          if (oldWallDS.brep) {
            wall.originalWallMesh.brep = store.resurrect.stringify(
              oldWallDS.brep
            );
          }

          wall.neighbours = oldWallDS.neighbours;
          wall.neighboursDetails = oldWallDS.neighboursDetails;
          wall.setTopCoords(oldWallDS.topCoords);
          wall.setBottomCoords(oldWallDS.bottomCoords);
          wall.setMidYHeight(oldWallDS.midY);
          wall.direction = oldWallDS.direction;
          wall.bottomLineSegment = oldWallDS.bottomLineSegment;
          wall.room_id = oldWallDS.room_id;
          wall.profile = oldWallDS.profile;
          wall.topLineSegment = oldWallDS.topLineSegment;
          wall.properties = oldWallDS.properties;
          wall.revitMetaData = oldWallDS.revitMetaData;

          if (!wall.brep) {
            copyMaterialData(oldWallMesh, newWallMesh);
          }

          let structures = StructureCollection.getInstance();
          wall.mesh.structure_id = oldWallMesh.structure_id;
          let str = structures.getStructureById(wall.mesh.structure_id);
          let level = str.getLevelByUniqueId(
            str.getObjectByUniqueId(oldWallMesh.uniqueId).level_id
          );

          level.removeObjectToLevel(oldWallMesh.getSnaptrudeDS());
          level.addWallToLevel(wall, false);

          newWallMesh.childrenComp = oldWallMesh.childrenComp;
          newWallMesh.childrenComp.push(_windowInstance);

          newWallMesh.childrenComp.forEach(function (child) {
            child.setParent(newWallMesh);
            // child.parent = newWallMesh
          });
          newWallData.push(newWallMesh);

          stackedWallHelper.update(oldWallMesh, newWallMesh);
          oldWallMesh.dispose();
          // onSolid(_newWindow.mesh, false, { enableFurnitureEdgesOnce: true });
        }
        level.addWindowToLevel(_newWindow, false);
        const storey = checkStorey(window?.levelName);
        if (storey) {
          storey.addElement(_newWindow);
        } else {
          StoreyMutation.assignStorey(_newWindow);
        }
        let window2D = set2dWindowRevitImport(_newWindow, window2dData, window);
        _newWindow.symbolData = window2D?.window2dSymbolData;
        windowData = _newWindow.mesh;
        windowsData.push(windowData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  onSolid();
  let wallsWithWindowHoles = newWallData.filter((wall) => {
    let mesh = store.scene.getMeshByUniqueID(wall.uniqueId);
    if (mesh) return wall;
  });
  return { sourceWindowData, windowsData, wallsWithWindowHoles };
};

const createWindowsWithMeshData = (data, materials, materialList, options) => {
  let material;
  let windowMesh,
    windowsMesh = [];
  for (let id = 0; id < data.length; ++id) {
    windowMesh = createCustomMeshFromRevitExport(
      data[id],
      materials[id],
      materialList,
      { renderUsingSpeckle: false }
    );
    windowsMesh.push(windowMesh);
  }
  if (windowsMesh.length == 0) return;
  if (windowsMesh.length > 1) {
    windowMesh = BABYLON.Mesh.MergeMeshes(
      windowsMesh,
      true,
      true,
      undefined,
      true,
      false
    );
    material = windowMesh.material;
  } else {
    windowMesh = windowsMesh[0];
  }

  if (windowMesh) {
    let mainWindowMeshData = data[0];
    windowMesh.position = new BABYLON.Vector3(10000, 0, 10000);
    windowMesh.computeWorldMatrix();

    windowMesh.structure_id = store.activeLayer.structure_id;
    let window = new Window(windowMesh);
    window.room_type = "window";
    window.massType = "window";
    windowMesh.type = "window"; //throwAway is overwritten when mesh is added to level
    windowMesh.name = `${mainWindowMeshData.type} ${mainWindowMeshData.family}`;

    if (mainWindowMeshData.width)
      windowMesh.name += `-${parseInt(mainWindowMeshData.width)}`;
    if (mainWindowMeshData.height)
      windowMesh.name += `x${parseInt(mainWindowMeshData.height)}`;

    windowMesh.name += mainWindowMeshData.isFaceFlipped ? "-1" : "-0";
    if (material) {
      const name = `${mainWindowMeshData.type} ${mainWindowMeshData.family}`;
      const isNewMaterial = store.scene.getMultiMaterialByID(name) ? false: true;
      material.id = name;
      material.name = name;
      // if(isNewMaterial){
      //   saveMaterialInBackend(material);
      // }
    }
    window.room_type = "window";
    window.massType = "window";
    // window.importType = "speckleRevitImport";
    window.storey = 1;
    // window.subType = "revitImport";
    window.subType = `${mainWindowMeshData.type} ${mainWindowMeshData.family}`;
    window.mesh.isVisible = false;
    windowMesh.storey = 1;

    window.revitMetaData = {
      family: mainWindowMeshData.family,
      facingFlipped: mainWindowMeshData?.facingFlipped,
      type: mainWindowMeshData?.type,
    };

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure = structureCollection.getStructureById(
      store.activeLayer.structure_id
    );
    const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
    talkingAboutLevel.addObjectToLevel(window, false);

    return window;
  }
};

const createCSGForWindow = (createdMesh, _wall = null) => {
  if (_wall) {
    let oldWallMesh = _wall.mesh;
    let oldWallMeshDS = oldWallMesh.getSnaptrudeDS();
    if( oldWallMeshDS && oldWallMeshDS.revitMetaData ){
      try{
        if( !_wall.revitMetaData.wallProfile ){
          let _oldWallProfile = _getWallProfileForWallsWithDoor( oldWallMeshDS );
          _wall.revitMetaData.originalWallMeshPosition = new BABYLON.Vector3(oldWallMesh.position.x, oldWallMesh.position.y, oldWallMesh.position.z);
          _wall.revitMetaData.wallProfile = _oldWallProfile ? _oldWallProfile : {};
        }
      }catch(e){
        console.log(e)
      }
    }
    let windowSelectionBox = createSelectionBox(createdMesh, oldWallMeshDS);
    let windowCSG = getCSGFormOfMesh(windowSelectionBox);
    let wallCSG = getCSGFormOfMesh(oldWallMesh);
    let newCSG = wallCSG.subtract(windowCSG);
    let newWallMesh = newCSG.toSnaptrudeMesh("wall", null, store.scene);
    newWallMesh.position = oldWallMesh.position;
    newWallMesh.storey = oldWallMesh.storey;
    let wall = new Wall(newWallMesh, oldWallMesh.room_id);
    windowSelectionBox.dispose();

    if (!newWallMesh.subMeshes) {
      wall = null;
      newWallMesh.dispose();
      // TODO - no submeshes on newWalMeshes results in save errors
      // refactor
    }

    return { wall, oldWallMesh };
  }
  let allMeshes = store.scene.meshes.filter((mesh) => mesh.type == "wall");
  for (let i = 0; i < allMeshes.length; i++) {
    let intersect = createdMesh.intersectsMesh(allMeshes[i]);
    if (intersect) {
      //     createdMesh.parent = allMeshes[i];
      let oldWallMesh = allMeshes[i];
      let oldWallDS = oldWallMesh.getSnaptrudeDS();
      let windowSelectionBox = createSelectionBox(createdMesh, oldWallDS);

      let windowCSG = getCSGFormOfMesh(windowSelectionBox);
      let wallCSG = getCSGFormOfMesh(oldWallMesh);
      let newCSG = wallCSG.subtract(windowCSG);
      let newWallMesh = newCSG.toSnaptrudeMesh("wall", null, store.scene);
      newWallMesh.position = oldWallMesh.position;
      newWallMesh.storey = oldWallMesh.storey;
      let wall = new Wall(newWallMesh, allMeshes[i].room_id);
      windowSelectionBox.dispose();

      if (!newWallMesh.subMeshes) {
        wall = null;
        newWallMesh.dispose();
        // TODO - no submeshes on newWalMeshes results in save errors
        // refactor
      }

      return { wall, oldWallMesh };
    }
  }
  return false;
};


const set2dWindowRevitImport = (window, windowData, data) => {
  let window2D;
  let windowMesh = window.mesh;

  window2D = draw2dRevitImport(windowData, data);
  if (window2D) {
    let window2dMesh = window2D.mergedMesh;
    window2dMesh.setParent(windowMesh);
    window2dMesh.position = BABYLON.Vector3.Zero();
    window2dMesh.isVisible = store.$scope.isTwoDimension && window.storey == store.activeLayer.storey;
    window2dMesh.type = GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator;
    scenePickController.add(window2dMesh);
  }
  return window2D;
};

const draw2dRevitImport = (windowData, data) => {
  let mergeMeshes = [],
    window2dSymbolData = [],
    window2D;
  for (let d = 0; d < windowData.length; d++) {
    if (windowData[d].type == "Arc") {
      let start = windowData[d].startPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      let end = windowData[d].endPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );

      let typeofArc =
        windowData[d]?.startAngle > 88 && windowData[d]?.startAngle < 91
          ? "negetive"
          : "positive";

      window2dSymbolData.push({
        startPoint: start,
        endPoint: end,
        typeOfArc: typeofArc,
        type: "Arc",
      });

      window2D = drawDoor.drawCurve(
        "curve1",
        start[0],
        end[0],
        start[1],
        end[1],
        typeofArc
      );
      mergeMeshes.push(window2D);
    } else if (windowData[d].type == "Line") {
      let startPoint = windowData[d].startPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      startPoint = new BABYLON.Vector3(startPoint[0], 0, startPoint[1]);
      let endPoint = windowData[d].endPoint.map((point) =>
        DisplayOperation.getOriginalDimension(point, "millimeter")
      );
      endPoint = new BABYLON.Vector3(endPoint[0], 0, endPoint[1]);
      window2dSymbolData.push({
        startPoint: startPoint.asArray(),
        endPoint: endPoint.asArray(),
        type: "Line",
      });
      window2D = drawDoor.drawThickLine(null, startPoint, null, endPoint);
      mergeMeshes.push(window2D);
    }
  }

  if (mergeMeshes.length == 0) {
    return;
  }

  let mergedMesh = BABYLON.Mesh.MergeMeshes(
    mergeMeshes,
    true,
    true,
    undefined,
    false,
    false
  );
  mergedMesh.renderingGroupId = 2;
  mergedMesh.isPickable = false;
  mergedMesh.rotation.y = 2 * Math.PI - data.rotation;
  window2dSymbolData.push({ rotation: data.rotation });

  return { mergedMesh, window2dSymbolData };
};

export { createWindow };
