export const TABS = [
  {
      "title": "Colors",
      "content": [],
  },
  {
      "title": "Concrete",
      "content": [],
  },
  {
      "title": "Glass",
      "content": [],
  },
  {
      "title": "Metal",
      "content": [],
  },
  {
      "title": "Tiles",
      "content": [],
  },
  {
      "title": "Stone",
      "content": [],
  },
  {
      "title": "Wood",
      "content": [],
  },
  {
      "title": "Groundcover",
      "content": [],
  },
  {
      "title": "Screed",
      "content": [],
  },
  {
      "title": "Water Proofing",
      "content": [],
  },
  {
      "title": "RCC",
      "content": [],
  },
  {
      "title": "Plaster",
      "content": [],
  },
  {
      "title": "Paint",
      "content": [],
  },
  {
      "title": "Brick",
      "content": [],
  }
]