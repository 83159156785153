import BABYLON from "../../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../../modules/utilityFunctions/Store.js";
import {
  removeSmallEdges,
  twoGetDistance,
  changePolygonShape,
  isOrthogonal,
  distanceBetweenPoints2D,
  slopeOfLine,
  movePolygonInDirection,
  removeZeroArrays,
  removeDuplicateVerticesModified,
  removecollinear,
  roundAndRemoveDuplicates,
  equateDecimalPoints,
  isCompletelyInside,
  cleanIntersectArray,
  angleBetweenEdges,
  isBetween,
  distanceBetweenEdges,
  projectionOfPointOnLine2D,
  multiplyPointArrayByFactor,
  lineParallelToLineAndPassingThroughPoint,
  lineWithAngleAndAtDistanceFromPoint,
  areEdgesClose2,
} from "./twoServices.js";
import { ScopeUtils } from "../scopeFunctions.js";
import { calculateAreaOfIrregularPolygons } from "../sceneFuncs.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import { getFaceVerticesFromFace } from "../brepOperations.js";
import { getNormalVector } from "../mathFuncs.js";
import {getAngleBetweenVectors, isFloatEqual} from "../snapFuncs.js";
// import { ResolveEngineUtils } from "../../modules/wallEngine/resolveEngine.js";
const twoSnapDimension = (polygon) => {
  polygon.pop();
  polygon = removeSmallEdges(polygon);
  const roundFactor = ScopeUtils.getUnitFactor();
  for (let i = 0; i < polygon.length - 2; i++) {
    let distance = twoGetDistance(
      [polygon[i][0] * roundFactor, polygon[i][1] * roundFactor],
      [
        polygon[(i + 1) % polygon.length][0] * roundFactor,
        polygon[(i + 1) % polygon.length][1] * roundFactor,
      ]
    );
    let roundedDistanceWithFactor = Math.round(distance / 10) * 10;
    let roundedDistance = roundedDistanceWithFactor / roundFactor;
    polygon = changePolygonShape(
      polygon,
      [polygon[i], polygon[(i + 1) % polygon.length]],
      roundedDistance
    );
  }
  polygon.push(polygon[0]);
  return polygon;
};

const twoSnapShape = (
  polygon,
  structureID,
  levelID,
  threshold,
  waterTightThreshold,
  angleThreshold,
  storey
) => {
  const roundFactor = ScopeUtils.getUnitFactor();
  let allPolygons = getBottomCoordinates(structureID, storey);
  let newPolygon = Object.assign([], polygon);
  newPolygon.pop();
  let origPolygon = polygon;
  const looper = newPolygon.length;
  for (let i = 0; i < looper; i++) {
    const oldEdge = [newPolygon[i], newPolygon[(i + 1) % newPolygon.length]];
    let newEdge = undefined;
    try {
      newEdge = twoSnapEdge(
        oldEdge,
        newPolygon,
        allPolygons,
        threshold,
        angleThreshold
      );
    } catch (err) {
      console.log("Error in edge snapping. ", err);
      continue;
    }
    if (newEdge) {
      newPolygon[(i + 1) % newPolygon.length] = newEdge.snappedEdge[1];
      newPolygon[i] = newEdge.snappedEdge[0];
      //calc area of newpoly
      //of area==0
      //
      if (isOrthogonal(newPolygon)) {
        const distanceBetweenFirstPointsInUnits =
          twoGetDistance(newPolygon[i], newEdge.snappedWithEdge[0]) *
          roundFactor;
        const roundedDistanceWithFactor =
          Math.round(distanceBetweenFirstPointsInUnits / 10) * 10;
        const roundedDistance = roundedDistanceWithFactor / roundFactor;
        const displacement =
          distanceBetweenPoints2D(
            newPolygon[i],
            newPolygon[(i + 1) % newPolygon.length]
          ) - roundedDistance;
        const slope = slopeOfLine(
          newPolygon[i],
          newPolygon[(i + 1) % newPolygon.length]
        );
        if (slope == 0) {
          newPolygon = movePolygonInDirection(newPolygon, displacement, 0);
        } else if (slope == "inf") {
          newPolygon = movePolygonInDirection(newPolygon, 0, displacement);
        }
      }
    }
  }
  //console.log(newPolygon);
  try {
    newPolygon = makeWaterTight(
      newPolygon,
      allPolygons,
      waterTightThreshold,
      angleThreshold
    );
    //newPolygon = vertexSnapping(newPolygon, allPolygons);
    newPolygon = makeWaterTight(
      newPolygon,
      allPolygons,
      waterTightThreshold,
      angleThreshold
    );
  } catch (err) {
    console.log("Error in water tight. ", err);
  }
  let areaPolygon = calculateAreaOfIrregularPolygons(newPolygon);
  if (Math.round(areaPolygon) == 0) {
    origPolygon.pop();
    newPolygon = origPolygon;
  }
  if (allPolygons.length >= 1) {
    let overlapIndices = isOverlap(newPolygon, allPolygons);
    let diff = [];
    let diffArr = [];
    let dArr = [];
    if (overlapIndices) {
      //overlapIndices = removeDuplicateVerticesModified(overlapIndices);
      allPolygons = removeZeroArrays(allPolygons);
      for (let i = 0; i < allPolygons.length; i++) {
        newPolygon = removeDuplicateVerticesModified(newPolygon);
        allPolygons[i] = removeDuplicateVerticesModified(allPolygons[i]);

        newPolygon = removecollinear(newPolygon);
        allPolygons[i] = removecollinear(allPolygons[i]);

        newPolygon = roundAndRemoveDuplicates(newPolygon);
        allPolygons[i] = roundAndRemoveDuplicates(allPolygons[i]);

        newPolygon = equateDecimalPoints(newPolygon);
        allPolygons[i] = equateDecimalPoints(allPolygons[i]);
        //store.bool.epsilon(1e-5);
        let isTotallyInside = isCompletelyInside(newPolygon, allPolygons[i]);
        if (isTotallyInside === true) continue;
        
        allPolygons = removeZeroArrays(allPolygons);
        diff = store.bool.difference(
          {
            regions: [newPolygon],
            inverted: false,
          },
          {
            regions: [allPolygons[i]],
            inverted: false,
          }
        );
        if (undefined !== diff.regions[0] && diff.regions[0].length !== 0) {
          diffArr.push(diff);
        }
        //diffArr.push(diff);
      }
      for (let i = 0; i < diffArr.length; i++) {
        let newPoly = newPolygon;
        newPoly = removeDuplicateVerticesModified(newPoly);
        newPoly.reverse();
        let d = _.differenceWith(diffArr[i].regions[0], newPoly, (v1, v2) => {
          return v1[0] === v2[0] && v1[1] === v2[1];
        });
        if (d.length > 0) {
          dArr.push(diffArr[i].regions[0]);
        }
      }
      if (dArr.length === 1) {
        newPolygon = dArr[0];
        //newPolygon = removeInaccuracies(newPolygon);
      }

      if (dArr.length > 1) {
        let intersect = store.bool.intersect(
          {
            regions: [dArr[0]],
            inverted: false,
          },
          {
            regions: [dArr[1]],
            inverted: false,
          }
        );
        intersect.regions[0] = roundAndRemoveDuplicates(intersect.regions[0]);
        let intersectArr = [];
        for (let i = 2; i < dArr.length; i++) {
          dArr[i] = removeDuplicateVerticesModified(dArr[i]);
          intersect.regions[0] = removeDuplicateVerticesModified(
            intersect.regions[0]
          );
          dArr[i] = roundAndRemoveDuplicates(dArr[i]);
          intersect.regions[0] = roundAndRemoveDuplicates(intersect.regions[0]);
          //intersect.regions[0] = removeInaccuracies(intersect.regions[0])
          //store.bool.epsilon(1e-5);

          dArr[i] = equateDecimalPoints(dArr[i]);
          intersect.regions[0] = equateDecimalPoints(intersect.regions[0]);
          intersect = store.bool.intersect(
            {
              regions: [intersect.regions[0]],
              inverted: false,
            },
            {
              regions: [dArr[i]],
              inverted: false,
            }
          );
          //console.log("intersect", intersect);
          intersectArr.push(intersect);
        }
        if (undefined !== intersect.regions[0]) {
          if (intersect.regions[0].length > 2) {
            newPolygon = intersect.regions[0];
          } else {
            if (intersectArr.length >= 1) {
              intersectArr = cleanIntersectArray(intersectArr);
              newPolygon = intersectArr[intersectArr.length - 1].regions[0];
            }
          }
        }
      }
      //newPolygon = removeInaccuracies(newPolygon);
    }
  }
  newPolygon.push(newPolygon[0]);
  return newPolygon;
};

const twoSnapShapeforSketch = (
  polygon,
  structureID,
  levelID,
  threshold,
  waterTightThreshold,
  angleThreshold
) => {
  const roundFactor = ScopeUtils.getUnitFactor();
  const allPolygons = getBottomCoordinatesforSketch(structureID, levelID);
  let newPolygon = Object.assign([], polygon);
  newPolygon.pop();
  let origPolygon = polygon;
  const looper = newPolygon.length;
  for (let i = 0; i < looper; i++) {
    const oldEdge = [newPolygon[i], newPolygon[(i + 1) % newPolygon.length]];
    let newEdge = undefined;
    try {
      newEdge = twoSnapEdgeforSketch(
        oldEdge,
        newPolygon,
        allPolygons,
        threshold,
        angleThreshold
      );
    } catch (err) {
      console.log("Error in edge snapping. ", err);
      continue;
    }
    if (newEdge) {
      newPolygon[(i + 1) % newPolygon.length] = newEdge.snappedEdge[1];
      newPolygon[i] = newEdge.snappedEdge[0];
      if (isOrthogonal(newPolygon)) {
        const distanceBetweenFirstPointsInUnits =
          twoGetDistance(newPolygon[i], newEdge.snappedWithEdge[0]) *
          roundFactor;
        const roundedDistanceWithFactor =
          Math.round(distanceBetweenFirstPointsInUnits / 10) * 10;
        const roundedDistance = roundedDistanceWithFactor / roundFactor;
        const displacement =
          distanceBetweenPoints2D(
            newPolygon[i],
            newPolygon[(i + 1) % newPolygon.length]
          ) - roundedDistance;
        const slope = slopeOfLine(
          newPolygon[i],
          newPolygon[(i + 1) % newPolygon.length]
        );

        if (slope == 0) {
          newPolygon = movePolygonInDirection(newPolygon, displacement, 0);
        } else if (slope == "inf") {
          newPolygon = movePolygonInDirection(newPolygon, 0, displacement);
        }
      }
    }
  }
  try {
    newPolygon = makeWaterTight(
      newPolygon,
      allPolygons,
      waterTightThreshold,
      angleThreshold
    );
    //newPolygon = vertexSnapping(newPolygon, allPolygons);
    newPolygon = makeWaterTight(
      newPolygon,
      allPolygons,
      waterTightThreshold,
      angleThreshold
    );
  } catch (err) {
    console.log("Error in water tight. ", err);
  }
  let areaPolygon = calculateAreaOfIrregularPolygons(newPolygon);
  if (Math.round(areaPolygon) == 0) {
    origPolygon.pop();
    newPolygon = origPolygon;
  }
  if (allPolygons.length >= 1) {
    let overlapIndices = isOverlap(newPolygon, allPolygons);
    let diff = [];
    let diffArr = [];
    let dArr = [];

    if (overlapIndices) {
      //overlapIndices = removeDuplicateVerticesModified(overlapIndices);
      for (let i = 0; i < allPolygons.length; i++) {
        newPolygon = removeDuplicateVerticesModified(newPolygon);
        allPolygons[i] = removeDuplicateVerticesModified(allPolygons[i]);

        newPolygon = roundAndRemoveDuplicates(newPolygon);
        allPolygons[i] = roundAndRemoveDuplicates(allPolygons[i]);

        newPolygon = equateDecimalPoints(newPolygon);
        allPolygons[i] = equateDecimalPoints(allPolygons[i]);
        //store.bool.epsilon(1e-5);

        let isTotallyInside = isCompletelyInside(newPolygon, allPolygons[i]);
        if (isTotallyInside === true) continue;

        diff = store.bool.difference(
          {
            regions: [newPolygon],
            inverted: false,
          },
          {
            regions: [allPolygons[i]],
            inverted: false,
          }
        );
        if (undefined !== diff.regions[0] && diff.regions[0].length !== 0) {
          diffArr.push(diff);
        }
        diffArr.push(diff);
      }
      for (let i = 0; i < diffArr.length; i++) {
        let newPoly = newPolygon;
        newPoly = removeDuplicateVerticesModified(newPoly);
        newPoly.reverse();
        let d = _.differenceWith(diffArr[i].regions[0], newPoly, (v1, v2) => {
          return v1[0] === v2[0] && v1[1] === v2[1];
        });
        if (d.length > 0) {
          dArr.push(diffArr[i].regions[0]);
        }
      }
      if (dArr.length === 1) {
        newPolygon = dArr[0];
        //newPolygon = removeInaccuracies(newPolygon);
      }

      if (dArr.length > 1) {
        let intersect = store.bool.intersect(
          {
            regions: [dArr[0]],
            inverted: false,
          },
          {
            regions: [dArr[1]],
            inverted: false,
          }
        );
        intersect.regions[0] = roundAndRemoveDuplicates(intersect.regions[0]);
        let intersectArr = [];
        for (let i = 2; i < dArr.length; i++) {
          dArr[i] = removeDuplicateVerticesModified(dArr[i]);
          intersect.regions[0] = removeDuplicateVerticesModified(
            intersect.regions[0]
          );
          dArr[i] = roundAndRemoveDuplicates(dArr[i]);
          intersect.regions[0] = roundAndRemoveDuplicates(intersect.regions[0]);
          //intersect.regions[0] = removeInaccuracies(intersect.regions[0])
          //store.bool.epsilon(1e-5);

          dArr[i] = equateDecimalPoints(dArr[i]);
          intersect.regions[0] = equateDecimalPoints(intersect.regions[0]);
          intersect = store.bool.intersect(
            {
              regions: [intersect.regions[0]],
              inverted: false,
            },
            {
              regions: [dArr[i]],
              inverted: false,
            }
          );
          //console.log("intersect", intersect);
          intersectArr.push(intersect);
        }
        if (
          undefined !== intersect.regions[0] ||
          intersect.regions[0].length > 2
        ) {
          newPolygon = intersect.regions[0];
        } else {
          intersectArr = cleanIntersectArray(intersectArr);
          newPolygon = intersectArr[intersectArr.length - 1].regions[0];
        }
      }
      //newPolygon = removeInaccuracies(newPolygon);
    }
  }
  newPolygon.push(newPolygon[0]);
  return newPolygon;
};

const snappingOfHoles = (
  holesArray,
  structureID,
  levelID,
  threshold,
  waterTightThreshold,
  angleThreshold
) => {
  const roundFactor = ScopeUtils.getUnitFactor();
  const allHoles = [];
  for (let i = 0; i < holesArray.length; i++) {
    let eachHole = holesArray[i];
    eachHole.pop();
    allHoles.push(eachHole);
  }
  let newHolesArray = [];
  for (let i = 0; i < holesArray.length; i++) {
    let polygon = holesArray[i];
    let newPolygon = Object.assign([], polygon);
    //newPolygon.pop();
    const looper = newPolygon.length;
    for (let i = 0; i < looper; i++) {
      const oldEdge = [newPolygon[i], newPolygon[(i + 1) % newPolygon.length]];
      let newEdge = undefined;
      try {
        newEdge = twoSnapEdgeforSketch(
          oldEdge,
          newPolygon,
          allHoles,
          threshold,
          angleThreshold
        );
      } catch (err) {
        console.log("Error in edge snapping. ", err);
        continue;
      }
      if (newEdge) {
        newPolygon[(i + 1) % newPolygon.length] = newEdge.snappedEdge[1];
        newPolygon[i] = newEdge.snappedEdge[0];
        if (isOrthogonal(newPolygon)) {
          const distanceBetweenFirstPointsInUnits =
            twoGetDistance(newPolygon[i], newEdge.snappedWithEdge[0]) *
            roundFactor;
          const roundedDistanceWithFactor =
            Math.round(distanceBetweenFirstPointsInUnits / 10) * 10;
          const roundedDistance = roundedDistanceWithFactor / roundFactor;
          const displacement =
            distanceBetweenPoints2D(
              newPolygon[i],
              newPolygon[(i + 1) % newPolygon.length]
            ) - roundedDistance;
          const slope = slopeOfLine(
            newPolygon[i],
            newPolygon[(i + 1) % newPolygon.length]
          );

          if (slope == 0) {
            newPolygon = movePolygonInDirection(newPolygon, displacement, 0);
          } else if (slope == "inf") {
            newPolygon = movePolygonInDirection(newPolygon, 0, displacement);
          }
        }
      }
    }
    try {
      newPolygon = makeWaterTight(
        newPolygon,
        allHoles,
        waterTightThreshold,
        angleThreshold
      );
      //newPolygon = vertexSnapping(newPolygon, allPolygons);
      newPolygon = makeWaterTight(
        newPolygon,
        allHoles,
        waterTightThreshold,
        angleThreshold
      );
    } catch (err) {
      console.log("Error in water tight. ", err);
    }
    newPolygon.push(newPolygon[0]);
    newHolesArray.push(newPolygon);
  }
  return newHolesArray;
};

const removeZeroAngles = (polygon) => {
  for (let i = 0; i < polygon.length; i++) {
    let edge1 = [polygon[i], polygon[(i + 1) % polygon.length]];
    let edge2 = [
      polygon[(i + 1) % polygon.length],
      polygon[(i + 2) % polygon.length],
    ];
    let angle = Math.round(angleBetweenEdges(edge1, edge2));
    if (angle === 0 || angle === 180) {
      polygon.splice((i + 1) % polygon.length, 1);
    }
  }
  return polygon;
};
const twoSnapShapeForHoles = (
  holesArray,
  structureID,
  levelID,
  threshold,
  waterTightThreshold,
  angleThreshold
) => {
  let newHolesArray = snappingOfHoles(
    holesArray,
    structureID,
    levelID,
    threshold,
    waterTightThreshold,
    angleThreshold
  );

  if (newHolesArray.length > 1) {
    for (let i = 0; i < newHolesArray.length; i++) {
      newHolesArray[i] = removeDuplicateVerticesModified(newHolesArray[i]);
      newHolesArray[i] = roundAndRemoveDuplicates(newHolesArray[i]);
      newHolesArray[i] = equateDecimalPoints(newHolesArray[i]);
    }
    let boolUnion = store.bool.union(
      {
        regions: [newHolesArray[newHolesArray.length - 1]],
        inverted: false,
      },
      {
        regions: [newHolesArray[newHolesArray.length - 2]],
        inverted: false,
      }
    );
    let extraPolygons = [];
    if (boolUnion.regions.length > 1 && newHolesArray.length > 2) {
      extraPolygons.push(boolUnion.regions[1]);
    }

    for (let i = newHolesArray.length - 3; i >= 0; i--) {
      let hole = newHolesArray[i];
      boolUnion = store.bool.union(
        {
          regions: [boolUnion.regions[0]],
          inverted: false,
        },
        {
          regions: [hole],
          inverted: false,
        }
      );
      if (boolUnion.regions.length > 1 && newHolesArray.length > 2) {
        extraPolygons.push(boolUnion.regions[1]);
      }
    }

    if (extraPolygons.length > 0) {
      for (let i = 0; i < extraPolygons.length; i++) {
        extraPolygons[i].push(extraPolygons[i][0]);
      }
      let hugePoly = boolUnion.regions;
      hugePoly[0].push(hugePoly[0][0]);

      let leftOutPolygonsWithHugePoly = hugePoly.concat(extraPolygons);
      let newAllPolygons = snappingOfHoles(
        leftOutPolygonsWithHugePoly,
        structureID,
        levelID,
        threshold,
        waterTightThreshold,
        angleThreshold
      );

      let leftOutPolygons = [];
      let finalUnion = store.bool.union(
        {
          regions: [newAllPolygons[0]],
          inverted: false,
        },
        {
          regions: [newAllPolygons[1]],
          inverted: false,
        }
      );
      if (finalUnion.regions.length > 1) {
        leftOutPolygons.push(finalUnion.regions[1]);
      }

      for (let i = 2; i < newAllPolygons.length; i++) {
        let hole = newAllPolygons[i];
        finalUnion = store.bool.union(
          {
            regions: [finalUnion.regions[0]],
            inverted: false,
          },
          {
            regions: [hole],
            inverted: false,
          }
        );
        if (finalUnion.regions.length > 1) {
          leftOutPolygons.push(finalUnion.regions[1]);
        }
      }

      if (leftOutPolygons.length > 0) {
        for (let i = 0; i < leftOutPolygons.length; i++) {
          leftOutPolygons[i].push(leftOutPolygons[i][0]);
        }
        let hugePolyFinal = finalUnion.regions;
        hugePolyFinal[0].push(hugePoly[0][0]);

        let leftOutPolygonsWithHugePolyFinal =
          hugePolyFinal.concat(leftOutPolygons);
        let finalPolygonsNew = snappingOfHoles(
          leftOutPolygonsWithHugePolyFinal,
          structureID,
          levelID,
          threshold,
          waterTightThreshold,
          angleThreshold
        );

        let result = store.bool.union(
          {
            regions: [finalPolygonsNew[0]],
            inverted: false,
          },
          {
            regions: [finalPolygonsNew[1]],
            inverted: false,
          }
        );

        for (let i = 2; i < result.length; i++) {
          let hole = finalPolygonsNew[i];
          result = store.bool.union(
            {
              regions: [result.regions[0]],
              inverted: false,
            },
            {
              regions: [hole],
              inverted: false,
            }
          );
        }
      }

      let output = finalUnion.regions;
      //let output = finalUnion.regions.concat(leftOutPolygons);
      for (let i = 0; i < output.length; i++) {
        output[i] = equateDecimalPoints(output[i]);
        output[i] = removeZeroAngles(output[i]);
        output[i].push(output[i][0]);
      }
      return output;
    } else {
      let output = boolUnion.regions;
      for (let i = 0; i < output.length; i++) {
        output[i] = equateDecimalPoints(output[i]);
        output[i] = removeZeroAngles(output[i]);
        output[i].push(output[i][0]);
      }
      return output;
    }
    //return [output];
  } else {
    return newHolesArray;
  }
  /* eslint-disable */
  return null;
  /* eslint-enable */
};

const twoSnapEdge = (
  edge,
  talkingOfPolygon,
  allPolygons,
  threshold,
  angleThreshold
) => {
  //if(slopeOfLine(edge) == 'inf' || slopeOfLine(edge) == 0) return null;
  for (let j = 0; j < allPolygons.length; j++) {
    if (allPolygons[j].length <= 4 || isOrthogonal(allPolygons[j])) {
      for (let k = 0; k < allPolygons[j].length; k++) {
        const talkingOfEdge = [
          allPolygons[j][k],
          allPolygons[j][(k + 1) % allPolygons[j].length],
        ];
        let angle = angleBetweenEdges(edge, talkingOfEdge);
        if (
          isBetween(0 - angleThreshold, angle, 0 + angleThreshold) ||
          isBetween(180 - angleThreshold, angle, 180 + angleThreshold)
        ) {
          if (distanceBetweenEdges(edge, talkingOfEdge) < threshold) {
            //CHANGE CHANGE CHANGE
            threshold = distanceBetweenEdges(edge, talkingOfEdge);
            const firstPoint = projectionOfPointOnLine2D(
              edge[0],
              talkingOfEdge[0],
              talkingOfEdge[1]
            );
            const secondPoint = projectionOfPointOnLine2D(
              edge[1],
              talkingOfEdge[0],
              talkingOfEdge[1]
            );
            return {
              snappedEdge: [firstPoint, secondPoint],
              snappedWithEdge: talkingOfEdge,
            };
          }
        }
      }
    }
  }
  return null;
};

const oneHotSnapper = (
  point,
  imageScaleFactor,
  scale,
  structureID,
  levelID
) => {
  let polygon = multiplyPointArrayByFactor(point, 1 / imageScaleFactor, scale);
  polygon = twoSnapDimension(polygon);
  polygon = snapDimensionFromEdgesOfCanvas(polygon);

  let threshold = 4; // ~ 1 metre
  let angleThreshold = 10; // degrees
  let waterTightThreshold = 4;

  polygon = twoSnapShape(
    polygon,
    structureID,
    levelID,
    threshold,
    waterTightThreshold,
    angleThreshold
  );
};

const twoSnapEdgeforSketch = (
  edge,
  talkingOfPolygon,
  allPolygons,
  threshold,
  angleThreshold
) => {
  //if(slopeOfLine(edge) == 'inf' || slopeOfLine(edge) == 0) return null;
  for (let j = 0; j < allPolygons.length; j++) {
    if (allPolygons[j].length <= 4) {
      for (let k = 0; k < allPolygons[j].length; k++) {
        const talkingOfEdge = [
          allPolygons[j][k],
          allPolygons[j][(k + 1) % allPolygons[j].length],
        ];
        let angle = angleBetweenEdges(edge, talkingOfEdge);
        if (
          isBetween(0 - angleThreshold, angle, 0 + angleThreshold) ||
          isBetween(180 - angleThreshold, angle, 180 + angleThreshold)
        ) {
          if (distanceBetweenEdges(edge, talkingOfEdge) < threshold) {
            //CHANGE CHANGE CHANGE
            threshold = distanceBetweenEdges(edge, talkingOfEdge);
            const firstPoint = projectionOfPointOnLine2D(
              edge[0],
              talkingOfEdge[0],
              talkingOfEdge[1]
            );
            const secondPoint = projectionOfPointOnLine2D(
              edge[1],
              talkingOfEdge[0],
              talkingOfEdge[1]
            );
            return {
              snappedEdge: [firstPoint, secondPoint],
              snappedWithEdge: talkingOfEdge,
            };
          }
        }
      }
    }
  }
  return null;
};

const snapDimensionFromEdgesOfCanvas = (polygon) => {
  const roundFactor = ScopeUtils.getUnitFactor();
  let xDisplacement = undefined;
  let yDisplacement = undefined;

  for (let i = 0; i < polygon.length - 2; i++) {
    //check if it has to be for(let  i = 0; i < polygon.length - 2; i++) instead
    //however, because we are currently talking about just orthogonal shapes, adjacent sides will be 90 degrees

    //can use for(let  i = 0; i < 2; i++) for optimizing <= this is a wrong statement.
    //REASON:
    //We are anyway "returning" as soon as we get xDisplacement and yDisplacement
    const talkingOfEdge = [polygon[(i + 1) % polygon.length], polygon[i]];
    const slope = slopeOfLine(talkingOfEdge);
    if (yDisplacement == undefined && slope == 0) {
      const yDistance = polygon[i][1];
      const yDistanceFactor = polygon[i][1] * roundFactor;
      const roundedDistanceWithFactor = Math.round(yDistanceFactor / 10) * 10;
      const roundedYDistance = roundedDistanceWithFactor / roundFactor;
      yDisplacement = roundedYDistance - yDistance;
      if (xDisplacement != undefined) {
        return movePolygonInDirection(polygon, xDisplacement, yDisplacement);
      }
    } else if (xDisplacement == undefined && slope == "inf") {
      const xDistance = polygon[i][0];
      const xDistanceFactor = polygon[i][0] * roundFactor;
      const roundedDistanceWithFactor = Math.round(xDistanceFactor / 10) * 10;
      const roundedXDistance = roundedDistanceWithFactor / roundFactor;
      xDisplacement = roundedXDistance - xDistance;
      if (yDisplacement != undefined) {
        return movePolygonInDirection(polygon, xDisplacement, yDisplacement);
      }
    }
  }
  return polygon;
};

const getBottomCoordinates = (structureID, storey) => {
  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure =
    structureCollection.getStructureById(structureID);
  // const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelID);
  const allMasses = talkingAboutStructure
    .getStoreyData()
    .getStoreyByValue(storey).elements;
  const allBottomCoordinates = [];

  for (let i = 0; i < allMasses.length; i++) {
    //Some meshes are staying in StructureCollection even after deleting them (esp instances, i.e., after copy/array and delete.
    //Checking this to prevent such meshes and all.
    if (allMasses[i].mesh.hasOwnProperty("_isDisposed"))
      if (allMasses[i].mesh._isDisposed) continue;
    if(allMasses[i].mesh && allMasses[i].mesh.name.includes("terrain")){
      continue;
    }
    try {
      const bottomCoordinates = getBottomCoordinatesOfMesh(allMasses[i].mesh);
      allBottomCoordinates.push(bottomCoordinates);
    } catch (err) {
      console.log("Error in getting bottom coordinates. ", err);
    }
  }
  return allBottomCoordinates;
};

const getBottomCoordinatesOfMesh = (mesh) => {
  const localVertices = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  const worldVertices = [];
  for (let i = 0; i < localVertices.length - 2; i += 3) {
    if (localVertices[i + 1] > 0) {
      break;
    }
    const vector = BABYLON.Vector3.TransformCoordinates(
      new BABYLON.Vector3(
        localVertices[i],
        localVertices[i + 1],
        localVertices[i + 2]
      ),
      mesh.getWorldMatrix()
    );
    worldVertices.push([vector.x, -vector.z]);
  }
  return worldVertices;
};

const getBottomCoordinatesforSketch = (structureID, levelID) => {
  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure =
    structureCollection.getStructureById(structureID);
  const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelID);
  const allMasses = talkingAboutLevel.getMasses();
  const allBottomCoordinates = [];

  for (let i = 0; i < allMasses.length; i++) {
    //Some meshes are staying in StructureCollection even after deleting them (esp instances, i.e., after copy/array and delete.
    //Checking this to prevent such meshes and all.
    if (allMasses[i].mesh.hasOwnProperty("_isDisposed"))
      if (allMasses[i].mesh._isDisposed) continue;
    //const bottomCoordinates = getBottomCoordinatesOfMeshforSketch(allMasses[i].mesh);
    try {
      const bottomCoords = getBottomCoordinatesofMeshModified(
        allMasses[i].mesh
      );
      allBottomCoordinates.push(bottomCoords);
    } catch (err) {
      console.log("Error in getting bottom coordinates (BREP). ", err);
    }
  }
  return allBottomCoordinates;
};

const getBottomCoordinatesOfMeshforSketch = (mesh) => {
  const localVertices = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  const worldVertices = [];
  for (let i = 0; i < localVertices.length - 2; i += 3) {
    if (localVertices[i + 1] > 0) {
      break;
    }
    const vector = BABYLON.Vector3.TransformCoordinates(
      new BABYLON.Vector3(
        localVertices[i],
        localVertices[i + 1],
        localVertices[i + 2]
      ),
      mesh.getWorldMatrix()
    );
    worldVertices.push([vector.x, vector.z]);
  }
  return worldVertices;
};

const getBottomCoordinatesofMeshModified = (mesh) => {
  let brep = mesh.getSnaptrudeDS().brep;
  let roofAngleThresold = 45;
  let precision = 1e-3;
  let facesWithAngle = [];
  let minAngle = 10000;
  let finalVertices = [];
  brep.getFaces().some((face) => {
    let options = {};
    options.holes = true;
    let worldVertices = getFaceVerticesFromFace(
      face,
      mesh,
      BABYLON.Space.WORLD
    );
    /*        let localVertices = getFaceVerticesFromFace(face, mesh, BABYLON.Space.LOCAL, options);
        for(let k = 0; k < worldVertices.length; k++ ){
            let eachFace = worldVertices[k];
            let localVerticesEach = localVertices[k];
            let normal = getNormalVector(eachFace);
            if(normal){
                let unitNormalVector = BABYLON.Vector3.FromArray(normal).normalize();
                let angleNormalMakesWithZ = getAngleBetweenVectors(unitNormalVector, BABYLON.Vector3.Up().negate());
                minAngle = Math.min(minAngle, angleNormalMakesWithZ);
                facesWithAngle.push({face, angleNormalMakesWithZ});

                let angle = angleNormalMakesWithZ;
                if ((angle < roofAngleThresold) || (angle > 180 - roofAngleThresold)) {
                    for( let i = 0; i < eachFace.length; i++ ){
                        if ( localVerticesEach[i].y > 0){
                            break;
                        }
                        finalVertices.push([eachFace[i].x, eachFace[i].z]);
                    }
                }

            }
        }*/

    let normal = getNormalVector(worldVertices);
    if (normal) {
      let unitNormalVector = BABYLON.Vector3.FromArray(normal).normalize();
      let angleNormalMakesWithZ = getAngleBetweenVectors(
        unitNormalVector,
        BABYLON.Vector3.Up().negate()
      );
      minAngle = Math.min(minAngle, angleNormalMakesWithZ);
      facesWithAngle.push({ face, angleNormalMakesWithZ });

      let angle = angleNormalMakesWithZ;
      if (angle < roofAngleThresold || angle > 180 - roofAngleThresold) {
        let localVertices = getFaceVerticesFromFace(
          face,
          mesh,
          BABYLON.Space.LOCAL
        );
        for (let i = 0; i < localVertices.length; i++) {
          if (localVertices[i].y > 0) {
            break;
          }
          finalVertices.push([worldVertices[i].x, worldVertices[i].z]);
        }
      }
    }
  });
  return finalVertices;
};

const forceMakeParallel = (edgeToMakeParallel, polygon, referenceEdge) => {
  for (let i = 0; i < polygon.length; i++) {
    if (polygon[i] == edgeToMakeParallel[0]) {
      if (polygon[(i + 1) % polygon.length] == edgeToMakeParallel[1]) {
        const angleWithPrevious = angleBetweenEdges(
          [polygon[(i - 1 + polygon.length) % polygon.length], polygon[i]],
          [polygon[i], polygon[(i + 1) % polygon.length]]
        );
        const angleWithNext = angleBetweenEdges(
          [polygon[i], polygon[(i + 1) % polygon.length]],
          [polygon[(i + 1) % polygon.length], polygon[(i + 2) % polygon.length]]
        );
        const originalDistance = distanceBetweenPoints2D(
          polygon[i],
          polygon[(i + 1) % polygon.length]
        );
        const line = lineParallelToLineAndPassingThroughPoint(
          referenceEdge,
          edgeToMakeParallel[0]
        );
        const ninetyPoint = projectionOfPointOnLine2D(
          polygon[(i - 1 + polygon.length) % polygon.length],
          line[0],
          line[1]
        );
        const opposite = distanceBetweenPoints2D(
          polygon[(i - 1 + polygon.length) % polygon.length],
          ninetyPoint
        );
        let adjacent = null;
        if (angleWithPrevious != 90) {
          adjacent = opposite / Math.tan((angleWithPrevious * Math.PI) / 180);
        } else {
          adjacent = 0;
        }
        const newPoints0 = lineWithAngleAndAtDistanceFromPoint(
          ninetyPoint,
          angleWithPrevious,
          adjacent
        );
        let firstPoint = null;
        /*if(checkIfNearArray(newPoints0[0], polygon[i], thresholdDistance)){
                    firstPoint = newPoints0[0];
                } else {
                    firstPoint = newPoints0[1];
                }*/

        if (
          distanceBetweenPoints2D(newPoints0[0], polygon[i]) <
          distanceBetweenPoints2D(newPoints0[1], polygon[i])
        ) {
          firstPoint = newPoints0[0];
        } else {
          firstPoint = newPoints0[1];
        }

        const ninetyPoint2 = projectionOfPointOnLine2D(
          polygon[(i + 2) % polygon.length],
          line[0],
          line[1]
        );
        const opposite2 = distanceBetweenPoints2D(
          polygon[(i + 2) % polygon.length],
          ninetyPoint2
        );
        let adjacent2 = null;
        if (angleWithPrevious != 90) {
          adjacent2 = opposite2 / Math.tan((angleWithNext * Math.PI) / 180);
        } else {
          adjacent2 = 0;
        }

        const newPoints1 = lineWithAngleAndAtDistanceFromPoint(
          ninetyPoint2,
          angleWithNext,
          adjacent2
        );
        let secondPoint = null;
        /*if(checkIfNearArray(newPoints1[0], polygon[(i + 1) % polygon.length], thresholdDistance)){
                    secondPoint = newPoints1[0];
                } else {
                    secondPoint = newPoints1[1];
                }*/

        if (
          distanceBetweenPoints2D(
            newPoints1[0],
            polygon[(i + 1) % polygon.length]
          ) <
          distanceBetweenPoints2D(
            newPoints1[1],
            polygon[(i + 1) % polygon.length]
          )
        ) {
          secondPoint = newPoints1[0];
        } else {
          secondPoint = newPoints1[1];
        }

        return [firstPoint, secondPoint];
      }
    }
  }
  return null;
};

const makeWaterTight = (polygon, allPolygons, threshold, angleThreshold) => {
  let distanceArray = [];
  let whichEdgeArray = [];
  let angleArray = [];
  for (let i = 0; i < polygon.length; i++) {
    distanceArray.push(null);
    whichEdgeArray.push(null);
  }

  for (let i = 0; i < polygon.length; i++) {
    for (let j = 0; j < allPolygons.length; j++) {
      for (let k = 0; k < allPolygons[j].length; k++) {
        if (
          areEdgesClose2(
            [polygon[i], polygon[(i + 1) % polygon.length]],
            [allPolygons[j][k], allPolygons[j][(k + 1) % allPolygons[j].length]]
          ) ||
          areEdgesClose2(
            [
              allPolygons[j][k],
              allPolygons[j][(k + 1) % allPolygons[j].length],
            ],
            [polygon[i], polygon[(i + 1) % polygon.length]]
          )
        ) {
          const angle = angleBetweenEdges(
            [polygon[i], polygon[(i + 1) % polygon.length]],
            [allPolygons[j][k], allPolygons[j][(k + 1) % allPolygons[j].length]]
          );
          if (
            isBetween(0 - angleThreshold, angle, 0 + angleThreshold) ||
            isBetween(180 - angleThreshold, angle, 180 + angleThreshold)
          ) {
            const distance = distanceBetweenEdges(
              [polygon[i], polygon[(i + 1) % polygon.length]],
              [
                allPolygons[j][k],
                allPolygons[j][(k + 1) % allPolygons[j].length],
              ]
            );
            //.log("Distance of", [polygon[i], polygon[(i + 1) % polygon.length]], "From ", [allPolygons[j][k], allPolygons[j][(k + 1) % allPolygons[j].length]], "is", distance);
            if (distanceArray[i] == null || distance < distanceArray[i]) {
              distanceArray[i] = distance;
              whichEdgeArray[i] = {
                polygonIndex: j,
                edgeIndex: [k, (k + 1) % allPolygons[j].length],
                polygon: allPolygons[j],
                edge: [
                  allPolygons[j][k],
                  allPolygons[j][(k + 1) % allPolygons[j].length],
                ],
              };
            }
          }
        }
      }
    }
  }
  //console.log('distanceArr', distanceArray);
  //console.log('thresh', threshold);

  const newPolygon = [];
  for (let i = 0; i < polygon.length; i++) {
    newPolygon.push(polygon[i]);
    const angle1 = angleBetweenEdges(
      [polygon[i], polygon[(i + 1) % polygon.length]],
      [polygon[(i + 1) % polygon.length], polygon[(i + 2) % polygon.length]]
    );
    angleArray.push(angle1);
  }
  //console.log('angleArray', angleArray);
  let originalPolygon = Object.assign([], newPolygon);
  //const origPolygon = newPolygon;
  for (let i = 0; i < newPolygon.length; i++) {
    if (distanceArray[i] != null) {
      if (whichEdgeArray[i] != null) {
        if (distanceArray[i] != 0) {
          if (distanceArray[i] < threshold) {
            //CHANGE CHANGE CHANGE
            const edge = [
              allPolygons[whichEdgeArray[i].polygonIndex][
                whichEdgeArray[i].edgeIndex[0]
              ],
              allPolygons[whichEdgeArray[i].polygonIndex][
                whichEdgeArray[i].edgeIndex[1]
              ],
            ];
            //if (edge == whichEdgeArray[i].edge) {
            //newPolygon[i] = projectionOfPointOnLine2D(newPolygon[i], edge[0], edge[1]);
            //newPolygon[(i + 1) % newPolygon.length] = projectionOfPointOnLine2D(newPolygon[(i + 1) % newPolygon.length], edge[0], edge[1]);
            //console.log('thresholdwater', threshold);

            newPolygon[i] = projectionOfPointOnLine2D(
              newPolygon[i],
              edge[0],
              edge[1]
            );
            newPolygon[(i + 1) % newPolygon.length] = projectionOfPointOnLine2D(
              newPolygon[(i + 1) % newPolygon.length],
              edge[0],
              edge[1]
            );

            // let edge1 = [newPolygon[i], newPolygon[(i + 1) % newPolygon.length]];
            // const th = 1.0; // approximately 208mm
            // //console.log('th', threshold);
            // if (distanceBetweenEdges(edge,edge1) < 1)
            // {
            //     if (angleBetweenEdges(edge, edge) < 10)
            //     {
            //         if( Math.abs(newPolygon[i][0] - edge[0][0]) < th && Math.abs(newPolygon[i][1] - edge[0][1]) < th )
            //         {
            //             newPolygon[i][0] = edge[0][0];
            //             newPolygon[i][1] = edge[0][1];
            //         }
            //         if( Math.abs(newPolygon[(i + 1) % newPolygon.length][0] - edge[1][0]) < th && Math.abs(newPolygon[(i + 1) % newPolygon.length][1] - edge[1][1]) < th )
            //         {
            //             newPolygon[(i + 1) % newPolygon.length][0] = edge[1][0];
            //             newPolygon[(i + 1) % newPolygon.length][1] = edge[1][1];
            //         }
            //     }
            // }
          }
        }
      }
    }
  }

  return newPolygon;
};

const makeWaterTightforSketch = (
  polygon,
  allPolygons,
  threshold,
  angleThreshold
) => {
  let distanceArray = [];
  let whichEdgeArray = [];
  let angleArray = [];
  for (let i = 0; i < polygon.length; i++) {
    distanceArray.push(null);
    whichEdgeArray.push(null);
  }

  for (let i = 0; i < polygon.length; i++) {
    for (let j = 0; j < allPolygons.length; j++) {
      for (let k = 0; k < allPolygons[j].length; k++) {
        if (
          areEdgesClose2(
            [polygon[i], polygon[(i + 1) % polygon.length]],
            [allPolygons[j][k], allPolygons[j][(k + 1) % allPolygons[j].length]]
          ) ||
          areEdgesClose2(
            [
              allPolygons[j][k],
              allPolygons[j][(k + 1) % allPolygons[j].length],
            ],
            [polygon[i], polygon[(i + 1) % polygon.length]]
          )
        ) {
          const angle = angleBetweenEdges(
            [polygon[i], polygon[(i + 1) % polygon.length]],
            [allPolygons[j][k], allPolygons[j][(k + 1) % allPolygons[j].length]]
          );
          if (
            isBetween(0 - angleThreshold, angle, 0 + angleThreshold) ||
            isBetween(180 - angleThreshold, angle, 180 + angleThreshold)
          ) {
            const distance = distanceBetweenEdges(
              [polygon[i], polygon[(i + 1) % polygon.length]],
              [
                allPolygons[j][k],
                allPolygons[j][(k + 1) % allPolygons[j].length],
              ]
            );
            //.log("Distance of", [polygon[i], polygon[(i + 1) % polygon.length]], "From ", [allPolygons[j][k], allPolygons[j][(k + 1) % allPolygons[j].length]], "is", distance);
            if (distanceArray[i] == null || distance < distanceArray[i]) {
              distanceArray[i] = distance;
              whichEdgeArray[i] = {
                polygonIndex: j,
                edgeIndex: [k, (k + 1) % allPolygons[j].length],
                polygon: allPolygons[j],
                edge: [
                  allPolygons[j][k],
                  allPolygons[j][(k + 1) % allPolygons[j].length],
                ],
              };
            }
          }
        }
      }
    }
  }
  //console.log('distanceArr', distanceArray);
  //console.log('thresh', threshold);

  const newPolygon = [];
  for (let i = 0; i < polygon.length; i++) {
    newPolygon.push(polygon[i]);
    const angle1 = angleBetweenEdges(
      [polygon[i], polygon[(i + 1) % polygon.length]],
      polygon[(i + 1) % polygon.length],
      polygon[(i + 2) % polygon.length]
    );
    angleArray.push(angle1);
  }
  //console.log('angleArray', angleArray);
  for (let i = 0; i < newPolygon.length; i++) {
    if (distanceArray[i] != null) {
      if (whichEdgeArray[i] != null) {
        if (distanceArray[i] != 0) {
          if (distanceArray[i] < threshold) {
            //CHANGE CHANGE CHANGE
            const edge = [
              allPolygons[whichEdgeArray[i].polygonIndex][
                whichEdgeArray[i].edgeIndex[0]
              ],
              allPolygons[whichEdgeArray[i].polygonIndex][
                whichEdgeArray[i].edgeIndex[1]
              ],
            ];
            //if (edge == whichEdgeArray[i].edge) {
            //newPolygon[i] = projectionOfPointOnLine2D(newPolygon[i], edge[0], edge[1]);
            //newPolygon[(i + 1) % newPolygon.length] = projectionOfPointOnLine2D(newPolygon[(i + 1) % newPolygon.length], edge[0], edge[1]);
            //console.log('thresholdwater', threshold);
            newPolygon[i] = projectionOfPointOnLine2D(
              newPolygon[i],
              edge[0],
              edge[1]
            );
            newPolygon[(i + 1) % newPolygon.length] = projectionOfPointOnLine2D(
              newPolygon[(i + 1) % newPolygon.length],
              edge[0],
              edge[1]
            );

            let edge1 = [
              newPolygon[i],
              newPolygon[(i + 1) % newPolygon.length],
            ];
            const th = 1; // approximately 208mm
            //console.log('th', threshold);
            if (distanceBetweenEdges(edge, edge1) < 1) {
              if (angleBetweenEdges(edge, edge) < 5) {
                if (
                  Math.abs(newPolygon[i][0] - edge[0][0]) < th &&
                  Math.abs(newPolygon[i][1] - edge[0][1]) < th
                ) {
                  newPolygon[i][0] = edge[0][0];
                  newPolygon[i][1] = edge[0][1];
                }
                if (
                  Math.abs(
                    newPolygon[(i + 1) % newPolygon.length][0] - edge[1][0]
                  ) < th &&
                  Math.abs(
                    newPolygon[(i + 1) % newPolygon.length][1] - edge[1][1]
                  ) < th
                ) {
                  newPolygon[(i + 1) % newPolygon.length][0] = edge[1][0];
                  newPolygon[(i + 1) % newPolygon.length][1] = edge[1][1];
                }
              }
            }

            // console.log("p1", newPolygon[i]);
            // console.log("p2", newPolygon[(i+1)%newPolygon.length]);
            //
            // console.log("p3", allPolygons[whichEdgeArray[i].polygonIndex][whichEdgeArray[i].edgeIndex[0]]);
            // console.log("p4", allPolygons[whichEdgeArray[i].polygonIndex][whichEdgeArray[i].edgeIndex[1]]);
            //
            // console.log("p5", allPolygons[whichEdgeArray[(i + 1) % newPolygon.length].polygonIndex][whichEdgeArray[(i + 1) % newPolygon.length].edgeIndex[1]][0]);
            // console.log("p6", allPolygons[whichEdgeArray[(i + 1) % newPolygon.length].polygonIndex][whichEdgeArray[(i + 1) % newPolygon.length].edgeIndex[1]][1]);
            //
            // console.log("p7", Math.abs(newPolygon[i][0] - allPolygons[whichEdgeArray[i].polygonIndex][whichEdgeArray[i].edgeIndex[0]][0] ));
            // console.log("p8", Math.abs(newPolygon[(i + 1) % newPolygon.length][0] - allPolygons[whichEdgeArray[i].polygonIndex][whichEdgeArray[i].edgeIndex[1]][0]  ));
          }
        }
      }
    }
  }

  return newPolygon;
};

const areInsideAnyPoly = (points, polygons, index) => {
  let polIndices = [];
  for (let i = 0; i < points.length; i++) {
    let status = isInsideAnyPoly(points[i], polygons, index);
    if (status) {
      polIndices.push(status);
    }
    // if ((!status) && (status !== 0)){
    //     return false;
    // }
    // else{
    //     polIndices.push(status);
    // }
  }
  let ind = polIndices[0];
  for (let i = 0; i < polIndices.length; i++) {
    if (polIndices[i] !== ind) return false;
  }
  return [polIndices[0]];
};

const isInsideAnyPoly = (point, polygons, index) => {
  for (let i = 0; i < polygons.length; i++) {
    if (i === index) continue;
    if (isInsidePoly(point, polygons[i])) {
      return i;
    }
  }
  return false;
};

const isInsidePoly = (point, polygon) => {
  let x = point[0];
  let y = point[1];
  let intersections = 0;
  //let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    if (x == polygon[0][0] && y == polygon[0][1]) {
      return true;
    }
    if (
      x == polygon[polygon.length - 1][0] &&
      y == polygon[polygon.length - 1][1]
    ) {
      return true;
    }
    let xi = polygon[i][0];
    let yi = polygon[i][1];

    let xj = polygon[j][0];
    let yj = polygon[j][1];

    if (yj == yi && yj == y && x > Math.min(xj, xi) && x < Math.max(xj, xi)) {
      return true;
    }

    if (
      y > Math.min(yj, yi) &&
      y <= Math.max(yj, yi) &&
      x <= Math.max(xj, xi) &&
      yj != yi
    ) {
      let res = ((y - yj) * (xi - xj)) / (yi - yj) + xj;
      if (res == x) {
        return true;
      }

      if (xj == xi || x <= res) {
        intersections++;
      }
    }
  }
  //let intersect = ( (yi > y) != (yj > y) ) && ( x < ( ( xj - xi ) * (yj - yi) ) / ( (yj - yi) + xi ) )
  //if(intersect) inside = !inside;

  if (intersections % 2 != 0) {
    return true;
  } else {
    return false;
  }
};

// https://lucidar.me/en/mathematics/check-if-a-point-belongs-on-a-line-segment/
const isPointOnSegment = (point, segmentStart, segmentEnd) => {
  // segment ab, and a point c

  const a = BABYLON.Vector2.FromArray(segmentStart);
  const b = BABYLON.Vector2.FromArray(segmentEnd);
  const c = BABYLON.Vector2.FromArray(point);

  const ab = b.subtract(a);
  const ac = c.subtract(a);

  const threshold = 1e-3;
  const abCrossAc = (c.y - a.y) * (b.x - a.x) - (c.x - a.x) * (b.y - a.y);
  if (!isFloatEqual(abCrossAc, 0, threshold)) return false;

  const abDotAc = BABYLON.Vector2.Dot(ab, ac);
  const abDotAb = BABYLON.Vector2.Dot(ab, ab);

  // <= implies true if c is on a or b
  return 0 <= abDotAc && abDotAc <= abDotAb;
};

const isPointOnPolygon = (point, polygon) => {
  let liesOnPolygon = false;
  polygon.some((p0, i) => {
    const p1 = i === polygon.length - 1 ? polygon[0]: polygon[i + 1];
    liesOnPolygon = isPointOnSegment(point, p0, p1);

    return liesOnPolygon;
  });

  return liesOnPolygon;
};

const isInsidePoly1 = (point, polygon) => {
  let x = point[0];
  let y = point[1];

  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i][0];
    let yi = polygon[i][1];

    let xj = polygon[j][0];
    let yj = polygon[j][1];

    let intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }
  return inside;
};
/**
 * All params are vector3 objects but y is ignored
 *
 * @param point
 * @param polygon
 * @returns {boolean}
 */
const isPointInsidePolygon = (point, polygon) => {
  let x = point.x;
  let y = point.z;
  // const resolveEngineUtils = new ResolveEngineUtils();
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const point1 = polygon[i];
    let x1 = point1.x;
    let y1 = point1.z;

    const point2 = polygon[j];
    let x2 = point2.x;
    let y2 = point2.z;

    if (store.resolveEngineUtils.onSegment2D(point1, point, point2)) {
      inside = false;
      break;
    }

    let intersect =
      y1 > y !== y2 > y && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1;
    // this formula is from func isInsidePoly1

    if (intersect) inside = !inside;
  }

  return inside;
};

const isOverlap = (polygon, allPolygons) => {
  let pointInside = [];
  //let polygonEdges = pointArrayToEdgeArray(polygon);
  //let allPolyEdges = pointArrayToEdgeArray(allPolygons[0]);
  for (let i = 0; i < polygon.length; i++) {
    if (isInsidePoly(polygon[i], allPolygons[0])) {
      pointInside.push(polygon[i]);
    }
  }
  return pointInside;
};

const vertexSnapping = (polygon, allPolygons) => {
  let newPolygon = Object.assign(polygon);
  for (let i = 0; i < newPolygon.length; i++) {
    for (let j = 0; j < allPolygons.length; j++) {
      for (let k = 0; k < allPolygons[j].length; k++) {
        if (distanceBetweenPoints2D(newPolygon[i], allPolygons[j][k]) < 1) {
          newPolygon[i] = allPolygons[j][k];
        }
      }
    }
  }
  return newPolygon;
};

const vertexSnappingforSketch = (polygon, allPolygons) => {
  let newPolygon = Object.assign(polygon);
  for (let i = 0; i < newPolygon.length; i++) {
    for (let j = 0; j < allPolygons.length; j++) {
      for (let k = 0; k < allPolygons[j].length; k++) {
        if (distanceBetweenPoints2D(newPolygon[i], allPolygons[j][k]) < 1) {
          newPolygon[i] = allPolygons[j][k];
        }
      }
    }
  }
  return newPolygon;
};

//Shortest path algorithm
const pointInPolygonSet = (pt, polygons) => {
  let oddNodes = false;
  for (let k = 0; k < polygons.length; k++) {
    let poly = polygons[k];
    for (let i = 0; i < poly.length; i++) {
      let j = (i + 1) % poly.length;
      if (
        (poly[i][1] < pt[1] && poly[j][1] >= pt[1]) ||
        (poly[j][1] < pt[1] && poly[i][1] >= pt[1])
      ) {
        if (
          poly[i][0] +
            ((pt[1] - poly[i][1]) / (poly[j][1] - poly[i][1])) *
              (poly[j][0] - poly[i][0]) <
          pt[0]
        ) {
          oddNodes = !oddNodes;
        }
      }
    }
  }
  return oddNodes;
};

const lineInPolygonSet = (st, end, polygons) => {
  end[0] -= st[0];
  end[1] -= st[1];
  const dist = Math.sqrt(end[0] * end[0] + end[1] * end[1]);
  const cosSE = end[0] / dist;
  const sinSE = end[1] / dist;

  for (let k = 0; k < polygons.length; k++) {
    let poly = polygons[k];
    for (let i = 0; i < poly.length; i++) {
      let j = (i + 1) % poly.length;
      let s = [poly[i][0] - st[0], poly[i][1] - st[1]];
      let e = [poly[j][0] - st[0], poly[j][1] - st[1]];
      if (s[0] === 0 && s[1] === 0 && e[0] === end[0] && end[1] === end[1]) {
        return true;
      } else if (
        s[0] === end[0] &&
        s[1] === end[1] &&
        e[0] === 0 &&
        end[1] === 0
      ) {
        return true;
      }
      let rotS = [s[0] * cosSE + s[1] * sinSE, s[1] * cosSE - s[0] * sinSE];
      let rotE = [e[0] * cosSE + e[1] * sinSE, e[1] * cosSE - e[0] * sinSE];
      if ((rotS[1] < 0 && rotE[1] > 0) || (rotE[1] < 0 && rotS[1] > 0)) {
        let crossX =
          rotS[0] + ((rotE[0] - rotS[0]) * -rotS[1]) / (rotE[1] - rotS[1]);
        if (crossX >= 0 && crossX <= dist) return false;
      }
      if (
        rotS[1] == 0 &&
        rotE[1] == 0 &&
        (rotS[0] >= 0 || rotE[0] >= 0) &&
        (rotS[0] <= dist || rotE[0] <= dist) &&
        (rotS[0] < 0 || rotE[0] < 0 || rotS[0] > dist || rotE[0] > dist)
      ) {
        return false;
      }
    }
  }
  return pointInPolygonSet([st[0] + end[0] / 2, st[1] + end[1] / 2], polygons);
};

const shortestPath = (st, end, polygons) => {
  let solutionNodes;

  function calcDist(s, e) {
    e[0] -= s[0];
    e[1] -= s[1];
    return Math.sqrt(e[0] * e[0] + e[1] * e[1]);
  }

  //  Fail if either the startpoint or endpoint is outside the polygon set.
  if (
    !pointInPolygonSet(JSON.parse(JSON.stringify(st)), polygons) ||
    !pointInPolygonSet(JSON.parse(JSON.stringify(end)), polygons)
  ) {
    return false;
  }

  //  If there is a straight-line solution, return with it immediately.
  if (
    lineInPolygonSet(
      JSON.parse(JSON.stringify(st)),
      JSON.parse(JSON.stringify(end)),
      polygons
    )
  ) {
    solutionNodes = 0;
    return true;
  }

  //  Build a point list that refers to the corners of the
  //  polygons, as well as to the startpoint and endpoint.
  let pointList = [];
  pointList.push({ x: st[0], y: st[1] });
  let pointCount = 1;
  for (let k = 0; k < polygons.length; k++) {
    let poly = polygons[k];
    for (let i = 0; i < poly.length; i++) {
      let j = (i + 1) % poly.length;
      pointList.push({ x: poly[i][0], y: poly[i][1], totalDist: 0 });
      pointCount++;
    }
  }
  pointList.push({ x: end[0], y: end[1] });
  pointCount++;

  //  Initialize the shortest-path tree to include just the startpoint.
  let treeCount = 1;
  pointList[0].totalDist = 0;

  //  Iteratively grow the shortest-path tree until it reaches the endpoint
  //  -- or until it becomes unable to grow, in which case exit with failure.
  let bestJ = 0;
  let bestI;

  while (bestJ < pointCount - 1) {
    let bestDist = 1e15;
    let newDist;
    for (let i = 0; i < treeCount; i++) {
      for (let j = treeCount; j < pointCount; j++) {
        if (
          lineInPolygonSet(
            JSON.parse(JSON.stringify([pointList[i].x, pointList[i].y])),
            JSON.parse(JSON.stringify([pointList[j].x, pointList[j].y])),
            polygons
          )
        ) {
          newDist =
            pointList[i].totalDist +
            calcDist(
              JSON.parse(JSON.stringify([pointList[i].x, pointList[i].y])),
              JSON.parse(JSON.stringify([pointList[j].x, pointList[j].y]))
            );
          if (newDist < bestDist) {
            bestDist = newDist;
            bestI = i;
            bestJ = j;
          }
        }
      }
    }
    if (bestDist >= 1e10) {
      return false;
    }
    pointList[bestJ].prev = bestI;
    pointList[bestJ].totalDist = bestDist;
    // swapPoints(pointList[bestJ], pointList[treeCount]);
    let temp = JSON.stringify(pointList[bestJ]);
    pointList[bestJ] = JSON.parse(JSON.stringify(pointList[treeCount]));
    pointList[treeCount] = JSON.parse(temp);
    treeCount++;
  }

  //  Load the solution arrays.
  solutionNodes = -1;
  let i = treeCount - 1;
  while (i > 0) {
    i = pointList[i].prev;
    solutionNodes++;
  }
  let j = solutionNodes - 1;
  i = treeCount - 1;
  let solutionPoints = [];
  while (j >= 0) {
    i = pointList[i].prev;
    solutionPoints.push([pointList[i].x, pointList[i].y]);
    j--;
  }
  //  Success.
  return solutionPoints;
};
export {
  twoSnapDimension,
  twoSnapShape,
  twoSnapShapeforSketch,
  snappingOfHoles,
  removeZeroAngles,
  twoSnapShapeForHoles,
  twoSnapEdge,
  oneHotSnapper,
  twoSnapEdgeforSketch,
  snapDimensionFromEdgesOfCanvas,
  getBottomCoordinates,
  getBottomCoordinatesOfMesh,
  getBottomCoordinatesforSketch,
  getBottomCoordinatesOfMeshforSketch,
  getBottomCoordinatesofMeshModified,
  forceMakeParallel,
  makeWaterTight,
  makeWaterTightforSketch,
  areInsideAnyPoly,
  isInsideAnyPoly,
  isInsidePoly,
  isInsidePoly1,
  isPointOnPolygon,
  isPointInsidePolygon,
  isOverlap,
  vertexSnapping,
  vertexSnappingforSketch,
  pointInPolygonSet,
  lineInPolygonSet,
  shortestPath,
};
