import React, { useState } from "react";
import styled from "styled-components";
import tick from "../../assets/icons/tick.svg";
import { colors } from "../../themes/constant";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  background: ${colors.white};
  border: 0.063rem solid ${colors.thinGray};
  box-sizing: border-box;
  border-radius: 0.181rem;
  height: 0.725rem;
  width: 0.725rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 0.4rem;
    width: 0.4rem;
  }
`;

const CheckBoxLabel = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: ${(props) => props.textAlign ? props.textAlign : 'right'};
  letter-spacing: -0.192708px;
  color: ${colors.thinGray};
  margin: 0.5rem 0;
  flex: 1;
  padding-left: 5px;
  width: 100%
`;

const CheckBox = (props) => {
  const { isChecked, onPress, label, labelLeft, textAlign } = props;
  const [state, setState] = useState(isChecked);

  const onCheckboxPress = () => {
    if (onPress) onPress(!state);
    setState((prevState) => !prevState);
  };

  return (
    <Container style={{ justifyContent: label ? "space-between" : "center" }}>
      {labelLeft && label ? <CheckBoxLabel>{label}</CheckBoxLabel> : null}
      <CheckBoxContainer onClick={onCheckboxPress}>
        {state ? <img src={tick} alt="yes" /> : <div />}
      </CheckBoxContainer>
      {label ? <CheckBoxLabel textAlign={textAlign}>{label}</CheckBoxLabel> : null}
    </Container>
  );
};

export default CheckBox;
