import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import { Select } from "../../../../components/DataTable";
import Toggle from "../../../../components/Toggle";
import {getTeamPreferences, updateTeamPreference} from '../../../../services/team.service';
import _ from "lodash";
import LoadingScreen from "../../../../components/Loading/LoadingScreen";
const Wrapper = styled.div`
    // border: 2px solid;
    padding: 0.25em;
    width: 25em;
    .disabled{
        opacity: 0.5;
        pointer-events: none;
    }
`
const Row = styled.div`
    display: flex;
    align-items: center;
    margin: 1.75em 0em;
`
const Column = styled.div`
    flex: ${props => props.flex ? props.flex : "0 1 auto"};
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContentEnd ? "end" : "start"};
`
const PLACEHOLDER_TEAM_PREFERENCES = [
    {key: "Use Snaptrude Materials", property: "use_snaptrude_materials", value: false },
    {key: "Use Snaptrude Doors", property: "use_snaptrude_doors", value: false},
    {key: "Use Snaptrude Windows", property: "use_snaptrude_windows", value: false},
    {key: "Use Snaptrude Furniture", property: "use_snaptrude_furniture", value: false},
    {key: "Enable team defaults for Units", property: "enable_team_default_for_units", value: false},
    {key: "Enable team defaults for Construction", property: "enable_team_default_for_construction", value: false},
    {key: "Enable team defaults for Space properties", property: "enable_team_default_for_space_properties", value: false},
    {key: "Enable team defaults for Area Settings", property: "enable_team_default_for_area_settings", value: false},
]
const INVITE_SETTINGS_DICT = {
    "anyonewithlink": {value: "anyonewithlink", displayValue: "Anyone with link"},
    "restrictedonlythoseadded": {value: "restrictedonlythoseadded", displayValue: "Restricted only those added"}
}
function Admin({selectedTeam}){
    const [teamPreferences, setTeamPreferences] = useState(PLACEHOLDER_TEAM_PREFERENCES);
    const [defaultInviteSetting, setDefaultInviteSetting] = useState(INVITE_SETTINGS_DICT.anyonewithlink);
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    const itemRefs = useRef([]);
    const defaultInviteRef = useRef(null);
    useEffect(() => {
        setShowLoadingAnimation(true);
        getTeamPreferences(selectedTeam.id)
        .then(resp => {
            if(resp.status === "success"){
                const {preferences} = resp.team;
                const newPreferences = PLACEHOLDER_TEAM_PREFERENCES.map(preference => {
                    let value = preferences[preference.property];
                    if(_.isString(value)){
                        if(preferences[preference.property] === "False")
                        value = false
                    }
                    return {
                        ...preference,
                        value: value
                    }
                })
                setTeamPreferences(newPreferences)
                setDefaultInviteSetting(INVITE_SETTINGS_DICT[preferences["default_invite_setting"]]);
                setShowLoadingAnimation(false);
            }
        })
    }, [selectedTeam]);

    const handleSettingChange = async (index, newValue) => {
        
        try {
            itemRefs.current[index].classList.add("disabled");
            const resp = await updateTeamPreference(selectedTeam.id, teamPreferences[index].property, newValue);
            if(resp.status === "success"){
                const newPreferences = teamPreferences.map((preference, idx) => {
                    if(idx === index){
                        preference.value = newValue;
                    }
                    return {
                        ...preference
                    }
                })
                setTeamPreferences(newPreferences);
            }
        } catch (error) {
            
        } finally {
            itemRefs.current[index].classList.remove("disabled");
        }
    }

    const handleDefaultInviteChange = async (selectedOption, index) => {
        const previousOption = defaultInviteSetting;
        if(previousOption.value === selectedOption.value) return;
        try {
            defaultInviteRef.current.classList.add("disabled");
            const resp = await updateTeamPreference(selectedTeam.id, "default_invite_setting", selectedOption.value);
            if(resp.status === "success"){
                
            }else{
                setDefaultInviteSetting(previousOption);
            }
        } catch (error) {
            
        } finally {
            defaultInviteRef.current.classList.remove("disabled");
        }
    }
    if(showLoadingAnimation) return <LoadingScreen />
    return (
        <Wrapper>
        <Row ref={defaultInviteRef}>
            <Column flex={1}>
                Default invite setting
            </Column>
            <Column justifyContentEnd>
                <Select 
                    key={defaultInviteSetting.value}
                    selectedOption={defaultInviteSetting} 
                    options={
                        [
                            INVITE_SETTINGS_DICT.anyonewithlink,
                            INVITE_SETTINGS_DICT.restrictedonlythoseadded
                        ]
                    }  
                    onSelect={handleDefaultInviteChange}
                />
            </Column>
        </Row>
        {
            teamPreferences.map((setting, index) => {
                return (
                    <Row key={setting.key} ref={el => itemRefs.current[index] = el }>
                        <Column flex={1}>
                            {setting.key}
                        </Column>
                        <Column justifyContentEnd>
                            <Toggle state={setting.value} labelMargin={"0rem"} handleClick={() => {handleSettingChange(index, !setting.value)}} />
                        </Column>
                    </Row>
                )
            })
        }
        </Wrapper>
    )
}

export default Admin;