export const MONTH_ARRAY = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const ITEM_CHECKLIST_OPTIONS = [
  {
    title: "Walls",
    value: "wall",
    isChecked: true,
  },
  {
    title: "Slabs",
    value: "Roof",
    isChecked: true,
  },
  {
    title: "Doors",
    value: "Door",
    isChecked: false,
  },
  {
    title: "Windows",
    value: "Window",
    isChecked: false,
  },
  {
    title: "Furniture",
    value: "Furniture",
    isChecked: true,
  },
  {
    title: "Staircases",
    value: "staircase",
    isChecked: false,
  },
];

export const STOREY_CHECKLIST_OPTIONS = [
  {
    title: "Storey 4",
    isChecked: true,
  },
  {
    title: "Storey 3",
    isChecked: true,
  },
  {
    title: "Storey 2",
    isChecked: false,
  },
  {
    title: "Storey 1",
    isChecked: false,
  },
  {
    title: "Storey -1",
    isChecked: true,
  },
  {
    title: "Storey -2",
    isChecked: false,
  },
  {
    title: "Storey -3",
    isChecked: true,
  },
];

export const SETTINGS_MENU_OPTIONS = [
  {
    isGroup: true,
    initalActiveItems: ["Textured"],
    list: [
      // {
      //   title: "Shaded",
      //   isActive: false,
      // },
      {
        title: "Textured",
        isActive: true,
      },
      {
        title: "Monochrome",
        isActive: false,
      }
    ]
  },
  {
    title: "Hidden Line",
    isActive: false,
  },
  {
    title: "Orthographic",
    isActive: false,
  }
];

const returnSubtitle = (customTab) => {
  switch (customTab) {
    case "Sustainability Metrics":
      return "";
    case "Areas":
      return "Export area calculation as spreadsheet";
    case "BOQ":
      return "Export BBQ as spreadsheet";
    case "Spaces":
      return null;
    case "Comments":
      return null;
    case "Views":
      return "Export views as images";
    default:
      return "";
  }
};

const EXPORT_SHARE_COMMON_BUTTON_LIST = (customTab) => [
  {
    title: `Export ${customTab}`,
    subtitle: returnSubtitle(customTab),
    option: `${customTab}`,
  },
  {
    title: "Export Model",
    subtitle: "Export model or drawings to any format",
    option: "Export",
  },
  {
    title: "Share Model",
    subtitle: "Share this project’s link with your team",
    option: "Share",
  },
];

export const EXPORT_SHARE_BUTTON_LIST = (customTab) => {
  if (!customTab || customTab === "Spaces" || customTab === "Comments")
    return [
      {
        title: "Export Model",
        subtitle: "Export model or drawings to any format",
        option: "Export",
      },
      {
        title: "Share Model",
        subtitle: "Share this project’s link with your team",
        option: "Share",
      },
    ];
  else return EXPORT_SHARE_COMMON_BUTTON_LIST(customTab);
};

export const SUNPATH_TOOLS = ["format", "graphical"];