import pointer from "../../assets/subMenuIcon/select/pointer.svg";
import optionSelector from "../../assets/subMenuIcon/select/optionSelector.svg";
import storeySelector from "../../assets/subMenuIcon/select/storeySelector.svg";
import checkboxSelector from "../../assets/subMenuIcon/select/checkboxSelector.svg";
import draw from "../../assets/subMenuIcon/draw/draw.svg";
import drawCircle from "../../assets/subMenuIcon/draw/drawCircle.svg";
import move from "../../assets/subMenuIcon/draw/move.svg";
import edit from "../../assets/subMenuIcon/draw/edit.svg";
import edit3d from "../../assets/subMenuIcon/draw/edit3d.svg";
import copy from "../../assets/subMenuIcon/draw/copy.svg";
import drawVoid from "../../assets/subMenuIcon/draw/drawVoid.svg";
import addLayer3d from "../../assets/subMenuIcon/draw/addvertex3d.svg";
import rotate from "../../assets/subMenuIcon/draw/rotate.svg";
import resize from "../../assets/subMenuIcon/draw/resize.svg";
import flipX from "../../assets/subMenuIcon/draw/flipX.svg";
import flipXY from "../../assets/subMenuIcon/draw/flipXY.svg";
import verticalResize from "../../assets/subMenuIcon/draw/verticalResize.svg";
import addLayer from "../../assets/subMenuIcon/draw/addLayer.svg";
import removeLayer from "../../assets/subMenuIcon/draw/removeLayer.svg";
import flipY from "../../assets/subMenuIcon/draw/flipY.svg";
import moveUp from "../../assets/subMenuIcon/draw/moveUp.svg";
import reorder from "../../assets/subMenuIcon/draw/reorder.svg";
import image from "../../assets/subMenuIcon/import/image.svg";
import file from "../../assets/subMenuIcon/import/file.svg";
import topo from "../../assets/subMenuIcon/import/topo.svg";
import cube from "../../assets/subMenuIcon/import/cube.svg";
import pdf from "../../assets/subMenuIcon/import/pdf.svg";
import speckleRevitImport from "../../assets/subMenuIcon/import/speckleRevitImport.svg";
import scaling from "../../assets/subMenuIcon/measure/scaling.svg";
import setdatum from "../../assets/subMenuIcon/measure/setdatum.svg";
import scale from "../../assets/subMenuIcon/measure/scale.svg";
import angle from "../../assets/subMenuIcon/measure/angle.svg";
import snapshot from "../../assets/subMenuIcon/views/snapshot.svg";
import screenshot from "../../assets/subMenuIcon/views/screenshot.svg";
import sectional from "../../assets/subMenuIcon/views/sectional.svg";
import frontView from "../../assets/subMenuIcon/views/frontView.svg";
import topView from "../../assets/subMenuIcon/views/topView.svg";
import backView from "../../assets/subMenuIcon/views/backView.svg";
import leftView from "../../assets/subMenuIcon/views/leftView.svg";
import rightView from "../../assets/subMenuIcon/views/rightView.svg";
import graphical from "../../assets/subMenuIcon/views/graphical.svg";
import format from "../../assets/subMenuIcon/views/format.svg";
import settings from "../../assets/subMenuIcon/views/settings.svg";
import render from "../../assets/subMenuIcon/views/render.svg";
import autoReorder from "../../assets/subMenuIcon/automate/autoReorder.svg";
import autoResize from "../../assets/subMenuIcon/automate/autoResize.svg";
import auto from "../../assets/subMenuIcon/automate/auto.svg";
import autoScale from "../../assets/subMenuIcon/automate/autoScale.svg";
import autoFormat from "../../assets/subMenuIcon/automate/autoFormat.svg";
import commenticon from "../../assets/icons/commentAdd.svg";
import brainstormicon from "../../assets/icons/brainstorm.svg";
import leafIcon from "../../assets/menu_icons/leaf_icon.svg";
import Areas from "../../assets/menu_icons/Areas.svg";
import ChangelogIcon from "../../assets/menu_icons/cost.svg";
import BOM from "../../assets/menu_icons/BOM.svg";
import Comments from "../../assets/menu_icons/comments.svg";
import Views from "../../assets/menu_icons/Views.svg";
import euiIcon from "../../assets/menu_icons/eui_icon.svg";
import daylightRecordsIcon from "../../assets/menu_icons/daylight_records_icon.svg";
import setAlignmentCoordinates from "../../assets/subMenuIcon/measure/setAlignmentCoordinates.svg";
import shadowsdropdownButton from "../../assets/icons/angularDropDown.svg";

import sunpathDiagram from "../../assets/subMenuIcon/analysis/sunpath_icon.svg";
import sdaAnalysis from "../../assets/subMenuIcon/analysis/sda_icon.svg";
import aseAnalysis from "../../assets/subMenuIcon/analysis/ase_icon.svg";
import euiTrigger from "../../assets/menu_icons/eui_icon.svg";
import daylightSetting from "../../assets/subMenuIcon/analysis/settings_icon.svg";

export const SELECT_SUB_MENU = [
  ["pointer", pointer],
  // ["optionSelector", optionSelector],
  ["storeySelector", storeySelector],
  ["checkboxSelector", checkboxSelector],
];
export const DRAW_2D_SUB_MENU = [
  ["draw", draw],
  ["drawCircle", drawCircle],
  ["move", move],
  ["edit", edit],
  ["copy", copy],
  ["rotate", rotate],
  // ["resize", resize],
  ["flipX", flipX],
  ["flipXY", flipXY],
  ["verticalResize", verticalResize],
  ["addLayer", addLayer],
  ["removeLayer", removeLayer],
  // ["flipY", flipY], // not implemented
  // ["drawVoid", drawVoid]
  // ["moveUp", moveUp],
  // ["reorder", reorder],
];
export const DRAW_3D_SUB_MENU = [
  ["draw", draw],
  ["drawCircle", drawCircle],
  ["move", move],
  ["pushpull", edit3d],
  ["copy", copy],
  ["rotate", rotate],
  // ["resize", resize],
  ["flipX", flipX],
  ["flipXY", flipXY],
  ["addLayer3d", addLayer3d],
  // ["flipY", flipY], // not implemented
  ["drawVoid", drawVoid],
  // ["moveUp", moveUp],
];
export const IMAGE_SUB_MENU = [
  ["image", image],
  ["file", file],
  ["topo", topo],
  ["cube", cube],
  ["importpdf", pdf],
  ["importRevit", speckleRevitImport],
];
export const MEASURE_SUB_MENU = [
  ["scaling", scaling],
  ["scale", scale, {permissionKey: "measure_distances"}],
  ["angle", angle, {permissionKey: "measure_angles"}],
  ["setdatum", setdatum],
  ["latlong", setAlignmentCoordinates],
];
export const MEASURE_3D_SUB_MENU = [
  // ["scaling", scaling],
  ["scale", scale, {permissionKey: "measure_distances"}],
  ["angle", angle, {permissionKey: "measure_angles"}],
  ["setdatum", setdatum]
];
export const VIEWS_SUB_MENU = [
  ["screenshot", screenshot],
  ["snapshot", snapshot],
  // ["sectional", sectional],
  ["frontView", frontView],
  ["topView", topView],
  ["backView", backView],
  ["leftView", leftView],
  ["rightView", rightView],
  ["graphical", graphical],
  ["shadowsdropdown", shadowsdropdownButton],
  ["render", render],
  ["settings", settings],
];
export const VIEWS_SUB_MENU_FOR_COVE_PROJECT = [
  ["screenshot", screenshot],
  ["snapshot", snapshot],
  // ["sectional", sectional],
  ["frontView", frontView],
  ["topView", topView],
  ["backView", backView],
  ["leftView", leftView],
  ["rightView", rightView],
  ["settings", settings],
];
export const AUTOMATE_SUB_MENU = [
  ["autoResize", autoResize],
  // ["auto", auto],
  // ["autoScale", autoScale],
  ["autoReorder", autoReorder],
  // ["autoFormat", autoFormat],
];
export const COLLABORATE_SUB_MENU = [
  ["commentmode", commenticon, {permissionKey: "add_comments"}],
  ["brainstormmode", brainstormicon, {permissionKey: "start_brainstorming_session"}]
]
export const ANALYSIS_SUB_MENU = [
  ["format", sunpathDiagram],
  ["analysis_sda", sdaAnalysis],
  ["analysis_ase", aseAnalysis],
  ["eui_trigger", euiTrigger],
  ["daylight_settings", daylightSetting]
]
export const TOP_RIGHT_ICONS = [
    ["Areas", Areas, {permissionKey: "view_area_statements"}],
    ["BOQ", BOM, {permissionKey: "view_boq"}],
    ["Changelog", ChangelogIcon],
    ["Comments", Comments, {permissionKey: "view_comment_history"}],
    ["Sustainability Metrics", leafIcon],
    ["Views", Views],
]

export const TOP_RIGHT_ICONS_RENDER = [
    ["Comments", Comments, {permissionKey: "view_comment_history"}],
    ["Views", Views],
]

export const TOP_RIGHT_ICONS_COVE = [
  ["Views", Views],
  ["Energy Use Intensity", euiIcon],
  ["Daylight Analysis History", daylightRecordsIcon],
]

export const RENDER_PAGE_ICONS = [
  // ["scale", scale],
  // ["angle", angle],
  // ["format", sunpathDiagram],  
  // ["graphical", graphical],
  // ["shadowsdropdown", shadowsdropdownButton],
]

export const COVE_TOOLS_ICONS = [
  ["scale", scale],
  ["angle", angle],
  ["format", sunpathDiagram],  
  ["graphical", graphical],
  ["shadowsdropdown", shadowsdropdownButton],
]