import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import ScrollableDropdown from "../../components/ScrollableDropdown/index";
import Toggle from "../../components/Toggle";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import {useSelector} from "react-redux";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1.3125rem;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .screenshot {
    width: 8.1875rem;
    height: 4.6rem;
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    border: 2px solid ${colors.white};
    border-radius: 8px;
    cursor: pointer;
  }
  .active {
    border: 2px solid ${props => props.theme.colors.borderRed}
  }
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    color: ${colors.thinGray};
  }
  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
`;

export const ViewsModal = (props) => {
  const { customProps } = props;
  const { dropdownList, screenshotList } = customProps;
  const [allScreenshots, setAllScreenshots] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const screenshotUiDataRedux = useSelector(state => state.screenshotUiData);
  
  useEffect(() => {
    const newAllScreenShots = screenshotUiDataRedux.sceneViewData.map((screenshot) => {
      return {
        ...screenshot,
        highlighted: false,
      }
    })
    store.$scope.screenshotsSelectedInUI = newAllScreenShots;
    setAllScreenshots(newAllScreenShots);
  }, [screenshotUiDataRedux])
  
  const toggleIsHighlighted = (index) => {
    const selectedScreenshots = allScreenshots.slice();
    selectedScreenshots[index].highlighted = !selectedScreenshots[index].highlighted;
    let newSelectAll = selectedScreenshots.reduce((accumlator, currentValue) => {
      return accumlator && currentValue.highlighted;
    }, true);
    setSelectAll(newSelectAll);
    store.$scope.screenshotsSelectedInUI = selectedScreenshots;
    setAllScreenshots(selectedScreenshots);
  }
  
  const toggleSelectAll = () => {
    const highlight = !selectAll;
    const newAllScreenShots = screenshotList.map((screenshot) => {
      return {
        ...screenshot,
        highlighted: highlight,
      }
    })
    store.$scope.screenshotsSelectedInUI = newAllScreenShots;
    setAllScreenshots(newAllScreenShots);
    setSelectAll(highlight);
  }

  return (
    <Container>
      <StyledDiv>
        <StyledDiv
          style={{
            overflowX: "scroll",
            maxHeight: "9.375rem",
            flexWrap: "nowrap",
          }}
        >
          {allScreenshots &&
            allScreenshots.map((item, index) => {
              return (
                <div key={index} style={{ padding: "1rem 0" }}>
                  <div onClick={() => {toggleIsHighlighted(index)}} 
                    className={`screenshot ${item.highlighted ? "active" : ""}`}
                    style={{ marginRight: "0.5rem", background: `url(${item.path})`, backgroundSize: "contain"}}
                  >
                    {/* <img src={item.screenshot} alt="ss" /> */}
                  </div>
                  {item.screenshotTitle && <p>{item.screenshotTitle}</p>}
                </div>
              );
            })}
        </StyledDiv>
        {dropdownList &&
          dropdownList.map((item, index) => {
            return (
              <TopContainer
                key={index}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <TopContainerLabel>{item.label}</TopContainerLabel>
                <ScrollableDropdown
                  options={item.options}
                  height={item.height || "auto"}
                  width="auto"
                  optionTextStyle={{ padding: "0.125rem 0.5rem" }}
                  cutomTextStyle={{
                    padding: "0.4375rem",
                    fontWeight: "bold",
                    color: colors.primeBlack,
                  }}
                  onChange = {(newValue) => {item.onChange(newValue)}}
                />
              </TopContainer>
            );
          })}
          <TopContainer
            style={{
              justifyContent: "space-between",
            }}
          >
            <TopContainerLabel>Select All</TopContainerLabel>
            <Toggle state={selectAll} handleClick={toggleSelectAll} />
          </TopContainer>
      </StyledDiv>
    </Container>
  );
};
