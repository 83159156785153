import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { assignProperties } from "../../libs/sceneStateFuncs.js";
import { click } from "../../libs/meshEvents.js";
import { labelView } from "../../libs/labelView.js";
import { perpendicularLineToEdge } from "../../libs/snapFuncs.js";
import {
  slopeOfLine,
  lineWithSlopeAurAtDistanceFromPoint,
} from "../../libs/twoD/twoServices.js";
import {
  getActiveStoreyObject,
  convertLocalCoordsToGlobal,
  convertGlobalCoordsToLocal,
  onSolid,
  deepCopyObject,
  changeHeightOfObjects,
} from "../extrafunc.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import {
  plainRoofParameters,
  getOffsetValues,
} from "../factoryTypes/roof.types.js";
import { ResolveEngineUtils } from "../wallEngine/resolveEngine.js";
import { copyBrep } from "../../libs/brepOperations.js";
import { meshObjectMapping } from "./mapping.js";
import { WALL_NOTIFY_FUNCTIONS } from "./wall.ds.js";
import { SnapProperty } from "../../libs/GUI/snap_properties.js";
import { SnapElement } from "../../libs/GUI/snap_element.js";
import {
  overhangRoof,
  toggleRoofVisibility,
} from "../../libs/roofVisibilityFuncs.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { floorProperties } from "../../libs/objectProperties.js";
import { BasicRoofComp } from "../../libs/basicCompProperties.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import { userSetBIMPropertiesHandler } from "../utilityFunctions/CONSTANTS/userSetBimPropertiesHandler.js";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService.js";
import { StructureCollection } from "./structure.ds.js";
/**
 * { Roof datastructure }
 *
 * @class      Roof (name)
 * @param      {<type>}  mesh    The mesh
 */
var Roof = function (mesh) {
  this.id = "rf_" + Math.random().toString(36).substr(2, 9);
  this.mesh = mesh;
  this.properties = {};
  this.level = 0; //TODO
  this.structure = 0; //TODO
  this.type = "Roof";
  this.room_id = null;
  this.roof_pol_offset_top = null;
  this.roof_pol_offset_bottom = null;
  this.roof_pol_bottom = null;
  this.roof_pol_top = null;
  this.offset = null;
  this.storey = mesh.storey;
  this.height = Math.abs(mesh.height);
  this.baseHeight = mesh.baseHeight;
  this.faceFacetMapping = mesh.faceFacetMapping;
  this.typeChangeAllowed = false;
  this.mesh.name = "Roof";
  this.mesh.type = "Roof";
  this.mesh.checkCollisions = true;
  this.mesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  this.mesh.level = mesh.level;
  this.edited = false;
  this.brep = mesh.brep;
  this.isLocked = false;
  this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  this.revitMetaData = {};

  this.setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  let bbinfo = this.mesh.getBoundingInfo();
  let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  this.slabType = "Intermediate Slab";
  this.overhangDisabled = false;
  // this.mesh.setPivotPoint(centroid);
  
  if(!this.mesh.room_type){
    this.mesh.room_type = snaptrudeDSCount.getLabel("Slab");
  }
  

  delete mesh.brep;
  delete mesh.height;
  delete mesh.baseHeight;
  delete mesh.faceFacetMapping;

  assignProperties(this.mesh, -1, "roof");
  click(this.mesh);

  this.assignProperties = function (options) {
    this.properties = _.cloneDeep(floorProperties);
    this.properties._type = "Roof";
    this.properties._rooms.push(mesh.room_name);
    this.properties._id = mesh.room_id;
    this.properties._level = mesh.level;
    this.properties._indices = mesh.room_path;
    this.properties._curve = mesh.room_curve;
    this.properties._components = options?.floorComponent ? options.floorComponent : BasicRoofComp
    if (!this.mesh.sourceMesh && !this.mesh.material) {
      // this.mesh.convertToFlatShadedMesh();
      this.mesh.material = store.scene.getMaterialByName("ceiling_mat");
      this.mesh.material.backFaceCulling = false;
      this.mesh.visibility = 1.0;
    }
    this.mesh.childrenComp = [];
  };

  this.onMove = {
    onPointerDown: function (evt) {
      //pass
    },

    onPointerUp: function (evt) {
      //pass
    },

    onPointerMove: function (direction, diff, speed) {
      this.mesh.position[direction] += diff[direction] * speed;
      if (this.mesh.name.indexOf("boxScale") !== -1) {
        this.mesh.parentMesh.position = this.mesh.position;
      }
    },
  };
};

/**
 * Method to compute if the geometry of the roof is changed
 */
Roof.prototype.getGeometryChangeBoolean = function () {
  if (labelView) {
    let edgePoints1 = labelView._getEdgePoints(
      this.mesh,
      new BABYLON.Vector3(0, 1, 0)
    );
    let edgePoints2 = labelView._getEdgePoints(
      this.mesh,
      new BABYLON.Vector3(0, -1, 0)
    );
    if (edgePoints1.length !== edgePoints2.length) {
      return true;
    }
    let thickness = edgePoints1[0].y - edgePoints2[0].y;
    for (let i = 1; i < edgePoints1.length; i++) {
      let thick = edgePoints1[i].y - edgePoints2[i].y;
      if (Math.abs(thick - thickness) > 1e-3) {
        return true;
      }
    }
    return false;
  }
  return false;
};

/**
 * Updates the thickness in the layers of the roof
 * Invoke this function before material schedule is calculated or
 * When the roof's layers are accessed
 */
Roof.prototype.setThickness = function () {
  /*
    TODO: write the logic to compute the thickness
     */
  if (this.getGeometryChangeBoolean()) {
    this.properties._components._layers.forEach(function (layer) {
      if (layer.core) {
        layer.thickness = "Variable";
      }
    });
    return;
  }

  if (labelView) {
    let edgePoints;
    try {
      edgePoints = labelView._getEdgePoints(
        this.mesh,
        new BABYLON.Vector3(1, 0, 0)
      );
    } catch (err) {
      this.properties._components._layers.forEach(function (layer) {
        if (layer.core) {
          layer.thickness = "Variable";
        }
      });
      return;
    }

    let thickness = 1000000;
    let variableThickness = false;
    for (let i = 0; i < edgePoints.length; i++) {
      let pt1 = new BABYLON.Vector2(edgePoints[i].y, edgePoints[i].z);
      let pt2 = new BABYLON.Vector2(
        edgePoints[(i + 1) % edgePoints.length].y,
        edgePoints[(i + 1) % edgePoints.length].z
      );
      let perpendicularPoints1 = perpendicularLineToEdge(pt1, pt1, pt2);
      let perpendicularPoints2 = perpendicularLineToEdge(pt2, pt1, pt2);

      let slop1 = slopeOfLine([
        perpendicularPoints1[0].asArray(),
        perpendicularPoints1[1].asArray(),
      ]);
      let slop2 = slopeOfLine([
        perpendicularPoints2[0].asArray(),
        perpendicularPoints2[1].asArray(),
      ]);
      let slope1 = -1 / slop1 === "inf" ? 0 : slop1;
      let slope2 = -1 / slop2 === "inf" ? 0 : slop2;

      let points1 = lineWithSlopeAurAtDistanceFromPoint(
        pt1.asArray(),
        slope1.toString().includes("Inf") ? "inf" : slope1,
        1
      );
      let points2 = lineWithSlopeAurAtDistanceFromPoint(
        pt2.asArray(),
        slope2.toString().includes("Inf") ? "inf" : slope2,
        1
      );

      let storeyObject = getActiveStoreyObject(store.activeLayer.storey);
      let st = null;
      let end = null;
      if (slop1 === 0) {
        st = new BABYLON.Vector3(
          points1[1][0],
          storeyObject.height,
          points1[1][1]
        );
        end = new BABYLON.Vector3(
          points2[1][0],
          storeyObject.height,
          points2[1][1]
        );
      } else if (slop1 === "inf") {
        st = new BABYLON.Vector3(
          points1[0][0],
          storeyObject.height,
          points1[0][1]
        );
        end = new BABYLON.Vector3(
          points2[0][0],
          storeyObject.height,
          points2[0][1]
        );
      } else {
        st = new BABYLON.Vector3(
          points1[1][0],
          storeyObject.height,
          points1[1][1]
        );
        end = new BABYLON.Vector3(
          points2[1][0],
          storeyObject.height,
          points2[1][1]
        );
      }
      let thick = BABYLON.Vector3.Distance(st, end);
      if (thick < thickness) {
        thickness = thick;
      }
    }

    if (this.properties) {
      if (this.properties._components) {
        if (thickness !== 1000000) {
          this.properties._components._layers.forEach(function (layer) {
            if (layer.core) {
              layer.thickness =
                DisplayOperation.convertDimensionTo(thickness).mMts;
            }
          });
          this.properties._components._thickness =
            DisplayOperation.convertDimensionTo(thickness).mMts;
          return this.properties._components._thickness;
        }
      }
    }
  }
};

/**
 * Make object visible in store.scene
 */
Roof.prototype.show = function () {
  if (this.hiddenByStorey) {
    this.mesh.isVisible = true;
    this.hiddenByStorey = false;
  }
};
/**
 * Hides object in store.scene
 */
Roof.prototype.hide = function () {
  if (this.mesh.isVisible) {
    this.mesh.isVisible = false;
    this.hiddenByStorey = true;
  }
};

Roof.prototype.isIntermediateSlab = function () {
  return this.slabType === "Intermediate Slab";
};

Roof.prototype.isBasementSlab = function () {
  return this.slabType === "Basement Slab";
};

Roof.prototype.isPlinth = function () {
  return this.slabType === "Plinth";
};

Roof.prototype.isTypeChangeAllowed = function () {
  return this.typeChangeAllowed;
};

Roof.prototype.allowTypeChange = function () {
  this.typeChangeAllowed = true;
};

Roof.prototype.disallowTypeChange = function () {
  this.typeChangeAllowed = false;
};

Roof.prototype.markAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = true;
  else this.edited = true;
};
Roof.prototype.removeAsEdited = function () {
  if (this.mesh.isAnInstance)
    this.mesh.sourceMesh.getSnaptrudeDS().edited = false;
  else this.edited = false;
};
Roof.prototype.isEdited = function () {
  if (this.mesh.isAnInstance)
    return this.mesh.sourceMesh.getSnaptrudeDS().edited;
  else return this.edited;
};
Roof.prototype.updateBase = function (heightDifference) {
  function isOfBalconyHeightOrLess() {
    let boundingBox = this.mesh.getBoundingInfo().boundingBox;
    return (
      boundingBox.extendSizeWorld.y * 2 <=
      StoreyMutation._CONSTANTS.balconyHeight
    );
  }

  // if (!(!isOfBalconyHeightOrLess.call(this) && this.isEdited())) {
  //     this.mesh.position.y += heightDifference;
  // }
  if (this.storey > 0) {
    this.mesh.position.y += heightDifference;
  } else {
    this.mesh.position.y -= heightDifference;
  }
};
Roof.prototype.adjustHeight = function (pol_bottom, pol_top, midY = 0) {
  let verData = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let bbinfo = this.mesh.getBoundingInfo();
  if (!midY) {
    midY = (bbinfo.maximum.y + bbinfo.minimum.y) / 2;
  }
  for (let i = 0; i < verData.length; i += 3) {
    if (verData[i + 1] < midY) {
      for (let j = 0; j < pol_bottom.length; j++) {
        if (
          Math.abs(verData[i] - pol_bottom[j][0]) < 0.1 &&
          Math.abs(verData[i + 2] - pol_bottom[j][1]) < 0.1
        ) {
          verData[i + 1] = pol_bottom[j][2];
          break;
        }
      }
    } else {
      for (let j = 0; j < pol_top.length; j++) {
        if (
          Math.abs(verData[i] - pol_top[j][0]) < 0.1 &&
          Math.abs(verData[i + 2] - pol_top[j][1]) < 0.1
        ) {
          verData[i + 1] = pol_top[j][2];
          break;
        }
      }
    }
  }
  this.mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, verData);
};

Roof.prototype.isOverhangDisabled = function () {
  const roof = this.getDataStore();
  return roof.overhangDisabled;
};

Roof.prototype.enableOverhang = function () {
  const roof = this.getDataStore();
  roof.overhangDisabled = false;
};

Roof.prototype.disableOverhang = function () {
  const roof = this.getDataStore();
  roof.overhangDisabled = true;
};

Roof.prototype.getDataStore = function (){
  return this.mesh.isAnInstance ? this.mesh.sourceMesh.getSnaptrudeDS() : this;
};

Roof.prototype.getRoofOffset = function () {
  const roof = this.getDataStore();
  return roof.offset;
};

Roof.prototype.getRoofPolBottom = function () {
  const roof = this.getDataStore();
  return roof.roof_pol_bottom;
};

Roof.prototype.getRoofPolTop = function () {
  const roof = this.getDataStore();
  return roof.roof_pol_top;
};

Roof.prototype.getRoofPolOffsetBottom = function () {
  const roof = this.getDataStore();
  return roof.roof_pol_offset_bottom;
};

Roof.prototype.getRoofPolOffsetTop = function () {
  const roof = this.getDataStore();
  return roof.roof_pol_offset_top;
};

Roof.prototype.setRoofOffset = function (value) {
  const roof = this.getDataStore();
  roof.offset = value;
};

Roof.prototype.setRoofPolBottom = function (value) {
  const roof = this.getDataStore();
  roof.roof_pol_bottom = value;
};

Roof.prototype.setRoofPolTop = function (value) {
  const roof = this.getDataStore();
  roof.roof_pol_top = value;
};

Roof.prototype.setRoofPolOffsetBottom = function (value) {
  const roof = this.getDataStore();
  roof.roof_pol_offset_bottom = value;
};

Roof.prototype.setRoofPolOffsetTop = function (value) {
  const roof = this.getDataStore();
  roof.roof_pol_offset_top = value;
};

Roof.prototype.overhang = function (offset, forced = false) {
  function _isVector3InArray(array, vector3) {
    let res = -1;
    array.some(function (elem, index) {
      if (elem.almostEquals(vector3, plainRoofParameters.proximityThreshold)) {
        res = index;
        return true;
      }
    });
    return res;
  }

  if (isNaN(offset)) return;
  if (offset === null) offset = 0;
  if (offset === this.offset) return;
  if (this.isOverhangDisabled() && !forced) return;

  this.mesh.disableEdgesRendering();

  let brep = this.brep;

  let roof_pol_global_top = convertLocalCoordsToGlobal(
    this.getRoofPolTop(),
    this.mesh.getWorldMatrix(),
    false,
    false
  );
  let roofPolOffsetTop = getOffsetValues(roof_pol_global_top, offset);
  roofPolOffsetTop = convertGlobalCoordsToLocal(
    roofPolOffsetTop,
    this.mesh.getWorldMatrix(),
    false,
    false
  );

  let roof_pol_global_bottom = convertLocalCoordsToGlobal(
    this.getRoofPolBottom(),
    this.mesh.getWorldMatrix(),
    false,
    false
  );
  let roofPolOffsetBottom = getOffsetValues(roof_pol_global_bottom, offset);
  roofPolOffsetBottom = convertGlobalCoordsToLocal(
    roofPolOffsetBottom,
    this.mesh.getWorldMatrix(),
    false,
    false
  );

  let pol_top = [];
  let pol_bottom = [];

  // this.roof_pol_offset_bottom.forEach.call(this, function (vertex, index) {
  
  const currentRoofPolOffsetBottom = this.getRoofPolOffsetBottom();
  const currentRoofPolOffsetTop = this.getRoofPolOffsetTop();
  for (
    let index = 0, vertex = currentRoofPolOffsetBottom[index];
    index < currentRoofPolOffsetBottom.length;
    index++, vertex = currentRoofPolOffsetBottom[index]
  ) {
    pol_bottom.push(new BABYLON.Vector3(vertex[0], vertex[1], vertex[2]));
    vertex = currentRoofPolOffsetTop[index];
    pol_top.push(new BABYLON.Vector3(vertex[0], vertex[1], vertex[2]));
  }

  brep.positions.forEach((p, i) => {
    let vertex = BABYLON.Vector3.FromArray(p);

    let index = _isVector3InArray(pol_top, vertex);
    if (index !== -1) {
      brep.positions[i] = roofPolOffsetTop[index];
    } else {
      let index = _isVector3InArray(pol_bottom, vertex);
      if (index !== -1) {
        brep.positions[i] = roofPolOffsetBottom[index];
      }
    }
  });
  
  this.setRoofOffset(offset);
  this.setRoofPolOffsetTop(roofPolOffsetTop);
  this.setRoofPolOffsetBottom(roofPolOffsetBottom);

  this.mesh.BrepToMesh();

  onSolid(this.mesh);

  return true;
};

/*Roof.prototype.overhang = function (offset) {

    let roofAngleThreshold = 45;

    if (isNaN(offset)) return;
    if (offset === this.offset) return;

    let brep = this.brep;

    brep.getFaces().forEach(face => {

        let facetId = this.faceFacetMapping[face.getIndex()][0];
        let faceVertices = getFaceVerticesFromFace(face, this.mesh, BABYLON.Space.WORLD);
        let unitNormalVector = getUnitNormalVectorV3(faceVertices);

        if (!unitNormalVector) return;

        let angleNormalMakesWithZ = getAngleBetweenVectors(unitNormalVector, BABYLON.Vector3.Up().negate());

        if ((angleNormalMakesWithZ < roofAngleThreshold) || (angleNormalMakesWithZ > 180 - roofAngleThreshold)) {

        }
        else {
            // lateral face
            let options = {
                component: this,
                faceVertices,
                facetId,
                movementAmount: offset - this.offset,
                saveResults: false,
                unitNormalVector
            };

            moveFace.justMoveIt(options);
        }
    });

    this.offset = offset;

};*/

Roof.prototype.updateRoofOutline = function (oldPositions, newPositions) {
  // let positions = this.roof_pol_bottom;
  let positions_bottom = this.getRoofPolOffsetBottom();
  let positions_top = this.getRoofPolOffsetTop();
  let util = new ResolveEngineUtils();

  oldPositions.forEach((oldPosition, i) => {
    let bottomPoint = false;
    positions_bottom.some((position, index) => {
      if (util.areArraysAlmostEqual(position, oldPosition)) {
        positions_bottom[index] = newPositions[i];
        bottomPoint = true;
        return true;
      }
    });
    if (!bottomPoint) {
      positions_top.some((position, index) => {
        if (util.areArraysAlmostEqual(position, oldPosition)) {
          positions_top[index] = newPositions[i];
          return true;
        }
      });
    }
  });

  // let overhang = this.offset - h_external/10;
  // let overhang = this.offset;
  // let roof_pol_offset_global = convertLocalCoordsToGlobal(positions, this.mesh.getWorldMatrix(), false, true);

  // let roof_pol_bottom_global = getOffsetValues(roof_pol_offset_global, -overhang);
  // this.roof_pol_bottom = convertGlobalCoordsToLocal(roof_pol_bottom_global, this.mesh.getWorldMatrix(), true, false);
};

Roof.prototype.cloneProperties = function (otherRoof, unique) {
  
  otherRoof.setRoofPolBottom(this.getRoofPolBottom());
  otherRoof.setRoofPolTop(this.getRoofPolTop());
  otherRoof.setRoofPolOffsetBottom(this.getRoofPolOffsetBottom());
  otherRoof.setRoofPolOffsetTop(this.getRoofPolOffsetTop());
  otherRoof.setRoofOffset(this.getRoofOffset());
  
  otherRoof.baseHeight = this.baseHeight;
  otherRoof.typeChangeAllowed = this.typeChangeAllowed;
  otherRoof.slabType = this.slabType;
  // otherRoof.room_id = this.room_id;

  if (unique) {
    otherRoof.brep = copyBrep(this.brep);
    otherRoof.faceFacetMapping = deepCopyObject(this.faceFacetMapping);
  } else {
    otherRoof.brep = this.brep;
    otherRoof.faceFacetMapping = this.faceFacetMapping;
  }
};

/**
 * Remove a mass to level.
 *
 * @param      {<type>}  object  The object
 */
Roof.prototype.removeRoofFromLevel = function (level) {
  meshObjectMapping.deleteMapping(this.mesh);
  for (let i = 0; i < level.flyweight.roofs.length; i++) {
    if (this.mesh.uniqueId === level.flyweight.roofs[i].mesh.uniqueId) {
      level.flyweight.roofs.splice(i, 1);
      return;
    }
  }
};
/**
 * Notifies all.
 */
Roof.prototype.notifyAll = function (action) {
  if (action === "resetlevel") {
    WALL_NOTIFY_FUNCTIONS.resetLevel.call(this);
  }
};

Roof.prototype.getMeshObjectProperties = function () {
  let props = [];
  let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);
  props.push(
    new SnapProperty(
      "Category",
      "Slab",
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Label",
      this.mesh.room_type,
      SnapElement.getInputElement(
        (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
        true
      )
    )
  );
  //props.push(new SnapProperty("Type",'roof1',SnapElement.getDropDown(['roof1','roof22','roof3','roof4'],(meshes,name) => objectPropertiesView.onTypeChange(this.mesh,name),true)));
  if(this.revitMetaData.floorType){
    props.push(
      new SnapProperty(
        "Slab Type",
        this.revitMetaData.floorType,
        SnapElement.getInputElement(null, false)
      )
    );
  }

  if (this.slabType !== "Plinth")
    props.push(
      new SnapProperty(
        "Sub - Category",
        this.slabType,
        SnapElement.getDropDown(
          this.getSlabTypeOptions(),
          (meshes, value) => this.changeSlabType(value),
          true
        )
      )
    );
  else
    props.push(
      new SnapProperty(
        "Sub - Category",
        this.slabType,
        SnapElement.getInputElement(null, false)
      )
    );

  props.push(
    new SnapProperty(
      "Thickness",
      meshDimensions.height,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange(
            "height",
            value,
            meshDimensions.height
          ),
        true
      )
    )
  );
  if (this.slabType === "Intermediate Slab" && !this.isOverhangDisabled()) {
    props.push(
      new SnapProperty(
        "Overhang",
        DisplayOperation.convertToDefaultDimension(this.offset),
        SnapElement.getInputElement((mesh, value) => overhangRoof(value), true)
      )
    );
  } else if (
    ["Basement Slab", "Plinth"].includes(this.slabType) ||
    this.isOverhangDisabled()
  ) {
    props.push(
      new SnapProperty(
        "Overhang",
        DisplayOperation.convertToDefaultDimension(this.offset),
        SnapElement.getInputElement(null, false)
      )
    );
  }
  props.push(
    new SnapProperty(
      "Hide",
      !this.mesh.isVisible,
      SnapElement.getCheckBox(
        (mesh, value) => toggleRoofVisibility(this.mesh, value),
        true
      )
    )
  );
  let storey = StructureCollection.getInstance().getStructureById(this.mesh.structure_id).getStoreyData().getStoreyByValue(this.mesh.storey).name;
  let storeyValue = storey? storey : this.mesh.storey;
  props.push(
    new SnapProperty(
      "Storey",
      storeyValue,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Surface Area",
      meshDimensions.area,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Volume",
      meshDimensions.volume,
      SnapElement.getInputElement(null, false)
    )
  );
  props.push(
    new SnapProperty(
      "Length",
      meshDimensions.breadth,
      SnapElement.getInputElement(
        (meshs, value) => objectPropertiesView.dimensionChange("width", value),
        false
      )
    )
  );
  props.push(
    new SnapProperty(
      "Width",
      meshDimensions.depth,
      SnapElement.getInputElement(
        (meshs, value) =>
          objectPropertiesView.dimensionChange("thickness", value),
        false
      )
    )
  );
  props.push(new SnapProperty("object-buttons", this.mesh, null, true));

  return props;
};

Roof.prototype.getSlabTypeOptions = function () {
  return ["Intermediate Slab", "Basement Slab"];
};

Roof.prototype.changeSlabType = function (newType, options = {}) {
  // if(_.isNil(options.executeCommand)) options.executeCommand = true;
  if (this.slabType === "Plinth") return;

  if (newType === this.slabType) return;

  let optionsForPropertyChange = {
    componentKeys: ["slabType"],
  };
  let propertyChangeCommandData =
    commandUtils.propertyChangeOperations.getCommandData(
      this.mesh,
      optionsForPropertyChange
    );

  this.slabType = newType;

  // if(newType == "Basement Slab"){
  //     this.slabType = "Basement Slab"
  // }
  // else if(newType == "Intermediate Slab"){
  //     this.slabType = "Intermediate Slab"
  // }

  optionsForPropertyChange.data = propertyChangeCommandData;
  propertyChangeCommandData =
    commandUtils.propertyChangeOperations.getCommandData(
      this.mesh,
      optionsForPropertyChange
    );
  let propertyChangeCommand = [
    commandUtils.propertyChangeOperations.getCommand(
      "slabTypeChange",
      propertyChangeCommandData
    ),
  ];
  let yetToExecute = [true];
  let command = {
    commands: propertyChangeCommand,
    yetToExecutes: yetToExecute,
  };
  let overHangOptions = { command: command, codeInitiated: true };

  if (newType === "Basement Slab") {
    overhangRoof(0, this.mesh, overHangOptions);
  } else if (newType === "Intermediate Slab") {
    let projectOverhang = parseFloat(
      DisplayOperation.convertToDefaultDimension(
        store.userSettingsInStructure["roofOverhang"]
      )
    );
    overhangRoof(projectOverhang, this.mesh, overHangOptions);
  }
};

Roof.isPlinth = function (mesh) {
  return mesh.type.toLowerCase() === "roof" && mesh.slabType === "Plinth";
};

// This function is used to set the slab type in processes. It does not create or execute a command
// Is supposed to be used during creation type places so that the command execution and data is handled at such places.
Roof.prototype.setSlabType = function () {
  this.computeSlabType();
  switch (this.slabType) {
    case Roof.CONSTANTS().SLAB_TYPES.INTERMEDIATE: {
      if (this.isOverhangDisabled()) {
        this.overhang(0);
      } else {
        this.overhang(
          userSetBIMPropertiesHandler.getRoofOverhang()
        );
      }
      break;
    }
    case Roof.CONSTANTS().SLAB_TYPES.BASEMENT: {
      this.overhang(0);
      break;
    }
    case Roof.CONSTANTS().SLAB_TYPES.PLINTH: {
      let oldSlabThickness = DisplayOperation.getOriginalDimension(
        objectPropertiesView.getMeshDimensions(this.mesh).height
      );
      changeHeightOfObjects([this.mesh], {
        currentHeightInBabylonUnits: oldSlabThickness,
        newHeightInBabylonUnits:
          store.projectProperties.properties.plinthHeightProperty.getValue(),
      });
      this.slabType = "Plinth";
      // DisplayOperation.updateDimensionScale(projectProperties.properties.plinthHeightProperty.getValue(), "height", oldSlabThickness, this.mesh, {noCommand: true});
      this.overhang(0);
      break;
    }
  }
};

Roof.prototype.computeSlabType = function () {
  let _checkBottomMostSlab = function (slabMesh) {
    slabMesh.position.y -= 0.1;
    slabMesh.computeWorldMatrix(true);
    let intersection = store.scene.meshes.some((mesh) => {
      if (
        mesh.storey === -1 &&
        ["wall", "mass"].includes(mesh.type.toLowerCase())
      ) {
        return slabMesh.intersectsMesh(mesh);
      }
    });
    slabMesh.position.y += 0.1;
    return !intersection;
  };

  StoreyMutation.assignStorey(this);
  if (this.storey > 1) this.slabType = "Intermediate Slab";
  else if (this.storey === 1 && _checkBottomMostSlab(this.mesh))
    this.slabType = "Plinth";
  else this.slabType = "Basement Slab";

  return this.slabType;
};

Roof.CONSTANTS = function () {
  return {
    SLAB_TYPES: {
      TYPES: ["Intermediate Slab", "Basement Slab", "Plinth"],
      INTERMEDIATE: "Intermediate Slab",
      BASEMENT: "Basement Slab",
      PLINTH: "Plinth",
    },
  };
};
export { Roof };
