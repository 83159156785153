import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../../themes/constant";
import useOnClickOutside from "../../../../common/utils/onOutsideClickHandler";

const MainWrapper = styled.div`
  padding: 0.3125rem 0.625rem;
  background: ${colors.fullWhite};
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  border-radius: 8px;
  border-bottom-left-radius: 0;
  width: 4.625rem;
`;

const EachRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Value = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.192708px;
  color: ${colors.thinGray};
  margin: 0.3125rem 0;
  text-align: right;
`;

const data = ["Last Hidden", "All", "Select Hidden"];

const LastStatusModal = (props) => {
  const { closePopUp } = props;
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (closePopUp) closePopUp();
  });
  return (
    <MainWrapper ref={ref}>
      {data.map((item, index) => {
        return (
          <EachRow key={index}>
            <Value>{item}</Value>
          </EachRow>
        );
      })}
    </MainWrapper>
  );
};

export default LastStatusModal;
