import React from "react";
import EditMenu from "../../../../components/rightMenus/editMenuInputs.js";

export const WallDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Wall",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        { title: "Height", value: "2750", type: "input" },
        { title: "Length", value: "18000", type: "input" },
        { title: "Thickness", value: "200", type: "input" },
        { title: "Storey", value: "5", type: "fixedValue" },
        { title: "Area", value: "16,724.32", type: "fixedValue" },
        { title: "Volume", value: "16,724.32", type: "fixedValue" },
      ]}
      buttonsData={[
        { title: "Edit Structure" },
        { title: "Match Properties" },
        { title: "Make Unique" },
      ]}
    />
  );
};

export const DoorDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Door",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        {
          title: "Type",
          value: "1200 x 2100 Swing 2 shutter 2 panel",
          type: "fixedValue",
          align: "left",
          padding: "0 0 1.0625rem 0",
        },
        { title: "Width", value: "1200", type: "input" },
        { title: "Lintel height", value: "2100", type: "input" },
        {
          title: "Storey",
          value: "5",
          type: "fixedValue",
        },
      ]}
      buttonsData={[
        { title: "Edit Structure" },
        { title: "Match Properties" },
        { title: "Make Unique" },
      ]}
    />
  );
};

export const WindowDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Window",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        {
          title: "Type",
          value: "1200 x 2100 Swing 2 shutter 2 panel",
          type: "fixedValue",
          align: "left",
          padding: "0 0 1.0625rem 0",
        },
        { title: "Width", value: "1200", type: "input" },
        { title: "Height", value: "1500", type: "input" },
        { title: "Sill height", value: "900", type: "input" },
        {
          title: "Storey",
          value: "5",
          type: "fixedValue",
        },
      ]}
      buttonsData={[
        { title: "Edit Structure" },
        { title: "Match Properties" },
        { title: "Make Unique" },
      ]}
    />
  );
};

export const SlabDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Slab",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        {
          title: "Type",
          options: ["Roof Slab", "Intermediate Slab", "Grade Slab", "Plinth"],
          type: "dropdown",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        { title: "Thickness", value: "150", type: "input" },
        { title: "Overhang", value: "0", type: "input" },
        {
          title: "Storey",
          value: "5",
          type: "fixedValue",
        },
        { title: "Area", value: "16,724.32", type: "fixedValue" },
        { title: "Volume", value: "16,724.32", type: "fixedValue" },
      ]}
      buttonsData={[
        { title: "Edit Structure" },
        { title: "Match Properties" },
        { title: "Make Unique" },
      ]}
    />
  );
};

export const FurnitureDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Furniture",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        {
          title: "Type",
          value: "1800 3 seater sofa",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.4375rem 0",
        },
        { title: "Length", value: "1800", type: "input" },
        { title: "Width", value: "1200", type: "input" },
        { title: "Height", value: "750", type: "input" },
        { title: "Storey", value: "5", type: "fixedValue" },
        {
          title: "Snap To",
          options: ["Floor", "Walls", "None"],
          type: "dropdown",
          align: "left",
        },
      ]}
      buttonsData={[{ title: "Match Properties" }]}
    />
  );
};

export const FlooringDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Flooring",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.375rem 0",
        },
        { title: "Thickness", value: "25", type: "input" },
        { title: "Storey", value: "5", type: "fixedValue" },
        { title: "Area", value: "16,724.32", type: "fixedValue" },
        { title: "Volume", value: "16,724.32", type: "fixedValue" },
      ]}
      buttonsData={[
        { title: "Edit Structure" },
        { title: "Match Properties" },
        { title: "Make Unique" },
      ]}
    />
  );
};

export const StaircaseDimensionEdit = (props) => {
  return (
    <EditMenu
      closePopUp={props.closePopUp}
      data={[
        {
          title: "Category",
          value: "Staircase",
          type: "fixedValue",
          align: "left",
          padding: "0 0 0.375rem 0",
        },
        { title: "Riser", value: "150", type: "input" },
        { title: "Tread", value: "300", type: "input" },
        { title: "Flight Width", value: "1500", type: "input" },
        { title: "Steps", value: "20", type: "input" },
        { title: "Thickness", value: "120", type: "input" },
        { title: "Storey", value: "5", type: "fixedValue" },
      ]}
      buttonsData={[
        { title: "Edit Structure" },
        { title: "Match Properties" },
        { title: "Make Unique" },
      ]}
    />
  );
};
