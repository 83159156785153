import React, { useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";
import styled from "styled-components";
import useQuery from "../../common/utils/useQuery";
import { routes } from "../../routes/constants";
import { initializeTeams } from "../../services/team.service";
import loaderGif from "../../assets/loader.gif"
import { initializeFileSystem } from "../../services/folder.service";
const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8em;
    pointer-events: none;
    img{
        // width: 80vw;
        // max-width: 500px;
    }
`
const loadData = async () => {
    await initializeFileSystem();
    return await initializeTeams();
}

const AUTH_ROUTES = [routes.login, routes.signup, routes.resetPassword];

function AppLoadingScreen({handleClose, style, ...props}) {
    let history = useHistory();
    let query = useQuery();
    useEffect(() => {
        const user = localStorage.getItem("user");
        const queryString = query.toString();
        let path = history.location.pathname;
        let route = routes.login;
        for(let auth_route of AUTH_ROUTES){
            if(matchPath(path, {path: auth_route})) route = path;
        }
        const isEditorRoute = matchPath(path, {path: routes.editor});
        if(isEditorRoute && isEditorRoute.isExact){
            handleClose()
        }else if(!user){
            if(queryString) route += `?${queryString}`;
            history.push(route);
            handleClose();
            return;
        }
        loadData()
        .then(() => {handleClose()})
    }, []);
    return ( 
        <Wrapper  style={style} {...props} >
            <img src={loaderGif} alt={"Snaptrude"} />
        </Wrapper>
     );
}

export default AppLoadingScreen;