"use strict";
import BABYLON, { Line } from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { colorUtil } from "../utilityFunctions/colorUtility.js";
import { getDistanceBetweenVectors } from "../extrafunc.js";

const axisIndicator = (function () {
  const CONSTANTS = {
    lineWidth: 2,
    dash: [9, 3],
  };

  const TYPES = {
    allIndicators: 0,
    axis1: 1,
    axis2: 2,
    axis3: 3,
  };

  let texture;
  let axis1Indicator;
  let axis2Indicator;
  let axis3Indicator;

  const activeIndicators = [];

  const _addToTexture = function (control, texture) {
    if (!control.host) {
      texture.addControl(control);
    }
    if (!control.parent) {
      texture.rootContainer.addControl(control);
    }
  };

  const _newLine = function () {
    let line = new Line("axisIndicator");
    line.lineWidth = CONSTANTS.lineWidth;
    line.dash = CONSTANTS.dash;
    line.snaptrudeProperties = {};
    line.isVisible = false;
    
    // line.shadowColor = colorUtil.type.black;
    // line.shadowBlur = 5;

    texture.addControl(line);

    return line;
  };

  const init = function () {
    texture = store.advancedTexture;
    axis1Indicator = _newLine(TYPES.axis1);
    axis2Indicator = _newLine(TYPES.axis2);
    axis3Indicator = _newLine(TYPES.axis3);

    axis1Indicator.snaptrudeProperties.type = TYPES.axis1;
    axis2Indicator.snaptrudeProperties.type = TYPES.axis2;
    axis3Indicator.snaptrudeProperties.type = TYPES.axis3;
  };

  const _getExtensions = function (firstPoint, secondPoint, options) {
    const dynamicSize = BABYLON.Vector3.Distance(
      store.scene.activeCamera.position,
      BABYLON.Vector3.Center(firstPoint, secondPoint)
    );
    const extension = options.extension ? options.extension * dynamicSize :
      0.025 * dynamicSize;

    const firstPointExtended = options.extensionNotRequired
      ? firstPoint
      : firstPoint.add(
          firstPoint.subtract(secondPoint).normalize().scale(extension)
        );

    const secondPointExtended = options.extensionNotRequired
      ? secondPoint
      : secondPoint.add(
          secondPoint.subtract(firstPoint).normalize().scale(extension)
        );

    return {
      firstPointExtended,
      secondPointExtended,
    };
  };

  const show = function (firstPoint, secondPoint, options) {
    let activeIndicator;
    if (options.indicator === TYPES.axis1) activeIndicator = axis1Indicator;
    else if (options.indicator === TYPES.axis2)
      activeIndicator = axis2Indicator;
    else if (options.indicator === TYPES.axis3)
      activeIndicator = axis3Indicator;

    if (firstPoint.almostEquals(secondPoint)) return;

    remove(options.indicator);
    const extensions = _getExtensions(firstPoint, secondPoint, options);

    if (!activeIndicator.isVisible) activeIndicator.isVisible = true;
    _addToTexture(activeIndicator, texture);
    activeIndicator.snaptrudeProperties.axisType = options.axisType;
    activeIndicator.snaptrudeProperties.firstPoint = firstPoint;
    activeIndicator.snaptrudeProperties.secondPoint = secondPoint;
    activeIndicator.snaptrudeProperties.extensionNotRequired =
      options.extensionNotRequired;

    const color =
      colorUtil.getColorNameForType(options.axisType) ||
      options.color ||
      colorUtil.type.black;
    activeIndicator.color = color.toLowerCase();
    activeIndicator.shadowColor = activeIndicator.color;
    activeIndicator.moveToVector3(extensions.firstPointExtended, store.scene);
    activeIndicator.moveToVector3(extensions.secondPointExtended, store.scene, true);

    activeIndicators.push(activeIndicator);

    return activeIndicator;
  };

  const remove = function (type = TYPES.allIndicators) {
    const _deactivate = function (indicator) {
      indicator.isVisible = false;
      indicator.isEnabled = true;
      _.remove(activeIndicators, indicator);
    };

    if (type === TYPES.allIndicators) {
      for (let i = 0; i < activeIndicators.length; ) {
        // no increment
        _deactivate(activeIndicators[i]);
      }
    } else if (type === TYPES.axis1) {
      _deactivate(axis1Indicator);
    } else if (type === TYPES.axis2) {
      _deactivate(axis2Indicator);
    } else if (type === TYPES.axis3) {
      _deactivate(axis3Indicator);
    }
  };

  const update = function () {
    activeIndicators.forEach((ai) => {
      ai.moveToVector3(ai.snaptrudeProperties.firstPoint, store.scene);
      ai.moveToVector3(ai.snaptrudeProperties.secondPoint, store.scene, true);
    });
    /*const newIndicators = [];
        activeIndicators.forEach(ai => {
            let options = {};
            let indicator;

            if (ai === axis1Indicator) indicator = TYPES.axis1;
            else if (ai === axis2Indicator) indicator = TYPES.axis2;

            options.indicator = indicator;
            options.axisType = ai.snaptrudeProperties.axisType;

            newIndicators.push([
                ai.snaptrudeProperties.firstPoint,
                ai.snaptrudeProperties.secondPoint,
                options
            ])
        });

        remove();

        newIndicators.forEach(ni => show(...ni));*/
  };

  const alignAxis1Indicator =
    function updatesPointsOfAxis1WhenIntersectionOccursWithAxis2(
      intersectionPoint
    ) {
      const activeIndicator = axis1Indicator;

      const d1 = getDistanceBetweenVectors(
        activeIndicator.snaptrudeProperties.firstPoint,
        intersectionPoint
      );
      const d2 = getDistanceBetweenVectors(
        activeIndicator.snaptrudeProperties.secondPoint,
        intersectionPoint
      );

      if (d1 < d2) {
        activeIndicator.snaptrudeProperties.firstPoint = intersectionPoint;
      } else {
        activeIndicator.snaptrudeProperties.secondPoint = intersectionPoint;
      }

      const extensions = _getExtensions(
        activeIndicator.snaptrudeProperties.firstPoint,
        activeIndicator.snaptrudeProperties.secondPoint,
        activeIndicator.snaptrudeProperties
      );

      activeIndicator.moveToVector3(extensions.firstPointExtended, store.scene);
      activeIndicator.moveToVector3(
        extensions.secondPointExtended,
        store.scene,
        true
      );
    };

  const getMetadata = function () {
    const getActiveIndicators = function () {
      return activeIndicators;
    };

    return {
      getActiveIndicators,
    };
  };

  return {
    TYPES,
    init,
    show,
    remove,
    update,
    alignAxis1Indicator,
    getMetadata,
  };
})();
export { axisIndicator };
