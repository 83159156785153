import BABYLON from "../modules/babylonDS.module.js";
import { drawCircle, drawCircleOutline, executeDrawCircleEvent } from "./twoD/twoDrawCircle.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { findPrioritizedSnapPoint, } from "./snapFuncsPrimary.js";
import { disposeSnappingObjects, } from "../modules/meshoperations/moveOperations/moveUtil.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { initializeSnappingEngine, turnOffSnappingEngine } from "./snapUtilities.js";
import { StateMachine } from "../modules/Classes/StateMachine.js";
import { getActiveStoreyObject, mmToSnaptrudeUnits, onSolid } from "../modules/extrafunc.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { pi } from "mathjs";
import { getFaceIdFromFacet, getFaceVerticesFromFace } from "../libs/brepOperations.js"
import { getUnitNormalVectorV3 } from "../libs/mathFuncs"
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import { is2D } from "./twoDimension";

const drawCylinder = (() =>{
    let _firstClick = true;
    let _center;
    let _meshOfInterest;
    let _faceId;
    let _normal;
    let _pointOnCircle;
    let _outLine;
    let _circleOutline;
    let _pickInfo;
    let _snappedPoint;

    const ONE_METER = mmToSnaptrudeUnits(1000);
    const ONE_SQUARE_METER = ONE_METER ** 2;
    const COLUMN_AREA_THRESHOLD = ONE_SQUARE_METER;
    const CHILD_THICKNESS = mmToSnaptrudeUnits(2);

    const _getPredicate = () => {
        if (_meshOfInterest) {
            return (mesh) => mesh === _meshOfInterest;
        } else {
            if (store.$scope.isTwoDimension) {
                return (mesh) => ['mass', 'floor', 'roof', 'layerrefplane'].includes(mesh.type.toLowerCase())
            }
            else {
                return (mesh) => { return ['mass', 'floor', 'roof'].includes(mesh.type.toLowerCase()) || mesh.name == 'ground1' }
            }

        }
    }

    const _resetVariables = () => {
        _firstClick = true;
        _center = undefined;
        _meshOfInterest = undefined;
        _faceId = undefined;
        _normal = undefined;
        _pointOnCircle = undefined;
        _snappedPoint = undefined;
        if(_outLine) {
            _outLine.dispose();
            _outLine = undefined;
        }
        if (_circleOutline) {
            _circleOutline.dispose();
            _circleOutline = undefined;
        }
        turnOffSnappingEngine();
        DisplayOperation.removeDimensions()
    };

    const onPointerDown = evt => {
        if (StateMachine.EventValidation.isPointerDownWithMiddleButton(evt)) return;

        if (!!_firstClick) {
            _pickInfo = scenePickController.compoundPick(_getPredicate(), { pickVisibleAndInvisibleMeshes: true });
            if (_pickInfo.hit) {
                _center = _snappedPoint || _pickInfo.pickedPoint;
                _meshOfInterest = _pickInfo.pickedMesh;
                _faceId = getFaceIdFromFacet(_pickInfo.faceId, _meshOfInterest);
                _normal = getNormalizedNormal();
                _firstClick = false;
                if (isDrawingOnGround()) _center.y = 0;
                if (is2D()) {
                    const activeStorey = getActiveStoreyObject();
                    if (activeStorey) _center.y = activeStorey.base;
                }
                initializeSnappingEngine(_center);
            }
        } else {
            _pickInfo = scenePickController.compoundPick(_getPredicate(), { pickVisibleAndInvisibleMeshes: true });
            if (_pickInfo.hit) {
                _pointOnCircle = _snappedPoint || _pickInfo.pickedPoint;

                _meshOfInterest = _pickInfo.pickedMesh;

                const radius = BABYLON.Vector3.Distance(_pointOnCircle, _center);

                if (!!radius) _createCircle(_center, radius);

                _resetVariables();
            }
        }
    };

    const getNormalizedNormal = () => {
        if (["layerrefplane", "ground"].includes(_meshOfInterest.type.toLowerCase())) return new BABYLON.Vector3(0, 0, 1);
        if (_meshOfInterest.name.includes('terrain')) return new BABYLON.Vector3(0, 0, 1);

        let face = _meshOfInterest.getSnaptrudeDS().brep.getFaces()[_faceId]
        let faceVertices = getFaceVerticesFromFace(face, _meshOfInterest, BABYLON.Space.WORLD);

        let normalVector = getUnitNormalVectorV3(faceVertices);

        [normalVector._y, normalVector._z] = [normalVector._z, normalVector._y]

        return normalVector;
    }

    const onPointerMove = function (evt) {
        if (StateMachine.EventValidation.isPointerDownWithMiddleButton(evt)) return;

        disposeSnappingObjects();
        if (_circleOutline) _circleOutline.dispose();

        _snappedPoint = _getSnappingPoint();

        if (!!_firstClick) return;

        _pickInfo = scenePickController.compoundPick(_getPredicate(), { pickVisibleAndInvisibleMeshes: true });
        _pointOnCircle = _snappedPoint || _pickInfo.pickedPoint;

        if (!_pointOnCircle) return;

        const radius = BABYLON.Vector3.Distance(_pointOnCircle, _center);

        _circleOutline = drawCircleOutline(_center, radius, _normal);

        DisplayOperation.drawOnMove(_center, _pointOnCircle, "circleDrawTool");
        DisplayOperation.displayOnMove(
            parseFloat(radius),
            null,
            true,
            {
                inputFocus: true,
                inputTextPosition: _center,
                followMouse: false,
                pointerLocation: _pointOnCircle,
            }
        );
    };

    const handleUserInput = input => {
        if (_center) {
            let radius = DisplayOperation.getOriginalDimension(input.text);

            if (!!radius) _createCircle(_center, radius);

            _resetVariables();
        }
    };

    const cancelOperation = () => {
        if (_meshOfInterest){
            _resetVariables();
            return true;
        }
    };

    const isDrawingOnGround = () => {
        return ["layerrefplane", "ground"].includes(_meshOfInterest.type.toLowerCase())
            || _meshOfInterest.name.includes('terrain')
    }

    const _isTopFace = function (){
        // return _faceId == '1';
        // weird and incorrect logic

        return _normal.almostEquals(BABYLON.Vector3.Forward());
        // again weird. y and z of normal are inverted for some reason
    }

    const _createCircle = (center, radius) => {
        var height = undefined;
        var yCoordinate = undefined;

        if (!isDrawingOnGround() && !_isTopFace()) {
            height = CHILD_THICKNESS;
        }
        if (store.$scope.isTwoDimension && isDrawingOnGround()) yCoordinate = _meshOfInterest.position._y;

        const circleDS = drawCircle(center, radius, _normal, yCoordinate, height);

        StoreyMutation.assignStorey(circleDS);

        if (isDrawingOnGround() || !_isTopFace()) {
            const area = pi * radius * radius;
            if (area <= COLUMN_AREA_THRESHOLD) circleDS.massType = "Column";
        }

        executeDrawCircleEvent(circleDS);

        if (!store.$scope.isTwoDimension) onSolid(circleDS.mesh);

        return circleDS;
    };

    const _getSnappingPoint = () => {
        const options = {
            material: colorUtil.getMaterial(colorUtil.type.preSnap),
            doNotDoSecondaryScenePicks: true,
            doPreClickAxisSnaps: true,
            axisIndicatorExtensionNotRequired: true,
            ignoreLowerStorey: false,
            attemptCadSnaps: true,
        };

        if (_pickInfo && _pickInfo.pickedPoint && _center) {
            options.parallelFaceSnap = true;
            options.axisSnapPoint = _pickInfo.pickedPoint;
        }

        const point = findPrioritizedSnapPoint(
            _center,
            null,
            null,
            options
        )

        if (point)
            if (!point._x || !point._y || !point._z) return;

        return point;
    };

    return {
        onPointerDown,
        onPointerMove,
        cancelOperation,
        handleUserInput,
        cleanUp: _resetVariables
    };
})();

export { drawCylinder }