import React from "react";
import PropTypes from "prop-types";

import Text from "../../components/Text";
import Button from "../../components/Button";
import backArrow from "../../assets/icons/back_arrow.svg";
import { NavLink } from "react-router-dom";
import Modal from "../../components/Modal";
import { colors } from "../../themes/constant";
import * as constants from "./constants";
import * as styles from "./styles";

function Cancel({ isOpen, onClose }) {
  const getCancelModalBody = () => {
    return (
      <div>
        <Text
          opacity={1}
          color={colors.darkGrey}
          size="0.875rem"
          lineHeight="1.125rem"
        >
          {constants.CANCEL_HEADER_TEXT}
        </Text>
        <div style={styles.cancelStyles.ctaParent}>
          <Text
            opacity={1}
            color={colors.darkGrey}
            weight="bold"
            size="0.875rem"
            lineHeight="1.125rem"
          >
            {constants.CANCEL_CTA_TEXT}
          </Text>
        </div>
      </div>
    );
  };

  const getCancelModalFooter = () => {
    return (
      <>
        <NavLink
          style={styles.cancelStyles.navLink}
          className="link"
          to="/dashboard/profile/billing"
        >
          <Button
            image={backArrow}
            imageWidth={10}
            imageHeight={10}
            customButtonStyle={styles.cancelStyles.billingCustomButtonStyle}
            customButtonTextStyle={
              styles.cancelStyles.billingCustomButtonTextStyle
            }
            type="button"
            title={constants.BILLING_BUTTON_TEXT}
          />
        </NavLink>
        <Button
          customButtonStyle={styles.cancelStyles.cancelCustomButtonStyle}
          type="button"
          title={constants.CANCEL_SUBSCRIPTION_TEXT}
        />
      </>
    );
  };
  return (
    <Modal
      modalHeaderText="Cancel Subscription"
      modalBody={getCancelModalBody()}
      customBodyStyle={styles.cancelStyles.modalBodyStyle}
      modalFooter={getCancelModalFooter()}
      customFooterStyle={styles.cancelStyles.footerStyle}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

Cancel.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Cancel;
