import { getDimensionInSnaptrudeUnits } from ".";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { store } from "../utilityFunctions/Store";
import {
  convertVerticesToSnaptrude,
  createMaterialFromSpeckleData,
  createMeshWithMeshData,
  getMaterialIndex,
  getMaterialUrl,
  getPointsData,
  getPointsData2,
} from "./extraFunctions";
import { createCustomMesh } from "../../libs/massModeling.js";
import { Floor } from "../snaptrudeDS/floor.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { getCSGFormOfMesh, onSolid } from "../extrafunc";
import { setLayerTransperancy } from "../../libs/sceneFuncs";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { createRoof } from "./roof";
import { BasicFloorComp } from "../../libs/basicCompProperties";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import { applyMaterialByBREP } from "../../libs/mats";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher";
import speckleConstants from "./speckleConstants";
import { checkStorey } from "./storey";
import { drawVoids } from "./ceillings";
import { removeCollinearPoints } from "../../libs/wall_generation_helper";
import { removecollinear } from "../../libs/twoD/twoServices";

const createFloorComponent = (type, floorTypeData) => {
  let thickness = 12;
  let floorComp = {
    _name: type,
    _type: "Floor",
    _id: "",
    _layers: [],
  };
  let layersData,
    layers = [],
    material = [];
  let overallThickness = 0;
  let structuralThickness = 0;
  for (let i = 0; i < floorTypeData.length; ++i) {
    if (floorTypeData[i] && floorTypeData[i][type]) {
      layersData = floorTypeData[i][type];
      break;
    }
  }

  if (layersData) {
    layersData.forEach((layer) => {
      layers.push({
        check: false,
        value: layer.material.name,
        subtype: "",
        thickness: layer.width,
        quantity: "volume",
        unit: "Cubic metres",
        core: layer.function === "Structure" ? true : false,
      });

      if (layer.function == "Structure") {
        structuralThickness += layer.width;
      }
      overallThickness += layer.width;

      material.push({
        type: layer.material.name,
        value: layer.material.value,
      });
    });

    floorComp._layers = layers;
    thickness =
      structuralThickness > 0 ? structuralThickness : overallThickness;
    floorComp._thickness = thickness;

    return { floorComp, material, thickness };
  } else {
    floorComp = BasicFloorComp;
    return { floorComp, material, thickness };
  }
};

const createFloors = (
  floorData,
  floorTypeData,
  floorsGeometryData,
  materialList
) => {
  let floorsMesh = [],
    floorMaterialCommands = [];
  // floorData = floorData.filter(
  //   (floor) => floor.speckle_type === speckleConstants.speckleType.FLOOR
  // );

  let floors = [];
  for (let fId in floorData) {
    try {
      let floor = floorData[fId];
      const family = floor?.family;
      const type = floor?.type;
      const level = floor?.level;
      let segments = floor?.outline;
      let voidsData = floor?.voids;
      // const unit = floor?.units ? floor?.units : "mm";
      // let height = DisplayOperation.getOriginalDimension(
      //   floor.parameters.FLOOR_ATTR_THICKNESS_PARAM.value,
      //   speckleConstants.speckleUnits[unit]
      // );

      // let floorGeometryData = floorsGeometryData[fId];
      let createdFloor;
      let { floorComp, material, thickness } = createFloorComponent(
        floor.type,
        floorTypeData
      );
      thickness = DisplayOperation.getOriginalDimension(
        thickness,
        "millimeter"
      );

      let floorComponent = floorComp;
      let layerMaterials = material;
      const storey = checkStorey(floor?.level?.name);

      let floorGeometryData = floorData[fId];
      if (floorGeometryData["vertices"]) {
        if (thickness < 0.295) {
          createdFloor = createFloor([], thickness, storey, {
            floorComponent,
            layerMaterials,
            geometry: floorGeometryData,
            type,
            family,
            elementId: fId,
            level,
          });
        } else {
          createdFloor = createRoof([], thickness, storey, {
            floorComponent,
            layerMaterials,
            geometry: floorGeometryData,
            type,
            family,
            elementId: fId,
            level,
          });
        }
        if (createdFloor) {
          createdFloor.revitMetaData.floorType = floor.type;
          floors.push(createdFloor);
        }
        continue;
      }

      for (let id in segments) {
        try {
          let outline = segments[id];
          let voids = voidsData[id];
          outline = getDimensionInSnaptrudeUnits(outline, "millimeter");
          outline = outline.map((point) => [
            point[0],
            point[1],
            point[2],
          ]);
          removecollinear(outline);
          if (thickness < 0.295) {
            createdFloor = createFloor(outline, thickness, storey, {
              floorComponent,
              layerMaterials,
              type,
              family,
              elementId: fId,
              curveId: id,
              voids,
              level,
            });
          } else {
            createdFloor = createRoof(outline, thickness, storey, {
              floorComponent,
              layerMaterials,
              type,
              family,
              elementId: fId,
              curveId: id,
              voids,
              level,
            });
          }
          if (createdFloor) {
            createdFloor.revitMetaData.floorType = floor.type;
            floors.push(createdFloor);
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  virtualSketcher.addWithoutGeometryEdit(floors);

  floors.forEach((f) => floorsMesh.push(f.mesh));

  floors.forEach(function (floor) {
    try {
      let newMaterial = false,
        floorMat;
      floorMat = store.scene.getMaterialByName(
        `${floor.mesh.layerMaterials[0]?.value?.name}`
      );
      if (!floorMat) {
        if (floor.mesh.layerMaterials[0]?.value?.name) {
          floorMat = createMaterialFromSpeckleData(
            floor.mesh.layerMaterials[0],
            floor.mesh.layerMaterials[0]?.value?.name,
            materialList,
            { category: floor.mesh.name }
          );
        }
      }
      if (floorMat) {
        let materialIndex,
          isNewMaterial = false;
        if (floor.mesh.getSnaptrudeDS()) {
          let object = floor.mesh.getSnaptrudeDS();
          if (floor.mesh.layerMaterials.length == 1) {
            ({ materialIndex, isNewMaterial } = getMaterialIndex(
              floor.mesh,
              floorMat
            ));
            floor.mesh.subMeshes.forEach(function (subMesh) {
              subMesh.materialIndex = materialIndex;
            });
            if (object.brep) {
              let faces = object.brep.getFaces();
              faces.forEach(function (face) {
                face.materialIndex = materialIndex;
              });
            }
          } else if (floor.mesh.layerMaterials.length > 1) {
            let len = floor.mesh.layerMaterials.length;
            if (
              floor.mesh.layerMaterials[0].type ==
              floor.mesh.layerMaterials[len - 1].type
            ) {
              materialIndex = getMaterialIndex(floor.mesh, floorMat).materialIndex;
              floor.mesh.subMeshes.forEach(function (subMesh) {
                subMesh.materialIndex = materialIndex;
              });
              if (object.brep) {
                let faces = object.brep.getFaces();
                faces.forEach(function (face) {
                  face.materialIndex = materialIndex;
                });
              }
            } else {
              ({ materialIndex, isNewMaterial } = getMaterialIndex(
                floor.mesh,
                floorMat
              ));
              applyMaterialByBREP(floor.mesh, 1, "", "Paint", {
                mat_index: materialIndex,
                revitImport: true,
              });
            }
          }
        }
        // if (floor.mesh.material && isNewMaterial) {
        //   saveMaterialInBackend(floor.mesh.material);
        // }
      }
    } catch (e) {
      console.log(e);
    }
  });

  return { floorsMesh };
};

const createFloor = (path_bottom, height, storey, options) => {
  let createdMesh;
  if (options.geometry) {
    createdMesh = drawWithGeometryData(options.geometry);
  } else {
    // let segments = data.outline.segments;
    createdMesh = createCustomMesh(path_bottom, height, null);
    let voidsData = options.voids;
    createdMesh = drawVoids(voidsData, createdMesh, height);

    // let path_bottom = getPointsData(segments);
    // path_bottom = getDimensionInSnaptrudeUnits(
    //   path_bottom,
    //   speckleConstants.speckleUnits[options.unit]
    // );
    // path_bottom = path_bottom.map((parameter) => [
    //   parameter[0],
    //   parameter[1],
    //   parameter[2] - height,
    // ]);
    // createdMesh = createCustomMesh(path_bottom, height, null);
  }
  createdMesh.structure_id = store.activeLayer.structure_id;
  let floor = new Floor(createdMesh);
  createdMesh.type = "Floor"; //throwAway is overwritten when mesh is added to level
  createdMesh.layerMaterials = options.layerMaterials;
  createdMesh.floorComponent = options.floorComponent;
  floor.storey = storey?.value;
  floor.room_type = "Floor";
  floor.assignProperties({ floorComponent: options.floorComponent });
  floor.mesh.isVisible = true;
  let originalPosition = floor.mesh.position.clone();
  floor.revitMetaData = {
    elementId: options.elementId,
    type: options.type,
    family: options.family,
    curveId: options.curveId,
    originalVoids: !!options.voids
      ? Object.keys(options.voids).map((k) => {
          return { id: k, path: options.voids[k] };
        })
      : null,
    originalProfile: path_bottom,
    originalPosition: originalPosition,
  };

  floor.height = createdMesh.height;

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  talkingAboutLevel.addFloorToLevel(floor, false);
  if (storey) {
    if (floor.storey !== storey?.value) {
      storey.addElement(floor);
    }
  } else {
    StoreyMutation.assignStorey(floor);
  }
  onSolid(createdMesh);
  setLayerTransperancy(createdMesh);
  return floor;
};

const drawWithGeometryData = (data) => {
  let vertex, face, uvs, createdMesh;

  vertex = [].concat(...data["vertices"]);
  vertex = vertex.map((v) => v * 304.8);
  vertex = convertVerticesToSnaptrude(vertex, [0, 0, 0]);
  face = [].concat(...data["faces"]);
  uvs = [].concat(...data["uvs"]);
  createdMesh = createMeshWithMeshData(vertex, face, uvs);

  return createdMesh;
};

// const drawVoids = (floor, createdMesh, thickness) => {
//   for (let i = 0; i < floor.voids.length; ++i) {
//     let segments = floor.voids[i].segments;
//     let parameters = getPointsData2(segments);

//     parameters = getDimensionInSnaptrudeUnits(parameters, "millimeters");
//     let height = thickness;
//     parameters = parameters.map((parameter) => [
//       parameter[0],
//       parameter[1],
//       parameter[2] - height,
//     ]);

//     let voidMesh = createCustomMesh(parameters, height, null);
//     voidMesh.forceSharedVertices();
//     let createdMeshCSG = getCSGFormOfMesh(createdMesh);
//     let voidMeshCSG = getCSGFormOfMesh(voidMesh);
//     let newCreatedMeshCSG = createdMeshCSG.subtract(voidMeshCSG);
//     let newCreatedMesh = newCreatedMeshCSG.toSnaptrudeMesh(
//       "floor",
//       null,
//       store.scene
//     );
//     newCreatedMesh.position = createdMesh.position;
//     createdMesh.dispose();
//     voidMesh.dispose();
//     createdMesh = newCreatedMesh;
//   }
//   return createdMesh;
// };

export { createFloors, drawVoids, drawWithGeometryData };
