let StoneRoof = {
  "_name": "StoneRoof",
  "_layers": [{check:false, value:"Stone",
    subtype: "",
    thickness:250,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

let StoneRoofFace = {
  "_name": "StoneRoofFace",
  "_layers": [{check:false, value:"Stone",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let TileRoofFace = {
  "_name": "TileRoofFace",
  "_layers": [{check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Water Proofing",
    subtype: "",
    thickness:2,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let WoodRoof = {
  "_name": "WoodRoof",
  "_layers": [{check:false, value:"Wood",
    subtype: "",
    thickness:150,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

let WoodRoofFace = {
  "_name": "WoodRoofFace",
  "_layers": [{check:false, value:"Wood",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }],
}

let BrickRoof = {
  "_name": "BrickRoof",
  "_layers": [{check:false, value:"Brick",
    subtype: "",
    thickness:2000,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

let BrickRoofFace = {
  "_name": "BrickRoofFace",
  "_layers": [{check:false, value:"Brick",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Brick",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }],
}

let ColorsRoof = {
  "_name": "ColorsRoof",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Water Proofing",
    subtype: "",
    thickness:2,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let ColorsRoofFace = {
  "_name": "ColorsRoofFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Water Proofing",
    subtype: "",
    thickness:2,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let PaintRoof = {
  "_name": "ColorsRoof",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Water Proofing",
    subtype: "",
    thickness:2,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let PaintRoofFace = {
  "_name": "ColorsRoofFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Water Proofing",
    subtype: "",
    thickness:2,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let ConcreteRoof = {
  "_name": "ConcreteRoof",
  "_layers": [{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

let ConcreteRoofFace = {
  "_name": "ConcreteRoofFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:5,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"RCC",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

let PCCRoof = {
  "_name": "PCCRoof",
  "_layers": [{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

let PCCRoofFace = {
  "_name": "PCCRoofFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:5,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"PCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"PCC",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

let RCCRoof = {
  "_name": "RCCRoof",
  "_layers": [{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

let RCCRoofFace = {
  "_name": "RCCRoofFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:5,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"RCC",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

let MetalRoof = {
  "_name": "MetalRoof",
  "_layers": [{check:false, value:"Metal",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

let MetalRoofFace = {
  "_name": "MetalRoofFace",
  "_layers": [{check:false, value:"Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"RCC",
    subtype: "",
    thickness:150,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let GlassRoof = {
  "_name": "GlassRoof",
  "_layers": [{check:false, value:"Glass",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    transparency: 0.4,
    core: true
  }]
}

var GroundcoverRoof = {
  "_name": "GroundcoverRoof",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

var GroundcoverRoofFace = {
  "_name": "GroundcoverRoofFace",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  },{check:false, value:"Soil",
      subtype: "",
      thickness:25,
      quantity: "Area",
      unit: "Square metres",
    },
  ]
}

export {
  StoneRoof,
  StoneRoofFace,
  TileRoofFace,
  WoodRoof,
  WoodRoofFace,
  BrickRoof,
  BrickRoofFace,
  ColorsRoof,
  ColorsRoofFace,
  PaintRoof,
  PaintRoofFace,
  ConcreteRoof,
  ConcreteRoofFace,
  PCCRoof,
  PCCRoofFace,
  RCCRoof,
  RCCRoofFace,
  MetalRoof,
  MetalRoofFace,
  GlassRoof,
  GroundcoverRoof,
  GroundcoverRoofFace
}
