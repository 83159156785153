import { parseCAD } from "../cadImporter/cadImporter.js";
import { drawEdgeArrayAsSketch, handleShowOrHideForCAD } from "../cadImporter/cadServices.js";
import { showToast } from "../extrafunc.js";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants.js";
import { TOAST_TYPES } from "../extrafunc.js";
import { store } from "../utilityFunctions/Store.js";
import { getCadJson } from "../../../services/cadImport.service.js";
import { showHideCADConfirmationModal } from "../../../components/CADConfirmationModal/helper.js";

export const sketchCADFromJson = (cadJSON) => {
  console.log(JSON.stringify('preparing to sketch'))
  const cadParser = new parseCAD();
  cadParser.loadJSON(cadJSON);
  cadParser.setAllowedLayersSubstring(cadParser.getLayersDefinedInJSON());
  cadParser.setScaleFactor(cadParser.getScaleFactorFromUnit(cadJSON.unit));
  cadParser.generateEdgeArray();
  let layer = drawEdgeArrayAsSketch(cadParser.getEdgeArray(), cadParser.getScaledPointArray());

  cadParser.flushAllData();

  let maximum = layer.mesh.getBoundingInfo().boundingBox.maximumWorld;
  if (maximum.x > 10000 || maximum.z > 10000) {
    showHideCADConfirmationModal(true, layer)
  }

  return layer;
}

export class CadImporter {
  constructor(requestId) {
    this.requestId = requestId;
    this.cadPollingInterval = null;

    this.showedTimeWarningToast = false;
    this.startedPollingTimeInSeconds = null;
  }

  shouldShowTimeoutWarning() {
    let currentTimeInSeconds = Math.floor(Date.now() / 1000);
    return currentTimeInSeconds > this.startedPollingTimeInSeconds + 60
  }

  showTimeoutWarningToast() {
    if (this.shouldShowTimeoutWarning()) {
      if (!this.showedTimeWarningToast) {
        showToast("CAD Import is taking longer than usual", 6000, TOAST_TYPES.error); // TODO: write better messages
        this.showedTimeWarningToast = true;
      }
    }
  }

  startPollingCadJson(intervalDuration = 10000) {
    this.startedPollingTimeInSeconds = Math.floor(Date.now() / 1000);

    const _intervalCallback = () => {
      this.showTimeoutWarningToast();

      if (GLOBAL_CONSTANTS.cadRequestIdsToCancel.includes(this.requestId)) {
        console.log("Canceled CAD Import", this.requestId );
        this.stopPollingCadJson();
        return;
      }

      getCadJson(this.requestId).then((data) => {
        console.log("got the cad data", data, 'for requestId', this.requestId);

        if (!data || !data.cadJson) {
          setTimeout(_intervalCallback, intervalDuration);
          return;
        }

        if (GLOBAL_CONSTANTS.cadRequestIdsToCancel.includes(this.requestId)) {
          console.log("Canceled CAD Import", this.requestId);
          this.stopPollingCadJson();
          return;
        }

        try {
          this.stopPollingCadJson();

          sketchCADFromJson(JSON.parse(data.cadJson));

          let messageDuration = 3000;
          showToast('Imported CAD', messageDuration, TOAST_TYPES.success);
          clearCadLoader("Finished Uploading CAD");
        } catch (e) {
          let messageDuration = 3000;
          showToast('Error Importing', messageDuration, TOAST_TYPES.error);
          clearCadLoader("Error Importing CAD file");
          setTimeout(_intervalCallback, intervalDuration);
        }
      }).catch(e => {
        console.error(e);
        setTimeout(_intervalCallback, intervalDuration);
      })
    };

    this.cadPollingInterval = setTimeout(_intervalCallback, intervalDuration);
  }

  stopPollingCadJson() {
    if (this.cadPollingInterval) clearTimeout(this.cadPollingInterval);
    this.cadPollingInterval = undefined;
    GLOBAL_CONSTANTS.cadRequestIdsToCancel.push(this.requestId); // ensure same cad file isn't created multiple times in the scene.
    store.currentCadRequestId = null;
  }
}

export const cadImportDownloadResult = (message) => {
  let workItemId = message.workItemId;
  let cadRequestId = message.cadRequestId;

  if (GLOBAL_CONSTANTS.cadRequestIdsToCancel.includes(cadRequestId)) {
    console.log("Canceled CAD Import", JSON.stringify({workItemId, cadRequestId}));
    return;
  }
  GLOBAL_CONSTANTS.cadRequestIdsToCancel.push(cadRequestId); // ensure same cad file isn't created multiple times in the scene.
  store.currentCadRequestId = null;

  sendCadImportProgressEvent(90, "Pre-Processing ...", 99);
  try {
    store.cadImporter.stopPollingCadJson();
    sketchCADFromJson(message.jsonData);

    let messageDuration = 3000;
    showToast('Imported CAD', messageDuration, TOAST_TYPES.success);
    clearCadLoader("Finished Uploading CAD");
  } catch (e) {
    clearCadLoader("Failed to import");
    console.log("Failed to import CAD", e);
    let messageDuration = 3000;
    showToast("There was an unexpected error creating the CAD sketch.", messageDuration);
  }
};

export const cadImportOnComplete = (message) => {
  let workItemId = message.id;
  console.log(message);
  if (message.status === "failedDownload") {
    showToast("Bad network encountered. Please re-upload CAD.");
    clearCadLoader("Failed to import");
  }
};

export const cadImportOnError = (message) => {
  console.error(message);
  let _message =  message || "An unexpected error occured, please refresh the page and try again.";
  showToast("Forgenode: " + _message, 9000, TOAST_TYPES.error);
  store.currentCadRequestId = null;
  if (store.cadImporter) store.cadImporter.stopPollingCadJson();
  clearCadLoader("Failed to import");
};

let cadProgressInterval;
export const sendCadImportProgressEvent = function (completed, text, next, intervalDuration=1000) {
  const getEvent = (completed, text) => {
    const event = new CustomEvent(GLOBAL_CONSTANTS.strings.events.cadUploadProgress, {
      detail: {
        progress: completed,
        text: text,
      }
    })
    return event;
  }
  if(cadProgressInterval) {
    clearInterval(cadProgressInterval);
  }
  if (!text) text = "Uploading CAD ...";
  if(completed === 100){
    clearInterval(cadProgressInterval);
    const event = getEvent(100, text);
    window.dispatchEvent(event);
    return;
  }
  if(next){
    let progress = completed;
    if(cadProgressInterval){
      clearInterval(cadProgressInterval);
    }
    let incrementAt = (progress + next)/2;

    let factor = 2;

    cadProgressInterval = setInterval(() => {
      if(progress > next) {
        clearInterval(cadProgressInterval);
      }else{
        const newVal = progress + (1/factor);
        if(newVal >= incrementAt){
          factor = factor*2;
          incrementAt = (incrementAt + next)/2;
        }
        const event = getEvent(newVal, text);
        window.dispatchEvent(event);
        progress = newVal;
      }
    }, intervalDuration);
  }else{
    const event = getEvent(completed, text);
    window.dispatchEvent(event);
  }
};

export const clearCadLoader = function (text) {
  sendCadImportProgressEvent(100, text);
};

export const cadImportOperationStatus = (data) => {
  let percent = data.value;
  let requestId = data.requestId;

  if (GLOBAL_CONSTANTS.cadRequestIdsToCancel.includes(data.requestId)) {
    return;
  }

  if (Math.ceil(percent) >= 90) {
        sendCadImportProgressEvent(90, "Downloading result ...", 99);
  } else {
    sendCadImportProgressEvent(percent, data.message);
  }
};
