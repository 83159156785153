import {
  getRenders, startRender
} from "../../../services/render.service";
import reduxStore from "../../stateManagers/store/reduxStore";
import {
  addNewRender,
  populateRenders,
  stopRenderLoading
} from "../../stateManagers/reducers/objectProperties/screenshotUiDataSlice";
import exportGLB from "../exports/gltfExporter";
import {showToast, TOAST_TYPES} from "../extrafunc";
import BABYLON from "../babylonDS.module";
import {store} from "../utilityFunctions/Store";

let _isRenderEnabled = false;

const Samples = {
  0: 8,
  1: 16,
  2: 64,
  3: 128
};

const RenderState = {
  IDLE: "idle",
  STARTED: "started",
  RUNNING: "running",
  FINISHED: "finished"
};

const SSAOParams = {
  "type": "SSAO",
  "passes": {
    "ssao": {
      "bilateralPass": {
        "smoothEnabled": true,
        "edgeSharpness": 0.1,
        "enabled": true
      },
      "parameters": {
        "intensity": 0.15,
        "occlusionWorldRadius": 0.49,
        "bias": 0.001,
        "falloff": 1,
        "edgeSharpness": 0.3,
        "blurEnabled": true
      },
      "enabled": true
    }
  },
  "resources": {
    "geometries": {},
    "materials": {},
    "textures": {},
    "images": {},
    "shapes": {},
    "skeletons": {},
    "animations": {}
  }
}

const currentRender = {
  state: RenderState.IDLE,
  id: null
};

const isRenderEnabled = _ => _isRenderEnabled;

/**
 * Capture screenshot for render thumbnail
 *
 * @returns {Promise<string>}
 * @private
 */
const _captureScreenShot = () => {
  return new Promise((resolve, reject) => {
    BABYLON.Tools.CreateScreenshot(
      store.engine,
      store.scene.activeCamera,
      {width: 256, height: 144},
      (data) => {
        resolve(data);
      }
    );
  });
}

/**
 * Initialize render. Currently, render enabled by default
 * No authentication needed
 *
 * @param projectName
 * @param floorKey
 * @returns {Promise<void>}
 */
const renderInit = async (projectName, floorKey) => {
  _isRenderEnabled = true;
}

const initializeRendersForViewsSidebar = async (floorKey) => {
  const response = await getRenders(floorKey);
  if (response.renders) {
    const renders = JSON.parse(response.renders);
    reduxStore.dispatch(
      populateRenders({renders})
    );
  }
}

const finishRender = async (response, renderName) => {
  if (response.renders) {
    const render = JSON.parse(response.renders)[0];
    reduxStore.dispatch(
      addNewRender({render})
    );
    showToast(`Render "${renderName}" ready`, null, TOAST_TYPES.success);
  }
}

const startRenderProcess = async (
  renderName,
  renderQuality,
  floorKey
) => {
  try {
    const glbBlob = await exportGLB(renderName, false, true);
    const thumbnailImageBase64String = await _captureScreenShot();
    const response = await startRender(
      glbBlob,
      thumbnailImageBase64String,
      renderName,
      Samples[renderQuality],
      floorKey
    );

    if (!response) throw new Error("Server error: Render failed");

    finishRender(response, renderName);
  } catch (e) {
    showToast(`Render "${renderName}" failed!`, null, TOAST_TYPES.error);
    console.warn(e);
  } finally {
    reduxStore.dispatch(stopRenderLoading());
  }
}

const reset = () => {
  _isRenderEnabled = false;
}

export {
  renderInit,
  isRenderEnabled,
  initializeRendersForViewsSidebar,
  startRenderProcess,
  reset,
  SSAOParams
}
