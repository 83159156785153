import React, { useCallback, useState } from "react";
import Button from "../../../../components/Button";
import { Content, Controls, Footer, Header, MainContainer, Nav, NavItem, Title } from "../../styles";
import Furnitures from "./Furnitures";
import Materials from "./Materials";
import imageIcon from '../../../../assets/icons/image.svg';
import cubeIcon from "../../../../assets/icons/objectImage.svg";

import SnaptrudeImportFunctionalityModal from "../../../../components/SnaptrudeModal/SnaptrudeImportFunctionalityModal";
import { SnaptrudeDropzone } from "../../../../components/SnaptrudeModal/SnaptrudeDropzone";
import { searchTeamObjects, uploadTeamMaterials } from "../../../../services/team.service";
import { MATERIAL_TYPE_OPTIONS, FURNITURE_TYPE_OPTIONS } from "./constants";
import Modal from "./Modal";
import TeamInviteModal from "../../../../components/TeamModal.js";
import { debounce } from "lodash";
import { SearchInput } from "../../../../components/searchInput";
const TABS = ["materials", "furniture"];
function Libraries({ selectedTeam, teamlist }) {
    const [activeTab, setActiveTab] = useState(TABS[0]);
    const [isUploadMaterialModalOpen, setIsUploadMaterialModalOpen] = useState(false);
    const [isUploadFurnitureModalOpen, setIsUploadFurnitureModalOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressText, setProgressText] = useState("Uploading");
    const [progress, setProgress] = useState(0);
    const [materialType, setMaterialType] = useState(MATERIAL_TYPE_OPTIONS[0]);
    const [furnitureType, setFurnitureType] = useState(FURNITURE_TYPE_OPTIONS[0]);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [searchterm, setSearchterm] = useState("");
    const [searchResult, setSearchResult] = useState({
        searchterm: "",
        objects: [],
        type: "",
        teamId: null
    });
    const [isSearched, setIsSearched] = useState(false);

    const debounceSearch = useCallback(
        debounce(async (text) => {
            console.log("calling searcch team objects")
            const { status, teamId, objects, type, message } = await searchTeamObjects(selectedTeam.id, text)
            if (status === "success") {
                setSearchResult({
                    searchterm: text,
                    objects: objects,
                    type: type,
                    teamId: teamId
                })
                setIsSearched(true)
            } else {
                console.warn(message);
            }
        }, 500),
        []
    )

    const handleSearchTextChange = (evt) => {
        const { value } = evt.target;
        setSearchterm(value);
        if (!value) {
           setIsSearched(false)
        } else {
            debounceSearch(value);
        }
    }

    const handleCancelFile = (index) => {
        let newUploadedFiles = uploadedFile.slice();
        newUploadedFiles.splice(index, 1);
        setUploadedFile(newUploadedFiles);
    };
    return (
        <MainContainer>
            <Header>
                <Title style={{ flex: 1, minWidth: "8em" }}>
                    Libraries
                </Title>
                <Controls>
                    {
                        activeTab === "furniture" &&
                        <SearchInput
                            style={{ marginRight: "0.5em" }}
                            value={searchterm}
                            onChange={handleSearchTextChange}
                        />
                    }
                    {
                        (selectedTeam.roleBasedPermissions && selectedTeam.roleBasedPermissions[selectedTeam.role]) ?
                            selectedTeam.roleBasedPermissions[selectedTeam.role]["add_users_to_team"] &&
                            <Button
                                outline={true}
                                title={"Add team mate"}
                                onPress={() => { setShowCreateTeamModal(true) }}
                                customButtonStyle={{minHeight: "2.188rem", height: "2.188rem"}}
                            />
                            :
                            <Button
                                outline={true}
                                title={"Add team mate"}
                                onPress={() => { setShowCreateTeamModal(true) }}
                                customButtonStyle={{minHeight: "2.188rem", height: "2.188rem"}}
                            />
                    }
                </Controls>
            </Header>
            <Nav>
                {
                    TABS.map(tab => {
                        return <NavItem key={tab} isActive={activeTab === tab} onClick={() => { setActiveTab(tab) }}>{tab}</NavItem>
                    })
                }
            </Nav>
            <Content>
                {activeTab === "materials" && <Materials selectedTeam={selectedTeam} teamlist={teamlist} />}
                {activeTab === "furniture" && <Furnitures selectedTeam={selectedTeam} teamlist={teamlist} searchResult={searchResult} isSearched={isSearched} />}
            </Content>
            <Footer>
                {
                    activeTab === "materials" && (selectedTeam.roleBasedPermissions && selectedTeam.roleBasedPermissions[selectedTeam.role]) &&
                    selectedTeam.roleBasedPermissions[selectedTeam.role]["add_to_library"] &&
                    <>
                        <Button outline={true} title={"Upload new materials"} onPress={() => { setIsUploadMaterialModalOpen(true) }} />
                    </>
                }
                {
                    activeTab === "furniture" && (selectedTeam.roleBasedPermissions && selectedTeam.roleBasedPermissions[selectedTeam.role]) &&
                    selectedTeam.roleBasedPermissions[selectedTeam.role]["add_to_library"] &&
                    <>
                        <Button outline={true} title={"Upload new furniture"} onPress={() => { setIsUploadFurnitureModalOpen(true) }} />
                    </>
                }
            </Footer>
            <Modal modalFor={"materials"} isOpen={isUploadMaterialModalOpen} onClose={() => { setIsUploadMaterialModalOpen(false) }} selectedTeam={selectedTeam} />
            <Modal modalFor={"furnitures"} isOpen={isUploadFurnitureModalOpen} onClose={() => { setIsUploadFurnitureModalOpen(false) }} selectedTeam={selectedTeam} />
            {
                showCreateTeamModal && <TeamInviteModal page={2} handleClose={() => { setShowCreateTeamModal(false) }} selectedTeamId={selectedTeam.id} />
            }
        </MainContainer>
    )
}

export default Libraries;