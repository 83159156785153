import { cameraFollow } from "./cameraFollow";
import { mouseMove } from "./mouseMove";
import { Role } from "./role";

const onClickShareScreen = (() => {
  let _isClicked = false;

  const click = () => {
      _isClicked = !_isClicked;
      if(_isClicked){
          if(Role.get() === Role.CONSTANTS.owner){
              cameraFollow.broadcastShare();
              mouseMove.broadcast();
          } else {
              cameraFollow.followSharer();
              mouseMove.broadcast();
          }
      } else {
          if(Role.get() === Role.CONSTANTS.owner){
              cameraFollow.unbroadcastShare();
              mouseMove.unbroadcast();
          } else {
              cameraFollow.unfollowSharer();
              mouseMove.unbroadcast();
          }
      }
  };

  const updateButtonColor = isSelected => {
      try{
          const button = document.getElementById('ShareScreen');
          if(isSelected){
              button.style.border = '1px solid #d30041';
          } else {
              button.style.border = '';
          }
      } catch (err){
          console.log("Error in updating Share Screen button", err);
      }
  };

  const isClicked = () => _isClicked;

  return {
      click,
      updateButtonColor,
      isClicked,
  };
})();

export {
  onClickShareScreen,
};
