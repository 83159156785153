import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as constants from "./constants";
import Button from "../../components/Button/index";
import Divider from "../../components/Divider/index";
import Input from "../../components/Input/index";
import Google from "../../assets/images/google.svg";
import { signUpStyles } from "./styles";
import { routes } from "../../routes/constants";
import * as jwt from "jsonwebtoken";

import AuthService from "../../services/auth.service";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './telInput.css';
import { ORIGIN } from "../../services/url.constants";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import { getCountryFromIP } from "../../services/payment.service";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import GoogleLogin from "react-google-login";
import { v4 as uuidv4 } from 'uuid';
import { initializeTeams } from "../../services/team.service";
import _ from "lodash";

const Wrapper = styled.div`
  width: 25rem;
  @media (max-width: 768px){
    width: 18rem;
  }
`
const Header = styled.h4`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.primeBlack};
  font-size: ${(props) => props.theme.fontSizes.tiny};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.primeBlack};
  font-size: 0.775rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0 0.5rem 0;
`;

const generateRandomPassword = () => {
  return Math.random().toString(36).slice(2) + 
                Math.random().toString(36)
                    .toUpperCase().slice(2);
}

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.invitedEmail ? props.invitedEmail : "",
      name: "",
      password: "",
      emailError: false,
      formError: "",
      isButtonLoading: false,
    };
  }

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  setEmailError = (value) => {
    this.setState({
      emailError: value,
    });
  };

  handleSignUp = () => {
    window.analytics.track('Cliked Signup Button');
    this.setState({isButtonLoading: true})
    if (!this.shouldSignUpDisable) {
      const {email, name, phoneNumberWithCode, password} = this.state;
      AuthService.register(
        email,
        name,
        phoneNumberWithCode,
        password
      )
        .then((res) => {
          this.setState({isButtonLoading: false});
          if(res === "Already Exists") this.setFormProperty("formError", "User already exists, please sign in.");
          else if(res === "Invalid") this.setFormProperty("formError", "Invalid form details.");
          else if(this.props.postSuccessfulSignUp) this.props.postSuccessfulSignUp(email);
          else {
            initializeTeams()
            .then(teamResp => {
              if(teamResp.status !== "success"){
                showToast("Could not load teams, you may need to refresh once logged in.");
              }
            })
            .catch(error => {
              showToast("Could not load teams, you may need to refresh once logged in.");
            })
            const query = new URLSearchParams(this.props.location.search);
            const redirect = query.get("redirect");
            let url = routes.selectProfession;
            if(redirect){
              url = `${routes.selectProfession}?redirect=${redirect}`;
            }
            this.props.history.push(url);
          } 
        })
        .catch((err) => {
          this.setState({isButtonLoading: false});
          console.log(err);
        });
    }
  };

  
  onGoogleFailure = (resp) => {
    window.analytics.track('signup - google fail');
    if(resp.error && resp.error === "popup_closed_by_user") return;
    showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
  }

  onGoogleSuccess = (profileObj) => {
    window.analytics.track('signup - google success');
    const password = generateRandomPassword();
    this.setState({
      email: profileObj.email,
      name: profileObj.name,
      password: password,
    })
    this.handleSignUp();
  }

  handleCredentialResponse = (response) => {
    const responsePayload = jwt.decode(response.credential);
    // console.log("ID: " + responsePayload.sub);
    // console.log('Full Name: ' + responsePayload.name);
    // console.log('Given Name: ' + responsePayload.given_name);
    // console.log('Family Name: ' + responsePayload.family_name);
    // console.log("Image URL: " + responsePayload.picture);
    // console.log("Email: " + responsePayload.email);
    if(responsePayload){
      const profileObj = {
        name: responsePayload.name,
        email: responsePayload.email
      }
      this.onGoogleSuccess(profileObj)
    }else{
        showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
    }
  }

  componentDidMount(){
    var head = document.getElementsByTagName('head').item(0);
    const script = document.createElement("script");
    script.setAttribute("src", "https://accounts.google.com/gsi/client")
    head.appendChild(script)
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
        callback: this.handleCredentialResponse
      });
      const buttonWidth = window.innerWidth <= 768 ? 300 : 400;
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "medium", width: buttonWidth, text: "signup_with" }  // customization attributes
      );
    }
    window.analytics.track('landed on direct signup');
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.handleSignUp();
  }

  render() {
    const { history, headerElement, showLoginOption=true } = this.props;
    const {
      email,
      name,
      password,
      emailError,
    } = this.state;
    const shouldSignUpDisable = emailError || !email || !name || !password;
    return (
      <Wrapper>
        <Header>
          {headerElement ? (
            headerElement()
          ) : (
            constants.SIGN_UP_CARD_HEADER
          )}
        </Header>
        <Divider />
        <ColumnWrapper style={{width: "100%"}}>
        <div id="buttonDiv"></div>
        </ColumnWrapper>
        <form onSubmit={this.handleSubmit} >
        <Input
          label={constants.NAME_LABEL}
          type={"text"}
          value={name}
          onPress={(e) => {window.analytics.track('signup - typing name');
          this.setFormProperty("name", e.target.value)}}
          customInputContainerStyle={signUpStyles.inputWrapper}
          onBlur={() => {window.analytics.track('signup - typed name');}}
        />
        <Input
          label={constants.EMAIL_ADDRESS_LABEL}
          type={"email"}
          name="email"
          value={email}
          onPress={(e) => {window.analytics.track('signup - typing email');this.setFormProperty("email", _.toLower(_.trim(e.target.value)))}}
          customInputContainerStyle={signUpStyles.inputWrapper}
          errorMessage={constants.EMAIL_ERROR_MESSAGE}
          error={emailError}
          setError={this.setEmailError}
          onBlur={() => {window.analytics.track('signup - typed email');}}
        />
        {/* <Input
          label={constants.PHONE_LABEL}
          customInputContainerStyle={signUpStyles.inputWrapper}
          customInput={{display: "none"}}
        /> */}
        {/* <TelInput error={this.state.phoneError}>
          <IntlTelInput
            defaultCountry={defaultCountry}
            containerClassName="intl-tel-input telInputContainer"
            inputClassName="form-control telInput"
            value={this.state.phoneNumber}
            separateDialCode={true}
            onSelectFlag={(num, country) => {
              this.setState({phoneNumber: ""});
              this.setState({phoneNumberWithCode: "+" + country.dialCode})
            }}
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              this.setState({phoneError: ""});
              if(this.phoneNumberCheck(value)) {
                this.setState({phoneNumber: value});
                this.setState({phoneNumberWithCode: number});
              }
            }}
            onPhoneNumberBlur={(status, value, countryData, number, id)=> {
              this.setState({phoneError: ""});
              if(status === false){
                this.setState({phoneError: "Please Enter Valid Phone number"})
              }
              this.setState({phoneNumberWithCode: number});
            }}
            format
            formatOnInit={false}
            separateDialCode
          />
          {this.state.phoneError ?
            <TelInputError>
              {this.state.phoneError}
            </TelInputError>
            :
            <></>
          }
        </TelInput> */}
        {/* <Input
          label={constants.PHONE_LABEL}
          type={"tel"}
          value={phoneNumber}
          onPress={(e) => this.setFormProperty("phoneNumber", e.target.value)}
          customInputContainerStyle={signUpStyles.inputWrapper}
          errorMessage={constants.INVALID_PHONE_NUMBER}
          maxLength={10}
          error={phoneError}
          setError={this.setPhoneError}
        /> */}
        <Input
          label={constants.PASSWORD_LABEL}
          type={"password"}
          value={password}
          onPress={(e) => this.setFormProperty("password", e.target.value)}
          customInputContainerStyle={signUpStyles.inputWrapper}
          onBlur={() => {window.analytics.track('signup - typed password');}}
        />
        <ButtonWrapper>
          <Button
            customButtonStyle={
              shouldSignUpDisable
                ? signUpStyles.signUpButtonDisabled
                : signUpStyles.signUpButtonEnabled
            }
            type="submit"
            title={constants.SIGN_UP}
            disabled={shouldSignUpDisable}
            isLoading={this.state.isButtonLoading}
          />
        </ButtonWrapper>
        </form>
        {
          showLoginOption && 
            <div style={{display: "flex", alignItems: "center"}}>
              <p style={{fontSize: "0.875rem", color: "#828282", fontWeight: "500", marginRight: "5px"}}>Already a user?</p>
              
              <Button
                onPress={() => {
                  const query = new URLSearchParams(this.props.location.search);
                  const redirect = query.get("redirect");
                  let url = routes.login;
                  if(redirect){
                    url = `${routes.login}?redirect=${redirect}`;
                  }
                  history.push(url);
                }}
                customButtonStyle={signUpStyles.forgotPasswordButton}
                title={constants.LOG_IN}
              />
            </div>
        }
        <ColumnWrapper>
          <Text style={signUpStyles.fromErrorText}>
            {this.state.formError}
          </Text>
          <Text style={signUpStyles.disclaimerText}>
            <div >{constants.DISCLAIMER_TEXT}</div>
            <a href={"https://api.snaptrude.com/media/pdf/TermsAndConditions.pdf"} onClick={() => {
              window.analytics.track('clicked terms and conditions on signup page');
            }} target="_blank">
              <span style={signUpStyles.termsAndConditionsButton}>Terms of service</span>
            </a>
            {" & "}
            <a href={"https://api.snaptrude.com/media/pdf/PrivacyPolicy.pdf"} onClick={() => {
              window.analytics.track('clicked privacy and policy on signup page');
            }} target="_blank">
              <span style={signUpStyles.termsAndConditionsButton}>Privacy policy</span>
            </a>
          </Text>
        </ColumnWrapper>
      </Wrapper>
    );
  }
}

SignUp.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(SignUp);
