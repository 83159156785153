import BABYLON from "../../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../../modules/utilityFunctions/Store.js"
import { commandUtils } from "../../modules/commandManager/CommandUtils.js";
import { CommandManager } from "../../modules/commandManager/CommandManager.js";
import { removecollinear, convertArray2ToArray3, removeDuplicateVertices, removeDuplicateVerticesModified, removeZeroArrays, zeroDownZ, changeOrderForHoles } from "./twoServices.js";
import { onSolid, getRoomHeight, isTerrainMesh } from "../../modules/extrafunc.js";
import { createCustomMesh } from "../massModeling.js";
import { makeid } from "../arrayFuncs.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import { Mass } from "../../modules/snaptrudeDS/mass.ds.js";
import { verifyBRepIntegrity } from "../brepOperations.js";
import { assignProperties } from "../sceneStateFuncs.js";
import { virtualSketcher } from "../../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { setLayerTransperancy } from "../sceneFuncs.js";
import { room_types_db } from "../obj_base.js";
import { handleIncorrectDetection, doMassPostProcessing } from "./twoDrawRooms.js";

let executeDrawCircleEvent = function (roomDS, { parentMesh = null } = {}) {
    let cmdsToExecute = [];

    // creations command
    roomDS.mesh.isCircular = true;
    let commandData = commandUtils.creationOperations.getCommandData(roomDS.mesh);
    let detectCommandName = "sketchObjectDetection";

    const creationCommand = commandUtils.creationOperations.getCommand(
        detectCommandName,
        commandData
    );
    cmdsToExecute.push(creationCommand);

    // integrationGeometryChangeCommand
    let integrationGeometryChangeCommand = virtualSketcher.addWithGeometryEdit(roomDS);
    cmdsToExecute.push(integrationGeometryChangeCommand);

    // draw plinth command
    if (roomDS.storey === 1) {
        cmdsToExecute.push(Mass.drawPlinth(roomDS.mesh));
    }

    // attach parent
    if (parentMesh) {
        let commandData = commandUtils.parentChildOperations.getCommandData(roomDS.mesh);

        roomDS.mesh.setParent(parentMesh);

        if (["mass", "wall", "floor", "roof"].includes(parentMesh.type.toLowerCase()) && !isTerrainMesh(parentMesh)) { parentMesh.childrenComp.push(roomDS.mesh); }

        commandData = commandUtils.parentChildOperations.getCommandData(roomDS.mesh, { data: commandData });

        let parentChangeCommand = commandUtils.parentChildOperations.getCommand("drawMassParenting", commandData);

        cmdsToExecute.push(parentChangeCommand);
    }

    const commands = _.compact([...cmdsToExecute]);
    const yets = commands.map((_) => false);

    CommandManager.execute(commands, yets);
};

let _createMass = function (roomType, height, zeroedZ, zeroedZHoles) {
    const STRUCTURE_ID = store.activeLayer.structure_id;
    const IS_CURVE = false;
    const LEVEL_ID = '01';

    let data = {
        polygon: zeroedZ,
        height: height,
    };
    data.polygon = removecollinear(data.polygon);

    zeroedZHoles = removeZeroArrays(zeroedZHoles);
    let mesh = createCustomMesh(data.polygon, data.height, null, zeroedZHoles);

    if (data.uniqueId) mesh.uniqueId = data.uniqueId;
    mesh.pointsUsed = data.polygon;
    mesh.checkCollisions = true;
    mesh.name = 'circularMass';
    mesh.room_type = roomType;
    mesh.room_id = makeid(3);
    mesh.room_curve = IS_CURVE;
    mesh.room_path = JSON.stringify(data.polygon);
    mesh.height = height;
    mesh.showBoundingBox = true;
    mesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    mesh.room_unique_id = mesh.room_id;
    mesh.structure = STRUCTURE_ID;
    mesh.offsetFlag = false;
    mesh.storey = store.$scope.isTwoDimension ? store.activeLayer.storey : '1';
    mesh.structure_id = STRUCTURE_ID;
    mesh.type = "Mass";

    let roomMasses = JSON.parse(localStorage.getItem(mesh.name));
    if (!roomMasses) {
        roomMasses = [mesh.uniqueId];
        localStorage.setItem(mesh.name, JSON.stringify(roomMasses));
    }
    roomMasses.push(mesh.uniqueId);
    localStorage.setItem(mesh.name, JSON.stringify(roomMasses));

    let bbinfo = mesh.getBoundingInfo();
    let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure =
        structureCollection.getStructureById(STRUCTURE_ID);
    const talkingAboutLevel = talkingAboutStructure.getLevelByName(LEVEL_ID);
    talkingAboutLevel.addObjectToLevel(new Mass(mesh), false);

    let roomDS = mesh.getSnaptrudeDS();
    roomDS.room_type = mesh.room_type;
    roomDS.assignProperties();
    roomDS.room_id = mesh.room_id;
    roomDS.type = "Mass";
    roomDS.setIsCircularMass(true);

    if (!verifyBRepIntegrity(roomDS.brep)) {
        handleIncorrectDetection(mesh);
        return false;
    }

    doMassPostProcessing(roomDS);

    assignProperties(mesh, null, "mass");
    onSolid(mesh);
    setLayerTransperancy(mesh);
    mesh.enableEdgesRendering(0.05);

    return roomDS;
};

const drawCircleMass = (pointArray, yPos, _height) => {
    if (!_height) {
        let roomProperties = room_types_db.searchForKeyValuePair("name", "Bedroom");
        if (roomProperties) {
            height = roomProperties.props.height * store.floor_height;
        }
    }

    let newPoly = [];
    newPoly = pointArray[0];

    //For rooms with holes
    let zeroedZHoles = [];
    if (pointArray[1] !== undefined) {
        let holes = pointArray[1];
        holes = removeZeroArrays(holes);
        for (let i = 0; i < holes.length; i++) {
            holes[i] = convertArray2ToArray3(holes[i]);
            let h = zeroDownZ(holes[i], yPos);
            h.pop();
            h = removeDuplicateVertices(h);
            h = removeDuplicateVerticesModified(h);

            h = changeOrderForHoles(h);
            zeroedZHoles.push(h);
        }
    }

    let yPosExists = !!yPos || yPos === 0;
    let zeroedZ = (yPosExists) ? zeroDownZ(newPoly, yPos) : newPoly;

    zeroedZ.pop(); //last point is the same as the first

    zeroedZ = removeDuplicateVertices(zeroedZ);
    zeroedZ = removeDuplicateVerticesModified(zeroedZ);

    let roomType = "Default";
    let height = _height ? _height : getRoomHeight(roomType);

    return _createMass(roomType, height, zeroedZ, zeroedZHoles);
};

// Check for implementation details: https://stackoverflow.com/questions/27714014/3d-point-on-circumference-of-a-circle-with-a-center-radius-and-normal-vector
const getPointsOnArc = (center, radius, normal, swapYandZ = false, minPoints = 80, startPoint = null, endPoint = null, totalAngle = null) => {
    const points = [];
    const angleAccuracy = 2 * Math.PI / minPoints;

    let v3x = normal._x;
    let v3y = normal._y;
    let v3z = normal._z;

    // Calculate v1.
    let s = 1 / (v3x ** 2 + v3z ** 2);
    let v1x = s * v3z;
    let v1y = 0;
    let v1z = s * -v3x;

    if (parseFloat(v3x.toFixed(5)) == 0 && parseFloat(v3z.toFixed(5)) == 0) {
        s = 1 / (v3x ** 2 + v3y ** 2);
        v1x = s * v3y;
        v1y = 0;
        v1z = s * -v3x;
    }

    // Calculate v2 as cross product of v3 and v1.
    let v2x = v3y * v1z - v3z * v1y;
    let v2y = v3z * v1x - v3x * v1z;
    let v2z = v3x * v1y - v3y * v1x;

    var startAngle = startPoint ? Math.atan2(startPoint.y - center.y, startPoint.x - center.x) : 0;

    const shouldBreak = (angle) => {
        if (totalAngle == null) return false;

        return angle - startAngle > totalAngle;
    }

    for (let i = 0; i < minPoints; i++) {
        let angle = startAngle + i * angleAccuracy;

        if (shouldBreak(angle)) break;

        const x = center.x + radius * (v1x * Math.cos(angle) + v2x * Math.sin(angle))
        const y = center.y + radius * (v1y * Math.cos(angle) + v2y * Math.sin(angle))
        const _z = center.z + radius * (v1z * Math.cos(angle) + v2z * Math.sin(angle))

        let newPoint = swapYandZ ? new BABYLON.Vector3(x, _z, y) : new BABYLON.Vector3(x, y, _z);

        points.push(newPoint);
    }

    // if it's a circle, add first point is also the last point.
    if (startPoint == null) points.push(points[0]);

    return points;
};

const drawCircle = (origin, radius, normal, yCoordinate, _height) => {
    const _origin = new BABYLON.Vector3(origin._x, origin._z, origin._y);
    const points = getPointsOnArc(_origin, radius, normal);
    const pointArray = points.map(point => point.asArray());

    return drawCircleMass([pointArray, undefined], yCoordinate, _height);
};

const drawCircleOutline = (origin, radius, normal) => {
    const _origin = new BABYLON.Vector3(origin._x, origin._z, origin._y);
    const points = getPointsOnArc(_origin, radius, normal, true);

    var lines = BABYLON.MeshBuilder.CreateLines("circle", { points: points }, store.scene);
    lines.color = new BABYLON.Color3(0, 0, 0);
    return lines;
}

export {
    drawCircle,
    drawCircleOutline,
    executeDrawCircleEvent,
    getPointsOnArc
};
