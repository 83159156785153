import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input";
import { RecentBox } from "../../components/SortByBox";
import * as styles from "./styles";
import { colors } from "../../themes/constant";
import checkboxActive from "../../assets/icons/checkboxActive.svg";
import checkboxInactive from "../../assets/icons/checkboxInactive.svg";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { handleToolbar } from "../../snaptrude/libs/handleToolbar";
import ProgressBar from "../../components/ProgressBar";
import {useSelector} from "react-redux";
import { saveAsDjango } from "../../services/projects.service";
import { saveAsSaveMicro } from "../../services/saveMicro.service";
import Toggle from "../../components/Toggle";
import covetoolService from "../../services/covetool.service";
import { openInNewTab } from "../../common/utils/helper";

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  &>p{
    flex: 1;
  }
  &>div{
    flex: 1;
  }
`;

function SaveAs({ isOpen, onClose }) {
  
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressText, setProgressText] = useState("Saving...");
  
  const currentProjectName = useSelector((state) => state.projectMetadata.name);
  const [saveAsName, setSaveAsName] = useState(currentProjectName + " Copy");
  const [shareWithSamePeople, setShareWithSamePeople] = useState(false);
  const [copyCommentsAndMarkups, setCopyCommentsAndMarkups] = useState(false);
  const [copyDaylightAnalysis, setCopyDaylightAnalysis] = useState(false);

  const handleSaveAs = () => {
    setShowProgressBar(true);
    saveAsDjango(store.floorkey, saveAsName).then(res => {
      if (res.success){
        setProgress(50);
        saveAsSaveMicro(store.floorkey, res.newFloorkey, shareWithSamePeople, copyCommentsAndMarkups).then(resp => {
          if (resp.success){
            setProgress(100);
            if(res.newFloorkey) openInNewTab(`/model/${res.newFloorkey}`)
            if(copyDaylightAnalysis) covetoolService.copyDaylightAnalysis(store.floorkey, res.newFloorkey)
          }
        });
      }

      setShowProgressBar(false);
      onClose();
    });
  };
  
  const getBody = () => {
    return (
      <FlexContainer
        flexDirection="column"
        alignItems="normal"
        justifyContent="flex-start"
        style={{marginBottom: "-4rem"}}
      >
      <FlexContainer alignItems="center">
        <Text opacity={1}>Project Name</Text>
        <Input type="text" placeholder="Save as project name" value={saveAsName} customOnChange={(e) => {setSaveAsName(e.target.value)}} />
      </FlexContainer>
      <FlexContainer alignItems="center" style={{marginTop: "3.5rem"}}>
        <Text opacity={1}>Share with the same people</Text>
        {/* <Toggle state={shareWithSamePeople} handleClick={() => {setShareWithSamePeople(!shareWithSamePeople)}} /> */}
        <div>
          <img src={shareWithSamePeople ? checkboxActive : checkboxInactive} onClick={() => {setShareWithSamePeople(!shareWithSamePeople)}} />
          {/* <input type="checkbox" checked={shareWithSamePeople} onChange={() => {setShareWithSamePeople(!shareWithSamePeople)}} /> */}
        </div>

      </FlexContainer>
      <FlexContainer alignItems="center">
        <Text opacity={1}>Copy comments and markups</Text>
        {/* <Toggle state={copyCommentsAndMarkups} handleClick={() => {setCopyCommentsAndMarkups(!copyCommentsAndMarkups)}} /> */}
        <div>
          <img src={copyCommentsAndMarkups ? checkboxActive : checkboxInactive} onClick={() => {setCopyCommentsAndMarkups(!copyCommentsAndMarkups)}} />
          {/* <input type="checkbox" checked={copyCommentsAndMarkups} onChange={() => {setCopyCommentsAndMarkups(!copyCommentsAndMarkups)}} /> */}
        </div>
      </FlexContainer>
        <FlexContainer alignItems="center">
        <Text opacity={1}>Copy daylight analysis</Text>
        {/* <Toggle state={copyCommentsAndMarkups} handleClick={() => {setCopyCommentsAndMarkups(!copyCommentsAndMarkups)}} /> */}
        <div>
          <img src={copyDaylightAnalysis ? checkboxActive : checkboxInactive} onClick={() => {setCopyDaylightAnalysis((prev) => !prev)}} />
          {/* <input type="checkbox" checked={copyCommentsAndMarkups} onChange={() => {setCopyCommentsAndMarkups(!copyCommentsAndMarkups)}} /> */}
        </div>
      </FlexContainer>
        
      </FlexContainer>
    );
  };

  const getFooter = () => {
    
    return (
      <>
        {showProgressBar ?
          <ProgressBar bgColor={colors.red} completed={progress} text={progressText} setParentShowProgressBar={p => {setShowProgressBar(p)}}/> :
          <Button
            title="Save"
            primary
            customButtonStyle={styles.exportStyles.exportCustomButtonStyle}
            onPress={handleSaveAs}
          />
        }
        </>
    );
  };
  return (
    <Modal
      modalHeaderText="Save As"
      modalBody={getBody()}
      customBodyStyle={styles.exportStyles.modalBodyStyle}
      modalFooter={getFooter()}
      customFooterStyle={
        showProgressBar
          ? styles.exportStyles.progressStyle
          : styles.exportStyles.footerStyle
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

SaveAs.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SaveAs;
