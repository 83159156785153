const BasicMassComp = {
  _name: "Basic Mass",
  _type: "Mass",
  _id: "",
  _layers: [
    {
      check: false,
      value: "Concrete",
      subtype: "",
      thickness: 200,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true,
    },
  ],
  _materials: [],
  _properties: {},
};

const BasicWallComp = {
  _name: "Basic Wall",
  _type: "Wall",
  _id: "",
  _thickness: 200,
  _layers: [
    {
      check: false,
      value: "Paint",
      subtype: "",
      thickness: 2,
      quantity: "volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Plaster",
      subtype: "",
      thickness: 12,
      quantity: "volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Brick",
      subtype: "",
      thickness: 200,
      quantity: "volume",
      unit: "Cubic metres",
      core: true,
    },
    {
      check: false,
      value: "Plaster",
      subtype: "",
      thickness: 12,
      quantity: "volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Paint",
      subtype: "",
      thickness: 2,
      quantity: "volume",
      unit: "Cubic metres",
      core: false,
    },
  ],
  _materials: [],
  _properties: {},
};

const BasicFloorComp = {
  _name: "Basic Floor",
  _type: "floor",
  _id: "",
  _thickness: "12",
  _layers: [
    {
      check: false,
      value: "Tile",
      subtype: "",
      thickness: 25,
      quantity: "Area",
      unit: "Square metres",
      core: false,
    },
    {
      check: false,
      value: "Screed",
      subtype: "",
      thickness: 25,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
  ],
  _materials: [],
  _properties: {},
};

const BasicCeilingComp = {
  _name: "Basic Ceiling",
  _type: "Basic Ceiling",
  _id: "",
  _thickness: "1",
  _layers: [],
  _materials: [],
  _properties: {},
};

const BasicRoofComp = {
  _name: "Basic Roof",
  _type: "Roof",
  _id: "6",
  _thickness: "",
  _layers: [
    {
      check: false,
      value: "Screed",
      subtype: "",
      thickness: 25,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Water Proofing",
      subtype: "",
      thickness: 2,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Screed",
      subtype: "",
      thickness: 25,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "RCC",
      subtype: "",
      thickness: 200,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true,
    },
    {
      check: false,
      value: "Plaster",
      subtype: "",
      thickness: 12,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Paint",
      subtype: "",
      thickness: 2,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
  ],
  _materials: [],
  _properties: {},
};

const BasicGradeSlabComp = {
  _name: "Basic Grade Slab",
  _type: "Roof",
  _id: "6",
  _thickness: "",
  _layers: [
    {
      check: false,
      value: "Screed",
      subtype: "",
      thickness: 25,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "PCC",
      subtype: "",
      thickness: 200,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true,
    },
  ],
  _materials: [],
  _properties: {},
};

const BasicStairComp = {
  _name: "Staircase",
  _type: "Staircase",
  _id: "",
  _thickness: "",
  _layers: [
    {
      check: false,
      value: "Tiles",
      subtype: "",
      thickness: 12,
      stairProp: "Riser",
      quantity: "Area",
      unit: "Square metres",
      core: false,
    },
    {
      check: false,
      value: "Tiles",
      subtype: "",
      thickness: 12,
      stairProp: "Tread",
      quantity: "Area",
      unit: "Square metres",
      core: false,
    },
    {
      check: false,
      value: "Paint",
      subtype: "",
      thickness: 2,
      stairProp: "Side",
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "Screed",
      subtype: "",
      thickness: 25,
      quantity: "Volume",
      unit: "Cubic metres",
      core: false,
    },
    {
      check: false,
      value: "RCC",
      subtype: "",
      thickness: "Variable",
      quantity: "Volume",
      unit: "Cubic metres",
      core: true,
    },
  ],
  _materials: [],
  _properties: {},
};

const SingleDoorComp = {
  _name: "Single Door",
  _type: "Door",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

const DoubleDoorComp = {
  _name: "Single Door",
  _type: "Door",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

const SlideDoorComp = {
  _name: "Single Door",
  _type: "Door",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

const SingleWindowComp = {
  _name: "Window",
  _type: "Window",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _plinth: "",
  _thickness: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

const DoubleWindowComp = {
  _name: "Window",
  _type: "Window",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _plinth: "",
  _thickness: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

const TripleWindowComp = {
  _name: "Window",
  _type: "Window",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _plinth: "",
  _thickness: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

const QuadWindowComp = {
  _name: "Window",
  _type: "Window",
  _id: "",
  _breadth: "",
  _depth: "",
  _lintel: "",
  _plinth: "",
  _thickness: "",
  _layers: [],
  _materials: [],
  _properties: {},
};

export {
  BasicCeilingComp,
  BasicWallComp,
  BasicStairComp,
  BasicRoofComp,
  BasicMassComp,
  BasicGradeSlabComp,
  BasicFloorComp,
  SingleDoorComp,
  DoubleDoorComp,
  SingleWindowComp,
  DoubleWindowComp,
  TripleWindowComp,
  QuadWindowComp,
  SlideDoorComp,
};
