import React from 'react';
import styled from 'styled-components';
import copylinkIcon from "../../assets/icons/copylink.svg";
import { copyToClipboard } from '../../snaptrude/modules/extrafunc';
import { colors } from '../../themes/constant';

const Wrapper = styled.div`
    color: ${colors.brightRed};
    font-family: "DM Sans","sans-serif";
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    img{
        margin: 0;
        margin-right: 5px;
    }
`

function CopyLink({style, textToCopy}) {
    
    const handleCopy = () => {
        copyToClipboard(textToCopy);
    }

    return (
        <Wrapper style={style} onClick={handleCopy}>
            <img src={copylinkIcon} alt="link" />
            Copy Link
        </Wrapper>
    )
}

export default CopyLink
