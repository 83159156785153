import BABYLON from "./babylonDS.module.js";
import { create, all } from "mathjs";
import _ from "lodash";
import { isFloatEqual, getAngleBetweenVectors } from "../libs/snapFuncs.js";
import { store } from "./utilityFunctions/Store.js";

const externalUtil = (function () {
  const CONSTANTS = {
    mathIntersectRoundOffFactor: 5,
  };
  
  // create a mathjs instance with configuration
  const config = {
    epsilon: 1
  };
  
  /*
  Note about the epsilon value-
  It's default value is 1e-12. So setting it to 1 is uncomfortable
  But intersect won't work for any other value
   */
  
  const math = create(all, config);

  /**
   *
   * args = [v1, v2, v1Dash, v2Dash] for vector-vector
   *
   * args = [v1, v2, plane] or [v1, v2, [p1, p2, p3]] for vector-plane
   *
   * options = {
   *     type : str
   *     ignoreY : bool
   * }
   *
   * @param args
   * @param options
   * @returns {null}
   */
  function getPointOfIntersection(args, options = {}) {
    let intersection = null;
    if (!options.type) options.type = "vector-vector";

    switch (options.type) {
      case "vector-vector":
        /*
          args = [v1, v1Dash, v, v2Dash]
           */
        let uniqueCheckArgs = _.uniqWith(args, (v, other) => {
          return v.almostEquals(other);
        });

        if (uniqueCheckArgs.length !== args.length) return null;
        
        const roundOffFactor = options.roundOffFactor || CONSTANTS.mathIntersectRoundOffFactor;

        let modifiedArgs = args
          .map((vector) => vector.asArray())
          .map((vectorArray) =>
            vectorArray.map((v) =>
              math.round(v, roundOffFactor)
            )
          );
        if (options.ignoreY)
          modifiedArgs = modifiedArgs.map((vectorArray) => [
            vectorArray[0],
            vectorArray[2],
          ]);

        intersection = math.intersect(...modifiedArgs);

        if (intersection) {
          if (options.ignoreY)
            intersection = new BABYLON.Vector3(
              intersection[0],
              0,
              intersection[1]
            );
          else intersection = BABYLON.Vector3.FromArray(intersection);

          /*if (args.inArray(v => v.almostEquals(intersection))){
              intersection = null;
              // filter for cases where intersection belongs to [a,b,c,d]
          }*/
        }

        break;

      case "vector-plane":
        let v1 = args[0];
        let v2 = args[1];

        let plane;
        if (_.isArray(args[2])) {
          plane = BABYLON.Plane.FromPoints(args[2][0], args[2][1], args[2][2]);
        } else {
          plane = args[2]; //babylon plane object
        }

        let vectorOfInterest = v2.subtract(v1);
        let normalToPlane = plane.normal;

        if (
          isFloatEqual(
            getAngleBetweenVectors(vectorOfInterest, normalToPlane),
            90,
            1
          )
        ) {
          //vector is parallel to plane, thus intersection will be at inf
          break;
        }

        let planeCoordinates = plane.asArray(); //babylon plane object
        planeCoordinates[3] = -planeCoordinates[3];
        intersection = math.intersect(
          v1.asArray(),
          v2.asArray(),
          planeCoordinates
        );

        if (intersection) {
          intersection = BABYLON.Vector3.FromArray(intersection);
        }

        break;

      default:
        intersection = null;
    }

    return intersection;
  }
  /*
    Accepts an array of array of polygons,
    where each polygon is an array of 2D points
     */
  function getUnionOfPolygons(polygons) {
    function _areTwoPointsEqual(p1, p2) {
      return (
        isFloatEqual(p1[0], p2[0], 0.01) && isFloatEqual(p1[1], p2[1], 0.01)
      );
    }

    function _areThreePointsCollinear(p1, p2, p3) {
      let areaOfTriangle =
        0.5 *
        (p1[0] * (p2[1] - p3[1]) +
          p2[0] * (p3[1] - p1[1]) +
          p3[0] * (p1[1] - p2[1]));
      return isFloatEqual(areaOfTriangle, 0, 0.01);
    }

    function _removeRedundantPoints(polygon) {
      polygon.regions.forEach((region) => {
        _.remove(region, (point, i, region) => {
          return _areTwoPointsEqual(point, _.nth(region, i - 1));
        });

        _.remove(region, (point, i, region) => {
          let p1 = _.nth(region, i - 1),
            p3 = _.nth(region, i + 1) || _.nth(region, 0);

          return _areThreePointsCollinear(p1, point, p3);
        });
      });
    }

    polygons = polygons.map((p) => {
      return {
        regions: [p],
        inverted: false,
      };
    });

    polygons.forEach((polygon) => _removeRedundantPoints(polygon));

    // store.bool.epsilon(1);

    // code snippet from the documentation
    let segments = store.bool.segments(polygons[0]);
    for (let i = 1; i < polygons.length; i++) {
      let seg2 = store.bool.segments(polygons[i]);
      let comb = store.bool.combine(segments, seg2);
      segments = store.bool.selectUnion(comb);

      let intermediatePolygon = store.bool.polygon(segments);
      _removeRedundantPoints(intermediatePolygon);
      segments = store.bool.segments(intermediatePolygon);
    }

    let finalPolygon = store.bool.polygon(segments);

    _removeRedundantPoints(finalPolygon);

    return finalPolygon.regions;
  }

  return {
    getPointOfIntersection,
    getUnionOfPolygons,
  };
})();
export { externalUtil };
