import React from "react";
import styled from "styled-components";
import { RIGHT_SIDEBAR_OPEN_WIDTH } from "../../containers/editor/components/constants";
import { colors } from "../../themes/constant";

const StyledSidebar = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
  .sidepanel {
    width: 0;
    z-index: 1;
    background-color: ${colors.fullWhite};
    overflow-x: hidden;
    box-shadow: 0px 4px 30px ${colors.lightGreyShadow};
    // transition: 0.5s ease;
  }

  .closed_sidenav {
    width: 0;
  }

  .open_sidenav {
    width: ${RIGHT_SIDEBAR_OPEN_WIDTH};
    height: 100%;
  }
`;

const RightSidebar = (props) => {
  const { sidenavOpen, mainContainerStyles, sidebarElelment } = props;
  return (
    <StyledSidebar>
      <div
        style={mainContainerStyles}
        className={`sidepanel ${
          sidenavOpen ? "open_sidenav" : "closed_sidenav"
        }`}
      >
        <div style={{ position: "sticky", top: 0, right: 0 }} />
        {sidebarElelment}
      </div>
    </StyledSidebar>
  );
};

export default RightSidebar;
