export const getElementByIdAsync = (id, tryCount=0) => new Promise(resolve => {
    const getElement = (tryCount) => {
    
      const element = document.getElementById(id);
      if(element) {
        resolve(element);
      } else {
        ++tryCount;
        if(tryCount<100) requestAnimationFrame(() => {getElement(tryCount)});
      }
    };
    getElement(tryCount);
  });