'use strict';
/**
 *
 * Simple API to do performance checks of multiple flows at the same time
 * Call start with an identifier for each process you want to track
 * Call stop to stop tracking the process
 * Call lap to reset and continue tracking the process
 *
 * Call measure to measure the time taken by a function easily
 *
 * @type {{stop: stop, start: start, lap: lap}}
 */
const stopwatch = (function (){

    const defaultProcessId = 'defaultProcess';

    let processIdStartTimeMap = new Map();

    const _now = function (){
        return performance.now();
    };

    const _diff = function (processId){
        const startTime = processIdStartTimeMap.get(processId);
        if (startTime){
            const endTime = _now();
            console.log(`Call to ${processId} took ${((endTime - startTime) / 1e3).toFixed(6)} seconds.`);

            processIdStartTimeMap.set(processId, endTime);
        }
    };

    const start = function(processId = defaultProcessId){
        const startTime = _now();
        processIdStartTimeMap.set(processId, startTime);
    };

    const lap = function(processId = defaultProcessId){
        _diff(processId);
    };

    const stop = function(processId = defaultProcessId){
        _diff(processId);
        processIdStartTimeMap.delete(processId);
    };

    const measure = function (callback){
        start();
        callback();
        stop();
    };

    return {
        start,
        lap,
        stop,

        measure
    };

})();

export {
  stopwatch,
};