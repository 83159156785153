/*
  *CERAMIC, PLASTIC, COLOR, GLAZING, METAL, WALLPAINT*
  value {color}
  *TEXTURE, CONCRETE, WOOD*
  value{path, vScale, uScale, vOffset, uOffset, wAng, diffuseColor?}
*/

const speckleConstants = {
  material: {
    WALLPAINT: "wallpaint", 
    TEXTURE: "texture",
    WOOD: "wood",
    CONCRETE: "concrete",
    COLOR: "color",
    METAL:"metal",
    CERAMIC : "ceramic",
    GLAZING : "glazing",
    PLASTIC : "plastic",
  },
  speckleType:{
      WALL: "Objects.BuiltElements.Wall:Objects.BuiltElements.Revit.RevitWall",
      FLOOR : "Objects.BuiltElements.Floor:Objects.BuiltElements.Revit.RevitFloor",
      FAMILYINSTANCE: "Objects.BuiltElements.Revit.FamilyInstance",
      LEVEL : "Objects.BuiltElements.Level:Objects.BuiltElements.Revit.RevitLevel",
      DIRECTSHAPE : "Objects.BuiltElements.Revit.DirectShape",
      CEILING : "Objects.BuiltElements.Ceiling:Objects.BuiltElements.Revit.RevitCeiling",
  },
  speckleCategory: {
    FURNITURE : "Furniture",
    CASEWORK : "Casework",
    FURNITURES : ["Furniture", "Casework"],
    FURNITURE_SYSTEM : ["Furniture Systems"]
  },
  speckleUnits:{
    "m":"meters",
    "mm": "millimeters",
    "cm":"centimeters",
    "in": "inches",
    "ft" : "feet-inches",
  },
  convertIntomm(value, unit){
    if(unit == "m") return value*1000
    if(unit == "cm") return value*100
    if(unit == "in") return value*25.4
    if(unit == "ft") return value*304.8
    if(unit == "mm") return value
    return value
  }
}

export const FOOT_TO_MM = 304.8;

export default speckleConstants