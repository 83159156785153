import BABYLON from "../babylonDS.module";
import {goIntoTwoD, goOutOfTwoD} from "../../libs/twoDimension";
import {store} from "../utilityFunctions/Store";
import axios from "axios";
import {FakeProgressEvent} from "../FakeProgressEvent/FakeProgressEvent";
import {updateExportProgressBar} from "../../../containers/genericModal/export";

const ELIGIBLE_TYPES = [
  "wall", "roof", "floor",
  "door", "window", "furniture",
  "staircase", "mass"
];

/*
  A list of free cameras to be created based on views
  to be used to create render images using the API
 */
const tempCameras = [];

/* Maintain a key-value pair of mesh and it's parent.
   Parenting for meshes will be removed as render API
   requires glb output with parent-free meshes. Parenting
   will be reassigned using this map at the end of export
 */
const _parentChildMap = new Map();

const componentTypeLowercase = type => type.toLowerCase();

const is2D = _ => store.$scope.isTwoDimension;

const generateViewCamerasForExport = () => {
  const viewData = store.$scope.sceneViewData.map(sv => sv.camData);

  viewData.forEach((cameraInfo, idx) => {
    const camera = new BABYLON.FreeCamera("camera" + idx,
      new BABYLON.Vector3(
        cameraInfo.position._x,
        cameraInfo.position._y,
        cameraInfo.position._z
      ), store.scene);
    camera.setTarget(new BABYLON.Vector3(
      cameraInfo.target._x,
      cameraInfo.target._y,
      cameraInfo.target._z
    ));
    tempCameras.push(camera);
  });
}


const exportGLB = (
  filename,
  directDownload=false,
  keepParenting=true,
  optimize=false
) => {
  // Switch to 3D view if in 2D.
  // This reverts material transparency changes
  // by setLayerTransparency() to avoid transparency in exports

  // Fake progress
  if (directDownload) {
    const fakeProgressEvent = new FakeProgressEvent(0, 90, "Exporting...",
      80, "exportProgress", updateExportProgressBar);
    fakeProgressEvent.start();
  }

  let isViewSwitched = false;
  if (is2D()) {
    goOutOfTwoD();
    isViewSwitched = true;
  }

  generateViewCamerasForExport();

  // TODO: Let's check this later
  // store.scene.materials.forEach(m => {
  //   m.specularPower = 0;
  //   m.specularIntensity = 0;
  // });

  const options = {
    shouldExportNode: function (node) {
      if (node instanceof BABYLON.FreeCamera) return true;
      if (!node.type) return false;
      if (!node.isVisible) return false;
      const isEligibleForExport = ELIGIBLE_TYPES.includes(
        componentTypeLowercase(node.type)
      );

      if (!isEligibleForExport) return;
      if (node.parent && !keepParenting) {
        _parentChildMap.set(node, node.parent);
        node.setParent(null);
      }
      return true;
    }
  };

  return new Promise((resolve, reject) => {
    BABYLON.GLTF2Export.GLBAsync(store.scene, filename, options)
      .then((glb) => {
        tempCameras.forEach(camera => {
          camera.dispose();
        });
        tempCameras.length = 0;

        if (isViewSwitched) goIntoTwoD();

        // Download glb file on browser directly
        if (directDownload) {
          if (!optimize) {
            glb.downloadFiles();
          }
          else {
            const formData = new FormData();
            formData.append('gltf', glb.glTFFiles[filename + '.glb'], filename);
            formData.append('metaData', null);
            formData.append('compress', true);

            const UPLOAD_URL = 'https://blender.snaptru.de/gltfExport/';
            const DOWNLOAD_URL = 'https://blender.snaptru.de/download/';

            axios.post(UPLOAD_URL, formData, {responseType: "json"})
              .then(async (data) => {
                const blob = await fetch(DOWNLOAD_URL).then(r => r.blob());
                const url = window.URL.createObjectURL(blob);
                const dlAnchorElem = document.getElementById("downloadAnchorElem");
                dlAnchorElem.setAttribute("href", url);
                dlAnchorElem.setAttribute("download", filename + ".glb");
                dlAnchorElem.click();
                const evtFound = FakeProgressEvent.find("exportProgress");
                if (evtFound) evtFound.stop();
                updateExportProgressBar(100, 'Exporting', true);
                updateExportProgressBar(0, 'Exporting', false);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          resolve();
        }
        const glbNameAccessor = filename + ".glb";
        resolve(glb.glTFFiles[glbNameAccessor]);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        if (isViewSwitched) goIntoTwoD();

        // Assign back parent to meshes if any and empty the map
        if (!keepParenting) {
          for (const [key, value] of _parentChildMap) {
            key.setParent(value);
          }
          _parentChildMap.clear();
        }
      });
  });
};

export default exportGLB;
