import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sceneViewData: [],
  renders: {
    list: [],
    isRunning: false
  }
}

export const screenshotUiDataSlice = createSlice({
  name: "screenshotUiData",
  initialState,
  reducers: {
    resetScreenshotUiData: state => initialState,
    updateScreenshotUiData: (state, action) => {
      const {sceneViewData} = action.payload;
      state.sceneViewData = sceneViewData.map(data => data);
    },
    populateRenders: (state, action) => {
      const { renders } = action.payload;
      state.renders.list = renders;
    },
    addNewRender: (state, action) => {
      const { render } = action.payload
      state.renders.list.push(render);
    },
    startRenderLoading: (state, action) => {
      state.renders.isRunning = true;
    },
    stopRenderLoading: (state, action) => {
      state.renders.isRunning = false;
    }
  }
})

export const { resetScreenshotUiData, updateScreenshotUiData, populateRenders, addNewRender,
                startRenderLoading, stopRenderLoading} = screenshotUiDataSlice.actions;
export default screenshotUiDataSlice.reducer;