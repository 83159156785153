let BrickWall = {
  "_name": "BrickWall",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let BrickWallFace = {
  "_name": "BrickWallFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let StoneWall = {
  "_name": "StoneWall",
  "_layers": [
      {check:false, value:"Stone",
      subtype: "",
      thickness:300,
      quantity: "Volume",
      unit: "Cubic metres",
      core: true
      }],
}

let StoneWallFace = {
  "_name": "StoneWallFace",
  "_layers": [{check:false, value:"Stone Cladding",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Stone",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let TilesWall = {
  "_name": "Tiles",
  "_layers": [{check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let TilesWallFace = {
  "_name": "TilesWallFace",
  "_layers": [{check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Screed",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Tile",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let WoodWall = {
  "_name": "WoodWallFace",
  "_layers": [{check:false, value:"Gypsum Board",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Wood Studs",
    subtype: "",
    thickness:200,
    quantity: "Count",
    unit: "Each",
    core: true
  },{check:false, value:"Insulation",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Gypsum Board",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }],
}

let WoodWallFace = {
  "_name": "WoodWallFace",
  "_layers": [{check:false, value:"Wood Cladding",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Wood",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }],
}

let ConcreteWall = {
  "_name": "ConcreteWall",
  "_layers": [{check:false, value:"Concrete",
    subtype: "",
    thickness:250,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }]
}

let ConcreteWallFace = {
  "_name": "ConcreteWallFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Concrete",
    subtype: "",
    thickness:5,
    quantity: "Volume",
    unit: "Cubic metres",
    core: false
  }]
}

let ColorsWall = {
  "_name": "ColorsWall",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let ColorsWallFace = {
  "_name": "ColorsWallFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let PaintWall = {
  "_name": "ColorsWall",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let PaintWallFace = {
  "_name": "ColorsWallFace",
  "_layers": [{check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Plaster",
    subtype: "",
    thickness:12,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Paint",
    subtype: "",
    thickness:2,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let MetalWall = {
  "_name": "MetalWall",
  "_layers": [{check:false, value:"Metal",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

let MetalWallFace = {
  "_name": "MetalWallFace",
  "_layers": [{check:false, value:"Metal Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Metal Cladding",
    subtype: "",
    thickness:4,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

let GlassWall = {
  "_name": "GlassWall",
  "_layers": [{check:false, value:"Glass",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    transparency: 0.3,
    core: true
  }]
}

let GlassWallFace = {
  "_name": "GlassWallFace",
  "_layers": [{check:false, value:"Glass",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  },{check:false, value:"Brick",
    subtype: "",
    thickness:200,
    quantity: "Volume",
    unit: "Cubic metres",
    core: true
  }, {check:false, value:"Support",
    subtype: "",
    thickness:19,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }, {check:false, value:"Glass",
    subtype: "",
    thickness:25,
    quantity: "Area",
    unit: "Square metres",
    core: false
  }]
}

var GroundcoverWall = {
  "_name": "GroundcoverWall",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  }]
}

var GroundcoverWallFace = {
  "_name": "GroundcoverRoofWall",
  "_layers": [{check:false, value:"Groundcover",
    subtype: "",
    thickness:20,
    quantity: "Area",
    unit: "Square metres",
    core: true
  },{check:false, value:"Soil",
      subtype: "",
      thickness:25,
      quantity: "Area",
      unit: "Square metres",
    },
  ]
}

export {
  BrickWall,
  BrickWallFace,
  StoneWall,
  StoneWallFace,
  TilesWall,
  TilesWallFace,
  WoodWall,
  WoodWallFace,
  ConcreteWall,
  ConcreteWallFace,
  MetalWall,
  MetalWallFace,
  ColorsWall,
  ColorsWallFace,
  PaintWall,
  PaintWallFace,
  GlassWall,
  GroundcoverWall,
  GroundcoverWallFace
}
