"use strict";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { StateMachine } from "../Classes/StateMachine.js";
import { disposeAllVertexIndicators,indicateAllVertices,disposeSnappingObjects,showVertexIndicator,getComponentsLinkedToEdge,handleRoofPropertyChangeCommand,createVertexIndicatorInstance,removeVertexIndicatorInstance } from "./moveOperations/moveUtil.js";
import {
  getFaceVerticesFromFacetID,
  findNearestEdgeFromBRep,
  getLowerVertexOfTheEdge,
  getFaceObjectFromVectors,
  splitFaceByFaceObject,
  splitFaceByFacetId,
  getEdgeObjectFromVertexPositions,
  deleteEdge,
  verifyBRepIntegrity,
  deleteVertex,
  getVertexObjectFromPositionV3,
  getFaceVerticesFromFace, getVertexFaces, getEdgeFaces
} from "../../libs/brepOperations.js";
import {
  doesComponentHaveBrep,
  areTwoLinesCollinear,
  isMeshCurved,
  getAngleBetweenEdges,
  getBabylonPlane, getDistanceBetweenVectorAndFace, getEdgesFromFaceVertices
} from "../extrafunc.js";
import { findPrioritizedSnapPoint } from "../../libs/snapFuncsPrimary.js";
import { uiIndicatorsHandler } from "../uiIndicatorOperations/uiIndicatorsHandler.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { colorUtil } from "../utilityFunctions/colorUtility.js";
import {getAngleBetweenVectors, isFloatEqual, projectionOfPointOnLine} from "../../libs/snapFuncs.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { moveFace } from "./moveOperations/moveFace.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { geometryUpdater } from "../sketchMassBIMIntegration/geometryUpdater.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { setLayerTransperancy } from "../../libs/sceneFuncs.js";
import {
  areEdgesSimilar,
  getDynamicThreshold,
  getFaceAreaFactor,
  initializeSnappingEngine,
  turnOffSnappingEngine
} from "../../libs/snapUtilities.js";
import { scenePickController } from "../utilityFunctions/scenePickController.js";
import {GLOBAL_CONSTANTS} from "../utilityFunctions/globalConstants";
import {externalUtil} from "../externalUtil";

const splitFaceOperator = (function () {
  
  let _currentSnappedPoint;
  let _meshWithVerticesShown;
  let _componentOfInterest;
  let _brep;
  let _pickInfo;
  let _snapMetadata;
  
  const _3dVariables = {
    pathPoints: [],
    outlineMesh: null,
    faceBeingSplit: null,
    snapData: {
      beginVertex: null,
      beginEdge: null,
      endVertex: null,
      endEdge: null,
    },
  };

  const CONSTANTS = {
    operatingSpace: null,
  };

  const init = () => {
    CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
  }

  const EVENTS = {
    0: _cleanUpWithoutVertexIndicators,
    1: _showAllVertices,
    2: _getSnapPoint,
    3: _addVerticalEdge,
    4: _addPathPoint,
  };
  
  let STATE_MACHINE;
  
  function setStateMachineEvents(){
    
    let mouseSequence, touchSequence;
    
    if (_is2D()){
      mouseSequence = {
        pointermove: {
          0: [2, 2, 0],
          2: [2, 2, 0],
        },
        pointerdown: {
          0: [1, 0, 0],
          2: [3, [0, 0, 0], 2],
        },
      };
    
      touchSequence = {
        pointerdown: {
          0: [2, [3, 2, [0, 0, 0]], [1, 0, [0, 0, 0]]],
        },
    
        pointermove: {
          2: [2, 2, 0],
        },
    
        pointerup: {
          2: [3, [0, 0, 0], [0, 0, 0]],
        },
      };
    }
    else {
      mouseSequence = {
        pointermove: {
          0: [2, 2, 0],
          2: [2, 2, 0],
        },
        pointerdown: {
          2: [4, [0, 0, 0], 2],
        },
      };
    
      touchSequence = {
        pointerdown: {
          0: [2, [4, 2, [0, 0, 0]], [0, 0, 0]],
        },
    
        pointermove: {
          2: [2, 2, 0],
        },
    
        pointerup: {
          2: [4, [0, 0, 0], [0, 0, 0]],
        },
      };
    }
    
    STATE_MACHINE = new StateMachine(EVENTS, mouseSequence, touchSequence);
    
  }

  function _showAllVertices() {
    return new Promise((resolve, reject) => {
      if (_is2D()) {
        disposeAllVertexIndicators();

        _pickTheScene(true);

        if (_pickInfo.hit) {
          const faceVertices = getFaceVerticesFromFacetID(
            _pickInfo.faceId,
            _pickInfo.pickedMesh,
            CONSTANTS.operatingSpace
          );

          if (faceVertices) {
            indicateAllVertices(faceVertices, _pickInfo.pickedMesh);
            _meshWithVerticesShown = _pickInfo.pickedMesh;
            resolve();
          } else {
            _meshWithVerticesShown = null;
            reject();
          }
        } else {
          _meshWithVerticesShown = null;
          reject();
        }
      } else {
        reject();
      }
    });
  }
  
  const _is2D = function (){
    return store.$scope.isTwoDimension;
  };
  
  const _drawOutline = function () {
    if (_3dVariables.outlineMesh) _3dVariables.outlineMesh.dispose();
    if (_3dVariables.pathPoints.length < 2) return;

    const outline = BABYLON.MeshBuilder.CreateLines(
      "splitFaceOutline",
      {
        points: _3dVariables.pathPoints,
        updatable: true,
      },
      store.newScene
    );

    outline.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    outline.color = BABYLON.Color3.Black();

    outline.renderingGroupId = 1;
    
    _3dVariables.outlineMesh = outline;
  };

  function _getSnapPoint() {
    return new Promise((resolve, reject) => {
      disposeSnappingObjects();
      
      _pickTheScene();
      const hasFirstPathPointBeenAdded = _3dVariables.pathPoints.length >= 1;
  
      let pickedComponent;
      if (_pickInfo.hit) {
        pickedComponent = _pickInfo.pickedMesh.getSnaptrudeDS();
        if (!doesComponentHaveBrep(pickedComponent)) {
          reject();
          return;
        }
      } else {
        // to make it easier to do split face in 3D
        // when this is resolved, a click calls _addPathPoint
        // so even if the pointer is away from the edge, the split works
        
        if (_is2D()) reject();
        else {
          if (hasFirstPathPointBeenAdded) resolve();
          else {
            _componentOfInterest = null;
            reject();
          }
        }
        
        return;
      }
      
      const optionsForSnap = {
        vertexSnap: !_is2D(),
        edgeSnap: true,
        faceSnap: false,
        pickInfo: _pickInfo,
        disableTertiarySnaps: true,
        doNotDoSecondaryScenePicks: true,
        axisSnapPoint: _pickInfo.pickedPoint,
      };
      
      if (hasFirstPathPointBeenAdded){
        // optionsForSnap.faceSnap = true;
        // optionsForSnap.parallelFaceSnap = true;
      }

      const lastAddedPathPoint = _.last(_3dVariables.pathPoints);
      let snappedPoint = findPrioritizedSnapPoint(
        lastAddedPathPoint,
        null,
        null,
        optionsForSnap
      );

      snappedPoint = snappedPoint || _pickInfo.pickedPoint;
      
      _snapMetadata = optionsForSnap.metadata;

      if (_snapMetadata.snappedToEdge) {
        
        const indicator = lastAddedPathPoint
          ? uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator
          : uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;

        showVertexIndicator(snappedPoint, _pickInfo.pickedMesh, {
          indicator,
        });

        _currentSnappedPoint = snappedPoint;
      } else if (_snapMetadata.snappedToVertex) {
        _currentSnappedPoint = snappedPoint;
      } else if (_snapMetadata.snappedToFace){
        _currentSnappedPoint = snappedPoint;
        uiIndicatorsHandler.faceIndicator.remove();
      } else if (!_is2D() && hasFirstPathPointBeenAdded){
        // _currentSnappedPoint = snappedPoint;
      } else {
        _currentSnappedPoint = null;
      }

      if (lastAddedPathPoint) {
        
        const lineIndicator = DisplayOperation.drawOnMove(
          lastAddedPathPoint,
          snappedPoint
        );
        
        DisplayOperation.displayOnMove(
          BABYLON.Vector3.Distance(lastAddedPathPoint, snappedPoint)
        );

        const axisIndicators = uiIndicatorsHandler.axisIndicator
          .getMetadata()
          .getActiveIndicators();

        axisIndicators.forEach((ai) => {
          const v1 = lastAddedPathPoint.subtract(snappedPoint);
          const v2 = ai.snaptrudeProperties.firstPoint.subtract(
            ai.snaptrudeProperties.secondPoint
          );

          if (areTwoLinesCollinear(v1, v2, 2)) {
            lineIndicator.color = colorUtil.getColor(
              colorUtil.getColorNameForType(ai.snaptrudeProperties.axisType)
            );
            uiIndicatorsHandler.axisIndicator.remove(
              ai.snaptrudeProperties.type
            );
          }
        });

        resolve();
      } else if (!_currentSnappedPoint) {
        reject();
      } else {
        _componentOfInterest = pickedComponent;
        resolve();
      }
    });
  }

  const _pickTheScene = function (inconsiderate = false) {
    // when a mesh is clicked on so that its vertices are shown, pick is performed only on that mesh
    // when inconsiderate is set to true, that is overruled and pick happens on everything

    let meshFilter;
    if (_is2D()){
        meshFilter = mesh => ['mass', 'floor', 'roof'].includes(mesh.type.toLowerCase()) && !isMeshCurved(mesh);
    }
    else {
        meshFilter = mesh => mesh.type !== "staircase" && !isMeshCurved(mesh);
    }

    let predicate;

    if (inconsiderate) predicate = meshFilter;
    else if (_meshWithVerticesShown) predicate = m => m === _meshWithVerticesShown;
    else if (_componentOfInterest) predicate = m => m === _componentOfInterest.mesh;
    else predicate = meshFilter;

    _pickInfo = scenePickController.compoundPick(predicate);
  };

  const determineFaceToSplitAndPoints = function (pickInfo, upperPoint) {

    const _logFailure = function (){
      console.warn("Face not determined");
      return faceInfo;
    }

    const faceInfo = { face: null, lowerPoint: null };
    if (!pickInfo.hit) return _logFailure();

    const meshOfInterest = pickInfo.pickedMesh;
    const space = CONSTANTS.operatingSpace;

    const optionsForEdgeSnap = {
      withoutThreshold: true,
    };

    const edge = findNearestEdgeFromBRep(pickInfo, space, optionsForEdgeSnap);

    if (!edge) return _logFailure();

    const v1 = edge.headPt;
    const v2 = edge.tailPt;

    const options = { facetId: pickInfo.faceId, faceId: NaN };
    const v4 = getLowerVertexOfTheEdge(v1, meshOfInterest, options, space);
    const v3 = getLowerVertexOfTheEdge(v2, meshOfInterest, options, space);

    if (!v3 || !v4) return _logFailure();

    const vectors = [v1, v2, v3, v4];
    const face = getFaceObjectFromVectors(vectors, meshOfInterest, space);

    if (!face) return _logFailure();

    faceInfo.face = face;

    const upperEdgeLength = BABYLON.Vector3.Distance(v1, v2);
    const lowerEdgeLength = BABYLON.Vector3.Distance(v4, v3);

    const upperEdgeDivisionRatio =
      BABYLON.Vector3.Distance(v1, upperPoint) / upperEdgeLength;
    const lowerEdgeDivisionLength =
      lowerEdgeLength * upperEdgeDivisionRatio;

    let lowerPoint = v4.add(
      v3.subtract(v4).normalize().scale(lowerEdgeDivisionLength)
    );
    lowerPoint = projectionOfPointOnLine(lowerPoint, v3, v4);
    faceInfo.lowerPoint = lowerPoint;
    // faceInfo.lowerPoint = projectionOfPointOnLine(upperPoint, v3, v4);

    return faceInfo;
  };

  const _splitFace2D = function () {
    const meshOfInterest = _componentOfInterest.mesh;
    const _componentsOfInterest = [];

    if (virtualSketcher.util.isComponentPlanar(_componentOfInterest)) {
      _componentsOfInterest.push(_componentOfInterest);
    } else {
      _componentsOfInterest.push(
        ...getComponentsLinkedToEdge(_snapMetadata.snappedToEdge, _componentOfInterest)
      );
    }

    /*
        TODO - Make this efficient by using faceObjects returned by getFaceObjectFromTopEdgeAndLookForParallelFaces
         directly to add vertices, inside determineFaceToSplitAndPoints
         */
    const parallelFaceData = {};
    const dataForParallelFaceSearch = {
      edge: _snapMetadata.snappedToEdge,
      pickInfo: _pickInfo,
      component: _componentOfInterest,
    };

    moveFace.util.getFaceObjectFromTopEdgeAndLookForParallelFaces(
      dataForParallelFaceSearch,
      parallelFaceData
    );

    const componentUpperVertexMapping = {};
    _componentsOfInterest.forEach((c) => {
      componentUpperVertexMapping[c.id] = _currentSnappedPoint;
    });

    if (parallelFaceData.pickData) {
      _componentsOfInterest.push(parallelFaceData.pickData.component);
      componentUpperVertexMapping[parallelFaceData.pickData.component.id] =
        projectionOfPointOnLine(
          _currentSnappedPoint,
          parallelFaceData.pickData.edge.headPt,
          parallelFaceData.pickData.edge.tailPt
        );
    }

    const _meshesOfInterest = _componentsOfInterest.map((c) => c.mesh);

    let commandData =
      commandUtils.geometryChangeOperations.getCommandData(_meshesOfInterest);
    const propertyChangeCommandData = handleRoofPropertyChangeCommand(
      _componentsOfInterest
    );

    let split = false;
    _componentsOfInterest.forEach((component) => {
      const upperPoint = componentUpperVertexMapping[component.id];

      let pickInfo = _pickInfo;
      if (component !== _componentOfInterest){
          const facetId = geometryUpdater.util.getTopFacetId(component);
          pickInfo = geometryUpdater.util.getFakePickInfo(upperPoint, component, facetId);
      }

      const faceInfo = determineFaceToSplitAndPoints(pickInfo, upperPoint);

      if (!faceInfo.face || !faceInfo.lowerPoint) {
        console.warn("Vertex not added");
        return;
      }

      split =
        splitFaceByFaceObject(
          component.mesh,
          faceInfo.face,
          upperPoint,
          faceInfo.lowerPoint,
          CONSTANTS.operatingSpace
        ) || split;
    });

    if (split) {
      commandData = commandUtils.geometryChangeOperations.getCommandData(
        _meshesOfInterest,
        commandData
      );

      let callbackOptions = {};
      if (uiIndicatorsHandler.vertexIndicator.multiIndicators.isActive()) {
        createVertexIndicatorInstance(_currentSnappedPoint);
        commandData.addedVertex = _currentSnappedPoint;
        commandData.indicatorLinkedToMesh = meshOfInterest.uniqueId;

        callbackOptions.postExecuteCallback = function () {
          if (uiIndicatorsHandler.vertexIndicator.multiIndicators.isActive()) {
            if (
              uiIndicatorsHandler.vertexIndicator.multiIndicators.getActiveMesh()
                .uniqueId === this.data.indicatorLinkedToMesh
            ) {
              createVertexIndicatorInstance(this.data.addedVertex);
            }
          }
        };

        callbackOptions.postUnExecuteCallback = function () {
          if (uiIndicatorsHandler.vertexIndicator.multiIndicators.isActive()) {
            if (
              uiIndicatorsHandler.vertexIndicator.multiIndicators.getActiveMesh()
                .uniqueId === this.data.indicatorLinkedToMesh
            ) {
              removeVertexIndicatorInstance(this.data.addedVertex);
            }
          }
        };
      }

      const splitFaceCommand = commandUtils.geometryChangeOperations.getCommand(
        commandUtils.CONSTANTS.addVertexOperation,
        commandData,
        callbackOptions
      );

      virtualSketcher.updateWithoutGeometryEdit(_componentsOfInterest, true);

      const propertyChangeCommand = handleRoofPropertyChangeCommand(
        _componentsOfInterest,
        propertyChangeCommandData
      );

      const commands = _.compact([splitFaceCommand, propertyChangeCommand]);
      const yets = commands.map((_) => false);

      CommandManager.execute(commands, yets);
    }

    _meshesOfInterest.forEach(setLayerTransperancy);
  };
  
  const _doesPointLieOnEdge = function (point, edge){
    return store.resolveEngineUtils.onSegment3D(
      edge.headPt,
      point,
      edge.tailPt
    );
  }
  
  const _determineFaceBeingSplit = function (){
    let firstSetOfFaces, secondSetOfFaces;
    
    if (_3dVariables.snapData.beginVertex){
      firstSetOfFaces = getVertexFaces(
        _componentOfInterest,
        _3dVariables.snapData.beginVertex
      );
    }
    else if (_3dVariables.snapData.beginEdge){
      const edgeObject = getEdgeObjectFromVertexPositions(
        _componentOfInterest.mesh,
        _componentOfInterest.brep,
        _3dVariables.snapData.beginEdge.headPt,
        _3dVariables.snapData.beginEdge.tailPt,
      );
      
      firstSetOfFaces = getEdgeFaces(edgeObject);
    }
    
    if (_3dVariables.snapData.endVertex){
      secondSetOfFaces = getVertexFaces(
        _componentOfInterest,
        _3dVariables.snapData.endVertex
      );
    }
    else if (_3dVariables.snapData.endEdge){
      const edgeObject = getEdgeObjectFromVertexPositions(
        _componentOfInterest.mesh,
        _componentOfInterest.brep,
        _3dVariables.snapData.endEdge.headPt,
        _3dVariables.snapData.endEdge.tailPt,
      );
      
      secondSetOfFaces = getEdgeFaces(edgeObject);
    }
    
    const commonFaces = _.intersection(firstSetOfFaces, secondSetOfFaces);
    if (commonFaces.length === 1){
      _3dVariables.faceBeingSplit = commonFaces[0];
    }
    else if (_3dVariables.snapData.beginEdge &&
      _3dVariables.snapData.endEdge &&
      areEdgesSimilar(
        _3dVariables.snapData.beginEdge,
        _3dVariables.snapData.endEdge
      )
    ){
      
      /* handles this case
      
            split ---
                     |
                     v
         -------------------------
        |       |_____|           |
        |                         |
        |                         |
         -------------------------
      
       */
      const anyPathPointNotOnTheEdge = _3dVariables.pathPoints[1];
      
      commonFaces.some(face => {
        const faceVertices = getFaceVerticesFromFace(face, _componentOfInterest.mesh);
        const d = getDistanceBetweenVectorAndFace(anyPathPointNotOnTheEdge, faceVertices);
        
        if (isFloatEqual(d, 0, 1e-3)){
          _3dVariables.faceBeingSplit = face;
        }
        
        return _3dVariables.faceBeingSplit;
      });
    }
    else {
      console.log(commonFaces);
      console.warn("Common faces error");
    }
    
  }
  
  const _splitFromLines = function () {
    
    const splitData = {
      geometryChanged: false,
      newElements: {
        vertices: [],
        edges: [],
        faces: [],
      }
    }
    
    const optionsWithBrepRef = {
      brep: _brep
    };
    
    const meshOfInterest = _componentOfInterest.mesh;
    const faceVertices = getFaceVerticesFromFace(
      _3dVariables.faceBeingSplit, meshOfInterest, BABYLON.Space.WORLD, optionsWithBrepRef
    );
    const allFaceEdges = getEdgesFromFaceVertices(faceVertices);
    
    let length = _3dVariables.pathPoints.length;
    const linesToAdd = [];
    
    _3dVariables.pathPoints.forEach((point, i) => {
      if (i === length - 1) return;
      
      linesToAdd.push([
        point,
        _3dVariables.pathPoints[i + 1]
      ]);
    });
    
    let breaks = false;
    
    const edgesToAdd = linesToAdd.map((line) => {
      // line[0] will remain the same
      
      const lineBegin = line[0];
      const lineEnd = line[1];
      
      let existsOnAnEdge = false;
      allFaceEdges.some(edge => {
        if (_doesPointLieOnEdge(lineEnd, edge)){
          existsOnAnEdge = true;
        }
        return existsOnAnEdge;
      });
      
      if (existsOnAnEdge){
        return [...line];
      }
      else {
        let extendedOntoEdgePoint;
        allFaceEdges.some(edge => {
          const intersection = externalUtil.getPointOfIntersection(
            [
              lineBegin,
              lineEnd,
              edge.headPt,
              edge.tailPt
            ],
            {
              type: "vector-vector",
              ignoreY: false,
              roundOffFactor: 5,
            }
          );
          
          if (!intersection) return;

          // intersection point should not be lineBegin
          if (intersection.almostEquals(lineBegin)) return;

          // intersecting point should be along lineBegin to lineEnd direction, not opposite
          
          const v1 = lineEnd.subtract(lineBegin).normalize();
          const v2 = intersection.subtract(lineBegin).normalize();
          
          const angle = getAngleBetweenVectors(v1, v2);
          if (!isFloatEqual(angle, 0)) return;
          
          if (_doesPointLieOnEdge(intersection, edge)){
            extendedOntoEdgePoint = intersection;
          }
          return extendedOntoEdgePoint;
        });
        
        if (!extendedOntoEdgePoint) {
          breaks = true;
        }
        
        return [
          lineBegin,
          extendedOntoEdgePoint
        ];
      }
    });
    
    if (breaks) {
      return splitData;
    }
    
    let geometryChanged = false;
    
    const ogFaceObject = _3dVariables.faceBeingSplit;
    let facesToCheck = [ogFaceObject];
    
    length = edgesToAdd.length;
    edgesToAdd.forEach((edgeToAdd, i) => {
      
      let faceObject;
      
      const v1 = edgeToAdd[0];
      const v2 = edgeToAdd[1];
      
      facesToCheck.some(faceToCheck => {
        const faceVertices = getFaceVerticesFromFace(
          faceToCheck, meshOfInterest, BABYLON.Space.WORLD, optionsWithBrepRef
        );
        const edges = getEdgesFromFaceVertices(faceVertices);
        
        let v1Found, v2Found;
        edges.some(edge => {
          
          if (!v1Found) v1Found = _doesPointLieOnEdge(v1, edge);
          if (!v2Found) v2Found = _doesPointLieOnEdge(v2, edge);
          
          return v1Found && v2Found;
        });
        
        if (v1Found && v2Found) faceObject = faceToCheck;
        
        return v1Found && v2Found;
      });
      
      const options = {
        brep: _brep
      };

      if (!faceObject){
        console.warn("No face object to split");
      }
      
      const split = splitFaceByFaceObject(
        meshOfInterest,
        faceObject,
        ...edgeToAdd,
        CONSTANTS.operatingSpace,
        options
      );
      
      geometryChanged = geometryChanged || split;
      
      if (options.newElements){
        facesToCheck.push(options.newElements.face);
        facesToCheck = _.intersection(facesToCheck, _brep.getFaces());
        
        splitData.newElements.vertices.push(...options.newElements.vertices);
        splitData.newElements.edges.push(options.newElements.edge);
        splitData.newElements.faces.push(options.newElements.face);
      }
      
      // console.log(split);
      
      if (i !== 0){
        // remove extended portion of previous edge
        const previousAddedEdge = edgesToAdd[i - 1];
        const edgeToRemove = [
          edgeToAdd[0],
          previousAddedEdge[1]
        ];
        
        const edgeObject = getEdgeObjectFromVertexPositions(
          meshOfInterest,
          _brep,
          ...edgeToRemove
        );
        
        if (!edgeObject) return;
        
        const vertexToBeDeleted = getVertexObjectFromPositionV3(
          meshOfInterest,
          previousAddedEdge[1],
          BABYLON.Space.WORLD,
          optionsWithBrepRef
        );

        _.pull(splitData.newElements.edges, edgeObject);
        deleteEdge(_brep, edgeObject.getIndex());

        if (splitData.newElements.vertices.includes(vertexToBeDeleted)) {
          _.pull(splitData.newElements.vertices, vertexToBeDeleted);
          deleteVertex(_brep, vertexToBeDeleted.getIndex());
        }

        
        facesToCheck = _.intersection(facesToCheck, _brep.getFaces());
        
        meshOfInterest.BrepToMesh(_brep);
        // verifyBRepIntegrity(_brep);

      }
    });
    
    splitData.newElements.faces = _.intersection(splitData.newElements.faces, _brep.getFaces());
    splitData.geometryChanged = geometryChanged;
    
    return splitData;
  }
  
  const _splitFace3D = function () {
    const meshOfInterest = _componentOfInterest.mesh;
    _brep = _componentOfInterest.brep;
    
    _determineFaceBeingSplit();
    if (!_3dVariables.faceBeingSplit){
      console.error("Face to be split not determined");
      return;
    }
    
    let commandData =
      commandUtils.geometryChangeOperations.getCommandData(meshOfInterest);
    const propertyChangeCommandData = handleRoofPropertyChangeCommand([
      _componentOfInterest,
    ]);
    
    const splitData = _splitFromLines();
    
    if (splitData.geometryChanged) {
      virtualSketcher.updateWithoutGeometryEdit(_componentOfInterest, true);
      const propertyChangeCommand = handleRoofPropertyChangeCommand(
        [_componentOfInterest],
        propertyChangeCommandData
      );

      commandData = commandUtils.geometryChangeOperations.getCommandData(
        meshOfInterest,
        commandData
      );

      const splitFaceCommand = commandUtils.geometryChangeOperations.getCommand(
        commandUtils.CONSTANTS.addEdgeOperation,
        commandData
      );

      const commands = _.compact([splitFaceCommand, propertyChangeCommand]);
      const yets = commands.map((_) => false);

      CommandManager.execute(commands, yets);
    }
  };

  function _addVerticalEdge() {
    return new Promise((resolve, reject) => {
      if (_currentSnappedPoint) {
        _splitFace2D();
        resolve();
      }
      else reject();
    });
  }
  
  function _addPathPoint(){
    
    return new Promise((resolve, reject) => {
      if (_currentSnappedPoint) {
        _3dVariables.pathPoints.push(_currentSnappedPoint);
        _drawOutline();
        
        if (_3dVariables.pathPoints.length === 1) {
          
          if (_snapMetadata.snappedToVertex) _3dVariables.snapData.beginVertex = _snapMetadata.snappedToVertex;
          else if (_snapMetadata.snappedToEdge) _3dVariables.snapData.beginEdge = _snapMetadata.snappedToEdge;
          
          reject();
        } else if (_snapMetadata.snappedToVertex || _snapMetadata.snappedToEdge) {
          
          if (_snapMetadata.snappedToVertex) _3dVariables.snapData.endVertex = _snapMetadata.snappedToVertex;
          else if (_snapMetadata.snappedToEdge) _3dVariables.snapData.endEdge = _snapMetadata.snappedToEdge;
          
          _splitFace3D();
          resolve();
        }
      }
      else reject();
    });
    
  }
  
  function performSplitFromData (component, brep, splitPoints, faceBeingSplit){
    _componentOfInterest = component;
    _brep = brep;
    _3dVariables.pathPoints = splitPoints;
    _3dVariables.faceBeingSplit = faceBeingSplit;
    
    _splitFromLines();
    
    cleanUp();
  }

  function _cleanUpWithoutVertexIndicators() {
    _meshWithVerticesShown = null;

    _currentSnappedPoint = null;
    _pickInfo = null;
    _componentOfInterest = null;
    _brep = null;
    
    DisplayOperation.removeDimensions();
    
    turnOffSnappingEngine();
    disposeSnappingObjects();
    
    if (_3dVariables.outlineMesh) _3dVariables.outlineMesh.dispose();
    _3dVariables.pathPoints = [];
    _3dVariables.faceBeingSplit = null;
    
    _3dVariables.snapData = {
      beginVertex: null,
      beginEdge: null,
      endVertex: null,
      endEdge: null,
    };
    
    if (STATE_MACHINE) STATE_MACHINE.reset();

    return Promise.resolve();
  }

  function cleanUp() {
    
    _cleanUpWithoutVertexIndicators();
    disposeAllVertexIndicators();
    
    return Promise.resolve();
  }

  const eventHandler = function (evt) {
    STATE_MACHINE.nextEvent(evt);
  };

  const handleUserInput = function (inputField) {
    if (!_is2D() && STATE_MACHINE?.currentState === 2){
      
      if (!_currentSnappedPoint) return;
    
      const input = DisplayOperation.getOriginalDimension(inputField.text);
      const lastPoint = _.last(_3dVariables.pathPoints);
      
      const direction = _currentSnappedPoint.subtract(lastPoint).normalize();
      const resultantPoint = lastPoint.add(direction.scale(input));
      
      let isPointOnTheFace = true;
      
      if (isPointOnTheFace){
        _currentSnappedPoint = resultantPoint;
        STATE_MACHINE.fakeAPointerDown();
      }
      else {
        DisplayOperation.signalInputError();
      }
      
    }
  };

  const cancelOperation = function () {
    if (_componentOfInterest) {
      if (_is2D()) {
        cleanUp();
      } else {
        _3dVariables.pathPoints.pop();
        _drawOutline();
        if (_.isEmpty(_3dVariables.pathPoints)) cleanUp();
      }
      
      return true;
    }
  };

  return {
    init,
    eventHandler,
    determineFaceToSplitAndPoints,
    handleUserInput,
    cancelOperation,
    cleanUp,
    setStateMachineEvents,
    performSplitFromData,
  };
})();
export { splitFaceOperator };
