import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../themes/constant'
import Tooltip from '../Tooltip'
import { colorController } from './color.service'

const Wrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: ${colors.fullWhite};
    border-radius: 30px;
    background: ${props => props.bgColor};
    text-transform: uppercase;
    margin-right: 10px;
    position: relative;
    .online-indicator{
        position: absolute;
        bottom: 0px;
        right: 0px;
        border: 1px solid #FFFFFF;
        width: 0.55em;
        height: 0.55em;
        border-radius: 1em;
        background-color: #27AE60;
    }
`
/* 
    id: same id will get the same color each time. use ID as email.
    text: first letter of this text will be shown inside avatar
*/
function NamedAvatar({id, text, backgroundColor, style, isActive, tooltipPlace="bottom", tooltipHidden=false}) {
    const [bgColor, setBgColor] = useState(colorController.getColor(id));
    
    if(!text) return null;
    return (
        <>
            <Wrapper data-tip data-for={text} bgColor={backgroundColor || bgColor} style={style}>
                {text && text[0]}
                {isActive && <span className='online-indicator'></span>}
            </Wrapper>
            {
                !tooltipHidden &&
                <Tooltip id={text} place={tooltipPlace}>{text}</Tooltip>
            }
        </>
    )
}

export default NamedAvatar
