import React, { useRef } from 'react';
import styled from 'styled-components';
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';
import { colors } from '../../themes/constant';

const List = styled.div`
  box-sizing: border-box;
  border: 1px solid #F2F2F2;
  background-color: #FFFFFF;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 5px;
  display: inline-block;
  color: ${colors.lightGrey};
  width: 75px;
  filter: drop-shadow(0px 4px 10px rgba(197, 202, 220, 0.3));
`
const ListItem = styled.div`
  padding: 3px 0px 3px 7px;
  :hover{
    color: ${colors.primeBlack};
    background-color: #F2F2F2;
    cursor: pointer;
  }
`

function MenuList({items, onItemClick, listStyle, itemStyle, handleClose}) {
  const ref = useRef(null);
  useOutsideClickHandler(ref, handleClose)
  return (
    <List style={listStyle} ref={ref}>
      {items.map((item, index) => {
        return <ListItem key={`${item}-${index}`} style={itemStyle} onClick={onItemClick ? (evt) => {onItemClick(item, index, evt)} : ""}>{item}</ListItem>
      })}
    </List>
  )
}

export default MenuList
