import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import {store} from "../modules/utilityFunctions/Store.js";
import {colorUtil} from "../modules/utilityFunctions/colorUtility.js";
import {StateMachine} from "../modules/Classes/StateMachine.js";
import { getFaceIdFromFacet, getFaceVerticesFromFace, getFaceVerticesFromFacetID } from "../libs/brepOperations.js"
import { generateBrepFromPositionsAndCells } from "../libs/brepOperations.js";
import {
  areEdgesParallel,
  areTwoLinesCollinear,
  changeHeightOfObjects,
  changeTypeInStructure,
  correctMeshNormals,
  deepCopyObject,
  doAllPointsLieOnSameComponentEdge,
  doPointsLieOnComponentPerimeter,
  getActiveStoreyHeight,
  getActiveStoreyObject,
  getCentroidOfVertices,
  getCollinearVertices,
  getDistanceBetweenVectors,
  getSerializedFormOfMesh,
  getVertexCloserToPoint,
  getVertexProximityInfo,
  isMeshCurved,
  isPointerOverGUIElement,
  isPointInsideTheMesh,
  isPointInTheVicinityOfMesh, isRoomOfType,
  isTerrainMesh,
  isTouchEvent,
  mmToSnaptrudeUnits,
  onSolid,
  removeCollinearVertices,
  removeMeshFromStructure,
  updateCSG,
} from "../modules/extrafunc.js";
import {deleteDrawnLines, isLoopClosed} from "./drawingFuncs.js";
import {
  createReferenceGround,
  disposeAllVertexIndicators,
  disposeAngles,
  disposeAngleSector,
  showEdgeIndicator,
  disposeHighlightedVertex,
  disposeHightlightedEdge,
  disposeSnappingObjects,
  showAngles,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import {DisplayOperation} from "../modules/displayOperations/displayOperation.js";
import {virtualSketcher} from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import {
  areEdgesSame,
  changeCheckPointFor2D,
  degreeToRadian,
  forgetEdgeAndVertexInformation, getAxisSnapConstraintDirection,
  getDynamicThreshold,
  getTertiarySnapObject, handleOptionsForAlternateSnaps,
  initializeSnappingEngine, isCADMesh, lineParallelToWhichAxis,
  radianToDegree,
  resetConstrainedSnapAxisInformation,
  setAngleBetweenPoints,
  turnOffSnappingEngine,
} from "./snapUtilities.js";
import {
  distanceBetweenPointAndLine,
  getAngleBetweenVectors,
  getAngleInRadians,
  isFloatEqual,
  projectionOfPointOnFace,
} from "./snapFuncs.js";
import {calculateAreaOfIrregularPolygons, setLayerTransperancy,} from "./sceneFuncs.js";
import {assignProperties, nonDefaultMeshForSnapping,} from "./sceneStateFuncs.js";
import {GLOBAL_CONSTANTS} from "../modules/utilityFunctions/globalConstants.js";
import {findAngleSnappedPoint, findDimensionSnappedPoint, findPrioritizedSnapPoint,} from "./snapFuncsPrimary.js";
import {externalUtil} from "../modules/externalUtil.js";
import {uiIndicatorsHandler} from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import {
  generateBrepForComponents,
  getAllVertices, isComponentThin,
  labelVerticesAndEdgesAsSystemGenerated, sanitizeVertices,
  sanitizeVerticesInPlace,
  sortFaceIndicesForHalfEdge,
  verifyBRepIntegrity,
  getLowerVertexOfTheEdge,
  getTopFaceVertices,
  getVerticesFromEdgeObject, getBottomFaceVertices,
} from "./brepOperations.js";
import {getStandardDeviation, getUnitNormalVectorV3CyclicCheck,} from "./mathFuncs.js";
import {createCustomMeshAccordingToNormal} from "./massModeling.js";
import {StructureCollection} from "../modules/snaptrudeDS/structure.ds.js";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray, makeid} from "./arrayFuncs.js";
import {StoreyMutation} from "../modules/storeyEngine/storeyMutations.js";
import {commandUtils} from "../modules/commandManager/CommandUtils.js";
import {CommandManager} from "../modules/commandManager/CommandManager.js";
import {Mass} from "../modules/snaptrudeDS/mass.ds.js";
import {computeAreaMass} from "./areaFuncs.js";
import {scenePickController} from "../modules/utilityFunctions/scenePickController.js";
import {isPointInsidePolygon} from "./twoD/twoSnap";
import snaptrudeDSCount from "../modules/utilityFunctions/snaptrudeDSCountService.js";
import {massDissector} from "../modules/createBuilding/massDissector";
import {Wall} from "../modules/snaptrudeDS/wall.ds";
import {straightwall} from "../modules/factoryTypes/wall.types";
import snapEngine from "../modules/snappingEngine/snapEngine";
import {is2D} from "./twoDimension";
import splitMass from "../modules/draw/splitMass";
import { geometryUpdater } from "../modules/sketchMassBIMIntegration/geometryUpdater.js";
import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
import {meshUniqueIdMapper} from "../modules/utilityFunctions/meshUniqueIdMapper";
import {assignColumnProperties, shouldMassBeAColumn} from "../modules/draw/drawUtil";
import { i, sqrt } from "mathjs";
import { array } from "prop-types";

// import { ResolveEngineUtils } from "../modules/wallEngine/resolveEngine.js";
class Profile {
  constructor() {
    this._PtIdCellMap = [];
    this._curveIdList = [];
    this._face_facet_map = [];
    this._curveTypeList = [];
    this._faceLoops = [];
  };
  initProfile()
  {
    this._PtIdCellMap = [];
    this._curveIdList = [];
    this._face_facet_map = [];
    this._curveTypeList = [];
    this._faceLoops = [];
  };
  popLastCurve()
  {
    this._curveTypeList.pop();
    this._PtIdCellMap.pop();
    this._curveIdList.pop();
    this. _faceLoops.pop();
  };
};


var drawingOperator = (function () {
  const _CONSTANTS = {
    preSnapMaterial: null,
    vertexBoxInstanceName: "vertexBoxInstance",
    vertexBoxName: "vertexBoxOG",
    angleInput: "angleInput",
    distanceInput: "distBox",
  };

  var _selectedMesh = null;
  var faceCoordinates = null;
  var drawingPointEdgesForTertiarySnap = [];
  var _pickInfo = null;
  let _alternateSnapData = null;

  let _commandData = [];
  let _noBRepOnElement = false;

  let _meshOfInterest;
  let _drawingMode = null;
  let _modes = {
    drawingOnGround: 1,
    drawingOnMassOrSomething: 2,
  };
  let _drawingPointStackLengthWhenModeWasDetermined = null;

  let _dependantMassType = null;
  let _secondPick;

  const _dependantMassTypes = {
    singleMeshSingleStorey: 1,
    multipleMeshSingleStorey: 2,
    multipleMeshMultipleStorey: 3,
  };

  let _snapMetadata = {};
  let _drawingPlaneMesh = null;

  let _escapePressedOniPad = false;

  let _keypadInputBeforeFirstPoint = {
    point1: null,
    point2: null,
    point3: null,
  };

  const inputCoordinationVariables = {
    lockedInput: null,
    lockedDistanceAmount: null,
    lockedAngleValue: null,
    lockedAngleReferencePoint: null,
    focusedInput: _CONSTANTS.distanceInput,
  };

  let _autoCompleteData = {
    eligible: false,
    sourceEdge: null,
    destinationEdge: null,
    sourceVertex: null,
    destinationVertex: null,
    allEdgesOverlap: false,
    pathCompletion: null,
  };
    const CURVE_TYPE=
  {
    LINE:0,
    ARC:1,
  };
  const DRAWING_MODE ={
    ARC_MODE:1,
    LINE_MODE:2,
  };
  let currentDrawingMode = DRAWING_MODE.LINE_MODE;

  const ARC_TYPE={
    THREE_POINT_ARC:1,
    TWO_POINT_CENTER_ARC:2,
  };
  const SEL_MODE ={
    START_PT:1,
    END_PT:2,
    ARC_PT:3,
    CENTER_PT:4,
  };
  let currArcData = {
     arcType: ARC_TYPE.THREE_POINT_ARC,
     arcSelectedPts: [null,null,null],
     selMode: SEL_MODE.START_PT,
     arcNumPts: 20,
     arcEvalPoints:[],
  };
  let _faceId = null;
  let _normal = null;
  let _curveCounter = 0;
  let _profile= new Profile;

  function initProfile(_profile)
  {
    _profile.initProfile();
    _curveCounter = 0;
  }
  function popLastCurve(_profile)
  {
    _profile.popLastCurve();
    _curveCounter--;
  }
  let _massGenerationData = {};
  
  const drawWallData = {
    snapPointWallMap: new Map(),
    CONSTANTS: {
      centreProfile: "Centre",
      internalProfile: "Internal",
      externalProfile: "External",
    }
  };
  
  const _splitMassData = {
    snapsData : [],
  };
  
  let _pointUsedForAngleSnap;

  let _pointerMoveEvent = null;

  let startingPointOnDraw = null;
  let currentPointOnDraw = null;

  let _currentLineMesh;
  let _outlineMesh;

  const init = () => {
    _CONSTANTS.preSnapMaterial = colorUtil.getMaterial(colorUtil.type.preSnap);
    initProfile(_profile);
  }

  var onPointerDown = function (evt, force = false) {
    // console.log("Pointer Down");
    if (!StateMachine.EventValidation.isPointerDownWithLeftButton(evt)) return;
    if (store.isiPad) {
      if (isTouchEvent(evt)) return;
      if (isPointerOverGUIElement()) return;

      if (_escapePressedOniPad) {
        _escapePressedOniPad = false;
        return;
      }
    }

    let firstTapOniPad = store.isiPad && store.drawingPointStack.length === 0;
    if (firstTapOniPad) {
      let predicateArray = _getPredicate();
      // _pickInfo = newScene.sequentialPick(predicateArray);
      _pickInfo = scenePickController.sequentialPick(predicateArray);
      if (_pickInfo.hit) {
        var snappedPoint = _getSnappedPoint();

        currentPointOnDraw = snappedPoint || _pickInfo.pickedPoint;
        _meshOfInterest = _pickInfo.pickedMesh;
      }
    }
    /*else if (isiPad && !force){
            return;
        }*/

    if (_pickInfo.hit) {
      //both solid and void
      if (currentPointOnDraw) _onPointerDownDrawTool();
    }
  };

  var onPointerUp = function (evt) {
    // console.log("Pointer Up");
    if (store.isiPad) {
      if (isPointerOverGUIElement()) return;
      /* eslint-disable */
      function _callPointerDown() {
        onPointerDown({ button: 0 }, true);
      }
      /* eslint-enable */

      let tempStack = store.drawingPointStack.map(v => v.clone());
      tempStack.push(currentPointOnDraw);
      if (isLoopClosed(tempStack)) {
        _callPointerDown();
      }
      // _callPointerDown();
    }
  };
  function radiansToDegrees(radians) {
    return radians * (180 / Math.PI);
  }

  const _evalThirdPointOnArcForConstruction = function(stPt, endPt)
  {
    var center = stPt.add((endPt.subtract(stPt)).scale( 0.5));
    var radius = (endPt.subtract(stPt)).length()/2;
    if(radius ==0)return;
    var norm = new BABYLON.Vector3(_normal.x, _normal.z, _normal.y);
    var vector1 = stPt.subtract(center);
    var vector2 = endPt.subtract(center);
    var vector3 = (BABYLON.Vector3.Cross(norm, vector1)).normalize();
    return center.add(vector3.scale(radius));
  };

  //this is a utility function to draw arc with start point, end point and center

  const _constructTwoPointCenterArc = function(stPt,endPt, center,points, axis=null)
  {
    var radius = (endPt.subtract(center)).length();
    var vector1 = stPt.subtract(center);
    var vector2 = endPt.subtract(center);
    var cross = BABYLON.Vector3.Cross(vector1, vector2);
    var dot = BABYLON.Vector3.Dot(vector1, vector2);
    var angle = Math.acos(dot / (vector1.length() * vector2.length()));
    var check = axis?BABYLON.Vector3.Dot(cross,axis):0;
    if(check>0)
        angle = Math.PI*2 -angle;

     var nbPoints = currArcData.arcNumPts;

    var firstPoint = ((BABYLON.Vector3.Normalize(vector1)).scale(radius));
    var lastPoint = ((BABYLON.Vector3.Normalize(vector2)).scale(radius));
    if(check>0)
    {
        lastPoint = ((BABYLON.Vector3.Normalize(vector1)).scale(radius));
        firstPoint  = ((BABYLON.Vector3.Normalize(vector2)).scale(radius));
    }
    var matrix;
    var deltaAngle = angle / (nbPoints-1);
    var rotated;
    var rotAxis = axis?BABYLON.Vector3.Normalize(axis): cross;
    if(check>0){
      for (var i = nbPoints-1; i>=0 ; i--) {
        var ang = deltaAngle*i;
        matrix = BABYLON.Matrix.RotationAxis(rotAxis, ang);
        rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
        points.push(rotated.add(center));
      }
    }
    else{
      for (var i = 0; i < nbPoints; i++) {
        var ang = deltaAngle*i;
        matrix = BABYLON.Matrix.RotationAxis(rotAxis, ang);
        rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
        points.push(rotated.add(center));
      }
    }
  };
  function showAngleSector2(stPt, midPt, endPt, points)
  {
    let p1 = stPt, p2 = midPt, p3 = endPt;
    var v1 = p2.subtract(p1);
    var v2 = p3.subtract(p1);
    var v1v1, v2v2, v1v2;
    var v1v1 = BABYLON.Vector3.Dot(v1, v1);
    var v2v2 = BABYLON.Vector3.Dot(v2, v2);
    var v1v2 = BABYLON.Vector3.Dot(v2, v1);

    var base = 0.5/(v1v1*v2v2-v1v2*v1v2);
    var k1 = base*v2v2*(v1v1-v1v2);
    var k2 = base*v1v1*(v2v2-v1v2);
    var origin = p1.add( (v1.scale(k1)).add(v2.scale(k2))); // center
    var vector1 = stPt.subtract(origin);
    var vector2 = endPt.subtract(origin);
    var vector3 = stPt.subtract(midPt);
    var vector4 = endPt.subtract(midPt);
    var radius = (p1.subtract(origin)).length();
    var cross2 = BABYLON.Vector3.Cross(vector3, vector4);
    var cross = BABYLON.Vector3.Cross(vector1, vector2);
    var check = BABYLON.Vector3.Dot(cross,cross2);
    var dot = BABYLON.Vector3.Dot(vector1, vector2);
    var angle = Math.acos(dot / (vector1.length() * vector2.length()));
    var nbPoints = currArcData.arcNumPts;
    var rotAxis = cross;
    if(angle == Math.PI)
      rotAxis = cross2;
    if(check>0)
    {
        angle = Math.PI*2 -angle;
    }
    //var points = [];

    var firstPoint = ((BABYLON.Vector3.Normalize(vector1)).scale(radius));
    var lastPoint = ((BABYLON.Vector3.Normalize(vector2)).scale(radius));
    if(check>0)
    {
        lastPoint = ((BABYLON.Vector3.Normalize(vector1)).scale(radius));
        firstPoint  = ((BABYLON.Vector3.Normalize(vector2)).scale(radius));
    }
    var matrix;
    var deltaAngle = angle / (nbPoints-1);
    var rotated;
    if(check>0){
      for (var i = nbPoints-1; i>=0 ; i--) {
        var ang = deltaAngle*i;
        matrix = BABYLON.Matrix.RotationAxis(rotAxis, ang);
        rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
        points.push(rotated.add(origin));
      }
    }
    else{
      for (var i = 0; i < nbPoints; i++) {
        var ang = deltaAngle*i;
        matrix = BABYLON.Matrix.RotationAxis(rotAxis, ang);
        rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
        points.push(rotated.add(origin));
      }
    }

}
  //this is a utility function to draw arc with start point, any point on arc, end point
  const _askCenterFromThreePoints = function(stPt, endPt, thirdPt)
  {
    let p1 = stPt, p2 = thirdPt, p3 = endPt;
    var v1 = p2.subtract(p1);
    var v2 = p3.subtract(p1);
    var v1v1, v2v2, v1v2;
    var v1v1 = BABYLON.Vector3.Dot(v1, v1);
    var v2v2 = BABYLON.Vector3.Dot(v2, v2);
    var v1v2 = BABYLON.Vector3.Dot(v2, v1);

    var base = 0.5/(v1v1*v2v2-v1v2*v1v2);
    var k1 = base*v2v2*(v1v1-v1v2);
    var k2 = base*v1v1*(v2v2-v1v2);
    var c = p1.add( (v1.scale(k1)).add(v2.scale(k2))); // center
    return c;
  };
  const _handleOnMovePick = function (pickInfo) {

    uiIndicatorsHandler.vertexIndicator.multiIndicators.remove();
    if(currentDrawingMode == DRAWING_MODE.ARC_MODE && startingPointOnDraw)
    {
      currentPointOnDraw = _pickInfo.pickedPoint;
      if(currArcData.selMode == SEL_MODE.END_PT ||
        currArcData.selMode == SEL_MODE.ARC_PT)
      {
        if(currArcData.selMode == SEL_MODE.END_PT)
        {
          if (pickInfo.hit) {
            let snappedPoint = _getSnappedPoint(pickInfo);
            if (_.isEmpty(store.drawingPointStack)) {
              createReferenceGround(snappedPoint || undefined);
           }
           let pointOnPath = _doDrawingSnaps(pickInfo.pickedPoint);

           currentPointOnDraw = pointOnPath || snappedPoint || pickInfo.pickedPoint;
          }
          let stPt = currArcData.arcSelectedPts[0];
          let endPt = currentPointOnDraw.clone();

          var dirNorm = BABYLON.Vector3.Normalize(endPt.subtract(stPt));
          var cross = BABYLON.Vector3.Cross(dirNorm, BABYLON.Vector3.Up());
          //var cross = BABYLON.Vector3.Cross(dirNorm,cross1);
          cross = cross.normalize();
          var radius = (endPt.subtract(stPt)).length() * 1.5;
          var center = stPt.add((endPt.subtract(stPt)).scale(0.5));
          center = center.add(cross.scale(radius));
          let b_snapTangentAtStart = false;
          if(store.drawingPointStack.length>2)
          {
            var dirCenter = center.subtract(stPt);
            var dirPrev = store.drawingPointStack[store.drawingPointStack.length-2].subtract(store.drawingPointStack[store.drawingPointStack.length-1]);
            const angleBetweenStAndCenter = getAngleBetweenVectors(dirPrev,dirCenter);
            b_snapTangentAtStart = (angleBetweenStAndCenter > 85 && angleBetweenStAndCenter <95);
            if(b_snapTangentAtStart)
            {
              let indicatorType =
              uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;
              let lastCurvPtsArr = [];
              let lastCurvPts = [];
              if(_profile._PtIdCellMap.length)
              {

                let lastCurvPtIds =  _profile._PtIdCellMap[_profile._PtIdCellMap.length-1];
                for(let i=0;i< lastCurvPtIds.length; i++)
                  lastCurvPts.push(store.drawingPointStack[lastCurvPtIds[i]].clone());
              }
              if(lastCurvPts.length==0)
              {
                lastCurvPts.push(store.drawingPointStack[0].clone());
                lastCurvPts.push(store.drawingPointStack[1].clone());
              }
              lastCurvPtsArr.push(lastCurvPts);
              uiIndicatorsHandler.edgeIndicator.massConstruct(
                lastCurvPtsArr,
                [],
                {
                  indicator: indicatorType,
                  showMassEdge: true,
                }
              );
            }
          }

          currArcData.arcEvalPoints = [];
          _constructTwoPointCenterArc(stPt,endPt,center,currArcData.arcEvalPoints);
          uiIndicatorsHandler.vertexIndicator.show(endPt, null, {
            indicator: uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
          });
        }
        else
        {
          disposeSnappingObjects();
          let stPt = currArcData.arcSelectedPts[0];
          let endPt = currArcData.arcSelectedPts[1];
          var dirNorm = BABYLON.Vector3.Normalize(endPt.subtract(stPt));
          var cross = BABYLON.Vector3.Cross(dirNorm, BABYLON.Vector3.Up());
            // //if the current point is not on plane then take it projected onto current plane.
          var planeNorm = BABYLON.Vector3.Cross(dirNorm,cross);
          var tempRad = (endPt.subtract(stPt)).length() * 1.5;
          //find out temporary center
          var tempCenter = stPt.add((endPt.subtract(stPt)).scale(0.5));
          tempCenter = tempCenter.add(cross.scale(tempRad));
          var tempPlane = BABYLON.Plane.FromPositionAndNormal(tempCenter,planeNorm);
          var midPt  = currentPointOnDraw.clone();
          const projectedPoint = projectionOfPointOnFace(
            midPt,
            null,
            tempPlane,
            1e3
          );

          var midPt  = projectedPoint.clone();
          var center = _askCenterFromThreePoints(stPt, endPt, midPt);
          var radius = (center.subtract(endPt)).length();
          //try snapping
          const angleBetweenStartAndEnd = getAngleBetweenVectors(
            stPt.subtract(center),
            endPt.subtract(center)
          );
          const b_snap90 = (angleBetweenStartAndEnd > 80 && angleBetweenStartAndEnd < 100);
            const b_snapCenter = (angleBetweenStartAndEnd > 170 && angleBetweenStartAndEnd < 190);
            if(b_snapCenter){
              center = stPt.add((endPt.subtract(stPt)).scale(0.5));
              var midVec = BABYLON.Vector3.Normalize(midPt.subtract(center));
              midPt =  center.add(midVec.scale(radius));
            }
            if(b_snapCenter){
              pickInfo.pickedPoint = midPt;
              currArcData.arcSelectedPts[2] = midPt.clone();
              uiIndicatorsHandler.vertexIndicator.show(midPt, null, {
                indicator: uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator,
              });
            }
          //get last edge in stack as edge1
          if(store.drawingPointStack.length >1)
          {
            var angleBetweenEndAndCenter = null;
            var b_snapTangentAtEnd = null;
            if(((store.drawingPointStack[0].subtract(endPt)).length() == 0) && store.drawingPointStack.length > 2)
            {
              var dirLast = store.drawingPointStack[1].subtract(store.drawingPointStack[0]);
              var dirCenterEnd = center.subtract(endPt);
              angleBetweenEndAndCenter = getAngleBetweenVectors(dirLast,dirCenterEnd);
              b_snapTangentAtEnd = (angleBetweenEndAndCenter > 85 && angleBetweenEndAndCenter <95);

            }
            var dirCenter = center.subtract(stPt);
            var dirPrev = store.drawingPointStack[store.drawingPointStack.length-2].subtract(store.drawingPointStack[store.drawingPointStack.length-1]);

            const angleBetweenStAndCenter = getAngleBetweenVectors(dirPrev,dirCenter);
            const b_snapTangentAtStart = (angleBetweenStAndCenter > 85 && angleBetweenStAndCenter <95);

            if(b_snapTangentAtStart)
            {
              var dirNext = BABYLON.Vector3.Normalize(dirCenter);
              var dirPrev = BABYLON.Vector3.Normalize(dirPrev);
              var cross = BABYLON.Vector3.Cross(dirNext,dirPrev);
              var cross1 = BABYLON.Vector3.Cross(dirPrev,cross);
              center = stPt.add(cross1.scale(radius));
              var midVec = BABYLON.Vector3.Normalize(midPt.subtract(center));
              midPt =  center.add(midVec.scale(radius));
              pickInfo.pickedPoint = midPt;
              let indicatorType =
              uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;
              let lastCurvPtsArr = [];
              let lastCurvPts = [];
              if(_profile._PtIdCellMap.length)
              {

                let lastCurvPtIds =  _profile._PtIdCellMap[_profile._PtIdCellMap.length-1];
                for(let i=0;i< lastCurvPtIds.length; i++)
                  lastCurvPts.push(store.drawingPointStack[lastCurvPtIds[i]].clone());
              }
              if(lastCurvPts.length==0)
              {
                lastCurvPts.push(store.drawingPointStack[0].clone());
                lastCurvPts.push(store.drawingPointStack[1].clone());
              }
              lastCurvPtsArr.push(lastCurvPts);
              uiIndicatorsHandler.edgeIndicator.curveIndicatorConstruct(
                lastCurvPtsArr,
                [],
                {
                  indicator: indicatorType,
                  showMassEdge: true,
                }
              );
            }
            else if(b_snapTangentAtEnd){
              var dirPrev = store.drawingPointStack[1].subtract(store.drawingPointStack[0]);
              var dirCenter = center.subtract(endPt);

              var dirNext = BABYLON.Vector3.Normalize(dirCenter);
              var dirPrev = BABYLON.Vector3.Normalize(dirPrev);
              var cross = BABYLON.Vector3.Cross(dirNext,dirPrev);
              var cross1 = BABYLON.Vector3.Cross(dirPrev,cross);
              center = endPt.add(cross1.scale(radius));
              var midVec = BABYLON.Vector3.Normalize(midPt.subtract(center));
              midPt =  center.add(midVec.scale(radius));
              pickInfo.pickedPoint = midPt;

              let indicatorType =
              uiIndicatorsHandler.vertexIndicator.TYPES.preSnapIndicator;

              let curvPtsArr = [];
              let curvPts = [];
              if(_profile._PtIdCellMap.length)
              {
                let lastCurvPtIds =  _profile._PtIdCellMap[0];
                for(let i=0;i< lastCurvPtIds.length; i++)
                  curvPts.push(store.drawingPointStack[lastCurvPtIds[i]].clone());
              }
              if(curvPts.length==0)
              {
                curvPts.push(store.drawingPointStack[0].clone());
                curvPts.push(store.drawingPointStack[1].clone());
              }
              curvPtsArr.push(curvPts);
              uiIndicatorsHandler.edgeIndicator.curveIndicatorConstruct(
                curvPtsArr,
                [],
                {
                  indicator: indicatorType,
                  showMassEdge: true,
                }
              );
            }
          }

          currArcData.arcSelectedPts[2] = midPt;
          currArcData.arcEvalPoints = [];
          showAngleSector2(stPt, midPt, endPt, currArcData.arcEvalPoints);
          uiIndicatorsHandler.vertexIndicator.show(midPt, null, {
            indicator: uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
          });
        }

        if(currArcData.arcEvalPoints.length)
        {
          if (_currentLineMesh) _currentLineMesh.dispose();
          _currentLineMesh = BABYLON.MeshBuilder.CreateLines(
            "lastMovedLine",
            {
              points: currArcData.arcEvalPoints,
              updatable: true,
            },
            store.newScene
          );
          _currentLineMesh.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
          _currentLineMesh.color = BABYLON.Color3.Black();

          _currentLineMesh.renderingGroupId = 1;

          var point1 = center;
          var point2 = midPt;

          const firstDrawnPoint = point1;

          if (!point1 || !point2) return;

          const distanceInputFocus =
            inputCoordinationVariables.focusedInput === _CONSTANTS.distanceInput;

          const lineLength = getDistanceBetweenVectors(point1, point2);
          const displacement = 0.5 * lineLength;
          // const inputTextPosition = point2.add(point1.subtract(point2).normalize().scale(displacement));
          const inputTextPosition = firstDrawnPoint;
          const pointerLocation = {
            x: _pointerMoveEvent.clientX,
            y: _pointerMoveEvent.clientY,
          };

          DisplayOperation.drawOnMove(firstDrawnPoint, point2, "drawTool");
          DisplayOperation.displayOnMove(
            parseFloat(BABYLON.Vector3.Distance(point1, point2)),
            null,
            true,
            {
              inputFocus: distanceInputFocus,
              onChangeCallback: _handleDistanceInput,
              inputTextPosition,
              followMouse: true,
              pointerLocation,
            }
          );
        }
      }
      return;
    }
    if (pickInfo.hit) {
      let snappedPoint = _getSnappedPoint(pickInfo);
      if (_.isEmpty(store.drawingPointStack)) {
        createReferenceGround(snappedPoint || undefined);
      }

      let pointOnPath = _doDrawingSnaps(pickInfo.pickedPoint);

      currentPointOnDraw = pointOnPath || snappedPoint || pickInfo.pickedPoint;
      
      if (startingPointOnDraw) {
        let lastLineCoordinates = [startingPointOnDraw, currentPointOnDraw];

        _drawCurrentLine(lastLineCoordinates);
        _showIndicators();
      } else {
        if (!_snapMetadata.snappedToTwoAxes) {

          if (_snapMetadata.snappedToEdge){
            const edge = _snapMetadata.snappedToEdge;
            const {closerVertex, fartherVertex} = getVertexProximityInfo(edge, currentPointOnDraw);

            const anchorPoint = closerVertex;

            const pickedComponent = _pickInfo.pickedMesh.getSnaptrudeDS();
            const faceId = getFaceIdFromFacet(_pickInfo.faceId, pickedComponent.mesh);
            const faceObject = pickedComponent.brep.getFaces()[faceId];
            const faceVertices = getFaceVerticesFromFace(faceObject, pickedComponent.mesh);

            let secondReferencePoint;
            faceVertices.some((v, i) => {
              if (v.almostEquals(anchorPoint)){
                const previousVertex = getCircularlyPreviousElementInArray(faceVertices, v);
                const nextVertex = getCircularlyNextElementInArray(faceVertices, v);

                if (previousVertex.almostEquals(fartherVertex)) secondReferencePoint = nextVertex;
                else if (nextVertex.almostEquals(fartherVertex)) secondReferencePoint = previousVertex;

                return !!secondReferencePoint;
              }
            });

            if (is2D()){
              anchorPoint.y = currentPointOnDraw.y;
              secondReferencePoint.y = currentPointOnDraw.y;
            }

            if (!_snapMetadata.snappedToEdgeMidpoint){
              currentPointOnDraw = findDimensionSnappedPoint(anchorPoint, currentPointOnDraw);
            }

            _showIndicators(anchorPoint, currentPointOnDraw);

            _keypadInputBeforeFirstPoint.point1 = anchorPoint;
            _keypadInputBeforeFirstPoint.point2 = currentPointOnDraw;
            _keypadInputBeforeFirstPoint.point3 = secondReferencePoint;
          }
          else if (_snapMetadata.tertiarySnappedPointDash) {
            _snapMetadata.tertiarySnappedPointDash.y = currentPointOnDraw.y;
            _showIndicators(_snapMetadata.tertiarySnappedPointDash, currentPointOnDraw);

            _keypadInputBeforeFirstPoint.point1 =
              _snapMetadata.tertiarySnappedPointDash;
            _keypadInputBeforeFirstPoint.point2 = currentPointOnDraw;
          }
          else DisplayOperation.removeDimensions();
        }
      }
    }
  }

  var onPointerMove = function (evt) {
    // console.log("Pointer Move");
    _pointerMoveEvent = evt;
    
    if (StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(evt))
      return;
    /*if (StateMachine.EventValidation.isPointerMoveFastEnoughToIgnore(evt))
      return;*/
    
    snapEngine.alternateSnaps.invalidate();
    
    if (store.isiPad) {
      if (isTouchEvent(evt)) return;
      if (isPointerOverGUIElement()) return;
    }

    let predicateArray = _getPredicate();
    // let multiPicks = newScene.sequentialPick(predicateArray, {doAllOfThem : true});

    let doAllOfThem = _drawingMode === _modes.drawingOnMassOrSomething;
    let multiPicksOrSinglePick = scenePickController.sequentialPick(predicateArray, {
        doAllOfThem
    });

    if (_drawingMode === _modes.drawingOnMassOrSomething) {
      const multiPicks = multiPicksOrSinglePick;
      // uses multiPicks
      _pickInfo = multiPicks[0]; // pick of drawing plane
      _secondPick = multiPicks[1];
    } else {
      _pickInfo = multiPicksOrSinglePick;
    }

    if (_pickInfo.hit) {
      _handleOnMovePick(_pickInfo);
    } else {
      disposeHightlightedEdge();
      disposeHighlightedVertex();
    }
  };

  const _getComponentToSplitFromGraph = function (splitPoints){
    const _getLabelComponents = function (snapMetadata, isFirstPoint) {
      
      let components = [];

      const findAndPushBalconyComponents = function (topVertex, lowerVertex) {
        if(lowerVertex !== null){
          let nodes = virtualSketcher.structuralFindNodesAlongEdge(topVertex, lowerVertex,{"includeEdgePoints":false});
          _.forEach(nodes, (node) => {
            components.push(...node.components);
          });
        }
      }
      
      // In certain cases the snappedToVertex isn't set properly
      // To handle those we just recheck the condition on if SnappedToVertex is truly absent
      // User issue #80

      if(!snapMetadata.snappedToVertex && !snapMetadata.snappedToEdge){
        if(isFirstPoint) {
          snapMetadata.snappedToEdge = virtualSketcher.lookupOverEdge(store.drawingPointStack[0]);
        }
        else {
          snapMetadata.snappedToEdge = virtualSketcher.lookupOverEdge(_.last(store.drawingPointStack));
        }
      }

      if (snapMetadata.snappedToVertex){
        const label = virtualSketcher.structuralLookup(snapMetadata.secondarySnappedPoint)
        if(label){
          components.push(...label.components);
          
          // Adding potential Balcony mass
          _.forEach(components,(component) => {
            const topFaceId = geometryUpdater.util.getTopFaceId(component);
            const options = { facetId: NaN, faceId: topFaceId };

            const lowerVertex = getLowerVertexOfTheEdge(label.vector,component.mesh,options);
            findAndPushBalconyComponents(label.vector, lowerVertex);
          });
        }
        else {
          components.push(...store.exposed.structureCollection.getInstance().getAllComponents());
        }

        components = _.uniq(components);
      }
      else if (snapMetadata.snappedToEdge){
        const label = virtualSketcher.structuralLookupEdge(snapMetadata.snappedToEdge.headPt, snapMetadata.snappedToEdge.tailPt);
        if(label){
          components.push(...label.components);
        
          // Adding potential Balcony Mass
          _.forEach(components,(component) => {
            const topFaceId = geometryUpdater.util.getTopFaceId(component);
            const options = { facetId: NaN, faceId: topFaceId };
            const topVertex = snapMetadata.snappedToEdge.headPt;

            // Both HeadPt and Tail Pt should encompass the same required component so proceeding with head alone
            const lowerVertex = getLowerVertexOfTheEdge(topVertex, component.mesh, options);
            findAndPushBalconyComponents(topVertex, lowerVertex);
          });
        }
        else {
          components.push(...store.exposed.structureCollection.getInstance().getAllComponents());
        }

        components = _.uniq(components);
      }
      
      return components.filter(c => {
        return c.type.toLowerCase() === "mass" && c.storey === store.activeLayer.storey;
      });
    }

    const label1Components = _getLabelComponents(_.first(_splitMassData.snapsData),true);
    const label2Components = _getLabelComponents(_.last(_splitMassData.snapsData),false);
    
    const commonComponents = _.intersection(label1Components, label2Components);

    let componentToSplit;
    if (commonComponents.length === 0) {
      // console.warn("No common components for split");
      // console.warn(label1Components);
      // console.warn(label2Components);
      return;
    }
    else if (commonComponents.length === 1) {
      componentToSplit = commonComponents[0];
    }
    else if (commonComponents.length > 1) {
      // imagine a case with a U shaped mass and another in the hole of the U and thee split edge
      // connects the two shared edges
      let midPoint = _.first(splitPoints).add(splitPoints[1]).scale(0.5);
      
      commonComponents.some(c => {
        midPoint.y = c.mesh.getBoundingInfo().boundingBox.centerWorld.y;
        if (isPointInsideTheMesh(midPoint, c.mesh)){
          componentToSplit = c;
          return true;
        }
      });
    }

    return componentToSplit;
  }

  /*
  If the drawn line is eligible for split, but the first lines are drawn on the same edge
  they should be popped to get a clean split line first

  https://www.loom.com/share/4cebed721427450d929f9c74074ed395
   */
  const _removeFirstSplitPointsIfOnSameEdge = function (splitPoints, component){
    const points = [splitPoints[0], splitPoints[1]];
    if (doAllPointsLieOnSameComponentEdge(points, component)){
      splitPoints.shift();
      if (splitPoints.length >= 2) _removeFirstSplitPointsIfOnSameEdge(splitPoints, component);
    }
  }

  /*
  If line drawn is along an existing edge, split is not done, only draw mass
  This change was made after enabling split on all component types
   */
  const _doSplitPointsLieOnExistingEdge = function (splitPoints, component){
    const points = [splitPoints[0], splitPoints[1]];
    if (doAllPointsLieOnSameComponentEdge(points, component)){
      return true;
    }
  }

  const _getComponentToSplitFromPick = function (splitPoints){

    let componentToSplit;

    const firstSnapData = _.first(_splitMassData.snapsData);
    const lastSnapData = _.last(_splitMassData.snapsData);

    if (!firstSnapData || !lastSnapData) return;

    if (!firstSnapData.snappedToVertex && !firstSnapData.snappedToEdge) return;
    if (!lastSnapData.snappedToVertex && !lastSnapData.snappedToEdge) return;

    let pointThatShouldBeDefinitelyOnTheFaceIfValidSplit;

    if (splitPoints.length === 2) {
      pointThatShouldBeDefinitelyOnTheFaceIfValidSplit = splitPoints[0].add(splitPoints[1]).scale(0.5);
    }
    else {
      pointThatShouldBeDefinitelyOnTheFaceIfValidSplit = splitPoints[1];
    }

    /*
    For certain types like ceiling, the objects lies below the split line on the storey below,
    it's easy to miss the ray during pick
    So, consider the point to be lower, by reducing the y
     */

    pointThatShouldBeDefinitelyOnTheFaceIfValidSplit.y -= 5;

    const optionsForPick = {
      useMultiPick: true,
      pickWithRay: true,
      rayOrigin: pointThatShouldBeDefinitelyOnTheFaceIfValidSplit,
      rayDirection: BABYLON.Vector3.Up(), // because these vertices are on storey basem
    }

    const predicate = function (mesh){
      return !!mesh.getSnaptrudeDS(false)?.brep;
    }

    // moved to multi pick to handle sites and all
    // the overlap there is not avoidable and thus need to pick all of them

    const pickInfos = scenePickController.pick(predicate, optionsForPick);

    pickInfos.some(pickInfo => {
      if (!pickInfo.hit) return;

      const points = [
        firstSnapData.secondarySnappedPoint,
        lastSnapData.secondarySnappedPoint
      ];

      const pickedComponent = pickInfo.pickedMesh.getSnaptrudeDS();
      if (componentToSplit?.isCircularMass()){
        console.warn("Split not supported on circular masses");
        return;
      }
      if (!doPointsLieOnComponentPerimeter(points, pickedComponent)) return;

      // _removeFirstSplitPointsIfOnSameEdge(splitPoints, pickedComponent);
      // if (splitPoints.length >= 2) componentToSplit = pickedComponent;

      if (_doSplitPointsLieOnExistingEdge(splitPoints, pickedComponent)){
        // do draw mass
      }
      else {
        componentToSplit = pickedComponent;
      }

      return true;
    });

    return componentToSplit;
  }
  
  const _attemptSplitMass = function (){

    const splitPoints = store.drawingPointStack.map(v3 => v3.clone());

    if(splitPoints.length < 2){
      return;
    }
    
    // const componentToSplit = _getComponentToSplitFromGraph(splitPoints);
    const componentToSplit = _getComponentToSplitFromPick(splitPoints);

    if (!componentToSplit) return;
    
    if (is2D()){
      const vertices = getTopFaceVertices(componentToSplit);
      let util = new ResolveEngineUtils();
      for(let i = 0;i < splitPoints.length - 1;i++){
        let v2 = splitPoints[i];
        let v2dash = splitPoints[i + 1];
  
        for(let j = 0;j < vertices.length;j++) {
          const v1 = vertices[j];
          const v1dash = vertices[(j + 1)%vertices.length]
          v2.y = v2dash.y = v1.y;
          const intersectionPoint = externalUtil
                                    .getPointOfIntersection([v1, v1dash, v2, v2dash],{ignoreY: false});
          if(intersectionPoint){
            // The intersection point shouldn't be a split point itself
  
            // case 1: the point where the two lines meet, lies on an edge
            //         then proceed with split
  
            if(util.onSegment3D(v1,v2,v1dash) || util.onSegment3D(v1,v2dash,v1dash)){
              continue;
            }
  
            // case 2: intersection point doesn't lie on the split line
            //         intersection happens at infinity, proceed with split
            
            if(!util.onSegment3D(v2,intersectionPoint,v2dash)){
              continue;
            }
  
            // case 3: intersection point doesn't lie on the line segment
            //         intersection happens at infinity, proceed with split
            
            if(!util.onSegment3D(v1,intersectionPoint,v1dash)){
              continue;
            }
  
            if(v2.almostEquals(intersectionPoint) || v2dash.almostEquals(intersectionPoint)){
              continue;
            }
            
            console.warn("Multiple component split not supported yet");
            return;
          }
        }
      }
    }

    splitMass.splitUsingTopEdge(componentToSplit, splitPoints);
    return true;
  }

  const _doAutoCompleteThings = function () {
    // const resolveEngineUtils = new ResolveEngineUtils();
  
    const _getFinalPointStack = function (
      possiblePathCompletion,
      shouldMutate
    ) {
      // to complete the loop, first and last element should be the same
      // in this case destinationV3 is different from first element of store.drawingPointStack, so pushing it
      if (_autoCompleteData.destinationEdge)
        possiblePathCompletion.push(store.drawingPointStack[0]);

      let drawnPath = store.drawingPointStack;
      // last element is included in possiblePathCompletion as sourceV3 in other cases
      if (!_autoCompleteData.sourceEdge) {
        if (shouldMutate) {
          store.drawingPointStack.pop();
        } else {
          drawnPath = _.take(
            store.drawingPointStack,
            store.drawingPointStack.length - 1
          );
        }
      }

      return [...drawnPath, ...possiblePathCompletion];
    };

    /**
     * Checks if the path formed by these could cause overlap if the source and destination are the same
     *
     * @param sourceV3
     * @param destinationV3
     * @returns {boolean}
     * @private
     */
    const _verifySourceV3AndDestinationV3 = function (sourceV3, destinationV3) {
      if (sourceV3.almostEquals(destinationV3)) {
        const possiblePathCompletion = [destinationV3];

        const potentialFinalStack = _getFinalPointStack(
          possiblePathCompletion,
          false
        );
        return !_checkOverlap(potentialFinalStack);
      } else {
        return true;
      }
    };

    if (store.drawingPointStack.length === 1) {
      const doesPointExistOnTheGraph_nodeLabel = virtualSketcher.lookup(
        store.drawingPointStack[0]
      );

      if (doesPointExistOnTheGraph_nodeLabel) {
        _autoCompleteData.eligible = true;
        _autoCompleteData.destinationVertex =
          doesPointExistOnTheGraph_nodeLabel.vector.clone();
        // using the vector in the graph instead of store.drawingPointStack to prevent overlap check inaccuracies
        // due to ~4th decimal differences in points, which led to different autocomplete results unpredictably
        changeCheckPointFor2D(_autoCompleteData.destinationVertex);
      } else {
        let snappedToEdge = virtualSketcher.lookupOverEdge(
          store.drawingPointStack[0]
        );
        if (snappedToEdge) {
          _autoCompleteData.eligible = true;
          _autoCompleteData.destinationEdge = {
            headPt: snappedToEdge.headPt.clone(),
            tailPt: snappedToEdge.tailPt.clone(),
          };
          changeCheckPointFor2D(_autoCompleteData.destinationEdge.headPt);
          changeCheckPointFor2D(_autoCompleteData.destinationEdge.tailPt);
        } else {
          _autoCompleteData.eligible = false;
        }
      }
      _autoCompleteData.allEdgesOverlap = true;
    } else if (
      _autoCompleteData.eligible &&
      _drawingMode !== _modes.drawingOnMassOrSomething
    ) {
      // do auto-complete when _drawingMode === _modes.drawingOnGround and also when _drawingMode is not
      // determined. Example - vertex snap and vertex snap in 3D
  
      if (_autoCompleteData.allEdgesOverlap){
    
        // if allEdgesOverlap was true, it needs to be checked again
        // if it's false already, no need to check
    
        // check if all the drawn lines fall on existing edges
        // in that case auto-complete need not be attempted since it'll result in a duplicate mass
    
        let allEdgesOverlap = false;
        const numberOfVertices = store.drawingPointStack.length;
    
        const edgeLabels = _.compact(store.drawingPointStack.map((vertex, i) => {
          if (i === numberOfVertices - 1) return;
      
          const nextIndex = i + 1;
          const nextVertex = store.drawingPointStack[nextIndex];
      
          return virtualSketcher.lookupCoincidentEdge(vertex, nextVertex);
        }));
    
        if (edgeLabels.length === numberOfVertices - 1){
          // allEdgesOverlap possible
          
          // downward component check is wrong
          // even if the drawn lines fall on the edges of a roof or a water body,
          // autocomplete shouldn't work
          
          // so just coming to this if condition is proof enough that all edges overlap
          allEdgesOverlap = true;
      
          /*edgeLabels.every(edgeLabel => {
            const componentsAbove = edgeLabel.components.filter(
              c => !virtualSketcher.util.doesComponentGrowDownwards(c));
        
            allEdgesOverlap = !_.isEmpty(componentsAbove);
            
            return allEdgesOverlap;
        
          });*/
        }
    
        _autoCompleteData.allEdgesOverlap = allEdgesOverlap;
      }
  
      if (_autoCompleteData.allEdgesOverlap) return;
      
      const doesPointExistOnTheGraph_nodeLabel = virtualSketcher.lookup(
        _.last(store.drawingPointStack)
      );
  
      const drawingStackWithoutFirstAndLast2Elements = store.drawingPointStack
        .slice(0, 2)
        .slice(store.drawingPointStack.length - 2);
      const referenceV3 = drawingStackWithoutFirstAndLast2Elements
        .reduce((acc, current) => acc.add(current), BABYLON.Vector3.Zero())
        .scale(1 / store.drawingPointStack.length);
  
      let sourceV3,
        otherPossibleSourceV3,
        destinationV3,
        otherPossibleDestinationV3;
  
      if (_autoCompleteData.destinationVertex) {
        destinationV3 = _autoCompleteData.destinationVertex;
      } else if (_autoCompleteData.destinationEdge) {
        destinationV3 = getVertexCloserToPoint(
          _autoCompleteData.destinationEdge,
          referenceV3
        );
        otherPossibleDestinationV3 = destinationV3.almostEquals(
          _autoCompleteData.destinationEdge.headPt
        )
          ? _autoCompleteData.destinationEdge.tailPt
          : _autoCompleteData.destinationEdge.headPt;
      }

      const lastPoint = _.last(store.drawingPointStack).clone();



      if (doesPointExistOnTheGraph_nodeLabel) {
        _autoCompleteData.sourceVertex =
          doesPointExistOnTheGraph_nodeLabel.vector.clone();
        // using the vector in the graph instead of store.drawingPointStack to prevent overlap check inaccuracies
        // due to ~4th decimal differences in points, which led to different autocomplete results unpredictably
        changeCheckPointFor2D(_autoCompleteData.sourceVertex);
        sourceV3 = _autoCompleteData.sourceVertex;
      } else {
        let snappedToEdge = virtualSketcher.lookupOverEdge(
          _.last(store.drawingPointStack)
        );

        if (snappedToEdge) {
          if (

            store.resolveEngineUtils.onSegment2D(
              snappedToEdge.headPt,
              lastPoint,
              snappedToEdge.tailPt
            )
          ) {
            _autoCompleteData.sourceEdge = {
              headPt: snappedToEdge.headPt.clone(),
              tailPt: snappedToEdge.tailPt.clone(),
            };
            changeCheckPointFor2D(_autoCompleteData.sourceEdge.headPt);
            changeCheckPointFor2D(_autoCompleteData.sourceEdge.tailPt);

            sourceV3 = getVertexCloserToPoint(
              _autoCompleteData.sourceEdge,
              referenceV3
            );
            otherPossibleSourceV3 = sourceV3.almostEquals(snappedToEdge.headPt)
              ? snappedToEdge.tailPt
              : snappedToEdge.headPt;
          } else {
            // teleport snap
            _autoCompleteData.sourceVertex = lastPoint;
            changeCheckPointFor2D(_autoCompleteData.sourceVertex);
            sourceV3 = _autoCompleteData.sourceVertex;
          }
        } else {
          return;
        }
      }

      if (!_verifySourceV3AndDestinationV3(sourceV3, destinationV3)) {
        if (otherPossibleSourceV3) {
          sourceV3 = otherPossibleSourceV3;
        } else if (otherPossibleDestinationV3) {
          destinationV3 = otherPossibleDestinationV3;
        } else {
          return;
        }
      }

      let possiblePathCompletion;

      if (
        _autoCompleteData.sourceEdge &&
        _autoCompleteData.destinationEdge &&
        areEdgesSame(
          _autoCompleteData.sourceEdge,
          _autoCompleteData.destinationEdge
        )
      ) {
        // when _autoCompleteData.sourceEdge is falsy, the third condition won't evaluate
        // so won't throw error

        // this case applies to two scenarios
        // the points selected on the edge need to get connected and one where they go
        // in the opposite direction

        const lastPoint = _.nth(store.drawingPointStack, -1);
        const secondLastPoint = _.nth(store.drawingPointStack, -2);
        const lastEdge = secondLastPoint.subtract(lastPoint);

        const referenceLine = referenceV3.subtract(lastPoint);

        const angleBetweenThem = getAngleBetweenVectors(
          lastEdge,
          referenceLine
        );

        const isAcute = angleBetweenThem < 90;

        if (isAcute) {
          // same direction, the points should get connected to each other
          possiblePathCompletion = [_.last(store.drawingPointStack)];
          _autoCompleteData.sourceEdge = null;
        } else {
          const edge = _autoCompleteData.sourceEdge;
          const firstPoint = store.drawingPointStack[0];

          sourceV3 = getVertexCloserToPoint(edge, lastPoint);
          destinationV3 = getVertexCloserToPoint(edge, firstPoint);
        }
      } else if (sourceV3.almostEquals(destinationV3)) {
        possiblePathCompletion = [destinationV3];
      }

      // let checkedForMassOverlap = false;
      if (!possiblePathCompletion) {
        // unless the path was determined already

        const outerPath = deepCopyObject(store.drawingPointStack);

        if (!_autoCompleteData.sourceEdge) outerPath.pop();
        if (!_autoCompleteData.destinationEdge) outerPath.shift();

        // if snapped to vertices, the first and last point info will be sent as sourceV3 and destinationV3, so removing
        // from the outerPath

        possiblePathCompletion = virtualSketcher.findPath(
          sourceV3,
          destinationV3,
          outerPath
        );

        // checkedForMassOverlap = true;
        // checked inside findPath

        // path returned will be [source, ... , destination]
      }


      if (possiblePathCompletion) {

        const potentialFinalStack = _getFinalPointStack(possiblePathCompletion, false);

        if (_checkOverlap(potentialFinalStack, false)) {
            // drawingPointStack.pop();
          console.warn("Overlap detected, not auto completing")
        }
        else {
            const potentialFinalStack = _getFinalPointStack(possiblePathCompletion, true);
            const tempStack = potentialFinalStack.map(v => [v.x, v.z]);
            const area = calculateAreaOfIrregularPolygons(tempStack);

            if (area > 0.2) {
                _autoCompleteData.pathCompletion = possiblePathCompletion;

                store.drawingPointStack = potentialFinalStack;
                _setDrawingMode(_modes.drawingOnGround);
                // in case it wasn't already figured out, so that the mass doesn't become a child
                //for the time being remove profiles information and let the autocompletework
                _profile.initProfile();
                const drawn = _concludeDraw(true);
                if (!drawn) _cleanUpPreviousPointAdditionAutoCompleteData();

            }
        }
      }
    }
  };

  const _cleanUpPreviousPointAdditionAutoCompleteData = function () {
    _autoCompleteData.sourceEdge = null;
    _autoCompleteData.sourceVertex = null;
    _autoCompleteData.allEdgesOverlap = true;
  };

  const _getAverageY = function (){
    const totalY = store.drawingPointStack.reduce(
      (acc, v) => acc + v.y,
      0
    );

    return totalY / store.drawingPointStack.length;
  };
  
  const _getAverage = function (){
    const total = store.drawingPointStack.reduce(
      (acc, v) => acc.addInPlace(v),
      BABYLON.Vector3.Zero()
    );

    return total.scale(1 / store.drawingPointStack.length);
  };

  const _doPointsLieOnXZPlane = function(){
    const Ys = store.drawingPointStack.map(v3 => v3.y);
    const stdDev = getStandardDeviation(Ys);
    
    const threshold = 1e-2;
    return stdDev < threshold;
  }
  
  const _isAtAStoreyBase = function(){
    const averageY = _getAverageY();
    const storeys = StoreyMutation.getAllStoreys();
    const allStoreyBases = Object.values(storeys).map(s => s.base);

    allStoreyBases.push(StoreyMutation.queries.getCurrentMaxStoreyHeight());

    return allStoreyBases.inArray(v => isFloatEqual(v, averageY, 0.1));
  }

  const _setDrawingMode = function (mode){
    _drawingMode = mode;
    _drawingPointStackLengthWhenModeWasDetermined = store.drawingPointStack.length;
  };

  const _resetDrawingMode = function (){
    _drawingMode = null;
    _drawingPointStackLengthWhenModeWasDetermined = null;
  };

  function _determineDrawingMode() {

    if (nonDefaultMeshForSnapping(_pickInfo.pickedMesh)) {
      let pickedMeshDS = _pickInfo.pickedMesh.getSnaptrudeDS();
      if (pickedMeshDS) {
        _noBRepOnElement = !pickedMeshDS.brep;
      }
    }

    //checks after new point is pushed to stack
    if (!_drawingMode) {
      if (store.$scope.isTwoDimension) {
        _setDrawingMode(_modes.drawingOnGround);
      } else {
        /*
        if (_isMeshGroundLike(_pickInfo.pickedMesh)) {
          _setDrawingMode(_modes.drawingOnGround);
        } else if (_snapMetadata.snappedToFace) {

          const normal = getUnitNormalVectorV3CyclicCheck(_snapMetadata.snappedToFace);
          if (
            normal.almostEquals(BABYLON.Vector3.Up()) ||
            normal.almostEquals(BABYLON.Vector3.Down())
          ) {
            const topFaceId = geometryUpdater.util.getTopFaceId(_pickInfo.pickedMesh.getSnaptrudeDS());
            if (topFaceId === _snapMetadata.snappedToFaceId){
              _setDrawingMode(_modes.drawingOnGround);
            }
            else {
              // some weirdo drawing on the bottom face
              _setDrawingMode(_modes.drawingOnMassOrSomething);
            }
          }
          else {
            _setDrawingMode(_modes.drawingOnMassOrSomething);
          }

          if (store.drawingPointStack.length === 1) {
            _createPlaneMeshFromPoints(_snapMetadata.snappedToFace);
          } else if (store.drawingPointStack.length === 2) {
            if (
              isPointOnTheFace(
                store.drawingPointStack[0],
                _snapMetadata.snappedToFace
              )
            ) {
              _createPlaneMeshFromPoints(_snapMetadata.snappedToFace);
            }
          }
        } else if (_noBRepOnElement) {
          _setDrawingMode(_modes.drawingOnMassOrSomething);
        } else {
          // have to constrain next points to this plane of 3 points
          let averageY = _getAverageY();
          const allStoreyBases = Object.values(StoreyMutation.getAllStoreys()).map(s => s.base);

          if (allStoreyBases.inArray(v => isFloatEqual(v, averageY, 0.1))) { // if it forms a horizontal plane
            if (store.drawingPointStack.length >= 3) {
              _setDrawingMode(_modes.drawingOnGround);
            }
            else {
              // could go either way, wait
            }
          }
          else {
            _setDrawingMode(_modes.drawingOnMassOrSomething);
          }
        }*/

      }
    }

    if (!store.$scope.isTwoDimension && !_drawingPlaneMesh) {
      if (store.drawingPointStack.length >= 3) {
        _createPlaneMeshFromPoints(store.drawingPointStack);

        if (_doPointsLieOnXZPlane()) {
          // if it forms a horizontal plane
          _setDrawingMode(_modes.drawingOnGround);
        }
        else {
          _setDrawingMode(_modes.drawingOnMassOrSomething);
        }

      }
    }
  }

  function _determineDependantMassType() {
    if (!_secondPick) return;
    if (!_secondPick.hit) return;

    let secondPickedMesh = _secondPick.pickedMesh;
    if (secondPickedMesh === _selectedMesh) {
      if (!_dependantMassType)
        _dependantMassType = _dependantMassTypes.singleMeshSingleStorey;
    } else {
      if (!_drawingPlaneMesh) return;
      let drawingPlane = _drawingPlaneMesh.sourcePlane;
      if (
        Math.abs(drawingPlane.signedDistanceTo(_pickInfo.pickedPoint)) > 0.25
      ) {
        if (!_dependantMassType)
          _dependantMassType = _dependantMassTypes.singleMeshSingleStorey;
        // mesh was picked but it's far away, doesn't coincide with the drawing plane
      } else {
        if (secondPickedMesh.storey === _selectedMesh.storey) {
          if (
            _dependantMassType !==
            _dependantMassTypes.multipleMeshMultipleStorey
          ) {
            _dependantMassType = _dependantMassTypes.multipleMeshSingleStorey;
          }
        } else {
          _dependantMassType = _dependantMassTypes.multipleMeshMultipleStorey;
        }
      }
    }
  }

  const _checkOverlap = function (vertices = store.drawingPointStack,ignoreDownwardsComponents = true){
    const check1 = virtualSketcher.graphQueries.doesANodeExistWithinLoop(vertices);
    if (check1) return true;
    else {
      const length = store.drawingPointStack.length;
      const edgeMidPoints = [];
      store.drawingPointStack.forEach((point, i) => {
        if (i === length - 1) return;

        const nextPoint = getCircularlyNextElementInArray(store.drawingPointStack, point);
        edgeMidPoints.push(point.add(nextPoint).scale(0.5));
      });

      let overlaps = false;

      edgeMidPoints.some(point => {
        const options = {
          ignoreDownwardsComponents
        };

        overlaps = virtualSketcher.graphQueries.doesThisVectorLieWithinAComponent(point, options);

        if (overlaps && options.component.type.toLowerCase() === "roof") overlaps = false;

        return overlaps;
      });

      return overlaps;
    }
  };

  const _concludeDraw = function (checkedForMassOverlap) {
    if (!checkedForMassOverlap) {
      let massOverlapExists = _checkOverlap();
      if (massOverlapExists) {
        store.drawingPointStack.pop();
        startingPointOnDraw = _.last(store.drawingPointStack);

        return false;
      }
    }

    let pullMagnitude = _getPullMagnitude();
    let createdPolygon = _createPolygon(store.drawingPointStack, pullMagnitude);

    if (createdPolygon) {
      _massGenerationData.pullMagnitude = pullMagnitude;
      _assignPropertiesToTheCreatedMass(createdPolygon, _pickInfo?.pickedMesh);
      cleanUpDrawingData();

      return createdPolygon;
    }
  };
  const getNormalizedNormal = () => {
    if (["layerrefplane", "ground"].includes(_meshOfInterest.type.toLowerCase())) return new BABYLON.Vector3(0, 0, 1);
    if (_meshOfInterest.name.includes('terrain')) return new BABYLON.Vector3(0, 0, 1);

    let face = _meshOfInterest.getSnaptrudeDS().brep.getFaces()[_faceId]
    let faceVertices = getFaceVerticesFromFace(face, _meshOfInterest, BABYLON.Space.WORLD);

    let normalVector = getUnitNormalVectorV3CyclicCheck(faceVertices);

    [normalVector._y, normalVector._z] = [normalVector._z, normalVector._y]

    return normalVector;
}
function _getPredicate() {
  let predicate = null;

  if (store.sketchMode === "void") {
    if (_selectedMesh) {
      //one click has happened
      predicate = function (mesh) {
        return mesh === _selectedMesh;
      };
    } else {
      predicate = function (mesh) {
        return mesh.type.toLowerCase() === "wall" && !isMeshCurved(mesh);
      };
    }
  } else if (store.sketchMode === "solid") {
    // this is for draw mass

    if (_drawingPlaneMesh) {
      predicate = [
        {
            predicate : function (mesh) {
                return mesh === _drawingPlaneMesh;
            },
            pickInvisibleMeshes : true
        },
        {
            predicate : null
        }
      ];
    } else {
      if (store.$scope.isTwoDimension) {
        predicate = [
          {
              predicate : function (mesh) {
                return !isCADMesh(mesh);
              },
          },
          {
              predicate : function (mesh) {
                  return mesh.name === "ground1";
              },
              pickInvisibleMeshes : true
          }
        ];
      } else {
        if (_noBRepOnElement) {
          predicate = [
            {
                predicate : function (mesh) {
                    return mesh === _selectedMesh;
                }
            },
            {
                predicate : function (mesh) {
                    return mesh.name === "referenceGround";
                },
                pickInvisibleMeshes : true
            },
            {
                predicate : null
            }
          ];
        } else {
          predicate = [
            {
                predicate : null
            },
            {
                predicate : function (mesh) {
                    return mesh.name === "referenceGround";
                },
                pickInvisibleMeshes : true
            }
          ];
        }
      }
    }
  }

  if (!_.isArray(predicate)) predicate = [{
    predicate,
    pickInvisibleMeshes : false
  }];

  return predicate;
}
  function _onPointerDownDrawTool()
  {
    // store.newScene.activeCamera.detachControl(canvas);
    if (!_selectedMesh) _selectedMesh = _pickInfo.pickedMesh;
	  if(currentDrawingMode == DRAWING_MODE.ARC_MODE)
    {
      if (!_normal && _pickInfo.hit) {
        _meshOfInterest = _pickInfo.pickedMesh;
        _faceId = getFaceIdFromFacet(_pickInfo.faceId, _meshOfInterest);
        if(_faceId)
        _normal = getNormalizedNormal(_meshOfInterest, _faceId);
      }
      if (store.drawingPointStack.length > 0 && store.drawingPointStack.length < 3){
        if (_.last(store.drawingPointStack).almostEquals(currentPointOnDraw)){
          // double click with less than 3 points
          return;
        }
      }

      if (_pickInfo.pickedMesh.type.toLowerCase() === "wall"){
        drawWallData.snapPointWallMap.set(currentPointOnDraw, _pickInfo.pickedMesh);
      }

      if (store.drawingPointStack.length === 0){
        initializeSnappingEngine(currentPointOnDraw.clone());
      }
      else {
        resetConstrainedSnapAxisInformation();
      }

      currArcData.arcEvalPoints= [];
      let currPt = currentPointOnDraw.clone();
      if(currArcData.selMode == SEL_MODE.START_PT)
      {
        startingPointOnDraw = currentPointOnDraw.clone();
        currArcData.arcSelectedPts[0] = currentPointOnDraw.clone();
        currArcData.selMode = SEL_MODE.END_PT;
      }
      else if(currArcData.selMode == SEL_MODE.END_PT)
      {
        startingPointOnDraw = currentPointOnDraw.clone();
        currArcData.arcSelectedPts[1] = currentPointOnDraw.clone();
        currArcData.selMode = SEL_MODE.ARC_PT;
        disposeSnappingObjects();
      }
      else if(currArcData.selMode == SEL_MODE.ARC_PT)
      {
        let stPt = currArcData.arcSelectedPts[0];
        let endPt = currArcData.arcSelectedPts[1];
        var midPt  = currArcData.arcSelectedPts[2];
        currArcData.arcEvalPoints = [];
        if(stPt && midPt && endPt)
          showAngleSector2(stPt, midPt, endPt, currArcData.arcEvalPoints);

        if(currArcData.arcEvalPoints.length){
          let len = currArcData.arcEvalPoints[currArcData.arcEvalPoints.length-1].subtract(endPt).length();
          if(len>0)
          {
            var vertices = [stPt,endPt, midPt];
            uiIndicatorsHandler.vertexIndicator.multiIndicators.show(vertices, null, {
              indicator: uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
            });
          }

          var cellPointIds=[];

          for(let i=0; i< currArcData.arcEvalPoints.length; i++)
          {
            if(i!=0 || store.drawingPointStack.length==0)
              store.drawingPointStack.push(currArcData.arcEvalPoints[i].clone());
            cellPointIds.push(store.drawingPointStack.length-1);
          }
          _profile._PtIdCellMap.push(cellPointIds);

          currentDrawingMode = DRAWING_MODE.LINE_MODE;
          currArcData.selMode = SEL_MODE.END_PT;
          _resetInputsHandlingData();

          createReferenceGround(currPt);

          forgetEdgeAndVertexInformation();
          _determineDrawingMode();
          _determineDependantMassType();

          currArcData.arcSelectedPts[0] = endPt.clone();
          currArcData.arcSelectedPts[1] = null;
          currArcData.arcSelectedPts[2] = null;
          startingPointOnDraw = endPt.clone();

          _profile._curveTypeList.push(CURVE_TYPE.ARC);
          _profile._curveIdList.push(_curveCounter++);
          if (isLoopClosed(store.drawingPointStack)) {
            let numCurves = _profile._PtIdCellMap.length;
            let numPtsPerCurve = _profile._PtIdCellMap[numCurves-1].length;
            _profile._PtIdCellMap[numCurves-1][numPtsPerCurve-1] = 0;
            _concludeDraw(true);
            // if user deliberately wants an overlapping mass, let them have it
          } else {
            _drawOutline();
            _doAutoCompleteThings();
          }

        }
      }
    }
    else
    {
      if (store.drawingPointStack.length > 0 && store.drawingPointStack.length < 3){
        if (_.last(store.drawingPointStack).almostEquals(currentPointOnDraw)){
          // double click with less than 3 points
          return;
        }
      }

    _splitMassData.snapsData.push(_snapMetadata);
    startingPointOnDraw = currentPointOnDraw.clone();
    
    if (_pickInfo.pickedMesh.type.toLowerCase() === "wall"){
      drawWallData.snapPointWallMap.set(currentPointOnDraw, _pickInfo.pickedMesh);
    }

    if (store.drawingPointStack.length === 0){
      initializeSnappingEngine(startingPointOnDraw);
    }
    else {
      resetConstrainedSnapAxisInformation();
    }

    store.drawingPointStack.push(startingPointOnDraw);

    _resetInputsHandlingData();
    /*drawingPointStack.pushIfNotExist(startingPointOnDraw,
                    e => startingPointOnDraw.almostEquals(e));*/

    createReferenceGround(startingPointOnDraw);

    forgetEdgeAndVertexInformation();
    _determineDrawingMode();

    /*if (_drawingMode === _modes.drawingOnMassOrSomething){
            _determineDependantMassType();
        }*/

    // this is only useful for dependant masses but if determination happens sequentially, information will be lost
    // So this should be run even if we're not sure if it's a dependant mass
    _determineDependantMassType();


    if (store.drawingPointStack.length >= 2) {
      if (_.isEmpty(drawingPointEdgesForTertiarySnap)) {
        for (let i = 0; i < store.drawingPointStack.length - 1; i++) {
          let edge = {
            headPt: store.drawingPointStack[i],
            tailPt: store.drawingPointStack[i + 1],
          };

          drawingPointEdgesForTertiarySnap.push(edge);
        }
      } else {
        let edge = {
          headPt: _.nth(store.drawingPointStack, -2), //second last
          tailPt: _.last(store.drawingPointStack),
        };

        drawingPointEdgesForTertiarySnap.push(edge);
      }
    }

    if (store.drawingPointStack.length > 1){
      _profile._curveTypeList.push(CURVE_TYPE.LINE);
      _profile._curveIdList.push(_curveCounter++);
      var cellPointIds=[store.drawingPointStack.length-2, store.drawingPointStack.length-1];
      _profile._PtIdCellMap.push(cellPointIds);
    }
    
    if (isLoopClosed(store.drawingPointStack)) {
      let numCurves = _profile._PtIdCellMap.length;
      let numPtsPerCurve = _profile._PtIdCellMap[numCurves-1].length;
      _profile._PtIdCellMap[numCurves-1][numPtsPerCurve-1] = 0;

      _concludeDraw(true);
      // if user deliberately wants an overlapping mass, let them have it
    } else {
      if (store.drawingPointStack.length > 1) _drawOutline();

      // let split = _attemptSplitMass();

      let split;
      if (is2D()) split = _attemptSplitMass();

      if (split){
        cleanUpDrawingData();
        _cleanUpPreviousPointAdditionAutoCompleteData();
      }
      else _doAutoCompleteThings();
    }
    
  }

  }

  function _getParallelEdgeSnapPoint(currentPoint, prominentEdge, options) {
    const returnArray = [];

    if (_.isEmpty(store.drawingPointStack)) return returnArray;

    const parallelSnapEnabled = store.projectProperties.properties.parallelSnapEnabled.getValue();

    const lastPoint = _.last(store.drawingPointStack);
    const currentEdge = {
      headPt: lastPoint,
      tailPt: currentPoint,
    };

    if (parallelSnapEnabled && areEdgesParallel(prominentEdge, currentEdge)) {
      // drawing parallel to the existing drawn edges
      const returnObject = getTertiarySnapObject();

      const currentLength = getDistanceBetweenVectors(lastPoint, currentPoint);
      const direction = prominentEdge.headPt
        .subtract(prominentEdge.tailPt)
        .normalize();
      const candidate1 = lastPoint.add(direction.scale(currentLength));
      const candidate2 = lastPoint.add(direction.negate().scale(currentLength));

      // const dynamicThreshold = getDynamicThreshold(currentPoint);

      if (
        snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(candidate1)
      ) {
        returnObject.snapPoint = candidate1;
        returnObject.snapPointDash = lastPoint;
        returnObject.snapType = GLOBAL_CONSTANTS.strings.snaps.parallel;
        returnArray.push(returnObject);
      } else if (
        snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(candidate2)
      ) {
        returnObject.snapPoint = candidate2;
        returnObject.snapPointDash = lastPoint;
        returnObject.snapType = GLOBAL_CONSTANTS.strings.snaps.parallel;
        returnArray.push(returnObject);
      }
    }

    if (store.drawingPointStack.length > 2) {
      // drawing such that the final edge becomes parallel to some drawn edge
      const firstPoint = _.first(store.drawingPointStack);

      const futureEdge = {
        headPt: currentPoint,
        tailPt: firstPoint,
      };

      if (parallelSnapEnabled && areEdgesParallel(prominentEdge, futureEdge)) {
        const returnObject = getTertiarySnapObject();
        const currentLength = getDistanceBetweenVectors(
          firstPoint,
          currentPoint
        );
        const direction = prominentEdge.headPt
          .subtract(prominentEdge.tailPt)
          .normalize();
        const candidate1 = firstPoint.add(direction.scale(currentLength));
        const candidate2 = firstPoint.add(
          direction.negate().scale(currentLength)
        );

        // const dynamicThreshold = getDynamicThreshold(currentPoint);

        if (
          snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(candidate1)
        ) {
          returnObject.snapPoint = candidate1;
          returnObject.snapPointDash = firstPoint;
          returnObject.snapType = GLOBAL_CONSTANTS.strings.snaps.parallel;
          returnArray.push(returnObject);
        } else if (
          snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(candidate2)
        ) {
          returnObject.snapPoint = candidate2;
          returnObject.snapPointDash = firstPoint;
          returnObject.snapType = GLOBAL_CONSTANTS.strings.snaps.parallel;
          returnArray.push(returnObject);
        }
      }
    }

    if (
      prominentEdge.tailPt.almostEquals(_.last(store.drawingPointStack)) &&
      inputCoordinationVariables.lockedInput !== _CONSTANTS.angleInput
    ) {
      if (_drawingMode === _modes.drawingOnGround) {
        options.rotationAxis = BABYLON.Vector3.Up();
      }
      else {
        // const Ys = store.drawingPointStack.map(v3 => v3.y);
        // const stdDev = getStandardDeviation(Ys);

        if (_isAtAStoreyBase()){
          options.rotationAxis = BABYLON.Vector3.Up();
        }
        else {
          // for angle snapping while drawing on the lateral face of a wall etc
          options.rotationAxis = _pickInfo.getNormal(true);
        }
      }
      // no thresholding here, because this snap (if enabled) *always* happens

      const angleSnappedPoint = findAngleSnappedPoint(
        prominentEdge.headPt,
        prominentEdge.tailPt,
        currentPoint,
        options
      );
      if (angleSnappedPoint) {
        
        const returnObject = getTertiarySnapObject();
        returnObject.snapPoint = angleSnappedPoint;
        returnObject.snapPointDash = prominentEdge.tailPt;

        returnArray.push(returnObject);
      }
    }

    return returnArray;
  }
  
  function _shouldDoParallelSnap(){
    const constrainedAxisSnapDirection = getAxisSnapConstraintDirection();
    
    if (constrainedAxisSnapDirection === GLOBAL_CONSTANTS.strings.snaps.parallel){
      return true;
    }
    else if (constrainedAxisSnapDirection === null){
      if (["mass", "wall", "roof", "floor"].includes(_pickInfo.pickedMesh.type.toLowerCase())){
        return true;
      }
      else if (_drawingPlaneMesh === _pickInfo.pickedMesh) {
        return true;
      }
      else return false;
    }
    else {
      return false;
    }
  }
  function _findPointsToSnap()
  {
    let tertiarySnapPtsList = [];
    if(_profile._PtIdCellMap.length>0 && store.drawingPointStack.length >0)
    {
      tertiarySnapPtsList.push(store.drawingPointStack[0].clone());
      for(let i = 0; i <_profile._PtIdCellMap.length; i++)
      {
        let ptIdsList = _profile._PtIdCellMap[i];
        let lastPtid = ptIdsList[ptIdsList.length-1];
        tertiarySnapPtsList.push(store.drawingPointStack[lastPtid].clone());
      }
    }
    return tertiarySnapPtsList;
  }
  function _getSnappedPoint(pickInfoLocal, drawStartPoint) {
    
    if (StateMachine.EventValidation.isUserScrolling())
      return currentPointOnDraw;
    
    let tertiarySnapEdgeArray = _.concat(
      store.snappingGlobalVariables.lastSnappedEdgeArray,
      drawingPointEdgesForTertiarySnap
    );

    if (store.drawingPointStack.length === 1) {
      // for drawing the first line, use x-axis as ref for angle snap

      const firstPoint = drawStartPoint? drawStartPoint: store.drawingPointStack[0];
      // const secondPoint = currentPointOnDraw;
      const secondPoint = _pickInfo.pickedPoint;
      
      let isReferenceHorizontal = false;
      
      if (is2D()) isReferenceHorizontal = true;
      else if (_isMeshGroundLike(_pickInfo.pickedMesh)) isReferenceHorizontal = true;
      else if (_snapMetadata.snappedToFace){
       const normal = getUnitNormalVectorV3CyclicCheck(_snapMetadata.snappedToFace);
       if (normal){
          isReferenceHorizontal =
            normal.almostEquals(BABYLON.Vector3.Up()) ||
            normal.almostEquals(BABYLON.Vector3.Down());
       }
      }
      
      
      if (isReferenceHorizontal){
        const pointAlongX = _getDisplacedPoint(firstPoint);
        tertiarySnapEdgeArray.push({
          headPt: pointAlongX,
          tailPt: firstPoint,
        });
        
        _pointUsedForAngleSnap = pointAlongX;
      }
      else {
        let axis = lineParallelToWhichAxis(firstPoint, secondPoint, {usePointerLocation : true});
        if (axis){
          
          if (axis === GLOBAL_CONSTANTS.strings.snaps.y) axis = GLOBAL_CONSTANTS.strings.snaps.x;
          const displacedPoint = _getDisplacedPoint(firstPoint, axis);
    
          tertiarySnapEdgeArray.push({
            headPt: displacedPoint,
            tailPt: firstPoint,
          });
    
          _pointUsedForAngleSnap = displacedPoint;
        }
        else if (!firstPoint.almostEquals(secondPoint)){
          const pulledCurrentPoint = secondPoint.clone();
          pulledCurrentPoint.y = firstPoint.y;
          
          const direction = pulledCurrentPoint.subtract(firstPoint).normalize();
          const elongatedPoint = firstPoint.add(direction.scale(10));
    
          tertiarySnapEdgeArray.push({
            headPt: elongatedPoint,
            tailPt: firstPoint,
          });
          
          _pointUsedForAngleSnap = elongatedPoint;
        }
      }
  
    }
    let tertiarySnapPtList = _findPointsToSnap();
    tertiarySnapPtList = tertiarySnapPtList.length>0?tertiarySnapPtList:store.drawingPointStack;
    let options = {
      material: _CONSTANTS.preSnapMaterial,
      lessStringent: false,
      doTeleportSnap: true,
      tertiarySnapEdgeArray,
      tertiarySnapVertexArray: _.concat(
        store.snappingGlobalVariables.lastSnappedVertexArray,
        tertiarySnapPtList
      ),
      doNotDoSecondaryScenePicks: true,
      doNotReturnUnsnappedPoint: true,
      doPreClickAxisSnaps: true,
      axisIndicatorExtensionNotRequired: true,
      tertiaryEdgeSnapCallback: _getParallelEdgeSnapPoint,
      excludedMeshes: [],
      ignoreLowerStorey : false
    };

    if (inputCoordinationVariables.lockedInput === _CONSTANTS.angleInput) {
      options.doTeleportSnap = false;
      options.disableDimensionSnap = true;
      // options.disableTertiarySnaps = true;
      // axisPoint = currentPointOnDraw;
    }
    
    if (_splitMassData.snapsData.length === 1) {
      const firstSnap = _splitMassData.snapsData[0];
      if (firstSnap.snappedToEdge || firstSnap.snappedToVertex){
        // to allow for seamless mass splitting
        options.doTeleportSnap = false;
      }
    }

    if (_drawingMode === _modes.drawingOnMassOrSomething) {
      if (_shouldDoParallelSnap()){
        options.parallelFaceSnap = true;
        options.axisSnapPoint = _pickInfo.pickedPoint;
      }
      // options.tertiarySnapEdgeArray.length = 0;
      // if this is done, angle snap won't work
      
      // options.pickInfo = pickInfoLocal;
    }
    else if (_drawingMode === _modes.drawingOnGround){
      options.restrictYAxisMovement = true;
      // prevents y axis snap
    }
    else {
      if (_shouldDoParallelSnap()){
        // these variables are required for snaps when drawing on a vertical face
        options.parallelFaceSnap = true;
        options.axisSnapPoint = _pickInfo.pickedPoint;
      }
    }

    if (store.sketchMode === "void") {
      options.pickInfo = pickInfoLocal;
    }

    if (store.$scope.isTwoDimension) {
      options.ignoreY = true;
      options.doNotDoSecondaryScenePicks = false;
      if (store.sketchMode === "solid") {
        /*options.secondPickRequest = {
          predicate : m => {
              return m.name === "layerRefPlane";
          },
          pickInvisibleMeshes : true
        };*/
        options.doNotReturnUnsnappedPoint = false;
        options.attemptCadSnaps = true;
      }
    }
    
    handleOptionsForAlternateSnaps(_alternateSnapData, options);

    if (_drawingPlaneMesh) {
      options.excludedMeshes.push(_drawingPlaneMesh);
    }

    let snaptrudeDS = pickInfoLocal.pickedMesh.getSnaptrudeDS(false);
    if (!!snaptrudeDS && snaptrudeDS.type.toLowerCase() === "mass" && snaptrudeDS.isCircularMass()) {
      if (store.isTwoDimension) options.edgeSnap = false;
    }

    disposeSnappingObjects();
    let snappedPoint = findPrioritizedSnapPoint(
      startingPointOnDraw,
      null,
      null,
      options
    );

    _snapMetadata = options.metadata;
    if (options.metadata.pickInfo) _pickInfo = options.metadata.pickInfo;

    if (snappedPoint) {
      //thus always drawing on ground
      if (store.$scope.isTwoDimension) {
        changeCheckPointFor2D(snappedPoint);
      } else {
        if (_drawingMode === _modes.drawingOnGround) {
          if (_drawingPlaneMesh) {
            snappedPoint = _projectOntoDrawingPlane(snappedPoint, pickInfoLocal.pickedPoint);
          } else snappedPoint.y = store.drawingPointStack[0].y;
        } else if (_drawingMode === _modes.drawingOnMassOrSomething) {
          if (_drawingPlaneMesh) {
            snappedPoint = _projectOntoDrawingPlane(snappedPoint, pickInfoLocal.pickedPoint);
          }
        }
      }
    } else {
      if (store.$scope.isTwoDimension) {
        changeCheckPointFor2D(_pickInfo.pickedPoint);
      }
    }

    const referencePoint =
      startingPointOnDraw || options.metadata.tertiarySnappedPointDash;
    if (referencePoint) {
      if (inputCoordinationVariables.lockedInput === _CONSTANTS.angleInput) {
        let pointToCheck = snappedPoint || _pickInfo.pickedPoint;

        const displacedPointX = _getDisplacedPoint(pointToCheck, "x", 5);
        const displacedPointZ = _getDisplacedPoint(pointToCheck, "z", 5);

        const intersectionX = externalUtil.getPointOfIntersection([
          referencePoint,
          inputCoordinationVariables.lockedAngleReferencePoint,
          pointToCheck,
          displacedPointX,
        ]);

        const intersectionZ = externalUtil.getPointOfIntersection([
          referencePoint,
          inputCoordinationVariables.lockedAngleReferencePoint,
          pointToCheck,
          displacedPointZ,
        ]);

        let intersection;
        if (intersectionX && intersectionZ) {
          const points = [intersectionX, intersectionZ];
          const distances = points.map((p) =>
            getDistanceBetweenVectors(p, pointToCheck)
          );

          if (isFloatEqual(distances[0], distances[1], 1)) {
            intersection = intersectionX;
          } else {
            const minD = _.min(distances);
            intersection = points[distances.indexOf(minD)];
          }
        } else {
          intersection = intersectionX || intersectionZ;
        }
        
        if (!intersection && _pointUsedForAngleSnap){
          intersection = externalUtil.getPointOfIntersection([
            referencePoint,
            inputCoordinationVariables.lockedAngleReferencePoint,
            pointToCheck,
            _pointUsedForAngleSnap,
          ]);
        }

        if (intersection) {
          const optionsForAxis = {
            indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis3,
            extensionNotRequired: true,
          };

          uiIndicatorsHandler.axisIndicator.show(
            pointToCheck,
            intersection,
            optionsForAxis
          );

          if (!snappedPoint) {
            snappedPoint = findDimensionSnappedPoint(
              referencePoint,
              intersection,
              options
            );
          } else snappedPoint = intersection;
        } else {
          snappedPoint = referencePoint;
        }

        // uiIndicatorsHandler.remove();
        // _invalidateSnapMetadata(options);
      } else if (
        inputCoordinationVariables.lockedInput === _CONSTANTS.distanceInput &&
        snappedPoint
      ) {
        const direction = snappedPoint.subtract(referencePoint).normalize();
        const constantDistancePoint = referencePoint.add(
          direction.scale(inputCoordinationVariables.lockedDistanceAmount)
        );

        const optionsForAxis = {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis3,
          extensionNotRequired: true,
        };

        uiIndicatorsHandler.axisIndicator.show(
          snappedPoint,
          constantDistancePoint,
          optionsForAxis
        );

        snappedPoint = constantDistancePoint;
        // uiIndicatorsHandler.remove();
        // _invalidateSnapMetadata(options);
      }
      // else snappedPoint = findDimensionSnappedPoint(referencePoint, snappedPoint, options);
    }

    return snappedPoint;
  }

  function _projectOntoDrawingPlane(snappedPoint, pickedPointOnThePlane){

    if (store.drawingPointStack.length < 3) return snappedPoint;

    const lastPoint = _.last(store.drawingPointStack);
    const secondLastPoint = _.nth(store.drawingPointStack, -2);

    const lastLine = lastPoint.subtract(secondLastPoint);

    const projectedPoint = projectionOfPointOnFace(
      snappedPoint,
      null,
      _drawingPlaneMesh.sourcePlane,
      1e3
    );

    const currentLine = projectedPoint.subtract(lastPoint);

    if (areTwoLinesCollinear(lastLine, currentLine)){
      return pickedPointOnThePlane;
    }
    else {
      return projectedPoint;
    }
  }

  function _invalidateSnapMetadata(options) {
    options.snappedToTwoAxes = false;
  }

  function _createPlaneMeshFromPoints(points) {
    if (!points) return;

    if (_drawingPlaneMesh) _drawingPlaneMesh.dispose();

    let planeObject = BABYLON.Plane.FromPoints(
      points[0],
      points[Math.floor(points.length / 2)],
      points[points.length - 1]
    );

    let planeMesh = BABYLON.MeshBuilder.CreatePlane(
      "drawingPlane",
      {
        size: 10000,
        sourcePlane: planeObject,
      },
      store.scene
    );

    planeMesh.visibility = 0;
    planeMesh.type = GLOBAL_CONSTANTS.strings.identifiers.utilityElement;
    planeMesh.sourcePlane = planeObject;
    planeMesh.position = points[0];

    scenePickController.add(planeMesh);
    _drawingPlaneMesh = planeMesh;
  }
  function constructEdgeHalfEdgeMap(ds)
  {
    ds.geomRepMapHalfEdgesEdges = {};
    let lastCurve = _profile._PtIdCellMap[_profile._PtIdCellMap.length -1];
    let bottomProfileLen = lastCurve[lastCurve.length-2]+1;
    ds.geomRepRevMapEdgesHalfEdges = new Array(_profile._PtIdCellMap.length * 3);
    for(var i=0;i<_profile._PtIdCellMap.length * 3; i++)
      ds.geomRepRevMapEdgesHalfEdges[i] = [];
    for(var i=0;i<_profile._PtIdCellMap.length; i++)
      {
        //create a map for top and bottom edges
        //create a map for side edges
        var edgePts = _profile._PtIdCellMap[i];
        for(var j=0; j<edgePts.length -1; j++)
        {
          let itr1 = j;
          let itr2 = (j+1)% edgePts.length;

          let botId1 = edgePts[itr1];
          let botId2 = edgePts[itr2];

          let heTag = botId1 + '-' + botId2;
          let heTagAdj = botId2 + '-' + botId1;

          ds.geomRepMapHalfEdgesEdges[heTag] = i;
          ds.geomRepMapHalfEdgesEdges[heTagAdj] = i;

          ds.geomRepRevMapEdgesHalfEdges[i].push(botId1);

          if(itr2 == edgePts.length -1) //do not insert the duplicate vertices
            ds.geomRepRevMapEdgesHalfEdges[i].push(botId2);

          var topId1 = botId1 + bottomProfileLen;
          var topId2 = botId2 + bottomProfileLen;

          let heTagTop = topId1 + '-' + topId2;
          let heTagTopAdj = topId2 + '-' + topId1;
          ds.geomRepMapHalfEdgesEdges[heTagTop] = i + _profile._PtIdCellMap.length;
          ds.geomRepMapHalfEdgesEdges[heTagTopAdj] = i + _profile._PtIdCellMap.length;

          ds.geomRepRevMapEdgesHalfEdges[i + _profile._PtIdCellMap.length].push(topId1);
          if(itr2 == edgePts.length -1) //do not insert the duplicate vertices
            ds.geomRepRevMapEdgesHalfEdges[i + _profile._PtIdCellMap.length].push(topId2);

          if(j==0)
          {
            let heTagSide = botId1 + '-' + topId1;
            let heTagSideAdj = topId1 + '-' + botId1;
            ds.geomRepMapHalfEdgesEdges[heTagSide] = i + _profile._PtIdCellMap.length * 2;
            ds.geomRepMapHalfEdgesEdges[heTagSideAdj] = i + _profile._PtIdCellMap.length * 2;

            ds.geomRepRevMapEdgesHalfEdges[i + _profile._PtIdCellMap.length * 2].push(botId1);
            ds.geomRepRevMapEdgesHalfEdges[i + _profile._PtIdCellMap.length * 2].push(topId1);
          }
        }
      }
  }
  function createCellPtIdsMap(profiles){
    let downPolygon  = profiles.path_bottom_vec;
    let reverseStack = profiles.reverse_stack;
    const mapOldNewIndices = new Map();
    //faceLoops
    _profile._faceLoops=[];
    //create a bottom loop and top loop
    let bottomLoop = [];
    let topLoop=[];
    mapOldNewIndices.set(0,0);
    if(reverseStack)
    {
      for(var i=1, j= downPolygon.length-1; i<downPolygon.length; i++, j--)
        mapOldNewIndices.set(i,j);
      _profile._PtIdCellMap.reverse();
      for( var i=0; i < _profile._PtIdCellMap.length; i++)
        _profile._PtIdCellMap[i].reverse();
    }
    else
      for(var i= 0; i<downPolygon.length; i++)
        mapOldNewIndices.set(i,i);

    for(var i=0; i<downPolygon.length; i++){
      bottomLoop.push(i);
      var topId = i + downPolygon.length;
      topLoop.push(topId);
    }
    //reverse the bottom loop and reorder so that it will start from zeroth
    bottomLoop.reverse();
    var temp = bottomLoop.pop();
    bottomLoop.unshift(temp);

    _profile._faceLoops.push([bottomLoop]);
    _profile._faceLoops.push([topLoop]);

    //create side faces loop
    for(var i=0;i<_profile._PtIdCellMap.length; i++)
    {
      var faceBotPts = _profile._PtIdCellMap[i];
      var faceTopPts = [];
      var faceLoop =[];
      for(var j=0; j<faceBotPts.length; j++)
      {
        var botId = mapOldNewIndices.get(faceBotPts[j]);
        var topId = botId + downPolygon.length;
        faceTopPts.push( topId);
        faceLoop.push(botId);
      }
      //faceTopPts.reverse();
      for(var j=0; j<faceTopPts.length; j++)
        faceLoop.push(faceTopPts[j]);
      _profile._faceLoops.push([faceLoop]);
    }
  }
  function  checkCyclicityOfLoop(drawingStack){
    let unitNorm = new BABYLON.Vector3();
    for(var i=0; i<drawingStack.length; i++)
    {
      let prevId = (i==0)? drawingStack.length -1: i-1;
      let nextId = (i+1)%drawingStack.length;
      let A = drawingStack[i].subtract(drawingStack[prevId]);
      let B = drawingStack[nextId].subtract(drawingStack[i]);
      let cross = BABYLON.Vector3.Cross(A,B);
      unitNorm = unitNorm.add(cross);
    }
    //BABYLON.Vector3.Up()
    let dir = BABYLON.Vector3.Dot(unitNorm, BABYLON.Vector3.Up());
    return (dir>0.0);  //(dir>0.0)?clockwise:anticlckwise;
  }
  function _createPolygon(drawingStack, pullMagnitude, storeyVal) {
    if (_.first(drawingStack).almostEquals(_.last(drawingStack))) drawingStack.pop();

    sanitizeVerticesInPlace(store.drawingPointStack, { collinearCheck: false });

    let downPolygon = [];
    let upPolygon = [];

    let extension = mmToSnaptrudeUnits(10);

    let reverseStackInLoops = false;
    let unitNormalVector = getUnitNormalVectorV3CyclicCheck(drawingStack);

    if (!unitNormalVector) return;
    // direction of unit normal vector cannot be trusted; it depends on the direction of drawing,
    // so need to confirm
    if (_drawingMode === _modes.drawingOnMassOrSomething){
      let potentialParentMesh = _pickInfo?.pickedMesh || _selectedMesh;
      if (potentialParentMesh){
        const smallLength = 1e-3;

        // first point must be on the mesh, so no need to check others REDACTED

        // when first point is snapped to a vertex, the isPointInsideTheMesh check is inconsistent
        // so checking the midpoint of first edge now

        // const pointToCheck = drawingStack[0].add(unitNormalVector.scale(smallLength));
        const pointToCheck = BABYLON.Vector3.Center(
          drawingStack[0],
          drawingStack[1]
        ).add(unitNormalVector.scale(smallLength));

        const isNormalGoingInsideTheParent = isPointInsideTheMesh(
          pointToCheck,
          potentialParentMesh
        );

        if (store.sketchMode === "void"){
          if (!isNormalGoingInsideTheParent){
            unitNormalVector = unitNormalVector.negate();
            drawingStack.reverse();
            reverseStackInLoops = true;
          }
        }
        else {
          if (isNormalGoingInsideTheParent){
            unitNormalVector = unitNormalVector.negate();
            drawingStack.reverse();
            reverseStackInLoops = true;
          }
        }
      }
    }
    else {

      let up = BABYLON.Vector3.Up();
      let dir = BABYLON.Vector3.Dot(unitNormalVector, up);
      if (dir>0){
        drawingStack.reverse();
        reverseStackInLoops = true;
      }
      let down = BABYLON.Vector3.Down();
      if (unitNormalVector.equalsWithEpsilon(down))
        unitNormalVector = up;
    }
    if(reverseStackInLoops){
      var temp = drawingStack.pop();
      drawingStack.unshift(temp);
    }
    let diff;
    if (store.sketchMode === "void") {
      let depthOfWall =
        _pickInfo.pickedMesh.getSnaptrudeDS().calculateWidth() + extension;
      diff = new BABYLON.Vector3(depthOfWall, depthOfWall, depthOfWall);
    } else if (store.sketchMode === "solid") {
      diff = new BABYLON.Vector3(pullMagnitude, pullMagnitude, pullMagnitude);
    }

    for (let i = 0; i < drawingStack.length; i++) {
      if (store.sketchMode === "void") {
        let originalPoint = new BABYLON.Vector3(
          drawingStack[i].x - extension * unitNormalVector.x,
          drawingStack[i].y - extension * unitNormalVector.y,
          drawingStack[i].z - extension * unitNormalVector.z
        );
        downPolygon.push(originalPoint); //When the point is perfectly on the wall, CSG is not working sometimes,
        // thus extending it a little
      } else {
        let originalPoint = new BABYLON.Vector3(
          drawingStack[i].x,
          drawingStack[i].y,
          drawingStack[i].z
        );
        downPolygon.push(originalPoint);
      }
      // let heightedPoint = new BABYLON.Vector3(drawingStack[i].x + (diff.x * normalVector[0]), drawingStack[i].y + (diff.y * normalVector[1]), drawingStack[i].z + (diff.z * normalVector[2]));
      let heightedPoint = new BABYLON.Vector3(
        drawingStack[i].x + diff.x * unitNormalVector.x,
        drawingStack[i].y + diff.y * unitNormalVector.y,
        drawingStack[i].z + diff.z * unitNormalVector.z
      );
      upPolygon.push(heightedPoint);
    }

    if(_profile._curveIdList.length>0)
    {
      let profiles = {
        path_bottom_vec: downPolygon,
        path_top_vec: upPolygon,
        reverse_stack: reverseStackInLoops
      }
      createCellPtIdsMap(profiles);
    }

    let polygonObject = {
      path_bottom_vec: downPolygon,
      path_top_vec: upPolygon,
      normal:null,
      height:null,
      shiftCentre:true,
      faceLoops: _profile._faceLoops.length>0? _profile._faceLoops:null,
    };
    _commandData = [];

    let mesh = createCustomMeshAccordingToNormal(polygonObject);

    mesh.type = "Mass";
    mesh.hideEdges = true;
    // mergedMesh.material = store.scene.getMaterialByName("basicObjectMaterial");
    mesh.storey = storeyVal;
    mesh.height =
      mesh.getBoundingInfo().boundingBox.maximumWorld.y -
      mesh.getBoundingInfo().boundingBox.minimumWorld.y;

    //correctMeshNormals(mesh);

    deleteDrawnLines();

    return mesh;
  }

  /*
    Originally written for merging CGAL facets
     */
  function _differencePreProcessor(brepPrecursor) {
    const _mergeFacets = function (facets) {
      const _rotateLeft = function (array, count) {
        while (count--) {
          array.push(array.shift());
        }

        return array;
      };

      const _getChunks = function (facets) {
        // return _.chunk(facets, 2);

        const chunks = [];
        const processedIndices = [];

        facets.forEach((facet1, i) => {
          if (processedIndices.includes(i)) return;
          facets.some((facet2, j) => {
            if (j === i) return;
            if (processedIndices.includes(j)) return;
            const intersection = _.intersection(facet1, facet2);
            if (intersection.length === 2) {
              chunks.push([facet1, facet2]);
              processedIndices.push(i, j);
              return true;
            } else if (intersection.length === 4) {
              chunks.push([facet1, facet2]);
              processedIndices.push(i, j);
              return true;
            }
          });
        });

        if (facets.length !== processedIndices.length) {
          facets.forEach((facet, i) => {
            if (!processedIndices.includes(i)) {
              chunks.push([facet]);
            }
          });
        }

        return chunks;
      };

      const _mergeFacetsWith1CommonEdge = function (
        facet1,
        facet2,
        commonEdge
      ) {
        let commonEdges = _.chunk(commonEdge, 2);

        let mergedVertices = [];
        let pointsToAvoidInFacet1 = [];
        let pointsToAvoidInFacet2 = [];

        commonEdges.forEach((ce) => {
          pointsToAvoidInFacet1.push(ce[1]);
          pointsToAvoidInFacet2.push(...ce);
        });

        let startingIndex = facet1.indexOf(commonEdge[1]);
        mergedVertices.push(facet1[startingIndex]);

        let verticesWithHoles = [];

        let nextIndex = startingIndex;
        let facet1Active = true;
        let facet2Active = false;

        const condition = true;
        while (condition) {
          let pointsToAvoid = facet1Active
            ? pointsToAvoidInFacet1
            : pointsToAvoidInFacet2;
          let facet = facet1Active ? facet1 : facet2;
          nextIndex = nextIndex === facet.length - 1 ? 0 : nextIndex + 1;
          if (pointsToAvoid.includes(facet[nextIndex])) {
            if (facet2Active) {
              verticesWithHoles.push(mergedVertices);
              let facet1Remaining = _.difference(facet1, mergedVertices);
              let facet2Remaining = _.difference(facet2, mergedVertices);
              let commonEdgesRemaining = _.intersection(
                facet1Remaining,
                facet2Remaining
              );

              facet1Remaining = _verifyFacetOrder(
                commonEdgesRemaining,
                facet1Remaining
              );
              facet2Remaining = _verifyFacetOrder(
                commonEdgesRemaining,
                facet2Remaining
              );
              commonEdgesRemaining = _.intersection(facet1, facet2);

              const otherVertices = _mergeFacetsWith1CommonEdge(
                facet1Remaining,
                facet2Remaining,
                commonEdgesRemaining
              );

              verticesWithHoles.push(otherVertices);
              break;
            }

            const element = facet[nextIndex];
            facet1Active = !facet1Active;
            facet2Active = !facet2Active;

            facet = facet1Active ? facet1 : facet2;
            nextIndex = facet.indexOf(element);
            nextIndex = nextIndex === facet.length - 1 ? 0 : nextIndex + 1;
          } else {
            mergedVertices.push(facet[nextIndex]);
            pointsToAvoid.push(facet[nextIndex]);
          }

          if (
            pointsToAvoidInFacet1.length === facet1.length &&
            pointsToAvoidInFacet2.length === facet2.length
          ) {
            break;
          }
        }

        if (_.isEmpty(verticesWithHoles)) {
          return mergedVertices;
        } else {
          return verticesWithHoles;
        }
      };

      const _verifyFacetOrder = function (commonEdge, facet) {
        const indices = commonEdge.map((e) => facet.indexOf(e));
        if (indices.includes(0) && !indices.includes(1)) {
          return _verifyFacetOrder(commonEdge, _rotateLeft(facet, 1));
        } else {
          return facet;
        }
      };

      const chunks = _getChunks(facets);
      if (chunks.length === facets.length) return facets;
      // adjacent facets are ordered next to each other

      let dontCallMeAgain = false;
      let biggerFacets = [];
      chunks.forEach((chunk) => {
        if (chunk.length === 1) {
          biggerFacets.push(chunk[0]);
          return;
        }
        let facet1 = chunk[0];
        let facet2 = chunk[1];

        let commonEdge = _.intersection(facet1, facet2);
        facet1 = _verifyFacetOrder(commonEdge, facet1);
        facet2 = _verifyFacetOrder(commonEdge, facet2);
        commonEdge = _.intersection(facet1, facet2);

        if (commonEdge.length === 4) {
          // dontCallMeAgain = true;
          const vertices = _mergeFacetsWith1CommonEdge(
            facet1,
            facet2,
            commonEdge
          );
          biggerFacets.push(vertices);
        } else if (commonEdge.length === 2) {
          const vertices = _mergeFacetsWith1CommonEdge(
            facet1,
            facet2,
            commonEdge
          );
          biggerFacets.push(vertices);
        }
      });

      if (dontCallMeAgain || _.isEmpty(biggerFacets)) {
        if (_.isEmpty(biggerFacets)) biggerFacets = facets;
      } else {
        biggerFacets = _mergeFacets(biggerFacets);
      }

      return biggerFacets;
    };

    const cells = brepPrecursor.cells;

    const peripheralIndices = [];
    const triangularFacets = _.remove(cells, (c) => c.length === 3);
    const biggerFaces = _mergeFacets(triangularFacets);

    cells.push(...biggerFaces);

    cells.forEach((cellArray) => {
      if (!_.isArray(cellArray[0])) return;

      // face with hole
      const facesOnPeriphery = [];
      cellArray.some((cell, i) => {
        const intersection = _.intersection(cell, peripheralIndices);
        if (_.isEmpty(intersection)) {
          facesOnPeriphery.push(i);
          return true;
        }
      });

      if (facesOnPeriphery[0] !== 0) {
        const cell = cellArray.splice(facesOnPeriphery[0], 1);
        cellArray.unshift(cell);
      }
    });
    /*
        let face1 = [[0, 1, 6, 5], [7, 8, 9, 10]];
        let face2 = [[3, 2, 11, 4], [12, 13, 14, 15]];

        cells.push(face1);
        cells.push(face2);
        */

    return brepPrecursor;
  }
  
  function _getCreationCommand(createdMeshes){
    
    if (!_.isArray(createdMeshes)) createdMeshes = [createdMeshes];
    
    const commandData = commandUtils.creationOperations.getCommandData(
      createdMeshes,
    );
    
    return commandUtils.creationOperations.getCommand(
      commandUtils.CONSTANTS.drawMassOperation,
      commandData
    );
  }

  function _assignPropertiesToTheCreatedMass(createdPolygon, pickedMesh) {
    // store.newScene.activeCamera.attachControl(canvas, true, false);

    let structures = StructureCollection.getInstance();
    let str;
    let level;
    
    str = structures.getStructureById(store.activeLayer.structure_id);
    level = str.getLevelsByLow(0)[0]; //not sure how to get the right level
    createdPolygon.structure_id = store.activeLayer.structure_id;
    createdPolygon.storey = 1; //dummy value
    level.addMeshToLevel(createdPolygon, false); //createdPolygon.type = "Mass"

    if (
      _drawingMode === _modes.drawingOnGround ||
      pickedMesh.name === "drawingPlane"
    ) {
      assignProperties(createdPolygon, 0, "mass");
      createdPolygon.room_id = makeid(3);
      createdPolygon.getSnaptrudeDS().room_id = createdPolygon.room_id;
    }

    const createdPolygonComponent = createdPolygon.getSnaptrudeDS();
    StoreyMutation.assignStorey(createdPolygonComponent);

    createdPolygonComponent.assignProperties();

    if (store.sketchMode === "void") {
      if (pickedMesh.type.toLowerCase() === "wall") {
        if (pickedMesh.childrenComp.length === 0)
          pickedMesh.getSnaptrudeDS().originalWallMesh =
            getSerializedFormOfMesh(pickedMesh);

        createdPolygon.setParent(pickedMesh);
        createdPolygon.type = "Void";
        createdPolygonComponent.disallowTypeChange();
        createdPolygonComponent.massType = "Mass";
        pickedMesh.childrenComp.push(createdPolygon);

        let optionsForCSG = {
          rePosition: createdPolygon.parent.absolutePosition.asArray(),
          reScale: createdPolygon.parent.absoluteScaling.asArray()
        };

        let commandsObject = updateCSG(createdPolygon, optionsForCSG);
        let wallCreationCmd = commandsObject.creationCommands[0];
        let deletionCmd = commandsObject.deletionCommands[0];
        let dllCommand = commandsObject.dllCommands[0];

        /*createdPolygon.visibility = 1;
        let wallCreationCmd, deletionCmd, dllCommand;*/
        
        let voidCreationCmd = _getCreationCommand(createdPolygon);
        
        CommandManager.execute(
          _.compact([
            wallCreationCmd,
            voidCreationCmd,
            deletionCmd,
            dllCommand,
          ]),
          [false, false, true, false]
        );
      }
    } else if (store.sketchMode === "solid") {
      //fill the position data
    let positions = [];
    let verData =   createdPolygon.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    let verticesLength = createdPolygon.verticesLength;
    for (let i = 0; i < verticesLength*2*3;) {
      positions.push([verData[i++], verData[i++], verData[i++]]);
    }

    if(_profile._faceLoops.length)
    {
      //fill the face loop data
      let faceLoops = [];
      faceLoops.push([].concat(_profile._faceLoops[0]));
      faceLoops.push([].concat(_profile._faceLoops[1]));
      for(var i=2;i<_profile._faceLoops.length; i++)
      {
        let loop = _profile._faceLoops[i][0];
        var newLoop = new Array(loop.length);
        for(var j=0, k=loop.length-1; j<loop.length/2; j++, k--)
        {
            newLoop[j] = loop[j];
            newLoop[j+loop.length/2] = loop[k];
        }
        faceLoops.push([newLoop]);
      }
      let brep = generateBrepFromPositionsAndCells(positions, faceLoops);
      if (brep) {
        let ds = createdPolygon.getSnaptrudeDS();
        ds.brep = brep;
        constructEdgeHalfEdgeMap(ds);
        createdPolygon.BrepToMesh();
      }
    }
    else
      sortFaceIndicesForHalfEdge(createdPolygon);

      let createdPolygonDS = createdPolygon.getSnaptrudeDS();

      initProfile(_profile);

      if (!verifyBRepIntegrity(createdPolygonDS.brep)) {
        removeMeshFromStructure(createdPolygon);
        createdPolygon.dispose();
        cleanUpDrawingData();
        return;
      }

      let integrationGeometryChangeCommand;
      let plinthCreationCommand;

      if (_drawingMode === _modes.drawingOnMassOrSomething) {
        createdPolygonDS.dependantMass = true;
        createdPolygonDS.massType = "Mass";

        // createdPolygon.getSnaptrudeDS().brep.getFaces().splice(1);
        // createdPolygon.BrepToMesh();
      } else {
        _determineDrawnMassType(createdPolygon);

        createdPolygonDS = createdPolygon.getSnaptrudeDS();
        // getting again, because it could've changed from mass to wall

        integrationGeometryChangeCommand =
          virtualSketcher.addWithGeometryEdit(createdPolygonDS);
        if (_autoCompleteData.pathCompletion) {
          const allCollinearVerticesBottom = getCollinearVertices(
            store.drawingPointStack
          );
          let collinearVerticesBottom = _.intersection(
            allCollinearVerticesBottom,
            _autoCompleteData.pathCompletion
          );
          // because we wouldn't want to label a user added collinear vertex
          // as system generated

          // because of auto dimension tuning, have to check if a point in drawingPointStack
          // is still there on the mass
          const allCurrentVerticesOfComponent = getAllVertices(createdPolygonDS);

          collinearVerticesBottom = collinearVerticesBottom.filter(v => {
              return allCurrentVerticesOfComponent.inArray(vertexInComponent => vertexInComponent.almostEquals(v));
          });

          const collinearVerticesTop = collinearVerticesBottom.map((v) => {
            const vClone = v.clone();
            vClone.y += _massGenerationData.pullMagnitude;

            return vClone;
          });

          labelVerticesAndEdgesAsSystemGenerated(
            createdPolygon,
            createdPolygonDS.brep,
            collinearVerticesBottom,
            collinearVerticesTop
          );
        }

        if (createdPolygon.storey === 1) {
          // Create a Plinth under the drawn mass
          plinthCreationCommand = Mass.drawPlinth(createdPolygon);
        }
      }
      
      let creationCommand = _getCreationCommand(createdPolygon);

      let parentChangeCommand;
  
      // Disabling all parent child relationship establishment on draw
      
      const attemptEstablishingParentChildRelationship = false;
      // const attemptEstablishingParentChildRelationship = _drawingMode === _modes.drawingOnMassOrSomething;
      
      if (attemptEstablishingParentChildRelationship) {
        if (pickedMesh.name === "drawingPlane") {
          pickedMesh = _selectedMesh;
        }

        if (_dependantMassType === _dependantMassTypes.singleMeshSingleStorey) {
          
          if (
            ["mass", "wall"].includes(pickedMesh.type.toLowerCase()) &&
            !isTerrainMesh(pickedMesh)
          ) {
            
            const averagePoint = _getAverage();
            
            if (!isComponentThin(pickedMesh.getSnaptrudeDS()) &&
              !_doPointsLieOnXZPlane() &&
              isPointInTheVicinityOfMesh(averagePoint, pickedMesh)
            ){
              let commandData =
                commandUtils.parentChildOperations.getCommandData(createdPolygon);
  
              createdPolygon.setParent(pickedMesh);
    
              if (
                ["mass", "wall", "floor", "roof"].includes(pickedMesh.type.toLowerCase()) &&
                !isTerrainMesh(pickedMesh)
              ) {
                pickedMesh.childrenComp.push(createdPolygon);
              }
    
              commandData = commandUtils.parentChildOperations.getCommandData(
                createdPolygon,
                {
                  data: commandData,
                }
              );
    
              parentChangeCommand = commandUtils.parentChildOperations.getCommand(
                "drawMassParenting",
                commandData
              );
            }
          }
        }
      }

      const allCommands = _.compact([
        creationCommand,
        parentChangeCommand,
        integrationGeometryChangeCommand,
        plinthCreationCommand,
      ]);
      CommandManager.execute(
        allCommands,
        allCommands.map(() => false)
      );

      onSolid(createdPolygon);
      setLayerTransperancy(createdPolygon);
    }
  }

  function _determineDrawnMassType(createdPolygon) {
    function _doWallThings() {
      changeTypeInStructure(createdPolygon, "Wall");

      const heightOfWall = getActiveStoreyHeight() - store.projectProperties.properties.slabThicknessProperty.getValue();

      changeHeightOfObjects([createdPolygon], {
        newHeightInBabylonUnits: heightOfWall,
      });

      let wall = createdPolygon.getSnaptrudeDS();
      wall.allowTypeChange();

      wall.originalWallMesh = getSerializedFormOfMesh(createdPolygon);
      wall.calculateWidth();

      // Make the wall a Glass wall if thickness is less than equal to 50mm

      // let meshDimensions = objectPropertiesView.getMeshDimensions(createdPolygon, {valueInSnapDim: true});
      // let thickness = meshDimensions.breadth > meshDimensions.depth ? meshDimensions.depth : meshDimensions.breadth;

      const thickness = wall.wThickness;
      if (thickness - DisplayOperation.getOriginalDimension(50, "millimeter") < 1e-3) {
        // wall.properties.wallMaterialType = "GLASS";
        // Moving the decision of wall type to project props
      }

      wall.properties.wallMaterialType = store.projectProperties.properties.wallTypePropertyExt.getValue();
      wall.updateDefaultMaterial();
      onSolid(createdPolygon);
    }

    function _checkIfWall() {
      
      const sanitizedPoints = sanitizeVertices(store.drawingPointStack, { collinearThreshold: 1e2 });
      if (sanitizedPoints.length === 4) {
        let firstPoint = store.drawingPointStack[0];
        let secondPoint = store.drawingPointStack[1];
        let thirdPoint = store.drawingPointStack[2];
        let lastPoint = store.drawingPointStack[3];

        let bigSideThreshold = mmToSnaptrudeUnits(1000);
        let smallSideThreshold = mmToSnaptrudeUnits(300);

        let edges = [
          distanceBetweenPointAndLine(firstPoint, secondPoint, thirdPoint),
          distanceBetweenPointAndLine(secondPoint, thirdPoint, lastPoint),
          distanceBetweenPointAndLine(thirdPoint, lastPoint, firstPoint),
          distanceBetweenPointAndLine(lastPoint, firstPoint, secondPoint),
        ];

        edges = edges.map((e) => _.round(e, 2));

        let edgesLessThanSmallThreshold = edges.filter(
          (e) => e <= smallSideThreshold
        ).length;
        let edgesGreaterThanBigThreshold = edges.filter(
          (e) => e >= bigSideThreshold
        ).length;
        let edgesBetween =
          edges.length -
          (edgesLessThanSmallThreshold + edgesGreaterThanBigThreshold);

        const are13Parallel = areEdgesParallel(
          { headPt: firstPoint, tailPt: secondPoint },
          { headPt: thirdPoint, tailPt: lastPoint }
        );

        const are24Parallel = areEdgesParallel(
          { headPt: secondPoint, tailPt: thirdPoint },
          { headPt: lastPoint, tailPt: firstPoint }
        );

        if (!are13Parallel && !are24Parallel) return false;

        if (
          (edgesLessThanSmallThreshold === 2 &&
            edgesGreaterThanBigThreshold === 2) ||
          (edgesLessThanSmallThreshold === 2 &&
            edgesGreaterThanBigThreshold === 1 &&
            edgesBetween === 1) ||
          (edgesLessThanSmallThreshold === 1 &&
            edgesGreaterThanBigThreshold === 2 &&
            edgesBetween === 1)
        ) {
          return true;
        }
      } else {
        // wallSplitterCheck(store.drawingPointStack.map(v3 => v3.asArray()));
      }
    }

    if (_checkIfWall()) {
      _doWallThings();
    } else {

      if (_drawingMode === _modes.drawingOnGround) {
        const createdPolygonDS = createdPolygon.getSnaptrudeDS();
        const isColumn = shouldMassBeAColumn(createdPolygonDS);

        if (isColumn){
          assignColumnProperties(createdPolygonDS);
          onSolid(createdPolygon);
        }
        else {
          _assignPropertiesForMassToGenerateWalls(createdPolygon);
        }

      } else if (_drawingMode === _modes.drawingOnMassOrSomething) {
        createdPolygon.getSnaptrudeDS().massType = "Mass";
      } else {
        // undetermined case, defaulting to 'room'
        _assignPropertiesForMassToGenerateWalls(createdPolygon);
      }
    }
  }
  
  function _getCurrentStorey(){
    if (store.$scope.isTwoDimension) {
      return store.activeLayer.storey;
    }
    else {
      const lowestY = _.min(store.drawingPointStack.map(v => v.y));
      const storeyObject = StoreyMutation.queries.onWhichStoreyDoesTheHeightBelong(lowestY);
      return storeyObject?.value || 1;
    }
  }

  function _getPullMagnitude() {
    let pullMagnitude = null;
    if (_drawingMode === _modes.drawingOnMassOrSomething) {
      // pullMagnitude = 0.01;
      pullMagnitude = mmToSnaptrudeUnits(2);
    } else {
      let storeyHeight;
      if (store.$scope.isTwoDimension) storeyHeight = getActiveStoreyHeight();
      else {
        const lowestY = _.min(store.drawingPointStack.map(v => v.y));
        const storeyObject = StoreyMutation.queries.onWhichStoreyDoesTheHeightBelong(lowestY);
        if (storeyObject) storeyHeight = storeyObject.height;
      }
      pullMagnitude = storeyHeight || store.floor_height;
    }

    return pullMagnitude;
  }

  function _isMeshGroundLike(mesh) {
    return (
      mesh.name === "ground1" ||
      mesh.name === "referenceGround" ||
      mesh.name === "layerRefPlane" ||
      isTerrainMesh(mesh)
    );
  }

  let _assignPropertiesForMassToGenerateWalls = function (createdPolygon) {
    createdPolygon.room_type = "Default";
    createdPolygon.room_unique_id = makeid(3);
  };

  const _getPointThatMakesAngle = function (currentPoint, angleValue) {
    const lastPoint = _.nth(store.drawingPointStack, -1);
    const secondLastPoint =
      _.nth(store.drawingPointStack, -2) || _pointUsedForAngleSnap || _getDisplacedPoint(lastPoint);

    const rotationAxis = getUnitNormalVectorV3CyclicCheck([
      secondLastPoint,
      lastPoint,
      currentPoint,
    ]);

    if (!rotationAxis) return currentPoint;
    // collinear points

    return setAngleBetweenPoints(
      secondLastPoint,
      lastPoint,
      currentPoint,
      rotationAxis,
      angleValue
    );
  };

  const _handleAngleInput = function (angle, lockInput = false) {
    if (store.drawingPointStack.length >= 1) {
      const angleValue = degreeToRadian(parseFloat(angle));
      currentPointOnDraw = _getPointThatMakesAngle(
        currentPointOnDraw,
        angleValue
      );
      inputCoordinationVariables.lockedAngleReferencePoint =
        currentPointOnDraw.clone();
      _drawCurrentLine([startingPointOnDraw, currentPointOnDraw]);
      _showIndicators();

      if (store.isiPad) {
        _escapePressedOniPad = false;
      } else {
        if (lockInput) {
          inputCoordinationVariables.lockedAngleValue = angleValue;
        } else {
          _onPointerDownDrawTool();
        }
      }
    }
  };

  const _handleDistanceInput = function (distanceText, lockInput = false) {
    
    if (startingPointOnDraw) {
      const distance = DisplayOperation.getOriginalDimension(distanceText);
      currentPointOnDraw = startingPointOnDraw.add(
        currentPointOnDraw
          .subtract(startingPointOnDraw)
          .normalize()
          .scale(distance)
      );

      _drawCurrentLine([startingPointOnDraw, currentPointOnDraw]);

      if (store.isiPad) {
        _escapePressedOniPad = false;
      } else {
        if (lockInput) {
          inputCoordinationVariables.lockedDistanceAmount = distance;
        } else {
          _onPointerDownDrawTool();
        }
      }
    } else {
      // input before first point

      if (
        _keypadInputBeforeFirstPoint.point1 &&
        _keypadInputBeforeFirstPoint.point2
      ) {

        const comma = ",";
        const isDualInput = distanceText.includes(comma);

        let firstLength, secondLength;

        if (isDualInput){
          const twoParts = distanceText.split(comma, 2);
          firstLength = DisplayOperation.getOriginalDimension(twoParts[0]);
          secondLength = DisplayOperation.getOriginalDimension(twoParts[1]);
        }
        else {
          firstLength = DisplayOperation.getOriginalDimension(distanceText);
        }

        const anchorPoint = _keypadInputBeforeFirstPoint.point1;
        const firstDirection = _keypadInputBeforeFirstPoint.point2.subtract(anchorPoint).normalize();

        const firstExtension = anchorPoint.add(firstDirection.scale(firstLength));

        if (isDualInput && _keypadInputBeforeFirstPoint.point3){
          const secondDirection = _keypadInputBeforeFirstPoint.point3.subtract(anchorPoint).normalize();
          currentPointOnDraw = firstExtension.add(secondDirection.scale(secondLength));
        }
        else currentPointOnDraw = firstExtension;

        _onPointerDownDrawTool();

        _provideFeedbackForDualInput(currentPointOnDraw);
      }
    }
    
  };

  function _provideFeedbackForDualInput(snappedPoint){
    // just adding a vertex for now
    uiIndicatorsHandler.vertexIndicator.show(snappedPoint, null, {
      indicator: uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
    });
  }

  function _getDisplacedPoint(point, coordinate = "x", displacement = 1000) {
    const displacedPoint = point.clone();
    displacedPoint[coordinate] += displacement;

    return displacedPoint;
  }

  function _findNearestSnapPointOnSameDrawingPath(pickedPoint) {
    let snappedPoint = null;

    // let threshold = getDynamicThreshold(pickedPoint);

    store.drawingPointStack
      .slice(0, store.drawingPointStack.length - 1)
      .some((point) => {
        if (snapEngine.snapVerifier.checkIfSnapIsValidInScreenSpace(point)) {
          snappedPoint = point;
          return true;
        }
      });

    return snappedPoint;
  }

  function _doDrawingSnaps(currentPoint) {
    let snappedPoint = _findNearestSnapPointOnSameDrawingPath(currentPoint);

    if (snappedPoint) {
      uiIndicatorsHandler.vertexIndicator.show(snappedPoint, null, {
        indicator: uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
      });

      uiIndicatorsHandler.axisIndicator.remove();
    }

    return snappedPoint;
  }

  let _drawOutline = function () {
    if (_outlineMesh) _outlineMesh.dispose();

    _outlineMesh = BABYLON.MeshBuilder.CreateLines(
      "drawnLineUsingClicks",
      {
        points: store.drawingPointStack,
        updatable: true,
      },
      store.newScene
    );

    _outlineMesh.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    _outlineMesh.color = BABYLON.Color3.Black();

    _outlineMesh.renderingGroupId = 1;
  };

  let _disposeCurrentLine = function () {
    if (_currentLineMesh) _currentLineMesh.dispose();
  };

  let _drawCurrentLine = function (lastLineCoordinates) {
    _disposeCurrentLine();

    _currentLineMesh = BABYLON.MeshBuilder.CreateLines(
      "lastMovedLine",
      {
        points: lastLineCoordinates,
        updatable: true,
      },
      store.newScene
    );

    _currentLineMesh.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    _currentLineMesh.color = BABYLON.Color3.Black();

    _currentLineMesh.renderingGroupId = 1;

    const axisIndicators = uiIndicatorsHandler.axisIndicator
      .getMetadata()
      .getActiveIndicators();

    axisIndicators.forEach((ai) => {
      if (
        ai.snaptrudeProperties.type ===
        uiIndicatorsHandler.axisIndicator.TYPES.axis3
      )
        return;
      // this is the distance/angle input locked extension line
      // shouldn't be removed

      const v1 = lastLineCoordinates[0].subtract(lastLineCoordinates[1]);
      const v2 = ai.snaptrudeProperties.firstPoint.subtract(
        ai.snaptrudeProperties.secondPoint
      );

      if (areTwoLinesCollinear(v1, v2, 2)) {
        _currentLineMesh.color =
          colorUtil.getColor(
            colorUtil.getColorNameForType(ai.snaptrudeProperties.axisType)
          ) || colorUtil.getColor("black");

        uiIndicatorsHandler.axisIndicator.remove(ai.snaptrudeProperties.type);
      }
    });
  };

  function _resetInputsHandlingData() {
    inputCoordinationVariables.lockedInput = null;
    inputCoordinationVariables.focusedInput = _CONSTANTS.distanceInput;
    inputCoordinationVariables.lockedDistanceAmount = null;
    inputCoordinationVariables.lockedAngleValue = null;
    inputCoordinationVariables.lockedAngleReferencePoint = null;
  }

  function cleanUpDrawingData(disposeVertexIndicators = true) {
    // store.newScene.activeCamera.attachControl(canvas, true, false);
    store.drawingPointStack.length = 0;

    if (_outlineMesh) _outlineMesh.dispose();
    if (_currentLineMesh) _currentLineMesh.dispose();
    if (_drawingPlaneMesh) _drawingPlaneMesh.dispose();

    // disposeMesh("drawingPlane");
    // disposeReferenceGround();
    disposeSnappingObjects();
    turnOffSnappingEngine();
    disposeAngles();

    if (disposeVertexIndicators) {
      _selectedMesh = null;
      disposeAllVertexIndicators();
    }
    DisplayOperation.removeDimensions();

    _resetInputsHandlingData();

    startingPointOnDraw = null;
    currentPointOnDraw = null;
    _selectedMesh = null;
    drawingPointEdgesForTertiarySnap = [];
    faceCoordinates = null;

    _resetDrawingMode();
    _snapMetadata = {};

    _noBRepOnElement = false;
    _dependantMassType = null;
    _secondPick = null;
    _drawingPlaneMesh = null;
    _escapePressedOniPad = false;

    _keypadInputBeforeFirstPoint.point1 = null;
    _keypadInputBeforeFirstPoint.point2 = null;
    _keypadInputBeforeFirstPoint.point3 = null;

    _autoCompleteData = {};
    _massGenerationData = {};
    _pickInfo = null;
    _alternateSnapData = null;
    _splitMassData.snapsData = [];
    
    drawWallData.snapPointWallMap = new Map();
    currArcData.arcSelectedPts= [null,null,null];
    currArcData.selMode = SEL_MODE.START_PT;
  }

  function _showIndicators(point1 = startingPointOnDraw, point2 = currentPointOnDraw) {

    const firstDrawnPoint = store.drawingPointStack[0] || point1;
    
    if (!point1 || !point2) return;

    const distanceInputFocus =
      inputCoordinationVariables.focusedInput === _CONSTANTS.distanceInput;

    const lineLength = getDistanceBetweenVectors(point1, point2);
    const displacement = 0.5 * lineLength;
    // const inputTextPosition = point2.add(point1.subtract(point2).normalize().scale(displacement));
    const inputTextPosition = firstDrawnPoint;
    const pointerLocation = {
      x: _pointerMoveEvent.clientX,
      y: _pointerMoveEvent.clientY,
    };

    const distance = BABYLON.Vector3.Distance(point1, point2);

    DisplayOperation.drawOnMove(firstDrawnPoint, firstDrawnPoint, "drawTool");
    DisplayOperation.displayOnMove(
      distance,
      null,
      true,
      {
        inputFocus: distanceInputFocus,
        onChangeCallback: _handleDistanceInput,
        inputTextPosition,
        followMouse: true,
        pointerLocation,
      }
    );

    if (store.drawingPointStack.length >= 1) {
      const pivotPoint = point1;
      let firstPoint = _.nth(store.drawingPointStack, -2);
      const secondPoint = point2;

      if (!firstPoint) {
        // when first line is being drawn i.e store.drawingPointStack.length === 1
        // firstPoint = _getDisplacedPoint(store.drawingPointStack[0]);
        firstPoint = _pointUsedForAngleSnap;
      }
      
      if (!firstPoint) return;

      const angle = getAngleInRadians(
        firstPoint.subtract(pivotPoint),
        secondPoint.subtract(pivotPoint)
      );

      const angleInDegrees = Math.abs(_.round(radianToDegree(angle), 1));

      disposeAngleSector();
      showAngles(firstPoint, pivotPoint, secondPoint, angleInDegrees, null, {
        inputFocus: !distanceInputFocus,
        onChangeCallback: _handleAngleInput,
      });
    }
  }

  function getMetadata() {
    return {
      assignPropertiesForMassToGenerateWalls:
        _assignPropertiesForMassToGenerateWalls,
    };
  }
  
  function handleTabKey(event, inputElementBeforeTab, inputElementAfterTab) {
    
    if (!currentPointOnDraw) return;
    
    if (_snapMetadata.snappedSecondarily){
      
      if (store.drawingPointStack.length === 0){
        initializeSnappingEngine(_pickInfo.pickedPoint);
      }
      
      const nextSnap = snapEngine.alternateSnaps.getNext(
        _pickInfo,
        event.shiftKey
      );
      if (nextSnap) {
        _alternateSnapData = nextSnap;
        _handleOnMovePick(nextSnap.pickInfo);
      }
      
      return true;
    }
    else {
      
      lockInput(inputElementBeforeTab, inputElementAfterTab);
      return true;
      
    }
  }

  function lockInput(inputElementBeforeTab, inputElementAfterTab) {
    if (_.isEmpty(store.drawingPointStack)) return;

    if (inputElementBeforeTab.name === _CONSTANTS.distanceInput) {
      inputCoordinationVariables.lockedInput = _CONSTANTS.distanceInput;
      inputCoordinationVariables.focusedInput = _CONSTANTS.angleInput;

      _handleDistanceInput(inputElementBeforeTab.text, true);
    } else if (inputElementBeforeTab.name === _CONSTANTS.angleInput) {
      inputCoordinationVariables.lockedInput = _CONSTANTS.angleInput;
      inputCoordinationVariables.focusedInput = _CONSTANTS.distanceInput;

      _handleAngleInput(inputElementBeforeTab.text, true);
    }
    
    return true;
  }

  function cancelOperation() {
    if (startingPointOnDraw) {
      // cleanUpDrawingData();
      if (store.isiPad) _escapePressedOniPad = true;
      if (!_.isEmpty(store.drawingPointStack)) {
        //if last curve is arc then pop the arc points
        var lastCurveType = _profile._curveTypeList[_profile._curveTypeList.length-1];

        if(currentDrawingMode == DRAWING_MODE.ARC_MODE){
          // lastCurveType == CURVE_TYPE.ARC
          if(currArcData.selMode == SEL_MODE.ARC_PT)
            currArcData.selMode = SEL_MODE.END_PT;
          else
          {
            if(lastCurveType == CURVE_TYPE.ARC){
              for(var i=0;i<currArcData.arcNumPts-1; i++)
                store.drawingPointStack.pop();
            }
            else
              store.drawingPointStack.pop();

            popLastCurve(_profile);
            currArcData.selMode = SEL_MODE.END_PT;
            if (!_.isEmpty(store.drawingPointStack))
            {
              startingPointOnDraw = _.last(store.drawingPointStack);
              currArcData.arcSelectedPts[0] = startingPointOnDraw.clone();
            }
          }

          if (_.isEmpty(store.drawingPointStack))
          {
            currArcData.selMode = SEL_MODE.START_PT;
            for(var i=0; i<3; i++)
              currArcData.arcSelectedPts[i] = null;
            cleanUpDrawingData();
          }
          else {
            let endPt = currentPointOnDraw.clone();
            let stPt = currArcData.arcSelectedPts[0];
            var dirNorm = BABYLON.Vector3.Normalize(endPt.subtract(stPt));
            var cross = BABYLON.Vector3.Cross(dirNorm, BABYLON.Vector3.Up());
            //var cross = BABYLON.Vector3.Cross(dirNorm,cross1);
            cross = cross.normalize();
            var radius = (endPt.subtract(stPt)).length() * 1.5;
            var center = stPt.add((endPt.subtract(stPt)).scale(0.5));
            center = center.add(cross.scale(radius));

            let arcEvalPoints= [];
            _constructTwoPointCenterArc(stPt,endPt,center,arcEvalPoints);

            if (_currentLineMesh) _currentLineMesh.dispose();
              _currentLineMesh = BABYLON.MeshBuilder.CreateLines(
              "lastMovedLine",
              {
                points: arcEvalPoints,
                updatable: true,
              },
              store.newScene
            );
            _currentLineMesh.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
            _currentLineMesh.color = BABYLON.Color3.Black();

            _currentLineMesh.renderingGroupId = 1;

            _drawOutline();
            _cleanUpPreviousPointAdditionAutoCompleteData();
            _.defer(_showIndicators);
          }
        }
        else
        {
          var poppedPoint = null;
          if(lastCurveType == CURVE_TYPE.ARC){
            for(var i=0;i<currArcData.arcNumPts-1; i++)
            poppedPoint = store.drawingPointStack.pop();
          }
          else
            poppedPoint = store.drawingPointStack.pop();

          drawingPointEdgesForTertiarySnap.pop();

          popLastCurve(_profile);

          if (_.isEmpty(store.drawingPointStack)) cleanUpDrawingData();
          else {
            startingPointOnDraw = _.last(store.drawingPointStack);
            _drawOutline();
            _drawCurrentLine([startingPointOnDraw, currentPointOnDraw]);
            _cleanUpPreviousPointAdditionAutoCompleteData();

            _.defer(_showIndicators);
            // this is done because in handleEscapeEvent, display box is removed, and in the beginning
            // presence of a box is checked, so successive escapes won't work.
            // Thus, creating the display box after everything.
            // Lifesaver lodash
          }

          drawWallData.snapPointWallMap.delete(poppedPoint);
        }
        _resetInputsHandlingData();
        if (store.drawingPointStack.length === _drawingPointStackLengthWhenModeWasDetermined - 1){
          _resetDrawingMode();
        }
        //currentDrawingMode = DRAWING_MODE.LINE_MODE;
        return true;
      }
    }
  }
  function _handleUserInput(input) {
    if (input.name === _CONSTANTS.distanceInput) {
      _handleDistanceInput(input.text, false);
    } else if (input.name === _CONSTANTS.angleInput) {
      _handleAngleInput(input.text, false);
    }
  }
  
  function handleKeyInput(input)
  {
    if(input == 65)
    {
      //check if current drawing mode is arc and if arc not commited then skip changing mode until arc is complete
      if( currentDrawingMode != DRAWING_MODE.ARC_MODE)
      {
        currentDrawingMode = DRAWING_MODE.ARC_MODE;
        currArcData.arcType = ARC_TYPE.THREE_POINT_ARC;
        currArcData.arcSelectedPts = [startingPointOnDraw, null, null];
        currArcData.selMode = SEL_MODE.START_PT;
        if(startingPointOnDraw != null)
          currArcData.selMode = SEL_MODE.END_PT;
        if (!_normal && (_pickInfo && _pickInfo.hit)) {
          _meshOfInterest = _pickInfo.pickedMesh;
          _faceId = getFaceIdFromFacet(_pickInfo.faceId, _meshOfInterest);
          _normal = getNormalizedNormal(_meshOfInterest, _faceId);
        }
      }
    }
    else if(input == 76)
    {
      currentDrawingMode = DRAWING_MODE.LINE_MODE;
    }
  }

  function handleEnterKey(input){
    if (input.edited){
      _handleUserInput(input);
    }
    else if (!_.isEmpty(store.drawingPointStack)){
      _drawWallsFromTheOutline();
      cleanUpDrawingData();
    }
  }
  
  function _drawWallsFromTheOutline() {
    
    const _generateWalls = function (wallData){
      let walls = straightwall(wallData);
  
      let structure_id = store.activeLayer.structure_id;
      const structureCollection = StructureCollection.getInstance();
      const structure = structureCollection.getStructureById(structure_id);
      const level = structure.getLevelByName("01");
    
      walls.forEach((wall) => {
        level.addWallToLevel(wall, false);
        StoreyMutation.assignStorey(wall);
        generateBrepForComponents(wall.mesh);
        wall.updateDefaultMaterial();
        wall.allowTypeChange();
      });
      
      return walls;
    };
  
    if (store.drawingPointStack.length < 2) return;
    
    const baselineProfiles = [];
    const nodesToBeRemovedFromTheGraph = [];
    const existingWallProfileWallMeshUniqueIdMap = new Map();
    
    const profileType = store.projectProperties.properties.wallProfileTypeProperty.getValue();
    
    nodesToBeRemovedFromTheGraph.push(
      ...store.drawingPointStack
    );
    
    store.drawingPointStack.forEach((point, i) => {
      if (i === store.drawingPointStack.length - 1) return;
      baselineProfiles.push([point, store.drawingPointStack[i + 1]]);
    });
    
    /*
    Adjust profile points based on internal/external/centre setting
    
    Not cloning the baseline points and modifying them in place,
    changes each point (apart from the extreme points) twice, so that no gap is created at the junction
    
    In the above approach, when you have two collinear walls, the middle points need to be changed only once
    If changed twice, collinearity is lost. So, adding a check for that
    
     */
    baselineProfiles.forEach((centreProfile, i) => {
      
      if (profileType === drawWallData.CONSTANTS.centreProfile) {
        // kuch nahi karna
      }
      else {
        
        const v1 = centreProfile[0];
        const v2 = centreProfile[1];
        
        const direction = v2.subtract(v1);
        
        let directionRotatedBy90;
        
        if (profileType === drawWallData.CONSTANTS.externalProfile) {
          // cw, towards right of the direction of the drawn line
          directionRotatedBy90 = new BABYLON.Vector3(direction.z, 0, -direction.x);
        }
        else if (profileType === drawWallData.CONSTANTS.internalProfile) {
          // ccw, towards left of the direction of the drawn line
          directionRotatedBy90 = new BABYLON.Vector3(-direction.z, 0, direction.x);
        }
        
        const shiftAmount = store.projectProperties.properties.wallThicknessPropertyExt.getValue() / 2;
        const shiftVector = directionRotatedBy90.normalize().scale(shiftAmount);
        
        let isCollinear = false;
        if (i !== 0){
          // not the first profile
          
          const previousProfile = getCircularlyPreviousElementInArray(baselineProfiles, centreProfile);
          isCollinear = areTwoLinesCollinear(
            previousProfile[0].subtract(previousProfile[1]),
            centreProfile[0].subtract(centreProfile[1])
          );
        }
        
        if (!isCollinear) centreProfile[0].addInPlace(shiftVector);
        centreProfile[1].addInPlace(shiftVector);
      }
      
    });
    
    /*
    To modify the geometry of existing walls at the junctions
     */
    
    if (!_.isEmpty(drawWallData.snapPointWallMap)){
      
      const _getIntersection = function (p1, p2, p1ExistingWall, p2ExistingWall, refPoint){
        const threshold = 0.5;
        // should match with geometry based wall junction update threshold during point replacement in
        // massDissector
        
        const intersection = externalUtil.getPointOfIntersection([p1, p2, p1ExistingWall, p2ExistingWall]);
        if (intersection) {
          
          if (getDistanceBetweenVectors(intersection, refPoint) > threshold) return;
          
          if (store.resolveEngineUtils.onSegment3D(p1ExistingWall, intersection, p2ExistingWall, null, true)){
            // if this point is used, new wall would be overlapping with existing wall
            const d1 = getDistanceBetweenVectors(intersection, p1ExistingWall);
            const d2 = getDistanceBetweenVectors(intersection, p2ExistingWall);
            
            if (_.min([d1, d2]) > threshold) return null;
            else return intersection;
            
          } else if (store.resolveEngineUtils.onSegment3D(p1, intersection, p2, null, true)){
            // if this point is used, drawn wall would be far from the points user has given
            const d1 = getDistanceBetweenVectors(intersection, p1);
            const d2 = getDistanceBetweenVectors(intersection, p2);
           
            if (_.min([d1, d2]) > threshold) return null;
            else return intersection;
            
          } else {
            return intersection;
          }
        }
      }
      /*
      Base lines of existing walls is modified below
      This makes identification of neighboring walls easier but the problem is
      that because of this change, there's an inconsistency between determined wallPoints and the brep positions
      of the wall, making the update complicated. Currently, have just introduced thresholds to solve it
      
      TODO - A neat solution for the above problem
      
       */
      const equalityThreshold = 1;
      drawWallData.snapPointWallMap.forEach((wallMesh, snapPoint) => {
        const wall = wallMesh.getSnaptrudeDS();
        const baseLine = wall.getBaseLine();
        
        if (baseLine){
          
          const p1ExistingWall = baseLine[0];
          const p2ExistingWall = baseLine[1];
          
          let intersectionFound = false;
          baselineProfiles.some(([p1, p2]) => {
            if (
              p1ExistingWall.almostEquals(p1, equalityThreshold)
            ) {
              const intersection = _getIntersection(p1, p2, p1ExistingWall, p2ExistingWall, p1ExistingWall);
              if (intersection) {
                intersectionFound = true;
                p1ExistingWall.copyFrom(intersection);
                p1.copyFrom(intersection);
                nodesToBeRemovedFromTheGraph.push(p2ExistingWall);
                return true;
              }
            } else if (
              p1ExistingWall.almostEquals(p2, equalityThreshold)
            ) {
              const intersection = _getIntersection(p1, p2, p1ExistingWall, p2ExistingWall, p1ExistingWall);
              if (intersection) {
                intersectionFound = true;
                p1ExistingWall.copyFrom(intersection);
                p2.copyFrom(intersection);
                nodesToBeRemovedFromTheGraph.push(p2ExistingWall);
                return true;
              }
            } else if (
              p2ExistingWall.almostEquals(p1, equalityThreshold)
            ) {
              const intersection = _getIntersection(p1, p2, p1ExistingWall, p2ExistingWall, p2ExistingWall);
              if (intersection) {
                intersectionFound = true;
                p2ExistingWall.copyFrom(intersection);
                p1.copyFrom(intersection);
                nodesToBeRemovedFromTheGraph.push(p1ExistingWall);
                return true;
              }
            } else if (
              p2ExistingWall.almostEquals(p2, equalityThreshold)
            ) {
              const intersection = _getIntersection(p1, p2, p1ExistingWall, p2ExistingWall, p2ExistingWall);
              if (intersection) {
                intersectionFound = true;
                p2ExistingWall.copyFrom(intersection);
                p2.copyFrom(intersection);
                nodesToBeRemovedFromTheGraph.push(p1ExistingWall);
                return true;
              }
            }
          });
          
          if (intersectionFound){
            existingWallProfileWallMeshUniqueIdMap.set(baseLine, wallMesh.uniqueId);
          }
          
        }
        
      });
    }
    
    const existingWallProfiles = Array.from(
      existingWallProfileWallMeshUniqueIdMap.keys()
    );
    baselineProfiles.push(...existingWallProfiles);
    
    const existingWallProfileIndices = existingWallProfiles.map((profile) =>
      baselineProfiles.indexOf(profile));
    
    const height = _getPullMagnitude() - store.projectProperties.properties.slabThicknessProperty.getValue();
    const UP = BABYLON.Vector3.Up();
    /*
    The vertices formed from the baseLine determines the order of the face vertices
    in the Brep. So there is a correct answer here for the order question.
    
    But it's difficult to determine. For simple cases, the below algo suffices
     */
    const baseVertices = baselineProfiles.map(base => {
      return [
        base[0],
        base[1],
        base[1].add(UP.scale(height)),
        base[0].add(UP.scale(height))
      ];
    });
    
    baselineProfiles.forEach((profile) => {
      virtualSketcher.coreGraphOperations.addIndividualEdge(
        ...profile
      );
    });
    
    const currentStorey = _getCurrentStorey();
    const wallPoints = baseVertices.map((vertices, i) => {
      
      const isAnExistingWall = existingWallProfileIndices.includes(i);
      
      const optionsForWallPoints = {
        isMasslessWallGeneration: true,
        storey: currentStorey,
      };
      
      let existingWall;
      if (isAnExistingWall) {
        const existingWallProfile = baselineProfiles[i];
        const existingWallUniqueId =
          existingWallProfileWallMeshUniqueIdMap.get(existingWallProfile);
        
        existingWall = meshUniqueIdMapper.get(existingWallUniqueId).getSnaptrudeDS();
        optionsForWallPoints.thickness = existingWall.getThickness();
      }
      
      const wallPoints = massDissector.getWallPoints(
        vertices,
        massDissector.CONSTANTS.wallTypes.external,
        optionsForWallPoints
      );
      
      if (isAnExistingWall){
        wallPoints.createdWallUniqueId = existingWall.mesh.uniqueId;
      }
      
      return wallPoints;
    });
    
    const optionsForHandlingWallJunction = {
      changeGeometry: true,
      geometryChangeCommand: null,
    };
    
    let wallData;
    try {
      massDissector.allotJunctionOccupationRights();
      wallData = massDissector.handleWallJunctions(
        wallPoints,
        optionsForHandlingWallJunction
      );
      massDissector.cleanUp();
    } catch (e) {
      console.error(e);
    }
    
    const walls = _generateWalls(wallData);
    
    const creationCommand = _getCreationCommand(walls.map(w => w.mesh));
    const allCommands = _.compact([
      creationCommand,
      optionsForHandlingWallJunction.junctionGeometryChangeCommand,
    ]);
    
    CommandManager.execute(allCommands, allCommands.map(_ => false));
    
    baselineProfiles.forEach((profile) => {
      virtualSketcher.coreGraphOperations.removeIndividualEdge(
        ...profile
      );
    });
    
    virtualSketcher.coreGraphOperations.removeOrphanNodes(nodesToBeRemovedFromTheGraph);
    
  }

  function convertProjectUnitPointsToV3(arrayOfPointsInProjectUnits){

    const defaultY = store.$scope.isTwoDimension ? getActiveStoreyObject().base : 0;

    const arrayOfV3s = arrayOfPointsInProjectUnits.map(pointInProjectUnits => {

        let pointInSnaptrudeUnits = pointInProjectUnits.map(point => DisplayOperation.getOriginalDimension(point));
        if (pointInSnaptrudeUnits.length === 2) pointInSnaptrudeUnits.splice(1, 0, defaultY);

        return BABYLON.Vector3.FromArray(pointInSnaptrudeUnits);
    });

    return arrayOfV3s;
  }

  function createFromPoints(arrayOfPointsInProjectUnits){

    _setDrawingMode(_modes.drawingOnGround);
    store.sketchMode = "solid";

    arrayOfPointsInProjectUnits.push(_.first(arrayOfPointsInProjectUnits));

    store.drawingPointStack = convertProjectUnitPointsToV3(arrayOfPointsInProjectUnits);
    return _concludeDraw(true);
  }

  return {
    init,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    handleEnterKey,
    getMetadata,
    cleanUp: cleanUpDrawingData,
    handleTabKey,
    cancelOperation,
    createFromPoints,
    handleKeyInput,
    convertProjectUnitPointsToV3
  };
})();
export { drawingOperator };
