import BABYLON from "../modules/babylonDS.module";
import { store } from "../modules/utilityFunctions/Store";
import { assignProperties } from "./sceneStateFuncs";
import { snapVertData } from "./snapFuncs";
import { computeStructureBounds, ZoomAll } from "./zoomFuncs";

function createWallBlocks(wall_data, mat_name, $scope) {
  var wall_pols = wall_data["pols"];
  var wall_names = wall_data["names"];
  var wall_types = wall_data["types"];
  var wall_ids = wall_data["ids"];
  var wall_pos = wall_data["pos"];
  var wall_levels = wall_data["levels"];
  var wall_rot = wall_data["rot"];
  var wall_scaling = wall_data["scaling"];
  var wall_curves = wall_data["curves"];
  var wall_paths = wall_data["paths"];
  var wall_structure = wall_data["structure"];
  var max_level = 0;

  var mat = store.scene.getMaterialByName("mat1");

  for (var i = 0; i < wall_pols.length; i++) {
    if (!wall_curves[i]) {
      var shape_temp1 = [];
      var shape_temp2 = [];
      var path = [];
      var path1 = [];
      var path2 = [];
      for (var j = 0; j < wall_pols[i].length; j++) {
        var point = new BABYLON.Vector3(
          wall_pols[i][j][0] *store.unit_scale,
          -wall_pols[i][j][1] *store.unit_scale,
          0.0
        );
        point = snapVertData(point);
        shape_temp1.push(point);
        var point = new BABYLON.Vector3(
          wall_pols[i][j][0] *store.unit_scale,
          -wall_pols[i][j][1] *store.unit_scale,
         store.floor_height
        );
        point = snapVertData(point);
        shape_temp2.push(point);
        var point = new BABYLON.Vector3(
          wall_pols[i][j][0] *store.unit_scale,
         store.floor_height,
          -wall_pols[i][j][1] *store.unit_scale
        );
        point = snapVertData(point);
        path1.push(point);
        var point = new BABYLON.Vector3(
          wall_pols[i][j][0] *store.unit_scale,
          0.0,
          -wall_pols[i][j][1] *store.unit_scale
        );
        point = snapVertData(point);
        path2.push(point);
      }

      var ribPol1 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        shape_temp1,
        store.scene
      ).build(true);
      // ribPol1.material = mat;
      var meshVert1 = ribPol1.getTotalVertices();
      var meshInd1 = ribPol1.getIndices().length;

      var ribPol2 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        shape_temp2,
        store.scene
      ).build(true);
      ribPol2.position.y =store.floor_height;
      // ribPol2.material = mat;
      var meshVert2 = ribPol2.getTotalVertices();
      var meshInd2 = ribPol2.getIndices().length;

      var ribbon = BABYLON.Mesh.CreateRibbon(
        "ribbon",
        [path1, path2],
        false,
        false,
        0,
        store.scene,
        true,
        BABYLON.DOUBLESIDE
      );
      // ribbon.material = mat;
      var meshVert3 = ribbon.getTotalVertices();
      var meshInd3 = ribbon.getIndices().length;

      var mergedMesh = BABYLON.Mesh.MergeMeshes(
        [ribPol1, ribPol2, ribbon],
        true,
        true
      );

      var indices = mergedMesh.getIndices();
      var vb = mergedMesh.getVertexBuffer(BABYLON.VertexBuffer.PositionKind);
      var verData = vb.getData();

      mergedMesh.subMeshes = [];
      var verticesCount = mergedMesh.getTotalVertices();

      mergedMesh.subMeshes.push(
        new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)
      ); // Works perfectly
      mergedMesh.subMeshes.push(
        new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)
      ); // Works perfectly
      mergedMesh.subMeshes.push(
        new BABYLON.SubMesh(
          2,
          0,
          verticesCount,
          meshInd1 + meshInd2,
          meshInd3,
          mergedMesh
        )
      ); // Works perfectly

      var multimat2 = new BABYLON.MultiMaterial("multiMat", store.scene);
      // multimat2.subMaterials.push(wall_mat4);
      multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
      multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
      multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));

      mergedMesh.material = multimat2;

      mergedMesh.checkCollisions = true;
      mergedMesh.name = "wall_" + wall_ids[i];
      mergedMesh.type = "Wall";
      mergedMesh.room_type = wall_types[i];
      mergedMesh.wall_type = wall_types[i];
      mergedMesh.wall_id = wall_ids[i];
      mergedMesh.wall_curve = wall_curves[i];
      mergedMesh.wall_path = wall_paths[i];
      mergedMesh.level = wall_levels[i];
      mergedMesh.structure = wall_structure[i];
      mergedMesh.offsetFlag = false;
      mergedMesh.checkCollisions = true;
      mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

      mergedMesh.convertToFlatShadedMesh();
      // mergedMesh.material = store.scene.getMaterialByName(mat_name);

      // console.log(mergedMesh.material);

      var bbinfo = mergedMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
      mergedMesh.setPivotPoint(centroid);

      if (wall_pos[i][0] != 0 || wall_pos[i][2] != 0) {
        mergedMesh.position.x = wall_pos[i][0] *store.unit_scale;
        // mergedMesh.position.y = wall_pos[i][1]*unit_scale;
        mergedMesh.position.z = wall_pos[i][2] *store.unit_scale;
      }
      // (mergedMesh.position.y,store.floor_height*wall_levels[i],store.unit_scale);
      if (max_level < wall_levels[i]) {
        document.getElementById("add_new_level_button").click();
        ZoomAll();
        max_level = wall_levels[i];
      }
      //			var temp = Math.abs($scope.selectedItemLevelHeight[wall_levels[i] + 1]/inch_to_mtr) * wall_levels[i] + 0.5 * Math.abs($scope.selectedItemLevelHeight[wall_levels[i]+ 1]/inch_to_mtr);
      //			console.info(temp);
      //			var temp2 =store.floor_height * wall_levels[i] + 0.5 *store.floor_height;

      mergedMesh.position.y =
       store.floor_height * wall_levels[i] + 0.5 *store.floor_height;

      mergedMesh.rotation.x = wall_rot[i][0];
      mergedMesh.rotation.y = wall_rot[i][1];
      mergedMesh.rotation.z = wall_rot[i][2];

      mergedMesh.scaling.x = wall_scaling[i][0];
      mergedMesh.scaling.y = wall_scaling[i][1];
      mergedMesh.scaling.z = wall_scaling[i][2];

      store.click(mergedMesh);
      assignProperties(mergedMesh, i, "mass");
    } else {
      var path = JSON.parse(wall_paths[i]);
      var curPoint = [];
      var ribPaths = [];
      var temp_path1 = [];
      var temp_path2 = [];
      var temp_path3 = [];
      var temp_path4 = [];
      for (var j = 0; j < path.length; j++) {
        if (path[j][0] == "M") {
          var curPoint1 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            -path[j][2] *store.unit_scale,
            0
          );
          var curPoint2 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            0,
            -path[j][2] *store.unit_scale
          );
          var curPoint3 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            -path[j][2] *store.unit_scale,
           store.floor_height
          );
          var curPoint4 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
           store.floor_height,
            -path[j][2] *store.unit_scale
          );
          temp_path1.push(curPoint1);
          temp_path2.push(curPoint2);
          temp_path3.push(curPoint3);
          temp_path4.push(curPoint4);
        }
        if (path[j][0] == "L") {
          var pt1 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            -path[j][2] *store.unit_scale,
            0
          );
          var pt2 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            0,
            -path[j][2] *store.unit_scale
          );
          var pt3 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            -path[j][2] *store.unit_scale,
           store.floor_height
          );
          var pt4 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
           store.floor_height,
            -path[j][2] *store.unit_scale
          );

          // temp_path1.push([curPoint1, pt1]);
          // temp_path2.push([curPoint2, pt2]);
          // temp_path3.push([curPoint1, pt3]);
          // temp_path4.push([curPoint2, pt4]);

          temp_path1.push(pt1);
          temp_path2.push(pt2);
          temp_path3.push(pt3);
          temp_path4.push(pt4);

          curPoint1 = pt1;
          curPoint2 = pt2;
          curPoint3 = pt3;
          curPoint4 = pt4;
        } else if (path[j][0] == "C") {
          var control1 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            -path[j][2] *store.unit_scale,
            0
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] *store.unit_scale,
            -path[j][4] *store.unit_scale,
            0
          );
          var dest1 = new BABYLON.Vector3(
            path[j][5] *store.unit_scale,
            -path[j][6] *store.unit_scale,
            0
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint1,
            control1,
            control2,
            dest1,
            10
          );
          curPoint1 = dest1;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path1.push(temp_pts[k]);
          }

          var control1 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            0,
            -path[j][2] *store.unit_scale
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] *store.unit_scale,
            0,
            -path[j][4] *store.unit_scale
          );
          var dest2 = new BABYLON.Vector3(
            path[j][5] *store.unit_scale,
            0,
            -path[j][6] *store.unit_scale
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint2,
            control1,
            control2,
            dest2,
            10
          );
          curPoint2 = dest2;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path2.push(temp_pts[k]);
          }

          var control1 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
            -path[j][2] *store.unit_scale,
           store.floor_height
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] *store.unit_scale,
            -path[j][4] *store.unit_scale,
           store.floor_height
          );
          var dest3 = new BABYLON.Vector3(
            path[j][5] *store.unit_scale,
            -path[j][6] *store.unit_scale,
           store.floor_height
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint3,
            control1,
            control2,
            dest3,
            10
          );
          curPoint3 = dest3;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path3.push(temp_pts[k]);
          }

          var control1 = new BABYLON.Vector3(
            path[j][1] *store.unit_scale,
           store.floor_height,
            -path[j][2] *store.unit_scale
          );
          var control2 = new BABYLON.Vector3(
            path[j][3] *store.unit_scale,
           store.floor_height,
            -path[j][4] *store.unit_scale
          );
          var dest4 = new BABYLON.Vector3(
            path[j][5] *store.unit_scale,
           store.floor_height,
            -path[j][6] *store.unit_scale
          );
          var bezier3 = BABYLON.Curve3.CreateCubicBezier(
            curPoint4,
            control1,
            control2,
            dest4,
            10
          );
          curPoint4 = dest4;
          var temp_pts = bezier3.getPoints();
          for (var k = 0; k < temp_pts.length; k++) {
            temp_path4.push(temp_pts[k]);
          }
        }
      }
      // (temp_path1);
      var ribPol1 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        temp_path1,
        store.scene
      ).build(true);
      ribPol1.material = mat;
      var meshVert1 = ribPol1.getTotalVertices();
      var meshInd1 = ribPol1.getIndices().length;

      var ribPol2 = new BABYLON.PolygonMeshBuilder(
        "RibPol",
        temp_path3,
        store.scene
      ).build(true);
      ribPol2.position.y =store.floor_height;
      ribPol2.material = mat;
      var meshVert2 = ribPol2.getTotalVertices();
      var meshInd2 = ribPol2.getIndices().length;

      var ribbon = BABYLON.Mesh.CreateRibbon(
        "ribbon",
        [temp_path2, temp_path4],
        false,
        false,
        0,
        store.scene,
        true,
        BABYLON.DOUBLESIDE
      );
      ribbon.material = mat;
      var meshVert3 = ribbon.getTotalVertices();
      var meshInd3 = ribbon.getIndices().length;

      var mergedMesh = BABYLON.Mesh.MergeMeshes(
        [ribPol1, ribPol2, ribbon],
        true,
        true
      );

      var indices = mergedMesh.getIndices();
      var vb = mergedMesh.getVertexBuffer(BABYLON.VertexBuffer.PositionKind);
      var verData = vb.getData();

      mergedMesh.subMeshes = [];
      var verticesCount = mergedMesh.getTotalVertices();

      mergedMesh.subMeshes.push(
        new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)
      ); // Works perfectly
      mergedMesh.subMeshes.push(
        new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)
      ); // Works perfectly
      mergedMesh.subMeshes.push(
        new BABYLON.SubMesh(
          2,
          0,
          verticesCount,
          meshInd1 + meshInd2,
          meshInd3,
          mergedMesh
        )
      ); // Works perfectly

      var wall_mat4 = new BABYLON.StandardMaterial("wall_mat4", store.scene);
      wall_mat4.alpha = 1.0;
      wall_mat4.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1);
      wall_mat4.backFaceCulling = false;

      var multimat2 = new BABYLON.MultiMaterial("multi", store.scene);
      // multimat2.subMaterials.push(wall_mat4);
      multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
      multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
      multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));

      mergedMesh.material = multimat2;

      mergedMesh.checkCollisions = true;
      mergedMesh.name = "wall_" + wall_names[i];
      mergedMesh.wall_type = wall_types[i];
      mergedMesh.wall_id = wall_ids[i];
      mergedMesh.wall_curve = wall_curves[i];
      mergedMesh.wall_path = wall_paths[i];
      mergedMesh.level = wall_levels[i];
      mergedMesh.structure = wall_structure[i];
      mergedMesh.checkCollisions = true;
      // mergedMesh.showBoundingBox = true;
      mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

      var bbinfo = mergedMesh.getBoundingInfo();
      var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);

      mergedMesh.convertToFlatShadedMesh();
      mergedMesh.material = store.scene.getMaterialByName(mat_name);

      mergedMesh.setPivotPoint(centroid);
      console.log(
        wall_pos[i][0] *store.unit_scale,
        wall_pos[i][1] *store.unit_scale,
        mergedMesh.position,
        Math.abs(bbinfo.maximum.x - bbinfo.minimum.x) / 2,
        Math.abs(bbinfo.maximum.z - bbinfo.minimum.z) / 2
      );

      if (wall_pos[i][0] != 0 || wall_pos[i][2] != 0) {
        mergedMesh.position.x = wall_pos[i][0] *store.unit_scale;
        mergedMesh.position.z = -Math.abs(wall_pos[i][2] *store.unit_scale);
      }

      mergedMesh.position.y =
       store.floor_height * wall_levels[i] + 0.5 *store.floor_height;

      mergedMesh.rotation.x = wall_rot[i][0];
      mergedMesh.rotation.y = wall_rot[i][1];
      mergedMesh.rotation.z = wall_rot[i][2];

      mergedMesh.scaling.x = wall_scaling[i][0];
      mergedMesh.scaling.y = wall_scaling[i][1];
      mergedMesh.scaling.z = wall_scaling[i][2];

      store.click(mergedMesh);
      assignProperties(mergedMesh, i, "mass");
    }
  }
}

function createDoors(door_data, mat_name, flag, $scope) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin + "/media/models/doors/door1.babylon";
    var comp_path_dir = window.location.origin + "/media/models/doors/";
    var door_mesh = store.scene.getMeshByName("door1");
    var door_mesh = loadDoorComponent(comp_path, comp_path_dir, scale_factor);
  }

  //let temp = selectionStack[0] + $scope.selectedItemLevelHeight[floor_index + 1]/inch_to_mtr;
  window.setTimeout(function () {
    var door_name = "door1";
    if (store.scene.getMeshByName(door_name) == null) {
      // console.log("reloading");
      createDoors(door_data, mat_name, false);
    } else {
      var comp_path_dir = window.location.origin + "/media/models/doors/";
      var newMesh = store.scene.getMeshByName("door1");
      // loadDoorTexture(store.scene.getMeshByName("door1"), comp_path_dir);
      // if (store.scene.getMeshByName("window1")){
      // 	var comp_path_dir = window.location.origin + "/media/models/doors/";
      // 	var newMesh = store.scene.getMeshByName("door1");
      // 	loadDoorTexture(newMesh, comp_path_dir);
      // 	console.log('returned');
      // 	comp_path_dir = window.location.origin + "/media/models/windows/";
      // 	newMesh = store.scene.getMeshByName("window1");
      // 	loadWindowTexture(newMesh, comp_path_dir);
      // }
      createDoorBlocks(door_data, mat_name);
    }
  }, 1000);
}

function createWindows(door_data, mat_name, flag, $scope) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin + "/media/models/windows/window1.babylon";
    var comp_path_dir = window.location.origin + "/media/models/windows/";
    var door_mesh = store.scene.getMeshByName("window1");
    var door_mesh = loadWindowComponent(comp_path, comp_path_dir, scale_factor);
  }
  window.setTimeout(function () {
    var door_name = "window1";
    if (store.scene.getMeshByName(door_name) == null) {
      // console.log("reloading");
      createWindows(door_data, mat_name, false);
    } else {
      // console.log("Window Loaded");
      // console.log(store.scene.getMeshByName(door_name));
      var comp_path_dir = window.location.origin + "/media/models/windows/";
      var newMesh = store.scene.getMeshByName("window1");
      // console.log(newMesh);
      // loadWindowTexture(store.scene.getMeshByName("window1"), comp_path_dir);

      createWindowLintel(door_data, mat_name);
      createWindowPlinth(door_data, mat_name);
    }
  }, 1000);
}

function getCompType(door_dims) {
  var dim0 = Math.abs(door_dims[2][0] - door_dims[0][0]);
  var dim1 = Math.abs(door_dims[2][1] - door_dims[0][1]);

  var dim2 = Math.abs(door_dims[1][0] - door_dims[0][0]);
  var dim3 = Math.abs(door_dims[1][1] - door_dims[0][1]);

  var dim4 = Math.abs(door_dims[3][0] - door_dims[2][0]);
  var dim5 = Math.abs(door_dims[3][1] - door_dims[2][1]);

  if (dim2 == 0 || dim4 == 0) {
    return "hor";
  } else if (dim3 == 0 || dim5 == 0) {
    return "ver";
  } else {
    if (dim0 > dim1) {
      return "acute";
    } else {
      return "obtuse";
    }
  }
  // console.log(door_dims);
}

function createDoorBlocks(door_data, mat_name) {
  var door_pols = door_data["pols"];
  var door_types = door_data["type"];
  var door_level = door_data["levels"];
  var door_structure = door_data["structure"];

  var mat = store.scene.getMaterialByName("mat1");
  for (var i = 0; i < door_pols.length; i++) {
    var shape_temp1 = [];
    var shape_temp2 = [];
    var path = [];
    var path1 = [];
    var path2 = [];
    var lintel = 36 *store.unit_scale;
    var center_point = new BABYLON.Vector3(0, 0, 0);

    for (var j = 0; j < door_pols[i].length; j++) {
      var point = new BABYLON.Vector3(
        door_pols[i][j][0] *store.unit_scale,
        -door_pols[i][j][1] *store.unit_scale,
       store.floor_height - lintel
      );
      point = snapVertData(point);
      shape_temp1.push(point);
      var point = new BABYLON.Vector3(
        door_pols[i][j][0] *store.unit_scale,
        -door_pols[i][j][1] *store.unit_scale,
       store.floor_height
      );
      point = snapVertData(point);
      shape_temp2.push(point);
      var point = new BABYLON.Vector3(
        door_pols[i][j][0] *store.unit_scale,
       store.floor_height,
        -door_pols[i][j][1] *store.unit_scale
      );
      point = snapVertData(point);
      path1.push(point);
      var point = new BABYLON.Vector3(
        door_pols[i][j][0] *store.unit_scale,
       store.floor_height - lintel,
        -door_pols[i][j][1] *store.unit_scale
      );
      point = snapVertData(point);
      path2.push(point);

      center_point.x += door_pols[i][j][0] *store.unit_scale;
      center_point.y += door_pols[i][j][1] *store.unit_scale;
    }
    shape_temp1.push(shape_temp1[0]);
    shape_temp2.push(shape_temp2[0]);
    path1.push(path1[0]);
    path2.push(path2[0]);

    var door_name = "door1";
    var door_type = "Vertical";
    var door_instance = loadDoorInstance(
      door_name,
      center_point,
      door_pols[i],
      lintel,
      door_type,
      i,
      door_level[i],
      door_structure[i]
    );

    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp1,
      store.scene
    ).build(true);
    ribPol1.position.y =store.floor_height - lintel;
    ribPol1.material = mat;
    var meshVert1 = ribPol1.getTotalVertices();
    var meshInd1 = ribPol1.getIndices().length;

    var ribPol2 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp2,
      store.scene
    ).build(true);
    ribPol2.position.y =store.floor_height;
    ribPol2.material = mat;
    var meshVert2 = ribPol2.getTotalVertices();
    var meshInd2 = ribPol2.getIndices().length;

    var ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    ribbon.material = mat;
    var meshVert3 = ribbon.getTotalVertices();
    var meshInd3 = ribbon.getIndices().length;

    var mergedMesh = BABYLON.Mesh.MergeMeshes(
      [ribPol1, ribPol2, ribbon],
      true,
      true
    );

    var indices = mergedMesh.getIndices();
    var vb = mergedMesh.getVertexBuffer(BABYLON.VertexBuffer.PositionKind);
    var verData = vb.getData();

    mergedMesh.subMeshes = [];
    var verticesCount = mergedMesh.getTotalVertices();

    mergedMesh.subMeshes.push(
      new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)
    ); // Works perfectly
    mergedMesh.subMeshes.push(
      new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)
    ); // Works perfectly
    mergedMesh.subMeshes.push(
      new BABYLON.SubMesh(
        2,
        0,
        verticesCount,
        meshInd1 + meshInd2,
        meshInd3,
        mergedMesh
      )
    ); // Works perfectly

    var wall_mat4 = new BABYLON.StandardMaterial("wall_mat4", store.scene);
    wall_mat4.alpha = 1.0;
    wall_mat4.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1);
    wall_mat4.backFaceCulling = false;

    var multimat2 = new BABYLON.MultiMaterial("multi", store.scene);
    // multimat2.subMaterials.push(wall_mat4);
    multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
    multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
    multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));

    mergedMesh.material = multimat2;

    mergedMesh.checkCollisions = true;
    mergedMesh.name = "door_lintel" + i;
    mergedMesh.type = "door_lintel";
    mergedMesh.door_type = door_types[i];
    mergedMesh.level = door_level[i];
    mergedMesh.structure = door_structure[i];
    mergedMesh.offsetFlag = false;

    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    mergedMesh.convertToFlatShadedMesh();
    // mergedMesh.material = store.scene.getMaterialByName(mat_name);

    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mergedMesh.setPivotPoint(centroid);
    mergedMesh.position.y +=store.floor_height * mergedMesh.level;

   store.click(mergedMesh);
  }
}

function createWindowLintel(window_data, mat_name) {
  var window_pols = window_data["pols"];
  var window_types = window_data["type"];
  var window_level = window_data["levels"];
  var window_structure = window_data["structure"];

  var mat = store.scene.getMaterialByName("mat1");
  for (var i = 0; i < window_pols.length; i++) {
    var shape_temp1 = [];
    var shape_temp2 = [];
    var path = [];
    var path1 = [];
    var path2 = [];
    var lintel = 36 *store.unit_scale;
    var center_point = new BABYLON.Vector3(0, 0, 0);
    for (var j = 0; j < window_pols[i].length; j++) {
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
        -window_pols[i][j][1] *store.unit_scale,
       store.floor_height - lintel
      );
      point = snapVertData(point);
      shape_temp1.push(point);
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
        -window_pols[i][j][1] *store.unit_scale,
       store.floor_height
      );
      point = snapVertData(point);
      shape_temp2.push(point);
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
       store.floor_height,
        -window_pols[i][j][1] *store.unit_scale
      );
      point = snapVertData(point);
      path1.push(point);
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
       store.floor_height - lintel,
        -window_pols[i][j][1] *store.unit_scale
      );
      point = snapVertData(point);
      path2.push(point);

      center_point.x += window_pols[i][j][0] *store.unit_scale;
      center_point.y += window_pols[i][j][1] *store.unit_scale;
    }
    shape_temp1.push(shape_temp1[0]);
    shape_temp2.push(shape_temp2[0]);
    path1.push(path1[0]);
    path2.push(path2[0]);

    var window_name = "window1";
    var window_type = "Vertical";
    var window_instance = loadWindowInstance(
      window_name,
      center_point,
      window_pols[i],
      lintel,
      lintel,
      window_type,
      i,
      window_level[i],
      window_structure[i]
    );

    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp1,
      store.scene
    ).build(true);
    ribPol1.material = mat;
    var meshVert1 = ribPol1.getTotalVertices();
    var meshInd1 = ribPol1.getIndices().length;

    var ribPol2 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp2,
      store.scene
    ).build(true);
    ribPol2.position.y =store.floor_height;
    ribPol2.material = mat;
    var meshVert2 = ribPol2.getTotalVertices();
    var meshInd2 = ribPol2.getIndices().length;

    var ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    ribbon.material = mat;
    var meshVert3 = ribbon.getTotalVertices();
    var meshInd3 = ribbon.getIndices().length;

    var mergedMesh = BABYLON.Mesh.MergeMeshes(
      [ribPol1, ribPol2, ribbon],
      true,
      true
    );

    var indices = mergedMesh.getIndices();
    var vb = mergedMesh.getVertexBuffer(BABYLON.VertexBuffer.PositionKind);
    var verData = vb.getData();

    mergedMesh.subMeshes = [];
    var verticesCount = mergedMesh.getTotalVertices();

    mergedMesh.subMeshes.push(
      new BABYLON.SubMesh(1, 0, verticesCount, 0, meshInd1, mergedMesh)
    ); // Works perfectly
    mergedMesh.subMeshes.push(
      new BABYLON.SubMesh(0, 0, verticesCount, meshInd1, meshInd2, mergedMesh)
    ); // Works perfectly
    mergedMesh.subMeshes.push(
      new BABYLON.SubMesh(
        2,
        0,
        verticesCount,
        meshInd1 + meshInd2,
        meshInd3,
        mergedMesh
      )
    ); // Works perfectly

    var wall_mat4 = new BABYLON.StandardMaterial("wall_mat4", store.scene);
    wall_mat4.alpha = 1.0;
    wall_mat4.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1);
    wall_mat4.backFaceCulling = false;

    var multimat2 = new BABYLON.MultiMaterial("multi", store.scene);
    // multimat2.subMaterials.push(wall_mat4);
    multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
    multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));
    multimat2.subMaterials.push(store.scene.getMaterialByName("wall_mat"));

    mergedMesh.material = multimat2;

    mergedMesh.checkCollisions = true;
    mergedMesh.name = "window_lintel" + i;
    mergedMesh.type = "window_lintel";
    mergedMesh.window_type = window_types[i];
    mergedMesh.level = window_level[i];
    mergedMesh.offsetFlag = false;
    mergedMesh.structure = window_structure[i];
    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    mergedMesh.position.y +=store.floor_height * mergedMesh.level;

    mergedMesh.convertToFlatShadedMesh();
    // mergedMesh.material = store.scene.getMaterialByName(mat_name);

    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mergedMesh.setPivotPoint(centroid);

   store.click(mergedMesh);
  }
}

function createWindowPlinth(window_data, mat_name) {
  var window_pols = window_data["pols"];
  var window_types = window_data["type"];
  var window_level = window_data["levels"];
  var window_structure = window_data["structure"];

  var mat = store.scene.getMaterialByName("mat1");
  for (var i = 0; i < window_pols.length; i++) {
    var shape_temp1 = [];
    var shape_temp2 = [];
    var path = [];
    var path1 = [];
    var path2 = [];
    var plinth = 36 *store.unit_scale;

    for (var j = 0; j < window_pols[i].length; j++) {
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
        -window_pols[i][j][1] *store.unit_scale,
        0.0
      );
      point = snapVertData(point);
      shape_temp1.push(point);
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
        -window_pols[i][j][1] *store.unit_scale,
        plinth
      );
      point = snapVertData(point);
      shape_temp2.push(point);
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
        plinth,
        -window_pols[i][j][1] *store.unit_scale
      );
      point = snapVertData(point);
      path1.push(point);
      var point = new BABYLON.Vector3(
        window_pols[i][j][0] *store.unit_scale,
        0.0,
        -window_pols[i][j][1] *store.unit_scale
      );
      point = snapVertData(point);
      path2.push(point);
    }

    shape_temp1.push(shape_temp1[0]);
    shape_temp2.push(shape_temp2[0]);
    path1.push(path1[0]);
    path2.push(path2[0]);

    var ribPol1 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp1,
      store.scene
    ).build(true);
    ribPol1.material = mat;
    var ribPol2 = new BABYLON.PolygonMeshBuilder(
      "RibPol",
      shape_temp2,
      store.scene
    ).build(true);
    ribPol2.position.y = plinth;
    ribPol2.material = mat;
    var ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [path1, path2],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    ribbon.material = mat;
    var mergedMesh = BABYLON.Mesh.MergeMeshes(
      [ribPol1, ribPol2, ribbon],
      true,
      true
    );
    mergedMesh.checkCollisions = true;
    mergedMesh.name = "window_plinth" + i;
    mergedMesh.type = "window_plinth";
    mergedMesh.level = window_level[i];
    mergedMesh.offsetFlag = false;
    mergedMesh.structure = window_structure[i];
    mergedMesh.window_type = window_types[i];
    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    mergedMesh.convertToFlatShadedMesh();
    mergedMesh.material = store.scene.getMaterialByName(mat_name);

    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    mergedMesh.setPivotPoint(centroid);
    mergedMesh.position.y +=store.floor_height * mergedMesh.level;
   store.click(mergedMesh);
  }
}

function calculateLevelsAndIndex(wall_levels, wall_structure, wall_cord) {
  var floorObj = [];
  var levels = new Object();
  var value = 0;
  levels.cords = [];
  for (var i = 1; i <= wall_levels.length; i++) {
    if (
      wall_levels[i] != wall_levels[i - 1] ||
      wall_structure[i] != wall_structure[i - 1]
    ) {
      floorObj.push(levels);
      levels = new Object();
      value = value + 1;
      levels.value = wall_levels[i - 1];
      levels.structure = wall_structure[i - 1];
      levels.cords = [];
      levels.cords.push(wall_cord[i]);
    } else {
      levels.value = wall_levels[i - 1];
      levels.structure = wall_structure[i - 1];
      levels.cords.push(wall_cord[i]);
    }
  }
  return floorObj;
}

function createFloor(wall_data, mat_name, $scope) {
  var wall_pols = wall_data["pols"];
  var wall_levels = wall_data["levels"];
  var wall_structure = wall_data["structure"];
  var obj = calculateLevelsAndIndex(wall_levels, wall_structure, wall_pols);

  for (let level of obj) {
    var cont = [];
    var fmaxX, fmaxY, fminX, fminY;
    fmaxX = level.cords[0][0][0];
    fmaxY = level.cords[0][0][1];
    fminX = level.cords[0][0][0];
    fminY = level.cords[0][0][1];
    for (var i = 0; i < level.cords.length; i++) {
      for (var j = 0; j < level.cords[i].length; j++) {
        if (level.cords[i][j][0] > fmaxX) {
          fmaxX = level.cords[i][j][0];
        }
        if (level.cords[i][j][1] > fmaxY) {
          fmaxY = level.cords[i][j][1];
        }
        if (level.cords[i][j][0] < fminX) {
          fminX = level.cords[i][j][0];
        }
        if (level.cords[i][j][1] < fminY) {
          fminY = level.cords[i][j][1];
        }
      }
    }
    var sizeX = Math.abs(fmaxX - fminX) *store.unit_scale;
    var sizeY = Math.abs(fmaxY - fminY) *store.unit_scale;
    var centroidX = ((fmaxX + fminX) / 2) *store.unit_scale;
    var centroidY = ((fmaxY + fminY) / 2) *store.unit_scale;

    var temp = "";
    temp = BABYLON.Mesh.CreateBox(
      "floor",
      1.0,
      store.scene,
      false,
      BABYLON.Mesh.DEFAULTSIDE
    );
    temp.scaling.x = sizeX;
    temp.scaling.z = sizeY;
    temp.scaling.y = 0.01;
    temp.position.x = centroidX;
    temp.position.z = -centroidY;
    temp.position.y =store.floor_height * level.value + 0.5;
    temp.material = store.scene.getMaterialByName(mat_name);
    // var bbinfo = temp.getBoundingInfo();
    // setUVScale(temp, bbinfo.boundingBox.extendSizeWorld.x / 50, bbinfo.boundingBox.extendSizeWorld.z / 50);
    temp.receiveShadows = true;
    temp.name = "floor";
    temp.type = "floor";
    temp.structure = level.structure;
    temp.offsetFlag = false;
  }
}

var loadAllTexture = function (newMeshes, comp_path_dir) {
  if (true) {
    for (var p = 0; p < newMeshes.length; p++) {
      newMeshes[p].showBoundingBox = false;
      if (newMeshes[p].name.indexOf("door")) {
        comp_path_dir = window.location.origin + "/media/models/doors/";
      } else if (newMeshes[p].name.indexOf("window")) {
        comp_path_dir = window.location.origin + "/media/models/windows/";
      }
      if (newMeshes[p].material.subMaterials) {
        for (let i = 0; i < newMeshes[p].material.subMaterials.length; i++) {
          if (newMeshes[p].material.subMaterials[i].diffuseTexture) {
            var text_url =
              newMeshes[p].material.subMaterials[i].diffuseTexture.url;
            text_url.replace(/\-/g, "");
            var text = new BABYLON.Texture(comp_path_dir + text_url, store.scene);
            newMeshes[p].material.subMaterials[i].diffuseTexture = text;
          }
        }
      }
    }
  }
};

function loadDoorTexture(dMesh, comp_path_dir) {
  console.log("Loading texture");
  if (true) {
    dMesh.showBoundingBox = false;
    if (dMesh.material.subMaterials) {
      for (var i = 0; i < dMesh.material.subMaterials.length; i++) {
        if (dMesh.material.subMaterials[i].diffuseTexture) {
          var text_url =
            comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url;
          text_url.replace(/\-/g, "");
          console.log(text_url, dMesh.name);
          //  var text = new BABYLON.Texture(comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url, store.scene);
          dMesh.material.subMaterials[i].diffuseTexture = new BABYLON.Texture(
            comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url,
            store.scene
          );
        }
      }
    }
  }
  //  return dMesh;
}

function loadWindowTexture(dMesh, comp_path_dir) {
  if (true) {
    dMesh.showBoundingBox = false;
    if (dMesh.material.subMaterials) {
      for (var i = 0; i < dMesh.material.subMaterials.length; i++) {
        if (dMesh.material.subMaterials[i].diffuseTexture) {
          var text_url =
            comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url;
          text_url.replace(/\-/g, "");
          console.log(text_url, dMesh.name);
          var text = new BABYLON.Texture(
            comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url,
            store.scene
          );
          dMesh.material.subMaterials[i].diffuseTexture = new BABYLON.Texture(
            comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url,
            store.scene
          );
        }
      }
    }
  }
  // return dMesh;
}

var scaleMeshProportionate = function (mesh, scale_factor) {
  var binfo = mesh.getBoundingInfo();
  var meshWidth = binfo.maximum.x - binfo.minimum.x;
  var meshHeight = binfo.maximum.y - binfo.minimum.y;
  var meshDepth = binfo.maximum.z - binfo.minimum.z;

  if (meshHeight > 10) {
    //  var scale_factor = 5/meshHeight;
    mesh.scaling.x *= scale_factor;
    mesh.scaling.y *= scale_factor;
    mesh.scaling.z *= scale_factor;
  }
  // meshWidth, meshHeight, meshDepth, mesh.scaling;
  store.click(mesh);
};

var scaleMeshProportionate = function (mesh, scale_factor) {
  var binfo = mesh.getBoundingInfo();
  var meshWidth = binfo.maximum.x - binfo.minimum.x;
  var meshHeight = binfo.maximum.y - binfo.minimum.y;
  var meshDepth = binfo.maximum.z - binfo.minimum.z;

  if (meshHeight > 10) {
    //  var scale_factor = 5/meshHeight;
    mesh.scaling.x *= scale_factor;
    mesh.scaling.y *= scale_factor;
    mesh.scaling.z *= scale_factor;
  }
  // meshWidth, meshHeight, meshDepth, mesh.scaling;
  store.click(mesh);
};

function scaleDoor(mesh, door_dims, lintel, center_point) {
  var binfo = mesh.getBoundingInfo();
  var meshWidth = binfo.maximum.x - binfo.minimum.x;
  var meshHeight = binfo.maximum.y - binfo.minimum.y;
  var meshDepth = binfo.maximum.z - binfo.minimum.z;

  var dim0 = Math.abs(door_dims[2][0] - door_dims[0][0]);
  var dim1 = Math.abs(door_dims[2][1] - door_dims[0][1]);
  var comp_type = getCompType(door_dims);
  // console.log(comp_type);
  if (comp_type == "hor") {
    mesh.scaling.x =
      (Math.abs(door_dims[2][0] - door_dims[0][0]) *store.unit_scale) / meshWidth;
    mesh.scaling.y =
      (Math.abs(door_dims[1][1] - door_dims[0][1]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel) / meshDepth;
  } else if (comp_type == "ver") {
    mesh.scaling.x =
      (Math.abs(door_dims[2][1] - door_dims[0][1]) *store.unit_scale) / meshWidth;
    mesh.scaling.y =
      (Math.abs(door_dims[1][0] - door_dims[0][0]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel) / meshDepth;
    mesh.rotation.z = Math.PI / 2;
  } else if (comp_type == "acute") {
    mesh.scaling.x =
      (Math.abs(door_dims[2][0] - door_dims[0][0]) *store.unit_scale) / meshWidth;
    mesh.scaling.y =
      (Math.abs(door_dims[1][1] - door_dims[0][1]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel) / meshDepth;
    mesh.rotation.z = Math.atan(
      Math.abs(door_dims[2][1] - door_dims[2][1]) /
        Math.abs(door_dims[1][0] - door_dims[0][0])
    );
  } else if (comp_type == "obtuse") {
    mesh.scaling.y =
      (Math.abs(door_dims[2][0] - door_dims[0][0]) *store.unit_scale) / meshWidth;
    mesh.scaling.x =
      (Math.abs(door_dims[1][1] - door_dims[0][1]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel) / meshDepth;
    mesh.rotation.z =
      Math.atan(
        Math.abs(door_dims[2][1] - door_dims[2][1]) /
          Math.abs(door_dims[1][0] - door_dims[0][0])
      ) +
      Math.PI / 2;
  }

  mesh.position.x = center_point.x / 4;
  mesh.position.z = -center_point.y / 4;
  mesh.position.y = (120 *store.unit_scale - lintel) / 2;
}

function scaleWindow(mesh, door_dims, lintel, plinth, center_point) {
  var binfo = mesh.getBoundingInfo();
  var meshWidth = binfo.maximum.x - binfo.minimum.x;
  var meshHeight = binfo.maximum.y - binfo.minimum.y;
  var meshDepth = binfo.maximum.z - binfo.minimum.z;

  var dim0 = Math.abs(door_dims[2][0] - door_dims[0][0]);
  var dim1 = Math.abs(door_dims[2][1] - door_dims[0][1]);
  var comp_type = getCompType(door_dims);
  // console.log(comp_type);
  if (comp_type == "hor") {
    mesh.scaling.x =
      (Math.abs(door_dims[2][0] - door_dims[0][0]) *store.unit_scale) / meshWidth;
    mesh.scaling.y =
      (Math.abs(door_dims[1][1] - door_dims[0][1]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel - plinth) / meshDepth;
  } else if (comp_type == "ver") {
    mesh.scaling.x =
      (Math.abs(door_dims[2][1] - door_dims[0][1]) *store.unit_scale) / meshWidth;
    mesh.scaling.y =
      (Math.abs(door_dims[1][0] - door_dims[0][0]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel - plinth) / meshDepth;
    mesh.rotation.z = Math.PI / 2;
  } else if (comp_type == "acute") {
    mesh.scaling.x =
      (Math.abs(door_dims[2][0] - door_dims[0][0]) *store.unit_scale) / meshWidth;
    mesh.scaling.y =
      (Math.abs(door_dims[1][1] - door_dims[0][1]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel - plinth) / meshDepth;
    mesh.rotation.z = Math.atan(
      Math.abs(door_dims[2][1] - door_dims[2][1]) /
        Math.abs(door_dims[1][0] - door_dims[0][0])
    );
  } else if (comp_type == "obtuse") {
    mesh.scaling.y =
      (Math.abs(door_dims[2][0] - door_dims[0][0]) *store.unit_scale) / meshWidth;
    mesh.scaling.x =
      (Math.abs(door_dims[1][1] - door_dims[0][1]) *store.unit_scale) / meshHeight;
    mesh.scaling.z = (120 *store.unit_scale - lintel - plinth) / meshDepth;
    mesh.rotation.z =
      Math.atan(
        Math.abs(door_dims[2][1] - door_dims[2][1]) /
          Math.abs(door_dims[1][0] - door_dims[0][0])
      ) +
      Math.PI / 2;
  }

  mesh.position.x = center_point.x / 4;
  mesh.position.z = -center_point.y / 4;
  //mesh.position.y =  (120 *store.unit_scale) / 2;
}

function loadDoorInstance(
  door_name,
  center_point,
  door_dims,
  lintel,
  door_type,
  index,
  level = 0,
  structure = 0
) {
  //console.log(center_point)
  var door_mesh = store.scene.getMeshByName(door_name);
  var newInstance = door_mesh.clone(door_mesh.name + index);
  var bbinfo = newInstance.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  newInstance.setPivotPoint(centroid);
  newInstance.name = "door" + index;
  newInstance.type = "door";
  newInstance.visibility = 1;
  newInstance.level = level;
  newInstance.structure = structure;
  newInstance.offsetFlag = false;
  scaleDoor(newInstance, door_dims, lintel, center_point);
  // newInstance.position.y = ((floor_height-lintel) * (level+1/2));
  newInstance.position.y = ((2 * level + 1) *store.floor_height - lintel) / 2;
  //newInstance.position.y =store.floor_height * level
 store.click(newInstance);
  // console.log("loading Door");
  console.log(newInstance);
}

function loadWindowInstance(
  door_name,
  center_point,
  door_dims,
  lintel,
  plinth,
  door_type,
  index,
  level = 0,
  structure = 0
) {
  var door_mesh = store.scene.getMeshByName(door_name);
  var newInstance = door_mesh.clone(door_mesh.name + index);
  newInstance.name = "window" + index;
  newInstance.type = "window";
  newInstance.visibility = 1;
  newInstance.level = level;
  newInstance.offsetFlag = false;
  newInstance.structure = structure;
  scaleWindow(newInstance, door_dims, lintel, plinth, center_point);
  newInstance.position.y =store.floor_height * (level + 1 / 2);
  //newInstance.position.y =store.floor_height * level
 store.click(newInstance);
  // console.log("loading Door");
}

function loadDoorComponent(comp_path, comp_path_dir, scale_factor) {
  var meshLoadedFlag = false;
  var newMesh;
  BABYLON.SceneLoader.ImportMesh(
    "",
    "",
    comp_path,
    store.scene,
    function (mesh) {
      meshLoadedFlag = true;
      newMesh = mesh[0];
      newMesh.name = "door1";
      newMesh.visibility = 0;
      console.log(newMesh.material);
      newMesh.material.id = newMesh.name + "MultiMaterial";
      newMesh.material.name = newMesh.name + "MultiMaterial";
      newMesh.material.backFaceCulling = false;
      newMesh.showBoundingBox = false;
      if (newMesh.material.subMaterials) {
        for (var i = 0; i < newMesh.material.subMaterials.length; i++) {
          if (newMesh.material.subMaterials[i].diffuseTexture) {
            var text_url =
              comp_path_dir +
              newMesh.material.subMaterials[i].diffuseTexture.url;
            text_url.replace(/\-/g, "");
            console.log(text_url, newMesh.name);
            newMesh.material.subMaterials[i].backFaceCulling = false;
            //  var text = new BABYLON.Texture(comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url, store.scene);
            newMesh.material.subMaterials[i].diffuseTexture =
              new BABYLON.Texture(
                comp_path_dir +
                  newMesh.material.subMaterials[i].diffuseTexture.url,
                store.scene
              );
          }
        }
      }
      newMesh.scaling = new BABYLON.Vector3(0.0001, 0.0001, 0.0001);
      newMesh.position = new BABYLON.Vector3(10000, 10000, 10000);
    },
    function (evt) {}
  );
  return newMesh;
}

function loadWindowComponent(comp_path, comp_path_dir, scale_factor) {
  var meshLoadedFlag = false;
  var newMesh;
  BABYLON.SceneLoader.ImportMesh(
    "",
    "",
    comp_path,
    store.scene,
    function (mesh) {
      meshLoadedFlag = true;
      newMesh = mesh[0];
      newMesh.name = "window1";
      newMesh.visibility = 0;
      newMesh.material.id = newMesh.name + "MultiMaterial";
      newMesh.material.name = newMesh.name + "MultiMaterial";
      newMesh.material.backFaceCulling = false;
      if (newMesh.material.subMaterials) {
        for (var i = 0; i < newMesh.material.subMaterials.length; i++) {
          if (newMesh.material.subMaterials[i].diffuseTexture) {
            var text_url =
              comp_path_dir +
              newMesh.material.subMaterials[i].diffuseTexture.url;
            text_url.replace(/\-/g, "");
            console.log(text_url, newMesh.name);
            newMesh.material.subMaterials[i].backFaceCulling = false;
            //  var text = new BABYLON.Texture(comp_path_dir + dMesh.material.subMaterials[i].diffuseTexture.url, store.scene);
            newMesh.material.subMaterials[i].diffuseTexture =
              new BABYLON.Texture(
                comp_path_dir +
                  newMesh.material.subMaterials[i].diffuseTexture.url,
                store.scene
              );
          }
        }
      }
      newMesh.scaling = new BABYLON.Vector3(0.0001, 0.0001, 0.0001);
      newMesh.position = new BABYLON.Vector3(10000, 10000, 10000);
    },
    function (evt) {}
  );
  return newMesh;
}

function offsetStructures() {
  var bbinfo = computeStructureBounds(0);
  var structure_offset = bbinfo.diagonalLength;
  //console.log("inside new func", structure_offset);
  if (structure_offset > 1000) structure_offset = 100;
  //console.log("after new func", structure_offset);
  for (var i = 0; i < store.scene.meshes.length; i++) {
    var mesh = store.scene.meshes[i];
    if (mesh.parentId) {
      // console.log(store.scene.getMeshByID(mesh.parentId));
      mesh.parent = store.scene.getMeshByID(mesh.parentId);
    }
    if (mesh.structure && !mesh.offsetFlag) {
      // if (mesh.structure % 2 !== 0){
      mesh.position.x += mesh.structure * structure_offset;
      // mesh.position.z -= parseInt(mesh.structure/2)*structure_offset;
      // }
      mesh.offsetFlag = true;
    }
  }
}
export {
  createWallBlocks,
  createDoors,
  createWindows,
  getCompType,
  createDoorBlocks,
  createWindowLintel,
  createWindowPlinth,
  calculateLevelsAndIndex,
  createFloor,
  loadAllTexture,
  loadDoorTexture,
  loadWindowTexture,
  scaleMeshProportionate,
  scaleDoor,
  scaleWindow,
  loadDoorInstance,
  loadWindowInstance,
  loadDoorComponent,
  loadWindowComponent,
  offsetStructures,
};
