
import React from 'react';
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { terrainGeneration } from '../geo/terrainMap'
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import API from '../../../services/covetool.service'
import BUILDING_TYPES from './BUILDING_TYPES.json'
import FAR_AWAY_WALL from './wall.json'
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { Action } from "../../stateManagers/reducers/objectProperties/coveAnalysisSlice.js";
import { setIsCoveToolProject } from "../../stateManagers/reducers/objectProperties/projectMetadataSlice";
import { _sliceName } from "../../stateManagers/reducers/objectProperties/coveAnalysisSlice";
import { saveAsSaveMicro } from '../../../services/saveMicro.service'
import { showToast, TOAST_TYPES } from "../extrafunc.js";
import { handleRecordStatus, handleUncompletedRecord, pollStartCallback, pollCompleteCallback, getLocationInfo, getEnergyCodeFromMapping, getDefaultBuildingTypes, fetchLatestAnalysis, Polling } from './utils'
import { debounce } from "lodash";
import { goOutOfTwoD } from "../../libs/twoDimension.js";
import { updateProjectImage, updateProjectImageOne } from "../../../services/projects.service.js";
import { createCustomMeshAccordingToNormal } from "../../libs/massModeling.js";
import { hideAllRoofs } from "../../libs/roofVisibilityFuncs.js";
import { typicalNotifier } from "../../libs/apiCalls/backendCalls.js";
import { AutoSave } from '../socket/autoSave.js';
import { DisplayOperation } from '../displayOperations/displayOperation.js';

/**
 * Helpers functions related to CoveTool 
 * 
 * Note: - private functions are prefixed with _
 *       - for private functions snake case is used for better readability
 */
const coveToolHelpers = (function() {
    let _polyCount = 0
    let _trigger = false
    let _checkAutosave = true
    let tryAgain = null, maxTry = 20, tryInterval = 4000
    let _useLastSerachedLocation = false
    let _rotatedMeshStack = [], _mainMesh = null

    const toggleUseOflastSearchedLocation = (val = false) => _useLastSerachedLocation = val

    const _addPolyCount = (triangles_count) => _polyCount += triangles_count

    const getState = () => reduxStore.getState()[_sliceName]

    const _getElements = () => ({
        // Geometry
        floors: [],
        outdoor_floors: [],
        roofs: [],
        walls: [],
        interior_walls: [],
        below_grade_walls: [],
        windows: [],
        shading_devices: [],
        skylights: [],
        // Measurements
        roof_area: 0,
        floor_area: 0,
        ground_floor_area: 0,
        building_height: 0,
        basement_depth: 0,
        // Measurements of walls
        wall_area_n: 1,
        wall_area_ne: 0,
        wall_area_e: 0,
        wall_area_se: 0,
        wall_area_s: 0,
        wall_area_sw: 0,
        wall_area_w: 0,
        wall_area_nw: 0,
        // Measurements of windows
        window_area_n: 1,
        window_area_ne: 0,
        window_area_e: 0,
        window_area_se: 0,
        window_area_s: 0,
        window_area_sw: 0,
        window_area_w: 0,
        window_area_nw: 0,
        // Heat transfer value for material (unit of U-value in IP system is BTU/hr.ft2.°F and in SI system is Watts/m2.K)
        // wall_u_value_n: 0,
        // window_u_value_n: 0,
        // roof_u_value: 0,
        // ground_floor_u_value: 0,
        // skylight_u_value: 0,
        // extra
        rooms: {}
    })

    /**
     * Math.trunc but shorter syntax
     * @param {float} x 
     * @returns 
     */
    const _trunc = x => Math.trunc(x);

    /**
     * Checks if x is around to n based on threshold
     * @param {number} x - value to check
     * @param {number} n - value to check against
     * @param {number} threshold - default set to 5
     * @returns 
     */
    const _around = (x, n, threshold = 5) => n - threshold <= x && x <= n + threshold;

    const _getAreaInFt = (mesh) => {
        const dimen = objectPropertiesView.getMeshDimensions(mesh, { valueInSnapDim: true })
        const lenght = parseFloat(DisplayOperation.convertDimensionTo(dimen.depth).cm) / 30.48 // divide by 30.48 to convert from cm to ft
        const breadth = parseFloat(DisplayOperation.convertDimensionTo(dimen.breadth).cm) / 30.48
        
        return lenght * breadth
    }

    /**
     * Give height of the building starting from ground.
     * Building height: Overall height from the lowest point of above ground conditioned space to the highest point.
     * Regardless of the projects varying height, and/or multiple project roofs, the building height will be the total height of the project's thermal envelope.
     * @param {{ _floors: [],
     *           _roofs: [],
     *           _walls: [],
     *           _doors: [],
     *           _windows: [],
     *           _staircases: [],
     *           _masses: []
     *        }} levelData}
     * @param {{ mesh: {} }} groundFloor 
     * @returns {{
     *      inch: string,
     *      mts: string,
     *      cm: string,
     *      feet: string,
     *      mMts: string,
     *      ft: Number
     *  }}
     */
    const _getBuildingHeight = (levelData, groundFloor) => {
        let top = groundFloor.mesh.position.y, bottom = top;

        const { _roofs } = levelData

        _roofs.forEach(r => {
            const y = r.mesh.position.y
            if(y > top) top = y
        })

        const m = DisplayOperation.convertDimensionTo(top - bottom)
        m['ft'] = parseFloat(m.cm) / 30.48

        return m
    }

    /**
     * Gives depth of the basement
     * Basement Depth: starting from ground floor to lowest floor in the building
     * @param {{ _floors: [],
     *           _roofs: [],
     *           _walls: [],
     *           _doors: [],
     *           _windows: [],
     *           _staircases: [],
     *           _masses: []
     *        }} levelData}
     * @param {{ mesh: {} }} groundFloor 
     * @returns {{
     *      inch: string,
     *      mts: string,
     *      cm: string,
     *      feet: string,
     *      mMts: string,
     *      ft: Number
     *  }}
     */
    const _getBasementDepth = (levelData, groundFloor) => {
        let top = groundFloor.mesh.position.y, bottom = top;

        const { _floors } = levelData

        _floors.forEach(f => {
            const y = f.mesh.position.y
            if(y < bottom) bottom = y
        })

        const m = DisplayOperation.convertDimensionTo((bottom - top) * -1)
        m['ft'] = parseFloat(m.cm) / 30.48

        return m
    }

    const _stackMeshes = (meshes, mainMesh = null) => {
        if (meshes.length > 1) {
            let firstMesh = mainMesh || meshes[0];
            firstMesh.stack = true;

            if (firstMesh.parent) {
                firstMesh._prevParent = firstMesh.parent.uniqueId;
                firstMesh.setParent(null);
            }

            for (let i = 1; i < meshes.length; i++) {
                let mesh = meshes[i];
                mesh.stack = true;
                if (mesh.parent) {
                    mesh._prevParent = mesh.parent.uniqueId;
                    mesh.setParent(null);
                }
                mesh.setParent(firstMesh);
            }

            return firstMesh
        }
    }

    const _resetStackMeshes = (meshes) => {
        if (meshes.length > 1) {
            meshes.forEach((mesh, index) => {
                mesh.stack = false;
                if (index !== 0) {
                    if (mesh._prevParent) {
                        mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
                    } else {
                        mesh.setParent(null);
                    }
                }
            });
        }
    }

    const _allowList = {
        "wall": true,
        "floor": true,
        "roof": true,
        "door": true,
        "window": true,
        "staircase": true,
        'deck': true,
        'lawn': true,
        'courtyard': true,
        'beam': true,
        'column': true,
        'pergola': true,
        'sunshade': true
    }

    const rotateMeshes = (angle, validObjectNames = _allowList) => {
        let meshes = store.scene.meshes

        if(Array.isArray(meshes)) {
            meshes = meshes.filter(m =>  {
                if(validObjectNames) {
                    return String(m?.name).toLowerCase() in validObjectNames
                }
                
                return true
            })

            const parentMesh = _stackMeshes(meshes)
            
            if(!parentMesh.rotationQuaternion) {
                parentMesh.rotationQuaternion = new BABYLON.Quaternion.RotationAlphaBetaGamma(0, 0, 0)
            }

            parentMesh._prevRotation = parentMesh.rotationQuaternion.clone()
            parentMesh.rotate(BABYLON.Axis.Y, angle, BABYLON.Space.BONE);

            _rotatedMeshStack = meshes
            _mainMesh = parentMesh
        }
    }

    const resetMeshesRotation = () => {
        if(_mainMesh && _mainMesh._prevRotation) {
            _mainMesh.rotationQuaternion = new BABYLON.Quaternion(
              _mainMesh._prevRotation.x,
              _mainMesh._prevRotation.y,
              _mainMesh._prevRotation.z,
              _mainMesh._prevRotation.w
            );
            _mainMesh._prevRotation = null;

            if (_rotatedMeshStack.length > 1) {
                _resetStackMeshes(_rotatedMeshStack)
            }
        }
    }

    /**
     * Returns true if element is out of scene
     * @param {Object} element 
     * @returns 
     */
    function isOutOfScene(element) {
        const globalPosition = element?.mesh?.position
        const absolutePosition = element?.mesh?.getAbsolutePosition();

        if(
            _around(Math.abs(globalPosition.x), 10000, 10) && _around(Math.abs(globalPosition.z), 10000, 10) || // outside of the scene
            _around(Math.abs(absolutePosition.x), 10000, 10) && _around(Math.abs(absolutePosition.z), 10000, 10) // outside of the scene
        ) {
            return true
        }

        return false
    }
    
    const _colorMesh = (mesh, color = [1.0, 0.2, 0.7]) => {
        if(!mesh) return
        var material = new BABYLON.StandardMaterial(store.scene);
        material.alpha = 1;
        material.diffuseColor = new BABYLON.Color3(...color);
        mesh.material = material;
    }


    const _getBoxUpperLowerPoint = (mesh) => {
        try {
            if(!mesh) return

            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            
            const transformationMatrix = mesh.computeWorldMatrix(true)
            
            let y1 = null, y2 = null

            for(let i = 0; i < positions.length; i += 3) {
                const coords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                if(!y1) y1 = coords.y
                if(!y2 && coords.y != y1) y2 = coords.y

                if(y1 && y2) break
            }
            
            return { upperPoint: y1, lowerPoint: y2 }
        } catch(err) {
            console.log(err)
        }
    }

    /**
     * Provides box's base vertices in local coordinates
     * @param {BABYLON.Mesh} mesh 
     * @returns 
     */
    const _getBoxBaseVertices = (mesh) => {
        try {
            if(!mesh) return

            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            
            let y = null
            const uniq = {}

            for(let i = 0; i < positions.length; i += 3) {
                if(y == null) y = positions[i + 1]

                if(y == positions[i + 1]) {
                    uniq[`${positions[i]},${positions[i + 1]},${positions[i + 2]}`] = [positions[i], positions[i + 1], positions[i + 2]]
                }
            }

            const vertices = []
            Object.values(uniq).forEach(arr => vertices.push(...arr))

            return vertices
        } catch(err) {
            console.log(err)
        }
    }

    /**
     * Converts local coords array to global coords array
     * @param {Array} positions 
     * @param {BABYLON.Mesh} mesh 
     * @returns 
     */
    const _localToGlobalCoordsArray = (positions, mesh) => {
        const transformationMatrix = mesh.computeWorldMatrix(true)
        const global = []

        // fill mesh vertices
        for(let i = 0; i < positions.length; i += 3) {
            const coords = BABYLON.Vector3.TransformCoordinates(
                new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                transformationMatrix
            )

            global.push(coords.x, coords.y, coords.z)
        }

        return global
    }

    /**
     * Returns two planes path
     * @param {number[]} basepath 
     * @param {number} pointOne 
     * @param {number} pointTwo 
     * @returns 
     */
    const _getTopBottomPathVector = (basepath, pointOne, pointTwo) => {
        if(!Array.isArray(basepath)) return

        const path_top_vec = [], path_bottom_vec = []

        for(let i = 0; i < basepath.length; i+= 3) {
            path_top_vec.push(new BABYLON.Vector3(basepath[i], pointOne, basepath[i + 2]))
            path_bottom_vec.push(new BABYLON.Vector3(basepath[i], pointTwo, basepath[i + 2]))
        }

        return {
            path_top_vec,
            path_bottom_vec,
            normal: [0, -1, 0]
        }
    }

    /**
     * Returns a new roof after subtracting floors
     * @param {BABYLON.Mesh} roof 
     * @param {BABYLON.Mesh[]} floors 
     */
    const subtractFloorsFromRoof = (roof, floors, ref, baseStorey, debug = false) => {
        const voidMeshes = []

        try {
            if(!roof || !Array.isArray(floors)) return

            const roofVerticalPoints = _getBoxUpperLowerPoint(roof.mesh)

            const floorsCSG = []

            floors.forEach(floor => {
                // subtract floor area from roof_area
                if(floor.mesh.storey > baseStorey) {
                    const floorArea = _getAreaInFt(floor.mesh)
                    ref['roof_area'] -= floorArea
                }

                // Get path of floor base
                const baseVertices = _getBoxBaseVertices(floor.mesh)
                // convert to global coordinates
                const globalVertices = _localToGlobalCoordsArray(baseVertices, floor.mesh)
                // create two path with floor base and roof top and bottom points
                const pathObject = _getTopBottomPathVector(globalVertices, roofVerticalPoints.upperPoint, roofVerticalPoints.lowerPoint)
                // create mesh
                const voidMesh = createCustomMeshAccordingToNormal(pathObject)
                voidMeshes.push(voidMesh)
                // create CSG mesh
                const voidMeshCSG = BABYLON.CSG.FromMesh(voidMesh)
                // store in array
                floorsCSG.push(voidMeshCSG)
            })

            /* Handle case -> have source mesh */
            let meshToUse = roof.mesh
            let origPos = null
            
            // check if original mesh exist
            if(roof.mesh?.sourceMesh) {
                // lets use original mesh
                meshToUse = roof.mesh?.sourceMesh
                // save original mesh position for later
                origPos = meshToUse.position
                // temporarily move original mesh to current roof position
                meshToUse.position = roof.mesh.position
            }

            // create roof's CSG mesh
            let newRoof = BABYLON.CSG.FromMesh(meshToUse)

            floorsCSG.forEach(floorCSG => {
                newRoof = newRoof.subtract(floorCSG)
            })

            // restore original mesh back to its place
            if(origPos) meshToUse.position = origPos

            // dispose meshes
            if(debug == false) voidMeshes.forEach(mesh => mesh?.dispose())

            return newRoof?.toMesh(roof?.name + "-holed", null, store.scene)
        } catch(err) {
            console.log(err)
            voidMeshes.forEach(mesh => mesh?.dispose())
        }
    }

    function parseGenericObjects(objects, ref, name) {
        if(!Array.isArray(objects) || !ref) return

        objects.forEach(object => {
            if(!object.storey) return

            const parsed = {
                "Mesh":   { "Vertices": [], "Triangles": [] },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': 0, 'Z': 0 }
            }

            const mesh = object.mesh

            /* set mesh's Vertices and Triangles */
            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            const indices = mesh.getIndices()
            
            // length must be mulitple of 3
            if(positions.length % 3 !== 0 || indices.length % 3 !== 0) return
            
            // starting from 0, every three items of array,
            // represents vertex's X, Y, Z
            
            const transformationMatrix = mesh.computeWorldMatrix(true)

            // fill mesh vertices
            for(let i = 0; i < positions.length; i += 3) {
                const globalCoords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                parsed.Mesh.Vertices.push({
                    'X': globalCoords.x,
                    'Y': globalCoords.z,
                    'Z': globalCoords.y
                })
            }

            // fill mesh triangles
            for(let i = 0; i < indices.length; i += 3) {
                parsed.Mesh.Triangles.push([
                    indices[i],
                    indices[i + 2],
                    indices[i + 1]
                ])
            }

            if(ref[name]) {
                _addPolyCount(parsed.Mesh.Triangles.length)
                ref[name].push(parsed)
            }
        })
    }

    function parseFloors(floors, other, ref) {
        if(!Array.isArray(floors) || !ref) return

        floors.forEach(floor => {
            if(!floor.storey) return

            const parsed = {
                "Mesh":   { "Vertices": [], "Triangles": [] },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': -1, 'Z': 0 }
            }

            const mesh = floor.mesh

            /* set mesh's Vertices and Triangles */
            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            const indices = mesh.getIndices()
            
            // length must be mulitple of 3
            if(positions.length % 3 !== 0 || indices.length % 3 !== 0) return
            
            // starting from 0, every three items of array,
            // represents vertex's X, Y, Z
            
            const transformationMatrix = mesh.computeWorldMatrix(true)

            // fill mesh vertices
            for(let i = 0; i < positions.length; i += 3) {
                const globalCoords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                parsed.Mesh.Vertices.push({
                    'X': globalCoords.x,
                    'Y': globalCoords.z,
                    'Z': globalCoords.y
                })
            }

            // fill mesh triangles
            for(let i = 0; i < indices.length; i += 3) {
                parsed.Mesh.Triangles.push([
                    indices[i],
                    indices[i + 2],
                    indices[i + 1]
                ])
            }
            
            _addPolyCount(parsed.Mesh.Triangles.length)
            
            // Send all floor as inside floors to get analysis
            if(ref["floors"]) {
                ref["floors"].push(parsed)
                ref['floor_area'] += _getAreaInFt(floor.mesh)
            }
            // if(isFloorInside(floor, other)) {
            //     if(ref["floors"]) ref["floors"].push(parsed)
            // } else {
            //     if(ref["outdoor_floors"]) ref["outdoor_floors"].push(parsed)
            // }
        })
    }

    function parseRoofs(roofs, other, ref, baseStorey, debug = false) {
        if(!Array.isArray(roofs) || !ref) return

        // store floors by storey
        const _floorsByStorey = {}

        if(Array.isArray(other['_floors'])) {
            other['_floors'].forEach(floor => {
                if(floor.storey == undefined) return

                if(!_floorsByStorey[floor.mesh.storey]) _floorsByStorey[floor.mesh.storey] = []

                _floorsByStorey[floor.mesh.storey].push(floor)
            })
        }

        roofs.forEach(roof => {
            if(roof.mesh.storey == undefined) return

            const parsed = {
                "Mesh":   { "Vertices": [], "Triangles": [] },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': -1, 'Z': 0 }
            }
            
            let floors = _floorsByStorey[roof.mesh.storey]
            let mesh = roof.mesh
            let roofWithHoles = null

            if(roof.mesh.storey > baseStorey) ref['roof_area'] += _getAreaInFt(roof.mesh)

           if(Array.isArray(floors)) {
                roofWithHoles = subtractFloorsFromRoof(roof, floors, ref, baseStorey, debug)
                mesh = roofWithHoles || roof.mesh
                if(debug) {
                    _colorMesh(mesh)
                    mesh.position = roof.mesh.position
                }
            }

            /* set mesh's Vertices and Triangles */
            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            const indices = mesh.getIndices()
            
            // length must be mulitple of 3
            if(positions.length % 3 !== 0 || indices.length % 3 !== 0) return
            
            // starting from 0, every three items of array,
            // represents vertex's X, Y, Z
            
            const transformationMatrix = mesh.computeWorldMatrix(true)

            // fill mesh vertices
            for(let i = 0; i < positions.length; i += 3) {
                const globalCoords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                parsed.Mesh.Vertices.push({
                    'X': globalCoords.x,
                    'Y': globalCoords.z,
                    'Z': globalCoords.y
                })
            }

            // fill mesh triangles
            for(let i = 0; i < indices.length; i += 3) {
                parsed.Mesh.Triangles.push([
                    indices[i],
                    indices[i + 2],
                    indices[i + 1]
                ])
            }

            if(hasTransparentMaterial(mesh)) {
                _addPolyCount(parsed.Mesh.Triangles.length)
                ref['skylights'].push(parsed)
                return
            }

            if(ref["roofs"]) {
                _addPolyCount(parsed.Mesh.Triangles.length)
                ref["roofs"].push(parsed)
            }

            // destroy roofWithHoles
            if(!debug && roofWithHoles && roofWithHoles.dispose) roofWithHoles.dispose()
        })
    }

    /**
     * Parses Walls 
     * @param {Array} walls
     * @param {Object} other 
     * @param {Object} ref 
     */
    function parseWalls(walls, other, ref) {
        if(!Array.isArray(walls) || !other || !ref) return

        // const intersectingWallCount = _getWallIntersectCount(walls)

        walls.forEach((wall) => {
            if(!wall.storey) return

            const mesh = wall.mesh
            
            const parsed = {
                "Mesh":   { "Vertices": [], "Triangles": [] },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': 0, 'Z': 0 }
            }

            /* set mesh's Vertices and Triangles */
            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            const indices = mesh.getIndices()
            
            // length must be mulitple of 3
            if(positions.length % 3 !== 0 || indices.length % 3 !== 0) return
            
            // starting from 0, every three items of array,
            // represents vertex's X, Y, Z
            
            const transformationMatrix = mesh.computeWorldMatrix(true)

            // fill mesh vertices
            for(let i = 0; i < positions.length; i += 3) {
                const globalCoords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                parsed.Mesh.Vertices.push({
                    'X': globalCoords.x,
                    'Y': globalCoords.z,
                    'Z': globalCoords.y
                })
            }

            // fill mesh triangles
            for(let i = 0; i < indices.length; i += 3) {
                parsed.Mesh.Triangles.push([
                    indices[i],
                    indices[i + 2],
                    indices[i + 1]
                ])
            }

            _addPolyCount(parsed.Mesh.Triangles.length)

            if(wall.properties.wallMaterialType == "GLASS" ||
               wall.mesh?.material?.materialType == "Glass" ||
               wall.mesh?._effectiveMaterial?.materialType == "Glass"
            ) {
                ref["windows"].push(parsed)
                return
            }

            if(wall.mesh.storey < 0) {
                ref["below_grade_walls"].push(parsed)
                return
            }

            // if wall is interior wall then add it as interior walls
            const sameStoreyFloors = other["_floors"].filter(floor => floor.mesh.storey == wall.mesh.storey)
            
            if(isInteriorWall(wall, sameStoreyFloors, other)) {
                ref["interior_walls"].push(parsed)
                return
            }

            // else add it as walls
            ref["walls"].push(parsed)
        })
    }

    const _matException = {
        "wall_mat": true
    }

    /**
     * Returns true if a mesh or its subMeshes has transparent/translucent material
     * @param {Object} mesh 
     * @returns 
     */
    function hasTransparentMaterial(mesh) {
        if(!mesh) return false

        const limit = 0.8

        const material = mesh.material,
              material1 = mesh?._effectiveMaterial

        if(Number.isFinite(material?.alpha) && material?.alpha < limit) return true
        if(!(material1?.id in _matException)) {
            if(Number.isFinite(material1?.alpha) && material1?.alpha < limit) return true
        }

        if(Array.isArray(mesh?.subMeshes)) {
            for(let i = 0; i < mesh.subMeshes.length; i++) {
                const subMesh = mesh.subMeshes[i]
                const material = subMesh.getMaterial()

                if(material?.name in _matException) continue

                if(Number.isFinite(material?.alpha) && material?.alpha < limit) return true
            }
        }

        return false;
    }

    function highlightVertex(data) {
        // console.log("highlighting", data)

        if(Array.isArray(data)) {
            data.forEach((p) => {
                const box = BABYLON.MeshBuilder.CreateBox("box", { size: 0.1 }, store.scene);
                box.position = p
                box.isPickable = false
                _colorMesh(box)
            })
        } else {
            const box = BABYLON.MeshBuilder.CreateBox("box", { size: 0.1 }, store.scene);
            box.position = data
            box.isPickable = false
            _colorMesh(box)
        }
    }

    function _createCustomMesh(positions, indices) {
        let customMesh = new BABYLON.Mesh("customMesh", store.scene);
        
        var vertexData = new BABYLON.VertexData();
        vertexData.positions = positions;
        vertexData.indices = indices;

        vertexData.applyToMesh(customMesh)

        return customMesh
    }

    /**
     * @param {[]} vectorsWorld 
     */
    function _getSideDominantFace(vectorsWorld) {
        let y      = null,
            low_y = Number.MAX_SAFE_INTEGER,
            high_y = Number.MIN_SAFE_INTEGER,
            low_x  = Number.MAX_SAFE_INTEGER,
            high_x = Number.MIN_SAFE_INTEGER,
            low_z  = Number.MAX_SAFE_INTEGER,
            high_z = Number.MIN_SAFE_INTEGER

        for(let i = 0; i < vectorsWorld.length; i++) {
            const vtx = vectorsWorld[i]

            if(vtx.y < low_y) low_y = vtx.y
            if(vtx.y > high_y) high_y = vtx.y

            if(y == null) y = vtx.y

            if(y !== vtx.y) continue

            if(vtx.x < low_x) low_x = vtx.x
            if(vtx.x > high_x) high_x = vtx.x

            if(vtx.z < low_z) low_z = vtx.z
            if(vtx.z > high_z) high_z = vtx.z
        }

        const dist_x = high_x - low_x,
              dist_z = high_z - low_z

        if(dist_x > dist_z) {
            return {
                axis: 'x',
                value_low: low_x,
                value_high: high_x,
                low_y,
                high_y
            }
        }

        return {
            axis: 'z',
            value_low: low_z,
            value_high: low_z,
            low_y,
            high_y
        }
    }

    /**
     * Convert a mesh (check below) to single surface mesh
     * Supported meshes are door/window
     * @param {BABYLON.Mesh} mesh 
     */
    function meshToPlaneVertexData(mesh, debug = false) {
        try {
            const _wm = mesh?.computeWorldMatrix(true) // DO NOT REMOVE THIS LINE
            const boundsInfo = mesh?.getBoundingInfo()
            const boundingBox = boundsInfo?.boundingBox
            const centerWorld = boundingBox?.centerWorld
            const vectorsWorld = boundingBox?.vectorsWorld


            if(!Array.isArray(vectorsWorld)) return

            const dominantFace = _getSideDominantFace(vectorsWorld)
            const oppositeAxis = dominantFace.axis == 'x' ? 'z' : 'x'
            let oppositeAxisValue = null

            // vertices will be stored in below order
            const vertices = []

            // order in array -> vtx0, vtx1, vtx2, vtx3
            //    vtx0      vtx1
            //        o     o
            //
            //        o     o
            //    vtx3      vtx2

            vectorsWorld.forEach((vtx) => {
                if(oppositeAxisValue == null) oppositeAxisValue = vtx[oppositeAxis]

                if(_around(vtx[oppositeAxis], oppositeAxisValue, 0.1)) {
                    const newVtx = new BABYLON.Vector3(vtx.x, vtx.y, vtx.z)
                    newVtx[oppositeAxis] = centerWorld[oppositeAxis]

                    // store in index
                    let idx = 0

                    if(newVtx.y > dominantFace.low_y) { // put in upper index
                        if(newVtx[dominantFace.axis] > dominantFace.value_low) idx = 1
                    } else { // put in lower index
                        // if greater -> left side (3) else right side (2)
                        if(newVtx[dominantFace.axis] > dominantFace.value_low) idx = 2
                        else idx = 3
                    }
                    
                    vertices[idx] = newVtx
                }
            })

            
            if(debug)  // for debug purposes only
            {
                highlightVertex(centerWorld);
                highlightVertex(vertices);
            }

            const positions = [], indices = [0, 2, 1, 2, 0, 1, 0, 2, 3, 2, 0, 3]

            vertices.forEach(vtx => positions.push(vtx.x, vtx.y, vtx.z))

            return {
                positions,
                indices 
            }
        } catch(err) {
            console.log("Failed to convert to single surface mesh", err)
        }
    }

    /**
     * Parses Doors 
     * @param {Array} doors 
     * @param {Object} other 
     * @param {Object} ref 
     */
    function parseDoors(doors, other, ref) {
        if(!Array.isArray(doors) || !other || !ref) return

        doors.forEach((door) => {
            if(!door.storey) return

            const mesh = door.mesh

            const planeVertexData = meshToPlaneVertexData(mesh)

            if(!planeVertexData) return

            const parsed = {
                "Mesh":   { "Vertices": planeVertexData.positions, "Triangles": planeVertexData.indices },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': 0, 'Z': 0 }
            }

            
            _addPolyCount(parsed.Mesh.Triangles.length)

            if(hasTransparentMaterial(mesh)) {
                ref["windows"].push(parsed)
            } else {
                ref["walls"].push(parsed)
            }
        })
    }

    /**
     * Parses Windows 
     * @param {Array} windows 
     * @param {Object} other 
     * @param {Object} ref 
     */
    function parseWindows(windows, other, ref) {
        if(!Array.isArray(windows) || !ref) return

        windows.forEach(window => {
            if(!window.storey) return

            const mesh = window.mesh

            const planeVertexData = meshToPlaneVertexData(mesh)

            if(!planeVertexData) return

            const parsed = {
                "Mesh":   { "Vertices": planeVertexData.positions, "Triangles": planeVertexData.indices },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': 0, 'Z': 0 }
            }

            _addPolyCount(parsed.Mesh.Triangles.length)

            ref["windows"].push(parsed)
        })
    }

    /**
     * Parses Staircases 
     * @param {Array} staircases 
     * @param {Object} other 
     * @param {Object} ref 
     */
    function parseStaircases(staircases, other, ref) {
        parseGenericObjects(staircases, ref, "shading_devices")
    }

    const OPEN_ROOM_LABELS = [
        ["balcony"],
        ["terrace"],
        ["verandah"],
        ["corridor", "open"],
        ["deck"]
    ]

    function _isOpenRoomByLabel(label) {
        if(!label) return;

        let open = false;

        // if room label contains any of OPEN_ROOM_LABELS, then it is floor outside the building
        for(const labelsArr of OPEN_ROOM_LABELS) {
            let includes = true;

            // must contain all subtrings in label
            for(const substring of labelsArr) {
                if(label.includes(substring)) continue;

                includes = false;
                break;
            }

            if(includes) {
                open = true;
                break;
            }
        }

        return open
    }

    /**
     * Returns true if Floor is inside the building
     * Logic -> Cast ray from floor mid point to up
     * if it hits roof then return true
     * @param {BABYLON.Mesh} floor 
     * @param {Object} other 
     */
    function isFloorInside(floor, other) {
        // check is open room using label
        const label = String(floor?.room_type).toLowerCase()
        if(label && _isOpenRoomByLabel(label)) return false

        // get all roofs
        let roofs = other["_roofs"]
        roofs = roofs.filter(roof => (floor.mesh.storey + 1) == roof.mesh.storey)

        // show hidden roof for now
        const hiddenRoofs = []

        roofs.forEach(roof => {
            if(roof.mesh.isVisible == false) {
                hiddenRoofs.push(roof)
                roof.mesh.isVisible = true
            }
        })

        // our predicate function, which will tell if mesh is pickable or not
        const isPickable = (mesh) => {
            const type = mesh?.type?.toLowerCase()

            if(type != "roof") return false

            return true
        }

        // cast ray to up
        const pickedMesh = castRay(floor.mesh, new BABYLON.Vector3(0, 1, 0), isPickable)

        // hide roofs again
        hiddenRoofs.forEach(roof => roof.mesh.isVisible = false)

        // if picked mesh is roof then return true (as it is inside floor)
        if(pickedMesh?.name?.toLowerCase() == 'roof') return true
        
        return false
    }

    /**
     * Checks if column mass is inside the building
     * @param {BABYLON.Mesh} mass 
     * @param {Object} other 
     * @returns 
     */
    function _isColumnInside(mass, other) {
        let floors = other["_floors"]

        if(!Array.isArray(floors) || !mass.mesh?.storey) return false

        const storey = mass.mesh.storey;
        floors = floors.filter(floor => floor.mesh.storey == storey && isFloorInside(floor, other))

        for(const floor of floors) {
            if(mass.intersectsMesh(floor?.mesh)) {
                return true
            }
        }

        return false
    }

    /**
     * Checks if beam is inside the building
     * @param {BABYLON.Mesh} mass 
     * @param {Object} other 
     * @returns 
     */
    function _isBeamInside(mass, other) {
        // get all floors on same storey or one above, as beam maybe on another storey
        let _floors = other["_floors"]
        _floors = _floors.filter(floor => (floor.mesh.storey == mass.mesh.storey) || (floor.mesh.storey + 1 == mass.mesh.storey))

        // our predicate function, which will tell if mesh is pickable or not
        const isPickable = (mesh) => {
            const type = mesh?.type?.toLowerCase()

            if(type != "floor") return false

            return true
        }

        // cast ray to down
        const pickedMesh = castRay(mass, new BABYLON.Vector3(0, -1, 0), isPickable)

        // check floor room_type -> based on some open room labels, we can determine as it is outside
        if(pickedMesh) {
            const label = String(pickedMesh?.room_type).toLowerCase()
            if(_isOpenRoomByLabel(label)) return false;
            else return true;
        }
        
        // did not found any floor beneath, means it is outside the building
        return true
    }

    /**
     * Returns true if mass is considered as floor based on label
     * @param {BABYLON.Mesh} mass 
     * @returns 
     */
    function _isMassFloor(mass) {
        const _floorLabels = {
            'deck': true,
            'lawn': true,
            'courtyard': true
        }

        if(String(mass?.room_type).toLowerCase() in _floorLabels) return true

        return false
    }

    /**
     * Returns true if mass is a shading device
     * @param {string} massType 
     * @returns 
     */
    function isShadingDevice(mass, other) {
        const massType = mass.massType

        const types = {
            'Beam': true,
            'Column': true,
            'Pergola': true,
            'Sunshade': true
        }

        if(massType in types) {
            switch(massType) {
                case "Column":
                    if(_isColumnInside(mass.mesh, other)) return false
                    return true
                case "Beam":
                    if(_isBeamInside(mass.mesh, other)) return false
                    return true
                default:
                    return false
            }
        }
    }

    /**
     * Parses masses
     * @param {Array} staircases 
     * @param {Object} other 
     * @param {Object} ref 
     */
    function parseMasses(masses, other, ref) {
        if(!Array.isArray(masses) || !ref) return

        masses.forEach(mass => {
            if(!mass.storey) return

            const parsed = {
                "Mesh":   { "Vertices": [], "Triangles": [] },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': 0, 'Z': 0 }
            }

            const mesh = mass.mesh

            /* set mesh's Vertices and Triangles */
            const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
            const indices = mesh.getIndices()
            
            // length must be mulitple of 3
            if(positions.length % 3 !== 0 || indices.length % 3 !== 0) return
            
            // starting from 0, every three items of array,
            // represents vertex's X, Y, Z
            
            const transformationMatrix = mesh.computeWorldMatrix(true)

            // fill mesh vertices
            for(let i = 0; i < positions.length; i += 3) {
                const globalCoords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                parsed.Mesh.Vertices.push({
                    'X': globalCoords.x,
                    'Y': globalCoords.z,
                    'Z': globalCoords.y
                })
            }

            // fill mesh triangles
            for(let i = 0; i < indices.length; i += 3) {
                parsed.Mesh.Triangles.push([
                    indices[i],
                    indices[i + 2],
                    indices[i + 1]
                ])
            }

            if(_isMassFloor(mass)) {
                _addPolyCount(parsed.Mesh.Triangles.length)
                ref["floors"].push(parsed)
                return
            }

            if(isShadingDevice(mass, other)) {
                _addPolyCount(parsed.Mesh.Triangles.length)
                ref["shading_devices"].push(parsed)
                return
            }
        })
    }

    function isRevitImported(ele) {
        return !!ele?.revitMetaData?.type
    }

    // handles revit elements
    function _formatLevelData(level) {
        const _floors = Array.from(level?.getFloors()),
              _roofs = [],
              _walls = Array.from(level?.getWalls()),
              _doors = Array.from(level?.getDoors()),
              _windows = Array.from(level?.getWindows()),
              _staircases = Array.from(level?.getStaircases()),
              _masses = []

        const map = { _floors, _roofs, _walls, _doors, _windows, _staircases, _masses, }

        const revitElements = []

        const _seperateEle = (name, elements) => {
            if(Array.isArray(elements)) {
                for(const ele of elements) {
                    if(isRevitImported(ele)) revitElements.push(ele)
                    else if(Array.isArray(map[name])) map[name].push(ele)
                }
            }
        }

        _seperateEle('_roofs', level?.getRoofs());
        _seperateEle('_masses', level?.getMasses());

        revitElements.forEach(ele => {
            const type = ele?.type
            if(type == 'Roof') {
                if(ele?.slabType === "Intermediate Slab") {
                    _floors.push(ele)
                } else {
                    _roofs.push(ele)
                }
            } else if(type == 'Mass') {
                if(ele?.massType == 'Ceiling') {
                    _roofs.push(ele)
                }
            }
        })

        return {
            _floors,
            _roofs,
            _walls,
            _doors,
            _windows,
            _staircases,
            _masses,
        }
    }

    /**
     * Parses all element in the given level
     * @param {{ _floors: [],
    *            _roofs: [],
    *            _walls: [],
    *            _doors: [],
    *            _windows: [],
    *            _staircases: [],
    *            _masses: []
    *          }} levelData 
    */
    function parseLevel(levelData, baseStorey) {
        const refObj = _getElements()

        const { _floors, _roofs , _walls, _doors, _windows, _staircases, _masses } = levelData

        // filter out original meshes
        const all = { 
            _floors: _floors.filter(floor => parseInt(floor.storey) >= baseStorey && !isOutOfScene(floor)),
            _roofs: _roofs.filter(roof => parseInt(roof.storey) >= baseStorey && !isOutOfScene(roof)),
            _walls: _walls.filter(wall => parseInt(wall.storey) >= baseStorey && !isOutOfScene(wall)),
            _doors: _doors.filter(door => parseInt(door.storey) >= baseStorey && !isOutOfScene(door)),
            _windows: _windows.filter(window => parseInt(window.storey) >= baseStorey && !isOutOfScene(window)),
            _staircases: _staircases.filter(staircase => parseInt(staircase.storey) >= baseStorey && !isOutOfScene(staircase)),
            _masses: _masses.filter(mass => parseInt(mass.storey) >= baseStorey && !isOutOfScene(mass))
        };

        parseFloors(all._floors, all, refObj);
        parseRoofs(all._roofs, all, refObj, baseStorey);
        parseWalls(all._walls, all, refObj);
        parseDoors(all._doors, all, refObj);
        parseWindows(all._windows, all, refObj);
        parseStaircases(all._staircases, all, refObj);
        parseMasses(all._masses, all, refObj);

        // add ground floor area
        _floors.forEach(floor => {
            if(parseInt(floor.storey) == baseStorey && !isOutOfScene(floor)) {
                refObj.ground_floor_area += _getAreaInFt(floor.mesh)
            }
        })

        return refObj
    }

    /**
     * Parses array of Snaptrudes 3d elements (roofs, walls, doors etc) to Covetool Format; array of objects
     * @elements array of Snaptrudes 3d elements -> [{ Mesh: {}, Center: {}, Normal: {} }]
     * @meshes_map map of meshes (babylon mesh)
     * @cb callback function called after parsing each element: (parsedElement: mutable) => {}
     */
    function parseElementsLegacyUses(elements = [], meshes_map = new Map(), cb = () => {}) {
        // elements must be an array; length must be mulitple of 3
        if(!Array.isArray(elements)) return

        const parsed = []

        /*
            Note: coordinates system is different in cove.tool
            x, y, z => x, z, y
        */

        /* 
            original mesh's position is set to (10000, 0, 10000)
            instances uses geometry of original mesh to save memory
            thus, instances do not carry geometry of their own.
            Therefore, we will save orignal mesh's data in originalElements
            to get original mesh's data when we encounter instance of original mesh
            originalElements: Map<uniqueId: string, ele: object>
        */
        const orignalElements = new Map() // to get geometry for instances

        elements.forEach(ele => {
            let uniqueId = ele["meshes"][0]["uniqueId"] // mesh id; it won't exist in case on instance (mesh)
            let element = ele

            const instances = ele['meshes'][0]["instances"]
            const globalPosition = ele['meshes'][0]["position"]
            
            // check if has instances
            if(
                Array.isArray(instances) && instances.length > 0 ||
                 (globalPosition[0] === 10000 && globalPosition[1] === 0 && globalPosition[2] === 10000) // outside of the scene
                // _around(Math.abs(globalPosition[0]), 10000, 10) && _around(Math.abs(globalPosition[2]), 10000, 10) // outside of the scene
            ) {
                // found element which has instances
                orignalElements.set(uniqueId, ele)
                return // skip as we do not need send data of mesh that is not in view
            }
            
            // if instance
            if(!uniqueId) {
                uniqueId = ele['dsProps']["uniqueID"] // instance mesh id found in dsProps
                const uniqueIdSource = ele["meshes"][0]["uniqueIdSource"] // original mesh id
                element = orignalElements.get(uniqueIdSource) // get original element
            }

            const mesh = meshes_map.get(uniqueId) // get mesh from map
            
            const parsedEle = {
                "Mesh":   { "Vertices": [], "Triangles": [] },
                "Center": { 'X': 0, 'Y': 0, 'Z': 0 },
                "Normal": { 'X': 0, 'Y': 0, 'Z': 0 }
            }

            /* set normal */
            // to be implemented
            // parsedEle.Normal.x = 0
            // parsedEle.Normal.y = 0
            // parsedEle.Normal.z = 0

            /* set mesh's Vertices and Triangles */
            const verticesData = element['geometries']['vertexData'][0]
            const positions = verticesData["positions"] // convert to Mesh Vertices
            const indices = verticesData["indices"] // convert to Mesh Triangles
            
            // length must be mulitple of 3
            if(positions.length % 3 !== 0 || indices.length % 3 !== 0) return
            
            // starting from 0, every three items of array,
            // represents vertex's X, Y, Z
            
            const transformationMatrix = mesh.computeWorldMatrix(true)

            // fill mesh vertices
            for(let i = 0; i < positions.length; i += 3) {
                const globalCoords = BABYLON.Vector3.TransformCoordinates(
                    new BABYLON.Vector3(positions[i], positions[i + 1], positions[i + 2]),
                    transformationMatrix
                )

                parsedEle.Mesh.Vertices.push({
                    'X': globalCoords.x,
                    'Y': globalCoords.z,
                    'Z': globalCoords.y
                })
            }

            // fill mesh triangles
            for(let i = 0; i < indices.length; i += 3) {
                parsedEle.Mesh.Triangles.push([
                    indices[i],
                    indices[i + 2],
                    indices[i + 1]
                ])
            }

            // call callback function
            cb(parsedEle)

            parsed.push(parsedEle)
        })

        return parsed
    }

    /**
     * Provides floor from revit imported models
     * In revit imported models, there are slabs acting as floors
     * We can get these slabs from getRoofs() func,
     * And slab type is Intermediate Slabs
     */
    function _getDataFromRevitImportedModels(levels) {
        if(!Array.isArray(levels)) return {
            floors: [],
            roofs: []
        }

        const _roofs = [], _masses = [];

        levels.forEach(level => {
            const r = level?.getRoofs(),
                  m = level?.getMasses()

            _roofs.push(...r)
            _masses.push(...m)
        })

        return {
            floors: _roofs.filter(r => !!r?.revitMetaData?.type && r?.slabType === "Intermediate Slab"),
            roofs: _masses.filter(r => !!r?.revitMetaData?.type && r?.massType === "Ceiling")
        }
    }

    /**
     * Ground floor is asummed the last floor where there is opening for sunglight to come in
     *  @param {{ _floors: [],
     *            _roofs: [],
     *            _walls: [],
     *            _doors: [],
     *            _windows: [],
     *            _staircases: [],
     *            _masses: []
     *          }} levelData
     */
    function getGroundFloor(levelData) {
        let walls, floors, doorAndWindows = { _doors: [], _windows: [] }

        let lowestStorey = Number.MAX_SAFE_INTEGER

        const { _floors, _walls, _doors, _windows } = levelData

        floors = _floors.filter(floor => {
            const valid = !isOutOfScene(floor)

            if(valid) lowestStorey = floor.storey

            return valid
        })
        walls = _walls.filter(wall => !isOutOfScene(wall))
        doorAndWindows._doors = _doors.filter(door => !isOutOfScene(door))
        doorAndWindows._windows = _windows.filter(window => !isOutOfScene(window))

        const doorByStorey = {}

        doorAndWindows._doors.forEach(d => {
            if(!doorByStorey[d.storey]) doorByStorey[d.storey] = []
            doorByStorey[d.storey].push(d)
        })

        const windowByStorey = {}

        doorAndWindows._windows.forEach(w => {
            if(!windowByStorey[w.storey]) windowByStorey[w.storey] = []
            windowByStorey[w.storey].push(w)
        })

        const alreadyIntersects = {} // a window or door already intersects

        for(const wall of walls) {
            const wallStorey = parseInt(wall.storey)
            if(wallStorey >= lowestStorey) continue

            if(isInteriorWall(wall, floors, doorAndWindows)) continue

            if(wall.properties.wallMaterialType == "GLASS" ||
               wall.mesh?.material?.materialType == "Glass" ||
               wall.mesh?._effectiveMaterial?.materialType == "Glass") {
                if(wallStorey < lowestStorey) lowestStorey = wallStorey
                continue
            }

            let intersects = false

            const _doors = doorByStorey[wallStorey]

            if(Array.isArray(_doors)) {
                for(const door of _doors) {
                    const doorStorey = parseInt(door.storey)
                    if(doorStorey >= lowestStorey) continue

                    if(wall.mesh.intersectsMesh(door.mesh)) {
                        alreadyIntersects[door.mesh.uniqueId] = true
                        intersects = true
                        
                        if(doorStorey < lowestStorey) lowestStorey = door.storey
                        
                        break;
                    }
                }
            }

            if(intersects) continue

            const _windows = windowByStorey[wallStorey]

            if(Array.isArray(_windows)) {
                for(const window of _windows) {
                    const windowStorey = parseInt(window.storey)
                    if(windowStorey >= lowestStorey) continue

                    if(wall.mesh.intersectsMesh(window.mesh)) {
                        alreadyIntersects[window.mesh.uniqueId] = true

                        if(windowStorey < lowestStorey) lowestStorey = window.storey

                        break;
                    }
                }
            }
        }

        for(const floor of floors) {
            if(parseInt(floor.storey) == lowestStorey) return floor
        }

        return null
    }

    const _getAdjustedStoreyValue = (base, storey) => {
        storey = parseInt(storey)

        if(storey > 0) {
            return storey + Math.abs(base)
        } 

        const val = storey + Math.abs(base) + 1

        return val === 0 ? -1 : val
    }

    /**
     * Repositions the whole building based on the ground floor, so it is in storey one
     * @returns {[]} meshes, so hierarchy can be reset
     */
    function adjustBuildingPositionVertically(groundFloor) {
        let meshes = store.scene.meshes

        if(Array.isArray(meshes)) {
            let refY = 0 

            for(const m of meshes) {
                if(parseInt(m.storey) == 1 && String(m.type).toLowerCase() == 'floor') {
                    refY = m?.position?.y
                    break
                }
            }

            meshes = meshes.filter(m =>  {
                if(m.uniqueId == groundFloor.mesh.uniqueId) return false

                const val = String(m?.name).toLowerCase() in _allowList

                if(val) { // adjust storey
                    m._origStorey = m.storey
                    m.storey = _getAdjustedStoreyValue(groundFloor.storey, m.storey)
                }

                return val
            })

            meshes = [groundFloor.mesh, ...meshes]

            const parentMesh = _stackMeshes(meshes)
            parentMesh._origPosY = parentMesh.position.y

            parentMesh.position.y = refY

            return meshes
        }
    }

    // restores whole building actual position vertically and remove meshes hierarchy
    function resetBuildingPositionVertically(groundFloor, meshes) {
        if(groundFloor?.mesh && groundFloor.mesh._origPosY) {
            groundFloor.mesh.position.y = groundFloor.mesh._origPosY

            delete groundFloor.mesh._origPosY
            
            if(Array.isArray(meshes)) _resetStackMeshes(meshes)
        }
    }

    /**
     * Converts structure to Cove.tools JSON Format
     * For Uploading Geometry
     * @param {{}} structure 
     * @param {boolean} si_units if true, convert to SI units (metres), otherwise convert to feet
     * @param {Map<string, {}>} meshes_map map of meshes
     * @returns {string} Stringified JSON
     */
    function structureToCoveToolJSONFormat(levels) {
        const level = levels["01"]
        const levelData = _formatLevelData(level)

        const groundFloor = getGroundFloor(levelData)
        if(!groundFloor) console.error("Failed to find ground floor")
        const baseStorey = groundFloor?.storey

        let meshes = []

        if(groundFloor?.storey < 0) { // let's adjust it temporarily
            meshes = adjustBuildingPositionVertically(groundFloor)
        }

        // reset poly count
        _polyCount = 0

        const objects = parseLevel(levelData, baseStorey)

        // Building is only made of glass walls, must add an wall somewhere a geometry to work
        if(objects.windows.length > 0 && objects.walls.length == 0) {
            objects.walls.push(FAR_AWAY_WALL)
        }
        
        if(objects.walls.length == 0 && objects.floors.length == 0) throw new Error("Please create a building.")

        if(!objects.walls.length && !objects.below_grade_walls.length) throw new Error("Building must contain at least a wall")
        if(!objects.floors.length) throw new Error("Building must contain at least a floor")
        // if(!objects.windows.length) throw new Error("Building must contain at least a window")

        const obj = {
            source: "Snaptrude",
            si_units: false,
            roof_area: 0,
            floor_area: 0,
            ground_floor_area: 0,
            building_height: 0,
            wall_area_n: 1,
            window_area_n: 1,
            ...objects
        }

        obj['building_height'] = _getBuildingHeight(levelData, groundFloor).ft
        obj['basement_depth'] = _getBasementDepth(levelData, groundFloor).ft

        // console.log("Geomtery Data:")
        // console.log(obj)

        resetBuildingPositionVertically(groundFloor, meshes)

        return JSON.stringify(obj)
    }

    /**
     * Converts RGB Array to RGBA Array
     * - Input array - every 3 items represent RGB
     * - Output array - every 4 items represent RGBA
     * @param {number[]} rgb - must be rgb.length % 3 === 0
     * @param {number} alpha - 0 - 1
     * @returns RGBA array or empty array
     */
    function _rgb_array_to_rgba_array(rgb, sda, alpha = 1) {
        if(!Array.isArray(rgb) || rgb.length % 3 !== 0) return []

        const rgba = []

        for(let i = 0; i < rgb.length; i += 3) {
            if(!sda) { // for ase change dark colours to dark red
                if(rgb[i] < 0.25 && rgb[i + 1] < 0.25 && rgb[i + 1] < 0.25) {
                    rgba.push(0.56, 0, 0, alpha)
                } else {
                    rgba.push(rgb[i], rgb[i + 1], rgb[i + 2], alpha)    
                }
            } else {
                rgba.push(rgb[i], rgb[i + 1], rgb[i + 2], alpha)
            }
        }
        return rgba
    }

    /**
     * Convert x to negative x
     * @param {number[]} coords - every 3 items represent a point in 3D space
     * @returns 
     */
    function _convert_x_to_neg_x(coords, yOffset = 0) {
        if(!Array.isArray(coords)) return []

        const copy = [...coords]

        for(let i = 0; i < copy.length; i += 3) {
            copy[i] *= -1
            if(yOffset) copy[i + 1] += yOffset
        }

        return copy
    }

    /**
     * Generates indices based on position, drawRange, and group
     * @param {number[]} position 
     * @param {{
     *      start: number,
     *      count: number,
     * }} group 
     * @param {{
     *      start: number,
     *      count: number,
     * }} drawRange 
     * @returns 
     */
    function _generate_indices(position, group, drawRange) {
        if(!group || !Array.isArray(position)) return

        const group_start = group.start || 0,
              group_count = group.count || Number.MAX_SAFE_INTEGER,
              position_count = position.length || 0,
              drawRange_start = drawRange.start || 0,
              drawRange_count = drawRange.count || Number.MAX_SAFE_INTEGER

        let start = Math.max(group_start, drawRange_start);
        const end = Math.min(position_count, Math.min(group_start + group_count, drawRange_start + drawRange_count));

        const indices = []

        for(start; start < end; start += 3) {
            indices.push(start, start + 1, start + 2)
        }

        return indices
    }
    
    /**
     * Converts ThreeJS geometry to VertexData
     * @param {Object} geometry - ThreeJS Geomtrey
     */
    function convertThreeJSGeometryDataToVertexData(geometry, repositionVetically = 0, sda) {
        if(!geometry || !geometry.data || !geometry.data.attributes) return

        const { data: { attributes, groups, index, drawRange } } = geometry
        const { color, normal, position, uv } = attributes
        const positions = position.array
        const normals = normal.array
        const rgb = color.array
        const uvs = uv.array

        const vertexData = new BABYLON.VertexData()

        vertexData.positions = _convert_x_to_neg_x(positions, repositionVetically)
        vertexData.normals = normals
        vertexData.colors = _rgb_array_to_rgba_array(rgb, sda)
        vertexData.uvs = uvs // maybe not necessary
        vertexData.indices = index || _generate_indices(positions, groups[0], drawRange || {}) // if index is not defined, generate indices
        return vertexData
    }

    function _getSkippedStoreysCount() {
        const floors = store.scene?.meshes?.filter(m => m.type?.toLowerCase() == "floor")

        if(!Array.isArray(floors) || floors.length == 0) return 0

        let minValidStorey = Number.MAX_SAFE_INTEGER

        for(const floor of floors) {
            const storey = parseInt(floor.storey)
            if(storey < minValidStorey) minValidStorey = storey
        }

        if(minValidStorey == Number.MAX_SAFE_INTEGER) return 0

        let count = 0

        for(let i = 1; i < minValidStorey; i++) count++

        return count
    }

    /**
     * Returns count of valid storeys in positive and negative Y planes
     * Storey containing floor is considered valid storey
     * @returns
     */
    function _getValidStoreyCount() {
        const count = {
            pos: 0,
            neg: 0
        }

        const floors = store.scene?.meshes?.filter(m => m.type?.toLowerCase() == "floor")

        if(!Array.isArray(floors)) {
            console.log("error in _getValidStoreyCount", floors)
            return count
        }

        const alreadyCounted = {}

        for(const floor of floors) {
            if(floor.storey in alreadyCounted) continue
            const storey = parseInt(floor.storey)
            if(storey > 0) count.pos++;
            else count.neg++

            alreadyCounted[storey] = true
        }

        return count
    }

    function adjustAnalysisVertically(gridsCount) {
        const storeyCount = _getValidStoreyCount()

        const _fixedOffsetNeg = -12, _fixedOffsetPos = 12

        let _adjustYBy = 0

        if(gridsCount > storeyCount.pos) {
            _adjustYBy = storeyCount.neg
        }

        // start analysis from ground floor
        let skippedStoreys = _getSkippedStoreysCount()
        
        /* get geometry data */
        const structure_id = store.activeLayer.structure_id
        const structures = store.exposed.structureCollection.getInstance().getStructures()
        const structure = structures[structure_id]
        const levels = structure?.getAllLevels()
        const levelData = _formatLevelData(levels['01'])
        const groundFloor = getGroundFloor(levelData)
        if(!groundFloor) console.error("Failed to find ground floor")

        if(groundFloor && parseInt(groundFloor.storey) < 0) { // when ground floor is in neg storey
            // skip floors from bottom = total neg storeys - abs ground floor pos
            skippedStoreys = storeyCount.neg - Math.abs(parseInt(groundFloor.storey))
        }

        return (_fixedOffsetPos * skippedStoreys) + (_fixedOffsetNeg * _adjustYBy)
    }

    /**
     * Renders Daylight analysis on scene
     * Default SDA (Spatial Daylight Analysis) is rendered
     * @param {Object[]} daylightData 
     * @param {boolean} sda 
     */
    function renderDaylightAnalysis(data, sda = true) {
        const daylightData = data?.analysis

        if(!Array.isArray(daylightData)) return
        if(store.daylightAnalysis.meshes) {
            for(const mesh of store.daylightAnalysis.meshes) {
                mesh.dispose()
            }
            store.daylightAnalysis.meshes = null
        }

        const scene = store.scene

        const daylightAnalysisMesh = []

        // checking if needs to adjust analysis vertically
        const analysis_rotation = data?.rotationAngle
        const gridsCount = daylightData.length > 0 ?  daylightData[0]?.grids.length : 0
        const _adjustYOffset = adjustAnalysisVertically(gridsCount)

        daylightData.forEach((ele) => {
            ele.grids.forEach((data, index) => {
                const geo = data.geometries[sda ? 0 : 1]
                const name = (sda ? "sda_" : "ase_") + "analysis_grid" + "_" + index
                const customMesh = new BABYLON.Mesh(name, scene);
                customMesh.storey = index + 1
                customMesh.type = "analysis_grid"
                customMesh.isPickable = false
                customMesh.state = "on"

                const vertexData = convertThreeJSGeometryDataToVertexData(geo, _adjustYOffset, sda)

                vertexData.applyToMesh(customMesh)
                daylightAnalysisMesh.push(customMesh)

                if(analysis_rotation) {
                    customMesh.setPivotPoint(customMesh.getBoundingInfo().boundingBox.center.clone())
                    customMesh.rotate(BABYLON.Axis.Y, analysis_rotation, BABYLON.Space.BONE)
                }

                if(Array.isArray(store.scene.meshes)) {
                    store.scene.meshes.push(customMesh)
                }
            })
        })

        store.daylightAnalysis.meshes = daylightAnalysisMesh

        // set camera to 3D
        goOutOfTwoD()
        hideAllRoofs(false)

        showToast("Scene Load Complete", 2000, TOAST_TYPES.success);

        const fn = sda ? updateProjectImage : updateProjectImageOne

        setTimeout(() => {
            // console.log("capturing photo")
            BABYLON.Tools.CreateScreenshot(
                store.engine,
                store.scene.activeCamera,
                { height: 400 },
                (data) => fn(data, store.floorkey)
            )
        }, 1000)
    }

    /**
     * Rotates coordinates around the origin based on the radians
     * @param {number} cx 
     * @param {number} cy 
     * @param {number} x 
     * @param {number} y 
     * @param {number} radians 
     * @returns 
     */
    function _rotate(cx, cy, x, y, radians) {
        const cos = Math.cos(radians), sin = Math.sin(radians);
        const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
        const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return [nx, ny];
    }

    function lastSearchedLocationChangedFromDefault() {
        if (store.lastSearchedLocationData.lastKnownZoom == 15 &&
            String(store.lastSearchedLocationData.lastKnownCoordinates.lat).includes("38.") &&
            String(store.lastSearchedLocationData.lastKnownCoordinates.lng).includes("-77.")
        ) return false

        return true
    }

    const _radsToDegrees = (rad) => rad * (180/3.14)

    // Provides North West point of terrain in global coords
    // z is negative (think z as y in 2D place)
    const _getNWCoordsFromBoundaryCoords = (boundaryCoords, rads) =>  {
        const degrees = _radsToDegrees(rads)

        let maxX = boundaryCoords[0].x, maxZ = boundaryCoords[0].z,
            minX = boundaryCoords[0].x, minZ = boundaryCoords[0].z

        const _littleOffThreshold = 2; // parallel points x or z axis may not have exact value, value may differ a little

        if(_around(degrees, 90, 3)) { // coord with max x and min z (top right)
            for(const item of boundaryCoords) {
                if( (item.x > maxX || _around(item.x, maxX, _littleOffThreshold))
                    && (item.z > minZ || _around(item.z, minZ, _littleOffThreshold))) {
                    maxX = item.x
                    minZ = item.z
                }
            }

            return { x: maxX, z: minZ }
        }

        if(_around(degrees, -90, 3)) { // coord with min x and max z (bottom left)
            for(const item of boundaryCoords) {
                if( (item.x < minX || _around(item.x, minX, _littleOffThreshold))
                    && (item.z < maxZ || _around(item.z, maxZ, _littleOffThreshold))) {
                    minX = item.x
                    maxZ = item.z
                }
            }

            return { x: minX, z: maxZ }
        }

        if(_around(degrees, 180, 3)) { // coord with max x and max z (bottom right)
            for(const item of boundaryCoords) {
                if(( item.x > maxX || _around(item.x, maxX, _littleOffThreshold))
                    && (item.z < maxZ || _around(item.z, maxZ, _littleOffThreshold))) {
                    maxX = item.x
                    maxZ = item.z
                }
            }

            return { x: maxX, z: maxZ }
        }

        let maxXIdx = 0, maxZIdx = 0, minXIdx = 0, minZIdx = 0

        for(const [idx, item] of boundaryCoords.entries()) {
            if(item.x > maxX) {
                maxX = item.x
                maxXIdx = idx
            }
            if(item.z < maxZ) { // because z is neg plane
                maxZ = item.z
                maxZIdx = idx
            }
            if(item.x < minX) {
                minX = item.x
                minXIdx = idx
            }
            if(item.z > minZ) {
                minZ = item.z
                minZIdx = idx
            }
        }
        
        if(degrees > 0 && degrees < 90) { // min Z
            return {
                x: boundaryCoords[minZIdx].x,
                z: boundaryCoords[minZIdx].z
            }
        }

        if(degrees > 90 && degrees < 180) { // max X
            return {
                x: boundaryCoords[maxXIdx].x,
                z: boundaryCoords[maxXIdx].z
            }
        }

        if(degrees > -90 && degrees < 0) { // min X
            return {
                x: boundaryCoords[minXIdx].x,
                z: boundaryCoords[minXIdx].z
            }
        }

        if(degrees < -90) { // max Z
            return {
                x: boundaryCoords[maxZIdx].x,
                z: boundaryCoords[maxZIdx].z
            }
        }
    }

    /**
     * Returns location if there is topography loaded
     * location of NW corner of the topography
     */
    function getLocationFromTerrain(allowRotateBuilding = false) {
        try {
            // check if there is terrain
            const terrains = terrainGeneration.allTerrainMapsOnTheScene()

            if(terrains.length == 0 && _useLastSerachedLocation == false) return { lat: null, lng: null }

            if(_useLastSerachedLocation && terrains.length == 0) {
                if (store.lastSearchedLocationData.lastKnownZoom == 15 &&
                    String(store.lastSearchedLocationData.lastKnownCoordinates.lat).includes("38.") &&
                    String(store.lastSearchedLocationData.lastKnownCoordinates.lng).includes("-77.")
                ) return {
                    lat: null,
                    lng: null
                }

                if(store.lastSearchedLocationData.bounds) {
                    const _bounds = store.lastSearchedLocationData.bounds
                    return {
                        lat: _bounds?._ne?.lat,
                        lng: _bounds?._sw?.lng
                    }
                }
                return {
                    lat: null,
                    lng: null
                }
            }

            // get the first terrain mesh
            const terrainMesh = terrains[0]

            // get terrain layer for information
            const structure = store.exposed.structureCollection.getInstance().getStructures()[store.activeLayer.structure_id];
            const storey = structure.getStoreyData().getStoreyByValue(1);
            const layer = storey.layerData.getLayerByName(terrainMesh.layerName, 1);
            const terrain = layer.terrain[0]

            let tilesArray = terrain?.parameters?.tilesArray;
            
            /* check if terrain is repositioned */
            const size = terrain?.parameters?.height // terrain tile is square, so height == width (almost)
            const position = terrain?.parameters?.terrainPosition
            const pos_x = position[0], pos_z = position[2] * -1 // as we have negative z

            // if terrain pos_x or pos_z does fall between size/2 - threshold and size/2 + threshold
            const isTerrainRepositioned = !_around(_trunc(pos_x), _trunc(size / 2)) || !_around(_trunc(pos_z), _trunc(size / 2))
            
            const tile_x = terrain?.parameters?.width, tile_z = terrain?.parameters?.height;
            const bounds = terrainGeneration.getBoundsOfTheTerrainMap(tilesArray, terrainMesh.mapZoom);
            const nw = bounds._nw, ne = bounds._ne, sw = bounds._sw, se = bounds._se;
            let new_lat = nw.lat, new_lng = nw.lng;

            if(isTerrainRepositioned) { // x is x, z is y of cartesian plane
                const dist_btw_nw_ne_x = nw.lng - ne.lng;
                const dist_btw_nw_sw_z = nw.lat - sw.lat;
                
                const conversion_rate_x = Math.abs(dist_btw_nw_ne_x / tile_x);
                const conversion_rate_z = Math.abs(dist_btw_nw_sw_z / tile_z);

                const centerX = tile_x / 2, centerZ = tile_z / 2;
                const movedX = terrainMesh.position.x, movedZ = terrainMesh.position.z;
                const displacementX = Math.abs(movedX) - Math.abs(centerX),
                      displacementZ = Math.abs(movedZ) - Math.abs(centerZ);

                // increase in x direction
                if(Math.abs(movedX) > Math.abs(centerX)) {
                    new_lng = nw.lng + (displacementX * conversion_rate_x);
                } else { // decrease in x direction
                    new_lng = nw.lng - (displacementX * conversion_rate_x);
                }

                // increase in z direction
                if(Math.abs(movedZ) > Math.abs(centerZ)) {
                    new_lat = nw.lat + (displacementZ * conversion_rate_z);
                } else { // decrease in z direction
                    new_lat = nw.lat - (displacementZ * conversion_rate_z);
                }
            }

            /* check if terrain is rotated  */
            const isTerrainRotated = terrainMesh.rotationQuaternion && (Math.abs(terrainMesh.rotationQuaternion.y) > 0.1)

            let resetAngle = undefined

            if(isTerrainRotated) {
                const terrainCenter = terrain?.parameters?.mapCenter;
                const rad_y = terrainMesh.rotationQuaternion.toEulerAngles().y;

                if(allowRotateBuilding) {
                    resetAngle = rad_y * -1
                    rotateMeshes(rad_y)
                }
                
                const _coord = _rotate(terrainCenter.lat, terrainCenter.lng, new_lat, new_lng, rad_y)
                new_lat = _coord[0]
                new_lng = _coord[1]
            }

            return {
                lat: new_lat,
                lng: new_lng,
                resetAngle
            }
        } catch(err) {
            console.log("Error recalculating location", err)
            return null
        }
    }

    /**
     * Get all building types
     * @returns {Array}
     */
    const _getAll = () => Object.values(BUILDING_TYPES.types)

    /**
     * Get max limit based on energy code name
     * @param {string} energyCodeName 
     * @returns 
     */
    function _getMaxLimit(energyCodeName) {
        return 1
        // if(energyCodeName?.includes("Residential") || energyCodeName?.includes("Home")) {
        //     return 1
        // }

        // return 3
    }

    function _isSelectableBuildingType(buildingType, energyCodeName, selectedBuildingTypes) {
        try {
            if(!buildingType || !energyCodeName || !Array.isArray(selectedBuildingTypes)) return true

            if(energyCodeName?.includes("Home") || energyCodeName?.includes("Residential")) {
                if(buildingType == "Single Family Home") return true
                else return false
            } else {
                if(buildingType == "Single Family Home") return false
                return true
            }
        } catch(err) {
            console.log(err)
        }
    }

    /**
     * Check if building type option should be disabled
     * @param {string} type 
     * @param {number} energyCode
     * @param {Array} buildingTypes
     * @returns 
     */
    function _disableBuildingTypeOption(buildingType, energyCodeName, selectedBuildingTypes) {
        if(!buildingType || !energyCodeName || !selectedBuildingTypes) return false

        if(energyCodeName?.includes("Home") || energyCodeName?.includes("Residential")) {
            if(buildingType != "Single Family Home") return true
        } else {
            if(buildingType == "Single Family Home") return true
        }

        return false
    }

    function resetDayligthAnalysisDataAndView() {
        // remove mesh
        if(store.daylightAnalysis.meshes) {
            for(const mesh of store.daylightAnalysis.meshes) {
                mesh.dispose()
            }
            store.daylightAnalysis.meshes = null
        }

        reduxStore.dispatch(Action.reset())
        reduxStore.dispatch(setIsCoveToolProject(false))

        // clear all polls
        store.daylightAnalysis.polls.forEach(id => clearInterval(id))
        
        // remove data
        store.daylightAnalysis =  {
            data: null,
            meshes: null,
            projectSettings: null,
            polls: [],
            pollsFloorKey: {}
        }
    }

    function handleReset() {
        resetDayligthAnalysisDataAndView()
        reduxStore.dispatch(Action.stopFakeLoading())
    }

    /**
     * Fetches daylight analysis and shows the result
     * @param {string} floor_key
     * @param {BABYLON.scene} scene
     */
    async function fetchAndStoreDaylightAnalysis(floorkey) {
        try {
            if(!store.floorkey) {
                console.log("fetchAndStoreDaylightAnalysis - no floor key", store.floorkey)
                return
            }
            reduxStore.dispatch(Action.setFetchingAnalysisAs(true))
            const data = await API.getDaylightAnalysis(floorkey)
            store.daylightAnalysis.data = data
            reduxStore.dispatch(Action.setFetchingAnalysisAs(false))
        } catch(err) {
            console.log(err)
        }
    }

    /**
     * Returns mesh if hit by ray else null
     * @param {BABYLON.Mesh} mesh - cast ray from mesh's center
     * @param {BABYLON.Vector3} direction
     * @param {Function} pickable - predicate function, must return boolean when called, true if mesh is pickable else false
     * @returns 
     */
    function castRay(mesh, direction, pickable = (mesh) => true, showRay = false) {
        const origin = mesh.position

        var ray = new BABYLON.Ray(origin, direction, 2000);

        if(showRay) {
            let rayHelper = new BABYLON.RayHelper(ray)
            rayHelper.show(store.scene)
        }
        
        const hit = store.scene.pickWithRay(ray, pickable)

        if(hit.pickedMesh) return hit.pickedMesh

        return null
    }

    /**
     * Returns true if wall is interior
     * @param {Object} wall 
     * @param {Object[]} floors 
     * @param {{
     *  _doors: Object[],
     *  _windows: Object[],
     * }} other 
     * @returns {Boolean}
     */
    function isInteriorWall(wall, floors, other) {
        if(!objectPropertiesView) return

        const mesh = wall.mesh

        // we will make changes to the real mesh
        // so, save current scaling and rotation
        const a = mesh.scaling.x;
        const b = mesh.scaling.y;
        const c = mesh.scaling.z;
        const d = mesh.rotation.y;

        let scale = mesh.scaling;
        let meshDimensions = objectPropertiesView.getMeshDimensions(mesh, { valueInSnapDim: true });
        let depth = meshDimensions.depth;
        let breadth = meshDimensions.breadth;
        let dist = 1;

        // compress wall
        // if breadth is greater than depth, then compress on z else compress on x
        if (breadth > depth) {
            mesh.scaling.z = 1/(scale.z * dist) / meshDimensions.breadth;
        } else {
            mesh.scaling.x = 1/(scale.x * dist) / meshDimensions.depth;
        }

        // rotate wall
        mesh.rotation.y += (Math.PI / 180) * 10;
        mesh.computeWorldMatrix(true);

        let intersectedFloorCount = 0
        // check if wall is touching more than one floor
        for (let p = 0; p < floors.length; p++) {
            if(isOutOfScene(floors[p])) continue

            if (floors[p].storey === wall.storey) {
                const intersect = floors[p].mesh.intersectsMesh(mesh);
                if (intersect && floors[p].room_id) {
                    intersectedFloorCount++;
                }
            }

            if(intersectedFloorCount > 1) break
        }

        // restore scaling and rotation
        mesh.scaling.x = a;
        mesh.scaling.y = b;
        mesh.scaling.z = c;
        mesh.rotation.y = d;
        mesh.computeWorldMatrix(true)

        /* if wall is intersecting more than one floor, then it is interior wall */
        if(intersectedFloorCount > 1) {
            return true
        } else {
            /* An interior wall may touch only one floor,
             * In that case, we cast ray from wall's center and check if it hits any floor
             * If it hits, then it is interior wall
             * Caution -> ignore all other things that are not floor
             */

            // ignore list (will not be picked up by ray)
            // we do not want to hit things other than floor
            const ignoreMeshLookUp = new Map()

            // add doors to ignore list
            if(Array.isArray(other['_doors'])) {
                other['_doors'].forEach(door => {
                    // just add door to ignore list
                    ignoreMeshLookUp.set(door.mesh.uniqueId, true)

                    /**
                     * There are floor just below the doors which are not part of the floor mesh
                     * So, we need to ignore them as well,
                     * to get those floors, squeeze the door x, z and scale up y
                     * check if door is intersecting any floor
                     */
                    // save current scaling
                    const x = door.mesh.scaling.x, y = door.mesh.scaling.y, z = door.mesh.scaling.z
                    door.mesh.scaling.x = door.mesh.scaling.x / 2
                    door.mesh.scaling.z = door.mesh.scaling.z / 2
                    door.mesh.scaling.y = door.mesh.scaling.y * 1.5

                    floors.forEach(floor => {
                        const isDoorFloor = floor.mesh?.parent?.uniqueId == door.mesh.uniqueId

                        if(isDoorFloor || door.mesh.intersectsMesh(floor.mesh)) {
                            ignoreMeshLookUp.set(floor.mesh.uniqueId, true)
                        }
                    })

                    // restore scaling
                    door.mesh.scaling.x = x
                    door.mesh.scaling.y = y
                    door.mesh.scaling.z = z
                })
            }

            // add doors to ignore list
            if(Array.isArray(other['_windows'])) {
                other['_windows'].forEach(window => {
                    ignoreMeshLookUp.set(window.mesh.uniqueId, true)
                })
            }

            // our predicate function, which will tell if mesh is pickable or not
            const isPickable = (mesh) => {
                if(ignoreMeshLookUp.has(mesh.uniqueId)) return false // not pickable
                return true // pickable
            }

            const pickedMesh = castRay(mesh, new BABYLON.Vector3(0, -1, 0), isPickable)
            // if we hit a mesh, check if it is floor or not
            if(pickedMesh?.name?.toLowerCase() == 'floor') return true
        }

        return false
    }

    function calculateAndSaveLocation() {
        const location = getLocationFromTerrain()

        if(location?.lat && location?.lng) {
            reduxStore.dispatch(Action.updateSettings({
                location: {
                    lat: location?.lat,
                    lng: location?.lng,
                }
            }))

            store.daylightAnalysis.projectSettings.calculationOn = true

            if(_trigger) {
                // _checkAutosave = false
                if(tryAgain) {
                    clearInterval(tryAgain)
                    tryAgain = null
                }

                collectAndValidateData(() => {})
                .then(data => {
                    if(data) prepareForDaylightAnalysis(data)
                    // _checkAutosave = true
                })
                _trigger = false
            }
        }
    }

    function initCoveProject() {
        // console.log("initiating cove tool project")
        // fetch and store daylight analysis
        fetchAndStoreDaylightAnalysis(store.floorkey, store.scene);

        // fetch cove project details
        API.getDaylightAnalysisInfo(store.floorkey)
        .then(data => {
            const original_project_name = data.details?.project_name
            if(original_project_name) document.title = original_project_name

            // set project data
            reduxStore.dispatch(Action.setCoveProjectDetails(data))

            // set loader true for fetching all records
            reduxStore.dispatch(Action.setFetchingRecordsAs(true))

            // fetch all records
            API.getDaylightAnalysisInfoAll(data?.parent_floor_key)
            .then(recordsData => {
                // sort by latest asc
                recordsData = recordsData.sort((a, b) => new Date(b.added) - new Date(a.added))

                // poll for uncompleted records
                recordsData.forEach(record => {
                    if(!record?.completed) {
                        const poller = new Polling(handleUncompletedRecord(record), {},
                            pollStartCallback(record?.floor_key),
                            pollCompleteCallback(record?.floor_key)
                        )

                        poller.start()
                    }
                })

                // set all records in redux
                reduxStore.dispatch(Action.setRecords(recordsData))
                // set loader false for fetching all records
                reduxStore.dispatch(Action.setFetchingRecordsAs(false))
                
            })
        })
        .catch(err => console.log(err))

        reduxStore.dispatch(Action.setFetchingEUIAs(true))

        // Fetch EUI of cove project
        API.getEUIBreakdown(store.floorkey)
        .then((euiData) => {
            reduxStore.dispatch(Action.setEUI({
                floor_key: store.floorkey,
                data: euiData
            }))
            
            reduxStore.dispatch(Action.setFetchingEUIAs(false))
        })
    }

    const validateData = (energy_code_id, energy_code_name, building_types, location, geometry) => {
        const res = (valid, msg = null) => ({ valid, msg })

        if(energy_code_id < 1 || !energy_code_name) return res(false, "Default energy code is not set")
        if(!Array.isArray(building_types)) return res(false, "Building types is not set")
        if(building_types.length == 0) return res(false, "Default building type is not selected")
        if(!location || !location.lat || !location.lng) return res(false, "Please load topography!")
        if(!geometry) return res(false, "No geometry provided")

        return res(true)
    }

    // Find energy code from mapping
    async function getSuitableEnergyCode() {
        const projectSettings = store.daylightAnalysis.projectSettings

        const energyCodes = store.daylightAnalysis.allEnergyCodes

        if(Array.isArray(energyCodes) && energyCodes.length < 1) return

        const location = projectSettings.location

        if(!location.lat || !location.lng) return

        const data = await getLocationInfo(location?.lat, location?.lng)

        if(!data) {
            console.log("Received no data from getLocationInfo (reverse geocoding)")
            return
            // throw Error("Oh no! An error occured while starting daylight analysis.")
        }

        const isResidential = projectSettings.buildingTypes?.includes("Single Family Home")

        const code = getEnergyCodeFromMapping(data.country, data.place, isResidential)

        for(const item of energyCodes) {
            if(item.id == code) return item
        }
    }


    const collectAndValidateData = async (openTopograhyModal) => {
        try {
            const projectSettings = store.daylightAnalysis.projectSettings

            let energyCode = parseInt(projectSettings?.energyCode),
                energyCodeName = projectSettings?.energyCodeName,
                buildingTypes = projectSettings?.buildingTypes

            if(store.daylightAnalysis.projectSettings.calculationOn) {
                const energyCodeItem = await getSuitableEnergyCode()

                if(energyCodeItem) {
                    energyCode = energyCodeItem?.id || energyCode
                    energyCodeName = energyCodeItem?.name || energyCodeName
                }

                const _mappedBuildingTypes = getDefaultBuildingTypes()

                buildingTypes = _mappedBuildingTypes.length > 0 ? _mappedBuildingTypes : (buildingTypes || ["Apartments"])

                projectSettings.energyCode = energyCode
                projectSettings.energyCodeName = energyCodeName
                projectSettings.buildingTypes = buildingTypes
                
                reduxStore.dispatch(Action.updateSettings({
                    energyCode,
                    energyCodeName,
                    buildingTypes
                }))
            }

            const location = getLocationFromTerrain(true)

            if(location && location?.lat && location?.lng) {
                reduxStore.dispatch(Action.updateSettings({ location }))
            }

            /* get geometry data */
            const structure_id = store.activeLayer.structure_id
            const structures = store.exposed.structureCollection.getInstance().getStructures()
            const structure = structures[structure_id]
            
            const geometryJSON = structureToCoveToolJSONFormat(structure.getAllLevels())

            // reset rotation 
            resetMeshesRotation()

            // const kbs = (new TextEncoder().encode(geometryJSON)).length / 1000

            // console.info("JSON:", geometryJSON)
            // console.info("Size (kb): ", kbs)
            // console.info("Size (mb): ", kbs / 1000)
            // console.info("Total Triangles Count: ", _polyCount)

            if(_polyCount > 50000) {
                typicalNotifier("Geometry Triangle count has exceeded 50,000 limit! Count: " + _polyCount)
                reduxStore.dispatch(Action.showInfoModal({
                    title: "Model is too large to run the analysis!",
                    body: (
                    <p style={{
                        fontSize: '14px',
                        lineHeight: '1.5em'
                    }}>
                        <div>Your model seems to be quite large.</div>
                        <div>Try simplifying it a bit to run the analysis.</div>
                        <div>If you need any help with this, please feel free to contact our team. We'll help you figure it out.</div>
                    </p>
                    )
                }))
                return
            }

            // validate data
            const res = validateData(energyCode, energyCodeName, buildingTypes, location, geometryJSON)

            if(!res.valid) {
                if(res.msg == "Please load topography!") {
                    store.daylightAnalysis.trigger = true
                    openTopograhyModal()
                    _trigger = true
                    return
                }
                throw new Error(res.msg)
            }

            const _locationString = (() => {
                if(location && location?.lat && location?.lng) {
                return location?.lat + ',' + location?.lng
                }
            })()

            await API.saveSettings(store.floorkey, energyCode, energyCodeName, buildingTypes, _locationString)

            const data = {
                energy_code_id: energyCode,
                energy_code_name: energyCodeName,
                building_types: buildingTypes,
                location: location.lat + ',' + location.lng,
                geometry: geometryJSON,
            }

            if(location.resetAngle) data.resetAngle = location.resetAngle

            // console.log("Starting analysis with", data)

            return data
        } catch(err) {
            console.log(err)
            showToast(err, 3000, TOAST_TYPES.error)
        }
    }

    const prepareForDaylightAnalysis = async(data, userInitiated = true) => {
        let copyFloorkey = null

        try {
            if(!store.floorkey) {
                console.log("Save default settings - no floor key", store.floorkey)
                return
            }

            if(_checkAutosave && AutoSave.getQueue().length > 0) {
                if(userInitiated) showToast("Auto save in progress. Please wait while your model is being saved.", 3000)

                if(tryAgain == null) {
                    tryAgain = setInterval(() => {
                        maxTry--

                        if(maxTry == 0) {
                            clearInterval(tryAgain)
                            maxTry = 20
                        }
                        
                        collectAndValidateData(() => {})
                        .then(data => data && prepareForDaylightAnalysis(data, false))
                    }, tryInterval)
                }

                return
            } else {
                clearInterval(tryAgain)
            }

            const errorMsg = "Oh no! An error occured while starting daylight analysis. Please try again sometime later!"
            
            /* create copy of current project data */
            const copyProjectCreated = await API.saveAsCoveProject(store.floorkey)

            if(copyProjectCreated?.status == 403) {
                showToast("You are not authorised to run Daylight Analysis.", 3000, 'collaboration')
                return
            }


            if(copyProjectCreated?.status < 200 || copyProjectCreated?.status > 299) {
                console.log("During starting daylight analysis. Error: saveAsCoveProject")
                console.log("Response status: ", copyProjectCreated?.status)
                console.log("Response data: ", copyProjectCreated?.data)
                throw new Error(errorMsg)
            }

            copyFloorkey = copyProjectCreated?.data?.floorkey

            if(!copyFloorkey) {
                console.log("During starting daylight analysis. Error: copyFloorkey does not exist")
                throw new Error(errorMsg)
            }

            const savedInSaveMicro = await saveAsSaveMicro(store.floorkey, copyFloorkey, false, false);
            if(savedInSaveMicro?.failure) {
                console.log("During starting daylight analysis. Error: savedInSaveMicro")
                throw new Error(errorMsg)
            }

            showToast("Starting daylight analysis...", 3000, TOAST_TYPES.success)
            reduxStore.dispatch(Action.removeEUI({ floor_key: store.floorkey }))
            reduxStore.dispatch(Action.startFakeLoading({
                energy_code_name: data.energy_code_name,
                building_types: data.building_types.join(',')
            }))
        
            // prepare form data
            const formData = new FormData();
            formData.append('floor_key', copyFloorkey);
            formData.append('energy_code_id', data.energy_code_id);
            formData.append('energy_code_name', data.energy_code_name);
            formData.append('building_types', data.building_types.join(','));
            formData.append('location', data.location);
            formData.append('geometry', data.geometry);

            if(data.resetAngle) formData.append('reset_angle', data.resetAngle)

            const newRecord = await API.triggerDaylightAnalysis(formData)

            if(!newRecord) {
                console.log("During starting daylight analysis. Error: No record found, can't start polling for record")
                throw new Error(errorMsg)
            }
            
            if(newRecord?.parent_floor_key == store.floorkey) {
                showToast("Daylight analysis is in progress...", 5000, TOAST_TYPES.success);
            }

            const validRecordHandler = handleRecordStatus(newRecord)
            if(validRecordHandler) {
                const poller = new Polling(validRecordHandler, {},
                    pollStartCallback(newRecord?.floor_key),
                    pollCompleteCallback(newRecord?.floor_key)
                )
                poller.start()
            }
        } catch (err) {
            reduxStore.dispatch(Action.stopFakeLoading())
            if(copyFloorkey) {
                reduxStore.dispatch(Action.setAnalysis({ floor_key: store.floorkey }))
                await API.analysisFailed(copyFloorkey)
                fetchLatestAnalysis()
            }
            console.log("error when trigerring daylight analysis", err)
            showToast(err?.msg || err, 3000, TOAST_TYPES.error);
        }
    }
    
    const startDaylightAnalysisDebounced = debounce((data) => prepareForDaylightAnalysis(data), 5000, { leading: true})

    const startDaylightAnalysis = (openTopograhyModal) => {
        collectAndValidateData(openTopograhyModal)
            .then(data => data && startDaylightAnalysisDebounced(data))
    }

    const _cr = 3.13769143505
    const kwh_m2Tokbtu_ft2 = val => val / _cr
    const kbtu_ft2Tokwh_m2 = val => val * _cr

    return {
        BuildingType: {
            getAll: _getAll,
            maxLimit: _getMaxLimit,
            isSelectableBuildingType: _isSelectableBuildingType,
            disableBuildingTypeOption: _disableBuildingTypeOption
        },
        structureToCoveToolJSONFormat,
        convertThreeJSGeometryDataToVertexData,
        renderDaylightAnalysis,
        getLocationFromTerrain,
        fetchAndStoreDaylightAnalysis,
        handleReset,
        calculateAndSaveLocation,
        startDaylightAnalysis,
        initCoveProject,
        lastSearchedLocationChangedFromDefault,
        toggleUseOflastSearchedLocation,
        kwh_m2Tokbtu_ft2,
        kbtu_ft2Tokwh_m2
    }
})()


export default coveToolHelpers