"use strict";

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

class SnapElement {
  constructor(type, onChange, editable = true, options) {
    _defineProperty(this, "onChange", null);

    _defineProperty(this, "type", "");

    _defineProperty(this, "editable", null);

    this.onChange = onChange;
    this.type = type;
    this.editable = editable;

    if (options) {
      this.options = options;
    }
  }
}

_defineProperty(SnapElement, "getInputElement", function (onChange, editable) {
  return new SnapElement("input-box", onChange, editable);
});

_defineProperty(SnapElement, "getCheckBox", function (onChange, editable) {
  return new SnapElement("check-box", onChange, editable);
});

_defineProperty(SnapElement, "getDropDown", function (options, onChange) {
  return new SnapElement("drop-down", onChange, true, options);
});

_defineProperty(
  SnapElement,
  "getSearchableDropDown",
  function (options, onChange) {
    return new SnapElement("searchable-drop-down", onChange, true, options);
  }
);
export { _defineProperty,SnapElement };
