import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import noProfileIcon from "../../assets/icons/noProfileIcon.svg";
import { colors } from "../../themes/constant";

const StyledAvatar = styled.div`
  width: ${({ width }) => width || "2.25rem"};
  height: ${({ height }) => height || "2.25rem"};
  border-radius: 50%;
  background: ${({ url, theme }) =>
    url ? `url(${url})` : theme.colors.secondaryGrey};
  background-blend-mode: normal;
  border: 0.125rem solid ${colors.veryDimDullGrey};
  box-sizing: border-box;
  position: relative;
  background-size: ${({ backgroundSize }) => backgroundSize || "2rem 2rem"};
  left: calc(-0.625rem * ${({ index }) => (index ? index : 0)});
  margin-left: ${({ marginLeft }) => marginLeft || "-0.5rem"};
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 0.625rem 0;
  height: 100%;
`;

const UrlWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
`;

function Avatar({
  urls,
  customStyle,
  width,
  backgroundSize,
  height,
  marginLeft,
}) {
  return (
    <AvatarContainer>
      <UrlWrapper>
        {urls.map((url, index) => (
          <StyledAvatar
            width={width}
            height={height}
            backgroundSize={backgroundSize}
            marginLeft={marginLeft}
            style={customStyle}
            zIndex={urls.length - index}
            key={index}
            url={url || noProfileIcon}
          />
        ))}
      </UrlWrapper>
    </AvatarContainer>
  );
}

Avatar.propTypes = {
  urls: PropTypes.array,
};

export default Avatar;
