import $ from "jquery";
var loaderView = (function () {
  const loaderImage = "../../../static/images/snap_loader.gif";
  const loaderText = [
    "Snapping to grids",
    "Optimizing plan dimensions",
    "Building the plan drawing",
    "Processing your design",
    "Gathering data from sketch",
    "Uploading your sketch",
  ];

  const infiniteloaderTexts = [
    "Getting your drawing ready",
    "Form follows... Well, we'll leave that to you :)",
    '"Less is... more? ... A bore?" Either way cleaning the clutter!',
    '"God is in the details"... Resolving those junctions',
    "Almost there...",
    '"There are 360°, so why stick to one" Optimising angles',
  ];
  let messages = [];
  let loaderActive = true;

  const _displayLoader = function (text) {
    // $.LoadingOverlay("show", {
    //     image: loaderImage,
    //     text: text,
    //     imageAnimation: "",
    //     imageColor: "transparent",
    //     imageResizeFactor: 2
    // });

    $("#loader").css("display", "block");
  };

  let i = 0;

  function move() {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var width = 1;
      var id = setInterval(frame, 2000);
      /* eslint-disable */
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }
      /* eslint-enable */
    }
  }

  function nextMsg() {
    if (messages.length == 0) {
      messages = $.merge([], infiniteloaderTexts);
      // loaderActive ? nextMsg() : "break";
      // once there is no more message, do whatever you want
    } else {
      // change content of message, fade in, wait, fade out and continue with next message
      $("#myBar").css("width :");
      $("#loading-text")
        .html(messages.pop())
        .fadeIn(500)
        .delay(12000)
        .fadeOut(500, nextMsg);
    }
  }

  const showLoader = function displayLoaderWhileLoadingData(onlyImage) {
    loaderActive = true;
    _displayLoader("");
    if (!onlyImage) {
      messages = $.merge([], loaderText);
      nextMsg();
      move();
    }
  };

  const hideLoader = function hideLoaderAfterOperation() {
    $("#myBar").css("width :100%");
    $("#loader").css("display", "none");
    loaderActive = false;
    nextMsg();
  };

  const hideSiteLoader = function () {
    document.getElementById("site-loading").style.display = "none";
  };

  const showSiteLoader = function () {
    document.getElementById("site-loading").style.display = "inline";
  };

  const showSiteLoaderForLoadingCollection = function () {
    showSiteLoader();
    document.getElementById("site-loading-status").innerHTML =
      "Recreating your project...";
  };

  const hideSiteLoaderForLoadingCollection = function () {
    hideSiteLoader();
    document.getElementById("site-loading-status").innerHTML = "";
    document.getElementById("site-loading-quotes").innerHTML = "";
  };

  const hideCanvasLoader = function () {
    document.getElementById("canvas-loader").style.display = "none";
    //document.getElementById("canvas").style.display = "block";
  };

  const showCanvasLoader = function () {
    loaderActive = true;
    document.getElementById("loader").style.zIndex = 2;
    _displayLoader("");
    messages = $.merge([], loaderText);
    nextMsg();
    move();
  };

  const moveBar = function (total, completed) {
    let progress = (completed / total) * 100;
    let elem = document.getElementById("myBar");
    $("#loading-percentage").text(progress.toFixed(2) + "%");
    elem.style.width = progress + "%";
  };

  return {
    showLoader: function (onlyImage) {
      showLoader(onlyImage);
    },
    hideLoader,
    hideSiteLoader,
    showSiteLoader,
    hideSiteLoaderForLoadingCollection,
    showSiteLoaderForLoadingCollection,
    showCanvasLoader,
    hideCanvasLoader,
    showProgress: moveBar,
  };
})();
export { loaderView };
