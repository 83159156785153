import $ from "jquery";
import { store } from "../utilityFunctions/Store.js";
import { autoSaveConfig } from "../socket/autoSaveConfig.js";
/**
 *
 * @type {{logError: (function(*=): boolean), windowOnError: (function(*=, *=, *=, *=, *=): boolean), logAction: logAction}}
 *
 * Usage:
 * 1. NewLogger.logError('your error message here')
 * 2. NewLogger.logAction('your action tracker here')
 */
const NewLogger = (() => {
  const logUrl = "/logger/";
  let isLoggerActive = false;
  const agent = navigator.userAgent;

  const postData = (type, message) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: logUrl,
        data: {
          type: type,
          floorkey: store.floorkey,
          agent: agent,
          message: message,
          csrfmiddlewaretoken: store.csrf_token,
        },
        success: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
        dataType: "json",
        type: "POST",
      });
    });
  };

  const sendDataToBackend = (type, message) => {
    if (!isLoggerActive) return;
    const dataSender = postData(type, message);
    dataSender
      .then((response) => {
        //empty
      })
      .catch((error) => {
        console.log("Error in logging error", error, message);
        isLoggerActive = false;
      });
  };

  const logAction = (actionMessage) => {
    sendDataToBackend("action", actionMessage);
  };

  const logError = (errorMessage) => {
    sendDataToBackend("error", errorMessage);
    return true;
  };

  const logPerformance = (performanceMessage) => {
    sendDataToBackend("performance", performanceMessage);
    return true;
  };

  const logPerformanceAutoSave = (performanceMessage) => {
    sendDataToBackend("performance-auto-save", performanceMessage);
    return true;
  };

  const windowOnError = (
    message = "",
    errorUrl = "",
    lineNumber = "",
    columnNumber = "",
    error = ""
  ) => {
    const finalMessage =
      "--line: " +
      lineNumber.toString() +
      " --message: " +
      message +
      " --column: " +
      columnNumber +
      " --error: " +
      error;
    return logError(finalMessage);
  };

  return {
    logAction,
    logError,
    logPerformance,
    logPerformanceAutoSave,
    windowOnError,
  };
})();

const copyProject = (destination, authkey) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: autoSaveConfig.getSocketUrl() + "copy",
      crossDomain: true,
      data: {
        source: store.floorkey,
        destination: destination,
        authkey: authkey,
      },
      success: function (data) {
        console.log("Project copy success", data);
        resolve(data);
      },
      error: function (error) {
        console.log("Project copy error", error);
        reject(error);
      },
      dataType: "JSON",
      method: "post",
    });
  });
};
export { NewLogger, copyProject };
