import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import { DragDropWrapper } from "../DragDrop";
import { handleItemsMove } from "../Folder/FoldersContainer";
import ProjectCard from "./ProjectCard";
import { AutoSizer, List } from "react-virtualized";

const ITEM_SIZE = 300;

const ProjectsWrapper = styled.div`
    height: 100%;
    .List {
        box-sizing: border-box;
    }
    .Row {
        width: 100%;
        height: 100%;
        display: grid; 
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 1em 1em;
        box-sizing: border-box;
    }
    a{
        color: ${colors.thinGray};
        text-decoration: none;
    }
    a:visited{
        color: ${colors.thinGray};
        text-decoration: none;
    }
`
const Title = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5em;
    margin-top: 1em;
    font-weight: bold;
    height: 20px;
    position: sticky;
    top: 35px;
    z-index: 10;
    background-color: ${colors.midWhite};
    span{
        cursor: pointer;
        img{
            display: block;
        }
    }
`
function ProjectsContainer({ projects, selectedTeam, viewBy, folderId }) {
    if (!projects) return null;
    return (
        <React.Fragment>
            <Title>
                <div>Projects</div>
            </Title>
            <ProjectsWrapper>
                <AutoSizer>
                    {({ height, width }) => {
                        const ITEMS_COUNT = projects.length;

                        const itemsPerRow = Math.floor(width / ITEM_SIZE) || 1;
                        const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);

                        return (
                            <List
                                className='List'
                                width={width}
                                height={height}
                                rowCount={rowCount}
                                rowHeight={ITEM_SIZE}
                                rowRenderer={
                                    ({ index, key, style }) => {
                                        const items = [];
                                        const fromIndex = index * itemsPerRow;
                                        const toIndex = Math.min(fromIndex + itemsPerRow, ITEMS_COUNT);

                                        for (let i = fromIndex; i < toIndex; i++) {
                                            const project = projects[i];
                                            items.push(
                                                <DragDropWrapper
                                                    ddId={"drag_project_" + i}
                                                    key={project.key}
                                                    id={project.key}
                                                    bucketName="folders"
                                                    type="file"
                                                    data={{ ...project, floorkey: project?.key, team_dashboard: selectedTeam?.id, folderId: folderId }}
                                                    onDrop={handleItemsMove}
                                                >
                                                    <ProjectCard project={project} selectedTeam={selectedTeam} viewBy={viewBy} parentFolderID={folderId} />
                                                </DragDropWrapper>
                                            )
                                        }

                                        return (
                                            <div
                                                className='Row'
                                                key={key}
                                                style={style}
                                            >
                                                {items}
                                            </div>
                                        )
                                    }
                                }
                            />
                        )
                    }}
                </AutoSizer>
            </ProjectsWrapper>
        </React.Fragment>
    );
}

export default ProjectsContainer;