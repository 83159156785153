import * as materialStructureMass from "./materialStructureMass"
import * as materialStructureWall from "./materialStructureWall"
import * as materialStructureRoof from "./materialStructureRoof"
import * as materialStructureFloor from "./materialStructureFloor"
import * as materialStructureStaircase from "./materialStructureStaircase"

const MATERIAL_STRUCTURE = {
  "Mass": materialStructureMass,
  "Wall": materialStructureWall,
  "Roof": materialStructureRoof,
  "Floor": materialStructureFloor,
  "Staircase": materialStructureStaircase,
}

export default MATERIAL_STRUCTURE
