import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as constants from "./constants";
import Button from "../../../components/Button/index";
import Input, { InputLabel } from "../../../components/Input/index";
import { accountStyles } from "./styles";
import noProfileIcon from "../../../assets/icons/noProfileIcon.svg";
import { routes } from "../../../routes/constants";
import { withRouter } from "react-router-dom";

import COUNTRY_CODES, { getCountryCodeByValue} from "../../../common/utils/country_codes";
import ScrollableDropdown from "../../../components/ScrollableDropdown"

import AuthService, { sendActivationMail } from "../../../services/auth.service";
import { getUserCountry, getUserProfilePictureUrl, updateAccount, updateProfilePicture } from "../../../services/account.service";
import { colors } from "../../../themes/constant";
import { updateCurrency } from "../../../services/payment.service";
import { showToast, TOAST_TYPES } from "../../../snaptrude/modules/extrafunc";

const Text = styled.p`
  font-family: ${(props) => props.theme.fonts};
  color: ${(props) => props.color || props.theme.colors.primeBlack};
  font-size: 0.775rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
`;

const Container = styled.div`
  margin: 1rem;
  .avatar {
    border-radius: 12.5rem;
    border: 0.25rem solid ${(props) => props.theme.colors.white};
    width: 4.625rem;
    min-width: 4.625rem;
    height: 4.625rem;
    min-height: 4.625rem;
  }
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 0 1.5rem 0;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomButton = styled.button`
  padding: 0.5em 1em;
  margin-top: 1.2em;
  margin-left: 1.5em;
  color: ${colors.greyishBlack};
  font-weight: bold;

  ${({ loading }) => loading && `
    cursor: progress;
  `}
`

const BLANK_COUNTRY = '-';
const COUNTRY_NAMES = [BLANK_COUNTRY, ...Object.values(COUNTRY_CODES)]

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploadState: "",
      country: COUNTRY_CODES[getUserCountry(false)] || BLANK_COUNTRY,
      email: constants.EMAIL_TEXT,
      name: constants.NAME_TEXT,
      phoneNumber: constants.PHONE_TEXT,
      profilePictureUrl: noProfileIcon,
      emailError: false,
      phoneError: false,
      sentActivationEmail: false,
      sendingActivationEmail: false
    };
  }

  updateAccountListener = (e) => {
      if (e.key === 'Enter') {
        window.analytics.track('updated account by pressing enter key');

        const [firstName, lastName] = this.splitName();

        updateAccount(firstName, lastName, this.state.phoneNumber, getCountryCodeByValue(this.state.country))
      }
  }

  componentDidMount() {
    window.analytics.track('opened accounts page');
    let user = AuthService.getCurrentUser();
    this.setState({ email: user.email });
    this.setState({ name: user.name });
    this.setState({ phoneNumber: user.phone });
    this.setState({ profilePictureUrl: getUserProfilePictureUrl() });
    this.setState({ emailActivated: user.emailActivated })
  }

  fileUploadButton = () => {
    window.analytics.track('clicked profile picture upload button');
    let imgInput = document.getElementById("fileButton");
    var fReader = new FileReader();
    imgInput.click();
    imgInput.onchange = () => {
      fReader.readAsDataURL(imgInput.files[0]);
    };

    fReader.onloadend = (e) => {
      this.setState({
        fileUploadState: e.target.result,
      });

      updateProfilePicture(imgInput.files[0]).then((data) => {
        window.analytics.track('successfully updated profile picture');
        this.setState({profilePictureUrl: getUserProfilePictureUrl()})
      });
    };
  };

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value
    });
    document.addEventListener('keydown', this.updateAccountListener)

    if (state == 'country') {
        const [firstName, lastName] = this.splitName();

        updateAccount(firstName, lastName, this.state.phoneNumber, value)
    }
  };

  splitName = () => {
        let splitedName = this.state.name.split(' ');
        let firstName = splitedName[0];

        splitedName.shift();
        let lastName = splitedName.join(' ').trim();

        return [firstName, lastName];
  }

  countryOnChange = (value) => {
    let countryCode = getCountryCodeByValue(value);
    this.setFormProperty("country", countryCode || BLANK_COUNTRY);
    updateCurrency(countryCode || 'US');
  }

  setEmailError = (value) => {
    this.setState({
      emailError: value,
    });
  };

  setPhoneError = (value) => {
    this.setState({
      phoneError: value,
    });
  };

  sentVerificationEmail = async () => {
    this.setState({ sendingActivationEmail: true })
    const res = await sendActivationMail()
    console.log("got", res)
    if(res?.sent) {
      sessionStorage.setItem("sentVerificationEmail", true);
      this.setState({ sentActivationEmail: true })
      showToast("Verification email has been sent successfully.", 3000, TOAST_TYPES.success)
      this.setState({ sendingActivationEmail: false })
    } else {
      showToast(res?.message, 3000, TOAST_TYPES.error)
      this.setState({ sendingActivationEmail: false })
    }
  }

  render() {
    const { history } = this.props;
    const {
      fileUploadState,
      email,
      name,
      phoneNumber,
      emailError,
      phoneError,
      emailActivated,
      sendingActivationEmail
    } = this.state;

    const sentVerificationEmail = this.state.sentActivationEmail || sessionStorage.getItem("sentVerificationEmail")

    return (
      <Container>
        <ProfilePicContainer>
          <img
            className="avatar"
            src={this.state.profilePictureUrl}
            alt="avatar"
            width={74}
            height={74}
          />
          <input id="fileButton" type="file" accept=".jpg,.jpeg,.png" hidden />
          <Button
            className="profiletext"
            onPress={this.fileUploadButton}
            customButtonStyle={accountStyles.addProfilePicture}
            customButtonTextStyle={accountStyles.addProfilePictureText}
            title={ constants.EDIT_PROFILE_PICTURE }
          />
        </ProfilePicContainer>
        <EmailContainer>
          <Input
            label={constants.EMAIL_ADDRESS_LABEL}
            type={"email"}
            editable={false}
            isInputDisabled={true}
            value={email}
            customInputContainerStyle={accountStyles.inputWrapper}
            error={emailError}
            setError={this.setEmailError}
          />
          {!sentVerificationEmail && !emailActivated && <CustomButton onClick={this.sentVerificationEmail} loading={sendingActivationEmail} disabled={sendingActivationEmail}>Resend Verification Email</CustomButton>}
        </EmailContainer>
        <Input
          label={constants.NAME_LABEL}
          type={"text"}
          editable={true}
          value={name}
          customInputContainerStyle={accountStyles.inputWrapper}
          onPress={(e) => this.setFormProperty("name", e.target.value)}
        />
        <Input
          label={constants.PHONE_LABEL}
          type={"tel"}
          editable={true}
          value={phoneNumber}
          customInputContainerStyle={accountStyles.inputWrapper}
          onPress={(e) => this.setFormProperty("phoneNumber", e.target.value)}
          error={phoneError}
          setError={this.setPhoneError}
        />
        <InputLabel>Country</InputLabel>
        <div style={accountStyles.inputWrapper}>
          <ScrollableDropdown
            width="100%"
            onChange={this.countryOnChange}
            options={COUNTRY_NAMES}
            selectedOption={this.state.country}
            value="country"
            name="countries"
            placeholder="Choose your Country"
            backgroundColor={colors.white}
            wrapperHeight={'2.5rem'}
          />
        </div>
        <Button
          onPress={() => {
            window.analytics.track('clicked change password on account page');
            history.push(routes.changePassword);
          }}
          customButtonStyle={accountStyles.changePasswordStyle}
          title={constants.CHANGE_PASSWORD}
        />
      </Container>
    );
  }
}

Account.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Account);
