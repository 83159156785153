import { ROOM_TYPES } from "./room_types";
import { TABS } from "./tabs";
import { TOLERANCE_LEVELS } from "./tolerance_levels";
import { UNITS_TYPE } from "./units_type";
import { UNITS_TYPES } from "./units_types";
import { UNIT_TOLERANCE } from "./unit_tolerance";
import { USER_SET_BIM_PROPERTIES } from "./user_set_bim_properties";
import { USER_SET_BIM_PROPERTIES_HANDLER } from "./user_Set_BIM_Properties_Handler";

export const $SCOPE = {
  newStorey: null,
  storeysUiData: [],
  storeyUItoggle: {},
  isTwoDimension: false,
  selected_display_unit: null,
  room_types: ROOM_TYPES,
  units_type: UNITS_TYPE,
  units_types: UNITS_TYPES,
  unit_tolerance: UNIT_TOLERANCE,
  tolerance_levels: TOLERANCE_LEVELS,
  tape_measure_value: 1,
  old_tape_measure_value: null,
  display_units: [],
  userSetBIMProperties: USER_SET_BIM_PROPERTIES,
  userSetBIMPropertiesHandler: USER_SET_BIM_PROPERTIES_HANDLER,
  areasUpdateNeeded: false,
  applyMaterialProperties : {
    imgURL : "",
    type : "",
    materialByFace: false,
  },
  face_model_value: true,
  selectedInterior: "",
  selectedInteriorType: "",
  snap_to: {
    value: "none"
  },
  cutHole: false,
  hide_object: {
    hide_object_activation: false,
    hide_object_status: false
  },
  newObjectProps: [],
  selectedItemLevelHeight: undefined,
  month: 6,
  date: 16,
  time: 25,
  hideRoofsSelected: false,
  sceneViewData: [],
  areas: {
    site_area: null, 
    structures: [], 
    total_bua: 0, 
    total_bua_far: 0, 
    total_ca: 0, 
    total_ca_far: 0, 
    total_far: 'NA'
  },
  generateMatSchedule: null,
  images: [],
  screenshotsSelectedInUI: [],
  updateViaReact: false,
  tabs: TABS,
  matProp: [],
};