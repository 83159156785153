import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import ActionDropdown from "../../../components/ActionDropdown";
import Button from "../../../components/Button";
import HistoryContext from "../../../components/Context/HistoryContext";
import TeamInviteModal from "../../../components/TeamModal.js";
import { routes } from "../../../routes/constants";
import { selectTeam } from "../../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import { Content, Footer, Header, MainContainer, Nav, NavItem, Title } from "../styles";
import Account from "./Account";
import Billing from "./Billing";
import * as constants from "./constants";
import Plans from "./Plans";
import Team from "./Team";

const TABS = constants.NAV_OPTIONS;

function ProfilePage({selectedOption}){
    const [activeTab, setActiveTab] = useState(selectedOption || constants.ACCOUNT);
    const {selectedTeam, teamlist} = useSelector((state) => state.teams);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const history = useHistory();
    useEffect(() => {
        setActiveTab(selectedOption)
    }, [selectedOption]);

    const dispatch = useDispatch();
    const handleTeamSelect = (team) => {
        dispatch(selectTeam(team.id))
    }
    const handleWorkspaceSelect = () => {
        dispatch(selectTeam(null))
    }
    const handleTabSelection = (tab) => {
        history.push(tab.route)
    }
    return (
        <MainContainer>
            <Header>
                <Title style={{flex: "none", minWidth: "8em", display: "flex"}}>
                    {selectedTeam?.title || "My Workspace"}
                    <ActionDropdown 
                        style={{marginLeft: "1em"}}
                        actions={[
                            {title: "My Workspace", handleClick: handleWorkspaceSelect },
                            ...teamlist.map((team) => {
                                return {
                                    title: team.title,
                                    handleClick: () => {handleTeamSelect(team)}
                                }
                            })
                        ]
                        }
                    />
                </Title>
                {/* <Controls></Controls> */}
            </Header>
            <Nav>
                {
                    TABS.map(tab => {
                        return <NavItem key={tab.title} isActive={activeTab === tab.title} onClick={() => {handleTabSelection(tab)}}>{tab.title}</NavItem>
                    })
                }
            </Nav>
            <Content>
                <Switch>
                    <Route exact path={routes.account}>
                        <Account />
                    </Route>
                    <Route exact path={routes.teams}>
                        <Team selectedTeam={teamlist.find(team => team.id === selectedTeam?.id)} />
                    </Route>
                    <Route exact path={routes.billing}>
                        <Billing />
                    </Route>
                    <Route exact path={routes.plans}>
                        <Plans />
                    </Route>
                </Switch>
            </Content>
            {
                activeTab === constants.TEAM && selectedTeam &&
                <Footer>
                    <Button outline={true} title={"Add team mate"} onPress={() => {setShowCreateTeamModal(true)}} />
                </Footer>
            }
            {
                showCreateTeamModal && <TeamInviteModal page={2} handleClose={() => {setShowCreateTeamModal(false)}} selectedTeamId={selectedTeam.id} />
            }
        </MainContainer>
    )
}

export default ProfilePage;