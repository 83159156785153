import React, { useState } from "react";
import styled from "styled-components";

import * as constants from "./constants";
import { SearchInput } from "../../../components/searchInput/index";
import Card from "../../../components/Card/index";
import Player from "../../../components/Player/index";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  video {
    object-fit: cover;
  }
`;

const TUTORIALS = [
  {
    name: "Understanding the interface",
    videoUrl: "https://www.youtube.com/watch?v=vV7bu-X1EzU",
    desc: "",
    thumbnail: "https://img.youtube.com/vi/vV7bu-X1EzU/hqdefault.jpg",
  },
  {
    name: "How to get started",
    videoUrl: "https://www.youtube.com/watch?v=lrrONnNOyYg",
    desc: "",
    thumbnail: "https://img.youtube.com/vi/lrrONnNOyYg/hqdefault.jpg"
  },
  {
    name: "Drawing and editing spaces",
    videoUrl: "https://youtu.be/VOGN78OMgzo",
    desc: "",
    thumbnail: "https://img.youtube.com/vi/VOGN78OMgzo/hqdefault.jpg"
  },
  {
    name: "Creating building components from spaces",
    videoUrl: "https://youtu.be/J6iaVP7RTmg",
    desc: "",
    thumbnail: "https://img.youtube.com/vi/J6iaVP7RTmg/hqdefault.jpg"
  },
]

const fuseList = new Fuse(TUTORIALS, {
  shouldSort: true,
  keys: ["name", "desc"]
})

const Tutorials = () => {
  const [projects, setProjects] = useState(TUTORIALS);
  const [searchText, setSearchText] = useState("");
  const handleSearchProjects = (evt) => {
    evt.preventDefault();
    if(searchText === ""){
      setProjects(TUTORIALS);
      return;
    }
    const matchedProjects = fuseList.search(searchText);
    setProjects(matchedProjects);
  }
  return (
    <MainContainer>
      <div className="content">
        <div className="header">
          <div className="project-header">
            <h3>{constants.PROJECT_HEADER}</h3>
          </div>
          <div className="header-div">
            <SearchInput value={searchText} onChange={(evt) => {setSearchText(evt.target.value)}}  onSubmit={handleSearchProjects} />
          </div>
        </div>
        <div className="cards">
          {projects.map((project, index) => {
            return (
              <Card
                cardWrapperStyle={{flexBasis:"28%", margin: "1%"}}
                inner={
                  <Player
                    videoUrl={project.videoUrl}
                    thumbnailUrl={project.thumbnail}
                    width={"100%"}
                    height={"11em"}
                    playImage
                    id={index}
                  />
                }
                headerText={project.name}
                description={project.desc}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </MainContainer>
  );
};

export default Tutorials;
