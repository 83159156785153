import BABYLON from "../../babylonDS.module.js";
import _ from "lodash";
import { getIndicesOfPointInVerData,convertGlobalVector3ToLocal,onSolid } from "../../extrafunc.js";
import { getVertexNeighbours,updateBrepPositions } from "../../../libs/brepOperations.js";
import { virtualSketcher } from "../../sketchMassBIMIntegration/virtualSketcher.js";
import { filterOutStructuralAssociationComponents,handleRoofPropertyChangeCommand,disposeSnapToObjects,updateMeshFacetData } from "./moveUtil.js";
import { commandUtils } from "../../commandManager/CommandUtils.js";
import { delayedExecutionEngine } from "../../utilityFunctions/delayedExecution.js";
import { findPrioritizedSnapPoint } from "../../../libs/snapFuncsPrimary.js";
import { DisplayOperation } from "../../displayOperations/displayOperation.js";
import { moveOperator } from "./moveOperation.js";
import { uiIndicatorsHandler } from "../../uiIndicatorOperations/uiIndicatorsHandler.js";
import { setLayerTransperancy } from "../../../libs/sceneFuncs.js";
import { getMeshMedianY } from "../../../libs/mathFuncs.js";
import { StoreyMutation } from "../../storeyEngine/storeyMutations.js";
import { CommandManager } from "../../commandManager/CommandManager.js";
const moveVertex = (function () {
  let _selectedVertex = null;
  let _meshOfInterest = null;
  let _componentOfInterest = null;

  const _meshesOfInterest = [];
  const _componentsOfInterest = [];

  let _vertexSelected = false;
  let _vertexStartingPositionOnMove = null;
  let _vertexInitialPosition = null;

  let _componentVertexIndicesMapping = {};
  let _vertexNeighbours = [];

  let _moveVertexCommandData = null;
  let _propertyChangeCommandData = null;
  let _meshEditCheckValue = null;

  const _CONSTANTS = {
    propertyChangeCommandName: "moveVertexPropertyChange",
  };

  function setInitialData(data) {
    let storeVertexIndices = function () {
      _componentsOfInterest.forEach((c) => {
        _componentVertexIndicesMapping[c.id] = getIndicesOfPointInVerData(
          _vertexInitialPosition,
          c,
          BABYLON.Space.WORLD
        );
      });
    };

    let populateVertexNeighbourInformation = function () {
      _vertexNeighbours = getVertexNeighbours(
        _selectedVertex,
        _meshOfInterest,
        BABYLON.Space.WORLD
      );
    };

    _selectedVertex = data.vertex;
    _meshOfInterest = data.mesh;
    _componentOfInterest = _meshOfInterest.getSnaptrudeDS();

    if (virtualSketcher.util.isComponentPlanar(_componentOfInterest)) {
      _componentsOfInterest.push(_componentOfInterest);
    } else {
      const structuralAssociation =
        virtualSketcher.structuralLookup(_selectedVertex);

      if (structuralAssociation) {
        _selectedVertex = structuralAssociation.vector.clone();
        filterOutStructuralAssociationComponents(
          _componentOfInterest,
          structuralAssociation.components
        );
        _componentsOfInterest.push(...structuralAssociation.components);
      } else _componentsOfInterest.push(_componentOfInterest);
    }

    _meshesOfInterest.push(..._componentsOfInterest.map((c) => c.mesh));

    _vertexSelected = true;
    _vertexInitialPosition = _selectedVertex.clone();
    _vertexStartingPositionOnMove = _selectedVertex.clone();

    storeVertexIndices();
    populateVertexNeighbourInformation();
    _moveVertexCommandData =
      commandUtils.geometryChangeOperations.getCommandData(_meshesOfInterest);
    _meshEditCheckValue =
      _meshOfInterest.getBoundingInfo().boundingBox.extendSizeWorld.y;
    // newScene.activeCamera.detachControl(canvas);

    _propertyChangeCommandData = handleRoofPropertyChangeCommand(
      _componentsOfInterest
    );

    delayedExecutionEngine.addExecutable(_recordOperation);
  }

  function _moveVertex(toPosition, fromPosition) {
    _componentsOfInterest.forEach((component) => {
      const brep = component.brep;
      const mesh = component.mesh;

      let movementVector = convertGlobalVector3ToLocal(
        toPosition,
        mesh
      ).subtract(convertGlobalVector3ToLocal(fromPosition, mesh));

      let oldPos = [];
      let newPos = [];

      let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

      const vertexCoordIndices = _componentVertexIndicesMapping[component.id];

      for (let i = 0; i < vertexCoordIndices.length; i++) {
        oldPos.push([
          verData[vertexCoordIndices[i][0]],
          verData[vertexCoordIndices[i][1]],
          verData[vertexCoordIndices[i][2]],
        ]);
        verData[vertexCoordIndices[i][0]] += movementVector.x;
        verData[vertexCoordIndices[i][1]] += movementVector.y;
        verData[vertexCoordIndices[i][2]] += movementVector.z;
        newPos.push([
          verData[vertexCoordIndices[i][0]],
          verData[vertexCoordIndices[i][1]],
          verData[vertexCoordIndices[i][2]],
        ]);
      }

      let subMeshes = [...mesh.subMeshes];

      let geometry = mesh.geometry || mesh.sourceMesh.geometry;
      geometry.setVerticesData(
        BABYLON.VertexBuffer.PositionKind,
        verData,
        true
      );

      mesh.subMeshes = subMeshes;

      updateBrepPositions(brep, oldPos, newPos);

      if (mesh.type.toLowerCase() === "roof")
        component.updateRoofOutline(oldPos, newPos);
    });
  }

  function move() {
    function _getScenePosition() {
      disposeSnapToObjects();

      let vertexMovement = {};
      vertexMovement.neighbours = _vertexNeighbours;

      let options = {
        vertexMovement,
        operationSometimesLimitedToY: true, //for roofs
        excludedMeshes: _meshesOfInterest,
      };

      return findPrioritizedSnapPoint(
        _vertexInitialPosition,
        null,
        _meshOfInterest,
        options
      );
    }

    if (!_vertexInitialPosition) return;

    let vertexCurrentPosition = _getScenePosition();

    if (!vertexCurrentPosition) return;

    let incrementalMovementVector = vertexCurrentPosition.subtract(
      _vertexStartingPositionOnMove
    );

    DisplayOperation.drawOnMove(_vertexInitialPosition, vertexCurrentPosition);
    let totalMovementAmount = vertexCurrentPosition
      .subtract(_vertexInitialPosition)
      .length();
    DisplayOperation.displayOnMove(totalMovementAmount, null, true, {
      onChangeCallback: moveOperator.handleUserInput,
    });

    _moveVertex(vertexCurrentPosition, _vertexStartingPositionOnMove);
    uiIndicatorsHandler.vertexIndicator.moveBy(incrementalMovementVector);

    _vertexStartingPositionOnMove = vertexCurrentPosition;

    _meshesOfInterest.forEach((m) => {
      onSolid(m);
      setLayerTransperancy(m);
    });
  }

  function _recordOperation() {
    let verData = _meshOfInterest.getVerticesData(
      BABYLON.VertexBuffer.PositionKind
    );
    _meshOfInterest.midY = getMeshMedianY(
      verData,
      _meshOfInterest.getWorldMatrix()
    );

    StoreyMutation.assignStorey(_componentsOfInterest);

    _meshesOfInterest.forEach((m) => {
      m.computeWorldMatrix(true);
      updateMeshFacetData(m);
    });

    let newMeshEditCheckValue =
      _meshOfInterest.getBoundingInfo().boundingBox.extendSizeWorld.y;
    if (_.round(_meshEditCheckValue, 4) !== _.round(newMeshEditCheckValue, 4)) {
      _componentsOfInterest.forEach((c) => c.markAsEdited());
    }

    _moveVertexCommandData =
      commandUtils.geometryChangeOperations.getCommandData(
        _meshesOfInterest,
        _moveVertexCommandData
      );
    let moveVertexCommand = commandUtils.geometryChangeOperations.getCommand(
      commandUtils.CONSTANTS.moveVertexOperation,
      _moveVertexCommandData
    );

    const integrationGeometryChangeCommand =
      virtualSketcher.updateWithGeometryEdit(_componentsOfInterest, true);

    const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
        [moveVertexCommand, integrationGeometryChangeCommand]
    );

    const propertyChangeCommand = handleRoofPropertyChangeCommand(_componentsOfInterest, _propertyChangeCommandData);

    const commands = _.compact([commonGeometryChangeCommand, propertyChangeCommand]);
    const yets = commands.map((_) => false);

    CommandManager.execute(commands, yets);
    
    resetData();
  }

  const resetData = function () {
    _vertexSelected = false;
    _vertexStartingPositionOnMove = null;
    _vertexInitialPosition = null;
    _componentVertexIndicesMapping = {};
    _vertexNeighbours = [];
    _componentsOfInterest.length = 0;
    _meshesOfInterest.length = 0;
  };

  function finishOperation(intermediateConclusion) {
    _componentsOfInterest.forEach((c) => {
      delete c.brep.indexes;

      onSolid(c.mesh);
      setLayerTransperancy(c.mesh);

      if (c.mesh.isAnInstance) {
        c.mesh.sourceMesh.instances.forEach((i) => i.refreshBoundingInfo());
      } else {
        c.mesh.refreshBoundingInfo();
      }
    });

    // eslint-disable-next-line no-empty
    if (intermediateConclusion) {
    } else {
      delayedExecutionEngine.executeAll();
      resetData();
    }
  }

  function handleUserInput(input) {
    if (_vertexSelected) {
      let currentPosition = _vertexStartingPositionOnMove;
      let direction = currentPosition
        .subtract(_vertexInitialPosition)
        .normalize()
        .scale(input);
      let destination = _vertexInitialPosition.add(direction);

      _moveVertex(destination, currentPosition);
    }
  }

  function cancelOperation() {
    handleUserInput(0);
    finishOperation(true);
    resetData();
  }

  return {
    setInitialData,
    move,
    finishOperation,
    handleUserInput,
    cancelOperation,
    resetData,
  };
})();
export { moveVertex };
