(function (exports) {
  "use strict";
  function DoublyLinkedList() {
    this._head = null;
    this._tail = null;
    this._length = 0;
  }

  DoublyLinkedList.prototype._createNewNode = function (data) {
    var list = this;

    var node = {
      data: data,
      next: null,
      prev: null,

      remove: function () {
        if (this.prev !== null) {
          this.prev.next = this.next;
        }

        if (this.next !== null) {
          this.next.prev = this.prev;
        }

        if (list._head === this) {
          list._head = this.next;
        }

        if (list._tail === this) {
          list._tail = this.prev;
        }

        list._length--;
      },

      prepend: function (data) {
        if (list._head === this) {
          return list.prepend(data);
        }

        var newNode = list._createNewNode(data);
        newNode.prev = this.prev;
        newNode.next = this;
        this.prev.next = newNode;
        this.prev = newNode;

        list._length++;
        return newNode;
      },

      append: function (data) {
        if (list._tail === this) {
          return list.append(data);
        }

        var newNode = list._createNewNode(data);
        newNode.prev = this;
        newNode.next = this.next;
        this.next.prev = newNode;
        this.next = newNode;

        list._length++;
        return newNode;
      },
    };

    return node;
  };

  DoublyLinkedList.prototype.append = function (data) {
    var node = this._createNewNode(data);

    if (this._length === 0) {
      this._head = node;
      this._tail = node;
      node.prev = node;
    } else {
      this._tail.next = node;
      this._head.prev = node;
      node.prev = this._tail;
      node.next = this._head;
      this._tail = node;
    }
    this._length++;

    return node;
  };

  DoublyLinkedList.prototype.prepend = function (data) {
    var node = this._createNewNode(data);

    if (this._head === null) {
      return this.append(data);
    } else {
      this._head.prev = node;
      node.next = this._head;
      this._head = node;
    }
    this._length++;
    return node;
  };

  DoublyLinkedList.prototype.item = function (index) {
    switch (typeof index) {
      case "object":
        let flag = false;
        for (var a = this._head; a != null; a = a.next) {
          if (
            JSON.stringify(a.data.original_cords) ===
              JSON.stringify(index.original_cords) ||
            a.data.uniqueId === index.uniqueId
          ) {
            flag = true;
            break;
          }
        }
        if (flag) {
          return a;
        } else return {};

      case "string":
        for (let a = this._head; a != null; a = a.next) {
          if (a.data === index) return a;
        }
        return {};

      case "number":
        if (index >= 0 && index < this._length) {
          var node = this._head;
          while (index--) {
            node = node.next;
          }
          return node;
        }
        break;
    }
  };
  DoublyLinkedList.prototype.head = function () {
    return this._head;
  };
  DoublyLinkedList.prototype.tail = function () {
    return this._tail;
  };

  DoublyLinkedList.prototype.size = function () {
    return this._length;
  };
  DoublyLinkedList.prototype.remove = function (index) {
    switch (typeof index) {
      case "object":
        let flag = false;
        for (var i = 0, a = this._head; i < this._length; i++, a = a.next) {
          if (a.data.uniqueId === index.uniqueId || index.isDisposed()) {
            flag = true;
            break;
          }
        }
        if (flag) {
          return a.remove();
        } else return {};

      case "string":
        for (let a = this._head; a != null; a = a.next) {
          if (a.data === index) return a.remove();
        }
        return {};

      case "number":
        if (index >= 0 && index < this._length) {
          var node = this._head;
          while (index--) {
            node = node.next;
          }
          return node.remove();
        }
        break;
    }
  };

  exports.DoublyLinkedList = DoublyLinkedList;
})(typeof exports === "undefined" ? (this["DLL"] = {}) : exports);
