import stackedWallHelper from "./stackedWallHelper";
import {Command} from "../commandManager/Command";
import {AutoSave} from "../socket/autoSave";
import {store} from "../utilityFunctions/Store";

const stackedWallCommandHelper = (function (){
  
  const _add = function (arrayOfIDs) {
    arrayOfIDs.forEach(([meshId, companionId]) => {
      const mesh = store.scene.getMeshByUniqueID(meshId);
      const companion = store.scene.getMeshByUniqueID(companionId);
      
      if (mesh && companion) stackedWallHelper.rawAccess.addToData(mesh, companion);
    });
  };
  
  const _remove = function (arrayOfIDs) {
    arrayOfIDs.forEach(([meshId, companionId]) => {
      const mesh = store.scene.getMeshByUniqueID(meshId);
      const companion = store.scene.getMeshByUniqueID(companionId);
      
      if (mesh && companion) stackedWallHelper.rawAccess.removeFromData(mesh, companion);
    });
  };
  
  const execute = function (data) {
    if (!data) data = this.data;
  
    _remove(data.toRemoveArray);
    _add(data.toAddArray);
  };
  
  const unexecute = function (data) {
    if (!data) data = this.data;
  
    _remove(data.toAddArray);
    _add(data.toRemoveArray);
  };
  
  const getSaveData = function () {
    const saveData = AutoSave.getSaveDataPrototype();
    
    saveData.commandId = this.id;
    saveData.data.saveType = "stackedWallData";
    saveData.data.operationData = this.data;
    
    return saveData;
  };
  
  const getCommand = function (toAddArray = [], toRemoveArray = []){
    
    const commandData = {
      toAddArray: toAddArray.map(([mesh, companion]) => [mesh.uniqueId, companion.uniqueId]),
      toRemoveArray: toRemoveArray.map(([mesh, companion]) => [mesh.uniqueId, companion.uniqueId]),
    };
    
    const logic = {
      execute,
      unexecute,
    }
    
    return new Command("stackedWallData", commandData, logic, getSaveData);
    
  };
  
  const executeFromSaveData = function (saveData) {
    execute(saveData.operationData);
  };
  
  const unExecuteFromSaveData = function (saveData) {
    unexecute(saveData.operationData);
  };
  
  return {
    getCommand,
    executeFromSaveData,
    unExecuteFromSaveData,
  }
})();

export default stackedWallCommandHelper;
