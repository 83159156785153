"use strict";
import BABYLON, { Line } from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { colorUtil } from "../utilityFunctions/colorUtility.js";
import { deepCopyObject } from "../extrafunc.js";
const edgeIndicator = (function () {
  const CONSTANTS = {
    edgeIndicator: "edgeIndicator",
    lineWidth: 3,
  };

  const TYPES = {
    allIndicators: 0,
    preSnapIndicator: 1,
    postSnapIndicator: 2,
    measureIndicator1: 3,
    measureIndicator2: 4,
    massCreatedIndicator: 5,
    persistentIndicator : 6,
    curveIndicator : 7,
  };

  let texture;
  let preSnapIndicator;
  let postSnapIndicator;
  let measureIndicator1;
  let measureIndicator2;
  let curveIndicator;

  const massCreatedIndicators = [];
  const activeIndicators = [];
  const curveIndicators = [];

  const _addToTexture = function (control, texture) {
    if (!control.host) {
      texture.addControl(control);
    }
    if (!control.parent) {
      texture.rootContainer.addControl(control);
    }
  };

  const _newLine = function (color, lineWidth = CONSTANTS.lineWidth) {
    let line = new Line(CONSTANTS.edgeIndicator);
    line.color = color;
    line.lineWidth = lineWidth;
    line.snaptrudeProperties = {};
    line.isVisible = false;

    // line.shadowColor = colorUtil.type.black;
    // line.shadowBlur = 5;
    
    _addToTexture(line, texture);

    return line;
  };

  const init = function () {
    texture = store.advancedTexture;
    preSnapIndicator = _newLine(colorUtil.type.preSnap);
    postSnapIndicator = _newLine(colorUtil.type.postSnap);
    measureIndicator1 = _newLine(colorUtil.type.postSnap);
    measureIndicator2 = _newLine(colorUtil.type.postSnap);
  };

  const show = function (edge, mesh, options) {
    let activeIndicator;
    if (options.indicator === TYPES.preSnapIndicator)
      activeIndicator = preSnapIndicator;
    else if (options.indicator === TYPES.postSnapIndicator)
      activeIndicator = postSnapIndicator;
    else if (options.indicator === TYPES.measureIndicator1)
      activeIndicator = measureIndicator1;
    else if (options.indicator === TYPES.measureIndicator2)
      activeIndicator = measureIndicator2;
    else if (options.indicator === TYPES.massCreatedIndicator || 
      options.indicator === TYPES.persistentIndicator ||
      options.indicator === TYPES.curveIndicator )
      activeIndicator = options.indicatorLine;

    if (!activeIndicator.isVisible) activeIndicator.isVisible = true;
    _addToTexture(activeIndicator, texture);
    activeIndicator.snaptrudeProperties.mesh = mesh;
    activeIndicator.snaptrudeProperties.edge = deepCopyObject(edge);
    activeIndicator.moveToVector3(edge.headPt, store.scene);
    activeIndicator.moveToVector3(edge.tailPt, store.scene, true);

    activeIndicators.push(activeIndicator);

    return activeIndicator;
  };

  const moveBy = function (movementAmount) {
    let activeIndicator = preSnapIndicator;
    if (!activeIndicator.isVisible) return;

    _addToTexture(activeIndicator, texture);
    activeIndicator.snaptrudeProperties.edge.headPt.addInPlace(movementAmount);
    activeIndicator.snaptrudeProperties.edge.tailPt.addInPlace(movementAmount);

    activeIndicator.moveToVector3(
      activeIndicator.snaptrudeProperties.edge.headPt,
      store.scene
    );
    activeIndicator.moveToVector3(
      activeIndicator.snaptrudeProperties.edge.tailPt,
      store.scene,
      true
    );
  };

  const moveTo = function (destination) {
    let activeIndicator = preSnapIndicator;
    if (!activeIndicator.isVisible) return;

    const refPoint = BABYLON.Vector3.Center(
      activeIndicator.snaptrudeProperties.edge.headPt,
      activeIndicator.snaptrudeProperties.edge.tailPt
    );

    moveBy(destination.subtract(refPoint));
  };

  const remove = function (type = TYPES.allIndicators) {
    const _deactivate = function (indicator) {
      indicator.isVisible = false;
      indicator.isEnabled = true;
      if (indicator.color === colorUtil.type.nonEditable) {
        indicator.color = colorUtil.type.preSnap;
      }
      _.remove(activeIndicators, indicator);
    };

    if (type === TYPES.allIndicators) {
      remove(TYPES.massCreatedIndicator);
      for (let i = 0; i < activeIndicators.length; ) {
        // no increment
        if (activeIndicators[i].snaptrudeProperties.type === TYPES.persistentIndicator) i++;
        else _deactivate(activeIndicators[i]);
      }
    } else if (type === TYPES.preSnapIndicator) {
      _deactivate(preSnapIndicator);
    } else if (type === TYPES.postSnapIndicator) {
      _deactivate(postSnapIndicator);
    } else if (type === TYPES.measureIndicator1) {
      _deactivate(measureIndicator1);
    } else if (type === TYPES.measureIndicator2) {
      _deactivate(measureIndicator2);
    } else if (type === TYPES.massCreatedIndicator) {
      for (let i = 0; i < activeIndicators.length; ) {
        // no increment
        if (
          activeIndicators[i].snaptrudeProperties.type !==
          TYPES.massCreatedIndicator
        ) {
          i++;
          continue;
        }
        activeIndicators[i].dispose();
        _.remove(activeIndicators, activeIndicators[i]);
      }
    } else if (type === TYPES.persistentIndicator) {
      for (let i = 0; i < activeIndicators.length;){ // no increment
          if (activeIndicators[i].snaptrudeProperties.type !== TYPES.persistentIndicator) {
              i++;
              continue;
          }
          activeIndicators[i].dispose();
          _.remove(activeIndicators, activeIndicators[i]);
      }
    }else if (type === TYPES.curveIndicator) {
      for (let i = 0; i < activeIndicators.length;){ // no increment
        if (activeIndicators[i].snaptrudeProperties.type === TYPES.curveIndicator) {
          activeIndicators[i].dispose();
          _.remove(activeIndicators, activeIndicators[i]);  
        }
      }
    }
  };

  const update = function () {
    activeIndicators.forEach((ai) => {
      const edge = ai.snaptrudeProperties.edge;
      ai.moveToVector3(edge.headPt, store.scene);
      ai.moveToVector3(edge.tailPt, store.scene, true);
    });
  };

  const markAsUneditable = function () {
    activeIndicators.forEach((indicator) => {
      if (indicator.snaptrudeProperties.type === TYPES.massCreatedIndicator) return;
      
      indicator.color = colorUtil.type.nonEditable;
      indicator.background = colorUtil.type.nonEditable;
    });
  };

  const massConstruct = function (
    arrayOfFaceVertices,
    exclusions = [],
    options = {}
  ) {
    if (!options.colors) options.colors = [];
    let massIndicatorType = TYPES.massCreatedIndicator;
    let showMassEdge = false;
    if(options.showMassEdge)
      showMassEdge = options.showMassEdge;
    arrayOfFaceVertices.forEach((faceVertices, i) => {
      const length = faceVertices.length;
      const colorOfEdges = options.colors[i];
      faceVertices.forEach((vertex, j) => {
        if(showMassEdge && j === length - 1)
          return;
        const nextIndex = j === length - 1 ? 0 : j + 1;
        const nextVertex = faceVertices[nextIndex];

        const edge = {
          headPt: vertex,
          tailPt: nextVertex,
        };

        if (
          exclusions.inArray((e) => e.almostEquals(vertex)) &&
          exclusions.inArray((e) => e.almostEquals(nextVertex))
        )
          return;

        const indicatorLine = _newLine(
          colorOfEdges || colorUtil.type.postSnap,
          options.lineWidth
        );
        indicatorLine.snaptrudeProperties.type =
          TYPES.massCreatedIndicator;
        massCreatedIndicators.push(indicatorLine);

        show(edge, null, {
          indicator: massIndicatorType,
          indicatorLine,
        });
      });
    });
  };

  const massConstructFromEdges = function (arrayOfArrayOfEdges, options = {}) {
    if (!options.colors) options.colors = [];

    arrayOfArrayOfEdges.forEach((arrayOfEdges, i) => {
      const colorOfEdges = options.colors[i];
      arrayOfEdges.forEach((edge, j) => {
        const type = options.persist ? TYPES.persistentIndicator : TYPES.massCreatedIndicator;

        const indicatorLine = _newLine(colorOfEdges || colorUtil.type.postSnap, options.lineWidth);
        indicatorLine.snaptrudeProperties.type = type;
        massCreatedIndicators.push(indicatorLine);

        show(edge, null, {
          indicator : type,
          indicatorLine
        });
      });
    });
  };

  const massDestruct = function (persistent = true) {
    remove(TYPES.massCreatedIndicator);
    massCreatedIndicators.length = 0;
    if (persistent) remove(TYPES.persistentIndicator);
  };

  const curveIndicatorDestruct = function (persistent = true) {
    remove(TYPES.massCreatedIndicator);
    massCreatedIndicators.length = 0;
    if (persistent) remove(TYPES.persistentIndicator);
  };

  const curveIndicatorConstruct = function (
    arrayOfFaceVertices,
    exclusions = [],
    options = {}
  ) {
    if (!options.colors) options.colors = [];
    arrayOfFaceVertices.forEach((faceVertices, i) => {
      const length = faceVertices.length;
      faceVertices.forEach((vertex, j) => {
        if(j === length - 1)
          return;
        const nextIndex = j + 1;
        const nextVertex = faceVertices[nextIndex];

        const edge = {
          headPt: vertex,
          tailPt: nextVertex,
        };

        if (
          exclusions.inArray((e) => e.almostEquals(vertex)) &&
          exclusions.inArray((e) => e.almostEquals(nextVertex))
        )
          return;

        const indicatorLine = _newLine(
          colorUtil.type.preSnap,
          options.lineWidth
        );
        indicatorLine.snaptrudeProperties.type =
          TYPES.curveIndicator;
        curveIndicators.push(indicatorLine);

        show(edge, null, {
          indicator: TYPES.curveIndicator,
          indicatorLine,
        });
      });
    });
  };
  return {
    TYPES,
    init,
    show,
    moveBy,
    moveTo,
    remove,
    update,
    markAsUneditable,
    massConstruct,
    massConstructFromEdges,
    massDestruct,
    curveIndicatorConstruct,
    curveIndicatorDestruct,

  };
})();
export { edgeIndicator };
