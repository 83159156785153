import { store } from "../../modules/utilityFunctions/Store.js";
import { removeAllChildren } from "../keyEvents.js";
import { removeAllGripsWithId } from "./objectGrips.js";
import { appElement } from "../bimDataFuncs.js";
// *********************************************************************
function updateEventMode(evt, md) {
  updateButtonColor(md);
  removeAllChildren();
  removeAllHoverComponents();
  removeAllGripsWithId("addvertexfinal");
  removeAllGripsWithId("addvertex");
  removeAllGripsWithId("children");
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  $scope.$apply(function () {
    if (md == "null") {
      $scope.op_mode = "None";
      return;
    } else if (md == "zoom") {
      $scope.op_mode = "Zoom";
    } else if (md == "pan") {
      $scope.op_mode = "Pan";
    } else if (md == "paste") {
      $scope.op_mode = "Paste";
    } else if (md == "door") {
      $scope.op_mode = "Door";
    } else if (md == "window") {
      $scope.op_mode = "Window";
    } else if (md == "door_draw") {
      $scope.op_mode = "Draw Door";
    } else if (md == "window_draw") {
      $scope.op_mode = "Draw Window";
    } else if (md == "stair_draw") {
      $scope.op_mode = "Draw Stairs";
    } else if (md == "partition") {
      $scope.op_mode = "Partition";
    } else if (md == "edit") {
      $scope.op_mode = "Edit Object";
    } else if (md == "edit_vert") {
      $scope.op_mode = "Edit Vertices";
    } else if (md == "edit_edge") {
      $scope.op_mode = "Edit Edges";
    } else if (md == "tape") {
      $scope.op_mode = "Enter Scale";
    } else if (store.mode == "pen") {
      $scope.op_mode = "Free Draw";
    }
    // $scope.op_mode = md;
  });
}

function removeAllHoverComponents() {
  if (store.door_group) {
    store.canvas.remove(store.door_group);
  }
  if (store.window_group) {
    store.canvas.remove(store.window_group);
  }
  if (store.stair_group) {
    store.canvas.remove(store.stair_group);
  }
}

function updateEventModeInterior(evt, md, name) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  if (name === "room1") {
    if ($scope.$$phase) {
      $scope.op_mode = "Room-1";
    } else {
      $scope.$apply(function () {
        $scope.op_mode = "Room-1";
      });
    }
  } else {
    var n = name.slice(1, name.length - 1);
    $scope.op_mode = "R" + n + "-" + name[name.length - 1];
  }
}

function updateButtonColor(dom) {
  console.log(dom);
  var activeButtonColor = "orange";
  var inactiveButtonColor = "";
  if (dom === "edit_edge") {
    document.getElementById("edit_edge").style.backgroundColor =
      activeButtonColor;
    document.getElementById("edit").style.backgroundColor = inactiveButtonColor;
    document.getElementById("penTool").style.backgroundColor =
      inactiveButtonColor;
    //document.getElementById("eraseTool").style.backgroundColor = inactiveButtonColor;
  }
  if (dom === "edit") {
    document.getElementById("edit").style.backgroundColor = activeButtonColor;
    document.getElementById("penTool").style.backgroundColor =
      inactiveButtonColor;
    //document.getElementById("eraseTool").style.backgroundColor = inactiveButtonColor;
    document.getElementById("edit_edge").style.backgroundColor =
      inactiveButtonColor;
  } else if (dom == "pen") {
    document.getElementById("edit").style.backgroundColor = inactiveButtonColor;
    document.getElementById("penTool").style.backgroundColor =
      activeButtonColor;
    //document.getElementById("eraseTool").style.backgroundColor = inactiveButtonColor;
    document.getElementById("edit_edge").style.backgroundColor =
      inactiveButtonColor;
  } else if (dom == "erase") {
    document.getElementById("edit").style.backgroundColor = inactiveButtonColor;
    document.getElementById("penTool").style.backgroundColor =
      inactiveButtonColor;
    document.getElementById("eraseTool").style.backgroundColor =
      activeButtonColor;
    document.getElementById("edit_edge").style.backgroundColor =
      inactiveButtonColor;
  } else {
    document.getElementById("edit").style.backgroundColor = inactiveButtonColor;
    document.getElementById("penTool").style.backgroundColor =
      inactiveButtonColor;
    //document.getElementById("eraseTool").style.backgroundColor = inactiveButtonColor;
    document.getElementById("edit_edge").style.backgroundColor =
      inactiveButtonColor;
  }
}
export {
  updateEventMode,
  removeAllHoverComponents,
  updateEventModeInterior,
  updateButtonColor,
};
