export const FLOORKEY_DETAILS = {
  doors: {
    levels: [],
    pols: [],
    structure: [],
    structure: [],
    type: [],
  },
  partition: {
    levels: [],
    pols: [],
    structure: [],
    type: [],
  },
  rooms: {
    levels: [],
    pols: [],
    structure: [],
    type: [],
  },
  walls: {
    levels: [],
    pols: [],
    structure: [],
    type: [],
  },
  windows: {
    levels: [],
    pols: [],
    structure: [],
    type: [],
  },
}