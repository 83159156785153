"use strict";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import {
  computeAreaTriangle3D,
  computeVolumeWall,
  computeVolumeMass,
  computeVolumeStairsLayer,
  computeVolumeStairsLayerType,
  computeVolumeFloor,
  computeAreaWall,
  computeAreaForMass,
  computeAreaFloor,
  computeAreaSlab,
  computeAreaColumn,
  computeAreaBeam,
} from "./areaFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { isMeshThrowAway, JSONToCSVConvertor } from "../modules/extrafunc.js";
import objectPropertiesView from "../modules/objectProperties/objectPropertiesView.js";
import { getFaceVerticesFromFace } from "./brepOperations.js";

var materialScheduleViewSplit = null;
materialScheduleViewSplit = (function () {
  var structureCollection = StructureCollection.getInstance();
  var _materialScheduleData = null;
  let matSchedule = null;

  const init = () => {
    structureCollection = StructureCollection.getInstance();
  };

  var getStrucuters = function () {
    return structureCollection.getStructures();
  };

  var getLevelsInStructure = function (id) {
    let levels = getStrucuters()[id].getAllLevels();
    console.log(levels);
    return getStrucuters()[id].getAllLevels();
  };

  let _assignProjectUnits = function (unit) {
    if (store.$scope.units_type.value === "feet-inches") {
      if (unit === "Square metres") return "Sq-Ft.";
      if (unit === "Cubic metres") return "Cubic Ft.";
    } else if (store.$scope.units_type.value === "inches") {
      if (unit === "Square metres") return "Sq. Inches.";
      if (unit === "Cubic metres") return "Cubic Inches";
    } else {
      return unit;
    }
    // else if ($scope.units_type.value === "millimeter"){
    //     return "Litres";
    // }
    // else if ($scope.units_type.value === "centimeter"){
    //     return "Litres";
    // }
    // else if ($scope.units_type.value === "meters"){
    //     return "Litres";
    // }
  };

  let _findIntersectingObjects = function (roof, floors) {
    let intersectingFloors = [];
    if (floors.length > 0) {
      for (let t = 0; t < floors.length; t++) {
        let floor = floors[t];
        if (roof.mesh.intersectsMesh(floor.mesh)) {
          intersectingFloors.push(floor);
        }
      }
    }
    // return [];
    return intersectingFloors;
  };

  let checkVertexInsideFace = (v, face, mesh) => {
    let vertices = getFaceVerticesFromFace(face, mesh, BABYLON.Space.WORLD);
    let anglesum = 0;
    for (let i = 0; i < vertices.length; i++) {
      let p1 = new BABYLON.Vector3(
        vertices[i][0] - v[0],
        vertices[i][1] - v[1],
        vertices[i][2] - v[2]
      );
      let p2 = new BABYLON.Vector3(
        vertices[(i + 1) % vertices.length][0] - v[0],
        vertices[(i + 1) % vertices.length][1] - v[1],
        vertices[(i + 1) % vertices.length][2] - v[2]
      );
      let m1 = p1.length();
      let m2 = p2.length();
      let costheta;
      if (Math.abs(m1 * m2) < 1e-5) {
        anglesum = Math.PI * 2; /* We are on a node, consider this inside */
      } else {
        costheta = (p1.x * p2.x + p1.y * p2.y + p1.z * p2.z) / (m1 * m2);
      }
      anglesum += Math.acos(costheta);
    }
    if (Math.abs(Math.abs(anglesum) - Math.PI * 2) < 1e-3) {
      return true;
    } else {
      return false;
    }
  };

  let faceIntersection = (face1, face2, mesh1, mesh2) => {
    let v = getFaceVerticesFromFace(face1, mesh1, BABYLON.Space.WORLD);
    for (let i = 0; i < v.length; i++) {
      let intersectionStatus = checkVertexInsideFace(v[0], face2, mesh2);
    }
  };

  let meshFaceIntersection = function (obj1, obj2) {
    let brep1 = obj1.brep;
    let brep2 = obj2.brep;
    if (brep1 && brep2) {
      let faces1 = brep1.getFaces();
      let faces2 = brep2.getFaces();
      for (let i = 0; i < faces1.length; i++) {
        for (let j = 0; j < faces2.length; j++) {
          faceIntersection(faces1, faces2, obj1.mesh, obj2.mesh);
        }
      }
    }
  };

  const handleIntersectingObjects = function (
    wall,
    p,
    coreIndex,
    material,
    thickness,
    intersectingFloors,
    intersectingWalls,
    intersectingMasses,
    type
  ) {
    // Subtract the floor volume/area for all intersecting floors with the current roof to calculate the BOM accurately
    let tempVolume = 0;
    if (wall.type !== "Roof") {
      return tempVolume;
    }

    if (p < coreIndex) {
      for (let q = 0; q < intersectingFloors.length; q++) {
        if (type === "volume") {
          tempVolume += _calculateVolume(
            material,
            intersectingFloors[q].mesh,
            thickness,
            "floor"
          );
        } else if (type === "area") {
          tempVolume += _calculateAreaLayer(
            material,
            intersectingFloors[q].mesh,
            thickness,
            "floor"
          );
        }
        // str_object.components.roof[materialType][materialId] -= vol;
      }
      // Subtract the walls area which are above the roof for the layers exposed externally
      for (let q = 0; q < intersectingWalls.length; q++) {
        if (intersectingWalls[q].mesh.position.y > wall.mesh.position.y) {
          if (type === "volume") {
            tempVolume += _calculateVolume(
              material,
              intersectingWalls[q].mesh,
              thickness,
              "roof"
            );
          } else if (type === "area") {
            tempVolume += _calculateAreaLayer(
              material,
              intersectingWalls[q].mesh,
              thickness,
              "roof"
            );
          }
        }
      }
    }
    // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
    if (p > coreIndex) {
      for (let q = 0; q < intersectingWalls.length; q++) {
        if (intersectingWalls[q].mesh.position.y < wall.mesh.position.y) {
          if (type === "volume") {
            tempVolume += _calculateVolume(
              material,
              intersectingWalls[q].mesh,
              thickness,
              "roof"
            );
          } else if (type === "area") {
            tempVolume += _calculateAreaLayer(
              material,
              intersectingWalls[q].mesh,
              thickness,
              "roof"
            );
          }
        }
      }
      for (let q = 0; q < intersectingMasses.length; q++) {
        if (intersectingMasses[q].mesh.position.y < wall.mesh.position.y) {
          if (type === "volume") {
            tempVolume += _calculateVolume(
              material,
              intersectingMasses[q].mesh,
              thickness,
              "roof"
            );
          } else if (type === "area") {
            tempVolume += _calculateAreaLayer(
              material,
              intersectingMasses[q].mesh,
              thickness,
              "roof"
            );
          }
        }
      }
    }
    return tempVolume;
  };

  let _calculateArea = function (material, mesh, subMesh = null) {
    if (subMesh) {
      let indices = mesh.getIndices();
      let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      let sIndexData = [];
      let surfaceArea = 0;
      for (let i = 0; i < subMesh.indexCount; i += 3) {
        let px = verData[indices[subMesh.indexStart + i] * 3];
        let py = verData[indices[subMesh.indexStart + i] * 3 + 1];
        let pz = verData[indices[subMesh.indexStart + i] * 3 + 2];
        let v1 = new BABYLON.Vector3(px, py, pz);
        px = verData[indices[subMesh.indexStart + i + 1] * 3];
        py = verData[indices[subMesh.indexStart + i + 1] * 3 + 1];
        pz = verData[indices[subMesh.indexStart + i + 1] * 3 + 2];
        let v2 = new BABYLON.Vector3(px, py, pz);
        px = verData[indices[subMesh.indexStart + i + 2] * 3];
        py = verData[indices[subMesh.indexStart + i + 2] * 3 + 1];
        pz = verData[indices[subMesh.indexStart + i + 2] * 3 + 2];
        let v3 = new BABYLON.Vector3(px, py, pz);
        let area = computeAreaTriangle3D(v1, v2, v3);
        if (area) {
          surfaceArea += computeAreaTriangle3D(v1, v2, v3);
        }
        sIndexData.push(v1);
        sIndexData.push(v2);
        sIndexData.push(v3);
      }
      return parseInt(DisplayOperation.convertToDefaultArea(surfaceArea));
    } //calculate the area for material for given mesh
    return 0;
  };

  let _calculateVolume = function (material, mesh, thickness, type) {
    let volume = 0;
    if (type === "wall") {
      if (thickness) {
        volume = computeVolumeWall(mesh, thickness);
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    } else if (type === "mass") {
      volume = computeVolumeMass(mesh, thickness);
    } else if (type === "staircase") {
      if (thickness) {
        volume = computeVolumeStairsLayer(mesh, thickness);
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    } else if (type === "staircaseRiser") {
      if (thickness) {
        volume = computeVolumeStairsLayerType(mesh, thickness, "Riser");
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    } else if (type === "staircaseTread") {
      if (thickness) {
        volume = computeVolumeStairsLayerType(mesh, thickness, "Tread");
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    } else if (type === "staircaseSide") {
      if (thickness) {
        volume = computeVolumeStairsLayerType(mesh, thickness, "Side");
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    } else if (type === "floor") {
      if (thickness) {
        volume = computeVolumeFloor(mesh, thickness);
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    } else if (type === "roof") {
      if (thickness) {
        volume = computeVolumeFloor(mesh, thickness);
      } else {
        volume = computeVolumeMass(mesh, thickness);
      }
    }
    // return parseInt(volume);
    // return parseInt(Math.ceil(parseFloat(DisplayOperation.convertToDefaultVolume(volume))));
    // return Math.abs(Math.round(parseFloat(DisplayOperation.convertToDefaultVolume(volume))*100)/100);
    return parseFloat(DisplayOperation.convertToDefaultVolume(volume));
  };

  let _calculateAreaLayer = function (material, mesh, thickness, type) {
    let area = 0;
    if (type === "wall") {
      area = computeAreaWall(mesh, thickness);
    } else if (type === "mass") {
      area = computeAreaForMass(mesh, thickness);
    } else if (type === "staircase") {
      area = computeAreaForMass(mesh, thickness);
    } else if (type === "staircaseRiser") {
      area = computeVolumeStairsLayerType(mesh, thickness, "Riser", "Area");
    } else if (type === "staircaseTread") {
      area = computeVolumeStairsLayerType(mesh, thickness, "Tread", "Area");
    } else if (type === "staircaseSide") {
      area = computeVolumeStairsLayerType(mesh, thickness, "Side", "Area");
    } else if (type === "floor") {
      area = computeAreaFloor(mesh, thickness);
    } else if (type === "roof") {
      area = computeAreaFloor(mesh, thickness);
    }
    // return parseInt(volume);
    // return parseInt(Math.ceil(parseFloat(DisplayOperation.convertToDefaultArea(area))));
    return Math.abs(
      Math.round(
        parseFloat(DisplayOperation.convertToDefaultArea(area)) * 100
      ) / 100
    );
  };

  let _calculateStuds = function (material, mesh, params, factor, thickness, type) {
    let len, br;
    let studNo = 0;

    if (params){
      len = DisplayOperation.getOriginalDimension(params.depth);
      br = DisplayOperation.getOriginalDimension(params.breadth);
      if (parseFloat(len) < parseFloat(br)){
        let temp = br;
        br = len;
        len = temp;
      }
      let oDim = DisplayOperation.convertDimensionTo(len);
      studNo = parseFloat(oDim.inch)/factor + 1;
      if (mesh.children){
        if (mesh.children.length > 0){
          studNo += 4*mesh.children.length;
        }  
      }
    }
    return studNo;
  };

  const calculateComponentParameters = (obj) => {
    let type = obj.type;
    let params;
    if (obj.type === "Roof") {
      if (obj.slabType) {
        params = objectPropertiesView.getMeshDimensions(obj.mesh);
        params.area = DisplayOperation.convertToDefaultArea(
          computeAreaSlab(obj.mesh)
        );
      }
    } else if (obj.type === "Mass") {
      if (obj.massType) {
        if (obj.massType === "Column") {
          params = objectPropertiesView.getMeshDimensions(obj.mesh);
          params.area = DisplayOperation.convertToDefaultArea(
            computeAreaColumn(obj.mesh)
          );
        } else if (obj.massType === "Beam") {
          params = objectPropertiesView.getMeshDimensions(obj.mesh);
          params.area = DisplayOperation.convertToDefaultArea(
            computeAreaBeam(obj.mesh)
          );
        } else {
          params = objectPropertiesView.getMeshDimensions(obj.mesh);
          params.area = DisplayOperation.convertToDefaultArea(
            computeAreaBeam(obj.mesh)
          );
        }
      } else {
        params = objectPropertiesView.getMeshDimensions(obj.mesh);
        params.area = DisplayOperation.convertToDefaultArea(
          computeAreaBeam(obj.mesh)
        );
      }
    } else {
      params = objectPropertiesView.getMeshDimensions(obj.mesh);
    }
    if (params) {
      if (params.depth && params.breadth) {
        if (parseFloat(params.depth) > parseFloat(params.breadth)) {
          [params.depth, params.breadth] = [params.breadth, params.depth];
        }
      }
      if (params.area){
        params.area = params.area.toFixed(store.$scope.unit_tolerance.value);
      }
    }    
    return params;
  };

  const generateBoQObjects = (objects, str_object, fw = null) => {
    for (let m = 0; m < objects.length; m++) {
      let wall = objects[m];
      let objType = wall.type;
      let oType = wall.type.toLowerCase();
      let oLabel = wall.mesh.room_type;
      if (wall.type === "Roof") {
        objType = wall.slabType;
      } else if (wall.type === "Mass") {
        objType = wall.massType;
      }
      if (isMeshThrowAway(wall.mesh)) {
        continue;
      }
      try {
        wall.setThickness();
      } catch (err) {
        console.log(err);
      }
      if (store.selectionStack.length > 0) {
        if (wall.mesh.state !== "on") continue;
      }
      if (!wall.properties) continue;
      let layers;
      if (wall.mesh.isAnInstance) {
        if (wall.mesh.sourceMesh.getSnaptrudeDS().properties) {
          if (wall.mesh.sourceMesh.getSnaptrudeDS().properties) {
            if (wall.mesh.sourceMesh.getSnaptrudeDS().properties._components) {
              layers =
                wall.mesh.sourceMesh.getSnaptrudeDS().properties._components
                  ._layers;
            }
          }
        }
      } else {
        if (wall.properties) {
          if (wall.properties._components) {
            layers = wall.properties._components._layers;
          }
        }
      }
      if (!layers) continue;
      let intersectingFloors,
        intersectingWalls,
        intersectingMasses = [];
      let coreIndex = null;
      if (wall.type) {
        if (wall.type === "Roof") {
          intersectingFloors = _findIntersectingObjects(wall, fw.floors);
          intersectingWalls = _findIntersectingObjects(wall, fw.walls);
          intersectingMasses = _findIntersectingObjects(wall, fw.masses);
        }
      }
      for (let p = 0; p < layers.length; p++) {
        if (layers[p].core) {
          coreIndex = p;
          break;
        }
      }

      for (let p = 0; p < layers.length; p++) {
        let layer = layers[p];
        let materialType = layer.value;
        let thickness = layer.thickness;
        if (layer.core) {
          thickness = null;
        }
        let propName = "";
        if (layer.stairProp) {
          propName = layer.stairProp;
        }
        if (oType.toLowerCase() === "staircase") {
          oType = "staircase" + layer.stairProp;
        }
        let materialId = layer.subtype;
        let material = layer.value;
        let quantity = layer.quantity;
        if (!materialId) materialId = "Default";
        // if (material === "Paint") continue;
        if (quantity) {
          if (str_object.components[materialType] === undefined) {
            str_object.components[materialType] = {};
          }
          if (str_object.components[materialType][materialId] === undefined) {
            str_object.components[materialType][materialId] = {};
          }
          if (
            str_object.components[materialType][materialId][objType] ===
            undefined
          ) {
            str_object.components[materialType][materialId][objType] = {};
          }
          if (
            str_object.components[materialType][materialId][objType][oLabel] ===
            undefined
          ) {
            str_object.components[materialType][materialId][objType][oLabel] =
              {};
          }
          if (
            !str_object.components[materialType][materialId][objType][oLabel][
              "quant"
            ]
          ) {
            str_object.components[materialType][materialId][objType][oLabel][
              "quant"
            ] = 0;
          }
          if (quantity.toLowerCase() === "volume") {
            str_object.components[materialType][materialId][objType][oLabel][
              "quant"
            ] += _calculateVolume(material, wall.mesh, thickness, oType);
            let intersectingVolume = handleIntersectingObjects(
              wall,
              p,
              coreIndex,
              material,
              thickness,
              intersectingFloors,
              intersectingWalls,
              intersectingMasses,
              "volume"
            );
            if (
              str_object.components[materialType][materialId][objType][oLabel][
                "quant"
              ] > intersectingVolume
            ) {
              str_object.components[materialType][materialId][objType][oLabel][
                "quant"
              ] -= intersectingVolume;
            } else {
              str_object.components[materialType][materialId][objType][oLabel][
                "quant"
              ] = 0;
            }

            str_object.components[materialType][materialId][objType][oLabel][
              "dimns"
            ] = calculateComponentParameters(wall);
            str_object.components[materialType][materialId][objType][oLabel][
              "unit"
            ] = layer.unit;
          } else if (quantity.toLowerCase() === "area") {
            str_object.components[materialType][materialId][objType][oLabel][
              "quant"
            ] += _calculateAreaLayer(material, wall.mesh, thickness, oType);

            let intersectingArea = handleIntersectingObjects(
              wall,
              p,
              coreIndex,
              material,
              thickness,
              intersectingFloors,
              intersectingWalls,
              intersectingMasses,
              "area"
            );
            if (
              str_object.components[materialType][materialId][objType][oLabel][
                "quant"
              ] > intersectingArea
            ) {
              str_object.components[materialType][materialId][objType][oLabel][
                "quant"
              ] -= intersectingArea;
            } else {
              str_object.components[materialType][materialId][objType][oLabel][
                "quant"
              ] = 0;
            }

            str_object.components[materialType][materialId][objType][oLabel][
              "dimns"
            ] = calculateComponentParameters(wall);
            str_object.components[materialType][materialId][objType][oLabel][
              "unit"
            ] = layer.unit;
          } else if (quantity.toLowerCase() === "count"){

            let objCompParams = calculateComponentParameters(wall);
            let factor = 16;
            str_object.components[materialType][materialId][objType][oLabel][
              "quant"
            ] += _calculateStuds(material, wall.mesh, objCompParams, factor, thickness, oType);
            str_object.components[materialType][materialId][objType][oLabel][
              "dimns"
            ] = objCompParams;
            str_object.components[materialType][materialId][objType][oLabel][
              "unit"
            ] = layer.unit;

          }
        }
      }
    }
    return str_object;
  };

  var generateMatSchedule = function () {
    let structures = getStrucuters();
    for (let k = 0; k < Object.keys(structures).length; k++) {
      let str_key = Object.keys(structures)[k];
      if (str_key === "default") {
        continue;
      }
      let levels = getLevelsInStructure(str_key);
      let str_object = {
        structure_key: str_key,
        structure_name: "str-" + str_key.split("_")[1],
        components: {},
      };

      for (let j = 0; j < Object.keys(levels).length; j++) {
        let level = levels[Object.keys(levels)[j]];
        let fw = level.flyweight;

        if (fw.walls.length > 0) {
          generateBoQObjects(fw.walls, str_object);
        }
        if (fw.floors.length > 0) {
          generateBoQObjects(fw.floors, str_object);
        }
        if (fw.roofs.length > 0) {
          generateBoQObjects(fw.roofs, str_object, fw);
        }
        if (fw.masses.length > 0) {
          generateBoQObjects(fw.masses, str_object);
        }
        if (fw.staircases.length > 0) {
          generateBoQObjects(fw.staircases, str_object);
        }
      }
      _materialScheduleData = str_object;
    }
    let data = {};
    let component_obj = {};
    let components = _materialScheduleData.components;
    for (let i = 0; i < Object.keys(components).length; i++) {
      let comp_key = Object.keys(components)[i];
      let component = components[comp_key];
      for (let j = 0; j < Object.keys(component).length; j++) {
        let material_key = Object.keys(component)[j];
        let mat_sub_types = component[material_key];
        for (let k = 0; k < Object.keys(mat_sub_types).length; k++) {
          let mat_sub_type_key = Object.keys(mat_sub_types)[k];
          if (mat_sub_type_key === "unit") continue;
          let mat_sub_type = mat_sub_types[mat_sub_type_key];
          let unit = mat_sub_types["unit"];
          unit = _assignProjectUnits(unit);

          if (component_obj[comp_key]) {
            component_obj[comp_key].push({
              component: comp_key,
              material: material_key,
              material_type: mat_sub_type_key,
              quantity: mat_sub_type,
              unit: unit,
            });
          } else {
            component_obj[comp_key] = [
              {
                component: comp_key,
                material: material_key,
                material_type: mat_sub_type_key,
                quantity: mat_sub_type,
                unit: unit,
              },
            ];
          }
        }
      }
    }

    store.$scope.materialScheduleData = component_obj;
    _materialScheduleData = component_obj;
    console.log("Split BoQ data-------", component_obj);
    exportCSV(_materialScheduleData);
    return _materialScheduleData;
  };

  let exportCSV = function (_materialScheduleData) {
    // let data = _.flatMapDeep(Object.values(_materialScheduleData));
    let data = [];
    let keys = Object.keys(_materialScheduleData);
    for (let i = 0; i < keys.length; i++) {
      data.push({
        Material: keys[i].toUpperCase(),
        "Material Type": "",
        Object: "",
        Component: "",
        Length: "",
        Breadth: "",
        Height: "",
        Area: "",
        Volume: "",
        Quantity: "",
        Unit: "",
      });
      let mtypes = Object.keys(_materialScheduleData[keys[i]]);
      for (let j = 0; j < mtypes.length; j++) {
        let mType = _materialScheduleData[keys[i]][mtypes[j]];
        data.push({
          Material: "",
          "Material Type": mType.material.toUpperCase(),
          Object: "",
          Component: "",
          Length: "",
          Breadth: "",
          Height: "",
          Area: "",
          Volume: "",
          Quantity: "",
          Unit: "",
        });
        let comps = Object.keys(
          _materialScheduleData[keys[i]][mtypes[j]].quantity
        );
        let comp = _materialScheduleData[keys[i]][mtypes[j]].material_type;
        data.push({
          Material: "",
          "Material Type": "",
          Object: comp.toUpperCase(),
          Component: "",
          Length: "",
          Breadth: "",
          Height: "",
          Area: "",
          Volume: "",
          Quantity: "",
          Unit: "",
        });

        for (let k = 0; k < comps.length; k++) {
          let obj =
            _materialScheduleData[keys[i]][mtypes[j]]["quantity"][comps[k]];
          if (obj.quant){
            if (typeof obj.quant === 'number'){
              obj.quant = obj.quant.toFixed(store.$scope.unit_tolerance.value);
            }
          }
          console.log(obj.dimns);
          data.push({
            Material: "",
            "Material Type": "",
            Object: "",
            Component: comps[k].toUpperCase(),
            Length: obj.dimns["breadth"],
            Breadth: obj.dimns["depth"],
            Height: obj.dimns["height"],
            Area: obj.dimns["area"],
            Volume: obj.dimns["volume"],
            Quantity: obj.quant,
            Unit: obj.unit,
          });
        }
      }
    }

    JSONToCSVConvertor(data, "Materials", true);
  };
  return {
    getStrucuters: function () {
      return getStrucuters();
    },

    generateMatSchedule: function () {
      return generateMatSchedule();
    },
    exportCSV: function (matData) {
      return exportCSV(matData);
    },
    init,
    materialScheduleData: _materialScheduleData,
  };
})();
export { materialScheduleViewSplit };
