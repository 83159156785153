import styled from "styled-components";
import { colors } from "../../themes/constant";
import OuterCard from "../Card/Outer";
import CloseButton from "../../assets/icons/close.svg";
export const ICON = {
    CloseButton
}
export const Card = styled(OuterCard)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

export const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

export const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

export const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  min-height: 200px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

export const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

export const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 100,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

export const modalStylesTeam = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 100,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
    overflow: "initial"
  },
};

export const StyledRow = styled.div`
  display: flex;
  flex-direction: col;
  padding: 0px 10px;
  align-items: center;
`
export const StyledCol = styled.div`
  flex: 1;
  padding: 10px;
  strong{
    overflow-wrap: anywhere;
  }
`
export const recentBoxStyle = {
  boxStyle: {
    border: "none",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: colors.primeBlack,
    width: "100%",
  },
  customDropdownStyle: {
    position: "absolute",
    top: "-6.25rem",
    left: 0,
    right: 0,
    borderRadius: "0.313rem",
    paddingLeft: 0,
  },
};

export const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
`;
export const uneditableContainerStyle = {
  fontWeight: 700,
  overflow: "hidden",
  textOverflow: "ellipsis"
}