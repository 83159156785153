import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import authService from "../../../../services/auth.service";

export const findFolderRecursive = (folders, folderID) => {
    let folderFound = null;
    if (!Array.isArray(folders)) return folderFound;
    folderFound = folders.find(folder => folder.id === folderID);
    if (folderFound) return folderFound;
    for (let folder of folders) {
        folderFound = findFolderRecursive(folder.folders, folderID);
        if (folderFound) return folderFound;
    }
    return folderFound;
}

export const generatePathToFolder = (folder, folderID, path = []) => {
    if (!folder.isDummy) path.push({ name: folder.name, folderID: folder.id });

    if (!folder.isDummy && folder.id == folderID) return { found: true, path };

    let found = false;

    if (folder.folders) {
        for (const item of folder.folders) {
            const res = generatePathToFolder(item, folderID, path);
            if (res.found) {
                found = res.found;
                path = res.path;
                break;
            }
        }
    }

    if (!found) path.pop();

    return { found, path }
}

const rightJoin = (localFiles, serverFiles, uniqKey) => {
    const lsiFiles = _.intersectionWith(serverFiles, localFiles, (sFile, lFile) => {
        if(sFile[uniqKey] === lFile[uniqKey]) {
            if(lFile.folders != null) sFile.folders = lFile.folders;
            if(lFile.projects != null) sFile.projects = lFile.projects;
            return true;
        }
        return false;
    })
    const rightJoined = _.uniqBy(_.unionBy(lsiFiles, serverFiles), uniqKey);
    return rightJoined;
}

const buildFolderTree = (fileSystem, parents) => {
    fileSystem = _.cloneDeep(fileSystem)
    parents = _.cloneDeep(parents);
    let folders = fileSystem.folders;
    let parent = fileSystem;
    while (parents.length > 0) {
        parent = parents.pop();
        const folder = findFolderRecursive(folders, parent.id);
        if (folder) {
            if (!folder.folders) folder.folders = [];
            if (!folder.projects) folder.projects = [];
            folders = folder.folders;
            parent = folder;
        } else {
            parent.folders = [];
            parent.projects = [];
            folders.push(parent);
            folders = parent.folders;
        }
    }
    return { fileSystem, parentFolder: parent }
}
const initialState = {
    teamlist: [],
    selectedTeam: null,
    personalWorkspace: {
        fileSystem: {
            folders: [],
            projects: []
        }
    }
}

export const teamsSlice = createSlice({
    name: "teams",
    initialState,
    reducers: {
        resetTeams: () => initialState,
        addTeams: (state, action) => {
            const teams = action.payload;
            state.teamlist = teams.map(team => {
                return {
                    ...team,
                    projects: [],
                    activeUsers: [],
                    roleBasedPermissions: null,
                    materials: []
                }
            });
        },
        addTeam: (state, action) => {
            const team = action.payload;
            state.teamlist.push({
                ...team,
                projects: [],
                activeUsers: []
            })
        },
        selectTeam: (state, action) => {
            const teamId = action.payload;
            if (teamId === null) state.selectedTeam = null;
            else state.selectedTeam = state.teamlist.find((team) => team.id === teamId);
        },
        addMembersRedux: (state, action) => {
            const { members, teamId } = action.payload;
            const newTeamList = state.teamlist.map((team) => {
                if (team.id === teamId) {
                    team.members = _.uniqBy(_.concat(team.members, members), 'email')
                }
                return team;
            });
            state.teamlist = newTeamList;
        },
        addProjectsToTeam: (state, action) => {
            const { projects, teamId, isNewlyCreatedProject, offset } = action.payload;
            const newTeamList = state.teamlist.map((team) => {
                if (team.id === teamId) {
                    if (offset === 0) {
                        team.projects = [...projects];
                    } else {
                        if (isNewlyCreatedProject) {
                            team.projects = [...projects, ...team.projects]
                        } else {
                            team.projects = [...team.projects, ...projects]
                        }
                    }
                    team.projects = _.uniqBy(team.projects, 'key')
                    state.selectedTeam = team;
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        updateProjectRedux: (state, action) => {
            const { floorkey, teamId, newValues } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.projects = team.projects.map(project => {
                        if (project.key === floorkey) {
                            project = {
                                ...project,
                                ...newValues
                            }
                        }
                        return project;
                    })
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        deleteProjectRedux: (state, action) => {
            const { floorkey } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                team.projects = team.projects.filter((project) => {
                    return project.key !== floorkey
                })
                if (team.id === state.selectedTeam?.id) {
                    state.selectedTeam = team;
                }
                return { ...team }
            });
            state.teamlist = newTeamList;
        },
        clearTeamProjects: (state, action) => {
            const teamId = action.payload;

            const newTeamList = state.teamlist.map((team) => {
                if (team.id === teamId) {
                    team.projects = []
                    state.selectedTeam = team;
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        updateTeamMemberSettingRedux: (state, action) => {
            const { teamId, teammemberId, newSetting } = action.payload;
            const newTeamlist = state.teamlist.map(team => {
                if (team.id === teamId) {
                    if(newSetting.delete){
                        team.members = team.members.filter(member => member.team_member_id !== teammemberId);
                    }
                    team.members = team.members.map(member => {
                        if (member.team_member_id === teammemberId) {
                            if (newSetting.role) member.role = newSetting.role;
                        }
                        return member;
                    })
                }
                return team;
            })
            state.teamlist = newTeamlist;
        },
        renameTeamUI: (state, action) => {
            const { teamId, teamname } = action.payload;

            const newTeamList = state.teamlist.map((team) => {
                if (team.id === teamId) {
                    team.title = teamname
                }
                return team;
            })
            if (state.selectedTeam?.id === teamId) state.selectedTeam.title = teamname;
            state.teamlist = newTeamList;
        },
        leaveTeamUI: (state, action) => {
            const { teamId } = action.payload;
            const newTeamList = state.teamlist.filter((team) => {
                return team.id !== teamId;
            })
            if (state.selectedTeam?.id === teamId) state.selectedTeam = null;
            state.teamlist = newTeamList;
        },
        acceptTeamInvite: (state, action) => {
            const { teamId } = action.payload;
            const user = authService.getCurrentUser();
            const newTeamList = state.teamlist.map((team) => {
                if (team.id === teamId) {
                    team.me.status = "approved";
                    team.members = team.members.map(teammember => {
                        if (teammember.email === user.email) {
                            teammember.status = "approved"
                        }
                        return teammember;
                    })
                }
                return {
                    ...team
                }
            })
            state.teamlist = newTeamList
        },
        addRoleBasedPermissionsRedux: (state, action) => {
            const { teamId, roleBasedPermissions } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    const roleBasedPermissionsSanitized = _.transform(roleBasedPermissions, (result, value, key) => {
                        result[_.lowerCase(key)] = value;
                    })
                    team.roleBasedPermissions = roleBasedPermissionsSanitized;
                    if (team.roleBasedPermissions[team.role].view_library === false) {
                        team.children = team.children.filter(children => children.title !== "Libraries")
                    }
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        updateRoleBasedPermissionsRedux: (state, action) => {
            const { teamId, role, newSettings } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    const updatedRoleBasedPermissions = {
                        ...team.roleBasedPermissions,
                        [role]: newSettings
                    }
                    team.roleBasedPermissions = updatedRoleBasedPermissions;
                    state.selectedTeam = team;
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        addMaterialsRedux: (state, action) => {
            const { teamId, materials } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.materials = materials;
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        uploadTeamMaterialsRedux: (state, action) => {
            const { teamId, materials, elementId } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    let insertionIndex = -1;
                    if (elementId) insertionIndex = team.materials.findIndex(element => element.id === elementId);
                    const newMaterials = team.materials || [];
                    newMaterials.splice(insertionIndex + 1, 0, ...materials);
                    team.materials = newMaterials;
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        updateTeamMaterialRedux: (state, action) => {
            const { teamId, updatedMaterial } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.materials = team.materials.map((material => {
                        if (material.id === updatedMaterial.id) {
                            return updatedMaterial;
                        }
                        return material;
                    }))
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        addTeamObjectsRedux: (state, action) => {
            const { teamId, objects, type } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.objects = {
                        [type]: objects
                    };
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        uploadTeamObjectsRedux: (state, action) => {
            const { teamId, objects, type, elementId } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    let insertionIndex = -1;
                    if (elementId) insertionIndex = team.objects[type].findIndex(element => element.id === elementId);
                    const newObjects = team.objects[type] || [];
                    newObjects.splice(insertionIndex + 1, 0, ...objects);
                    team.objects[type] = newObjects;
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        updateTeamObjectRedux: (state, action) => {
            const { teamId, updatedObject, type } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.objects[type] = team.objects[type].map((object => {
                        if (object.id === updatedObject.id) {
                            return updatedObject;
                        }
                        return object;
                    }))
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        deleteTeamMaterialRedux: (state, action) => {
            const { teamId, elementId } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.materials = team.materials.filter(material => material.id !== elementId)
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        deleteTeamObjectRedux: (state, action) => {
            const { teamId, elementId, type } = action.payload;
            const newTeamList = state.teamlist.map(team => {
                if (team.id === teamId) {
                    team.objects[type] = team.objects[type].filter(obj => obj.id !== elementId)
                }
                return team;
            })
            state.teamlist = newTeamList;
        },
        initializeFileSystemRedux: (state, action) => {
            let { teamID, folders, projects } = action.payload;
            if (teamID) {
                const newTeamlist = state.teamlist.map(team => {
                    if (team.id === teamID) {
                        team.fileSystem = {
                            folders,
                            projects
                        }
                        team.children[0].children = folders;
                    }
                    return team;
                })
                state.teamlist = newTeamlist;
            } else {
                state.personalWorkspace.fileSystem.folders = folders;
                state.personalWorkspace.fileSystem.projects = projects;
            }
        },
        addFoldersRedux: (state, action) => {
            let { teamID, parentFolderID, folders, projects, parents, useParentFolderID, append } = action.payload;
            if (teamID) {
                const newTeamlist = state.teamlist.map(team => {
                    if (team.id === teamID) {
                        let fileSystem = team.fileSystem;
                        let parentFolder = null;
                        if (useParentFolderID) {
                            if (parentFolderID == null || parentFolderID === "root") {
                                parentFolder = fileSystem;
                            } else {
                                parentFolder = findFolderRecursive(fileSystem.folders, parentFolderID);
                            }
                        } else {
                            const tree = buildFolderTree(team.fileSystem, parents);
                            fileSystem = tree.fileSystem;
                            parentFolder = tree.parentFolder;
                        }
                        if(append){
                            parentFolder.folders = _.concat(parentFolder.folders || [], folders);
                            parentFolder.projects = _.concat(parentFolder.projects || [], projects);
                        }else{
                            parentFolder.folders = rightJoin(parentFolder.folders || [], folders, "id");
                            parentFolder.projects = rightJoin(parentFolder.projects || [], projects, "key");
                        }
                        team.fileSystem = fileSystem;
                    }
                    return team;
                })
                state.teamlist = newTeamlist;

            } else {
                let fileSystem = state.personalWorkspace.fileSystem;
                let parentFolder = null;
                if (useParentFolderID) {
                    if (parentFolderID == null || parentFolderID === "root") {
                        parentFolder = fileSystem;
                    } else {
                        parentFolder = findFolderRecursive(fileSystem.folders, parentFolderID);
                    }
                } else {
                    const tree = buildFolderTree(state.personalWorkspace.fileSystem, parents);
                    fileSystem = tree.fileSystem;
                    parentFolder = tree.parentFolder;
                }
                if(append){
                    parentFolder.folders = _.concat(parentFolder.folders || [], folders);
                    parentFolder.projects = _.concat(parentFolder.projects || [], projects);
                }else{
                    parentFolder.folders = rightJoin(parentFolder.folders || [], folders, "id");
                    parentFolder.projects = rightJoin(parentFolder.projects || [], projects, "key");
                }
                state.personalWorkspace.fileSystem = fileSystem
            }
        },
        removeFolderRedux: (state, action) => {
            const { teamID, parentFolderID, folderID, projectKey } = action.payload;
            if (teamID) {
                const newTeamlist = state.teamlist.map(team => {
                    if (team.id === teamID) {
                        let fileSystem = team.fileSystem;
                        let parentFolder = null;
                        if (parentFolderID == null || parentFolderID === "root") {
                            parentFolder = fileSystem;
                        } else {
                            parentFolder = findFolderRecursive(fileSystem.folders, parentFolderID);
                        }
                        if (folderID) parentFolder.folders = parentFolder.folders.filter(folder => folder.id != folderID);
                        if (projectKey) parentFolder.projects = parentFolder.projects.filter(project => project.key != projectKey);
                        team.fileSystem = fileSystem;
                    }
                    return team;
                })
                state.teamlist = newTeamlist;
            } else {
                let fileSystem = state.personalWorkspace.fileSystem;
                let parentFolder = null;
                if (parentFolderID == null || parentFolderID === "root") {
                    parentFolder = fileSystem;
                } else {
                    parentFolder = findFolderRecursive(fileSystem.folders, parentFolderID);
                }
                if (folderID) parentFolder.folders = parentFolder.folders.filter(folder => folder.id != folderID);
                if (projectKey) parentFolder.projects = parentFolder.projects.filter(project => project.key != projectKey);
                state.personalWorkspace.fileSystem = fileSystem;
            }
        },
        updateFolderRedux: (state, action) => {
            const { teamID, folderID, parentFolderID, updatedProperties, projectKey } = action.payload;
            if (teamID) {
                const newTeamlist = state.teamlist.map(team => {
                    if (team.id === teamID) {
                        let fileSystem = team.fileSystem;
                        let parentFolder = null;
                        if (parentFolderID == null || parentFolderID === "root") {
                            parentFolder = fileSystem;
                        } else {
                            parentFolder = findFolderRecursive(fileSystem.folders, parentFolderID);
                        }
                        if (folderID) {
                            parentFolder.folders = parentFolder.folders.map(folder => {
                                if (folder.id === folderID) return { ...folder, ...updatedProperties };
                                return folder;
                            })
                        }
                        if (projectKey) {
                            parentFolder.projects = parentFolder.projects.map(project => {
                                if (project.key === projectKey) return { ...project, ...updatedProperties };
                                return project;
                            })
                        }
                    }
                    return team;
                })
                state.teamlist = newTeamlist;
            } else {
                let fileSystem = state.personalWorkspace.fileSystem;
                let parentFolder = null;
                if (parentFolderID == null || parentFolderID === "root") {
                    parentFolder = fileSystem;
                } else {
                    parentFolder = findFolderRecursive(fileSystem.folders, parentFolderID);
                }
                if (folderID) {
                    parentFolder.folders = parentFolder.folders.map(folder => {
                        if (folder.id === folderID) return { ...folder, ...updatedProperties };
                        return folder;
                    })
                }
                if (projectKey) {
                    parentFolder.projects = parentFolder.projects.map(project => {
                        if (project.key === projectKey) return { ...project, ...updatedProperties };
                        return project;
                    })
                }
                state.personalWorkspace.fileSystem = fileSystem;
            }
        },
        removeItemsRedux: (state, action) => {
            const [teamID, foldersData, projects, sourceFolderId] = action.payload

            if (teamID) {
                for (const team of state.teamlist) {
                    if (team.id === teamID) {
                        // remove items from source folder
                        const lookup = {}
                        if (Array.isArray(foldersData)) foldersData.forEach(folder => lookup[folder?.id] = true)
                        if (Array.isArray(projects)) projects.forEach(project => lookup[project?.floorkey] = true)

                        if (sourceFolderId == 'root') {
                            team.fileSystem.folders = team.fileSystem.folders?.filter(folder => !(folder?.id in lookup))
                            team.fileSystem.projects = team.fileSystem.projects?.filter(project => !(project?.key in lookup))
                        } else {
                            const sourceFolder = findFolderRecursive(team.fileSystem.folders, sourceFolderId);

                            if (sourceFolder.folders) sourceFolder.folders = sourceFolder.folders?.filter(folder => !(folder?.id in lookup))
                            if (sourceFolder.projects) sourceFolder.projects = sourceFolder.projects?.filter(project => !(project?.key in lookup))
                        }

                        break
                    }
                }
            } else {
                const fileSystem = state.personalWorkspace.fileSystem;

                // remove items from source folder
                const lookup = {}
                if (Array.isArray(foldersData)) foldersData.forEach(folder => lookup[folder?.id] = true)
                if (Array.isArray(projects)) projects.forEach(project => lookup[project?.floorkey] = true)

                if (sourceFolderId == 'root') {
                    fileSystem.folders = fileSystem.folders?.filter(folder => !(folder?.id in lookup))
                    fileSystem.projects = fileSystem.projects?.filter(project => !(project?.key in lookup))
                } else {
                    const sourceFolder = findFolderRecursive(fileSystem.folders, sourceFolderId);

                    if (sourceFolder.folders) sourceFolder.folders = sourceFolder.folders?.filter(folder => !(folder?.id in lookup))
                    if (sourceFolder.projects) sourceFolder.projects = sourceFolder.projects?.filter(project => !(project?.key in lookup))
                }
            }
        },
        addItemsRedux: (state, action) => {
            const [teamID, destinationFolderId, foldersData, projects] = action.payload

            if (teamID) {
                for (const team of state.teamlist) {
                    if (team.id === teamID) {
                        if (destinationFolderId == "root") {
                            if (Array.isArray(team.fileSystem.folders) &&
                                Array.isArray(foldersData) &&
                                foldersData.length
                            ) {
                                team.fileSystem.folders.push(...foldersData)
                            }

                            if (Array.isArray(team.fileSystem.projects) &&
                                Array.isArray(projects) &&
                                projects.length
                            ) {
                                team.fileSystem.projects.push(...projects)
                            }
                        } else {
                            const destFolder = findFolderRecursive(team.fileSystem.folders, destinationFolderId);
                            // add folders to new destination
                            if (destFolder && Array.isArray(foldersData) && foldersData.length) {
                                if (!destFolder.folders) destFolder['folders'] = []

                                destFolder.folders.push(...foldersData)
                            }

                            // add projects to new destination
                            if (destFolder && Array.isArray(projects) && projects.length) {
                                if (!destFolder.projects) destFolder['projects'] = []

                                destFolder.projects.push(...projects)
                            }
                        }
                        break
                    }
                }
            } else {
                const fileSystem = state.personalWorkspace.fileSystem;

                if (destinationFolderId == "root") {
                    if (Array.isArray(fileSystem.folders) &&
                        Array.isArray(foldersData) &&
                        foldersData.length
                    ) {
                        fileSystem.folders.push(...foldersData)
                    }

                    if (Array.isArray(fileSystem.projects) &&
                        Array.isArray(projects) &&
                        projects.length
                    ) {
                        fileSystem.projects.push(...projects)
                    }
                } else {
                    const destFolder = findFolderRecursive(fileSystem.folders, destinationFolderId);
                    // add folders to new destination
                    if (destFolder && Array.isArray(foldersData) && foldersData.length) {
                        if (!destFolder.folders) destFolder['folders'] = []

                        destFolder.folders.push(...foldersData)
                    }

                    // add projects to new destination
                    if (destFolder && Array.isArray(projects) && projects.length) {
                        if (!destFolder.projects) destFolder['projects'] = []

                        destFolder.projects.push(...projects)
                    }
                }
            }
        }
    }
})

export const {
    resetTeams,
    addTeams,
    addTeam,
    selectTeam,
    addMembersRedux,
    addProjectsToTeam,
    updateProjectRedux,
    clearTeamProjects,
    renameTeamUI,
    leaveTeamUI,
    acceptTeamInvite,
    deleteProjectRedux,
    addRoleBasedPermissionsRedux,
    updateRoleBasedPermissionsRedux,
    addMaterialsRedux,
    uploadTeamMaterialsRedux,
    updateTeamMaterialRedux,
    addTeamObjectsRedux,
    uploadTeamObjectsRedux,
    updateTeamObjectRedux,
    updateTeamMemberSettingRedux,
    deleteTeamMaterialRedux,
    deleteTeamObjectRedux,
    initializeFileSystemRedux,
    addFoldersRedux,
    removeFolderRedux,
    updateFolderRedux,
    removeItemsRedux,
    addItemsRedux
} = teamsSlice.actions;
export default teamsSlice.reducer;