import _ from "lodash";

const SPECIAL_CHARACTERS_REG_EXP = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
const EMAIL_REG_EXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const startsWithSpecialCharacter = (_string) => {
    return SPECIAL_CHARACTERS_REG_EXP.test( _string);
}

export const teamNameCheck = (str) => {
    const result = {
        invalid: false,
        reason: "",
    }

    str = _.trim(str);
    if(!str){
        result.invalid = true;
        result.reason = "Team name cannot be empty."
    }else if(startsWithSpecialCharacter(str)){
        result.invalid = true;
        result.reason = "Team name should not start with special character."
    }else if(!isNaN(str[0])){
        result.invalid = true;
        result.reason = "Team name should not start with a number."
    }else if(str.length < 4){
        result.invalid = true;
        result.reason = "Team name must be at least 4 characters long."
    }
    return result;
}

export const validateEmail = email => {
    return String(email).toLowerCase().match(EMAIL_REG_EXP);
};
