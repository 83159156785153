import { drawSketchMass, getDimensionInSnaptrudeUnits } from ".";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import { BasicCeilingComp } from "../../libs/basicCompProperties";
import { createCustomMesh } from "../../libs/massModeling";
import { applyMaterialByBREP } from "../../libs/mats";
import { setLayerTransperancy } from "../../libs/sceneFuncs";
import BABYLON from "../babylonDS.module";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { getCSGFormOfMesh, onSolid } from "../extrafunc";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  createMaterialFromSpeckleData,
  getMaterialIndex,
  getPointsData,
} from "./extraFunctions";
import speckleConstants from "./speckleConstants";
import { checkStorey } from "./storey";

const getCeilingData = (data) => {
  let points = [];
  let i = 0;
  let firstPoint = [data[i].start.x, data[i].start.y];
  points.push(firstPoint);
  let nextPoint = [data[i].end.x, data[i].end.y];
  console.log(data.length);
  while (points.length < data.length) {
    for (let j = 0; j < data.length; ++j) {
      if (
        Math.round(nextPoint[0], 5) === Math.round(data[j].start.x, 5) &&
        Math.round(nextPoint[1], 5) === Math.round(data[j].start.y, 5)
      ) {
        points.push([data[j].start.x, data[j].start.y]);
        nextPoint = [data[j].end.x, data[j].end.y];
        break;
      }
    }
  }
  return points;
};

const createCeilingComponent = (type, layersData) => {
  const defaultThickness = 25; // default thickness of ceilings
  let thickness = 0;
  let ceilingComp = {
    _name: type,
    _type: "Ceiling",
    _id: "",
    _thickness: defaultThickness,
    _layers: [],
  };
  let layers = [],
    material = [];

  if (layersData) {
    layersData.forEach((layer) => {
      layers.push({
        check: false,
        value: layer.material.name,
        subtype: "",
        thickness: layer.width,
        quantity: "volume",
        unit: "Cubic metres",
        core: layer.function === "Structure" ? true : false,
      });
      thickness += layer.width;

      material.push({
        type: layer.material.type,
        value: layer.material.value,
      });
    });

    ceilingComp._layers = layers;
    ceilingComp._thickness = thickness;

    return { ceilingComp, material, thickness };
  } else {
    ceilingComp = BasicCeilingComp;
    thickness = defaultThickness;
    return { ceilingComp, material, thickness };
  }
};

const createCeilings = (ceilingData, ceilingTypeData, materialList) => {
  let ceilingsMesh = [],
    ceilingMaterialCommands = [];

  let stack = [],
    ceilingsData = [];
  for (let cId in ceilingData) {
    try {
      let ceiling = ceilingData[cId];
      const family = ceiling?.family;
      const type = ceiling?.type;
      const level = ceiling?.level;
      let segments = ceiling?.outline;
      let voidsData = ceiling?.voids;
      let layersData;

      for (let i = 0; i < ceilingTypeData.length; ++i) {
        if (ceilingTypeData[i] && ceilingTypeData[i][type]) {
          layersData = ceilingTypeData[i][type];
          break;
        }
      }

      let { ceilingComp, material, thickness } = createCeilingComponent(
        type,
        layersData
      );

      let createdCeiling;

      let ceilingComponent = ceilingComp;
      let layerMaterials = material;
      thickness = DisplayOperation.getOriginalDimension(
        thickness,
        "millimeter"
      );

      for (let id in segments) {
        try {
          let outline = segments[id];
          let voids = voidsData[id];
          outline = getDimensionInSnaptrudeUnits(outline, "millimeter");
          outline = outline.map((point) => [
            point[0],
            point[1],
            point[2] - thickness,
          ]);
          createdCeiling = createCeiling(outline, thickness, {
            ceilingComponent,
            layerMaterials,
            type,
            family,
            elementId: cId,
            curveId: id,
            voids,
            level
          });

          if (createdCeiling) ceilingsData.push(createdCeiling);
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  virtualSketcher.addWithoutGeometryEdit(ceilingsData);

  ceilingsData.forEach((f) => ceilingsMesh.push(f.mesh));
  // saveDataInBackend(stack);
  ceilingsData.forEach(async function (ceiling) {
    try {
      let newMaterial = false,
        ceilingMat;

      ceilingMat = store.scene.getMaterialByName(
        `${ceiling.mesh.layerMaterials[0]?.value?.name}`
      );
      if (!ceilingMat) {
        if (ceiling.mesh.layerMaterials[0]?.value?.name) {
          ceilingMat = createMaterialFromSpeckleData(
            ceiling.mesh.layerMaterials[0],
            ceiling.mesh.layerMaterials[0]?.value?.name,
            materialList,
            { category: ceiling.mesh.name }
          );
        }
      }
      if (ceilingMat) {
        let materialIndex,
          isNewMaterial = false;
        if (ceiling.mesh.getSnaptrudeDS()) {
          let object = ceiling.mesh.getSnaptrudeDS();
          if (ceiling.mesh.layerMaterials.length == 1) {
            ({ materialIndex, isNewMaterial } = getMaterialIndex(
              ceiling.mesh,
              ceilingMat
            ));
            ceiling.mesh.subMeshes.forEach(function (subMesh) {
              subMesh.materialIndex = materialIndex;
            });
            if (object.brep) {
              let faces = object.brep.getFaces();
              faces.forEach(function (face) {
                face.materialIndex = materialIndex;
              });
            }
          } else if (ceiling.mesh.layerMaterials.length > 1) {
            ({ materialIndex, isNewMaterial } = getMaterialIndex(
              ceiling.mesh,
              ceilingMat
            ));
            applyMaterialByBREP(ceiling.mesh, 1, "", "Paint", {
              mat_index: materialIndex,
              revitImport: true,
            });
          }
        }
        // if (ceiling.mesh.material && isNewMaterial) {
        //   saveMaterialInBackend(ceiling.mesh.material);
        // }
      }
    } catch (e) {
      console.log(e);
    }
  });

  return { ceilingsMesh, ceilingMaterialCommands };
};

const createCeiling = (path_bottom, height, options) => {
  let createdMesh = createCustomMesh(path_bottom, height, null);
  let voidsData = options.voids;
  createdMesh = drawVoids(voidsData, createdMesh, height);

  createdMesh.structure_id = store.activeLayer.structure_id;
  let ceiling = new Mass(createdMesh);
  createdMesh.type = "Mass"; //throwAway is overwritten when mesh is added to level
  createdMesh.layerMaterials = options.layerMaterials;
  createdMesh.ceilingComponent = options.ceilingComponent;
  const storey = checkStorey(options.level);
  ceiling.storey = storey;
  ceiling.room_type = "Default";
  ceiling.assignProperties({ compData: options.ceilingComponent });
  ceiling.mesh.isVisible = true;
  ceiling.massType = "Ceiling";
  ceiling.height = createdMesh.height;
  let originalPosition = ceiling.mesh.position.clone();

  ceiling.revitMetaData = {
    elementId: options.elementId,
    type: options.type,
    family: options.family,
    curveId: options.curveId,
    originalVoids: !!options.voids ? Object.keys(options.voids).map((k) => { return { id: k, path: options.voids[k] } }) : null,
    originalProfile: path_bottom,
    originalPosition: originalPosition,
  };

  ceiling.originalMesh = BABYLON.SceneSerializer.SerializeMesh(ceiling.mesh);

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  talkingAboutLevel.addObjectToLevel(ceiling, false);
  if(storey){
    if (ceiling.storey !== storey?.value) {
      storey.addElement(ceiling);
    } 
  }else{
    StoreyMutation.assignStorey(ceiling);
  }
  // onSolid(ceiling.mesh);
  return ceiling;
};

const drawVoids = (voids, createdMesh, thickness) => {
  // for (let i = 0; i < floor.voids.length; ++i) {
  for (let voidId in voids) {
    let voidOutline = voids[voidId];
    // let parameters = getPointsData2(segments);

    let outline = getDimensionInSnaptrudeUnits(voidOutline, "millimeters");
    outline = outline.map((point) => [
      point[0],
      point[1],
      point[2] - thickness,
    ]);

    let voidMesh = createCustomMesh(outline, thickness, null);
    voidMesh.forceSharedVertices();
    let createdMeshCSG = getCSGFormOfMesh(createdMesh);
    let voidMeshCSG = getCSGFormOfMesh(voidMesh);
    let newCreatedMeshCSG = createdMeshCSG.subtract(voidMeshCSG);
    let newCreatedMesh = newCreatedMeshCSG.toSnaptrudeMesh(
      "ceiling",
      null,
      store.scene
    );
    newCreatedMesh.position = createdMesh.position;
    createdMesh.dispose();
    voidMesh.dispose();
    createdMesh = newCreatedMesh;
  }
  return createdMesh;
};
export { getCeilingData, createCeilings, drawVoids };
