import { Command } from "../commandManager/Command";
import { CommandManager } from "../commandManager/CommandManager";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { AutoSave } from "../socket/autoSave";
import { scenePickController } from "../utilityFunctions/scenePickController";
import { store } from "../utilityFunctions/Store";

const Locker = (() => {
    const lockedMeshes = [];

    const _addLockedElement = mesh => {
        if(!mesh) return;
        lockedMeshes.push(mesh);
        scenePickController.addLockedMesh(mesh);
    };

    const addMultipleMeshes = meshes => {
        for(let i = 0; i < meshes.length; i++){
            const response = _addLockedElement(meshes[i].mesh);
            // if(response === false){
            //     // Some error happened, unlock all locked meshes and throw a message
            //     while(i > 0){
            //         _removeLockedElement(meshes[i - 1]);
            //         i--;
            //     }
            //     console.log('Locker: Unable to lock');
            //     return false;
            // }
        }
        return true;
    };

    const _removeLockedElement = mesh => {
        if(!mesh) return;
        const index = lockedMeshes.indexOf(mesh);
        if(index === -1) return;
        lockedMeshes.splice(index, 1);
        scenePickController.removeLockedMesh(mesh);
    };

    const removeMultipleMeshes = meshes => {
        for(let i = 0; i < meshes.length; i++){
            const response = _removeLockedElement(meshes[i].mesh);
            // if(response === false){
            //     // Some error happened, lock all unlocked meshes and throw a message
            //     while(i > 0){
            //         _addLockedElement(meshes[i - 1]);
            //         i--;
            //     }
            //     console.log('Locker: Unable to unlock');
            //     return false;
            // }
        }
        return true;
    };

    const getAllLockedElements = () => {
        return lockedMeshes;
    };

    const isLocked = (mesh) => {
        if(!mesh) return;
        return lockedMeshes.includes(mesh);
    };

    const Utils = {
        execute: function(){
            const data = this.data;
            const meshes = data.meshes;
            addMultipleMeshes(meshes);
            for(let i = 0; i < meshes.length; i++){
                try{
                    const ds = meshes[i].mesh.getSnaptrudeDS();
                    ds.isLocked = true; 
                } catch (err){
                    // snaptrude ds probably does not exist, ignore
                }
            }
        },
        unexecute: function(){
            const data = this.data;
            const meshes = data.meshes;
            removeMultipleMeshes(meshes);
            for(let i = 0; i < meshes.length; i++){
                try{
                    const ds = meshes[i].mesh.getSnaptrudeDS();
                    ds.isLocked = false; 
                } catch (err){
                    // snaptrude ds probably does not exist, ignore
                }
            }
        },
        getSaveData: function(){
            const saveDataCollective = [];
            const meshes = this.data.meshes;
            const lock = this.data.lock;

            for(let i = 0; i < meshes.length; i++){
                const mesh = meshes[i].mesh;
                const saveData = AutoSave.getSaveDataPrototype();
                saveData.commandId = this.id;

                saveData.data.saveType = 'lockOperation';
                const dataBefore = {
                    isLocked: meshes[i].isLocked,
                };
                const dataAfter = {
                    isLocked: lock,
                };
                
                const identifierMeshDS = mesh.getSnaptrudeDS();
                saveData.data.identifier = AutoSave.getComponentIdentifier(identifierMeshDS);
                if (mesh.type.toLowerCase() === "floorplan") {
                    saveData.data.identifier.layer_id = mesh.layer_id;
                } else if (mesh.name === "terrain") {
                    const layerName = mesh.layerName;
        
                    const layerData = StructureCollection.getInstance()
                      .getStructureById(identifierMeshDS.structure_id)
                      .getStoreyData()
                      .getStoreyByValue(mesh.storey).layerData;
                    const layer = layerData.getLayerByName(layerName, mesh.storey);
                    saveData.data.identifier.layer_id = layer?.id;
                    saveData.data.identifier.type = "terrain";
                } else if(mesh.type.includes("pdf")){
                    const layerId = mesh.layer_id;
        
                    const layerData = StructureCollection.getInstance()
                      .getStructureById(identifierMeshDS.structure_id)
                      .getStoreyData()
                      .getStoreyByValue(mesh.storey).layerData;
                    const layer = layerData.getLayerBylayerId(layerId);
                    saveData.data.identifier.layer_id = layer?.id;
                } else if(mesh.type.includes("cad")){
                    const layerId = mesh.layer_id;
        
                    const layerData = StructureCollection.getInstance()
                      .getStructureById(identifierMeshDS.structure_id)
                      .getStoreyData()
                      .getStoreyByValue(mesh.storey).layerData;
                    const layer = layerData?.getLayerByName(mesh.layerName, mesh.storey);
                    saveData.data.identifier.layer_id = layer?.id;
                }


                saveData.data.beforeOperationData = dataBefore;
                saveData.data.afterOperationData = dataAfter;
                saveDataCollective.push(saveData);
            }

            return saveDataCollective;
        },
        executeFromSaveData: function(saveData){
            const identifier = saveData.identifier;
            const opData = saveData.afterOperationData;
            const meshOfInterest = store.scene.getMeshByUniqueID(identifier.component_id);

            if(meshOfInterest){
                if(opData.isLocked){
                    _addLockedElement(meshOfInterest);
                    try{
                        meshOfInterest.getSnaptrudeDS().isLocked = true;
                    } catch(err){
                        console.log(`Error in lock autoListener, ${err}`);
                    }
                } else {
                    _removeLockedElement(meshOfInterest);
                    try{
                        meshOfInterest.getSnaptrudeDS().isLocked = false;
                    } catch(err){
                        console.log(`Error in lock autoListener, ${err}`);
                    }
                }
            }
        },
        unExecuteFromSaveData: function(saveData){
            const identifier = saveData.identifier;
            const opData = saveData.beforeOperationData;
            const meshOfInterest = store.scene.getMeshByUniqueID(identifier.component_id);

            if(meshOfInterest){
                if(opData.isLocked){
                    _addLockedElement(meshOfInterest);
                    try{
                        meshOfInterest.getSnaptrudeDS().isLocked = true;
                    } catch(err){
                        console.log(`Error in lock autoListener, ${err}`);
                    }
                } else {
                    _removeLockedElement(meshOfInterest);
                    try{
                        meshOfInterest.getSnaptrudeDS().isLocked = false;
                    } catch(err){
                        console.log(`Error in lock autoListener, ${err}`);
                    }
                }
            }
        },
    };

    const LockMeshes = (meshes, autosave=true) => {
        const actualMeshes = [];

        // block to update structureCollection
        for(let i = 0; i < meshes.length; i++){
            try{
                const ds = meshes[i].getSnaptrudeDS();
                actualMeshes.push({
                    mesh: meshes[i],
                    isLocked: ds.isLocked,
                })
                ds.isLocked = true;
                if (!autosave) {
                    _addLockedElement(meshes[i]);
                }
            } catch (err){
                // snaptrude ds probably does not exist, ignore
            }
        }
        if (autosave) {
            const data = {
                lock: true,
                meshes: actualMeshes,
            };
            const lockCommand = new Command('lockOperation', data, { execute: Utils.execute, unexecute: Utils.unexecute }, Utils.getSaveData);
            CommandManager.execute(lockCommand, true);
        }
    };

    const UnlockMeshes = (meshes, autosave=true) => {
        const actualMeshes = [];

        // block to update structureCollection
        for(let i = 0; i < meshes.length; i++){
            try{
                const ds = meshes[i].getSnaptrudeDS();
                actualMeshes.push({
                    mesh: meshes[i],
                    isLocked: ds.isLocked,
                })
                ds.isLocked = false;
                if (!autosave) {
                    _removeLockedElement(meshes[i]);
                }
            } catch (err){
                // snaptrude ds probably does not exist, ignore
            }
        }

        if (autosave) {
            const data = {
                lock: false,
                meshes: actualMeshes,
            };
            const unlockCommand = new Command('unlockOperation', data, { execute: Utils.unexecute, unexecute: Utils.execute }, Utils.getSaveData);
            CommandManager.execute(unlockCommand, true);
        }
    };

    const lockOnRecreate = meshes => {
        addMultipleMeshes(meshes);
    };

    const filterLockedMeshes = meshes => {
        const unlockedMeshes = meshes.filter(mesh => {
            if(mesh){
                const ds = mesh.getSnaptrudeDS();
                if(ds && ds.isLocked){
                    return false;
                }
                return true;
            }
        });
        return unlockedMeshes;
    };

    return {
        Utils,
        getAllLockedElements,
        isLocked,
        LockMeshes,
        UnlockMeshes,
        lockOnRecreate,
        filterLockedMeshes,
    }
})();

window.locker = Locker;

export {
    Locker,
};
