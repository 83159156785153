import React, {useEffect, useState} from "react"
import styled from "styled-components"
import DataTable, { Select, Td, Text, Th, Tr } from "../../../components/DataTable";
import materialPlaceholderIcon from "../../../assets/icons/material-placeholder.png";
import { getMaterials } from "../../../services/resources.service";
import { randomIntFromInterval } from "../../../snaptrude/modules/extrafunc";
const Wrapper = styled.div`
    // border: 2px solid;
    padding: 0.25em;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const HEADINGS = [ "Texture", "Material Name", "Material Type", "uScale", "vScale", "Cost / sqm ($)", "Manufacturer", "SHGC"]
const arr = new Array(50).fill(5);

function Materials(){
  const [materialsList, setMaterialsList] = useState([]);
  useEffect(() => {
    getMaterials("materials", "ALVAGF")
    .then(resp => {
        if(resp.status === "success"){
            const {list, categories} = resp;
            // console.log(list, categories)
            setMaterialsList(list);
        }
    })
  }, []);
    return (
        <Wrapper>
            <DataTable>
                <Tr borderedBottom sticky={true}>
                    <Th style={{width: "5em"}}>Texture</Th>
                    <Th>Material Name</Th>
                    <Th style={{width: "10em", textAlign: "right"}}>Material Type</Th>
                    <Th>uScale</Th>
                    <Th>vScale</Th>
                    <Th>Cost / sqm ($)</Th>
                    <Th>Manufacturer</Th>
                    <Th>SHGC</Th>
                </Tr>
        {
          materialsList.map((item, index) => {
            return <Tr>
                <Td style={{width: "5em"}}>
                    <img style={{width: "4.5em", height: "4.5em", borderRadius: "5px"}} src={item.img} alt="material-placeholder" />
                </Td>
              <Td>
                <Text>{item.fullName}</Text>
              </Td>
              <Td style={{width: "10em"}}>
                <Select selectedOption={item.subType} options={["colors", "concrete", "glass", "metal", "tiles"]} />
              </Td>
              <Td>
                <Text>{1000}</Text>
              </Td>
              <Td>
                <Text>{1000}</Text>
              </Td>
              <Td>
                <Text>&nbsp;</Text>
              </Td>
              <Td>
                <Text>&nbsp;</Text>
              </Td>
              <Td>
                <Text>&nbsp;</Text>
              </Td>
            </Tr>
          })
        }
      </DataTable>
        </Wrapper>
    )
}

export default Materials;