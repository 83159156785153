import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as constants from "./constants";
import Button from "../../components/Button/index";
import Divider from "../../components/Divider/index";
import Input from "../../components/Input/index";
import { signUpStyles } from "./styles";
import * as jwt from "jsonwebtoken";
import { routes } from "../../routes/constants";

import AuthService from "../../services/auth.service";
import { acceptInvitation } from "../../services/teams.service";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import { acceptTeamInvitation, initializeTeams } from "../../services/team.service";
import {redirectToSnaptrudeManager} from "../../services/linking.service";
import _ from "lodash";


const Wrapper = styled.div`
  width: 25rem;
  @media (max-width: 768px){
    width: 18rem;
  }
`
const Header = styled.h4`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.primeBlack};
  font-size: ${(props) => props.theme.fontSizes.tiny};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
`;

const ErrorText = styled.p`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.mustard};
  font-size: 0.775rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
`

const ButtonWrapper = styled.div`
  padding: 1rem 0 0.5rem 0;
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailError: false,
      loginError: false,
      isButtonLoading: false,
    };
    this.loginFormRef = React.createRef();
  }

  setLoginError = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  setEmailError = (value) => {
    this.setState({
      emailError: value,
    });
  };

  handleLogin = async (isGoogleLogin = false, isExternalAuth) => {
    // console.log(this.state.email,this.state.password)
    window.analytics.track('Cliked Login Submit');
    if (!this.shouldLoginDisabled) {
      // console.log(this.state.email,this.state.password)
      this.setLoginError("loginError", false);
      this.setState({isButtonLoading: true});
      
      try {
        const resp = await AuthService.login(this.state.email, this.state.password, isGoogleLogin);
        
        
        
        this.setState({isButtonLoading: false});
        if(resp.error){
          this.setLoginError("loginError", resp.message);
        }else{
            if (isExternalAuth) {
              redirectToSnaptrudeManager(resp);
            }
            const query = new URLSearchParams(this.props.location.search);
            // Accept team invites if any
            const teamId = query.get("teamId");
            const token = query.get("token");
            if(teamId && token){
              await acceptTeamInvitation(token, teamId);
            }
            // Load all user teams
            try {
              const teamResp = await initializeTeams()
              if(teamResp.status !== "success"){
                showToast("Could not load teams, you may need to refresh once logged in.");
              }
            } catch (error) {
              showToast("Could not load teams, you may need to refresh once logged in.");
            }

            
            window.analytics.track('Login complete');
            const redirect = query.get("redirect");
            let url = routes.dashboard;
            if(redirect){
              url = redirect;
            }
            this.props.history.push(url);
        }
        
      } catch (error) {
        showToast("Something went wrong", 3000, TOAST_TYPES.error);
      }
    }
  };

  handleCredentialResponse = (response) => {
    const responsePayload = jwt.decode(response.credential);
    if(responsePayload){
      const profileObj = {
        name: responsePayload.name,
        email: responsePayload.email
      }
      this.onGoogleSuccess(profileObj)
    }else{
        showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
    }
  }

  componentDidMount(){
    var head = document.getElementsByTagName('head').item(0);
    const script = document.createElement("script");
    script.setAttribute("src", "https://accounts.google.com/gsi/client")
    head.appendChild(script)
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
        callback: this.handleCredentialResponse
      });
      const buttonWidth = window.innerWidth <= 768 ? 300 : 400;
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "medium", width: buttonWidth }  // customization attributes
      );
    }
    window.analytics.track('Login page opened');
  }

  onGoogleFailure = (resp) => {
    console.log("Failure",resp);
    if(resp.error && resp.error === "popup_closed_by_user") return;
    showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
  }
  onGoogleSuccess = async (profileObj) => {
    const isGoogleLogin = true;
    const urlSearch = new URLSearchParams(window.location.search);
    const isExternalAuth = urlSearch.get("externalAuth");
    this.setState({isButtonLoading: true});

    try {
      const resp = await AuthService.login(profileObj.email, "", isGoogleLogin);
      this.setState({isButtonLoading: false});
      if(resp.error){
        this.setLoginError("loginError", resp.message);
      }else{
          if (isExternalAuth) {
            redirectToSnaptrudeManager(resp);
          }
          const query = new URLSearchParams(this.props.location.search);
          // ACCEPT TEAM INVITATION NEW
          const teamId = query.get("teamId");
          const token = query.get("token");
          if(teamId && token){
            await acceptTeamInvitation(token, teamId)
          }
          
          // Load all user teams
          try {
            const teamResp = await initializeTeams()
            if(teamResp.status !== "success"){
              showToast("Could not load teams, you may need to refresh once logged in.");
            }
          } catch (error) {
            showToast("Could not load teams, you may need to refresh once logged in.");
          }

          
          window.analytics.track('Login complete');
          const redirect = query.get("redirect");
          let url = routes.dashboard;
          if(redirect){
            url = redirect;
          }
          this.props.history.push(url);
      }
      
    } catch (error) {
      showToast("Something went wrong", 3000, TOAST_TYPES.error);
    }
  }

  handleSubmit = (evt, isExternalAuth) => {
    evt.preventDefault();
    this.handleLogin(false, isExternalAuth);
  }

  render() {
    const { history } = this.props;
    const { email, password, emailError } = this.state;
    const shouldLoginDisabled = !email || !password || emailError;
    
    const urlSearch = new URLSearchParams(window.location.search);
    const isExternalAuth = urlSearch.get("externalAuth");
    
    return (
      <Wrapper>
        <Header>{constants.LOGIN_CARD_HEADER}</Header>
        <Divider />
        <ErrorText style={{ color: "#F2994A" }}>{this.state.loginError}</ErrorText>
        <ColumnWrapper style={{width: "100%"}}>
        <div id="buttonDiv"></div> 

        </ColumnWrapper >
        <form onSubmit={(evt) => this.handleSubmit(evt, isExternalAuth)} ref={this.loginFormRef}>
        <Input
          label={constants.EMAIL_ADDRESS_LABEL}
          type={"email"}
          customInputContainerStyle={signUpStyles.inputWrapper}
          setError={this.setEmailError}
          value={email}
          error={emailError}
          errorMessage={constants.EMAIL_ERROR_MESSAGE}
          onPress={(e) => this.setFormProperty("email", _.toLower(_.trim(e.target.value)))}

        />
        <Input
          label={constants.PASSWORD_LABEL}
          type={"password"}
          customInputContainerStyle={signUpStyles.inputWrapper}
          value={password}
          onPress={(e) => this.setFormProperty("password", e.target.value)}
        />
        <ButtonWrapper>
          <Button
            title={constants.SIGN_IN}
            disabled={shouldLoginDisabled}
            type="submit"
            customButtonStyle={
              shouldLoginDisabled
                ? signUpStyles.signUpButtonDisabled
                : signUpStyles.signUpButtonEnabled
            }
            isLoading={this.state.isButtonLoading}
          />
        </ButtonWrapper>
        <Button
          onPress={() => {
            history.push(routes.forgotPassword);
          }}
          customButtonStyle={signUpStyles.forgotPasswordButton}
          title={constants.FORGOT_PASSWORD}
        />
        </form>
        <div style={{display: "flex", alignItems: "center"}}>
          <p style={{fontSize: "0.875rem", color: "#828282", fontWeight: "500", marginRight: "5px"}}>Not yet a user?</p>
          
          <Button
            onPress={() => {
              const query = new URLSearchParams(this.props.location.search);
              const params = query.toString();
              let url = routes.signup;
              if(params){
                url = `${routes.signup}?${params}`;
              }
              history.push(url);
            }}
            customButtonStyle={signUpStyles.forgotPasswordButton}
            title={constants.NEW_USER}
          />
        </div>
      </Wrapper>
    );
  }
}

Login.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Login);
