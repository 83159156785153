import { fabric } from "fabric";
import { store } from "../../modules/utilityFunctions/Store.js";
import { removeAllChildren } from "../keyEvents.js";
import { getCanvasTransformState } from "./updateMods.js";
import { transformPoints } from "./canvasServices.js";
function addObjectEdgeGrips(obj) {
  removeAllChildren();
  store.objSelected = obj;
  obj.setCoords();
  obj.hasControls = false;
  obj.hasBorders = false;
  obj.lockScalingX = true;
  obj.lockScalingY = true;
  obj.lockMovementX = true;
  obj.lockMovementY = true;

  var group = new fabric.Group();
  var points = obj.points;
  //let transformedPoints =  transformPoints(obj);

  for (let i = 0; i < obj.points.length; i++) {
    var midpointX =
      (obj.points[i].x + obj.points[(i + 1) % obj.points.length].x) / 2;
    var midpointY =
      (obj.points[i].y + obj.points[(i + 1) % obj.points.length].y) / 2;
    var rect = new fabric.Rect({
      left: midpointX,
      top: midpointY,
      width: 15,
      height: 15,
      fill: "rgba(24, 230, 24,1)",
      id: "grips",
      evented: "false",
      originX: "center",
      originY: "center",
    });
    rect.ptIdx1 = i;
    rect.ptIdx2 = (i + 1) % obj.points.length;
    group.addWithUpdate(rect);
    var midpointX = obj.points[i].x;
    var midpointY = obj.points[i].y;
    /*var rect2 = new fabric.Rect({
            left: midpointX,
            top: midpointY,
            width: 15,
            height: 15,
            fill: 'rgba(240, 230, 24,0)',
            id: 'grips_temp',
            evented: 'false',
            originX: "center",
            originY: "center"
        });
        group.addWithUpdate(rect2);
        var midpointX = (transformedPoints[(i+1)%transformedPoints.length].x);
        var midpointY = (transformedPoints[(i+1)%transformedPoints.length].y);
        var rect3 = new fabric.Rect({
            left: midpointX,
            top: midpointY,
            width: 15,
            height: 15,
            fill: 'rgba(240, 230, 24,0)',
            id: 'grips_temp',
            evented: 'false',
            originX: "center",
            originY: "center"
        });
        group.addWithUpdate(rect3);*/
    let canTransState = getCanvasTransformState();
    if (canTransState) group.perPixelTargetFind = true;
    else group.perPixelTargetFind = false;
  }
  // group.setCoords();
  group.hasControls = false;
  group.hasBorders = false;
  group.lockScalingX = true;
  group.lockScalingY = true;
  group.lockMovementX = true;
  group.lockMovementY = true;
  group.id = "children";
  obj.children = group;
  //obj.children.left = obj.left-7.5;
  //obj.children.top = obj.top-7.5;
  // group.bringToFront();
  // obj.setCoords();
  //group.remove(rect2);
  //group.remove(rect3);
  store.canvas.add(obj.children);
  // store.canvas.renderAll();
  // obj.children.bringToFront();
}

function addObjectVertGripsNew(obj) {
  let transformedPoints = transformPoints(obj);
  if (transformedPoints) {
    for (let i = 0; i < transformedPoints.length; i++) {
      let rect = new fabric.Rect({
        left: transformedPoints[i].x,
        top: transformedPoints[i].y,
        width: 15,
        height: 15,
        fill: "rgba(24, 230, 24,1)",
        id: "vertgrip",
        evented: "false",
        originX: "center",
        originY: "center",
      });
      store.canvas.add(rect);
    }
  }
}

function addObjectVertGrips(obj) {
  removeAllChildren();
  store.objSelected = obj;
  obj.setCoords();
  obj.hasControls = false;
  obj.hasBorders = false;
  obj.lockScalingX = true;
  obj.lockScalingY = true;
  obj.lockMovementX = true;
  obj.lockMovementY = true;

  var points = obj.points;
  var group = new fabric.Group([], {
    left: 250,
    top: 200,
    height: 100,
    width: 300,
  });

  /*var matrix = obj.calcTransformMatrix();
    console.log("MAtrix", matrix);
    var transformedPoints = obj.get("points")
        .map(function(p){
            console.log("Inside1", p);
            return new fabric.Point(p.x - obj.minX -obj.width/2, p.y - obj.minY - obj.height/2);
        })
        .map(function(p){
            console.log("Inside2", p);
            return fabric.util.transformPoint(p, matrix);
        });*/

  /*let transformedPoints =  transformPoints(obj);

    console.log("1", obj.points);
    console.log("2", transformedPoints);*/
  //let transformedPoints = obj.points;

  if (obj.points) {
    for (var i = 0; i < obj.points.length; i++) {
      var midpointX = obj.points[i].x;
      var midpointY = obj.points[i].y;
      var rect = new fabric.Rect({
        left: midpointX,
        top: midpointY,
        width: 15,
        height: 15,
        fill: "rgba(24, 230, 24,1)",
        id: "vertgrip",
        evented: false,
        originX: "center",
        originY: "center",
      });
      rect.ptIdx1 = i;
      rect.ptIdx2 = (i + 1) % obj.points.length;
      group.addWithUpdate(rect);
    }
    group.hasControls = false;
    group.hasBorders = false;
    group.lockScalingX = true;
    group.lockScalingY = true;
    group.lockMovementX = true;
    group.lockMovementY = true;
    group.id = "children";
    // var canTransState = getCanvasTransformState();
    // if (canTransState)
    // 	group.perPixelTargetFind = true;
    // else
    // 	group.perPixelTargetFind = false;
    group.perPixelTargetFind = true;
    obj.children = group;
    //obj.children.left = obj.left - 7.5;
    //obj.children.top = obj.top - 7.5;
    // group.bringToFront();
    store.canvas.add(obj.children);

    //NEWLY COMMENTED
    //objSelected.prevLeft = objSelected.left;
    //objSelected.prevTop = objSelected.top;

    // obj.children.bringToFront();
  } else {
    console.log(obj);
    /* TODO: Fix Issues for grips
     */

    var path = obj.path;
    if (path) {
      for (var i = 0; i < path.length; i++) {
        if (path[i][0] === "M") {
          for (var j = 1; j < path[i].length; j += 2) {
            var rect = addControlPoints(
              obj,
              { x: path[i][j], y: path[i][j + 1] },
              i,
              j
            );
            console.log(rect);
            group.addWithUpdate(rect);
          }
        } else if (path[i][0] === "C") {
          for (var j = 1; j < path[i].length; j += 2) {
            var rect = addControlPoints(
              obj,
              { x: path[i][j], y: path[i][j + 1] },
              i,
              j
            );
            group.addWithUpdate(rect);
          }
        } else if (path[i][0] === "L") {
          for (var j = 1; j < path[i].length; j += 2) {
            var rect = addControlPoints(
              obj,
              { x: path[i][j], y: path[i][j + 1] },
              i,
              j
            );
            group.addWithUpdate(rect);
          }
        } else {
        }
      }
      group.hasControls = false;
      group.hasBorders = false;
      group.lockScalingX = true;
      group.lockScalingY = true;
      group.lockMovementX = true;
      group.lockMovementY = true;
      group.id = "children";
      group.perPixelTargetFind = true;
      // group.setCoords();
      // group.left = path[0][1];
      // group.top = path[0][2];
      obj.children = group;
      //obj.children.left = obj.left - 7.5;
      //obj.children.top = obj.top - 7.5;
      // obj.children.left = obj.left;
      // obj.children.top = obj.top;
      // group.bringToFront();
      // store.canvas.add(obj.children);
      store.canvas.add(obj.children);
      // objSelected.prevLeft = objSelected.left;
      // objSelected.prevTop = objSelected.top;
      // obj.children.bringToFront();
    }
  }
  // else {
  //     var path = obj.path;
  //     if (path) {
  //         for (var i = 0; i < path.length; i++) {
  //             var type = path[i][0];
  //             if (type === 'M') {
  //                 for (var j = 1; j < path[i].length; j += 2) {
  //                     group.addWithUpdate(new fabric.Rect({
  //                         left: group.get('left') + path[i][j],
  //                         top: midpointY,
  //                         width: 8,
  //                         height: 8,
  //                         fill: 'rgba(24, 230, 24,1)',
  //                         id: 'grips',
  //                         evented: 'false',
  //                         ptIdx1: [i, j],
  //                         ptIdx2: [(i + 1) % obj.path.length, (j + 1) % obj.path.length]
  //                     }));
  //                 }
  //             }
  //         }
  //     }
  // }
}

function addControlPoints(obj, pt, i, j) {
  var midpointX = pt.x;
  var midpointY = pt.y;
  var rect = new fabric.Rect({
    originX: "center",
    originY: "center",
    left: midpointX,
    top: midpointY,
    width: 12,
    height: 12,
    fill: "rgba(24, 230, 24,1)",
    id: "grips",
    evented: "false",
  });
  rect.ptIdx1 = [i, j];
  rect.ptIdx2 = [(i + 1) % obj.path.length, (j + 1) % obj.path.length];
  return rect;
}

function removeAllGripsWithId(typeOfGrip) {
  let allObjects = store.canvas.getObjects();
  let allVertices = allObjects.filter(function (obj) {
    return obj.id == typeOfGrip;
  });
  for (let i = 0; i < allVertices.length; i++) {
    store.canvas.remove(allVertices[i]);
  }
}

//still under construction, need ot take care of children shit
function removeGripAtPoint(typeOfGrip, point) {
  console.log("INSIDE TWO");
  let allObjects = store.canvas.getObjects();
  let allVertices = allObjects.filter(function (obj) {
    return obj.id == "children";
  });
  let removalVertices = [];
  //console.log("ALLVERT", allVertices);
  //console.log("ALLOBBJS", allVertices[0]._objects);
  /*allVertices.forEach(obj => {
       console.log("INS", obj);
    });*/
  /*allVertices = allVertices.filter(function(obj){
        console.log("TOP", obj._objects.top, "POINT", point.y);
        console.log("LFT", obj._objects.left, "POINT", point.x);
        return ((obj._objects.top == point.y) && (obj._objects.left == point.x));
    });*/
  for (let i = 0; i < allVertices[0]._objects.length; i++) {
    console.log(
      "TOP",
      allVertices[0]._objects[i].top + allVertices[0].top,
      "POINT",
      point.y
    );
    console.log(
      "LFT",
      allVertices[0]._objects[i].left + allVertices[0].left,
      "POINT",
      point.x
    );
    if (
      allVertices[0]._objects[i].top + allVertices[0].top == point.y &&
      allVertices[0]._objects[i].left + allVertices[0].left == point.x
    ) {
      removalVertices.push(allVertices[0]._objects[i]);
    }
  }
  console.log("ALLVERT", removalVertices);
  for (let i = 0; i < removalVertices.length; i++) {
    store.canvas.remove(removalVertices[i]);
  }
}
export {
  addObjectEdgeGrips,
  addObjectVertGripsNew,
  addObjectVertGrips,
  addControlPoints,
  removeAllGripsWithId,
  removeGripAtPoint,
};
