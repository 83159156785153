import BABYLON from "../../babylonDS.module";
import {scenePickController} from "../../utilityFunctions/scenePickController";
import {store} from "../../utilityFunctions/Store";
import {GLOBAL_CONSTANTS} from "../../utilityFunctions/globalConstants";
import {getDistanceBetweenVectors, isPointInTheVicinityOfMesh} from "../../extrafunc";
import {resetConstrainedSnapAxisInformation} from "../../../libs/snapUtilities";
import {is2D} from "../../../libs/twoDimension";
import {getRefGroundPoint, projectionOfPointOnFace} from "../../../libs/snapFuncs";

/*
There's probably a better way to implement movement constraint
Currently, this module intercepts secondary snapped point calls but
probably axis point is probably a better place

When moving d/w the axis of the wall is the axis to snap to
And then integrate that with constrained axis snap logic

 */

const movementConstrainer = (function (){
  
  let _componentOfInterest;
  let _planeOfConstraint;
  
  const init = function (component, pickInfo, planeData = {}){
    _componentOfInterest = component;
    
    let position, normal;
    
    if (pickInfo){
      position = pickInfo.pickedPoint;
      normal = pickInfo.getNormal(true, false);
    }
    else {
      position = planeData.position;
      normal = planeData.normal;
    }
    
    const planeObject = BABYLON.Plane.FromPositionAndNormal(
      position,
      normal
    );
    
    _planeOfConstraint = BABYLON.MeshBuilder.CreatePlane(
      "movementConstrainerPlane",
      {
        size: 1000,
        sourcePlane: planeObject,
      },
      store.scene
    );
    
    _planeOfConstraint.visibility = 0;
    _planeOfConstraint.type = GLOBAL_CONSTANTS.strings.identifiers.utilityElement;
    _planeOfConstraint.sourcePlane = planeObject;
    _planeOfConstraint.position = position;
    
    scenePickController.add(_planeOfConstraint);
  };
  
  const getSecondarySnappedPoint = function (startingPoint, axisPoint, options){
    if (!_planeOfConstraint) return null;
    
    if (is2D()){
      const refGroundPoint = getRefGroundPoint();
      const projection = projectionOfPointOnFace(
        refGroundPoint,
        null,
        _planeOfConstraint.sourcePlane
      );
      
      if (isPointInTheVicinityOfMesh(projection, _componentOfInterest.mesh.parent)){
        options.metadata.snappedToFaceParallely = true;
        return projection;
      }
      else return _componentOfInterest.mesh.getAbsolutePosition();
    }
    else {
      const pick = scenePickController.pickInvisibleMeshes(m => m === _planeOfConstraint);
      if (pick.hit){
        // if (_componentOfInterest.mesh.parent.intersectsPoint(pick.pickedPoint)){
        if (isPointInTheVicinityOfMesh(pick.pickedPoint, _componentOfInterest.mesh.parent)){
          let secondarySnappedPoint = pick.pickedPoint;
          
          if (axisPoint){
            const dFromPlane = _planeOfConstraint.sourcePlane.signedDistanceTo(axisPoint);
            if (Math.abs(dFromPlane) < 1e-1){
              const dFromAxisPoint = getDistanceBetweenVectors(axisPoint, secondarySnappedPoint);
              if (dFromAxisPoint < 1) secondarySnappedPoint = axisPoint;
            }
            else {
              axisPoint = null;
              if (options.metadata.constrainedAxisSnap){
                resetConstrainedSnapAxisInformation();
              }
            }
          }
          
          pick.pickedMesh = _componentOfInterest.mesh.parent;
          options.metadata.pickInfo = pick;
          
          return secondarySnappedPoint;
        }
        else {
          return startingPoint;
        }
      }
      else {
        return startingPoint;
      }
    }
  };
  
  const flush = function (){
    if (_planeOfConstraint) _planeOfConstraint.dispose();
    _componentOfInterest = null;
    _planeOfConstraint = null;
  };
  
  const isActive = function (){
    return !!_planeOfConstraint;
  };
  
  const projectPoint = function (point){
    if (!isActive()) return null;
    
    return projectionOfPointOnFace(
      point,
      null,
      _planeOfConstraint.sourcePlane
    );
  };
  
  const constrainPoint = function (point){
    if (!isActive()) return null;
    
    if (isPointInTheVicinityOfMesh(point, _componentOfInterest.mesh.parent)){
      return point;
    }
    else return _componentOfInterest.mesh.getAbsolutePosition();
  };
  
  const isPointInRange = function (point){
    if (!isActive()) return null;
    return isPointInTheVicinityOfMesh(point, _componentOfInterest.mesh.parent);
  };
  
  return {
    init,
    getSecondarySnappedPoint,
    isActive,
    flush,
    
    util: {
      projectPoint,
      constrainPoint,
      isPointInRange,
    },
  };
})();

export default movementConstrainer;