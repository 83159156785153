import React from "react";
import styled from "styled-components";
import loaderAnimation from "../../assets/icons/loader.gif"
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 4em;
    img{
        width: 1em;
        filter: invert(90%) sepia(94%) saturate(4987%) hue-rotate(337deg)
                brightness(93%) contrast(98%);
    }
`
function LoadingScreen({style, ...props}) {
    return ( 
        <Wrapper  style={style} {...props} >
            <img src={loaderAnimation} alt="Loading"/>
        </Wrapper>
     );
}

export default LoadingScreen;