import React, { useRef, useState } from 'react'
import ReactModal from 'react-modal';
import styled from "styled-components";
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';
import { colors } from '../../themes/constant';
import { modalStylesDefault, modalStylesTeam } from '../SnaptrudeModal/style';
import PageOne from './PageOne';
import PageTwo from './PageTwo';

const Wrapper = styled.div`
    // border: 2px solid;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
`
const ModalWrapper = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    // min-width: 20%;
    // min-height: 20%;
    .heading{
        font-weight: 700;
        font-size: 24px;
        color: ${colors.primeBlack};
    }
    .subheading{
        font-weight: 400;
        font-size: 14px;
        color: ${colors.greyishBlack};
    }
    .actions{
        display: flex;
        flex-direction: row;
        z-index: 5;
    }
    .button-wrapper{
        // border: 2px solid crimson;
        width: 100%;
        // padding: 0.5em 3em;
        z-index: 5;
    }
    .page-indicator{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .indicator{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 0px 1px;
        background: #F2F2F2;
    }
    .active{
        background: #818181;
    }
`
function TeamInviteModal({ page, handleClose, selectedTeamId, pageOne, ...props }) {
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [teamId, setTeamId] = useState(selectedTeamId || null);
    const ref = useRef(null);
    // useOutsideClickHandler(ref, handleClose)
    const updatePage = (newPage) => {
        setCurrentPage(newPage);
    }
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={handleClose}
            style={modalStylesTeam}
        >
            <Wrapper>
                <ModalWrapper ref={ref}>
                    {
                        currentPage === 1 && <PageOne updatePage={updatePage} handleClose={handleClose} setTeamId={setTeamId} headingText={pageOne?.headingText} inputLabelText={pageOne?.inputLabelText} />
                    }
                    {
                        currentPage === 2 && <PageTwo updatePage={updatePage} handleClose={handleClose} teamId={teamId} />
                    }
                </ModalWrapper>
            </Wrapper>
        </ReactModal>

    );
}

export default TeamInviteModal;