import BABYLON from "../babylonDS.module";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { showVertexIndicator } from "../meshoperations/moveOperations/moveUtil";
import { Staircase } from "../snaptrudeDS/staircase.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { uiIndicatorsHandler } from "../uiIndicatorOperations/uiIndicatorsHandler";
import { store } from "../utilityFunctions/Store";
import { createCustomMeshFromRevitExport } from "./extraFunctions";
import { Mass } from "../snaptrudeDS/mass.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { onSolid } from "../extrafunc";
import { checkStorey } from "./storey";

// const createStaircaseDeprecated = () => {

//   let staircaseData = {
//     height: 550,
//     treadDepth: 300,
//     riserHeight: 180.55555555556018,
//     riserNumber: 3,
//     runWidth: 5999.999999999994,
//   }
//   //     let position = {
//   //     "x": 21689.99999999998,
//   // "y": - 1820.0000000002142 - 2720.000000000214 / 2,
//   // "z": 2500.0000000000828,
//   //     }

//   let segment = [
//     {
//       "id": "b6b6a7ab455774bee0c6886a2d2aa7db",
//       "end": {
//         "x": 18689.99999999998,
//         "y": -2720.000000000214,
//         "z": 2500.0000000000828,
//         "id": "da288e4173ae7ca20e5f2c777ab70b53",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "area": 0,
//       "start": {
//         "x": 18689.99999999998,
//         "y": -1820.0000000002142,
//         "z": 2500.0000000000828,
//         "id": "c62cb7780d444f8b7b46f8a840dc8143",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "units": "mm",
//       "domain": {
//         "id": "2dd2143f8bea5efdfc8efa3aa18f219f",
//         "end": 2.4868995751603507e-14,
//         "start": -2.9527559055117862,
//         "speckle_type": "Objects.Primitive.Interval",
//         "totalChildrenCount": 0
//       },
//       "length": 900,
//       "speckle_type": "Objects.Geometry.Line",
//       "totalChildrenCount": 0
//     },
//     {
//       "id": "5ef7832addd573b923da07a97579adc9",
//       "end": {
//         "x": 24689.999999999975,
//         "y": -2720.000000000214,
//         "z": 2500.0000000000828,
//         "id": "2a0140fb3d30ba353ae068b1705370d7",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "area": 0,
//       "start": {
//         "x": 18689.99999999998,
//         "y": -2720.000000000214,
//         "z": 2500.0000000000828,
//         "id": "da288e4173ae7ca20e5f2c777ab70b53",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "units": "mm",
//       "domain": {
//         "id": "d2f80da67618d8bde45352ab91e4dd98",
//         "end": 9.84251968503936,
//         "start": -9.84251968503936,
//         "speckle_type": "Objects.Primitive.Interval",
//         "totalChildrenCount": 0
//       },
//       "length": 5999.999999999994,
//       "speckle_type": "Objects.Geometry.Line",
//       "totalChildrenCount": 0
//     },
//     {
//       "id": "f1827ab7701210ffb11cd593098397c6",
//       "end": {
//         "x": 24689.999999999975,
//         "y": -1820.0000000002142,
//         "z": 2500.0000000000828,
//         "id": "6baf431d0f6338001979b3570223b381",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "area": 0,
//       "start": {
//         "x": 24689.999999999975,
//         "y": -2720.000000000214,
//         "z": 2500.0000000000828,
//         "id": "2a0140fb3d30ba353ae068b1705370d7",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "units": "mm",
//       "domain": {
//         "id": "a216f26ea364e5249a1572e263685d1c",
//         "end": 2.952755905511836,
//         "start": 2.4868995751603507e-14,
//         "speckle_type": "Objects.Primitive.Interval",
//         "totalChildrenCount": 0
//       },
//       "length": 900,
//       "speckle_type": "Objects.Geometry.Line",
//       "totalChildrenCount": 0
//     },
//     {
//       "id": "01f51ef52818f6389857cb9c53e4f315",
//       "end": {
//         "x": 18689.99999999998,
//         "y": -1820.0000000002142,
//         "z": 2500.0000000000828,
//         "id": "c62cb7780d444f8b7b46f8a840dc8143",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "area": 0,
//       "start": {
//         "x": 24689.999999999978,
//         "y": -1820.0000000002142,
//         "z": 2500.0000000000828,
//         "id": "a68c8e3fbc0863e7edb9ab97907a80ab",
//         "units": "mm",
//         "speckle_type": "Objects.Geometry.Point",
//         "totalChildrenCount": 0
//       },
//       "units": "mm",
//       "domain": {
//         "id": "d2f80da67618d8bde45352ab91e4dd98",
//         "end": 9.84251968503936,
//         "start": -9.84251968503936,
//         "speckle_type": "Objects.Primitive.Interval",
//         "totalChildrenCount": 0
//       },
//       "length": 5999.999999999994,
//       "speckle_type": "Objects.Geometry.Line",
//       "totalChildrenCount": 0
//     }
//   ]


//   position.y = 2500.0000000000828

//   let runsWidth = []

//   // staircaseData = staircaseData.filter(staircase => 
//   //   staircase.speckle_type === "Objects.BuiltElements.Revit.RevitStair"
//   // )

//   // let staircase = {}



//   position.x = DisplayOperation.getOriginalDimension(position.x, "millimeter")
//   position.y = DisplayOperation.getOriginalDimension(position.y, "millimeter")
//   position.z = DisplayOperation.getOriginalDimension(position.z, "millimeter")
//   const structureCollection = StructureCollection.getInstance();
//   const structureId = store.activeLayer.structure_id
//   const structure =
//     structureCollection.getStructures()[structureId];
//   let mesh = new BABYLON.Mesh();
//   // mesh.position = new BABYLON.Vector3(position.x, position.y,position.z)
//   staircaseData.height = DisplayOperation.getOriginalDimension(
//     staircaseData.height,
//     "millimeter"
//   )
//   let myStair = new Staircase(mesh, "custom", staircaseData.height);

//   staircaseData.treadDepth = DisplayOperation.getOriginalDimension(
//     staircaseData.treadDepth,
//     "millimeter"
//   )

//   staircaseData.runWidth = DisplayOperation.getOriginalDimension(
//     staircaseData.runWidth,
//     "millimeter"
//   )

//   staircaseData.riserHeight = DisplayOperation.getOriginalDimension(
//     staircaseData.riserHeight,
//     "millimeter"
//   )

//   // staircaseData.runs.forEach(run =>{
//   //   runsWidth.push(run.runWidth)
//   // })

//   myStair.updateTread(staircaseData.treadDepth)
//   myStair.updateRiser(staircaseData.riserHeight)
//   myStair.updateSteps(staircaseData.riserNumber)
//   myStair.updateWidth(staircaseData.runWidth)
//   // myStair.setRevitParameter("runWidth", runsWidth)
//   myStair.createStaircase();
//   myStair.mesh.isVisible = true
//   for (let i = 0; i < myStair.meshes.length; i++) {
//     myStair.meshes[i].visibility = 1;
//   }
//   let level = structure.getLevelByName("01");
//   level.addStaircaseToLevel(myStair, false);
//   myStair.doUnion()
//   myStair.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
//   myStair.positionStair(new BABYLON.Vector3(position.x, position.y, position.z))

// }

const createStaircases = (staircasesData, materialList) => {
  const staircaseMeshes = [];

  for (const staircaseId in staircasesData) {
    // if (type != "Panels" || type != "mullions") return;
    try {
      const staircaseData = staircasesData[staircaseId];
      const data = staircaseData["data"];
      // const data = {
      //   category: staircaseData["category"],
      //   family: staircaseData["family"],
      //   type: staircaseData["type"],
      //   elementId: staircaseData["elementId"]
      // };
      for (const dataType in staircaseData) {
        if (dataType === "data") continue;
        // if (dataType === "category") continue;
        // if (dataType === "elementId") continue;
        // if (dataType === "family") continue;
        // if (dataType === "type") continue;
        const meshData = staircaseData[dataType];
        if (!meshData || !data) return;
        const mesh = createCustomMeshFromRevitExport(
          data,
          staircaseData,
          materialList,
          { isMassType: true }
        );

        if (!mesh) continue;
        let staircaseDS = new Mass(mesh);
        mesh.structure_id = store.activeLayer.structure_id;

        mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
        staircaseDS.storey = 1;

        staircaseDS.room_type = "Default";
        staircaseDS.massType = "Staircase";
        // staircaseDS.importType = "speckleRevitImport";
        staircaseDS.revitMetaData = { elementId: data.elementId };
        mesh.storey = 1;
        staircaseDS.storey = 1;
        staircaseDS.structure_id = store.activeLayer.structure_id;

        const structureCollection = StructureCollection.getInstance();
        const talkingAboutStructure = structureCollection.getStructureById(
          store.activeLayer.structure_id
        );
        const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
        talkingAboutLevel.addObjectToLevel(staircaseDS, false);
        const storey = checkStorey(data?.levelName);
        if (storey) {
          storey.addElement(staircaseDS);
        } else {
          StoreyMutation.assignStorey(staircaseDS);
        }
        onSolid(mesh);

        staircaseMeshes.push(mesh);
      }
    }
    catch (e) {
      console.warn(e, "Corrupt Staircase data");
    }
  }
  return staircaseMeshes;
}

export { createStaircases }
