import { useEffect } from "react";

function useOutsideClickHandler(ref, handler) {
    useEffect(() => {
      
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
        //   alert("You clicked outside of me!");
            handler();
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

export default useOutsideClickHandler;