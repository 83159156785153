import { store } from "../../modules/utilityFunctions/Store";

function getNearestEdge(canvas, pt1, snap_threshX, snap_threshY, flag) {
  var X1, X2, Y1, Y2, X3, X4, Y3, Y4, X5, Y5;
  canvas.forEachObject(function (obj) {
    if (obj.id != "rooms") {
      return;
    }
    var meanX = 0;
    var meanY = 0;
    for (let i = 0; i < obj.points.length; i++) {
      meanX = meanX + obj.points[i].x;
      meanY = meanY + obj.points[i].y;
    }
    meanX = meanX / obj.points.length;
    meanY = meanY / obj.points.length;
    var n = obj.points.length;
    for (var p = 0; p < obj.points.length; p++) {
      let pt2 = Object.assign({}, obj.points[p]);
      if (pt2.x > meanX) {
        //pt2.x = obj.left + (pt2.x+obj.getScaledWidth()*(obj.scaleX-1)/obj.scaleX - obj.minX);
        pt2.x =
          obj.left +
          (pt2.x + (obj.getWidth() * (obj.scaleX - 1)) / obj.scaleX - obj.minX);
      } else {
        pt2.x = obj.left + (pt2.x - obj.minX);
      }
      if (pt2.y > meanY) {
        //pt2.y = obj.top + (pt2.y + obj.getScaledHeight()*(obj.scaleY-1)/obj.scaleY  - obj.minY);
        pt2.y =
          obj.top +
          (pt2.y +
            (obj.getHeight() * (obj.scaleY - 1)) / obj.scaleY -
            obj.minY);
      } else {
        pt2.y = obj.top + (pt2.y - obj.minY);
      }
      if (Math.abs(pt1.x - pt2.x) < snap_threshX) {
        X1 = pt1;
        X2 = pt2;
        X3 = obj.points[(p + 1) % n];
        if (p == 0) {
          X4 = obj.points[obj.points.length - 1];
        } else {
          X4 = obj.points[p - 1];
        }
        X5 = getNearestPoint(pt1, X3, X4);
        snap_threshX = Math.abs(pt1.x - pt2.x);
      }
      if (Math.abs(pt1.y - pt2.y) < snap_threshY) {
        Y1 = pt1;
        Y2 = pt2;
        Y3 = obj.points[(p + 1) % n];
        if (p == 0) {
          Y4 = obj.points[obj.points.length - 1];
        } else {
          Y4 = obj.points[p - 1];
        }
        Y5 = getNearestPoint(pt1, Y3, Y4);
        snap_threshY = Math.abs(pt1.y - pt2.y);
      }
    }
  });
  // console.log(X2, Y2, X5, Y5);
  var pt3 = pt1;
  var pt4 = pt1;
  if (!X2 && !Y2) {
  } else if (!X2) {
    pt3.y = Y2.y;
  } else if (!Y2) {
    pt3.x = X2.x;
  } else {
    pt3.x = X2.x;
    pt3.y = Y2.y;
  }

  if (!X5 && !Y5) {
  } else if (!X5) {
    pt4 = Y5;
  } else if (!Y5) {
    pt4 = X5;
  } else {
    pt4.x = X2.x;
    pt4.y = Y2.y;
  }

  console.log(pt3, pt4);
  return [pt3, pt4];
}

function getNearestPoint(qp, p2, p3) {
  // console.log(qp, p2, p3);
  var d1 = Math.sqrt((qp.x - p2.x) ** 2 + (qp.y - p2.y) ** 2);
  var d2 = Math.sqrt((qp.x - p3.x) ** 2 + (qp.y - p3.y) ** 2);
  if (d1 < d2) {
    return p2;
  } else {
    return p3;
  }
}

function getSlope(p1, p2) {
  var xs = p2.x - p1.x;
  var ys = p1.y - p2.y;
  if (xs == 0 && ys == 0) {
    return 0;
  } else if (xs == 0) {
    return "nan";
  } else {
    return ys / xs;
  }
}

function getCoeff(p2, m) {
  store.c = -p2.y - m * p2.x;
  return store.c;
}
export { getNearestEdge, getNearestPoint, getSlope, getCoeff };
