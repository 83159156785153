/*jshint esversion: 6 */
"use strict";
import * as log from "loglevel";
import _ from "lodash";
import { RoomEngine,CreateCurvedRoom,CreateNormalRoom } from "./snaptrudeEngine.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";

/**
 * { Initializes the wall builder engine }
 *
 * @class      WallBuilder (name)
 * @return     {<Object>}  { builder object which can be used to contruct walls }
 */
function WallBuilder() {
  /**
   * { init wallMaker object }
   */
  this.walls = new WallMaker();
  /**
   * { Step 1 of wall generation process, i.e construct walls for all levels and structures }
   */
  this.step1 = function (mass) {
    this.walls.createWalls(mass);
  };
  /**
   * { Step 2 of wall generation process, i.e resolve overlapping of walls }
   */
  this.step2 = function (mass) {
    //this.walls.resolveIntersection();
  };

  this.step3 = function (mass) {
    //pass
  };

  //add any other step

  /**
   * { Get the wall maker object }
   *
   * @return     {<Object>}  {returns the wall maker object }
   */
  this.get = function () {
    return this.walls;
  };
}
/**
 * { Responsible for creating all types of walls, uses command pattern for wall generation process }
 *
 * @class      WallMaker (name)
 */
function WallMaker() {
  this.walls = [];

  /**
   * Creates walls for all the rooms in scene.
   */
  this.createWalls = function (mass) {
    var roomEngine = new RoomEngine();
    var structureCollection = StructureCollection.getInstance();
    var structure = structureCollection.getStructureById(mass.structure_id);
    var level = structure.getLevelByUniqueId(mass.level_id);

    try {
      if (mass.mesh.room_curve) {
        //TODO add type to mass datastructure
        roomEngine.execute(new CreateCurvedRoom(mass.mesh));
      } else {
        roomEngine.execute(new CreateNormalRoom(mass.mesh));
      }
      //add more
    } catch (e) {
      log.error(e);
      // var errorMessage = new ErrorMessage(mass.mesh, "error");
      // errorMessage.show("Ooops, can't generate wall for this structure");
      // mass.mesh.material = errorMessage.material;
    }

    log.log(roomEngine.getCurrentValue());
    let walls = roomEngine.getCurrentValue();
    _.flatten(walls).forEach(function (wall) {
      try {
        level.addWallToLevel(wall, false);
        wall.groupId = mass.groupId;

        createBuildingEngine.addComponent(wall);
      } catch (e) {
        console.log("add to level error");
      }
    });

    walls.length = 0;
  };

  /**
   * { Resolve intersection method }
   */
  this.resolveIntersection = function () {
    return;
    //TODO
  };
}
// function run(){
//     var procedure = new Procedure();
//     var wallBuilder = new WallBuilder();

//     var level = procedure.construct(wallBuilder);
// }
export { WallBuilder,WallMaker };
