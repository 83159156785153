import React, { useRef, useState } from 'react'
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import { deleteTeam } from '../../services/team.service';
import { leaveTeamUI } from '../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice';
import { colors } from '../../themes/constant';
import Button from '../Button';
import { modalStylesTeam } from './style';

const Wrapper = styled.div`
    // border: 2px solid;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
`
const ModalWrapper = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    // min-width: 20%;
    // min-height: 20%;
    .heading{
        font-weight: 700;
        font-size: 24px;
        color: ${colors.primeBlack};
    }
    .subheading{
        font-weight: 400;
        font-size: 14px;
        color: ${colors.greyishBlack};
    }
    .actions{
        display: flex;
        flex-direction: row;
        z-index: 5;
    }
    .button-wrapper{
        // border: 2px solid crimson;
        width: 100%;
        // padding: 0.5em 3em;
        z-index: 5;
    }
    .page-indicator{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .indicator{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 0px 1px;
        background: #F2F2F2;
    }
    .active{
        background: #818181;
    }
`

const BodyWrapper = styled.div`
    position: relative;
    width: 35em;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: ${colors.primeBlack};
    padding: 1em;
    & > div{
        // border: 2px solid;
        margin: 0em 0.5em;
        padding: 0.25em;
        text-align: center;
    }

`
function DeleteTeamModal({ team, handleClose, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        setIsLoading(true);
        const resp = await deleteTeam(team.id);
        if (resp.status === "success") {
            dispatch(leaveTeamUI({ teamId: team.id }));
            setTimeout(() => {
                handleClose();
            }, 500);
        }
        setIsLoading(false);
    }
    const ref = useRef(null);
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={handleClose}
            style={modalStylesTeam}
        >
            <Wrapper>
                <ModalWrapper ref={ref}>
                    <BodyWrapper>
                        <div className="subheading" style={{ marginTop: "2em" }}>Are you sure you want to delete your team <strong>{team.title}</strong></div>
                        <div className="actions" style={{ marginTop: "3em" }}>
                            <div className="button-wrapper">
                                <Button customButtonStyle={{ backgroundColor: colors.fullWhite, color: colors.secondaryGrey }} title={"Cancel"} onPress={handleClose} />
                            </div>
                            <div className="button-wrapper">
                                <Button isLoading={isLoading} primary={true} title={"Delete"} onPress={handleDelete} />
                            </div>
                        </div>
                    </BodyWrapper>
                </ModalWrapper>
            </Wrapper>
        </ReactModal>
    );
}

export default DeleteTeamModal;