import React from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import { DragDropWrapper } from "../DragDrop";
import Folder from "./Folder";
import { moveToFolder } from '../../services/folder.service';
import addIcon from "../../assets/icons/addIcon.svg";
import { useFileSystemUpdate } from "../../contexts/FileSystemContext";
const FoldersWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1em 1em;
    a{
        color: ${colors.thinGray};
        text-decoration: none;
    }
    a:visited{
        color: ${colors.thinGray};
        text-decoration: none;
    }
`
const Title = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5em;
    font-weight: bold;
    height: 20px;
    position: sticky;
    top: 0;
    z-index: 15;
    background-color: ${colors.midWhite};
    span{
        cursor: pointer;
        img{
            display: block;
            transition: transform 0.2s;
        }
    }
    img:hover{
        transform: scale(1.5);
    }
`
function FoldersContainer({folders, workspaceRoute, permissions, parentFolder, hideAddButton, viewBy}) {
    const fileSystemUpdate = useFileSystemUpdate();
    if(!folders) return null;
    return ( 
        <React.Fragment>
            <Title>
                <div>Folders</div>
                {
                    hideAddButton ? null : permissions["create_rename_delete_folder"] &&
                        <span onClick={() => {fileSystemUpdate(parentFolder?.team_dashboard, parentFolder?.id, "create")}}>
                            <img src={addIcon} alt="add" />
                        </span>
                }
            </Title>
            <FoldersWrapper>
                {
                    folders.map((folder, i) => {
                        return (
                            <DragDropWrapper
                                ddId={"dragDrop_folder_" + i}
                                key={folder.id}
                                id={folder.id}
                                bucketName="folders"
                                type="folder"
                                data={folder}
                                accept={["folder", "file"]}
                                onDrop={handleItemsMove}
                                enableDrop
                            >
                                <Folder folder={folder} workspaceRoute={workspaceRoute} permissions={permissions} viewBy={viewBy}/>
                            </DragDropWrapper>
                        )
                    })
                }
            </FoldersWrapper>
        </React.Fragment>
     );
}

export default FoldersContainer;

export const handleItemsMove = (items, droppedOn) => {
    const teamId = droppedOn?.team_dashboard
    const destFolderID = droppedOn?.id
    const foldersId = []
    const floorkeys = []
    const folders = []
    const projects = []
    let sourceFolderId = null
    
    if(Array.isArray(items) && items.length) {
        items.forEach((item) => {
            if(item?.type == 'folder') {
                foldersId.push(item?.id)
                folders.push(item)
            }
            if(item?.type == 'file') {
                floorkeys.push(item?.floorkey)
                projects.push(item)
            }
        })
        const firstItem = items[0]
        if(firstItem?.type == 'folder') sourceFolderId = firstItem?.immediate_parent || 'root'
        else if(firstItem?.type == 'file') sourceFolderId = firstItem?.folderId || 'root'
    }
    if(!foldersId.length && !floorkeys.length) return

    moveToFolder(teamId, sourceFolderId, destFolderID, foldersId, floorkeys, folders, projects)
}