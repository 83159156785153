import axios from "axios";
import { isAcademic } from "swot-node";
import { showToast, TOAST_TYPES } from "../snaptrude/modules/extrafunc";
import { store } from "../snaptrude/modules/utilityFunctions/Store";
import { resetTeams } from "../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import reduxStore from "../snaptrude/stateManagers/store/reduxStore";
import authHeader from "./auth.header"
import { getJWT } from "./jwt.service";
import { getCountryFromIP, updateCurrency } from "./payment.service";
import { ORIGIN, socketUrl } from "./url.constants";
const API_URL = "http://localhost:8000/";
const API_URL_OLD = "http://localhost:8000";

const register = async (email, fullName, phoneNumber, password) => {
  let country = await getCountryFromIP();
  const isStudent = await isAcademic(email);

  const qp = new URLSearchParams(window.location.search);
  let ref_email = qp.get("ref");
  if (!ref_email) ref_email = "";

  const data = {
    username: email,
    email: email,
    first_name: fullName,
    password,
    phone: phoneNumber,
    profession: isStudent ? "Student" : "Developer",
    country: country,
    isStudent: isStudent,
    ref_email: ref_email,
    origin: window.location.origin
  }
  const formData = new FormData();
  for (let item in data) {
    formData.append(item, data[item]);
  }
  return axios
    .post(ORIGIN + "/register/", formData)
    .then((response) => {
      if (response.data.message !== "Invalid" && response.data.message !== "Already Exists") {
        const { refreshToken, accessToken } = response.data;
        localStorage.setItem("user", JSON.stringify(accessToken));
        localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
        localStorage.setItem("id", response.data.id);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("name", response.data.fullname);
        localStorage.setItem("phone", response.data.phone);
        localStorage.setItem("isPro", response.data.isPro);
        localStorage.setItem("country", response.data.country);
        if (response.data.activePlan) {
          localStorage.setItem("activePlan", response.data.activePlan);
        }
        postUserSignup();
        updateCurrency();
      }
      return response.data.message;
    });
};

const postUserSignup = () => {
  const data = {
    jwt: getJWT(),
  }
  axios.post(socketUrl + "/newusersignup", data)
    .then(res => {
      // console.log(res);
    })
    .catch(err => {
      console.log(err);
    })
}

const setRoleAndDesignation = async (role, industry, country, city, usecases, phone) => {
  if (!country) {
    country = await getCountryFromIP();
  }
  const data = {
    profession: industry,
    role: role,
    country: country,
    city: city,
    usecases: usecases,
    phone: phone,
  // cardnumber: cardnumber
  }
  const formData = new FormData();
  for (let item in data) formData.append(item, data[item]);
  return axios.post(ORIGIN + "/selectProfession/", formData)
    .then(res => {
      return res.data.message;
    });
};

const changePassword = (username, currentPassword, newPassword) => {
  return axios.post(ORIGIN + "/changePassword/", {
    username,
    currentPassword,
    newPassword,
  });
};

const login = (username, password, isGoogleLogin = false) => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("isGoogleLogin", isGoogleLogin);
  return axios.post(ORIGIN + "/snaplogin/", formData).then((response) => {
    if (response.data.accessToken && response.data.refreshToken) {
      localStorage.setItem("user", JSON.stringify(response.data.accessToken));
      localStorage.setItem("id", response.data.id);
      localStorage.setItem("email", response.data.email);
      localStorage.setItem("name", response.data.fullname);
      localStorage.setItem("phone", response.data.phone);
      localStorage.setItem("isPro", response.data.isPro);
      localStorage.setItem("profilePictureUrl", response.data.profilePictureUrl);
      localStorage.setItem("country", response.data.country);
      localStorage.setItem("emailActivated", response.data.emailActivated)
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(response.data.refreshToken)
      );
      if (response.data.activePlan) {
        localStorage.setItem("activePlan", response.data.activePlan);
      }
      updateCurrency();
    }

    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("id");
  localStorage.removeItem("email");
  localStorage.removeItem("numberOfProjects");
  localStorage.removeItem("activePlan");
  reduxStore.dispatch(resetTeams());
  return new Promise((resolve, reject) => {
    axios
      .post(ORIGIN + "/logout/")
      .then((resp) => {
        if (resp.data.error === 0) {
          localStorage.clear();
          resolve({ error: 0 });
        } else {
          reject({ error: 1 });
        }
      })
      .catch((err) => {
        console.log(err);
        reject({ error: 1, message: err });
      });
  });
};

const getCurrentUser = () => {
  let numberOfProjects = localStorage.getItem('numberOfProjects');
  let id = localStorage.getItem("id");
  return {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    phone: localStorage.getItem("phone"),
    isPro: localStorage.getItem("isPro") === 'true',
    numberOfProjects: numberOfProjects ? parseInt(numberOfProjects) : 0,
    id: id ? parseInt(id) : null,
    emailActivated: (localStorage.getItem("emailActivated") == "true")
  };
};

const isAuthenticated = () => {
  let user = getCurrentUser();
  if (user) {
    return true;
  } else {
    return false;
  }
};

const sendResetPasswordMail = (email) => {
  const data = {
    username: email,
  }
  const formData = new FormData();
  for (let item in data) {
    formData.append(item, data[item]);
  }
  return axios.post(ORIGIN + "/sendResetPasswordMail/", formData)
    .then(resp => {
      const { data } = resp;
      if (data.error) return { status: "fail", message: data.message }
      return { status: "success", message: data.message }
    })
    .catch(err => {
      console.error(err);
      return { status: "error", message: "Something went wrong, try again." }
    })
}

const resetPassword = (token, password) => {
  const data = {
    token,
    password
  }
  const formData = new FormData();
  for (let item in data) {
    formData.append(item, data[item]);
  }
  return axios.post(ORIGIN + "/resetPassword/", formData)
    .then(resp => {
      const { data } = resp;
      if (data.error) return { status: "fail", message: data.message };
      return { status: "success", message: data.message };
    })
    .catch(err => {
      console.error(err);
      return { status: "error", message: "Something went wrong, try again." };
    })
}

// Used only for student plans
const getActivePlan = () => {
  const timeDifference = (upcoming, current = new Date()) => {
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;

    let elapsed = upcoming - current;

    if (elapsed < msPerMinute) {
      return Math.abs(Math.round(elapsed / 1000)) + " seconds remaining";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes remaining";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours remaining";
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + " days remaining";
    } else {
      return (
        Math.round(elapsed / msPerMonth) + " months remaining"
      );
    }
  };

  let activePlan = null;
  const activePlanString = localStorage.getItem("activePlan");
  if (activePlanString) {
    const splited = activePlanString.split("::");
    const expiry = new Date(splited[1]);
    let remaining = null;
    if (expiry) {
      remaining = timeDifference(expiry);
    }
    activePlan = {
      name: splited[0],
      expiry: expiry,
      remaining: remaining
    }
  }
  return activePlan;
}

const sendInvite = (email) => {
  const invitedBy = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  if (!invitedBy) {
    showToast("Something went wrong, Refresh and try again", 3000, TOAST_TYPES.error);
    return { success: false };
  }
  const data = {
    name,
    email,
    invitedBy,
    origin: window.location.origin
  }
  const formData = new FormData();
  for (let item in data) {
    formData.append(item, data[item]);
  }
  return axios.post(ORIGIN + "/sendInvite/", formData)
    .then(resp => {
      return resp.data;
    })
    .catch(err => {
      console.log(err);
      return { success: false, message: "Something went wrong, Refresh and try again" }
    })
}

const getAllInvites = () => {
  const email = localStorage.getItem("email");
  const data = {
    email
  }
  const formData = new FormData();
  for (let item in data) {
    formData.append(item, data[item]);
  }
  return axios.post(ORIGIN + "/getAllInvites/", formData)
    .then(resp => {
      const { invites, activePlan } = resp.data.payload
      localStorage.setItem("activePlan", activePlan);
      return { success: true, invites }
    })
    .catch(err => {
      showToast("Something went wrong while getting all invites, refresh and try again.", 3000, TOAST_TYPES.error)
      return { success: false }
    })
}

const activateEmail = (uidb64, token) => {
  // const data ={
  //   uidb64,
  //   token,
  // }
  // const formData = new FormData();
  // for(let item in data) formData.append(item, data[item]);
  return axios.get(ORIGIN + "/activate/" + uidb64 + "/" + token + "/")
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      console.log(err);
      return { success: false, message: "Something went wrong try again." }
    })
}

export const isCurrentUser = (email) => {
  return email === localStorage.getItem('email');
}

export const isB2BPaid = async () => {
  const endpoint = `/paywall/check/`;
  try {
    const resp = await axios.post(ORIGIN + endpoint, null, { validateStatus: false });
    if (resp.status === 200) {
        return { status: "success", isB2BPaid: true }
    } else if(resp.status === 401){
      return {status: "success", isB2BPaid: false}
    } 
    else {
      return { status: "fail", message: resp.data.message || "Could not get B2B paid info., try again." }
    }
  } catch (error) {
    let message = "Something went wrong while getting B2B paid info.";
    if (error && error.message) message = error.message;
    return { status: "error", message };
  }
}

export const sendActivationMail = async () => {
  const endpoint = "/sendActivationMail/";
  const errMsg = "Something went wrong while sending verification email. Please try again later."
  try {
    const formdata = new FormData()
    formdata.append("origin", window.location.origin)
    const resp = await axios.post(ORIGIN + endpoint, formdata);
    if (resp.status === 200) return { status: "success", sent: true}
    else if(resp.status === 401) return {status: "success", sent: false, message: errMsg }
    else return { status: "fail", sent: false, message: resp.data.message || errMsg }
  } catch (error) {
    console.log(error)
    return { status: "error", sent: false, message: errMsg };
  }
}

export default {
  changePassword,
  register,
  login,
  logout,
  getCurrentUser,
  setRoleAndDesignation,
  isAuthenticated,
  sendResetPasswordMail,
  resetPassword,
  getActivePlan,
  sendInvite,
  getAllInvites,
  activateEmail,
  isCurrentUser,
  sendActivationMail
};
