import { store } from "../modules/utilityFunctions/Store";
import reduxStore from "../stateManagers/store/reduxStore";
import { takeToComment } from "./sceneFuncs";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler";
import {getV3ProjectedOntoScreenSpace} from "../modules/extrafunc.js"
import { scenePickController } from "../modules/utilityFunctions/scenePickController";
import { isCADMesh } from "./snapUtilities";
import { is2D } from "./twoDimension";

const LAYERTYPES = ["FloorPlan", "cadSketch", "pdf"]
class CommentSpriteElem{
    constructor(innerText){
        const elem = document.createElement('div');
        elem.classList.add("comment-pin");
        const numberElem = document.createElement("span");
        numberElem.innerText = innerText;
        elem.appendChild(numberElem);

        this.elem = elem;
    }
}

class CommentSprite {
    constructor(comment, position, localIndex){
        this.comment = comment;
        this.elem = new CommentSpriteElem(localIndex).elem;
        this.pos = position;
        this.width = 32;
        this.height = 32;
        this.localIndex = localIndex;
        this.isActive = false;
        this.elem.onclick = this.select;
        this.elem.style.visibility = (comment.isResolved || comment.isDeleted) ? "hidden" : "visible";
        this.hiddenForView = false;
        this.hiddenForStorey = false;
        this.alwaysHidden = (comment.isResolved || comment.isDeleted);
    }
    static activeSprite = null;
    
    displayCommentComponent = () => {
        
        window.dispatchEvent(
            new CustomEvent("set-comment", {
              detail: this.comment,
            })
          );
        const newPos = getV3ProjectedOntoScreenSpace(this.pos);
        const offsetLeft = newPos.x;
        const offsetTop = newPos.y;
        const showCommentWrapper = document.getElementById("show-comment-wrapper");
        showCommentWrapper.style.left = offsetLeft + 30 + "px";
        showCommentWrapper.style.top = offsetTop - 45 + "px";
        showCommentWrapper.style.display = "block";
    }

    closeCommentComponent = () => {
        window.dispatchEvent(
            new CustomEvent("set-comment", {
              detail: null,
            })
          );
    }
    select = () => {
        // close add comment dialog box if open
        const commentWrapper = document.getElementById("add-comment-wrapper");
        if(commentWrapper) commentWrapper.style.display = "none";
        
        // deselect previously selected sprite, and select the current sprite;
        if(CommentSprite.activeSprite){
            CommentSprite.activeSprite.deselect()
        }
        CommentSprite.activeSprite = this;
        this.show(true);

        // reflect the active status in UI.
        this.isActive = true;
        this.elem.classList.add("active");

        commentOperator.sprites.forEach(sprite => {
            if(sprite !== this) sprite.hide();
        })

        // show the actual comment in UI.
        this.displayCommentComponent();

    }
    deselect = () => {
        this.isActive = false;
        if(!store.$scope.isTwoDimension) this.hide(true);
        this.elem.classList.remove("active");
        this.closeCommentComponent();
        CommentSprite.activeSprite = null;
    }
    hide = (forced) => {
        if(!forced){
            if(this.alwaysHidden) return;
            if(this.hiddenForView) return;
            if(this.hiddenForStorey) return;
        }
        this.elem.style.visibility = "hidden";
    }
    show = (forced=false) => {
        if(!forced){
            if(this.alwaysHidden) return;
            if(this.hiddenForView) return;
            if(this.hiddenForStorey) return;
        }
        this.elem.style.visibility = "visible";
    }

    updateVisibilityForViewChange = (toBeHidden) => {
        this.hiddenForView = toBeHidden;
    }
    updateVisibilityForStorey = (toBeHidden) => {
        this.hiddenForStorey = toBeHidden;
    }
    static activeButton = null;
}

const commentOperator = (() => {
    const sprites = [];
    let totalSprites = -1;
    let isUpdateActive = false;
    const acitvateUpdate = () => {
        isUpdateActive = true;
    }
    const deactivateUpdate = () => {
        isUpdateActive = false;
    }

    const onPointerUp = (evt) => {
      // Don't respond to mouse middle button
      if (evt && (evt.which == 2 || evt.button == 4)) return;
      if (CommentSprite.activeSprite) CommentSprite.activeSprite.deselect();
      // const x = evt.clientX;
      // const y = evt.clientY;
      const x = store.scene.pointerX;
      const y = store.scene.pointerY;
      const camera = store.scene.activeCamera;
      const cameraPosition = {
        position: camera.position,
        alpha: camera.alpha,
        beta: camera.beta,
        radius: camera.radius,
        target: camera.target,
        isOrtho: store.$scope.isTwoDimension,
        orthoLeft: camera.orthoLeft,
        orthoRight: camera.orthoRight,
        orthoBottom: camera.orthoBottom,
        orthoTop: camera.orthoTop,
      };

      let options = {
        faceSnap: true,
        wantMetadata: true,
        firstIndicators: true,
        storeyCheck: true,
        doNotShowIndicators: true,
      };

      /*
            Because of the cursor icon being used, there is space in cursor-pin-drop position and pin-icon-point position.
            To adjust for this space, extra 16.5(half of icon width) and 20(half of icon height) is added.
            A better approach could be possible. Need to think over it.
        */
      // const pickInfo = store.scene.pick(x+16.5, y+20);
      let componentAttachedTo = null;
      const requests = [
        {
          predicate: null,
          pickWithBoundingInfo: false,
          doSimplePick: true,
          includeFloorPlans: true,
          pickLockedMeshes: true,
          includeVoids: true,
        },
      ];

      // theoretically, pickWithBoundingInfo: true should work
      // but getting incorrect pick results when thin glass curtain walls are involved
      // since it's set to false now, have to have a separate request for cad meshes

      if (is2D()) {
        requests.unshift({
          predicate: null,
          pickDWIndicators: true,
        });

        requests.push({
          predicate: isCADMesh,
          pickWithBoundingInfo: true,
        });
      }
      const pickInfo = scenePickController.sequentialPick(requests);
      if (pickInfo && pickInfo.pickedMesh) {
        if (LAYERTYPES.includes(pickInfo.pickedMesh._type)) {
          const storey = pickInfo.pickedMesh.storey;
          componentAttachedTo = pickInfo.pickedMesh._type + "::" + storey;
        } else {
          componentAttachedTo = pickInfo.pickedMesh.getSnaptrudeDS().id;
        }
      } else {
        return;
      }
      // let pickedPoint = findSecondarySnappedPoint(null, null, null, options);
      // let pickedPoint = pickInfo.pickedPoint;
      let { pickedPoint } = store.scene.pick(x, y,(mesh)=>true);
      if (pickedPoint) {
        const { _x, _y, _z } = pickedPoint;
        store.newComment.sceneData = {
          isAttachedToScene: true,
          camera: cameraPosition,
          component: {
            dsPropsId: componentAttachedTo,
          },
          absolutePosition: { _x, _y, _z },
        };
        const commentWrapper = document.getElementById("add-comment-wrapper");
        commentWrapper.style.left = x - 30 + "px";
        commentWrapper.style.top = y - 40 + "px";
        commentWrapper.style.display = "block";
      }
    };

    const update = () => {
        if(!isUpdateActive) return;
        if (sprites.length === 0) return;
        const canvasZone = document.getElementById("canvas-zone");
        sprites.forEach((sprite, index) => {
            const newPos = getV3ProjectedOntoScreenSpace(sprite.pos);
            var px = newPos.x - 16;
            var py = newPos.y - 35.5;

            sprite.elem.style.left = px + "px";
            sprite.elem.style.top = py + "px";
        })
        if(CommentSprite.activeSprite){
            const activeSprite = CommentSprite.activeSprite;
            const newPos = getV3ProjectedOntoScreenSpace(activeSprite.pos);
            const offsetLeft = newPos.x;
            const offsetTop = newPos.y;

            const showCommentWrapper = document.getElementById("show-comment-wrapper");
            showCommentWrapper.style.left = offsetLeft + 30 + "px";
            showCommentWrapper.style.top = offsetTop - 45 + "px";
            showCommentWrapper.style.display = "block";
        }
    }

    const onCancelComment = (comment) => {
        const activeSprite = CommentSprite.activeSprite;
        if(activeSprite){
            activeSprite.hide(true);
            activeSprite.deselect();
        } 
        sprites.forEach(sprite => sprite.show())
    }

    const selectCommentFromOutside = (comment) => {
        sprites.forEach(sprite => {
            if(sprite.comment.id === comment.id) sprite.select();
        })
    }

    const drawCommentPins = () => {
        // let isNewCommentAdded = false;
        const {comments} = reduxStore.getState();
        // if(comments.comments.length === sprites.length + 1) isNewCommentAdded = true;
        clearCommentPins();
        const canvasZone = document.getElementById("canvas-zone");
        comments.comments.forEach((comment, index) => {
            if(comment.scene && comment.scene.absolutePosition){
                const sprite = new CommentSprite(comment, comment.scene.absolutePosition, index+1);
                sprites.push(sprite);
                canvasZone.appendChild(sprite.elem);
            }
        })
        acitvateUpdate();
        uiIndicatorsHandler.markForUpdate();
        
        // SELECT THE NEWLY ADDED COMMENT
        // if(isNewCommentAdded && sprites.length > 1) onCommentSelect(sprites.length-1);
    }

    const clearCommentPins = () => {
        const canvasZone = document.getElementById("canvas-zone");
        while(sprites.length > 0){
            const sprite = sprites.pop();
            canvasZone.removeChild(sprite.elem);
        }
        deactivateUpdate();
    }


    const onCommentSelect = (index) => {

        const sprite = sprites[index];
        if(sprite){
            takeToComment(sprite.comment.scene);
            sprite.select();
        }
    }

    const showCommentPin = (index) => {
        if(sprites[index]){
            sprites[index].show();
        }
    }

    const updateVisibilityForViewChange = (toBeHidden=true, index=null) => {
        if(index === null) sprites.forEach(sprite => sprite.updateVisibilityForViewChange(toBeHidden));
        else{
            sprites[index].updateVisibilityForViewChange(toBeHidden);
        }
    }

    const updateVisibilityForStorey = (toBeHidden = true, index = null) => {
        if(index === null) sprites.forEach(sprite => sprite.updateVisibilityForStorey(toBeHidden))
        else {
            sprites[index].updateVisibilityForStorey(toBeHidden);
        }
    }

    const hideAll = () => {
        sprites.forEach(sprite => sprite.hide(true));
        if(CommentSprite.activeSprite){
            CommentSprite.activeSprite.show(true);
        }
    }
    const showAll = () => {
        sprites.forEach(sprite => sprite.show());
    }

    const showStoreywiseComments = (storey, viaCommentSelect) => {
        if(!storey) storey = {comments: []}
        let doDeselect = true;
        hideAll();
        updateVisibilityForStorey(true);
        storey.comments.forEach(c => {
            commentOperator.updateVisibilityForStorey(false, c.localIndex);
            if(CommentSprite.activeSprite && CommentSprite.activeSprite.comment.id === c.id){
                doDeselect = false;
            } 
        })
        if(CommentSprite.activeSprite && doDeselect){
            CommentSprite.activeSprite.hide(true);
            CommentSprite.activeSprite.deselect();
        } 
        if(!viaCommentSelect) commentOperator.showAll();
    }

    const showNewlyAddedComment = () => {
        const canvasZone = document.getElementById("canvas-zone");

        const {comments} = reduxStore.getState();
        const index = comments.comments.length-1;
        const newComment = comments.comments[index];
        let newSprite;
        if(sprites.length !== comments.comments.length){
            newSprite = new CommentSprite(newComment, newComment.scene.absolutePosition, index+1);
            sprites.push(newSprite);
            canvasZone.appendChild(newSprite.elem);
            acitvateUpdate();
            uiIndicatorsHandler.markForUpdate();
        }else{
            newSprite = sprites[sprites.length-1];
        }
        newSprite.select();
    }

    return {
        sprites,
        onPointerUp,
        onCommentSelect,
        update,
        onCancelComment,
        drawCommentPins,
        selectCommentFromOutside,
        clearCommentPins,
        updateVisibilityForViewChange,
        updateVisibilityForStorey,
        hideAll,
        showAll,
        showStoreywiseComments,
        showNewlyAddedComment,
    }
})();

export {
    commentOperator
}