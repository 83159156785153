import React, {useEffect, useState} from 'react';
import styled, { css } from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from '../Button';
import { SingleUploadOverView } from "../../containers/genericModal/singleUploadOverView";
import MultipleUploadOverview from './MultipleUploadOverview';
import ScrollableDropdown from '../ScrollableDropdown';
import ProgressBar from "../ProgressBar";

const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 1em;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
  display: flex;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 100,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};
const Container = styled.div`
  flex: ${props => props.flex ? props.flex : 2};
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Row = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${props => props.thinRow && css`
    padding-top : 5px;
    padding-bottom : 5px;
  `}
  
`
const Col = styled.div`
  // border: 2px solid crimson;
  flex: 1;
`
const HighlightedText = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  white-space: nowrap;
  background-color: ${colors.transparent};
  color: ${colors.brightRed};
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`

function SnaptrudeImportFunctionalityModal({
  isOpen,
  onClose,
  modalHeaderText,
  bodyHeaderText,
  allowedTypes,
  DropzoneComponent,
  uploadedFile,
  handleCancelFile,
  resetUploadedFile,
  onUpload,
  type,
  typeOptions,
  typeOptionsTitle,
  setType, 
  hasUnit,
  unit,
  unitOptions,
  setUnit,
  showProgressBar,
  progress,
  progressText,
  isSingleUpload = false,
  selectedStorey, 
  storey, 
  setStorey,
}) {

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
      id="snaptrudeImportFunctionalityModal"
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Import {modalHeaderText}
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          {
            uploadedFile ?
              isSingleUpload ?
              <SingleUploadOverView customProps={{files: uploadedFile, selectedStorey, storey, setStorey, showProgressBar}} />
              :
              <MultipleUploadOverview files={uploadedFile} handleCancelFile={handleCancelFile} />
            :
              <>
                <Container flex={3}>
                  <Row>
                    {bodyHeaderText}
                  </Row>
                  <Row style={{flexDirection: "row"}}>
                    {allowedTypes.map((type, index) => {
                      return ( 
                        <div key={type.name + index} style={{margin: "0px 5px", textAlign: "center"}}>
                          <div><img src={type.src} alt={type.name}/></div>
                          <div>{type.name}</div>
                        </div>
                      )
                    })}
                  </Row>
                  <Row>
                    <HighlightedText>{DropzoneComponent}</HighlightedText> &nbsp; or Drag and drop your files here.
                  </Row>
                </Container>
                {
                  (hasUnit || typeOptions) &&
                  <Container>
                    {
                      typeOptions &&
                      <>
                      <Row thinRow>
                        <Col>{typeOptionsTitle ? typeOptionsTitle : "Type"}</Col>
                      </Row>
                      <Row thinRow>
                        <Col>
                          <ScrollableDropdown
                            selectedOption={type}
                            options={typeOptions}
                            height="15em"
                            onChange={(item) => {setType(item)}}
                            width={"auto"}
                            cutomTextStyle={{
                              paddingLeft: 0,
                              fontWeight:"bold"
                            }}
                          />
                        </Col>
                      </Row>
                      </>
                    }
                    {
                      hasUnit ? 
                      <Row>
                        <Col>Unit</Col>
                        <Col>
                            <ScrollableDropdown 
                              selectedOption={unit}
                              options={unitOptions}
                              height="auto"
                              onChange={(item) => {setUnit(item)}}
                            />
                        </Col>
                      </Row>
                      :
                      <></>
                    }
                  </Container>
                }
              </> 
          }
        </ModalBody>
        <ModalFooter>
        {showProgressBar ?
            <ProgressBar bgColor={colors.red} completed={progress} text={progressText}/>:
            <>
          <ButtonWrapper>
            {
              uploadedFile ? 
                <Button onPress={resetUploadedFile} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title={"< Back to " + modalHeaderText} />
                :
                <Button onPress={onClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Back" />
            }
          </ButtonWrapper>
          <ButtonWrapper>
            <Button disabled={uploadedFile?.length === 0} primary={uploadedFile?.length > 0} title="Import" onPress={onUpload} />
          </ButtonWrapper>
          </>
          }
        </ModalFooter>
      </Card>
    </ReactModal>
  )
}

export default SnaptrudeImportFunctionalityModal
