import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { computeBounds } from "./zoomFuncs.js";
import { nonDefaultMeshForSnapping } from "./sceneStateFuncs.js";
import {
  disposeHighlightedFace,
  faceIndicatorName,
  showFaceIndicator,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import { getFaceVerticesFromFacetID } from "./brepOperations.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { getNormalVector } from "./mathFuncs.js";
import { getVector3FromArray } from "../modules/extrafunc.js";
import { getAngleInRadians } from "./snapFuncs.js";
let sectionViews = (function () {
  let _createPlane = function (size, position) {
    let plane = BABYLON.Mesh.CreatePlane("sectionPlane", size, store.scene);
    // plane.rotate(new BABYLON.Vector3(1,0,0), Math.PI/2);
    plane.position = position;
    plane.material = new BABYLON.StandardMaterial("planeMaterial", store.scene);
    plane.material.diffuseColor = new BABYLON.Color3(1.0, 0, 0);
    plane.material.wireframe = false;
    plane.visibility = 0.2;
    return plane;
  };

  let _showSlider = function (plane, mesh) {
    const sliderWidth = "170px";
    let advancedTexture =
      BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

    let panel = new BABYLON.GUI.StackPanel();
    panel.width = "200px";
    panel.background = "#ffffff";
    panel.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    panel.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    panel.paddingTop = "-10px";
    panel.paddingLeft = "-10px";
    panel.paddingBottom = "-10px";
    advancedTexture.addControl(panel);

    // let header = new BABYLON.GUI.TextBlock();
    // header.text = "Y";
    // header.width = "190px";
    // header.paddingTop = "10px";
    // header.textVerticalAlignment= BABYLON.GUI.Control.VERTICAL_ALIGNMENT_TOP;
    // header.textHorizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    // header.color = "green";
    // panel.addControl(header);

    let slider = new BABYLON.GUI.Slider();
    // slider.name = "Y";
    let boundingBox = plane.getBoundingInfo().boundingBox;
    slider.minimum = -boundingBox.maximum.y;
    slider.maximum = -boundingBox.minimum.y;
    slider.value = -boundingBox.maximum.y;

    // let clipPlaneY = -boundingBox.maximum.y;
    store.scene.clipPlane1 = new BABYLON.Plane(0, 1, 0, 0);
    slider.paddingTop = "10px";
    slider.height = "30px";
    slider.width = sliderWidth;
    slider.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    slider.color = "green";
    slider.onValueChangedObservable.add(function (value) {
      store.scene.clipPlane1 = new BABYLON.Plane(0, 1, 0, value);
    });

    panel.addControl(slider);
  };

  let _faceVertices = null;
  let pickInfo = null;
  let _allSectionPlanes = [];

  return {
    createInitialPlane: function () {
      _allSectionPlanes.push(_createPlane(30, BABYLON.Vector3.Zero()));
    },

    onPointerDown: function (evt) {
      let plane = store.scene.getMeshByName("sectionPlane");
      store.scene.onBeforeRenderObservable.add(() => {
        // clear depth
        store.engine.clear(undefined, false, true, false);
      });

      if (plane) {
        let planeNormal = plane.getFacetNormal(0);
        let bounds = computeBounds(store.activeLayer.storey);
        let scalingFactor =
          bounds.diagonalLength / plane.getBoundingInfo().diagonalLength;
        plane.scaling.multiplyInPlace(
          new BABYLON.Vector3(scalingFactor, scalingFactor, scalingFactor)
        );

        if (pickInfo.pickedMesh) {
          _showSlider(plane, pickInfo.pickedMesh);
        }

        // mesh.onBeforeRenderObservable.add(function() {
        //     store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, clipPlaneY);
        //     plane.position.set(0, -clipPlaneY, 0);
        //     // store.engine.setStencilBuffer(true);
        // });
        //
        // mesh.onAfterRenderObservable.add(function() {
        //     store.scene.clipPlane = null;
        //     // store.engine.setStencilBuffer(false);
        // });
      }
    },
    onPointerUp: function (evt) {},
    onPointerMove: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        // let faceBox = store.scene.getMeshByName(faceIndicatorName);
        // if(faceBox){
        //     startingFacePosition = jQuery.extend({}, faceBox.getAbsolutePosition());
        //
        //     let options = {
        //         faceSnap: true,
        //         vertexSnap: false,
        //         edgeSnap: false
        //     };
        //
        //     let snapPoint = findSecondarySnappedPoint(pickInfo.pickedMesh, startingFacePosition, null, options);
        //     console.log(options.metaData);
        //
        // }

        let plane = _allSectionPlanes[_allSectionPlanes.length - 1];
        pickInfo = store.scene.pick(
          store.scene.pointerX,
          store.scene.pointerY,
          function (mesh) {
            return nonDefaultMeshForSnapping(mesh);
          }
        );

        if (pickInfo.hit) {
          disposeHighlightedFace(faceIndicatorName);
          _faceVertices = getFaceVerticesFromFacetID(
            pickInfo.faceId,
            pickInfo.pickedMesh,
            BABYLON.Space.WORLD
          );
          showFaceIndicator(
            _faceVertices,
            pickInfo.pickedMesh,
            faceIndicatorName,
            colorUtil.getMaterial(colorUtil.type.preSnap)
          );

          let normalVector = getNormalVector(_faceVertices, false);
          normalVector = getVector3FromArray(normalVector).normalize().negate();

          plane.position = store.scene
            .getMeshByName("faceBox")
            .getAbsolutePosition()
            .addInPlace(normalVector);
          // angleWithX = getAngleInRadians(normalVector, BABYLON.Vector3.Right());
          // angleWithY = getAngleInRadians(normalVector, BABYLON.Vector3.Up());
          // angleWithZ = getAngleInRadians(normalVector, BABYLON.Vector3.Forward().negate());

          let normalToPlane = plane.getFacetNormal(0);
          let normalToFace = pickInfo.getNormal();
          let angleWithNormal = getAngleInRadians(normalToFace, normalToPlane);

          plane.rotate(BABYLON.Axis.Y, -angleWithNormal);
          plane.computeWorldMatrix();
          if (
            getAngleInRadians(normalToFace, plane.getFacetNormal(0)) !== 0 &&
            getAngleInRadians(normalToFace, plane.getFacetNormal(0)) !== Math.PI
          ) {
            plane.rotate(
              BABYLON.Axis.X,
              -getAngleInRadians(normalToFace, plane.getFacetNormal(0))
            );
          }
        }
      }
    },
  };
})();
export { sectionViews };
