import { $SCOPE } from "./CONSTANTS/$scope";
import { ACTIVE_LAYER } from "./CONSTANTS/acitve_layer";
import { PROJECT_PROPERTIES } from "./CONSTANTS/project_properties";
import { ROOM_TYPES } from "./CONSTANTS/room_types";
import { ROOM_TYPE_PROPERTIES } from "./CONSTANTS/room_type_properties";
import { TOLERANCE_LEVELS } from "./CONSTANTS/tolerance_levels";
import { UNITS_TYPE } from "./CONSTANTS/units_type";
import { UNITS_TYPES } from "./CONSTANTS/units_types";
import { UNIT_TOLERANCE } from "./CONSTANTS/unit_tolerance";
// import { SendItToTeam } from '../diagnoses/copyProject';

let _store = {
  canvas: null,
  engine: null,
  scene: null,
  newScene: null,
  renderer: null,
  depthMap: null,
  buffer: null,
  map: null,
  terrainMap: null,
  lastImportedTerrainMap: null,
  lastSearchedLocationData:  {
    lastKnownCoordinates: { lat: 38.907, lng: -77.04 },
    lastKnownZoom: 15,
    bounds: null
  },
  renderer: null,
  depthMap: null,
  buffer: null,
  // UNKNOWN VARIABLES
  room_width_line: null,
  room_height_line: null,
  width_text: null,
  width_text_static: null,
  height_text: null,
  height_text_static: null,
  object_name: null,
  angular: null,
  edgeDimensionLine: null,
  edgeMeasurementText: null,
  objectPropertiesView: null,
  backImg: "",
  bool: null,
  componentHandler: null,
  combineRoofs: null,
  pointerDown: null,
  controlerInput: null,
  findNearestSnapPointOnSameDrawingPath: null,
  drawVertexForSnapping: null,
  checkIfOnEdge: null,
  saveAs: null,
  mixpanel: null,
  reportError: null,
  createBuilding: null,
  posDiffThresh: 1,
  click: null,
  canvasResize: null,
  computeAreaFace: null,
  assignWallThicknessPropertiesToMesh: null,
  getWallOrientation: null,
  url_fplan: null,
  stateStack: [],
  WebsocketFunctions: null,
  gizmoManager: null,
  roomTypeProperties: ROOM_TYPE_PROPERTIES,
  meshData: null,
  mat: null,
  floorplanmode: null,
  numberFlag: 0,
  room_types: ROOM_TYPES,
  activeStoreyHeight: 11.811023622047244,
  units_type: UNITS_TYPE,
  units_types: UNITS_TYPES,
  unit_tolerance: UNIT_TOLERANCE,
  tolerance_levels: TOLERANCE_LEVELS,
  $scope: $SCOPE,
  ACTIVE_EVENT: {
    event: null,
  },
  isOrbitModeActive: false,
  isEyeDropperSelection: false,
  sceneScreenshot: [],
  newSceneViewData: [],
  newComment: {
    position: null,
  },
  newProject: true,
  newProjectUnit: 1,
  curtains: null,
  storeyNumForImport: 1,
  // VARIABLES FROM sketch.js
  thresh_slider: 0,
  img: `new Image()`,
  img: {
    src: "{{ documents.url }}",
  },
  w_w: "getWidth()",
  w_h: "getHeight()",
  w_w: `w_w - 150`, //Adjusting the Offset
  w_h: `w_h - 40`,
  w: null,
  h: null,
  // Computing the scale factor w.r.t the opencv module to
  // align coordinated systems
  scale_factor: null,
  imgWidth: null,
  imgHeight: null,
  data: null,
  ctx: null,
  imageData: null,
  canvas_img: null,
  ctx_img: null,
  prev_thresh_value: null,
  prev_evt_mode: null,
  url_send: `'{% url "sketch" 0 %}'.replace("0", "{{floorkey}}")`,
  url: `'{% url "sketch" 0 %}'.replace("0", "{{floorkey}}")`,
  prev_scale_factor: "{ { room_scale_2d } }",
  userid: null,
  line: null,
  isDown: null,
  arc: null,
  line1: null,
  line2: null,
  line3: null,
  door_group: null,
  window_group: null,
  stair_group: null,
  partition_group: null,
  door_group1: null,
  window_group1: null,
  pointer2: null,
  pointer1: null,
  points: null,
  m: null,
  line_flag: 0,
  c: null,
  circle: null,
  rect: null,
  arc1: null,
  oct: null,
  point1: null,
  tapeisDown: false,
  tape_line: null,
  tape_text_tool: null,
  tape_text_static_tool: null,
  tape_scale_factor: 1,
  room_name: null,
  state: [],
  RedoState: [],
  mods: 0,
  undoStack: [],
  redoStack: [],
  objSelected: null,
  objSelectedFlag: false,
  edgedetection: 10,
  scaledetection: 15,
  top_view_image: "new Image()",
  angle: null,
  top_view_image: {
    src: `"/media/" + userid + "/" + floorkey + "/temp.png"`,
    crossOrigin: "anonymous",
  },
  panning: false,
  img2: `new fabric.Image(top_view_image, {
    height: h,
    width: w,
    selection: false,
    evented: false,
  })`,
  mouse_down: false,
  freeDrawArray: [],
  child_number: null,
  child_orientation: null,
  mouse_mode: null,
  drag_points: null,
  snap_thresh: 30,
  tape_flag: 0,
  tape: null,
  tape_text: null,
  tape_measurement: 10,
  mode: "normalView",
  evt_mode: "null",
  mod_mode: "null",
  m: 0,
  dl: 50,
  a: `dl / (2 * Math.sqrt(1 + m * m))`,
  b: `a * m`,
  ang_shift: `Math.atan(m)`,
  isUp: true,
  door_group: null,
  shiftKeyPress: false,

  version: "{{ version | safe }}",
  floorkey: "{{ floorkey | safe }}",
  infoUser: null,
  csrf_token: "{{ csrf_token }}",
  domain: "window.location.origin",
  isProduction:
    "window.location.hostname.toLowerCase().includes('app.snaptrude.com')",
  publicProject: "{{ public }}",
  project_type: "{{project_type}}",
  project_name: "{{ project_name | safe}}",
  // project_name.replace("&#39,", "'"),
  floorkey_pk: null, //WILL BE DEPRECATED
  project_type: "{{ project_type }}",
  BIMProject: true,
  features: "{{ features}}",
  view_only: "{{ view_only }}",
  directWall: false,
  appElement: "document.querySelector('[ng-app=Webtrude]')",
  idb: "idb",
  storeysDS: [],
  room_pols: [], //WILL BE DEPRECATED
  room_ids: [], //WILL BE DEPRECATED
  // room_types: [], //WILL BE DEPRECATED
  room_pos: [], //WILL BE DEPRECATED
  room_rot: [], //WILL BE DEPRECATED
  room_scaling: [], //WILL BE DEPRECATED
  room_names: [], //WILL BE DEPRECATED
  wall_pols: [[], []], //WILL BE DEPRECATED
  room_levels: [], //WILL BE DEPRECATED
  room_curves: [], //WILL BE DEPRECATED
  room_paths: [], //WILL BE DEPRECATED
  room_structures: [], //WILL BE DEPRECATED
  fplan_mode: "False",
  wall_block_data: [[], [], [], [], [], [], [], [], [], [], []], //WILL BE DEPRECATED
  tape_scale_factor: 1, //WILL BE DEPRECATED
  door_data: [[], [], [], []], //WILL BE DEPRECATED
  window_data: [[], [], [], []], //WILL BE DEPRECATED
  mat_paths_common: { walls: [] },
  gen_mats: "{{gen_mats|safe }}",
  gen_objs: "{{gen_objs|safe }}",
  view_data: "{{view_data|safe}}",
  ajax_url: "'{% url 'model_link' 0 %}'.replace('0', '{{floorkey}}'')",
  ajax_csrf: "{{ csrf_token }}",
  unit_absolute_scale: 0.0254,
  cameraScrollScale: 20,
  // editor_scale: "{{unit_scale }}",
  room_scale_2d: 1, //WILL BE DEPRECATED
  room_labels: "{{room_labels|safe}}",
  startingPoint: null,
  face_ribbon: null,
  state: [],
  redoState: [],
  selectionStack: [],
  intersectingRoofs: [],
  sketchMode: "solid",
  currentMesh: null,
  overlap_flag: false,
  diffTotal: "new BABYLON.Vector3(0, 0, 0)",
  tempflag: 0,
  nonOverlapDiff: [],
  nonOverlapMeshPos: "new BABYLON.Vector3(0, 0, 0)",
  diffCounter: 0,
  far: null,
  sar: null,
  max_height: null,
  allowed_built_area: -1,
  global_scale_factor: 5,
  pushPullScale: 1,
  distanceLine: null,
  face_coords: null,
  face_coords_index: null,
  drawingPointStack: [],
  normalVector: null,
  first_center_coord: null,
  end_center_coord: null,
  distanceLineOffset: 10,
  floor_index: 1,
  copy_index: 0,
  wall_partition: [],
  copy_mesh: null,
  copy_stack: null,
  types_of_rooms: [
    "Bedroom",
    "Living",
    "Kitchen",
    "Dining",
    "Bathroom",
    "Balcony",
    "Workspace",
    "Cabin",
    "Conference",
    "Foyer",
    "Default",
    "default",
  ],
  wall_thickness: "4.5 * unit_absolute_scale",
  objPropDialog:
    "window.location.origin + '/static/js/libs/objPropDialog.tmpl.html'",
  objStructDialog:
    "window.location.origin + '/static/js/libs/objStructDialog.tmpl.html'",
  importObjectsDialog:
    "window.location.origin + '/static/js/libs/importObjects.tmpl.html'",
  importFenestrationDialog:
    "window.location.origin + '/static/js/libs/importFenestration.tmpl.html'",
  exportViewsDialog:
    "window.location.origin + '/static/js/libs/exportView.tmpl.html'",
  renderViewsDialog:
    "window.location.origin + '/static/js/libs/renderDialog.tmpl.html'",
  importObjectsScaleDialog:
    "window.location.origin + '/static/js/libs/importObjectsScale.tmpl.html'",
  importMaterialDialog:
    "window.location.origin + '/static/js/libs/importMaterial.tmpl.html'",
  addAnotherFloorPlan:
    "window.location.origin + '/static/js/libs/comp_templates/addAnotherFloorPlan.htm'",
  bottomToolbarTemplate:
    "window.location.origin + '/static/js/libs/bottomToolbar.tmpl.html'",
  serverURL: "'{% url 'model_link' 0 %}'.replace('0', '{{floorkey}}')",
  uploadCompURL: '{% url "importComps" %}',
  getInteriorsURL: '{% url "getInteriors" %}',
  addCustomFurnitureTypeURL: '{% url "addCustomFurnitureType" %}',
  uploadMaterialURL: '{%  url "uploadMaterialComps" %}',
  canvasState: "{{ canvasInstance | safe }}",
  mongoStructure: "{{ mongo_structure | safe }}",
  floorkey_details: {}, //WILL BE DEPRECATED
  doublyLinkedList: "new DLL.DoublyLinkedList()",
  wallLevelArray: [],
  advancedTexture: null,
  matEditMenu: null,
  matEventsActivationFlag: false,
  shadowGenerator: null,
  editor_scale: 0.254,
  unit_scale: 0.1,
  floor_height: 11.811023622047244,
  inch_to_mtr: 0.254,
  num_of_projects: "{{num_of_projects}}",
  userInfo: "{{ userInfo }}",
  username: "{{ username }}",
  created_at: "{{ created_at }}",
  isCADFile: false,
  holesFlag: false,
  h_internal:
    "(-15.500030563610416575449066827356 * unit_absolute_scale) / unit_scale",
  h_external:
    "(15.500030563610416575449066827356 * unit_absolute_scale) / unit_scale",
  //console.log(canvasState),
  validNavigation: false,
  activeLayer: ACTIVE_LAYER,
  roofsInScene: [],
  ua: "navigator.userAgent",
  isiPad: false,
  // resurrect: new Resurrect({resolver: new Resurrect.NamespaceResolver(snapmda), cleanup: true, }),
  resurrect: null,
  tools: "{{ tools | safe}}",
  subscription: "{{subscription}}",
  duration: "{{duration}}",
  panMode: false,
  // isMobile: "window.mobileAndTabletcheck()",
  isMobile: false,
  // editor_scale: "editor_scale * 0.254",
  chat: null,
  sceneLoadInProgress: true,
  projectProperties: PROJECT_PROPERTIES,
  storeyClickTimeout: null,
  storeyClickTime: 200,

  userSettings: { freeEdgeEdit: false },
  Dropzone: {
    autoDiscover: false,
  },

  userSettingsInStructure: {
    autoInteriorState: false,
    roofOverhang: null,
    wallThickness: null,
    tolerance: 2,
    siteArea: null,
    unitsType: 1,
    gridSnapEnabled: true,
    gridSnapThreshold: 0.3937007874015748,
    gridUnitVal: 0.3937007874015748,
    gridEnabled: false,
    storeyUIToggle: {},
    layerUIToggle: {},
    textureDetail: "texture",
  },

  arrayFunctionGlobalVariables: {
    overrideCustomKeyboardInput: null,
    uniqueObjects: false,
  },

  createBuildingComponents: [],
  createBuildingMassesToDispose: [],

  createBuildingGlobalVariables: {
    changeParentChildRelationships: true,
  },

  snappingGlobalVariables: {
    lastSnappedEdgeArray: [],
    edgeMemorySize: 2,
    lastSnappedVertexArray: [],
    vertexMemorySize: 2,
    snappedToTwoAxes: false,
    sessionVariables: {
      inProgress: false,
      constrainedSnapAxis: null,
      activeTeleportVertex: null,
      excludedTeleportVertices: [],
      activeTeleportEdge: null,
      excludedTeleportEdges: [],
      previousSecondarySnappedPoint: null,
    },
  },

  uiCoordinationVariables: {
    clickedOnInputBox: false,
  },

  resolveEngineUtils: null,
  sessionId: null,
  exposed: {},
  roofsVisibleIn3D: [],
  hiddenMeshes: [],
  viewSettingsUIToggles: {
    textured: true,
    monochrome: false,
    hiddenLine: false,
    orthographic: false,
  },
  areShadowsEnabled: false,
  makeFloorPlanOrCADInvisible: false,
  // SendItToTeam,
  neighborhoodBuildings: {},
  terrainMapData: {},
  undoSketchUploadBeforeScale: false,
  currentCadRequestId: null,
  residualTerrainLayers: [],
  isCoveToolProject: false,
  daylightAnalysis: {
    data: null,
    meshes: null,
    projectSettings: {
      calculationOn: true,
      energyCode: null,
      energyCodeName: null,
      buildingTypes: [],
      location: {
        lat: null,
        lng: null
      }
    },
    allEnergyCodes: [],
    polls: [],
    pollsFloorKey: {},
    euiPoll: {},
    hiddenMeshes: null,
    trigger: false,
    blink: false,
  }
};

export var store = _store;

export const resetStore = () => {
  if (store) {
    if (store.roofsInScene) {
      store.roofsInScene = [];
    }
  }

  store = {
    canvas: null,
    engine: null,
    scene: null,
    newScene: null,
    renderer: null,
    depthMap: null,
    buffer: null,
    map: null,
    terrainMap: null,
    lastImportedTerrainMap: null,
    lastSearchedLocationData:  {
      lastKnownCoordinates: { lat: 38.907, lng: -77.04 },
      lastKnownZoom: 15,
      bounds: null
    },
    renderer: null,
    depthMap: null,
    buffer: null,
    // UNKNOWN VARIABLES
    room_width_line: null,
    room_height_line: null,
    width_text: null,
    width_text_static: null,
    height_text: null,
    height_text_static: null,
    object_name: null,
    angular: null,
    edgeDimensionLine: null,
    edgeMeasurementText: null,
    objectPropertiesView: null,
    backImg: "",
    bool: null,
    componentHandler: null,
    combineRoofs: null,
    pointerDown: null,
    controlerInput: null,
    findNearestSnapPointOnSameDrawingPath: null,
    drawVertexForSnapping: null,
    checkIfOnEdge: null,
    saveAs: null,
    mixpanel: null,
    reportError: null,
    createBuilding: null,
    posDiffThresh: 1,
    click: null,
    canvasResize: null,
    computeAreaFace: null,
    assignWallThicknessPropertiesToMesh: null,
    getWallOrientation: null,
    url_fplan: null,
    stateStack: [],
    WebsocketFunctions: null,
    gizmoManager: null,
    roomTypeProperties: ROOM_TYPE_PROPERTIES,
    meshData: null,
    mat: null,
    floorplanmode: null,
    numberFlag: 0,
    room_types: ROOM_TYPES,
    activeStoreyHeight: 11.811023622047244,
    units_type: UNITS_TYPE,
    units_types: UNITS_TYPES,
    unit_tolerance: UNIT_TOLERANCE,
    tolerance_levels: TOLERANCE_LEVELS,
    $scope: $SCOPE,
    ACTIVE_EVENT: {
      event: null,
    },
    isOrbitModeActive: false,
    isEyeDropperSelection: false,
    sceneScreenshot: [],
    newSceneViewData: [],
    newComment: {
      position: null,
    },
    newProject: true,
    newProjectUnit: 1,
    curtains: null,
    storeyNumForImport: 1,
    // VARIABLES FROM sketch.js
    thresh_slider: 0,
    img: `new Image()`,
    img: {
      src: "{{ documents.url }}",
    },
    w_w: "getWidth()",
    w_h: "getHeight()",
    w_w: `w_w - 150`, //Adjusting the Offset
    w_h: `w_h - 40`,
    w: null,
    h: null,
    // Computing the scale factor w.r.t the opencv module to
    // align coordinated systems
    scale_factor: null,
    imgWidth: null,
    imgHeight: null,
    data: null,
    ctx: null,
    imageData: null,
    canvas_img: null,
    ctx_img: null,
    prev_thresh_value: null,
    prev_evt_mode: null,
    url_send: `'{% url "sketch" 0 %}'.replace("0", "{{floorkey}}")`,
    url: `'{% url "sketch" 0 %}'.replace("0", "{{floorkey}}")`,
    prev_scale_factor: "{ { room_scale_2d } }",
    userid: null,
    line: null,
    isDown: null,
    arc: null,
    line1: null,
    line2: null,
    line3: null,
    door_group: null,
    window_group: null,
    stair_group: null,
    partition_group: null,
    door_group1: null,
    window_group1: null,
    pointer2: null,
    pointer1: null,
    points: null,
    m: null,
    line_flag: 0,
    c: null,
    circle: null,
    rect: null,
    arc1: null,
    oct: null,
    point1: null,
    tapeisDown: false,
    tape_line: null,
    tape_text_tool: null,
    tape_text_static_tool: null,
    tape_scale_factor: 1,
    room_name: null,
    state: [],
    RedoState: [],
    mods: 0,
    undoStack: [],
    redoStack: [],
    objSelected: null,
    objSelectedFlag: false,
    edgedetection: 10,
    scaledetection: 15,
    top_view_image: "new Image()",
    angle: null,
    top_view_image: {
      src: `"/media/" + userid + "/" + floorkey + "/temp.png"`,
      crossOrigin: "anonymous",
    },
    panning: false,
    img2: `new fabric.Image(top_view_image, {
      height: h,
      width: w,
      selection: false,
      evented: false,
    })`,
    mouse_down: false,
    freeDrawArray: [],
    child_number: null,
    child_orientation: null,
    mouse_mode: null,
    drag_points: null,
    snap_thresh: 30,
    tape_flag: 0,
    tape: null,
    tape_text: null,
    tape_measurement: 10,
    mode: "normalView",
    evt_mode: "null",
    mod_mode: "null",
    m: 0,
    dl: 50,
    a: `dl / (2 * Math.sqrt(1 + m * m))`,
    b: `a * m`,
    ang_shift: `Math.atan(m)`,
    isUp: true,
    door_group: null,
    shiftKeyPress: false,

    version: "{{ version | safe }}",
    floorkey: "{{ floorkey | safe }}",
    infoUser: null,
    csrf_token: "{{ csrf_token }}",
    domain: "window.location.origin",
    isProduction:
      "window.location.hostname.toLowerCase().includes('app.snaptrude.com')",
    publicProject: "{{ public }}",
    project_type: "{{project_type}}",
    project_name: "{{ project_name | safe}}",
    // project_name.replace("&#39,", "'"),
    floorkey_pk: null, //WILL BE DEPRECATED
    project_type: "{{ project_type }}",
    BIMProject: true,
    features: "{{ features}}",
    view_only: "{{ view_only }}",
    directWall: false,
    appElement: "document.querySelector('[ng-app=Webtrude]')",
    idb: "idb",
    storeysDS: [],
    room_pols: [], //WILL BE DEPRECATED
    room_ids: [], //WILL BE DEPRECATED
    // room_types: [], //WILL BE DEPRECATED
    room_pos: [], //WILL BE DEPRECATED
    room_rot: [], //WILL BE DEPRECATED
    room_scaling: [], //WILL BE DEPRECATED
    room_names: [], //WILL BE DEPRECATED
    wall_pols: [[], []], //WILL BE DEPRECATED
    room_levels: [], //WILL BE DEPRECATED
    room_curves: [], //WILL BE DEPRECATED
    room_paths: [], //WILL BE DEPRECATED
    room_structures: [], //WILL BE DEPRECATED
    fplan_mode: "False",
    wall_block_data: [[], [], [], [], [], [], [], [], [], [], []], //WILL BE DEPRECATED
    tape_scale_factor: 1, //WILL BE DEPRECATED
    door_data: [[], [], [], []], //WILL BE DEPRECATED
    window_data: [[], [], [], []], //WILL BE DEPRECATED
    mat_paths_common: { walls: [] },
    gen_mats: "{{gen_mats|safe }}",
    gen_objs: "{{gen_objs|safe }}",
    view_data: "{{view_data|safe}}",
    ajax_url: "'{% url 'model_link' 0 %}'.replace('0', '{{floorkey}}'')",
    ajax_csrf: "{{ csrf_token }}",
    unit_absolute_scale: 0.0254,
    cameraScrollScale: 20,
    // editor_scale: "{{unit_scale }}",
    room_scale_2d: 1, //WILL BE DEPRECATED
    room_labels: "{{room_labels|safe}}",
    startingPoint: null,
    face_ribbon: null,
    state: [],
    redoState: [],
    selectionStack: [],
    intersectingRoofs: [],
    sketchMode: "solid",
    currentMesh: null,
    overlap_flag: false,
    diffTotal: "new BABYLON.Vector3(0, 0, 0)",
    tempflag: 0,
    nonOverlapDiff: [],
    nonOverlapMeshPos: "new BABYLON.Vector3(0, 0, 0)",
    diffCounter: 0,
    far: null,
    sar: null,
    max_height: null,
    allowed_built_area: -1,
    global_scale_factor: 5,
    pushPullScale: 1,
    distanceLine: null,
    face_coords: null,
    face_coords_index: null,
    drawingPointStack: [],
    normalVector: null,
    first_center_coord: null,
    end_center_coord: null,
    distanceLineOffset: 10,
    floor_index: 1,
    copy_index: 0,
    wall_partition: [],
    copy_mesh: null,
    copy_stack: null,
    types_of_rooms: [
      "Bedroom",
      "Living",
      "Kitchen",
      "Dining",
      "Bathroom",
      "Balcony",
      "Workspace",
      "Cabin",
      "Conference",
      "Foyer",
      "Default",
      "default",
    ],
    wall_thickness: "4.5 * unit_absolute_scale",
    objPropDialog:
      "window.location.origin + '/static/js/libs/objPropDialog.tmpl.html'",
    objStructDialog:
      "window.location.origin + '/static/js/libs/objStructDialog.tmpl.html'",
    importObjectsDialog:
      "window.location.origin + '/static/js/libs/importObjects.tmpl.html'",
    importFenestrationDialog:
      "window.location.origin + '/static/js/libs/importFenestration.tmpl.html'",
    exportViewsDialog:
      "window.location.origin + '/static/js/libs/exportView.tmpl.html'",
    renderViewsDialog:
      "window.location.origin + '/static/js/libs/renderDialog.tmpl.html'",
    importObjectsScaleDialog:
      "window.location.origin + '/static/js/libs/importObjectsScale.tmpl.html'",
    importMaterialDialog:
      "window.location.origin + '/static/js/libs/importMaterial.tmpl.html'",
    addAnotherFloorPlan:
      "window.location.origin + '/static/js/libs/comp_templates/addAnotherFloorPlan.htm'",
    bottomToolbarTemplate:
      "window.location.origin + '/static/js/libs/bottomToolbar.tmpl.html'",
    serverURL: "'{% url 'model_link' 0 %}'.replace('0', '{{floorkey}}')",
    uploadCompURL: '{% url "importComps" %}',
    getInteriorsURL: '{% url "getInteriors" %}',
    addCustomFurnitureTypeURL: '{% url "addCustomFurnitureType" %}',
    uploadMaterialURL: '{%  url "uploadMaterialComps" %}',
    canvasState: "{{ canvasInstance | safe }}",
    mongoStructure: "{{ mongo_structure | safe }}",
    floorkey_details: {}, //WILL BE DEPRECATED
    doublyLinkedList: "new DLL.DoublyLinkedList()",
    wallLevelArray: [],
    advancedTexture: null,
    matEditMenu: null,
    matEventsActivationFlag: false,
    shadowGenerator: null,
    editor_scale: 0.254,
    unit_scale: 0.1,
    floor_height: 11.811023622047244,
    inch_to_mtr: 0.254,
    num_of_projects: "{{num_of_projects}}",
    userInfo: "{{ userInfo }}",
    username: "{{ username }}",
    created_at: "{{ created_at }}",
    isCADFile: false,
    holesFlag: false,
    h_internal:
      "(-15.500030563610416575449066827356 * unit_absolute_scale) / unit_scale",
    h_external:
      "(15.500030563610416575449066827356 * unit_absolute_scale) / unit_scale",
    //console.log(canvasState),
    validNavigation: false,
    activeLayer: ACTIVE_LAYER,
    roofsInScene: [],
    ua: "navigator.userAgent",
    isiPad: false,
    // resurrect: new Resurrect({resolver: new Resurrect.NamespaceResolver(snapmda), cleanup: true, }),
    resurrect: null,
    tools: "{{ tools | safe}}",
    subscription: "{{subscription}}",
    duration: "{{duration}}",
    panMode: false,
    // isMobile: "window.mobileAndTabletcheck()",
    isMobile: false,
    // editor_scale: "editor_scale * 0.254",
    chat: null,
    sceneLoadInProgress: true,
    projectProperties: PROJECT_PROPERTIES,
    storeyClickTimeout: null,
    storeyClickTime: 200,

    userSettings: { freeEdgeEdit: false },
    Dropzone: {
      autoDiscover: false,
    },

    userSettingsInStructure: {
      autoInteriorState: false,
      roofOverhang: null,
      wallThickness: null,
      tolerance: 2,
      siteArea: null,
      unitsType: 1,
      gridSnapEnabled: true,
      gridSnapThreshold: 0.3937007874015748,
      gridUnitVal: 0.3937007874015748,
      gridEnabled: false,
      storeyUIToggle: {},
      layerUIToggle: {},
      textureDetail: "texture",
    },

    arrayFunctionGlobalVariables: {
      overrideCustomKeyboardInput: null,
      uniqueObjects: false,
    },

    createBuildingComponents: [],
    createBuildingMassesToDispose: [],

    createBuildingGlobalVariables: {
      changeParentChildRelationships: true,
    },

    snappingGlobalVariables: {
      lastSnappedEdgeArray: [],
      edgeMemorySize: 2,
      lastSnappedVertexArray: [],
      vertexMemorySize: 2,
      snappedToTwoAxes: false,
      sessionVariables: {
        inProgress: false,
        constrainedSnapAxis: null,
        activeTeleportVertex: null,
        excludedTeleportVertices: [],
        activeTeleportEdge: null,
        excludedTeleportEdges: [],
      },
    },

    uiCoordinationVariables: {
      clickedOnInputBox: false,
    },

    resolveEngineUtils: null,
    sessionId: null,
    exposed: store.exposed,
    roofsVisibleIn3D: [],
    hiddenMeshes: [],
    viewSettingsUIToggles: {
      textured: true,
      monochrome: false,
      hiddenLine: false,
      orthographic: false,
    },
    areShadowsEnabled: false,
    makeFloorPlanOrCADInvisible: false,
    // SendItToTeam,
    neighborhoodBuildings: {},
    terrainMapData: {},
    undoSketchUploadBeforeScale: false,
    currentCadRequestId: null,
    residualTerrainLayers: [],
    isCoveToolProject: false,
    daylightAnalysis: {
      data: null,
      meshes: null,
      projectSettings: {
        calculationOn: true,
        energyCode: null,
        energyCodeName: null,
        buildingTypes: [],
        location: {
          lat: null,
          lng: null
        }
      },
      allEnergyCodes: [],
      polls: [],
      pollsFloorKey: {},
      euiPoll: {},
      hiddenMeshes: null,
      trigger: false,
      blink: false,
    }
  };
};
