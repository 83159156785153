import { colors } from "../../../themes/constant";

export const ButtonStyles = {
  background: colors.transparent,
  width: "auto",
};

export const ButtonTextStyles = {
  color: colors.brightRed,
};

export const DividerStyles = {
  marginLeft: 0,
};
