import { store } from "../modules/utilityFunctions/Store";
import BABYLON from "../modules/babylonDS.module";
import { djangoUrl, ORIGIN } from "../../services/url.constants";
import {
  saveHighResImage,
  saveLowResImage,
  saveViewMetaData,
} from "../../services/views.service";
import reduxStore from "../stateManagers/store/reduxStore";
import { updateScreenshotUiData } from "../stateManagers/reducers/objectProperties/screenshotUiDataSlice";
import { updateViewOperation } from "./updateViewOperation";
import JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import _ from "lodash";
import { saveAs } from "file-saver";
import {goOutOfTwoD} from "./twoDimension";
import {toggleOrthoView} from "./sceneFuncs";
import sunpath from "../modules/sunpath/sunpathOperations";

const viewOperation = (function () {
  async function saveSceneScreenShot(floorKey, options = {}) {
    const img = document.createElement("img");
    let key = Math.floor(Math.random() * 90000) + 10000;
    let orig = ORIGIN + "/media/";
    let path =
      store.userid + "/" + floorKey + "/screenshot/" + "view_" + key + ".png";
    let hemiLight = store.scene.getLightByName("light1");
    let shadowToggle = hemiLight.intensity === 0;
    let sunPath = !!store.scene.getMeshByName("sunpathLines");
    let currentCameraData;
    if (options.updateView) {
      currentCameraData = options.camData;
    } else {
      currentCameraData = {
        alpha: store.scene.activeCamera.alpha,
        beta: store.scene.activeCamera.beta,
        radius: store.scene.activeCamera.radius,
        position: store.scene.activeCamera.position.clone(),
        target: store.scene.activeCamera.target.clone(),
        shadowData: {
          shadowToggle: shadowToggle,
          mapcoord: store.terrainMapData?.center,
          date: {
            month: store.$scope.month,
            date: store.$scope.date,
            time: store.$scope.time,
          },
        },
        sunPath: sunPath,
        ortho: store.scene.activeCamera.mode,
        isTwoDimension: store.$scope.isTwoDimension,
        hiddenMeshesScene: JSON.parse(JSON.stringify(store.hiddenMeshes)),
      };
    }
    const camDataJson = JSON.stringify(currentCameraData);
    await saveViewMetaData(
      camDataJson,
      floorKey,
      key,
      store.userSettingsInStructure.textureDetail
    );

    // let highResImage;
    // const highResPromise = new Promise((resolve, reject) => {
    //   BABYLON.Tools.CreateScreenshot(
    //     store.engine,
    //     store.scene.activeCamera,
    //     { width: 3840, height: 2160 },
    //     function (image) {
    //       resolve(image);
    //     }
    //   );
    // });
    // const highRes = await highResPromise;
    // await saveHighResImage(highRes, floorKey, key);

    const lowResPromise = new Promise((resolve, reject) => {
      BABYLON.Tools.CreateScreenshot(
        store.engine,
        store.scene.activeCamera,
        { width: 256, height: 144 },
        (data) => {
          resolve(data);
        }
      );
    });

    const lowRes = await lowResPromise;
    await saveLowResImage(lowRes, floorKey, key);

    if (options.position === undefined) {
      store.$scope.sceneViewData.push({
        path: orig + path,
        imgKey: key,
        camData: currentCameraData,
        textureDetail: store.userSettingsInStructure.textureDetail,
        screenshotTitle: "ScreenShot Title " + key,
        highlighted: false,
      });
    } else {
      store.$scope.sceneViewData.splice(options.position, 1, {
        path: orig + path,
        imgKey: key,
        camData: currentCameraData,
        textureDetail: store.userSettingsInStructure.textureDetail,
        screenshotTitle: "ScreenShot Title " + key,
        highlighted: false,
      });
    }
    reduxStore.dispatch(
      updateScreenshotUiData({ sceneViewData: store.$scope.sceneViewData })
    );
    img.src = lowRes;
    img.key = key;

    return img;
  }

  async function exportAllSelectedViews(resolution, format) {
    // store.$scope.progressVisibilityForUpdate = true;
    let imageArr = [];
    let highLightedImageObjects = [];
    // let key = Math.floor(Math.random() * 90000) + 10000;

    for (let i = 0; i < store.$scope.images.length; i++) {
      if (store.$scope.images[i].highlighted === true) {
        imageArr.push(store.$scope.images[i].imgKey);
        highLightedImageObjects.push(store.$scope.images[i]);
      }
    }
    let highResPromises = [];
    // let highlightedPositions = [];
    for (let i = 0; i < highLightedImageObjects.length; i++) {
      let imageObject = highLightedImageObjects[i];
      store.scene.activeCamera.position = imageObject.camData.position;
      store.scene.activeCamera.target = new BABYLON.Vector3(
        imageObject.camData.target._x,
        imageObject.camData.target._y,
        imageObject.camData.target._z
      );
      store.scene.render();

      let options = {};
      options.updateView = true;
      options.camData = imageObject.camData;

      // highlightedPositions.push(options.position);
    }

    await updateViewOperation.refreshSelectedViews(imageArr);

    store.$scope.progressVisibilityForExport = true;
    let newImageArr = [];
    for (let i = 0; i < store.$scope.sceneViewData.length; i++) {
      if (store.$scope.sceneViewData[i].highlighted) {
        newImageArr.push(store.$scope.sceneViewData[i].imgKey);

        let imageObject = store.$scope.sceneViewData[i];
        store.scene.activeCamera.position = imageObject.camData.position;
        store.scene.activeCamera.target = new BABYLON.Vector3(
          imageObject.camData.target._x,
          imageObject.camData.target._y,
          imageObject.camData.target._z
        );
        store.scene.render();

        if( !imageObject.camData.isTwoDimension ){
          if( store.$scope.isTwoDimension ){
            goOutOfTwoD();
          }
          if( imageObject.camData.ortho === BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
            if( store.scene.activeCamera.mode !== BABYLON.Camera.ORTHOGRAPHIC_CAMERA ){
              toggleOrthoView();
            }
          }
          else {
            store.scene.activeCamera.mode = BABYLON.Camera.PERSPECTIVE_CAMERA;
          }
        }

        if (imageObject.camData.shadowData.shadowToggle) {
          let hemiLight = store.scene.getLightByName("light1");
          let shadowStatus = hemiLight.intensity === 0;
          if (!shadowStatus) sunpath.toggleShadows();
          sunpath.changeSunPos(
              imageObject.camData.shadowData.date.month,
              imageObject.camData.shadowData.date.date,
              imageObject.camData.shadowData.date.time
          );
          // toggleHiddenLine(true);
        }
        else {
          // let hemiLight = store.scene.getLightByName("light2");
          // let shadowStatus = hemiLight.intensity === 0;
          // if (shadowStatus) sunpath.toggleShadows();
          // sunpath.toggleSunpath();
          sunpath.clearShadows();
          store.scene.render();
        }

        if(imageObject.camData.sunPath){
          let sunPathLines = store.scene.getMeshByName("sunpathLines");
          if(!sunPathLines){
            sunpath.toggleSunpath();
            store.scene.render();
          }
        }
        else{
          sunpath.clearSunpath();
        }

        store.scene.render();

        highResPromises.push(
          updateViewOperation.generateHighResImages(
            resolution,
            format,
            store.$scope.sceneViewData[i].imgKey
          )
        );
      }
    }
    await Promise.all(highResPromises);

    await exportView(newImageArr, resolution, format);
    // await zipAndDownload();
  }

  async function exportView(imgKey, option, ext) {
    if (!ext) ext = "png";
    let pathsArr = [];
    let curr_path = store.$scope.sceneViewData;
    for (let i = 0; i < imgKey.length; i++) {
      for (let j = 0; j < curr_path.length; j++) {
        if (curr_path[j].imgKey === imgKey[i]) {
          let path = curr_path[j].path.replace(
            "screenshot/view_" + imgKey[i],
            "exportviews/exp_" + imgKey[i]
          );
          pathsArr.push(path);
          break;
        }
      }
    }
    let output = ext === "jpeg" ? "image/jpeg" : "image/png";
    let imgHeight, imgWidth;
    if (!option) option = "1280 x 720";
    switch (option) {
      // case "3840 x 2160":
      //   imgHeight = 2160;
      //   imgWidth = 3840;
      //   break;
      case "1920 x 1080":
        imgHeight = 1080;
        imgWidth = 1920;
        break;
      case "1280 x 720":
        imgHeight = 720;
        imgWidth = 1280;
        break;
      case "960 x 540":
        imgHeight = 540;
        imgWidth = 960;
        break;
      case "800 x 240":
        imgHeight = 240;
        imgWidth = 800;
        break;
      case "560 x 240":
        imgHeight = 240;
        imgWidth = 560;
        break;
      default:
        console.log("no resolution selected");
        break;
    }
    // for (let i = 0; i < pathsArr.length; i++) {
    //     var filename = pathsArr[i].split('/').pop();
    //     toDataURL(pathsArr[i], zipFiles, output, imgWidth, imgHeight);
    // }
    let finalArray = await getDataURLArray(
      pathsArr,
      imgWidth,
      imgHeight,
      output
    );
    resetExportViewsData();
    // setTimeout(createZip, 100, [finalArray, ext]);
    createZip(finalArray, ext);
  }

  async function getDataURLArray(pathArray, width, height, outputFormat) {
    let base64Array = [];
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width;

    let loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`load ${url} fail`));
        img.src = url;
      });
    };

    let draw = (imageSource) => {
      return loadImage(imageSource).then((img) => {
        ctx.drawImage(img, 0, 0, width, height);
      });
    };

    for (const p of pathArray) {
      await draw(p);
      let dataURL = canvas.toDataURL(outputFormat);
      base64Array.push(dataURL);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    return base64Array;
  }

  function createZip(base64Arr, ext) {
    let zip = new JSZip();
    let count = 0;
    let zipFilename = "Views.zip";
    base64Arr.forEach(function (elem) {
      JSZipUtils.getBinaryContent(elem, function (err, data) {
        if (err) {
          // resetExportViewsData();
          throw err; // or handle the error
        }
        zip.file("view_" + count + "." + ext, elem.split("base64,")[1], {
          base64: true,
        });
        count++;
        if (count === base64Arr.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
            // $scope.progressVisibilityForExport = false;
            // $scope.cancel();
          });
        }
      });
    });
  }

  function resetExportViewsData() {
    if (!_.isEmpty(store.$scope.sceneViewData)) {
      store.$scope.sceneViewData = store.$scope.sceneViewData.map(
        (screenshot) => {
          return {
            ...screenshot,
            highlighted: false,
          };
        }
      );
      // for (let i = 0; i < store.$scope.sceneViewData.length; i++) {
      //   if (store.$scope.sceneViewData[i].highlighted) {
      //     store.$scope.sceneViewData[i].highlighted = false;
      //   }
      // }
    }
    if (!_.isEmpty(store.$scope.images)) {
      store.$scope.images = store.$scope.images.map((screenshot) => {
        return {
          highlighted: false,
          ...screenshot,
        };
      });
      // for (let i = 0; i < store.$scope.images.length; i++) {
      //   if (store.$scope.images[i].highlighted) {
      //     store.$scope.images[i].highlighted = false;
      //   }
      // }
    }
  }

  function toDataURL(src, callback, outputFormat, imgW, imgH) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    var dataURL;
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = store.canvas.getContext("2d");
      store.canvas.height = imgH;
      store.canvas.width = imgW;
      ctx.drawImage(this, 0, 0, store.canvas.width, store.canvas.height);
      dataURL = store.canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }

  return {
    saveSceneScreenShot,
    exportAllSelectedViews,
  };
})();

export { viewOperation };
