import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Card from "../Outer";
import Text from "../../Text";
import Button from "../../Button";

import Medal from "../../../assets/images/medal.svg";
import * as styles from "./styles";
import * as constants from "./constants";
import { routes } from "../../../routes/constants";
import AuthService from "../../../services/auth.service";

const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  max-width: 22em;
  .link {
    text-decoration: none;
    width: 100%;
  }
`;

const CustomText = styled(Text)`
  color: ${(props) => props.theme.colors[props.color]};
`;

const UpgradeText = ({upgradeText}) => {
  if(upgradeText) return upgradeText;
  const activePlan = AuthService.getActivePlan();
  if(activePlan && activePlan.name === "student"){
    return constants.UPGRADE_TEXT_STUDENT.map(t => <div>{t}</div>)
  }
  return constants.UPGRADE_TEXT;
}
const ProDeadline = ({projectsCount}) => {
  const activePlan = AuthService.getActivePlan();
  if(activePlan && activePlan.name === "student"){
    return <>{activePlan.remaining}</>
  }
  return <>{projectsCount >= 3 ? "0 " : 3-projectsCount + " "} Free projects remaining</>
}

const Action = ({handleInviteModalOpen}) => {
  const activePlan = AuthService.getActivePlan();
  if(activePlan && activePlan.name === "student"){
    return <Button
      type="button"
      customButtonStyle={styles.upgradeStyles.button}
      customButtonTextStyle={styles.upgradeStyles.buttonText}
      title={constants.BUTTON_TEXT_STUDENT}
      onPress={handleInviteModalOpen}
    />
  }
    return  <NavLink className="link" to={routes.plans}>
      <Button
      type="button"
      customButtonStyle={styles.upgradeStyles.button}
      customButtonTextStyle={styles.upgradeStyles.buttonText}
      title={constants.BUTTON_TEXT}
    />
    </NavLink> 
}

function Upgrade({projectsCount, style, handleInviteModalOpen, upgradeText, proDeadlineHidden = false}) {
  return (
    <CustomCard style={style}>
      <img src={Medal} alt="medal" />
      <CustomText size="0.8125rem" color="primeBlack">
        <UpgradeText upgradeText={upgradeText} />
      </CustomText>
      {
        !proDeadlineHidden &&
        <CustomText size="0.8125rem" color="brightRed">
          <ProDeadline projectsCount={projectsCount} />
        </CustomText>
      }
      <Action handleInviteModalOpen={handleInviteModalOpen} />
    </CustomCard>
  );
}

export default Upgrade;
