import * as log from "loglevel";
import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { reloadScene } from "./sceneFuncs.js";
import { updateRoofAccordion } from "./roofVisibilityFuncs.js";
import { isTwoDimension } from "./twoDimension.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { handleEscapeKeyEvent } from "./keyEvents.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { onSolid } from "../modules/extrafunc.js";
import { textureOnLoadCallback } from "./mats.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { click } from "./meshEvents.js";
import { removeRooms, removeObjects } from "./sceneEvents.js";
import { restoreMeshes, getMeshMat, getMeshSubMesh } from "./meshData.js";
import { createMassBlocksFromData } from "./massModeling.js";
import {
  floorProperties,
  massProperties,
  roofProperties,
  wallProperties,
} from "./objectProperties.js";
import {
  BasicFloorComp,
  BasicMassComp,
  BasicRoofComp,
  BasicWallComp,
} from "./basicCompProperties.js";
import { djangoUrl } from "../../services/url.constants.js";
import reduxStore from "../stateManagers/store/reduxStore.js";
import { updateProjectProperties } from "../stateManagers/reducers/objectProperties/projectPropertiesSlice.js";
import { saveMaterialInBackend } from "./applyMaterialFuncs.js";

function getMeshData() {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  var serializedScene = BABYLON.SceneSerializer.Serialize(store.scene);
  serializedScene.unit_scale = store.unit_scale;
  serializedScene.unit_absolute_scale = store.unit_absolute_scale;
  serializedScene.levels = $scope.selectedItemLevelHeight;
  // serializedScene.floorkeys.push()
  for (var p = 0; p < serializedScene.meshes.length; p++) {
    var mesh = serializedScene.meshes[p];
    if (nonDefaultMesh(mesh)) {
      serializedScene.meshes[p]._properties = store.scene.meshes[p]._properties;
      if (store.scene.meshes[p].room_type)
        serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
      if (store.store.scene.meshes[p].room_name)
        serializedScene.meshes[p].room_name = store.scene.meshes[p].room_name;
      if (store.scene.meshes[p].room_id)
        serializedScene.meshes[p].room_id = store.scene.meshes[p].room_id;
      if (store.scene.meshes[p].level)
        serializedScene.meshes[p].level = store.scene.meshes[p].level;
      if (store.scene.meshes[p].level === 0)
        serializedScene.meshes[p].level = store.scene.meshes[p].level;
      if (store.scene.meshes[p].wall_type)
        serializedScene.meshes[p].wall_type = store.scene.meshes[p].wall_type;
      if (store.scene.meshes[p].wall_id)
        serializedScene.meshes[p].wall_id = store.scene.meshes[p].wall_id;
      if (store.scene.meshes[p].type)
        serializedScene.meshes[p].type = store.scene.meshes[p].type;
      if (store.scene.meshes[p].structure)
        serializedScene.meshes[p].structure = store.scene.meshes[p].structure;
      if (store.scene.meshes[p].wallJunctions)
        serializedScene.meshes[p].wallJunctions = JSON.stringify(
          store.scene.meshes[p].wallJunctions
        );
      if (store.scene.meshes[p].checkForExtention)
        serializedScene.meshes[p].checkForExtention =
          store.scene.meshes[p].checkForExtention;
      //if (scene.meshes[p].moveMeshObj) serializedScene.meshes[p].moveMeshObj = store.scene.meshes[p].moveMeshObj;
      if (store.scene.meshes[p].floor)
        serializedScene.meshes[p].floor = store.scene.meshes[p].floor;
      if (store.scene.meshes[p].wall_pol)
        serializedScene.meshes[p].wall_pol = store.scene.meshes[p].wall_pol;
      if (store.scene.meshes[p].uniqueId)
        serializedScene.meshes[p].uniqueId = store.scene.meshes[p].uniqueId;
      if (store.scene.meshes[p].neighborsWallMesh)
        serializedScene.meshes[p].neighborsWallMesh = JSON.stringify(
          store.scene.meshes[p].neighborsWallMesh
        );
      if (store.scene.meshes[p].parent) {
        // store.scene.meshes[p].parent.id = store.scene.meshes[p].parent.id + p;
        serializedScene.meshes[p].parent =
          store.scene.meshes[p].parent.uniqueId;
      }
      if (store.scene.meshes[p].moveMeshObj) {
        serializedScene.meshes[p].moveMeshObj = {};
        if (store.scene.meshes[p].moveMeshObj.clkWiseMesh) {
          serializedScene.meshes[p].moveMeshObj.clkWiseMesh = [];
          store.scene.meshes[p].moveMeshObj.clkWiseMesh.forEach(function (obj) {
            serializedScene.meshes[p].moveMeshObj.clkWiseMesh.push({
              mesh: obj.mesh.uniqueId,
              nearPointIndexes: obj.nearPointIndexes,
            });
          });
        }
        if (store.scene.meshes[p].moveMeshObj.counterClkWiseMesh) {
          serializedScene.meshes[p].moveMeshObj.counterClkWiseMesh = [];
          store.scene.meshes[p].moveMeshObj.counterClkWiseMesh.forEach(
            function (obj) {
              serializedScene.meshes[p].moveMeshObj.counterClkWiseMesh.push({
                mesh: obj.mesh.uniqueId,
                nearPointIndexes: obj.nearPointIndexes,
              });
            }
          );
        }
      }
    }
  }
  // for (var p = 0; p < serializedScene.materials.length; p++) {
  //     if (serializedScene.materials[p].diffuseTexture){
  //         // console.log(serializedScene.materials[p].diffuseTexture.url);
  //     }
  //     if (serializedScene.materials[p].diffuseTexture){
  //         // console.log(serializedScene.materials[p].diffuseTexture.url);
  //     }
  // }
  return serializedScene;
}

async function updateModifications() {
  return;
  /*
    // console.log("Updating");
    let structures = StructureCollection.getInstance();
    let $scope = store.angular.element(appElement).scope();
    $scope = $scope.$$childHead;
    // let  state = null;
    // await clientDb.getLocalState("state"+floorkey).then((stat) =>{
    //     store.state = stat;
    //     store.state.push(structures.getSnaptrudeJSON());
    // });
    store.state.push(structures.getSnaptrudeJSON());
    if (state.length > 15) {
        store.state.shift();
    }
    //clientDb.saveStateLocaly(structures.getSnaptrudeJSON(),"state"+floorkey);
    if($scope.areasUpdateNeeded){areaView.generateArea()}
    layerView.generateLayerData();

    //clientDb.saveCanvasLocally("canvasState"+floorkey);
   //auto_save();
   */
}

async function redo() {
  // let  redoState = null;
  // await clientDb.getLocalState("redoState"+floorkey).then((stat) =>{
  //     store.redoState = stat;
  // });
  console.log(store.redoState.length);
  store.selectionStack.length = 0;
  if (store.redoState.length > 0) {
    //var tempState = await clientDb.popState("redoState"+floorkey);
    var tempState = store.redoState.pop();
    //await clientDb.saveStateLocaly(tempState,"state"+floorkey);
    store.state.push(tempState);
    // console.log(tempState);
    // recreateScene(JSON.parse(tempState));
    removeAllMeshes();
    // let structures = StructureCollection.getInstance();
    // structures.recreateSceneStructure(JSON.parse(tempState));
    reloadScene(JSON.parse(tempState)).finally(() => {
      updateRoofAccordion(true);
      if (!store.$scope.isTwoDimension) {
        let structureCollection = StructureCollection.getInstance();
        structureCollection
          .getStructures()
        [store.activeLayer.structure_id].showNonActiveStoreyMeshes();
      }
    });
  }
}

async function undo() {
  // let  state = null;
  // let  redoState = null;
  // await clientDb.getLocalState("redoState"+floorkey).then((rstat) =>{
  //     store.redoState = rstat;
  // });
  //  await clientDb.getLocalState("state"+floorkey).then((stat) =>{
  //         store.state = stat;
  //     });
  store.selectionStack.length = 0;
  if (store.state.length > 1) {
    //var tempState = await clientDb.popState("state"+floorkey);
    var tempState = store.state.pop();
    //   await clientDb.getLocalState("state"+floorkey).then((stat) =>{
    //     store.state = stat;
    // });
    store.redoState.push(tempState);
    //await clientDb.saveStateLocaly(tempState,"redoState"+floorkey);
    // recreateScene(JSON.parse(state[state.length - 1]));
    removeAllMeshes();
    DisplayOperation.removeDimensions();
    // let structures = StructureCollection.getInstance();
    // structures.recreateSceneStructure(JSON.parse(state[state.length - 1]));

    reloadScene(JSON.parse(store.state[store.state.length - 1])).finally(() => {
      updateRoofAccordion(true);
      if (!store.$scope.isTwoDimension) {
        let structureCollection = StructureCollection.getInstance();
        structureCollection
          .getStructures()
        [store.activeLayer.structure_id].showNonActiveStoreyMeshes();
      }
    });
    // recreateScene(JSON.parse(tempState));
  }

  // handleEscapeKeyEvent();
}

// Command Pattern Undo/Redo
function CMUndo() {
  handleEscapeKeyEvent(true);
  CommandManager.undo();
}

function CMRedo() {
  handleEscapeKeyEvent(true);
  CommandManager.redo();
}

function removeAllMeshes() {
  var iter1 = 0;
  while (iter1 < store.scene.materials.length) {
    if (
      !["colorShader", "lineShader"].includes(store.scene.materials[iter1].id)
    ) {
      store.scene.materials[iter1].dispose();
      continue;
    }
    iter1++;
  }

  store.scene.multiMaterials.length = 0;
  var iter = 0;
  while (iter < store.scene.meshes.length) {
    if (
      nonDefaultMesh(store.scene.meshes[iter]) ||
      store.scene.meshes[iter].name.indexOf("boxScale") !== -1
    ) {
      store.scene.meshes[iter].dispose();
      continue;
    }
    iter++;
  }

  store.selectionStack = [];

  try {
    let guiRoot = store.advancedTexture.getChildren()[0];
    while (guiRoot.children[0]) guiRoot.children[0].dispose();
  } catch (e) {
    console.log(
      "Error in removing all meshes store.advancedTexture.getChildren",
      e
    );
  }
}

function getGeometryById(geometries, id) {
  for (var i = 0; i < geometries.length; i++) {
    if (geometries[i].id === id) return geometries[i];
  }
  return -1;
}

function getMaterialById(materials, id) {
  console.log(materials);
  for (var i = 0; i < materials.length; i++) {
    if (materials[i].id === id) return materials[i];
  }
  return -1;
}

function recreateScene(data) {
  removeAllMeshes();
  for (var i = 0; i < data.meshes.length; i++) {
    if (nonDefaultMesh(data.meshes[i])) {
      if (
        data.meshes[i].isVisible ||
        data.meshes[i].name.toLowerCase() === "roof"
      ) {
        var geom = getGeometryById(
          data.geometries.vertexData,
          data.meshes[i].geometryId
        );
        if (geom !== -1) var mesh = recreateMesh(data.meshes[i], geom, data);
      }
    }
  }

  onSolid();
}

function recreateMultiMaterials(data) {
  for (let i = 0; i < data.multiMaterials.length; i++) {
    let multiMat = new BABYLON.MultiMaterial(
      data.multiMaterials[i].name,
      store.scene
    );
    multiMat.id = data.multiMaterials[i].id;
    for (let j = 0; j < data.multiMaterials[i].materials.length; j++) {
      multiMat.subMaterials.push(
        store.scene.getMaterialByName(data.multiMaterials[i].materials[j])
      );
    }
  }
}

function recreateMaterials(data) {
  for (var i = 0; i < data.materials.length; i++) {
    // if (!scene.getMaterialByName(data.materials[i].name)){
    let mat = store.scene.getMaterialByName(data.materials[i].name);
    if (!mat) {
      if (data.materials[i].diffuse)
        recreateStandardMaterial(data.materials[i]);
      else if (data.materials[i].albedo) {
        // recreatePBRMaterial(data.materials[i]);
        recreatePBRMaterialToStandard(data.materials[i]);
      }
    }
  }
}

/*  Not using PBR Material anywhere

function recreatePBRMaterial(matData) {
    var mat = new BABYLON.PBRMaterial(matData.name, store.scene);
    mat.id = matData.name;
    if (matData.albedo)
        mat.albedoColor = BABYLON.Color3.FromArray(matData.albedo);
    if (matData.albedoTexture) {
        let relativeURL;
        let urlComponents = matData.albedoTexture.url.split('media');
        if (urlComponents[1] === '/') relativeURL = '/media/media' + urlComponents[2];
        else relativeURL = '/media' + matData.albedoTexture.url.split('media')[1];
        mat.albedoTexture = new BABYLON.Texture(window.location.origin + relativeURL, store.scene);
        mat.albedoTexture.uScale = matData.albedoTexture.uScale;
        mat.albedoTexture.vScale = matData.albedoTexture.vScale;
        mat.albedoTexture.uOffset = matData.albedoTexture.uOffset;
        mat.albedoTexture.vOffset = matData.albedoTexture.vOffset;
        mat.albedoTexture.wAng = matData.albedoTexture.wAng;
    }
    mat.environmentIntensity = matData.environmentIntensity;
    mat.specularIntensity = matData.specularIntensity;
    mat.microSurface = matData.microSurface;
    if (matData.reflectivity)
        mat.reflectivityColor = BABYLON.Color3.FromArray(matData.reflectivity);
    mat.directIntensity = matData.directIntensity;
    mat.backFaceCulling = false;
    if (matData.specular)
        mat.specularColor = BABYLON.Color3.FromArray(matData.specular);
    mat.zOffset = matData.zOffset;
    mat.alpha = matData.alpha;
    if (matData.cameraExposure)
        mat.cameraExposure = matData.cameraExposure;
    if (matData.cameraContrast)
        mat.cameraContrast = matData.cameraContrast;
    if (matData.reflectivityTexture){
        let texture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
        texture.uScale = 5.0;
        texture.vScale = 5.0;
        mat.reflectivityTexture = texture;
    }
    mat.useMicroSurfaceFromReflectivityMapAlpha = matData.useMicroSurfaceFromReflectivityMapAlpha;
    if (matData.materialType) {
        mat.materialType = matData.materialType;
    }

    // mat.alphaCutOff = matData.alphaCutOff;
    // mat.alphaMode = matData.alphaMode;
    // if (matData.ambient) {
    //     mat.ambientColor = new BABYLON.Color3(matData.ambient[0], matData.ambient[1], matData.ambient[2]);
    // }
    // mat.ambientTextureStrength = matData.ambientTextureStrength;
    // mat.backFaceCulling = matData.backFaceCulling;
    // // mat.checkReadyOnEveryCall = matData.checkReadyOnEveryCall;
    // // mat.checkReadyOnlyOnce = matData.checkReadyOnlyOnce;
    // // mat.customType = matData.customType;
    // mat.directIntensity = matData.directIntensity;
    // // mat.disableBumpMap = matData.disableBumpMap;
    // // mat.disableDepthWrite = matData.disableDepthWrite;
    // mat.disableLighting = matData.disableLighting;
    // if (matData.emissiveColor) {
    //     mat.emissiveColor = new BABYLON.Color3(matData.emissive[0], matData.emissive[1], matData.emissive[2]);
    // }
    // mat.emissiveIntensity = matData.emissiveIntensity;
    // // mat.environmentBRDFTexture = matData.environmentBRDFTexture;
    // mat.environmentIntensity = matData.environmentIntensity;
    // // mat.fillMode = matData.fillMode;
    // // mat.fogEnabled = matData.fogEnabled;
    // // mat.forceAlphaTest = matData.forceAlphaTest;
    // // mat.forceDepthWrite = matData.forceDepthWrite;
    // // mat.forceIrradianceFragment = matData.forceIrradianceFragment;
    // if (matData.albedo) mat.albedoColor = BABYLON.Vector3.FromArray(matData.albedo);
    // if (matData.albedoTexture) mat.albedoTexture = createTextureFromData(matData.albedoTexture);
    // if (matData.reflectivityTexture) mat.reflectivityTexture = createTextureFromData(matData.reflectivityTexture);
    // if (matData.microSurface) mat.microSurface = matData.microSurface;
    // if (matData.reflectivity) mat.reflectivityColor = BABYLON.Vector3.FromArray(matData.reflectivity);
    // if (matData.specularIntensity) mat.specularIntensity = matData.specularIntensity;
}

*/

function recreatePBRMaterialToStandard(matData) {
  let mat = new BABYLON.StandardMaterial(matData.name, store.scene);
  mat.id = matData.id;
  if (matData.albedo) mat.diffuseColor = BABYLON.Color3.White();
  if (matData.albedoTexture) {
    let relativeURL;
    let urlComponents = matData.albedoTexture.url.split("media");
    if (urlComponents[1] === "/")
      relativeURL = "/media/media" + urlComponents[2];
    else relativeURL = "/media" + matData.albedoTexture.url.split("media")[1];
    mat.diffuseTexture = new BABYLON.Texture(
      window.location.origin + relativeURL,
      store.scene,
      undefined,
      undefined,
      undefined,
      textureOnLoadCallback.bind(mat)
    );
    mat.diffuseTexture.uScale = matData.albedoTexture.uScale;
    mat.diffuseTexture.vScale = matData.albedoTexture.vScale;
    mat.diffuseTexture.uOffset = matData.albedoTexture.uOffset;
    mat.diffuseTexture.vOffset = matData.albedoTexture.vOffset;
    mat.diffuseTexture.wAng = matData.albedoTexture.wAng;
  }
  // mat.directIntensity = 0.5;
  // mat.environmentIntensity = 1.0;
  // mat.specularIntensity = 0.3;
  mat.cameraExposure = 0.9;
  mat.cameraContrast = 1.6;
  mat.backFaceCulling = false;
  // mat.specularTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", store.scene);
  // mat.useGlossinessFromSpecularMapAlpha = true;
  mat.zOffset = matData.zOffset;
  mat.alpha = matData.alpha;
  if (matData.materialType) {
    mat.materialType = matData.materialType;
  }
}

function recreateStandardMaterial (matData, category = null) {

  const onError = (message, exception) => {
    // console.log(message, mat);
    mat.diffuseTexture = null;
    if(category === null){
      category = matData?.metadata?.category
    }
    if (category && matData?.fallbackColor && ["furniture"].includes(category.toLowerCase())){
      mat.diffuseColor =  BABYLON.Color3.FromArray(matData.fallbackColor);
    }
    else {
      switch (category?.toLowerCase()) {
        case "furniture":
          mat.diffuseColor = new BABYLON.Color3(0.96, 0.97 ,0.92);
          break;
        case "wall":
          mat.diffuseColor = new BABYLON.Color3(1, 1, 1);
          break;
        case "floor":
          mat.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
          break;
        case "roof":
          mat.diffuseColor = new BABYLON.Color3(0.4, 0.4, 0.4);
          break;
        case "column":
          mat.diffuseColor = new BABYLON.Color3(0.55, 0.55, 0.55);
        default:
          mat.diffuseColor = new BABYLON.Color3(0.3, 0.3, 0.3);
          break;
      }
    }

    mat.emissiveColor = new BABYLON.Color3(0,0,0);
    // saveMaterialInBackend(mat);
  }
  var mat = new BABYLON.StandardMaterial(matData.name, store.scene);
  mat.id = matData.id;
  // BABYLON.Tools.fallbackTexture = `${djangoUrl}/media/media/materials/RAL_9010_JNZAKPF.jpg`
  if(matData?.revitImport || matData?.metadata?.hasAlpha ){
    if(!mat.metadata) mat.metadata = {}
    mat.revitImport = true
    mat.metadata.hasAlpha = matData.alpha < 1 ? true : false;
    mat.metadata.alpha = matData.alpha;
    if(category){
      mat.metadata.category = category
    }
  }
  // mat.emissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1);
  mat.diffuseColor = BABYLON.Color3.FromArray(matData.diffuse);
  if (matData.emissive)
  mat.emissiveColor = BABYLON.Color3.FromArray(matData.emissive);
  if (matData.diffuseTexture && matData.diffuseTexture?.url) {
    let relativeURL;

    let urlComponents = matData.diffuseTexture.url.split('media');
    let inStaticMaterialTypes = {
      RoomType: true,
      // Brick: true,
      // Concrete: true,
      // Wood: true,
      // Glass: true
    };
    if (matData.inStatic || matData.materialType === "RoomType") {
      let urlData = matData.diffuseTexture.url.split('/');
      let img = urlData[urlData.length - 1];
      relativeURL = '/media/materials/' + img;
      mat.inStatic = matData.inStatic;
    }
    // if (inStaticMaterialTypes[matData.materialType]) relativeURL = '/static' + matData.diffuseTexture.url.split("static")[1];
    // if (matData.materialType === "RoomType") relativeURL = '/static' + matData.diffuseTexture.url.split("static")[1];
    // if (matData.materialType === "WallType") relativeURL = '/static' + matData.diffuseTexture.url.split("static")[1];
    else if (urlComponents[1] === '/')
      relativeURL = '/media/media' + urlComponents[2];
    else
      relativeURL = '/media' + matData.diffuseTexture.url.split('media')[1];
    mat.diffuseTexture = new BABYLON.Texture(
      djangoUrl + relativeURL,
      store.scene, undefined, undefined, undefined,
      textureOnLoadCallback.bind(mat),
      onError
    );
    mat.diffuseTexture.uScale = matData.diffuseTexture.uScale ? matData.diffuseTexture.uScale :
      1 / (store.unit_absolute_scale * 10 * DisplayOperation.getOriginalDimension("1000", "millimeter"));
    mat.diffuseTexture.vScale = matData.diffuseTexture.vScale ? matData.diffuseTexture.vScale :
      1 / (store.unit_absolute_scale * 10 * DisplayOperation.getOriginalDimension("1000", "millimeter"));
    mat.diffuseTexture.uOffset = matData.diffuseTexture.uOffset;
    mat.diffuseTexture.vOffset = matData.diffuseTexture.vOffset;
    mat.diffuseTexture.wAng = matData.diffuseTexture.wAng;
    mat.diffuseTexture.hasAlpha = true;
  }
  // mat.directIntensity = 0.5;
  // mat.environmentIntensity = 1.0;
  // mat.specularIntensity = 0.3;
  mat.cameraExposure = 0.9;
  mat.cameraContrast = 1.6;
  mat.backFaceCulling = true;
  // mat.specularColor = new BABYLON.Color3(0, 0, 0);
  // mat.reflectivityTexture = new BABYLON.Texture(window.location.origin + "/media/Textures/granite/reflectivity.png", scene);
  // mat.useMicroSurfaceFromReflectivityMapAlpha = true;
  mat.zOffset = 0;
  mat.alpha = matData.alpha;
  if (matData.materialType) {
    mat.materialType = matData.materialType.charAt(0).toUpperCase() + matData.materialType.slice(1);
  }
}

function createTextureFromData(texData) {
  // return new Promise(resolve => {
  var tex = new BABYLON.Texture(texData.url, store.scene);
  tex.url = texData.url;
  // tex.level = texData.level;
  // tex.hadAlpha = texData.hasAlpha;
  // tex.getAlphaFromRGB = texData.getAlphaFromRGB;
  // tex.coordinatesMode = texData.coordinatesMode;
  tex.uOffset = texData.uOffset;
  tex.vOffset = texData.vOffset;
  tex.uScale = texData.uScale;
  tex.vScale = texData.vScale;
  tex.uAng = texData.uAng;
  tex.vAng = texData.vAng;
  tex.wAng = texData.wAng;
  tex.wrapU = texData.wrapU;
  tex.wrapV = texData.wrapV;
  // tex.coordinatesIndex = texData.coordinatesIndex;
  // tex.animations = texData.animations;
  // tex.base64String = texData.base64String;
  // tex.coordinatesMode = texData.coordinatesMode;
  // tex.gammaSpace = texData.gammaSpace;
  return tex;
  //console.log(tex, texData);
  // resolve(tex);
  // });
}

function recreateSceneInit(data) {
  recreateMaterials(data);
  for (var i = 0; i < data.meshes.length; i++) {
    if (nonDefaultMesh(data.meshes[i])) {
      if (
        data.meshes[i].isVisible ||
        data.meshes[i].name.toLowerCase() === "roof"
      ) {
        var geom = getGeometryById(
          data.geometries.vertexData,
          data.meshes[i].geometryId
        );
        if (geom !== -1) var mesh = recreateMesh(data.meshes[i], geom, data);
      }
    }
  }
  onSolid();
}

function findMultiMaterial(data, matId) {
  if (data.multiMaterials) {
    for (var i = 0; i < data.multiMaterials.length; i++) {
      if (data.multiMaterials[i].id === matId) {
        return data.multiMaterials[i];
      }
    }
  }
  return false;
}

function findMaterialByID(data, matId) {
  // console.log(data);
  if (data.materials) {
    // console.log(matId, data.materials);
    for (var i = 0; i < data.materials.length; i++) {
      if (data.materials[i].id === matId) {
        return data.materials[i];
      }
    }
  }
  return false;
}

function recreateMesh(mesh, geom, data) {
  var customMesh = new BABYLON.Mesh("custom", store.scene);

  var positions = geom.positions;
  var indices = geom.indices;
  var uvs = geom.uvs;
  var normals = geom.normals;

  var vertexData = new BABYLON.VertexData();

  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  vertexData.applyToMesh(customMesh);

  console.log(mesh);
  customMesh.setPivotPoint(
    customMesh.getBoundingInfo().boundingBox.centerWorld
  );

  customMesh.subMeshes = [];
  var verticesCount = customMesh.getTotalVertices();
  for (var i = 0; i < mesh.subMeshes.length; i++) {
    // customMesh.subMeshes.push(new BABYLON.SubMesh(mesh.subMeshes[i].materialIndex, 0, verticesCount, mesh.subMeshes[i].indexStart, mesh.subMeshes[i].indexCount, customMesh)); // Works perfectly
    customMesh.subMeshes.push(
      new BABYLON.SubMesh(
        mesh.subMeshes[i].materialIndex,
        mesh.subMeshes[i].verticesStart,
        mesh.subMeshes[i].verticesCount,
        mesh.subMeshes[i].indexStart,
        mesh.subMeshes[i].indexCount,
        customMesh
      )
    ); // Works perfectly
    customMesh.subMeshes.pop();
  }

  if (mesh.materialId) {
    var mat = store.newScene.getMaterialByID(mesh.materialId);
    if (!mat) {
      mat = findMultiMaterial(data, mesh.materialId);
      if (mat) {
        var multimat = new BABYLON.MultiMaterial(mat.name, store.scene);
        for (let i = 0; i < mat.materials.length; i++) {
          var tempMat = store.scene.getMaterialByID(mat.materials[i]);
          if (tempMat) {
            tempMat.backFaceCulling = false;
            multimat.subMaterials.push(tempMat);
          }
        }
        customMesh.material = multimat;
      } else {
        mat = findMaterialByID(data, mesh.materialId);
      }
    } else {
      mat.backFaceCulling = false;
      customMesh.material = mat;
    }
  }

  // if (mesh.name === "wall"){
  //     var multimat = new BABYLON.MultiMaterial("wall_multimat", store.scene);
  //     multimat.subMaterials.push(scene.getMaterialByName("wall_mat"));
  //     multimat.subMaterials.push(scene.getMaterialByName("floor_mat"));
  //     multimat.subMaterials.push(scene.getMaterialByName("wall_mat4"));
  //     customMesh.material = multimat;
  // }
  // else{
  //     customMesh.material = store.newScene.getMaterialByID(mesh.materialId);
  // }

  // if (mesh.name === 'wall'){
  //     customMesh.subMeshes = [];
  //     var verticesCount = customMesh.getTotalVertices();
  //     for (var i=0; i<mesh.subMeshes.length; i++)
  //         customMesh.subMeshes.push(new BABYLON.SubMesh(mesh.subMeshes[i].materialIndex, 0, verticesCount, mesh.subMeshes[i].indexStart, mesh.subMeshes[i].indexCount, customMesh)); // Works perfectly
  //
  //     customMesh.material = store.newScene.getMaterialByID(mesh.materialId);
  //     console.log(customMesh.material);
  // }
  // else{
  //     customMesh.material = store.newScene.getMaterialByID(mesh.materialId);
  // }

  if (mesh.name !== "Roof1") {
    customMesh.position = new BABYLON.Vector3(
      mesh.position[0],
      mesh.position[1],
      mesh.position[2]
    );
  }
  if (mesh.rotation) {
    customMesh.rotation = new BABYLON.Vector3(
      mesh.rotation[0],
      mesh.rotation[1],
      mesh.rotation[2]
    );
  } else if (mesh.rotationQuaternion) {
    customMesh.rotationQuaternion = new BABYLON.Quaternion(
      mesh.rotationQuaternion[0],
      mesh.rotationQuaternion[1],
      mesh.rotationQuaternion[2],
      mesh.rotationQuaternion[3]
    );
  }
  customMesh.scaling = new BABYLON.Vector3(
    mesh.scaling[0],
    mesh.scaling[1],
    mesh.scaling[2]
  );
  customMesh.name = mesh.name;
  customMesh.id = mesh.id;
  customMesh.offsetFlag = true;
  customMesh._properties = mesh._properties;
  log.warn(customMesh._properties);

  if (mesh.room_type) customMesh.room_type = mesh.room_type;
  if (mesh.room_id) customMesh.room_id = mesh.room_id;
  if (mesh.wall_type) customMesh.wall_type = mesh.wall_type;
  if (mesh.wall_id) customMesh.wall_id = mesh.wall_id;
  if (mesh.level) customMesh.level = mesh.level;
  if (mesh.level === 0) customMesh.level = mesh.level;
  if (mesh.type) customMesh.type = mesh.type;
  if (mesh.structure) customMesh.structure = mesh.structure;
  if (mesh.wall_pol) customMesh.wall_pol = mesh.wall_pol;
  if (mesh.prevVectorData) customMesh.prevVectorData = mesh.prevVectorData;
  if (mesh.wallJunctions)
    customMesh.wallJunctions = JSON.parse(mesh.wallJunctions);
  if (mesh.uniqueId) customMesh.uniqueId = mesh.uniqueId;
  if (mesh.prevVectorData) customMesh.prevVectorData = mesh.prevVectorData;
  if (mesh.floor) customMesh.floor = mesh.floor;
  if (mesh.neighborsWallMesh)
    customMesh.neighborsWallMesh = JSON.parse(mesh.neighborsWallMesh);
  if (mesh.parent) {
    customMesh.parent = store.scene.getMeshByUniqueID(mesh.parent);
    customMesh.parentId = mesh.parent;
  }
  if (mesh.checkForExtention)
    customMesh.checkForExtention = mesh.checkForExtention;
  if (mesh.visibilty == 0) customMesh.visibility = 0;
  if (mesh.isVisible == 0) customMesh.isVisible = 0;
  if (mesh.moveMeshObj) {
    customMesh.moveMeshObj = {};
    if (mesh.moveMeshObj.clkWiseMesh) {
      customMesh.moveMeshObj.clkWiseMesh = [];
      mesh.moveMeshObj.clkWiseMesh.forEach(function (obj) {
        customMesh.moveMeshObj.clkWiseMesh.push({
          mesh: obj.mesh,
          nearPointIndexes: obj.nearPointIndexes,
        });
      });
    }
    if (mesh.moveMeshObj.counterClkWiseMesh) {
      customMesh.moveMeshObj.counterClkWiseMesh = [];
      mesh.moveMeshObj.counterClkWiseMesh.forEach(function (obj) {
        customMesh.moveMeshObj.counterClkWiseMesh.push({
          mesh: obj.mesh,
          nearPointIndexes: obj.nearPointIndexes,
        });
      });
    }
  }

  meshVisibilityProcessing(customMesh);
  return customMesh;
}

function updateCompClasses(comp) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  if (comp._type.toLowerCase() === "wall") {
    updateCompClass(comp, $scope.wallClasses);
  }
  if (comp._type.toLowerCase() === "floor") {
    updateCompClass(comp, $scope.floorClasses);
  }
  if (comp._type.toLowerCase() === "ceiling") {
    updateCompClass(comp, $scope.ceilingClasses);
  }
  if (comp._type.toLowerCase() === "roof") {
    updateCompClass(comp, $scope.roofClasses);
  }
  if (comp._type.toLowerCase() === "stair") {
    updateCompClass(comp, $scope.stairClasses);
  }
  if (comp._type.toLowerCase() === "door") {
    updateCompClass(comp, $scope.doorClasses);
  }
  if (comp._type.toLowerCase() === "window") {
    updateCompClass(comp, $scope.windowClasses);
  }
}

function updateCompClass(comp, classes) {
  var n = classes.length;
  var flag = true;
  for (var p = 0; p < n; p++) {
    if (JSON.stringify(classes[p]) === JSON.stringify(comp)) {
      flag = false;
      break;
    }
  }
  if (flag) classes.push(comp);
}

function meshVisibilityProcessing(mesh) {
  if (mesh.name.indexOf("room") <= 2 && mesh.name.indexOf("room") !== -1) {
    mesh.enableEdgesRendering();
    mesh.edgesWidth = 8.0;
    mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
  }
  if (mesh.type) {
    // if (mesh.type.toLowerCase() === "door") mesh.showBoundingBox = true;
    // if (mesh.type.toLowerCase() === "window") mesh.showBoundingBox = true;
    if (mesh.type.toLowerCase() === "staircase") {
      mesh.enableEdgesRendering();
      mesh.edgesWidth = 2.0;
      mesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
    }
    if (mesh.type === "Wall") {
      mesh.enableEdgesRendering(1.0, true);
      mesh.edgesWidth = 5.0;
      mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    }
  }
}
function nonDefaultEdgeMesh(mesh) {
  if (mesh.name.indexOf("ground") !== -1) return false;
  if (mesh.name.indexOf("backwall") !== -1) return false;
  if (mesh.name.indexOf("axis") !== -1) return false;
  if (mesh.name.indexOf("boxScale") !== -1) return false;
  if (mesh.name.indexOf("rotLine") !== -1) return false;
  if (mesh.name.indexOf("default") !== -1) return false;
  if (mesh.name.indexOf("Default") !== -1) return false;
  //if (mesh.name.indexOf("edgeBox") !== -1) return false;
  if (mesh.name.indexOf("vertexBox") !== -1) return false;
  if (mesh.name.indexOf("isolatedViewSphere") !== -1) return false;
  return true;
}

function nonDefaultMesh(mesh) {
  if (!mesh.name) return false;
  if (mesh.name.indexOf("ground") !== -1) return false;
  if (mesh.name.indexOf("backwall") !== -1) return false;
  if (mesh.name.indexOf("axis") !== -1) return false;
  if (mesh.name.indexOf("boxScale") !== -1) return false;
  if (mesh.name.indexOf("rotLine") !== -1) return false;
  // if (mesh.name.indexOf("default") !== -1) return false;
  if (mesh.name.indexOf("Default") !== -1) return false;
  if (mesh.name.indexOf("edgeBox") !== -1) return false;
  if (mesh.name.indexOf("vertexBox") !== -1) return false;
  if (mesh.name.indexOf("faceBox") !== -1) return false;
  if (mesh.name.indexOf("isolatedViewSphere") !== -1) return false;
  // if (mesh.name.indexOf("twoPlane") !== -1) return false;
  if (mesh.name.indexOf("referenceGround") !== -1) return false;
  if (mesh.name.indexOf("heightReference") !== -1) return false;
  if (mesh.name.indexOf("dimline") !== -1) return false;
  if (mesh.name.indexOf("edgeTrajectory") !== -1) return false;
  if (mesh.name.indexOf("snappedAxis") !== -1) return false;
  if (mesh.name.indexOf("layerRefPlane") !== -1) return false;
  if (mesh.name.indexOf("sunSphere") !== -1) return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned)
    return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.visualElement)
    return false;
  if (mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh))
    return false;
  if (mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator))
    return false;

  return true;
}

function snapMeshforMeasure(mesh) {
  if (mesh.name.indexOf("ground") !== -1) return false;
  if (mesh.name.indexOf("backwall") !== -1) return false;
  if (mesh.name.indexOf("axis") !== -1) return false;
  if (mesh.name.indexOf("boxScale") !== -1) return false;
  if (mesh.name.indexOf("rotLine") !== -1) return false;
  if (mesh.name.indexOf("default") !== -1) return false;
  if (mesh.name.indexOf("Default") !== -1) return false;
  if (mesh.name.indexOf("edgeBox") !== -1) return false;
  if (mesh.name.indexOf("vertexBox") !== -1) return false;
  if (mesh.name.indexOf("faceBox") !== -1) return false;
  if (mesh.name.indexOf("edgeBox2") !== -1) return false;
  if (mesh.name.indexOf("vertexBox2") !== -1) return false;
  if (mesh.name.indexOf("faceBox2") !== -1) return false;
  if (mesh.name.indexOf("referenceGround") !== -1) return false;
  if (mesh.name.indexOf("heightReference") !== -1) return false;
  if (mesh.name.indexOf("dimline") !== -1) return false;
  if (mesh.name.indexOf("dimLine") !== -1) return false;
  if (mesh.name.indexOf("edgeTrajectory") !== -1) return false;
  if (mesh.name.indexOf("snappedAxis") !== -1) return false;
  if (mesh.name.indexOf("twoPlane") !== -1) return false;
  if (mesh.name.indexOf("sketchLine") !== -1) return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.visualElement)
    return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned)
    return false;
  // if (mesh.visibility === 0) return false;
  if (!mesh.isVisible) return false;
  return true;
}

function nonDefaultMeshForPositionCamera(mesh) {
  // if (mesh.name.indexOf("ground") !== -1) return false;
  if (mesh.name.indexOf("backwall") !== -1) return false;
  if (mesh.name.indexOf("axis") !== -1) return false;
  if (mesh.name.indexOf("boxScale") !== -1) return false;
  if (mesh.name.indexOf("rotLine") !== -1) return false;
  // if (mesh.name.indexOf("default") !== -1) return false;
  if (mesh.name.indexOf("Default") !== -1) return false;
  if (mesh.name.indexOf("edgeBox") !== -1) return false;
  if (mesh.name.indexOf("vertexBox") !== -1) return false;
  if (mesh.name.indexOf("faceBox") !== -1) return false;
  if (mesh.name.indexOf("edgeBox2") !== -1) return false;
  if (mesh.name.indexOf("vertexBox2") !== -1) return false;
  if (mesh.name.indexOf("faceBox2") !== -1) return false;
  if (mesh.name.indexOf("referenceGround") !== -1) return false;
  if (mesh.name.indexOf("heightReference") !== -1) return false;
  if (mesh.name.indexOf("dimline") !== -1) return false;
  if (mesh.name.indexOf("dimLine") !== -1) return false;
  if (mesh.name.indexOf("edgeTrajectory") !== -1) return false;
  if (mesh.name.indexOf("snappedAxis") !== -1) return false;
  if (mesh.name.indexOf("twoPlane") !== -1) return false;
  if (mesh.name.indexOf("sketchLine") !== -1) return false;
  if (mesh.name.indexOf("layerRefPlane") !== -1) return false;

  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.visualElement)
    return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned)
    return false;
  if (mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh))
    return false;
  // if (mesh.visibility === 0) return false;
  if (!mesh.isVisible) return false;
  return true;
}

function nonDefaultMeshForSnapping(mesh, options = {}) {
  if (!_.isBoolean(options.storeyCheck)) options.storeyCheck = true;

  if (!mesh.name) return false;
  if (mesh.name.indexOf("ground") !== -1) return false;
  if (mesh.name.indexOf("backwall") !== -1) return false;

  if (mesh.name.indexOf("boxScale") !== -1) return false;

  // if (mesh.name.indexOf("default") !== -1) return false;
  if (mesh.name.indexOf("Default") !== -1) return false;
  if (mesh.name.indexOf("dimline") !== -1) return false;
  if (mesh.name.indexOf("dimLine") !== -1) return false;
  // if (mesh.name.indexOf("twoPlane") !== -1) return false;
  if (mesh.name.indexOf("sketchLine") !== -1) return false;
  if (mesh.name.indexOf("layerRefPlane") !== -1) return false;
  // if (mesh.name.indexOf("layerRefPlane") !== -1) return false;
  // if (mesh.name.indexOf("Floor") !== -1) return false;
  // if (mesh.name.indexOf("Roof") !== -1) return false;
  // if (mesh.type === "Void") return false;

  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned)
    return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.visualElement)
    return false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.utilityElement)
    return false;
  if (mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh))
    return false;
  // if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.cadSketch) return false;

  if (mesh.visibility === 0) return false;
  if (!mesh.isVisible) return false;

  if (store.$scope.isTwoDimension && options.storeyCheck)
    if (parseInt(mesh.storey) !== parseInt(store.activeLayer.storey))
      return false;

  if (options.filter) {
    return options.filter(mesh);
  }

  return true;
}

function basicSnappingFilter(mesh, options = {}) {

  if (mesh.visibility === 0) return false;
  if (!mesh.isVisible) return false;
  if (!mesh.isPickable) return false;

  if (!_.isBoolean(options.storeyCheck)) options.storeyCheck = true;
  if (store.$scope.isTwoDimension && options.storeyCheck) {
      if (parseInt(mesh.storey) !== parseInt(store.activeLayer.storey)) return false;
  }

  if (options.meshExclusionCriterion){
      if (options.meshExclusionCriterion(mesh)) return false;
  }

  const basicTypes = [
      'mass',
      'wall',
      'roof',
      'floor',
      'furniture',
      'door',
      'window',
      'staircase',
      GLOBAL_CONSTANTS.strings.identifiers.cadSketch
  ];
  const meshTypeLowercase = mesh.type.toLowerCase();
  if (basicTypes.includes(meshTypeLowercase)) return true;

  const basicNames = [
      GLOBAL_CONSTANTS.strings.identifiers.uploadedSketch
  ];
  const meshNameLowercase = mesh.name.toLowerCase();
  if (basicNames.includes(meshNameLowercase)) return true;

  if (options.meshInclusionCriterion){
      if (options.meshInclusionCriterion(mesh)) return true;
  }

  return false;
}

function basicFamily(mesh) {
  if (
    mesh.name.toLowerCase() === "mass" ||
    mesh.name.toLowerCase() === "wall" ||
    mesh.name.toLowerCase() === "room"
  ) {
    return true;
  } else if (mesh.type) {
    if (
      mesh.type.toLowerCase() === "mass" ||
      mesh.type.toLowerCase() === "wall" ||
      mesh.type.toLowerCase() === "room"
    ) {
      return true;
    }
  } else {
    return false;
  }
}

function assignIndices(mesh) {
  var indices = mesh.getIndices();
  var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  for (var i = 0; i < indices.length; i++) {
    var vert = new BABYLON.Vector3(
      verData[indices[i] * 3],
      verData[indices[i] * 3 + 1],
      verData[indices[i] * 3 + 2]
    );
  }
}

function assignProperties(mesh, index, type, comp) {
  if (!mesh) return;
  if (!mesh.name) return;
  if (type === "mass") {
    if (mesh.name.indexOf("wall") === -1) {
      mesh._properties = jQuery.extend({}, massProperties);
      mesh._properties._type = "Mass";
      mesh._properties._id = mesh.room_id;
      mesh._properties._level = mesh.level;
      mesh._properties._height = mesh.height;
      mesh._properties._indices = mesh.room_path;
      mesh._properties._curve = mesh.room_curve;
      mesh._properties._components = comp ? comp : BasicMassComp;
    } else {
      mesh._properties = jQuery.extend({}, massProperties);
      mesh._properties._type = "Mass";
      mesh._properties._id = mesh.wall_id;
      mesh._properties._level = mesh.level;
      mesh._properties._height = mesh.height;
      mesh._properties._indices = mesh.wall_path;
      mesh._properties._curve = mesh.wall_curve;
      mesh._properties._components = BasicWallComp;
    }
  }
  if (type === "wall") {
    // var $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    mesh._properties = jQuery.extend({}, wallProperties);
    mesh._properties._type = "Wall";
    // mesh._properties._rooms.push(mesh.room_name);
    mesh._properties._id = mesh.room_id;
    mesh._properties._level = mesh.level;
    mesh._properties._indices = mesh.room_path;
    mesh._properties._curve = mesh.room_curve;
    mesh._properties._layers = [];
    // var item = {check:false, value:"Concrete", thickness: 200};
    // mesh._properties._layers.push(item);
    mesh._properties._components = comp ? comp : BasicWallComp;
  }
  if (type === "floor") {
    // let $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    mesh._properties = jQuery.extend({}, floorProperties);
    mesh._properties._type = "Floor";
    mesh._properties._rooms.push(mesh.room_name);
    mesh._properties._id = mesh.room_id;
    mesh._properties._level = mesh.level;
    mesh._properties._indices = mesh.room_path;
    mesh._properties._curve = mesh.room_curve;
    mesh._properties._components = BasicFloorComp;
  }
  if (type === "roof") {
    // let $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    mesh._properties = jQuery.extend({}, roofProperties);
    mesh._properties._type = "Roof";
    mesh._properties._rooms.push(mesh.room_name);
    mesh._properties._id = mesh.room_id;
    mesh._properties._level = mesh.level;
    mesh._properties._indices = mesh.room_path;
    mesh._properties._curve = mesh.room_curve;
    mesh._properties._components = BasicRoofComp;
  }
}

function updateSceneMeshes(meshArray) {
  for (var i = 0; i < meshArray.length; i++) {
    var newMesh = new BABYLON.AbstractMesh(meshArray[i].name, store.scene);
    store.scene.addMesh(newMesh);
    click(newMesh);
  }
}

function undo2() {
  if (store.state.length > 1) {
    var canStateRedo = {
      meshData: getMeshData(),
      can_mode: $("#mass_mode").prop("checked"),
    };
    store.redoState.push(canStateRedo);
    removeRooms();
    removeObjects();
    var canState = store.state.pop();
    if (store.fplan_mode) {
      restoreMeshes(canState.meshData);
    } else if (!canState.can_mode) {
      // createRooms();
      console.log("Nothing to see here");
    } else {
      // (canState.meshData.coord);
      createMassBlocksFromData(canState.meshData);
    }
    // send_complete_scene_data("save");
    // updateModifications();
  }
}

function createSceneBabylon(baby_scene_path_send) {
  // window.location.origin + "/" + baby_scene_path_send;
  BABYLON.SceneLoader.ImportMesh(
    "",
    "",
    window.location.origin + "/" + baby_scene_path_send,
    store.scene,
    function (mesh) {
      // loadTexture(mesh, comp_path_dir);
      // mesh;
    },
    function (evt) { }
  );
}

function isCyclic(obj) {
  var seenObjects = [];

  function detect(obj) {
    if (obj && typeof obj === "object") {
      if (seenObjects.indexOf(obj) !== -1) {
        return true;
      }
      seenObjects.push(obj);
      for (var key in obj) {
        if (obj.hasOwnProperty(key) && detect(obj[key])) {
          console.log(obj, "cycle at " + key);
          return true;
        }
      }
    }
    return false;
  }

  return detect(obj);
}

function getMeshData2() {
  let meshData = {};
  meshData.name = [];
  meshData.room = [];
  meshData.pos = [];
  meshData.rot = [];
  meshData.scaling = [];
  meshData.verData = [];
  meshData.id = [];
  meshData.coord = [];
  meshData.levels = [];
  meshData.curves = [];
  meshData.paths = [];
  meshData.materials = [];
  meshData.subMeshes = [];
  meshData.indices = [];

  // if (!$('#mass_mode').prop('checked')) {
  //     return meshData;
  // }
  var meshObjs = [];
  for (var i = 0; i < store.scene.meshes.length; i++) {
    var mesh = store.scene.meshes[i];
    // if (fplan_mode){
    //   meshObjs.push(JSON.stringify(JSON.decycle(mesh)));
    // }
    if (
      mesh.name.indexOf("boxScale") === -1 &&
      (mesh.name.indexOf("room") !== -1 ||
        mesh.name.indexOf("default") !== -1 ||
        mesh.name.indexOf("Default") !== -1)
    ) {
      meshData.name.push(store.scene.meshes[i].name);
      meshData.room.push(store.scene.meshes[i].room_type);
      meshData.curves.push(store.scene.meshes[i].room_curve);
      meshData.paths.push(store.scene.meshes[i].room_path);
      // (scene.meshes[i].room_id, store.scene.meshes[i].name);
      if (store.scene.meshes[i].room_id != null) {
        meshData.id.push(store.scene.meshes[i].room_id);
      } else {
        meshData.id.push(i);
      }
      meshData.pos.push([
        store.scene.meshes[i].position.x / store.unit_scale,
        store.scene.meshes[i].position.y / store.unit_scale,
        store.scene.meshes[i].position.z / store.unit_scale,
      ]);
      meshData.rot.push([
        store.scene.meshes[i].rotation.x,
        store.scene.meshes[i].rotation.y,
        store.scene.meshes[i].rotation.z,
      ]);
      meshData.scaling.push([
        store.scene.meshes[i].scaling.x,
        store.scene.meshes[i].scaling.y,
        store.scene.meshes[i].scaling.z,
      ]);

      //Remove Cicular Referencing from material and meshes
      var meshMat = getMeshMat(store.scene.meshes[i].material);
      var meshSubMesh = getMeshSubMesh(store.scene.meshes[i].subMeshes);
      // (meshMat);
      meshData.materials.push(meshMat);
      meshData.subMeshes.push(meshSubMesh);
      // meshData.subMeshes.push(scene.meshes[i].subMeshes);
      meshData.indices.push(store.scene.meshes[i].getIndices());

      var meshLevel = Math.floor(
        store.scene.meshes[i].position.y / store.floor_height
      );
      // (meshLevel, store.scene.meshes[i].position.y, store.floor_height);
      meshData.levels.push(meshLevel);
      var verData = store.scene.meshes[i].getVerticesData(
        BABYLON.VertexBuffer.PositionKind
      );
      var builtPol = [];
      var verDataTemp = [];
      if (!verData) {
        continue;
      }
      for (var j = 0; j < verData.length; j += 3) {
        if (verData[j + 1] == 0) {
          // (verData[j], -verData[j+2], store.unit_scale);
          builtPol.push([
            verData[j] / store.unit_scale,
            -verData[j + 2] / store.unit_scale,
          ]);
        }
        verDataTemp.push(verData[j] / store.unit_scale);
        verDataTemp.push(verData[j + 1] / store.unit_scale);
        verDataTemp.push(verData[j + 2] / store.unit_scale);
      }
      builtPol = builtPol.uniq([].join);
      builtPol.push(builtPol[0]);
      // (builtPol);
      meshData.coord.push(builtPol);
      // Change from Vertext Data to the entire mesh data for experiment
      // meshData.verData.push(verDataTemp);
      // (mesh);
      meshData.verData.push(verDataTemp);
    }
  }
  // // (meshData);
  // if (fplan_mode){
  //   return meshObjs;
  // }
  return meshData;
}
/**
 * Gets the mesh data uppdate.
 *
 * @return     {Array}  The mesh data update.
 */
function getMeshDataUpdate() {
  var sceneMeshData = [];
  for (var i = 0; i < store.scene.meshes.length; i++) {
    var mesh = store.scene.meshes[i];
    if (
      mesh.name.indexOf("boxScale") == -1 &&
      (mesh.name.indexOf("room") != -1 ||
        mesh.name.indexOf("default") != -1 ||
        mesh.name.indexOf("Default") != -1)
    ) {
      store.sceneMeshData.push(store.scene.meshes[i]);
    }
  }
  return store.sceneMeshData;
}
export {
  getMeshData,
  updateModifications,
  redo,
  undo,
  CMUndo,
  CMRedo,
  removeAllMeshes,
  getGeometryById,
  getMaterialById,
  recreateScene,
  recreateMultiMaterials,
  recreateMaterials,
  recreatePBRMaterialToStandard,
  recreateStandardMaterial,
  createTextureFromData,
  recreateSceneInit,
  findMultiMaterial,
  findMaterialByID,
  recreateMesh,
  updateCompClasses,
  updateCompClass,
  meshVisibilityProcessing,
  nonDefaultEdgeMesh,
  nonDefaultMesh,
  snapMeshforMeasure,
  nonDefaultMeshForPositionCamera,
  nonDefaultMeshForSnapping,
  basicFamily,
  assignIndices,
  assignProperties,
  updateSceneMeshes,
  undo2,
  createSceneBabylon,
  isCyclic,
  getMeshData2,
  getMeshDataUpdate,
};
