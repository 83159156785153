import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { StoreyMutation } from "../../../modules/storeyEngine/storeyMutations";
import { store } from "../../../modules/utilityFunctions/Store";
import { DisplayOperation } from "../../../modules/displayOperations/displayOperation";

export const updateHeight = createAsyncThunk(
  "storeys/updateHeight",
  async (data, { rejectWithValue}) => {
    const { storeyValue, storeyHeight } = data;
    try {
      const height = DisplayOperation.getOriginalDimension(storeyHeight);
      await new Promise(resolve => {
        StoreyMutation.updateHeight(
          store.activeLayer.structure_id,
          storeyValue,
          height
        );
        resolve();
      })
    }
    catch (err) {
      console.warn(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  items: [],
  unique: false,
};

export const storeysSlice = createSlice({
  name: "storeys",
  initialState,
  reducers: {
    resetStoreys: state => initialState,
    addBlankStoreys: {
      reducer(state, action) {
        const { items } = action.payload;
        state.items.push(...items);
        state.items.sort((a, b) => {
          return b.value - a.value;
        });
      },
      prepare(numberOnBlankMode, isAbove) {
        const payload = {
          items: [],
        };

        for (let i = 0; i < numberOnBlankMode; i++) {
          const storey = StoreyMutation.createNewBlankStorey(
            store.activeLayer.structure_id,
            isAbove
          );

          payload.items.push({
            id: storey.id,
            value: storey.value,
            name: storey.name,
            height: DisplayOperation.convertToDefaultDimension(storey.height),
            hidden: storey.hidden,
            layers: [],
          });
        }

        return {
          payload,
        };
      },
    },
    appendStorey(state, action) {
      const { items } = action.payload;
      state.items.push(...items);
      state.items.sort((a, b) => {
        return b.value - a.value;
      });
    },
    updateStorey(state, action) {
      const { storeyValue, property, value } = action.payload;
      const existingStorey = state.items.find(
        (storey) => storey.value == storeyValue
      );
      if (existingStorey) {
        existingStorey[property] = value;
        if (property == "hidden") {
          StoreyMutation.showOrHideStorey(
            store.activeLayer.structure_id,
            storeyValue,
            value
          );
        }
      }
    },
    updateHeightUnits(state, action) {
      const storeyCollection = StoreyMutation.getAllStoreys();
      state.items.forEach(storeyData => {
        const storey = storeyCollection[storeyData.value];
        storeyData.height = DisplayOperation.convertToDefaultDimension(storey.getHeight());
      })
    },
    appendLayer(state, action) {
      const { storey } = action.payload;
      const str = state.items.find((st) => st.value == storey);
      if (str) {
        const exists = str.layers?.find(item => item?.id == action.payload?.id) // to prevent duplicates
        if(exists == undefined) {
          str.layers.push(action.payload);
        }
      }
    },
    deleteLayer(state, action) {
      const { layerId, storeyValue } = action.payload;
      const newState = state;
      newState.items.forEach(storey => {
        if(storey.value === storeyValue){
          storey.layers = storey.layers.filter(layer => layer.id !== layerId);
        }
      });
      state = newState;
    },
    showOrHideLayer(state, action) {
      const { layerId, storeyValue, operation } = action.payload;
      const str = state.items.find((st) => st.value === storeyValue);
      if (str) {
        const layerIndex = str.layers.findIndex((l) => l.id === layerId);

        str.layers[layerIndex].hidden = !!operation.includes("hide");
      }
    },
    enableOrDisableHeightMap(state, action){
      const { layerId, storeyValue } = action.payload;
      const str = state.items.find((st) => st.value === storeyValue);
      if (str) {
        const layerIndex = str.layers.findIndex((l) => l.id === layerId);
        str.layers[layerIndex].heightMapToggle = !str.layers[layerIndex].heightMapToggle;
      }
    },
    toggleHideOrShowStorey: (state, action) => {
      const {storey, visibility} = action.payload;
      const newState = state;
      const str = newState.items.find((st) => st.value === storey);
      str.hidden = !(StoreyMutation._CONSTANTS.show === visibility);
      state = newState;
    }
  },
});

export const {
  resetStoreys,
  addBlankStoreys,
  appendStorey,
  updateStorey,
  updateHeightUnits,
  appendLayer,
  deleteLayer,
  showOrHideLayer,
  duplicateStoreys,
  enableOrDisableHeightMap,
  toggleHideOrShowStorey
} = storeysSlice.actions;

export default storeysSlice.reducer;

export const selectAllStoreys = (state) => state.storeys.items;

export const selectStorey = (state, storeyValue) => {
  return state.items.find((st) => st.value === storeyValue);
};

export const selectLayers = (state, storeyValue) => {
  const storey = state.items.find((st) => st.value === storeyValue);
  return storey.layers;
};
