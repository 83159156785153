export const MATERIAL_TYPE_OPTIONS = ["Colors", "Concrete", "Glass", "Metal", "Tiles", "Stone", "Wood", "Groundcover", "Brick"]
export const FURNITURE_TYPE_OPTIONS = ["Living", "Bedroom", "Kitchen", "Dining", "Bathroom", "Balcony", "Landscape", "People", "Office", "Private Office", "Meeting Room", "Cafeteria", "Reception"]
export const MATERIAL_PROPERTIES = [
    {title: "Texture", key: "texture"},
    {title: "Material Name", key: "displayName"},
    {title: "Material Type", key: "type"},
    // {title: "uScale", key: ""},
    // {title: "vScale", key: ""},
    // {title: "Transparency", key: ""},
    // {title: "Angle", key: ""},
    {title: "Cost / sqm ($)", key: "cost"},
    {title: "Manufacturer", key: "manufacturer"},
    {title: "Description", key: "description"},
]

export const FURNITURE_PROPERTIES = [
    {title: "Image", key: "image"},
    {title: "Furniture Name", key: "displayName"},
    {title: "Family Name", key: "familyName"},
    {title: "Room", key: "subType"},
    {title: "Cost ($)", key: "cost"},
    {title: "Vendor", key: "vendor"},
    {title: "Description", key: "description"},
    // {title: "Length", key: ""},
    // {title: "Breadth", key: ""},
    // {title: "Height", key: ""},
    
]

export const CONTEXT_MENU_STATE_DEFAULT = {
    show: false,
    x: 0,
    y: 0,
    elementId: null
}

export const DIRECTION = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: ''
}