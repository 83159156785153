import _ from "lodash";
import { AutoSave } from "./autoSave";
import { sketchOperation } from "../meshoperations/sketchOperation";
import { commandUtils } from "../commandManager/CommandUtils";
import {
    _handleCollaborationForCAD,
    _handleCollaborationForDeleteCAD,
    _handleCollaborationForMoveCAD,
    handleCollaborationForShowOrHideLayer
} from '../cadImporter/cadServices';
import { terrainGeneration, _handleCollaborationForSetStoreyHeight } from "../geo/terrainMap";
import {importPdfOperations} from "../../libs/importPdfOperations";
import { Locker } from "../locker/locker";
import stackedWallCommandHelper from "../stackedWalls/stackedWallCommandHelper";
import {handleCollaborationForFloorPlanDeletion} from "../../libs/twoDimension";

const autoListener = saveData => {
  const router = {
      'updateGeometry': commandUtils.geometryChangeOperations,
      'updateWM': commandUtils.worldMatrixChangeOperations,
      'creation': commandUtils.creationOperations,
      'deletion': commandUtils.deletionOperations,
      'addStorey': commandUtils.storeyOperations,
      'addLayer': commandUtils.storeyOperations,
      'updateStorey': commandUtils.storeyOperations,
      'updateStoreyName': commandUtils.storeyOperations,
      'changeStoreyHeight': commandUtils.storeyOperations,
      'addFloorPlan': commandUtils.storeyOperations,
      'updateFloorPlanWM': commandUtils.storeyOperations,
      'deleteFloorPlan': handleCollaborationForFloorPlanDeletion(),
      'propertyChange': commandUtils.propertyChangeOperations,
      'addMaterial': commandUtils.materialOperations,
      'applyMaterial': commandUtils.materialOperations,
      'deleteMaterial': commandUtils.materialOperations,
      'editMaterial': commandUtils.materialOperations,
      'parentChildChange': commandUtils.parentChildOperations,
      "terrainMapGeneration": terrainGeneration,
      "terrainMapDeletion": terrainGeneration._handleCollaborationOfTerrainDeletion(),
      "updateTerrainWM": commandUtils.worldMatrixChangeOperations,
      "setTerrainHeight": _handleCollaborationForSetStoreyHeight(),
      "addCadSketch": _handleCollaborationForCAD(),
      "moveCAD": _handleCollaborationForMoveCAD(),
      "cadSketchDeletion":_handleCollaborationForDeleteCAD(),
      "addSketch": sketchOperation,
      "addPdf": importPdfOperations.handleCollaborationForAddPdf(),
      "deletePdf": importPdfOperations.handleCollaborationForDeletePdf(),
      "updatePdfWM": commandUtils.worldMatrixChangeOperations,
      "lockOperation": Locker.Utils,
      "showLayer": handleCollaborationForShowOrHideLayer(),
      "stackedWallData": stackedWallCommandHelper,
  };

  let payload = saveData.payload;
  if(!_.isArray(saveData.payload)){
      payload = [saveData.payload];
  }

  for(let i = 0; i < payload.length; i++) {
      if(router[payload[i].data.saveType]){
          if (saveData.execute) {
              router[payload[i].data.saveType].executeFromSaveData(payload[i].data);
          }
          else {
              router[payload[i].data.saveType].unExecuteFromSaveData(payload[i].data);
          }
      }
  }

  AutoSave.incomingSave({
      id: saveData.history.id,
      author: null
  });
};

export {
  autoListener,
};