export const TEAM_ROLES = {
    ADMIN: "admin",
    VIEWER: "viewer",
    EDITOR: "editor",
    CREATOR: "creator",
    MEMBER: "memeber"
}

export const PROJECT_DROPDOWN_OPTIONS = [
    { title: "Open", sep: true },
    // "Duplicate",
    "Rename",
    { title: "Delete", sep: true },
    // "Share",
    // "Export Model",
    // "Download BBQ",
    // "Download Areas",
    // "Download Views",
  ];
  
  export const PERMISSIONS = {
    PROJECTS: [
      { title: "Create Project", key: "create_project", isDisabled: [TEAM_ROLES.ADMIN, TEAM_ROLES.CREATOR, TEAM_ROLES.EDITOR, TEAM_ROLES.VIEWER], },
      { title: "Edit Model", key: "edit_model", isDisabled: [TEAM_ROLES.ADMIN, TEAM_ROLES.CREATOR, TEAM_ROLES.EDITOR, TEAM_ROLES.VIEWER ], isDisabledIf: "create_project"},
      { title: "View Area Statements", key: "view_area_statements", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "View Boq", key: "view_boq", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "View Comment History", key: "view_comment_history", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Measure Distances", key: "measure_distances", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Measure Angles", key: "measure_angles", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Lock And Unlock Objects", key: "lock_and_unlock_objects", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Hide And Show Objects", key: "hide_and_show_objects", isDisabled: [TEAM_ROLES.ADMIN, ], },
      // { title: "Create Revit Model Import", key: "create_revit_model_import", isDisabled: [TEAM_ROLES.ADMIN, TEAM_ROLES.CREATOR, TEAM_ROLES.EDITOR, TEAM_ROLES.VIEWER ], },
      { title: "Save As Model", key: "save_as_model", isDisabled: [TEAM_ROLES.ADMIN, TEAM_ROLES.CREATOR, TEAM_ROLES.EDITOR, TEAM_ROLES.VIEWER ], },
      { title: "Export Model", key: "export_model", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Export Area Statements", key: "export_area_statements", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Export Boq", key: "export_boq", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Export Comment History", key: "export_comment_history", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Add Comments", key: "add_comments", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Edit And Delete Comments", key: "edit_and_delete_comments", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Resolve Comments", key: "resolve_comments", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Start Brainstorming Session", key: "start_brainstorming_session", isDisabled: [TEAM_ROLES.ADMIN, ], },
    ],
    LIBRARIES: [
      { title: "Add To Library", key: "add_to_library", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Edit Library Properties", key: "edit_library_properties", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "View Library", key: "view_library", isDisabled: [TEAM_ROLES.ADMIN, ], },
    ],
    TEMPLATES: [
      { title: "Create Templates", key: "create_templates", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Edit Templates", key: "edit_templates", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Copy From Template To Project", key: "copy_from_template_to_project", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Save Template As Project", key: "save_template_as_project", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Duplicate Templates", key: "duplicate_templates", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "View Templates", key: "view_templates", isDisabled: [TEAM_ROLES.ADMIN, ], },
    ],
    GENERAL: [
      { title: "Add Users To Team", key: "add_users_to_team", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Invite Users To Model", key: "invite_users_to_model", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Create, rename & delete folder", key: "create_rename_delete_folder", isDisabled: [TEAM_ROLES.ADMIN, ], },
      { title: "Add projects to folder", key: "add_projects_to_folder", isDisabled: [TEAM_ROLES.ADMIN, ], },
      // { title: "Add New Folders", key: "add_new_folders", isDisabled: [TEAM_ROLES.ADMIN, ], },
      // { title: "Reorganize Folders And Models", key: "reorganize_folders_and_models", isDisabled: [TEAM_ROLES.ADMIN, ], },
    ],
  };
  