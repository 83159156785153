import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import teamWorkspaceIcon from "../../../assets/icons/teamWorkspaceIcon.svg";
import projectIcon from "../../../assets/icons/projectIcon.svg";
import settingsIcon from "../../../assets/icons/settingsIcon.svg";
import librariesIcon from "../../../assets/icons/librariesIcon.svg";
import { Link, useLocation } from "react-router-dom";
import addFolderIcon from "../../../assets/icons/addFolderIcon.svg";
import FolderSidebar from "./FolderSidebar";
import { useFileSystemUpdate } from "../../../contexts/FileSystemContext";
import { useTeamRightClickMenuUpdate } from "../../../contexts/TeamRightClickMenuContext";
import { REGEXP } from "../../../common/utils/constants";
import { TEAM_ROLES } from "../../../containers/dashboard/TeamDashboard/constants";
import { DragDropWrapper } from "../../DragDrop";
import { handleItemsMove } from "../../Folder/FoldersContainer";
import { generatePathToFolder } from "../../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import { ProjectLink } from "./PersonalSidebar";

const Wrapper = styled.div`
    font-size: 0.875rem;
    font-weight: normal;
    color: ${colors.thinGray};
    cursor: pointer;

    img{
        display: block;
    }
    a{
        height: 2rem;
        color: ${colors.thinGray};
        text-decoration: none;
    }
    a:visited{
        color: ${colors.thinGray};
        text-decoration: none;
    }
    .titlebar{
        display: flex;
        align-items: center;
    }
    .titlebar.active{
        color: ${colors.darkGrey};
        font-weight: bold;
    }
    .titlebar.active .icon{
        filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg) brightness(93%) contrast(98%);
    }
    .titlebar.active-route{
        border-right: 0.125rem solid ${colors.brightRed};
    }
    .titlebar:hover{
        background-color: ${colors.white};
        cursor: pointer;
    }
    .title{
        display: flex;
        flex: 1;
        align-items: center;
        color: ${colors.thinGray};
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titletext{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .add-folder-button{
        position: sticky;
        right: 10px;
        visibility: hidden;
    }
    .titlebar:hover .add-folder-button{
        visibility: visible;
    }
    .expand-folder-button{
        padding: 0px 5px;
    }
    .rotate-90{
        transform: rotate(90deg);
    }
`

function TeamSidebar({ team, selectedTeamID, permissions }) {
    const ref = useRef(null);
    const location = useLocation();
    const fileSystemUpdate = useFileSystemUpdate();
    const teamRightClickMenuUpdate = useTeamRightClickMenuUpdate();
    useEffect(() => {
        ref.current.addEventListener("contextmenu", handleContextMenu);
        return () => {
            ref.current.removeEventListener("contextmenu", handleContextMenu);
        }
    }, []);
    const handleContextMenu = (evt) => {
        evt.preventDefault();
        const { x, y } = evt;
        teamRightClickMenuUpdate({ x, y }, team, true);
    }
    const teamRoutes = useMemo(() => {
        return {
            default: team.route,
            libraries: team.route + "/libraries",
            preferences: team.route + "/preferences"
        }
    }, [team.route]);

    const selectedFolderID = useMemo(() => {
        if (location.pathname.match(REGEXP.folder)) {
            const folderID = location.pathname.split("/").pop();
            if (folderID) return parseInt(folderID);
        }
        return null;
    }, [location.pathname]);

    const expandFolders = useMemo(() => {
        if (location.pathname.match(REGEXP.folder)) return true;
        if (location.pathname === teamRoutes.default) return true;
        return false;
    }, [teamRoutes, location.pathname])

    const path = useMemo(() => {
        let pathToFolder = []
        if(selectedFolderID){
            pathToFolder = generatePathToFolder({ isDummy: true, folders: team.fileSystem.folders }, selectedFolderID).path;
        }
        return pathToFolder;
    }, [selectedFolderID, team.fileSystem]);

    return (
        <Wrapper>
            <Link ref={ref} className={`titlebar ${team.id === selectedTeamID ? 'active' : ''}`} to={team.route}>
                <div className="icon"><img src={teamWorkspaceIcon} alt="team" /></div>
                <div className="titletext">{team.title}</div>
            </Link>
            {
                team.id === selectedTeamID &&
                <div style={{paddingLeft: "10px"}}>
                    <DragDropWrapper
                        ddId={"drop_team_root"}
                        id="root"
                        bucketName="folders"
                        type="folder"
                        data={{ team_dashboard: team.id, id: "root"}}
                        accept={["folder", "file"]}
                        onDrop={handleItemsMove}
                        style={{}}
                        selectionStyle={{}}
                        dropAreaStyle={{}}
                        draggable={false}
                        enableDrop
                    >
                        <ProjectLink
                            active={location.pathname === teamRoutes.default}
                            route={team.route}
                            showAddFolder={permissions["create_rename_delete_folder"]}
                            onAddFolder={() => { fileSystemUpdate(team.id, null, "create") }}
                            alt="team"
                        />
                    </DragDropWrapper>
                    {
                        expandFolders &&
                        <FolderSidebar
                            folders={team.fileSystem?.folders}
                            workspaceRoute={teamRoutes.default}
                            selectedFolderID={selectedFolderID}
                            permissions={permissions}
                            path={path}
                        />
                    }
                    {
                        permissions["view_library"] &&
                        <div className={`titlebar ${location.pathname === teamRoutes.libraries ? 'active active-route' : ''}`} >
                            <Link className={`title`} to={teamRoutes.libraries}>
                                <div className="icon"><img src={librariesIcon} alt="team" /></div>
                                <div className="titletext">Libraries</div>
                            </Link>
                        </div>
                    }
                    {
                        team.role === TEAM_ROLES.ADMIN &&
                        <div className={`titlebar ${location.pathname === teamRoutes.preferences ? 'active active-route' : ''}`} >
                            <Link className={`title`} to={teamRoutes.preferences}>
                                <div className="icon"><img src={settingsIcon} alt="team" /></div>
                                <div className="titletext">Preferences</div>
                            </Link>
                        </div>
                    }
                </div>
            }
        </Wrapper>
    );
}

export default TeamSidebar;