/*jshint esversion: 6 */
"use strict";
import { StructureCollection } from "./snaptrudeDS/structure.ds.js";

var GLOBAL_CONSTANTS_FUNCTIONS = (function () {
  "use strict";
  var _some_global_variable = null;

  /**
   * { function_description }
   *
   * @param      {Object}   mesh    The mesh
   * @return     {Promise}  { description_of_the_return_value }
   */
  function _deleteMeshFromStructure(mesh) {
    return new Promise((resolve, reject) => {
      let sc = StructureCollection.getInstance();
      let structure = sc.getStructureById(mesh.structure_id);

      let levels = structure.getAllLevels();
      for (let level_id in levels) {
        let level = levels[level_id];

        let masses = level.getMasses();
        for (let i = 0; i < masses.length; i++) {
          if (masses[i].mesh.uniqueId === mesh.uniqueId) {
            masses.splice(i, 1);
            resolve();
          }
        }

        let walls = level.getWalls();
        for (let i = 0; i < walls.length; i++) {
          if (walls[i].mesh.uniqueId === mesh.uniqueId) {
            walls.splice(i, 1);
            resolve();
          }
        }

        let doors = level.getDoors();
        for (let i = 0; i < doors.length; i++) {
          if (doors[i].mesh.uniqueId === mesh.uniqueId) {
            doors.splice(i, 1);
            resolve();
          }
        }

        let win = level.getWindows();
        for (let i = 0; i < win.length; i++) {
          if (win[i].mesh.uniqueId === mesh.uniqueId) {
            win.splice(i, 1);
            resolve();
          }
        }

        let roofs = level.getRoofs();
        for (let i = 0; i < roofs.length; i++) {
          if (roofs[i].mesh.uniqueId === mesh.uniqueId) {
            roofs.splice(i, 1);
            resolve();
          }
        }

        let floors = level.getFloors();
        for (let i = 0; i < floors.length; i++) {
          if (floors[i].mesh.uniqueId === mesh.uniqueId) {
            floors.splice(i, 1);
            resolve();
          }
        }
      }

      reject("not found");
    });
  }

  return {
    some_variable: _some_global_variable,
    deleteMeshFromStructure: function (mesh) {
      return _deleteMeshFromStructure(mesh);
    },
  };

  //add others
})();

/**
 * { function_description }
 *
 * @class      Group (name)
 * @param      {<type>}  id      The identifier
 */
var Group = function (id) {
  this.members = [];
  this.temporaryMembers = [];
  this.id = id || "gr_" + Math.random().toString(36).substr(2, 5);
};

/**
 * Gets the unique identifier.
 *
 * @return     {<type>}  The unique identifier.
 */
Group.prototype.getUniqueId = function () {
  return this.id;
};

Group.prototype.hasAnyMember = function () {
  if (this.members.length >= 1 || this.temporaryMembers.length >= 1)
    return true;
  return false;
};

/**
 * Adds a member.
 *
 * @param      {<type>}  obj     The object
 * @return     {<type>}  { description_of_the_return_value }
 */
Group.prototype.addMember = function (obj) {
  try {
    let sds = obj.getSnaptrudeDS();
    sds.isPartOfGroup = true;
    sds.groupId = this.id;
    if (sds) {
      this.members.push(sds);
    } else {
      this.members.push(obj);
    }
  } catch (e) {
    this.members.push(obj);
  }
};

/**
 * Adds a temporary member.
 *
 * @param      {<type>}  obj     The object
 * @return     {<type>}  { description_of_the_return_value }
 */
Group.prototype.addTemporaryMember = function (obj) {
  try {
    let sds = obj.getSnaptrudeDS();
    sds.isPartOfTempGroup = true;
    sds.tempGroupId = this.id;
    if (sds) {
      this.temporaryMembers.push(sds);
    } else {
      this.temporaryMembers.push(obj);
    }
  } catch (e) {
    this.temporaryMembers.push(obj);
  }
};

/**
 * Removes a member.
 *
 * @param      {<type>}  uniqueId  The unique identifier
 */
Group.prototype.removeMember = function (uniqueId) {
  let isDeleted = false;
  for (let i = 0; i < this.members.length; i++) {
    let curr_mesh = this.members[i];
    if (curr_mesh.uniqueId === uniqueId) {
      this.members.splice(i, 1);
      isDeleted = true;
      break;
    }
  }
  if (!isDeleted) {
    throw "member not exists";
  }
};

/**
 * Gets all members.
 *
 * @return     {<type>}  All members.
 */
Group.prototype.getAllMembers = function () {
  return this.members;
};

/**
 * Gets all members.
 *
 * @return     {<type>}  All members.
 */
Group.prototype.getAllTempMembers = function () {
  return this.temporaryMembers;
};

/**
 * { function_description }
 *
 * @class      MeshGroups (name)
 * @return     {(Group|Object)}  { description_of_the_return_value }
 */
var MeshGroups = (function () {
  "use strict";
  var groups = {};

  return {
    /**
     * Creates a new group.
     *
     * @param      {<type>}  id      The identifier
     * @return     {Group}   { description_of_the_return_value }
     */
    createNewGroup: function (id) {
      var group = new Group(id);
      if (id) {
        if (group.hasOwnProperty(id)) delete groups[id];
        groups[id] = group;
      } else {
        groups[group.getUniqueId()] = group;
      }
      return group;
    },
    /**
     * { function_description }
     *
     * @param      {<type>}  group_id  The group identifier
     */
    deleteGroup: function (group_id) {
      if (groups.hasOwnProperty(group_id)) delete groups[group_id];
      else {
        throw "Group not present";
      }
    },
    /**
     * Gets all groups.
     *
     * @return     {<type>}  All groups.
     */
    getAllGroups: function () {
      return groups;
    },
    /**
     * Gets the group by unique identifier.
     *
     * @param      {<type>}  id      The identifier
     * @return     {<type>}  The group by unique identifier.
     */
    getGroupByUniqueId: function (id) {
      if (!groups.hasOwnProperty(id)) {
        throw "key not found";
      } else {
        return groups[id];
      }
    },
  };
})();
export { GLOBAL_CONSTANTS_FUNCTIONS,Group,MeshGroups };
