import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import * as styles from "./styles";

import eye from "../../assets/images/eye.svg";
import eyeLine from "../../assets/images/eyeLine.svg";
import { VALIDATION_REGEXES, INAVLID_VALUE } from "./constants";
import _ from 'lodash';

const StyledInput = styled.input`
  border-radius: 0.3125rem;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primeBlack};
  outline: 0;
  border: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  letter-spacing: -0.021rem;
  flex: 1;
  height: 2.5rem;
  ::placeholder {
    font-family: ${(props) => props.theme.font};
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : props.theme.fontSizes.tiny};
    font-weight: 500;
    opacity: 0.5;
  }
  .edit-btn {
    font-weight: bold;
  }
`;

export const InputLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSizes.veryTiny};
  color: ${(props) => props.theme.colors.greyishBlack};
  font-weight: 500;
  line-height: 1.813rem;
  // padding-bottom: 0.5rem;
  letter-spacing: -0.0208rem;
  margin: 0;
  width: ${({ width }) => width};
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
`;

const InputCover = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0.3125rem;
  ${({ error }) => {
    if (error) {
      return css`
        outline: 0.0625rem solid ${(props) => props.theme.colors.orangeYellow};
      `;
    } else {
      return css`
        &:focus-within,
        &:hover {
          outline: 0.0625rem solid ${(props) => props.theme.colors.lightGrey};
        }
      `;
    }
  }}
`;

const InputContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const CustomElement = styled.div`
  position: absolute;
`;

const CustomInnerButton = styled.div`
  outline: 0;
  border: none;
  background: ${(props) => props.theme.colors.transparent};
  font-size: ${(props) => props.theme.fontSizes.veryTiny};
  font-family: ${(props) => props.theme.font};
  /* line-height: 1.813rem; */
  letter-spacing: -0.0208rem;
  color: ${(props) => props.theme.colors.secondaryGrey};
  margin-right: 10px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 0.625rem;
  position: relative;
  bottom: 0;
  color: ${(props) => props.theme.colors.orangeYellow};
  padding: 0 0 0.25rem 1.25rem;
`;

const Input = ({
  label,
  type,
  placeholder,
  editable,
  customInputContainerStyle,
  value,
  customInput,
  disableSee,
  validator,
  onPress,
  onBlur,
  keyPress,
  customOnChange,
  errorMessage,
  maxLength,
  setError,
  error,
  min,
  max,
  labelWidth,
  labelStyle,
  fontSize,
  isInputDisabled,
  ...rest
}) => {
  const [toggle, setToggle] = useState(true);
  const [isEditable, setIsEditable] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!isEditable) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  const defaultOnBlur = (evt) => {
    const regex = validator || VALIDATION_REGEXES[type];
    if (regex && setError) {
      if (!regex.test(value)) {
        setError(true);
      } else {
        setError(false);
      }
    }
    if(onBlur) onBlur(evt);
  };
  
  const onChange = (e) => {
    if (!_.isFunction(onPress)) return;
    if (setError) setError(false);
    onPress(e);
  };

  const onKeyPress = (e) => {
    if (!_.isFunction(keyPress)) return;
    keyPress(e);
  }

  const PASSWORD = "password",
    TEXT = "text";

  return (
    <InputContentContainer style={customInputContainerStyle}>
      {label && (
        <InputLabel
          style={labelStyle}
          width={labelWidth ? `${labelWidth}` : "auto"}
        >
          {label}
        </InputLabel>
      )}
      <InputCover error={error}>
        <InputContainer
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {type === PASSWORD && !disableSee ? (
            <>
              <StyledInput
                fontSize={fontSize}
                type={toggle ? PASSWORD : TEXT}
                value={value || ""}
                placeholder={placeholder}
                onChange={customOnChange ? customOnChange : onChange}
                onKeyDown={onKeyPress}
                {...rest}
                style={customInput}
                maxLength={maxLength}
                onBlur={defaultOnBlur}
                min={min}
                max={max}
                onDrag={(evt) => {evt.preventDefault()}}
                onDrop={(evt) => {evt.preventDefault()}}
              />
              <CustomElement>
                <CustomInnerButton onClick={(evt) => {evt.preventDefault();setToggle(!toggle);}}>
                  {toggle ? (
                    <img alt="eyeLine" src={eyeLine} style={styles.imgStyles} />
                  ) : null}
                  <img alt="eye" src={eye} />
                </CustomInnerButton>
              </CustomElement>
            </>
          ) : editable ? (
            <>
              <StyledInput
                fontSize={fontSize}
                type={type}
                placeholder={placeholder}
                disabled={isEditable}
                value={value || ""}
                ref={inputRef}
                onKeyDown={onKeyPress}
                onChange={customOnChange ? customOnChange : onChange}
                {...rest}
                style={customInput}
                maxLength={maxLength}
                onBlur={defaultOnBlur}
                min={min}
                max={max}
                onDrag={(evt) => {evt.preventDefault()}}
                onDrop={(evt) => {evt.preventDefault()}}

              />
              <CustomElement>
                <CustomInnerButton onClick={() => setIsEditable(!isEditable)}>
                  {isEditable && <span style={styles.editButton}>Edit</span>}
                </CustomInnerButton>
              </CustomElement>
            </>
          ) : (
            <StyledInput
              fontSize={fontSize}
              type={type}
              placeholder={placeholder}
              value={value || ""}
              disabled={isInputDisabled}
              ref={inputRef}
              onKeyDown={onKeyPress}
              onChange={customOnChange ? customOnChange : onChange}
              style={customInput}
              maxLength={maxLength}
              onBlur={defaultOnBlur}
              min={min}
              max={max}
              onDrag={(evt) => {evt.preventDefault()}}
              onDrop={(evt) => {evt.preventDefault()}}
              {...rest}
            />
          )}
        </InputContainer>
        {error ? (
          <ErrorMessage>{errorMessage || INAVLID_VALUE}</ErrorMessage>
        ) : null}
      </InputCover>
    </InputContentContainer>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  editable: PropTypes.bool,
  value: PropTypes.string,
  onPress: PropTypes.func,
  validator: PropTypes.instanceOf(RegExp),
  errorMessage: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  error: PropTypes.bool,
  setError: PropTypes.func,
  maxLength: PropTypes.number,
  disableSee: PropTypes.bool,
  customInput: PropTypes.instanceOf(Object),
  customInputContainerStyle: PropTypes.instanceOf(Object),
};

export default Input;
