var massProperties = {
  _name: "Mass",
  _type: "Mass",
  _id: "Room",
  _level: "0",
  _height: "0",
  _indices: [],
  _properties: {},
  _curve: "false",
  _void: "false",
  _component: {},
};

var wallProperties = {
  _name: "Wall",
  _type: "Wall",
  _id: "Room",
  _level: "0",
  _height: "0",
  _rooms: [],
  _indices: [],
  _thickness: "",
  _layers: [],
  _relationships: {
    _partner1: "",
    _partner2: "",
    _children: [],
  },
  _properties: {},
  _component: {},
};

var floorProperties = {
  _name: "Floor",
  _type: "Floor",
  _id: "Room",
  _level: "0",
  _thickness: "0",
  _rooms: [],
  _walls: [],
  _indices: [],
  _relationships: {
    _partner1: "",
    _partner2: "",
    _children: [],
  },
  _properties: {},
  _component: {},
};

var roofProperties = {
  _name: "Roof",
  _type: "Roof",
  _id: "Room",
  _level: "0",
  _thickness: "0",
  _rooms: [],
  _walls: [],
  _indices: [],
  _relationships: {
    _partner1: "",
    _partner2: "",
    _children: [],
  },
  _properties: {},
  _component: {},
};

var stairProperties = {
  _name: "Staircase",
  _type: "Staircase",
  _id: "Staircase",
  _level: "0",
  _thickness: "0",
  _properties: {},
  _component: {},
};

var ceilingProperties = {
  _name: "Roof",
  _type: "Roof",
  _id: "Room",
  _level: "0",
  _thickness: "0",
  _rooms: [],
  _walls: [],
  _indices: [],
  _relationships: {
    _partner1: "",
    _partner2: "",
    _children: [],
  },
  _properties: {},
  _component: {},
};

var doorProperties = {
  _name: "Door",
  _type: "Door",
  _id: "Room",
  _lintel: "36",
  _level: "0",
  _indices: [],
  _fileURL: "",
  _parent: "",
  _properties: {},
};

var windowProperties = {
  _name: "Window",
  _type: "Window",
  _id: "Room",
  _lintel: "36",
  _plinth: "36",
  _level: "0",
  _indices: [],
  _fileURL: "",
  _parent: "",
  _properties: {},
  _component: {},
};

var familyProperties = {
  _name: "Furniture",
  _type: "Furniture",
  _id: "Room",
  _level: "0",
  _indices: [],
  _fileURL: "",
  _parent: "",
  _properties: {},
  _component: {},
};

export {
  massProperties,
  wallProperties,
  floorProperties,
  roofProperties,
  stairProperties,
  ceilingProperties,
  doorProperties,
  windowProperties,
  familyProperties,
};
