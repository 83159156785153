"use strict";
import BABYLON from "../babylonDS.module.js";
import graphlib from "graphlib";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { makeid } from "../../libs/arrayFuncs.js";
import {
  isMeshThrowAway,
  areThreeVectorsCollinear,
  isRoomOfType,
  getRoomTypeProperties,
  getDistanceBetweenVectors,
} from "../extrafunc.js";
import {
  getTopFaceVertices,
  getBottomFaceVertices,
  getVerticesFromEdgeObject,
  sanitizeVertices,
} from "../../libs/brepOperations.js";
import { geometryUpdater } from "./geometryUpdater.js";
import { isFloatEqual, getAngleBetweenVectors } from "../../libs/snapFuncs.js";
import { areaOfPolygon3D, areaOfPolygonV3 } from "../../libs/areaFuncs.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { isPointInsidePolygon } from "../../libs/twoD/twoSnap.js";
import { ResolveEngineUtils } from "../wallEngine/resolveEngine.js";
import { dimensionsTuner } from "./dimensionsTuner.js";
import { massDissector } from "../createBuilding/massDissector.js";
import { meshObjectMapping } from "../snaptrudeDS/mapping.js";
import { average } from "../../libs/mathFuncs.js";
import {computeWorldMatrix} from "../meshoperations/moveOperations/moveUtil";

const virtualSketcher = (function () {
  const _newGraph = function () {
    return new graphlib.Graph({ directed: false });
  };

  let adjacencyGraph = _newGraph();
  let uniqueIdVerticesMapping = {};

  // TODO - Make this a Map object
  let structuralAdjacencyGraph = _newGraph();
  let structuralUniqueIdEdgesMapping = {};

  let _idPrefix = makeid(2);
  let _id = 0;
  const _usedPrefixes = [_idPrefix];

  const ROUND_OFF_THRESHOLD = 2;
  const PROXIMITY_CHECK_THRESHOLD = 2 * 1e-3;

  // Values used in graphlib.js
  const DEFAULT_EDGE_NAME = "\x00";
  const EDGE_KEY_DELIM = "\x01";
  
  const PLACEHOLDER_COMPONENT = {};

  const _format = function (v) {
    const xDash = _.round(v.x, ROUND_OFF_THRESHOLD);
    const yDash = _.round(v.y, ROUND_OFF_THRESHOLD);
    const zDash = _.round(v.z, ROUND_OFF_THRESHOLD);

    return JSON.stringify([xDash, yDash, zDash]);
  };

  const _filterComponents = function (masses) {
    const componentsToRemove = [];
    for (let mass of masses) {
      if (!shouldAddComponentToGraph(mass)) componentsToRemove.push(mass);
    }

    return _.without(masses, ...componentsToRemove);
  };

  const shouldAddComponentToGraph = function (component) {
    if (!component) return false;
    if (!component.brep) return false;

    if (component.mesh.type.toLowerCase().includes("mass")) {
      if (!["Room"].includes(component.massType)) return false;
      // add only rooms
    }
    else if (["roof"].includes(component.mesh.type.toLowerCase())) {
      // add roofs
    } else {
      return false;
    }

    if (isComponentPlanar(component)) return false;
    if (isMeshThrowAway(component.mesh)) return false;

    return true;
  };

  const _getId = function () {
    if (_id > 1e5) {
      do {
        _idPrefix = makeid(2);
      } while (_usedPrefixes.includes(_idPrefix));
      _usedPrefixes.push(_idPrefix);
      _id = 0;
    }
    _id++;
    return _idPrefix + _id;
  };

  const _getIdFromNodes = function (node1, node2) {
    /*
        As spotted in graphlib.js
         */
    let v = "" + node1;
    let w = "" + node2;
    if (v > w) {
      let tmp = v;
      v = w;
      w = tmp;
    }

    return v + EDGE_KEY_DELIM + w + EDGE_KEY_DELIM + DEFAULT_EDGE_NAME;
  };

  const _getEdgeLabel = function (node1, node2, graph) {
    const edgeId = _getIdFromNodes(node1, node2);
    return graph._edgeLabels[edgeId];
  };

  const _incrementEdgeLabel = function (node1, node2, component, graph) {
    const edgeId = _getIdFromNodes(node1, node2);
    graph._edgeLabels[edgeId].weight++;
    graph._edgeLabels[edgeId].components.push(component);
  };

  const _decrementEdgeLabel = function (node1, node2, component, graph) {
    const edgeId = _getIdFromNodes(node1, node2);
    graph._edgeLabels[edgeId].weight--;
    _.remove(graph._edgeLabels[edgeId].components, component);
  };

  /**
   * Vector stores the value of the vector used when the node is initially created
   * If a new vertex close by is added, its precise value is stored in preciseVectors[], vector won't reflect its value exactly.
   *
   *
   * @returns {{components: [], preciseVectors: [], vector: null}}
   * @private
   */
  const _getDefaultNodeLabel = function () {
    return {
      vector: null,
      components: [],
      preciseVectors: [],
      id: "n-" + _getId(),
    };
  };

  const _getDefaultEdgeLabel = function (graph, node1, node2) {
    return {
      weight: 1,
      components: [],
      contains: (checkVector, evenIfOnVertex) =>
        _doesEdgeContainVector(graph, node1, checkVector, node2, evenIfOnVertex),
      contains2D: (checkVector) =>
        _doesEdgeContainVector2D(graph, node1, checkVector, node2),
      getNodeVectors: (component) =>
        _getPreciseVectorsFromEdgeLabel(graph, node1, node2, component),
      id: "e-" + _getId(),
    };
  };

  const _doesEdgeContainVectorV3Inputs = function (
    vector1,
    vector2,
    vector3,
    strictCheck = false,
    evenIfOnVertex
  ) {
    if (strictCheck) {
      // for geometry sanitization
      return areThreeVectorsCollinear(
        vector1,
        vector2,
        vector3,
        PROXIMITY_CHECK_THRESHOLD
      );
    } else {
      // let resolveEngineUtils = new ResolveEngineUtils();
      return store.resolveEngineUtils.onSegment3D(
        vector1,
        vector2,
        vector3,
        PROXIMITY_CHECK_THRESHOLD,
        !evenIfOnVertex
      );
    }
  };

  const _doesEdgeContainVectorV3Inputs2D = function (
    vector1,
    vector2,
    vector3
  ) {
    if (vector1.almostEquals(vector2) || vector3.almostEquals(vector2))
      return false;
    else
      return store.resolveEngineUtils.onSegment2D(
        vector1,
        vector2,
        vector3,
        PROXIMITY_CHECK_THRESHOLD
      );
  };

  const _doesEdgeContainVector = function (graph, node1, checkVector, node2, evenIfOnVertex) {
    const vertex1 = graph.node(node1).vector;
    const vertex2 = graph.node(node2).vector;

    return _doesEdgeContainVectorV3Inputs(vertex1, checkVector, vertex2, false, evenIfOnVertex);
  };

  const _doesEdgeContainVector2D = function (graph, node1, checkVector, node2) {
    const vertex1 = graph.node(node1).vector;
    const vertex2 = graph.node(node2).vector;

    return _doesEdgeContainVectorV3Inputs2D(vertex1, checkVector, vertex2);
  };

  const _getPreciseVectorsFromEdgeLabel = function (
    graph,
    node1,
    node2,
    component
  ) {
    const nodeLabel1 = graph.node(node1);
    const nodeLabel2 = graph.node(node2);

    const componentPosition1 = _.findIndex(nodeLabel1.components, component);
    const componentPosition2 = _.findIndex(nodeLabel2.components, component);

    return [
      nodeLabel1.preciseVectors[componentPosition1],
      nodeLabel2.preciseVectors[componentPosition2],
    ];
  };

  /**
   * Return bottom face vertices for regular masses and top for masses like water bodies
   * Should make changes based on vertical position and not massType, when requirement arises
   *
   * @param mass
   * @private
   */
  const getAppropriateVerticesToAdd = function (mass) {
    computeWorldMatrix(mass.mesh);

    if (doesComponentGrowDownwards(mass)) {
      return getTopFaceVertices(mass, BABYLON.Space.WORLD);
    } else {
      return getBottomFaceVertices(mass, BABYLON.Space.WORLD);
    }
  };

  const doesComponentGrowDownwards = function (c) {
    return (c.type.toLowerCase() === 'mass' &&
            (isRoomOfType(c.mesh, 'water body')
              || isRoomOfType(c.mesh, 'pool')
              || c.massType === "Beam"
            ))
            || (c.type.toLowerCase() === 'roof');
  };

  const isComponentPlanar = function (c) {
    return c.type.toLowerCase() === "mass" && isRoomOfType(c.mesh, "site");
  };

  const isComponentPresentInGraph = function (component){
    return !!uniqueIdVerticesMapping[component.mesh.uniqueId];
  };

  const isComponentNotOfFullHeight = function (c) {
    return c.type.toLowerCase() === "mass" && isRoomOfType(c.mesh, "balcony");
  };

  const _add = function (masses, updateGeometry, forced = false) {
    if (!_.isArray(masses)) {
      masses = [masses];
    }

    if (!forced) masses = _filterComponents(masses);
    if (_.isEmpty(masses)) return;

    masses.forEach((mass) => {
      if (uniqueIdVerticesMapping[mass.mesh.uniqueId]) return;
      if (isMeshThrowAway(mass.mesh)) return;

      const faceVertices = getAppropriateVerticesToAdd(mass);
      _addVertices(faceVertices, mass, updateGeometry);
    });

    if (updateGeometry) {
      dimensionsTuner.add(masses);
      return geometryUpdater.yieldResult();
    }
  };

  const _addEdge = function (
    graph,
    mass,
    { vertex, vertexString, nextVertex, nextVertexString }
  ) {
    const edgeLabel = graph.edge(vertexString, nextVertexString);

    const vertexNodeLabel = graph.node(vertexString);
    const nextVertexNodeLabel = graph.node(nextVertexString);

    if (vertexNodeLabel && !vertexNodeLabel.components.includes(mass)) {
      vertexNodeLabel.components.push(mass);
      vertexNodeLabel.preciseVectors.push(vertex);
    }
    if (nextVertexNodeLabel && !nextVertexNodeLabel.components.includes(mass)) {
      nextVertexNodeLabel.components.push(mass);
      nextVertexNodeLabel.preciseVectors.push(nextVertex);
    }

    if (edgeLabel) {
      _incrementEdgeLabel(vertexString, nextVertexString, mass, graph);
    } else {
      if (!vertexNodeLabel) {
        const label = _getDefaultNodeLabel();
        label.vector = vertex;
        label.components.push(mass);
        label.preciseVectors.push(vertex);

        graph.setNode(vertexString, label);
      }

      if (!nextVertexNodeLabel) {
        const label = _getDefaultNodeLabel();
        label.vector = nextVertex;
        label.components.push(mass);
        label.preciseVectors.push(nextVertex);

        graph.setNode(nextVertexString, label);
      }

      const edgeLabel = _getDefaultEdgeLabel(
        graph,
        vertexString,
        nextVertexString
      );
      edgeLabel.components.push(mass);
      graph.setEdge(vertexString, nextVertexString, edgeLabel);
    }

    // Kuladeep Incomplete Changes
    // return edgeLabel;
  };

  const _addVertices = function (bottomFaceVertices, mass, updateGeometry) {
    if (uniqueIdVerticesMapping[mass.mesh.uniqueId]) return;
    if (isMeshThrowAway(mass.mesh)) return;

    const bottomFaceVerticesStrings = bottomFaceVertices.map(_format);

    const length = bottomFaceVerticesStrings.length;
    bottomFaceVerticesStrings.forEach((vertexString, i) => {
      const nextIndex = i === length - 1 ? 0 : i + 1;
      const nextVertexString = bottomFaceVerticesStrings[nextIndex];

      _addEdge(adjacencyGraph, mass, {
        vertex: bottomFaceVertices[i],
        vertexString,
        nextVertex: bottomFaceVertices[nextIndex],
        nextVertexString,
      });
    });

    uniqueIdVerticesMapping[mass.mesh.uniqueId] = bottomFaceVertices;

    if (updateGeometry) geometryUpdater.add(mass);

    const allEdgesOfMass = [];
    mass.brep.getEdges().forEach((edge) => {
      const edgeVertices = getVerticesFromEdgeObject(
        mass,
        edge,
        BABYLON.Space.WORLD
      );
      const edgeVerticesStrings = edgeVertices.map(_format);

      _addEdge(structuralAdjacencyGraph, mass, {
        vertex: edgeVertices[0],
        vertexString: edgeVerticesStrings[0],
        nextVertex: edgeVertices[1],
        nextVertexString: edgeVerticesStrings[1],
      });

      allEdgesOfMass.push(edgeVertices);
    });

    structuralUniqueIdEdgesMapping[mass.mesh.uniqueId] = allEdgesOfMass;
  };

  const addWithGeometryEdit = function (components) {
    return _add(components, true);
  };

  const addWithoutGeometryEdit = function (components, forced) {
    _add(components, false, forced);
  };

  const _isNodeRedundant = function (graph, checkForNode, neighbours) {
    const checkVector = graph.node(checkForNode).vector;
    return _doesEdgeContainVector(
      graph,
      neighbours[0],
      checkVector,
      neighbours[1]
    );
  };

  const _removeEdge = function (vertexString, nextVertexString, graph, mass, updateGeometry) {
    const edgeLabel = graph.edge(vertexString, nextVertexString);
    if (edgeLabel.weight === 1) {
      graph.removeEdge(vertexString, nextVertexString);
    } else if (edgeLabel.weight > 1) {
      if (updateGeometry){
        const distanceBetweenVertices = getDistanceBetweenVectors(...edgeLabel.getNodeVectors(mass));
        if (distanceBetweenVertices > massDissector.getInternalWallThickness()){
            const otherComponentsLinkedToEdge = edgeLabel.components.filter(c => c !== mass);
            dimensionsTuner.add(otherComponentsLinkedToEdge);
        }
        else {
            // these are the thin edges formed at junctions, no need to remove them separately
        }

      }
      
      _decrementEdgeLabel(vertexString, nextVertexString, mass, graph);
    }
  };

  const _removeLonelyNodes = function (nodes, graph, mass, updateGeometry) {
    nodes.forEach((vertexString) => {
      const vertexNeighbours = graph.neighbors(vertexString);
      if (_.isEmpty(vertexNeighbours)) graph.removeNode(vertexString);
      else {
        const nodeLabel = graph.node(vertexString);
        const componentPosition = _.findIndex(nodeLabel.components, c => c === mass);

        nodeLabel.components.splice(componentPosition, 1);
        nodeLabel.preciseVectors.splice(componentPosition, 1);

        if (
          updateGeometry &&
          vertexNeighbours.length === 2 &&
          _isNodeRedundant(graph, vertexString, vertexNeighbours)
        ) {
          geometryUpdater.remove(vertexString);
        }
      }
    });
  };

  const _removeVertices = function (mass, updateGeometry) {
    const bottomFaceVertices = uniqueIdVerticesMapping[mass.mesh.uniqueId];
    if (!bottomFaceVertices) return;

    const bottomFaceVerticesStrings = bottomFaceVertices.map(_format);

    const length = bottomFaceVerticesStrings.length;
    bottomFaceVerticesStrings.forEach((vertexString, i) => {
      const nextIndex = i === length - 1 ? 0 : i + 1;
      const nextVertexString = bottomFaceVerticesStrings[nextIndex];

      _removeEdge(vertexString, nextVertexString, adjacencyGraph, mass, updateGeometry);
    });

    _removeLonelyNodes(
      bottomFaceVerticesStrings,
      adjacencyGraph,
      mass,
      updateGeometry
    );

    const allEdges = structuralUniqueIdEdgesMapping[mass.mesh.uniqueId];

    if (allEdges) {
      const allVerticesOfMass = [];
      allEdges.forEach((edgeVertices) => {
        const vertexStrings = edgeVertices.map(_format);
        _removeEdge(
          vertexStrings[0],
          vertexStrings[1],
          structuralAdjacencyGraph,
          mass
        );

        allVerticesOfMass.pushIfNotExist(edgeVertices[0], (e) =>
          e.almostEquals(edgeVertices[0])
        );
        allVerticesOfMass.pushIfNotExist(edgeVertices[1], (e) =>
          e.almostEquals(edgeVertices[1])
        );
      });

      _removeLonelyNodes(
        allVerticesOfMass.map(_format),
        structuralAdjacencyGraph,
        mass,
        false
      );
    }

    if (updateGeometry) {
      // this is to remove the excess vertices of the mass itself being moved

      // will do geometry sanitization here itself
      // example - move edge done such that two edges coincide

      const _analyzeVertices = function (newVertices) {
        const length = newVertices.length;
        newVertices.some((vertex, i) => {
          const nextIndex = i === length - 1 ? 0 : i + 1;
          const previousIndex = i === 0 ? length - 1 : i - 1;

          const nextVertex = newVertices[nextIndex];
          const previousVertex = newVertices[previousIndex];

          if (
            _doesEdgeContainVectorV3Inputs(
              previousVertex,
              vertex,
              nextVertex,
              true
            )
          ) {
            // this means the vertices are collinear in some way
            // so consider for removal

            // forcedRemoval will be false is vertex is between previousVertex and nextVertex
            // this is not a case of geometry weirdness but just a collinear vertex
            // so its removal depends on the type - if it's user added or system generated

            const regularCollinear = _doesEdgeContainVectorV3Inputs(
              previousVertex,
              vertex,
              nextVertex
            );
            const forcedRemoval = !regularCollinear;

            vertex.forcedRemoval = forcedRemoval;

            geometryUpdater.remove(null, {
              vertexToRemove: vertex,
              component: mass,
              removalForGeometrySanitization: true,
            });

            _.remove(newVertices, vertex);
            _analyzeVertices(newVertices);

            return true;
          }
        });
      };

      _analyzeVertices(getAppropriateVerticesToAdd(mass));
    }

    delete uniqueIdVerticesMapping[mass.mesh.uniqueId];
    delete structuralUniqueIdEdgesMapping[mass.mesh.uniqueId];
  };

  const _remove = function (masses, updateGeometry) {
    let excludeComponentsForGeometryEdit = false;
    if (_.isArray(masses)) {
      /*
            When multiple masses are being deleted, parametric edits shouldn't be done on them
             */
      if (updateGeometry) excludeComponentsForGeometryEdit = true;
    } else {
      masses = [masses];
    }

    // masses = _filterComponents(masses);
    if (_.isEmpty(masses)) return;

    if (excludeComponentsForGeometryEdit)
      geometryUpdater.excludeComponents(masses);

    masses.forEach((mass) => {
      _removeVertices(mass, updateGeometry);
    });

    if (updateGeometry) return geometryUpdater.yieldResult();
  };

  const removeWithoutGeometryEdit = function (components) {
    _remove(components, false);
  };

  const removeWithGeometryEdit = function (components) {
    return _remove(components, true);
  };

  const updateWithGeometryEdit = function (components, allInstances = false) {
    return _update(components, allInstances, true);
  };

  const updateWithoutGeometryEdit = function (
    components,
    allInstances = false
  ) {
    _update(components, allInstances, false);
  };

  const _update = function (masses, allInstances, updateGeometry) {
    let excludeComponentsForGeometryEdit = false;
    if (_.isArray(masses)) {
      /*
            When simultaneous vertex/edge edit is happening, disabling automatic edits on those components
             */
      if (updateGeometry && allInstances)
        excludeComponentsForGeometryEdit = true;
    } else {
      masses = [masses];
    }

    masses = _filterComponents(masses);
    if (_.isEmpty(masses)) return;

    if (updateGeometry) dimensionsTuner.add(masses);
    if (excludeComponentsForGeometryEdit)
      geometryUpdater.excludeComponents(masses);

    let components = [];
    masses.forEach((mass) => {
      if (mass.mesh.isAnInstance && allInstances) {
        // because if an instance has been moved, no need to update all of them
        const sourceMesh = mass.mesh.sourceMesh;
        components.push(...sourceMesh.instances.map((i) => i.getSnaptrudeDS()));
      } else {
        components.push(mass);
      }
    });

    components.forEach((mass) => {
      _removeVertices(mass, updateGeometry);
    });

    if (updateGeometry) geometryUpdater.performRemoval();

    components.forEach((mass) => {
      const faceVertices = getAppropriateVerticesToAdd(mass);
      _addVertices(faceVertices, mass, updateGeometry);
    });

    if (updateGeometry) {
      geometryUpdater.performAddition();
      return geometryUpdater.yieldResult();
    }
  };

  /**
   *
   * Returns path [sourceV3, ... , destinationV3]
   *
   * https://imgur.com/a/fNqH105
   *
   * Use the image to understand the algo
   * If orange node is the source and blue node is the destination,
   * the two orange paths and two blue paths are the external paths.
   *
   * Tens of internal paths are avoided by assigning the internal edges higher wights in the weight function
   * used in dijkstra's algorithm.
   *
   * But these 4 paths cannot be filtered with edge weights, they can only be filtered post dijkstra by checking
   * if any nodes lie within the path, i.e intersecting with current masses.
   *
   * So, dijkstra has to be run 4 times (or more if source/destination nodes have more neighbours)
   * Each time, one of the blue and orange edges are made high weight so that they're discarded
   * and the other path comes out nicely
   *
   *
   * @param sourceV3
   * @param destinationV3
   * @param outerPath
   * @returns {*}
   */
  const findPath = function (sourceV3, destinationV3, outerPath) {
    const _edgeFunction = function (node) {
      return [
        ...adjacencyGraph.outEdges(node),
        ...adjacencyGraph.inEdges(node),
      ];
    };

    const _weightFunction = function (edge) {
      /*
            Multiple paths are possible between two nodes. This function assigns weight to edges so that
            internal paths and outer boundary paths become high cost
             */

      const _isEdgeHeavyWeightForThisIteration = function (edge) {
        return (
          (edge.v === destination &&
            edge.w === irrelevantDestinationNeighbor) ||
          (edge.v === irrelevantDestinationNeighbor &&
            edge.w === destination) ||
          (edge.v === source && edge.w === irrelevantSourceNeighbor) ||
          (edge.v === irrelevantSourceNeighbor && edge.w === source)
        );
      };

      const normalWeight = 1;
      const heavyWeight = 100;

      let weight = normalWeight;

      if (_isEdgeHeavyWeightForThisIteration(edge)) {
        weight = heavyWeight;
      } else {
        const edgeLabel = _getEdgeLabel(edge.v, edge.w, adjacencyGraph);
        const componentsAbove = edgeLabel.components.filter(
          (c) => c.type.toLowerCase() !== "roof"
        );
        if (componentsAbove.length > 1) weight = heavyWeight;

        // Changed filter to exclude only roofs for this case
        // https://imgur.com/a/mkm2UyT

        // edge that has two components above storey base, so cannot be used for autocomplete
        // if >1 slabs are present, doesn't matter
      }

      return weight;
    };

    const source = _format(sourceV3);
    const destination = _format(destinationV3);

    if (!adjacencyGraph.hasNode(source)) return;
    if (!adjacencyGraph.hasNode(destination)) return;

    const destinationNeighbours = adjacencyGraph.neighbors(destination);
    const sourceNeighbours = adjacencyGraph.neighbors(source);

    // keep only those neighbours who are connected by a unit weighted edge
    // other neighbours can never result in a non-overlapping path

    _.remove(destinationNeighbours, (neighbour) => {
      const edgeLabel = _getEdgeLabel(neighbour, destination, adjacencyGraph);
      const componentsAbove = edgeLabel.components.filter(
        (c) => !doesComponentGrowDownwards(c)
      );
      return componentsAbove.length > 1;
    });

    _.remove(sourceNeighbours, (neighbour) => {
      const edgeLabel = _getEdgeLabel(neighbour, source, adjacencyGraph);
      const componentsAbove = edgeLabel.components.filter(
        (c) => !doesComponentGrowDownwards(c)
      );
      return componentsAbove.length > 1;
    });

    let irrelevantDestinationNeighbor, irrelevantSourceNeighbor;

    const possiblePaths = [];

    destinationNeighbours.forEach((destinationNeighbour) => {
      irrelevantDestinationNeighbor = destinationNeighbour;

      sourceNeighbours.forEach((sourceNeighbour) => {
        irrelevantSourceNeighbor = sourceNeighbour;

        const path = [];
        const nodeDistanceMapping = graphlib.alg.dijkstra(
          adjacencyGraph,
          source,
          _weightFunction,
          _edgeFunction
        );

        // backtrack from the destination
        let lookupKey = destination;
        while (lookupKey !== source) {
          path.push(adjacencyGraph.node(lookupKey).vector.clone());
          const data = nodeDistanceMapping[lookupKey];
          if (data.distance === Infinity) return null;
          lookupKey = data.predecessor;
        }

        path.push(sourceV3.clone());
        path.reverse();

        possiblePaths.push(path);
      });
    });

    const pathAreas = [];
    const possiblePathsWithoutOverlap = _.compact(
      possiblePaths.map((path) => {
        let closedPath = [...outerPath, ...path];
        if (closedPath.length < 3) return null;

        const length = closedPath.length;
        closedPath.forEach((vertex, i) => {
          const nextIndex = i === length - 1 ? 0 : i + 1;
          const previousIndex = i === 0 ? length - 1 : i - 1;

          const nextVertex = closedPath[nextIndex];
          const previousVertex = closedPath[previousIndex];

          const v1 = nextVertex.subtract(vertex).normalize();
          const v2 = previousVertex.subtract(vertex).normalize();

          const angleThreshold = 5;
          if (isFloatEqual(getAngleBetweenVectors(v1, v2), 0, angleThreshold)) {
            _.remove(path, (v) => v.almostEquals(vertex));
          }
        });

        const nodeInsidePath = doesANodeExistWithinLoop(closedPath);

        if (nodeInsidePath) {
          return null;
        } else {
          const sanitizedClosedPath = sanitizeVertices(closedPath);
                // sanitizing won't modify the area

          const area = areaOfPolygonV3(sanitizedClosedPath);

          if (area > 0.2) {
            pathAreas.push(area);
            return path;
          }
        }
      })
    );

    if (_.isEmpty(possiblePathsWithoutOverlap)) {
      console.log("No path found without overlaps");
      return;
    }

    let relevantPath;

    if (possiblePathsWithoutOverlap.length === 1) {
      relevantPath = possiblePathsWithoutOverlap[0];
    } else {
      const minArea = _.min(pathAreas);
      relevantPath = possiblePathsWithoutOverlap[pathAreas.indexOf(minArea)];
    }

    // return [... outerPath, ...relevantPath];
    return relevantPath;
  };
 
  const lookup = function (v) {
    v = _format(v);
    return adjacencyGraph.node(v);
  };

  const lookupEdge = function (v1, v2) {
    v1 = _format(v1);
    v2 = _format(v2);

    const edgeLabel = _getEdgeLabel(v1, v2, adjacencyGraph);

    if (edgeLabel) return edgeLabel;
    else return _getEdgeLabel(v2, v1, adjacencyGraph);
  };

  const structuralLookupEdge = function (v1, v2) {
    v1 = _format(v1);
    v2 = _format(v2);

    const edgeLabel = _getEdgeLabel(v1, v2, structuralAdjacencyGraph);

    if (edgeLabel) return edgeLabel;
    else return _getEdgeLabel(v2, v1, structuralAdjacencyGraph);
  };
  
  const lookupCoincidentEdge = function (v1, v2) {
    const actualEdge = lookupEdge(v1, v2);
    if (actualEdge) return actualEdge;
    
    const v1Label = lookup(v1);
    const v2Label = lookup(v2);
    
    if (v1Label && v2Label) return false;
    // because if both are vertices, then actualEdge should've been there
    
    let v1EdgeLabel, v2EdgeLabel;
    
    const options = {
      sendLabel: true
    };
    
    if (!v1Label) v1EdgeLabel = lookupOverEdge(v1, options);
    if (!v2Label) v2EdgeLabel = lookupOverEdge(v2, options);
    
    if (v1EdgeLabel && v1EdgeLabel.contains(v2, true)) {
      return v1EdgeLabel;
    }
    else if (v2EdgeLabel && v2EdgeLabel.contains(v1, true)) {
      return v2EdgeLabel;
    }
    
  };

  const lookupOverEdge = function (v, options = {}) {
    let edge, correspondingEdgeLabel;

    const graph = options.graph || adjacencyGraph;
    graph.edges().some((edgeObject) => {
      const edgeLabel = graph.edge(edgeObject);
      if (edgeLabel.contains(v)) {
        const v1 = edgeObject.v;
        const v2 = edgeObject.w;
        edge = {
          headPt: graph.node(v1).vector,
          tailPt: graph.node(v2).vector,
        };
        correspondingEdgeLabel = edgeLabel;

        return true;
      }
    });

    return options.sendLabel ? correspondingEdgeLabel : edge;
  };

  const structuralLookup = function (v) {
    v = _format(v);
    return structuralAdjacencyGraph.node(v);
  };

  /*
    Returns nodes sorted from top to bottom
     */
  const structuralFindNodesAlongEdge = function (headPt, tailPt, options = {}) {
    const nodes = [];
    _.forEach(structuralAdjacencyGraph._nodes, (nodeLabel, _) => {
      if (
        nodeLabel.vector.almostEquals(headPt) ||
        nodeLabel.vector.almostEquals(tailPt)
      ) {
        if (options.includeEdgePoints) nodes.push(nodeLabel);
        return;
      }

      if (
        _doesEdgeContainVectorV3Inputs(nodeLabel.vector, headPt, tailPt, true)
      ) {
        nodes.push(nodeLabel);
      }
    });

    return _.sortBy(nodes, [(n) => -n.vector.y]);
  };

  const getBottomAdjacencyGraph = function () {
    return adjacencyGraph;
  };

  const getStructuralAdjacencyGraph = function () {
    return structuralAdjacencyGraph;
  };

  const getGraphs = function () {
    return [adjacencyGraph, structuralAdjacencyGraph];
  };

  const getMappings = function () {
    return [uniqueIdVerticesMapping, structuralUniqueIdEdgesMapping];
  };

  const flush = function () {
    adjacencyGraph = _newGraph();
    structuralAdjacencyGraph = _newGraph();
    uniqueIdVerticesMapping = {};
    structuralUniqueIdEdgesMapping = {};
    _usedPrefixes.length = 0;
  };

  const getVertexLinkageData = function (
    vertexV3,
    associatedComponentVertices
  ) {
    const linkageData = {
      isConnectedToInternalWall: false,
      isConnectedToExternalWall: false,
      isConnectedToHalfHeightWall: false,
    };

    const v3Formatted = _format(vertexV3);
    const neighbours = adjacencyGraph.neighbors(v3Formatted);

    const neighbourVectors = neighbours.map(
      (n) => adjacencyGraph.node(n).vector
    );

    const neighboursNotInAssociatedVertices = _.differenceWith(
      neighbourVectors,
      associatedComponentVertices,
      (v1, v2) => {
        return v1.almostEquals(v2);
      }
    );

    if (neighboursNotInAssociatedVertices.length === 0) {
      // no mass attached to vertex
    } else {
      const neighbourVertexToConsider = neighboursNotInAssociatedVertices[0];
      const neighbourToConsiderFormatted = _format(neighbourVertexToConsider);

      const neighbourEdgeLabel = _getEdgeLabel(
        v3Formatted,
        neighbourToConsiderFormatted,
        adjacencyGraph
      );
      const edgeLabelComponentsSelectedForCreateBuildingAllHeight =
        _.intersection(
          neighbourEdgeLabel.components,
          createBuildingEngine.getConcernedMasses()
        );

      // remove components that go downwards or have no walls or not of full height
      _.remove(edgeLabelComponentsSelectedForCreateBuildingAllHeight, (c) => {
        let roomTypeProperties = getRoomTypeProperties(c.mesh.room_type);
        if (!roomTypeProperties) {
          roomTypeProperties = { mass: {}, bim: {} };
        }

        if (roomTypeProperties.bim.noWalls) return true;
        else {
          if (doesComponentGrowDownwards(c)) {
            return true;
          }
        }
      });

      const edgeLabelComponentsSelectedForCreateBuildingFullHeight =
        edgeLabelComponentsSelectedForCreateBuildingAllHeight.filter((c) => {
          const storey = StoreyMutation.getAParticularStorey(c.storey);
          const boundingBox = c.mesh.getBoundingInfo().boundingBox;
          const meshHeight =
            boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;

          if (meshHeight > 0.95 * storey.height) {
            return true;
          }
        });

      if (_.isEmpty(edgeLabelComponentsSelectedForCreateBuildingFullHeight)) {
        if (_.isEmpty(edgeLabelComponentsSelectedForCreateBuildingAllHeight)) {
          // no connection
        } else {
          linkageData.isConnectedToHalfHeightWall = true;
        }
      } else {
        linkageData.isConnectedToExternalWall =
          edgeLabelComponentsSelectedForCreateBuildingFullHeight.length === 1;
        linkageData.isConnectedToInternalWall =
          !linkageData.isConnectedToExternalWall;
      }
    }

    return linkageData;
  };

  const doesANodeExistWithinLoop = function (closedPath) {
    const nodeLabels = Object.values(adjacencyGraph._nodes);

    let nodeInsidePath = false;

    const threshold = 1e-2;
    const nodeLabelsSameY = nodeLabels.filter((label) =>
      isFloatEqual(label.vector.y, closedPath[0].y, threshold)
    );
    nodeLabelsSameY.some((label) => {
      const componentsAbove = label.components.filter(
        (c) => !doesComponentGrowDownwards(c)
      );
      if (_.isEmpty(componentsAbove)) return;

      const nodeV = label.vector;
      if (closedPath.inArray((e) => e.almostEquals(nodeV))) return;

      if (isPointInsidePolygon(nodeV, closedPath)) {
        nodeInsidePath = true;
        return true;
      }
    });

    return nodeInsidePath;
  };

  const doesThisVectorLieWithinAComponent = function (v3, options = {}){
    let vectorLiesWithin = false;
    const yEqualThreshold = 1e-3;

    const uniqueIds = Object.keys(uniqueIdVerticesMapping).map(id => parseInt(id));
    const allVertices = Object.values(uniqueIdVerticesMapping);
    allVertices.some((vertices, i) => {

      const uniqueId = uniqueIds[i];
      const component = meshObjectMapping.getObjectByUniqueId(uniqueId);

      if (options.ignoreDownwardsComponents){
        if (doesComponentGrowDownwards(component)) return;
      }

      const averageY = average(vertices.map(v => v.y));
      if (isFloatEqual(v3.y ,averageY, yEqualThreshold)){
        vectorLiesWithin = isPointInsidePolygon(v3, vertices);
      }

      if (vectorLiesWithin) options.component = component;

      return vectorLiesWithin;
    });

    return vectorLiesWithin;
};

  
  const addIndividualEdge = function(vertex, nextVertex, mass = PLACEHOLDER_COMPONENT){
    const vertexString = _format(vertex);
    const nextVertexString = _format(nextVertex);

    return _addEdge(adjacencyGraph, mass, {vertex, vertexString, nextVertex, nextVertexString});
  };

  const removeIndividualEdge = function(vertex, nextVertex, mass = PLACEHOLDER_COMPONENT){
    const vertexString = _format(vertex);
    const nextVertexString = _format(nextVertex);

    _removeEdge(vertexString, nextVertexString, adjacencyGraph, mass);
  };
  
  const removeOrphanNodes = function(vertices = [], mass = PLACEHOLDER_COMPONENT){
    let vertexStrings = vertices.map(_format);
    
    if (_.isEmpty(vertexStrings)){
      vertexStrings = adjacencyGraph.nodes();
    }
    _removeLonelyNodes(vertexStrings, adjacencyGraph, mass);
  };

  return {
    addWithGeometryEdit,
    addWithoutGeometryEdit,
    removeWithGeometryEdit,
    removeWithoutGeometryEdit,
    updateWithGeometryEdit,
    updateWithoutGeometryEdit,

    util: {
      getAppropriateVerticesToAdd,
      doesComponentGrowDownwards,
      isComponentPlanar,
      isComponentNotOfFullHeight,
      shouldAddComponentToGraph,
    },

    graphQueries: {
      getVertexLinkageData,
      doesANodeExistWithinLoop,
      doesThisVectorLieWithinAComponent,
    },
    
    coreGraphOperations : {
      addIndividualEdge,
      removeIndividualEdge,
      removeOrphanNodes,
    },

    getGraphs,
    getBottomAdjacencyGraph,
    getStructuralAdjacencyGraph,
    getMappings,
    findPath,
    lookup,
    lookupEdge,
    structuralLookupEdge,
    lookupCoincidentEdge,
    lookupOverEdge,
    structuralLookup,
    structuralFindNodesAlongEdge,
    flush,
    isComponentPresentInGraph,
  };
})();
export { virtualSketcher };
