import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { chart } from './ChangelogChart';
import dropArrowIcon from '../../../assets/icons/dropArrow.svg';


let formatCurrencyKforThousand = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
  // minimumFractionDigits: 2,
  notation: 'compact',
});
let formatCurrency = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
  // minimumFractionDigits: 2,
  notation: 'compact',
});
let formatCurrencyStandardNotation = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  notation: 'standard'
})
const formatNumberToCurrency = (number) => {
  if (number < 100000 && number > -100000) {
    return formatCurrencyKforThousand.format(number)
  }
  return formatCurrency.format(number);
}
const formatDate = (createdAt) => {
  const date = new Date(createdAt);
  const dateAsString = `${date.getDate()}/${date.getMonth() + 1}`;
  return dateAsString;
}


const MainContainer = styled.div`
  padding: 4rem 1.06em 2em 0.98em;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  .tick line{
    stroke: rgba(0,0,0,0.2);
  }
`;
const TotalWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #2D2D2E;
  margin-bottom: 0.75em;
  & > div:first-child{
    flex: 1;
    font-weight: 700;
    font-size: 0.75em;
  }
`
const ChartWrapper = styled.div`
`
const LogsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  color: #4F4F4F;
  padding: 0px;
  .row{
    display: flex;
    align-items: baseline;
    padding: 3px;
  }
  .row div{
    padding: 2px;
  }
  .all-log-titles{
    width: 55%;
  }
  .groups{
    /* width: 55%; */
  }
  .log-title{
    /* width: 55%; */
  }
  .group-title{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .log-title, .group-title{
    padding-left: 0px;
    font-size: 0.75em;
    position: relative;
    word-break: break-all;
    .group-item-titles{
      padding-left: 8px;
      /* display: none; */
    }
    .uncollapsed{
      display: block;
    }
    .group-item-title{
      position: relative;
    }
    .group-item-cost{
      position: absolute;
      top: 0;
      right: -95%;
    }
  }
  img.rotate-180{
    transform: rotate(180deg);
  }
  .log-type{
    width: 15%;
    font-size: 0.625em;
  }
  .log-date{
    width: 10%;
    font-size: 0.625em;
    text-align: right;
  }
  .log-value{
    width: 20%;
    text-align: right;
    font-weight: 500;
    font-size: 0.75em;
    color: #F2994A;
  }
  .log-value.green{
    color: #27AE60;
  }
`

const LOG_TYPE = {
  'priceChange': ["Price Decreased", "Price Increased"],
  'deletion': "Removed",
  'creation': "Added",
  'revitImport' : "Revit Import"
}

function GroupLogTitle({ group, groupKey }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <div className='group-title'>
      <div><strong>{group.occurrence}x </strong>{groupKey} {group.family ? `${group.family}` : ''} <span>{(group.snaptrudeElements || group.nonSnaptrudeElements) && <img onClick={() => { setIsCollapsed(!isCollapsed) }} className={`${!isCollapsed ? 'rotate-180' : ''}`} src={dropArrowIcon} alt='arrow' style={{ cursor: 'pointer' }} />}</span></div>
      {
        !isCollapsed &&
        <>
          {/* Snaptrude Elements */}
          <div className={`group-item-titles`}>
            {group.snaptrudeElements && Object.keys(group.snaptrudeElements)?.map(snapKey => {
              let totalCost = group.snaptrudeElements[snapKey].occurrence * group.snaptrudeElements[snapKey].cost;
              let family = group.snaptrudeElements[snapKey].family;
              return (
                <div className='group-item-title'>
                  <strong>{group.snaptrudeElements[snapKey].occurrence}x </strong>{group.snaptrudeElements[snapKey].name} {family ? `${family}` : ''}
                  <div className='group-item-cost'>{formatNumberToCurrency(totalCost)}</div>
                </div>
              )
            })}
          </div>
          {/* Non Snaptrude Elements */}
          <div className={`group-item-titles`}>
            {group.nonSnaptrudeElements && Object.keys(group.nonSnaptrudeElements)?.map(nonSnapKey => {
              let family = group.nonSnaptrudeElements[nonSnapKey].family 
              return (
                <div className='group-item-title'>
                  <strong>{group.nonSnaptrudeElements[nonSnapKey].occurrence}x </strong>{nonSnapKey} {family ? `${family}` : ''}
                </div>
              )
            })}
          </div>
        </>
      }
    </div>
  )
}

function GroupLogTitles({ groups }) {
  return (
    <div className='groups'>
      {
        groups && Object.keys(groups).map((key, index) => {
          if (key != "team") {
            return (
              <GroupLogTitle group={groups[key]} groupKey={key} />
            )
          }

        })
      }
    </div>
  )
}

const getLogType = (operationName, costOfOperation, typeOfExecution) => {
  if (!operationName) return;
  if (operationName === 'priceChange') {
    if (costOfOperation < 0) return LOG_TYPE.priceChange[0]
    return LOG_TYPE.priceChange[1];
  }
  if (typeOfExecution === "undo") {
    if (operationName === "deletion") return LOG_TYPE.creation;
    return LOG_TYPE.deletion;
  }
  return LOG_TYPE[operationName];
}

function ChangelogSidebar() {
  const chartContainerRef = useRef(null);
  const { logs } = useSelector(state => state.changelogs);
  useEffect(() => {
    if (logs && logs.length > 0) {
      if (chartContainerRef.current) {
        chartContainerRef.current.childNodes.forEach(child => child.remove());
      }
      chart.init(logs);
    }
  }, [logs])
  return (
    <MainContainer>
      <TotalWrapper>
        <div>Total Cost</div>
        {logs && logs.length > 0 &&
          <div>{formatCurrencyStandardNotation.format(logs[0].cumulativeCost)}</div>
        }
      </TotalWrapper>
      <ChartWrapper ref={chartContainerRef} id="my_dataviz">
      </ChartWrapper>
      <LogsWrapper>
        {
          logs && logs.map(log => {
            return (
              <div className='row' key={log._id}>
                <div className="all-log-titles">
                  {
                    log.groups && Object.keys(log.groups).length > 0 && <GroupLogTitles groups={log.groups} />}
                  <div className='log-title'>
                    {log.groupTitle}
                    {
                      log.titles && log.titles.length > 0 ?
                        log.titles.map(title => {
                          return (
                            <div className={log.groupTitle ? 'group-item' : ''}>
                              <strong>{title.count}x </strong>
                              {title.name} {title.familyName ? `${title.familyName}` : ''}
                              {/* <div className='group-item-cost'>{formatNumberToCurrency(title.cost)}</div> */}
                            </div>
                          )
                        })
                        :
                        _.startCase(log.breed.name)
                    }
                  </div>

                </div>



                <div className='log-type'>
                  {
                    getLogType(log.operation.name, log.costOfOperation, log.typeOfExecution)
                  }
                </div>
                <div className='log-date'>{formatDate(log.createdAt)}</div>
                {
                  log.operation.name === 'saveas' ?
                    <div className={`log-value ${log.cumulativeCost <= 0 && log.operation.name != "creation" ? 'green' : ''}`}>{formatNumberToCurrency(log.cumulativeCost)}</div>
                    :
                    <div className={`log-value ${log.costOfOperation <= 0 && log.operation.name != "creation" ? 'green' : ''}`}>{formatNumberToCurrency(log.costOfOperation)}</div>
                }
              </div>
            )
          })
        }
      </LogsWrapper>
    </MainContainer>
  )
}

export default ChangelogSidebar;