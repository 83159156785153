import _, { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import NamedAvatar from "../../../components/Avatar/NamedAvatar";
import { SearchInput } from "../../../components/searchInput";
import { RecentBox } from "../../../components/SortByBox";
import { teamsConnect } from "../../../snaptrude/modules/socket/teamSocket";
import { findFolderRecursive, generatePathToFolder } from "../../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice";
import { Content, ContentStyle, Controls, Header, MainContainer } from "../styles";
import { isCurrentUser } from "../../../services/auth.service";
import createProjectGraphic from "../../../assets/images/createProjectGraphic.svg";
import TeamInviteModal from "../../../components/TeamModal.js";
import FoldersContainer from "../../../components/Folder/FoldersContainer";
import ProjectsContainer from "../../../components/Project/ProjectsContainer";
import FolderBreadcrumbs from "../../../components/FolderBreadcrumbs";
import { getFilesForFolder } from "../../../services/folder.service";
import { routes } from "../../../routes/constants";
import { universalSearch } from "../../../services/global.service";
import { DragDropScroller } from "../../../components/DragDrop/Scroller";
import { orderFolders, orderProjects } from "../TeamDashboard/helper";
import LoadingScreen from "../../../components/Loading/LoadingScreen";


const DEFAULT_SEARCH_STATE = {
    isLoading: false,
    text: "",
    results: {
        folders: [],
        projects: []
    },
    inSearchMode: false
}

const SORT_ORDER = {
    "Recently Updated": "latest",
    "Created Date": "earliest",
    "Project Name": "projectname",
}
const SORT_ORDER_UI = {
    "latest": "Recently Updated",
    "earliest": "Created Date",
    "projectname": "Project Name",
}
const VIEW_BY = {
    "Created On": "createdon",
    "Updated On": "updatedon",
}
const VIEW_BY_UI = {
    "createdon": "Created On",
    "updatedon": "Updated On",
}
const VIEW_BY_FOR_SORT = {
    "latest": "updatedon",
    "earliest": "createdon",
    "projectname": "updatedon"
}

function ActiveUsers({ teamId, allMembers }) {
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        const addMyselfToDashboard = async () => {
            try {
                const resp = await teamsConnect.addToDashboard(teamId);
                if (resp.status === "success" && resp.teamId === teamId) {
                    setUsersList(resp.activeUsers);
                }
            } catch (error) {
                console.log(error)
            }

        }
        const handleRefreshOnlineUsers = (evt) => {
            const { detail } = evt;
            if (detail.team === teamId) {
                setUsersList(detail.users)
            }
        }
        addMyselfToDashboard();
        document.addEventListener("refreshonlineusersUI", handleRefreshOnlineUsers);
        return () => {
            teamsConnect.removeFromDashboard();
            document.removeEventListener("refreshonlineusersUI", handleRefreshOnlineUsers);
        }
    }, [teamId]);

    return <div style={{ display: "flex", marginRight: "1em", flexDirection: "row-reverse" }}>
        {
            allMembers
                ?.filter(member => {
                    if (member.status === 'invited') return false;
                    if (isCurrentUser(member.user.email)) return false;
                    return true;
                })
                .map((member, index) => {
                    if (member.status === "invited") return <></>
                    return <NamedAvatar
                        key={`${index}-${member.user.email}`}
                        id={member.user.email}
                        text={member.user.email}
                        style={{ marginRight: index === usersList.length - 1 ? "0px" : "-8px", outline: "2px solid white" }}
                        isActive={usersList.find(activeUser => activeUser.email === member.user.email) ? true : false}
                    />
                })
        }
    </div>
}

function AllFiles({ personalWorkspace }) {
    const { folderID } = useParams();
    const [sortOrder, setSortOrder] = useState('latest');
    const [viewBy, setViewBy] = useState('updatedon');
    const [search, setSearch] = useState(DEFAULT_SEARCH_STATE);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

    useEffect(() => {
        setViewBy('updatedon')
        setSortOrder("latest")
    }, []);

    useEffect(() => {
        setSearch(DEFAULT_SEARCH_STATE);
    }, [folderID]);

    const handleSortBy = async (newSortOrder) => {
        newSortOrder = SORT_ORDER[newSortOrder];
        if (newSortOrder === sortOrder) return;
        setSortOrder(newSortOrder);
        setViewBy(VIEW_BY_FOR_SORT[newSortOrder] || "updatedon");
    }

    const handleViewBy = (newViewBy) => {
        newViewBy = VIEW_BY[newViewBy];
        if (newViewBy === viewBy) return;
        setViewBy(newViewBy)
    }

    const handleSearch = useCallback(debounce((text) => {
        universalSearch(text)
            .then(resp => {
                const { status, user, searchedText } = resp;
                if (status === "success") {
                    setSearch((prev) => {
                        if (prev.text !== searchedText) return prev;
                        return {
                            isLoading: false,
                            results: user,
                            inSearchMode: true,
                            text: prev.text
                        }
                    })
                }
            })
    }, 500), [])

    const handleSearchTextChange = (evt) => {
        const { value } = evt.target;
        if (value === "") {
            setSearch({
                isLoading: false,
                inSearchMode: false,
                text: value,
            })
            return;
        }
        setSearch({
            isLoading: true,
            inSearchMode: true,
            text: value
        })
        handleSearch(value)
    }

    const { selectedFolder, breadcrumbs, showCreateProjectGraphic } = useMemo(() => {
        const root = [{ name: "Personal", teamID: null }];
        let currentFolder, breadcrumbs;
        let showCreateProjectGraphic = false;
        if (!folderID) {
            currentFolder = {
                ...personalWorkspace.fileSystem,
                team_dashboard: null,
                id: "root"
            }
            breadcrumbs = root;
        } else {
            currentFolder = _.cloneDeep(findFolderRecursive(personalWorkspace.fileSystem.folders, parseInt(folderID)));
            breadcrumbs = generatePathToFolder({ isDummy: true, folders: personalWorkspace.fileSystem.folders }, parseInt(folderID), root).path;
        }
        if (currentFolder) {
            currentFolder.folders = orderFolders(currentFolder.folders, sortOrder);
            currentFolder.projects = orderProjects(currentFolder.projects, sortOrder);
            currentFolder.projects = _.filter(currentFolder.projects, project => !(project.speckleStreamId != null && project.isSpeckleImportDone === false));
            if(currentFolder.id === "root"){
                showCreateProjectGraphic = personalWorkspace.fileSystem.projects.length == 0 && personalWorkspace.fileSystem.folders.length == 0;
            }
        }
        return {
            selectedFolder: currentFolder,
            breadcrumbs: breadcrumbs,
            showCreateProjectGraphic: showCreateProjectGraphic
        };
    }, [personalWorkspace.fileSystem, folderID, sortOrder]);

    useEffect(() => {
        getFilesForFolder(null, folderID)
    }, [folderID]);

    return (
        <MainContainer>
            <Header>
                {/* <Title style={{ flex: 1 }}>
                    All Projects
                    <Select
                        style={{minWidth: "8em", width: "fit-content", fontSize: "large"}}
                        key={selectedTeam.title} 
                        selectedOption={selectedTeam.title} 
                        options={teamlist.map(team=> team.title)} 
                        onSelect={handleTeamDropdownSelect}
                    /> 
                </Title> */}
                <FolderBreadcrumbs
                    path={breadcrumbs}
                    teamId={null}
                    rootRoute={routes.dashboard}
                    workspaceRoute={routes.dashboard}
                />
                <Controls>
                    {/* <ActiveUsers key={selectedTeam.id} teamId={selectedTeam.id} allMembers={selectedTeam.members} /> */}

                    {
                        <SearchInput
                            style={{ marginRight: "0.5em" }}
                            value={search.text}
                            onChange={handleSearchTextChange}
                        />
                    }

                    {
                        // selectedTeam.projects.length > 0 &&
                        <RecentBox
                            seperate={true}
                            header={"View:"}
                            optionAppender={"View:"}
                            options={["Created On", "Updated On"]}
                            customDropDownStyle={
                                {
                                    marginTop: "0",
                                    marginLeft: "0",
                                    borderRadius: "5px",
                                    width: "10em",
                                }
                            }
                            selectOption={handleViewBy}
                            customBoxStyles={{ marginRight: "0.5em" }}
                            selectedOption={VIEW_BY_UI[viewBy]}
                        />
                    }

                    {

                        <RecentBox
                            seperate={true}
                            header={"Sort By:"}
                            optionAppender={"Sort By:"}
                            options={["Recently Updated", "Created Date", "Project Name"]}
                            customDropDownStyle={
                                {
                                    marginTop: "0",
                                    marginLeft: "0",
                                    borderRadius: "5px",
                                    width: "10em",
                                }
                            }
                            selectOption={handleSortBy}
                            customBoxStyles={{ marginRight: "0.5em", minWidth: "11rem" }}
                            selectedOption={SORT_ORDER_UI[sortOrder]}
                        />
                    }

                </Controls>
            </Header>
            {
                search.inSearchMode ?
                    search.isLoading ? <Content><LoadingScreen /></Content> :
                        <DragDropScroller style={ContentStyle}>
                            <FoldersContainer folders={search.results?.folders || []} workspaceRoute={routes.dashboard} permissions={{ "create_rename_delete_folder": true }} hideAddButton={true} viewBy={viewBy} />
                            <ProjectsContainer projects={search.results?.projects || []} selectedTeam={null} viewBy={viewBy} folderId={selectedFolder?.id} />
                        </DragDropScroller>
                    :
                    showCreateProjectGraphic ?
                        <img className="create-project-graphic" src={createProjectGraphic} alt={""} />
                    :
                    <DragDropScroller style={ContentStyle}>
                        <FoldersContainer parentFolder={selectedFolder} folders={selectedFolder ? selectedFolder.folders : []} workspaceRoute={routes.dashboard} permissions={{ "create_rename_delete_folder": true }}viewBy={viewBy} />
                        <ProjectsContainer projects={selectedFolder ? selectedFolder.projects : []} selectedTeam={null} viewBy={viewBy} folderId={selectedFolder?.id} />
                    </DragDropScroller>
            }

            {/* <Footer>Footer</Footer> */}
            {
                showCreateTeamModal && <TeamInviteModal page={2} handleClose={() => { setShowCreateTeamModal(false) }} selectedTeamId={null} />
            }
        </MainContainer>
    )
}

export default AllFiles;