import { colors, fontSizes } from "../../themes/constant";

// signup
export const signUpStyles = {
  loginButton: {
    backgroundColor: colors.fullWhite,
    boxShadow: `0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey}`,
    padding: "0.5rem",
  },
  loginText: {
    color: colors.greyishBlack,
    fontWeight: "bold",
    fontSize: fontSizes.tiny,
  },
  signUpButtonDisabled: {
    backgroundColor: colors.lightGrey,
    cursor: "default",
  },
  signUpButtonEnabled: {
    backgroundColor: colors.brightRed,
  },
  forgotPasswordButton: {
    backgroundColor: colors.transparent,
    color: colors.brightRed,
    padding: 0,
    margin: 0,
    width: "auto",
    textDecoration: "underline",
  },
  termsAndConditionsButton: {
    backgroundColor: colors.transparent,
    color: colors.brightRed,
    padding: "0.25rem",
    margin: 0,
  },
  inputWrapper: {
    // padding: "0 0 0.75rem 0",
    lineHeight: "1rem",
    marginTop: "1rem",
  },
  dividerStyle: {
    paddingBottom: "0.75rem",
  },
  fromErrorText: {
    color: colors.mustard,
    opacity: 0.8,
    fontSize: "0.775rem",
    margin: "0.25rem",
  },
  disclaimerText: {
    color: colors.primeBlack,
    opacity: 1,
    fontSize: "0.875rem",
    margin: "0.25rem",
    textAlign: "center"
  },
  selectRoleAndIndustryButton: {
    background: "#FFFFFF",
    border: "1px solid #E6E6E6",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
    borderRadius: "5px",
    color: "#575758",
  },
  highlightedButton: {
    backgroundColor: "#ec1c45",
    border: "1px solid #E6E6E6",
    boxSizing: "border-box",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
    borderRadius: "5px",
    color: "white",
  }
};
