import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Sidebar from "../../components/sidebar/index";
import Input from "../../components/Input/index";
import * as constants from "./constants";
import { styles } from "./styles";
import backArrow from "../../assets/icons/back_arrow.svg";
import { colors } from "../../themes/constant";
import Button from "../../components/Button/index";

import AuthService from "../../services/auth.service";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
`;

const ContentContainer = styled.div`
  padding: 1rem 2.5rem;
  width: 100%;
`;

const PasswordContainer = styled.div`
  padding: 1rem 0 1rem 0;
`;

const Heading = styled.div`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.secondaryBlack};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 1rem;
`;

const ArrowContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fullWhite};
  box-shadow: 0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey};
  border-radius: 2.063rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0 1em 0;
  border: 0 solid ${colors.veryLightGrey};
  border-bottom-width: 0.063rem;
  margin: 1em 0 2em 0;
`;

const ButtonWrapper = styled.div`
  padding: 0.5rem 0 0.5rem 0;
`;

const ContentWrapper = styled.div`
  width: 21rem;
`;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      reconfirmPassword: "",
    };
  }

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  handleChangePassword = () => {
    if (!this.shouldChangePasswordDisable) {
      let user = AuthService.getCurrentUser();
      AuthService.changePassword(
        user.username,
        this.state.oldPassword,
        this.state.newPassword
      )
        .then((res) => {
          if (res.data.message == "success") {
            this.setFormProperty("oldPassword", "");
            this.setFormProperty("newPassword", "");
            this.setFormProperty("reconfirmPassword", "");
            alert("password changed successfully");
          } else {
            alert("enter correct password");
          }
        })
        .catch((err, res) => {
          console.log(err);
          alert(err.response.data.message);
        });
    }
  };

  render() {
    const { history } = this.props;
    const { oldPassword, newPassword, reconfirmPassword } = this.state;
    const shouldChangePasswordDisable =
      !oldPassword ||
      !newPassword ||
      !reconfirmPassword ||
      newPassword !== reconfirmPassword;
    return (
      <MainContainer>
        <Sidebar />
        <ContentContainer>
          <>
            <HeaderWrapper>
              <ArrowContainer
                onClick={() => {
                  history.goBack();
                }}
              >
                <img src={backArrow} width={8} height={11} alt="<" />
              </ArrowContainer>
              <Heading>{constants.CHANGE_PASSWORD}</Heading>
            </HeaderWrapper>
            <ContentWrapper>
              <Input
                placeholder={constants.ENTER_CURRENT_PASSWORD}
                type={"password"}
                editable={true}
                value={oldPassword}
                customInputContainerStyle={styles.inputWrapper}
                onPress={(e) =>
                  this.setFormProperty("oldPassword", e.target.value)
                }
              />
              <PasswordContainer>
                <Input
                  placeholder={constants.ENTER_NEW_PASSORD}
                  type={"password"}
                  editable={true}
                  value={newPassword}
                  customInputContainerStyle={styles.inputWrapper}
                  onPress={(e) =>
                    this.setFormProperty("newPassword", e.target.value)
                  }
                />
                <Input
                  placeholder={constants.RECONFIRM_NEW_PASSWORD}
                  type={"password"}
                  editable={true}
                  value={reconfirmPassword}
                  customInputContainerStyle={styles.inputWrapper}
                  onPress={(e) =>
                    this.setFormProperty("reconfirmPassword", e.target.value)
                  }
                />
              </PasswordContainer>
              <ButtonWrapper>
                <Button
                  title={constants.CHANGE_PASSWORD}
                  disabled={shouldChangePasswordDisable}
                  onPress={this.handleChangePassword}
                  customButtonStyle={
                    shouldChangePasswordDisable
                      ? styles.signUpButtonDisabled
                      : styles.signUpButtonEnabled
                  }
                />
              </ButtonWrapper>
            </ContentWrapper>
          </>
        </ContentContainer>
      </MainContainer>
    );
  }
}

ChangePassword.propTypes = {};

export default withRouter(ChangePassword);
