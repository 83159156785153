import { colors } from "../../../themes/constant";

// account
export const accountStyles = {
  inputWrapper: {
    padding: "0 0 0.75rem 0",
    width: "21rem",
  },
  changePasswordStyle: {
    backgroundColor: colors.transparent,
    color: colors.secondaryGrey,
    fontWeight: "bold",
    padding: 0,
    margin: 0,
    width: "auto",
    textDecoration: "underline",
  },
  addProfilePicture: {
    color: colors.secondaryGrey,
    width: "max-content",
    backgroundColor: colors.transparent,
    paddingLeft: 0,
  },
  addProfilePictureText: {
    fontSize: "0.75rem",
  },
};

// teams
export const teamStyles = {
  boxStyle: {
    border: "none",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
  },
  customDropDownStyle: {
    marginTop: "0",
    marginLeft: "0",
    borderRadius: "5px",
    width: "10em",
    position: "absolute",
    top: 0,
    right: 0,
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "black",
  },
  inputWrapper: {
    padding: "0.5em 0.5em 0.5em 0",
  },
  sendInvitesStyle: {
    backgroundColor: colors.transparent,
    color: colors.red,
    padding: "0",
    width: "auto",
  },
  customButtonStyle: {
    width: "17.313rem",
    height: "1.875rem",
    margin: "1rem 0 1rem 0",
    minHeight: 0,
  },
};

// billing
export const billingStyles = {
  customTableContainer: {
    width: "100%",
  },
  customTableRow: {
    borderBottom: `1px solid ${colors.veryLightGrey}`,
  },
  customTableHeading: {
    color: colors.secondaryBlack,
    opacity: 0.8,
    fontSize: "0.875em",
    marginLeft: 0,
  },
};

//plan
export const planStyles = {
  marginRight: "1.5em",
};
