import axios from "axios";
import { ORIGIN } from "./url.constants";

export const getIsPro = () => {
  let promise = axios.get(ORIGIN + "/payments/ispro/")
    .then((res) => {

      localStorage.setItem("isPro", res.data.isPro);
      return res.data;
    });

  return promise;
};

export const getPlans = () => {
  let promise = axios.get(ORIGIN + "/payments/plan")
    .then((res) => {
      return res.data;
    });

  return promise;
};

export const createCheckoutSession = () => {
  let promise = axios.post(ORIGIN + "/card/checkoutSession/")
    .then((res) => {
      console.log(res.data);

      return res.data;
    });

  return promise;
}

export const updateCard = (sessionId) => {
  let promise = axios.put(ORIGIN + "/card/", { sessionId: sessionId })
    .then((res) => {
      console.log(res.data);

      return res.data;
    });

  return promise;
}

export const cancelSubscription = () => {
  let promise = axios.delete(ORIGIN + "/payments/subscribe/")
    .then((res) => {
      console.log(res.data);

      let t = new Date(res.data.subscription.current_period_end * 1000);
      let formatted = t.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: "numeric" })
      console.log(formatted);

      return res.data;
    });

  return promise;
};

export const createStripeSubscription = (data) => {
  let promise = axios.post(ORIGIN + "/payments/subscribe/", data)
    .then((res) => {
      return res.data;
    });

  return promise;
};

export const retrieveStripeSubscription = () => {
  let promise = axios.get(ORIGIN + "/payments/subscribe/")
    .then((res) => {
      return res.data;
    });

  return promise;
};

export const validatePayment = (data) => {
  let promise = axios.post(ORIGIN + "/payments/validate/", data)
    .then((res) => {
      return res.data;
    });

  return promise;
};

export const updatePlan = (noOfLicenses, priceId) => {
  let data = {
    noOfLicenses: noOfLicenses,
    priceId: priceId
  }
  let promise = axios.post(ORIGIN + "/payments/plan/", data)
    .then((res) => {
      return res.data;
    });

  return promise;
}

const CURRENCIES = {
  IN: 'inr',
  US: 'usd',
  GB: 'gbp',
};

export const getCountryFromIP = async () => {
  try {
    let res = await fetch("https://api.country.is/");
    let data = await res.json();

    return data.country;
  } catch (e) {
    console.log("Failed to get country from IP: ", e);

    return 'US';
  }
}

export const getCurrency = async () => {
  let currency = localStorage.getItem("currency");

  if (currency) return currency;

  try {
    return await updateCurrency();
  } catch {
    return CURRENCIES.US;
  }
}

export const updateCurrency = async (countryCode) => {
  if (!countryCode) {
    countryCode = await getCountryFromIP();
  }

  localStorage.setItem("currency", CURRENCIES[countryCode] || CURRENCIES.US);

  return localStorage.getItem("currency");
}

export const getCoupon = (couponCode) => {
  let promise = axios.get(ORIGIN + "/payments/coupon?couponCode="+couponCode)
    .then((res) => {
      return res.data;
    });

  return promise;
};