import React, { useState } from "react";
import styled from "styled-components";
import GenericModal from "../../genericModal";
import { SHARE_MODAL } from "../../genericModal/constants";
import Notification from "../../../components/Notification";
import Cancel from "../../genericModal/Cancel";
import Export from "../../genericModal/export";
import MaterialColor from "../../../components/rightMenus/materialColor";
import {
  WallDimensionEdit,
  DoorDimensionEdit,
  WindowDimensionEdit,
  SlabDimensionEdit,
  FurnitureDimensionEdit,
  FlooringDimensionEdit,
  StaircaseDimensionEdit,
} from "../../editor/components/rightmenuPopUps/canvasEdit";
import DoorEdit from "../../editor/components/editModals/doorEdit";
import WindowEdit from "../../editor/components/editModals/windowEdit";
import {
  UpdateMaterialModal,
  ImportMaterialModal,
  ImportDoorModal,
  ImportWindowModal,
  ImportFurnitureModal,
  UpdateCadDrawing,
} from "../../genericModal/UploadImportModals";
import LastStatusModal from "../../editor/components/bottomPopUps/lastStatus";
import NumericKeypad from "../../otherModals/numericKeypad";
import ShortCutKeyModal from "../../editor/components/bottomPopUps/shotCutKeyModal";
import SettingsPopUp from "../../editor/components/bottomPopUps/settingsPopUp";
import MaterialEdit from "../../../components/rightMenus/materialEdit";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  .modals {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin: 0.5rem;
    }
  }

  .button-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
  }

  button {
    line-height: 2rem;
  }
`;

function Modals() {
  const [modalState, setModalState] = useState({
    share: false,
    notification: false,
    cancel: false,
    export: false,
    colorOverlay: false,
    wallDimensionEdit: false,
    doorDimensionEdit: false,
    windowDimensionEdit: false,
    slabDimensionEdit: false,
    furnitureDimensionEdit: false,
    flooringDimensionEdit: false,
    staircaseDimensionEdit: false,
    doorEdit: false,
    windowEdit: false,
    updateMaterialModal: false,
    importMaterialModal: false,
    importDoorModal: false,
    importWindowModal: false,
    importFurnitureModal: false,
    updateCadDrawing: false,
    lastStatusModal: false,
    numericKeypad: false,
    shortCutKeyModal: false,
    settingsPopUp: false,
    materialEdit: false,
  });
  const [permission, setPermission] = useState("Anyone with the link");
  const [subPermission, setSubPermission] = useState("Can View");

  return (
    <MainContainer>
      <div className="content">
        <div className="header">
          <div className="project-header">
            <h3>Modals</h3>
          </div>
        </div>
        <div className="button-container">
          <button onClick={() => setModalState({ ...modalState, share: true })}>
            Share Modal
          </button>
          <button
            onClick={() => setModalState({ ...modalState, notification: true })}
          >
            Notifications
          </button>
          <button
            onClick={() => setModalState({ ...modalState, cancel: true })}
          >
            Cancel
          </button>
          <button
            onClick={() => setModalState({ ...modalState, export: true })}
          >
            Export
          </button>
          <button
            onClick={() => setModalState({ ...modalState, colorOverlay: true })}
          >
            Color Overlay
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, wallDimensionEdit: true })
            }
          >
            WallDimensionEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, doorDimensionEdit: true })
            }
          >
            DoorDimensionEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, windowDimensionEdit: true })
            }
          >
            WindowDimensionEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, slabDimensionEdit: true })
            }
          >
            SlabDimensionEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, furnitureDimensionEdit: true })
            }
          >
            FurnitureDimensionEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, flooringDimensionEdit: true })
            }
          >
            FlooringDimensionEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, staircaseDimensionEdit: true })
            }
          >
            StaircaseDimensionEdit
          </button>
          <button
            onClick={() => setModalState({ ...modalState, doorEdit: true })}
          >
            DoorEdit
          </button>
          <button
            onClick={() => setModalState({ ...modalState, windowEdit: true })}
          >
            WindowEdit
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, updateMaterialModal: true })
            }
          >
            UpdateMaterialModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, importMaterialModal: true })
            }
          >
            ImportMaterialModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, importDoorModal: true })
            }
          >
            ImportDoorModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, importWindowModal: true })
            }
          >
            ImportWindowModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, importFurnitureModal: true })
            }
          >
            ImportFurnitureModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, updateCadDrawing: true })
            }
          >
            UpdateCadDrawing
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, lastStatusModal: true })
            }
          >
            LastStatusModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, numericKeypad: true })
            }
          >
            NumericKeypad
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, shortCutKeyModal: true })
            }
          >
            ShortCutKeyModal
          </button>
          <button
            onClick={() =>
              setModalState({ ...modalState, settingsPopUp: true })
            }
          >
            SettingsPopUp
          </button>
          <button
            onClick={() => setModalState({ ...modalState, materialEdit: true })}
          >
            MaterialEdit
          </button>
          <GenericModal
            modalType={SHARE_MODAL}
            onClose={() => setModalState({ ...modalState, share: false })}
            isOpen={modalState.share}
            modalHeaderText={"Share"}
            customProps={{
              permission,
              setPermission,
              subPermission,
              setSubPermission,
            }}
          />
          <Cancel
            isOpen={modalState.cancel}
            onClose={() => setModalState({ ...modalState, cancel: false })}
          />
          <Export
            isOpen={modalState.export}
            onClose={() => setModalState({ ...modalState, export: false })}
          />
          <div className="modals">
            <Notification
              heading="User Invited"
              body="User has been invited
Please check Billing section for updated billing information"
              onClose={() =>
                setModalState({ ...modalState, notification: false })
              }
              isOpen={modalState.notification}
            />
            <Notification
              heading="Upgrade to Enterprise"
              body="You have more than 10 users on your team. Upgrade to Enterprise to avail discounted pricing"
              onClose={() =>
                setModalState({ ...modalState, notification: false })
              }
              isOpen={modalState.notification}
            />
            <Notification
              heading="User Removed"
              body="User has been removed
            Please check Billing section for updated billing information"
              onClose={() =>
                setModalState({ ...modalState, notification: false })
              }
              isOpen={modalState.notification}
            />
            <Notification
              heading="Cancelled"
              body="Your subscription has been cancelled. You can activate at any time to access your projects"
              onClose={() =>
                setModalState({ ...modalState, notification: false })
              }
              isOpen={modalState.notification}
            />
            <Notification
              heading="Request Sent"
              body="You’ll soon be contacted by our Team to craft a custom plan to suit your enterprise"
              onClose={() =>
                setModalState({ ...modalState, notification: false })
              }
              isOpen={modalState.notification}
            />
            {modalState.colorOverlay && (
              <MaterialColor
                setModalOpen={() =>
                  setModalState({ ...modalState, colorOverlay: false })
                }
              />
            )}
            {modalState.wallDimensionEdit && (
              <WallDimensionEdit
                closePopUp={() =>
                  setModalState({ ...modalState, wallDimensionEdit: false })
                }
              />
            )}
            {modalState.doorDimensionEdit && (
              <DoorDimensionEdit
                closePopUp={() =>
                  setModalState({ ...modalState, doorDimensionEdit: false })
                }
              />
            )}
            {modalState.windowDimensionEdit && (
              <WindowDimensionEdit
                closePopUp={() =>
                  setModalState({ ...modalState, windowDimensionEdit: false })
                }
              />
            )}
            {modalState.slabDimensionEdit && (
              <SlabDimensionEdit
                closePopUp={() =>
                  setModalState({ ...modalState, slabDimensionEdit: false })
                }
              />
            )}
            {modalState.furnitureDimensionEdit && (
              <FurnitureDimensionEdit
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    furnitureDimensionEdit: false,
                  })
                }
              />
            )}
            {modalState.flooringDimensionEdit && (
              <FlooringDimensionEdit
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    flooringDimensionEdit: false,
                  })
                }
              />
            )}
            {modalState.staircaseDimensionEdit && (
              <StaircaseDimensionEdit
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    staircaseDimensionEdit: false,
                  })
                }
              />
            )}
            {modalState.doorEdit && (
              <DoorEdit
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    doorEdit: false,
                  })
                }
              />
            )}
            {modalState.windowEdit && (
              <WindowEdit
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    windowEdit: false,
                  })
                }
              />
            )}
            <UpdateMaterialModal
              isOpen={modalState.updateMaterialModal}
              onClose={() =>
                setModalState({
                  ...modalState,
                  updateMaterialModal: false,
                })
              }
            />
            <ImportMaterialModal
              isOpen={modalState.importMaterialModal}
              onClose={() =>
                setModalState({
                  ...modalState,
                  importMaterialModal: false,
                })
              }
            />
            <ImportDoorModal
              isOpen={modalState.importDoorModal}
              onClose={() =>
                setModalState({
                  ...modalState,
                  importDoorModal: false,
                })
              }
            />
            <ImportWindowModal
              isOpen={modalState.importWindowModal}
              onClose={() =>
                setModalState({
                  ...modalState,
                  importWindowModal: false,
                })
              }
            />
            <ImportFurnitureModal
              isOpen={modalState.importFurnitureModal}
              onClose={() =>
                setModalState({
                  ...modalState,
                  importFurnitureModal: false,
                })
              }
            />
            <UpdateCadDrawing
              isOpen={modalState.updateCadDrawing}
              onClose={() =>
                setModalState({
                  ...modalState,
                  updateCadDrawing: false,
                })
              }
            />
            {modalState.lastStatusModal && (
              <LastStatusModal
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    lastStatusModal: false,
                  })
                }
              />
            )}
            {modalState.numericKeypad && (
              <NumericKeypad
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    numericKeypad: false,
                  })
                }
              />
            )}
            {modalState.shortCutKeyModal && (
              <ShortCutKeyModal
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    shortCutKeyModal: false,
                  })
                }
              />
            )}
            {modalState.settingsPopUp && (
              <SettingsPopUp
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    settingsPopUp: false,
                  })
                }
              />
            )}
            {modalState.materialEdit && (
              <MaterialEdit
                closePopUp={() =>
                  setModalState({
                    ...modalState,
                    materialEdit: false,
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default Modals;
