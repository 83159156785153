import { fabric } from "fabric";
import * as math from "mathjs";
import { store } from "../../modules/utilityFunctions/Store.js";
import { room_measurement_flag } from "./canvasMouseEvents.js";
import { appElement } from "../bimDataFuncs.js";
import { draw_interior_drag } from "../fplan/drawInteriorsFplan.js";
import { removeAllGripsWithId, addObjectEdgeGrips } from "./objectGrips.js";
import { updateModifications } from "../sceneStateFuncs.js";
import { reDrawAllRoomsAfterTransform } from "./drawInteriors.js";
function removeRoomDimension(obj) {
  if (room_measurement_flag) {
    store.canvas.remove(store.room_width_line); /* UNKOWN: CONVERTED TO STORE. */
    store.canvas.remove(store.room_height_line);
    store.canvas.remove(store.width_text);
    store.canvas.remove(store.width_text_static);
    store.canvas.remove(store.height_text);
    store.canvas.remove(store.height_text_static);
    try {
      store.object_name.remove();
    } catch (err) {}
    room_measurement_flag = false;
  }
}

function displayRoomDimension(obj, pts1, pts2) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  var diffX1 = pts1[0].x - pts1[1].x;
  var diffY1 = pts1[0].y - pts1[1].y;
  var diffX2 = pts2[0].x - pts2[1].x;
  var diffY2 = pts2[0].y - pts2[1].y;
  var w1, w2;
  if (
    Math.abs(obj.oCoords.tl.x - pts1[0].x) >
    Math.abs(obj.oCoords.tr.x - pts1[0].x)
  ) {
    w1 = obj.oCoords.tr.x;
    w2 = pts1[0].x;
  } else {
    w2 = pts1[0].x;
    w1 = obj.oCoords.tl.x;
  }
  store.room_width_line = new fabric.Line(
    [w1, obj.oCoords.tl.y + 10, w2, obj.oCoords.tr.y + 10],
    {
      stroke: "red",
    }
  );
  store.canvas.add(store.room_width_line);
  store.room_width_line.top = obj.top;

  // var width_measurement = Math.abs(obj.oCoords.tl.x - obj.oCoords.tr.x) + diffX1;
  var width_measurement = Math.abs(w1 - w2);
  var unit_disp = "mts";
  var x = document.getElementById("units").selectedIndex;

  if ($scope.unit.value == "meters") {
    width_measurement = (width_measurement * 2.54) / 100;
    unit_disp = "mts";
  } else if ($scope.unit.value == "cm") {
    width_measurement = ((width_measurement * 2.54) / 100) * 100;
    unit_disp = "cm";
  } else if ($scope.unit.value == "mm") {
    width_measurement = ((width_measurement * 2.54) / 100) * 1000;
    unit_disp = "mm";
  }
  width_measurement =
    Math.round(width_measurement * 1000 * store.tape_scale_factor) / 1000;

  store.width_text = new fabric.Textbox(width_measurement.toString(), {
    id: "tape_width_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.tr.x) / 2 + 2,
    top: (obj.oCoords.tl.y + obj.oCoords.tr.y + 20) / 2 + 2,
    selectable: true,
    editable: true,
    fontSize: 12,
    borderColor: "black",
    cornerSize: 2,
    hasRotatingPoint: false,
    hoverCursor: "text",
    transparentCorners: false,
  });
  store.canvas.add(store.width_text);
  store.width_text_static = new fabric.Textbox(unit_disp, {
    id: "tape_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.tr.x) / 2 + 4,
    top: (obj.oCoords.tl.y + obj.oCoords.tr.y + 20) / 2 + 14,
    selectable: false,
    fontSize: 12,
  });
  store.canvas.add(store.width_text_static);
  room_measurement_flag = true;

  var h1, h2;
  if (
    Math.abs(obj.oCoords.tl.y - pts1[0].y) >
    Math.abs(obj.oCoords.bl.y - pts1[0].y)
  ) {
    h1 = pts1[0].y;
    h2 = obj.oCoords.bl.y;
  } else {
    h1 = obj.oCoords.tl.y;
    h2 = pts1[0].y;
  }
  store.room_height_line = new fabric.Line(
    [obj.oCoords.tl.x + 10, h1, obj.oCoords.bl.x + 10, h2],
    {
      stroke: "green",
    }
  );
  store.canvas.add(store.room_height_line);
  // var height_measurement = Math.abs(obj.oCoords.tl.y - obj.oCoords.bl.y);
  var height_measurement = Math.abs(h1 - h2);

  var unit_disp = "mts";
  var x = document.getElementById("units").selectedIndex;
  if ($scope.unit.value == "meters") {
    height_measurement = (height_measurement * 2.54) / 100;
    unit_disp = "inches";
  } else if ($scope.unit.value == "cm") {
    height_measurement = ((height_measurement * 2.54) / 100) * 100;
    unit_disp = "cm";
  } else if ($scope.unit.value == "mm") {
    height_measurement = ((height_measurement * 2.54) / 100) * 1000;
    unit_disp = "mm";
  }

  height_measurement =
    Math.round(height_measurement * 1000 * store.tape_scale_factor) / 1000;

  store.height_text = new fabric.Textbox(height_measurement.toString(), {
    id: "tape_width_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.bl.x + 20) / 2 + 2,
    top: (obj.oCoords.tl.y + obj.oCoords.bl.y) / 2 + 2,
    selectable: true,
    editable: true,
    fontSize: 12,
    borderColor: "black",
    cornerSize: 2,
    hasRotatingPoint: false,
    hoverCursor: "text",
    transparentCorners: false,
  });
  store.canvas.add(store.height_text);
  store.height_text_static = new fabric.Textbox(unit_disp, {
    id: "tape_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.bl.x + 20) / 2 + 4,
    top: (obj.oCoords.tl.y + obj.oCoords.bl.y) / 2 + 14,
    selectable: false,
    fontSize: 12,
  });
  store.canvas.add(store.height_text_static);
  //console.log(width_measurement, height_measurement);
}

function display_room_height(obj) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;

  store.room_height_line = new fabric.Line(
    [
      obj.oCoords.tl.x + 10,
      obj.oCoords.tl.y,
      obj.oCoords.bl.x + 10,
      obj.oCoords.bl.y,
    ],
    {
      stroke: "green",
    }
  );
  store.canvas.add(store.room_height_line);
  var height_measurement = Math.abs(obj.oCoords.tl.y - obj.oCoords.bl.y);

  var unit_disp = "mts";
  var x = document.getElementById("units").selectedIndex;
  if ($scope.unit.value == "meters") {
    height_measurement = (height_measurement * 2.54) / 100;
    unit_disp = "inches";
  }
  height_measurement =
    Math.round(height_measurement * 100 * store.tape_scale_factor) / 100;

  store.height_text = new fabric.Textbox(height_measurement.toString(), {
    id: "tape_width_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.bl.x + 20) / 2 + 2,
    top: (obj.oCoords.tl.y + obj.oCoords.bl.y) / 2 + 2,
    selectable: true,
    editable: true,
    fontSize: 10,
    borderColor: "black",
    cornerSize: 2,
    hasRotatingPoint: false,
    hoverCursor: "text",
    transparentCorners: false,
  });
  store.canvas.add(store.height_text);
  store.height_text_static = new fabric.Textbox("mts", {
    id: "tape_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.bl.x + 20) / 2 + 4,
    top: (obj.oCoords.tl.y + obj.oCoords.bl.y) / 2 + 14,
    selectable: false,
    fontSize: 10,
  });
  store.canvas.add(store.height_text_static);
  room_measurement_flag = true;
}

const displayEdgeDimension = (edge) => {
  let $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  let dimensionalLine = linesParallelToLine(edge, 10);
  dimensionalLine = dimensionalLine[0];
  store.edgeDimensionLine = new fabric.Line(
    [
      dimensionalLine[0].x,
      dimensionalLine[0].y,
      dimensionalLine[1].x,
      dimensionalLine[1].y,
    ],
    {
      id: "edgedimensionline",
      stroke: "blue",
    }
  );
  store.canvas.add(store.edgeDimensionLine);

  let edgeMeasure = distanceBetweenPoints2D(edge[0], edge[1]);

  let unit_disp = "mts";
  if ($scope.unit.value == "meters") {
    edgeMeasure = (edgeMeasure * 2.54) / 100;
    unit_disp = "mts";
  } else if ($scope.unit.value == "cm") {
    edgeMeasure = ((edgeMeasure * 2.54) / 100) * 100;
    unit_disp = "cm";
  } else if ($scope.unit.value == "mm") {
    edgeMeasure = ((edgeMeasure * 2.54) / 100) * 1000;
    unit_disp = "mm";
  }
  edgeMeasure = Math.round(edgeMeasure * 100 * store.tape_scale_factor) / 100;
  let edgeMeasureWithUnit = edgeMeasure.toString() + unit_disp;

  store.edgeMeasurementText = new fabric.Textbox(edgeMeasureWithUnit, {
    id: "edgedimensiontext",
    fontFamily: "arial black",
    left: (dimensionalLine[0].x + dimensionalLine[1].x) / 2,
    top: (dimensionalLine[0].y + dimensionalLine[1].y) / 2,
    selectable: true,
    editable: true,
    fontSize: 12,
    borderColor: "red",
    cornerSize: 2,
    hasRotatingPoint: false,
    hoverCursor: "text",
    transparentCorners: false,
  });
  store.canvas.add(store.edgeMeasurementText);
};

function updateDimensionText(inputString) {
  if (store.evt_mode == "edit_edge") {
    if (store.child_orientation) {
      if (store.child_orientation === "ns") {
        if (store.height_text) {
          store.height_text.text = inputString;
        }
      }
      if (store.child_orientation === "ew") {
        if (store.width_text) {
          store.width_text.text = inputString;
        }
      }
      if (store.child_orientation === "ne") {
        var inputString1 = inputString.split(",")[0];
        var inputString2 = inputString.split(",")[1];
        if (store.width_text) {
          store.width_text.text = inputString1;
        }
        if (store.height_text) {
          store.height_text.text = inputString2;
        }
      }
      if (store.child_orientation === "nw") {
        var inputString1 = inputString.split(",")[0];
        var inputString2 = inputString.split(",")[1];
        if (store.width_text) {
          store.width_text.text = inputString1;
        }
        if (store.height_text) {
          store.height_text.text = inputString2;
        }
      }
    }
  }
  if (store.evt_mode == "edit_polygon") {
    //removeAllGripsWithId('edgedimensiontext');
    store.edgeMeasurementText.text = inputString;
  }
  store.canvas.renderAll();
}

function drawDimension(obj) {
  //console.log("Drawing tape", obj);
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  let points = store.angular.copy(obj.points);
  let mean = points[0];
  mean.x = 0;
  mean.y = 0;
  let min = store.angular.copy(points[0]);
  //console.log("Min", min);
  let max = store.angular.copy(points[0]);
  for (let i = 0; i < points.length; i++) {
    mean.x += store.angular.copy(points[i].x);
    mean.y += store.angular.copy(points[i].y);
    if (min.x > points[i].x) min.x = store.angular.copy(points[i].x);
    if (min.y > points[i].y) min.y = store.angular.copy(points[i].y);
    if (max.x < points[i].x) max.x = store.angular.copy(points[i].x);
    if (max.y < points[i].y) max.y = store.angular.copy(points[i].y);
  }
  let minPt = getWorldCoords(min, mean, min, obj);
  let maxPt = getWorldCoords(max, mean, min, obj);

  store.room_width_line = new fabric.Line(
    [
      obj.oCoords.tl.x,
      obj.oCoords.tl.y + 10,
      obj.oCoords.tl.x + obj.width,
      obj.oCoords.tr.y + 10,
    ],
    {
      stroke: "red",
    }
  );
  store.room_width_line.left = obj.left;
  store.room_width_line.top = obj.top + 10;
  store.canvas.add(store.room_width_line);
  store.room_height_line = new fabric.Line(
    [
      obj.oCoords.tl.x + 10,
      obj.oCoords.tl.y,
      obj.oCoords.bl.x + 10,
      obj.oCoords.tl.y + obj.height,
    ],
    {
      stroke: "green",
    }
  );
  store.room_height_line.left = obj.left + 10;
  store.room_height_line.top = obj.top;
  store.canvas.add(store.room_height_line);

  // (tape_scale_factor);
  // var width_measurement = Math.abs(obj.oCoords.tl.x - obj.oCoords.tr.x);
  // var height_measurement = Math.abs(obj.oCoords.tl.y - obj.oCoords.bl.y);

  // var width_measurement = Math.abs(max.x - min.x);
  // var height_measurement = Math.abs(max.y - min.y);
  var width_measurement = obj.width; // + obj.strokeWidth;
  var height_measurement = obj.height; // + obj.strokeWidth;

  var unit_disp = "mts";
  var x = document.getElementById("units").selectedIndex;
  //console.log(width_measurement);
  if ($scope.unit.value == "meters") {
    width_measurement = (width_measurement * 2.54) / 100;
    height_measurement = (height_measurement * 2.54) / 100;
    unit_disp = "mts";
  } else if ($scope.unit.value == "cm") {
    width_measurement = ((width_measurement * 2.54) / 100) * 100;
    height_measurement = ((height_measurement * 2.54) / 100) * 100;
    unit_disp = "cm";
  } else if ($scope.unit.value == "mm") {
    width_measurement = ((width_measurement * 2.54) / 100) * 1000;
    height_measurement = ((height_measurement * 2.54) / 100) * 1000;
    unit_disp = "mm";
  } else if ($scope.unit.value == "inches") {
    width_measurement = width_measurement;
    height_measurement = height_measurement;
    unit_disp = "inches";
  }
  width_measurement =
    Math.round(width_measurement * 100 * store.tape_scale_factor) / 100;
  height_measurement =
    Math.round(height_measurement * 100 * store.tape_scale_factor) / 100;

  store.width_text = new fabric.Textbox(width_measurement.toString(), {
    id: "tape_width_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.tr.x) / 2 + 2,
    top: (obj.oCoords.tl.y + obj.oCoords.tr.y + 20) / 2 + 2,
    selectable: true,
    editable: true,
    fontSize: 12,
    borderColor: "black",
    cornerSize: 2,
    hasRotatingPoint: false,
    hoverCursor: "text",
    transparentCorners: false,
  });
  store.canvas.add(store.width_text);
  store.width_text_static = new fabric.Textbox(unit_disp, {
    id: "tape_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.tr.x) / 2 + 4,
    top: (obj.oCoords.tl.y + obj.oCoords.tr.y + 20) / 2 + 14,
    selectable: false,
    fontSize: 12,
  });
  store.canvas.add(store.width_text_static);

  store.height_text = new fabric.Textbox(height_measurement.toString(), {
    id: "tape_width_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.bl.x + 20) / 2 + 2,
    top: (obj.oCoords.tl.y + obj.oCoords.bl.y) / 2 + 2,
    selectable: true,
    editable: true,
    fontSize: 12,
    borderColor: "black",
    cornerSize: 2,
    hasRotatingPoint: false,
    hoverCursor: "text",
    transparentCorners: false,
  });
  store.canvas.add(store.height_text);

  store.height_text_static = new fabric.Textbox(unit_disp, {
    id: "tape_measure",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.bl.x + 20) / 2 + 4,
    top: (obj.oCoords.tl.y + obj.oCoords.bl.y) / 2 + 14,
    selectable: false,
    fontSize: 12,
  });
  store.canvas.add(store.height_text_static);

  store.width_text.top = obj.top + 14;
  store.width_text_static.top = obj.top + 28;
  store.height_text.left = obj.left + 14;
  store.height_text_static.left = obj.left + 14;

  var n = obj.fill.slice(5, obj.fill.length - 2).split(",");
  let fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  var n2 = 1.37;
  for (let p = 0; n2 % 1 != 0; p++) {
    n2 = (n[0] - 5 + 255 * p) / 53;
  }

  let room_type_text = $scope.searchText[n2 - 1];
  // (room_types[n2]);
  store.object_name = new fabric.Textbox(room_type_text, {
    id: "room_type_display",
    fontFamily: "arial black",
    left: (obj.oCoords.tl.x + obj.oCoords.tr.x) / 2,
    top: (obj.oCoords.tl.y + obj.oCoords.br.y) / 2,
    selectable: false,
    fontSize: 10,
  });
  store.canvas.add(store.object_name);

  room_measurement_flag = true;
}

function getWorldCoords(pt, mean, min, obj) {
  if (pt.x > mean.x)
    pt.x =
      obj.left + (pt.x + (obj.width * (obj.scaleX - 1)) / obj.scaleX - min.x);
  else pt.x = obj.left + (pt.x - min.x);
  if (pt.y > mean.y)
    pt.y =
      obj.top + (pt.y + (obj.height * (obj.scaleY - 1)) / obj.scaleY - min.y);
  else pt.y = obj.top + (pt.y - min.y);
  return pt;
}

function moveEdgeKey(inputString) {
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;

  if (store.evt_mode == "edit_edge" && store.mouse_down) {
    if (store.objSelected.hasOwnProperty("children")) {
      if (store.child_number !== undefined) {
        //if (Math.abs(e.e.movementX) > 100 || Math.abs(e.e.movementY) > 100) return;
        let obj = store.objSelected.children;
        let items = obj._objects;
        let idx1 = items[store.child_number].ptIdx1;
        let idx2 = items[store.child_number].ptIdx2;
        let pt1 = store.objSelected.points[idx1];
        let pt2 = store.objSelected.points[idx2];
        store.drag_points = store.objSelected.points;
        var orientation;
        let signX = Math.sign(pt1.x - items[store.child_number].pt1.x);
        let signY = Math.sign(pt1.y - items[store.child_number].pt1.y);
        let prevPt1 = items[store.child_number].pt1;
        let prevPt2 = items[store.child_number].pt2;
        //console.log(unit_scale);
        var unit_scale_factor;
        if ($scope.unit.value == "meters") {
          store.unit_scale_factor = 1;
        } else if ($scope.unit.value == "cm") {
          store.unit_scale_factor = 100;
        } else if ($scope.unit.value == "mm") {
          store.unit_scale_factor = 1000;
        } else if ($scope.unit.value == "inches") {
          store.unit_scale_factor = 1 / 2.54;
        }
        if (inputString.indexOf(",") == -1) {
          var movementX =
            (inputString /
              store.tape_scale_factor /
              store.unit_scale /
              store.unit_scale_factor) *
            10 *
            signX;
          var movementY =
            (inputString /
              store.tape_scale_factor /
              store.unit_scale /
              store.unit_scale_factor) *
            10 *
            signY;
        } else {
          var movementX =
            (inputString.split(",")[0] /
              store.tape_scale_factor /
              store.unit_scale /
              store.unit_scale_factor) *
            10 *
            signX;
          var movementY =
            (inputString.split(",")[1] /
              store.tape_scale_factor /
              store.unit_scale /
              store.unit_scale_factor) *
            10 *
            signY;
        }

        if (store.child_orientation === "ns") {
          pt1.y = prevPt1.y + movementY;
          pt2.y = prevPt2.y + movementY;

          items[store.child_number].top =
            items[store.child_number].top + movementY;
          items[store.child_number].ptIdx1 = idx1;
          items[store.child_number].ptIdx2 = idx2;
          orientation = "y";
        }
        if (store.child_orientation === "ew") {
          pt1.x = prevPt1.x + movementX;
          pt2.x = prevPt2.x + movementX;
          items[store.child_number].left =
            items[store.child_number].left + movementX;
          items[store.child_number].ptIdx1 = idx1;
          items[store.child_number].ptIdx2 = idx2;
          orientation = "x";
        }
        if (store.child_orientation === "ne") {
          pt1.x = prevPt1.x + movementX * Math.cos(store.angle);
          pt2.x = prevPt2.x + movementX * Math.cos(store.angle);
          pt1.y = prevPt1.y + movementY * Math.sin(store.angle);
          pt2.y = prevPt2.y + movementY * Math.sin(store.angle);
          items[store.child_number].left =
            items[store.child_number].left + movementX;
          items[store.child_number].top =
            items[store.child_number].top + movementY;
          items[store.child_number].ptIdx1 = idx1;
          items[store.child_number].ptIdx2 = idx2;
          orientation = "a";
        }
        if (store.child_orientation === "nw") {
          pt1.x = prevPt1.x + movementX * Math.cos(store.angle);
          pt2.x = prevPt2.x + movementX * Math.cos(store.angle);
          pt1.y = prevPt1.y + movementY * Math.sin(store.angle);
          pt2.y = prevPt2.y + movementY * Math.sin(store.angle);
          items[store.child_number].left =
            items[store.child_number].left + movementX;
          items[store.child_number].top =
            items[store.child_number].top + movementY;
          items[store.child_number].ptIdx1 = idx1;
          items[store.child_number].ptIdx2 = idx2;
          orientation = "a";
        }

        store.drag_points[idx1].x = pt1.x;
        store.drag_points[idx1].y = pt1.y;
        store.drag_points[idx2].x = pt2.x;
        store.drag_points[idx2].y = pt2.y;

        store.objSelected.setCoords();
        var objSelected1 = draw_interior_drag(
          store.drag_points,
          store.objSelected,
          getFillColor(store.objSelected)
        );
        removeRoomDimension(store.objSelected);
        store.canvas.remove(store.objSelected);
        store.canvas.renderAll();
        store.mouse_mode = "drag";
        removeAllGripsWithId("children");
        updateModifications(true);
        store.mouse_down = false;
        addObjectEdgeGrips(objSelected1);
      }
    }
  }
}

const snapAddVertexToEdge = (point) => {
  let nearbyThreshold = 10;
  let allObjects = store.canvas.getObjects();
  let rooms = allObjects.filter(function (obj) {
    return obj.id == "rooms";
  });

  rooms = rooms.filter(function (room) {
    return room.hasOwnProperty("points");
  });
  let projPt = null;

  for (let k = 0; k < rooms.length; k++) {
    for (let l = 0; l < rooms[k].points.length; l++) {
      let edgeHead = rooms[k].points[l];
      let edgeTailIndex = (l + 1) % rooms[k].points.length;
      let edgeTail = rooms[k].points[edgeTailIndex];
      if (checkIfNearEdge2D(point, edgeHead, edgeTail, nearbyThreshold)) {
        projPt = projectionOfPointOnLine2D(point, edgeHead, edgeTail);
        if (checkIfPointIsBetween(projPt, edgeHead, edgeTail)) {
          return {
            point: projPt,
            room: rooms[k],
            roomIndex: k,
            afterPoint: edgeHead,
            beforePoint: edgeTail,
            afterPointIndex: edgeTailIndex,
          };
        }
      }
    }
  }

  return null;
};

const nicelySnapNearestEdge = (point) => {
  let allEdges = snapNearestEdge(point);
  if (allEdges.length) {
    for (let i = 0; i < allEdges.length; i++) {
      let projPt = projectionOfPointOnLine2D(
        point,
        allEdges[i].edge[0],
        allEdges[i].edge[1]
      );
      if (
        checkIfPointIsBetween(projPt, allEdges[i].edge[0], allEdges[i].edge[1])
      ) {
        return {
          edge: allEdges[i].edge,
          edgeIndex: allEdges[i].edgeIndex,
          room: allEdges[i].room,
          roomIndex: allEdges[i].roomIndex,
          projPt: projPt,
        };
      }
    }
  }
  return null;
};

const snapNearestEdge = (point) => {
  let nearbyThreshold = 10;
  let allObjects = store.canvas.getObjects();
  let rooms = allObjects.filter(function (obj) {
    return obj.id == "rooms" && obj.hasOwnProperty("points");
  });
  for (let k = 0; k < rooms.length; k++) {
    for (let l = 0; l < rooms[k].points.length; l++) {
      let edgeHead = rooms[k].points[l];
      let edgeTailIndex = (l + 1) % rooms[k].points.length;
      let edgeTail = rooms[k].points[edgeTailIndex];
      if (checkIfNearEdge2D(point, edgeHead, edgeTail, nearbyThreshold)) {
        let projPt = projectionOfPointOnLine2D(point, edgeHead, edgeTail);
        if (checkIfPointIsBetween(projPt, edgeHead, edgeTail)) {
          return {
            edge: [edgeHead, edgeTail],
            edgeIndex: [l, edgeTailIndex],
            room: rooms[k],
            roomIndex: k,
            projPt: projPt,
          };
        }
      }
    }
  }
  return null;
};

const changePolygonShape = (roomObject, edge, newDimension) => {
  let $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  let unit_scale_factor;
  if ($scope.unit.value == "meters") {
    store.unit_scale_factor = 1;
  } else if ($scope.unit.value == "cm") {
    store.unit_scale_factor = 100;
  } else if ($scope.unit.value == "mm") {
    store.unit_scale_factor = 1000;
  } else if ($scope.unit.value == "inches") {
    store.unit_scale_factor = 1 / 2.54;
  }
  let fDimension = parseFloat(newDimension);
  let dimensionForFabric =
    (fDimension /
      store.tape_scale_factor /
      store.unit_scale /
      store.unit_scale_factor) *
    10;
  let allObjects = store.canvas.getObjects();
  let allRooms = allObjects.filter(function (obj) {
    return obj.id == "rooms" && obj.hasOwnProperty("points");
  });
  let newPolygon = [];
  let returnEdge = null;
  for (let i = 0; i < allRooms.length; i++) {
    if (allRooms[i] == roomObject) {
      let oldPolygon = allRooms[i].points;
      for (let j = 0; j < oldPolygon.length; j++) {
        let prevIndex = (j - 1) % oldPolygon.length;
        if (j == 0) {
          prevIndex = oldPolygon.length - 1;
        }
        if (oldPolygon[j] == edge[1]) {
          //let currentDistance = distanceBetweenPoints2D(oldPolygon[prevIndex], oldPolygon[j]);
          let secondDistance = distanceBetweenPoints2D(
            oldPolygon[j],
            oldPolygon[(j + 1) % oldPolygon.length]
          );
          let currentSlope = slopeOfLine([
            oldPolygon[prevIndex],
            oldPolygon[j],
          ]);
          let secondSlope = slopeOfLine([
            oldPolygon[j],
            oldPolygon[(j + 1) % oldPolygon.length],
          ]);
          let yDirection = Math.sign(oldPolygon[j].y - oldPolygon[prevIndex].y);
          // if(yDirection != 0) {
          //     yDirection = Math.abs(yDirection) / yDirection;
          // }
          let xDirection = Math.sign(oldPolygon[j].x - oldPolygon[prevIndex].x);
          // if(xDirection != 0) {
          //     xDirection = Math.abs(xDirection) / xDirection;
          // }

          let pointsWithGivenEdgeSlope = lineWithSlopeAndAtDistanceFromPoint(
            oldPolygon[prevIndex],
            currentSlope,
            dimensionForFabric
          );
          let new1xDirection = Math.sign(
            pointsWithGivenEdgeSlope[0].x - oldPolygon[prevIndex].x
          );
          // if(new1xDirection != 0) {
          //     new1xDirection = Math.abs(new1xDirection) / new1xDirection;
          // }
          let new1yDirection = Math.sign(
            pointsWithGivenEdgeSlope[0].y - oldPolygon[prevIndex].y
          );
          // if(new1yDirection != 0) {
          //     new1yDirection = Math.abs(new1yDirection) / new1yDirection;
          // }
          let new2xDirection = Math.sign(
            pointsWithGivenEdgeSlope[1].x - oldPolygon[prevIndex].x
          );
          // if(new2xDirection != 0) {
          //     new2xDirection = Math.abs(new2xDirection) / new2xDirection;
          // }
          let new2yDirection = Math.sign(
            pointsWithGivenEdgeSlope[1].y - oldPolygon[prevIndex].y
          );
          // if(new2yDirection != 0) {
          //     new2yDirection = Math.abs(new2yDirection) / new2yDirection;
          // }
          let newPoint = null;
          let newPoint2 = null;

          if (xDirection == new1xDirection && yDirection == new1yDirection) {
            newPoint = pointsWithGivenEdgeSlope[0];
          } else {
            if (xDirection == new2xDirection && yDirection == new2yDirection) {
              newPoint = pointsWithGivenEdgeSlope[1];
            }
          }
          newPolygon.push(newPoint);
          returnEdge = [oldPolygon[prevIndex], newPoint];
          let pointsWithGivenEdgeSlope2 = lineWithSlopeAndAtDistanceFromPoint(
            newPoint,
            secondSlope,
            secondDistance
          );
          if (
            distanceBetweenPoints2D(
              oldPolygon[(j + 1) % oldPolygon.length],
              pointsWithGivenEdgeSlope2[0]
            ) <
            distanceBetweenPoints2D(
              oldPolygon[(j + 1) % oldPolygon.length],
              pointsWithGivenEdgeSlope2[1]
            )
          ) {
            newPoint2 = pointsWithGivenEdgeSlope2[0];
          } else {
            newPoint2 = pointsWithGivenEdgeSlope2[1];
          }
          newPolygon.push(newPoint2);
        } else if (oldPolygon[prevIndex] == edge[1]) {
        } else {
          newPolygon.push(allRooms[i].points[j]);
        }
      }
      allRooms[i].points = newPolygon;
      //allRooms[i].setCoords();
    }
  }
  updateModifications(true);
  reDrawAllRoomsAfterTransform();
  store.canvas.renderAll();
  return returnEdge;
};

const findNearestVertex = (point, threshold) => {
  let allObjects = store.canvas.getObjects();
  let allRooms = allObjects.filter(function (obj) {
    return obj.id == "rooms" && obj.hasOwnProperty("points");
  });
  for (let i = 0; i < allRooms.length; i++) {
    for (let j = 0; j < allRooms[i].points.length; j++) {
      if (distanceBetweenPoints2D(point, allRooms[i].points[j]) < threshold) {
        return {
          point: allRooms[i].points[j],
          room: allRooms[i],
          roomIndex: i,
        };
      }
    }
  }
  return null;
};

const checkIfPointIsBetween = (checkingPoint, endPoint1, endPoint2) => {
  if (isBetween(endPoint1.x, checkingPoint.x, endPoint2.x)) {
    if (isBetween(endPoint1.y, checkingPoint.y, endPoint2.y)) {
      return true;
    }
  }
  return false;
};

const isBetween = (number1, number2, number3) => {
  if (number2 >= number1) {
    if (number2 <= number3) {
      return true;
    }
  } else if (number2 >= number3) {
    if (number2 <= number1) {
      return true;
    }
  } else {
    return false;
  }
};

const checkIfNearEdge2D = (pt, edgePt1, edgePt2, threshold) => {
  if (distanceBetweenPointAndLine2D(pt, edgePt1, edgePt2) < threshold) {
    return true;
  }
  return false;
};

const projectionOfPointOnLine2D = (pt, edgePt1, edgePt2) => {
  let line = [edgePt2.x - edgePt1.x, edgePt2.y - edgePt1.y];
  let lineMod = (line[0] ** 2 + line[1] ** 2) ** 0.5;
  let projectionOfLine = [line[0] / lineMod, line[1] / lineMod];
  let vectorFromEdgePoint1ToPoint = [pt.x - edgePt1.x, pt.y - edgePt1.y];
  let t = math.dot(vectorFromEdgePoint1ToPoint, projectionOfLine);
  let scaledT = [projectionOfLine[0] * t, projectionOfLine[1] * t];
  let finalPoint = [edgePt1.x + scaledT[0], edgePt1.y + scaledT[1]];
  return { x: finalPoint[0], y: finalPoint[1] };
};

const distanceBetweenPoints3D = (pt1InArrayType, pt2InArrayType) => {
  let term1 = (pt2InArrayType[0] - pt1InArrayType[0]) ** 2;
  let term2 = (pt2InArrayType[1] - pt1InArrayType[1]) ** 2;
  let term3 = (pt2InArrayType[2] - pt1InArrayType[2]) ** 2;
  return (term1 + term2 + term3) ** 0.5;
};

const distanceBetweenPointAndLine2D = (pt, edgePt1, edgePt2) => {
  let vector1 = [pt.x - edgePt1.x, pt.y - edgePt1.y, 0];
  let vector2 = [pt.x - edgePt2.x, pt.y - edgePt2.y, 0];
  let crossVector = math.cross(vector1, vector2);
  let numerator =
    (crossVector[0] ** 2 + crossVector[1] ** 2 + crossVector[2] ** 2) ** 0.5;
  let line = [edgePt2.x - edgePt1.x, edgePt2.y - edgePt1.y];
  let denominator = (line[0] ** 2 + line[1] ** 2) ** 0.5;
  return numerator / denominator;
};

const distanceBetweenPoints2D = (pt1, pt2) => {
  return ((pt2.x - pt1.x) ** 2 + (pt2.y - pt1.y) ** 2) ** 0.5;
};

//This transformPoints function is very jugaadu, especially:
//p.x - obj.minX - obj.width/2 - 1
//There should not be a need to do minus one, but apparently it solves a lot of headache.
//The actual formula is supposed to be: p.x - obj.minX - obj.width/2
//Read theory to understand "why".
const transformPoints = (obj) => {
  obj.setCoords();
  let matrix = obj.calcTransformMatrix();
  let transformedPoints = obj
    .get("points")
    .map(function (p) {
      return new fabric.Point(
        p.x - obj.minX - obj.width / 2 - 1,
        p.y - obj.minY - obj.height / 2 - 1
      );
    })
    .map(function (p) {
      return fabric.util.transformPoint(p, matrix);
    });

  return transformedPoints;
};

const transformPath = (obj) => {
  obj.setCoords();
  let matrix = obj.calcTransformMatrix();
  let transformedPoints = obj
    .get("path")
    .map(function (p) {
      if (p[0] == "M" || p[0] == "L") {
        let point = new fabric.Point(p[1], p[2]);
        let transformed = new fabric.Point(
          point.x - obj.minX - obj.width / 2 - store.scale_factor - 0.25,
          point.y - obj.minY - obj.height / 2 - store.scale_factor - 0.25
        );
        return [p[0], transformed.x, transformed.y];
      }
      if (p[0] == "Q") {
        let point1 = new fabric.Point(p[1], p[2]);
        let point2 = new fabric.Point(p[3], p[4]);
        let transformed1 = new fabric.Point(
          point1.x - obj.minX - obj.width / 2 - store.scale_factor - 0.25,
          point1.y - obj.minY - obj.height / 2 - store.scale_factor - 0.25
        );
        let transformed2 = new fabric.Point(
          point2.x - obj.minX - obj.width / 2 - store.scale_factor - 0.25,
          point2.y - obj.minY - obj.height / 2 - store.scale_factor - 0.25
        );
        return [
          p[0],
          transformed1.x,
          transformed1.y,
          transformed2.x,
          transformed2.y,
        ];
      }
    })
    .map(function (p) {
      if (p[0] == "M" || p[0] == "L") {
        let point = new fabric.Point(p[1], p[2]);
        let transformed = fabric.util.transformPoint(point, matrix);
        return [p[0], transformed.x, transformed.y];
      }
      if (p[0] == "Q") {
        let point1 = new fabric.Point(p[1], p[2]);
        let point2 = new fabric.Point(p[3], p[4]);
        let transformed1 = fabric.util.transformPoint(point1, matrix);
        let transformed2 = fabric.util.transformPoint(point2, matrix);
        return [
          p[0],
          transformed1.x,
          transformed1.y,
          transformed2.x,
          transformed2.y,
        ];
      }
    });
  return transformedPoints;
};

const getFillColor = (obj) => {
  let n = obj.fill.slice(5, obj.fill.length - 2).split(",");
  let n2 = 1.37;
  for (let p = 0; n2 % 1 != 0; p++) {
    n2 = (n[0] - 5 + 255 * p) / 53;
  }
  let fillColor =
    "rgba(" +
    ((n2 * 53 + 5) % 255) +
    "," +
    ((n2 * 153 + 2) % 255) +
    "," +
    ((n2 * 34 + 3) % 255) +
    ",0.3)";
  return fillColor;
};

const doesPolygonJustHave90Angles = (pointArray) => {
  for (let i = 0; i < pointArray.length; i++) {
    if (
      angleBetweenEdges(
        [
          pointArray[i % pointArray.length],
          pointArray[(i + 1) % pointArray.length],
        ],
        [
          pointArray[(i + 1) % pointArray.length],
          pointArray[(i + 2) % pointArray.length],
        ]
      ) == 90
    ) {
      //console.log("ANGLE IS 90");
    } else {
      return false;
      //console.log(i, "is not 90, it is", angleBetweenEdges([pointArray[(i) % pointArray.length], pointArray[(i + 1) % pointArray.length]], [pointArray[(i + 1) % pointArray.length], pointArray[(i + 2) % pointArray.length]]));
    }
  }
  return true;
};

const angleBetweenEdges = (edge1, edge2) => {
  return (angleBetweenEdgesInRadians(edge1, edge2) * 180) / Math.PI;
};

const angleBetweenEdgesInRadians = (edge1, edge2) => {
  let vector1 = [edge1[1].x - edge1[0].x, edge1[1].y - edge1[0].y];
  let vector2 = [edge2[1].x - edge2[0].x, edge2[1].y - edge2[0].y];

  let dotProduct = math.dot(vector1, vector2);
  let normalizedProduct = null;
  if (dotProduct != 0) {
    normalizedProduct = dotProduct / (modVector(vector1) * modVector(vector2));
  } else {
    normalizedProduct = dotProduct;
  }
  return Math.acos(normalizedProduct);
};

const modVector = (vector) => {
  return (vector[0] ** 2 + vector[1] ** 2) ** 0.5;
};

const slopeOfLine = (edge) => {
  //console.log("EDGE", edge);
  if (edge[1].x - edge[0].x == 0) {
    return "inf";
  }
  return (edge[1].y - edge[0].y) / (edge[1].x - edge[0].x);
};

/**
 *
 * @param point - the fixed point through which you want the line to pass through
 * @param slope - the slope of the line
 * @param distance - the distance at which the required point is situated
 * @returns {fabric.Point[]} - returns two points with equal distance from the @param point, which are in two opposite directions from the @param point
 * Example:
 *
 *      X[0]
 *
 *              IP
 *
 *                         X[1]
 *
 *
 * Legend:
 * X[0] and X[1] are returned points lying on the same line with equal distance from IP, the input point.
 */
const lineWithSlopeAndAtDistanceFromPoint = (point, slope, distance) => {
  if (slope == "inf") {
    let x2Plus = point.x;
    let x2Minus = point.x;
    let y2Plus = point.y + distance;
    let y2Minus = point.y - distance;
    let firstPoint = new fabric.Point(x2Plus, y2Plus);
    let secondPoint = new fabric.Point(x2Minus, y2Minus);
    return [firstPoint, secondPoint];
  }

  let c = point.y - slope * point.x; // y = mx + c
  let x2Plus = point.x + Math.sqrt(distance ** 2 / (slope ** 2 + 1));
  let x2Minus = point.x - Math.sqrt(distance ** 2 / (slope ** 2 + 1));
  let y2Plus = slope * x2Plus + c;
  let y2Minus = slope * x2Minus + c;
  let firstPoint = new fabric.Point(x2Plus, y2Plus);
  let secondPoint = new fabric.Point(x2Minus, y2Minus);
  return [firstPoint, secondPoint];
};

const linesParallelToLine = (givenLine, atDistance) => {
  let slopeOfGivenLine = slopeOfLine(givenLine);
  let lengthOfLine = distanceBetweenPoints2D(givenLine[0], givenLine[1]);
  let slopeOfNormal = null;
  if (slopeOfGivenLine == "inf") {
    slopeOfNormal = 0;
  } else if (slopeOfGivenLine == 0) {
    slopeOfNormal = "inf";
  } else {
    slopeOfNormal = -1 / slopeOfGivenLine;
  }
  let normalLines = lineWithSlopeAndAtDistanceFromPoint(
    givenLine[0],
    slopeOfNormal,
    atDistance
  );
  let upperLine = lineWithSlopeAndAtDistanceFromPoint(
    normalLines[0],
    slopeOfGivenLine,
    lengthOfLine
  );
  let upperLineActual = null;
  if (
    distanceBetweenPoints2D(givenLine[1], upperLine[0]) <
    distanceBetweenPoints2D(givenLine[1], upperLine[1])
  ) {
    upperLineActual = upperLine[0];
  } else {
    upperLineActual = upperLine[1];
  }

  let lowerLine = lineWithSlopeAndAtDistanceFromPoint(
    normalLines[1],
    slopeOfGivenLine,
    lengthOfLine
  );
  let lowerLineActual = null;
  if (
    distanceBetweenPoints2D(givenLine[1], lowerLine[0]) <
    distanceBetweenPoints2D(givenLine[1], lowerLine[1])
  ) {
    lowerLineActual = lowerLine[0];
  } else {
    lowerLineActual = lowerLine[1];
  }

  return [
    [normalLines[0], upperLineActual],
    [normalLines[1], lowerLineActual],
  ];
};
export {
  removeRoomDimension,
  displayRoomDimension,
  display_room_height,
  displayEdgeDimension,
  updateDimensionText,
  drawDimension,
  getWorldCoords,
  moveEdgeKey,
  snapAddVertexToEdge,
  nicelySnapNearestEdge,
  snapNearestEdge,
  changePolygonShape,
  findNearestVertex,
  checkIfPointIsBetween,
  isBetween,
  checkIfNearEdge2D,
  projectionOfPointOnLine2D,
  distanceBetweenPoints3D,
  distanceBetweenPointAndLine2D,
  distanceBetweenPoints2D,
  transformPoints,
  transformPath,
  getFillColor,
  doesPolygonJustHave90Angles,
  angleBetweenEdges,
  angleBetweenEdgesInRadians,
  modVector,
  slopeOfLine,
  lineWithSlopeAndAtDistanceFromPoint,
  linesParallelToLine,
};
