"use strict";
/*jshint esversion: 6 */
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js"
// 'use strict';
// /*jshint esversion: 6 */
//
// var clipOperations = (function () {
//     "use strict";
//     let startingPointFace = null;
//     let clipPlane = null;
//     let downClick = 0;
//     let pickClipPlane = null;
//     var pointerDragBehavior = null;
//     // let getCommandLogic = function(){
//     //     return {
//     //         execute : createClipPlane,
//     //         unexecute : removeClipPlane
//     //     }
//     // };
//     return {
//         // getCommandLogic,
//         onPointerDown: function (evt) {
//             //    debugger;
//             console.log("pointer down");
//             // store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, 1);
//             // console.log("clip plane-->",scene.clipPlane);
//             if (clipPlane) {
//                 console.log('ClipPlane-Normal->', clipPlane.normal);
//                 let pickInfo1 = store.newScene.pick(newScene.pointerX, store.newScene.pointerY, function (mesh) {
//                     return mesh.name == 'clippingPlane'
//                 });
//                 if (pickInfo1.hit) {
//                     drawOutline(clipPlane);
//                         faceVertices = getFaceVerticesFromFacetID(pickInfo1.faceId, pickInfo1.pickedMesh);
//             // faceVertices = getFaceVerticesFromFacetID(pickInfo.faceId, pickInfo.pickedMesh, BABYLON.Space.WORLD);
//                     store.scene.getMeshByName('clippingPlane').visibility = 0;
//                    // store.newScene.activeCamera.detachControl(canvas);
//                     pointerDragBehavior = new BABYLON.PointerDragBehavior({dragAxis: new BABYLON.Vector3(0, 0, 1)});
//                     pointerDragBehavior.useObjectOrienationForDragging = true;
//                     pointerDragBehavior.updateDragPlane = false;
//                     //
//                     //pickClipPlane = pickInfo1.pickedMesh;
//
//                     pointerDragBehavior.onDragStartObservable.add((event) => {
//                         console.log("dragStart");
//                         console.log(event);
//                     })
//                     pointerDragBehavior.onDragObservable.add((event) => {
//                         store.scene.clipPlane.d = -clipPlane._boundingInfo.boundingBox.minimumWorld.x
//                     })
//                     pointerDragBehavior.onDragEndObservable.add((event) => {
//                         console.log("dragEnd");
//                         store.scene.clipPlane.d = -clipPlane._boundingInfo.boundingBox.minimumWorld.x
//                         console.log(event);
//                     })
//                     //scene.clipPlane.addBehavior(pointerDragBehavior);
//                     clipPlane.addBehavior(pointerDragBehavior);
//                     rotationGizmoFromClip(clipPlane);
//                 }
//                 downClick = 1;
//             }
//             //check if plane exist
//             // if exist
//             // move clip plane in mouse direction
//         },
//         onPointerUp: function (evt) {
//             console.log("pointer up");
//             // set clip plane position to last left position after pointer down
//             // save state
//         },
//         onPointerMove: function (evt) {
//             console.log("pointer move");
//             // if(clipPlane && downClick == 1){
//             //     clipPlane.dispose();
//             // }
//             // if(pickClipPlane){
//             //    console.log("clipping plane here");
//             //   // drawAxis();
//             //   // showNormals(pickClipPlane);
//             //   //  debugger;
//             // }
//             if(clipPlane){
//
//                 return;
//             }
//             if (!startingPointFace) {
//
//                 var pickInfo = store.newScene.compoundPick(function (mesh) {
//                     return mesh.visibility > 0 && nonDefaultMeshForSnapping(mesh);
//                 });
//                 if (pickInfo.hit) {
//                     meshForMoveFace = pickInfo.pickedMesh;
//                     pickedFace = getFaceVerticesFromFacetID(pickInfo.faceId, pickInfo.pickedMesh, BABYLON.Space.WORLD);
//
//                     if (pickedFace) {
//                         disposeFaceIndicator();
//                         let faceBox = showFaceIndicator(pickedFace, pickInfo.pickedMesh, faceIndicatorName, "redMaterial");
//                         faceBoxInitialPosition = jQuery.extend({}, faceBox.position);
//                         clipPlane = getRefPlaneWithClipPlane(pickedFace, faceBoxInitialPosition);
//                         //newScene.activeCamera.detachControl(canvas);
//                         meshForMoveFace.disableEdgesRendering();
//                         console.log("Clipping Plane-->", clipPlane);
//                     } else {
//                         disposeFaceIndicator();
//                     }
//                 } else {
//                     disposeFaceIndicator();
//                 }
//
//
//                 return;
//             }
//             // if (faceSelected) {
//             //     var current = getScenePositionForFace();
//             //     if (!current) return;
//             //
//             //
//             //     let faceBox = store.scene.getMeshByName(faceIndicatorName);
//             //
//             //     var diff = startingPointFace.subtract(current);
//             //
//             //     let meshOfInterest = faceBox.parentMesh;
//             //     let diffLocal = convertGlobalVector3ToLocal(startingPointFace, meshOfInterest).subtract(
//             //         convertGlobalVector3ToLocal(current, meshOfInterest)
//             //     );
//             //
//             //     DisplayOperation.drawOnMove(faceBoxInitialPosition, faceBox.position);
//             //     var diffDim = faceBox.position.subtract(faceBoxInitialPosition);
//             //     DisplayOperation.displayOnMove(Math.abs(diffDim.length()));
//             //     moveFace(faceBox, diff, diffLocal);
//             //
//             //     // if (store.$scope.isTwoDimension) meshOfInterest.BrepToMesh();
//             //     startingPointFace = current;
//             //
//             //     onSolid(meshOfInterest);
//             //
//             // }
//
//         },
//         createClipPlane: function (evt) {
//         },
//         removeClipPlane: function(){
//             store.scene.clipPlane = null;
//             store.scene.getMeshByName('ClipOutline').dispose();
//             store.scene.getMeshByName('clippingPlane').dispose();
//         }
//
//     }
// })();
//======================================================================================================================


var clipOperations = (function () {
  let sliders = false;
  let header,
    slider,
    plane,
    stencilPlane,
    meshInsideMaterial,
    meshOutsideMaterial,
    meshInside;
  let clipPlaneY;
  let clickPickInfo;
  let stencilPlaneMaterial
  //materials
  // meshOutsideMaterial = new BABYLON.StandardMaterial(
  //   "meshOutsideMaterial",
  //   store.scene
  // );
  // meshOutsideMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
  // meshOutsideMaterial.backFaceCulling = true;
  // meshInsideMaterial = new BABYLON.CustomMaterial("meshInsideMaterial", store.scene);
  // meshInsideMaterial.diffuseColor = new BABYLON.Color3(0.4, 0.4, 0.4);
  // meshInsideMaterial.backFaceCulling = false;
  // meshInsideMaterial.Fragment_Before_FragColor(
  //   "\
  //   if(gl_FrontFacing) discard;\
  //     "
  // );
  // let stencilPlaneMaterial = new BABYLON.StandardMaterial(
  //   "stencilPlaneMaterial",
  //   store.scene
  // );
  // // stencilPlaneMaterial.diffuseColor = new BABYLON.Color3(1.0, 1.0, 0);
  // stencilPlaneMaterial.diffuseColor = new BABYLON.Color3(0.91, 0.11, 0.38);
  // stencilPlaneMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
  // stencilPlaneMaterial.emissiveColor = new BABYLON.Color3(0, 0, 0);
  // stencilPlaneMaterial.ambientColor = new BABYLON.Color3(0, 0, 0);
  const init = () => {
        //materials
    meshOutsideMaterial = new BABYLON.StandardMaterial(
      "meshOutsideMaterial",
      store.scene
    );
    meshOutsideMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
    meshOutsideMaterial.backFaceCulling = true;
    meshInsideMaterial = new BABYLON.CustomMaterial("meshInsideMaterial", store.scene);
    meshInsideMaterial.diffuseColor = new BABYLON.Color3(0.4, 0.4, 0.4);
    meshInsideMaterial.backFaceCulling = false;
    meshInsideMaterial.Fragment_Before_FragColor(
      "\
      if(gl_FrontFacing) discard;\
        "
    );
    stencilPlaneMaterial = new BABYLON.StandardMaterial(
      "stencilPlaneMaterial",
      store.scene
    );
    // stencilPlaneMaterial.diffuseColor = new BABYLON.Color3(1.0, 1.0, 0);
    stencilPlaneMaterial.diffuseColor = new BABYLON.Color3(0.91, 0.11, 0.38);
    stencilPlaneMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    stencilPlaneMaterial.emissiveColor = new BABYLON.Color3(0, 0, 0);
    stencilPlaneMaterial.ambientColor = new BABYLON.Color3(0, 0, 0);
  }
  let resetAll = function () {
    sliders = false;
    clipPlaneY = null;
    clickPickInfo = null;
  };

  let createSliders = function (mesh) {
    // store.engine.resize();
    mesh.disableEdgesRendering();
    store.scene.setRenderingAutoClearDepthStencil(0, false);
    store.scene.setRenderingAutoClearDepthStencil(1, false);
    store.scene.setRenderingAutoClearDepthStencil(2, false);
    //scene.createDefaultCameraOrLight(true, true, true);
    //scene.createDefaultEnvironment();
    let boundingBox = mesh.getBoundingInfo().boundingBox;
    let bboxSizeX = boundingBox.maximum.x - boundingBox.minimum.x;
    let bboxSizeY = boundingBox.maximum.y - boundingBox.minimum.y;
    let bboxSizeZ = boundingBox.maximum.z - boundingBox.minimum.z;
    let bboxMax = Math.max(
      boundingBox.maximum.x,
      boundingBox.maximum.y,
      boundingBox.maximum.z
    );

    meshInside = mesh.clone();
    // mesh.material = meshOutsideMaterial;
    meshInside.material = meshInsideMaterial;

    const sliderWidth = "170px";
    let panel = new BABYLON.GUI.StackPanel();
    panel.width = "200px";
    panel.background = "#ffffff";
    panel.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    panel.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    panel.paddingTop = "-10px";
    panel.paddingLeft = "-10px";
    panel.paddingBottom = "-10px";
    store.advancedTexture.addControl(panel);
    header = new BABYLON.GUI.TextBlock();
    header.text = "Y";
    header.width = "190px";
    header.paddingTop = "10px";
    header.textVerticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_TOP;
    header.textHorizontalAlignment =
      BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    header.color = "green";
    panel.addControl(header);
    slider = new BABYLON.GUI.Slider();
    slider.name = "Y";
    slider.minimum = -mesh._boundingInfo.boundingBox.maximum.y;
    slider.maximum = -mesh._boundingInfo.boundingBox.minimum.y;
    slider.value = -mesh._boundingInfo.boundingBox.maximum.y;
    clipPlaneY = -mesh._boundingInfo.boundingBox.maximum.y;
    slider.paddingTop = "10px";
    slider.height = "30px";
    slider.width = sliderWidth;
    slider.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    slider.color = "green";
    slider.onValueChangedObservable.add(function (value) {
      clipPlaneY = value;
    });

    panel.addControl(slider);
    plane = BABYLON.Mesh.CreatePlane("planeY", 2 * bboxMax, store.scene);
    plane.rotate(new BABYLON.Vector3(1, 0, 0), Math.PI / 2);
    plane.position.set(bboxSizeX, bboxSizeY, bboxSizeZ);
    plane.material = new BABYLON.StandardMaterial("planeMaterial", store.scene);
    plane.material.diffuseColor = new BABYLON.Color3(1.0, 1.0, 1.0);
    plane.material.wireframe = true;

    stencilPlane = BABYLON.Mesh.CreatePlane("planeStencil", 2 * bboxMax, store.scene);
    stencilPlane.rotate(new BABYLON.Vector3(1, 0, 0), Math.PI / 2);
    stencilPlane.position.set(bboxSizeX, bboxSizeY, bboxSizeZ);
    stencilPlane.material = stencilPlaneMaterial;

    mesh.renderingGroupId = 0;
    meshInside.renderingGroupId = 1;
    stencilPlane.renderingGroupId = 2;

    // addObservers();

    mesh.onBeforeRenderObservable.add(function () {
      store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, clipPlaneY);
      plane.position.set(mesh.position.x, -clipPlaneY, mesh.position.z);
      // store.engine.setStencilBuffer(true);
    });

    mesh.onAfterRenderObservable.add(function () {
      store.scene.clipPlane = null;
      // store.engine.setStencilBuffer(false);
    });

    meshInside.onBeforeRenderObservable.add(function () {
      store.scene.clipPlane = new BABYLON.Plane(0, 1, 0, clipPlaneY);
      plane.position.set(mesh.position.x, -clipPlaneY, mesh.position.z);
      stencilPlane.position.set(0, -clipPlaneY, 0);
      store.engine.setStencilBuffer(true);
    });

    meshInside.onAfterRenderObservable.add(function () {
      store.scene.clipPlane = null;
      store.engine.setStencilBuffer(false);
    });

    let previousStencilMask = store.engine.getStencilMask();
    let previousStencilFunction = store.engine.getStencilFunction();
    stencilPlane.onBeforeRenderObservable.add(function () {
      store.engine.setStencilBuffer(true);
      store.engine.setStencilMask(0x00);
      store.engine.setStencilFunction(BABYLON.Engine.EQUAL);
    });

    stencilPlane.onAfterRenderObservable.add(() => {
      store.engine.setStencilBuffer(false);
      store.engine.setStencilMask(previousStencilMask);
      store.engine.setStencilFunction(previousStencilFunction);
    });
  };

  return {
    init,
    onPointerDown: function (evt) {
      console.log("pointerDownEvt");
      clickPickInfo = store.newScene.pick(
        store.newScene.pointerX,
        store.newScene.pointerY,
        function (mesh) {
          return (
            mesh &&
            // && mesh.name !== 'ground1'
            mesh.name.indexOf("sketchLine") === -1 &&
            mesh.name.indexOf("boxScale") === -1 &&
            mesh.name.indexOf("dimline") === -1 &&
            mesh.name.indexOf("backwall") === -1
          );
        }
      );
      if (!sliders) {
        createSliders(clickPickInfo.pickedMesh);
        sliders = true;
      }
      document.addEventListener("keydown", function (event) {
        const key = event.key; // Or const {key} = event; in ES6+
        if (key === "Escape") {
          resetAll();
        }
      });
    },
    onPointerMove: function (evt) {
      console.log("pointerMoveEvt");
    },
    onPointerUp: function (evt) {},
  };
})();
export { clipOperations };
