import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as constants from "./constants";
import Button from "../../components/Button/index";
import Divider from "../../components/Divider/index";
import Input from "../../components/Input/index";
import "./select-style.css";

import SelectSearch from "react-select-search";

import Google from "../../assets/images/google.svg";
import { signUpStyles } from "./styles";
import { routes } from "../../routes/constants";

import AuthService from "../../services/auth.service";
import Dropdown from "../../components/Dropdown";
import { colors } from "../../themes/constant";
import ScrollableDropdown from "../../components/ScrollableDropdown";
import SelectableTags from "../../components/SelectableTags";
import IntlTelInput from "react-intl-tel-input";
import { getCountryFromIP } from "../../services/payment.service";

const Wrapper = styled.div`
  width: 29rem;
  overscroll-behavior: contain;
  @media (max-width: 768px){
    width: 18rem;
    max-height: 99vh;
    overflow-y: auto;
  }
`
const Header = styled.h4`
  font-family: ${(props) => props.theme.fonts};
  color: ${(props) => props.theme.colors.primeBlack};
  font-size: ${(props) => props.theme.fontSizes.tiny};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const ColumnWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: space-evenly;
  padding-top: 1rem;
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.fonts};
  color: ${(props) => props.color || props.theme.colors.primeBlack};
  font-size: 0.775rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;

const ButtonWrapper = styled.div`
  padding: 0.5rem 0 0.5rem 0;
`;

const StyledButton = styled.button`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
`;

const TelInput = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0.3125rem;
  margin-top: 1rem;
  ${({ error }) => {
    if (error) {
      return css`
        border-width: 0.0625rem;
        border-style: solid;
        border-color: ${(props) => props.theme.colors.orangeYellow};
      `;
    } else {
      return css`
        &:focus-within,
        &:hover {
          border-width: 0.0625rem;
          border-style: solid;
          border-color: ${(props) => props.theme.colors.lightGrey};
          margin: -0.0625rem;
          margin-top: 1rem;

        }
      `;
    }
  }}
`;

const TelInputError = styled.div`
  font-size: 0.625rem;
  position: relative;
  bottom: 0;
  color: #ffae42;
  padding: 0 0 0.25rem 1.25rem;
  // background-color: #F2F2F2;
`
const REGEXP = {
  card: /^[0-9]{13,16}$/,
  cvv: /^[0-9]{3,4}$/,
  expiry: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/
}
class SelectProfession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      industry: null,
      isActionDisabled: false,
      selectProfessionError: "",
      isButtonLoading: false,
      isStudent: false,
      usecases: [],
      getPhoneNumber: false,
      phoneNumber: "",
      phoneNumberWithCode: "",
      phoneError: false,
      defaultCountry: "us",
      phoneInputValid: false,
      cardnumber: "",
      cardexpiry: "",
      cardcvv: "",
      cardnumberError: false,
      cardexpiryError: false,
      cardcvvError: false,
      isB2b: false
    };
  }

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value,
    });
  };


  setPhoneError = (value) => {
    this.setState({
      phoneError: value,
    });
  };

  phoneNumberCheck = (inputValue) => {
    let number = `${inputValue}`;
    number = number.trim();
    number = number.replaceAll("-", "");
    number = number.replaceAll(" ", "");
    number = number.replaceAll("(", "");
    number = number.replaceAll(")", "");
    return !isNaN(number);
  }

  setFormItemError = (item, value) => {
    this.setState({
      [item]: value
    })
  }

  openPage = (url) => {
    window.open(url, "_blank");
  };

  bookDemo = () => {
    this.openPage("https://calendly.com/altaf-1/30min")
  }

  handleSubmit = (evt) => {
    if (evt) evt.preventDefault();
    this.setState({ isButtonLoading: true });
    this.setFormProperty("selectProfessionError", "");
    const country = "";
    const city = "";
    window.analytics.track(`selectProfession - selected role - ${this.state.role}`);
    window.analytics.track(`selectProfession - selected industry - ${this.state.industry}`);
    window.analytics.track(`selectProfession - selected usecases - ${this.state.usecases.join(',')}`);
    const { role, industry, usecases, phoneNumberWithCode, cardnumber } = this.state;
    AuthService.setRoleAndDesignation(role, industry, country, city, usecases, phoneNumberWithCode)
      .then((res) => {
        this.setState({ isButtonLoading: false });
        if (res === "success") {
          const query = new URLSearchParams(this.props.location.search);
          const redirect = query.get("redirect");
          let url = routes.dashboard;
          if (redirect) {
            url = redirect;
          }
          this.props.history.push(url, { from: "selectProfession" });
        }
        else this.setFormProperty("selectProfessionError", "Please try again.");
      })
      .catch((err) => {
        this.setState({ isButtonLoading: false });
        console.log(err);
      });
  }

  handleRoleAndDesgnation = (evt) => {
    if (evt) evt.preventDefault();
    this.handleSubmit();
  };

  handleUsecases = (value) => {
    let selectedUseCases = this.state.usecases;
    if (selectedUseCases.includes(value)) {
      selectedUseCases = selectedUseCases.filter(selectedUsecase => selectedUsecase !== value);
    } else {
      selectedUseCases.push(value);
    }
    this.setState({
      usecases: selectedUseCases,
    })
  }

  componentDidMount = () => {
    const activePlan = AuthService.getActivePlan();
    if (activePlan && activePlan.name === "student") {
      this.setState({ isStudent: true });
    }
    getCountryFromIP()
      .then(countryCode => {
        this.setState({
          defaultCountry: countryCode.toLowerCase(),
        })
      })
  }

  render() {
    const { history, headerElement } = this.props;
    const { industry, role, isB2b, phoneInputValid, cardnumber, cardcvv, cardexpiry, cardnumberError, cardcvvError, cardexpiryError } = this.state;
    const isActionDisabled = !industry || !role;
    // const allowSubmit = isB2b ? (phoneInputValid && cardnumber && cardcvv && cardexpiry && !cardnumberError && !cardcvvError && !cardexpiryError) : (phoneInputValid);
    const allowSubmit = this.state.role === "Student" || phoneInputValid;
    if (this.state.getPhoneNumber) {
      return (
        <Wrapper>
          <form onSubmit={this.handleSubmit}>
            <Header>{constants.SELECT_PROFESSION_CARD_HEADER}</Header>
            <Divider />
            {/* {
              isB2b &&
              <div>
                <Input
                  type={"number"}
                  value={this.state.cardnumber}
                  onPress={(e) => {
                    this.setState({ "cardnumber": e.target.value })
                  }}
                  customInputContainerStyle={signUpStyles.inputWrapper}
                  placeholder={constants.CARD_LABEL}
                  validator={REGEXP.card}
                  setError={(val) => { this.setFormItemError('cardnumberError', val) }}
                  errorMessage={"Invalid card"}
                  error={this.state.cardnumberError}
                />
                <div style={{ display: "flex" }}>

                  <Input
                    type={"number"}
                    value={this.state.cardcvv}
                    onPress={(e) => {
                      this.setState({ "cardcvv": e.target.value })
                    }}
                    customInputContainerStyle={{ ...signUpStyles.inputWrapper, flex: 1, marginRight: '10px' }}
                    placeholder={"CVV"}
                    validator={REGEXP.cvv}
                    setError={(val) => { this.setFormItemError('cardcvvError', val) }}
                    errorMessage={"Invalid CVV"}
                    error={this.state.cardcvvError}
                  />
                  <Input
                    type={"text"}
                    value={this.state.cardexpiry}
                    onPress={(e) => {
                      this.setState({ "cardexpiry": e.target.value })
                    }}
                    customInputContainerStyle={{ ...signUpStyles.inputWrapper, flex: 1 }}
                    placeholder={"Expiry MM/YY"}
                    validator={REGEXP.expiry}
                    setError={(val) => { this.setFormItemError('cardexpiryError', val) }}
                    errorMessage={"Invalid Expiry date"}
                    error={this.state.cardexpiryError}
                  />
                </div>
              </div>
            } */}

            <TelInput error={this.state.phoneError}>
              <IntlTelInput
                defaultCountry={this.state.defaultCountry}
                containerClassName="intl-tel-input telInputContainer"
                inputClassName="form-control telInput"
                value={this.state.phoneNumber}
                separateDialCode={true}
                onSelectFlag={(num, country) => {
                  this.setState({ phoneNumber: "" });
                  this.setState({ phoneNumberWithCode: "+" + country.dialCode })
                }}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                  this.setState({ phoneError: "", phoneInputValid: status });
                  if (this.phoneNumberCheck(value)) {
                    this.setState({ phoneNumber: value });
                    this.setState({ phoneNumberWithCode: number });
                  }
                }}
                onPhoneNumberBlur={(status, value, countryData, number, id) => {
                  this.setState({ phoneError: "" });
                  if (status === false) {
                    this.setState({ phoneError: "Please Enter Valid Phone number" })
                  }
                  this.setState({ phoneNumberWithCode: number });
                }}
                format
                formatOnInit={false}
              />
              {this.state.phoneError && <TelInputError>{this.state.phoneError}</TelInputError>}
            </TelInput>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <ColumnWrapper style={{ flex: 1 }}>
                <Button
                  className={allowSubmit ? "hoverRed" : ""}
                  customButtonStyle={allowSubmit ? signUpStyles.highlightedButton : signUpStyles.signUpButtonDisabled}
                  onPress={this.handleSubmit}
                  title={"Get Started"}
                  // isLoading={this.state.isButtonLoading}
                  disabled={!allowSubmit}
                />
              </ColumnWrapper>
            </div>
          </form>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        {headerElement ? (
          headerElement()
        ) : (
          <Header>{constants.SELECT_PROFESSION_CARD_HEADER}</Header>
        )}
        <Divider />
        <Text>Industry</Text>
        <ColumnWrapper style={{ justifyContent: "center" }}>
          <SelectableTags
            tags={constants.INDUSTRIES.map(industry => industry.name)}
            selected={this.state.industry}
            handleSelect={(value) => { this.setState({ industry: value }) }}
          />
        </ColumnWrapper>
        {
          this.state.industry &&
          <>
            <Text>Role</Text>

            <ColumnWrapper style={{ justifyContent: "center" }}>
              {
                this.state.isStudent ?
                  <SelectableTags
                    tags={["Student", "Faculty"]}
                    selected={"Student"}
                    handleSelect={(value) => { this.setState({ role: value }) }}
                  />
                  :
                  <SelectableTags
                    tags={constants.ROLES.map(role => role.name)}
                    selected={this.state.role}
                    handleSelect={(value) => { this.setState({ role: value }) }}
                  />
              }
            </ColumnWrapper>
          </>
        }
        {
          this.state.role && this.state.role !== 'Student' &&
          <div style={{ marginTop: "2.1875rem" }}>
            <Text>Contact Information</Text>
            {/* <ColumnWrapper style={{ marginTop: "0.575rem" }}> */}
            <TelInput error={this.state.phoneError}>
              <IntlTelInput
                defaultCountry={this.state.defaultCountry}
                containerClassName="intl-tel-input telInputContainer"
                inputClassName="form-control telInput"
                value={this.state.phoneNumber}
                separateDialCode={true}
                onSelectFlag={(num, country) => {
                  this.setState({ phoneNumber: "" });
                  this.setState({ phoneNumberWithCode: "+" + country.dialCode })
                }}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                  this.setState({ phoneError: "", phoneInputValid: status });
                  if (this.phoneNumberCheck(value)) {
                    this.setState({ phoneNumber: value });
                    this.setState({ phoneNumberWithCode: number });
                  }
                }}
                onPhoneNumberBlur={(status, value, countryData, number, id) => {
                  this.setState({ phoneError: "" });
                  if (status === false) {
                    this.setState({ phoneError: "Please Enter Valid Phone number" })
                  }
                  this.setState({ phoneNumberWithCode: number });
                }}
                format
                formatOnInit={false}
              />
              {this.state.phoneError && <TelInputError>{this.state.phoneError}</TelInputError>}
            </TelInput>
            {/* </ColumnWrapper> */}
          </div>
        }
        {
          allowSubmit &&
            <>
              <Text color={colors.mustard}>{this.state.selectProfessionError}</Text>
              <div style={{ display: "flex", marginTop: "2rem" }}>
                <ColumnWrapper style={{ flex: 1 }}>
                  <Button
                    className="hoverRed"
                    customButtonStyle={["Principal", "Manager"].includes(this.state.role) ? signUpStyles.highlightedButton : signUpStyles.selectRoleAndIndustryButton}
                    onPress={this.handleRoleAndDesgnation}
                    title={"Get Started"}
                    isLoading={this.state.isButtonLoading}
                  />
                </ColumnWrapper>
                {
                  ["Designer", "Student", "Engineer"].includes(this.state.role) ?
                    <>
                    </>
                    :
                    <>
                      <ColumnWrapper style={{ margin: "0px 10px" }}>
                        <Text>or</Text>
                      </ColumnWrapper>

                      <ColumnWrapper style={{ flex: 1 }}>
                        <Button
                          className="hoverRed"
                          customButtonStyle={signUpStyles.selectRoleAndIndustryButton}
                          onPress={this.bookDemo}
                          title={"Book demo"}
                          isLoading={this.state.isButtonLoading}
                        />
                      </ColumnWrapper>
                    </>
                }
              </div>
            </>

        }
      </Wrapper>
    );
  }
}

SelectProfession.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(SelectProfession);
