import { initSelf, resetActiveUsers, updateActiveUsers } from "../../stateManagers/reducers/objectProperties/activeUsersSlice";
import reduxStore from "../../stateManagers/store/reduxStore";
import CursorFactory from "../cursor/cursorFactory";
import { showToast } from "../extrafunc";
import { store } from "../utilityFunctions/Store";

const CollaborationNotification = (() => {
  const toastTheme = 'collaboration';
  const notificationDuration = 3000;

  const someoneJoined = (joineeEmail, joineeName) => {
    let name = joineeName || joineeEmail || "Guest";
    const message = `${name} joined the session. 🔺`;
    showToast(message, notificationDuration, toastTheme);
  };

  const joined = user => {
    someoneJoined(user.email, user.name);
  };

  const someoneLeft = (joineeEmail, joineeName) => {
    let name = joineeName || joineeEmail || "Guest";
    const message = `${name} left the session. 🔻`;
    showToast(message, notificationDuration, toastTheme);
  };

  const left = user => {
    someoneLeft(user.email, user.name);
  };

  const unauthorised = saveData => {
    if(isWarningSuppressed(saveData)){
        return;
    }

    if(store.isCoveToolProject) {
      showToast('Your changes will not be saved as this is a daylight analysis project.', notificationDuration, toastTheme);
    } else {
      showToast('You are not authorised to edit this project. Changes you make will not be saved.', notificationDuration, toastTheme);
    }
  };



  const userDoesNotExist = () => {
    showToast('User does not exist in Snaptrude. Please ask the user to sign up to Snaptrude.', notificationDuration, toastTheme);
  };

  const isWarningSuppressed = saveData => {
    const skip = ["Hide Object", "Show All", "Show All Roofs", "Hide All Roofs", "Isolate Object"]
    if(saveData && saveData.hasOwnProperty('length') && saveData.length && saveData[0]){
        if(saveData[0].hasOwnProperty('name') && saveData[0].name){
            if(skip.includes(saveData[0].name)){
                return true;
            }
        }
    }
    return false;
  };

  return {
    someoneJoined,
    someoneLeft,
    joined,
    left,
    unauthorised,
    userDoesNotExist,
  };
})();

const ActiveUserList = (() => {

    /**
     * Currently supports only 11 users with singular tabs (same user opening multiple tabs is a no-no)
     * @param {*[]} sessionList 
     */
    const incomingSessionListToFrontendReadable = sessionList => {
        const returnArray = [];
        if(sessionList){
            for(const email in sessionList){
                for(const sessionId in sessionList[email]){
                    returnArray.push({
                        color: sessionList[email][sessionId]['color'],
                        user: {
                            id: sessionList[email][sessionId]['id'],
                            email: sessionList[email][sessionId]['email']
                        }
                    })
                }
            }
        }
        return returnArray;
    };

    const populate = sessionList => {
        const frontendReadable = incomingSessionListToFrontendReadable(sessionList);
        reduxStore.dispatch(updateActiveUsers({ allActiveUsers: frontendReadable }));
    };

    const receivedList = sessionList => {
        const cursorFactory = new CursorFactory();
        cursorFactory.deactivateAll();
        populate(sessionList);
    };

    const selfPopulate = self => {
        reduxStore.dispatch(initSelf({ self: self }));
    };
    
    const reset = () => {
        reduxStore.dispatch(resetActiveUsers());
    }

    return{
        incomingSessionListToFrontendReadable,
        populate,
        receivedList,
        selfPopulate,
        reset,
    };
})();

export {
  CollaborationNotification,
  ActiveUserList,
};
