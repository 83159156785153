import { colors } from "../../themes/constant";

export const styles = {
  inputWrapper: {
    padding: "0 0 0.75rem 0",
  },
  signUpButtonDisabled: {
    backgroundColor: colors.lightGrey,
    cursor: "default",
  },
  signUpButtonEnabled: {
    backgroundColor: colors.brightRed,
  },
};
