import _ from "lodash";
import io from "socket.io-client";
import { getJWT } from "../../../services/jwt.service";
import { socialUrl, socketUrl } from "../../../services/url.constants";
import { addActiveUsersToSelectedTeam } from "../../stateManagers/reducers/objectProperties/teamsSlice";
import reduxStore from "../../stateManagers/store/reduxStore";
import { store } from "../utilityFunctions/Store";
const teamsConnect = (() => {
    let teamSocket;
    const author = {
        id: store.infoUser.id,
        email: store.infoUser.email,
        name: store.infoUser.name
    };
    const connectSocket = () => {
        teamSocket = io(socketUrl, {
            reconnection: true,
            forceNew: false,
            transports: ["websocket"],
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
        });

        let params = {
            room: null,
            user: store.infoUser.id,
            lastCommandId: null,
            sessionId: false,
            author: author,
            jwt: getJWT(),
            isTeamSocket: true
        };

        
      
        teamSocket.on("connect", () => {
            console.log("CONNECTED TEAM SOCKET.")
            if(teamSocket){
                teamSocket.emit("join", params, response => {
                    console.log(response);
                })
                teamSocket.on("refreshonlineusers", handleRefreshOnlineUsers)
            }
        })
    }

    const handleRefreshOnlineUsers = (data) => {
        console.log("on TEAM SOCKET: HANDLE REFRESH ONLINE USERS");
        data.team = parseInt(data.team);
        data.users = _.values(data.users);
        const refreshonlineusersUIEvt = new CustomEvent("refreshonlineusersUI", {
            detail: data
        });
        document.dispatchEvent(refreshonlineusersUIEvt);
    }


    const addToDashboard = (teamId) => {
        console.log("ADD TO DASHBOARD: TEAM SOCKET")
        return new Promise((resolve, reject) => {
            if(!teamSocket) reject({status: "error", message: "teamSocket does not exist"});
            teamSocket.emit("addtodashboard", teamId, (resp) => {
                if(resp.message === "success"){
                    const activeUsers = _.values(resp.users);
                    resolve({status: "success", activeUsers: activeUsers, teamId: resp.team})     
                }else{
                    reject({staus: "fail", message: "addtodashboard did not succeed"})
                }
            });

        })
    }

    const removeFromDashboard = () => {
        console.log("REMOVE FROM DASHBOARD TEAM SOCKET")
        if(!teamSocket) return;
        teamSocket.emit("removefromdashboard", (resp) => {
            if(resp.message === "success"){
            }
        })
    }

    const forceDisconnect = function(){
        console.log("FORCE DISCONNECT TEAM SOCKET")
        if(teamSocket){
          teamSocket.emit('forceDisconnect');
        }
        teamSocket = undefined;
    };

    return {
        connectSocket,
        addToDashboard,
        removeFromDashboard,
        forceDisconnect,
    }
})();

export {teamsConnect}