/*jshint esversion: 6 */
"use strict";
import * as log from "loglevel";
import { store } from "../utilityFunctions/Store.js"
import { normal,curved } from "../factoryTypes/room.types.js";
import { plainfloor } from "../factoryTypes/floor.types.js";
import { plainroof } from "../factoryTypes/roof.types.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";
import { ResolveEngine } from "./resolveEngine.js";
import { isMeshThrowAway,getRoomTypeProperties } from "../extrafunc.js";
import { shelf } from "../utilityFunctions/shelf.js";
import { WallBuilder } from "./wallbuilder.js";
import { FloorBuilder } from "./floorbuilder.js";
import { RoofBuilder } from "./roofbuilder.js";


/**
 * { delete mess }
 *
 * @param      {BABYLON}  mesh    The mesh
 */
function deletemass(mesh) {
  let level = mesh.getSnaptrudeLevel();
  level.removeObjectToLevel(mesh.getSnaptrudeDS());
  if (mesh.children) {
    mesh.children.forEach((me) => {
      if (me) me.dispose();
    });
  }
  mesh.dispose();
}

var CreateRoom = function (execute, mesh) {
  this.execute = execute;
  this.mesh = mesh;
};

var CreateFloor = function (execute, mesh) {
  this.execute = execute;
  this.mesh = mesh;
};

var CreateRoof = function (execute, mesh) {
  this.execute = execute;
  this.mesh = mesh;
};

var CreateNormalRoom = function (mesh) {
  return new CreateRoom(normal, mesh);
};

var CreateCurvedRoom = function (mesh) {
  return new CreateRoom(curved, mesh);
};

var CreatePlainFloor = function (mesh) {
  return new CreateFloor(plainfloor, mesh);
};

var CreatePlainRoof = function (mesh) {
  return new CreateRoof(plainroof, mesh);
};

var DeleteObject = function (execute, mesh) {
  this.execute = execute;
  this.mesh = mesh;
};

var DeleteMass = function (mesh) {
  return new DeleteObject(deletemass, mesh);
};

//add any other room type

/**
 * { room engine }
 *
 * @class      RoomEngine (name)
 * @return     {(Array|Object)}  { description_of_the_return_value }
 */
var RoomEngine = function () {
  var current = [];
  return {
    execute: function (room) {
      let result = room.execute(room.mesh);
      if (result) current.push(result);
    },
    getCurrentValue: function () {
      return current;
    },
    logger: function () {
      return log.getLogger("room_engine");
    },
    //any other
  };
};

const ComponentGenerationEngine = function () {
  /*
    Builds floors and roofs mass by mass, gather points for the walls but doesn't build
     */
  this.structureTraverser = function (arrayOfSteps, concernedMasses = []) {
    for (let mass of concernedMasses) {
      if (this.util.isMassQualifiedForWallGeneration(mass)) {
        try {
          for (let step of arrayOfSteps) {
            step(mass);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  this.generateWalls = function () {
    createBuildingEngine.resolveJunctionsAndGenerateWalls();
  };

  this.resolveWalls = function () {
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      /* eslint-enable */
      let resolveEngine = new ResolveEngine();

      try {
        let intersectedWalls = resolveEngine.findIntersectedWalls();

        await resolveEngine.startEngine(intersectedWalls);
        await resolveEngine.constructSmallDeletedWalls();

        resolve();

        // await RelationshipEngine.init();
      } catch (e) {
        console.warn(e);
        console.warn("resolveEngine.startEngine failed");
        resolve();
      }
    });
  };

  this.combineRoofs = function () {
    let resolveEngine = new ResolveEngine();
    try {
      // await resolveEngine.combineRemainingRoofs();
      return resolveEngine.combineRoofsCSG();
    } catch (e) {
      console.log(e);
      return Promise.resolve();
    }
  };

  /*this.generateRoofs = function() {
		return new Promise((resolve, reject) => {
			let dissectionObject = shelf.retrieve();
			let offsetValue = userSetBIMPropertiesHandler.getRoofOverhang();
			let effectiveOffsetValue = offsetValue + userSetBIMPropertiesHandler.getWallThickness();

			_.forEach(dissectionObject, (roofObject, storeyNumber) => {
				let allPolygons = roofObject.roofs;
				// [x, z, y] format

				if (_.isEmpty(allPolygons)) return;

				let storeyData = StructureCollection.getInstance()
					.getStructureById(activeLayer.structure_id).getStoreyData();

				let storey = storeyData.getStoreyByValue(storeyNumber);
				let heightValue = storey.base + storey.height - plainRoofParameters.roofDepth;

				let offsetAllPolygons = allPolygons.map(arrayOf3DPoints => {
					let offSetArray = getOffsetValues(arrayOf3DPoints, effectiveOffsetValue);
					// let offSetArray = arrayOf3DPoints;
					return offSetArray.map(array => _.dropRight(array, 1));
				});

				let roofPolygonsToGenerate = externalUtil.getUnionOfPolygons(offsetAllPolygons);

				var factory = new Factory();
				roofPolygonsToGenerate.forEach(roofPoints => {

					roofPoints = roofPoints.map(p => [p[0], p[1], heightValue]);

					var roof = new Roof(factory.createRoof("plain", roofPoints));
					roof.assignProperties();
					roof.mesh.structure_id = activeLayer.structure_id;

					roof.offset = offsetValue;

					roof.mesh.computeWorldMatrix(true);
					roof.roof_pol_offset_bottom = convertGlobalCoordsToLocal(
						roofPoints, roof.mesh.getWorldMatrix(), true, false);
					roof.roof_pol_offset_top = roof.roof_pol_offset_bottom.map(p => [p[0], p[1] + plainRoofParameters.roofDepth, p[2]]);

					let structures = StructureCollection.getInstance();
					let str = structures.getStructureById(activeLayer.structure_id);
					let level = str.getLevelByUniqueId(roofObject.level_id);

					level.addRoofToLevel(roof, false);
				})
			});


			resolve();
		});

	};*/

  this.util = {
    isMassQualifiedForWallGeneration: function (mass) {
      let qualified = true;

      if (!mass.massType) qualified = false;
      else if (mass.massType.toLowerCase() !== "room") qualified = false;
      else if (isMeshThrowAway(mass.mesh)) qualified = false;
      else if (mass.mesh.type.toLowerCase() === "void") qualified = false;
      else if (!mass.mesh.room_type) qualified = false;

      return qualified;
    },
  };
};

/**
 * {  Builder pattern for creating modular object }
 *
 * @class      Procedure (name)
 * @return     {Object}  {  }
 */
function Procedure() {
  this.constructForAreas = async function (builder, concernedMasses) {
    store.createBuildingGlobalVariables.changeParentChildRelationships = false;
    //this is because after wall generation, new walls are made parents of drawn masses
    //shouldn't happen now
    let componentGenerationEngine = new ComponentGenerationEngine();

    let steps = [builder.step1, builder.step2];
    componentGenerationEngine.structureTraverser(steps, concernedMasses);

    componentGenerationEngine.generateWalls();
    // await componentGenerationEngine.resolveWalls();

    store.createBuildingGlobalVariables.changeParentChildRelationships = true;
    return builder.get();
  };

  this.construct = async function (builder, concernedMasses) {
    let componentGenerationEngine = new ComponentGenerationEngine();
    shelf.place({}); // object to hold the roofPolygons

    // Not generating roofs at this stage now
    let steps = [builder.step1, builder.step2, builder.step3, builder.step4];
    // let steps = [builder.step1, builder.step2, builder.step4];

    try {
      componentGenerationEngine.structureTraverser(steps, concernedMasses);
      const roofCombinationPromise = componentGenerationEngine.combineRoofs();
      componentGenerationEngine.generateWalls();
      // await componentGenerationEngine.resolveWalls();

      await roofCombinationPromise;
    } catch (e) {
      console.warn(e);
    }

    return builder.get();
  };

  /**
   * Constructs a single mass
   * @param {*} builder
   * @param {*} mass
   */
  this.singleConstruct = async function (builder, mesh) {
    /*

        NOT USED. this.construct USED EVEN FOR SINGLE MASS CREATE ROOM

         */

    let componentGenerationEngine = new ComponentGenerationEngine();
    let mass = mesh.getSnaptrudeDS();

    if (!componentGenerationEngine.util.isMassQualifiedForWallGeneration(mass))
      return;

    try {
      builder.step1(mass);
      builder.step2(mass);
      builder.step3(mass);
      builder.step4(mass);
    } catch (e) {
      console.error(e);
    }

    await componentGenerationEngine.resolveWalls().catch(() => {});

    await componentGenerationEngine.combineRoofs();

    return builder.get();
  };

  this.startWorking = function (builder, mass) {
    builder.step1(mass);
    builder.step2(mass);
    builder.step3(mass);
    //add any other if required

    return builder.get();
  };
}

var ConverMassToComponents = function () {
  let procedure = new Procedure();
  // let alpha = new MassDissector();
  let bob = new WallBuilder();
  let charley = new FloorBuilder();
  let tango = new RoofBuilder();

  /*
	this.step0 = function(mass){
		procedure.startWorking(alpha, mass);
	};
    */

  this.step1 = function (mass) {
    procedure.startWorking(bob, mass);
  };

  this.step2 = function (mass) {
    if (mass.mesh.convertedToComponents) procedure.startWorking(charley, mass);
  };

  this.step3 = function (mass) {
    if (mass.mesh.convertedToComponents) procedure.startWorking(tango, mass);
  };

  this.step4 = function (mass) {
    let roomTypeProperties = getRoomTypeProperties(mass.mesh.room_type);
    if (roomTypeProperties) {
      if (roomTypeProperties.bim.preserveMass) {
        // mass.createBuildingDone = true;
        createBuildingEngine.markAsMassNotDeleted(mass);
        return;
      }
      //Certain types of room types shouldn't be deleted
    }

    // createBuildingEngine.getComponentsCreated().forEach(component => {
    //     if(component.type.toLowerCase() === "roof"){
    //         component.setSlabType();
    //     }
    // });

    if (mass.mesh.convertedToComponents) {
      // hiding the mass since roof combination takes a while, it looks ugly
      mass.hide();
      createBuildingEngine.removeComponent(mass);
    }

    /*
		let roomEngine = new RoomEngine();
		try{
			roomEngine.execute(new DeleteMass(mass.mesh));
			//add more
		}
		catch(e){
			console.error(e);
			var errorMessage = new ErrorMessage(mass.mesh, "error");
			errorMessage.show("Ooops, can't generate wall for this structure");
			mass.mesh.material = errorMessage.material;
		}*/
  };

  this.get = function () {
    return Promise.resolve("success");
  };

  //add more
};
export { deletemass,CreateRoom,CreateFloor,CreateRoof,CreateNormalRoom,CreateCurvedRoom,CreatePlainFloor,CreatePlainRoof,DeleteObject,DeleteMass,RoomEngine,ComponentGenerationEngine,Procedure,ConverMassToComponents };
