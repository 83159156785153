"use strict";
import _ from "lodash";
import { AutoSave } from "../socket/autoSave.js";
// Commented import mixpanel from 'mixpanel-browser';

var CommandManager = (function () {
  let commandManager = {};

  let executed = [];
  let unexecuted = [];

  function handleAutoSave(saveData) {
    return new Promise((resolve, reject) => {
      saveData = AutoSave.sanitizeSaveData(saveData);

      if (_.isEmpty(saveData)) {
        console.warn("Commands not saved in back end");
      } else {
        AutoSave.publishCommand(saveData, false);
      }
      resolve();
    });
  }

  commandManager.getCurrentState = function () {
    let state = {};
    state.executed = executed;
    state.unexecuted = unexecuted;

    return state;
  };

  commandManager.getExecutionSequence = function () {
    let filteredProperties = ["name"];
    console.table(executed, filteredProperties);
  };

  commandManager.execute = function (cmd, yetToExecute) {
    let saveData = [];

    if (_.isArray(cmd)) {
      if (_.isEmpty(cmd)) return;

      cmd.forEach((individualCmd, i) => {
        window.analytics.track("Command " + individualCmd.name);
        if (yetToExecute[i]) individualCmd.execute();
        saveData.push(...individualCmd.getSaveData());
      });
    } else {
      window.analytics.track("Command " + cmd.name);
      if (yetToExecute) cmd.execute();
      saveData.push(...cmd.getSaveData());
    }

    executed.push(cmd);
    handleAutoSave(saveData);
    unexecuted = [];
  };

  commandManager.executeAndReplace = function (cmd, yetToExecute) {
    executed.pop();
    commandManager.execute(cmd, yetToExecute);
  };

  commandManager.undo = function () {
    let cmd = executed.pop();

    if (cmd) {
      if (_.isArray(cmd)) {
        if (cmd.executeLeftToRight) {
          for (let j = 0; j < cmd.length; j++) {
            cmd[j].unexecute();
          }
        } else {
          for (let j = cmd.length - 1; j >= 0; j--) {
            cmd[j].unexecute();
          }
        }
        // cmd.forEach(individualCmd => individualCmd.unexecute());
      } else cmd.unexecute();
      unexecuted.push(cmd);

      AutoSave.undo(cmd);
    }
    // else showToast('Maximum Undo Reached');
  };

  commandManager.redo = function () {
    let cmd = unexecuted.pop();
    if (cmd) {
      if (_.isArray(cmd)) {
        cmd.forEach((individualCmd) => individualCmd.execute());
      } else cmd.execute();
      executed.push(cmd);

      AutoSave.redo(cmd);
    }
    // else showToast('Maximum Redo Reached');
  };

  commandManager.getLatestCommand = function () {
    return _.last(executed);
  };

  commandManager.popLastItemFromExecuted = function () {
    executed.pop();
  };

  commandManager.reset = function () {
    executed.length = 0;
    unexecuted.length = 0;
  }
  /*commandManager.saveCommandToLocalStorageCommandQueue = function (cmd) {

        function _serializeAndQueueCommand(cmd){
            let serialized = cmd.serialize();
            currentCommandQueue.push(serialized);
        }

        let currentCommandQueue = localStorage.getItem('CommandQueue');

        if (!currentCommandQueue) currentCommandQueue = [];
        else currentCommandQueue = JSON.parse(currentCommandQueue);

        if (_.isArray(cmd)){
            cmd.forEach(cmd => _serializeAndQueueCommand(cmd));
        }
        else _serializeAndQueueCommand(cmd);

        let json = JSON.stringify(currentCommandQueue);
        localStorage.setItem('CommandQueue', json);
    };

    commandManager.getCommandsFromLocalStorageCommandQueue = function () {
        let currentCommandQueue = localStorage.getItem('CommandQueue');

        if (currentCommandQueue === null){
            currentCommandQueue = [];
        }
        else {
            currentCommandQueue = JSON.parse(currentCommandQueue);
        }

        return currentCommandQueue;
    };*/

  return commandManager;
})();

// store.CommandManager= CommandManager;

export { CommandManager };
