import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors} from '../../themes/constant'
import Button from '../Button'
import Input from '../Input'
import { terrainGeneration } from "../../snaptrude/modules/geo/terrainMap";

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  z-index: 20;
  position: fixed;
  padding: 0.75rem;
  background-color: white;
  border-radius: 5px;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem;
`
const InputWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    padding: 0.75rem;
    width: 7rem;
  }
`

const FirstInput = ({lat1, long1, setLat1, setLong1, handleSubmit, handleClose}) => {
  return <>
    <InputWithLabel>
      <label>Latitude 1</label>
      <Input type="number" value={lat1} onPress={(evt) => {
        setLat1(evt.target.value)
      }}/>
    </InputWithLabel>
    <InputWithLabel>
      <label>Longitude 1</label>
      <Input type="number" value={long1} onPress={(evt) => {
        setLong1(evt.target.value)
      }}/>
    </InputWithLabel>
    <Controls>
      <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} primary
              title={"Cancel"} onPress={handleClose}/>
      <Button primary title={"Submit"} onPress={handleSubmit}/>
    </Controls>
  </>
}

const SecondInput = ({lat2, long2, setLat2, setLong2, handleSubmit, handleClose}) => {
  return <>
    <InputWithLabel>
      <label>Latitude 2</label>
      <Input type="number" value={lat2} onPress={(evt) => {
        setLat2(evt.target.value)
      }}/>
    </InputWithLabel>
    <InputWithLabel>
      <label>Longitude 2</label>
      <Input type="number" value={long2} onPress={(evt) => {
        setLong2(evt.target.value)
      }}/>
    </InputWithLabel>
    <Controls>
      <Button customButtonStyle={{backgroundColor: colors.fullWhite, color: colors.secondaryGrey}} primary
              title={"Cancel"} onPress={handleClose}/>
      <Button primary title={"Submit"} onPress={handleSubmit}/>
    </Controls>
  </>
}

export default function LatLongInput() {
  const [lat1, setLat1] = useState("");
  const [long1, setLong1] = useState("");
  const [lat2, setLat2] = useState("");
  const [long2, setLong2] = useState("");
  const [hasFirstInput, setHasFirstInput] = useState(false);
  const [pointClicked1, setPointClicked1] = useState(null);
  const [pointClicked2, setPointClicked2] = useState(null);

  const handlePickedPoint = useCallback(
    (evt) => {
      // console.log(evt.detail);
      setPointClicked2(evt.detail);
    },
    [],
  ) 

  useEffect(() => {
    window.addEventListener("picked-point-event", handlePickedPoint)
    return () => {
      window.removeEventListener("picked-point-event", handlePickedPoint)
    }
  }, [handlePickedPoint])

  const handleClose = () => {
    const latLongWrapper = document.getElementById("lat-long-input-wrapper");
    latLongWrapper.style.display = "none";
  }

  const handleSubmit1 = () => {
    if (lat1 && long1) {
      setHasFirstInput(true);
      setPointClicked1(pointClicked2)
      handleClose();
    }
  }

  const handleSubmit2 = () => {
    if (lat2 && long2) {
      // do something with the inputs
      // console.log(lat1, long1, lat2, long2, pointClicked1, pointClicked2);
      terrainGeneration.alignTerrainMapWithInGivenLatLon(
        {lng: parseFloat(long1), lat: parseFloat(lat1)},
        {lng: parseFloat(long2), lat: parseFloat(lat2)},
        pointClicked1,
        pointClicked2);

      handleClose();
      reset();
    }
  }

  const reset = () => {
    setLat1("");
    setLong1("");
    setHasFirstInput(false);
    setLat2("");
    setLong2("");
  }

  return (
    <Wrapper id="lat-long-input-wrapper">
      {
        hasFirstInput ?
          <SecondInput lat2={lat2} long2={long2} setLat2={setLat2} setLong2={setLong2} handleSubmit={handleSubmit2}
                       handleClose={handleClose}/>
          :
          <FirstInput lat1={lat1} long1={long1} setLat1={setLat1} setLong1={setLong1} handleSubmit={handleSubmit1}
                      handleClose={handleClose}/>
      }
    </Wrapper>
  )
}
