import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ActionDropdown from '../../../../components/ActionDropdown';
import LibraryContextMenu from '../../../../components/ContextMenu/LibraryContextMenu';
import LibraryInput from '../../../../components/LibraryInput';
import { deleteTeamMaterial, duplicateTeamMaterial, getTeamMaterials, updateTeamMaterialProperty } from '../../../../services/team.service';
import { showToast, TOAST_TYPES } from '../../../../snaptrude/modules/extrafunc';
import { sort } from './classificationOperations';
import { CONTEXT_MENU_STATE_DEFAULT, DIRECTION, MATERIAL_PROPERTIES, MATERIAL_TYPE_OPTIONS } from './constants';
import { Wrapper as GenericWrapper } from './Styled';
import addIcon from '../../../../assets/icons/addButton.svg'
import { formatMultipleSubType } from '../helper';

const Wrapper = styled(GenericWrapper)`
.material-name{
    min-width: 300px;
    text-align: left;
}
.material-type{
    min-width: 125px;
    text-align: right;
    text-transform: capitalize;
}
.cost{
    width: 100px;
}
`

function Materials({ selectedTeam, teamlist }) {
    const scrollContainerRef = useRef(null);
    const [lastItemIndex, setLastItemIndex] = useState(20);
    const [currentElementId, setCurrentElementId] = useState(null);
    const [contextMenu, setContextMenu] = useState(CONTEXT_MENU_STATE_DEFAULT);
    const [orderBy, setOrderBy] = useState({ field: '', direction: DIRECTION.NONE });

    const handleDelete = async (teamId, elementId) => {
        const materialTableEle = document.getElementById("material-table");
        if (materialTableEle) materialTableEle.classList.add("disabled");
        setContextMenu(CONTEXT_MENU_STATE_DEFAULT);
        const resp = await deleteTeamMaterial(teamId, elementId);
        if (resp.status !== "success") {
            showToast(resp.message || "Could not delete material, try again", 3000, TOAST_TYPES.error);
        }
        if (materialTableEle) materialTableEle.classList.remove("disabled")
    }

    const handleDuplicate = async (teamId, elementId) => {
        const materialTableEle = document.getElementById("material-table");
        if (materialTableEle) materialTableEle.classList.add("disabled");
        setContextMenu(CONTEXT_MENU_STATE_DEFAULT);
        const resp = await duplicateTeamMaterial(teamId, elementId);
        if (resp.status !== "success") {
            showToast(resp.message || "Could not duplicate material, try again", 3000, TOAST_TYPES.error);
        }
        if (materialTableEle) materialTableEle.classList.remove("disabled")
    }
    const handleRename = (elementId, targetElement) => {
        setCurrentElementId(elementId);
        setTimeout(() => {
            if (targetElement) targetElement.focus();
        }, 1)
        setContextMenu(CONTEXT_MENU_STATE_DEFAULT);
    }
    const contextMenuOptions = [
        { title: "Rename", handleClick: () => { handleRename(contextMenu.elementId, contextMenu.targetElement) } },
        { title: "Duplicate", handleClick: () => { handleDuplicate(selectedTeam.id, contextMenu.elementId) } },
        { title: "Delete", handleClick: () => { handleDelete(selectedTeam.id, contextMenu.elementId) } },
    ]

    const handlePropertyUpdate = async (property, newValue, material) => {
        setCurrentElementId(null);
        const materialTableEle = document.getElementById("material-table");
        if (materialTableEle) materialTableEle.classList.add("disabled");
        if(property === "mat_type") newValue = formatMultipleSubType(newValue, material.type);
        const resp = await updateTeamMaterialProperty(selectedTeam.id, property, newValue, material);
        if (resp.status !== "success") {
            showToast(resp.message || "Could not update property, try again", 3000, TOAST_TYPES.error);
        }
        if (materialTableEle) materialTableEle.classList.remove("disabled")
    }

    const handleOrderBy = (field) => {
        if (field === 'texture') return;
        const newOrderBy = {
            field: field,
            direction: DIRECTION.ASC
        }

        let prev = orderBy;
        if (prev.field === newOrderBy.field) {
            if (prev.direction === DIRECTION.ASC) {
                newOrderBy.direction = DIRECTION.DESC
            } else if (prev.direction === DIRECTION.DESC) {
                newOrderBy.field = '';
                newOrderBy.direction = DIRECTION.NONE
            }
        }

        scrollContainerRef.current.scrollTop = 0;
        setOrderBy(newOrderBy);
        setLastItemIndex(20)
    }


    const currentTeam = teamlist.find(team => team.id === selectedTeam.id);
    const permissions = currentTeam?.roleBasedPermissions[currentTeam.role];
    const hasEditPermission = permissions?.edit_library_properties;
    const materials = useMemo(() => {
        if (!currentTeam?.materials) return [];
        const sorted = sort(currentTeam.materials, orderBy);
        return sorted;
    }, [currentTeam.materials, orderBy])

    useEffect(() => {
        const handleScrollBottom = () => {
            const { offsetHeight, scrollTop, scrollHeight } = scrollContainerRef.current;
            const length = currentTeam?.materials?.length || Number.MAX_VALUE;
            if (offsetHeight + scrollTop >= scrollHeight-10) {
                setLastItemIndex(prev => Math.min(length, prev + 20));
            }
        }
        scrollContainerRef.current.addEventListener("scroll", handleScrollBottom);
        return () => {
            scrollContainerRef.current.removeEventListener("scroll", handleScrollBottom);
        }

    }, [scrollContainerRef, currentTeam?.materials?.length]);

    const handleContextMenu = (evt, material) => {
        evt.preventDefault();
        if (evt.target.dataset.customcontextmenu !== "allow") return;
        const { clientX, clientY } = evt;
        setContextMenu({ show: true, x: clientX, y: clientY, elementId: material.id, targetElement: evt.target })
    }
    return (
        <Wrapper ref={scrollContainerRef}>
            <table id='material-table'>
                <thead>
                    <tr>
                        {
                            MATERIAL_PROPERTIES.map(property => {
                                return (
                                    <th
                                        key={property.key}
                                        onClick={() => { handleOrderBy(property.key) }}
                                        className={`${_.kebabCase(property.title)} ${orderBy.field === property.key ? orderBy.direction : ''}`}
                                    >
                                        {property.title}
                                    </th>

                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        materials.slice(0, lastItemIndex).map((material, index) => {
                            return (
                                <tr key={material.id} onContextMenu={(evt) => { handleContextMenu(evt, material) }}>
                                    <td>
                                        <img style={{ width: "inherit", borderRadius: "5px", height: "50px" }} src={material.url} alt="material" />
                                    </td>
                                    <td className='material-name'>
                                        <LibraryInput data-customcontextmenu="allow" unEditable={hasEditPermission ? material.id !== currentElementId : true} textAlign='left' placeholder={"Material Name"} type={"text"} value={material.displayName} handleSubmit={(newValue) => { handlePropertyUpdate("custom_name", newValue, material) }} />
                                    </td>
                                    <td className='material-type'>
                                        <div className='pills-wrapper'>
                                            <div class='all-pills'>
                                                {
                                                    material.type.split(";").map(materialType => {
                                                        return <span key={materialType} className='pill'>{_.startCase(materialType)} <span style={{cursor: "pointer"}} onClick={() => {handlePropertyUpdate("mat_type", "-"+materialType.toLowerCase(), material)}}>&#10006;</span></span>
                                                    })
                                                }
                                            </div>
                                            <ActionDropdown
                                                actions={
                                                    MATERIAL_TYPE_OPTIONS.map(option => {
                                                        return { title: option, handleClick: () => { handlePropertyUpdate("mat_type", option.toLowerCase(), material) } }
                                                    })
                                                }
                                                dropdownAlign="right"
                                                unEditable={!hasEditPermission}
                                                icon={addIcon}
                                                iconStyles={{ width: '1.15em' }}
                                            />

                                        </div>
                                    </td>
                                    <td className='cost'>
                                        <LibraryInput unEditable={!hasEditPermission} placeholder={"Cost"} type={"number"} value={material.cost} handleSubmit={(newValue) => { handlePropertyUpdate("cost", newValue, material) }} />
                                    </td>
                                    <td className='manufacturer'>
                                        <LibraryInput unEditable={!hasEditPermission} placeholder={"Manufacturer"} type={"text"} value={material.manufacturer} handleSubmit={(newValue) => { handlePropertyUpdate("manufacturer", newValue, material) }} />
                                    </td>
                                    <td className='description'>
                                        <LibraryInput unEditable={!hasEditPermission} placeholder={"Description"} type={"text"} value={material.description} handleSubmit={(newValue) => { handlePropertyUpdate("description", newValue, material) }} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                contextMenu.show &&
                <LibraryContextMenu x={contextMenu.x} y={contextMenu.y} handleClose={() => { setContextMenu({ show: false }) }}>
                    {
                        contextMenuOptions.map(menuItem => {
                            return <div key={menuItem.title} className="menu-item" onClick={menuItem.handleClick}>
                                {menuItem.title}
                            </div>
                        })
                    }
                </LibraryContextMenu>
            }
        </Wrapper>
    );
}

export default Materials;