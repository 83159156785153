import React, { useState } from 'react';
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from '../Button';
import Input from '../Input';
import { PERMISSIONS } from '../../containers/dashboard/TeamDashboard/constants';
import _ from 'lodash';
import Toggle from '../Toggle';
import { showToast, TOAST_TYPES } from '../../snaptrude/modules/extrafunc';
import { createCustomRole } from '../../services/team.service';

const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  min-height: 200px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
  table{
    width: 100%;
  }
  th:first-child, td:first-child{
    text-align: left;
    min-width: 250px;
  }
  th, td{
      padding: 0.25em;
      min-width: 100px;
      text-align: center;
  }
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: col;
  padding: 0px 10px;
  align-items: center;
`
const StyledCol = styled.div`
  flex: 1;
  padding: 10px;
`
const recentBoxStyle = {
  boxStyle: {
    border: "none",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: colors.primeBlack,
    width: "100%",
  },
  customDropdownStyle: {
    position: "absolute",
    top: "-1.25rem",
    left: 0,
    right: 0,
    borderRadius: "0.313rem",
    paddingLeft: 0,
  },
};

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
`;
function CreateCustomRoleModal({
  isOpen,
  onClose,
  viewerPermissions,
  existingRoles,
  teamId,
}) {
  const [customRoleName, setCustomRoleName] = useState('');
  const [roleBasedPermissions, setRoleBasedPermissions] = useState(_.cloneDeep(viewerPermissions));
  const [isLoading, setIsLoading] = useState(false);
  const handleTogglePermission = (permissionKey) => {
    const newRoleBasedPermissions = {
      ...roleBasedPermissions,
      [permissionKey]: !roleBasedPermissions[permissionKey]
    }
    setRoleBasedPermissions(newRoleBasedPermissions);
  }
  const handleSubmit = async () => {
    // validate inputs
    let roleName = customRoleName;
    roleName = _.trim(roleName);
    roleName = _.lowerCase(roleName)
    if(roleName === ""){
      showToast("Must provide a role name");
      return;
    }
    if(existingRoles.includes(roleName.toLowerCase())){
      showToast("Role name not allowed");
      return;
    }

    setIsLoading(true);
    const resp = await createCustomRole(teamId, roleName, roleBasedPermissions);
    setIsLoading(false);
    if(resp.status === "success"){
      showToast("Added new role!", 3000, TOAST_TYPES.success);
      onClose();
    }else{
      showToast(resp.message, 3000, TOAST_TYPES.error);
    }

  }
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Create custom role
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          <StyledRow>
            <StyledCol>
              Role name
            </StyledCol>
            <StyledCol>
              <Input 
                placeholder={"Enter new role name"}
                value={customRoleName}
                onPress={(e)=>{setCustomRoleName(e.target.value)}}
                maxLength={50}
              />
            </StyledCol>
          </StyledRow>
          <StyledRow>
            <StyledCol style={{maxHeight: "300px", overflowY: "auto"}}>
              <table id="permissions-table">
                <tbody>
                    {/* PROJECTS */}
                    <tr>
                        <th>Projects</th>
                    </tr>
                    {
                        PERMISSIONS.PROJECTS.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    <td><Toggle state={roleBasedPermissions[permission.key]} handleClick={() => {handleTogglePermission(permission.key)}} /></td>
                                </tr>
                            )
                        })
                    }

                    {/* LIBRARIES */}
                    {/* <tr>
                        <th>Libraries</th>
                    </tr>
                    {
                        PERMISSIONS.LIBRARIES.map(permission => {
                            return (
                                <tr key={permission.key}>
                                  <td>{permission.title}</td>
                                  <td><Toggle state={roleBasedPermissions[permission.key]} handleClick={() => {handleTogglePermission(permission.key)}} /></td>
                                </tr>
                            )
                        })
                    } */}

                    {/* TEMPLATES */}
                    {/* <tr>
                        <th>Templates</th>
                    </tr>
                    {
                        PERMISSIONS.TEMPLATES.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    <td><Toggle state={roleBasedPermissions[permission.key]} handleClick={() => {handleTogglePermission(permission.key)}} /></td>
                                </tr>
                            )
                        })
                    }
                     */}
                    {/* GENERAL */}
                    <tr>
                        <th>General</th>
                    </tr>
                    {
                        PERMISSIONS.GENERAL.map(permission => {
                            return (
                                <tr key={permission.key}>
                                    <td>{permission.title}</td>
                                    <td><Toggle state={roleBasedPermissions[permission.key]} handleClick={() => {handleTogglePermission(permission.key)}} /></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            </StyledCol>

          </StyledRow>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button onPress={onClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Cancel" />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button primary title="Create" onPress={handleSubmit} isLoading={isLoading} />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  )
}

export default CreateCustomRoleModal;
