import BABYLON from "../modules/babylonDS.module";
import { store } from "../modules/utilityFunctions/Store";
import { findSecondarySnappedPoint } from "./snapFuncsPrimary";

const latlongOperator = (() => {
    const onPointerUp = (evt) => {
        const x = evt.clientX;
        const y = evt.clientY;
        let options = {
            faceSnap: false,
            edgeSnap: false,
            vertexSnap: true,
            doNotShowIndicators: false,
            // wantMetadata: true,
            // firstIndicators: true,
            // storeyCheck: true,
        };

        const pickedPoint = findSecondarySnappedPoint(null, null, null, options);
        // console.log(pickedPoint);
        if(pickedPoint){
            const pickedPointEvent = new CustomEvent("picked-point-event", {
                detail: pickedPoint
            });
            window.dispatchEvent(pickedPointEvent);
            var coordinates = BABYLON.Vector3.Project(pickedPoint,
                BABYLON.Matrix.Identity(),
                store.scene.getTransformMatrix(),
                store.scene.activeCamera.viewport.toGlobal(
                store.engine.getRenderWidth(),
                store.engine.getRenderHeight(),
            ));
            // console.log(coordinates);
            const latLongWrapper = document.getElementById("lat-long-input-wrapper");
            latLongWrapper.style.left = (coordinates.x + 20) + "px";
            latLongWrapper.style.top = (coordinates.y - 20) + "px";
            latLongWrapper.style.display = "flex";
        }
    }
    return {
        onPointerUp
    }
})();

export {
    latlongOperator
}