import { store } from "../utilityFunctions/Store.js";
import axios from "axios";
import { ORIGIN, socketUrl } from "../../../services/url.constants.js";

const SendItToTeam = (() => {
  const TEAMCHECK = {
    id: 1,
    email: "a@a.com",
  };

  const getHost = () => {
    if (window.location.hostname.toLowerCase().includes("united")) {
      return "united";
    } else if (window.location.hostname.toLowerCase().includes("app.snaptru.de") || window.location.hostname.toLowerCase().includes("staging.snaptru.de")) {
      return "dotde";
    } else if (window.location.hostname.toLowerCase().includes("app.snaptrude.com") || window.location.hostname.toLowerCase().includes("staging.snaptrude.com") ) {
      return "dotcom";
    }
  };

  const users = {
    altaf: {
      dotcom: {
        id: 538,
        email: "altaf@snaptrude.com",
      },
      dotde: {
        id: 538,
        email: "altaf@snaptrude.com",
      },
      united: {
        id: 538,
        email: "altaf@snaptrude.com",
      },
    },
    amritha: {
      dotcom: {
        id: 432,
        email: "amritha.krsn@gmail.com",
      },
      dotde: {
        id: 432,
        email: "amritha.krsn@gmail.com",
      },
      united: {
        id: 9,
        email: "amritha@snaptrude.com",
      },
    },
    manvith: {
      dotcom: {
        id: 2,
        email: "manvith@snaptrude.com",
      },
      dotde: {
        id: 2,
        email: "manvith@snaptrude.com",
      },
      united: {
        id: 4,
        email: "manvith@snaptrude.com",
      },
    },
    kuladeep: {
      dotcom: {
        id: 548,
        email: "kuladeep@snaptrude.com",
      },
      dotde: {
        id: 548,
        email: "kuladeep@snaptrude.com",
      },
      united: {
        id: 548,
        email: "kuladeep@snaptrude.com",
      },
    },
    sravan: {
      dotcom: {
        id: 967,
        email: "sravan@snaptrude.com",
      },
      dotde: {
        id: 588,
        email: "sravan@snaptrude.com",
      },
      united: {
        id: 6,
        email: "csravan83@gmail.com",
      },
    },
    shobit: {
      dotcom: {
        id: 1034,
        email: "shobhit@snaptrude.com",
      },
      dotde: {
        id: 743,
        email: "shobhit@snaptrude.com",
      },
      united: {
        id: 5,
        email: "shobhit@snaptrude.com",
      },
    },
    nischal: {
      dotcom: {
        id: 3495,
        email: "nischal@snaptrude.com",
      },
      dotde: {
        id: 780,
        email: "nischal@snaptrude.com",
      },
      united: {
        id: 538,
        email: "altaf@snaptrude.com",
      },
    },
    team: {
      dotcom: {
        id: 50,
        email: "hello@snaptrude.com",
      },
      dotde: {
        id: 50,
        email: "hello@snaptrude.com",
      },
      united: {
        id: 538,
        email: "altaf@snaptrude.com",
      },
    },
    /*trackd: {
            dotcom: {
                id: 538,
                email: 'altaf@snaptrude.com'
            },
            dotde: {
                id: 538,
                email: 'altaf@snaptrude.com'
            },
            united: {
                id: 538,
                email: 'altaf@snaptrude.com'
            }
        },*/
    pranav: {
      dotcom: {
        id: 1,
        email: "pranav@snaptrude.com",
      },
      dotde: {
        id: 1,
        email: "pranav@snaptrude.com",
      },
      united: {
        id: 1,
        email: "pranav@snaptrude.com",
      },
    },
  };

  const _createProject = destination => {
    const data = {
      source: store.floorkey,
      destination: destination,
    };
    const formData = new FormData();
    for(let item in data) formData.append(item, data[item]);
    return axios.post(ORIGIN + "/letuscheck/", formData)
    .then((res) => {
      if(res.data.error) console.log(`Error in SendItToTeam, ${res.data.error}`);
      else {
        return res.data.destination;
      }
    });
  };

  const _collectProject = (destination, authkey) => {
    const data = {
      source: store.floorkey,
      destination: destination.floorkey,
      destinationid: destination.id,
      destinationmail: destination.email,
      authkey: authkey,
    };
    return axios.post(socketUrl + "/copy/", data)
    .then((res) => {
      if(res.data.error) console.log(`Project copy error ${res.data.error}`);
      else {
        console.log(`Project copy success, ${store.floorkey} -> ${destination.floorkey}`);
      }
    });
  };

  const send = async (destination) => {
    const key = await _createProject(destination.id);
    console.log("Informed team, now collecting data");
    await _collectProject({
      floorkey: key,
      id: destination.id,
      email: destination.email,
    });
    console.log("Collected data for team, thanks!", key);
  };

  const sendTo = async (name) => {
    await send(users[name][getHost()]);
  };

  const now = async () => {
    await send(TEAMCHECK);
  };

  return {
    send,
    now,
    sendTo,
  };
})();

export { SendItToTeam };
