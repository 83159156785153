import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import dropArrow from "../../assets/icons/dropArrow.svg";
import useOutsideClickHandler from "../../common/utils/useOutsideClickHandler";
import { colors } from "../../themes/constant";

const MainWrapper = styled.div`
  position: relative;
  .topFixDropdown {
    top: 0;
  }
  .bottomFixDropdown {
    bottom: 0;
  }
`;

const MainDropdownWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${colors.fullWhite};
  border-radius: 5px;
  padding: 0.125rem 0;
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  .selected {
    p {
      color: ${colors.primeBlack};
      opacity: 1;
      font-weight: 500;
    }
  }
  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
`;

const EachRow = styled.div`
  cursor: pointer;
  p {
    fontfamily: DM Sans;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.333333px;
    color: ${colors.darkGrey};
    opacity: 0.7;
    padding: 0.125rem 0.6875rem;
    margin: 0;
  }
  &:hover {
    background-color: ${colors.white};
    p {
      color: ${colors.primeBlack};
      opacity: 1;
      font-weight: 500;
    }
  }
`;

const DropdownWarpper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || colors.fullWhite};
  height: ${(props) => props.height || ''};
  border-radius: 5px;
  padding: 0.125rem 0;
  p {
    margin: 0;
    padding-left: 0.5rem;
    font-family: DM Sans;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.211789px;
    color: ${colors.darkGrey};
  }
  img {
    width: 0.5rem;
    height: 0.5rem;
    padding-right: 0.5rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ScrollableDropdown = (props) => {
  const {
    selectedOption,
    options,
    width,
    height,
    cutomTextStyle,
    noheader,
    onChange,
    optionTextStyle,
    bottomFix,
    customDropArrow,
    backgroundColor,
    wrapperHeight,
    reduxState,
    selectedOptionIndex=0,
    controlled=false,
    autoClose=false
  } = props;
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  useOutsideClickHandler(ref, () => {
    if(autoClose) setOpen(false)
  })

  let [selectedOptionCopy, setSelectedOptionCopy] = useState(
      selectedOption || (options && options.length && options[selectedOptionIndex])
    );
  if (reduxState) {
    selectedOptionCopy = selectedOption;
    setSelectedOptionCopy = () => {};
  }

    useEffect(() => {
     if(controlled) setSelectedOptionCopy(selectedOption)
   }, [controlled, selectedOption])

  return (
    <MainWrapper
      style={{ width: width || "6.3125rem", maxWidth: width || "6.3125rem" }}
      ref={ref}
    >
      {!noheader && (
        <DropdownWarpper
          backgroundColor={backgroundColor}
          height={wrapperHeight}
          onClick={() => {
            setOpen((prevState) => !prevState);
          }}
        >
          <p style={cutomTextStyle}>{selectedOptionCopy}</p>
          <img src={customDropArrow || dropArrow} alt=">" />
        </DropdownWarpper>
      )}
      {open && options ? (
        <MainDropdownWrapper
          className={bottomFix ? "bottomFixDropdown" : "topFixDropdown"}
          style={{ width: width || "6.3125rem", height: height || "6.3125rem" }}
        >
          {options.map((item, index) => {
            return (
              <EachRow
                onClick={(evt) => {
                  setSelectedOptionCopy(item);
                  if (onChange, evt) onChange(item, evt);
                  setOpen();
                }}
                className={selectedOptionCopy === item ? "selected" : ""}
                key={index}
              >
                <p style={optionTextStyle}>{item}</p>
              </EachRow>
            );
          })}
        </MainDropdownWrapper>
      ) : null}
    </MainWrapper>
  );
};

export default ScrollableDropdown;
