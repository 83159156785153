"use strict";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { setActiveLayerAndRecord } from "../modules/extrafunc.js";
import { goIntoTwoD, isTwoDimension } from "./twoDimension.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
var layerView = null;
// store.angular.element(function () {
  layerView = (function () {
    // let $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
      let structureCollection;
      let _layerData = null;
      let refPlane = null;
    const init = () => {
      structureCollection = StructureCollection.getInstance();
    }

    var getStructures = function () {
      return structureCollection.getStructures();
    };

    var getLayersInStructure = function (id) {
      return getStructures()[id].getLayerData().getAllLayers();
    };

    var generateLayerData = function () {
      _layerData = {
        structures: [],
      };

      let structures = getStructures();

      for (let k = 0; k < Object.keys(structures).length; k++) {
        let str_key = Object.keys(structures)[k];
        if (str_key === "default") continue;

        let str_object = {
          structure_key: str_key,
          structure_name: "Structure" + str_key.split("_")[1],
          storeys: [],
        };

        for (let j = 0; j < Object.keys(store.storeysDS).length; j++) {
          str_object.storeys.push({
            id: "storey-" + Object.keys(store.storeysDS)[j],
            name: "Storey" + Object.keys(store.storeysDS)[j],
            serial: j + 1,
            layers: [],
          });

          let layers = getLayersInStructure(str_key);
          for (let i = 0; i < Object.keys(layers).length; i++) {
            if (layers[i].storey === j + 1) {
              str_object.storeys[j].layers.push({
                serial: i + 1,
                layer_name: layers[i].name,
                layer_id: layers[i].id,
              });
            }
          }
        }
        _layerData.structures.push(str_object);
      }

      // $scope.layerData = _layerData;
    };

    var addNewLayerToStorey = function (layerName, layerType, storey, options) {
      let layer = storey.layerData.addLayer(
        layerName,
        storey.structure_id,
        layerType,
        storey.value,
        options
      );
      if (layer === "Name exists") {
        //document.getElementById('nameCheck').innerHTML = "Name already exists";
      } else {
        // generateLayerData();
        // activateLayer(layerName, str_key, storeyNum);
        // there's a separate function for select layer. This call is causing
        // setLayerTransparency to be called 5 times on image upload
      }
    };

    var activateLayer = function (name, strId, storeyVal, options) {
      let structures = StructureCollection.getInstance();
      let str = structures.getStructureById(strId);
      let storeyData = str.getStoreyData().getStoreyByValue(storeyVal);
      /*lastLayer = (activeLayer)?activeLayer.name : 'Wall';
            camData = {
                pos: store.scene.activeCamera.position,
                target: store.scene.activeCamera.target
            }
            activeLayer.lastActiveLayer = lastLayer;
            activeLayer.cameraInfo = camData;*/

      // if (activeLayer) {
      //     if (activeLayer.floorplans.length > 0)
      //         activeLayer.floorplans[0].deactivate();
      // }
      setActiveLayerAndRecord(
        storeyData.layerData.getLayerByName(name, storeyData.value), options
      );
      generateRefPlane(storeyData);
      let conditions = ["terrain", "buildings"];
      if (!conditions.some((condition) => name.includes(condition)))
        goIntoTwoD(false, true);
      // setLayerTransperancy();
      // showAllLayersInStorey(storeyData);
    };

    var generateRefPlane = function (storey) {
      refPlane = store.scene.getMeshByName('layerRefPlane');
      if (!refPlane) {
          refPlane = BABYLON.Mesh.CreatePlane("layerRefPlane", 10000, store.newScene);
          refPlane.doNotSerialize = true;
          refPlane.visibility = 0;
          // overridden according to project properties below, setting this to add it appropriately in scenePickController
          scenePickController.add(refPlane);
      }

      refPlane.visibility = store.$scope.isTwoDimension?(ScopeUtils.getScope().userSetBIMProperties.gridEnabled)?1:0:0;
      refPlane.type = "LayerRefPlane";
      const ground = store.scene.getMeshByID("ground1");
      let refPlanePos = ground.position.clone();
      let refPlaneRot = ground.rotation.clone();
      refPlane.position = new BABYLON.Vector3(refPlanePos.x, refPlanePos.y, refPlanePos.z);
      refPlane.rotation = new BABYLON.Vector3(refPlaneRot.x, refPlaneRot.y, refPlaneRot.z);
      refPlane.rotation.x = Math.PI / 2;
      refPlane.position.y = storey.base;
      

      return refPlane;
    };

    var setActiveLayer = function (name, storeyVal, structureId) {
      let structure =
        StructureCollection.getInstance().getStructures()[structureId];
      let storey = structure.getStoreyData().getStoreyByValue(storeyVal);
      setActiveLayerAndRecord(storey.layerData.getLayerByName(name, storeyVal));
    };

    let getLayerFromLayerId = function (structureId, storeyVal, layerId){
      let structure = StructureCollection.getInstance().getStructures()[structureId];
      let storey = structure.getStoreyData().getStoreyByValue(storeyVal);
      return storey.layerData.getLayerBylayerId(layerId);
    }

    return {
      init,
      generateLayerData: function () {
        return generateLayerData();
      },
      appendNewLayer: function (name, storey, strId, options) {
        let structures = StructureCollection.getInstance();
        let str = structures.getStructureById(strId);
        let storeyData = str.getStoreyData().getStoreyByValue(storey);
        if (name.includes("cad")) {
          let layerType = "cad";
          addNewLayerToStorey(name, layerType, storeyData, options);
        } else if (name.includes("terrain")) {
          let layerType = "terrain";
          addNewLayerToStorey(name, layerType, storeyData, options);
        } else if (name.includes("buildings")) {
          let layerType = "buildings";
          addNewLayerToStorey(name, layerType, storeyData, options);
        } else {
          addNewLayerToStorey(name, name.toLowerCase(), storeyData, options);
        }

        //  let layer = storeyData.layerData.getLayerByName(name, storeyData.value);

        // var storey = document.getElementById('storey-number');
        // var addNewLayer = document.getElementById('add-layer');
        // var layerType = $('#layer-type');
        // var layerName = document.getElementById('layername');

        // storey.innerHTML = 'Storey' + storeyNum;

        // addNewLayer.onclick = function () {
        // };
        //
        // $('#addLayerModal').modal({
        //     backdrop: false,
        //     show: true
        // });
        //
        // $('.modal-dialog').draggable({
        //     handle: ".modal-header"
        // });
      },
      selectLayer: function (name, strId, storey, options) {
        activateLayer(name, strId, storey, options);
      },
      addAndSelectLayer: function (name, str_key, storey, options) {
        layerView.appendNewLayer(name, storey, str_key, options);
        layerView.selectLayer(name, str_key, storey, options);
      },
      generateDefaultLayers: function (structureId, storey) {
        var layerNames = ["Wall", "Rough"];

        let layers = [];
        for (let i = 0; i < layerNames.length; i++) {
          let layer = getStructures()
            [structureId].getLayerData()
            .addLayer(
              layerNames[i],
              structureId,
              layerNames[i].toLowerCase(),
              storey
            );
          layers.push(layer);
        }

        return layers;
      },
      generateRefPlane(storey) {
        generateRefPlane(storey);
      },
      getRefPlane() {
        return refPlane;
      },
      setActiveLayer,
      layerData: _layerData,
      getLayerFromLayerId: getLayerFromLayerId,
    };
  })();
// });
export { layerView };
