import { cursor } from "../../themes/cursor";

let currentCursor = null;
let previousCursor = null;
export const updateCursor = (newCursor = cursor.default) => {
    const canvasWrapper = document.getElementById("canvas-wrapper");
    if(canvasWrapper) canvasWrapper.style.cursor = newCursor;
    previousCursor = currentCursor;
    currentCursor = newCursor;
}

export const resetDefaultCursor = () => {
    const canvasWrapper = document.getElementById("canvas-wrapper");
    if(canvasWrapper) canvasWrapper.style.cursor = cursor.default;
    previousCursor = currentCursor;
    currentCursor = cursor.default;
}

export const addTemporaryCursor = (tempCursor) => {
    updateCursor(tempCursor);
}

export const removeTemporaryCursor = () => {
    const prevCursor = previousCursor || cursor.default;
    updateCursor(prevCursor);
}