/*jshint esversion: 6 */
"use strict";
import * as log from "loglevel";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { RoomEngine, CreatePlainRoof } from "./snaptrudeEngine.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";

function RoofBuilder() {
  this.bob = new RoofMaker();

  this.step1 = function (mass) {
    this.bob.createRoofs(mass);
  };

  this.step2 = function (mass) {
    //this.bob.save();
    //this.bob.combineRoofs();
  };

  this.step3 = function (mass) {
    //this.bob.save();
  };

  //add more

  this.get = function () {
    return this.bob;
  };
}

function RoofMaker() {
  this.roofs = [];

  /**
   * Creates walls for all the rooms in store.scene.
   */
  this.createRoofs = function (mass) {
    //roof generation
    var roomEngine = new RoomEngine();
    var structureCollection = StructureCollection.getInstance();
    var structure = structureCollection.getStructureById(mass.structure_id);
    var level = structure.getLevelByUniqueId(mass.level_id);

    try {
      roomEngine.execute(new CreatePlainRoof(mass.mesh));
      //add more
    } catch (e) {
      log.error(e);
      // var errorMessage = new ErrorMessage(mass.mesh, "error");
      // errorMessage.show("Ooops, can't generate wall for this structure");
      // mass.mesh.material = errorMessage.material;
    }
    log.log(roomEngine.getCurrentValue());
    let roofs = roomEngine.getCurrentValue();
    if (mass.isCircularMass())
      for (let _roofs of roofs) {
        for (let roof of _roofs) {
          roof.properties.belongsToCircularMass = true;
        }
      }
    _.flatten(roofs).forEach(function (roof) {
      // roof.hide();
      level.addRoofToLevel(roof, false);
      if (createBuildingEngine.isCreateRoom) roof.setSlabType();
      createBuildingEngine.addComponent(roof);
    });

    roofs.length = 0;
  };

  /**
   * { Resolve intersection method }
   */
  this.resolveIntersection = function () {
    return;
    //TODO
  };

  this.combineRoofs = function () {
    store.scene.meshes.forEach(function (mesh) {
      if (mesh.type === "Roof") {
        var aCSG = BABYLON.CSG.FromMesh(store.selectionStack[0]);
        var bCSG = BABYLON.CSG.FromMesh(store.selectionStack[1]);

        var subCSG = aCSG.union(bCSG);
        var mergedMesh = subCSG.toMesh("csg", null, store.newScene);
      }
    });
  };
}
export { RoofBuilder, RoofMaker };
