"use strict";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { changeToOrthoViewCamera } from "../../libs/cameraFuncs.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { showToast, onCamRot } from "../extrafunc.js";
import { AutoSave } from "../socket/autoSave.js";
import { ScopeUtils } from "../../libs/scopeFunctions.js";
import { layerView } from "../../libs/layerView.js";
import { loaderView } from "../../libs/loaderView.js";
import { AutoDetectDisplay } from "../displayOperations/AutoDetectDisplay.js";
import { Command } from "../commandManager/Command.js";
import { sketchOperation } from "../meshoperations/sketchOperation.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { cadImport } from "../../libs/cadImport.js";
import { removeMeshSelectionChildren } from "../../libs/meshEvents.js";
import { removeMouseEvents } from "../../libs/toolbarEvents.js";
import { scenePickController } from "../utilityFunctions/scenePickController.js";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants.js";
import { isNeighborhoodBuilding } from "../geo/terrainNeighborhood.js";

var setScaleOperation = (function () {
  let _dragScaleLine = null;
  let _dragScaleSphere1 = null;
  let _dragScaleSphere2 = null;
  let _scaleImage = null;
  let _pointerDragBehaviourSphere1;
  let _pointerDragBehaviourSphere2;
  let _inputBoxEnable = false;
  let _scaleType = null;
  let _setScaleDone = false;
  let _isSetScaleTriggered = null;
  let _pivotPoint = null;
  let _upload = false;
  let _initialDistance = null;

  function _createScale(scaleType, storeyNum, structureNum, upload = false) {
    let bbInfo;
    let points = [];

    if (scaleType === "imageScale" || scaleType === "AutoImageScale") {
      _scaleImage = store.scene.getMeshByName(
        "twoPlane_" + storeyNum + "_" + structureNum
      );
      bbInfo = _scaleImage.getBoundingInfo();

      let len = bbInfo.boundingBox.extendSizeWorld.x * 2;
      let scaleFactor =
        DisplayOperation.getOriginalDimension(38000, "millimeter") / len;
      _scaleImage.scaling.multiplyInPlace(
        new BABYLON.Vector3(scaleFactor, scaleFactor, scaleFactor)
      );

      _scaleImage.computeWorldMatrix(true);
      bbInfo = _scaleImage.getBoundingInfo();
      _scaleImage.position.x -= bbInfo.boundingBox.minimumWorld.x;
      _scaleImage.position.z -= bbInfo.boundingBox.maximumWorld.z;
      _scaleImage.computeWorldMatrix(true);

      let storeyHeight =
        StoreyMutation.getAllStoreys()[storeyNum.toString()].getHeight();
      points.push(
        new BABYLON.Vector3(
          bbInfo.boundingBox.minimumWorld.x +
            0.4 * bbInfo.boundingBox.maximumWorld.x,
          bbInfo.boundingBox.centerWorld.y + storeyHeight,
          bbInfo.boundingBox.centerWorld.z
        )
      );
      points.push(
        new BABYLON.Vector3(
          bbInfo.boundingBox.maximumWorld.x -
            0.4 * bbInfo.boundingBox.maximumWorld.x,
          bbInfo.boundingBox.centerWorld.y + storeyHeight,
          bbInfo.boundingBox.centerWorld.z
        )
      );
      _upload = true;
      if (store.$scope.isTwoDimension) changeToOrthoViewCamera(true);
      _scaleType = scaleType;
      // _initialDistance = distanceFromActiveCamera(
      //   store.scene.getMeshByName("layerRefPlane").position
      // );
      _initialDistance = distanceFromActiveCameraUsingOrthoValues();
    } else if (scaleType === "meshScale") {
      _scaleType = scaleType;
      let structureCollection = StructureCollection.getInstance();
      let meshes;
      _upload = upload;
      if (_upload) {
        meshes = store.activeLayer.sketches.map((sketch) => sketch.mesh);
      } else {
        meshes = structureCollection
          .getStructures()
          [store.activeLayer.structure_id].getActiveStoreyMeshes(
            store.activeLayer.storey
          );
      }
      meshes = meshes.filter((m) => m.name !== "terrain");
      meshes = meshes.filter((m) => !isNeighborhoodBuilding(m));
      meshes = meshes.filter(
        (m) => m.type.toLowerCase().indexOf("throwaway") === -1
      );
      if (meshes.length === 0) {
        setScaleOperation._reset();
        showToast("No objects to scale");
        return "MESHES0";
      } else {
        let vec_arr = [];
        meshes.forEach(function (mesh) {
          let bbInfo = mesh.getBoundingInfo();
          vec_arr.push(bbInfo.boundingBox.maximumWorld);
          vec_arr.push(bbInfo.boundingBox.minimumWorld);
          store.selectionStack.push(mesh);
        });
        let x_min = _.minBy(vec_arr, "x");
        let y_min = _.minBy(vec_arr, "y");
        let z_min = _.minBy(vec_arr, "z");

        let x_max = _.maxBy(vec_arr, "x");
        let y_max = _.maxBy(vec_arr, "y");
        let z_max = _.maxBy(vec_arr, "z");

        bbInfo = new BABYLON.BoundingInfo(
          new BABYLON.Vector3(x_min.x, y_min.y, z_min.z),
          new BABYLON.Vector3(x_max.x, y_max.y, z_max.z)
        );
        _pivotPoint = bbInfo.boundingBox.centerWorld;

        points.push(
          new BABYLON.Vector3(
            bbInfo.boundingBox.minimumWorld.x +
              0.2 * bbInfo.boundingBox.maximumWorld.x,
            bbInfo.boundingBox.maximumWorld.y,
            bbInfo.boundingBox.centerWorld.z
          )
        );
        points.push(
          new BABYLON.Vector3(
            bbInfo.boundingBox.maximumWorld.x -
              0.2 * bbInfo.boundingBox.maximumWorld.x,
            bbInfo.boundingBox.maximumWorld.y,
            bbInfo.boundingBox.centerWorld.z
          )
        );
      }
    }

    removeSetScaleObjects();
    store.scene.getMeshByName("layerRefPlane").isPickable = false;
    let _terrainMeshes = store.scene.meshes.filter((m) =>
      m.name ? m.name.includes("terrain") : false
    );
    _terrainMeshes.forEach((m) => {
      m.isPickable = false;
    });
    let _neighborhoodBuildingMeshes = store.scene.meshes.filter((m) =>
        isNeighborhoodBuilding(m)
      );
    _neighborhoodBuildingMeshes.forEach((m) => {
        m.isPickable = false;
      });
    _dragScaleLine = _drawScaleLine(points, "dragScaleLine");
    _dragScaleSphere1 = _drawScaleSphere(
      points[0],
      distanceFromActiveCamera(points[1]),
      "dragScaleSphere1"
    );
    _dragScaleSphere2 = _drawScaleSphere(
      points[1],
      distanceFromActiveCamera(points[1]),
      "dragScaleSphere2"
    );
    // _initialCameraDistance = distanceFromActiveCamera(points[1]);
    _inputBoxEnable = true;

    DisplayOperation.setDimMesh(_dragScaleLine);
    DisplayOperation.displayOnMove(
      BABYLON.Vector3.Distance(
        _dragScaleSphere1.position,
        _dragScaleSphere2.position
      ),
      "measureBox",
      undefined,
      { setPretext: true }
    );
  }

  function _addBehaviours() {
    _pointerDragBehaviourSphere1 = new BABYLON.PointerDragBehavior({
      dragPlaneNormal: new BABYLON.Vector3(0, 1, 0),
    });
    _pointerDragBehaviourSphere2 = new BABYLON.PointerDragBehavior({
      dragPlaneNormal: new BABYLON.Vector3(0, 1, 0),
    });

    _pointerDragBehaviourSphere1.useObjectOrienationForDragging = false;
    _pointerDragBehaviourSphere2.useObjectOrienationForDragging = false;

    _pointerDragBehaviourSphere1.onDragObservable.add((event) => {
      if (_dragScaleLine) {
        _dragScaleLine.dispose();
        let points = [event.dragPlanePoint, _dragScaleSphere2.position];
        _dragScaleLine = _drawScaleLine(points, "dragScaleLine");
        _dragScaleSphere1.position = event.dragPlanePoint;
        DisplayOperation.setDimMesh(_dragScaleLine);
      }
      DisplayOperation.displayOnMove(
        BABYLON.Vector3.Distance(
          _dragScaleSphere1.position,
          _dragScaleSphere2.position
        ),
        "measureBox"
      );
    });
    _pointerDragBehaviourSphere2.onDragObservable.add((event) => {
      if (_dragScaleLine) {
        _dragScaleLine.dispose();
        let points = [_dragScaleSphere1.position, event.dragPlanePoint];
        _dragScaleLine = _drawScaleLine(points, "dragScaleLine");
        _dragScaleSphere2.position = event.dragPlanePoint;
        DisplayOperation.setDimMesh(_dragScaleLine);
      }
      DisplayOperation.displayOnMove(
        BABYLON.Vector3.Distance(
          _dragScaleSphere1.position,
          _dragScaleSphere2.position
        ),
        "measureBox"
      );
    });

    _dragScaleSphere1.addBehavior(_pointerDragBehaviourSphere1);
    _dragScaleSphere2.addBehavior(_pointerDragBehaviourSphere2);
    // when drag behavior is added, babylon creates a plane called "pointerDragPlane" based on dragPlaneNormal
        // and uses it for picking. After pick optimizations, this mesh is not picked, causing drag observable to fail

        // Either I can change scenePickController.getPickEligibleMeshes to return all scene.meshes or add the mesh to the list
        // I'm taking approach 2 because this seems to be a one-off case. If multiple similar cases are found, I'll take the former approach.

        // Change in plan. Babylon adds "pointerDragPlane" to a new scene object not the one with our meshes.
        // So, approach 2 doesn't work. Taking approach 1.

  }

  function _removeBehaviours() {
    if (_pointerDragBehaviourSphere1)
      _dragScaleSphere1.removeBehavior(_pointerDragBehaviourSphere1);
    if (_pointerDragBehaviourSphere2)
      _dragScaleSphere2.removeBehavior(_pointerDragBehaviourSphere2);
  }

  var _drawScaleLine = function (points, name) {
    let line = BABYLON.MeshBuilder.CreateLines(
      name,
      { points: points },
      store.scene
    );
    line.color = new BABYLON.Color3.Blue();
    line.renderingGroupId = 1;

    const sum = points.reduce((sum, v3) => sum.add(v3), BABYLON.Vector3.Zero());
    const position = sum.scale(1 / points.length);

    line.snaptrudeProperties = {
      position,
    };

    return line;
  };

  var _drawScaleSphere = function (point, distance, name) {
    let diameter = null;
    if (!store.scene.getMeshByName(name)) {
      diameter = distance / 70;
      if(diameter > 5) diameter = 5;
      if (diameter < 0.5) diameter = 0.5;
      // else if (diameter > 0.75) diameter = 0.75;

      let sphere = BABYLON.Mesh.CreateSphere(name, 10, diameter, store.scene);
      sphere.position = point;

      let sphereMat;
      if (!store.scene.getMaterialByName("scaleSphereMat")) {
        sphereMat = new BABYLON.StandardMaterial("scaleSphereMat", store.scene);
        sphereMat.diffuseColor = new BABYLON.Color3.Blue();
        sphereMat.specularColor = new BABYLON.Color3(0.2, 0.2, 0.2);
      } else sphereMat = store.scene.getMaterialByName("scaleSphereMat");
      sphere.material = sphereMat;
      sphere.renderingGroupId = 1;

      sphere.type = GLOBAL_CONSTANTS.strings.identifiers.utilityElement;
      scenePickController.add(sphere);
      

      return sphere;
    }
  };

  var distanceFromActiveCamera = function (point) {
    let cameraPosition = store.scene.activeCamera.position;
    return BABYLON.Vector3.Distance(point, cameraPosition);
  };

  let distanceFromActiveCameraUsingOrthoValues = function (point){
    let camera = store.scene.activeCamera;
    return Math.abs(camera.orthoTop - camera.orthoBottom) / 6;
  }

  var removeSetScaleObjects = function () {
    if (store.scene.getMeshByName("dragScaleLine"))
      store.scene.getMeshByName("dragScaleLine").dispose();
    if (store.scene.getMeshByName("dragScaleSphere1"))
      store.scene.getMeshByName("dragScaleSphere1").dispose();
    if (store.scene.getMeshByName("dragScaleSphere2"))
      store.scene.getMeshByName("dragScaleSphere2").dispose();
  };

  let scaleSketches = function (allSketches, scale, origin) {
    let scaledUpSketchPoints = [];
    for (let i = 0; i < allSketches.length; i++) {
      let oneCurve = allSketches[i];
      let b = [];
      for (let j = 0; j < oneCurve.length; j++) {
        let oneCurveLocal = oneCurve[j];
        let a = [];
        for (let k = 0; k < oneCurveLocal.length; k++) {
          let sketchPoint = oneCurveLocal[k];
          let xScaled = (sketchPoint.x - origin[0]) * scale + origin[0];
          let yScaled = sketchPoint.y;
          let zScaled = (sketchPoint.z - origin[2]) * scale + origin[2];
          let scaledVec = new BABYLON.Vector3(xScaled, yScaled, zScaled);
          a.push(scaledVec);
        }
        b.push(a);
      }
      b.color = allSketches[i].color;
      b.thickness = allSketches[i].thickness;
      scaledUpSketchPoints.push(b);
    }
    return scaledUpSketchPoints;
  };

  function saveImageUploadChanges(floorPlan) {
    let dataAfter = {};
    dataAfter.scaling = floorPlan.mesh.scaling.asArray();
    dataAfter.position = floorPlan.mesh.position.asArray();

    let updateFloorPlanSaveData = AutoSave.getSaveDataPrototype();
    updateFloorPlanSaveData.commandId = floorPlan.id + "_wm1";
    updateFloorPlanSaveData.data.saveType = "updateFloorPlanWM";
    updateFloorPlanSaveData.data.identifier =
      AutoSave.getComponentIdentifier(floorPlan);
      updateFloorPlanSaveData.data.identifier.layer_id = floorPlan.layer_id;
    updateFloorPlanSaveData.data.afterOperationData = {
      data: dataAfter,
    };
    AutoSave.directPublish(updateFloorPlanSaveData);
  }

  function scale() {
    try {
      if (!_scaleType) {
        throw new Error("ScaleType is null");
      } else {
        let _inputCurrentValue = ScopeUtils.getDisplayInputCurrentValue();

        //Checking the type coz when input is not entered, its returning just num 1 or 0. That case is handle after this loop.
        if (typeof _inputCurrentValue !== "number")
        {
          if (_inputCurrentValue.toString().match("^0*$")) {
            showToast("Can't set value to Zero");
            return;
          }
          let _inputCurrentValueToInt = parseInt(_inputCurrentValue);
          let _inputValueInSnaptrudeUnits = DisplayOperation.getOriginalDimension(_inputCurrentValueToInt, store.$scope.units_type.value);
          let _minimumValueAllowed = DisplayOperation.getOriginalDimension( 100, "millimeter");
          if( _inputValueInSnaptrudeUnits < _minimumValueAllowed ){
            showToast( " Please enter a greater value ");
            return;
          }
        }

        let scaleFactor;

        if (ScopeUtils.getDisplayInputOldValue() !== 0) {
          scaleFactor =
            DisplayOperation.getOriginalDimension(
              ScopeUtils.getDisplayInputCurrentValue()
            ) /
            DisplayOperation.getOriginalDimension(
              ScopeUtils.getDisplayInputOldValue()
            );
        }

        if (
          ScopeUtils.getDisplayInputCurrentValue() === 1 ||
          ScopeUtils.getDisplayInputCurrentValue() === 0 ||
          ScopeUtils.getDisplayInputCurrentValue() === ""
        ) {
          scaleFactor = 1;
        }

        if (_scaleType === "imageScale" || _scaleType === "AutoImageScale") {
          let structure = StructureCollection.getInstance().getStructureById(
            store.activeLayer.structure_id
          );
          let storey = structure
            .getStoreyData()
            .getStoreyByValue(store.activeLayer.storey);
          let imageLayer = storey.layerData.getLayerByName(
            "Image",
            storey.value
          );
          let imagePlane = imageLayer.floorplans[0].mesh;
          imagePlane.scaling.multiplyInPlace(
            new BABYLON.Vector3(scaleFactor, scaleFactor, scaleFactor)
          );
          imageLayer.floorplans[0].scaleFactor = scaleFactor;

          imagePlane.computeWorldMatrix(true);
          let bbInfo = imagePlane.getBoundingInfo();
          imagePlane.position.x -= bbInfo.boundingBox.minimumWorld.x;
          imagePlane.position.z -= bbInfo.boundingBox.maximumWorld.z;

          layerView.generateRefPlane(storey);
          imageLayer.floorplans[0].mesh.computeWorldMatrix(true);
          _setScaleDone = true;

          if (_scaleType === "AutoImageScale") {
            loaderView.showLoader();
            AutoDetectDisplay.AutodetectDisplay();
          }
          // imagePlane.layer_id = imageLayer.id;
          saveImageUploadChanges(imageLayer.floorplans[0]);
        } else if (_scaleType === "meshScale") {
          if (store.selectionStack.length > 0) {
            let defStructure =
              StructureCollection.getInstance().getStructures()[
                store.activeLayer.structure_id
              ];
            let layers = defStructure
              .getStoreyData()
              .getStoreyByValue(store.activeLayer.storey)
              .layerData.getAllLayers();
            let layerData = {};

            for (let key in layers) {
              if (layers[key].layerType === "cad") {
                continue;
              }
              if (layers.hasOwnProperty(key)) {
                let val = layers[key];
                layerData[val.name] = [];
                for (let i = 0; i < val.sketches.length; i++) {
                  val.sketches[i].points.color = val.sketches[i].color;
                  val.sketches[i].points.thickness = val.sketches[i].thickness;
                  layerData[val.name].push(val.sketches[i].points);
                }
                // layers[key].deleteAllSketches();
              }
            }

            let meshes = store.selectionStack.filter(
              (m) => m.name.toLowerCase() !== "sketchline"
            );
            meshes = meshes.filter((m) => m.name !== "terrain");
            meshes = meshes.filter((m) => !isNeighborhoodBuilding(m));

            let scaledLayerData = {};
            for (let key in layerData) {
              if (layerData.hasOwnProperty(key)) {
                let points = layerData[key];
                scaledLayerData[key] = [];
                let scaledPoints = scaleSketches(
                  points,
                  scaleFactor,
                  [0, 0, 0]
                );
                scaledLayerData[key].push(scaledPoints);
              }
            }

            let getSaveDataForScaledSketches = function () {
              let data = this.data;

              let saveData = AutoSave.getSaveDataPrototype();
              saveData.commandId = this.id;
              saveData.data.saveType = "setScaleSketches";
              saveData.data.identifier = {
                floorkey: store.floorkey,
                structure_id: data.structureId,
                storey: data.storey.toString(),
              };

              saveData.data.beforeOperationData = data.layers;
              saveData.data.afterOperationData = _.mapValues(
                data.layers,
                (points) =>
                  scaleSketches(points, scaleFactor, data.pivot.asArray())
              );

              return saveData;
            };

            //let scaledSketchPoints = scaleSketches(layerData["Wall"].points, scaleFactor, [0, 0, 0]);
            let _executeEvent = function () {
              let data = {
                structureId: store.activeLayer.structure_id,
                storey: store.activeLayer.storey,
                layers: layerData,
                pivot: _pivotPoint,
              };
              let sketchCommandName = "sketchOperationOnSetScale";
              return new Command(
                sketchCommandName,
                data,
                getCommandLogic(),
                getSaveDataForScaledSketches
              );
              // CommandManager.execute(cmd, true);
            };

            let scaleUpSketch = function () {
              let data = this.data;
              for (let key in layers) {
                if (
                  layers[key].layerType !== "cad" &&
                  layers.hasOwnProperty(key)
                ) {
                  layers[key].deleteAllSketches();
                }
              }
              for (let key in data.layers) {
                if (data.layers.hasOwnProperty(key)) {
                  let points = data.layers[key];
                  let scaledPoints = scaleSketches(
                    points,
                    scaleFactor,
                    data.pivot.asArray()
                  );
                  let _data = {};
                  for (let i = 0; i < scaledPoints.length; i++) {
                    _data.data = {};
                    _data.data.points = scaledPoints[i];
                    _data.data.color = scaledPoints[i].color;
                    _data.data.thickness = scaledPoints[i].thickness;
                    _data.data.structureId = data.structureId;
                    _data.data.storey = data.storey;
                    _data.data.layerName = key;
                    sketchOperation.createSketch.call(_data);
                  }
                }
              }
            };
            let scaleDownSketch = function () {
              let data = this.data;
              for (let key in layers) {
                if (
                  layers[key].layerType !== "cad" &&
                  layers.hasOwnProperty(key)
                ) {
                  layers[key].deleteAllSketches();
                }
              }
              for (let key in data.layers) {
                if (data.layers.hasOwnProperty(key)) {
                  let points = data.layers[key];
                  let _data = {};
                  for (let i = 0; i < points.length; i++) {
                    _data.data = {};
                    _data.data.points = points[i];
                    _data.data.color = points[i].color;
                    _data.data.thickness = points[i].thickness;
                    _data.data.structureId = data.structureId;
                    _data.data.storey = data.storey;
                    _data.data.layerName = key;
                    sketchOperation.createSketch.call(_data);
                  }
                }
              }
            };

            let getCommandLogic = function () {
              return {
                execute: scaleUpSketch,
                unexecute: scaleDownSketch,
              };
            };

            let optionsBeforeSetScale = {
              params: [
                commandUtils.worldMatrixChangeOperations.PARAMS.position,
                commandUtils.worldMatrixChangeOperations.PARAMS.scale,
              ],
              stack: meshes,
              operationOnChild: true,
            };

            let setScaleBeforeCommandData =
              commandUtils.worldMatrixChangeOperations.getCommandData(
                null,
                optionsBeforeSetScale
              );

            let parentMesh = new BABYLON.TransformNode(
              "setScaleNode",
              store.newScene
            );
            parentMesh.position = _pivotPoint;

            meshes.forEach(function (mesh) {
              if (mesh.uniqueId !== parentMesh.uniqueId) {
                if (mesh.parent) mesh._prevParent = mesh.parent.uniqueId;
                mesh.setParent(parentMesh);
              }
            });

            parentMesh.scaling.multiplyInPlace(
              new BABYLON.Vector3(scaleFactor, 1, scaleFactor)
            );
            parentMesh.computeWorldMatrix(true);

            meshes.forEach(function (mesh) {
              mesh.state = "off";
              if (mesh._prevParent)
                mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
              else mesh.setParent(null);
            });

            parentMesh.dispose();
            optionsBeforeSetScale.data = setScaleBeforeCommandData;
            let sketchesScaleCommand = _executeEvent();

            // Passed null in place of stack, meshes(stack) passed in options
            let setScaleAfterCommandData =
              commandUtils.worldMatrixChangeOperations.getCommandData(
                null,
                optionsBeforeSetScale
              );

            let meshesScaleCommand =
              commandUtils.worldMatrixChangeOperations.getCommand(
                "setScale",
                setScaleAfterCommandData
              );

            const components = meshes.map((m) => m.getSnaptrudeDS());
            const integrationCommand =
              virtualSketcher.updateWithGeometryEdit(components);

            CommandManager.execute(
              _.compact([
                meshesScaleCommand,
                sketchesScaleCommand,
                integrationCommand,
              ]),
              [false, true, false]
            );

            if (store.isCADFile) cadImport.autoSaveCad();
          }
        } /*else {

                }*/

        ScopeUtils.setDisplayInputValue(0);

        // Reset global variables

        if (store.selectionStack.length > 0) {
          store.selectionStack.forEach(function (mesh) {
            removeMeshSelectionChildren(mesh);
            mesh.state = "off";
          });
        }
        store.selectionStack.length = 0;

        if (store.$scope.isTwoDimension) changeToOrthoViewCamera(true);
        removeMouseEvents();
        onCamRot();
        showToast(
          "Objects scaled by factor of " + Math.round(scaleFactor * 1000) / 1000
        );
      }
      setScaleOperation._reset();
      document.getElementById("canvas").style.zIndex = 1;
      ScopeUtils.showToolBar();
      ScopeUtils.showSection();
    } catch (err) {
      console.log(err);
      setScaleOperation._reset();
      document.getElementById("canvas").style.zIndex = 1;
      ScopeUtils.showToolBar();
      ScopeUtils.showSection();
    }
  }

  var setScaleCheck = function () {
    return _setScaleDone;
  };
  let isScaleTriggered = function () {
    return _isSetScaleTriggered;
  };
  function _updateSpheres() {
    let sphere1 = store.scene.getMeshByName("dragScaleSphere1");
    let sphere2 = store.scene.getMeshByName("dragScaleSphere2");
    _dragScaleLine.dispose();
    let zoomInScaleFactor = 0.950;
    let zoomOutScaleFactor = 1.075;

    let layerRefPlanePosition =
      store.scene.getMeshByName("layerRefPlane").position;
    // let _currentDistance = distanceFromActiveCamera(layerRefPlanePosition);
    let _currentDistance = distanceFromActiveCameraUsingOrthoValues();

    if (
      sphere1 &&
      sphere2 &&
      store.scene.activeCamera.position.y > layerRefPlanePosition.y
    ) {
      if (_currentDistance < _initialDistance) {
        sphere1.scaling.multiplyInPlace(
          new BABYLON.Vector3(
            zoomInScaleFactor,
            zoomInScaleFactor,
            zoomInScaleFactor
          )
        );
        sphere2.scaling.multiplyInPlace(
          new BABYLON.Vector3(
            zoomInScaleFactor,
            zoomInScaleFactor,
            zoomInScaleFactor
          )
        );
      } else {
        sphere1.scaling.multiplyInPlace(
          new BABYLON.Vector3(
            zoomOutScaleFactor,
            zoomOutScaleFactor,
            zoomOutScaleFactor
          )
        );
        sphere2.scaling.multiplyInPlace(
          new BABYLON.Vector3(
            zoomOutScaleFactor,
            zoomOutScaleFactor,
            zoomOutScaleFactor
          )
        );
      }
    }
    _dragScaleLine = _drawScaleLine(
      [sphere1.position, sphere2.position],
      "dragScaleLine"
    );
    DisplayOperation.setDimMesh(_dragScaleLine);
    DisplayOperation.displayOnMove(
      BABYLON.Vector3.Distance(
        _dragScaleSphere1.position,
        _dragScaleSphere2.position
      ),
      "measureBox"
    );
    // _initialDistance = distanceFromActiveCamera(layerRefPlanePosition);
    _initialDistance = distanceFromActiveCameraUsingOrthoValues();
  }
  return {
    createScale: function (scaleType, storey, structure, upload) {
      document.getElementById("canvas").style.zIndex = 15;
      ScopeUtils.hideToolBar();
      ScopeUtils.hideSection();
      try {
        let create = _createScale(scaleType, storey, structure, upload);
        if (create !== "MESHES0") _addBehaviours();
      } catch (err) {
        console.error(err);
        document.getElementById("canvas").style.zIndex = 1;
        ScopeUtils.showToolBar();
        ScopeUtils.showSection();
      }
    },
    addBehaviours: function () {
      _addBehaviours();
    },
    removeBehaviours: function () {
      _removeBehaviours();
    },
    isInputBoxEnabled: function () {
      return _inputBoxEnable;
    },
    getScaleType: function () {
      return _scaleType;
    },
    scale,
    updateSpheres: _updateSpheres,
    setScaleCheck: setScaleCheck,
    resetCheck: function () {
      _setScaleDone = false;
    },
    isUpload: function () {
      return _upload;
    },
    _reset: function (switchingBetweenViews=false) {
      _removeBehaviours();
      removeSetScaleObjects();
      _dragScaleLine = null;
      _dragScaleSphere1 = null;
      _dragScaleSphere2 = null;
      _scaleImage = null;
      _pointerDragBehaviourSphere1 = null;
      _pointerDragBehaviourSphere2 = null;
      _inputBoxEnable = false;
      _scaleType = null;
      _pivotPoint = null;
      _upload = false;
      if (!switchingBetweenViews) {
        if (store.selectionStack.length > 0) {
          store.selectionStack.forEach(function (mesh) {
            removeMeshSelectionChildren(mesh);
            mesh.state = "off";
          });
        }
        store.selectionStack.length = 0;
      }
      let _terrainMeshes = store.scene.meshes.filter((m) =>
        m.name.includes("terrain")
      );
      _terrainMeshes.forEach((m) => {
        m.isPickable = true;
      });
      let _neighborhoodBuildingMeshes = store.scene.meshes.filter((m) =>
        isNeighborhoodBuilding(m)
      );
      _neighborhoodBuildingMeshes.forEach((m) => {
        m.isPickable = true;
      });
      document.getElementById("canvas").style.zIndex = 1;
      store.scene.getMeshByName("layerRefPlane").isPickable = true;
    },
  };
})();
export { setScaleOperation };
