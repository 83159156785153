import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button/index";
import Logo from "../Logo/index";
import polygon from "../../assets/icons/polygon.svg";
import bellIcon from "../../assets/icons/bell.svg";
import bellBadgeIcon from "../../assets/icons/bellBadge.svg";
import bellActiveIcon from "../../assets/icons/bellActive.svg";
import help from "../../assets/icons/help.svg";
import bookdemoIcon from "../../assets/icons/bookademo.svg";
import PropTypes from "prop-types";
import { routes } from "../../routes/constants";
import { Link, NavLink, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { colors } from "../../themes/constant";

import AuthService from "../../services/auth.service";
import authService from "../../services/auth.service";
import { useContext } from "react";
import HistoryContext from "../Context/HistoryContext";
import { createBimProject, getProjects } from "../../services/projects.service";
import CreateProjectModal from "../SnaptrudeModal/CreateProjectModal";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import MenuList from "../MenuList";
import { getIsPro } from "../../services/payment.service";
import { getUserProfilePictureUrl } from "../../services/account.service";
import InviteModal from "../SnaptrudeModal/InviteModal";
import { NAVITEMS_ENTERPRISE, NAVITEMS_PRO } from "./constants";
import TeamInviteModal from "../TeamModal.js";
import { createTeamProject, getPendingTeamInvites, isTeamPlanActive } from "../../services/team.service";
import { useSelector } from "react-redux";
import DeleteTeamModal from "../SnaptrudeModal/DeleteTeamModal";
import RenameTeamModal from "../SnaptrudeModal/RenameTeamModal";
import NotificationBar from "../AllNotifications";
import TeamContextMenu from "../ContextMenu/TeamContextMenu";
import LeaveTeamModal from "../SnaptrudeModal/LeaveTeamModal";
import AcceptTeamInviteModal from "../SnaptrudeModal/AcceptTeamInviteModal";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import Separator from "../Divider/Seperator";
import CreateFolderModal from "../SnaptrudeModal/CreateFolderModal";
import WorkspaceSidebar from "./WorkspaceSidebar";
import { useFileSystem, useFileSystemUpdate } from "../../contexts/FileSystemContext";
import RenameFolderModal from "../SnaptrudeModal/RenameFolderModal";
import DeleteFolderModal from "../SnaptrudeModal/DeleteFolderModal";
import { useTeamRightClickMenu, useTeamRightClickMenuUpdate } from "../../contexts/TeamRightClickMenuContext";
import { REGEXP, SIDEBAR_WIDTH } from "../../common/utils/constants";
const StyledSidebar = styled.div`
  min-width: ${SIDEBAR_WIDTH}px;
  max-width: ${SIDEBAR_WIDTH}px;
  width: ${SIDEBAR_WIDTH}px;
  // border: 10px solid cadetblue;
  box-sizing: border-box;
  height: 100vh;
  max-height: 100vh;
  background-color: ${(props) => props.theme.colors.clearWhite};
  font-family: DM Sans;
  overflow: hidden;
  z-index : 50;
  position: sticky;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey};
  h1 {
    font-size: 2rem;
    text-align: left;
    padding-top: 1rem;
    margin-left: 1rem;
    margin-top: 0;
  }
  h5,
  h3 {
    font-size: 0.875rem;
    font-weight: normal;
    color: ${colors.thinGray};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-left: 1rem;
  }
  h4 {
    font-size: 0.875rem;
    font-weight: normal;
    margin-left: 0.6rem;
  }
  h5 {
    margin: 0.8rem 0 0 0;
    height: 2rem;
  }
  h3 {
    height: 2rem;
    margin: 0;
    display: flex;
  }
  .collapsible-title {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primeBlack};
    opacity: 0.5;
  }
  .darkOpacity {
    opacity: 0.6;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  ul li {
    font-size: 0.875rem;
    cursor: pointer;
    padding: 0.5rem 0 0.5rem 1em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    color: ${colors.thinGray};
  }
  span:not(.collapsible-title) {
    display: flex;
    align-items: center;
  }
  ul li:hover,
  h3:hover,
  h5:hover {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 0.313rem;
    width: 100%;
  }
  ul li:hover .display-not {
    display: block;
  }
  h3:hover .display-not {
    display: block;
  }
  hr {
    width: 85%;
    background-color: ${(props) => props.theme.colors.mercury};
    border: none;
    height: 0.2vh;
  }

  .icons img:nth-child(1) {
    margin-right: 0.8rem;
    width: 1.125rem;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.3);
  }
  .profile-div {
    cursor: pointer;
    width: 100%;

    a {
      margin: 1rem 0 1rem 1rem;
      height: 4vh;
      display: flex;
    }
  }
  .profile-div .row {
    display: flex;
    align-items: center;
  }
  .profile-div img {
    border-radius: 12.5rem;
    border: 0.1rem solid ${(props) => props.theme.colors.brightRed};
  }
  .button-div {
    margin: 0 1rem;
    margin-bottom: 11px;
  }
  .display-not {
    display: none;
  }
  .nested-lists {
    display: inline-block;
    list-style: none;
  }
  .resources-div img,
  .notification-div img,
  .workspace-div img {
    width: 1rem;
    position: relative;
    margin-right: 0.8rem;
  }
  .toggle-icon {
    transform: matrix(1, 0, 0, -1, 0, 0);
    width: 0.6rem !important;
  }
  .logo-container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding: 1rem 0 0.5rem 0;
  }
  .options-container {
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .options-container > div {
    box-sizing: border-box;
  }
  .workspace-div{
    // overflow: auto;
    // overflow-x: hidden;
    // flex: 1;
    // overscroll-behavior: contain;
  }
  a.selected {
    border-right: 0.125rem solid ${(props) => props.theme.colors.brightRed};
    display: flex;
    color: ${(props) => props.theme.colors.darkGrey};

    li {
      color: ${(props) => props.theme.colors.darkGrey};
      font-weight: bold;
    }
  }
  a.selected.no-border-highlight{
    border-right: 0px;
  }
  .selected h3 {
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkGrey};
  }
  .selected li:hover,
  .selected h3:hover {
    background-color: ${(props) => props.theme.colors.fullWhite};
  }
  .member-count{
    border-radius: 1em;
    padding: 0em 0.25em;
    font-size: small;
    display: flex;
    justify-content: space-around;
    align-items: center;
    visibility: hidden;
  }
  .member-count span{
    padding: 0.15em;
  }
  h3:hover .member-count{
    visibility: visible;
  }
  .selected li:hover .display-not,
  .selected h3:hover .display-not {
    display: none;
  }
  .selected .icon {
    filter: invert(26%) sepia(94%) saturate(4987%) hue-rotate(337deg)
      brightness(93%) contrast(98%);
  }
  .selected svg:first-child {
    color: ${colors.brightRed};
  }
  .link {
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkGrey};
  }
  .create-work-btn {
    display: inline-block;
    color: ${(props) => props.theme.colors.thinGray};
  }
  .small-icon {
    width: 0.6rem !important;
  }
  svg {
    margin-right: 0.8rem;
    color: ${colors.thinGray};
  }
`;

const NavItemParent = ({
  item,
  location,
  isActive,
  iconStyle,
  handleClick,
  teamID,
  isPersonalWorkspace,
  setContextMenuTeam,
  setContextMenuPosition,
  setShowContextMenu,
  setCreateFolder,
  team
}) => {

  const ref = useRef(null);
  useEffect(() => {
    ref.current.addEventListener("contextmenu", handleContextMenu);
    return () => {
      ref.current.removeEventListener("contextmenu", handleContextMenu);
    }
  }, []);

  const handleContextMenu = (evt) => {
    evt.preventDefault();
    if (isPersonalWorkspace) return;
    const { x, y } = evt;
    setContextMenuTeam(item);
    setContextMenuPosition({ x, y })
    setShowContextMenu(true);
  }
  const handleSelect = () => {
    if (handleClick) handleClick();
  }
  const handleCreateFolder = (teamID, folderID) => {
    setCreateFolder({ showModal: true, teamID: teamID, folderID: folderID })
  }

  return (
    <React.Fragment>
      <NavLink
        to={item.route}
        className={isActive ? `link selected no-border-highlight` : "link"}
        onClick={handleSelect}
        id={isPersonalWorkspace ? "navitem-personalworkspace" : `navitem-team-${item.id}`}
        ref={ref}
      >
        <h3>
          <span>
            <img style={iconStyle} src={item.icon} alt={item.icontext} className="icon" />
          </span>
          <span style={{ position: "relative" }}>
            {item.title}
            {/* {
              !isPersonalWorkspace &&
              <div className="member-count" style={{position: "absolute", left: "10em"}}>
                <span><img style={{width: "0.6em", margin: "0em"}} src={individualIcon} alt="count" /></span>
                <span>{item.members?.length}</span>
              </div>
            } */}
          </span>
        </h3>
      </NavLink>
      {
        isActive &&
        <div style={{ paddingLeft: "2em" }}>
          {
            item.children.length > 1 && item.children.map((subItem) => {
              return (
                <NavItem key={`navitem-team-${item.id}-${subItem.title}`} title={subItem.title} route={subItem.route} icon={subItem.icon} icontext={subItem.icontext}
                  isActive={location.pathname === subItem.route}
                  setCreateFolder={setCreateFolder}
                  teamID={teamID}
                />
              )
            })
          }
        </div>
      }
    </React.Fragment>
  )
}

const NavItem = ({ route, icon, icontext, title, isActive, setCreateFolder, teamID }) => {
  const handleCreateFolder = () => {
    setCreateFolder({ showModal: true, teamID: teamID })
  }
  return (
    <NavLink
      to={route}
      className={isActive ? "link selected" : "link"}
    >
      <h3>
        <span>
          <img src={icon} alt={icontext} className="icon" />
          {title}
        </span>
        {
          title === "All Projects" &&
          <span>
            <button onClick={handleCreateFolder}>+</button>
          </span>
        }
      </h3>
    </NavLink>
  )
}

const NotificationToggle = ({ setTeamInvites, isNotificationBarOpen, toggleNotification, setShowAcceptInviteModal, hasInvites }) => {
  useEffect(() => {
    let isSubscribed = true;
    getPendingTeamInvites()
      .then(resp => {
        if (resp.status === "success") {
          const pendingInvites = resp.invites?.map(invite => {
            return {
              ...invite,
              status: "pending"
            }
          })
          if(isSubscribed){
            setTeamInvites(pendingInvites);
            setShowAcceptInviteModal(true);
          }
        }
      })
    return () => {
      isSubscribed = false;
    }
  }, []);
  return (
    <div className="resources-div">
      <Separator />
      <h5 onClick={toggleNotification}>
        <span>
          <img
            className="icon"
            src={isNotificationBarOpen ? bellActiveIcon : hasInvites ? bellBadgeIcon : bellIcon}
            alt="bell-icon"
            style={{ width: "14px" }}
          />
          <span>
            Notification
          </span>
        </span>
      </h5>
    </div>
  )
}

const Sidebar = ({ isInviteModalOpen, handleInviteModalClose }) => {
  const location = useLocation();
  const sidebarRef = useRef(null);
  const [NAVITEMS, setNAVITEMS] = useState(NAVITEMS_PRO);
  const [project, setProject] = useState(true);
  const [resources, setResources] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [createFolder, setCreateFolder] = useState({
    showModal: false,
    teamID: null,
    folderID: null
  });
  const [showAcceptInviteModal, setShowAcceptInviteModal] = useState(false);
  const [user, setUser] = useState(true);
  const [isPro, setIsPro] = useState(false);
  const [projectsCount, setProjectsCount] = useState(0);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [newProjectUnit, setNewProjectUnit] = useState(store.newProjectUnit)
  const [showUserMenu, setShowUserMenu] = useState(false);
  const history = useContext(HistoryContext);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const { teamlist, selectedTeam } = useSelector(state => state.teams);
  const [teamInvites, setTeamInvites] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const fileSystem = useFileSystem();
  const fileSystemUpdate = useFileSystemUpdate();
  const teamRightClickeMenu = useTeamRightClickMenu();
  const teamRightClickeMenuUpdate = useTeamRightClickMenuUpdate();

  const showResources = () => {
    return setResources(!resources);
  };

  useEffect(() => {
    if (teamlist.length > 0) setNAVITEMS(NAVITEMS_ENTERPRISE);
    else setNAVITEMS(NAVITEMS_PRO)
  }, [teamlist]);

  const handleUserMenuClick = (item, index, evt) => {
    setShowUserMenu(false);
    if (item === "Logout") handleLogout(evt);
    else if (item === "Account") history.push(routes.account);
    else if (item === "Teams") history.push(routes.teams);
    else if (item === "Billing") history.push(routes.billing);
    else if (item === "Plans") history.push(routes.plans);
  }


  const selectedFolderID = useMemo(() => {
    if (location.pathname.match(REGEXP.folder) || location.pathname.match(REGEXP.personalFolder)) {
      const folderID = location.pathname.split("/").pop();
      if (folderID) return parseInt(folderID);
    }
    return null;
  }, [location.pathname]);

  const handleCreateProject = () => {
    window.analytics.track('clicked create in create project modal');
    setIsCreateProjectModalOpen(false);
    window.analytics.track(`project type ${newProjectUnit}`);
    if (selectedTeam) {
      createTeamProject(selectedTeam.id, newProjectName, "", selectedFolderID)
        .then(resp => {
          if (resp.status === "success") {
            store.newProjectUnit = newProjectUnit;
            localStorage.setItem("floorkey", JSON.stringify(resp.floorkey));
            window.analytics.track('project creation success');
            localStorage.setItem("newProjectUnit", JSON.stringify(newProjectUnit));
            history.push(`/model/${resp.floorkey}`, { dashboardRoute: location.pathname });
          } else {
            window.analytics.track('project creation error');
            showToast(resp.message, 3000, TOAST_TYPES.error);
          }
        })
      return;
    }
    createBimProject(newProjectName, newProjectUnit, selectedFolderID)
      .then(resp => {
        if (resp.status === "success") {
          store.newProjectUnit = newProjectUnit;
          localStorage.setItem("floorkey", JSON.stringify(resp.floorkey));
          window.analytics.track('project creation success');
          localStorage.setItem("newProjectUnit", JSON.stringify(newProjectUnit));
          history.push(`/model/${resp.floorkey}`, { dashboardRoute: location.pathname });
          // window.location.replace("/model/"+resp.floorkey);
        } else {
          window.analytics.track('project creation error');
          showToast(resp.message, 3000, TOAST_TYPES.error);
        }
      })
  }

  const handleLogout = (e) => {
    window.analytics.track('clicked logout')
    authService
      .logout()
      .then((resp) => {
        window.analytics.track('logout success');
        history.push(routes.login);
        // if (resp.error === 0) {
        //   history.push(routes.login);
        // }
      })
      .catch((err) => {
        window.analytics.track('logout fail');
        history.push(routes.login);
      });
    history.push(routes.login);
    e.preventDefault();
  };

  useEffect(() => {
    let isSubscribed = true;
    getIsPro().then((data) => {
      let user = AuthService.getCurrentUser();
      if(isSubscribed){
        setUser(user);
        setIsPro(data.isPro);
      }
    })
    getProjects(0)
      .then((data) => {
        if(isSubscribed){
          setProjectsCount(data.length);
        }
      })

    return () => {
      isSubscribed = false;
    }
  }, []);

  useEffect(() => {
    if (selectedTeam) setResources(false);
  }, [selectedTeam]);

  const hasCreateProjectPermission = selectedTeam ? selectedTeam.roleBasedPermissions[selectedTeam.role]["create_project"] : true;
  let allowCreateProject = false
  if (hasCreateProjectPermission) {

    if (isPro) {
      if (selectedTeam && hasCreateProjectPermission) {
        if (isTeamPlanActive(selectedTeam)) allowCreateProject = true;
        else allowCreateProject = selectedTeam.projects.length < 1;
      }
      if (selectedTeam === null) allowCreateProject = true;
    } else {
      if (selectedTeam && hasCreateProjectPermission) {
        if (isTeamPlanActive(selectedTeam)) allowCreateProject = true;
        else allowCreateProject = selectedTeam.projects.length < 1;
      }
      if (selectedTeam === null) allowCreateProject = projectsCount <= 3;
    }
  }

  return (
    <>
      {
        showCreateTeamModal && <TeamInviteModal handleClose={() => { setShowCreateTeamModal(false) }} />
      }

      <StyledSidebar ref={sidebarRef}>
        {/* <DynamicWidth sidebarRef={sidebarRef} /> */}
        <div className="logo-container">
          <Link to={routes.dashboard}><Logo /></Link>
        </div>
        <div className="button-div" style={{ position: "relative" }}>
          <Button
            primary={allowCreateProject}
            disabled={!allowCreateProject}
            title={"Create a Project"}
            onPress={() => {
              setIsCreateProjectModalOpen(true);
              window.analytics.track('clicked create a project in sidebar')
            }}
          />
        </div>
        {/* {!isPro &&
          <div className="button-div">
            <Button
              outline={projectsCount <= 3}
              primary={projectsCount > 3}
              title={"Upgrade Plan"}
              customButtonStyle={{ margin: "1rem 0" }}
              onPress={() => { 
                window.analytics.track('clicked upgrade plan on sidebar');
                history.push(routes.plans);
               }}
            />
          </div>
        } */}
        <div className="options-container">
          <WorkspaceSidebar setShowCreateTeamModal={setShowCreateTeamModal} />
          <div className="workspace-div">
            {/* <h5 onClick={() => {
            history.push(routes.dashboard)
          }}>
            <span>
            <img
              src={projectIcon}
              alt="all"
            />
              <span className="collapsible-title darkOpacity">
                All Projects
            </span>
          </span>
        </h5> */}
            {/* <h5 onClick={showProject}>
            <span>
              <img
                className={
                  project === true ? "toggle-icon icon" : "small-icon icon"
                }
                src={polygon}
                alt="polygon-icon"
              />
              <span className="collapsible-title">Workspace</span>
            </span>
          </h5> */}
            {/* <ul className={project === false ? "display-not" : "nested-lists"}>
            <DraggableList routes={routes} />
            <NavLink
              to={routes.workspace}
              className="link"
              activeClassName="selected"
            >
              <li className="create-work-btn">
                <span>
                  <img src={addIcon} alt="add-icon" className="icon" />
                  Create a workspace
                </span>
              </li>
            </NavLink>
          </ul> */}
            {/* {
              NAVITEMS.map((item) => {
                if (item.children) {
                  return <NavItemParent
                    key={`parent-${item.title}`}
                    item={item}
                    location={location}
                    isActive={!selectedTeam}
                    iconStyle={{ width: "0.75em" }}
                    handleClick={() => { dispatch(selectTeam(null)) }}
                    isPersonalWorkspace={true}
                    setCreateFolder={setCreateFolder}
                  />
                }
                return (
                  <NavItem key={`${item.title}`} title={item.title} route={item.route} icon={item.icon} icontext={item.icontext}
                    isActive={location.pathname === item.route}
                  />
                )
              })
            } */}

            {/* {
              teamlist.map((team) => {
                for (let member of team.members) {
                  if (isCurrentUser(member.email)) {
                    if (member.status === "invited") return <React.Fragment key={`team-${team.id}`}></React.Fragment>
                  }
                }
                if (team.children) {
                  return <NavItemParent
                    key={`team-${team.id}`}
                    item={team}
                    location={location}
                    isActive={selectedTeam && selectedTeam.id === team.id}
                    setShowRenameModal={setShowRenameModal}
                    setShowLeaveConfirmationModal={setShowLeaveConfirmationModal}
                    setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
                    setContextMenuTeam={setContextMenuTeam}
                    setShowContextMenu={setShowContextMenu}
                    setContextMenuPosition={setContextMenuPosition}
                    setCreateFolder={setCreateFolder}
                    teamID={team.id}
                    team={team}
                  />
                }
                return (
                  <NavItem key={`navitem-team-${team.id}`} title={team.title} route={team.route} icon={teamIcon} icontext={"team"}
                    isActive={location.pathname === team.route}
                  />
                )
              })
            } */}

            {/* {
              <div onClick={() => { setShowCreateTeamModal(true) }}>
                <h3>
                  <span>
                    <img src={plusIcon} alt={"Add"} className="icon" />
                    Create your team
                  </span>
                </h3>
              </div>
            } */}
          </div>
          <div className="resources-div">
            <Separator />
            <h5 onClick={showResources}>
              <span>
                <img
                  className={
                    resources === true ? "toggle-icon icon" : "small-icon icon"
                  }
                  src={polygon}
                  alt="polygon-icon"
                />
                <span className="collapsible-title darkOpacity" onClick={() => {
                  window.analytics.track('clicked learning resources on dashboard');
                }}>
                  Learning Resources
                </span>
              </span>
            </h5>
            <ul className={resources === false ? "display-not" : "nested-lists"}>
              <NavLink
                to={routes.tutorials}
                className="link"
                activeClassName="selected"
              >
                <li>
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 19 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.1846 2.03417C17.9705 1.23878 17.3434 0.61181 16.5482 0.397627C15.0952 0 9.28339 0 9.28339 0C9.28339 0 3.47169 0 2.01873 0.38247C1.23878 0.596511 0.596369 1.23892 0.382328 2.03417C0 3.48699 0 6.5 0 6.5C0 6.5 0 9.52817 0.382328 10.9658C0.596511 11.7611 1.22348 12.3882 2.01887 12.6024C3.48699 13 9.28353 13 9.28353 13C9.28353 13 15.0952 13 16.5482 12.6175C17.3436 12.4035 17.9705 11.7764 18.1847 10.9811C18.5671 9.52817 18.5671 6.5153 18.5671 6.5153C18.5671 6.5153 18.5824 3.48699 18.1846 2.03417Z"
                        fill="currentColor"
                      />
                      <path
                        d="M7.43311 9.28363L12.266 6.50009L7.43311 3.71655V9.28363Z"
                        fill="white"
                      />
                    </svg>
                    Tutorials
                  </span>
                </li>
              </NavLink>
              <a
                href={routes.help}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                onClick={() => {
                  window.analytics.track('clicked help on dashboard');
                }}
              >
                <li>
                  <span>
                    <img className="icon" src={help} alt="help-icon" />
                    Help
                  </span>
                </li>
              </a>
              <a
                href={routes.bookademo}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                onClick={() => {
                  window.analytics.track('clicked book a demo on dashboard');
                }}
              >
                <li>
                  <span>
                    <img className="icon" src={bookdemoIcon} alt="help-icon" />
                    Book a Demo
                  </span>
                </li>
              </a>
            </ul>
          </div>

          <NotificationToggle
            setTeamInvites={setTeamInvites}
            isNotificationBarOpen={showNotification}
            toggleNotification={() => { setShowNotification(!showNotification) }}
            setShowAcceptInviteModal={setShowAcceptInviteModal}
            hasInvites={teamInvites && teamInvites.filter(invite => invite.status === "pending").length !== 0}
          />
          <div className="profile-div">
            <Separator />
            <NavLink
              to={""}
              isActive={() => false}
              className="link"
              activeClassName={showUserMenu ? "selected" : ""}
              onClick={(evt) => { setShowUserMenu(!showUserMenu); evt.preventDefault() }}
            >
              <div className="row">
                <img src={getUserProfilePictureUrl()} alt="avatar" width={32} height={32} />
                <h4>{user.name}</h4>
              </div>

            </NavLink>
          </div>
        </div>
        <CreateProjectModal
          isOpen={isCreateProjectModalOpen}
          onClose={() => { setIsCreateProjectModalOpen(false) }}
          handleCreateProject={handleCreateProject}
          newProjectName={newProjectName}
          setNewProjectName={setNewProjectName}
          setSelectedOption={setNewProjectUnit}
          selectedTeam={selectedTeam}
          folderID={selectedFolderID}
        />
        <InviteModal
          isOpen={isInviteModalOpen}
          onClose={handleInviteModalClose}
        />
      </StyledSidebar>

      {/* MODALS */}
      {
        showUserMenu &&
        <MenuList
          items={["Logout", "Account", "Teams", "Billing", "Plans"]}
          listStyle={{ zIndex: "30", position: "fixed", left: `${SIDEBAR_WIDTH+5}px`, bottom: "0.5rem" }}
          onItemClick={handleUserMenuClick}
          handleClose={() => { setShowUserMenu(false) }}
        />
      }
      {
        showDeleteConfirmationModal && teamRightClickeMenu.team &&
        <DeleteTeamModal team={teamRightClickeMenu.team} handleClose={() => { setShowDeleteConfirmationModal(false) }} />
      }
      {
        showLeaveConfirmationModal && teamRightClickeMenu.team &&
        <LeaveTeamModal team={teamRightClickeMenu.team} handleClose={() => { setShowLeaveConfirmationModal(false) }} />
      }
      {
        showRenameModal && teamRightClickeMenu.team &&
        <RenameTeamModal team={teamRightClickeMenu.team} handleClose={() => { setShowRenameModal(false) }} />
      }
      {
        showAcceptInviteModal && teamInvites?.length > 0 &&
        <AcceptTeamInviteModal handleClose={() => { setShowAcceptInviteModal(false) }} teamInvites={teamInvites} setTeamInvites={setTeamInvites} />
      }
      {
        showNotification &&
        <NotificationBar teamInvites={teamInvites} setTeamInvites={setTeamInvites} handleClose={() => { setShowNotification(false) }} />
      }
      {
        fileSystem.modal === "create" &&
        <CreateFolderModal teamID={fileSystem.teamID} folderID={fileSystem.folderID} handleClose={() => { fileSystemUpdate() }} />
      }
      {
        fileSystem.modal === "rename" &&
        <RenameFolderModal teamID={fileSystem.teamID} folderID={fileSystem.folderID} handleClose={() => { fileSystemUpdate() }} />
      }
      {
        fileSystem.modal === "delete" &&
        <DeleteFolderModal teamID={fileSystem.teamID} folderID={fileSystem.folderID} handleClose={() => { fileSystemUpdate() }} />
      }
      {
        teamRightClickeMenu.showMenu && teamRightClickeMenu.team &&
        <TeamContextMenu x={teamRightClickeMenu.position.x} y={teamRightClickeMenu.position.y}
          handleClose={() => { teamRightClickeMenuUpdate() }}
          hideMenu={() => { teamRightClickeMenuUpdate(teamRightClickeMenu.position, teamRightClickeMenu.team, false) }}
          teamId={teamRightClickeMenu.team.id}
          setShowRenameModal={setShowRenameModal}
          setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
          setShowLeaveConfirmationModal={setShowLeaveConfirmationModal}
          contextMenuTeam={teamRightClickeMenu.team}
        />
      }
    </>
  );
};

Sidebar.propTypes = {
  header: PropTypes.string,
};

export default withRouter(Sidebar);
