import BABYLON from "../../babylonDS.module.js";
import _ from "lodash";
import { store } from "../../utilityFunctions/Store.js";
// import { ResolveEngineUtils } from "../../wallEngine/resolveEngine";
import { colorUtil } from "../../utilityFunctions/colorUtility.js";
import {
  updateBrepPositions,
  getTopFaceVertices,
  getVertexObjectFromPositionV3,
  isElementSystemGenerated,
  splitFaceByFaceObject,
  getEdgeObjectFromVertexObjects,
  labelElementAsSystemGenerated,
  removeVertexFromComponent,
  getFaceVerticesFromFace,
  getMostLikelyUnitNormalToFace,
  getLowerVertexOfTheEdge,
  populateBrepIndexes,
  getFaceObjectFromVectors,
  getFaceIdFromFacet,
  getAllVertices,
  getUpperEdgeFromFaceVertices,
  getEdgeObjectsAroundTheFace,
  getEdgeAdjacentFace,
  extrudeFace,
  getEdgeFaces,
  deleteEdge,
  getEdgeVertices,
  deleteVertex,
  getPositionFromVertex,
  getVertexNeighbours,
  verifyBRepIntegrity,
  getEdgeObjectFromVertexPositions,
} from "../../../libs/brepOperations.js";
import { constraintSolver } from "./constraintSolver.js";
import {
  convertGlobalVector3ToLocal,
  deepCopyObject,
  getIndicesOfPointInVerData,
  getBabylonPlane,
  convertLocalVector3ToGlobal,
  areComponentsSiblings,
  isMassDependant,
  onSolid,
  getDistanceBetweenVectors,
  areThreeVectorsCollinear,
  areVectorsParallel,
} from "../../extrafunc.js";
import { geometryUpdater } from "../../sketchMassBIMIntegration/geometryUpdater.js";
import { splitFaceOperator } from "../splitFaceOperations.js";
import {
  getAngleBetweenVectors,
  isFloatEqual,
  getAngleInRadians,
  projectionOfPointOnLine,
} from "../../../libs/snapFuncs.js";
import { virtualSketcher } from "../../sketchMassBIMIntegration/virtualSketcher.js";
import {
  filterOutStructuralAssociationComponents,
  disposeHighlightedFace,
  showFaceIndicator,
  handleRoofPropertyChangeCommand,
  disposeSnapToObjects,
  disposeAxisLine,
  disposeAllAxisLines,
  updateMeshFacetData, isEdgeVerticalLike,
} from "./moveUtil.js";
import { commandUtils } from "../../commandManager/CommandUtils.js";
import { isTwoDimension } from "../../../libs/twoDimension.js";
import { pseudoEdgeRenderer } from "./pseudoEdgeRenderer.js";
import { delayedExecutionEngine } from "../../utilityFunctions/delayedExecution.js";
import {
  findPrioritizedSnapPoint,
  findSecondarySnappedPoint,
  findDimensionSnappedPoint,
} from "../../../libs/snapFuncsPrimary.js";
import { getWeightedPointin3DSpace } from "../../../libs/extrudeEvents.js";
import { uiIndicatorsHandler } from "../../uiIndicatorOperations/uiIndicatorsHandler.js";
import { DisplayOperation } from "../../displayOperations/displayOperation.js";
import { moveOperator } from "./moveOperation.js";
import {areEdgesSimilar, rotateVector} from "../../../libs/snapUtilities.js";
import { externalUtil } from "../../externalUtil.js";
import {
  updateHeightAfterEdits,
  setLayerTransperancy,
} from "../../../libs/sceneFuncs.js";
import { StoreyMutation } from "../../storeyEngine/storeyMutations.js";
import { CommandManager } from "../../commandManager/CommandManager.js";
import {dimensionsTuner} from "../../sketchMassBIMIntegration/dimensionsTuner";
import {getOffsetValues, populateRoofBoundInfo} from "../../factoryTypes/roof.types";
import {getUnitNormalVectorV3} from "../../../libs/mathFuncs";

const moveFace = (function () {
  let faceSelected = false;
  
  let faceIndicatorInitialPosition = null;
  let faceIndicatorInitialPositionConstant = null;
  
  let clickedPointOnFaceConstant = null;
  let clickedPointOnFaceOnMove = null;
  
  let freeEdgeMove2D = false;
  let freeFaceMove3D = false;
  let _faceMove3D = false;
  let _leadingFacePlane = null;
  let _activeFacePlanes = [];
  
  let facetIdStoredForReference = null;
  let _meshOfInterest = null;
  let _componentOfInterest = null;
  let moveFaceCommandData = null;
  let propertyChangeCommandData = null;
  let _selectedEdgeIn2D = null;
  let _selectedFaceIn3D = [];
  let _meshEditCheckValue = null;
  
  let _pointOnPointerMove;
  
  let _onTheFlyVerticesAdditionData = {};
  
  let componentPositionsToChangeMap = new Map();
  let componentVerticesToFetchIndicesMap = new Map();
  
  const _meshesOfInterest = [];
  const _componentsOfInterest = [];
  const _componentsFromPreviousOperation = [];
  let _allModifiedComponents = [];
  let _allModifiedComponentsWithUniqueInstances = [];
  
  let _componentVertexIndicesMapping = {};
  const _facesData = [];
  
  let faceMovementData;
  let autoDimensionTuningInProgress = false;
  let programmaticUseOfMoveFace = false;
  let parametricEditsDisabled = false;
  let massShrinkageDuringCB = false;
  
  let _CONSTANTS = {
    preSnapMaterial: null,
    postSnapMaterial: null,
    normalSnap: "",
    propertyChangeCommandName: "moveFaceProperty",
    operatingSpace: null,
    preSnapFaceIndicatorName: "faceBox",
    postSnapFaceIndicatorName: "faceBox2",
    yValuePreservationThreshold: 4,
  };
  
  const init = () => {
    _CONSTANTS.preSnapMaterial = colorUtil.getMaterial(colorUtil.type.preSnap);
    _CONSTANTS.postSnapMaterial = colorUtil.getMaterial(colorUtil.type.postSnap);
    _CONSTANTS.operatingSpace = BABYLON.Space.WORLD;
  }
  
  function moveFace(diff) {
    _componentsOfInterest.forEach((component) => {
      const brep = component.brep;
      const mesh = component.mesh;
      
      let oldPos = [];
      let newPos = [];
      
      const faceCoordIndices = _componentVertexIndicesMapping[component.id];
      
      var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      for (var i = 0; i < faceCoordIndices.length; i++) {
        let xIndex = faceCoordIndices[i][0];
        let yIndex = faceCoordIndices[i][1];
        let zIndex = faceCoordIndices[i][2];
        
        oldPos.push([verData[xIndex], verData[yIndex], verData[zIndex]]);
        verData[xIndex] += diff.x;
        verData[yIndex] += diff.y;
        verData[zIndex] += diff.z;
        newPos.push([verData[xIndex], verData[yIndex], verData[zIndex]]);
      }
      let subMeshes = [...mesh.subMeshes];
      
      let geometry = mesh.geometry || mesh.sourceMesh.geometry;
      geometry.setVerticesData(
        BABYLON.VertexBuffer.PositionKind,
        verData,
        true
      );
      
      mesh.subMeshes = subMeshes;
      
      updateBrepPositions(brep, oldPos, newPos);
      
      if (mesh.type.toLowerCase() === "roof")
        component.updateRoofOutline(oldPos, newPos);
    });
    
    if (faceMovementData) {
      faceMovementData.forEach((data, index) => {
        faceMovementData[index].point.x -= diff.x;
        faceMovementData[index].point.y -= diff.y;
        faceMovementData[index].point.z -= diff.z;
      });
    }
  }
  
  function restrictedPushPull(faceMovementData) {
    
    const componentMovementDataMap = new Map();
    
    if (!programmaticUseOfMoveFace){
      _componentsOfInterest.forEach(component => {
        
        const movementDataMap = new Map();
        componentMovementDataMap.set(component, movementDataMap);
        
        const mesh = component.mesh;
        var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        
        const faceCoordIndices = _componentVertexIndicesMapping[component.id];
        for (let i = 0; i < faceCoordIndices.length; i++) {
          let vec = new BABYLON.Vector3(verData[faceCoordIndices[i][0]], verData[faceCoordIndices[i][1]], verData[faceCoordIndices[i][2]]);
          faceMovementData.some(data => {
            let pointLocal = convertGlobalVector3ToLocal(data.point, mesh);
            if (pointLocal.almostEquals(vec)) {
              movementDataMap.set(data.point, data.pointDestination);
            }
          });
        }
      });
      
      const options = {
        checkEdgeLength : false
      };
      
      const isConstrained = constraintSolver.shouldConstrain(componentMovementDataMap, options);
      if (isConstrained) return;
      
    }
    
    _componentsOfInterest.forEach((component) => {
      const mesh = component.mesh;
      let brep = component.brep;
      let oldPos = [];
      let newPos = [];
      var verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      
      const faceCoordIndices = _componentVertexIndicesMapping[component.id];
      for (let i = 0; i < faceCoordIndices.length; i++) {
        let vec = new BABYLON.Vector3(
          verData[faceCoordIndices[i][0]],
          verData[faceCoordIndices[i][1]],
          verData[faceCoordIndices[i][2]]
        );
        faceMovementData.some((data) => {
          let pointLocal = convertGlobalVector3ToLocal(data.point, mesh);
          if (pointLocal.almostEquals(vec)) {
            oldPos.push([
              verData[faceCoordIndices[i][0]],
              verData[faceCoordIndices[i][1]],
              verData[faceCoordIndices[i][2]],
            ]);
            let destinationPointLocal = convertGlobalVector3ToLocal(
              data.pointDestination,
              mesh
            );
            verData[faceCoordIndices[i][0]] = destinationPointLocal.x;
            verData[faceCoordIndices[i][1]] = destinationPointLocal.y;
            verData[faceCoordIndices[i][2]] = destinationPointLocal.z;
            newPos.push([
              verData[faceCoordIndices[i][0]],
              verData[faceCoordIndices[i][1]],
              verData[faceCoordIndices[i][2]],
            ]);
            
            let threshold = 0.1;
            
            if (_selectedEdgeIn2D.headPt.almostEquals(data.point, threshold)) {
              _selectedEdgeIn2D.headPt = data.pointDestination;
            }
            else if (_selectedEdgeIn2D.tailPt.almostEquals(data.point, threshold)) {
              _selectedEdgeIn2D.tailPt = data.pointDestination;
            }
            
            // this threshold being 0.1 might cause problems when vertices are < 0.1 (25 mm) apart
            
            _selectedFaceIn3D.some((v, i) => {
              if (v.almostEquals(data.point, threshold)){
                _selectedFaceIn3D[i] = data.pointDestination;
                return true;
              }
            });
            
            return true;
          }
        });
      }
      
      let subMeshes = [...mesh.subMeshes];
      
      let geometry = mesh.geometry || mesh.sourceMesh.geometry;
      geometry.setVerticesData(
        BABYLON.VertexBuffer.PositionKind,
        verData,
        true
      );
      
      mesh.subMeshes = subMeshes;
      
      updateBrepPositions(brep, oldPos, newPos);
      
      if (mesh.type.toLowerCase() === "roof")
        mesh.getSnaptrudeDS().updateRoofOutline(oldPos, newPos);
    });
    
    faceMovementData.forEach((data, index) => {
      faceMovementData[index].point = faceMovementData[index].pointDestination;
    });
    
    return true;
  }
  
  const _areFacesParallel = function (face1, face2){
    const vertices1 = getFaceVerticesFromFace(face1, _componentOfInterest.mesh);
    const normal1 = getUnitNormalVectorV3(vertices1);
    
    const vertices2 = getFaceVerticesFromFace(face2, _componentOfInterest.mesh);
    const normal2 = getUnitNormalVectorV3(vertices2);
    
    if (normal1.almostEquals(normal2) || normal1.almostEquals(normal2.negate())){
      return true;
    }
  }
  
  const _extrudeAndRemoveEdges = function (){
    const selectedFaceData = _getAllFacesData()[0];
    const selectedFaceObject = selectedFaceData.faceObject;
    const selectedFaceVerticesBeforeExtrude = getFaceVerticesFromFace(
      selectedFaceObject, _componentOfInterest.mesh
    );
    
    const oldEdges = getEdgeObjectsAroundTheFace(selectedFaceObject);
  
    const extrudeAmount = 2;
    
    let newElements = extrudeFace(
      _componentOfInterest,
      selectedFaceObject,
      -extrudeAmount
    );

    newElements.faces.forEach(function (face) {
      face.materialIndex = selectedFaceObject.materialIndex;
    });
    
    const newFaces = newElements.faces;
    let vertexObjectsToCheck = [];
    const vertexPositionsToRemove = [];
    
    oldEdges.forEach((edge) => {
      const [face1, face2] = getEdgeFaces(edge);
      
      if (newFaces.includes(face1) || newFaces.includes(face2)){
        if (_areFacesParallel(face1, face2)){
          
          const [vo1, vo2] = getEdgeVertices(edge);
          vertexObjectsToCheck.push(vo1, vo2);
          deleteEdge(_componentOfInterest.brep, edge.getIndex());
          
        }
      }
      
    });
    
    vertexObjectsToCheck = _.uniq(vertexObjectsToCheck);
    
    vertexObjectsToCheck.forEach(vo => {
      const v3 = getPositionFromVertex(vo, _componentOfInterest.mesh);
      const v3Neighbours = getVertexNeighbours(v3, _componentOfInterest.mesh);
      
      if (v3Neighbours.length === 2){
        if (areThreeVectorsCollinear(v3Neighbours[0], v3, v3Neighbours[1])) {
          vertexPositionsToRemove.push(getPositionFromVertex(vo, _componentOfInterest.mesh));
        }
      }
    });
    
    vertexPositionsToRemove.forEach(v3 => {
      const vo = getVertexObjectFromPositionV3(_componentOfInterest.mesh, v3);
      deleteVertex(_componentOfInterest.brep, vo.getIndex());
      _componentOfInterest.mesh.BrepToMesh();
      // verifyBRepIntegrity(_componentOfInterest.brep);
    });
    
    _componentOfInterest.mesh.BrepToMesh();
    
    const faceVerticesAfterExtrude = getFaceVerticesFromFace(
      selectedFaceObject, _componentOfInterest.mesh
    );
    _addVerticesToMap(_componentOfInterest, faceVerticesAfterExtrude);
    
    const positionsToChange = [];
    faceVerticesAfterExtrude.forEach(vertexAfterExtrude => {
      selectedFaceVerticesBeforeExtrude.some(vertexBeforeExtrude => {
        if (vertexBeforeExtrude.almostEquals(vertexAfterExtrude, 1.01 * extrudeAmount)){
          positionsToChange.push([vertexAfterExtrude, vertexBeforeExtrude]);
          return true;
        }
      });
    });
    
    componentPositionsToChangeMap.set(_componentOfInterest, positionsToChange);
    
    return true;
  }
  
  const _addVerticesToMap = function (component, faceVertices) {
    let vertices = componentVerticesToFetchIndicesMap.get(component);
    if (!vertices) {
      vertices = [];
      componentVerticesToFetchIndicesMap.set(component, vertices);
    }
    faceVertices.forEach((vertexToAdd) => {
      vertices.pushIfNotExist(vertexToAdd, (v) =>
        v.almostEquals(vertexToAdd)
      );
    });
  };
  
  
  /**
   * Relevant when user tries to edit the edge whose vertices are system generated.
   * In this case new vertices are added on the fly
   *
   * @private
   */
  const _addVerticesIfNecessary = function () {
    // const resolveEngineUtils = new ResolveEngineUtils();
    const _isVertexCollinear = function (v) {
      let collinear = false;
      
      const collinearityThreshold = 1e-1;
      allFaceVertices.some((faceVertices) => {
        const length = faceVertices.length;
        
        faceVertices.some((vertex, i) => {
          if (!vertex.snaptrudeFunctions().almostEquals2D(v)) return;
          
          const nextIndex = i === length - 1 ? 0 : i + 1;
          const previousIndex = i === 0 ? length - 1 : i - 1;
          
          const nextVertex = faceVertices[nextIndex];
          const previousVertex = faceVertices[previousIndex];
          
          let threshold = collinearityThreshold;
          
          const d1 = getDistanceBetweenVectors(vertex, nextVertex);
          const d2 = getDistanceBetweenVectors(vertex, previousVertex);
          
          const minD = _.min([d1, d2]);
          if (minD < collinearityThreshold){
            threshold = minD / 2;
          }
          
          if (
            store.resolveEngineUtils.onSegment3D(
              previousVertex,
              vertex,
              nextVertex,
              threshold
            )
          ) {
            collinear = true;
            return true;
          }
        });
        
        return collinear;
      });
      
      return collinear;
    };
    
    const allFaceVertices = _componentsOfInterest.map((c) =>
      getTopFaceVertices(c, _CONSTANTS.operatingSpace)
    );
    
    let modified = false;
    const _componentCurrentVerticesObjectMapping = {};
    
    // _onTheFlyVerticesAdditionData = {};
    
    _getAllFacesData().forEach(
      ({ faceObject, faceVertices, components, editData }) => {
        if (!editData.edge || !editData.edgeLower) {
          components.forEach((c) => {
            _addVerticesToMap(c, faceVertices);
          });
          return;
        }
        
        const edge = editData.edge;
        
        const driftForLongEdge = 1e-1;
        const smallEdgeThreshold = 2 * driftForLongEdge;
        // when edge length is < 0.2, if drift amount is 0.1 the vertices get added 'past' each other
        
        const edgeLength = getDistanceBetweenVectors(edge.headPt, edge.tailPt);
        
        let driftAmount;
        if (edgeLength < smallEdgeThreshold){
          const halfEdge = edgeLength / 2;
          driftAmount = halfEdge - 0.05 * halfEdge;
        }
        else driftAmount = driftForLongEdge;
        
        const edgeHeadToTail = edge.tailPt
          .subtract(edge.headPt)
          .normalize()
          .scale(driftAmount);
        
        const verticesToAdd = [
          edge.headPt.add(edgeHeadToTail),
          edge.tailPt.add(edgeHeadToTail.negate()),
        ];
        
        const currentVertices = [edge.headPt, edge.tailPt];
        
        const currentLowerVertices = [
          editData.edgeLower.headPt,
          editData.edgeLower.tailPt,
        ];
        
        components.forEach((component, componentNumber) => {
          _componentCurrentVerticesObjectMapping[component.id] = [];
          currentVertices.forEach((currentVertex) => {
            _componentCurrentVerticesObjectMapping[component.id].push(
              getVertexObjectFromPositionV3(
                component.mesh,
                currentVertex,
                _CONSTANTS.operatingSpace
              )
            );
          });
          
          const componentVertexObjects =
            _componentCurrentVerticesObjectMapping[component.id];
          const componentVertexObjectLabels = componentVertexObjects.map(
            (vo, i) => {
              return _isVertexCollinear(currentVertices[i]);
            }
          );
          
          let positionsToChange = componentPositionsToChangeMap.get(component);
          if (!positionsToChange) {
            positionsToChange = [];
            componentPositionsToChangeMap.set(component, positionsToChange);
          }
          
          // _onTheFlyVerticesAdditionData[component.id] = [];
          
          // let topFacetId = editData.pickInfo.faceId;
          // if (isNaN(topFacetId)) topFacetId = geometryUpdater.util.getTopFacetId(component);
          
          let topFacetId = geometryUpdater.util.getTopFacetId(component);
          
          let verticesToAddForComponent,
            currentVerticesOfComponent,
            currentLowerVerticesOfComponent,
            faceVerticesOfComponent;
          
          let fakePickInfo;
          verticesToAddForComponent = verticesToAdd;
          currentVerticesOfComponent = currentVertices;
          currentLowerVerticesOfComponent = currentLowerVertices;
          faceVerticesOfComponent = deepCopyObject(faceVertices);
          
          const pickedPoint = editData.pickInfo.pickedPoint;
          
          fakePickInfo = geometryUpdater.util.getFakePickInfo(
            pickedPoint,
            component,
            topFacetId
          );
          
          verticesToAddForComponent.forEach((vertexToAdd, i) => {
            const currentVertex = currentVerticesOfComponent[i];
            const currentLowerVertex = currentLowerVerticesOfComponent[i];
            
            let shouldAddVertex = componentVertexObjectLabels[i];
            
            if (shouldAddVertex) {
              const faceInfo = splitFaceOperator.determineFaceToSplitAndPoints(
                fakePickInfo,
                vertexToAdd
              );
              
              if (!faceInfo.face || !faceInfo.lowerPoint) {
                console.warn("Dynamic addition of vertices failed");
                return;
              }
              
              const optionsForSplit = {
                thresholdAngle: 1e-3,
                isSystemGenerated: true,
              };
              
              const split = splitFaceByFaceObject(
                component.mesh,
                faceInfo.face,
                vertexToAdd,
                faceInfo.lowerPoint,
                _CONSTANTS.operatingSpace,
                optionsForSplit
              );
              
              if (split) {
                modified = true;
                
                _.remove(faceVerticesOfComponent, (v) =>
                  v.almostEquals(currentVertex)
                );
                _.remove(faceVerticesOfComponent, (v) =>
                  v.almostEquals(currentLowerVertex)
                );
                faceVerticesOfComponent.pushIfNotExist(vertexToAdd, (v) =>
                  v.almostEquals(vertexToAdd)
                );
                faceVerticesOfComponent.pushIfNotExist(
                  faceInfo.lowerPoint,
                  (v) => v.almostEquals(faceInfo.lowerPoint)
                );
                
                positionsToChange.push([vertexToAdd, currentVertex]);
                positionsToChange.push([
                  faceInfo.lowerPoint,
                  currentLowerVertex,
                ]);
                
                // _vertexObjectsForSystemGeneratedLabelRemoval.push(currentVertexObject);
                // _onTheFlyVerticesAdditionData[component.id].push(currentVertex);
                
                // things above were used on escape, not anymore
                
                const upperVertexLeftBehind = getVertexObjectFromPositionV3(
                  component.mesh,
                  currentVertex,
                  _CONSTANTS.operatingSpace
                );
                const lowerVertexLeftBehind = getVertexObjectFromPositionV3(
                  component.mesh,
                  currentLowerVertex,
                  _CONSTANTS.operatingSpace
                );
                const edgeLeftBehind = getEdgeObjectFromVertexObjects(
                  component,
                  upperVertexLeftBehind,
                  lowerVertexLeftBehind
                );
                
                labelElementAsSystemGenerated(upperVertexLeftBehind);
                labelElementAsSystemGenerated(lowerVertexLeftBehind);
                if (edgeLeftBehind) labelElementAsSystemGenerated(edgeLeftBehind);
              } else {
                console.warn("Dynamic addition of vertices failed");
              }
              
              fakePickInfo.faceId =
                geometryUpdater.util.getTopFacetId(component);
              
              if (componentNumber === 0)
                editData.pickInfo.faceId = fakePickInfo.faceId;
              // the first component to whom the faceObject and editData belong
            }
          });
          
          _addVerticesToMap(component, faceVerticesOfComponent);
        });
      }
    );
    
    
    return modified;
  };
  
  /**
   * Removes vertices added in _addVerticesIfNecessary if user escapes the operation
   * @private
   */
  /*const _removeAddedVerticesIfAny = function () {
    _componentsOfInterest.forEach((component) => {
      if (_.isEmpty(_onTheFlyVerticesAdditionData)) return;
      
      _onTheFlyVerticesAdditionData[component.id].forEach((upperVertex) => {
        const topFaceId = geometryUpdater.util.getTopFaceId(component);
        
        const options = {
          operatingSpace: _CONSTANTS.operatingSpace,
          faceId: topFaceId,
          forcedRemoval: true,
        };
        
        const removed = removeVertexFromComponent(
          upperVertex,
          component,
          options
        );
        if (!removed) console.error("Removal of auto added vertices failed");
      });
    });
  };*/
  
  const _getFaceVerticesMetadata = function (
    faceObject,
    component,
    faceVertices,
    facePlane
  ) {
    let options = { faceId: faceObject.getIndex(), facetId: NaN };
    
    if (!faceVertices)
      faceVertices = getFaceVerticesFromFace(
        faceObject,
        component.mesh,
        _CONSTANTS.operatingSpace
      );
    const normalToFace = getMostLikelyUnitNormalToFace(component, faceObject, {
      confirmWithPick: true,
    });
    
    const verticesMetadata = faceVertices.map((point) => {
      let returnObject = {
        point: null,
        direction: null,
        facePlane,
        isDirectionNormalToFace: false,
      };
      returnObject.point = point;
      let otherPoint = getLowerVertexOfTheEdge(
        point,
        component.mesh,
        options,
        _CONSTANTS.operatingSpace
      );
      if (!otherPoint) {
        // store.$scope.isTwoDimension ? freeEdgeMove2D = true : freeFaceMove3D = true;
        returnObject.direction = normalToFace;
        returnObject.isDirectionNormalToFace = true;
        return returnObject;
      }
      
      const direction = point.subtract(otherPoint).normalize();
      const angle = getAngleBetweenVectors(normalToFace, direction);
      
      if (isFloatEqual(angle, 90, 5)) {
        returnObject.direction = normalToFace;
        returnObject.isDirectionNormalToFace = true;
        // if face-to-be-moved's neighbour is parallel to the face, angle to preserve is not defined
        // Before this case fell back to free edit, now direction is made normal to itself
      } else {
        returnObject.direction = point.subtract(otherPoint).normalize();
      }
      
      return returnObject;
    });
    
    return verticesMetadata;
  };
  
  const _getFaceMovementData = function () {
    // store movement data based on old points
    // (i.e not the newly added vertices, because they will be moved within brep after adding)
    
    let allVerticesData = [];
    
    const faceDataObjects = _getAllFacesData();
    faceDataObjects.forEach(
      ({ faceObject, faceVertices, components, facePlane, referenceComponentForDirection }) => {
        const component = components[0];
        const verticesMetadata = _getFaceVerticesMetadata(
          faceObject,
          component,
          faceVertices,
          facePlane
        );

        verticesMetadata.forEach((data) => {
          // even if y deviates by a little, over time its effect starts showing in
          // graph updating and its related functionality
          data.direction.y = _.round(
            data.direction.y,
            _CONSTANTS.yValuePreservationThreshold
          );
        });
        
        if (referenceComponentForDirection){
          verticesMetadata.forEach(data => {
            const vertex = data.point;
            allVerticesData.some(storedData => {
              const storedVertex = storedData.point;
              if (vertex.snaptrudeFunctions().almostEquals2D(storedVertex)){
                data.direction = storedData.direction;
                return true;
              }
            });
          });
        }
        
        allVerticesData.push(...verticesMetadata);
        allVerticesData = _.uniqWith(allVerticesData, (o1, o2) => {
          return o1.point.almostEquals(o2.point);
        });
      }
    );

    return allVerticesData;
  };

  const _getAllFacesData = function () {
    return _facesData;
  };

  const _removeFacesData = function () {
    _facesData.length = 0;
    _activeFacePlanes.length = 0;
    _leadingFacePlane = null;
  };

  const _eraseStoredIndices = function () {
    _componentVertexIndicesMapping = {};
  };

  const _storeFaceIndices = function (allVertices, component) {
    // when vertices are added, capture indices of newly added points before they're replaced,
    // so that we know what vertices to move exactly

    if (!allVertices)
      allVertices = _.flatten(
        _getAllFacesData().map((data) => data.faceVertices)
      );
    const allVerticesUnique = _.uniqWith(allVertices, (v1, v2) =>
      v1.almostEquals(v2)
    );
    
    _componentsOfInterest.forEach((c) => {
      if (component && c !== component) return;
      
      populateBrepIndexes(c, allVerticesUnique, true);
      allVerticesUnique.forEach((vertex) => {
        const vertexIndices = getIndicesOfPointInVerData(
          vertex,
          c,
          _CONSTANTS.operatingSpace
        );
        if (!_componentVertexIndicesMapping[c.id])
          _componentVertexIndicesMapping[c.id] = [];
        _componentVertexIndicesMapping[c.id].push(...vertexIndices);
      });
    });
    
    // _componentVertexIndicesMapping[c.id].length / 3 is the number of vertices that'll move
    // brep.indexes.length is the number of vertices that'll move
  };
  
  const _populateComponentsOfInterest = function () {
    if (!_.isEmpty(_componentsOfInterest)) {
      _componentsFromPreviousOperation.push(..._componentsOfInterest);
    }
    _componentsOfInterest.length = 0;
    
    const facesData = _getAllFacesData();
    // length should be 1 or 2 here
    
    if (facesData.length === 2) {
      const allComponents = _.uniq(
        _.flatten(facesData.map((faceData) => faceData.components))
      );
      _componentsOfInterest.push(...allComponents);
    } else if (facesData.length === 1) {
      if (
        !parametricEditsDisabled &&
        virtualSketcher.util.shouldAddComponentToGraph(_componentOfInterest) &&
        !virtualSketcher.util.isComponentPlanar(_componentOfInterest)
      ) {
        const structuralAssociationComponents = [];
        const faceVertices = facesData[0].faceVertices;
        faceVertices.forEach((vertex) => {
          const structuralAssociation =
            virtualSketcher.structuralLookup(vertex);
          if (structuralAssociation)
            structuralAssociationComponents.push(
              structuralAssociation.components
            );
        });
        
        const commonComponents = _.intersection(
          ...structuralAssociationComponents
        );
        
        /*
                Extra check because of this case
                https://imgur.com/a/BHdkPmY
                Shares all the vertices but no face connecting them
                 */
        
        const componentNewFaceObjectMap = new Map();
        const commonComponentsWithFaceOnThoseVertices = commonComponents.filter(
          (c) => {
            const faceObject = getFaceObjectFromVectors(
              faceVertices,
              c.mesh,
              _CONSTANTS.operatingSpace
            );
            if (faceObject) componentNewFaceObjectMap.set(c, faceObject);
            return !!faceObject;
          }
        );
        
        filterOutStructuralAssociationComponents(
          _componentOfInterest,
          commonComponentsWithFaceOnThoseVertices
        );
        
        _componentsOfInterest.push(...commonComponentsWithFaceOnThoseVertices);
        
        const updatedComponentNewFaceObjectMap = new Map();
        // because some components would've been removed in filterOutStructuralAssociationComponents
        
        commonComponentsWithFaceOnThoseVertices.forEach((c) => {
          updatedComponentNewFaceObjectMap.set(
            c,
            componentNewFaceObjectMap.get(c)
          );
        });
        _addComponentsToFaceData(updatedComponentNewFaceObjectMap);
      } else {
        _componentsOfInterest.push(_componentOfInterest);
      }
    } else {
      console.warn(facesData);
      console.warn("Too many or too little faces to move");
    }
  };
  
  const _captureInitialCommandData = function () {
    _meshesOfInterest.length = 0;
    _meshesOfInterest.push(..._componentsOfInterest.map((c) => c.mesh));
    
    if (moveFaceCommandData) {
      // for edit polygon, where multiple moveFaces are done per operation
      
      const meshesWhoseInitialStateNotCaptured = _.filter(
        _meshesOfInterest,
        (m) =>
          !moveFaceCommandData.inArray((data) => m.uniqueId === data.meshId)
      );
      const moveFaceCommandDataForOtherMeshes =
        commandUtils.geometryChangeOperations.getCommandData(
          meshesWhoseInitialStateNotCaptured
        );
      
      moveFaceCommandData.push(...moveFaceCommandDataForOtherMeshes);
    } else {
      moveFaceCommandData =
        commandUtils.geometryChangeOperations.getCommandData(_meshesOfInterest);
    }
  };
  
  /**
   * Used in 2D
   * Returns face object for the top edge and also parallel face data
   *
   * @param data = {
   *     edge,
   *     component
   * }
   * @param metadata
   * @returns {null}
   * @private
   */
  const getFaceObjectFromTopEdgeAndLookForParallelFaces = function (
    data,
    metadata
  ) {
    const edge = data.edge;
    let v1 = edge.headPt;
    let v2 = edge.tailPt;
    
    if (!data.pickInfo) {
      data.pickInfo = geometryUpdater.util.getFakePickInfo(
        BABYLON.Vector3.Center(v1, v2),
        data.component,
        geometryUpdater.util.getTopFacetId(data.component)
      );
    }
    
    let options = { facetId: data.pickInfo.faceId };
    let v4 = getLowerVertexOfTheEdge(
      v1,
      data.component.mesh,
      options,
      _CONSTANTS.operatingSpace
    );
    let v3 = getLowerVertexOfTheEdge(
      v2,
      data.component.mesh,
      options,
      _CONSTANTS.operatingSpace
    );
    
    if (!v3 || !v4) {
      console.warn("Lower vertices not determined");
      return;
    }
    
    data.edgeLower = {
      headPt: v4,
      tailPt: v3,
    };
    
    if (data.faceObject) return data.faceObject;
    // parametric case, assigned below to the data object
    
    let vectors = [v1, v2, v3, v4];
    let face = getFaceObjectFromVectors(
      vectors,
      data.component.mesh,
      _CONSTANTS.operatingSpace
    );
    
    if (!face) {
      disposeHighlightedFace();
      console.warn("Face not determined");
      return;
    }
    
    if (metadata && virtualSketcher.util.shouldAddComponentToGraph(data.component)) {
      
      const topEdgeObject = virtualSketcher.structuralLookupEdge(v1, v2);
      const bottomEdgeObject = virtualSketcher.structuralLookupEdge(v3, v4);
      
      if ((topEdgeObject && topEdgeObject.components.length > 1) || 
          (bottomEdgeObject && bottomEdgeObject.components.length > 1)) {
        // mezzanines not considered right now
        
        const sharedEdgeLabel = bottomEdgeObject ? (bottomEdgeObject.components.length > 1 ? bottomEdgeObject : topEdgeObject)
                                                 : topEdgeObject;
        
        const componentsAroundThatEdge = sharedEdgeLabel.components.filter(c => c !== data.component && c.storey === data.component.storey);
        let parametricallyRelatedComponent = componentsAroundThatEdge[0];
        
        if (!parametricallyRelatedComponent) {
          // this will happen in multi-storey buildings.
          // console.error("Common component not determined");
          return face;
        }
        
        // extra checks
        // 1. the two components should have at least one common edge
        // 2. the components should not overlap in areas in top view
        
        // the case that makes these checks necessary
        // https://imgur.com/9hune78
        
        // const mezzanineLike = nodesAlongEdge1.length > 1 && nodesAlongEdge2.length > 1;
        const mezzanineLike = false;
        // TODO - proper mezzanine determination
        
        const doesComponentGrowDownwards =
          virtualSketcher.util.doesComponentGrowDownwards(
            parametricallyRelatedComponent
          );
        
        const sharedVectors = sharedEdgeLabel.getNodeVectors();
        const sharedEdge = {
          headPt: sharedVectors[0],
          tailPt: sharedVectors[1],
        };
        
        const sharedEdgeObject = getEdgeObjectFromVertexPositions(
          parametricallyRelatedComponent.mesh,
          parametricallyRelatedComponent.brep,
          ...sharedVectors
        );
        
        const refVertices = getFaceVerticesFromFace(face, data.component.mesh);
        const referenceNormal = getUnitNormalVectorV3(refVertices);
        
        const faces = getEdgeFaces(sharedEdgeObject);
        const arrayOfFaceVertices = faces.map(face => getFaceVerticesFromFace(face, parametricallyRelatedComponent.mesh));
        const normals = arrayOfFaceVertices.map(vertices => getUnitNormalVectorV3(vertices));
        const angles = normals.map(normal => getAngleBetweenVectors(normal, referenceNormal));
        
        let parallelFaceIndex;
        
        const angleShouldBe = 180;
        const angleThreshold = 5;
        
        if (isFloatEqual(angleShouldBe, angles[0], angleThreshold)){
          parallelFaceIndex = 0;
        }
        else if (isFloatEqual(angleShouldBe, angles[1], angleThreshold)){
          parallelFaceIndex = 1;
        }
        
        const parallelFace = faces[parallelFaceIndex];
        const parallelFaceVertices = arrayOfFaceVertices[parallelFaceIndex];
        
        if (!parallelFace){
          return face;
          // no parametric movement
        }
        
        const edge = getUpperEdgeFromFaceVertices(parallelFaceVertices);
        
        metadata.pickData = {
          edge,
          faceObject: parallelFace,
          component: parametricallyRelatedComponent,
        };
        
        metadata.component = parametricallyRelatedComponent;
        
      }
    }
    
    return face;
  };
  
  const _addFaceDataObject = function (
    faceObject,
    faceVertices,
    components,
    editData,
    options = {}
  ) {
    if (!options.angleBetweenNormals) options.angleBetweenNormals = 0;
    
    const faceCentre = faceVertices
      .reduce((sum, value) => sum.add(value), BABYLON.Vector3.Zero())
      .scale(1 / faceVertices.length);
    
    const facePlane = getBabylonPlane(faceVertices, faceCentre);
    facePlane.angleBetweenNormals = options.angleBetweenNormals;
    
    _activeFacePlanes.push(facePlane);
    
    if (
      !_leadingFacePlane &&
      components.length === 1 &&
      components[0] === _componentOfInterest
    ) {
      _leadingFacePlane = facePlane;
    }
    
    const faceObjects = [faceObject];
    // adding this array to keep track of the faceObjects of each component
    // can add a different dataObject altogether which is cleaner
    // maybe should do that later
    
    const referenceComponentForDirection = options.referenceComponentForDirection;
    // while fetching faceMovementData and the vertex movement direction, currently the faceData
    // present before in _facesData is automatically used. That doesn't work for balconies and when
    // toggle will be introduced for choosing which mass' angles will be preserved in parametric edit
    
    
    const dataObject = {
      faceObject,
      faceVertices,
      components,
      faceObjects,
      editData,
      facePlane,
      referenceComponentForDirection
    };
    
    _facesData.push(dataObject);
  };
  
  // faceObject is captured before vertices are added
  // when vertices are added, they need to be updated
  const _correctFaceData = function (){
    _getAllFacesData().forEach(faceData => {
      const faceVertices = faceData.faceVertices;
      faceData.faceObjects.forEach((fo, i) => {
        const component = faceData.components[i];
        const vs = getFaceVerticesFromFace(fo, component.mesh, 1);
        faceData.faceObjects[i] = getFaceObjectFromVectors(faceVertices, component.mesh);
        
        if (i === 0) faceData.faceObject = faceData.faceObjects[i];
      });
    });
  };
  
  const _addComponentsToFaceData = function (componentNewFaceObjectMap) {
    const allComponents = Array.from(componentNewFaceObjectMap.keys());
    
    const faceData = _getAllFacesData()[0];
    const existingComponents = faceData.components;
    const newComponents = _.difference(allComponents, existingComponents);
    const faceObjects = newComponents.map((c) =>
      componentNewFaceObjectMap.get(c)
    );
    
    existingComponents.push(...newComponents);
    faceData.faceObjects.push(...faceObjects);
  };
  
  const _handleSimultaneousMultiFaceEdit = function () {
    const _handleSetOfInstances = function (
      instanceComponents,
      handleNextLevelInstanceComponents
    ) {
      const nextLevelInstanceComponents = [];
      
      instanceComponents.forEach((instanceComponent) => {
        const facesData = _getAllFacesData();
        
        const instanceFaceData = facesData.filter((faceData) =>
          faceData.components.includes(instanceComponent)
        )[0];
        let instanceEditData = instanceFaceData.editData;
        
        const lowerEdge = instanceEditData.edgeLower;
        if (!lowerEdge) return;
        
        const lowerEdgeLocal = {
          headPt: convertGlobalVector3ToLocal(
            lowerEdge.headPt,
            instanceComponent.mesh
          ),
          tailPt: convertGlobalVector3ToLocal(
            lowerEdge.tailPt,
            instanceComponent.mesh
          ),
        };
        
        const upperEdge = instanceEditData.edge;
        const upperEdgeLocal = {
          headPt: convertGlobalVector3ToLocal(
            upperEdge.headPt,
            instanceComponent.mesh
          ),
          tailPt: convertGlobalVector3ToLocal(
            upperEdge.tailPt,
            instanceComponent.mesh
          ),
        };
        
        const instanceFaceObject =
          getFaceObjectFromTopEdgeAndLookForParallelFaces({
            edge: upperEdge,
            component: instanceComponent,
          });
        
        let mainInstanceDirection = getMostLikelyUnitNormalToFace(
          instanceComponent, instanceFaceObject, {confirmWithPick : true});
        
        
        const allInstances = instanceComponent.mesh.sourceMesh.instances;
        allInstances.forEach((currentInstanceMesh) => {
          if (currentInstanceMesh === instanceComponent.mesh) return;
          
          const currentInstanceComponent = currentInstanceMesh.getSnaptrudeDS();
          
          let lowerEdgeForThisInstance; // the one won the graph
          if (
            virtualSketcher.util.doesComponentGrowDownwards(
              currentInstanceComponent
            )
          ) {
            lowerEdgeForThisInstance = {
              headPt: convertLocalVector3ToGlobal(
                upperEdgeLocal.headPt,
                currentInstanceMesh
              ),
              tailPt: convertLocalVector3ToGlobal(
                upperEdgeLocal.tailPt,
                currentInstanceMesh
              ),
            };
          } else {
            lowerEdgeForThisInstance = {
              headPt: convertLocalVector3ToGlobal(
                lowerEdgeLocal.headPt,
                currentInstanceMesh
              ),
              tailPt: convertLocalVector3ToGlobal(
                lowerEdgeLocal.tailPt,
                currentInstanceMesh
              ),
            };
          }
          
          const edgeLabel = virtualSketcher.lookupEdge(
            lowerEdgeForThisInstance.headPt,
            lowerEdgeForThisInstance.tailPt
          );
          
          if (!edgeLabel) return;
          
          let neighbourComponentToCurrentInstance;
          if (edgeLabel.components.length === 2) {
            neighbourComponentToCurrentInstance = edgeLabel.components.filter(
              (c1) => {
                /*let alreadyConsidered = false;
                            _componentsOfInterest.some(c2 => {
                                alreadyConsidered = areComponentsSiblings(c1, c2);
                                return alreadyConsidered;
                            });
                            return !alreadyConsidered;*/
                return !areComponentsSiblings(c1, currentInstanceComponent);
              }
            )[0];
          }
          
          if (!neighbourComponentToCurrentInstance) return;
          
          const upperEdgeForThisInstance = {
            headPt: convertLocalVector3ToGlobal(
              upperEdgeLocal.headPt,
              currentInstanceMesh
            ),
            tailPt: convertLocalVector3ToGlobal(
              upperEdgeLocal.tailPt,
              currentInstanceMesh
            ),
          };
          
          let neighbourToCurrentInstancePickData = {
            edge: upperEdgeForThisInstance,
            component: neighbourComponentToCurrentInstance,
          };
          
          const parallelFaceData = {};
          
          const currentInstanceFaceObject =
            getFaceObjectFromTopEdgeAndLookForParallelFaces(
              {
                edge: upperEdgeForThisInstance,
                component: currentInstanceComponent,
              },
              parallelFaceData
            );
          
          let neighbourToCurrentInstanceFaceObject;
          
          if (parallelFaceData.pickData) {
            neighbourToCurrentInstanceFaceObject =
              getFaceObjectFromTopEdgeAndLookForParallelFaces(
                parallelFaceData.pickData
              );
            
            neighbourToCurrentInstancePickData = parallelFaceData.pickData;
          } else {
            neighbourToCurrentInstanceFaceObject =
              getFaceObjectFromTopEdgeAndLookForParallelFaces(
                neighbourToCurrentInstancePickData
              );
          }
          
          if (neighbourToCurrentInstanceFaceObject) {
            let componentHandled = false;
            if (neighbourComponentToCurrentInstance.mesh.isAnInstance) {
              facesData.some((faceData) => {
                faceData.components.some((c, i) => {
                  if (
                    areComponentsSiblings(
                      c,
                      neighbourComponentToCurrentInstance
                    )
                  ) {
                    if (
                      faceData.faceObjects[i].index ===
                      neighbourToCurrentInstanceFaceObject.index
                    ) {
                      // movement of this face handled, do nothing
                      componentHandled = true;
                    }
                  }
                  return componentHandled;
                });
                
                return componentHandled;
              });
            }
            
            if (componentHandled) return;
            
            let faceVertices = getFaceVerticesFromFace(
              neighbourToCurrentInstanceFaceObject,
              neighbourToCurrentInstancePickData.component.mesh,
              _CONSTANTS.operatingSpace
            );
            
            neighbourToCurrentInstancePickData.faceVertices = faceVertices;
            
            let currentInstanceDirection = getMostLikelyUnitNormalToFace(
              currentInstanceComponent, currentInstanceFaceObject, {confirmWithPick : true});
            
            let angleBetweenNormals = BABYLON.Vector3.GetAngleBetweenVectors(
              mainInstanceDirection,
              currentInstanceDirection,
              BABYLON.Vector3.Up()
            );
            
            // sometimes returns NaN
            if (isNaN(angleBetweenNormals))
              angleBetweenNormals = getAngleInRadians(
                mainInstanceDirection,
                currentInstanceDirection
              );
            
            if (!handleNextLevelInstanceComponents) {
              // condition applies only in level 2 search
              
              /*
                            Difficult to explain the need for this condition

                            https://imgur.com/a/o4kz6bz

                            Image 1-
                            This is before the fix, gap appears between b2 and c. Because,
                            angleBetweenNormals is calculated in this case between b2 and b1 is zero, so c moves in the same direction as a1
                            Actually, c should move opposite because b1 itself is deviated by Pi rads from a2. That should also be accounted for when
                            moving c. So, angleBetweenNormals for c will be 0 + Pi

                            Image 2-
                            After the fix. Without the fix, there would've been a gap between b2 and c.
                            Even though angleBetweenNormals of b1 and b2 is Pi, c should move in the same direction as a1
                            So, we add Pi (angle between a1 and a2) to the angle, so effective angle is 2*Pi i.e 0.

                             */
              
              facesData.some((faceData) => {
                if (faceData.components[0] === instanceComponent) {
                  const angleBetweenNormalsForThisMainInstance =
                    faceData.facePlane.angleBetweenNormals;
                  angleBetweenNormals += angleBetweenNormalsForThisMainInstance;
                  return true;
                }
              });
            }
            
            const options = {
              angleBetweenNormals,
            };
            
            _addFaceDataObject(
              neighbourToCurrentInstanceFaceObject,
              faceVertices,
              [neighbourToCurrentInstancePickData.component],
              neighbourToCurrentInstancePickData,
              options
            );
            
            _componentsOfInterest.pushIfNotExist(
              neighbourComponentToCurrentInstance,
              (c) => c === neighbourComponentToCurrentInstance
            );
            
            /*
                        there's no check for sibling instances while populating _componentsOfInterest
                        because of the case below

                        https://imgur.com/a/1Zc50sR

                        There's a j on storey 1 as well, so let's call them j2 and j1
                        j2 is level 0, 3 x i's are level 1, j1 becomes level 2

                        For edits, _componentsOfInterest need only contain either j1 or j2
                        But in this case, j's left face on storey 2 is captured, goes down, triggers i-left
                        Then on the next search i-centre and i-right capture points for centre and right faces of j1

                        So, left face is captured on storey 2 but centre and right on storey 1
                        So, if j1 is not added to _componentsOfInterest, those faces don't move
                         */
            
            if (neighbourComponentToCurrentInstance.mesh.isAnInstance) {
              nextLevelInstanceComponents.pushIfNotExist(
                neighbourComponentToCurrentInstance,
                (c) =>
                  areComponentsSiblings(c, neighbourComponentToCurrentInstance)
              );
            }
          }
        });
      });
      
      if (handleNextLevelInstanceComponents) {
        _handleSetOfInstances(nextLevelInstanceComponents, false);
      }
    };
    
    if (
      _componentsOfInterest.length === 1 ||
      _componentsOfInterest.length === 2
    ) {
      const instanceComponents = _componentsOfInterest.filter(
        (c) => c.mesh.isAnInstance
      );
      const nonInstances = _componentsOfInterest.filter(
        (c) => !c.mesh.isAnInstance
      );
      
      _handleSetOfInstances(instanceComponents, true);
    }
  };
  
  const _removePreviousCycleData = function () {
    _removeFacesData();
    _eraseStoredIndices();
  };
  
  function setInitialData(pickData) {
    _removePreviousCycleData();
    
    let space = _CONSTANTS.operatingSpace;
    // newScene.activeCamera.detachControl(canvas);
    
    _meshOfInterest = pickData.mesh;
    _componentOfInterest = _meshOfInterest.getSnaptrudeDS();
    
    pickData.component = _componentOfInterest;
    // _addVerticesIfNecessary(pickData);
    
    if (store.userSettings.freeEdgeEdit) {
      /*
            clickedPointOnFaceOnMove = pickData.pickInfo.pickedPoint;

            let faceVertices;
            if (store.$scope.isTwoDimension){

                _selectedEdgeIn2D = pickData.edge;
                clickedPointOnFaceOnMove = projectionOfPointOnLine(clickedPointOnFaceOnMove, _selectedEdgeIn2D.headPt, _selectedEdgeIn2D.tailPt);

                const faceObject = getFaceObjectFromTopEdgeAndLookForParallelFaces(pickData);
                if (!faceObject) return;

                faceVertices = getFaceVerticesFromFace(faceObject, _meshOfInterest, _CONSTANTS.operatingSpace);

                showFaceIndicator(faceVertices, _meshOfInterest, _CONSTANTS.preSnapFaceIndicatorName, _CONSTANTS.preSnapMaterial);

                _populateComponentsOfInterest(faceVertices);
                _captureInitialCommandData();

            }
            else {

                faceVertices = pickData.face;
                _faceMove3D = true;

                _componentsOfInterest.push(_componentOfInterest);
                _captureInitialCommandData();
            }

            let faceBox = _getPreSnapFaceIndicator();
            faceIndicatorInitialPosition = faceBox.position.clone();
            faceIndicatorInitialPositionConstant = faceIndicatorInitialPosition.clone();

            getBabylonPlane(faceVertices);

            clickedPointOnFaceConstant = clickedPointOnFaceOnMove.clone();
            faceSelected = true;
            _storeFaceIndices(faceVertices);
            */
    } else {
      let faceObject, faceObjectFromVertices;
      
      if (store.$scope.isTwoDimension || pickData.provideTwoDimensionalTreatment) {
        _faceMove3D = false;
        _selectedEdgeIn2D = pickData.edge;
        
        let v1 = _selectedEdgeIn2D.headPt;
        let v2 = _selectedEdgeIn2D.tailPt;
        
        faceIndicatorInitialPosition = BABYLON.Vector3.Center(v1, v2);
      } else {
        _faceMove3D = true;
        
        let faceVertices = pickData.face;
        facetIdStoredForReference = pickData.pickInfo.faceId;
        
        const faceId = getFaceIdFromFacet(
          facetIdStoredForReference,
          _meshOfInterest
        );
        
        const isFaceTopOrBottom = geometryUpdater.util.isFaceTopOrBottom(
          _componentOfInterest,
          faceId
        );
        
        if (!isFaceTopOrBottom && !isMassDependant(_componentOfInterest)) {
          // parametric edit not applicable to top and bottom faces
          
          pickData.edge = getUpperEdgeFromFaceVertices(faceVertices);
          
          pickData.pickInfo = geometryUpdater.util.getFakePickInfo(
            BABYLON.Vector3.Center(pickData.edge.headPt, pickData.edge.tailPt),
            _componentOfInterest,
            geometryUpdater.util.getTopFacetId(_componentOfInterest)
          );
        }
        
        faceObjectFromVertices = getFaceObjectFromVectors(
          faceVertices,
          _meshOfInterest,
          _CONSTANTS.operatingSpace
        );
        
        faceIndicatorInitialPosition = faceVertices
          .reduce((sum, value) => {
            return sum.add(value);
          }, BABYLON.Vector3.Zero())
          .scale(1 / faceVertices.length);
        
        clickedPointOnFaceOnMove = pickData.pickInfo.pickedPoint;
      }
      
      faceIndicatorInitialPositionConstant =
        faceIndicatorInitialPosition.clone();
      
      let parallelFaceData = {};
      if (parametricEditsDisabled) parallelFaceData = null;
      // won't look up parallel faces
      
      if (pickData.edge) {
        // 2D and parametric 3D
        const inferredFaceObject =
          getFaceObjectFromTopEdgeAndLookForParallelFaces(
            pickData,
            parallelFaceData
          );
        if (inferredFaceObject) {
          if (faceObjectFromVertices) {
            // parametric 3D
            if (faceObjectFromVertices.index === inferredFaceObject.index) {
              // checks out
              faceObject = inferredFaceObject;
            } else {
              // mis-identification because of angle of the face maybe. Eg - slant roof
              // trusting the pick pickData rather than inferred pickData
              faceObject = faceObjectFromVertices;
              parallelFaceData = {};
            }
          } else {
            // in 2D
            faceObject = inferredFaceObject;
          }
        } else {
          // no case in particular
          // when face from edge fails
          faceObject = faceObjectFromVertices;
        }
      } else {
        // non-parametric edits in 3D
        faceObject = faceObjectFromVertices;
      }
      
      if (!faceObject) return;
      
      /*
            Not using v1, v2, v3, v4 directly because the face might have additional vertices.
             */
      let faceVertices = getFaceVerticesFromFace(
        faceObject,
        _meshOfInterest,
        space
      );
      showFaceIndicator(
        faceVertices,
        _meshOfInterest,
        _CONSTANTS.preSnapFaceIndicatorName,
        _CONSTANTS.preSnapMaterial
      );
      
      _addFaceDataObject(
        faceObject,
        faceVertices,
        [_componentOfInterest],
        pickData
      );
      
      let parallelFaceObject;
      if (parallelFaceData?.pickData) {
        parallelFaceObject = getFaceObjectFromTopEdgeAndLookForParallelFaces(
          parallelFaceData.pickData
        );
        
        if (parallelFaceObject) {
          let parallelFaceVertices = getFaceVerticesFromFace(
            parallelFaceObject,
            parallelFaceData.component.mesh,
            space
          );
          
          const options = {
            referenceComponentForDirection : _componentOfInterest
          };
          
          parallelFaceData.faceVertices = parallelFaceVertices;
          _addFaceDataObject(
            parallelFaceObject,
            parallelFaceVertices,
            [parallelFaceData.component],
            parallelFaceData.pickData,
            options
          );
        }
      }
      
      _populateComponentsOfInterest();
      if (_.isEmpty(_componentsOfInterest)){
        _componentsOfInterest.push(_componentOfInterest);
      }
      
      if (!parametricEditsDisabled) _handleSimultaneousMultiFaceEdit();
      _captureInitialCommandData();
      
      faceMovementData = _getFaceMovementData();
      // store movement data based on old points
      // (i.e not the newly added vertices, because they will be moved within brep after adding)
      
      let takeExtrudeApproach = false;
      if (_componentsOfInterest.length === 1){
        // check if it's a weird face obtained from split face
        
        const component = _componentsOfInterest[0];
        const brep = component.brep;
        const selectedFaceData = _getAllFacesData()[0];
        const selectedFaceObject = selectedFaceData.faceObject;
        
        const edgeObjects = getEdgeObjectsAroundTheFace(selectedFaceObject);
        
        let numberOfNeighboringParallelFaces = 0;
        
        edgeObjects.forEach((edgeObject) => {
          const otherFace = getEdgeAdjacentFace(edgeObject, selectedFaceObject);
          
          if (_areFacesParallel(selectedFaceObject, otherFace)) {
            
            const edgeVertices = getEdgeVertices(edgeObject);
            const edgePositionsLocalArray = edgeVertices.map(v => brep.positions[v.getIndex()]);
            
            const edgePositionsV3 = edgePositionsLocalArray.map(p => {
              return convertLocalVector3ToGlobal(BABYLON.Vector3.FromArray(p), component.mesh);
            });
            
            const edge = {
              headPt: edgePositionsV3[0],
              tailPt: edgePositionsV3[1],
            }
            
            const threshold = 15; // degrees
            if (!isEdgeVerticalLike(edge, threshold)) numberOfNeighboringParallelFaces++;
          }
        });
        
        if (numberOfNeighboringParallelFaces > 1) {
          takeExtrudeApproach = true;
          // weird face
        } else if (numberOfNeighboringParallelFaces === 1) {
          takeExtrudeApproach = true;
          // regular horizontal/vertical face
          // that might or might not be a part of extruded weird face
        }
      }
      takeExtrudeApproach = false;
      let edited;
      if (takeExtrudeApproach){
        // follow extrude approach
        edited = _extrudeAndRemoveEdges();
      }
      else {
        edited = _addVerticesIfNecessary();
      }
      
      componentVerticesToFetchIndicesMap.forEach((vertices, component) => {
        _storeFaceIndices(vertices, component);
        // to populate the brep and geometry indices according to older vertices
        // if done later, index values of both old and new vertex will be captured
      });
  
      if (edited) {
        
        // constraining rules fail when vertices are dynamically added
        constraintSolver.flush();
        
        componentPositionsToChangeMap.forEach((vectorArray, component) => {
          vectorArray.forEach(([replacee, replacer]) => {
            const replacerLocal = convertGlobalVector3ToLocal(
              replacer,
              component.mesh
            );
            const replaceeLocal = convertGlobalVector3ToLocal(
              replacee,
              component.mesh
            );
        
            component.brep.positions.forEach((p, i) => {
              if (
                store.resolveEngineUtils.areArraysAlmostEqual(
                  p,
                  replaceeLocal.asArray()
                )
              ) {
                component.brep.positions[i] = replacerLocal.asArray();
              }
            });
          });
        });
    
        _componentsOfInterest.forEach((c) => c.mesh.BrepToMesh());
        
        _correctFaceData();
      }
      
      pseudoEdgeRenderer.initialize(_componentsOfInterest);
      constraintSolver.initialize(_componentsOfInterest);
      
      _selectedFaceIn3D = faceVertices;
      faceSelected = true;
    }
    
    _meshEditCheckValue =
      _meshOfInterest.getBoundingInfo().boundingBox.extendSizeWorld.y;
    
    propertyChangeCommandData = handleRoofPropertyChangeCommand(
      _componentsOfInterest
    );
    
    // using delayedExecutionEngine for auto dimension tuning is
    //      1. unnecessary, since no user interaction is present here
    //      2. makes things complex since the operation that called for dimension tuning will itself be using delayedExecutionEngine
    // so directly using the executables in that scenario
    
    if (!programmaticUseOfMoveFace) delayedExecutionEngine.addExecutable(_recordOperation);
    
    
    
    return true;
  }
  
  const _getScenePositionForFace = function (evt) {
    let faceMovement = {};
    faceMovement.normal = _leadingFacePlane.normal.normalize();
    
    let options = {
      faceMovement,
      excludedMeshes: _meshesOfInterest,
    };
    
    if (!store.$scope.isTwoDimension) {
      options.doNotDoSecondaryScenePicks = true;
    }
    
    return findPrioritizedSnapPoint(
      clickedPointOnFaceConstant,
      null,
      _meshOfInterest,
      options
    );
  };
  
  const _getScenePositionForRestrictedMove = function () {
    let snapPoint;
    
    if (_faceMove3D) {
      if (freeFaceMove3D) {
        let options = {
          doNotDoSecondaryScenePicks: true,
        };
        snapPoint = findPrioritizedSnapPoint(
          faceIndicatorInitialPositionConstant,
          null,
          _meshOfInterest,
          options
        );
      } else {
        let options = {
          faceSnap: false,
        };
        
        let intermediatePoint = findSecondarySnappedPoint(
          _meshOfInterest,
          faceIndicatorInitialPositionConstant,
          null,
          options
        );
        if (!intermediatePoint) {
          let weightedPointIn3DSpace = getWeightedPointin3DSpace(
            faceIndicatorInitialPositionConstant
          );
          
          const secondPoint = faceIndicatorInitialPositionConstant.add(
            _leadingFacePlane.normal
          );
          
          let projection = projectionOfPointOnLine(
            weightedPointIn3DSpace,
            faceIndicatorInitialPositionConstant,
            secondPoint
          );
          
          if (isNaN(projection.x) || !projection) {
            intermediatePoint = faceIndicatorInitialPositionConstant;
          } else {
            intermediatePoint = findDimensionSnappedPoint(
              faceIndicatorInitialPositionConstant,
              projection
            );
          }
        }
        
        snapPoint = intermediatePoint;
      }
    } else {
      let edgeMovement = {};
      edgeMovement.edge = _selectedEdgeIn2D;
      
      let options = {
        edgeMovement,
        excludedMeshes: _meshesOfInterest,
        attemptCadSnaps: true,
      };
      
      if (!freeEdgeMove2D) {
        options.snappedToEdge = _selectedEdgeIn2D; //helps in snapping to same mesh
        options.dimensionSnapRefPointAdjuster = (startingPoint, refPoint) => {
          const secondPoint = startingPoint.add(_leadingFacePlane.normal);
          return projectionOfPointOnLine(refPoint, startingPoint, secondPoint);
        };
      }
      
      snapPoint = findPrioritizedSnapPoint(
        faceIndicatorInitialPositionConstant,
        null,
        _meshOfInterest,
        options
      );
    }
    
    return snapPoint;
  };
  
  function move(moveToPoint) {
    disposeSnapToObjects();
    let movementAmountOnMove;
    let moved;
    
    if (store.userSettings.freeEdgeEdit || freeEdgeMove2D || freeFaceMove3D) {
      /*
            This is default SketchUp behaviour for edit edge.
            Will use contextual menu selection to toggle this
             */
      /*
            const snapPoint = _getScenePositionForFace();
            if (!snapPoint) return;

            let preSnapFaceIndicator = _getPreSnapFaceIndicator();

            movementAmountOnMove = snapPoint.subtract(clickedPointOnFaceOnMove);

            let diffLocal = convertGlobalVector3ToLocal(snapPoint, _meshOfInterest).subtract(
                convertGlobalVector3ToLocal(clickedPointOnFaceOnMove, _meshOfInterest)
            );

            moveFace(diffLocal);

            _pointOnPointerMove = preSnapFaceIndicator.position.addInPlace(movementAmountOnMove);
            uiIndicatorsHandler.edgeIndicator.moveBy(movementAmountOnMove);

            clickedPointOnFaceOnMove = snapPoint;
            */
    } else {
      let snapPoint = moveToPoint || _getScenePositionForRestrictedMove();
      
      disposeAxisLine();
      
      /*
            This is default Revit behaviour for edit edge to maintain parametric nature.
            Will use contextual menu selection to toggle this
             */
      let result = updateFaceMovementData(snapPoint);
      if (!result) return;
      
      moved = restrictedPushPull(faceMovementData);
      
      if (moved) {
        if (_faceMove3D) {
          let currentFaceVertices = _selectedFaceIn3D;
          disposeHighlightedFace(_CONSTANTS.postSnapFaceIndicatorName);
          let postSnapFaceIndicator = showFaceIndicator(
            currentFaceVertices,
            _meshOfInterest,
            _CONSTANTS.postSnapFaceIndicatorName,
            _CONSTANTS.postSnapMaterial
          );
        }
        
        const secondPoint = faceIndicatorInitialPositionConstant.add(
          _leadingFacePlane.normal
        );
        _pointOnPointerMove = projectionOfPointOnLine(
          snapPoint,
          faceIndicatorInitialPositionConstant,
          secondPoint
        );
        
        uiIndicatorsHandler.edgeIndicator.moveTo(_pointOnPointerMove);
        // showAxisLine(faceIndicatorInitialPositionConstant, endPoint, _CONSTANTS.normalSnap);
      }
    }
    
    if (moved) {
      const totalMovementAmount = faceIndicatorInitialPositionConstant
        .subtract(_pointOnPointerMove)
        .length();
      
      DisplayOperation.drawOnMove(
        faceIndicatorInitialPositionConstant,
        _pointOnPointerMove
      );
      DisplayOperation.displayOnMove(totalMovementAmount, null, true, {
        onChangeCallback: moveOperator.handleUserInput,
      });
      
      pseudoEdgeRenderer.update();
      /*
            _meshesOfInterest.forEach(m => {
                onSolid(m);
                setLayerTransperancy(m);
            });
            */
    } else {
      disposeAllAxisLines();
      disposeSnapToObjects();
    }
    
    return moved;
  }
  
  function _updateFacePlanes(pointWherePlaneShouldGo) {
    // pointWherePlaneShouldGo.y = _leadingFacePlane.position.y;
    
    const movementVector = pointWherePlaneShouldGo.subtract(
      _leadingFacePlane.position
    );
    const movementAmount = movementVector.length();
    if (!movementAmount) return;
    
    const rotationAxis = BABYLON.Vector3.Up();
    _activeFacePlanes.forEach((facePlane) => {
      const pointToGo = facePlane.position.add(
        rotateVector(
          movementVector,
          rotationAxis,
          facePlane.angleBetweenNormals
        )
      );
      facePlane.d = facePlane.dotCoordinate(
        facePlane.position.subtract(pointToGo)
      );
      facePlane.position = pointToGo;
    });
  }
  
  function updateFaceMovementData(pointWherePlaneShouldGo) {
    _updateFacePlanes(pointWherePlaneShouldGo);
    
    for (let i = 0; i < faceMovementData.length; i++) {
      let t1 = faceMovementData[i].point;
      let t2 = faceMovementData[i].point.add(faceMovementData[i].direction);
      
      let intersection = externalUtil.getPointOfIntersection(
        [t1, t2, faceMovementData[i].facePlane],
        { type: "vector-plane" }
      );
      
      if (intersection) {
        faceMovementData[i].pointDestination = intersection;
      } else return false;
    }
    
    if (!_selectedEdgeIn2D) {
      _selectedEdgeIn2D = {};
      _selectedEdgeIn2D.headPt = faceMovementData[0].point;
      _selectedEdgeIn2D.tailPt = faceMovementData[1].point;
    }
    
    for (let i = 0, j = 1; i < faceMovementData.length; i++, j++) {
      if (j === faceMovementData.length) j = 0;
      let t1 = faceMovementData[i].pointDestination;
      let t2 = faceMovementData[j].pointDestination;
      
      if (t1.almostEquals(t2, 0.001)) {
        //maintains brep structure integrity
        // console.warn("Vertex crossover prevented");
        return false;
      }
    }
    
    return true;
  }
  
  function handleUserInput(input) {
    if (faceSelected) {
      if (store.userSettings.freeEdgeEdit || freeEdgeMove2D || freeFaceMove3D) {
        /*let faceIndicatorMesh = _getPreSnapFaceIndicator();
                if (faceIndicatorMesh){

                    let faceBoxCurrentPosition =  faceIndicatorMesh.getAbsolutePosition().clone();
                    if (freeEdgeMove2D) faceBoxCurrentPosition.y = faceIndicatorInitialPositionConstant.y;

                    let edgeVector = faceIndicatorInitialPositionConstant.subtract(faceBoxCurrentPosition).normalize().scale(input);
                    let p = faceIndicatorInitialPositionConstant.subtract(edgeVector);

                    let diffLocal = convertGlobalVector3ToLocal(p, _meshOfInterest).subtract(
                        convertGlobalVector3ToLocal(faceBoxCurrentPosition, _meshOfInterest)
                    );

                    if (!_faceMove3D){
                        diffLocal.y = 0;
                    }

                    moveFace(diffLocal);
                }*/
      } else {
        let endPoint =
          _pointOnPointerMove || faceIndicatorInitialPositionConstant;
        //this handles the case of double click + don't move + escape (No faceBox2)
        
        let point = faceIndicatorInitialPositionConstant.add(
          endPoint
            .subtract(faceIndicatorInitialPositionConstant)
            .normalize()
            .scale(input)
        );
        let result = updateFaceMovementData(point);
        if (!result) return;
        
        return restrictedPushPull(faceMovementData);
      }
    }
  }
  
  function _recordOperation() {
    
    if (_.isEmpty(_allModifiedComponents)){
      
      // snaptrude_2575 weird test case
      // the concluding click of move face is done 'over' a tool button
      // so finishOperation isn't called
      
      _allModifiedComponents = [..._componentsOfInterest];
      _populateAllComponentsForFinishingOperation();
      _postCompletionVisualProcessing(_allModifiedComponents);
    }
    
    const _allModifiedMeshes = _allModifiedComponents.map((c) => c.mesh);
    const _allModifiedMeshesWithUniqueInstances =
      _allModifiedComponentsWithUniqueInstances.map((c) => c.mesh);
    
    _allModifiedMeshesWithUniqueInstances.forEach((m) => {
      m.BrepToMesh(null, { doNotUpdateSubMeshes: m.subMeshes.length === 1 });
      updateHeightAfterEdits(m);
      updateMeshFacetData(m);
      m.computeWorldMatrix(true);
    });
    
    // _vertexObjectsForSystemGeneratedLabelRemoval.forEach(removeSystemGeneratedLabel);
    
    StoreyMutation.assignStorey(_allModifiedComponents);
    
    let newMeshEditCheckValue =
      _meshOfInterest.getBoundingInfo().boundingBox.extendSizeWorld.y;
    if (_.round(_meshEditCheckValue, 4) !== _.round(newMeshEditCheckValue, 4)) {
      _allModifiedComponents.forEach((c) => c.markAsEdited());
    }
    
    moveFaceCommandData = commandUtils.geometryChangeOperations.getCommandData(
      _allModifiedMeshesWithUniqueInstances,
      moveFaceCommandData
    );
    let moveFaceCommand = commandUtils.geometryChangeOperations.getCommand(
      commandUtils.CONSTANTS.moveFaceOperation,
      moveFaceCommandData
    );
    
    moveFaceCommandData = null;
    
    const propertyChangeCommand = handleRoofPropertyChangeCommand(_allModifiedComponents, propertyChangeCommandData);
    
    let integrationGeometryChangeCommand;
    
    if (autoDimensionTuningInProgress){
      // this differentiation is necessary because calling updateWithGeometryEdit will again call dimensionsTuner
      // making things really complex and unnecessarily slow
      // the integration geometry changes are taken care of in dimensionsTuner.yieldResult
      
      /*
          ABOVE COMMENT IS DEPRECATED

          Calling updateWithoutGeometryEdit here causes problems in complex junctions
          So, as far as graph update is concerned, there's no difference in what function us called

          But during dimension tuning, the tuner is turned off centrally in dimensionsTuner.yieldResult
          to prevent recursive calls
       */
      
      integrationGeometryChangeCommand = virtualSketcher.updateWithGeometryEdit(
        _allModifiedComponentsWithUniqueInstances, true);
    }
    else if (massShrinkageDuringCB){
      /*virtualSketcher.updateWithoutGeometryEdit(
        _allModifiedComponentsWithUniqueInstances, true);*/
      
      // doing this in create building engine
      // need to update the graph of all the masses at once, after they've been shrunk
      // otherwise graph queries to determine shrinkage amount will differ based on mass order of execution
    }
    else {
      integrationGeometryChangeCommand = virtualSketcher.updateWithGeometryEdit(
        _allModifiedComponentsWithUniqueInstances, true);
    }
    
    const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
      [moveFaceCommand, integrationGeometryChangeCommand]
    );
    
    const commands = _.compact([commonGeometryChangeCommand, propertyChangeCommand]);
    const yets = commands.map(_ => false);
    
    // for auto dimension tuning, the commands are aggregated and executed at once, so have to just return the commands
    if (programmaticUseOfMoveFace) return commands;
    else CommandManager.execute(commands, yets);
    
    resetData();
  }
  
  function resetData() {
    _removePreviousCycleData();
    
    clickedPointOnFaceOnMove = null;
    faceSelected = false;
    freeEdgeMove2D = false;
    freeFaceMove3D = false;
    
    faceIndicatorInitialPosition = null;
    faceIndicatorInitialPositionConstant = null;
    clickedPointOnFaceConstant = null;
    facetIdStoredForReference = null;
    _onTheFlyVerticesAdditionData = {};
    _meshesOfInterest.length = 0;
    _componentsOfInterest.length = 0;
    _componentsFromPreviousOperation.length = 0;
    _allModifiedComponents.length = 0;
    _allModifiedComponentsWithUniqueInstances.length = 0;
    moveFaceCommandData = null;
    _selectedFaceIn3D = [];
    disposeAxisLine();
    disposeHighlightedFace();
    pseudoEdgeRenderer.flush();
    constraintSolver.flush();
    
    _pointOnPointerMove = null;
    autoDimensionTuningInProgress = false;
    programmaticUseOfMoveFace = false;
    parametricEditsDisabled = false;
    massShrinkageDuringCB = false;
    
    componentPositionsToChangeMap = new Map();
    componentVerticesToFetchIndicesMap = new Map();
  }
  
  function _postCompletionVisualProcessing(components){
    components.forEach(c => {
      delete c.brep.indexes;
      
      onSolid(c.mesh);
      setLayerTransperancy(c.mesh);
      
      if (c.mesh.isAnInstance){
        c.mesh.sourceMesh.instances.forEach(i => i.refreshBoundingInfo());
      }
      else {
        c.mesh.refreshBoundingInfo();
      }
    });
  }
  
  function _populateAllComponentsForFinishingOperation() {
    
    // putting instances first in the list because updating graph becomes lot simpler
    _allModifiedComponents = _allModifiedComponents.sort((c1, c2) => {
      if (c1.mesh.isAnInstance && c2.mesh.isAnInstance) return 0;
      else if (c1.mesh.isAnInstance) return -1;
      else if (c2.mesh.isAnInstance) return 1;
      else return 0;
    });
    
    _allModifiedComponentsWithUniqueInstances = _.uniqWith(
      _allModifiedComponents,
      (c1, c2) => {
        if (c1.mesh.isAnInstance && c2.mesh.isAnInstance) {
          return c1.mesh.sourceMesh === c2.mesh.sourceMesh;
        } else return false;
      }
    );
  }
  
  function finishOperation(intermediateConclusion) {
    
    if (!_.isEmpty(_componentsFromPreviousOperation)) {
      // mainly edit polygon leads to this now
      _allModifiedComponents = [..._componentsOfInterest, ..._componentsFromPreviousOperation];
      _allModifiedComponents = _.uniq(_allModifiedComponents);
    }
    else {
      _allModifiedComponents = [..._componentsOfInterest];
    }
    
    _populateAllComponentsForFinishingOperation();
    
    _postCompletionVisualProcessing(_allModifiedComponents);
    
    // eslint-disable-next-line no-empty
    if (intermediateConclusion) {
    } else {
      // using delayedExecutionEngine for auto dimension tuning is
      //      1. unnecessary, since no user interaction is present here
      //      2. makes things complex since the operation that called for dimension tuning will itself be using delayedExecutionEngine
      // so directly using the executables in that scenario
      
      let result;
      if (programmaticUseOfMoveFace) result = _recordOperation();
      else delayedExecutionEngine.executeAll();
      
      return result;
    }
  }
  
  function cancelOperation() {
    /*handleUserInput(0, false);
        _removeAddedVerticesIfAny();*/
    
    if (!moveFaceCommandData) {
      resetData();
      return;
    }
    
    let sourceComponentsAffected = [];
    moveFaceCommandData.forEach((data) => {
      const component = data.meshDS;
      if (component.mesh.isAnInstance) {
        const sourceComponent = component.mesh.sourceMesh.getSnaptrudeDS();
        sourceComponent.brep = data.brepBefore;
        sourceComponentsAffected.push(sourceComponent);
      } else {
        sourceComponentsAffected.push(component);
        component.brep = data.brepBefore;
      }
      
      // no need to do copyBrep since this command data will be discarded
    });
    
    sourceComponentsAffected = _.uniq(sourceComponentsAffected);
    
    sourceComponentsAffected.forEach((sourceComponent) => {
      const sourceMesh = sourceComponent.mesh;
      sourceMesh.BrepToMesh(null, { doNotUpdateSubMeshes: sourceMesh.subMeshes.length === 1 });
      
      if (sourceMesh.instances && !_.isEmpty(sourceMesh.instances)) {
        sourceMesh.instances.forEach((instance) => {
          const instanceDS = instance.getSnaptrudeDS();
          instanceDS.brep = sourceComponent.brep;
          instance.refreshBoundingInfo();
        });
      }
    });
    
    const roofs = sourceComponentsAffected
      .filter((c) => c.type.toLowerCase() === "roof")
      .map((r) => r.getDataStore());

    roofs.forEach((roof) => {
      populateRoofBoundInfo(roof);
      
      roof.setRoofPolBottom(
        getOffsetValues(roof.getRoofPolOffsetBottom(), -roof.getRoofOffset(),
        { subtract: true }
      ));
      
      roof.setRoofPolTop(
        getOffsetValues(roof.getRoofPolOffsetTop(), -roof.getRoofOffset(),
        { subtract: true }
      ));
    });
    
    finishOperation(true);
    
    resetData();
  }
  
  function justMoveIt(data) {
    let component = data.component;
    let saveResults = data.saveResults;
    
    let justMovedIt = false;
    
    try {
      
      const allVerticesBeingMoved = [];
      
      // const externalWallHalfThickness = massDissector.getExternalWallThickness() / 2;
      // const internalWallHalfThickness = massDissector.getInternalWallThickness() / 2;
      
      data.movementData.forEach(edgeMovementDatum => {
        allVerticesBeingMoved.push(edgeMovementDatum.edge.headPt);
        allVerticesBeingMoved.push(edgeMovementDatum.edge.tailPt);
      });
      
      data.movementData.some(edgeMovementDatum => {
        
        autoDimensionTuningInProgress = data.forAutoDimensionTuning;
        programmaticUseOfMoveFace = data.programmaticUseOfMoveFace;
        parametricEditsDisabled = data.parametricEditsDisabled;
        massShrinkageDuringCB = data.massShrinkageDuringCB;
        
        const edgeBeingMoved = edgeMovementDatum.edge;
        
        const facetId = edgeMovementDatum.doesEdgeBelongToTopFace ?
          geometryUpdater.util.getTopFacetId(component) :
          geometryUpdater.util.getBottomFacetId(component);
        
        const pickInfo = geometryUpdater.util.getFakePickInfo(
          BABYLON.Vector3.Center(edgeBeingMoved.headPt, edgeBeingMoved.tailPt),
          component,
          facetId
        );
        
        const initialData = {
          edge : edgeBeingMoved,
          mesh : component.mesh,
          pickInfo,
          provideTwoDimensionalTreatment : true,
        };
        
        const edgeBeforeEdit = deepCopyObject(edgeBeingMoved);
        
        const allGood = setInitialData(initialData);
        
        // since simultaneous edit was introduced, while moving one member of edgesToBeMoved
        // another member might've moved. In that case, we can skip that edge
        if (!allGood) {
          _removePreviousCycleData();
          return;
        }
        
        let movementAmount = edgeMovementDatum.movementAmount;
        let movementDirection = edgeMovementDatum.movementDirection;
        
        const facesData = _getAllFacesData();
        
        const moveToPoint = edgeBeingMoved.tailPt.add(movementDirection.scale(movementAmount));
        
        const directionOfMovingEdge = edgeBeingMoved.tailPt.subtract(edgeBeingMoved.headPt).normalize();
        
        justMovedIt = move(moveToPoint.clone());
        if (justMovedIt){
          
          const pointTheFaceShouldMoveTo = externalUtil.getPointOfIntersection([
            moveToPoint, moveToPoint.add(directionOfMovingEdge),
            faceIndicatorInitialPositionConstant, _pointOnPointerMove
          ], {
            type : 'vector-vector',
            ignoreY : true
          });
          
          if (pointTheFaceShouldMoveTo) {
            pointTheFaceShouldMoveTo.y = moveToPoint.y;
            
            justMovedIt = handleUserInput(faceIndicatorInitialPositionConstant.subtract(pointTheFaceShouldMoveTo).length());
            
            const edgeAfterEdit = edgeBeingMoved;
            
            const allCurrentVerticesOfComponent = getAllVertices(component);
            
            allVerticesBeingMoved.forEach(v => {
              if (allCurrentVerticesOfComponent.inArray(vertexInComponent => vertexInComponent.almostEquals(v))){
                // do nothing, that vertex is still present, maybe because the previous move involved adding a vertex
              }
              else {
                if (v.almostEquals(edgeBeforeEdit.headPt)){
                  v.copyFrom(edgeAfterEdit.headPt);
                }
                else if (v.almostEquals(edgeBeforeEdit.tailPt)){
                  v.copyFrom(edgeAfterEdit.tailPt);
                }
              }
            });
            
            if (autoDimensionTuningInProgress){
              
              facesData.forEach(faceData => {
                faceData.faceObjects.forEach((faceObject, i) => {
                  dimensionsTuner.addDefaultFaceMetadata(faceObject);
                  // this is necessary for cases like mass convert columns -> rooms
                  // componentFaceBelongsTo might be later in the dimensionTuner queue
                  
                  const componentFaceBelongsTo = faceData.components[i];
                  component === componentFaceBelongsTo ?
                    faceObject.autoDimensionData.movementAmount += movementAmount :
                    faceObject.autoDimensionData.movementAmount -= movementAmount;
                });
              });
              
            }
            
            if (programmaticUseOfMoveFace){
              data.commands.push(...finishOperation());
            }
            
          }
        }
        else {
          // possibly input limit breached
          
          _removePreviousCycleData();
          return true;
        }
        
      });
      
      if (saveResults) finishOperation();
      else resetData();
      
    }
    catch (e) {
      console.log(e);
    }
    
    return justMovedIt;
  }
  
  return {
    init,
    setInitialData,
    move,
    finishOperation,
    handleUserInput,
    cancelOperation,
    justMoveIt,
    resetData,
    
    util: {
      getFaceObjectFromTopEdgeAndLookForParallelFaces,
    },
  };
})();
export { moveFace };
