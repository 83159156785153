import React, { useEffect } from "react";
import { store } from "../../../../snaptrude/modules/utilityFunctions/Store";
import { useSelector, useDispatch } from "react-redux";
import { Action, getCoveAnalysisState } from "../../../../snaptrude/stateManagers/reducers/objectProperties/coveAnalysisSlice";
import * as S from './styles'
import { EuiInformation } from './other'
import API from "../../../../services/covetool.service";
import { startPollingEUI } from "../../../../snaptrude/modules/covetool/utils";

/**
 * EUI Sidebar
 * @returns {JSX.Element}
 */
const EUISidebar = () => {
  // Redux State
  const dispatch = useDispatch()
  const coveAnalysisState = useSelector(getCoveAnalysisState);
  const { eui, fetchingEUI: loading } = coveAnalysisState
  const euiData = eui[store.floorkey]

  useEffect(() => {
    if(!euiData && !loading) {
      // Fetch EUI of cove project
      dispatch(Action.setFetchingEUIAs(true))
      API.getEUIBreakdown(store.floorkey)
      .then((euiData) => {
          // start polling if data does not exist
          if(!euiData) {
            startPollingEUI(store.floorkey, store.floorkey, () => {}, () => dispatch(Action.setFetchingEUIAs(false)))
          } else {
            dispatch(Action.setEUI({
                floor_key: store.floorkey,
                data: euiData
            }))
            dispatch(Action.setFetchingEUIAs(false))
          }
      })
    }
  }, [euiData, loading])

  if(loading) {
    return (
      <S.MainContainer>
        <S.CenterElement>Loading...</S.CenterElement>
      </S.MainContainer>
    )
  }

  const euiDataWithMoreInfo = (function(){
    if(euiData?.runs?.length > 0) {
      if(typeof euiData.runs[0] == 'object') {
        return {
          ...euiData.runs[0],
          added: euiData?.added
        }
      }
    }

    return null
  })()

  return (
    <S.MainContainer>
      {
        euiData ?
        <S.RecordContainer>
          <EuiInformation data={euiDataWithMoreInfo} />
        </S.RecordContainer> :
        <S.CenterElement>Energy Use Intensity is being calculated.</S.CenterElement>
      }
    </S.MainContainer>
  );
};

export default EUISidebar;
