"use strict";
import BABYLON from "../babylonDS.module.js";
import $ from "jquery";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import {
  removeThrowAwayIdentifier,
  showToast,
  updateToast,
} from "../extrafunc.js";
import { makeid } from "../../libs/arrayFuncs.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { autoSaveConfig } from "./autoSaveConfig.js";
import { update_project_image } from "../../libs/serverFuncs.js";
import { NewLogger } from "../logger/logger.js";
import { typicalNotifier } from "../../libs/apiCalls/backendCalls.js";
import { refreshAccessToken, getJWT } from "../../../services/jwt.service.js";
import { updateProjectImage as updateImage } from "../../../services/projects.service.js";
import { CollaborationNotification } from "./notification.js";
import { cameraFollow } from "./cameraFollow.js";
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { addIncomingChangelog } from "../../stateManagers/reducers/objectProperties/changelogsSlice.js";
import { ChangelogConnect } from "./changelog.js";

var AutoSave = (function () {
  let autoSave = {};
  let startTime = 0;
  let endTime = 0;
  const history = [];
  let refreshCounter = 0;
  const REFRESH_THRESHOLD = 20;

  const STATES = {
    ready: 1,
    waitingForServer: 2,
  };

  const CONSTANTS = {
    saveCommandIdLength: 5,
    queueSizeForLoaderDisplay: 5,
  };

  const userMessagingOrchestration = {
    loaderShown: false,
    loaderShouldBeShown: false,
    intervalId: null,
    index: 0,
  };

  let currentState = STATES.ready;

  const executionQueue = [];

  /*
    Helper functions
     */

  autoSave.getSaveDataPrototype = function () {
    let identifier;
    if (store.activeLayer) {
      identifier = {
        structure_id: store.activeLayer.structure_id,
      };
    }

    return {
      commandId: null,
      data: {
        identifier,
        beforeOperationData: {},
        afterOperationData: {},
        operationData: null,
        saveType: null,
      },
    };
  };

  autoSave.getComponentClass = function (type) {
    if (type) {
      type = removeThrowAwayIdentifier(type);

      switch (type.toLowerCase()) {
        case "mass":
          return "masses";
        case "void":
          return "masses";
        case "wall":
          return "walls";
        case "roof":
          return "roofs";
        case "floor":
          return "floors";
        case "door":
          return "doors";
        case "window":
          return "windows";
        case "furniture":
          return "furnitures";
        case "staircase":
          return "staircases";
      }
    }
  };

  autoSave.getComponentIdentifier = function (componentDS) {
    let identifier = {};
    identifier.structure_id = componentDS.structure_id;
    identifier.level_id = componentDS.level_id;
    identifier.level = componentDS.level_low + "" + componentDS.level_high;
    identifier.storey = componentDS.storey;
    identifier.type = componentDS.type;
    identifier.component_id = componentDS.mesh.uniqueId;
    identifier.dsPropsId = componentDS.id;
    identifier.floorkey = store.floorkey;

    return identifier;
  };

  autoSave.sanitizeSaveData = function (saveData) {
    if (!_.isArray(saveData)) saveData = [saveData];
    saveData = _.compact(saveData);

    return saveData;
  };

  autoSave.restructureSaveData = function (saveData) {
    saveData = autoSave.sanitizeSaveData(saveData);
    let netCost = 0;
    let operation = null;
    let sendCost = false;
    const identifierMap = {furniture: {team: {}}};

    saveData.forEach((saveDataPoint) => {
      if (saveDataPoint.data.identifier) {
        saveDataPoint.data.identifier.componentClass =
          autoSave.getComponentClass(saveDataPoint.data.identifier.type);
      }
      if (
        saveDataPoint.data &&
        saveDataPoint.data.change &&
        saveDataPoint.data.change.cost != null
      ) {
        if (saveDataPoint.data.change.isRevitImport) {
          sendCost = true;
          let previousCost = saveDataPoint.data.change.identifier?.previousCost ? saveDataPoint.data.change.identifier?.previousCost : 0
          netCost += saveDataPoint.data.change.cost.reduce(
            (partialSum, num) => partialSum + num,
            0
          ) - previousCost;
          if (saveDataPoint.data.change.identifier) {
            if (saveDataPoint.data.change.identifier.team) {
              if (saveDataPoint.data.change.identifier?.group?.groupName) {
                const groupName =
                  saveDataPoint.data.change.identifier?.group?.groupName;
                const groupCategory =
                  saveDataPoint.data.change.identifier?.group?.groupCategory;
                const groupFamily =
                  saveDataPoint.data.change.identifier?.group?.groupFamily;
                if (!identifierMap["revitMetaData"]) {
                  identifierMap["revitMetaData"] = {};
                  identifierMap["revitMetaData"]["team"] =
                    saveDataPoint.data.change.identifier.team;
                }
                if (identifierMap["revitMetaData"][groupName]) {
                  ++identifierMap["revitMetaData"][groupName]["occurrence"];
                } else {
                  const elements =
                    saveDataPoint.data.change.identifier.elementId;
                  const elementNames =
                    saveDataPoint.data.change.identifier.elements;
                  const categories =
                    saveDataPoint.data.change.identifier.categories;
                  const families =
                    saveDataPoint.data.change.identifier.families;
                  const cost = saveDataPoint.data.change.cost;
                  let snaptrudeElements = {},
                    nonSnaptrudeElements = {};
                  if (
                    !elements.length ||
                    (elements.length == 1 &&
                      elements[0] == "-1" &&
                      groupName == elementNames[0])
                  ) {
                    identifierMap["revitMetaData"][groupName] = {};
                    identifierMap["revitMetaData"][groupName]["occurrence"] = 1;
                    identifierMap["revitMetaData"][groupName]["category"] =
                      groupCategory;
                    identifierMap["revitMetaData"][groupName]["family"] =
                      groupFamily;
                  } else {
                    elements.forEach((element, i) => {
                      if (element != -1) {
                        if (snaptrudeElements[element]) {
                          ++snaptrudeElements[element]["occurrence"];
                        } else {
                          snaptrudeElements[element] = {};
                          snaptrudeElements[element]["occurrence"] = 1;
                          snaptrudeElements[element]["cost"] = cost[i];
                          snaptrudeElements[element]["name"] = elementNames[i];
                          snaptrudeElements[element]["category"] =
                            categories[i];
                          snaptrudeElements[element]["family"] = families[i];
                        }
                      } else {
                        if (nonSnaptrudeElements[elementNames[i]]) {
                          ++nonSnaptrudeElements[elementNames[i]]["occurrence"];
                        } else {
                          nonSnaptrudeElements[elementNames[i]] = {};
                          nonSnaptrudeElements[elementNames[i]][
                            "occurrence"
                          ] = 1;
                          nonSnaptrudeElements[elementNames[i]]["category"] =
                            categories[i];
                          nonSnaptrudeElements[elementNames[i]]["family"] =
                            families[i];
                        }
                      }
                      identifierMap["revitMetaData"][groupName] = {
                        snaptrudeElements,
                        nonSnaptrudeElements,
                      };
                      identifierMap["revitMetaData"][groupName][
                        "occurrence"
                      ] = 1;
                      identifierMap["revitMetaData"][groupName]["category"] =
                        groupCategory;
                      identifierMap["revitMetaData"][groupName]["family"] =
                        groupFamily;
                    });
                  }
                }
              } else {
                const elements = saveDataPoint.data.change.identifier.elementId;
                for (let id = 0; id < elements.length; ++id) {
                  const elementId = elements[id];
                  if (
                    identifierMap.furniture.team[
                      saveDataPoint.data.change.identifier.team
                    ]
                  ) {
                    if (
                      identifierMap.furniture.team[
                        saveDataPoint.data.change.identifier.team
                      ][elementId]
                    ) {
                      identifierMap.furniture.team[
                        saveDataPoint.data.change.identifier.team
                      ][elementId]++;
                    } else {
                      identifierMap.furniture.team[
                        saveDataPoint.data.change.identifier.team
                      ][elementId] = 1;
                    }
                  } else {
                    identifierMap.furniture.team[
                      saveDataPoint.data.change.identifier.team
                    ] = {};
                    identifierMap.furniture.team[
                      saveDataPoint.data.change.identifier.team
                    ][elementId] = 1;
                  }
                }
              }
            }
          }
        } else {
          sendCost = true;
          netCost += saveDataPoint.data.change.cost;

          if (saveDataPoint.data.change.identifier) {
            if (saveDataPoint.data.change.identifier.team) {
              if (
                identifierMap.furniture.team[
                  saveDataPoint.data.change.identifier.team
                ]
              ) {
                if (
                  identifierMap.furniture.team[
                    saveDataPoint.data.change.identifier.team
                  ][saveDataPoint.data.change.identifier.elementId]
                ) {
                  identifierMap.furniture.team[
                    saveDataPoint.data.change.identifier.team
                  ][saveDataPoint.data.change.identifier.elementId]++;
                } else {
                  identifierMap.furniture.team[
                    saveDataPoint.data.change.identifier.team
                  ][saveDataPoint.data.change.identifier.elementId] = 1;
                }
              } else {
                identifierMap.furniture.team[
                  saveDataPoint.data.change.identifier.team
                ] = {};
                identifierMap.furniture.team[
                  saveDataPoint.data.change.identifier.team
                ][saveDataPoint.data.change.identifier.elementId] = 1;
              }
            }
          }
        }
      }
      if (saveDataPoint.data && saveDataPoint.data.change && saveDataPoint.data.change.operation) {
        operation = saveDataPoint.data.change.operation;
      }
    });

    let restructuredSaveData = {};
    restructuredSaveData.id =
      "save_" + store.floorkey + "_" + makeid(CONSTANTS.saveCommandIdLength);
    restructuredSaveData.payload = saveData;
    restructuredSaveData.floorkey = store.floorkey;
    restructuredSaveData.userID = store.infoUser.id;
    restructuredSaveData.userEmail = store.infoUser.email;
    restructuredSaveData.change = {
      cost: netCost,
      operation: operation,
      identifier: identifierMap,
    };

    if(!sendCost){
      restructuredSaveData.change.cost = null;
    }

    return restructuredSaveData;
  };

  autoSave.linkSaveIdToCommand = function (saveCommand) {
    let lastCommand = CommandManager.getLatestCommand();
    if (lastCommand) lastCommand.saveCommandId = saveCommand.id;
  };

  /*
    Execution functions
     */
  autoSave.publishCommand = function (saveData, direct = false) {
    if (store.sceneLoadInProgress) return;
    if (autoSaveConfig.isDisabled()){
      CollaborationNotification.unauthorised(saveData);
      return;
    }
    let saveCommand = autoSave.restructureSaveData(saveData);

    if (!direct) autoSave.linkSaveIdToCommand(saveCommand);

    autoSave.addToQueue("data", saveCommand);
  };

  autoSave.directPublish = function (saveData) {
    autoSave.publishCommand(saveData, true);
  };

  autoSave.undo = function (command) {
    if (autoSaveConfig.isDisabled()) {
      return;
    }
    let undoData = {
      saveCommandId: command.saveCommandId,
      floorkey: store.floorkey,
      userID: store.infoUser.id,
      userEmail: store.infoUser.email,
      executeLeftToRight: command.executeLeftToRight,
    };

    autoSave.addToQueue("undo", undoData);
  };

  autoSave.redo = function (command) {
    if (autoSaveConfig.isDisabled()) {
      return;
    }
    let redoData = {
      saveCommandId: command.saveCommandId,
      floorkey: store.floorkey,
      userID: store.infoUser.id,
      userEmail: store.infoUser.email,
    };

    autoSave.addToQueue("redo", redoData);
  };

  /*
    State query operations
     */

  autoSave.isReady = function () {
    return currentState === STATES.ready;
  };

  autoSave.markAsReady = function () {
    currentState = STATES.ready;
  };

  autoSave.markAsWaiting = function () {
    currentState = STATES.waitingForServer;
  };

  /*
    Queuing functions
     */

  autoSave.updateProjectImage = function () {
    BABYLON.Tools.CreateScreenshot(
      store.engine,
      store.scene.activeCamera,
      { height: 400 },
      function (data) {
        updateImage(data, store.floorkey);
      }
    );
  };

  autoSave.showToast = function (message, duration, type) {
    if (autoSaveConfig.areToastsAllowed()) {
      console.log("AutoSave toast -", message);
      if (
        autoSave.loaderController.isLoaderShown() ||
        autoSave.loaderController.shouldLoaderBeShown()
      ) {
        autoSave.loaderController.stop();
        if (duration === 0) {
          // do nothing, persistent message being shown
        } else {
          _.delay(() => {
            if (_.size(executionQueue) > 0) autoSave.loaderController.start();
          }, duration);
        }
      }
      // if not stopped, new message will be shown which will be overwritten by loaderController.update()

      showToast(message, duration, type);
    }
  };

  /*
    Loader related functions
     */

  autoSave.loaderController = {};

  autoSave.loaderController.start = function () {
    // if disconnected, the disconnected toast should continue to be visible
    if (autoSaveConfig.isDisconnected()) {
      userMessagingOrchestration.loaderShouldBeShown = true;
      return;
    }

    if (!autoSave.loaderController.isLoaderShown()) {
      autoSave.loaderController.cleanUp();

      autoSave.showToast(
        autoSaveConfig.CONSTANTS.savingMessage,
        0,
        autoSaveConfig.CONSTANTS.toastError
      );

      autoSave.loaderController.scheduleUpdate();

      userMessagingOrchestration.loaderShown = true;
      userMessagingOrchestration.loaderShouldBeShown = false;
    }
  };

  autoSave.loaderController.scheduleUpdate = function () {
    userMessagingOrchestration.intervalId = setInterval(() => {
      const nextSpinner =
        autoSaveConfig.CONSTANTS.asciiSpinners[
          userMessagingOrchestration.index++
        ];
      if (
        userMessagingOrchestration.index >=
        autoSaveConfig.CONSTANTS.asciiSpinners.length - 1
      ) {
        userMessagingOrchestration.index = 0;
      }

      autoSave.loaderController.update(
        autoSaveConfig.CONSTANTS.savingMessage + nextSpinner
      );
    }, 200);
  };

  autoSave.loaderController.update = function (message) {
    if (autoSaveConfig.areToastsAllowed()) {
      updateToast(message);
    }
  };

  autoSave.loaderController.stop = function () {
    if (autoSave.loaderController.isLoaderShown()) {
      autoSave.loaderController.cleanUp();

      autoSave.showToast(
        autoSaveConfig.CONSTANTS.saveCompleteMessage,
        autoSaveConfig.CONSTANTS.shortMessageDuration,
        autoSaveConfig.CONSTANTS.toastSuccess
      );
    }
  };

  autoSave.loaderController.cleanUp = function () {
    clearInterval(userMessagingOrchestration.intervalId);
    userMessagingOrchestration.index = 0;
    userMessagingOrchestration.loaderShown = false;
    userMessagingOrchestration.loaderShouldBeShown = false;
  };

  autoSave.loaderController.isLoaderShown = function () {
    return userMessagingOrchestration.loaderShown;
  };

  autoSave.loaderController.shouldLoaderBeShown = function () {
    return userMessagingOrchestration.loaderShouldBeShown;
  };

  /*
    Socket interfacing functions
     */

  autoSave.handleFailure = function (message) {
    autoSave.showToast(
      message,
      autoSaveConfig.CONSTANTS.failureMessageDuration,
      autoSaveConfig.CONSTANTS.toastError
    );
  };

  autoSave.refreshAndRetry = async () => {
    if(refreshCounter > REFRESH_THRESHOLD){
      console.log("Token expired");
      showToast("Session timed out, Please login again");
      return;
    }
    refreshCounter++;
    refreshAccessToken()
      .then(() => {
        autoSave.markAsReady();
        autoSave.executeNext();
      })
      .catch(err => {

      });
  };

  autoSave.emptyCounter = () => {
    refreshCounter = 0;
  };

  autoSave.acknowledgementHandler = async function (ack) {
    endTime = window.performance.now();
    const timeTaken = endTime - startTime;
    NewLogger.logPerformanceAutoSave(timeTaken);
    if(ack.message === autoSaveConfig.CONSTANTS.expired){
      await autoSave.refreshAndRetry();
      return;
    }
    if (ack.message === autoSaveConfig.CONSTANTS.saveSuccessMessage){
      if(ack.history){
          history.push({
              id: ack.history.id,
              author: store.infoUser
          });
      } else {
          console.error("Did not get history from backend.", store.floorkey);
      }
      if(ack.log){
        ChangelogConnect.handleIncomingChangelog(ack.log);
      }
      if(!store.isCoveToolProject) autoSave.updateProjectImage();
    } else if(ack.message === autoSaveConfig.CONSTANTS.unauthorized){
      autoSaveConfig.disable();
      cameraFollow.unAuthoriseToEmit();
      CollaborationNotification.unauthorised();
    } else {
      console.warn("Autosave error", ack);
      autoSave.handleFailure(
        autoSaveConfig.CONSTANTS.autoSaveFailureNewMessage
      );
      ack = JSON.stringify(ack);
      typicalNotifier(`There was an autosave error:\n\n ${ack}`);
      // userpilot.trigger("1615973495vDdj4974");
    }
    autoSave.markAsReady();
    autoSave.deQueue();
  };

  autoSave.emitEvent = function (
    event,
    data,
    callback = autoSave.acknowledgementHandler
  ) {
    let autoSaveSocket = autoSaveConfig.getSocketObject();
    if (autoSaveSocket.connected) {
      data.sessionId = store.sessionId;
      data.jwt = getJWT();
      let stringifiedData = JSON.stringify(data);
      // Stringification not required but a self referencing object here would lead
      // to stack overflow w/o a proper error message
      startTime = window.performance.now();
      autoSaveSocket.emit(event, stringifiedData, callback);
      autoSave.markAsWaiting();
    } else {
      // autoSave.handleFailure(autoSaveConfig.CONSTANTS.disconnectMessage);
    }
  };

  autoSave.addChangeLog = (saveData, operation = "") => {
    let saveCommand = autoSave.restructureSaveData(saveData);
    const data = {
      change: {
        cost: saveCommand.change.cost,
        operation: operation,
        identifier: saveCommand.change.identifier,
      },
      commandID: saveCommand.id,
    };
    autoSave.addToQueue("addchangelog", data);
  };

  autoSave.addToQueue = function (event, eventData) {
    executionQueue.push({ event, eventData });

    if (_.size(executionQueue) === 1 || autoSave.isReady()) {
      autoSave.executeNext().catch((err) => {
        console.error(err);
        autoSave.deQueue();
      });
    } else {
      console.log("AutoSave queue size is up to " + _.size(executionQueue));
      // autoSaveConfig.verifyConnection();

      if (_.size(executionQueue) > CONSTANTS.queueSizeForLoaderDisplay)
        autoSave.loaderController.start();
    }
  };

  autoSave.deQueue = function () {
    executionQueue.splice(0, 1);

    if (_.size(executionQueue) > 0) {
      console.log("AutoSave queue size is down to " + _.size(executionQueue));
    } else {
      autoSave.loaderController.stop();
    }

    autoSave.executeNext().catch((err) => {
      console.error(err);
      autoSave.deQueue();
    });
  };

  autoSave.flushQueue = function () {
    executionQueue.length = 0;
  };

  autoSave.executeNext = function () {
    return new Promise((resolve) => {
      if (!_.isEmpty(executionQueue)) {
        let firstInQueue = _.first(executionQueue);
        autoSave.emitEvent(firstInQueue.event, firstInQueue.eventData);
        resolve();
      }
    });
  };

  autoSave.getQueue = function () {
    return executionQueue;
  };

  autoSave.removePopup = function () {
    // userpilot.end("1615973495vDdj4974");
  };

  autoSave.getStructureCollectionUsingAJAX = function () {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: autoSaveConfig.getSocketUrl() + "migrateorstructure",
        data: {
          floorkey: store.floorkey,
          userID: store.infoUser.id,
          userEmail: store.infoUser.email,
        },
        success: function (data) {
          resolve(data[store.floorkey]);
        },
        error: function (error) {
          reject(error);
        },
        dataType: "JSON",
        method: "post",
      });
    });
  };

  autoSave.getStructureCollectionUsingSocket = function () {
    return new Promise((resolve, reject) => {
      const socket = autoSaveConfig.getSocketObject();

      const identifier = {
        floorkey: store.floorkey,
        userID: store.infoUser.id,
        userEmail: store.infoUser.email,
      };

      socket.emit("getstructure", identifier, (data) => {
        if (data.status == 200) {
          resolve(data.result);
        } else {
          reject(data.result);
        }
      });
    });
  };

  autoSave.getHistory = () => history;

  autoSave.getLatestSave = () => history.length ? history[history.length - 1] : null;

  autoSave.incomingSave = historyObject => {
      history.push(historyObject);
  };

  autoSave.emptyHistory = () => {
    history.length = 0;
  };

  return autoSave;
})();
export { AutoSave };
