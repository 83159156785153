import _ from "lodash";
var collator = new Intl.Collator("en", {
    numeric: true,
    sensitivity: 'base'
});

export const formatMultipleSubType = (newsubtype, existingsubtype) => {
    if(newsubtype.startsWith('-')){
        newsubtype = newsubtype.substring(1);
        const filteredSubtype = _.filter(_.uniq(existingsubtype.split(";")), (o) => o.toLowerCase() !== newsubtype.toLowerCase());
        return filteredSubtype.join(';');
    }else{
        newsubtype = existingsubtype + ";" + newsubtype;
        const filteredSubtype = _.uniq(newsubtype.split(";"));
        return filteredSubtype.join(';');
    }
}

export const orderProjects = (projects, sortOrder) => {
    switch (sortOrder) {
        case "latest":
            projects = _.orderBy(projects, project => new Date(project.updated), "desc");
            break;
        case "earliest":
            projects = _.orderBy(projects, project => new Date(project.added), "asc");
            break;
        case "projectname":
            projects = _.clone(projects)
            projects = projects.sort((a,b) => collator.compare(a.name, b.name));
            break;
        case "createdby":
            projects = _.clone(projects)
            projects = projects.sort((a,b) => collator.compare(a.createdby, b.createdby));
            break;
        default:
            break;
    }
    return projects;
}

export const orderFolders = (folders, sortOrder) => {
    switch (sortOrder) {
        case "latest":
            folders = _.orderBy(folders, folder => new Date(folder.updated), "desc");
            break;
        case "earliest":
            folders = _.orderBy(folders, folder => new Date(folder.added), "asc");
            break;
        case "projectname":
            folders = _.clone(folders);
            folders = folders.sort((a, b) => collator.compare(a.name, b.name));
            break;
        case "createdby":
            folders = _.clone(folders);
            folders = folders.sort((a, b) => collator.compare(a.createdby, b.createdby));
            break;
        default:
            break;
    }
    return folders;
}
