import {
  convertBrepPositionsFromTypedArrayToArray,
  copyBrep,
  getEdgeFaces,
  getEdgeObjectFromVertexObjects,
  getEdgeVertices,
  getFaceVerticesFromFace,
  getLowerVertexOfTheEdge,
  getTopFaceVertices,
  getVertexObjectFromPositionV3,
  labelElementAsSystemGenerated,
  reCenterBrepPositions
} from "../../libs/brepOperations";
import {
  convertLocalVector3ToGlobal,
  getBabylonPlane,
  getCollinearVertices,
  getEdgesFromFaceVertices,
  markMeshAsThrowAway,
  removeCollinearVertices
} from "../extrafunc";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray} from "../../libs/arrayFuncs";
import _ from "lodash";
import {store} from "../utilityFunctions/Store.js"
import {commandUtils} from "../commandManager/CommandUtils";
import {pasteObject} from "../../libs/defaultEvents";
import {virtualSketcher} from "../sketchMassBIMIntegration/virtualSketcher";
import {CommandManager} from "../commandManager/CommandManager";
import {is2D} from "../../libs/twoDimension";
import {geometryUpdater} from "../sketchMassBIMIntegration/geometryUpdater";
import {externalUtil} from "../externalUtil";
import BABYLON from "../babylonDS.module.js";
import {projectionOfPointOnFace, projectionOfPointOnLine} from "../../libs/snapFuncs";
import {GLOBAL_CONSTANTS} from "../utilityFunctions/globalConstants";
import {StoreyMutation} from "../storeyEngine/storeyMutations";
import {splitFaceOperator} from "../meshoperations/splitFaceOperations";
import {assignColumnProperties, shouldMassBeAColumn} from "./drawUtil";
import {handleRoofPropertyChangeCommand} from "../meshoperations/moveOperations/moveUtil";

const snapmda = window.snapmda;

const splitMass = (function (){

  const HIGH_INTERSECTION_THRESHOLD = 1E-1;
  let _splitPoints, _splitPointsLower;
  
  const splitUsingTopEdge = function (component, splitPoints){
    
    const _getLowerPoint = function (point, snapData) {
      const options = { facetId: NaN, faceId: topFaceId };
      if (snapData.snappedToVertex) {
        return getLowerVertexOfTheEdge(point, component.mesh, options);
      }
      else if (snapData.snappedToEdge) {
        const v1 = snapData.snappedToEdge.headPt;
        const v2 = snapData.snappedToEdge.tailPt;
        
        const v1Lower = getLowerVertexOfTheEdge(v1, component.mesh, options);
        const v2Lower = getLowerVertexOfTheEdge(v2, component.mesh, options);
        
        const v1Tov1Lower = v1Lower.subtract(v1);
        const v2Tov2Lower = v2Lower.subtract(v2);
        
        const direction = v1Tov1Lower.add(v2Tov2Lower).scale(0.5).normalize();
        
        let possiblePoint = externalUtil.getPointOfIntersection([
          point,
          point.add(direction),
          v1Lower,
          v2Lower,
        ]);
        
        if (!possiblePoint){
          possiblePoint = point.clone();
          possiblePoint.y = v1Lower.y;
        }

        return projectionOfPointOnLine(possiblePoint, v1Lower, v2Lower);
      }
      else {
        return projectionOfPointOnFace(point, null, bottomFacePlane);
      }
      
    }
    
    const _correctSplitPointsAndGetSnapData = function (){
      return splitPoints.map((point) => {
        
        // this is necessary for points snapped to face because the splitPoints are obtained from draw, which uses points on the base
        // of the active storey. So, have to align them with the top face first
        
        const optionsForIntersection = {
          type: "vector-plane"
        };
        
        const intersection = externalUtil.getPointOfIntersection(
          [point, point.add(topFacePlane.normal.negate()), topFacePlane], optionsForIntersection
        );
        
        const snapData = {
          snappedToVertex: null,
          snappedToEdge: null,
        };
        
        if (intersection) {
          point.copyFrom(intersection);
          
          topFaceVertices.some(v => {
            if (v.almostEquals(intersection)){
              snapData.snappedToVertex = v;
              point.copyFrom(v);
              return true;
            }
          });
          
          if (!snapData.snappedToVertex) {
            topFaceEdges.some(edge => {
              if (store.resolveEngineUtils.onSegment3D(edge.headPt, intersection, edge.tailPt, HIGH_INTERSECTION_THRESHOLD)){
                snapData.snappedToEdge = edge;

                const projection = projectionOfPointOnLine(intersection, edge.headPt, edge.tailPt);
                point.copyFrom(projection);

                return true;
              }
            });
          }
        }
        else {
          console.warn("Intersection not found");
          console.warn(point);
        }
        
        return snapData;
      });
    }
    
    if (!is2D()) return;
    
    component.mesh.computeWorldMatrix();
    
    const splitLeftBrep = copyBrep(component.brep);
    const brepBefore = copyBrep(component.brep);
    
    // Confirmwithpick interacts with the pickinfo to check if we're
    // dealing with the required face and intended.
    // User issue #96

    splitPoints = removeCollinearVertices(splitPoints);

    let topFaceId, bottomFaceId;
    
    if (is2D()){
      topFaceId = geometryUpdater.util.getTopFaceId(component,{confirmWithPick : true});
      bottomFaceId = geometryUpdater.util.getBottomFaceId(component,{confirmWithPick : true});
    }
    else {
      const v1 = splitPoints[0];
      const v2 = splitPoints[1];
      
      let edgeForV1, edgeForV2;
      
      const brep = component.brep;
      component.brep.edges.forEach(edgeObject => {
        const edgeVertices = getEdgeVertices(edgeObject);
        const edgePositionsLocalArray = edgeVertices.map(v => brep.positions[v.getIndex()]);
        
        const edgePositionsV3 = edgePositionsLocalArray.map(p => {
          return convertLocalVector3ToGlobal(BABYLON.Vector3.FromArray(p), component.mesh);
        });
        
        const edge = {
          headPt: edgePositionsV3[0],
          tailPt: edgePositionsV3[1],
        };
        
        if (store.resolveEngineUtils.onSegment3D(edge.headPt, v1, edge.tailPt)){
          edgeForV1 = edgeObject;
        }
        else if (store.resolveEngineUtils.onSegment3D(edge.headPt, v2, edge.tailPt)){
          edgeForV2 = edgeObject;
        }
      });
      
      const facesAroundE1 = getEdgeFaces(edgeForV1);
      const facesAroundE2 = getEdgeFaces(edgeForV2);
      
      const commonFace = _.intersection(facesAroundE1, facesAroundE2)[0];
      
      if (!commonFace) return;
      
      topFaceId = commonFace.getIndex();
      
      const h1 = edgeForV1.getHalfEdge();
      
      let h1Flip;
      if (h1.getFace() === commonFace){
        h1Flip = h1.getFlipHalfEdge();
      }
      else h1Flip = h1;
      
      bottomFaceId = h1Flip.getNextHalfEdge().getNextHalfEdge().getFlipHalfEdge().getFace().getIndex();
    }
    
    const topFaceObject = splitLeftBrep.getFaces()[topFaceId];
    const bottomFaceObject = splitLeftBrep.getFaces()[bottomFaceId];
    
    const topFaceVertices = getFaceVerticesFromFace(topFaceObject, component.mesh);
    const topFacePlane = getBabylonPlane(removeCollinearVertices(topFaceVertices));
    
    const topFaceEdges = getEdgesFromFaceVertices(topFaceVertices);
    
    const bottomFaceVertices = getFaceVerticesFromFace(bottomFaceObject, component.mesh);
    const bottomFacePlane = getBabylonPlane(removeCollinearVertices(bottomFaceVertices));
    
    const snapsData = _correctSplitPointsAndGetSnapData();
    
    const splitPointsLower = splitPoints.map((point, i) => {
      return _getLowerPoint(point, snapsData[i]);
    });

    _splitPoints = splitPoints;
    _splitPointsLower = splitPointsLower;

    const splitPointsLowerReversed = [...splitPointsLower];
    splitPointsLowerReversed.reverse();
    
    component.brep = splitLeftBrep; // TODO - fix this
    
    splitFaceOperator.performSplitFromData(component, splitLeftBrep, splitPoints, topFaceObject);
    splitFaceOperator.performSplitFromData(component, splitLeftBrep, splitPointsLowerReversed, bottomFaceObject);
    
    const optionsWithBrepRef = {
      brep: splitLeftBrep
    };

    
    const allVerticesToAdd = [...splitPoints, ...splitPointsLowerReversed];
    const addedVertexObjects = allVerticesToAdd.map(v3 => {
      return getVertexObjectFromPositionV3(component.mesh, v3, BABYLON.Space.WORLD, optionsWithBrepRef);
    });
    
    
    if (addedVertexObjects.length !== allVerticesToAdd.length){
      console.warn("Vertices not added");
      return;
    }
    
    convertBrepPositionsFromTypedArrayToArray(splitLeftBrep);
    
    const addedEdgeObjects = addedVertexObjects.map(vo1 => {
      const vo2 = getCircularlyNextElementInArray(addedVertexObjects, vo1);
      
      let edge = splitLeftBrep.getEdge(vo1.getIndex(), vo2.getIndex());
      if (!edge) {
        // edge will be absent for vertical edges
        let newObjects = snapmda.InsertEdgeOperator(splitLeftBrep, vo1.getIndex(), vo2.getIndex());
        edge = newObjects.edge;
      }
      
      return edge;
    });
    
    const splitRightBrep = copyBrep(splitLeftBrep);
    
    const [leftFace, rightFace] = _getFacesAroundEdge(addedEdgeObjects[0]);
    
    const addedEdgeObjectsInSplitRightBrep = addedEdgeObjects.map(e => splitRightBrep.getEdges()[e.getIndex()]);
    const addedVertexObjectsInSplitRightBrep = addedVertexObjects.map(v => splitRightBrep.getVertices()[v.getIndex()]);
    const leftFaceInSplitRightBrep = splitRightBrep.getFaces()[leftFace.getIndex()];
    
    _deSkeletonBrep(splitLeftBrep, addedEdgeObjects, addedVertexObjects, rightFace);
    _deSkeletonBrep(splitRightBrep, addedEdgeObjectsInSplitRightBrep, addedVertexObjectsInSplitRightBrep, leftFaceInSplitRightBrep);
    
    component.brep = brepBefore; // TODO - fix this
    
    _createComponents(component, splitLeftBrep, splitRightBrep);
    
  };
  
  const _createCopy = function (mesh, isUnique) {
    const optionsForPaste = {
      uniqueObject: isUnique,
      handleChildren: false,
    };
    
    return pasteObject(mesh, optionsForPaste);
  }
  
  const _cleanBrep = function (component){
    
    const _removeRedundantElements = function (brep) {
      const HEs = brep.getHalfEdges();
      const HEsFromEdges = [];
      
      brep.getEdges().forEach(edge => {
        HEsFromEdges.push(edge.getHalfEdge(), edge.getHalfEdge().getFlipHalfEdge());
      });
      
      const necessaryHEs = _.difference(HEs, HEsFromEdges);
      brep.halfEdges = necessaryHEs;
      
      const indicesWithoutPositions = [];
      
      const positions = brep.getPositions();
      const vertices = brep.getVertices();
      
      positions.forEach((position, i) => {
        if (_.isEmpty(position)) indicesWithoutPositions.push(i);
      });
      
      const newPositions = positions.filter((position, i) => {
         return indicesWithoutPositions.indexOf(i) === -1;
      });
      
      const newVertices = vertices.filter((v, i) => {
         return indicesWithoutPositions.indexOf(i) === -1;
      });
      
      newVertices.forEach((v, i) => v.setIndex(i));
      
      brep.positions = newPositions;
      brep.vertices = newVertices;
      
      brep.rebuildEdgeMap();
      
    }
    
    reCenterBrepPositions(component);
    // _removeRedundantElements(component.brep);
    
    
  }
  
  const _createComponents = function (component, splitLeftBrep, splitRightBrep) {

    const _getPropertyChangeCommand = function (component1, component2){
      const allComponents = [];

      const isMass = component.type.toLowerCase() === "mass";
      const isRoof = component.type.toLowerCase() === "roof";

      if (isMass){
        if (shouldMassBeAColumn(component1)){
          allComponents.push(component1);
          allComponents.push(...component1.mesh.instances.map(i => i.getSnaptrudeDS()));
        }

        if (shouldMassBeAColumn(component2)){
          allComponents.push(component2);
          allComponents.push(...component2.mesh.instances.map(i => i.getSnaptrudeDS()));
        }

        const optionsForPropertyChange = {
          meshKeys: ["room_type"],
          componentKeys: ["massType"]
        };

        const allMeshes = allComponents.map(c => c.mesh);

        let propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(allMeshes, optionsForPropertyChange);
        optionsForPropertyChange.data = propertyChangeCommandData;

        allComponents.forEach(assignColumnProperties);

        propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(allMeshes, optionsForPropertyChange);

        return commandUtils.propertyChangeOperations.getCommand("Split Operation Property Change", propertyChangeCommandData);
      }
      else if (isRoof){
        allComponents.push(component1, component2);
        const commandData = handleRoofPropertyChangeCommand(allComponents);
        return handleRoofPropertyChangeCommand(allComponents, commandData);
      }

    }

    const _labelSystemGeneratedElements = function (component){
      const faceVertices = getTopFaceVertices(component);
      const collinearVertices = getCollinearVertices(faceVertices);

      _splitPoints.forEach((point, i) => {
        if (collinearVertices.inArray(cv => cv.almostEquals(point))){
          const vertex = getVertexObjectFromPositionV3(component.mesh, point);

          const lowerPoint = _splitPointsLower[i];
          const lowerVertex = getVertexObjectFromPositionV3(component.mesh, lowerPoint);

          labelElementAsSystemGenerated(vertex);
          labelElementAsSystemGenerated(lowerVertex);

          const edgeObject = getEdgeObjectFromVertexObjects(component, vertex, lowerVertex);

          labelElementAsSystemGenerated(edgeObject);
        }

      })
    }

    /*
    Two options-
    1. Create 2 new components from the breps and delete the old component
    2. Update the split component's brep with the splitLeftBrep and create a new component with the second
    
    When a unique mass is being split, 1 makes more sense because uniform approach and what not
    But when an instance is being split, and let's say it has ten siblings, then 1 will generate 20 commands but 2 will generate 11
    
    So, going with option 2
     */
    
    const optionsForWMChange = {
      params: [
        commandUtils.worldMatrixChangeOperations.PARAMS.position
      ]
    };

    let allCommands;
    
    if (component.mesh.isAnInstance){
      
      const sourceMesh = component.mesh.sourceMesh;
      const sourceComponent = sourceMesh.getSnaptrudeDS();
      
      const newSourceMesh = _createCopy(component.mesh, true);
      const newSourceComponent = newSourceMesh.getSnaptrudeDS();
      newSourceComponent.brep = splitRightBrep;
      
      const newInstanceComponents = [];

      let splitInstanceComponent = component;
      let newInstanceCorrespondingToSplitInstanceComponent;

      sourceMesh.instances.forEach(i => {
        const newInstanceMesh = _createCopy(newSourceMesh, false);
        const newInstanceComponent = newInstanceMesh.getSnaptrudeDS();
        newInstanceMesh.copyWorldMatrixProperties(i);
        newInstanceComponents.push(newInstanceComponent);

        if (i === splitInstanceComponent.mesh) newInstanceCorrespondingToSplitInstanceComponent = newInstanceComponent;
      });
      
      StoreyMutation.assignStorey(newInstanceComponents);
      
      StoreyMutation.removeObjectFromStorey(newSourceComponent);
      const positionToBe = BABYLON.Vector3.One().scale(
          GLOBAL_CONSTANTS.numbers.positions.throwAwayMesh
      );
      positionToBe.y = 0;
      newSourceMesh.setAbsolutePosition(positionToBe);
      markMeshAsThrowAway(newSourceMesh);
      
      _cleanBrep(newSourceComponent);
      newSourceMesh.BrepToMesh();
      _labelSystemGeneratedElements(newInstanceCorrespondingToSplitInstanceComponent)
      
      const newlyCreatedMeshes = [newSourceMesh, ...newInstanceComponents.map(c => c.mesh)];
      const splitRightCommandData = commandUtils.creationOperations.getCommandData(newlyCreatedMeshes);
      const splitRightCommand = commandUtils.creationOperations.getCommand("splitRight", splitRightCommandData);
      
      splitRightCommand.rearrangeComponents = true;
      // this flag changes the order of deletion such that instances are deleted first and sourceMesh later
      
      const allSplitLeftMeshes = [sourceMesh, ...sourceMesh.instances];
      let splitLeftPositionChangeCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        allSplitLeftMeshes, optionsForWMChange);
      
      optionsForWMChange.data = splitLeftPositionChangeCommandData;
      
      const splitLeftCommand = commandUtils.geometryChangeOperations.expressCheckout("splitLeft", sourceMesh, () => {
        sourceComponent.brep = splitLeftBrep;
        _cleanBrep(sourceComponent);

        sourceMesh.instances.forEach(i => {
          i.getSnaptrudeDS().brep = splitLeftBrep;
        });

        sourceMesh.BrepToMesh();
        _labelSystemGeneratedElements(splitInstanceComponent);
      });
      
      splitLeftPositionChangeCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        allSplitLeftMeshes, optionsForWMChange);
      
      const splitLeftPositionChangeCommand = commandUtils.worldMatrixChangeOperations.getCommand(
        "splitMassCleanBrep", splitLeftPositionChangeCommandData);

      
      const graphUpdateCommand1 = virtualSketcher.updateWithoutGeometryEdit(component, true);
      const graphUpdateCommand2 = virtualSketcher.addWithGeometryEdit(newInstanceComponents);
      
      const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
        [splitLeftCommand, graphUpdateCommand1, graphUpdateCommand2]);


      allCommands = [splitRightCommand, commonGeometryChangeCommand, splitLeftPositionChangeCommand];

      const propertyChangeCommand = _getPropertyChangeCommand(sourceComponent, newSourceComponent);
      if (propertyChangeCommand) allCommands.push(propertyChangeCommand);
      
    }
    else {
      
      const newMesh = _createCopy(component.mesh, true);
      const newComponent = newMesh.getSnaptrudeDS();
      newComponent.brep = splitRightBrep;
      
      // StoreyMutation.assignStorey([newComponent, component]);
      
      _cleanBrep(newComponent);
      newComponent.mesh.BrepToMesh();
      _labelSystemGeneratedElements(newComponent);
      
      const splitRightCommandData = commandUtils.creationOperations.getCommandData(newComponent.mesh);
      const splitRightCommand = commandUtils.creationOperations.getCommand("splitRight", splitRightCommandData);
      
      let splitLeftPositionChangeCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(component.mesh, optionsForWMChange);
      optionsForWMChange.data = splitLeftPositionChangeCommandData;
      
      const splitLeftCommand = commandUtils.geometryChangeOperations.expressCheckout("splitLeft", component.mesh, () => {
        component.brep = splitLeftBrep;
        component.mesh.BrepToMesh();
        _labelSystemGeneratedElements(component);
        _cleanBrep(component);
      });

      splitLeftPositionChangeCommandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        component.mesh, optionsForWMChange);
      
      const splitLeftPositionChangeCommand = commandUtils.worldMatrixChangeOperations.getCommand(
        "splitMassCleanBrep", splitLeftPositionChangeCommandData);
      
      const graphUpdateCommand1 = virtualSketcher.updateWithoutGeometryEdit(component);
      const graphUpdateCommand2 = virtualSketcher.addWithGeometryEdit(newComponent);
      
      const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
        [splitLeftCommand, graphUpdateCommand1, graphUpdateCommand2]);
      
      allCommands = [splitRightCommand, commonGeometryChangeCommand, splitLeftPositionChangeCommand];

      const propertyChangeCommand = _getPropertyChangeCommand(component, newComponent);
      if (propertyChangeCommand) allCommands.push(propertyChangeCommand);
    }

    CommandManager.execute(
      allCommands,
      allCommands.map(c => false)
    );
  }
  
  const _getFacesAroundEdge = function (edge) {
    return [
      edge.getHalfEdge().getFace(),
      edge.getHalfEdge().getFlipHalfEdge().getFace()
    ];
  }
  
  const _getEdgesAroundFace = function (face) {
    const HEs = snapmda.FaceHalfEdges(face);
    return HEs.map(he => he.getEdge());
  }
  
  const _reassignIndices = function (brep) {
    
    const _reassign = function (array) {
      array.forEach((element, i) => {
        element.setIndex(i);
      });
    }
    
    _reassign(brep.getVertices());
    _reassign(brep.getEdges());
    _reassign(brep.getFaces());
    
  };
  
  /**
   * Think of this process as demolishing a building
   *
   * The critical edges are first identified, and then everything linked to them are isolated from the
   * main mass and are dropped like stones from the brep
   *
   * And then a new face is created along the split plane
   *
   * @param brep
   * @param addedEdgeObjects
   * @param addedVertexObjects
   * @param startingFaceToRemove
   * @private
   */
  const _deSkeletonBrep = function (brep, addedEdgeObjects, addedVertexObjects, startingFaceToRemove){
    
    /*
    Phase 1 - Removal of unnecessary edges and faces
     */
    
    const facesToRemove = [];
    
    const traversedEdges = [...addedEdgeObjects];
    
    const facesToTraverse = [startingFaceToRemove];
    
    while (!_.isEmpty(facesToTraverse)) {
      const face = _.last(facesToTraverse);
      
      facesToRemove.push(face);
      facesToTraverse.pop();
      
      const edges = _getEdgesAroundFace(face)
      
      edges.forEach(edge => {
        if (traversedEdges.inArray(edge)) return;
        const faces = _getFacesAroundEdge(edge);
        
        const facesNotAlreadyTraversed = faces.filter(face => !facesToRemove.inArray(face));
        facesNotAlreadyTraversed.forEach(face => facesToTraverse.pushIfNotExist(face));
      });
    }
    
    const allEdges = [];
    facesToRemove.forEach(face => {
      allEdges.push(..._getEdgesAroundFace(face));
    });
    
    const edgesToRemove = _.uniq(_.difference(allEdges, addedEdgeObjects));
    
    const allVertices = [];
    const halfEdgesToRemove = [];
    
    edgesToRemove.forEach(edge => {
      allVertices.push(...snapmda.EdgeVertices(edge));
      halfEdgesToRemove.push(edge.getHalfEdge(), edge.getHalfEdge().getFlipHalfEdge());
    });
    
    const verticesToRemove = _.uniq(_.difference(allVertices, addedVertexObjects));
    
    addedEdgeObjects.forEach(edge => {
      const he = edge.getHalfEdge();
      const flipHe = he.getFlipHalfEdge();
      
      const shouldInvert = facesToRemove.inArray(he.getFace());
      
      if (shouldInvert){
        edge.setHalfEdge(flipHe);
      }
      
    });
    
  
    const brepVertices = brep.getVertices();
    const brepEdges = brep.getEdges();
    const brepFaces = brep.getFaces();
    const brepHEs = brep.getHalfEdges();
    
    _.pullAll(brepFaces, facesToRemove);
    _.pullAll(brepEdges, edgesToRemove);
    _.pullAll(brepHEs, halfEdgesToRemove);
    
    verticesToRemove.forEach(vertex => {
      const i = _.findIndex(brepVertices, vertex);
      
      brepVertices.splice(i, 1);
      brep.positions.splice(i, 1); // getPositions() returns a copy
    });
    
    _reassignIndices(brep);
    brep.rebuildEdgeMap();
    
    
    /*
    Phase 2 - Addition of new edges and faces
     */
    
    const edgeMap = brep.getEdgeMap();
    
    const firstEdge = addedEdgeObjects[0];
    const firstVertex = addedVertexObjects[0];
    
    let needsReversal = false;
    if (firstEdge.getHalfEdge().getFlipHalfEdge().getVertex() === firstVertex){
      // the flip half-edge is in the direction of the vertex traversal
    }
    else {
      // the flip half-edge is in the opposite direction of the vertex traversal
      // so have to change the traversal direction
      needsReversal = true;
    }
    
    const nVertices = addedVertexObjects.length;
    const nEdges = addedEdgeObjects.length;
    
    // since these form a closed shape, nVertices = nEdges
    
    const [upperVertexObjects, lowerVertexObjects] = _.chunk(addedVertexObjects, nVertices / 2);
    
    if (needsReversal) {
      upperVertexObjects.reverse();
      lowerVertexObjects.reverse();
      
      const [upperEdgeObjects, lowerEdgeObjects] = _.chunk(addedEdgeObjects, nEdges / 2);
      
      upperEdgeObjects.reverse();
      lowerEdgeObjects.reverse();
      
      addedEdgeObjects = [...upperEdgeObjects, ...lowerEdgeObjects];
      // rotate left
      const firstElement = addedEdgeObjects.shift();
      addedEdgeObjects.push(firstElement);
    }
    
    const lowerVertexObjectsReversed = [...lowerVertexObjects].reverse();
    
    const nSplitPoints = upperVertexObjects.length;
    const numberOfNewFaces = nSplitPoints - 1;
    
    const newlyAddedVerticalEdges = [];
    
    upperVertexObjects.forEach((upperVO, i) => {
      
      const lowerVO = lowerVertexObjectsReversed[i];
      const upperEdgeObject = addedEdgeObjects[i];
      
      if (i === numberOfNewFaces) {
        // last upper vertex
        
        // in this case, upperEdgeObject will be the vertical edge
        const verticalEdge = upperEdgeObject;
        const heAlongNewFace = verticalEdge.getHalfEdge().getFlipHalfEdge();
        upperVO.setHalfEdge(heAlongNewFace);
        lowerVO.setHalfEdge(heAlongNewFace.getNextHalfEdge());
        
        return;
      }

      const nextUpperVO = getCircularlyNextElementInArray(upperVertexObjects, upperVO);
      const nextLowerVO = getCircularlyNextElementInArray(lowerVertexObjectsReversed, lowerVO);
      
      const isLastFace = i === numberOfNewFaces - 1;
      
      const previousEdgeObject = _.last(newlyAddedVerticalEdges) || getCircularlyPreviousElementInArray(addedEdgeObjects, upperEdgeObject);
      
      let nextEdgeObject;
      if (isLastFace){
        nextEdgeObject = brep.getEdge(nextUpperVO.getIndex(), nextLowerVO.getIndex());
      }
      else {
        
        nextEdgeObject = new snapmda.Edge();
        
        const he1 = new snapmda.HalfEdge();
        const he2 = new snapmda.HalfEdge();
        
        he1.setEdge(nextEdgeObject);
        he2.setEdge(nextEdgeObject);
        
        he1.setFlipHalfEdge(he2);
        he2.setFlipHalfEdge(he1);
        
        he1.setVertex(nextUpperVO);
        he2.setVertex(nextLowerVO);
        
        // nextHE and face will be set below or in the next iteration
        
        nextEdgeObject.setHalfEdge(he1);
        nextEdgeObject.setIndex(brepEdges.length);
        
        brepEdges.push(nextEdgeObject);
        brepHEs.push(he1);
        brepHEs.push(he2);
        
        const edgeKeys = brep.getEdgeKeys(nextUpperVO.getIndex(), nextLowerVO.getIndex());
        edgeMap[ edgeKeys[ 0 ] ] = nextEdgeObject;
        edgeMap[ edgeKeys[ 1 ] ] = nextEdgeObject;
        
        newlyAddedVerticalEdges.push(nextEdgeObject);
      }
      
      const lowerEdgeIndex = nVertices - ( i + 2);
      const lowerEdgeObject = addedEdgeObjects[lowerEdgeIndex];
      
      const upperEdgeHE = upperEdgeObject.getHalfEdge().getFlipHalfEdge();
      const lowerEdgeHE = lowerEdgeObject.getHalfEdge().getFlipHalfEdge();
      
      const previousEdgeHE = previousEdgeObject.getHalfEdge().getFlipHalfEdge();
      const nextEdgeHE = isLastFace ? nextEdgeObject.getHalfEdge().getFlipHalfEdge() : nextEdgeObject.getHalfEdge();
      
      upperEdgeHE.setNextHalfEdge(nextEdgeHE);
      nextEdgeHE.setNextHalfEdge(lowerEdgeHE);
      lowerEdgeHE.setNextHalfEdge(previousEdgeHE);
      previousEdgeHE.setNextHalfEdge(upperEdgeHE);
      
      upperVO.setHalfEdge(upperEdgeHE);
      lowerVO.setHalfEdge(previousEdgeHE);
      
      const newFace = new snapmda.Face();
      newFace.setIndex(brep.getFaces().length);
      newFace.setHalfEdge(upperEdgeHE);
      newFace.materialIndex = startingFaceToRemove.materialIndex;
      
      brep.getFaces().push(newFace);
      
      upperEdgeHE.setFace(newFace);
      nextEdgeHE.setFace(newFace);
      lowerEdgeHE.setFace(newFace);
      previousEdgeHE.setFace(newFace);
      
    });
    
  }
  
  return {
    splitUsingTopEdge,
  };
  
})();

export default splitMass;
