import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  isPointerOverGUIElement,
  isPointInsideTheMesh,
  convertGlobalVector3ToLocal,
  onSolid,
  checkOperationBIMFlowConditions,
} from "../modules/extrafunc.js";
import { StateMachine } from "../modules/Classes/StateMachine.js";
import {
  updateModifications,
} from "./sceneStateFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { isFloatEqual, projectionOfPointOnLine } from "./snapFuncs.js";
import { updateHeightAfterEdits } from "./sceneFuncs.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { commandUtils } from "../modules/commandManager/CommandUtils.js";
import { virtualSketcher } from "../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { isPickedfaceObject } from "./defaultEvents.js";
import {
  createBackWall,
  createReferenceGround,
  faceIndicatorName,
  disposeSnapToObjects,
  disposeHighlightedFace,
  showFaceIndicator,
  disposeAllAxisLines,
} from "../modules/meshoperations/moveOperations/moveUtil.js";
import {
  getFaceIdFromFacet,
  getFaceVerticesFromFacetID,
} from "./brepOperations.js";
import { getNormalVector } from "./mathFuncs.js";
import {
  findSecondarySnappedPoint,
  findDimensionSnappedPoint,
} from "./snapFuncsPrimary.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";

const snapmda = window.snapmda;

let faceID = null;
let startingFacePosition = null;
let faceVertices = null;
let normalToFace = null;
let faceTrajectory = null;
let extrusionFaceSelected = false;
let meshForExtrude = null;
let extrudeCommandData = null;
let extrudeMeshEditCheckValue = null;
var startingPointFace = null;

var onPointerDownExtrude = function (evt, force = false) {
  if (store.isiPad && isPointerOverGUIElement() && !force) return;
  if (StateMachine.EventValidation.isPointerDownWithMiddleButton(evt)) return;

  //scene.getCameraByName("ArcRotateCamera3").setTarget(BABYLON.Vector3.Zero());
  var pickInfo = scenePickController.pick(function (mesh) {
    return mesh.type !== "staircase";
  });
  DisplayOperation.removeDimensions();
  if (extrusionFaceSelected) {
    // store.newScene.activeCamera.attachControl(canvas, true, false);
    // meshForExtrude.visibility = 1.0;
    if (startingPointFace) {
      const componentForExtrude = meshForExtrude.getSnaptrudeDS();
      let brep = componentForExtrude.brep;
      let currentFacePosition = store.scene
        .getMeshByName("faceBox")
        .getAbsolutePosition();

      let difference = currentFacePosition.subtract(startingFacePosition);

      if (!isFloatEqual(difference.length(), 0, 0.01)) {
        let pointToCheck = startingFacePosition.add(difference.normalize());

        //inward extrude not handled, thus returning as of now
        if (isPointInsideTheMesh(pointToCheck, meshForExtrude)) return;

        const currentFacePositionLocal = convertGlobalVector3ToLocal(
          currentFacePosition,
          meshForExtrude
        );
        const startingFacePositionLocal = convertGlobalVector3ToLocal(
          startingFacePosition,
          meshForExtrude
        );

        let extrudeAmount = currentFacePositionLocal
          .subtract(startingFacePositionLocal)
          .length();
        extrudeAmount *= -1;
        // This inversion of sign is because, when normal is calculated inside ExtrudeOperator,
        //since faceVertices are CCW, normal goes inside the mass

        let faceShrink = 0;
        if (evt) {
          if (evt.metaKey || evt.ctrlKey) faceShrink = 2;
        }

        let materialIndices = [];
        meshForExtrude.subMeshes.forEach(function (subMesh) {
          materialIndices.push(subMesh.materialIndex);
        });

        materialIndices = _.uniq(materialIndices);
        let faceData = snapmda.ExtrudeOperator(
          brep,
          faceID,
          extrudeAmount,
          faceShrink
        );

        if (materialIndices.length === 1) {
          faceData.newFaces.forEach(function (face) {
            face.materialIndex = faceData.originalFace.materialIndex;
          });
        }
        meshForExtrude.BrepToMesh();
        meshForExtrude = updateHeightAfterEdits(meshForExtrude);
        onSolid(meshForExtrude);
        updateModifications();

        // storeyAssignment();
        StoreyMutation.assignStorey(componentForExtrude);

        let s = StructureCollection.getInstance();
        let structure = s.getStructureById(meshForExtrude.structure_id);
        let mass = structure.getObjectByUniqueId(meshForExtrude.uniqueId);

        mass.notifyAll("resetlevel");

        meshForExtrude.computeWorldMatrix(true);
        let newMeshEditCheckValue =
          meshForExtrude.getBoundingInfo().boundingBox.extendSizeWorld.y;
        if (
          _.round(extrudeMeshEditCheckValue, 4) !==
          _.round(newMeshEditCheckValue, 4)
        )
          componentForExtrude.markAsEdited();

        extrudeCommandData =
          commandUtils.geometryChangeOperations.getCommandData(
            meshForExtrude,
            extrudeCommandData
          );

        const extrudeCommand = commandUtils.geometryChangeOperations.getCommand(commandUtils.CONSTANTS.extrudeOperation, extrudeCommandData);
        const integrationGeometryChangeCommand =
          virtualSketcher.updateWithGeometryEdit(componentForExtrude);

        const commonGeometryChangeCommand = commandUtils.geometryChangeOperations.flattenCommands(
          [extrudeCommand, integrationGeometryChangeCommand]
        );

        CommandManager.execute(commonGeometryChangeCommand, false);
      }

      disposeExtrudeData();
    }
  } else if (pickInfo.hit) {
    if (isPickedfaceObject(pickInfo)) {
      let options = {
        operation: "extrude",
        mesh: pickInfo.pickedMesh,
      };
      if (!checkOperationBIMFlowConditions(options)) return;

      // store.newScene.activeCamera.detachControl(canvas);
      createBackWall(pickInfo.pickedPoint);
      createReferenceGround(pickInfo.pickedPoint);

      faceID = getFaceIdFromFacet(pickInfo.faceId, pickInfo.pickedMesh);
      // faceVertices = getFaceVerticesFromFacetID(pickInfo.faceId, pickInfo.pickedMesh, BABYLON.Space.WORLD);
      let normalVector = getNormalVector(faceVertices, false);
      // let index_buf = pickInfo.pickedMesh.selectFace(pickInfo);
      normalToFace = BABYLON.Vector3.FromArray(normalVector);
      // normalToFace = pickInfo.pickedMesh.getFacetNormal(pickInfo.faceId);
      meshForExtrude = pickInfo.pickedMesh;
      // pickInfo.pickedMesh.visibility = 0.5;

      store.startingPointFace = new BABYLON.Vector3(
        pickInfo.pickedPoint.x,
        pickInfo.pickedPoint.y,
        pickInfo.pickedPoint.z
      );

      let faceBox = store.scene.getMeshByName(faceIndicatorName);
      startingFacePosition = faceBox.getAbsolutePosition().clone();
      startingPointFace = startingFacePosition.clone();
      faceTrajectory = startingFacePosition.add(normalToFace);
      extrusionFaceSelected = true;

      extrudeMeshEditCheckValue =
        meshForExtrude.getBoundingInfo().boundingBox.extendSizeWorld.y;
      extrudeCommandData =
        commandUtils.geometryChangeOperations.getCommandData(meshForExtrude);
    }
  }
};

var onPointerUpExtrude = function (evt) {};

var onPointerMoveExtrude = function (evt) {
  if (StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(evt))
    return;
  if (extrusionFaceSelected) {
    disposeSnapToObjects();
    let snapPoint = findSecondarySnappedPoint(
      meshForExtrude,
      startingFacePosition,
      null,
      {
        faceSnap: false,
      }
    );

    if (!snapPoint) {
      snapPoint = getWeightedPointin3DSpace(startingFacePosition);
      if (!snapPoint) return;

      let projection = projectionOfPointOnLine(
        snapPoint,
        startingFacePosition,
        faceTrajectory
      );
      snapPoint = findDimensionSnappedPoint(startingFacePosition, projection);
    } else {
      snapPoint = projectionOfPointOnLine(
        snapPoint,
        startingFacePosition,
        faceTrajectory
      );
    }

    store.startingPointFace = snapPoint;
    moveFaceIndicator(snapPoint);
    // showAxisLine(startingFacePosition, snapPoint);
  } else {
    disposeHighlightedFace(faceIndicatorName);

    let pickInfo = scenePickController.pick(mesh => {
      return mesh.type !== "staircase";
    });

    if (pickInfo.hit) {
      let options = {
        operation: "extrude",
        mesh: pickInfo.pickedMesh,
      };
      if (!checkOperationBIMFlowConditions(options)) return;

      faceVertices = getFaceVerticesFromFacetID(
        pickInfo.faceId,
        pickInfo.pickedMesh,
        BABYLON.Space.WORLD
      );
      showFaceIndicator(
        faceVertices,
        pickInfo.pickedMesh,
        faceIndicatorName,
        colorUtil.getMaterial(colorUtil.type.preSnap)
      );
    }
  }
};

function disposeExtrudeData() {
  store.startingPointFace = null;
  store.startingPoint = null;
  extrusionFaceSelected = false;
  faceID = null;
  meshForExtrude = null;
  startingFacePosition = null;
  faceVertices = null;
  normalToFace = null;
  faceTrajectory = null;
  disposeHighlightedFace();
  disposeSnapToObjects();
  disposeAllAxisLines();
}

function enableOnMoveDirectly(point) {
  store.startingPointFace = point;
  createBackWall(startingPointFace);
  createReferenceGround(startingPointFace);
}

var getWeightedPointin3DSpace = function (startingPoint) {
  var pickInfoGround = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("referenceGround");
    }
  );
  var pickInfoBackwall = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("backwall");
    }
  );
  // if (pickInfoGround.hit && pickInfoBackwall.hit && !BIMProject) {
  if (pickInfoGround.hit && pickInfoBackwall.hit) {
    // var pt = new BABYLON.Vector3(pickInfoGround.pickedPoint.x, pickInfoBackwall.pickedPoint.y, pickInfoGround.pickedPoint.z);

    let distance1 = BABYLON.Vector3.Distance(
      startingPoint,
      pickInfoGround.pickedPoint
    );
    let distance2 = BABYLON.Vector3.Distance(
      startingPoint,
      pickInfoBackwall.pickedPoint
    );

    let pt =
      distance1 > distance2
        ? pickInfoBackwall.pickedPoint
        : pickInfoGround.pickedPoint;

    return pt;
  } else if (pickInfoGround.hit) {
    return pickInfoGround.pickedPoint;
  }
  // else if (pickInfoBackwall.hit && !BIMProject) {
  else if (pickInfoBackwall.hit) {
    return pickInfoBackwall.pickedPoint;
  }
  return null;
};

function moveFaceIndicator(pos) {
  let faceBox = store.scene.getMeshByName("faceBox");
  faceBox.setAbsolutePosition(pos);

  // faceBox.computeWorldMatrix(true);
  // let endPoint = faceBox.getBoundingInfo().boundingBox.centerWorld;
  let endPoint = pos;

  DisplayOperation.drawOnMove(startingFacePosition, endPoint);
  let distance = BABYLON.Vector3.Distance(startingFacePosition, endPoint);
  DisplayOperation.displayOnMove(distance, null, true, {
    onChangeCallback: updateExtrude,
  });
}

function updateExtrude(distance) {
  if (extrusionFaceSelected) {
    let input = DisplayOperation.getOriginalDimension(distance);

    let currentFacePosition = store.scene
      .getMeshByName("faceBox")
      .getAbsolutePosition();
    let finalPosition = startingFacePosition.add(
      currentFacePosition
        .subtract(startingFacePosition)
        .normalize()
        .scale(input)
    );
    moveFaceIndicator(finalPosition);

    onPointerDownExtrude({}, true);

    return true;
  }
}
export {
  faceID,
  startingFacePosition,
  faceVertices,
  normalToFace,
  faceTrajectory,
  extrusionFaceSelected,
  meshForExtrude,
  extrudeCommandData,
  extrudeMeshEditCheckValue,
  startingPointFace,
  onPointerDownExtrude,
  onPointerUpExtrude,
  onPointerMoveExtrude,
  disposeExtrudeData,
  enableOnMoveDirectly,
  getWeightedPointin3DSpace,
  moveFaceIndicator,
  updateExtrude,
};
