import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { findArrayElemInArray } from "./arrayFuncs.js";
import { createMaterialFromImage, applyMaterialToFace } from "./mats.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { st } from "./scaleMeshEvents.js";
import { updateBrepPositions } from "./brepOperations.js";

const snapmda = window.snapmda;

function selectFace(pickInfo) {
  store.currentMesh = pickInfo.pickedMesh;
  var indices = store.currentMesh.getIndices();
  var verData = store.currentMesh.getVerticesData(
    BABYLON.VertexBuffer.PositionKind
  );

  var index0 = indices[pickInfo.faceId * 3];
  var index1 = indices[pickInfo.faceId * 3 + 1];
  var index2 = indices[pickInfo.faceId * 3 + 2];

  var index_buf = [];
  index_buf.push(index0);
  index_buf.push(index1);
  index_buf.push(index2);

  var Ax = verData[index_buf[0] * 3];
  var Ay = verData[index_buf[0] * 3 + 1];
  var Az = verData[index_buf[0] * 3 + 2];

  var Bx = verData[index_buf[1] * 3];
  var By = verData[index_buf[1] * 3 + 1];
  var Bz = verData[index_buf[1] * 3 + 2];

  var Cx = verData[index_buf[2] * 3];
  var Cy = verData[index_buf[2] * 3 + 1];
  var Cz = verData[index_buf[2] * 3 + 2];

  // (Ax, Ay, Az, Bx, By, Bz, Cx, Cy, Cz);
  var a = (By - Ay) * (Cz - Az) - (Cy - Ay) * (Bz - Az);
  var b = (Bz - Az) * (Cx - Ax) - (Cz - Az) * (Bx - Ax);
  var c = (Bx - Ax) * (Cy - Ay) - (Cx - Ax) * (By - Ay);
  var d = -(a * Ax + b * Ay + c * Az);

  store.normalVector = [a, b, c];

  if (store.face_ribbon) {
    store.face_ribbon.dispose();
  }
  var mat2 = new BABYLON.StandardMaterial("mat2", store.scene);
  mat2.alpha = 1.0;
  mat2.diffuseColor = new BABYLON.Color3(0.0, 0.0, 0.0);
  mat2.backFaceCulling = false;

  store.face_coords = [];
  store.face_coords_index = [];

  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  //console.log($scope.selectedMaterial);
  if ($scope.selectedMaterial !== "nan") {
    if (store.currentMesh.name.toLowerCase() === "roof") {
      store.face_coords = [];
      store.face_coords_index = [];
      for (let i = 0; i < verData.length; i = i + 3) {
        // (Math.abs(a*verData[i]+b*verData[i+1]+c*verData[i+2]+d));
        if (
          Math.abs(
            a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d
          ) <= 0.001
        ) {
          let vert = new BABYLON.Vector3(
            verData[i],
            verData[i + 1],
            verData[i + 2]
          );
          store.face_coords.push(vert);
          store.face_coords_index.push([i, i + 1, i + 2]);
        }
      }
    } else if (
      store.currentMesh.name.toLowerCase() === "wall" ||
      store.currentMesh.type.toLowerCase() === "wall"
    ) {
      // Working perfectly for getting 4 coords
      for (let i = 0; i < index_buf.length; i++) {
        if (
          Math.abs(
            a * verData[index_buf[i] * 3] +
              b * verData[index_buf[i] * 3 + 1] +
              c * verData[index_buf[i] * 3 + 2] +
              d
          ) <= 0.001
        ) {
          let vert = new BABYLON.Vector3(
            verData[index_buf[i] * 3],
            verData[index_buf[i] * 3 + 1],
            verData[index_buf[i] * 3 + 2]
          );
          store.face_coords.push(vert);
          store.face_coords_index.push([
            index_buf[i] * 3,
            index_buf[i] * 3 + 1,
            index_buf[i] * 3 + 2,
          ]);
        }
      }
      // var temp_coord = getLastVertex(vert, store.face_coords);
      // store.face_coords.push(temp_coord);
      for (let i = 1; i < indices.length / 2; i++) {
        if (index_buf[0] + i < indices.length - 1) {
          var vert1 = new BABYLON.Vector3(
            verData[indices[i + index_buf[0]] * 3],
            verData[indices[i + index_buf[0]] * 3 + 1],
            verData[indices[i + index_buf[0]] * 3 + 2]
          );
          if (
            Math.abs(
              a * verData[indices[i + index_buf[0]] * 3] +
                b * verData[indices[i + index_buf[0]] * 3 + 1] +
                c * verData[indices[i + index_buf[0]] * 3 + 2] +
                d
            ) <= 0.001
          ) {
            var ind = findArrayElemInArray(
              [
                indices[i + index_buf[0]] * 3,
                indices[i + index_buf[0]] * 3 + 1,
                indices[i + index_buf[0]] * 3 + 2,
              ],
              store.face_coords_index
            );
            if (ind !== -1) continue;
            store.face_coords_index.push([
              indices[i + index_buf[0]] * 3,
              indices[i + index_buf[0]] * 3 + 1,
              indices[i + index_buf[0]] * 3 + 2,
            ]);
            store.face_coords.push(vert1);
            break;
          }
        }
        if (index_buf[0] - i > 0) {
          var vert2 = new BABYLON.Vector3(
            verData[indices[index_buf[0] - i] * 3],
            verData[indices[index_buf[0] - i] * 3 + 1],
            verData[indices[index_buf[0] - i] * 3 + 2]
          );
          if (
            Math.abs(
              a * verData[indices[index_buf[0] - i] * 3] +
                b * verData[indices[index_buf[0] - i] * 3 + 1] +
                c * verData[indices[index_buf[0] - i] * 3 + 2] +
                d
            ) <= 0.001
          ) {
            /* eslint-disable */
            var ind = $.inArray(
              [
                indices[index_buf[0] - i] * 3,
                indices[index_buf[0] - i] * 3 + 1,
                indices[index_buf[0] - i] * 3 + 2,
              ],
              store.face_coords_index
            );
            /* eslint-enable */
            if (ind !== -1) continue;
            store.face_coords_index.push([
              indices[index_buf[0] - i] * 3,
              indices[index_buf[0] - i] * 3 + 1,
              indices[index_buf[0] - i] * 3 + 2,
            ]);
            store.face_coords.push(vert2);
            break;
          }
        }
      }
      // var face_coords_temp = [];
      // var face_coords_index_temp = [];
      // for ( let i = 0; i < verData.length; i = i + 3) {
      //     // (Math.abs(a*verData[i]+b*verData[i+1]+c*verData[i+2]+d));
      //     if (Math.abs(a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d) <= 0.001) {
      //         var vert = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
      //         store.face_coords_temp.push(vert);
      //         store.face_coords_index_temp.push([i, i + 1, i + 2]);
      //     }
      // }
      // console.log(face_coords_temp, store.face_coords_index_temp);
      //Working perfectly for getting 4 coords
      // findFaceIndices(currentMesh, pickInfo, indices, verData, [a,b,c,d], store.face_coords, store.face_coords_index);
    }

    var temp_mat = store.scene.getMaterialByName($scope.selectedMaterial);
    if (!temp_mat) {
      temp_mat = createMaterialFromImage(
        $scope.selectedMaterial,
        $scope.selectedMaterialType
      );
    }
    if (store.currentMesh.name.indexOf("room") === -1) {
      console.log(store.face_coords_index, store.face_coords);
      if (store.currentMesh.name.toLowerCase() === "wall")
        applyMaterialToFace(
          store.currentMesh,
          store.face_coords,
          store.face_coords_index,
          temp_mat
        );
      else if (store.currentMesh.type.toLowerCase() === "wall")
        applyMaterialToFace(
          store.currentMesh,
          store.face_coords,
          store.face_coords_index,
          temp_mat
        );
      updateModifications();
    }
  } else {
    //Working perfectly
    var diff = new BABYLON.Vector3(0.01, 0.01, 0.01);
    if (Math.abs(store.normalVector[0]) > 0.001)
      diff.x = Math.sign(store.normalVector[0]) * diff.x;
    else diff.x = 0;
    if (Math.abs(store.normalVector[1]) > 0.001)
      diff.y = Math.sign(store.normalVector[1]) * diff.y;
    else diff.y = 0;
    if (Math.abs(store.normalVector[2]) > 0.001)
      diff.z = Math.sign(store.normalVector[2]) * diff.z;
    else diff.z = 0;
    for (let i = 0; i < verData.length; i = i + 3) {
      // (Math.abs(a*verData[i]+b*verData[i+1]+c*verData[i+2]+d));
      if (
        Math.abs(
          a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d
        ) <= 0.001
      ) {
        let vert = new BABYLON.Vector3(
          verData[i] + diff.x,
          verData[i + 1] + diff.y,
          verData[i + 2] + diff.z
        );
        store.face_coords.push(vert);
        store.face_coords_index.push([i, i + 1, i + 2]);
      }
    }
    //Working perfectly
    store.face_ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [store.face_coords],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    store.face_ribbon.material = mat2;
    store.face_ribbon.parent = pickInfo.pickedMesh;
  }

  store.first_center_coord = new BABYLON.Vector3(0, 0, 0);
  for (let i = 0; i < store.face_coords.length; i++) {
    store.first_center_coord.addInPlace(store.face_coords[i]);
  }
  store.first_center_coord.x = store.currentMesh.position.x;
  store.first_center_coord.y =
    store.currentMesh.position.y + store.distanceLineOffset;
  store.first_center_coord.z = store.currentMesh.position.z;
  // updatePropBlockFace(face_ribbon);
}

function getTriangle(pickInfo) {
  store.currentMesh = pickInfo.pickedMesh;
  var indices = store.currentMesh.getIndices();
  var verData = store.currentMesh.getVerticesData(
    BABYLON.VertexBuffer.PositionKind
  );

  var index0 = indices[pickInfo.faceId * 3];
  var index1 = indices[pickInfo.faceId * 3 + 1];
  var index2 = indices[pickInfo.faceId * 3 + 2];

  var index_buf = [];
  index_buf.push(index0);
  index_buf.push(index1);
  index_buf.push(index2);

  var Ax = verData[index_buf[0] * 3];
  var Ay = verData[index_buf[0] * 3 + 1];
  var Az = verData[index_buf[0] * 3 + 2];
  var pt1 = new BABYLON.Vector3(Ax, Ay, Az);

  var Bx = verData[index_buf[1] * 3];
  var By = verData[index_buf[1] * 3 + 1];
  var Bz = verData[index_buf[1] * 3 + 2];
  var pt2 = new BABYLON.Vector3(Bx, By, Bz);

  var Cx = verData[index_buf[2] * 3];
  var Cy = verData[index_buf[2] * 3 + 1];
  var Cz = verData[index_buf[2] * 3 + 2];
  var pt3 = new BABYLON.Vector3(Cx, Cy, Cz);

  return [pt1, pt2, pt3, index_buf[0], index_buf[1], index_buf[2]];
}

function getFace(pickInfo) {
  store.currentMesh = pickInfo.pickedMesh;
  if (!store.currentMesh) return;
  var indices = store.currentMesh.getIndices();
  var verData = store.currentMesh.getVerticesData(
    BABYLON.VertexBuffer.PositionKind
  );

  let baseIndex = pickInfo.faceId * 3;
  if (store.currentMesh.type === GLOBAL_CONSTANTS.strings.identifiers.cadSketch)
    baseIndex = pickInfo.faceId * 2;

  var index0 = indices[baseIndex];
  var index1 = indices[baseIndex + 1];
  var index2 = indices[baseIndex + 2];

  var index_buf = [];
  index_buf.push(index0);
  index_buf.push(index1);
  index_buf.push(index2);

  var Ax = verData[index_buf[0] * 3];
  var Ay = verData[index_buf[0] * 3 + 1];
  var Az = verData[index_buf[0] * 3 + 2];

  var Bx = verData[index_buf[1] * 3];
  var By = verData[index_buf[1] * 3 + 1];
  var Bz = verData[index_buf[1] * 3 + 2];

  var Cx = verData[index_buf[2] * 3];
  var Cy = verData[index_buf[2] * 3 + 1];
  var Cz = verData[index_buf[2] * 3 + 2];

  let av3 = new BABYLON.Vector3(Ax, Ay, Az);
  let bv3 = new BABYLON.Vector3(Bx, By, Bz);
  let cv3 = new BABYLON.Vector3(Cx, Cy, Cz);

  if (
    store.currentMesh.type === GLOBAL_CONSTANTS.strings.identifiers.cadSketch
  ) {

    store.normalVector = [0, 0, 0]; // infinite normals for two points

    store.face_coords = [av3, bv3];
    store.face_coords_index = [
      [index0 * 3, index0 * 3 + 1, index0 * 3 + 2],
      [index1 * 3, index1 * 3 + 1, index1 * 3 + 2],
    ];

    return [store.face_coords, store.normalVector, store.face_coords_index];
  }

  // (Ax, Ay, Az, Bx, By, Bz, Cx, Cy, Cz);
  var a = (By - Ay) * (Cz - Az) - (Cy - Ay) * (Bz - Az);
  var b = (Bz - Az) * (Cx - Ax) - (Cz - Az) * (Bx - Ax);
  var c = (Bx - Ax) * (Cy - Ay) - (Cx - Ax) * (By - Ay);
  var d = -(a * Ax + b * Ay + c * Az);

  store.normalVector = [a, b, c];

  store.face_coords = [av3, bv3, cv3];
  store.face_coords_index = [index_buf];

  // Below snippet gets all the coplanar vertices from other facets
  // After brep there's no particular use for this time taking operation
  // Just returning facet coordinates

  /*
  //Working perfectly
  for (let i = 0; i < verData.length; i = i + 3) {
    if (
      Math.abs(a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d) <=
      0.001
    ) {
      var vert = new BABYLON.Vector3(
        verData[i],
        verData[i + 1],
        verData[i + 2]
      );
      store.face_coords.push(vert);
      store.face_coords_index.push([i, i + 1, i + 2]);
    }
  }
  */

  //Working perfectly
  return [store.face_coords, store.normalVector, store.face_coords_index];
}

function getFaceDistinctVertices(pickInfo) {
  store.currentMesh = pickInfo.pickedMesh;
  if (!store.currentMesh) return;
  var indices = store.currentMesh.getIndices();
  var verData = store.currentMesh.getVerticesData(
    BABYLON.VertexBuffer.PositionKind
  );

  var index0 = indices[pickInfo.faceId * 3];
  var index1 = indices[pickInfo.faceId * 3 + 1];
  var index2 = indices[pickInfo.faceId * 3 + 2];

  var index_buf = [];
  index_buf.push(index0);
  index_buf.push(index1);
  index_buf.push(index2);

  var Ax = verData[index_buf[0] * 3];
  var Ay = verData[index_buf[0] * 3 + 1];
  var Az = verData[index_buf[0] * 3 + 2];

  var Bx = verData[index_buf[1] * 3];
  var By = verData[index_buf[1] * 3 + 1];
  var Bz = verData[index_buf[1] * 3 + 2];

  var Cx = verData[index_buf[2] * 3];
  var Cy = verData[index_buf[2] * 3 + 1];
  var Cz = verData[index_buf[2] * 3 + 2];

  // (Ax, Ay, Az, Bx, By, Bz, Cx, Cy, Cz);
  var a = (By - Ay) * (Cz - Az) - (Cy - Ay) * (Bz - Az);
  var b = (Bz - Az) * (Cx - Ax) - (Cz - Az) * (Bx - Ax);
  var c = (Bx - Ax) * (Cy - Ay) - (Cx - Ax) * (By - Ay);
  var d = -(a * Ax + b * Ay + c * Az);

  store.normalVector = [a, b, c];

  store.face_coords = [];
  store.face_coords_index = [];

  //Working perfectly
  for (let i = 0; i < verData.length; i = i + 3) {
    if (
      Math.abs(a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d) <=
      0.001
    ) {
      var vert = new BABYLON.Vector3(
        verData[i],
        verData[i + 1],
        verData[i + 2]
      );
      if (!duplicateVert(store.face_coords, vert)) {
        store.face_coords.push(vert);
        store.face_coords_index.push([i, i + 1, i + 2]);
      }
    }
  }
  //Working perfectly
  return [store.face_coords, store.normalVector, store.face_coords_index];
}

function duplicateVert(face_coords, vert) {
  let vx = roundToTwo(vert.x);
  let vy = roundToTwo(vert.y);
  let vz = roundToTwo(vert.z);
  for (let i = 0; i < store.face_coords.length; i++) {
    let x = roundToTwo(face_coords[i].x);
    let y = roundToTwo(face_coords[i].y);
    let z = roundToTwo(face_coords[i].z);
    if (x === vx && y === vy && z === vz) {
      return true;
    }
  }
  return false;
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function pushPull(diff, mag, key = false) {
  let brep = store.currentMesh.getSnaptrudeDS().brep;
  let oldPos = [];
  let newPos = [];
  var verData = store.currentMesh.getVerticesData(
    BABYLON.VertexBuffer.PositionKind
  );
  let newVerData = [];
  for (let i = 0; i < verData.length - 2; i += 3) {
    oldPos.push([verData[i], verData[i + 1], verData[i + 2]]);
    let vec = BABYLON.Vector3.TransformCoordinates(
      new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]),
      store.currentMesh.getWorldMatrix()
    );
    newVerData.push(vec.x);
    newVerData.push(vec.y);
    newVerData.push(vec.z);
  }
  if (!key) {
    mag =
      BABYLON.Vector3.Distance(new BABYLON.Vector3(0, 0, 0), diff) /
      Math.sqrt(3);
  }
  store.normalVector[0] = Math.round(store.normalVector[0] * 1000) / 1000;
  store.normalVector[1] = Math.round(store.normalVector[1] * 1000) / 1000;
  store.normalVector[2] = Math.round(store.normalVector[2] * 1000) / 1000;
  // console.log(normalVector[0] && !(normalVector[1]) && !(normalVector[2]));
  let extrudeValue;
  if (
    store.normalVector[0] &&
    !store.normalVector[1] &&
    !store.normalVector[2]
  ) {
    for (let i = 0; i < store.face_coords_index.length; i++) {
      if (!key)
        newVerData[store.face_coords_index[i][0]] +=
          (mag * Math.sign(diff.x)) / store.pushPullScale;
      else
        newVerData[store.face_coords_index[i][0]] -=
          (mag * Math.sign(diff.x)) / store.pushPullScale;
      extrudeValue += (mag * Math.sign(diff.x)) / store.pushPullScale;
      // console.log(mag, diff, verData[face_coords_index[i][0]]);
    }
    store.face_ribbon.position.x +=
      (mag * Math.sign(diff.x)) / store.pushPullScale;
  } else if (
    store.normalVector[1] &&
    !store.normalVector[0] &&
    !store.normalVector[2]
  ) {
    for (let i = 0; i < store.face_coords_index.length; i++) {
      if (!key)
        newVerData[store.face_coords_index[i][1]] -=
          (mag * Math.sign(diff.y)) / (store.pushPullScale * 2);
      else
        newVerData[store.face_coords_index[i][1]] -=
          (mag * Math.sign(diff.y)) / store.pushPullScale;
      extrudeValue -= (mag * Math.sign(diff.y)) / (store.pushPullScale * 2);
      // console.log(mag, diff, verData[face_coords_index[i][1]]);
    }
    store.face_ribbon.position.y -=
      (mag * Math.sign(diff.y)) / (store.pushPullScale * 2);
  } else if (
    store.normalVector[2] &&
    !store.normalVector[0] &&
    !store.normalVector[1]
  ) {
    for (let i = 0; i < store.face_coords_index.length; i++) {
      newVerData[store.face_coords_index[i][2]] +=
        (mag * Math.sign(diff.z)) / store.pushPullScale;
      extrudeValue += (mag * Math.sign(diff.z)) / store.pushPullScale;
    }
    store.face_ribbon.position.z +=
      (mag * Math.sign(diff.z)) / store.pushPullScale;
  } else {
    // let temp_coords = [];
    let vect = new BABYLON.Vector3(
      store.normalVector[0],
      store.normalVector[1],
      store.normalVector[2]
    );
    vect = vect.normalize();
    for (let i = 0; i < store.face_coords_index.length; i++) {
      newVerData[store.face_coords_index[i][0]] +=
        (mag * vect.x * Math.sign(diff.x)) / store.pushPullScale;
      // verData[face_coords_index[i][1]] -= mag * Math.sign(normalVector[1]) * Math.sign(diff.y)/ (pushPullScale);
      newVerData[store.face_coords_index[i][2]] +=
        (mag * vect.z * Math.sign(diff.z)) / store.pushPullScale;
    }
    store.face_ribbon.position.x +=
      (mag * vect.x * Math.sign(diff.x)) / store.pushPullScale;
    store.face_ribbon.position.z +=
      (mag * vect.z * Math.sign(diff.z)) / store.pushPullScale;
    // console.log(temp_coords);
  }
  store.face_ribbon.computeWorldMatrix(true);
  let endpt = store.face_ribbon.getBoundingInfo().boundingBox.centerWorld;
  store.face_ribbon.visibility = 1.0;

  // console.log(st, endpt);
  DisplayOperation.drawOnMove(st, endpt);
  DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st, endpt));
  // DisplayOperation.displayOnMove(-extrudeValue/2*unit_absolute_scale*10);

  let m = new BABYLON.Matrix();
  store.currentMesh.getWorldMatrix().invertToRef(m);
  verData = [];
  for (let i = 0; i < newVerData.length - 2; i += 3) {
    let vec = BABYLON.Vector3.TransformCoordinates(
      new BABYLON.Vector3(newVerData[i], newVerData[i + 1], newVerData[i + 2]),
      m
    );
    newPos.push([vec.x, vec.y, vec.z]);
    verData.push(vec.x);
    verData.push(vec.y);
    verData.push(vec.z);
  }

  if (store.currentMesh.sourceMesh === undefined) {
    store.currentMesh.geometry.setVerticesData(
      BABYLON.VertexBuffer.PositionKind,
      verData,
      true
    );
  } else {
    var newMesh = store.currentMesh.sourceMesh.clone(store.currentMesh.name);
    newMesh.geometry.setVerticesData(
      BABYLON.VertexBuffer.PositionKind,
      verData,
      true
    );
    newMesh.position = store.currentMesh.position;
    newMesh.rotation = store.currentMesh.rotation;
    newMesh.scaling = store.currentMesh.scaling;
    newMesh.material = store.currentMesh.material;
    store.currentMesh.dispose();
  }

  updateBrepPositions(brep, oldPos, newPos);

  if (store.currentMesh.type.toLowerCase() === "roof")
    store.currentMesh.getSnaptrudeDS().updateRoofOutline(oldPos, newPos);
  // if (document.getElementById("solid").style.backgroundColor === "orange") {
  //     if (!currentMesh.hideEdges) {
  //         store.currentMesh.enableEdgesRendering(1);
  //         store.currentMesh.edgesWidth = 8.0;
  //         store.currentMesh.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
  //     }
  // }
}

function pushPullRibbon(diff, mag, key = false) {
  store.face_ribbon.computeWorldMatrix(true);
  let endpt = store.face_ribbon.getBoundingInfo().boundingBox.centerWorld;
  store.face_ribbon.visibility = 1.0;

  // console.log(st, endpt);
  DisplayOperation.drawOnMove(st, endpt);
  let dist = BABYLON.Vector3.Distance(st, endpt);
  DisplayOperation.displayOnMove(dist);
}

function pickFace(pickInfo) {
  store.currentMesh = pickInfo.pickedMesh;
  var indices = store.currentMesh.getIndices();
  var verData = store.currentMesh.getVerticesData(
    BABYLON.VertexBuffer.PositionKind
  );

  var index0 = indices[pickInfo.faceId * 3];
  var index1 = indices[pickInfo.faceId * 3 + 1];
  var index2 = indices[pickInfo.faceId * 3 + 2];

  var index_buf = [];
  index_buf.push(index0);
  index_buf.push(index1);
  index_buf.push(index2);

  var Ax = verData[index_buf[0] * 3];
  var Ay = verData[index_buf[0] * 3 + 1];
  var Az = verData[index_buf[0] * 3 + 2];

  var Bx = verData[index_buf[1] * 3];
  var By = verData[index_buf[1] * 3 + 1];
  var Bz = verData[index_buf[1] * 3 + 2];

  var Cx = verData[index_buf[2] * 3];
  var Cy = verData[index_buf[2] * 3 + 1];
  var Cz = verData[index_buf[2] * 3 + 2];

  // (Ax, Ay, Az, Bx, By, Bz, Cx, Cy, Cz);
  var a = (By - Ay) * (Cz - Az) - (Cy - Ay) * (Bz - Az);
  var b = (Bz - Az) * (Cx - Ax) - (Cz - Az) * (Bx - Ax);
  var c = (Bx - Ax) * (Cy - Ay) - (Cx - Ax) * (By - Ay);
  var d = -(a * Ax + b * Ay + c * Az);

  store.normalVector = [a, b, c];
  // console.log(pickInfo.pickedMesh.getFacetNormal(pickInfo.faceId));
  // console.log(pickInfo.pickedMesh.getFacetLocalNormals()[pickInfo.faceId]);

  if (store.face_ribbon) {
    store.face_ribbon.dispose();
  }
  var mat2 = new BABYLON.StandardMaterial("mat2", store.scene);
  mat2.alpha = 1.0;
  mat2.diffuseColor = new BABYLON.Color3(0.0, 0.0, 0.0);
  mat2.backFaceCulling = false;

  store.face_coords = [];
  store.face_coords_index = [];
  let face_coords_index2 = [];

  if ((pickInfo.faceId * 3) % 2 === 0) {
    for (let i = 0; i < 6; i++) {
      store.face_coords_index2.push(pickInfo.faceId * 3 + i);
    }
  } else {
    for (let i = 0; i < 6; i++) {
      store.face_coords_index2.push(pickInfo.faceId * 3 + (i - 3));
    }
  }

  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  // console.log($scope.selectedMaterial);
  if ($scope.selectedMaterial !== "nan") {
    // if (currentMesh.name.toLowerCase() === "roof") {
    //     store.face_coords = [];
    //     store.face_coords_index = [];
    //     for ( let i = 0; i < verData.length; i = i + 3) {
    //         // (Math.abs(a*verData[i]+b*verData[i+1]+c*verData[i+2]+d));
    //         if (Math.abs(a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d) <= 0.001) {
    //             var vert = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
    //             store.face_coords.push(vert);
    //             store.face_coords_index.push([i, i + 1, i + 2]);
    //         }
    //     }
    // }
    // if (['wall', 'mass', 'floor', 'roof'].includes(pickInfo.pickedMesh.type.toLowerCase())) {
    //     // else if (true){
    //     // Working perfectly for getting 4 coords
    //     // for (var i = 0; i < index_buf.length; i++) {
    //     //     if (Math.abs(a * verData[index_buf[i] * 3] + b * verData[index_buf[i] * 3 + 1] + c * verData[index_buf[i] * 3 + 2] + d) <= 0.001) {
    //     //         var vert = new BABYLON.Vector3(verData[index_buf[i] * 3], verData[index_buf[i] * 3 + 1], verData[index_buf[i] * 3 + 2]);
    //     //         store.face_coords.push(vert);
    //     //         store.face_coords_index.push([index_buf[i] * 3, index_buf[i] * 3 + 1, index_buf[i] * 3 + 2]);
    //     //     }
    //     // }
    //     // console.log(face_coords, store.face_coords_index);
    //     // var temp_coord = getLastVertex(vert, store.face_coords);
    //     // store.face_coords.push(temp_coord);
    //     // for (var i = 1; i < indices.length / 2; i++) {
    //     //     if ((index_buf[0] + i) < indices.length - 1) {
    //     //         var vert1 = new BABYLON.Vector3(verData[indices[i + index_buf[0]] * 3], verData[indices[i + index_buf[0]] * 3 + 1], verData[indices[i + index_buf[0]] * 3 + 2]);
    //     //         if (Math.abs(a * verData[indices[i + index_buf[0]] * 3] + b * verData[indices[i + index_buf[0]] * 3 + 1] + c * verData[indices[i + index_buf[0]] * 3 + 2] + d) <= 0.001) {
    //     //             var ind = findArrayElemInArray([indices[i + index_buf[0]] * 3, indices[i + index_buf[0]] * 3 + 1, indices[i + index_buf[0]] * 3 + 2], store.face_coords_index);
    //     //             if (ind !== -1) continue;
    //     //             store.face_coords_index.push([indices[i + index_buf[0]] * 3, indices[i + index_buf[0]] * 3 + 1, indices[i + index_buf[0]] * 3 + 2]);
    //     //             store.face_coords.push(vert1);
    //     //             break;
    //     //         }
    //     //     }
    //     //     if ((index_buf[0] - i) > 0) {
    //     //         var vert2 = new BABYLON.Vector3(verData[indices[index_buf[0] - i] * 3], verData[indices[index_buf[0] - i] * 3 + 1], verData[indices[index_buf[0] - i] * 3 + 2]);
    //     //         if (Math.abs(a * verData[indices[index_buf[0] - i] * 3] + b * verData[indices[index_buf[0] - i] * 3 + 1] + c * verData[indices[index_buf[0] - i] * 3 + 2] + d) <= 0.001) {
    //     //             var ind = $.inArray([indices[index_buf[0] - i] * 3, indices[index_buf[0] - i] * 3 + 1, indices[index_buf[0] - i] * 3 + 2], store.face_coords_index);
    //     //             if (ind !== -1) continue;
    //     //             store.face_coords_index.push([indices[index_buf[0] - i] * 3, indices[index_buf[0] - i] * 3 + 1, indices[index_buf[0] - i] * 3 + 2]);
    //     //             store.face_coords.push(vert2);
    //     //             break;
    //     //         }
    //     //     }
    //     // }
    //     // var face_coords_temp = [];
    //     // var face_coords_index_temp = [];
    //     // for ( let i = 0; i < verData.length; i = i + 3) {
    //     //     // (Math.abs(a*verData[i]+b*verData[i+1]+c*verData[i+2]+d));
    //     //     if (Math.abs(a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d) <= 0.001) {
    //     //         var vert = new BABYLON.Vector3(verData[i], verData[i + 1], verData[i + 2]);
    //     //         store.face_coords_temp.push(vert);
    //     //         store.face_coords_index_temp.push([i, i + 1, i + 2]);
    //     //     }
    //     // }
    //     // console.log(face_coords_temp, store.face_coords_index_temp);
    //     // Working perfectly for getting 4 coords
    //     var faceInfo = findFaceIndices(currentMesh, pickInfo, indices, verData, [a, b, c, d]);
    //     store.face_coords = faceInfo.face_coords;
    //     store.face_coords_index = faceInfo.face_coords_index;
    // }
    /* eslint-disable */
    var mat_name = $scope.selectedMaterial
      .replace(/^.*[\\\/]/, "")
      .split(".")[0];
    /* eslint-enable */
    var temp_mat = store.scene.getMaterialByName(mat_name);
    console.log(temp_mat);
    if (!temp_mat) {
      temp_mat = createMaterialFromImage(
        $scope.selectedMaterial,
        $scope.selectedMaterialType
      );
    }
    // if (currentMesh.name.indexOf("room") === -1){
    if (
      ["wall", "mass", "floor", "roof"].includes(
        pickInfo.pickedMesh.type.toLowerCase()
      )
    ) {
      // console.log(face_coords_index, store.face_coords);
      // if ((currentMesh.name.toLowerCase() === "wall") || true)
      //         applyMaterialToFace(currentMesh, store.face_coords, store.face_coords_index, temp_mat);
      // else if ((currentMesh.type.toLowerCase() === "wall") || true)
      //         applyMaterialToFace(currentMesh, store.face_coords, store.face_coords_index, temp_mat);
      applyMaterialToFace(
        store.currentMesh,
        store.face_coords,
        store.face_coords_index,
        store.face_coords_index2,
        temp_mat
      );
      updateModifications();
    }
  } else {
    //Working perfectly
    var diff = new BABYLON.Vector3(0.01, 0.01, 0.01);
    if (Math.abs(store.normalVector[0]) > 0.001)
      diff.x = Math.sign(store.normalVector[0]) * diff.x;
    else diff.x = 0;
    if (Math.abs(store.normalVector[1]) > 0.001)
      diff.y = Math.sign(store.normalVector[1]) * diff.y;
    else diff.y = 0;
    if (Math.abs(store.normalVector[2]) > 0.001)
      diff.z = Math.sign(store.normalVector[2]) * diff.z;
    else diff.z = 0;
    for (let i = 0; i < verData.length; i = i + 3) {
      // (Math.abs(a*verData[i]+b*verData[i+1]+c*verData[i+2]+d));
      if (
        Math.abs(
          a * verData[i] + b * verData[i + 1] + c * verData[i + 2] + d
        ) <= 0.001
      ) {
        var vert = new BABYLON.Vector3(
          verData[i] + diff.x,
          verData[i + 1] + diff.y,
          verData[i + 2] + diff.z
        );
        store.face_coords.push(vert);
        store.face_coords_index.push([i, i + 1, i + 2]);
      }
    }
    //Working perfectly
    store.face_ribbon = BABYLON.Mesh.CreateRibbon(
      "ribbon",
      [store.face_coords],
      false,
      false,
      0,
      store.scene,
      true,
      BABYLON.DOUBLESIDE
    );
    store.face_ribbon.material = mat2;
    store.face_ribbon.parent = pickInfo.pickedMesh;
  }

  store.first_center_coord = new BABYLON.Vector3(0, 0, 0);
  for (let i = 0; i < store.face_coords.length; i++) {
    store.first_center_coord.addInPlace(store.face_coords[i]);
  }
  store.first_center_coord.x = store.currentMesh.position.x;
  store.first_center_coord.y =
    store.currentMesh.position.y + store.distanceLineOffset;
  store.first_center_coord.z = store.currentMesh.position.z;
  // updatePropBlockFace(face_ribbon);
}

function findFaceIndices(currentMesh, pickInfo, indices, verData, planParams) {
  var a = planParams[0];
  var b = planParams[1];
  var c = planParams[2];
  var d = planParams[3];
  var temp_face_coords = [];
  var temp_face_coords_index = [];
  for (var i = 0; i < indices.length / 3; i += 1) {
    var index_buf = [];
    index_buf.push(indices[i * 3]);
    index_buf.push(indices[i * 3 + 1]);
    index_buf.push(indices[i * 3 + 2]);
    var vert1 = new BABYLON.Vector3(
      verData[index_buf[0] * 3],
      verData[index_buf[0] * 3 + 1],
      verData[index_buf[0] * 3 + 2]
    );
    var vert2 = new BABYLON.Vector3(
      verData[index_buf[1] * 3],
      verData[index_buf[1] * 3 + 1],
      verData[index_buf[1] * 3 + 2]
    );
    var vert3 = new BABYLON.Vector3(
      verData[index_buf[2] * 3],
      verData[index_buf[2] * 3 + 1],
      verData[index_buf[2] * 3 + 2]
    );
    // var vert1 = new BABYLON.Vector3(verData[index_buf[0]*1], verData[index_buf[0]*1 + 1], verData[index_buf[0]*1 + 2]);
    // var vert2 = new BABYLON.Vector3(verData[index_buf[1]*1], verData[index_buf[1]*1 + 1], verData[index_buf[1]*1 + 2]);
    // var vert3 = new BABYLON.Vector3(verData[index_buf[2]*1], verData[index_buf[2]*1 + 1], verData[index_buf[2]*1 + 2]);
    // console.log(Math.abs(a*vert1.x + b*vert1.y + c*vert1.z + d))
    // console.log(Math.abs(a*vert2.x + b*vert2.y + c*vert2.z + d))
    // console.log(Math.abs(a*vert3.x + b*vert3.y + c*vert3.z + d))
    if (Math.abs(a * vert1.x + b * vert1.y + c * vert1.z + d) < 0.01) {
      if (Math.abs(a * vert2.x + b * vert2.y + c * vert2.z + d) < 0.01) {
        if (Math.abs(a * vert3.x + b * vert3.y + c * vert3.z + d) < 0.01) {
          temp_face_coords.push(vert1);
          temp_face_coords_index.push([
            index_buf[0] * 3,
            index_buf[0] * 3 + 1,
            index_buf[0] * 3 + 2,
          ]);
          // temp_face_coords_index.push(index_buf[0]);
          temp_face_coords.push(vert2);
          // temp_face_coords_index.push(index_buf[1]);
          temp_face_coords_index.push([
            index_buf[1] * 3,
            index_buf[1] * 3 + 1,
            index_buf[1] * 3 + 2,
          ]);
          temp_face_coords.push(vert3);
          // temp_face_coords_index.push(index_buf[2]);
          temp_face_coords_index.push([
            index_buf[2] * 3,
            index_buf[2] * 3 + 1,
            index_buf[2] * 3 + 2,
          ]);
        }
      }
    }

    // temp_face_coords = [];
    // temp_face_coords_index = [];
    // for (var j=0; j<index_buf.length; j++){
    //     var vert = new BABYLON.Vector3(verData[index_buf[j]], verData[index_buf[j] + 1], verData[index_buf[j] + 2]);
    //     if  (a*vert.x + b*vert.y + c*vert.z + d < 0.001){
    //         temp_face_coords.push(vert);
    //         temp_face_coords_index.push([index_buf[j], index_buf[j]+1, index_buf[j]+2]);
    //     }
    // }
    // if (temp_face_coords.length === 3){
    //     for (var j=0; j<temp_face_coords.length; j++){
    //         store.face_coords.push(temp_face_coords[j]);
    //         store.face_coords_index.push(temp_face_coords_index[j]);
    //         console.log(temp_face_coords);
    //         console.log(temp_face_coords_index);
    //     }
    //     // return;
    // }
  }
  console.log(indices);
  console.log(temp_face_coords, temp_face_coords_index);
  return {
    face_coords: temp_face_coords,
    face_coords_index: temp_face_coords_index,
  };
  // console.log(temp_face_coords);
  // console.log(temp_face_coords_index);
}

function pickFace2(pickInfo) {
  // let normalsTemp = pickInfo.pickedMesh.getVerticesData(BABYLON.VertexBuffer.NormalKind);
  // pickInfo.pickedMesh.updateVerticesData(BABYLON.VertexBuffer.NormalKind, normalsTemp);
  // showNormals(pickInfo.pickedMesh);
  let normal1 = pickInfo.getNormal(true, false);
  normal1 = normal1.normalize();
  let normals = pickInfo.pickedMesh.getFacetLocalNormals();
  // console.log(normals);
  let adjacentFaceId = null;
  for (let i = 0; i < normals.length; i++) {
    if (i !== pickInfo.faceId) {
      let normal2 = pickInfo.pickedMesh.getFacetNormal(i);
      if (normal1.equals(normal2)) {
        adjacentFaceId = i;
        break;
      }
    }
  }

  let _id1,
    _id2 = null;

  if (pickInfo.faceId < adjacentFaceId) {
    _id1 = pickInfo.faceId * 3;
    _id2 = adjacentFaceId * 3;
  } else {
    _id1 = adjacentFaceId * 3;
    _id2 = pickInfo.faceId * 3;
  }

  let $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  if ($scope.selectedMaterial !== "nan") {
    if (
      pickInfo.pickedMesh.type.toLowerCase() === "wall" ||
      pickInfo.pickedMesh.type.toLowerCase() === "mass"
    ) {
      let temp_mat = store.scene.getMaterialByName($scope.selectedMaterial);
      if (!temp_mat) {
        temp_mat = createMaterialFromImage(
          $scope.selectedMaterial,
          $scope.selectedMaterialType
        );
      }
      applyMaterialToFace3(pickInfo.pickedMesh, _id1, _id2, temp_mat);
    }
  }

  // console.log(pickInfo.faceId, adjacentFaceId);
}

// function  updatePushPull(dist) {
//
//     pushPull(DisplayOperation.getOriginalDimension(dist), "");
//     console.log(dist,diff);
// }

function applyMaterialToFace3(temp_mesh, _id1, _id2, mat) {
  let mat_index;
  if (temp_mesh.material.subMaterials) {
    let multiMatSubs = temp_mesh.material.subMaterials;
    let mat_exist_flag = false;
    let mat_exist_index = -1;
    for (let p = 0; p < temp_mesh.material.subMaterials.length; p++) {
      if (temp_mesh.material.subMaterials[p].id === mat.id) {
        mat_exist_flag = true;
        mat_exist_index = p;
      }
    }
    if (!mat_exist_flag) {
      temp_mesh.material.subMaterials.push(mat);

      mat_index = multiMatSubs.length - 1;
    } else {
      mat_index = mat_exist_index;
    }
  } else {
    let id = Math.random().toString(36).substr(2, 6) + "_";
    let multiMat = new BABYLON.MultiMaterial(mat.name + id, store.scene);
    multiMat.subMaterials.push(temp_mesh.material);
    multiMat.subMaterials.push(mat);
    temp_mesh.material = multiMat;
    mat_index = multiMat.subMaterials.length - 1;
  }

  let verticesCount = temp_mesh.getTotalVertices();

  if (temp_mesh.subMeshes.length > 1) {
    for (let i = 0; i < temp_mesh.subMeshes.length; i++) {
      let indexStart = temp_mesh.subMeshes[i].indexStart;
      let indexEnd = temp_mesh.subMeshes[i].indexCount + indexStart;
      let mat_idx = temp_mesh.subMeshes[i].materialIndex;
      _id2 = _id2 + 3;

      if (_id1 === _id2) break;

      if (_id1 === indexStart && _id2 === indexEnd) {
        temp_mesh.subMeshes[i].materialIndex = mat_index;
        break;
      } else if (_id1 === indexStart && _id2 > indexEnd) {
        temp_mesh.subMeshes[i].materialIndex = mat_index;
        break;
      } else if (_id1 === indexStart && _id2 < indexEnd) {
        temp_mesh.subMeshes.splice(i, 1);
        BABYLON.SubMesh.AddToMesh(
          mat_index,
          _id1,
          _id2 - _id1,
          _id1,
          _id2 - _id1,
          temp_mesh
        );
        BABYLON.SubMesh.AddToMesh(
          mat_idx,
          _id2,
          indexEnd - _id2,
          _id2,
          indexEnd - _id2,
          temp_mesh
        );
        break;
      } else if (_id1 < indexStart && _id2 === indexEnd) {
        temp_mesh.subMeshes[i].materialIndex = mat_index;
        break;
      } else if (_id1 > indexStart && _id2 === indexEnd) {
        temp_mesh.subMeshes.splice(i, 1);
        BABYLON.SubMesh.AddToMesh(
          mat_idx,
          0,
          verticesCount,
          indexStart,
          _id1 - indexStart,
          temp_mesh
        );
        BABYLON.SubMesh.AddToMesh(
          mat_index,
          0,
          verticesCount,
          _id1,
          _id2 - _id1,
          temp_mesh
        );
        break;
      } else if (_id1 > indexStart && _id2 > indexEnd) {
        if (_id1 < indexStart) {
          temp_mesh.subMeshes.splice(i, 1);
          BABYLON.SubMesh.AddToMesh(
            mat_idx,
            indexStart,
            _id1 - indexStart,
            indexStart,
            _id1 - indexStart,
            temp_mesh
          );
          BABYLON.SubMesh.AddToMesh(
            mat_index,
            _id1,
            indexEnd - _id1,
            _id1,
            indexEnd - _id1,
            temp_mesh
          );
          break;
        }
      } else if (_id1 < indexStart && _id2 < indexEnd) {
        if (_id2 > indexStart) {
          BABYLON.SubMesh.AddToMesh(
            mat_index,
            indexStart,
            _id2 - indexStart,
            indexStart,
            _id2 - indexStart,
            temp_mesh
          );
          BABYLON.SubMesh.AddToMesh(
            mat_idx,
            _id2,
            indexEnd - _id2,
            _id2,
            indexEnd - _id2,
            temp_mesh
          );
          break;
        }
      } else if (_id1 > indexStart && _id2 < indexEnd) {
        temp_mesh.subMeshes.splice(i, 1);
        BABYLON.SubMesh.AddToMesh(
          mat_idx,
          0,
          verticesCount,
          indexStart,
          _id1 - indexStart,
          temp_mesh
        );
        BABYLON.SubMesh.AddToMesh(
          mat_index,
          0,
          verticesCount,
          _id1,
          _id2 - _id1,
          temp_mesh
        );
        BABYLON.SubMesh.AddToMesh(
          mat_idx,
          0,
          verticesCount,
          _id2,
          indexEnd - _id2,
          temp_mesh
        );
        break;
      }
    }
  } else {
    let indexEnd = temp_mesh.subMeshes[0].indexCount;
    let mat_idx = temp_mesh.subMeshes[0].materialIndex;
    temp_mesh.subMeshes = [];

    BABYLON.SubMesh.AddToMesh(mat_idx, 0, verticesCount, 0, _id1, temp_mesh);
    BABYLON.SubMesh.AddToMesh(mat_index, 0, verticesCount, _id1, 6, temp_mesh);
    BABYLON.SubMesh.AddToMesh(
      mat_idx,
      0,
      verticesCount,
      _id1 + 6,
      indexEnd - (_id1 + 6),
      temp_mesh
    );
  }
  //console.log(verticesCount, temp_mesh.getIndices().length);
  //console.log(temp_mesh);
  //console.log(temp_mesh.subMeshes);
}

function extrudeFace(mesh, faceid, params) {
  //console.log(faceid, mesh.faceid);
  var faceIndex = faceid[0] / 3;
  var extrudeAmount = params.mag;
  var faceShrink = 0.0;
  snapmda.ExtrudeOperator(mesh.brep, faceIndex, extrudeAmount, faceShrink);
  //console.log(mesh.brep);
  mesh.BrepToMesh(mesh.brep);
}
export {
  selectFace,
  getTriangle,
  getFace,
  getFaceDistinctVertices,
  duplicateVert,
  roundToTwo,
  pushPull,
  pushPullRibbon,
  pickFace,
  findFaceIndices,
  pickFace2,
  applyMaterialToFace3,
  extrudeFace,
};
