import BABYLON from "../../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../../modules/utilityFunctions/Store.js"
import { commandUtils } from "../../modules/commandManager/CommandUtils.js";
import { CommandManager } from "../../modules/commandManager/CommandManager.js";
import { removeDuplicatesNew,removecollinear,multiplyPointArrayByFactor,negateYOfPointArray,convertArray2ToArray3,removeDuplicateVertices,removeDuplicateVerticesModified,removeZeroArrays,zeroDownZ,changeOrderForHoles } from "./twoServices.js";
import { createCustomMesh } from "../massModeling.js";
import { makeid } from "../arrayFuncs.js";
import { scaleFactor } from "../twoDimension.js";
import { getActiveStoreyObject,onSolid,updateLevelsAngularUI,getRoomHeight,removeMeshFromStructure,showToast,isRoomOfType } from "../../modules/extrafunc.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import { Mass } from "../../modules/snaptrudeDS/mass.ds.js";
import { verifyBRepIntegrity } from "../brepOperations.js";
import { assignProperties } from "../sceneStateFuncs.js";
import { virtualSketcher } from "../../modules/sketchMassBIMIntegration/virtualSketcher.js";
import { setLayerTransperancy } from "../sceneFuncs.js";
import { pathToPoints,swapYAndZInPathArray } from "../../modules/curve/curveServices.js";
import { room_types_db } from "../obj_base.js";
import objectPropertiesView from "../../modules/objectProperties/objectPropertiesView.js";

const drawRoomMass = (
  pointArray,
  roomName,
  roomType,
  roomID,
  levelID,
  structureID,
  isCurve,
  storey,
  objectType = null
) => {
  let _executeEvent = function (options = {}) {
    // let data = {
    //     polygon: scaledZPolygon,
    //     height: height,
    //     roomName: roomName,
    //     roomType: roomType,
    //     roomID: roomID,
    //     levelID: levelID,
    //     structureID: structureID,
    //     isCurve: isCurve,
    //     storey: storey,
    //     uniqueId: room.uniqueId
    // };
    // let cmd = new Command(detectCommandName, data, _getCommandLogic());
    // CommandManager.execute(cmd, false);

    if (_.isNil(options.cmdsToExecute)) options.cmdsToExecute = [];

    let commandData = commandUtils.creationOperations.getCommandData(room);
    let detectCommandName = "imageObjectDetection";

    const creationCommand = commandUtils.creationOperations.getCommand(
      detectCommandName,
      commandData
    );

    const commands = _.compact([
      creationCommand,
      integrationGeometryChangeCommand,
      ...options.cmdsToExecute,
    ]);
    const yets = commands.map((_) => false);

    CommandManager.execute(commands, yets);
  };

  let _createMass = function () {
    let data = {
      polygon: scaledZPolygon,
      height: height,
    };
    data.polygon = removeDuplicatesNew(data.polygon);
    data.polygon = removecollinear(data.polygon);
    //data.polygon = removesmallEdgesAfterOverlap(data.polygon);
    //data.polygon = removeInaccuracies(data.polygon);

    room = createCustomMesh(data.polygon, data.height);
    if (data.uniqueId) {
      room.uniqueId = data.uniqueId;
    }
    room.pointsUsed = data.polygon;
    // room.material = scene.getMaterialByName("basicObjectMaterial");
    room.checkCollisions = true;
    room.name = roomName;
    room.room_type = roomType;
    room.room_id = makeid(3);
    room.room_curve = isCurve;
    room.room_path = JSON.stringify(
      multiplyPointArrayByFactor(pointArray, scaleFactor)
    );
    room.height = height;
    room.showBoundingBox = true;
    room.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    room.level = levelID;
    room.room_unique_id = room.room_id;
    // room.structure = structure;
    room.offsetFlag = false;
    room.type = "Mass";
    room.position.y += getActiveStoreyObject().base;
    room.storey = storey;
    // room.position.y = height * parseInt(levelID);
    room.structure_id = structureID;
    let roomMasses = JSON.parse(localStorage.getItem(room.name));
    roomMasses
      ? roomMasses.push(room.uniqueId)
      : (roomMasses = [room.uniqueId]);
    localStorage.setItem(room.name, JSON.stringify(roomMasses));

    let bbinfo = room.getBoundingInfo();
    let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    // room.setPivotPoint(centroid);

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure =
      structureCollection.getStructureById(structureID);
    const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelID);

    talkingAboutLevel.addObjectToLevel(new Mass(room), false);
    let roomDS = room.getSnaptrudeDS();
    roomDS.room_type = room.room_type;
    roomDS.room_curve = isCurve;
    roomDS.room_id = room.room_id;
    roomDS.assignProperties();
    // sortFaceIndicesForHalfEdge(room);
    // let verify = (room.getSnaptrudeDS().brep)?verifyBRepIntegrity(room.getSnaptrudeDS().brep):false;
    if (!verifyBRepIntegrity(roomDS.brep)) {
      handleIncorrectDetection(room);
      return false;
    }

    assignProperties(room, null, "mass");
    // addMaterialToMesh(room, material);
    /*if (project_type){
           if (project_type === "sketch-to-bim"){
               //createRoom for the detected mass
               convertMassToWalls(room);
           }
        }*/
    doMassPostProcessing(roomDS);

    let cmdsToExecute = [];
    if (roomDS.storey === 1) {
      // Create a Plinth under the drawn mass
      cmdsToExecute.push(Mass.drawPlinth(roomDS.mesh));
    }

    integrationGeometryChangeCommand =
      virtualSketcher.addWithGeometryEdit(roomDS);
    onSolid(room);
    updateLevelsAngularUI();
    setLayerTransperancy(room);
    if (objectType) {
      if (objectType.toLowerCase() === "wall") {
       objectPropertiesView.updateMeshType(roomDS, "Wall");
      }
    }
    _executeEvent({ cmdsToExecute: cmdsToExecute });
    return true;
  };

  /*
    let height = (storeysDS[activeLayer.storey-1]) ? storeysDS[activeLayer.storey-1].height : store.floor_height;
    let roomProperties = room_types_db.searchForKeyValuePair('name', roomType);
    if(roomProperties){
        height = roomProperties.props.height * store.floor_height;
        material = roomProperties.props.mat;
    }
    */

  let scaledZPolygon = negateYOfPointArray(convertArray2ToArray3(pointArray));
  scaledZPolygon.reverse().pop();

  let material = "none";

  let room;

  scaledZPolygon = removeDuplicateVertices(scaledZPolygon);
  scaledZPolygon = removeDuplicateVerticesModified(scaledZPolygon);
  roomType = roomType === "" ? "Default" : roomType;

  let height = getRoomHeight(roomType);

  let integrationGeometryChangeCommand;

  //let room = createCustomMesh(scaledZPolygon, height);

  _createMass();
  // if (_createMass()) _executeEvent();
};

const drawSketchMass = (
  pointArray,
  roomName,
  roomType,
  roomID,
  structureID,
  isCurve,
  yPos,
  levelID,
) => {
  let _executeEvent = function (options = {}) {
    // let data = {
    //     polygon: zeroedZ,
    //     height: height,
    //     roomName: roomName,
    //     roomType: roomType,
    //     roomID: roomID,
    //     levelID: levelID,
    //     structureID: structureID,
    //     isCurve: isCurve,
    //     uniqueId: room.uniqueId,
    //     holes: zeroedZHoles
    // };
    // let cmd = new Command(detectCommandName, data, _getCommandLogic());
    // CommandManager.execute(cmd, false);

    if (_.isNil(options.cmdsToExecute)) options.cmdsToExecute = [];
    let commandData = commandUtils.creationOperations.getCommandData(room);
    let detectCommandName = "sketchObjectDetection";

    const creationCommand = commandUtils.creationOperations.getCommand(
      detectCommandName,
      commandData
    );

    const commands = _.compact([
      creationCommand,
      integrationGeometryChangeCommand,
      ...options.cmdsToExecute,
    ]);
    const yets = commands.map((_) => false);

    CommandManager.execute(commands, yets);
  };

  //let room = createCustomMesh(zeroedZ, height);
  let _createMass = function () {
    let data = {
      polygon: zeroedZ,
      height: height,
    };
    data.polygon = removecollinear(data.polygon);
    //data.polygon = removeInaccuracies(data.polygon);
    //data.polygon = removesmallEdgesAfterOverlap(data.polygon);
    //data.polygon = removecollinear(data.polygon);

    zeroedZHoles = removeZeroArrays(zeroedZHoles);
    room = createCustomMesh(data.polygon, data.height, null, zeroedZHoles);
    if (data.uniqueId) {
      room.uniqueId = data.uniqueId;
    }
    room.pointsUsed = data.polygon;
    // room.material = scene.getMaterialByName("basicObjectMaterial");
    room.checkCollisions = true;
    room.name = roomName;
    room.room_type = roomType;
    room.room_id = makeid(3);
    room.room_curve = isCurve;
    room.room_path = JSON.stringify(data.polygon);
    room.height = height;
    room.showBoundingBox = true;
    room.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    //room.level = levelID;
    room.room_unique_id = room.room_id;
    room.structure = store.activeLayer.structure_id;
    room.structure_id = structureID;
    room.storey = store.activeLayer.storey;
    room.offsetFlag = false;
    room.type = "Mass";
    // commenting based on discussion
    // room.position.y = yPos;
    let roomMasses = JSON.parse(localStorage.getItem(room.name));
    if (!roomMasses) {
      roomMasses = [room.uniqueId];
      localStorage.setItem(room.name, JSON.stringify(roomMasses));
    }
    roomMasses.push(room.uniqueId);
    localStorage.setItem(room.name, JSON.stringify(roomMasses));

    let bbinfo = room.getBoundingInfo();
    let centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    // room.setPivotPoint(centroid);

    //  let levels = StructureCollection.getInstance().getStructures()[structureID].getAllLevels();
    //   let levels = StructureCollection.getInstance().getStructures()[structureID].getLevelsByLow(0);
    //   let levelID = levels[0].flyweight.low + levels[0].flyweight.high;

    const structureCollection = StructureCollection.getInstance();
    const talkingAboutStructure =
      structureCollection.getStructureById(structureID);
    const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelID);
    talkingAboutLevel.addObjectToLevel(new Mass(room), false);

    let roomDS = room.getSnaptrudeDS();
    roomDS.room_type = room.room_type;
    roomDS.assignProperties();
    roomDS.room_id = room.room_id;
    //attaches brep as a property

    //sortFaceIndicesForHalfEdge(room);

    if (!verifyBRepIntegrity(roomDS.brep)) {
      handleIncorrectDetection(room);
      return false;
    }

    let cmdsToExecute = [];
    if (roomDS.storey === 1) {
      // Create a Plinth under the drawn mass
      cmdsToExecute.push(Mass.drawPlinth(roomDS.mesh));
    }

    doMassPostProcessing(roomDS);
    integrationGeometryChangeCommand =
      virtualSketcher.addWithGeometryEdit(roomDS);
    // resetLevels(room);
    assignProperties(room, null, "mass");
    // addMaterialToMesh(room, material);
    /*if (project_type){
           if (project_type === "sketch-to-bim"){
               //createRoom for the detected mass
               convertMassToWalls(room);
           }
        }*/
    onSolid(room);
    // updateLevelsAngularUI();
    setLayerTransperancy(room);

    _executeEvent({ cmdsToExecute: cmdsToExecute });
    return true;
  };

  // let height = (storeysDS[activeLayer.storey-1]) ? storeysDS[activeLayer.storey-1].height : store.floor_height;
  let material = "none";
  let roomProperties = room_types_db.searchForKeyValuePair("name", "Bedroom");
  if (roomProperties) {
    height = roomProperties.props.height * store.floor_height;
    material = roomProperties.props.mat;
  }
  let newPoly = [];
  newPoly = convertArray2ToArray3(pointArray[0]);

  //For rooms with holes
  let zeroedZHoles = [];
  if (pointArray[1] !== undefined) {
    let holes = pointArray[1];
    holes = removeZeroArrays(holes);
    for (let i = 0; i < holes.length; i++) {
      holes[i] = convertArray2ToArray3(holes[i]);
      let h = zeroDownZ(holes[i], yPos);
      h.pop();
      h = removeDuplicateVertices(h);
      h = removeDuplicateVerticesModified(h);

      h = changeOrderForHoles(h);
      zeroedZHoles.push(h);
    }
  }

  //const droppedY = exchangeYandZinSketch(newPoly);
  let zeroedZ = zeroDownZ(newPoly, yPos);

  zeroedZ.pop(); //last point is the same as the first
  roomType = roomType === "" ? "Default" : roomType;
  // height for buildings
  let height;
  height = getRoomHeight(roomType);
  zeroedZ = removeDuplicateVertices(zeroedZ);
  zeroedZ = removeDuplicateVerticesModified(zeroedZ);

  let room;
  let integrationGeometryChangeCommand;

  _createMass();
  // if (_createMass()) _executeEvent();
};


const handleIncorrectDetection =
  function triggeredByDuplicateVerticesOrCollinearOrWhateverThatMessesUpBRep(
    mesh
  ) {
    removeMeshFromStructure(mesh);
    mesh.dispose();

    let errorMessage = "Please try detecting other rooms before this";
    showToast(errorMessage);
  };

const doMassPostProcessing = function forLiftsTheTopAndBottomFacesWillBeTrimmed(
  masses
) {
  if (!_.isArray(masses)) masses = [masses];

  const liftMasses = masses.filter((mass) => isRoomOfType(mass.mesh, "lift"));
  if (!_.isEmpty(liftMasses)) {
    const liftMeshes = liftMasses.map((mass) => mass.mesh);
    return commandUtils.geometryChangeOperations.expressCheckout(
      "Lift type face trim",
      liftMeshes,
      () => {
        liftMasses.forEach((mass) => {
          // remove the top and bottom face
          mass.brep.getFaces().splice(0, 2);
          mass.brep.getFaces().forEach((f) => (f.index -= 2));

          mass.mesh.BrepToMesh();
        });
      }
    );
  }
};

const drawCurveMass = (
  pathArray,
  roomName,
  roomType,
  roomID,
  structureID,
  isCurve,
  yPos,
  levelID
) => {
  const path = pathArray[0];
  const pointArray = [];
  let skipArray = [];
  for (let i = 0; i < path.length; i++) {
    if (path[i].length > 2) {
      const pointArr = pathToPoints([path[i]]);
      pointArray.push(...pointArr);
      skipArray.push(pointArr);
    } else {
      pointArray.push(...path[i]);
    }
  }
  skipArray = swapYAndZInPathArray(skipArray);
  drawSketchMass(
    [pointArray, undefined],
    roomName,
    roomType,
    roomID,
    structureID,
    skipArray,
    yPos,
    levelID
  );
};

const drawCurveRoomMass = (
  path,
  roomName,
  roomType,
  roomID,
  levelID,
  structureID,
  isCurve,
  storey,
  objectType = null
) => {
  const pointArray = [];
  let skipArray = [];
  for (let i = 0; i < path.length; i++) {
    if (path[i].length > 2) {
      const pointArr = pathToPoints([path[i]]);
      pointArray.push(...pointArr);
      skipArray.push(pointArr);
    } else {
      pointArray.push(...path[i]);
    }
  }
  skipArray = swapYAndZInPathArray(skipArray);
  drawRoomMass(
    pointArray,
    roomName,
    roomType,
    roomID,
    levelID,
    structureID,
    isCurve,
    storey,
    objectType
  );
};

//drawCurveMass(samplePath2, "room1", "Default", "1", store.activeLayer.structure_id, true, 0, "01")
export {
  drawRoomMass,
  drawSketchMass,
  handleIncorrectDetection,
  doMassPostProcessing,
  drawCurveMass,
  drawCurveRoomMass,
};
