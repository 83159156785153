"use strict";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import {
  computeAreaTriangle3D,
  computeVolumeWall,
  computeVolumeMass,
  computeVolumeStairsLayer,
  computeVolumeStairsLayerType,
  computeVolumeFloor,
  computeAreaWall,
  computeAreaForMass,
  computeAreaFloor,
  computeAreaSlab,
  computeAreaColumn,
  computeAreaBeam,
} from "./areaFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { isMeshThrowAway, JSONToCSVConvertor } from "../modules/extrafunc.js";
import objectPropertiesView from "../modules/objectProperties/objectPropertiesView.js";

var materialScheduleView = null;
// angular.element(function () {
  materialScheduleView = (function () {
    // var $scope = angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    var structureCollection = StructureCollection.getInstance();
    var _materialScheduleData = null;
    let matSchedule = null;

    const init = () => {
      structureCollection = StructureCollection.getInstance();
    }

    var getStrucuters = function () {
      return structureCollection.getStructures();
    };

    var getLevelsInStructure = function (id) {
      let levels = getStrucuters()[id].getAllLevels();
      console.log(levels);
      return getStrucuters()[id].getAllLevels();
    };

    let _assignProjectUnits = function (unit) {
      if (store.$scope.units_type.value === "feet-inches") {
        if (unit === "Square metres") return "Sq-Ft.";
        if (unit === "Cubic metres") return "Cubic Ft.";
      } else if (store.$scope.units_type.value === "inches") {
        if (unit === "Square metres") return "Sq. Inches.";
        if (unit === "Cubic metres") return "Cubic Inches";
      } else {
        return unit;
      }
      // else if ($scope.units_type.value === "millimeter"){
      //     return "Litres";
      // }
      // else if ($scope.units_type.value === "centimeter"){
      //     return "Litres";
      // }
      // else if ($scope.units_type.value === "meters"){
      //     return "Litres";
      // }
    };

    let _findIntersectingFloors = function (roof, floors) {
      // let structures = getStrucuters();
      // let structure = structures[(Object.keys(structures)[roof.structure])];
      // let meshes = structure.getAllMeshes();
      // // let fw = storeys[Object.keys(storeys)[roof.storey]].elements;
      // // let intersectingFloors = fw.filter(f => {
      // //     if (f.type.toLowerCase() === "floor"){
      // //         return f;
      // //     }
      // });
      let intersectingFloors = [];
      if (floors.length > 0) {
        for (let t = 0; t < floors.length; t++) {
          let floor = floors[t];
          if (roof.mesh.intersectsMesh(floor.mesh)) {
            intersectingFloors.push(floor);
          }
        }
      }
      // return [];
      return intersectingFloors;
    };

    let _calculateArea = function (material, mesh, subMesh = null) {
      if (subMesh) {
        let indices = mesh.getIndices();
        let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        let sIndexData = [];
        let surfaceArea = 0;
        for (let i = 0; i < subMesh.indexCount; i += 3) {
          let px = verData[indices[subMesh.indexStart + i] * 3];
          let py = verData[indices[subMesh.indexStart + i] * 3 + 1];
          let pz = verData[indices[subMesh.indexStart + i] * 3 + 2];
          let v1 = new BABYLON.Vector3(px, py, pz);
          px = verData[indices[subMesh.indexStart + i + 1] * 3];
          py = verData[indices[subMesh.indexStart + i + 1] * 3 + 1];
          pz = verData[indices[subMesh.indexStart + i + 1] * 3 + 2];
          let v2 = new BABYLON.Vector3(px, py, pz);
          px = verData[indices[subMesh.indexStart + i + 2] * 3];
          py = verData[indices[subMesh.indexStart + i + 2] * 3 + 1];
          pz = verData[indices[subMesh.indexStart + i + 2] * 3 + 2];
          let v3 = new BABYLON.Vector3(px, py, pz);
          let area = computeAreaTriangle3D(v1, v2, v3);
          if (area) {
            surfaceArea += computeAreaTriangle3D(v1, v2, v3);
          }
          sIndexData.push(v1);
          sIndexData.push(v2);
          sIndexData.push(v3);
        }
        return parseInt(DisplayOperation.convertToDefaultArea(surfaceArea));
      } //calculate the area for material for given mesh
      return 0;
    };

    let _calculateVolume = function (material, mesh, thickness, type) {
      let volume = 0;
      if (type === "wall") {
        if (thickness) {
          volume = computeVolumeWall(mesh, thickness);
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      } else if (type === "mass") {
        volume = computeVolumeMass(mesh, thickness);
      } else if (type === "staircase") {
        if (thickness) {
          volume = computeVolumeStairsLayer(mesh, thickness);
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      } else if (type === "staircaseRiser") {
        if (thickness) {
          volume = computeVolumeStairsLayerType(mesh, thickness, "Riser");
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      } else if (type === "staircaseTread") {
        if (thickness) {
          volume = computeVolumeStairsLayerType(mesh, thickness, "Tread");
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      } else if (type === "staircaseSide") {
        if (thickness) {
          volume = computeVolumeStairsLayerType(mesh, thickness, "Side");
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      } else if (type === "floor") {
        if (thickness) {
          volume = computeVolumeFloor(mesh, thickness);
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      } else if (type === "roof") {
        if (thickness) {
          volume = computeVolumeFloor(mesh, thickness);
        } else {
          volume = computeVolumeMass(mesh, thickness);
        }
      }
      // return parseInt(volume);
      // return parseInt(Math.ceil(parseFloat(DisplayOperation.convertToDefaultVolume(volume))));
      // return Math.abs(Math.round(parseFloat(DisplayOperation.convertToDefaultVolume(volume))*100)/100);
      return parseFloat(DisplayOperation.convertToDefaultVolume(volume));
    };

    let _calculateAreaLayer = function (material, mesh, thickness, type) {
      let area = 0;
      if (type === "wall") {
        area = computeAreaWall(mesh, thickness);
      } else if (type === "mass") {
        area = computeAreaForMass(mesh, thickness);
      } else if (type === "staircase") {
        area = computeAreaForMass(mesh, thickness);
      } else if (type === "staircaseRiser") {
        area = computeVolumeStairsLayerType(mesh, thickness, "Riser", "Area");
      } else if (type === "staircaseTread") {
        area = computeVolumeStairsLayerType(mesh, thickness, "Tread", "Area");
      } else if (type === "staircaseSide") {
        area = computeVolumeStairsLayerType(mesh, thickness, "Side", "Area");
      } else if (type === "floor") {
        area = computeAreaFloor(mesh, thickness);
      } else if (type === "roof") {
        area = computeAreaFloor(mesh, thickness);
      }
      // return parseInt(volume);
      // return parseInt(Math.ceil(parseFloat(DisplayOperation.convertToDefaultArea(area))));
      return Math.abs(
        Math.round(
          parseFloat(DisplayOperation.convertToDefaultArea(area)) * 100
        ) / 100
      );
    };

    const calculateComponentParameters = (obj) => {
      let type = obj.type;
      let params;
      if (obj.type === "Roof") {
        if (obj.slabType) {
          params = objectPropertiesView.getMeshDimensions(obj.mesh);
          params.area = DisplayOperation.convertToDefaultArea(
            computeAreaSlab(obj.mesh)
          );
        }
      } else if (obj.type === "Mass") {
        if (obj.massType) {
          if (obj.massType === "Column") {
            params = objectPropertiesView.getMeshDimensions(obj.mesh);
            params.area = DisplayOperation.convertToDefaultArea(
              computeAreaColumn(obj.mesh)
            );
          } else if (obj.massType === "Beam") {
            params = objectPropertiesView.getMeshDimensions(obj.mesh);
            params.area = DisplayOperation.convertToDefaultArea(
              computeAreaBeam(obj.mesh)
            );
          } else {
            params = objectPropertiesView.getMeshDimensions(obj.mesh);
            params.area = DisplayOperation.convertToDefaultArea(
              computeAreaBeam(obj.mesh)
            );
          }
        } else {
          params = objectPropertiesView.getMeshDimensions(obj.mesh);
          params.area = DisplayOperation.convertToDefaultArea(
            computeAreaBeam(obj.mesh)
          );
        }
      } else {
        params = objectPropertiesView.getMeshDimensions(obj.mesh);
      }
      if (params) {
        if (params.depth && params.breadth) {
          if (parseFloat(params.depth) > parseFloat(params.breadth)) {
            [params.depth, params.breadth] = [params.breadth, params.depth];
          }
        }
        if (params.area){
          params.area = params.area.toFixed(store.$scope.unit_tolerance.value);
        }
      }    
      return params;
    };

    let _calculateStuds = function (material, mesh, params, factor, thickness, type) {
      let len, br;
      let studNo = 0;
  
      if (params){
        len = DisplayOperation.getOriginalDimension(params.depth);
        br = DisplayOperation.getOriginalDimension(params.breadth);
        if (parseFloat(len) < parseFloat(br)){
          let temp = br;
          br = len;
          len = temp;
        }
        let oDim = DisplayOperation.convertDimensionTo(len);
        studNo = parseFloat(oDim.inch)/factor + 1;
        if (mesh.children){
          if (mesh.children.length > 0){
            studNo += 4*mesh.children.length;
          }  
        }
      }
      return studNo;
    };    

    var generateMatSchedule = function () {
      let structures = getStrucuters();
      for (let k = 0; k < Object.keys(structures).length; k++) {
        let str_key = Object.keys(structures)[k];
        if (str_key === "default") {
          continue;
        }
        let levels = getLevelsInStructure(str_key);
        let str_object = {
          structure_key: str_key,
          structure_name: "str-" + str_key.split("_")[1],
          components: {},
        };

        for (let j = 0; j < Object.keys(levels).length; j++) {
          let level = levels[Object.keys(levels)[j]];
          let fw = level.flyweight;

          if (fw.floors.length > 0) {
            if (!str_object.components["floor"]) {
              str_object.components["floor"] = {};
            }
            for (let t = 0; t < fw.floors.length; t++) {
              let floor = fw.floors[t];
              if (isMeshThrowAway(floor.mesh)) {
                continue;
              }
              try {
                floor.setThickness();
              } catch (err) {
                console.log(err);
              }
              // If selection stack is active, the material is calculated for objects included
              // in the selection stack
              if (store.selectionStack.length > 0) {
                if (floor.mesh.state !== "on") continue;
              }

              if (!floor.properties) continue;
              let layers;
              if (floor.mesh.isAnInstance) {
                if (floor.mesh.sourceMesh.getSnaptrudeDS().properties) {
                  if (floor.mesh.sourceMesh.getSnaptrudeDS().properties) {
                    if (
                      floor.mesh.sourceMesh.getSnaptrudeDS().properties
                        ._components
                    ) {
                      layers =
                        floor.mesh.sourceMesh.getSnaptrudeDS().properties
                          ._components._layers;
                    }
                  }
                }
              } else {
                if (floor.properties) {
                  if (floor.properties._components) {
                    layers = floor.properties._components._layers;
                  }
                }
              }
              if (!layers) continue;
              for (let p = 0; p < layers.length; p++) {
                let layer = layers[p];
                let materialType = layer.value;
                let thickness = layer.thickness;
                if (layer.core) {
                  thickness = null;
                }
                let materialId = layer.subtype;
                let material = layer.value;
                let quantity = layer.quantity;
                let unit = layer.unit;
                if (!materialId) materialId = "Default";
                // if (material === "Paint") continue;
                if (quantity) {
                  if (quantity.toLowerCase() === "volume") {
                    if (str_object.components.floor[materialType]) {
                      if (
                        str_object.components.floor[materialType][materialId]
                      ) {
                        str_object.components.floor[materialType][materialId] +=
                          _calculateVolume(
                            material,
                            floor.mesh,
                            thickness,
                            "floor"
                          );
                      } else {
                        str_object.components.floor[materialType][materialId] =
                          _calculateVolume(
                            material,
                            floor.mesh,
                            thickness,
                            "floor"
                          );
                      }
                    } else {
                      str_object.components.floor[materialType] = {
                        [materialId]: _calculateVolume(
                          material,
                          floor.mesh,
                          thickness,
                          "floor"
                        ),
                      };
                      str_object.components.floor[materialType]["unit"] =
                        layer.unit;
                    }
                  }
                  if (quantity.toLowerCase() === "area") {
                    if (str_object.components.floor[materialType]) {
                      if (
                        str_object.components.floor[materialType][materialId]
                      ) {
                        str_object.components.floor[materialType][materialId] +=
                          _calculateAreaLayer(
                            material,
                            floor.mesh,
                            thickness,
                            "floor"
                          );
                      } else {
                        str_object.components.floor[materialType][materialId] =
                          _calculateAreaLayer(
                            material,
                            floor.mesh,
                            thickness,
                            "floor"
                          );
                      }
                    } else {
                      str_object.components.floor[materialType] = {
                        [materialId]: _calculateAreaLayer(
                          material,
                          floor.mesh,
                          thickness,
                          "floor"
                        ),
                      };
                      str_object.components.floor[materialType]["unit"] =
                        layer.unit;
                    }
                  }
                }
              }
            }
          }

          if (fw.walls.length > 0) {
            if (!str_object.components["wall"]) {
              str_object.components["wall"] = {};
            }
            let materialsOnWall = [];
            for (let m = 0; m < fw.walls.length; m++) {
              let wall = fw.walls[m];
              if (isMeshThrowAway(wall.mesh)) {
                continue;
              }
              try {
                wall.setThickness();
              } catch (err) {
                console.log(err);
              }
              if (store.selectionStack.length > 0) {
                if (wall.mesh.state !== "on") continue;
              }
              if (!wall.properties) continue;
              let layers;
              if (wall.mesh.isAnInstance) {
                if (wall.mesh.sourceMesh.getSnaptrudeDS().properties) {
                  if (wall.mesh.sourceMesh.getSnaptrudeDS().properties) {
                    if (
                      wall.mesh.sourceMesh.getSnaptrudeDS().properties
                        ._components
                    ) {
                      layers =
                        wall.mesh.sourceMesh.getSnaptrudeDS().properties
                          ._components._layers;
                    }
                  }
                }
              } else {
                if (wall.properties) {
                  if (wall.properties._components) {
                    layers = wall.properties._components._layers;
                  }
                }
              }
              if (!layers) continue;
              for (let p = 0; p < layers.length; p++) {
                let layer = layers[p];
                let materialType = layer.value;
                let thickness = layer.thickness;
                if (layer.core) {
                  thickness = null;
                }
                let materialId = layer.subtype;
                let material = layer.value;
                let quantity = layer.quantity;
                let unit = layer.unit;
                let objCompParams = calculateComponentParameters(wall);
                let factor = 16;
                if (!materialId) materialId = "Default";
                // if (material === "Paint") continue;
                if (quantity) {
                  if (quantity.toLowerCase() === "volume") {
                    if (str_object.components.wall[materialType]) {
                      if (
                        str_object.components.wall[materialType][materialId]
                      ) {
                        str_object.components.wall[materialType][materialId] +=
                          _calculateVolume(
                            material,
                            wall.mesh,
                            thickness,
                            "wall"
                          );
                      } else {
                        str_object.components.wall[materialType][materialId] =
                          _calculateVolume(
                            material,
                            wall.mesh,
                            thickness,
                            "wall"
                          );
                      }
                    } else {
                      str_object.components.wall[materialType] = {
                        [materialId]: _calculateVolume(
                          material,
                          wall.mesh,
                          thickness,
                          "wall"
                        ),
                      };
                      str_object.components.wall[materialType]["unit"] =
                        layer.unit;
                    }
                  }
                  if (quantity.toLowerCase() === "area") {
                    if (str_object.components.wall[materialType]) {
                      if (
                        str_object.components.wall[materialType][materialId]
                      ) {
                        str_object.components.wall[materialType][materialId] +=
                          _calculateAreaLayer(
                            material,
                            wall.mesh,
                            thickness,
                            "wall"
                          );
                      } else {
                        str_object.components.wall[materialType][materialId] =
                          _calculateAreaLayer(
                            material,
                            wall.mesh,
                            thickness,
                            "wall"
                          );
                      }
                    } else {
                      str_object.components.wall[materialType] = {
                        [materialId]: _calculateAreaLayer(
                          material,
                          wall.mesh,
                          thickness,
                          "wall"
                        ),
                      };
                      str_object.components.wall[materialType]["unit"] =
                        layer.unit;
                    }
                  }
                  if (quantity.toLowerCase() === "count") {
                    if (str_object.components.wall[materialType]) {
                      if (
                        str_object.components.wall[materialType][materialId]
                      ) {
                        str_object.components.wall[materialType][materialId] +=
                          _calculateStuds(
                            material,
                            wall.mesh,
                            objCompParams,
                            factor,
                            thickness,
                            "wall"
                          );
                      } else {
                        str_object.components.wall[materialType][materialId] =
                        _calculateStuds(
                            material,
                            wall.mesh,
                            objCompParams,
                            factor,
                            thickness,
                            "wall"
                          );
                      }
                    } else {
                      str_object.components.wall[materialType] = {
                        [materialId]: _calculateStuds(
                          material,
                          wall.mesh,
                          objCompParams,
                          factor,
                          thickness,
                          "wall"
                        ),
                      };
                      str_object.components.wall[materialType]["unit"] =
                        layer.unit;
                    }
                  }                  
                }
              }
              // console.log(materialsOnWall)
            }
            console.log(str_object);
          }

          if (fw.roofs.length > 0) {
            if (!str_object.components["roof"]) {
              str_object.components["roof"] = {};
            }
            for (let m = 0; m < fw.roofs.length; m++) {
              let roof = fw.roofs[m];
              if (isMeshThrowAway(roof.mesh)) {
                continue;
              }
              try {
                roof.setThickness();
              } catch (err) {
                console.log(err);
              }
              if (store.selectionStack.length > 0) {
                if (roof.mesh.state !== "on") continue;
              }
              if (!roof.properties) continue;
              let roofType = "roof";
              if (roof.slabType) {
                if (!str_object.components[roof.slabType]) {
                  str_object.components[roof.slabType] = {};
                }
                roofType = roof.slabType;
              }

              let coreIndex = null;
              roof.mesh.scaling.y *= 1.00005;
              let layers;
              if (roof.mesh.isAnInstance) {
                if (roof.mesh.sourceMesh.getSnaptrudeDS().properties) {
                  if (roof.mesh.sourceMesh.getSnaptrudeDS().properties) {
                    if (
                      roof.mesh.sourceMesh.getSnaptrudeDS().properties
                        ._components
                    ) {
                      layers =
                        roof.mesh.sourceMesh.getSnaptrudeDS().properties
                          ._components._layers;
                    }
                  }
                }
              } else {
                if (roof.properties) {
                  if (roof.properties._components) {
                    layers = roof.properties._components._layers;
                  }
                }
              }
              if (!layers) continue;
              let intersectingFloors = _findIntersectingFloors(roof, fw.floors);
              let intersectingWalls = _findIntersectingFloors(roof, fw.walls);
              // intersectingFloors = [];
              // intersectingWalls = [];
              for (let p = 0; p < layers.length; p++) {
                if (layers[p].core) {
                  coreIndex = p;
                  break;
                }
              }
              roof.mesh.scaling.y *= 1;
              for (let p = 0; p < layers.length; p++) {
                let layer = layers[p];
                let materialType = layer.value;
                let thickness = layer.thickness;
                if (layer.core) {
                  thickness = null;
                }
                let materialId = layer.subtype;
                let material = layer.value;
                let quantity = layer.quantity;
                let unit = layer.unit;
                if (!materialId) materialId = "Default";
                // if (material === "Paint") continue;
                if (quantity) {
                  if (quantity.toLowerCase() === "volume") {
                    if (str_object.components[roofType][materialType]) {
                      if (
                        str_object.components[roofType][materialType][
                          materialId
                        ]
                      ) {
                        str_object.components[roofType][materialType][
                          materialId
                        ] += _calculateVolume(
                          material,
                          roof.mesh,
                          thickness,
                          "roof"
                        );
                        // Subtract the floor volume/area for all intersecting floors with the current roof to calculate the BOM accurately
                        let tempVolume = 0;
                        if (p < coreIndex) {
                          for (let q = 0; q < intersectingFloors.length; q++) {
                            tempVolume += _calculateVolume(
                              material,
                              intersectingFloors[q].mesh,
                              thickness,
                              "floor"
                            );
                            // str_object.components.roof[materialType][materialId] -= vol;
                          }
                          // Subtract the walls area which are above the roof for the layers exposed externally
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y >
                              roof.mesh.position.y
                            ) {
                              tempVolume += _calculateVolume(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                        // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        if (p > coreIndex) {
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y <
                              roof.mesh.position.y
                            ) {
                              tempVolume += _calculateVolume(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                        if (
                          str_object.components[roofType][materialType][
                            materialId
                          ] > tempVolume
                        ) {
                          str_object.components[roofType][materialType][
                            materialId
                          ] -= tempVolume;
                        } else {
                          str_object.components[roofType][materialType][
                            materialId
                          ] = 0;
                        }
                      } else {
                        str_object.components[roofType][materialType][
                          materialId
                        ] = _calculateVolume(
                          material,
                          roof.mesh,
                          thickness,
                          "roof"
                        );
                        let tempVolume = 0;
                        if (p < coreIndex) {
                          for (let q = 0; q < intersectingFloors.length; q++) {
                            tempVolume += _calculateVolume(
                              material,
                              intersectingFloors[q].mesh,
                              thickness,
                              "floor"
                            );
                            // console.log("here", roof.mesh.uniqueId, layer, vol);
                            // str_object.components.roof[materialType][materialId] -= vol;
                          }
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y >
                              roof.mesh.position.y
                            ) {
                              tempVolume += _calculateVolume(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                        // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        if (p > coreIndex) {
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y <
                              roof.mesh.position.y
                            ) {
                              tempVolume += _calculateVolume(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                        if (
                          str_object.components[roofType][materialType][
                            materialId
                          ] > tempVolume
                        ) {
                          str_object.components[roofType][materialType][
                            materialId
                          ] -= tempVolume;
                        } else {
                          str_object.components[roofType][materialType][
                            materialId
                          ] = 0;
                        }
                      }
                    } else {
                      str_object.components[roofType][materialType] = {
                        [materialId]: _calculateVolume(
                          material,
                          roof.mesh,
                          thickness,
                          "roof"
                        ),
                      };
                      str_object.components[roofType][materialType]["unit"] =
                        layer.unit;
                      let tempVolume = 0;
                      if (p < coreIndex) {
                        for (let q = 0; q < intersectingFloors.length; q++) {
                          tempVolume += _calculateVolume(
                            material,
                            intersectingFloors[q].mesh,
                            thickness,
                            "floor"
                          );
                          // console.log("here", roof.mesh.uniqueId, layer, vol);
                          // str_object.components.roof[materialType][materialId] -= vol;
                        }
                        for (let q = 0; q < intersectingWalls.length; q++) {
                          if (
                            intersectingWalls[q].mesh.position.y >
                            roof.mesh.position.y
                          ) {
                            tempVolume += _calculateVolume(
                              material,
                              intersectingWalls[q].mesh,
                              thickness,
                              "roof"
                            );
                          }
                        }
                      }
                      // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                      if (p > coreIndex) {
                        for (let q = 0; q < intersectingWalls.length; q++) {
                          if (
                            intersectingWalls[q].mesh.position.y <
                            roof.mesh.position.y
                          ) {
                            tempVolume += _calculateVolume(
                              material,
                              intersectingWalls[q].mesh,
                              thickness,
                              "roof"
                            );
                          }
                        }
                      }
                      if (
                        str_object.components[roofType][materialType][
                          materialId
                        ] > tempVolume
                      ) {
                        str_object.components[roofType][materialType][
                          materialId
                        ] -= tempVolume;
                      } else {
                        str_object.components[roofType][materialType][
                          materialId
                        ] = 0;
                      }
                    }
                  } else if (quantity.toLowerCase() === "area") {
                    if (str_object.components[roofType][materialType]) {
                      if (
                        str_object.components[roofType][materialType][
                          materialId
                        ]
                      ) {
                        str_object.components[roofType][materialType][
                          materialId
                        ] += _calculateAreaLayer(
                          material,
                          roof.mesh,
                          thickness,
                          "roof"
                        );
                        if (p < coreIndex) {
                          for (let q = 0; q < intersectingFloors.length; q++) {
                            str_object.components[roofType][materialType][
                              materialId
                            ] -= _calculateAreaLayer(
                              material,
                              intersectingFloors[q].mesh,
                              thickness,
                              "roof"
                            );
                          }
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y >
                              roof.mesh.position.y
                            ) {
                              str_object.components[roofType][materialType][
                                materialId
                              ] -= _calculateAreaLayer(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                        // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        if (p > coreIndex) {
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y <
                              roof.mesh.position.y
                            ) {
                              str_object.components[roofType][materialType][
                                materialId
                              ] -= _calculateAreaLayer(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                      } else {
                        str_object.components[roofType][materialType][
                          materialId
                        ] = _calculateAreaLayer(
                          material,
                          roof.mesh,
                          thickness,
                          "roof"
                        );
                        if (p < coreIndex) {
                          for (let q = 0; q < intersectingFloors.length; q++) {
                            str_object.components[roofType][materialType][
                              materialId
                            ] -= _calculateAreaLayer(
                              material,
                              intersectingFloors[q].mesh,
                              thickness,
                              "roof"
                            );
                            if (
                              intersectingFloors[q].mesh.position.y >
                              roof.mesh.position.y
                            ) {
                              str_object.components[roofType][materialType][
                                materialId
                              ] -= _calculateAreaLayer(
                                material,
                                intersectingFloors[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                        // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        if (p > coreIndex) {
                          for (let q = 0; q < intersectingWalls.length; q++) {
                            if (
                              intersectingWalls[q].mesh.position.y <
                              roof.mesh.position.y
                            ) {
                              str_object.components[roofType][materialType][
                                materialId
                              ] -= _calculateAreaLayer(
                                material,
                                intersectingWalls[q].mesh,
                                thickness,
                                "roof"
                              );
                            }
                          }
                        }
                      }
                    } else {
                      str_object.components[roofType][materialType] = {
                        [materialId]: _calculateAreaLayer(
                          material,
                          roof.mesh,
                          thickness,
                          "roof"
                        ),
                      };
                      str_object.components[roofType][materialType]["unit"] =
                        layer.unit;
                      if (p < coreIndex) {
                        for (let q = 0; q < intersectingFloors.length; q++) {
                          str_object.components[roofType][materialType][
                            materialId
                          ] -= _calculateAreaLayer(
                            material,
                            intersectingFloors[q].mesh,
                            thickness,
                            "roof"
                          );
                          if (
                            intersectingFloors[q].mesh.position.y >
                            roof.mesh.position.y
                          ) {
                            str_object.components[roofType][materialType][
                              materialId
                            ] -= _calculateAreaLayer(
                              material,
                              intersectingFloors[q].mesh,
                              thickness,
                              "roof"
                            );
                          }
                        }
                      }
                      // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                      if (p > coreIndex) {
                        for (let q = 0; q < intersectingWalls.length; q++) {
                          if (
                            intersectingWalls[q].mesh.position.y <
                            roof.mesh.position.y
                          ) {
                            str_object.components[roofType][materialType][
                              materialId
                            ] -= _calculateAreaLayer(
                              material,
                              intersectingWalls[q].mesh,
                              thickness,
                              "roof"
                            );
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          if (fw.staircases.length > 0) {
            if (!str_object.components["staircase"]) {
              str_object.components["staircase"] = {};
            }
            for (let m = 0; m < fw.staircases.length; m++) {
              let staircase = fw.staircases[m];
              if (isMeshThrowAway(staircase.mesh)) {
                continue;
              }
              try {
                staircase.setThickness();
              } catch (err) {
                console.log(err);
              }
              if (store.selectionStack.length > 0) {
                if (staircase.mesh.state !== "on") continue;
              }
              if (!staircase.properties) continue;
              let coreIndex = null;
              let intersectingFloors = _findIntersectingFloors(
                staircase,
                fw.floors
              );
              let intersectingRoofs = _findIntersectingFloors(
                staircase,
                fw.roofs
              );
              let intersectingWalls = _findIntersectingFloors(
                staircase,
                fw.walls
              );
              let layers;
              if (staircase.mesh.isAnInstance) {
                layers =
                  staircase.mesh.sourceMesh.getSnaptrudeDS().properties
                    ._components._layers;
              } else {
                layers = staircase.properties._components._layers;
              }
              for (let p = 0; p < layers.length; p++) {
                if (layers[p].core) {
                  coreIndex = p;
                  break;
                }
              }
              for (let p = 0; p < layers.length; p++) {
                let layer = layers[p];
                let materialType = layer.value;
                let thickness = layer.thickness;
                if (layer.core) {
                  thickness = null;
                }
                let propName = "";
                if (layer.stairProp) {
                  propName = layer.stairProp;
                }
                let materialId = layer.subtype;
                let material = layer.value;
                let quantity = layer.quantity;
                let unit = layer.unit;
                if (!materialId) materialId = "Default";

                // if (material === "Paint") continue;
                if (quantity) {
                  if (quantity.toLowerCase() === "volume") {
                    if (str_object.components.staircase[materialType]) {
                      if (
                        str_object.components.staircase[materialType][
                          materialId
                        ]
                      ) {
                        str_object.components.staircase[materialType][
                          materialId
                        ] += _calculateVolume(
                          material,
                          staircase.mesh,
                          thickness,
                          "staircase" + propName
                        );
                        // // Subtract the floor volume/area for all intersecting floors with the current roof to calculate the BOM accurately
                        // if ( p < coreIndex ){
                        //     for (let q=0; q<intersectingFloors.length; q++){
                        //         let vol = _calculateVolume(material, intersectingFloors[q].mesh, thickness, "floor");
                        //         console.log("here", staircase.mesh.uniqueId, layer, vol);
                        //         str_object.components.staircase[materialType][materialId] -= vol;
                        //     }
                        //     // Subtract the walls area which are above the roof for the layers exposed externally
                        //     for (let q=0; q<intersectingWalls.length; q++){
                        //         if (intersectingWalls[q].mesh.position.y > staircase.mesh.position.y){
                        //             str_object.components.staircase[materialType][materialId] -= _calculateVolume(material, intersectingWalls[q].mesh, thickness, "roof");
                        //         }
                        //     }
                        // }
                        // // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        // if ( p > coreIndex ){
                        //     for (let q=0; q<intersectingWalls.length; q++){
                        //         if (intersectingWalls[q].mesh.position.y < staircase.mesh.position.y) {
                        //             d.components.staircase[materialType][materialId] -= _calculateVolume(material, intersectingWalls[q].mesh, thickness, "roof");
                        //         }
                        //     }
                        // }
                      } else {
                        str_object.components.staircase[materialType][
                          materialId
                        ] = _calculateVolume(
                          material,
                          staircase.mesh,
                          thickness,
                          "staircase" + propName
                        );
                        // if ( p < coreIndex ){
                        //     for (let q=0; q<intersectingFloors.length; q++){
                        //         let vol = _calculateVolume(material, intersectingFloors[q].mesh, thickness, "floor");
                        //         str_object.components.staircase[materialType][materialId] -= vol;
                        //     }
                        //     for (let q=0; q<intersectingWalls.length; q++){
                        //         if (intersectingWalls[q].mesh.position.y > staircase.mesh.position.y){
                        //             str_object.components.staircase[materialType][materialId] -= _calculateVolume(material, intersectingWalls[q].mesh, thickness, "roof");
                        //         }
                        //     }
                        // }
                        // // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        // if ( p > coreIndex ){
                        //     for (let q=0; q<intersectingWalls.length; q++){
                        //         if (intersectingWalls[q].mesh.position.y < roof.mesh.position.y){
                        //             str_object.components.staircase[materialType][materialId] -= _calculateVolume(material, intersectingWalls[q].mesh, thickness, "roof");
                        //         }
                        //     }
                        // }
                      }
                    } else {
                      str_object.components.staircase[materialType] = {
                        [materialId]: _calculateVolume(
                          material,
                          staircase.mesh,
                          thickness,
                          "staircase" + propName
                        ),
                      };
                      str_object.components.staircase[materialType]["unit"] =
                        layer.unit;
                      // if ( p < coreIndex ){
                      //     for (let q=0; q<intersectingFloors.length; q++){
                      //         let vol = _calculateVolume(material, intersectingFloors[q].mesh, thickness, "floor");
                      //         str_object.components.staircase[materialType][materialId] -= vol;
                      //     }
                      //     for (let q=0; q<intersectingWalls.length; q++){
                      //         if (intersectingWalls[q].mesh.position.y > staircase.mesh.position.y){
                      //             str_object.components.staircase[materialType][materialId] -= _calculateVolume(material, intersectingWalls[q].mesh, thickness, "roof");
                      //         }
                      //     }
                      // }
                      // // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                      // if ( p > coreIndex ){
                      //     for (let q=0; q<intersectingWalls.length; q++){
                      //         if (intersectingWalls[q].mesh.position.y < staircase.mesh.position.y){
                      //             str_object.components.staircase[materialType][materialId] -= _calculateVolume(material, intersectingWalls[q].mesh, thickness, "roof");
                      //         }
                      //     }
                      // }
                    }
                  } else if (quantity.toLowerCase() === "area") {
                    if (str_object.components.staircase[materialType]) {
                      if (
                        str_object.components.staircase[materialType][
                          materialId
                        ]
                      ) {
                        str_object.components.staircase[materialType][
                          materialId
                        ] += _calculateAreaLayer(
                          material,
                          staircase.mesh,
                          thickness,
                          "staircase" + propName
                        );
                        // if ( p < coreIndex ){
                        //     for (let q=0; q<intersectingFloors.length; q++){
                        //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingFloors[q].mesh, thickness, "roof");
                        //     }
                        //     for (let q=0; q<intersectingWalls.length; q++){
                        //         if (intersectingWalls[q].mesh.position.y > staircase.mesh.position.y) {
                        //             str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingWalls[q].mesh, thickness, "roof");
                        //         }
                        //     }
                        // }
                        // // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        // if ( p > coreIndex ){
                        //         if (intersectingWalls[q].mesh.position.y < staircase.mesh.position.y) {
                        //             str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingWalls[q].mesh, thickness, "roof");
                        //         }
                        // }
                      } else {
                        str_object.components.staircase[materialType][
                          materialId
                        ] = _calculateAreaLayer(
                          material,
                          staircase.mesh,
                          thickness,
                          "staircase" + propName
                        );
                        // if ( p < coreIndex ){
                        //     for (let q=0; q<intersectingFloors.length; q++){
                        //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingFloors[q].mesh, thickness, "roof");
                        //     }
                        //     if (intersectingWalls[q].mesh.position.y > staircase.mesh.position.y) {
                        //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingWalls[q].mesh, thickness, "roof");
                        //     }
                        // }
                        // // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                        // if ( p > coreIndex ){
                        //     if (intersectingWalls[q].mesh.position.y < staircase.mesh.position.y) {
                        //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingWalls[q].mesh, thickness, "roof");
                        //     }
                        // }
                      }
                    } else {
                      str_object.components.staircase[materialType] = {
                        [materialId]: _calculateAreaLayer(
                          material,
                          staircase.mesh,
                          thickness,
                          "staircase" + propName
                        ),
                      };
                      str_object.components.staircase[materialType]["unit"] =
                        layer.unit;
                      // if ( p < coreIndex ){
                      //     for (let q=0; q<intersectingFloors.length; q++){
                      //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingFloors[q].mesh, thickness, "roof");
                      //     }
                      //     if (intersectingWalls[q].mesh.position.y > staircase.mesh.position.y) {
                      //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingWalls[q].mesh, thickness, "roof");
                      //     }
                      // }
                      // // Subtract the floor volume/area for all intersecting walls below the current roof to calculate the BOM accurately
                      // if ( p > coreIndex ){
                      //     if (intersectingWalls[q].mesh.position.y < staircase.mesh.position.y) {
                      //         str_object.components.staircase[materialType][materialId] -= _calculateAreaLayer(material, intersectingWalls[q].mesh, thickness, "roof");
                      //     }
                      // }
                    }
                  }
                }
              }
            }
          }

          if (fw.masses.length > 0) {
            if (!str_object.components["mass"]) {
              str_object.components["mass"] = {};
            }
            for (let m = 0; m < fw.masses.length; m++) {
              let mass = fw.masses[m];
              if(mass.mesh && (mass.mesh.name.includes("terrain") || mass.massType === "Building")){
                continue;
              }
              if (isMeshThrowAway(mass.mesh)) {
                continue;
              }
              if (!mass.mesh.visibility) continue;
              if (mass.massType === "Room") continue;
              if (store.selectionStack.length > 0) {
                if (mass.mesh.state !== "on") continue;
              }

              if (mass.massType !== "Room") {
                if (!str_object.components[mass.massType]) {
                  str_object.components[mass.massType] = {};
                }
              }
              for (let s = 0; s < mass.mesh.subMeshes.length; s++) {
                if (mass.mesh.material.subMaterials) {
                  let material =
                    mass.mesh.material.subMaterials[
                      mass.mesh.subMeshes[s].materialIndex
                    ];
                  if (str_object.components.mass[material.materialType]) {
                    if (
                      str_object.components.mass[material.materialType][
                        material.id
                      ]
                    ) {
                      // str_object.components.roof[material.materialType][material.id] += _calculateArea(material, roof.mesh,roof.mesh.subMeshes[s]);
                    } else {
                      // str_object.components.roof[material.materialType][material.id] = _calculateArea(material, roof.mesh,roof.mesh.subMeshes[s]);
                    }
                  } else {
                    // str_object.components.roof[material.materialType] = {[material.id]: _calculateArea(material, roof.mesh,roof.mesh.subMeshes[s])};
                    // str_object.components.roof[material.materialType]["unit"] = "sq m";
                  }
                } else {
                  let material = mass.mesh.material;
                  if (str_object.components.mass[material.materialType]) {
                    if (
                      str_object.components.mass[material.materialType][
                        material.id
                      ]
                    ) {
                      // str_object.components.roof[material.materialType][material.id] += _calculateArea(material, roof.mesh, roof.mesh.subMeshes[s]);
                    } else {
                      // str_object.components.roof[material.materialType][material.id] = _calculateArea(material, roof.mesh, roof.mesh.subMeshes[s]);
                    }
                  } else {
                    // str_object.components.roof[material.materialType] = {[material.id]: _calculateArea(material, roof.mesh, roof.mesh.subMeshes[s])};
                    // str_object.components.roof[material.materialType]["unit"] = "sq m";
                  }
                }
              }
              if (!mass.properties) continue;
              let layers;
              if (mass.mesh.isAnInstance) {
                try {
                  layers =
                    mass.mesh.sourceMesh.getSnaptrudeDS().properties._components
                      ._layers;
                } catch (err) {
                  continue;
                }
              } else {
                if (mass.properties) {
                  if (mass.properties._components) {
                    layers = mass.properties._components._layers;
                  }
                }
              }
              if (!layers) continue;
              for (let p = 0; p < layers.length; p++) {
                let layer = layers[p];
                let materialType = layer.value;
                let thickness = layer.thickness;
                let materialId = mass.massType;
                let material = layer.value;
                let quantity = layer.quantity;
                let unit = layer.unit;
                if (!materialId) materialId = "mass";
                // if (material === "Paint") continue;
                if (quantity) {
                  if (quantity.toLowerCase() === "volume") {
                    if (str_object.components[materialId][materialType]) {
                      if (
                        str_object.components[materialId][materialType][
                          materialId
                        ]
                      ) {
                        str_object.components[materialId][materialType][
                          materialId
                        ] += _calculateVolume(
                          material,
                          mass.mesh,
                          thickness,
                          "mass"
                        );
                      } else {
                        str_object.components[materialId][materialType][
                          materialId
                        ] = _calculateVolume(
                          material,
                          mass.mesh,
                          thickness,
                          "mass"
                        );
                        // str_object.components.roof[materialType]["unit"] = "cubic m";
                      }
                    } else {
                      str_object.components[materialId][materialType] = {
                        [materialId]: _calculateVolume(
                          material,
                          mass.mesh,
                          thickness,
                          "mass"
                        ),
                      };
                      str_object.components[materialId][materialType]["unit"] =
                        layer.unit;
                    }
                  } else if (quantity.toLowerCase() === "area") {
                    if (str_object.components[materialId][materialType]) {
                      if (
                        str_object.components[materialId][materialType][
                          materialId
                        ]
                      ) {
                        str_object.components[materialId][materialType][
                          materialId
                        ] += _calculateAreaLayer(
                          material,
                          mass.mesh,
                          thickness,
                          "mass"
                        );
                      } else {
                        str_object.components[materialId][materialType][
                          materialId
                        ] = _calculateAreaLayer(
                          material,
                          mass.mesh,
                          thickness,
                          "mass"
                        );
                        // str_object.components.roof[materialType]["unit"] = "cubic m";
                      }
                    } else {
                      str_object.components[materialId][materialType] = {
                        [materialId]: _calculateAreaLayer(
                          material,
                          mass.mesh,
                          thickness,
                          "mass"
                        ),
                      };
                      str_object.components[materialId][materialType]["unit"] =
                        layer.unit;
                    }
                  }
                }
              }
              console.log(str_object);
            }
          }

          if (fw.doors.length > 0) {
            if (!str_object.components["door"]) {
              str_object.components["door"] = {};
            }
            for (let m = 0; m < fw.doors.length; m++) {
              let door = fw.doors[m];
              if (isMeshThrowAway(door.mesh)) {
                continue;
              }
              if (door.mesh) {
                if (
                  door.mesh.scaling.x == 0 ||
                  door.mesh.scaling.y == 0 ||
                  door.mesh.scaling.z == 0
                ) {
                  continue;
                }
              }
              if (store.selectionStack.length > 0) {
                if (door.mesh.state !== "on") continue;
              }
              let doorType = door.type;
              let doorMaterial = "";
              if (door.mesh.sourceMesh) {
                doorType = door.mesh.sourceMesh.getSnaptrudeDS().subType;
              } else {
                doorType = door.mesh.getSnaptrudeDS().subType;
              }
              let doorDims = objectPropertiesView.getMeshDimensions(door.mesh);
              doorMaterial =
                doorDims["depth"] +
                "x" +
                doorDims["breadth"] +
                "x" +
                doorDims["height"];

              if (!doorType) {
                doorType = "Single";
              }
              if (!doorMaterial) {
                doorMaterial = "Default";
              }
              if (str_object.components.door[doorType]) {
                if (str_object.components.door[doorType][doorMaterial]) {
                  str_object.components.door[doorType][doorMaterial] += 1;
                } else {
                  str_object.components.door[doorType][doorMaterial] = 1;
                }
              } else {
                str_object.components.door[doorType] = { [doorMaterial]: 1 };
                str_object.components.door[doorType]["unit"] = "Quantity";
              }
            }
          }

          if (fw.windows.length > 0) {
            if (!str_object.components["window"]) {
              str_object.components["window"] = {};
            }
            for (let m = 0; m < fw.windows.length; m++) {
              let window = fw.windows[m];
              if (isMeshThrowAway(window.mesh)) {
                continue;
              }
              if (window.mesh) {
                if (
                  window.mesh.scaling.x == 0 ||
                  window.mesh.scaling.y == 0 ||
                  window.mesh.scaling.z == 0
                ) {
                  continue;
                }
              }
              if (store.selectionStack.length > 0) {
                if (window.mesh.state !== "on") continue;
              }

              let windowType = window.type;
              if (window.mesh.sourceMesh) {
                windowType = window.mesh.sourceMesh.getSnaptrudeDS().subType;
              } else {
                windowType = window.mesh.getSnaptrudeDS().subType;
              }
              let windowDims = objectPropertiesView.getMeshDimensions(
                window.mesh
              );
              let windowMaterial =
                windowDims["depth"] +
                "x" +
                windowDims["breadth"] +
                "x" +
                windowDims["height"];

              if (!windowType) {
                windowType = "Single";
              }
              if (!windowMaterial) {
                windowMaterial = "Default";
              }
              if (str_object.components.window[windowType]) {
                if (str_object.components.window[windowType][windowMaterial]) {
                  str_object.components.window[windowType][windowMaterial] += 1;
                } else {
                  str_object.components.window[windowType][windowMaterial] = 1;
                }
              } else {
                str_object.components.window[windowType] = {
                  [windowMaterial]: 1,
                };
                str_object.components.window[windowType]["unit"] = "Quantity";
              }
            }
          }

          if (fw.furnitures.length > 0) {
            if (!str_object.components["furniture"]) {
              str_object.components["furniture"] = {};
            }
            for (let m = 0; m < fw.furnitures.length; m++) {
              let furniture = fw.furnitures[m];
              if (isMeshThrowAway(furniture.mesh)) {
                continue;
              }
              if (furniture.mesh) {
                if (
                  furniture.mesh.scaling.x == 0 ||
                  furniture.mesh.scaling.y == 0 ||
                  furniture.mesh.scaling.z == 0
                ) {
                  continue;
                }
              }
              if (store.selectionStack.length > 0) {
                if (furniture.mesh.state !== "on") continue;
              }

              let furnitureType = furniture.mesh.name;
              if (furniture.mesh.sourceMesh) {
                furnitureType = furniture.mesh.sourceMesh.name;
              }
              let furnitureMaterial = furniture.mesh.name;
              if(furniture.revitMetaData?.type){
                furnitureType = furniture.revitMetaData?.type
                furnitureMaterial = furnitureType
              }
              let furnDims = objectPropertiesView.getMeshDimensions(
                furniture.mesh
              );
              furnitureMaterial =
                furnDims["depth"] +
                "x" +
                furnDims["breadth"] +
                "x" +
                furnDims["height"];

              if (!furnitureType) {
                furnitureType = "Default";
              }
              if (!furnitureMaterial) {
                furnitureMaterial = "Default";
              }
              if (str_object.components.furniture[furnitureType]) {
                if (
                  str_object.components.furniture[furnitureType][
                    furnitureMaterial
                  ]
                ) {
                  str_object.components.furniture[furnitureType][
                    furnitureMaterial
                  ] += 1;
                } else {
                  str_object.components.furniture[furnitureType][
                    furnitureMaterial
                  ] = 1;
                }
              } else {
                str_object.components.furniture[furnitureType] = {
                  [furnitureMaterial]: 1,
                };
                str_object.components.furniture[furnitureType]["unit"] =
                  "Quantity";
              }
            }
          }
        }
        _materialScheduleData = str_object;
      }
      let data = {};
      let component_obj = {};
      let components = _materialScheduleData ? _materialScheduleData.components : {};
      for (let i = 0; i < Object.keys(components).length; i++) {
        let comp_key = Object.keys(components)[i];
        let component = components[comp_key];
        for (let j = 0; j < Object.keys(component).length; j++) {
          let material_key = Object.keys(component)[j];
          let mat_sub_types = component[material_key];
          for (let k = 0; k < Object.keys(mat_sub_types).length; k++) {
            let mat_sub_type_key = Object.keys(mat_sub_types)[k];
            if (mat_sub_type_key === "unit") continue;
            let mat_sub_type = mat_sub_types[mat_sub_type_key];
            let unit = mat_sub_types["unit"];
            unit = _assignProjectUnits(unit);
            if (mat_sub_type) {
              if (typeof mat_sub_type === "number") {
                mat_sub_type = Math.round(mat_sub_type * 100) / 100;
              }
            }
            if (component_obj[comp_key]) {
              component_obj[comp_key].push({
                component: comp_key,
                material: material_key,
                material_type: mat_sub_type_key,
                quantity: mat_sub_type,
                unit: unit,
              });
            } else {
              component_obj[comp_key] = [
                {
                  component: comp_key,
                  material: material_key,
                  material_type: mat_sub_type_key,
                  quantity: mat_sub_type,
                  unit: unit,
                },
              ];
            }
          }
        }
      }

      store.$scope.materialScheduleData = component_obj;
      _materialScheduleData = component_obj;
      console.log("data-------", component_obj);

      // setTimeout(function () {
      //   $scope.$apply(function () {
          store.$scope.materialScheduleData = component_obj;
          if (
            !Object.keys(components).includes("wall") &&
            Object.keys(components).includes("mass")
          ) {
            if (Object.keys(components.mass).length === 0) {
              // document.getElementById("materialScheduleModal").click();
              // userpilot.trigger("1606387049uZxj8656");
            }
          }
      //   });
      // });
      return _materialScheduleData;
    };

    let exportCSV = function () {
      // let data = _.flatMapDeep(Object.values(_materialScheduleData));
      let data = [];
      generateMatSchedule();
      let keys = Object.keys(_materialScheduleData);
      for (let i = 0; i < keys.length; i++) {
        let comp = _materialScheduleData[keys[i]];
        data.push({
          Component: keys[i].toUpperCase(),
          Material: "",
          "Material Type": "",
          Quantity: "",
          Unit: "",
        });
        for (let j = 0; j < comp.length; j++) {
          data.push({
            Component: "",
            Material: comp[j]["material"],
            "Material Type": comp[j]["material_type"],
            Quantity: comp[j]["quantity"],
            Unit: comp[j]["unit"],
          });
        }
      }

      JSONToCSVConvertor(data, "Materials", true);

      // _materialScheduleData.forEach(mat =>{
      //     if(mat.floor){
      //         mat.floor.forEach(floor =>{
      //             data.push(floor);
      //         })
      //     }
      //
      //     if(mat.roof){
      //
      //     }
      //
      //     if(mat.wall){
      //
      //     }
      //
      // })
    };

    return {
      getStrucuters: function () {
        return getStrucuters();
      },

      generateMatSchedule: function () {
        return generateMatSchedule();
      },
      exportCSV: function () {
        return exportCSV();
      },
      init,
      materialScheduleData: _materialScheduleData,
    };
  })();
// });
export { materialScheduleView };
