// icons imports
import HeatingIcon from '../../../../assets/icons/daylight_analysis/heating.svg'
import CoolingIcon from '../../../../assets/icons/daylight_analysis/cooling.svg'
import LightingIcon from '../../../../assets/icons/daylight_analysis/lighting.svg'
import HotWaterIcon from '../../../../assets/icons/daylight_analysis/hot_water.svg'
import EquipmentsIcon from '../../../../assets/icons/daylight_analysis/equipments.svg'
import FanIcon from '../../../../assets/icons/daylight_analysis/fan.svg'
import PumpIcon from '../../../../assets/icons/daylight_analysis/pump.svg'

export const EuiIconsMapping = {
  "Heating": HeatingIcon,
  "Cooling": CoolingIcon,
  "Lighting": LightingIcon,
  "Hot Water": HotWaterIcon,
  "Equipment": EquipmentsIcon,
  "Fans": FanIcon,
  "Pumps": PumpIcon,
}

export const ColorsMapping = {
  "Heating": "#EB1E45",
  "Cooling": "#1B3A97",
  "Lighting": "#FFCF55",
  "Hot Water": "#F47E5F",
  "Equipment": "#818181",
  "Fans": "#B3D001",
  "Pumps": "#4F4F4F",
}

export const SDA_RANGE_VALUES = ['0%', '10%', '30%', '50%', '70%', '90%', '100%']
export const ASE_RANGE_VALUES = ['0', '250', '500', '750', '1000+']
