import { useEffect, useState, useRef } from "react";

const BOUND_HEIGHT = 100;

function getScrollDirection({
  position,
  upperBounds = Infinity,
  lowerBounds = -Infinity,
  boundHeight = BOUND_HEIGHT
}) {
  if (position === undefined) {
    return "stable";
  }
  if (position > lowerBounds - boundHeight) {
    return "bottom";
  }
  if (position < upperBounds + boundHeight) {
    return "top";
  }
  return "stable";
}

export const useScroll = (ref, boundHeight) => {
  const [config, setConfig] = useState({
    position: 0,
    isScrollAllowed: false
  });

  const scrollTimer = useRef(null);

  const scrollSpeed = 1;
  const { position, isScrollAllowed } = config;

  const bounds = ref.current?.getBoundingClientRect();
  const direction = getScrollDirection({
    position,
    upperBounds: bounds?.top,
    lowerBounds: bounds?.bottom,
    boundHeight
  });

  useEffect(() => {
    if (direction !== "stable" && isScrollAllowed) {
      scrollTimer.current = setInterval(() => {
        if(ref.current?.scrollBy) {
          ref.current.scrollBy(0, scrollSpeed * (direction === "top" ? -1 : 1));
        }
      }, 1);
    }

    return () => {
      if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
      }
    };
  }, [isScrollAllowed, direction, ref, scrollSpeed]);

  return { updatePosition: setConfig };
};
