import { colors } from "../../../themes/constant";

export const upgradeStyles = {
  button: {
    border: `2px solid ${colors.red}`,
    background: colors.transparent,
  },
  buttonText: {
    color: `${colors.brightRed}`,
  },
};
