/**
 * Experimental util - Do not use if you found this in the wild
 *
 * When multiple functions have to share some information but there's no pipeline for it,
 * and you don't want to pollute the global space, use the shelf.
 * Meant to be used only for flow based tasks.
 * Example - A long operation like create building.
 * If stage 1 of the process needs to pass something on to the stage 4, use the shelf.
 * Critically bound to time of usage.
 *
 * Any attempts to retrieve objects from the shelf, without explicit awareness of placing it there
 * and time of placing it will break your code.
 *
 * Do not use the shelf for use cases where you 'might' need something in the future,
 * only for the things you will definitely need, and when their usefulness is short lived.
 *
 *
 * Usefulness yet to be determined
 * @type {{retrieve: (function(): *), place: place}}
 */

const shelf = (function () {
  let thingOnShelf;

  const place = function (thing) {
    thingOnShelf = thing;
  };

  const retrieve = function () {
    return thingOnShelf;
  };

  return {
    place,
    retrieve,
  };
})();
export { shelf };
