import _ from "lodash";

const brepDiagnostics = (function (){
  
  const edgesHalfEdgesDiff = function (component){
    const brep = component.brep;
    
    const HEs = brep.getHalfEdges();
    const HEsFromEdges = _.flatten(
      brep
        .getEdges()
        .map((e) => [e.getHalfEdge(), e.getHalfEdge().getFlipHalfEdge()])
    );
    
    console.log(_.difference(HEs, HEsFromEdges));
    console.log(_.difference(HEsFromEdges, HEs));
  };
  
  return {
    edgesHalfEdgesDiff,
  };
  
})();

export default brepDiagnostics;
