import React, { useRef } from 'react';
import styled from "styled-components";
import useOutsideClickHandler from '../../common/utils/useOutsideClickHandler';

const Wrapper = styled.div`
    z-index: 50;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 200px;
    padding: 10px 0px;
    position: fixed;
    left: ${props => props.x}px;
    top: ${props => props.y}px;
    font-weight: 400;
    font-size: 12.5px;
    color: #202124;
    .menu-item{
        padding: 3px 25px 3px 25px;
        color: black;
        // margin: 4px 0px;
        cursor: pointer;
    }
    .menu-item:hover{
        background-color: #F2F2F2;
    }
`
function LibraryContextMenu({x, y, handleClose, ...props}) {
    const ref = useRef(null);
    useOutsideClickHandler(ref, handleClose);
    return ( 
        <Wrapper x={x} y={y} ref={ref} {...props} />
     );
}

export default LibraryContextMenu;