import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../themes/constant";

const StyledToggle = styled.div`
  width: 1.875rem;
  height: 1rem;
  border-radius: 1.6875rem;
  margin: ${props => props.margin ? props.margin : "0 0.75rem"};
  cursor: pointer;
  transition: 0.4s;
  display: inline-block;
  transform: scale(0.8);
  &.on{
    outline: 2px solid ${(props) => props.theme.colors.brightRed};
  }
  &.off{
    outline: 2px solid ${(props) => props.theme.colors.darkGrey};
  }
  &.unEditable{
    outline: 2px solid ${(props) => props.theme.colors.secondaryGrey};
    pointer-events: none;

  }

`
const StyledRound = styled.div`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  transition: 0.4s;
  &.on{
    background-color: ${(props) => props.theme.colors.brightRed};
  }
  &.off{
    background-color: ${(props) => props.theme.colors.darkGrey};
  }
  &.unEditable{
    background-color: ${(props) => props.theme.colors.secondaryGrey};
    pointer-events: none;
  }
`

function Toggle({ handleClick, state, labelMargin, unEditable = false }) {
  const handleToggle = () => {
    if(unEditable) return;
    handleClick();
  }
  return (
    <StyledToggle unEditable={unEditable} onClick={handleToggle} margin={labelMargin} className={`${state ? 'on' : 'off'} ${unEditable ? 'unEditable' : ''}`} >
      <StyledRound className={`${state ? 'on' : 'off'} ${unEditable ? 'unEditable' : ''}`} style={state ? {transform: "translateX(0.875rem)"} : {}} />
    </StyledToggle>
  );
}

Toggle.propTypes = {
  handleClick: PropTypes.func,
  state: PropTypes.bool,
};

export default Toggle;
