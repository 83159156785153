import axios from "axios";
import { ORIGIN } from "./url.constants";
import { cachingService } from "../snaptrude/modules/utilityFunctions/cachingService";
import _ from "lodash";
import {store} from "../snaptrude/modules/utilityFunctions/Store";
import {formatMultipleSubType, formatSubType} from "./projects.service";
import { generateNameFromImgPath } from "../containers/editor/components/rightmenuPopUps/helper";
import { libraryService } from "../snaptrude/modules/utilityFunctions/libraryService";
import { getFormData } from "./helper";

const TTL = 60 * 1e3 * 10; // 10 minutes
const userImportedSubTypes = ["Revit", "Rhino", "SketchUp", "3D Model"]
export const populateComponentLibraryData = () => {
  
  const cachedData = cachingService.get(cachingService.SERVICES.door);
  if (cachedData){
    return Promise.resolve();
  }
  else {
    const options = {
      resourceName: "objects",
      floorkey: store.floorkey,
      filterType: "furniture",
      filterSubType: "living", // random
    }
    
    return handleGetResourceOperations(options);
  }
  
}

export const populateMaterialLibraryData = () => {
  
  const cachedData = cachingService.get(cachingService.SERVICES.material);
  if (cachedData){
    return Promise.resolve();
  }
  else {
    const options = {
      resourceName: "materials",
      floorkey: store.floorkey,
      filterType: "material",
      filterSubType: "colors",
    }
    
    return handleGetResourceOperations(options);
  }
  
}

export const handleGetResourceOperations = ({resourceName, floorkey, filterType, filterSubType}, forceUpdate=false) => {
  
  let cachedData = cachingService.get(cachingService.SERVICES[filterType]);
  if(forceUpdate) cachedData = null;
  if (cachedData){
    return Promise.resolve(
      cachedData.filter((data) => data.subType.toLowerCase() === filterSubType.toLowerCase())
    );
  }
  else {
    const data = {
      floorkey: floorkey
    };
  
    const formData = new FormData();
    for (let item in data){
      formData.append(item, data[item]);
    }
  
    const RESOURCE = "/get" + resourceName + "/";
    return axios.post(ORIGIN + RESOURCE, formData)
      .then(response => {
        const {data} = response;
        if(!data || !data.payload) console.log("Error in getting " + resourceName);
        else {
          const {payload} = data;
          // console.log(payload);
          const list = [];
        
          if (resourceName === "materials") {
            const materialCategories = [];
            
            payload.forEach(item => {
              const subType = formatSubType(item[1]);
              materialCategories.pushIfNotExist(subType, existingType => existingType === subType);

              let fullName;
              if (!item[0]) return;
              if (item[3] && item[3] !== '') fullName = item[3]
              else fullName = generateNameFromImgPath(item[0]);

              list.push({
                img: ORIGIN + "/media/" + item[0],
                type: filterType,
                subType,
                fullName
              })
            });
            console.log(list)
            cachingService.set(cachingService.SERVICES.material, list, TTL);
            cachingService.set(cachingService.SERVICES.materialCategories, materialCategories, TTL);
            
            libraryService.createList(filterType, list);
            
            return list.filter((data) => data.subType.toLowerCase() === filterSubType.toLowerCase());
          }
          else if (resourceName === "objects"){
            
            const doorCategories = [];
            const windowCategories = [];
            const furnitureCategories = [];
            const userImportedFileCategories = [];

            payload.forEach((item, index) => {
              let objectTypeLowercaseArr = formatMultipleSubType(item[1]);

              objectTypeLowercaseArr.forEach(objectType => {
                let objectTypeLowercase = objectType;
                let objectSubType = item[2];

                if (objectSubType === "null" || objectSubType === "" || objectSubType === "na") {

                  if (objectTypeLowercase === "door" || objectTypeLowercase === "window") return;
                  // some entries in DB where type is door and sub_type is null

                  objectSubType = objectTypeLowercase;
                  objectTypeLowercase = "furniture";
                }

                switch (objectTypeLowercase) {
                  case "door":
                    doorCategories.pushIfNotExist(objectSubType, existingType => existingType === objectSubType);
                    break;
                  case "window":
                    windowCategories.pushIfNotExist(objectSubType, existingType => existingType === objectSubType);
                    break;
                  case "furniture":
                    if (userImportedSubTypes.includes(item[6])) {
                      objectTypeLowercase = "userImportedFile"
                      objectSubType = item[6]
                      userImportedFileCategories.pushIfNotExist(objectSubType, existingType => existingType === objectSubType);
                    } else {
                      furnitureCategories.pushIfNotExist(objectSubType, existingType => existingType === objectSubType);
                    }
                    break;
                }

                if (!item[0]) return;

                const fullName = generateNameFromImgPath(item[0]);
                list.push({
                  zip: ORIGIN + "/media/" + item[0],
                  type: objectTypeLowercase,
                  subType: objectSubType,
                  babylon: ORIGIN + item[3],
                  img: (ORIGIN + item[4]).replace(/\\/g, "/"),
                  fullName
                });
              });
            });
            
            const doors = list.filter(object => object.type === "door");
            const windows = list.filter(object => object.type === "window");
            const furniture = list.filter(object => object.type === "furniture");
            const userImportedFiles = list.filter(object => object.type === "userImportedFile");

            libraryService.createList("door", doors);
            libraryService.createList("window", windows);
            libraryService.createList("furniture", furniture);
            libraryService.createList("userImportedFile", userImportedFiles);
            
            cachingService.set(cachingService.SERVICES.door, doors, TTL);
            cachingService.set(cachingService.SERVICES.window, windows, TTL);
            cachingService.set(cachingService.SERVICES.furniture, furniture, TTL);
            cachingService.set(cachingService.SERVICES.userImportedFile, userImportedFiles, TTL);
            
            cachingService.set(cachingService.SERVICES.doorCategories, doorCategories, TTL);
            cachingService.set(cachingService.SERVICES.windowCategories, windowCategories, TTL);
            cachingService.set(cachingService.SERVICES.furnitureCategories, furnitureCategories, TTL);
            cachingService.set(cachingService.SERVICES.userImportedFileCategories, userImportedFileCategories, TTL);
            
            let currentList = [];
            switch(filterType){
              case "door":
                currentList = doors;
                break;
              case "window":
                currentList = windows;
                break;
              case "furniture":
                currentList = furniture;
                break;
              case "userImportedFile":
                currentList = userImportedFiles;
                break;
            }
            return currentList.filter((data) => data.subType.toLowerCase() === filterSubType.toLowerCase());
          }
        }
      })
      .catch(err => {
        console.log(err);
        return [];
      })
  }
}

export const getMaterials = async (resourceName, floorkey) => {
  const endpoint = `/get${resourceName}/`;
  const data = {
    floorkey
  }
  const formData = getFormData(data);

  try {
    const resp = await axios.post(ORIGIN + endpoint, formData);
    if(!resp.data && !resp.data.payload){
      return {status: "fail", list: []}
    }
    const list = [];
    
    const materialCategories = [];
    resp.data.payload.forEach(item => {
      const subType = formatSubType(item[1]);
      materialCategories.pushIfNotExist(subType, existingType => existingType === subType);

      let fullName;
      if (!item[0]) return;
      if (item[3] && item[3] !== '') fullName = item[3]
      else fullName = generateNameFromImgPath(item[0]);

      list.push({
        img: ORIGIN + "/media/" + item[0],
        // type: filterType,  
        subType,
        fullName,
      })
    });

    return {status: "success", list: list, categories: materialCategories}
  } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
  }
}

export const getFurnitures = async (resourceName, floorkey) => {
  const endpoint = `/get${resourceName}/`;
  const data = {
    floorkey
  }
  const formData = getFormData(data);

  try {
    const resp = await axios.post(ORIGIN + endpoint, formData);
    if(!resp.data && !resp.data.payload){
      return {status: "fail", list: []}
    }
    const list = [];
    
    const furnitureCategories = [];
    const userImportedFileCategories = [];
    resp.data.payload.forEach((item, index) => {
      let objectTypeLowercase = formatSubType(item[1]);
      let objectSubType = item[2];
      
      if (objectSubType === "null" || objectSubType === "" || objectSubType === "na"){
        
        if (objectTypeLowercase === "door" || objectTypeLowercase === "window") return;
        // some entries in DB where type is door and sub_type is null
        
        objectSubType = objectTypeLowercase;
        objectTypeLowercase = "furniture";
      }
      
      switch (objectTypeLowercase){
        case "furniture":
          if(userImportedSubTypes.includes(item[6])){
            objectTypeLowercase = "userImportedFile"
            objectSubType = item[6]
            userImportedFileCategories.pushIfNotExist(objectSubType, existingType => existingType === objectSubType);
          }else{
            furnitureCategories.pushIfNotExist(objectSubType, existingType => existingType === objectSubType);
          }
          break;
      }
      
      if (!item[0]) return;
      
      const fullName = generateNameFromImgPath(item[0]);
      list.push({
        zip: ORIGIN + "/media/" + item[0],
        type: objectTypeLowercase,
        subType: objectSubType,
        babylon: ORIGIN + item[3],
        img: (ORIGIN + item[4]).replace(/\\/g, "/"),
        fullName
      });
    });
    
    return {status: "success", list: list, categories: furnitureCategories}
  } catch (error) {
        let message = "Something went wrong";
        if(error && error.message) message = error.message;
        return {status: "error", message};
  }
}