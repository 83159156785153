import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const StyledOuterCard = styled.div`
  background: ${(props) => props.theme.colors.fullWhite};
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  padding: 1rem;
  border-radius: 0.938rem;
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        box-shadow: 0 0.25rem 1.25rem rgba(197, 202, 220, 0.9);
      }
    `}
  &.disabled{
    /* pointer-events: none; */
    opacity: 0.5;
  }
`;

function OuterCard({ children, hover, ...rest }) {
  return (
    <StyledOuterCard hover={hover} {...rest}>
      {children}
    </StyledOuterCard>
  );
}

OuterCard.propTypes = {
  children: PropTypes.any,
  hover: PropTypes.bool,
};

export default OuterCard;
