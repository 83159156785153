import BABYLON from "../babylonDS.module.js";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { NewLogger } from "../logger/logger.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { loadStaircaseMaterial, applyMaterialByBREP } from "../../libs/mats.js";
import { copyBrep } from "../../libs/brepOperations.js";
import {
  deepCopyObject,
  showToast,
  onSolid,
  updateSubMeshes,
  getFaceArea,
} from "../extrafunc.js";
import { $scope } from "../../libs/twoDimension.js";
import { createCustomMesh } from "../../libs/massModeling.js";
import { csgOperator } from "../meshoperations/csgOperation.js";
import { staircaseUndoRedo } from "../meshoperations/staircaseOperation.js";
import { computeVolumeMass } from "../../libs/areaFuncs.js";
import { SnapProperty } from "../../libs/GUI/snap_properties.js";
import { SnapElement } from "../../libs/GUI/snap_element.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { removeSelectionBox } from "../../libs/meshEvents.js";
import { meshObjectMapping } from "./mapping.js";
import { generateFacetFaceMapping } from "../babylonDS.module.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { BasicStairComp } from "../../libs/basicCompProperties.js";
import { stairProperties } from "../../libs/objectProperties.js";
import objectPropertiesView from "../objectProperties/objectPropertiesView.js";
import snaptrudeDSCount from "../utilityFunctions/snaptrudeDSCountService.js";
class Staircase {
  constructor(mesh, typeValue, heightValue) {
    // Instance Properties: Do not access without get/set
    this.type = null;
    this.mesh = mesh;
    this.mesh.name = "staircase";
    this.newMesh = null;
    // this.meshUniqueId = this.mesh.uniqueId
    // Staircases Properties
    this.staircaseType = null;
    this.staircaseInternalType = null;
    this.staircaseHeight = null;
    this.tread = null;
    this.depth = null;
    this.riser = null;
    this.width = null;
    this.isLocked = false;

    // Computed Properties
    this.h = null;
    this.flights = null;
    this.flightsDependent = null;
    this.landings = null;
    // this.isLandingDependent
    this.steps = null;
    this.stepsArrangement = null;
    this.stairLength = null;
    this.stairWidth = null;
    this.stairArea = null;
    this.stairVolume = null;

    // Object Properties
    this.brep = null;
    this.meshes = null;
    this.breps = null;
    this.points = null;

    // revit parameters
    this.revitParameters = null

    if(!this.mesh.room_type){
      this.mesh.room_type = snaptrudeDSCount.getLabel("Staircase")
    }
    

    this.position = null;
    this.rotation = null;
    this.scaling = null;
    this.rotationQuaternion = null;
    // this.orignalScaling

    this.unionDone = null;
    this.isInstance = null;
    this.properties = jQuery.extend({}, stairProperties);
    this.properties._components = BasicStairComp;
    this.revitMetaData = {};

    // Setting the properties using setter functions(Default Values etc. minding range and other validations)
    try {
      this.setDefaultStaircaseValues();
      if (!this.mesh.type.toLowerCase().includes("staircase")) {
        this.setType(typeValue);
        this.setHeight(heightValue);
        this.setSnaptrudeDSProperties();
        this.updateStepsRiserFromHeight();
        this.generateStairsArrangement();
        this.assignMeshProperties();
      }
    } catch (error) {
      NewLogger.logError(error);
    }
    // createStaircase() is required to actually create the mesh
  }

  setIsModified = () => {
    if (!this.revitMetaData?.elementId) return;
    this.revitMetaData.isModified = true;
  }

  // Provide CONSTANT Value in Original Dimension(BABYLON Units)
  getStaircaseDimension(key) {
    let value = Staircase.CONSTANTS().STAIRCASE_DIMENSIONS_MM[key];
    if (Number.isInteger(value)) {
      return DisplayOperation.getOriginalDimension(value, "millimeter");
    } else {
      throw new Error("Value is not a Number \n Value: " + value);
    }
  }

  static CONSTANTS() {
    return {
      STAIRCASE_DIMENSIONS_MM: {
        STAIRCASE_TREAD_MIN: 250,
        STAIRCASE_TREAD_DEF: 300,
        STAIRCASE_TREAD_MAX: 350,

        STAIRCASE_RISER_MIN: 120,
        STAIRCASE_RISER_DEF: 150,
        STAIRCASE_RISER_MAX: 190,

        STAIRCASE_WIDTH_MIN: 900,
        STAIRCASE_WIDTH_DEF: 900,
        STAIRCASE_WIDTH_MAX: 6000,

        STAIRCASE_DEPTH_MIN: 150,
        STAIRCASE_DEPTH_DEF: 150,
        STAIRCASE_DEPTH_MAX: 200,

        STAIRCASE_HEIGHT_DEF: 3000,

        STAIRCASE_DOG_LEGGED_GAP_DEF: 150,
      },

      STAIRCASE_MAX_STEPS: 12, //This is the maximum number of continous steps without a landing

      // STAIRCASE_OPEN_WALL_GAP_DEF: 450,
      STAIRCASE_MIN_FLIGHT_STEPS: 3, //This is the minimum number of steps that need to be there in the middle part of openWell(dogLegged)

      STAIRCASES_VALID_TYPES: ["straight", "dogLegged", "lShaped", "square", "custom"],
      // STAIRCASES_VALID_TYPES: ["straight", "dogLegged","openWell1", "openWell2", "arc", "spiral", "lShaped"]

      // STAIRCASE_POSITION_OFFSET: new BABYLON.Vector3(50, 0, -50)
      STAIRCASE_POSITION_OFFSET: new BABYLON.Vector3(0, 0, 0),

      STAIRCASE_TOAST_DURATION: 3000,
      STAIRCASE_TOAST_THEME: "error",
    };
  }

  setDefaultStaircaseValues() {
    this.tread = this.getStaircaseDimension("STAIRCASE_TREAD_DEF");
    this.riser = this.getStaircaseDimension("STAIRCASE_RISER_DEF");
    this.depth = this.getStaircaseDimension("STAIRCASE_DEPTH_DEF");
    this.width = this.getStaircaseDimension("STAIRCASE_WIDTH_DEF");
    this.landings = []; // List of Lists
    // this.isLandingDependent = []		// List of Lists(contains bool)
    // this.mesh = null
    this.brep = null;
    this.newMesh = null;
    this.meshes = [];
    this.breps = [];
    this.stepsArrangement = []; // List of Lists. stepsArrangement[i][j] represents the no of steps in between 'j' and 'j+1' landing in mesh 'i'
    this.points = []; // This will be a List of Lists. Each index(representing the mesh) will contain a list(representing points of that mesh)
    this.position = BABYLON.Vector3.Zero();
    this.rotation = BABYLON.Vector3.Zero();
    this.scaling = BABYLON.Vector3.One();
    this.stairLength = 0;
    this.stairWidth = 0;
    this.stairArea = 0;
    this.stairVolume = 0;
    this.unionDone = false;
    this.isInstance = false;
    this.linkedListId = "dll_" + Math.random().toString(36).substr(2, 9);
  }

  setSnaptrudeDSProperties() {
    this.level_low = 0;
    this.level_hight = 1;
    this.storey = 1;
    this.structure_id = 1;
    this.id = "st_" + Math.random().toString(36).substr(2, 9);
    this.type = "staircase";
    this.groupId = null;
    this.faceFacetMapping = null; // Will be assigned after mesh creation
  }

  materialStuff() {
    let staircaseMaterial = store.scene.getMaterialByName("staircase_mat");
    if (!staircaseMaterial) {
      staircaseMaterial = loadStaircaseMaterial();
    }
    this.mesh.material = staircaseMaterial;
  }

  assignMeshProperties() {
    this.mesh.type = "staircase";
    this.mesh.storey = 1;
    this.mesh.structure_id = store.activeLayer.structure_id;
    // this.positionStair(this.position)
  }

  assignBackObjectProperties(dsProps, sourceMesh) {
    this.staircaseType = dsProps["staircaseType"];
    this.staircaseHeight = dsProps["staircaseHeight"];
    this.tread = dsProps["tread"];
    this.depth = dsProps["depth"];
    this.riser = dsProps["riser"];
    this.width = dsProps["width"];

    if (sourceMesh) {
      this.brep = sourceMesh.getSnaptrudeDS().brep;
      this.faceFacetMapping = sourceMesh.getSnaptrudeDS().faceFacetMapping;
    } else {
      let brep = dsProps["brep"];
      if (_.isString(brep)) {
        this.brep = store.resurrect.resurrect(brep);
      } else {
        this.brep = copyBrep(brep);
      }
      this.faceFacetMapping = dsProps["faceFacetMapping"];
    }

    this.updateStepsRiserFromHeight();
    this.generateStairsArrangement();
    this.position = new BABYLON.Vector3(
      dsProps["position"]["x"],
      dsProps["position"]["y"],
      dsProps["position"]["z"]
    );
    this.rotation = new BABYLON.Vector3(
      dsProps["rotation"]["x"],
      dsProps["rotation"]["y"],
      dsProps["rotation"]["z"]
    );
    if (dsProps.scaling) {
      this.scaling = new BABYLON.Vector3(
        dsProps["scaling"]["x"],
        dsProps["scaling"]["y"],
        dsProps["scaling"]["z"]
      );
    }
    if (dsProps.rotationQuaternion) {
      this.rotationQuaternion = new BABYLON.Vector4(
        dsProps["rotationQuaternion"]["x"],
        dsProps["rotationQuaternion"]["y"],
        dsProps["rotationQuaternion"]["z"],
        dsProps["rotationQuaternion"]["w"]
      );
    }
    this.unionDone = dsProps["unionDone"];
    this.isInstance = dsProps["isInstance"];
    // console.log(this.position, this.rotation, this.unionDone)

    this.level_low = dsProps["level_low"];
    this.level_hight = dsProps["level_hight"];
    this.storey = dsProps["storey"];
    this.structure_id = dsProps["structure_id"];
    this.id = dsProps["id"];
    this.type = dsProps["type"];
    this.groupId = dsProps["groupId"];
    this.linkedListId = dsProps["linkedListId"];
  }

  cloneProperties(newObj, unique = true) {
    newObj.staircaseType = this.staircaseType;
    newObj.staircaseHeight = this.staircaseHeight;
    newObj.tread = this.tread;
    newObj.depth = this.depth;
    newObj.riser = this.riser;
    newObj.width = this.width;
    newObj.updateStepsRiserFromHeight();
    newObj.generateStairsArrangement();

    newObj.position = this.position;
    newObj.rotation = this.rotation;
    newObj.scaling = this.scaling;
    newObj.rotationQuaternion = this.rotationQuaternion;
    newObj.unionDone = this.unionDone;
    newObj.isInstance = this.isInstance;

    newObj.level_low = this.level_low;
    newObj.level_hight = this.level_hight;
    newObj.storey = this.storey;
    newObj.structure_id = this.structure_id;
    newObj.id = "st_" + Math.random().toString(36).substr(2, 9);
    newObj.type = this.type;
    newObj.groupId = this.groupId;
    if (unique) {
      newObj.brep = copyBrep(this.brep);
      newObj.faceFacetMapping = deepCopyObject(this.faceFacetMapping);
      if (!newObj.mesh.isAnInstance) {
        newObj.mesh.makeGeometryUnique();
      }
    } else {
      newObj.brep = this.brep;
      newObj.faceFacetMapping = this.faceFacetMapping;
    }
  }

  setType(typeValue) {
    if (!Staircase.CONSTANTS().STAIRCASES_VALID_TYPES.includes(typeValue)) {
      throw new Error("Invalid staircase type recieved");
    }
    this.staircaseType = typeValue;
    return true;
  }

  setHeight(heightValue) {
    this.staircaseHeight = heightValue;
  }

  // Update Functions

  // Updating type is not allowed as of now
  // updateType(newType){
  //     if(!STAIRCASES_VALID_TYPES.includes(typeValue)){
  //         console.log("Invalid staircase type recieved")
  //         return false
  //     }
  //     this.type = typeValue
  //     return true
  // }

  updateHeight(newHeight) {
    this.staircaseHeight = newHeight;
    return (
      this.updateStepsRiserFromHeight() && this.generateStairsArrangement()
    );
  }

  getHeight() {
    return this.staircaseHeight;
  }

  updateTread(newTread) {
    if (
      newTread > this.getStaircaseDimension("STAIRCASE_TREAD_MAX") ||
      newTread < this.getStaircaseDimension("STAIRCASE_TREAD_MIN")
    ) {
      let values = this.getValuesForRangeToast("STAIRCASE_TREAD");
      let msg =
        "Provided Tread is out of Range. Tread Range: " +
        values.lowerLimit +
        " - " +
        values.upperLimit;
      if (values.dimension != "Feet-inches") {
        msg += " " + values.dimension;
      }
      showToast(
        msg,
        Staircase.CONSTANTS().STAIRCASE_TOAST_DURATION,
        Staircase.CONSTANTS().STAIRCASE_TOAST_THEME
      );
      throw new Error(
        "Provided Tread is out of range. Tread Range: " +
          values.lowerLimit +
          " to " +
          values.upperLimit
      );
    }
    this.tread = newTread;
    return true;
  }

  getTread() {
    return this.tread;
  }

  updateDepth(newDepth) {
    if (
      newDepth > this.getStaircaseDimension("STAIRCASE_DEPTH_MAX") ||
      newDepth < this.getStaircaseDimension("STAIRCASE_DEPTH_MIN")
    ) {
      let values = this.getValuesForRangeToast("STAIRCASE_DEPTH");
      let msg =
        "Provided Depth is out of Range. Depth Range: " +
        values.lowerLimit +
        " - " +
        values.upperLimit;
      if (values.dimension != "Feet-inches") {
        msg += " " + values.dimension;
      }
      showToast(
        msg,
        Staircase.CONSTANTS().STAIRCASE_TOAST_DURATION,
        Staircase.CONSTANTS().STAIRCASE_TOAST_THEME
      );
      throw new Error(
        "Provided depth is out of range. Depth Range: " +
          values.lowerLimit +
          " to " +
          values.upperLimit
      );
    }
    this.depth = newDepth;
    return true;
  }

  getDepth() {
    return this.depth;
  }

  getValuesForRangeToast(constant) {
    return {
      dimension: store.$scope.units_type.name,
      lowerLimit: DisplayOperation.convertToDefaultDimension(
        this.getStaircaseDimension(constant + "_MIN")
      ),
      upperLimit: DisplayOperation.convertToDefaultDimension(
        this.getStaircaseDimension(constant + "_MAX")
      ),
    };
  }

  updateRiser(newRiser, isUpdateSteps) {
    if (
      newRiser > this.getStaircaseDimension("STAIRCASE_RISER_MAX") ||
      newRiser < this.getStaircaseDimension("STAIRCASE_RISER_MIN")
    ) {
      if (isUpdateSteps) {
        return true;
      }
      let values = this.getValuesForRangeToast("STAIRCASE_RISER");
      let msg =
        "Provided Riser is out of Range. Riser Range: " +
        values.lowerLimit +
        " - " +
        values.upperLimit;
      if (values.dimension != "Feet-inches") {
        msg += " " + values.dimension;
      }
      showToast(
        msg,
        Staircase.CONSTANTS().STAIRCASE_TOAST_DURATION,
        Staircase.CONSTANTS().STAIRCASE_TOAST_THEME
      );
      throw new Error(
        "Provided riser is out of range. Riser Range: " +
          values.lowerLimit +
          " to " +
          values.upperLimit
      );
    }
    this.riser = newRiser;

    this.updateStepsRiserFromHeight();
    this.generateStairsArrangement();
  }

  getRiser() {
    return this.riser;
  }

  updateSteps(newSteps) {
    if (newSteps <= 0) {
      throw new Error("Steps cannot be zero or negative");
    }
    let calculatedRiser = this.staircaseHeight / newSteps;
    // console.log("Calculated Riser for " + newSteps + " steps: " + calculatedRiser)
    // console.log("Staircase Height: " + this.staircaseHeight)

    if (this.updateRiser(calculatedRiser, true)) {
      let values = this.getValuesForRangeToast("STAIRCASE_RISER");
      let msg =
        "Staircase with provided number of Steps cannot be made as riser height will get out of Range. Riser Range: " +
        values.lowerLimit +
        " - " +
        values.upperLimit;
      if (values.dimension != "Feet-inches") {
        msg += " " + values.dimension;
      }
      showToast(
        msg,
        Staircase.CONSTANTS().STAIRCASE_TOAST_DURATION,
        Staircase.CONSTANTS().STAIRCASE_TOAST_THEME
      );
      throw new Error(
        "Staircase with provided number of Steps cannot be made as riser height will get out of Range. Riser Range: " +
          values.lowerLimit +
          " to " +
          values.upperLimit
      );
    }
  }

  getSteps() {
    return this.steps;
  }

  updateWidth(newWidth) {
    if (
      newWidth > this.getStaircaseDimension("STAIRCASE_WIDTH_MAX") ||
      newWidth < this.getStaircaseDimension("STAIRCASE_WIDTH_MIN")
    ) {
      let values = this.getValuesForRangeToast("STAIRCASE_WIDTH");
      let msg =
        "Provided Width is out of Range. Flight Width Range: " +
        values.lowerLimit +
        " - " +
        values.upperLimit;
      if (values.dimension != "Feet-inches") {
        msg += " " + values.dimension;
      }
      showToast(
        msg,
        Staircase.CONSTANTS().STAIRCASE_TOAST_DURATION,
        Staircase.CONSTANTS().STAIRCASE_TOAST_THEME
      );
      throw new Error(
        "Provided width is out of range. Width Range: " +
          values.lowerLimit +
          " to " +
          values.upperLimit
      );
    }
    this.width = newWidth;
    for (let i = 0; i < this.landings.length; i++) {
      for (let j = 0; j < this.landings[i].length; j++) {
        // if(this.isLandingDependent[i][j]){
        // 	console.log("Landing[" + i + "][" + j + "] changed as this landing is dependent to the staircase width as it has not been manually changed")
        // 	this.landings[i][j] = this.width
        // }
        if (this.landings[i][j] < this.width) {
          // console.log("Landing[" + i + "][" + j + "] changed as it was lower than minimum(width of the staircase)")
          this.landings[i][j] = this.width;
        }

        // This is a temporary thing till landing dimension editing and locking unlocking is not provided
        if (this.landings[i][j] > this.width) {
          // console.log("Landing[" + i + "][" + j + "] changed as it was lower than minimum(width of the staircase)")
          this.landings[i][j] = this.width;
        }
      }
    }
    return true;
  }

  getWidth() {
    return this.width;
  }

  updateLanding(newLanding, meshNumber, landingNumber) {
    if (newLanding < this.width) {
      throw new Error("Landing cannot be less than the width of the staircase");
    }
    if (this.landings[meshNumber] == null) {
      throw new Error("The meshLanding provided does not exist");
    }
    if (this.landings[meshNumber][landingNumber] == null) {
      throw new Error("The landing provided does not exist");
    }
    this.landings[meshNumber][landingNumber] = newLanding;
    // this.isLandingDependent[meshNumber][landingNumber] = false
    return true;
  }

  // Mesh Functions
  createStaircase(startPoint = [0, 0, 0]) {
    this.deleteMeshes(false, true);
    // if (this.mesh) {
    // 	this.mesh.visibility = 0;
    // }
    this.mesh.scaling = BABYLON.Vector3.One();
    this.createStaircaseMeshes(startPoint);
    this.unionDone = false;

    this.meshes[0].snaptrudeFunctions().copyVertexDataToMesh(this.mesh);
    this.brep = this.breps[0];
    this.mesh.rotation = this.meshes[0].rotation;
    this.mesh.position = this.meshes[0].position;
    this.mesh.visibility = this.meshes[0].visibility;
    onSolid(this.mesh);

    for (let i = 1; i < this.meshes.length; i++) {
      // this.meshes[i].parent = this.meshes[0]
      this.meshes[i].setParent(this.mesh);
      // this.meshes[i].parent = this.mesh
    }
    this.meshesSolid();
    this.meshes[0].dispose();
    this.mesh.position = this.position;
    // if (!this.mesh) {
    // 	this.mesh = this.meshes[0]
    // 	this.brep = this.breps[0]
    // 	this.assignMeshProperties()
    // }
    // else{

    // }
    // this.mesh = this.meshes[0]
    // this.brep = this.breps[0]
  }

  createStaircaseMeshes(startPoint) {
    this.providePoints(startPoint);
    function manipulatePoints(points) {
      let newPoints = [];
      let x, y, z;
      for (let i = 0; i < points.length; i++) {
        x = points[i]["x"] * -1;
        y = points[i]["y"];
        z = points[i]["z"];
        // newPoints.push(new BABYLON.Vector3(x, y, z))
        newPoints.push([x, y, z]);
      }

      return newPoints;
    }
    // let scale = 0.01
    let points = [];
    for (let i = 0; i < this.points.length; i++) {
      points[i] = manipulatePoints(this.points[i]);
    }

    for (let i = 0; i < this.points.length; i++) {
      // this.meshes[i] = BABYLON.MeshBuilder.ExtrudePolygon("staircase", { shape: points[i], depth: manWidth, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true }, store.scene);
      // this.meshes[i] = createCustomMeshAccordingToNormal({path_bottom_vec: points[i], height: manWidth, shiftCentre: false});
      this.meshes[i] = createCustomMesh(points[i], this.width, null, [], false);
      this.breps[i] = this.meshes[i].brep;
      this.meshes[i].snaptrudeFunctions().copyVertexDataToMesh(this.meshes[i]);
      this.meshes[i].visibility = 0;
      delete this.meshes[i].brep;
    }
    // this.brep = this.breps[0]
    this.orientMeshes();
  }

  disposeMeshes(shouldDisposeMainMesh = true, shouldDisposeMeshesZero = true) {
    if (shouldDisposeMainMesh && this.mesh) {
      this.mesh.dispose();
    }
    let i = 1;
    if (shouldDisposeMeshesZero) i = 0;
    for (; i < this.meshes.length; i++) {
      this.meshes[i].dispose();
    }
  }
  /**
   *
   * @param {*} shouldDisposeMainMesh
   * @param {*} shouldDisposeMeshesZero
   */
  deleteMeshes(shouldDisposeMainMesh, shouldDisposeMeshesZero) {
    this.disposeMeshes(shouldDisposeMainMesh, shouldDisposeMeshesZero);
    this.meshes = [];
    this.breps = [];
  }

  positionStair(position, storePosition = true) {
    if (position != null) {
      if (storePosition) {
        this.position = position;
      }
      this.mesh.position = position;
      return true;
    }
    return false;
  }

  /**
   *
   * @param {[]} position
   * @param {boolean} offset
   */
  updatePosition(position, offset = false) {
    if (position) {
      if (offset) {
        this.position = position.add(
          Staircase.CONSTANTS().STAIRCASE_POSITION_OFFSET
        );
      } else {
        this.position = position;
      }
    } else {
      throw new Error("Position is not valid");
    }
  }

  // setUnityRotationAndScaling() {
  // 	this.mesh.rotation = BABYLON.Vector3.Zero()
  // 	if(this.mesh.rotationQuaternion){
  // 		this.mesh.rotationQuaternion = new BABYLON.Vector4(0,0,0,1)
  // 	}
  // 	this.mesh.scaling = BABYLON.Vector3.One()
  // }

  updateRotationAndScaling() {
    this.rotation = this.mesh.rotation;
    this.rotationQuaternion = this.mesh.rotationQuaternion;
    this.scaling = this.mesh.scaling;
  }

  reassignRotationAndScaling() {
    this.mesh.rotation = this.rotation;
    this.mesh.rotationQuaternion = this.rotationQuaternion;
    this.mesh.scaling = this.scaling;
  }

  positionMesh(meshNumber, x, y, z) {
    this.meshes[meshNumber].position.x = x * -1;
    this.meshes[meshNumber].position.y = y;
    this.meshes[meshNumber].position.z = z;
  }

  orientMeshes() {
    // let x = this.position[0], y = this.position[1], z = this.position[2]
    let x = 0,
      y = 0,
      z = 0;
    if (this.staircaseType == "straight" || this.staircaseType == "custom") {
      this.meshes[0].rotation = new BABYLON.Vector3(-Math.PI / 2, 0, 0);
      this.positionMesh(0, x, y, z);
    } else if (this.staircaseType == "dogLegged") {
      let totalX = this.tread * this.stepsArrangement[0][0];
      let totalY = 0;
      for (let i = 0; i < this.meshes.length; i++) {
        x = 0;
        y = 0;
        z = 0;
        if (i % 4 == 0) {
          this.meshes[i].rotation = new BABYLON.Vector3(-Math.PI / 2, 0, 0);
          this.positionMesh(i, x, totalY, z);
        } else if (i % 4 == 1) {
          this.meshes[i].rotation = new BABYLON.Vector3(
            -Math.PI / 2,
            Math.PI / 2,
            Math.PI
          );
          if (this.stepsArrangement[1][0]) {
            x += (this.depth * (this.h - this.tread)) / this.riser;
          }
          x += this.width;
          // z -= this.width
          this.positionMesh(i, x + totalX, totalY, z);
        } else if (i % 4 == 2) {
          this.meshes[i].rotation = new BABYLON.Vector3(
            -Math.PI / 2,
            -Math.PI,
            0
          );
          if (this.stepsArrangement[1][0]) {
            x += (this.depth * (this.h - this.tread)) / this.riser;
            z -=
              this.stepsArrangement[1][0] * this.tread +
              (this.depth * (this.h - this.tread)) / this.riser;
            z -= 2 * this.width;
          } else {
            z -=
              2 * this.width +
              this.getStaircaseDimension("STAIRCASE_DOG_LEGGED_GAP_DEF");
          }
          this.positionMesh(i, x + totalX, y + totalY, z);
        } else if (i % 4 == 3) {
          this.meshes[i].rotation = new BABYLON.Vector3(
            Math.PI / 2,
            0,
            Math.PI / 2
          );
          x -= this.width;
          // z += this.width
          this.positionMesh(i, x, y + totalY - this.riser, z);
        }
        if (this.stepsArrangement[i][0]) {
          totalY += this.riser * this.stepsArrangement[i][0];
        }
      }
    } else if (this.staircaseType == "lShaped") {
      this.meshes[0].rotation = new BABYLON.Vector3(-Math.PI / 2, 0, 0);
      this.positionMesh(0, x, y, z);

      this.meshes[1].rotation = new BABYLON.Vector3(
        -Math.PI / 2,
        Math.PI / 2,
        Math.PI
      );
      let totalSteps = 0,
        totalLandings = this.stepsArrangement[0].length - 1;
      for (let i = 0; i < this.stepsArrangement[0].length; i++) {
        totalSteps += this.stepsArrangement[0][i];
      }
      x +=
        this.tread * (totalSteps - totalLandings) +
        (this.depth * (this.h - this.tread)) / this.riser;
      x += this.width * (totalLandings + 1);
      y += this.riser * totalSteps;
      // z += this.width
      this.positionMesh(1, x, y, z);
    } else if (this.staircaseType == "square") {
      let totalX = this.tread * this.stepsArrangement[0][0];
      let totalY = 0;
      for (let i = 0; i < this.meshes.length; i++) {
        // (x = 0), (y = 0), (z = 0);
        x = 0;
        y = 0;
        z = 0;
        if (i % 4 == 0) {
          this.meshes[i].rotation = new BABYLON.Vector3(-Math.PI / 2, 0, 0);
          this.positionMesh(i, x, totalY, z);
        } else if (i % 4 == 1) {
          this.meshes[i].rotation = new BABYLON.Vector3(
            -Math.PI / 2,
            Math.PI / 2,
            Math.PI
          );
          x += (this.depth * (this.h - this.tread)) / this.riser;
          x += this.width;
          this.positionMesh(i, x + totalX, totalY, z);
        } else if (i % 4 == 2) {
          this.meshes[i].rotation = new BABYLON.Vector3(
            -Math.PI / 2,
            -Math.PI,
            0
          );
          x += (this.depth * (this.h - this.tread)) / this.riser;
          z -=
            this.stepsArrangement[1][0] * this.tread +
            (this.depth * (this.h - this.tread)) / this.riser;
          z -= 2 * this.width;
          this.positionMesh(i, x + totalX, y + totalY, z);
        } else if (i % 4 == 3) {
          this.meshes[i].rotation = new BABYLON.Vector3(
            -Math.PI / 2,
            0,
            Math.PI / 2
          );
          x -= this.width;
          z -=
            this.stepsArrangement[1][0] * this.tread +
            this.width +
            (this.depth * (this.h - this.tread)) / this.riser;
          z -= this.width;
          this.positionMesh(i, x, y + totalY, z);
        }
        totalY += this.riser * this.stepsArrangement[i][0];
      }
    }
  }

  // reassignMeshProperties(mesh) {
  // 	mesh.type = "staircase";
  // 	mesh.storey = this.mesh.storey
  // 	mesh.structure_id = this.mesh.structure_id
  // 	let staircaseMaterial = store.scene.getMaterialByName("staircase_mat")
  // 	if (!staircaseMaterial) {
  // 		staircaseMaterial = loadStaircaseMaterial()
  // 	}
  // 	// this.meshSolid(mesh)
  // }

  meshesSolid() {
    let staircase_mat = store.scene.getMaterialByName("staircase_mat");
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].type = "staircase";
      onSolid(this.meshes[i]);
      this.meshes[i].material = staircase_mat;
    }
  }

  applyMaterial_2() {
    let staircaseMaterial = store.scene.getMaterialByName("staircase_mat");
    if (!staircaseMaterial) {
      staircaseMaterial = loadStaircaseMaterial();
    }
    this.mesh.material = staircaseMaterial;
    if (this.mat) {
      this.reapplyMaterial();
    }
    // mesh.material = this.mesh.material
    // for (let i = 0; i < this.meshes.length; i++) {
    // 	this.meshes[i].type = "staircase";
    // 	onSolid(this.meshes[i])
    // 	this.meshes[i].material = staircaseMaterial
    // }
  }

  async doUnion() {
    this.mesh.position = Staircase.CONSTANTS().STAIRCASE_POSITION_OFFSET;
    this.mesh.scaling = BABYLON.Vector3.One();
    let meshesFormatted = csgOperator.formatMeshes(
      [this.mesh, ...this.meshes.slice(1)],
      [this.brep, ...this.breps.slice(1)]
    );
    this.mesh.position = this.position;
    let brepPrecursor = await csgOperator.promisifiedUnion(
      meshesFormatted,
      null,
      { keepGlobalCoordinates: true, meshesFormatted: true }
    );
    // csgOperator.union([this.mesh, ...this.meshes.slice(1)], [this.brep, ...this.breps.slice(1)], this.unionCallback.bind(this))
    this.unionCallback(brepPrecursor);
  }

  unionCallback(brepPrecursor) {
    // console.log("Callback Recieved")
    // console.log(brepPrecursor)
    this.unionDone = true;
    // let lengthBrepCells = brepPrecursor.cells.length
    // let brepCells = []
    // for (let i = 0; i < lengthBrepCells; i++) {
    // 	brepCells.push([brepPrecursor.cells[i]])
    // }
    // let brep = generateBrepFromPositionsAndCells(brepPrecursor.positions, brepCells);
    // let mesh = generateMeshFromBrep(brep);
    this.replaceMesh(brepPrecursor.mesh, brepPrecursor.brep);
    if (this.isNewStair) {
      delete this.isNewStair;
      staircaseUndoRedo.commandManager_createStaircase(this);
    }
  }

  replaceMesh(mesh, brep) {
    let shouldDisposeMeshesZero = true;
    // if (this.mesh.uniqueId === this.meshes[0].uniqueId) {
    // 	shouldDisposeMeshesZero = false
    // }
    this.deleteMeshes(false, false);
    this.replaceGeometry(mesh, brep);
    this.reassignRotationAndScaling();
    mesh.dispose();
    // this.positionStair(this.position.subtract(Staircase.CONSTANTS().STAIRCASE_POSITION_OFFSET), false)
    this.calculateAreaVolume();
    this.applyMaterial_2();
    if (this.mesh.instances) {
      for (let i = 0; i < this.mesh.instances.length; i++) {
        let instanceMesh = this.mesh.instances[i];
        let instanceObject = instanceMesh.getSnaptrudeDS();
        instanceMesh.refreshBoundingInfo();
        instanceObject.brep = this.brep;
        instanceObject.faceFacetMapping = this.faceFacetMapping;
      }
    }
    // if(this.isInstance){
    // 	this.mesh.geometry = this.mesh.sourceMesh.geometry
    // }
  }

  replaceGeometry(mesh, brep) {
    // this.mesh.rotation = BABYLON.Vector3.Zero()
    mesh.snaptrudeFunctions().copyVertexDataToMesh(this.mesh);
    this.brep = brep;
    onSolid(this.mesh);
    this.mesh.visibility = 1;
    this.faceFacetMapping = mesh.faceFacetMapping;
    [this.mesh, ...this.mesh.instances].forEach((mesh) => {
      mesh.disableFacetData();
      mesh._internalAbstractMeshDataInfo._facetData.facetParameters = {};
      /*
			Found this solution after a lot of clawing around in the source code.

			Just calling updateFacetData results in an array indexing error since this function doesn't
			expect new normals.
			So have to call disableFacetData first to flush all the facetData.
			Calling updateFacetData later counts the number of facets as number of indices / 3, which makes sense. So now,
			it can expect new normals. But disableFacetData sets
			mesh._internalAbstractMeshDataInfo._facetData.facetParameters = null
			This is probably a bug. So initialising it manually.
			Working as expected for now.
			*/
      mesh.updateFacetData();
    });
    delete mesh.faceFacetMapping;
    updateSubMeshes(this.mesh, this.brep.getFaces(), this.faceFacetMapping);
  }

  // Auxilary Functions
  calculateAreaVolume() {
    this.stairVolume = computeVolumeMass(this.mesh);
  }

  /**
   * 	Rule for calculating steps and riser:-
   *	(1) In case the number of steps are fractional, go for the nearest closest integer(ciel or floor)
   *	(2) Recalculate the riser and ensure that it lies in the defined range else go for the other closest integer in range
   *	(3) In case it does not lie in either of the ranges, go for the higher closest integer(outside the range)
   */
  updateStepsRiserFromHeight() {
    if (this.riser <= 0 || this.staircaseHeight < this.riser) {
      throw new Error("Invalid Height or Riser");
    }
    let calculatedSteps = this.staircaseHeight / this.riser;
    let finalSteps = 0,
      finalRiser = 0;
    if (Number.isInteger(calculatedSteps)) {
      finalSteps = calculatedSteps;
      finalRiser = this.riser;
    } else {
      let lowerSteps = Math.floor(calculatedSteps);
      let higherSteps = Math.ceil(calculatedSteps);
      let smallerRiser = this.staircaseHeight / higherSteps;
      let largerRiser = this.staircaseHeight / lowerSteps;

      if (calculatedSteps - lowerSteps <= 0.5) {
        // Case when we go for lowerSteps
        if (largerRiser <= this.getStaircaseDimension("STAIRCASE_RISER_MAX")) {
          // largerRiser will always be more the STAIRCASE_RISER_MIN
          finalSteps = lowerSteps;
          finalRiser = largerRiser;
        } else {
          finalSteps = higherSteps;
          finalRiser = smallerRiser;
        }
      } else {
        // Case when we go for higherSteps
        if (smallerRiser >= this.getStaircaseDimension("STAIRCASE_RISER_MIN")) {
          // smallerRiser will always be less than STAIRCASE_RISER_MAX
          finalSteps = higherSteps;
          finalRiser = smallerRiser;
        } else if (
          largerRiser <= this.getStaircaseDimension("STAIRCASE_RISER_MIN")
        ) {
          finalSteps = lowerSteps;
          finalRiser = largerRiser;
        } else {
          finalSteps = higherSteps;
          finalRiser = smallerRiser;
        }
      }
    }
    if (finalSteps < this.minimumStepsRequired()) {
      throw new Error("Steps less than minimum allowed limit");
    }
    if (finalSteps === 0 || finalRiser === 0) {
      throw new Error("Issue in calculation of Steps or Riser");
    }
    this.steps = finalSteps;
    this.riser = finalRiser;
    // console.log("Riser: " + this.riser + ", Steps: " + this.steps)
    return true;
  }

  minimumStepsRequired() {
    let minSteps = 0;
    switch (this.staircaseType) {
      case "straight":
        minSteps = Staircase.CONSTANTS().STAIRCASE_MIN_FLIGHT_STEPS * 1;
        break;
      case "dogLegged":
        minSteps = Staircase.CONSTANTS().STAIRCASE_MIN_FLIGHT_STEPS * 2;
        break;
      case "lShaped":
        minSteps = Staircase.CONSTANTS().STAIRCASE_MIN_FLIGHT_STEPS * 2;
        break;
      case "square":
        minSteps = Staircase.CONSTANTS().STAIRCASE_MIN_FLIGHT_STEPS * 4;
        break;
    }
    return minSteps;
  }

  generateStairsArrangement() {
    let status = true;
    this.stepsArrangement = [];
    if (this.staircaseType == "straight" || this.staircaseType === "custom") {
      let noOfDivisions = Math.ceil(
        this.steps / Staircase.CONSTANTS().STAIRCASE_MAX_STEPS
      );
      let noOfLandings = noOfDivisions - 1;
      let wholeSteps = Math.floor(this.steps / noOfDivisions);
      this.stepsArrangement[0] = Array(noOfDivisions).fill(wholeSteps);
      this.landings[0] = Array(noOfLandings).fill(this.width);
      // this.isLandingDependent[0] = Array(noOfLandings).fill(true)
      let extraSteps = this.steps - wholeSteps * noOfDivisions;
      let i = 0;
      while (extraSteps > 0) {
        this.stepsArrangement[0][i++]++;
        extraSteps--;
      }
    } else if (this.staircaseType == "dogLegged") {
      let noOfFlights = this.steps / Staircase.CONSTANTS().STAIRCASE_MAX_STEPS;
      let stepsInMesh = [];

      // noOfFlights is a float value
      if (noOfFlights > 2 && noOfFlights <= 3) {
        // this.staircaseInternalType = "openWell";
        stepsInMesh[1] = Staircase.CONSTANTS().STAIRCASE_MIN_FLIGHT_STEPS;
        let remainingSteps = this.steps - stepsInMesh[1];
        if (remainingSteps % 2 != 0) {
          stepsInMesh[1]++;
          remainingSteps--;
        }
        if (remainingSteps > 2 * Staircase.CONSTANTS().STAIRCASE_MAX_STEPS) {
          stepsInMesh[0] = Staircase.CONSTANTS().STAIRCASE_MAX_STEPS;
          stepsInMesh[2] = Staircase.CONSTANTS().STAIRCASE_MAX_STEPS;
          stepsInMesh[1] =
            this.steps - 2 * Staircase.CONSTANTS().STAIRCASE_MAX_STEPS;
        } else {
          stepsInMesh[0] = remainingSteps / 2;
          stepsInMesh[2] = remainingSteps / 2;
        }
      } else {
        let noOfRounds = Math.ceil(noOfFlights / 2);
        let wholeSteps = Math.floor(this.steps / (2 * noOfRounds));
        let extraSteps = this.steps - wholeSteps * noOfRounds * 2;
        for (let i = 0; i < 2 * noOfRounds; i++) {
          stepsInMesh[2 * i] = wholeSteps;
          if (extraSteps > 0) {
            stepsInMesh[2 * i]++;
            extraSteps--;
          }
        }
      }

      for (let i = 0; i < stepsInMesh.length; i++) {
        this.stepsArrangement[i] = [];
        this.stepsArrangement[i][0] = stepsInMesh[i];
      }
    } else if (this.staircaseType == "lShaped") {
      let noOfDivisions = Math.ceil(
        this.steps / Staircase.CONSTANTS().STAIRCASE_MAX_STEPS
      );
      let noOfLandings = noOfDivisions - 1;
      let wholeSteps = Math.floor(this.steps / noOfDivisions);
      let stepsArrangement = Array(noOfDivisions).fill(wholeSteps);
      let landings = Array(noOfLandings).fill(this.width);
      this.stepsArrangement = [[0], [0]];
      // this.isLandingDependent[0] = Array(noOfLandings).fill(true)
      let extraSteps = this.steps - wholeSteps * noOfDivisions;
      let i = 0;
      while (extraSteps > 0) {
        stepsArrangement[i++]++;
        extraSteps--;
      }
      if (noOfLandings === 0) {
        this.stepsArrangement[0][0] = Math.ceil(this.steps / 2);
        this.stepsArrangement[1][0] = Math.floor(this.steps / 2);
      } else {
        noOfLandings -= 1;
        let landingsInLowerMesh = Math.ceil(noOfLandings / 2);
        this.stepsArrangement[0] = stepsArrangement.slice(
          0,
          landingsInLowerMesh + 1
        );
        this.stepsArrangement[1] = stepsArrangement.slice(
          landingsInLowerMesh + 1
        );
        this.landings[0] = landings.slice(0, landingsInLowerMesh);
        this.landings[1] = landings.slice(landingsInLowerMesh);
      }
    } else if (this.staircaseType == "square") {
      let noOfFlights = this.steps / Staircase.CONSTANTS().STAIRCASE_MAX_STEPS;
      let noOfRounds = Math.ceil(noOfFlights / 4);
      let wholeSteps = Math.floor(this.steps / (noOfRounds * 4));
      let extraSteps = this.steps - wholeSteps * noOfRounds * 4;
      let stepsInMesh = Array(noOfRounds * 4).fill(wholeSteps);
      // let stepsInMesh
      let i = 0;
      while (extraSteps > 0 && i < stepsInMesh.length) {
        stepsInMesh[i]++;
        extraSteps--;
        i += 2;
      }
      i = 1;
      while (extraSteps > 0 && i < stepsInMesh.length) {
        stepsInMesh[i]++;
        extraSteps--;
        i += 2;
      }

      for (let i = 0; i < stepsInMesh.length; i++) {
        this.stepsArrangement[i] = [];
        this.stepsArrangement[i][0] = stepsInMesh[i];
      }
    } else {
      throw new Error("Unknown staircase type");
    }
    return status;
  }

  // startPoint needs to by (x,0,z) where z is typically negative
  providePoints(startPoint) {
    this.points = [];
    let status = true,
      lastPointIndex,
      startPointForBottom;
    let x = startPoint[0],
      y = startPoint[1],
      z = startPoint[2];
    this.h = Math.sqrt(this.tread * this.tread + this.riser * this.riser);
    if (this.staircaseType == "straight") {
      let meshNumber = 0;
      this.points[meshNumber] = [];
      this.providePointsFlight(startPoint, meshNumber);
    }
    if (this.staircaseType == "custom") {
      let meshNumber = 0;
      this.points[meshNumber] = [];
      this.providePointsFlight(startPoint, meshNumber);
    } else if (this.staircaseType == "dogLegged") {
      // First Flight
      let meshNumber = 0;
      this.points[meshNumber] = [];
      let stepsInNextMesh = false;
      if (this.stepsArrangement[1][0]) {
        stepsInNextMesh = true;
      }
      this.providePointsFlight(startPoint, meshNumber, stepsInNextMesh);

      // Middle Part
      meshNumber = 1;
      this.points[meshNumber] = [];
      if (!this.stepsArrangement[meshNumber][0]) {
        this.providePointsLanding(startPoint, meshNumber);
      } else {
        this.providePointsFlightWithLanding(startPoint, meshNumber);
      }

      // Infinite Staircase
      let i;
      for (i = 2; i < this.stepsArrangement.length - 1; i++) {
        this.points[i] = [];
        if (i % 2 == 0) {
          this.providePointsFlight(startPoint, i, false);
        } else {
          this.providePointsLanding(startPoint, i, false);
        }
      }
      // Last Flight
      meshNumber = i;
      this.points[meshNumber] = [];
      this.providePointsFlight(startPoint, meshNumber, false, stepsInNextMesh);
    } else if (this.staircaseType == "lShaped") {
      let meshNumber = 0;
      this.points[meshNumber] = [];
      this.providePointsFlight(startPoint, meshNumber, true, false);

      meshNumber = 1;
      this.points[meshNumber] = [];
      this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
      x += this.width;
      this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
      this.providePointsStairTop([x, y, z], meshNumber);
      let lastPointIndex = this.points[meshNumber].length - 1;
      let startPointForBottom = [
        this.points[meshNumber][lastPointIndex].x,
        this.points[meshNumber][lastPointIndex].y,
        this.points[meshNumber][lastPointIndex].z,
      ];

      // Top Side Transition
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0],
          startPointForBottom[1] - (this.depth * this.h) / this.tread,
          startPointForBottom[2]
        )
      );
      this.providePointsStairBottom(startPointForBottom, meshNumber);

      // Bottom Side Transition
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPoint[0] +
            this.width +
            (this.depth * (this.h - this.tread)) / this.riser,
          startPoint[1] - this.depth,
          z
        )
      );
      this.points[meshNumber].push(
        new BABYLON.Vector3(startPoint[0], startPoint[1] - this.depth, z)
      );
      // this.points[meshNumber].push(new BABYLON.Vector3((startPoint[0] + (this.depth * this.h) / this.riser), startPoint[1], startPoint[2]))
      // this.points[meshNumber].push(new BABYLON.Vector3(startPoint[0], startPoint[1], startPoint[2]))
    } else if (this.staircaseType == "square") {
      // First Flight
      let meshNumber = 0;
      this.points[meshNumber] = [];
      this.providePointsFlight(startPoint, meshNumber, true, false);

      let i;
      for (let i = 1; i < this.stepsArrangement.length; i++) {
        this.points[i] = [];
        if (i % 2 == 0) {
          this.providePointsFlight(startPoint, i, true, true);
        } else {
          this.providePointsFlightWithLanding(startPoint, i);
        }
      }
    } else {
      NewLogger.logError("Unknown staircase type");
      status = false;
    }
    return status;
  }

  /**
   * Provides points for Intermediate Flights
   * @param {[number, number, number]} startPoint
   * @param {number} meshNumber
   * @param {boolean} stepsInNextMesh
   * @param {boolean} stepsInPrevMesh
   *
   */
  providePointsFlight(
    startPoint,
    meshNumber,
    stepsInNextMesh = false,
    stepsInPrevMesh = false
  ) {
    // this.points[meshNumber] = []
    let x = startPoint[0],
      y = startPoint[1],
      z = startPoint[2];
    let initialOffsetX = 0;
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    if (stepsInPrevMesh && !stepsInNextMesh) {
      x += (this.depth * (this.h - this.tread)) / this.riser;
      this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    }
    if (this.stepsArrangement[meshNumber][0] < this.stepsArrangement[0][0]) {
      x += this.tread;
      this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    }
    initialOffsetX = x - startPoint[0];
    this.providePointsStairTop([x, y, z], meshNumber);
    let lastPointIndex = this.points[meshNumber].length - 1;
    let startPointForBottom = [
      this.points[meshNumber][lastPointIndex].x,
      this.points[meshNumber][lastPointIndex].y,
      this.points[meshNumber][lastPointIndex].z,
    ];
    if(this.staircaseType === "custom"){
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0] + DisplayOperation.getOriginalDimension(1985, "millimeter"),
          startPointForBottom[1],
          startPointForBottom[2]
        )
      );
    }

    lastPointIndex = this.points[meshNumber].length - 1;
    startPointForBottom = [
      this.points[meshNumber][lastPointIndex].x,
      this.points[meshNumber][lastPointIndex].y,
      this.points[meshNumber][lastPointIndex].z,
    ];
    // Top Side Transition
    if (meshNumber == this.stepsArrangement.length - 1) {
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0],
          startPointForBottom[1] - (this.depth * this.h) / this.tread,
          startPointForBottom[2]
        )
      );
      if(this.staircaseType === "custom"){
        this.points[meshNumber].push(
          new BABYLON.Vector3(
            startPointForBottom[0] - DisplayOperation.getOriginalDimension(1985, "millimeter"),
            startPointForBottom[1] - (this.depth * this.h) / this.tread,
            startPointForBottom[2]
          )
        );
      }
    } else if (!stepsInNextMesh) {
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0],
          startPointForBottom[1] - this.depth,
          startPointForBottom[2]
        )
      );
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0] +
            (this.depth * (this.h - this.tread)) / this.riser,
          startPointForBottom[1] - this.depth,
          startPointForBottom[2]
        )
      );
    } else if (stepsInNextMesh) {
      // this.points[meshNumber].pop()
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0] +
            (this.depth * (this.h - this.tread)) / this.riser,
          startPointForBottom[1],
          startPointForBottom[2]
        )
      );
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPointForBottom[0] +
            (this.depth * (this.h - this.tread)) / this.riser,
          startPointForBottom[1] - this.depth,
          startPointForBottom[2]
        )
      );
    }
    this.providePointsStairBottom(startPointForBottom, meshNumber);

    // Bottom Side Transition
    if (meshNumber != 0) {
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPoint[0] +
            (this.depth * (this.h - this.tread)) / this.riser +
            initialOffsetX,
          startPoint[1] - this.depth,
          startPoint[2]
        )
      );
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPoint[0],
          startPoint[1] - this.depth,
          startPoint[2]
        )
      );
    } else {
      this.points[meshNumber].push(
        new BABYLON.Vector3(
          startPoint[0] + (this.depth * this.h) / this.riser,
          startPoint[1],
          startPoint[2]
        )
      );
    }
  }

  /**
   * Provides points for Intermediate Flights with Landings on both sides
   * @param {[number, number, number]} startPoint
   * @param {number} meshNumber
   * @param {boolean} stepsInNextMesh
   * @param {boolean} stepsInPrevMesh
   */
  providePointsFlightWithLanding(
    startPoint,
    meshNumber,
    stepsInNextMesh = false,
    stepsInPrevMesh = false
  ) {
    // this.points[meshNumber] = []
    let x = startPoint[0],
      y = startPoint[1],
      z = startPoint[2];
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    x += this.width;
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    let initialOffsetX = 0;
    if (this.stepsArrangement[meshNumber][0] < this.stepsArrangement[1][0]) {
      x += this.tread;
      initialOffsetX += this.tread;
      this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    }

    this.providePointsStairTop([x, y, z], meshNumber);
    let lastPointIndex = this.points[meshNumber].length - 1;
    [x, y, z] = [
      this.points[meshNumber][lastPointIndex].x,
      this.points[meshNumber][lastPointIndex].y,
      this.points[meshNumber][lastPointIndex].z,
    ];
    // x += this.stepsArrangement[meshNumber][0] * this.tread
    // y += this.stepsArrangement[meshNumber][0] * this.riser

    // Top Transition before Landing
    x += (this.depth * (this.h - this.tread)) / this.riser;
    // this.points[meshNumber].pop()
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));

    // Landing
    x += this.width;
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    y -= this.depth;
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));

    // Top Transition after Landing
    // this.points[meshNumber].push(new BABYLON.Vector3(x - this.width + this.depth * (this.h - this.tread) / this.riser, y, z))
    this.points[meshNumber].push(new BABYLON.Vector3(x - this.width, y, z));

    // this.providePointsStairBottom(startPointForBottom, meshNumber)

    // Botton Transition
    this.points[meshNumber].push(
      new BABYLON.Vector3(
        startPoint[0] +
          this.width +
          (this.depth * (this.h - this.tread)) / this.riser +
          initialOffsetX,
        startPoint[1] - this.depth,
        z
      )
    );
    this.points[meshNumber].push(
      new BABYLON.Vector3(startPoint[0], startPoint[1] - this.depth, z)
    );
  }

  providePointsStairTop(startPoint, meshNumber) {
    let x = startPoint[0],
      y = startPoint[1],
      z = startPoint[2];
    // this.points[meshNumber].push(new BABYLON.Vector3(x, y, z))
    for (let i = 0; i < this.stepsArrangement[meshNumber].length; i++) {
      // Landing
      if (i > 0) {
        this.points[meshNumber].pop();
        x += this.landings[meshNumber][i - 1] - this.tread;
        this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
      }

      // Normal Stairs
      for (let j = 0; j < this.stepsArrangement[meshNumber][i]; j++) {
        y += this.riser;
        this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
        x += this.tread;
        this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
      }
    }
  }

  providePointsStairBottom(startPointForBottom, meshNumber) {
    let x = startPointForBottom[0],
      y = startPointForBottom[1],
      z = startPointForBottom[2];
    // Landing Base
    let deltaX, deltaY;
    for (let i = this.stepsArrangement[meshNumber].length - 2; i >= 0; i--) {
      y -= this.stepsArrangement[meshNumber][i + 1] * this.riser;
      x -= this.stepsArrangement[meshNumber][i + 1] * this.tread;
      deltaX = (this.depth * (this.h - this.tread)) / this.riser;
      deltaY = this.depth;
      this.points[meshNumber].push(
        new BABYLON.Vector3(x + deltaX, y - deltaY, z)
      );
      x -= this.landings[meshNumber][i] - this.tread;
      this.points[meshNumber].push(
        new BABYLON.Vector3(x + deltaX, y - deltaY, z)
      );
    }
  }

  providePointsLanding(startPoint, meshNumber) {
    let x = startPoint[0],
      y = startPoint[1],
      z = startPoint[2];
    // let totalLandingLength = 0;
    // for(let i = 0; i < this.landings[0].length; i++){
    // 	totalLandingLength += (this.landings[0][i] - this.tread)
    // }
    // x += this.steps * this.tread + totalLandingLength
    // y += this.steps * this.riser
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    x +=
      2 * this.width +
      this.getStaircaseDimension("STAIRCASE_DOG_LEGGED_GAP_DEF");
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    y -= this.depth;
    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));

    // x = startPoint[0] + (this.depth * (this.h - this.tread) / this.riser)
    // this.points[meshNumber].push(new BABYLON.Vector3(x, y, z))

    x = startPoint[0];
    y = startPoint[1] - this.depth;

    this.points[meshNumber].push(new BABYLON.Vector3(x, y, z));
    // y - ((this.depth * this.h) / this.tread)

    // x -= (2 * this.width) + this.getStaircaseDimension("STAIRCASE_DOG_LEGGED_GAP_DEF")
    // this.points[meshNumber].push(new BABYLON.Vector3(x, y, z))
    // this.points[meshNumber].push(this.points[meshNumber][0])
  }

  // Object Properties
  getMeshObjectProperties() {
    if (this.mesh.sourceMesh)
      return this.mesh.sourceMesh.getSnaptrudeDS().getMeshObjectProperties();
    let props = [];
    // let meshDimensions = objectPropertiesView.getMeshDimensions(this.mesh);
    // props.push(new SnapProperty("Type",mesh.type,SnapElement.getDropDown(['Mass','Room','Column','Furniture'],(meshes,name) => objectPropertiesView.onTypeChange(mesh,name),true)));
    // props.push(new SnapProperty("Riser",mesh.room_type,SnapElement.getInputElement((meshs,name) =>objectPropertiesView.dimensionChange(mesh,name),true)));
    let unitScale = DisplayOperation.convertToDefaultDimension(1);
    props.push(
      new SnapProperty(
        "Category",
        "Staircase",
        SnapElement.getInputElement((meshs, value) => null, false)
      )
    );
    props.push(
      new SnapProperty(
        "Label",
        this.mesh.room_type,
        SnapElement.getInputElement(
          (meshs, name) => objectPropertiesView.onLabelChange(this.mesh, name),
          true
        )
      )
    );
    props.push(
      new SnapProperty(
        "Riser",
        DisplayOperation.convertToDefaultDimension(this.riser),
        SnapElement.getInputElement(
          (meshs, value) => this.objectPropertiesChanged("riser", value),
          true
        )
      )
    );
    props.push(
      new SnapProperty(
        "Tread",
        DisplayOperation.convertToDefaultDimension(this.tread),
        SnapElement.getInputElement(
          (meshs, value) => this.objectPropertiesChanged("tread", value),
          true
        )
      )
    );
    props.push(
      new SnapProperty(
        "Flight Width",
        DisplayOperation.convertToDefaultDimension(this.width),
        SnapElement.getInputElement(
          (meshs, value) => this.objectPropertiesChanged("width", value),
          true
        )
      )
    );
    props.push(
      new SnapProperty(
        "Depth",
        DisplayOperation.convertToDefaultDimension(this.depth),
        SnapElement.getInputElement(
          (meshs, value) => this.objectPropertiesChanged("depth", value),
          true
        )
      )
    );
    props.push(
      new SnapProperty(
        "Steps",
        this.steps,
        SnapElement.getInputElement(
          (meshs, value) => this.objectPropertiesChanged("steps", value),
          true
        )
      )
    );
    props.push(
      new SnapProperty(
        "Storey",
        this.storey,
        SnapElement.getInputElement(null, false)
      )
    );
    // props.push(new SnapProperty("Length", this.stairLength, SnapElement.getInputElement(null, false)));
    // props.push(new SnapProperty("Width", this.stairWidth, SnapElement.getInputElement(null, false)));
    // props.push(new SnapProperty("Area", this.stairArea, SnapElement.getInputElement(null, false)));
    props.push(
      new SnapProperty(
        "Volume",
        Math.abs(
          Math.round(
            parseFloat(
              DisplayOperation.convertToDefaultVolume(this.stairVolume)
            ) * 100
          ) / 100
        ),
        SnapElement.getInputElement(null, false)
      )
    );
    props.push(new SnapProperty("object-buttons", this.mesh, null, true));

    return props;
  }

  async objectPropertiesChanged(propName, newValue, options = {}) {
    let stack = Object.assign([], store.selectionStack);
    let commands = [],
      sourceMeshesUID = [];
    for (let i = 0; i <= stack.length - 1; i++) {
      if (stack[i].type === "staircase") {
        options.returnUndoCmd = true;
        options.updateUI = true;
        if (stack[i]) {
          if (
            !_.find(
              sourceMeshesUID,
              (mesh) => mesh === stack[i].uniqueId
            )
          ) {
            sourceMeshesUID.push(stack[i].uniqueId);
            // eslint-disable-next-line no-await-in-loop
            const commandsToPush = await stack[i].getSnaptrudeDS()._objectPropertiesChanged(propName, newValue, options);
            if(commandsToPush) commands.push(...commandsToPush);
          }
        } else {
          // eslint-disable-next-line no-await-in-loop 
          const commandsToPush = await stack[i].getSnaptrudeDS()._objectPropertiesChanged(propName, newValue, options);
          if(commandsToPush) commands.push(...commandsToPush);
        }
      }
    }
    CommandManager.execute(commands, ...commands.map((c) => false));
  }

  async _objectPropertiesChanged(propName, newValue, options = {}) {
    // console.log(propName, newValue)
    if (this.mesh.sourceMesh) {
      let toReturn = this.mesh.sourceMesh
        .getSnaptrudeDS()
        ._objectPropertiesChanged(propName, newValue, options);
      return toReturn;
    }
    if (_.isNil(options.returnUndoCmd)) options.returnUndoCmd = false;
    if (_.isNil(options.updateUI)) options.updateUI = true;
    this.storeMaterialInfo();
    let status = true,
      oldValue = 0;
    let propertyName = propName;
    // newValue = parseInt(newValue)
    let undoRedoCmdData = staircaseUndoRedo.commandManager_changeObjectProperty(
      this,
      propertyName
    );
    try {
      switch (propertyName) {
        case "riser":
          oldValue = this.riser;
          this.updateRiser(DisplayOperation.getOriginalDimension(newValue));
          break;
        case "tread":
          oldValue = this.tread;
          this.updateTread(DisplayOperation.getOriginalDimension(newValue));
          break;
        case "width":
          oldValue = this.width;
          this.updateWidth(DisplayOperation.getOriginalDimension(newValue));
          break;
        case "depth":
          oldValue = this.depth;
          this.updateDepth(DisplayOperation.getOriginalDimension(newValue));
          break;
        case "staircaseHeight":
          oldValue = this.staircaseHeight;
          this.updateHeight(newValue);
          break;
        case "steps":
          oldValue = this.steps;
          this.updateSteps(newValue);
          break;
      }
    } catch (error) {
      NewLogger.logError(error);
      status = false;
    }
    if (status) {
      if (options.updateUI) {
        removeSelectionBox(this.mesh);
        store.selectionStack.length = 0;
        DisplayOperation.removeDimensions();
        objectPropertiesView.updateObjectProperties(this.mesh);
      }
      this.updatePosition(this.mesh.position, this.unionDone);
      this.updateRotationAndScaling();
      this.createStaircase();
      await this.doUnion();
      this.mesh.BrepToMesh();
      let undoRedoCmd = staircaseUndoRedo.commandManager_changeObjectProperty(
        this,
        propertyName,
        undoRedoCmdData
      );
      if (options.updateUI)
        objectPropertiesView.updateObjectProperties(this.mesh);
      if (options.returnUndoCmd) return undoRedoCmd;
      else CommandManager.execute(undoRedoCmd, [false, false]);
    }
  }

  // Delete
  removeStaircaseFromLevel(level) {
    meshObjectMapping.deleteMapping(this.mesh);
    for (let i = 0; i < level.flyweight.staircases.length; i++) {
      if (this.mesh.uniqueId === level.flyweight.staircases[i].mesh.uniqueId) {
        level.flyweight.staircases.splice(i, 1);
        return;
      }
    }
  }

  isEdited() {
    return false;
  }

  markAsEdited() {}

  removeAsEdited() {}

  onElementMove(movementAmount) {
    movementAmount.y = 0;
  }

  storeMaterialInfo() {
    if (!this.mesh.material.subMaterials) {
      return;
    }
    this.mat = {};
    this.mat.material = this.mesh.material;
    this.mat.isMultiMaterial = this.mat.material.subMaterials ? true : false;
    if (!this.mesh.subMeshes[1]) {
      this.mat.materialIndex = this.mesh.subMeshes[0].materialIndex;
      return;
    } else {
      delete this.mat.materialIndex;
    }
    if (this.mat.isMultiMaterial) {
      let facetLocalNormals = this.mesh
        .getFacetLocalNormals()
        .map((vec) => vec.snaptrudeFunctions().roundOff(3));
      let face, faceId;
      let treadNormal = new BABYLON.Vector3(0, 1, 0);
      let riserArea = this.riser * this.width;
      this.mat.tread = {};
      this.mat.riser = {}; 
      this.mat.side = {};
      for (face in this.faceFacetMapping) {
        faceId = parseInt(face);
        if (
          facetLocalNormals[this.faceFacetMapping[face][0]].equals(treadNormal)
        ) {
          this.mat.tread.materialIndex =
            this.mesh.subMeshes[faceId].materialIndex;
        } else if (
          Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1
        ) {
          this.mat.riser.materialIndex =
            this.mesh.subMeshes[faceId].materialIndex;
        } else {
          this.mat.side.materialIndex =
            this.mesh.subMeshes[faceId].materialIndex;
        }
        if (
          this.mat.tread.materialIndex &&
          this.mat.riser.materialIndex &&
          this.mat.side.materialIndex
        ) {
          break;
        }
      }
    }
  }

  getFaceType(faceId) {
    if (!this.faceFacetMapping[faceId]) {
      NewLogger.logError("faceId not present");
      // console.log("faceId not present")
      return;
    }
    let facetLocalNormals = this.mesh
      .getFacetLocalNormals()
      .map((vec) => vec.snaptrudeFunctions().roundOff(3));
    let facetId = this.faceFacetMapping[faceId][0],
      normal = facetLocalNormals[facetId];
    let treadNormal = new BABYLON.Vector3(0, 1, 0);
    if (normal && normal.equals(treadNormal)) {
      return "tread";
    }
    let riserArea = this.riser * this.width;
    if (Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) {
      return "riser";
    } else {
      return "side";
    }
  }

  applyMaterial(pickInfo, materialURL, materialType) {
    if (this.mesh.sourceMesh) {
      this.mesh.sourceMesh
        .getSnaptrudeDS()
        .applyMaterial(pickInfo, materialURL, materialType);
    }
    let facetLocalNormals = this.mesh
      .getFacetLocalNormals()
      .map((vec) => vec.snaptrudeFunctions().roundOff(3));
    let facetId = pickInfo.faceId,
      face,
      normal = facetLocalNormals[facetId];
    let facetFaceMapping = generateFacetFaceMapping(this.faceFacetMapping),
      faceId,
      i = 0;

    // Material for Tread
    let treadNormal = new BABYLON.Vector3(0, 1, 0);
    if (normal.equals(treadNormal)) {
      // this.material.tread = [materialURL, materialType]
      for (face in this.faceFacetMapping) {
        faceId = parseInt(face);
        if (
          facetLocalNormals[this.faceFacetMapping[face][0]].equals(treadNormal)
        ) {
          applyMaterialByBREP(this.mesh, faceId, materialURL, materialType);
        }
      }
      return;
    }

    faceId = facetFaceMapping[facetId];
    let riserArea = this.riser * this.width;

    // Material for Riser
    if (Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) {
      // this.material.riser = [materialURL, materialType]
      for (face in this.faceFacetMapping) {
        faceId = parseInt(face);
        if (Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) {
          applyMaterialByBREP(this.mesh, faceId, materialURL, materialType);
        }
      }
      return;
    }

    // this.material.side = [materialURL, materialType]

    // Material for Sides
    for (face in this.faceFacetMapping) {
      faceId = parseInt(face);
      if (
        !(Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) &&
        !facetLocalNormals[this.faceFacetMapping[face][0]].equals(treadNormal)
      ) {
        applyMaterialByBREP(this.mesh, faceId, materialURL, materialType);
      }
    }
  }

  deleteMaterial(pickInfo) {
    let facetLocalNormals = this.mesh
      .getFacetLocalNormals()
      .map((vec) => vec.snaptrudeFunctions().roundOff(3));
    let facetId = pickInfo.faceId,
      face,
      normal = facetLocalNormals[facetId];
    let facetFaceMapping = generateFacetFaceMapping(this.faceFacetMapping),
      faceId,
      i = 0;

    // Material for Tread
    let treadNormal = new BABYLON.Vector3(0, 1, 0);
    if (normal.equals(treadNormal)) {
      // this.material.tread = [materialURL, materialType]
      for (face in this.faceFacetMapping) {
        faceId = parseInt(face);
        if (
          facetLocalNormals[this.faceFacetMapping[face][0]].equals(treadNormal)
        ) {
          // applyMaterialByBREP(this.mesh, faceId, materialURL, materialType)
          this.mesh.subMeshes[faceId].materialIndex = 0;
          this.brep.getFaces()[faceId].materialIndex = 0;
        }
      }
      return;
    }

    faceId = facetFaceMapping[facetId];
    let riserArea = this.riser * this.width;

    // Material for Riser
    if (Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) {
      // this.material.riser = [materialURL, materialType]
      for (face in this.faceFacetMapping) {
        faceId = parseInt(face);
        if (Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) {
          this.mesh.subMeshes[faceId].materialIndex = 0;
          this.brep.getFaces()[faceId].materialIndex = 0;
        }
      }
      return;
    }

    // this.material.side = [materialURL, materialType]

    // Material for Sides
    for (face in this.faceFacetMapping) {
      faceId = parseInt(face);
      if (
        !(Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1) &&
        !facetLocalNormals[this.faceFacetMapping[face][0]].equals(treadNormal)
      ) {
        this.mesh.subMeshes[faceId].materialIndex = 0;
        this.brep.getFaces()[faceId].materialIndex = 0;
      }
    }
  }

  reapplyMaterial() {
    this.mesh.material = this.mat.material;
    if (this.mat.materialIndex) {
      let i;
      for (let i in this.mesh.subMeshes) {
        this.mesh.subMeshes[i].materialIndex = this.mat.materialIndex;
        this.brep.faces[i].materialIndex = this.mat.materialIndex;
      }
      delete this.mat;
      return;
    }
    let facetLocalNormals = this.mesh
      .getFacetLocalNormals()
      .map((vec) => vec.snaptrudeFunctions().roundOff(3));
    let face, faceId;
    let treadNormal = new BABYLON.Vector3(0, 1, 0);
    let riserArea = this.riser * this.width;
    for (face in this.faceFacetMapping) {
      faceId = parseInt(face);
      if (
        facetLocalNormals[this.faceFacetMapping[face][0]].equals(treadNormal)
      ) {
        this.mesh.subMeshes[faceId].materialIndex =
          this.mat.tread.materialIndex;
        this.brep.faces[faceId].materialIndex = this.mat.tread.materialIndex;
      } else if (
        Math.abs(getFaceArea(this.mesh, null, faceId) - riserArea) < 0.1
      ) {
        this.mesh.subMeshes[faceId].materialIndex =
          this.mat.riser.materialIndex;
        this.brep.faces[faceId].materialIndex = this.mat.riser.materialIndex;
      } else {
        this.mesh.subMeshes[faceId].materialIndex = this.mat.side.materialIndex;
        this.brep.faces[faceId].materialIndex = this.mat.side.materialIndex;
      }
    }
    delete this.mat;
  }

  /**
   * Make object visible in store.scene
   */
  show() {
    this.mesh.isVisible = true;
  }

  /**
   * Hides object in store.scene
   */
  hide() {
    this.mesh.isVisible = false;
  }

  /**
   * updates the base of object on lower storey height change
   * @param heightDifference
   */
  updateBase(heightDifference) {
    function isOfBalconyHeightOrLess() {
      let boundingBox = this.mesh.getBoundingInfo().boundingBox;
      return (
        boundingBox.extendSizeWorld.y * 2 <=
        StoreyMutation._CONSTANTS.balconyHeight
      );
    }

    if (!(!isOfBalconyHeightOrLess.call(this) && this.isEdited())) {
      if (this.storey > 0) {
        this.mesh.position.y += heightDifference;
      } else {
        this.mesh.position.y -= heightDifference;
      }
    }
  }

  setThickness() {}

  setRevitParameter(parameter, value){
    this.revitParameters[parameter] = value
  //   if(parameter == "runWidth"){
  //     this.revitParameters.runWidth = value
  //   }
  //   if(parameter == "position"){

  //   }
  }
}
export { Staircase };
