import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import {
  changeToOrthoViewCamera,
  changeToIsoViewCamera,
} from "./cameraFuncs.js";
import {
  onSolid,
  deepCopyObject,
  getSerializedFormOfMesh,
  setActiveLayerAndRecord,
} from "../modules/extrafunc.js";
import {
  setLayerTransperancy,
  showDoorWindow2D,
  createGrid,
} from "./sceneFuncs.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { angleMeasure } from "./angleMeasure.js";
import { measureFunction } from "./measureEvents.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { labelView } from "./labelView.js";
import { areaView } from "./areaView.js";
import { setScaleOperation } from "../modules/setScaleOperations/setScaleOperation.js";
import {deleteMaterialInBackend, saveMaterialInBackend} from "./applyMaterialFuncs.js";
import { FloorPlan } from "../modules/snaptrudeDS/floorplan.ds.js";
import { AutoSave } from "../modules/socket/autoSave.js";
import reduxStore from "../stateManagers/store/reduxStore.js";
import {appendLayer, deleteLayer} from "../stateManagers/reducers/objectProperties/storeysSlice.js";
import imageLayer from "../../assets/tiles/imageLayer.svg";
import sunpath from "../modules/sunpath/sunpathOperations";
import {Command} from "../modules/commandManager/Command";
import {CommandManager} from "../modules/commandManager/CommandManager";
import {removeMeshSelectionChildren} from "./meshEvents";
import {meshUniqueIdMapper} from "../modules/utilityFunctions/meshUniqueIdMapper";
import axios from "axios";
import {ORIGIN} from "../../services/url.constants";
import {importPdfOperations} from "./importPdfOperations";
import {handleShowOrHideForCAD} from "../modules/cadImporter/cadServices";
import {cameraController} from "../modules/cameraControl/cameraController";
import {createReferenceGround} from "../modules/meshoperations/moveOperations/moveUtil";
import {StoreyMutation} from "../modules/storeyEngine/storeyMutations";
import {commandUtils} from "../modules/commandManager/CommandUtils";

var isTwoDimension = false;
var twoInitializedStruct = false;
var floorplanImage = new Image();
const currentURL = window.location.href;
const model = currentURL.slice(
  currentURL.indexOf("model") + 6,
  currentURL.indexOf("model") + 12
);
var scaleFactor;
var twoZoomedToStructure = null;
var twoZoomedToLevel = null;
var twoZoomedToStructureID = null;
var twoZoomedToLevelID = null;
var twoImageWidth = null;
var twoImageHeight = null;
var $scope = null;

// store.angular.element(function () {
//   $scope = store.angular.element(appElement).scope();
//   $scope = $scope.$$childHead;
// });

$.get({
  url: "/rest_2d/apisketch/" + model + "/",
  async: false,
  success: function (response) {
    localStorage.setItem("project_details", JSON.stringify(response.project));
    store.userid = response.user;
    if (response.floorplan != null) {
      floorplanImage.src = response.floorplan;
    }

    // if(!response.floorplan.includes("Blank")){floorplanImage.src = response.floorplan};
  },
});

const goIntoTwoD = (entry, flag) => {
  //if(!twoInitializedStruct){
  //PREFERABLY CALL THIS FUNCTION *ONCE* IN WEBTRUDE.HTML
  //    twoInitStructures();
  //    twoInitializedStruct = true;
  //}

  // const structureCollection = StructureCollection.getInstance();
  // const allStructures = structureCollection.getStructures();
  // for(structure in allStructures){
  //     twoZoomedToStructureID = structure;
  //     //twoZoomedToStructure = allStructures[structure];
  //
  // }
  // const allLevels = allStructures[twoZoomedToStructureID].getAllLevels();
  // for(level in allLevels){
  //     twoZoomedToLevelID = level;
  //     //twoZoomedToLevel = allLevels[level];
  // }
  // document.getElementById('sketch').style.display = 'block';
  // document.getElementById("erase").style.display = 'block';
  // document.getElementById("set-scale").style.display = 'block';
  // document.getElementById("removeElements").style.display = 'block';
  // document.getElementById("editPolygon").style.display = 'block';
  cameraController.recordPriorParams();
  const structureCollection = StructureCollection.getInstance();
  const structure =
    structureCollection.getStructures()[store.activeLayer.structure_id];
  // structure.hideNonActiveStoreyMeshes(activeLayer.storey);
  store.isCADFile = store.activeLayer.layerType.toLowerCase() === "cad";
  let movingFromOneStoreyToAnother = false;
  if (store.$scope.isTwoDimension) movingFromOneStoreyToAnother = true;
  // if (!store.$scope.isTwoDimension) changeToOrthoViewCamera(entry === "fitImage");

  /*
  Setting arbitrary camera position before switching to ortho view.
  This ensures objects are not out of bounds for camera
   */
  store.scene.activeCamera.position = new BABYLON.Vector3(0, 400, 0);

  changeToOrthoViewCamera(entry === "fitImage", !movingFromOneStoreyToAnother, entry === "init");
  if (entry === "fitImage" || store.isCADFile)
    changeToOrthoViewCamera(entry === "fitImage", null, entry === "init");

  store.$scope.isTwoDimension = true;

  // Set 3D-2D view. Should not run on project initialization
  if (entry !== "init") {
    cameraController.setParams();
  }

  //for orthographic views toggle
  const orthoViewUpdatedEvent = new CustomEvent("orthoViewUpdated", {
    detail: { 
      isActive: true,
    }
  })
  window.dispatchEvent(orthoViewUpdatedEvent);

  let storeyOfInterest = structure
    .getStoreyData()
    .getStoreyByValue(store.activeLayer.storey);
  
  // For Editor state change only.
  const viewTypeUpdated = new CustomEvent("viewTypeUpdated", {
    detail: {
      viewType2D: true,
      selectedStorey: storeyOfInterest.value
    }
  })
  window.dispatchEvent(viewTypeUpdated);
  
  let imageLayer = storeyOfInterest.layerData.getLayerByName(
    "image",
    store.activeLayer.storey
  );

  if (imageLayer) {
    if (imageLayer.floorplans.length > 0 && store.$scope.isTwoDimension){
      //layer toggle check
      //isVisible check from hide/show/isolate
      if(imageLayer.hidden){
        imageLayer.floorplans[0].deactivate();
      }
      else{
        imageLayer.floorplans[0].activate();
      }
    }
  }

  onSolid();
  // changeToOrthoViewCamera(entry === "fitImage", flag);
  setLayerTransperancy(null, movingFromOneStoreyToAnother);
  showDoorWindow2D();
  createGrid(
    store.$scope.userSetBIMProperties.gridEnabled,
    store.$scope.userSetBIMProperties.gridUnitVal
  );
  // onSolid();
  uiIndicatorsHandler.remove();
  
  const currentStorey = StoreyMutation.getAParticularStorey(store.activeLayer.storey);
  if (currentStorey) createReferenceGround(currentStorey.base);

  if (store.ACTIVE_EVENT.event === "angleMeasure") angleMeasure.resetAngleMeasure();
  if (store.ACTIVE_EVENT.event === "measureMesh") measureFunction.cleanUp();
  if(store.areShadowsEnabled){
    sunpath.toggleShadows({goingInto2D: true});
  }

  //if (!movingFromOneStoreyToAnother) hideAllRoofs(false, true);
  // if (!movingFromOneStoreyToAnother) hideAllRoofs(false, true);

  //twoInitEvents();
  importPdfOperations.handleShowOrHideForPDF(storeyOfInterest);
  handleShowOrHideForCAD(storeyOfInterest);
  // removeMouseEvents();
  // store.idle = true;
  store.scene.lights[2].intensity = 0;
  store.scene.lights[1].intensity = 0.4;
};

const goOutOfTwoD = () => {
  cameraController.recordPriorParams();
  store.$scope.isTwoDimension = false;
  //for orthographic views toggle
  const orthoViewUpdatedEvent = new CustomEvent("orthoViewUpdated", {
    detail: {
      isActive: false,
    }
  })
  window.dispatchEvent(orthoViewUpdatedEvent);

  twoZoomedToStructure = null;
  twoZoomedToStructureID = null;
  twoZoomedToLevel = null;
  twoZoomedToLevelID = null;
  twoImageWidth = null;
  twoImageHeight = null;
  /* AG-RE: ANGULAR REFERENCE */
  // $scope.store.$scope.isTwoDimension = store.$scope.isTwoDimension;

  let type = "";
  store.scene.meshes.some((mesh) => {
    if (mesh.type) {
      if (["wall", "floor", "roof"].includes(mesh.type.toLowerCase())) {
        type = "bim_building_position"; //check for 2D/3D or mass/BIM happens inside sortTools
        return true;
      }
    }
  });

  if(Array.isArray(store.daylightAnalysis.meshes)) {
    store.daylightAnalysis.meshes.forEach((m) => m.isVisible = true)
  }
  /* AG-RE: ANGULAR REFERENCE */
  // $scope.sortTools(type);
  // document.getElementById('sketch').style.display = 'none';
  // document.getElementById("erase").style.display = 'none';
  // document.getElementById("set-scale").style.display = 'none';
  // document.getElementById("removeElements").style.display = 'none';
  // document.getElementById("editPolygon").style.display = 'none';

  const structureCollection = StructureCollection.getInstance();
  const structure =
    structureCollection.getStructures()[store.activeLayer.structure_id];
  let storeyOfInterest = structure
    .getStoreyData()
    .getStoreyByValue(store.activeLayer.storey);
  // structure.showNonActiveStoreyMeshes();

  // try {
  //     // let twoPlane = store.scene.getMeshByName('twoPlane');
  //     // twoPlane.visibility = 0;
  //     if (activeLayer.floorplans.length > 0)
  //         store.activeLayer.floorplans[0].deactivate();
  // }
  // catch{}

  createReferenceGround(BABYLON.Vector3.Zero());
  DisplayOperation.removeDimensions();
  labelView.resetOperation();
  areaView.resetOperation();
  setScaleOperation._reset(true);
  // removeMouseEvents();
  changeToIsoViewCamera();
  // Set 2D-3D view
  cameraController.setParams();
  setLayerTransperancy();
  showDoorWindow2D();
  createGrid(false);
  onSolid();
  $("#ortho_camera_button").css("border", "none");

  // showAllRoofs(false, true);
  importPdfOperations.handleShowOrHideForPDF(storeyOfInterest);
  handleShowOrHideForCAD(storeyOfInterest);


  // For BottomRightView UI change only.
  const viewTypeUpdated = new CustomEvent("viewTypeUpdated", {
    detail: {
      viewType2D: false,
    }
  })
  window.dispatchEvent(viewTypeUpdated);
  // cameraController.activateOrbitMode();
  // store.idle = true;
  // store.scene.lights[0].intensity = 0.7;
  store.scene.lights[2].intensity = 0.7;
  store.scene.lights[1].intensity = 0;
};

function _checkIfMaterialAlreadyExistsAndDispose(storeyVal, structureNum){
  let _name = "twoMaterialPlane_" + storeyVal + "_" + structureNum;
  let _material = store.scene.getMaterialByName(_name);
  if(_material){
    deleteMaterialInBackend(_material);
    _material.dispose();
  }
}

function initFloorplan(data) {
  if(!data){
    data = this.data;
  }
  let floorplanImage = null;
  if(data.floorplanImage){
    floorplanImage = data.floorplanImage;
  }
  let storeyVal = data.storeyVal;
  let structureNum = data.structureNum;
  const storey = storeyVal;

  _checkIfMaterialAlreadyExistsAndDispose(storeyVal, structureNum);
  if (store.activeLayer.floorplans.length > 0)
    store.activeLayer.floorplans[0].deactivate();

  let twoMaterialPlane = new BABYLON.StandardMaterial(
    "twoMaterialPlane_" + storeyVal + "_" + structureNum,
    store.scene
  );
  if(data.materialTexture){
    twoMaterialPlane.diffuseTexture = data.materialTexture;
  }
  else{
    twoMaterialPlane.diffuseTexture = new BABYLON.Texture(
      floorplanImage?.src,
      store.scene
    );
  }
  twoMaterialPlane.specularColor = new BABYLON.Color3(0, 0, 0);
  twoMaterialPlane.backFaceCulling = false;
  // twoMaterialPlane.zOffset = 10;

  saveMaterialInBackend(twoMaterialPlane);

  const getWidth = () => {
    if (window.self.innerHeight) {
      return window.self.innerWidth;
    }
    if (document.documentElement && document.documentElement.clientWidth) {
      return document.documentElement.clientWidth;
    }
    if (document.body) {
      return document.body.clientWidth;
    }
  };

  const getHeight = () => {
    if (window.self.innerHeight) {
      return window.self.innerHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      return document.documentElement.clientHeight;
    }
    if (document.body) {
      return document.body.clientHeight;
    }
  };

  //floorplanImage.onload = () => {
  let imageWidth;
  let imageHeight;
  let floorPlanImageWidth = null;
  let floorPlanImageHeight = null;
  if(floorplanImage){
    floorPlanImageWidth = floorplanImage.width;
    floorPlanImageHeight = floorplanImage.height;
  }
  else if(data.floorPlanImageWidth){
    floorPlanImageWidth = data.floorPlanImageWidth;
    floorPlanImageHeight = data.floorPlanImageHeight;
  }
  if (
    (getWidth() - 150) / (getHeight() - 40) <
    floorPlanImageWidth / floorPlanImageHeight
  ) {
    imageWidth = getWidth() - 150;
    imageHeight = (floorPlanImageHeight * imageWidth) / floorPlanImageWidth;
    scaleFactor = floorPlanImageWidth / imageWidth;
  } else {
    imageHeight = getHeight() - 40;
    imageWidth = (floorPlanImageWidth * imageHeight) / floorPlanImageHeight;
    scaleFactor = floorPlanImageHeight / imageHeight;
  }
  let width = imageWidth;
  let height = imageHeight;
  let twoPlane = BABYLON.MeshBuilder.CreatePlane(
    "twoPlane_" + storeyVal + "_" + structureNum,
    { width: width, height: height },
    store.scene
  );
  if(data.uniqueId){
    let bbInfo = twoPlane.getBoundingInfo();
    let len = bbInfo.boundingBox.extendSizeWorld.x * 2;
    //this is to bring any image to default snaptrude size.
    let _initialScaleFactor = DisplayOperation.getOriginalDimension(38000, "millimeter") / len;
    twoPlane.scaling.multiplyInPlace(
      new BABYLON.Vector3(_initialScaleFactor, _initialScaleFactor, _initialScaleFactor)
    );

    let _scaleFactor;
    if(data.scaleFactor){
      _scaleFactor = data.scaleFactor
    }
    else{
      _scaleFactor = scaleFactor;
    }
    twoPlane.scaling.multiplyInPlace(
      new BABYLON.Vector3(_scaleFactor, _scaleFactor, _scaleFactor)
    );

    twoPlane.computeWorldMatrix(true);
    bbInfo = twoPlane.getBoundingInfo();
    if( data.floorPlanPosition ){
      twoPlane.position = BABYLON.Vector3.FromArray( data.floorPlanPosition );
    }
    else{
      twoPlane.position.x -= bbInfo.boundingBox.minimumWorld.x;
      twoPlane.position.z -= bbInfo.boundingBox.maximumWorld.z;
    }
  }
  else{
    twoPlane.position.x = width / 2;
    twoPlane.position.z = -height / 2;
  }
  twoPlane.alphaIndex = 0;
  twoPlane.rotation.x = Math.PI / 2;
  twoPlane.material = twoMaterialPlane;
  twoPlane.material.backFaceCulling = false;
  twoPlane.type = "sketchPlane";
  // twoPlane.strNum = structureNum;
  twoPlane.storey = storey;
  twoPlane.structure_id = store.activeLayer.structure_id;
  twoImageWidth = imageWidth;
  twoImageHeight = imageHeight;
  if(data.rotationQuaternion){
    twoPlane.rotationQuaternion = BABYLON.Quaternion.FromArray(data.rotationQuaternion);
  }

  twoPlane.computeWorldMatrix(true);

  let floorPlan = new FloorPlan(twoPlane);
  floorPlan.storey = storey;
  floorPlan.initialScaleFactor = scaleFactor;
  floorPlan.scaleFactor = data.scaleFactor ? data.scaleFactor : 1;
  floorPlan.floorPlanImageHeight = floorPlanImageHeight;
  floorPlan.floorPlanImageWidth = floorPlanImageWidth;
  floorPlan.strNum = structureNum;
  floorPlan.identifierForBackend = (data.uniqueIdOfThePlanInBackend) ? data.uniqueIdOfThePlanInBackend : null;

  let _storeyOfInterest = StructureCollection.getInstance()
    .getStructures()
    [floorPlan.structure_id].getStoreyData()
    .getStoreyByValue(twoPlane.storey);

  let options  = {};
  if(data.layerId){
    options.layerId = data.layerId
  }

  let layer = _storeyOfInterest.layerData.addLayer(
    "Image",
    floorPlan.structure_id,
    "image",
    storey,
    options
  );

  // Set plane height based on storey
  twoPlane.position.y = _storeyOfInterest.base;

  floorPlan.layer_id = layer.id;
  floorPlan.mesh.layer_id = layer.id;
  if(data.uniqueId){
    // floorPlan.mesh.uniqueId = data.uniqueId;
    recreateImageInTheBackend(data.uniqueIdOfThePlanInBackend, data.storeyVal);
    meshUniqueIdMapper.update(floorPlan.mesh, data.uniqueId);
  }
  layer.addFloorPlan(floorPlan);

  data.floorPlanId = floorPlan.id;
  data.layerId = layer.id;
  data.layerName = layer.name;
  data.materialName = twoPlane.material?.name;
  data.floorPlan = floorPlan;
  data.serializedMesh = getSerializedFormOfMesh(floorPlan.mesh);
  data.uniqueId = floorPlan.mesh.uniqueId;
  data.structure_id = floorPlan.structure_id;
  
  reduxStore.dispatch(
    appendLayer({
      id: layer.id,
      title: layer.name,
      storey: storey,
      hidden: layer.hidden,
      heightMapToggle: layer.heightMapToggle,
      image: imageLayer,
      // subView: {
      //   allIcons: true,
      //   heightToggle: layer.heightMapToggle,
      //   thicknessSelector: false,
      //   colorPicker: false,
      // }
    })
  );
  // ScopeUtils.addLayer(layer);
  setActiveLayerAndRecord(
    _storeyOfInterest.layerData.getLayerByName("wall", storey)
  );
  goIntoTwoD("fitImage");
  setLayerTransperancy();
}

function deleteFloorPlan(data) {
  if (!data) {
    data = this.data;
  }
  if (data.structureNum) {
    let mesh = store.scene.getMeshByUniqueID(data.uniqueId);
    data.floorPlanPosition = mesh.position.asArray();
    if( mesh ){
      let meshSnaptrudeDs = mesh.getSnaptrudeDS();
      if(!data.scaleFactor){
        data.scaleFactor = meshSnaptrudeDs.scaleFactor;
      }
      let structure = StructureCollection.getInstance().getStructures()[
          meshSnaptrudeDs.structure_id
          ];
      let storey = structure
          .getStoreyData()
          .getStoreyByValue(meshSnaptrudeDs.storey);
      let layer = null;
      if (data.layerName) {
        layer = storey.layerData.getLayerByName(
            data.layerName,
            meshSnaptrudeDs.storey
        );
      } else if (data.layerId) {
        layer = storey.layerData.getLayerBylayerId(data.layerId);
      }
      let layerID = layer?.id;

      // removeMeshFromStructure(mesh);
      removeMeshSelectionChildren(mesh);
      mesh.dispose();
      storey.layerData.deleteLayer(layerID);
      reduxStore.dispatch(deleteLayer({layerId: layerID, storeyValue: data.storeyVal}));
      setActiveLayerAndRecord(storey.layerData.getLayerByName('wall', data.storeyVal));
      deleteImageFromBackend(data.uniqueIdOfThePlanInBackend, data.storeyVal);
    }
  }
}

function handleFloorPlanDeletionFromLayersUI(data, storey){

  //data.id is layer_id
  let structure = StructureCollection.getInstance().getStructures()[data.structure_id];
  let layerData = structure.getStoreyData().getStoreyByValue(storey).layerData;
  let layer = layerData.getLayerBylayerId(data.id);
  let floorPlanDS = layer.floorplans[0];

  // let structure = StructureCollection.getInstance().getStructures()[meshSnaptrudeDs.structure_id];

  let _getCommandLogic = () => {
    return {
      execute: deleteFloorPlan,
      unexecute: initFloorplan,
    };
  };

  let _getSaveData = function () {
    let addFloorPlanSaveData = AutoSave.getSaveDataPrototype();
    addFloorPlanSaveData.commandId =
      "Floorplan_Deletion_" + data.id;
    addFloorPlanSaveData.data.saveType = "deleteFloorPlan";
    addFloorPlanSaveData.data.identifier = {
      structure_id: data.structure_id,
      storey: storey,
      floorkey: store.floorkey,
      layer_id: data.id,
    };
    addFloorPlanSaveData.data.afterOperationData =
      deepCopyObject(floorPlanDS);
    addFloorPlanSaveData.data.afterOperationData.mesh =
      this.data.serializedMesh;
    addFloorPlanSaveData.data.afterOperationData.mesh.layer_id =
      data.layerId;
    return addFloorPlanSaveData;
  };

  let _dataNeededToRecreate = {
    fromDeleteKeyEvent: true,
    materialTexture: floorPlanDS.mesh.material?.diffuseTexture,
    storeyVal: storey,
    structureNum: floorPlanDS.strNum,
    uniqueId: floorPlanDS.mesh.uniqueId,
    layerId: data.id,
    scaleFactor: floorPlanDS.scaleFactor,
    floorPlanImageWidth: floorPlanDS.floorPlanImageWidth,
    floorPlanImageHeight: floorPlanDS.floorPlanImageHeight,
    uniqueIdOfThePlanInBackend: floorPlanDS.identifierForBackend,
    rotationQuaternion: floorPlanDS.mesh?.rotationQuaternion?.asArray(),
    serializedMesh: getSerializedFormOfMesh(floorPlanDS.mesh),
  };

  let cmd = new Command(
    "FloorPlanDeletion",
    _dataNeededToRecreate,
    _getCommandLogic(),
    _getSaveData
  );

  CommandManager.execute(cmd, true);
}

const _floorPlanFunctions = (floorplanImage, storeyVal, structureNum, uniqueIdOfThePlanInBackend) => {

  let _addFloorPlan = function () {
    initFloorplan(this.data);
  };

  let _deleteFloorPlan = function () {
    deleteFloorPlan(this.data);
  };

  let _getCommandLogic = function () {
    return {
      execute: _addFloorPlan,
      unexecute: _deleteFloorPlan,
    };
  };

  let _getSaveData = function () {
    let addFloorPlanSaveData = AutoSave.getSaveDataPrototype();
    addFloorPlanSaveData.commandId = "addFloorPlan_" + this.data.layerId;
    addFloorPlanSaveData.data.saveType = "addFloorPlan";
    addFloorPlanSaveData.data.identifier = {
      structure_id: this.data.structure_id,
      storey: this.data.storeyVal,
      layerName: this.data.layerName,
      floorkey: store.floorkey,
      layer_id: this.data.layerId,
    };
    addFloorPlanSaveData.data.afterOperationData = deepCopyObject(this.data.floorPlan);
    addFloorPlanSaveData.data.afterOperationData.mesh = this.data.serializedMesh;
    addFloorPlanSaveData.data.afterOperationData.mesh.layer_id = this.data.layerId;
    return addFloorPlanSaveData;
  }

  let _executeEvent = function () {

    let _data = {
      floorplanImage: floorplanImage,
      storeyVal: parseInt(storeyVal),
      structureNum: structureNum,
      uniqueIdOfThePlanInBackend: uniqueIdOfThePlanInBackend
    };

    let cmd = new Command(
      "addFloorPlan",
      _data,
      _getCommandLogic(),
      _getSaveData
    );
    CommandManager.execute(cmd, true);
  };

  _executeEvent();
}

function handleCollaborationForFloorPlanDeletion (){

  function executeFromSaveData( saveData ){
    let data = saveData.afterOperationData;

    data.uniqueId = data?.mesh?.meshes[0]?.uniqueId;
    data.layerId = data?.layer_id;
    data.storeyVal = data?.storey;
    data.structureNum = data?.strNum;
    data.uniqueIdOfThePlanInBackend = data?.identifierForBackend;

    deleteFloorPlan(data);
  }
  // Re-check this later.
  function unExecuteFromSaveData( saveData ){

    // let data = saveData.afterOperationData;
    // if(data){
    //   let dataForCreation = {
    //     materialTexture: data.mesh?.materials[0]?.diffuseTexture,
    //     storeyVal: data.storey,
    //     structureNum: data.strNum,
    //     uniqueId: data.mesh?.meshes[0]?.uniqueId,
    //     layerId: data.layer_id,
    //     scaleFactor: data.scaleFactor,
    //     floorPlanImageWidth: data.floorPlanImageWidth,
    //     floorPlanImageHeight: data.floorPlanImageHeight,
    //     uniqueIdOfThePlanInBackend: data.identifierForBackend,
    //     fromAutoListener: true
    //   }
      commandUtils.storeyOperations._executeAddFloorplan( saveData );
  }

  return{
    executeFromSaveData,
    unExecuteFromSaveData
  }

}

export const deleteImageFromBackend = function (uniqueIdOfThePlanInBackend, storey){
  return new Promise((resolve, reject) => {
    let structure = 1;
    const data = {
      uniqueid: uniqueIdOfThePlanInBackend,
      floorkey: store.floorkey,
      structure: structure,
      structureID: store.activeLayer.structure_id,
      storey: storey,
    };
    // console.log("delete data", data);
    const formData = new FormData();
    for (let item in data) {
      formData.append(item, data[item]);
    }

    axios
      .post(ORIGIN + '/tegiplan/', formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then(r =>{
        console.log("Deleted Floorplan");
      })
      .catch((e) => {console.log(e);
      })
  })
}

export const recreateImageInTheBackend = function(uniqueIdOfThePlanInBackend, storey){
  return new Promise((resolve, reject) => {
    let structure = 1;
    const data = {
      uniqueid: uniqueIdOfThePlanInBackend,
      floorkey: store.floorkey,
      structure: structure,
      structureID: store.activeLayer.structure_id,
      storey: storey,
    };
    const formData = new FormData();
    for (let item in data) {
      formData.append(item, data[item]);
    }

    axios
      .post(ORIGIN + '/updatedeleteinfo/', formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then(r =>{
        console.log("Recreated Floorplan");
      })
      .catch((e) => {console.log(e);
      })
  })
}

const is2D = () => store.$scope.isTwoDimension;

/**
 * Helper function to set camera view for specific projects
 * Mostly used for demo projects to be displayed on main website
 * @param floorKey
 */
const goToViewForSpecificProject = (floorKey) => {
  const fK = "A37QEV";

  if (floorKey !== fK) return;

  // Switch to 3D
  goOutOfTwoD();

  // Set the required camera view data
  const position = new BABYLON.Vector3(
    318.8350612394484, 86.73761437551346, -311.48764043572146);
  const target = new BABYLON.Vector3(
    131.2727027701727, 66.15846676902854, -116.95572856603323);

  store.scene.activeCamera.position = position;
  store.scene.activeCamera.target = target;
}

export {
  isTwoDimension,
  twoInitializedStruct,
  floorplanImage,
  currentURL,
  model,
  scaleFactor,
  twoZoomedToStructure,
  twoZoomedToLevel,
  twoZoomedToStructureID,
  twoZoomedToLevelID,
  twoImageWidth,
  twoImageHeight,
  $scope,
  goIntoTwoD,
  goOutOfTwoD,
  initFloorplan,
  _floorPlanFunctions,
  deleteFloorPlan,
  handleFloorPlanDeletionFromLayersUI,
  is2D,
  handleCollaborationForFloorPlanDeletion,
  goToViewForSpecificProject
};
