import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from '../Button';
import { showHideCADConfirmationModal } from './helper';
import { deleteCadLayer} from '../../snaptrude/modules/cadImporter/cadServices';

const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  // min-height: 200px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
  .invite-items{
    position: relative;
    max-height: 30vh;
    overflow-y: auto;
  }
  .invite-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    margin: 5px 0px;
    position: relatvie;
  }
  .invite-item .status{
    position: absolute;
    right: 30px;
    text-transform: capitalize;
  }
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: col;
  padding: 0px 10px;
  align-items: center;
`
const StyledCol = styled.div`
  flex: 1;
  padding: 10px;
`

function CADConfirmationModal({
  isOpen,
  onClose,
  layer,
}) {
  const handleCancel = () => {
    deleteCadLayer(layer, layer.storey);
    showHideCADConfirmationModal(false);
  }
  const handleContinue = () => {
    showHideCADConfirmationModal(false);
  }
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
      id="CAD-confirmation-modal"
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Confirm
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            {/* <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" /> */}
          </ModalChild>
        </ModalHeader>
        <ModalBody>
            <StyledRow>
                <StyledCol>
                    The file being drawn out of bounds, would you like to continue?
                </StyledCol>
            </StyledRow>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button onPress={handleCancel} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="Cancel" />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button primary title="Continue" onPress={handleContinue} />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  )
}

export default CADConfirmationModal;
