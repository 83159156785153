import React, { useEffect } from "react";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createStripeSubscription, retrieveStripeSubscription } from "../../services/payment.service"

import { paymentStyles, ButtonWrapper, SuccessMessage } from "./styles";

import Button from "../../components/Button/index";
import Text from "../../components/Text";
import { RowContainer, StyledDropdown } from "./styles";

import { ColumnWrapper, ErrorContainer, ErrorMessage } from "./styles";

import * as constants from "./constants";
import Input, { InputLabel } from "../../components/Input/index";
import arrow from "../../assets/icons/arrow.svg";
import ScrollableDropdown from "../../components/ScrollableDropdown"
import { useState } from 'react';
import COUNTRY_CODES, { getCountryCodeByValue } from "../../common/utils/country_codes";
import { getUserCountry } from "../../services/account.service";

export default ({ customerId, noOfLicenses, couponId, priceId }) => {
  const [name, setName] = useState('')
  const [country, setCountry] = useState(COUNTRY_CODES[getUserCountry(false)] || COUNTRY_CODES['US'])
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isCardComplete, setIsCardComplete] = useState(false)

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setCountry(COUNTRY_CODES[getUserCountry()])
  }, [])

  const inputAlphaNumeric = (e, callback) => {

    const value = (e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '';

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === 'function') {
      return callback(value);
    }
  }

  const onCardChange = event => {
    setIsCardComplete(event.complete);

    if (event.error) {
      setIsCardComplete(false);
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = async (event) => {
    setErrorMessage('');
    // Block native form submission, prevent refresh
    event.preventDefault();

    if (!stripe || !elements) { return; }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
        address: {
          'city': city,
          'line1': address,
          'line2': '',
          'postal_code': zip,
          'state': state,
          'country': getCountryCodeByValue(country),
        }
      }
    });

    if (result.error) {
      // TODO: Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      setErrorMessage(result.error.message);
    } else {
      // The payment has been processed!
      let paymentIntent = await createStripeSubscription({
        customerId: customerId,
        paymentMethodId: result.paymentMethod.id,
        priceId: priceId,
        quantity: noOfLicenses,
        couponId: couponId,
        data: {
          name: name,
          address1: name,
          address2: "None",
          city: city,
          state: state,
          country: getCountryCodeByValue(country),
          postal_code: zip
        }
      });

      if (paymentIntent.paid == false) {
        const _result = await stripe.confirmCardPayment(paymentIntent.clientSecret)
        console.log(_result)

        if (_result.error) {
          console.log(_result.error.message); // TODO: show to user
          setErrorMessage(_result.error.message)
          return;
        } else {
          console.log(_result.paymentIntent.status)
          console.log(_result.paymentIntent.description)
        }
      }
      const stripeSubscription = await retrieveStripeSubscription();

      if (stripeSubscription.status == 'active') {
        setSuccessMessage('Successfully Subscribed for Pro Plan!')
      }
      console.log(stripeSubscription)
    }
  };

  const disableCheckoutButton =
    !name ||
    !address ||
    !city ||
    !state ||
    !zip ||
    !stripe ||
    !elements ||
    noOfLicenses <= 0 ||
    !isCardComplete;

  return (
    <form onSubmit={handleSubmit}>

      <RowContainer style={{ alignItems: "flex-start" }}>
        <div style={paymentStyles.widthStyle}>
          <Input
            label={constants.NAME_ON_CARD}
            type={"text"}
            value={name}
            required
            errorMessage="Sample error message"
            error={false}
            onPress={(e) => setName(e.target.value)}
            customInputContainerStyle={paymentStyles.cardNameContainer}
          />
          <br />
          <div style={{ padding: '10px', backgroundColor: '#F2F2F2' }}>
            <CardElement onChange={onCardChange}
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    backgroundColor: '#F2F2F2'
                  }
                }
              }}
            />
          </div>
          <div style={paymentStyles.countryInputWrapper}>
            <InputLabel>{constants.COUNTRY}</InputLabel>

          <ScrollableDropdown
            width="100%"
            onChange={setCountry}
            options={Object.values(COUNTRY_CODES)}
            selectedOption={country}
            value="country"
            name="countries"
            placeholder="Choose your Country"
            backgroundColor={'#F2F2F2' }
            wrapperHeight={'2.5rem'}
          />
            {/* <StyledDropdown onClick={(e) => {
              console.log(e)
              document.querySelectorAll(".dropdown-content").forEach(element => {
                element.style.display = ['none', ''].includes(element.style.display) ? 'block' : 'none';
              })
            }}>
              <div className="dropdown">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p>{country}</p>
                  <img src={arrow} alt=">" />
                </div>
                <div className="dropdown-content">
                  {Object.values(COUNTRY_CODES).map((item, index) => {
                    return (
                      <div
                        onClick={() =>
                          setCountry(item)
                        }
                        key={getCountryCodeByValue(index)}
                      >
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </StyledDropdown> */}
          </div>

          <Input
            label={constants.ADDRESS}
            type={"text"}
            value={address}
            required
            errorMessage="Sample error message"
            error={false}
            onPress={(e) => setAddress(e.target.value)}
            customInputContainerStyle={paymentStyles.addressContainer}
          />
          <Input
            label={constants.CITY}
            type={"text"}
            value={city}
            required
            onPress={(e) => setCity(e.target.value)}
            customInputContainerStyle={paymentStyles.addressContainer}
          />
          <RowContainer style={{ margin: "0.5rem 0 0 0" }}>
            <Input
              label={constants.STATE}
              type={"text"}
              value={state}
              required
              onPress={(e) => setState(e.target.value)}
              customInput={paymentStyles.zipContainer}
            />
            <Input
              label={constants.ZIP}
              type={"text"}
              value={zip}
              required
              maxLength={6}
              validator={/^[1-9]{1}[0-9]{2}\\s{0, 1}[0-9]{3}$/}
              onPress={(e) => inputAlphaNumeric(e, (value) => {
                setZip(value);
              })}
              customInput={paymentStyles.zipContainer}
            />
          </RowContainer>
        </div>

        <div style={paymentStyles.buttonContainer}>
          <div style={{ width: "100%" }}>
            <ButtonWrapper>
              <Button
                primary={true}
                type="submit"
                disabled={disableCheckoutButton}
                title={constants.PROCEED_TO_CHECKOUT}
                customButtonStyle={
                  disableCheckoutButton ? paymentStyles.disabledCheckoutButton : paymentStyles.checkoutButton
                }
              />
            </ButtonWrapper>
            <ColumnWrapper>
              {/* <ErrorContainer> */}
              <ErrorMessage>
                {errorMessage}
              </ErrorMessage>
              <SuccessMessage>
                {successMessage}
              </SuccessMessage>
              {/* </ErrorContainer> */}
              <Text style={paymentStyles.disclaimerText}>
                {constants.DISCLAIMER_TEXT}
              </Text>
              <Button
                onPress={() => {
                  window.analytics.track('opened terms and conditions on checkout page');
                  window.open("https://api.snaptrude.com/media/pdf/TermsAndConditions.pdf", "_blank")
                }}
                customButtonStyle={paymentStyles.termsAndConditionsButton}
                title={constants.TERMS_AND_CONDITONS}
              />
            </ColumnWrapper>
          </div>
        </div>
      </RowContainer>
    </form>
  );
};