import React from "react";
import PropTypes from "prop-types";

import Modal from "../../components/Modal/index";
import { ShareBody } from "./Share";
import { DropdowndownDropzone, DropdownFooter } from "./dropdownDropzone";
import { DropdowndownOverview } from "./dropdownOverview";
import { SingleUploadOverView } from "./singleUploadOverView";
import { ViewsModal } from "./viewsModal";
import Export from "./export";
import * as constants from "./constants";
import SaveAs from "./saveAs";
import { ViewsModalFooter } from "./ViewsModalFooter";

// TODO: REBUILD GENERIC MODAL.
const GenericModal = (props) => {
  const {
    isOpen,
    onClose,
    modalType,
    modalHeaderText,
    customProps,
    custonModalStyle,
  } = props;
  const getModalByType = () => {
    switch (modalType) {
      case constants.SHARE_MODAL:
        return <ShareBody customProps={customProps} onClose={onClose} />;
      case constants.IMPORT_WITH_DROPDOWN:
        return (
          <DropdowndownDropzone customProps={customProps} onClose={onClose} />
        );
      case constants.IMPORT_FILE_OVERVIEW:
        return (
          <DropdowndownOverview customProps={customProps} onClose={onClose} />
        );
      case constants.IMPORT_SINGLE_FILE_OVERVIEW:
        return (
          <SingleUploadOverView customProps={customProps} onClose={onClose} />
        );
      case constants.VIEWS_MODAL:
        return <ViewsModal customProps={customProps} onClose={onClose} />;
      case constants.EXPORT_MODAL:
        return <Export isOpen={isOpen} onClose={onClose} />;
      case constants.SAVE_AS_MODAL:
        return <SaveAs isOpen={isOpen} onClose={onClose} />;
      default:
        return <div />;
    }
  };

  const getModalFooterByType = () => {
    switch (modalType) {
      case constants.IMPORT_WITH_DROPDOWN:
        return <DropdownFooter customProps={customProps} onClose={onClose} />;
      case constants.VIEWS_MODAL:
        return <ViewsModalFooter customProps={customProps} onClose={onClose} />
      default:
        return <DropdownFooter customProps={customProps} onClose={onClose} />;
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      modalHeaderText={modalHeaderText || " "}
      modalBody={getModalByType()}
      modalFooter={getModalFooterByType()}
      custonModalStyle={custonModalStyle}
    />
  );
};

GenericModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  modalType: PropTypes.string,
  modalHeaderText: PropTypes.string,
  customProps: PropTypes.any,
};

export default GenericModal;
