"use strict";
/*jshint esversion: 6 */
import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js"
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { turnOffSnappingEngine,initializeSnappingEngine } from "./snapUtilities.js";
import { getAngleBetweenVectors } from "./snapFuncs.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { showAxisIndicator,showVertexIndicator,showEdgeIndicator,disposeSnapToObjects } from "../modules/meshoperations/moveOperations/moveUtil.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { externalUtil } from "../modules/externalUtil.js";
import { measureFunction } from "./measureEvents.js";
import { snapMeshforMeasure } from "./sceneStateFuncs.js";
import { isTwoDimension } from "./twoDimension.js";
import { findPrioritizedSnapPoint } from "./snapFuncsPrimary.js";

var angleMeasure = (function () {
  let anglePoint = null;
  let firstPoint = null;
  let lastPoint = null;
  let downClick = 0;
  let clickPickInfo = null;
  let highlightPickInfo = null;
  let onMovePoint = null;
  let options = null;
  let edge1 = null;
  let edge2 = null;
  let edgeDetected = false;
  let edge2Detected = false;

  let resetAll = function (edge3, vert) {
    let isWorking = !_.isEmpty(edge1);
    if (!edge3) {
      options = null;
      clickPickInfo = null;
    }
    firstPoint = null;
    anglePoint = null;
    lastPoint = null;
    downClick = 0;
    highlightPickInfo = null;
    if (!vert) onMovePoint = null;
    edge1 = null;
    edge2 = null;
    disposeVertices();
    disposeDashedLines();
    disposeSector();
    disposeEdges();
    disposeVertices();
    disposeEdges();
    DisplayOperation.removeDimensions();
    turnOffSnappingEngine();
    return isWorking;
  };
  let disposeSector = function () {
    let sec = store.scene.getMeshByName("sector");
    if (sec) sec.dispose();
  };
  let showAngleSector = function (
    origin,
    vector1,
    vector2,
    radius,
    sectorType
  ) {
    radius = radius || 1;
    sectorType = sectorType || 0;
    var cross = BABYLON.Vector3.Cross(vector1, vector2);
    var dot = BABYLON.Vector3.Dot(vector1, vector2);
    var angle = Math.acos(dot / (vector1.length() * vector2.length()));
    var points = [];
    var minNb = 4;
    var factor = 2;
    var nbPoints = Math.floor(radius * angle * factor);
    nbPoints = nbPoints < minNb ? minNb : nbPoints;

    var firstPoint = BABYLON.Vector3.Normalize(vector1).scale(radius);
    var lastPoint = BABYLON.Vector3.Normalize(vector2).scale(radius);
    var matrix;
    var ang = angle / nbPoints;
    var rotated;
    for (var i = 0; i < nbPoints; i++) {
      matrix = BABYLON.Matrix.RotationAxis(cross, ang * i);
      rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
      points.push(rotated.add(origin));
    }
    points.push(lastPoint.add(origin));

    var sector;
    switch (sectorType) {
      case 0:
        sector = BABYLON.Mesh.CreateLines("sector", points, store.scene);
        break;
      case 1:
        sector = BABYLON.Mesh.CreateDashedLines(
          "sector",
          points,
          3,
          1,
          nbPoints,
          store.scene
        );
        break;
      case 2:
        var pointO = [];
        for (var j = 0; j < points.length; j++) {
          pointO.push(origin);
        }
        sector = BABYLON.Mesh.CreateRibbon(
          "sector",
          [points, pointO],
          null,
          null,
          0,
          store.scene
        );
        break;
      default:
        sector = BABYLON.Mesh.CreateLines("sector", points, store.scene);
        break;
    }

    return sector;
  };
  let drawAngleSector = function (anglept, p1, p2, radius) {
    let dis1 = BABYLON.Vector3.Distance(anglept, p1);
    let dis2 = BABYLON.Vector3.Distance(anglept, p2);
    if (!radius) radius = dis1 > dis2 ? dis2 : dis1;
    // if (radius > dis1 || radius > dis2) radius = (dis1 > dis2) ? dis2 : dis1;

    let sector = showAngleSector(
      anglept,
      p1.subtract(anglept),
      p2.subtract(anglept),
      radius,
      0
    );
    sector.color = BABYLON.Color3.Blue();
    sector.renderingGroupId = 1;
    sector.isPickable = false;
  };
  let angleBetweenVectors = function (anglePt, p1, p2) {
    if (anglePt && p1 && p2) {
      let vec1 = p1.subtract(anglePt);
      let vec2 = p2.subtract(anglePt);
      let angle = 0;
      angle = getAngleBetweenVectors(vec1, vec2);
      return _.round(angle, 2);
    }
  };
  let disposeDashedLines = function (name) {
    if (!name) uiIndicatorsHandler.axisIndicator.remove();
    else {
      switch (name) {
        case "edgeExt1":
          uiIndicatorsHandler.axisIndicator.remove("axis1");
          break;
        case "edgeExt2":
          uiIndicatorsHandler.axisIndicator.remove("axis2");
          break;
        case "line1":
          uiIndicatorsHandler.axisIndicator.remove("axis1");
          break;
        case "line2":
          uiIndicatorsHandler.axisIndicator.remove("axis1");
          break;
      }
    }
  };
  let showAngleText = function (movePoint, angleVal, inPt, options = {}) {
    let degree = null;
    let centerArc = null;
    if (!movePoint) {
      degree = angleVal;
      anglePoint = inPt;
    } else degree = angleBetweenVectors(anglePoint, firstPoint, movePoint);
    let isArc = store.scene.getMeshByName("sector");
    if (isArc) centerArc = isArc.getBoundingInfo().boundingBox.centerWorld;
    let drawPt = centerArc ? centerArc : anglePoint;
    DisplayOperation.drawOnMove(drawPt, drawPt, "", "", "");
    DisplayOperation.displayOnMove(
      parseFloat(degree),
      "angleInput",
      true,
      options
    );
  };
  let drawDashedLines = function (pt1, pt2, name, disposeLine = true) {
    let point1 = new BABYLON.Vector3(pt1.x, pt1.y, pt1.z);
    let point2 = new BABYLON.Vector3(pt2.x, pt2.y, pt2.z);
    switch (name) {
      case "edgeExt1":
        showAxisIndicator(point1, point2, {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis1,
          extensionNotRequired: true,
          color: colorUtil.type.parallel,
        });
        break;
      case "edgeExt2":
        showAxisIndicator(point1, point2, {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
          extensionNotRequired: true,
          color: colorUtil.type.parallel,
        });
        break;
      case "line1":
        showAxisIndicator(point1, point2, {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis1,
          extensionNotRequired: true,
          color: colorUtil.type.parallel,
        });
        break;
      case "line2":
        showAxisIndicator(point1, point2, {
          indicator: uiIndicatorsHandler.axisIndicator.TYPES.axis2,
          extensionNotRequired: true,
          color: colorUtil.type.parallel,
        });
        break;
    }
  };
  let disposeVertices = function () {
    uiIndicatorsHandler.vertexIndicator.remove();
  };
  let drawInitVertex = function (vertex1, name) {
    const options = {};
    if (name === "anglePoint" || name === "intsec")
      options.indicator =
        uiIndicatorsHandler.vertexIndicator.TYPES.measureIndicator1;
    if (name === "firstPoint")
      options.indicator =
        uiIndicatorsHandler.vertexIndicator.TYPES.measureIndicator2;
    if (name === "lastPoint")
      options.indicator =
        uiIndicatorsHandler.vertexIndicator.TYPES.measureIndicator3;
    showVertexIndicator(vertex1, {}, options);
  };
  let disposeEdges = function () {
    uiIndicatorsHandler.edgeIndicator.remove();
    if (edge1) edge1 = null;
    if (edge2) edge2 = null;
  };
  let intersectionOfEdges = function (edg1, edg2) {
    let epsilon = 0.0005;
    if (edg1.headPt.equalsWithEpsilon(edg2.headPt, epsilon)) return edg1.headPt;
    else if (edg1.tailPt.equalsWithEpsilon(edg2.tailPt, epsilon))
      return edg1.tailPt;
    else if (edg1.tailPt.equalsWithEpsilon(edg2.headPt, epsilon))
      return edg1.tailPt;
    else if (edg1.headPt.equalsWithEpsilon(edg2.tailPt, epsilon))
      return edg1.headPt;
    else {
      let args = [edg1.headPt, edg1.tailPt, edg2.headPt, edg2.tailPt];
      let atrib = {
        type: "vector-vector",
        ignoreY: false,
      };
      let intersection = externalUtil.getPointOfIntersection(args, atrib);

      return intersection;
    }
  };
  let drawEdgeIntersection = function (intPt, e1, e2) {
    disposeDashedLines();
    let closePt2 =
      measureFunction.distanceBtwPts(intPt, e2.headPt) <
      measureFunction.distanceBtwPts(intPt, e2.tailPt)
        ? e2.headPt
        : e2.tailPt;
    let closePt1 =
      measureFunction.distanceBtwPts(intPt, e1.headPt) <
      measureFunction.distanceBtwPts(intPt, e1.tailPt)
        ? e1.headPt
        : e1.tailPt;
    if (!intPt.equals(closePt1) && !intPt.equals(closePt2)) {
      drawDashedLines(intPt, closePt2, "edgeExt2");
      drawDashedLines(intPt, closePt1, "edgeExt1");
    } else {
      if (intPt.equals(closePt1)) drawDashedLines(intPt, closePt2, "edgeExt2");
      if (intPt.equals(closePt2)) drawDashedLines(intPt, closePt1, "edgeExt1");
    }
  };

  return {
    onPointerDown: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        //three point system
        //point 1 - angle point
        //point 2 - first rigid point
        //point 3 - second point (here calculate angle between point1-2 and point2-3)

        // disposeSector();
        if (evt.button == 0) {

          clickPickInfo = store.newScene.pick(
            store.newScene.pointerX,
            store.newScene.pointerY,
            function (mesh) {
              return (
                mesh &&
                // && mesh.name !== 'ground1'
                mesh.name.indexOf("sketchLine") === -1 &&
                mesh.name.indexOf("boxScale") === -1 &&
                mesh.name.indexOf("dimline") === -1 &&
                mesh.name.indexOf("backwall") === -1
              );
            }
          );

          downClick = clickPickInfo.hit ? downClick + 1 : downClick;

          if (downClick == 1) {
            if (edgeDetected) {
              disposeEdges();
              edge1 = options.metadata.snappedToEdge;
              if (edge1)
                showEdgeIndicator(edge1, clickPickInfo.pickedMesh, {
                  indicator:
                    uiIndicatorsHandler.edgeIndicator.TYPES.measureIndicator1,
                });
            } else {
              disposeEdges();
              anglePoint = onMovePoint.clone();

              drawInitVertex(anglePoint, "anglePoint");
            }
          }
          if (downClick == 2) {
            if (edge2Detected) {
              edge2 = options.metadata.snappedToEdge;
              if (edge2)
                showEdgeIndicator(edge2, clickPickInfo.pickedMesh, {
                  indicator:
                    uiIndicatorsHandler.edgeIndicator.TYPES.measureIndicator2,
                });
              if (edge1 && edge2) {
                let intPt = intersectionOfEdges(edge1, edge2);
                if (intPt) drawInitVertex(intPt, "intsec");

                let drawAngle = angleBetweenVectors(
                  intPt,
                  BABYLON.Vector3.Center(edge1.headPt, edge1.tailPt),
                  BABYLON.Vector3.Center(edge2.headPt, edge2.tailPt)
                );
                if (drawAngle > 0) {
                  drawEdgeIntersection(intPt, edge1, edge2);
                  drawAngleSector(
                    intPt,
                    BABYLON.Vector3.Center(edge1.headPt, edge1.tailPt),
                    BABYLON.Vector3.Center(edge2.headPt, edge2.tailPt)
                  );
                  showAngleText(null, drawAngle, intPt);
                }
              }
            } else {
              disposeEdges();
              if (!anglePoint) {
                anglePoint = onMovePoint.clone();
                drawInitVertex(anglePoint, "anglePoint");
                downClick = 1;
              } else {
                firstPoint = onMovePoint.clone();
                drawInitVertex(firstPoint, "firstPoint");
              }
              if (anglePoint && firstPoint) {
                disposeDashedLines("line1");
                drawDashedLines(anglePoint, firstPoint, "line1");
              }
            }
          }
          if (downClick == 3) {
            if (edge1 && edge2) {
              resetAll(true, true);
              if (edgeDetected) {
                disposeEdges();
                edge1 = options.metadata.snappedToEdge;
                if (edge1) {
                  showEdgeIndicator(edge1, clickPickInfo.pickedMesh, {
                    indicator:
                      uiIndicatorsHandler.edgeIndicator.TYPES.measureIndicator1,
                  });
                }
                downClick = 1;
              } else {
                disposeEdges();
                anglePoint = onMovePoint.clone();
                drawInitVertex(anglePoint, "anglePoint");
                downClick = 1;
              }
            } else {
              lastPoint = onMovePoint.clone();
              drawInitVertex(lastPoint, "lastPoint");
              if (anglePoint && lastPoint) {
                disposeDashedLines("line2");
                drawDashedLines(anglePoint, lastPoint, "line2");
              }
              if (anglePoint && firstPoint && lastPoint) {
                let drawAngle = angleBetweenVectors(
                  anglePoint,
                  firstPoint,
                  onMovePoint
                );
                showAngleText(null, drawAngle, anglePoint);
                disposeSector();
                drawAngleSector(anglePoint, firstPoint, lastPoint, 10);
              }
            }
          }
          if (downClick >= 4) {
            if (!edge2 && lastPoint) {
              resetAll(true, true);
              disposeVertices();
              if (edgeDetected) {
                edge1 = options.metadata.snappedToEdge;
                if (edge1) {
                  showEdgeIndicator(edge1, clickPickInfo.pickedMesh, {
                    indicator:
                      uiIndicatorsHandler.edgeIndicator.TYPES.measureIndicator1,
                  });
                }
                downClick = 1;
              } else {
                anglePoint = onMovePoint.clone();
                drawInitVertex(anglePoint, "anglePoint");
                downClick = 1;
              }
            } else {
              resetAll();
            }
          }
        }
      }
    },
    onPointerMove: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        disposeSnapToObjects();
        let predicate = function (mesh) {
          return snapMeshforMeasure(mesh);
        };
        highlightPickInfo = store.newScene.pick(
          store.newScene.pointerX,
          store.newScene.pointerY,
          predicate
        );
        options = {
          wantMetadata: true,
          faceSnap: false,
          indicatorType:
            uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
          doNotDoSecondaryScenePicks: !store.$scope.isTwoDimension,
          pickNeighborhoodMeshes: true,
        };
        onMovePoint = findPrioritizedSnapPoint(null, null, null, options);
        initializeSnappingEngine(onMovePoint);

        if (downClick == 0) {
          if (options.metadata) {
            edgeDetected = options.metadata.hasOwnProperty("snappedToEdge")
              ? true
              : false;
          }
        }

        if (downClick == 1) {
          disposeDashedLines("line1");
          if (edge1) {
            if (options.metadata) {
              edge2Detected = options.metadata.hasOwnProperty("snappedToEdge")
                ? true
                : false;
            }
          } else {
            if (anglePoint) drawDashedLines(anglePoint, onMovePoint, "line1");
          }
        }
        if (downClick == 2) {
          if (edge1 && edge2) {
            if (options.metadata) {
              edge2Detected = options.metadata.hasOwnProperty("snappedToEdge")
                ? true
                : false;
              edgeDetected = options.metadata.hasOwnProperty("snappedToEdge")
                ? true
                : false;
            }
          } else {
            disposeDashedLines("line2");
            disposeSector();
            if (anglePoint) drawDashedLines(anglePoint, onMovePoint, "line2");
            // show angle at angle point here
            if (onMovePoint && anglePoint && firstPoint) {
              showAngleText(onMovePoint);
              // show angle arc here
              drawAngleSector(anglePoint, firstPoint, onMovePoint, 10);
            }
          }
        }
        if (downClick == 3) {
          //  resetAll();
          if (options.metadata) {
            edgeDetected = options.metadata.hasOwnProperty("snappedToEdge")
              ? true
              : false;
          }
        }
        if (downClick >= 4) {
          // disposeDashedLines()
        }
      }
    },
    onPointerUp: function (evt) {},
    resetAngleMeasure: function () {
      return resetAll();
    },
    showAngle: function (pt1, pt2, intsecPt, angleVal, radius, options) {
      drawAngleSector(intsecPt, pt1, pt2, radius);
      showAngleText(null, angleVal, intsecPt, options);
    },
    disposeAngles: function () {
      disposeSector();
      DisplayOperation.removeDimensions();
    },
    disposeSector,
  };
})();
export { angleMeasure };
