import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { MATERIAL_MENU } from "../../../../components/rightMenus/constants";

const initialState = {
  editMaterial: {
    name: null,
    thumbnailURL: null,
    opacity: null,
    overlay: null,
    uScale: null,
    vScale: null,
    uOffset: null,
    vOffset: null,
    angle: null,
  },
  whichMenu: MATERIAL_MENU,
}

const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    resetMaterials: state => initialState,
    setMaterialEditValues(state, action) {
      const { name, thumbnailURL, opacity, overlay, uScale, vScale, uOffset, vOffset, angle } = action.payload;
      state.editMaterial.name = name;
      state.editMaterial.thumbnailURL = thumbnailURL;
      state.editMaterial.opacity = opacity;
      state.editMaterial.overlay = overlay;
      state.editMaterial.uScale = uScale;
      state.editMaterial.vScale = vScale;
      state.editMaterial.uOffset = uOffset;
      state.editMaterial.vOffset = vOffset;
      state.editMaterial.angle = angle;
    },
    updateMaterialValue(state, action){
      _.forEach(action.payload, (v, k) => {
        state.editMaterial[k] = v;
      });
    },
    setMenuMode(state, action){
      state.whichMenu = action.payload;
    }
  }
});

export const { resetMaterials, setMaterialEditValues, updateMaterialValue, setMenuMode } = materialsSlice.actions;
export default materialsSlice.reducer;
