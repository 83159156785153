class Node {
  constructor(data) {
    this.data = data;
    this.next = null;
    this.prev = null;
  }
}

class DoublyLinkedList {
  constructor(id) {
    this.id = id;
    this.head = null;
    this.tail = null;
  }

  append(item) {
    let node = new Node(item);
    if (!this.head) {
      this.head = node;
      this.tail = node;
    } else {
      node.prev = this.tail;
      this.tail.next = node;
      this.tail = node;
    }
  }

  appendAt(pos, item) {
    let current = this.head;
    let counter = 1;
    let node = new Node(item);
    if (pos === 0) {
      this.head.prev = node;
      node.next = this.head;
      this.head = node;
    } else {
      while (current) {
        current = current.next;
        if (counter === pos) {
          node.prev = current.prev;
          current.prev.next = node;
          node.next = current;
          current.prev = node;
        }
        counter++;
      }
    }
  }

  remove(item) {
    let current = this.head;
    while (current) {
      if (current.data === item) {
        if (current === this.head && current === this.tail) {
          this.head = null;
          this.tail = null;
        } else if (current === this.head) {
          this.head = this.head.next;
          this.head.prev = null;
        } else if (current === this.tail) {
          this.tail = this.tail.prev;
          this.tail.next = null;
        } else {
          current.prev.next = current.next;
          current.next.prev = current.prev;
        }
      }
      current = current.next;
    }
  }

  removeAt(pos) {
    let current = this.head;
    let counter = 1;
    if (pos === 0) {
      this.head = this.head.next;
      this.head.prev = null;
    } else {
      while (current) {
        current = current.next;
        if (current === this.tail) {
          this.tail = this.tail.prev;
          this.tail.next = null;
        } else if (counter === pos) {
          current.prev.next = current.next;
          current.next.prev = current.prev;
          break;
        }
        counter++;
      }
    }
  }

  replace(item, replaceWith) {
    let current = this.head;
    while (current) {
      if (current.data === item) {
        current.data = replaceWith;
        break;
      }
      current = current.next;
    }
  }

  reverse() {
    let current = this.head;
    let prev = null;
    while (current) {
      let next = current.next;
      current.next = prev;
      current.prev = next;
      prev = current;
      current = next;
    }
    this.tail = this.head;
    this.head = prev;
  }

  swap(nodeOne, nodeTwo) {
    let current = this.head;
    let counter = 0;
    let firstNode;
    while (current !== null) {
      if (counter === nodeOne) {
        firstNode = current;
      } else if (counter === nodeTwo) {
        let temp = current.data;
        current.data = firstNode.data;
        firstNode.data = temp;
      }
      current = current.next;
      counter++;
    }
    return true;
  }

  giveHead() {
    // call this function only if 18+ lol
    return this.head;
  }

  length() {
    let current = this.head;
    let counter = 0;
    while (current !== null) {
      counter++;
      current = current.next;
    }
    return counter;
  }

  isEmpty() {
    return this.length() < 1;
  }

  traverse(fn) {
    let current = this.head;
    while (current !== null) {
      fn(current);
      current = current.next;
    }
    return true;
  }

  traverseReverse(fn) {
    let current = this.tail;
    while (current !== null) {
      fn(current);
      current = current.prev;
    }
    return true;
  }

  search(item) {
    let current = this.head;
    let counter = 0;
    while (current) {
      if (current.data === item) {
        return { counter: counter, node: current };
      }
      current = current.next;
      counter++;
    }
    return false;
  }

  serialize() {
    let serializedData = {};
    serializedData[this.id] = [];
    this.traverse((node) => {
      serializedData[this.id].push(node.data);
    });

    return serializedData;
  }
}

class CircularLinkedList extends DoublyLinkedList {
  constructor(id) {
    super(id);
  }

  append(item) {
    let node = new Node(item);
    if (!this.head) {
      this.head = node;
      this.tail = node;
    } else {
      node.prev = this.tail;
      this.tail.next = node;
      this.tail = node;

      this.tail.next = this.head;
      this.head.prev = this.tail;
    }
  }

  forEach(callback) {
    let current = this.head;

    do {
      callback(current);
      current = current.next;
    } while (current !== this.head);

    return true;
  }
}

function LinkedList() {
  this.length = 0;
  this.first = null;
  this.last = null;
}

LinkedList.prototype.append = function (node) {
  if (this.first === null) {
    this.first = node.prev = node;
    this.last = node.next = node;
  } else {
    node.prev = this.last;
    node.next = this.first;
    this.first.prev = node;
    this.last.next = node;
    this.last = node;
  }

  this.length += 1;
};

LinkedList.prototype.insert = function (node, inserted) {
  inserted.prev = node;
  inserted.next = node.next;
  node.next.prev = inserted;
  node.next = inserted;
  if (inserted.prev === this.last) this.last = inserted;

  this.length += 1;
};

LinkedList.prototype.remove = function (node) {
  if (this.length > 1) {
    node.prev.next = node.next;
    node.next.prev = node.prev;
    if (node === this.first) this.first = node.next;
    if (node === this.last) this.last = node.prev;
  } else if (this.first === node) {
    this.first = null;
    this.last = null;
  }
  node.prev = null;
  node.next = null;

  this.length -= 1;
};

LinkedList.prototype.each = function (cb) {
  var p = this.first;
  var n = this.length;

  while (n--) {
    cb(p.data);
    p = p.next;
  }
};
export { Node,DoublyLinkedList,CircularLinkedList,LinkedList };
