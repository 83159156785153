import $ from "jquery";
import _ from "lodash";
function searchForId(arr, a) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].name == a) {
      return i;
    }
  }
}

Array.prototype.sortByIndices = function () {
  var result = Array.from(Array(this.length).keys()).sort((a, b) =>
    this[a] < this[b] ? -1 : (this[b] < this[a]) | 0
  );
  return result;
};

Array.prototype.removeElemByIndex = function (key) {
  this.splice(key, 1);
};

Array.prototype.uniq = function (key) {
  var set = {};
  return $.grep(this, function (item) {
    var k = key ? key.apply(item) : item;
    return k in set ? false : (set[k] = true);
  });
};

Array.prototype.findElemsInArray = function (a) {
  let elems = [];
  for (var i = 0; i < this.length; i++) {
    if (this[i] === a) {
      elems.push(i);
    }
  }
  return elems;
};

Array.prototype.removeMesh = function (value) {
  let index = this.findIndex((item) => item.uniqueId === value.uniqueId);
  this.splice(index, 1);
};

Array.prototype.removeElem = function (value) {
  let index = this.findIndex((item) => item === value);
  this.splice(index, 1);
};

Array.prototype.replace = function (replacer, comparator) {
  let index = _.findIndex(this, comparator);
  if (index !== -1) {
    this[index] = replacer;
  }
};

Array.prototype.sum = function () {
  let sum = 0;
  for (var i = 0; i < this.length; i++) {
    sum += this[i];
  }
  return sum;
};

Array.prototype.max = function () {
  return Math.max.apply(null, this);
};

Array.prototype.min = function () {
  return Math.min.apply(null, this);
};

Array.prototype.mean = function () {
  let sum = 0;
  for (let i = 0; i < this.length; i++) {
    sum += this[i];
  }
  if (this.length != 0) {
    return sum / this.length;
  }
  return null;
};

function removeDuplicates(a) {
  var prims = { boolean: {}, number: {}, string: {} },
    objs = [];

  return a.filter(function (item) {
    var type = typeof item;
    if (type in prims)
      return prims[type].hasOwnProperty(item)
        ? false
        : (prims[type][item] = true);
    else {
      item = JSON.stringify(item);
      return objs.indexOf(item) >= 0 ? false : objs.push(item);
    }
  });
}

function findArrayElemInArray(a, arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][0] === a[0] && arr[i][1] === a[1] && arr[i][2] === a[2]) {
      return i;
    }
  }
  return -1;
}

var deepDiffMapper = (function () {
  return {
    VALUE_CREATED: "created",
    VALUE_UPDATED: "updated",
    VALUE_DELETED: "deleted",
    VALUE_UNCHANGED: "unchanged",
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw "Invalid argument. Function given, object expected.";
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        };
      }

      var diff = {};
      for (let key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        var value2 = undefined;
        if ("undefined" != typeof obj2[key]) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (let key in obj2) {
        if (this.isFunction(obj2[key]) || "undefined" != typeof diff[key]) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (
        this.isDate(value1) &&
        this.isDate(value2) &&
        value1.getTime() === value2.getTime()
      ) {
        return this.VALUE_UNCHANGED;
      }
      if ("undefined" == typeof value1) {
        return this.VALUE_CREATED;
      }
      if ("undefined" == typeof value2) {
        return this.VALUE_DELETED;
      }

      return this.VALUE_UPDATED;
    },
    isFunction: function (obj) {
      return {}.toString.apply(obj) === "[object Function]";
    },
    isArray: function (obj) {
      return {}.toString.apply(obj) === "[object Array]";
    },
    isDate: function (obj) {
      return {}.toString.apply(obj) === "[object Date]";
    },
    isObject: function (obj) {
      return {}.toString.apply(obj) === "[object Object]";
    },
    isValue: function (obj) {
      return !this.isObject(obj) && !this.isArray(obj);
    },
  };
})();

function makeid(length) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

Array.prototype.insertAtPlace = function (index, item) {
  for (var i = index + 1; i < this.length; i++) {
    this[i] = this[i - 1];
  }
  this[index] = item;
};

Array.prototype.searchForKeyValuePair = function (key, value) {
  for (let i = 0; i < this.length; i++) {
    if (this[i].hasOwnProperty(key)) {
      if (this[i].key == value) {
        return this[i];
      }
    }
  }
  return undefined;
};

Array.prototype.locationOfKeyValuePair = function (key, value) {
  for (let i = 0; i < this.length; i++) {
    if (this[i].hasOwnProperty(key)) {
      if (this[i].key == value) {
        return i;
      }
    }
  }
  return undefined;
};

Array.prototype.inArray = function (comparator) {
  
  if (!_.isFunction(comparator)){
    const element = comparator;
    comparator = (e) => e === element;
  }
  
  for (let i = 0; i < this.length; i++) {
    if (comparator(this[i])) {
      return true;
    }
  }
  return false;
};

Array.prototype.pushIfNotExist = function (element, comparator) {
  
  if (!comparator){
    comparator = (e) => e === element;
  }
  
  if (!this.inArray(comparator)) {
    this.push(element);
  }
};

Array.prototype.containsArray = function (val) {
  let hash = {};
  for (let i = 0; i < this.length; i++) {
    hash[this[i]] = i;
  }
  if (hash.hasOwnProperty(val)) {
    return hash[val];
  }
  return -1;
};

Array.prototype.almostEquals = function (anotherArray, precision = 1e-3) {
  if (this.length !== anotherArray.length) return false;
  for (let i = 0; i < this.length; i++) {
    if (
      Math.abs(this[0] - anotherArray[0]) > precision ||
      Math.abs(this[1] - anotherArray[1]) > precision ||
      Math.abs(this[2] - anotherArray[2]) > precision
    )
      return false;
  }
  return true;
};

const getCircularlyNextElementInArray = function (array, predicate) {
  
  if (!_.isFunction(predicate)){
    const value = predicate;
    predicate = (element) => element === value;
  }
  
  const currentIndex = _.findIndex(array, predicate);
  if (isNaN(currentIndex) || currentIndex === -1) return null;

  const length = array.length;

  const nextIndex = currentIndex === length - 1 ? 0 : currentIndex + 1;
  // const previousIndex = currentIndex === 0 ? length - 1 : currentIndex - 1;

  return array[nextIndex];
};

const getCircularlyPreviousElementInArray = function (array, predicate) {
  
  if (!_.isFunction(predicate)){
    const value = predicate;
    predicate = (element) => element === value;
  }
  
  const currentIndex = _.findIndex(array, predicate);
  if (isNaN(currentIndex)) return null;

  const length = array.length;

  // const nextIndex = currentIndex === length - 1 ? 0 : currentIndex + 1;
  const previousIndex = currentIndex === 0 ? length - 1 : currentIndex - 1;

  return array[previousIndex];
};
export { searchForId,removeDuplicates,findArrayElemInArray,deepDiffMapper,makeid,getCircularlyNextElementInArray,getCircularlyPreviousElementInArray };
