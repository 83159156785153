"use strict";
import BABYLON from "./babylonDS.module.js";
import $ from "jquery";
import jQuery from "jquery";
import _ from "lodash";
import { store } from "./utilityFunctions/Store.js";
import { updateButtonColor } from "../libs/sketch/updateEventModes.js";
import { getGeometryById, nonDefaultMesh, updateModifications, } from "../libs/sceneStateFuncs.js";
import { makeid, searchForId } from "../libs/arrayFuncs.js";
import { addMaterialToMeshSolid, copyMaterialData } from "../libs/mats.js";
import { StructureCollection } from "./snaptrudeDS/structure.ds.js";
import { GLOBAL_CONSTANTS } from "./utilityFunctions/globalConstants.js";
import { assignDefaultComponentMaterial, isComponentMaterialDefault, } from "../libs/basicMaterials.js";
import { populateRoofBoundInfo } from "./factoryTypes/roof.types.js";
import { setLayerTransperancy } from "../libs/sceneFuncs.js";
import { getScope, removeMouseEvents, } from "../libs/toolbarEvents.js";
import { unclick } from "../libs/meshEvents.js";
import {
  onPointerDownRotate,
  onPointerMoveRotate,
  onPointerUpRotate,
} from "./cameraControl/rotateEvents.js";
import { onMatPointerDown, onMatPointerMove, onMatPointerUp, } from "../libs/applyMaterialFuncs.js";
import { commandUtils } from "./commandManager/CommandUtils.js";
import { doorOperation } from "./meshoperations/doorOperation.js";
import { plainFloorParameters } from "./factoryTypes/floor.types.js";
import { windowOperation } from "./meshoperations/windowOperation.js";
import { furnitureOperation } from "./meshoperations/furnitureOperation.js";
import { Wall } from "./snaptrudeDS/wall.ds.js";
import { StoreyMutation } from "./storeyEngine/storeyMutations.js";
import {
  getBottomFaceVertices,
  getFaceIdFromFacet,
  getFaceVerticesFromFace,
  getTopFaceVertices,
  removeVertexFromComponent, sanitizeVertices,
} from "../libs/brepOperations.js";
import { RotateOperation } from "./meshoperations/rotateOperation.js";
import { virtualSketcher } from "./sketchMassBIMIntegration/virtualSketcher.js";
import {
  getAngleBetweenVectors,
  isFloatEqual,
  projectionOfPointOnFace,
  projectionOfPointOnLine,
} from "../libs/snapFuncs.js";
import { meshObjectMapping } from "./snaptrudeDS/mapping.js";
import { ResolveEngine, ResolveEngineUtils, } from "./wallEngine/resolveEngine.js";
import { ScopeUtils } from "../libs/scopeFunctions.js";
import { send_complete_scene_data } from "../libs/serverFuncs.js";
import { AutoSave } from "./socket/autoSave.js";
import { setScaleOperation } from "./setScaleOperations/setScaleOperation.js";
import { DisplayOperation } from "./displayOperations/displayOperation.js";
import { scaleSceneMeshes } from "../libs/twoD/twoScaling.js";
import { layerView } from "../libs/layerView.js";
import { changeToOrthoViewCamera } from "../libs/cameraFuncs.js";
import { Mass } from "./snaptrudeDS/mass.ds.js";
import { CommandManager } from "./commandManager/CommandManager.js";
import { CircularLinkedList } from "./Classes/doublyLinkedList.js";
import { externalUtil } from "./externalUtil.js";
import { getNormalVector } from "../libs/mathFuncs.js";
import { updateRoofAccordion } from "../libs/roofVisibilityFuncs.js";
import { room_types_db } from "../libs/obj_base.js";
import { areEdgesSimilar } from "../libs/snapUtilities";
import { isNeighborhoodBuilding } from "./geo/terrainNeighborhood.js";
import stackedWallHelper from "./stackedWalls/stackedWallHelper";
import reduxStore from "../stateManagers/store/reduxStore.js";

// var onSelectTool = function () {
//     removeMouseEvents();
//     updateButtonColor("select_tool");
//     var count = 0;
//     while (count < store.newScene.meshes.length) {
//         if ((newScene.meshes[count].name.indexOf("boxScale") === -1) && (newScene.meshes[count].name.indexOf("axis") === -1) && (newScene.meshes[count].name.indexOf("ground") === -1) && (newScene.meshes[count].name.indexOf("backwall") === -1)) {
//             if (newScene.meshes[count].visibility > 0) {
//                 click(newScene.meshes[count]);
//                 if (document.getElementById("face_mode").style.backgroundColor !== "orange")
//                     if (newScene.meshes[count].name.toLowerCase().startsWith('wall') || store.newScene.meshes[count].name.toLowerCase().startsWith('floor') || store.newScene.meshes[count].name.toLowerCase().startsWith('roof'))
//                         hover(newScene.meshes[count]);
//             }
//         }
//         count++;
//     }
//
//
//     var wall = store.scene.getMeshByName("backwall");
//     if (wall) {
//         wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
//         wall.isPickable = true;
//     }
//     store.canvas.addEventListener("pointerdown", onPointerDownRotate, false);
//
// };

var onNoEdge = function () {
  updateButtonColor("noedge");
  for (var i = 0; i < store.scene.meshes.length; i++) {
    let mesh = store.scene.meshes[i];
    if (nonDefaultMesh(mesh)) {
      mesh.disableEdgesRendering();
    }
  }
};

var onSolid = function (meshAsArgument, allInstances = true, options = {}) {
  // updateButtonColor("solid");

  if (meshAsArgument && meshAsArgument.name !== "terrain") {
    if (meshAsArgument.isAnInstance) {
      if (allInstances)
        onSolid(meshAsArgument.sourceMesh, allInstances, options);
      else _renderEdges(meshAsArgument);
    } else {
      _renderEdges(meshAsArgument);
      if (meshAsArgument.instances && allInstances) {
        meshAsArgument.instances.forEach(_renderEdges);
      }
    }
  } else {
    for (var i = 0; i < store.scene.meshes.length; i++) {
      var mesh = store.scene.meshes[i];
      if (
        mesh.name === "terrain" ||
        (mesh.name === "Building" && !options.isOrtho)
      ) {
        continue;
      }
      _renderEdges(mesh);
    }
  }

  function _renderEdges(mesh) {
    if (mesh.type === "Void") return;
    // if (mesh.isAnInstance) return;

    if (mesh.room_type && !isNeighborhoodBuilding(mesh)) {
      var id = searchForId(room_types_db, mesh.room_type);
      var obj_props = room_types_db[id];
      if (obj_props) {
        var obj_name = obj_props.name;
        var obj_height = obj_props.props.height * store.floor_height;
        var obj_far = obj_props.props.far;
        var obj_mat = obj_props.props.mat;
      } else {
        var obj_name = store.room_types[i];
        var obj_height = store.floor_height;
        var obj_far = true;
        var obj_mat = "none";
      }
      if ($("#mass_mode").prop("checked")) {
        addMaterialToMeshSolid(mesh, obj_mat, options);
      } else {
        mesh.showBoundingBox = false;
        addMaterialToMeshSolid(mesh, obj_mat, options);
      }
    } else {
      if ($("#mass_mode").prop("checked")) {
        addMaterialToMeshSolid(mesh, null, options);
      } else {
        mesh.showBoundingBox = false;
        addMaterialToMeshSolid(mesh, null, options);
      }
    }
    if (mesh.room_curve) {
      mesh.enableEdgesRendering(0.5, true);
    }
  }
};

function addMeshToStructure(mesh, refMesh, level_id, structure_id) {
  let levelId = level_id;
  let structureId = structure_id;

  if (!levelId) levelId = refMesh.getSnaptrudeDS().level_id;
  if (!structureId) structureId = refMesh.getSnaptrudeDS().structure_id;

  let structureCollection = StructureCollection.getInstance();
  let structure = structureCollection.getStructureById(structureId);

  let markAsThrowAway = false;
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned) {
    mesh.type = refMesh.type;
  }

  if (isMeshThrowAway(mesh)) {
    removeMeshThrowAwayIdentifier(mesh);
    markAsThrowAway = true;
  }

  mesh.structure_id = structureId;
  let level = structure.getLevelByUniqueId(levelId);
  level.addMeshToLevel(mesh, false);

  if (markAsThrowAway) {
    markMeshAsThrowAway(mesh);
  }
}

function removeMeshFromStructure(mesh) {
  let meshDS = mesh.getSnaptrudeDS();
  if (!meshDS) return;

  removeComponentFromStructure(meshDS);
}

function removeComponentFromStructure(component) {
  let levelId = component.level_id;
  let structureId = component.structure_id;

  let structureCollection = StructureCollection.getInstance();
  let structure = structureCollection.getStructureById(structureId);
  let level = structure.getLevelByUniqueId(levelId);
  level.removeObjectToLevel(component);
}

function changeTypeInStructure(mesh, newType, options = {}) {
  let previousObject = mesh.getSnaptrudeDS();
  let structureId = previousObject.structure_id;
  let levelId = previousObject.level_id;
  let typeChangeAllowed = previousObject.isTypeChangeAllowed();
  let brep = previousObject.brep;
  let faceFacetMapping = previousObject.faceFacetMapping;

  const defaultMaterial = isComponentMaterialDefault(previousObject);

  const markAsThrowAway = isMeshThrowAway(mesh);

  removeMeshFromStructure(mesh);
  mesh.type = newType;
  // don't make it toLowerCase, breaks things

  let childrenComp = mesh.childrenComp;
  // if(options.retainChildrenComp){
  //     childrenComp = mesh.childrenComp;
  // }
  addMeshToStructure(mesh, null, levelId, structureId);

  let newObject = mesh.getSnaptrudeDS();

  if (options.componentId) newObject.id = options.componentId;
  // refer to comment in _executeStructureChangeCommand, objectPropertiesView.js
  // to understand why this is necessary

  // unlike mesh.uniqueId, changing component.id doesn't warrant any communication with meshObjectMapping
  
  newObject.assignProperties();
  newObject.brep = brep;
  newObject.faceFacetMapping = faceFacetMapping;
  mesh.childrenComp.push(...childrenComp);

  if (defaultMaterial && !mesh.isAnInstance) {
    if (newObject.updateDefaultMaterial) newObject.updateDefaultMaterial();
    else assignDefaultComponentMaterial(newObject);
  }

  if (markAsThrowAway && !isThrowAwayIdentifier(mesh.type)) {
    // this is being handled in addToStructure
    // Here as well because assignProperties rewrites mesh.type sometimes
    markMeshAsThrowAway(mesh, true);
  }

  if (typeChangeAllowed) {
    newObject.allowTypeChange();
  } else {
    newObject.disallowTypeChange();
  }

  mesh.name = newType;

  if (options.doPropertyChanges) {
    if (newType.toLowerCase() === "roof") {
      mesh.computeWorldMatrix(true);

      populateRoofBoundInfo(newObject);
      newObject.setRoofPolBottom(newObject.getRoofPolOffsetBottom());
      newObject.setRoofPolTop(newObject.getRoofPolOffsetTop());
      newObject.setRoofOffset(0);
    } else if (newType.toLowerCase() === "mass") {
      newObject.massType = options.massSubTypeAfter;
    }

    setLayerTransperancy(mesh);
  }

  return newObject;
}

var onPan = function () {
  removeMouseEvents();
  updateButtonColor("pan");
  store.panMode = true;
};

var onCamRot = function () {
  removeMouseEvents();
  store.newScene.activeCamera.multiTouchPanAndZoom = true;
  if (store.isiPad) {
    store.newScene.activeCamera.pinchToPanMaxDistance = 50;
    store.canvas.addEventListener("pointerdown", onPointerDownRotate, false);
    store.canvas.addEventListener("pointermove", onPointerMoveRotate, false);
    store.canvas.addEventListener("pointerup", onPointerUpRotate, false);
  } else if (store.isMobile) {
    store.newScene.activeCamera.pinchToPanMaxDistance = 50;
    store.canvas.addEventListener("pointerdown", onPointerDownRotate, false);
    store.canvas.addEventListener("pointermove", onPointerMoveRotate, false);
    store.canvas.addEventListener("pointerup", onPointerUpRotate, false);
  } else {
    store.canvas.addEventListener("pointerdown", onPointerDownRotate, false);
    store.canvas.addEventListener("pointerup", onPointerUpRotate, false);
  }
  if (document.getElementById("canvas")) {
    document.getElementById("canvas").style.cursor = "default";
  }

  // store.newScene.activeCamera.attachControl(canvas, true, false);
};

var onMatMode = function () {
  // updateButtonColor("mat_mode");
  removeMouseEvents();
  /* AG-RE: ANGULAR REFERENCE */
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  // $scope.face_model_value = document.getElementById("mat_mode").style.backgroundColor === "orange";
  store.$scope.face_model_value = true;
  store.canvas.addEventListener("pointerdown", onMatPointerDown, false);
  store.canvas.addEventListener("pointerup", onMatPointerUp, false);
  store.canvas.addEventListener("pointermove", onMatPointerMove, false);
  store.ACTIVE_EVENT = { event: "onMat" };

  // if (document.getElementById("mat_mode").style.backgroundColor !== "orange") {
  //     removeMouseEvents();
  //     if (face_ribbon) {
  //         face_ribbon.dispose();
  //         if (scene.getMeshByName("textPlane")) {
  //             store.scene.getMeshByName("textPlane").dispose();
  //         }
  //     }
  // } else {
  //     store.canvas.addEventListener("pointerdown", onMatPointerDown, false);
  //     store.canvas.addEventListener("pointerup", onMatPointerUp, false);
  //     store.canvas.addEventListener("pointermove", onMatPointerMove, false);
  // }
  //changePropBlock();
  // var wall = store.scene.getMeshByName("backwall");
  // if (wall) {
  //     wall.position.z = store.scene.getCameraByName("ArcRotateCamera3").radius * 0.80;
  //     wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
  //     wall.isPickable = false;
  // }
  // onSelectTool();
  // moveBlocks();
  for (var i = 0; i < store.scene.meshes.length; i++) {
    unclick(store.scene.meshes[i]);
  }
};

var enableGizmo = function () {
  removeMouseEvents();
  store.gizmoManager.attachableMeshes = [];
  store.newScene.activeCamera.getActiveMeshes().data.forEach(function (mesh) {
    if (
      mesh.name.indexOf("boxScale") === -1 &&
      mesh.name.indexOf("axis") === -1 &&
      mesh.name.indexOf("ground") === -1
    ) {
      store.gizmoManager.attachableMeshes.push(mesh);
    }
  });
  store.gizmoManager.rotationGizmoEnabled = true;
};

function deSerializeMesh(serializedData) {
  let geom = getGeometryById(
    serializedData["geometries"]["vertexData"],
    serializedData["meshes"][0]["geometryId"]
  );
  let originalMesh = store.scene.recreateMesh(
    serializedData["meshes"][0],
    geom,
    null
  );
  correctMeshNormals(originalMesh);

  return originalMesh;
}

var updateCSG = function (mesh, optionsForCSG = {}) {
  let meshesToModify = [];

  function _handleParent(parent) {
    if (parent.type.toLowerCase() === "wall") {
      let wallMesh = parent;
      let newWallMesh;
      let originalWallMeshData = wallMesh.getSnaptrudeDS().originalWallMesh;
      if (originalWallMeshData) {
        let options = { preserveChildren: "true" };

        let wallDeletionCommandData =
          commandUtils.deletionOperations.getCommandData(
            [wallMesh],
            null,
            options
          );
        let wallDeletionCommand = commandUtils.deletionOperations.getCommand(
          "updateCSGDeletion",
          wallDeletionCommandData,
          options
        );

        let geom = getGeometryById(
          originalWallMeshData["geometries"]["vertexData"],
          originalWallMeshData["meshes"][0]["geometryId"]
        );
        let originalMesh = store.scene.recreateMesh(
          originalWallMeshData["meshes"][0],
          geom,
          null
        );
        correctMeshNormals(originalMesh);

        if (!optionsForCSG.hasOwnProperty("updatePosition")) {
          originalMesh.setAbsolutePosition(wallMesh.getAbsolutePosition());
        }
        if (optionsForCSG.reScale) {
          originalMesh.scaling = BABYLON.Vector3.FromArray(
            optionsForCSG.reScale
          );
        }
        if (optionsForCSG.rePosition) {
          originalMesh.position = BABYLON.Vector3.FromArray(
            optionsForCSG.rePosition
          );
        }
        let children = wallMesh.childrenComp;
        let wallCSG = getCSGFormOfMesh(originalMesh);

        children.forEach(function (child) {
          child.setParent(null);
          child.computeWorldMatrix(true);

          meshesToModify.push(child);
        });

        if (children.length > 0) {
          children.forEach(function (childMesh) {
            let childCSG = null;
            let _selectionBox = null;
            if (childMesh.type.toLowerCase() === "door") {
              let prevPosition = childMesh.position.clone();
              let prevRotation = childMesh.rotation.clone();
              childMesh.position = BABYLON.Vector3.Zero();
              childMesh.rotation = BABYLON.Vector3.Zero();
              _selectionBox = doorOperation.createDoorSelectionBox(
                childMesh,
                wallMesh.getSnaptrudeDS().wThickness
              );
              _selectionBox.position = prevPosition.clone();
              _selectionBox.position.y -= plainFloorParameters.floorDepth / 2;
              _selectionBox.rotation = prevRotation.clone();
              childMesh.position = prevPosition.clone();
              childMesh.rotation = prevRotation.clone();
              childCSG = getCSGFormOfMesh(_selectionBox);
            } else if (childMesh.type.toLowerCase() === "window") {
              let prevPosition = childMesh.position.clone();
              let prevRotation = childMesh.rotation.clone();
              childMesh.position = new BABYLON.Vector3.Zero();
              childMesh.rotation = new BABYLON.Vector3.Zero();
              _selectionBox = windowOperation.createWindowSelectionBox(
                childMesh,
                wallMesh.getSnaptrudeDS().wThickness
              );
              _selectionBox.position = prevPosition;
              _selectionBox.rotation = prevRotation;
              childMesh.position = new BABYLON.Vector3(
                prevPosition.x,
                prevPosition.y,
                prevPosition.z
              );
              childMesh.rotation = new BABYLON.Vector3(
                prevRotation.x,
                prevRotation.y,
                prevRotation.z
              );
              childCSG = getCSGFormOfMesh(_selectionBox);
            } else if (childMesh.type.toLowerCase() === "furniture") {
              let prevPosition = childMesh.position.clone();
              let prevRotation = childMesh.rotation.clone();
              childMesh.position = new BABYLON.Vector3.Zero();
              childMesh.rotation = new BABYLON.Vector3.Zero();
              _selectionBox = furnitureOperation.createFurnitureSelectionBox(
                childMesh,
                wallMesh
              );
              _selectionBox.position = prevPosition;
              _selectionBox.rotation = prevRotation;
              childMesh.position = new BABYLON.Vector3(
                prevPosition.x,
                prevPosition.y,
                prevPosition.z
              );
              childMesh.rotation = new BABYLON.Vector3(
                prevRotation.x,
                prevRotation.y,
                prevRotation.z
              );
              childCSG = getCSGFormOfMesh(_selectionBox);
            } else if (childMesh.type.toLowerCase() === "void") {
              childMesh.visibility = 0.01;
              childCSG = getCSGFormOfMesh(childMesh);
            } else if (childMesh.type.toLowerCase() === "mass") {
              return;
            }

            wallCSG.subtractInPlace(childCSG);
            if (_selectionBox) {
              _selectionBox.dispose();
              _selectionBox = null;
            }
          });
        }

        // let newWallMesh = wallCSG.toSnaptrudeMesh("wall", null, store.scene);
        newWallMesh = wallCSG.toMesh("wall", null, store.scene);
        newWallMesh.type = "wall";

        let brep;
        if (wallMesh.childrenComp.length === 0) {
          if (originalWallMeshData["brep"]) {
            brep = store.resurrect.resurrect(originalWallMeshData["brep"]);
            newWallMesh.storey = wallMesh.storey;
            newWallMesh.BrepToMesh(brep);
            newWallMesh.brep = brep;
            copyMaterialData(wallMesh, newWallMesh);
            let subMeshes = newWallMesh.subMeshes;

            if (subMeshes.length === 1) {
              newWallMesh.brep.faces.forEach((f) => {
                f.materialIndex = subMeshes[0].materialIndex;
              });
            }
          }
        } else {
          copyMaterialData(wallMesh, newWallMesh);
        }

        let wall = new Wall(newWallMesh, wallMesh.room_id);
        let oldWall = wallMesh.getSnaptrudeDS();
        wall.assignProperties();
        wall.wThickness = oldWall.wThickness;
        wall.localLineSegment = oldWall.localLineSegment;
        wall.originalWallMesh = oldWall.originalWallMesh;
        wall.neighbours = oldWall.neighbours;
        wall.neighboursDetails = oldWall.neighboursDetails;
        wall.properties = oldWall.properties;
        wall.setTopCoords(oldWall.topCoords);
        wall.setBottomCoords(oldWall.bottomCoords);
        wall.setMidYHeight(oldWall.midY);
        wall.mesh.storey = wallMesh.storey;

        wall.revitMetaData = oldWall.revitMetaData;
        meshesToModify.push(wall.mesh);

        if (oldWall.brep) {
          wall.originalWallMesh.brep = store.resurrect.stringify(
            oldWall.brep
          );
        }

        // newWallMesh.setAbsolutePosition = new BABYLON.Vector3.Zero();
        // newWallMesh.position = new BABYLON.Vector3.Zero();

        // wallMesh.getSnaptrudeDS().cloneProperties(wall);

        let structures = StructureCollection.getInstance();
        wall.mesh.structure_id = wallMesh.structure_id;
        let structure_id = wallMesh.structure_id;
        let str = structures.getStructureById(wall.mesh.structure_id);
        let level = str.getLevelByUniqueId(
          str.getObjectByUniqueId(wallMesh.uniqueId).level_id
        );
        level.addWallToLevel(wall, false);
        // newWallMesh.storey = wallMesh.storey;
        newWallMesh.childrenComp = wallMesh.childrenComp;

        // let wallPosition = Object.assign({}, wallMesh.position);
        // newWallMesh.position = new BABYLON.Vector3(
        //     wallPosition.x,
        //     wallPosition.y,
        //     wallPosition.z
        // );

        // if (!wall.brep) {
        // }

        let linkedListData = StoreyMutation.replaceElementInDLL(
          wallMesh.getSnaptrudeDS(),
          wall
        );

        wallMesh.getChildren().forEach(function (child) {
          // child.parent = null;
          // child.parent = newWallMesh;
          // child.setParent(newWallMesh)
        });

        originalMesh.dispose();

        //very important for this to be in the middle
        for (let i = 0; i < newWallMesh.childrenComp.length; i++) {
          // newWallMesh.childrenComp[i].parent = newWallMesh;
          newWallMesh.childrenComp[i].setParent(newWallMesh);
        }

        if (optionsForCSG.differenceY) {
          newWallMesh.position.y -= optionsForCSG.differenceY;
        }
        let wallCreationCommandData =
          commandUtils.creationOperations.getCommandData(
            [newWallMesh],
            null,
            options
          );
        let wallCreationCommand = commandUtils.creationOperations.getCommand(
          "updateCSGCreation",
          wallCreationCommandData,
          options
        );

        const stackedWallCommand = stackedWallHelper.update(wallMesh, newWallMesh);

        // wallMesh.dispose();

        onSolid(newWallMesh);

        if (linkedListData) {
          dllCommands.push(
            commandUtils.linkedListOperations.getCommand(
              "updateLinkedListCluster",
              linkedListData
            )
          );
        }
        creationCommands.push(wallCreationCommand);
        deletionCommands.push(wallDeletionCommand);
        if (stackedWallCommand) stackedWallCommands.push(stackedWallCommand);

        newWall = wall;
      }
    }
  }

  let creationCommands = [];
  let deletionCommands = [];
  let dllCommands = [];
  let stackedWallCommands = [];
  let newWall = null;

  const meshTypeLowerCase = mesh.type.toLowerCase();
  if (
    ["door", "window", "furniture", "void"].includes(meshTypeLowerCase) &&
    mesh.parent
  ) {
    
    if (meshTypeLowerCase === "furniture"){
      const component = mesh.getSnaptrudeDS();
      if (!component.cutHole) return;
    }
    
    if (optionsForCSG.multipleCSG) {
      _handleParent(mesh.parent);
      _handleParent(optionsForCSG.previousParent);
    } else {
      _handleParent(mesh.parent);
    }

    const otherCommands = [...stackedWallCommands];

    if (meshesToModify.length > 0) {
      let isModifiedCommand = setIsModifiedAndGetCommand(meshesToModify);

      otherCommands.push(isModifiedCommand);
    }

    return {
      creationCommands,
      deletionCommands,
      dllCommands,
      newWall,
      otherCommands,
    };
  }
};

const setIsModifiedAndGetCommand = (meshesToModify) => {
  const optionsForPropertyChange = {
    componentKeys: ["revitMetaData"],
  };

  const propertyChangeCommandDataBefore = commandUtils.propertyChangeOperations.getCommandData(
    meshesToModify,
    optionsForPropertyChange
  );

  optionsForPropertyChange.data = propertyChangeCommandDataBefore;

  for (const m of meshesToModify) {
    let ds = m.getSnaptrudeDS();
    ds.setIsModified();
  }

  const propertyChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
    meshesToModify,
    optionsForPropertyChange
  );

  const propertyChangeCommand =
    commandUtils.propertyChangeOperations.getCommand(
      "set is modified",
      propertyChangeCommandData
    );

    return propertyChangeCommand;
}

var isEqual = function (value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

async function updateLevelsAngularUI(levelRelHeight) {
  // debugger;
  //  if(!levelRelHeight){
  //    //  levelRelHeight = await reorderStoreys();
  //      levelRelHeight = storeysDS;
  //  }
  // var scope = store.angular.element(appElement).scope();
  // scope = scope.$$childHead;
  // scope.numberOfLevels = 0;
  // scope.selectedItemLevelHeight.push([]);
  //
  // let event = new MouseEvent('click', {
  //     view: window,
  //     bubbles: false,
  //     cancelable: true,
  //     source : true,
  // });
  // event.artificialSource = true;
  // event.artificialLevel = true;
  // //event.source = true;
  //
  // let elevel_button = clickAddLevel();
  //
  // if(document.getElementById('level_blocks')) {
  //     document.getElementById('level_blocks').innerHTML = "";
  // }
  // let storeyKeys = Object.keys(levelRelHeight);
  // storeyKeys.forEach(function(storeyElement){
  //     if(elevel_button) {
  //         event.level = levelRelHeight[storeyElement];
  //         elevel_button.dispatchEvent(event);
  //         event.artificialSource = false;
  //     }
  // });
  // assignStoreys();
  // layerView.generateLayerData();
}

function showVertexNormals(mesh, size, color, sc) {
  var normals = mesh.getVerticesData(BABYLON.VertexBuffer.NormalKind);
  var positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  color = color || BABYLON.Color3.Red();
  sc = sc || store.scene;
  size = size || 10;

  var lines = [];
  for (var i = 0; i < normals.length; i += 3) {
    var v1 = BABYLON.Vector3.FromArray(positions, i);
    var v2 = v1.add(BABYLON.Vector3.FromArray(normals, i).scaleInPlace(size));
    lines.push([v1.add(mesh.position), v2.add(mesh.position)]);
  }
  var normalLines = BABYLON.MeshBuilder.CreateLineSystem(
    "normalLines",
    { lines: lines },
    sc
  );
  normalLines.color = color;
  return normalLines;
}

function getRotationQuaternion(vector) {
  return new BABYLON.Quaternion.RotationYawPitchRoll(
    vector.y,
    vector.x,
    vector.z
  );
}

function localiseThePointsForMeshGeneration(points, v3 = true) {
  let sum = BABYLON.Vector3.Zero();

  if (!v3) {
    points = points.map((p) => BABYLON.Vector3.FromArray(p));
  }

  points.forEach((point) => {
    sum.addInPlace(point);
  });

  let centre = sum.scale(1 / points.length);

  if (v3) {
    points = points.map((vec) => vec.subtract(centre));
  } else {
    points = points.map((vec) => vec.subtract(centre)).map((p) => p.asArray());
  }

  return {
    points,
    centre,
  };
}

function printFaceVertices(mesh) {
  mesh
    .getSnaptrudeDS()
    .brep.getFaces()
    .forEach((face) => {
      console.log(getFaceVerticesFromFace(face, mesh, BABYLON.Space.WORLD));
    });
}

function showNormals(mesh) {
  // mesh.visibility = 0.3;
  // mesh.material.backFaceCulling = false;
  let localPositionsFrom = mesh.getFacetLocalPositions();
  let globalFrom = localPositionsFrom.map((pos) =>
    BABYLON.Vector3.TransformCoordinates(pos, mesh.getWorldMatrix())
  );
  let localNormals = mesh.getFacetLocalNormals();

  let localPositionsTo = localPositionsFrom.map((lp, i) =>
    lp.add(localNormals[i])
  );
  let globalTo = localPositionsTo.map((lp) =>
    convertLocalVector3ToGlobal(lp, mesh)
  );

  var lines = [];
  for (var i = 0; i < globalFrom.length; i++) {
    var line = [globalFrom[i], globalTo[i]];
    lines.push(line);
  }
  var lineSystem = BABYLON.MeshBuilder.CreateLineSystem(
    "normals",
    { lines: lines },
    store.scene
  );
  lineSystem.color = BABYLON.Color3.Red();
}

function disposeNormals() {
  while (store.scene.getMeshByName("normals")) {
    store.scene.getMeshByName("normals").dispose();
  }
}

function correctMeshNormals(mesh) {
  /*
    Updating facetData manually doesn't change _normals in mesh.getVerticesData(),
    which is used for shading. So it leads to an incomplete solution. Thus, have to address the root cause, the indices. Change them and do mesh.updateFacetData() which
    also takes care of _normals.
    */

  // Get old vertexData
  const vertexDataOld = BABYLON.VertexData.ExtractFromMesh(mesh);
  const indices = vertexDataOld.indices;
  const positions = vertexDataOld.positions;
  const uvs = vertexDataOld.uvs;
  const normals = [];

  // Maintain subMeshes as vertexData update on mesh will release submeshes
  const subMeshes = mesh.subMeshes;

  const localFacetPositions = mesh.getFacetLocalPositions();
  // let globalFacetPositions = localFacetPositions.map((pos) => convertLocalVector3ToGlobal(pos, mesh));
  let localFacetNormals = mesh.getFacetLocalNormals();

  const pushMag = 0.0005;
  localFacetPositions.forEach((localPosition, index) => {
    let normal = localFacetNormals[index].scale(pushMag);

    const pointToCheck = convertLocalVector3ToGlobal(
      localPosition.add(normal),
      mesh
    );
    if (isPointInsideTheMesh(pointToCheck, mesh)) {
      // facetNormals[index] = normal.negate();

      //Change the order of indices
      let holder = indices[index * 3 + 1];
      indices[index * 3 + 1] = indices[index * 3 + 2];
      indices[index * 3 + 2] = holder;
    }
  });

  // Recompute normals
  BABYLON.VertexData.ComputeNormals(positions, indices, normals);

  // Apply to new vertexData
  const vertexData = new BABYLON.VertexData();
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  vertexData.applyToMesh(mesh, true);
  // mesh.setIndices(indices, null, true);
  mesh.updateFacetData();
  mesh.subMeshes = subMeshes;
}

function updateSubMeshes(mesh, faces, faceFacetMapping) {
  mesh.synchronizeInstances();

  let material = mesh.material;
  if (!material) return;
  if (!mesh.material.subMaterials) return;
  if (mesh.isAnInstance) {
    mesh = mesh.sourceMesh;
  }
  let verticesCount = mesh.getTotalVertices();

  mesh.subMeshes = [];
  faces.forEach(function (face) {
    let faceIds = faceFacetMapping[face.index];
    let indicesStart = faceIds[0] * 3;
    let indicesCount = (faceIds[faceIds.length - 1] - faceIds[0] + 1) * 3;
    BABYLON.SubMesh.AddToMesh(
      face.materialIndex,
      0,
      verticesCount,
      indicesStart,
      indicesCount,
      mesh
    );
  });
}

function recalculateNormalsOfMassOrWall(mesh) {
  let normalsLength = mesh.getVerticesData(
    BABYLON.VertexBuffer.NormalKind
  ).length;
  let positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  let indices = mesh.getIndices();

  let normals = [];
  for (let i = 0; i < normalsLength; i++) {
    let faceId = i;

    let p1 = new BABYLON.Vector3(
      positions[indices[faceId * 3] * 3],
      positions[indices[faceId * 3] * 3 + 1],
      positions[indices[faceId * 3] * 3 + 2]
    );
    let p2 = new BABYLON.Vector3(
      positions[indices[faceId * 3 + 1] * 3],
      positions[indices[faceId * 3 + 1] * 3 + 1],
      positions[indices[faceId * 3 + 1] * 3 + 2]
    );
    let p3 = new BABYLON.Vector3(
      positions[indices[faceId * 3 + 2] * 3],
      positions[indices[faceId * 3 + 2] * 3 + 1],
      positions[indices[faceId * 3 + 2] * 3 + 2]
    );

    let vecU = p1.subtract(p3);
    let vecV = p2.subtract(p3);

    let normal = new BABYLON.Vector3(
      vecU.y * vecV.z - vecU.z * vecV.y,
      vecU.z * vecV.x - vecU.x * vecV.z,
      vecU.x * vecV.y - vecU.y * vecV.x
    );

    normal = normal.normalize();
    normals.push(normal);
  }
}

function invertNormals(mesh) {
  let normals = mesh.getFacetLocalNormals();
  normals.forEach(function (normal) {
    normal.x = -normal.x;
    normal.y = -normal.y;
    normal.z = -normal.z;
  });
}
function invertNormalData(data) {
  let newNormals = {
    x: -data.x,
    y: -data.y,
    z: -data.z,
  };
  return new BABYLON.Vector3(newNormals.x, newNormals.y, newNormals.z);
}

function getEmptyFunction() {
  return function () {};
}

function resetFuncs() {
  RotateOperation._reset();
}

function mmToSnaptrudeUnits(mm) {
  // return mm / (1e3 * store.inch_to_mtr);
  return mm / (1e3 * 0.254);
}

function metresToSnaptrudeUnits(metres) {
  return mmToSnaptrudeUnits(metres * 1e3);
}

/**
 * localArray is an array of arrays [[x, y, z], [x, y, z]]
 * If [x, z, y] set the third parameter as true
 * @param localArray
 * @param worldMatrix
 * @param xzyInput
 * @param xzyOutput
 * @returns {*} an array similar to the input
 */

function convertLocalCoordsToGlobal(
  localArray,
  worldMatrix,
  xzyInput = false,
  xzyOutput = false
) {
  let globalArray = localArray.map((coordsArray) => {
    if (xzyInput)
      var localV3 = new BABYLON.Vector3(
        coordsArray[0],
        coordsArray[2],
        coordsArray[1]
      );
    else
      var localV3 = new BABYLON.Vector3(
        coordsArray[0],
        coordsArray[1],
        coordsArray[2]
      );

    let globalV3 = BABYLON.Vector3.TransformCoordinates(localV3, worldMatrix);

    if (xzyOutput) return [globalV3.x, globalV3.z, globalV3.y];
    else return [globalV3.x, globalV3.y, globalV3.z];
  });

  return globalArray;
}

function convertGlobalCoordsToLocal(
  globalArray,
  worldMatrix,
  xzyInput = false,
  xzyOutput = false
) {
  let inverseWorldMatrix = new BABYLON.Matrix();
  worldMatrix.invertToRef(inverseWorldMatrix);
  let localArray = globalArray.map((coordsArray) => {
    if (xzyInput)
      var globalV3 = new BABYLON.Vector3(
        coordsArray[0],
        coordsArray[2],
        coordsArray[1]
      );
    else
      var globalV3 = new BABYLON.Vector3(
        coordsArray[0],
        coordsArray[1],
        coordsArray[2]
      );

    let localV3 = BABYLON.Vector3.TransformCoordinates(
      globalV3,
      inverseWorldMatrix
    );

    if (xzyOutput) return [localV3.x, localV3.z, localV3.y];
    else return [localV3.x, localV3.y, localV3.z];
  });

  return localArray;
}

function convertGlobalVector3ToLocal(globalV3, mesh, compute = false) {
  if (compute) mesh.computeWorldMatrix();
  let worldMatrix = mesh.getWorldMatrix().clone();
  return BABYLON.Vector3.TransformCoordinates(globalV3, worldMatrix.invert());
}

function convertLocalVector3ToGlobal(localV3, mesh, compute = false) {
  if (compute) mesh.computeWorldMatrix();
  let worldMatrix = mesh.getWorldMatrix();
  return BABYLON.Vector3.TransformCoordinates(localV3, worldMatrix);
}

/**
 * Indices returned as [[a, b, c],[]..]
 * @param point
 * @param component
 * @param space
 * @param threshold
 * @returns {Array}
 */
function getIndicesOfPointInVerData(point, component, space, threshold = 1e-3) {
  const mesh = component.mesh;

  if (space === BABYLON.Space.LOCAL) {
    point = convertLocalVector3ToGlobal(point, mesh);
  }
  if (
    virtualSketcher.util.isComponentPlanar(component) ||
    isMassDependant(component)
  ) {
    threshold = 1e-1;
  }

  let indices = [];
  let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

  for (var i = 0; i < verData.length; i = i + 3) {
    const localPoint = new BABYLON.Vector3(
      verData[i],
      verData[i + 1],
      verData[i + 2]
    );
    const globalPoint = convertLocalVector3ToGlobal(localPoint, mesh);
    if (BABYLON.Vector3.Distance(globalPoint, point) < threshold) {
      indices.push([i, i + 1, i + 2]);
    }
  }
  return indices;
}

var previousPositionX = null;
var previousPositionY = null;

function getMouseMovementSpeed(event) {
  if (event.movementX && event.movementY) {
    return Math.sqrt(event.movementX ** 2 + event.movementY ** 2);
  } else {
    if (previousPositionX && previousPositionY) {
      let movementX = event.screenX - previousPositionX;
      let movementY = event.screenY - previousPositionY;

      previousPositionX = event.screenX;
      previousPositionY = event.screenY;

      return Math.sqrt(movementX ** 2 + movementY ** 2);
    } else {
      previousPositionX = event.screenX;
      previousPositionY = event.screenY;
      return 0;
    }
  }
}

function isPointInsideTheMesh(pointToCheck, mesh) {
  let extremelySmallNudge = 1e-5;
  if (Math.abs(mesh.scaling.y) > 1){
    extremelySmallNudge = 1e-5 * Math.abs(mesh.scaling.y);
  }
  let numberOfIntersections = 0;

  let ray = new BABYLON.Ray(pointToCheck, BABYLON.Vector3.Up());
  while (numberOfIntersections < 1e2) {
    //ideally should be infinity, but can't risk an infinite loop
    let pickInfo = ray.intersectsMesh(mesh);

    if (pickInfo.hit) {
      let newOrigin = pickInfo.pickedPoint.add(
        ray.direction.scale(extremelySmallNudge)
      );
      ray.origin = newOrigin;
      numberOfIntersections++;
    } else {
      break;
    }
  }
  /*
    If the point is inside the mesh, the ray will intersect it
    odd number of times, else even. Not sure what'll happen if point is on a face.
     */
  if (numberOfIntersections % 2 === 1) return true;
}

function getComponentInTheVicinityOfThisComponent(thisComponent) {
  let allComponents =
    StoreyMutation.getAParticularStorey(thisComponent.storey)?.elements ?? [];

  allComponents = allComponents.filter(
    (c) =>
      ["wall", "mass", "roof", "floor"].includes(c.mesh.type.toLowerCase()) &&
      !c.mesh.parent &&
      c !== thisComponent
  );

  if (_.isEmpty(allComponents)) return;

  const whereThisComponentAt = thisComponent.mesh.getAbsolutePosition();
  let componentWeNeed;
  allComponents.some((c) => {
    if (isPointInTheVicinityOfMesh(whereThisComponentAt, c.mesh))
      componentWeNeed = c;

    return componentWeNeed;
  });

  if (!componentWeNeed) {
    allComponents.some((c) => {
      if (c.mesh.intersectsMesh(thisComponent.mesh)) componentWeNeed = c;

      return componentWeNeed;
    });
  }

  return componentWeNeed;
}

function getBabylonVertices(mesh) {
  const EXCLUDE_HEAVY_FURNITURE = 2000;
  const util = store.resolveEngineUtils;
  const vertices = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  if(vertices.length > EXCLUDE_HEAVY_FURNITURE) return null;
  return _.uniqWith(_.chunk(vertices, 3), (a1, a2) =>
    util.areArraysAlmostEqual(a1, a2)
  );
}

function areTwoMeshesCloseBy(m1, m2) {
  const intersecting = m1.intersectsMesh(m2);
  if (intersecting) return true;
  
  const options = {
    threshold: 1,
  };

  let closeBy = isPointInTheVicinityOfMesh(m1.getAbsolutePosition(), m2, options);
  if (closeBy) return true;

  closeBy = isPointInTheVicinityOfMesh(m2.getAbsolutePosition(), m1, options);
  if (closeBy) return true;

  return false;
}

function isPointInTheVicinityOfMesh(point, mesh, options = {}) {
  if (mesh.getBoundingInfo().intersectsPoint(point)) return true;

  let insideTheMesh = isPointInsideTheMesh(point, mesh);
  if (insideTheMesh) return true;

  let threshold = options.threshold || 0.1;
  let vertexThreshold = options.vertexThreshold || 2;

  // check if any vertices match
  // ray collision match near vertices is unpredictable

  const localPointArray = convertGlobalVector3ToLocal(point, mesh).asArray();
  const vertices = getBabylonVertices(mesh);
  if(!vertices) return false;

  if (
    vertices.inArray((v) => {
      return store.resolveEngineUtils.areArraysAlmostEqual(
        v,
        localPointArray,
        vertexThreshold
      );
    })
  )
    return true;

  let directions = [
    BABYLON.Vector3.Up(),
    BABYLON.Vector3.Down(),
    BABYLON.Vector3.Left(),
    BABYLON.Vector3.Right(),
    BABYLON.Vector3.Forward(),
    BABYLON.Vector3.Backward(),
  ];

  let inTheVicinity = false;

  directions.some((d) => {
    let ray = new BABYLON.Ray(point, d);
    let pickInfo = ray.intersectsMesh(mesh);

    if (pickInfo.hit) {
      if (BABYLON.Vector3.Distance(pickInfo.pickedPoint, point) < threshold) {
        inTheVicinity = true;
        return true;
      }
    }
  });

  return inTheVicinity;
}

function isPointOnTheFace(point, face, threshold = 1e-2) {
  let projection = projectionOfPointOnFace(point, face);
  return BABYLON.Vector3.Distance(point, projection) < threshold;
}

function isPointOnThePlane(point, plane) {
  return Math.abs(plane.signedDistanceTo(point)) < 0.01;
}

function isMeshThrowAway(mesh) {
  return (
    isThrowAwayIdentifier(mesh.type) ||
    isFloatEqual(
      mesh.position.x,
      GLOBAL_CONSTANTS.numbers.positions.throwAwayMesh,
      100
    )
  );
}

function isThrowAwayIdentifier(type) {
  return type.includes(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh);
}

function removeMeshThrowAwayIdentifier(mesh, updateGraph = false) {
  mesh.type = removeThrowAwayIdentifier(mesh.type);
  if (meshObjectMapping.mappingExist(mesh) && updateGraph)
    virtualSketcher.addWithoutGeometryEdit(mesh.getSnaptrudeDS());
}

function removeThrowAwayIdentifier(type) {
  return type.replace(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh, "");
}

function markMeshAsThrowAway(mesh, updateGraph = false) {
  if (!isThrowAwayIdentifier(mesh.type))
    mesh.type += GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh;
  if (updateGraph)
    virtualSketcher.removeWithoutGeometryEdit(mesh.getSnaptrudeDS());
}

function areComponentsSiblings(component1, component2) {
  if (component1.mesh.isAnInstance && component2.mesh.isAnInstance) {
    return component1.mesh.sourceMesh === component2.mesh.sourceMesh;
  } else {
    return false;
  }
}

function areThreeVectorsCollinear(p1, p2, p3, threshold) {
  let util = new ResolveEngineUtils();
  return (
    util.onSegment3D(p1, p2, p3, threshold) ||
    util.onSegment3D(p2, p3, p1, threshold) ||
    util.onSegment3D(p3, p1, p2, threshold)
  );
}

function areThreeVectorsCollinearIn2D(p1, p2, p3, threshold) {
  let util = new ResolveEngineUtils();
  return (
    util.onSegment2D(p1, p2, p3, threshold) ||
    util.onSegment2D(p2, p3, p1, threshold) ||
    util.onSegment2D(p3, p1, p2, threshold)
  );
}

function areEdgesParallel(edge1, edge2, threshold = 5) {
  const angleBetweenThem = getAngleBetweenEdges(edge1, edge2);

  return (
    isFloatEqual(angleBetweenThem, 0, threshold) ||
    isFloatEqual(angleBetweenThem, 180, threshold)
  );
}

function areVectorsParallel(vector1, vector2, threshold = 5) {
  let precise = false;
  if (threshold < 1) precise = true;
  const angleBetweenThem = getAngleBetweenVectors(vector1, vector2, precise);

  return (
    isFloatEqual(angleBetweenThem, 0, threshold) ||
    isFloatEqual(angleBetweenThem, 180, threshold)
  );
}

function areEdgesOverlapping(e1, e2, evenIfTouchingAtAVertex = false) {
  let overlapping = false;

  const parallel = areEdgesParallel(e1, e2);
  if (parallel) {
    const equalityCheck = !evenIfTouchingAtAVertex;
    if (areEdgesSimilar(e1, e2)) overlapping = true;
    else if (
      store.resolveEngineUtils.onSegment3D(
        e1.headPt,
        e2.headPt,
        e1.tailPt,
        null,
        equalityCheck
      ) ||
      store.resolveEngineUtils.onSegment3D(
        e1.headPt,
        e2.tailPt,
        e1.tailPt,
        null,
        equalityCheck
      ) ||
      store.resolveEngineUtils.onSegment3D(
        e2.headPt,
        e1.headPt,
        e2.tailPt,
        null,
        equalityCheck
      ) ||
      store.resolveEngineUtils.onSegment3D(
        e2.headPt,
        e1.tailPt,
        e2.tailPt,
        null,
        equalityCheck
      )
    ) {
      overlapping = true;
    }
  }

  return overlapping;
}

function invertEdge(edge) {
  return {
    headPt: edge.tailPt,
    tailPt: edge.headPt,
  };
}

function cloneEdge(edge) {
  return {
    headPt: edge.headPt.clone(),
    tailPt: edge.tailPt.clone(),
  };
}

function areEdgesIntersecting(edge1, edge2, threshold = 5) {
  const _checkIntersection = function (edge1, edge2, threshold) {
    const projection1 = projectionOfPointOnLine(
      edge2.headPt,
      edge1.headPt,
      edge1.tailPt
    );
    const projectionDirection1 = projection1.subtract(edge2.headPt);

    const projection2 = projectionOfPointOnLine(
      edge2.tailPt,
      edge1.headPt,
      edge1.tailPt
    );
    const projectionDirection2 = projection2.subtract(edge2.tailPt);

    const angleBetweenThem = getAngleBetweenVectors(
      projectionDirection1,
      projectionDirection2
    );

    return isFloatEqual(angleBetweenThem, 180, threshold);
  };

  return (
    _checkIntersection(edge1, edge2, threshold) &&
    _checkIntersection(edge2, edge1, threshold)
  );
}

function areTwoLinesCollinear(v1, v2, threshold = 10) {
  // threshold in degrees

  let angle = getAngleBetweenVectors(v1, v2);
  if (
    isFloatEqual(angle, 0, threshold) ||
    isFloatEqual(angle, 180, threshold)
  ) {
    return true;
  }
}

const TOAST_TYPES = {
  success: "success",
  error: "error",
};

function showToast(message, delay = 3000, type = "default") {
  /* AG-RE: ANGULAR REFERENCES */
  // alert(message);
  // console.warn("AG-RE: call showToast(message, delay, type)", message, delay, type);

  let notificationElement = document.getElementById("notificationElement");
  if (!notificationElement) notificationElement = document.createElement("div");

  notificationElement.setAttribute("class", `toast ${type}-toast`);
  notificationElement.setAttribute("id", "notificationElement");
  notificationElement.innerText = message;
  document.getElementById("toastWrapper").appendChild(notificationElement);

  if (delay === 0) {
    // persistent toast
  } else {
    setTimeout(() => {
      notificationElement.remove();
    }, delay);
  }
}

function hideToast() {
  /* AG-RE: ANGLUAR REFERENCE */
  const notificationElement = document.getElementById("notificationElement");
  if (notificationElement) notificationElement.remove();
  // console.warn("AG-RE: called hideToast()");
  // ScopeUtils.getScope().hideToast();
}

function updateToast(message) {
  /* AG-RE: ANGLUAR REFERENCE */
  // console.warn("AG-RE: called updateToast(message)", message);
  const notificationElement = document.getElementById("notificationElement");
  if (notificationElement) notificationElement.innerText = message;
  // store.angular.element(function () {
  //   let $scope = getScope("AppCtrl");
  //   $scope.updateToast(message);
  // });
}

function doManualSave() {
  return send_complete_scene_data("save");
}

/*
Not using this since keys have to be passed as strings
and I'm not comfortable with the idea. Still keeping it here
 */
function getUserSetting(key) {
  return store.userSettingsInStructure[key];
}

function setUserSetting(key, value) {
  let valueBefore = store.userSettingsInStructure[key];
  store.userSettingsInStructure[key] = value;

  return valueBefore;
}

function setUserSettingAndRecord(key, value) {
  let valueBefore = store.userSettingsInStructure[key];
  let valueAfter = value;
  // if (!valueBefore || !valueAfter) return;
  if (key == "storeyUIToggle" || key == "layerUIToggle") {
  } else if (valueAfter === valueBefore) return;

  store.userSettingsInStructure[key] = valueAfter;

  let saveData = AutoSave.getSaveDataPrototype();

  saveData.commandId = makeid(5);
  saveData.data.identifier = {
    floorkey: store.floorkey,
    structure_id: store.activeLayer
      ? store.activeLayer.structure_id || "default"
      : "default",
  };
  saveData.data.saveType = "setUserSetting";

  saveData.data.afterOperationData = [
    {
      key,
      value: valueAfter,
    },
  ];

  saveData.data.beforeOperationData = [
    {
      key,
      value: valueBefore,
    },
  ];

  AutoSave.directPublish(saveData);
}

function getPlansData() {
  let getPlansURL = "/plansgallu/";
  $.ajax({
    url: getPlansURL,
    data: {
      floorkey: store.floorkey,
      csrfmiddlewaretoken: store.csrf_token,
    },
    success: function (data) {
      console.log(data);
    },
    error: function (e, status) {
      console.log(e, status);
    },
    dataType: "json",
    type: "POST",
  });
}

function setScaleForFPAndMeshes() {
  store.angular.element(function () {
    let $scope = getScope("AppCtrl");
    setScaleOperation._reset();
    if (store.selectionStack.length > 0) {
      let scaleFactor =
        DisplayOperation.getOriginalDimension($scope.tape_measure_value) /
        DisplayOperation.getOriginalDimension($scope.old_tape_measure_value);
      scaleSceneMeshes(scaleFactor);
      updateModifications();
    } else {
      let scaleFactor =
        DisplayOperation.getOriginalDimension($scope.tape_measure_value) /
        DisplayOperation.getOriginalDimension($scope.old_tape_measure_value);
      scaleSceneMeshes(scaleFactor, "imageScale");
      let structures = StructureCollection.getInstance();
      let str = structures.getStructureById(store.activeLayer.structure_id);
      let storey = str
        .getStoreyData()
        .getStoreyByValue(store.activeLayer.storey);
      // let storeyData = storeyCollection.getStoreyByValue(activeLayer.storey);
      // let diff;

      // if (parseInt($('#storey_number').val()) > storeyCollection.getStoreysLength())
      //     diff = parseInt($('#storey_number').val()) - storeyCollection.getStoreysLength();
      // for (let i = 0; i < diff; i++) {
      //    storeyCollection.addStorey(activeLayer.structure_id, storeyData.base, storeyData.value);
      // }
      layerView.generateRefPlane(storey);
      updateModifications().then(send_complete_scene_data("save"));
      store.activeLayer.floorplans[0].mesh.computeWorldMatrix(true);
    }
    let scaleFactor =
      DisplayOperation.getOriginalDimension($scope.tape_measure_value) /
      DisplayOperation.getOriginalDimension($scope.old_tape_measure_value);
    showToast(
      "Objects scaled by factor of " + Math.round(scaleFactor * 1000) / 1000
    );
    $scope.tape_measure_value = 0;
    store.selectionStack.length = 0;
    if (store.$scope.isTwoDimension) changeToOrthoViewCamera(true);
    removeMouseEvents();
    onCamRot();
  });
}

function getBabylonPlane(points, position) {
  let facePlane;

  let sampledPoints = _.sampleSize(points, 3);

  while (areThreeVectorsCollinear(...sampledPoints)){
    sampledPoints = _.sampleSize(points, 3);
  }

  facePlane = new BABYLON.Plane.FromPoints(...sampledPoints);

  /*if (points.length > 4) {
    /!*To handle the cases where face is actually not planar,
        usually resulting after edit edge or vertex.
        This is not a solution but kind of a randomised prevention*!/
    facePlane = new BABYLON.Plane.FromPoints(
      points[0],
      points[Math.floor(points.length / 2)],
      points[points.length - 1]
    );
  } else {
    facePlane = new BABYLON.Plane.FromPoints(points[0], points[1], points[2]);
  }*/

  if (position) facePlane.position = position;

  return facePlane;
}

function getBabylonGUIElementByName(name) {
  return store.advancedTexture._rootContainer.getChildByName(name);
}

function isPointerOverGUIElement() {
  return (
    store.advancedTexture._shouldBlockPointer ||
    (store.isiPad && store.uiCoordinationVariables.clickedOnInputBox)
  );
}

function addTagToMesh(mesh, tag) {
  BABYLON.Tags.AddTagsTo(mesh, tag);
}

function getEdgesFromFaceVertices (faceVertices){
  let length = faceVertices.length;
  return faceVertices.map((vertex, i) => {
    const nextIndex = i === length - 1 ? 0 : i + 1;
    
    return {
      headPt: vertex,
      tailPt: faceVertices[nextIndex],
    };
  });
};

function getAngleBetweenEdges(edge1, edge2) {
  return getAngleBetweenVectors(
    edge1.headPt.subtract(edge1.tailPt),
    edge2.headPt.subtract(edge2.tailPt)
  );
}

function getFaceArea(mesh, facetId, faceId) {
  let totalArea = 0;
  try {
    function _throwError() {
      throw "Calculate for facet itself";
    }

    let faceFacetMapping = mesh.getSnaptrudeDS().faceFacetMapping;
    if (!faceFacetMapping) _throwError();

    if (!faceId) {
      faceId = getFaceIdFromFacet(facetId, mesh);
    }

    if (isNaN(faceId)) _throwError();

    faceFacetMapping[faceId].forEach((facetId) => {
      totalArea += getFacetArea(mesh, facetId);
    });
  } catch (e) {
    totalArea = getFacetArea(mesh, facetId);
  }

  return totalArea;
}

/**
 * Got this from BABYLON snippets
 *
 * @param mesh
 * @param facetId
 * @returns {number}
 */
function getFacetArea(mesh, facetId) {
  if (!mesh && !facetId) {
    return 0.0;
  }
  var indices = mesh.getIndices();
  if (facetId < 0 || facetId > nbFaces) {
    return 0.0;
  }
  var nbFaces = indices.length / 3;
  let positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

  let i1 = indices[facetId * 3];
  let i2 = indices[facetId * 3 + 1];
  let i3 = indices[facetId * 3 + 2];

  let v1dash = new BABYLON.Vector3(
    positions[i1 * 3],
    positions[i1 * 3 + 1],
    positions[i1 * 3 + 2]
  );
  let v2dash = new BABYLON.Vector3(
    positions[i2 * 3],
    positions[i2 * 3 + 1],
    positions[i2 * 3 + 2]
  );
  let v3dash = new BABYLON.Vector3(
    positions[i3 * 3],
    positions[i3 * 3 + 1],
    positions[i3 * 3 + 2]
  );

  v1dash = convertLocalVector3ToGlobal(v1dash, mesh);
  v2dash = convertLocalVector3ToGlobal(v2dash, mesh);
  v3dash = convertLocalVector3ToGlobal(v3dash, mesh);

  let v1x = v1dash.x - v2dash.x;
  let v1y = v1dash.y - v2dash.y;
  let v1z = v1dash.z - v2dash.z;
  let v2x = v3dash.x - v2dash.x;
  let v2y = v3dash.y - v2dash.y;
  let v2z = v3dash.z - v2dash.z;

  let crossx = v1y * v2z - v1z * v2y;
  let crossy = v1z * v2x - v1x * v2z;
  let crossz = v1x * v2y - v1y * v2x;

  return Math.sqrt(crossx * crossx + crossy * crossy + crossz * crossz) * 0.5;
}

function getVertexCloserToPoint(edge, refPoint) {
  let closerVertex;

  if (
    BABYLON.Vector3.Distance(refPoint, edge.headPt) <
    BABYLON.Vector3.Distance(refPoint, edge.tailPt)
  ) {
    closerVertex = edge.headPt;
  } else {
    closerVertex = edge.tailPt;
  }

  return closerVertex;
}

function getVertexProximityInfo(edge, refPoint) {
  let closerVertex, fartherVertex;

  if (
    BABYLON.Vector3.Distance(refPoint, edge.headPt) <
    BABYLON.Vector3.Distance(refPoint, edge.tailPt)
  ) {
    closerVertex = edge.headPt;
    fartherVertex = edge.tailPt;
  } else {
    closerVertex = edge.tailPt;
    fartherVertex = edge.headPt;
  }

  return {
    closerVertex: closerVertex.clone(),
    fartherVertex: fartherVertex.clone()
  };
}

function getCentroidOfVertices(vertices) {
  return vertices
    .reduce((acc, v) => acc.add(v), BABYLON.Vector3.Zero())
    .scale(1 / vertices.length);
}

function getCollinearVertices(vertices, metadata = {}) {
  const collinearVertices = [];
  // const resolveEngineUtils = new ResolveEngineUtils();
  metadata.surroundingVertices = [];
  const length = vertices.length;
  vertices.forEach((vertex, i) => {
    const nextIndex = i === length - 1 ? 0 : i + 1;
    const previousIndex = i === 0 ? length - 1 : i - 1;

    const nextVertex = vertices[nextIndex];
    const previousVertex = vertices[previousIndex];

    if (
      store.resolveEngineUtils.onSegment3D(previousVertex, vertex, nextVertex)
    ) {
      collinearVertices.push(vertex);
      metadata.surroundingVertices.push([previousVertex, nextVertex]);
    }
  });

  return collinearVertices;
}

function removeCollinearVertices(vertices){
  const collinearVertices = getCollinearVertices(vertices);
  return _.difference(vertices, collinearVertices);
}

const getActiveStoreyHeight = function () {
  const storeyData = StructureCollection.getInstance()
    .getStructureById(store.activeLayer.structure_id)
    .getStoreyData();

  let activeStorey = store.activeLayer.storey;
  if (!store.$scope.isTwoDimension) activeStorey = 1;

  return storeyData.getStoreyByValue(activeStorey).height;
  // return store.activeStoreyHeight;
};

function getDistanceBetweenVectors(v1, v2) {
  return BABYLON.Vector3.Distance(v1, v2);
}

function getDistanceIn2DBetweenVectors(v1, v2) {
  return BABYLON.Vector2.Distance(
    getVector2FromVector3(v1),
    getVector2FromVector3(v2)
  );
}

function getDistanceBetweenVectorAndFace(v3, faceVertices) {
  
  faceVertices = sanitizeVertices(faceVertices);
  const plane = BABYLON.Plane.FromPoints(faceVertices[0], faceVertices[1], faceVertices[2]);
  
  return Math.abs(plane.signedDistanceTo(v3));
}

function getVector2FromVector3(v3) {
  return new BABYLON.Vector2(v3.x, v3.z);
}

function getV3ProjectedOntoScreenSpace(v3) {
  const projectedV3 = BABYLON.Vector3.Project(
    v3,
    BABYLON.Matrix.Identity(),
    store.newScene.activeCamera
      .getViewMatrix()
      .multiply(store.newScene.activeCamera.getProjectionMatrix()), // guess store.scene.getTransformMatrix() also works here
    store.newScene.activeCamera.viewport.toGlobal(
      store.engine.getRenderWidth(),
      store.engine.getRenderHeight()
    )
  );

  return new BABYLON.Vector2(
    Math.round(projectedV3.x),
    Math.round(projectedV3.y)
  );
}

function getV2ProjectedOntoModelSpace(v2) {
  return BABYLON.Vector3.Unproject(
    new BABYLON.Vector3(v2.x, v2.y, 0),
    store.engine.getRenderWidth(),
    store.engine.getRenderHeight(),
    BABYLON.Matrix.Identity(),
    store.scene.getViewMatrix(),
    store.scene.getProjectionMatrix()
  );
}

function getRoomHeight(roomType) {
  let userSelectedLabel = roomType;
  let defaultHeight = getActiveStoreyObject()
    ? getActiveStoreyObject().height
    : store.floor_height;

  // let roomProperties = getRoomTypeProperties(userSelectedLabel);
  let roomHeightInMetres = ScopeUtils.getRoomHeight(userSelectedLabel);

  let roomHeight = defaultHeight;

  if (roomHeightInMetres) {
    roomHeight = metresToSnaptrudeUnits(roomHeightInMetres);
  }

  return roomHeight;
}

function setMeshVisibility(mesh, amount) {
  if (mesh.isAnInstance) {
    // can't do anything
  } else {
    mesh.visibility = amount;
  }
}

function changeHeightOfObjects(stack, options) {
  let newHeightInBabylonUnits = options.newHeightInBabylonUnits;
  let currentHeightInBabylonUnits = options.currentHeightInBabylonUnits;
  let commandName = options.commandName;

  const createCommand = !!commandName;

  if (!currentHeightInBabylonUnits) {
    let boundingBox = stack[0].getBoundingInfo().boundingBox;
    currentHeightInBabylonUnits =
      boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;
  }

  let heightChangeCommand;

  let offsetY = options.offsetY || 0;

  function _changeMeshScaling(mesh) {
    let prevScalingY = mesh.scaling.y;
    /*
    if (mesh.type.toLowerCase() === "roof" && mesh.storey === 1) {
      mesh.scaling.y *= Math.abs(
        store.projectProperties.properties.plinthHeightProperty.getValue() /
          currentHeightInBabylonUnits
      );
      // let defPos = DisplayOperation.getOriginalDimension(GLOBAL_CONSTANTS.numbers.positions.plinth, "millimeter");
      mesh.position.y =
        -store.projectProperties.properties.plinthHeightProperty.getValue() / 2;
      mesh.getSnaptrudeDS().slabType = "Plinth";
      return;
    } else {
      mesh.scaling.y *= Math.abs(
        newHeightInBabylonUnits / currentHeightInBabylonUnits
      );
    }*/

    let alignBottomFace = true;
    // the top face aligns with the position where the top face was before scaling

    if (mesh.type.toLowerCase() === "roof" && mesh.storey === 1) {
      alignBottomFace = false;
      // the top face aligns with the position where the top face was before scaling
    }

    mesh.scaling.y *= Math.abs(
      newHeightInBabylonUnits / currentHeightInBabylonUnits
    );

    let changeInScaling;
    let s1 = Math.abs(mesh.scaling.y);
    let s2 = Math.abs(prevScalingY);
    if (
      Math.sign(currentHeightInBabylonUnits) ===
      Math.sign(newHeightInBabylonUnits)
    ) {
      changeInScaling = s1 - s2;
    } else {
      //this will happen for water bodies which go downwards
      changeInScaling = (s1 + s2) * Math.sign(newHeightInBabylonUnits);
    }

    /*
    let roomTypeAfterLC;
    if (options.roomTypeAfter) {
      roomTypeAfterLC = ScopeUtils.getRoomType(
        options.roomTypeAfter
      ).toLowerCase();
    }

    if(["site", "ground", "deck", "road"].includes(roomTypeAfterLC) && mesh.storey === 1){
        mesh.position.y = DisplayOperation.getOriginalDimension(getLabelTypePosition(roomTypeAfterLC));
    }
    else{
      mesh.position.y +=
        changeInScaling * mesh.getBoundingInfo().boundingBox.extendSize.y +
        offsetY;
    }*/

    let yChange =
      changeInScaling * mesh.getBoundingInfo().boundingBox.extendSize.y +
      offsetY;
    if (alignBottomFace) mesh.position.y += yChange;
    else mesh.position.y -= yChange;
  }

  if (
    !isFloatEqual(newHeightInBabylonUnits, currentHeightInBabylonUnits, 1e-4)
  ) {
    let optionsForWMChange, commandData;

    let childrenNotToScale = [];
    stack.forEach((mesh) => {
      let children = mesh.getChildren();
      if (children) {
        children.forEach((child) => {
          if (
            child.type.toLowerCase() === "mass" &&
            child.getSnaptrudeDS().massType === "Plinth"
          ) {
            childrenNotToScale.push(child);
          }
        });
      }
    });

    if (createCommand) {
      optionsForWMChange = {
        params: [
          commandUtils.worldMatrixChangeOperations.PARAMS.scale,
          commandUtils.worldMatrixChangeOperations.PARAMS.position,
        ],
        stack,
      };

      if (childrenNotToScale.length) {
        optionsForWMChange.dataOptions = {};
        optionsForWMChange.dataOptions.excludeChildren = childrenNotToScale.map(
          (mesh) => mesh.uniqueId
        );
      }

      commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        null,
        optionsForWMChange
      );
    }

    let parents = [];
    childrenNotToScale.forEach((child) => {
      parents.push(child.parent);
      child.setParent(null);
    });

    stack.forEach((i) => _changeMeshScaling(i));
    
    if (options.assignStorey){
      const components = stack.map(m => m.getSnaptrudeDS());
      StoreyMutation.assignStorey(components);
    }

    childrenNotToScale.forEach(function (child, index) {
      child.setParent(parents[index]);
    });

    if (createCommand) {
      optionsForWMChange.data = commandData;
      commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
        null,
        optionsForWMChange
      );

      heightChangeCommand = commandUtils.worldMatrixChangeOperations.getCommand(
        commandName,
        commandData,
        optionsForWMChange
      );
    }
  }
  return heightChangeCommand;
}

function changeLabelOfRoom(mesh, roomTypeAfter) {
  function _changePropertiesOfMesh(mesh, value) {
    mesh.room_type = value;
    mesh.getSnaptrudeDS().room_type = value;
  }

  function _toggleDisplayBox(mesh, value) {
    let displayBox = getBabylonGUIElementByName(
      "label-input-" + mesh.room_unique_id
    );
    if (displayBox) {
      displayBox.text = value;
    }
  }

  function _labelPostExecute() {
    this.data.forEach((dataPoint) => {
      let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
      if (mesh) {
        _toggleDisplayBox(mesh, dataPoint.afterKeyValuesMesh.room_type);
      }
    });
  }

  function _labelPostUnExecute() {
    this.data.forEach((dataPoint) => {
      let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
      if (mesh) {
        _toggleDisplayBox(mesh, dataPoint.beforeKeyValuesMesh.room_type);
      }
    });
  }

  if (roomTypeAfter === "") {
    _toggleDisplayBox(mesh, mesh.room_type);
    return;
  }
  let stack;
  if (mesh.isAnInstance) {
    let instances = _.filter(mesh.sourceMesh.instances, (mesh) => {
      if (Mass.isPlinth(mesh)) return false;
      if (isMeshThrowAway(mesh)) return false;
      return true;
    });

    if (isMeshThrowAway(mesh.sourceMesh)) {
      mesh = instances[0];
      stack = [mesh.sourceMesh, ...instances];
    } else {
      mesh = mesh.sourceMesh;
      stack = [mesh, ...instances];
    }
  } else {
    if (isNeighborhoodBuilding(mesh)) return false;
    stack = [mesh];
  }

  const componentStack = stack.map((m) => m.getSnaptrudeDS());

  let optionsForLabelChange = {
    meshKeys: ["room_type"],
    componentKeys: ["room_type"],
  };

  let labelChangeCommandData =
    commandUtils.propertyChangeOperations.getCommandData(
      stack,
      optionsForLabelChange
    );
  optionsForLabelChange.data = labelChangeCommandData;

  stack.forEach((m) => _changePropertiesOfMesh(m, roomTypeAfter));
  const roomNameUpdateEvent = new CustomEvent("room-name-updated");
  window.dispatchEvent(roomNameUpdateEvent);
  labelChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
    stack,
    optionsForLabelChange
  );

  optionsForLabelChange.postExecuteCallback = _labelPostExecute;
  optionsForLabelChange.postUnExecuteCallback = _labelPostUnExecute;

  let labelChangeCommand = commandUtils.propertyChangeOperations.getCommand(
    "roomLabelChange - Property",
    labelChangeCommandData,
    optionsForLabelChange
  );

  let heightChangeCommand = null;

  let materialCommand = null;

  let roomTypeAfterLC = ScopeUtils.getRoomType(roomTypeAfter);
  let meshDS = mesh.getSnaptrudeDS();

  let meshesDeleted = [];
  if (
    ["Site", "Deck", "Road", "Ground"].includes(roomTypeAfterLC) &&
    !_.isEmpty(stack)
  ) {
    stack.forEach((mesh) => {
      mesh.childrenComp.forEach((child) => {
        if (Mass.isPlinth(child)) {
          meshesDeleted.push(child);
        }
      });
    });
  }

  let deletionCommand = null;
  if (meshesDeleted.length > 0) {
    let deletionCommandData = commandUtils.deletionOperations.getCommandData(
      meshesDeleted,
      null
    );

    deletionCommand = commandUtils.deletionOperations.getCommand(
      "meshesDeletion",
      deletionCommandData
    );
  }

  if (
    !meshDS.isEdited() &&
    meshDS.type.toLowerCase() === "mass" &&
    meshDS.massType.toLowerCase() === "room"
  ) {
    if (meshDS.updateDefaultMaterial) {
      materialCommand = meshDS.updateDefaultMaterial();
    }

    let boundingBox = mesh.getBoundingInfo().boundingBox;
    let currentHeightInBabylonUnits =
      boundingBox.maximumWorld.y - boundingBox.minimumWorld.y;
    let newHeightInBabylonUnits = getRoomHeight(roomTypeAfter);
    if (roomTypeAfter.toLowerCase() === "site") ScopeUtils.updateSiteArea(mesh);

    let storeyObject = StructureCollection.getInstance()
      .getStructureById(meshDS.structure_id)
      .getStoreyData()
      .getStoreyByValue(meshDS.storey);

    if (mesh.getAbsolutePosition().y < storeyObject.base) {
      //this is a water body or a similar type which goes down
      currentHeightInBabylonUnits *= -1;
    }

    heightChangeCommand = changeHeightOfObjects(stack, {
      newHeightInBabylonUnits,
      currentHeightInBabylonUnits,
      commandName: "roomLabelChange - Height",
      roomTypeAfter: roomTypeAfterLC,
    });

    virtualSketcher.updateWithoutGeometryEdit(componentStack);
  }

  if (!virtualSketcher.util.shouldAddComponentToGraph(meshDS)) {
    virtualSketcher.removeWithoutGeometryEdit(componentStack);
  }

  let faceTrimCommand;
  // faceTrimCommand = doMassPostProcessing(componentStack);

  let commands = _.compact([
    labelChangeCommand,
    heightChangeCommand,
    faceTrimCommand,
    materialCommand,
  ]);
  let yets = commands.map((_) => false);

  if (deletionCommand) {
    commands.push(deletionCommand);
    yets.push(true);
  }

  commands.executeLeftToRight = true;
  CommandManager.execute(commands, yets);
}

const getLabelChangeCommand = function (mesh, roomTypeAfter){
  function _changePropertiesOfMesh(mesh, value) {
    mesh.room_type = value;
    mesh.getSnaptrudeDS().room_type = value;
  }

  function _toggleDisplayBox(mesh, value) {
    let displayBox = getBabylonGUIElementByName(
      "label-input-" + mesh.room_unique_id
    );
    if (displayBox) {
      displayBox.text = value;
    }
  }

  function _labelPostExecute() {
    this.data.forEach((dataPoint) => {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (mesh) {
          _toggleDisplayBox(mesh, dataPoint.afterKeyValuesMesh.room_type);
        }
    });
  }
  
  function _labelPostUnExecute() {
    this.data.forEach((dataPoint) => {
        let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
        if (mesh) {
          _toggleDisplayBox(mesh, dataPoint.beforeKeyValuesMesh.room_type);
        }
    });
  }
  
  let stack;
  if (mesh.isAnInstance) {
    let instances = _.filter(mesh.sourceMesh.instances, (mesh) => {
      if (Mass.isPlinth(mesh)) return false;
      if (isMeshThrowAway(mesh)) return false;
      return true;
    });
  
    if (isMeshThrowAway(mesh.sourceMesh)) {
      mesh = instances[0];
      stack = [mesh.sourceMesh, ...instances];
    } else {
      mesh = mesh.sourceMesh;
      stack = [mesh, ...instances];
    }
  } else {
    if (isNeighborhoodBuilding(mesh)) return false;
    stack = [mesh];
  }
  
  // const componentStack = stack.map((m) => m.getSnaptrudeDS());
  
  let optionsForLabelChange = {
      meshKeys: ["room_type"],
      componentKeys: ["room_type"],
    };
    
  let labelChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
    stack,
    optionsForLabelChange
  );
  optionsForLabelChange.data = labelChangeCommandData;
  
  stack.forEach((m) => _changePropertiesOfMesh(m, roomTypeAfter));
  
  labelChangeCommandData = commandUtils.propertyChangeOperations.getCommandData(
  stack,
  optionsForLabelChange
  );
  
  optionsForLabelChange.postExecuteCallback = _labelPostExecute;
  optionsForLabelChange.postUnExecuteCallback = _labelPostUnExecute;
  
  let labelChangeCommand = commandUtils.propertyChangeOperations.getCommand(
      "roomLabelChange - Property",
      labelChangeCommandData,
      optionsForLabelChange
    );
  
  return {
    command: labelChangeCommand,
    yets: false
  }
}

function getRoomTypeProperties(roomType) {
  /*let category = _.findKey(labelCategories, category => {
        return category.inArray(arrayElement => {
            return arrayElement.toLowerCase() === roomType.toLowerCase();
        });
    });*/
  let category = ScopeUtils.getRoomType(roomType);
  if (!category) return null;

  let properties = store.roomTypeProperties[category];
  
  if (!properties) {
    properties = { mass: {}, bim: {} };
  }
  
  return properties;
}

function getActiveStoreyObject() {
  try {
    let obj = StructureCollection.getInstance()
      .getStructures()
      [store.activeLayer.structure_id].getStoreyData()
      .getStoreyByValue(store.activeLayer.storey);
    if (!obj) throw new Error();

    return obj;
  } catch (e) {
    return {};
  }
}

function disposeMesh(name) {
  let mesh = store.scene.getMeshByName(name);
  if (mesh) mesh.dispose();
}

function disposeMeshById(uniqueId) {
  let mesh = store.scene.getMeshByUniqueID(uniqueId);
  if (mesh) mesh.dispose();
}

function returnArrayOfV3s(arrayOfArgs) {
  return arrayOfArgs.map((arg) => {
    if (_.isArray(arg)) {
      return returnV3(arg);
    } else return arg;
  });
}

function returnV3(arg) {
  if (_.isArray(arg)) {
    return getVector3FromArray(arg);
  } else return arg;
}

function getVector3FromArray(array) {
  return BABYLON.Vector3.FromArray(array);
}

function updateLevelInfo(mesh) {
  mesh.computeWorldMatrix();
  mesh.refreshBoundingInfo();

  let sc = StructureCollection.getInstance();
  let structure = sc.getStructureById(mesh.structure_id);
  let thing = structure.getObjectByUniqueId(mesh.uniqueId);
  if (thing.notifyAll) thing.notifyAll("resetlevel");
}

function deepCopyObject(object) {
  return _.cloneDeep(object);
}

function getPermissionsBasedOnRole(role) {
  const { projectMetadata } = reduxStore.getState()
  const isTeamsProject = projectMetadata?.isTeamsProject

  if(isTeamsProject) {
    const { teams: { selectedTeam } } = reduxStore.getState()

    if(selectedTeam && selectedTeam.roleBasedPermissions) {
      return selectedTeam.roleBasedPermissions[role]
    }
  }
}

function getCurrentUserRole() {
  const { projectMetadata } = reduxStore.getState()
  const isTeamsProject = projectMetadata?.isTeamsProject

  if(isTeamsProject) {
    const { teams: { selectedTeam } } = reduxStore.getState()

    if(selectedTeam) return selectedTeam?.role
  } else {
    const { roles: { roles } } = reduxStore.getState()
    const email = store.infoUser?.email

    if(Array.isArray(roles)) {
      const role = roles.find(role => role?.email == email)

      if(role) return role.permission
    }
  }

  return null
}

function isAllowedToEditModel() {
  // check is Team's project
  const { projectMetadata: { isTeamsProject } } = reduxStore.getState()
  const role = getCurrentUserRole()

  // role must exist if project is shared to the person or is a team member
  // if role doesn't exist means it is not shared with current person and not a team member
  // meaning -> project can be opened by anyone with link
  // so, permission for edit model does not exist
  if(!role) return false

  if(isTeamsProject) {
    const permissions = getPermissionsBasedOnRole(role)
    if(permissions?.edit_model) return true
    else return false
  }

  if(role == 'viewer') return false

  return true
}

function setActiveLayerAndRecord(layer) {
  store.activeLayer = layer;
  if(isAllowedToEditModel()) saveActiveLayerInformation();
}

function saveActiveLayerInformation() {
  if (store.activeLayer) {
    let saveData = AutoSave.getSaveDataPrototype();

    saveData.data.saveType = "changeActiveLayer";
    saveData.data.identifier = {
      structure_id: store.activeLayer.structure_id,
      floorkey: store.floorkey,
    };
    saveData.data.afterOperationData = {
      name: store.activeLayer.name,
      storey: store.activeLayer.storey,
      structure_id: store.activeLayer.structure_id,
    };

    AutoSave.directPublish(saveData);
  }
}

function rubSandpaper(component, { removeCollinearVertices = true }) {
  const _removeVertex = function (vertex) {
    let optionsForRemoval = {
      operatingSpace,
      faceId: metadata.faceId,
    };

    let removed = removeVertexFromComponent(
      vertex,
      component,
      optionsForRemoval
    );
    // console.log(removed);

    topVertices = getTopFaceVertices(component, operatingSpace, metadata);
  };

  const _preciseSandpaper = function () {
    const _areNodesEquivalent = function (node1, node2) {
      return node1.data.almostEquals(node2.data, clusterDistance);
    };

    const verticesDLL = new CircularLinkedList("vertices");
    topVertices.forEach((v) => verticesDLL.append(v));

    let prominentEdge1 = {};
    let prominentEdge2 = {};

    let counter = 0;
    let vertexNode1 = verticesDLL.giveHead();

    let questionableVertices = [];
    while (_.isEmpty(prominentEdge1) || _.isEmpty(prominentEdge2)) {
      counter++;
      if (counter > topVertices.length) break;

      if (_areNodesEquivalent(vertexNode1, vertexNode1.next)) {
        vertexNode1 = vertexNode1.next;
      } else {
        let vertexNode2 = vertexNode1.next;
        if (_areNodesEquivalent(vertexNode2, vertexNode2.next)) {
          prominentEdge1.first = vertexNode1.data;
          prominentEdge1.second = vertexNode2.data;

          let vertexNode3 = vertexNode2.next;
          while (_areNodesEquivalent(vertexNode3, vertexNode3.next)) {
            questionableVertices.push(vertexNode3.data);
            vertexNode3 = vertexNode3.next;
          }

          questionableVertices.push(vertexNode2.data, vertexNode3.data);

          prominentEdge2.first = vertexNode3.data;
          prominentEdge2.second = vertexNode3.next.data;
          break;
        } else {
          vertexNode1 = vertexNode1.next;
        }
      }
    }

    if (_.isEmpty(prominentEdge1) || _.isEmpty(prominentEdge2)) return false;

    if (!_.isEmpty(questionableVertices)) {
      const options = {
        type: "vector-vector",
      };

      let idealVertex = externalUtil.getPointOfIntersection(
        [
          prominentEdge1.first,
          prominentEdge1.second,
          prominentEdge2.first,
          prominentEdge2.second,
        ],
        options
      );

      if (!idealVertex) {
        idealVertex = questionableVertices[0];
      }

      const distancesFromIdealVertex = questionableVertices.map((qv) =>
        BABYLON.Vector3.Distance(qv, idealVertex)
      );

      const innocentQuestionableVertex =
        questionableVertices[
          distancesFromIdealVertex.indexOf(_.min(distancesFromIdealVertex))
        ];

      _.remove(questionableVertices, innocentQuestionableVertex);

      questionableVertices.forEach((qv) => {
        _removeVertex(qv);
      });

      _preciseSandpaper();
    }
  };

  const _sharpEdgeRemover = function () {
    const verticesDLL = new CircularLinkedList("vertices");
    topVertices.forEach((v) => verticesDLL.append(v));

    const verticesToRemove = [];
    verticesDLL.forEach((vNode) => {
      const vertex = vNode.data;
      const previousVertex = vNode.prev.data;
      const nextVertex = vNode.next.data;

      const angleFormedByVertex = getAngleBetweenVectors(
        previousVertex.subtract(vertex),
        nextVertex.subtract(vertex)
      );

      const edge1Length = BABYLON.Vector3.Distance(vertex, previousVertex);
      const edge2Length = BABYLON.Vector3.Distance(vertex, nextVertex);

      // can be a little lenient with sharpnessThresholdAngle because of
      // the two length checks

      const possibleSharpEdge =
        (edge1Length < sharpnessThresholdDistance ||
          edge2Length < sharpnessThresholdDistance) &&
        (edge1Length / edge2Length < sharpnessEdgeLengthRatio ||
          edge2Length / edge1Length < sharpnessEdgeLengthRatio) &&
        (angleFormedByVertex < sharpnessThresholdAngle);

      if (possibleSharpEdge) verticesToRemove.push(vertex);

    });

    verticesToRemove.forEach((v) => _removeVertex(v));
  };

  const _collinearVerticesRemover = function () {
    const collinearVertices = [];

    const verticesDLL = new CircularLinkedList("vertices");
    topVertices.forEach((v) => verticesDLL.append(v));

    const util = new ResolveEngineUtils();
    verticesDLL.forEach((vNode) => {
      let vertex = vNode.data;
      let previousVertex = vNode.prev.data;
      let nextVertex = vNode.next.data;

      if (
        util.onSegment3D(
          previousVertex,
          vertex,
          nextVertex,
          collinearityThreshold
        )
      ) {
        collinearVertices.push(vertex);
      }
    });

    collinearVertices.forEach((v) => _removeVertex(v));
  };

  const _coarseSandpaper = function () {
    let vertexToRemove = null;
    topVertices.some((v1, i) => {
      let toBreak = false;
      topVertices.some((v2, j) => {
        if (j <= i) return;

        if (v1.almostEquals(v2, clusterDistance)) {
          vertexToRemove = v1;
          toBreak = true;
          return true;
        }
      });

      if (toBreak) return true;
    });

    if (vertexToRemove) {
      _removeVertex(vertexToRemove);
      _coarseSandpaper();
    }
  };

  if (!component.brep) return;

  const collinearityThreshold = 0.1;
  const clusterDistance = 0.5;

  const sharpnessThresholdAngle = 60;
  const sharpnessThresholdDistance = 5;
  const sharpnessEdgeLengthRatio = 5;

  const operatingSpace = BABYLON.Space.WORLD;
  const metadata = {};

  let topVertices = getTopFaceVertices(component, operatingSpace, metadata);

  try {
    _preciseSandpaper();
    _coarseSandpaper();
    _sharpEdgeRemover();
    if (removeCollinearVertices) _collinearVerticesRemover();
  } catch (e) {
    console.log(e);
  }
}

function isMeshNonSelectable(mesh) {
  return (
    (mesh.scaling.x === 0 && mesh.scaling.y === 0 && mesh.scaling.z === 0) ||
    mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.throwAwayMesh) ||
    mesh.type.includes(GLOBAL_CONSTANTS.strings.identifiers.cursor3D)
  );
}

function getCSGFormOfMesh(mesh) {
  if (mesh.isAnInstance) {
    let clone = mesh.sourceMesh.cleanClone("tempWallClone", null, true, false);
    clone.copyWorldMatrixProperties(mesh);
    let csg = BABYLON.CSG.FromMesh(clone);
    clone.dispose();
    return csg;
  } else {
    return BABYLON.CSG.FromMesh(mesh);
  }
}

function doesMeshHaveBrep(mesh) {
  try {
    return !!mesh.getSnaptrudeDS().brep;
  } catch (e) {
    return false;
  }
}

function doesComponentHaveBrep(c) {
  return !!c.brep;
}

function doPointsLieOnComponentPerimeter(points, component){
  const bottomFaceVertices = getBottomFaceVertices(component);

  let allPointsDo = true;
  points.every(point => {
    let thisPointDoes = false;

    bottomFaceVertices.some((v1, i) => {
      const v2 = bottomFaceVertices[(i + 1) % bottomFaceVertices.length];
      thisPointDoes = store.resolveEngineUtils.onSegment2D(v1, point, v2);

      return thisPointDoes;
    })

    allPointsDo = allPointsDo && thisPointDoes;
    return thisPointDoes;
  });

  return allPointsDo;

}

function doAllPointsLieOnSameComponentEdge(points, component){
  const bottomFaceVertices = getBottomFaceVertices(component);

  let theyDo = false;
  bottomFaceVertices.some((v1, i) => {
    const v2 = bottomFaceVertices[(i + 1) % bottomFaceVertices.length];
    theyDo = points.map(point => store.resolveEngineUtils.onSegment2D(v1, point, v2)).reduce((acc, value) => acc && value, true);

    return theyDo;
  })

  return theyDo;

}

function doesPointLieOnEdge(point, edge) {
  return areThreeVectorsCollinear(point, edge.headPt, edge.tailPt);
}

function focusBabylonGUIElement(element) {
  store.advancedTexture.moveFocusToControl(element);

  /* AG-RE: ANGULAR REFERENCE */
  // const allInputs = ScopeUtils.getScope().display_units;
  const allInputs = store.$scope.display_units;
  allInputs.forEach((input) => {
    input.inputFocus = input === element;
    // custom property used in displayOperations.js for some reason
  });

  DisplayOperation.focusOnCanvas();
}

function getSerializedFormOfMesh(mesh) {
  if (mesh.isAnInstance) {
    let sourceSerialized = getSerializedFormOfMesh(mesh.sourceMesh);
    sourceSerialized.meshes[0].position = mesh.getAbsolutePosition().asArray();
    sourceSerialized.meshes[0].rotation = mesh.rotation.asArray();
    sourceSerialized.meshes[0].scaling = mesh.scaling.asArray();

    return sourceSerialized;
  } else {
    return BABYLON.SceneSerializer.SerializeMesh(mesh);
  }
}

function checkOperationBIMFlowConditions(options) {

  // removing all restrictions
  return true;

  if (!store.BIMProject) {
    return true;
  } else {
    if (!options.mesh) return;

    const mesh = options.mesh;
    const component = mesh.getSnaptrudeDS();

    let meshType = mesh.type.toLowerCase();

    if (mesh.type !== GLOBAL_CONSTANTS.strings.identifiers.cadSketch) {
      if (meshType === "mass") {
        if (isMassDependant(component)) {
          meshType = "drawnMass";
          return true;
          //every operation is allowed on dependent drawn masses
          //If that changes, will have to include this below
        } else if (component.massType.toLowerCase() !== "room") {
          meshType = "nonRoomMass";
          return true;

          //every operation is allowed on nonRoom mass masses
          //If that changes, will have to include this below
        }
      }
    }

    switch (options.operation) {
      case "freeMoveEdit":
        switch (options.mode) {
          case "vertex":
            if (store.$scope.isTwoDimension) {
              if (["mass", "roof", "floor"].includes(meshType)) {
                return true;
              } else {
                return false;
              }
            } else {
              if (["roof"].includes(meshType)) {
                //roof vertex edit only along Y but that check cannot be done here,
                //it's in findPrioritizedSnapPoint
                return true;
              } else {
                return false;
              }
            }
          case "edge":
            if (store.$scope.isTwoDimension) {
              if (["mass", "wall", "floor", "roof"].includes(meshType)) {
                return true;
              }
            } else {
              if (["mass"].includes(meshType)) {
                let edgeVector = options.edge.headPt.subtract(
                  options.edge.tailPt
                );
                let angle = getAngleBetweenVectors(
                  edgeVector,
                  BABYLON.Vector3.Up()
                );

                let threshold = 2;
                if (
                  isFloatEqual(angle, 0, threshold) ||
                  isFloatEqual(angle, 180, threshold)
                ) {
                  //vertical edges
                  return true;
                } else {
                  return false;
                }
              } else if (["wall"].includes(meshType)) {
                return false;
              } else if (["roof"].includes(meshType)) {
                return true;
              } else {
                return false;
              }
            }
          case "face":
            if (store.$scope.isTwoDimension) {
              return false;
            } else {
              if (["mass", "floor"].includes(meshType)) {
                let normal = getNormalVector(options.face);
                if (normal)
                  normal = BABYLON.Vector3.FromArray(normal).normalize();

                let angle = getAngleBetweenVectors(
                  normal,
                  BABYLON.Vector3.Up()
                );

                let threshold = 2;
                if (
                  isFloatEqual(angle, 0, threshold) ||
                  isFloatEqual(angle, 180, threshold)
                ) {
                  //top face
                  return false;
                } else {
                  return true;
                }
              } else if (["wall", "roof"].includes(meshType)) {
                return true;
              } else {
                return false;
              }
            }

          default:
            return false;
        }

      case "finalChangesInXZ":
        if (["roof"].includes(meshType)) {
          return false;
        } else {
          return true;
        }

      case "freeMoveY":
        if (store.$scope.isTwoDimension) {
          return false;
        } else {
          if (["furniture", "door", "window", "void"].includes(meshType)) {
            return true;
          } else if (
            meshType.includes("mass") &&
            component.massType.toLowerCase().includes("room")
          ) {
            let roomType = ScopeUtils.getRoomType(mesh.room_type);

            if (roomType) roomType = roomType.toLowerCase();
            else return false;

            if (
              ["ground", "site", "road", "deck", "water body"].includes(
                roomType
              )
            ) {
              return true;
            }
            else return false;
          } else if (meshType.includes("roof")) {
            return true;
          } else if (mesh.name.includes("terrain")) {
            return true;
          } else {
            return false;
          }
        }

      case "freeMoveXZ":
        if (
          [
            "mass",
            "wall",
            "furniture",
            "door",
            "window",
            "void",
            "floorplan",
            "roof",
            "cadsketch",
            "staircase",
          ].includes(meshType)
        ) {
          return true;
        } else {
          return false;
        }
      case "extrude":
        // so only on drawn masses
        return false;

      case "scale":
        return false;

      case "array":
        if (["floor"].includes(meshType)) {
          if (store.selectionStack.length) {
            let wallInSelection = false;
            store.selectionStack.some((mesh) => {
              if (mesh.type) {
                if (mesh.type.toLowerCase() === "wall") {
                  wallInSelection = true;
                  return true;
                }
              }
            });

            return wallInSelection;
          } else {
            //array when they're selected alone is disabled
            return false;
          }
        } else {
          return true;
        }

      case "flip":
        let floorSelected = false;
        let wallSelected = false;
        let somethingElseSelected = false;

        store.selectionStack.some((mesh) => {
          let meshType = mesh.type ? mesh.type.toLowerCase() : null;
          if (["floor"].includes(meshType)) {
            floorSelected = true;
          } else if (["wall"].includes(meshType)) {
            wallSelected = true;
            // return true;
          } else {
            somethingElseSelected = true;
          }
        });

        if (wallSelected) {
          //all is well
          return true;
        } else if (floorSelected && !wallSelected) {
          return false;
        } else if (somethingElseSelected) {
          return true;
        }
      default:
        return false;
    }
  }
}

/**
 * Can also pass a single value in place of arrayOfTypesLowerCase
 *
 * @param mesh
 * @param arrayOfTypesLowerCase
 * @returns {boolean}
 */
function isMeshOfType(mesh, arrayOfTypesLowerCase) {
  if (!_.isArray(arrayOfTypesLowerCase))
    arrayOfTypesLowerCase = [arrayOfTypesLowerCase];

  const markAsThrowAway = isMeshThrowAway(mesh);
  removeMeshThrowAwayIdentifier(mesh);

  const meshTypeLowerCase = mesh.type.toLowerCase();

  const meshIsOfGivenType = arrayOfTypesLowerCase.includes(meshTypeLowerCase);

  if (markAsThrowAway && !isThrowAwayIdentifier(mesh.type)) {
    markMeshAsThrowAway(mesh);
  }

  return meshIsOfGivenType;
}

function isMeshCurved(mesh) {
  if (mesh.type === GLOBAL_CONSTANTS.strings.identifiers.cadSketch)
    return false;
  if (!mesh.getSnaptrudeDS()) return false;
  return !!mesh.getSnaptrudeDS().room_curve;
}

function isRoomOfType(mesh, type) {
  if (mesh.type.toLowerCase() === "mass" || mesh.type.toLowerCase() === "floor") {
    if (!mesh.room_type) return false;

    let roomSubTypes = ScopeUtils.getRoomSubTypes(type);
    return roomSubTypes
      .map((t) => t.toLowerCase())
      .includes(mesh.room_type.toLowerCase());
  } else {
    return false;
  }
}

function isRoomOfTypeBalcony(mesh) {
  return isRoomOfType(mesh, "balcony");
}

function isStoreyHidden(storeyValue) {
  if (!storeyValue) return false;

  const storeyCollection = StructureCollection.getInstance()
    .getStructures()
    [store.activeLayer.structure_id].getStoreyData();

  let storeyOfInterest = storeyCollection.getStoreyByValue(storeyValue);
  return storeyOfInterest.hidden;
}

function isMassDependant(mass) {
  return mass.dependantMass;
}

/**
 * Checks if a mesh can have its parent removed or not
 * @param mesh
 * @returns {boolean}
 */
function isMeshOrphanProof(mesh) {
  return ["door", "window", "void"].includes(mesh.type.toLowerCase());
}

function isTouchEvent(evt) {
  return evt.pointerType === "touch";
}

function updateButtonsOnTop(postCreateBuilding) {
  /* AG-RE: ANGLUAR REFERENCE */
  // console.warn("AG-RE: called updateButtonsOnTop(postCreateBuilding)");
  // let scope = store.angular.element(appElement).scope().$$childHead;

  // let type = "";
  // if (postCreateBuilding) type = "bim_building_position";
  // scope.sortTools(type);
}

function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

  var CSV = "";
  //Set Report title in first row or line

  CSV += ReportTitle + "\r\n\n";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var row = "";

    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ",";
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    var row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (var index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  var fileName = "MyReport_";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";
  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function removeInteriorComponent() {
  if (store.selectionStack.length > 0) {
    // removeMouseEvents();
    var iter = 0;
    while (iter < store.selectionStack.length) {
      var mesh = store.selectionStack[iter];
      let parentMesh = null;
      if (mesh.parent) {
        if (mesh.parent.type.toLowerCase() === "wall") {
          parentMesh = mesh.parent;
          parentMesh.childrenComp = parentMesh.childrenComp.filter(
            (child) => child.getSnaptrudeDS().id !== mesh.getSnaptrudeDS().id
          );
        }
      }

      if (parentMesh) {
        if (parentMesh.type.toLowerCase() === "wall") {
          if (
            ["door", "window", "furniture", "void"].includes(
              mesh.type.toLowerCase()
            )
          ) {
            let wallMesh = mesh.parent;
            if (wallMesh) {
              let originalWallMeshData =
                wallMesh.getSnaptrudeDS().originalWallMesh;
              if (originalWallMeshData) {
                let geom = getGeometryById(
                  originalWallMeshData["geometries"]["vertexData"],
                  originalWallMeshData["meshes"][0]["geometryId"]
                );
                let originalMesh = store.scene.recreateMesh(
                  originalWallMeshData["meshes"][0],
                  geom,
                  null
                );
                originalMesh.position.copyFrom(wallMesh.position);
                originalMesh.rotation.copyFrom(wallMesh.rotation);
                originalMesh.scaling.copyFrom(wallMesh.scaling);
                if (wallMesh.rotationQuaternion) {
                  originalMesh.rotationQuaternion =
                    new BABYLON.Quaternion.Zero();
                  originalMesh.rotationQuaternion.copyFrom(
                    wallMesh.rotationQuaternion
                  );
                }

                let children = wallMesh.childrenComp;
                let wallCSG = BABYLON.CSG.FromMesh(originalMesh);

                children.forEach(function (child) {
                  child.setParent(null);
                  child.computeWorldMatrix(true);
                });

                if (children.length > 0) {
                  children.forEach(function (childMesh) {
                    let childCSG = null;
                    let _selectionBox = null;
                    if (childMesh.type.toLowerCase() === "door") {
                      let prevPosition = jQuery.extend({}, childMesh.position);
                      let prevRotation = jQuery.extend({}, childMesh.rotation);
                      childMesh.position = new BABYLON.Vector3.Zero();
                      childMesh.rotation = new BABYLON.Vector3.Zero();
                      _selectionBox = doorOperation.createDoorSelectionBox(
                        childMesh,
                        wallMesh.getSnaptrudeDS().wThickness
                      );
                      _selectionBox.position = prevPosition;
                      _selectionBox.rotation = prevRotation;
                      childMesh.position = new BABYLON.Vector3(
                        prevPosition.x,
                        prevPosition.y,
                        prevPosition.z
                      );
                      childMesh.rotation = new BABYLON.Vector3(
                        prevRotation.x,
                        prevRotation.y,
                        prevRotation.z
                      );
                      childCSG = BABYLON.CSG.FromMesh(_selectionBox);
                    } else if (childMesh.type.toLowerCase() === "window") {
                      let prevPosition = jQuery.extend({}, childMesh.position);
                      let prevRotation = jQuery.extend({}, childMesh.rotation);
                      childMesh.position = new BABYLON.Vector3.Zero();
                      childMesh.rotation = new BABYLON.Vector3.Zero();
                      _selectionBox = windowOperation.createWindowSelectionBox(
                        childMesh,
                        wallMesh.getSnaptrudeDS().wThickness
                      );
                      _selectionBox.position = prevPosition;
                      _selectionBox.rotation = prevRotation;
                      childMesh.position = new BABYLON.Vector3(
                        prevPosition.x,
                        prevPosition.y,
                        prevPosition.z
                      );
                      childMesh.rotation = new BABYLON.Vector3(
                        prevRotation.x,
                        prevRotation.y,
                        prevRotation.z
                      );
                      childCSG = BABYLON.CSG.FromMesh(_selectionBox);
                    } else if (childMesh.type.toLowerCase() === "furniture") {
                      let prevPosition = jQuery.extend({}, childMesh.position);
                      let prevRotation = jQuery.extend({}, childMesh.rotation);
                      childMesh.position = new BABYLON.Vector3.Zero();
                      childMesh.rotation = new BABYLON.Vector3.Zero();
                      _selectionBox =
                        furnitureOperation.createFurnitureSelectionBox(
                          childMesh,
                          wallMesh
                        );
                      _selectionBox.position = prevPosition;
                      _selectionBox.rotation = prevRotation;
                      childMesh.position = new BABYLON.Vector3(
                        prevPosition.x,
                        prevPosition.y,
                        prevPosition.z
                      );
                      childMesh.rotation = new BABYLON.Vector3(
                        prevRotation.x,
                        prevRotation.y,
                        prevRotation.z
                      );
                      childCSG = BABYLON.CSG.FromMesh(_selectionBox);
                    } else if (childMesh.type.toLowerCase() === "void") {
                      childMesh.visibility = 0.01;
                      childCSG = BABYLON.CSG.FromMesh(childMesh);
                    } else if (childMesh.type.toLowerCase() === "mass") {
                      return;
                    }

                    wallCSG.subtractInPlace(childCSG);
                    if (_selectionBox) _selectionBox.dispose();
                  });
                }

                let newWall = wallCSG.toMesh("wall", null, store.scene);
                newWall.type = "wall";
                newWall.storey = wallMesh.storey;
                // newWall.setAbsolutePosition = new BABYLON.Vector3.Zero();

                let wall = new Wall(newWall, wallMesh.room_id);
                wall.assignProperties();

                wall.wThickness = wallMesh.getSnaptrudeDS().wThickness;
                wall.originalWallMesh =
                  wallMesh.getSnaptrudeDS().originalWallMesh;
                wall.neighbours = wallMesh.getSnaptrudeDS().neighbours;
                wall.neighboursDetails =
                  wallMesh.getSnaptrudeDS().neighboursDetails;
                wall.setTopCoords(wallMesh.getSnaptrudeDS().topCoords);
                wall.setBottomCoords(wallMesh.getSnaptrudeDS().bottomCoords);
                wall.setMidYHeight(wallMesh.getSnaptrudeDS().midY);
                let structures = StructureCollection.getInstance();
                wall.mesh.structure_id = wallMesh.structure_id;
                let structure_id = wallMesh.structure_id;
                let str = structures.getStructureById(wall.mesh.structure_id);
                let level = str.getLevelByUniqueId(
                  str.getObjectByUniqueId(wallMesh.uniqueId).level_id
                );
                level.addWallToLevel(wall, false);
                newWall.uniqueId = wallMesh.uniqueId;

                newWall.childrenComp = wallMesh.childrenComp;

                let wallPosition = Object.assign({}, wallMesh.position);
                newWall.position = new BABYLON.Vector3(
                  wallPosition.x,
                  wallPosition.y,
                  wallPosition.z
                );
                for (let i = 0; i < newWall.childrenComp.length; i++) {
                  newWall.childrenComp[i].setParent(newWall);
                }
                // wallMesh.getChildren().forEach(function (child) {
                //     child.parent = null;
                //     // child.parent = newWall;
                // });
                if (!wall.brep) {
                  copyMaterialData(wallMesh, newWall);
                }

                level.removeObjectToLevel(wallMesh.getSnaptrudeDS());
                originalMesh.dispose();
              }
              wallMesh.dispose();
            }
          }
          onSolid();
        }
      }
      if (mesh.children) {
        for (var i = 0; i < mesh.children.length; i++) {
          if (mesh.children[i]) mesh.children[i].dispose();
        }
      }
      let level = mesh.getSnaptrudeLevel();
      if (mesh.getSnaptrudeDS()) {
        level.removeObjectToLevel(mesh.getSnaptrudeDS());
      }
      mesh.dispose();
      iter++;
    }
    // var click1 = function (mesh) {
    //     if (mesh.state == "on" && (mesh.name.indexOf("boxScale") == -1) && (mesh.name.indexOf("axis") == -1) && (mesh.name.indexOf("ground") == -1)) {
    //         for ( let i = 0; i < mesh.children.length; i++) {
    //             mesh.children[i].dispose();
    //         }
    //         mesh.dispose();
    //     }
    // }
    // var count = 0;
    // while (count < store.newScene.meshes.length) {
    //     click1(newScene.meshes[count]);
    //     count++;
    // }

    DisplayOperation.removeDimensions();
    updateModifications();
    store.selectionStack = [];
    updateRoofAccordion(true);
    //cleanStoreysDS();
    // updateLevelsAngularUI(storeysDS);
  }
}

function removeAxisFromClip(data) {
  let xAxis = store.scene.getMeshByID("axisX");
  let yAxis = store.scene.getMeshByID("axisY");
  let zAxis = store.scene.getMeshByID("axisZ");

  xAxis.onBeforeRenderObservable.add(function () {
    store.scene.clipPlane = data;
  });
  yAxis.onBeforeRenderObservable.add(function () {
    store.scene.clipPlane = data;
  });
  zAxis.onBeforeRenderObservable.add(function () {
    store.scene.clipPlane = data;
  });
  xAxis.onAfterRenderObservable.add(function () {
    store.scene.clipPlane = null;
  });
  yAxis.onAfterRenderObservable.add(function () {
    store.scene.clipPlane = null;
  });
  zAxis.onAfterRenderObservable.add(function () {
    store.scene.clipPlane = null;
  });
  // return data;
}

function drawOutline(mesh) {
  var bbinfo = mesh.getBoundingInfo();
  // try{
  //     mesh.freezeWorldMatrix();
  // }
  // catch(e){
  //     console.log(e);
  // }
  // bbinfo.update(mesh._worldMatrix);
  let height = bbinfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
  let width = bbinfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
  let depth = bbinfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
  let center = bbinfo.boundingBox.centerWorld;
  // try{
  //     mesh.unfreezeWorldMatrix();
  // }
  // catch(e){
  //     console.log(e);
  // }

  let box = BABYLON.MeshBuilder.CreateBox(
    "ClipOutline",
    { height: height, width: width, depth: depth },
    store.scene
  );
  box.position = center;
  box.enableEdgesRendering();
  box.edgesWidth = 10.0;
  box.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
  box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

  // let mat = store.scene.getMaterialByName("bbMat");
  // let material;
  // if (mat) material = mat;
  // else{
  //     material = new BABYLON.StandardMaterial("bbMat", store.scene);
  //     material.diffuseColor = new BABYLON.Color3(0.3,0.5,1);
  //     material.alpha = 0.2;
  //     material.backFaceCulling = false;
  // }
  // box.material = material;
  //
  // if (mesh.children){
  //     mesh.children.push(box);
  // }
  // else{
  //     mesh.children = [];
  //     mesh.children.push(box);
  // }
  //
  //
  box.isPickable = false;
  //  box.parent = mesh;
  return box;
}

function rotationGizmoFromClip(selectedMesh, downclk, faceId) {
  if (downclk == 0) return;

  if (downclk == 1) {
    // store.scene.activeCamera.detachControl(canvas);
    let utilLayer = new BABYLON.UtilityLayerRenderer(store.scene);
    let endofDrag = false;

    let gizmo = new BABYLON.PlaneRotationGizmo(
      new BABYLON.Vector3(0, 1, 0),
      BABYLON.Color3.FromHexString("#00b894"),
      utilLayer
    );
    gizmo.attachedMesh = selectedMesh;

    // Updating using local rotation
    gizmo.updateGizmoRotationToMatchAttachedMesh = true;
    gizmo.updateGizmoPositionToMatchAttachedMesh = true;
    // let pointerRotateBehavior = new BABYLON.PointerDragBehavior();
    gizmo.dragBehavior.onDragEndObservable.add((event) => {
      console.log("rotation normals", store.scene.clipPlane.normal);

      let pickInfo1 = store.newScene.pick(
        store.newScene.pointerX,
        store.newScene.pointerY,
        function (mesh) {
          return mesh.name == "clippingPlane";
        }
      );
      //   let norm = getNormalsforFace(selectedMesh, faceId);
      let newNormals = pickInfo1.getNormal(true);
      newNormals.normalize();
      store.scene.clipPlane.normal = newNormals;
      gizmo.attachedMesh = !gizmo.attachedMesh ? selectedMesh : null;
      endofDrag = true;
    });
    //selectedMesh.addBehavior(pointerRotateBehavior);
    //  if (_.isEmpty(selectedMesh.behaviors)) selectedMesh.addBehavior(pointerRotateBehavior);
    if (endofDrag) {
      console.log("end of drag-->", endofDrag);
      // pointerRotateBehavior.enabled = false;
      //scene.activeCamera.detachControl(canvas);
    }
    // Toggle gizmo on keypress
  }
}
function attachMovementGizmo(selectedMesh, downclk) {
  let preVPos = selectedMesh.position.clone();
  if (downclk == 0) return;

  if (downclk == 1) {
    // store.scene.activeCamera.detachControl(canvas);
    var utilLayer = new BABYLON.UtilityLayerRenderer(store.scene);
    let gizmo = new BABYLON.AxisDragGizmo(
      store.scene.clipPlane.normal,
      BABYLON.Color3.FromHexString("#B80024"),
      utilLayer
    );
    gizmo.attachedMesh = selectedMesh;
    gizmo.updateGizmoRotationToMatchAttachedMesh = false;
    gizmo.updateGizmoPositionToMatchAttachedMesh = true;
    gizmo.dragBehavior.onDragEndObservable.add((event) => {
      let newD = store.scene.clipPlane.dotCoordinate(
        preVPos.subtract(selectedMesh.position)
      );
      preVPos = selectedMesh.clone();
      store.scene.clipPlane.d = newD;
      //   gizmo.attachedMesh = !gizmo.attachedMesh ? selectedMesh : null;
    });
  }
}

function createGroupBoundingBox() {
  //select all mesh
  let sizeData = null;
  const meshes = [];
  for (let j = 0; j < store.newScene.meshes.length; j++) {
    var mesh = store.newScene.meshes[j];
    if (
      nonDefaultMesh(mesh) &&
      mesh.name.indexOf("twoPlane") === -1 &&
      mesh.name.indexOf("sketchLine") === -1 && mesh.name.indexOf("terrain") === -1
    ) {
      if (mesh.scaling.x === 0 && mesh.scaling.y === 0 && mesh.scaling.z === 0)
        continue;
      //  mesh.state = "on";
      meshes.push(mesh);
    }
  }

  if (meshes.length >= 1) {
    let vec_arr = [];
    meshes.forEach(function (mesh) {
      let bbInfo = mesh.getBoundingInfo();
      vec_arr.push(bbInfo.boundingBox.maximumWorld);
      vec_arr.push(bbInfo.boundingBox.minimumWorld);
      //   mesh.children[0].dispose();
    });
    let x_min = _.minBy(vec_arr, "x");
    let y_min = _.minBy(vec_arr, "y");
    let z_min = _.minBy(vec_arr, "z");

    let x_max = _.maxBy(vec_arr, "x");
    let y_max = _.maxBy(vec_arr, "y");
    let z_max = _.maxBy(vec_arr, "z");

    let newBoundingBox = new BABYLON.BoundingInfo(
      new BABYLON.Vector3(x_min.x, y_min.y, z_min.z),
      new BABYLON.Vector3(x_max.x, y_max.y, z_max.z)
    );
    sizeData = {
      alongY: newBoundingBox.boundingBox.extendSizeWorld.y * 2 + 0.1,
      alongX: newBoundingBox.boundingBox.extendSizeWorld.x * 2 + 0.1,
      center: newBoundingBox.boundingBox.centerWorld,
      alongZ: newBoundingBox.boundingBox.extendSizeWorld.z * 2 + 0.1,
      radius: newBoundingBox.boundingSphere.radiusWorld,
    };
  }

  // store.selectionStack = [];
  return sizeData;
}

function createUUID() {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}


// var base64Arr = [];
// function zipFiles(dataUrl){
//     base64Arr.push(dataUrl);
// }

/**
 * Function to manipulate checkbox to set meshes to be
 * unique or not on storey duplication
 */
function setStoreyUnique(id) {
  let input = document.getElementById(id);
  let set = input.getAttribute("checked");

  if (!set) {
    input.setAttribute("checked", "checked");
    input.value = "on";
  } else {
    input.removeAttribute("checked");
    input.value = "off";
  }
}

function isTerrainMesh(mesh) {
  return mesh.name.includes("terrain");
}

function getLabelTypePosition(labelType) {
  if (["ground", "road", "site"].includes(labelType)) {
    let defaultPlinth =
      store.projectProperties.properties.plinthHeightProperty.getDimension(
        "VALUE_DEF",
        "mm"
      );
    let plinth =
      store.projectProperties.properties.plinthHeightProperty.getValue(
        "userUnit"
      );
    let value = GLOBAL_CONSTANTS.numbers.positions[labelType];
    return value - plinth + defaultPlinth;
  } else if ("deck" === labelType) {
    let value = GLOBAL_CONSTANTS.numbers.positions[labelType];
    return value;
  }
}

var extrafunc = (function () {
  // Searches for and returns a slab in the provided storey for which the
  // centroid of the wall lies within the polygon formed by the slab(in 2D)
  let checkWallInsideSlab = function (wallMesh, storeyForSlabs) {
    let point = [wallMesh.position.x, wallMesh.position.z];
    let structure =
      StructureCollection.getInstance().getStructures()[wallMesh.structure_id];
    let slabs = structure
      .getStoreyData()
      .getStoreyByValue(storeyForSlabs)
      .filterElements(["roof"]);
    let resolveEngine = ResolveEngine();
    let slab = _.find(slabs, (slab) => {
      let slabPos = slab.mesh.position;
      let polygon = slab.getRoofPolOffsetBottom().map((pol) => [
        pol[0] + slabPos.x,
        pol[2] + slabPos.z,
      ]);
      return resolveEngine.util.isPointInsidePolygon(point, polygon);
    });
    return slab;
  };
  return {
    checkWallInsideSlab,
  };
})();

const copyToClipboard = async (str) => {
  try {
    await navigator.clipboard.writeText(str);
    showToast("Copied", 3000, TOAST_TYPES.success)
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}


export {
  onNoEdge,
  onSolid,
  addMeshToStructure,
  removeMeshFromStructure,
  removeComponentFromStructure,
  changeTypeInStructure,
  onPan,
  onCamRot,
  onMatMode,
  enableGizmo,
  deSerializeMesh,
  updateCSG,
  isEqual,
  updateLevelsAngularUI,
  showVertexNormals,
  getRotationQuaternion,
  localiseThePointsForMeshGeneration,
  printFaceVertices,
  showNormals,
  disposeNormals,
  correctMeshNormals,
  updateSubMeshes,
  recalculateNormalsOfMassOrWall,
  invertNormals,
  invertNormalData,
  getEmptyFunction,
  resetFuncs,
  mmToSnaptrudeUnits,
  metresToSnaptrudeUnits,
  convertLocalCoordsToGlobal,
  convertGlobalCoordsToLocal,
  convertGlobalVector3ToLocal,
  convertLocalVector3ToGlobal,
  getIndicesOfPointInVerData,
  previousPositionX,
  previousPositionY,
  getMouseMovementSpeed,
  isPointInsideTheMesh,
  getComponentInTheVicinityOfThisComponent,
  areTwoMeshesCloseBy,
  isPointInTheVicinityOfMesh,
  isPointOnTheFace,
  isPointOnThePlane,
  isMeshThrowAway,
  isThrowAwayIdentifier,
  removeMeshThrowAwayIdentifier,
  removeThrowAwayIdentifier,
  markMeshAsThrowAway,
  areComponentsSiblings,
  areThreeVectorsCollinear,
  areThreeVectorsCollinearIn2D,
  areEdgesParallel,
  areVectorsParallel,
  areEdgesOverlapping,
  invertEdge,
  cloneEdge,
  areEdgesIntersecting,
  areTwoLinesCollinear,
  TOAST_TYPES,
  showToast,
  hideToast,
  updateToast,
  doManualSave,
  getUserSetting,
  setUserSetting,
  setUserSettingAndRecord,
  getPlansData,
  setScaleForFPAndMeshes,
  getBabylonPlane,
  getBabylonGUIElementByName,
  isPointerOverGUIElement,
  addTagToMesh,
  getAngleBetweenEdges,
  getFaceArea,
  getFacetArea,
  getVertexCloserToPoint,
  getVertexProximityInfo,
  getCentroidOfVertices,
  getCollinearVertices,
  removeCollinearVertices,
  getActiveStoreyHeight,
  getDistanceBetweenVectors,
  getRoomHeight,
  setMeshVisibility,
  changeHeightOfObjects,
  changeLabelOfRoom,
  getLabelChangeCommand,
  getRoomTypeProperties,
  getActiveStoreyObject,
  disposeMesh,
  disposeMeshById,
  returnArrayOfV3s,
  returnV3,
  getVector3FromArray,
  updateLevelInfo,
  deepCopyObject,
  setActiveLayerAndRecord,
  saveActiveLayerInformation,
  rubSandpaper,
  isMeshNonSelectable,
  getCSGFormOfMesh,
  doesMeshHaveBrep,
  doesComponentHaveBrep,
  doPointsLieOnComponentPerimeter,
  doAllPointsLieOnSameComponentEdge,
  doesPointLieOnEdge,
  focusBabylonGUIElement,
  getSerializedFormOfMesh,
  checkOperationBIMFlowConditions,
  isMeshOfType,
  isMeshCurved,
  isRoomOfType,
  isRoomOfTypeBalcony,
  isStoreyHidden,
  isMassDependant,
  isMeshOrphanProof,
  isTouchEvent,
  updateButtonsOnTop,
  JSONToCSVConvertor,
  removeInteriorComponent,
  removeAxisFromClip,
  drawOutline,
  rotationGizmoFromClip,
  attachMovementGizmo,
  createGroupBoundingBox,
  createUUID,
  setStoreyUnique,
  isTerrainMesh,
  getLabelTypePosition,
  extrafunc,
  getV3ProjectedOntoScreenSpace,
  getV2ProjectedOntoModelSpace,
  copyToClipboard,
  randomIntFromInterval,
  getBabylonVertices,
  getDistanceIn2DBetweenVectors,
  getVector2FromVector3,
  getDistanceBetweenVectorAndFace,
  getEdgesFromFaceVertices,
  setIsModifiedAndGetCommand,
  getCurrentUserRole,
  getPermissionsBasedOnRole
};
