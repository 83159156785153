import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as constants from "./constants";
import Button from "../../components/Button/index";
import Divider from "../../components/Divider/index";
import Input from "../../components/Input/index";
import { signUpStyles } from "./styles";
import { routes } from "../../routes/constants";
import backgroundImage from "../../assets/images/right-image.png";
import { PARAGRAPH_TEXT } from "./constants";
import Logo from "../../components/Logo";
import { colors } from "../../themes/constant";
import Paragraph from "../../components/Paragraph";
import AuthService from "../../services/auth.service";

const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

const MainWrapper = styled.div`
  height: 100%;
  font-family: ${(props) => props.theme.font};
  background-color: ${(props) => props.theme.colors.fullWhite};
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: 40% 100%;
  background-position: right;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  width: 30%;
`;

const CardContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fullWhite};
  box-shadow: 0.063rem 0.063rem 0.5rem 0.063rem ${colors.boxShadowgrey};
  border-radius: 0.313rem;
  margin: 1rem;
  padding: 0.5rem 1.44rem 1rem 1.44rem;
  flex: 1;
  margin: 0.75rem 4.5rem 0.75rem 4.5rem;
  max-width: 19rem;
  position: relative;
  left: 10%;
`;


const Header = styled.h4`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.primeBlack};
  font-size: ${(props) => props.theme.fontSizes.tiny};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;
const ButtonWrapper = styled.div`
  padding: 0.5rem 0 0.5rem 0;
`;
const ErrorText = styled.p`
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.mustard};
  font-size: 0.775rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
`


class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      reconfirmPassword: "",
      message: "",
      isLoading: false
    };
  }

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  handleSubmit = () => {
    this.setState({message: ""});
    if(this.state.newPassword !== this.state.reconfirmPassword){
      this.setState({message: "Passwords don't match"});
      return;
    }
    const token = this.props.match.params.token;
    const newPassword = this.state.newPassword;
    this.setState({isLoading: true})
    AuthService.resetPassword(token, newPassword)
    .then(data => {
      this.setState({
        message: data.message
      })
      if(data.status === "success"){
        setTimeout(() => {
          this.props.history.push(routes.login);
        }, 1000);
      }
      this.setState({isLoading: false})

    })
    .catch(err => {
      this.setState({isLoading: false})
      console.error(err);
    })
  }

  render() {
    const { history } = this.props;
    const { newPassword, reconfirmPassword } = this.state;
    const shouldForgotPasswordDisable =
      !newPassword || !reconfirmPassword || newPassword !== reconfirmPassword;
    return (
      <MainWrapper>
        <ContentContainer>
          <LeftContainer>
            <Logo />
            <Paragraph
              heading={PARAGRAPH_TEXT.heading}
              subHeading={PARAGRAPH_TEXT.subheading}
              body={PARAGRAPH_TEXT.body}
            />
            <div />
          </LeftContainer>
          <CardContainer>
            <Header>{constants.RESET_PASSWORD_CARD_HEADER}</Header>
            <ErrorText style={{ color: "#F2994A" }}>{this.state.message}</ErrorText>
            <Divider customStyle={signUpStyles.dividerStyle} />
            
            <Input
              value={newPassword}
              placeholder={constants.ENTER_NEW_PASSORD}
              type={"password"}
              onPress={(e) => this.setFormProperty("newPassword", e.target.value)}
            />
            <Input
              placeholder={constants.RECONFIRM_NEW_PASSWORD}
              type={"password"}
              value={reconfirmPassword}
              customInputContainerStyle={signUpStyles.inputWrapper}
              onPress={(e) =>
                this.setFormProperty("reconfirmPassword", e.target.value)
              }
            />
            <ButtonWrapper>
              <Button
                title={constants.CHANGE_PASSWORD}
                disabled={shouldForgotPasswordDisable}
                onPress={this.handleSubmit}
                customButtonStyle={
                  shouldForgotPasswordDisable
                    ? signUpStyles.signUpButtonDisabled
                    : signUpStyles.signUpButtonEnabled
                }
                isLoading={this.state.isLoading}
              />
            </ButtonWrapper>
        </CardContainer>
        </ContentContainer>
      </MainWrapper>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(ResetPassword);
