/*jshint esversion: 6 */
"use strict";
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { Factory } from "./meshFactory.module.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";
import { externalUtil } from "../externalUtil.js";
import { getVector3FromArray } from "../extrafunc.js";
import { createCustomMeshAccordingToNormal } from "../../libs/massModeling.js";

function straightwall(wallData, mass) {
  let walls = [];
  let factory = new Factory();
  wallData.forEach((wallPoints) => {
    var points = {
      innerCoords: wallPoints.innerCoords,
      outerCoords: wallPoints.outerCoords,
    };
    var wallMesh = factory.createWall("straight", points);
    if (!wallMesh) return;
    // sometimes points are collinear or coplanar, so no mesh
    
    var wall = new Wall(wallMesh, null, store.activeLayer.structure_id);

    try {
      if (wallPoints.bottomCoords) {
        wall.setBottomCoords(wallPoints.bottomCoords.map(v => v.asArray()));
        wall.setTopCoords(wallPoints.topCoords.map(v => v.asArray()));
      }
    } catch (e) {
      console.warn(e);
    }
    
    let wallTypes = { Ext: "External", Int: "Internal", Parapet: "Parapet" };
    wall.properties.wallType = wallTypes[wallPoints.wallType];
    
    wall.properties.wallMaterialType =
      store.projectProperties.properties[
        "wallTypeProperty" + wallPoints.wallType
      ].getValue();
    // if(wall.properties.wallType === "Parapet") wall.properties.wallMaterialType = "GLASS";
    // wallPoints.createdWall = wall;
    wallPoints.createdWallUniqueId = wall.mesh.uniqueId;
    wall.setMidYHeight(wallPoints.midY);
    wall.assignProperties();
    wall.faceId = wallPoints.face;
    walls.push(wall);

    
    wall.originalWallMesh = BABYLON.SceneSerializer.SerializeMesh(wallMesh);
    
    if (mass){
      const mesh = mass.mesh;
  
      wallMesh.structure_id = mesh.structure_id;
      wallMesh.storey = mesh.storey;
      
      wall.massId = mesh.uniqueId;
      wall.structure_id = mesh.structure_id;
      wall.room_id = mesh.room_unique_id;
    }
  });
  
  return walls;
}

/**
 * { Generates straight/normal walls }
 *
 * @class      StraightWall (name)
 * @param      {Object}  points  The points
 * @return     {Object}  { wall mesh }
 */
const StraightWallOld = function (points) {
  //innerCoords for wall by face and pol1 for resolved walls
  let pol1 = points.innerCoords || points.pol1;
  let pol2 = points.outerCoords || points.pol2;

  pol1 = pol1.map((point) => [point[0], point[2], point[1]]);
  pol2 = pol2.map((point) => [point[0], point[2], point[1]]);

  /*
    Temporary fix for resolved walls during wall by face.
    Since resolved walls, coordinates are down to up and not side to side.
    */

  let intersection1 = externalUtil.getPointOfIntersection(
    [
      getVector3FromArray(pol1[0]),
      getVector3FromArray(pol1[3]),
      getVector3FromArray(pol1[2]),
      getVector3FromArray(pol1[1]),
    ],
    { type: "vector-vector" }
  );

  let intersection2 = externalUtil.getPointOfIntersection(
    [
      getVector3FromArray(pol2[0]),
      getVector3FromArray(pol2[3]),
      getVector3FromArray(pol2[2]),
      getVector3FromArray(pol2[1]),
    ],
    { type: "vector-vector" }
  );

  if (intersection1) {
    let swapIndices = [0, 1];
    if (!intersection2) {
      if (
        store.resolveEngineUtils.areArraysAlmostEqual(pol1[0], pol2[0], 1e-4, {
          ignoreY: true,
        })
      ) {
        swapIndices = [2, 3];
      }
    }

    if (store.resolveEngineUtils.onSegment3D(pol1[0], intersection1, pol1[3])) {
      let temp = pol1[swapIndices[1]];
      pol1[swapIndices[1]] = pol1[swapIndices[0]];
      pol1[swapIndices[0]] = temp;
    }
  }

  if (intersection2) {
    let swapIndices = [0, 1];
    if (
      store.resolveEngineUtils.areArraysAlmostEqual(pol1[0], pol2[0], 1e-4, {
        ignoreY: true,
      })
    ) {
      swapIndices = [2, 3];
    }

    if (store.resolveEngineUtils.onSegment3D(pol2[0], intersection2, pol2[3])) {
      let temp = pol2[swapIndices[1]];
      pol2[swapIndices[1]] = pol2[swapIndices[0]];
      pol2[swapIndices[0]] = temp;
    }
  }

  // let wall_path1 = makePathByGrahamScanAlgo(pol1);
  // let wall_path1_vec = wall_path1.map(point => new BABYLON.Vector3(point[0], point[2], point[1]));
  let wall_path1_vec = pol1.map(
    (point) => new BABYLON.Vector3(point[0], point[1], point[2])
  );

  // let wall_path2 = makePathByGrahamScanAlgo(pol2);
  // let wall_path2_vec = wall_path2.map(point => new BABYLON.Vector3(point[0], point[2], point[1]));
  let wall_path2_vec = pol2.map(
    (point) => new BABYLON.Vector3(point[0], point[1], point[2])
  );

  wall_path1_vec.reverse();
  wall_path2_vec.reverse();

  // return createCustomMesh(wall_path1, null, wall_path2);
  return createCustomMeshAccordingToNormal({
    path_bottom_vec: wall_path1_vec,
    path_top_vec: wall_path2_vec,
  });
};

const StraightWall = function (points) {
  
  const innerCoords = points.innerCoords;
  const outerCoords = points.outerCoords;
  
  // const innerCoordsV3 = innerCoords.map(p => new BABYLON.Vector3(p[0], p[2], p[1]));
  // const outerCoordsV3 = outerCoords.map(p => new BABYLON.Vector3(p[0], p[2], p[1]));
  
  return createCustomMeshAccordingToNormal({
    path_bottom_vec: outerCoords,
    path_top_vec: innerCoords,
  });
};

export { straightwall, StraightWall };
