
export const shortcut = {
    "Space": {
        tool: "pointer",
        toolLocation: "top",
    },
    "KeyL": {
        tool: "draw",
        toolLocation: "top"
    },
    "KeyM": {
        tool: "move",
        toolLocation: "top"
    },
    "KeyP": {
        tool: "edit",
        toolIn3d: "pushpull",
        toolLocation: "top"
    },
    "KeyC": {
        tool: "copy",
        toolLocation: "top"
    },
    "KeyQ": {
        tool: "rotate",
        toolLocation: "top"
    },
    "KeyX": {
        tool: "flipX",
        toolLocation: "top"
    },
    "KeyZ": {
        tool: "flipXY",
        toolLocation: "top"
    },
    "KeyT": {
        tool: "scale",
        toolLocation: "top"
    },
    "KeyV": {
        tool: "screenshot",
        toolLocation: "top"
    },
    "KeyS": {
        tool: "graphical",
        toolLocation: "top",
        menu: "Views"
    },
    

    // RIGHT TOOLBAR
    "KeyB": {
        tool: "materials",
        toolLocation: "right"
    },
    "KeyI": {
        tool: "eyeDropperForMaterial",
        toolLocation: "right",
    },
    "KeyD": {
        tool: "doors",
        toolLocation: "right"
    },
    "KeyW": {
        tool: "windows",
        toolLocation: "right"
    },
    "KeyF": {
        tool: "furniture",
        toolLocation: "right"
    },

    // BOTTOM RIGHT
    "Shift + Period": {
        tool: "newView",
        toolLocation: "bottomRight"
    },

    // BOTTOM TOOLBAR
    "KeyH": {
        tool: "Bulb0",
        toolLocation: "bottom"
    },
    "KeyU": {
        tool: "Bulb",
        toolLocation: "bottom"
    },
    "Backslash": {
        tool: "ObjectIcon",
        toolLocation: "bottom"
    },
    "KeyO": {
        tool: "orbit",
        toolLocation: "bottom",
    },
    "Shift + KeyZ": {
        tool: "zoomExtents",
        toolLocation: "bottom"
    }
}


export const shortcutsOnAnalysisPage = {
    // TOP    
    "KeyS": {
        tool: "graphical",
        toolLocation: "top",
        menu: "Views"
    },
    "KeyT": {
        tool: "scale",
        toolLocation: "top"
    },

    // BOTTOM RIGHT
    "Shift + Period": {
        tool: "newView",
        toolLocation: "bottomRight"
    },

    // BOTTOM TOOLBAR
    "KeyH": {
        tool: "Bulb0",
        toolLocation: "bottom"
    },
    "KeyU": {
        tool: "Bulb",
        toolLocation: "bottom"
    },
    "Backslash": {
        tool: "ObjectIcon",
        toolLocation: "bottom"
    },
    "KeyO": {
        tool: "orbit",
        toolLocation: "bottom",
    },
    "Shift + KeyZ": {
        tool: "zoomExtents",
        toolLocation: "bottom"
    }
}