import { colors } from "../../../themes/constant";

export const ButtonStyles = {
  background: colors.transparent,
  width: "auto",
};

export const ButtonTextStyle = {
  color: colors.secondaryGrey,
};

export const DividerStyles = {
  marginLeft: 0,
};
