import { ORIGIN } from "./url.constants";
import axios from "axios";

const getJWT = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const refreshAccessToken = async () => {
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

  try{
    const resp = await axios.post(
      ORIGIN + "/refreshAccessToken/",
      {
        accessToken,
        refreshToken,
      }
    );
    if (resp.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(resp.data.accessToken));
      //TODO: refresh token to be updated everytime access token is updated
    } else if(resp.data.error === 2) {
      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
    }
    return getJWT();
  } catch(err){
    console.log('Error in updating token', err);
    // throw new Error('Could not update the token');
  }
};

export {
  getJWT,
  refreshAccessToken,
};
