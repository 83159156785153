import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { isMassDependant } from "../extrafunc.js";
import { AutoSave } from "../socket/autoSave.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";

const backwardCompatibilityChecker = (function () {
  const layersChecker = function (layers) {
    if (_.isObject(layers)) {
      layers = _.values(layers);
    }

    return layers;
  };

  const userSettingsTypeValidator = function (settings) {
    if (
      !settings.hasOwnProperty("unitsType") ||
      typeof settings.unitsType !== "number"
    ) {
      settings.unitsType = 1;
    }
    if (
      !settings.hasOwnProperty("tolerance") ||
      typeof settings.tolerance !== "number"
    ) {
      settings.tolerance = 2;
    }

    return settings;
  };

  const typeChangeAllowedPropertyPopulator = (function () {
    const commands = [];

    const _createCommand = function (component, allowChange) {
      const options = {
        componentKeys: ["typeChangeAllowed"],
      };

      const stack = [component.mesh];

      let commandData = commandUtils.propertyChangeOperations.getCommandData(
        stack,
        options
      );
      options.data = commandData;

      if (allowChange) {
        component.allowTypeChange();
      } else {
        component.disallowTypeChange();
      }

      commandData = commandUtils.propertyChangeOperations.getCommandData(
        stack,
        options
      );

      commands.push(
        commandUtils.propertyChangeOperations.getCommand(window.name, commandData)
      );
    };

    const handleComponent = function (component) {
      if (component.type.toLowerCase() === "mass") {
        /* eslint-disable */
        if (component.hasOwnProperty("typeChangeAllowed")) {
        } else {
        /* eslint-enable */
          let allowChange;
          if (isMassDependant(component)) {
            if (component.massType.toLowerCase() === "mass") {
              allowChange = true;
            } else {
              allowChange = false;
            }
          } else {
            if (component.massType.toLowerCase() === "room") {
              allowChange = true;
            } else {
              if (component.isEdited()) {
                allowChange = false;
              } else {
                allowChange = true;
              }
            }
          }

          _createCommand(component, allowChange);
        }
      } else if (component.type.toLowerCase() === "wall") {
        /* eslint-disable */
        if (component.hasOwnProperty("typeChangeAllowed")) {
        } else {
        /* eslint-enable */
          let allowChange;
          if (component.mesh.room_unique_id) {
            // wall from create building
            allowChange = false;
          } else {
            if (component.isEdited()) {
              allowChange = false;
            } else {
              allowChange = true;
            }
          }

          _createCommand(component, allowChange);
        }
      } else if (component.type.toLowerCase() === "roof") {
        /* eslint-disable */
        if (component.hasOwnProperty("typeChangeAllowed")) {
        } else {
        /* eslint-enable */
          _createCommand(component, false);
        }
      }
    };

    const register = function () {
      if (!_.isEmpty(commands)) {
        const saveData = commands.map((c) => c.getSaveData());
        AutoSave.directPublish(_.flatten(saveData));

        commands.length = 0;
      }
    };

    return {
      handleComponent,
      register,
    };
  })();

  const registerChanges = function () {
    typeChangeAllowedPropertyPopulator.register();
  };

  const validateVector3ToArray = function (vector) {
    if (!vector) return null;

    if (_.isArray(vector)) {
      return vector;
    } else if (vector.x) {
      return [vector.x, vector.y, vector.z];
    } else {
      return null;
    }
  };

  const sketchPointsToVector3 = function (sketchPoints) {
    if (!sketchPoints.points) sketchPoints.points = sketchPoints;

    let pointsArr = sketchPoints.points;
    if (pointsArr[0][0]._x) {
      pointsArr.forEach(function (points, index) {
        pointsArr[index] = points.map(
          (point) => new BABYLON.Vector3(point._x, point._y, point._z)
        );
      });
    } else if (pointsArr[0][0].x) {
      pointsArr.forEach(function (points, index) {
        pointsArr[index] = points.map(
          (point) => new BABYLON.Vector3(point.x, point.y, point.z)
        );
      });
    } else {
      pointsArr.forEach(function (points, index) {
        pointsArr[index] = points.map((point) =>
          BABYLON.Vector3.FromArray(point)
        );
      });
    }

    return sketchPoints;
  };

  const isComponentCorrupt = function (data) {
    const type = data.dsProps.type.toLowerCase();

    if (type === "wall") {
        return Wall.isCorrupt(data);
    }

    return false;
  };

  const doesGeometriesExistForFloorplan = function (plan){
    return (plan.mesh && plan.mesh.geometries);
  }

  return {
    layersChecker,
    userSettingsTypeValidator,
    typeChangeAllowedPropertyPopulator,
    registerChanges,
    validateVector3ToArray,
    sketchPointsToVector3,
    isComponentCorrupt,
    doesGeometriesExistForFloorplan
  };
})();
export { backwardCompatibilityChecker };
