import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { callMakeWall } from "./wall_generation.js";
import {
  assignWallNeighboursSync,
  refeshConnection,
} from "./wallRelationship.js";
import { onSolid } from "../modules/extrafunc.js";
import { getMeshMedianY } from "./mathFuncs.js";
import { generateNewFloorPath } from "./floorAndRoofHelper.js";
import { moveMeshInGroup } from "./wallMovement.js";
import {
  checkMeshLengthZero,
  getWallMeshLineSegment,
} from "./wall_generation_helper.js";
var moveMeshDir = null;
var moveMeshStartingPosition = null;
var moveMesh = null;
var onMoveMeshDown = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("ground1") === -1 &&
        mesh.name.indexOf("backwall") === -1 &&
        mesh.visibility > 0 &&
        mesh.type !== "floor"
      );
    }
  );
  var box = store.scene.getMeshByName("moveMeshBox");
  if (moveMeshDir) {
    moveMeshDir = null;
    if (moveMesh.type == "wall") {
      try {
        let newWalls = callMakeWall(moveMesh);
        assignWallNeighboursSync(newWalls);
      } catch (e) {
        console.error(e);
      }
      refeshConnection(moveMesh); // for refreshing neighbours connections
    }

    removeMoveMeshAxis();
    onSolid();

    if (moveMesh.type == "Mass" && moveMesh.midY) {
      let verData = moveMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
      let midY = getMeshMedianY(verData, moveMesh.getWorldMatrix());
      moveMesh.midY = midY;
    }
    //downloadWallPosToJosn();
    if (moveMesh.type === "wall") {
      try {
        generateNewFloorPath(moveMesh);
      } catch (e) {
        console.error("Floor generation failed");
      }
    }
  } else if (pickInfo.hit) {
    if (pickInfo.pickedMesh.name === "moveMeshaxisX") {
      // store.scene.getMeshByName("moveMeshaxisY").dispose();
      store.scene.getMeshByName("moveMeshaxisZ").dispose();
      moveMeshDir = "x";
      moveMeshStartingPosition = getScenePositionForMoveMesh(evt);
    } else if (pickInfo.pickedMesh.name === "moveMeshaxisY") {
      store.scene.getMeshByName("moveMeshaxisX").dispose();
      store.scene.getMeshByName("moveMeshaxisZ").dispose();
      moveMeshDir = "y";
      moveMeshStartingPosition = getScenePositionForMoveMesh(evt);
    } else if (pickInfo.pickedMesh.name === "moveMeshaxisZ") {
      store.scene.getMeshByName("moveMeshaxisX").dispose();
      //      store.scene.getMeshByName("moveMeshaxisY").dispose();
      moveMeshDir = "z";
      moveMeshStartingPosition = getScenePositionForMoveMesh(evt);
    } else {
      moveMeshDir = null;
      //console.log(pickInfo.pickedMesh);
      drawMoveMeshAxis(pickInfo.pickedMesh);
      moveMesh = pickInfo.pickedMesh;
    }
  } else if (box) {
    moveMeshDir = null;
    //moveMeshInGroup(moveMesh);
    drawMoveMeshAxis(box);
  } else {
    moveMeshDir = null;
    removeMoveMeshAxis();
    moveMesh = null;
    if (store.scene.getMeshByName("moveMeshBox"))
      store.scene.getMeshByName("moveMeshBox").dispose();
  }
};

var onMoveMeshUp = function (evt) {
  //refreshConnectionBefore(moveMesh);
  moveMeshInGroup(moveMesh);
  //updateModifications();
};

var onMoveMeshMove = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return (
        mesh.name.indexOf("ground1") === -1 &&
        mesh.name.indexOf("backwall") === -1 &&
        mesh.visibility > 0
      );
    }
  );
  if (moveMeshDir) {
    if (!moveMeshStartingPosition) {
      return;
    }
    var moveMeshCurrentPosition = getScenePositionForMoveMesh(evt);
    if (!moveMeshCurrentPosition) {
      return;
    }
    var diff = moveMeshStartingPosition.subtract(moveMeshCurrentPosition);
    moveMoveMesh(moveMesh, diff, moveMeshDir);
    moveMeshStartingPosition = moveMeshCurrentPosition;
  } else {
    /*else if (scene.getMeshByName("moveMeshaxisX") || store.scene.getMeshByName("moveMeshaxisY") || store.scene.getMeshByName("moveMeshaxisZ")) {
    }*/
    var box = store.scene.getMeshByName("moveMeshBox");
    if (box) box.dispose();
  }
};

function drawMoveMeshAxis(moveMesh) {
  removeMoveMeshAxis();
  moveMesh.refreshBoundingInfo();
  var bbinfo = moveMesh.getBoundingInfo();
  var size = bbinfo.diagonalLength * 2.5;
  var axisX = BABYLON.Mesh.CreateBox(
    "moveMeshaxisX",
    0.4,
    store.scene,
    false,
    BABYLON.Mesh.DOUBLESIDE
  );
  axisX.material = new BABYLON.StandardMaterial("axisXMat", store.scene);
  axisX.material.diffuseColor = new BABYLON.Color3(1.0, 0.0, 0.0);
  axisX.material.directIntensity = 5.0;
  axisX.material.backFaceCulling = false;
  axisX.material.environmentIntensity = 1.0;
  axisX.material.specularIntensity = 0.3;
  axisX.material.microSurface = 0.3;
  axisX.material.specularColor = new BABYLON.Color3(1.0, 0.0, 0.0);
  axisX.scaling.x = size;
  // axisX.parent = moveMesh;

  //console.log(moveMesh)
  if (moveMesh.name.toLowerCase() != "wall") {
    var axisY = BABYLON.Mesh.CreateBox(
      "moveMeshaxisY",
      0.4,
      store.scene,
      false,
      BABYLON.Mesh.DOUBLESIDE
    );
    axisY.material = new BABYLON.StandardMaterial("axisYMat", store.scene);
    axisY.material.diffuseColor = new BABYLON.Color3(0.0, 1.0, 0.0);
    axisY.material.directIntensity = 5.0;
    axisY.material.backFaceCulling = false;
    axisY.material.environmentIntensity = 1.0;
    axisY.material.specularIntensity = 0.3;
    axisY.material.microSurface = 0.3;
    axisY.material.specularColor = new BABYLON.Color3(0.0, 1.0, 0.0);
    axisY.scaling.y = size;
    // axisY.parent = moveMesh;
  }

  var axisZ = BABYLON.Mesh.CreateBox(
    "moveMeshaxisZ",
    0.4,
    store.scene,
    false,
    BABYLON.Mesh.DOUBLESIDE
  );
  axisZ.material = new BABYLON.StandardMaterial("axisZMat", store.scene);
  axisZ.material.diffuseColor = new BABYLON.Color3(0.0, 0.0, 1.0);
  axisZ.material.directIntensity = 5.0;
  axisZ.material.backFaceCulling = false;
  axisZ.material.environmentIntensity = 1.0;
  axisZ.material.specularIntensity = 0.3;
  axisZ.material.microSurface = 0.3;
  axisZ.material.specularColor = new BABYLON.Color3(0.0, 0.0, 1.0);
  axisZ.scaling.z = size;
  // axisZ.parent = moveMesh;

  // let worldMat = currMesh.getWorldMatrix();
  console.log(axisY);

  axisX.position = moveMesh.position;
  // axisX.position = moveMesh.getBoundingInfo().boundingBox.centerWorld;
  // if (axisY)
  //     axisY.position = moveMesh.getBoundingInfo().boundingBox.centerWorld;
  // axisZ.position = moveMesh.getBoundingInfo().boundingBox.centerWorld;
  axisZ.position = moveMesh.position;
  moveMeshAxismoveMeshventFunc(axisX);
  if (axisY) moveMeshAxismoveMeshventFunc(axisY);
  moveMeshAxismoveMeshventFunc(axisZ);
  // axisX.parent = moveMesh;
  // axisY.parent = moveMesh;
  // axisZ.parent = moveMesh;
}

function removeMoveMeshAxis(mesh) {
  var mesh1 = store.scene.getMeshByName("moveMeshaxisX");
  if (mesh1) {
    mesh1.dispose();
  }
  var mesh2 = store.scene.getMeshByName("moveMeshaxisY");
  if (mesh2) {
    mesh2.dispose();
  }
  var mesh3 = store.scene.getMeshByName("moveMeshaxisZ");
  if (mesh3) {
    mesh3.dispose();
  }
}

function moveMeshAxismoveMeshventFunc(mesh) {
  mesh.actionManager = new BABYLON.ActionManager(store.newScene);
  mesh.actionManager
    .registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          if (mesh.name == "moveMeshaxisX") {
            if (store.scene.getMeshByName("moveMeshaxisX"))
              store.scene.getMeshByName("moveMeshaxisX").visibility = 1.0;
            if (store.scene.getMeshByName("moveMeshaxisY"))
              store.scene.getMeshByName("moveMeshaxisY").visibility = 0.2;
            if (store.scene.getMeshByName("moveMeshaxisZ"))
              store.scene.getMeshByName("moveMeshaxisZ").visibility = 0.2;
          }
          if (mesh.name == "moveMeshaxisY") {
            if (store.scene.getMeshByName("moveMeshaxisX"))
              store.scene.getMeshByName("moveMeshaxisX").visibility = 0.2;
            if (store.scene.getMeshByName("moveMeshaxisY"))
              store.scene.getMeshByName("moveMeshaxisY").visibility = 1.0;
            if (store.scene.getMeshByName("moveMeshaxisZ"))
              store.scene.getMeshByName("moveMeshaxisZ").visibility = 0.2;
          }
          if (mesh.name == "moveMeshaxisZ") {
            if (store.scene.getMeshByName("moveMeshaxisX"))
              store.scene.getMeshByName("moveMeshaxisX").visibility = 0.2;
            if (store.scene.getMeshByName("moveMeshaxisY"))
              store.scene.getMeshByName("moveMeshaxisY").visibility = 0.2;
            if (store.scene.getMeshByName("moveMeshaxisZ"))
              store.scene.getMeshByName("moveMeshaxisZ").visibility = 1.0;
          }
        }
      )
    )
    .then(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function () {
          if (store.scene.getMeshByName("moveMeshaxisX"))
            store.scene.getMeshByName("moveMeshaxisX").visibility = 1.0;
          if (store.scene.getMeshByName("moveMeshaxisY"))
            store.scene.getMeshByName("moveMeshaxisY").visibility = 1.0;
          if (store.scene.getMeshByName("moveMeshaxisZ"))
            store.scene.getMeshByName("moveMeshaxisZ").visibility = 1.0;
        }
      )
    );
}

var getScenePositionForMoveMesh = function (evt) {
  if (store.scene.getMeshByName("backwall"))
    store.scene.getMeshByName("backwall").dispose();
  var wall = BABYLON.Mesh.CreatePlane("backwall", 10000.0, store.newScene);
  // wall.material = new BABYLON.StandardMaterial("wallMat", store.newScene);
  wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
  wall.visibility = 0.0;
  var pickinfo1 = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("ground1");
    }
  );
  var pickinfo2 = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh === store.scene.getMeshByName("backwall");
    }
  );
  if (pickinfo1.hit && pickinfo2.hit) {
    var pt = new BABYLON.Vector3(
      pickinfo1.pickedPoint.x,
      pickinfo2.pickedPoint.y,
      pickinfo1.pickedPoint.z
    );
    return pt;
  } else if (pickinfo2.hit) {
    return pickinfo2.pickedPoint;
  } else if (pickinfo1.hit) {
    return pickinfo1.pickedPoint;
  }
  return null;
};

function moveMoveMesh(box, diff, moveMeshDir) {
  // console.log("before", box.getPivotPoint());
  if (moveMeshDir === "x") box.position.x -= diff.x;
  if (moveMeshDir === "y") box.position.y -= diff.y;
  if (moveMeshDir === "z") box.position.z -= diff.z;
  // console.log("after", box.getPivotPoint());
  if (box.name.indexOf("boxScale") != -1) {
    box.parentMesh.position = box.position;
    if (moveMesh.parentMesh.name.toLowerCase() == "wall") {
      if (movePartnerMeshes(moveMesh.parentMesh, diff, moveMeshDir)) return;
    }
  }
  if (moveMesh.name.toLowerCase() == "wall") {
    if (movePartnerMeshes(box, diff, moveMeshDir)) return;
  }
}

function movePartnerMeshes(box, diff, moveMeshDir) {
  let neighbourMeshes = box.moveMeshObj;
  if (!neighbourMeshes) throw "Invalid relatoinship";
  let clkWiseMesh = neighbourMeshes.clkWiseMesh;
  let counterClkWiseMesh = neighbourMeshes.counterClkWiseMesh;

  for (let i = 0; i < clkWiseMesh.length; i++) {
    let mesh = clkWiseMesh[i].mesh;
    if (checkMeshLengthZero(mesh)) return true;
    let nearPointIndexes = clkWiseMesh[i].nearPointIndexes;

    let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    for (let i = 0; i < nearPointIndexes.length; i++) {
      if (moveMeshDir.toLowerCase() === "x") {
        verData[nearPointIndexes[i]] -= diff.x;
      }
      if (moveMeshDir.toLowerCase() === "z") {
        verData[nearPointIndexes[i] + 2] -= diff.z;
      }
    }
    mesh.geometry.setVerticesData(
      BABYLON.VertexBuffer.PositionKind,
      verData,
      true
    );
  }
  for (let i = 0; i < counterClkWiseMesh.length; i++) {
    let mesh = counterClkWiseMesh[i].mesh;
    if (checkMeshLengthZero(mesh)) return true;
    let nearPointIndexes = counterClkWiseMesh[i].nearPointIndexes;

    let verData = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    for (let i = 0; i < nearPointIndexes.length; i++) {
      if (moveMeshDir.toLowerCase() === "x") {
        verData[nearPointIndexes[i]] -= diff.x;
      }
      if (moveMeshDir.toLowerCase() === "z") {
        verData[nearPointIndexes[i] + 2] -= diff.z;
      }
    }
    mesh.geometry.setVerticesData(
      BABYLON.VertexBuffer.PositionKind,
      verData,
      true
    );
  }
  return false;
}

function downloadWallPosToJosn() {
  return new Promise(function (resolve, reject) {
    let walPosObj = [];
    for (
      let a = store.doublyLinkedList.item(0), i = 0;
      i < store.doublyLinkedList.size();
      a = store.doublyLinkedList.item(++i)
    ) {
      let meshLS = getWallMeshLineSegment(a.data);
      walPosObj.push({
        id: a.data.uniqueId,
        coords: meshLS,
        thickness: a.data._properties._thickness,
      });
    }

    console.log(JSON.stringify(walPosObj));
    resolve("resolved");
  });
}
export {
  moveMeshDir,
  moveMeshStartingPosition,
  moveMesh,
  onMoveMeshDown,
  onMoveMeshUp,
  onMoveMeshMove,
  drawMoveMeshAxis,
  removeMoveMeshAxis,
  moveMeshAxismoveMeshventFunc,
  getScenePositionForMoveMesh,
  moveMoveMesh,
  movePartnerMeshes,
  downloadWallPosToJosn,
};
