"use strict"
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { plainFloorParameters } from "../factoryTypes/floor.types.js";
import { Factory } from "../factoryTypes/meshFactory.module.js";
import { Floor } from "../snaptrudeDS/floor.ds.js";
import {
  getCSGFormOfMesh,
  getSerializedFormOfMesh,
  onSolid,
  removeMeshFromStructure,
} from "../extrafunc.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";
import { copyMaterialData } from "../../libs/mats.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { Door } from "../snaptrudeDS/doors.ds.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { drawDoor } from "../../libs/drawDoor2d.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { ComponentManager } from "../componentManager/componentManager.js";
import { StateMachine } from "../Classes/StateMachine.js";
import { nearestPointOnLine } from "../../libs/snapFuncs.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import {GLOBAL_CONSTANTS} from "../utilityFunctions/globalConstants";
import {scenePickController} from "../utilityFunctions/scenePickController";
import { updateCursor } from "../../../containers/editor/cursorHandler.js";
import { cursor } from "../../../themes/cursor.js";
import stackedWallHelper from "../stackedWalls/stackedWallHelper";
var doorOperation = (function () {
  "use strict";
  let _doorMeshName = null;
  let _door = null;
  let _selectionBox = null;
  let wallst1 = null;
  let wallst2 = null;
  let updateDoorOffset = null;
  let _newDoorInScene = false;

  let supportFloorBuilder = (function () {
    let path = [];

    return {
      computeBottomPath,
      generateSupportFloor,
      copyMaterial,
      getPath,
      reset,
    };

    function computeBottomPath(width, depth) {
      let vector;
      let height = plainFloorParameters.floorDepth;
      let scaleVector = new BABYLON.Vector3(width / 2, height / 2, depth / 2);
      let normal = new BABYLON.Vector3(0, -1, 0);

      let side1 = new BABYLON.Vector3(normal.y, normal.z, normal.x);
      let side2 = BABYLON.Vector3.Cross(normal, side1);

      vector = normal.subtract(side1).subtract(side2).multiply(scaleVector);
      path.push([vector.x, vector.z, vector.y]);
      vector = normal.subtract(side1).add(side2).multiply(scaleVector);
      path.push([vector.x, vector.z, vector.y]);
      vector = normal.add(side1).add(side2).multiply(scaleVector);
      path.push([vector.x, vector.z, vector.y]);
      vector = normal.add(side1).subtract(side2).multiply(scaleVector);
      path.push([vector.x, vector.z, vector.y]);
    }

    function generateSupportFloor(door) {
      if (!isPathEmpty()) {
        let factory = new Factory();

        let floor = new Floor(factory.createFloor("plain", path));
        let floorMesh = floor.mesh;

        floor.room_type = "Default";
        floor.structure_id = door.structure_id;
        floor.storey = door.storey;

        floorMesh.structure_id = door.structure_id;
        floorMesh.storey = door.storey;
        floor.assignProperties();

        updateWorldMatrix(floorMesh);

        return floor;
      }

      function updateWorldMatrix(mesh) {
        let height = plainFloorParameters.floorDepth;
        let angle = door.rotation.y;

        door.computeWorldMatrix(true);
        door.refreshBoundingInfo();
        let doorPosition = door.absolutePosition.clone();
        let doorMinY = door.getBoundingInfo().boundingBox.minimumWorld.y;

        let floorY = doorMinY - height / 2;
        mesh.position = new BABYLON.Vector3(
          doorPosition.x,
          floorY,
          doorPosition.z
        );
        mesh.rotation.y = angle;
        mesh.computeWorldMatrix(true);
      }
    }

    function copyMaterial(roomFloor, supportFloor) {
      supportFloor.mesh.material = roomFloor.mesh.material;
      let materialIndices = [];
      roomFloor.mesh.subMeshes.forEach(function (subMesh) {
        materialIndices.push(subMesh.materialIndex);
      });

      materialIndices = _.uniq(materialIndices);

      if (materialIndices.length === 1) {
        if (supportFloor.brep) {
          supportFloor.brep.getFaces().forEach((face) => {
            face.materialIndex = materialIndices[0];
          });
        }
        supportFloor.mesh.subMeshes.forEach(function (subMesh) {
          subMesh.materialIndex = materialIndices[0];
        });
      }
    }

    function isPathEmpty() {
      return path.length === 0;
    }

    function reset() {
      path.length = 0;
    }

    function getPath() {
      return path;
    }
  })();

  var _getNearestPoint = function (vw, point) {
    let pt1 = null;
    let pt2 = null;
    let pts = [];
    switch (point) {
      case "start":
        if (
          BABYLON.Vector3.Distance(vw[0], vw[4]) <
          BABYLON.Vector3.Distance(vw[0], vw[2])
        ) {
          pt1 = vw[3]
            .add(vw[6])
            .scale(0.5)
            .add(vw[0].add(vw[4]).scale(0.5))
            .scale(0.5);
          pt2 = vw[5]
            .add(vw[1])
            .scale(0.5)
            .add(vw[2].add(vw[7]).scale(0.5))
            .scale(0.5);
        } else {
          pt1 = vw[0]
            .add(vw[2])
            .scale(0.5)
            .add(vw[5].add(vw[3]).scale(0.5))
            .scale(0.5);
          pt2 = vw[7]
            .add(vw[4])
            .scale(0.5)
            .add(vw[1].add(vw[6]).scale(0.5))
            .scale(0.5);
        }
        pts = [pt1, pt2];
        break;
      case "end":
        if (
          BABYLON.Vector3.Distance(vw[0], vw[2]) <
          BABYLON.Vector3.Distance(vw[0], vw[4])
        ) {
          pt1 = vw[5]
            .add(vw[2])
            .scale(0.5)
            .add(vw[3].add(vw[0]).scale(0.5))
            .scale(0.5);
          pt2 = vw[1]
            .add(vw[7])
            .scale(0.5)
            .add(vw[6].add(vw[4]).scale(0.5))
            .scale(0.5);
        } else {
          pt1 = vw[3]
            .add(vw[0])
            .scale(0.5)
            .add(vw[6].add(vw[4]).scale(0.5))
            .scale(0.5);
          pt2 = vw[5]
            .add(vw[2])
            .scale(0.5)
            .add(vw[1].add(vw[7]).scale(0.5))
            .scale(0.5);
        }
        pts = [pt1, pt2];
        break;
    }

    return pts;
  };

  var _drawDoor = function (pickInfo, _doorInstance, _door) {
    try {
      if (_selectionBox) {
        let doorCSG = getCSGFormOfMesh(_selectionBox);

        if (pickInfo.pickedMesh.childrenComp.length === 0)
          pickInfo.pickedMesh.getSnaptrudeDS().originalWallMesh =
            getSerializedFormOfMesh(pickInfo.pickedMesh);

        let options = {
          preserveChildren: true,
        };

        let wallDeletionCommandData =
          commandUtils.deletionOperations.getCommandData(
            pickInfo.pickedMesh,
            null,
            options
          );

        let wallCSG = getCSGFormOfMesh(pickInfo.pickedMesh);
        let newCSG = wallCSG.subtract(doorCSG);

        let newWallMesh = newCSG.toMesh("wall", null, store.scene);
        newWallMesh.type = "wall";

        newWallMesh.position = pickInfo.pickedMesh.position;
        newWallMesh.storey = pickInfo.pickedMesh.storey;
        let wall = new Wall(newWallMesh, pickInfo.pickedMesh.room_id);
        wall.assignProperties();
        let oldWallDS = pickInfo.pickedMesh.getSnaptrudeDS();

        wall.wThickness = oldWallDS.wThickness;
        wall.localLineSegment = oldWallDS.localLineSegment;
        wall.originalWallMesh = oldWallDS.originalWallMesh;

        // Storing brep in originalWallMesh. Needed after deletion of fenestration
        if (oldWallDS.brep) {
          wall.originalWallMesh.brep = store.resurrect.stringify(
            oldWallDS.brep
          );
        }

        wall.neighbours = oldWallDS.neighbours;
        wall.neighboursDetails = oldWallDS.neighboursDetails;
        wall.setTopCoords(oldWallDS.topCoords);
        wall.setBottomCoords(oldWallDS.bottomCoords);
        wall.setMidYHeight(oldWallDS.midY);
        wall.direction = oldWallDS.direction;
        wall.bottomLineSegment = oldWallDS.bottomLineSegment;
        wall.room_id = oldWallDS.room_id;
        wall.profile = oldWallDS.profile;
        wall.topLineSegment = oldWallDS.topLineSegment;
        wall.properties = oldWallDS.properties;
        wall.revitMetaData = oldWallDS.revitMetaData;
        if (wall.revitMetaData)
          wall.revitMetaData.isModified = true;
        // wall.createdByCB = oldWallDS.createdByCB;

        if (!wall.brep) {
          copyMaterialData(pickInfo.pickedMesh, newWallMesh);
        }

        let structures = StructureCollection.getInstance();
        wall.mesh.structure_id = pickInfo.pickedMesh.structure_id;
        let structure_id = pickInfo.pickedMesh.structure_id;
        let str = structures.getStructureById(wall.mesh.structure_id);
        let level = str.getLevelByUniqueId(
          str.getObjectByUniqueId(pickInfo.pickedMesh.uniqueId).level_id
        );
        level.addWallToLevel(wall, false);

        newWallMesh.childrenComp = pickInfo.pickedMesh.childrenComp;
        newWallMesh.childrenComp.push(_doorInstance);
        _doorInstance.structure_id = structure_id;
        _doorInstance.storey = newWallMesh.storey;
        let _newDoor = new Door(_doorInstance);
        level.addDoorToLevel(_newDoor, false);
        newWallMesh.childrenComp.forEach(function (child) {
          child.setParent(newWallMesh);
        });

        // Draw support floor
        let floor = supportFloorBuilder.generateSupportFloor(_doorInstance);
        let roomFloor = wall.getAssociatedFloor();
        if (roomFloor) {
          supportFloorBuilder.copyMaterial(roomFloor, floor);
        }

        level.addFloorToLevel(floor, false);
        _doorInstance.childrenComp.push(floor.mesh);
        _doorInstance.addChild(floor.mesh);

        const stackedWallCommand = stackedWallHelper.update(pickInfo.pickedMesh, newWallMesh);

        let options1 = {
          postExecuteCallback: doorInstanceCreationPostExecution,
          preUnExecuteCallback: doorInstanceCreationPreUnExecution,
        };

        let linkedListData = StoreyMutation.replaceElementInDLL(
          oldWallDS,
          wall
        );
        let instanceCreationCommandData =
          commandUtils.creationOperations.getCommandData(_doorInstance);
        let doorInstanceCreationCmd =
          commandUtils.creationOperations.getCommand(
            "doorInstanceCreation",
            instanceCreationCommandData,
            options1
          );
        let wallCreationCommand =
          commandUtils.creationOperations.getCommandData(newWallMesh);
        let wallCreationCmd = commandUtils.creationOperations.getCommand(
          "wallWithHoleCreation",
          wallCreationCommand
        );
        let deletionCmd = commandUtils.deletionOperations.getCommand(
          "wallDeletion",
          wallDeletionCommandData,
          options
        );
        let floorCreationCommandData =
          commandUtils.creationOperations.getCommandData(floor.mesh);
        let floorCreationCmd = commandUtils.creationOperations.getCommand(
          "floorCreation",
          floorCreationCommandData
        );

        let dllCmd = null;
        if (linkedListData) {
          dllCmd = commandUtils.linkedListOperations.getCommand(
            "updateLinkedListCluster",
            linkedListData
          );
        }

        _door.scaling = new BABYLON.Vector3(0, 0, 0);
        _door.position = new BABYLON.Vector3(10000, 0, 10000);
        // _door.rotation = new BABYLON.Vector3(0, 0, 0);

        isCommandDataEmpty([wallDeletionCommandData, wallCreationCommand]);

        if (_newDoorInScene) {
          let creationCommandData =
            commandUtils.creationOperations.getCommandData(_door);
          let doorCreationCmd = commandUtils.creationOperations.getCommand(
            "doorCreation",
            creationCommandData
          );

          let allCommands = [
            wallCreationCmd,
            doorCreationCmd,
            doorInstanceCreationCmd,
            floorCreationCmd,
            deletionCmd,
            dllCmd,
          ];

          const yets = [
            false,
            false,
            false,
            false,
            true,
            false,
          ];

          allCommands = _.compact(allCommands);
          addStackedWallCommand(allCommands, yets, stackedWallCommand);
          CommandManager.execute(allCommands, yets);

          _newDoorInScene = false;
        } else {
          let allCommands = [
            wallCreationCmd,
            doorInstanceCreationCmd,
            floorCreationCmd,
            deletionCmd,
            dllCmd,
          ];

          const yets = [
            false,
            false,
            false,
            true,
            false,
          ];

          allCommands = _.compact(allCommands);
          addStackedWallCommand(allCommands, yets, stackedWallCommand);
          CommandManager.execute(allCommands, yets);
        }

        onSolid(newWallMesh);
        onSolid(_doorInstance);
      }
    } catch (err) {
      console.log("DOOR OPERATION FAILED", err);
      doorOperation._reset();
    }
  };

  function addStackedWallCommand (commands, yets, stackedWallCommand) {
    if (stackedWallCommand) {
      // should be in between wall create and wall delete commands
      commands.splice(1, 0, stackedWallCommand);
      yets.splice(1, 0, false);
    }
  }

  let setDoor2d = function (doorInstance) {
    if (doorInstance.getChildren().length !== 0) {
      doorInstance.getChildren().forEach((child) => {
        if (child.name.toLowerCase() !== "floor") {
          child.dispose();
        }
      });
    }
    let subType;
    if (doorInstance.isAnInstance) {
      subType = doorInstance.sourceMesh.getSnaptrudeDS().subType;
    } else {
      subType = doorInstance.getSnaptrudeDS().subType;
    }
    doorInstance.computeWorldMatrix(true);
    let doorWidth = doorInstance._boundingInfo.boundingBox.extendSize.x * doorInstance.absoluteScaling.x * 2;
    let doorThickness = doorInstance._boundingInfo.boundingBox.extendSize.z * doorInstance.absoluteScaling.z * 2;
    if (subType) {
      let {symbolData} = doorInstance.getSnaptrudeDS();
      if (symbolData) subType = "revitImport"
      let door2D = drawDoor.createDoor2D(subType, doorWidth, symbolData); // fetch door type from door mesh DS based on category
      if (door2D) {
        if (subType === "revitImport") {
          door2D.setParent(doorInstance);
          door2D.position = BABYLON.Vector3.Zero();
        } else {
          const doorRotation = doorInstance.rotation.y;
          doorInstance.rotation.y = 0;
          door2D.setParent(doorInstance);
          door2D.position = BABYLON.Vector3.Zero();
          const bBox = doorInstance.getBoundingInfo().boundingBox;
          door2D.position.x = bBox.minimum.x;
          door2D.position.y = bBox.minimum.y;
          door2D.position.z = -0.05;
          doorInstance.rotation.y = doorRotation;
        }
        door2D.isVisible = store.$scope.isTwoDimension;
        // door2D.visibility = (store.$scope.isTwoDimension) ? 1 : 0;
        door2D.type = GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator;
        scenePickController.add(door2D);
      }
    }
  };

  let doorInstanceCreationPostExecution = function () {
    this.data.forEach((dataPoint) => {
      let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
      if (mesh) {
        setDoor2d(mesh);
      }
    });
  };

  let doorInstanceCreationPreUnExecution = function () {
    this.data.forEach((dataPoint) => {
      let mesh = store.scene.getMeshByUniqueID(dataPoint.meshId);
      if (mesh) {
        mesh.getChildren().forEach((c) => c.dispose());
      }
    });
  };

  function loadDoorMesh(subType, tasks) {
    try {
      if (tasks.length > 0) {
        let mesh = tasks[0].loadedMeshes[0];
        mesh.name = tasks[0].name;
        mesh.type = "door";
        mesh.originalScaling = mesh.scaling.clone();
        ComponentManager.prepareLoadedMesh(mesh);
        mesh.getSnaptrudeDS().subType = subType;

        dispatchEvents.call(this, mesh);
        this.setMetadata();
      }
      ComponentManager.reset();
    } catch (err) {
      console.log(err);
      document.body.style.cursor = "default";
      ComponentManager.reset();
    }
  }

  function dispatchEvents(mesh) {
    document.body.style.cursor = "default";
    this.setDoorMeshName(mesh.name);
    store.canvas.addEventListener("pointerdown", this.onPointerDown, false);
    store.canvas.addEventListener("pointermove", this.onPointerMove, false);
    store.canvas.addEventListener("pointerup", this.onPointerUp, false);
    store.ACTIVE_EVENT = { event: "doorOperation" };
  }

  function setDoorMeshName(name) {
    _doorMeshName = name;
  }

  function clearEvents() {
    store.canvas.removeEventListener("pointerdown", this.onPointerDown, false);
    store.canvas.removeEventListener("pointerup", this.onPointerUp, false);
    store.canvas.removeEventListener("pointermove", this.onPointerMove, false);
    return this._reset();
  }

  function isCommandDataEmpty(commandData) {
    commandData.forEach((data) => {
      if (_.isEmpty(data)) {
        throw "Command Data Empty";
      }
    });
  }

  return {
    onPointerDown: function (evt) {
      if (StateMachine.EventValidation.isPointerDownWithMiddleButton(evt))
        return;
      if (store.isiPad) {
        // store.newScene.activeCamera.detachControl(canvas);
      }
      let pickInfo = store.newScene.pick(
        store.newScene.pointerX,
        store.newScene.pointerY,
        function (mesh) {
          return mesh.type.toLowerCase() === "wall" && mesh.isVisible;
        }
      );

      if (pickInfo.hit) {
        if (_door) {
          let doorName = _door.name + "Ins" + _door.instances.length;
          let _doorInstance = _door.createInstance(doorName);
          _doorInstance.setAbsolutePosition(_door.getAbsolutePosition());
          _doorInstance.type = "door";
          _drawDoor(pickInfo, _doorInstance, _door);
          setDoor2d(_doorInstance);
        }
      }
    },

    onPointerMove: function (evt) {
      try {
        if (
          StateMachine.EventValidation.isPointerMoveWhileMiddleButtonDown(evt)
        )
          return;
        let pickInfo = store.newScene.pick(
          store.newScene.pointerX,
          store.newScene.pointerY,
          function (mesh) {
            return mesh.type.toLowerCase() === "wall" && mesh.isVisible;
          }
        );

        if (pickInfo.hit) {
          updateCursor(cursor.placeObject);
          pickInfo.pickedMesh.computeWorldMatrix(true);
          let bbinfo1 = pickInfo.pickedMesh.getBoundingInfo();
          let pickedPoint = pickInfo.pickedPoint;
          let pickNormal = pickInfo.getNormal(true, false);
          let wm = pickInfo.pickedMesh.getWorldMatrix();
          let wmR = wm.getRotationMatrix();
          let normal = pickNormal.clone();

          let wallThickness = pickInfo.pickedMesh
            .getSnaptrudeDS()
            .calculateWidth();
          let pointSet = pickInfo.pickedMesh.getSnaptrudeDS().edgeMidPts();

          _door = store.newScene.getMeshByName(_doorMeshName);
          _door.visibility = 1;
          _door.isPickable = false;

          let _offset = wallThickness / 2;

          _door.scaling = new BABYLON.Vector3(
            _door.originalScaling.x,
            _door.originalScaling.y,
            _door.originalScaling.z
          );

          let wallAngle;
          if (Math.round(normal.y) === 1) {
            // let worldMatrix = pickInfo.pickedMesh.getSnaptrudeDS().getSupervisedWorldMatrix();
            // let wallTopEndPoints = pickInfo.pickedMesh.getSnaptrudeDS().getLocalLineSegment();
            // wallTopEndPoints = convertLocalCoordsToGlobal(wallTopEndPoints, worldMatrix)
            //     .map(point => BABYLON.Vector3.FromArray(point));

            let pickedPointPrecision = pickedPoint.clone();
            pickedPointPrecision.snaptrudeFunctions().roundOff(3);
            let snapPoint = nearestPointOnLine(
              pickedPointPrecision,
              pointSet[0],
              pointSet[1]
            );
            wallAngle = Wall.calculateAngleByMidLine(pointSet[0], pointSet[1]);

            _door.position.x = snapPoint.x;
            _door.position.z = snapPoint.z;
          } else {
            wallAngle = Wall.calculateAngleByFaceNormal(pickedPoint, normal);

            _door.position.x = pickedPoint.x;
            _door.position.z = pickedPoint.z;
            _door.position.x -= _offset * Math.sin(wallAngle);
            _door.position.z -= _offset * Math.cos(wallAngle);
          }

          _door.computeWorldMatrix(true);
          _door.refreshBoundingInfo();
          let bbinfo = _door.getBoundingInfo();
          _door.position.y =
            bbinfo1.boundingBox.minimumWorld.y +
            bbinfo.boundingBox.extendSizeWorld.y;
          _door.position.y += plainFloorParameters.floorDepth;

          // _door.rotation = BABYLON.Vector3.Zero();
          if (_selectionBox) _selectionBox.dispose();
          _door.rotation.y = 0;
          _door.computeWorldMatrix(true);
          _selectionBox = doorOperation.createDoorSelectionBox(
            _door,
            wallThickness
          );

          let _doorChildren = _door.getChildren();
          let _door2D;
          for (const child of _doorChildren) {
            if (child.type ===
                GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator) {
              child.isVisible = store.$scope.isTwoDimension;
              _door2D = child;
            }
          }

          if (!_door2D) {
            setDoor2d(_door);
          }

          _door.rotation.y = wallAngle;

          _selectionBox.scaling = _selectionBox.originalScaling.clone();
          _selectionBox.position = _door.position.clone();
          _selectionBox.position.y -= plainFloorParameters.floorDepth / 2;
          _selectionBox.rotation.y = wallAngle;

          // Initialize supportFloorBuilder
          supportFloorBuilder.reset();
          supportFloorBuilder.computeBottomPath(
            bbinfo.boundingBox.extendSizeWorld.x * 2,
            wallThickness
          );

          let vw = bbinfo1.boundingBox.vectorsWorld;
          let vw2 = bbinfo.boundingBox.vectorsWorld;
          let end = _door.position.clone();
          let st1 = pointSet[0];
          let st2 = pointSet[1];
          // let st1 = _getNearestPoint(vw, "start")[0];
          // let st2 = _getNearestPoint(vw, "start")[1];
          // let end1 = _getNearestPoint(vw2, "end")[0];
          // let end2 = _getNearestPoint(vw2, "end")[1];

          //doing it to get this in global cope to update on keypress event
          wallst1 = st1;
          wallst2 = st2;

          if (
            BABYLON.Vector3.Distance(st1, end) <=
            BABYLON.Vector3.Distance(st2, end)
          ) {
            //  (BABYLON.Vector3.Distance(st1, end1) <= BABYLON.Vector3.Distance(st1, end2)) ? end = end1 : end = end2;
            st1.y = end.y;
            updateDoorOffset = BABYLON.Vector3.Distance(_door.position, end);
            DisplayOperation.drawOnMove(st1, end);
            DisplayOperation.displayOnMove(
              BABYLON.Vector3.Distance(st1, end),
              "distBox",
              true
            );
          } else {
            // (BABYLON.Vector3.Distance(st2, end1) <= BABYLON.Vector3.Distance(st2, end2)) ? end = end1 : end = end2;
            st2.y = end.y;
            updateDoorOffset = BABYLON.Vector3.Distance(_door.position, end);
            DisplayOperation.drawOnMove(st2, end);
            DisplayOperation.displayOnMove(
              BABYLON.Vector3.Distance(st2, end),
              "distBox",
              true
            );
          }
        } else {
          updateCursor(cursor.cantPlace);
          if (_door) {
            _door.scaling = new BABYLON.Vector3(0, 0, 0);
            _door.position = new BABYLON.Vector3(10000, 0, 10000);
            // _door.rotation = new BABYLON.Vector3(0, 0, 0);
          }

          if (_selectionBox) {
            _selectionBox.dispose();
          }
        }
      } catch (err) {
        console.log("DOOR SNAP FAILED", err);
        if (_door) {
          _door.scaling = new BABYLON.Vector3(0, 0, 0);
          _door.position = new BABYLON.Vector3(10000, 0, 10000);
          // _door.rotation = new BABYLON.Vector3(0, 0, 0);
        }

        if (_selectionBox) {
          _selectionBox.dispose();
        }
      }
    },

    onPointerUp: function (evt) {},

    createDoorSelectionBox: function (door, thickness) {
      let bbInfo = door.getBoundingInfo();
      door.freezeWorldMatrix();
      bbInfo.update(door._worldMatrix);
      let height =
        bbInfo.boundingBox.extendSizeWorld.y * 2 +
        plainFloorParameters.floorDepth;
      let width = bbInfo.boundingBox.extendSizeWorld.x * 2;
      let depth = thickness + 0.2;
      door.unfreezeWorldMatrix();

      let box = BABYLON.MeshBuilder.CreateBox(
        "doorBoxScale",
        {
          height: height,
          width: width,
          depth: depth,
          updatable: true,
        },
        store.scene
      );

      box.enableEdgesRendering();
      box.edgesWidth = 8.0;
      box.edgesColor = new BABYLON.Color4(0, 1, 0, 1);
      box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

      let mat = store.scene.getMaterialByName("doorBBMat");
      if (!mat) {
        mat = new BABYLON.StandardMaterial("doorBBMat", store.scene);
        mat.diffuseColor = new BABYLON.Color3(0.3, 1, 0.5);
        mat.alpha = 0.2;
        mat.backFaceCulling = false;
      }
      box.material = mat;
      box.isPickable = false;

      box.originalScaling = box.scaling.clone();
      return box;
    },

    draw2d: function (instanceObj) {
      setDoor2d(instanceObj);
    },

    updateDoorPosition: function (distance) {
      if (_selectionBox) {
        let dist = DisplayOperation.getOriginalDimension(distance);

        if (
          BABYLON.Vector3.Distance(wallst1, _door.position) <=
          BABYLON.Vector3.Distance(wallst2, _door.position)
        ) {
          let pd = wallst1.subtract(_door.position).normalize();
          let p = wallst1.subtract(pd.scale(dist + updateDoorOffset));
          let endpt = wallst1.subtract(pd.scale(dist));
          // console.log(p, updateDoorOffset);
          _door.position = p;
          _selectionBox.position = p;
          wallst1.y = _door.position.y;
          let vw = _door.getBoundingInfo().boundingBox.vectorsWorld;
          DisplayOperation.drawOnMove(wallst1, endpt);
          DisplayOperation.displayOnMove(
            BABYLON.Vector3.Distance(wallst1, endpt)
          );
        } else {
          let pd = wallst2.subtract(_door.position).normalize();
          let p = wallst2.subtract(pd.scale(dist + updateDoorOffset));
          let endpt = wallst2.subtract(pd.scale(dist));
          // console.log(p, updateDoorOffset);
          _door.position = p;
          _selectionBox.position = p;
          wallst2.y = _door.position.y;
          //let vw = _door.getBoundingInfo().boundingBox.vectorsWorld;
          DisplayOperation.drawOnMove(wallst2, endpt);
          DisplayOperation.displayOnMove(
            BABYLON.Vector3.Distance(wallst2, endpt)
          );
        }
        let pickInfo = store.newScene.pick(
          store.newScene.pointerX,
          store.newScene.pointerY,
          function (mesh) {
            return mesh.type.toLowerCase() === "wall" && mesh.isVisible;
          }
        );
        let doorName = _door.name + "Ins" + _door.instances.length;
        let _doorInstance = _door.createInstance(doorName);
        _doorInstance.setAbsolutePosition(_door.getAbsolutePosition());
        _doorInstance.type = "door";
        _drawDoor(pickInfo, _doorInstance, _door);
        setDoor2d(_doorInstance);
      }
    },

    _reset: function () {
      // if (isiPad) store.newScene.activeCamera.attachControl(canvas, true, false);
      let isWorking = false;
      if (_newDoorInScene) {
        let redundantDoor = store.scene.getMeshByName(_doorMeshName);
        if (redundantDoor) {
          removeMeshFromStructure(redundantDoor);
          redundantDoor.dispose();
        }
        _door = null;
        if (_selectionBox) _selectionBox.dispose();
        _selectionBox = null;
        // isWorking = true;
      }
      if (_door) {
        _door.scaling = new BABYLON.Vector3(0, 0, 0);
        _door.position = new BABYLON.Vector3(10000, 0, 10000);
        // _door.rotation = new BABYLON.Vector3(0, 0, 0);
        if (_selectionBox) _selectionBox.dispose();
        _selectionBox = null;
        _door = null;
        // isWorking = true;
      }
      _doorMeshName = null;
      doorOperation.doorMeshName = null;
      _newDoorInScene = false;
      return isWorking;
    },

    doorMeshName: _doorMeshName,

    dispatchEvents,

    loadDoorMesh,

    setDoorMeshName,

    setMetadata: function () {
      _newDoorInScene = true;
    },

    setDoor2d,

    clearEvents,

    supportFloorBuilder,

    util: {
      addStackedWallCommand,
    }
  };
})();
export { doorOperation };
