import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ActionDropdown from '../../../components/ActionDropdown';
import NamedAvatar from '../../../components/Avatar/NamedAvatar';
import Toggle from '../../../components/Toggle';
import authService from '../../../services/auth.service';
import { getTeamPreferences, updateTeamMemberSetting } from '../../../services/team.service';
import { showToast, TOAST_TYPES } from '../../../snaptrude/modules/extrafunc';
import { TEAM_ROLES } from '../TeamDashboard/constants';

const ALLOWED_ROLES = [TEAM_ROLES.ADMIN, TEAM_ROLES.EDITOR, TEAM_ROLES.CREATOR];
const LIMIT = 4;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.333333px;

  color: #575758;
  table, th, td{
    padding: 0.5em;
  }
  table{
    width: 100%;
    text-align: left;
  }
  thead{
    position: sticky;
    top: 0px;
    z-index: 5;
    background-color: #fcfcfc;
  }
  th{
    border-bottom: 1px solid #ddd;
    padding-bottom: 1em;
  }
  .actions-wrapper{
    width: 10em;
  }
  .noneditable{
    pointer-events: none;
  }
`

function Team({selectedTeam}) {
  const [teamMembers, setTeamMembers] = useState([]);
  const currentUser = authService.getCurrentUser();
  useEffect(() => {
    if(selectedTeam){
      setTeamMembers(selectedTeam.members || [])
    }
  }, [selectedTeam]);

  const handleRemoveTeamMember = async (teamMember, index) => {
    const currTr = document.getElementById(`tr-${teamMember.team_member_id}`);
    if(currTr){
        currTr.style.opacity = 0.25;
        currTr.style.pointerEvents = "none";
    }
    const resp = await updateTeamMemberSetting(selectedTeam.id, teamMember.team_member_id, {delete: true})
    if(resp.status !== "success"){
        showToast("Could not remove the member", 3000, TOAST_TYPES.error);
        if(currTr){
          currTr.style.opacity = 0.25;
          currTr.style.pointerEvents = "none";
      }
    }
  }

  const allowTeamRoleUpdate = (newrole) => {
    if(newrole === TEAM_ROLES.VIEWER) return true;
    const NON_VIEWERS_COUNT = teamMembers.reduce((count, member) => {
      if(member.role.toLowerCase() !== TEAM_ROLES.VIEWER) return count + 1;
      return count;
    }, 0);
    if(NON_VIEWERS_COUNT < LIMIT) return true;
    showToast("Cannot update, you have reached the maximum team members limit.");
    return false;
  }

  const handleSettingUpdate = async (teamMember, newSetting) => {
    const {key, value, setting} = newSetting;
    // if(key === "role") {
    //   if(!allowTeamRoleUpdate(value)) return;
    // } 
    if(_.get(teamMember, key) === value) return;
    const currTr = document.getElementById(`tr-${teamMember.team_member_id}`);
    if(currTr){
        currTr.style.opacity = 0.25;
        currTr.style.pointerEvents = "none";
    }
    const resp = await updateTeamMemberSetting(selectedTeam.id, teamMember.team_member_id, setting)
    if(resp.status === "success"){
      // console.log(teamMembers.find(member => member.team_member_id === teamMember.team_member_id));
      const newTeamMemberList = teamMembers.map(member => {
        if(member.team_member_id === teamMember.team_member_id){
          _.set(member, key, value);
        }
        return {
          ...member
        }
      })
      // setTeamMembers(newTeamMemberList);
    }else{
        showToast("Could not update setting.", 3000, TOAST_TYPES.error);
    }
    if(currTr){
      currTr.style.opacity = 1;
      currTr.style.pointerEvents = "auto";
    }
  }
  
  if(!selectedTeam) return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {  
                currentUser.name &&
                  <>
                    <NamedAvatar id={currentUser.name} text={currentUser.name} />
                    {currentUser.name}
                  </>
                }
              </div>
            </td>
            <td>
              {currentUser.email}
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  )
  return ( 
    <Wrapper>
      <table style={{width: "80%"}}>
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th style={{textAlign: "center"}}>Role</th>
                {/* <th style={{textAlign: "center"}}>Invite to </th>
                <th style={{textAlign: "center"}}>Share</th> */}
            </tr>
        </thead>
        <tbody>
        {
        teamMembers.filter(teamMember => teamMember.status !== "left").map((teamMember, index) => {
            let role = teamMember.role.split(".").pop().toLowerCase();
            return <tr key={`${teamMember.email}-${index}`} id={`tr-${teamMember.team_member_id}`} className={`${role === "admin" ? "noneditable" : ""}`} >
            <td>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {teamMember.status === "invited" ?
                  <span style={{color: "#27AE60"}}>Invited</span>
                :
                teamMember.user.name &&
                  <>
                    <NamedAvatar id={teamMember.user.name} text={teamMember.user.name} />
                    {teamMember.user.name}
                  </>
                }
              </div>
            </td>
            <td>
              {teamMember.email}              
            </td>
            <td style={{textAlign: "center", textTransform: "capitalize"}}>
                {
                    ALLOWED_ROLES.includes(selectedTeam.role) && role !== 'admin' ? 
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {role}
                        <ActionDropdown 
                          actions={[
                            ..._.keys(selectedTeam.roleBasedPermissions).map(role => {
                              return {title: role, handleClick: () => {handleSettingUpdate(teamMember, {key: 'role', value: role, setting:{ role: role}}, index)}}
                            }),
                            {title: "Remove", handleClick: () => {handleRemoveTeamMember(teamMember, index)}},
                          ]}
                        />
                    </div>
                        // <Select options={["Viewer", "Editor", "Creator", "Remove"]} selectedOption={role==="admin" ? "Admin" : "Viewer"} />
                        :
                        role
                }
            </td>
            {/* <td style={{textAlign: "center"}}>
              <Toggle 
                state={teamMember.preference.invite_to_team} 
                handleClick={() => {
                  const newSetting = {
                    key: 'preference.invite_to_team', 
                    value: !teamMember.preference.invite_to_team, 
                    setting:{ invitetoteam: !teamMember.preference.invite_to_team}
                  }
                  handleSettingUpdate(teamMember, newSetting);
                }}
                unEditable={!ALLOWED_ROLES.includes(selectedTeam.role)} 
              />
            </td>
            <td style={{textAlign: "center"}}>
              <Toggle 
                state={teamMember.preference.share} 
                handleClick={() => {
                  const newSetting = {
                    key: 'preference.share', 
                    value: !teamMember.preference.share, 
                    setting:{ share: !teamMember.preference.share}
                  }
                  handleSettingUpdate(teamMember,  newSetting);
                }} 
                unEditable={!ALLOWED_ROLES.includes(selectedTeam.role)} 
              />
            </td> */}
            </tr>
        })
        }
        </tbody>
      </table>
    </Wrapper>
   );
}

export default Team;