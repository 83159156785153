import React from "react";
import GenericModal from "./index";
import image from "../../assets/icons/image.svg";
import objectImage from "../../assets/icons/objectImage.svg";
import autoCad from "../../assets/icons/autoCad.svg";

export const UpdateMaterialModal = (props) => {
  return (
    <GenericModal
      modalType={"IMPORT WITH DROPDOWN"}
      onClose={props.onClose}
      isOpen={props.isOpen}
      modalHeaderText={"Update Material"}
      customProps={{
        header: "Select sketch image and upload",
        images: [
          {
            src: image,
            extension: ".jpg",
          },
          {
            src: image,
            extension: ".png",
          },
          {
            src: image,
            extension: ".bmp",
          },
        ],
        rightTitle: "Upload",
        leftTitle: "Back to material edits",
        dropdownList: [
          { label: "Type", options: ["Concrete", "Glass", "Matte"] },
        ],
      }}
    />
  );
};

export const ImportMaterialModal = (props) => {
  return (
    <GenericModal
      modalType={"IMPORT WITH DROPDOWN"}
      onClose={props.onClose}
      isOpen={props.isOpen}
      modalHeaderText={"Import Material"}
      customProps={{
        header: "Select sketch image and upload",
        images: [
          {
            src: image,
            extension: ".jpg",
          },
          {
            src: image,
            extension: ".png",
          },
          {
            src: image,
            extension: ".bmp",
          },
        ],
        rightTitle: "Upload",
        leftTitle: "Back to materials",
        dropdownList: [
          { label: "Type", options: ["Concrete", "Glass", "Matte"] },
        ],
      }}
    />
  );
};

export const ImportDoorModal = (props) => {
  return (
    <GenericModal
      modalType={"IMPORT WITH DROPDOWN"}
      onClose={props.onClose}
      isOpen={props.isOpen}
      modalHeaderText={"Import Door"}
      customProps={{
        header: "Select and upload 3D models in .zip format.",
        subHeader: "You can upload upto 10 files",
        headerFont: "12px",
        images: [
          {
            src: objectImage,
            extension: ".skp",
          },
          {
            src: objectImage,
            extension: ".3ds",
          },
          {
            src: objectImage,
            extension: ".obj",
          },
        ],
        rightTitle: "Upload",
        leftTitle: "Back to doors",
        dropdownList: [
          { label: "Type", options: ["Concrete", "Glass", "Matte"] },
          {
            label: "Unit",
            options: ["Swing-door single Shutter", "Swing-door double Shutter"],
          },
        ],
      }}
    />
  );
};

export const ImportWindowModal = (props) => {
  return (
    <GenericModal
      modalType={"IMPORT WITH DROPDOWN"}
      onClose={props.onClose}
      isOpen={props.isOpen}
      modalHeaderText={"Import Window"}
      customProps={{
        header: "Select and upload 3D models in .zip format.",
        subHeader: "You can upload upto 10 files",
        headerFont: "12px",
        images: [
          {
            src: objectImage,
            extension: ".skp",
          },
          {
            src: objectImage,
            extension: ".3ds",
          },
          {
            src: objectImage,
            extension: ".obj",
          },
        ],
        rightTitle: "Upload",
        leftTitle: "Back to windows",
        dropdownList: [
          {
            label: "Type",
            options: ["Swing-door single Shutter", "Swing-door double Shutter"],
          },
          {
            label: "Unit",
            options: ["Millimeter", "Meter", "Centimeter"],
          },
        ],
      }}
    />
  );
};

export const ImportFurnitureModal = (props) => {
  return (
    <GenericModal
      modalType={"IMPORT WITH DROPDOWN"}
      onClose={props.onClose}
      isOpen={props.isOpen}
      modalHeaderText={"Import Furniture"}
      customProps={{
        header: "Select and upload 3D models in .zip format.",
        subHeader: "You can upload upto 10 files",
        headerFont: "12px",
        images: [
          {
            src: objectImage,
            extension: ".skp",
          },
          {
            src: objectImage,
            extension: ".3ds",
          },
          {
            src: objectImage,
            extension: ".obj",
          },
        ],
        rightTitle: "Upload",
        leftTitle: "Back to furniture",
        dropdownList: [
          {
            label: "Type",
            options: ["Landscape", "Potrait"],
          },
          {
            label: "Unit",
            options: ["Millimeter", "Meter", "Centimeter"],
          },
        ],
      }}
    />
  );
};

export const UpdateCadDrawing = (props) => {
  return (
    <GenericModal
      modalType={"IMPORT WITH DROPDOWN"}
      onClose={props.onClose}
      isOpen={props.isOpen}
      modalHeaderText={"Upload Cad Drawing"}
      customProps={{
        header: "Select AutoCAD drawing and upload",
        images: [
          {
            src: autoCad,
            extension: ".dwg",
          },
        ],
        rightTitle: "Upload",
        leftTitle: "Back",
      }}
    />
  );
};
