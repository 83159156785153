import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js"
const keypadController = (function () {
  const CONSTANTS = {
    keypadIdentifier: "Keypad",
  };

  let _keypad = null;

  function showKeypad(input, callback) {
    // if (getBabylonGUIElementByName(input.name + CONSTANTS.keypadIdentifier)) return;
    if (_keypad) {
      linkKeypadToInput(input);
      return;
    }

    _keypad = new BABYLON.GUI.VirtualKeyboard(
      input.name + CONSTANTS.keypadIdentifier
    );
    _keypad.addKeysRow(["7", "8", "9"]);
    _keypad.addKeysRow(["4", "5", "6"]);
    _keypad.addKeysRow(["1", "2", "3"]);
    _keypad.addKeysRow(["0", "\u2190", "\u21B5"]);

    _keypad.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    _keypad.horizontalAlignment =
      BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    _keypad.zIndex = 999999;

    _keypad.isPointerBlocker = true;

    store.advancedTexture.addControl(_keypad);

    linkKeypadToInput(input);
    input.onBlurObservable.add(() => callback(input.text));
  }

  function linkKeypadToInput(input) {
    /* eslint-disable */
    if (input._connectedVirtualKeyboard === _keypad) {
    } else {
      _keypad.connect(input);
    }
    /* eslint-enable */
  }

  function removeKeypad() {
    if (_keypad) {
      _keypad.dispose();
      _keypad = null;
    }
  }

  return {
    showKeypad,
    removeKeypad,
  };
})();
export { keypadController };
