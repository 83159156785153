import { Factory } from "./meshFactory.module.js";
import { Floor } from "../snaptrudeDS/floor.ds.js";
import { createCustomMesh } from "../../libs/massModeling.js";
import { mmToSnaptrudeUnits } from "../extrafunc.js";
/**
 * { creates a plain floor }
 *
 * @param      {BABYLON}  mesh    The mesh
 * @return     {BABYLON}   { description_of_the_return_value }
 */
function plainfloor(mesh) {
  var factory = new Factory();

  let floors = [];
  mesh.floor_pol.forEach((floor_pol) => {
    let floor = new Floor(factory.createFloor("plain", floor_pol));
    floor.room_type = mesh.room_type;
    floor.room_id = mesh.room_id;
    floor.assignProperties();
    floor.mesh.structure_id = mesh.structure_id;
    floor.adjustHeight(mesh.floor_pol);
    floor.mesh.storey = mesh.storey;

    floors.push(floor);
  });

  return floors;
}

/**
 * { Create floors }
 *
 * @class      PlainFloor (name)
 * @param      {Array}  points  The points
 * @return     {Object}  { description_of_the_return_value }
 */
var PlainFloor = function (points) {
  let height = plainFloorParameters.floorDepth;
  let floor = createCustomMesh(points, height);
  floor.height = height;
  return floor;
};

var plainFloorParameters = (function () {
  let _floorDepth = mmToSnaptrudeUnits(25);

  return {
    floorDepth: _floorDepth,
  };
})();
export { plainfloor,PlainFloor,plainFloorParameters };
