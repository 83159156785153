import React, {useEffect, useState} from "react"
import styled from "styled-components"
import DataTable, { Select, Td, Text, Th, Tr } from "../../../components/DataTable";
import materialPlaceholderIcon from "../../../assets/icons/material-placeholder.png";
import { getFurnitures, getMaterials } from "../../../services/resources.service";
import { randomIntFromInterval } from "../../../snaptrude/modules/extrafunc";
import furnitureJSON from "./furniture.json";
const Wrapper = styled.div`
    // border: 2px solid;
    padding: 0.25em;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const HEADINGS = [ "Texture", "Material Name", "Material Type", "uScale", "vScale", "Cost / sqm ($)", "Manufacturer", "SHGC"]
const arr = new Array(50).fill(5);

function Furnitures(){
  const [furnituresList, setFurnituresList] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [furnitureCategories, setFurnitureCategories] = useState([]);
  useEffect(() => {
    setFurnituresList(furnitureJSON.furnitureList)
    setAllRooms(furnitureJSON.allRooms);
    setAllCategories(furnitureJSON.allCategories);
    setAllSubCategories(furnitureJSON.allSubCategories);
    // getFurnitures("objects", "ALVAGF")
    // .then(resp => {
    //     console.log(furnitureJSON)
    //     if(resp.status === "success"){
    //         const {list, categories} = resp;
    //         // console.log(list, categories)
    //         setFurnituresList(list);
    //         setFurnitureCategories(categories);
    //     }
    // })
  }, []);
    return (
        <Wrapper>
            <DataTable>
                <Tr borderedBottom sticky={true}>
                    <Th style={{width: "5em"}}>Furniture</Th>
                    <Th>Item Name</Th>
                    <Th style={{width: "10em", textAlign: "right"}}>Rooms</Th>
                    <Th>Family name</Th>
                    <Th>Capacity</Th>
                    <Th style={{width: "10em", textAlign: "right"}}>Category</Th>
                    <Th style={{width: "10em", textAlign: "right"}}>Sub Category</Th>
                    <Th>Cost ($)</Th>
                    <Th>Length</Th>
                </Tr>
        {
          furnituresList.map((item, index) => {
            return <Tr>
                <Td style={{width: "5em"}}>
                    <img style={{width: "4.5em", height: "4.5em", borderRadius: "5px"}} src={item.img} alt="furniture-placeholder" onError={(e) => e.target.src = materialPlaceholderIcon} />
                </Td>
              <Td>
                <Text>{item.fullName}</Text>
              </Td>
              <Td style={{width: "10em"}}>
                <Select selectedOption={item.rooms} options={allRooms} />
              </Td>
              <Td>
                <Text>{item.familyNumber}</Text>
              </Td>
              <Td>
                <Text>{item.capacity}</Text>
              </Td>
              <Td style={{width: "10em"}}>
                <Select selectedOption={item.categories} options={allCategories} />
              </Td>
              <Td style={{width: "10em"}}>
                <Select selectedOption={item.subcategories} options={allSubCategories} />
              </Td>
              <Td>
                <Text>&nbsp;</Text>
              </Td>
              <Td>
                <Text>&nbsp;</Text>
              </Td>
            </Tr>
          })
        }
      </DataTable>
        </Wrapper>
    )
}

export default Furnitures;