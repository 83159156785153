import React, {useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import Text from "../Text";
import Toggle from "../Toggle";
import Input from "../Input";
import useOnClickOutside from "../../common/utils/onOutsideClickHandler";

import add from "../../assets/icons/add.svg";
import image from "../../assets/icons/image.svg";
import cube from "../../assets/icons/objectImage.svg";
import {colors} from "../../themes/constant";
import ScrollableDropdown from "../ScrollableDropdown/index";
import dropArrow from "../../assets/icons/blackDropArrow.svg";

import * as StyledComponents from "../../containers/editor/StyledEditorClasses";
import {removeMouseEvents} from "../../snaptrude/libs/toolbarEvents";
import SnaptrudeImportFunctionalityModal from "../SnaptrudeModal/SnaptrudeImportFunctionalityModal";
import {SnaptrudeDropzone} from "../SnaptrudeModal/SnaptrudeDropzone";
import {
  addCustomFurnitureType,
  formatSubType,
  uploadUserDWF,
  uploadUserMaterial,
} from "../../services/projects.service";

import {isEnterKey} from "../../snaptrude/libs/keyEvents";
import _ from "lodash";
import {cachingService} from "../../snaptrude/modules/utilityFunctions/cachingService";
import Tooltip from "../Tooltip";
import OnboardingTooltip from "../OnboardingTooltip";
import { FakeProgressEvent } from "../../snaptrude/modules/FakeProgressEvent/FakeProgressEvent";

const Main = styled.div`
  position: relative;
  box-shadow: 8.87059px 4.77647px 20.4706px ${colors.shadowGrey};
  .innerContainer {
    // width: 10.8125rem;
    width: 100%;
  }
  .container:before {
    content: "";
    position: absolute;
    top: 0px;
    right: ${props => props.disableTopRightCss ? "0px" : "-2.18755rem"};
    height: ${props => props.disableTopRightCss ? "0px" : "2.1875rem"};
    width: ${props => props.disableTopRightCss ? "0px" : "2.1875rem"};
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 20px 0 0 white;
    transform: rotate(90deg);
    pointer-events: none;
  }
  input:checked + .slider {
    background-color: ${(props) => props.theme.colors.fullWhite};
  }
  label {
    width: 1.22625rem;
    height: 0.75rem;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 0.66rem;
    width: 0.65rem;
    left: 0.0625em;
    bottom: 0.025em;
    background-color: ${(props) => props.theme.colors.brightRed};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: -0.09rem;
    margin-left: -0.1rem;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(0.4rem);
    -ms-transform: translateX(0.4rem);
    transform: translateX(0.4rem);
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.fullWhite};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid ${colors.dullgrey};
  }
`;

const Container = styled.div`
  display: flex;
  // min-width: 17em;
  border-radius: 0.625em;
  width: 20.9375rem;
  height: ${(props) => props.height ? props.height : "21.875rem"};
  transform: ${props => props.transformCss ? props.transformCss : ""}
  background-color: ${colors.fullWhite};
`;

const Inner = styled.div`
  background: ${({ bg }) => bg};
  display: flex;
  flex-direction: column;
  border-radius: 0.625em;
  flex: ${({ flex }) => flex};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ border }) => border};
  transform: ${({ transform }) => transform};
  border-bottom-left-radius: ${({ borderBottomLeft }) => borderBottomLeft};
  .add {
    width: 0.9375em;
    height: 0.9375em;
    padding-left: 0.9375em;
    cursor: pointer;
  }

  .add-material {
    display: none;
  }

  .toggleParent {
    // margin: 0 0.5625rem;
    border: 1px solid ${colors.white};
    border-radius: 0.3125rem;
    padding: 0 0.25rem;
  }
`;

const CustomText = styled(Text)`
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const ScrollContainer = styled.ul`
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.25em;
      padding: 0.5625rem;
    `}

  .selected {
    border-bottom: 2px solid ${colors.borderRed};
  }

  .m-selected {
    border-width: 0.0625em;
    border-style: solid;
    border-color: ${colors.borderRed};
    margin: -0.0625em;
  }

  .m-outer {
    border-radius: 0.3125em;
    &:hover {
      background: #ddd;
      cursor: pointer;
    }
  }

  li {
    ${({ grid }) =>
      !grid &&
      css`
        &:hover {
          background: ${colors.white};
          border-radius: 0.3125em;
          cursor: pointer;
        }
      `}
  }

  li > p {
    margin: 0 1em;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.625em;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.1875em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.125em;
    background-color: ${colors.thinGray};
  }
`;

const IconNav = styled.div`
  display: flex;
  margin: 0.5625rem;
  align-items: center;
  font-size: 0.8rem;
  img {
    width: 0.9375em;
    height: 0.9375em;
    margin-right: 0.25em;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const TopContainerLabel = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.211789px;
  color: ${colors.thinGray};
  padding: 0;
  margin: 0;
`;

const DropdownLabelWrapper = styled(Flex)`
  justify-content: space-between;
  border-radius: 0.3125rem;
  padding: 0 0.3125rem;
  // margin: 0 0.5625rem;
`;

const AddMaterial = ({leftMenuItems, setLeftMenuItems}) => {
  const [showInput, setShowInput] = useState(false);
  const [text, setText] = useState("");
  
  const onInputComplete = function (value){
    if (_.isEmpty(value)) return;
    
    value = _.startCase(value);
    const id = formatSubType(value);
  
    const cachedCategories = cachingService.get(cachingService.SERVICES.furnitureCategories);
    if (cachedCategories.includes(id)) return;
    
    addCustomFurnitureType(value).then((res) => {
      if (res.success){
        cachedCategories.push(id);
      
        setLeftMenuItems([
          ...leftMenuItems,
          {
            title: value,
            key: id,
          }
        ]);
        setText("");
      }
      
    });
  }

  const onBlur = function (e){
    onInputComplete(e.target.value);
  }
  
  const onKeyPress = function (e){
    if (isEnterKey(e)){
      e.target.blur();
    }
    else {
      // let event propagate
    }
  };

  return <>
    {showInput ? 
        <Input
        type="text"
        placeholder="Enter type"
        customInput={{
          height: "1.375rem",
          width: "4.125rem",
        }}
        value={text}
        fontSize="12px"
        customInputContainerStyle={{ margin: "0 0.3125rem" }}
        onPress={(e) => {setText(e.target.value)}}
        keyPress={(e) => onKeyPress(e)}
        onBlur={(e) => onBlur(e)}
      />
      :
      <>
        <img data-tip data-for={"add-category-tooltip"} className="add" onClick={() => {setShowInput(true)}} alt={"+"} src={add} />
        <Tooltip id="add-category-tooltip">Add Category</Tooltip>
      </>
    }
  </>
}

function MenuPopUp(props) {
  
  const {
    title,
    leftMenuItems,
    setLeftMenuItems,
    selectedIndex,
    setSelectedIndex,
    hasTopIcons,
    showToggleIcon,
    toggleOptions,
    child,
    hasleftMenu,
    showDropdowns,
    dropdownOptions,
    dropdownTitle,
    dropdownTooltipId,
    position,
    noBorder,
    hideEmptyBox,
    closePopUp,
    disableAdd,
    borderBottomLeft,
    topSubMenuOptions,
    selectedSubMenu,
    setSelectedSubMenu,
    toggleState,
    toggleTooltipText,
    handleToggle,
    selectedSubIndex,
    setSelectedSubIndex,
    getDBTypeFromUIType,
    disableTopRightCss,
    setSelectedThumbnail,
    onDropdownChange,
    selectedDropdownOption,
    thumbnailImageItems,
    setThumbnailImageItems,
    height,
    transformCss,
    style,
    isOpen,
    setImportedObjects
  } = props;

  const typeOptions = props.typeOptions || [];

  const [uploadedFile, setUploadedFile] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("Uploading");
  useEffect(() => {
    const handleProgressChagne = (evt) => {
      if(evt.detail){
        setProgress(evt.detail.progress);
        setProgressText(evt.detail.text);
      }
    }
    window.addEventListener("dwfuploadprgress", handleProgressChagne);
    return () => {
      window.removeEventListener("dwfuploadprgress", handleProgressChagne);
      
    }
  }, [])
  const unitMapping = {
    Millimeter: "mm",
    Centimeter: "cm",
    Meter: "m",
  };

  const unitOptions = Object.keys(unitMapping);
  const [unit, setUnit] = useState(unitOptions[2]);
  const [type, setType] = useState(typeOptions[0]);

  useEffect(() => {
    setType(typeOptions[0])
  }, [typeOptions]);

  const [addTypeInput, setAddTypeInput] = useState("");

  const handleCancelFile = (index) => {
    let newUploadedFiles = uploadedFile.slice();
    newUploadedFiles.splice(index, 1);
    setUploadedFile(newUploadedFiles);
  };
  
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (closePopUp) closePopUp();
  });

  const addTypeHandler = (e) => {
    const { target } = e;
    target.remove();
    document.querySelector(".add-material").style.display = "block";
  };

  const handleItemClick = (e, index) => {
    setSelectedIndex(index);
    if (setSelectedSubIndex) setSelectedSubIndex(0);
    if (setSelectedThumbnail)
      setSelectedThumbnail({
        imgURL: null,
        type: null,
      });
  };

  const getClassNameByPosition = (e) => {
    switch (position) {
      case "right":
        return "rightContainer";
      default:
        return "";
    }
  };

  const subMenuClickHandler = (subMenuData) => {
    if (subMenuData.persistent) {
      // its state is handled separately, since its selection is independent of other buttons
      // example - cut-hole in furniture
      subMenuData.callback();
    } else {
      const alreadySelected = selectedSubMenu === subMenuData.name;

      if (alreadySelected) {
        setSelectedSubMenu("");
        removeMouseEvents();
      } else {
        if (setSelectedThumbnail) {
          if (!subMenuData.oneTimeOperation){
            setSelectedThumbnail({
              imgURL: null,
              type: null,
            });
  
            removeMouseEvents();
            setSelectedSubMenu(subMenuData.name);
          }
        }
        else setSelectedSubMenu(subMenuData.name);
        
        if (subMenuData.callback) subMenuData.callback();
      }
    }
  };

  const updateStateBasedOnImportedObject = function (
    newObjects
  ) {
    let newIndex, newSubIndex;
    const selectedOption = type;
    leftMenuItems.some((item, index) => {
      const type = item.title;
      if (selectedOption.includes(type)) {
        let subIndex = 0;
        (item.options || []).some((subType, subI) => {
          if (selectedOption.includes(subType)) {
            subIndex = subI;
            newSubIndex = subIndex;
            return true;
          }
        });

        newIndex = index;
        return true;
      }
    });

    if (selectedIndex === newIndex && selectedSubIndex === newSubIndex) {
      // setThumbnailImageItems([...thumbnailImageItems, ...newObjects]);
      setImportedObjects(newObjects)
    } else {
      setSelectedIndex(newIndex);
      if (setSelectedSubIndex) setSelectedSubIndex(newSubIndex);
    }
  };

  const getSubMenuModal = () => {
    if (selectedSubMenu === "importMaterial") {
      return (
        <SnaptrudeImportFunctionalityModal
          isOpen={true}
          onClose={() => {
              if(showProgressBar) setShowProgressBar(false);
              setSelectedSubMenu("");
          }}
          onUpload={() => {
            console.log("upload", uploadedFile);
            setShowProgressBar(true);
            // setProgress(40);
            const uploadedFiles = uploadedFile.map((uf) => uf.file);
            uploadUserMaterial(uploadedFiles, type).then(
              (newObjects) => {
                updateStateBasedOnImportedObject(newObjects);
                setProgress(100);
                setShowProgressBar(false);
                
                setUploadedFile(null);
                setSelectedSubMenu("");
              }
            );
          }}
          handleCancelFile={handleCancelFile}
          modalHeaderText={"Material"}
          bodyHeaderText={
            "Select and upload material images. You can upload upto 10 files"
          }
          allowedTypes={[
            {
              name: ".jpg",
              src: image,
            },
            {
              name: ".png",
              src: image,
            },
            {
              name: ".bmp",
              src: image,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              multiple={true}
              setUploadedFile={setUploadedFile}
              dropzoneAccept="image/*"
            />
          }
          uploadedFile={uploadedFile}
          resetUploadedFile={() => {
            setUploadedFile(null);
          }}
          type={type}
          setType={setType}
          typeOptions={typeOptions}
          hasUnit={false}
          unit={unit}
          setUnit={setUnit}
          unitOptions={unitOptions}
          showProgressBar={showProgressBar}
          progress={progress}
          progressText={progressText}
        />
      );
    } else if (selectedSubMenu === "importDoor") {
      return (
        <SnaptrudeImportFunctionalityModal
          isOpen={true}
          onClose={() => {
            if(showProgressBar) setShowProgressBar(false);
            setSelectedSubMenu("");
          }}
          onUpload={() => {
            // console.log("upload", uploadedFile);
            setProgress(0);
            setShowProgressBar(true);
            
            const uploadedFiles = uploadedFile.map((uf) => uf.file);
            uploadUserDWF(
              uploadedFiles,
              "door",
              getDBTypeFromUIType(type),
              unitMapping[unit]
            ).then((uploadedObjects) => {
              updateStateBasedOnImportedObject(uploadedObjects);
              const evtFound = FakeProgressEvent.find("dwfuploadprgress");
              if(evtFound) evtFound.stop();
              setProgress(100);
              setTimeout(() => {
                setShowProgressBar(false);
                setUploadedFile(null);
                setSelectedSubMenu("");
              }, 1000);

            });
          }}
          handleCancelFile={handleCancelFile}
          modalHeaderText={"Door"}
          bodyHeaderText={
            "Select and upload 3D models in .zip format. You can upload upto 10 files"
          }
          allowedTypes={[
            {
              name: ".skp",
              src: cube,
            },
            {
              name: ".3ds",
              src: cube,
            },
            {
              name: ".obj",
              src: cube,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              multiple={true}
              setUploadedFile={setUploadedFile}
              dropzoneAccept=".zip, .fbx, .skp"
            />
          }
          uploadedFile={uploadedFile}
          resetUploadedFile={() => {
            setUploadedFile(null);
          }}
          type={type}
          setType={setType}
          typeOptions={typeOptions}
          hasUnit={false}
          unit={unit}
          setUnit={setUnit}
          unitOptions={unitOptions}
          showProgressBar={showProgressBar}
          progress={progress}
          progressText={progressText}
        />
      );
    } else if (selectedSubMenu === "importWindow") {
      return (
        <SnaptrudeImportFunctionalityModal
          isOpen={true}
          onClose={() => {
            if(showProgressBar) setShowProgressBar(false);
            setSelectedSubMenu("");
          }}
          onUpload={() => {
            // console.log("upload", uploadedFile);
            setProgress(0);
            setShowProgressBar(true);

            const uploadedFiles = uploadedFile.map((uf) => uf.file);
            uploadUserDWF(
              uploadedFiles,
              "window",
              getDBTypeFromUIType(type),
              unitMapping[unit]
            ).then((uploadedObjects) => {
              updateStateBasedOnImportedObject(uploadedObjects);
              
              const evtFound = FakeProgressEvent.find("dwfuploadprgress");
              if(evtFound) evtFound.stop();
              setProgress(100);
              setTimeout(() => {
                setShowProgressBar(false);
                setUploadedFile(null);
                setSelectedSubMenu("");
              }, 1000);

            });
          }}
          handleCancelFile={handleCancelFile}
          modalHeaderText={"Window"}
          bodyHeaderText={
            "Select and upload 3D models in .zip format. You can upload upto 10 files"
          }
          allowedTypes={[
            {
              name: ".skp",
              src: cube,
            },
            {
              name: ".3ds",
              src: cube,
            },
            {
              name: ".obj",
              src: cube,
            },
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              multiple={true}
              setUploadedFile={setUploadedFile}
              dropzoneAccept=".zip, .fbx, .skp"
            />
          }
          uploadedFile={uploadedFile}
          resetUploadedFile={() => {
            setUploadedFile(null);
          }}
          type={type}
          setType={setType}
          typeOptions={typeOptions}
          hasUnit={false}
          unit={unit}
          setUnit={setUnit}
          unitOptions={unitOptions}
          showProgressBar={showProgressBar}
          progress={progress}
          progressText={progressText}
        />
      );
    } else if (selectedSubMenu === "importFurniture") {
      return (
        <SnaptrudeImportFunctionalityModal
          isOpen={true}
          onClose={() => {
            if(showProgressBar) setShowProgressBar(false);
            setSelectedSubMenu("");
          }}
          onUpload={() => {
            // console.log("upload", uploadedFile);
            setProgress(0);
            setShowProgressBar(true);

            const uploadedFiles = uploadedFile.map((uf) => uf.file);
            uploadUserDWF(
              uploadedFiles,
              type.toLowerCase(),
              null,
              unitMapping[unit]
            ).then((uploadedObjects) => {
              updateStateBasedOnImportedObject(uploadedObjects);

              const evtFound = FakeProgressEvent.find("dwfuploadprgress");
              if(evtFound) evtFound.stop();
              setProgress(100);
              setTimeout(() => {
                setShowProgressBar(false);
                setUploadedFile(null);
                setSelectedSubMenu("");
              }, 1000);
            });
          }}
          handleCancelFile={handleCancelFile}
          modalHeaderText={"Furniture"}
          bodyHeaderText={
            "Select and upload 3D models in .zip format.\nYou can upload upto 10 files"
          }
          allowedTypes={[
            {
              name: ".skp",
              src: cube,
            },
            {
              name: ".3ds",
              src: cube,
            },
            {
              name: ".obj",
              src: cube,
            },
            {
              name: ".fbx",
              src: cube,
            }
          ]}
          DropzoneComponent={
            <SnaptrudeDropzone
              multiple={true}
              setUploadedFile={setUploadedFile}
              dropzoneAccept=".zip, .fbx, .skp"
            />
          }
          uploadedFile={uploadedFile}
          resetUploadedFile={() => {
            setUploadedFile(null);
          }}
          type={type}
          setType={setType}
          typeOptions={typeOptions}
          hasUnit={false}
          unit={unit}
          setUnit={setUnit}
          unitOptions={unitOptions}
          showProgressBar={showProgressBar}
          progress={progress}
          progressText={progressText}
        />
      );
    } else return <div />;
  };

  const getClassNameForSubMenuIcon = function (subMenuData) {
    const selectedClass = "selected-top-submenu-icon";
    const unselectedClass = "top-submenu-icon";

    let className;
    if (subMenuData.persistent)
      className = subMenuData.isSelected ? selectedClass : unselectedClass;
    else if (subMenuData.oneTimeOperation) className = unselectedClass;
    else if (selectedSubMenu === subMenuData.name) className = selectedClass;
    else className = unselectedClass;

    return className;
  };
  

  return (
    <Main ref={ref} disableTopRightCss={disableTopRightCss} style={{...style, display: isOpen ? "block" : "none"}}>
      <Container className="container" height={height}>
        {hasleftMenu && (
          <Inner
            borderBottomLeft={borderBottomLeft}
            border={`0.0625em solid ${colors.white}`}
            boxShadow="0px 4px 30px rgba(197, 202, 220, 0.3)"
            flex="0 0 6.125em"
            bg={colors.fullWhite}
            style={
              noBorder && {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }
            }
          >
            <CustomText
              marginTop="0.625em"
              marginLeft="0.9375em"
              size="0.75em"
              color={colors.darkGrey}
              weight="500"
              opacity="1"
            >
              {title}
            </CustomText>
            {!disableAdd && <AddMaterial leftMenuItems={leftMenuItems} setLeftMenuItems={setLeftMenuItems} />}
            {/* {!disableAdd && 
              <>
                <img data-tip data-for={"add-category-tooltip"} className="add" onClick={addTypeHandler} alt={"+"} src={add} />
                <Tooltip id="add-category-tooltip">Add Category</Tooltip>
                {}
              </>
            }
            {!disableAdd ? (
              <>
              </>
            ) : (
              <div />
            )}
            <div className="add-material">
              <Input
                type="text"
                placeholder="Enter type"
                customInput={{
                  height: "1.375rem",
                  width: "4.125rem",
                }}
                value={addTypeInput}
                fontSize="12px"
                customInputContainerStyle={{ margin: "0 0.3125rem" }}
                onPress={(e) => onChange(e)}
                keyPress={(e) => onKeyPress(e)}
                onBlur={(e) => onBlur(e)}
              />
            </div> */}
            <ScrollContainer>
              {leftMenuItems &&
                leftMenuItems.map((item, index) => (
                  <React.Fragment key={index + "option"}>
                    <li
                      onClick={(e) => handleItemClick(e, index)}
                      key={index + "option"}
                    >
                      <CustomText
                        id={index}
                        lineHeight="0.97625em"
                        marginTop={0}
                        marginBottom={0}
                        weight="400"
                        size="0.75em"
                        color={colors.thinGray}
                        opacity="1"
                        className={selectedIndex === index ? "selected" : ""}
                        key={index + "optionText"}
                      >
                        {item.title}
                      </CustomText>
                    </li>
                    {selectedIndex === index &&
                      item.options &&
                      item.options.map((subItem, subIndex) => (
                        <li
                          onClick={() => setSelectedSubIndex(subIndex)}
                          key={subIndex + "subOption"}
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <CustomText
                            id={subIndex}
                            lineHeight="0.97625em"
                            marginTop={0}
                            marginBottom={0}
                            weight="400"
                            size="0.75em"
                            color={colors.thinGray}
                            opacity="1"
                            className={
                              selectedSubIndex === subIndex ? "selected" : ""
                            }
                            key={subIndex + "subOptionText"}
                          >
                            {subItem}
                          </CustomText>
                        </li>
                      ))}
                  </React.Fragment>
                ))}
            </ScrollContainer>
          </Inner>
        )}
        <Inner
          className="innerContainer"
          style={
            noBorder && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
          }
          bg={colors.fullWhite}
        >
          {getSubMenuModal()}
          
          {child(selectedIndex)}

          {hasTopIcons ? (
            <IconNav>
              {topSubMenuOptions.map((subMenuData, index) => {
                return (
                  <StyledComponents.ImageButton
                    style={{ margin: 0 }}
                    key={index}
                    onClick={() => subMenuClickHandler(subMenuData)}
                    subMenuIcon
                    id={subMenuData.name}
                  >
                    <img
                      data-tip
                      data-for={subMenuData.name}
                      data-event='mouseenter' 
                      data-event-off='mouseleave click'
                      className={getClassNameForSubMenuIcon(subMenuData)}
                      src={subMenuData.icon}
                      alt={subMenuData.name}
                    />
                    <OnboardingTooltip toolid={subMenuData.name} id={subMenuData.name}>{subMenuData.name}</OnboardingTooltip>
                  </StyledComponents.ImageButton>
                );
              })}
              {showToggleIcon ? (
            <div className="toggleParent" data-tip data-for={"toggle-control-tooltip"}>
              <Flex>
                <CustomText
                  size="0.75em"
                  lineHeight="1em"
                  color={toggleState ? colors.thinGray : colors.borderRed}
                  weight={toggleState ? "normal" : "bold"}
                  opacity="1"
                  marginTop="0.375rem"
                  marginBottom="0.375rem"
                >
                  {toggleOptions[0]}
                </CustomText>
                <Toggle labelMargin={"0rem 0.2rem"} handleClick={handleToggle} state={toggleState} />
                <CustomText
                  size="0.75em"
                  lineHeight="1em"
                  color={toggleState ? colors.borderRed : colors.thinGray}
                  weight={toggleState ? "bold" : "normal"}
                  opacity="1"
                  marginTop="0.375rem"
                  marginBottom="0.375rem"
                >
                  {toggleOptions[1]}
                </CustomText>
              </Flex>
              <Tooltip id="toggle-control-tooltip">{toggleTooltipText}{toggleState ? toggleOptions[1] : toggleOptions[0]}</Tooltip>
            </div>
          ) : showDropdowns ? (
            <DropdownLabelWrapper 
              data-tip 
              data-for={"dropdown-title"}
              data-event='mouseenter' 
              data-event-off='mouseleave click'
            >
              <TopContainerLabel style={{width: "100%"}}>{dropdownTitle}</TopContainerLabel>
              <div style={{ width: "70%" }}>
                <ScrollableDropdown
                  customDropArrow={dropArrow}
                  options={dropdownOptions}
                  height="auto"
                  width="100%"
                  onChange={onDropdownChange}
                  selectedOption={selectedDropdownOption}
                />
              </div>
              <OnboardingTooltip toolid={dropdownTooltipId} id="dropdown-title">{dropdownTooltipId}</OnboardingTooltip>
            </DropdownLabelWrapper>
          ) : hideEmptyBox ? null : (
            <div style={{ height: "1.5rem" }} />
          )}
            </IconNav>
            
          ) : (
            <div
              style={{
                margin: "0.5em",
              }}
            />
          )}
          
        </Inner>
      </Container>
    </Main>
  );
}

export default MenuPopUp;
