import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from "styled-components";
import closeButtonIcon from '../../assets/icons/close.svg';
import authService from '../../services/auth.service';
import { approveSignOff, getSignOff } from '../../services/projects.service';
import { colors } from '../../themes/constant';
import Button from '../Button';

const Wrapper = styled.div`
    // border: 2px solid;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
`
const ModalWrapper = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    .heading{
        font-weight: 700;
        font-size: 24px;
        color: ${colors.primeBlack};
    }
    .subheading{
        font-weight: 400;
        font-size: 14px;
        color: ${colors.greyishBlack};
    }
    footer{
        display: flex;
        flex-direction: row;
        z-index: 5;
        padding: 1em;
        border-top: 1px solid rgba(96, 129, 159, 0.2);
        justify-content: space-around;
    }
    .button-wrapper{
        z-index: 5;
    }
    .button-wrapper button{
        min-width: 12em;
        width: fit-content;
    }
    .button-wrapper img{
        filter: contrast(0.25);
    }
    .page-indicator{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .indicator{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 0px 1px;
        background: #F2F2F2;
    }
    .active{
        background: #818181;
    }
`

const BodyWrapper = styled.div`
position: relative;
width: 36.5625rem;
display: flex;
flex-direction: column;
font-size: 14px;
color: ${colors.primeBlack};
header{
    padding: 0.75em 1em;
    border-radius: 12px 12px 0px 0px;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
}
.content{
    overflow: auto;
    padding: 3em 1em;
    text-align: center;
}
`
function SignOffModal({ handleClose, ...props }) {
    const [gettingSignOff, setGettingSignOff] = useState(true);
    const [signOff, setSignOff] = useState(null);
    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        getSignOff()
            .then(resp => {
                if (resp.status === 'success' && resp.requestedSignOff) {
                    setSignOff(resp.requestedSignOff);
                }
                setGettingSignOff(false);
            })
    }, []);

    const handleApprove = async () => {
        setIsLoading(true);
        const resp = await approveSignOff();
        setIsLoading(false);
        if (resp.status === 'success' && resp.requestedSignOff) {
            handleClose();
        }
    }
    if (gettingSignOff) return null;
    if (signOff == null) return null;
    if (signOff.approved) return null;
    if (currentUser.id === signOff.requested_to) {
        return (
            <Wrapper>
                <ModalWrapper ref={ref}>
                    <BodyWrapper>
                        <header>
                            <div style={{ flex: 1, fontWeight: 700 }}>Sign Off</div>
                            <div style={{ cursor: 'pointer' }}><img src={closeButtonIcon} alt="Close" onClick={handleClose} /></div>
                        </header>
                        <div className="content">
                            <strong>{currentUser.name || currentUser.email}</strong> requested your sign-off on all design changes in this model.
                        </div>
                        <footer>
                            <div className="button-wrapper">
                                <Button customButtonStyle={{ backgroundColor: colors.fullWhite, color: colors.secondaryGrey }} isLoading={isLoading} title={"Approve"} onPress={handleApprove} />
                            </div>
                            <div className="button-wrapper">
                                <Button primary={true} title={"Review Design"} onPress={handleClose} />
                            </div>
                        </footer>
                    </BodyWrapper>
                </ModalWrapper>
            </Wrapper>
        );
    }
    return null;
}

export default SignOffModal;