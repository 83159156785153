import axios from "axios";

import { forgeConfig } from "../snaptrude/modules/forgeConnection/forgeConfig";
import { ForgeConnection } from "../snaptrude/modules/forgeConnection/forgeConnection";
import { getJWT } from "./jwt.service";
import { revitDataPreparation } from "../snaptrude/modules/revitExport/revitExport";

import { store } from "../snaptrude/modules/utilityFunctions/Store";
import { environment } from "./url.constants";
import { stopwatch } from "../snaptrude/modules/utilityFunctions/stopwatch";
import { updateExportProgressBar } from "../containers/genericModal/export";

export const createRevitExportWorkItem = (outputFormat) => {
  console.log("Started preparing data for export")

  stopwatch.start("prepare_revit_export_request_data");

  let data = {
    roomId: ForgeConnection.getRoomId(),
    outputFormat: outputFormat,
    jwt: getJWT(),
    user: {
      floorKey: store.floorkey,
      userId: store.infoUser.id,
      name: store.infoUser.name,
      email: store.infoUser.email,
      origin: environment
    },
    addOnData: JSON.stringify(revitDataPreparation.addOnData()),
  };

  stopwatch.stop("prepare_revit_export_request_data");

  console.log("Finished preparing data for export:", data);

  const config = {
    onUploadProgress: (progressEvent) => {
      console.log(`${progressEvent.loaded} from ${progressEvent.total}`)
      let percentCompleted = Math.round((progressEvent.loaded * 10) / progressEvent.total);
      updateExportProgressBar(percentCompleted, 'Uploading...');
    }
  };

  let promise = axios.post(forgeConfig.revitWorkItemURL, data, config)
    .then((res) => {
      return res.data;
    });

  return promise;
};
