import React, { useState, useRef } from "react";
import styled from "styled-components";
import fullArrowLeft from "../../assets/icons/fullArrowLeft.svg";
import { colors } from "../../themes/constant";
import useOnClickOutside from "../../common/utils/onOutsideClickHandler";

const Container = styled.div`
  display: inline-Block;
  .w2 {
    width: 2.6875rem;
  }
`;

const InputCopy = styled.div`
  background: ${colors.white};
  border-radius: 3px;
  height: 1.98125rem;
  margin: 0.22875rem 0.1525rem 0 0.1525rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.333333px;
    color: ${colors.primeBlack};
    margin: 0.22875rem 0.305rem 0.305rem;
  }
`;

const Block = styled.div`
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  margin: 0.22875rem 0.1525rem;
  background: ${colors.dullgrey};
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.333333px;
    color: ${colors.fullWhite};
  }
  img {
    width: 0.978125rem;
    height: 0.45rem;
  }
`;

const MainWrapper = styled.div`
  padding: 0.2825rem 0.305rem;
  background: ${colors.fullWhite};
  width: 9.5625rem;
  border-radius: 5px;
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
`;

const NumericKeypad = (props) => {
  const { closePopUp } = props;
  const [value, setValue] = useState("");
  const rightIndexSet = [3, 7, 11, 15];
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (closePopUp) closePopUp();
  });
  const data = [
    "7",
    "8",
    "9",
    "Esc",
    "4",
    "5",
    "6",
    "C",
    "1",
    "2",
    "3",
    fullArrowLeft,
    "0",
    ".",
    "`",
    "Go",
  ];

  const onPressKey = (item, index) => {
    setValue((prevstate) => {
      if (rightIndexSet.indexOf(index) === -1) {
        const newValue = prevstate.concat(item);
        if (isNaN(newValue)) return prevstate;
        return newValue;
      }
      if (index === 7) return "";
      return prevstate;
    });
  };

  return (
    <MainWrapper ref={ref}>
      <InputCopy>
        <p>{value || 0}</p>
      </InputCopy>
      <Container>
        {data.map((item, index) => {
          return (
            <Block
              key={index}
              className={rightIndexSet.indexOf(index) !== -1 ? "w2" : ""}
              onClick={() => onPressKey(item, index)}
            >
              {index === 11 ? <img src={item} alt="<-" /> : <p>{item}</p>}
            </Block>
          );
        })}
      </Container>
    </MainWrapper>
  );
};

export default NumericKeypad;
