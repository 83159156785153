import React, { useContext, useState } from "react";

const TeamRightClickMenuContext = React.createContext();
const TeamRightClickMenuUpdateContext = React.createContext();

export const useTeamRightClickMenu = () => {
    return useContext(TeamRightClickMenuContext);
}
export const useTeamRightClickMenuUpdate = () => {
    return useContext(TeamRightClickMenuUpdateContext);
}

function TeamRightClickMenuProvider({ children }) {
    const [rightClick, setRightClick] = useState({
        position: {x: 0, y: 0},
        team: null,
        showMenu: false
    });
    const rightClickUpdate = (position={x: 0, y: 0}, team=null, showMenu=false) => {
        setRightClick({
            position,
            team,
            showMenu
        })
    }
    return ( 
        <TeamRightClickMenuContext.Provider value={rightClick}>
            <TeamRightClickMenuUpdateContext.Provider value={rightClickUpdate}>
                {children}
            </TeamRightClickMenuUpdateContext.Provider>
        </TeamRightClickMenuContext.Provider>
    );
}

export default TeamRightClickMenuProvider;