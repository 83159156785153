import _ from "lodash";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants.js";
import { scenePickController} from "../utilityFunctions/scenePickController";

var meshObjectMapping = (function () {
  let mapping = {};
  return {
    /**
     * Add the provided mapping
     * @param {*} mesh
     * @param {*} object
     */
    addMapping: function (mesh, object) {
      let status = true;
      if (!mesh) {
        console.log("Mesh Invalid");
        status = false;
      } else {
        status = this.addMappingByUniqueID(mesh.uniqueId, object);
        scenePickController.add(mesh, true);
      }
      return status;
    },

    addMappingByUniqueID: function (uniqueId, object) {
      let status = true;
      if (!uniqueId) {
        console.log("uniqueId is undefined");
        status = false;
      } else if (!object) {
        console.log("Object Invalid");
        status = false;
      } else {
        let obj = mapping[uniqueId];
        if (obj) {
          console.log(
            "Mesh with Unique ID already exists. Unique ID: " +
              uniqueId +
              ", Type:" +
              obj.mesh.type
          );
        }
        // else{
        mapping[uniqueId] = object;
        // }
      }
      return status;
    },

    /**
     * Delete the mapping for the provided mesh
     * @param {*} mesh
     */
    deleteMapping: function (mesh) {
      let status = true;
      if (!mesh) {
        console.log("Mesh does not exist");
        status = false;
      } else {
        status = this.deleteMappingByUniqueID(mesh.uniqueId);
      }
      return status;
    },

    deleteMappingByUniqueID: function (uniqueId) {
      let status = true;
      if (!uniqueId) {
        console.log("uniqueId is undefined");
        status = false;
      } else if (!mapping[uniqueId]) {
        console.log("Mesh having this uniqueId does not exist", uniqueId);
        status = false;
      } else {
        delete mapping[uniqueId];
      }
      return status;
    },

    /**
     * Update the mapping for the mesh
     * @param {*} mesh
     * @param {*} object
     * @returns {boolean} status of operation
     */
    // updateMapping: function(mesh, object){
    //     let status = true;
    //     if(!mapping[mesh.uniqueId]){
    //         console.log("No Mapping Found. Please use addMapping")
    //         status = false;
    //     }
    //     else{
    //         this.addMapping(mesh, object)
    //     }
    //     return status;
    // },

    /**
     * @returns Object corresponding to the Mesh
     */
    getObjectByUniqueId: function (uniqueId) {
      if (!_.isNumber(uniqueId)) {
        throw "Number Expected as Unique ID";
      }
      let obj = mapping[uniqueId];
      if (!obj) {
        throw "Structure not Assigned. Unique ID: " + uniqueId;
      }
      return obj;
    },

    /**
     * @returns Object corresponding to the Mesh
     */
    getObjectByMesh: function (mesh, throwError=true) {
      let obj = mapping[mesh.uniqueId];
      if (!obj) {
        if (
          ![
            GLOBAL_CONSTANTS.strings.identifiers.typeUnassigned,
            GLOBAL_CONSTANTS.strings.identifiers.visualElement,
            GLOBAL_CONSTANTS.strings.identifiers.boundingBox,
            GLOBAL_CONSTANTS.strings.identifiers.cadSketch,
            GLOBAL_CONSTANTS.strings.identifiers.doorWindowIndicator,
            GLOBAL_CONSTANTS.strings.identifiers.doorIndicatorin2d
          ].includes(mesh.type)
        ) {
          if (throwError) {
            throw (
              "Structure not Assigned. Mesh Name: " +
              mesh.name +
              ", Mesh Unique ID: " +
              mesh.uniqueId
            );
          } else {
            return;
          }
        }
      }
      return obj;
    },

    /**
     * Returns if any mapping for the mesh exists or not
     * @param {*} mesh
     */
    mappingExist: function (mesh) {
      let obj = mapping[mesh.uniqueId],
        status = true;
      if (!obj) {
        status = false;
      }
      return status;
    },

    /**
     * Validates if the given mapping if present or not
     * @param {*} mesh
     * @param {*} object
     */
    mappingValid: function (mesh, object) {
      let status = false;
      if (mapping[mesh.uniqueId] === object) {
        status = true;
      }
      return status;
    },

    returnMapping: function () {
      return mapping;
    },

    updateMesh: function (oldUniqueID, newUniqueID) {
      let obj = mapping[oldUniqueID];
      if (obj) {
        this.deleteMappingByUniqueID(oldUniqueID);
        this.addMappingByUniqueID(newUniqueID, obj);
      }
    },

    updateStructure: function (uniqueId, newStructure) {
      let obj = mapping[uniqueId];
      if (obj) {
        this.deleteMappingByUniqueID(uniqueId);
        this.addMappingByUniqueID(uniqueId, newStructure);
      } else {
        console.log(
          "No mapping exists for this unique ID. addMapping should be called instead of updateStructure for such a case. Adding Mapping"
        );
        this.addMappingByUniqueID(uniqueId, newStructure);
      }
    },

    flush: function(){
      mapping = {};
    },
  };
})();

var levelMapping = (function () {
  let mapping = {};
  return {
    addMapping: function (id, level) {
      mapping[id] = level;
    },

    deleteMapping: function (id) {
      delete mapping[id];
    },

    returnMapping: function () {
      return mapping;
    },

    getLevelByUniqueId: function (id) {
      return mapping[id];
    },

    flush: function(){
      mapping = {};
    },
  };
})();

// BABYLON.AbstractMesh.prototype.getSnaptrudeDS = function(){
//     if(!this.structure_id) throw "Structure not assigned !";

//     let sc = StructureCollection.getInstance();
//     let structure = sc.getStructureById(this.structure_id);
//     if (!structure) return null;

//     let object;
//     if (this.type.toLowerCase() === 'floorplan')
//         object = structure.getStoreyData().getStoreyByValue(this.storey).layerData.getObjectByUniqueId(this.uniqueId);
//     else
//         object = structure.getObjectByUniqueId(this.uniqueId);
//     if(!object) return null;

//     return object;
// };
export { meshObjectMapping,levelMapping };
