export const imgStyles = {
  position: "absolute",
  zIndex: 2,
};

export const editButton = {
  fontWeight: "bold",
  fontSize: "0.75rem",
  cursor: "pointer",
};
