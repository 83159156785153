import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import {
  loadIntComponent,
  getSlopeAngle,
  getRoomAngle,
  offsetInterior,
  getCompOrientation,
} from "./interiors_gen.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { Furniture } from "../modules/snaptrudeDS/furniture.ds.js";
function loadBed(name, room_pol, flag) {
  if (flag) {
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/bedroom/beds/" +
      name +
      ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/bedroom/beds/";
    var bed_mesh = store.scene.getMeshByName(name);
    if (!bed_mesh) {
      bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
    }
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadBed(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function loadDummy(name, room_pol, flag) {
  if (flag) {
    //console.log("dummy test");
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/bedroom/dummy/" +
      name +
      ".babylon";
    var comp_path_dir = window.location.origin + "/media/models/bedroom/dummy/";
    var dummy1_mesh = store.scene.getMeshByName(name);
    var dummy_mesh = loadIntComponent(
      comp_path,
      comp_path_dir,
      scale_factor,
      name
    );
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadDummy(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
      //console.log(newMesh);
    }
  }, 1000);
}

function loadWardrobe(name, room_pol, flag) {
  if (flag) {
    //console.log(name);
    var scale_factor = 1;
    var comp_path =
      window.location.origin +
      "/media/models/bedroom/wardrobe/" +
      name +
      ".babylon";
    var comp_path_dir =
      window.location.origin + "/media/models/bedroom/wardrobe/";
    var bed_mesh = store.scene.getMeshByName(name);
    bed_mesh = loadIntComponent(comp_path, comp_path_dir, scale_factor, name);
  }
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      loadWardrobe(name, room_pol, false);
    } else {
      var newMesh = store.scene.getMeshByName(name);
    }
  }, 1000);
}

function placeBedComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "bed",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeBedComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeBedComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  newInstance.storey = storey.value;

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  let level = structure.getLevel(0, 1);

  newInstance.structure_id = storey.structure_id;
  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForBed2(coords, newInstance);

  furniture.updateBase(base);
  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function getLengthandBreadthofInterior(newInstance) {
  let bbinfo = newInstance.getBoundingInfo();

  newInstance.freezeWorldMatrix();
  bbinfo.update(newInstance._worldMatrix);
  var XX =
    bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
  var ZZ =
    bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
  newInstance.unfreezeWorldMatrix();

  return { xx: XX, zz: ZZ };
}

function getRoomOrientationForBed(coords, newInstance) {
  var c1 = coords[0];
  var c2 = coords[1];
  var c3 = coords[2];
  var angle = getSlopeAngle(c1, c2);
  // var orientation = getCompOrientation(newInstance);
  var orientation_room = getRoomAngle(c1, c2, c3);
  // console.log(orientation_room);

  // let bbinfo = newInstance.getBoundingInfo();
  //
  // newInstance.position = new BABYLON.Vector3.Zero();
  //
  // let lb = getLengthandBreadthofInterior(newInstance);
  // let pivotAt = new BABYLON.Vector3(0, -lb.zz/1.5, 0);
  // let translation = newInstance.position.subtract(pivotAt);
  // newInstance.setPivotMatrix(BABYLON.Matrix.Translation(translation.x, translation.y, translation.z));
  // newInstance.position = new BABYLON.Vector3.Zero();
  // var intersectWallThickness = 0;

  // var bbinfo = newInstance.getBoundingInfo();
  // var ZZ = bbinfo.maximum.z - bbinfo.minimum.z;
  // var YY = bbinfo.maximum.y - bbinfo.minimum.y;
  // var XX = bbinfo.maximum.x - bbinfo.minimum.x;
  // // //console.log(orientation_room);
  //
  // if (orientation_room=="q3"){
  // 	newInstance.rotation.y = angle;
  // 	newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale - XX/2*unit_scale*Math.sin(angle);
  // 	newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale - YY/2*unit_scale*Math.cos(angle);
  // }
  // else if (orientation_room=="q4"){
  // 	newInstance.rotation.y = angle;
  // 	newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale + XX/2*unit_scale*Math.sin(angle);
  // 	newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale - YY/2*unit_scale*Math.cos(angle);
  // }
  // else if (orientation_room=="q1"){
  // 	newInstance.rotation.y = Math.PI+angle;
  // 	newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale + XX/2*unit_scale*Math.sin(angle);
  // 	newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale + XX/2*unit_scale*Math.cos(angle);
  // }
  // else if (orientation_room=="q2"){
  // 	newInstance.rotation.y = Math.PI + angle;
  // 	newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale - YY/2*unit_scale*Math.sin(angle);
  // 	newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale + YY/2*unit_scale*Math.cos(angle);
  // }
  var bbinfo = newInstance.getBoundingInfo();
  // console.log(bbinfo);
  // var ZZ = bbinfo.maximum.z - bbinfo.minimum.z;
  // var YY = bbinfo.maximum.y - bbinfo.minimum.y;
  // var XX = bbinfo.maximum.x - bbinfo.minimum.x;
  // let wMatrix = newInstance.getWorldMatrix();
  // let meshRotmatrix = wMatrix.getRotationMatrix();
  // let newVectorsWorld = [];
  // console.log(jQuery.extend([], bbinfo.boundingBox.vectorsWorld));
  // bbinfo.boundingBox.vectorsWorld.forEach(function (vec) {
  //     console.log(jQuery.extend({}, vec));
  //     // let rotVec = BABYLON.Vector3.TransformCoordinates(vec, wMatrix);
  //     // newVectorsWorld.push(rotVec);
  // });

  // for (let ix=0; ix<bbinfo.boundingBox.vectorsWorld.length; ix++){
  //     let vw = bbinfo.boundingBox.vectorsWorld[ix];
  //     console.log(vw);
  // }
  // console.log(jQuery.extend([], bbinfo.boundingBox.vectorsWorld));
  // console.log(newVectorsWorld);
  // newInstance.position.x = (coords[0][0] + coords[1][0]) / 2 * store.unit_scale;
  // newInstance.position.z = -(coords[0][1] + coords[1][1]) / 2 * store.unit_scale;
  newInstance.position.x = (coords[0][0] + coords[1][0]) / 2;
  newInstance.position.z = (coords[0][1] + coords[1][1]) / 2;
  newInstance.freezeWorldMatrix();
  bbinfo.update(newInstance._worldMatrix);
  var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
  newInstance.unfreezeWorldMatrix();
  if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;

  // newInstance.freezeWorldMatrix();
  // bbinfo.update(newInstance._worldMatrix);
  //
  // var XX = bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
  // var YY = bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
  // var ZZ = bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
  // newInstance.unfreezeWorldMatrix();
  //
  // console.log(XX, ZZ);
  // for (var a = doublyLinkedList.head(), i = 0; i < doublyLinkedList.size(); a = doublyLinkedList.item(++i)) {
  //     if (newInstance.intersectsMesh(a.data, true)) {
  //         intersectWallThickness = a.data._properties._thickness;
  //         break;
  //     }
  // }
  //
  // // if (orientation_room === "q3") {
  // //     newInstance.rotation.y = Math.PI + angle;
  // //     newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.sin(angle);
  // //     newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.cos(angle);
  // // }
  // // else if (orientation_room === "q4") {
  // //     newInstance.rotation.y = Math.PI - angle;
  // //     newInstance.position.x -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.sin(angle);
  // //     newInstance.position.z += (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.cos(angle);
  // // }
  // // else if (orientation_room === "q1") {
  // //     newInstance.rotation.y = angle;
  // //     newInstance.position.x -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.sin(angle);
  // //     newInstance.position.z -= (XX / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.cos(angle);
  // // }
  // // else if (orientation_room === "q2") {
  // //     newInstance.rotation.y = -angle;
  // //     newInstance.position.x += (XX / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.sin(angle);
  // //     newInstance.position.z -= (ZZ / 2 + intersectWallThickness / 2 * store.unit_scale) * Math.cos(angle);
  // // }
  //
  if (orientation_room === "q3") {
    newInstance.rotation.y = Math.PI + angle;
    let lb = getLengthandBreadthofInterior(newInstance);
    // newInstance.position.x += (lb.xx / 2) * Math.sin(angle);
    // newInstance.position.z += (lb.zz / 2) * Math.cos(angle);
  } else if (orientation_room === "q4") {
    newInstance.rotation.y = Math.PI - angle;
    let lb = getLengthandBreadthofInterior(newInstance);
    // newInstance.position.x -= (lb.xx / 2) * Math.sin(angle);
    // newInstance.position.z += (lb.zz / 2) * Math.cos(angle);
  } else if (orientation_room === "q1") {
    newInstance.rotation.y = angle;
    let lb = getLengthandBreadthofInterior(newInstance);
    // newInstance.position.x -= (lb.xx / 2) * Math.sin(angle);
    // newInstance.position.z -= (lb.zz / 2) * Math.cos(angle);
  } else if (orientation_room === "q2") {
    newInstance.rotation.y = -angle;
    let lb = getLengthandBreadthofInterior(newInstance);
    // newInstance.position.x += (lb.xx / 2) * Math.sin(angle);
    // newInstance.position.z -= (lb.zz / 2) * Math.cos(angle);
  }
}

function getRoomOrientationForBed2(coords, newInstance) {
  let wallThickness = 200 / (store.unit_absolute_scale * 10000);
  offsetInterior(coords, newInstance, wallThickness);
}

function getRoomOrientationForWardrobe(coords, newInstance) {
  let wallThickness = 200 / (store.unit_absolute_scale * 10000);
  offsetInterior(coords, newInstance, wallThickness);
}

// function getRoomOrientationForWardrobe(coords, newInstance){
// 	var c1 = coords[0];
// 	var c2 = coords[1];
// 	var c3 = coords[2];
// 	var angle = getSlopeAngle(c1, c2);
// 	var orientation = getCompOrientation(newInstance);
// 	var bbinfo = newInstance.getBoundingInfo();
// 	var ZZ = bbinfo.maximum.z - bbinfo.minimum.z;
// 	var YY = bbinfo.maximum.y - bbinfo.minimum.y;
// 	var XX = bbinfo.maximum.x - bbinfo.minimum.x;
// 	//console.log(orientation, angle);
// 	if (orientation=="x"){
// 		if ((c3[0] - (c1[0]+c2[0])/2) < 0){
// 			angle = Math.PI + angle;
// 			if (angle < 3*Math.PI/4){
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale - XX/3*unit_scale*Math.cos(angle);
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale + YY/3*unit_scale*Math.sin(angle);
// 			}
// 			else{
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale - XX/3*unit_scale*Math.cos(angle);
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale + YY/3*unit_scale*Math.sin(angle);
// 			}
// 		}
// 		else{
// 			angle = angle;
// 			if (angle < Math.PI/4){
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale;
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale-YY/3*unit_scale*Math.sin(angle);
// 			}
// 			else{
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale + XX/3*unit_scale;
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale;
// 			}
// 		}
// 	}
// 	else if (orientation=="y"){
// 		if ((c3[0] - (c1[0]+c2[0])/2) < 0){
// 			if (angle < 3*Math.PI/4){
// 				// angle = Math.PI + angle;
// 				// angle = Math.PI + angle;
// 				//console.log("less than 3pi/4");
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale - XX/3*unit_scale;
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale;
// 			}
// 			else{
// 				//console.log("greater than 3pi/4", angle);
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale;
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale + YY/3*unit_scale;
// 			}
// 		}
// 		else{
// 			if (angle < Math.PI/4){
// 				//console.log("less than pi/4");
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale;
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale-YY*unit_scale;
// 			}
// 			else{
// 				angle = Math.PI + angle;
// 				//console.log("greater than pi/4");
// 				newInstance.rotation.y = angle;
// 				newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale + XX/4*unit_scale;
// 				newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale;
// 			}
// 		}
// 	}
// }
//

function placeWardrobeComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "wardrobe",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeWardrobeComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeWardrobeComp(coords, name, storey, base) {
  var int_mesh = store.scene.getMeshByName(name);
  int_mesh.visibility = 1;
  var newInstance = int_mesh.createInstance(name);
  newInstance.storey = storey.value;

  let structure =
    StructureCollection.getInstance().getStructures()[storey.structure_id];
  let level = structure.getLevel(0, 1);

  newInstance.structure_id = storey.structure_id;
  let furniture = new Furniture(newInstance);
  newInstance.scaling = new BABYLON.Vector3(
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr,
    store.unit_absolute_scale / store.inch_to_mtr
  );

  getRoomOrientationForWardrobe(coords, newInstance);
  furniture.updateBase(base);

  level.addFurnitureToLevel(furniture, false);
  newInstance.getSnaptrudeDS().autoInterior = true;
  return newInstance;
}

function placeDummyComp(room_pol, name) {
  $.ajax({
    url: store.ajax_url,
    data: {
      mode1: JSON.stringify(room_pol),
      mode2: "room_data",
      mode3: "dummy",
      csrfmiddlewaretoken: store.ajax_csrf,
    },
    success: function (data) {
      arrangeDummyComp(data, name);
    },
    dataType: "json",
    type: "POST",
  });
}

function arrangeDummyComp(coords, name) {
  window.setTimeout(function () {
    if (store.scene.getMeshByName(name) == null) {
      arrangeDummyComp(coords, name);
    } else {
      var int_mesh = store.scene.getMeshByName(name);
      var newInstance = int_mesh.clone(name);
      newInstance.visibility = 1;
      newInstance.storey = 1;
      newInstance.scaling = new BABYLON.Vector3(1, 1, 1);
      var bbinfo = newInstance.getBoundingInfo();
      var height = bbinfo.maximum.z - bbinfo.minimum.z;
      var width = bbinfo.maximum.y - bbinfo.minimum.y;
      getRoomOrientationForDummy(coords, newInstance);
      // newInstance.rotation.y = angle;
      // newInstance.position.x = (coords[0][0]+coords[1][0])/2*unit_scale;
      // newInstance.position.z = -(coords[0][1]+coords[1][1])/2*unit_scale;
      // newInstance.position.y = height / 2 * store.unit_scale;
      // newInstance.position.y = 0;
    }
  }, 1000);
}

function getRoomOrientationForDummy(coords, newInstance) {
  var c1 = coords[0];
  var c2 = coords[1];
  var c3 = coords[2];
  var angle = getSlopeAngle(c1, c2);
  var orientation = getCompOrientation(newInstance);
  var orientation_room = getRoomAngle(c1, c2, c3);

  var bbinfo = newInstance.getBoundingInfo();

  newInstance.position.x =
    ((coords[0][0] + coords[1][0]) / 2) * store.unit_scale;
  newInstance.position.z =
    (-(coords[0][1] + coords[1][1]) / 2) * store.unit_scale;
  newInstance.freezeWorldMatrix();
  bbinfo.update(newInstance._worldMatrix);
  var interior_offset = Math.abs(bbinfo.boundingBox.minimumWorld.y);
  newInstance.unfreezeWorldMatrix();
  if (bbinfo.minimum.y < 0) newInstance.position.y += interior_offset;

  newInstance.freezeWorldMatrix();
  bbinfo.update(newInstance._worldMatrix);

  var XX =
    bbinfo.boundingBox.maximumWorld.x - bbinfo.boundingBox.minimumWorld.x;
  var YY =
    bbinfo.boundingBox.maximumWorld.y - bbinfo.boundingBox.minimumWorld.y;
  var ZZ =
    bbinfo.boundingBox.maximumWorld.z - bbinfo.boundingBox.minimumWorld.z;
  newInstance.unfreezeWorldMatrix();

  if (orientation_room === "q3") {
    newInstance.rotation.y = Math.PI + angle;
    newInstance.position.x += (ZZ / 2) * Math.sin(angle);
    newInstance.position.z += (XX / 2) * Math.cos(angle);
  } else if (orientation_room === "q4") {
    newInstance.rotation.y = -angle - Math.PI;
    newInstance.position.x -= (XX / 2) * Math.sin(angle);
    newInstance.position.z += (ZZ / 2) * Math.cos(angle);
  } else if (orientation_room === "q1") {
    newInstance.rotation.y = angle;
    newInstance.position.x -= (ZZ / 2) * Math.sin(angle);
    newInstance.position.z -= (XX / 2) * Math.cos(angle);
  } else if (orientation_room === "q2") {
    newInstance.rotation.y = angle;
    newInstance.position.x += (XX / 2) * Math.sin(angle);
    newInstance.position.z -= (ZZ / 2) * Math.cos(angle);
  }
}
export {
  loadBed,
  loadDummy,
  loadWardrobe,
  placeBedComp,
  arrangeBedComp,
  getLengthandBreadthofInterior,
  getRoomOrientationForBed,
  getRoomOrientationForBed2,
  getRoomOrientationForWardrobe,
  placeWardrobeComp,
  arrangeWardrobeComp,
  placeDummyComp,
  arrangeDummyComp,
  getRoomOrientationForDummy,
};
