import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import folderIcon from "../../../assets/icons/folderIcon.svg";
import addFolderIcon from "../../../assets/icons/addFolderIcon.svg";
import styled from "styled-components";
import rightArrowIcon from "../../../assets/icons/rightArrowIcon.svg";
import { useFileSystemUpdate } from "../../../contexts/FileSystemContext";
import { DragDropWrapper } from "../../DragDrop";
import { handleItemsMove } from "../../Folder/FoldersContainer";
import { useSelector } from "react-redux";
import { colors } from "../../../themes/constant";
import _ from "lodash";
import { getFilesForFolder } from "../../../services/folder.service";
import Tooltip from "../../Tooltip";
import { orderFolders } from "../../../containers/dashboard/TeamDashboard/helper";
const Wrapper = styled.div`
    margin-left: 10px;
`

const getAllFolderIds = (folders, store) => {
    if(Array.isArray(folders)) {
        folders.forEach(folder => {
            store[folder.id] = true

            getAllFolderIds(folder?.folders, store)
        })
    }
}

function Folder({ folder, workspaceRoute, selectedFolderID, permissions, enableDropZone, path, isOverCurrent }) {
    const fileSystemUpdate = useFileSystemUpdate();
    const [isExpanded, setIsExpanded] = useState(false);
    useEffect(() => {
        if(Boolean(path.find(step => step.folderID === folder.id))){
            setIsExpanded(true)
        }
    }, [path, folder.id])

    const dndSelectedFolders = useSelector(state => {
        const temp = {}

        if(state.dnd.selection?.folders) getAllFolderIds(Object.values(state.dnd.selection?.folders), temp)

        return temp
    })

    const handleExpandToggle = () => {
        if(!isExpanded){
            getFilesForFolder(folder.team_dashboard, folder.id);
        }
        setIsExpanded(!isExpanded);
    }

    return (
        <React.Fragment>
            <div className={`titlebar ${folder.id === path[0]?.folderID ? 'active active-route' : ''} ${isOverCurrent ? 'titlebar-dragover' : ''}`}>
                <span className={`expand-folder-button ${isExpanded ? 'rotate-90' : ''}`} onClick={handleExpandToggle}>
                    <img src={rightArrowIcon} alt="arrow"/>
                </span>
                <Link className={`title`} to={`${workspaceRoute}/folder/${folder.id}`}>
                    <div className="icon"><img src={folderIcon} alt="folder" /></div>
                    <div data-tip data-for={`sidebar-${folder.id}-${folder.name}`} className="titletext">{folder.name}</div>
                    <Tooltip id={`sidebar-${folder.id}-${folder.name}`}>{folder.name}</Tooltip>
                </Link>
                {
                    permissions["create_rename_delete_folder"] &&
                        <span className="add-folder-button" onClick={() => {fileSystemUpdate(folder.team_dashboard, folder.id, "create")}}>
                            <img data-tip data-for={`create-folder-${folder.id}-${folder.name}`} src={addFolderIcon} alt="add"/>
                            <Tooltip place="right" id={`create-folder-${folder.id}-${folder.name}`}>Create folder inside</Tooltip>
                        </span>
                }
            </div>
            {
                isExpanded &&
                <FolderSidebar
                    folders={folder.folders}
                    workspaceRoute={workspaceRoute}
                    selectedFolderID={selectedFolderID}
                    permissions={permissions}
                    enableDropZone={enableDropZone || !(folder.id in dndSelectedFolders)}
                    path={path}
                />
            }
        </React.Fragment>
    )
}
function FolderSidebar({ folders, workspaceRoute, selectedFolderID, permissions, enableDropZone = undefined, path }) {
    const {foldersSorted} = useMemo(() => {
        let foldersSorted = folders;
        if(folders){
            foldersSorted = orderFolders(folders, "earliest").reverse()
        }
        return {foldersSorted: foldersSorted}
    }, [folders])
    if (!folders) return null;

    return (
        <Wrapper>
            {
                foldersSorted.map((folder, i) => {
                    return (
                        <DragDropWrapper
                            ddId={"dragDrop_folder_" + i}
                            key={folder.id}
                            id={folder.id}
                            bucketName="folders"
                            type="folder"
                            data={folder}
                            accept={["folder", "file"]}
                            onDrop={handleItemsMove}
                            draggable={false}
                            style={{}}
                            selectionStyle={{}}
                            dropAreaStyle={{}}
                            enableDrop={enableDropZone == undefined ? true : enableDropZone}
                        >
                            <Folder 
                                folder={folder}
                                workspaceRoute={workspaceRoute}
                                selectedFolderID={selectedFolderID}
                                permissions={permissions}
                                enableDrop={enableDropZone == undefined ? true : enableDropZone}
                                path={path}
                            />
                        </DragDropWrapper>
                    )
                })
            }
        </Wrapper>
    );
}

export default FolderSidebar;