/*jshint esversion: 6 */
"use strict";
/**
 * { Sketches datastructure }
 *
 * @class      Sketch Walls (name)
 * @param      {Object}  mesh    The mesh
 */
var Sketch = function (mesh) {
  this.mesh = mesh;
  this.level = mesh.level;
  this.level_low = 0;
  this.level_hight = 1;
  this.height = 0;
  this.storey = !mesh.storey ? 1 : mesh.storey;
  this.structure_id = mesh.structure_id;
  this.id = "f_" + Math.random().toString(36).substr(2, 9);
  this.type = "Sketch";
  this.mesh.type = "Sketch";
  this.groupId = null;
  this.mesh.storey = this.storey;
  this.isLocked = false;
};

/**
 * Remove a Sketch from level.
 *
 * @param      {<type>}  object  The object
 */
Sketch.prototype.removeFurnitureFromLevel = function (level) {
  for (let i = 0; i < level.flyweight.sketches.length; i++) {
    if (this.mesh.uniqueId === level.flyweight.sketches[i].mesh.uniqueId) {
      level.flyweight.sketches.splice(i, 1);
      return;
    }
  }
};
/**
 * Notifies all.
 */
Sketch.prototype.notifyAll = function (action) {};
export { Sketch };
