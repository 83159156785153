const COLORCODES = [
    "#f5222d",
    "#fa541c",
    "#fa8c16",
    "#faad14",
    "#a0d911",
    "#13c2c2",
    "#1890ff",
    "#722ed1",
    "#eb2f96",
]
const colorController = (() => {
    const mp = new Map();

    const getRandomInt = () => {
        return Math.floor(Math.random() * COLORCODES.length);
    }

    const getColor = (id) => {
        if(!mp.has(id)){
            mp.set(id, COLORCODES[getRandomInt()]);
        }
        return mp.get(id);
    }

    return {
        getColor,
    }
})();

export {
    colorController
}