"use strict";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { StoreyMutation } from "../modules/storeyEngine/storeyMutations.js";
import imageLayer from "../../assets/tiles/imageLayer.svg";
import cadLayer from "../../assets/tiles/cadLayer.svg";
import topoLayer from "../../assets/tiles/topoLayer.svg";
import neighborLayer from "../../assets/tiles/NeighborLayer.svg";
import pdfLayer from "../../assets/subMenuIcon/import/importpdf.svg";
import reduxStore from "../stateManagers/store/reduxStore.js";
import { appendStorey } from "../stateManagers/reducers/objectProperties/storeysSlice.js";
import _ from "lodash";

var storeyView = null;

// store.angular.element(function () {
  storeyView = (function () {
    // var $scope = store.angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    var structureCollection;
    var _storeysUiData = null;    
    const init = () => {
      structureCollection = StructureCollection.getInstance();
    }

    var getStructures = function () {
      return structureCollection.getStructures();
    };

    var getStoreysInStructure = function (id) {
      return getStructures()[id].getStoreyData().getAllStoreys();
    };

    var getStoreyByValue = function (val) {
      return _storeysUiData.filter((storey) => storey.value === val)[0];
    };

    var getLayerByName = function (storey, layerName) {
      let _storeyOfInterest = getStoreyByValue(storey);
      return _storeyOfInterest.layers.filter(
        (layer) => layerName === layer.name
      )[0];
    };

    var getStoreyData = function (storey) {
      let layers = storey.layerData.getAllLayers();
      let storey_object = {
        id: storey.id,
        name: storey.name,
        value: storey.value,
        height: DisplayOperation.convertToDefaultDimension(storey.height),
        structure_id: storey.structure_id,
        hidden: storey.hidden,
        layers: [],
      };
      for (let key of Object.keys(layers)) {
        let layer = layers[key];
        let layer_object = {
          id: layer.id,
          name: layer.name,
          storey: layer.storey,
          structure_id: layer.structure_id,
          type: layer.type,
          hidden: false,
          heightMapToggle: layer.heightMapToggle,
        };
        storey_object.layers.push(layer_object);
      }

      return storey_object;
    };

    var getLayerData = function (layer) {
      return {
        id: layer.id,
        name: layer.name,
        storey: layer.storey,
        structure_id: layer.structure_id,
        type: layer.type,
        hidden: false,
        heightMapToggle: layer.heightMapToggle,
      };
    };

    var generateStoreyUiDataDeprecated = function () {
      let storeys_array = [];
      let structures = getStructures();
      let storeyToggle = {};
      let layerToggle = {};

      for (let k = 0; k < Object.keys(structures).length; k++) {
        let str_key = Object.keys(structures)[k];
        if (str_key === "default") {
          continue;
        }
        let storeys = getStoreysInStructure(str_key);
        for (let storeyId in storeys) {
          let storey = storeys[storeyId];
          let allLayers = storey.layerData.getAllLayers();
          let storey_object = getStoreyData(storey);
          storeys_array.push(storey_object);
          storeyToggle[storey_object.value] = storey_object.hidden;
          for(let i of Object.keys(allLayers)) {
            layerToggle[allLayers[i].id] = allLayers[i].hidden;
          }
        }
      }

      storeys_array.sort(function (a, b) {
        return b.value - a.value;
      });

      // setTimeout(function () {
      //     $scope.$apply(function () {
      // $scope.storeysUiData = storeys_array;
      // $scope.storeyUItoggle =
      //   typeof store.userSettingsInStructure.storeyUIToggle == "undefined"
      //     ? storeyToggle
      //     : store.userSettingsInStructure.storeyUIToggle;
      // $scope.layerUIToggles =
      //   typeof store.userSettingsInStructure.layerUIToggle == "undefined"
      //     ? layerToggle
      //     : store.userSettingsInStructure.layerUIToggle;

      //     });
      // },);
      // console.log('---->',storeys_array);
      _storeysUiData = storeys_array;
    };

    const generateStoreyUiData = function () {
      const storeyCollection = StoreyMutation.getAllStoreys();
      const payload = {
        items: []
      };

      for (let storeyId in storeyCollection) {
        const storey = storeyCollection[storeyId];
        const layerCollection = storey.layerData.getAllLayers();

        const storeyData = {
          id: storey.id,
          value: storey.value,
          name: storey.name,
          height: DisplayOperation.convertToDefaultDimension(storey.height),
          hidden: storey.hidden,
          layers: []
        };

        for (let layerId in layerCollection) {
          const layer = layerCollection[layerId];
          let icon = imageLayer;
          if(layer.layerType.toLowerCase().includes("terrain")){
            icon = topoLayer;
          }
          else if(layer.layerType.toLowerCase().includes("cad")){
            icon = cadLayer;
          }
          else if(layer.layerType.toLowerCase().includes("buildings")){
            icon = neighborLayer;
          }
          else if(layer.layerType.toLowerCase().includes("pdf-1")){
            icon = pdfLayer;
          }
          if (["image", "cad", "terrain", "buildings", "pdf-1"].includes(layer.layerType.toLowerCase())) {
            if( layer.layerType.includes("terrain") && _.isEmpty(layer.terrain) ){
              // Handle empty layers
              store.residualTerrainLayers.push(layer.id);
              continue;
            }
            storeyData.layers.push({
              id: layer.id,
              title: layer.name,
              storey: storey.value,
              hidden: layer.hidden,
              heightMapToggle: layer.heightMapToggle,
              image: icon,
              subView: {
                allIcons: true,
                heightToggle: layer.heightMapToggle,
                thicknessSelector: false,
                colorPicker: false,
              }
            });
          }
        }

        payload.items.push(storeyData);
      }

      reduxStore.dispatch(appendStorey(payload));
    };

    let deleteStoreyLayerUIData = function (storey, layerID) {
      // TODO: MANVITH not migrated
      // let storeys_array = $scope.storeysUiData;
      // let requiredStorey = storeys_array.filter(
      //   (s) => s.value === storey.value
      // );
      // let storeyIndex = Object.keys(requiredStorey)[0];

      // let layerData = requiredStorey[storeyIndex].layers;
      // layerData = layerData.filter((l) => l.id !== layerID);
      // storeys_array[storeyIndex].layers = layerData;

      // let layerToggles = $scope.layerUIToggles;
      // if (layerToggles.hasOwnProperty(layerID)) {
      //   delete layerToggles[layerID];
      // }

      // storeys_array.sort(function (a, b) {
      //   return b.value - a.value;
      // });

      // setTimeout(function () {
        // $scope.$apply(function () {
        //   $scope.storeysUiData = storeys_array;
        //   $scope.layerUIToggles = layerToggles;
        // });
      // });
      // console.log('---->',storeys_array);
      // _storeysUiData = storeys_array;
    };

    function changeHeightUnits() {
      if (store.activeLayer) {
        let structureCollection =
          StructureCollection.getInstance().getStructures()[
            store.activeLayer.structure_id
          ];
        let storeyCollection = structureCollection.getStoreyData();
        if (_storeysUiData) {
          _storeysUiData.forEach(function (storey) {
            let storeyDS = storeyCollection.getStoreyByValue(storey.value);
            let nativeUnits = storeyDS.getHeight();
            storey.height =
              DisplayOperation.convertToDefaultDimension(nativeUnits);
          });
        }
      }
    }

    return {
      init,
      getStoreyByValue: function (val) {
        return getStoreyByValue(val);
      },
      generateStoreyUiData: function () {
        return generateStoreyUiData();
      },
      deleteStoreyLayerUIData: function (storey, layerID) {
        return deleteStoreyLayerUIData(storey, layerID);
      },
      getStoreyData: function (storey) {
        return getStoreyData(storey);
      },
      getLayerData: function (layer) {
        return getLayerData(layer);
      },
      getLayerByName,
      changeHeightUnits,
      _storeysUiData: _storeysUiData,
    };
  })();
// });
export { storeyView };
