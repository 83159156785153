import { getObjectsFromIds } from "../../../services/team.service";
import { addIncomingChangelog, initChangelogs } from "../../stateManagers/reducers/objectProperties/changelogsSlice";
import reduxStore from "../../stateManagers/store/reduxStore";
import { randomIntFromInterval } from "../extrafunc";
import { autoSaveConfig } from "./autoSaveConfig";

const idToObject = new Map();
const placeholderGroups = [
  {
    name: "Alpha beta",
    occ: 2,
    snaptrudeElements: [{ name: "ele name 1", occ: 2, cost: 10 }, { name: "ele name 2", occ: 1, cost: 120 }],
    nonSnaptrudeElements: [{ name: "non snap elem 1", occ: 3 }, { name: "non snap elem 2", occ: 1 }]
  },
  {
    name: "Alpha beta gmma",
    occ: 1,
    snaptrudeElements: [{ name: "ele name 1", occ: 2, cost: 10 }, { name: "ele name 2", occ: 1, cost: 120 }],
    nonSnaptrudeElements: [{ name: "non snap elem 1", occ: 3 }, { name: "non snap elem 2", occ: 1 }]
  }
]

const updateIdentifiers = async (logs) => {
  const nonExistingIds = new Set();
  let teamId = null;
  for (let log of logs) {
    if (log.operation.name === 'saveas') continue;
    let team = log.identifier?.furniture?.team;
    if (log.identifier?.revitMetaData) {
      log.groups = log.identifier?.revitMetaData;
    } else {
      log.groups = {}
    }
    if (team == undefined) {
      team = log.identifier?.revitMetaData?.team
      if (team == undefined) return;
      teamId = team
    } else {
      teamId = Object.keys(team)[0];
      if (teamId == undefined) return;
      for (let key in team[teamId]) {
        if (!idToObject.has(key)) nonExistingIds.add(key)
      }
    }
  }
  const nonExistingIdsArray = Array.from(nonExistingIds);
  const response = await getObjectsFromIds(teamId, nonExistingIdsArray);
  if (response.status === 'success') {
    const { objects } = response;
    for (let obj of objects) {
      idToObject.set(obj.id, obj);
    }
    const updatedLogs = [];
    for (let log of logs) {
      const team = log.identifier?.furniture?.team || log.identifier?.revitMetaData?.team;
      const revitMetaData = log.identifier?.revitMetaData;
      if (revitMetaData) {
        log.groups = revitMetaData;
      } else {
        log.groups = {}
      }
      if (team == undefined) {
        updatedLogs.push(log);
        continue;
      }
      teamId = Object.keys(team)[0];
      if (teamId == undefined) {
        updatedLogs.push(log)
        continue;
      }
      if (log.costOfOperation === 0) {
        continue;
      }
      log.titles = [];
      for (let key in team[teamId]) {
        const object = idToObject.get(parseInt(key));
        if (object) {
          log.titles.push({ name: object.displayName, count: team[teamId][key], "cost": object.cost, familyName: object.familyName })
        }
      }
      updatedLogs.push(log);
    }
    return updatedLogs;
  }
  return logs;
}

const ChangelogConnect = (() => {
  const init = (floorkey, offset = 0, limit = 100) => {
    const autoSaveSocket = autoSaveConfig.getSocketObject();
    idToObject.clear();
    if (autoSaveSocket) {
      autoSaveSocket.emit("getlogs", { floorkey, offset, limit }, async (resp) => {
        if (resp.message === 'success') {
          const updatedLogs = await updateIdentifiers(resp.logs);
          reduxStore.dispatch(initChangelogs({ logs: updatedLogs }))
        }
      })
    }
  };

  const handleIncomingChangelog = async (log) => {
    if (log) {
      const updatedLogs = await updateIdentifiers([log]);
      if (updatedLogs.length > 0) {
        reduxStore.dispatch(addIncomingChangelog({ log: updatedLogs[0] }))
      }
    }
  }

  return {
    init,
    handleIncomingChangelog
  }

})();

export {
  ChangelogConnect,
};
