import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import Toggle from "../../Toggle/index";
import Text from "../../Text/index";
import { handleToolbar } from "../../../snaptrude/libs/handleToolbar";
import useOutsideClickHandler from "../../../common/utils/useOutsideClickHandler";

export const Container = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  padding: 0.3125rem 0.5rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  input:checked + .slider {
    background-color: ${(props) => props.theme.colors.fullWhite};
  }
  label {
    width: 1.22625rem;
    margin-right: 0;
    height: 0.75rem;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 0.66rem;
    width: 0.65rem;
    left: 0.0625em;
    bottom: 0.025em;
    background-color: ${(props) => props.theme.colors.brightRed};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: -0.09rem;
    margin-left: -0.1rem;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(0.4rem);
    -ms-transform: translateX(0.4rem);
    transform: translateX(0.4rem);
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.fullWhite};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid ${colors.dullgrey};
  }
`;

const ToggleWrapper = (props) => {
  const { item, customTextStyle, onToggle, isActive } = props;
  return (
    <MainWrapper>
      {item && item.title && (
        <Text
          size="0.75em"
          className="leftItem"
          lineHeight="1em"
          weight={isActive ? "bold" : "normal"}
          opacity="1"
          style={customTextStyle}
        >
          {item.title}
        </Text>
      )}
      <Toggle state={isActive} handleClick={() => { onToggle(item) }} />
      {item && item.secondtitle && (
        <Text
          size="0.75em"
          lineHeight="1em"
          weight={isActive ? "bold" : "normal"}
          opacity="1"
          style={customTextStyle}
        >
          {item.secondtitle}
        </Text>
      )}
    </MainWrapper>
  );
}

export const LabelWithToggle = (props) => {
  const { item, customTextStyle, onTogglePress } = props;
  const [isActive, setIsActive] = useState((item && item.isActive) || false);

  const onToggle = () => {
    if (onTogglePress) onTogglePress(item, !isActive);
    setIsActive((prevstate) => !prevstate);
  };

  return (
    <ToggleWrapper item={item} customTextStyle={customTextStyle} onToggle={onToggle} isActive={isActive} />
    // <MainWrapper>
    //   {item && item.title && (
    //     <Text
    //       size="0.75em"
    //       className="leftItem"
    //       lineHeight="1em"
    //       weight={isActive ? "bold" : "normal"}
    //       opacity="1"
    //       style={customTextStyle}
    //     >
    //       {item.title}
    //     </Text>
    //   )}
    //   <Toggle state={isActive} handleClick={onToggle} />
    //   {item && item.secondtitle && (
    //     <Text
    //       size="0.75em"
    //       lineHeight="1em"
    //       weight={isActive ? "bold" : "normal"}
    //       opacity="1"
    //       style={customTextStyle}
    //     >
    //       {item.secondtitle}
    //     </Text>
    //   )}
    // </MainWrapper>
  );
};

export const LabelWithToggleGroup = (props) => {
  const { item, customTextStyle, onTogglePress } = props;
  const [activeItemsInGroup, setActiveItemsInGroup] = useState(item.initalActiveItems);

  const onToggle = (newItem) => {
    if(!activeItemsInGroup.includes(newItem.title)){
      const newActiveItemsInGroup = JSON.parse(JSON.stringify(activeItemsInGroup));
      newActiveItemsInGroup.push(newItem.title);
      newActiveItemsInGroup.shift();
      setActiveItemsInGroup(newActiveItemsInGroup);
      if (onTogglePress) onTogglePress(newItem, true);
    }else{
      if(newItem.title === item.initalActiveItems[0]) return;
      const newActiveItemsInGroup = JSON.parse(JSON.stringify(activeItemsInGroup));
      newActiveItemsInGroup.push(item.initalActiveItems[0]);
      newActiveItemsInGroup.shift();
      setActiveItemsInGroup(newActiveItemsInGroup);
      if (onTogglePress) onTogglePress(newItem, false);
    }
  };

  return (
    item.list.map((tool, index) => {
      return <ToggleWrapper item={tool} index={index} customTextStyle={customTextStyle} onToggle={onToggle} isActive={activeItemsInGroup.includes(tool.title)} />
    })
  );
}

export const ToggleList = (props) => {
  const { list, onListChange, onClose } = props;
  const ref = useRef(null);
  useOutsideClickHandler(ref, onClose);
  const onTogglePress = (item, isActive) => {
    handleToolbar(item.title, null, {isActive});
    onListChange(item.title, isActive)
  }
  const [isRefreshed, setIsRefreshed] = useState(true);
  useEffect(() => {
    setIsRefreshed(false);
    setTimeout(() => {setIsRefreshed(true)}, 1);
    return () => {}
  }, [list])
  return (
    <Container ref={ref}>
      {isRefreshed && list &&
        list.map((item, index) => {
          if(item.isGroup) return <LabelWithToggleGroup key={index} item={item} onTogglePress={onTogglePress} />
          return <LabelWithToggle key={index} item={item} onTogglePress={onTogglePress} />;
        })}
    </Container>
  );
};
