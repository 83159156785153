import BABYLON from "../modules/babylonDS.module.js";
import $ from "jquery";
import { store } from "../modules/utilityFunctions/Store.js";
import { updateButtonColor } from "./sketch/updateEventModes.js";
import { moveOperator } from "../modules/meshoperations/moveOperations/moveOperation.js";
import { arrayFunctionOperator } from "./arrayFunctionEvents.js";
import {
  clearSelection,
  onPointerDoubleClick,
  onPointerDownSelect,
  onPointerLeaveSelect,
  onPointerMoveSelect,
  onPointerUpSelect,
} from "./selectToolFuncs.js";
import { staircaseOperation } from "../modules/meshoperations/staircaseOperation.js";
import { searchForId } from "./arrayFuncs.js";
import { addMaterialToMesh } from "./mats.js";
import { hideAxis } from "./sceneEvents.js";
import { showAxis } from "./sceneFuncs.js";
import { appElement } from "./bimDataFuncs.js";
import { onPointerDownExtrude, onPointerMoveExtrude, onPointerUpExtrude, } from "./extrudeEvents.js";
import { onMatEditPointerDown, onMatPointerDown, onMatPointerMove, onMatPointerUp, } from "./applyMaterialFuncs.js";
import { onMatDeleteDoubleClick, onMatDeletePointerDown, } from "./deleteMaterialEvents.js";
import { explorerCam } from "./explorerCam.js";
import { sectionViews } from "./sectionView.js";
import { angleMeasure } from "./angleMeasure.js";
import { sketchOperation } from "../modules/meshoperations/sketchOperation.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { eraseoperation } from "./twoD/eraserEvent.js";
import { measureFunction } from "./measureEvents.js";
import { clipOperations } from "./clipEvents.js";
import { RotateOperation } from "../modules/meshoperations/rotateOperation.js";
import { ScaleOperation } from "../modules/meshoperations/scaleOperation.js";
import { drawingOperator } from "./drawingEvents.js";
import { drawCylinder } from "./drawCylinder.js";

import { splitFaceOperator } from "../modules/meshoperations/splitFaceOperations.js";
import { removeElementsOperator } from "./removeElementsEvents.js";
import { editPolygonOperator } from "./editPolygonEvents.js";
import { doorOperation } from "../modules/meshoperations/doorOperation.js";
import { windowOperation } from "../modules/meshoperations/windowOperation.js";
import { furnitureOperation } from "../modules/meshoperations/furnitureOperation.js";
import { onDetectDownHead, showDoors } from "./twoD/twoDetectRooms.js";
import { setScaleOperation } from "../modules/setScaleOperations/setScaleOperation.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { disposeBackWall } from "../modules/meshoperations/moveOperations/moveUtil.js";
import { getRenderAPI, send_scene_serialize_data, } from "./serializers.js";
import { assignProperties, nonDefaultMesh, nonDefaultMeshForSnapping, } from "./sceneStateFuncs.js";
import { ForgeConnection } from "../modules/forgeConnection/forgeConnection";
import { OUTPUT_FORMATS } from "../modules/forgeConnection/revitExportListeners";
import { onPointerUpRotate, } from "../modules/cameraControl/rotateEvents.js";
import { onSolid, } from "../modules/extrafunc.js";
import { onWindowDrawDown, onWindowDrawMove, onWindowDrawUp, } from "./drawWindowEvents.js";
import { onStairDrawDown, onStairDrawMove, onStairDrawUp, } from "./drawStairEvents.js";
import { onCurtainDrawDown, onCurtainDrawMove, onCurtainDrawUp, } from "./drawCurtainEvents.js";
import { NewLogger } from "../modules/logger/logger.js";
import { onDoorDrawDown, onDoorDrawMove, onDoorDrawUp, } from "./drawDoorEvents.js";
import {
  drawSelectionBox,
  removeChildren,
  removeMeshSelectionChildren,
  removeSelectionBox,
  unclick,
} from "./meshEvents.js";
import { onSetStoreyDown } from "../modules/geo/terrainMap.js";
import { delayedExecutionEngine } from "../modules/utilityFunctions/delayedExecution.js";
import { changeToIsoViewCamera, changeToOrthoViewCamera, } from "./cameraFuncs.js";
import { room_types_db } from "./obj_base.js";
import { terrainGeneration } from "../modules/geo/terrainMap";
import { ComponentManager } from "../modules/componentManager/componentManager.js";
import { hideAllRoofs, objectVisibilityUtil, showAllRoofs, } from "./roofVisibilityFuncs.js";
import { scrollZoom } from "./zoomFuncs.js";
import { exportModel } from "./serializers";
import { cameraController } from "../modules/cameraControl/cameraController";
import MaterialEditor from "../modules/materials/materialEditor";
import { exportTrude } from "../../services/saveMicro.service.js";
import editMaterial from "./materialEditUI";
import { isThrowAwayIdentifier } from "../modules/extrafunc";
import { commentOperator } from "./commentEvents.js";
import { latlongOperator } from "./latLongEvents.js";
import { panOperator } from "../modules/cameraControl/pan";
import { addTemporaryCursor, updateCursor } from "../../containers/editor/cursorHandler.js";
import { cursor } from "../../themes/cursor.js";
import { FakeProgressEvent } from "../modules/FakeProgressEvent/FakeProgressEvent";
import { updateExportProgressBar } from "../../containers/genericModal/export";
import { UIHandler } from "./handleToolbar.js";
import { Locker } from "../modules/locker/locker.js";

/*jshint esversion: 6 */
// import * from ";
// const module = import("../modules/operationmanager.module.js");

var ACTIVE_OBJECT = {};
store.ACTIVE_EVENT = {
  event: null,
};

var moveBlocks = function () {
  removeMouseEvents();

  moveOperator
    .getMetadata()
    .setOperatingMode(moveOperator.getMetadata().getOperatingModes().move);

  store.canvas.addEventListener(
    "pointerdown",
    moveOperator.eventHandler,
    false
  );
  store.canvas.addEventListener("pointerup", moveOperator.eventHandler, false);
  store.canvas.addEventListener(
    "pointermove",
    moveOperator.eventHandler,
    false
  );
  store.ACTIVE_EVENT = { event: "moveBlocks" };
  // store.canvas.addEventListener("dblclick", onPointerDoubleClickFreeMove, false);
  // store.newScene.activeCamera.attachControl(canvas, true, false);
};

const editObject = function () {
  removeMouseEvents();

  clearSelection();

  moveOperator
    .getMetadata()
    .setOperatingMode(moveOperator.getMetadata().getOperatingModes().edit);

  store.canvas.addEventListener(
    "pointerdown",
    moveOperator.eventHandler,
    false
  );

  store.canvas.addEventListener("pointerup", moveOperator.eventHandler, false);

  store.canvas.addEventListener(
    "pointermove",
    moveOperator.eventHandler,
    false
  );

  store.ACTIVE_EVENT = { event: "editObject" };
};

var arrayFunction = function () {
  removeMouseEvents();
  // updateButtonColor("arrayFunction");

  store.canvas.addEventListener(
    "pointerdown",
    arrayFunctionOperator.eventHandler,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    arrayFunctionOperator.eventHandler,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    arrayFunctionOperator.eventHandler,
    false
  );
  // store.canvas.addEventListener("dblclick", onPointerDoubleClickFreeMove, false);
  // store.newScene.activeCamera.attachControl(canvas, true, false);
  store.ACTIVE_EVENT.event = "arrayFunction";
};

var onSelectTool = function () {
  removeMouseEvents();
  // updateButtonColor("select_tool");
  var count = 0;

  var wall = store.scene.getMeshByName("backwall");
  // if (!wall.position.x) wall.position.x = 0;
  // if (!wall.position.y) wall.position.y = 0;
  // wall.position.z = store.scene.getCameraByName("ArcRotateCamera3").radius * 0.80;
  if (wall) {
    wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
    wall.isPickable = true;
  }
  //canvas.addEventListener("pointerdown", onPointerDownRotate, false);
  // store.newScene.activeCamera.detachControl(canvas);
  store.canvas.addEventListener("pointerdown", onPointerDownSelect, false);
  store.canvas.addEventListener("pointerup", onPointerUpSelect, false);
  store.canvas.addEventListener("pointermove", onPointerMoveSelect, false);
  store.canvas.addEventListener("mouseleave", onPointerLeaveSelect, false);
  //canvas.addEventListener("dblclick", onPointerDoubleClick, false);
  store.ACTIVE_EVENT = { event: "select_tool" };

  // if (!wall.position.z) wall.position.z = 30;
  // // wall.position = new BABYLON.Vector3(0, 0, 0);
  // wall.position.z = store.newScene.activeCamera.radius*0.80;
  // wall.parent = store.newScene.activeCamera;
};

var onStaircaseTool = function (staircaseType) {
  removeMouseEvents();
  updateCursor(cursor.placeObject)
  store.ACTIVE_EVENT = { event: "staircase" };
  // updateButtonColor("select_tool");
  // var count = 0;

  // var wall = store.scene.getMeshByName("backwall");
  // if (!wall.position.x) wall.position.x = 0;
  // if (!wall.position.y) wall.position.y = 0;
  // wall.position.z = store.scene.getCameraByName("ArcRotateCamera3").radius * 0.80;
  // if (wall) {
  //     wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
  //     wall.isPickable = true;
  // }
  //canvas.addEventListener("pointerdown", onPointerDownRotate, false);
  // store.newScene.activeCamera.detachControl(canvas);
  staircaseOperation.reset();
  staircaseOperation.initialize(staircaseType);
  store.canvas.addEventListener(
    "pointerdown",
    staircaseOperation.onPointerDown,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    staircaseOperation.onPointerUp,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    staircaseOperation.onPointerMove,
    false
  );
  store.canvas.addEventListener(
    "dblclick",
    staircaseOperation.onPointerDoubleClick,
    false
  );
  // store.ACTIVE_EVENT = {"event": 'select_tool'}

  // if (!wall.position.z) wall.position.z = 30;
  // // wall.position = new BABYLON.Vector3(0, 0, 0);
  // wall.position.z = store.newScene.activeCamera.radius*0.80;
  // wall.parent = store.newScene.activeCamera;
};

// var onCamRot = function () {
//     removeMouseEvents();
//     updateButtonColor("camRot");
//     var count = 0;
//     while (count < store.newScene.meshes.length) {
//         unclick(newScene.meshes[count]);
//         count++;
//     }
//     // var bounds = computeBounds();
//     // store.newScene.activeCamera.setTarget(bounds.boundingSphere.center);
//     store.canvas.addEventListener("pointerdown", onPointerDownRotate, false);
//     document.getElementById("canvas").style.cursor = "default";
//     store.newScene.activeCamera.attachControl(canvas, true, false);
//     // var wall = store.scene.getMeshByName("backwall");
//     // wall.position.z = store.newScene.activeCamera.radius*0.80;
//     // wall.parent = store.newScene.activeCamera;
//     // wall.isPickable = true;
// };

var onWireFrame = function () {
  updateButtonColor("wireframe");
  for (var i = 0; i < store.scene.meshes.length; i++) {
    var mesh = store.scene.meshes[i];
    if (mesh.room_type) {
      var id = searchForId(room_types_db, mesh.room_type);
      var obj_props = store.room_types_db[id];
      var obj_name, obj_height, obj_far, obj_mat;
      if (obj_props) {
        obj_name = obj_props.name;
        obj_height = obj_props.props.height * store.floor_height;
        obj_far = obj_props.props.far;
        obj_mat = obj_props.props.mat;
      } else {
        obj_name = store.room_types[i];
        obj_height = store.floor_height;
        obj_far = true;
        obj_mat = "none";
      }
      if ($("#mass_mode").prop("checked")) {
        addMaterialToMesh(mesh, obj_mat);
      } else {
        mesh.showBoundingBox = true;
      }
    }
    if ($("#mass_mode").prop("checked")) {
      addMaterialToMesh(mesh, null);
    } else {
      addMaterialToMesh(mesh, null);
    }
  }
};

var onResetView = function () {
  store.newScene.activeCamera.target = new BABYLON.Vector3(0, 0, 0);
  store.newScene.activeCamera.setPosition(new BABYLON.Vector3(45, 30, -45));
};

var onVisualize = function () {
  var ground;
  if (document.getElementById("visualize").style.backgroundColor == "") {
    updateButtonColor("visualize");
    hideAxis();
    store.newScene.clearColor = new BABYLON.Color3(0.6, 0.6, 0.6);
    ground = store.newScene.getMeshByName("ground1");
    ground.material.diffuseColor = (0.5, 0.5, 0.5);
    ground.visibility = 0;
    store.scene.fogDensity = 0;
  } else {
    updateButtonColor("edit");
    showAxis(500);
    ground = store.newScene.getMeshByName("ground1");
    ground.visibility = 1;
    ground.enableEdgesRendering();
    ground.edgesWidth = 4.0;
    ground.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
    ground.material = new BABYLON.StandardMaterial("texture1", store.newScene);
    ground.material.alpha = 0.4;
    ground.checkCollisions = true;
    ground.optimize(100);
    ground.receiveShadows = true;
    // store.scene.fogDensity = 0.005;
    ground.material.diffuseColor = new BABYLON.Color3(
      190 / 255,
      196 / 255,
      190 / 255
    );
    store.newScene.clearColor = new BABYLON.Color3(
      240 / 255,
      240 / 255,
      240 / 255
    );
  }
};

var onFaceMode = function () {
  updateButtonColor("move_face");

  // document.body.style.cursor = 'grab';

  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  $scope.move_facel_value =
    document.getElementById("move_face").style.backgroundColor === "orange";
  if (document.getElementById("move_face").style.backgroundColor !== "orange") {
    removeMouseEvents();
    if (store.face_ribbon) {
      store.face_ribbon.dispose();
      if (store.scene.getMeshByName("textPlane")) {
        store.scene.getMeshByName("textPlane").dispose();
      }
    }
  } else {
    removeMouseEvents();
    store.canvas.addEventListener(
      "pointerdown",
      () => {
        console.log("RUN: onPointerDownMoveFace");
      },
      false
    );
    store.canvas.addEventListener(
      "pointerup",
      () => {
        console.log("RUN onPointerUpMoveFace");
      },
      false
    );
    store.canvas.addEventListener(
      "pointermove",
      () => {
        console.log("RUN onPointerMoveMoveFace");
      },
      false
    );
  }
  //  changePropBlock();
  var wall = store.scene.getMeshByName("backwall");
  if (wall) {
    wall.position.z =
      store.scene.getCameraByName("ArcRotateCamera3").radius * 0.8;
    wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
    wall.isPickable = false;
  }
  // onSelectTool();
  // moveBlocks();
};

var onExtrude = function () {
  

  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onPointerDownExtrude, false);
  store.canvas.addEventListener("pointerup", onPointerUpExtrude, false);
  store.canvas.addEventListener("pointermove", onPointerMoveExtrude, false);
  
  store.ACTIVE_EVENT = { event: "editObject" };
  
};

var onPickMaterialFromScene = function (setSelectedMaterial) {
  // updateButtonColor("mat_mode");
  // matEventsActivationFlag = true;
  /*let $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  $scope.delMat = false;
  if ($scope.editMat) {
    removeMouseEvents();
    if (store.matEditMenu) {
      store.matEditMenu.dispose();
      store.matEditMenu = null;
    }
  } else {
    removeMouseEvents();
    $scope.editMat = true;
    store.canvas.addEventListener("pointerdown", onMatEditPointerDown, false);
    store.canvas.addEventListener("pointerup", onMatEditPointerUp, false);
    store.canvas.addEventListener("pointermove", onMatEditPointerMove, false);
    store.ACTIVE_EVENT = { event: "mat_mode" };
  }*/
  removeMouseEvents();
  UIHandler.setTool("");
  addTemporaryCursor(cursor.eyeDropper);
  editMaterial.setMaterialSelect(setSelectedMaterial);
  store.canvas.addEventListener("pointerdown", onMatEditPointerDown, false);
  // MaterialEditor.init();
  // store.canvas.addEventListener("pointerup", onMatEditPointerUp, false);
  // store.canvas.addEventListener("pointermove", onMatEditPointerMove, false);

  store.ACTIVE_EVENT = { event: "mat_mode" };
};

// var onMatMode = function () {
//     // updateButtonColor("mat_mode");
//     var $scope = store.angular.element(appElement).scope();
//     $scope = $scope.$$childHead;
//     // $scope.move_facel_value = document.getElementById("mat_mode").style.backgroundColor === "orange";
//     $scope.move_facel_value = true;
//     store.canvas.addEventListener("pointerdown", onMatPointerDown, false);
//     store.canvas.addEventListener("pointerup", onMatPointerUp, false);
//     store.canvas.addEventListener("pointermove", onMatPointerMove, false);
//
//     // if (document.getElementById("mat_mode").style.backgroundColor !== "orange") {
//     //     removeMouseEvents();
//     //     if (face_ribbon) {
//     //         store.face_ribbon.dispose();
//     //         if (scene.getMeshByName("textPlane")) {
//     //             store.scene.getMeshByName("textPlane").dispose();
//     //         }
//     //     }
//     // } else {
//     //     store.canvas.addEventListener("pointerdown", onMatPointerDown, false);
//     //     store.canvas.addEventListener("pointerup", onMatPointerUp, false);
//     //     store.canvas.addEventListener("pointermove", onMatPointerMove, false);
//     // }
//     changePropBlock();
//     var wall = store.scene.getMeshByName("backwall");
//     if (wall) {
//         wall.position.z = store.scene.getCameraByName("ArcRotateCamera3").radius * 0.80;
//         wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
//         wall.isPickable = false;
//     }
//     // onSelectTool();
//     // moveBlocks();
//     for (var i=0; i<scene.meshes.length; i++){
//         unclick(scene.meshes[i]);
//     }
// };

var onMatMode = function () {
  removeMouseEvents();
  UIHandler.setTool("");
  setTimeout(() => {
    updateCursor(cursor.materials);
  }, 100)
  /* AG-RE: ANGULAR REFERENCE */
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  // $scope.face_model_value = document.getElementById("mat_mode").style.backgroundColor === "orange";
  store.$scope.face_model_value = true;
  store.canvas.addEventListener("pointerdown", onMatPointerDown, false);
  store.canvas.addEventListener("pointerup", onMatPointerUp, false);
  store.canvas.addEventListener("pointermove", onMatPointerMove, false);
  store.ACTIVE_EVENT = { event: "onMat" };

  // if (document.getElementById("mat_mode").style.backgroundColor !== "orange") {
  //     removeMouseEvents();
  //     if (face_ribbon) {
  //         face_ribbon.dispose();
  //         if (scene.getMeshByName("textPlane")) {
  //             store.scene.getMeshByName("textPlane").dispose();
  //         }
  //     }
  // } else {
  //     store.canvas.addEventListener("pointerdown", onMatPointerDown, false);
  //     store.canvas.addEventListener("pointerup", onMatPointerUp, false);
  //     store.canvas.addEventListener("pointermove", onMatPointerMove, false);
  // }
  //changePropBlock();
  // var wall = store.scene.getMeshByName("backwall");
  // if (wall) {
  //     wall.position.z = store.scene.getCameraByName("ArcRotateCamera3").radius * 0.80;
  //     wall.parent = store.scene.getCameraByName("ArcRotateCamera3");
  //     wall.isPickable = false;
  // }
  // onSelectTool();
  // moveBlocks();
  for (var i = 0; i < store.scene.meshes.length; i++) {
    unclick(store.scene.meshes[i]);
  }
};

let onDeleteMaterial = function () {
  // matEventsActivationFlag = true;
  /*let $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  $scope.editMat = false;
  if ($scope.delMat) {
    removeMouseEvents();
  } else {
    removeMouseEvents();
    $scope.delMat = true;
    store.canvas.addEventListener("pointerdown", onMatDeletePointerDown, false);
    store.canvas.addEventListener("dblclick", onMatDeleteDoubleClick, false);
    store.ACTIVE_EVENT = { event: "delete_mat" };
  }*/

  removeMouseEvents();
  UIHandler.setTool("");
  addTemporaryCursor(cursor.erase);
  store.canvas.addEventListener("pointerdown", onMatDeletePointerDown, false);
  store.canvas.addEventListener("dblclick", onMatDeleteDoubleClick, false);
  store.ACTIVE_EVENT = { event: "delete_mat" };
};

let onSelectMultiple = function () {};

var intSelect = function (prop, tabs, placeDirect=false) {
  function prepareLoadData(url) {
    let directoryPathArr = url.replace(/\\/g, "/").split("/");
    let fileNameArr = directoryPathArr.splice(-1, 1);

    let componentData = {};
    componentData.name = fileNameArr[0].split(".")[0];
    componentData.rootUrl = directoryPathArr.join("/") + "/";
    // componentData.rootUrl = "https://cors-container.herokuapp.com/" + componentData.rootUrl;
    console.log(componentData.rootUrl);
    // componentData.rootUrl = "https://cors-container.herokuapp.com/https://app.snaptru.de/media/media/objects/";
    // if(tabs.title === "Window") componentData.rootUrl = "https://cors-container.herokuapp.com/https://app.snaptru.de/media/media/objects/";
    // else if(tabs.title === "Office") componentData.rootUrl = "https://cors-container.herokuapp.com/https://app.snaptru.de/media/media/objects/";
    return [componentData];
  }

  delayedExecutionEngine.executeAll();

  store.$scope.selectedInterior = prop.propurl;
  store.$scope.selectedInteriorType = tabs.title;

  let componentData = prepareLoadData(prop.propurl);
  let type = tabs.title.toLowerCase();
  let subType = prop.subType;
  let callback;
  let format = ComponentManager.FORMAT.BABYLON;
  if (!placeDirect) {
    removeMouseEvents();
    updateCursor(cursor.placeObject);
    UIHandler.clearMenu();
    cameraController.deactivateOrbitMode();
  }
  let mesh = store.scene.getMeshByName(componentData[0].name);

  if (!mesh) {
    if (type === "door") {
      /* AG-RE: call window.analytics.track("Adding door") */
      // console.warn("call window.analytics.track('Adding door')");
      window.analytics.track("Adding door");

      callback = doorOperation.loadDoorMesh.bind(doorOperation, subType);
    } else if (type === "window") {
      /* AG-RE: call window.analytics.track("Adding window") */
      // console.warn("call window.analytics.track('Adding window')");
      window.analytics.track("Adding window");

      callback = windowOperation.loadWindowMesh.bind(windowOperation, subType);
    } else {
      /* AG-RE: call window.analytics.track("Adding furniture") */
      // console.warn("call window.analytics.track('Adding furniture')");
      window.analytics.track("Adding furniture");
      let extraData = {
        direct: placeDirect
      };
      if(prop.teamId){
        extraData['teamId'] = prop.teamId;
        extraData['elementId'] = prop.elementId;
        extraData['cost'] = prop.cost;
        if(prop.familyName) extraData['familyName'] = prop.familyName;
      }
  
      let importType = ["Revit", "Rhino", "SketchUp", "3D Model"].includes(tabs.title) ? tabs.title : null;
      callback = furnitureOperation.loadFurnitureMesh.bind(furnitureOperation, importType, extraData);
    }

    ComponentManager.addTask(componentData, format);
    ComponentManager.load(callback);
  } else {
    if (type === "door") {
      /* AG-RE: call window.analytics.track("Adding door") */
      // console.warn("call window.analytics.track('Adding door')");
      window.analytics.track("Adding door");

      doorOperation.dispatchEvents(mesh);
    } else if (type === "window") {
      /* AG-RE: call window.analytics.track("Adding window") */
      // console.warn("call window.analytics.track('Adding window')");
      window.analytics.track("Adding window");

      windowOperation.dispatchEvents(mesh);
    } else {
      /* AG-RE: call window.analytics.track("Adding furniture") */
      // console.warn("call window.analytics.track('Adding furniture')");
      window.analytics.track("Adding furniture");

      furnitureOperation.dispatchEvents(mesh);
    }
  }
  // Need to set canvas as active again after selecting image on sidebar DOM
  store.canvas.focus();
};

// function pointerDrag(){
//     let pickinfo = store.newScene.pick(scene.pointerX, store.scene.pointerY);
//
//     console.log(pickinfo);
// }

function initDraw(canvas) {
  function setMousePosition(e) {
    var ev = e || window.event;
    if (ev.pageX) {
      mouse.x = ev.pageX + window.pageXOffset;
      mouse.y = ev.pageY + window.pageYOffset;
    } else if (ev.clientX) {
      //IE
      mouse.x = ev.clientX + document.body.scrollLeft;
      mouse.y = ev.clientY + document.body.scrollTop;
    }
  }

  var mouse = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0,
  };
  var element = null;

  store.canvas.onmousemove = function (e) {
    setMousePosition(e);
    if (element !== null) {
      element.style.width = Math.abs(mouse.x - mouse.startX) + "px";
      element.style.height = Math.abs(mouse.y - mouse.startY) + "px";
      element.style.left =
        mouse.x - mouse.startX < 0 ? mouse.x + "px" : mouse.startX + "px";
      element.style.top =
        mouse.y - mouse.startY < 0 ? mouse.y + "px" : mouse.startY + "px";
    }
  };

  store.canvas.onclick = function (e) {
    if (element !== null) {
      element = null;
      store.canvas.style.cursor = "default";
      //console.log("finsihed.");
    } else {
      //console.log("begun.");
      mouse.startX = mouse.x;
      mouse.startY = mouse.y;
      element = document.createElement("div");
      element.className = "select-rectangle";
      element.id = "recto";
      element.style.left = mouse.x + "px";
      element.style.top = mouse.y + "px";
      element.style.border = "1px solid #FF0000";
      element.style.position = "absolute";
      element.style.zIndex = "999";
      store.canvas.appendChild(element);
      store.canvas.style.cursor = "crosshair";
      //console.log(document.getElementById('recto'));
    }
  };
}

function removeMouseEvents() {
  let id = store.ACTIVE_EVENT.event;
  switch (id) {
    case "explorerCam":
      store.canvas.removeEventListener(
        "pointerdown",
        explorerCam.onPointerDown
      );
      store.canvas.removeEventListener("pointerup", explorerCam.onPointerUp);
      store.canvas.removeEventListener(
        "pointermove",
        explorerCam.onPointerMove
      );
      explorerCam.resetCam();
      document.body.style.cursor = "default";
      break;

    case "sectionView":
      store.canvas.removeEventListener(
        "pointerdown",
        sectionViews.onPointerDown
      );
      store.canvas.removeEventListener("pointerup", sectionViews.onPointerUp);
      store.canvas.removeEventListener(
        "pointermove",
        sectionViews.onPointerMove
      );
      break;

    case "angleMeasure":
      angleMeasure.resetAngleMeasure();
      store.canvas.removeEventListener(
        "pointerdown",
        angleMeasure.onPointerDown
      );
      store.canvas.removeEventListener("pointerup", angleMeasure.onPointerUp);
      store.canvas.removeEventListener(
        "pointermove",
        angleMeasure.onPointerMove
      );
      break;

    case "moveBlocks":
      moveOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        moveOperator.eventHandler
      );
      store.canvas.removeEventListener("pointerup", moveOperator.eventHandler);
      store.canvas.removeEventListener(
        "pointermove",
        moveOperator.eventHandler
      );
      break;
    case "editObject":
      moveOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        moveOperator.eventHandler
      );
      store.canvas.removeEventListener("pointerup", moveOperator.eventHandler);
      store.canvas.removeEventListener(
        "pointermove",
        moveOperator.eventHandler
      );
      break;
    case "arrayFunction":
      arrayFunctionOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        arrayFunctionOperator.eventHandler
      );
      store.canvas.removeEventListener(
        "pointerup",
        arrayFunctionOperator.eventHandler
      );
      store.canvas.removeEventListener(
        "pointermove",
        arrayFunctionOperator.eventHandler
      );
      break;
    case "select_tool":
      store.canvas.removeEventListener("pointerdown", onPointerDownSelect);
      store.canvas.removeEventListener("pointerup", onPointerUpSelect);
      store.canvas.removeEventListener("pointermove", onPointerMoveSelect);
      store.canvas.removeEventListener("dblclick", onPointerDoubleClick);
      store.canvas.removeEventListener("mouseleave", onPointerLeaveSelect);
      break;
    case "sketch":
      store.canvas.removeEventListener(
        "pointerdown",
        sketchOperation.onPointerDown
      );
      store.canvas.removeEventListener(
        "pointerup",
        sketchOperation.onPointerUp
      );
      store.canvas.removeEventListener(
        "pointermove",
        sketchOperation.onPointerMove
      );
      ScopeUtils.hideSketchTool();
      // store.canvas.removeEventListener("pointerdown", onSketchDetectDown);
      break;
    case "erase":
      store.canvas.removeEventListener(
        "pointerdown",
        eraseoperation.onPointerDownErase
      );
      store.canvas.removeEventListener(
        "pointerup",
        eraseoperation.onPointerUpErase
      );
      store.canvas.removeEventListener(
        "pointermove",
        eraseoperation.onPointerMoveErase
      );
      break;
    case "measureMesh":
      store.canvas.removeEventListener(
        "pointerdown",
        measureFunction.onPointerDown
      );
      store.canvas.removeEventListener(
        "pointerup",
        measureFunction.onPointerUp
      );
      store.canvas.removeEventListener(
        "pointermove",
        measureFunction.onPointerMove
      );
      measureFunction.cleanUp();
      break;
    case "clipping":
      store.canvas.removeEventListener(
        "pointerdown",
        clipOperations.onPointerDown
      );
      store.canvas.removeEventListener("pointerup", clipOperations.onPointerUp);
      store.canvas.removeEventListener(
        "pointermove",
        clipOperations.onPointerMove
      );
      break;
    case "rotateMesh":
      RotateOperation._reset();
      store.canvas.removeEventListener(
        "pointerdown",
        RotateOperation.onPointerDown,
        false
      );
      store.canvas.removeEventListener(
        "pointerup",
        RotateOperation.onPointerUp,
        false
      );
      store.canvas.removeEventListener(
        "pointermove",
        RotateOperation.onPointerMove,
        false
      );
      break;
    case "scaleMesh":
      ScaleOperation._reset();
      store.canvas.removeEventListener(
        "pointerdown",
        ScaleOperation.onPointerDown,
        false
      );
      store.canvas.removeEventListener(
        "pointerup",
        ScaleOperation.onPointerUp,
        false
      );
      store.canvas.removeEventListener(
        "pointermove",
        ScaleOperation.onPointerMove,
        false
      );
      break;
    // case 'move_face':
    //     store.canvas.removeEventListener("pointerdown", onPointerDownMoveFace);
    //     store.canvas.removeEventListener("pointerup", onPointerUpMoveFace);
    //     store.canvas.removeEventListener("pointermove", onPointerMoveMoveFace);
    //     break;
    case "extrude":
      store.canvas.removeEventListener("pointerdown", onPointerDownExtrude);
      store.canvas.removeEventListener("pointerup", onPointerUpExtrude);
      store.canvas.removeEventListener("pointermove", onPointerMoveExtrude);
      break;
    // case 'mat_mode':
    //     store.canvas.removeEventListener("pointerdown", onMatPointerDown, false);
    //     store.canvas.removeEventListener("pointerup", onMatPointerUp, false);
    //     store.canvas.removeEventListener("pointermove", onMatPointerMove, false);
    //     break;
    case "drawingMode":
      drawingOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        drawingOperator.onPointerDown
      );
      store.canvas.removeEventListener(
        "pointerup",
        drawingOperator.onPointerUp
      );
      store.canvas.removeEventListener(
        "pointermove",
        drawingOperator.onPointerMove
      );
      break;
    case "circleDrawingMode":
      drawCylinder.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        drawCylinder.onPointerDown
      );
      store.canvas.removeEventListener("pointerup", drawCylinder.onPointerUp);
      store.canvas.removeEventListener(
        "pointermove",
        drawCylinder.onPointerMove
      );
      break;
    case "splitFace":
      splitFaceOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        splitFaceOperator.eventHandler
      );
      store.canvas.removeEventListener(
        "pointerup",
        splitFaceOperator.eventHandler
      );
      store.canvas.removeEventListener(
        "pointermove",
        splitFaceOperator.eventHandler
      );
      break;
    case "removeElements":
      removeElementsOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        removeElementsOperator.onPointerDown
      );
      store.canvas.removeEventListener(
        "pointermove",
        removeElementsOperator.onPointerMove
      );
      break;
    case "editPolygon":
      editPolygonOperator.cleanUp();
      store.canvas.removeEventListener(
        "pointerdown",
        editPolygonOperator.eventHandler
      );
      store.canvas.removeEventListener(
        "pointerup",
        editPolygonOperator.eventHandler
      );
      store.canvas.removeEventListener(
        "pointermove",
        editPolygonOperator.eventHandler
      );
      break;
    case "doorOperation":
      doorOperation._reset();
      store.canvas.removeEventListener(
        "pointerdown",
        doorOperation.onPointerDown,
        false
      );
      store.canvas.removeEventListener(
        "pointerup",
        doorOperation.onPointerUp,
        false
      );
      store.canvas.removeEventListener(
        "pointermove",
        doorOperation.onPointerMove,
        false
      );
      break;
    case "windowOperation":
      windowOperation._reset();
      store.canvas.removeEventListener(
        "pointerdown",
        windowOperation.onPointerDown,
        false
      );
      store.canvas.removeEventListener(
        "pointerup",
        windowOperation.onPointerUp,
        false
      );
      store.canvas.removeEventListener(
        "pointermove",
        windowOperation.onPointerMove,
        false
      );
      break;
    case "furnitureOperation":
      furnitureOperation._reset();
      store.canvas.removeEventListener(
        "pointerdown",
        furnitureOperation.onPointerDown,
        false
      );
      store.canvas.removeEventListener(
        "pointerup",
        furnitureOperation.onPointerUp,
        false
      );
      store.canvas.removeEventListener(
        "pointermove",
        furnitureOperation.onPointerMove,
        false
      );
      break;
    case "mat_mode":
      store.canvas.removeEventListener("pointerdown", onMatEditPointerDown);
      MaterialEditor.disable();
      // store.canvas.removeEventListener("pointerup", onMatEditPointerUp);
      // store.canvas.removeEventListener("pointermove", onMatEditPointerMove);
      break;
    case "onMat":
      store.canvas.removeEventListener("pointerdown", onMatPointerDown);
      store.canvas.removeEventListener("pointerup", onMatPointerUp);
      store.canvas.removeEventListener("pointermove", onMatPointerMove);
      break;
    case "delete_mat":
      store.canvas.removeEventListener("pointerdown", onMatDeletePointerDown);
      store.canvas.removeEventListener("dblclick", onMatDeleteDoubleClick);
      break;
    case "onDetectDownHead":
      store.canvas.removeEventListener("pointerdown", onDetectDownHead);
      break;
    case "set-scale":
      setScaleOperation._reset();
      break;
    case "showDoors":
      store.canvas.removeEventListener("pointerdown", showDoors);
      break;
    case "staircase":
      staircaseOperation.reset();
      store.canvas.removeEventListener(
        "pointerdown",
        staircaseOperation.onPointerDown
      );
      store.canvas.removeEventListener(
        "pointerup",
        staircaseOperation.onPointerUp
      );
      store.canvas.removeEventListener(
        "pointermove",
        staircaseOperation.onPointerMove
      );
      break;
    case "detectMass":
      store.canvas.removeEventListener("pointerdown", onDetectDownHead);
      break;
    /* eslint-disable */
    case "RoomsWithHoles":
      store.canvas.removeEventListener("pointerdown", onDetectDownHeadForHoles);
      break;

    case "setStoreyHeight":
      store.canvas.removeEventListener("pointerdown", onSetStoreyDown);
      break;

    case "zoomInOnClick":
      window.document.body.style.cursor = "default";
      store.canvas.removeEventListener("pointerup", callScrollZoom, false);
      break;

    case "zoomOutOnClick":
      window.document.body.style.cursor = "default";
      store.canvas.removeEventListener("pointerup", callScrollZoom, false);
      break;

    case "panMode":
      window.document.body.style.cursor = "default";
      // cameraController.deactivatePanMode();
      panOperator.setPanExclusive(false);
      break;

    case "orbitMode":
      window.document.body.style.cursor = "default";
      cameraController.deactivateOrbitMode();
      break;

    case "commentMode":
      disableOnCommentMode();
      break;
    case "latlongevent":
      store.canvas.removeEventListener(
        "pointerup",
        latlongOperator.onPointerUp,
        false
      )

    /* eslint-enable */

    // case 'doorDraw':
    //     onDoorDrawingMode();
    //     break;
    // case 'windowDraw':
    //     onWindowDrawingMode();
    //     break;
    // case 'stairDraw':
    //     onStairDrawingMode();
    //     break;
    // case 'curtainDraw':
    //     onCurtainMode();
    //     break;
  }

  DisplayOperation.removeDimensions();
  //labelView.resetOperation();
  // store.canvas.removeEventListener("pointerdown", onDetectDown);

  if (store.matEditMenu) {
    store.matEditMenu.dispose();
    store.matEditMenu = null;
  }
  var ground = store.newScene.getMeshByName("ground1");
  ground.isPickable = false;
  // global_tape = false;
  // if(!matEventsActivationFlag){

  // store.angular.element(function () {
  //   let $scope = getScope("AppCtrl");

  //   $scope.editMat = false;
  //   $scope.delMat = false;
  // });

  store.panMode = false;
  cameraController.deactivateOrbitMode();
  store.idle = false;
  document.body.style.cursor = "default";

  if (ACTIVE_OBJECT.hasOwnProperty("active")) {
    console.log(ACTIVE_OBJECT);
    var operation = ACTIVE_OBJECT.active;
    operation.resetOperation();
    store.canvas.removeEventListener(
      "pointerdown",
      operation.onPointerDown,
      false
    );
    store.canvas.removeEventListener("pointerup", operation.onPointerUp, false);
    store.canvas.removeEventListener(
      "pointermove",
      operation.onPointerMove,
      false
    );
    store.canvas.removeEventListener(
      "dblclick",
      operation.onGroupSelection,
      false
    );
    disposeBackWall();
    delete ACTIVE_OBJECT.active;
  }

  if (store.scene.getMeshByName("sectionPlane"))
    store.scene.getMeshByName("sectionPlane").dispose();

  // store.canvas.removeEventListener("pointerdown", onStairDrawDown);
  // store.canvas.removeEventListener("pointerup", onStairDrawUp);
  // store.canvas.removeEventListener("pointermove", onStairDrawMove);
  //
  // store.canvas.removeEventListener("pointerdown", onCurtainDrawDown);
  // store.canvas.removeEventListener("pointerup", onCurtainDrawUp);
  // store.canvas.removeEventListener("pointermove", onCurtainDrawMove);
  //
  // store.canvas.removeEventListener("pointerdown", onDoorDrawDown);
  // store.canvas.removeEventListener("pointerup", onDoorDrawUp);
  // store.canvas.removeEventListener("pointermove", onDoorDrawMove);
  //
  // store.canvas.removeEventListener("pointerdown", onWindowDrawDown);
  // store.canvas.removeEventListener("pointerup", onWindowDrawUp);
  // store.canvas.removeEventListener("pointermove", onWindowDrawMove);

  //resetEdgeModEvents();
  // store.canvas.removeEventListener("pointerdown", onEdgeDrawDown);
  // store.canvas.removeEventListener("pointerup", onEdgeDrawUp);
  // store.canvas.removeEventListener("pointermove", onEdgeDrawMove);

  //resetVertModEvents();
  // store.canvas.removeEventListener("pointerdown", onVertDrawDown);
  // store.canvas.removeEventListener("pointerup", onVertDrawUp);
  // store.canvas.removeEventListener("pointermove", onVertDrawMove);

  // doorOperation.doorMeshName = null;

  // store.canvas.removeEventListener("pointerdown", onScaleMeshDown);
  // store.canvas.removeEventListener("pointerup", onScaleMeshUp);
  // store.canvas.removeEventListener("pointermove", onScaleMeshMove);

  // store.canvas.removeEventListener("pointerdown", onScaleMeshDown);
  // store.canvas.removeEventListener("pointerup", onScaleMeshUp);
  // store.canvas.removeEventListener("pointermove", onScaleMeshMove);

  // }
  // matEventsActivationFlag = false;
}

function onDownload() {
  // var serializedScene = serializeScene();
  var serializedScene = serializeScene();
  var strScene = JSON.stringify(serializedScene);
  send_scene_serialize_data(strScene);
}

function onDownloadSVG(storeyValue) {
  let svg = '<svg xmlns="http://www.w3.org/2000/svg">';
  //let newPlane = BABYLON.Mesh.CreatePlane("clipPlane", 10000, store.newScene);
  let writeTOSVG = "";
  let final = "";

  for (var i = 0; i < store.scene.meshes.length; i++) {
    let mesh = store.scene.meshes[i];
    if (nonDefaultMeshForSnapping(mesh)) {
      var points = [];
      var worldMatrix = store.scene.meshes[i].getWorldMatrix();
      // brepInfo = mesh.getSnaptrudeDS().brep;
      var transformMatrix = store.scene.getTransformMatrix();
      var viewport = store.scene.activeCamera.viewport.toGlobal(
        store.engine.getRenderWidth(),
        store.engine.getRenderHeight()
      );
      var positions = JSON.parse(
        JSON.stringify(
          store.scene.meshes[i].getVerticesData(
            BABYLON.VertexBuffer.PositionKind
          )
        )
      );
      // var brepPOS = brepInfo.positions;
      var numberOfVertices = positions.length / 3;

      for (var j = 0; j < numberOfVertices; j++) {
        var pointsPos = new BABYLON.Vector3(
          positions[j * 3],
          positions[j * 3 + 1],
          positions[j * 3 + 2]
        );
        pointsPos = BABYLON.Vector3.TransformCoordinates(
          pointsPos,
          worldMatrix
        );
        if (
          Math.round(pointsPos.y) ==
          Math.round(store.storeysDS[storeyValue - 1].low)
        ) {
          var coordinates = BABYLON.Vector3.Project(
            pointsPos,
            BABYLON.Matrix.Identity(),
            transformMatrix,
            viewport
          );
          points.push(coordinates);
          // console.log("points",points);
        }
      }

      var tidiedPoints =
        mesh.name.toLowerCase() !== "wall" ? points : convexHull(points);
      //var tidiedPoints = points;

      for (let j = 0; j < tidiedPoints.length; j++) {
        var newLine = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "line"
        );
        // newLine.setAttribute('id', j + '-' + i);
        if (j == 0) {
          newLine.setAttribute(
            "x1",
            tidiedPoints[tidiedPoints.length - 1].x / 2
          );
          newLine.setAttribute(
            "y1",
            tidiedPoints[tidiedPoints.length - 1].y / 2
          );
        } else {
          newLine.setAttribute("x1", tidiedPoints[j - 1].x / 2);
          newLine.setAttribute("y1", tidiedPoints[j - 1].y / 2);
        }
        newLine.setAttribute("x2", tidiedPoints[j].x / 2);
        newLine.setAttribute("y2", tidiedPoints[j].y / 2);
        newLine.setAttribute("style", "stroke:black;stroke-width:1");
        svg += newLine.outerHTML;
      }
    }
  }
  svg += "</svg>";
  console.log(svg);
  DownloadSVG(svg, storeyValue);
}
function DownloadSVG(text, storeyValue) {
  var content = text;
  var filename = store.floorkey + "_storey_" + storeyValue + ".svg";

  var blob = new Blob([content], {
    type: "text/plain;charset=utf-8",
  });

  store.saveAs(blob, filename);
}
function convexHull(points) {
  points.sort(function (a, b) {
    return a.x != b.x ? a.x - b.x : a.y - b.y;
  });
  var n = points.length;
  var hull = [];
  for (var i = 0; i < 2 * n; i++) {
    var j = i < n ? i : 2 * n - 1 - i;
    while (
      hull.length >= 2 &&
      removeMiddle(hull[hull.length - 2], hull[hull.length - 1], points[j])
    )
      hull.pop();
    hull.push(points[j]);
  }
  hull.pop();
  return hull;
}

function removeMiddle(a, b, c) {
  var cross = (a.x - b.x) * (c.y - b.y) - (a.y - b.y) * (c.x - b.x);
  var dot = (a.x - b.x) * (c.x - b.x) + (a.y - b.y) * (c.y - b.y);
  return cross < 0 || (cross == 0 && dot <= 0);
}

function onDownloadFBX(filename) {
  var serializedScene = serializeScene();
  var strScene = JSON.stringify(serializedScene);
  exportModel(strScene, filename, "fbx");
  // send_scene_serialize_data_for_download_fbx(strScene, filename);
}

function onDownloadOBJ(filename) {
  var serializedScene = serializeScene();
  var strScene = JSON.stringify(serializedScene);
  exportModel(strScene, filename, "obj");
  // send_scene_serialize_data_for_download_obj(strScene, filename);
}

function onDownload3DS(filename) {
  var serializedScene = serializeScene();
  var strScene = JSON.stringify(serializedScene);
  exportModel(strScene, filename, "3ds");
  // send_scene_serialize_data_for_download_3ds(strScene, filename);
}

async function onDownloadStandard(filename, type) {
  const fakeProgressEvent = new FakeProgressEvent(0, 5, "Exporting...",
    5, "exportProgress", updateExportProgressBar);
  fakeProgressEvent.start();
  const serializedScene = await serializeSceneAsync();
  const strScene = JSON.stringify(serializedScene);
  await exportModel(strScene, filename, type);
}

function onDownloadRVT(fileName) {
  ForgeConnection.revitExport(OUTPUT_FORMATS.rvt);
}

function onDownloadDWG(fileName) {
  ForgeConnection.revitExport(OUTPUT_FORMATS.dwg);
}

function onDownloadIFC(fileName) {
  ForgeConnection.revitExport(OUTPUT_FORMATS.ifc);
}

function onDownloadPDF(fileName) {
  ForgeConnection.revitExport(OUTPUT_FORMATS.pdf);
}

function onDownloadTrude(fileName) {
  exportTrude(fileName);
}

function onRenderCycles(resolution, skyIndex) {
  var serializedScene = serializeScene();
  var strScene = JSON.stringify(serializedScene);
  // var sky = 3;
  // var resolution = 5;
  getRenderAPI(strScene, resolution, skyIndex);
}

function serializeSceneAsync() {
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;

  return new Promise((resolve, reject) => {
    for (let i = 0; i < store.scene.meshes.length; i++) {
      if (store.scene.meshes[i].type) {
        if (isThrowAwayIdentifier(store.scene.meshes[i].type)) {
          store.scene.meshes[i].metadata = {
            snapType: store.scene.meshes[i].type,
          };
        }
        if (store.scene.meshes[i].parent) {
          store.scene.meshes[i]._prevParent =
            store.scene.meshes[i].parent.uniqueId;
          store.scene.meshes[i].setParent(null);
          store.scene.meshes[i].computeWorldMatrix(true);
        }
      }
    }
    // for (let i = 0; i < store.scene.meshes.length; i++) {
    //     if (scene.meshes[i].type !== undefined) {
    //         //console.log(scene.meshes[i].name);
    //         if (['wall', 'floor'].includes(scene.meshes[i].type.toLowerCase())) {
    //             let verData = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.PositionKind);
    //             let verDataArr = [];
    //             for (let j = 0; j < verData.length; j += 3) {
    //                 let vec = new BABYLON.Vector3(verData[j], verData[j + 1], verData[j + 2]);
    //                 vec = BABYLON.Vector3.TransformCoordinates(vec, store.scene.meshes[i].getWorldMatrix());
    //                 // vec = new BABYLON.Vector3(vec.x, vec.y, vec.z);
    //                 verDataArr.push(vec.x, vec.y, vec.z);
    //             }
    //             let vertexDataPrev = new BABYLON.VertexData();
    //             vertexDataPrev.positions = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.PositionKind);
    //             vertexDataPrev.indices = store.scene.meshes[i].getIndices();
    //             vertexDataPrev.normals = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.NormalKind);
    //             vertexDataPrev.uvs = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.UVKind);
    //
    //             let vertexData = new BABYLON.VertexData();
    //             vertexData.positions = verDataArr;
    //             vertexData.indices = store.scene.meshes[i].getIndices();
    //             vertexData.normals = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.NormalKind);
    //             vertexData.uvs = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.UVKind);
    //
    //             vertexData.applyToMesh(scene.meshes[i], true);
    //             store.scene.meshes[i].vertexDataPrev = vertexDataPrev;
    //         }
    //     }
    // }
    var serializedScene = BABYLON.SceneSerializer.Serialize(store.scene);
    serializedScene.geometries.editor_scale = store.editor_scale / 0.254;
    serializedScene.geometries.floor_height =
      store.floor_height / store.unit_scale;
    /* AG-RE: ANGULAR REFERENCE */
    // serializedScene.geometries.mass_mode = $("#mass_mode").prop("checked");
    serializedScene.geometries.mass_mode = false;
    serializedScene.unit_scale = store.unit_scale;
    serializedScene.unit_absolute_scale = store.unit_absolute_scale;
    serializedScene.levels = store.$scope.selectedItemLevelHeight;
    serializedScene.fplan_mode = store.fplan_mode;
    // for (let i = 0; i < store.scene.meshes.length; i++) {
    //     if (scene.meshes[i].type) {
    //         if (scene.meshes[i].type) {
    //             if (['wall', 'floor'].includes(scene.meshes[i].type.toLowerCase())) {
    //                 if (scene.meshes[i].vertexDataPrev) {
    //                     store.scene.meshes[i].vertexDataPrev.applyToMesh(scene.meshes[i], true);
    //                 }
    //             }
    //         }
    //     }
    // }

    for (var p = 0; p < serializedScene.meshes.length; p++) {
      if (serializedScene.meshes[p].type) {
        if (serializedScene.meshes[p].type.toLowerCase() === "mass") {
          serializedScene.meshes[p].name = "mass";
          serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
        }
      }
    }
    // for (var i = 0; i < serializedScene.materials.length; i++) {
    //   if (serializedScene.materials[i].diffuseTexture) {
    //     //console.log(serializedScene.materials[i].diffuseTexture.url)
    //   }
    // }
    for (let i = 0; i < store.scene.meshes.length; i++) {
      if (store.scene.meshes[i].type) {
        if (store.scene.meshes[i]._prevParent) {
          store.scene.meshes[i].setParent(
            store.scene.getMeshByUniqueID(store.scene.meshes[i]._prevParent)
          );
          store.scene.meshes[i].computeWorldMatrix(true);
        }
      }
    }

    resolve(serializedScene);
  });
}

function serializeScene() {
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;

  for (let i = 0; i < store.scene.meshes.length; i++) {
    if (store.scene.meshes[i].type) {
      if (isThrowAwayIdentifier(store.scene.meshes[i].type)) {
        store.scene.meshes[i].metadata = {
          snapType: store.scene.meshes[i].type,
        };
      }
      if (store.scene.meshes[i].parent) {
        store.scene.meshes[i]._prevParent =
          store.scene.meshes[i].parent.uniqueId;
        store.scene.meshes[i].setParent(null);
        store.scene.meshes[i].computeWorldMatrix(true);
      }
    }
  }
  // for (let i = 0; i < store.scene.meshes.length; i++) {
  //     if (scene.meshes[i].type !== undefined) {
  //         //console.log(scene.meshes[i].name);
  //         if (['wall', 'floor'].includes(scene.meshes[i].type.toLowerCase())) {
  //             let verData = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.PositionKind);
  //             let verDataArr = [];
  //             for (let j = 0; j < verData.length; j += 3) {
  //                 let vec = new BABYLON.Vector3(verData[j], verData[j + 1], verData[j + 2]);
  //                 vec = BABYLON.Vector3.TransformCoordinates(vec, store.scene.meshes[i].getWorldMatrix());
  //                 // vec = new BABYLON.Vector3(vec.x, vec.y, vec.z);
  //                 verDataArr.push(vec.x, vec.y, vec.z);
  //             }
  //             let vertexDataPrev = new BABYLON.VertexData();
  //             vertexDataPrev.positions = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.PositionKind);
  //             vertexDataPrev.indices = store.scene.meshes[i].getIndices();
  //             vertexDataPrev.normals = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.NormalKind);
  //             vertexDataPrev.uvs = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.UVKind);
  //
  //             let vertexData = new BABYLON.VertexData();
  //             vertexData.positions = verDataArr;
  //             vertexData.indices = store.scene.meshes[i].getIndices();
  //             vertexData.normals = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.NormalKind);
  //             vertexData.uvs = store.scene.meshes[i].getVerticesData(BABYLON.VertexBuffer.UVKind);
  //
  //             vertexData.applyToMesh(scene.meshes[i], true);
  //             store.scene.meshes[i].vertexDataPrev = vertexDataPrev;
  //         }
  //     }
  // }
  var serializedScene = BABYLON.SceneSerializer.Serialize(store.scene);
  serializedScene.geometries.editor_scale = store.editor_scale / 0.254;
  serializedScene.geometries.floor_height =
    store.floor_height / store.unit_scale;
  /* AG-RE: ANGULAR REFERENCE */
  // serializedScene.geometries.mass_mode = $("#mass_mode").prop("checked");
  serializedScene.geometries.mass_mode = false;
  serializedScene.unit_scale = store.unit_scale;
  serializedScene.unit_absolute_scale = store.unit_absolute_scale;
  serializedScene.levels = store.$scope.selectedItemLevelHeight;
  serializedScene.fplan_mode = store.fplan_mode;
  // for (let i = 0; i < store.scene.meshes.length; i++) {
  //     if (scene.meshes[i].type) {
  //         if (scene.meshes[i].type) {
  //             if (['wall', 'floor'].includes(scene.meshes[i].type.toLowerCase())) {
  //                 if (scene.meshes[i].vertexDataPrev) {
  //                     store.scene.meshes[i].vertexDataPrev.applyToMesh(scene.meshes[i], true);
  //                 }
  //             }
  //         }
  //     }
  // }

  for (var p = 0; p < serializedScene.meshes.length; p++) {
    if (serializedScene.meshes[p].type) {
      if (serializedScene.meshes[p].type.toLowerCase() === "mass") {
        serializedScene.meshes[p].name = "mass";
        serializedScene.meshes[p].room_type = store.scene.meshes[p].room_type;
      }
    }
  }
  for (var i = 0; i < serializedScene.materials.length; i++) {
    if (serializedScene.materials[i].diffuseTexture) {
      //console.log(serializedScene.materials[i].diffuseTexture.url)
    }
  }
  for (let i = 0; i < store.scene.meshes.length; i++) {
    if (store.scene.meshes[i].type) {
      if (store.scene.meshes[i]._prevParent) {
        store.scene.meshes[i].setParent(
          store.scene.getMeshByUniqueID(store.scene.meshes[i]._prevParent)
        );
        store.scene.meshes[i].computeWorldMatrix(true);
      }
    }
  }

  return serializedScene;
}

function onDrawingMode() {
  console.log("onDrawingMode");
  var ground = store.newScene.getMeshByName("ground1");
  removeMouseEvents();

  ground.isPickable = true;
  store.canvas.addEventListener(
    "pointerdown",
    drawingOperator.onPointerDown,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    drawingOperator.onPointerUp,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    drawingOperator.onPointerMove,
    false
  );

  store.ACTIVE_EVENT.event = "drawingMode";
}

function onCircleDrawingMode() {
  console.log("onCircleDrawingMode");
  var ground = store.newScene.getMeshByName("ground1");
  removeMouseEvents();

  ground.isPickable = true;
  store.canvas.addEventListener(
    "pointerdown",
    drawCylinder.onPointerDown,
    false
  );
  store.canvas.addEventListener("pointerup", drawCylinder.onPointerUp, false);
  store.canvas.addEventListener(
    "pointermove",
    drawCylinder.onPointerMove,
    false
  );

  store.ACTIVE_EVENT.event = "circleDrawingMode";
}

function onRemoveElementsMode(type) {
  removeMouseEvents();

  // if (type === "edge") updateButtonColor("removeEdge");
  // updateButtonColor("removeElements");
  // if (
  //   document.getElementById("removeElements").style.backgroundColor == "orange"
  // ) {
  store.canvas.addEventListener(
    "pointerdown",
    removeElementsOperator.onPointerDown,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    removeElementsOperator.onPointerMove,
    false
  );
  if (store.isiPad) {
    store.canvas.addEventListener("pointerup", onPointerUpRotate, false);
  }
  // } else {
  //   onCamRot();
  // }
  store.ACTIVE_EVENT.event = "removeElements";
}

function onSplitFaceMode() {
  removeMouseEvents();

  // if (type === "edge") updateButtonColor("removeEdge");
  // updateButtonColor("splitFace");
  // if (document.getElementById("splitFace").style.backgroundColor == "orange") {
  store.canvas.addEventListener(
    "pointerdown",
    splitFaceOperator.eventHandler,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    splitFaceOperator.eventHandler,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    splitFaceOperator.eventHandler,
    false
  );

  splitFaceOperator.setStateMachineEvents();

  store.ACTIVE_EVENT.event = "splitFace";
}

function detectMassAddEvent() {
  // curves = false;
  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onDetectDownHead, false);
  // updateButtonColor("Create Mass");
  store.ACTIVE_EVENT = { event: "onDetectDownHead" };
}

function detectCurvedMassAddEvent() {
  // curves = true;
  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onDetectDownHead, false);
  updateButtonColor("Create Curved Mass");
  store.ACTIVE_EVENT = { event: "onDetectDownHead" };
}

function onEditPolygon() {
  removeMouseEvents();

  // if (type === "edge") updateButtonColor("removeEdge");
  // updateButtonColor("editPolygon");
  // if (
  //   document.getElementById("editPolygon").style.backgroundColor === "orange"
  // ) {
  store.canvas.addEventListener(
    "pointerdown",
    editPolygonOperator.eventHandler,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    editPolygonOperator.eventHandler,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    editPolygonOperator.eventHandler,
    false
  );
  // } else {
  //   onCamRot();
  // }
  store.ACTIVE_EVENT.event = "editPolygon";
}

function onWindowDrawingMode() {
  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onWindowDrawDown, false);
  store.canvas.addEventListener("pointerup", onWindowDrawUp, false);
  store.canvas.addEventListener("pointermove", onWindowDrawMove, false);
}

function onStairDrawingMode() {
  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onStairDrawDown, false);
  store.canvas.addEventListener("pointerup", onStairDrawUp, false);
  store.canvas.addEventListener("pointermove", onStairDrawMove, false);
}

function onCurtainMode() {
  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onCurtainDrawDown, false);
  store.canvas.addEventListener("pointerup", onCurtainDrawUp, false);
  store.canvas.addEventListener("pointermove", onCurtainDrawMove, false);
}

function onDoorDrawingMode() {
  window.analytics.track("drawing door");
  NewLogger.logAction("drawing door");
  removeMouseEvents();
  store.canvas.addEventListener("pointerdown", onDoorDrawDown, false);
  store.canvas.addEventListener("pointerup", onDoorDrawUp, false);
  store.canvas.addEventListener("pointermove", onDoorDrawMove, false);
}

function clipEvent() {
  updateButtonColor("clipping");
  removeMouseEvents();
  //  let sizeData = createGroupBoundingBox() ;
  store.canvas.addEventListener(
    "pointerdown",
    clipOperations.onPointerDown,
    false
  );
  store.canvas.addEventListener("pointerup", clipOperations.onPointerUp, false);
  store.canvas.addEventListener(
    "pointermove",
    clipOperations.onPointerMove,
    false
  );
}

function sketchEvent() {
  if (store.$scope.isTwoDimension && (store.isiPad || store.isMobile)) {
    updateButtonColor("sketch");
    removeMouseEvents();
    ScopeUtils.showSketchTool();
    // if (!activeLayer) {
    //     initSketchPlan();
    // }
    store.canvas.addEventListener(
      "pointerdown",
      sketchOperation.onPointerDown,
      false
    );
    store.canvas.addEventListener(
      "pointerup",
      sketchOperation.onPointerUp,
      false
    );
    store.canvas.addEventListener(
      "pointermove",
      sketchOperation.onPointerMove,
      false
    );
  }
  // ACTIVE_OBJECT.active = sketchoperation;
}
function eraseEvent() {
  updateButtonColor("erase");
  removeMouseEvents();
  //eraseoperation.breakLinesForErase();
  store.canvas.addEventListener(
    "pointerdown",
    eraseoperation.onPointerDownErase,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    eraseoperation.onPointerUpErase,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    eraseoperation.onPointerMoveErase,
    false
  );
}

function setScaleEvent() {
  // updateButtonColor("set-scale");
  removeMouseEvents();
  for (let i = 0; i < store.selectionStack.length; i++) {
    removeSelectionBox(store.selectionStack[i]);
  }
  store.selectionStack.length = 0;
  setScaleOperation.createScale("meshScale", store.activeLayer.storey, 1);
  store.ACTIVE_EVENT = { event: "set-scale" };
}

function measureMeshEvent(tape) {
  // updateButtonColor("measureMesh");
  var count = 0;
  let mPt2 = null;
  let mPt1 = null;
  let downClick = 0;
  let pickType = null;
  // store.newScene.activeCamera.detachControl(canvas);
  removeMouseEvents();
  store.canvas.addEventListener(
    "pointerdown",
    measureFunction.onPointerDown,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    measureFunction.onPointerUp,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    measureFunction.onPointerMove,
    false
  );
  store.ACTIVE_EVENT = { event: "measureMesh" };
}
function angleMeasureEvent() {
  // updateButtonColor("angleMeasure");
  // var count = 0;
  // mPt2 = null;
  // mPt1 = null;
  // downClick = 0;
  // pickType = null;
  // store.newScene.activeCamera.detachControl(canvas);
  removeMouseEvents();
  store.canvas.addEventListener(
    "pointerdown",
    angleMeasure.onPointerDown,
    false
  );
  store.canvas.addEventListener("pointerup", angleMeasure.onPointerUp, false);
  store.canvas.addEventListener(
    "pointermove",
    angleMeasure.onPointerMove,
    false
  );
  store.ACTIVE_EVENT = { event: "angleMeasure" };
}

function onExplorerCam() {
  // updateButtonColor("explorerCam");
  removeMouseEvents();
  document.body.style.cursor = "crosshair";
  store.canvas.addEventListener(
    "pointerdown",
    explorerCam.onPointerDown,
    false
  );
  store.canvas.addEventListener("pointerup", explorerCam.onPointerUp, false);
  store.canvas.addEventListener(
    "pointermove",
    explorerCam.onPointerMove,
    false
  );
  store.ACTIVE_EVENT = { event: "explorerCam" };
}

function rotateMeshEvent() {
  // updateButtonColor("rotateMesh");
  var count = 0;
  // store.newScene.activeCamera.detachControl(canvas);
  removeMouseEvents();
  const selection = Locker.filterLockedMeshes(store.selectionStack);
  RotateOperation.assignStackArray(Object.assign([], selection));
  // store.selectionStack.forEach((m) => (m.state = "off"));
  // store.selectionStack.length = 0;
  store.canvas.addEventListener(
    "pointerdown",
    RotateOperation.onPointerDown,
    false
  );
  store.canvas.addEventListener(
    "pointerup",
    RotateOperation.onPointerUp,
    false
  );
  store.canvas.addEventListener(
    "pointermove",
    RotateOperation.onPointerMove,
    false
  );
  store.ACTIVE_EVENT.event = "rotateMesh";
}

function scaleMeshEvent() {
  updateButtonColor("scaleMesh");
  var count = 0;
  removeMouseEvents();
  ScaleOperation.assignStackArray(Object.assign([], store.selectionStack));
  store.selectionStack.forEach((m) => (m.state = "off"));
  store.selectionStack.length = 0;
  store.canvas.addEventListener(
    "pointerdown",
    ScaleOperation.onPointerDown,
    false
  );
  store.canvas.addEventListener("pointerup", ScaleOperation.onPointerUp, false);
  store.canvas.addEventListener(
    "pointermove",
    ScaleOperation.onPointerMove,
    false
  );
}

function onUnion() {
  if (store.selectionStack) {
    store.selectionStack[0].setVerticesData(BABYLON.VertexBuffer.UVKind, []);
    store.selectionStack[1].setVerticesData(BABYLON.VertexBuffer.UVKind, []);
    var aCSG = BABYLON.CSG.FromMesh(store.selectionStack[0]);
    var bCSG = BABYLON.CSG.FromMesh(store.selectionStack[1]);

    var subCSG = aCSG.union(bCSG);
    var mergedMesh = subCSG.toSnaptrudeMesh("csg", null, store.newScene);

    mergedMesh.material = store.scene.getMaterialByName("wall_mat");
    mergedMesh.checkCollisions = true;
    mergedMesh.name = store.selectionStack[0].name;
    mergedMesh.room_type = store.selectionStack[0].type;
    mergedMesh.room_id = store.selectionStack[0].room_id;
    mergedMesh.room_curve = store.selectionStack[0].room_curve;
    mergedMesh.room_path = store.selectionStack[0].room_path;
    mergedMesh.height = store.selectionStack[0].height;
    mergedMesh.level = store.selectionStack[0].level;
    mergedMesh.checkCollisions = true;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    mergedMesh.type = store.selectionStack[0].type;

    /*mergedMesh.structure_id = store.selectionStack[0].getSnaptrudeDS().structure_id;
        mergedMesh.level_id = store.selectionStack[0].getSnaptrudeDS().level_id;

        let structures = StructureCollection.getInstance();
       let str = structures.getStructureById(mergedMesh.structure_id);
       let level = str.getLevelByUniqueId(mergedMesh.level_id);
       level.addMeshToLevel(mergedMesh, false);*/

    // var bbinfo = mergedMesh.getBoundingInfo();
    // var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    // mergedMesh.setPivotPoint(centroid);
    //addMaterialToMeshSolid(mergedMesh, "none");
    // assignProperties(mergedMesh, i, "mass");
    onSolid();
    // Dispose both original meshes
    removeMeshChildren(store.selectionStack[0]);
    removeMeshChildren(store.selectionStack[1]);
    store.selectionStack[0].dispose();
    store.selectionStack[1].dispose();
  }
}

function onIntersect() {
  if (store.selectionStack.length === 2) {
    store.selectionStack[0].setVerticesData(BABYLON.VertexBuffer.UVKind, []);
    store.selectionStack[1].setVerticesData(BABYLON.VertexBuffer.UVKind, []);
    var aCSG = BABYLON.CSG.FromMesh(store.selectionStack[0]);
    var bCSG = BABYLON.CSG.FromMesh(store.selectionStack[1]);

    var subCSG = aCSG.intersect(bCSG);

    // console.log(subCSG);
    /* eslint-disable */
    if (
      store.selectionStack[0].name.indexOf("room") <= 2 &&
      store.selectionStack[0].name.indexOf("room") !== -1
    ) {
    }
    /* eslint-enable */
    var mergedMesh = subCSG.toMesh("csg", null, store.newScene);

    mergedMesh.material = store.scene.getMaterialByName("wall_mat");
    mergedMesh.checkCollisions = true;
    mergedMesh.name = store.selectionStack[0].name;
    mergedMesh.room_type = store.selectionStack[0].type;
    mergedMesh.room_id = store.selectionStack[0].room_id;
    mergedMesh.room_curve = store.selectionStack[0].room_curve;
    mergedMesh.room_path = store.selectionStack[0].room_path;
    mergedMesh.height = store.selectionStack[0].height;
    mergedMesh.level = store.selectionStack[0].level;
    mergedMesh.type = "Mass";
    mergedMesh.checkCollisions = true;
    // mergedMesh.showBoundingBox = store.selectionStack[0].showBoundingBox;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    // mergedMesh.setPivotPoint(centroid);

    // if (room_pos[i][0] !== 0 || room_pos[i][2] !== 0) {
    //     mergedMesh.position.x = room_pos[i][0] * store.unit_scale;
    //     mergedMesh.position.z = room_pos[i][2] * store.unit_scale;
    // }
    // mergedMesh.position.y = obj_height * room_levels[i] + 0.5 * obj_height;
    //
    // mergedMesh.rotation.x = room_rot[i][0];
    // mergedMesh.rotation.y = room_rot[i][1];
    // mergedMesh.rotation.z = room_rot[i][2];
    //
    // mergedMesh.scaling.x = room_scaling[i][0];
    // mergedMesh.scaling.y = room_scaling[i][1];
    // mergedMesh.scaling.z = room_scaling[i][2];
    // mergedMesh.position.x = store.selectionStack[0].position.x;
    // mergedMesh.position.y = store.selectionStack[0].position.y;
    // mergedMesh.position.x = store.selectionStack[0].position.z;
    mergedMesh.convertToFlatShadedMesh();
    //addMaterialToMeshSolid(mergedMesh, "none");
    assignProperties(mergedMesh, -1, "wall");
    mergedMesh.material.backFaceCulling = false;
    onSolid();
    // Dispose both original meshes
    removeMeshChildren(store.selectionStack[0]);
    removeMeshChildren(store.selectionStack[1]);
    store.selectionStack[0].dispose();
    store.selectionStack[1].dispose();
    //console.log(mergedMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind), mergedMesh.getIndices());
  }
}

function onSubtract() {
  if (store.selectionStack) {
    store.selectionStack[0].setVerticesData(BABYLON.VertexBuffer.UVKind, []);
    store.selectionStack[1].setVerticesData(BABYLON.VertexBuffer.UVKind, []);
    var aCSG = BABYLON.CSG.FromMesh(store.selectionStack[0]);
    var bCSG = BABYLON.CSG.FromMesh(store.selectionStack[1]);
    var subCSG = aCSG.subtract(bCSG);

    /*if ((selectionStack[0].name.indexOf("room") <= 2) && (selectionStack[0].name.indexOf("room") !== -1)) {

        }*/

    var mergedMesh = subCSG.toMesh("csg", null, store.newScene);

    mergedMesh.material = store.scene.getMaterialByName("wall_mat");
    mergedMesh.checkCollisions = true;
    mergedMesh.name = store.selectionStack[0].name;
    mergedMesh.room_type = store.selectionStack[0].type;
    mergedMesh.room_id = store.selectionStack[0].room_id;
    mergedMesh.room_curve = store.selectionStack[0].room_curve;
    mergedMesh.room_path = store.selectionStack[0].room_path;
    mergedMesh.height = store.selectionStack[0].height;
    mergedMesh.level = store.selectionStack[0].level;
    mergedMesh.checkCollisions = true;
    mergedMesh.type = "Mass";
    // mergedMesh.showBoundingBox = store.selectionStack[0].showBoundingBox;
    mergedMesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    mergedMesh.enableEdgesRendering();
    var bbinfo = mergedMesh.getBoundingInfo();
    var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
    //  mergedMesh.setPivotPoint(centroid);

    // if (room_pos[i][0] !== 0 || room_pos[i][2] !== 0) {
    //     mergedMesh.position.x = room_pos[i][0] * store.unit_scale;
    //     mergedMesh.position.z = room_pos[i][2] * store.unit_scale;
    // }
    // mergedMesh.position.y = obj_height * room_levels[i] + 0.5 * obj_height;
    //
    // mergedMesh.rotation.x = room_rot[i][0];
    // mergedMesh.rotation.y = room_rot[i][1];
    // mergedMesh.rotation.z = room_rot[i][2];
    //
    // mergedMesh.scaling.x = room_scaling[i][0];
    // mergedMesh.scaling.y = room_scaling[i][1];
    // mergedMesh.scaling.z = room_scaling[i][2];
    // mergedMesh.position.x = store.selectionStack[0].position.x;
    // mergedMesh.position.y = store.selectionStack[0].position.y;
    // mergedMesh.position.z = store.selectionStack[0].position.z;

    //addMaterialToMeshSolid(mergedMesh, "none");
    assignProperties(mergedMesh, -1, "wall");
    mergedMesh.material.backFaceCulling = false;
    onSolid();
    // Dispose both original meshes
    removeMeshChildren(store.selectionStack[0]);
    removeMeshChildren(store.selectionStack[1]);
    store.selectionStack[0].dispose();
    store.selectionStack[1].dispose();
  }
}

function drawBoxes(mesh) {
  mesh.children = [];
  let bbox = mesh.getBoundingInfo();
  let boxes = [];
  for (var i = 0; i < bbox.boundingBox.vectorsWorld.length; i++) {
    var boxSize = 0.05 * bbox.diagonalLength;
    if (boxSize > 0.75) {
      boxSize = 0.75;
    }
    var box = BABYLON.Mesh.CreateBox(
      mesh.name + "boxScale" + i,
      boxSize,
      store.newScene
    );
    box.position = bbox.boundingBox.vectorsWorld[i];
    box.material = store.scene.getMaterialByName("boxm1");
    boxes.push(box);
    mesh.children.push(box);
  }

  // document.getElementById("cw").style.visibility = "visible";
  // document.getElementById("ccw").style.visibility = "visible";
}

function onSelectComponent(name) {
  for (var i = 0; i < store.scene.meshes.length; i++) {
    var mesh = store.scene.meshes[i];
    if (mesh && mesh.type) {
      if (mesh.type.toUpperCase() === name.toUpperCase()) {
        store.selectionStack.push(mesh);
        mesh.state = "on";
        //drawBoxes(mesh);
        drawSelectionBox(mesh);
        // onSelectComponent(mesh);
      }
    }
  }
}

function resetSelections(name) {
  for (var i = 0; i < store.selectionStack.length; ) {
    var mesh = store.selectionStack[i];
    if (mesh.type.toUpperCase() === name.toUpperCase()) {
      store.selectionStack.splice(i, 1);
      mesh.state = "off";
      removeMeshSelectionChildren(mesh);
    } else i++;
  }
  // document.getElementById("cw").style.visibility = "hidden";
  // document.getElementById("ccw").style.visibility = "hidden";
}

function removeMeshChildren(mesh) {
  if (mesh) {
    if (mesh.children) {
      for (var i = 0; i < mesh.children.length; i++) {
        mesh.children[i].dispose();
      }
    }
  }
}

function getScope(ctrlName) {
  var sel = 'div[ng-controller="' + ctrlName + '"]';
  return store.angular.element(sel).scope();
}

function triggerUserPilot(id) {
  // console.warn("AG-RE: called triggerUserPilot");
  // userpilot.trigger(id);
}

function setStoreyOneHeightEvent() {
  removeMouseEvents();
  // updateButtonColor("setStoreyHeight");
  store.canvas.addEventListener("pointerdown", onSetStoreyDown, false);
  store.ACTIVE_EVENT = { event: "setStoreyHeight" };
}


function handleLatLongEvent() {
  removeMouseEvents();
  // updateButtonColor("setStoreyHeight");
  store.canvas.addEventListener("pointerup", latlongOperator.onPointerUp, false);
  store.ACTIVE_EVENT = { event: "latlongevent" };
}

// store.angular.element(function () {
//   var $scope = getScope("AppCtrl");

//   $scope.handleToolbar = function (
//     id,
//     name = "",
//     structureKey,
//     storeyNum = ""
//   ) {
//     if (store.isProduction) {
//       store.window.analytics.track("clicked " + id);
//       NewLogger.logAction("clicked" + id);
//       //console.logInfo("clicked "+id);
//     }

//     if (id !== "escape") {
//       // iPad escape
//       delayedExecutionEngine.executeAll();
//     }

//     // if (LO){
//     //     LO.update_canvas("#canvas");
//     // }
//     let evtType = "";
//     switch (id) {
//       case "moveBlocks":
//         moveBlocks();
//         break;
//       case "arrayFunction":
//         arrayFunction();
//         break;
//       case "select_tool":
//         onSelectTool();
//         break;
//       case "camRot":
//         onCamRot();
//         break;
//       case "pan":
//         onPan();
//         break;
//       case "explorerCam":
//         onExplorerCam();
//         break;
//       case "orthoCam":
//         toggleOrthoView();
//         break;
//       case "toggleShadow":
//         toggleShadows();
//         break;
//       case "undo":
//         evtType = "singleClick";
//         CMUndo();
//         // setTimeout(function(){ updateLevelsAngularUI(); }, 1000);
//         ///setTimeout(assignStoreys, 3100);
//         // setTimeout(function(){ updateLevelsAngularUI(); }, 3000);
//         break;
//       case "redo":
//         evtType = "singleClick";
//         CMRedo();
//         // setTimeout(function(){ updateLevelsAngularUI(); }, 1000);
//         // setTimeout(assignStoreys, 50);
//         // setTimeout(function(){ updateLevelsAngularUI(); }, 3000);
//         break;
//       case "save":
//         evtType = "singleClick";
//         send_complete_scene_data("save");
//         //clientDb.saveCanvasLocally("canvasState"+floorkey);
//         break;
//       case "saveas":
//         evtType = "singleClick";
//         send_complete_scene_data("saveas");
//         //clientDb.saveCanvasLocally("canvasState"+floorkey);
//         break;
//       case "clipping":
//         clipEvent();
//         break;
//       case "sketch":
//         sketchEvent();
//         break;
//       case "erase":
//         eraseEvent();
//         break;
//       case "set-scale":
//         setScaleEvent();
//         break;
//       case "moveMesh":
//         moveMeshEvent();
//         break;
//       case "measureMesh":
//         measureMeshEvent();
//         break;
//       case "angleMeasure":
//         angleMeasureEvent();
//         break;
//       case "rotateMesh":
//         rotateMeshEvent();
//         break;
//       case "scaleMesh":
//         scaleMeshEvent();
//         break;
//       case "solid":
//         onSolid();
//         break;
//       case "noedge":
//         onNoEdge();
//         break;
//       case "wireframe":
//         onWireFrame();
//         break;
//       case "move_face":
//         onFaceMode();
//         break;
//       case "extrude":
//         onExtrude();
//         break;
//       case "mat_mode":
//         onMatEdit();
//         break;
//       case "resetview":
//         goOutOfTwoD();
//         break;
//       case "2dview":
//         goIntoTwoD(false, true);
//         break;
//       case "zoomfit2d":
//         goIntoTwoD("fitImage");
//         break;
//       case "visualize":
//         onVisualize();
//         break;
//       case "download":
//         onDownload();
//         break;
//       case "importProps":
//         evtType = "singleClick";
//         $scope.onImportProps();
//         break;
//       case "importFenestration":
//         evtType = "singleClick";
//         $scope.onImportFenestration();
//         break;
//       case "exportViews":
//         evtType = "singleClick";
//         $scope.onExportViews();
//         break;
//       case "renderView":
//         evtType = "singleClick";
//         $scope.onRenderView();
//         break;
//       case "drawingMode":
//         store.sketchMode = name;
//         onDrawingMode();
//         break;
//       case "splitFace":
//         onSplitFaceMode();
//         break;
//       case "removeElements":
//         onRemoveElementsMode();
//         break;
//       case "editPolygon":
//         onEditPolygon();
//         break;
//       case "doorDraw":
//         onDoorDrawingMode();
//         break;
//       case "windowDraw":
//         onWindowDrawingMode();
//         break;
//       case "stairDraw":
//         onStairDrawingMode();
//         break;
//       case "curtainDraw":
//         onCurtainMode();
//         break;
//       case "topView":
//         changeToTopViewCamera();
//         break;
//       case "orthoView":
//         goIntoTwoD();
//         break;
//       case "orthoStorey":
//         moveCameraToStructure();
//         break;
//       case "frontView":
//         changeToFrontViewCamera();
//         break;
//       case "sectionView":
//         onSectionViewCamera();
//         break;
//       case "showAll":
//         evtType = "singleClick";
//         $scope.showAllObjects();
//         break;
//       case "hideRoofs":
//         evtType = "singleClick";
//         $scope.hideRoofs();
//         break;
//       case "downloadFBX":
//         onDownloadFBX();
//         break;
//       case "downloadOBJ":
//         onDownloadOBJ();
//         break;
//       case "download3DS":
//         onDownload3DS();
//         break;
//       case "unionOp":
//         onUnion();
//         break;
//       case "addFloor":
//         $scope.addAnotherFloor();
//         break;
//       case "intersectOp":
//         onIntersect();
//         break;
//       case "subtractOp":
//         onSubtract();
//         break;
//       case "selectComponents":
//         if ($scope.selectionsObject[name]) onSelectComponent(name);
//         else resetSelections(name);
//         break;
//       case "report_error":
//         store.reportError();
//         break;
//       case "addMaterial":
//         evtType = "singleClick";
//         $scope.onImportMaterial();
//         break;
//       case "gizmo_manager":
//         enableGizmo();
//         break;
//       case "escape":
//         evtType = "singleClick";
//         handleEscapeKeyEvent();
//         break;
//       case "delete":
//         evtType = "singleClick";
//         handleDeleteKeyEvent();
//         break;
//       case "delete_mat":
//         onDeleteMaterial();
//         break;
//       case "addLayer":
//         evtType = "singleClick";
//         layerView.appendNewLayer(name, storeyNum, structureKey);
//         break;
//       case "createBuilding":
//         evtType = "singleClick";
//         store.createBuilding();
//         break;
//       case "selectLayer":
//         clearTimeout(store.storeyClickTimeout);
//         evtType = "singleClick";
//         store.storeyClickTimeout = setTimeout(() => {
//           layerView.selectLayer(name, structureKey, storeyNum);
//           labelView.changeAutoDimButtonStatus(storeyNum);
//         }, store.storeyClickTime);
//         break;
//       case "addAndSelectLayer":
//         evtType = "singleClick";
//         layerView.addAndSelectLayer(name, structureKey, storeyNum);
//         break;
//       case "onAutoInteriors":
//         evtType = "singleClick";
//         /* eslint-disable */
//         let element = name.currentTarget;
//         /* eslint-enable */
//         if (!store.userSettingsInStructure.autoInteriorState) {
//           AutoInterior.on();
//           setUserSettingAndRecord("autoInteriorState", true);
//           $(element).css("border", "1px solid #d30041");
//         } else {
//           /*
//                     AutoSave and UI state change for AutoInterior off
//                     happens in postExecute and postUnExecute callback as
//                     CommandManager executes on click as well as redo!
//                      */
//           AutoInterior.off();
//         }
//         break;
//       case "displayAutoDimensions":
//         evtType = "singleClick";
//         labelView.displayAutoDimensions();
//         break;
//       case "staircase":
//         evtType = "singleClick";
//         onStaircaseTool(name);
//         break;
//       case "detectMass":
//         evtType = "singleClick";
//         detectMassAddEvent();
//         break;
//       case "detectCurvedMass":
//         evtType = "singleClick";
//         detectCurvedMassAddEvent();
//         break;
//       case "setStoreyHeight":
//         evtType = "singleClick";
//         setStoreyOneHeightEvent();
//         break;
//     }

//     if (!(evtType === "singleClick")) store.ACTIVE_EVENT = { event: id };
//   };
// }, 2000);

const exportMap = (options) => {
  const url = terrainGeneration.getHeightMapTile();
  if (!options) {
    options = {
      isHeightMapChecked: false,
      isSatelliteEnabled: false,
      isNeighborhoodChecked: false,
    };
  }
  if(store.terrainMap){
    if(store.terrainMap.getZoom() < 15){
      store.terrainMap.setZoom(15);
    }
  }
  terrainGeneration.getHeightMapGround(url, "2", options);
};

const hideObject = () => {
  let hideObjectCommandName = "Hide Object";

  let i = 0;
  /* AG-RE: call window.analytics.track("hide object clicked"); */
  // console.warn("AG-RE: window.analytics.track('hide object clicked');")
  window.analytics.track("hide object clicked");

  let stack = [];
  while (i < store.selectionStack.length) {
    if (nonDefaultMesh(store.selectionStack[i])) {
      removeChildren(store.selectionStack[i]);
      stack.push(store.selectionStack[i]);
    }
    i++;
  }

  let options = {
    handleChildren: true,
  };

  objectVisibilityUtil(hideObjectCommandName, stack, true, undefined, options);

  store.selectionStack.length = 0;
  DisplayOperation.removeDimensions();
};
const showAllObjects = () => {
  let showAllCommandName = "Show All";

  let stack = [];
  for (let mesh of store.scene.meshes) {
    if (nonDefaultMesh(mesh) && !mesh.isVisible) {
      stack.push(mesh);
    }
  }

  objectVisibilityUtil(showAllCommandName, stack, false);

  store.$scope.hide_object.hide_object_status = false;
};
const isolateObject = () => {
  let isolateObjectCommandName = "Isolate Object";
  let stack = [];

  store.selectionStack.forEach((mesh) => {
    let children = mesh.getChildren();
    store.selectionStack.push(...children);
  });

  store.newScene.meshes.forEach(function (mesh) {
    if (
      nonDefaultMesh(mesh) &&
      mesh.isVisible &&
      !store.selectionStack.inArray((m) => m === mesh)
      && mesh?.type !== 'analysis_grid'
    ) {
      stack.push(mesh);
    }
  });

  objectVisibilityUtil(isolateObjectCommandName, stack, true, true);
};
const hideRoofs = function () {
  store.$scope.hideRoofsSelected = !store.$scope.hideRoofsSelected;
  let showRoofs = !store.$scope.hideRoofsSelected;
  toggleAllRoofsVisibility(showRoofs, true);
};
const toggleAllRoofsVisibility = function (roofShown, userInitiated = false) {
  //console.log("scope",$scope.roofShown, "noemal", roofShown)
  if (roofShown) {
    showAllRoofs(userInitiated);
  } else {
    hideAllRoofs(userInitiated);
  }
};
const callScrollZoom = () => {
  if (store.$scope.isTwoDimension) {
    scrollZoom({
      deltaY: store.ACTIVE_EVENT.event === "zoomInOnClick" ? 25 : -25,
    });
  }
  else {
    scrollZoom({
      deltaY: store.ACTIVE_EVENT.event === "zoomInOnClick" ? -25 : 25,
    });
  }
};
const zoomInOnClick = () => {
  removeMouseEvents();
  if (store.ACTIVE_EVENT.event === "zoomInOnClick") {
    store.ACTIVE_EVENT.event = "";
    return;
  }
  window.document.body.style.cursor = "zoom-in";
  store.canvas.addEventListener("pointerup", callScrollZoom, false);
  store.ACTIVE_EVENT.event = "zoomInOnClick";
};
const zoomOutOnClick = () => {
  removeMouseEvents();
  if (store.ACTIVE_EVENT.event === "zoomOutOnClick") {
    store.ACTIVE_EVENT.event = "";
    return;
  }
  window.document.body.style.cursor = "zoom-out";
  store.canvas.addEventListener("pointerup", callScrollZoom, false);
  store.ACTIVE_EVENT.event = "zoomOutOnClick";
};

const zoomExtentsOnClick = () => {
  if (store.$scope.isTwoDimension) changeToOrthoViewCamera(true, false);
  else changeToIsoViewCamera();
};

const onPanClick = () => {
  removeMouseEvents();
  if (store.ACTIVE_EVENT.event === "panMode") {
    store.ACTIVE_EVENT.event = "";
    cameraController.activateOrbitMode();
    store.idle = true;
    return;
  }
  // window.document.body.style.cursor = "grab";

  panOperator.setPanExclusive(true);
  // cameraController.activatePanMode();
  store.ACTIVE_EVENT.event = "panMode";
};

const onOrbitClick = () => {
  removeMouseEvents();
  if (store.ACTIVE_EVENT.event === "orbitMode") {
    store.ACTIVE_EVENT.event = "";
    cameraController.activateOrbitMode();
    store.idle = true;
    return;
  }
  // window.document.body.style.cursor = "pointer";

  cameraController.activateOrbitMode();
  store.ACTIVE_EVENT.event = "orbitMode";
};

function onCommentMode() {
  removeMouseEvents();
  // document.body.style.cursor = `url(${commentPinInactive}), auto`;
  updateCursor(cursor.comment);

  store.canvas.addEventListener(
    "pointerup",
    commentOperator.onPointerUp,
    false
  );
  store.ACTIVE_EVENT.event = "commentMode";
}
function disableOnCommentMode() {
  document.body.style.cursor = `default`;
  window.dispatchEvent(new CustomEvent("clear-comments-if-not-sidebar-open"))
  store.canvas.removeEventListener(
    "pointerup",
    commentOperator.onPointerUp,
    false
  );
}

export {
  moveBlocks,
  editObject,
  arrayFunction,
  onSelectTool,
  onStaircaseTool,
  onWireFrame,
  onResetView,
  onVisualize,
  onFaceMode,
  onExtrude,
  onPickMaterialFromScene,
  onDeleteMaterial,
  onSelectMultiple,
  initDraw,
  removeMouseEvents,
  onDownload,
  onDownloadSVG,
  DownloadSVG,
  convexHull,
  removeMiddle,
  onDownloadFBX,
  onDownloadOBJ,
  onDownload3DS,
  onDownloadStandard,
  onDownloadRVT,
  onDownloadDWG,
  onDownloadIFC,
  onDownloadPDF,
  onDownloadTrude,
  onRenderCycles,
  serializeScene,
  serializeSceneAsync,
  onDrawingMode,
  onCircleDrawingMode,
  onRemoveElementsMode,
  onSplitFaceMode,
  detectMassAddEvent,
  detectCurvedMassAddEvent,
  onEditPolygon,
  onWindowDrawingMode,
  onStairDrawingMode,
  onCurtainMode,
  onDoorDrawingMode,
  clipEvent,
  sketchEvent,
  eraseEvent,
  setScaleEvent,
  measureMeshEvent,
  angleMeasureEvent,
  onExplorerCam,
  rotateMeshEvent,
  scaleMeshEvent,
  onUnion,
  onIntersect,
  onSubtract,
  drawBoxes,
  onSelectComponent,
  resetSelections,
  removeMeshChildren,
  getScope,
  triggerUserPilot,
  setStoreyOneHeightEvent,
  /* FROM webtrudetodo.html */
  exportMap,
  onMatMode,
  intSelect,
  hideObject,
  showAllObjects,
  isolateObject,
  hideRoofs,
  zoomInOnClick,
  zoomOutOnClick,
  zoomExtentsOnClick,
  onPanClick,
  onOrbitClick,
  onCommentMode,
  disableOnCommentMode,
  handleLatLongEvent
};
