import React, {useEffect, useState} from "react";
import {getRenderById} from "../../services/render.service";
import {withRouter} from "react-router-dom";
import {SSAOParams} from "../../snaptrude/modules/render/render";
import ProgressBar from "../../components/ProgressBar";
import * as StyledComponents from "../editor/StyledEditorClasses";
import loader from "../../assets/loader.gif";

class Render extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      glb: "",
      isLoading: true,
      progress: 0
    }
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const floorKey = this.props.match.params.floorkey;
    getRenderById(id, floorKey).then(result => {
      if (!result.renders) return;
      const render = JSON.parse(result.renders)[0];
      this.setState({ glb: render.fields.glb});
      this.initialize3D(this.state.glb);
    });
  }

  /**
   * Initialize WebGi viewer and load the glb model
   *
   * @param glbPath - string
   * @returns {Promise<void>}
   */
  async initialize3D(glbPath) {
    // TODO: Use modules instead of direct usage for WebGi viewer
    const canvas = document.getElementById('my-canvas');
    const parentDiv = document.getElementById('viewer-container');

    // Create a viewer for the canvas
    const viewer = new window.ViewerApp({
      canvas: canvas,
    });

    viewer.setBackground("#fefefe");
    viewer.scene.envMapIntensity = 2.5;

    // Add plugins
    const manager = await viewer.addPlugin(window.AssetManagerPlugin);
    await viewer.addPlugin(window.TonemapPlugin);
    await viewer.addPlugin(window.ProgressivePlugin, 32);
    // await viewer.addPlugin(window.SSRPlugin);
    await viewer.addPlugin(window.SSAOPlugin);
    const ssaoPlugin = viewer.getPlugin(window.SSAOPlugin);
    ssaoPlugin.fromJSON(SSAOParams);

    const importer = viewer.getPlugin(window.AssetManagerPlugin).importer;

    // This event shares the state of the import and process in AssetImporter.
    importer.addEventListener("importFile", (ev) => {
      const progress = Math.round(ev.progress * 100);
      const state = ev.state;
      const path = ev.path;

      if (path !== glbPath) return;
      if (state === "done") {
        this.setState({isLoading: false});
        return;
      }
      this.setState({progress: progress});
    });

    viewer.renderer.refreshPipeline();

    viewer.scene.setEnvironment(
      await manager.importer.importSingle(
        {path: 'https://dist.pixotronics.com/webgi/assets/hdr/gem_2.hdr'}
      )
    );

    const options = {
      autoScale: true, // Scales the object before adding to the scene.
      autoScaleRadius: 2, // Scales the object bounding box to 2 World Units, if autoScale is true
      pseudoCenter: true, // centers the object to origin on load
      // check docs for other options (if required)
    }

    await manager.addFromPath(glbPath, options);

    // Set middle mouse button for orbiting
    viewer.scene.activeCamera.controls.mouseButtons.MIDDLE = window.MOUSE.ROTATE;

    // Resize canvas on resizing window
    window.addEventListener("resize", (evt) => {
      canvas.width = parentDiv.clientWidth;
      canvas.height = parentDiv.clientHeight;
    });

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    window.webgiviewer = viewer;
  }

  render() {
    return (
      <div id={"viewer-container"} style={{width: "100%"}}>
        <StyledComponents.LoadingScreen style={{display: this.state.isLoading ? "flex" : "none"}}>
          <img src={loader} alt="loading..."/>
          <div style={{width: "500px", maxWidth: "80vw"}}>
            <ProgressBar completed={this.state.progress} text={"Loading..."}/>
          </div>
        </StyledComponents.LoadingScreen>
        <canvas
          id="my-canvas"
          style={{width: "100%", height: "100%"}}
        ></canvas>
      </div>
    )
  }
}


export default withRouter(Render);