import * as log from "loglevel";
import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import {store} from "../modules/utilityFunctions/Store.js";
import {
  areEdgesParallel,
  convertGlobalVector3ToLocal,
  convertLocalVector3ToGlobal,
  createUUID,
  deepCopyObject,
  getBabylonPlane,
  getCentroidOfVertices,
  getDistanceBetweenVectors,
  getFacetArea, getRoomTypeProperties, isMeshThrowAway,
  isPointInsideTheMesh,
  isRoomOfType,
  mmToSnaptrudeUnits,
} from "../modules/extrafunc.js";
import {isPolygonClockwise} from "./polyFuncs.js";
import {ResolveEngineUtils} from "../modules/wallEngine/resolveEngine.js";
import {getDynamicThreshold, getFaceAreaFactor} from "./snapUtilities.js";
import {findNearestVertex, getAngleBetweenVectors, isFloatEqual, projectionOfPointOnLine,} from "./snapFuncs.js";
import {
  getNormalVector,
  getStandardDeviation,
  getUnitNormalVectorV3, getUnitNormalVectorV3CyclicCheck,
  mapOneSetOfNumbersToAnother,
} from "./mathFuncs.js";
import {getAngleBetweenVector} from "./wall_generation_helper.js";
import {dimensionsTuner} from "../modules/sketchMassBIMIntegration/dimensionsTuner.js";
import {geometryUpdater} from "../modules/sketchMassBIMIntegration/geometryUpdater";
import {commandUtils} from "../modules/commandManager/CommandUtils";
import {virtualSketcher} from "../modules/sketchMassBIMIntegration/virtualSketcher";
import {moveFace} from "../modules/meshoperations/moveOperations/moveFace";
import {getCircularlyNextElementInArray, getCircularlyPreviousElementInArray} from "./arrayFuncs";
import snapEngine from "../modules/snappingEngine/snapEngine";
import {getFace} from "./faceFuncs";
import {getNearestFaceOrientation}from "./massModeling"
import {getLengthOfEdge} from "./fplan/snapFuncsFplan";
import {computeWorldMatrix} from "../modules/meshoperations/moveOperations/moveUtil";
import {diagnostics} from "../modules/diagnoses/diagnostics";

/*
mda.js properties -
    HalfEdges are in CCW order
    HE's vertex is at its tail
 */

const snapmda = window.snapmda;

const INFINITY = 10000;

function generateMeshFromBrep(brep) {
  let mesh = new BABYLON.Mesh("csgProduct");
  let id = createUUID();
  let geometry = new BABYLON.Geometry(id, store.scene, null, true, mesh);
  mesh.BrepToMesh(brep);

  return mesh;
}

function generateBrepFromPositionsAndCells(positions, cells) {
  let brep = new snapmda.Mesh();
  //let brep = new Mesh();
  brep.setPositions(positions);
  brep.setCells(cells);
  brep.process();

  return brep;
}

/**
 * The standard we follow is that face vertices are counter clockwise when you look at them from
 * outside the mesh, since half edges are in CCW order
 *
 * For a function that generates a mesh out of planar points, the direction of extrusion matters.
 * If extrusion is towards the viewer, given points should be CW to the viewer.
 * If it's away from the viewer, points should be CCW to the viewer
 *
 * For room generation we always start from points on the ground or bottom of the storey.
 * So extrusion direction is fixed. Always up. So with a vantage point above,
 * the points should always be CW, no matter the source - draw mass or detect.
 *
 * isPolygonClockwise works like that.
 * When points are in xz plane and they're clockwise when looked at from a y coordinate
 * greater than the plane (i.e from above),
 * it returns true
 *
 * @param vertices
 */
function correctVerticesOrderOfBottomFaceXZ(vertices) {
  if (isPolygonClockwise(vertices)) {
    // looks clockwise from above
  } else {
    vertices.reverse();
  }
}

/*
Typed arrays give error during resurrect
 */
function convertBrepPositionsFromTypedArrayToArray(brep){
  brep.getPositions().forEach((position, index) => {
    if (Object.prototype.toString.call(position).includes("Float32Array")) {
      brep.positions[index] = Array.prototype.slice.call(position);
    }
  });
}

/**
 *
 * @param mesh
 * @returns {Promise<void>}
 */
function generateBrepForComponents(mesh) {
  if (mesh) {
    if (!mesh.getSnaptrudeDS().brep) {
      sortFaceIndicesForHalfEdge(mesh);
    }
  } else {
    store.scene.meshes.forEach((mesh) => {
      try {
        if (["wall", "roof", "floor"].includes(mesh.type.toLowerCase())) {
          if (!mesh.getSnaptrudeDS().brep) {
            sortFaceIndicesForHalfEdge(mesh);
          }
        }
      } catch (e) {
        console.log(e);
        console.warn("BRep generation failed for below mesh-");
        console.log(mesh);
      }
    });
  }
}

function verifyBRepIntegrity(brep) {
  return snapmda.MeshIntegrity(brep);
  //return MeshIntegrity(brep);
}

function sortFaceIndicesForHalfEdge(mesh) {
  let brep = mesh.getBrep(true);
  if (brep) {
    mesh.getSnaptrudeDS().brep = brep;
  }
  mesh.BrepToMesh();
}

function getFaceIdFromFacet(facetID, mesh, brepInMesh=false) {
  let faceID = null;
  try {
    var faceFacetMapping = brepInMesh ? mesh.faceFacetMapping : mesh.getSnaptrudeDS().faceFacetMapping;
  } catch (error) {
    return null;
  }
  for (let face in faceFacetMapping) {
    faceFacetMapping[face].some((facet) => {
      if (facet === facetID) {
        faceID = face;
        return true;
      }
    });
    if (faceID) return parseInt(faceID, 10);
  }
}

function splitFaceByFacetId(mesh, facetID, point1, point2, space = BABYLON.Space.WORLD) {
  let brep = mesh.getSnaptrudeDS().brep;
  let faceID = getFaceIdFromFacet(facetID, mesh);
  let faces = brep.getFaces();

  let split = splitFaceByFaceObject(mesh, faces[faceID], point1, point2, space);

  if (!split) {
    faces.some((face, id) => {
      if (id !== faceID) {
        split = splitFaceByFaceObject(mesh, face, point1, point2, space);
        return split;
      }
    });
  }

  return split;
}

function isElementSystemGenerated(element) {
  return element.isSystemGenerated;
}

function labelElementAsSystemGenerated(element) {
  element.isSystemGenerated = true;
}

function removeSystemGeneratedLabel(element) {
  element.isSystemGenerated = false;
}

function deleteSystemGeneratedLabel(element) {
  delete element.isSystemGenerated;
}

function labelVerticesAndEdgesAsSystemGenerated(
  mesh,
  brep,
  bottomVerticesV3 = [],
  topVerticesV3 = []
) {
  const edges = [];

  bottomVerticesV3.forEach((v3, i) => {
    const vertexObject = getVertexObjectFromPositionV3(
      mesh,
      v3,
      BABYLON.Space.WORLD
    );

    labelElementAsSystemGenerated(vertexObject);

    if (topVerticesV3[i]) {
      edges.push([v3, topVerticesV3[i]]);
    }
  });

  topVerticesV3.forEach((v3, i) => {
    const vertexObject = getVertexObjectFromPositionV3(
      mesh,
      v3,
      BABYLON.Space.WORLD
    );

    labelElementAsSystemGenerated(vertexObject);
  });

  edges.forEach((edge) => {
    const edgeObject = getEdgeObjectFromVertexPositions(
      mesh,
      brep,
      edge[0],
      edge[1],
      BABYLON.Space.WORLD
    );

    labelElementAsSystemGenerated(edgeObject);
  });
}

function deleteEdge(brep, edgeIndex){
  snapmda.DeleteEdgeOperator(brep, edgeIndex);
}

function deleteVertex(brep, vertexIndex){
  snapmda.DeleteVertexOperator(brep, vertexIndex);
}

function splitFaceByFaceObject(
  mesh,
  face,
  point1,
  point2,
  space,
  options = {}
) {
  const component = mesh.getSnaptrudeDS();
  let brep = options.brep;

  if (!brep) brep = component.brep;
  if (!brep) {
    log.warn("BRep not found");
    return false;
  }
  /*
    let faceVerts = snapmda.FaceVerticesWithHoles(face);
    let faceIndices = [];
    for (let i = 0; i < faceVerts.length; i++) {
        faceIndices.push(faceVerts[i].map(vertex => vertex.index));
    }

    let faceVertices = [];
    for (let i = 0; i < faceIndices.length; i++) {
        faceVertices.push(faceIndices[i].map(index => BABYLON.Vector3.FromArray(brep.positions[index])));
    }*/

  let faceIndices = snapmda.FaceVertices(face).map((vertex) => vertex.index);
  //let faceIndices = FaceVertices(face).map((vertex) => vertex.index);
  let faceVertices = getFaceVerticesFromFace(face, mesh, space);

  let vertex1Index = NaN;
  let vertex2Index = NaN;

  /*for( let i = 0; i < faceVertices.length; i++ ){
        let faceVerticesOfEachFace = faceVertices[i];
        faceVerticesOfEachFace.forEach((vertex, index) => {
            if (vertex.equals(point1)) vertex1Index = faceIndices[i][index];
            if (vertex.equals(point2)) vertex2Index = faceIndices[i][index];
        });
    }*/

  faceVertices.forEach((vertex, index) => {
    if (vertex.almostEquals(point1)) vertex1Index = faceIndices[index];
    if (vertex.almostEquals(point2)) vertex2Index = faceIndices[index];
  });

  let edge1Index = NaN;
  let edge2Index = NaN;
  //let bottomFace = brep.getFaces()[0];
  //let topFace = brep.getFaces()[1];

  if (isNaN(vertex1Index) || isNaN(vertex2Index)) {
    let HEs = snapmda.FaceHalfEdges(face);
    //let HEsOfTopFace = snapmda.FaceHalfEdges(topFace);
    //let HEsOfBottomFace = snapmda.FaceHalfEdges(bottomFace);

    let thresholdAngle = options.thresholdAngle || 1e-4;

    HEs.forEach((he) => {
      let edge = he.getEdge();
      let Vs = getVerticesFromEdgeObject(component, edge, space);
      // Vs = Vs.map(v3 => [v3.x, v3.y, v3.z]);
      // console.log(Vs);

      let edgeEndPoint1 = Vs[0];
      let edgeEndPoint2 = Vs[1];

      let util = new ResolveEngineUtils();

      if (isNaN(vertex1Index)) {
        if (
          util.onSegment3D(
            edgeEndPoint1,
            point1,
            edgeEndPoint2
          )
        ) {
          edge1Index = edge.getIndex();
        }
      }

      if (isNaN(vertex2Index)) {
        if (
          util.onSegment3D(
            edgeEndPoint1,
            point2,
            edgeEndPoint2
          )
        ) {
          edge2Index = edge.getIndex();
        }
      }
    });
  }

  if (
    (isNaN(vertex1Index) && isNaN(edge1Index)) ||
    (isNaN(vertex2Index) && isNaN(edge2Index))
  ) {
    log.warn("Vertex indices not determined");
    return false;
  }

  if (edge1Index === edge2Index) {
    log.warn("Incorrect edge indices");
    return false;
  }

  if (space === BABYLON.Space.WORLD) {
    point1 = convertGlobalVector3ToLocal(point1, mesh);
    point2 = convertGlobalVector3ToLocal(point2, mesh);
  }

  point1 = point1.asArray();
  point2 = point2.asArray();

  let newElements = {
    vertices: [],
    edge: null,
    face: null,
  }

  if (!isNaN(edge1Index)) {
    const vertex = snapmda.InsertVertexOperator(brep, edge1Index, point1);
    vertex1Index = vertex.getIndex();
    newElements.vertices.push(vertex);
  }

  if (!isNaN(edge2Index)) {
    const vertex = snapmda.InsertVertexOperator(brep, edge2Index, point2);
    vertex2Index = vertex.getIndex();
    newElements.vertices.push(vertex);
  }

  if (options.isSystemGenerated)
    labelElementAsSystemGenerated(brep.vertices[vertex1Index]);
  if (options.isSystemGenerated)
    labelElementAsSystemGenerated(brep.vertices[vertex2Index]);

  let newAddEdgeElements = snapmda.InsertEdgeOperator(brep, vertex1Index, vertex2Index);

  if (newAddEdgeElements) {

    newElements.edge = newAddEdgeElements.edge;
    newElements.face = newAddEdgeElements.face;

    if (options.isSystemGenerated) {
      labelElementAsSystemGenerated(newElements.edge);
    }
    newElements.face.materialIndex = face.materialIndex;
    dimensionsTuner.addDefaultFaceMetadata(newElements.face, face);
    dimensionsTuner.addAmbiguousFaces([newElements.face, face], component);

  }

  options.newElements = newElements;
  mesh.BrepToMesh();

  return true;
}

function getVerticesFromEdgeObject(
  component,
  edge,
  space = BABYLON.Space.WORLD
) {
  const mesh = component.mesh;
  const brep = component.brep;

  let EVs = snapmda.EdgeVertices(edge)
  // let EVs = EdgeVertices(edge)
    .map((v) => BABYLON.Vector3.FromArray(brep.positions[v.getIndex()]));
  if (space === BABYLON.Space.WORLD)
    EVs = EVs.map((v) =>
      BABYLON.Vector3.TransformCoordinates(v, mesh.getWorldMatrix())
    );

  return EVs;
}

/**
 * Returns a copy of extracted brep face mesh from given facetId
 * @param faceId
 * @param mesh
 * @param space
 * @returns {*}
 */
 function extractFaceBrepMeshFromGivenFaceId(faceId, mesh, space) {
  try {
    let brep = mesh.getSnaptrudeDS().brep;
    let face = brep.getFaces()[faceId];
    let verData = [];
    let indices = [];
    let uvData = [];

    let faceIndicesOrg = snapmda.FaceVertices(face).map((vertex) => vertex.index);
    // let faceIndices = FaceVertices(face).map((vertex) => vertex.index);
    let faceVertices = faceIndicesOrg.map((index) =>
      BABYLON.Vector3.FromArray(brep.positions[index])
    );
    let faceIndices = new Array(faceVertices.length);
    for(var i=0, j=faceVertices.length-1; i< faceVertices.length/2; i++, j--)
    {
      faceIndices[i] = i;
      faceIndices[i+ faceVertices.length/2] = j;
    }
    if (space === BABYLON.Space.WORLD)
      faceVertices = faceVertices.map((v) =>
        convertLocalVector3ToGlobal(v, mesh)
      );
   
    for(var i=0; i<faceVertices.length; i++) {
      verData.push(
        faceVertices[i].x,
        faceVertices[i].y,
        faceVertices[i].z
      );
    }
    const _populateUVs = function (faceVerticesGlobal) {
      let minX = 1e6;
      let maxX = -1e6;
      let minY = 1e6;
      let maxY = -1e6;
      let minZ = 1e6;
      let maxZ = -1e6;
      for (let w = 0; w < faceVerticesGlobal.length; w++) {
        minX = Math.min(faceVerticesGlobal[w].x, minX);
        maxX = Math.max(faceVerticesGlobal[w].x, maxX);
        minY = Math.min(faceVerticesGlobal[w].y, minY);
        maxY = Math.max(faceVerticesGlobal[w].y, maxY);
        minZ = Math.min(faceVerticesGlobal[w].z, minZ);
        maxZ = Math.max(faceVerticesGlobal[w].z, maxZ);
      }
  
      let diffX = maxX - minX;
      let diffY = maxY - minY;
      let diffZ = maxZ - minZ;
  
      let orientation = getNearestFaceOrientation(faceVerticesGlobal);
  
      faceVerticesGlobal.forEach((v) => {
        let uv1 = NaN;
        let uv2 = NaN;
  
        switch (orientation) {
          case "xy":
            uv1 = v.x - minX;
            uv2 = v.y - minY;
            break;
          case "-xy":
            uv1 = maxX - v.x;
            uv2 = v.y - minY;
            break;
          case "zy":
            uv1 = v.z - minZ;
            uv2 = v.y - minY;
            break;
          case "-zy":
            uv1 = maxZ - v.z;
            uv2 = v.y - minY;
            break;
          case "xz": //uv maps to x,z
            uv1 = v.x - minX;
            uv2 = v.z - minZ;
            break;
          case "x-z": //uv maps to x, -z
            uv1 = v.x - minX;
            uv2 = maxZ - v.z;
            break;
        }
  
        // if (isNaN(uv1) || isNaN(uv2)) log.warn("UVs problematic");
        uvData.push(
          uv1 * store.unit_absolute_scale * 10,
          uv2 * store.unit_absolute_scale * 10
        );
      });
    };


    for(var i=0, j=faceIndices.length-1; i<faceIndices.length/2-1; i++, j--)
    {
      var id0 = i;
      var id1 = i + 1;
      var id2 = j -1;
      var id3 = j;
      indices.push(id0);
      indices.push(id1);
      indices.push(id2);

      indices.push(id0);
      indices.push(id2);
      indices.push(id3);
    }
    _populateUVs(faceVertices);

    let normals = [];
    BABYLON.VertexData.ComputeNormals(verData, indices, normals);  
      //Create a custom mesh
    let faceMesh = new BABYLON.Mesh("customMesh", store.scene);
   
    //faceMesh.setAbsolutePosition(mesh.position);
    faceMesh.indicesLimit = indices.length;
    faceMesh.verticesLength = faceVertices.length;
    //Create a vertexData object
    var vertexData = new BABYLON.VertexData();

    //Assign positions, indices, normals and uvs to vertexData
    vertexData.positions = verData;
    vertexData.indices = indices;
    vertexData.normals = normals;
    vertexData.uvs = uvData;

    //Apply vertexData to custom mesh
    vertexData.applyToMesh(faceMesh, true);
    return faceMesh;
  } catch (error) {
    return null;
  }
}


/**
 * Returns an array of BABYLON.Vector3 objects
 * @param facetID
 * @param mesh
 * @param space
 * @returns {*}
 */
function getFaceVerticesFromFacetID(facetID, mesh, space, options) {
  if (options === undefined) {
    options = {};
    options.holes = false;
  }
  let faceId = getFaceIdFromFacet(facetID, mesh, options.brepInMesh);

  try {
    let brep = options.brepInMesh ? mesh.brep : mesh.getSnaptrudeDS().brep;
    let face = brep.getFaces()[faceId];
    options.faceId = faceId;
    return getFaceVerticesFromFace(face, mesh, space, options);
  } catch (error) {
    return null;
  }
}

/**
 * Returns an array of BABYLON.Vector3 objects
 * @param face
 * @param mesh
 * @param space
 * @param options
 * @returns {*}
 */
 function getFaceVerticesFromFace(face, mesh, space = BABYLON.Space.WORLD, options = {}) {
  if(!_.isBoolean(options.holes)) options.holes = false;
  if (options.holes) {
    try {
      let brep = options.brep;
      if (!brep) brep = options.brepInMesh ? mesh.brep : mesh.getSnaptrudeDS().brep;

      let faceVerts = snapmda.FaceVerticesWithHoles(face);
      // let faceVerts = FaceVerticesWithHoles(face);
      let faceIndices = [];
      for (let i = 0; i < faceVerts.length; i++) {
        faceIndices.push(faceVerts[i].map((vertex) => vertex.index));
      }

      let faceVertices = [];
      for (let i = 0; i < faceIndices.length; i++) {
        faceVertices.push(
          faceIndices[i].map((index) =>
            BABYLON.Vector3.FromArray(brep.positions[index])
          )
        );
      }

      if (space === BABYLON.Space.WORLD)
        for (let i = 0; i < faceVertices.length; i++) {
          faceVertices[i] = faceVertices[i].map((v) =>
            convertLocalVector3ToGlobal(v, mesh)
          );
        }
      return faceVertices;
    } catch (e) {
      console.error(e);
      return null;
    }
  } else {
    try {
      let brep = options.brep;
      if (!brep) brep = options.brepInMesh ? mesh.brep : mesh.getSnaptrudeDS().brep;
      let faceIndices = snapmda.FaceVertices(face).map((vertex) => vertex.index);
      // let faceIndices = FaceVertices(face).map((vertex) => vertex.index);
      let faceVertices = faceIndices.map((index) =>
        BABYLON.Vector3.FromArray(brep.positions[index])
      );

      if (space === BABYLON.Space.WORLD)
        faceVertices = faceVertices.map((v) =>
          convertLocalVector3ToGlobal(v, mesh)
        );

      return faceVertices;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

function populateBrepIndexes(component, globalPositions, update = false) {
   
   const _fetchIndices = function (threshold){
     let indices = [];
     positionArray.forEach((positionToCheck, i) => {
      brep.positions.some((position, index) => {
        if (
          store.resolveEngineUtils.areArraysAlmostEqual(position, positionToCheck, threshold)
        ) {
          indices.push(index);
          return true;
        }
      });
    });
    indices = _.uniq(indices);
    
    return indices;
   }
   
  const mesh = component.mesh;
  const brep = component.brep;

  const positionArray = globalPositions.map((gp) => {
    return convertGlobalVector3ToLocal(gp, mesh).asArray();
  });

  if (update && !_.isEmpty(brep.indexes)) {
    // do nothing
  } else brep.indexes = [];
  
  const lowThreshold = 1e-4;
  const highThreshold = 1e-2;
  
  let indices = _fetchIndices(lowThreshold);
  if (indices.length !== globalPositions.length){
    indices = _fetchIndices(highThreshold);
  }
  
  brep.indexes = indices;
}

function updateBrepPositions(brep, oldPositions, newPositions) {
  let positions = brep.positions;
  let util = new ResolveEngineUtils();

  let indexes = brep.indexes;
  let threshold = 1e-4;
  if (indexes) {
    oldPositions = _.uniqWith(oldPositions, (a1, a2) => {
      return util.areArraysAlmostEqual(a1, a2, threshold);
    });
    newPositions = _.uniqWith(newPositions, (a1, a2) => {
      return util.areArraysAlmostEqual(a1, a2, threshold);
    });

    if (oldPositions.length !== newPositions.length) {
      console.log("Brep updation messed up");
      return;
    }

    oldPositions.forEach((oldPosition, i) => {
      if (i >= newPositions.length) return;
      positions.forEach((position, index) => {
        if (util.areArraysAlmostEqual(position, oldPosition, threshold)) {
          if (!indexes.includes(index)) return;
          positions[index] = newPositions[i];
          return true;
        }
      });
    });
  } else {
    brep.indexes = [];
    oldPositions.forEach((oldPosition, i) => {
      positions.forEach((position, index) => {
        //not some because of flat masses
        if (util.areArraysAlmostEqual(position, oldPosition, threshold)) {
          positions[index] = newPositions[i];
          brep.indexes.push(index);
          return true;
        }
      });
    });
    brep.indexes = _.uniq(brep.indexes);
  }
}

function getLowerEdgeFromFaceVertices(faceVertices){
  const length = faceVertices.length;
  const heightsOfEachEdge = faceVertices.map((vertex, i) => {
    const nextIndex = i === length - 1 ? 0 : i + 1;
    const nextVertex = faceVertices[nextIndex];

    return BABYLON.Vector3.Center(vertex, nextVertex).y;
  });

  const minIndex = heightsOfEachEdge.indexOf(_.min(heightsOfEachEdge));
  const nextIndex = minIndex === length - 1 ? 0 : minIndex + 1;

  return {
    headPt: faceVertices[minIndex],
    tailPt: faceVertices[nextIndex],
  };
}

function getUpperEdgeFromFaceVertices(faceVertices){
  const length = faceVertices.length;
  const heightsOfEachEdge = faceVertices.map((vertex, i) => {
    const nextIndex = i === length - 1 ? 0 : i + 1;
    const nextVertex = faceVertices[nextIndex];

    return BABYLON.Vector3.Center(vertex, nextVertex).y;
  });

  const maxIndex = heightsOfEachEdge.indexOf(_.max(heightsOfEachEdge));
  const nextIndex = maxIndex === length - 1 ? 0 : maxIndex + 1;

  return {
    headPt: faceVertices[maxIndex],
    tailPt: faceVertices[nextIndex],
  };
}

function getVertexIndexFromPositionArray(brep, positionArray, options = {}) {
  let index = NaN;
  let util = new ResolveEngineUtils();
  let threshold = 0.001;
  if (options.sendMultiple) {
    const indices = [];
    brep.getPositions().forEach((pos, i) => {
      if (util.areArraysAlmostEqual(pos, positionArray, threshold)){
        indices.push(i);
      }
    });

    return indices;
  } else {
    brep.getPositions().some((pos, i) => {
      if (util.areArraysAlmostEqual(pos, positionArray, threshold)){
        index = i;
        return true;
      }
    });

    return index;
  }
}

function getVertexObjectFromPositionArray(brep, position) {
  let index = getVertexIndexFromPositionArray(brep, position);
  if (isNaN(index)) return null;
  else return brep.getVertices()[index];
}

function getVertexObjectFromPositionV3(mesh, position, space = BABYLON.Space.WORLD, options = {}) {
  let brep = options.brep;
  if (!brep) brep = mesh.getSnaptrudeDS().brep;
  if (space === BABYLON.Space.WORLD) {
    position = convertGlobalVector3ToLocal(position, mesh);
  }

  let index = getVertexIndexFromPositionArray(
    brep,
    [position.x, position.y, position.z],
    options
  );
  if (_.isArray(index)) {
    const vertices = index.map((i) => brep.getVertices()[i]);
    if (vertices.length === 1) return vertices[0];
    else return vertices;
  } else if (isNaN(index)) return null;
  else {
    return brep.getVertices()[index];
  }
}

function getPositionFromVertex(vertex, mesh, space = BABYLON.Space.WORLD) {
  try {
    let brep = mesh.getSnaptrudeDS().brep;
    let neighbourVertex = brep.getPositions()[vertex.getIndex()];
    neighbourVertex = BABYLON.Vector3.FromArray(neighbourVertex);

    if (space === BABYLON.Space.WORLD)
      neighbourVertex = BABYLON.Vector3.TransformCoordinates(
        neighbourVertex,
        mesh.getWorldMatrix()
      );

    return neighbourVertex;
  } catch (e) {
    return null;
  }
}

function getEdgeObjectFromVertexPositions(
  meshOfInterest,
  brep,
  vertex1,
  vertex2,
  space
) {
  let vertex1Object = getVertexObjectFromPositionV3(
    meshOfInterest,
    vertex1,
    space
  );
  let vertex2Object = getVertexObjectFromPositionV3(
    meshOfInterest,
    vertex2,
    space
  );
  
  if (!vertex1Object || !vertex2Object) return null;

  return brep.getEdge(vertex1Object.getIndex(), vertex2Object.getIndex());
}

function getEdgeObjectsAroundTheFace (face){
   const HEs = snapmda.FaceHalfEdges(face);
   return HEs.map(he => he.getEdge());
}

function getEdgeFaces(edge){
  return [
    edge.getHalfEdge().getFace(),
    edge.getHalfEdge().getFlipHalfEdge().getFace(),
  ];
}

function getEdgeVertices(edge){
  return [
    edge.getHalfEdge().getVertex(),
    edge.getHalfEdge().getFlipHalfEdge().getVertex(),
  ];
}

function getEdgeAdjacentFace(edge, currentFace){
   const facesAroundTheEdge = getEdgeFaces(edge);

   return currentFace === facesAroundTheEdge[0] ? facesAroundTheEdge[1] : facesAroundTheEdge[0];
}

function getEdgeObjectFromVertexObjects(
  componentOfInterest,
  vertex1Object,
  vertex2Object
) {
  return componentOfInterest.brep.getEdge(
    vertex1Object.getIndex(),
    vertex2Object.getIndex()
  );
}

function extrudeFace(component, faceObject, extrudeAmount){
  const brep = component.brep;

  const newElements = snapmda.ExtrudeOperator(
    brep,
    faceObject.getIndex(),
    extrudeAmount,
    0
  );

  component.mesh.BrepToMesh();

  return {
    vertices: newElements.newVertices,
    edges: newElements.newEdges,
    faces: newElements.newFaces,
  }
}

function findNearestGeomEdgeFromBrep(edge, mesh)
{
  if(!edge || !mesh)return null;
  let snaptrudeDS = mesh.getSnaptrudeDS(false);
  if(snaptrudeDS && snaptrudeDS.geomRepMapHalfEdgesEdges &&  snaptrudeDS.geomRepRevMapEdgesHalfEdges)
  {
    let stVt = getVertexObjectFromPositionV3(mesh, edge.headPt);
    let endVt = getVertexObjectFromPositionV3(mesh, edge.tailPt);
    let key = stVt.getIndex() +'-' + endVt.getIndex();
    let edgeId = snaptrudeDS.geomRepMapHalfEdgesEdges[key];
    let vertList = snaptrudeDS.geomRepRevMapEdgesHalfEdges[edgeId];
    if(vertList)
    {
      let brepVertices = [];
      for(let i=0;i< vertList.length; i++)
      {
        let idx = vertList[i];
        brepVertices.push(getPositionFromVertex(snaptrudeDS.brep.getVertices()[idx], mesh));
      }
      return brepVertices;
    }
  }
  return null;
}

function  findNearestEdgeFromBRep(pickInfo, space, options = {}) {
  let nearestEdge = null;
  let debugInfo = {
    faceVertices: null,
    pickInfo: null,
    arrayOfEdgesAndDistances: [],
    threshold: null,
    usingScreenSpaceThreshold: null,
  };

  try {
    let mesh = pickInfo.pickedMesh;
    //let brep = mesh.getSnaptrudeDS().brep;

    let arrayOfFaceVertices = getFaceVerticesFromFacetID(
      pickInfo.faceId,
      mesh,
      space,
      {
        holes: true,
      }
    );

    let snaptrudeDS = mesh.getSnaptrudeDS(false);
    let isCircular = !!snaptrudeDS && snaptrudeDS.type.toLowerCase() === "mass" && snaptrudeDS.isCircularMass();

    // if(mesh.room_curve){
    //     let faceArray = faceVertices[0].map(baby => baby.asArray());
    //     for(let i = 0; i < mesh.room_curve.length; i++){
    //         if(checkIfCommonPoint(faceArray, mesh.room_curve[i]))
    //             return null;
    //     }
    // }

    let pickedPoint = pickInfo.pickedPoint;
    if (space === BABYLON.Space.LOCAL) {
      pickedPoint = convertLocalVector3ToGlobal(pickedPoint, mesh);
    }

    options.faceVertices = arrayOfFaceVertices;
    
    let usingScreenSpaceThreshold = options.usePointerLocation && pickInfo.secondaryPick;
    // when it's primary pick, we need to check the size of the face and the logic to do that in scene space is already present
    // if the face is too small, then even if the edge is closer than the threshold,
    // sometimes we need to not snap to the edge so that the face snap becomes possible

    let useThresholds = true;
    if (options.withoutThreshold) useThresholds = false;
    // use this option for the cases when we know there is an edge for sure, just wanna know which one
    // avoid threshold calculation

    let threshold = 1e4;

    if (useThresholds){
      if (usingScreenSpaceThreshold){
        threshold = snapEngine.snapVerifier.getScreenSpaceThreshold();
      }
      else {
        const faceAreaFactor = getFaceAreaFactor(pickInfo);
        threshold = getDynamicThreshold(pickInfo.pickedPoint, faceAreaFactor);
      }
    }


    debugInfo.faceVertices = arrayOfFaceVertices;
    debugInfo.pickInfo = pickInfo;
    debugInfo.threshold = threshold;
    debugInfo.usingScreenSpaceThreshold = usingScreenSpaceThreshold;

    let nearestEdgeDistance = INFINITY;
    for (let i = 0; i < arrayOfFaceVertices.length; i++) {
      let faceVertices = arrayOfFaceVertices[i];

      let edges = faceVertices.map((vertex) => {
        const nextVertex = getCircularlyNextElementInArray(
          faceVertices,
          vertex
        );

        return {
          headPt: vertex,
          tailPt: nextVertex,
        };

      });


      const originalEdges = [];
      if (isCircular) {
        let vertices = snaptrudeDS.getPointsForCircle(1);
        originalEdges.push(...edges);
        edges = _getActiveEdgesForCircle(edges, vertices);
      }

      edges.forEach(edge => {
        
        const v1 = edge.headPt;
        const v2 = edge.tailPt;

        let projectionOnEdge = projectionOfPointOnLine(pickedPoint, v1, v2);
        
        let neighboringEdgeLengthThreshold = 1e4;
        if (useThresholds && !usingScreenSpaceThreshold){
          const nextEdge = getCircularlyNextElementInArray(edges, edge);
          const previousEdge = getCircularlyPreviousElementInArray(edges, edge);
  
          const previousEdgeLength = getDistanceBetweenVectors(previousEdge.headPt, previousEdge.tailPt);
          const currentEdgeLength = getDistanceBetweenVectors(edge.headPt, edge.tailPt);
          const nextEdgeLength = getDistanceBetweenVectors(nextEdge.headPt, nextEdge.tailPt);
  
          const distanceOfProjectionFromHeadPt = getDistanceBetweenVectors(projectionOnEdge, v1);
  
          /*
          Threshold maps from previousEdgeLength / 4 to nextEdgeLength / 4 as we travel from edge.headPt to edge.tailPt
  
          With these limits, there'll be 50% of the area on the face for face selection and 25% for the edges
          */
  
          neighboringEdgeLengthThreshold = mapOneSetOfNumbersToAnother(
            0,
            currentEdgeLength,
            previousEdgeLength / 4,
            nextEdgeLength / 4,
            distanceOfProjectionFromHeadPt
          );
        }

        const thresholdForThisEdge = _.min([neighboringEdgeLengthThreshold, threshold]);
        // const thresholdForThisEdge = threshold;
        
        let util = new ResolveEngineUtils();
        
        let distanceFromEdge;
        
        if (usingScreenSpaceThreshold){
          distanceFromEdge = snapEngine.snapVerifier.getScreenSpaceDistanceFromPointer(projectionOnEdge);
        }
        else {
          distanceFromEdge = BABYLON.Vector3.Distance(
            projectionOnEdge,
            pickedPoint
          );
        }

        debugInfo.arrayOfEdgesAndDistances.push({
          edge,
          distanceFromEdge,
          thresholdForThisEdge
        });
        
        if (distanceFromEdge < thresholdForThisEdge) {
          if (!util.onSegment3D(v1, projectionOnEdge, v2)) {
            return;
          }

          if (distanceFromEdge < nearestEdgeDistance) {
            nearestEdgeDistance = distanceFromEdge;
            nearestEdge = {};
            nearestEdge.headPt = v1;
            nearestEdge.tailPt = v2;
          }
          // break;
          //don't break out of the loop. Multiple edges can be at a distance lesser than threshold.
          //this function returns nearest edge, not some edge at a distance < threshold
        }

      });

    }
  } catch (e) {
    nearestEdge = null;
  }

  if (diagnostics.isInDebugMode()){
    console.log("------");
    console.log(debugInfo);
  }

  return nearestEdge;
}

let _getActiveEdgesForCircle = (edges, vertices) => {
  if (store.projectProperties.properties.toggleNearestPointSnap.value) return edges;

    let activeEdges = [];

    for (let edge of edges) {
      for (let v of vertices) {
        if (edge.headPt.almostEquals(v) || edge.tailPt.almostEquals(v)) {
          activeEdges.push(edge);
        }
      }
    }

    return activeEdges;
}

function findNearestVertexFromBRep(pickInfo, space, options = {}) {
  
  function _isTheEdgeTooSmall(vertexV3, vertexScreenDistance, vertices) {
    /*
    When snapping to a small edge, it's possible that the length of the edge
    is such that it's less than 2 * screen space threshold
    
    In such cases, snapping to edge is impossible unless zoomed in further
    This function handles that case, to override certain vertex snaps even if they're within range
     */
    
    const previousVertex = getCircularlyPreviousElementInArray(vertices, vertexV3);
    const nextVertex = getCircularlyNextElementInArray(vertices, vertexV3);

    let theEdgePointerIsCloseTo;
    
    // check if any of the 2 edges is small
    const previousLength = snapEngine.snapVerifier.getScreenSpaceDistanceBetweenVectors(vertexV3, previousVertex);
    const nextLength = snapEngine.snapVerifier.getScreenSpaceDistanceBetweenVectors(vertexV3, nextVertex);
    
    const edgeLengthLimit = 3 * screenSpaceThreshold;
    if (previousLength < edgeLengthLimit || nextLength < edgeLengthLimit) {
      return vertexScreenDistance > screenSpaceThreshold / 2;
    }
    
    return false;
  }

  let nearestVertex = null;
  let vertices;
  let isCircular;

  let meshOfInterest = pickInfo.pickedMesh;
  let screenSpaceThreshold;
  let brep;
  
  // const faceAreaFactor = getFaceAreaFactor(pickInfo);
  // threshold = getDynamicThreshold(pickInfo.pickedPoint, faceAreaFactor);
  
  // const thresholdAdjustment = 1.5;
  // threshold = threshold * thresholdAdjustment;
  
  screenSpaceThreshold = snapEngine.snapVerifier.getScreenSpaceThreshold();
  
  brep = meshOfInterest.getSnaptrudeDS(false)?.brep;
  if (brep) {
    vertices = getFaceVerticesFromFacetID(
      pickInfo.faceId,
      meshOfInterest,
      space,
      { holes: true }
    );
    
    if (!vertices) return null;
  
    let snaptrudeDS = meshOfInterest.getSnaptrudeDS(false);
    isCircular = !!snaptrudeDS && snaptrudeDS.type.toLowerCase() === "mass" && snaptrudeDS.isCircularMass();
  
    if (isCircular && !store.projectProperties.properties.toggleNearestPointSnap.value && !(store.ACTIVE_EVENT.event == "removeElements")) {
      vertices = [snaptrudeDS.getPointsForCircle(pickInfo.faceId)];
    }
  }
  else {
    let face = getFace(pickInfo);
    if (!face) return null;
    
    let facetVertices = face[0];
    facetVertices = facetVertices.map(v => convertLocalVector3ToGlobal(v, pickInfo.pickedMesh, false));
    // facetVertices = _.uniqWith(facetVertices, (v1, v2) => v1.almostEquals(v2));
  
    vertices = [facetVertices];
  }
  

  let pickedPoint = pickInfo.pickedPoint;

  if (space === BABYLON.Space.LOCAL)
    pickedPoint = convertGlobalVector3ToLocal(pickedPoint, meshOfInterest);
  
  let nearestVertexDistance = INFINITY;
  let nearestVertexIndex;

  for (let eachFaceVertices of vertices) {
    for (let j = 0; j < eachFaceVertices.length; j++) {
      let vertexV3 = eachFaceVertices[j];
      // let vertexDistance = BABYLON.Vector3.Distance(vertexV3, pickedPoint);
      let vertexScreenDistance = snapEngine.snapVerifier.getScreenSpaceDistanceFromPointer(vertexV3);

      if (vertexScreenDistance < screenSpaceThreshold && vertexScreenDistance < nearestVertexDistance) {

        if (_isTheEdgeTooSmall(vertexV3, vertexScreenDistance, eachFaceVertices)) continue;

        nearestVertexDistance = vertexScreenDistance;
        nearestVertex = vertexV3;
        nearestVertexIndex = j;
      }
    }
  }
  
  if (brep) {
    options.faceVertices = vertices;

    let outerVertices = vertices[0];
    if (nearestVertex && !isCircular) {
      if (nearestVertexIndex === 0) {
        options.faceNeighbours = [
          outerVertices[1],
          outerVertices[outerVertices.length - 1],
        ];
      } else if (nearestVertexIndex === outerVertices.length - 1) {
        options.faceNeighbours = [
          outerVertices[outerVertices.length - 2],
          outerVertices[0],
        ];
      } else {
        options.faceNeighbours = [
          outerVertices[nearestVertexIndex + 1],
          outerVertices[nearestVertexIndex - 1],
        ];
      }
    }
  }
  
  return nearestVertex;
}

function removeVertexFromComponent(vertex, componentOfInterest, options) {
  let meshOfInterest = componentOfInterest.mesh;
  let upperVertexObject = getVertexObjectFromPositionV3(
    meshOfInterest,
    vertex,
    options.operatingSpace
  );

  let lowerVertexPosition = getLowerVertexOfTheEdge(
    vertex,
    meshOfInterest,
    options,
    options.operatingSpace
  );
  if (!lowerVertexPosition) return false;

  let lowerVertexObject = getVertexObjectFromPositionV3(
    meshOfInterest,
    lowerVertexPosition,
    options.operatingSpace
  );

  let edgeToBeRemoved = componentOfInterest.brep.getEdge(
    upperVertexObject.getIndex(),
    lowerVertexObject.getIndex()
  );

  let removed = false;
  let _edgeIndex;
  if (edgeToBeRemoved) {
    if (options.forcedRemoval) {
      _edgeIndex = edgeToBeRemoved.getIndex();
      removed = removeEdgeAndReconstruct(meshOfInterest, _edgeIndex);
    } else {
      if (options.onlyIfSystemGenerated) {
        if (isElementSystemGenerated(edgeToBeRemoved)) {
          _edgeIndex = edgeToBeRemoved.getIndex();
          removed = removeEdgeAndReconstruct(meshOfInterest, _edgeIndex);
        } else {
          options.nonRemovalReason = "Cannot remove user added vertex";
          removed = false;
        }
      } else {
        if (isElementSystemGenerated(edgeToBeRemoved)) {
          removed = false;
        } else {
          _edgeIndex = edgeToBeRemoved.getIndex();
          removed = removeEdgeAndReconstruct(meshOfInterest, _edgeIndex);
        }
      }
    }
  }

  return removed;
}

function getLowerVertexOfTheEdge(topVertex, meshOfInterest, options, space = BABYLON.Space.WORLD) {
  //check if mass is flat (like a site)
  if (
    isFloatEqual(
      meshOfInterest.getBoundingInfo().minimum.y,
      meshOfInterest.getBoundingInfo().maximum.y,
      0.001
    )
  ) {
    return topVertex;
  }

  let brep = meshOfInterest.getSnaptrudeDS().brep;
  let totalFaceVertices = [];

  if (!isNaN(options.facetId)) var facetId = options.facetId;
  else if (!isNaN(options.faceId)) var mainFaceId = options.faceId;

  let faces = brep.getFaces();
  let optionsNew = {};
  optionsNew.holes = true;
  var mainFaceVertices;
  if (!isNaN(mainFaceId)) {
    mainFaceVertices = getFaceVerticesFromFace(
      faces[mainFaceId],
      meshOfInterest,
      space,
      optionsNew
    );
  } else {
    mainFaceId = getFaceIdFromFacet(facetId, meshOfInterest);
    mainFaceVertices = getFaceVerticesFromFacetID(
      facetId,
      meshOfInterest,
      space,
      optionsNew
    );
  }

  totalFaceVertices.push(..._.concat(...mainFaceVertices));
  const normal = getNormalVector(mainFaceVertices[0], false);
  if (!normal) return;

  let mainFaceNormal = BABYLON.Vector3.FromArray(normal).normalize();

  /*faces.forEach(face => {
        if (face.getIndex() !== mainFaceId){
            let secondaryFaceVertices = getFaceVerticesFromFace(face, meshOfInterest, space);
            let normal = getNormalVector(secondaryFaceVertices, false);
            if (!normal) return;
            let secondaryFaceNormal = BABYLON.Vector3.FromArray(normal).normalize();

            if (secondaryFaceNormal.almostEquals(mainFaceNormal)){
                if (_.intersectionWith(mainFaceVertices[0], secondaryFaceVertices, (v1, v2) => {
                    return v1.equals(v2);
                }).length !== 0){
                    totalFaceVertices.push(... secondaryFaceVertices);
                }
            }
        }
    });*/

  let neighbours = getVertexNeighbours(topVertex, meshOfInterest, space);

  let lowerVertices = _.differenceWith(
    neighbours,
    totalFaceVertices,
    (v1, v2) => {
      return v1.almostEquals(v2);
    }
  );

  if (lowerVertices.length === 1) {
    return lowerVertices[0];
  } else {
    // will try to determine the lower vertex by fitting a plane through totalFaceVertices
    // and seeing if any point is not on the plane

    // if one such point is available it'll be the lowerVertex
    // but this won't cover all cases

    const plane = getBabylonPlane(totalFaceVertices);
    const threshold = 1e-3;

    const filteredVertices = lowerVertices.filter((v) => {
      const d = plane.signedDistanceTo(v);
      return d > threshold;
    });

    if (filteredVertices.length === 1) return filteredVertices[0];
    else return null;
  }
}

function getVertexNeighbours(vertexPosition, mesh, space) {
  let vertexObject = getVertexObjectFromPositionV3(mesh, vertexPosition, space);
  if (!vertexObject) return;
  let neighbourObjects = snapmda.VertexNeighbors(vertexObject);
  // let neighbourObjects = VertexNeighbors(vertexObject);

  return neighbourObjects.map((neighbour) => {
    return getPositionFromVertex(neighbour, mesh, space);
  });
}

function getVertexFaces(component, vertexV3){
   const vertexObject = getVertexObjectFromPositionV3(component.mesh, vertexV3);
   return snapmda.VertexFaces(vertexObject);
}

function getFaceObjectFromVectors(points, mesh, space) {

  const _getCommonFaces = function (arrayOfFaces){
    let commonFaces = arrayOfFaces[0];

    for (
      let i = 0;
      i < arrayOfFaces.length - 1;
      i++
    ) {
      let arrayOfFaces2 = arrayOfFaces[i + 1];

      commonFaces = _.intersectionWith(
        commonFaces,
        arrayOfFaces2,
        (faceObject1, faceObject2) => {
          return faceObject1.getIndex() === faceObject2.getIndex();
        }
      );
    }

    return commonFaces;
  }

  const component = mesh.getSnaptrudeDS();

  let vertexObjects = points.map((v3) => {
    return getVertexObjectFromPositionV3(mesh, v3, space, {
      sendMultiple: true,
    });
  });

  let arrayOfMultipleFacesAssociatedWithEachVertex = vertexObjects.map(
    (vertexObject) => {
      if (_.isArray(vertexObject)) {
        return _.flatten(vertexObject.map((vo) => snapmda.VertexFaces(vo)));
        // return _.flatten(vertexObject.map((vo) => VertexFaces(vo)));
      } else {
        return snapmda.VertexFaces(vertexObject);
        // return VertexFaces(vertexObject);
      }
    }
  );

  let commonFaces = _getCommonFaces(arrayOfMultipleFacesAssociatedWithEachVertex);

  if (commonFaces.length > 1) {

    let vertexObjects = points.map((v3) => {
      return getVertexObjectFromPositionV3(mesh, v3, space, {
        sendMultiple: false,
      });
    });

    const edgeObjects = vertexObjects.map(vo1 => {
      const vo2 = getCircularlyNextElementInArray(vertexObjects, vo1);
      return getEdgeObjectFromVertexObjects(component, vo1, vo2);
    });

    if (_.compact(edgeObjects).length !== edgeObjects.length) return null;

    const arrayOfMultipleFacesAssociatedWithEachEdge = edgeObjects.map(eo => {
      return getEdgeFaces(eo);
    });

    let commonFaces = _getCommonFaces(arrayOfMultipleFacesAssociatedWithEachEdge);

    if (commonFaces.length > 1) return null;
    else return commonFaces[0];
  }
  else return commonFaces[0];
}

function reCenterBrepPositions(component){
   const currentGlobalPosition = component.mesh.getAbsolutePosition();

   const localPositions = component.brep.getPositions();
   const localPositionsV3 = localPositions.map(p => _.isEmpty(p) ? null : BABYLON.Vector3.FromArray(p));

   const filteredLocalPositions = localPositions.filter(p => !_.isEmpty(p));
   const filteredLocalPositionsV3 = filteredLocalPositions.map(p => BABYLON.Vector3.FromArray(p));


   const currentLocalCentre = getCentroidOfVertices(filteredLocalPositionsV3);
   const newGlobalPosition = convertLocalVector3ToGlobal(currentLocalCentre, component.mesh);

   // const zeroVector = BABYLON.Vector3.Zero();
   const zeroVector = convertGlobalVector3ToLocal(currentGlobalPosition, component.mesh);

   const offsetVector = zeroVector.subtract(currentLocalCentre);

   const newLocalPositionsV3 = localPositionsV3.map(v3 => v3 ? v3.add(offsetVector) : null);
   component.brep.positions = newLocalPositionsV3.map(v3 => v3 ? v3.asArray() : []);

   component.mesh.BrepToMesh();


   if (isMeshThrowAway(component.mesh)){
     component.mesh.instances.forEach(i => {
       const newGlobalPosition = convertLocalVector3ToGlobal(currentLocalCentre, i);
       i.setAbsolutePosition(newGlobalPosition);
       computeWorldMatrix(i);
     });
   }
   else {
     component.mesh.setAbsolutePosition(newGlobalPosition);
     computeWorldMatrix(component.mesh);
   }

}

function removeEdgeAndReconstruct(meshOfInterest, edgeIndex) {
  /*
    makes sure that faces are 4 sided for now
     */
  function _verifySimplicity(hePrev, heNext) {
    let heNextNext = heNext.getNextHalfEdge();
    let hePrevPrev = snapmda.HalfEdgePrev(hePrev);
    // let hePrevPrev = HalfEdgePrev(hePrev);

    return heNextNext.getEdge().getIndex() === hePrevPrev.getEdge().getIndex();
  }

  const componentOfInterest = meshOfInterest.getSnaptrudeDS();
  let brep = componentOfInterest.brep;

  let materialIndices = [];
  brep.getFaces().forEach((f) => {
    materialIndices.push(f.materialIndex);
  });
  materialIndices = _.uniq(materialIndices);

  let edges = brep.getEdges();
  let edgeToBeRemoved = edges[edgeIndex];

  let halfEdge = edgeToBeRemoved.getHalfEdge();
  let halfEdgeNext = halfEdge.getNextHalfEdge();
  let halfEdgePrevious = snapmda.HalfEdgePrev(halfEdge);
  // let halfEdgePrevious = HalfEdgePrev(halfEdge);
  if (!_verifySimplicity(halfEdgePrevious, halfEdgeNext)) {
    return;
  }

  let halfEdgeFlip = edgeToBeRemoved.getHalfEdge().getFlipHalfEdge();
  let halfEdgeFlipNext = halfEdgeFlip.getNextHalfEdge();
  let halfEdgeFlipPrevious = snapmda.HalfEdgePrev(halfEdgeFlip);
  // let halfEdgeFlipPrevious = HalfEdgePrev(halfEdgeFlip);
  if (!_verifySimplicity(halfEdgeFlipPrevious, halfEdgeFlipNext)) {
    return;
  }
  let options = {};
  options.holes = true;
  let halfEdgeNextFlip = halfEdgeNext.getFlipHalfEdge();
  let vertex1Top = halfEdgeNextFlip.getVertex();
  let vertex2Top = halfEdgeNextFlip
    .getNextHalfEdge()
    .getNextHalfEdge()
    .getVertex();
  let newElements = snapmda.InsertEdgeOperator(brep, vertex1Top.getIndex(), vertex2Top.getIndex(), options);
  // let newElements = InsertEdgeOperator(brep, vertex1Top.getIndex(), vertex2Top.getIndex(), options);
  let faceToBeModified = halfEdgeNextFlip.face;
  if (newElements) {
    newElements.face.materialIndex = faceToBeModified.materialIndex;
    dimensionsTuner.addDefaultFaceMetadata(newElements.face, faceToBeModified);
  }

  let halfEdgeFlipNextFlip = halfEdgeFlipNext.getFlipHalfEdge();
  let vertex1Bottom = halfEdgeFlipNextFlip.getVertex();
  let vertex2Bottom = halfEdgeFlipNextFlip
    .getNextHalfEdge()
    .getNextHalfEdge()
    .getVertex();
  newElements = snapmda.InsertEdgeOperator(brep, vertex1Bottom.getIndex(), vertex2Bottom.getIndex(), options);
  // newElements = InsertEdgeOperator(brep, vertex1Bottom.getIndex(), vertex2Bottom.getIndex(), options);
  faceToBeModified = halfEdgeFlipNextFlip.face;
  if (newElements) {
    newElements.face.materialIndex = faceToBeModified.materialIndex;
    dimensionsTuner.addDefaultFaceMetadata(newElements.face, faceToBeModified);
  }

  /*
    EdgeMap has the edges referencing old vertex indices. So storing them now, to delete later.
     */
  let verticesIndexInEdgeMap = [];
  verticesIndexInEdgeMap.push([
    halfEdge.getVertex().getIndex(),
    halfEdgeFlip.getVertex().getIndex(),
  ]);
  verticesIndexInEdgeMap.push([
    halfEdgeNext.getVertex().getIndex(),
    halfEdgeNextFlip.getVertex().getIndex(),
  ]);
  verticesIndexInEdgeMap.push([
    halfEdgeFlipNext.getVertex().getIndex(),
    halfEdgeFlipNextFlip.getVertex().getIndex(),
  ]);
  verticesIndexInEdgeMap.push([
    halfEdgePrevious.getVertex().getIndex(),
    halfEdgePrevious.getFlipHalfEdge().getVertex().getIndex(),
  ]);
  verticesIndexInEdgeMap.push([
    halfEdgeFlipPrevious.getVertex().getIndex(),
    halfEdgeFlipPrevious.getFlipHalfEdge().getVertex().getIndex(),
  ]);

  // top and bottom faces for remove vertex
  removeFaceFromTheStructure(brep, halfEdgeNextFlip.getFace().getIndex());
  removeFaceFromTheStructure(brep, halfEdgeFlipNextFlip.getFace().getIndex());

  // side faces for remove vertex
  const side1Face = halfEdgeNext.getFace();
  const side2Face = halfEdgeFlipNext.getFace();
  removeFaceFromTheStructure(brep, side1Face.getIndex());
  removeFaceFromTheStructure(brep, side2Face.getIndex());

  removeEdgeFromTheStructure(brep, edgeIndex);
  removeEdgeFromTheStructure(brep, halfEdgeNext.getEdge().getIndex());
  removeEdgeFromTheStructure(brep, halfEdgePrevious.getEdge().getIndex());
  removeEdgeFromTheStructure(brep, halfEdgeFlipNext.getEdge().getIndex());
  removeEdgeFromTheStructure(brep, halfEdgeFlipPrevious.getEdge().getIndex());

  let verticesToCreateNewFace = [];
  verticesToCreateNewFace.push(halfEdgeNext.getNextHalfEdge().getVertex());
  verticesToCreateNewFace.push(halfEdgePrevious.getVertex());
  verticesToCreateNewFace.push(halfEdgeFlipNext.getNextHalfEdge().getVertex());
  verticesToCreateNewFace.push(halfEdgeFlipPrevious.getVertex());

  let faces = brep.getFaces();
  let newFace = snapmda.CreateFaceOperator(brep, verticesToCreateNewFace);
  // let newFace = CreateFaceOperator(brep, verticesToCreateNewFace);

  if (newFace) {
    faces.push(newFace);

    const side1Vertices = getVerticesFromEdgeObject(componentOfInterest, halfEdgeNext.getEdge());
    const side2Vertices = getVerticesFromEdgeObject(componentOfInterest, halfEdgeFlipNext.getEdge());

    const side1Edge = {
        headPt : side1Vertices[0],
        tailPt : side1Vertices[1],
    };

    const side2Edge = {
        headPt : side2Vertices[0],
        tailPt : side2Vertices[1],
    };

    if (areEdgesParallel(side1Edge, side2Edge)){
        // copy dimension properties from one of the faces
        dimensionsTuner.addDefaultFaceMetadata(newFace, side1Face);

        if (side1Face.autoDimensionData && side2Face.autoDimensionData){
            if (side1Face.autoDimensionData.movementAmount !== side2Face.autoDimensionData.movementAmount)
                dimensionsTuner.addAmbiguousFaces(newFace, componentOfInterest);
        }
        else dimensionsTuner.addAmbiguousFaces(newFace, componentOfInterest);
    }
    else {
        dimensionsTuner.addDefaultFaceMetadata(newFace);
        dimensionsTuner.addAmbiguousFaces(newFace, componentOfInterest);
    }
    if (materialIndices.length === 1) {
      newFace.materialIndex = materialIndices[0];
    }
    /*
        Doing vertices and edgeMap edition after everything else is done to prevent interference
         */
    let vertex0Top = halfEdge.getVertex();
    removeVertexFromTheStructure(brep, vertex0Top.getIndex());
    let vertex0Bottom = halfEdgeFlip.getVertex();
    removeVertexFromTheStructure(brep, vertex0Bottom.getIndex());

    let edgeMap = brep.getEdgeMap();
    verticesIndexInEdgeMap.forEach((indicesArray) => {
      let keys = brep.getEdgeKeys(indicesArray[0], indicesArray[1]);

      delete edgeMap[keys[0]];
      delete edgeMap[keys[1]];
    });

    meshOfInterest.BrepToMesh();
    return true;
  } else {
    return false;
  }
}

function removeVertexFromTheStructure(brep, vertexIndex) {
  /*
    let vertices = brep.getVertices();
    vertices.splice(vertexIndex, 1);
    brep.positions.splice(vertexIndex, 1);
    */

  /*
    let vLen = vertices.length;
    for(let i = 0; i < vLen; i++) {
        vertices[i].setIndex(i);
    }
    */

  /*
    This was the most straight forward solution to the problem.
    Ideally vertices should be removed, indices reassigned,
    corresponding positions sliced and edgeMap redone
     */

  brep.positions[vertexIndex] = [];
  deleteSystemGeneratedLabel(brep.vertices[vertexIndex]);
}

function removeEdgeFromTheStructure(brep, edgeIndex) {
  let edges = brep.getEdges();
  edges.splice(edgeIndex, 1);

  let eLen = edges.length;
  for (let i = 0; i < eLen; i++) {
    edges[i].setIndex(i);
  }
}

function removeFaceFromTheStructure(brep, faceIndex) {
  let faces = brep.getFaces();
  faces.splice(faceIndex, 1);

  let fLen = faces.length;
  for (let i = 0; i < fLen; i++) {
    faces[i].setIndex(i);
  }
}

function editEdgeMap(vertexIndex1, vertexIndex2) {
  // var keys = brep.getEdgeKeys(v1, v2);
  let brep;
  var keys = brep.getEdgeKeys(vertexIndex1, vertexIndex2);

  var edgeMap = brep.getEdgeMap();
  delete edgeMap[keys[0]];
  delete edgeMap[keys[1]];
}

function copyBrep(brep) {
  if (!brep) return;
  // const resurrect = new Resurrect({
  //   resolver: new Resurrect.NamespaceResolver(snapmda),
  //   cleanup: true
  // });
  return store.resurrect.resurrect(store.resurrect.stringify(brep));
}

function getAllVertices(component, space = BABYLON.Space.WORLD, options = {}) {
  if (!component.brep) return null;

  const brepPositions = component.brep.positions;

  const localV3s = _.compact(
    brepPositions.map((positionArray) => {
      if (!_.isEmpty(positionArray))
        return BABYLON.Vector3.FromArray(positionArray);
    })
  );

  if (space === BABYLON.Space.LOCAL) return localV3s;
  else if (space === BABYLON.Space.WORLD) {
    return localV3s.map((localV3) =>
      convertLocalVector3ToGlobal(localV3, component.mesh)
    );
  } else {
    console.log("Arre kehna kya chahte ho");
  }
}

function getAllEdges(component, space = BABYLON.Space.WORLD) {
  if (!component.brep) return null;

  const allEdgeObjects = component.brep.getEdges();

  const allEdges = allEdgeObjects.map((edgeObject) => {
    const vertices = getVerticesFromEdgeObject(component, edgeObject, space);
    return {
      headPt: vertices[0],
      tailPt: vertices[1],
    };
  });

  return allEdges;
}

function getMostLikelyUnitNormalToFace(component, face, options = {}) {
  function _getNormal(faceVertices) {
    // console.warn('Calculating normal using vertices, things could go wrong');

    let centreOfFace = getCentroidOfVertices(faceVertices);
    let normalVector;

    try {
      normalVector = getUnitNormalVectorV3(faceVertices);
    } catch (err) {
      return;
    }

    if (!normalVector) return;

    let normalVectorArray = [normalVector, normalVector.negate()];

    let distances = normalVectorArray
      .map((v) => centreOfFace.add(v))
      .map((v) => BABYLON.Vector3.Distance(v, BABYLON.Vector3.Zero()));

    const stdDev = getStandardDeviation(distances);
    if (stdDev < 0.01) return normalVector;

    return normalVectorArray[
      distances.findIndex((d) => d === _.max(distances))
    ];
  }

  function _getStoredNormal(faceId) {
    if (_.isEmpty(facetGlobalNormals)) return;

    const facetIds = component.faceFacetMapping[faceId];
    if (_.isEmpty(facetIds)) {
      return;
    }

    const normalNumberOfOccurrencesMapping = new Map();

    facetIds.forEach((facetId) => {
      const facetArea = getFacetArea(component.mesh, facetId);
      if (facetArea < 0.1) {
        return;
      }
      const storedNormal = facetGlobalNormals[facetId].normalize();

      const inMapping = _.find(
        Array.from(normalNumberOfOccurrencesMapping.keys()),
        (e) => e.almostEquals(storedNormal)
      );

      if (inMapping) {
        const occurrences = normalNumberOfOccurrencesMapping.get(inMapping);
        normalNumberOfOccurrencesMapping.set(inMapping, occurrences + 1);
      } else {
        normalNumberOfOccurrencesMapping.set(storedNormal, 1);
      }
    });

    if (normalNumberOfOccurrencesMapping.size === 0) return;
    // happens when all facets have zero-ish area

    if (normalNumberOfOccurrencesMapping.size === 1) {
      // all facets have the same normal
      return normalNumberOfOccurrencesMapping.keys().next().value;
    } else {
      // all facets don't have the same normal
      // will return the normal most facets have, if >75% of facets have it
      // if face has 2 facets and both are opposite it'll return null

      const sortedMap = new Map(
        [...normalNumberOfOccurrencesMapping.entries()].sort(
          (a, b) => b[1] - a[1]
        )
      );

      const mostOccurringPairArray = sortedMap.entries().next().value;
      const mostOccurringNormal = mostOccurringPairArray[0];
      const mostOccurrence = mostOccurringPairArray[1];

      const threshold = 0.66;
      // 2/3 kept as min requirement

      if (mostOccurrence > facetIds.length * threshold)
        return mostOccurringNormal;
      else return null;
    }
  }

  let mesh = component.mesh;
  // let facetGlobalNormals = mesh.getFacetDataParameters().facetNormals;
  // this thing not getting updated after wm change

  let numberOfFacets = mesh.facetNb;
  if (!numberOfFacets){
      mesh.updateFacetData();
      numberOfFacets = mesh.facetNb;
  }
  let facetGlobalNormals = _.range(0, numberOfFacets, 1).map(id => mesh.getFacetNormal(id));

  const faceVertices = getFaceVerticesFromFace(face, mesh, BABYLON.Space.WORLD);

  let normal;

  if (!mesh.isAnInstance || options.useStoredNormal) normal = _getStoredNormal(face.getIndex());
  if (!normal) normal = _getNormal(faceVertices);

  if (normal && options.confirmWithPick) {
    const faceCentre = getFaceCentre(faceVertices);
    const pushMag = 0.0005;
    if (
      isPointInsideTheMesh(
        faceCentre.add(normal.scale(pushMag)),
        component.mesh
      )
    ) {
      normal = normal.negate();
    }
  }

  return normal;
}

const getFaceCentre = function (faceVertices){
  return faceVertices
    .reduce((acc, v3) => acc.add(v3), BABYLON.Vector3.Zero())
    .scale(1 / faceVertices.length);
};

function getSomeFaceVertices(
  component,
  refVector,
  space = BABYLON.Space.WORLD,
  options = {}
) {
  function _iterator(face) {
    let faceVertices = getFaceVerticesFromFace(face, mesh, BABYLON.Space.WORLD);
    let normal = getMostLikelyUnitNormalToFace(component, face, options);
    if (!normal) return false;

    // In certain cases as site, similar normals could defer around the order 1e-1
    // User issue #96

    if (normal.almostEquals(refVector,1e-1)) {
      someFaceVertices = faceVertices;
      faceId = face.getIndex();
      faceIds.push(faceId);
      return true;
    } else {
      arrayOfFaceVertices.push(faceVertices);
      indicesOfFace.push(face.getIndex());
      arrayOfNormals.push(normal);
    }
  }

  let mesh = component.mesh;
  let brep = component.brep;
  if (!brep) return;

  let faces = brep.getFaces();

  let arrayOfFaceVertices = [];
  let indicesOfFace = [];
  let arrayOfNormals = [];

  const PI_PROXIMITY_THRESHOLD = 1e-3;

  let someFaceVertices = null;
  let faceId = null;
  let faceIds = [];

  if (options.allFaces) faces.forEach(_iterator);
  else faces.some(_iterator);

  if (!someFaceVertices) {
    const arrayOfAnglesFormedWithRefVector = arrayOfNormals.map((normal) => {
      let angle = getAngleBetweenVector(normal, refVector);
      if (isFloatEqual(angle, Math.PI, PI_PROXIMITY_THRESHOLD)) angle = 0;

      return angle;
    });

    const minAngle = _.min(arrayOfAnglesFormedWithRefVector);
    const minIndices = [];

    arrayOfAnglesFormedWithRefVector.forEach((angle, i) => {
      if (isFloatEqual(angle, minAngle)) minIndices.push(i);
    });

    const faceIndices = [];
    const arrayOfAverageFaceYValues = minIndices.map((index) => {
      const vertices = arrayOfFaceVertices[index];
      faceIndices.push(index);
      return vertices.reduce((acc, v) => v.y + acc, 0) / vertices.length;
    });

    let valueToCheck;
    if (refVector.almostEquals(BABYLON.Vector3.Up()))
      valueToCheck = _.max(arrayOfAverageFaceYValues);
    else if (refVector.almostEquals(BABYLON.Vector3.Down()))
      valueToCheck = _.min(arrayOfAverageFaceYValues);

    const lowYIndex = _.indexOf(arrayOfAverageFaceYValues, valueToCheck);
    const someFaceIndex = faceIndices[lowYIndex];

    someFaceVertices = arrayOfFaceVertices[someFaceIndex];
    faceId = indicesOfFace[someFaceIndex];
  }

  if (space === BABYLON.Space.LOCAL) {
    // TODO
    // when local vertices are queried, getFaceVerticesFromFace fetches local, converts to global and
    // in here again, we're converting to local. Inefficient.
    someFaceVertices = someFaceVertices.map((v) =>
      convertGlobalVector3ToLocal(v, mesh)
    );
  }

  options.faceId = faceId;
  options.faceIds = faceIds;

  if (!_.isBoolean(options.confirmWithPick)) options.confirmWithPick = true;

  return someFaceVertices;
}

function getBottomFaceVertices(component, space, options = {}) {
  options.useStoredNormal = true; // since flip vertical isn't used as often
  return getSomeFaceVertices(component, BABYLON.Vector3.Down(), space, options);
}

function getTopFaceVertices(component, space, options = {}) {
  options.useStoredNormal = true; // since flip vertical isn't used as often
  return getSomeFaceVertices(component, BABYLON.Vector3.Up(), space, options);
}

function sanitizeVertices(vertices, options){
  const copyOfVertices = deepCopyObject(vertices);
  sanitizeVerticesInPlace(copyOfVertices, options);

  return copyOfVertices;
}

/*

Removes duplicates, collinear vertices when they're close and dangling edges

WARNING - Edits the passed argument
 */
function sanitizeVerticesInPlace(vertices, options = {}) {
  // let resolveEngineUtils = new ResolveEngineUtils();
  let duplicateCheck = true;
  let overlapCheck = true;
  let collinearCheck = true;
  if (options.collinearCheck === false) collinearCheck = false;
  if (options.duplicateCheck === false) duplicateCheck = false;
  if (options.overlapCheck === false) overlapCheck = false;
  
  const duplicateThreshold = options.duplicateThreshold || 1e-3;
  const overlapThreshold = options.overlapThreshold || 1e-4;
  const collinearThreshold = options.collinearThreshold || 2;

  const length = vertices.length;
  vertices.some((vertex, i) => {
    const nextIndex = i === length - 1 ? 0 : i + 1;
    const previousIndex = i === 0 ? length - 1 : i - 1;

    const nextVertex = vertices[nextIndex];
    const previousVertex = vertices[previousIndex];

    let hataoIsko = false;
    if (duplicateCheck &&
      (
        vertex.almostEquals(previousVertex, duplicateThreshold) ||
        vertex.almostEquals(nextVertex, duplicateThreshold)
      )
    ) {
      hataoIsko = true;
    } else if (overlapCheck &&
      (
        store.resolveEngineUtils.onSegment3D(vertex, previousVertex, nextVertex, overlapThreshold) ||
        store.resolveEngineUtils.onSegment3D(vertex, nextVertex, previousVertex, overlapThreshold)
      )
    ) {

      if (options.onlyIfSystemGenerated && options.component){
        const vertexObject = getVertexObjectFromPositionV3(options.component.mesh, vertex);
        if (isElementSystemGenerated(vertexObject)) hataoIsko = true;
      }
      else hataoIsko = true;
    }

    if (
      !hataoIsko && collinearCheck &&
      store.resolveEngineUtils.onSegment3D(
        previousVertex,
        vertex,
        nextVertex
      )
    ) {
      // collinear vertices
      // remove only if small length

      if (
        getDistanceBetweenVectors(vertex, previousVertex) < collinearThreshold ||
        getDistanceBetweenVectors(vertex, nextVertex) < collinearThreshold
      ) {
        hataoIsko = true;
      }
    }

    if (hataoIsko) {
      _.pullAt(vertices, i);
      sanitizeVerticesInPlace(vertices, options);

      return true;
    }
  });
}

function honeyIShrunkTheMass(component, shrinkAmount, options = {}){

  const _shrink = function (){

    const faces = brep.getFaces();

    faces.forEach((face, i)=> {
      const faceId = face.getIndex();
      if (faceId === topFaceId || faceId === bottomFaceId) return;

      const faceVertices = getFaceVerticesFromFace(face, component.mesh);
      const normal = getMostLikelyUnitNormalToFace(component, face);
      if (!normal) return;

      const optionsForLowerVertex = { faceId };
      faceVertices.forEach(vertex => {
        const otherVertex = getLowerVertexOfTheEdge(vertex, component.mesh, optionsForLowerVertex);

        let direction = otherVertex.subtract(vertex).normalize();

         // check with face normal to handle concave sides correctly
        const angle = getAngleBetweenVectors(direction, normal);
        if (angle < 90) direction = direction.negate();

        const vertexToGoTo = vertex.add(direction.scale(shrinkAmount));

        const localVertexArray = convertGlobalVector3ToLocal(vertex, component.mesh).asArray();
        const localVertexToGoTo = convertGlobalVector3ToLocal(vertexToGoTo, component.mesh).asArray();

        positions.some(p => {
          if (store.resolveEngineUtils.areArraysAlmostEqual(p, localVertexArray)) {
            p[0] = localVertexToGoTo[0];
            p[1] = localVertexToGoTo[1]
            p[2] = localVertexToGoTo[2];

            return true;
          }
        });

      });
    });

    component.mesh.BrepToMesh();
  };

  const _shrinkWithMoveFace = function (){

    const faces = brep.getFaces();

    // this has to be done before edit begins because otherwise, since, brep gets modified in the loop
    // graph queries in the later iterations will not fetch results

    const movementData = [];

    faces.forEach(face => {
      const faceId = face.getIndex();
      if (faceId === topFaceId || faceId === bottomFaceId) return;

      const faceVertices = getFaceVerticesFromFace(face, component.mesh);
      const refEdge = doesComponentGrowDownwards
        ? getUpperEdgeFromFaceVertices(faceVertices)
        : getLowerEdgeFromFaceVertices(faceVertices);

      const edge = virtualSketcher.lookupEdge(refEdge.headPt, refEdge.tailPt);
      if (!edge) {
        console.warn("Problem with face", faceId);
        return;
      }
      
      const masses = edge.components.filter (c => c.type.toLowerCase() === "mass");

      const sameDirectionComponents = masses.filter(
        (c) =>
          virtualSketcher.util.doesComponentGrowDownwards(c) ===
          doesComponentGrowDownwards
      );
      
      const sameDirectionOtherComponentsWithWalls = sameDirectionComponents.filter(c => {
        if (c === component) return false;
        
        const roomTypeProperties = getRoomTypeProperties(c.mesh.room_type);
        if (roomTypeProperties.bim.noWalls) return false;
        else return true;
      });
      
      const currentRoomTypeProperties = getRoomTypeProperties(
        component.mesh.room_type
      );
      
      let shrinkAmount;
      
      let internal = store.projectProperties.properties.wallThicknessPropertyInt.getValue() / 2;
      let external = store.projectProperties.properties.wallThicknessPropertyExt.getValue() / 2;
      
      if (sameDirectionOtherComponentsWithWalls.length >= 1){
        if (currentRoomTypeProperties.bim.noWalls){
          shrinkAmount = external;
        }
        else shrinkAmount = internal;
      }
      else if (sameDirectionOtherComponentsWithWalls.length === 0){
        if (currentRoomTypeProperties.bim.noWalls){
          shrinkAmount = 0;
        }
        else shrinkAmount = external;
      }

      /*let shrinkAmount =
        sameDirectionComponents.length > 1
          ? store.projectProperties.properties.wallThicknessPropertyInt.getValue() / 2
          : store.projectProperties.properties.wallThicknessPropertyExt.getValue() / 2;

      if (isRoomOfType(component.mesh, "ground")){
        shrinkAmount =
          sameDirectionComponents.length > 1
            ? store.projectProperties.properties.wallThicknessPropertyExt.getValue() / 2
            : 0;
      }*/

      if (shrinkAmount === 0) return;

      const normal = getMostLikelyUnitNormalToFace(component, face, {
        confirmWithPick: true,
      });
      
      if (!normal) {
        console.warn("Problem with face", faceId);
        return;
      }
      
      let movementDirection = normal.negate();

      /*const optionsForLowerVertex = { faceId };

      let movementDirection;
      faceVertices.some(vertex => {
        const otherVertex = getLowerVertexOfTheEdge(vertex, component.mesh, optionsForLowerVertex);
        if (!otherVertex) return;

        let direction = otherVertex.subtract(vertex).normalize();

        // check with face normal to handle concave sides correctly
        const angle = getAngleBetweenVectors(direction, normal);

        if (isFloatEqual(angle, 90, 3)) {
          // do nothing try the next vertex
        }
        else if (angle < 90) movementDirection = direction.negate();
        else movementDirection = direction;

        return !!movementDirection;
      });

      if (!movementDirection) movementDirection = normal;*/

      movementData.push({
        edge : refEdge,
        movementAmount : shrinkAmount,
        movementDirection,
        doesEdgeBelongToTopFace : doesComponentGrowDownwards
      });
    });

    const moveOptions = {
      component,
      saveResults : false,
      movementData,
      programmaticUseOfMoveFace : true,
      commands : [],
      parametricEditsDisabled: true,
      massShrinkageDuringCB: true
    };

    const moved = moveFace.justMoveIt(moveOptions);
    const allCommands = _.compact(moveOptions.commands);

    return commandUtils.geometryChangeOperations.flattenCommands(allCommands);
  };

  const brep = component.brep;
  if (!brep) return;

  const topFaceId = geometryUpdater.util.getTopFaceId(component);
  const bottomFaceId = geometryUpdater.util.getBottomFaceId(component);
  const doesComponentGrowDownwards = virtualSketcher.util.doesComponentGrowDownwards(component);

  const positions = brep.getPositions();

  const shrinkFunction = options.useGraph ? _shrinkWithMoveFace : _shrink;

  if (options.useGraph){
    return shrinkFunction();
  }
  else if (options.generateCommand){
    return commandUtils.geometryChangeOperations.expressCheckout(
      "waterBodyShrinkCB",
      component.mesh,
      shrinkFunction
    );
  }
  else shrinkFunction();

}

const getFaceNeighbours = function (brep, face){
  const HEs = snapmda.FaceHalfEdges(face);
  
  const neighboringFaces = [];
  HEs.forEach(he => {
    const neighboringFace = he.getFlipHalfEdge().getFace();
    neighboringFaces.pushIfNotExist(neighboringFace);
  });
  
  return neighboringFaces;
}

const getFaceToFaceDistances = function (component){
  if (!component.brep) return;
  
  const brep = component.brep;
  const faces = brep.getFaces();
  
  const faceMidPointMap = new Map();
  
  faces.forEach(face => {
    const faceVertices = getFaceVerticesFromFace(face, component.mesh);
    const midPoint = getFaceCentre(faceVertices);
    
    faceMidPointMap.set(face, midPoint);
  });
  
  const distances = faces.map(face => {
    const neighboringFaces = getFaceNeighbours(brep, face);
    const nonNeighboringFaces = _.difference(faces, neighboringFaces, [face]);
    
    const p1 = faceMidPointMap.get(face);
    return nonNeighboringFaces.map(neighboringFace => {
      const p2 = faceMidPointMap.get(neighboringFace);
  
      return getDistanceBetweenVectors(p1, p2);
    });
  });
  
  return distances;
};

const isComponentThin = function (component, threshold){
  threshold = threshold || mmToSnaptrudeUnits(50);
  
  const distances = getFaceToFaceDistances(component);
  const flattenedDistances = _.flatten(distances);
  const minD = _.min(flattenedDistances);
  
  return minD < threshold;
}

const _getBottomVerticesOfTheFace = function (face, mesh, options = {}){
  let _faceVertices = [];

  if(options && options.faceVertices){
    _faceVertices = options.faceVertices;
  }
  else{
    _faceVertices = getFaceVerticesFromFace(face, mesh);
  }
  if( !_.isEmpty(_faceVertices) ){
    let _leastY = _faceVertices[0]._y;
    let _bottomVertices = [];

    for ( let i = 0; i < _faceVertices.length; i++ ){
      if( _leastY > _faceVertices[i]._y ){
        _leastY = _faceVertices[i]._y;
      }
    }

    _faceVertices.forEach( v => {
      if( v._y.toFixed(2) === _leastY.toFixed(2) ) {
        _bottomVertices.push(v);
      }
    });

    return _bottomVertices;
  }
}

const _getLengthOfTheWall = function ( _bottomVertices ){

  let _lengthOfTheWall = 0;
  if(_bottomVertices.length === 2){
    _lengthOfTheWall = getDistanceBetweenVectors(_bottomVertices[0], _bottomVertices[1]);
  }
  else{
    for( let i = 0; i < _bottomVertices.length; i++ ){
      let _eachvertex = _bottomVertices[i];
      let _nextVertex = _bottomVertices[ (i+1) % _bottomVertices.length ];
      let _distance = getDistanceBetweenVectors(_eachvertex, _nextVertex);
      if( _distance > _lengthOfTheWall ){
        _lengthOfTheWall = _distance;
      }
    }
  }

  return _lengthOfTheWall;
}

const _getSideFaces = function( component ) {

  let _brep = component.brep;
  let _mesh = component.mesh;

  let _allFaces = _brep.getFaces();
  // const topFaceId = geometryUpdater.util.getTopFaceId(component);
  // const bottomFaceId = geometryUpdater.util.getBottomFaceId(component);
  let _faceLengths = [];
  let _sideFaceOfInterest = null;

  if(!_.isEmpty(_allFaces)){
    for( let i = 0; i < _allFaces.length; i++ ){
      let _eachFace = _allFaces[i];
      let _faceNormalToTopFace = BABYLON.Vector3.Up();
      let _faceNormalToBottomFace = BABYLON.Vector3.Down();
      let _normalToFace = getMostLikelyUnitNormalToFace(component, _eachFace);

      //Ignore top and bottom faces and all the ones that are parallel to them.
      if( !_normalToFace.almostEquals(_faceNormalToTopFace) && !_normalToFace.almostEquals(_faceNormalToBottomFace) )
      {
        let _bottomVerticesOfTheFace = _getBottomVerticesOfTheFace(_eachFace, _mesh);
        let _lengthOfTheWall = _getLengthOfTheWall(_bottomVerticesOfTheFace);
        _faceLengths.push({index: _eachFace.index, length: _lengthOfTheWall});
      }
    }
  }

  if(!_.isEmpty(_faceLengths)){
    let _maxLength = _faceLengths[0].length;
    for( let i = 0; i < _faceLengths.length; i++ ){
      if(_faceLengths[i].length >= _maxLength){
        _maxLength = _faceLengths[i].length;
        _sideFaceOfInterest = _faceLengths[i];
      }
    }
  }
  return _sideFaceOfInterest;
}

const _getSideFacesWithOutComponent = function ( brep, mesh ){

  let _allFaces = brep.getFaces();
  let _faceLengths = [];
  let _sideFaceOfInterest = null;
  mesh.brep = brep;

  for( let i = 0; i < _allFaces.length; i++ ) {
    let _eachFace = _allFaces[i];
    let _faceNormalToTopFace = BABYLON.Vector3.Up();
    let _faceNormalToBottomFace = BABYLON.Vector3.Down();
    let _faceVertices = getFaceVerticesFromFace(_eachFace, mesh, BABYLON.Space.WORLD, {brepInMesh: true})

    let _normalToFace = getUnitNormalVectorV3CyclicCheck(_faceVertices, true);
    if( !_normalToFace.almostEquals(_faceNormalToTopFace) && !_normalToFace.almostEquals(_faceNormalToBottomFace) )
    {
      let _bottomVerticesOfTheFace = _getBottomVerticesOfTheFace(_eachFace, mesh, {faceVertices: _faceVertices});
      let _lengthOfTheWall = _getLengthOfTheWall(_bottomVerticesOfTheFace);
      _faceLengths.push({index: _eachFace.index, length: _lengthOfTheWall});
    }
  }

  if(!_.isEmpty(_faceLengths)){
    let _maxLength = _faceLengths[0].length;
    for( let i = 0; i < _faceLengths.length; i++ ){
      if(_faceLengths[i].length >= _maxLength){
        _maxLength = _faceLengths[i].length;
        _sideFaceOfInterest = _faceLengths[i];
      }
    }
  }

  return _sideFaceOfInterest;
}

const _offSetSideFace = function (sideFaceVertices, wallThickness){

  let _normal = getUnitNormalVectorV3CyclicCheck(sideFaceVertices, true);
  let _vectorInTheOppositeDirectionOfTheNormal = BABYLON.Vector3.Zero();

  _vectorInTheOppositeDirectionOfTheNormal.x = -1 * _normal._x;
  _vectorInTheOppositeDirectionOfTheNormal.y = -1 * _normal._y;
  _vectorInTheOppositeDirectionOfTheNormal.z = -1 * _normal._z;

  let _distanceToOffset = wallThickness/2;
  let _pointsAfterOffset = [];

  for( let i = 0; i < sideFaceVertices.length; i++ ) {
    let _eachVertex = sideFaceVertices[i];
    let _eachVertexAfterOffset = BABYLON.Vector3.Zero();

    _eachVertexAfterOffset._x = _eachVertex._x + _distanceToOffset * _vectorInTheOppositeDirectionOfTheNormal.x;
    _eachVertexAfterOffset._y = _eachVertex._y + _distanceToOffset * _vectorInTheOppositeDirectionOfTheNormal.y;
    _eachVertexAfterOffset._z = _eachVertex._z + _distanceToOffset * _vectorInTheOppositeDirectionOfTheNormal.z;

    _pointsAfterOffset.push(_eachVertexAfterOffset);
  }

  return _pointsAfterOffset;
}


const _getMiddleSectionOfAWall = function ( component, options = {} ){

  try{
    let _brep;
    let _mesh;
    let _sideFaceInfo;
    let _pointsAfterOffsettingSideFace = [];
    let _wallThickness;

    if( options && options.componentDoesNotExist ){
      _brep = options.brep;
      _mesh = options.mesh;
      _sideFaceInfo = _getSideFacesWithOutComponent( _brep, _mesh );
      _wallThickness = options.wallThickness;
    }
    else{
      _brep = component.brep;
      _mesh = component.mesh;
      _sideFaceInfo = _getSideFaces( component );
      _wallThickness = component.calculateWidth();
    }

    if( _brep ){
      if(_sideFaceInfo){
        let _faceOfInterest = _brep.getFaces()[_sideFaceInfo.index];
        _mesh.brep = _brep;
        let _faceVertices = getFaceVerticesFromFace(_faceOfInterest, _mesh, BABYLON.Space.WORLD, { brepInMesh: true });
        _pointsAfterOffsettingSideFace = _offSetSideFace(_faceVertices, _wallThickness);
        delete _mesh.brep;
      }
      return _pointsAfterOffsettingSideFace;
    }
  }
  catch (e){
    console.log("Error fetching profile of the wall");
  }
}

export {
  generateMeshFromBrep,
  generateBrepFromPositionsAndCells,
  correctVerticesOrderOfBottomFaceXZ,
  convertBrepPositionsFromTypedArrayToArray,
  generateBrepForComponents,
  verifyBRepIntegrity,
  sortFaceIndicesForHalfEdge,
  getFaceIdFromFacet,
  splitFaceByFacetId,
  isElementSystemGenerated,
  labelElementAsSystemGenerated,
  removeSystemGeneratedLabel,
  deleteSystemGeneratedLabel,
  labelVerticesAndEdgesAsSystemGenerated,
  splitFaceByFaceObject,
  getVerticesFromEdgeObject,
  getFaceVerticesFromFacetID,
  getFaceVerticesFromFace,
  populateBrepIndexes,
  updateBrepPositions,
  getVertexIndexFromPositionArray,
  getVertexObjectFromPositionArray,
  getVertexObjectFromPositionV3,
  getPositionFromVertex,
  getEdgeObjectFromVertexPositions,
  getEdgeObjectFromVertexObjects,
  findNearestEdgeFromBRep,
  findNearestGeomEdgeFromBrep,
  findNearestVertexFromBRep,
  removeVertexFromComponent,
  getLowerVertexOfTheEdge,
  getVertexNeighbours,
  getFaceObjectFromVectors,
  removeEdgeAndReconstruct,
  removeVertexFromTheStructure,
  removeEdgeFromTheStructure,
  removeFaceFromTheStructure,
  editEdgeMap,
  copyBrep,
  getAllVertices,
  getAllEdges,
  getMostLikelyUnitNormalToFace,
  getSomeFaceVertices,
  getBottomFaceVertices,
  getTopFaceVertices,
  sanitizeVertices,
  sanitizeVerticesInPlace,
  getLowerEdgeFromFaceVertices,
  honeyIShrunkTheMass,
  getUpperEdgeFromFaceVertices,
  deleteEdge,
  deleteVertex,
  isComponentThin,
  getFaceCentre,
  getEdgeObjectsAroundTheFace,
  getEdgeAdjacentFace,
  getVertexFaces,
  getEdgeFaces,
  _getMiddleSectionOfAWall,
  extrudeFace,
  getEdgeVertices,
  reCenterBrepPositions,
  extractFaceBrepMeshFromGivenFaceId,
};
