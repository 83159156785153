import React from "react";
import { withRouter } from "react-router-dom";
import * as constants from "./constants";
import Toggle from "../../components/Toggle";
import { paymentStyles } from "./styles";
import { getCoupon, getCurrency, getPlans, updatePlan } from "../../services/payment.service"

import { RowContainer, DataHeading, Flex, CustomText, Container, MainWrapper, HeaderWrapper, ArrowContainer, Heading, ValueText } from "./styles";
import backArrow from "../../assets/icons/back_arrow.svg";
import Divider from "../../components/Divider/index";
import NumberRange from "../../components/NumberRange/index";
import Input from "../../components/Input/index";
import { ButtonWrapper } from "./styles";

import Button from "../../components/Button/index";

import { loadStripe } from '@stripe/stripe-js';
import { STRIP_PUBLISHABLE_KEY } from "./constants";

import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from "./checkoutForm";
import mixpanel from "mixpanel-browser";
const stripePromise = loadStripe(STRIP_PUBLISHABLE_KEY);

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleState: props.location.state.period || 'Monthly',
      cardNumber: "",
      customerId: "",
      offerCodeError: null,
      noOfLicenses: 1,
      offerCode: "",
      coupon: {},
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: null,
      country: "India",
      onCheckoutError: false,
      currency: "usd",
      planName: "",
      price: {
        currency: 'usd',
        interval: props.location.state.period || 'Monthly',
        id: '',
        unitAmount: 120000
      },
      isSubscribed: false
    };
  }

  componentDidMount() {
    window.analytics.track('opened payments page');
    this.setState(this.props.location.state);
    getPlans().then((data) => {
      console.log("currentPlans: ", data);
      this.setState(
        {
          noOfLicenses: data.currentMaxSize,
          customerId: data.stripeCustomerId,
          isSubscribed: data.isSubscribed,
        }
      );

      getCurrency().then(currency =>{
        this.setState({ currency: currency })
        this.setPrice(this.state.noOfLicenses, this.state.toggleState);
      })
    })
  }

  setPrice = (noOfLicenses, intervalToggleState) => {
    // let price = this.state.prices.filter(price => price.currency == this.state.currency && price.interval == interval)[0];
    let price = constants.PRICES[this.state.plan][this.state.currency][intervalToggleState]
    let unitAmount = this.getDiscountUnitAmount(price);
    if (price) {
      price.displayAmount = `${constants.CURRENCY_SYMBOLS[this.state.currency]}  ${unitAmount / 100}`;
      price.displayTotalAmount = `${constants.CURRENCY_SYMBOLS[this.state.currency]}  ${(unitAmount / 100) * noOfLicenses}`;

      price.totalSaved = (price.unitAmount / 100) * noOfLicenses - (unitAmount / 100) * noOfLicenses

      this.setState({price: price})
    }
    return price;
  }

  getDiscountUnitAmount = (price) => {
    if (!this.state.coupon) return price.unitAmount;
    if (!this.state.coupon.percent_off) return price.unitAmount;

    return price.unitAmount - (price.unitAmount / 100) * this.state.coupon.percent_off;
  }

  setFormProperty = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  setError(state, value) {
    this.setState({ [state]: value });
  }

  setNoOfLicences = (newVal) => {
    newVal = newVal < 0 ? 0 : newVal;

    this.setState({ noOfLicenses: newVal });
    this.setPrice(newVal, this.state.toggleState)
  }

  updateNoOfLicences = (decrement) => {
    const { noOfLicenses } = this.state;
    let newVal = noOfLicenses;
    if (decrement) {
      newVal = newVal - 1 >= 1 ? newVal - 1 : newVal;
    } else {
      newVal += 1;
    }
    this.setState({ noOfLicenses: newVal });
    this.setPrice(newVal, this.state.toggleState)
  };

  handleCardValueChange = (e) => {
    this.setState({
      cardNumber: this.formatCardNumber(e.target.value).trim(),
    });
  };

  formatCardNumber = (cardValue) => {
    var value = cardValue.replace(/\D/g, "");
    var formattedValue;
    if (/^3[47]\d{0,13}$/.test(value)) {
      formattedValue = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
      formattedValue = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (/^\d{0,16}$/.test(value)) {
      formattedValue = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
        .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
    }
    return formattedValue;
  };

  handleToggle = () => {
    const { toggleState } = this.state;
    if (toggleState === constants.MONTHLY) {
      this.setState({ toggleState: constants.YEARLY });
      this.setPrice(this.state.noOfLicenses, constants.YEARLY);
    } else {
      this.setState({ toggleState: constants.MONTHLY });
      this.setPrice(this.state.noOfLicenses, constants.MONTHLY);
    }
  };

  getDetailsRow = (header, valueElement) => {
    return (
      <RowContainer>
        <DataHeading>{header}</DataHeading>
        <div style={paymentStyles.rowWidth}>{valueElement}</div>
      </RowContainer>
    );
  };

  getToggleState = () => {
    const { toggleState } = this.state;

    return (
      <Flex justifyContent="space-around">
        <CustomText
          size="0.875rem"
          lineHeight="1.125rem"
          color="primeBlack"
          opacity={toggleState === constants.MONTHLY ? 1 : 0.6}
          weight={toggleState === constants.MONTHLY ? "bold" : "normal"}
        >
          {constants.MONTHLY}
        </CustomText>
        <Toggle handleClick={this.handleToggle} state={toggleState === constants.YEARLY} />
        <CustomText
          size="0.875rem"
          lineHeight="1.125rem"
          color="primeBlack"
          opacity={toggleState === constants.YEARLY ? 1 : 0.6}
          weight={toggleState === constants.YEARLY ? "bold" : "normal"}
        >
          {constants.YEARLY}
        </CustomText>
      </Flex>
    );
  };

  render() {
    const { history } = this.props;
    const {
      noOfLicenses,
      offerCode,
      planType,
      currency
    } = this.state;


    return (
      <Container>
        <MainWrapper>
          <HeaderWrapper>
            <ArrowContainer
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={backArrow} width={8} height={11} alt="<" />
            </ArrowContainer>
            <Heading>{constants.MAKE_PAYMENT}</Heading>
          </HeaderWrapper>
          <RowContainer style={{ padding: "1rem 0" }}>
            <div style={paymentStyles.widthStyle}>
              {this.getDetailsRow(
                constants.YOUR_PLAN,
                <ValueText>{this.state.planName}</ValueText>
              )}
              {this.getDetailsRow(
                constants.NUMBER_OF_LICENCES,
                <NumberRange
                  value={noOfLicenses}
                  onChange={e => { this.setNoOfLicences(e.target.value)}}
                  onIncrement={() => this.updateNoOfLicences(false)}
                  onDecrement={() => this.updateNoOfLicences(true)}
                />
              )}
              {this.getDetailsRow(
                constants.APPLY_CODE,
                <Input
                  type={"text"}
                  value={offerCode}
                  errorMessage={this.state.offerCodeError}
                  error={!!offerCode && !!this.state.offerCodeError}
                  onPress={
                    (e) => {
                      this.setFormProperty("offerCode", e.target.value)
                      getCoupon(e.target.value).then((data) => {
                        console.log(data);
                        if (data.error) {
                          this.setState({ coupon: {} })
                          this.setState({ offerCodeError: data.error})
                        } else {
                          this.setState({ offerCodeError: null })
                          this.setState({ coupon: data })
                        }
                        this.setPrice(this.state.noOfLicenses, this.state.toggleState);
                      });
                    }
                  }
                />
              )}
            </div>
            <div style={paymentStyles.widthStyle}>
              {this.getDetailsRow(
                constants.LICENSE_COST,
                <RowContainer style={{ justifyContent: "flex-start" }}>
                  <ValueText>
                    { this.state.price.displayAmount }
                  </ValueText>
                  <ValueText style={{ fontSize: "0.75rem" }}>
                    {this.state.toggleState === constants.MONTHLY
                      ? constants.PER_USER_MONTH
                      : constants.PER_USER_YEAR}
                  </ValueText>
                </RowContainer>
              )}
              {this.getDetailsRow(constants.BILLED, this.getToggleState())}
              {this.getDetailsRow(
                constants.TOTAL_BILLED,
                <div style={{ flexDirection: "column" }}>
                  <ValueText>
                    {this.state.price.displayTotalAmount}
                  </ValueText>
                  {this.state.price.totalSaved ?
                    <ValueText style={paymentStyles.savingText}>
                      {constants.YOU_SAVED(this.state.price.totalSaved, constants.CURRENCY_SYMBOLS[this.state.currency])}
                    </ValueText>
                    : <></>
                  }
                </div>
              )}
            </div>
          </RowContainer>
          <Divider />
          {!this.state.isSubscribed &&

            <Elements stripe={stripePromise}>
              <CheckoutForm
                customerId={this.state.customerId}
                noOfLicenses={this.state.noOfLicenses}
                couponId={this.state.offerCode}
                priceId={this.state.price.id}
              />
            </Elements>
          }
          {this.state.isSubscribed &&
            <ButtonWrapper>
              <Button
                primary={true}
                // type="submit"
                title={"Update"}
                customButtonStyle={paymentStyles.checkoutButton}

                onPress={
                  () => {updatePlan(this.state.noOfLicenses, this.state.price.id)}
                }
              />
            </ButtonWrapper>
          }
        </MainWrapper>
      </Container>
    );
  }
}

// LoginSignupPage.propTypes = {
//   history: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default withRouter(Payment);
