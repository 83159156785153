export const PROJECT_PROPERTIES = {
  "properties": {
      "slabThicknessProperty": {
        "getValue": () => 0.5905511811023622,
      },
      "wallTypePropertyExt": {
        "getValue": () => "BRICK"
      },
      "wallThicknessPropertyExt": {
        "getValue": () => 0.7874015748031497
      },
      "wallTypePropertyInt": {
        "getValue": () => "BRICK"
      },
      "wallThicknessPropertyInt": {
        "getValue": () => 0.3937007874015748
      },
      "wallTypePropertyParapet": {
        "getValue": () => "GLASS"
      },
      "wallThicknessPropertyParapet": {
        "getValue": () => 0.0984251968503937
      },
      "angleSnapThreshold": {
        "getValue": () => 15
      },
      "angleSnapEnabled": {
        "getValue": () => true
      },
      "disableTooltips": {
        "getValue": () => false
      },
      "constraintSolverThreshold": {
        "getValue": () => 0.03937007874015748
      },
      "plinthHeightProperty": {
        "getValue": () => 1.1811023622047243
      },
      "toggleInternalDimensionsProperty": {
        "getValue": () => false
      },
      "toggleNearestPointSnap": {
        "getValue": () => false
      },
  }
}