"use strict";
/*jshint esversion: 6 */
import BABYLON from "../../babylonDS.module.js";
import $ from "jquery";
import _ from "lodash";
import { store } from "../../utilityFunctions/Store.js"
import { ComponentManager } from "../../componentManager/componentManager.js";
import { StructureCollection } from "../../snaptrudeDS/structure.ds.js";
import { setUserSettingAndRecord } from "../../extrafunc.js";
import { commandUtils } from "../../commandManager/CommandUtils.js";
import { CommandManager } from "../../commandManager/CommandManager.js";
import { convertVector3To2DArray } from "../../../libs/interiors_gen.js";
import { getBottomFaceVertices } from "../../../libs/brepOperations.js";
import { arrangeBedComp,arrangeWardrobeComp } from "../../../libs/bedroom.js";
import { arrangeSofaComp,arrangeTableComp,arrangeChairComp,arrangeCoffeeTableComp,arrangeSideTableComp } from "../../../libs/living.js";
import { arrangeKitchen1Comp,arrangeKitchen2Comp } from "../../../libs/kitchen.js";
import { arrangeBathroomComp } from "../../../libs/bathroom.js";
import { arrangeDiningTableComp,arrangePaintingComp } from "../../../libs/dining.js";
import { setLayerTransperancy } from "../../../libs/sceneFuncs.js";
import { ORIGIN } from "../../../../services/url.constants.js";
const AutoInterior = (function () {
  const PRIMARYSTOREY = 1;
  // const DOMAIN = window.location.origin;
  const DOMAIN = ORIGIN;
  const FORMAT = ComponentManager.FORMAT.BABYLON;
  const INTERIORNAMES = {
    BED: "bed01",
    WARDROBE: "wardrobe01",
    SOFA: "sofa01",
    CHAIR: "chair01",
    SIDETABLE: "stable01",
    COFFEETABLE: "ctable01",
    TABLE: "table01",
    KITCHEN: "kitchen01",
    DINING: "dining01",
    PAINTING: "painting01",
    BATHROOM: "bathroom01",
  };
  const AUTOINTERIORONCOMMAND = "autoInteriorOn";
  const AUTOINTERIOROFFCOMMAND = "autoInteriorOff";

  let interiorMetaData = [];
  let hosts = [];
  let loading = false;

  function _getStructure() {
    return StructureCollection.getInstance().getStructures()[
      store.activeLayer.structure_id
    ];
  }

  function _getSimplifiedRoomType(roomType) {
    return roomType.split(/(?<=^\S+)\s/)[0].toLowerCase();
  }

  function _populateHosts() {
    let structure = _getStructure();
    let levels = structure.getAllLevels();

    for (let levelId in levels) {
      let level = levels[levelId];
      let filteredFloors = level.flyweight.floors.filter((floor) =>
        floor.hasNonDefaultRoomType()
      );
      let filteredMasses = level.flyweight.masses.filter((mass) =>
        mass.hasNonDefaultRoomType()
      );

      hosts.push(...filteredFloors);
      hosts.push(...filteredMasses);
    }
  }

  function _prepareInteriorData() {
    let roomTypes = hosts.map((host) => host.room_type);
    roomTypes = roomTypes.map((r) => r.toLowerCase());
    roomTypes = _.uniq(roomTypes);

    let components = autoInteriorData.components;
    let defaultTypes = Object.keys(components);
    let eligibleDefaultTypes = [];

    for (let roomType of roomTypes) {
      for (let defaultType of defaultTypes) {
        if (roomType.indexOf(defaultType) !== -1) {
          eligibleDefaultTypes.push(defaultType);
          break;
        }
      }
    }

    eligibleDefaultTypes = _.uniq(eligibleDefaultTypes);

    for (let eligibleDefaultType of eligibleDefaultTypes) {
      interiorMetaData.push(
        ..._.cloneDeep(components[eligibleDefaultType].interiors)
      );
    }

    interiorMetaData.forEach(function (data) {
      data.rootUrl = DOMAIN + data.rootUrl;
    });
  }

  function postTurnOffAutoInterior() {
    setUserSettingAndRecord("autoInteriorState", false);
    $("#autoInterior").css("border", "none");
  }

  function postTurnOnAutoInterior() {
    setUserSettingAndRecord("autoInteriorState", true);
    $("#autoInterior").css("border", "1px solid #d30041");
  }

  function _loadInteriors(tasks) {
    try {
      let sourceMeshes = [];
      for (let i = 0; i < tasks.length; i++) {
        console.log(tasks[i])
        let mesh = tasks[i].loadedMeshes[0];
        mesh.name = tasks[i].name;
        mesh.type = "furniture";
        ComponentManager.prepareLoadedMesh(mesh);
        sourceMeshes.push(mesh);
      }

      /*
            Saving source meshes to backend using CommandUtils.
            Remove this command from executed list.
            This ensures source meshes are not loaded again on undo/redo
             */
      let options = {
        preserveChildren: false,
      };
      let sourceMeshCommandData =
        commandUtils.creationOperations.getCommandData(
          sourceMeshes,
          null,
          options
        );
      let sourceMeshCommand = commandUtils.creationOperations.getCommand(
        "tempSourceMeshCommand",
        sourceMeshCommandData,
        options
      );

      CommandManager.execute(sourceMeshCommand, false);
      CommandManager.popLastItemFromExecuted();

      // Place the interiors
      this.placeInteriors();
    } catch (err) {
      console.log(err);
      this.reset();
    }
  }

  function placeInteriors() {
    function getCentroid(coords) {
      let centX = 0,
        centY = 0;
      let length = coords.length;

      for (let coord of coords) {
        centX += coord[0];
        centY += coord[1];
      }

      return [centX / length, centY / length];
    }

    function getSortedIndices(coords) {
      let distances = [];

      let x, y;
      let length = coords.length;
      for (let i = 0; i < length - 1; i++) {
        x = coords[i][0] - coords[i + 1][0];
        y = coords[i][1] - coords[i + 1][1];

        distances.push(Math.hypot(x, y));
      }

      x = coords[length - 1][0] - coords[0][0];
      y = coords[length - 1][1] - coords[0][1];

      distances.push(Math.hypot(x, y));

      return Array.from(Array(length).keys()).sort((a, b) =>
        distances[a] < distances[b] ? -1 : (distances[b] < distances[a]) | 0
      );
    }

    function getUltimateCoords(coords) {
      let interiorCoords = [];
      let indices = getSortedIndices(coords);
      let centroid = getCentroid(coords);
      let length = indices.length;

      if (indices[length - 1] === length - 1) {
        interiorCoords.push(coords[indices[length - 1]]);
        interiorCoords.push(coords[0]);
      } else {
        interiorCoords.push(coords[indices[length - 1]]);
        interiorCoords.push(coords[indices[length - 1] + 1]);
      }

      interiorCoords.push(centroid);

      return interiorCoords;
    }

    function getPenultimateCoords(coords) {
      let interiorCoords = [];
      let indices = getSortedIndices(coords);
      let centroid = getCentroid(coords);
      let length = indices.length;

      if (indices[length - 2] === length - 1) {
        interiorCoords.push(coords[indices[length - 2]]);
        interiorCoords.push(coords[0]);
      } else {
        interiorCoords.push(coords[indices[length - 2]]);
        interiorCoords.push(coords[indices[length - 2] + 1]);
      }

      interiorCoords.push(centroid);

      return interiorCoords;
    }

    function getHostBase(host) {
      let bBox = host.mesh.getBoundingInfo().boundingBox;
      return bBox.minimumWorld.y;
    }

    function rearrangeCoords(coords) {
      let sum = 0;
      for (let i = 0; i < coords.length; i++) {
        if (i === coords.length - 1) {
          sum += (coords[0][0] - coords[i][0]) * (coords[0][1] + coords[i][1]);
        } else {
          sum += (coords[i + 1][0] - coords[i][0]) * (coords[i + 1][1] + coords[i][1]);
        }
      }

      if (sum > 0) return coords.reverse();
      else return coords;
    }

    let interiorInstances = [];

    for (let host of hosts) {
      let roomType = host.room_type.toLowerCase();
      let bottomFaceVertices = getBottomFaceVertices(host, BABYLON.Space.WORLD);

      let coords = convertVector3To2DArray(bottomFaceVertices);
      let rearrangedCoords = rearrangeCoords(coords);

      let ultimateCoords = getUltimateCoords(rearrangedCoords);
      let penultimateCoords = getPenultimateCoords(rearrangedCoords);

      let storey = _getStructure().getStoreyData().getAllStoreys()[host.storey];
      let base = getHostBase(host);

      // Initially positioned at 0, the furniture is moved by heightDifference based on storey
      let heightDifference = Math.abs(base);

      if (roomType.indexOf("bedroom") !== -1) {
        interiorInstances.push(
          arrangeBedComp(
            ultimateCoords,
            INTERIORNAMES.BED,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangeWardrobeComp(
            penultimateCoords,
            INTERIORNAMES.WARDROBE,
            storey,
            heightDifference
          )
        );
      } else if (roomType.indexOf("living") !== -1) {
        interiorInstances.push(
          arrangeSofaComp(
            ultimateCoords,
            INTERIORNAMES.SOFA,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangeTableComp(
            ultimateCoords,
            INTERIORNAMES.TABLE,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangeChairComp(
            ultimateCoords,
            INTERIORNAMES.CHAIR,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangeCoffeeTableComp(
            ultimateCoords,
            INTERIORNAMES.COFFEETABLE,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangeSideTableComp(
            ultimateCoords,
            INTERIORNAMES.SIDETABLE,
            storey,
            heightDifference
          )
        );
      } else if (roomType.indexOf("kitchen") !== -1) {
        interiorInstances.push(
          arrangeKitchen1Comp(
            ultimateCoords,
            INTERIORNAMES.KITCHEN,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangeKitchen2Comp(
            penultimateCoords,
            INTERIORNAMES.KITCHEN,
            storey,
            heightDifference
          )
        );
      } else if (roomType.indexOf("bathroom") !== -1) {
        interiorInstances.push(
          arrangeBathroomComp(
            ultimateCoords,
            INTERIORNAMES.BATHROOM,
            storey,
            heightDifference
          )
        );
      } else if (roomType.indexOf("dining") !== -1) {
        interiorInstances.push(
          arrangeDiningTableComp(
            ultimateCoords,
            INTERIORNAMES.DINING,
            storey,
            heightDifference
          )
        );
        interiorInstances.push(
          arrangePaintingComp(
            penultimateCoords,
            INTERIORNAMES.PAINTING,
            storey,
            heightDifference
          )
        );
      }
    }

    let options = {
      preserveChildren: false,
      postExecuteCallback: postTurnOnAutoInterior,
      postUnExecuteCallback: postTurnOffAutoInterior,
    };

    if (!_.isEmpty(interiorInstances)) {
      let autoInteriorCommandData =
        commandUtils.creationOperations.getCommandData(
          interiorInstances,
          null,
          options
        );
      let autoInteriorCommand = commandUtils.creationOperations.getCommand(
        AUTOINTERIORONCOMMAND,
        autoInteriorCommandData,
        options
      );

      CommandManager.execute(autoInteriorCommand, false);
    }
    interiorInstances.forEach(function (mesh) {
      setLayerTransperancy(mesh);
    });

    this.reset();
  }

  function on() {
    try {
      loading = true;
      _populateHosts();
      _prepareInteriorData();
      ComponentManager.addTask(interiorMetaData, FORMAT);
      ComponentManager.load(_loadInteriors.bind(this));
    } catch (err) {
      console.log(err);
      this.reset();
    }
  }

  function off() {
    try {
      if (!this.isLoading()) {
        let structure = _getStructure();
        let levels = structure.getAllLevels();

        let furnitureMeshes = [];

        for (let levelId in levels) {
          let level = levels[levelId];
          let furniture = level.flyweight.furnitures.filter(
            (fur) => fur.autoInterior
          );
          furnitureMeshes.push(...furniture.map((fur) => fur.mesh));
        }

        let options = {
          preserveChildren: false,
          postExecuteCallback: postTurnOffAutoInterior,
          postUnExecuteCallback: postTurnOnAutoInterior,
        };

        if (!_.isEmpty(furnitureMeshes)) {
          let autoInteriorOffCommandData =
            commandUtils.deletionOperations.getCommandData(
              furnitureMeshes,
              null,
              options
            );
          let autoInteriorOffCommand =
            commandUtils.deletionOperations.getCommand(
              AUTOINTERIOROFFCOMMAND,
              autoInteriorOffCommandData,
              options
            );

          CommandManager.execute(autoInteriorOffCommand, true);
        } else {
          postTurnOffAutoInterior();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function isLoading() {
    return loading;
  }

  function reset() {
    ComponentManager.reset();
    loading = false;
    interiorMetaData.length = 0;
    hosts.length = 0;
    document.body.style.cursor = "auto";
  }

  return {
    on,
    off,
    placeInteriors,
    isLoading,
    reset,
  };
})();

var autoInteriorData = {
  components: {
    bedroom: {
      interiors: [
        {
          name: "bed01",
          rootUrl: "/media/models/bedroom/beds/",
        },
        {
          name: "wardrobe01",
          rootUrl: "/media/models/bedroom/wardrobe/",
        },
      ],
    },
    living: {
      interiors: [
        {
          name: "sofa01",
          rootUrl: "/media/models/living/sofa/",
        },
        {
          name: "chair01",
          rootUrl: "/media/models/living/chair/",
        },
        {
          name: "stable01",
          rootUrl: "/media/models/living/stable/",
        },
        {
          name: "ctable01",
          rootUrl: "/media/models/living/ctable/",
        },
        {
          name: "table01",
          rootUrl: "/media/models/living/table/",
        },
      ],
    },
    kitchen: {
      interiors: [
        {
          name: "kitchen01",
          rootUrl: "/media/models/kitchen/kitchen1/",
        },
        {
          name: "kitchen01",
          rootUrl: "/media/models/kitchen/kitchen2/",
        },
      ],
    },
    bathroom: {
      interiors: [
        {
          name: "bathroom01",
          rootUrl: "/media/models/bathroom/bathroom/",
        },
      ],
    },
    dining: {
      interiors: [
        {
          name: "dining01",
          rootUrl: "/media/models/dining/table/",
        },
        {
          name: "painting01",
          rootUrl: "/media/models/dining/painting/",
        },
      ],
    },
  },
};
export { AutoInterior,autoInteriorData };
