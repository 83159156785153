import axios from "axios";
import {speckleUrl, speckleApiToken} from "../../../services/url.constants";

const SPECKLE_TOKEN = speckleApiToken;

const fetchSingleObject = async (streamId, object) => {
  let url = speckleUrl + `/objects/${streamId}/${object}/single`;
  const config = {
    headers: { Authorization: SPECKLE_TOKEN },
  };
  const response = await axios.get(url, config);
  return response.data;
};

const fetchListOfObjects = async (streamId, objects) => {
  let response;
  let url = speckleUrl + `/api/getobjects/${streamId}/`;
  const config = {
    headers: { Authorization: SPECKLE_TOKEN },
  };
  const data = {
    objects: JSON.stringify(objects),
  };
  try {
    response = await axios.post(url, data, config);
  } catch (e) {
    console.log(e);
  }
  return response.data;
};

const fetchLatestCommit = async (streamId) => {
  const query = `query Commits{
    stream(id : "${streamId}"){
      branch(name : "main"){
        commits(limit: 1){
          items{
            referencedObject
            createdAt
          }
        }
      }
    }
  }`;

  const variables = {};

  const API_URL = speckleUrl + "/graphql";

  const data = await axios.post(
    API_URL,
    {
      query: query,
      variables: variables,
    },
    {
      headers: {
        Authorization: "Bearer " + SPECKLE_TOKEN,
      },
    }
  );

  return data;
};
const fetchReferenceIdOfCommit = async (streamId, commitId) => {
  let data;
  const query = `query commit {
    stream(id: "${streamId}") {
      commit(id:  "${commitId}") {
        id
        referencedObject
      }
    }
  }`;

  const variables = {};

  const API_URL = speckleUrl + "/graphql";

  try {
    data = await axios.post(
      API_URL,
      {
        query: query,
        variables: variables,
      },
      {
        headers: {
          Authorization: "Bearer " + SPECKLE_TOKEN,
        },
      }
    );
  } catch {
    console.log(data);
  }
  return data;
};


const fetchMetaData = async (streamId, objectID) => {
  let metaData = await fetchSingleObject(streamId, objectID);

  let levels = [];

  let wallsData = [],
    floorsData = [],
    ceilingsData = [],
    columnsData = [],
    columnsGeometryData = {},
    railingsData = [],
    curtainWallsData = [],
    newCurtainWallData = {},
    stairsData = {},
    MEPData = {},
    massData = {},
    structuralFramingData = {}

  let singleFurnitureIds, furnitureSetMap;
  let wallsTypeData = [],
    ceilingTypeData = [],
    columnTypeData = [],
    floorTypeData = [],
    doorTypeData = [],
    windowTypeData = [],
    wallMapData = {},
    wallSideFacesData = [],
    wallInsertsData = [];

  let materialData = {};


  const revitExportKeys = [
    "Doors",
    "Furniture",
    "Floors",
    "Windows",
    "Railings",
    "Security Devices",
    "Fire Alarm Devices",
    "Communication Devices",
    "Lighting Fixtures",
    "Casework",
    "Furniture Systems",
    "Planting",
    "Electrical Fixtures",
    "Data Devices",
    "Lighting Devices",
    "Specialty Equipment",
    "RVT Links",
    "Curtain Wall Mullions",
    "Curtain Panels",
    "Stairs",
    "Structural Columns",
    "Generic Models",
    "Assemblies",
    "Model Groups",
    "Ducts",
    "Duct Fittings",
    "Flex Ducts",
    "Cable Trays",
    "Air Terminals",
    "Mechanical Equipment",
    "Cable Tray Fittings",
    "Duct Accessories",
    "Ramps",
    "Top Rails",
    "Mass",
    "Pipes",
    "Pipe Fittings",
    "Conduit Fittings",
    "Conduits",
    "Plumbing Fixtures",
    "Electrical Equipment",
    "Sprinklers",
    "Structural Framing",
    "Columns"
  ];
  const familyData = {};

  const componentData = {};

  const revitExportData = {};

  let promiseArray = [];

  for (let i = 0; i < metaData["@data"].length; ++i) {
    let promise = fetchSingleObject(
      streamId,
      metaData["@data"][i].referencedId
    );
    promiseArray.push(promise);
  }

  await Promise.all(promiseArray).then(function (values) {
    values.forEach((data) => {
      for (let k in data) {
        if (
          [
            "id",
            "speckle_type",
            "applicationId",
            "totalChildrenCount",
            "__closures",
          ].includes(k)
        )
          continue;
        let key = k.split("-")[0];
        let splitKeys = key.split("*");
        if (key == "walls") {
          wallsData.push(...data[k]);
        } else if (key == "floors") {
          floorsData.push(...data[k]);
        } else if (key == "levels") {
          levels.push(...data[k]);
        } else if (key == "ceilings") {
          ceilingsData.push(data[k]);
        } else if (key == "columns") {
          columnsData.push(...data[k]);
        } else if (key == "curtainsWallsData") {
          curtainWallsData.push(data[k]);
        } else if (key == "wallTypes") {
          wallsTypeData.push(data[k]);
        } else if (key == "wallSideProfile"){
          //only information of the external side wall is being sent from speckle now.
          wallSideFacesData.push(data[k])
        }else if (key == "wallInsertsData"){
          //Information about Curtain walls inserted in walls
          wallInsertsData.push(data[k])
        }
        else if (key == "floorTypes") {
          floorTypeData.push(data[k]);
        } else if (key == "ceilingTypes") {
          ceilingTypeData.push(data[k]);
        } else if (key == "columnTypes") {
          columnTypeData.push(data[k]);
        } else if (key == "doorTypes") {
          doorTypeData.push(data[k]);
        } else if (key == "windowTypes") {
          windowTypeData.push(data[k]);
        } else if (key == "doorsWallMap") {
          if (!("door" in wallMapData)) {
            wallMapData["door"] = [];
          }
          wallMapData["door"].push(data[k]);
        } else if (key == "windowsWallMap") {
          if (!("window" in wallMapData)) {
            wallMapData["window"] = [];
          }
          wallMapData["window"].push(data[k]);
        } else if (
          revitExportKeys.includes(splitKeys[0]) &&
          splitKeys.length == 1
        ) {
          if (!(splitKeys[0] in revitExportData)) {
            revitExportData[splitKeys[0]] = [];
          }
          revitExportData[splitKeys[0]].push(data[k]);
        } else if (
          revitExportKeys.includes(splitKeys[0]) &&
          splitKeys[1] == "FamilyData"
        ) {
          if (!(splitKeys[0] in familyData)) {
            familyData[splitKeys[0]] = [];
          }
          familyData[splitKeys[0]].push(data[k]);
        } else if (
          revitExportKeys.includes(splitKeys[0]) &&
          splitKeys[1] == "ComponentsData"
        ) {
          if (!(splitKeys[0] in componentData)) {
            componentData[splitKeys[0]] = [];
          }
          componentData[splitKeys[0]].push(data[k]);
        }
      }
    });
  });

  for (let key in revitExportData) {
    revitExportData[key] = revitExportData[key].join("");
    revitExportData[key] = JSON.parse(revitExportData[key]);
  }
  for (let key in familyData) {
    familyData[key] = familyData[key].join("");
    familyData[key] = JSON.parse(familyData[key]);
  }
  for (let key in componentData) {
    componentData[key] = componentData[key].join("");
    componentData[key] = JSON.parse(componentData[key]);
  }

  wallsTypeData = JSON.parse(wallsTypeData.join(""));
  wallSideFacesData = JSON.parse(wallSideFacesData.join(""));
  columnTypeData = JSON.parse(columnTypeData.join(""));
  floorTypeData = JSON.parse(floorTypeData.join(""));
  ceilingTypeData = JSON.parse(ceilingTypeData.join(""));
  ceilingsData =  JSON.parse(ceilingsData.join(""));
  wallInsertsData = wallInsertsData.length ? JSON.parse(wallInsertsData.join("")) : [];
  stairsData = revitExportData["Stairs"];
  structuralFramingData = revitExportData["Structural Framing"];
  newCurtainWallData["Panels"] = revitExportData["Curtain Panels"];
  newCurtainWallData["mullions"] = revitExportData["Curtain Wall Mullions"];
  newCurtainWallData["CurtainWallInsertMap"] = wallInsertsData;
  MEPData = {...revitExportData["Ducts"], ...revitExportData["Duct Fittings"], ...revitExportData["Flex Ducts"], ...revitExportData[ "Cable Trays"], ...revitExportData[ "Cable Tray Fittings"], ...revitExportData["Duct Accessories"], ...revitExportData[ "Air Terminals"], ...revitExportData[ "Pipes"],  ...revitExportData[ "Pipe Fittings"],...revitExportData[ "Conduit Fittings"],  ...revitExportData[ "Conduits"] }
  railingsData = {...revitExportData["Railings"], ...revitExportData["Top Rails"], ...revitExportData["Ramps"]}
  massData = {
    ...revitExportData["Mass"]
  }
  floorsData = familyData["Floors"] 
  columnsGeometryData = revitExportData["Columns"]

  return {
    wallsData,
    wallSideFacesData,
    floorsData,
    levels,
    ceilingsData,
    columnsData,
    railingsData,
    newCurtainWallData,
    wallsTypeData,
    floorTypeData,
    ceilingTypeData,
    columnTypeData,
    doorTypeData,
    windowTypeData,
    wallMapData,
    revitExportData,
    familyData,
    componentData,
    stairsData,
    MEPData,
    massData,
    structuralFramingData,
    columnsGeometryData
  };
};
export {
  fetchListOfObjects,
  fetchSingleObject,
  fetchMetaData,
  fetchLatestCommit,
  fetchReferenceIdOfCommit,
};
