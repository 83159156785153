'use strict';
import _ from 'lodash';
import { colorUtil } from "../utilityFunctions/colorUtility";
import { uiIndicatorsHandler } from '../uiIndicatorOperations/uiIndicatorsHandler';
import { massDissector } from '../createBuilding/massDissector';
import { virtualSketcher } from './virtualSketcher';
import { getBottomFaceVertices, getLowerVertexOfTheEdge, getMostLikelyUnitNormalToFace, getTopFaceVertices, getFaceVerticesFromFace, getLowerEdgeFromFaceVertices } from '../../libs/brepOperations';
import { geometryUpdater } from './geometryUpdater';
import { moveFace } from '../meshoperations/moveOperations/moveFace';
import { getDistanceBetweenVectors, areEdgesParallel, isMeshThrowAway, isRoomOfType, getAngleBetweenEdges, getCollinearVertices, areEdgesOverlapping } from '../extrafunc';
import { commandUtils } from '../commandManager/CommandUtils';
import { areEdgesSimilar } from '../../libs/snapUtilities';
import { store } from '../utilityFunctions/Store';

/*
Module to handle auto dimension changes when user prefers internal wall to wall dimensions
 */

const dimensionsTuner = (function (){

    const CONSTANTS = {
        EDGE_COLOR_RIGHT : colorUtil.type.y,
        EDGE_COLOR_WRONG : colorUtil.type.x,
    };

    let _shouldTuneDimensions = false; // absolute state, derived from project properties
    let _currentlyActive = false; // used to prevent loops

    const _componentsOfInterest = [];
    let massFacesMap = new Map();

    const debug = (function (){
        let _debugMode = false;

        const on = function (){
            _debugMode = true;
        };

        const off = function (){
            clean();
            _debugMode = false;
        };

        const clean = function (){
            uiIndicatorsHandler.edgeIndicator.massDestruct(true);
        };

        const isActive = function (){
            return _debugMode;
        };

        const highlight = function (components){

            const _getThickness = function (edgeLabel){
                let thickness;
                if (_isInternal(edgeLabel)){
                    thickness = internalHalfThickness;
                }
                else {
                    thickness = _isParapet(edgeLabel) ?
                        parapetHalfThickness : externalHalfThickness;
                }

                return thickness;
            };

            components = components.filter(_shouldTuneDimension);
            if (_.isEmpty(components)) return;

            const externalHalfThickness = massDissector.getExternalWallThickness() / 2;
            const internalHalfThickness = massDissector.getInternalWallThickness() / 2;
            const parapetHalfThickness = massDissector.getParapetWallThickness() / 2;

            const options = {
                colors : [CONSTANTS.EDGE_COLOR_RIGHT, CONSTANTS.EDGE_COLOR_WRONG],
                persist : true
            };

            const rightEdges = [];
            const wrongEdges = [];

            uiIndicatorsHandler.edgeIndicator.massDestruct(true);

            components.forEach(component => {

                if (!virtualSketcher.isComponentPresentInGraph(component)) return;
                // false during delete

                const bottomFaceVertices = getBottomFaceVertices(component);
                const bottomFacetId = geometryUpdater.util.getBottomFacetId(component);

                const topFaceVertices = bottomFaceVertices.map(v1 => {
                    return getLowerVertexOfTheEdge(v1, component.mesh, {
                        facetId : bottomFacetId
                    });
                });

                const length = bottomFaceVertices.length;

                const topEdges = [];
                const edges = bottomFaceVertices.map((vertex, i) => {
                    const nextIndex = _getNextIndex(i, length);
                    const nextVertex = bottomFaceVertices[nextIndex];

                    topEdges.push({
                        headPt : topFaceVertices[i],
                        tailPt : topFaceVertices[nextIndex],
                    });

                    return {
                        headPt : vertex,
                        tailPt : nextVertex
                    };
                });

                const edgeLabels = edges.map(edge => {
                    return virtualSketcher.lookupEdge(edge.headPt, edge.tailPt);
                });

                let faceObjects = topEdges.map(topEdge => {
                    return moveFace.util.getFaceObjectFromTopEdgeAndLookForParallelFaces({
                        edge : topEdge,
                        component
                    });
                });

                edges.forEach((edge,i) => {
                    const edgeLabel = edgeLabels[i];
                    const faceObject = faceObjects[i];

                    if (_getThickness(edgeLabel) === faceObject.autoDimensionData.movementAmount) {
                        // rightEdges.push(edge);
                    }
                    else wrongEdges.push(edge);
                });
            });

            uiIndicatorsHandler.edgeIndicator.massConstructFromEdges([rightEdges, wrongEdges], options);
        };

        return {
            on,
            off,
            clean,
            highlight,
            isActive
        };

    })();


    const toggleTuner = function (state){
        _shouldTuneDimensions = state;
        _currentlyActive = state;
    };

    const _haltTemporarily = function (){
        _currentlyActive = false;
    };

    const _removeTemporaryHalt = function (){
        _currentlyActive = true;
    };

    const _isHalted = function (){
        return !_currentlyActive;
    };

    const isTunerActive = function (){
        return _shouldTuneDimensions;
    };

    const _shouldTuneDimension = function (component){
        return !component.mesh.isAnInstance
            && !isMeshThrowAway(component.mesh)
            && component.type.toLowerCase() === 'mass'
            && component.massType.toLowerCase() === 'room';
    };

    const _getPreviousIndex = function (i, length){
        return i === 0 ? length - 1 : i - 1;
    };

    const _getNextIndex = function (i, length){
        return i === length - 1 ? 0 : i + 1;
    };

    const _isInternal = function (edgeLabel){
        return edgeLabel.weight > 1;
    };

    const _isExternal = function (edgeLabel){
        return edgeLabel.weight === 1;
    };

    const _isParapet = function (edgeLabel){
        return edgeLabel.weight === 1 && isRoomOfType(edgeLabel.components[0].mesh, 'balcony');
    };

    const _doesComponentHaveDimensionTuningMetadata = function (component){
        return !!component.brep.getFaces()[0].autoDimensionData;
    };

    const _getSmallEdges = function (faceVertices){
        const length = faceVertices.length;
        const edges = faceVertices.map((vertex, i) => {
            const nextIndex = _getNextIndex(i, length);
            const nextVertex = faceVertices[nextIndex];

            return {
                headPt : vertex,
                tailPt : nextVertex
            };
        });

        const threshold = 2 * massDissector.getInternalWallThickness();

        return edges.filter(e => {
            return getDistanceBetweenVectors(e.headPt, e.tailPt) < threshold;
        });
    };

    const _areEdgesGoingInSameDirection = function (edge1, edge2){
        const angleBetweenThem = getAngleBetweenEdges(edge1, edge2);
        return angleBetweenThem <= 90;
    };

    const _areEdgesGoingInDifferentDirection = function (edge1, edge2){
        return !_areEdgesGoingInSameDirection(edge1, edge2);
    };

    const _updateDimensions = function (component){

        const _getNeighbouringIndices = function (edgeLabel){

            // gets indices such that previousEdge and previousToPreviousEdge are not parallel to each other
            // also nextEdge and nextToNextEdge

            // currentEdge can be parallel to previousEdge or nextEdge

            const i = _.findIndex(edgeLabels, edgeLabel);
            const length = edgeLabels.length;

            const previousIndex = _getPreviousIndex(i, length);
            const nextIndex = _getNextIndex(i, length);

            const previousEdge = edges[previousIndex];
            const nextEdge = edges[nextIndex];

            let previousOfPreviousIndex = _getPreviousIndex(previousIndex, length);
            let previousOfPreviousEdge = edges[previousOfPreviousIndex];

            let safeCounter = 100;

            while (areEdgesParallel(previousEdge, previousOfPreviousEdge) && safeCounter){
                previousOfPreviousIndex = _getPreviousIndex(previousOfPreviousIndex, length);
                previousOfPreviousEdge = edges[previousOfPreviousIndex];
                safeCounter--;
            }

            let nextToNextIndex = _getNextIndex(nextIndex, length);
            let nextToNextEdge = edges[nextToNextIndex];

            safeCounter = 100;
            while (areEdgesParallel(nextEdge, nextToNextEdge) && safeCounter){
                nextToNextIndex = _getNextIndex(nextToNextIndex, length);
                nextToNextEdge = edges[nextToNextIndex];
                safeCounter--;
            }

            return {
                previousIndex,
                previousOfPreviousIndex,
                nextIndex,
                nextToNextIndex
            };
        };

        const componentHasDimensionTuningMetadata = _doesComponentHaveDimensionTuningMetadata(component);

        const bottomFaceVertices = getBottomFaceVertices(component);
        const bottomFacetId = geometryUpdater.util.getBottomFacetId(component);

        const topFaceVertices = bottomFaceVertices.map(v1 => {
            return getLowerVertexOfTheEdge(v1, component.mesh, {
                facetId : bottomFacetId
            });
        });

        const length = bottomFaceVertices.length;

        let movementData = [];

        const topEdges = [];
        const edges = bottomFaceVertices.map((vertex, i) => {
            const nextIndex = _getNextIndex(i, length);
            const nextVertex = bottomFaceVertices[nextIndex];

            topEdges.push({
                headPt : topFaceVertices[i],
                tailPt : topFaceVertices[nextIndex],
            });

            return {
                headPt : vertex,
                tailPt : nextVertex
            };
        });

        const edgeLabels = edges.map(edge => {
            return virtualSketcher.lookupEdge(edge.headPt, edge.tailPt);
        });

        let faceObjects;

        let metadataChangeCommandData = commandUtils.geometryChangeOperations.getCommandData(component.mesh);
        if (!componentHasDimensionTuningMetadata){
            component.brep.faces.forEach(face => addDefaultFaceMetadata(face, null, NaN));
        }

        faceObjects = topEdges.map(topEdge => {
            return moveFace.util.getFaceObjectFromTopEdgeAndLookForParallelFaces({
                edge : topEdge,
                component
            });
        });

        const externalEdgesThatShouldNotMove = [];
        const externalEdgesParallelToInternalEdges = [];

        const externalHalfThickness = massDissector.getExternalWallThickness() / 2;
        const internalHalfThickness = massDissector.getInternalWallThickness() / 2;
        const parapetHalfThickness = massDissector.getParapetWallThickness() / 2;

        const differenceBetweenExternalAndInternalHalfThicknesses = externalHalfThickness - internalHalfThickness;

        edgeLabels.forEach((edgeLabel, i) => {
            const nextIndex = _getNextIndex(i, length);
            const nextEdgeLabel = edgeLabels[nextIndex];

            const previousIndex = _getPreviousIndex(i, length);
            const previousEdgeLabel = edgeLabels[previousIndex];

            const faceObject = faceObjects[i];
            const unitNormal = getMostLikelyUnitNormalToFace(component, faceObject, {
                confirmWithPick : true
            });

            let absoluteMovementAmount;
            let doNotMoveAnyFurther = false;

            if (_isInternal(edgeLabel)){
                if (!componentHasDimensionTuningMetadata) {

                    // if internal wall thickness is 0'4" and external is 1'0",
                    // internal edge which is supposed to move by 2" in total will have to move by 4" because
                    // on the other side of the edge, an external wall is becoming internal, so 6" to 2" is happening

                    const shouldMoveByAmount = differenceBetweenExternalAndInternalHalfThicknesses;
                    faceObject.autoDimensionData.movementAmount = internalHalfThickness - shouldMoveByAmount;
                }
                absoluteMovementAmount = internalHalfThickness;
            }
            else {

                if (!componentHasDimensionTuningMetadata) faceObject.autoDimensionData.movementAmount = 0;

                absoluteMovementAmount = _isParapet(edgeLabel) ?
                    parapetHalfThickness : externalHalfThickness;

                if (!componentHasDimensionTuningMetadata){
                    const currentEdge = edges[i];
                    const previousEdge = edges[previousIndex];
                    const nextEdge = edges[nextIndex];

                    // in some cases, a neighbour of an internal edge shouldn't move at all,
                    // below checks are to determine that

                    // no matter what the direction of vertices is,
                    // currentEdge's tailPt will equal nextEdge's headPt
                    // currentEdge's headPt will equal previousEdge's tailPt

                    // external edges neighbouring internal edges, which when moved will breach the boundary
                    // of the neighbouring component should not move.
                    // To compensate for this lack of movement, corresponding external edges, if any, will
                    // move by double the amount below

                    // Visual representation of the above explanation
                    // https://imgur.com/a/XhyiaKT
                    // Drawing a vs drawing b have different treatments.
                    // Edge 1 will extend upwards but edge 2 will not since it'll breach the dimensional boundary

                    let edgeHandled = false;

                    if (_isInternal(previousEdgeLabel)){

                        if (areEdgesParallel(currentEdge, previousEdge)){
                            if (!componentHasDimensionTuningMetadata) {

                                // if internal wall thickness is 0'4" and external is 1'0",
                                // internal edge will move by 4" as shown above, so the external edge, next to it has to move by 8" instead of 6"
                                // because when that edge becomes internal, it'll move back by 4" making straight junction

                                faceObject.autoDimensionData.movementAmount = -internalHalfThickness;
                                externalEdgesParallelToInternalEdges.push(edgeLabel);

                                edgeHandled = true;

                                // this is like pulling it a bit behind, so it'll move a bit more
                                // example - rather than 0" to 6", now it'll move from -2" to 6" so by 8"

                                // remember that
                                // faceObject.autoDimensionData.movementAmount describes 'where it is' and
                                // absoluteMovementAmount describes where 'it should be'
                                // movementData.movementAmount is how much should face move by to make that happen
                            }
                        }
                        else {
                            const pointToCheck = previousEdge.tailPt.add(unitNormal.scale(absoluteMovementAmount));
                            const nodeLabelToCheck = virtualSketcher.lookup(pointToCheck);

                            const otherComponent = previousEdgeLabel.components.filter(c => c !== component)[0];

                            let doNotMove = false;
                            if (nodeLabelToCheck){
                                if (nodeLabelToCheck.components.includes(otherComponent)){
                                    // this edge could be moved but not any further
                                    doNotMoveAnyFurther = true;
                                }
                                else doNotMove = true;
                            }
                            else {
                                const edgeLabelToCheck = virtualSketcher.lookupOverEdge(pointToCheck, {sendLabel : true});

                                if (edgeLabelToCheck){
                                    if (edgeLabelToCheck.components.includes(otherComponent)){
                                        // this edge could be moved
                                    }
                                    else doNotMove = true;
                                }
                                else doNotMove = true;
                            }

                            if (doNotMove){
                                // don't move this edge
                                externalEdgesThatShouldNotMove.push(edgeLabel);
                                faceObject.autoDimensionData.movementAmount = absoluteMovementAmount;
                                edgeHandled = true;
                            }
                        }
                    }

                    if (_isInternal(nextEdgeLabel) && !edgeHandled){

                        if (areEdgesParallel(currentEdge, nextEdge)){
                            if (!componentHasDimensionTuningMetadata) {
                                faceObject.autoDimensionData.movementAmount = -internalHalfThickness;
                                externalEdgesParallelToInternalEdges.push(edgeLabel);
                            }
                        }
                        else {
                            const pointToCheck = nextEdge.headPt.add(unitNormal.scale(absoluteMovementAmount));
                            const nodeLabelToCheck = virtualSketcher.lookup(pointToCheck);

                            const otherComponent = nextEdgeLabel.components.filter(c => c !== component)[0];

                            let doNotMove = false;
                            if (nodeLabelToCheck){
                                if (nodeLabelToCheck.components.includes(otherComponent)){
                                    // this edge could be moved but not any further
                                    doNotMoveAnyFurther = true;
                                }
                                else doNotMove = true;
                            }
                            else {
                                const edgeLabelToCheck = virtualSketcher.lookupOverEdge(pointToCheck, {sendLabel : true});

                                if (edgeLabelToCheck){
                                    if (edgeLabelToCheck.components.includes(otherComponent)){
                                        // this edge could be moved
                                    }
                                    else doNotMove = true;
                                }
                                else doNotMove = true;
                            }

                            if (doNotMove){
                                // don't move this edge
                                externalEdgesThatShouldNotMove.push(edgeLabel);
                                faceObject.autoDimensionData.movementAmount = absoluteMovementAmount;
                            }
                        }
                    }
                }
            }

            const actualMovementAmount = absoluteMovementAmount - faceObject.autoDimensionData.movementAmount;

            const topEdge = topEdges[i];
            // const previousTopEdge = topEdges[previousIndex];
            // const movementDirection = previousTopEdge.tailPt.subtract(previousTopEdge.headPt).normalize();

            const movementDirection = unitNormal;

            movementData.push({
                edge : topEdge,
                movementAmount : actualMovementAmount,
                movementDirection,
                doesEdgeBelongToTopFace : true,
                edgeLabel,
                faceObject,
                doNotMoveAnyFurther, //applies to external edges that could potentially be considered for compensatory movement
            });
        });

        /*
        Some edges should move by amount greater than initial assessment
        This is to identify those
         */

        movementData.forEach((movementDatum, i) => {

            const edgeLabel = movementDatum.edgeLabel;
            if (_isInternal(edgeLabel)) return;
            if (!movementDatum.movementAmount) return; //zero
            if (movementDatum.doNotMoveAnyFurther) return; //zero

            const {previousIndex, nextIndex, previousOfPreviousIndex, nextToNextIndex} =
                _getNeighbouringIndices(edgeLabel);

            const previousEdgeLabel = edgeLabels[previousIndex];
            const previousOfPreviousEdgeLabel = edgeLabels[previousOfPreviousIndex];
            const nextEdgeLabel = edgeLabels[nextIndex];
            const nextToNextEdgeLabel = edgeLabels[nextToNextIndex];

            // compensating for the lack of movement of that other external edge
            // by doubly moving this

            const previousEdge = edges[previousIndex];
            const previousOfPreviousEdge = edges[previousOfPreviousIndex];
            const currentEdge = movementDatum.edge;
            const nextEdge = edges[nextIndex];
            const nextToNextEdge = edges[nextToNextIndex];

            if (areEdgesParallel(previousEdge, currentEdge) || areEdgesParallel(nextEdge, currentEdge)){
                // lack of movement of current edge would have no impact on the length of previousEdge
                return;
            }

            // ordering of the if blocks below is important
            // an external edge could be 'opposite' an externalEdgeThatShouldNotMove or an internal edge
            // and both of them warrant opposite treatment.
            // externalEdgeThatShouldNotMove gets priority

            let adjustedAccordingToPreviousOfPreviousEdge;

            if (_areEdgesGoingInDifferentDirection(currentEdge, previousOfPreviousEdge)){

                // if edges are going in the same direction, they can't influence one another because
                // of the concave walls.
                // Their measurement is such that internal dimension will be the same as centre line dimension

                if (_isExternal(previousOfPreviousEdgeLabel)){

                    // if internal wall thickness is 0'4" and external is 1'0",
                    // external edge which is supposed to move by 6" in cases where boundary breach has to be contained, will not move
                    // so to make the total edge length extension 12",
                    // the external edge movement amount will increase from 6" to 12"

                    const thatDatum = movementData[previousOfPreviousIndex];

                    if (thatDatum.movementAmount) {
                        movementDatum.faceObject.autoDimensionData.movementAmount -= thatDatum.faceObject.autoDimensionData.movementAmount;
                        movementDatum.movementAmount += thatDatum.faceObject.autoDimensionData.movementAmount;

                        adjustedAccordingToPreviousOfPreviousEdge = true;
                    }
                    else {
                        // have to differentiate between faces that cannot move and faces that should not move
                        if (externalEdgesThatShouldNotMove.includes(previousOfPreviousEdgeLabel)){
                            // cannot move, so move this edge more to compensate
                            movementDatum.faceObject.autoDimensionData.movementAmount -= thatDatum.faceObject.autoDimensionData.movementAmount;
                            movementDatum.movementAmount += thatDatum.faceObject.autoDimensionData.movementAmount;

                            adjustedAccordingToPreviousOfPreviousEdge = true;
                        }
                        else {
                            // should not move, do not modify this edge's movement
                        }
                    }

                }
                else if (_isInternal(previousOfPreviousEdgeLabel)){
                    const thatDatum = movementData[previousOfPreviousIndex];

                    if (thatDatum.movementAmount && _areEdgesGoingInDifferentDirection(thatDatum.edge, currentEdge)){
                        movementDatum.faceObject.autoDimensionData.movementAmount -= thatDatum.faceObject.autoDimensionData.movementAmount;
                        movementDatum.movementAmount += thatDatum.faceObject.autoDimensionData.movementAmount;

                        adjustedAccordingToPreviousOfPreviousEdge = true;
                    }

                    // if internal wall thickness is 0'4" and external is 1'0",
                    // internal edge which is supposed to move by 2" will move by 4", so to limit the total edge length
                    // extension to 8", the external edge will reduce from 6" to  4"

                }
            }

            if (!adjustedAccordingToPreviousOfPreviousEdge && previousOfPreviousEdgeLabel !== nextToNextEdgeLabel) {

                if ( _areEdgesGoingInDifferentDirection(currentEdge, nextToNextEdge)) {

                    // if already adjustedAccordingToPreviousOfPreviousEdge, doing further
                    // changes will disturb that, so just letting it be
                    // have to see how this pans out

                    const thatDatum = movementData[nextToNextIndex];

                    if (_isExternal(nextToNextEdgeLabel)) {

                        if (thatDatum.movementAmount) {
                            movementDatum.faceObject.autoDimensionData.movementAmount -= thatDatum.faceObject.autoDimensionData.movementAmount;
                            movementDatum.movementAmount += thatDatum.faceObject.autoDimensionData.movementAmount;
                        } else {
                            // have to differentiate between faces that cannot move and faces that should not move
                            if (externalEdgesThatShouldNotMove.includes(nextToNextEdgeLabel)) {
                                // cannot move, so move this edge more to compensate
                                movementDatum.faceObject.autoDimensionData.movementAmount -= thatDatum.faceObject.autoDimensionData.movementAmount;
                                movementDatum.movementAmount += thatDatum.faceObject.autoDimensionData.movementAmount;
                            } else {
                                // should not move, do not modify this edge's movement
                            }
                        }

                    } else if (_isInternal(nextToNextEdgeLabel)) {

                        if (thatDatum.movementAmount && _areEdgesGoingInDifferentDirection(thatDatum.edge, currentEdge)) {
                            movementDatum.faceObject.autoDimensionData.movementAmount -= thatDatum.faceObject.autoDimensionData.movementAmount;
                            movementDatum.movementAmount += thatDatum.faceObject.autoDimensionData.movementAmount;
                        }

                    }
                }
            }
        });

        movementData = movementData.filter(datum => datum.movementAmount !== 0);
        if (_.isEmpty(movementData)) return;

        metadataChangeCommandData = commandUtils.geometryChangeOperations.getCommandData(
            component.mesh, metadataChangeCommandData);

        const metadataChangeCommand = commandUtils.geometryChangeOperations.getCommand(
            'dimensionAutoTuningCommand', metadataChangeCommandData);

        /*
        internal edges could create extra vertices during move, so moving them at last will make things much easier

        https://imgur.com/a/odgE8wL
        In the figure, 3 should move last to avoid disturbances caused by the tiny blue edge
         */
        movementData.sort((d1, d2) => {
            // if returns 1, d1 will be put after d2
            if (_isInternal(d1.edgeLabel) && _isInternal(d2.edgeLabel)){

                const otherD1Component = d1.edgeLabel.components.filter(m => m !== component)[0];
                const d1TopFaceVertices = getTopFaceVertices(otherD1Component);
                const d1SmallEdges = _getSmallEdges(d1TopFaceVertices);

                const otherD2Component = d2.edgeLabel.components.filter(m => m !== component)[0];
                const d2TopFaceVertices = getTopFaceVertices(otherD2Component);
                const d2SmallEdges = _getSmallEdges(d2TopFaceVertices);

                const commonSmallEdges = _.intersectionWith(d1SmallEdges, d2SmallEdges, areEdgesSimilar);

                if (_.isEmpty(commonSmallEdges)){

                    const d1CollinearTopFaceVertices = getCollinearVertices(d1TopFaceVertices);
                    const d1HasACollinearInternalEdge = d1CollinearTopFaceVertices.inArray(
                        v => v.almostEquals(d1.edge.headPt) || v.almostEquals(d1.edge.tailPt));

                    const d2CollinearTopFaceVertices = getCollinearVertices(d2TopFaceVertices);
                    const d2HasACollinearInternalEdge = d2CollinearTopFaceVertices.inArray(
                        v => v.almostEquals(d2.edge.headPt) || v.almostEquals(d2.edge.tailPt));

                    if (d1HasACollinearInternalEdge && !d2HasACollinearInternalEdge) return 1;
                    else return -1;
                }
                else {
                    let returnValue = -1;
                    commonSmallEdges.some(smallEdge => {
                        if (areEdgesOverlapping(d1.edge, smallEdge, true)){
                            returnValue = 1;
                            return true;
                        }
                        else if (areEdgesOverlapping(d2.edge, smallEdge, true)){
                            returnValue = -1;
                            return true;
                        }
                    });

                    return returnValue;
                }
            }
            else if (_isInternal(d1.edgeLabel) && _isExternal(d2.edgeLabel)) return 1;
            else if (_isExternal(d1.edgeLabel) && _isInternal(d2.edgeLabel)) return -1;
            else return -1;
        });

        const moveOptions = {
            component,
            saveResults : false,
            movementData,
            forAutoDimensionTuning : true,
            programmaticUseOfMoveFace : true,
            commands : []
        };

        const moved = moveFace.justMoveIt(moveOptions);

        const allCommands = _.compact([metadataChangeCommand, ...moveOptions.commands]);

        return _.flatten(allCommands);

    };

    const add = function (components){
        if (!isTunerActive()) return;
        if (_isHalted()) return;
        if (!_.isArray(components)) components = [components];

        components.forEach(component => {
            if (!_componentsOfInterest.includes(component)) _componentsOfInterest.push(component);
        });

    };

    const yieldResult = function (){

        if (!isTunerActive()) return;
        if (_isHalted()) return;

        const dimensionChangeCommands = [];

        const filteredComponents = _componentsOfInterest.filter(_shouldTuneDimension);

        // to avoid recursive calls, toggling the tuner state while updateWithGeometryEdit

        _haltTemporarily();

        const faceMetadataCorrectionCommandPreTuning = assignCorrectMetadata();

        filteredComponents.forEach(mass => {
            try {
                const c = _updateDimensions(mass);
                if (c) dimensionChangeCommands.push(...c);
            } catch (e) {
                console.warn(e);
                console.warn("Auto dimension tuning has failed for a mass");
            }
        });

        let flattenedCommand;

        try {
            const faceMetadataCorrectionCommandPostTuning = assignCorrectMetadata();
            flattenedCommand = commandUtils.geometryChangeOperations.flattenCommands(
                [faceMetadataCorrectionCommandPreTuning,
                    ...dimensionChangeCommands,
                    faceMetadataCorrectionCommandPostTuning
                ]);

            if (debug.isActive()) {
                if (flattenedCommand) {
                    const components = flattenedCommand.data.map(datum => store.scene.getMeshByUniqueID(datum.meshId)?.getSnaptrudeDS());
                    debug.highlight(components);
                }
            }
        } catch (e) {
            console.warn(e);
            console.warn("Auto dimension tuning has failed");
        }

        _removeTemporaryHalt();

        flush();

        return flattenedCommand;
    };

    const flush = function (){
        _componentsOfInterest.length = 0;
    };

    const assignCorrectMetadata = function (){
        if (!isTunerActive()) return;

        const allMasses = Array.from(massFacesMap.keys());
        if (_.isEmpty(allMasses)) return;

        const allMeshes = allMasses.map(mass => mass.mesh);

        let commandData = commandUtils.geometryChangeOperations.getCommandData(allMeshes);
        massFacesMap.forEach((faces, mass) => {
            const currentFaces = mass.brep.getFaces();
            faces.forEach(faceObject => {

                if (!currentFaces.includes(faceObject)) return;

                const faceVertices = getFaceVerticesFromFace(faceObject, mass.mesh);
                const lowerEdge = getLowerEdgeFromFaceVertices(faceVertices);

                const lowerEdgeLabel = virtualSketcher.lookupEdge(lowerEdge.headPt, lowerEdge.tailPt);
                if (!lowerEdgeLabel) return;

                let absoluteMovementAmount;
                if (_isInternal(lowerEdgeLabel)){
                    absoluteMovementAmount = massDissector.getInternalWallThickness() / 2;
                }
                else {
                    absoluteMovementAmount = _isParapet(lowerEdgeLabel) ?
                        massDissector.getParapetWallThickness() / 2 :
                        massDissector.getExternalWallThickness() / 2;
                }

                if (!faceObject.autoDimensionData) addDefaultFaceMetadata(faceObject);
                faceObject.autoDimensionData.movementAmount = absoluteMovementAmount;

            });
        });

        commandData = commandUtils.geometryChangeOperations.getCommandData(allMeshes, commandData);
        const metadataCorrectionCommand = commandUtils.geometryChangeOperations.getCommand(
            'faceMetadataCorrection', commandData);

        massFacesMap = new Map();

        return metadataCorrectionCommand;
    };

    const addAmbiguousFaces = function (faces, mass){
        if (!isTunerActive()) return;
        if (!_shouldTuneDimension(mass)) return;

        if (!_.isArray(faces)) faces = [faces];

        let facesInMap = massFacesMap.get(mass);
        if (!facesInMap) {
            facesInMap = [];
            massFacesMap.set(mass, facesInMap);
        }

        faces.forEach(face => {
            if (!facesInMap.includes(face)) facesInMap.push(face);
        });

    };

    const addDefaultFaceMetadata = function (faceObject, referenceFace, movementAmount = 0){
        if (referenceFace){
            if (!referenceFace.autoDimensionData) return; // not operating in internal dimensions mode
            else {
                movementAmount = referenceFace.autoDimensionData.movementAmount;
            }
        }

        if (!faceObject.autoDimensionData) faceObject.autoDimensionData = {
            movementAmount
        };
    };

    return {
        add,
        toggleTuner,
        yieldResult,
        isTunerActive,
        flush,

        addDefaultFaceMetadata,
        addAmbiguousFaces,

        debug
    };

})();

export {
  dimensionsTuner,
};