"use strict";
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { snapAngle } from "../../libs/rotateMeshEvents.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { delayedExecutionEngine } from "../utilityFunctions/delayedExecution.js";
import { updateHeightAfterEdits } from "../../libs/sceneFuncs.js";
import {
  checkOperationBIMFlowConditions,
  getComponentInTheVicinityOfThisComponent,
  isPointerOverGUIElement,
  isPointInTheVicinityOfMesh,
  isTerrainMesh,
  setIsModifiedAndGetCommand,
  showToast
} from "../extrafunc.js";
import { nonDefaultMesh, updateModifications, } from "../../libs/sceneStateFuncs.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { keypadController } from "../../libs/keyboardView.js";
import { computeBoundsForMeshes } from "../../libs/zoomFuncs.js";
import { flipMesh } from "../../libs/twoD/twoScaling.js";
import {
  drawSelectionBox,
  removeMeshSelectionBox,
  removeMeshSelectionChildren,
  removeSelectionBox
} from "../../libs/meshEvents.js";
import { scenePickController } from "../utilityFunctions/scenePickController";
import { disposeHighlightedFace, faceIndicatorName, showFaceIndicator } from "./moveOperations/moveUtil";
import { uiIndicatorsHandler } from "../uiIndicatorOperations/uiIndicatorsHandler";
import { colorUtil } from "../utilityFunctions/colorUtility";
import { findAxisSnappedPoint } from "../../libs/snapFuncsPrimary";
import { doorOperation } from "./doorOperation";
import { createCustomMesh } from "../../libs/massModeling";
import { getFaceVerticesFromFacetID } from "../../libs/brepOperations";
import { isNeighborhoodBuilding } from "../geo/terrainNeighborhood.js";
import { isCADMesh } from "../../libs/snapUtilities";
import snapEngine from "../snappingEngine/snapEngine";
import { Locker } from "../locker/locker.js";

var RotateOperation = (function () {
  let _dnClick = 0;
  let _startingPoint = null;
  let _handlePoint1 = null;
  let _direction = null;
  let _mesh = null;
  // let _prevRotation = null;
  let _advancedTexture = null;
  let _text = null;
  let _stack_arr = [];
  let _commandData = null;
  let _restrictGroupRotateToY = false;
  let _rotateBoxName = "rotateBox";
  let _currentMesh = null;

  let removeRotationSupportElements = function () {
    let line1 = store.newScene.getMeshByName("lines1");
    if (line1) line1.dispose();

    let rotationSupportWall = store.newScene.getMeshByName(
      "rotationSupportWall"
    );
    if (rotationSupportWall) rotationSupportWall.dispose();

    let line2 = store.newScene.getMeshByName("lines2");
    if (line2) line2.dispose();

    let sector = store.newScene.getMeshByName("sector");
    if (sector) sector.dispose();
  };

  const _isPlanView = () => {
    return store.$scope.isTwoDimension;
  }

  const _planViewEligiblePredicate = (mesh) => {
    return ["wall", "mass", "furniture", "staircase", "roof", "floorplan", "cadsketch", "pdf"].includes(mesh.type.toLowerCase());
  }

  const _homeViewEligiblePredicate = (mesh) => {
    if (_stack_arr.length > 1) return ["wall", "mass", "furniture", "staircase"].includes(mesh.type.toLowerCase());
    else return ["wall", "roof", "mass", "furniture", "staircase"].includes(mesh.type.toLowerCase());
  }

  const _isRoom = (mesh) => {
    return mesh.getSnaptrudeDS()?.massType === "Room";
  }

  const checkIfMeshToBeGroupRestricted = (mesh) => {
    if (!["mass", "roof", "furniture"].includes(mesh.type.toLowerCase())) {
      _restrictGroupRotateToY = true;
    } else _restrictGroupRotateToY = mesh.getSnaptrudeDS().massType === "Room";
  }

  var calculateAngleForRotation = function (
    startingPoint,
    handlePoint1,
    handlePoint2
  ) {
    let _BA = handlePoint1.subtract(startingPoint);
    let _BC = handlePoint2.subtract(startingPoint);
    let angle = null;

    if (_direction === "x") {
      angle = Math.atan2(_BC.y, _BC.z) - Math.atan2(_BA.y, _BA.z);
    } else if (_direction === "y") {
      angle = Math.atan2(_BC.z, _BC.x) - Math.atan2(_BA.z, _BA.x);
    } else if (_direction === "z") {
      angle = Math.atan2(_BC.y, _BC.x) - Math.atan2(_BA.y, _BA.x);
    }
    return angle;
  };

  var _rotateMesh = function (mesh, angle) {
    angle = snapAngle(angle);
    if (_direction === "x") {
      mesh.rotationQuaternion = new BABYLON.Quaternion(
        mesh._prevRotation.x,
        mesh._prevRotation.y,
        mesh._prevRotation.z,
        mesh._prevRotation.w
      );
      mesh.rotate(BABYLON.Axis.X, -angle, BABYLON.Space.BONE);
    } else if (_direction === "y") {
      mesh.rotationQuaternion = new BABYLON.Quaternion(
        mesh._prevRotation.x,
        mesh._prevRotation.y,
        mesh._prevRotation.z,
        mesh._prevRotation.w
      );
      mesh.rotate(BABYLON.Axis.Y, -angle, BABYLON.Space.BONE);
    } else if (_direction === "z") {
      mesh.rotationQuaternion = new BABYLON.Quaternion(
        mesh._prevRotation.x,
        mesh._prevRotation.y,
        mesh._prevRotation.z,
        mesh._prevRotation.w
      );
      mesh.rotate(BABYLON.Axis.Z, angle, BABYLON.Space.BONE);
    }
  };

  let _removeParentChildRelationships = function (removeStack) {
    if (_stack_arr.length > 1) {
      _stack_arr.forEach(function (mesh, index) {
        if (removeStack) mesh.stack = false;
        if (index !== 0) {
          if (mesh._prevParent) {
            mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
          } else {
            mesh.setParent(null);
          }
        }
      });
    }
  };

  let _createParentChildRelationships = function () {
    if (_stack_arr.length > 1) {
      let firstMesh = _stack_arr[0];
      _stack_arr.forEach(function (mesh, index) {
        if (index !== 0) {
          mesh.setParent(firstMesh);
        }
      });
    }
  };

  let _createBeforeCommand = function () {
    _removeParentChildRelationships();

    let options = {
      params: [
        commandUtils.worldMatrixChangeOperations.PARAMS.rotation,
        commandUtils.worldMatrixChangeOperations.PARAMS.position,
      ],
      stack: _stack_arr,
    };

    if (_stack_arr.length === 0 || _stack_arr.length === 1) {
      if (_mesh.parentMesh.parent) {
        //_stack_arr is populated only when selectionStack.length > 1
        //so it's not _prevParent
        options.operationOnChild = true;
      }
    } else if (_stack_arr.length > 1) {
      let nonChildMeshPresent = false;
      _stack_arr.some((mesh, i) => {
        if (i === 0) {
          if (!mesh.parent) {
            nonChildMeshPresent = true;
            return true;
          }
        } else if (!mesh._prevParent) {
          nonChildMeshPresent = true;
          return true;
        }
      });

      if (!nonChildMeshPresent) options.operationOnChild = true;
    }

    _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
      _mesh.parentMesh,
      options
    );

    _setDelayedExecutable();
    _createParentChildRelationships();
  };

  const _updateVirtualSketcherData = function () {
    let array = [];
    if (_.isEmpty(_stack_arr)) {
      array = [_mesh.parentMesh];
    } else {
      array = _stack_arr;
    }

    const components = array.map(m => m.getSnaptrudeDS());
    if (components.length > 5) {
      virtualSketcher.updateWithoutGeometryEdit(components);
      return [];
    }
    else {
      return components.map((c) => virtualSketcher.updateWithGeometryEdit(c));
    }
    
  };

  let _createAfterCommand = function () {
    _removeParentChildRelationships();

    let options = {};
    options.data = _commandData;

    _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
      _mesh.parentMesh,
      options
    );

    const rotateCommand = commandUtils.worldMatrixChangeOperations.getCommand(
      commandUtils.CONSTANTS.rotateOperation,
      _commandData
    );

    const integrationGeometryChangeCommands = _updateVirtualSketcherData();
    
    let allMeshes;
    const meshesToModify = [];
    if (_.isEmpty(_stack_arr)) {
      allMeshes = [_mesh.parentMesh];

      meshesToModify.push(_mesh.parentMesh);
      if (_mesh.parentMesh.childrenComp)
        for (const childMesh of _mesh.parentMesh.childrenComp) {
          meshesToModify.push(childMesh);
        }
    } else {
      allMeshes = _stack_arr;
      for (const m of _stack_arr) {

        if (m.childrenComp)
          for (const childMesh of m.childrenComp) {
            meshesToModify.push(childMesh);
          }
      }
    }
    
    const cadMeshes = allMeshes.filter(m => isCADMesh(m));
    cadMeshes.forEach(m => snapEngine.cadSnaps.update(m));

    const isModifiedCommand = setIsModifiedAndGetCommand(meshesToModify);

    const allCommands = _.compact([
      rotateCommand,
      ...integrationGeometryChangeCommands,
      isModifiedCommand,
    ]);
    const yets = allCommands.map((_) => false);

    CommandManager.execute(allCommands, yets);

    _createParentChildRelationships();
  };

  let _variableCleanup = function () {
    _dnClick = 0;
    _startingPoint = null;
    _handlePoint1 = null;
    _direction = null;
    if (_text) {
      DisplayOperation.removeDimensions();
      _text = null;
    }
    _removeStoredRotation();
    _mesh = null;
    removeRotationSupportElements();
  };

  let _setDelayedExecutable = function () {
    delayedExecutionEngine.addExecutable(_createAfterCommand);
  };

  let _removeStoredRotation = function () {
    if (_stack_arr.length > 1) {
      _stack_arr.forEach(function (mesh) {
        mesh._prevRotation = null;
      });
    } else {
      if (_mesh) {
        _mesh.parentMesh._prevRotation = null;
      }
    }
  };

  let _concludeOperation = function () {
    // store.newScene.activeCamera.attachControl(canvas, true, false);
    if (_mesh.parentMesh.stack) {
      _stack_arr.forEach(m => {
        drawSelectionBox(m);
      })
    }
    uiIndicatorsHandler.axisIndicator.remove();
    updateHeightAfterEdits(_mesh.parentMesh);
    removeRotationSupportElements();
    delayedExecutionEngine.executeAll();
    _variableCleanup();
  };

  let _createInputBox = function () {
    // _text = new BABYLON.GUI.InputText("rotateBox");

    _text = DisplayOperation.createInput("rotateBox", "", false, {
      onChangeCallback: updateRotateAngle,
    });
    _text._verticalAlignment -= 1;

    _text.height = "20px";
    _text.focusedBackground = "white";
    _text.color = "black";
    _text.fontWeight = "10px";
    _text.isEnabled = false;
    _text.isVisible = false;
    _text.isPointerBlocker = true;
    _text.outlineColor = "blue";
    _text.background = "transparent";
    _text.mesh = _mesh;
    _text.disableMobilePrompt = true;
  };

  let onPointerDown = function (evt) {
    if (store.isiPad && isPointerOverGUIElement()) return;

    if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
      if (evt.button === 1) {
        return;
      }
      _dnClick++;
      // console.log(_dnClick);
      if (_dnClick === 1) {
        delayedExecutionEngine.executeAll();

        let pickInfo = scenePickController.pick(function (mesh) {
          return mesh.name === _rotateBoxName;
        });


        if (pickInfo.hit) {
          // console.log("detaching camera");
          // store.newScene.activeCamera.detachControl(canvas);
          _mesh = pickInfo.pickedMesh;
          // if (!_advancedTexture) _advancedTexture = advancedTexture;
          _createInputBox();
          // _advancedTexture.addControl(_text);

          if (_mesh.parentMesh.stack) {
            if (_stack_arr.length > 1) {
              _stack_arr.forEach(function (mesh) {
                if (!mesh.rotationQuaternion) {
                  mesh.rotationQuaternion =
                    new BABYLON.Quaternion.RotationYawPitchRoll(
                      mesh.rotation.y,
                      mesh.rotation.x,
                      mesh.rotation.z
                    );
                }
                mesh._prevRotation = mesh.rotationQuaternion.clone();
              });
            }
          } else {
            if (!_mesh.parentMesh.rotationQuaternion) {
              _mesh.parentMesh.rotationQuaternion =
                new BABYLON.Quaternion.RotationYawPitchRoll(
                  _mesh.parentMesh.rotation.y,
                  _mesh.parentMesh.rotation.x,
                  _mesh.parentMesh.rotation.z
                );
            }
            _mesh.parentMesh._prevRotation =
              _mesh.parentMesh.rotationQuaternion.clone();
          }

          _startingPoint = pickInfo.pickedPoint;

          let rotationSupportWall = BABYLON.Mesh.CreatePlane(
            "rotationSupportWall",
            1000000.0,
            store.newScene
          );
          let mat = store.newScene.getMaterialByName("rotationWallMat");
          if (!mat) {
            mat = new BABYLON.StandardMaterial(
              "rotationWallMat",
              store.newScene
            );
          }
          rotationSupportWall.material = mat;
          rotationSupportWall.material.alpha = 0;
          rotationSupportWall.type = "rotationSupportWall";
          rotationSupportWall.isPickable = true;

          rotationSupportWall.isVisible = false;
          scenePickController.add(rotationSupportWall);

          let normal = pickInfo.getNormal();
          normal = normal.normalize();

          if (_isRoom(_mesh.parentMesh) || _restrictGroupRotateToY) {
            _direction = "y";
            rotationSupportWall.rotate(
              BABYLON.Axis.X,
              Math.PI / 2,
              BABYLON.Space.WORLD
            );
            rotationSupportWall.position.y = _startingPoint.y;
          }
          else {
            if (normal.x !== 0) {
              _direction = "x";
              rotationSupportWall.rotate(
                BABYLON.Axis.Y,
                Math.PI / 2,
                BABYLON.Space.WORLD
              );
              rotationSupportWall.position.x = _startingPoint.x;
            } else if (normal.y !== 0) {
              _direction = "y";
              rotationSupportWall.rotate(
                BABYLON.Axis.X,
                Math.PI / 2,
                BABYLON.Space.WORLD
              );
              rotationSupportWall.position.y = _startingPoint.y;
            } else if (normal.z !== 0) {
              _direction = "z";
              rotationSupportWall.position.z = _startingPoint.z;
            }
          }

          _createBeforeCommand();
        } else _dnClick = 0;
      } else if (_dnClick === 2) {
        let pickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
          return mesh.name === "rotationSupportWall";
        });

        // if (_direction === "x") {
        //   _handlePoint1 = new BABYLON.Vector3(
        //     _startingPoint.x,
        //     pickInfo.pickedPoint.y,
        //     pickInfo.pickedPoint.z
        //   );
        // } else if (_direction === "y") {
        //   _handlePoint1 = new BABYLON.Vector3(
        //     pickInfo.pickedPoint.x,
        //     _startingPoint.y,
        //     pickInfo.pickedPoint.z
        //   );
        // } else if (_direction === "z") {
        //   _handlePoint1 = new BABYLON.Vector3(
        //     pickInfo.pickedPoint.x,
        //     pickInfo.pickedPoint.y,
        //     _startingPoint.z
        //   );
        // }

        _removeAllSelectionBoxes();
        // store.newScene.activeCamera.detachControl(canvas);
      } else if (_dnClick === 3) {
        if (store.isiPad) {
          _concludeOperation();
          onPointerDown(evt);
        } else {
          _concludeOperation();
        }
      }
    }
  };

  var drawSupportElements = function (handlePoint2) {
    let points = null;
    let radius = BABYLON.Vector3.Distance(_startingPoint, _handlePoint1);
    let newPoint = null;
    let angle = null;
    let vec = null;

    if (_direction === "x") {
      vec = handlePoint2.subtract(_startingPoint);
      angle = Math.atan2(vec.y, vec.z) - Math.PI / 2;
      newPoint = new BABYLON.Vector3(
        _startingPoint.x,
        _startingPoint.y + radius * Math.cos(-angle),
        _startingPoint.z + radius * Math.sin(-angle)
      );
      points = [_startingPoint, newPoint];
    } else if (_direction === "y") {
      vec = handlePoint2.subtract(_startingPoint);
      angle = Math.atan2(vec.z, vec.x) - Math.PI / 2;
      newPoint = new BABYLON.Vector3(
        _startingPoint.x + radius * Math.sin(-angle),
        _startingPoint.y,
        _startingPoint.z + radius * Math.cos(-angle)
      );
      points = [_startingPoint, newPoint];
    } else if (_direction === "z") {
      vec = handlePoint2.subtract(_startingPoint);
      angle = Math.atan2(vec.y, vec.x) - Math.PI / 2;
      newPoint = new BABYLON.Vector3(
        _startingPoint.x + radius * Math.sin(-angle),
        _startingPoint.y + radius * Math.cos(-angle),
        _startingPoint.z
      );
      points = [_startingPoint, newPoint];
    }

    let sector = showAngleSector(
      _startingPoint,
      _handlePoint1.subtract(_startingPoint),
      handlePoint2.subtract(_startingPoint),
      radius,
      1
    );
    sector.color = BABYLON.Color3.Blue();
    sector.renderingGroupId = 1;
    sector.isPickable = false;

    let line2 = BABYLON.MeshBuilder.CreateLines(
      "lines2",
      { points: points, updatable: true },
      store.newScene
    );
    let mat = store.newScene.getMaterialByName("line1Mat");
    if (!mat) {
      mat = new BABYLON.StandardMaterial("line1Mat", store.newScene);
    }
    line2.material = mat;
    line2.color = BABYLON.Color3.Blue();
    line2.isPickable = false;
    line2.renderingGroupId = 1;
  };

  var showAngleSector = function (
    origin,
    vector1,
    vector2,
    radius,
    sectorType
  ) {
    radius = radius || 1;
    sectorType = sectorType || 0;
    var cross = BABYLON.Vector3.Cross(vector1, vector2);
    var dot = BABYLON.Vector3.Dot(vector1, vector2);
    var angle = Math.acos(dot / (vector1.length() * vector2.length()));
    var points = [];
    var minNb = 4;
    var factor = 2;
    var nbPoints = Math.floor(radius * angle * factor);
    nbPoints = nbPoints < minNb ? minNb : nbPoints;

    var firstPoint = BABYLON.Vector3.Normalize(vector1).scale(radius);
    var lastPoint = BABYLON.Vector3.Normalize(vector2).scale(radius);
    var matrix;
    var ang = angle / nbPoints;
    var rotated;
    for (var i = 0; i < nbPoints; i++) {
      matrix = BABYLON.Matrix.RotationAxis(cross, ang * i);
      rotated = BABYLON.Vector3.TransformCoordinates(firstPoint, matrix);
      points.push(rotated.add(origin));
    }
    points.push(lastPoint.add(origin));

    var sector;
    switch (sectorType) {
      case 0:
        sector = BABYLON.Mesh.CreateLines("sector", points, store.scene);
        break;
      case 1:
        sector = BABYLON.Mesh.CreateDashedLines(
          "sector",
          points,
          3,
          1,
          nbPoints,
          store.scene
        );
        break;
      case 2:
        var pointO = [];
        for (var j = 0; j < points.length; j++) {
          pointO.push(origin);
        }
        sector = BABYLON.Mesh.CreateRibbon(
          "sector",
          [points, pointO],
          null,
          null,
          0,
          store.scene
        );
        break;
      default:
        sector = BABYLON.Mesh.CreateLines("sector", points, store.scene);
        break;
    }

    return sector;
  };

  var displayRotationAngle = function (angle, text, handlePoint) {
    angle = -angle;
    if (angle < 0) angle = angle + 2 * Math.PI;
    angle = Math.ceil((angle * 180) / Math.PI);
    handlePoint = BABYLON.Vector3.Project(
      handlePoint,
      BABYLON.Matrix.Identity(),
      store.newScene.activeCamera
        .getViewMatrix()
        .multiply(store.newScene.activeCamera.getProjectionMatrix()),
      store.newScene.activeCamera.viewport.toGlobal(
        store.engine.getRenderWidth(),
        store.engine.getRenderHeight()
      )
    );
    // console.log(handlePoint);
    text.isEnabled = store.isiPad;
    text.isVisible = true;
    text.text = angle.toString() + " degrees";
    text.color = "blue";
    text.fontSize = 20;
    text.autoStretchWidth = true;
    text.zIndex = 100;
    text.left = handlePoint.x - store.canvas.width / 2;
    text.top = handlePoint.y - store.canvas.height / 2;
  };

  let _drawBoxScale = function () {
    const predicate = _isPlanView() ?
      _planViewEligiblePredicate : _homeViewEligiblePredicate;

    const options = {};
    if (_isPlanView()) options.includeFloorPlans = true;
    
    // let pickInfo = scenePickController.pickWithBoundingInfo(predicate, options);
    let pickInfo = scenePickController.pick(predicate, options);

    removeRotationSupportElements();

    if (pickInfo.hit) {
      if (_currentMesh === pickInfo.pickedMesh) return;
      if (pickInfo.pickedMesh.type) {
        // if (
        //   ["wall", "mass", "furniture", "staircase"].includes(
        //     pickInfo.pickedMesh.type.toLowerCase()
        //   )
        // ) {
        _currentMesh = pickInfo.pickedMesh;
        if (store.newScene.getMeshByName(_rotateBoxName)) {
          store.newScene.getMeshByName(_rotateBoxName).dispose();
        }
        if (pickInfo.pickedMesh.stack) {
          if (_stack_arr.length > 1) {
            let vec_arr = [];
            _stack_arr.forEach(function (mesh) {
              let bbInfo = mesh.getBoundingInfo();
              vec_arr.push(bbInfo.boundingBox.maximumWorld);
              vec_arr.push(bbInfo.boundingBox.minimumWorld);
            });
            let x_min = _.minBy(vec_arr, "x");
            let y_min = _.minBy(vec_arr, "y");
            let z_min = _.minBy(vec_arr, "z");

            let x_max = _.maxBy(vec_arr, "x");
            let y_max = _.maxBy(vec_arr, "y");
            let z_max = _.maxBy(vec_arr, "z");

            let newBoundingBox = new BABYLON.BoundingInfo(
              new BABYLON.Vector3(x_min.x, y_min.y, z_min.z),
              new BABYLON.Vector3(x_max.x, y_max.y, z_max.z)
            );
            _drawGroupSelectionBoxRotate(newBoundingBox, pickInfo.pickedMesh);
          }
        } else {
          if (Mass.isPlinth(pickInfo.pickedMesh)) {
            return;
          }
          checkIfMeshToBeGroupRestricted(_currentMesh);
          _drawSelectionBoxRotate(pickInfo.pickedMesh);
        }
        // }
      }
    } else {
      if (store.newScene.getMeshByName(_rotateBoxName)) {
        store.newScene.getMeshByName(_rotateBoxName).dispose();
      }
      _currentMesh = null;
    }
  };

  let _removeAllSelectionBoxes = function () {
    let mesh = store.scene.getMeshByName("boxScale");
    while (mesh) {
      mesh.dispose();
      mesh = store.scene.getMeshByName("boxScale");
    }
    if (store.scene.getMeshByName(_rotateBoxName))
      store.scene.getMeshByName(_rotateBoxName).dispose();
  };

  let _drawSelectionBoxRotate = function (mesh) {
    if (mesh.name === _rotateBoxName || !nonDefaultMesh(mesh)) return;
    // console.log(mesh.name);
    var boxScale = store.scene.getMeshByName(_rotateBoxName);
    if (boxScale) {
      if (boxScale.parentMesh === mesh) {
        return;
      } else {
        boxScale.dispose();
      }
    }
    var bbinfo = mesh.getBoundingInfo();
    mesh.freezeWorldMatrix();
    bbinfo.update(mesh._worldMatrix);
    var height = bbinfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
    var width = bbinfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
    var depth = bbinfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
    // mesh.unfreezeWorldMatrix();
    // console.log(height, width, depth);
    // let wmtrix = mesh.getWorldMatrix();
    // let nepoint = BABYLON.Vector3.TransformCoordinates(bbinfo.boundingBox.extendSize, wmtrix);
    // var height = nepoint.y*2 + 0.1;
    // var width = nepoint.x*2 + 0.1;
    // var depth = nepoint.z*2 + 0.1;

    var center = bbinfo.boundingBox.centerWorld;
    mesh.unfreezeWorldMatrix();

    doorOperation.supportFloorBuilder.computeBottomPath(width, depth);
    const pathBottom = doorOperation.supportFloorBuilder.getPath();
    // var box = BABYLON.MeshBuilder.CreateBox(
    //   _rotateBoxName,
    //   { height: height, width: width, depth: depth },
    //   store.scene
    //
    var box = createCustomMesh(pathBottom, height);
    box.name = _rotateBoxName;
    box.position = center;
    box.enableEdgesRendering();
    box.edgesWidth = 8.0;
    box.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
    box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
    // box.rotation = mesh.rotation;
    // box.scaling = mesh.scaling;

    var material = new BABYLON.StandardMaterial("bbMat", store.scene);
    material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 1);
    material.alpha = 0.1;
    material.backFaceCulling = false;
    box.material = material;
    box.isVisible = true;
    // box.isPickable = false;
    mesh.children = [];
    mesh.children.push(box);
    box.parentMesh = mesh;
    scenePickController.add(box);

    doorOperation.supportFloorBuilder.reset();
    return box;
  };

  let _drawGroupSelectionBoxRotate = function (bbInfo, mesh) {
    let boxScale = store.scene.getMeshByName(_rotateBoxName); // selection boxes gotten with select tool
    if (boxScale) {
      if (boxScale.parentMesh === mesh) {
        return;
      } else {
        boxScale.dispose();
      }
    }

    let height = bbInfo.boundingBox.extendSizeWorld.y * 2 + 0.1;
    let width = bbInfo.boundingBox.extendSizeWorld.x * 2 + 0.1;
    let depth = bbInfo.boundingBox.extendSizeWorld.z * 2 + 0.1;
    let center = bbInfo.boundingBox.centerWorld;

    doorOperation.supportFloorBuilder.computeBottomPath(width, depth);
    const pathBottom = doorOperation.supportFloorBuilder.getPath();

    let box = createCustomMesh(pathBottom, height);
    box.name = _rotateBoxName;
    box.position = center;
    box.enableEdgesRendering();
    box.edgesWidth = 8.0;
    box.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
    box.sideOrientation = BABYLON.Mesh.DOUBLESIDE;

    let mat = store.scene.getMaterialByName("bbMat");
    let material;
    if (mat) material = mat;
    else {
      material = new BABYLON.StandardMaterial("bbMat", store.scene);
      material.diffuseColor = new BABYLON.Color3(0.3, 0.5, 1);
      material.alpha = 0.1;
      material.backFaceCulling = false;
    }
    box.material = material;
    // box.isPickable = false;
    box.isVisible = true;
    mesh.children = [];
    mesh.children.push(box);
    box.parentMesh = mesh;
    doorOperation.supportFloorBuilder.reset();

    scenePickController.add(box);
  };

  let updateRotateAngle = function (text) {
    let angle = text.replace(/[^0-9]/g, "");
    if (_mesh.parentMesh.stack) {
      if (_stack_arr.length > 1) {
        _stack_arr.forEach(function (mesh) {
          mesh.rotationQuaternion = new BABYLON.Quaternion(
            mesh._prevRotation.x,
            mesh._prevRotation.y,
            mesh._prevRotation.z,
            mesh._prevRotation.w
          );
        });
      }
    } else {
      if (_mesh) {
        _mesh.parentMesh.rotationQuaternion = new BABYLON.Quaternion(
          _mesh.parentMesh._prevRotation.x,
          _mesh.parentMesh._prevRotation.y,
          _mesh.parentMesh._prevRotation.z,
          _mesh.parentMesh._prevRotation.w
        );
      }
    }
    angle = -angle;
    angle = (angle * Math.PI) / 180;
    if (_mesh.parentMesh.stack) {
      if (_stack_arr.length > 1) {
        _rotateMesh(_stack_arr[0], angle);
      }
    } else _rotateMesh(_mesh.parentMesh, angle);

    _concludeOperation();
  };

  return {
    onPointerDown,

    onPointerMove: function (evt) {
      if (store.isiPad && isPointerOverGUIElement()) return;

      if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
        if (_dnClick === 0) {
          // if (store.BIMProject && !store.$scope.isTwoDimension) return; //Disabling rotate in 3D in BIM mode
          disposeHighlightedFace(faceIndicatorName);
          _drawBoxScale();
          let pickInfo = scenePickController.pick(function (mesh) {
            return mesh.name === _rotateBoxName;
          });

          if (pickInfo.hit) {
            let faceVertices = getFaceVerticesFromFacetID(
              pickInfo.faceId,
              pickInfo.pickedMesh,
              BABYLON.Space.WORLD,
              {brepInMesh: true}
            );

            let normal = pickInfo.getNormal();
            normal = normal.normalize();

            let material = null;
            if (_isRoom(pickInfo.pickedMesh.parentMesh) || _restrictGroupRotateToY) {
              material = colorUtil.getMaterial(colorUtil.type.y);
            }
            else {
              if (normal.x !== 0) {
                material = colorUtil.getMaterial(colorUtil.type.x);
              }
              else if (normal.y !== 0) {
                material = colorUtil.getMaterial(colorUtil.type.y);
              }
              else if (normal.z !== 0) {
                material = colorUtil.getMaterial(colorUtil.type.z);
              }
            }

            showFaceIndicator(
              faceVertices,
              pickInfo.pickedMesh,
              faceIndicatorName,
              material
            );
          }
        } else if (_dnClick === 1) {
          disposeHighlightedFace(faceIndicatorName);
          let points = null;
          let pickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
            return mesh.name === "rotationSupportWall";
          });

          if (store.newScene.getMeshByName("lines1")) {
            store.newScene.getMeshByName("lines1").dispose();
          }
          if (_direction === "x") {
            points = [
              _startingPoint,
              new BABYLON.Vector3(
                _startingPoint.x,
                pickInfo.pickedPoint.y,
                pickInfo.pickedPoint.z
              ),
            ];
          } else if (_direction === "y") {
            points = [
              _startingPoint,
              new BABYLON.Vector3(
                pickInfo.pickedPoint.x,
                _startingPoint.y,
                pickInfo.pickedPoint.z
              ),
            ];
          } else if (_direction === "z") {
            points = [
              _startingPoint,
              new BABYLON.Vector3(
                pickInfo.pickedPoint.x,
                pickInfo.pickedPoint.y,
                _startingPoint.z
              ),
            ];
          }
          const snapPt = findAxisSnappedPoint(points[0], {axisSnapPoint: points[1], extension: 0.3});
          if (snapPt) points[1] = snapPt;
          _handlePoint1 = points[1];

          let line1 = BABYLON.MeshBuilder.CreateLines(
            "lines1",
            { points: points, updatable: true },
            store.newScene
          );
          let mat = store.newScene.getMaterialByName("line1Mat");
          if (!mat) {
            mat = new BABYLON.StandardMaterial("line1Mat", store.newScene);
          }
          line1.material = mat;
          line1.color = BABYLON.Color3.Blue();
          line1.isPickable = false;
          line1.renderingGroupId = 1;
        } else if (_dnClick === 2) {
          let pickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
            return mesh.name === "rotationSupportWall";
          });

          if (store.newScene.getMeshByName("lines2"))
            store.newScene.getMeshByName("lines2").dispose();
          if (store.newScene.getMeshByName("sector"))
            store.newScene.getMeshByName("sector").dispose();

          let _handlePoint2 = null;

          if (_direction === "x") {
            _handlePoint2 = new BABYLON.Vector3(
              _startingPoint.x,
              pickInfo.pickedPoint.y,
              pickInfo.pickedPoint.z
            );
          } else if (_direction === "y") {
            _handlePoint2 = new BABYLON.Vector3(
              pickInfo.pickedPoint.x,
              _startingPoint.y,
              pickInfo.pickedPoint.z
            );
          } else if (_direction === "z") {
            _handlePoint2 = new BABYLON.Vector3(
              pickInfo.pickedPoint.x,
              pickInfo.pickedPoint.y,
              _startingPoint.z
            );
          }
          const snapPt = findAxisSnappedPoint(_startingPoint, {axisSnapPoint: _handlePoint2, extension: 0.3});
          if (snapPt) _handlePoint2 = snapPt;

          let angle = calculateAngleForRotation(
            _startingPoint,
            _handlePoint1,
            _handlePoint2
          );
          if (_mesh.parentMesh.stack) {
            if (_stack_arr.length > 1) {
              _rotateMesh(_stack_arr[0], angle);
            }
          } else _rotateMesh(_mesh.parentMesh, angle);
          drawSupportElements(_handlePoint2);
          displayRotationAngle(angle, _text, _handlePoint2);
        }
      }
    },

    onPointerUp: function (evt) {
      if (store.isiPad) {
        if (_dnClick === 2) {
          // keypadController.showKeypad(_text, updateRotateAngle);
        }
      }
    },

    updateRotateAngle,

    assignStackArray: function (arr) {
      if (arr.length > 1) {
        _stack_arr = arr;
        _.remove(_stack_arr, (mesh) => Mass.isPlinth(mesh));
        let firstMesh = _stack_arr[0];
        firstMesh.stack = true;
        if (firstMesh.parent) {
          firstMesh._prevParent = firstMesh.parent.uniqueId;
          firstMesh.setParent(null);
        }
        checkIfMeshToBeGroupRestricted(firstMesh);

        for (let i = 1; i < _stack_arr.length; i++) {
          let mesh = _stack_arr[i];
          mesh.stack = true;
          if (mesh.parent) {
            mesh._prevParent = mesh.parent.uniqueId;
            mesh.setParent(null);
          }
          mesh.setParent(firstMesh);
          checkIfMeshToBeGroupRestricted(mesh);
        }
      }
    },

    _reset: function () {
      let isWorking = false;
      if (_dnClick === 1 || _dnClick === 2) {
        if (_mesh.parentMesh.stack) {
          if (_stack_arr.length > 1) {
            _stack_arr.forEach(function (mesh) {
              mesh.rotationQuaternion = new BABYLON.Quaternion(
                mesh._prevRotation.x,
                mesh._prevRotation.y,
                mesh._prevRotation.z,
                mesh._prevRotation.w
              );
              mesh._prevRotation = null;
            });
          }
        } else {
          if (_mesh) {
            _mesh.parentMesh.rotationQuaternion = new BABYLON.Quaternion(
              _mesh.parentMesh._prevRotation.x,
              _mesh.parentMesh._prevRotation.y,
              _mesh.parentMesh._prevRotation.z,
              _mesh.parentMesh._prevRotation.w
            );
            _mesh.parentMesh._prevRotation = null;
          }
        }
        isWorking = true;
      }
      if (!isWorking) {
        _removeParentChildRelationships(true);
        _stack_arr.length = 0;
      }
      _dnClick = 0;
      removeRotationSupportElements();
      if (store.scene.getMeshByName(_rotateBoxName)) {
        store.scene.getMeshByName(_rotateBoxName).dispose();
      }
      keypadController.removeKeypad();
      delayedExecutionEngine.flush();
      _startingPoint = null;
      _handlePoint1 = null;
      _direction = null;
      _mesh = null;
      _restrictGroupRotateToY = false;
      _currentMesh = null;
      store.numberFlag = 0;
      uiIndicatorsHandler.axisIndicator.remove();
      if (!_.isEmpty(store.selectionStack)) {
        removeSelectionBox();
        store.selectionStack.forEach(m => {
          m.state = "on";
          drawSelectionBox(m);
        })
      }
      if (_text) {
        // advancedTexture.removeControl(_text);
        DisplayOperation.removeDimensions();
        _text = null;
      }
      return isWorking;
    },
  };
})();
export { RotateOperation };
