var room_prop = {};
room_prop.height = 1;
room_prop.mat = "none";
room_prop.far = true;

var balcony_prop = {};
balcony_prop.height = 0.4;
balcony_prop.mat = "none";
balcony_prop.far = false;

var workspace_prop = {};
workspace_prop.height = 0.5;
workspace_prop.mat = "none";
workspace_prop.far = false;

var cabin_prop = {};
cabin_prop.height = 1;
cabin_prop.mat = "none";
cabin_prop.far = false;

var conf_prop = {};
conf_prop.height = 1;
conf_prop.mat = "none";
conf_prop.far = false;

var foyer_prop = {};
foyer_prop.height = 1;
foyer_prop.mat = "none";
foyer_prop.far = true;

var garden_prop = {};
garden_prop.height = 0.1;
garden_prop.mat = "garden";
garden_prop.far = false;

var park_prop = {};
park_prop.height = 0.1;
park_prop.mat = "park";
park_prop.far = false;

var water_prop = {};
water_prop.height = 0.1;
water_prop.mat = "water";
water_prop.far = false;

var tower_prop = {};
tower_prop.height = 1;
tower_prop.mat = "none";
tower_prop.far = true;

var vacant_prop = {};
vacant_prop.height = 0.1;
vacant_prop.mat = "none";
vacant_prop.far = false;

var sports_prop = {};
sports_prop.height = 0.1;
sports_prop.mat = "sports";
sports_prop.far = false;

var ground_prop = {};
ground_prop.height = 0.1;
ground_prop.mat = "ground";
ground_prop.far = false;

var club_prop = {};
club_prop.height = 1;
club_prop.mat = "none";
club_prop.far = true;

var road_prop = {};
road_prop.height = 1;
road_prop.mat = "road";
road_prop.far = false;

var lift_prop = {};
lift_prop.height = 1;
lift_prop.mat = "lift";
lift_prop.far = false;

var elevator_prop = {};
elevator_prop.height = 1;
elevator_prop.mat = "elevator";
elevator_prop.far = false;

var void_prop = {};
void_prop.height = 0.1;
void_prop.mat = "none";
void_prop.far = false;

var bound_prop = {};
bound_prop.height = 0.1;
bound_prop.mat = "none";
bound_prop.far = false;

var stairs_prop = {};
stairs_prop.height = 0.1;
stairs_prop.mat = "stairs";
stairs_prop.far = false;

var parking_prop = {};
parking_prop.height = 0.1;
parking_prop.mat = "parking";
parking_prop.far = false;

var basement_prop = {};
basement_prop.height = 0.1;
basement_prop.mat = "basement";
basement_prop.far = false;

var room_types_db = [
  { value: "Default", name: "Default", props: room_prop },
  { value: "Bedroom", name: "Bedroom", props: room_prop },
  { value: "Living", name: "Living Room", props: room_prop },
  { value: "Kitchen", name: "Kitchen", props: room_prop },
  { value: "Dining", name: "Dining", props: room_prop },
  { value: "Bathroom", name: "Bathroom", props: room_prop },
  { value: "Toilet", name: "Toilet", props: room_prop },
  { value: "Balcony", name: "Balcony", props: balcony_prop },
  { value: "Workspace", name: "Workspace", props: workspace_prop },
  { value: "Cabin", name: "Cabin", props: cabin_prop },
  { value: "Conference", name: "Conference Room", props: conf_prop },
  { value: "Foyer", name: "Foyer", props: foyer_prop },
  { value: "Garden", name: "Garden", props: garden_prop },
  { value: "Park", name: "Park", props: park_prop },
  { value: "Road", name: "Road", props: road_prop },
  { value: "Water Body", name: "Water Body", props: water_prop },
  { value: "Existing Building", name: "Existing Building", props: tower_prop },
  { value: "Vacant", name: "Vacant", props: vacant_prop },
  { value: "Sports", name: "Sports", props: sports_prop },
  { value: "Ground", name: "Ground", props: ground_prop },
  { value: "Club", name: "Club", props: club_prop },
  { value: "Lift", name: "Lift", props: lift_prop },
  { value: "Elevator", name: "Elevator", props: elevator_prop },
  { value: "Void", name: "Void", props: void_prop },
  { value: "Boundary", name: "Boundary", props: bound_prop },
  { value: "Site", name: "Site", props: bound_prop },
  { value: "Setback", name: "Setback", props: bound_prop },
  { value: "Parking", name: "Parking", props: parking_prop },
  { value: "Basement", name: "Basement", props: basement_prop },
];
export { room_types_db, room_prop,balcony_prop,workspace_prop,cabin_prop,conf_prop,foyer_prop,garden_prop,park_prop,water_prop,tower_prop,vacant_prop,sports_prop,ground_prop,club_prop,road_prop,lift_prop,elevator_prop,void_prop,bound_prop,stairs_prop,parking_prop,basement_prop };
