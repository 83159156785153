import { meshDiagnostics } from "./meshDiagnostics.js";
import { saveDiagnostics } from "./saveDiagnostics.js";
import { graphDiagnostics } from "./graphDiagnostics.js";
import { dimensionsDiagnostics } from "./dimensionsDiagnostics.js";
import brepDiagnostics from "./brepDiagnostics.js";
import snapDiagnostics from "./snapDiagnostics";
import debugDiagnostics from "./debugDiagnostics";

const diagnostics = (function () {

  let _inDebugMode = false;

  const turnOnDebugMode = function (){
    _inDebugMode = true;
  }

  const turnOffDebugMode = function (){
    _inDebugMode = false;
  }

  const isInDebugMode = function (){
    return _inDebugMode;
  }

  return {
    meshDiagnostics,
    saveDiagnostics,
    graphDiagnostics,
    dimensionsDiagnostics,
    snapDiagnostics,
    brepDiagnostics,
    debugDiagnostics,

    turnOnDebugMode,
    turnOffDebugMode,
    isInDebugMode,
  };
})();
export { diagnostics };
