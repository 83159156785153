import _ from 'lodash';
import React, { useRef, useState } from 'react'
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { createFolder, validateFolderName } from '../../services/folder.service';
import { findFolderRecursive } from '../../snaptrude/stateManagers/reducers/objectProperties/teamsSlice';
import { colors } from '../../themes/constant';
import Button from '../Button';
import Input from '../Input';
import Text from '../Text';
import { CloseButtonIcon, ModalChild, ModalHeader, ICON, modalStylesDefault, ModalBody, StyledRow, StyledCol, ModalFooter, ButtonWrapper, Card, uneditableContainerStyle } from './style';

function CreateFolderModal({ teamID, folderID, handleClose, ...props }) {
    const [folderName, setFolderName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isFormSubmitting = useRef(false);
    const { team, parentFolder } = useSelector(state => {
        let team, parentFolder;
        if (teamID) {
            team = state.teams.teamlist.find(team => team.id === teamID);
            parentFolder = findFolderRecursive(team.fileSystem?.folders, folderID);
        } else {
            team = null;
            parentFolder = findFolderRecursive(state.teams.personalWorkspace.fileSystem.folders, folderID);
        }
        return {
            team,
            parentFolder
        }
    })
    const handleSubmit = async (evt) => {
        if (evt) evt.preventDefault();
        if (isFormSubmitting.current) return;
        isFormSubmitting.current = true;
        setErrorMessage("");
        setIsLoading(true);
        const { isValid, reason } = validateFolderName(folderName);
        if (isValid) {
            const resp = await createFolder(folderName, teamID, folderID);
            if (resp.status === "success") {
                handleClose();
            }
        } else {
            setErrorMessage(reason);
        }
        isFormSubmitting.current = false;
        setIsLoading(false);
    }
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={handleClose}
            style={modalStylesDefault}
        >
            <Card>
                <ModalHeader>
                    <ModalChild left="1.0625rem" style={{ maxWidth: "31rem", whiteSpace: "nowrap", overflow: "hidden" }}>
                        <Text opacity={1} weight="bold">
                            Create New Folder
                        </Text>
                    </ModalChild>
                    <ModalChild right="1.0625rem">
                        <CloseButtonIcon onClick={handleClose} src={ICON.CloseButton} alt="icon" />
                    </ModalChild>
                </ModalHeader>
                <ModalBody>
                    {
                        team &&
                        <StyledRow>
                            <StyledCol>Team</StyledCol>
                            <StyledCol style={uneditableContainerStyle}>{team.title}</StyledCol>
                        </StyledRow>
                    }
                    {
                        parentFolder &&
                        <StyledRow>
                            <StyledCol>Parent Folder</StyledCol>
                            <StyledCol style={uneditableContainerStyle}>{parentFolder.name}</StyledCol>
                        </StyledRow>
                    }
                    <StyledRow>
                        <StyledCol>Folder name</StyledCol>
                        <StyledCol>
                            <form onSubmit={handleSubmit}>
                                <Input
                                    placeholder={"Enter new folder name"}
                                    value={folderName}
                                    onPress={(e) => { setFolderName(e.target.value) }}
                                    autoFocus={true}
                                    errorMessage={errorMessage}
                                    error={Boolean(errorMessage)}
                                />
                            </form>
                        </StyledCol>
                    </StyledRow>
                </ModalBody>
                <ModalFooter>
                    <ButtonWrapper>
                        <Button onPress={handleClose} style={{ backgroundColor: colors.transparent, color: colors.dullgrey, }} title="< Cancel" />
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <Button disabled={isLoading} isLoading={isLoading} primary title="Create" onPress={handleSubmit} />
                    </ButtonWrapper>
                </ModalFooter>
            </Card>
        </ReactModal>
    );
}

export default CreateFolderModal;