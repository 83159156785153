export const ROOM_TYPE_PROPERTIES ={
  "Default": {
      "mass": {},
      "bim": {}
  },
  "Living Room": {
      "mass": {},
      "bim": {}
  },
  "Dining Room": {
      "mass": {},
      "bim": {}
  },
  "Kitchen": {
      "mass": {},
      "bim": {}
  },
  "Bedroom": {
      "mass": {},
      "bim": {}
  },
  "Bathroom": {
      "mass": {},
      "bim": {}
  },
  "Balcony": {
      "mass": {
          "height": 1
      },
      "bim": {}
  },
  "Deck": {
      "mass": {
          "height": 0.15
      },
      "bim": {
          "noWalls": true,
          "noRoofs": true
      }
  },
  "Office Cabin": {
      "mass": {},
      "bim": {}
  },
  "Site": {
      "mass": {
          "height": 0.001
      },
      "bim": {
          "wallHeight": 1.5,
          "noFloors": true,
          "noRoofs": true,
          "preserveMass": true
      }
  },
  "Ground": {
      "mass": {
          "height": 0.1
      },
      "bim": {
          "noWalls": true,
          "noFloors": true,
          "noRoofs": true,
          "preserveMass": true
      }
  },
  "Workspace": {
      "mass": {},
      "bim": {}
  },
  "Conference Room": {
      "mass": {},
      "bim": {}
  },
  "Foyer": {
      "mass": {},
      "bim": {}
  },
  "Road": {
      "mass": {
          "height": 0.1
      },
      "bim": {
          "noWalls": true,
          "noFloors": true,
          "noRoofs": true,
          "preserveMass": true
      }
  },
  "Footpath": {
      "mass": {
          "height": 0.1
      },
      "bim": {
          "noWalls": true,
          "noFloors": true,
          "noRoofs": true,
          "preserveMass": true
      }
  },
  "Water Body": {
      "mass": {
          "height": -0.5
      },
      "bim": {
          "noRoofs": true,
          "preserveMass": true
      }
  },
  "Pool": {
      "mass": {
          "height": -0.5
      },
      "bim": {
          "noRoofs": true,
          "preserveMass": true
      }
  },
  "Club": {
      "mass": {},
      "bim": {}
  },
  "Lift": {
      "mass": {},
      "bim": {}
  }
}