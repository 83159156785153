import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import * as styles from "./styles";
import arrow from "../../assets/icons/arrow.svg";
import Dropdown from "../Dropdown/index";

const StyledRecentBox = styled.div`
  font-family: ${(props) => props.theme.font};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.clearWhite};
  border: 0.1rem solid ${(props) => props.theme.colors.blackRgb};
  box-sizing: border-box;
  border-radius: 0.313rem;
  height: 2.188rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  min-width: 10rem;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  &:hover,
  &:focus-within {
    border-color: ${(props) => props.theme.colors.lightGrey};
  }
  & span{
    white-space: nowrap;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.2rem;
    /* identical to box height */
    color: ${(props) => props.theme.colors.secondaryBlack};
    opacity: 0.5;
    margin-right: 0.9rem;
  }
  ${({ isOptionElement }) =>
    !isOptionElement &&
    css`
      span {
        color: ${(props) => props.theme.colors.secondaryBlack};
        cursor: pointer;
        letter-spacing: -0.021rem;
        font-size: 0.75rem;
        font-family: ${(props) => props.theme.font};
      }
    `}
  .element {
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.gray};
    position: absolute;
    padding: 0.6rem;
  }
  .toggle-icon {
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .icon {
    margin: 0 0.5rem 0 0.5rem;
  }
  .dropdown {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const RecentBox = (props) => {
  const {
    header,
    customBoxStyles,
    optionAppender,
    customParentDivStyles,
    customSelectedTextStyle,
    options,
    selectedOption,
    customDropDownStyle,
    selectOption,
    withIcon,
    seperate,
    align,
    selectedItemColor,
    isOptionElement,
  } = props;
  const [selectedItem, setSelectedItem] = useState(
    selectedOption || options[0]
  );
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if(selectedOption){
      setSelectedItem(selectedOption)
    }
  }, [selectedOption]);

  const handleSelect = (item) => {
    setSelectedItem(item);
    if (selectOption) selectOption(item);
  };

  const getDropDown = (icon) => {
    if (icon) {
      return (
        <Dropdown
          ref={dropdownRef}
          items={options}
          icon={arrow}
          customDropdownStyles={{
            ...customDropDownStyle,
          }}
          customParentDivStyles={customParentDivStyles}
          selectedItem={selectedItem}
          selected={handleSelect}
          separate={seperate}
          isOptionElement={isOptionElement || false}
          selectedItemColor={selectedItemColor}
          align={align || "left"}
        />
      );
    } else {
      return (
        <Dropdown
          ref={dropdownRef}
          items={options}
          forceOpen={toggleDropdown}
          customParentDivStyles={styles.sortByBox.customParentDivStyles}
          customDropdownStyles={{
            ...customDropDownStyle,
          }}
          selected={handleSelect}
          selectedItem={selectedItem}
          selectedItemColor={selectedItemColor}
          isOptionElement={isOptionElement || false}
          separate={seperate}
          align={align || "right"}
          element={<span className="element">{optionAppender}</span>}
        />
      );
    }
  };

  return (
    <StyledRecentBox
      selectedItem={selectedItem}
      isOptionElement={isOptionElement}
      onClick={() => setToggleDropdown(!toggleDropdown)}
      style={customBoxStyles}
    >
      {header && <p>{header}</p>}
      <span
        onClick={() => {
          if (dropdownRef.current && dropdownRef.current.firstChild) {
            dropdownRef.current.firstChild.click();
          }
        }}
        style={customSelectedTextStyle}
      >
        {selectedItem.title || selectedItem}{" "}
      </span>
      {optionAppender
        ? withIcon
          ? getDropDown(true)
          : toggleDropdown && getDropDown(false)
        : withIcon
        ? getDropDown(true)
        : toggleDropdown && getDropDown(false)}
    </StyledRecentBox>
  );
};

RecentBox.propTypes = {
  header: PropTypes.string,
  customBoxStyles: PropTypes.instanceOf(Object),
  optionAppender: PropTypes.any,
  customSelectedTextStyle: PropTypes.instanceOf(Object),
  options: PropTypes.array,
  selectedOption: PropTypes.any,
  customDropDownStyle: PropTypes.instanceOf(Object),
  selectOption: PropTypes.func,
  withIcon: PropTypes.bool,
  seperate: PropTypes.bool,
  align: PropTypes.string,
  selectedItemColor: PropTypes.string,
};
