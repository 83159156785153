import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { store } from "../../../modules/utilityFunctions/Store";

export const _sliceName = "coveAnalysis"

/**
 * All states ending with 'Table'
 * structure pattern -> 
 * someNameTable = {
 *  [floor_key]: { // original project floor_key; in records parent_floor_key
 *          [floor_key]: boolean | object, //  cove project floor key
 *      }
 * }
 */

const initialState = {
    // information
    isCoveToolProject: null,
    coveProjectDetails: null,
    allEnergyCodes: null,
    // project settings
    fetchingSettings: false,
    fetchingEnergyCodes: false,
    projectSettings: {
        energyCode: null,
        energyCodeName: null,
        buildingTypes: [],
        location: {
          lat: null,
          lng: null
        }
    },
    // loading and daylight data
    newRecordsTable: {}, // for showing completed analysis modal
    notificationTable: {}, // for indicating an unseen analysis
    remainingAnalysisCount: 0, // count of remaining analysis
    runningAnalysisTable: {}, // for tracking analysis
    records: [], //  for storing records
    analysisTable: {}, // for storing latest analysis
    fetchingRecords: false, // loading state when fetching records or latest analysis
    eui: {}, // for storing, eui of latest run/opened covetool project
    fetchingEUI: false, // loading state when fetching eui
    fetchingAnalysis: false, // loading state when daylight analysis grids,
    infoModal: {
        open: false,
        title: "",
        body: ""
    },
    blinkFake: false,
    tempDataForLoading: {}
};

export const coveAnalysisSlice = createSlice({
    name: _sliceName,
    initialState,
    reducers: {
        reset: (state) => ({
            ...initialState,
            allEnergyCodes: state.allEnergyCodes
        }),
        setFetchingEnergyCodesAs: (state, action) => {
            state.fetchingEnergyCodes = action.payload
        },
        setEnergyCodes: (state, action) => {
            state.allEnergyCodes = action.payload
            store.daylightAnalysis.allEnergyCodes = action.payload
        },
        setFetchingSettingsAs: (state, action) => {
            state.fetchingSettings = action.payload
        },
        updateSettings: (state, action) => {
            const data = action.payload

            if(state.projectSettings) {
                state.projectSettings = {
                    ...state.projectSettings,
                    ...data
                }
            } else {
                state.projectSettings = data
            }

            // keep changes in sync with store
            if(typeof data === 'object') {
                store.daylightAnalysis.projectSettings = {
                    ...store.daylightAnalysis.projectSettings,
                    ...data
                }
            }
        },
        setFetchingRecordsAs: (state, action) => {
            state.fetchingRecords = action.payload
        },
        setFetchingEUIAs: (state, action) => {
            state.fetchingEUI = action.payload
        },
        setFetchingAnalysisAs: (state, action) => {
            state.fetchingAnalysis = action.payload
        },
        setRecords: (state, action) => {
            state.records = action.payload
        },
        addRecord: (state, action) => {
            state.records.unshift(action.payload);
        },
        updateRecord: (state, action) => {
            const record = action.payload

            const recordIndex = _.findIndex(state.records, (item) => item?.floor_key === record?.floor_key, 0);
            state.records[recordIndex] = record;
        },
        addRunningAnalysis: (state, action) => {
            const floor_key = action.payload?.floor_key
            const parent_floor_key = action.payload?.parent_floor_key

            if(!floor_key || !parent_floor_key) return

            if(!state.runningAnalysisTable[parent_floor_key]) {
                state.runningAnalysisTable[parent_floor_key] = {}
            }

            state.runningAnalysisTable[parent_floor_key][floor_key] = true
            // state.remainingAnalysisCount++;
        },
        removeRunningAnalysis: (state, action) => {
            const floor_key = action.payload?.floor_key
            const parent_floor_key = action.payload?.parent_floor_key

            if(parent_floor_key in state.runningAnalysisTable) {
                delete state.runningAnalysisTable[parent_floor_key][floor_key]
                // state.remainingAnalysisCount--;
            }
        },
        addNewRecord: (state, action) => {
            const floor_key = action.payload?.floor_key
            const record = action.payload?.record

            if(!(floor_key in state.newRecordsTable)) state.newRecordsTable[floor_key] = []

            // only show completion modal if record belongs to current opened project
            if(store.floorkey == record?.parent_floor_key) {
                // safety check - must be an array
                if(Array.isArray(state.newRecordsTable[floor_key])) {
                    // and does not exist already
                    if(state.newRecordsTable[floor_key].find(ele => ele.floor_key == floor_key) == undefined) {
                        state.newRecordsTable[floor_key].push(record);
                        // only show notification on sidebar if record belongs to current opened project
                        state.notificationTable[floor_key] = true
                    }
                }
            }
        },
        removeNewRecord: (state, action) => {
            const floor_key = action.payload?.floor_key
            if(Array.isArray(state.newRecordsTable[floor_key]) && state.newRecordsTable[floor_key].length > 0) {
                state.newRecordsTable[floor_key].pop()
            }
        },
        setNotify: (state, action) => {
            state.notificationTable[store.floorkey] = action.payload
        },
        setCoveProjectDetails: (state, action) => {
            state.coveProjectDetails = action.payload
        },
        clearCoveProjectDetails: (state) => {
            state.coveProjectDetails = null
        },
        setAnalysis: (state, action) => {
            const parent_floor_key = action.payload?.parent_floor_key
            state.analysisTable[parent_floor_key] = action.payload
        },
        removeAnalysis: (state, action) => {
            delete state.analysisTable[action.payload?.floor_key]
        },
        setEUI: (state, action) => {
            const floor_key = action.payload?.floor_key
            const data = action.payload?.data
            state.eui[floor_key] = data
        },
        removeEUI: (state, action) => {
            const floor_key = action.payload?.floor_key
            state.eui[floor_key] = undefined
        },
        showInfoModal: (state, action) => {
            state.infoModal.open = true
            state.infoModal.title = action.payload?.title
            state.infoModal.body = action.payload?.body
        },
        closeInfoModal: (state) => {
            state.infoModal.open = false
            state.infoModal.title = ""
            state.infoModal.body = ""
        },
        startFakeLoading: (state, action) => {
            state.blinkFake = true
            state.tempDataForLoading['details'] = {
                energy_code_name: action.payload?.energy_code_name,
                building_types: action.payload?.building_types
            }
            state.tempDataForLoading['added'] = new Date()
        },
        stopFakeLoading: (state) => {
            state.blinkFake = false
            state.tempDataForLoading = {}
        }
    },
});

export const Action = coveAnalysisSlice.actions;
export const getCoveAnalysisState = (state) => state[_sliceName];


export default coveAnalysisSlice.reducer;