import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { getFace } from "./faceFuncs.js";
import { getWallAngle } from "./drawStairEvents.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
var windowt1 = null;
var windowt2 = null;
var _globalWindow = null;
var onWindowDrawDown = function (evt) {
  var window = store.scene.getMeshByName("WindowsTemp");
  _globalWindow = window;
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;
  if (window) {
    if ($scope.searchTextWindow[0].toLowerCase() === "single")
      window.name = "Single_Window";
    else if ($scope.searchTextWindow[0].toLowerCase() === "double")
      window.name = "Double_Window";
    else if ($scope.searchTextWindow[0].toLowerCase() === "sliding")
      window.name = "Sliding_Window";
    else if ($scope.searchTextWindow[0].toLowerCase() === "Triple")
      window.name = "Triple_Window";
    else if ($scope.searchTextWindow[0].toLowerCase() === "Quadruple")
      window.name = "Quadruple_Window";
    else window.name = "Single_Window";
    window.type = "Window";

    let structures = StructureCollection.getInstance();
    window.structure_id = window.parent.structure_id;
    let str = structures.getStructureById(window.structure_id);
    let level = str.getLevelByUniqueId(
      str.getObjectByUniqueId(window.parent.uniqueId).level_id
    );
    level.addMeshToLevel(window, false);

    updateModifications();
  }
};

var onWindowDrawUp = function (evt) {};

var onWindowDrawMove = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      // return mesh !== store.scene.getMeshByName('ground1');
      return mesh.name == "wall";
    }
  );
  var step_size = [3.6, 3.6];
  if (pickInfo.hit) {
    var $scope = store.angular.element(appElement).scope();
    $scope = $scope.$$childHead;
    if (pickInfo.pickedMesh.name == "wall") {
      var face = getFace(pickInfo);
      var angle = getWallAngle(face);
      var window;
      if (store.scene.getMeshByName("WindowsTemp")) {
        window = store.scene.getMeshByName("WindowsTemp");
        _globalWindow = window;
      } else {
        window = drawWindow(pickInfo, $scope.searchTextWindow[0], step_size);
        _globalWindow = window;
      }
      var bbinfo = window.getBoundingInfo();
      let bbinfo1 = pickInfo.pickedMesh.getBoundingInfo();
      window.position = pickInfo.pickedPoint;
      window.position.x +=
        2 * (window.getBoundingInfo().maximum.x / 2) * Math.cos(angle);
      window.position.y =
        (pickInfo.pickedMesh.getBoundingInfo().maximum.y +
          pickInfo.pickedMesh.getBoundingInfo().minimum.y) /
        2;
      window.position.z -=
        2 * (window.getBoundingInfo().maximum.z / 2) * Math.sin(angle);
      window.rotation.y = angle;
      window.parent = pickInfo.pickedMesh;
      /*
            TODO: Draw a dim line between pickInfo.pickedMesh.position to door.position
            and show the distance as BABYLON.Distance()
             */
      let vw = bbinfo1.boundingBox.vectorsWorld;
      // st = (vw[5].add(vw[2])).scale(0.5);
      // let end = Object.assign({},window.position);
      // end.y = st.y;
      // DisplayOperation.drawOnMove(st, end);
      // DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st, end));

      let end = window.position.clone();
      let st1 = null;
      let st2 = null;
      if (
        BABYLON.Vector3.Distance(vw[0], vw[4]) <
        BABYLON.Vector3.Distance(vw[0], vw[2])
      ) {
        st1 = vw[3]
          .add(vw[6])
          .scale(0.5)
          .add(vw[0].add(vw[4]).scale(0.5))
          .scale(0.5);
        st2 = vw[5]
          .add(vw[1])
          .scale(0.5)
          .add(vw[2].add(vw[7]).scale(0.5))
          .scale(0.5);
        console.log("in if");
      } else {
        st1 = vw[0]
          .add(vw[2])
          .scale(0.5)
          .add(vw[5].add(vw[3]).scale(0.5))
          .scale(0.5);
        st2 = vw[7]
          .add(vw[4])
          .scale(0.5)
          .add(vw[1].add(vw[6]).scale(0.5))
          .scale(0.5);
        console.log("in else");
      }

      //doing it to get this in global cope to update on keypress event
      windowt1 = st1;
      windowt2 = st2;

      if (
        BABYLON.Vector3.Distance(st1, end) <= BABYLON.Vector3.Distance(st2, end)
      ) {
        st1.y = end.y;
        DisplayOperation.drawOnMove(st1, end);
        DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st1, end));
      } else {
        st2.y = end.y;
        DisplayOperation.drawOnMove(st2, end);
        DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st2, end));
      }

      if (Math.abs(face[1][0]) > 0) {
        // window.position.x += Math.sign(face[1][0]) * bbinfo.maximum.x / 2;
      } else if (Math.abs(face[1][2]) > 0) {
        // window.position.z += Math.sign(face[1][2]) * bbinfo.maximum.x / 2;
      }
    }
  }
};

var updateWindowPosition = function (distance) {
  console.log("hello");
  let dist = DisplayOperation.getOriginalDimension(distance);
  if (
    BABYLON.Vector3.Distance(windowt1, _globalWindow.position) <=
    BABYLON.Vector3.Distance(windowt2, _globalWindow.position)
  ) {
    let pd = windowt1.subtract(_globalWindow.position).normalize();
    let p = windowt1.subtract(pd.scale(dist));
    _globalWindow.position = p;
    _globalWindow.position.y = windowt1.y;
    DisplayOperation.drawOnMove(windowt1, _globalWindow.position);
    DisplayOperation.displayOnMove(
      BABYLON.Vector3.Distance(windowt1, _globalWindow.position)
    );
  } else {
    let pd = windowt2.subtract(_globalWindow.position).normalize();
    let p = windowt2.subtract(pd.scale(dist));
    _globalWindow.position = p;
    _globalWindow.position.y = windowt2.y;
    DisplayOperation.drawOnMove(windowt2, _globalWindow.position);
    DisplayOperation.displayOnMove(
      BABYLON.Vector3.Distance(windowt2, _globalWindow.position)
    );
  }
  onWindowDrawDown("");
};

function drawWindow(pickInfo, windowType, step_size) {
  if (windowType.toLowerCase() === "single") {
    return createSingleWindow(pickInfo.pickedMesh, step_size);
  } else if (windowType.toLowerCase() === "double") {
    return createDoubleWindow(pickInfo.pickedMesh, step_size);
  } else if (windowType.toLowerCase() === "triple") {
    return createTripleWindow(pickInfo.pickedMesh, step_size);
  } else if (windowType.toLowerCase() === "quadruple") {
    return createQuadrupleWindow(pickInfo.pickedMesh, step_size);
  } else {
    return createSingleWindow(pickInfo.pickedMesh, step_size);
  }
}

function drawWindowFplan(pickInfo, windowType, step_size) {
  if (windowType.toLowerCase() === "single") {
    return createSingleWindow(null, step_size);
  } else if (windowType.toLowerCase() === "double") {
    return createDoubleWindow(null, step_size);
  } else if (windowType.toLowerCase() === "triple") {
    return createTripleWindow(null, step_size);
  } else if (windowType.toLowerCase() === "quadruple") {
    return createQuadrupleWindow(null, step_size);
  } else {
    return createSingleWindow(null, step_size);
  }
}

function createSingleWindow(parentMesh = null, step_size) {
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "windows",
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = step_size[0] / 2;
  mergedMesh.scaling.z = step_size[0] / 2;
  mergedMesh.scaling.y = store.floor_height - step_size[1] * 2;
  mergedMesh.name = "WindowsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.25;
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  mergedMesh.showBoundingBox = true;
  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}

function createDoubleWindow(parentMesh = null, step_size) {
  var i = 0;
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "windows" + i, /* UNKNOWN: i */
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = step_size[0];
  mergedMesh.scaling.z = step_size[0];
  mergedMesh.scaling.y = store.floor_height - step_size[1] * 2;
  mergedMesh.name = "WindowsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.25;
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  mergedMesh.showBoundingBox = true;
  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}

function createTripleWindow(parentMesh = null, step_size) {
  var i = 0;
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "windows" + i,
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = (step_size[0] * 3) / 2;
  mergedMesh.scaling.z = (step_size[0] * 3) / 2;
  mergedMesh.scaling.y = store.floor_height - step_size[1] * 2;
  mergedMesh.name = "WindowsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.25;
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  mergedMesh.showBoundingBox = true;
  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}

function createQuadrupleWindow(parentMesh = null, step_size) {
  var i = 0;
  var mergedMesh = BABYLON.Mesh.CreateBox(
    "windows" + i,
    1.0,
    store.scene,
    false,
    BABYLON.Mesh.DEFAULTSIDE
  );
  mergedMesh.scaling.x = step_size[0] * 2;
  mergedMesh.scaling.z = step_size[0] * 2;
  mergedMesh.scaling.y = store.floor_height - step_size[1] * 2;
  mergedMesh.name = "WindowsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  // mergedMesh.scaling.x = 0.25;
  // mergedMesh.scaling.x = 0.254/bbinfo.boundingBox.extendSizeWorld.x;
  if (parentMesh)
    mergedMesh.scaling.x =
      parentMesh._properties._components._thickness /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  else
    mergedMesh.scaling.x =
      200 /
      store.unit_absolute_scale /
      10000 /
      ((bbinfo.boundingBox.extendSizeWorld.x * 3) / 2);
  mergedMesh.showBoundingBox = true;
  // mergedMesh.position.y = 0.1;
  // mergedMesh.enableEdgesRendering();
  // mergedMesh.edgesWidth = 2.0;
  // mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}
export {
  windowt1,
  windowt2,
  _globalWindow,
  onWindowDrawDown,
  onWindowDrawUp,
  onWindowDrawMove,
  updateWindowPosition,
  drawWindow,
  drawWindowFplan,
  createSingleWindow,
  createDoubleWindow,
  createTripleWindow,
  createQuadrupleWindow,
};
