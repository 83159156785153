"use strict"
/*jshint esversion: 6 */
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { ScopeUtils } from "./scopeFunctions.js";
import { uiIndicatorsHandler } from "../modules/uiIndicatorOperations/uiIndicatorsHandler.js";
import { isTwoDimension, goOutOfTwoD } from "./twoDimension.js";
import { onExplorerCam } from "./toolbarEvents.js";
import { measureFunction } from "./measureEvents.js";
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants.js";
import { lineParallelToWhichAxis } from "./snapUtilities.js";
import { colorUtil } from "../modules/utilityFunctions/colorUtility.js";
import { getAngleInRadians } from "./snapFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
import { findPrioritizedSnapPoint } from "./snapFuncsPrimary.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";

var explorerCam = (function () {
  let downClick = 0;
  let pickPosition = null;
  let pickMovePosition = null;
  let pickPtCam = null;
  let pickDirCam = null;
  let ptOne = null;
  let ptTwo = null;
  let highlightPoints = null;
  let options = null;
  let p13D = null;
  let p23D = null;
  let highlightPickInfo = null;
  let prevCam = {
    alpha: 0,
    beta: 0,
    radius: 0,
    position: null,
  };
  //pickpoint from layer refplane and add 6 feet height to camera position y
  // show mesh X on floor on move
  // esc to reset to 2d view camera

  let changeCursor = function () {
    document.body.style.cursor = "crosshair";
  };
  let changeCursorDefault = function () {
    document.body.style.cursor = "default";
  };
  let lastCamPosition = function () {
    let scope = ScopeUtils.getScope();
    //set last camera position if 2d / 3d
    scope.handleToolbar(
      "selectLayer",
      store.activeLayer.name,
      store.activeLayer.structure_id,
      store.activeLayer.storey
    );
  };
  let disposeLine = function () {
    let lineDashed = store.scene.getMeshByName("camDirLine");
    if (lineDashed) lineDashed.dispose();
  };

  let disposeHighlights = function () {
    uiIndicatorsHandler.vertexIndicator.remove();
    uiIndicatorsHandler.edgeIndicator.remove();
  };

  let onReset = function () {
    downClick = 0;
    changeCursorDefault();
    pickPosition = null;
    pickMovePosition = null;
    ptTwo = null;
    ptOne = null;
    p13D = null;
    p23D = null;
    disposeLine();
    disposeHighlights();
  };
  let takeToCamera = function (position, angle, targetPt) {
    let newPos = null;
    let newTarget = null;
    let fromTwoD = false;
    if (store.$scope.isTwoDimension) {
      let height = 7;
      goOutOfTwoD();
      onExplorerCam();
      fromTwoD = true;
      newPos = new BABYLON.Vector3(position.x, position.y + height, position.z);
      newTarget = new BABYLON.Vector3(
        targetPt.x,
        targetPt.y + height,
        targetPt.z
      );
    } else {
      newPos = new BABYLON.Vector3(position.x, position.y, position.z);
      newTarget = new BABYLON.Vector3(targetPt.x, targetPt.y, targetPt.z);
    }
    let radius = measureFunction.distanceBtwPts(newPos, targetPt);
    let targY = position.y > targetPt.y ? position.y : targetPt.y;
    let angle3d =
      position.y > targetPt.y
        ? Math.PI / 2 - Math.sin(targY / radius)
        : Math.PI / 2 + Math.sin(targY / radius);
    let alphaVal = newPos.z < targetPt.z ? -angle : angle;
    store.scene.activeCamera.setPosition(newPos);
    store.scene.activeCamera.spinTo("alpha", alphaVal, 80);
    if (!fromTwoD) {
      store.scene.activeCamera.spinTo("beta", angle3d, 80);
    } else {
      store.scene.activeCamera.spinTo("beta", Math.PI / 2, 80);
    }
    store.scene.activeCamera.spinTo("radius", radius, 70);
    store.scene.activeCamera.setTarget(newTarget);
  };

  let restoreCam = function (val) {
    if (val == "save") {
      prevCam.alpha = store.scene.activeCamera.alpha;
      prevCam.beta = store.scene.activeCamera.beta;
      prevCam.radius = store.scene.activeCamera.radius;
      prevCam.position = store.scene.activeCamera.position;
    } else if (val == "apply") {
      store.scene.activeCamera.alpha = prevCam.alpha;
      store.scene.activeCamera.beta = prevCam.beta;
      store.scene.activeCamera.radius = prevCam.radius;
      store.scene.activeCamera.position = prevCam.position;
    }
  };
  let animateCameraToPosition = function (cam, speed, frameCount, newPos) {
    if (store.$scope.isTwoDimension) {
      goOutOfTwoD();
    }
    let ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    let anim = BABYLON.Animation.CreateAndStartAnimation(
      "at4",
      cam,
      "position",
      speed,
      frameCount,
      cam.position,
      newPos,
      0,
      ease
    );
    anim.disposeOnEnd = true;
  };

  let animateCameraTargetToPosition = function (
    cam,
    speed,
    frameCount,
    newPos
  ) {
    let ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

    let targetAnim = BABYLON.Animation.CreateAndStartAnimation(
      "at5",
      cam,
      "target",
      speed,
      frameCount,
      cam.target,
      newPos,
      0,
      ease
    );
    targetAnim.disposeOnEnd = true;
  };

  let speed = 45;
  let frameCount = 200;

  let drawArrow = function (pt1, pt2, name) {
    let point1 = new BABYLON.Vector3(pt1.x, pt1.y, pt1.z);
    let point2 = new BABYLON.Vector3(pt2.x, pt2.y, pt2.z);
    //if (disposeLine) disposeDashedPinkLine1();
    let attrib = {
      points: [point1, point2],
      dashNb: 20,
      dashSize: 4,
      gapSize: 1,
    };
    let dashedLine = BABYLON.MeshBuilder.CreateDashedLines(
      name,
      attrib,
      store.newScene
    );
    dashedLine.visibility = 0.5;
    dashedLine.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;
    let axis = lineParallelToWhichAxis(pt1, pt2);
    dashedLine.color = axis
      ? colorUtil.getColor(colorUtil.getColorNameForType(axis))
      : colorUtil.getColor("black");
    dashedLine.renderingGroupId = 1;
  };

  let alphaForCam = function (ptOne, ptTwo) {
    let p1 = ptOne.clone();
    let p2 = ptTwo.clone();
    let vectorP1P2 = p1.subtract(p2);
    vectorP1P2.y = 0;
    let axisVector = new BABYLON.Vector3(1, 0, 0);
    return getAngleInRadians(axisVector, vectorP1P2);
  };

  return {
    onPointerDown: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        if (evt.button == 0) {
          document.addEventListener("keydown", function (event) {
            const key = event.key; // Or const {key} = event; in ES6+
            if (key === "Escape" || event.ctrlKey) {
              onReset();
            }
          });

          if (!store.$scope.isTwoDimension) {
            let predicate = function (mesh) {
              return mesh.type === 'ground';
            };

            highlightPickInfo = scenePickController.pickInvisibleMeshes(predicate);
            downClick = highlightPickInfo.hit ? downClick + 1 : downClick;
            if (highlightPickInfo.hit) {
              if (downClick == 1) {
                p13D = highlightPickInfo.pickedPoint.clone();
                // store.newScene.activeCamera.detachControl(canvas, true);
              }
              if (downClick == 2) {
                p23D = highlightPickInfo.pickedPoint.clone();
                disposeLine();
                drawArrow(p13D, p23D, "camDirLine");
                // let angle = alphaForCam(p13D, p23D);
                p13D.y += DisplayOperation.getOriginalDimension(1.1, "meters");
                // if(p13D!=null && p23D!=null)takeToCamera(p13D, angle, p23D);
                animateCameraTargetToPosition(
                  store.scene.activeCamera,
                  speed,
                  frameCount,
                  p23D
                ); //target position
                animateCameraToPosition(
                  store.scene.activeCamera,
                  speed,
                  frameCount,
                  p13D
                );
                downClick = 0;
                prevCam = store.scene.activeCamera;
                disposeLine();
                disposeHighlights();
                // store.newScene.activeCamera.attachControl(canvas, true);
              }
              if (downClick > 2) {
                onReset();
              }
            } else {
              if (Number.isNaN(store.scene.activeCamera.radius))
                restoreCam("apply");
            }
          } else {
            if (downClick <= 2) {
              if (evt.pickInfo) pickPosition = evt.pickInfo;
              else {
                let predicate = function (mesh) {
                  return mesh.name.indexOf("layerRefPlane") !== -1;
                };
                pickPosition = store.newScene.pick(
                  evt.clientX,
                  evt.clientY,
                  predicate
                );
              }
            }

            downClick = pickPosition.hit ? downClick + 1 : downClick;
            if (downClick == 1) {
              ptOne = pickPosition.pickedPoint;
            }
            if (downClick == 2) {
              ptTwo = pickPosition.pickedPoint;
              // let angle = alphaForCam(ptOne, ptTwo);
              ptOne.y += DisplayOperation.getOriginalDimension(1.1, "meters");
              // takeToCamera(ptOne.clone(), angle, ptTwo.clone());
              animateCameraTargetToPosition(
                store.scene.activeCamera,
                speed,
                frameCount,
                ptTwo
              ); //target position
              animateCameraToPosition(
                store.scene.activeCamera,
                speed,
                frameCount,
                ptOne
              );
              downClick = 0;
              disposeLine();
              if (!Number.isNaN(store.scene.activeCamera.radius))
                restoreCam("save");
            }
            if (downClick > 2) {
              onReset();
            }
          }
        }
      }
    },
    onPointerMove: function (evt) {
      if (
        evt.pointerType === "pen" ||
        evt.pointerType === "mouse" ||
        evt.pointerType === ""
      ) {
        disposeLine();
        let panMode = false;
        if ((evt.ctrlKey && evt.which == 2) || evt.which == 2) {
          // store.newScene.activeCamera.attachControl(canvas, true);
          panMode = true;
          onReset();
        }

        if (!store.$scope.isTwoDimension) {
          if (!panMode) {
            disposeHighlights();
            options = {
              wantMetadata: true,
              faceSnap: false,
              indicatorType:
                uiIndicatorsHandler.vertexIndicator.TYPES.postSnapIndicator,
              doNotDoSecondaryScenePicks: !store.$scope.isTwoDimension,
            };
            if (evt.pickInfo) pickMovePosition = evt.pickInfo;
            else {
              let predicate = function (mesh) {
                return mesh;
              };
              pickMovePosition = store.newScene.pick(
                evt.clientX,
                evt.clientY,
                predicate
              );
            }
            highlightPoints = findPrioritizedSnapPoint(
              null,
              null,
              null,
              options
            );
            if (!highlightPoints) {
              highlightPoints = pickMovePosition.pickedPoint;
            }

            if (downClick == 1 && highlightPoints) {
              drawArrow(p13D, highlightPoints, "camDirLine");
            }
          }
        } else {
          if (downClick <= 1) {
            if (evt.pickInfo) pickMovePosition = evt.pickInfo;
            else {
              let predicate = function (mesh) {
                return mesh.name.indexOf("layerRefPlane") !== -1;
              };
              pickMovePosition = store.newScene.pick(
                evt.clientX,
                evt.clientY,
                predicate
              );
            }
          }
          if (downClick == 0) changeCursor();
          if (downClick == 1) {
            //draw line
            if (ptOne) {
              drawArrow(ptOne, pickMovePosition.pickedPoint, "camDirLine");
            }
          }
        }
      }
    },
    onPointerUp: function (evt) {},
    resetCam: function () {
      return onReset();
    },
    animateCameraTargetToPosition,
    animateCameraToPosition,
  };
})();
export { explorerCam };
