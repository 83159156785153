import addVertexCursor from "../assets/cursors/addVertex.png";
import angleMeasureCursor from "../assets/cursors/angleMeasure.png";
import cantPlaceCursor from "../assets/cursors/cantPlace.png";
import commentCursor from "../assets/cursors/comment.png";
import copyCursor from "../assets/cursors/copy.png";
import deleteCursor from "../assets/cursors/delete.png";
import detectCursor from "../assets/cursors/detect.png";
import drawCursor from "../assets/cursors/draw.png";
import drawCircleCursor from "../assets/cursors/drawCircle.png";
import drawRectangleCursor from "../assets/cursors/drawRectangle.png";
import drawVoidCursor from "../assets/cursors/drawVoid.png";
import editLengthCursor from "../assets/cursors/editLength.png";
import eraseCursor from "../assets/cursors/erase.png";
import eyeDropperCursor from "../assets/cursors/eyeDropper.png";
import groupselectionCursor from "../assets/cursors/groupselection.png";
import groupselection_shadowedCursor from "../assets/cursors/groupselection_shadowed.png";
import materialsCursor from "../assets/cursors/materials.png";
import moveCursor from "../assets/cursors/move.png";
import orbitCursor from "../assets/cursors/orbit.png";
import panCursor from "../assets/cursors/pan.png";
import placeObjectCursor from "../assets/cursors/placeObject.png";
import placeObject_shadowedCursor from "../assets/cursors/placeObject_shadowed.png";
import positioncameraCursor from "../assets/cursors/positioncamera.png";
import pushpullEditCursor from "../assets/cursors/pushpullEdit.png";
import pushpullCursor from "../assets/cursors/pushpull.png";
import removeVertexCursor from "../assets/cursors/removeVertex.png";
import revisionccloudCursor from "../assets/cursors/revisionccloud.png";
import rotateCursor from "../assets/cursors/rotate.png";
import sectionalViewCursor from "../assets/cursors/sectionalView.png";
import selectCursor from "../assets/cursors/select.png";
import selectShadowedCursor from "../assets/cursors/selectShadowed.png";
import setalignmentCursor from "../assets/cursors/setalignment.png";
import setDatumCursor from "../assets/cursors/setDatum.png";
import setScaleCursor from "../assets/cursors/setScale.png";
import setScale_shadowedCursor from "../assets/cursors/setScale_shadowed.png";
import splitFaceCursor from "../assets/cursors/splitFace.png";
import tagCursor from "../assets/cursors/tag.png";
import tapeCursor from "../assets/cursors/tape.png";
import UICursor from "../assets/cursors/UICursor.png";
import zoomInCursor from "../assets/cursors/zoomIn.png";
import zoomOutCursor from "../assets/cursors/zoomOut.png";

export const cursor = {
    default: `url(${selectCursor}) 6 5, auto`,
    UICursor: `url(${UICursor}), auto`,
    
    // SELECT TAB 
    select: `url(${selectCursor}) 6 5, auto`,
    selectShadowed: `url(${selectShadowedCursor}) 6 5, auto`, 
    groupSelection: `url(${groupselectionCursor}) 13 21, auto`, 
    groupselection_shadowed: `url(${groupselection_shadowedCursor}) 13 21, auto`,
    
    // DRAW TAB
    draw: `url(${drawCursor}) 7 21, auto`,
    drawCircle: `url(${drawCircleCursor}) 7 21, auto`,
    drawRectangle: `url(${drawRectangleCursor}) 7 21, auto`,
    move: `url(${moveCursor}) 15 14, auto`,
    pushpullEdit: `url(${pushpullEditCursor}) 15 14, auto`,
    pushpull: `url(${pushpullCursor}) 15 5, auto`,
    copy: `url(${copyCursor}) 15 14, auto`,
    rotate: `url(${rotateCursor}) 18 14, auto`,
    drawVoid: `url(${drawVoidCursor}) 7 21, auto`,
    addVertex: `url(${addVertexCursor}) 7 21, auto`,
    removeVertex: `url(${removeVertexCursor}) 7 21, auto`,
    splitFace: `url(${splitFaceCursor}) 6 22, auto`,
    editLength: `url(${editLengthCursor}) 10 14, auto`,
    
    // MEASURE TAB
    setScale: `url(${setScaleCursor}) 16 15, auto`,
    setScale_shadowed: `url(${setScale_shadowedCursor}) 16 15, auto`,
    tape: `url(${tapeCursor}) 11 22, auto`,
    angleMeasure: `url(${angleMeasureCursor}) 6 21, auto`,
    setDatum: `url(${setDatumCursor}) 14 14, auto`,
    setalignment: `url(${setalignmentCursor}) 18 18, auto`,

    // VIEWS
    positioncamera: `url(${positioncameraCursor}) 15 15, auto`,
    sectionalView: `url(${sectionalViewCursor}) 16 16, auto`,

    // COLLABORATE
    comment: `url(${commentCursor}) 12 21, auto`,
    revisionccloud: `url(${revisionccloudCursor}) 15 13, auto`,
    
    // RIGHT MENU
    materials: `url(${materialsCursor}) 6 14, auto`,
    eyeDropper: `url(${eyeDropperCursor}) 6 21, auto`,
    erase: `url(${eraseCursor}) 8 20, auto`,
    cantPlace: `url(${cantPlaceCursor}) 15 15, auto`,
    detect: `url(${detectCursor}) 6 5, auto`,
    placeObject: `url(${placeObjectCursor}) 16 15, auto`,
    placeObject_shadowed: `url(${placeObject_shadowedCursor}) 16 15, auto`,

    // BOTTOM LEFT MENU
    pan: `url(${panCursor}) 15 16, auto`,
    orbit: `url(${orbitCursor}) 14 13, auto`,
    zoomIn: `url(${zoomInCursor}) 13 12, auto`,
    zoomOut: `url(${zoomOutCursor}) 13 12, auto`,
    delete: `url(${deleteCursor}) 13 15, auto`,
    
    
    tag: `url(${tagCursor}) 6 5, auto`,
    
}