"use strict";
import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js"
import { line2D,circle2D } from "../modules/meshoperations/sketchOperation.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
/*jshint esversion: 6 */

var drawWindow = (function () {
  "use strict";

  let thickness1 = 0.04;
  let thickness2 = 0.03;
  let thickness3 = 0.015;
  let thickness4 = 0.01;
  let frameLength = null;
  let frameWidth = null;
  let sliderLength = null;
  let sliderWidth = null;
  let drawCurve = function (name, X1, X2, Z1, Z2, type) {
    if (type == "positive") {
      let quadraticBezierVectors = BABYLON.Curve3.CreateQuadraticBezier(
        new BABYLON.Vector3(X1, 0, Z1),
        new BABYLON.Vector3(X1, 0, Z2),
        new BABYLON.Vector3(X2, 0, Z2),
        8
      );
      let quadraticBezierCurve = drawCurveFromPoints(
        quadraticBezierVectors.getPoints(),
        thickness4
      );
      return quadraticBezierCurve;
    }
    if (type == "negetive") {
      let quadraticBezierVectors = BABYLON.Curve3.CreateQuadraticBezier(
        new BABYLON.Vector3(X1, 0, Z1),
        new BABYLON.Vector3(X2, 0, Z1),
        new BABYLON.Vector3(X2, 0, Z2),
        8
      );
      let quadraticBezierCurve = drawCurveFromPoints(
        quadraticBezierVectors.getPoints(),
        thickness4
      );
      return quadraticBezierCurve;
    }
  };
  let drawCurveFromPoints = function (points, thickness) {
    let finalLine = [];
    let path = [];
    for (let i = 0; i < points.length - 1; i++) {
      path.push([points[i], points[i + 1]]);
    }
    for (let i = 0; i < path.length; i++) {
      let line = line2D("curve", { path: path[i], width: thickness }, store.scene);
      finalLine.push(line);
    }
    let lineSystem = BABYLON.Mesh.MergeMeshes(finalLine, true);
    lineSystem.color = new BABYLON.Color3(0, 0, 0);
    lineSystem.isPickable = false;
    return lineSystem;
  };
  let drawThickLine = function (radius, start, thickness, end, name) {
    if (!start) start = new BABYLON.Vector3.Zero();
    if (!thickness) thickness = 0.02;
    if (!name) name = "dline";
    if (!end) end = new BABYLON.Vector3(start.x + radius, start.y, start.z);
    let path = [start, end];
    // var linesystem = BABYLON.MeshBuilder.CreateLineSystem("test", {lines: [path], updatable: true}, store.scene);
    //  linesystem.color = new BABYLON.Color3(0, 1, 0);
    let line = line2D(name, { path: path, width: thickness }, store.scene);
    return line;
  };
  let drawDoorFrame = function (radius, length, width, start, thickness, name) {
    let path = [];
    let lineMesh = [];
    if (!start) start = new BABYLON.Vector3.Zero();
    if (!length)
      length = DisplayOperation.getOriginalDimension("50", "millimeter"); // x direction
    if (!width)
      width = DisplayOperation.getOriginalDimension("100", "millimeter"); //z direction
    if (!thickness) thickness = 0.02;
    let end = new BABYLON.Vector3(start.x + radius, start.y, start.z);
    path.push([start, new BABYLON.Vector3(length, 0, 0)]);
    path.push([
      new BABYLON.Vector3(length, 0, 0),
      new BABYLON.Vector3(length, 0, -width),
    ]);
    path.push([
      new BABYLON.Vector3(length, 0, -width),
      new BABYLON.Vector3(0, 0, -width),
    ]);
    path.push([new BABYLON.Vector3(0, 0, -width), start]);
    for (let i = 0; i < path.length; i++) {
      let line = line2D("dline", { path: path[i], width: thickness }, store.scene);
      lineMesh.push(line);
    }
    let lineSystem = BABYLON.Mesh.MergeMeshes(lineMesh, true);
    //let lineSystem = data.mergedMeshArr;
    if (!name) name = "dFrame";
    lineSystem.name = name;
    lineSystem.color = new BABYLON.Color3(0, 0, 0);
    lineSystem.isPickable = false;
    return lineSystem;
  };
  var drawFrame = function (name, length, width, startPoint) {
    if (!startPoint) startPoint = BABYLON.Vector3.Zero();
    if (!length)
      length = DisplayOperation.getOriginalDimension("50", "millimeter"); // x direction
    if (!width)
      width = DisplayOperation.getOriginalDimension("100", "millimeter"); // z direction
    var myLines = [
      startPoint, //start
      new BABYLON.Vector3(length, 0, 0),
      new BABYLON.Vector3(length, 0, -width),
      new BABYLON.Vector3(0, 0, -width),
      startPoint, //start
    ];

    var linesystem = BABYLON.MeshBuilder.CreateLineSystem(
      name,
      { lines: [myLines], updatable: true },
      store.scene
    );
    linesystem.color = new BABYLON.Color3(0, 0, 0);
    linesystem.position.z = Math.abs(width / 2);

    return linesystem;
  };
  let drawTopHung = function (radius, frameLength) {
    let lines = [];
    let windowLength = radius / 2 - frameLength;
    let drawOuter = drawThickLine(radius - 2 * frameLength, null, thickness3);
    drawOuter.position.x = frameLength;
    drawOuter.position.z = windowLength;
    let point1 = new BABYLON.Vector3(frameLength, 0, windowLength);
    let point2 = new BABYLON.Vector3(radius - frameLength, 0, windowLength);
    // point2.x = point1.x + frameLength/2;
    let point3 = new BABYLON.Vector3(radius / 2, 0, 0);
    let point4 = new BABYLON.Vector3(frameLength, 0, 0);
    let point5 = new BABYLON.Vector3(radius - frameLength, 0, 0);
    let line1 = drawThickLine(windowLength, point1, thickness3, point3);
    let line2 = drawThickLine(windowLength, point2, thickness3, point3);
    let line3 = drawThickLine(windowLength, point1, thickness3, point4);
    let line4 = drawThickLine(windowLength, point2, thickness3, point5);
    lines.push(drawOuter);
    lines.push(line1);
    lines.push(line2);
    lines.push(line3);
    lines.push(line4);
    let lineSystem = BABYLON.Mesh.MergeMeshes(lines, true);
    return lineSystem;
    //let drawLine = drawThickLine(windowLength, null, thickness3);
  };
  let drawGlass = function (radius, frameLength) {
    let merge = [];
    let glass = drawThickLine(radius, null, thickness2);
    glass.position.x = frameLength;
    glass.position.z = DisplayOperation.getOriginalDimension("6", "millimeter");
    let glass2 = drawThickLine(radius, null, thickness2);
    glass2.position.x = frameLength;
    glass2.position.z = -DisplayOperation.getOriginalDimension(
      "6",
      "millimeter"
    );
    glass.setParent(glass2);
    merge.push(glass);
    merge.push(glass2);
    let mergedMesh = BABYLON.Mesh.MergeMeshes(merge, true, true, undefined, false, false);
    return mergedMesh;
  };
  let fixedWindow = function (radius) {
    // console.log(type);
    let mergeArr = [];
    //Frame
    let frame1 = drawDoorFrame(
      radius,
      null,
      null,
      null,
      thickness1,
      "fixedWindow"
    );
    frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.x = thickness1 / 2;
    frame1.position.z = frameWidth - thickness1;
    let frame2 = drawDoorFrame(radius, null, null, null, thickness1);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;

    //WindowSill
    let windowSill = drawThickLine(radius, null, thickness4);
    let windowSill2 = drawThickLine(radius, null, thickness4);
    windowSill.position.z =
      frameWidth / 2 +
      DisplayOperation.getOriginalDimension("100", "millimeter") -
      thickness1;
    windowSill2.position.z =
      frameWidth / 2 -
      DisplayOperation.getOriginalDimension("100", "millimeter");

    //Glass
    let glassLength = radius - 2 * frameLength;
    let glass = drawGlass(glassLength, frameLength);
    glass.position.z = frameWidth / 2 - thickness1 / 2;
    glass.position.x = 0;

    //Shutter bottom
    let shutterBottom = drawThickLine(glassLength, null, thickness3);
    shutterBottom.position.x = frameLength;
    shutterBottom.position.z = frameWidth - thickness1;
    let shutterBottom2 = drawThickLine(glassLength, null, thickness3);
    shutterBottom2.position.z = 0;
    shutterBottom2.position.x = frameLength;

    mergeArr.push(frame1);
    mergeArr.push(frame2);
    mergeArr.push(windowSill);
    mergeArr.push(windowSill2);
    mergeArr.push(glass);
    mergeArr.push(shutterBottom);
    mergeArr.push(shutterBottom2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let louvredWindow = function (radius) {
    let mergeArr = [];
    //Frame
    let frame1 = drawDoorFrame(
      radius,
      null,
      null,
      null,
      thickness1,
      "loweredWindow"
    );
    frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.x = thickness1 / 2;
    frame1.position.z = frameWidth - thickness1;
    let frame2 = drawDoorFrame(radius, null, null, null, thickness1);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;

    //WindowSill
    let windowSill = drawThickLine(radius, null, thickness4);
    let windowSill2 = drawThickLine(radius, null, thickness4);
    windowSill.position.z =
      frameWidth / 2 +
      DisplayOperation.getOriginalDimension("100", "millimeter") -
      thickness1;
    windowSill2.position.z =
      frameWidth / 2 -
      DisplayOperation.getOriginalDimension("100", "millimeter");

    //Glass
    let glassLength = radius - 2 * frameLength;
    let glass = drawGlass(glassLength, frameLength);
    glass.position.z = thickness1 / 2;
    glass.position.x = 0;

    //Shutter bottom
    let shutterBottom = drawThickLine(glassLength, null, thickness3);
    shutterBottom.position.x = frameLength;
    shutterBottom.position.z = frameWidth - thickness1;
    let shutterBottom2 = drawThickLine(glassLength, null, thickness3);
    shutterBottom2.position.z = 0;
    shutterBottom2.position.x = frameLength;

    mergeArr.push(frame1);
    mergeArr.push(frame2);
    mergeArr.push(windowSill);
    mergeArr.push(windowSill2);
    mergeArr.push(glass);
    mergeArr.push(shutterBottom);
    mergeArr.push(shutterBottom2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let topHungWindow = function (radius) {
    let mergeArr = [];
    //Frame
    let frame1 = drawDoorFrame(
      radius,
      null,
      null,
      null,
      thickness1,
      "topHungWindow"
    );
    frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.x = thickness1 / 2;
    frame1.position.z = frameWidth - thickness1;
    let frame2 = drawDoorFrame(radius, null, null, null, thickness1);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;

    //innerFrame
    let innerFrame = drawDoorFrame(
      radius - frameLength,
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      null,
      thickness1
    );
    let innerFrameLength =
      innerFrame._boundingInfo.boundingBox.extendSize.x * 2;
    let innerFrameWidth = innerFrame._boundingInfo.boundingBox.extendSize.z * 2;
    innerFrame.position.x = frameLength;
    innerFrame.position.z = frameWidth / 2 + innerFrameWidth / 2 - thickness1;
    let innerFrame2 = drawDoorFrame(
      radius - frameLength,
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      null,
      thickness1
    );
    innerFrame2.position.x =
      radius - frameLength - innerFrameLength + thickness1;
    innerFrame2.position.z = frameWidth / 2 + innerFrameWidth / 2 - thickness1;

    //WindowSill
    let windowSill = drawThickLine(radius, null, thickness4);
    let windowSill2 = drawThickLine(radius, null, thickness4);
    windowSill.position.z =
      frameWidth / 2 +
      DisplayOperation.getOriginalDimension("100", "millimeter") -
      thickness1;
    windowSill2.position.z =
      frameWidth / 2 -
      DisplayOperation.getOriginalDimension("100", "millimeter");

    //glass
    let glassLength =
      radius - 2 * frameLength - 2 * innerFrameLength + thickness1;
    let glass = drawGlass(glassLength, frameLength);
    glass.position.x = innerFrameLength - thickness1 / 2;
    glass.position.z = frameLength / 2 + innerFrameWidth / 2 - thickness1;

    //shutter
    let shutterLength = radius - 2 * frameLength;
    let shutterBottom = drawThickLine(shutterLength, null, thickness3);
    shutterBottom.position.x = frameLength;
    shutterBottom.position.z =
      frameWidth / 2 + innerFrameWidth / 2 - thickness1 / 2;
    let shutterBottom2 = drawThickLine(shutterLength, null, thickness3);
    shutterBottom2.position.x = frameLength;
    shutterBottom2.position.z = frameWidth / 2 - innerFrameWidth / 2;
    let windowArea = drawTopHung(radius, frameLength);
    windowArea.position.z = frameWidth / 2;

    mergeArr.push(frame1);
    mergeArr.push(frame2);
    mergeArr.push(innerFrame);
    mergeArr.push(innerFrame2);
    mergeArr.push(windowSill);
    mergeArr.push(windowSill2);
    mergeArr.push(glass);
    mergeArr.push(shutterBottom);
    mergeArr.push(shutterBottom2);
    mergeArr.push(windowArea);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let windowFrame = function (radius) {
    let mergeframe = [];
    let frame1 = drawDoorFrame(radius, null, null, null, thickness1, "frame");
    frameWidth = frame1._boundingInfo.boundingBox.extendSize.z * 2;
    frameLength = frame1._boundingInfo.boundingBox.extendSize.x * 2;
    frame1.position.x = thickness1 / 2;
    frame1.position.z = frameWidth - thickness1;
    let frame2 = drawDoorFrame(radius, null, null, null, thickness1);
    frame2.position.x = radius - frameLength + thickness1 / 2;
    frame2.position.z = frameWidth - thickness1;
    let windowSill = drawThickLine(radius, null, thickness4);
    let windowSill2 = drawThickLine(radius, null, thickness4);
    windowSill.position.z =
      frameWidth / 2 +
      DisplayOperation.getOriginalDimension("100", "millimeter") -
      thickness1;
    windowSill2.position.z =
      frameWidth / 2 -
      DisplayOperation.getOriginalDimension("100", "millimeter");
    mergeframe.push(frame1);
    mergeframe.push(frame2);
    mergeframe.push(windowSill);
    mergeframe.push(windowSill2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeframe, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let windowGuide = function (radius) {
    let arr = [];
    let frames = windowFrame(radius);
    let remRadius = radius - frameLength * 2;

    let doorRail = drawThickLine(remRadius + thickness1, null, thickness4);
    doorRail.position.z = frameWidth / 2 - frameWidth / 4 + thickness1;
    doorRail.position.x = frameLength - thickness1 / 2;

    let doorRail2 = drawThickLine(remRadius + thickness1, null, thickness4);
    doorRail2.position.z = frameWidth / 2 + frameWidth / 4 - thickness1;
    doorRail2.position.x = frameLength - thickness1 / 2;

    arr.push(frames);
    arr.push(doorRail);
    arr.push(doorRail2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(arr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let windowSlider = function (radius) {
    let arr = [];
    let frames = windowFrame(radius);
    let remRadius = radius - frameLength * 2;
    let doorRail = drawThickLine(remRadius + thickness1, null, thickness3);
    doorRail.position.z = frameWidth - thickness1;
    doorRail.position.x = frameLength - thickness1 / 2;
    let doorRail2 = drawThickLine(remRadius + thickness1, null, thickness3);
    doorRail2.position.z = 0;
    doorRail2.position.x = frameLength - thickness1 / 2;
    arr.push(frames);
    arr.push(doorRail);
    arr.push(doorRail2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(arr, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let drawSlidingWin = function (radius, shutters) {
    let oneDoor = [];
    let remRadius = radius - frameLength * 2;
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let drawSlider1 = drawDoorFrame(
      "sdFrame1",
      frameLength * 2,
      frameWidth / shutters - thickness1,
      null,
      thickness1
    );
    sliderLength = drawSlider1._boundingInfo.boundingBox.extendSize.x * 2;
    sliderWidth = drawSlider1._boundingInfo.boundingBox.extendSize.z * 2;
    let drawRadius =
      (remRadius + (shutters - 1) * sliderLength) / shutters - thickness1 / 2;
    let drawSlider2 = drawDoorFrame(
      "sdFrame1",
      frameLength * 2,
      frameWidth / shutters - thickness1,
      null,
      thickness1
    );
    drawSlider2.position.x = drawRadius - sliderLength + thickness1;
    let shutterSill = drawThickLine(drawRadius, null, thickness4);
    shutterSill.position.z = thickness1 / 2;
    let shutterSill2 = drawThickLine(drawRadius, null, thickness4);
    shutterSill2.position.z = -sliderWidth + thickness1 / 2;
    let glass = drawGlass(
      drawRadius - sliderLength * 2 + thickness1 * 2,
      sliderLength - thickness1
    );
    glass.position.z = -sliderWidth / 2 + thickness1 / 2;
    oneDoor.push(drawSlider1);
    oneDoor.push(drawSlider2);
    oneDoor.push(shutterSill);
    oneDoor.push(shutterSill2);
    oneDoor.push(glass);
    let mergedMesh = BABYLON.Mesh.MergeMeshes(oneDoor, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let windowShutter = function (radius) {
    //radius = remainder radius ==> radius/shutters
    let shutterMerge = [];

    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");

    let innerFrame = drawDoorFrame(
      radius,
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      null,
      thickness1
    );
    let innerFrameLength =
      innerFrame._boundingInfo.boundingBox.extendSize.x * 2;
    let innerFrameWidth = innerFrame._boundingInfo.boundingBox.extendSize.z * 2;
    //  innerFrame.position.x = frameLength;
    //  innerFrame.position.z = frameWidth/2 + innerFrameWidth/2 -thickness1;
    let innerFrame2 = drawDoorFrame(
      radius,
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      DisplayOperation.getOriginalDimension("50", "millimeter"),
      null,
      thickness1
    );
    innerFrame2.position.x = radius - innerFrameLength;
    //  innerFrame2.position.z = frameWidth/2 + innerFrameWidth/2 -thickness1;

    let shutterLength = radius - thickness1;
    let shutterBottom = drawThickLine(shutterLength, null, thickness3);
    //shutterBottom.position.x =frameLength;
    shutterBottom.position.z = thickness1 / 2;
    let shutterBottom2 = drawThickLine(shutterLength, null, thickness3);
    // shutterBottom2.position.x =frameLength;
    shutterBottom2.position.z = -innerFrameWidth + thickness1 / 2;
    let glassLength = radius - innerFrameLength * 2 + thickness1;
    let glass = drawGlass(glassLength, innerFrameLength);
    glass.position.x = -thickness1 / 2;
    glass.position.z = -innerFrameWidth / 2 + thickness1 / 2;

    shutterMerge.push(innerFrame);
    shutterMerge.push(innerFrame2);
    shutterMerge.push(shutterBottom);
    shutterMerge.push(shutterBottom2);
    shutterMerge.push(glass);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(shutterMerge, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let pivotWindow = function (radius, numb) {
    let oneDoor = [];

    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let remRadius = radius - frameLength * 2;
    let shutter = windowShutter(remRadius / numb);
    let shutterWidth = shutter.getBoundingInfo().boundingBox.extendSize.z * 2;
    let shutterLength = shutter.getBoundingInfo().boundingBox.extendSize.x * 2;
    shutter.position.x = shutterWidth / 2 - thickness1 / 2;
    shutter.position.z = shutterLength / 2 - thickness1 / 2;
    shutter.rotation.y = Math.PI / 2;

    let circle1 = circle2D(shutterWidth / 2, 12, store.scene);
    let curve1 = drawCurve(
      "curve1",
      -shutterLength / 2 + thickness1 / 4,
      0,
      0,
      shutterLength / 2,
      "positive"
    );
    let curve2 = drawCurve(
      "curve2",
      0,
      shutterLength / 2 - thickness1 / 4,
      -shutterLength / 2,
      0,
      "negetive"
    );
    oneDoor.push(shutter);
    oneDoor.push(circle1);
    oneDoor.push(curve1);
    oneDoor.push(curve2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(oneDoor, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let casementSingle = function (radius) {
    let merge = [];
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");

    let frame = windowFrame(radius);
    let shutterRadius = radius - frameLength * 2 + thickness1;
    let shutter = windowShutter(shutterRadius);
    let shutterWidth = shutter.getBoundingInfo().boundingBox.extendSize.z * 2;
    let shutterLength = shutter.getBoundingInfo().boundingBox.extendSize.x * 2;
    shutter.position.x = frameLength;
    shutter.position.z = frameWidth / 2 - thickness1;
    shutter.rotation.y = -Math.PI / 2;
    let curve1 = drawCurve(
      "curve1",
      frameLength,
      radius - frameLength,
      shutterLength + frameWidth / 2 - thickness1 * 2,
      frameWidth / 2,
      "negetive"
    );

    merge.push(frame);
    merge.push(shutter);
    merge.push(curve1);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(merge, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let casementDouble = function (radius) {
    let merge = [];
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");

    let frame = windowFrame(radius);
    let shutterRadius = radius - frameLength * 2 + thickness1;
    let shutter = windowShutter(shutterRadius / 2);

    let shutterWidth = shutter.getBoundingInfo().boundingBox.extendSize.z * 2;
    let shutterLength = shutter.getBoundingInfo().boundingBox.extendSize.x * 2;
    shutter.position.x = frameLength;
    shutter.position.z = frameWidth / 2 - thickness1;
    shutter.rotation.y = -Math.PI / 2;
    let shutter2 = windowShutter(shutterRadius / 2);
    shutter2.position.x =
      radius - frameLength - shutterWidth + thickness1 * 1.5;
    shutter2.rotation.y = -Math.PI / 2;
    shutter2.position.z = frameWidth / 2 - thickness1;
    let curve1 = drawCurve(
      "curve1",
      frameLength,
      radius / 2,
      shutterLength + frameWidth / 2 - thickness1 * 2,
      frameWidth / 2,
      "negetive"
    );
    let curve2 = drawCurve(
      "curve2",
      radius / 2,
      radius - frameLength,
      frameWidth / 2,
      shutterLength + frameWidth / 2 - thickness1 * 2,
      "positive"
    );

    merge.push(frame);
    merge.push(shutter);
    merge.push(curve1);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(merge, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let foldingEven = function (radius, folds) {
    let merge = [];
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let remRadius = radius - frameLength * 2;
    let shutter = windowShutter(remRadius / folds);
    let shutterWidth = shutter.getBoundingInfo().boundingBox.extendSize.z * 2;
    let shutterLength = shutter.getBoundingInfo().boundingBox.extendSize.x * 2;
    shutter.rotation.y = -Math.PI / 3;
    let shutter2 = windowShutter(remRadius / folds);
    shutter2.position.x = shutterLength + shutterWidth / 2 + thickness1;
    shutter2.position.z = -frameLength / 2;
    shutter2.rotation.y = (-2 / 3) * Math.PI;
    let circle = circle2D(shutterWidth / 2, 12, store.scene);
    circle.position = shutter.getBoundingInfo().boundingBox.maximumWorld;
    circle.position.z = thickness1;

    merge.push(shutter);
    merge.push(shutter2);
    merge.push(circle);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(merge, true, true, undefined, false, false);
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let foldingOdd = function (radius, folds) {
    let merge = [];
    frameWidth = frameWidth
      ? frameWidth
      : DisplayOperation.getOriginalDimension("100", "millimeter");
    frameLength = frameLength
      ? frameLength
      : DisplayOperation.getOriginalDimension("50", "millimeter");
    let remRadius = radius - frameLength * 2;
    let shutter = windowShutter(remRadius / folds);
    let shutterWidth = shutter.getBoundingInfo().boundingBox.extendSize.z * 2;
    let shutterLength = shutter.getBoundingInfo().boundingBox.extendSize.x * 2;
    shutter.rotation.y = -Math.PI / 3;
    let shutter2 = windowShutter(remRadius / folds);
    shutter2.position.x = shutterLength + shutterWidth / 2 + thickness1;
    shutter2.position.z = -frameLength / 2;
    shutter2.rotation.y = (-2 / 3) * Math.PI;
    let shutter3 = windowShutter(remRadius / folds);
    shutter3.position.x = shutterLength + shutterWidth * 2;
    shutter3.rotation.y = -Math.PI / 3;

    let circle = circle2D(shutterWidth / 2, 12, store.scene);
    circle.position = shutter.getBoundingInfo().boundingBox.maximumWorld;
    circle.position.z = thickness1;

    let circle2 = circle2D(shutterWidth / 2, 12, store.scene);
    circle2.position.x = shutterLength + shutterWidth * 2 - thickness1 * 1.5;
    circle2.position.z = -thickness1;

    merge.push(shutter);
    merge.push(shutter2);
    merge.push(shutter3);
    merge.push(circle);
    merge.push(circle2);

    let mergedMesh = BABYLON.Mesh.MergeMeshes(merge, true, true, undefined, false, false);

    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let folding = function (radius, type) {
    let shutters = type.substr(type.length - 1);
    let mergeArr = [];
    let doorGen = [];
    let frames = windowGuide(radius);
    if (shutters == 2) {
      let fdoor = foldingEven(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      doorGen.push(fdoor);
    }
    if (shutters == 4) {
      let fdoor = foldingEven(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      let fdoor2 = foldingEven(radius, shutters);
      fdoor2.position.z = frameWidth / 2;
      fdoor2.rotation.z = Math.PI;
      fdoor2.position.x = radius - frameLength + thickness1;
      doorGen.push(fdoor);
      doorGen.push(fdoor2);
    }
    if (shutters == 3) {
      let fdoor = foldingOdd(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      doorGen.push(fdoor);
    }
    if (shutters == 6) {
      let fdoor = foldingOdd(radius, shutters);
      fdoor.position.x = frameLength - thickness1;
      fdoor.position.z = frameWidth / 2;
      let fdoor2 = foldingOdd(radius, shutters);
      fdoor2.position.z = frameWidth / 2;
      fdoor2.rotation.z = Math.PI;
      fdoor2.position.x = radius - frameLength + thickness1;
      doorGen.push(fdoor);
      doorGen.push(fdoor2);
    }
    mergeArr.push(frames);
    doorGen.forEach((ele) => mergeArr.push(ele));

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);

    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let pivot = function (radius, type) {
    let shutters = type.substr(type.length - 1);
    let mergeArr = [];
    let frames = windowFrame(radius);
    let doorGen = [];
    for (let i = 0; i < shutters; i++) {
      let doorShutter = pivotWindow(radius, shutters);
      doorGen.push(doorShutter);
    }
    let remRadius = radius - frameLength * 2;
    let doorSpan = remRadius / shutters;
    let doorLen = 0;
    for (let i = 1; i < doorGen.length + 1; i++) {
      let position = doorSpan / 2 + doorLen + frameLength;
      doorGen[i - 1].position.x = position;
      doorGen[i - 1].position.z = frameWidth / 2;
      doorLen = doorSpan * i;
    }
    mergeArr.push(frames);
    doorGen.forEach((ele) => mergeArr.push(ele));

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);

    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };
  let sliding = function (radius, type) {
    let shutters = type.substr(type.length - 1);
    let mergeArr = [];
    let doorGen = [];
    let frames = windowSlider(radius);
    for (let i = 0; i < shutters; i++) {
      let doorShutters = drawSlidingWin(radius, shutters);
      doorGen.push(doorShutters);
    }
    sliderLength = sliderLength ? sliderLength : frameLength * 2;
    sliderWidth = sliderWidth
      ? sliderWidth
      : frameWidth / shutters - thickness1;
    let remRadius = radius - frameLength * 2;
    let doorSpan = doorGen[0].getBoundingInfo().boundingBox.extendSize.x * 2;
    let sliderLen = 0;
    let doorWidSpan = frameWidth / shutters;
    let doorWid = frameWidth - thickness1;
    let doorLen = 0;
    let posX = 0;
    for (let i = 1; i < doorGen.length + 1; i++) {
      let positionX;
      positionX = posX + frameLength + doorLen - thickness1;
      posX = positionX;
      doorGen[i - 1].position.x = positionX;
      let positionZ = doorWid - doorWidSpan * (i - 1);
      doorGen[i - 1].position.z = positionZ;
      sliderLen = sliderLength;
      doorLen = doorSpan - sliderLen - frameLength + thickness1;
    }
    mergeArr.push(frames);
    doorGen.forEach((ele) => mergeArr.push(ele));

    let mergedMesh = BABYLON.Mesh.MergeMeshes(mergeArr, true, true, undefined, false, false);
    
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    return mergedMesh;
  };

  let revitImportWindow = function(symbolData){
    let mergeMeshes = [], window2D;
    let rotation = symbolData[symbolData.length - 1].rotation
    for (let d = 0; d < symbolData.length-1; d++) {
      if (symbolData[d].type == "Arc") {
        window2D = drawCurve(
          "curve1",
          symbolData[d].startPoint[0],
          symbolData[d].endPoint[0],
          symbolData[d].startPoint[1],
          symbolData[d].endPoint[1],
          symbolData[d].typeOfArc
         );
         mergeMeshes.push(window2D);
    } else if (symbolData[d].type == "Line") {
       let startPoint = new BABYLON.Vector3.FromArray(symbolData[d].startPoint)
       let endPoint = new BABYLON.Vector3.FromArray(symbolData[d].endPoint)
       window2D = drawThickLine(null, startPoint, 0.02, endPoint);
       mergeMeshes.push(window2D);
      }
    }

    let mergedMesh = BABYLON.Mesh.MergeMeshes(
      mergeMeshes,
      true,
      true,
      undefined,
      false,
      false
    );
    mergedMesh.renderingGroupId = 2;
    mergedMesh.isPickable = false;
    mergedMesh.rotation.y = 2 * Math.PI - rotation
    return mergedMesh
  }

  let drawWindow = function (radius, type, symbolData) {
    let genWindow;
    switch (type) {
      case "fixedWindow":
        genWindow = fixedWindow(radius);
        break;
      case "louvredWindow":
        genWindow = louvredWindow(radius);
        break;
      case "topHungWindow":
        genWindow = topHungWindow(radius);
        break;
      case "casementSingle":
        genWindow = casementSingle(radius);
        break;
      case "casementDouble":
        genWindow = casementDouble(radius);
        break;
      case "sliding2":
        genWindow = sliding(radius, type);
        break;
      case "sliding3":
        genWindow = sliding(radius, type);
        break;
      case "sliding4":
        genWindow = sliding(radius, type);
        break;
      case "sliding6":
        genWindow = sliding(radius, type);
        break;
      case "folding2":
        genWindow = folding(radius, type);
        break;
      case "folding3":
        genWindow = folding(radius, type);
        break;
      case "folding4":
        genWindow = folding(radius, type);
        break;
      case "folding6":
        genWindow = folding(radius, type);
        break;
      case "pivot3":
        genWindow = pivot(radius, type);
        break;
      case "pivot2":
        genWindow = pivot(radius, type);
        break;
      case "pivot4":
        genWindow = pivot(radius, type);
        break;
      case "pivot6":
        genWindow = pivot(radius, type);
        break;
      case "revitImport":
        genWindow = revitImportWindow(symbolData);
        break;
      default:
        return null;
    }
    return genWindow;
  };

  return {
    createDrawing: function (type, radius,symbolData) {
      return drawWindow(radius, type,symbolData);
    },
  };
})();
export { drawWindow };
