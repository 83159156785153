"use strict";
/*jshint esversion: 6 */
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import { _diff, removeScaleMeshAxis } from "../../libs/scaleMeshEvents.js";
import {
  isPointerOverGUIElement,
  checkOperationBIMFlowConditions,
} from "../extrafunc.js";
import { nonDefaultMeshForSnapping } from "../../libs/sceneStateFuncs.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import { delayedExecutionEngine } from "../utilityFunctions/delayedExecution.js";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import { updateHeightAfterEdits } from "../../libs/sceneFuncs.js";
import { scenePickController } from "../utilityFunctions/scenePickController.js";


var ScaleOperation = (function () {
  let _direction = null;
  let _scaleMesh = null;
  let _scaleAxisEnable = false;
  let _scaleBox = null;
  let _startPosition = null;
  let _dnClick = 0;
  let _stack_arr = [];
  let _stack_arr_ids = [];
  let _originalScaling = null;
  let _commandData = null;
  let _finalExecutedCount = null;
  let _meshEditCheckValue = null;

  let _commandDataBefore = null;

  var drawScaleAxes = function (mesh, boundingBox) {
    let bbInfo;
    if (mesh) bbInfo = mesh.getBoundingInfo();
    else bbInfo = boundingBox;

    let singleDirectionAxesPoints = getSingleScaleDirectionAxesPoints(bbInfo);

    drawScaleBox(mesh, singleDirectionAxesPoints[0], "x1", bbInfo);
    drawScaleBox(mesh, singleDirectionAxesPoints[1], "x2", bbInfo);
    drawScaleBox(mesh, singleDirectionAxesPoints[2], "y1", bbInfo);
    drawScaleBox(mesh, singleDirectionAxesPoints[3], "y2", bbInfo);
    drawScaleBox(mesh, singleDirectionAxesPoints[4], "z1", bbInfo);
    drawScaleBox(mesh, singleDirectionAxesPoints[5], "z2", bbInfo);

    for (let i = 0; i < bbInfo.boundingBox.vectorsWorld.length; i++) {
      let point = bbInfo.boundingBox.vectorsWorld[i].clone();
      let _dir = null;
      switch (i) {
        case 0:
          _dir = "x1y1z1";
          break;
        case 1:
          _dir = "x2y2z2";
          break;
        case 2:
          _dir = "x2y1z1";
          break;
        case 3:
          _dir = "x1y2z1";
          break;
        case 4:
          _dir = "x1y1z2";
          break;
        case 5:
          _dir = "x2y2z1";
          break;
        case 6:
          _dir = "x1y2z2";
          break;
        case 7:
          _dir = "x2y1z2";
          break;
      }
      drawScaleBox(mesh, point, _dir, bbInfo);
    }
  };

  var drawScaleBox = function (mesh, point, direction, bbInfo) {
    let boxSize = 0.05 * bbInfo.diagonalLength;
    if (boxSize > 0.75) boxSize = 0.75;

    if (mesh) if (mesh.type.toLowerCase() === "wall") boxSize = 0.25;

    let box = BABYLON.MeshBuilder.CreateBox(
      "scaleAxis" + direction,
      {
        size: boxSize,
      },
      store.scene
    );

    box.enableEdgesRendering();
    box.edgesWidth = 3.0;
    box.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
    box.position = point;
    box.material = store.scene.getMaterialByName("scaleBoxMat1");
    box.id = "scaleMeshAxis";
    box.type = "scaleAxis";
    box.direction = direction;
    scenePickController.add(box);
    // box.addLODLevel(200, null);
  };

  var getSingleScaleDirectionAxesPoints = function (bbInfo) {
    // let maximumWorld = bbInfo.boundingBox.maximumWorld;
    // let minimumWorld = bbInfo.boundingBox.minimumWorld;
    let vectorsWorld = bbInfo.boundingBox.vectorsWorld;
    let points = [];

    var point = vectorsWorld[0]
      .add(vectorsWorld[4])
      .add(vectorsWorld[6])
      .add(vectorsWorld[3])
      .scale(0.25);
    points.push(point);

    point = vectorsWorld[2]
      .add(vectorsWorld[7])
      .add(vectorsWorld[1])
      .add(vectorsWorld[5])
      .scale(0.25);
    points.push(point);

    point = vectorsWorld[0]
      .add(vectorsWorld[2])
      .add(vectorsWorld[7])
      .add(vectorsWorld[4])
      .scale(0.25);
    points.push(point);

    point = vectorsWorld[3]
      .add(vectorsWorld[5])
      .add(vectorsWorld[1])
      .add(vectorsWorld[6])
      .scale(0.25);
    points.push(point);

    point = vectorsWorld[0]
      .add(vectorsWorld[2])
      .add(vectorsWorld[5])
      .add(vectorsWorld[3])
      .scale(0.25);
    points.push(point);

    point = vectorsWorld[4]
      .add(vectorsWorld[7])
      .add(vectorsWorld[1])
      .add(vectorsWorld[6])
      .scale(0.25);
    points.push(point);
    console.log(points);

    return points;
  };

  var getCurrentScalePosition = function () {
    let hPickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
      return mesh.name === 'horizontalScaleSupportWall';
  });

  let vPickInfo = scenePickController.pickInvisibleMeshes(function (mesh) {
      return mesh.name === 'verticalScaleSupportWall';
  });

    if (["y1", "y2"].includes(_direction)) {
      if (vPickInfo.hit) return vPickInfo.pickedPoint;
    } else if (["x1", "x2", "z1", "z2"].includes(_direction)) {
      if (hPickInfo.hit) return hPickInfo.pickedPoint;
    } else {
      if (vPickInfo.hit && hPickInfo.hit) {
        return new BABYLON.Vector3(
          hPickInfo.pickedPoint.x,
          vPickInfo.pickedPoint.y,
          hPickInfo.pickedPoint.z
        );
      }
    }
  };

  var updateScaleMesh = function (distance) {
    if (_dnClick === 0) return;

    DisplayOperation.removeDimensions();
    let dist = distance;
    let mesh = _scaleMesh;
    let diff = _diff;
    removeScaleMeshAxis();
    mesh.prevScaling = mesh.scaling.clone();
    let prevScaling = _scaleMesh.scaling.clone();
    var bbinfo = mesh.getBoundingInfo();

    if (_direction === "x1") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x -=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    }
    if (_direction === "x2") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x +=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
    }
    if (_direction === "y1") {
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y -=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    }
    if (_direction === "y2") {
      mesh.scaling.y = dist * _originalScaling.y;
      // console.log(mesh.scaling.y);
      mesh.position.y +=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
    }
    if (_direction === "z1") {
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z -=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "z2") {
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z +=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x1y1z1") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x -=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y -=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z -=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x2y1z1") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x +=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y -=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z -=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x1y2z1") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x -=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.x;
      mesh.position.y +=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.x;
      mesh.position.z -=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x2y2z1") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x +=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y +=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z -=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x1y1z2") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x -=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y +=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z +=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x2y1z2") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x +=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y -=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z +=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x1y2z2") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x -=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y +=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z +=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (_direction === "x2y2z2") {
      mesh.scaling.x = dist * _originalScaling.x;
      mesh.position.x +=
        (mesh.scaling.x - prevScaling.x) * bbinfo.boundingBox.extendSize.x;
      mesh.scaling.y = dist * _originalScaling.y;
      mesh.position.y +=
        (mesh.scaling.y - prevScaling.y) * bbinfo.boundingBox.extendSize.y;
      mesh.scaling.z = dist * _originalScaling.z;
      mesh.position.z +=
        (mesh.scaling.z - prevScaling.z) * bbinfo.boundingBox.extendSize.z;
    }
    if (mesh.name.indexOf("boxScale") !== -1) {
      mesh.parentMesh.scaling = mesh.scaling;
      mesh.parentMesh.position = mesh.position;
    }

    _dnClick = 3;
    //so that parent child relationship is removed between selected elements
    //and _stack_arr is not cleared
    _scaleMesh.computeWorldMatrix(true);
    let newMeshEditCheckValue =
      _scaleMesh.getBoundingInfo().boundingBox.extendSizeWorld.y;
    if (_.round(_meshEditCheckValue, 4) !== _.round(newMeshEditCheckValue, 4))
      _scaleMesh.getSnaptrudeDS().markAsEdited();
    ScaleOperation._reset();

    _concludeOperation();
  };

  var scaleMesh = function (diff) {
    removeScaleAxes();
    _scaleMesh.computeWorldMatrix(true);
    let bbInfo = _scaleMesh.getBoundingInfo();
    bbInfo.update(_scaleMesh._worldMatrix);
    let prevScaling = _scaleMesh.scaling.clone();

    if (_direction === "y2") {
      _scaleMesh.scaling.y += diff.y / bbInfo.boundingBox.extendSize.y / 2;
      // console.log((diff.y / bbInfo.boundingBox.extendSize.y) / 2, _scaleMesh.scaling.y);
      _scaleMesh.position.y +=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
    } else if (_direction === "x2") {
      _scaleMesh.scaling.x += diff.x / bbInfo.boundingBox.extendSize.x / 2;
      _scaleMesh.position.x +=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
    } else if (_direction === "z2") {
      _scaleMesh.scaling.z += diff.z / bbInfo.boundingBox.extendSize.z / 2;
      _scaleMesh.position.z +=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x1") {
      _scaleMesh.scaling.x -= diff.x / bbInfo.boundingBox.extendSize.x / 2;
      _scaleMesh.position.x -=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
    } else if (_direction === "y1") {
      _scaleMesh.scaling.y -= diff.y / bbInfo.boundingBox.extendSize.y / 2;
      _scaleMesh.position.y -=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
    } else if (_direction === "z1") {
      _scaleMesh.scaling.z -= diff.z / bbInfo.boundingBox.extendSize.z / 2;
      _scaleMesh.position.z -=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x1y1z1") {
      _scaleMesh.scaling.x -=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x -=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y -= diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y -=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z -=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z -=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x2y1z1") {
      _scaleMesh.scaling.x -=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x +=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y -= diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y -=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z -=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z -=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x1y2z1") {
      _scaleMesh.scaling.x +=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x -=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y += diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y +=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z +=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z -=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x2y2z1") {
      _scaleMesh.scaling.x +=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x +=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y += diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y +=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z +=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z -=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x1y1z2") {
      _scaleMesh.scaling.x -=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x -=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y -= diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y -=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z -=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z +=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x2y1z2") {
      _scaleMesh.scaling.x -=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x +=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y -= diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y -=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z -=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z +=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x1y2z2") {
      _scaleMesh.scaling.x +=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x -=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y += diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y +=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z +=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z +=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    } else if (_direction === "x2y2z2") {
      _scaleMesh.scaling.x +=
        (diff.y * Math.abs(prevScaling.x / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.x +=
        (_scaleMesh.scaling.x - prevScaling.x) *
        bbInfo.boundingBox.extendSize.x;
      _scaleMesh.scaling.y += diff.y / bbInfo.boundingBox.extendSize.y / 5;
      _scaleMesh.position.y +=
        (_scaleMesh.scaling.y - prevScaling.y) *
        bbInfo.boundingBox.extendSize.y;
      _scaleMesh.scaling.z +=
        (diff.y * Math.abs(prevScaling.z / prevScaling.y)) /
        bbInfo.boundingBox.extendSize.y /
        5;
      _scaleMesh.position.z +=
        (_scaleMesh.scaling.z - prevScaling.z) *
        bbInfo.boundingBox.extendSize.z;
    }
  };

  var removeScaleAxes = function () {
    var meshes = store.scene.getMeshesByID("scaleMeshAxis");
    meshes.forEach(function (mesh) {
      mesh.dispose();
    });
  };

  var highlightScaleAxis = function (mesh) {
    if (mesh.material.name !== "scaleBoxMatBlue") {
      let material = store.scene.getMaterialByName("scaleBoxMatBlue");

      if (!material) {
        material = new BABYLON.StandardMaterial("scaleBoxMatBlue", store.scene);
        material.backFaceCulling = false;
        material.diffuseColor = new BABYLON.Color3(0.2, 0.5, 1.0);
      }

      mesh.material = material;
    }
  };

  var resetScaleAxesMaterial = function () {
    let meshes = store.scene.getMeshesByID("scaleMeshAxis");
    meshes.forEach(function (mesh) {
      mesh.material = store.scene.getMaterialByName("scaleBoxMat1");
    });
  };

  var createScaleSupportElements = function (position) {
    let horizontalScaleSupportWall = store.scene.getMeshByName(
      "horizontalScaleSupportWall"
    );
    if (!horizontalScaleSupportWall) {
      horizontalScaleSupportWall = BABYLON.Mesh.CreatePlane(
        "horizontalScaleSupportWall",
        10000,
        store.scene
      );
      horizontalScaleSupportWall.visibility = 1.0;
      horizontalScaleSupportWall.position = position;
      horizontalScaleSupportWall.rotation.x = Math.PI / 2;
      horizontalScaleSupportWall.visibility = 0.0;
      scenePickController.add(horizontalScaleSupportWall);
    }

    let verticalScaleSupportWall = store.scene.getMeshByName(
      "verticalScaleSupportWall"
    );
    if (!verticalScaleSupportWall) {
      verticalScaleSupportWall = BABYLON.Mesh.CreatePlane(
        "verticalScaleSupportWall",
        10000,
        store.scene
      );
      verticalScaleSupportWall.visibility = 1.0;
      verticalScaleSupportWall.position = position;
      verticalScaleSupportWall.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
      verticalScaleSupportWall.visibility = 0.0;
      scenePickController.add(verticalScaleSupportWall);
    }
  };

  var removeScaleSupportElements = function () {
    let horizontalScaleSupportWall = store.scene.getMeshByName(
      "horizontalScaleSupportWall"
    );
    if (horizontalScaleSupportWall) horizontalScaleSupportWall.dispose();
    let verticalScaleSupportWall = store.scene.getMeshByName(
      "verticalScaleSupportWall"
    );
    if (verticalScaleSupportWall) verticalScaleSupportWall.dispose();
  };

  function onPointerDown(evt) {
    if (store.isiPad && isPointerOverGUIElement()) return;
    if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
      _dnClick++;
      let pickInfo = scenePickController.pick(function (mesh) {
        return mesh.type !== "staircase" && !Mass.isPlinth(mesh);
      });

      if (_dnClick === 1) {
        if (pickInfo.hit) {
          let options = {
            operation: "scale",
            mesh: pickInfo.pickedMesh,
          };
          if (!checkOperationBIMFlowConditions(options)) {
            _dnClick = 0;
            return;
          }

          // newScene.activeCamera.detachControl(canvas);
          if (pickInfo.pickedMesh.stack) {
            if (_stack_arr.length > 1) {
              _scaleMesh = _stack_arr[0];
              let vec_arr = [];
              _stack_arr.forEach(function (mesh) {
                let bbInfo = mesh.getBoundingInfo();
                vec_arr.push(bbInfo.boundingBox.maximumWorld);
                vec_arr.push(bbInfo.boundingBox.minimumWorld);
              });
              let x_min = _.minBy(vec_arr, "x");
              let y_min = _.minBy(vec_arr, "y");
              let z_min = _.minBy(vec_arr, "z");

              let x_max = _.maxBy(vec_arr, "x");
              let y_max = _.maxBy(vec_arr, "y");
              let z_max = _.maxBy(vec_arr, "z");

              let newBoundingBox = new BABYLON.BoundingInfo(
                new BABYLON.Vector3(x_min.x, y_min.y, z_min.z),
                new BABYLON.Vector3(x_max.x, y_max.y, z_max.z)
              );
              drawScaleAxes(null, newBoundingBox);
            }
          } else {
            if (Mass.isPlinth(pickInfo.pickedMesh)) {
              _dnClick--;
              return;
            }
            _scaleMesh = pickInfo.pickedMesh;
            drawScaleAxes(pickInfo.pickedMesh, null);
            _commandData = null;
          }
          _meshEditCheckValue =
            _scaleMesh.getBoundingInfo().boundingBox.extendSizeWorld.y;
        } else _dnClick = 0;
      } else if (_dnClick === 2) {
        let pickInfo1 = scenePickController.pick(function (mesh) {
          return ['scaleaxis'].includes(mesh.type.toLowerCase());
        });

        if (pickInfo1.hit) {
          _scaleAxisEnable = true;
          _scaleBox = pickInfo1.pickedMesh;
          _direction = _scaleBox.direction;
          _startPosition = _scaleBox.position.clone();
          _originalScaling = _scaleMesh.scaling.clone();
          console.log("this is scaling orginal one", _originalScaling);
          createScaleSupportElements(_scaleBox.position);
          // newScene.activeCamera.detachControl(canvas);
          _removeAllSelectionBoxes();

          let undoDoneBetweenCycles = false;
          if (_finalExecutedCount)
            undoDoneBetweenCycles =
              CommandManager.getCurrentState().executed.length !==
              _finalExecutedCount;
          if (!_commandData || undoDoneBetweenCycles) {
            // _commandData will be populated if group selected
            _createBeforeCommand();
          }

          if (_stack_arr.length > 1) {
            _stack_arr.forEach(function (mesh) {
              if (mesh.type) {
                if (["wall", "floor"].includes(mesh.type.toLowerCase())) {
                  if (mesh.childrenComp.length > 0) {
                    mesh.childrenComp.forEach(function (child) {
                      if (!_stack_arr_ids.includes(child.uniqueId)) {
                        child.setParent(null);
                      }
                    });
                  }
                }
              }
            });
          } else {
            if (_scaleMesh) {
              if (_scaleMesh.type) {
                if (["wall", "floor"].includes(_scaleMesh.type.toLowerCase())) {
                  if (_scaleMesh.childrenComp.length > 0) {
                    _scaleMesh.childrenComp.forEach(function (child) {
                      if (!_stack_arr_ids.includes(child.uniqueId)) {
                        child.setParent(null);
                      }
                    });
                  }
                }
              }
            }
          }
        } else {
          ScaleOperation._reset();
          if (pickInfo.hit) {
            if (pickInfo.pickedMesh.stack) {
              if (_stack_arr.length > 1) {
                _scaleMesh = _stack_arr[0];
                let vec_arr = [];
                _stack_arr.forEach(function (mesh) {
                  let bbInfo = mesh.getBoundingInfo();
                  vec_arr.push(bbInfo.boundingBox.maximumWorld);
                  vec_arr.push(bbInfo.boundingBox.minimumWorld);
                });
                let x_min = _.minBy(vec_arr, "x");
                let y_min = _.minBy(vec_arr, "y");
                let z_min = _.minBy(vec_arr, "z");

                let x_max = _.maxBy(vec_arr, "x");
                let y_max = _.maxBy(vec_arr, "y");
                let z_max = _.maxBy(vec_arr, "z");

                let newBoundingBox = new BABYLON.BoundingInfo(
                  new BABYLON.Vector3(x_min.x, y_min.y, z_min.z),
                  new BABYLON.Vector3(x_max.x, y_max.y, z_max.z)
                );
                drawScaleAxes(null, newBoundingBox);
              }
            } else {
              _scaleMesh = pickInfo.pickedMesh;
              drawScaleAxes(pickInfo.pickedMesh, null);
            }
            _dnClick = 1;
          } else {
            _dnClick = 0;
          }
        }
      } else if (_dnClick === 3) {
        if (store.isiPad) {
          _concludeOperation();
          onPointerDown(evt);
        } else {
          _concludeOperation();
        }
      }
      console.log(_dnClick);
    }
  }

  let _setDelayedExecutable = function () {
    delayedExecutionEngine.addExecutable(_createAfterCommand);
  };

  function _createBeforeCommand() {
    let stack = [];
    let checkUnderscoreParent = true;
    if (!_.isEmpty(_stack_arr)) {
      stack = _stack_arr;
    } else if (!_.isEmpty(store.selectionStack)) {
      stack = store.selectionStack;
      checkUnderscoreParent = false;
    }

    let options = {
      params: [
        commandUtils.worldMatrixChangeOperations.PARAMS.position,
        commandUtils.worldMatrixChangeOperations.PARAMS.scale,
      ],
      stack: stack,
    };

    if (stack.length === 0 || stack.length === 1) {
      if (_scaleMesh)
        if (_scaleMesh.parent) {
          options.operationOnChild = true;
        }
    } else if (stack.length > 1) {
      let nonChildMeshPresent = false;
      stack.some((mesh, i) => {
        if (i === 0) {
          if (!mesh.parent) {
            nonChildMeshPresent = true;
            return true;
          }
        } else {
          let property;
          if (checkUnderscoreParent) {
            property = mesh._prevParent;
          } else {
            property = mesh.parent;
          }
          if (!property) {
            nonChildMeshPresent = true;
            return true;
          }
        }
      });

      if (!nonChildMeshPresent) options.operationOnChild = true;
    }

    _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
      _scaleMesh,
      options
    );

    _setDelayedExecutable();
  }

  const _updateVirtualSketcherData = function () {
    let array;
    if (_.isEmpty(_stack_arr)) {
      array = [_scaleMesh];
    } else {
      array = _stack_arr;
    }

    return array.map((m) =>
      virtualSketcher.updateWithGeometryEdit(m.getSnaptrudeDS())
    );
  };

  function _createAfterCommand() {
    _removeParentChildRelationships();

    let options = {};
    options.data = _commandData;

    _commandData = commandUtils.worldMatrixChangeOperations.getCommandData(
      _scaleMesh,
      options
    );

    const scaleCommand = commandUtils.worldMatrixChangeOperations.getCommand(
      commandUtils.CONSTANTS.scaleOperation,
      _commandData
    );

    const integrationGeometryChangeCommands = _updateVirtualSketcherData();

    // const allCommands = [scaleCommand, ...integrationGeometryChangeCommands];
    const allCommands = _.compact([
      scaleCommand,
      ...integrationGeometryChangeCommands,
    ]);
    const yets = allCommands.map((_) => false);

    CommandManager.execute(allCommands, yets);

    _commandData = null;

    _createParentChildRelationship();
  }

  function _createParentChildRelationship() {
    if (_stack_arr.length > 1) {
      let firstMesh = _stack_arr[0];
      _stack_arr.forEach(function (mesh, index) {
        mesh.stack = true;
        if (index !== 0) {
          mesh.setParent(firstMesh);
        }
      });
    }
  }

  function _removeParentChildRelationships(removeStack) {
    if (_stack_arr.length > 1) {
      _stack_arr.forEach(function (mesh, index) {
        if (removeStack) mesh.stack = false;
        if (index !== 0) {
          if (mesh._prevParent) {
            mesh.setParent(store.scene.getMeshByUniqueID(mesh._prevParent));
          } else {
            mesh.setParent(null);
          }
        }
      });
    }
  }

  let _removeAllSelectionBoxes = function () {
    let mesh = store.scene.getMeshByName("boxScale");
    while (mesh) {
      mesh.dispose();
      mesh = store.scene.getMeshByName("boxScale");
    }
  };

  function _concludeOperation() {
    // newScene.activeCamera.attachControl(canvas, true, false);
    _scaleMesh = updateHeightAfterEdits(_scaleMesh);

    _scaleMesh.computeWorldMatrix(true);
    let newMeshEditCheckValue =
      _scaleMesh.getBoundingInfo().boundingBox.extendSizeWorld.y;
    if (_.round(_meshEditCheckValue, 4) !== _.round(newMeshEditCheckValue, 4))
      _scaleMesh.getSnaptrudeDS().markAsEdited();

    delayedExecutionEngine.executeAll();

    ScaleOperation._reset();

    _finalExecutedCount = CommandManager.getCurrentState().executed.length;
  }

  return {
    onPointerDown,

    onPointerMove: function (evt) {
      if (store.isiPad && isPointerOverGUIElement()) return;
      if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
        if (_scaleMesh) {
          resetScaleAxesMaterial();
          if (_scaleAxisEnable) {
            let _currentPosition = getCurrentScalePosition();
            if (!_currentPosition) return;

            let diff = _currentPosition.subtract(_startPosition);
            scaleMesh(diff);
            _startPosition = _currentPosition;

            const options = {
              onChangeCallback: updateScaleMesh,
            };

            DisplayOperation.displayScaleDimensions(
              _scaleMesh.position,
              _scaleMesh,
              diff,
              _direction,
              _originalScaling,
              options
            );
          } else {
            let pickInfo = scenePickController.pick(function (mesh) {
              return mesh.type === 'scaleAxis';
            });
            if (pickInfo.hit) highlightScaleAxis(pickInfo.pickedMesh);
          }
        }
      }
    },

    onPointerUp: function (evt) {
      if (store.isiPad && isPointerOverGUIElement()) return;
      if (store.isiPad) {
        if (_dnClick === 2) {
          // _commandDataBefore = deepCopyObject(_commandData);
          // keypadController.showKeypad(getBabylonGUIElementByName("distBox"), updateScaleMesh);
        }
      }
    },

    assignStackArray: function (arr) {
      if (arr.length > 1) {
        _createBeforeCommand();

        _stack_arr = arr;
        _.remove(_stack_arr, (mesh) => Mass.isPlinth(mesh));
        let firstMesh = _stack_arr[0];

        _stack_arr.forEach(function (mesh, index) {
          _stack_arr_ids.push(mesh.uniqueId);
          mesh.stack = true;
          if (index !== 0) {
            if (mesh.parent) mesh._prevParent = mesh.parent.uniqueId;
            mesh.setParent(firstMesh);
          }
        });
      }
    },

    updateScaleMesh: function (distance) {
      if (_scaleMesh) {
        updateScaleMesh(distance);
      }
    },

    _reset: function () {
      if (_scaleMesh) {
        if (_scaleMesh.stack) {
          if (_stack_arr.length > 0) {
            _stack_arr.forEach(function (mesh, index) {
              if (mesh.type) {
                if (["wall", "floor"].includes(mesh.type.toLowerCase())) {
                  if (mesh.childrenComp.length > 0) {
                    mesh.childrenComp.forEach(function (child) {
                      child.setParent(mesh);
                    });
                  }
                }
              }
              if (_dnClick !== 2) {
                mesh.stack = false;
                if (index !== 0) {
                  if (mesh._prevParent)
                    mesh.setParent(
                      store.scene.getMeshByUniqueID(mesh._prevParent)
                    );
                  else mesh.setParent(null);
                }
              }
            });
          }
        } else {
          if (_scaleMesh.type) {
            if (["wall", "floor"].includes(_scaleMesh.type.toLowerCase())) {
              if (_scaleMesh.childrenComp.length > 0) {
                _scaleMesh.childrenComp.forEach(function (child) {
                  child.setParent(_scaleMesh);
                });
              }
            }
          }
        }
      }

      if (_dnClick !== 3 && _dnClick !== 2) {
        _stack_arr.length = 0;
        _stack_arr_ids.length = 0;
        _scaleMesh = null;

        _commandData = null;
        _finalExecutedCount = null;
      }
      _dnClick = 0;
      _scaleAxisEnable = false;
      _direction = null;
      _startPosition = null;
      removeScaleAxes();
      removeScaleSupportElements();
      DisplayOperation.removeDimensions();
    },
  };
})();
export { ScaleOperation };
