import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import leftArrow from "../../../assets/icons/leftArrow.svg";

export const Container = styled.div`
  background: ${colors.fullWhite};
  box-shadow: 0px 7.51562px 17.3438px ${colors.shadowGrey};
  padding: 0.875rem 0.5625rem;
  width: 16.125rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const OptionsContainer = styled.div`
  padding: 0.5rem 0.8125rem;
  border-radius: 2.30888px;
  cursor: pointer;
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    mergin-right: 0.5rem;
    letter-spacing: -0.333333px;
    color: ${colors.darkGrey};
  }
  .subText {
    color: ${colors.thinGray};
  }
  &:hover {
    background: ${colors.white};
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  .arrowImage {
    margin-left: 0.4375rem;
  }
`;

export const ExportButtonDropdown = (props) => {
  const { list, onPress, closePopUp } = props;
  const ref = useRef(null);

  const closeOnOutsideClick = (evt) => {
    if(!ref.current.contains(evt.target)) {
      closePopUp();
    }
  }

  useEffect(() => {
    window.addEventListener("click", closeOnOutsideClick);
    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
    }
  }, [closeOnOutsideClick]);

  return (
    <Container ref={ref}>
      {list &&
        list.map((item, index) => {
          return (
            <OptionsContainer onClick={() => onPress(item.option)} key={index}>
              <Flex>
                <p>{item.title}</p>
                <img className="arrowImage" src={leftArrow} alt=">" />
              </Flex>
              <p className="subText">{item.subtitle}</p>
            </OptionsContainer>
          );
        })}
    </Container>
  );
};
