'use strict';

import cache from "js-cache";

const cachingService = (function (){

    const SERVICES = {
        pick : '1',
        material : '2',
        door : '3',
        window : '4',
        furniture : '5',
        materialCategories : '6',
        doorCategories : '7',
        windowCategories : '8',
        furnitureCategories : '9',
        userImportedFile:'10',
        userImportedFileCategories:'11',
    };

    const TTL = 60 * 1e3;

    const set = function (serviceId, value, ttl = TTL){
        cache.set(serviceId, value, ttl);
    };

    const get = function (serviceId){
        return cache.get(serviceId);
    };

    const invalidate = function (serviceId){
        cache.del(serviceId);
    };

    const flush = function(){
        cache.clear();
    };

    return {
        set,
        get,
        invalidate,
        flush,
        SERVICES
    };

})();

export {
    cachingService,
};
