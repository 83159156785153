import {removeMouseEvents} from "../../libs/toolbarEvents";
import {store} from "../utilityFunctions/Store";
import {scenePickController} from "../utilityFunctions/scenePickController";
import {nonDefaultMeshForSnapping} from "../../libs/sceneStateFuncs";
import {DisplayOperation} from "../displayOperations/displayOperation";
import BABYLON from "../babylonDS.module";
import {StateMachine} from "../Classes/StateMachine";
import reduxStore from "../../stateManagers/store/reduxStore";
import {updateMaterialValue} from "../../stateManagers/reducers/objectProperties/materialsSlice";

const MaterialEditor = (function () {
  const EVENTS = {
    0: cleanUp,
    1: _selectMaterial,
    2: _performTransform,
    4: _intermediateCleanUp
  };

  const mouseSequence = {
    pointermove: {
      0: [0, 0, 0],
      1: [2, 1, 0]
    },

    pointerdown: {
      0: [1, 1, 0],
    },

    pointerup: {
      1: [4, 0, 0]
    }
  }

  const STATE_MACHINE = new StateMachine(EVENTS, mouseSequence);

  let _drag = false;
  let _startPoint = null;
  let _scroll = false;
  let _material = null;

  const eventHandler = function (evt) {
    STATE_MACHINE.nextEvent(evt);
  };

  function _selectMaterial() {
    return new Promise((resolve, reject) => {
      const pickInfo = scenePickController.pick(mesh => {
        return nonDefaultMeshForSnapping(mesh);
      });

      if (pickInfo.hit) {
        _drag = true;
        _startPoint = pickInfo.getTextureCoordinates().clone();
        const pickedMesh = pickInfo.pickedMesh;
        if (!pickedMesh.material.subMaterials) reject();
        const subMeshId = pickInfo.subMeshId;
        const materialIndex = pickedMesh.subMeshes[subMeshId].materialIndex;

        _material = pickedMesh.material.subMaterials[materialIndex];
        if (!_material.diffuseTexture) reject();
        resolve();
      }
      else {
        reject();
      }
    })
  }

  function _performTransform() {
    return new Promise((resolve, reject) => {
      const pickInfo = scenePickController.pick(mesh => {
        return nonDefaultMeshForSnapping(mesh);
      });

      if (pickInfo.hit) {
        if (_drag) {
          const currentPoint = pickInfo.getTextureCoordinates().clone();

          const delta = _startPoint.subtract(currentPoint);
          const matrix = _material.diffuseTexture.getTextureMatrix();

          let deltaTransform = new BABYLON.Vector2.Zero();
          BABYLON.Vector2.TransformToRef(delta, matrix, deltaTransform);

          _material.diffuseTexture.uOffset += deltaTransform.x;
          _material.diffuseTexture.vOffset += deltaTransform.y;

          const payload = {
            uOffset: DisplayOperation.convertToDefaultDimension(
              _material.diffuseTexture.uOffset / (store.unit_absolute_scale * 10)),
            vOffset: DisplayOperation.convertToDefaultDimension(
              _material.diffuseTexture.vOffset / (store.unit_absolute_scale * 10))
          }

          reduxStore.dispatch(updateMaterialValue(payload));
          _startPoint = currentPoint.clone();
          resolve();
        } else {
          reject();
        }
      } else {
        reject();
      }
    })
  }

  function onScroll(evt) {
    let sign = evt.deltaY > 0 ? 1 : -1;
    if (!_material) return;
    _material.diffuseTexture.uScale += DisplayOperation.getOriginalDimension(0.05, "meters") * sign;
    _material.diffuseTexture.vScale += DisplayOperation.getOriginalDimension(0.05, "meters") * sign;
  }

  function init() {
    // removeMouseEvents();
    store.canvas.addEventListener("pointerdown", eventHandler, false);
    store.canvas.addEventListener("pointermove", eventHandler, false);
    store.canvas.addEventListener("pointerup", eventHandler, false);
  }

  function cleanUp() {
    _drag = false;
    _startPoint = null;
    _material = null;

    return Promise.resolve();
  }

  function _intermediateCleanUp() {
    _drag = false;
    _startPoint = null;

    return Promise.resolve();
  }

  function isScale() {
    return _scroll;
  }

  function disable() {
    cleanUp();
    store.canvas.removeEventListener("pointerdown", eventHandler);
    store.canvas.removeEventListener("pointermove", eventHandler);
    store.canvas.removeEventListener("pointerup", eventHandler);
  }

  return {
    eventHandler,
    init,
    isScale,
    onScroll,
    cleanUp,
    disable
  }
})();

export default MaterialEditor;