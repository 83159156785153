import * as d3 from "d3";
import { kdTree } from "kd-tree-javascript";
import { fabric } from "fabric";
import $ from "jquery";
import { store } from "../../modules/utilityFunctions/Store.js";
import { updateModifications } from "../sceneStateFuncs.js";
import { snapNewObject } from "./snapFuncsFplan.js";
function distance(a, b) {
  let dx = a.x - b.x;
  let dy = a.y - b.y;
  return dx * dx + dy * dy;
}

function draw_door(door) {
  // let list = door.split(',');
  // let numb = [];
  for (let i = 0; i < door.length; i++) {
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
  }

  let pol = new fabric.Polygon(
    [
      { x: door[0][0], y: door[0][1] },
      { x: door[1][0], y: door[1][1] },
      { x: door[3][0], y: door[3][1] },
      { x: door[2][0], y: door[2][1] },
    ],
    {
      angle: 0,
      stroke: "green",
      strokeWidth: 2,
      evented: true,
      id: "door",
      fill: "rgba(0,0,0,0)",
    }
  );
  store.canvas.add(pol);
  updateModifications(true);
  store.undoStack.push("Door");
}

function change_door_position(pointer) {
  if (!store.line_flag) {
    store.m = 0;
    store.c = 0;
  }
  if (store.line_flag) {
    let px = pointer.x;
    let py = pointer.y;
    pointer.x =
      (px - store.m * py - store.m * store.c) / (1 + store.m * store.m);
    pointer.y =
      -(store.m * px - store.m * store.m * py + store.c) /
      (1 + store.m * store.m);
  }
  store.dl = 50;
  store.a = store.dl / (2 * Math.sqrt(1 + store.store.m * store.m));
  store.b = store.a * store.m;
  store.ang_shift = Math.atan(store.m);
  store.isUp = true;
  let points = [
    pointer.x - store.a,
    pointer.y + store.b,
    pointer.x + store.a,
    pointer.y - store.b,
  ];
  // let points = [ pointer.x + 6 , pointer.y + 55 , pointer.x + 55, pointer.y + 55 ];
  store.door_group.width = 50;
  store.door_group.height = 50;
  // (door_group);
  store.door_group.left = pointer.x;
  store.door_group.top = pointer.y;
  store.door_group.bringToFront();
}

function draw_door_position(pointer) {
  if (!store.line_flag) {
    store.m = 0;
    store.c = 0;
  }
  if (store.line_flag == 1) {
    let px = pointer.x;
    let py = pointer.y;
    pointer.x =
      (px - store.m * py - store.m * store.c) / (1 + store.m * store.m);
    pointer.y =
      -(store.m * px - store.m * store.m * py + store.c) /
      (1 + store.m * store.m);
  }

  let dl = 50;
  let a = dl / (2 * Math.sqrt(1 + store.m * store.m));
  let b = a * store.m;
  let ang_shift = Math.atan(store.m);
  let isUp = true;
  // if (line_flag == 2){
  //     a = 0;
  //     b = 0;
  //     ang_shift = 90;
  // }
  let points = [pointer.x - a, pointer.y + b, pointer.x + a, pointer.y - b];
  store.line1 = new fabric.Line(points, {
    id: "line1",
    strokeWidth: 2,
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    evented: false,
  });
  store.canvas.add(store.line1);
  store.arc = new fabric.Circle({
    id: "arc",
    radius: dl,
    left: pointer.x - a,
    top: pointer.y + b,
    angle: 0,
    startAngle: -(Math.PI / 2 + ang_shift),
    endAngle: -ang_shift,
    strokeWidth: 1,
    stroke: "black",
    transparentCorners: true,
    fill: "",
    selectable: false,
    originX: "center",
    originY: "center",
    evented: false,
  });
  store.canvas.add(store.arc);
  points = [
    pointer.x - a,
    pointer.y + b,
    pointer.x - a - 2 * b,
    pointer.y + b - 2 * a,
  ];
  store.line2 = new fabric.Line(points, {
    id: "line2",
    strokeWidth: 2,
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    evented: false,
  });
  store.canvas.add(store.line2);
}

function draw_door_init(door) {
  // var list = door.split(',');
  // var numb = [];
  for (var i = 0; i < door.length; i++) {
    door[i][0] = door[i][0];
    door[i][1] = door[i][1];
  }

  var pol = new fabric.Polygon(
    [
      { x: door[0][0], y: door[0][1] },
      { x: door[1][0], y: door[1][1] },
      { x: door[2][0], y: door[2][1] },
      { x: door[3][0], y: door[3][1] },
    ],
    {
      angle: 0,
      stroke: "green",
      strokeWidth: 2,
      evented: true,
      id: "door",
      fill: "rgba(0,0,0,0)",
    }
  );
  store.canvas.add(pol);
  // updateModifications(true);
  // undoStack.push("Door");
}

function draw_window(door) {
  // let list = door.split(',');
  // let numb = [];
  // store.scale_factor;
  for (let i = 0; i < door.length; i++) {
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
  }

  let pol = new fabric.Polygon(
    [
      { x: door[0][0], y: door[0][1] },
      { x: door[1][0], y: door[1][1] },
      { x: door[3][0], y: door[3][1] },
      { x: door[2][0], y: door[2][1] },
    ],
    {
      angle: 0,
      stroke: "red",
      strokeWidth: 2,
      evented: false,
      id: "window",
      fill: "rgba(0,0,0,0)",
    }
  );
  store.canvas.add(pol);
  updateModifications(true);
  store.undoStack.push("Window");
}

function draw_window_position(pointer) {
  if (!store.line_flag) {
    store.m = 0;
  }
  let dl = 50;
  let a = dl / (2 * Math.sqrt(1 + store.m * store.m));
  let b = a * store.m;
  let points = [pointer.x - a, pointer.y + b, pointer.x + a, pointer.y - b];
  store.line3 = new fabric.Line(points, {
    strokeWidth: 2,
    stroke: "black",
    originX: "center",
    originY: "center",
    selectable: false,
    evented: false,
  });
  store.canvas.add(store.line3);
}

function draw_window_init(door) {
  // var list = door.split(',');
  // var numb = [];
  // store.scale_factor;
  for (var i = 0; i < door.length; i++) {
    door[i][0] = door[i][0];
    door[i][1] = door[i][1];
  }

  var pol = new fabric.Polygon(
    [
      { x: door[0][0], y: door[0][1] },
      { x: door[1][0], y: door[1][1] },
      { x: door[2][0], y: door[2][1] },
      { x: door[3][0], y: door[3][1] },
    ],
    {
      angle: 0,
      stroke: "red",
      strokeWidth: 2,
      evented: false,
      id: "window",
      fill: "rgba(0,0,0,0)",
    }
  );
  store.canvas.add(pol);
  // updateModifications(true);
  // undoStack.push("Window");
}

function draw_interior(door, mode) {
  let points = [];
  let tree_points = [];
  let temp_pts = [];
  for (let i = 0; i < door.length; i++) {
    temp_pts.push([door[i][0], door[i][1]]);
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
    points.push(new fabric.Point(door[i][0], door[i][1]));
    let point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  let fillColor;
  let n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  if (points.length > 100 || $("#curve_tog").prop("checked")) {
    let svg = d3
      .select("body")
      .append("svg")
      .attr("width", 600)
      .attr("height", 500);

    var path = svg
      .append("path")
      .data([door])
      .attr("d", d3.line().curve(d3.curveBasis))
      .attr("stroke-weight", "5px")
      .attr("fill", "none")
      .attr("class", "poly");
    let pathHTML = svg._groups[0][0].innerHTML;
    // let n = name.slice(1, name.length - 1);
    pathHTML = pathHTML.split('"');
    // pathHTML = pathHTML.slice(5,pathHTML.length);
    // (pathHTML);
    // (door);
    var path = new fabric.Path(pathHTML[1], {
      angle: 0,
      stroke: "blue",
      strokeWidth: 2,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      evented: true,
      id: "rooms",
      pol_points: temp_pts,
      fill: fillColor,
    });
    store.canvas.renderAll();
    store.canvas.add(path);
    // (canvas);
    // (path);
    updateModifications(true);
    return;
  }

  let tree = new kdTree(tree_points, distance, ["x", "y"]);
  let pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "blue",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    ktree: tree,
    id: "rooms",
    fill: fillColor,
  });
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  store.canvas.renderAll();
  store.canvas.add(pol);

  pol = snapNewObject(pol, 15, 15);
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  pol.perPixelTargetFind = true;
  store.canvas.renderAll();

  store.canvas.renderAll();
  updateModifications(true);
  // undoStack.push("Door");
}

function draw_interior_polToC(door, mode) {
  let points = [];
  let tree_points = [];
  let temp_pts = [];
  for (let i = 0; i < door.length; i++) {
    temp_pts.push([door[i][0], door[i][1]]);
    door[i][0] = door[i][0] / store.scale_factor;
    door[i][1] = door[i][1] / store.scale_factor;
    points.push(new fabric.Point(door[i][0], door[i][1]));
    let point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  let fillColor;
  let n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  if (true) {
    let svg = d3
      .select("body")
      .append("svg")
      .attr("width", 600)
      .attr("height", 500);

    var path = svg
      .append("path")
      .data([door])
      .attr("d", d3.line().curve(d3.curveBasis))
      .attr("stroke-weight", "5px")
      .attr("fill", "none")
      .attr("class", "poly");
    let pathHTML = svg._groups[0][0].innerHTML;
    // let n = name.slice(1, name.length - 1);
    pathHTML = pathHTML.split('"');
    // pathHTML = pathHTML.slice(5,pathHTML.length);
    // (pathHTML);
    // (door);
    var path = new fabric.Path(pathHTML[1], {
      angle: 0,
      stroke: "blue",
      strokeWidth: 2,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      evented: true,
      id: "rooms",
      pol_points: temp_pts,
      fill: fillColor,
    });
    store.canvas.renderAll();
    store.canvas.add(path);
    // (canvas);
    // (path);
    updateModifications(true);
    return;
  }
}

function draw_interior_init(door, mode, room_pos, room_rot, room_scaling) {
  //let prev_scale_factor = {{room_scale_2d}}; DECLARED GLOBALLY
  let points = [];
  let tree_points = [];
  for (let i = 0; i < door.length; i++) {
    door[i][0] = door[i][0];
    door[i][1] = door[i][1];
    points.push(
      new fabric.Point(
        (door[i][0] * store.prev_scale_factor) / store.scale_factor,
        (door[i][1] * store.prev_scale_factor) / store.scale_factor
      )
    );
    let point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  let fillColor;
  let n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  // let n = parseInt(fillColor.split(5,10));

  let tree = new kdTree(tree_points, distance, ["x", "y"]);
  let pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "blue",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    ktree: tree,
    id: "rooms",
    fill: fillColor,
  });
  if (room_pos[0] != 0) {
    // pol.originX = 'center';
    pol.left = room_pos[0] - pol.width / 2;
    // pol.originX = 'left';
    // (pol.left);
  }
  if (room_pos[2] != 0) {
    // pol.originY = 'center';
    pol.top = Math.abs(room_pos[2]) - pol.height / 2;
    // pol.originX = 'top';
    // (pol.top);
  }

  pol.origLeft = pol.left;
  pol.origTop = pol.top;

  // pol.scaleX = 1/scale_factor;
  // pol.scaleY = 1/scale_factor;
  store.canvas.renderAll();
  store.canvas.add(pol);

  // undoStack.push("Door");
}

function draw_interior_init_curve(
  door,
  mode,
  room_pos,
  room_rot,
  room_scaling
) {
  //let prev_scale_factor = DECLARED GLOBALLY
  let tree_points = [];
  let fillColor;
  let n = mode[mode.length - 1];
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";

  // (door);
  let pol = new fabric.Path(door, {
    angle: 0,
    stroke: "blue",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    id: "rooms",
    fill: fillColor,
  });
  if (room_pos[0] != 0) {
    pol.left = room_pos[0] - pol.width / 2;
  }
  if (room_pos[2] != 0) {
    pol.top = Math.abs(room_pos[2]) - pol.height / 2;
  }
  pol.origLeft = pol.left;
  pol.origTop = pol.top;
  store.canvas.renderAll();
  store.canvas.add(pol);
  updateModifications(true);
  return;
}

function draw_interior_drag(door, obj, mode) {
  //obj is left unused and it's recommended to leave it unused.
  let points = [];
  let tree_points = [];
  for (let i = 0; i < door.length; i++) {
    door[i][0] = door[i].x;
    door[i][1] = door[i].y;
    points.push(new fabric.Point(door[i][0], door[i][1]));
    let point = {
      x: door[i][0],
      y: door[i][1],
    };
    tree_points.push(point);
  }

  let fillColor;
  let n = mode;
  fillColor =
    "rgba(" +
    ((n * 53 + 5) % 255) +
    "," +
    ((n * 153 + 2) % 255) +
    "," +
    ((n * 34 + 3) % 255) +
    ",0.3)";
  // let n = parseInt(fillColor.split(5,10));

  // (door.length);
  let tree = new kdTree(tree_points, distance, ["x", "y"]);
  let pol = new fabric.Polygon(points, {
    angle: 0,
    stroke: "blue",
    strokeWidth: 2,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    evented: true,
    ktree: tree,
    id: "rooms",
    fill: fillColor,
  });
  pol.origLeft = pol.left;
  pol.origTop = pol.top;

  // pol.setGradient('fill', {
  //   type: 'linear',
  //   x1: -pol.width / 2,
  //   y1: 0,
  //   x2: pol.width / 2,
  //   y2: 0,
  //   colorStops: {
  //     0: 'red',
  //     0.5: '#005555',
  //     1: 'rgba(0,0,255,0.5)'
  //   }
  // });
  store.canvas.renderAll();
  store.canvas.add(pol);
  return pol;
  // undoStack.push("Door");
}
export {
  distance,
  draw_door,
  change_door_position,
  draw_door_position,
  draw_door_init,
  draw_window,
  draw_window_position,
  draw_window_init,
  draw_interior,
  draw_interior_polToC,
  draw_interior_init,
  draw_interior_init_curve,
  draw_interior_drag,
};
