import styled from "styled-components"
export const Wrapper = styled.div`
    // border: 2px solid;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    .disabled{
        opacity: 0.5;
        pointer-events: none;
    }
    .noneditable{
        pointer-events: none;
    }
    table{
        border-spacing: 8px;
    }
    thead{
        position: sticky;
        top: 0px;
        z-index: 5;
        background-color: #fcfcfc;
        border-bottom: 1px solid #ddd;
        white-space: nowrap;
    }
    thead th{
        border-bottom: 1px solid #ddd;
        position: relative;
        cursor: pointer;
    }
    th:first-child, td:first-child{
        text-align: left;
        width: 50px;
        min-width: 50px;
    }
    th, td{
        padding: 0.25em;
        min-width: 100px;
        text-align: center;
    }

    .dropdown-wrapper{
        display: flex;
        justify-content: flex-end;
    }
    .pills-wrapper{
        display: flex;
        justify-content: flex-end;
    }
    .pills-wrapper .pill, .add-more{
        min-width: 18px;
        height: 18px;
        font-size: 13px;
        font-weight: 400;
        background-color: #eee;
        color: #040404;
        fill: #040404;
        max-width: 100%;
        border-radius: 9999px;
        padding: 0rem .5rem;
        margin: 0rem 0.1rem;
        line-height: 1.5;
        white-space: nowrap;
    }
    .pills-wrapper .add-more{
        min-width: auto;
        padding: 0px 5px;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
    }
    .pills-wrapper .all-pills{
        width: 125px;
        overflow-y: auto;
        text-align: right;
    }
    th.asc::after{
        content: '\u2193';
        margin-left: 5px;
    }
    th.desc::after{
        content: '\u2191';
        margin-left: 5px;
    }

    
`