import { getDimensionInSnaptrudeUnits, speckleRevitImport } from ".";
import { generateBrepForComponents } from "../../libs/brepOperations";
import BABYLON from "../babylonDS.module";
import { massDissector } from "../createBuilding/massDissector";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { Factory } from "../factoryTypes/meshFactory.module";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { Wall } from "../snaptrudeDS/wall.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  createCustomMeshWithSpeckleDataWithoutMaterial,
  createMaterialFromSpeckleData,
} from "./extraFunctions";
import { BasicWallComp } from "../../libs/basicCompProperties";
import { saveMaterialInBackend } from "../../libs/applyMaterialFuncs";
import { applyMaterialByBREP, copyMaterialData } from "../../libs/mats";

import { getCSGFormOfMesh, mmToSnaptrudeUnits, onSolid } from "../extrafunc";
import { getMaterialIndex } from "./extraFunctions";
import _ from "lodash";
import { isFloatEqual } from "../../libs/snapFuncs";
import stackedWallHelper from "../stackedWalls/stackedWallHelper";
import speckleConstants from "./speckleConstants";
import { getUnitNormalVectorV3CyclicCheck } from "../../libs/mathFuncs";
import { createSelectionBox, _getWallProfileForWallsWithDoor } from "./door";
import { checkStorey } from "./storey";

const createWallComponent = (type, thickness, wallTypeData) => {
  let wallComp = {
    _name: type,
    _type: "Wall",
    _id: "",
    _thickness: DisplayOperation.convertToDefaultDimension(thickness),
    _layers: [],
  };
  let layersData = wallTypeData,
    layers = [],
    material = [];

  if (layersData) {
    layersData.forEach((layer) => {
      layers.push({
        check: false,
        value: layer.material.name,
        subtype: "",
        thickness: layer.width,
        quantity: "volume",
        unit: "Cubic metres",
        core: layer.function === "Structure" ? true : false,
      });

      material.push({
        type: layer.material.name,
        value: layer.material.value,
      });
    });

    wallComp._layers = layers;

    return { wallComp, material };
  } else {
    wallComp = BasicWallComp;
    return { wallComp, material };
  }
};

const getWallThickness = (layersData) => {
  let thickness = 0,
    typeOfLayer;
  const getWidth = (type) => {
    let width = 0;
    let widthArr = layersData.filter((wt) => wt.function == type);
    for (let layer = 0; layer < widthArr.length; ++layer) {
      width += widthArr[layer].width;
    }
    return width;
  };

  const checkCoreLayer = (type) => {
    return layersData.filter((wt) => wt.function == type).length;
  };

  const PRIORITYLIST = [
    "Structure",
    "Substrate",
    "Insulation",
    "Finish1",
    "Finish2",
  ];
  for (let type = 0; type < PRIORITYLIST.length; ++type) {
    if (checkCoreLayer(PRIORITYLIST[type])) {
      thickness = getWidth(PRIORITYLIST[type]);
      typeOfLayer = PRIORITYLIST[type];
      break;
    }
  }
  if (typeOfLayer) {
    for (let i = 0; i < layersData.length; ++i) {
      if (layersData[i].function == typeOfLayer) {
        layersData[i].function = "Structure";
      }
    }
  } else {
    for (let layer = 0; layer < layersData.length; ++layer) {
      thickness += layersData[layer].width;
    }
  }

  return { thickness, layersData };
};

function straightWall(wallPointsData, wallTypeData) {
  let revitWallMap = {};
  let structure_id = store.activeLayer.structure_id;
  const structureCollection = StructureCollection.getInstance();
  const structure = structureCollection.getStructureById(structure_id);
  const level = structure.getLevelByName("01");
  let walls = [];
  let factory = new Factory();
  wallPointsData.forEach((wallPoints) => {
    try {
      var points = {
        innerCoords: wallPoints.innerCoords,
        outerCoords: wallPoints.outerCoords,
      };
      var wallMesh = factory.createWall("straight", points);

      if (!wallMesh) return;
      // sometimes points are collinear or coplanar, so no mesh

      wallMesh.storey = 1;
      let wall = new Wall(wallMesh, 1, structure_id);

      try {
        if (wallPoints.bottomCoords) {
          wall.adjustHeight(
            wallPoints.bottomCoords,
            wallPoints.topCoords,
            wallPoints.midY
          );
          // adjustMeshCoords(wallMesh, coords1, coords2, null, null);
          wall.setBottomCoords(wallPoints.bottomCoords.map(v => v.asArray()));
          wall.setTopCoords(wallPoints.topCoords.map(v => v.asArray()));
        }
      } catch (e) {
        console.warn(e);
      }
      let wallTypes = { Ext: "External", Int: "Internal", Parapet: "Parapet" };
      wall.properties.wallType = wallTypes[wallPoints.wallType];
      wall.properties.wallMaterialType =
        store.projectProperties.properties[
          "wallTypeProperty" + wallPoints.wallType
        ].getValue();
      wall.revitMetaData.elementId = wallPoints.revitData.id;
      wall.revitMetaData.family = wallPoints.revitData.wallType;
      wall.revitMetaData.layersData = wallPoints.revitData.layersData;
      wall.revitMetaData.isStackedWall = wallPoints.revitData.isStackedWall;
      wall.revitMetaData.stackedWallParentId =
        wallPoints.revitData.stackedWallParentId;
      wall.revitMetaData.isProfiledWall = wallPoints.revitData.isProfiledWall;
      wall.revitMetaData.bottomRefLine = wallPoints.revitData.bottomRefLine;
      wallPoints.createdWallUniqueId = wall.mesh.uniqueId;
      wall.setMidYHeight(wallPoints.midY);
      wall.assignProperties(true, wallPoints.wallComponent);
      wall.faceId = wallPoints.face;
      const storey = checkStorey(wallPoints.revitData?.level);
      wall.storey = storey?.value
      level.addWallToLevel(wall, false);
  
      if(storey){
        if (wall.storey !== storey?.value) {
          storey.addElement(wall);
        } 
      }else{
        StoreyMutation.assignStorey(wall);
      }
      generateBrepForComponents(wall.mesh);
      wall.originalWallMesh = BABYLON.SceneSerializer.SerializeMesh(wallMesh);
      wallMesh.structure_id = structure_id;
      wall.mesh.layerMaterials = wallPoints.layerMaterials;
      wall.mesh.wallComponent = wallPoints.wallComponent;

      if (wallPoints.revitData.id) revitWallMap[wallPoints.revitData.id] = wall;
      walls.push(wall);
      onSolid(wallMesh);
    } catch (e) {
      console.log(e);
    }
  });

  return { walls, revitWallMap };
}

const createWallsWithInsert = (
  curtainWallInserts,
  revitWallMap,
  curtainPanelsMullionsMap
) => {
  const wallsWithInsert = [];
  for (let wall in curtainWallInserts) {
    try {
      let mergedMesh, newWall, newWallMesh;
      const curtainWalls = curtainWallInserts[wall];
      let originalWall = revitWallMap[wall];
      if (!originalWall) continue;
      const originalWallId = originalWall.mesh.uniqueId;
      let oldWallMesh = originalWall.mesh;
      if (
        originalWall.revitMetaData.isProfiledWall &&
        !originalWall.revitMetaData.wallProfile
      ) {
        let _oldWallProfile = _getWallProfileForWallsWithDoor(originalWall);
        originalWall.revitMetaData.originalWallMeshPosition =
          new BABYLON.Vector3(
            oldWallMesh.position.x,
            oldWallMesh.position.y,
            oldWallMesh.position.z
          );
        originalWall.revitMetaData.wallProfile = _oldWallProfile
          ? _oldWallProfile
          : {};
      }

      for (let idx = 0; idx < curtainWalls.length; ++idx) {
        const curtainWallMeshes = [];
        const curtainWallElementIds = curtainWalls[idx];
        for (let w = 0; w < curtainWallElementIds.length; ++w) {
          const elementId = curtainWallElementIds[w];
          const element = curtainPanelsMullionsMap[elementId];
          if (element) {
            curtainWallMeshes.push(element);
          }
        }
        if (!curtainWallMeshes.length) continue;

        if (curtainWallMeshes.length > 1) {
          mergedMesh = BABYLON.Mesh.MergeMeshes(
            curtainWallMeshes,
            false,
            true,
            undefined,
            true,
            false
          );
        } else {
          mergedMesh = curtainWallMeshes[0];
        }

        const wallThickness = originalWall.calculateWidth() + 3;
        const selectionBox = createSelectionBox(mergedMesh, originalWall);
        let curtainWallsCSG = getCSGFormOfMesh(selectionBox);
        let wallCSG = getCSGFormOfMesh(oldWallMesh);
        let newCSG = wallCSG.subtract(curtainWallsCSG);
        newWallMesh = newCSG.toSnaptrudeMesh("wall", null, store.scene);
        newWallMesh.position = oldWallMesh.position;
        newWallMesh.storey = oldWallMesh.storey;
        if (curtainWallMeshes.length > 1) {
          mergedMesh.dispose();
        }
        if (oldWallMesh.uniqueId != originalWallId) oldWallMesh.dispose(); // delete extra wall meshes created through csg
        selectionBox.dispose(); // delete selection box
        oldWallMesh = newWallMesh;
      }
      if (newWallMesh) {
        newWall = new Wall(newWallMesh, oldWallMesh.room_id);
        reApplyOriginalProperties(newWall, originalWall.mesh);
        wallsWithInsert.push(newWall.mesh);
        revitWallMap[wall] = newWall;
      }
    } catch (e) {
      console.log(e);
    }
  }
  onSolid();
  return { wallsWithInsert };
};

const reApplyOriginalProperties = (wall, oldWallMesh) => {
  const structures = StructureCollection.getInstance();
  const str = structures.getStructureById(store.activeLayer.structure_id);
  const level = str.getLevelByName("01");
  let newWallMesh = wall.mesh;
  newWallMesh.type = "wall";
  wall.assignProperties();
  let oldWallDS = oldWallMesh.getSnaptrudeDS();

  wall.wThickness = oldWallDS.wThickness;
  wall.localLineSegment = oldWallDS.localLineSegment;
  wall.originalWallMesh = oldWallDS.originalWallMesh;

  // Storing brep in originalWallMesh. Needed after deletion of fenestration
  if (oldWallDS.brep) {
    wall.originalWallMesh.brep = store.resurrect.stringify(oldWallDS.brep);
  }

  wall.neighbours = oldWallDS.neighbours;
  wall.neighboursDetails = oldWallDS.neighboursDetails;
  wall.setTopCoords(oldWallDS.topCoords);
  wall.setBottomCoords(oldWallDS.bottomCoords);
  wall.setMidYHeight(oldWallDS.midY);
  wall.direction = oldWallDS.direction;
  wall.bottomLineSegment = oldWallDS.bottomLineSegment;
  wall.room_id = oldWallDS.room_id;
  wall.profile = oldWallDS.profile;
  wall.topLineSegment = oldWallDS.topLineSegment;
  wall.properties = oldWallDS.properties;
  wall.revitMetaData = oldWallDS.revitMetaData;
  wall.revitMetaData.containsCurtainWall = true;
  wall.mesh.containsCurtainWall = true;
  // wall.mesh.uniqueId = oldWallDS.mesh.uniqueId

  if (!wall.brep) {
    copyMaterialData(oldWallMesh, newWallMesh);
  }

  wall.mesh.structure_id = oldWallMesh.structure_id;
  level.removeObjectToLevel(oldWallMesh.getSnaptrudeDS());
  level.addWallToLevel(wall, false);
  newWallMesh.childrenComp = oldWallMesh.childrenComp;
  oldWallMesh.dispose();
};

const createCurvedWalls = (data, meshData, wallVertexData, wallFaceData) => {
  let structure_id = store.activeLayer.structure_id;
  const structureCollection = StructureCollection.getInstance();
  const structure = structureCollection.getStructureById(structure_id);
  const level = structure.getLevelByName("01");
  let curvedWallDS,
    curvedWallMesh,
    curvedWalls = [];

  for (let w = 0; w < data.length; ++w) {
    curvedWallMesh = createCustomMeshWithSpeckleDataWithoutMaterial(
      data[w],
      meshData,
      wallVertexData,
      wallFaceData
    );

    curvedWallDS = new Wall(curvedWallMesh, 1, structure_id);
    curvedWallDS.structure_id = structure_id;
    curvedWallMesh.structure_id = structure_id;
    curvedWallMesh.storey = 1;
    curvedWallDS.revitMetaData.elementId = data[w].elementId;
    curvedWallDS.revitMetaData.family = data[w].family;
    level.addWallToLevel(curvedWallDS, false);
    StoreyMutation.assignStorey(curvedWallDS);

    // curvedWallDS.revitMetaData.layersData = wallPoints.revitData.layersData;
    curvedWalls.push(curvedWallMesh);
  }

  return curvedWalls;
};

const createWalls = (
  wallsData,
  wallTypeData,
  materialList,
  wallSideFacesData
) => {
  let wallMaterialCommands = [];
  let wallsMesh = [];
  wallsData = wallsData.filter(
    (wall) =>
      wall.speckle_type ===
        "Objects.BuiltElements.Wall:Objects.BuiltElements.Revit.RevitWall" &&
      wall.family !== "Curtain Wall" &&
      wall.baseLine.start
  );

  let { wallPoints, removeEdges } = createWallsPoints(
    wallsData,
    wallTypeData,
    wallSideFacesData
  );

  let doorWindowReferences = []

  wallsData.forEach(wall => {
    if(wall.elements){
      doorWindowReferences.push(...wall.elements.map(element => element.referencedId))
    }
  })

  let massWallPointsMapping = new Map();
  massWallPointsMapping.set(null, wallPoints);

  try {
    massDissector.findAndFixOverlappingWalls(massWallPointsMapping);
    massDissector.allotJunctionOccupationRights();
  } catch (e) {
    console.warn(e);
  }
  let wallData;
  massWallPointsMapping.forEach((wallPoints, mass) => {
    try {
      wallData = massDissector.handleWallJunctions(wallPoints, {
        isRevitImport: true,
      });
    } catch (e) {
      console.warn(e);
    }
  });

  /*
  Add stacked walls
  
  revitData won't be added to the wall
  Thus, it'll be treated like a new wall while exporting back to revit
   */

  wallData.forEach((wallPoints) => {
    if (wallPoints.revitData?.isStackedWall) {
      const pointsToGenerateTheTopSectionOfStackedWall = {
        outerCoords: null,
        innerCoords: null,
        wallType: wallPoints.wallType,
        wallComponent: wallPoints.wallComponent,
        layerMaterials: wallPoints.layerMaterials,
        revitData: {
          isStackedWall: true,
          stackedWallParentId: wallPoints.revitData.id,
          level:wallPoints.revitData.level,
        },
      };

      const lowerYStart = wallPoints.bottomCoords[0][2];
      const lowerYEnd = lowerYStart + wallPoints.revitData.lowerHeight;

      const upperYStart = lowerYEnd;
      const upperYEnd = lowerYStart + wallPoints.revitData.fullHeight;

      const yCoordinateUpdater = (coords) => {
        const upperCoords = [...coords];
        if (isFloatEqual(upperCoords[2], lowerYStart)) {
          upperCoords[2] = upperYStart;
        } else if (isFloatEqual(upperCoords[2], lowerYEnd)) {
          upperCoords[2] = upperYEnd;
        }

        return upperCoords;
      };

      pointsToGenerateTheTopSectionOfStackedWall.outerCoords =
        wallPoints.outerCoords.map(yCoordinateUpdater);
      pointsToGenerateTheTopSectionOfStackedWall.innerCoords =
        wallPoints.innerCoords.map(yCoordinateUpdater);

      wallData.push(pointsToGenerateTheTopSectionOfStackedWall);
    }
  });

  let { walls, revitWallMap } = straightWall(wallData, wallTypeData);

  stackedWallHelper.populate(walls);

  let structure_id = store.activeLayer.structure_id;
  const structureCollection = StructureCollection.getInstance();
  const structure = structureCollection.getStructureById(structure_id);
  const level = structure.getLevelByName("01");

  virtualSketcher.addWithoutGeometryEdit(walls);

  const bottomStackedWalls = [];

  walls.forEach((wall) => {
    try {
      if (
        wall.mesh.layerMaterials.length > 0 &&
        wall.mesh.layerMaterials[0].type != "none"
      ) {
        let wallMat;

        if (
          wall.revitMetaData.isStackedWall &&
          !wall.revitMetaData.stackedWallParentId
        ) {
          wall.properties.wallMaterialType = "GLASS";
          wall.updateDefaultMaterial({ returnCommand: false });
          return;
        }

        wallMat = store.scene.getMaterialByName(
          `${wall.mesh.layerMaterials[0].value?.name}`
        );
        if (!wallMat) {
          if (wall.mesh.layerMaterials[0].value?.name) {
            wallMat = createMaterialFromSpeckleData(
              wall.mesh.layerMaterials[0],
              wall.mesh.layerMaterials[0].value?.name,
              materialList,
              { category: wall.mesh.name }
            );
          }
        }
        if (wallMat) {
          let materialIndex,
            isNewMaterial = false;
          if (wall.mesh.getSnaptrudeDS()) {
            let object = wall.mesh.getSnaptrudeDS();
            if (wall.mesh.layerMaterials.length === 1) {
              ({ materialIndex, isNewMaterial } = getMaterialIndex(
                wall.mesh,
                wallMat
              ));
              wall.mesh.subMeshes.forEach(function (subMesh) {
                subMesh.materialIndex = materialIndex;
              });
              if (object.brep) {
                let faces = object.brep.getFaces();
                faces.forEach(function (face) {
                  face.materialIndex = materialIndex;
                });
              }
            } else if (wall.mesh.layerMaterials.length > 1) {
              ({ materialIndex, isNewMaterial } = getMaterialIndex(
                wall.mesh,
                wallMat
              ));
              // applying same material for both interior and exterior layer
              applyMaterialByBREP(wall.mesh, 0, "", "Paint", {
                mat_index: materialIndex,
                revitImport: true,
              });
              applyMaterialByBREP(wall.mesh, 1, "", "Paint", {
                mat_index: materialIndex,
                revitImport: true,
              });
              applyMaterialByBREP(wall.mesh, 3, "", "Paint", {
                mat_index: materialIndex,
                revitImport: true,
              });
              applyMaterialByBREP(wall.mesh, 5, "", "Paint", {
                mat_index: materialIndex,
                revitImport: true,
              });
            }
            // if (wall.mesh.material && isNewMaterial) {
            //   saveMaterialInBackend(wall.mesh.material);
            // }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  });

  // bottomStackedWalls.forEach((wall) => {
  //   wall.properties.wallMaterialType = "GLASS";
  //   const { cmds } = wall.updateDefaultMaterial({ returnCommand: true });
  //   wallMaterialCommands.push(...cmds);
  // });

  walls.forEach((wall) => {
    wallsMesh.push(wall.mesh);
  });
  return { wallsMesh, revitWallMap };
};

const filterProfiledWalls = function (wallsData, wallSideFacesData) {
  let _profiledWalls = [];

  let _getBottomVertices = function (allVertices) {
    let leastY = allVertices[0][2];
    let bottomVertices = [];

    for (let i = 0; i < allVertices.length; i++) {
      if (leastY > allVertices[i][2]) {
        leastY = allVertices[i][2];
      }
    }

    allVertices.forEach((v) => {
      if (v[2].toFixed(2) === leastY.toFixed(2)) {
        bottomVertices.push(v);
      }
    });

    return bottomVertices;
  };

  if (!_.isEmpty(wallSideFacesData)) {
    for (let j = 0; j < wallsData.length; j++) {
      let _elementId = wallsData[j].elementId;
      if (wallSideFacesData[_elementId]) {
        wallsData[j].profiledWallsInformation = {
          sideFaceVertices: wallSideFacesData[_elementId],
          bottomVertices: _getBottomVertices(wallSideFacesData[_elementId]),
        };
      }
    }
  }

  return wallsData;
};

const createWallsPoints = (wallsData, wallTypeData, wallSideFacesData) => {
  let wallPoints = []; // array of wall
  let removeEdges = [];

  const stackedWallData = speckleRevitImport.getStackedWallsData();
  wallsData = filterProfiledWalls(wallsData, wallSideFacesData);

  wallsData.forEach((wall) => {
    try {
      const i = _.findIndex(
        stackedWallData.familyNames,
        (name) => name === wall.type
      );
      const isStackedWall = i !== -1;
      let lowerStackedHeight;
      if (isStackedWall) {
        lowerStackedHeight = stackedWallData.heights[i];
        wall.profiledWallsInformation = {}; // if stacked wall, don't create it as profile wall
      }

      let width = wall.parameters.WALL_ATTR_WIDTH_PARAM?.value
        ? wall.parameters.WALL_ATTR_WIDTH_PARAM?.value
        : 25;
      const unit = wall?.units ? wall?.units : "mm";
      width = DisplayOperation.getOriginalDimension(
        width,
        speckleConstants.speckleUnits[unit]
      );

      const wallLevel = wall?.level?.name

      let wallType = wall.parameters.FUNCTION_PARAM.value ? "Ext" : "Int";
      let wallRevitType = wall.type;

      let thickness, layersData;

      for (let i = 0; i < wallTypeData.length; ++i) {
        if (wallTypeData[i][wall.type]) {
          layersData = JSON.parse(JSON.stringify(wallTypeData[i][wall.type]));
          break;
        }
      }

      let revitLayerData = layersData?.length
        ? JSON.parse(JSON.stringify(layersData))
        : {};
      if (layersData) {
        ({ thickness, layersData } = getWallThickness(layersData));
      }

      const { wallComp, material } = createWallComponent(
        wall.type,
        width,
        layersData
      );

      if (thickness == 0 || isStackedWall) {
        // for stacked walls, thickness determined based on layers is incorrect
        // so using speckle provided data directly
        thickness = width;
      } else {
        thickness = DisplayOperation.getOriginalDimension(
          thickness,
          "millimeter"
        );
      }

      const fullHeight = speckleConstants.convertIntomm(wall.height, unit);
      //
      // let _acceptableThicknessOfProfiledWall = DisplayOperation.getOriginalDimension(15, "millimeter");
      // if(thickness > _acceptableThicknessOfProfiledWall ){
      //   wall.profiledWallsInformation = {};
      // }

      let { points, removeEdge } = getWallCoordinates(
        wall.baseLine,
        lowerStackedHeight || fullHeight,
        thickness,
        { wallType, wallRevitType, revitLayerData },
        wall.elementId,
        { units: unit },
        wall.profiledWallsInformation ? wall.profiledWallsInformation : {}
      );

      points.wallComponent = wallComp;
      points.layerMaterials = material;
      points.revitData.level = wallLevel
      if (isStackedWall) {
        points.revitData.isStackedWall = isStackedWall;
        points.revitData.fullHeight = mmToSnaptrudeUnits(fullHeight);
        points.revitData.lowerHeight = mmToSnaptrudeUnits(lowerStackedHeight);
      }
      points.revitData.isProfiledWall = !!wall.profiledWallsInformation;

      removeEdges.push(removeEdge);

      wallPoints.push(points);
    } catch (e) {
      console.log(e);
    }
  });
  return { wallPoints, removeEdges };
};

let getWallCoordinates = (
  baseline,
  height,
  thickness,
  { wallType, wallRevitType, revitLayerData },
  id,
  options = { units: "mm" },
  profiledWallsInformation
) => {
  let points = [];
  let vertices = [];
  let removeEdge = [];
  let isProfiledWall = false;
  height = DisplayOperation.getOriginalDimension(height, "millimeter"); // height of stack wall or normal wall (Always in mm)

  if (
    !_.isEmpty(profiledWallsInformation) &&
    profiledWallsInformation.sideFaceVertices
  ) {
    let _sideFacePoints = profiledWallsInformation.sideFaceVertices; // middle face of the wall
    _sideFacePoints = _sideFacePoints.map((p) => [p[0], p[2], p[1]]);
    points = getDimensionInSnaptrudeUnits(
      _sideFacePoints,
      "millimeter"
    );
    isProfiledWall = true;
  } else {
    points.push([baseline.start.x, baseline.start.z, baseline.start.y]);
    points.push([baseline.end.x, baseline.end.z, baseline.end.y]);
    points.push([baseline.end.x, baseline.end.z, baseline.end.y]);
    points.push([baseline.start.x, baseline.start.z, baseline.start.y]);
    // to change unit given by speckle to snaptrude unit
    points = getDimensionInSnaptrudeUnits(
      points,
      speckleConstants.speckleUnits[options.units]
    );

    // Create upper points of walls

    points[2][1] += height;
    points[3][1] += height; // add height component to wall face
  }

  let _baseLinePoints = [];
  _baseLinePoints.push([baseline.start.x, baseline.start.z, baseline.start.y]);
  _baseLinePoints.push([baseline.end.x, baseline.end.z, baseline.end.y]);
  _baseLinePoints = getDimensionInSnaptrudeUnits(_baseLinePoints, speckleConstants.speckleUnits[options.units]);

  let _baseLineVectors = [];
  _baseLinePoints.forEach((point) => {
    _baseLineVectors.push(new BABYLON.Vector3(point[0], point[1], point[2]));
  });

  let _bottomCenterLine = [_baseLinePoints[0], _baseLinePoints[1]]

  // console.log(_baseLinePoints);

  points.forEach((point) => {
    vertices.push(new BABYLON.Vector3(point[0], point[1], point[2]));
  });

  if (!_.isEmpty(vertices)) {
    let wallPoints = virtualSketcher.coreGraphOperations.addIndividualEdge(
      _baseLineVectors[0],
      _baseLineVectors[1],
      null
    );

    removeEdge = [vertices[0], vertices[1]];
  }
  const bottomRefLine = [
    [baseline.start.x, baseline.start.y, baseline.start.z],
    [baseline.end.x, baseline.end.y, baseline.end.z],
  ];
  points = massDissector.getWallPoints(vertices, wallType, {
    isMasslessWallGeneration: true,
    storey: 1,
    thickness: thickness,
    revitData: {
      id: id,
      wallType: wallRevitType,
      layersData: revitLayerData,
      isProfiledWall: isProfiledWall,
      bottomRefLine,
      bottomCenterLine : _baseLineVectors,
      height
    },
  });

  return { points, removeEdge };
};

const getWallDataFromBaseObject = (data) => {
  let walls = [];
  data.forEach((wall) => {
    walls.push(wall.referencedId);
  });
  return walls;
};

const filterCurvedWalls = (wallsData) => {
  return wallsData.filter(
    (wall) =>
      wall.speckle_type ===
        "Objects.BuiltElements.Wall:Objects.BuiltElements.Revit.RevitWall" &&
      wall.family !== "Curtain Wall" &&
      !wall.baseLine.start
  );
};

export {
  createWallsPoints,
  createWalls,
  getWallDataFromBaseObject,
  filterCurvedWalls,
  createCurvedWalls,
  createWallsWithInsert,
};
