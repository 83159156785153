export const player = {
  playerStyles: {
    borderRadius: "10px",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
  },
  playerPlay: {
    backgroundColor: " rgba(255, 255, 255, 0.47)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    height: "50px",
    width: "50px",
    border: "none",
  },
  pauseParent: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pauseButton: {
    backgroundColor: " rgba(255, 255, 255, 0.47)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    height: "50px",
    width: "50px",
    border: "none",
  },
};
