import _ from "lodash";
import { store } from "./utilityFunctions/Store.js";
import { DisplayOperation } from "./displayOperations/displayOperation.js";
import { ScopeUtils } from "../libs/scopeFunctions.js";
import {
  showToast,
  setUserSetting,
  getLabelTypePosition,
  TOAST_TYPES,
} from "./extrafunc.js";
import { StructureCollection } from "./snaptrudeDS/structure.ds.js";
import { Roof } from "./snaptrudeDS/roof.ds.js";
import { commandUtils } from "./commandManager/CommandUtils.js";
import { Command } from "./commandManager/Command.js";
import { CommandManager } from "./commandManager/CommandManager.js";
import { constraintSolver } from "./meshoperations/moveOperations/constraintSolver.js";
import objectPropertiesView from "./objectProperties/objectPropertiesView.js";
import { dimensionsTuner } from "./sketchMassBIMIntegration/dimensionsTuner.js";

class ProjectProperties {
  constructor(obj = {}) {
    this.properties = {};
    this.properties.slabThicknessProperty = new SlabThicknessProperty(
      obj.slabThicknessProperty
    );
    // this.properties.wallThicknessProperty = new WallThicknessProperty(obj.wallThicknessProperty);
    this.properties.wallTypePropertyExt = new WallTypePropertyExt(
      obj.wallTypePropertyExt
    );
    this.properties.wallThicknessPropertyExt = new WallThicknessPropertyExt(
      obj.wallThicknessPropertyExt
    );
    this.properties.wallTypePropertyInt = new WallTypePropertyInt(
      obj.wallTypePropertyInt
    );
    this.properties.wallThicknessPropertyInt = new WallThicknessPropertyInt(
      obj.wallThicknessPropertyInt
    );
    this.properties.wallTypePropertyParapet = new WallTypePropertyParapet(
      obj.wallTypePropertyParapet
    );
    this.properties.wallThicknessPropertyParapet =
      new WallThicknessPropertyParapet(obj.wallThicknessPropertyParapet);
    this.properties.angleSnapThreshold = new AngleSnapThreshold(
      obj.angleSnapThreshold
    );
    this.properties.angleSnapEnabled = new AngleSnapEnabled(
      obj.angleSnapEnabled
    );
    this.properties.parallelSnapEnabled = new ParallelSnapEnabled(
      obj.parallelSnapEnabled
    );
    this.properties.orthogonalSnapEnabled = new OrthogonalSnapEnabled(
      obj.orthogonalSnapEnabled
    );
    this.properties.normalSnapEnabled = new NormalSnapEnabled(
      obj.normalSnapEnabled
    );
    this.properties.disableTooltips = new DisableTooltips(obj.disableTooltips);
    this.properties.constraintSolverThreshold = new ConstraintSolverThreshold(
      obj.constraintSolverThreshold
    );
    this.properties.plinthHeightProperty = new PlinthHeightProperty(
      obj.plinthHeightProperty
    );
    this.properties.ceilingHeightProperty = new CeilingHeightProperty(
      obj.ceilingHeightProperty
    );
    this.properties.toggleInternalDimensionsProperty = new ToggleInternalWallToWallDimensions(obj.toggleInternalDimensionsProperty);
    this.properties.toggleNearestPointSnap= new ToggleNearestPointSnap(obj.toggleNearestPointSnap);
    this.properties.wallProfileTypeProperty = new WallProfileTypeProperty(obj.wallProfileTypeProperty);
  }

  initialize(obj = {}) {
    for (let property in this.properties) {
      if (this.properties[property].initialize) {
        this.properties[property].initialize(obj[property]);
      }
    }
  }

  updateUI() {
    for (let property in this.properties) {
      if (this.properties[property].updateUI) {
        this.properties[property].updateUI();
      }
    }
  }

  getUIKeys() {
    let UIKeys = [];
    for (let property in this.properties) {
      let UIKey = this.properties[property].CONSTANTS().UIKey;
      if (UIKey) {
        UIKeys.push(UIKey);
      }
    }
    return UIKeys;
  }
}

class Property {
  constructor(value) {
    if (_.isNil(value)) {
      this.value = this.getDimension("VALUE_DEF");
    } else {
      this.value = value;
    }
    // this.updateUI();
  }

  CONSTANTS() {
    return {};
  }

  /**
   * Used to get Constant Values in User Units
   * @param {string} key
   */
  getDimension(key, unit) {
    let value = this.CONSTANTS().DIMENSIONS_MM[key];
    if (Number.isInteger(value)) {
      if (unit === "mm") {
        return value;
      } else {
        return DisplayOperation.getOriginalDimension(value, "millimeter");
      }
    } else {
      throw new Error("Value is not a Number \n Value: " + value);
    }
  }

  /**
   * Used to initialize the slab thickness property with saved value at reload
   * @param {number} value
   */
  initialize(value) {
    if (!_.isNil(value)) {
      this.value = value;
    }
  }

  /**
   * Formats the user input according to entered value.
   * Example - 150 will be made 150.00
   */
  formatInput(value) {
    if (_.isNil(value)) {
      value = this.getValue("userUnit");
    }

    const key = "userSetBIMProperties." + this.CONSTANTS().UIKey;

    ScopeUtils.applyAsync(function () {
      _.set(ScopeUtils.getScope(), key, value);
    });
  }

  changeValue() {
    this.formatInput();
  }

  checkRange(value, options = {}) {
    let threshold = (["feet-inches", "inches", "miles"].includes(ScopeUtils.getCurrentUnit())) ?
    DisplayOperation.getOriginalDimension(1, "inches") : DisplayOperation.getOriginalDimension(1, "millimeter");

    if((value >= this.getDimension("VALUE_MIN") - threshold) && (value <= this.getDimension("VALUE_MAX") + threshold))  return true;
    else {
      this.updateUI();
      let values = {
        dimension: ScopeUtils.getScope().units_type.name,
        lowerLimit: this.getDimension("VALUE_MIN"),
        upperLimit: this.getDimension("VALUE_MAX"),
      };

      if (!options.rawValues) {
        values.lowerLimit = DisplayOperation.convertToDefaultDimension(
          values.lowerLimit
        );
        values.upperLimit = DisplayOperation.convertToDefaultDimension(
          values.upperLimit
        );
      }

      let msg =
        "Provided value is out of the range : " +
        values.lowerLimit +
        " - " +
        values.upperLimit;
      let units = options.units || values.dimension;
      if (values.dimension !== "Feet-inches") {
        msg += " " + units;
      }
      showToast(msg, 3000, TOAST_TYPES.error);
      return false;
    }
  }

  updateUI() {
    ScopeUtils.getScope().userSetBIMProperties[this.CONSTANTS().UIKey] =
      this.getValue("userUnit");
  }

  getValue(type) {
    if (type === "userUnit")
      return DisplayOperation.convertToDefaultDimension(this.value);
    else {
      return this.value;
    }
  }
}

class SlabThicknessProperty extends Property {
  CONSTANTS() {
    return {
      DIMENSIONS_MM: {
        VALUE_MIN: 50,
        VALUE_DEF: 150,
        VALUE_MAX: 2100,
      },
      UIKey: "slabThicknessProperty",
    };
  }

  /**
   * Public function to change the thickness of slabs. Value taken in in user units
   * @param {string} value
   * @param {*} options
   */
  changeValue(value, options = {}) {
    let valueSnapDim;
    if(options.valueInSnapDim)  valueSnapDim = value ;
    else valueSnapDim = DisplayOperation.getOriginalDimension(value);


    if (valueSnapDim === this.value) return;

    function execute() {
      store.projectProperties.properties.slabThicknessProperty.value =
        this.data.newValue;
    }

    function unexecute() {
      store.projectProperties.properties.slabThicknessProperty.value =
        this.data.oldValue;
    }

    if (this.checkRange(valueSnapDim)) {
      let oldValue = this.value;
      this.value = valueSnapDim;
      let cmds = [],
        yets = [];
      let levelID,
        levels = StructureCollection.getInstance()
          .getStructures()
          [store.activeLayer.structure_id].getAllLevels();
      for (levelID in levels) {
        levels[levelID].getRoofs().forEach((roof) => {
          if (roof.slabType != Roof.CONSTANTS().SLAB_TYPES.PLINTH) {
            let roofMesh = roof.mesh,
              toExes;
            if (!roof.isEdited()) {
              toExes = DisplayOperation.updateDimensionScale(
                valueSnapDim,
                "height",
                DisplayOperation.getOriginalDimension(
                  objectPropertiesView.getMeshDimensions(roofMesh).height
                ),
                roofMesh,
                { returnCommand: true }
              );
              cmds.push(...toExes.cmd);
              yets.push(...toExes.yetToExecutes);
            }
          }
        });
      }

      setUserSetting("slabThicknessProperty", valueSnapDim);
      let uiDataPoint = [
        "userSetBIMProperties.slabThicknessProperty",
        DisplayOperation.convertToDefaultDimension(oldValue),
        DisplayOperation.convertToDefaultDimension(valueSnapDim),
      ];
      let saveDataPoint = ["slabThicknessProperty", oldValue, valueSnapDim];
      let cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
        uiDataPoint,
        saveDataPoint
      );
      let cmdUI = commandUtils.changeInUIOperations.getCommand(
        "changeProjectSlabThicknessUI",
        cmdDataUI
      );
      cmds.push(cmdUI);
      yets.push(false);

      let data = { oldValue: oldValue, newValue: valueSnapDim };
      cmds.push(
        new Command("changeProjectSlabThickness", data, {
          execute: execute,
          unexecute: unexecute,
        })
      );
      yets.push(false);
      if (!options.doNotExecuteCmd) {
        CommandManager.execute(cmds, yets);
      }

      super.changeValue();
    }
  }
}

class WallThicknessProperty extends Property {
  CONSTANTS() {
    return {
      DIMENSIONS_MM: {
        VALUE_MIN: 10,
        VALUE_DEF: 200,
        VALUE_MAX: 10000,
      },
      // UIKey: "wallThicknessProperty"
    };
  }

  /**
   * Public function to change the thickness of slabs. Value taken in in user units
   * @param {string} value
   * @param {*} options
   */
  changeValue(value, options = {}) {
    let valueSnapDim = DisplayOperation.getOriginalDimension(value);

    if (valueSnapDim === this.value) return;

    function execute() {
      store.projectProperties.properties[this.data.UIKey].value =
        this.data.newValue;
    }

    function unexecute() {
      store.projectProperties.properties[this.data.UIKey].value =
        this.data.oldValue;
    }

    if (this.checkRange(valueSnapDim)) {
      let oldValue = this.value;
      this.value = valueSnapDim;

      // return;

      let cmds = [],
        yets = [];

      // let levelID, levels = StructureCollection.getInstance().getStructures()[activeLayer.structure_id].getAllLevels();
      // for (levelID in levels){
      //     levels[levelID].getWalls().forEach(wall => {
      //         let wallMesh = wall.mesh, toExes, wallDimensions = objectPropertiesView.getMeshDimensions(wallMesh), thickness;
      //         let breadth = DisplayOperation.getOriginalDimension(wallDimensions.breadth), depth = DisplayOperation.getOriginalDimension(wallDimensions.depth);
      //         if (breadth > depth)  thickness = depth;
      //         else    thickness = breadth;
      //         if(thickness === oldValue){
      //         // let a = true;
      //         // if(a){
      //             toExes = DisplayOperation.updateDimensionScale(valueSnapDim, "thickness", thickness, wallMesh, {returnCommand: true});
      //             cmds.push(...toExes.cmd);
      //             yets.push(...toExes.yetToExecutes);
      //         }
      //     });
      // }

      if (!options.codeInitiated){
        /*
        if (this.CONSTANTS().UIKey === "wallThicknessPropertyExt") {
          let levelID,
            levels = StructureCollection.getInstance()
              .getStructures()
              [store.activeLayer.structure_id].getAllLevels();
          for (levelID in levels) {
            levels[levelID].getWalls().forEach((wall) => {
              if (!wall.properties.createdByCB) {
                let wallMesh = wall.mesh,
                  toExes,
                  wallDimensions =
                    objectPropertiesView.getMeshDimensions(wallMesh),
                  thickness;
                let breadth = DisplayOperation.getOriginalDimension(
                    wallDimensions.breadth
                  ),
                  depth = DisplayOperation.getOriginalDimension(
                    wallDimensions.depth
                  );
                if (breadth > depth) thickness = depth;
                else thickness = breadth;
                if (thickness === oldValue) {
                  toExes = DisplayOperation.updateDimensionScale(
                    valueSnapDim,
                    "thickness",
                    thickness,
                    wallMesh,
                    { returnCommand: true }
                  );
                  if (toExes) {
                    cmds.push(...toExes.cmd);
                    yets.push(...toExes.yetToExecutes);
                  }
                }
              }
            });
          }
          // if(this.value > 50 && oldValue <= 50)
          //     cmds.push(projectProperties.properties.wallTypePropertyExt.changeValue(projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES.GLASS));
          // else if(this.value <=50 && oldValue > 50)
          //     cmds.push(projectProperties.properties.wallTypePropertyExt.changeValue(projectProperties.properties.wallTypePropertyExt.CONSTANTS().VALID_TYPES.BRICK));
        }
         */

        /*
        21/10/22
        Disabling changing type upon changing thickness
         */

        let newWallType;
        /*let thresholdInBabylonUnits = DisplayOperation.getOriginalDimension(
          50,
          "millimeter"
        );
        if (
          this.value > thresholdInBabylonUnits &&
          oldValue <= thresholdInBabylonUnits
        )
          newWallType =
            store.projectProperties.properties.wallTypePropertyExt.CONSTANTS()
              .VALID_TYPES.BRICK;
        else if (
          this.value <= thresholdInBabylonUnits &&
          oldValue > thresholdInBabylonUnits
        )
          newWallType =
            store.projectProperties.properties.wallTypePropertyExt.CONSTANTS()
              .VALID_TYPES.GLASS;*/
        if (newWallType) {
          let command = store.projectProperties.properties[
          "wallTypeProperty" + this.CONSTANTS().UIKey.substring(21)
            ].changeValue(newWallType, {
            returnCommand: true,
            codeInitiated: true,
          });
          if (command) {
            cmds.push(...command.cmds);
            yets.push(...command.yets);
          }
        }
      }

      this.updateUI();
      setUserSetting(this.CONSTANTS().UIKey, valueSnapDim);
      let uiDataPoint = [
        "userSetBIMProperties." + this.CONSTANTS().UIKey,
        DisplayOperation.convertToDefaultDimension(oldValue),
        DisplayOperation.convertToDefaultDimension(valueSnapDim),
      ];
      let saveDataPoint = [this.CONSTANTS().UIKey, oldValue, valueSnapDim];
      let cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
        uiDataPoint,
        saveDataPoint
      );
      let cmdUI = commandUtils.changeInUIOperations.getCommand(
        "change" + this.CONSTANTS().UIKey + "UI",
        cmdDataUI
      );
      cmds.push(cmdUI);
      yets.push(false);

      let data = {
        oldValue: oldValue,
        newValue: valueSnapDim,
        UIKey: this.CONSTANTS().UIKey,
      };
      cmds.push(
        new Command("change" + this.CONSTANTS().UIKey, data, {
          execute: execute,
          unexecute: unexecute,
        })
      );
      yets.push(false);
      if (!options.returnCommand) {
        CommandManager.execute(cmds, yets);
      } else {
        return { cmds: cmds, yets: yets };
      }
    }
  }
}

class WallThicknessPropertyExt extends WallThicknessProperty {
  CONSTANTS() {
    let superConst = super.CONSTANTS();
    superConst.DIMENSIONS_MM.VALUE_DEF = 200;
    superConst.UIKey = "wallThicknessPropertyExt";
    return superConst;
  }
}

class WallThicknessPropertyInt extends WallThicknessProperty {
  CONSTANTS() {
    let superConst = super.CONSTANTS();
    superConst.DIMENSIONS_MM.VALUE_DEF = 100;
    superConst.UIKey = "wallThicknessPropertyInt";
    return superConst;
  }
}

class WallThicknessPropertyParapet extends WallThicknessProperty {
  CONSTANTS() {
    let superConst = super.CONSTANTS();
    superConst.DIMENSIONS_MM.VALUE_DEF = 25;
    superConst.UIKey = "wallThicknessPropertyParapet";
    return superConst;
  }
}

class WallTypeProperty extends Property {
  CONSTANTS() {
    return {
      VALID_TYPES: {
        BRICK: "Brick Wall",
        CONCRETE: "Concrete Wall",
        WOOD: "Wood Stud Wall",
        GLASS: "Glass Curtain Wall",
      },
      // OPTIONS: ["Brick Wall with Plaster and Paint", "Concrete Wall with Plaster and Paint", "Wood Stud Wall with Ply and Sheathing", "Glass Curtain wall"]
      // OPTIONS: ["BRICK", "CONCRETE", "WOOD", "GLASS"]
      // UIKey: "wallThicknessProperty"
    };
  }

  getValue(type) {
    if (type === "userUnit") return this.CONSTANTS().VALID_TYPES[this.value];
    else {
      return this.value;
    }
  }

  getDimension(key) {
    return this.CONSTANTS()[key];
  }

  /**
   * Public function to change the thickness of slabs. Value taken in in user units
   * @param {string} value
   * @param {*} options
   */
  changeValue(value, options = {}) {
    // let strToValueDict = {};
    let reverseTypeMapping = {};
    for (let key in this.CONSTANTS().VALID_TYPES) {
      reverseTypeMapping[this.CONSTANTS().VALID_TYPES[key]] = key;
    }
    let valueSnapDim = reverseTypeMapping[value];
    // let valueSnapDim = DisplayOperation.getOriginalDimension(value);
    if (!valueSnapDim) return;
    if (valueSnapDim === this.value) return;

    function execute() {
      store.projectProperties.properties[this.data.UIKey].value =
        this.data.newValue;
    }

    function unexecute() {
      store.projectProperties.properties[this.data.UIKey].value =
        this.data.oldValue;
    }

    let oldValue = this.value;
    this.value = valueSnapDim;

    let cmds = [],
      yets = [];

    let newThickness = 0;
    if (this.value === "GLASS")
      newThickness = DisplayOperation.convertToDefaultDimension(
        DisplayOperation.getOriginalDimension(25, "millimeter")
      );
    else if (this.value === "WOOD")
      newThickness = DisplayOperation.convertToDefaultDimension(
        DisplayOperation.getOriginalDimension(125, "millimeter")
      );
    else if (
      ["BRICK", "CONCRETE"].includes(this.value) &&
      ["WOOD", "GLASS"].includes(oldValue)
    ) {
      if (this.CONSTANTS().UIKey === "wallTypePropertyExt")
        newThickness = DisplayOperation.convertToDefaultDimension(
          DisplayOperation.getOriginalDimension(200, "millimeter")
        );
      else if (
        ["wallTypePropertyInt", "wallTypePropertyParapet"].includes(
          this.CONSTANTS().UIKey
        )
      )
        newThickness = DisplayOperation.convertToDefaultDimension(
          DisplayOperation.getOriginalDimension(100, "millimeter")
        );
    }
    if (newThickness && !options.codeInitiated) {
      let command = store.projectProperties.properties[
        "wallThicknessProperty" + this.CONSTANTS().UIKey.substring(16)
      ].changeValue(newThickness, { returnCommand: true, codeInitiated: true });
      if (command) {
        cmds.push(...command.cmds);
        yets.push(...command.yets);
      }
    }
    // cmds.push(projectProperties.properties["wallThicknessProperty" + this.CONSTANTS().UIKey.substring(16)].changeValue(newThickness, {returnCommand: true}));

    this.updateUI();
    setUserSetting(this.CONSTANTS().UIKey, valueSnapDim);
    let uiDataPoint = [
      "userSetBIMProperties." + this.CONSTANTS().UIKey,
      this.CONSTANTS().VALID_TYPES[oldValue],
      this.CONSTANTS().VALID_TYPES[valueSnapDim],
    ];
    let saveDataPoint = [this.CONSTANTS().UIKey, oldValue, valueSnapDim];
    let cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    let cmdUI = commandUtils.changeInUIOperations.getCommand(
      "change" + this.CONSTANTS().UIKey + "UI",
      cmdDataUI
    );
    cmds.push(cmdUI);
    yets.push(false);

    let data = {
      oldValue: oldValue,
      newValue: valueSnapDim,
      UIKey: this.CONSTANTS().UIKey,
    };
    cmds.push(
      new Command("change" + this.CONSTANTS().UIKey, data, {
        execute: execute,
        unexecute: unexecute,
      })
    );
    yets.push(false);
    if (!options.returnCommand) {
      CommandManager.execute(cmds, yets);
    } else {
      return { cmds: cmds, yets: yets };
    }
  }
}

class WallTypePropertyExt extends WallTypeProperty {
  CONSTANTS() {
    let superConst = super.CONSTANTS();
    superConst.VALUE_DEF = "BRICK";
    superConst.UIKey = "wallTypePropertyExt";
    return superConst;
  }
}

class WallTypePropertyInt extends WallTypeProperty {
  CONSTANTS() {
    let superConst = super.CONSTANTS();
    superConst.VALUE_DEF = "BRICK";
    superConst.UIKey = "wallTypePropertyInt";
    return superConst;
  }
}

class WallTypePropertyParapet extends WallTypeProperty {
  CONSTANTS() {
    let superConst = super.CONSTANTS();
    superConst.VALUE_DEF = "GLASS";
    superConst.UIKey = "wallTypePropertyParapet";
    return superConst;
  }
}

class AngleSnapThreshold extends Property {
  CONSTANTS() {
    return {
      DIMENSIONS_DEGREE: {
        VALUE_MIN: 5,
        VALUE_DEF: 15,
        VALUE_MAX: 90,
      },
      UIKey: "angleSnapThreshold",
    };
  }

  getDimension(key) {
    let value = this.CONSTANTS().DIMENSIONS_DEGREE[key];
    if (Number.isInteger(value)) {
      return value;
    } else {
      throw new Error("Value is not a Number \n Value: " + value);
    }
  }

  checkRange(value) {
    return super.checkRange(value, {
      rawValues: true,
      units: "degrees",
    });
  }

  getValue(type) {
    if (type === "userUnit") return this._format(super.getValue());
    else {
      return super.getValue();
    }
  }

  _format(value) {
    const degreeCharacter = String.fromCharCode(176);
    return "" + value + degreeCharacter;
  }

  formatInput() {
    super.formatInput(this._format(this.getValue()));
  }

  changeValue(value, options = {}) {
    const valueUserDim = parseFloat(value);
    const valueSnapDim = valueUserDim;

    if (valueSnapDim === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    if (this.checkRange(valueSnapDim)) {
      const oldValue = this.value;
      this.value = valueSnapDim;

      const cmds = [];
      const yets = [];

      setUserSetting(uiKey, valueSnapDim);

      const uiDataPoint = [
        "userSetBIMProperties." + uiKey,
        this._format(oldValue),
        this._format(valueUserDim),
      ];
      const saveDataPoint = [uiKey, oldValue, valueSnapDim];
      const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
        uiDataPoint,
        saveDataPoint
      );
      const cmdUI = commandUtils.changeInUIOperations.getCommand(
        "changeProjectPropUI" + uiKey,
        cmdDataUI
      );

      cmds.push(cmdUI);
      yets.push(false);

      const data = { oldValue: oldValue, newValue: valueSnapDim };

      cmds.push(
        new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
      );
      yets.push(false);

      if (!options.doNotExecuteCmd) {
        CommandManager.execute(cmds, yets);
      }

      super.changeValue();
    }
  }
}

class ConstraintSolverThreshold extends Property {
  CONSTANTS() {
    return {
      DIMENSIONS_MM: {
        VALUE_MIN: 10,
        VALUE_DEF: 10,
        VALUE_MAX: 1000,
      },
      UIKey: "constraintSolverThreshold",
    };
  }

  initialize(value) {
    if (!value) return;

    super.initialize(value);
    constraintSolver.setThreshold(value);
  }

  changeValue(value, options = {}) {
    const valueSnapDim = DisplayOperation.getOriginalDimension(value);

    if (valueSnapDim === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
      constraintSolver.setThreshold(this.data.newValue);
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
      constraintSolver.setThreshold(this.data.oldValue);
    };

    if (this.checkRange(valueSnapDim)) {
      const oldValue = this.value;
      this.value = valueSnapDim;

      const cmds = [];
      const yets = [];

      setUserSetting(uiKey, valueSnapDim);
      constraintSolver.setThreshold(valueSnapDim);

      const uiDataPoint = [
        "userSetBIMProperties." + uiKey,
        DisplayOperation.convertToDefaultDimension(oldValue),
        DisplayOperation.convertToDefaultDimension(valueSnapDim),
      ];
      const saveDataPoint = [uiKey, oldValue, valueSnapDim];
      const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
        uiDataPoint,
        saveDataPoint
      );
      const cmdUI = commandUtils.changeInUIOperations.getCommand(
        "changeProjectPropUI" + uiKey,
        cmdDataUI
      );

      cmds.push(cmdUI);
      yets.push(false);

      const data = { oldValue: oldValue, newValue: valueSnapDim };

      cmds.push(
        new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
      );
      yets.push(false);

      if (!options.doNotExecuteCmd) {
        CommandManager.execute(cmds, yets);
      }

      super.changeValue();
    }
  }
}

class AngleSnapEnabled extends Property {
  CONSTANTS() {
    return {
      VALUES: {
        VALUE_DEF: true,
      },
      UIKey: "angleSnapEnabled",
    };
  }

  getDimension(key) {
    let value = this.CONSTANTS().VALUES[key];
    if (_.isBoolean(value)) {
      return value;
    } else {
      throw new Error("Value is not a Boolean \n Value: " + value);
    }
  }

  getValue(type) {
    return super.getValue();
  }

  changeValue(value, options = {}) {
    const newValue = value;

    if (newValue === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    const oldValue = this.value;
    this.value = newValue;

    const cmds = [];
    const yets = [];

    setUserSetting(uiKey, newValue);

    const uiDataPoint = ["userSetBIMProperties." + uiKey, oldValue, newValue];
    const saveDataPoint = [uiKey, oldValue, newValue];
    const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    const cmdUI = commandUtils.changeInUIOperations.getCommand(
      "changeProjectPropUI" + uiKey,
      cmdDataUI
    );

    cmds.push(cmdUI);
    yets.push(false);

    const data = { oldValue: oldValue, newValue: newValue };

    cmds.push(
      new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
    );
    yets.push(false);

    if (!options.doNotExecuteCmd) {
      CommandManager.execute(cmds, yets);
    }
  }
}

class ParallelSnapEnabled extends Property {
  CONSTANTS() {
    return {
      VALUES: {
        VALUE_DEF: true,
      },
      UIKey: "parallelSnapEnabled",
    };
  }

  getDimension(key) {
    let value = this.CONSTANTS().VALUES[key];
    if (_.isBoolean(value)) {
      return value;
    } else {
      throw new Error("Value is not a Boolean \n Value: " + value);
    }
  }

  getValue(type) {
    return super.getValue();
  }

  changeValue(value, options = {}) {
    const newValue = value;

    if (newValue === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    const oldValue = this.value;
    this.value = newValue;

    const cmds = [];
    const yets = [];

    setUserSetting(uiKey, newValue);

    const uiDataPoint = ["userSetBIMProperties." + uiKey, oldValue, newValue];
    const saveDataPoint = [uiKey, oldValue, newValue];
    const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    const cmdUI = commandUtils.changeInUIOperations.getCommand(
      "changeProjectPropUI" + uiKey,
      cmdDataUI
    );

    cmds.push(cmdUI);
    yets.push(false);

    const data = { oldValue: oldValue, newValue: newValue };

    cmds.push(
      new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
    );
    yets.push(false);

    if (!options.doNotExecuteCmd) {
      CommandManager.execute(cmds, yets);
    }
  }
}

class NormalSnapEnabled extends Property {
  CONSTANTS() {
    return {
      VALUES: {
        VALUE_DEF: true,
      },
      UIKey: "normalSnapEnabled",
    };
  }

  getDimension(key) {
    let value = this.CONSTANTS().VALUES[key];
    if (_.isBoolean(value)) {
      return value;
    } else {
      throw new Error("Value is not a Boolean \n Value: " + value);
    }
  }

  getValue(type) {
    return super.getValue();
  }

  changeValue(value, options = {}) {
    const newValue = value;

    if (newValue === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    const oldValue = this.value;
    this.value = newValue;

    const cmds = [];
    const yets = [];

    setUserSetting(uiKey, newValue);

    const uiDataPoint = ["userSetBIMProperties." + uiKey, oldValue, newValue];
    const saveDataPoint = [uiKey, oldValue, newValue];
    const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    const cmdUI = commandUtils.changeInUIOperations.getCommand(
      "changeProjectPropUI" + uiKey,
      cmdDataUI
    );

    cmds.push(cmdUI);
    yets.push(false);

    const data = { oldValue: oldValue, newValue: newValue };

    cmds.push(
      new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
    );
    yets.push(false);

    if (!options.doNotExecuteCmd) {
      CommandManager.execute(cmds, yets);
    }
  }
}

class OrthogonalSnapEnabled extends Property {
  CONSTANTS() {
    return {
      VALUES: {
        VALUE_DEF: true,
      },
      UIKey: "orthogonalSnapEnabled",
    };
  }

  getDimension(key) {
    let value = this.CONSTANTS().VALUES[key];
    if (_.isBoolean(value)) {
      return value;
    } else {
      throw new Error("Value is not a Boolean \n Value: " + value);
    }
  }

  getValue(type) {
    return super.getValue();
  }

  changeValue(value, options = {}) {
    const newValue = value;

    if (newValue === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    const oldValue = this.value;
    this.value = newValue;

    const cmds = [];
    const yets = [];

    setUserSetting(uiKey, newValue);

    const uiDataPoint = ["userSetBIMProperties." + uiKey, oldValue, newValue];
    const saveDataPoint = [uiKey, oldValue, newValue];
    const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    const cmdUI = commandUtils.changeInUIOperations.getCommand(
      "changeProjectPropUI" + uiKey,
      cmdDataUI
    );

    cmds.push(cmdUI);
    yets.push(false);

    const data = { oldValue: oldValue, newValue: newValue };

    cmds.push(
      new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
    );
    yets.push(false);

    if (!options.doNotExecuteCmd) {
      CommandManager.execute(cmds, yets);
    }
  }
}

class DisableTooltips extends Property {
  CONSTANTS() {
    return {
      VALUES: {
        VALUE_DEF: false,
      },
      UIKey: "disableTooltips",
    };
  }

  getDimension(key) {
    let value = this.CONSTANTS().VALUES[key];
    if (_.isBoolean(value)) {
      return value;
    } else {
      throw new Error("Value is not a Boolean \n Value: " + value);
    }
  }

  getValue(type) {
    return super.getValue();
  }

  changeValue(value, options = {}) {
    const newValue = value;

    if (newValue === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    const oldValue = this.value;
    this.value = newValue;

    const cmds = [];
    const yets = [];

    setUserSetting(uiKey, newValue);

    const uiDataPoint = ["userSetBIMProperties." + uiKey, oldValue, newValue];
    const saveDataPoint = [uiKey, oldValue, newValue];
    const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    const cmdUI = commandUtils.changeInUIOperations.getCommand(
      "changeProjectPropUI" + uiKey,
      cmdDataUI
    );

    cmds.push(cmdUI);
    yets.push(false);

    const data = { oldValue: oldValue, newValue: newValue };

    cmds.push(
      new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
    );
    yets.push(false);

    if (!options.doNotExecuteCmd) {
      CommandManager.execute(cmds, yets);
    }
  }
}

class PlinthHeightProperty extends Property {
  CONSTANTS() {
    return {
      DIMENSIONS_MM: {
        VALUE_MIN: 10,
        VALUE_DEF: 300,
        VALUE_MAX: 50000,
      },
      UIKey: "plinthHeightProperty",
    };
  }

  /**
   * Public function to change the thickness of slabs. Value taken in in user units
   * @param {string} value
   * @param {*} options
   */
  changeValue(value, options = {}) {
    let valueSnapDim = DisplayOperation.getOriginalDimension(value);

    if (valueSnapDim === this.value) return;

    function execute() {
      store.projectProperties.properties.plinthHeightProperty.value =
        this.data.newValue;
      // axes.forEach(axis => axis.position.y = -this.data.newValue);
    }

    function unexecute() {
      store.projectProperties.properties.plinthHeightProperty.value =
        this.data.oldValue;
      // axes.forEach(axis => axis.position.y = -this.data.oldValue);
    }

    if (this.checkRange(valueSnapDim)) {
      let oldValue = this.value;
      this.value = valueSnapDim;
      let cmds = [],
        yets = [];
      let positionChangeCmdMeshes = [];

      let levelID,
        levels = StructureCollection.getInstance()
          .getStructures()
          [store.activeLayer.structure_id].getAllLevels();
      for (levelID in levels) {
        levels[levelID].getRoofs().forEach((roof) => {
          let roofMesh = roof.mesh,
            toExes;
          if (roof.storey === 1 && roof.slabType === "Plinth") {
            toExes = DisplayOperation.updateDimensionScale(
              valueSnapDim,
              "height",
              DisplayOperation.getOriginalDimension(
                objectPropertiesView.getMeshDimensions(roofMesh).height
              ),
              roofMesh,
              { returnCommand: true }
            );
            cmds.push(...toExes.cmd);
            yets.push(...toExes.yetToExecutes);
          }
        });
        levels[levelID].getMasses().forEach((mass) => {
          let massMesh = mass.mesh,
            toExes;
          if (mass.storey === 1 && mass.massType === "Plinth") {
            toExes = DisplayOperation.updateDimensionScale(
              valueSnapDim,
              "height",
              DisplayOperation.getOriginalDimension(
                objectPropertiesView.getMeshDimensions(massMesh).height
              ),
              massMesh,
              { returnCommand: true }
            );
            cmds.push(...toExes.cmd);
            yets.push(...toExes.yetToExecutes);
          }
          // else if(mass.storey === 1 && ["Ground", "Site", "Road"].includes(ScopeUtils.getRoomType(massMesh.room_type))){
          //     positionChangeCmdMeshes.push(massMesh);
          // }
        });
      }

      if (positionChangeCmdMeshes.length > 0) {
        let cmdOptions = {
          params: [commandUtils.worldMatrixChangeOperations.PARAMS.position],
          stack: positionChangeCmdMeshes,
        };
        let posChangeCmdData =
          commandUtils.worldMatrixChangeOperations.getCommandData(
            null,
            cmdOptions
          );
        positionChangeCmdMeshes.forEach(
          (mesh) =>
            (mesh.position.y = DisplayOperation.getOriginalDimension(
              getLabelTypePosition(
                ScopeUtils.getRoomType(mesh.room_type).toLowerCase()
              )
            ))
        );
        cmdOptions.data = posChangeCmdData;
        posChangeCmdData =
          commandUtils.worldMatrixChangeOperations.getCommandData(
            null,
            cmdOptions
          );
        let posChangeCmd = commandUtils.worldMatrixChangeOperations.getCommand(
          "Position Change",
          posChangeCmdData,
          cmdOptions
        );
        cmds.push(posChangeCmd);
        yets.push(false);
      }

      // axes.forEach(axis => axis.position.y = -valueSnapDim);

      setUserSetting("plinthHeightProperty", valueSnapDim);
      let uiDataPoint = [
        "userSetBIMProperties.plinthHeightProperty",
        DisplayOperation.convertToDefaultDimension(oldValue),
        DisplayOperation.convertToDefaultDimension(valueSnapDim),
      ];
      let saveDataPoint = ["plinthHeightProperty", oldValue, valueSnapDim];
      let cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
        uiDataPoint,
        saveDataPoint
      );
      let cmdUI = commandUtils.changeInUIOperations.getCommand(
        "changeProjectPlinthHeightUI",
        cmdDataUI
      );
      cmds.push(cmdUI);
      yets.push(false);

      let data = { oldValue: oldValue, newValue: valueSnapDim };
      cmds.push(
        new Command("changeProjectPlinthHeight", data, {
          execute: execute,
          unexecute: unexecute,
        })
      );
      yets.push(false);
      if (!options.doNotExecuteCmd) {
        CommandManager.execute(cmds, yets);
      }

      super.changeValue();
    }
  }
}

class CeilingHeightProperty extends Property {
  CONSTANTS() {
    return {
      DIMENSIONS_MM: {
        VALUE_MIN: 10,
        VALUE_DEF: 2400,
        VALUE_MAX: 50000,
      },
      UIKey: "ceilingHeightProperty",
    };
  }

  /**
   * Public function to change the thickness of slabs. Value taken in in user units
   * @param {string} value
   * @param {*} options
   */
  changeValue(value, options = {}) {
    let valueSnapDim = DisplayOperation.getOriginalDimension(value);

    if (valueSnapDim === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    function execute() {
      store.projectProperties.properties[uiKey].value =
        this.data.newValue;
    }

    function unexecute() {
      store.projectProperties.properties[uiKey].value =
        this.data.oldValue;
    }

    if (this.checkRange(valueSnapDim)) {
      let oldValue = this.value;
      this.value = valueSnapDim;

      let cmds = [], yets = [];

      setUserSetting(uiKey, valueSnapDim);
      let uiDataPoint = [
        "userSetBIMProperties." + uiKey,
        DisplayOperation.convertToDefaultDimension(oldValue),
        DisplayOperation.convertToDefaultDimension(valueSnapDim),
      ];

      let saveDataPoint = [uiKey, oldValue, valueSnapDim];
      let cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
        uiDataPoint,
        saveDataPoint
      );
      let cmdUI = commandUtils.changeInUIOperations.getCommand(
        "changeProjectProp" + uiKey + "UI",
        cmdDataUI
      );
      cmds.push(cmdUI);
      yets.push(false);

      let data = { oldValue: oldValue, newValue: valueSnapDim };
      cmds.push(
        new Command("changeProjectProp" + uiKey, data, {
          execute: execute,
          unexecute: unexecute,
        })
      );
      yets.push(false);
      if (!options.doNotExecuteCmd) {
        CommandManager.execute(cmds, yets);
      }

      super.changeValue();
    }
  }
}

class ToggleNearestPointSnap extends Property{

  CONSTANTS() {
      return {
          VALUES: {
              VALUE_DEF: false
          },
          UIKey: "toggleNearestPointSnap"
      };
  }

  initialize(value) {
      if (!_.isBoolean(value)) return;

      super.initialize(value);
  }

  getDimension(key) {
      let value = this.CONSTANTS().VALUES[key];
      if (_.isBoolean(value)) {
          return value;
      }
      else {
          throw (new Error("Value is not a Boolean \n Value: " + value));
      }
  }


  getValue(){
      return super.getValue();
  }

  changeValue(value, options = {}){

      const newValue = value;

      const uiKey = this.CONSTANTS().UIKey;

      const execute = function(){
          store.projectProperties.properties[uiKey].value = this.data.newValue;
      };

      const unexecute = function(){
          store.projectProperties.properties[uiKey].value = this.data.oldValue;
      };

      const oldValue = this.value;
      this.value = newValue;

      const cmds = [];
      const yets = [];

      setUserSetting(uiKey, newValue);

      const uiDataPoint = ['userSetBIMProperties.' + uiKey, oldValue, newValue];
      const saveDataPoint = [uiKey, oldValue, newValue];
      const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(uiDataPoint, saveDataPoint);
      const cmdUI = commandUtils.changeInUIOperations.getCommand("changeProjectPropUI" + uiKey, cmdDataUI);

      cmds.push(cmdUI);
      yets.push(false);

      const data = {oldValue: oldValue, newValue: newValue};

      cmds.push(new Command("changeProjectProp" + uiKey, data, {execute, unexecute}));
      yets.push(false);

      if(!options.doNotExecuteCmd){
          CommandManager.execute(cmds, yets);
      }

  }
}
class ToggleInternalWallToWallDimensions extends Property{

  CONSTANTS() {
      return {
          VALUES: {
              VALUE_DEF: false
          },
          UIKey: "toggleInternalDimensionsProperty"
      };
  }

  initialize(value) {
      if (!_.isBoolean(value)) return;

      super.initialize(value);
      dimensionsTuner.toggleTuner(value);
  }

  getDimension(key) {
      let value = this.CONSTANTS().VALUES[key];
      if (_.isBoolean(value)) {
          return value;
      }
      else {
          throw (new Error("Value is not a Boolean \n Value: " + value));
      }
  }


  getValue(type){
      return super.getValue();
  }

  changeValue(value, options = {}){

      const newValue = value;

      // if(newValue === this.value)  return;

      const uiKey = this.CONSTANTS().UIKey;

      const execute = function(){
          store.projectProperties.properties[uiKey].value = this.data.newValue;
          dimensionsTuner.toggleTuner(this.data.newValue);
      };

      const unexecute = function(){
          store.projectProperties.properties[uiKey].value = this.data.oldValue;
          dimensionsTuner.toggleTuner(this.data.oldValue);
      };

      const oldValue = this.value;
      this.value = newValue;

      const cmds = [];
      const yets = [];

      setUserSetting(uiKey, newValue);
      dimensionsTuner.toggleTuner(newValue);

      const uiDataPoint = ['userSetBIMProperties.' + uiKey, oldValue, newValue];
      const saveDataPoint = [uiKey, oldValue, newValue];
      const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(uiDataPoint, saveDataPoint);
      const cmdUI = commandUtils.changeInUIOperations.getCommand("changeProjectPropUI" + uiKey, cmdDataUI);

      cmds.push(cmdUI);
      yets.push(false);

      const data = {oldValue: oldValue, newValue: newValue};

      cmds.push(new Command("changeProjectProp" + uiKey, data, {execute, unexecute}));
      yets.push(false);

      if(!options.doNotExecuteCmd){
          CommandManager.execute(cmds, yets);
      }

  }
}

class WallProfileTypeProperty extends Property {
  CONSTANTS() {
    return {
      VALUES: {
        VALUE_DEF: "Centre", // All possible values are provided in projectPropertiesSlice.js
      },
      UIKey: "wallProfileTypeProperty",
    };
  }
  
  getDimension(key) {
    let value = this.CONSTANTS().VALUES[key];
    if (_.isString(value)) {
      return value;
    }
    else {
      throw (new Error("Value is not a String \n Value: " + value));
    }
  }

  changeValue(value, options = {}) {
    const newValue = value;

    if (newValue === this.value) return;

    const uiKey = this.CONSTANTS().UIKey;

    const execute = function () {
      store.projectProperties.properties[uiKey].value = this.data.newValue;
    };

    const unexecute = function () {
      store.projectProperties.properties[uiKey].value = this.data.oldValue;
    };

    const oldValue = this.value;
    this.value = newValue;

    const cmds = [];
    const yets = [];

    setUserSetting(uiKey, newValue);

    const uiDataPoint = ["userSetBIMProperties." + uiKey, oldValue, newValue];
    const saveDataPoint = [uiKey, oldValue, newValue];
    const cmdDataUI = commandUtils.changeInUIOperations.getCommandData(
      uiDataPoint,
      saveDataPoint
    );
    const cmdUI = commandUtils.changeInUIOperations.getCommand(
      "changeProjectPropUI" + uiKey,
      cmdDataUI
    );

    cmds.push(cmdUI);
    yets.push(false);

    const data = { oldValue: oldValue, newValue: newValue };

    cmds.push(
      new Command("changeProjectProp" + uiKey, data, { execute, unexecute })
    );
    yets.push(false);

    if (!options.doNotExecuteCmd) {
      CommandManager.execute(cmds, yets);
    }
  }
}


export {
  ProjectProperties,
  Property,
  SlabThicknessProperty,
  WallThicknessProperty,
  WallThicknessPropertyExt,
  WallThicknessPropertyInt,
  WallThicknessPropertyParapet,
  WallTypeProperty,
  WallTypePropertyExt,
  WallTypePropertyInt,
  WallTypePropertyParapet,
  AngleSnapThreshold,
  ConstraintSolverThreshold,
  AngleSnapEnabled,
  ParallelSnapEnabled,
  OrthogonalSnapEnabled,
  NormalSnapEnabled,
  DisableTooltips,
  PlinthHeightProperty,
  WallProfileTypeProperty,
};
