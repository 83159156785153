export const generateNameFromImgPath = (img) => {
  let fullName = "";
  img = img.split("/");
  img = img[img.length-1];
  const pattern = /[ \w-]+\./g;
  const matches = img.match(pattern);
  if(matches && matches.length>0){
    fullName = matches[0].replaceAll("_", " ");
    if(fullName.endsWith(".")) fullName = fullName.slice(0, -1);
  }
  return fullName;
}