import React, { useContext, useState } from "react";

const FileSystemContext = React.createContext();
const FileSystemUpdateContext = React.createContext();

export function useFileSystem(){
    return useContext(FileSystemContext);
}
export function useFileSystemUpdate(){
    return useContext(FileSystemUpdateContext);
}

function FileSystemProvider({children}) {
    const [fileSystem, setFileSystem] = useState({
        teamID: null,
        folderID: null,
        modal: "",
    });
    function updateFileSystem(teamID=null, folderID=null, modal=""){
        setFileSystem({
            teamID,
            folderID,
            modal
        })
    }
    return ( 
        <FileSystemContext.Provider value={fileSystem} >
            <FileSystemUpdateContext.Provider value={updateFileSystem}>
                {children}
            </FileSystemUpdateContext.Provider >
        </FileSystemContext.Provider>
    );
}

export default FileSystemProvider;