import React from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";
import PropTypes from "prop-types";

const Container = styled.div`
  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .number-input {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: ${colors.white};
    border-radius: 0.313rem;
    height: 2.625rem;
  }

  .number-input,
  .number-input * {
    box-sizing: border-box;
  }

  .number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
    position: relative;
    width: 1.4rem;
    height: 1.3rem;
    color: ${colors.fullWhite};
    background-color: ${colors.dullgrey};
    border-radius: 0.313rem;
  }

  .number-input input[type="number"] {
    max-width: 5rem;
    padding: 0.5rem;
    border: none;
    font-size: 0.8rem;
    height: 2.6rem;
    font-weight: bold;
    text-align: center;
    color: ${colors.primeBlack};
    background-color: ${colors.white};
  }
`;

const NumberRange = (props) => {
  const { value, onIncrement, onDecrement, onChange } = props;
  return (
    <Container>
      <div className="number-input">
        <button onClick={onDecrement}>-</button>
        <input
          className="quantity"
          // readOnly
          min="0"
          name="quantity"
          onChange={onChange}
          value={value}
          type="number"
        />
        <button onClick={onIncrement}>+</button>
      </div>
    </Container>
  );
};

NumberRange.propTypes = {
  value: PropTypes.number,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
};

export default NumberRange;
