import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";
import { colors } from "../../../themes/constant";
import dropArrow from "../../../assets/icons/dropArrow.svg";
import dropOpenArrow from "../../../assets/icons/dropOpenArrow.svg";
import Divider from "../../../components/Divider/index";
import * as constants from "./constants";
import formatNumber from "../../../common/utils/formatNumber";
import { materialScheduleView } from "../../../snaptrude/libs/materialScheduleView";
// import { materialScheduleViewSplit } from "../../../snaptrude/libs/materialScheduleViewSplit";
import { showToast, TOAST_TYPES } from "../../../snaptrude/modules/extrafunc";
import _ from "lodash";

const MainContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
`;

const PieChartWrapper = styled.div`
  width: 15em;
  position: relative;
  flex: 0.4;
`;

const PieChartOverlay = styled.div`
  position: absolute;
  background: ${colors.transparent};
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    display: flex;
    align-items: baseline;
  }
  h4 {
    font-family: ${(props) => props.theme.font};
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.266804px;
    color: ${colors.darkGrey};
    margin: 0.5em;
  }
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 35px;
    line-height: 46px;
    letter-spacing: -0.266804px;
    color: ${colors.darkGrey};
    margin: 0;
  }
  .dollar {
    font-size: 14px;
  }
`;

const BomTable = styled.div`
  overflow-y: scroll;
  padding: 0 0.3125em;
  width: 100%;
  height: 100%
  flex: 0.4;
  ::-webkit-scrollbar {
    width: 0.1875em;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: ${colors.thinGray};
    -webkit-box-shadow: inset 0 0 6px ${colors.black05};
  }
`;

const BOMTableRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: ${(props) => props.theme.font};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.230052px;
    color: ${colors.primeBlack};
    margin-right: 0.25rem;
  }
  .rowHeader {
    margin: 0;
  }
  .dimText {
    font-family: ${(props) => props.theme.font};
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.230052px;
    color: ${colors.thinGray};
  }
  .subHeader {
    width: 9em;
  }
`;

const SubRowHeader = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.230052px;
  color: ${colors.darkGrey};
  margin: 0;
`;

const BarGraphItems = styled.div`
  border: 0 solid ${colors.primeBlack};
  border-right-width: 2px;
  width: 6.02em;
  p {
    font-family: ${(props) => props.theme.font};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.230052px;
    color: ${colors.darkGrey};
    margin: 0;
    padding: 1em 0;
  }
`;

const BarGraphBars = styled.div`
  width: 10.25em;
  border: none;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 3.63em;
  margin-top: 0.5em;
}
`;

const BarGraph = styled.div`
  display: flex;
  padding: 1em 0 2em 0;
  flex: 0.1;
`;

const StandardBar = styled.div`
  background-color: ${colors.dullgrey};
  height: 1em;
`;

const DesignBar = styled.div`
  background-color: ${colors.mustard};
  height: 1em;
`;

const SubHeaderRow = styled(BOMTableRowContainer)`
  marginleft: 0.3125em;
  .subheadertitle {
    margin: 0.5em 1em;
  }
  .subHeaderCenter {
    justify-content: flex-start;
  }
  .subHeaderAmount {
    margin: 0.5em;
  }
  .subHeaderMargin {
    margin: 0.5em 0;
  }
`;

const BomTableRow = (props) => {
  const { key, bomRow, setDropDownOpen } = props;
  const { header, body } = bomRow;
  const { item, cost } = header;
  const [collapsed, setCollapsed] = useState(true);

  const getCollapsibleSubRow = (subRow, index) => {
    const { title, subBody } = subRow;
    return (
      <div key={index}>
        <SubRowHeader>{title}</SubRowHeader>
        {subBody.map((subitem, subindex) => {
          return (
            <SubHeaderRow key={subindex}>
              <p className="dimText subHeader subheadertitle">
                {subitem.subTitle}
              </p>
              <BOMTableRowContainer className="subHeaderCenter">
                <p className="subHeaderAmount">
                  {formatNumber(subitem.amount)}
                </p>
                <p className="dimText subHeaderMargin">{subitem.unit}</p>
              </BOMTableRowContainer>
              {/* <p className="subHeaderMargin">{`${
                constants.DOLLAR
              }${formatNumber(subitem.cost)}`}</p> */}
            </SubHeaderRow>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div style={{ padding: "0.625em 0" }}>
        <BOMTableRowContainer
          key={key}
          style={{ cursor: "pointer" }}
          onClick={() =>
            setCollapsed((prevState) => {
              if (!prevState) setDropDownOpen((prev) => prev - 1);
              else setDropDownOpen((prev) => prev + 1);
              return !prevState;
            })
          }
        >
          <BOMTableRowContainer className="subHeaderCenter">
            <BOMTableRowContainer>
              <img
                src={collapsed ? dropArrow : dropOpenArrow}
                style={{ marginRight: "0.5em" }}
                alt="drop"
              />
            </BOMTableRowContainer>
            <p className="rowHeader">{item}</p>
          </BOMTableRowContainer>
          {/* <p className="rowHeader">{`${constants.DOLLAR}${
            // formatNumber(
            cost
            // )
          }`}</p> */}
        </BOMTableRowContainer>
        {!collapsed && <Divider />}
      </div>
      {!collapsed && (
        <div>
          {body.map((subRow, index) => {
            return getCollapsibleSubRow(subRow, index);
          })}
          <Divider />
        </div>
      )}
    </>
  );
};

const BOMSidebar = (props) => {
  const [selected, setSelected] = useState(undefined);
  const [bomTableData, setBomTableData] = useState([]);
  const [isDropDownOpen, setDropDownOpen] = useState(0);
  const STANDARD_COST = 100;
  const DESIGN_COST = 60;

  useEffect(() => {
    const bomData = materialScheduleView.generateMatSchedule();
    // const bomDataSplit = materialScheduleViewSplit.generateMatSchedule();
    if(_.isEmpty(bomData)){
      showToast("Please Create Building from the Automate tab and then view the Bill of Quantities");
      return;
    }
    if(bomData){
      let bomDataArray = [];
      for(let item in bomData) {
        const bomTableRow = {
          header: {
            item: item[0].toUpperCase() + item.slice(1, item.length),
            cost: 0,
          },
          body: null,
        }
        bomTableRow.body = bomData[item].map((component, index) => {
          return {
            title: component.material,
            subBody: [
              {
                subTitle: component.material_type,
                amount: component.quantity,
                unit: component.unit,
                cost: 0
              }
            ]
          }
        })
        bomDataArray.push(bomTableRow);
      }
      setBomTableData(bomDataArray);
    }
  }, [])

  return (
    <MainContainer>
      <div style={{ flex: 0.1 }} />
      {/* <PieChartWrapper>
        <PieChart
          data={constants.PIE_CHART_DATA}
          paddingAngle={0}
          lineWidth={10}
          viewBoxSize={[100, 100]}
          startAngle={-125}
          radius={PieChart.defaultProps.radius - 6}
          segmentsStyle={(index) =>
            index === selected
              ? {
                  transition: "stroke .3s",
                  cursor: "pointer",
                  strokeWidth: "0.625em",
                }
              : {
                  transition: "stroke .3s",
                  cursor: "pointer",
                  opacity: selected || selected === 0 ? 0.5 : 1,
                }
          }
          segmentsShift={(index) => (index === selected ? 2 : 1)}
          animate
          onMouseOver={(_, index) => {
            setSelected(index === selected ? undefined : index);
          }}
          onMouseOut={() => {
            setSelected(undefined);
          }}
        />
        <PieChartOverlay>
          <h4>
            {selected || selected === 0
              ? constants.PIE_CHART_DATA[selected].title
              : "TOTAL COST"}
          </h4>
          <div>
            <p className="dollar">{constants.DOLLAR}</p>
            <p>
              {selected || selected === 0
                ? constants.PIE_CHART_DATA[selected].value
                : "100,854"}
            </p>
          </div>
        </PieChartOverlay>
      </PieChartWrapper>
      <BarGraph>
        <BarGraphItems>
          <p>{constants.STANDARD_COST_TEXT}</p>
          <p>{constants.DESIGN_COST_TEXT}</p>
        </BarGraphItems>
        <BarGraphBars>
          <StandardBar
            style={{
              width:
                STANDARD_COST >= DESIGN_COST
                  ? "100%"
                  : `${(STANDARD_COST / DESIGN_COST) * 100}%`,
            }}
          />
          <DesignBar
            style={{
              width:
                STANDARD_COST >= DESIGN_COST
                  ? `${(DESIGN_COST / STANDARD_COST) * 100}%`
                  : "100%",
            }}
          />
        </BarGraphBars>
      </BarGraph> */}
      <BomTable>
        <BOMTableRowContainer style={{ flex: 1 }}>
          <p style={{ flex: 0.5 }}>{constants.DESCRIPTION}</p>
          <BOMTableRowContainer
            style={{
              flex: 0.5,
              justifyContent: isDropDownOpen > 0 ? "space-between" : "flex-end",
            }}
          >
            {isDropDownOpen > 0 && (
              <BOMTableRowContainer style={{ marginLeft: 80 }}>
                <p>{constants.QUANTITY}</p>
                <p>{constants.UNIT}</p>
              </BOMTableRowContainer>
            )}
            {/* <p>{constants.COST}</p> */}
          </BOMTableRowContainer>
        </BOMTableRowContainer>
        {bomTableData.map((dataRow, index) => {
          return (
            <BomTableRow
              setDropDownOpen={setDropDownOpen}
              key={index}
              bomRow={dataRow}
            />
          );
        })}
      </BomTable>
    </MainContainer>
  );
};

export default BOMSidebar;
