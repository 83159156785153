const snapDiagnostics = (function (){
  
  let converter;
  
  const setConverterEquation = function (converterEquation){
    converter = converterEquation;
  };
  
  const getThreshold = function (distanceFromCamera){
    return converter(distanceFromCamera);
  };
  
  const isModified = function (){
    return !!converter;
  };
  
  return {
    isModified,
    getThreshold,
    setConverterEquation
  };
  
})();

export default snapDiagnostics;
