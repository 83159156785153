import React, { useState } from "react";
import styled from "styled-components";
import folderIcon from "../../assets/icons/folderIcon.svg";
import ActionDropdown from "../ActionDropdown";
import { Link } from "react-router-dom";
import { useFileSystemUpdate } from "../../contexts/FileSystemContext";
import Tooltip from "../Tooltip";
const Wrapper = styled.div`
    box-shadow: 0 0.25rem 1.875rem rgb(197 202 220 / 30%);
    border-radius: 0.938rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #FFFFFF;
    .title{
        padding: 0.25rem;
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
    }
    .titletext{
        font-weight: 600;
        position: absolute;
        left: 2em;
        right: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .icon img{
        display: block;
    }
    .options-dropdown{
        position: relative;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        width: 2em;
        justify-content: center;
        border-radius: 0 0.938em 0.938em 0;
    }
    .options-dropdown .action-icon{
        z-index: 0;
    }
    .footertext{
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.021rem;
        color: rgba(45, 45, 46, 0.6);
        opacity: 0.8;
        margin: 0.25rem;
        padding: 0;
    }
`
function Folder({ folder, workspaceRoute, permissions }) {
    const fileSystemUpdate = useFileSystemUpdate();
    const [isMouseDown, setIsMouseDown] = useState(false);
    const handleFolderAction = (action) => {
        fileSystemUpdate(folder.team_dashboard, folder.id, action)
    }
    const handleMouseDown = () => {
        setIsMouseDown(true);
        document.addEventListener("mouseup", handleMouseUp);
    }
    const handleMouseUp = () => {
        setIsMouseDown(false);
        document.removeEventListener("mouseup", handleMouseUp);
    }
    return (
        <Wrapper onMouseDown={handleMouseDown}>
            <Link data-tip data-for={`${folder.name}-${folder.id}`} className="title" to={`${workspaceRoute}/folder/${folder.id}`}>
                <div className="title">
                    <span className="icon">
                        <img src={folderIcon} alt="folder" />
                    </span>
                    <div className="titletext">{folder.name}</div>
                </div>
            </Link>
            {
                permissions["create_rename_delete_folder"] &&
                <div className="options-dropdown">
                    <ActionDropdown
                        actions={[
                            { title: "Create folder inside", handleClick: () => { handleFolderAction("create") } },
                            { title: "Rename", handleClick: () => { handleFolderAction("rename") } },
                            { title: "Delete", handleClick: () => { handleFolderAction("delete") } },
                        ]}
                        wrapperStyle={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            padding: "0px",
                        }}
                        dropdownAlign="right"
                        dropdownStyle={{
                            zIndex: 12
                        }}
                    />
                </div>
            }
            {
                !isMouseDown && folder.name?.length > 28 &&
                <Tooltip id={`${folder.name}-${folder.id}`} place="top">{folder.name}</Tooltip>
            }
        </Wrapper>
    );
}

export default Folder;