export const TOLERANCE_LEVELS = [
  {
      "id": 0,
      "value": 0,
      "name": "0",
      "$$hashKey": "object:784",
      "$$mdSelectId": 11
  },
  {
      "id": 1,
      "value": 1,
      "name": "0.0",
      "$$hashKey": "object:785",
      "$$mdSelectId": 12
  },
  {
      "id": 2,
      "value": 2,
      "name": "0.00",
      "$$mdSelectId": 10,
      "$$hashKey": "object:786"
  },
  {
      "id": 3,
      "value": 3,
      "name": "0.000",
      "$$hashKey": "object:787",
      "$$mdSelectId": 13
  },
  {
      "id": 4,
      "value": 4,
      "name": "0.0000",
      "$$hashKey": "object:788",
      "$$mdSelectId": 14
  },
  {
      "id": 5,
      "value": 5,
      "name": "0.00000",
      "$$hashKey": "object:789",
      "$$mdSelectId": 15
  },
  {
      "id": 6,
      "value": 6,
      "name": "0.000000",
      "$$hashKey": "object:790",
      "$$mdSelectId": 16
  },
  {
      "id": 7,
      "value": 7,
      "name": "0.0000000",
      "$$hashKey": "object:791",
      "$$mdSelectId": 17
  },
  {
      "id": 8,
      "value": 8,
      "name": "0.00000000",
      "$$hashKey": "object:792",
      "$$mdSelectId": 18
  }
]