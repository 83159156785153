import BABYLON from "../../modules/babylonDS.module.js";
import { store } from "../../modules/utilityFunctions/Store.js";
import { sketchOperation } from "../../modules/meshoperations/sketchOperation.js";
import { StructureCollection } from "../../modules/snaptrudeDS/structure.ds.js";
import { AutoSave } from "../../modules/socket/autoSave.js";
import { convertGlobalVector3ToLocal } from "../../modules/extrafunc.js";
import { Command } from "../../modules/commandManager/Command.js";
import { CommandManager } from "../../modules/commandManager/CommandManager.js";
var eraseoperation = (function () {
  let sketchPickInfo;
  let lineSystems = [];
  let downFlag = 0;
  let eraseoperationCommand = "eraseoperation";
  let pointsData;

  let pointsObj = {};
  let panMode = false;
  let radius = 0.12;
  let numberOfEdgesOnCircle = 20;
  // let eraseSphere;

  let reCreateSketchNew = function () {
    let data = this.data;
    if (data.points.length) {
      let _data = {};
      for (let i = 0; i < data.points.length; i++) {
        _data.data = {};
        _data.data.points = data.points[i].points;
        _data.data.color = data.points[i].color;
        _data.data.thickness = data.points[i].thickness;
        _data.data.structureId = data.structureId;
        _data.data.storey = data.storey;
        _data.data.layerName = data.layerName;
        _data.data.sketchUniqueId = data.points[i].id;
        sketchOperation.createSketch.call(_data);
      }
    }
  };

  let reCreateSketch = function () {
    let data = this.data;
    let sketchUIDs = [];
    if (data.points.length) {
      for (let i = 0; i < data.points.length; i++) {
        let lineSystem = BABYLON.MeshBuilder.CreateLineSystem(
          "lineSystem",
          {
            lines: data.points[i],
            updatable: true,
          },
          store.scene
        );
        lineSystem.name = "sketchLine";
        lineSystem.color = new BABYLON.Color3(0, 0, 0);
        lineSystem.isPickable = false;
        sketchUIDs.push(lineSystem.uniqueId);

        let sketch = {};
        sketch.points = data.points[i];
        sketch.mesh = lineSystem;
        sketch.mesh.structure_id = data.structureId;

        let structureCollection = StructureCollection.getInstance();
        structureCollection
          .getStructures()
          [data.structureId].getStoreyData()
          .getStoreyByValue(data.storey)
          .layerData.getLayerByName(data.layerName, data.storey)
          .sketches.push(sketch);
      }
      data.sketchUniqueId = sketchUIDs;
    }
    // CommandManager.saveCommandToLocalStorageCommandQueue(cmd);
  };

  let deleteSketch = function () {
    let data = this.data;

    let structureCollection = StructureCollection.getInstance();
    let sketches = structureCollection
      .getStructures()
      [data.structureId].getStoreyData()
      .getStoreyByValue(data.storey)
      .layerData.getLayerByName(data.layerName, data.storey).sketches;
    for (let i = 0; i < data.points.length; i++) {
      for (let j = 0; j < sketches.length; j++) {
        if (sketches[j].mesh.uniqueId === data.points[i].id) {
          sketches[j].mesh.dispose();
          sketches.splice(j, 1);
          //data.sketchUniqueId.splice(i,1);
          break;
        }
      }
      //scene.getMeshByUniqueID(data.sketchUniqueId).dispose();
    }
  };
  let getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = this.id;
    saveData.data.saveType = "removeSketch";

    console.log("remove sketch save data");

    //   let sketch = store.scene.getMeshByUniqueID(this.data.sketchUniqueId);
    saveData.data.identifier = {
      structure_id: this.data.structureId,
      storey: this.data.storey,
      layerName: this.data.layerName,
      layerId: this.data.layerId,
      floorkey: store.floorkey,
    };

    let dataAfter = {
      data: pointsData,
    };

    saveData.data.afterOperationData = dataAfter;

    return saveData;
  };

  let getLocalCoordsForSketch = function (pointsData, mesh) {
    let pointsDataLocal = [];
    for (let i = 0; i < pointsData.length; i++) {
      let oneCurve = pointsData[i];
      let b = [];
      for (let j = 0; j < oneCurve.length; j++) {
        let sketchPoint = oneCurve[j];
        let sketchPointLocal = convertGlobalVector3ToLocal(sketchPoint, mesh);
        b.push(sketchPointLocal);
      }
      pointsDataLocal.push(b);
    }
    return pointsDataLocal;
  };

  let getCommands = function () {
    return {
      execute: deleteSketch,
      unexecute: reCreateSketchNew,
    };
  };
  return {
    getCommands,

    breakLinesForErase: function () {
      let linesystems = [];
      store.activeLayer.sketches.forEach(function (sketch) {
        let lines = {};
        lines.points = [];
        lines.mesh = [];
        let tpts = sketch.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        for (let i = 0; i < tpts.length - 1; i += 3) {
          let pt1 = new BABYLON.Vector3(tpts[i], tpts[i + 1], tpts[i + 2]);
          let pt2 = new BABYLON.Vector3(tpts[i + 3], tpts[i + 4], tpts[i + 5]);
          let cpts = [pt1, pt2];
          let line = BABYLON.MeshBuilder.CreateLines(
            "sketchLine",
            { points: cpts },
            store.scene
          );
          line.color = new BABYLON.Color3(0, 0, 0);
          lines.points.push(cpts);
          lines.mesh.push(line);
        }

        linesystems.push(lines);
        //  store.activeLayer.sketches.push(linesystems);
        sketch.dispose();
      });
    },
    combineLinesAfterErase: function () {
      lineSystems.forEach(function (lines) {
        let lineSystem = BABYLON.MeshBuilder.CreateLineSystem(
          "lineSystem",
          {
            lines: lines.points,
            updatable: true,
          },
          store.scene
        );
        lineSystem.name = "sketchLine";
        lineSystem.color = new BABYLON.Color3(0, 0, 0);
        lineSystem.isPickable = false;
        console.log("created lines before dispose -->", lines);
        lines.mesh.forEach(function (mesh) {
          mesh.dispose();
        });
        store.activeLayer.sketches.push(lineSystem);
      });
    },
    onPointerDownErase: function (evt) {
      pointsData = [];
      panMode = false;
      if (evt.ctrlKey && evt.which == 2) {
        // store.newScene.activeCamera.attachControl(canvas, true);
        panMode = true;
      } else if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
        sketchPickInfo = store.newScene.pick(
          store.newScene.pointerX,
          store.newScene.pointerY,
          function (mesh) {
            return mesh.isVisible;
          }
        );
        // store.newScene.activeCamera.detachControl(canvas);
        downFlag = 1;
      }
    },

    onPointerMoveErase: function (evt) {
      if (!panMode) {
        if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
          sketchPickInfo = store.newScene.pick(
            store.newScene.pointerX,
            store.newScene.pointerY,
            function (mesh) {
              return mesh.name === "sketchLine";
            }
          );
          // if (eraseSphere) {
          //     eraseSphere.position = sketchPickInfo.pickedPoint;
          //
          //     if (eraseSphere.intersectsMesh(scene.getMeshByID('sketchLine'), true)) {
          //  console.log("intersect true");
          //console.log("intersect true");
          if (
            downFlag &&
            sketchPickInfo.pickedMesh &&
            sketchPickInfo.pickedMesh.name == "sketchLine"
          ) {
            for (let i = 0; i < store.activeLayer.sketches.length; i++) {
              if (
                store.activeLayer.sketches[i].mesh.uniqueId ==
                sketchPickInfo.pickedMesh.uniqueId
              ) {
                pointsObj = {};
                pointsObj.id = store.activeLayer.sketches[i].mesh.uniqueId;
                pointsObj.points = store.activeLayer.sketches[i].points;
                pointsObj.position =
                  store.activeLayer.sketches[i].mesh.position.asArray();
                pointsObj.scaling =
                  store.activeLayer.sketches[i].mesh.scaling.asArray();
                pointsObj.color = store.activeLayer.sketches[i].color;
                pointsObj.thickness = store.activeLayer.sketches[i].thickness;
                pointsData.push(pointsObj);
                store.activeLayer.sketches.splice(i, 1);
                sketchPickInfo.pickedMesh.dispose();
              }
            }
          }
          // console.log("sketch deleted down");
          //  console.log("sketch deleted move");
          //     }
          // } else {
          //     console.log("intersect false");
          // }

          document.addEventListener("keydown", function (event) {
            const key = event.key; // Or const {key} = event; in ES6+
            /*if (key === "Escape") {
                      }*/
          });
        }
      }
    },

    onPointerUpErase: function (evt) {
      if (evt.pointerType === "pen" || evt.pointerType === "mouse") {
        //eraseSphere.dispose();

        let _executeEvent = function () {
          let data = {
            points: pointsData,
            structureId: store.activeLayer.structure_id,
            storey: store.activeLayer.storey,
            layerName: store.activeLayer.name,
            layerId: store.activeLayer.id,
          };

          let cmd = new Command(
            eraseoperationCommand,
            data,
            getCommands(),
            getSaveData
          );
          CommandManager.execute(cmd, false);
        };
        // store.scene.activeCamera.attachControl(canvas, true);
        downFlag = 0;
        if (pointsData && pointsData.length > 0) {
          _executeEvent();
        }
        //updateModifications();
      }
    },
  };
})();
export { eraseoperation };
