import BABYLON from "../modules/babylonDS.module";
import {makeid} from "./arrayFuncs";
import {store} from "../modules/utilityFunctions/Store";
import {DisplayOperation} from "../modules/displayOperations/displayOperation";
import {AutoSave} from "../modules/socket/autoSave";
import {Command} from "../modules/commandManager/Command";
import {CommandManager} from "../modules/commandManager/CommandManager";
import {StructureCollection} from "../modules/snaptrudeDS/structure.ds";
import {setActiveLayerAndRecord} from "../modules/extrafunc";
import {removeMeshSelectionChildren} from "./meshEvents";
import reduxStore from "../stateManagers/store/reduxStore";
import {appendLayer, appendStorey, deleteLayer} from "../stateManagers/reducers/objectProperties/storeysSlice";
import {Pdf} from "../modules/snaptrudeDS/pdf.ds";
import pdfIcon from "../../assets/subMenuIcon/import/importpdf.svg"
import {setLayerTransperancy} from "./sceneFuncs";
import {goIntoTwoD} from "./twoDimension";
import {layerView} from "./layerView";
import {meshUniqueIdMapper} from "../modules/utilityFunctions/meshUniqueIdMapper";
import pdfworker_script from './webworkers/pdfworker';
import { GLOBAL_CONSTANTS } from "../modules/utilityFunctions/globalConstants";
import axios from "axios";
import {djangoUrl} from "../../services/url.constants";
import {autoSaveConfig} from "../modules/socket/autoSaveConfig";
import {getJWT} from "../../services/jwt.service";


var pdfworker = new Worker(pdfworker_script);

// let timings = [];
// const timeEvent = (eventName) => {
//   timings.push({
//     eventName,
//     time: performance.now()
//   })
// }

const importPdfOperations = (function() {

  function _logPdfInSaveMicro ( pathToFile, storey ){
    let dataObject = {
      floorkey: store.floorkey,
      filePath: djangoUrl + '/media/' + pathToFile,
      storey: storey,
      jwt: getJWT(),
    }
    let _socketURL = autoSaveConfig.getSocketUrl();

    return new Promise((resolve, reject) => {
      axios.post( _socketURL + "/logpdf/", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch(error => {
            console.log("Error logging pdf in saveMicro");
            reject(error)
          })
    })

  }

  function _logPdf ( pdfFile, storey ) {

    let dataObject = {
      floorkey: store.floorkey,
      file: pdfFile,
      storey: storey
    }
    const formData = new FormData();
    for(let item in dataObject) formData.append(item, dataObject[item]);

    return new Promise((resolve, reject) => {
      axios.post( djangoUrl + "/pdfLog/", formData)
        .then((res) => {
          console.log("Logged PDF in Django");
          _logPdfInSaveMicro(res.data?.file_url, storey)
              .then((res) => {
                console.log("Logged in save micro");
                resolve(res);
              })
              .catch(error => {
                console.log("Error Logging in Save Micro. ");
                reject(error);
              })
        })
        .catch(error => {
          console.log("Logging PDF failed.");
          reject(error)
        })
    });

  }

  function sendProgressEvent(completed, text, next, intervalDuration = 200) {
    const EVT_NAME = GLOBAL_CONSTANTS.strings.events.pdfUploadProgress;
    if (!text) text = "Uploading PDF ...";
    const event = new CustomEvent(EVT_NAME, {
      detail: {
        progress: completed,
        text: text,
        next,
        intervalDuration,
      }
    })
    window.dispatchEvent(event);
  }

  async function _fetchPDFMetaData ( sourcePdf ) {

    let pdfjsLib = window["pdfjs-dist/build/pdf"];
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
    let loadingTask = pdfjsLib.getDocument(sourcePdf);

    return new Promise((resolve, reject) => {
      loadingTask.promise.then(function (pdf) {
        resolve({
          totalNumberOfPages: pdf.numPages
        })
      }, function (reason) {
        // PDF loading error
        console.error(reason);
        reject("Problem reading the pdf");
      });
    })
  }

  async function _init( sourcePdf, scaleFactor, storey, page ) {
    // timings = [];
    sendProgressEvent(5, "Pre-Processing ...", 10);
    // timeEvent("preReadPDF")
    let _processedPdf = await _readPdf(sourcePdf, page);
    // timeEvent("postReadPDF")
    let _sourcePdfInBase64 = _processedPdf.dataURL;
    let _viewPortWidth = _processedPdf.viewPortWidth;
    let _viewPortHeight = _processedPdf.viewPortHeight;

    // _addPdf(_sourcePdfInBase64, 100, storey);

    if(store.$scope.units_type.value.includes("inch")){
      let [ _lhs, _rhs ] = scaleFactor.split('=');
      _lhs = _lhs.replace('"','');

      let [ _lhsNumerator, _lhsDenominator ] = _lhs.split('/');
      if(!_lhsDenominator) _lhsDenominator = "1";
      _rhs = _rhs.split('-')[0]
      _rhs = parseInt(_rhs); // this is always in feet.

      let _rhsInInches = _rhs * 12; // scale value on the lhs of an inch should be made equivalent to scale value of feet-in on the right.
      scaleFactor = _rhsInInches * ( parseInt(_lhsDenominator) / parseInt(_lhsNumerator) );
    }
    else{
      scaleFactor = parseInt(scaleFactor.split(':')[1]);
    }
    storey = parseInt(storey);

    // ADD STOREY IF NOT EXISTS
    // const {storeys} = reduxStore.getState();
    // const storeyExists = storeys.items.filter(storey => storey.value === storey);
    // console.log(storeyExists);
    // if(storeyExists.length === 0){

    // }
    let structures = StructureCollection.getInstance();
    let str = structures.getStructureById(
      store.activeLayer.structure_id
    );
    let storeyCollection = str.getStoreyData();

    let storeyNum = storey;
    let payload = {
      items: [],
    };
    if (storeyNum > 0) {
      let posLength = storeyCollection.getPositiveStoreysLength();
      if (storeyNum > posLength - 1) {
        for (let i = posLength; i <= storeyNum; i++) {
          const storey = storeyCollection.addStorey(
            store.activeLayer.structure_id,
            i
          );
          // const existStoreyData = selectStorey(state, storey.value);

          if (!storey.exists) {
            payload.items.push({
              id: storey.id,
              value: storey.value,
              name: storey.name,
              height: DisplayOperation.convertToDefaultDimension(
                storey.height
              ),
              hidden: storey.hidden,
              layers: [],
            });
            delete storey.exists;
          }
        }
      }
    } else {
      let negLength = storeyCollection.getNegativeStoreysLength();
      if (Math.abs(storeyNum) > negLength) {
        for (let i = negLength + 1; i <= Math.abs(storeyNum); i++) {
          const storey = storeyCollection.addStorey(
            store.activeLayer.structure_id,
            -i
          );
          if (!storey.exists) {
            payload.items.push({
              id: storey.id,
              value: storey.value,
              name: storey.name,
              height: DisplayOperation.convertToDefaultDimension(
                storey.height
              ),
              hidden: storey.hidden,
              layers: [],
            });
            delete storey.exists;
          }
        }
      }
    }
    reduxStore.dispatch(appendStorey(payload));

    let _executeEvent = function (){

      let _requiredData = {
        sourcePdfInBase64: _sourcePdfInBase64,
        scaleFactor: scaleFactor,
        storey: storey,
        structure_id: store.activeLayer.structure_id,
        viewPortWidth: _viewPortWidth,
        viewPortHeight: _viewPortHeight
      }

      let _getCommandLogic = function(){
        return {
          execute: _addPdf,
          unexecute: _deletePdf,
        };
      }
      let _getSaveData = function() {
        let saveData = AutoSave.getSaveDataPrototype();

        saveData.commandId = "addPdf" + this.data.layerId
        saveData.data.saveType = "addPdf";

        saveData.data.identifier = {
          structure_id: this.data.structure_id,
          storey: this.data.storey,
          floorkey: store.floorkey,
          layerId: this.data.layerId,
        };

        saveData.data.afterOperationData = {
          sourcePdfInBase64: this.data.sourcePdfInBase64,
          scaleFactor: this.data.scaleFactor,
          storey: this.data.storey,
          structure_id: this.data.structure_id,
          layerId: this.data.layerId,
          width: this.data.width,
          height: this.data.height,
          pdfPosition: this.data.pdfPosition,
          uniqueId: this.data.uniqueId
        };
        return saveData;
      }

      let cmd = new Command("addPdf", _requiredData, _getCommandLogic(), _getSaveData);
      CommandManager.execute(cmd, true);
    }
    _executeEvent();
  }

  function _readPdf( sourcePdf, page ){

    // let sourcePdf = "https://cdn.glitch.com/3da1885b-3463-4252-8ded-723332b5de34%2FNew_Horizons.pdf?v=1599831745689";
    // let sourcePdf = testbasic;
    // let _scaleFactor = 100;

    let pdfjsLib = window["pdfjs-dist/build/pdf"];
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';

    // timeEvent("preLoadingTask")
    let loadingTask = pdfjsLib.getDocument(sourcePdf);
    let viewPortWidth = null;
    let viewPortHeight = null;

    return new Promise((resolve, reject) => {
      sendProgressEvent(10, "Preprocessing PDF ...", 20);
      loadingTask.promise.then(function(pdf) {
        // timeEvent("loadingTaskResponse")
        // sendProgressEvent(30, "Pre-Processing ...", 60);
        // console.log('PDF loaded', pdf.getMetadata());
        // Fetch the first page
        // timeEvent("preGetPage")
        pdf.getPage(page).then(function(page) {
          // timeEvent("getPageRespose");
          // sendProgressEvent(60, "Pre-Processing ...", 65);
          // console.log('Page loaded');
          let scale = 3;
          let viewport = page.getViewport({scale: scale});

          // Prepare canvas using PDF page dimensions
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          viewPortWidth = viewport.width / scale;
          viewPortHeight = viewport.height / scale;

          // Render PDF page into canvas context
          let renderContext = {
            canvasContext: context,
            viewport: viewport
          };

          // // sendProgressEvent(55, "Rendering PDF ...", 60);

          // timeEvent("preRenderTask")
          sendProgressEvent(20, "Rendering PDF ...", 85, 1000);
          let renderTask = page.render(renderContext);
          renderTask.promise
            .then(function () {
            sendProgressEvent(85, "Rendering PDF ...", 98, 500);

              // timeEvent("rednerTaskResponse");
              // console.log('Page rendered');
              // _sourcePdfInBase64 = canvas.toDataURL();
              // sendProgressEvent(50, "Creating PDF...", 100);
              // sendProgressEvent(75, "Creating PDF ...", 85)
              const mimeType = 'image/png';
              canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.addEventListener('loadend', () => {
                  // timeEvent("blobConversionResponse");
                  const arrayBuffer = reader.result;

                  // Dispay Blob content in an Image.
                  const blob = new Blob([arrayBuffer], {type: mimeType});
                  // timeEvent("preCreateObjectURL")
                  const dataUrl = URL.createObjectURL(blob);
                  pdfworker.postMessage(dataUrl);
                  pdfworker.onmessage = (resp) => {
                    const base64Url = resp.data;
                    resolve({
                      dataURL: base64Url,
                      viewPortWidth: viewPortWidth,
                      viewPortHeight: viewPortHeight
                    });
                  };
                  // timeEvent("postCreateObjectURL")
                });
                // timeEvent("preBlobConversion")
                reader.readAsArrayBuffer(blob);
              }, mimeType);
            });
        });

      }, function (reason) {
        // PDF loading error
        console.error(reason);
        reject("Problem reading the pdf");
      });
    })
  }

  function _checkIfPdfExistsOnStorey(storeyNum) {
    storeyNum = parseInt(storeyNum)
    const {storeys} = reduxStore.getState();
    const storey = storeys.items.find(st => st.value === storeyNum);
    if(!storey) return false;
    for(let layer of storey.layers){
      if(layer.title.includes("pdf")) return true;
    }
    return false;
  }

  function _addPdf (data){
    // timeEvent("addPDFStart")
    // sendProgressEvent(95, "Creating PDF ...", 100)
    if(!data){
      data = this.data;
    }

    let pdfMat = new BABYLON.StandardMaterial("pdfMaterial", store.scene);
    pdfMat.backFaceCulling = false;
    pdfMat.diffuseTexture = new BABYLON.Texture.CreateFromBase64String(
      data.sourcePdfInBase64,
      "pdfMat_" + makeid(3),
      store.scene
    );
    let _actualWidthInCM = null;
    let _actualHeightInCM = null;
    let _actualWidthInInches = null;
    let _actualHeightInInches = null;
    let _dpi = 72; //72 dpi for a 1920 x 1080p screen.
    let _widthInSnaptrudeUnits = null;
    let _heightInSnaptrudeUnits = null;

    if( data.width && data.height ){
      _widthInSnaptrudeUnits = data.width;
      _heightInSnaptrudeUnits = data.height;
    }
    else{
      if(data.viewPortWidth && data.viewPortHeight){
        if(store.$scope.units_type.value.includes("inch")){
          _actualWidthInInches = data.viewPortWidth / _dpi;
          _actualHeightInInches = data.viewPortHeight / _dpi;

          _widthInSnaptrudeUnits = DisplayOperation.getOriginalDimension(_actualWidthInInches * data.scaleFactor, "inches");
          _heightInSnaptrudeUnits = DisplayOperation.getOriginalDimension(_actualHeightInInches * data.scaleFactor, "inches");
        }
        else{
          _actualWidthInCM = ( data.viewPortWidth / _dpi ) * 2.54;
          _actualHeightInCM = ( data.viewPortHeight / _dpi ) * 2.54;

          // 1px = 0.0264583333 centimeter
          _widthInSnaptrudeUnits = DisplayOperation.getOriginalDimension(_actualWidthInCM * data.scaleFactor, "centimeter");
          _heightInSnaptrudeUnits = DisplayOperation.getOriginalDimension(_actualHeightInCM * data.scaleFactor, "centimeter");
        }
      }
    }

    let _pdfMesh = BABYLON.MeshBuilder.CreatePlane("pdfMesh",
      {width: _widthInSnaptrudeUnits, height: _heightInSnaptrudeUnits}, store.scene);

    _pdfMesh.material = pdfMat;
    if(data.uniqueId){
      meshUniqueIdMapper.update(_pdfMesh, data.uniqueId);
    }
    else{
      data.uniqueId = _pdfMesh.uniqueId;
    }

    _pdfMesh.computeWorldMatrix(true);
    let _bbInfo = _pdfMesh.getBoundingInfo();
    _pdfMesh.position.x -= _bbInfo.boundingBox.minimumWorld.x;
    _pdfMesh.position.z -= _bbInfo.boundingBox.maximumWorld.y;

    let _storeyOfInterest = StructureCollection.getInstance()
      .getStructures()
      [data.structure_id].getStoreyData()
      .getStoreyByValue(data.storey);

    let options = {};

    let count = _storeyOfInterest?.layerData?.getCountOfLayerByType("pdf", data.storey);
    count = count ? count + 1 : 1;

    let layerName = "pdf-" + count;

    options.layerId = data.layerId ? data.layerId : null;
    options.autoSave = true;
    layerView.addAndSelectLayer(layerName, data.structure_id, data.storey, options);

    let _layer = store.activeLayer;
    _pdfMesh.position.y = _storeyOfInterest.base;
    if(data.pdfPosition){
      _pdfMesh.position = BABYLON.Vector3.FromArray(data.pdfPosition);
    }
    if(data.rotationQuaternion){
      _pdfMesh.rotationQuaternion = BABYLON.Quaternion.FromArray(data.rotationQuaternion);
    }
    else{
      _pdfMesh.rotation.x = Math.PI / 2;
    }
    _pdfMesh.storey = data.storey;

    let _pdfDS = new Pdf(_pdfMesh);
    _pdfDS.storey = data.storey;
    _pdfDS.scaleFactor = data.scaleFactor;
    _pdfDS.structure_id = data.structure_id;

    _pdfDS.layer_id = _layer.id;
    _pdfDS.mesh.layer_id = _layer.id;

    // Set pdf height based on storey
    _pdfDS.mesh.isVisible = true;
    _pdfDS.width = _widthInSnaptrudeUnits;
    _pdfDS.height = _heightInSnaptrudeUnits;
    _pdfDS.sourcePdfInBase64 = data.sourcePdfInBase64;
    _pdfDS.scaleFactor = data.scaleFactor;

    _layer.addPdf(_pdfDS);

    data.layerId = _layer.id;
    data.pdfPosition = _pdfMesh.position.asArray();
    data.width = _widthInSnaptrudeUnits;
    data.height = _heightInSnaptrudeUnits;
    data.layerName = layerName;

    reduxStore.dispatch(
      appendLayer({
        id: _layer.id,
        title: _layer.name,
        storey: data.storey,
        hidden: _layer.hidden,
        image: pdfIcon
        // subView: {
        //   allIcons: true,
        //   heightToggle: layer.heightMapToggle,
        //   thicknessSelector: false,
        //   colorPicker: false,
        // }
      })
    );
    // ScopeUtils.addLayer(layer);
    setActiveLayerAndRecord(
      _storeyOfInterest.layerData.getLayerByName("wall", data.storey)
    );
    goIntoTwoD("fitImage");
    setLayerTransperancy();
    _pdfMesh.isVisible = true;
    // timeEvent("addPDFEnd");
    // for(let i=1;i<timings.length;i++){
    //   const evt = timings[i-1].eventName + "-" + timings[i].eventName;
    //   const time = timings[i].time - timings[i-1].time;
    //   console.log(evt, time);
    // }
    sendProgressEvent(100, "Uploaded PDF", 100);
  }

  function _deletePdf(data){
    if(!data){
      data = this.data;
    }
    if (data.structure_id) {

      let mesh = store.scene.getMeshByUniqueID(data.uniqueId);
      let meshSnaptrudeDs = mesh?.getSnaptrudeDS();

      let structure = StructureCollection.getInstance().getStructures()[meshSnaptrudeDs.structure_id];
      let storey = structure.getStoreyData().getStoreyByValue(meshSnaptrudeDs.storey);

      if(!data.scaleFactor){
        data.scaleFactor = meshSnaptrudeDs.scaleFactor;
      }

      let layer = null;
      if (data.layerName) {
        layer = storey.layerData.getLayerByName(
          data.layerName,
          meshSnaptrudeDs.storey
        );
      } else if (data.layerId) {
        layer = storey.layerData.getLayerBylayerId(data.layerId);
      }
      let layerID = layer?.id;

      // removeMeshFromStructure(mesh);
      removeMeshSelectionChildren(mesh);
      mesh.dispose();

      storey.layerData.deleteLayer(layerID);
      reduxStore.dispatch(deleteLayer({layerId: layerID, storeyValue: data.storey}));
      setActiveLayerAndRecord(storey.layerData.getLayerByName('wall', data.storey));
      }
  }

  let _handleCollaborationForAddPdf = function (){
    let _executeFromSaveData = function(saveData){
      const afterOperationData = saveData.afterOperationData;
      _addPdf(afterOperationData);
    };
    let _unexecuteFromSaveData = function (saveData) {
      const afterOperationData = saveData.afterOperationData;
      _deletePdf(afterOperationData);
    };

    return {
      executeFromSaveData: _executeFromSaveData,
      unExecuteFromSaveData: _unexecuteFromSaveData,
    };
  };

  //From Key Events
  let _handleCollaborationForDeletePdf = function (){
    let _executeFromSaveData = function(saveData){
      const afterOperationData = saveData.afterOperationData;
      _deletePdf(afterOperationData);
    };
    let _unexecuteFromSaveData = function (saveData) {
      const afterOperationData = saveData.afterOperationData;
      _addPdf(afterOperationData);
    };

    return {
      executeFromSaveData: _executeFromSaveData,
      unExecuteFromSaveData: _unexecuteFromSaveData,
    };
  };

  let _handlePdfDeletionFromUI = function(layerData, storey) {

    let structure = StructureCollection.getInstance().getStructures()[layerData.structure_id];
    let layerDataFromStructure = structure.getStoreyData().getStoreyByValue(storey).layerData;
    let layer = layerDataFromStructure.getLayerBylayerId(layerData.id);
    let pdf = layer.pdfs[0];

    let _pdfMesh = store.scene.getMeshByUniqueID(pdf?.mesh?.uniqueId);
    let meshSnaptrudeDs = _pdfMesh.getSnaptrudeDS();

    let _dataNeededForRegeneration = {
      sourcePdfInBase64: meshSnaptrudeDs.sourcePdfInBase64,
      scaleFactor: meshSnaptrudeDs.scaleFactor,
      storey: meshSnaptrudeDs.storey,
      structure_id: meshSnaptrudeDs.structure_id,
      width: meshSnaptrudeDs.width,
      height: meshSnaptrudeDs.height,
      uniqueId: _pdfMesh.uniqueId,
      pdfPosition: _pdfMesh.position?.asArray(),
      rotationQuaternion: _pdfMesh.rotationQuaternion?.asArray(),
      layerId: _pdfMesh.layer_id,
    };

    function getCommandLogic() {
      return {
        execute: _deletePdf,
        unexecute: _addPdf
      };
    }

    let getSaveData = function () {
      let saveData = AutoSave.getSaveDataPrototype();
      saveData.commandId = "Pdf_Deletion_" + layerData.id;

      saveData.data.saveType = "deletePdf";

      saveData.data.identifier = {
        structure_id: meshSnaptrudeDs.structure_id,
        storey: meshSnaptrudeDs.storey,
        floorkey: store.floorkey,
        layerId: layerData.id,
      };

      let dataAfter = {
        sourcePdfInBase64: this.data.sourcePdfInBase64,
        scaleFactor: this.data.scaleFactor,
        storey: this.data.storey,
        structure_id: this.data.structure_id,
        layerId: this.data.layerId,
        width: this.data.width,
        height: this.data.height,
        pdfPosition: this.data.pdfPosition
      };

      saveData.data.afterOperationData = dataAfter;
      return saveData;
    };

    let cmd = new Command(
      "Pdf Deletion",
      _dataNeededForRegeneration,
      getCommandLogic(),
      getSaveData
    );

    CommandManager.execute(cmd, true);
  }

  let _handleShowOrHideForPDF = function ( storeyOfInterest, layerName ){
    if(!layerName){
      layerName = "pdf-1";
    }

    let pdfLayer = storeyOfInterest.layerData.getLayerByName(
      layerName,
      store.activeLayer.storey
    );

    if (pdfLayer) {
      if (pdfLayer.pdfs.length > 0 ){
        if(pdfLayer.hidden){
          pdfLayer.pdfs[0].deactivate();
        }
        else{
          pdfLayer.pdfs[0].activate();
        }
      }
    }
  }

  return {
    fetchPDFMetaData: _fetchPDFMetaData,
    logPdf: _logPdf,
    init: _init,
    addPdf: _addPdf,
    deletePdf: _deletePdf,
    checkIfPdfExistsOnStorey: _checkIfPdfExistsOnStorey,
    handleCollaborationForAddPdf: _handleCollaborationForAddPdf,
    handleCollaborationForDeletePdf: _handleCollaborationForDeletePdf,
    handlePdfDeletionFromUI: _handlePdfDeletionFromUI,
    handleShowOrHideForPDF: _handleShowOrHideForPDF
  }
})();

export { importPdfOperations }