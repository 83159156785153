import React, { useEffect, useRef } from "react";
import { createUUID } from "../../../../snaptrude/modules/extrafunc";
import * as d3 from "d3";

/**
 * Donut Chart
 * @Data can be an Object { key: value, ..., }
 * @Data or [{ key: string, value: number | string }]
 * @param {{
 *  key: string,
 *  width: number,
 *  height: number,
 *  margin: number,
 *  style: {}
 *  data: [],
 *  colors: [],
 *  convertData: boolean,
 *  arcInner: number,
 *  arcOuter: number,
 *  arcOverInner: number,
 *  arcOverOuter: number,
 *  insideTextTop: string,
 *  insideTextTopOffset: number,
 *  insideTextMiddle: string,
 *  insideTextMiddleOffset: number,
 *  insideTextBottom: string,
 *  insideTextBottomOffset: number,
 * }} props
 */
const DonutChart = (props) => {
  let key    = props.key    || createUUID(),
      width  = props.width  || 320,
      height = props.height || 300,
      margin = props.margin || 40,
      style  = props.style  || {},
      data   = props.data   || []

  const svgRef = useRef()
  
  // Draw Donut Chart
  useEffect(() => {
    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin

    // append the svg object to the div called 'donut_chart_{key}'
    const svg = d3.select(svgRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // set the color scale
    const color = props.colors || d3.scaleOrdinal()
      .domain(["Heating", "Cooling", "Lighting", "Hot Water", "Equipments", "Fan", "Pump"])
      .range(d3.schemePastel1);

    // Compute the position of each group on the pie:
    const pie = d3.pie()
      .sort(null) // Do not sort group by size
      .value(function(d) {return d.value; })

    // Ready Data
    const data_ready = pie(data)

    // The arc generator
    const arcInner = props.arcInner || 0.7,
          arcOuter = props.arcOuter || 0.8

    const arc = d3.arc()
      .innerRadius(radius * arcInner)   // This is the size of the donut hole
      .outerRadius(radius * arcOuter)

    // wider arc shown when hover/click
    const arcOverInner = props.arcOverInnerInner || 0.65,
          arcOverOuter = props.arcOverOuterOuter || 0.85

    const arcOver = d3.arc()
      .innerRadius(radius * arcOverInner)  // This is the size of the donut hole
      .outerRadius(radius * arcOverOuter)

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.

    // draw each arc
    svg.selectAll('allSlices')
      .data(data_ready)
      .join(
        enter => {
          enter.append('path')
          .attr('d', arc)
          .attr('fill', function(d) { return(color(d.data.key)) })
          .on("mouseover", function(d, { data }) {
            // change to bigger arc
            d3.select(this)
              .transition()
              .duration(200)
              .attr("d", arcOver)

            // show label
            d3.select(`#arc-label-1-${data.id}`).style('visibility', 'visible')
            d3.select(`#arc-label-1-${data.id}`).style('opacity', 1) // fade out effect
            d3.select(`#arc-label-2-${data.id}`).style('visibility', 'visible')
            d3.select(`#arc-label-2-${data.id}`).style('opacity', 1) // fade out effect
          })
          .on("mouseout", function(d, { data }) {
            // reset to normal arc
            d3.select(this)
              .transition()
              .duration(200)
              .attr("d", arc);

            // hide label
            d3.select(`#arc-label-1-${data.id}`).style('visibility', 'hidden')
            d3.select(`#arc-label-1-${data.id}`).style('opacity', 0) // fade out effect
            d3.select(`#arc-label-2-${data.id}`).style('visibility', 'hidden')
            d3.select(`#arc-label-2-${data.id}`).style('opacity', 0) // fade out effect
          })
          .attr("stroke", "white")
          .style("stroke-width", "2px")
          .style("opacity", 1)

          // Lable 1 shown when hover
          enter.append('text')
            .attr('id', function(d) { return `arc-label-1-${d.data.id}` })
            .attr("transform", function(d) {
              var _d = arc.centroid(d);
              _d[0] *= 1.5;
              _d[1] *= 1.5;
              return "translate(" + _d + ")";
            })
            .attr("dy", "0")
            .style("text-anchor", "middle")
            .style("visibility", "hidden")
            .style("transition", "all 0.3s linear")
            .attr('font-size', '12px')
            .text(function(d) { return d.data.key })

          // Lable 2 shown when hover
          enter.append('text')
            .attr('id', function(d) { return `arc-label-2-${d.data.key.replaceAll(' ', '')}` })
            .attr("transform", function(d) {
              var _d = arc.centroid(d);
              _d[0] *= 1.5;
              _d[1] *= 1.5;
              return "translate(" + _d + ")";
            })
            .attr("dy", "18px")
            .style("text-anchor", "middle")
            .style("visibility", "hidden")
            .style("transition", "all 0.3s linear")
            .attr('font-size', '14px')
            .attr('font-weight', 500)
            .text(function(d) { return d.data.value })
        },
      )
      .attr('id', "piechart_")

    svg.selectAll('insideTextTop')
      .data([props.insideTextTop])
      .join(
        enter => {
          enter.append('text')
          .attr("text-anchor", "middle")
          .attr('font-size', '12px')
          .attr('font-weight', 400)
          .attr('y', props.insideTextTopOffset || -30)
          .text(props.insideTextTop);

        },
      )

    svg.selectAll('insideTextMiddle')
      .data([props.insideTextMiddle])
      .join(
        enter => {
          enter.append('text')
          .attr("text-anchor", "middle")
          .attr('font-size', '35px')
          .attr('y', props.insideTextMiddleOffset || 10)
          .text(props.insideTextMiddle);
        },
      )

    svg.selectAll('insideTextBottom')
      .data([props.insideTextBottom])
      .join(
        enter => {
          enter.append('text')
          .attr("text-anchor", "middle")
          .attr('font-size', '12px')
          .attr('font-weight', 400)
          .attr('y', props.insideTextBottomOffset || 30)
          .text(props.insideTextBottom);
        },
      )

      return () => {
        if(svgRef.current) {
          if(svgRef.current?.children.length) {
            svgRef.current.children[0].remove()
          }
        }
      }
  }, [data.length, props.insideTextMiddle, props.insideTextBottom])

  return (
    <svg ref={svgRef} style={{ width: "100%", height: "100%", ...style }}></svg>
  );
}

export default DonutChart