import { createCustomMesh } from "../../libs/massModeling.js";
import { DisplayOperation } from "../displayOperations/displayOperation";
import { Mass } from "../snaptrudeDS/mass.ds";
import BABYLON from "../babylonDS.module";
import { onSolid } from "../extrafunc";
import { Door } from "../snaptrudeDS/doors.ds";
import { Furniture } from "../snaptrudeDS/furniture.ds";
import { StructureCollection } from "../snaptrudeDS/structure.ds";
import { wall } from "../snaptrudeDS/wall.ds";
import { StoreyMutation } from "../storeyEngine/storeyMutations";
import { store } from "../utilityFunctions/Store";
import {
  convertFacesToSnaptrude,
  convertVerticesToSnaptrude,
  createMaterialFromSpeckleData,
  createMeshWithMeshData,
  getGeometryDataFromReferenceData,
  getMeshDataFromReferenceData,
} from "./extraFunctions";
import { getDimensionInSnaptrudeUnits } from "./index.js";
import { sum } from "mathjs";
import { fetchSingleObject } from "./sampledata.js";
import { loadWallMaterial, materialLoader } from "../../libs/mats.js";
import { pasteObject } from "../../libs/defaultEvents.js";
import { Wall } from "../snaptrudeDS/wall.ds";
import _ from "lodash";
import { disposeAllAxisLines } from "../meshoperations/moveOperations/moveUtil.js";
import { createCustomMeshFromRevitExport } from "./extraFunctions";
import { checkStorey } from "./storey.js";
// TODO: Code is messy with lots of duplication. Needs refactor


//Panel
// Point(X = 543.750, Y = 0.000, Z = 0.000), Point(X = -543.750, Y = 0.000, Z = 0.000)
// Point(X = -543.750, Y = 0.000, Z = 0.000), Point(X = -543.750, Y = 12.700, Z = 0.000),
// Point(X = -543.750, Y = 12.700, Z = 0.000), Point(X = 543.750, Y = 12.700, Z = 0.000),
// Point(X = 543.750, Y = 12.700, Z = 0.000), Point(X = 543.750, Y = 0.000, Z = 0.000),

// Mullion

const createCurtainWallsDeprecated = (curtainWalls) => {
  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure = structureCollection.getStructureById(
    store.activeLayer.structure_id
  );
  const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
  // let curtainWalls = await fetchSingleObject(
  //   "953a4b17e0",
  //   "8de61695dcf56bf4a25135febf81ecbc"
  // );
  curtainWalls = JSON.parse(curtainWalls.join(""));
  console.log(curtainWalls);
  let curtainWallsData = []

  for (let id in curtainWalls) {
    // if (id !== "14347610" || id !== "14347605") continue;
    // if (!["14347489", "14347605"].includes(id)) continue;
    let curtainWall = curtainWalls[id];
    let panelGeometry = curtainWall["panels"]["geometry"];
    let panelPoints = [];
    panelGeometry.forEach((point) => {
      panelPoints.push([
        DisplayOperation.getOriginalDimension(point[0], "millimeter"),
        DisplayOperation.getOriginalDimension(point[1], "millimeter"),
        DisplayOperation.getOriginalDimension(point[2], "millimeter"),
      ]);
    });
    if (!panelPoints.length) continue;
    let panelsWidth = curtainWall["panels"]["widthArr"];
    let panelsHeight = curtainWall["panels"]["heightArr"];
    let mullionsType = curtainWall["mullions"]["geometry"];
    let mullionHeight = curtainWall["mullions"]["height"];
    let cornerMullionPoints = [];
    let mullionPoint = [];
    let curtainWallComponents = [];

    for (let type in mullionsType) {
      if (type === "Quad Corner Mullion") {
        mullionsType[type].forEach((point) => {
          cornerMullionPoints.push([
            DisplayOperation.getOriginalDimension(point[0], "millimeter"),
            DisplayOperation.getOriginalDimension(point[1], "millimeter"),
            DisplayOperation.getOriginalDimension(
              point[2] + mullionHeight,
              "millimeter"
            ),
          ]);
        });
      } else {
        mullionsType[type].forEach((point) => {
          mullionPoint.push([
            DisplayOperation.getOriginalDimension(point[0], "millimeter"),
            DisplayOperation.getOriginalDimension(point[1], "millimeter"),
            DisplayOperation.getOriginalDimension(
              point[2] + mullionHeight,
              "millimeter"
            ),
          ]);
        });
      }
    }
    if (!cornerMullionPoints.length) {
      cornerMullionPoints = mullionPoint;
    }

    let startPoint = curtainWall["basePoint"]["start"].map((point) =>
      DisplayOperation.getOriginalDimension(point, "millimeter")
    );
    let endPoint = curtainWall["basePoint"]["end"].map((point) =>
      DisplayOperation.getOriginalDimension(point, "millimeter")
    );

    let { orientation, length } = getOrientationAndLengthOfWall(
      startPoint,
      endPoint
    );

    console.log(orientation);
    // const angle = Math.atan2((endPoint[1]-startPoint[1]), (endPoint[0]-startPoint[0]))
    const angle = Wall.calculateAngleByMidLine(
      new BABYLON.Vector3(startPoint[0], startPoint[2], startPoint[1]),
      new BABYLON.Vector3(endPoint[0], endPoint[2], endPoint[1])
    );
    // orientation = [1, 0, 0]; // y <--> z

    // Create Mullions
    let mullionsWidthTemp = [];
    let mullionsWidth = [];
    let totalMullionWidth = 0;
    let panelOffset = 0;
    let fixedMullionWidth = null;
    mullionHeight = DisplayOperation.getOriginalDimension(
      mullionHeight,
      "millimeter"
    );
    let previousHeight = 0;
    let totalMullionHeight = 0;
    let level = 0;

    for (let i = panelsWidth.length - 1; i >= 0; --i) {
      let Mullion;
      let tempMullion;
      let tempMullionWidth = 0;
      let mullionThickness = null;
      let horizontalMullion;
      if (!mullionPoint.length) {
        mullionsWidthTemp.push(0);
        mullionsWidth.push(0);
        continue
      }

      if (DisplayOperation.getOriginalDimension(panelsWidth[i], "millimeter") == 0) {
        mullionsWidth.push(0);
        continue;
      }

      const tempPanelHeight = DisplayOperation.getOriginalDimension(panelsHeight[i], "millimeter");
      if (i == 0 || i == panelsWidth.length + 1) {
        Mullion = createCustomMesh(cornerMullionPoints, tempPanelHeight, null);
        Mullion.cornerMullion = true;
        tempMullion = createCustomMesh(mullionPoint, tempPanelHeight, null);
        let bbinfo = tempMullion.getBoundingInfo().boundingBox;
        let maximum = bbinfo.maximum.asArray();
        let minimum = bbinfo.minimum.asArray();
        tempMullionWidth = maximum[0] - minimum[0];
        if (tempMullion) tempMullion.dispose();
        // horizontalMullion = createCustomMesh(mullionPoint, tempPanelHeight, null);
      } else {
        Mullion = createCustomMesh(mullionPoint, tempPanelHeight, null);
      }

      // Create horizontal mullion
      horizontalMullion = createCustomMesh(mullionPoint, tempPanelHeight, null);

      let bbinfo = Mullion.getBoundingInfo().boundingBox;
      let maximum = bbinfo.maximum.asArray();
      let minimum = bbinfo.minimum.asArray();

      let mullionWidth = 0;
      // for (let i = 0; i < 3; ++i) {
      //   mullionWidth += (maximum[i] - minimum[i]) * Math.abs(orientation[i]);
      // }
      mullionWidth = maximum[0] - minimum[0];
      if (!mullionThickness) mullionThickness = bbinfo.extendSizeWorld.z * 2;
      if (!fixedMullionWidth) fixedMullionWidth = mullionWidth;
      mullionsWidth.push(mullionWidth);
      mullionsWidthTemp.push(mullionWidth);
      //   const panel = createCustomMesh(panelPoint, height, null);

      mullionWidth = _.min(mullionsWidthTemp);
      if (!mullionWidth) mullionWidth = fixedMullionWidth;
      transformMullionToHorizontal(horizontalMullion, tempPanelHeight,
        DisplayOperation.getOriginalDimension(panelsWidth[i], "millimeter"));

      let point = [...startPoint];
      // point[1] = point[1] + (mullionThickness / 2);

      if (sum(...orientation) > 0) {
        // point = endPoint;
      }
      let x =
        point[0] +
        orientation[0] *
        (totalMullionWidth + panelOffset + mullionWidth / 2);
      let y = point[2] + totalMullionHeight + (Mullion.cornerMullion ? tempMullionWidth : mullionWidth) +
        DisplayOperation.getOriginalDimension(panelsHeight[i], "millimeter") / 2;
      let z =
        point[1] +
        orientation[2] *
        (totalMullionWidth + panelOffset + mullionWidth / 2);
      Mullion.rotation.y = angle;
      Mullion.position = new BABYLON.Vector3(x, y, z);

      const mullionWidthForScaling = Mullion.cornerMullion ? tempMullionWidth : (2 * mullionWidth);
      Mullion.scaling.y *= (tempPanelHeight + mullionWidthForScaling) / tempPanelHeight;

      totalMullionWidth = sum(...mullionsWidthTemp);
      panelOffset += parseFloat(
        DisplayOperation.getOriginalDimension(panelsWidth[i], "millimeter")
      );

      horizontalMullion.position.x = point[0] + orientation[0] *
        (totalMullionWidth + panelOffset - DisplayOperation.getOriginalDimension(panelsWidth[i], "millimeter") / 2);
      horizontalMullion.position.y = point[2] + totalMullionHeight +
        DisplayOperation.getOriginalDimension(panelsHeight[i], "millimeter") + (Mullion.cornerMullion ? 1.5 * tempMullionWidth : 1.5 * mullionWidth);
      horizontalMullion.position.z = point[1] +
        orientation[2] *
        (totalMullionWidth + panelOffset - DisplayOperation.getOriginalDimension(panelsWidth[i], "millimeter") / 2);
      horizontalMullion.rotation.y = angle;
      Mullion.structure_id = store.activeLayer.structure_id;
      let MullionMass = new Mass(Mullion);
      Mullion.type = "mass"; //throwAway is overwritten when mesh is added to level
      // MullionMass.autoInterior = true;
      MullionMass.room_type = "mass";
      // MullionMass.assignProperties();
      MullionMass.mesh.isVisible = true;
      Mullion.storey = 1;
      MullionMass.height = Mullion.height;
      MullionMass.revitMetaData.elementId = id
      MullionMass.type = "Mass";
      MullionMass.massType = "Mullion";

      talkingAboutLevel.addObjectToLevel(MullionMass, false);
      MullionMass.storey = 1;
      StoreyMutation.assignStorey(MullionMass);
      curtainWallsData.push(Mullion);
      curtainWallComponents.push(Mullion);
      onSolid(Mullion);

      horizontalMullion.structure_id = store.activeLayer.structure_id;
      let horizontalMullionMass = new Mass(horizontalMullion);
      horizontalMullion.type = "mass"; //throwAway is overwritten when mesh is added to level
      // MullionMass.autoInterior = true;
      horizontalMullionMass.room_type = "mass";
      // MullionMass.assignProperties();
      horizontalMullionMass.mesh.isVisible = true;
      horizontalMullion.storey = 1;
      horizontalMullionMass.height = horizontalMullion.height;
      horizontalMullionMass.revitMetaData.elementId = id;
      horizontalMullionMass.type = "Mass";
      horizontalMullionMass.massType = "Mullion";

      talkingAboutLevel.addObjectToLevel(horizontalMullionMass, false);
      horizontalMullionMass.storey = 1;
      StoreyMutation.assignStorey(horizontalMullionMass);
      curtainWallsData.push(horizontalMullion);
      curtainWallComponents.push(horizontalMullion);
      onSolid(horizontalMullion);

      if (level == 0) {
        const options = {
          uniqueObject: true,
          sendOriginalToInfinity: false,
          arrayOperation: false,
        }
        const lowerHorizontalMullion = pasteObject(horizontalMullion, options);
        lowerHorizontalMullion.position.y -= DisplayOperation.getOriginalDimension(
          panelsHeight[i],
          "millimeter"
        ) + (1.5 * mullionWidth);
        curtainWallsData.push(lowerHorizontalMullion);
        curtainWallComponents.push(lowerHorizontalMullion);
      }

      if (Math.round((panelOffset + totalMullionWidth + fixedMullionWidth) * 10) / 10 >= Math.round(length * 10) / 10) {
        let lastMullionOnLevel;
        if (i == 0 || i == panelsWidth.length + 1) {
          lastMullionOnLevel = createCustomMesh(cornerMullionPoints, tempPanelHeight, null);
          lastMullionOnLevel.cornerMullion = true;
        } else {
          lastMullionOnLevel = createCustomMesh(mullionPoint, tempPanelHeight, null);
        }
        x =
          point[0] +
          orientation[0] *
          (totalMullionWidth + panelOffset + mullionWidth / 2);
        y = point[2] + totalMullionHeight + (lastMullionOnLevel.cornerMullion ? tempMullionWidth : mullionWidth) +
          DisplayOperation.getOriginalDimension(panelsHeight[i], "millimeter") / 2;
        z =
          point[1] +
          orientation[2] *
          (totalMullionWidth + panelOffset + mullionWidth / 2);
        lastMullionOnLevel.rotation.y = angle;
        lastMullionOnLevel.position = new BABYLON.Vector3(x, y, z);
        const mullionWidthForScaling = lastMullionOnLevel.cornerMullion ? tempMullionWidth : (2 * mullionWidth);
        lastMullionOnLevel.scaling.y *= (tempPanelHeight + mullionWidthForScaling) / tempPanelHeight;
        lastMullionOnLevel.structure_id = store.activeLayer.structure_id;
        let MullionMass = new Mass(lastMullionOnLevel);
        lastMullionOnLevel.type = "mass"; //throwAway is overwritten when mesh is added to level
        // MullionMass.autoInterior = true;
        MullionMass.room_type = "mass";
        // MullionMass.assignProperties();
        MullionMass.mesh.isVisible = true;
        lastMullionOnLevel.storey = 1;
        MullionMass.height = lastMullionOnLevel.height;
        MullionMass.revitMetaData.elementId = id;
        MullionMass.type = "Mass";
        MullionMass.massType = "Mullion";

        talkingAboutLevel.addObjectToLevel(MullionMass, false);
        MullionMass.storey = 1;
        StoreyMutation.assignStorey(MullionMass);
        curtainWallsData.push(lastMullionOnLevel);
        curtainWallComponents.push(lastMullionOnLevel);
        onSolid(lastMullionOnLevel);

        mullionsWidthTemp = [];
        totalMullionWidth = 0;
        panelOffset = 0;
        totalMullionHeight += previousHeight;

        level++;
      }
      previousHeight = tempPanelHeight;
    }

    // Create panels
    let mullionWidth = 0;
    let totalPanelsWidth = 0;
    let totalPanelsHeight = 0;
    previousHeight = 0;
    for (let i = panelsWidth.length - 1; i >= 0; --i) {
      let height = DisplayOperation.getOriginalDimension(
        panelsHeight[i],
        "millimeter"
      );
      if (DisplayOperation.getOriginalDimension(panelsWidth[i], "millimeter") == 0) {
        mullionWidth += mullionsWidth[i];
        continue;
      }

      const panelPoint = getPanelPoints(panelPoints, panelsWidth[i]);
      const panel = createCustomMesh(panelPoint, height, null);
      mullionWidth += mullionsWidth[i];
      let panelWidth = DisplayOperation.getOriginalDimension(
        panelsWidth[i],
        "millimeter"
      );

      let point = startPoint;

      if (sum(...orientation) > 0) {
        // point = endPoint;
      }
      let x =
        point[0] +
        orientation[0] *
        (mullionWidth + totalPanelsWidth + panelWidth / 2);
      let y = point[2] + totalPanelsHeight + height / 2 + mullionsWidth[i];
      let z =
        point[1] +
        orientation[2] *
        (mullionWidth + totalPanelsWidth + panelWidth / 2);
      panel.rotation.y = angle;

      panel.position = new BABYLON.Vector3(x, y, z);
      totalPanelsWidth += panelWidth;
      // panel.position.x = DisplayOperation.getOriginalDimension(1087.5, "millimeter")*i + mullionWidth*i
      panel.structure_id = store.activeLayer.structure_id;
      let panelMass = new Mass(panel);
      panel.type = "mass"; //throwAway is overwritten when mesh is added to level
      panel.material = materialLoader.loadWallMaterial("GLASS");
      panelMass.autoInterior = true;
      panelMass.room_type = "mass";
      panelMass.revitMetaData.elementId = id
      // panelMass.assignProperties();
      panelMass.mesh.isVisible = true;
      panel.storey = 1;
      panelMass.height = panel.height;
      panelMass.storey = 1;
      panelMass.type = "Mass";
      panelMass.massType = "Panel";
      panel.isVisible = true;
      talkingAboutLevel.addObjectToLevel(panelMass, false);
      StoreyMutation.assignStorey(panelMass);
      curtainWallsData.push(panel)
      curtainWallComponents.push(panel);

      if (Math.round((totalPanelsWidth + mullionWidth + fixedMullionWidth) * 10) / 10 >= Math.round(length * 10) / 10) {
        mullionWidth = 0;
        totalPanelsWidth = 0;
        totalPanelsHeight += previousHeight;
      }
      previousHeight = height;
      // onSolid(panel);
    }
  }
  return curtainWallsData
};

const createCurtainWalls = (curtainWallsData, materialList) => {
  const curtainWallMeshes = [];
  const curtainPanelsMullionsMap = {};
  for (const type in curtainWallsData) {
    // if (type != "Panels" || type != "mullions") return;
    if (type == "CurtainWallInsertMap") continue;
    const components = curtainWallsData[type];
    for (const componentData in components) {
      try {
        const component = components[componentData];
        const data = component["data"];
        for (const dataType in component) {
          if (dataType === "data") continue;
          const meshesData = component[dataType];
          if (!meshesData || !data) return;
          const mesh = createCustomMeshFromRevitExport(
            data,
            component,
            materialList,
            { isMassType: true }
          );

          if (!mesh) continue;
          let curtainWallDS = new Mass(mesh);
          mesh.structure_id = store.activeLayer.structure_id;

          mesh.type = "mass"; //throwAway is overwritten when mesh is added to level
          curtainWallDS.storey = 1;

          curtainWallDS.room_type = "Default";
          curtainWallDS.massType = (type == "Panels" ? "Panel" : "Mullion");
          // curtainWallDS.importType = "speckleRevitImport";
          curtainWallDS.revitMetaData = { elementId: data.elementId };
          mesh.storey = 1;
          curtainWallDS.storey = 1;
          curtainWallDS.structure_id = store.activeLayer.structure_id;
          curtainPanelsMullionsMap[data.elementId] = mesh;

          const structureCollection = StructureCollection.getInstance();
          const talkingAboutStructure = structureCollection.getStructureById(
            store.activeLayer.structure_id
          );
          const talkingAboutLevel = talkingAboutStructure.getLevelByName("01");
          talkingAboutLevel.addObjectToLevel(curtainWallDS, false);
          const storey = checkStorey(data?.levelName);
          if (storey) {
            storey.addElement(curtainWallDS);
          } else {
            StoreyMutation.assignStorey(curtainWallDS);
          }
          onSolid(mesh);

          curtainWallMeshes.push(mesh);
        }
      }
      catch (e) {
        console.warn(e, "Corrupt curtain component data");
      }
    }
  }
  return {curtainWallMeshes, curtainPanelsMullionsMap};
}

const isMassPanelOrLinkedModel = (mesh) => {
  const component = mesh.getSnaptrudeDS();
  if (!component) return false;
  return ["Panel", "LinkedModel"].includes(component.massType);
}

const getOrientationAndLengthOfWall = (start, end) => {
  let orientation = [0, 0, 0];
  let length = 0;
  // if (start[0].toFixed(3) == end[0].toFixed(3)) {
  //   length = Math.abs(start[1] - end[1]);
  //   orientation[1] = start[1] > end[1] ? -1 : 1;
  // } else if (start[1].toFixed(3) == end[1].toFixed(3)) {
  //   length = Math.abs(start[0] - end[0]);
  //   orientation[0] = start[0] > end[0] ? -1 : 1;
  // }
  const startVector = BABYLON.Vector3.FromArray([start[0], start[2], start[1]]);
  const endVector = BABYLON.Vector3.FromArray([end[0], end[2], end[1]]);
  length = BABYLON.Vector3.Distance(startVector, endVector);

  const curtainWallVector = endVector.subtract(startVector);
  orientation = curtainWallVector.normalize().asArray();

  return { orientation, length };
};

const getPanelPoints = (panelPoints, width) => {
  width = DisplayOperation.getOriginalDimension(width, "millimeter") / 2;
  for (let i = 0; i < panelPoints.length; ++i) {
    panelPoints[i][0] = panelPoints[i][0] > 0 ? width : -width;
  }
  return panelPoints;
};

const transformMullionToHorizontal = (mullion, verticalHeight, width) => {
  mullion.rotation.z = Math.PI / 2;
  let ratio = width / verticalHeight;
  mullion.scaling.y *= ratio;
}

export { createCurtainWalls, isMassPanelOrLinkedModel };
