import { RotateOperation } from "../modules/meshoperations/rotateOperation";
import {
  onMatMode,
  exportMap,
  detectMassAddEvent,
  onExplorerCam,
  onEditPolygon,
  angleMeasureEvent,
  arrayFunction,
  measureMeshEvent,
  moveBlocks,
  onDrawingMode,
  onCircleDrawingMode,
  onRemoveElementsMode,
  onSelectTool,
  onSplitFaceMode,
  rotateMeshEvent,
  setScaleEvent,
  intSelect,
  onStaircaseTool,
  hideObject,
  showAllObjects,
  isolateObject,
  onExtrude,
  onDownload3DS,
  onDownloadFBX,
  onDownloadOBJ,
  onDownloadRVT,
  onDownloadDWG,
  onDownloadIFC,
  onDownloadPDF,
  hideRoofs,
  zoomInOnClick,
  zoomOutOnClick,
  setStoreyOneHeightEvent,
  removeMouseEvents,
  onPanClick,
  onOrbitClick,
  zoomExtentsOnClick,
  editObject,
  onDownloadTrude,
  onCommentMode,
  handleLatLongEvent,
  disableOnCommentMode, onDownloadStandard,
} from "./toolbarEvents";
import {
  changeCameraView,
  toggleOrthoView,
  createBuilding,
  toggleShadows,
  toggleHiddenLine,
} from "./sceneFuncs";
import {viewOperation} from "./viewOperation";
import { changeTextureDetail } from "./mats";
import { goIntoTwoD, goOutOfTwoD } from "./twoDimension";
import { labelView } from "./labelView";
import { initFloorplan } from "./twoDimension";
import { store } from "../modules/utilityFunctions/Store";
import { CMRedo, CMUndo } from "./sceneStateFuncs";
import { handleDeleteKeyEvent } from "./keyEvents";
import sunpath from "../modules/sunpath/sunpathOperations";
import { AutoInterior } from "../modules/roomOperations/autoInterior/autoInterior";
import { setUserSettingAndRecord } from "../modules/extrafunc";
import { runOnUiClick } from "../modules/uiClickOperations/uiClickOperations";
import { createMass, speckleRevitImport } from "../modules/speckleRevitImport";
import { onClickShareScreen } from "../modules/socket/collaboration";
import { cameraController } from "../modules/cameraControl/cameraController";
import { resetDefaultCursor } from "../../containers/editor/cursorHandler";
import { onClickLock, onClickUnlock } from "../modules/locker/lockerEvents";
import coveToolHelpers from "../modules/covetool";
import { revitDataPreparation } from "../modules/revitExport/revitExport";
import flipOperator from "../modules/meshoperations/flipOperation";
import { toggleBuilding } from "../modules/covetool/utils";
import exportGLB from "../modules/exports/gltfExporter";


let isAutoDimensionsShown = false;
let storeyNum = 1;
export const handleToolbar = (selectedTool, fileName, options, callback) => {
  if (selectedTool !== "escape" && selectedTool !== "disableComment") {
    // iPad escape
    runOnUiClick();
  }
  switch (selectedTool) {
    case "REMOVE":
      removeMouseEvents();
      cameraController.activateOrbitMode();
      store.idle = true;
      resetDefaultCursor();
      break;

    // DRAW TOOLS
    case "pointer":
      // console.log("RUN onSelectTool()");
      onSelectTool();
      break;
    case "draw":
      store.sketchMode = "solid";
      onDrawingMode();
      break;
    case "drawCircle":
      store.sketchMode = "solid";
      onCircleDrawingMode();
      break;
    case "copy":
      arrayFunction();
      break;
    case "move":
      // console.log("RUN moveBlocks()");
      moveBlocks();
      break;
    case "edit":
      editObject();
      break;
    case "pushpull":
      editObject();
      break;
    case "rotate":
      // console.log("RUN ROTATE METHOD");
      rotateMeshEvent();
      break;
    case "verticalResize":
      onEditPolygon();
      break;
    case "addLayer":
      // console.log("RUN onSplitFaceMode()");
      onSplitFaceMode();
      break;
    case "addLayer3d":
      // console.log("RUN onSplitFaceMode()");
      onSplitFaceMode();
      break;
    case "removeLayer":
      // console.log("RUN onRemoveElementsMode()");
      onRemoveElementsMode();
      break;
    case "flipX":
      flipOperator.flip("x");
      break;
    case "flipY":
      flipOperator.flip("y");
      break;
    case "flipXY":
      flipOperator.flip("z");
      break;
    case "moveUp":
      console.log("call onExtrude()");
      // onExtrude();
      break;
    case "drawVoid":
      store.sketchMode = "void";
      onDrawingMode();
      break;

    // IMPORT
    case "image":
      // console.log("Load image")
      // const img = new Image(1109, 1000);
      // img.src = "https://i.ibb.co/fQQ2MZV/sketch1.jpg";
      // initFloorplan(img, storeyNum, storeyNum.toString())
      // ++storeyNum;
      break;
    case "loadTopography":
      console.log("Load topography");
      exportMap(options);
      break;

    case "importRevit":
      // testCurtainWall();
      break;

    // MEASURE TOOLS
    case "scaling":
      setScaleEvent();
      break;
    case "scale":
      measureMeshEvent();
      break;
    case "angle":
      angleMeasureEvent();
      break;
    case "setdatum":
      setStoreyOneHeightEvent();
      break;
    case "latlong":
      handleLatLongEvent();
      break;

    // VIEWS
    case "snapshot":
      viewOperation.saveSceneScreenShot(store.floorkey);
      break;
    case "screenshot":
      onExplorerCam();
      break;
    case "frontView":
      changeCameraView("frontView");
      break;
    case "topView":
      changeCameraView("topView");
      break;
    case "backView":
      changeCameraView("backView");
      break;
    case "leftView":
      changeCameraView("leftView");
      break;
    case "rightView":
      changeCameraView("rightView");
      break;
    case "format" /* SUNPATH DIAGRAM */:
      sunpath.toggleSunpath();
      break;
    case "graphical":
      store.areShadowsEnabled = !store.areShadowsEnabled;
      sunpath.toggleShadows();
      break;
    case "Textured":
      // console.log("TEXTURED");
      changeTextureDetail("texture");
      break;
    case "Monochrome":
      // console.log("Monochrome");
      changeTextureDetail("color");
      break;
    case "Orthographic":
      toggleOrthoView();
      break;
    case "Hidden Line":
      toggleHiddenLine(options.isActive);
      break;
    case "analysis_sda":
    case "analysis_ase":
    case "eui_trigger":
      coveToolHelpers.startDaylightAnalysis(callback)
    case "daylight_settings":
      break;

    // TOGGLE 2D 3D VIEW
    case "2d-view":
      // console.log("go into 2d");
      goIntoTwoD(true, false);
      break;
    case "3d-view":
      // console.log("go into 3d");
      goOutOfTwoD();
      break;

    // AUTOMATE
    case "autoResize":
      createBuilding();
      break;
    case "autoReorder":
      detectMassAddEvent();
      break;
    case "auto":
      if (!store.userSettingsInStructure.autoInteriorState) {
        AutoInterior.on();
        setUserSettingAndRecord("autoInteriorState", true);
        // $(element).css('border','1px solid #d30041');
      } else {
        /*
              AutoSave and UI state change for AutoInterior off
              happens in postExecute and postUnExecute callback as
              CommandManager executes on click as well as redo!
              */
        AutoInterior.off();
      }
      break;
    case "autoScale":
      if (!isAutoDimensionsShown) labelView.displayAutoDimensions();
      else labelView.resetOperation();
      isAutoDimensionsShown = !isAutoDimensionsShown;
      break;

    // RIGHT SIDE BAR
    // MATERIALS
    case "applyMaterail":
      // store.$scope.selectedMaterial = "https://i.ibb.co/3zqzTDt/RAL-1026-De-Aa-B25.jpg";
      // store.$scope.selectedMaterialType = "Colors";
      store.$scope.selectedMaterial =
        "https://i.ibb.co/fdK9NVX/Glass-Corrugated.jpg";
      store.$scope.selectedMaterialType = "Glass";
      onMatMode();
      break;
    case "applyDoors":
      const prop = {
        propurl:
          "https://app.snaptru.de/media/media/objects/1000_x_2100_Swing_Single_Shutter_Flush.babylon",
        subType: "singleDoor",
      };
      const tabs = {
        title: "Door",
      };
      intSelect(prop, tabs);
      break;
    case "applyWindows":
      const windowProp = {
        propurl:
          "https://app.snaptru.de/media/media/objects/1200_x_1200_Casement_2_shutter.babylon",
        subType: "casementDouble",
      };
      const windowTabs = {
        title: "Window",
      };
      intSelect(windowProp, windowTabs);
      break;
    case "applyStaircase":
      // alert("Alert stair case");
      onStaircaseTool("dogLegged");
      break;
    case "applyFurniture":
      const furnitureProp = {
        propurl:
          "https://app.snaptru.de/media/media/objects/1400_x_800_Standing_desk.babylon",
        subType: "",
      };
      const furnitureTabs = {
        title: "Office",
      };
      intSelect(furnitureProp, furnitureTabs);
      break;

    // RIGHT SIDEBAR OPEN
    case "commentmode":
      onCommentMode();
      break;
    case "disableComment":
      disableOnCommentMode();
      break;
    case "brainstormmode":
      onClickShareScreen.click();
      if(onClickShareScreen.isClicked()){
        window.analytics.track('clicked brainstorm');
      }else{
        window.analytics.track('exited brainstorm');
      }
      break;
    // BOTTOM LEFT BAR
    case "chatwithus":
      if (window.HubSpotConversations) {
        const status = window.HubSpotConversations.widget.status();
        if (status.loaded) {
          window.HubSpotConversations.widget.remove();
        } else {
          window.HubSpotConversations.widget.load();
        }
      }
      break;
    case "Undo":
      CMUndo();
      break;
    case "Redo":
      CMRedo();
      break;
    case "Bulb0":
      hideObject();
      break;
    case "Bulb":
      showAllObjects();
      break;
    case "NoView":
      hideRoofs();
      break;
    case "HideBuilding":
      toggleBuilding()
      break;
    case "ObjectIcon":
      isolateObject();
      break;
    case "Lock":
      onClickLock();
      break;
    case "Unlock":
      onClickUnlock();
      break;
    case "zoomIn":
      zoomInOnClick();
      break;
    case "zoomOut":
      zoomOutOnClick();
      break;
    case "zoomExtents":
      zoomExtentsOnClick();
      break;
    case "delete_icon":
      handleDeleteKeyEvent();
      break;
    case "pan":
      onPanClick();
      break;
    case "orbit":
      onOrbitClick();
      break;
    case "searchBox":
      window.open("https://help.snaptrude.com/", "_blank");
      break;

    // EXPORT
    case "3ds":
      onDownloadStandard(fileName, "3ds");
      break;
    case "fbx":
      onDownloadStandard(fileName, "fbx");
      break;
    case "obj":
      onDownloadStandard(fileName, "obj");
      break;
    case "rvt":
      onDownloadRVT(fileName);
      break;
    case "dwg":
      onDownloadDWG(fileName);
      break;
    case "ifc":
      onDownloadIFC(fileName);
      break;
    case "pdf":
      onDownloadPDF(fileName);
      break;
    case "trude":
      onDownloadTrude(fileName);
      break;
    case "glb":
      exportGLB(fileName, true, true, true);
      break;
    case "revitExport":
      async function onDownloadRevitFile() {
        const dlAnchorElem = document.getElementById("downloadAnchorElem");

        let jsonText = revitDataPreparation.getStructureCollectionForExport();
        let encodedJson = encodeURIComponent(jsonText);
        dlAnchorElem.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodedJson);
        dlAnchorElem.setAttribute("download", fileName + ".trude");
        dlAnchorElem.click();

        let event = new CustomEvent("exportProgress", {
          detail: {
            progress: 0,
            text: 'Done',
            showProgressFooter: false
          },
        })
        window.dispatchEvent(event);
      }

      onDownloadRevitFile();

      break;
    // DEFAULT
    default:
      // console.log("DEFAULT CASE.");
      break;
  }
};

/**
 * A back channel handler to change UI from snaptrude core
 */
export const UIHandler = (function (){
  let selector = null;

  function setSelector(sel) {
      selector = sel;
  }

  function clearMenu() {
    selector("REMOVE", "top");
  }

  function setTool(tool) {
    selector(tool, "top")
  }

  return {
    setSelector,
    setTool,
    clearMenu
  }
})();
