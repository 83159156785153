import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import cube from "../../assets/icons/objectImage.svg";
import uploadCloudIcon from "../../assets/images/upload.svg";

const DropHere = styled.div`
  position: absolute; 
  top: 0;
  bottom: 0; 
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  background: #FFFFFF;
  z-index: 10;
  border: 2px dashed #EB1E45;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px;
`
const DragOverHere = styled.div`
  position: absolute;
  top: 0;
  bottom: 0; 
  left: 0;
  right: 0;
`

export const SnaptrudeDropzone = ({setUploadedFile, dropzoneAccept, multiple = false}) => {
  
  const _getFilePreview = function (file){
    if (file.type.includes("image")){
      return URL.createObjectURL(file);
    }
    else {
      return cube;
    }
  };
  
  const onDrop = useCallback(acceptedFiles => {
    if(multiple){
      const newUploadedFiles = [];
      acceptedFiles.forEach((file, index) => {
        if(index>=10) return;
        newUploadedFiles.push({
          preview: _getFilePreview(file),
          file: file 
        })
      })
      setUploadedFile(newUploadedFiles);
    }else{
      setUploadedFile({
        preview: _getFilePreview(acceptedFiles[0]),
        file: acceptedFiles[0]
      });
    }
  }, [])
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
    accept: dropzoneAccept,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <DropHere>
            <img src={uploadCloudIcon} alt="Upload" />
            <div>Drop files to upload</div>
          </DropHere> :
          <>
            <DragOverHere />
            <div>Select from computer</div>
          </>
      }
    </div>
  )
}