import { delayedExecutionEngine } from "../utilityFunctions/delayedExecution";
import { store } from "../utilityFunctions/Store";
import { arrayFunctionOperator } from "../../libs/arrayFunctionEvents";

/**
 * Add stuff here that has to be run on clicking the UI.
 * Do not add stuff here like handle toolbar, canvas listeners et cetra.
 */
const runOnUiClick = () => {
  if(delayedExecutionEngine.kitneBacheHai()){
    delayedExecutionEngine.executeAll();
  } else {
    arrayFunctionOperator.cancelOperation();
  }
  // if(store.ACTIVE_EVENT.event = "arrayFunction"){
  //   arrayFunctionOperator.cancelOperation();
  // }
  
  // onSolid();
  // setLayerTransperancy();
};

export {
  runOnUiClick,
};
