import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import * as constants from "./constants";
import Input from "../../components/Input/index";
import Button from "../../components/Button/index";
import link from "../../assets/icons/link.svg";
import { shareModal } from "./styles";
import { RecentBox } from "../../components/SortByBox/index";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";
import { useSelector } from "react-redux";
import { socialConnect } from "../../snaptrude/modules/social/socialSocket";
import NamedAvatar from "../../components/Avatar/NamedAvatar";
import _ from "lodash";
import Divider from "../../components/Divider";
import Tooltip from "../../components/Tooltip";
import { validateEmail } from "../../common/utils/stringChecks";

const StyledDiv = styled.div`
  .input-div {
    display: flex;
    margin-top: 1rem;
    justify-content: space-around;
  }
  .items-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link-icon {
    width: 1.5rem;
  }
  .profile-img {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    border: 0.1rem solid ${(props) => props.theme.colors.greyishBlack};
  }
  .dropdown-div {
    margin-top: -0.4rem;
    margin-left: -0.4rem;
  }
  span {
    align-items: center;
    display: flex;
    align-items: center;
  }
  span p {
    color: ${(props) => props.theme.colors.gray};
  }
  li {
    list-style: none;
  }
  h2 {
    font-weight: normal;
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.darkGrey};
  }
  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primeBlack};
  }
  ul {
    padding: 1rem;
    margin: 0;
  }
  // span > img {
  //   margin: 0 0.75rem 0 0;
  // }
`;

export const ShareBody = (props) => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [publicShare, setPublicShare] = useState(constants.RECENT_BOX_DROPDOWN_OPTIONS[0]);
  const [hasEditPermissions, setHasEditPermissions] = useState(false);
  const [isSendInviteLoading, setIsSendInviteLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const roles = useSelector(state => state.roles);
  const {teamlist, projectMetadata} = useSelector(state => {
    return {
        teamlist: state.teams.teamlist,
        projectMetadata: state.projectMetadata
    }
});
  
  useEffect(() => {
    // REMOVE DUPLICATES IF ANY
    let prev = null;
    const newRoleList = roles.roles.filter((role) => {
      if(_.isEqual(role, prev)) return false;
      prev = role;
      return true;
    })
    setRoleList(newRoleList);

    const email = localStorage.getItem("email");
    newRoleList.forEach(role => {
      if(role.email === email){
        if(role.permission === "owner" || role.permission === "editor"){
          setHasEditPermissions(true);
        }
      }
    });
    const option = roles.isPublic ? 1 : 0;
    setPublicShare(constants.RECENT_BOX_DROPDOWN_OPTIONS[option]);
  }, [roles])

  const handleSendInvite = async () => {
    if(!inviteEmail) return;
    const sanitizedInviteEmail = inviteEmail.trim();
    if(!validateEmail(sanitizedInviteEmail)){
      showToast(`${sanitizedInviteEmail} is not a valid email address.`);
      return;
    }
    for(let i in roles.roles){
      if(roles.roles[i].email === sanitizedInviteEmail) {
        showToast(`${sanitizedInviteEmail} is already present and has ${roles.roles[i].permission} permission.`);
        return;
      }
    }
    // const resp = await isSnaptrudeUser(inviteEmail);
    // if(resp.status === 404) {
		// 	showToast(`${inviteEmail} is not a Snaptrude user.`, 3000, TOAST_TYPES.error);
    //   return;
		// }
    const user = {email: sanitizedInviteEmail}
    const defaultPermission = "viewer";
    handlePermissionChange(user, defaultPermission);
  }

  const handlePublicShareChange = (value) => {
    setPublicShare(value);
    if(value === constants.RECENT_BOX_DROPDOWN_OPTIONS[1]){
      window.analytics.track('share modal - made project public');
      socialConnect.makePublic()
      .then(success => {
        if(success) showToast("Anyone with the link can view.", 3000, TOAST_TYPES.success);
        else showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
      })
    }else{
      window.analytics.track('share modal - unmade project public');
      socialConnect.unmakePublic()
      .then(success => {
        if(success) showToast("Only those added can view.", 3000, TOAST_TYPES.success);
        else showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
      })
    }
  }

  const handlePermissionChange = async (user, newPermission) => {
    if(newPermission === "remove"){
      window.analytics.track('share modal - removed permission');
      const success = await socialConnect.removePermission(user, newPermission);
      if(success) showToast(`${user.email} has been successfully removed.`, 3000, TOAST_TYPES.success);
      else showToast("Something went wrong, try again", 3000, TOAST_TYPES.error);
    }else{
      setIsSendInviteLoading(true);
      const success = await socialConnect.updatePermission(user, newPermission);
      setIsSendInviteLoading(false);
      if(success){
        setInviteEmail("");
        showToast(`${user.email} now has ${newPermission} permission.`, 3000, TOAST_TYPES.success);
      } 
      else showToast("Something went wrong, try again.", 3000, TOAST_TYPES.error);
    }
  }
  
  let currentProjectTeam = null;
  if(projectMetadata.isTeamsProject){
    currentProjectTeam = teamlist.find(team => team.id === projectMetadata.team.id)
  }

  return (
    <StyledDiv>
      { true? <div className="input-div">
        <Input
          placeholder={constants.SHARE_EMAIL}
          customInputContainerStyle={shareModal.customInputContainerStyle}
          value={inviteEmail}
          onPress={(evt) => {
            window.analytics.track('share modal - typed invite mail');
            setInviteEmail(evt.target.value)}}
        />
        <div>
          <Button
            primary={true}
            title={constants.SEND_INVITE}
            customButtonStyle={shareModal.customSendInviteButtonStyle}
            onPress={() => {
                window.analytics.track('share modal - clicked send invite');
                handleSendInvite();
              }
            }
            isLoading={isSendInviteLoading}
          />
        </div>
      </div> : null}
      <div>
        <ul>
          {
            projectMetadata.isTeamsProject &&
              <li>
                  <div className="items-div">
                    <span data-tip data-for={`${projectMetadata.team.name}-team-member-list`}>
                    <NamedAvatar id={projectMetadata.team.name} text={projectMetadata.team.name} tooltipHidden={true} /> 
                      <h2>{projectMetadata.team.name}</h2>
                    </span>
                    {/* {
                      currentProjectTeam &&
                        <Tooltip place="right" id={`${projectMetadata.team.name}-team-member-list`}>
                          {
                            currentProjectTeam.members.filter(teamMember => teamMember.status === "approved").map(teamMember => {
                              return <div>{teamMember.user.name || teamMember.user.email}</div>
                            })
                          }
                        </Tooltip>
                    } */}
                    <span>
                      <span style={shareModal.selectedOptionStyle}>Team</span>
                    </span>
                  </div>
                  <Divider />
              </li>
          }
          {
            roleList.map(role => {
              const displayName = role.email || role.user.email || role.user.firstName;
              if(!displayName) return <></>
              return <li>
                  <div className="items-div">
                    <span>
                    <NamedAvatar id={displayName} text={displayName} tooltipPlace="right" /> 
                      <h2>{displayName}</h2>
                    </span>
                    <span>
                      {
                        role.permission === "owner" ? 
                        <span style={shareModal.selectedOptionStyle}>Creator</span>
                        : 
                        hasEditPermissions ? 
                        <RecentBox
                          selectedOption={role.permission}
                          customBoxStyles={shareModal.boxStyle}
                          customParentDivStyles={shareModal.customParentDivStyle}
                          customDropDownStyle={shareModal.customDropDownStyle}
                          customSelectedTextStyle={shareModal.selectedOptionStyle}
                          options={["viewer", "editor", "remove"]}
                          selectOption={(value) => {
                            window.analytics.track('share modal - changed permission');
                            handlePermissionChange(role.user, value)}
                          }
                          align="left"
                          withIcon={true}
                        />
                        :
                        <span style={shareModal.selectedOptionStyle}>{role.permission}</span>
                      }
                    </span>
                  </div>
                </li>
              })
          }
          <li>
            <div className="items-div">
              <span>
                <img className="link-icon" src={link} alt="link icon" />
                <RecentBox
                  selectedOption={publicShare}
                  customBoxStyles={shareModal.boxStyle}
                  customDropDownStyle={{
                    ...shareModal.permsissionDropdown,
                  }}
                  customSelectedTextStyle={{
                    ...shareModal.selectedOptionStyle,
                    ...shareModal.selectedMainText,
                  }}
                  options={constants.RECENT_BOX_DROPDOWN_OPTIONS}
                  selectOption={handlePublicShareChange}
                  align="left"
                  seperate={false}
                  withIcon={true}
                />
              </span>
              <span>
                <span style={shareModal.selectedOptionStyle}>Viewer</span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </StyledDiv>
  );
};

ShareBody.propTypes = {
  customProps: PropTypes.any,
};
