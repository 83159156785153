import BABYLON from "../modules/babylonDS.module.js";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import { StructureCollection } from "../modules/snaptrudeDS/structure.ds.js";
import { updateModifications } from "./sceneStateFuncs.js";
import { getFace } from "./faceFuncs.js";
import { DisplayOperation } from "../modules/displayOperations/displayOperation.js";
var onStairDrawDown = function (evt) {
  var staircase = store.scene.getMeshByName("StairsTemp");
  // var $scope = angular.element(appElement).scope();
  // $scope = $scope.$$childHead;
  console.log(staircase);
  if (staircase) {
    // if ($scope.searchTextStair[0].toLowerCase() === "straight")
    //   staircase.name = "Straight_Stairs";
    // if ($scope.searchTextStair[0].toLowerCase() === "double")
    //   staircase.name = "Double_Stairs";
    // else staircase.name = "Straight Stairs"; 
    staircase.name = "Straight Stairs";
    staircase.type = "Staircase";

    let structures = StructureCollection.getInstance();
    staircase.structure_id = staircase.parent.structure_id;
    let str = structures.getStructureById(staircase.structure_id);
    let level = str.getLevelByUniqueId(
      str.getObjectByUniqueId(staircase.parent.uniqueId).level_id
    );
    level.addMeshToLevel(staircase, false);

    updateModifications();
  }
};

var onStairDrawUp = function (evt) {};

var onStairDrawMove = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return mesh.name === "wall";
    }
  );
  // var step_size = [0.775, 1.0, 3.6];
  var step_size = [1.0, 0.775, 3.6];
  if (pickInfo.hit) {
    // var $scope = angular.element(appElement).scope();
    // $scope = $scope.$$childHead;
    if (pickInfo.pickedMesh.name === "wall") {
      var face = getFace(pickInfo);
      var angle = getWallAngle(face);
      var staircase;
      if (store.scene.getMeshByName("StairsTemp")) {
        staircase = store.scene.getMeshByName("StairsTemp");
      } else {
        staircase = drawStaircase(
          pickInfo,
          "searchTextStair[0]", /* UNKNOWN: $scope.searchTextStair[0] */
          step_size
        );
      }
      var bbinfo = staircase.getBoundingInfo();
      let bbinfo1 = pickInfo.pickedMesh.getBoundingInfo();
      // var yoffset = bbinfo.boundingBox.minimumWorld.y;
      // console.log(yoffset);
      staircase.position = pickInfo.pickedPoint;
      staircase.position.y =
        (pickInfo.pickedMesh.getBoundingInfo().maximum.y +
          pickInfo.pickedMesh.getBoundingInfo().minimum.y) /
        2;
      staircase.position.x +=
        bbinfo.boundingBox.extendSizeWorld.x * Math.cos(angle);
      staircase.position.z -=
        bbinfo.boundingBox.extendSizeWorld.z * Math.sin(angle);
      staircase.rotation.y = Math.PI + angle;
      staircase.parent = pickInfo.pickedMesh;

      let vw = bbinfo1.boundingBox.vectorsWorld;
      let vw2 = bbinfo.boundingBox.vectorsWorld;
      // let end = (vw2[1].add(vw2[5])).scale(0.5);
      // st = (vw[5].add(vw[2])).scale(0.5);
      // // /let end = Object.assign({},staircase.position);
      // end.y = st.y;
      // DisplayOperation.drawOnMove(st, end);
      // DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st, end));
      /*
            TODO: Draw a dim line between pickInfo.pickedMesh.position to door.position

            and show the distance as BABYLON.Distance()
             */

      let end = vw2[1]
        .add(vw2[6])
        .scale(0.5)
        .add(vw2[7].add(vw2[4]).scale(0.5))
        .scale(0.5);
      let st1 = null;
      let st2 = null;
      if (vw[0].add(vw[4]).scale(0.5) > vw[0].add(vw[2]).scale(0.5)) {
        st1 = vw[0]
          .add(vw[4])
          .scale(0.5)
          .add(vw[3].add(vw[6]).scale(0.5))
          .scale(0.5);
        st2 = vw[2]
          .add(vw[4])
          .scale(0.5)
          .add(vw[5].add(vw[1]).scale(0.5))
          .scale(0.5);
        end.y = st1.y;
      } else {
        st1 = vw[0]
          .add(vw[2])
          .scale(0.5)
          .add(vw[5].add(vw[3]).scale(0.5))
          .scale(0.5);
        st2 = vw[7]
          .add(vw[4])
          .scale(0.5)
          .add(vw[1].add(vw[6]).scale(0.5))
          .scale(0.5);
        end.y = st2.y;
      }

      if (
        BABYLON.Vector3.Distance(st1, end) < BABYLON.Vector3.Distance(st2, end)
      ) {
        end.y = st1.y;
        DisplayOperation.drawOnMove(st1, end);
        DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st1, end));
      } else {
        end.y = st2.y;
        DisplayOperation.drawOnMove(st2, end);
        DisplayOperation.displayOnMove(BABYLON.Vector3.Distance(st2, end));
      }

      // console.log(face[1], angle)
      if (Math.abs(face[1][0]) > 0) {
        // staircase.position.x += Math.sign(face[1][0]) * bbinfo.boundingBox.extendSizeWorld.x / 1;
        // staircase.rotation.y = angle;
      } else {
        // staircase.rotation.y = -angle;
      }
      if (Math.abs(face[1][2]) > 0) {
        // staircase.position.z += Math.sign(face[1][2]) * bbinfo.boundingBox.extendSizeWorld.z / 1;
        // staircase.rotation.y = angle;
      } else {
        // staircase.rotation.y = -angle;
      }
    }
  }
};

function getWallAngle(coords) {
  // console.log(coords);
  var coord = coords[0];
  var normalVector = coords[1];
  // return Math.atan2(normalVector[2], normalVector[0]);
  if (normalVector[2] === 0) {
    if (normalVector[0] > 0) {
      return Math.PI;
    } else {
      return 0;
    }
  } else if (Math.abs(normalVector[0]) > 0.001) {
    var m = normalVector[2] / normalVector[0];
    return Math.atan2(normalVector[0], normalVector[2]) + Math.PI / 2;
  } else {
    if (normalVector[2] > 0) {
      return Math.PI / 2;
    } else {
      return -Math.PI / 2;
    }
  }
}

function getWallAngleX(coords) {
  var coord = coords[0];
  var normalVector = coords[1];
  return Math.atan2(normalVector[0], normalVector[1]);
}

function getWallAngleZ(coords) {
  var coord = coords[0];
  var normalVector = coords[1];
  return Math.atan2(normalVector[2], normalVector[1]);
}

function drawStaircase(pickInfo, stairType, step_size) {
  if (stairType.toLowerCase() === "straight") {
    return createStraightStair(step_size);
  } else if (stairType.toLowerCase() === "double") {
    console.log(stairType);
    return createDoubleStair(step_size);
  } else {
    return createStraightStair(step_size);
  }
}

function createStraightStair(step_size) {
  // console.log(floor_height, step_size[0]);
  var steps = [];
  for (var i = 0; i < store.floor_height / step_size[1]; i++) {
    var step = BABYLON.Mesh.CreateBox(
      "step" + i,
      1.0,
      store.scene,
      false,
      BABYLON.Mesh.DEFAULTSIDE
    );
    step.scaling.y = step_size[1];
    step.scaling.x = step_size[0];
    step.scaling.z = step_size[2];
    step.position.x += step_size[0] * i;
    step.position.y += step_size[1] * i;
    steps.push(step);
  }
  var mergedMesh = BABYLON.Mesh.MergeMeshes(steps, true, true);
  mergedMesh.name = "StairsTemp";
  var bbinfo = mergedMesh.getBoundingInfo();
  // var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(bbinfo.boundingBox.center);
  mergedMesh.enableEdgesRendering();
  mergedMesh.edgesWidth = 2.0;
  mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}

function createDoubleStair(step_size) {
  var steps = [];
  for (var i = 0; i < store.floor_height / step_size[0] / 2; i++) {
    var step = BABYLON.Mesh.CreateBox(
      "step" + i,
      1.0,
      store.scene,
      false,
      BABYLON.Mesh.DEFAULTSIDE
    );
    step.scaling.y = step_size[1];
    step.scaling.x = step_size[0];
    step.scaling.z = step_size[2];
    step.position.x += step_size[0] * i;
    step.position.y += step_size[1] * i;
    steps.push(step);
  }
  var mergedMesh1 = BABYLON.Mesh.MergeMeshes(steps, true, true);
  var bbinfo = mergedMesh1.getBoundingInfo();
  var centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh1.setPivotPoint(centroid);
  var mergedMesh2 = mergedMesh1.clone(mergedMesh1.name);
  mergedMesh2.rotation.y = Math.PI;
  mergedMesh2.position.z += bbinfo.maximum.z;
  mergedMesh2.position.y += mergedMesh1.getBoundingInfo().maximum.y;
  var mergedMesh = BABYLON.Mesh.MergeMeshes(
    [mergedMesh1, mergedMesh2],
    true,
    true
  );
  mergedMesh.name = "StairsTemp";
  bbinfo = mergedMesh.getBoundingInfo();
  centroid = BABYLON.Vector3.Center(bbinfo.maximum, bbinfo.minimum);
  mergedMesh.setPivotPoint(centroid);
  mergedMesh.enableEdgesRendering();
  mergedMesh.edgesWidth = 2.0;
  mergedMesh.edgesColor = new BABYLON.Color4(0, 0, 1, 1);
  return mergedMesh;
}
export {
  onStairDrawDown,
  onStairDrawUp,
  onStairDrawMove,
  getWallAngle,
  getWallAngleX,
  getWallAngleZ,
  drawStaircase,
  createStraightStair,
  createDoubleStair,
};
