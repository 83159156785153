"use strict";
import BABYLON from "../babylonDS.module.js";
import { store } from "../utilityFunctions/Store.js";
import { isTouchEvent } from "../extrafunc.js";
import _ from "lodash";
import { addTemporaryCursor, removeTemporaryCursor, updateCursor } from "../../../containers/editor/cursorHandler.js";
import { cursor } from "../../../themes/cursor.js";

const cameraController = (function () {
  
  const LEFT_CLICK_ID = 0;
  const MIDDLE_CLICK_ID = 1;
  const RIGHT_CLICK_ID = 2;
  let cameraParams = {
    threeD: {},
    twoD: {}
  };
  
  const attach = function () {
    const camera = store.scene.activeCamera;
    camera.inputs.clear();
    removeTemporaryCursor();
    const cameraPointersInputHandler =
      new BABYLON.ArcRotateCameraPointersInput();

    if (store.isiPad || store.isMobile) {
      const _validateEvent = function (evt) {
        return isTouchEvent(evt);
      };

      const _removeObserversAndAddCustom = function () {
        const _pointerInputProcessor = cameraPointersInputHandler._pointerInput;
        const _observer = cameraPointersInputHandler._observer;

        camera.getScene().onPointerObservable.remove(_observer);

        cameraPointersInputHandler._pointerInput = function customPointerInput(
          p,
          s
        ) {
          if (_validateEvent(p.event)) {
            _pointerInputProcessor(p, s);
          }
        };

        cameraPointersInputHandler._observer = camera
          .getScene()
          .onPointerObservable.add(
            cameraPointersInputHandler._pointerInput,
            BABYLON.PointerEventTypes.POINTERDOWN |
              BABYLON.PointerEventTypes.POINTERUP |
              BABYLON.PointerEventTypes.POINTERMOVE
          );
      };

      cameraPointersInputHandler.buttons = [LEFT_CLICK_ID]; // touch and pen input event

      camera.inputs.add(cameraPointersInputHandler);
      cameraPointersInputHandler.attachControl(true);

      _removeObserversAndAddCustom();
    } else {
      cameraPointersInputHandler.buttons = [MIDDLE_CLICK_ID]; // only middle button
      camera._useCtrlForPanning = true;

      camera.inputs.add(cameraPointersInputHandler);
      cameraPointersInputHandler.attachControl(true);
    }
  };

  const detach = function () {
    addTemporaryCursor(cursor.pan);
    const camera = store.scene.activeCamera;
    camera.detachControl(store.canvas);
  };

  const validate = function (evt) {
    if (store.isiPad || store.isMobile) {
      // return evt.pointerType !== 'pen' && evt.button === 0;
      // not pen and just main input, nothing weird
      // button is -1 sometimes

      return isTouchEvent(evt);
    } else {
      return true;
    }
  };
  
  const _getCameraPointersInputHandler = () => store.scene.activeCamera.inputs.attached.pointers;
  
  const activatePanMode = function (){
    const cameraPointersInputHandler = _getCameraPointersInputHandler();
    const camera = store.scene.activeCamera;
    
    camera._panningMouseButton = 0;
    camera._useCtrlForPanning = false;
    cameraPointersInputHandler.buttons = [LEFT_CLICK_ID, MIDDLE_CLICK_ID];
  };
  
  const deactivatePanMode = function (){
    const cameraPointersInputHandler = _getCameraPointersInputHandler();
    const camera = store.scene.activeCamera;
    
    delete camera._panningMouseButton;
    camera._useCtrlForPanning = true;
    cameraPointersInputHandler.buttons = [MIDDLE_CLICK_ID];
  };
  
  const activateOrbitMode = function (){
    store.isOrbitModeActive = true;
    const cameraPointersInputHandler = _getCameraPointersInputHandler();
    cameraPointersInputHandler.buttons = [LEFT_CLICK_ID, MIDDLE_CLICK_ID];
  };
  
  const deactivateOrbitMode = function (){
    store.isOrbitModeActive = false;
    const cameraPointersInputHandler = _getCameraPointersInputHandler();
    cameraPointersInputHandler.buttons = [MIDDLE_CLICK_ID];
  };

  /**
   * Record camera parameters.
   * Used to save views on switching between 3D-2D, 2D-2D
   */
  const recordPriorParams = function (){
    if (store.$scope.isTwoDimension) {
      const twoD = cameraParams.twoD;
      twoD.position = store.scene.activeCamera.position.clone();
      twoD.target = store.scene.activeCamera.target.clone();
      twoD.orthoTop = store.scene.activeCamera.orthoTop;
      twoD.orthoBottom = store.scene.activeCamera.orthoBottom;
      twoD.orthoLeft = store.scene.activeCamera.orthoLeft;
      twoD.orthoRight = store.scene.activeCamera.orthoRight;
    } else {
      const threeD = cameraParams.threeD;
      threeD.position = store.scene.activeCamera.position.clone();
      threeD.target = store.scene.activeCamera.target.clone();
    }
  }

  /**
   * Set previously recorded camera view
   */
  const setParams = function (){
    if (store.$scope.isTwoDimension) {
      const twoD = cameraParams.twoD;
      if (_.isEmpty(twoD)) return;
      store.scene.activeCamera.position = twoD.position;
      store.scene.activeCamera.target = twoD.target;
      store.scene.activeCamera.orthoTop = twoD.orthoTop;
      store.scene.activeCamera.orthoBottom = twoD.orthoBottom;
      store.scene.activeCamera.orthoLeft = twoD.orthoLeft;
      store.scene.activeCamera.orthoRight = twoD.orthoRight;
    } else {
      const threeD = cameraParams.threeD;
      if (_.isEmpty(threeD)) return;
      store.scene.activeCamera.position = threeD.position;
      store.scene.activeCamera.target = threeD.target;
    }
  }

  return {
    attach,
    detach,
    validate,
    
    activateOrbitMode,
    deactivateOrbitMode,
    activatePanMode,
    deactivatePanMode,
    recordPriorParams,
    setParams
  };
})();
export { cameraController };
