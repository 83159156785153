import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from '../Button';
import Input from '../Input';
import * as styles from "../../containers/genericModal/styles";
import { RecentBox } from '../SortByBox';
import {useDispatch, useSelector} from "react-redux";
import {isRenderEnabled, renderInit, startRenderProcess} from "../../snaptrude/modules/render/render";
import {TailSpin} from "react-loader-spinner";
import {startRenderLoading, stopRenderLoading} from "../../snaptrude/stateManagers/reducers/objectProperties/screenshotUiDataSlice";
import {store} from "../../snaptrude/modules/utilityFunctions/Store";

const Card = styled(Outer)`
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  min-height: 200px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const StyledRow = styled.div`
  flex: 1;
  // flex-direction: col;
  padding: 5px 30px;
  align-items: center;
`
const StyledCol = styled.div`
  flex: 1;
  padding: 10px;
`
const recentBoxStyle = {
  boxStyle: {
    border: "none",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  selectedOptionStyle: {
    fontFamily: "DM Sans",
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
    color: colors.primeBlack,
    width: "100%",
  },
  customDropdownStyle: {
    position: "absolute",
    top: "-1.25rem",
    left: 0,
    right: 0,
    borderRadius: "0.313rem",
    paddingLeft: 0,
  },
};

const FlexContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
`;

function RenderSettingsModal({
  isOpen,
  onClose,
}) {
  const project = useSelector(state => state.projectMetadata);
  const [renderEnabled, setRenderEnabled] = useState(false);
  const [renderName, setRenderName] = useState("");
  const [renderQuality, setRenderQuality] = useState(0);
  const projectName = project.name;
  const dispatch = useDispatch();

  useEffect(() => {
  }, [renderEnabled, renderName]);

  const handleRender = async () => {
    dispatch(startRenderLoading());
    startRenderProcess(renderName, renderQuality, store.floorkey);
    onClose();
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Render Settings
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={onClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          <StyledCol>
            <StyledRow>
              Render Name
            </StyledRow>
            <StyledRow>
              <Input
                placeholder={"Enter render name"}
                value={renderName}
                onPress={(e) => {
                  setRenderName(e.target.value);
                  setRenderEnabled(e.target.value.trim() !== "");
                }}
                maxLength={50}
              />
            </StyledRow>
          </StyledCol>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
             <Button onPress={onClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Cancel" />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              primary={renderEnabled}
              disabled={!renderEnabled}
              title="Start Render"
              onPress={handleRender}
            />
          </ButtonWrapper>
        </ModalFooter>
      </Card>
    </ReactModal>
  )
}

export default RenderSettingsModal
