import { autoSaveConfig } from "./autoSaveConfig";
import { ScopeUtils } from "../../libs/scopeFunctions";
import { cameraFollow } from "./cameraFollow";

const Role = (() => {
  const CONSTANTS = {
      owner: 'owner',
      unauthorized: 'unauthorized',
      viewer: 'viewer',
      editor: 'editor'
  };

  let _role = undefined;
  let _i = 0;

  const init = () => {
      const autoSaveSocket = autoSaveConfig.getSocketObject();
      if(autoSaveSocket){
          autoSaveSocket.emit('role', role => {
              setRole(role);
          });
      } else {
          if(_i < 5){
              autoSaveConfig.connectSocket();
              init();
              _i++;
          } else {
              console.error('Unable to fetch role');
          }
      }
  };

  const getRole = () => _role;

  const setRole = role => {
      if(role === CONSTANTS.owner){
          _role = CONSTANTS.owner;
      } else if (role ===  CONSTANTS.unauthorized){
          _role = CONSTANTS.unauthorized;
      } else if (role === CONSTANTS.viewer){
          _role = CONSTANTS.viewer;
      } else if (role === CONSTANTS.editor){
          _role = CONSTANTS.editor;
      }
      _roleChangeNotifier();
  };

  const _roleChangeNotifier = () => {
      // TODO: PRANAV
    //   const scope = ScopeUtils.getScope();
    //   scope.updateUserRole(_role);
      switch(_role){
          case CONSTANTS.unauthorized:
              break;
          case CONSTANTS.owner:
              cameraFollow.authoriseToEmit();
              break;
          default:
              cameraFollow.unAuthoriseToEmit();
              break;
      }
  };

  return {
      init,
      get : getRole,
      set: setRole,
      CONSTANTS
  };
})();

export {
  Role,
};
