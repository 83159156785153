import React, { useState, forwardRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import useOnClickOutside from "./hook";

const StyledDropdown = styled.div`
  ${({ icon }) =>
    icon &&
    css`
      margin: 0 0.5rem;
    `}
  ul {
    position: absolute;
    z-index: 1;
    margin: 0;
    margin-top: -1.8rem;
    margin-left: 0.4rem;
    padding: 0;
  }
  .list {
    height: auto;
    background: ${(props) => props.theme.colors.clearWhite};
    border: 0.1rem solid ${(props) => props.theme.colors.mercury};
    box-sizing: border-box;
    box-shadow: 0 0.25rem 1.875rem
      ${(props) => props.theme.colors.lightGreyShadow};
    border-radius: 0.75rem;
    overflow: hidden;
  }
  .separator {
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.mercury};
    padding-top: 0.6rem;
    padding-left: 0.37rem;
    padding-right: 0.37rem;
  }
  .separator:last-child {
    border-bottom: none;
  }
`;

const DropdownImg = styled.img`
  position: relative;
  // margin-left: 0.4rem;
  width: 0.6rem;
  top: 0;
  cursor: pointer;
`;

const ListItem = styled.li`
  list-style: none;
  padding: ${({ customPaddingStyles }) =>
    customPaddingStyles || "0.7rem 0.5rem"};
  cursor: pointer;
  font-family: ${(props) => props.theme.font};
  opacity: 0.8;
  font-size: 0.75rem;
  align-items: center;
  text-align: ${({ align }) => align};
  font-weight: ${({ selectedItemWeight }) => selectedItemWeight};
  ${({ isOptionElement }) => {
    if (isOptionElement) {
      return css`
        > * {
          color: ${({ selectedItemColor }) => selectedItemColor};
        }
      `;
    } else {
      return css`
        color: ${({ selectedItemColor }) => selectedItemColor};
      `;
    }
  }}
  &:hover {
    background: ${(props) => props.theme.colors.white};
  }
`;

const Dropdown = forwardRef(
  (
    {
      icon,
      items,
      selected,
      separate,
      forceOpen,
      align,
      element,
      customParentDivStyles,
      customDropdownStyles,
      selectedItem,
      customToggle,
      forceToggle,
      customPaddingStyles,
      selectedItemColor,
      isOptionElement,
    },
    ref
  ) => {
    const [open, setOpen] = useState(forceOpen || false);
    const toggle = () => setOpen(!open);

    useOnClickOutside(ref, () => setOpen(false));
    return (
      <>
        <StyledDropdown ref={ref} style={customParentDivStyles} icon={icon}>
          {icon && (
            <DropdownImg
              onClick={customToggle || toggle}
              src={icon}
              className={open === true ? "toggle-icon" : ""}
              alt="polygon-icon"
            />
          )}
          {(forceToggle || open) && (
            <ul style={customDropdownStyles} className="list">
              {element}
              {items.map((item, index) => (
                <ListItem
                  customPaddingStyles={customPaddingStyles}
                  align={align}
                  isOptionElement={isOptionElement}
                  selectedItemColor={selectedItemColor}
                  selectedItemWeight={selectedItem === item ? "bold" : "normal"}
                  className={separate || item.sep ? "separator" : ""}
                  onClick={() => {
                    selected(item);
                    customToggle ? customToggle() : toggle();
                  }}
                  key={index}
                >
                  {item.title || item}
                </ListItem>
              ))}
            </ul>
          )}
        </StyledDropdown>
      </>
    );
  }
);

Dropdown.displayName = "Dropdown";

Dropdown.propTypes = {
  icon: PropTypes.string,
  items: PropTypes.array,
  selected: PropTypes.func,
  separate: PropTypes.bool,
  forceOpen: PropTypes.bool,
  align: PropTypes.string,
  element: PropTypes.element,
  selectedItem: PropTypes.any,
  customToggle: PropTypes.func,
  forceToggle: PropTypes.bool,
  customParentDivStyles: PropTypes.instanceOf(Object),
  customDropdownStyles: PropTypes.instanceOf(Object),
  customPaddingStyles: PropTypes.string,
  selectedItemColor: PropTypes.string,
  isOptionElement: PropTypes.bool,
};

export default Dropdown;
