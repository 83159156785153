const debugDiagnostics = (function (){
  
  let _toggle = false;
  
  const toggle = function (){
    _toggle = true;
  };
  
  const isToggled = function (){
    return _toggle;
  }
  
  return {
    isToggled,
    toggle,
  };
  
})();

export default debugDiagnostics;