import * as DLL from "../Classes/doublyLinkedList.js";
import * as log from "loglevel";
import BABYLON from "../babylonDS.module.js";
import _ from "lodash";
import { store } from "../utilityFunctions/Store.js"
import { getAngleInRadians,projectionOfPointOnLine,isFloatEqual } from "../../libs/snapFuncs.js";
import { returnArrayOfV3s,convertLocalCoordsToGlobal,rubSandpaper, areEdgesIntersecting, areEdgesOverlapping } from "../extrafunc.js";
import { Factory } from "../factoryTypes/meshFactory.module.js";
import { Wall } from "../snaptrudeDS/wall.ds.js";
import { createBuildingEngine } from "../createBuilding/createBuildingEngine.js";
import { calculateEuclidianDistance } from "../../libs/polygon_dip_workers.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import { walls } from "../../libs/twoD/twoDetectRooms.js";
import { StoreyMutation } from "../storeyEngine/storeyMutations.js";
import { plainroof,populateRoofBoundInfo,getOffsetValues,plainRoofParameters } from "../factoryTypes/roof.types.js";
import { average } from "../../libs/mathFuncs.js";
import { isCompletelyInside } from "../../libs/twoD/twoServices.js";
import { Roof } from "../snaptrudeDS/roof.ds.js";
import { csgOperator } from "../meshoperations/csgOperation.js";
/*jshint esversion: 6 */
//************ Helper methods ********
var ResolveEngineUtils = function () {
  this.temp = [];
  this.isContainedWithinThres = 2;
  this.isMinWallConstructThres = 0.5;
  this.intersectThres = 1; //uses _loadash's round method
};
/**
 * Gets the orientation.
 *
 * @param      {number}            p1      The p 1
 * @param      {number}            p2      The p 2
 * @param      {number}            p3      The p 3
 * @return     {(boolean|number)}  The orientation.
 */
ResolveEngineUtils.prototype.getOrientation = function (p1, p2, p3) {
  let val =
    (p2[1] - p1[1]) * (p3[0] - p2[0]) - (p2[0] - p1[0]) * (p3[1] - p2[1]);
  if (val == 0) return 0;
  return val > 0 ? 1 : 2;
};

ResolveEngineUtils.prototype.getOrientationApprox = function (p1, p2, p3) {
  let val =
    (p2[1] - p1[1]) * (p3[0] - p2[0]) - (p2[0] - p1[0]) * (p3[1] - p2[1]);
  if (Math.abs(val) < 0.5) return 0;
  return val > 0 ? 1 : 2;
};

ResolveEngineUtils.prototype.getOrientation3D = function (
  p1,
  p2,
  p3,
  thresholdAngle = 1e-4
) {
  var v1, v2, v3;
  if (_.isArray(p1)) {
    v1 = new BABYLON.Vector3(p1[0], p1[2], p1[1]);
    v2 = new BABYLON.Vector3(p2[0], p2[2], p2[1]);
    v3 = new BABYLON.Vector3(p3[0], p3[2], p3[1]);
  } else {
    v1 = p1;
    v2 = p2;
    v3 = p3;
  }

  let v4 = v2.subtract(v1);
  let v5 = v3.subtract(v1);

  let angle = getAngleInRadians(v4, v5, true);
  if (Math.abs(angle) < thresholdAngle) return 0;
  else return 1;
};
/**
 * Arrays should have three members, of the form [x, y, z]
 * @param a1
 * @param a2
 * @param threshold
 * @param options
 * @returns {boolean}
 */
ResolveEngineUtils.prototype.areArraysAlmostEqual = function (
  a1,
  a2,
  threshold = 1e-4,
  options = {}
) {
  return (
    Math.abs(a1[0] - a2[0]) <= threshold &&
    (Math.abs(a1[1] - a2[1]) <= threshold || options.ignoreY) &&
    Math.abs(a1[2] - a2[2]) <= threshold
  );
};

/**
 * { function_description }
 *
 * @param      {number}   p1      The p 1
 * @param      {number}   p2      The p 2
 * @param      {number}   p3      The p 3
 * @return     {boolean}  { description_of_the_return_value }
 */
ResolveEngineUtils.prototype.onSegment = function (p1, p2, p3, flag = true) {
  if (flag) {
    if (
      p2[0] <= Math.max(p1[0], p3[0]) &&
      p2[0] >= Math.min(p1[0], p3[0]) &&
      p2[1] <= Math.max(p1[1], p3[1]) &&
      p2[1] >= Math.min(p1[1], p3[1])
    )
      return true;
  } else {
    if (
      (p2[0] < Math.max(p1[0], p3[0]) &&
        p2[0] > Math.min(p1[0], p3[0]) &&
        p2[1] <= Math.max(p1[1], p3[1]) &&
        p2[1] >= Math.min(p1[1], p3[1])) ||
      (p2[0] <= Math.max(p1[0], p3[0]) &&
        p2[0] >= Math.min(p1[0], p3[0]) &&
        p2[1] < Math.max(p1[1], p3[1]) &&
        p2[1] > Math.min(p1[1], p3[1]))
    )
      return true;
  }

  return false;
};

ResolveEngineUtils.prototype.onSegment3D = function (
  p1,
  p2,
  p3,
  threshold = 1e-4,
  equalityCheck = false
) {
  if (!_.isNumber(threshold)) threshold = 1e-4;

  [p1, p2, p3] = returnArrayOfV3s([p1, p2, p3]);

  if (equalityCheck && (p1.almostEquals(p2) || p3.almostEquals(p2)))
    return false;

  let projection = projectionOfPointOnLine(p2, p1, p3);

  const dp2Projection = BABYLON.Vector3.Distance(p2, projection);
  const dp1p2 = BABYLON.Vector3.Distance(p1, p2);
  const dp2p3 = BABYLON.Vector3.Distance(p2, p3);
  const dp1p3 = BABYLON.Vector3.Distance(p1, p3);

  if (
    dp2Projection < threshold &&
    isFloatEqual(
      dp1p2 + dp2p3,
      dp1p3,
      threshold
    )
  ) {
    return true;
  } else return false;
};

ResolveEngineUtils.prototype.onSegment2D = function (
  p13D,
  p23D,
  p33D,
  threshold = 1e-4,
  equalityCheck = false
) {
  if (!_.isNumber(threshold)) threshold = 1e-4;

  if (equalityCheck && (p13D.almostEquals(p23D) || p33D.almostEquals(p23D)))
    return false;

  let p1 = new BABYLON.Vector2(p13D.x, p13D.z);
  let p2 = new BABYLON.Vector2(p23D.x, p23D.z);
  let p3 = new BABYLON.Vector2(p33D.x, p33D.z);

  let projection = projectionOfPointOnLine(p2, p1, p3, true);

  if (
    BABYLON.Vector2.Distance(p2, projection) < threshold &&
    isFloatEqual(
      BABYLON.Vector2.Distance(p1, p2) + BABYLON.Vector2.Distance(p2, p3),
      BABYLON.Vector2.Distance(p1, p3),
      threshold
    )
  ) {
    return true;
  } else return false;
};

// ResolveEngineUtils.prototype.onSegment2D_2 = function (p, q, r) {
//     if ((q[0] <= max(p[0], r[0])) && (q[0] >= min(p[0], r[0])) &&
//         (q[2] <= max(p[2], r[2])) && (q[2] >= min(p[2], r[2])))   return true;
//     return false;
// };

// Works for 2D points [x,y]
ResolveEngineUtils.prototype.isPointInsidePolygon = function (point, polygon) {
  let n = polygon.length;
  if (n < 3) {
    return false;
  }
  let extreme = [10000, point[1]];
  let count = 0,
    i = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    let next = (i + 1) % n;
    if (this.doIntersect(polygon[i], polygon[next], point, extreme)) {
      if (this.getOrientation(polygon[i], point, polygon[next]) == 0) {
        return this.onSegment(polygon[i], point, polygon[next], true);
      }
      count += 1;
    }
    i = next;
    if (i == 0) break;
  }
  return count % 2 === 1;
};

ResolveEngineUtils.prototype.checkForExtention = async function (wall1, wall2) {
  let wall1LS = wall1.getLineSegment();
  let wall2LS = wall2.getLineSegment();

  if (wall1.profile !== 2 || wall2.profile !== 2) return;

  if (
    this.getOrientation(wall1LS[0], wall1LS[1], wall2LS[0]) ===
      this.getOrientation(wall1LS[0], wall1LS[1], wall2LS[1]) &&
    !this.isWithinBounds(wall1LS, wall2LS, wall1.direction, wall2.direction)
  ) {
    try {
      wall1.onMove.onStart.call(wall1, wall2.id);
      wall1.extendEndVertex(wall2LS, wall2);
      wall1.onMove.onFinish.call(wall1, 1);
    } catch (e) {
      console.error(e);
    }
  }
};
/**
 * { function_description }
 *
 * @param      {<type>}   p1      The p 1
 * @param      {<type>}   p2      The p 2
 * @param      {<type>}   q1      The quarter 1
 * @param      {<type>}   q2      The quarter 2
 * @return     {boolean}  { description_of_the_return_value }
 */
ResolveEngineUtils.prototype.doIntersect = function (
  p1,
  p2,
  q1,
  q2,
  flag = false
) {
  p1 = p1.map((x) => _.round(x, this.intersectThres));
  p2 = p2.map((x) => _.round(x, this.intersectThres));
  q1 = q1.map((x) => _.round(x, this.intersectThres));
  q2 = q2.map((x) => _.round(x, this.intersectThres));

  //for rounding up further
  for (let j = 0; j < 2; j++) {
    if (p1[j] !== q1[j] && Math.abs(p1[j] - q1[j]) < 0.2) {
      p1[j] = q1[j];
    }
    if (p1[j] !== q2[j] && Math.abs(p1[j] - q2[j]) < 0.2) {
      p1[j] = q2[j];
    }
    if (p2[j] !== q1[j] && Math.abs(p2[j] - q1[j]) < 0.2) {
      p2[j] = q1[j];
    }
    if (p2[j] !== q2[j] && Math.abs(p2[j] - q2[j]) < 0.2) {
      p2[j] = q2[j];
    }
  }

  if (
    (this.areArraysAlmostEqual(p1, q1, 0) ||
      this.areArraysAlmostEqual(p1, q2, 0)) &&
    (this.areArraysAlmostEqual(p2, q1, 0) ||
      this.areArraysAlmostEqual(p2, q2, 0))
  ) {
    // when p1p2 and q1q2 are the same segment
    return true;
  }

  let o1 = this.getOrientation(p1, p2, q1);
  let o2 = this.getOrientation(p1, p2, q2);
  let o3 = this.getOrientation(q1, q2, p1);
  let o4 = this.getOrientation(q1, q2, p2);

  if (o1 != o2 && o3 != o4) return true; //TODO modify this in future

  // Special Cases
  // p1, q1 and p2 are colinear and p2 lies on segment p1q1
  if (o1 == 0 && this.onSegment(p1, q1, p2, flag)) return true;

  // p1, q1 and q2 are colinear and q2 lies on segment p1q1
  if (o2 == 0 && this.onSegment(p1, q2, p2, flag)) return true;

  // p2, q2 and p1 are colinear and p1 lies on segment p2q2
  if (o3 == 0 && this.onSegment(q1, p1, q2, flag)) return true;

  // p2, q2 and q1 are colinear and q1 lies on segment p2q2
  if (o4 == 0 && this.onSegment(q1, p2, q2, flag)) return true;

  return false; // Doesn't fall in any of wallthe above cases
};

ResolveEngineUtils.prototype.areCollinear = function (
  p1,
  p2,
  q1,
  q2,
  threshold = 1e-4
) {
  let b1 = this.onSegment3D(p1, q1, p2, threshold);
  let b2 = this.onSegment3D(p1, q2, p2, threshold);
  let b3 = this.onSegment3D(q1, p1, q2, threshold);
  let b4 = this.onSegment3D(q1, p2, q2, threshold);

  let pts = [];
  if (b1) pts.push(q1);
  if (b2) pts.push(q2);
  if (b3) pts.push(p1);
  if (b4) pts.push(p2);

  if (pts.length === 2) {
    if (
      Math.abs(pts[0][0] - pts[1][0]) < 0.1 &&
      Math.abs(pts[0][1] - pts[1][1]) < 0.1
    ) {
      //console.log("Not intersecting");
      return false;
    } else {
      return true;
    }
  } else if (pts.length === 3 || pts.length === 4) {
    return true;
  }

  return false; // Doesn't fall in any of wallthe above cases
};

ResolveEngineUtils.prototype.getProfile = function (p1, p2, q1, q2) {
  let o1 = this.getOrientation(p1, p2, q1);
  let o2 = this.getOrientation(p1, p2, q2);
  let o3 = this.getOrientation(q1, q2, p1);
  let o4 = this.getOrientation(q1, q2, p2);

  if (o1 != o2 && o3 != o4) return 1;

  // Special Cases
  // p1, q1 and p2 are colinear and p2 lies on segment p1q1
  if (o1 == 0 && this.onSegment(p1, q1, p2)) return 2;

  // p1, q1 and q2 are colinear and q2 lies on segment p1q1
  if (o2 == 0 && this.onSegment(p1, q2, p2)) return 2;

  // p2, q2 and p1 are colinear and p1 lies on segment p2q2
  if (o3 == 0 && this.onSegment(q1, p1, q2)) return 2;

  // p2, q2 and q1 are colinear and q1 lies on segment p2q2
  if (o4 == 0 && this.onSegment(q1, p2, q2)) return 2;

  return 3; // Doesn't fall in any of the above cases
};

ResolveEngineUtils.prototype.isWithinBounds = function (
  wallLS1,
  wallLS2,
  direction1 = null,
  direction2 = null
) {
  let wall1_min_x = Math.min(wallLS1[0][0], wallLS1[1][0]);
  let wall1_max_x = Math.max(wallLS1[0][0], wallLS1[1][0]);
  let wall1_min_z = Math.min(wallLS1[0][1], wallLS1[1][1]);
  let wall1_max_z = Math.max(wallLS1[0][1], wallLS1[1][1]);

  let wall2_min_x = Math.min(wallLS2[0][0], wallLS2[1][0]);
  let wall2_max_x = Math.max(wallLS2[0][0], wallLS2[1][0]);
  let wall2_min_z = Math.min(wallLS2[0][1], wallLS2[1][1]);
  let wall2_max_z = Math.max(wallLS2[0][1], wallLS2[1][1]);

  if (direction1 === "x") {
    if (
      (wall2_min_x >= Math.min(wall1_min_x, wall1_max_x) &&
        wall2_min_x <= Math.max(wall1_min_x, wall1_max_x)) ||
      (wall2_max_x <= Math.max(wall1_min_x, wall1_max_x) &&
        wall2_max_x >= Math.min(wall1_min_x, wall1_max_x))
    )
      return true;
  }

  if (direction1 === "z") {
    if (
      (wall2_min_z >= Math.min(wall1_min_z, wall1_max_z) &&
        wall2_min_z <= Math.max(wall1_min_z, wall1_max_z)) ||
      (wall2_max_z <= Math.max(wall1_min_z, wall1_max_z) &&
        wall2_max_z >= Math.min(wall1_min_z, wall1_max_z))
    )
      return true;
  }

  return false;
};

ResolveEngineUtils.prototype.checkIntersectForSmallWalls = function (
  p1,
  p2,
  q1,
  q2,
  flag = false
) {
  p1 = p1.map((x) => _.round(x, this.intersectThres));
  p2 = p2.map((x) => _.round(x, this.intersectThres));
  q1 = q1.map((x) => _.round(x, this.intersectThres));
  q2 = q2.map((x) => _.round(x, this.intersectThres));

  //for rounding up further
  for (let j = 0; j < 2; j++) {
    if (p1[j] !== q1[j] && Math.abs(p1[j] - q1[j]) < 0.2) {
      p1[j] = q1[j];
    }
    if (p1[j] !== q2[j] && Math.abs(p1[j] - q2[j]) < 0.2) {
      p1[j] = q2[j];
    }
    if (p2[j] !== q1[j] && Math.abs(p2[j] - q1[j]) < 0.2) {
      p2[j] = q1[j];
    }
    if (p2[j] !== q2[j] && Math.abs(p2[j] - q2[j]) < 0.2) {
      p2[j] = q2[j];
    }
  }

  let o1 = this.getOrientation(p1, p2, q1);
  let o2 = this.getOrientation(p1, p2, q2);
  let o3 = this.getOrientation(q1, q2, p1);
  let o4 = this.getOrientation(q1, q2, p2);

  if (o1 != o2 && o3 != o4) return true; //TODO modify this in future

  // Special Cases
  // p1, q1 and p2 are colinear and p2 lies on segment p1q1
  if (o1 == 0 && this.onSegment(p1, q1, p2, flag)) return true;

  // p1, q1 and q2 are colinear and q2 lies on segment p1q1
  if (o2 == 0 && this.onSegment(p1, q2, p2, flag)) return true;

  // p2, q2 and p1 are colinear and p1 lies on segment p2q2
  if (o3 == 0 && this.onSegment(q1, p1, q2, flag)) return true;

  // p2, q2 and q1 are colinear and q1 lies on segment p2q2
  if (o4 == 0 && this.onSegment(q1, p2, q2, flag)) return true;

  return false; // Doesn't fall in any of the above cases
};

ResolveEngineUtils.prototype.isContainedWithin = function (
  wall1,
  wall2,
  isInside = false
) {
  let wall_ls1 = wall1.getTopLineSegment();
  let wall_ls2 = wall2.getTopLineSegment();

  // in x axis
  let c1 = this.onSegment(wall_ls1[0], wall_ls2[0], wall_ls1[1]);
  let c2 = this.onSegment(wall_ls1[0], wall_ls2[1], wall_ls1[1]);
  let c3 = this.onSegment(wall_ls2[0], wall_ls1[0], wall_ls2[1]);
  let c4 = this.onSegment(wall_ls2[0], wall_ls2[1], wall_ls2[1]);

  if (!isInside) {
    if (c1 && c2) {
      if (
        wall_ls2[0][2] === wall_ls1[0][2] &&
        wall_ls2[1][2] === wall_ls1[1][2]
      )
        return 2;
      else return false;
    }
    if (c3 && c4) {
      if (
        wall_ls1[0][2] === wall_ls2[0][2] &&
        wall_ls1[1][2] === wall_ls2[1][2]
      )
        return 1;
      else return false;
    }
  } else {
    if (c1 && c2) {
      if (wall_ls2[0][2] < wall_ls1[0][2] && wall_ls2[1][2] < wall_ls1[1][2])
        return 2;
      else return false;
    }
    if (c3 && c4) {
      if (wall_ls1[0][2] < wall_ls2[0][2] && wall_ls1[1][2] < wall_ls2[1][2])
        return 1;
      else return false;
    }
  }

  return false;
};

ResolveEngineUtils.prototype.isContainedApprox = function (
  wall_ls1,
  wall_ls2,
  thres = 1
) {
  // in x axis
  if (
    Math.abs(wall_ls1[0][0] - wall_ls2[0][0]) < thres &&
    Math.abs(wall_ls1[1][0] - wall_ls2[1][0]) < thres &&
    wall_ls1[0][1] === wall_ls2[0][1] &&
    wall_ls1[1][1] === wall_ls2[1][1]
  ) {
    return wall_ls1[0][2] > wall_ls2[0][2] ? 1 : 2;
  }
  if (
    Math.abs(wall_ls1[0][1] - wall_ls2[0][1]) < thres &&
    Math.abs(wall_ls1[1][1] - wall_ls2[1][1]) < thres &&
    wall_ls1[0][0] === wall_ls2[0][0] &&
    wall_ls1[1][0] === wall_ls2[1][0]
  ) {
    return wall_ls1[0][2] > wall_ls2[0][2] ? 1 : 2;
  }
  return false;
};

ResolveEngineUtils.prototype.removeDuplicates = function (arr) {
  var prims = { boolean: {}, number: {}, string: {} },
    objs = [];
  let thres = this.intersectThres,
    isFound = false;

  return arr.filter(function (item) {
    let type = typeof item;
    if (type in prims)
      return prims[type].hasOwnProperty(item)
        ? false
        : (prims[type][item] = true);
    else {
      item = item.map((x) => _.round(x, thres));
      for (let j = 0; j < objs.length; j++) {
        let point = objs[j];
        if (
          Math.abs(point[0] - item[0]) < 0.2 &&
          Math.abs(point[1] - item[1]) < 0.2 &&
          Math.abs(point[2] - item[2]) < 0.2
        ) {
          isFound = true;
          break;
        }
      }
      if (isFound) {
        isFound = false;
        return false;
      } else {
        objs.push(item);
        return true;
      }
    }
  });
};

ResolveEngineUtils.prototype.makeDeletedWalls = async function (
  deletedWalls,
  roomid,
  structure_id,
  level
) {
  const factory = new Factory();
  const newDeletedWalls = [];

  try {
    if (deletedWalls.length !== 0) {
      for (let k = 0; k < deletedWalls.length; k += 8) {
        var wall_path_bottom = [
          deletedWalls[k],
          deletedWalls[k + 1],
          deletedWalls[k + 2],
          deletedWalls[k + 3],
        ];

        //fix height
        deletedWalls[k + 6][2] = deletedWalls[k + 4][2];
        deletedWalls[k + 7][2] = deletedWalls[k + 5][2];
        var wall_path_top = [
          deletedWalls[k + 4],
          deletedWalls[k + 5],
          deletedWalls[k + 6],
          deletedWalls[k + 7],
        ];
        const heightObj = {
          low: deletedWalls[k][2],
          high: deletedWalls[k + 4][2],
        };

        var points = {
          pol1: wall_path_bottom,
          pol2: wall_path_top,
          height: heightObj,
        };
        var wallMesh = factory.createWall("straight", points);
        wallMesh.structure_id = structure_id;
        const wall = new Wall(wallMesh, roomid, structure_id);
        let midY = (heightObj.high + heightObj.low) / 2;
        wall.adjustHeight(wall_path_bottom, wall_path_top, midY);
        // adjustMeshCoords(wallMesh, coords1, coords2, null, null);
        wall.setBottomCoords(wall_path_bottom);
        wall.setTopCoords(wall_path_top);
        wall.setMidYHeight(midY);
        wall.mesh.isVisible = false;
        newDeletedWalls.push(wall);

        createBuildingEngine.addComponent(wall);
      }
    }
    return Promise.resolve({ level: level, walls: newDeletedWalls });
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};

function normal_normal_dheight(walls = null) {
  let dwalls = walls || this.walls;
  let allBotCoordsArr = [],
    allTopCoordsArr = [],
    factory = new Factory(),
    roomid = "",
    midY = 0,
    direction = null,
    groupid = "";

  //direct dump

  for (let wall of dwalls) {
    Array.prototype.push.apply(allBotCoordsArr, wall.getBottomCoords());
    Array.prototype.push.apply(allTopCoordsArr, wall.getTopCoords());
    roomid += wall.room_id;
    if (groupid.length > 1) {
      if (groupid !== wall.groupId) {
        groupid = groupid + "|" + (wall.groupId ? wall.groupId : "default");
      }
    } else {
      groupid += wall.groupId;
    }
    direction = wall.direction;
  }

  if (direction == "x") {
    allBotCoordsArr.sort(function (a, b) {
      return a[0] - b[0];
    });
    allTopCoordsArr.sort(function (a, b) {
      return a[0] - b[0];
    });
  } else {
    allBotCoordsArr.sort(function (a, b) {
      return a[1] - b[1];
    });
    allTopCoordsArr.sort(function (a, b) {
      return a[1] - b[1];
    });
  }

  //push extra coords
  let tempArrTop = [],
    tempArrBot = [],
    ctr = 0;

  for (
    let i = 0, index = 1, isDone = false;
    i < allTopCoordsArr.length;
    i += 2, index++
  ) {
    if (i % 2 === 0 && i !== 0 && i !== allTopCoordsArr.length - 2) {
      let w1 = dwalls[Math.floor((i - 2) / 4)].getTopLineSegment();
      let w2 = dwalls[Math.floor((i + 2) / 4)].getTopLineSegment();

      let newCoordsT = JSON.parse(
        JSON.stringify(allTopCoordsArr.slice(i, i + 2))
      );
      let newCoordsB = JSON.parse(
        JSON.stringify(allBotCoordsArr.slice(i, i + 2))
      );

      let midTop = [
        (newCoordsT[0][0] + newCoordsT[1][0]) / 2,
        (newCoordsT[0][1] + newCoordsT[1][1]) / 2,
        (newCoordsT[0][2] + newCoordsT[1][2]) / 2,
      ];
      midTop = midTop.map((x) => _.round(x, 1));

      if (
        this.utils.onSegment(w1[index % 2], midTop, w1[(index + 1) % 2]) &&
        !isDone
      ) {
        if (
          w1[index % 2][2] > w2[index % 2][2] &&
          w1[(index + 1) % 2][2] > w2[index % 2][2]
        ) {
          newCoordsT[0][2] = w1[index % 2][2];
          newCoordsT[1][2] = w1[index % 2][2];
          allTopCoordsArr[i][2] = w1[index % 2][2];
          allTopCoordsArr[i + 1][2] = w1[index % 2][2];
        } else {
          newCoordsT[0][2] = w1[index % 2][2];
          newCoordsT[1][2] = w1[index % 2][2];
        }
        isDone = true;
      } else if (
        this.utils.onSegment(w2[index % 2], midTop, w2[(index + 1) % 2])
      ) {
        if (
          w1[index % 2][2] > w2[index % 2][2] &&
          w1[(index + 1) % 2][2] > w2[index % 2][2]
        ) {
          newCoordsT[0][2] = w1[index % 2][2];
          newCoordsT[1][2] = w1[index % 2][2];
          allTopCoordsArr[i][2] = w2[index % 2][2];
          allTopCoordsArr[i + 1][2] = w2[index % 2][2];
        } else {
          if (
            calculateEuclidianDistance(
              allBotCoordsArr.slice(allBotCoordsArr.length - 1)[0],
              w2[index % 2]
            ) < 5
          ) {
            newCoordsT[0][2] = w2[index % 2][2];
            newCoordsT[1][2] = w2[index % 2][2];
            allTopCoordsArr[i][2] = w2[index % 2][2];
            allTopCoordsArr[i + 1][2] = w2[index % 2][2];
          } else {
            newCoordsT[0][2] = w2[index % 2][2];
            newCoordsT[1][2] = w2[index % 2][2];
            allTopCoordsArr[i][2] = w1[index % 2][2];
            allTopCoordsArr[i + 1][2] = w1[index % 2][2];
          }
        }
      }

      Array.prototype.push.apply(tempArrTop, newCoordsT);
      Array.prototype.push.apply(tempArrBot, newCoordsB);
    }

    Array.prototype.push.apply(tempArrTop, allTopCoordsArr.slice(i, i + 2));
    Array.prototype.push.apply(tempArrBot, allBotCoordsArr.slice(i, i + 2));
  }

  //   let structures = StructureCollection.getInstance();
  //   let sc = structures.getStructureById(dwalls[0].structure_id);
  let structure_id = dwalls[0].structure_id;
  let meshStorey = dwalls[0].storey;
  //   let level = sc.getLevelByUniqueId(dwalls[0].level_id);

  allTopCoordsArr = JSON.parse(JSON.stringify(tempArrTop));
  allBotCoordsArr = JSON.parse(JSON.stringify(tempArrBot));
  tempArrTop.length = 0;
  tempArrBot.length = 0;
  let deletedWalls = [];

  //delete small walls
  for (let j = 0, a, b; j < allBotCoordsArr.length - 3; ) {
    let horizontal_len =
      (a = calculateEuclidianDistance(
        allBotCoordsArr[j + 1],
        allBotCoordsArr[j + 2]
      )) <
      (b = calculateEuclidianDistance(
        allBotCoordsArr[j + 1],
        allBotCoordsArr[j + 3]
      ))
        ? a
        : b;
    let vertical_len = calculateEuclidianDistance(
      allBotCoordsArr[j],
      allBotCoordsArr[j + 1]
    );

    if (
      Math.abs(vertical_len - horizontal_len) <=
      this.utils.isMinWallConstructThres
    ) {
      if (j === allBotCoordsArr.length - 4) {
        allTopCoordsArr[allBotCoordsArr.length - 1][2] =
          allTopCoordsArr[allBotCoordsArr.length - 5][2];
        allTopCoordsArr[allBotCoordsArr.length - 2][2] =
          allTopCoordsArr[allBotCoordsArr.length - 6][2];
        //deletedWalls = deletedWalls.concat(allBotCoordsArr.slice(j, 4));
        //deletedWalls = deletedWalls.concat(allTopCoordsArr.slice(j, 4));
        allBotCoordsArr.splice(j - 2, 4);
        allTopCoordsArr.splice(j - 2, 4);
      } else if (j === 0) {
        allTopCoordsArr[0][2] = allTopCoordsArr[4][2];
        allTopCoordsArr[1][2] = allTopCoordsArr[5][2];
        //deletedWalls = deletedWalls.concat(allBotCoordsArr.slice(j, 4));
        //deletedWalls = deletedWalls.concat(allTopCoordsArr.slice(j, 4));
        allBotCoordsArr.splice(j + 2, 4);
        allTopCoordsArr.splice(j + 2, 4);
      } else {
        allBotCoordsArr.splice(j, 4);
        allTopCoordsArr.splice(j, 4);
        // deletedWalls = deletedWalls.concat();
        // deletedWalls = deletedWalls.concat();
      }
    } else {
      j += 4;
    }

    // console.log("Horizontal :", horizontal_len);
    // console.log("Vertical Len :", vertical_len);
  }

  let tempWalls = [];
  for (let i = 0, j = 0; j < allBotCoordsArr.length - 1; i += 2, j += 4) {
    var wall_path_bottom = [
      allBotCoordsArr[j],
      allBotCoordsArr[j + 1],
      allBotCoordsArr[j + 2],
      allBotCoordsArr[j + 3],
    ];
    var wall_path_top = [
      allTopCoordsArr[j],
      allTopCoordsArr[j + 1],
      allTopCoordsArr[j + 2],
      allTopCoordsArr[j + 3],
    ];
    const heightObj = {
      low: allBotCoordsArr[i][2],
      high: allTopCoordsArr[i][2],
    };

    var points = {
      pol1: wall_path_bottom,
      pol2: wall_path_top,
      height: heightObj,
    };
    var wallMesh = factory.createWall("straight", points);
    wallMesh.structure_id = structure_id;
    wallMesh.storey = meshStorey;
    var wall = new Wall(wallMesh, roomid, structure_id, groupid);
    midY = (heightObj.high + heightObj.low) / 2;
    wall.storey = meshStorey;
    wall.adjustHeight(wall_path_bottom, wall_path_top, midY);
    // adjustMeshCoords(wallMesh, coords1, coords2, null, null);
    wall.setBottomCoords(wall_path_bottom);
    wall.setTopCoords(wall_path_top);
    wall.setMidYHeight(midY);
    this.level.addWallToLevel(wall, false);
    wall.assignProperties();
    wall.originalWallMesh = BABYLON.SceneSerializer.SerializeMesh(wallMesh);
    tempWalls.push(wall);

    createBuildingEngine.addComponent(wall);
  }
  // let deletedWallMesh = this.utils.makeDeletedWalls(deletedWalls, roomid, structure_id, this.level);
  // this.deletedWalls.push(deletedWallMesh);
  dwalls.forEach(function (wall) {
    wall.remove();
  });

  return tempWalls;
}

function normal_normal_mixedHeight() {
  let walls = this.walls;
  if (walls.length === 0) {
    return;
  }
  if (walls.length === 1) {
    if (!walls[0].isNew) {
      //walls[0].mesh.dispose();
      walls[0].remove();
    }
    return;
  }

  let direction = walls[0].direction;

  this.mergeDifferentHeight = function (wall1, wall2, wall1LS, wall2LS) {
    let isContained = this.utils.isContainedWithin(wall1, wall2); //|| this.utils.isContainedApprox(wall1LS, wall2LS, 2);
    if (isContained) {
      if (isContained === 2) {
        //wall2.mesh.dispose();
        wall2.remove();
      } else {
        //wall1.mesh.dispose();
        wall1.remove();
      }
    } else if (
      (isContained = this.utils.isContainedWithin(wall1, wall2, true))
    ) {
      let maxHeight =
        wall2.getTopLineSegment()[0][2] < wall1.getTopLineSegment()[0][2]
          ? wall1.getTopLineSegment()[0][2]
          : wall2.getTopLineSegment()[0][2];
      if (isContained === 2) {
        wall2.topCoords = wall2.topCoords.map((x) => {
          x[2] = maxHeight;
          return x;
        });
      } else {
        wall1.topCoords = wall1.topCoords.map((x) => {
          x[2] = maxHeight;
          return x;
        });
      }
      this.mergeSameHeight(wall1, wall2);
    } else if (
      this.utils.onSegment(wall1LS[0], wall2LS[0], wall1LS[1], false) ||
      this.utils.onSegment(wall1LS[0], wall2LS[1], wall1LS[1], false) ||
      this.utils.onSegment(wall2LS[0], wall1LS[0], wall2LS[1], false) ||
      this.utils.onSegment(wall2LS[0], wall1LS[1], wall2LS[1], false)
    ) {
      let tempwalls = normal_normal_dheight.call(this, [wall1, wall2]);
      //    this.tempWalls.concat(tempwalls);
      tempwalls.forEach((item) => {
        item.isNew = true;
        walls.splice(0, 0, item);
      });
      tempwalls.length = 0;
    } else {
      //for same x and z but different y
      let smallWall;
      for (let j = 0; j < 2; j++) {
        let x = wall1LS[j][0] === wall2LS[j][0];
        let z = wall1LS[j][1] === wall2LS[j][1];
        smallWall = wall1LS[j][2] > wall2LS[j][2] ? wall2 : wall1;
        if (!x && z) {
          return;
        }
      }
      //smallWall.mesh.dispose();
      smallWall.remove();
    }
  };

  this.mergeSameHeight = function (wall1, wall2, wall1LS, wall2LS) {
    let tempwalls = normal_normal_sheight.call(this, [wall1, wall2]);
    tempwalls.forEach((item) => {
      item.isNew = true;
      walls.splice(0, 0, item);
    });
    tempwalls.length = 0;
  };

  if (direction == "x") {
    walls.sort(function (a, b) {
      if (Math.abs(a.getLineSegment()[0][0] - b.getLineSegment()[0][0]) < 0.3) {
        return b.getTopLineSegment()[0][2] - a.getTopLineSegment()[0][2];
      }
      return a.getLineSegment()[0][0] - b.getLineSegment()[0][0];
    });
  } else {
    walls.sort(function (a, b) {
      if (Math.abs(a.getLineSegment()[0][1] - b.getLineSegment()[0][1]) < 0.3) {
        return b.getTopLineSegment()[0][2] - a.getTopLineSegment()[0][2];
      }
      return a.getLineSegment()[0][1] - b.getLineSegment()[0][1];
    });
  }

  // var iterator = walls.entries();
  let wall1 = walls[0];
  let wall2 = walls[1];

  let wall1TS = wall1.getTopLineSegment(true, 1);
  let wall2TS = wall2.getTopLineSegment(true, 1);
  let wall1LS = wall1.getLineSegment(true, 1);
  let wall2LS = wall2.getLineSegment(true, 1);

  if (
    this.utils.doIntersect(wall1LS[0], wall1LS[1], wall2LS[0], wall2LS[1]) ||
    this.utils.isContainedApprox(wall1LS, wall2LS)
  ) {
    walls.splice(0, 1);
    walls.splice(0, 1);
  } else if (
    !this.utils.doIntersect(wall1LS[0], wall1LS[1], wall2LS[0], wall2LS[1])
  ) {
    let index = walls[0].isNew ? 0 : walls[1].isNew ? 1 : 0;
    walls.splice(index, 1);
    normal_normal_mixedHeight.call(this);
    return;
  }

  if (wall1TS[0][2] !== wall2TS[0][2] || wall1TS[1][2] !== wall2TS[1][2]) {
    this.mergeDifferentHeight(wall1, wall2, wall1LS, wall2LS);
  } else {
    this.mergeSameHeight(wall1, wall2, wall1LS, wall2LS);
  }

  normal_normal_mixedHeight.call(this);
}

/**
 * { Algorithm to combine same height walls }
 */
function normal_normal_sheight(wallsArr = null) {
  let walls = wallsArr || this.walls;
  let allBotCoordsArr = [],
    allTopCoordsArr = [],
    factory = new Factory(),
    roomid = "",
    groupid = "",
    id = "",
    midY = 0,
    direction = null,
    meshStorey = null;

  for (let wall of walls) {
    Array.prototype.push.apply(allBotCoordsArr, wall.getBottomCoords());
    Array.prototype.push.apply(allTopCoordsArr, wall.getTopCoords());
    id += wall.room_id;
    midY += wall.midY;
    roomid += wall.room_id;
    if (groupid.length > 1) {
      if (groupid !== wall.groupId) {
        groupid = groupid + "|" + (wall.groupId ? wall.groupId : "default");
      }
    } else {
      groupid += wall.groupId;
    }
    direction = wall.direction;
    meshStorey = wall.storey;
  }

  midY /= walls.length;
  if (direction == "x") {
    allBotCoordsArr.sort(function (a, b) {
      return a[0] - b[0];
    });
    allTopCoordsArr.sort(function (a, b) {
      return a[0] - b[0];
    });
  } else {
    allBotCoordsArr.sort(function (a, b) {
      return a[1] - b[1];
    });
    allTopCoordsArr.sort(function (a, b) {
      return a[1] - b[1];
    });
  }

  allBotCoordsArr = this.utils.removeDuplicates(allBotCoordsArr);
  allTopCoordsArr = this.utils.removeDuplicates(allTopCoordsArr);

  // let structures = StructureCollection.getInstance();
  // let sc = structures.getStructureById(walls[0].structure_id);
  let structure_id = walls[0].structure_id;
  // let level = sc.getLevelByUniqueId(walls[0].level_id);
  let deletedWalls = [];

  for (let j = 0, a, b; j < allBotCoordsArr.length - 3; j += 2) {
    let horizontal_len =
      (a = calculateEuclidianDistance(
        allBotCoordsArr[j + 1],
        allBotCoordsArr[j + 2]
      )) <
      (b = calculateEuclidianDistance(
        allBotCoordsArr[j + 1],
        allBotCoordsArr[j + 3]
      ))
        ? a
        : b;
    let vertical_len = calculateEuclidianDistance(
      allBotCoordsArr[j],
      allBotCoordsArr[j + 1]
    );

    if (
      Math.abs(vertical_len - horizontal_len) <= this.isMinWallConstructThres &&
      j + 2 !== allBotCoordsArr.length
    ) {
      deletedWalls = deletedWalls.concat(allBotCoordsArr.slice(j + 2, 4));
      deletedWalls = deletedWalls.concat(allTopCoordsArr.slice(j + 2, 4));
      allBotCoordsArr.splice(j + 2, 2);
      allTopCoordsArr.splice(j + 2, 2);
    } else if (
      Math.abs(vertical_len - horizontal_len) <=
      this.utils.isMinWallConstructThres
    ) {
      deletedWalls = deletedWalls.concat(allBotCoordsArr.slice(j, 4));
      deletedWalls = deletedWalls.concat(allTopCoordsArr.slice(j, 4));
      allBotCoordsArr.splice(j, 2);
      allTopCoordsArr.splice(j, 2);
    }

    // console.log("Horizontal :", horizontal_len);
    // console.log("Vertical Len :", vertical_len);
  }

  let tempWalls = [];
  for (
    let i = 0, j = 0;
    i < (2 * allBotCoordsArr.length - 4) / 4;
    i++, j += 2
  ) {
    var wall_path_bottom = [
      allBotCoordsArr[j],
      allBotCoordsArr[j + 1],
      allBotCoordsArr[j + 2],
      allBotCoordsArr[j + 3],
    ];
    var wall_path_top = [
      allTopCoordsArr[j],
      allTopCoordsArr[j + 1],
      allTopCoordsArr[j + 2],
      allTopCoordsArr[j + 3],
    ];
    const heightObj = {
      low: allBotCoordsArr[i][2],
      high: allTopCoordsArr[i][2],
    };

    var points = {
      pol1: wall_path_bottom,
      pol2: wall_path_top,
      height: heightObj,
    };
    var wallMesh = factory.createWall("straight", points);
    wallMesh.storey = meshStorey;
    var wall = new Wall(wallMesh, id, structure_id, groupid);
    midY = (heightObj.high + heightObj.low) / 2;
    wall.adjustHeight(wall_path_bottom, wall_path_top, midY);
    // adjustMeshCoords(wallMesh, coords1, coords2, null, null);
    wall.setBottomCoords(wall_path_bottom);
    wall.setTopCoords(wall_path_top);
    wall.setMidYHeight(midY);
    this.level.addWallToLevel(wall, false);
    wall.assignProperties();
    wall.originalWallMesh = BABYLON.SceneSerializer.SerializeMesh(wallMesh);

    tempWalls.push(wall);
    createBuildingEngine.addComponent(wall);
  }

  // let deletedWallMesh = this.utils.makeDeletedWalls(deletedWalls, roomid, structure_id, this.level);
  // this.deletedWalls.push(deletedWallMesh);

  walls.forEach(function (wall) {
    wall.remove();
  });

  return tempWalls;
}

/**
 * { Algorithm to combine normal and  incline wall }
 */
function normal_incline() {}

var MergeWalls = function (execute, walls, level) {
  this.execute = execute;
  this.walls = walls;
  this.utils = new ResolveEngineUtils();
  this.deletedWalls = [];
  this.level = level;
};

var ResolveEngine = function () {
  let current,
    walls = new Map(),
    tempWalls = [],
    _deletedWalls = [];
  const _recurseIntersectSearch = function (wall, currentWalls, subLevelWalls) {
    let wallLS = wall[1].getLineSegment();
    for (let j = 0; j < currentWalls.length; j++) {
      let cwall = currentWalls[j];
      if (!cwall) continue;
      if (wall[1].room_id === cwall.room_id || wall[1].id === cwall.id) {
        continue;
      }
      let cwallLS = cwall.getLineSegment();
      if (!wallLS || !cwallLS) continue;
      if (this.util.doIntersect(wallLS[0], wallLS[1], cwallLS[0], cwallLS[1])) {
        tempWalls.push(j);
        tempWalls.push(cwall);
      }
    }

    subLevelWalls.push(wall[1]);
    currentWalls[wall[0]] = null;

    if (tempWalls.length == 0) return;
    _recurseIntersectSearch.call(
      this,
      tempWalls.splice(0, 2),
      currentWalls,
      subLevelWalls
    );
  };

  const _constructDeletedWalls = function () {
    return new Promise((resolve, reject) => {
      if (_.isEmpty(_deletedWalls)) {
        resolve();
      } else {
        _deletedWalls.forEach((arr) => {
          Promise.all(arr).then((data) => {
            data = data.map(async (subRes) => {
              let walls = subRes.walls;
              let level = subRes.level;
              walls = walls.map(async (wall) => {
                let isIntersection = _checkSingleInsectionForSmallWalls.call(
                  wall,
                  wall
                );
                if (!isIntersection) {
                  wall.mesh.isVisible = true;
                  wall.assignProperties();
                  wall.originalWallMesh = BABYLON.SceneSerializer.SerializeMesh(
                    wall.mesh
                  );
                  level.addWallToLevel(wall);
                } else {
                  wall.mesh.dispose();
                  wall = null;
                }
                return Promise.resolve();
              });

              Promise.all(walls)
                .then(() => {
                  return Promise.resolve();
                })
                .catch(() => {
                  return Promise.reject();
                });
            });
            Promise.all(data)
              .then(() => {
                _deletedWalls = [];
                resolve("done creating small walls");
              })
              .catch(() => {
                reject("error in creating small walls");
              });
          });
        });
      }
    });
  };

  const _checkSingleInsectionForSmallWalls = function (pwall) {
    var structureCollection = StructureCollection.getInstance();
    var structures = structureCollection.getStructures();

    if (!pwall) return;
    let pwalLS = pwall.getLineSegment();

    for (let structure in structures) {
      let levels = structures[structure].getAllLevels();

      for (let id in levels) {
        let level = levels[id];
        let currentWalls = level.getWalls();
        for (let j = 0; j < currentWalls.length; j++) {
          let cwall = currentWalls[j];
          if (!cwall || cwall.id === pwall.id) continue;

          let cwallLS = cwall.getLineSegment();
          if (
            this.utils.checkIntersectForSmallWalls(
              pwalLS[0],
              pwalLS[1],
              cwallLS[0],
              cwallLS[1]
            )
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return {
    tempWalls: tempWalls,
    walls: walls,
    startEngine: __solveWallIntersected,
    deletedWalls: _deletedWalls,
    execute: function (wallsObj) {
      current = wallsObj.execute();
      _deletedWalls.push(wallsObj.deletedWalls);
    },
    util: new ResolveEngineUtils(),
    getCurrentValue: function () {
      return current;
    },
    findIntersectedWalls: __findIntersectedWalls,
    recurseIntersectSearch: _recurseIntersectSearch,
    constructSmallDeletedWalls: _constructDeletedWalls,

    // resolveIntersectingRoofs: _resolveIntersectingRoofs,
    populateIntersectingRoofsInfo: _populateIntersectingRoofsInfo,
    combineRemainingRoofs: _combineRemainingRoofs,
    combineRoofsCSG: _combineRoofsCSG,
    getPointsOfIntersection: _getPointsOfIntersection,
    combineThePaths: _combineThePaths,
    logger: function () {
      return log.getLogger("resolve engine");
    },
  };
};

var NormalNormalMergeAlgo = function (walls, level) {
  //checking for same height
  //   let utils = new ResolveEngineUtils();
  for (let i = 0; i < walls.length - 1; i++) {
    let wall1 = walls[i].getTopLineSegment(true, 1);
    let wall2 = walls[i + 1].getTopLineSegment(true, 1);
    //   let wall1LS = walls[i].getLineSegment(true, 1);
    //   let wall2LS = walls[i+1].getLineSegment(true, 1);

    if (wall1[0][2] !== wall2[0][2] || wall1[1][2] !== wall2[1][2]) {
      return new MergeWalls(normal_normal_mixedHeight, walls, level);
    }
  }
  return new MergeWalls(normal_normal_sheight, walls, level);
};

var NormalInclinedAlgo = function () {
  return new MergeWalls(normal_incline, walls);
};

var storeyObj = function (number, level = []) {
  this.number = number;
  this.level = level;
};

storeyObj.prototype.valueOf = function () {
  //debugger
};

storeyObj.prototype.equals = function () {
  //debugger
};

storeyObj.prototype.toString = function () {
  //debugger
};

var __findIntersectedWalls = function (wall = null) {
  var structureCollection = StructureCollection.getInstance();
  var structures = structureCollection.getStructures();
  store.intersectingRoofs = [];
  let sameStoryWalls = {},
    allcurrWalls = [];

  for (let structure in structures) {
    let levels = structures[structure].getAllLevels();

    if (structure === "default") continue;

    for (let id in levels) {
      let level = levels[id];
      Array.prototype.push.apply(allcurrWalls, level.getWalls());
    }

    const allStoreys = StoreyMutation.getAllStoreys();
    allcurrWalls = allcurrWalls.filter((w) => {
      const storeyInStructure = allStoreys[w.storey];
      const storeyBase = storeyInStructure.base;
      return w.midY > storeyBase;
      // water body walls, need not be considered for resolution
    });

    for (let i = 0; i < allcurrWalls.length; i++) {
      if (sameStoryWalls.hasOwnProperty(allcurrWalls[i].storey)) {
        let t = sameStoryWalls[allcurrWalls[i].storey];
        t.push(allcurrWalls[i]);
      } else {
        sameStoryWalls[allcurrWalls[i].storey] = [];
        sameStoryWalls[allcurrWalls[i].storey].push(allcurrWalls[i]);
      }
    }

    for (let storey in sameStoryWalls) {
      let currentWalls = sameStoryWalls[storey];
      let level;
      let storeyOj = new storeyObj(storey);

      for (let i = 0; i < currentWalls.length - 1; i++) {
        let pwall = wall || currentWalls[i],
          breakFl = false;
        if (!pwall) continue;
        let pwalLS = pwall.getLineSegment();
        for (let j = i + 1; j < currentWalls.length; j++) {
          let cwall = currentWalls[j];
          if (!cwall) continue;
          if (pwall.room_id === cwall.room_id || pwall.id === cwall.id) {
            continue;
          }

          let cwallLS = cwall.getLineSegment();

          if (!pwalLS || !cwallLS) continue;
          if (
            this.util.doIntersect(pwalLS[0], pwalLS[1], cwallLS[0], cwallLS[1])
          ) {
            //intersectingRoofs.push([pwall.level_id, pwall.room_id, pwalLS[0], pwalLS[1], cwall.room_id, cwallLS[0], cwallLS[1]]);

            this.tempWalls.push(j);
            this.tempWalls.push(cwall);
            // currentWalls.splice(j,1);
            breakFl = true;
          }
        }
        if (breakFl) {
          if (wall) return true;

          if (!this.walls.has(storeyOj)) this.walls.set(storeyOj, []);
          let levelWalls = this.walls.get(storeyOj);

          if (!levelWalls) levelWalls = [];
          let subLevelWalls = [];

          subLevelWalls.push(pwall);
          currentWalls[i] = null;
          this.recurseIntersectSearch(
            this.tempWalls.splice(0, 2),
            currentWalls,
            subLevelWalls
          );

          subLevelWalls.forEach((wall) => {
            level = structures[structure].getLevelByUniqueId(wall.level_id);

            let lmn = storeyOj.level.find((elem) => {
              return elem.flyweight.uniqueId === level.flyweight.uniqueId;
            });
            if (!lmn) {
              storeyOj.level.push(level);
            }
          });

          levelWalls.push(subLevelWalls);
        }
      }

      if (level) {
        for (let i = 0; i < level.flyweight.walls.length; ) {
          if (level.flyweight.walls[i] === null) {
            level.flyweight.walls.splice(i, 1);
          } else i++;
        }
      }
    }
    //console.log("********** Done Finding Intersection");
  }
  if (wall) return false;
  // console.log(this.walls);
  return this.walls;
};

/*const combineThePathsBoolean = function(points1, points2){

    let offsetValue = userSetBIMPropertiesHandler.getRoofOverhang();
    let effectiveOffsetValue = offsetValue + userSetBIMPropertiesHandler.getWallThickness();
    let heightValue = points1[0][2];

    let polygons = [points1, points2].map(arrayOf3DPoints => {
        let offSetArray = getOffsetValues(arrayOf3DPoints, effectiveOffsetValue);
        // let offSetArray = arrayOf3DPoints;
        return offSetArray.map(array => _.dropRight(array, 1));
    });

    let roofPolygonsToGenerate;

    try {
        roofPolygonsToGenerate = externalUtil.getUnionOfPolygons(polygons);
    } catch (e) {
        console.warn(e);
    }

    if (!roofPolygonsToGenerate) return null;
    if (roofPolygonsToGenerate.length > 1) return null;

    let roofPoints = roofPolygonsToGenerate[0].map(p => [p[0], p[1], heightValue]);
    return getOffsetValues(roofPoints, -effectiveOffsetValue);
}*/

/*
let _resolveIntersectingRoofs = function (options) {
  let p1 = options.p1;
  let p2 = options.p2;
  let q1 = options.q1;
  let q2 = options.q2;

  let level_id = options.level_id1;
  let level_id2 = options.level_id2;

  let room_id1 = options.room_id1;
  let room_id2 = options.room_id2;

  /!*
    Check if intersection is true
    To filter out cases like p1---p2q1-----q2
    all 4 collinear and 2 duplicate points in the middle
     *!/

  let flag = false;
  let b1 = this.util.onSegment(p1, q1, p2, flag);
  let b2 = this.util.onSegment(p1, q2, p2, flag);
  let b3 = this.util.onSegment(q1, p1, q2, flag);
  let b4 = this.util.onSegment(q1, p2, q2, flag);

  let pts = []; //points in the middle of the union of walls
  if (b1) pts.push(q1);
  if (b2) pts.push(q2);
  if (b3) pts.push(p1);
  if (b4) pts.push(p2);

  if (pts.length === 2) {
    if (
      Math.abs(pts[0][0] - pts[1][0]) < 1 &&
      Math.abs(pts[0][1] - pts[1][1]) < 1
    ) {
      //console.log("Not intersecting");
      return;
    }
  }

  let roof1 = options.roof1;
  let roof2 = options.roof2;

  /!*
    Get the roofs to be merged
     *!/
  if (!roof1 || !roof2) {
    store.scene.meshes.some(function (mesh) {
      if (
        mesh.name === "Roof" &&
        mesh.getSnaptrudeDS().room_id.indexOf(room_id1) !== -1 &&
        (mesh.getSnaptrudeDS().level_id === level_id ||
          mesh.getSnaptrudeDS().level_id === level_id2)
      ) {
        roof1 = mesh.getSnaptrudeDS();
        room_id1 = roof1.room_id;
        if (roof2) return true;
      }
      if (
        mesh.name === "Roof" &&
        mesh.getSnaptrudeDS().room_id.indexOf(room_id2) !== -1 &&
        (mesh.getSnaptrudeDS().level_id === level_id ||
          mesh.getSnaptrudeDS().level_id === level_id2)
      ) {
        roof2 = mesh.getSnaptrudeDS();
        room_id2 = roof2.room_id;
        if (roof1) return true;
      }
    });
  }

  if (!roof1 || !roof2) return;
  if (roof1 === roof2) return;

  let points1 = roof1.roof_pol_bottom;
  points1 = convertLocalCoordsToGlobal(
    points1,
    roof1.mesh.getWorldMatrix(),
    false,
    true
  );
  let points2 = roof2.roof_pol_bottom;
  points2 = convertLocalCoordsToGlobal(
    points2,
    roof2.mesh.getWorldMatrix(),
    false,
    true
  );

  // let vertices = combineThePathsBoolean(points1, points2);
  // if (!vertices) vertices = combineThePathsBoolean(points1, points2, p1, p2, q1, q2);
  // if (!vertices) return;

  let vertices = this.combineThePaths(points1, points2, p1, p2, q1, q2);
  if (!vertices) return;

  // let mergedRoofMesh = await csgOperator.promisifiedUnion([roof1.mesh, roof2.mesh], [roof1.brep, roof2.brep]);
  // let mergedRoof = mergedRoofMesh.getSnaptrudeDS();

  /!*
    // For debug purposes

    console.log("---------------------------");
    console.log("Points 1 ");
    console.table(points1);
    console.log("Points 2 ");
    console.table(points2);
    console.log("p1 " + p1);
    console.log("p2 " + p2);
    console.log("q1 " + q1);
    console.log("q2 " + q2);
    console.log("Combined ");
    console.table(vertices);
    console.log("---------------------------");
    *!/

  let refMesh = {};
  refMesh.room_unique_id = room_id1 + room_id2;
  refMesh.room_type = "Room";
  refMesh.type = "Mass";
  refMesh.structure_id = roof1.structure_id;
  refMesh.roof_pol = [vertices];
  let mergedRoof = plainroof(refMesh)[0];

  mergedRoof.mesh.storey = roof1.mesh.storey;

  let structures = StructureCollection.getInstance();
  let str = structures.getStructureById(roof1.structure_id);
  let level = str.getLevelByUniqueId(level_id);

  level.addRoofToLevel(mergedRoof, false);

  roof1.type = "roof";
  roof2.type = "roof";
  level.removeObjectToLevel(roof1);
  level.removeObjectToLevel(roof2);

  roof1.mesh.dispose();
  roof2.mesh.dispose();
  // onSolid();

  return mergedRoof;
};
*/

let _combineThePaths = function (points1, points2, p1, p2, q1, q2) {
  let roof1point1 = null;
  let roof1point2 = null;
  let roof2point1 = null;
  let roof2point2 = null;

  let p1index = null;
  let p2index = null;
  let q1index = null;
  let q2index = null;

  let concaveVerts = [];

  /*
    The vertices of each roof in counter clockwise order is available.
    The information of vertices common to the roofs is obtained below
     */
  points1.some(function (point, index) {
    if (Math.abs(point[0] - p1[0]) < 1 && Math.abs(point[1] - p1[1]) < 1) {
      p1index = index;
      if (p2index) return true;
    }
    if (Math.abs(point[0] - p2[0]) < 1 && Math.abs(point[1] - p2[1]) < 1) {
      p2index = index;
      if (p1index) return true;
    }
  });

  let roof1PointsDetermined = true;
  let indices1 = [];

  if (p1index !== null && p2index !== null) {
    if (Math.abs(p1index - p2index) === 1) {
      roof1point1 = points1[Math.min(p1index, p2index)];
      roof1point2 = points1[Math.max(p1index, p2index)];
    } else {
      if (
        (p1index === 0 && p2index === points1.length - 1) ||
        (p2index === 0 && p1index === points1.length - 1)
      ) {
        roof1point1 = points1[points1.length - 1];
        roof1point2 = points1[0];
      } else {
        roof1PointsDetermined = false;
      }

      //console.log("Indices- " + p1index + " " + p2index);
    }
  } else {
    if (p1index === null && p2index === null) return;

    let index = null;
    let pt = null;
    if (p1index !== null) pt = points1[p1index];
    else if (p2index !== null) pt = points1[p2index];
    for (let i = 0; i < points1.length; i++) {
      if (this.util.getOrientationApprox(points1[i], p1, p2) === 0) {
        if (
          !(
            Math.abs(pt[0] - points1[i][0]) < 0.1 &&
            Math.abs(pt[1] - points1[i][1]) < 0.1
          )
        ) {
          indices1.push(i);
        }
      }
    }

    if (indices1.length !== 1) roof1PointsDetermined = false;
    else index = indices1[0];

    if (roof1PointsDetermined && p1index !== null) {
      if (
        (p1index === 0 && index === points1.length - 1) ||
        (index === 0 && p1index === points1.length - 1)
      ) {
        roof1point1 = points1[points1.length - 1];
        roof1point2 = points1[0];
      } else {
        roof1point1 = points1[Math.min(p1index, index)];
        roof1point2 = points1[Math.max(p1index, index)];
      }
    } else if (roof1PointsDetermined && p2index !== null) {
      if (
        (p2index === 0 && index === points1.length - 1) ||
        (index === 0 && p2index === points1.length - 1)
      ) {
        roof1point1 = points1[points1.length - 1];
        roof1point2 = points1[0];
      } else {
        roof1point1 = points1[Math.min(p2index, index)];
        roof1point2 = points1[Math.max(p2index, index)];
      }
    }
  }

  points2.some(function (point, index) {
    if (Math.abs(point[0] - q1[0]) < 1 && Math.abs(point[1] - q1[1]) < 1) {
      q1index = index;
      if (q2index) return true;
    }
    if (Math.abs(point[0] - q2[0]) < 1 && Math.abs(point[1] - q2[1]) < 1) {
      q2index = index;
      if (q1index) return true;
    }
  });

  let roof2PointsDetermined = true;
  let indices2 = [];

  if (q1index !== null && q2index !== null) {
    if (Math.abs(q1index - q2index) === 1) {
      roof2point1 = points2[Math.min(q1index, q2index)];
      roof2point2 = points2[Math.max(q1index, q2index)];
    } else {
      if (
        (q1index === 0 && q2index === points2.length - 1) ||
        (q2index === 0 && q1index === points2.length - 1)
      ) {
        roof2point1 = points2[points2.length - 1];
        roof2point2 = points2[0];
      } else {
        roof2PointsDetermined = false;
      }
      //console.log("Indices- " + q1index + " " + q2index);
    }
  } else {
    if (q1index === null && q2index === null) return;

    let index = null;
    let pt = null;
    if (q1index !== null) pt = points2[q1index];
    else if (q2index !== null) pt = points2[q2index];
    for (let i = 0; i < points2.length; i++) {
      if (this.util.getOrientationApprox(points2[i], q1, q2) === 0) {
        if (
          !(
            Math.abs(pt[0] - points2[i][0]) < 0.1 &&
            Math.abs(pt[1] - points2[i][1]) < 0.1
          )
        ) {
          indices2.push(i);
        }
      }
    }

    if (indices2.length !== 1) roof2PointsDetermined = false;
    else index = indices2[0];

    if (roof2PointsDetermined && q1index !== null) {
      if (
        (q1index === 0 && index === points2.length - 1) ||
        (index === 0 && q1index === points2.length - 1)
      ) {
        roof2point1 = points2[points2.length - 1];
        roof2point2 = points2[0];
      } else {
        roof2point1 = points2[Math.min(q1index, index)];
        roof2point2 = points2[Math.max(q1index, index)];
      }
    } else if (roof2PointsDetermined && q2index !== null) {
      if (
        (q2index === 0 && index === points2.length - 1) ||
        (index === 0 && q2index === points2.length - 1)
      ) {
        roof2point1 = points2[points2.length - 1];
        roof2point2 = points2[0];
      } else {
        roof2point1 = points2[Math.min(q2index, index)];
        roof2point2 = points2[Math.max(q2index, index)];
      }
    }
  }

  if (!roof1PointsDetermined && !roof2PointsDetermined) return;

  if (!roof1PointsDetermined) {
    let minDist = 10000;
    let minIndex = null;
    indices1.forEach(function (index) {
      let pt = points1[index];
      let dist = BABYLON.Vector3.Distance(
        new BABYLON.Vector3(pt[0], pt[2], pt[1]),
        new BABYLON.Vector3(roof2point2[0], roof2point2[2], roof2point2[1])
      );
      if (dist < minDist) {
        minIndex = index;
        minDist = dist;
      }
    });

    roof1point1 = points1[minIndex];
  }

  if (!roof2PointsDetermined) {
    let minDist = 10000;
    let minIndex = null;
    indices2.forEach(function (index) {
      let pt = points2[index];
      let dist = BABYLON.Vector3.Distance(
        new BABYLON.Vector3(pt[0], pt[2], pt[1]),
        new BABYLON.Vector3(roof1point1[0], roof1point1[2], roof1point1[1])
      );
      if (dist < minDist) {
        minIndex = index;
        minDist = dist;
      }
    });

    roof2point2 = points2[minIndex];
  }

  if (!roof1point1 || !roof2point2) return;

  concaveVerts.push(roof1point1, roof1point2, roof2point1, roof2point2);

  let index1 = points1.indexOf(roof1point1);
  let index2 = points2.indexOf(roof2point2);

  let vertices = [];
  vertices.push(...points1.slice(0, index1 + 1));
  vertices.push(...points2.slice(index2));
  vertices.push(...points2.slice(0, index2));
  vertices.push(...points1.slice(index1 + 1));

  let doublyLinkedList = new DLL.DoublyLinkedList();

  for (let i = 0; i < vertices.length; i++) {
    doublyLinkedList.append(vertices[i]);
  }

  let toBeRemoved = [];
  let elements = [];
  let number = [];

  /*
       Fix overlapping paths in the resultant vertex path of merged roof
       Works by finding palindrome like sequences
    */
  for (
    let pt = doublyLinkedList.head(), i = 0;
    i < doublyLinkedList.size();
    pt = doublyLinkedList.item(i + 1), i++
  ) {
    let nextPt = pt;
    for (let j = 1; j < doublyLinkedList.size() - 1; j++) {
      nextPt = nextPt.next;

      let count = -1;
      let pt1 = pt;
      let pt2 = nextPt;
      let toBeContinued = false;
      while (++count < j / 2) {
        if (!this.util.areArraysAlmostEqual(pt1.data, pt2.data)) {
          toBeContinued = true;
          break;
        }
        pt1 = pt1.next;
        pt2 = pt2.prev;
      }
      if (toBeContinued) continue;

      toBeRemoved.push(i);
      number.push(j);

      elements.push(
        Array.from(
          { length: j + 1 },
          (v, k) => (k + i) % doublyLinkedList.size()
        )
      );

      i += j;
    }
  }

  if (!_.isEmpty(toBeRemoved)) {
    let sum = 0;
    toBeRemoved.forEach(function (ver, index) {
      for (let i = index + 1; i < elements.length; i++) {
        if (_.difference(elements[index], elements[i]).length === 0) {
          return;
        }
      }

      if (ver - sum + number[index] > vertices.length) {
        let start = ver - sum;
        let diff = vertices.length - start;

        vertices.splice(start, diff);
        vertices.splice(0, number[index] - diff);
      } else {
        vertices.splice(ver - sum, number[index]);
      }
      // if (!(index === 0)) sum += number[index];
      sum += number[index];
    });
  }

  doublyLinkedList = new DLL.DoublyLinkedList();
  for (let i = 0; i < vertices.length; i++) {
    doublyLinkedList.append(vertices[i]);
  }

  /*
      Remove intermediate redundant points that fall on a straight line
    */
  for (
    let pt = doublyLinkedList.head(), i = 0, k = 0;
    i < doublyLinkedList.size();
    pt = doublyLinkedList.item(i + 1), i++
  ) {
    let p = pt.prev.data;
    let q = pt.data;
    let r = pt.next.data;

    /*
        if (Math.abs(p[2]-q[2]) < 0.1 && Math.abs(q[2]-r[2]) < 0.1) {
            // if (this.util.getOrientationApprox(p, q, r) === 0) {
            if (this.util.getOrientation3D(p, q, r) === 0) {
                //if (this.util.onSegment(p, q, r, flag)) {
                vertices.splice(i - k, 1);
                k++;
                //}
            }
        }
        else {
            if (this.util.getOrientation3D(q, p, r) === 0) {
                //if (this.util.onSegment(p, q, r, flag)) {
                vertices.splice(i - k, 1);
                k++;
                //}
            }
        }
        */

    let threshold = 1e-4;

    if (this.util.onSegment3D(p, q, r, threshold)) {
      vertices.splice(i - k, 1);
      k++;
    } else if (this.util.onSegment3D(q, p, r, threshold)) {
      vertices.splice(i - k, 1);
      k++;
    } else if (this.util.onSegment3D(p, r, q, threshold)) {
      vertices.splice(i - k, 1);
      k++;
    }
  }

  return vertices;
};

let _populateIntersectingRoofsInfo = function (wallMap) {
  for (let level of wallMap.keys()) {
    let wallArr = wallMap.get(level);
    let util = this.util;
    wallArr.forEach(function (walls) {
      let dir = walls[0].direction;
      if (dir === "x") {
        walls.sort(function (a, b) {
          return a.bottomLineSegment[0] - b.bottomLineSegment[0];
        });
      } else {
        walls.sort(function (a, b) {
          return a.bottomLineSegment[1] - b.bottomLineSegment[1];
        });
      }

      for (let i = 0; i < walls.length; i++) {
        let wall1 = walls[i];
        for (let j = i + 1; j < walls.length; j++) {
          let wall2 = walls[j];
          if (wall1.midY !== wall2.midY) continue;
          if (wall1.level_id !== wall2.level_id) continue;

          let p1 = wall1.bottomLineSegment[0];
          let p2 = wall1.bottomLineSegment[1];
          let q1 = wall2.bottomLineSegment[0];
          let q2 = wall2.bottomLineSegment[1];

          if (!util.doIntersect(p1, p2, q1, q2)) continue;

          let returnObj = {
            p1,
            p2,
            q1,
            q2,

            level_id1: wall1.level_id,
            level_id2: wall2.level_id,
            room_id1: wall1.room_id,
            room_id2: wall2.room_id,
          };

          store.intersectingRoofs.push(returnObj);
        }
      }
    });
  }
};

/*
Not being used for slant roofs, but will be needed.
 */
let _combineRemainingRoofs = function () {
  let _combineRoofs = function (roofsInScene) {
    for (let i = 0; i < roofsInScene.length; i++) {
      let roof1 = roofsInScene[i];
      if (roof1.isDisposed() || !roof1.getSnaptrudeDS()) continue;

      for (let j = i + 1; j < roofsInScene.length; j++) {
        let roof2 = roofsInScene[j];
        if (roof2.isDisposed() || !roof2.getSnaptrudeDS()) continue;

        if (roof1.intersectsMesh(roof2, true)) {
          let combination = store.combineRoofs(
            roof1.getSnaptrudeDS(),
            roof2.getSnaptrudeDS()
          );
          if (combination.combined) {
            roofsInScene.push(combination.newRoof.mesh);
            _.remove(roofsInScene, (r) => {
              return r.isDisposed();
            });

            _combineRoofs(roofsInScene);

            return;
          }
        }
      }
    }
  };

  let roofsInScene = store.scene.meshes.map((m) => {
    if (m.type === "Roof") {
      return m;
    }
  });

  roofsInScene = _.compact(roofsInScene);

  _combineRoofs(roofsInScene);

  /*
    for (let i=0, length = store.scene.meshes.length; i<length; i++){
        let mesh = store.scene.meshes[i];
        // if (mesh.type === "Roof" && mesh.getSnaptrudeDS().type === "Slant"){
        if (mesh.type === "Roof" && mesh.getSnaptrudeDS().type.toLowerCase() !== "slant"){
            for (let j=0; j<length; j++) {
                if (j === i) continue;
                let mesh2 = store.scene.meshes[j];
                if (mesh2.type === "Roof" && mesh2.getSnaptrudeDS().type.toLowerCase() !== "slant"){
                    if (mesh.intersectsMesh(mesh2, true)){
                        let combination = combineRoofs(mesh.getSnaptrudeDS(), mesh2.getSnaptrudeDS());
                        if (combination){
                            length--;
                            i = -1;
                            break;
                        }
                        // continue;
                    }
                }
            }
        }
    }
    */
};

let _combineRoofsCSG = async function () {
  let clustersOfIntersectingRoofs = [];

  const _deleteInternalSlabs = function (roofsInScene) {
    const _isRoof2WithinRoof1 = function (roof1, roof2) {
      if (roof1 === roof2) return false;
      if (!roof1.mesh.intersectsMesh(roof2.mesh)) return false;

      const roof1Pol = convertLocalCoordsToGlobal(
        roof1.getRoofPolBottom(),
        roof1.mesh.getWorldMatrix()
      );
      const roof1Ys = [];
      const roof1Pol2D = roof1Pol.map((r) => {
        roof1Ys.push(r[1]);
        return [r[0], r[2]];
      });

      const roof2Pol = convertLocalCoordsToGlobal(
        roof2.getRoofPolBottom(),
        roof2.mesh.getWorldMatrix()
      );
      const roof2Ys = [];
      const roof2Pol2D = roof2Pol.map((r) => {
        roof2Ys.push(r[1]);
        return [r[0], r[2]];
      });

      if (isFloatEqual(average(roof1Ys), average(roof2Ys), 0.1)) {
        return isCompletelyInside(roof1Pol2D, roof2Pol2D, true);
      } else {
        return false;
      }
    };

    let roofsToRemove = [];
    roofsInScene.forEach((roof1) => {
      roofsInScene.forEach((roof2) => {
        if (_isRoof2WithinRoof1(roof1, roof2)) {
          roofsToRemove.push(roof2);
        }
      });
    });

    roofsToRemove = _.uniq(roofsToRemove);

    roofsToRemove.forEach((r) => {
      _.remove(roofsInScene, r);
      createBuildingEngine.removeComponent(r);
    });
  };

  const _presentInCluster = function (roof) {
    let { present, clusterId } = _getClusterDetails(roof);
    return present;
  };

  const _getClusterDetails = function (roof) {
    let present = false;
    let clusterId;
    _.some(clustersOfIntersectingRoofs, (cluster, index) => {
      present = _.includes(cluster, roof);
      clusterId = index;
      return present;
    });

    return { present, clusterId };
  };

  const _clusterRoofs = function (roofsInScene) {
    for (let i = 0; i < roofsInScene.length; i++) {
      let roof1 = roofsInScene[i];
      let roofMesh1 = roof1.mesh;
      if (roofMesh1.isDisposed() || !roof1) continue;

      let clusterDetailsRoof1 = _getClusterDetails(roof1);

      for (let j = i + 1; j < roofsInScene.length; j++) {
        let roof2 = roofsInScene[j];
        let roofMesh2 = roof2.mesh;
        if (roofMesh2.isDisposed() || !roof2) continue;

        let clusterDetailsRoof2 = _getClusterDetails(roof2);

        if (clusterDetailsRoof1.present && clusterDetailsRoof2.present) {
          if (clusterDetailsRoof1.clusterId === clusterDetailsRoof2.clusterId)
            continue;
        }

        if (roofMesh1.intersectsMesh(roofMesh2, true)) {
          let intersectionInfo = _getIntersectionInfo(roof1, roof2);
          if (intersectionInfo) {
            if (clusterDetailsRoof1.present && clusterDetailsRoof2.present) {
              clustersOfIntersectingRoofs[clusterDetailsRoof1.clusterId].push(
                ...clustersOfIntersectingRoofs[clusterDetailsRoof2.clusterId]
              );
              clustersOfIntersectingRoofs.splice(
                clusterDetailsRoof2.clusterId,
                1
              );

              clusterDetailsRoof1 = _getClusterDetails(roof1);
            } else if (clusterDetailsRoof1.present) {
              clustersOfIntersectingRoofs[clusterDetailsRoof1.clusterId].push(
                roof2
              );
              // drawSelectionBox(roofMesh2);
            } else if (clusterDetailsRoof2.present) {
              clustersOfIntersectingRoofs[clusterDetailsRoof2.clusterId].push(
                roof1
              );
              clusterDetailsRoof1 = {
                present: true,
                clusterId: clusterDetailsRoof2.clusterId,
              };
              // drawSelectionBox(roofMesh1);
            } else {
              clustersOfIntersectingRoofs.push([roof1, roof2]);
              clusterDetailsRoof1 = {
                present: true,
                clusterId: clustersOfIntersectingRoofs.length - 1,
              };
              // drawSelectionBox(roofMesh1);
              // drawSelectionBox(roofMesh2);
            }

            // store.scene.render();
            // continue outerLoop;
          }
        }
      }
    }
  };

  const _assignProperties = function (roof) {
    const _verifyThickness = function () {
      const mesh = roof.mesh;
      const boundInfo = mesh.getBoundingInfo();
      const currentThickness =
        boundInfo.boundingBox.extendSize.y * Math.abs(mesh.scaling.y) * 2;
      const scalingValue = mesh.scaling.clone();

      let slabThickness;
      roof.computeSlabType();
      if (roof.slabType === Roof.CONSTANTS().SLAB_TYPES.PLINTH)
        slabThickness =
          store.projectProperties.properties.plinthHeightProperty.getValue();
      else
        slabThickness =
          store.projectProperties.properties.slabThicknessProperty.getValue();
      // mesh.scaling.y = (scalingValue.y * plainRoofParameters.roofDepth) / currentThickness;
      mesh.scaling.y = (scalingValue.y * slabThickness) / currentThickness;
      mesh.position.y -=
        (mesh.scaling.y - scalingValue.y) * boundInfo.boundingBox.extendSize.y;
    };

    _verifyThickness();

    roof.assignProperties();
    roof.mesh.computeWorldMatrix(true);

    populateRoofBoundInfo(roof);

    roof.setRoofPolBottom(
      getOffsetValues(roof.getRoofPolOffsetBottom(), -roof.getRoofOffset(),
      { subtract: true }
    ));

    roof.setRoofPolTop(
      getOffsetValues(roof.getRoofPolOffsetTop(), -roof.getRoofOffset(),
      { subtract: true }
    ));

    if (roof.getRoofPolBottom().length === 3) {
      if (roof.brep.getFaces().length > 5) {
        // courtyard slab
        roof.disableOverhang();
      }
    }
  };

  const _combineRoofs = function () {
    let resolve;
    let overallPromise = new Promise((r) => (resolve = r));

    let csgPromises = [];

    _.forEach(clustersOfIntersectingRoofs, (cluster) => {
      cluster.forEach((c) => {
        c.previousOverhang = c.offset;
        c.overhang(plainRoofParameters.overhangBeforeUnion);
      });

      let referenceRoof = cluster[0];
      let structureCollection = StructureCollection.getInstance();
      let structure = structureCollection.getStructureById(
        referenceRoof.structure_id
      );
      let level = structure.getLevelByUniqueId(referenceRoof.level_id);

      csgPromises.push(
        csgOperator
          .promisifiedUnion(
            cluster.map((c) => c.mesh),
            cluster.map((c) => c.brep),
            { roundThreshold: 2 }
          )
          .then(({ mesh, brep }) => {
            mesh.structure_id = referenceRoof.structure_id;
            mesh.type = "Roof";
            level.addMeshToLevel(mesh, false);

            let newRoof = mesh.getSnaptrudeDS();
            newRoof.brep = brep;
            // newRoof.offset = referenceRoof.offset;
            newRoof.offset = plainRoofParameters.overhangBeforeUnion;

            let isCircularMass =  !!cluster.find((roof) => roof.properties.belongsToCircularMass == true);
            rubSandpaper(newRoof, { removeCollinearVertices: !isCircularMass });

            try {
              _assignProperties(newRoof);
            } catch (e) {
              console.warn(e);
            }

            createBuildingEngine.addComponent(newRoof);

            cluster.forEach((roof) => {
              roof.overhang(roof.previousOverhang);
              delete roof.previousOverhang;
              // so that if user does undo after create room, slab overhang should be restored
              createBuildingEngine.removeComponent(roof);
            });
          })
          .catch((e) => {
            console.log(e);
          })
      );
    });

    Promise.all(csgPromises).finally(() => {
      resolve();
    });

    return overallPromise;
  };

  let roofsInScene = store.scene.meshes.map((m) => {
    if (m.type === "Roof") {
      let roof = m.getSnaptrudeDS();
      // if (!roof.isEdited() && !roof.isOverhangDisabled()) {
      if (!roof.isEdited()) {
        return roof;
      }
    }
  });

  roofsInScene = _.compact(roofsInScene);

  _deleteInternalSlabs(roofsInScene);
  _clusterRoofs(roofsInScene);

  return _combineRoofs();
};

let _getIntersectionInfo = function (roof1, roof2) {
  let path1 = roof1.getRoofPolBottom();
  let path2 = roof2.getRoofPolBottom();


  path1 = convertLocalCoordsToGlobal(path1, roof1.mesh.getWorldMatrix(), false, false);
  path2 = convertLocalCoordsToGlobal(path2, roof2.mesh.getWorldMatrix(), false, false);

  /*
  // crazy bug
  // BABYLON.Vector3.FromArray receives a second 'offset' argument,
  // which map passes as index which messes up the whole thing lol

  path1 = path1.map(BABYLON.Vector3.FromArray);
  path2 = path2.map(BABYLON.Vector3.FromArray);
  */

  path1 = path1.map(arr => BABYLON.Vector3.FromArray(arr));
  path2 = path2.map(arr => BABYLON.Vector3.FromArray(arr));


  let resolveEngine = new ResolveEngine();

  for(let i = 0; i <= path1.length - 1; i++){
    for(let j = 0; j <= path2.length - 1; j++){
      let nextI = i === path1.length - 1 ? 0 : i + 1;
      let nextJ = j === path2.length - 1 ? 0 : j + 1;

      const e1 = {
        headPt : path1[i],
        tailPt : path1[nextI],
      };

      const e2 = {
        headPt : path2[j],
        tailPt : path2[nextJ],
      };

      if(areEdgesIntersecting(e1, e2) || areEdgesOverlapping(e1, e2, false))  return true;

      // if(resolveEngine.util.doIntersect(path1[i], path1[i+1], path2[j], path2[j+1]))  return true;
    }
  }
  return false;

  // return resolveEngine.getPointsOfIntersection(path1, path2);
};

// Old Logic. Not used anymore
// let combineRoofs = function(roof1, roof2){

//     let level_id1 = roof1.level_id;
//     let room_id1 = roof1.room_id;
//     let level_id2 = roof2.level_id;
//     let room_id2 = roof2.room_id;
//     let combined = false;
//     let newRoof = null;

//     let resolveEngine = new ResolveEngine();
//     let intersectionInfo = _getIntersectionInfo(roof1, roof2);
//     if (intersectionInfo.found) {

//         let options = {
//             p1 : intersectionInfo.p1,
//             p2 : intersectionInfo.p2,
//             q1 : intersectionInfo.q1,
//             q2 : intersectionInfo.q2,

//             level_id1,
//             level_id2,
//             room_id1,
//             room_id2,

//             roof1,
//             roof2
//         };

//         let roof = resolveEngine.resolveIntersectingRoofs(options);

//         if (roof) {
//             combined = true;
//             newRoof = roof;
//         }
//     }

//     return {
//         combined,
//         newRoof
//     };

// };

function _getPointsOfIntersection(loop1, loop2) {
  let returnObj = { found: false };
  let p1 = null;
  let p2 = null;
  let q1 = null;
  let q2 = null;
  let pathList1 = new DLL.DoublyLinkedList();
  for (let i = 0; i < loop1.length; i++) {
    pathList1.append(loop1[i]);
  }
  let pathList2 = new DLL.DoublyLinkedList();
  for (let i = 0; i < loop2.length; i++) {
    pathList2.append(loop2[i]);
  }

  for (
    let pt = pathList1.head(), i = 0;
    i < pathList1.size();
    pt = pathList1.item(i + 1), i++
  ) {
    p1 = pt.data;
    p2 = pt.next.data;

    // if (p1[2] % 12 > 1 || p2[2] % 12 > 1) continue;
    for (
      let pt2 = pathList2.head(), j = 0;
      j < pathList2.size();
      pt2 = pathList2.item(j + 1), j++
    ) {
      q1 = pt2.data;
      q2 = pt2.next.data;

      /*if (this.util.doIntersect(p1, p2, q1, q2, true)){
                returnObj.found = true;
                returnObj.p1 = p1;
                returnObj.p2 = p2;
                returnObj.q1 = q1;
                returnObj.q2 = q2;

                // break;
                return returnObj;
            }*/

      if (this.util.areCollinear(p1, p2, q1, q2, 0.1)) {
        returnObj.found = true;
        returnObj.p1 = p1;
        returnObj.p2 = p2;
        returnObj.q1 = q1;
        returnObj.q2 = q2;

        // break;
        return returnObj;
      }
    }
  }

  return returnObj;
}

var __typeResolver = async function (walls, level) {
  let dir = walls[0].direction;
  if (dir === "x") {
    walls.sort(function (a, b) {
      return a.bottomLineSegment[0] - b.bottomLineSegment[0];
    });
  } else {
    walls.sort(function (a, b) {
      return a.bottomLineSegment[1] - b.bottomLineSegment[1];
    });
  }

  for (let i = 0; i < walls.length; ) {
    let wallpr = walls[i].profile;
    let index = walls.findIndex((wall) => wall.profile !== wallpr);

    if (index !== -1) {
      switch (wallpr) {
        case 1:
          // statements_1
          break;
        case 2:
          this.execute(
            new NormalNormalMergeAlgo(walls.splice(0, index), level)
          );
          break;
        default:
          throw "Error! undefined wall profile";
      }
      i += index;
    } else {
      switch (wallpr) {
        case 1:
          // statements_1
          break;
        case 2:
          this.execute(new NormalNormalMergeAlgo(walls, level));
          break;
        default:
          throw "Error! undefined wall profile";
      }
      i += walls.length;
    }
  }

  return Promise.resolve(walls);
};

var __solveWallIntersected = function (wallMap) {
  __typeResolver = __typeResolver.bind(this);

  let _storeyResolve = function (storey) {
    return new Promise((resolve, reject) => {
      let subIntersection = wallMap.get(storey);

      let level = storey.level[0]; //need to change
      subIntersection = subIntersection.map(async (subWalls) => {
        try {
          await __typeResolver(subWalls, level);
        } catch (e) {
          return Promise.reject(e);
        }
        subWalls = null;
        return Promise.resolve(subWalls);
      });

      Promise.all(subIntersection).then(
        (success) => {
          //console.log("wall finalized for level :", level.flyweight.uniqueId, " limit :", level.flyweight.low, level.flyweight.high);
          wallMap.delete(level);
          resolve("done sublevel");
        },
        (m) => {
          reject(m);
        }
      );
    });
  };

  return new Promise((resolve, reject) => {
    let levelPromises = [];
    for (let storey of wallMap.keys()) {
      levelPromises.push(_storeyResolve(storey));
    }
    Promise.all(levelPromises)
      .then((success) => {
        resolve("done for all levels");
      })
      .catch((error) => {
        console.log(error);
        reject("error in all levels");
      });
  });
};
export {
  ResolveEngineUtils,
  normal_normal_dheight,
  normal_normal_mixedHeight,
  normal_normal_sheight,
  normal_incline,
  MergeWalls,
  ResolveEngine,
  NormalNormalMergeAlgo,
  NormalInclinedAlgo,
  storeyObj,
  __findIntersectedWalls,
  _combineThePaths,
  _populateIntersectingRoofsInfo,
  _combineRemainingRoofs,
  _combineRoofsCSG,
  _getIntersectionInfo,
  _getPointsOfIntersection,
  __typeResolver,
  __solveWallIntersected,
};
