import { store } from "../utilityFunctions/Store.js";
import { terrainGeneration } from "./terrainMap";
import { DisplayOperation } from "../displayOperations/displayOperation.js";
import _ from "lodash";
import { commandUtils } from "../commandManager/CommandUtils.js";
import { CommandManager } from "../commandManager/CommandManager.js";
import {
  changeOrderForHoles,
  convertArray2ToArray3,
  removecollinear,
  removeDuplicateVertices,
  removeDuplicateVerticesModified,
  removeZeroArrays,
  zeroDownZ,
} from "../../libs/twoD/twoServices.js";
import { createCustomMesh } from "../../libs/massModeling.js";
import BABYLON from "../babylonDS.module.js";
import { StructureCollection } from "../snaptrudeDS/structure.ds.js";
import {
  onSolid,
  setActiveLayerAndRecord,
  removeMeshFromStructure,
  metresToSnaptrudeUnits,
  showToast,
  TOAST_TYPES,
} from "../extrafunc.js";
import { Mass } from "../snaptrudeDS/mass.ds.js";
import { makeid } from "../../libs/arrayFuncs.js";
import { virtualSketcher } from "../sketchMassBIMIntegration/virtualSketcher.js";
import reduxStore from "../../stateManagers/store/reduxStore.js";
import { layerView } from "../../libs/layerView.js";
import {
  appendLayer,
  deleteLayer,
} from "../../stateManagers/reducers/objectProperties/storeysSlice.js";
import NeighborLayer from "../../../assets/tiles/NeighborLayer.svg";
import { AutoSave } from "../socket/autoSave.js";
import { Command } from "../commandManager/Command.js";
import { meshUniqueIdMapper } from "../utilityFunctions/meshUniqueIdMapper.js";

const isNeighborhoodBuilding = (mesh) => {
  return mesh.room_type === "neighborhoodBuilding";
};

const drawNeighborhoodMass = (
  pointArray,
  roomName,
  roomType,
  roomID,
  structureID,
  isCurve,
  yPos,
  levelID,
  massHeight,
  parent
) => {

  let newPoly = [];
  newPoly = convertArray2ToArray3(pointArray[0]);
  //For rooms with holes
  let zeroedZHoles = [];
  if (pointArray[1] !== undefined) {
    let holes = pointArray[1];
    holes = removeZeroArrays(holes);
    for (let i = 0; i < holes.length; i++) {
      holes[i] = convertArray2ToArray3(holes[i]);
      let h = zeroDownZ(holes[i], yPos);
      h.pop();
      h = removeDuplicateVertices(h);
      h = removeDuplicateVerticesModified(h);

      h = changeOrderForHoles(h);
      zeroedZHoles.push(h);
    }
  }

  let zeroedZ = zeroDownZ(newPoly, yPos);

  zeroedZ.pop(); //last point is the same as the first

  // height for buildings
  let height;
  if (massHeight) {
    height = metresToSnaptrudeUnits(massHeight);
  }
  zeroedZ = removeDuplicateVertices(zeroedZ);
  zeroedZ = removeDuplicateVerticesModified(zeroedZ);

  let building;
  let data = {
    polygon: zeroedZ,
    height: height,
    uniqueId: roomID === 1 ? undefined : roomID,
  };
  data.polygon = removecollinear(data.polygon);

  building = createCustomMesh(
    data.polygon,
    data.height,
    null,
  );
  if (data.uniqueId) {
    meshUniqueIdMapper.update(building, data.uniqueId);
  }

  building.pointsUsed = data.polygon;
  building.checkCollisions = true;
  building.name = roomName;
  building.room_type = roomType;
  building.room_id = makeid(3);
  building.room_curve = isCurve;
  building.room_path = JSON.stringify(data.polygon);
  building.height = height;
  building.showBoundingBox = false;
  building.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  building.level = levelID;
  building.room_unique_id = building.room_id;
  building.structure = store.activeLayer.structure_id;
  building.offsetFlag = false;
  building.type = "Mass";
  building.storey = store.activeLayer.storey;
  building.isVisible = true;
  building.structure_id = structureID;

  // handle parent child relationship (position and rotation)
  if (parent) {
  building.layerName = "buildings-" + parent.layerName.slice(-1);
    building.position.x =
      building.position.x + parent.position.x - parent._width / 2;
    building.position.z =
      building.position.z + parent.position.z + parent._height / 2;

    let rotation;
    if (parent.rotationQuaternion) {
      rotation = parent.rotationQuaternion.asArray();
      parent.rotationQuaternion = new BABYLON.Quaternion(0, 0, 0, 1);
    }
    building.setParent(parent);
    if (parent.rotationQuaternion) {
      parent.rotationQuaternion = new BABYLON.Quaternion(
        rotation[0],
        rotation[1],
        rotation[2],
        rotation[3]
      );
    }
    let elevation = parent._subdivisionsX === 1 ? false : true;
    if (!elevation) {
      building.position.y = building.height / 2;
    } else {
      building.position.y = yPos + building.height / 2;
    }
  }

  const structureCollection = StructureCollection.getInstance();
  const talkingAboutStructure =
    structureCollection.getStructureById(structureID);
  const talkingAboutLevel = talkingAboutStructure.getLevelByName(levelID);
  talkingAboutLevel.addObjectToLevel(new Mass(building), false);

  let buildingDS = building.getSnaptrudeDS();
  buildingDS.room_type = building.room_type;
  buildingDS.room_id = building.room_id;
  buildingDS.massType = roomName;
  building.typeChangeAllowed = false;
  buildingDS.elevation = yPos;
  onSolid(building);

  // building.edgesWidth = store.$scope.isTwoDimension ? 7 : 70;
  if (!store.neighborhoodBuildings[parent.layerName].includes(building.uniqueId)) {
    store.neighborhoodBuildings[parent.layerName].push(building.uniqueId);
  }
  return building
};

const drawPolygon = (pol, height = 0, yPosition, parent) => {
  let polygon = JSON.parse(JSON.stringify(pol));
  polygon.push(polygon[0]);
  let activeLayer = store.activeLayer;
  // parameters.push(); // store all parameter for reconstruction in case of undo for delete + delete layer
  let building = drawNeighborhoodMass(
    [polygon, undefined],
    "Building",
    "neighborhoodBuilding",
    1,
    activeLayer.structure_id,
    false,
    yPosition,
    "01",
    height,
    parent
  );
  return {building, parameter : {
    polygon,
    height,
    yPosition,
  }}
};

const getBuildingFeatureData = (map) =>{
  let featuresBuildings;
  const layers = map.getStyle().layers;
  const labelLayerId = layers.find(
      (layer) => layer.type === "symbol" && layer.layout["text-field"]
  ).id;

  map.addLayer(
      {
        id: "add-3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: [">", "height", 0],
        type: "fill-extrusion",
        minzoom: 15,
      },
      labelLayerId
    );

    featuresBuildings = map.queryRenderedFeatures({
      layers: ["building"],
      filter: [">", "height", 0],
    });
  return featuresBuildings
}

const createNeighborhood = (
  bounds,
  mapData,
  parentId,
  parameters = [],
  options = {}
) => {
  let map;
  let buildingStack = [];
  let parent = store.scene.getMeshByUniqueID(parentId); //get Parent mesh
  let _layerName = "buildings-" + parent?.layerName?.slice(-1);

  const drawBuilding = (pol, props, nw, parent) => {
    let map = store.terrainMap;
    for (let i = 0; i < pol.length; i++) {
      let polygon = [];
      let flagX = true;
      let yPosition = [];

      for (let j = 0; j < pol[i].length; j++) {
        if (
          nw.lat < pol[i][j][1] ||
          nw.lng > pol[i][j][0] ||
          pol[i][j][0].length > 1
        ) {
          flagX = false; // in case co-ordinates are outside bounds.
          break;
        }
        let elevation;

        if (map) {
          elevation = Math.floor(
            map.queryTerrainElevation([pol[i][j][0], pol[i][j][1]], {
              exaggerated: false,
            })
          );
          elevation = DisplayOperation.getOriginalDimension(
            elevation,
            "meters"
          );

          yPosition.push(elevation);
        }

        let sizeY = terrainGeneration.terrainBoundsInMeters(
          nw.lat,
          pol[i][j][1],
          nw.lng,
          nw.lng
        );

        let sizeX = terrainGeneration.terrainBoundsInMeters(
          nw.lat,
          nw.lat,
          nw.lng,
          pol[i][j][0]
        );

        sizeX = DisplayOperation.getOriginalDimension(sizeX, "meters");
        sizeY = DisplayOperation.getOriginalDimension(sizeY, "meters");
        polygon.push([sizeX, -sizeY]);
      }

      try {
        let avgPosition =
          yPosition.reduce((a, b) => a + b, 0) / yPosition.length; //average of all elevation
        if (flagX) {
          let {building, parameter} = drawPolygon(polygon, props.height, avgPosition, parent);
          building.layerName = _layerName
          buildingStack.push(building)
          parameters.push(parameter)
        }
      } catch (err) {
        console.log("Polygon Empty");
      }
    }
  };

  // initialization
  if (!parameters.length && store.terrainMap) {
    map = store.terrainMap;
    store.neighborhoodBuildings[parent.layerName] = [];
    let featuresBuildings = getBuildingFeatureData(map)

    featuresBuildings.forEach((feature) => {
      if (feature.layer.id === "building") {
        if (feature.geometry) {
          drawBuilding(
            feature.geometry.coordinates,
            feature.properties,
            bounds._nw,
            parent
          );
        }
      }
    });
    createBuilding(buildingStack); // Save building data in backend
  }
  // in undo for deleteLayer or delete Key
  else if (parameters.length > 0) {

    parameters.forEach((parameter, index) => {
      let activeLayer = store.activeLayer;
      let building = drawNeighborhoodMass(
        [parameter.polygon, undefined],
        "Building",
        "neighborhoodBuilding",
        store.neighborhoodBuildings[parent.layerName][index],
        activeLayer.structure_id,
        false,
        parameter.yPosition,
        "01",
        parameter.height,
        parent
      );
      buildingStack.push(building)
    });
    createBuilding(buildingStack); // Save building data in backend
  }

  let buildings = store.neighborhoodBuildings[parent.layerName];
  if (!buildings.length) {
    showToast("No Buildings detected in region....", 3000, TOAST_TYPES.error);
  } else {
    let structure_id = store.activeLayer.structure_id;
    let storey = 1;

    layerView.addAndSelectLayer(_layerName, structure_id, storey, options);

    let layer = store.activeLayer;
    reduxStore.dispatch(
      appendLayer({
        id: layer.id,
        title: layer.name,
        storey: storey,
        hidden: layer.hidden,
        heightMapToggle: layer.heightMapToggle,
        image: NeighborLayer,
      })
    );

    buildings.forEach((buildingID) => {
      store.activeLayer.addNeighborhood(buildingID);
    });
    layer.neighborhood.center = mapData.center;
    layer.neighborhood.bounds = bounds;
    layer.neighborhood.zoom = mapData.zoom;
    layer.neighborhood.parentID = parentId;
    layer.neighborhood.parameters = parameters;
  }
};

const createBuilding = (buildingStack) => {

  let commandData = commandUtils.creationOperations.getCommandData(
    buildingStack,
    null,
    {}
  );

  let command = commandUtils.creationOperations.getCommand(
    "create",
    commandData,
    {}
  );

  CommandManager.execute(command, false);
  CommandManager.popLastItemFromExecuted()
};

const deleteNeighborhood = (neighborhood_layer) => {
  const structureCollection = StructureCollection.getInstance();
  const structure =
    structureCollection.getStructures()[store.activeLayer.structure_id];
  const storeyOfInterest = structure
    .getStoreyData()
    .getStoreyByValue(store.activeLayer.storey);
  if (!neighborhood_layer) {
    neighborhood_layer = storeyOfInterest.layerData.getLayerByName(
      "buildings",
      store.activeLayer.storey
    );
  }
  if (neighborhood_layer) {
    // in case layer is deleted
    let neighborhood = neighborhood_layer.neighborhood.mesh;
    let stack = [];
    neighborhood.forEach((building) => {
      let mesh = store.scene.getMeshByUniqueID(building);
      if (mesh) {
        mesh.setParent("");
        stack.push(mesh);
      }
    });

    let commandData = commandUtils.deletionOperations.getCommandData(
      stack,
      null,
      {}
    );

    let command = commandUtils.deletionOperations.getCommand(
      "delete",
      commandData,
      {}
    );

    let storey = structure.getStoreyData().getStoreyByValue(1);
    let layerID = neighborhood_layer.id;
    storey.layerData.deleteLayer(layerID);
    reduxStore.dispatch(deleteLayer({ layerId: layerID, storeyValue: 1 }));
    setActiveLayerAndRecord(storey.layerData.getLayerByName("wall", 1));
    CommandManager.execute(command, false);
    CommandManager.popLastItemFromExecuted()

    neighborhood.forEach((building) => {
      let mesh = store.scene.getMeshByUniqueID(building);
      if (mesh) {
        removeMeshFromStructure(mesh);
        mesh.dispose();
      }
    });
  }
};

// add neighborhood
const addNeighborhoodToLayer = (
  bounds,
  mapData,
  parent,
  neighborParameters,
  options
) => {
  let _executeEvent = function () {
    let data = {
      storey: 1,
      structure_id: store.activeLayer.structure_id,
      neighborhood: {},
    };

    let cmd = new Command(
      "AddNeighborhoodToLayer",
      data,
      getCommandLogic(),
      getSaveData
    );
    CommandManager.execute(cmd, true);
  };

  function _createNeighborhood() {
    createNeighborhood(bounds, mapData, parent, neighborParameters, options);
    this.data.layer = store.activeLayer;
  }

  function _deleteNeighborhood() {
    deleteNeighborhood();
  }

  let getCommandLogic = function () {
    return {
      execute: _createNeighborhood,
      unexecute: _deleteNeighborhood,
    };
  };

  let getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = this.id;
    saveData.data.saveType = "addNeighborhoodToLayer";
    saveData.data.identifier = {
      structure_id: this.data.structure_id,
      floorkey: store.floorkey,
      storey: this.data.storey.toString(),
      id: this.data.layer.id,
    };

    let dataAfter = {
      neighborhood: this.data.layer.neighborhood,
    };
    saveData.data.afterOperationData = dataAfter;
    return saveData;
  };
  _executeEvent();
};

const deleteNeighborhoodLayer = (layerData, storeyValue) => {
  let _executeEvent = function () {
    let data = {
      storey: 1,
      structure_id: layer.structure_id,
      layer: layer,
      layerId: layer.id,
      storeyData: storey,
    };

    let cmd = new Command(
      "deleteNeighborhoodLayer",
      data,
      getCommandLogic(),
      getSaveData
    );
    CommandManager.execute(cmd, true);
  };

  let _createNeighborhood = function () {
    let neighborhood = this.data.layer.neighborhood;
    createNeighborhood(
      neighborhood.bounds,
      { center: neighborhood.center, zoom: neighborhood.zoom },
      neighborhood.parentID,
      neighborhood.parameters,
      { layerId: this.data.layerId }
    );
  };

  let _deleteNeighborhood = function () {
    deleteNeighborhood(this.data.layer);
  };

  let getCommandLogic = function () {
    return {
      execute: _deleteNeighborhood,
      unexecute: _createNeighborhood,
    };
  };

  let getSaveData = function () {
    let saveData = AutoSave.getSaveDataPrototype();
    saveData.commandId = this.id;
    saveData.data.saveType = "deleteNeighborhoodLayer";
    saveData.data.identifier = {
      structure_id: this.data.structure_id,
      floorkey: store.floorkey,
      storey: this.data.storey.toString(),
      id: this.data.layer.id,
    };

    let dataAfter = { neighborhood: this.data.layer.neighborhood };
    saveData.data.afterOperationData = dataAfter;
    return saveData;
  };

  let layer;
  const structureCollection = StructureCollection.getInstance();
  const structure =
    structureCollection.getStructures()[store.activeLayer.structure_id];
  let storey = structure.getStoreyData().getStoreyByValue(1);
  
  layer = storey.layerData.getLayerByName(layerData.name, 1);
  
  _executeEvent();
};

export {
  createNeighborhood,
  deleteNeighborhood,
  deleteNeighborhoodLayer,
  addNeighborhoodToLayer,
  isNeighborhoodBuilding,
};
