export const ROOM_TYPES = [
  {
      "height": "",
      "name": "Formal Living",
      "value": "Formal Living",
      "room_type": "Living Room"
  },
  {
      "height": "",
      "name": "Family Living",
      "value": "Family Living",
      "room_type": "Living Room"
  },
  {
      "height": "",
      "name": "Common Room",
      "value": "Common Room",
      "room_type": "Living Room"
  },
  {
      "height": "",
      "name": "TV Room",
      "value": "TV Room",
      "room_type": "Living Room"
  },
  {
      "height": "",
      "name": "Home Theatre",
      "value": "Home Theatre",
      "room_type": "Living Room"
  },
  {
      "height": "",
      "name": "Entertainment room",
      "value": "Entertainment room",
      "room_type": "Living Room"
  },
  {
      "height": "",
      "name": "Dining Room",
      "value": "Dining Room",
      "room_type": "Dining Room"
  },
  {
      "height": "",
      "name": "Kitchen",
      "value": "Kitchen",
      "room_type": "Kitchen"
  },
  {
      "height": "",
      "name": "Dry Kitchen",
      "value": "Dry Kitchen",
      "room_type": "Kitchen"
  },
  {
      "height": "",
      "name": "Wet Kitchen",
      "value": "Wet Kitchen",
      "room_type": "Kitchen"
  },
  {
      "height": "",
      "name": "Utility",
      "value": "Utility",
      "room_type": "Kitchen"
  },
  {
      "height": "",
      "name": "Bedroom",
      "value": "Bedroom",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Master Bedroom",
      "value": "Master Bedroom",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Guest Bedroom",
      "value": "Guest Bedroom",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Children's Bedroom",
      "value": "Children's Bedroom",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Hotel Room",
      "value": "Hotel Room",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Nap Room",
      "value": "Nap Room",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Maid's Room",
      "value": "Maid's Room",
      "room_type": "Bedroom"
  },
  {
      "height": "",
      "name": "Bathroom",
      "value": "Bathroom",
      "room_type": "Bathroom"
  },
  {
      "height": "",
      "name": "Toilet",
      "value": "Toilet",
      "room_type": "Bathroom"
  },
  {
      "height": "",
      "name": "Powder room",
      "value": "Powder room",
      "room_type": "Bathroom"
  },
  {
      "height": "",
      "name": "Maid's Toilet",
      "value": "Maid's Toilet",
      "room_type": "Bathroom"
  },
  {
      "height": "1",
      "name": "Balcony",
      "value": "Balcony",
      "room_type": "Balcony"
  },
  {
      "height": "1",
      "name": "Terrace",
      "value": "Terrace",
      "room_type": "Balcony"
  },
  {
      "height": "1",
      "name": "Refuge Terrace",
      "value": "Refuge Terrace",
      "room_type": "Balcony"
  },
  {
      "height": "1",
      "name": "Corridor (Open)",
      "value": "Corridor (Open)",
      "room_type": "Balcony"
  },
  {
      "height": "0.15",
      "name": "Deck",
      "value": "Deck",
      "room_type": "Deck"
  },
  {
      "height": "0.15",
      "name": "Verandah",
      "value": "Verandah",
      "room_type": "Deck"
  },
  {
      "height": "0.15",
      "name": "Patio",
      "value": "Patio",
      "room_type": "Deck"
  },
  {
      "height": "0.15",
      "name": "Corridor (Open - ground)",
      "value": "Corridor (Open - ground)",
      "room_type": "Deck"
  },
  {
      "height": "",
      "name": "Home Office",
      "value": "Home Office",
      "room_type": "Office Cabin"
  },
  {
      "height": "",
      "name": "Private Office",
      "value": "Private Office",
      "room_type": "Office Cabin"
  },
  {
      "height": "",
      "name": "Cabin",
      "value": "Cabin",
      "room_type": "Office Cabin"
  },
  {
      "height": "0.001",
      "name": "Site",
      "value": "Site",
      "room_type": "Site"
  },
  {
      "height": "0.1",
      "name": "Ground",
      "value": "Ground",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Green area",
      "value": "Green area",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Park",
      "value": "Park",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Court",
      "value": "Court",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Courtyard",
      "value": "Courtyard",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Lawn",
      "value": "Lawn",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Sports",
      "value": "Sports",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Football field",
      "value": "Football field",
      "room_type": "Ground"
  },
  {
      "height": "",
      "name": "Living Room",
      "value": "Living Room",
      "room_type": "Living Room"
  },
  {
      "height": "-0.5",
      "name": "Water Body",
      "value": "Water Body",
      "room_type": "Water Body"
  },
  {
      "height": "0.1",
      "name": "Tennis Court",
      "value": "Tennis Court",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Basketball Court",
      "value": "Basketball Court",
      "room_type": "Ground"
  },
  {
      "height": "0.001",
      "name": "Vacant Plot",
      "value": "Vacant Plot",
      "room_type": "Site"
  },
  {
      "height": "",
      "name": "Workspace",
      "value": "Workspace",
      "room_type": "Workspace"
  },
  {
      "height": "",
      "name": "Open Office",
      "value": "Open Office",
      "room_type": "Workspace"
  },
  {
      "height": "",
      "name": "Workstations",
      "value": "Workstations",
      "room_type": "Workspace"
  },
  {
      "height": "",
      "name": "Conference Room",
      "value": "Conference Room",
      "room_type": "Conference room"
  },
  {
      "height": "",
      "name": "War Room",
      "value": "War Room",
      "room_type": "Conference room"
  },
  {
      "height": "",
      "name": "Board Room",
      "value": "Board Room",
      "room_type": "Conference room"
  },
  {
      "height": "",
      "name": "Meeting Room",
      "value": "Meeting Room",
      "room_type": "Conference room"
  },
  {
      "height": "",
      "name": "Foyer",
      "value": "Foyer",
      "room_type": "Foyer"
  },
  {
      "height": "",
      "name": "Reception",
      "value": "Reception",
      "room_type": "Foyer"
  },
  {
      "height": "",
      "name": "Lobby",
      "value": "Lobby",
      "room_type": "Foyer"
  },
  {
      "height": "",
      "name": "Lift Lobby",
      "value": "Lift Lobby",
      "room_type": "Foyer"
  },
  {
      "height": "",
      "name": "Entrance Lobby",
      "value": "Entrance Lobby",
      "room_type": "Foyer"
  },
  {
      "height": "",
      "name": "Corridor",
      "value": "Corridor",
      "room_type": "Corridor"
  },
  {
      "height": "",
      "name": "Passage",
      "value": "Passage",
      "room_type": "Corridor"
  },
  {
      "height": "0.1",
      "name": "Road",
      "value": "Road",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Main Road",
      "value": "Main Road",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Access Road",
      "value": "Access Road",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Service Road",
      "value": "Service Road",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Highway",
      "value": "Highway",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Secondary Road",
      "value": "Secondary Road",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Primary Road",
      "value": "Primary Road",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Walkway",
      "value": "Walkway",
      "room_type": "Footpath"
  },
  {
      "height": "0.1",
      "name": "Footpath",
      "value": "Footpath",
      "room_type": "Footpath"
  },
  {
      "height": "-0.5",
      "name": "Water Body",
      "value": "Water Body",
      "room_type": "Water Body"
  },
  {
      "height": "-0.5",
      "name": "Pond",
      "value": "Pond",
      "room_type": "Water Body"
  },
  {
      "height": "-0.5",
      "name": "Lake",
      "value": "Lake",
      "room_type": "Water Body"
  },
  {
      "height": "-0.5",
      "name": "River",
      "value": "River",
      "room_type": "Water Body"
  },
  {
      "height": "-0.5",
      "name": "Swimming Pool",
      "value": "Swimming Pool",
      "room_type": "Pool"
  },
  {
      "height": "-0.5",
      "name": "Water Stream",
      "value": "Water Stream",
      "room_type": "Water Body"
  },
  {
      "height": "-0.5",
      "name": "Pool",
      "value": "Pool",
      "room_type": "Pool"
  },
  {
      "height": "",
      "name": "Club",
      "value": "Club",
      "room_type": "Club"
  },
  {
      "height": "",
      "name": "Clubhouse",
      "value": "Clubhouse",
      "room_type": "Club"
  },
  {
      "height": "",
      "name": "Lift",
      "value": "Lift",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Elevator",
      "value": "Elevator",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Shaft",
      "value": "Shaft",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Void",
      "value": "Void",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Cutout",
      "value": "Cutout",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Stairwell",
      "value": "Stairwell",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Staircase",
      "value": "Staircase",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Courtyard Below",
      "value": "Courtyard Below",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Service Cutout",
      "value": "Service Cutout",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "MEP Cutout",
      "value": "MEP Cutout",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "HVAC Cutout",
      "value": "HVAC Cutout",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Communications Shaft",
      "value": "Communications Shaft",
      "room_type": "Lift"
  },
  {
      "height": "",
      "name": "Corridor",
      "value": "Corridor",
      "room_type": "Foyer"
  },
  {
      "height": "",
      "name": "Commercial",
      "value": "Commercial",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Residential",
      "value": "Residential",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Hotel",
      "value": "Hotel",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Hospitality",
      "value": "Hospitality",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Education",
      "value": "Education",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "School",
      "value": "School",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Retail",
      "value": "Retail",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Public",
      "value": "Public",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Industrial",
      "value": "Industrial",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Mixed-Use",
      "value": "Mixed-Use",
      "room_type": "Typology"
  },
  {
      "height": "",
      "name": "Office Block",
      "value": "Office Block",
      "room_type": "Typology"
  },
  {
      "height": "0.001",
      "name": "Boundary",
      "value": "Boundary",
      "room_type": "Site"
  },
  {
      "height": "0.001",
      "name": "Plot",
      "value": "Plot",
      "room_type": "Site"
  },
  {
      "height": "0.1",
      "name": "Pathway",
      "value": "Pathway",
      "room_type": "Footpath"
  },
  {
      "height": "0.1",
      "name": "Pavement",
      "value": "Pavement",
      "room_type": "Footpath"
  },
  {
      "height": "0.1",
      "name": "Parking",
      "value": "Parking",
      "room_type": "Road"
  },
  {
      "height": "0.1",
      "name": "Pavement",
      "value": "Pavement",
      "room_type": "Footpath"
  },
  {
      "height": "0.1",
      "name": "Kitchen garden",
      "value": "Kitchen garden",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Garden",
      "value": "Garden",
      "room_type": "Ground"
  },
  {
      "height": "0.1",
      "name": "Plaza",
      "value": "Plaza",
      "room_type": "Footpath"
  },
  {
      "height": "0.1",
      "name": "Atrium",
      "value": "Atrium",
      "room_type": "Footpath"
  }
]