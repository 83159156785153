import React, {useRef, useState} from "react";
import MenuPopUp from "../../../../components/MenuPopUp/index";
import ScrollViewCommon from "../../../../components/rightMenus/scrollViewCommon";
import { RIGHT_SUB_MENU_ICONS } from "../../Icons";
import {
  onDeleteMaterial,
  onMatMode,
  onPickMaterialFromScene,
} from "../../../../snaptrude/libs/toolbarEvents";
import * as constants from "../../../../components/MenuPopUp/constants";
import {useDispatch, useSelector} from "react-redux";
import MaterialEdit from "../../../../components/rightMenus/materialEdit";
import {
  setMenuMode,
  updateMaterialApplicationValues
} from "../../../../snaptrude/stateManagers/reducers/objectProperties/materialsSlice";
import MaterialColor from "../../../../components/rightMenus/materialColor";
import {
  MATERIAL_EDIT_MENU,
  MATERIAL_MENU,
  MATERIAL_OVERLAY_HEADER,
  MATERIAL_OVERLAY_MENU
} from "../../../../components/rightMenus/constants";
import editMaterial from "../../../../snaptrude/libs/materialEditUI";
import {store} from "../../../../snaptrude/modules/utilityFunctions/Store";
import _ from "lodash";
import {formatSubType} from "../../../../services/projects.service";
import {handleGetResourceOperations} from "../../../../services/resources.service";
import { showToast, TOAST_TYPES } from "../../../../snaptrude/modules/extrafunc";
import { removeTemporaryCursor } from "../../cursorHandler";

const MaterialPopUps = (props) => {
  const { closePopUp, resetSelectedTopSubMenu, style, isOpen, selectedTopMenu, activateEyeDropper } = props;
  
  const whichMenu = useSelector(state => state.materials.whichMenu);
  const dispatch = useDispatch();
  
  const [materialByFace, setMaterialByFace] = useState(store.$scope.applyMaterialProperties.materialByFace);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState({
    imgURL: null,
    type: null,
    name: null
  });
  
  const [thumbnailImageItems, setThumbnailImageItems] = useState([]);
  const [importedObjects, setImportedObjects] = useState([]);

  const scrollViewRef = useRef(null);
  const leftMenuItems = [
    { title: "Colors" },
    { title: "Concrete" },
    { title: "Glass" },
    { title: "Metal" },
    { title: "Tiles" },
    { title: "Stone" },
    { title: "Wood" },
    { title: "Groundcover" },
    { title: "Brick" },
  ];
  
  leftMenuItems.forEach(item => {
    item.key = formatSubType(item.title);
  });
  
  const topSubMenuOptions = [];

  const handleThumbnailItemClick = ({img, type, fullName, reset}) => {
    removeTemporaryCursor();
    if (reset) {
      setSelectedMaterial({
        imgURL: null,
        type: null,
        name: null
      });
      store.$scope.applyMaterialProperties.imgURL = null;
      store.$scope.applyMaterialProperties.type = null;
      store.$scope.applyMaterialProperties.name = null;
      return;
    }
    resetSelectedTopSubMenu();
    dispatch(setMenuMode(MATERIAL_MENU));
    const menuIndex = typeOptions.findIndex(item => item === type);
    store.isEyeDropperSelection = true;
    setSelectedMenuIndex(menuIndex);
    const options = {
      resourceName: "materials",
      floorkey: store.floorkey,
      filterType: "material",
      filterSubType: type,
    }
    setSelectedSubMenu("");
    setSelectedMaterial({
      imgURL : img,
      type : type,
      name : fullName
    });
    handleGetResourceOperations(options)
    .then(result => {
      scrollViewRef.current.scrollToView({imgURL: img, type, thumbnailItems: result});
    });
    store.$scope.applyMaterialProperties.imgURL = img;
    store.$scope.applyMaterialProperties.type = type;
    store.$scope.applyMaterialProperties.name = fullName
    onMatMode();
  }

  topSubMenuOptions.push({
    icon : RIGHT_SUB_MENU_ICONS.importMaterial,
    callback : null,
    name : "importMaterial",
    tooltipText: "Add material",
  });
  
  topSubMenuOptions.push({
    icon : RIGHT_SUB_MENU_ICONS.removeMaterial,
    callback : onDeleteMaterial,
    name : "removeMaterial",
    tooltipText: "Remove material",
  });
  
  topSubMenuOptions.push({
    icon : RIGHT_SUB_MENU_ICONS.eyeDropperForMaterial,
    callback : onPickMaterialFromScene.bind(this, handleThumbnailItemClick),
    name : "eyeDropperForMaterial",
    tooltipText: "Eyedropper",
  });
  
  if (selectedMaterial.imgURL){
    topSubMenuOptions.push({
      icon : RIGHT_SUB_MENU_ICONS.editMaterial,
      callback : () => editMaterial.onMaterialEdit(selectedMaterial),
      name : "editMaterial",
      oneTimeOperation : true, // false when a button attached event listeners, like remove material
      tooltipText: "Edit material",
  });
  
    topSubMenuOptions.push({
      icon : RIGHT_SUB_MENU_ICONS.cloneMaterial,
      callback : () => {
        editMaterial
          .cloneMaterial(selectedMaterial)
          .then((newMaterials) => {
            setThumbnailImageItems([...thumbnailImageItems, ...newMaterials]);
            const evt = new CustomEvent("material-copied", {
              detail: {
                resourceName: "material",
                newMaterials: newMaterials,
              }
            })
            window.dispatchEvent(evt);
            showToast("Material copied", 3000, TOAST_TYPES.success);
          }).catch(e => {
            console.log(e);
            console.log("Clone material failed");
          });
      },
      name : "cloneMaterial",
      tooltipText: "Copy material",
      oneTimeOperation : true
    });
  }
  
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  
  const typeOptions = leftMenuItems.map(item => item.title);
  const toggleMaterialByFace = function (){
    setMaterialByFace(!materialByFace);
    store.$scope.applyMaterialProperties.materialByFace = !materialByFace;
  };

  return whichMenu === MATERIAL_MENU ? (
    <MenuPopUp
      leftMenuItems={leftMenuItems}
      selectedIndex={selectedMenuIndex}
      setSelectedIndex={setSelectedMenuIndex}
      showToggleIcon={true}
      toggleOptions={["Object", "Face"]}
      toggleTooltipText={"Apply by "}
      toggleState={materialByFace}
      handleToggle={toggleMaterialByFace}
      hasleftMenu={true}
      hasTopIcons={true}
      topSubMenuOptions={topSubMenuOptions}
      title={"Materials"}
      style={style}
      isOpen={isOpen}
      child={() => {
        return (
          <ScrollViewCommon
            ref={scrollViewRef}
            noBorder={true}
            filterType="material"
            filterSubType={leftMenuItems[selectedMenuIndex].key}
            selectedThumbnail={selectedMaterial}
            setSelectedThumbnail={setSelectedMaterial}
            setSelectedSubMenu={setSelectedSubMenu}
            thumbnailImageItems={thumbnailImageItems}
            setThumbnailImageItems={setThumbnailImageItems}
            resetSelectedTopSubMenu={resetSelectedTopSubMenu}
            maintainAspectRatio={false}
            isOpen={isOpen}
            selectedTopMenu={selectedTopMenu}
            activateEyeDropper={activateEyeDropper}
            importedObjects={importedObjects}

          />
        );
      }}
      noBorder={true}
      closePopUp={closePopUp}
      typeOptions={typeOptions}
      setSelectedThumbnail={setSelectedMaterial}
      selectedSubMenu={selectedSubMenu}
      setSelectedSubMenu={setSelectedSubMenu}
      thumbnailImageItems={thumbnailImageItems}
      setThumbnailImageItems={setThumbnailImageItems}
      setImportedObjects={setImportedObjects}
      disableAdd={true}
    />
  ) : whichMenu === MATERIAL_EDIT_MENU ? (
    <MaterialEdit
      closePopUp={() => dispatch(setMenuMode(MATERIAL_MENU))}
      setSelectedSubMenu={setSelectedSubMenu}
    />
  ) : whichMenu === MATERIAL_OVERLAY_MENU ? (
    <MaterialColor
      setModalOpen={() =>{}}
    />
  ) : (
    <div />
  );
};

export default MaterialPopUps;
