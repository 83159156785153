export class FakeProgressEvent{
    constructor(from, to, text, durationInSec, uniqueName, callback, callbackOptions){
        this.from = from;
        this.to = to;
        this.text = text;
        this.uniqueName = uniqueName;
        this.currentProgress = this.from;
        this.progressPerSec = (to-from)/durationInSec;
        this.interval = null;
        this.callback = callback;
        this.callbackOptions = callbackOptions;
    }
    static allEvents = [];
    static find = (uniqueName) => {
        return FakeProgressEvent.allEvents.find(evt => evt.uniqueName === uniqueName);
    }
    start = () => {
        FakeProgressEvent.allEvents.push(this);
        this.interval = setInterval(() => {
            this.currentProgress += this.progressPerSec;
            if(this.callback) this.callback(this.currentProgress, this.text, this.callbackOptions);
            if(this.currentProgress >= this.to) {
                if(this.interval) this.stop();
            }
        }, 1000);
    }
    stop = () => {
        FakeProgressEvent.allEvents = FakeProgressEvent.allEvents.filter(evt => evt !== this);
        clearInterval(this.interval);
    }
}