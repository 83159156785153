import styled from "styled-components";
import { colors } from "../../themes/constant";

export const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  z-index: 10;
  overflow: auto;
  padding: 2em 0em 0.25em 2.5em;
`;

export const Header = styled.header`
    padding: 10px;
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: -0.333333px;
    color: rgba(45,45,46, 0.8);
    z-index: 20;
    justify-content: space-between;
    padding-right: 2em;
`
export const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(96, 129, 159, 0.2);
`
export const Content = styled.div`
    padding: 10px;
    flex: 1;
    height: 100%;
    overflow: hidden;
    padding-right: 2em;
`

export const ContentStyle = {
    flex: 1,
    padding: "10px",
    paddingTop: "0px",
    paddingRight: "2em",
}

export const Footer = styled.footer`
    // border: 2px solid cadetblue;
    padding: 10px;
    display: flex;
    & button{
        width: 200px;
        margin-right: 15px;
    }
`

export const Title = styled.div`
    // border: 2px solid;
    flex: 1;
    font-weight: 700;
    font-size: 24px;
    color: rgba(45,45,46, 0.8);
    .selected{
        text-align: left;
    }
`
export const Controls = styled.div`
    // border: 2px solid;
    display: flex;
    align-items: center;
`
export const NavItem = styled.div`
    // border: 2px solid;
    cursor: pointer;
    padding: 10px 10px;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    position: relative;
    text-transform: capitalize;
    color: ${(props) => props.isActive ? colors.darkGrey : colors.thinGray};
    &::after{
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: ${colors.borderRed};
        visibility: ${props => props.isActive ? "visible" : "hidden"};
    }
`
