import BABYLON from "../modules/babylonDS.module.js";
import _ from "lodash";
import { store } from "../modules/utilityFunctions/Store.js";
import { appElement } from "./bimDataFuncs.js";
import {
  nonDefaultMeshForSnapping,
  nonDefaultMesh,
  updateModifications,
} from "./sceneStateFuncs.js";
import { isPickedfaceObject } from "./defaultEvents.js";
import { getFaceIdFromFacet, copyBrep } from "./brepOperations.js";
import {
  applyMaterialByBREP,
  applyMaterialByFace,
  createMaterialFromImage,
} from "./mats.js";
import { Command } from "../modules/commandManager/Command.js";
import { CommandManager } from "../modules/commandManager/CommandManager.js";
import { AutoSave } from "../modules/socket/autoSave.js";
import MATERIAL_STRUCTURE from "../modules/materialStructure/materialStructure";
import editMaterial from "./materialEditUI.js";
import reduxStore from "../stateManagers/store/reduxStore";
import { setIsModifiedAndGetCommand } from "../modules/extrafunc.js";
import { applyMaterialToFlippedGeometry } from "../modules/speckleRevitImport/extraFunctions.js";
import { scenePickController } from "../modules/utilityFunctions/scenePickController.js";
var applyMaterialCommandName = "applyMaterial";
var onMatPointerDown = function (evt) {
  /* AG-RE: ANGULAR REFERENCE */
  // var $scope = store.angular.element(appElement).scope();
  // $scope = $scope.$$childHead;

  if (evt.button !== 0) {
    return;
  }

  const applyMaterialProperties = store.$scope.applyMaterialProperties;
  const selectedMaterial = applyMaterialProperties.imgURL;
  const selectedMaterialType = applyMaterialProperties.type;
  const materialName = applyMaterialProperties.name;
  const materialByFace = applyMaterialProperties.materialByFace;

  // check if we are under a mesh
  // var pickInfo = store.newScene.pick(
  //   store.newScene.pointerX,
  //   store.newScene.pointerY,
  //   function (mesh) {
  //     // return mesh !== store.scene.getMeshByName('ground1');
  //     return nonDefaultMeshForSnapping(mesh);
  //   }
  // );
  var pickInfo = scenePickController.pick();
  if (pickInfo.hit) {
    let applyMaterialCommandData = {};
    let commandDataExist = false;

    if (pickInfo.pickedMesh.isPickable && pickInfo.pickedMesh.type) {
      if (pickInfo.pickedMesh.name.indexOf("boxScale") === -1 && !pickInfo.pickedMesh.name.includes("terrain")) {
        if (nonDefaultMesh(pickInfo.pickedMesh)) {
          if (materialByFace) {
            if (isPickedfaceObject(pickInfo)) {
              let pickedMesh = pickInfo.pickedMesh;
              let meshType = pickedMesh.type.toLowerCase();
              if (pickedMesh.isAnInstance) pickedMesh = pickedMesh.sourceMesh;

              let object = pickedMesh.getSnaptrudeDS();
              if (
                ["wall", "floor", "roof", "mass", "staircase", "door", "window", "furniture"].includes(
                  meshType
                )
              ) {
                if (object) {
                  applyMaterialCommandData._prevData = prepareDataForCMD(
                    pickedMesh,
                    pickInfo.faceId
                  );
                  if (object.brep) {
                    applyMaterialCommandData._prevData = prepareDataForCMD(
                      pickedMesh,
                      pickInfo.faceId
                    );
                    if (object.type.toLowerCase() === "staircase") {
                      object.applyMaterial(
                        pickInfo,
                        selectedMaterial,
                        selectedMaterialType
                      );
                    } else {
                      let facetId = pickInfo.faceId,
                        faceId = getFaceIdFromFacet(facetId, pickedMesh);
                      applyMaterialByBREP(
                        pickedMesh,
                        faceId,
                        selectedMaterial,
                        selectedMaterialType
                      );
                    }
                  } else {
                    applyMaterialByFace(
                      pickInfo,
                      selectedMaterial,
                      selectedMaterialType
                    );
                  }
                  pickedMesh.synchronizeInstances();
                  applyMaterialCommandData._newData = prepareDataForCMD(
                    pickedMesh,
                    pickInfo.faceId
                  );
                  commandDataExist = true;
                }
              }
            }
          } else {
            // TODO: Add user-input for name of material in UI
            let newMaterial = false;
            if (
              ["wall", "mass", "floor", "roof", "staircase", "door", "window", "furniture"].includes(
                pickInfo.pickedMesh.type.toLowerCase()
              )
            ) {
              var mat_name = selectedMaterial
                .replace(/^.*[\\/]/, "")
                .split(".")[0];
              /* eslint-enable */
              let temp_mat = store.scene.getMaterialByName(materialName);
              if (!temp_mat) {
                temp_mat = createMaterialFromImage(
                  selectedMaterial,
                  selectedMaterialType,
                  materialName
                );
                newMaterial = true;
              }
              let _meshOfInterest;
              let mat_index;

              if (pickInfo.pickedMesh.isAnInstance)
                _meshOfInterest = pickInfo.pickedMesh.sourceMesh;
              else _meshOfInterest = pickInfo.pickedMesh;

              if (_meshOfInterest.material.subMaterials) {
                var multimatSubs = _meshOfInterest.material.subMaterials;
                var mat_exist_flag = false;
                var mat_exist_index = -1;
                for (
                  var p = 0;
                  p < _meshOfInterest.material.subMaterials.length;
                  p++
                ) {
                  if (
                    _meshOfInterest.material.subMaterials[p].id === temp_mat.id
                  ) {
                    mat_exist_flag = true;
                    mat_exist_index = p;
                  }
                }
                if (!mat_exist_flag) {
                  _meshOfInterest.material.subMaterials.push(temp_mat);

                  mat_index = multimatSubs.length - 1;
                } else {
                  mat_index = mat_exist_index;
                }
              } else {
                let id = Math.random().toString(36).substr(2, 6) + "_";
                var multimat = new BABYLON.MultiMaterial(
                  temp_mat.name + id,
                  store.scene
                );
                multimat.subMaterials.push(_meshOfInterest.material);
                multimat.subMaterials.push(temp_mat);
                _meshOfInterest.material = multimat;
                mat_index = multimat.subMaterials.length - 1;
              }

              if (_meshOfInterest.getSnaptrudeDS()) {
                let object = _meshOfInterest.getSnaptrudeDS();
                applyMaterialCommandData._prevData =
                  prepareDataForCMD(_meshOfInterest);
                _meshOfInterest.subMeshes.forEach(function (subMesh) {
                  subMesh.materialIndex = mat_index;
                });
                if (object.brep) {
                  let faces = object.brep.getFaces();
                  faces.forEach(function (face) {
                    face.materialIndex = mat_index;
                  });
                  updateModifications();
                }
                addMaterialToLayers(_meshOfInterest, mat_index);
                applyMaterialCommandData._newData = prepareDataForCMD(_meshOfInterest);
                commandDataExist = true;
                if (newMaterial) {
                  saveMaterialInBackend(temp_mat);
                }
              }
              _meshOfInterest.synchronizeInstances();
            }
          }
        }
      }
    }
    let _executeEvent = function () {

      let object = pickInfo.pickedMesh.getSnaptrudeDS();
      const isRevitImport = object?.revitMetaData?.elementId ? true : false;
      let cmdForMaterialForFlippedGeometry;
      if(isRevitImport){
        cmdForMaterialForFlippedGeometry = applyMaterialToFlippedGeometry(object, pickInfo.subMeshId, materialName, materialByFace, selectedMaterial)
      }
      
      let setIsModifiedCmd = setIsModifiedAndGetCommand([pickInfo.pickedMesh]);

      let cmd = new Command(
        applyMaterialCommandName,
        applyMaterialCommandData,
        getMaterialCommandLogic(),
        getApplyMaterialSaveData
      );
      let cmds = [cmd,setIsModifiedCmd ]
      if(cmdForMaterialForFlippedGeometry){
        cmds.push(cmdForMaterialForFlippedGeometry)
      }
      CommandManager.execute(_.compact(cmds), false);
    };
    if (commandDataExist) {
      _executeEvent();
      commandDataExist = false;
    }
  }
};

var onMatPointerUp = function (evt) {};

var onMatPointerMove = function (evt) {};

var prepareDataForCMD = function (meshOfInterest, facetID) {
  let mesh;
  if (meshOfInterest.isAnInstance) mesh = meshOfInterest.sourceMesh;
  else mesh = meshOfInterest;

  let object = mesh.getSnaptrudeDS();

  let subMeshes = Object.assign([], mesh.subMeshes);

  let data = {};
  data.meshId = mesh.uniqueId;
  data.materialID = mesh.material.id;
  data.components = object.properties._components;

  if (object.brep) {
    let faces = object.brep.getFaces();
    data.materialIndices = faces.map((f) => f.materialIndex);
    data.facetId = facetID;
    data.brep = copyBrep(object.brep);
  }

  data.subMeshes = [];

  subMeshes.forEach(function (subMesh) {
    let subMeshMetaData = {};
    subMeshMetaData.materialIndex = subMesh.materialIndex;
    subMeshMetaData.verticesStart = subMesh.verticesStart;
    subMeshMetaData.verticesCount = subMesh.verticesCount;
    subMeshMetaData.indexStart = subMesh.indexStart;
    subMeshMetaData.indexCount = subMesh.indexCount;
    data.subMeshes.push(subMeshMetaData);
  });

  return data;
};

let getMaterialCommandLogic = function () {
  return {
    execute: applyMaterial,
    unexecute: removeMaterial,
  };
};

let getApplyMaterialSaveData = function () {
  let data = this.data;
  let saveData = AutoSave.getSaveDataPrototype();

  saveData.commandId = this.id;
  saveData.data.saveType = "applyMaterial";

  let dataBefore = {};
  let dataAfter = {};

  let mesh = store.scene.getMeshByUniqueID(data._prevData.meshId);
  let material, serializedMaterial;
  material = store.scene.getMaterialByID(data._prevData.materialID);
  if (!material) {
    material = store.scene.getMultiMaterialByID(data._prevData.materialID);
  }
  serializedMaterial = material.serialize();
  dataBefore.brep = store.resurrect.stringify(data._prevData.brep);
  dataBefore.material = serializedMaterial;
  dataBefore.subMeshes = data._prevData.subMeshes;
  dataBefore.components = data._prevData.components;
  dataBefore.materialIndices = data._prevData.materialIndices;
  dataAfter.brep = store.resurrect.stringify(data._newData.brep);
  material = store.scene.getMaterialByID(data._newData.materialID);
  if (!material) {
    material = store.scene.getMultiMaterialByID(data._newData.materialID);
  }
  serializedMaterial = material.serialize();
  dataAfter.material = serializedMaterial;
  dataAfter.subMeshes = data._newData.subMeshes;
  dataAfter.components = data._newData.components;
  dataAfter.materialIndices = data._newData.materialIndices;

  let identifierMeshDS;
  if (mesh.isAnInstance) {
    identifierMeshDS = mesh.sourceMesh.getSnaptrudeDS();
  } else {
    identifierMeshDS = mesh.getSnaptrudeDS();
  }

  saveData.data.identifier = AutoSave.getComponentIdentifier(identifierMeshDS);
  saveData.data.afterOperationData = dataAfter;
  saveData.data.beforeOperationData = dataBefore;

  return saveData;
};


var applyMaterial = function () {
  let data = this.data._newData;
  recreateSubMeshes(data);
};

var removeMaterial = function () {
  let data = this.data._prevData;
  recreateSubMeshes(data);
};

var recreateSubMeshes = function (data) {
  let mesh = store.newScene.getMeshByUniqueID(data.meshId);

  if (mesh.material.id !== data.materialID) {
    let material = store.scene.getMultiMaterialByID(data.materialID);
    if (!material) material = store.scene.getMaterialByID(data.materialID);
    if (material) mesh.material = material;
  }

  if (mesh.getSnaptrudeDS()) {
    let object = mesh.getSnaptrudeDS();

    if (object.brep) {
      let faces = object.brep.getFaces();
      let faceFacetMapping = object.faceFacetMapping;

      for (let i = 0; i < faces.length; i++) {
        faces[i].materialIndex = data.materialIndices[i];
      }
      // updateSubMeshes(mesh, faces, faceFacetMapping);
    }

    mesh.subMeshes.length = 0;
    let subMeshesMetaData = data.subMeshes;
    subMeshesMetaData.forEach(function (subMesh) {
      BABYLON.SubMesh.AddToMesh(
        subMesh.materialIndex,
        subMesh.verticesStart,
        subMesh.verticesCount,
        subMesh.indexStart,
        subMesh.indexCount,
        mesh
      );
    });
    if (object?.properties?._components && data.components) {
      object.properties._components = data.components;
    }
  }

  mesh.synchronizeInstances();
};

function serializeMaterial(material, multiMaterial) {
  let returnObject = {
    multiMaterial,
    subMaterials: [],
  };

  if (multiMaterial) {
    returnObject.multiMaterial = material.serialize();
    material.subMaterials.forEach((m) => {
      let mat = m.serialize();
      mat.materialType = m.materialType;
      mat.inStatic = m.inStatic;
      returnObject.subMaterials.push(mat);
    });

    _.compact(returnObject.subMaterials);
  } else if (material.id !== "colorShader" && material.id !== "lineShader") {
    returnObject.subMaterials[0] = material.serialize();
    returnObject.subMaterials[0].materialType = material.materialType;
    returnObject.subMaterials[0].inStatic = material.inStatic;
  }

  return returnObject;
}

function saveMaterialInBackend(material) {
  let multiMaterial = false;
  if (material.subMaterials) multiMaterial = true;

  let saveData = AutoSave.getSaveDataPrototype();
  saveData.commandId = material.id;
  saveData.data.saveType = "addMaterial";
  saveData.data.identifier = {
    structure_id: store.activeLayer.structure_id,
    floorkey: store.floorkey,
  };

  let serializedMaterial = serializeMaterial(material, multiMaterial);
  if (_.isEmpty(serializedMaterial.subMaterials)) return;

  saveData.data.afterOperationData = serializedMaterial;
  AutoSave.directPublish(saveData);
}

function deleteMaterialInBackend(material){
  // let multiMaterial = false;
  // if (material.subMaterials) multiMaterial = true;

  let saveData = AutoSave.getSaveDataPrototype();
  saveData.commandId = material.id;
  saveData.data.saveType = "deleteFloorPlanMaterial";
  saveData.data.identifier = {
    structure_id: store.activeLayer.structure_id,
    floorkey: store.floorkey,
    materialId: material.id
  };

  // saveData.data.afterOperationData = serializedMaterial;
  AutoSave.directPublish(saveData);
}

var onMatEditPointerDown = function (evt) {
  var pickInfo = store.newScene.pick(
    store.newScene.pointerX,
    store.newScene.pointerY,
    function (mesh) {
      return nonDefaultMeshForSnapping(mesh);
    }
  );
  // if (pickInfo.pickedMesh.type === 'wall' || pickInfo.pickedMesh.type === 'Mass') {
  //
  //     if (pickInfo.pickedMesh.subMeshes[pickInfo.subMeshId]._currentMaterial){
  //         let subMesh = pickInfo.pickedMesh.subMeshes[pickInfo.subMeshId];
  //         onMatEditMenu(subMesh, 'subMesh');
  //     }
  //     else {
  //         onMatEditMenu(pickInfo.pickedMesh, 'mesh')
  //     }
  // }
  if (pickInfo.hit) {
    if (
      [
        "wall",
        "mass",
        "floor",
        "door",
        "window",
        "furniture",
        "roof",
        "staircase",
      ].includes(pickInfo.pickedMesh.type.toLowerCase())
    ) {
      // openMaterialEditModal();
      editMaterial.pickMaterialFromScene(pickInfo);
    }
  }
};

var onMatEditPointerUp = function (evt) {};

var onMatEditPointerMove = function (evt) {};

var addMaterialToLayers = function (mesh, mat_index, options = {}) {
  function  updateLayers(object, material) {
    const type = object.type.charAt(0).toUpperCase() + object.type.slice(1);
    const props = MATERIAL_STRUCTURE[type][material.materialType + type];
    if (props) {
      let newComps = JSON.parse(
        JSON.stringify(props)
      );
      object.properties._components = JSON.parse(
        JSON.stringify(object.properties._components)
      );
      let n = newComps._name.match(/[A-Z][a-z]+/g);
      let name = "";
      n.forEach((n1) => {
        name += " ";
        name += n1;
      });
      object.properties._components._name = name;
      object.properties._components._layers = JSON.parse(
        JSON.stringify(newComps._layers)
      );
      object.properties._components._layers.forEach(function (layer) {
        if (layer.transparency) {
          material.alpha = layer.transparency;
        }
      });
    }
  }

  try {
    if (mesh.material.subMaterials) {
      let material = mesh.material.subMaterials[mat_index];
      let object = mesh.getSnaptrudeDS();
      let layers;
      let oldThickness = 200;
      try {
        if (object) {
          if (object.properties) {
            if (object.properties._components._layers) {
              const objectType = object.type.charAt(0).toUpperCase() + object.type.slice(1);
              layers = object.properties._components._layers;
              layers.forEach(function (layer) {
                if (layer.core) {
                  let oldLayers = MATERIAL_STRUCTURE[objectType][layer.value + objectType]._layers;
                  oldLayers.forEach(function (oldLayer) {
                    if (oldLayer.core) {
                      oldThickness = oldLayer.thickness;
                    }
                  });
                }
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      let curThickness;
      try {
        curThickness = object.setThickness();
      } catch (err) {
        console.log(err);
      }
      let updateThickness = true;
      if (curThickness !== oldThickness) updateThickness = false;
      if (material && layers && !options.revitImport) {
        updateLayers(object, material);
        object.properties._components._layers.forEach(function (layer) {
          if (material.materialType === "Colors") {
            if (layer.value === "Paint") {
              layer.subtype = material.name;
            }
          }
          if (layer.value === material.materialType) {
            layer.subtype = material.name;
          } else if (
            material.materialType.includes(layer.value) ||
            layer.value.includes(material.materialType)
          ) {
            layer.subtype = material.name;
          }
        });

        // let newComps = JSON.parse(JSON.stringify(object.properties._components));
        // let maxThickness = layers[0].thickness;
        // let maxThicknessLayerIndex = 0;
        // for (let i=1; i<layers.length; i++){
        //     if (layers[i].thickness > maxThickness){
        //         maxThicknessLayerIndex = i;
        //         maxThickness = layers[i].thickness;
        //     }
        // }
        // newComps._layers[maxThicknessLayerIndex].value = material.materialType;
        // newComps._layers[maxThicknessLayerIndex].subType = material.id;
        // // if (material.materialType = "Glass"){
        // //     newComps._layers[maxThicknessLayerIndex].thickness = 30;
        // // }
        // object.properties._components = newComps;
      }
    }
  } catch (err) {
    /*
        Ignore any eval() type error. The error occurs due to non-existence of basic material structure
        in materialStructureProperties.json. So, when a eval() is run on the non-existent type
        an error is thrown.
         */
    // NewLogger.logError(err);
  }
};

var addMaterialToLayersFace = function (
  mesh,
  prevSubMeshes,
  materialType,
  matIndex,
  normal
) {
  try {
    let object = mesh.getSnaptrudeDS();
    let layers;
    if (object) {
      if (object.properties) {
        if (object.properties._components._layers) {
          layers = JSON.parse(
            JSON.stringify(object.properties._components._layers)
          );
        }
      }
    }
    if (layers) {
      if (object.type.toLowerCase() === "wall") {
        const props = MATERIAL_STRUCTURE["Wall"][_.startCase(materialType) + "WallFace"];
        if (!props) return;

        if (normal.y) {
          if (!normal.x && !normal.z) return;
        }
        let bbinfo = mesh.getBoundingInfo();
        let vec1 = bbinfo.boundingBox.vectorsWorld[2].subtract(
          bbinfo.boundingBox.vectorsWorld[0]
        );
        let vec2 = bbinfo.boundingBox.vectorsWorld[7].subtract(
          bbinfo.boundingBox.vectorsWorld[2]
        );
        let result;
        if (vec1.length() > vec2.length()) {
          result = BABYLON.Vector3.Dot(vec1, normal);
        } else {
          result = BABYLON.Vector3.Dot(vec2, normal);
        }
        if (result) return;
        if (layers[0].value === layers[layers.length - 1].value) {
          let newComps = JSON.parse(
            JSON.stringify(props)
          );
          if (layers[0].core) {
            layers.unshift(newComps._layers[1]);
            layers.unshift(newComps._layers[0]);
            layers[0].subtype = mesh.material.subMaterials[matIndex].name;
          }
          else {
            layers[0] = newComps._layers[0];
            layers[0].subtype = mesh.material.subMaterials[matIndex].name;
            layers[1] = newComps._layers[1];
          }
        } else {
          let matLayer1 = layers[0];
          let matLayer2 = layers[layers.length - 1];
          let subMatIndex1 = [];
          let subMatIndex2 = [];
          let prevMat = [];

          for (let i in prevSubMeshes) {
            if (prevSubMeshes[i].materialIndex !== undefined) {
              if (mesh.material.subMaterials) {
                if (
                  mesh.material.subMaterials[prevSubMeshes[i].materialIndex]
                    .name === matLayer1.subtype
                ) {
                  subMatIndex1.push(i);
                } else if (
                  mesh.material.subMaterials[prevSubMeshes[i].materialIndex]
                    .name === matLayer2.subtype
                ) {
                  subMatIndex2.push(i);
                }
              }
            }
          }
          // console.log(subMatIndex1, subMatIndex2);
          if (
            mesh.subMeshes[subMatIndex1[0]].materialIndex !==
            prevSubMeshes[subMatIndex1[0]].materialIndex
          ) {
            //Change first layer
            let newComps = JSON.parse(
              JSON.stringify(props)
            );
            layers[0] = newComps._layers[0];
            layers[0].subtype = mesh.material.subMaterials[matIndex].name;
            layers[1] = newComps._layers[1];
          } else {
            //Change last layer
            let newComps = JSON.parse(
              JSON.stringify(props)
            );
            if (layers[layers.length - 1].core) {
              layers.push(newComps._layers[1]);
              layers.push(newComps._layers[0]);
              layers[layers.length - 1].subtype =
                mesh.material.subMaterials[matIndex].name;
            }
            else {
              layers[layers.length - 1] = newComps._layers[0];
              layers[layers.length - 1].subtype =
                mesh.material.subMaterials[matIndex].name;
              layers[layers.length - 2] = newComps._layers[1];
            }
          }
        }
        object.properties._components = JSON.parse(
          JSON.stringify(object.properties._components)
        );
        object.properties._components._layers = JSON.parse(
          JSON.stringify(layers)
        );
      } else if (object.type.toLowerCase() === "floor") {
        const props = MATERIAL_STRUCTURE["Floor"][materialType + "FloorFace"];
        if (!props) return;
        if (!normal.y) {
          return;
        }
        if (normal.y < 0) {
          let newComps = JSON.parse(
            JSON.stringify(props)
          );
          layers[layers.length - 1] = newComps._layers[0];
          layers[layers.length - 1].subtype =
            mesh.material.subMaterials[matIndex].name;
          layers[layers.length - 2] = newComps._layers[1];
        } else {
          let newComps = JSON.parse(
            JSON.stringify(props)
          );
          layers[0] = newComps._layers[0];
          layers[0].subtype = mesh.material.subMaterials[matIndex].name;
          layers[1] = newComps._layers[1];
        }
        object.properties._components = JSON.parse(
          JSON.stringify(object.properties._components)
        );
        object.properties._components._layers = JSON.parse(
          JSON.stringify(layers)
        );
      } else if (mesh.name.toLowerCase() === "roof") {
        const props = MATERIAL_STRUCTURE["Roof"][materialType + "RoofFace"];
        if (!props) return;

        if (!normal.y) {
          return;
        }
        if (normal.y < 0) {
          let newComps = JSON.parse(
            JSON.stringify(props)
          );
          layers[layers.length - 1] = newComps._layers[0];
          layers[layers.length - 1].subtype =
            mesh.material.subMaterials[matIndex].name;
          layers[layers.length - 2] = newComps._layers[1];
        } else {
          let newComps = JSON.parse(
            JSON.stringify(props)
          );
          layers[0] = newComps._layers[0];
          layers[0].subtype = mesh.material.subMaterials[matIndex].name;
          layers[1] = newComps._layers[1];
        }
        object.properties._components = JSON.parse(
          JSON.stringify(object.properties._components)
        );
        object.properties._components._layers = JSON.parse(
          JSON.stringify(layers)
        );
      } else if (mesh.name.toLowerCase() === "staircase") {
        let type = "";
        let layerIndex = -1;
        for (let i = 0; i < object.mesh.subMeshes.length; i++) {
          if (object.mesh.subMeshes[i].materialIndex === matIndex) {
            type = object.getFaceType(i);
            break;
          }
        }
        object.properties._components._layers.forEach((layer, j) => {
          if (layer.stairProp) {
            if (layer.stairProp.toLowerCase() === type) {
              layerIndex = j;
              return j;
            }
          }
        });
        const props = MATERIAL_STRUCTURE["Staircase"][materialType + type.charAt(0).toUpperCase() + type.slice(1)];
        if (props) {
          let newLayer = JSON.parse(
            JSON.stringify(
              props
            )
          );
          layers[layerIndex] = newLayer;
          layers[layerIndex].subtype =
            mesh.material.subMaterials[matIndex].name;
        }
        object.properties._components = JSON.parse(
          JSON.stringify(object.properties._components)
        );
        object.properties._components._layers = JSON.parse(
          JSON.stringify(layers)
        );
      }
      object.properties._components._name += " " + materialType + " finish";

      // if (object.type.toLowerCase() === "wall"){
      //     let newComps = JSON.parse(JSON.stringify(eval(materialType+"WallFace")))
      //     object.properties._components._layers = newComps._layers;
      // }
      // if (object.type.toLowerCase() === "floor"){
      //     let newComps = JSON.parse(JSON.stringify(eval(materialType+"FloorFace")))
      //     object.properties._components._layers = newComps._layers;
      // }
      // if (object.type.toLowerCase() === "roof"){
      //     let newComps = JSON.parse(JSON.stringify(eval(materialType+"RoofFace")))
      //     object.properties._components._layers = newComps._layers;
      // }
    }
  } catch (err) {
    console.log(err);
  }
};

var deleteMaterialToLayersFace = function (mesh, matIndex) {
  try{
    let object = mesh.getSnaptrudeDS();
    let layers;
    if (object) {
      if (object.properties) {
        if (object.properties._components._layers) {
          layers = JSON.parse(
            JSON.stringify(object.properties._components._layers)
          );
        }
      }
    }
    if (layers) {
      let materialType = mesh.material.subMaterials[matIndex].materialType;
      let meshType = mesh.type;
      meshType = meshType[0].toUpperCase() + meshType.slice(1);
      if (layers[layers.length - 1].value === materialType) {
        let newComps = JSON.parse(
          JSON.stringify(eval("Colors" + meshType + "Face"))
        );
        layers[layers.length - 1] = newComps._layers[0];
        layers[layers.length - 2] = newComps._layers[1];
      } else if (layers[0].value === materialType) {
        let newComps = JSON.parse(
          JSON.stringify(eval("Colors" + meshType + "Face"))
        );
        layers[0] = newComps._layers[0];
        layers[1] = newComps._layers[1];
      } else if (layers[0].value === "Paint" && materialType === "Colors") {
        let newComps = JSON.parse(
          JSON.stringify(eval("Colors" + meshType + "Face"))
        );
        layers[0] = newComps._layers[0];
        layers[1] = newComps._layers[1];
      }
      object.properties._components = JSON.parse(
        JSON.stringify(object.properties._components)
      );
      object.properties._components._layers = JSON.parse(JSON.stringify(layers));
    }
  } catch (err){
    console.log('Error in deleteMaterialToLayersFace', err);
  }
};
export {
  applyMaterialCommandName,
  onMatPointerDown,
  onMatPointerUp,
  onMatPointerMove,
  prepareDataForCMD,
  getMaterialCommandLogic,
  getApplyMaterialSaveData,
  applyMaterial,
  removeMaterial,
  recreateSubMeshes,
  serializeMaterial,
  saveMaterialInBackend,
  onMatEditPointerDown,
  onMatEditPointerUp,
  onMatEditPointerMove,
  addMaterialToLayers,
  addMaterialToLayersFace,
  deleteMaterialToLayersFace,
  deleteMaterialInBackend
};
