import { store } from "../utilityFunctions/Store";
import BABYLON from '../babylonDS.module';
import { changeToIsoViewCamera } from "../../libs/cameraFuncs";
import { cameraController } from "../cameraControl/cameraController";
import { goIntoTwoD, goOutOfTwoD } from '../../libs/twoDimension';
import CameraCursor from "../cursor/cameraCursor";

const cameraFollow = (() => {
  let _isAuthorised = false;
  let _socket = null;
  let _followSharer = false;
  let _broadcast = false;

  const init = socket => {
      _socket = socket;
      socket.on('incam', _setCamera);
      socket.on('in2dbutton', _setView);
  };

  const _isAuthorisedToEmit = () => {
      return _isAuthorised;
  };

  const _unAuthoriseToEmit = () => {
      _isAuthorised = false;
  };

  const _authoriseToEmit = () => {
      _isAuthorised = true;
  };

  const _retrieveCameraData = camera => {
      return {
          position: camera.position,
          alpha: camera.alpha,
          beta: camera.beta,
          radius: camera.radius,
          target: camera.target,
          orthoLeft: camera.orthoLeft,
          orthoRight: camera.orthoRight,
          orthoBottom: camera.orthoBottom,
          orthoTop: camera.orthoTop,
      };
  };

  const onCameraUpdate = () => {
      const camera = store.scene.activeCamera;
      const data = _retrieveCameraData(camera);

      if(_isAuthorised && _broadcast){
          if(_socket){
              _socket.emit('outcam', data);
          }
      }
  };

  const onClickTwoDButton = newView => {
      const data = { newView: newView };
      if(_isAuthorised && _broadcast){
          if(_socket){
              _socket.emit('out2dbutton', data);
          }
      }
  };

  const _setCamera = cameraData => {
      if(_followSharer){
          const camera = store.scene.activeCamera;
          camera.setPosition(new BABYLON.Vector3(cameraData.position._x, cameraData.position._y, cameraData.position._z));
          camera.alpha = cameraData.alpha;
          camera.beta = cameraData.beta;
          camera.radius = cameraData.radius;
          camera.setTarget(new BABYLON.Vector3(cameraData.target._x, cameraData.target._y, cameraData.target._z));
          camera.orthoLeft = cameraData.orthoLeft;
          camera.orthoRight = cameraData.orthoRight;
          camera.orthoBottom = cameraData.orthoBottom;
          camera.orthoTop = cameraData.orthoTop;
      }
  };

  const _setView = data => {
      if(_followSharer){
          if(data && data.newView){
              if(data.newView === '2d-view'){
                  goIntoTwoD(true, false);
              } else if(data.newView === '3d-view'){
                  goOutOfTwoD();
              }
          }
      }
  };

  const followSharer = () => {
      _followSharer = true;
      changeToIsoViewCamera();
      cameraController.detach();
      const cameraCursor = new CameraCursor();
      cameraCursor.goIntoBrainStorm();
  };

  const unfollowSharer = () => {
      _followSharer = false;
      cameraController.attach();
      const cameraCursor = new CameraCursor();
      cameraCursor.goOutOfBrainStorm();
  };

  const broadcastShare = () => {
      _broadcast = true;
      const cameraCursor = new CameraCursor();
      cameraCursor.goIntoBrainStorm();
  };

  const unbroadcastShare = () => {
      _broadcast = false;
      const cameraCursor = new CameraCursor();
      cameraCursor.goOutOfBrainStorm();
  };

  return {
      init,
      onCameraUpdate,
      onClickTwoDButton,
      unAuthoriseToEmit: _unAuthoriseToEmit,
      authoriseToEmit: _authoriseToEmit,
      followSharer,
      unfollowSharer,
      broadcastShare,
      unbroadcastShare,
  };
})();

export {
  cameraFollow,
};
