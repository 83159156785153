import React from 'react'
import { ORIGIN } from '../../../../services/url.constants';
import { EuiIconsMapping, ColorsMapping, SDA_RANGE_VALUES, ASE_RANGE_VALUES } from './constants'
import DonutChart from './DonutChart'
import * as S from './styles'

import SDARangeImage from '../../../../assets/icons/daylight_analysis/sda_range_with_flag.svg'
import ASERangeImage from '../../../../assets/icons/daylight_analysis/ase_range_with_flag.svg'
import { useSelector } from 'react-redux';
import coveToolHelpers from '../../../../snaptrude/modules/covetool';
import { useMemo } from 'react';

export const DaylightAnalysisRecord = ({ running, data, euiData }) => {
  return (
    <S.RecordContainer>
      <div className='fn-12 fw-600 pd-v'>Energy Code: {data?.details?.energy_code_name}</div>
      <div className='fn-12 fw-600 pd-v'>Building Type: {data?.details?.building_types}</div>
      { euiData && <EuiInformation data={euiData} showBorder /> }
      <DaylightAnalysisBlock key="sda" data={data} running={running} sda />
      <DaylightAnalysisBlock key="ase" data={data} running={running} sda={false} />
    </S.RecordContainer>
  )
}

export const DaylightAnalysisBlock = ({ sda = true, running, data }) => {
  const title = sda ? "Spatial Daylight Autonomy" : "Annual Sunlight Exposure"

  const firstCalculation = Array.isArray(data?.calculation) && data?.calculation?.length > 0 && data?.calculation[0]
  const percentage = sda ? firstCalculation?.sda : firstCalculation?.ase

  /*
   * Pass/Fail logic:
   * LEED requires annual sDA value of atleast 55%, 75% or 90% demonstrated through annual computer simulations and an ASE of less than 10%.
   * Source (Step 4 - Analysis for sDA and ASE): https://help.covetool.com/en/articles/5081956-leed-daylighting-documentation
   */
  const fail = sda ? percentage < 55 : percentage > 10

  return (
    <div>
      <S.Header>
        <S.FlexBox direction="column" justifyContent="space-around">
          <span className="fw-600 fn-12">{ title }</span>
          <span className="fn-12">{ running ? "Started" : "Run" } on {new Date(data?.added).toLocaleString()}</span>
        </S.FlexBox>
        {
          !running && <S.PercentageText fail={fail}>{Number.isFinite(percentage) && parseFloat(percentage).toFixed(1) + "%"}</S.PercentageText>
        }
      </S.Header>
      {
        running ?
        (<S.Block>
            <S.DaylightAnalysisLoader height="50px" width="50px"/>
            <div className='fn-12'>Running Analysis</div>
        </S.Block>)
        : 
        (<>
          <S.ImageWrapper to={`/model/${data?.floor_key}?daylight=${sda ? "SDA" : "ASE"}`} target="_blank">
            <S.RecordPreview src={`${ORIGIN}/media/${sda ? data?.threeDUrl : data?.threeDUrlOne}` + "?v=" + String(Math.floor(Math.random() * 100))} alt={title} />
          </S.ImageWrapper>
          <S.WrapLabelAndRange>
            { sda ?
              <S.FlexBox className="fn-12">
                  <span>Never daylit</span>
                  <span>Mostly daylit</span>
              </S.FlexBox> :
              <S.FlexBox className="fn-12" justifyContent="flex-end">
                  {`# of Glare Hours (>1000 lux)`}
              </S.FlexBox>
            }
            <S.RangeBar url={sda ? SDARangeImage : ASERangeImage} />
          </S.WrapLabelAndRange>
          {
            sda ? 
            <S.FlexBox className="fn-12">
                { SDA_RANGE_VALUES.map(val => <span key={val}>{val}</span>) }
            </S.FlexBox> :
            <S.FlexBox className="fn-12">
                { ASE_RANGE_VALUES.map(val => <span key={val}>{val}</span>) }
            </S.FlexBox>
          }
        </>)
      }
    </div>
  )
}

export const EuiInformation = ({ data, showBorder }) => {
  const projectUnits = useSelector((state) => state.projectProperties.length.value)
  const usekbtu_ft2_unit = String(projectUnits).toLowerCase().includes("inches")

  const euiUnit = useMemo(() => {
    let val = data?.eui

    if(usekbtu_ft2_unit) val = coveToolHelpers.kwh_m2Tokbtu_ft2(val)

    return parseFloat(val).toFixed(2)
  }, [usekbtu_ft2_unit])

  function createData(keys = [], values = []) {
    const lookUp = {}
    const parsedData = []

    keys.forEach((key, index) => {
      const value = parseFloat(values[index]).toFixed(2),
            iconSrc = EuiIconsMapping[key]

      lookUp[key] = {
        value,
        iconSrc
      }

      parsedData.push({
        key,
        value,
        iconSrc,
        id: key?.replaceAll(' ', ''),
      })
    })

    return [lookUp, parsedData]
  }

  const [lookUp, parsedData] = createData(data?.eui_breakdown?.headers, data?.eui_breakdown?.values)

  return (
    <div>
      <S.Header>
        <span className="fw-600 fn-12">Energy Use Intensity</span>
        <span className="fn-12">Run on {new Date(data?.added).toLocaleString()}</span>
      </S.Header>
      <DonutChart
        key="eui_breakdown_info_chart"
        insideTextTop="Total EUI"
        insideTextMiddle={euiUnit}
        insideTextBottom={usekbtu_ft2_unit ? "kBtu/ft²/yr" : "kWh/m²/yr"}
        data={parsedData}
        colors={(key) => ColorsMapping[key]}
        style={{ height: "300px" }}
      />
      <S.EuiItemsContainer>
        {
          Object.keys(EuiIconsMapping).map(key => (
            <S.EuiItem>
              <img src={lookUp[key]?.iconSrc} alt="Heating" />
              <span className="fn-12">{key}</span>
              <span className="fw-600 fn-14">{lookUp[key]?.value}</span>
            </S.EuiItem>
          ))
        }
      </S.EuiItemsContainer>
      { showBorder && <S.Divider /> }
    </div>
  )
}

