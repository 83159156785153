import React from 'react'
import loaderGif from "../../assets/icons/loader.gif";

function SpinLoader({...props}) {
    return (
        <img
          alt="load"
          width={20}
          height={20}
          src={loaderGif}
          style={{margin: 0}}
          {...props}
        />
    )
}

export default SpinLoader
