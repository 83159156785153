"use strict";
import BABYLON from "../babylonDS.module.js";
import { createCustomMeshAccordingToNormal } from "../../libs/massModeling.js";
import { isTwoDimension } from "../../libs/twoDimension.js";
import { GLOBAL_CONSTANTS } from "../utilityFunctions/globalConstants.js";
import { projectionOfPointOnFace } from "../../libs/snapFuncs.js";
import { colorUtil } from "../utilityFunctions/colorUtility.js";
import { store } from "../utilityFunctions/Store.js";
import { getFaceIdFromFacet,
  extractFaceBrepMeshFromGivenFaceId,
 } from "../../libs/brepOperations.js";

const faceIndicator = (function () {
  let faceBox, faceBox2;

  const TYPES = {
    allIndicators: 0,
    preSnapIndicator: 1,
    postSnapIndicator: 2,
  };
  
  const show = function (faceCoordinates,facetId, mesh, name, material) {
    let faceIndicator = null;
    let faceId = getFaceIdFromFacet(facetId, mesh);
    if(faceId>1)
    {
      faceIndicator = extractFaceBrepMeshFromGivenFaceId(faceId, mesh,  BABYLON.Space.WORLD);
    }
    else{
        faceIndicator = createCustomMeshAccordingToNormal({
        path_bottom_vec: faceCoordinates,
        height: 0.1,
      });
    }   
    if (!faceIndicator) return;

    if (store.$scope.isTwoDimension) faceIndicator.position.y = 0;
    faceIndicator.name = name;
    faceIndicator.material = material;
    faceIndicator.renderingGroupId = 1;
    faceIndicator.visibility = 0.3;
    faceIndicator.parentMesh = mesh;
    faceIndicator.type = GLOBAL_CONSTANTS.strings.identifiers.visualElement;

    // Below things are done so that indicator encloses the face and not just sit on it
    // let position = faceIndicator.position;
    // let projection = projectionOfPointOnFace(position, faceCoordinates);
    // // let projection = position;
    // faceIndicator.setAbsolutePosition(projection);
    faceIndicator.computeWorldMatrix();

    remove(name);
    if (name === "faceBox") faceBox = faceIndicator;
    else faceBox2 = faceIndicator;

    return faceIndicator;
  };

  const remove = function (name) {
    const _remove = function (mesh) {
      if (mesh) mesh.dispose();
    };

    if (name) {
      if (name === "faceBox") {
        _remove(faceBox);
        faceBox = null;
      }
      else {
        _remove(faceBox2);
        faceBox2 = null;
      }
    } else {
      _remove(faceBox);
      _remove(faceBox2);
      faceBox = null;
      faceBox2 = null;
    }
  };

  const get = function (type) {
    if (type === TYPES.preSnapIndicator) {
      return faceBox;
    } else if (type === TYPES.postSnapIndicator) {
      return faceBox2;
    }
  };

  const moveBy = function (amount) {
    if (faceBox) faceBox.position.addInPlace(amount);
  };

  const markAsUneditable = function () {
    if (faceBox)
      faceBox.material = colorUtil.getMaterial(colorUtil.type.nonEditable);
  };

  return {
    TYPES,
    get,
    show,
    remove,
    moveBy,
    markAsUneditable,
  };
})();
export { faceIndicator };
