import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../themes/constant";
import edit from "../../../assets/icons/edit.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import dropArrow from "../../../assets/icons/dropArrow.svg";
import dropOpenArrow from "../../../assets/icons/dropOpenArrow.svg";
import Input from "../../../components/Input/index";

const MainContainer = styled.div`
  padding: 5rem 1.06em 0 0.98em;
  height: 85%;
`;

const ListRow = styled.div`
  max-width: 12rem;
  margin: 0.05rem 0;
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.266804px;
    color: ${colors.thinGray};
    margin: 0;
    padding: 0.25em 0.5em;
  }
  .addRow {
    cursor: pointer;
  }
`;

const ItemName = styled.div`
  border-radius: 5px;
  position: relative;
`;

const ItemOverlay = styled.div`
  width: max-content;
  position: absolute;
  background: ${colors.white};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
  p {
    padding: 0.25em 0.5em;
  }
  img {
    padding: 0.125em;
    margin: 0 0.125em;
    cursor: pointer;
    width: 0.625em;
    height: 0.625em;
  }
  .whiteIcons {
    background: ${colors.fullWhite};
    border-radius: 3px;
  }
  .emptyIcon {
    width: 0.625em;
    height: 0.625em;
    padding: 0.125em;
    margin: 0 0.125em;
  }

  &:hover {
    opacity: 1;
  }
`;

const ListWrapper = styled.div``;

const DropDownTree = (props) => {
  const { key, item, nextItem, level } = props;
  const [collapsed, setCollapsed] = useState(true);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState(false);
  const [addName, setAddName] = useState(false);
  const [appenndListWithValue, setAppenndListWithValue] = useState("");
  const hasChildren = item.group && item.group.length > 0;
  return (
    <ListRow key={key} style={{ margin: `0.25rem 0 0 ${level * 0.5}em` }}>
      {editName ? (
        <Input
          customInput={{
            height: "1rem",
            paddingLeft: 0,
          }}
          value={newName}
          type={"text"}
          onPress={(e) => {
            setNewName(e.target.value);
          }}
        />
      ) : (
        <ItemName>
          <p>{item.header}</p>
          <ItemOverlay>
            <p>{item.header}</p>
            <div onClick={() => setEditName((prevState) => !prevState)}>
              <img className="whiteIcons" src={edit} alt="edit" />
            </div>
            <div>
              <img src={deleteIcon} className="whiteIcons" alt="delete" />
            </div>
            {hasChildren ? (
              <div onClick={() => setCollapsed((prevState) => !prevState)}>
                <img src={collapsed ? dropArrow : dropOpenArrow} alt="drop" />
              </div>
            ) : (
              <div className="emptyIcon" />
            )}
          </ItemOverlay>
        </ItemName>
      )}
      {!collapsed && hasChildren && (
        <div>
          {nextItem(item.group, level + 1)}
          {addName ? (
            <Input
              customInput={{ height: "auto" }}
              customInputContainerStyle={{}}
              value={appenndListWithValue}
              type={"text"}
              onPress={(e) => {
                setAppenndListWithValue(e.target.value);
              }}
            />
          ) : (
            <ListRow onClick={() => setAddName((prevState) => !prevState)}>
              <p className="addRow">+</p>
            </ListRow>
          )}
        </div>
      )}
    </ListRow>
  );
};

const SpaceSidebar = (props) => {
  const [addName, setAddName] = useState(false);
  const [appenndListWithValue, setAppenndListWithValue] = useState("");

  const getDropDownItem = (list, level) => {
    return (
      <ListWrapper>
        {list.map((item, index) => {
          return (
            <DropDownTree
              key={index}
              item={item}
              nextItem={getDropDownItem}
              level={level}
            />
          );
        })}
        {level === 0 && (
          <>
            {addName ? (
              <Input
                customInput={{
                  width: `${8.5 - level - 0.5}em`,
                  height: "auto",
                  margin: `0 ${level + 1.5}em 0 0.25em`,
                }}
                customInputContainerStyle={{
                  margin: `0 ${level + 1.5}em 0 0.25em`,
                }}
                value={appenndListWithValue}
                type={"text"}
                onPress={(e) => {
                  setAppenndListWithValue(e.target.value);
                }}
              />
            ) : (
              <ListRow onClick={() => setAddName((prevState) => !prevState)}>
                <p className="addRow">+</p>
              </ListRow>
            )}
          </>
        )}
      </ListWrapper>
    );
  };

  return (
    <MainContainer>
      {getDropDownItem(
        [
          {
            header: "Resedential",
            group: [
              {
                header: "1bhk",
                group: [
                  { header: "hello", group: null },
                  { header: "haha", group: null },
                  { header: "kjhkjh", group: null },
                ],
              },
              { header: "2bhk", group: null },
              { header: "3bhk", group: null },
            ],
          },
          { header: "Commercial", group: null },
          { header: "Rental", group: null },
          {
            header: "Community",
            group: [
              { header: "4bhk", group: null },
              { header: "5bhk", group: null },
            ],
          },
        ],
        0
      )}
    </MainContainer>
  );
};

export default SpaceSidebar;
