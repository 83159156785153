import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SidebarComment from "../../../components/Comment/SidebarComment";
import { onClickShareScreen } from "../../../snaptrude/modules/socket/collaboration";
import { handleToolbar } from "../../../snaptrude/libs/handleToolbar";
import { commentOperator } from "../../../snaptrude/libs/commentEvents";
import Divider from "../../../components/Divider";
import { colors } from "../../../themes/constant";
import _ from "lodash";
import { updateCursor } from "../cursorHandler";
import { cursor } from "../../../themes/cursor";
import SignOff from "../../../components/SignOff";

const sendCollabToolSelectEvent = (tool) => {
  const evt = new CustomEvent("collaboration-tool-select", {
    detail: {
      tool: tool
    }
  });
  window.dispatchEvent(evt);
}

const MainContainer = styled.div`
  padding: 5rem 1.06em 1em 0.98em;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
const CommentsWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow-y: auto;
`

const CommentGroupHeading = styled.div`
  font-weight: bold;
  color: ${colors.darkGrey};
  margin: 5px;
`
const Controls = styled.div`
  display: flex;
  position: fixed;
  bottom: 10px;
  img{
    cursor: pointer;
  }
  
`
const AddCommentWrapper = styled.div`
  position: fixed;
  display: none;
`
const ShowCommentWrapper = styled.div`
  position: fixed;
  display: none;
`

const DEFAULT_COMMENTS = {
  unAssigned: {
    title: "",
    comments: []
  },
  resolved: {
    title: "Resolved",
    comments: []
  },
}

const CommentsSidebar = (props) => {
  const {selectedStorey, viewType2D, setSelectedStorey, viaCommentSelect, currentProjectTeam} = props;
  const [activeTool, setActiveTool] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const comments = useSelector(state => state.comments);

  useEffect(() => {
    commentOperator.drawCommentPins();
    if(!viewType2D){
      commentOperator.updateVisibilityForViewChange(true);
    } else {
      commentOperator.updateVisibilityForViewChange(false);
    }
    if(selectedStorey !== undefined){
      const storey = comments.groupedComments[selectedStorey];
      commentOperator.showStoreywiseComments(storey);
    }
    commentOperator.hideAll();
  }, [comments]);

  useEffect(() => {
    commentOperator.hideAll();
    if(!viewType2D){
      commentOperator.updateVisibilityForViewChange(true);
    } else {
      commentOperator.updateVisibilityForViewChange(false);
    }
    commentOperator.showAll();
  }, [viewType2D])

  useEffect(() => {
    if(selectedStorey !== undefined){
      const storey = comments.groupedComments[selectedStorey];
      commentOperator.showStoreywiseComments(storey, viaCommentSelect);
    }
  }, [selectedStorey, viaCommentSelect]);

  const disableOnCommentMode = useCallback(
    () => {
      setActiveTool(null);
      sendCollabToolSelectEvent(null);
      handleCommentDialogClose();
      handleToolbar("disableComment");
    },
    [],
  )

  const handleSetCommentEvent = useCallback((evt) => {
    setSelectedComment(evt.detail);
  }, [])

  const handleEscapeEvent = useCallback((evt) => {
    if(evt.key === "Escape"){
      disableOnCommentMode();
    }
  }, [disableOnCommentMode]);

  const handleKeyUp = (evt) => {
    const key = evt.which || evt.keyCode;
    const isCtrlUp = key === 17;
    if(isCtrlUp && activeTool === "comment"){
      // document.body.style.cursor = `url(${commentPinInactive}), auto`;
      updateCursor(cursor.comment);
    }
  }

  // EVENT LISTENERS useEffect
  useEffect(() => {
    window.addEventListener('keydown', handleEscapeEvent, true);
    window.addEventListener("set-comment", handleSetCommentEvent);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleEscapeEvent, true);
      window.removeEventListener("set-comment", handleSetCommentEvent);
      window.removeEventListener("keyup", handleKeyUp);
    }
  }, [handleEscapeEvent, handleSetCommentEvent, handleKeyUp])

  useEffect(() => {
    // Temporary solution for canvas scrolling outside view when comment sidebar is open
    document.body.style.overflow = "clip";
    return () => {
      document.body.style.overflow = "visible";
      commentOperator.clearCommentPins();
      // disableOnCommentMode();
    }
  }, [disableOnCommentMode])

  const handleCommentDialogClose = () => {
    const commentWrapper = document.getElementById("add-comment-wrapper");
    if(commentWrapper) commentWrapper.style.display = "none";
  }
  const onCommentMode = () => {
    if(activeTool === "comment"){
      window.analytics.track('exited comment in 3d mode');
      setActiveTool(null);
      sendCollabToolSelectEvent(null);
      handleCommentDialogClose();
      handleToolbar("REMOVE");
    }else{
      window.analytics.track('entered comment in 3d mode');
      setActiveTool("comment");
      sendCollabToolSelectEvent("comment");
      handleToolbar("comment");
    }
  }

  const handleCommentClick = (comment, index, storeyValue) => {
    window.analytics.track('clicked on a posted comment');
    // if(comment.isResolved) return;
    if(comment.scene){
      if(storeyValue && comment.scene.camera.isOrtho){
        const selectStoreyEvt = new CustomEvent("select-storey", {
          detail: {
            storeyValue: storeyValue,
            viaCommentSelect: true
          }
        })
        window.dispatchEvent(selectStoreyEvt)
      }
      commentOperator.onCommentSelect(index);
      sendCollabToolSelectEvent(null);
    }else{
      document.getElementById("show-comment-wrapper").style.left = 20 + "px";
      document.getElementById("show-comment-wrapper").style.top = 120 + "px";
      document.getElementById("show-comment-wrapper").style.display = "block";
    }
    setSelectedComment(comment);
  }

  const onBrainstormMode = () => {
    handleToolbar("REMOVE")
    if(activeTool === "brainstorm"){
      window.analytics.track('exited brainstorm');
      setActiveTool(null);
      sendCollabToolSelectEvent(null);
    }
    else {
      window.analytics.track('clicked brainstorm');
      setActiveTool("brainstorm");
      sendCollabToolSelectEvent("brainstorm");
    }
    onClickShareScreen.click();
  };

  return (
    <MainContainer>
    <CommentsWrapper>
      {
        Object.values(comments.groupedComments).map(group => {
          if(group.comments.length === 0) return <></>
          return <>
            <Divider />
            <CommentGroupHeading>{group.title}</CommentGroupHeading>
            {
              group.comments.map((comment) => {
                return <SidebarComment key={comment.id} onClick={() => {handleCommentClick(comment, comment.localIndex, group.storey)}} comment={comment} index={comment.localIndex} />
              }).reverse()
            }
          </> 
        })
      }
    </CommentsWrapper>
    {
        <SignOff currentProjectTeam={currentProjectTeam} />
    }
    </MainContainer>
  );
};

export default CommentsSidebar;
