import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../themes/constant";

import ReactModal from "react-modal";
import Outer from "../Card/Outer";
import Text from "../Text";

import CloseButton from "../../assets/icons/close.svg";
import Button from "../Button";
import { SingleUploadOverView } from "../../containers/genericModal/singleUploadOverView";
import ProgressBar from "../ProgressBar";
import { GLOBAL_CONSTANTS } from "../../snaptrude/modules/utilityFunctions/globalConstants";
import { randomIntFromInterval } from "../../snaptrude/modules/extrafunc";
import { store } from "../../snaptrude/modules/utilityFunctions/Store";
import { showToast, TOAST_TYPES } from "../../snaptrude/modules/extrafunc";

const Card = styled(Outer)`
  overflow: hidden;
  width: 36.5625rem;
  background-color: ${colors.fullWhite};
  padding: 0;
  box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(197, 202, 220, 0.3);
  }
  z-index: 100;
`;

const ModalHeader = styled.div`
  background-color: ${colors.white};
  height: 4rem;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  margin: 0.25rem;
`;

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding: 0.25em 0em;
  width: 100%;
  z-index: -2;
  border-top: 0.0625em solid ${colors.veryLightGrey};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5em 3em;
`

const ModalChild = styled.div`
  position: relative;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ModalBody = styled.div`
  position: relative;
  padding: 1em 0em;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;

const CloseButtonIcon = styled.img`
  cursor: pointer;
`;

const modalStylesDefault = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(87, 87, 88, 0.6)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: 0,
    padding: 0,
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const Row = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HighlightedText = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.333333px;
  white-space: nowrap;
  background-color: ${colors.transparent};
  color: ${colors.brightRed};
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`
let pdfProgressInterval = null;
let cadProgressInterval = null;
function SnaptrudeModal({
  isOpen,
  onClose,
  modalHeaderText,
  bodyHeaderText,
  allowedTypes,
  DropzoneComponent,
  uploadedFile,
  selectedStorey,
  storey,
  setStorey,
  resetUploadedFile,
  onUpload,
  modalBody,
  modalFooter,
  customHeaderStyle,
  customBodyStyle,
  customFooterStyle,
  custonModalStyle,
  showProgressBar,
  closeModal,
  page, pdfPageCount, isPdfPageCountLoading, setPdfPage, scale, setScale,
}) {
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("");

  useEffect(() => {
    const handleImageUploadProgress = (evt) => {
      if (evt.detail) {
        setProgress(evt.detail.progress);
        setProgressText(evt.detail.text);
      }
    }

    const handleCadUploadProgress = (evt) => {
      if (evt.detail) {
        setProgress(evt.detail.progress);
        setProgressText(evt.detail.text);
        if(evt.detail.progress === 100){
          setTimeout(() => {
            onClose();
          }, 100);
        }
      }
    };

    const handlePdfUploadProgress = (evt) => {
      if (evt.detail) {
        setProgress(evt.detail.progress);
        setProgressText(evt.detail.text);
        const {next, intervalDuration, progress } = evt.detail;
        if(pdfProgressInterval) clearInterval(pdfProgressInterval);
        if(progress === 100) return;
        let incrementAt = (progress + next)/2;
        let factor = 1;
        pdfProgressInterval = setInterval(() => {
          setProgress((prev) => {
            if(prev >= 100) {
              clearInterval(pdfProgressInterval);
              return 100;
            }
            if(prev >= next){
              clearInterval(pdfProgressInterval);
              pdfProgressInterval = null;
              return prev;
            }
            const newVal = prev + (1/factor);
            if(newVal >= incrementAt){
              factor = factor*2;
              incrementAt = (incrementAt + next)/2;
            }
            return newVal;
          })
        }, intervalDuration)
      }
    }
    const handleModelUploadProgress = (evt) => {
      if (evt.detail) {
        setProgress(evt.detail.progress);
        setProgressText(evt.detail.text);
        if (evt.detail.progress === 100) {
          onClose();
          setProgress(0);
        }
      }
    }
    window.addEventListener(GLOBAL_CONSTANTS.strings.events.imageUploadProgress, handleImageUploadProgress);
    window.addEventListener(GLOBAL_CONSTANTS.strings.events.cadUploadProgress, handleCadUploadProgress);
    window.addEventListener(GLOBAL_CONSTANTS.strings.events.modelUploadProgress, handleModelUploadProgress);
    window.addEventListener(GLOBAL_CONSTANTS.strings.events.pdfUploadProgress, handlePdfUploadProgress);
    return () => {
      window.removeEventListener(GLOBAL_CONSTANTS.strings.events.imageUploadProgress, handleImageUploadProgress);
      window.removeEventListener(GLOBAL_CONSTANTS.strings.events.cadUploadProgress, handleCadUploadProgress);
      window.removeEventListener(GLOBAL_CONSTANTS.strings.events.modelUploadProgress, handleModelUploadProgress);
      window.removeEventListener(GLOBAL_CONSTANTS.strings.events.pdfUploadProgress, handlePdfUploadProgress);
    }
  }, []);

  useEffect(() => {
    // RESETS THE PROGRESSBAR TO 0 EVERYTIME THE showProgressBar STATE CHAGNES.
    if(!showProgressBar) setProgress(0);
  }, [showProgressBar])

  const handleClose = () => {
    if (modalHeaderText === "CAD Drawing") {
      if (store.currentCadRequestId) {
        GLOBAL_CONSTANTS.cadRequestIdsToCancel.push(store.currentCadRequestId);
        store.currentCadRequestId = null;
        showToast("Canceled CAD import");
      }
    }
    else if(modalHeaderText.toLowerCase().includes("image")){
      window.scheduleCancelImageUpload = true;
      // cancelImageUpload();
    }
    onClose();
  }
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={modalStylesDefault}
    >
      <Card>
        <ModalHeader>
          <ModalChild left="1.0625rem">
            <Text opacity={1} weight="bold">
              Upload {modalHeaderText}
            </Text>
          </ModalChild>
          <ModalChild right="1.0625rem">
            <CloseButtonIcon onClick={handleClose} src={CloseButton} alt="icon" />
          </ModalChild>
        </ModalHeader>
        <ModalBody>
          {
            uploadedFile ?
              <SingleUploadOverView customProps={{files: uploadedFile, selectedStorey, storey, setStorey, showProgressBar, page, pdfPageCount, setPdfPage, isPdfPageCountLoading, scale, setScale}} />
            :
            <>
              <Row>
                {bodyHeaderText}
              </Row>
              <Row style={{ flexDirection: "row" }}>
                {allowedTypes.map((type, index) => {
                  return (
                    <div
                      key={type.name + index}
                      style={{ margin: "0px 5px", textAlign: "center" }}
                    >
                      <div>
                        <img src={type.src} alt={type.name} />
                      </div>
                      <div>{type.name}</div>
                    </div>
                  );
                })}
              </Row>
              <Row>
                <HighlightedText>{DropzoneComponent}</HighlightedText> &nbsp; or
                Drag n drop your files here.
              </Row>
            </>
        }
        </ModalBody>
        <ModalFooter>
          {showProgressBar ?
            <ProgressBar bgColor={colors.red} completed={progress} text={progressText}/>:
            <>
              <ButtonWrapper>
            {
              uploadedFile ?
                <Button onPress={resetUploadedFile} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title={"< Change " + modalHeaderText} />
                :
                <Button onPress={handleClose} style={{backgroundColor: colors.transparent, color: colors.dullgrey,}} title="< Back" />
            }
          </ButtonWrapper>
            <ButtonWrapper>
              <Button primary={uploadedFile!=null} disabled={uploadedFile==null} title="Upload" onPress={onUpload} />
            </ButtonWrapper>
            </>
          }

        </ModalFooter>
      </Card>
    </ReactModal>
  );
}

export default SnaptrudeModal;
