import { fabric } from "fabric";
import { store } from "../../modules/utilityFunctions/Store.js";
import { updateModifications } from "../sceneStateFuncs.js";
import {
  detectDoor,
  detectWindow,
  detectRoomsFromWalls,
  send_wall_query,
} from "./sendCanvasData.js";
import {
  send_door_data,
  send_window_data,
  send_tape_data,
  canvas_tape_data,
  send_interior_data,
} from "../fplan/sendDoorWindowData.js";
import { appElement } from "../bimDataFuncs.js";
import { removeAllChildren } from "../keyEvents.js";
import {
  draw_interior_drag,
  draw_door_position,
  draw_window_position,
} from "../fplan/drawInteriorsFplan.js";
import {
  getFillColor,
  removeRoomDimension,
  snapNearestEdge,
  displayEdgeDimension,
  displayRoomDimension,
  drawDimension,
} from "./canvasServices.js";
import {
  addObjectEdgeGrips,
  removeAllGripsWithId,
  addObjectVertGrips,
} from "./objectGrips.js";
import { changeCursor } from "./updateMods.js";
import { snapObjectVert, snapObjectEdge } from "../fplan/snapFuncsFplan.js";
import { snapCurveVert, snapTapeVert } from "./snapFuncs.js";
import {
  getNearestEdge,
  getSlopeXY,
  getCoeffXY,
  getParentRoom,
  assignParentRoom,
} from "./nearestProps.js";
import { getSlopeAngle } from "../interiors_gen.js";
import {
  draw_stair_position,
  draw_partition_position,
  draw_stair_geom_position,
} from "./doorWindow.js";
import { points } from "../polygon_dip_workers.js";
import { findNearestVertex } from "../snapFuncs.js";
import { updateEventMode } from "./updateEventModes.js";
var addVertexSnapPoint = null;
var removeVertexSnapPoint = null;
var pointsBeforeEditEdge = null;
var editPolygonSnapPoint = null;
var editPolygonSnapPointFinal = null;
var started = false;
var xwid = 0;
var ywid = 0;
function canvasMouseDown(o) {
  if (store.mode === "pen") {
    store.isDown = true;
    var pointer2 = store.canvas.getPointer(o.e);
    store.freeDrawArray = [];
    store.freeDrawArray.push([
      pointer2.x * store.scale_factor,
      pointer2.y * store.scale_factor,
    ]);
  } else if (store.evt_mode === "door_draw") {
    var gObjs = store.door_group.getObjects();
    gObjs.forEach(function (gObj) {
      if (gObj.get("id") == "line2") {
        gObj.set({ stroke: "white", strokeWidth: 4 });
      }
    });
    store.door_group.id = "door";
    updateModifications(true);
  } else if (store.evt_mode === "window_draw") {
    updateModifications(true);
  } else if (store.evt_mode === "partition") {
    updateModifications(true);
  } else if (store.evt_mode == "detect_door") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    detectDoor(point);

    let x = document.createElement("div");
    x.className = "mdl-spinner mdl-js-spinner is-active";
    x.id = "load" + point[0] + point[1];
    x.style.zIndex = 9999999;
    store.componentHandler.upgradeElement(x);
    document.getElementById("canvas_container").appendChild(x);
    x.style.position = "absolute";
    let cx = o.e.clientX;
    let cy = o.e.clientY;
    x.style.left = cx + "px";
    x.style.top = cy + "px";
  } else if (store.evt_mode == "detect_window") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    detectWindow(point);
    let x = document.createElement("div");
    x.className = "mdl-spinner mdl-js-spinner is-active";
    x.id = "load" + point[0] + point[1];
    x.style.zIndex = 9999999;
    store.componentHandler.upgradeElement(x);
    document.getElementById("canvas_container").appendChild(x);
    x.style.position = "absolute";
    let cx = o.e.clientX;
    let cy = o.e.clientY;
    x.style.left = cx + "px";
    x.style.top = cy + "px";
  }
  if (store.evt_mode === "door") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    send_door_data(point, 3);
    var x = document.createElement("div");
    x.className = "mdl-spinner mdl-js-spinner is-active";
    x.id = "load" + point[0] + point[1];
    x.style.zIndex = 2;
    store.componentHandler.upgradeElement(x);
    document.getElementById("canvas_container").appendChild(x);
    x.style.position = "absolute";
    var cx = o.e.clientX;
    var cy = o.e.clientY;
    x.style.left = cx + "px";
    x.style.top = cy + "px";
  }
  if (store.evt_mode === "window") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    send_window_data(point, 4);
    var x = document.createElement("div");
    x.className = "mdl-spinner mdl-js-spinner is-active";
    x.id = "load" + point[0] + point[1];
    x.style.zIndex = 2;
    store.componentHandler.upgradeElement(x);
    document.getElementById("canvas_container").appendChild(x);
    x.style.position = "absolute";
    x.style.left = pointer.x + "px";
    x.style.top = pointer.y + "px";
  }
  if (store.evt_mode === "doors") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    send_door_data(point, 3);
  }
  if (store.evt_mode === "windows") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    send_window_data(point, 4);
  }
  if (store.evt_mode === "pan") {
    store.panning = true;
  }
  if (store.evt_mode === "tape") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    let obj = o.target;
    if (obj == undefined) {
      send_tape_data(point, 7);
    } else {
      store.canvas_tape_data(point, obj);
    }
  }
  if (store.evt_mode === "walls") {
    let mouse = store.canvas.getPointer(o.e);
    started = true;
    xwid = mouse.x;
    ywid = mouse.y;
    let square = new fabric.Rect({
      width: 1,
      height: 1,
      left: mouse.x,
      top: mouse.y,
      stroke: "red",
      fill: "rgba(0,0,0,0)",
    });
    store.canvas.add(square);
    store.canvas.renderAll();
    store.canvas.setActiveObject(square);
  }
  if (store.evt_mode === "Interiors") {
    let pointer = store.canvas.getPointer(o.e);
    let point = [
      pointer.x * store.scale_factor,
      pointer.y * store.scale_factor,
    ];
    let $scope = store.angular.element(appElement).scope();
    $scope = $scope.$$childHead;
    if (
      $scope.room_type[store.room_name[store.room_name.length - 1]] == undefined
    ) {
      $scope.$apply(function () {
        $scope.room_type[store.room_name[store.room_name.length - 1]] = {
          id: 0,
          value: "default",
          name: "default",
        };
      });
    }
    var room_type =
      $scope.room_type[store.room_name[store.room_name.length - 1]].value;
  //  store.window.analytics.track("Add Room");
    if (store.floorplanmode) {
      detectRoomsFromWalls(point, store.room_name, room_type);
    } else {
      send_interior_data(point, store.room_name, room_type);
    }
    let x = document.createElement("div");
    x.className = "mdl-spinner mdl-js-spinner is-active";
    x.id = "load" + point[0] + point[1];
    x.style.zIndex = 9999999;
    store.componentHandler.upgradeElement(x);
    document.getElementById("canvas_container").appendChild(x);
    x.style.position = "absolute";
    let cx = o.e.clientX;
    let cy = o.e.clientY;
    x.style.left = cx + "px";
    x.style.top = cy + "px";
  }
  if (store.evt_mode == "edit_vert") {
    if (o.target == undefined) {
      removeAllChildren();
      store.objSelected = null;
    }
    if (
      store.objSelected != null &&
      store.objSelected != undefined &&
      (o.target.get("id") != "rooms" || true)
    ) {
      if (store.objSelected.get("id") == "rooms") {
        if (store.child_number != undefined) {
          if (store.child_number === -1) return;
          var obj = store.objSelected.children;
          var items = obj._objects;
          var pointer = store.canvas.getPointer(o.e);
          store.canvas.add(obj);
          for (var i = 0; i < items.length; i++) {
            items[i].fill = "rgba(24, 230, 24,1)";
          }
          items[store.child_number].fill = "rgba(255,0,0,1)";
          store.mouse_down = true;
        }
      }
    }
  }
  if (store.evt_mode == "edit_edge") {
    if (o.target == undefined && !store.mouse_down) {
      removeAllChildren();
      store.objSelected = null;
    }
    if (
      store.objSelected != null &&
      store.objSelected != undefined &&
      !store.mouse_down
    ) {
      if (!o.target) return;
      if (o.target.get("id") == "rooms") return;
      if (store.objSelected.get("id") == "rooms") {
        if (store.child_number != undefined && store.child_number != -1) {
          pointsBeforeEditEdge = store.objSelected.points;
          let obj = store.objSelected.children;
          let items = obj._objects;
          store.canvas.add(obj);
          items[store.child_number].pt1 = Object.assign(
            {},
            store.objSelected.points[items[store.child_number].ptIdx1]
          );
          items[store.child_number].pt2 = Object.assign(
            {},
            store.objSelected.points[items[store.child_number].ptIdx2]
          );
          store.mouse_down = true;
        }
      }
    } else {
      if (store.mouse_mode == "drag") {
        let objSelected1 = draw_interior_drag(
          store.drag_points,
          store.objSelected,
          getFillColor(store.objSelected)
        );

        let temp_children = store.objSelected.children;
        removeAllChildren();
        store.canvas.remove(store.objSelected);
        objSelected1.children = temp_children;
        store.canvas.bringToFront(objSelected1);
        objSelected1.children.hasControls = false;
        objSelected1.children.hasBorders = false;
        removeRoomDimension(store.objSelected);
        updateModifications(true);
        addObjectEdgeGrips(objSelected1);
        store.mouse_mode = null;
        store.mouse_down = false;
        store.objSelected = objSelected1;
      } else if (store.mouse_down) {
        if (store.objSelected.hasOwnProperty("children")) {
          if (store.child_number != undefined) {
            let obj = store.objSelected.children;
            let items = obj._objects;
            items[store.child_number].fill = "rgba(24, 230, 24,1)";
            store.canvas.renderAll();
          }
        }
        store.mouse_down = false;
      }
    }
  }
  if (store.evt_mode == "add_vertex") {
    if (!o.target) {
      return;
    }
    if (addVertexSnapPoint) {
      let point = addVertexSnapPoint.projPt;
      let addVertexGripFinal = new fabric.Rect({
        left: point.x - 7.5,
        top: point.y - 7.5,
        width: 15,
        height: 15,
        fill: "rgba(24, 230, 24,1)",
        id: "addvertexfinal",
        evented: false,
        lockScalingX: true,
        lockScalingY: true,
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
      });
      store.canvas.add(addVertexGripFinal);

      let room = addVertexSnapPoint.room;
      let afterPoint = addVertexSnapPoint.edge[0];
      let newPolygon = [];
      let allObjects = store.canvas.getObjects();
      let allRooms = allObjects.filter(function (obj) {
        return obj.id == "rooms";
      });
      for (let i = 0; i < allRooms.length; i++) {
        if (allRooms[i] == room) {
          for (let j = 0; j < allRooms[i].points.length; j++) {
            newPolygon.push(allRooms[i].points[j]);
            if (allRooms[i].points[j] == afterPoint) {
              let pushingPoint = new fabric.Point(point.x, point.y);
              newPolygon.push(pushingPoint);
            }
          }
          allRooms[i].points = newPolygon;
        }
      }

      updateModifications(true);
      store.canvas.renderAll();
    }
  }
  if (store.evt_mode == "remove_vertex") {
    if (removeVertexSnapPoint) {
      let point = removeVertexSnapPoint.point;
      let room = removeVertexSnapPoint.room;
      let newPolygon = [];
      let allObjects = store.canvas.getObjects();
      let allRooms = allObjects.filter(function (obj) {
        return obj.id == "rooms";
      });
      for (let i = 0; i < allRooms.length; i++) {
        if (allRooms[i] == room) {
          if (allRooms[i].points.length < 4) {
            store.canvas.remove(allRooms[i]);
          } else {
            for (let j = 0; j < allRooms[i].points.length; j++) {
              if (allRooms[i].points[j] == point) {
              } else {
                newPolygon.push(allRooms[i].points[j]);
              }
            }
            allRooms[i].points = newPolygon;
          }
        }
      }
      updateModifications(true);
      removeAllGripsWithId("grips");
      store.canvas.renderAll();
    }
  }
  if (store.evt_mode == "edit_polygon") {
    if (!o.target) {
      return;
      editPolygonSnapPointFinal = null;
    }
    if (store.isMobile) {
      let pointer = store.canvas.getPointer(o.e);
      let snappedPointObj = snapNearestEdge(pointer);
      editPolygonSnapPoint = snappedPointObj;
    }
    if (editPolygonSnapPoint) {
      removeAllGripsWithId("editpolygon");
      editPolygonSnapPointFinal = editPolygonSnapPoint;
      let snappedEdge = editPolygonSnapPointFinal.edge;
      let midPoint = new fabric.Point(
        (snappedEdge[0].x + snappedEdge[1].x) / 2,
        (snappedEdge[0].y + snappedEdge[1].y) / 2
      );
      let editPolygonGrip = new fabric.Rect({
        left: midPoint.x - 7.5,
        top: midPoint.y - 7.5,
        width: 15,
        height: 15,
        fill: "blue",
        id: "editpolygonfinal",
        evented: "false",
      });
      store.canvas.add(editPolygonGrip);
      displayEdgeDimension(snappedEdge);
    } else {
      editPolygonSnapPointFinal = null;
    }
  }
}

function canvasMouseMove(e) {
  obj = e.target;
  if (store.mode == "pen" && store.isDown) {
    var pt = store.canvas.getPointer(e.e);
  }
  if (
    obj != null &&
    store.objSelected != undefined &&
    store.evt_mode == "edit_vert"
  ) {
    if (
      (obj.get("id") == "children" || obj.get("id") == "rooms") &&
      !store.mouse_down
    ) {
      if (obj.get("id") == "children") var items = obj._objects;
      else if (obj.get("id") == "rooms") {
        if (obj.hasOwnProperty("children")) {
          var items = obj.children._objects;
        } else {
          return;
        }
      }
      var pointer = store.canvas.getPointer(e.e);
      let diffX, diffY;
      for (var i = 0; i < items.length; i++) {
        diffX = Math.abs(
          pointer.x - items[i].getCenterPoint().x - obj.getCenterPoint().x
        );
        diffY = Math.abs(
          pointer.y - items[i].getCenterPoint().y - obj.getCenterPoint().y
        );
        if (diffX < 15 && diffY < 15) {
          store.child_number = i;
          store.child_orientation = "vert";
          store.canvas.hoverCursor = "pointer";
          break;
        } else {
          store.child_orientation = "none";
          store.canvas.hoverCursor = "default";
        }
      }
    } else if (!store.mouse_down) {
      if (store.canvas.defaultCursor != "pointer") {
        changeCursor("pointer");
      }
    }
  } else {
    if (store.evt_mode == "edit_vert" && store.mouse_down) {
      if (store.objSelected) {
        if (store.objSelected.hasOwnProperty("children")) {
          if (store.child_number != undefined) {
            var obj = store.objSelected.children;
            var items = obj._objects;
            var idx1 = items[store.child_number].ptIdx1;
            var idx2 = items[store.child_number].ptIdx2;
            if (store.objSelected.points) {
              var pt1 = store.objSelected.points[idx1];
              store.drag_points = store.objSelected.points;
            } else if (store.objSelected.path) {
              var pt1 = {};
              pt1.x = store.objSelected.path[idx1[0]][idx1[1]];
              pt1.y = store.objSelected.path[idx1[0]][idx1[1] + 1];
              store.drag_points = store.objSelected.path;
            }
            var prevPt1 = Object.assign({}, pt1);
            if (store.child_orientation == "vert") {
              pt1.x = pt1.x + e.e.movementX;
              pt1.y = pt1.y + e.e.movementY;

              items[store.child_number].top =
                items[store.child_number].top + e.e.movementY;
              items[store.child_number].left =
                items[store.child_number].left + e.e.movementX;
              items[store.child_number].ptIdx1 = idx1;
              items[store.child_number].ptIdx2 = idx2;
            }
            var pt2 = store.objSelected.points[idx2];

            if (store.canvas.hoverCursor == "ns-resize") {
              pt1.y = pt1.y + e.e.movementY;
              pt2.y = pt2.y + e.e.movementY;

              items[store.child_number].top =
                items[store.child_number].top + e.e.movementY;
              items[store.child_number].ptIdx1 = idx1;
              items[store.child_number].ptIdx2 = idx2;
            }
            if (store.canvas.hoverCursor == "ew-resize") {
              pt1.x = pt1.x + e.e.movementX;
              pt2.x = pt2.x + e.e.movementX;
              items[store.child_number].left =
                items[store.child_number].left + e.e.movementX;
              items[store.child_number].ptIdx1 = idx1;
              items[store.child_number].ptIdx2 = idx2;
            }
            if (store.objSelected.points) {
              store.drag_points[idx1].x = pt1.x;
              store.drag_points[idx1].y = pt1.y;
              var pts = snapObjectVert(store.objSelected, [pt1], 5, 5);
              pt1 = pts[0];
              pt2 = pts[1];
            } else if (store.objSelected.path) {
              store.drag_points[idx1[0]][idx1[1]] = pt1.x;
              store.drag_points[idx1[0]][idx1[1] + 1] = pt1.y;
              store.objSelected.path[idx1[0]][idx1[1]] = pt1.x;
              store.objSelected.path[idx1[0]][idx1[1] + 1] = pt1.y;
              var pts = snapCurveVert(store.objSelected, [pt1], 5, 5);
              pt1 = pts[0];
              pt2 = pts[1];
            }
            store.mouse_mode = "drag";
            removeRoomDimension(store.objSelected);
            displayRoomDimension(
              store.objSelected,
              [pt1, prevPt1],
              [pt1, prevPt1]
            );
          }
        }
      }
    } else if (
      store.canvas.defaultCursor != "pointer" &&
      store.evt_mode == "edit_vert"
    ) {
      changeCursor("pointer");
    } else {
      store.canvas.hoverCursor = "crosshair";
    }
  }
  if (
    obj != null &&
    store.objSelected &&
    store.evt_mode == "edit_edge" &&
    !store.mouse_down
  ) {
    if (obj.get("id") == "children" && !store.mouse_down) {
      let items = obj._objects;
      let pointer = store.canvas.getPointer(e.e);
      for (let i = 0; i < items.length; i++) {
        if (
          Math.abs(
            pointer.x - items[i].getCenterPoint().x - obj.getCenterPoint().x
          ) < 15 &&
          Math.abs(
            pointer.y - items[i].getCenterPoint().y - obj.getCenterPoint().y
          ) < 15
        ) {
          if (!store.objSelected.points[items[i].ptIdx1]) {
            continue;
          }
          if (!store.objSelected.points[items[i].ptIdx2]) {
            continue;
          }

          let centSlopeX =
            store.objSelected.points[items[i].ptIdx2].x -
            store.objSelected.points[items[i].ptIdx1].x;
          let centSlopeY =
            store.objSelected.points[items[i].ptIdx2].y -
            store.objSelected.points[items[i].ptIdx1].y;

          if (centSlopeX != 0) {
            store.angle = (Math.atan(centSlopeY / centSlopeX) * 180) / Math.PI;
          } else {
            store.angle = 90;
          }
          if (Math.abs(store.angle) > 85) {
            store.child_number = i;
            store.child_orientation = "ew";
            store.canvas.hoverCursor = "ew-resize";
          } else if (Math.abs(store.angle) < 5) {
            store.child_number = i;
            store.child_orientation = "ns";
            store.canvas.hoverCursor = "ns-resize";
          } else if (
            Math.abs(store.angle) > 5 &&
            Math.abs(store.angle) < 85 &&
            store.angle < 0
          ) {
            store.child_number = i;
            store.child_orientation = "nw";
            store.canvas.hoverCursor = "nw-resize";
          } else if (
            Math.abs(store.angle) > 5 &&
            Math.abs(store.angle) < 85 &&
            store.angle > 0
          ) {
            store.child_number = i;
            store.child_orientation = "ne";
            store.canvas.hoverCursor = "ne-resize";
          }
        }
      }
    } else if (!store.mouse_down) {
      if (store.canvas.defaultCursor !== "pointer") {
        changeCursor("pointer");
        store.child_number = -1;
        store.child_orientation = "";
      }
    }
  } else {
    if (store.evt_mode == "edit_edge" && store.mouse_down) {
      if (store.objSelected.hasOwnProperty("children")) {
        if (store.child_number !== undefined) {
          if (Math.abs(e.e.movementX) > 100 || Math.abs(e.e.movementY) > 100)
            return;
          let obj = store.objSelected.children;
          let items = obj._objects;
          let idx1 = items[store.child_number].ptIdx1;
          let idx2 = items[store.child_number].ptIdx2;
          let pt1 = store.objSelected.points[idx1];
          let pt2 = store.objSelected.points[idx2];
          let prevPt1 = Object.assign({}, pt1);
          let prevPt2 = Object.assign({}, pt2);
          store.drag_points = store.objSelected.points;
          var orientation;
          if (store.child_orientation === "ns") {
            pt1.y = pt1.y + e.e.movementY;
            pt2.y = pt2.y + e.e.movementY;

            items[store.child_number].top =
              items[store.child_number].top + e.e.movementY;
            items[store.child_number].ptIdx1 = idx1;
            items[store.child_number].ptIdx2 = idx2;
            orientation = "y";
          }
          if (store.child_orientation === "ew") {
            pt1.x = pt1.x + e.e.movementX;
            pt2.x = pt2.x + e.e.movementX;
            items[store.child_number].left =
              items[store.child_number].left + e.e.movementX;
            items[store.child_number].ptIdx1 = idx1;
            items[store.child_number].ptIdx2 = idx2;
            orientation = "x";
          }
          if (store.child_orientation === "ne") {
            pt1.x = pt1.x + e.e.movementX * Math.cos(store.angle);
            pt2.x = pt2.x + e.e.movementX * Math.cos(store.angle);
            pt1.y = pt1.y + e.e.movementY * Math.sin(store.angle);
            pt2.y = pt2.y + e.e.movementY * Math.sin(store.angle);
            items[store.child_number].left =
              items[store.child_number].left + e.e.movementX;
            items[store.child_number].top =
              items[store.child_number].top + e.e.movementY;
            items[store.child_number].ptIdx1 = idx1;
            items[store.child_number].ptIdx2 = idx2;
            orientation = "a";
          }
          if (store.child_orientation === "nw") {
            pt1.x = pt1.x + e.e.movementX * Math.cos(store.angle);
            pt2.x = pt2.x + e.e.movementX * Math.cos(store.angle);
            pt1.y = pt1.y + e.e.movementY * Math.sin(store.angle);
            pt2.y = pt2.y + e.e.movementY * Math.sin(store.angle);
            items[store.store.child_number].left =
              items[store.child_number].left + e.e.movementX;
            items[store.child_number].top =
              items[store.child_number].top + e.e.movementY;
            items[store.child_number].ptIdx1 = idx1;
            items[store.child_number].ptIdx2 = idx2;
            orientation = "a";
          }

          store.drag_points[idx1].x = pt1.x;
          store.drag_points[idx1].y = pt1.y;
          store.drag_points[idx2].x = pt2.x;
          store.drag_points[idx2].y = pt2.y;

          let pts = snapObjectEdge(
            store.objSelected,
            [pt1, pt2],
            3,
            3,
            orientation
          );
          pt1 = pts[0];
          pt2 = pts[1];
          store.objSelected.setCoords();

          store.canvas.renderAll();

          store.mouse_mode = "drag";
          removeRoomDimension(store.objSelected);
          displayRoomDimension(
            store.objSelected,
            [pt1, prevPt1],
            [pt2, prevPt2]
          );
        }
      }
    } else if (
      store.canvas.defaultCursor !== "pointer" &&
      store.evt_mode === "edit_edge"
    ) {
      changeCursor("pointer");
      store.child_number = -1;
      store.child_orientation = "";
    } else if (store.evt_mode === "edit_edge") {
      store.child_number = -1;
      store.child_orientation = "";
      store.canvas.hoverCursor = "crosshair";
    }
  }
  if (store.panning && e && e.e) {
    var delta = new fabric.Point(e.e.movementX, e.e.movementY);
    store.canvas.relativePan(delta);
  } else if (store.evt_mode === "door_draw") {
    store.canvas.remove(store.door_group);
    var pointer = store.canvas.getPointer(e.e);
    var pointer1 = store.canvas.getPointer(e.e);
    var edgedetection_tape = 20;
    var nearest;
    let [nearest1, nearest2, nearest3] = getNearestEdge(
      store.canvas,
      pointer1,
      5,
      5,
      false
    );
    var m = getSlopeXY(nearest1, nearest3);
    var c = getCoeffXY(nearest2, m);
    draw_door_position(Object.assign({}, nearest1), m, c);
  } else if (store.evt_mode == "window_draw") {
    store.canvas.remove(store.window_group);
    var pointer = store.canvas.getPointer(e.e);
    var pointer1 = store.canvas.getPointer(e.e);
    var edgedetection_tape = 20;
    var nearest;
    let [nearest1, nearest2, nearest3] = getNearestEdge(
      store.canvas,
      pointer1,
      5,
      5,
      false
    );
    var m = getSlopeAngle(nearest1, nearest3);
    var c = 0;
    draw_window_position(nearest1, m, c);
  } else if (store.evt_mode == "stair_draw") {
    store.canvas.remove(store.stair_group);
    var pointer1 = store.canvas.getPointer(e.e);
    var edgedetection_tape = 20;
    var nearest;
    let [nearest1, nearest2, nearest3] = getNearestEdge(
      store.canvas,
      pointer1,
      5,
      5,
      false
    );
    var m = getSlopeAngle(nearest1, nearest3);
    var c = 0;
    draw_stair_position(nearest1, m, c);
  } else if (store.evt_mode == "partition") {
    store.canvas.remove(store.partition_group);
    var pointer = store.canvas.getPointer(e.e);
    var pointer1 = store.canvas.getPointer(e.e);
    var edgedetection_tape = 20;
    var nearest;
    let [nearest1, nearest2, nearest3] = getNearestEdge(
      store.canvas,
      pointer1,
      5,
      5,
      false
    );
    var m = getSlopeAngle(nearest1, nearest3);
    var c = 0;
    draw_partition_position(nearest1, m, c);
  }
  var edgedetection_tape = 10;
  if (store.evt_mode == "tape_tool") {
    if (!store.tapeisDown) return;
    store.canvas.remove(store.tape_line);
    store.canvas.remove(store.tape_text_tool);
    store.canvas.remove(store.tape_text_static_tool);

    pointer1 = store.canvas.getPointer(e.e);
    // pointer1 = zoomedPoint(e.e);

    // store.canvas.forEachObject(function (targ) {
    //     activeObject = store.canvas.getActiveObject();
    //
    //     if (targ === activeObject) return;
    //
    //     if (Math.abs(pointer1.x - targ.oCoords.tl.x) < edgedetection_tape) {
    //         pointer1.x = targ.oCoords.tl.x;
    //         //corner Snapping
    //         if (Math.abs(pointer1.y - targ.oCoords.tl.y) < edgedetection_tape) {
    //             pointer1.y = targ.oCoords.tl.y;
    //         }
    //         if (Math.abs(pointer1.y - targ.oCoords.bl.y) < edgedetection_tape) {
    //             pointer1.y = targ.oCoords.bl.y;
    //         }
    //         //center Snapping
    //         if (Math.abs(pointer1.y - (targ.oCoords.bl.y + targ.oCoords.tl.y) / 2) < edgedetection_tape) {
    //             pointer1.y = (targ.oCoords.bl.y + targ.oCoords.tl.y) / 2;
    //         }
    //     }
    //     if (Math.abs(pointer1.x - targ.oCoords.tr.x) < edgedetection_tape) {
    //         pointer1.x = targ.oCoords.tr.x
    //         //corner Snapping
    //         if (Math.abs(pointer1.y - targ.oCoords.tr.y) < edgedetection_tape) {
    //             pointer1.y = targ.oCoords.tr.y;
    //         }
    //         if (Math.abs(pointer1.y - targ.oCoords.br.y) < edgedetection_tape) {
    //             pointer1.y = targ.oCoords.br.y;
    //         }
    //         //center Snapping
    //         if (Math.abs(pointer1.y - (targ.oCoords.br.y + targ.oCoords.tr.y) / 2) < edgedetection_tape) {
    //             pointer1.y = (targ.oCoords.br.y + targ.oCoords.tr.y) / 2;
    //         }
    //     }
    //     if (Math.abs(pointer1.y - targ.oCoords.tr.y) < edgedetection_tape) {
    //         pointer1.y = targ.oCoords.tr.y
    //         //corner Snapping
    //         if (Math.abs(pointer1.x - targ.oCoords.tr.x) < edgedetection_tape) {
    //             pointer1.x = targ.oCoords.tr.x;
    //         }
    //         if (Math.abs(pointer1.x - targ.oCoords.tl.x) < edgedetection_tape) {
    //             pointer1.x = targ.oCoords.tl.x;
    //         }
    //         //center Snapping
    //         if (Math.abs(pointer1.x - (targ.oCoords.tl.x + targ.oCoords.tr.x) / 2) < edgedetection_tape) {
    //             pointer1.x = (targ.oCoords.tl.x + targ.oCoords.tr.x) / 2;
    //         }
    //     }
    //     if (Math.abs(pointer1.y - targ.oCoords.br.y) < edgedetection_tape) {
    //         pointer1.y = targ.oCoords.br.y
    //         //corner Snapping
    //         if (Math.abs(pointer1.x - targ.oCoords.br.x) < edgedetection_tape) {
    //             pointer1.x = targ.oCoords.br.x;
    //         }
    //         if (Math.abs(pointer1.x - targ.oCoords.bl.x) < edgedetection_tape) {
    //             pointer1.x = targ.oCoords.bl.x;
    //         }
    //         //center Snapping
    //         if (Math.abs(pointer1.x - (targ.oCoords.bl.x + targ.oCoords.br.x) / 2) < edgedetection_tape) {
    //             pointer1.x = (targ.oCoords.bl.x + targ.oCoords.br.x) / 2;
    //         }
    //     }
    // });

    var pts = snapTapeVert({ x: pointer1.x, y: pointer1.y }, 15, 15);
    if (Math.abs(store.tape_line.x1 - pts.x) < 15) pts.x = store.tape_line.x1;
    if (Math.abs(store.tape_line.y1 - pts.y) < 15) pts.y = store.tape_line.y1;
    //Draw Line
    // points = [tape_line.x1, tape_line.y1, pointer1.x, pointer1.y];
    points = [store.tape_line.x1, store.tape_line.y1, pts.x, pts.y];
    store.tape_line = new fabric.Line(points, {
      strokeWidth: 4,
      stroke: "black",
      originX: "center",
      originY: "center",
      selectable: false,
    });
    store.canvas.add(store.tape_line);
    var tape_length = Math.pow(
      Math.pow(store.tape_line.x1 - pts.x, 2) +
        Math.pow(store.tape_line.y1 - pts.y, 2),
      0.5
    );
    tape_length = (tape_length * 2.54) / 100;
    // tape_length = Math.round(tape_length * 100) / 100;
    tape_length = Math.round(tape_length * store.tape_scale_factor * 100) / 100;
    // (tape_length);
    store.tape_text_tool = new fabric.Textbox(tape_length.toString(), {
      id: "tape_tool_measure",
      fontFamily: "arial black",
      left: (store.tape_line.x1 + pointer1.x) / 2 + 4,
      top: (store.tape_line.y1 + pointer1.y) / 2 + 4,
      selectable: true,
      editable: true,
      fontSize: 10,
      borderColor: "black",
      cornerSize: 2,
      hasRotatingPoint: false,
      hoverCursor: "text",
      transparentCorners: false,
    });
    store.canvas.add(store.tape_text_tool);
    store.tape_text_static_tool = new fabric.Textbox("mts", {
      id: "tape_tool_measure",
      fontFamily: "arial black",
      left: (store.tape_line.x1 + pointer1.x) / 2 + 10,
      top: (store.tape_line.y1 + pointer1.y) / 2 + 10,
      selectable: false,
      fontSize: 10,
    });
    store.canvas.add(store.tape_text_static_tool);
  }
  if (store.evt_mode == "add_vertex") {
    if (!e.target) {
      removeAllGripsWithId("addvertex");
      return;
    }
    removeAllGripsWithId("addvertex");
    let pointer = store.canvas.getPointer(e.e);

    /*let pointerGot = store.canvas.getPointer(e.e);
        var mCanvas = store.canvas.viewportTransform;
        var mObject = e.target.calcTransformMatrix();
        var mTotal = fabric.util.multiplyTransformMatrices(mCanvas, mObject); // inverting the order gives wrong result
        var mInverse = fabric.util.invertTransform(mTotal);
        var pointer = fabric.util.transformPoint(pointerGot, mInverse);

        console.log("1", pointerGot);
        console.log("2", pointer);*/

    let snappedPointObj = snapNearestEdge(pointer);
    addVertexSnapPoint = snappedPointObj;
    if (snappedPointObj) {
      let snappedPoint = snappedPointObj.projPt;
      if (snappedPoint) {
        let addVertexGrip = new fabric.Rect({
          left: snappedPoint.x - 7.5,
          top: snappedPoint.y - 7.5,
          width: 15,
          height: 15,
          fill: "rgba(24, 230, 24,1)",
          id: "addvertex",
          evented: "false",
        });
        store.canvas.add(addVertexGrip);
      } else {
        removeAllGripsWithId("addvertex");
      }
    } else {
      removeAllGripsWithId("addvertex");
    }
  }
  if (store.evt_mode == "remove_vertex") {
    removeAllGripsWithId("removevertex");
    let pointer = store.canvas.getPointer(e.e);
    let snappedPointObj = findNearestVertex(pointer, 10);
    removeVertexSnapPoint = snappedPointObj;
    if (snappedPointObj) {
      let snappedPoint = snappedPointObj.point;
      if (snappedPoint) {
        let removeVertexGrip = new fabric.Rect({
          left: snappedPoint.x - 7.5,
          top: snappedPoint.y - 7.5,
          width: 15,
          height: 15,
          fill: "rgba(24, 230, 24,1)",
          id: "removevertex",
          evented: "false",
        });
        store.canvas.add(removeVertexGrip);
      } else {
        removeAllGripsWithId("removevertex");
      }
    } else {
      removeAllGripsWithId("removevertex");
    }
  }
  if (store.evt_mode == "walls") {
    if (!started) {
      return false;
    }
    var mouse = store.canvas.getPointer(e.e);
    // (xwid = Math.min(mouse.x, xwid)),
    //   (ywid = Math.min(mouse.y, ywid)),
    //   (store.w = Math.abs(mouse.x - xwid)),
    //   (store.h = Math.abs(mouse.y - ywid));
    if (!store.w || !store.h) {
      return false;
    }
    var square = store.canvas.getActiveObject();
    square
      .set("top", ywid)
      .set("left", xwid)
      .set("width", store.w)
      .set("height", store.h);
    store.canvas.renderAll();
  }
  if (store.evt_mode == "edit_polygon") {
    if (!e.target) {
      removeAllGripsWithId("editpolygon");
      return;
    }
    removeAllGripsWithId("editpolygon");
    removeAllGripsWithId("edgedimensionline");
    removeAllGripsWithId("edgedimensiontext");
    let pointer = store.canvas.getPointer(e.e);
    let snappedPointObj = snapNearestEdge(pointer);
    editPolygonSnapPoint = snappedPointObj;
    if (snappedPointObj) {
      let snappedEdge = snappedPointObj.edge;
      if (snappedEdge) {
        let midPoint = new fabric.Point(
          (snappedEdge[0].x + snappedEdge[1].x) / 2,
          (snappedEdge[0].y + snappedEdge[1].y) / 2
        );
        let editPolygonGrip = new fabric.Rect({
          left: midPoint.x - 7.5,
          top: midPoint.y - 7.5,
          width: 15,
          height: 15,
          fill: "rgba(24, 230, 24,1)",
          id: "editpolygon",
          evented: "false",
        });
        store.canvas.add(editPolygonGrip);
        displayEdgeDimension(snappedEdge);
      } else {
        removeAllGripsWithId("editpolygon");
        removeAllGripsWithId("edgedimensionline");
        removeAllGripsWithId("edgedimensiontext");
      }
    } else {
      removeAllGripsWithId("editpolygon");
      removeAllGripsWithId("edgedimensionline");
      removeAllGripsWithId("edgedimensiontext");
    }
  }
}

function canvasMouseUp(e) {
  store.panning = false;
  if (store.mode == "pen" && store.isDown) {
    store.isDown = false;
    updateModifications(true);
  }
  if (store.evt_mode == "door_draw") {
    var gObjs = store.door_group.getObjects();
    gObjs.forEach(function (gObj) {
      if (gObj.get("id") == "line2") {
        gObj.set({ stroke: "white", strokeWidth: 4 });
      }
    });
    store.door_group.id = "door";
    var door_parent = getParentRoom(store.door_group, store.canvas);
    assignParentRoom(door_parent, store.door_group);
    var pointer = store.canvas.getPointer(e.e);
    draw_door_position(pointer, 0, 0);
  } else if (store.evt_mode == "window_draw") {
    var gObjs = store.window_group.getObjects();
    gObjs.forEach(function (gObj) {
      if (gObj.get("id") == "line2") {
        gObj.set({ stroke: "white" });
      }
    });
    store.window_group.id = "window";
    var window_parent = getParentRoom(store.window_group, store.canvas);
    assignParentRoom(window_parent, store.window_group);

    var pointer = store.canvas.getPointer(e.e);
    draw_window_position(pointer);
  } else if (store.evt_mode == "stair_draw") {
    store.stair_group.id = "stair";
    var stair_parent = getParentRoom(store.stair_group, store.canvas);
    assignParentRoom(stair_parent, store.stair_group);

    var pointer = store.canvas.getPointer(e.e);
    draw_stair_geom_position(pointer);
  } else if (store.evt_mode == "partition") {
    var gObjs = store.partition_group.getObjects();
    gObjs.forEach(function (gObj) {
      if (gObj.get("id") == "line2") {
        gObj.set({ stroke: "white" });
      }
    });
    store.partition_group.id = "partition";
    var partition_parent = getParentRoom(store.partition_group, store.canvas);
    assignParentRoom(partition_parent, store.partition_group);
    var pointer = store.canvas.getPointer(e.e);
    draw_partition_position(pointer);
  }
  if (store.evt_mode == "tape_tool") {
    if (!store.tapeisDown) {
      store.tapeisDown = true;
      var pointer = store.canvas.getPointer(e.e);
      var pts = snapTapeVert({ x: pointer.x, y: pointer.y }, 15, 15);
      points = [pts.x, pts.y, pts.x, pts.y];
      store.tape_line = new fabric.Line(points, {
        strokeWidth: 2,
        stroke: "black",
        originX: "center",
        originY: "center",
        selectable: false,
      });
      store.canvas.add(store.tape_line);
      store.point1 = [pointer.x, pointer.y];

      var tape_length = Math.pow(
        Math.pow(store.tape_line.x1 - pointer.x, 2) +
          Math.pow(store.tape_line.y1 - pointer.y, 2),
        0.5
      );
      tape_length = (tape_length * 2.54) / 100;
      tape_length =
        Math.round(tape_length * store.tape_scale_factor * 100) / 100;
      store.tape_text_tool = new fabric.Textbox(tape_length.toString(), {
        id: "tape_tool_measure",
        fontFamily: "arial black",
        left: (store.tape_line.x1 + pointer.x) / 2 + 2,
        top: (store.tape_line.x1 + pointer.x) / 2 + 2,
        selectable: true,
        editable: true,
        fontSize: 10,
        borderColor: "black",
        cornerSize: 2,
        hasRotatingPoint: false,
        hoverCursor: "text",
        transparentCorners: false,
      });
      store.canvas.add(store.tape_text_tool);
      store.tape_text_static_tool = new fabric.Textbox("mts", {
        id: "tape_tool_measure",
        fontFamily: "arial black",
        left: (store.tape_line.x1 + pointer.x) / 2 + 8,
        top: (store.tape_line.x1 + pointer.x) / 2 + 8,
        selectable: false,
        fontSize: 10,
      });
      store.canvas.add(store.tape_text_static_tool);
      let rect1 = new fabric.Rect({
        width: 10,
        height: 10,
        left: points.x - 5,
        top: points.y - 5,
        fill: "rgba(0,0.5,0,0.3)",
      });
      store.canvas.add(rect1);
    } else {
      store.tapeisDown = false;
      updateModifications(true);
      var points = [
        store.tape_line.x1 * store.scale_factor,
        store.tape_line.y1 * store.scale_factor,
        store.tape_line.x2 * store.scale_factor,
        store.tape_line.y2 * store.scale_factor,
      ];
    }
  }
  if (store.evt_mode == "edit_vert") {
    if (store.evt_mode == "edit_vert") {
      if (store.mouse_mode == "drag") {
        if (store.objSelected) {
          if (store.objSelected.hasOwnProperty("points")) {
            if (store.objSelected.points) {
              var objSelected1 = draw_interior_drag(
                store.drag_points,
                store.objSelected,
                getFillColor(store.objSelected)
              );
              var temp_children = store.objSelected.children;
              store.canvas.remove(store.objSelected);
            } else {
              var objSelected1 = draw_interior_drag(
                Object.assign({}, store.drag_points),
                store.objSelected,
                getFillColor(store.objSelected)
              );
              var temp_children = store.objSelected.children;
              objSelected1.setCoords();
              store.canvas.remove(store.objSelected);
            }
            objSelected1.children = temp_children;
            objSelected1.children.bringToFront();
            objSelected1.children.hasControls = false;
            objSelected1.children.hasBorders = false;
            objSelected1.setCoords();
            removeAllChildren();
            store.mouse_mode = null;
            store.mouse_down = false;
            store.objSelected = objSelected1;
            removeAllGripsWithId("addvertexfinal");
            addObjectVertGrips(objSelected1);
            updateModifications(true);
          }
        }
      } else if (store.mouse_down) {
        if (store.objSelected.hasOwnProperty("children")) {
          if (store.child_number != undefined) {
            var obj = store.objSelected.children;
            var items = obj._objects;
            items[store.child_number].fill = "rgba(24, 230, 24,1)";
            store.canvas.renderAll();
          }
        }
        store.mouse_down = false;
      }
    }
  }
  if (store.evt_mode == "edit_edge") {
  }
  if (store.evt_mode == "walls") {
    if (started) {
      var square = store.canvas.getActiveObject();
      store.canvas.remove(square);
      started = false;
      var mouse = store.canvas.getPointer(e.e);
      let data = {};
      data.pt1 = [xwid, ywid];
      data.pt2 = [mouse.x, mouse.y];
      var point = [mouse.x * store.scale_factor, mouse.y * store.scale_factor];
      send_wall_query(data, point);
      var x = document.createElement("div");
      x.className = "mdl-spinner mdl-js-spinner is-active";
      x.id = "load" + point[0] + point[1];
      x.style.zIndex = 9999999;
      store.componentHandler.upgradeElement(x);
      document.getElementById("canvas_container").appendChild(x);
      x.style.position = "absolute";
      var cx = e.e.clientX;
      var cy = e.e.clientY;
      x.style.left = cx + "px";
      x.style.top = cy + "px";
    }
  }
}

var room_measurement_flag = false;

function canvasMouseOver(e) {
  if (!e.target) {
    return;
  }
  var $scope = store.angular.element(appElement).scope();
  $scope = $scope.$$childHead;

  var obj = e.target;
  if (obj != null && store.objSelected && store.evt_mode == "edit_vert") {
    if (obj.get("id") == "children") {
      var items = obj._objects;
      var pointer = store.canvas.getPointer(e.e);
      for (var i = 0; i < items.length; i++) {
        if (
          Math.abs(
            pointer.x - items[i].getCenterPoint().x - obj.getCenterPoint().x
          ) < 15 &&
          Math.abs(
            pointer.y - items[i].getCenterPoint().y - obj.getCenterPoint().y
          ) < 15
        ) {
          var centSlopeX =
            items[i].getCenterPoint().x +
            obj.getCenterPoint().x -
            store.objSelected.getCenterPoint().x;
          var centSlopeY =
            items[i].getCenterPoint().y +
            obj.getCenterPoint().y -
            store.objSelected.getCenterPoint().y;
          var angle;
          if (centSlopeX != 0) {
            angle = (Math.atan(centSlopeY / centSlopeX) * 180) / Math.PI;
          } else {
            angle = 90;
          }
          if (Math.abs(angle) > 50) {
            changeCursor("ns-resize");
          } else if (Math.abs(angle) < 35) {
            changeCursor("ew-resize");
          }
        }
      }
    } else {
      changeCursor("crosshair");
    }
  }

  if (obj != null && store.objSelected && store.evt_mode == "edit_edge") {
    if (obj.get("id") == "children") {
      let items = obj._objects;
      let pointer = store.canvas.getPointer(e.e);
      for (let i = 0; i < items.length; i++) {
        if (
          Math.abs(
            pointer.x - items[i].getCenterPoint().x - obj.getCenterPoint().x
          ) < 15 &&
          Math.abs(
            pointer.y - items[i].getCenterPoint().y - obj.getCenterPoint().y
          ) < 15
        ) {
          let centSlopeX =
            items[i].getCenterPoint().x +
            obj.getCenterPoint().x -
            store.objSelected.getCenterPoint().x;
          let centSlopeY =
            items[i].getCenterPoint().y +
            obj.getCenterPoint().y -
            store.objSelected.getCenterPoint().y;
          let angle = null;
          if (centSlopeX != 0) {
            angle = (Math.atan(centSlopeY / centSlopeX) * 180) / Math.PI;
          } else {
            angle = 90;
          }
          if (Math.abs(angle) > 50) {
            changeCursor("ns-resize");
          } else if (Math.abs(angle) < 35) {
            changeCursor("ew-resize");
          }
        }
      }
    } else {
      changeCursor("crosshair");
    }
  }

  if (obj.id == "rooms") {
    try {
      store.canvas.remove(store.room_width_line);
      store.canvas.remove(store.room_height_line);
      store.canvas.remove(store.width_text);
      store.canvas.remove(store.width_text_static);
      store.canvas.remove(store.height_text);
      store.canvas.remove(store.height_text_static);
      store.object_name.remove();
      room_measurement_flag = false;
    } catch (err) {}
    if (obj.points && store.evt_mode != "edit_polygon") drawDimension(obj);
  } else {
    try {
      store.canvas.remove(store.room_width_line);
      store.canvas.remove(store.room_height_line);
      store.canvas.remove(store.width_text);
      store.canvas.remove(store.width_text_static);
      store.canvas.remove(store.height_text);
      store.canvas.remove(store.height_text_static);
      store.object_name.remove();
      room_measurement_flag = false;
    } catch (err) {}
  }

  if (store.evt_mode == "tape") {
    updateEventMode(store.mode, store.evt_mode);
  }
  if (
    store.evt_mode == "door_draw" ||
    store.evt_mode == "window_draw" ||
    store.evt_mode == "partition"
  ) {
    var obj = e.target;
    if (obj.isType("polygon")) {
      // ("Its a poly");
    }

    if (obj.isType("line")) {
      var x1 = obj.x1;
      var x2 = obj.x2;
      var y1 = obj.y1;
      var y2 = obj.y2;
      store.m = (y1 - y2) / (x2 - x1);
      store.c = -y2 - store.m * x2;
      store.line_flag = 1;
    } else store.line_flag = 0;
  }

  store.canvas.renderAll();
}

function canvasMouseOut(e) {
  if (store.evt_mode == "Hover") {
    store.evt_mode = "tape";
    store.mode = "tape";
    updateEventMode(store.mode, store.evt_mode);
  }
  store.line_flag = 0;
  store.panning = false;
  try {
    store.canvas.remove(store.room_width_line);
    store.canvas.remove(store.room_height_line);
    store.canvas.remove(store.width_text);
    store.canvas.remove(store.width_text_static);
    store.canvas.remove(store.height_text);
    store.canvas.remove(store.height_text_static);
    store.object_name.remove();
    room_measurement_flag = false;
  } catch (err) {}
}

const mouseObserver = (function(){
  let pickedPoint;

  const onMouseDown = (pointerInfo) => {
    pickedPoint = pointerInfo.pickInfo.pickedPoint;
    // console.log(pickedPoint)
  }

  return {
    pickedPoint,
    onMouseDown
  }

})();

export {
  addVertexSnapPoint,
  removeVertexSnapPoint,
  pointsBeforeEditEdge,
  editPolygonSnapPoint,
  editPolygonSnapPointFinal,
  started,
  xwid,
  ywid,
  canvasMouseDown,
  canvasMouseMove,
  canvasMouseUp,
  room_measurement_flag,
  canvasMouseOver,
  canvasMouseOut,
  mouseObserver
};
